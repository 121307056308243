import React, { useEffect, useState } from 'react';
import { GridDxDrawdownMon, SkeletonLoading } from '../../components/index';
import { useGlobalState } from '../../contexts/useGlobalState';
import Card from 'react-bootstrap/Card'
import moment from 'moment';
import { toInteger, toUpper } from 'lodash';

const format = {
    type: "currency",
    precision: 2
}
const column = [
    { "dataField": "description", "caption": "Description", "dataType": "string", minWidth: 200 },
    { "dataField": "cost_element_name", "caption": "Cost Element", "visible": true, "dataType": "string", width: 150 },
    { "dataField": "plan_actual", "caption": "", "visible": true, "dataType": "string",minWidth: 76, width: 76, allowFiltering:false },
    { "dataField": "plan_jan", "caption": "JAN", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_feb", "caption": "FEB", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_mar", "caption": "MAR", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_apr", "caption": "APR", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_may", "caption": "MAY", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_jun", "caption": "JUN", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_jul", "caption": "JUL", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_aug", "caption": "AUG", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_sep", "caption": "SEP", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_oct", "caption": "OCT", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_nov", "caption": "NOV", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_dec", "caption": "DEC", "visible": true, "format": format, "dataType": "number", width: 65 }
]
const TabBudgetMon = ({ param }) => {
    const [data, setData] = useState()
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        if (param?.id) {
            action.getData("/api/param-cost-element", {}, "cost_element").then((resp) => {
                if (resp.code === "00") {
                    dispatch({
                        type: "getSuccess",
                        payload: { cost_element: resp.data, loadingGet: false }
                    })
                }
            })
            action.getData("/api/project-ongoing-crud/view-budget-4/" + param?.id, {}, null, 'loadingBudget', true).then((resp) => {
                if (resp.code === "00") {
                    setData([...(resp.data.data_view_budget || [])])
                    action.setForm('cut_off_budget', resp.data.data_cut_off_day || 1);
                    action.setForm('mon', resp.data_page);
                }
            })
        }

    }, [])

    return (
        <>
            <div className="row" style={{ paddingLeft: 15, paddingRight: 15 }}>
                <div className="col">
                <span className='d-block text-center mb-5 px-2 py-1 bg-primary rounded text-white'>Pengisian realisasi bulan {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).format('MMM'))} dapat dilakukan s.d. tanggal {state.data_cut_off_date} {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).add(1, 'month').format('MMM'))} {state.data_year_month?.split('/')?.[1]}</span>
                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>Drawdown Budget(Anggaran Biaya Operasi)</p>
                    <p><i className="fa fa-circle icon-sm"></i> Nominal anggaran yang tertera bernilai $(Ribu USD)</p>
                    <p><i className="fa fa-circle icon-sm"></i> Kurs untuk tahun 2024: Rp 14.500</p>
                    <p><i className="fa fa-circle icon-sm"></i> Kurs untuk tahun 2025: Rp 15.046</p>
                </div>
            </div>
            {
                state.loadingBudget ? (
                    <SkeletonLoading type="table" rows={5} cols={column.length} />
                ) : (
                    [...(data || [])].map((e, i) => {
                        return (
                            <Card className="p-0 my-2">
                                <Card.Body className="p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 text-left">
                                                    <span>{e.year}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <GridDxDrawdownMon
                                                        dataSource={e}
                                                        key={i}
                                                        allowAdding={false}
                                                        allowDeleting={false}
                                                        allowEditing={false}
                                                        columns={column}
                                                        showTotalActual={true}
                                                    />
                                                </div>
                                            </div>                                           
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                )
            }
        </>
    )
}
export default TabBudgetMon;