import React, { useEffect, useRef, useState } from 'react'
import { GridDx } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';


const column = [
    { fieldName: "date", caption: "Tanggal", width: 100 },
    { fieldName: "user_name", caption: "Create By", width: 100 },
    { fieldName: "description", caption: "Deskripsi", minWidth: 200 },
    { fieldName: "way_forward", caption: "Way Forward", width: 200 },
]
const SelectLogBook = ({
    data = [],
    defaultSelected = [],
    onAdd = () => {},
    onSelectionData = () => {}
}) => {
    const [state, dispatch, action] = useGlobalState();
    const [sel, setSel] = useState()
    const tableRef = useRef()

    function customCellrender(e) {
        if (e.column.name === "date") {
            return (<span className="d-block text-wrap">{e.data.date_format}</span>)
        } else if (e.column.name === "way_forward") {
            return (<div className="text-wrap dangerous-text" dangerouslySetInnerHTML={{ __html: e.value }}></div>)
        } else if (e.column.name === "description") {
            return (<div className="text-wrap dangerous-text" dangerouslySetInnerHTML={{ __html: e.value }}></div>)
        } else {
            return (
                <span className="d-block text-wrap">{e.text}</span>
            )
        }
    }
    return (
        <div>
            <span className="d-block">Add From Logbook</span>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <GridDx
                        defaultSelected={defaultSelected}
                        selectionMode="multiple"
                        searchForm={true}
                        ref={tableRef}
                        data={data}
                        onSelectionData={ (e) => {onSelectionData(e.dataSelected); setSel(e.dataSelected);}}
                        columns={column}
                        cellRender={(e) => customCellrender(e)}
                        withAction={false}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                    />
                </div>
            </div>
            <div className="mt-5 d-block text-right">
                <button className="btn btn-sm btn-primary" onClick={() => onAdd(sel)}>Save</button>
            </div>
        </div>
    )
}
export default SelectLogBook;