import React, { memo } from 'react'
import { Card } from 'react-bootstrap'
import { TextEditor } from '../../components'
import { useGlobalState } from '../../contexts/useGlobalState';
import DateBox from 'devextreme-react/date-box';

const ModalAbout = memo(({ data, mode }) => {
    const [, , action] = useGlobalState();

    return (
        <Card
            className="m-0 p-0 customShadow"
        >
            <Card.Body>
                <span><b>{mode}&nbsp;About</b></span>
                <hr />
                <div className="row mb-3">
                    <div className="col-md-3">Date</div>
                    <div className="col-md-9">
                        <DateBox
                            type="date" />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-3">Version</div>
                    <div className="col-md-9">
                        <input className="form-control form-control-sm"></input>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-3">Update</div>
                    <div className="col-md-9">
                        <TextEditor
                            className="mb-5"
                            multilineToolbar={false}
                            height="250px"
                        />
                    </div>
                </div>
                <hr/>
                <div className="d-block text-right">
                    <button className="btn btn-sm btn-secondary mx-1"
                        onClick={() => action.toggleModal("modalabout")}
                    >
                        Batal
                    </button>
                    <button className="btn btn-sm btn-primary">
                        <i className="fa fa-save icon-nm mx-1 text-white"></i>
                        Save
                    </button>
                </div>
            </Card.Body>
        </Card>
    )
})
export default ModalAbout