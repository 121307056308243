import React, { useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GridDx, HeaderList } from '../../components';
import useTabelDetail, { TabelDetailUserProvider } from './useTableDetail';

const column = [
    { fieldName: 'nama', caption: 'Nama User' },
    { fieldName: 'position', caption: 'Position' },
    { fieldName: 'chief', caption: 'Chief' },
    { fieldName: 'func', caption: 'Function' },
    { fieldName: 'jumlah', caption: 'Jumlah Visit' },
    { fieldName: 'jumlah_b', caption: 'Jumlah Page View' },
]
function TableDetail(props) {
    const tableRef = useRef();
    const { tabDtAction, tabDtState } = useTabelDetail();
    const history = useHistory();
    return (
        <div className='p-5'>
            <HeaderList
                title={tabDtState.data_page?.page_title}
                someText={tabDtState.data_page?.page_breadcrumbs}
            />
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <GridDx
                                searchForm={true}
                                ref={tableRef}
                                // loading={state.loadingGrid}
                                data={tabDtState?.data}
                                columns={column}
                                // cellRender={(e) => cstmCellRender(e)}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                            {/* <button onClick={() => history.goBack()} className='btn btn-sm btn-secondary mt-5'>Kembali</button> */}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default () => <TabelDetailUserProvider><TableDetail /></TabelDetailUserProvider>;