import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "../../contexts/useGlobalState";
import Swal from 'sweetalert2';
import moment from "moment";

const edtSwaContext = createContext({
    edtSwaState: {},
    edtSwaAction: {}
})
export function EdtSwaPrvider({ children }) {
    const [globalState, dispatch, globalAction] = useGlobalState();
    const history = useHistory();
    const [loadingGetEdit, setLoadingGetEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState({});
    const [dataSection, setDataSection] = useState([]);
    const [judul, setJudul] = useState('');
    const [nama_pihak3, setNama_pihak3] = useState([]);
    const [nama_pihak3_temp, setNama_pihak3_temp] = useState('');
    const [pic, setPic] = useState('');
    const [fungsi, setFungsi] = useState('');
    const [section_manager, setSection_manager] = useState('');
    const [persetujuan_oleh, setPersetujuan_oleh] = useState('');
    const [related_list_sel, setRelated_list_sel] = useState({});
    const [related_list_name, setRelated_list_name] = useState('');
    const [related_list, setRelated_list] = useState([]);
    const [owner_est, setOwner_est] = useState('');
    const [start_date, setStart_date] = useState('');
    const [end_date, setEnd_date] = useState('');
    const [dok1_kak, setDok1_kak] = useState('');
    const [dok2_owner_est, setDok2_owner_est] = useState('');
    const [dok3_purchase_req, setDok3_purchase_req] = useState('');
    const [dok4_pakta_int, setDok4_pakta_int] = useState('');
    const [dok5_justifikasi, setDok5_justifikasi] = useState('');
    const [dok6_lainnya, setDok6_lainnya] = useState('');


    async function getDataEdit(id = '0') {
        setLoadingGetEdit(true)
        try {
            const resp = await globalAction.getData(`/api/ext-swakelola/Edit/${id}`, {}, null, null, true)
            if (resp.code === "00") {
                setDataEdit(resp.data);
                editorPreparing(resp.data);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingGetEdit(false)
    }
    async function getDataSection(id = '') {
        if (id === '') {
            setDataSection([]);
        } else {
            try {
                const resp = await globalAction.getData(`/api/param-function-chief-manager/${id}`)
                if (resp.code === "00") {
                    setDataSection(resp.data)
                }
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }
        }
    }
    async function uploadFile(data, dok_type) {
        const file = data.value?.[0];
        var new_file = new File([file], 'Swakelola-' + file?.name);
        if (file && file.size <= 10000000) {
            try {
                const resp = await globalAction.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": new_file,
                        },
                        withFile: true,
                        withLoading: false,
                        withAlert: false,
                    }
                );
                if (resp.code === "00") {
                    if (dok_type === 'dok_1') {
                        setDok1_kak(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_2') {
                        setDok2_owner_est(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_3') {
                        setDok3_purchase_req(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_4') {
                        setDok4_pakta_int(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_5') {
                        setDok5_justifikasi(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_6') {
                        setDok6_lainnya(resp.data?.data_filename);
                    }
                }
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }

        } else if (file && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    async function postAdd(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/store-swakelola",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                history.goBack();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postEdit(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/update-swakelola",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                history.goBack();
                // history.replace(`/admin/swakelola-detail/${dataEdit?.data_pengajuan?.id}`);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    function onFileUploaded(res, dok_type) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            if (dok_type === 'dok_1') {
                setDok1_kak(resp.data?.data_filename);
            }
            if (dok_type === 'dok_2') {
                setDok2_owner_est(resp.data?.data_filename);
            }
            if (dok_type === 'dok_3') {
                setDok3_purchase_req(resp.data?.data_filename);
            }
            if (dok_type === 'dok_4') {
                setDok4_pakta_int(resp.data?.data_filename);
            }
            if (dok_type === 'dok_5') {
                setDok5_justifikasi(resp.data?.data_filename);
            }
            if (dok_type === 'dok_6') {
                setDok6_lainnya(resp.data?.data_filename);
            }
        }
    }
    function editorPreparing(data) {
        setJudul(data?.data_pengajuan?.judul);
        setPersetujuan_oleh(data?.data_pengajuan?.persetujuan_oleh);
        setPic(data?.data_pengajuan?.pic);
        setStart_date(data?.data_pengajuan?.start_date ? moment(data?.data_pengajuan?.start_date).format('YYYY-MM-DD') : '');
        setEnd_date(data?.data_pengajuan?.end_date ? moment(data?.data_pengajuan?.end_date).format('YYYY-MM-DD') : '');
        setOwner_est(data?.data_pengajuan?.owner_est);
        setDok1_kak(data?.data_pengajuan?.dok1_kak);
        setDok2_owner_est(data?.data_pengajuan?.dok2_owner_est);
        setDok3_purchase_req(data?.data_pengajuan?.dok3_purchase_req);
        setDok4_pakta_int(data?.data_pengajuan?.dok4_pakta_int);
        setDok5_justifikasi(data?.data_pengajuan?.dok5_justifikasi);
        setDok6_lainnya(data?.data_pengajuan?.dok6_lainnya);
        setNama_pihak3(data?.data_pengajuan?.nama_pihak3);
        setRelated_list(data?.data_pengajuan?.related_list);
        setFungsi(data?.data_pengajuan?.fungsi_id);
        fChange(data?.data_pengajuan?.fungsi_id || '');
        setSection_manager(data?.data_pengajuan?.section_manager);
    }
    function addPihak3(pihak3) {
        var tmp = nama_pihak3 || [];
        if (pihak3?.nama && !tmp.includes(pihak3)) {
            setNama_pihak3_temp('');
            setNama_pihak3([pihak3, ...tmp]);
        }
    }
    function deletePihak3(pihak3) {
        if (pihak3) {
            setNama_pihak3(nama_pihak3.filter(d => d.nama !== pihak3.nama));
        }
    }
    function addRelatedList(related) {
        if (related?.id) {
            const tmp_rel = {
                "id": '',
                "swakelola_id": dataEdit?.data_pengajuan?.swakelola_id || '',
                "title": related?.name,
                "research_id": related?.id
            };
            const tmp = related_list || [];
            if (related && !tmp.find(d => {
                return d.research_id?.toString() === tmp_rel.research_id?.toString()
            })) {
                setRelated_list_name('');
                setRelated_list_sel({});
                setRelated_list([tmp_rel, ...tmp]);
            }
        }
    }
    function deleteRelatedList(related) {
        const tmp = related_list || [];
        if (related) {
            setRelated_list(tmp.filter(d => d.research_id?.toString() !== related?.research_id?.toString()));
        }
    }
    function fChange(id) {
        setSection_manager('');
        getDataSection(id);
        setFungsi(id);
    }
    function btnBackClick() {
        history.goBack();
    }
    function addSwakelola() {
        const data = {
            "id": dataEdit?.data_pengajuan?.id,
            "swakelola_id": dataEdit?.data_pengajuan?.swakelola_id || '',
            "judul": judul || '',
            "nama_pihak3": nama_pihak3 || [],
            "related_list": related_list || [],
            "show_create": "",
            "show_creator": "",
            "fungsi": fungsi || '',
            "status_swakelola": "",
            "show_start": "",
            "show_end": "",
            "start_date": start_date || '',
            "end_date": end_date || '',
            "nama_PIC": '',
            "nama_section": '',
            "nama_persetujuan": '',
            "owner_est": owner_est || '0',
            "nilai_kontrak_terbaru": '0',
            "totalPlan": '0',
            "totalReal": '0',
            "presentasePlanReal": '0',
            "pic": pic || '',
            "section_manager": section_manager || '',
            "persetujuan_oleh": persetujuan_oleh || '',
            "plan_1": null,
            "plan_2": null,
            "plan_3": null,
            "plan_4": null,
            "plan_5": null,
            "plan_6": null,
            "plan_7": null,
            "plan_8": null,
            "plan_9": null,
            "plan_10": null,
            "plan_11": null,
            "plan_12": null,
            "real_1": null,
            "real_2": null,
            "real_3": null,
            "real_4": null,
            "real_5": null,
            "real_6": null,
            "real_7": null,
            "real_8": null,
            "real_9": null,
            "real_10": null,
            "real_11": null,
            "real_12": null,
            "dok1_kak": dok1_kak || '',
            "dok2_owner_est": dok2_owner_est || '',
            "dok3_purchase_req": dok3_purchase_req || '',
            "dok4_pakta_int": dok4_pakta_int || '',
            "dok5_justifikasi": dok5_justifikasi || '',
            "dok6_lainnya": dok6_lainnya
        }
        const val = validation(data);
        if (val.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        }

        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Swakelola?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postAdd(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function editSwakelola() {
        const data = {
            "id": dataEdit?.data_pengajuan?.id,
            "swakelola_id": dataEdit?.data_pengajuan?.swakelola_id || '',
            "judul": judul || '',
            "nama_pihak3": nama_pihak3 || [],
            "related_list": related_list || [],
            "show_create": "",
            "show_creator": "",
            "fungsi": fungsi || '',
            "status_swakelola": "",
            "show_start": "",
            "show_end": "",
            "start_date": start_date || '',
            "end_date": end_date || '',
            "nama_PIC": '',
            "nama_section": '',
            "nama_persetujuan": '',
            "owner_est": owner_est || '0',
            "nilai_kontrak_terbaru": '0',
            "totalPlan": '0',
            "totalReal": '0',
            "presentasePlanReal": '0',
            "pic": pic || '',
            "section_manager": section_manager || '',
            "persetujuan_oleh": persetujuan_oleh || '',
            "plan_1": null,
            "plan_2": null,
            "plan_3": null,
            "plan_4": null,
            "plan_5": null,
            "plan_6": null,
            "plan_7": null,
            "plan_8": null,
            "plan_9": null,
            "plan_10": null,
            "plan_11": null,
            "plan_12": null,
            "real_1": null,
            "real_2": null,
            "real_3": null,
            "real_4": null,
            "real_5": null,
            "real_6": null,
            "real_7": null,
            "real_8": null,
            "real_9": null,
            "real_10": null,
            "real_11": null,
            "real_12": null,
            "dok1_kak": dok1_kak || '',
            "dok2_owner_est": dok2_owner_est || '',
            "dok3_purchase_req": dok3_purchase_req || '',
            "dok4_pakta_int": dok4_pakta_int || '',
            "dok5_justifikasi": dok5_justifikasi || '',
            "dok6_lainnya": dok6_lainnya
        }
        const val = validation(data);
        if (val.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update Swakelola?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postEdit(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageCompareDate = '';
        // if (data?.judul === '' ||
        //     data?.nama_pihak3?.length === 0 ||
        //     data?.pic === '' ||
        //     data?.fungsi === '' ||
        //     data?.section_manager === '' ||
        //     data?.persetujuan_oleh === '' ||
        //     data?.related_list?.length === 0 ||
        //     data?.owner_est === '' ||
        //     data?.dok1_kak === '' ||
        //     data?.dok2_owner_est === '' ||
        //     data?.dok3_purchase_req === '' ||
        //     data?.dok4_pakta_int === '' ||
        //     data?.dok5_justifikasi === ''
        // ) {
        //     isValid = false;
        //     message = "Lengkapi semua data"
        // }
        // console.log(data)
        if (data?.judul === '') {
            isValid = false;
            message = [...message, "Judul"]
        }
        if (data?.nama_pihak3?.length <= 0) {
            isValid = false;
            message = [...message, "Pihak ke III"]
        }
        if (data?.pic === '') {
            isValid = false;
            message = [...message, "PIC"]
        }
        if (data?.fungsi === '') {
            isValid = false;
            message = [...message, "Fungsi"]
        }
        if (data?.section_manager === '') {
            isValid = false;
            message = [...message, "Manager"]
        }
        if (data?.persetujuan_oleh === '') {
            isValid = false;
            message = [...message, "Persetujuan"]
        }
        if (data?.related_list?.length <= 0) {
            isValid = false;
            message = [...message, "Proyek Berkaitan"]
        }
        if (data?.owner_est === '0' || data?.owner_est === '') {
            isValid = false;
            message = [...message, "Owner Estimate"]
        }
        if (data?.dok1_kak === '') {
            isValid = false;
            message = [...message, "Dok. Kerangka Acu."]
        }
        if (data?.dok2_owner_est === '') {
            isValid = false;
            message = [...message, "Dok. Ownet Est."]
        }
        if (data?.dok3_purchase_req === '') {
            isValid = false;
            message = [...message, "Dok. Purchase Req."]
        }
        if (data?.dok4_pakta_int === '') {
            isValid = false;
            message = [...message, "Dok. Pakta Int."]
        }
        if (data?.dok5_justifikasi === '') {
            isValid = false;
            message = [...message, "Dok. Justifikasi"]
        }
        if (isValid) {
            if (data?.start_date === '' || data?.end_date === '') {
                isValid = false;
                message = [...message, "Periode proyek"]
            } else {
                if (moment(data?.end_date).isBefore(data?.start_date)) {
                    isValid = false;
                    messageCompareDate = "Masa berlaku salah";
                }
            }
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak boleh kosong" : "") + (message.length > 0 && messageCompareDate ? ' dan ' : '') + messageCompareDate
        }
    }

    return <edtSwaContext.Provider value={{
        edtSwaState: {
            dataEdit,
            dataSection,
            loadingGetEdit,
            fungsi,
            section_manager,
            judul,
            pic,
            nama_pihak3,
            nama_pihak3_temp,
            persetujuan_oleh,
            related_list,
            related_list_name,
            related_list_sel,
            owner_est,
            start_date,
            end_date,
            dok1_kak,
            dok2_owner_est,
            dok3_purchase_req,
            dok4_pakta_int,
            dok5_justifikasi,
            dok6_lainnya
        },
        edtSwaAction: {
            btnBackClick,
            getDataEdit,
            fChange,
            setSection_manager,
            setJudul,
            setPic,
            setNama_pihak3_temp,
            addPihak3,
            deletePihak3,
            setPersetujuan_oleh,
            setRelated_list_sel,
            setRelated_list_name,
            addRelatedList,
            deleteRelatedList,
            setOwner_est,
            setStart_date,
            setEnd_date,
            uploadFile,
            setDok1_kak,
            setDok2_owner_est,
            setDok3_purchase_req,
            setDok4_pakta_int,
            setDok5_justifikasi,
            setDok6_lainnya,
            addSwakelola,
            editSwakelola,
            onFileUploaded
        }
    }}>
        {children}
    </edtSwaContext.Provider>
}
export default function useEditingSwakelola() {
    return useContext(edtSwaContext)
}