import React, { useEffect, useRef, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import { CustomPopover, Modal, RadioGrp, StepperInActive, ExportPdf } from '../../components';
import './profile.scss';
import { pathFile, pathImage, pathProfile } from '../../app.config';
import ModalProfileEdit from './modalProfileEdit';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ModalPendidikan from './modalPendidikan';
import ModalInternalExp from './modalInternalExp';
import ModalExternalExp from './modalExternalExp';
import ModalAchievement from './modalAchievement';
import ModalProfileOverview from './modalProfileOverview';
import ModalExpertise from './modalExpertise';
import ModalContactOverview from './modalContactOverview';
import { status_pegawai, status_pegawai_solid } from '../../components/utility/utility';
import ModalLibrary from './modalLibrary';
import moment from 'moment';

function Profile(props) {
  const [state, dispatch, action] = useGlobalState();
  const refEx = useRef();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      <i className="fas fa-ellipsis-v"></i>
      {children}
    </a>
  ));

  useEffect(() => {
    if (state.dataUser?.id) {
      getData()
    }
  }, [state.dataUser])

  function getData() {
    action.getData(`/api/people-hrd/view?id=${state.dataUser?.id}`, {}, null, null, true);
  }
  function onRenderPendidikan(e) {
    return (
      <>
        <p><b>{e.degree}</b></p>
        <p>{e.college_name}</p>
        <p>{e.major}</p>
        <p>{e.tahun_lulus}</p>
      </>
    )
  }
  function onSave(data) {
    const dataKirim = {
      "id": state.data_user_profile.id,
      "employee_id": data.employee_id,
      "join_date": data.join_date,
      "linked_in": data.linked_in,
      "facebook": data.facebook,
      "handphone": data.handphone,
      "twitter": data.twitter,
      "instagram": data.instagram,
      "blog": data.blog,
      "photo_location": data.photo_location,
      "photo_link": data.photo_link,
      "date_of_birth": data.date_of_birth,
      "type": data.type,
      "user_id": state.data_user_profile.user_id,
      "status_profil": data.status_profil,
      "end_date": data.end_date,
      "position_id": data.position_id,
      "gender": data.gender
    }
    const res = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-sm btn-primary mx-1',
        cancelButton: 'btn btn-sm btn-secondary mx-1'
      },
      buttonsStyling: false
    }).fire({
      html:
        '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
        '<strong>Update Profile?</strong>',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //console.log('semua data', state)
        action.postData(
          {
            url: "/api/people-hrd/update-profile",
            data: dataKirim,
            withFile: false,
          }
        ).then((resp) => {
          if (resp.code === '00') {
            action.resetForm();
            action.toggleModal('profileEdit');
            getData();
          }
        })
        return true;
      } else {
        return true;
      }
    })
    // console.log(dataKirim)
  }
  return (
    <>

      {/* Profile Edit */}
      <Modal
        name="profileEdit"
        baseColor={true}
        size="60%"
      >
        <ModalProfileEdit id={state.data_user_profile?.user_id} onSave={onSave} onClose={() => action.toggleModal('profileEdit')} />
      </Modal>

      {/* Edit Pendidikan */}
      <Modal
        name="editPendidikan"
        baseColor={true}
        size="50%"
      >
        <ModalPendidikan data={state.data_user_pendidikan} onSave={getData} onClose={() => action.toggleModal('editPendidikan')} />
      </Modal>

      {/* Edit In Experience */}
      <Modal
        name="insideExpertise"
        baseColor={true}
        size="50%"
      >
        <ModalInternalExp data={state.data_user_internal_exp} onSave={getData} onClose={() => action.toggleModal('insideExpertise')} />
      </Modal>

      {/* Edit Ext Experience */}
      <Modal
        name="outsideExpertise"
        baseColor={true}
        size="50%"
      >
        <ModalExternalExp data={state.data_user_external_exp} onSave={getData} onClose={() => action.toggleModal('outsideExpertise')} />
      </Modal>

      {/* Add Achievement */}
      <Modal
        name="achievement"
        baseColor={true}
        size="50%"
      >
        <ModalAchievement data={state.data_user_achievement} onFinish={getData} />
      </Modal>
      <Modal
        name="modalLibrary"
        baseColor={true}
        size="50%"
      >
        <ModalLibrary data={state.data_user_library} onFinish={getData} />
      </Modal>

      <Modal
        name="expertiseEdit"
        baseColor={true}
        size="50%"
      >
        <ModalExpertise data={state.data_user_expertise} onSave={getData} />
      </Modal>
      <div className="mx-5" ref={refEx}>
        <Card
          className="p-0 customShadow mb-3"
        >
          <Card.Body className="p-5 text-center">
            <div className="text-right">
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link className="dropdown-item" to={`/profile-edit/${state.data_user_profile?.user_id}`}>
                    <span className="align-items-center"><i className="fa fa-pencil-alt icon-nm text-primary mx-2"></i>Edit</span>
                  </Link>
                  <div className="dropdown-item" role="button">
                    <ExportPdf
                      targetRef={refEx}
                      scale={0.8}
                      fileName={`Profile - ${state.dataUser?.name} - ${moment(new Date()).format('YYYY-MM-DD')}`}
                    >
                      <span>
                        Export PDF
                      </span>
                    </ExportPdf>
                  </div>
                  {/* <span className="dropdown-item align-items-center text-primary" role="button" onClick={() => action.toggleModal('profileEdit')}><i className="fa fa-pencil-alt icon-nm text-primary mx-2"></i>Edit</span> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              <div className="symbol symbol-120 mr-3 customShadow">
                <img alt="Pic" src={state.data_user_profile?.photo_location}
                  onError={(e) => { e.target.onerror = null; e.target.src = "/media/svg/icons/General/User.svg" }} />
                {/* <i className="symbol-badge bg-success"></i> */}
              </div>
            </div>
            <p className="mt-5"><b>{state.data_user_profile?.name}</b></p>
            <div className="d-inline-flex">
              <span className="px-3">{status_pegawai_solid(state.data_user_profile?.status_profil)}</span>
              {
                state.data_user_profile?.gender === "Laki" && (
                  <div className="symbol symbol-25 symbol-circle symbol-primary my-auto">
                    <span className="symbol-label ">
                      <i className="fas fa-mars icon-nm text-white"></i>
                    </span>
                  </div>
                )
              }
              {
                state.data_user_profile?.gender === "Perempuan" && (
                  <div className="symbol symbol-25 symbol-circle my-auto">
                    <span className="symbol-label bg-pink">
                      <i className="fas fa-venus icon-nm text-white"></i>
                    </span>
                  </div>
                )
              }
            </div>
            <p className="mt-3">
              <span className="border-right px-2 mx-1">{state.data_user_profile?.date_of_birth_form}/{state.data_user_profile?.usia && state.data_user_profile?.usia + " Tahun"}</span>
              <span className="border-right px-2 mx-1">{state.data_user_profile?.employee_id && `No. Peg ${state.data_user_profile?.employee_id}`}</span>
              <span className="mx-1">{state.data_user_profile?.position_name}</span>
            </p>
            <p>
              <span className="border-right px-2 mx-1">{state.data_user_profile?.section_name}/{state.data_user_profile?.function_name}</span>
            </p>
            <div className="mb-3">
              <div className="d-inline-flex">
                <span className="p-0 rounded svg-icon svg-icon-lg svg-icon-primary">
                  <SVG src="/media/svg/icons/Clothes/Briefcase.svg" />
                </span>
                <span className="my-auto"> Bergabung pada {state.data_user_profile?.join_date_form} di Pertamina / Total {state.data_user_profile?.tahun_pengalaman && `${state.data_user_profile?.tahun_pengalaman} Tahun `}</span>
              </div>
            </div>
            <div className="d-inline-flex">
              {
                state.data_user_profile?.email && (
                  <CustomPopover
                    contentPopup={{
                      content: <>{state.data_user_profile?.email}</>
                    }}
                  >
                    <div className="symbol symbol-25 symbol-circle my-auto mr-3">
                      <span className="symbol-label bg-purple">
                        <i className="far fa-envelope icon-nm text-white"></i>
                      </span>
                    </div>
                  </CustomPopover>
                )
              }
              {
                state.data_user_profile?.handphone && (
                  <div>
                    <CustomPopover
                      contentPopup={{
                        content: <>{state.data_user_profile?.handphone}</>
                      }}
                    >
                      <i className="flaticon-whatsapp text-success icon-xl mr-3"></i>
                    </CustomPopover>
                  </div>
                )
              }
              {
                state.data_user_profile?.linked_in && (
                  <CustomPopover
                    contentPopup={{
                      content: <a target="_blank" href={state.data_user_profile?.linked_in}>{state.data_user_profile?.linked_in}</a>
                    }}
                  >
                    <div className="symbol symbol-25 symbol-circle my-auto mr-3">
                      <span className="symbol-label style-linkedin">
                        <i className="fab fa-linkedin-in icon-nm text-white"></i>
                      </span>
                    </div>
                  </CustomPopover>
                )
              }
              {
                state.data_user_profile?.facebook && (
                  <CustomPopover
                    contentPopup={{
                      content: <a target="_blank" href={state.data_user_profile?.facebook}>{state.data_user_profile?.facebook}</a>
                    }}
                  >
                    <div className="symbol symbol-25 symbol-circle my-auto mr-3">
                      <span className="symbol-label style-facebook">
                        <i className="fab fa-facebook-f icon-nm text-white"></i>
                      </span>
                    </div>
                  </CustomPopover>
                )
              }
              {
                state.data_user_profile?.twitter && (
                  <CustomPopover
                    contentPopup={{
                      content: <a target="_blank" href={state.data_user_profile?.twitter}>{state.data_user_profile?.twitter}</a>
                    }}
                  >
                    <div className="symbol symbol-25 symbol-circle symbol-primary my-auto mr-3">
                      <span className="symbol-label">
                        <i className="fab fa-twitter icon-nm text-white"></i>
                      </span>
                    </div>
                  </CustomPopover>
                )
              }
              {
                state.data_user_profile?.blog && (
                  <CustomPopover
                    contentPopup={{
                      content: <a target="_blank" href={state.data_user_profile?.blog}>{state.data_user_profile?.blog}</a>
                    }}
                  >
                    <div className="symbol symbol-25 symbol-circle my-auto mr-3">
                      <span className="symbol-label style-link">
                        <i className="fas fa-link icon-nm text-white"></i>
                      </span>
                    </div>
                  </CustomPopover>
                )
              }
              {
                state.data_user_profile?.instagram && (
                  <CustomPopover
                    contentPopup={{
                      content: <a target="_blank" href={state.data_user_profile?.instagram}>{state.data_user_profile?.instagram}</a>
                    }}
                  >
                    <div className="symbol symbol-25 symbol-circle my-auto mr-3">
                      <span className="symbol-label style-intstagram">
                        <i className="fab fa-instagram icon-nm text-white"></i>
                      </span>
                    </div>
                  </CustomPopover>
                )
              }
            </div>
            {/* <div>
              <button className="btn btn-sm btn-primary mx-1" onClick={() => action.toggleModal("profileOverview")}>Profile Overview</button>
              <button className="btn btn-sm btn-success mx-1" onClick={() => action.toggleModal("contactOverview")}>Contact Info</button>
            </div> */}
          </Card.Body>
        </Card>
        <Card
          className="p-0 customShadow"
        >
          <Card.Body className="p-5">
            <div className="d-flex justify-content-between">
              <span><b>Pendidikan</b></span>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-pendidikan">
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <span className="dropdown-item align-items-center text-primary" role="button" onClick={() => action.toggleModal('editPendidikan')}><i className="fa fa-pencil-alt icon-nm text-primary mx-2"></i>Edit</span>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <StepperInActive data={state.data_user_pendidikan} onRenderLabel={onRenderPendidikan} bgIconColor="#fff4de" iconColor="#FFA800" icon="fas fa-graduation-cap" />

          </Card.Body>
        </Card>
        <div className="row mt-5">
          <div className="col-md-6">
            <Card
              className="p-0 customShadow h-100"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Pengalaman di dalam Pertamina</b></span>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-pendidikan">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <span className="dropdown-item align-items-center text-primary" role="button" onClick={() => action.toggleModal('insideExpertise')}><i className="fa fa-pencil-alt icon-nm text-primary mx-2"></i>Edit</span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {
                  [...(state.data_user_internal_exp || [])].map((d, i) => (
                    <div key={i}>
                      <div className="d-inline-flex m-3">
                        <div className="symbol symbol-circle">
                          <span className="symbol-label bg-light-primary font-size-h5">
                            <span className="p-0 rounded svg-icon svg-icon-lg svg-icon-primary">
                              <SVG src="/media/svg/icons/Clothes/Briefcase.svg" />
                            </span>
                            {/* <i className="fas fa-graduation-cap icon-lg text-warning"></i> */}
                          </span>
                        </div>
                        <div className="ml-5">
                          <span className="d-block">{d.nama_pt}</span>
                          <span className="d-block">{d.position}</span>
                          <span className="d-block">{d.year_start} - {d.year_end}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6">
            <Card
              className="p-0 customShadow h-100"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Pengalaman di luar Pertamina</b></span>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-pendidikan">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <span className="dropdown-item align-items-center text-primary" role="button" onClick={() => action.toggleModal('outsideExpertise')}><i className="fa fa-pencil-alt icon-nm text-primary mx-2"></i>Edit</span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  {
                    [...(state.data_user_external_exp || [])].map((d, i) => (
                      <div key={i}>
                        <div className="d-inline-flex m-3">
                          <div className="symbol symbol-circle">
                            <span className="symbol-label bg-light-primary font-size-h5">
                              <span className="p-0 rounded svg-icon svg-icon-lg svg-icon-primary">
                                <SVG src="/media/svg/icons/Clothes/Briefcase.svg" />
                              </span>
                              {/* <i className="fas fa-graduation-cap icon-lg text-warning"></i> */}
                            </span>
                          </div>
                          <div className="ml-5">
                            <span className="d-block">{d.company_name}</span>
                            <span className="d-block">{d.position}</span>
                            <span className="d-block">{d.year_start} - {d.year_end}</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <Card
              className="p-0 customShadow h-100"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Expertise</b></span>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-pendidikan">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <span className="dropdown-item align-items-center text-primary" role="button" onClick={() => action.toggleModal('expertiseEdit')}><i className="fa fa-pencil-alt icon-nm text-primary mx-2"></i>Edit</span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {
                  [...(state.data_user_expertise || [])].map((d, i) => (
                    <div key={i}>
                      <div className="d-inline-flex m-3">
                        <div className="symbol symbol-circle">
                          <span className="symbol-label bg-expertise font-size-h5">
                            <span className="p-0 rounded svg-icon svg-icon-lg svg-icon-expertise">
                              <SVG src="/media/svg/icons/Clothes/Crown.svg" />
                            </span>
                            {/* <i className="fas fa-graduation-cap icon-lg text-warning"></i> */}
                          </span>
                        </div>
                        <div className="ml-5">
                          <span className="d-block">{d.technical_competency}</span>
                          <span className="d-block">{d.skill_group}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6">
            <Card
              className="p-0 customShadow h-100"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Intellectual Property</b></span>
                </div>
                {
                  [...(state.data_user_intel_property || [])].map((d, i) => (
                    <div key={i}>
                      <div className="d-inline-flex m-3">
                        <div className="symbol symbol-circle">
                          <span className="symbol-label bg-patent font-size-h5">
                            <span className="p-0 rounded svg-icon svg-icon-lg svg-icon-patent">
                              <SVG src="/media/svg/icons/Design/Sketch.svg" />
                            </span>
                            {/* <i className="fas fa-graduation-cap icon-lg text-warning"></i> */}
                          </span>
                        </div>
                        <div className="ml-5">
                          <Link to={`/people/patent-view/${d?.id}`} target={'_blank'}>
                            <span className="d-block">
                              {
                                d.name
                              }
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <Card
              className="p-0 customShadow h-100"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Ongoing Projects</b></span>
                </div>
                {
                  [...(state.data_user_ongoing_projects || [])].map((d, i) => (
                    <div key={i}>
                      <div className="d-inline-flex m-3">
                        <div className="symbol symbol-circle">
                          <span className="symbol-label bg-light-danger font-size-h5">
                            <span className="p-0 rounded svg-icon svg-icon-lg svg-icon-danger">
                              <SVG src="/media/svg/icons/Design/Pixels.svg" />
                            </span>
                            {/* <i className="fas fa-graduation-cap icon-lg text-warning"></i> */}
                          </span>
                        </div>
                        <div className="ml-5">
                          <Link target="_blank" to={`/OngoingResearch/view/${d.data_id}`}>
                            <span className="d-block">{d.title}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6">
            <Card
              className="p-0 customShadow h-100"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Previous Project</b></span>
                </div>
                {
                  [...(state.data_user_previous_projects || [])].map((d, i) => (
                    <div key={i}>
                      <div className="d-inline-flex m-3">
                        <div className="symbol symbol-circle">
                          <span className="symbol-label bg-light-danger font-size-h5">
                            <span className="p-0 rounded svg-icon svg-icon-lg svg-icon-danger">
                              <SVG src="/media/svg/icons/Design/Pixels.svg" />
                            </span>
                            {/* <i className="fas fa-graduation-cap icon-lg text-warning"></i> */}
                          </span>
                        </div>
                        <div className="ml-5">
                          <Link target="_blank" to={`/OngoingResearch/view/${d.data_id}`}>
                            <span className="d-block">{d.title}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 mb-5">
            <Card
              className="p-0 customShadow"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Library</b></span>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-pendidikan">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Link className="dropdown-item" to={`/people-achievement/add`}>
                        <i className="fa fa-plus icon-nm mx-1 align-self-center"></i>Add
                      </Link> */}
                      <span className="dropdown-item align-items-center text-primary" role="button" onClick={() => action.toggleModal('modalLibrary')}><i className="fa fa-plus icon-nm text-primary mx-2"></i>Library</span>
                      {/* <Dropdown.Item eventKey="2"><i className="fa fa-pencil-alt icon-nm mx-1 align-self-center"></i>Edit</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {
                  [...(state.data_user_library || [])].map((d, i) => (
                    <div key={i}>
                      <div className="d-inline-flex m-3">
                        <img width={50} height={80} className="border-none" src={d.cover_location}
                          onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }}
                        />
                        <div className="ml-5">
                          <Link to={`/library-detail-hrd/${d?.id}`} target="_blank">
                            <span className="d-block">{d.title}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6 mb-5">
            <Card
              className="p-0 customShadow"
            >
              <Card.Body className="p-5">
                <div className="d-flex justify-content-between">
                  <span><b>Achievement</b></span>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-pendidikan">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Link className="dropdown-item" to={`/people-achievement/add`}>
                        <i className="fa fa-plus icon-nm mx-1 align-self-center"></i>Add
                      </Link> */}
                      <span className="dropdown-item align-items-center text-primary" role="button" onClick={() => action.toggleModal('achievement')}><i className="fa fa-plus icon-nm text-primary mx-2"></i>Achievement</span>
                      {/* <Dropdown.Item eventKey="2"><i className="fa fa-pencil-alt icon-nm mx-1 align-self-center"></i>Edit</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {
                  [...(state.data_user_achievement || [])].map((d, i) => (
                    <div key={i}>
                      <div className="d-inline-flex m-3">
                        <img width={50} height={80} className="border-none" src={d.photo_location}
                          onError={(e) => { e.target.onerror = null; e.target.src = "/media/svg/icons/General/User.svg" }}
                        />
                        <div className="ml-5">
                          <Link className="mb-3" to={`/People/achievement-detail-hrd/${d?.id}`} target={'_blank'}>
                            <span className="d-block mb-3">{d.name}</span>
                          </Link>
                          {
                            d.status_approval === 1 && (
                              <span className="py-1 px-3 bg-light-secondary rounded mt-3 text-dark">Review</span>
                            )
                          }
                          {
                            d.status_approval === 2 && (
                              <span className="py-1 px-3 bg-light-success rounded mt-3 text-success">Approve</span>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;