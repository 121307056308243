import React, { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useFetch from "../../../modules/useFetch";
import Swal from "sweetalert2";
import { toString } from "lodash";

const partApprovalContext = createContext({
    partAppstate: {},
    partappAction: {}
});
const optRadioSa = [
    { label: '6', value: '6', hint: 'Memiliki kapabilitas sebagai Offtake/User' },
    { label: '5', value: '5', hint: 'Memiliki akses ke pasar' },
    { label: '4', value: '4', hint: 'Memiliki bahan baku' },
    { label: '3', value: '3', hint: 'Bersedia sharing cost penelitian' },
    { label: '2', value: '2', hint: 'Memiliki teknologi' },
]
const approvalOption = [
    { label: 'Keputusan disetujui dan dilanjutkan', value: 'Approved' },
    { label: 'Kerjasama di evaluasi kembali', value: 'Reevaluate' },
    { label: 'Kerjasama tidak disetujui dan dilanjutkan', value: 'Rejected' },
]
export function PartAppProvider({ children }) {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [data_detail, setData_detail] = useState({});
    const [data_page, setData_page] = useState({});
    const { doGet, doPost } = useFetch();
    const [status_approval, setStatus_approval] = useState("");
    const [status_approval_note, setStatus_approval_note] = useState("");
    const [reviewer, setReviewer] = useState("");
    const history = useHistory();

    const GetDataDetail = async () => {
        setLoading(true)
        try {
            const resp = await doGet({
                url: `api/ext-partnership/view/${id}`,
            });
            if (resp?.code === '00') {
                const kerjasama_rk = [...(resp?.data?.header_kerjasama?.kerjasama_rkaps || [])].map(d => (
                    {
                        "id": d?.id,
                        "kerjasama_id": d?.kerjasama_id,
                        "research_id": toString(d?.research_id),
                        "show_research": d?.title
                    }))
                setData_detail({
                    ...resp.data?.header_kerjasama,
                    reviewer: resp.data?.header_kerjasama?.reviewer || '',
                    kerjasama_partners: [...(resp?.data?.header_kerjasama?.kerjasama_partners || [])].map(d => {
                        const bantu = (d?.score_sa === 1) ? true : false;
                        const sa1 = (d?.score_sa1 === 1 ? "a" : d?.score_sa1 === 2 ? "b" : d?.score_sa1 === 3 ? "c" : null);
                        return {
                            ...d,
                            score_sa: bantu ? sa1 : toString(d?.score_sa)
                        }
                    }),
                    kerjasama_rkaps: kerjasama_rk,
                    partnership_scoring: {
                        total_pc: resp?.data?.total_pc,
                        total_pc_tpta: resp?.data?.total_pc_tpta,
                        total_sa: resp?.data?.total_sa,
                        total_sa_cs_pc_tpta: resp?.data?.total_sa_cs_pc_tpta,
                        total_tpta: resp?.data?.total_tpta,
                        tpta: resp?.data?.tpta,
                        translation_scoring: resp?.data?.translation_scoring
                    }
                });
                setData_page(resp.data_page);
                setStatus_approval(resp.data?.header_kerjasama?.status_approval);
                setStatus_approval_note(resp.data?.header_kerjasama?.status_approval_note);
                setReviewer(resp.data?.header_kerjasama?.reviewer || '');
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoading(false);
    }
    const validation = () => {
        var isValid = true;
        var message = [];
        if (status_approval === '') {
            isValid = false;
            message = [...message, 'Status Approval'];
        }
        // if (status_approval_note === "") {
        //     isValid = false;
        //     message = [...message, 'Catatan Approval'];
        // }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }
    const confirm = () => {
        const val = validation();
        if (!val?.isValid) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: `btn btn-sm btn-primary mx-1`,
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>` +
                `<strong>Apa anda yakin?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                doNext();
                return true;
            } else {
                return true;
            }
        })
    }
    const doNext = async function () {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-partnership/approval-kerjasama?id=${id}&status_approval=${status_approval}&status_approval_note=${status_approval_note}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    // GetDataDetail();
                    history.goBack();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const confirmReviewer = () => {
        if (reviewer === "" || reviewer === undefined || reviewer === null) {
            return Swal.fire({
                title: 'Warning',
                text: 'Reviewer tidak boleh kosong',
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: `btn btn-sm btn-primary mx-1`,
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>` +
                `<strong>Apa anda yakin?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                doNextReviewer();
                return true;
            } else {
                return true;
            }
        })
    }
    const doNextReviewer = async function () {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-partnership/reviewer-note?id=${id}&reviewer=${reviewer}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    // GetDataDetail();
                    history.push('/admin/partnership-approval');
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    useEffect(() => {
        GetDataDetail();
    }, [])
    return <partApprovalContext.Provider
        value={{
            partAppstate: {
                data_detail,
                data_page,
                optRadioSa,
                approvalOption,
                loading,
                status_approval,
                status_approval_note,
                reviewer
            },
            partappAction: {
                confirm,
                confirmReviewer,
                setStatus_approval,
                setStatus_approval_note,
                setReviewer
            }
        }}
    >{children}</partApprovalContext.Provider>
}

export default function usePartApproval() {
    return useContext(partApprovalContext);
}