import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useGlobalState } from "../../contexts/useGlobalState";
require("./asset/style.css");
// require("../../../index.light.scss");

export default function () {
  const [state, , actions] = useGlobalState();
  const [showPass, setShowPass] = useState(false);
  return (
    <>
      <div className="newloginPage">

        <div className="login-card">
          <div className="login-card-body">
            <form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault(e);
                actions.doLogin();
              }}
            >
              <div className="row">
                <div className="col-md">
                  <div className=" text-center" style={{ width: "100%" }}>
                    <img
                      className="logospiirit"
                      src="/loginasset/logospiirit.png"
                    />
                  </div>
                  <div
                    style={{ width: "100%", wordWrap: "break-word" }}
                    className="title"
                  >
                    Sistem Pengelolaan Inovasi dan Informasi Riset & Teknologi
                    Pertamina
                  </div>
                </div>
              </div>

              <div className="row formLogin_">
                <div className="col text-center" >
                  <h3 style={{ fontWeight: "bold", color: "#5867DD" }}>Masuk</h3>
                  <span className="title-text">Masukkan Email dan Password</span>
                </div>
              </div>
              <div className="row text-center" style={{ marginTop: "30px" }}>
                <div className="col-12">
                  <input
                    onChange={(e) => actions.setForm("Email", e.target.value)}
                    value={state.formData.Email}
                    id="email"
                    className="form-control form-control-sm col-10 form-login"
                    name="email"
                    placeholder="Username"
                    required
                    autofocus
                  />
                </div>
              </div>
              <div className="row text-center" style={{ marginTop: "10px" }}>
                <div className="col-12">
                  <input
                    onChange={(e) => actions.setForm("Password", e.target.value)}
                    value={state.formData.Password}
                    autocomplete="off"
                    id="password"
                    type={showPass ? 'text' : 'password'}
                    className="form-control form-control-sm col-10 form-login"
                    name="password"
                    placeholder="******"
                    required
                  />
                  <span className="showpass" onClick={() => setShowPass(!showPass)}><i className={showPass === true ? 'far fa-eye-slash icon-nm' : 'far fa-eye icon-nm'}></i></span>
                </div>
              </div>
              <div
                className="row text-center"
                style={{ marginTop: "25px", marginBottom: "20px" }}
              >
                <div className="col-md-12">
                  <button
                    type="submit"
                    disabled={state.loadingLogin}
                    className="btn btn-sm btn-primary"
                  >
                    {state.loadingLogin ? (
                      <>
                        <Spinner
                          animation="grow"
                          variant="light"
                          size="sm"
                          style={{ marginRight: 5 }}
                        />{" "}
                        Login
                      </>
                    ) : (
                      <>
                        <i className="la la-sign-in"></i>
                        Login
                      </>
                    )}
                  </button>
                  <span>&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <a
                    href="#"
                    className="btn bg-white btn-sm btn-outline-primary"
                  >
                    <i className="la la-pencil"></i> Create Research
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="design-login">
          <div className="none-on-devices">
            <img className="logopertamina" src="/loginasset/logopertamina.png" />
          </div>
        </div>
      </div>
      <footer className="footer text-lg-right text-md-right text-left mr-lg-5 mr-md-5 mr-0 px-lg-5 px-md-5 px-1"
      >&copy; Copyright PT Pertamina(Persero) 2019. All Right Reserved</footer>
    </>
  );
}
