import React, { memo } from 'react';
import { FormResearch, FileUploadDx, RadioGrp } from '../../../../components';
import moment from 'moment';
import useCrudEquipment from '../../useCrudEquipment';
import { fileExtensions } from '../../../../components/utility/utility';
import { SelectBox, TagBox } from 'devextreme-react';

export default memo(function FormAddEdit(props) {
    const { ceqAction, ceqState } = useCrudEquipment();
    return (
        <div>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: false,
                        childs: [
                            {
                                type: "text",
                                label: <span>Nomor Asset<span className="text-danger"> *</span></span>,
                                placeholder: "Nomor Asset",
                                value: ceqState.nomor || '',
                                onChange: (val) => ceqAction.setNomor(val)
                            },
                            {
                                type: "text",
                                label: <span>Nomor Seri<span className="text-danger"> *</span></span>,
                                placeholder: "Nomor Seri",
                                value: ceqState.nomorSeri || '',
                                onChange: (val) => ceqAction.setNomorSeri(val)
                            },
                            {
                                type: "text",
                                label: <span>Nama Alat<span className="text-danger"> *</span></span>,
                                placeholder: "Nama Alat",
                                value: ceqState.nama || '',
                                onChange: (val) => ceqAction.setNama(val)
                            },
                            {
                                type: "select",
                                label: <span>Tipe Alat<span className="text-danger"> *</span></span>,
                                options: [{ name: '- Pilih tipe alat -', value: '' }, ...[...(ceqState.data_edit?.data_tipe || [])].map(d => { return { name: d?.title, value: d?.param_id } })],
                                value: ceqState.equipment_type || '',
                                onChange: (val) => ceqAction.setEquipment_type(val)
                            },
                            {
                                type: "text",
                                label: <span>Merk<span className="text-danger"> *</span></span>,
                                placeholder: "Merk",
                                value: ceqState.merk || '',
                                onChange: (val) => ceqAction.setMerk(val)
                            },
                            {
                                type: "textarea",
                                label: <span>Deskripsi Alat (Kegunaan)<span className="text-danger"> *</span></span>,
                                placeholder: "Deskripsi..",
                                value: ceqState.deskripsi || '',
                                onChange: (val) => ceqAction.setDeskripsi(val)
                            },
                            {
                                type: "textarea",
                                label: "Utilitas Pendukung",
                                placeholder: "Utilitas Pendukung",
                                value: ceqState.utilitas || '',
                                onChange: (val) => ceqAction.setUtilitas(val)
                            },
                            {
                                type: "textarea",
                                label: "Supporting Equipment",
                                placeholder: "Supporting Equipment",
                                value: ceqState.supporting || '',
                                onChange: (val) => ceqAction.setSupporting(val)
                            },

                        ]
                    },
                    {
                        rows: 2,
                        header: false,
                        childs: [
                            {
                                type: "custom",
                                label: "Daya Listrik (Watt)",
                                component: (
                                    <div className="input-group input-group-sm mb-5">
                                        <input type="number" min={0} className="form-control" placeholder="Daya Listrik (Watt)"
                                            value={ceqState.daya_listrik || ''}
                                            onChange={(e) => ceqAction.setDaya_listrik(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">(Watt)</span>
                                        </div>
                                    </div>
                                )
                            },
                            // {
                            //     type: "text",
                            //     label: "Daya Listrik (Watt)",
                            //     placeholder: "Daya Listrik (Watt)",
                            //     value: ceqState.daya_listrik,
                            //     onChange: (val) => ceqAction.daya_listrik(val)
                            // },
                            {
                                type: "custom",
                                label: <span>Tahun Pembelian<span className="text-danger"> *</span></span>,
                                component: (
                                    <input type='number' placeholder='Minimum tahun pembelian 1990' min={1990} max={moment(new Date()).format('YYYY')} className="form-control form-control-sm mb-5"
                                        value={ceqState.tahun_pembelian || ''}
                                        onChange={(e) => ceqAction.setTahun_pembelian(e.target.value)}
                                    ></input>
                                )
                            }
                            ,
                            {
                                type: "custom",
                                label: <span>Harga Alat (USD)<span className="text-danger"> *</span></span>,
                                component: (
                                    <div className="input-group input-group-sm mb-5">
                                        <input type="number" min={0} className="form-control" placeholder="Harga Alat"
                                            value={ceqState.harga_alat || ''}
                                            onChange={(e) => ceqAction.setHarga_alat(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">$ (USD)</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>PC<span className="text-danger"> *</span></span>,
                                placeholder: "",
                                component: (
                                    <div className='mb-3'>
                                        <RadioGrp
                                            modeRow={true}
                                            onChange={(e) => ceqAction.setPC(e)}
                                            value={ceqState.pc || ''}
                                            data={[
                                                { label: 'Ada', value: '1' },
                                                { label: 'Tidak Ada', value: '0' },
                                            ]}
                                        />
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>Lingkup Akreditasi ISO 17025<span className="text-danger"> *</span></span>,
                                placeholder: "",
                                component: (
                                    <div className='mb-3'>
                                        <RadioGrp
                                            modeRow={true}
                                            onChange={(e) => ceqAction.setIso(e)}
                                            value={ceqState.iso || ''}
                                            data={[
                                                { label: 'Ya', value: '1' },
                                                { label: 'Tidak', value: '0' },
                                            ]}
                                        />
                                    </div>
                                )
                            },
                            {
                                type: "textarea",
                                label: <span>Informasi Tambahan<span className="text-danger"> *</span></span>,
                                placeholder: "Contoh : Durasi maintenance, cara penanganan jika rusak",
                                value: ceqState.information || '',
                                onChange: (val) => ceqAction.setInformation(val)
                            },
                            {
                                type: "custom",
                                label: "Dokument Terkait (Buku manual dll)",
                                component: (
                                    <div>
                                        {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                            onValueChanged={(e) => ceqAction.fileOnchangeFile(e)}
                                            showFileList={false} multiple={false}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                        </FileUploader> */}
                                        <FileUploadDx
                                            className="custom-dx-fileuploader-file-name"
                                            showFileList={true}
                                            multiple={false}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb"
                                            accept="*"
                                            onUploaded={(e) => ceqAction.onUploadedFile(e)}
                                            maxFileSize={10000000}
                                            uploadMode="instantly"
                                        />
                                        {
                                            [...(ceqState.list_dokumen || [])].map((d, i) => (
                                                <div className="d-flex flex-row justify-content-between mb-1" key={i}>
                                                    <span style={{ fontSize: '8pt' }}>{d?.file_doc_name}</span>
                                                    <div>
                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => ceqAction.deleteList_document(d)}></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>Foto Alat<span className="text-danger"> *</span></span>,
                                component: (
                                    <div>
                                        {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                            showFileList={false} multiple={false}
                                            onValueChanged={(e) => ceqAction.fileOnchangePhoto(e)}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            allowedFileExtensions={fileExtensions}
                                            accept="image/*"
                                            labelText="or Drop file hire | Max size 10 Mb" maxFileSize={10000000} uploadMode="useForm" >
                                        </FileUploader>                                         */}
                                        <FileUploadDx
                                            className="custom-dx-fileuploader-file-name"
                                            showFileList={true}
                                            multiple={false}
                                            selectButtonText="Select File"
                                            allowedFileExtensions={fileExtensions}
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb"
                                            accept="image/*"
                                            onUploaded={(e) => ceqAction.onUploadedCover(e)}
                                            maxFileSize={10000000}
                                            uploadMode="instantly"
                                        />
                                        {
                                            ceqState.file_foto && (
                                                <div className="d-flex flex-row justify-content-between mb-1">
                                                    <span style={{ fontSize: '8pt' }}>{ceqState.file_foto}</span>
                                                    <div>
                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => ceqAction.setFile_foto('')}></i>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className='border-bottom mb-3'></div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>PIC<span className="text-danger"> *</span></span>,
                                component: (
                                    <SelectBox
                                        searchEnabled={true}
                                        className='mb-5'
                                        displayExpr="name"
                                        displayValue="name"
                                        valueExpr="id"
                                        value={ceqState.penanggung_jawab}
                                        items={ceqState.data_edit?.data_users_penanggung_jawab}
                                        onValueChange={(e) => ceqAction.setPenanggung_jawab(e)} />
                                )
                            },
                            {
                                type: "custom",
                                label: <span>Operator<span className="text-danger"> *</span></span>,
                                component: (
                                    <TagBox
                                        searchEnabled={true}
                                        value={ceqState.list_pic || []}
                                        items={ceqState.data_edit?.data_users_pic}
                                        displayExpr="name"
                                        displayValue="name"
                                        valueExpr="id"
                                        className="mb-5"
                                        onValueChange={(e) => ceqAction.setList_pic(e)}
                                    />
                                )
                            },
                            {
                                type: "select",
                                label: <span>Lokasi<span className="text-danger"> *</span></span>,
                                placeholder: "Lokasi",
                                options: [{ name: '- Pilih Lokasi -', value: '' }, ...[...(ceqState.data_edit?.data_lokasi || [])].map(d => { return { name: d?.title, value: d?.param_id } })],
                                value: ceqState.equipment_loc || '',
                                onChange: (val) => ceqAction.setEquipment_loc(val)
                            },
                            {
                                type: "select",
                                label: <span>Lab<span className="text-danger"> *</span></span>,
                                placeholder: "Lab",
                                options: [{ name: '- Pilih Lab -', value: '' }, ...[...(ceqState.data_edit?.data_lab || [])].map(d => { return { name: d?.title, value: d?.param_id } })],
                                value: ceqState.equipment_lab || '',
                                onChange: (val) => ceqAction.setEquipment_lab(val)
                            },
                        ]
                    }
                ]}
            />
        </div>
    );
});