import React from 'react';
import { Modal } from 'react-bootstrap';

function ModalDiagram({ show = false, onHide = () => null }) {
    return (
        <Modal size='lg' show={show} onHide={onHide}>
            <Modal.Body>
                <img width={'100%'} src='/images/diagram_status_lab_test.png' alt='diagram status'/>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDiagram;