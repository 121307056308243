import React, { memo, useEffect, useRef, useState } from 'react'
import { FormResearch, TextEditor, RadioGrp, Modal, GridDx, FileUploadDx } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import FileUploader from 'devextreme-react/file-uploader';
import SelectLogBook from './modalSelectLogBook';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment';
import { useHistory } from 'react-router';
import { toInteger, toUpper } from 'lodash';

const filterType = [
    { text: 'Ya', value: 1 },
    { text: 'Tidak', value: 0 },
]
const column = [
    { fieldName: "date", caption: "Tanggal", width: 100 },
    { fieldName: "user_name", caption: "Create By", width: 100 },
    { fieldName: "type", caption: "Type", width: 100, headerFilterSource: filterType },
    { fieldName: "description", caption: "Deskripsi", minWidth: 200 },
    { fieldName: "way_forward", caption: "Way Forward", width: 200 },
    { fieldName: "uploaded_file", caption: "File Download", width: 100, allowSorting: false, allowFiltering: false },
]

const TabAchievement = memo(({ param }) => {
    const [typeModal, setTypeModal] = useState();
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const [date, setDate] = useState({
        month: '',
        year: ''
    })
    useEffect(() => {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-achieve-con-2/" + param?.id, {}, null, null, true).then((resp) => {
                if (resp.code === "00") {
                    const achievement = resp.data.data_view_achieve_con.find(d => d.type === "Achievement");
                    const way = resp.data.data_view_achieve_con.find(d => d.type === "Way Forward");
                    const concern = resp.data.data_view_achieve_con.find(d => d.type === "Concern");
                    const ac = (achievement?.from_logbook_research === "" ? [] : achievement?.from_logbook_research.split(','))
                    const conc = (concern?.from_logbook_research === "" ? [] : concern?.from_logbook_research.split(','))
                    // console.log('test', ac, conc);
                    getDataFromLogbook(ac, conc)

                    action.setForm('descriptionAchievement', achievement?.description)
                    action.setForm('fileAchievement', [...(achievement?.file_location ? achievement?.file_location.split('?') : [])].map(d => {
                        return {
                            "file_location": '',
                            "file_name": d,
                            "is_new": false,
                        }
                    }))
                    action.setForm('descriptionWayforward', way?.description)

                    action.setForm('descriptionConcern', concern?.description)
                    action.setForm('management_support', concern?.management_review)
                    action.setForm('fileConcern', [...(concern?.file_location ? concern?.file_location.split('?') : [])].map(d => {
                        return {
                            "file_location": '',
                            "file_name": d,
                            "is_new": false,
                        }
                    }))
                    action.setForm('achievement', achievement)
                    action.setForm('way', way)
                    action.setForm('concern', concern)
                    action.setForm('disable', allowEditing(resp.data))
                    const mont_year = moment(resp.data.data_cut_off_date + "/" + resp.data.data_year_month, "D/M/YYYY");
                    setDate({
                        month: mont_year.format("M"),
                        year: mont_year.format("YYYY")
                    })
                    action.setForm('mon', resp.data_page);
                }
            })
        }
    }, [])
    function allowEditing(data) {
        if (state.filterData.mode === "edit" &&
            data?.data_view_header?.workflow_state_id === 45) {
            if (data?.data_view_header?.is_continue === 1 &&
                data?.data_year_form === moment(new Date()).format('YYYY')) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    function getData() {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-achieve-con-2/" + param?.id).then((resp) => {
                if (resp.code === "00") {
                    const achievement = resp.data.data_view_achieve_con.find(d => d.type === "Achievement");
                    const way = resp.data.data_view_achieve_con.find(d => d.type === "Way Forward");
                    const concern = resp.data.data_view_achieve_con.find(d => d.type === "Concern");
                    const ac = (achievement?.from_logbook_research === "" ? [] : achievement?.from_logbook_research.split(','))
                    const conc = (concern?.from_logbook_research === "" ? [] : concern?.from_logbook_research.split(','))
                    getDataFromLogbook(ac, conc)

                    action.setForm('descriptionAchievement', achievement?.description)
                    action.setForm('fileAchievement', [...(achievement?.file_location === "" ? [] : achievement?.file_location.split('?'))].map(d => {
                        return {
                            "file_location": '',
                            "file_name": d,
                            "is_new": false,
                        }
                    }))
                    action.setForm('descriptionWayforward', way?.description)

                    action.setForm('descriptionConcern', concern?.description)
                    action.setForm('management_support', concern?.management_review)
                    action.setForm('fileConcern', [...(concern?.file_location === "" ? [] : concern?.file_location.split('?'))].map(d => {
                        return {
                            "file_location": '',
                            "file_name": d,
                            "is_new": false,
                        }
                    }))
                    action.setForm('achievement', achievement)
                    action.setForm('way', way)
                    action.setForm('concern', concern)
                }
            })
        }
    }
    function getDataFromLogbook(from_logbook_research_achievement, from_logbook_research_concern) {
        action.getData("/api/project-ongoing-crud/view-logbook-2/" + param?.id, {}, null, null, true).then((resp) => {
            if (resp.code === "00") {
                const ac = [...(from_logbook_research_achievement || [])].map(d => {
                    return [...(resp.data.data_view_logbook || [])].find(s => s?.id?.toString() === d)
                })
                const con = [...(from_logbook_research_concern || [])].map(d => {
                    return [...(resp.data.data_view_logbook || [])].find(s => s?.id?.toString() === d)
                })
                // console.log('data hasil', ac, con)
                action.setForm('from_logbook_research_achievement', ac)
                action.setForm('from_logbook_research_concern', con)
            }
        })
    }
    function fileOnchangeAchievement(data) {
        const file = data.value?.[0]
        if (data.value?.[0] && file.size <= 10000000) {
            action.postData(
                {
                    url: "/api/base/upload-file-temp",
                    data: {
                        "File": data.value?.[0],
                    },
                    withFile: true,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    const newData = state.formData.fileAchievement || [];
                    newData.splice(newData, 0, {
                        "file_location": resp.data?.data_file_location,
                        "file_name": resp.data?.data_filename,
                        "is_new": true,
                    })
                    action.setForm("fileAchievement", newData);
                }
            })
        } else if (data.value?.[0] && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    function fileUploadedAchievement(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            const newData = state.formData.fileAchievement || [];
            newData.splice(newData, 0, {
                "file_location": resp.data?.data_file_location,
                "file_name": resp.data?.data_filename,
                "is_new": true,
            })
            action.setForm("fileAchievement", newData);
        }
    }
    function fileOnchangeConcern(data) {
        const file = data.value?.[0]
        if (data.value?.[0] && file.size <= 10000000) {
            action.postData(
                {
                    url: "/api/base/upload-file-temp",
                    data: {
                        "File": data.value?.[0],
                    },
                    withFile: true,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    const newData = state.formData.fileConcern || [];
                    newData.splice(newData, 0, {
                        "file_location": resp.data?.data_file_location,
                        "file_name": resp.data?.data_filename,
                        "is_new": true,
                    })
                    action.setForm("fileConcern", newData);
                }
            })
        } else if (data.value?.[0] && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    function fileUploadedConcern(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            const newData = state.formData.fileConcern || [];
            newData.splice(newData, 0, {
                "file_location": resp.data?.data_file_location,
                "file_name": resp.data?.data_filename,
                "is_new": true,
            })
            action.setForm("fileConcern", newData);
        }
    }
    function deleteFileConcern(index, data) {
        const newData = state.formData.fileConcern || []
        const indexOf = newData.indexOf(data)

        if (!data.is_new) {
            newData.splice(indexOf, 1)
            action.setForm("fileConcern", newData);

            const docdelete = state.formData.deleteConcern || []
            docdelete.splice(docdelete, 0, data)
            action.setForm("deleteConcern", docdelete);
        } else {
            deleteFileApiConcern(index, data.file_name)

        }
    }
    function deleteFileAchievement(index, data) {
        const newData = state.formData.fileAchievement || []
        const indexOf = newData.indexOf(data)

        if (!data.is_new) {
            newData.splice(index, 1)
            action.setForm("fileAchievement", newData);

            const docdelete = state.formData.deleteAchievement || []
            docdelete.splice(docdelete, 0, data)
            action.setForm("deleteAchievement", docdelete);
        } else {
            deleteFileApiAchievement(index, data.file_name)
        }
    }
    function deleteFileApiAchievement(index, fileName) {
        action.postData(
            {
                url: "/api/base/delete-file-temp?fileName=" + fileName,
                withFile: false,
                withLoading: false,
                withAlert: false
            }
        ).then((resp) => {
            if (resp.code === "00") {
                const newData = state.formData.fileAchievement || []
                newData.splice(index, 1)
                action.setForm("fileAchievement", newData);
            }
        })
    }
    function deleteFileApiConcern(index, fileName) {
        action.postData(
            {
                url: "/api/base/delete-file-temp?fileName=" + fileName,
                withFile: false,
                withLoading: false,
                withAlert: false
            }
        ).then((resp) => {
            if (resp.code === "00") {
                const newData = state.formData.fileConcern || []
                newData.splice(index, 1)
                action.setForm("fileConcern", newData);
            }
        })
    }
    function validation() {
        const mng = state.formData.management_support || '';
        const ach = state.formData.descriptionAchievement || '';
        const why = state.formData.descriptionWayforward || '';
        const conc = state.formData.descriptionConcern || '';
        var isValid = true;
        var message = [];
        var messageLength = [];
        if (ach === '') {
            isValid = false;
            message = [...message, 'Achievement'];
        }
        if (why === '') {
            isValid = false;
            message = [...message, 'Way Forward'];
        }
        if (conc === '') {
            isValid = false;
            message = [...message, 'Concern'];
        }
        if (mng === '') {
            isValid = false;
            message = [...message, 'Management Support'];
        }
        if (isValid) {
            // if (ach.replace(/(<([^>]*)>)/ig, "").length < 50) {
            //     isValid = false;
            //     messageLength = [...messageLength, 'Achievement'];
            // }
            if (why.replace(/(<([^>]*)>)/ig, "").length < 50) {
                isValid = false;
                messageLength = [...messageLength, 'Way Forward'];
            }
            // if (conc.replace(/(<([^>]*)>)/ig, "").length < 50) {
            //     isValid = false;
            //     messageLength = [...messageLength, 'Concern'];
            // }
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : "") + (messageLength.length > 0 ? messageLength.join(', ') + " Minimal 50 karakter" : '')
        }
    }
    function onUpdate() {
        const validate = validation();
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            const data = [
                {
                    "id": state.formData.achievement?.id || '',
                    "year_month": state.data_year_month || '',
                    "description": state.formData.descriptionAchievement || '',
                    "type": "Achievement",
                    "file_location": [...(state.formData.fileAchievement || [])].map(d => { return d.file_name }).join('?'),
                    "link": '',
                    "status": state.formData.achievement?.status || '',
                    "from_logbook_research": [...(state.formData.from_logbook_research_achievement || [])].map(d => { return d?.id }).toString() || '',
                    "management_review": "",
                    "research_id": state.data_view_header?.id || '',
                    "document": [...(state.formData.fileAchievement || [])].map(d => {
                        return {
                            "file_name": d.file_name,
                            "is_new": d.is_new,
                        }
                    }),
                    "document_delete": [...(state.formData.deleteAchievement || [])].map(d => {
                        return {
                            "file_name": d.file_name,
                            "is_new": d.is_new,
                        }
                    })
                },
                {
                    "id": state.formData.concern?.id || '',
                    "year_month": state.data_year_month || '',
                    "description": state.formData.descriptionConcern || '',
                    "type": "Concern",
                    "file_location": [...(state.formData.fileConcern || [])].map(d => { return d.file_name }).join('?'),
                    "link": "",
                    "status": state.formData.concern?.status || '',
                    "from_logbook_research": [...(state.formData.from_logbook_research_concern || [])].map(d => { return d?.id }).toString() || '',
                    "management_review": state.formData.management_support || '',
                    "research_id": state.data_view_header?.id || '',
                    "document": [...(state.formData.fileConcern || [])].map(d => {
                        return {
                            "file_name": d.file_name,
                            "is_new": d.is_new,
                        }
                    }),
                    "document_delete": [...(state.formData.deleteConcern || [])].map(d => {
                        return {
                            "file_name": d.file_name,
                            "is_new": d.is_new,
                        }
                    })
                },
                {
                    "id": state.formData.way?.id || '',
                    "year_month": state.data_year_month || '',
                    "description": state.formData.descriptionWayforward || '',
                    "type": "Way Forward",
                    "file_location": "",
                    "link": "",
                    "status": state.formData.way?.status || '',
                    "from_logbook_research": "",
                    "management_review": "",
                    "research_id": state.data_view_header?.id || '',
                    "document": [],
                    "document_delete": []
                }
            ]
            // console.log('data kirim', data)
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-success mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Achievement?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/project-ongoing-crud/update-achieve-con?research_id=${state.data_view_header?.id}`,
                            data: data,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            history.push('/research/monthlyReport/' + state.data_view_header?.id + '/' + date.year + '/' + date.month)
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function getdataLogbook(type) {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-achieve-con-logbook/" + state.data_view_header?.id + "/" + type + "/").then((resp) => {
                if (resp.code === "00") {
                    if (type === '1') {
                        action.setForm('dataModalAchievement', [...(resp.data.data_view_logbook || [])].map(d => {
                            return {
                                ...d,
                                type_name: 'Achievement',
                                status_name: "Submitted"
                            }
                        }))
                    }
                    if (type === '2') {
                        action.setForm('dataModalConcern', [...(resp.data.data_view_logbook || [])].map(d => {
                            return {
                                ...d,
                                type_name: 'Concern',
                                status_name: "Submitted"
                            }
                        }))
                    }
                }
            });
            // action.getData("/api/project-ongoing-crud/view-achieve-con-logbook/" + state.data_view_header?.id + "/" + 3 + "/").then((resp) => {
            //     if (resp.code === '00') {
            //         action.setForm('dataModalOther', [...(resp.data.data_view_logbook || [])].map(d => {
            //             return {
            //                 ...d,
            //                 type_name: 'Other',
            //                 status_name: "Submitted"
            //             }
            //         }))
            //     }
            // })
        }
    }
    function clickModal(type) {
        setTypeModal(type)
        getdataLogbook(type);
        if (type === '1') {
            action.toggleModal("modalAchievement");
        }
        if (type === '2') {
            action.toggleModal("modalConcern");
        }
    }
    function addFromlogbookAchievement(data) {
        action.setForm('from_logbook_research_achievement', data)
        action.toggleModal("modalAchievement");
    }
    function addFromlogbookConcern(data) {
        action.setForm('from_logbook_research_concern', data)
        action.toggleModal("modalConcern");
    }
    return (
        <>
            <Modal
                name="modalAchievement"
                baseColor={true}
                size="70%"
            >
                <SelectLogBook
                    //onSelectionData={(e) => { action.setForm('from_logbook_research_achievement', e) }}
                    defaultSelected={state.formData.from_logbook_research_achievement}
                    data={[...(state.formData?.dataModalAchievement || [])]} onAdd={(e) => addFromlogbookAchievement(e)} />

            </Modal>
            <Modal
                name="modalConcern"
                baseColor={true}
                size="70%"
            >
                <SelectLogBook
                    //onSelectionData={(e) => action.setForm('from_logbook_research_concern', e)}
                    defaultSelected={state.formData.from_logbook_research_concern}
                    data={[...(state.formData?.dataModalConcern || [])]} onAdd={(e) => addFromlogbookConcern(e)} />

            </Modal>
            <span className='d-block text-center mb-5 px-2 py-1 bg-primary rounded text-white'>Pengisian realisasi bulan {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).format('MMM'))} dapat dilakukan s.d. tanggal {state.data_cut_off_date} {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).add(1, 'month').format('MMM'))} {state.data_year_month?.split('/')?.[1]}</span>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: true,
                        name: <>Achievement <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Description",
                                component: (
                                    state.formData.disable === false ? (
                                        <div>
                                            <TextEditor
                                                onChange={(e) => action.setForm('descriptionAchievement', e)}
                                                className="mb-5"
                                                multilineToolbar={false}
                                                height="250px"
                                                value={state.formData.descriptionAchievement}
                                            >
                                            </TextEditor>
                                            <div className="py-5">
                                                <a style={{ color: '#3699FF' }} onClick={() => clickModal('1')}>+ Tambah data dari logbook</a>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.descriptionAchievement }}></div>
                                    )
                                )
                            },
                            {
                                label: "Attachment",
                                type: "custom",
                                component: (
                                    state.formData.disable === false && (
                                        <div>
                                            <FileUploadDx
                                                className="custom-dx-fileuploader-file-name"
                                                showFileList={true}
                                                multiple={false}
                                                selectButtonText="Select File"
                                                readyToUploadMessage=" "
                                                labelText="or Drop file hire | Max size 10 Mb"
                                                accept="*"
                                                onUploaded={fileUploadedAchievement}
                                                maxFileSize={10000000}
                                                uploadMode="instantly"
                                            />
                                            {/* <FileUploader
                                                onValueChanged={(e) => fileOnchangeAchievement(e)}
                                                className="custom-dx-fileuploader-file-name"
                                                showFileList={false} multiple={false}
                                                selectButtonText="Select File"
                                                labelText="or Drop file hire | Max size 10 Mb"
                                                accept="*" maxFileSize={10000000} uploadMode="useForm" ></FileUploader> */}
                                        </div>
                                    )
                                )
                            }, {
                                label: "",
                                type: "custom",
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    [...(state.formData.fileAchievement || [])].map((d, i) => (
                                                        <div className="d-flex flex-row justify-content-between mb-1">

                                                            <span className="d-block pl-2" style={{ fontSize: '8pt' }}>{d.file_name}</span>
                                                            <div>
                                                                {
                                                                    state.formData.disable === false ? (
                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deleteFileAchievement(i, d)}></i>
                                                                    ) : (
                                                                        <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1" onClick={() => { }}></i>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span><strong>Additional Data From Log Book</strong></span>
                                                <hr />
                                                {
                                                    [...(state.formData.from_logbook_research_achievement || [])].map((d, i) => (
                                                        <div className="d-block mb-5" dangerouslySetInnerHTML={{ __html: d?.description }}></div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Way Forward <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Description",
                                component: (
                                    state.formData.disable === false ? (
                                        <TextEditor
                                            onChange={(e) => action.setForm('descriptionWayforward', e)}
                                            className="mb-5"
                                            multilineToolbar={false}
                                            height="250px"
                                            value={state.formData.descriptionWayforward}
                                        >

                                        </TextEditor>
                                    ) : (
                                        <div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.descriptionWayforward }}></div>
                                    )
                                )
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Concern <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Description",
                                component: (
                                    state.formData.disable === false ? (
                                        <div>
                                            <TextEditor
                                                onChange={(e) => action.setForm('descriptionConcern', e)}
                                                className="mb-5"
                                                multilineToolbar={false}
                                                height="250px"
                                                value={state.formData.descriptionConcern}
                                            >

                                            </TextEditor>
                                            <div className="py-5">
                                                <a style={{ color: '#3699FF' }} onClick={() => clickModal('2')}>+ Tambah data dari logbook</a>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.descriptionConcern }}></div>
                                    )
                                )
                            },
                            {
                                type: "custom",
                                label: "Need Management Support",
                                component: (
                                    state.formData.disable === false ? (
                                        <div className="py-5">
                                            <RadioGrp
                                                onChange={(e) => action.setForm('management_support', e)}
                                                value={state.formData.management_support?.toString()}
                                                modeRow={true}
                                                data={[{ label: "Ya", value: '1' }, { label: "Tidak", value: '0' }]}
                                            />
                                        </div>
                                    ) : (
                                        <div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.concern?.management_review === "0" ? "Tidak" : "Ya" }}></div>
                                    )
                                )
                            },
                            {
                                label: "Attachment",
                                type: "custom",
                                component: (
                                    state.formData.disable === false && (
                                        <div>
                                            <FileUploadDx
                                                className="custom-dx-fileuploader-file-name"
                                                showFileList={true}
                                                multiple={false}
                                                selectButtonText="Select File"
                                                readyToUploadMessage=" "
                                                labelText="or Drop file hire | Max size 10 Mb"
                                                accept="*"
                                                onUploaded={fileUploadedConcern}
                                                maxFileSize={10000000}
                                                uploadMode="instantly"
                                            />
                                            {/* <FileUploader
                                                onValueChanged={(e) => fileOnchangeConcern(e)}
                                                className="custom-dx-fileuploader-file-name"
                                                showFileList={false} multiple={false}
                                                selectButtonText="Select File" labelText="or Drop file hire | Max size 10 Mb"
                                                accept="*" maxFileSize={10000000} uploadMode="useForm" ></FileUploader> */}
                                        </div>
                                    )
                                )
                            }, {
                                label: "",
                                type: "custom",
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    [...(state.formData.fileConcern || [])].map((d, i) => (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span className="d-block pl-2" style={{ fontSize: '8pt' }}>{d.file_name}</span>
                                                            <div>
                                                                {
                                                                    state.formData.disable === false ? (
                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deleteFileConcern(i, d)}></i>
                                                                    ) : (
                                                                        <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1" onClick={() => { }}></i>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span><strong>Additional Data From Log Book</strong></span>
                                                <hr />
                                                {
                                                    [...(state.formData.from_logbook_research_concern || [])].map((d, i) => (
                                                        <div className="d-block mb-5" dangerouslySetInnerHTML={{ __html: d?.description }}></div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    }
                ]}
            />
            {
                state.formData.disable === false && (
                    <div className="row mt-5">
                        <div className="col-6">
                            <span className="d-block text-danger">(*) Mandatori tidak boleh kosong</span>
                        </div>
                        <div className="col-6 text-md-right">
                            <button className="btn btn-sm btn-outline-success" onClick={() => onUpdate()}><i className="fa fa-save icon-nm"></i> Update</button>
                        </div>
                    </div>
                )
            }
        </>
    )
})
export default TabAchievement