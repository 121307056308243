import React, { memo, useEffect, useState } from 'react';
import { HeaderList, Modal, CardBerita, PopularCard } from '../../components';
import TabTable from './tabTable';
import TabDashboard from './tabDashboard';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Card, DropdownButton, Dropdown } from 'react-bootstrap'
import { achievement_level } from '../../components/utility/utility'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { pathFile, pathImage } from '../../app.config';

export default memo(function () {
    const { pathname } = useLocation();
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    const param = useParams();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData("/api/people-hrd/view-update-achievement?id=" + param.id, {}, null, null, true).then((resp) => {
            if(resp.code === '00'){
                setData_page(resp.data_page);
            }
        });
    }, [param.id])
    const approveOnclick = () => {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Achievement?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-achievement/update-approve?id=" + param?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/people-achievement/approval')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const rejectOnclick = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Reject Avhievement ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-achievement/update-reject?id=" + param?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/people-achievement/approval')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const deleteOnclick = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Avhievement ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-achievement/delete?id=" + param?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/people-achievement/approval')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            >

            </HeaderList>

            <Card
                className="mx-0 mt-0 p-0 customShadow"
                style={{marginBottom:'40px'}}
            >
                <Card.Body className="p-4">
                    <div className="row">
                        <div className="col-md-4 mb-5">
                            <div className="pl-5 pr-5">
                                <img style={{ width: '100%', minHeight: 200, maxHeight: 300, objectFit: 'cover', borderRadius: '3%' }} className="bg-secondary border"
                                    src={state.data_user_achievement?.photo_location}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                            </div>
                        </div>
                        <div className="col-md-7 mb-5">
                            <h3><b>{state.data_user_achievement?.name}</b></h3>
                            <br />
                            <p className="text-dark">Deskripsi</p>
                            <p>{state.data_user_achievement?.description}</p>
                            <hr />
                            <div className="row mb-5">
                                <div className="col-md-4">
                                    <span className="d-block mb-3">Owner</span>
                                    <span><strong>{state.data_user_achievement?.creator_name}</strong></span>
                                </div>
                                <div className="col-md-4">
                                    <div>
                                        <span className="d-block mb-3">Level</span>
                                        <div>{achievement_level(state.data_user_achievement?.level)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <span className="d-block mb-3">Creator</span>
                                    <span><strong>{state.data_user_achievement?.creator_name}</strong></span>
                                </div>
                                <div className="col-md-4">
                                    <div>
                                        <span className="d-block mb-3">Tahun</span>
                                        <div><strong>{state.data_user_achievement?.year_of_achievement}</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {/* <div className="col" style={{ marginTop: "20px" }}>
                <h4>Lihat Achievement Lain</h4>
            </div>
            <div class="row">
                {
                    [...(state.data_table || [])].map((d, i) => (
                        <div className="col-md-3">
                            <Card
                                className="m-0 p-0  mb-5 customShadow"
                            >
                                <Card.Body className="p-4">
                                    <div className="col-md-12">
                                        <div className="card card-custom overlay my-5">
                                            <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                <div className="overlay-wrapper">
                                                    <img style={{ width: '100%', minHeight: 150, maxHeight:300, objectFit:'cover', borderRadius: '3%' }} className="bg-secondary"
                                                        src={d.photo_location}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                                </div>
                                                <div class="overlay-layer">
                                                    <Link to={
                                                        path.toLowerCase() === '/admin/people/achievement-detail-crud' ?
                                                            `/admin/people/achievement-detail-crud/${d.id}` :
                                                            path.toLowerCase() === '/admin/people/achievement-detail-approval' ? `/admin/people/achievement-detail-approval/${d.id}` :
                                                                `/people/achievement-detail/${d.id}`
                                                    }>
                                                        <button className="btn btn-sm btn-primary"><i className="fa fa-search"></i>
                                                            Lihat Detail
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <p style={{ color: '#4871db' }}>
                                            <h5>{d.name}</h5>
                                        </p>
                                        <p>{d.description}</p>
                                        <div>
                                            {achievement_level(d.level)}
                                        </div>
                                        <br />
                                        <br />
                                        <p>
                                            {d.year_of_achievement}
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }
            </div> */}
            <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        {
                            path.toLowerCase() === '/people/achievement-detail' && (
                                <button className="btn btn-sm btn-secondary" onClick={() => history.push('/People/achievement')}>Kembali</button>
                            )
                        }
                        {
                            path.toLowerCase() === '/admin/people/achievement-detail-crud' && (
                                <button className="btn btn-sm btn-secondary" onClick={() => history.push('/admin/people-achievement/table')}>Kembali</button>
                            )
                        }
                        {
                            path.toLowerCase() === '/admin/people/achievement-detail-approval' && (
                                <button className="btn btn-sm btn-secondary" onClick={() => history.push('/admin/people-achievement/approval')}>Kembali</button>
                            )
                        }
                    </div>
                    <div className="col-md-6 text-md-right">
                        {
                            path.toLowerCase() === '/admin/people/achievement-detail-approval' && (
                                <DropdownButton size="sm" id="dropdown-basic-button" title="Action">
                                    <Dropdown.Item onClick={approveOnclick}>Approve</Dropdown.Item>
                                    <Dropdown.Item onClick={() => history.push(`/admin/people-achievement/edit/${param.id}`)}>Edit</Dropdown.Item>
                                    <Dropdown.Item className="text-danger" onClick={rejectOnclick}>Reject</Dropdown.Item>
                                    <Dropdown.Item className="text-danger" onClick={deleteOnclick}>Delete</Dropdown.Item>
                                </DropdownButton>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>

    )
})