import React from 'react';
import { ChartDx, CstmFilterCahrtDx } from '../../../../components';
import useLab from '../useLaboratory';
import { truncate } from 'lodash';
import { formatter } from '../../../../components/utility/utility';

const dummy_data = [
    {
        state: 'Holding',
        year2023: 803,
        year2024: 294,
    },
    {
        state: 'SHU',
        year2023: 316,
        year2024: 642,
    },
    {
        state: 'R&P',
        year2023: 452,
        year2024: 459,
    },
    {
        state: 'C&T',
        year2023: 621,
        year2024: 332,
    },
    {
        state: 'Gas',
        year2023: 290,
        year2024: 823,
    },
    {
        state: 'PNRE',
        year2023: 621,
        year2024: 332,
    },
    {
        state: 'IML',
        year2023: 290,
        year2024: 823,
    },
]
const dummy_data2 = [
    {
        state: 'Aspal',
        year2023: 803,
    },
    {
        state: 'Avtur',
        year2023: 803,
    },
    {
        state: 'Biofuel',
        year2023: 316,
    },
    {
        state: 'Condensate',
        year2023: 452,
    },
    {
        state: 'Crude Oil',
        year2023: 621,
    },
    {
        state: 'Fuel Oil',
        year2023: 290,
    },
    {
        state: 'Naphta',
        year2023: 621,
    },
    {
        state: 'Polimer',
        year2023: 290,
    },
    {
        state: 'Solvent',
        year2023: 316,
    },
    {
        state: 'Wax',
        year2023: 452,
    },
]

const dummy_line = [
    {
        state: 'JAN',
        jumlah: 803,
    },
    {
        state: 'FEB',
        jumlah: 803,
    },
    {
        state: 'MAR',
        jumlah: 316,
    },
    {
        state: 'APR',
        jumlah: 452,
    },
    {
        state: 'MAY',
        jumlah: 621,
    },
    {
        state: 'JUN',
        jumlah: 290,
    },
    {
        state: 'JUL',
        jumlah: 621,
    },
    {
        state: 'AUG',
        jumlah: 290,
    },
    {
        state: 'SEP',
        jumlah: 316,
    },
    {
        state: 'OCT',
        jumlah: 452,
    },
    {
        state: 'NOV',
        jumlah: 290,
    },
    {
        state: 'DEC',
        jumlah: 621,
    },
]

const dummy_data3 = [
    {
        state: 'Aspal - Density',
        year2023: 803,
    },
    {
        state: 'Avtur - Total Acidity',
        year2023: 803,
    },
    {
        state: 'Avtur - Total Sulfur',
        year2023: 316,
    },
    {
        state: 'Avtur - Doctor Test',
        year2023: 452,
    },
    {
        state: 'Biofuel - Cetane Index',
        year2023: 621,
    },
    {
        state: 'Condensate - Aromatic',
        year2023: 290,
    },
    {
        state: 'Condensate - Pour Point',
        year2023: 621,
    },
    {
        state: 'Crude Oil - Acid Number',
        year2023: 290,
    },
    {
        state: 'Crude Oil - Metal Content',
        year2023: 316,
    },
    {
        state: 'Fuel Oil - Carbon Residue',
        year2023: 452,
    },
].sort((a, b) => a.year2023 - b.year2023);
const dummy4 = [
    {
        state: 'Testing Request',
        year2023: 8,
    },
    {
        state: 'Testing Request Review',
        year2023: 8,
    },
    {
        state: 'Testing Request Need Revision',
        year2023: 3,
    },
    {
        state: 'Testing Request Rejected',
        year2023: 4,
    },
    {
        state: 'Sample Delivery Request',
        year2023: 6,
    },
    {
        state: 'Sample Delivery Not Complete',
        year2023: 2,
    },
    {
        state: 'Sample Accepted',
        year2023: 6,
    },
    {
        state: 'Sample Rejected',
        year2023: 2,
    },
    {
        state: 'Testing Process Running',
        year2023: 3,
    },
    {
        state: 'Testing Process Aborted',
        year2023: 4,
    },
    {
        state: 'Testing Process Finished',
        year2023: 4,
    },
    {
        state: 'SPK Upload Request',
        year2023: 6,
    },
    {
        state: 'SPK Need Revision',
        year2023: 2,
    },
    {
        state: 'COA Published',
        year2023: 6,
    },
];

function Dashboard(props) {
    const { labAction, labState } = useLab();
    return (
        <div className='p-5'>
            <div className='d-flex align-items-center mb-5'>
                <h5>Dashboard Pengujian Lab RTI - Tahun</h5>
                <select className='form-control form-control-sm w-auto ml-5'>
                    <option selected>2024</option>
                    <option>2023</option>
                    <option>2022</option>
                </select>
            </div>
            <div className='row mb-5'>
                <div className='col-md-6'>
                    <CstmFilterCahrtDx
                        cardStyle={false}
                        title="Jumlah Pengujian"
                        subtitle="Jumlah pengujian laboratorium"
                        option={[...(labState.chart1?.data_parameter || [])].map(d => ({ name: d?.nama, value: d?.id }))}
                        value={labState.fChart1}
                        onChange={val => labAction.getChart1(val)}
                    >
                        {
                            React.useMemo(() => (

                                <ChartDx
                                    id="1"
                                    argumentField="nama"
                                    dataSource={labState.chart1?.data_chart || []}
                                    onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                    onrenderLabel={(e) => truncate(e.valueText, {
                                        length: 13,
                                    })}
                                    rotationAngle={45}
                                    overlappingBehavior="rotate"
                                    withTitle={false}
                                    showLegend={false}
                                    toolsExport={false}
                                    yAxisArgs={[
                                        {
                                            valueField: "jumlah",
                                            color: '#326BD5'
                                        },
                                    ]}
                                />
                            ), [labState.chart1?.data_chart])
                        }
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-md-6'>
                    <CstmFilterCahrtDx
                        cardStyle={false}
                        title="Revenue Pengujian (Ribu IDR)"
                        subtitle="Jumlah pendapatan pengujian berdasarkan komoditi dan customer"
                        option={[...(labState.chart2?.data_parameter || [])].map(d => ({ name: d?.nama, value: d?.id }))}
                        value={labState.fChart2}
                        onChange={val => labAction.getChart2(val)}
                    >
                        {
                            React.useMemo(() => (
                                <ChartDx
                                    id="1"
                                    argumentField="nama"
                                    dataSource={labState.chart2?.data_chart || []}
                                    onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${formatter.format(arg.value)}` })}
                                    onRenderLabelChart={(e) => formatter.format(e.value)}
                                    onrenderLabel={(e) => truncate(e.valueText, {
                                        length: 13,
                                    })}
                                    rotationAngle={45}
                                    overlappingBehavior="rotate"
                                    withTitle={false}
                                    showLegend={false}
                                    toolsExport={false}
                                    yAxisArgs={[
                                        {
                                            valueField: "jumlah",
                                            color: "#28a745"
                                        }
                                    ]}
                                />
                            ), [labState.chart2?.data_chart])
                        }
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-md-6'>
                    <CstmFilterCahrtDx
                        cardStyle={false}
                        title="Jumlah Revenue"
                        subtitle="Jumlah pendapatan pengujian berdasarkan bulanan atau tahunan"
                        option={[...(labState.chart3?.data_parameter || [])].map(d => ({ name: d?.nama, value: d?.id }))}
                        value={labState.fChart3}
                        onChange={val => labAction.getChart3(val)}
                    >
                        {
                            React.useMemo(() => (

                                <ChartDx
                                    id="1"
                                    type="line"
                                    argumentField="nama"
                                    dataSource={labState.chart3?.data_chart || []}
                                    onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${formatter.format(arg.value)}` })}
                                    onRenderLabelChart={(e) => formatter.format(e.value)}
                                    withTitle={false}
                                    showLegend={false}
                                    toolsExport={false}
                                    yAxisArgs={[
                                        {
                                            valueField: "jumlah",
                                            color: '#dc3545'
                                        }
                                    ]}
                                />
                            ), [labState.chart3?.data_chart])
                        }
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-md-6'>
                    <CstmFilterCahrtDx
                        cardStyle={false}
                        title="Jenis Pengujian"
                        subtitle="Top 10 jenis pengujian & komoditi per tahun"
                    >
                        {
                            React.useMemo(() => (

                                <ChartDx
                                    id="1"
                                    rotate={true}
                                    argumentField="nama"
                                    onrenderLabel={(e) => truncate(e.valueText, {
                                        length: 13,
                                    })}
                                    dataSource={labState.chart4}
                                    withTitle={false}
                                    showLegend={false}
                                    toolsExport={false}
                                    colorBarDifferent={true}
                                    colorBarDiffirentField="nama"
                                    coloBarDifferentValueField="jumlah"
                                    onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}

                                />
                            ), [labState.chart4])
                        }
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-md-12'>
                    <CstmFilterCahrtDx
                        cardStyle={false}
                        title="Status Pengujian"
                        subtitle="Jumlah pengujian per status pengujian"
                    >
                        {
                            React.useMemo(() => (

                                <ChartDx
                                    id="1"
                                    height={400}
                                    argumentField="nama"
                                    dataSource={labState.chart5 || []}
                                    withTitle={false}
                                    showLegend={false}
                                    toolsExport={false}
                                    colorBarDifferent={true}
                                    colorBarDiffirentField="nama"
                                    coloBarDifferentValueField="jumlah"
                                    rotationAngle={45}
                                    overlappingBehavior="rotate"
                                    onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                // onrenderLabel={() => null}                                    
                                />
                            ), [labState.chart5])
                        }
                    </CstmFilterCahrtDx>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;