import React, { createContext, useContext, useState } from "react";
import useFetch from "../../modules/useFetch";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const equipmentReportContext = createContext({
    state: {},
    action: {}
});

export function EquipmentReportProvider({ children }) {
    const { doGet } = useFetch();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    async function GetDetailHistory() {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: `api/ext-equipment/view-report-maintenance/${id}`,
            });
            if (resp?.code === '00') {
                setData(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }
    useEffect(() => {
        GetDetailHistory();
    }, []);

    return <equipmentReportContext.Provider
        value={{
            state: {
                loading,
                data
            },
            action: {}
        }}
    >
        {children}
    </equipmentReportContext.Provider>
}
export default function useEquipmentReporting() { return useContext(equipmentReportContext); }