import DataGrid, { Column, RowDragging, Lookup, LoadPanel } from 'devextreme-react/data-grid';
import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useGlobalState } from '../../contexts/useGlobalState';
import '../grid-timeline/grid-timeline.css';
import './grid-dx-drawdown.css'
import { NumberBox } from 'devextreme-react';
import moment from 'moment';
import { toInteger } from 'lodash';

export default function ({
    dataSource,
    columns,
    allowAdding = false,
    allowEditing = false,
    allowDeleting = false,
    modeEditing = "row",
    showTotalActual = false,
    data_year_month = ""
}) {
    const [ds, setDs] = useState();
    const [sum, setSum] = useState(0);
    const [sumActual, setSumActual] = useState(0)
    const [state, dispatch, action] = useGlobalState();
    const refGrid = useRef(null);

    useEffect(() => {
        setDs(dataSource.data);
    }, [dataSource])

    useEffect(() => {
        if (refGrid.current) {
            Summary();
            refGrid.current.instance.refresh()
        }
    }, [ds])

    function Summary() {
        const tmp = ds;
        let total = 0;
        let totalActual = 0;
        if (Array.isArray(tmp)) {
            tmp.map((dt, i) => {
                total += ((isNaN(Number(dt.plan_jan))) ? 0 : Number(dt.plan_jan)) +
                    ((isNaN(Number(dt.plan_feb))) ? 0 : Number(dt.plan_feb)) +
                    ((isNaN(Number(dt.plan_mar))) ? 0 : Number(dt.plan_mar)) +
                    ((isNaN(Number(dt.plan_apr))) ? 0 : Number(dt.plan_apr)) +
                    ((isNaN(Number(dt.plan_may))) ? 0 : Number(dt.plan_may)) +
                    ((isNaN(Number(dt.plan_jun))) ? 0 : Number(dt.plan_jun)) +
                    ((isNaN(Number(dt.plan_jul))) ? 0 : Number(dt.plan_jul)) +
                    ((isNaN(Number(dt.plan_aug))) ? 0 : Number(dt.plan_aug)) +
                    ((isNaN(Number(dt.plan_sep))) ? 0 : Number(dt.plan_sep)) +
                    ((isNaN(Number(dt.plan_oct))) ? 0 : Number(dt.plan_oct)) +
                    ((isNaN(Number(dt.plan_nov))) ? 0 : Number(dt.plan_nov)) +
                    ((isNaN(Number(dt.plan_dec))) ? 0 : Number(dt.plan_dec));

                totalActual += ((isNaN(Number(dt.actual_jan))) ? 0 : Number(dt.actual_jan)) +
                    ((isNaN(Number(dt.actual_feb))) ? 0 : Number(dt.actual_feb)) +
                    ((isNaN(Number(dt.actual_mar))) ? 0 : Number(dt.actual_mar)) +
                    ((isNaN(Number(dt.actual_apr))) ? 0 : Number(dt.actual_apr)) +
                    ((isNaN(Number(dt.actual_may))) ? 0 : Number(dt.actual_may)) +
                    ((isNaN(Number(dt.actual_jun))) ? 0 : Number(dt.actual_jun)) +
                    ((isNaN(Number(dt.actual_jul))) ? 0 : Number(dt.actual_jul)) +
                    ((isNaN(Number(dt.actual_aug))) ? 0 : Number(dt.actual_aug)) +
                    ((isNaN(Number(dt.actual_sep))) ? 0 : Number(dt.actual_sep)) +
                    ((isNaN(Number(dt.actual_oct))) ? 0 : Number(dt.actual_oct)) +
                    ((isNaN(Number(dt.actual_nov))) ? 0 : Number(dt.actual_nov)) +
                    ((isNaN(Number(dt.actual_dec))) ? 0 : Number(dt.actual_dec));
            })
        }
        setSum(total)
        setSumActual(totalActual)
    }
    function addNewRow() {
        refGrid.current.instance.addRow();
    }
    function onToolbarPreparing(e) {
        let toolbarItems = e.toolbarOptions.items;
        toolbarItems.forEach(function (item) {
            if (item.name === "addRowButton") {
                item.options = {
                    icon: "fa fa-plus-square",
                    text: "Add New",
                    hint: "Add New",
                    onClick: addNewRow.bind(this)
                }
            }
        });
    }
    function onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        const newDS = ds;
        const toIndex = newDS.indexOf(visibleRows[e.toIndex].data);
        const fromIndex = newDS.indexOf(e.itemData);
        newDS.splice(fromIndex, 1);
        newDS.splice(toIndex, 0, e.itemData);
        setDs(newDS);
        refGrid.current.instance.refresh()
    }
    function onRowRemoving(e) {
        // const res = Swal.fire({
        //     text: 'Are you sure you want to delete this record?',
        //     showCancelButton: true,
        //     confirmButtonText: `Yes`,
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         Swal.fire('Deleted!', '', 'success')
        //         return false;
        //     } else {
        //         return true;
        //     }
        // })
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Budget ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                return false;
            } else {
                return true;
            }
        })
        e.cancel = res;
    }
    function addOnclick() {
        refGrid.current.instance.addRow();
    }
    function updateSum() {
        Summary();
    }
    function cellRender(e) {
        return (
            <span className="text-wrap textContent">{e.text}</span>
        )
    }
    function actualDataChange(rowInfo, value) {
        const newDs = [...(ds || [])];
        newDs.map((d, i) => {
            if (d.id === rowInfo.key.id) {
                d[Object.keys(value)] = (isNaN(Number(value[Object.keys(value)]))) ? 0 : Number(value[Object.keys(value)])
            }
        });
        // console.log(rowInfo, value, newDs);
        setDs(newDs)
        Summary();
    }
    function RowRender(rowInfo) {
        const days = parseInt(moment(new Date()).format('D'));
        const month = toInteger(data_year_month.split('/')?.[0]);
        const year = toInteger(data_year_month.split('/')?.[1]);

        let disableInput = {
            jan: true,
            feb: true,
            mar: true,
            apr: true,
            may: true,
            jun: true,
            jul: true,
            aug: true,
            sep: true,
            oct: true,
            nov: true,
            dec: true,
        }
        if (allowEditing === true) {
            if (state.filterData.mode === "edit") {
                // const month = parseInt(moment(new Date()).format('M'));
                // const days = parseInt(moment(new Date()).format('D'));
                // disableInput = {
                //     jan: month === 1 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 2 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     feb: month === 2 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 3 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     mar: month === 3 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 4 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     apr: month === 4 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 5 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     may: month === 5 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 6 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     jun: month === 6 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 7 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     jul: month === 7 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 8 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     aug: month === 8 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 9 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     sep: month === 9 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 10 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     oct: month === 10 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 11 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     nov: month === 11 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 12 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                //     dec: month === 12 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 1 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
                // }
                disableInput = {
                    jan: month === 1 ? false : true,
                    feb: month === 2 ? false : true,
                    mar: month === 3 ? false : true,
                    apr: month === 4 ? false : true,
                    may: month === 5 ? false : true,
                    jun: month === 6 ? false : true,
                    jul: month === 7 ? false : true,
                    aug: month === 8 ? false : true,
                    sep: month === 9 ? false : true,
                    oct: month === 10 ? false : true,
                    nov: month === 11 ? false : true,
                    dec: month === 12 ? false : true,
                }
            }
            if (year.toString() !== dataSource.year.toString()) {
                disableInput = {
                    jan: true,
                    feb: true,
                    mar: true,
                    apr: true,
                    may: true,
                    jun: true,
                    jul: true,
                    aug: true,
                    sep: true,
                    oct: true,
                    nov: true,
                    dec: true,
                }
            }
        }

        return (
            <tbody>
                <tr className="main-row dx-row dx-data-row" role="row">
                    <td rowSpan="2" className="borderGridCstm">
                        <span className="d-block text-wrap px-2  border-0">{rowInfo.data.description}</span>
                    </td>
                    <td className="borderGridCstm p-0" colSpan="14">
                        <tbody>
                            <tr className="mb-1 bgTaskPlan">
                                <td rowSpan={2} width="150px" style={{ wordBreak: 'break-all' }} aria-colindex="3" className="borderGridCstm bg-white">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.cost_element_name}</span>
                                </td>
                                <td width="76px" height={'35px'} className="borderGridCstm">
                                    <span className="d-block text-wrap form-control form-control-sm border-0 rounded-0  cstmbgColorActual mx-0 border-top text-right"><strong>PLAN</strong></span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_jan}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_feb}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_mar}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_apr}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_may}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_jun}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_jul}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_aug}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_sep}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_oct}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_nov}</span>
                                </td>
                                <td width="65px" className="borderGridCstm">
                                    <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_dec}</span>
                                </td>
                            </tr>
                            <tr className="bgTaskActual">
                                <td >
                                    <span className="d-block text-wrap form-control form-control-sm border-0 rounded-0 bgTaskActual  mx-0 border-top text-right"><strong>REALISASI</strong></span>
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.jan} className={disableInput.jan ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_jan} onValueChanged={(e) => actualDataChange(rowInfo, { actual_jan: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm text-wrap border-0 rounded-0 cstmbgColorActual mx-0" disabled={disableInput.jan} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_jan')} value={rowInfo.data.actual_jan} onChange={(e) => actualDataChange(rowInfo, { actual_jan: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.feb} className={disableInput.feb ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_feb} onValueChanged={(e) => actualDataChange(rowInfo, { actual_feb: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0" disabled={disableInput.feb} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_feb')} value={rowInfo.data.actual_feb} onChange={(e) => actualDataChange(rowInfo, { actual_feb: e.target.value })}></input> */}
                                </td>
                                <td >
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0" disabled={disableInput.mar} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_mar')} value={rowInfo.data.actual_mar} onChange={(e) => actualDataChange(rowInfo, { actual_mar: e.target.value })}></input> */}
                                    <NumberBox min={0} disabled={disableInput.mar} className={disableInput.mar ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_mar} onValueChanged={(e) => actualDataChange(rowInfo, { actual_mar: e.value })} format="#,##0.##" />
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.apr} className={disableInput.apr ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_apr} onValueChanged={(e) => actualDataChange(rowInfo, { actual_apr: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0" disabled={disableInput.apr} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_apr')} value={rowInfo.data.actual_apr} onChange={(e) => actualDataChange(rowInfo, { actual_apr: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.may} className={disableInput.may ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_may} onValueChanged={(e) => actualDataChange(rowInfo, { actual_may: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0" disabled={disableInput.mar} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_may')} value={rowInfo.data.actual_may} onChange={(e) => actualDataChange(rowInfo, { actual_may: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.jun} className={disableInput.jun ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_jun} onValueChanged={(e) => actualDataChange(rowInfo, { actual_jun: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0" disabled={disableInput.jun} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_jun')} value={rowInfo.data.actual_jun} onChange={(e) => actualDataChange(rowInfo, { actual_jun: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.jul} className={disableInput.jul ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_jul} onValueChanged={(e) => actualDataChange(rowInfo, { actual_jul: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0 " disabled={disableInput.jul} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_jul')} value={rowInfo.data.actual_jul} onChange={(e) => actualDataChange(rowInfo, { actual_jul: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.aug} className={disableInput.aug ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_aug} onValueChanged={(e) => actualDataChange(rowInfo, { actual_aug: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0 " disabled={disableInput.aug} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_aug')} value={rowInfo.data.actual_aug} onChange={(e) => actualDataChange(rowInfo, { actual_aug: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.sep} className={disableInput.sep ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_sep} onValueChanged={(e) => actualDataChange(rowInfo, { actual_sep: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0 " disabled={disableInput.sep} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_sep')} value={rowInfo.data.actual_sep} onChange={(e) => actualDataChange(rowInfo, { actual_sep: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.oct} className={disableInput.oct ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_oct} onValueChanged={(e) => actualDataChange(rowInfo, { actual_oct: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0" disabled={disableInput.oct} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_oct')} value={rowInfo.data.actual_oct} onChange={(e) => actualDataChange(rowInfo, { actual_oct: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.nov} className={disableInput.nov ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_nov} onValueChanged={(e) => actualDataChange(rowInfo, { actual_nov: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0 " disabled={disableInput.nov} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_nov')} value={rowInfo.data.actual_nov} onChange={(e) => actualDataChange(rowInfo, { actual_nov: e.target.value })}></input> */}
                                </td>
                                <td >
                                    <NumberBox min={0} disabled={disableInput.dec} className={disableInput.dec ? 'cstmbgColorEditorActualDisable' : 'border-primary'} value={rowInfo.data.actual_dec} onValueChanged={(e) => actualDataChange(rowInfo, { actual_dec: e.value })} format="#,##0.##" />
                                    {/* <input className="form-control form-control-sm border-0 rounded-0 cstmbgColorActual mx-0 " disabled={disableInput.dec} type="number" min="0" step="1" max={maxActual(rowInfo.data, 'actual_dec')} value={rowInfo.data.actual_dec} onChange={(e) => actualDataChange(rowInfo, { actual_dec: e.target.value })}></input> */}
                                </td>
                            </tr>
                        </tbody>

                    </td>
                </tr>
            </tbody>
        )
    }
    return (
        <>
            <DataGrid
                dataSource={ds}
                rowAlternationEnabled={true}
                ref={refGrid}
                paging={false}
                onToolbarPreparing={onToolbarPreparing}
                onRowRemoving={onRowRemoving}
                onRowInserted={updateSum}
                onRowUpdated={updateSum}
                onRowRemoved={updateSum}
                showRowLines={true}
                showColumnLines={true}
                showBorders={true}
                rowRender={RowRender}
                className="headerCustomTimeline gridBorder gridBorder-col gridBorder-content"
            >
                <LoadPanel enabled={false} />
                {/* {
                    allowEditing && (<RowDragging
                        allowReordering={true}
                        onReorder={onReorder}
                        showDragIcons={true}
                    />)
                } */}
                {
                    columns.map(({
                        dataField,
                        minWidth = null,
                        caption,
                        custom = false,
                        width = null,
                        dataType,
                        dSource = [],
                        displayExpr,
                        valueExpr,
                        visible = true,
                        customType = "lookup",
                        format }, i) => custom === true ?
                            (
                                <Column
                                    fixed={true}
                                    minWidth={minWidth}
                                    width={width}
                                    cellRender={cellRender}
                                    dataField={dataField}
                                    allowSorting={false}
                                    caption={caption}
                                    key={i}
                                    visible={visible}
                                    dataType={dataType}
                                >
                                    {
                                        CreateCustom({
                                            type: customType,
                                            dataType: dataType,
                                            dataSource: dSource,
                                            displayExpr: displayExpr,
                                            valueExpr: valueExpr
                                        })
                                    }
                                </Column>

                            ) : (
                                < Column
                                fixed={true}
                                    minWidth={minWidth}
                                    allowSorting={false}
                                    width={width}
                                    dataField={dataField}
                                    cellRender={cellRender}
                                    caption={caption}
                                    key={i}
                                    visible={visible}
                                    format={format}
                                    dataType={dataType}
                                ></Column>
                            )
                    )
                }
            </DataGrid>
            <div style={{ padding: 10, borderLeft: '1px solid #006CB8', borderRight: '1px solid #006CB8', borderBottom: '1px solid #006CB8' }} >
                <span style={{ display: "block", textAlign: "center" }}>Total Plan $ {parseFloat(sum).toFixed(2)} (Ribu USD)</span>
            </div>
            {
                showTotalActual &&
                <div style={{ padding: 10, borderLeft: '1px solid #006CB8', borderRight: '1px solid #006CB8', borderBottom: '1px solid #006CB8' }} >
                    <span style={{ display: "block", textAlign: "center" }}>Total Realisasi $ {parseFloat(sumActual).toFixed(2)} (Ribu USD)</span>
                </div>
            }
        </>
    )
}
function CreateCustom(props) {
    if (props.type === "lookup") {
        return (
            <Lookup dataSource={props.dataSource} dataType={props.dataType} displayExpr={props.displayExpr} valueExpr={props.valueExpr} />
        )
    }
}
