import React from 'react';
import useLabMaster, { LabMasterProvider } from './useLabMaster';
import { HeaderList, Tab, TabContent } from '../../../../components';
import { Card } from 'react-bootstrap';
import TabJenis from './component/tabJenis';
import TabParameterAnalisis from './component/tabParameterAnalisis';

function Index(props) {
    const {lmAction, lmState} = useLabMaster();
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={"Admin Laboratory Data Master"}
                someText={""}
            />
            <div className="row">
                <div className="col-12">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "jenis",
                                title: "Jenis Komoditi",
                                onClick: () => lmAction.tabChangeHandle("jenis")
                            },
                            {
                                id: "parameter",
                                title: "Parameter Analisis",
                                onClick: () => lmAction.tabChangeHandle("parameter")
                            },                            
                        ]}
                        manualActive={lmState.tabActive}
                    >
                        <TabContent id="jenis">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabJenis/>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                        <TabContent id="parameter">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabParameterAnalisis/>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>                        
                    </Tab>
                </div>
            </div>
        </div>
    );
}

export default () => <LabMasterProvider><Index /></LabMasterProvider>;