import React, { memo, useEffect, useRef, useState } from 'react';
import { GridDx } from '../../components'
import { Link } from 'react-router-dom'
import { useGlobalState } from '../../contexts/useGlobalState';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import KerjasamaGridDetail from './kerjasamaGridDetail';
import useKejasama from './useKerjasama';

const filterWarning = [{ text: "Expired", value: 1 }, { text: "Warning", value: 2 }, { text: "Running", value: 3 }, { text: "None", value: 0 }, { text: "Selesai", value: 4 }];

const TabTable = memo((props) => {
    const tableRef = useRef();
    const [state, dispatch, action] = useGlobalState();
    const { krjState, krjAction } = useKejasama();
    const [dataTable, setDataTable] = useState([]);
    const [filterCountry, setfilterCountry] = useState([]);
    const [filterProvince, setfilterProvince] = useState([]);
    const [column, setColumn] = useState([
        { fieldName: "updated_at_form", caption: "Last Modified", width: 100 },
        { fieldName: "warning", caption: "Status Expired", width: 200, headerFilterSource: filterWarning },
        { fieldName: "judul", caption: "Judul Partnership", width: 300 },
        { fieldName: "nama_partner", caption: "Partner", width: 200, allowFiltering: true },
        { fieldName: "project_cluster_name", caption: "Project Cluster", width: 150 },
        { fieldName: "total_sa_cs_pc_tpta", caption: "Partnership Score", width: 150 },
        { fieldName: "country", caption: "Country", width: 150, headerFilterSource: filterCountry, 'calculateFilterExpression': calculateFilterExpression },
        { fieldName: "province", caption: "Province", width: 150, headerFilterSource: filterProvince, 'calculateFilterExpression': calculateFilterExpression },
        { fieldName: "function_name", caption: "Fungsi VP", width: 150 },
        { fieldName: "chief_name", caption: "Fungsi Manager", width: 150 },
        { fieldName: "jenis_dokumen_name", caption: "Status Dokumen", width: 150 },
        // { fieldName: "status_dokumen_name", caption: "Status Dokumen", width: 150 },
        // { fieldName: "berlaku_start", caption: "Mulai Berlaku", width: 150 },
        // { fieldName: "berlaku_end", caption: "Sampai Dengan", width: 150 },
        { fieldName: "rkap", caption: "Related RKAP", width: 200 },
        { fieldName: "created_by_name", caption: "Created By", width: 150 },
    ]);

    useEffect(() => {
        krjAction.getDashboardBox();
        action.getData('/api/ext-partnership/table-2', {}, null, 'loadingTable', true).then((resp) => {
            if (resp.code === '00') {
                var fc = [];
                var fp = [];
                setDataTable([...(resp.data?.tabel_kerjasama || [])].map(d => {
                    fc = [...fc, ...(d?.country || '').split(';')];
                    fp = [...fp, ...(d?.province || '').split(';')];
                    return {
                        ...d,
                        // kerjasama_fungsies: [...(d?.kerjasama_fungsies || [])].map(x => x?.show_chief_manager).join(','),
                        // kerjasama_rkaps: [...(d?.kerjasama_rkaps || [])].map(x => x?.show_research).join(','),
                        // show_jenis_dokumen: [...(d?.show_jenis_dokumen || [])].join(','),
                        // show_status_dokumen: [...(d?.show_status_dokumen || [])].join(','),
                        // show_berlaku_start: [...(d?.show_berlaku_start || [])].join(','),
                        // show_berlaku_end: [...(d?.show_berlaku_end || [])].join(','),
                    }
                }))
                fc = [...new Set(fc)].filter(d => d).map(d => ({ text: d, value: d }));
                fp = [...new Set(fp)].filter(d => d).map(d => ({ text: d, value: d }))
                setfilterCountry(fc);
                setfilterProvince(fp);
                setColumn([
                    { fieldName: "updated_at_form", caption: "Last Modified", width: 100 },
                    { fieldName: "warning", caption: "Status Expired", width: 200, headerFilterSource: filterWarning },
                    { fieldName: "judul", caption: "Judul Partnership", width: 300 },
                    { fieldName: "nama_partner", caption: "Partner", width: 200, allowFiltering: true },
                    { fieldName: "project_cluster_name", caption: "Project Cluster", width: 150 },
                    { fieldName: "total_sa_cs_pc_tpta", caption: "Partnership Score", width: 150 },
                    { fieldName: "country", caption: "Country", width: 150, headerFilterSource: fc, 'calculateFilterExpression': calculateFilterExpression, selectedFilterOperation: 'contains' },
                    { fieldName: "province", caption: "Province", width: 150, headerFilterSource: fp, 'calculateFilterExpression': calculateFilterExpression, selectedFilterOperation: 'contains' },
                    { fieldName: "function_name", caption: "Fungsi VP", width: 150 },
                    { fieldName: "chief_name", caption: "Fungsi Manager", width: 150 },
                    { fieldName: "jenis_dokumen_name", caption: "Status Dokumen", width: 150 },
                    // { fieldName: "status_dokumen_name", caption: "Status Dokumen", width: 150 },
                    // { fieldName: "berlaku_start", caption: "Mulai Berlaku", width: 150 },
                    // { fieldName: "berlaku_end", caption: "Sampai Dengan", width: 150 },
                    { fieldName: "rkap", caption: "Related RKAP", width: 200 },
                    { fieldName: "created_by_name", caption: "Created By", width: 150 },
                ])
            }
        });
    }, [])
    function calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        let column = this;
        if (filterValue) {
            let selector = (data) => {
                let applyOperation = (arg1, arg2, op) => {
                    if (op === "=") return arg1 === arg2;
                    if (op === "contains") return arg1.includes(arg2);
                    if (op === "startswith") return arg1.startsWith(arg2);
                    if (op === "endswith") return arg1.endsWith(arg2);
                };
                let values = (column.calculateCellValue(data))?.split(';');
                return (
                    values &&
                    !!values.find((v) =>
                        applyOperation(v, filterValue, selectedFilterOperation)
                    )
                );
            };
            return [selector, "=", true];
        }
        return column.defaultCalculateFilterExpression.apply(this, arguments);
    }
    function cstmCellRender(e) {
        if (e.column.name === "judul") {
            return (
                <Link to=
                    {
                        {
                            pathname: "/KerjasamaDetail/" + e.data.id,
                        }
                    }
                    target='_blank'
                >
                    <span className={`text-wrap ${e.data?.warning === 1 ? 'text-danger' : ''}`}>
                        {e.value}
                        {e.data?.warning === 1 && (<i title='Expired' className='fas fa-exclamation-circle icon-nm ml-2 text-danger'></i>)}
                    </span>
                </Link>
            )
        } else if (e.column.name === 'nama_partner') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => {
                            const partner = (d || '')?.split('|');
                            return (
                                <li className=" text-wrap p-2" key={i}>
                                    <span className='d-block'><b>{(partner[0] || '')}</b></span>
                                    <span className='d-block'>{(partner[1] || '')}</span>
                                    <span className='d-block'>{(partner[2] || '')}</span>
                                    {/* <span className='d-block'>Country = {d?.country}</span>
                                    {
                                        d?.country === "Indonesia" && <span>Province:{d?.Province}</span>
                                    } */}
                                </li>
                            )
                        })
                    }
                </ul>
            )
        } else if (e.column.name === 'function_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'chief_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'country') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'province') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'reviewer_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(',') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "jenis_dokumen_name") {
            return (
                <div>
                    <span className='d-block mb-2'>{`${e.value} (${e?.data?.status_dokumen_name})`}</span>
                    <span className='d-block'>{`${e?.data?.berlaku_start || ''} - ${e?.data?.berlaku_end || ''}`}</span>
                </div>
            )
        } else if (e.column.name === 'rkap') {
            return (
                // <span className="text-wrap">{e.value}</span>
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'warning') {
            const text = filterWarning.find(d => d.value === e.value)?.text || '';
            const color = e.value === 1 ? "text-danger" :
                e.value === 2 ? "text-warning" :
                    e.value === 3 ? "text-success" :
                        e.value === 0 ? "text-secondary" : ""
            return (
                <span className={`text-wrap ${color}`}>{text}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-3">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-3 col-md-3 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded icon-header shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Design/Difference.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block text-wrap">Total dokumen partnership yang telah disahkan</span>
                                    <span className="d-block"><strong>
                                        {[...(krjState.dashboard_box || [])].reduce((tot, val) => tot = tot + val?.jumlah, 0)}
                                        {/* {krjState.header?.jumlahTotal} */}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[0] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">MoU</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahMou} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 1)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[1] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NDA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahNDA} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 2)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[2] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PKS</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahPKS} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 3)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[3] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">JSA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahJSA} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 4)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[4] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">HoA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahHoa} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 5)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12 ">
                            <GridDx
                                toolbar={
                                    <Link to={'/partnership-add'}
                                    // target="_blank"
                                    >
                                        <button className="btn-toolbar btn btn-primary mb-3">
                                            <i className="fa fa-plus icon-nm my-auto"></i> Partnership
                                        </button>
                                    </Link>
                                }
                                className='custom-style-master'
                                masterDetail={true}
                                repaintChangesOnly={true}
                                filterOperations={["contains", "endswith", "=", "startswith"]}
                                //onRowExpanded={getData}
                                ComponenMasterDetail={KerjasamaGridDetail}
                                searchForm={true}
                                ref={tableRef}
                                loading={state.loadingTable}
                                data={dataTable}
                                cellRender={cstmCellRender}
                                columns={column}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})

export default TabTable;