import React, { useEffect, useRef } from 'react';
import { GridDx } from '../../components';
import useEquipment from '../equipmentMng/useEquipment';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { equipment_iso_1705_filter, equipment_pc_filter } from '../../components/utility/utility';
const column_table = [
    { fieldName: 'id', visible: false, caption: 'ID', allowFiltering: false },
    { fieldName: 'nomor', caption: 'Nomor Asset', allowFiltering: false },
    { fieldName: 'nomor_seri', caption: 'Nomor Seri', allowFiltering: false },
    { fieldName: 'nama', caption: 'Nama Alat', width: 300, allowFiltering: false },
    { fieldName: 'nama_tipe', caption: 'Tipe Alat', allowFiltering: true },
    { fieldName: 'merk', caption: 'Merk', allowFiltering: false },
    { fieldName: 'utilitas', caption: 'Utilitas Pendukung', allowFiltering: false },
    { fieldName: 'supporting', caption: 'Supporting Equipment', allowFiltering: false },
    { fieldName: 'daya_listrik', caption: 'Daya Listrik (Watt)', allowFiltering: false },
    { fieldName: 'harga_alat', caption: 'Harga (USD)', allowFiltering: false },
    { fieldName: 'tahun_pembelian', caption: 'Tahun Pembelian', allowFiltering: true },
    { fieldName: 'pc', caption: 'PC', headerFilterSource: equipment_pc_filter, onExport: (e) => e?.pc === true ? "Ada" : e?.pc === false ? "Tidak Ada" : "" },
    { fieldName: 'iso_17025', caption: 'Lingkup Akreditasi ISO 17025', headerFilterSource: equipment_iso_1705_filter, onExport: (e) => e?.iso_17025 === true ? "Ya" : e?.iso_17025 === false ? "Tidak" : "" },
    { fieldName: 'usia', caption: 'Umur (Tahun)', allowFiltering: false },
    { fieldName: 'nama_lab', caption: 'Lab' },
    { fieldName: 'nama_lokasi', caption: 'Lokasi', allowFiltering: true },
    { fieldName: 'nama_PIC', caption: 'PIC', allowFiltering: false },
    { fieldName: 'nama_status', caption: 'Status Terakhir', allowFiltering: false },
    { fieldName: 'show_end', caption: 'Sampai Dengan', allowFiltering: false },
    { fieldName: 'biaya_total', caption: 'Biaya Perbaikan / Pemeliharaan (USD)', allowFiltering: false },
]

function TabTable(props) {
    const { eqAction, eqState } = useEquipment();
    const tableRef = useRef();
    useEffect(() => {
        eqAction.getTableReport();
    }, [])

    return (
        <div>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        ref={tableRef}
                        searchForm={true}
                        settingColumn={true}
                        className='custom-style-master'
                        filenameExport={`Equipment Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                        cellRender={eqAction.cellRenderMaster}
                        loading={eqState.loadingTableR}
                        selectionMode={"multiple"}
                        exportMode="selection"
                        exportExcel={true}
                        columns={column_table}
                        //onRowExpanded={(e) => ceqAction.onRowExpandedMaster(e?.key)}
                        //ComponenMasterDetail={EquipmentGridDetail}
                        data={eqState.dataTableR?.tabelDetail}
                    />

                </Card.Body>
            </Card>
        </div>
    );
}

export default TabTable;