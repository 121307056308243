import React from 'react';
import { HeatmapRisk, HeatmapRiskTable } from '../../../components';
import useProjectProfile from './useProjectProfile';
// import {} from ''

const ChartProjectRisk = () => {


    const { pfState, pfAction } = useProjectProfile();
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <HeatmapRiskTable data={(pfState.data?.data_view_risk || []).map(({ inher_probabil, inher_dampak }) => ({ probabilitas: inher_probabil, dampak: inher_dampak }))} />
                    {/* <HeatmapRisk id="risk-1" data={(pfState.data?.data_view_risk || []).map(({ inher_probabil, inher_dampak }) => ({ probabilitas: inher_probabil, dampak: inher_dampak }))} /> */}
                </div>
            </div>
        </>
    );
}

export default ChartProjectRisk;