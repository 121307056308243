import React, { memo } from 'react'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useGlobalState } from '../../contexts/useGlobalState'

const cut_off_option = function () {
    const opt = []
    for (let i = 1; i <= 31; i++) {
        opt.push({
            name: i,
            value: i
        })
    }
    return opt
}
export default memo(function () {
    const [isEditing, setIsEditing] = useState(false)
    const [state, dispatch, action] = useGlobalState()

    return (
        <>
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Header>
                    <span className="d-block" style={{ fontWeight: 'bold' }}>Cut Off Date</span>
                    <span className="d-block" style={{ color: '#C6C6C6' }}>Setting Cut Off Date</span>
                </Card.Header>
                <Card.Body className="p-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="row my-5">
                                <div className="col-md-3">
                                    Batasan Tanggal
                                </div>
                                <div className="col-md">
                                    {
                                        isEditing === false ? (
                                            <div className="p-2 bg-light-primary text-white text-center rounded text-dark" style={{ width: '50px' }}>{state.formData.cut_off || 1}</div>
                                        ) : (
                                            <select className="form-control form-control-sm"
                                                value={state.formData.cut_off || 1}
                                                onChange={(e) => action.setForm("cut_off", e.target.value)}
                                            >
                                                {
                                                    cut_off_option()?.map((d, i) => (
                                                        <option key={i} value={d.value}>{d.name}</option>
                                                    ))
                                                }
                                            </select>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    Pratinjau
                                </div>
                                <div className="col-md">
                                    <span className="d-block p-3 text-center bg-light-primary text-dark rounded">
                                        <i className="fa fa-calendar-alt icon-sm text-primary mx-3"></i>
                                        Input realisasi bulan Juli 2021 dapat dilakukan hingga tanggal&nbsp;
                                        {
                                            state.formData.cut_off || 1
                                        }
                                        &nbsp;Agustus 2021
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div className="d-block text-right">
                                {
                                    isEditing === false ? (
                                        <button className="btn btn-sm btn-primary m-1"
                                            onClick={() => setIsEditing(true)}
                                        >
                                            <i className="fa fa-pencil-alt text-white icon-sm"></i>
                                            Ubah
                                        </button>
                                    ) : (
                                        <>
                                            <button className="btn btn-sm btn-secondary m-1"
                                                onClick={() => setIsEditing(false)}
                                            >
                                                <i className="fa fa-undo icon-sm"></i>
                                                Kembali
                                            </button>
                                            <button className="btn btn-sm btn-primary m-1"
                                                onClick={() => setIsEditing(false)}
                                            >
                                                <i className="fa fa-save text-white icon-sm"></i>
                                                Save
                                            </button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})