import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useGlobalState } from '../../../contexts/useGlobalState';


const projectProfileContext = createContext(null)
export const PfProvider = ({ children }) => {
    const [gState, dispatch, gAction] = useGlobalState();
    const { baseline_name = undefined, id = undefined } = useParams()
    const [data, setData] = useState({});
    const [dataMilestone, setDataMilestone] = useState([])
    const [zoom, setZoom] = useState("80%");

    async function getDataProfile() {
        const { code, message, data = {} } = await gAction.getData(
            `/api/project-ongoing-crud/view-project-profile-${gState.dataUser?.role_id === '10' ? '4' : '2'}`,
            { id, baseline_name },
            null,
            "loadingGet",
            true
        );
        if (code === "00") {
            const { data_view_timeline = [] } = data;
            let newDataMilesTone = [];

            data_view_timeline
                .forEach((vt) => {
                    vt.data.filter(
                        vr => (vr
                            .type === "milestones" || vr.type === "submilestones"
                        )
                    ).forEach(md => newDataMilesTone = [...newDataMilesTone, {
                        task: md.task,
                        type: md.type.charAt(0).toUpperCase() + md.type.slice(1),
                        year: vt.year
                    }])
                });

            setDataMilestone(newDataMilesTone)
            // console.log(newDataMilesTone)
            setData(data);
        }
    }
    async function getDataUser() {
        gAction.getData('/api/me').then((resp) => {
            if (resp.code === "00") {
                dispatch({
                    type: "getSuccess",
                    payload: { dataUser: resp.data.data_user_me?.[0], loadingGet: false }
                })
            }
        })
    }
    function renderMilestonesItem(item) {
        return (
            <ol style={{ paddingLeft: '15px' }}>
                {
                    [...(item?.data || [])].filter(d => d?.type === "milestones" || d?.type === "submilestones").map((d, i) => (
                        <li className='text-wrap' key={i}>{d?.task} ({
                            ["plan_jan", "plan_feb", "plan_mar", "plan_apr", "plan_may", "plan_jun", "plan_jul", "plan_aug", "plan_sep", "plan_oct", "plan_nov", "plan_dec"].map(x => d[x] > 0 ? x.split('_')[1] : '').filter(x => x).join(",").toUpperCase()
                        })</li>
                    ))
                }
            </ol>
        )
    }

    function getObject(x) {
        if (Array.isArray(x))
            return x.length > 0 ? x[0] : {}
        return x || {}
    }

    useEffect(function () {
        if (gState.dataUser) {
            getDataProfile();
        } else {
            getDataUser();
        }
    }, [gState.dataUser, baseline_name])


    return <projectProfileContext.Provider value={{
        pfState: {
            data,
            zoom,
            dataMilestone,
            gState
        },
        pfAction: {
            getObject,
            setZoom,
            renderMilestonesItem
        }
    }}>{children}</projectProfileContext.Provider>;
}

export default function useProjectProfile() { return useContext(projectProfileContext) };