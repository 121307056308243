import React from "react";

import {
  ChartDx,
} from "../../../components";
import useMonthlyReport from "./useMonthlyReport";
const PageTracking = () => {

  const { rState, rAction } = useMonthlyReport();
  return (
    <>


      {/* Page Content */}
      <div className="page-content">
        {/* root rows */}
        <div className="row">
          {/* col-6 parents 1*/}
          <div className="col-6">
            {/* row 1 parents 1 */}
            <div className="row">
              <div className="col pl-2 pr-2">
                <h5>Milestone Tracking </h5>
                <p>
                  <b>Performance: {parseFloat(rState.data?.data_view_header?.perform_month || 0).toFixed(2)}% | Progress: {parseFloat(rState.data?.data_view_header?.perform_progress || 0).toFixed(2)}%</b>
                </p>
                <br />

                <ChartDx
                  loading={false}
                  dataSource={rAction.createDataScurve(rState.data?.data_view_scurve1)}
                  type="spline"
                  labelFormat="largeNumber"
                  withTitle={false}
                  showLegend={true}
                  toolsExport={false}
                  LegendverticalAlignment="bottom"
                  LegendhorizontalAlignment="center"
                  LegenditemTextPosition="right"
                  onRenderLabelChart={function (e) { }}
                  onRenderTooltip={(e) => ({
                    text: `${e.argumentText}: ${e.valueText}`,
                  })}
                  yAxisArgs={[
                    {
                      name: "Plan",
                      valueField: "plan",
                      color: "#D1D1D1",
                    },
                    {
                      name: "Realisasi",
                      valueField: "actual",
                      color: "#326BD5",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col pl-2 pr-2">
                <table
                  border="1"
                  style={{
                    textAlign: "left",
                    width: "97.5%",
                    border: "1px solid black",
                    fontSize: "12pt",
                  }}
                  className="tb-gray"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Milestone Description</th>
                      {/* <th>Date</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      rState?.dataMilestone?.map(({ task }, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{task}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {/* end row 1 parents 1 */}
          </div>
          {/* end col-6 parents 1 */}

          {/* col-6 parents 2*/}
          <div className="col-6">
            {/* row 1 parents 2 */}
            <div className="row">
              <div className="col pl-2 pr-2">
                <h5>Budget Tracking</h5>
                <p>
                  {/* <b>Expanse: {rState.data?.data_view_header?.budget_expense} $(ribu USD) | Performance: {rState.data?.data_view_header?.budget_perform_progress}%</b> */}
                  <b>Budget Expense/Plan (Ribu USD) : $ {rState.data?.data_view_header?.budget_expense} / {rState.data?.data_view_header?.budget_total} | Progress : {rState.data?.data_view_header?.budget_perform_progress}%</b>
                </p>
                <br />
                <ChartDx
                  loading={false}
                  dataSource={rAction.createDataScurve(rState.data?.data_view_scurve2)}
                  type="spline"
                  labelFormat="largeNumber"
                  withTitle={false}
                  showLegend={true}
                  toolsExport={false}
                  LegendverticalAlignment="bottom"
                  LegendhorizontalAlignment="center"
                  LegenditemTextPosition="right"
                  onRenderLabelChart={function (e) { }}
                  onRenderTooltip={(e) => ({
                    text: `${e.argumentText}: ${e.valueText}`,
                  })}
                  yAxisArgs={[
                    {
                      name: "Plan",
                      valueField: "plan",
                      color: "#D1D1D1",
                    },
                    {
                      name: "Realisasi",
                      valueField: "actual",
                      color: "#F08044",
                    },
                  ]}
                />
              </div>
            </div>
            {/* end rows 1 parents 2 */}
            {/* end rows 1 parents 2 */}
            <div className="row mt-3">
              <div className="col pl-2 pr-2">
                <table
                  border="1"
                  style={{
                    textAlign: "left",
                    width: "97.5%",
                    border: "1px solid black",
                    fontSize: "12pt",
                  }}
                  className="tb-gray"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Budget Description</th>
                      <th>Cost $ (Ribu USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      rState?.dataBudget?.map(({ description, ...b }, i) => {
                        return (
                          <tr key={i}>
                            <td style={{ padding: 10 }}>{i + 1}</td>
                            <td style={{ padding: 10 }}>{description}</td>
                            <td style={{ padding: 10 }} className="text-right">{
                              b?.plan_jan +
                              b?.plan_feb +
                              b?.plan_mar +
                              b?.plan_apr +
                              b?.plan_may +
                              b?.plan_jun +
                              b?.plan_jul +
                              b?.plan_aug +
                              b?.plan_sep +
                              b?.plan_oct +
                              b?.plan_nov +
                              b?.plan_dec
                            }</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2" className="text-center">
                        Total Budget
                      </td>
                      <td className="text-right">
                        {
                          rState?.dataBudget?.reduce((tot, curr) => tot = tot + (curr?.plan_jan +
                            curr?.plan_feb +
                            curr?.plan_mar +
                            curr?.plan_apr +
                            curr?.plan_may +
                            curr?.plan_jun +
                            curr?.plan_jul +
                            curr?.plan_aug +
                            curr?.plan_sep +
                            curr?.plan_oct +
                            curr?.plan_nov +
                            curr?.plan_dec), 0)
                        }
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            {/* end rows 1 parents 2 */}
          </div>
          {/* end col-6 parents 2 */}
        </div>

        {/* end root rows */}
      </div>
      {/* End Of Page Content */}
    </>
  );
};

export default PageTracking;
