import React, { memo, useEffect, useState } from 'react'
import { useGlobalState } from '../../contexts/useGlobalState';
import { Card } from 'react-bootstrap'
import { RadioGrp } from '../../components';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { toString } from 'lodash';

const ModalFAQ = memo(({ data, mode }) => {
    const [state, , action] = useGlobalState();
    const [dataKategori, setDataKategori] = useState([]);
    const [category, setcategory] = useState('');
    const [question, setquestion] = useState('');
    const [answer, setanswer] = useState('');
    const [status, setstatus] = useState('0');
    const [id_edit, setId_edit] = useState(0);

    useEffect(() => {
        if (state.modal?.masterFaq === true) {
            getDataCategory();
            resetEditor();
            if (mode === 'Edit') {
                getDataEdit(data?.id);
            }
        }
    }, [state.modal?.masterFaq]);

    function resetEditor() {
        setcategory('');
        setquestion('');
        setanswer('');
        setstatus('0');
        setId_edit(0);
    }
    function getDataCategory() {
        action.getData(`/api/Faq/master-faq-category`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setDataKategori(resp.data?.category);
            }
        })
    }
    function getDataEdit(id) {
        action.getData(`/api/Faq/view-faq?id=${id}`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                const { faq } = resp.data;
                setcategory(faq?.category);
                setquestion(faq?.question);
                setanswer(faq?.answer);
                setstatus(toString(faq?.status));
                setId_edit(faq?.id);
            }
        })
    }
    function addFaq() {
        const data = {
            "id": id_edit,
            "category": category || '',
            "question": question || '',
            "answer": answer || '',
            "status": status || '',
            "created_at": ""
        }
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add FAQ? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/Faq/store-faq`,
                    data: data
                }).then((resp) => {
                    if (resp.code === '00') {
                        action.toggleModal('masterFaq');
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function updateFaq() {
        const data = {
            "id": id_edit,
            "category": category || '',
            "question": question || '',
            "answer": answer || '',
            "status": status || '',
            "created_at": ""
        }
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update FAQ? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/Faq/update-faq`,
                    data: data
                }).then((resp) => {
                    if (resp.code === '00') {
                        action.toggleModal('masterFaq');
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        if (data?.category === '') {
            isValid = false;
            message = [...message, "Category"];
        }
        if (data?.question === '') {
            isValid = false;
            message = [...message, "Question"];
        }
        if (data?.answer === '') {
            isValid = false;
            message = [...message, "Answer"];
        }
        if (data?.status === '') {
            isValid = false;
            message = [...message, "Status"];
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }
    function saveClick(){
        if(mode === "Add"){
            addFaq();
        }
        if(mode === "Edit"){
            updateFaq();
        }
    }
    return (
        <Card
            className="m-0 p-0 customShadow"
        >
            <Card.Body>
                <span><b>{mode}&nbsp;FAQ</b></span>
                <hr />
                <div className="row mb-3">
                    <div className="col-md-3">Category</div>
                    <div className="col-md-9">
                        <select className="form-control form-control-sm"
                            value={category}
                            onChange={(e) => setcategory(e.target.value)}
                        >
                            <option value={''}>- Pilih Kategori -</option>
                            {
                                dataKategori.map((d, i) => (
                                    <option value={d?.id} key={i}>{d?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-3">Question</div>
                    <div className="col-md-9">
                        <textarea className="form-control form-control-sm"
                            rows={5}
                            value={question}
                            onChange={(e) => setquestion(e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-3">Answer</div>
                    <div className="col-md-9">
                        <textarea className="form-control form-control-sm"
                            rows={5}
                            value={answer}
                            onChange={(e) => setanswer(e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-3">Status</div>
                    <div className="col-md-9">
                        <RadioGrp
                            className="px-3"
                            modeRow={true}
                            value={status}
                            onChange={(val) => setstatus(val)}
                            data={[{ label: 'Aktif', value: '1' }, { label: 'Inaktif', value: '0' }]}
                        />
                    </div>
                </div>
                <hr />
                <div className="d-block text-right">
                    <button className="btn btn-sm btn-secondary mx-1"
                        onClick={() => action.toggleModal("masterFaq")}
                    >
                        Batal
                    </button>
                    <button className="btn btn-sm btn-primary"
                        onClick={() => saveClick()}
                    >
                        <i className="fa fa-save icon-nm mx-1 text-white"></i>
                        Save
                    </button>
                </div>
            </Card.Body>
        </Card>
    )
})
export default ModalFAQ