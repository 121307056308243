import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import useLab from '../useLaboratory';
import ViewDetail from './viewDetail';

function ModalViewPengujian(props) {
    const { labAction, labState } = useLab();
    const closeModal = () => {
        labAction.setModalView({ show: false, default_data: {} });
    }
    useEffect(() => {
        if (labState.modalView?.show) {
            labAction.getDetail(labState.modalView?.default_data?.id_lab_test || "");
        }
    }, [labState.modalView?.show])
    return (
        <Modal size='xl' show={labState.modalView?.show} onHide={closeModal}>
            <Modal.Body>
                <ViewDetail data={labState.dataDetail} />
            </Modal.Body>
        </Modal>
    );
}

export default ModalViewPengujian;