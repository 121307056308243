import React, { memo, useEffect, useRef } from 'react';
import { useMemo } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';

export default memo(function ({
    children,
    name,
    title = null,
    onClose,
    footer = null,
    backdrop = true,
    baseColor = true,
    size = null
}) {

    const [state, dispatch, actions] = useGlobalState();

    const refModal = useRef(null)
    function closeModal() {
        actions.toggleModal(name)
        onClose !== undefined && onClose !== null && onClose()
    }

    const handleClickOutside = function (event) {
        if (refModal && !refModal.current.contains(event.target)) {
            // console.log(event.target)
            backdrop && actions.toggleModal(name)
        }
    }
    const createModal = useMemo(() => {
        return (
            <div onClick={handleClickOutside} className={`modal fade ${state.modal[name] ? "show" : ""}`} role="dialog" id={name} aria-hidden={!(state.modal[name] != null && state.modal[name] != undefined)}
                style={{
                    ...(
                        state.modal[name] === true ? {
                            display: "block",
                        } : {
                            display: "none"
                        }
                    ),
                    overflowY: "scroll",
                    // filter:"blur(4px)",
                    backdropFilter: "brightness(0.7) blur(3px)",
                    transition: "opacity 0.15s linear"
                }}
            >
                <div className="modal-dialog modal-lg" role="document" ref={refModal} style={{ maxWidth: size }}>
                    {<div className="modal-content" style={{
                        ...(!baseColor ? {
                            background: "none",
                            boxShadow: "none"
                        } : {})
                    }}>
                        {
                            (title !== null && baseColor) && <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {title}
                                </h5>
                                <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                                    <span aria-hidden="true">
                                        ×
                                    </span>
                                </button>
                            </div>
                        }
                        <div className="modal-body">
                            {children}
                        </div>
                        {
                            footer != null && <div className="modal-footer">
                                {footer}
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                                    Close
                                </button>
                            </div>
                        }
                    </div>}
                </div>
            </div>
        )
    }, [children, name, title, onClose, footer, backdrop, baseColor, size]);
    return createModal
})