import DataGrid, { Column } from 'devextreme-react/data-grid';
import React, { useState, useRef, useEffect } from 'react';
import './grid-dx-ganttchart.css';
const fieldName = ["plan_jan", "plan_feb", "plan_mar", "plan_apr", "plan_may", "plan_jun", "plan_jul", "plan_aug", "plan_sep",
    "plan_oct", "plan_nov", "plan_dec"]

export default function ({ dataSource, column, showActualPlan = false , defaultStyle = "Poppins"}) {
    const [ds, setDs] = useState(dataSource);
    useEffect(function () {
        setDs(dataSource)
    }, [dataSource])

    function DataRow(rowInfo) {
        return (
            <>
                <tbody className={`employee dx-row `} style={{ fontFamily: defaultStyle, fontSize: '8pt' }}>
                    <tr className={'main-row'}>
                        {
                            showActualPlan === false ? (
                                <td colSpan={rowInfo.data.type === 'task' ? '13' : undefined}>
                                    <div className="d-flex flex-column justify-content-between" style={{
                                        ...(rowInfo.data.type === "milestones" ? { fontWeight: "bold", paddingLeft: "10px", fontStyle: 'italic' } :
                                            rowInfo.data.type === "submilestones" ? { fontWeight: "bold", paddingLeft: "35px", fontStyle: 'italic' } :
                                                rowInfo.data.type === "task" ? { fontWeight: "bold", paddingLeft: "10px" } :
                                                    { fontWeight: "regular", paddingLeft: "35px" })
                                    }}>
                                        <span className={`textStyleTaskTitle text-wrap mb-2 ${rowInfo.data.type === "task" ? "text-uppercase" : ""}`}>{rowInfo.data.task}</span>
                                    </div>
                                </td>
                            ) : (
                                <td className='p-0' colSpan={rowInfo.data.type === 'task' ? '13' : undefined}>
                                    <div className="d-flex align-items-center" style={{
                                        height: '80px',
                                        ...(rowInfo.data.type === "milestones" ? { fontWeight: "bold", paddingLeft: "10px", fontStyle: 'italic' } :
                                            rowInfo.data.type === "submilestones" ? { fontWeight: "bold", paddingLeft: "35px", fontStyle: 'italic' } :
                                                rowInfo.data.type === "task" ? { fontWeight: "bold", paddingLeft: "10px" } :
                                                    { fontWeight: "regular", paddingLeft: "35px" })
                                    }}>
                                        <div className={`flex-grow-1 d-flex text-wrap p-2 ${rowInfo.data.type === "task" ? "text-uppercase" : ""}`}>{rowInfo.data.task}</div>
                                        {
                                            rowInfo.data.type !== 'task' && (
                                                <div className='d-flex flex-column h-100'>
                                                    <span className="textStyleTaskTitle text-right my-auto" style={{ fontWeight: 'bold' }}>PLAN</span>
                                                    <span className="textStyleTaskTitle text-right my-auto" style={{ fontWeight: 'bold' }}>REALISASI</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </td>
                            )
                        }

                        {
                            rowInfo.data.type !== 'task' && fieldName.map((f, i) => {
                                return (
                                    <td height="35px" className="p-0 bg-white" key={i}>
                                        <table className="h-100 w-100">
                                            <tbody>
                                                <tr className={(rowInfo.data.type === 'milestones' || rowInfo.data.type === "submilestones") ? "bgMlsPlan" : "bgTaskPlan"} style={{ height: '40px' }}>
                                                    <td>
                                                        <div className="d-block text-center my-2" style={{ height: '24.4px' }}>
                                                            <div className={
                                                                (
                                                                    rowInfo.data.type === "milestones" ? 'textStyleMilestone' :
                                                                        rowInfo.data.type === "submilestones" ? 'textStyleMilestone' :
                                                                            rowInfo.data.type === "task" ? 'textStyleTask' : 'textStyleTask'
                                                                ) +
                                                                (
                                                                    isNaN(rowInfo.data[f]) || Number(rowInfo.data[f]) === 0 ? ' textNull' : (rowInfo.data.type === "milestones" || rowInfo.data.type === "submilestones") ? ' bg-red' : ' bg-blue'
                                                                )
                                                            }
                                                            >
                                                                <div className={
                                                                    rowInfo.data.type === "milestones" || rowInfo.data.type === "submilestones" ? "innerMilestone"
                                                                        : "innerTask"
                                                                }
                                                                >
                                                                    {rowInfo.data[f] ? rowInfo.data[f] : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    showActualPlan && (
                                                        <tr style={{ height: '40px' }} className={((rowInfo.data.type === 'milestones' || rowInfo.data.type === "submilestones") ? "bgMlsActual" : "bgTaskActual")}>
                                                            <td>
                                                                {
                                                                    showActual(rowInfo, f)
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                )
                            })
                        }
                    </tr>
                </tbody>
            </>
        );
    }
    function showActual(rowInfo, f) {
        return (
            <div className="d-block text-center my-2" style={{ height: '24.4px' }}>
                <div className={
                    (
                        rowInfo.data.type === "milestones" ? 'textStyleMilestone border-red' :
                            rowInfo.data.type === "submilestones" ? 'textStyleMilestone border-red' :
                                rowInfo.data.type === "task" ? 'textStyleTask border-blue' : 'textStyleTask border-blue'
                    ) +
                    (
                        isNaN(rowInfo.data["actual" + f.substr(f.indexOf('_'), f.length)]) || Number(rowInfo.data["actual" + f.substr(f.indexOf('_'), f.length)]) === 0 ? ' textNull border-0' : ''
                    )
                }
                >
                    <div className={
                        rowInfo.data.type === "milestones" || rowInfo.data.type === "submilestones" ? "innerMilestone"
                            : "innerTask"
                    }
                    >
                        {isNaN(rowInfo.data["actual" + f.substr(f.indexOf('_'), f.length)]) || Number(rowInfo.data["actual" + f.substr(f.indexOf('_'), f.length)]) === 0 ? '' : rowInfo.data["actual" + f.substr(f.indexOf('_'), f.length)]}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <DataGrid
                paging={null}
                dataSource={ds}
                className="grid-ganchart headerCustomTimeline"
                rowRender={DataRow}
                showColumnLines={true}
            >
                {
                    column.map(({
                        fieldName,
                        dataType,
                        caption,
                        width = null,
                        minWidth = null,
                        visible = true
                    }, i) =>
                    (
                        fieldName === "type" ?
                            <Column
                                minWidth={minWidth}
                                width={width}
                                key={i}
                                dataType={dataType}
                                dataField={fieldName}
                                caption={caption}
                                visible={false} />
                            :

                            fieldName === "task" ?
                                <Column
                                    minWidth={minWidth}
                                    width={width}
                                    key={i}
                                    dataType={dataType}
                                    dataField={fieldName}
                                    caption=""
                                    allowSorting={false}
                                    visible={visible}
                                />
                                :
                                <Column
                                    width={width}
                                    key={i}
                                    dataType={dataType}
                                    dataField={fieldName}
                                    caption={caption}
                                    visible={visible}
                                />

                    )
                    )
                }
            </DataGrid>
        </>
    )
}