import React, { memo, useState, useEffect } from 'react';
import { HeaderList, Tab, TabContent } from '../../components';
import { useHistory, useLocation } from 'react-router-dom';
import TabDashboard from './tabDashboard';
import TabTable from './tabTable';
import { Card } from 'react-bootstrap';
import './inventory.css';
import SVG from "react-inlinesvg";
import useInventory, { InvProvider } from './useInventory';

function Inventory(props) {
    const { pathname, param } = useLocation();
    const { invAction, invState } = useInventory();
    const history = useHistory();
    return (
        <>
            <HeaderList
                title={invState.data_page?.page_title}
                someText={invState.data_page?.page_breadcrumbs}
            ></HeaderList>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/inventory/dashboard'
                            })
                        },
                        title: "Inventory Dashboard"
                    },
                    {
                        id: "table",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/inventory/list'
                            })
                        },
                        title: "Inventory List"
                    }
                ]}
                manualActive={pathname === "/inventory/list" ? "table" : "dashboard"}
            >
                <TabContent id="dashboard">
                    <TabDashboard ></TabDashboard>
                </TabContent>
                <TabContent id="table">
                    <TabTable></TabTable>
                </TabContent>
            </Tab>
        </>
    );
}

export default () => <InvProvider><Inventory /></InvProvider>;