import React from "react";
import { useAuthenticationIdAMan } from "./authenticationProvider";
import { useEffect } from "react";
import { useGlobalState } from "../contexts/useGlobalState";
import URLParse from "url-parse";
import Swal from "sweetalert2";

const LoginCallback = () => {
    const [, , action] = useGlobalState();
    const { LoginRequest, parseJwt } = useAuthenticationIdAMan();
    const token = window.localStorage.getItem("token")

    useEffect(() => {
        const defUrl = window.location.href.replace('#', '?');
        const Url = parseJwt(URLParse(defUrl, true)?.query?.id_token);
        if (token !== null && token !== "" && token !== undefined && token !== "null") {
            LoginRequest();
        } else {
            action.doLoginIdaman(true, { Email: Url?.email, Password: Url?.employee_id }, true).then(({success, message}) => {
                if (success) {
                    LoginRequest();
                } else {
                    return Swal.fire({
                        title: 'Error!',
                        text: message,
                        icon: 'error',
                    }).then(() => window.location.href = "/landing-page/beranda");
                }
            });
        }
    }, [])

    return <span>loading</span>;
};

export default LoginCallback;