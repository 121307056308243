import { FileUploader } from 'devextreme-react';
import React, { forwardRef, memo, useRef } from 'react';
import { useState } from 'react';
import { baseUrl } from '../../app.config';
import useLocalStorage from '../../contexts/useLocalStorage';

export default memo(
    forwardRef(function FileUploadDx({
        uploadUrl = '/api/base/upload-file-temp',
        uploadHeaders = {},
        onUploaded = (val) => null,
        disable = false,
        showFileList = false,
        multiple = false,
        withToken = true,
        labelText = "or Drop file hire | Max size 1 Mb",
        accept = "*",
        maxFileSize = 1000000,
        allowedFileExtensions = [],
        uploadMode = "instantly",
        invalidFileExtensionMessage="File type is not allowed"
    },
        ref
    ) {
        const [fixUrl, setFixUrl] = useState(`${baseUrl}${uploadUrl}`)
        const [token] = useLocalStorage("token", null);
        const [header, setHeader] = useState(() => {
            if (withToken) {
                return {
                    ...uploadHeaders,
                    'Authorization': 'Bearer ' + token
                }
            } else {
                return {
                    ...uploadHeaders
                }
            }
        });
        return (
            <div>
                <FileUploader
                    ref={ref}
                    invalidFileExtensionMessage={invalidFileExtensionMessage}
                    disabled={disable}
                    uploadHeaders={header}
                    allowedFileExtensions={allowedFileExtensions}
                    uploadUrl={fixUrl}
                    onUploaded={(e) => { onUploaded(e?.request.response) }}
                    className="custom-dx-fileuploader-file-name"
                    showFileList={showFileList} multiple={multiple}
                    selectButtonText="Select File"
                    readyToUploadMessage=" "
                    labelText={labelText} accept={accept} maxFileSize={maxFileSize} uploadMode={uploadMode} >
                </FileUploader>
            </div>
        );
    }
    ));