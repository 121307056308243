import React, { memo, useEffect, useRef, useState } from 'react'
import { Card } from 'react-bootstrap'
import StateManager from 'react-select'
import { GridDx, HeaderList } from '../../components'
import { useGlobalState } from '../../contexts/useGlobalState'

const columns = [
    { fieldName: "tipe_project", caption: "Type Project", width: 100 },
    { fieldName: "title", caption: "Title", minWidth: 100, link: true, url: "#" },
    { fieldName: "leader", caption: "Leader", width: 75 },
    { fieldName: "action_btn", caption: "Action", width: 75 },
]
const dataSource = [
    {
        tipe_project: "Product Development",
        title: 'Test1',
        leader: 'Velajuel'
    },
    {
        tipe_project: "Product Development",
        title: 'Test2',
        leader: 'Ariel'
    },
    {
        tipe_project: "Product Development",
        title: 'Test3',
        leader: 'Eladriel'
    },
    {
        tipe_project: "Product Development",
        title: 'Test4',
        leader: 'Artamiel'
    },
    {
        tipe_project: "Product Development",
        title: 'Test5',
        leader: 'Fermion'
    }
]
const dataSource1 = [

]
export default function () {
    const [state, dispatch, actions] = useGlobalState();
    const [ds1, setDs1] = useState(dataSource);
    const [ds2, setDs2] = useState(dataSource1);
    const refGridOne = useRef();
    const refGridTwo = useRef();

    useEffect(() => {
        if (refGridTwo.current) {
            // console.log("ngaruh")
            refGridTwo.current.instance.refresh()
        }
    }, [ds2])
    useEffect(() => {
        if (refGridOne.current) {
            refGridOne.current.instance.refresh()
        }
    }, [ds1])

    function gridOneSelectedChange(e) {
        // console.log("gridOne", e)
        actions.setForm("gridOneSelected", e)
    }
    function gridTwoSelectedChange(e) {
        // console.log("gridTwo", e)
        actions.setForm("gridTwoSelected", e)
    }
    function move() {
        const newData = state.formData.gridOneSelected?.dataSelected || []
        // console.log("datasss", newData)
        const temp = [...ds2, ...newData]
        const temp1 = ds1.filter((elem) => !newData.find(({ title }) => elem.title === title));
        // console.log(temp1)
        setDs1(temp1)
        setDs2(temp)
    }
    function remove() {
        const newData = state.formData.gridTwoSelected?.dataSelected || []
        const temp = [...ds1, ...newData]
        const temp1 = ds2.filter((elem) => !newData.find(({ title }) => elem.title === title));
        setDs1(temp)
        setDs2(temp1)
    }
    return (
        <>
            <HeaderList
                title="Migration Project"
            />
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body>
                    <div className="row mb-5">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group mb-2">
                                        <select className="form-control form-control-sm mr-1">
                                            <option disabled={true} selected value="">-All Type-</option>
                                        </select>
                                        <select className="form-control form-control-sm mr-1">
                                            <option disabled={true} selected value="">-Function-</option>
                                        </select>
                                        <select className="form-control form-control-sm mr-1">
                                            <option disabled={true} selected value="">-Chief/Manager-</option>
                                        </select>
                                        <div>
                                            <button type="submit" className="btn btn-sm btn-primary">
                                                <i className="fa fa-search icon-nm"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <div className="input-group mb-2">
                                        <select className="form-control form-control-sm mr-1">
                                            <option disabled={true} selected value="">-Function-</option>
                                        </select>
                                        <input className="form-control form-control-sm mr-1">
                                        </input>
                                        <div style={{ width: '38px' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <GridDx
                                ref={refGridOne}
                                data={ds1}
                                onSelectionData={(e) => gridOneSelectedChange(e)}
                                selectionMode="multiple"
                                columns={columns}
                            />

                        </div>
                        <div className="col-md-2 my-auto">
                            <div>
                                <div className="d-block text-center m-1">
                                    <div className="btn btn-sm btn-primary" onClick={() => move()} style={{ width: '126px' }}>
                                        <i className="fas fa-angle-right text-white"></i>
                                        <span className="d-block">Move Selected</span>
                                        <span className="d-block">
                                            {state.formData.gridOneSelected?.countSelected > 0 ? "(" + state.formData.gridOneSelected?.countSelected + ")" : ""}
                                        </span>
                                    </div>
                                </div>
                                <div className="d-block text-center m-1">
                                    <div className="btn btn-sm btn-orange" onClick={() => remove()} style={{ width: '126px' }}>
                                        <i className="fas fa-angle-left text-white"></i>
                                        <span className="d-block">Remove Selected</span>
                                        <span className="d-block">
                                            {state.formData.gridTwoSelected?.countSelected > 0 ? "(" + state.formData.gridTwoSelected?.countSelected + ")" : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group mb-2">
                                        <select className="form-control form-control-sm mr-1">
                                            <option disabled={true} selected value="">-All Status-</option>
                                        </select>
                                        <select className="form-control form-control-sm mr-1">
                                            <option disabled={true} selected value="">-Chief/Manager-</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12" style={{ height: '33px' }}></div>
                            </div>
                            <GridDx
                                ref={refGridTwo}
                                data={ds2}
                                onSelectionData={(e) => gridTwoSelectedChange(e)}
                                selectionMode="multiple"
                                columns={columns}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-block text-right">
                                <button className="btn btn-sm btn-primary"><i className="fa fa-save icon-nm text-white"></i>Save</button>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}