import React, { useEffect } from 'react';
import useEquipment, { EqProvider } from './useEquipment';
import { GridDx, HeaderList, Modal } from '../../components';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Button, Column } from 'devextreme-react/data-grid';
import ModalEditingHistory from '../crudEquipment/modalEditingHistory';
import ModalEditingLogbook from '../crudEquipment/modalEditingLogbook';
import useCrudEquipment, { CrudEquipmentProvider } from '../crudEquipment/useCrudEquipment';
import { status_equipment } from '../../components/utility/utility';

const column_history = [
    { fieldName: 'tanggal_mulai_str', caption: 'Tanggal', allowFiltering: false },
    { fieldName: 'equipment_status_str', caption: 'Status', allowFiltering: false },
    { fieldName: 'tanggal_selesai_str', caption: 'Sampai Dengan', allowFiltering: false },
    { fieldName: 'keterangan', caption: 'Keterangan ', allowFiltering: false },
    { fieldName: 'biaya', caption: 'Biaya', allowFiltering: false, width: 150 },
    // { fieldName: 'deskripsi', caption: 'Keterangan', allowFiltering: false },
]
const column_logbook = [
    { fieldName: 'status', caption: 'Status Alat', allowFiltering: false },
    { fieldName: 'show_start', caption: 'Mulai', allowFiltering: false },
    { fieldName: 'show_end', caption: 'Sampai Dengan', allowFiltering: false },
    { fieldName: 'pemakai_name', caption: 'Pemakai', allowFiltering: false },
    { fieldName: 'deskripsi', caption: 'Keterangan', allowFiltering: false },
]
function EquipmentDetail(props) {
    const { eqAction, eqState } = useEquipment();
    const { ceqAction, ceqState } = useCrudEquipment();
    const [state, dispatch, action] = useGlobalState()
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            eqAction.getDetail(id);
        }
    }, [id])

    return (
        <div className='container'>
            <Modal
                name="modalEqH"
                baseColor={true}
            >
                <ModalEditingHistory mode={ceqState.modeEditH} data={ceqState.dataEditH} />
            </Modal>
            <Modal
                name="modalEqL"
                baseColor={true}
            >
                <ModalEditingLogbook mode={ceqState.modeEditL} data={ceqState.dataEditL} />
            </Modal>
            <HeaderList
                title={eqState.data_paged?.page_title}
                someText={eqState.data_paged?.page_breadcrumbs}
            />
            <Card
                style={{ marginBottom: '50px' }}
                className="mx-0 mt-0 p-1 customShadow"
            >
                <Card.Body className="p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row mb-5">
                                <div className="col-md-3 mb-5">
                                    <div className="pr-5">
                                        <img style={{ width: '100%', height: 200, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                            src={eqState.dataDetail?.dataEquipment?.path}
                                            onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                    </div>
                                </div>
                                <div className="col-md-9 mb-5">
                                    <h5 className='font-bold'>{eqState.dataDetail?.dataEquipment?.nama}</h5>
                                    <hr />
                                    <span className="d-block text-wrap font-bold mb-1">Deskripsi Alat</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.deskripsi}</span>
                                    <span className="d-block text-wrap font-bold mb-1">Informasi Tambahan</span>
                                    <span className="d-block text-wrap text-dark-50">{eqState.dataDetail?.dataEquipment?.information}</span>
                                </div>
                            </div>
                            {
                                eqState.dataDetail?.dataEquipment?.status === '5' && (
                                    <div className="row justify-content-center mb-5">
                                        <div className="col-md-12">
                                            <div className='p-5 bg-danger rounded text-white text-center'>
                                                Alat dengan status 'UPP/Dumped' tidak bisa dirubah kembali !
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <span className="d-block font-bold mb-3 ">Detail Alat</span>
                                    <hr />
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Status Alat</span>
                                    <span className="d-flex text-wrap text-dark-50 mb-5">{status_equipment(eqState.dataDetail?.dataEquipment?.nama_status)}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Nomor Asset</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.nomor}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Nomor Seri</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.nomor_seri}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Tipe Alat</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.nama_tipe}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Merk</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.merk}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Utilitas Pendukung</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.utilitas}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Supporting Equipment</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.supporting}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Daya Listrik (Watt)</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.daya_listrik}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">PC</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.pc ? "Ada" : "Tidak Ada"}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Lingkup Akreditasi ISO 17025</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.iso_17025 ? "Ada" : "Tidak"}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Tahun Pembelian / Umur</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.tahun_pembelian} / {eqState.dataDetail?.dataEquipment?.usia} Tahun</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Dokumen Terkait</span>
                                    <div className="d-block">
                                        <ul>
                                            {
                                                [...(eqState.dataDetail?.dataEquipment?.list_dokumen || [])].map((d, i) => (
                                                    <li>
                                                        <a href={eqState.dataDetail?.baseURL + d.file_doc_name_with_url} target='_blank' download>
                                                            {d.file_doc_name}
                                                        </a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <span className="d-block text-wrap font-bold mb-3">Lokasi</span>
                                    <hr />
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Lokasi Alat</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.nama_lokasi}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Lab</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.nama_lab}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Penanggung Jawab</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.penanggung_jawab_name}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Operator</span>
                                    <ul>
                                        {
                                            [...(eqState.dataDetail?.dataEquipment?.nama_PIC_array_name || [])].map((d, i) => (
                                                <li key={i}>{d}</li>
                                            ))
                                        }
                                    </ul>
                                    {/* <span className="d-block text-wrap mb-5">Product Price</span> */}
                                    <span className="d-block text-wrap font-bold mb-3">Harga</span>
                                    <hr />
                                    <div className="mb-2">
                                        <label className="text-wrap font-weight-bold mb-1" style={{ width: '100px' }}>Harga Alat</label>
                                        <span className= "text-dark-50 mb-5 bg-light-primary mx-2 px-2 py-1 rounded text-primary">$ {eqState.dataDetail?.dataEquipment?.harga_alat}</span>
                                    </div>
                                    <div className="mb-2">
                                        <label className="text-wrap font-weight-bold mb-1" style={{ width: '100px' }}>Biaya Perbaikan / Pemeliharaan (USD)</label>
                                        <span className= "text-dark-50 mb-5 bg-light-warning mx-2 px-2 py-1 rounded text-warning">$ {eqState.dataDetail?.dataEquipment?.biaya_total}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between py-1">
                                        <span className="d-block text-wrap font-bold my-auto">History Status Alat</span>
                                        {
                                            (//eqState.path === '/admin/equipment-detail' && 
                                                (ceqState.editingH || eqState.dataDetail?.dataEquipment?.is_PIC === true)) && (
                                                <button className="btn btn-sm btn-primary"
                                                    onClick={() => ceqAction.addHistory(id)}
                                                >
                                                    <i className="fa fa-plus icon-nm"></i>
                                                    Status
                                                </button>
                                            )
                                        }
                                    </div>
                                    <hr className="mt-1" />
                                    <GridDx
                                        className='custom-style'
                                        columns={column_history}
                                        data={eqState.dataDetail?.dataHistory}
                                        cellRender={eqAction.customCellRenderHistory}
                                    >
                                        {
                                            (//eqState.path === '/admin/equipment-detail' && 
                                                (ceqState.editingH || eqState.dataDetail?.dataEquipment?.is_PIC === true)) && (

                                                <Column caption="Action" type="buttons" headerCellRender={ceqAction.headerCellRender} >
                                                    <Button name="customEdit" visible={true} render={(e) => ceqAction.editBtnH(e, id)} />
                                                </Column>
                                            )
                                        }
                                    </GridDx>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between py-1">
                                        <span className="d-block text-wrap font-bold my-auto">Log Book Pemakaian</span>
                                        {
                                            ( //eqState.path === '/admin/equipment-detail' && 
                                                eqState.dataDetail?.dataEquipment?.status === '3' && (ceqState.editingL || eqState.dataDetail?.dataEquipment?.is_PIC === true)) && (
                                                <button className="btn btn-sm btn-primary"
                                                    onClick={() => ceqAction.addLogbook(id)}
                                                >
                                                    <i className="fa fa-plus icon-nm"></i>
                                                    Logbook
                                                </button>
                                            )
                                        }
                                    </div>
                                    <hr className="mt-1" />
                                    <GridDx
                                        cellRender={eqAction.cellRenderLoogbook}
                                        className='custom-style'
                                        columns={column_logbook}
                                        data={eqState.dataDetail?.dataLogBook}
                                    >
                                        {
                                            (//eqState.path === '/admin/equipment-detail' && 
                                                eqState.dataDetail?.dataEquipment?.status === '3' && (ceqState.editingL || eqState.dataDetail?.dataEquipment?.is_PIC === true)) && (
                                                <Column caption="Action" type="buttons" headerCellRender={ceqAction.headerCellRender} >
                                                    <Button name="customEdit" visible={true} render={(e) => ceqAction.editBtnL(e, id)} />
                                                    <Button name="delete" visible={true} render={ceqAction.deleteBtnL} />
                                                </Column>
                                            )
                                        }
                                    </GridDx>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {/* <div className={`p-2 bg-white rounded shadow-lg floating-footer`} >
                <div className="row">
                    <div className="col-md-6 text-left">
                        {
                            eqState.path === '/admin/equipment-detail' && (
                                <button className="btn btn-sm btn-secondary"
                                    onClick={eqAction.backButton}
                                >Kembali</button>
                            )
                        }
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default () => <CrudEquipmentProvider><EqProvider><EquipmentDetail /></EqProvider></CrudEquipmentProvider>;