import React, { useRef } from 'react';
import './Test.scss';
import { useState } from 'react';
import { PDFExport, PDFMargin } from '@progress/kendo-react-pdf';
import html2canvas from 'html2canvas';
const ds = Array.from(Array(100), (d, i) => ({ id: i + 1, name: 'A' + i }));

function Test(props) {
  const pdfRef = useRef();
  const pdfCanva = useRef();
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);

  const exportAsImage = async (el) => {
    const canvas = await html2canvas(el, { scale: 10 });
    setImage(canvas.toDataURL("image/png", 100));
    if (pdfRef.current)
      pdfRef.current.save();
    // download the image
  }
  return (
    <div>
      <button className="btn btn-sm btn-primary"
        onClick={(e) => {
          if (pdfRef.current)
            pdfRef.current.save();
        }
        }
      >Export</button>
      <div className='mt-5'>
        <div className='bg-white doc-export-style' style={{
          height: '297mm',
          width: '210mm',
          paddingTop: '10mm',
          paddingBottom: '27mm',
          paddingLeft: '16mm',
          paddingRight: '16mm'
        }}>
          <PDFExport forcePageBreak=".page-break" paperSize="A4" ref={pdfRef} fileName={`testing.pdf`}>
            <PDFMargin top={"10mm"} left={"16mm"} bottom={"27mm"} right={"16mm"} />
            <div className='d-flex w-100 header-doc' style={{ border: '1px solid black' }}>
              <div className='w-50 border-right border-dark d-block'><span>PT. PERTAMINA (PERSERO)<br />LABORATORY - RTI</span></div>
              <div className='w-50'><span>LAPORAN KERUSAKAN DAN<br />TINDAKAN PERBAIKAN</span></div>
            </div>
            <div className='row-2 w-100 border-bottom border-left border-right border-top-0 border-dark'>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '110px' }}>Nama Alat/Mesin</div>
                <span className='text-wrap'>:</span>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '110px' }}>Lokasi Alat/Mesin</div>
                <span className='text-wrap'>:</span>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '110px' }}>Perkiraan Kerusakan</div>
                <span className='text-wrap'>:</span>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '110px' }}>Tanggal Kerusakan</div>
                <span className='text-wrap'>:</span>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '110px' }}>Running Hours</div>
                <span className='text-wrap'>:</span>
              </div>
            </div>
            <div className="row-3 w-100 border-bottom border-left border-right border-top-0 border-dark">
              <p>Kronologi Kejadian</p>
              <span>* Jika kolom ini tidak mencukupi maka dapat melampirkan keterangan tambahan.</span>
            </div>
            <div className='row-4 border-bottom border-left border-right border-top-0 border-dark'>
              <div className='w-50 border-right border-dark d-block'>
                <div className='d-flex h-100 flex-column justify-content-between text-center'>
                  <span>Mengetahui<br />Penanggung Jawab.</span>
                  <span>(...................................................)</span>
                </div>
              </div>
              <div className='w-50 mt-0'>
                <div className='d-flex h-100 flex-column justify-content-between text-center pt-1'>
                  <div>
                    <span className='d-block text-left'>Tanggal : ............................................</span>
                    <span>Yang Melaporkan<br />........................................</span>
                  </div>
                  <span>(...................................................)</span>
                </div>
              </div>
            </div>
            <div className="row-3 w-100 border-bottom border-left border-right border-top-0 border-dark">
              <p>Analisa</p>
            </div>
            <div className="row-3 w-100 border-bottom border-left border-right border-top-0 border-dark">
              <p>Tindakan Perbaikan</p>
            </div>
            <div className="row-7 w-100 border-bottom border-left border-right border-top-0 border-dark">
              <p className='text-center mb-1'>Serah Terima Hasil Tindakan Perbaikan</p>
              <div className='d-flex w-100 h-100'>
                <div className='w-50'>
                  <div className='d-flex h-100 flex-column justify-content-between content-left-row-7'>
                    <span>Yang Menyertakan<br />........................................</span>
                    <span>(...................................................)</span>
                  </div>
                </div>
                <div className='w-50'>
                  <div className='d-flex h-100 flex-column justify-content-between pt-1 content-right-row-7'>
                    <div>
                      <span className='d-block text-left'>Tanggal : ............................................</span>
                      <span>Yang Menerima<br />........................................</span>
                    </div>
                    <span>(...................................................)</span>
                  </div>
                </div>
              </div>
            </div>
            <p className='mt-2'><b>F02/C6-236</b></p>            
          </PDFExport>
        </div>        
      </div>
    </div>
  );
}

export default Test;