import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { HeaderList } from '../../components';
import useKnowledgeMng, { KnoProvider } from './useKnowledgeMng';

function ResearchDetail(props) {
    const { id } = useParams();
    const { knoAction, knoState } = useKnowledgeMng();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            knoAction.getResearchDetail(id);
        }
    }, [])
    return (
        <>
            <HeaderList
                title={knoState.data_pager?.page_title}
                someText={knoState.data_pager?.page_breadcrumbs}
            />
            <Card
                style={{ marginBottom: '40px' }}
                className="p-0 customShadow"
            >
                <Card.Body className="p-4">
                    <div className="row">
                        <div className="col-md-4 mb-5">
                            <div className="pl-5 pr-5">
                                <img style={{ width: '100%', minHeight: 200, maxHeight: 300, objectFit: 'cover', borderRadius: '3%' }} className="bg-secondary border"
                                    src={knoState.dataResearchD?.data_research?.cover_file_location}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                            </div>
                        </div>
                        <div className="col-md-7 mb-5">
                            <h5 className='font-bold'>{knoState.dataResearchD?.data_research?.title}</h5>
                            <br />
                            <p className="text-dark">Deskripsi</p>
                            <p>{knoState.dataResearchD?.data_research?.description}</p>
                            <hr />
                            <div className="row mb-5">
                                <div className="col-md-4">
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Kategori</span>
                                        <span className='font-bold'>{knoState.dataResearchD?.data_research?.research_category_name}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Tahun</span>
                                        <span className='font-bold'>{knoState.dataResearchD?.data_research?.year_end}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Leader</span>
                                        <span className='font-bold'>{knoState.dataResearchD?.data_research?.owner_name}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Team Member</span>
                                        <ul>
                                            {
                                                [...(knoState.dataResearchD?.data_research?.team_member || [])].map((d, i) => (
                                                    <li><span className='d-block font-bold'>{d?.name}</span></li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Area</span>
                                        <span className='font-bold'>{knoState.dataResearchD?.data_research?.area_research_name}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Tag</span>
                                        <span className='font-bold'>{knoState.dataResearchD?.data_research?.tag_list}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Status Project</span>
                                        <span className='font-bold'>{knoState.dataResearchD?.data_research?.workflow_state_state}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Source</span>
                                        {
                                            knoState.dataResearchD?.data_research?.status !== "upload_manual" && (
                                                <Link to={`/Ongoingresearch/view/${knoState.dataResearchD?.data_research?.id}`} target="_blank">
                                                    <span className='font-bold '>Open Project <i className='fas fa-external-link-alt icon-nm text-primary'></i></span>
                                                </Link>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer-nolayout" >
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        {
                            history.length > 1 && (
                                <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            )
                        }
                    </div>
                    <div className="col-md-6 text-md-right">
                        {
                            knoState.dataResearchD?.data_research?.file_location_name && (
                                <a target="_blank" href={knoState.dataResearchD?.data_research?.file_location_name} download>
                                    <button className="btn btn-sm btn-light-primary mx-1">
                                        <i className="fas fa-file-download icon-nm"></i>
                                        {/* Download {(knoState.dataResearchD?.data_research?.file_size || 0) < 1 ? ((knoState.dataResearchD?.data_research?.file_size || 0) * 1000) + " Kb" : knoState.dataResearchD?.data_research?.file_size + " Mb"} */}
                                    </button>
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <KnoProvider><ResearchDetail /></KnoProvider>;