import React from 'react';

function LabStatus({ status = "", className = "", children = "" }) {
    return (
        <div className='' style={{ fontSize:'8pt' }}>
            {
                [1, 3, 6, 10, 12].includes(status) && <span className={`px-3 py-1 bg-warning rounded ${className}`}>{children}</span>
            }
            {
                [2, 5, 8, 11].includes(status) && <span className={`px-3 py-1 bg-success rounded ${className}`}>{children}</span>
            }
            {
                [4, 7, 9, 13].includes(status) && <span className={`px-3 py-1 rounded ${className}`} style={{ background: '#181c3245' }}>{children}</span>
            }
        </div>
    );
}

export default LabStatus;