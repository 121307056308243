import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import useModalEditorSwa, { SwaEditModalProvider } from './useModalEditorSwa';
import Swal from 'sweetalert2';

const ModalAddendum = ({ id_swakelola = '' }) => {
    const { swaEditAction, swaEditState } = useModalEditorSwa();
    const [state] = useGlobalState();
    useEffect(() => {
        if (state.modal?.modalAddendum === true) {
            swaEditAction.handleAddendum.setNote_addendum('');
            swaEditAction.setSwakelola_id(id_swakelola);
        }
    }, [state.modal?.modalAddendum])

    function validation() {
        if (swaEditState.dataAddendum.note_addendum === '') {
            return Swal.fire({
                title: 'Error',
                text: "Catatan tidak boleh kosong",
                icon: 'error',
            })
        } else {
            swaEditAction.handleAddendum.addendum()
        }
    }
    return (
        <div>
            <Card
                className="customShadow"
            >
                <Card.Body>
                    <h5>Addendum Swakelola ?</h5>
                    <hr />
                    <div className="mx-5">
                        <span className="d-block">Swakelola di addendum dengan catatan :</span>
                        <textarea className="form-control form-control-sm" rows={4}
                            value={swaEditState.dataAddendum.note_addendum} onChange={(e) => swaEditAction.handleAddendum.setNote_addendum(e.target.value)}
                        ></textarea>
                    </div>
                    <hr />
                    <div className="text-md-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => swaEditAction.handleAddendum.batalAddendum()}
                        >Batal</button>
                        <button className="btn btn-sm btn-warning text-white"
                            onClick={() => validation()}
                        >
                            Addendum
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default (props) => <SwaEditModalProvider><ModalAddendum id_swakelola={props?.id_swakelola} /></SwaEditModalProvider>;