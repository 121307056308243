import React from 'react';
import { useHistory } from 'react-router-dom';

function Index(props) {
    const history = useHistory();
    return (
        <div className="row justify-content-center">
            <div className='col-lg-4 col-md-4 col-12 text-center'>
                <img width={'100%'} src='/images/oops.png' alt='error page'></img>
                <div>
                    <button className="btn btn-sm btn-primary mt-2" onClick={() => history.push('/')}>
                        <i className='fa fa-home icon-nm'></i>
                        Kembali ke Home
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Index;