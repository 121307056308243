export const mapindonesia = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [117.6272, -8.5064],
                [117.6347, -8.5577],
                [117.7339, -8.56494],
                [117.7511, -8.59203],
                [117.7476, -8.65059],
                [117.7706, -8.71411],
                [117.8005, -8.72671],
                [117.8649, -8.69836],
                [117.9643, -8.74235],
                [118.042, -8.66336],
                [118.1307, -8.65005],
                [118.1907, -8.67446],
                [118.2592, -8.65611],
                [118.2602, -8.58876],
                [118.0921, -8.49019],
                [118.0745, -8.45144],
                [117.9755, -8.46654],
                [117.8264, -8.36613],
                [117.69, -8.23815],
                [117.7187, -8.16267],
                [117.7737, -8.12987],
                [117.8297, -8.12762],
                [117.923, -8.08018],
                [117.9477, -8.1023],
                [118.0027, -8.09299],
                [118.0848, -8.11166],
                [118.1397, -8.13938],
                [118.1403, -8.20128],
                [118.1771, -8.26983],
                [118.2107, -8.28859],
                [118.2371, -8.34483],
                [118.3065, -8.37104],
                [118.376, -8.3322],
                [118.3766, -8.30573],
                [118.4821, -8.24431],
                [118.6543, -8.28915],
                [118.6959, -8.3531],
                [118.696, -8.43382],
                [118.666, -8.49455],
                [118.7134, -8.49515],
                [118.7031, -8.40608],
                [118.7351, -8.33774],
                [118.8045, -8.28351],
                [118.8935, -8.28139],
                [119.0086, -8.3121],
                [118.9956, -8.37304],
                [119.0554, -8.44954],
                [119.058, -8.5075],
                [119.0193, -8.52801],
                [119.0157, -8.56836],
                [119.0402, -8.64027],
                [119.0823, -8.64853],
                [119.1626, -8.63554],
                [119.1608, -8.70023],
                [119.136, -8.7498],
                [119.0251, -8.73356],
                [118.9733, -8.74543],
                [118.9094, -8.67704],
                [118.8719, -8.72035],
                [118.814, -8.69996],
                [118.7487, -8.69728],
                [118.7364, -8.75995],
                [118.8394, -8.76959],
                [118.9111, -8.7657],
                [118.952, -8.80798],
                [118.9095, -8.84041],
                [118.823, -8.83576],
                [118.7604, -8.79343],
                [118.6678, -8.8026],
                [118.5264, -8.87052],
                [118.4338, -8.88751],
                [118.368, -8.84007],
                [118.3683, -8.76699],
                [118.4155, -8.7207],
                [118.4147, -8.64578],
                [118.3432, -8.68399],
                [118.313, -8.72504],
                [118.2689, -8.74803],
                [118.1936, -8.83756],
                [118.1125, -8.88099],
                [118.0707, -8.84253],
                [117.994, -8.85823],
                [117.9719, -8.89648],
                [117.8884, -8.92488],
                [117.7711, -8.93188],
                [117.6781, -8.92221],
                [117.5918, -8.98328],
                [117.4106, -9.05105],
                [117.3334, -9.05713],
                [117.206, -9.02215],
                [117.1763, -9.02927],
                [117.1348, -9.08871],
                [117.0162, -9.11278],
                [116.9575, -9.06298],
                [116.8858, -9.06688],
                [116.7943, -9.04135],
                [116.7278, -8.98057],
                [116.7335, -8.87307],
                [116.7631, -8.86169],
                [116.8032, -8.79202],
                [116.7648, -8.74296],
                [116.7527, -8.67578],
                [116.7814, -8.59762],
                [116.831, -8.54152],
                [116.9356, -8.52108],
                [116.9938, -8.49331],
                [117.0946, -8.4171],
                [117.1216, -8.37079],
                [117.2359, -8.40081],
                [117.39, -8.47539],
                [117.4425, -8.40256],
                [117.4728, -8.41637],
                [117.5649, -8.40792],
                [117.6245, -8.44271],
                [117.6272, -8.5064]
              ]
            ],
            [
              [
                [116.3869, -8.95521],
                [116.3737, -8.90937],
                [116.3083, -8.9054],
                [116.2538, -8.91775],
                [116.1917, -8.91269],
                [116.1736, -8.86446],
                [116.0229, -8.90188],
                [115.9412, -8.83306],
                [115.833, -8.7992],
                [115.8193, -8.74502],
                [115.852, -8.71975],
                [115.913, -8.77189],
                [116.0066, -8.73022],
                [116.0682, -8.72393],
                [116.0982, -8.66515],
                [116.0939, -8.52486],
                [116.0652, -8.49738],
                [116.058, -8.44775],
                [116.0852, -8.40538],
                [116.1331, -8.39276],
                [116.2819, -8.25297],
                [116.37, -8.2136],
                [116.4473, -8.2233],
                [116.6033, -8.28462],
                [116.6502, -8.2903],
                [116.7153, -8.35162],
                [116.7078, -8.44609],
                [116.6622, -8.48923],
                [116.6654, -8.55788],
                [116.6227, -8.60767],
                [116.5379, -8.76436],
                [116.5104, -8.78135],
                [116.5681, -8.89245],
                [116.4771, -8.92124],
                [116.4524, -8.89981],
                [116.475, -8.82107],
                [116.4287, -8.82842],
                [116.3951, -8.86296],
                [116.4041, -8.94031],
                [116.3869, -8.95521]
              ]
            ],
            [
              [
                [117.5764, -8.1487],
                [117.6696, -8.15196],
                [117.6882, -8.17372],
                [117.6109, -8.23826],
                [117.5718, -8.33329],
                [117.5289, -8.39122],
                [117.4692, -8.35647],
                [117.509, -8.27402],
                [117.478, -8.25889],
                [117.4797, -8.19131],
                [117.5179, -8.16035],
                [117.5764, -8.1487]
              ]
            ],
            [
              [
                [119.0875, -8.12935],
                [119.1209, -8.16271],
                [119.1206, -8.22137],
                [119.0524, -8.26051],
                [119.0052, -8.23024],
                [119.0052, -8.17625],
                [119.0592, -8.12652],
                [119.0875, -8.12935]
              ]
            ]
          ]
        },
        "id": 52,
        "properties": {
          "ID": 2,
          "kode": 52,
          "Propinsi": "NUSA TENGGARA BARAT",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [122.188141, 1.045298],
              [122.226273, 1.00335],
              [122.320999, 1.010509],
              [122.375313, 0.997158],
              [122.4674, 1.025363],
              [122.494095, 0.990939],
              [122.589439, 0.954507],
              [122.653801, 0.949849],
              [122.690964, 0.895421],
              [122.718719, 0.895446],
              [122.847275, 0.816487],
              [122.925346, 0.861357],
              [122.950432, 0.910492],
              [122.948494, 0.965814],
              [122.989319, 0.968382],
              [123.028053, 0.933226],
              [123.088364, 0.911231],
              [123.117294, 0.92862],
              [123.1465, 0.881753],
              [123.240799, 0.83596],
              [123.257477, 0.749624],
              [123.368813, 0.661965],
              [123.494171, 0.636227],
              [123.527229, 0.57175],
              [123.51918, 0.449638],
              [123.465736, 0.330308],
              [123.416306, 0.313156],
              [123.343658, 0.309153],
              [123.269981, 0.325868],
              [123.199982, 0.396042],
              [123.165573, 0.404253],
              [123.122955, 0.465821],
              [123.071152, 0.510825],
              [123.000008, 0.484606],
              [122.895432, 0.484932],
              [122.836777, 0.495058],
              [122.712585, 0.478211],
              [122.548058, 0.493073],
              [122.535942, 0.507037],
              [122.423508, 0.502104],
              [122.406532, 0.518997],
              [122.280655, 0.475349],
              [122.228798, 0.486402],
              [122.177422, 0.471345],
              [122.138397, 0.491899],
              [122.056122, 0.479517],
              [121.949081, 0.445606],
              [121.876221, 0.44692],
              [121.814293, 0.414142],
              [121.717461, 0.521079],
              [121.572685, 0.536764],
              [121.534134, 0.550474],
              [121.528786, 0.49727],
              [121.45018, 0.468869],
              [121.419083, 0.484982],
              [121.345665, 0.474487],
              [121.35453, 0.571319],
              [121.247383, 0.597502],
              [121.168579, 0.690562],
              [121.225578, 0.708211],
              [121.260277, 0.78299],
              [121.294579, 0.82408],
              [121.386032, 0.870693],
              [121.499672, 0.850688],
              [121.620621, 0.883505],
              [121.673096, 0.914232],
              [121.754608, 0.926077],
              [121.831047, 0.963612],
              [121.882187, 0.9727],
              [121.925529, 0.946584],
              [121.968292, 0.894658],
              [121.993927, 0.94102],
              [122.02533, 0.949058],
              [122.067818, 0.997213],
              [122.188141, 1.045298]
            ],
            [
              [122.957306, 0.584448],
              [122.980988, 0.556596],
              [123.020927, 0.563377],
              [123.007317, 0.617577],
              [122.957306, 0.584448]
            ]
          ]
        },
        "id": 75,
        "properties": {
          "ID": 3,
          "kode": 75,
          "Propinsi": "GORONTALO",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [120.98423, -2.835341],
                [121.078339, -2.778087],
                [121.133423, -2.87806],
                [121.167259, -2.970928],
                [121.210396, -2.97805],
                [121.255768, -3.057803],
                [121.315308, -3.065957],
                [121.355957, -3.019798],
                [121.486954, -2.992209],
                [121.568336, -2.914573],
                [121.645721, -2.883784],
                [121.703339, -2.811771],
                [121.724182, -2.759546],
                [121.760185, -2.71899],
                [121.797012, -2.710249],
                [121.81871, -2.763949],
                [121.872299, -2.777285],
                [121.899376, -2.80432],
                [121.940231, -2.932159],
                [122.000359, -2.962439],
                [122.090057, -2.961801],
                [122.130539, -3.015782],
                [122.191582, -3.032609],
                [122.190437, -3.094346],
                [122.241173, -3.138603],
                [122.274086, -3.138374],
                [122.294449, -3.195179],
                [122.285965, -3.253934],
                [122.312798, -3.271106],
                [122.288254, -3.309927],
                [122.292755, -3.35732],
                [122.373749, -3.379456],
                [122.390915, -3.405149],
                [122.372299, -3.463288],
                [122.330208, -3.423481],
                [122.322395, -3.380808],
                [122.277771, -3.365802],
                [122.238159, -3.398722],
                [122.302132, -3.471626],
                [122.275734, -3.552534],
                [122.198738, -3.56528],
                [122.195618, -3.608036],
                [122.248512, -3.666231],
                [122.308617, -3.691788],
                [122.367409, -3.752801],
                [122.4011, -3.737035],
                [122.448067, -3.749501],
                [122.465042, -3.795845],
                [122.512268, -3.854226],
                [122.515129, -3.882586],
                [122.671906, -3.897003],
                [122.603531, -3.976277],
                [122.663445, -4.008508],
                [122.643234, -4.129524],
                [122.704361, -4.158368],
                [122.792816, -4.1576],
                [122.843758, -4.125524],
                [122.771515, -4.059357],
                [122.842255, -4.057042],
                [122.8918, -4.125212],
                [122.905563, -4.196556],
                [122.88662, -4.297779],
                [122.904427, -4.377195],
                [122.840179, -4.445022],
                [122.773346, -4.407986],
                [122.683952, -4.323638],
                [122.663979, -4.351196],
                [122.701942, -4.401843],
                [122.767426, -4.449976],
                [122.746147, -4.507518],
                [122.681007, -4.479528],
                [122.613434, -4.401861],
                [122.586288, -4.393665],
                [122.503342, -4.448484],
                [122.42131, -4.430861],
                [122.36808, -4.461316],
                [122.257019, -4.487848],
                [122.224892, -4.476355],
                [122.174736, -4.514592],
                [122.082832, -4.536929],
                [122.025444, -4.649319],
                [122.023575, -4.716242],
                [122.04731, -4.770176],
                [122.108398, -4.812253],
                [122.079865, -4.843765],
                [121.992233, -4.894716],
                [121.904724, -4.88616],
                [121.818916, -4.84867],
                [121.808197, -4.830569],
                [121.724449, -4.830998],
                [121.662598, -4.848654],
                [121.651039, -4.805225],
                [121.56263, -4.786319],
                [121.455299, -4.681352],
                [121.45945, -4.585207],
                [121.506104, -4.435465],
                [121.507233, -4.331302],
                [121.522499, -4.277308],
                [121.564728, -4.245612],
                [121.608521, -4.13802],
                [121.599144, -4.055925],
                [121.499374, -4.014913],
                [121.337173, -3.98036],
                [121.293785, -3.888254],
                [121.226234, -3.822368],
                [121.181694, -3.828741],
                [121.061172, -3.703674],
                [121.020912, -3.690099],
                [120.910744, -3.582204],
                [120.876328, -3.525793],
                [120.858788, -3.440268],
                [120.928894, -3.342],
                [120.95388, -3.329225],
                [120.966698, -3.26637],
                [121.016693, -3.244607],
                [121.051331, -3.184905],
                [121.056152, -3.070598],
                [121.084557, -2.923684],
                [121.071167, -2.89214],
                [120.98423, -2.835341]
              ]
            ],
            [
              [
                [122.97496, -5.080378],
                [122.974899, -5.114667],
                [122.942909, -5.160937],
                [122.962776, -5.195495],
                [123.032532, -5.13616],
                [123.085037, -5.172699],
                [123.151733, -5.246449],
                [123.176643, -5.245639],
                [123.21479, -5.304791],
                [123.135201, -5.395737],
                [123.014626, -5.44086],
                [122.972809, -5.394393],
                [122.921967, -5.43637],
                [122.888062, -5.441972],
                [122.846359, -5.485299],
                [122.897339, -5.518404],
                [122.897095, -5.56276],
                [122.815216, -5.65809],
                [122.797371, -5.695441],
                [122.744087, -5.637722],
                [122.661079, -5.689842],
                [122.623024, -5.679935],
                [122.578278, -5.571494],
                [122.563545, -5.480018],
                [122.647797, -5.425014],
                [122.617264, -5.403909],
                [122.646088, -5.356668],
                [122.651276, -5.326329],
                [122.682716, -5.320218],
                [122.732628, -5.241536],
                [122.793892, -5.241115],
                [122.806274, -5.200619],
                [122.737282, -5.170575],
                [122.788528, -5.106222],
                [122.80249, -5.037634],
                [122.768929, -5.008087],
                [122.811577, -4.978685],
                [122.832375, -4.890354],
                [122.807869, -4.866568],
                [122.858742, -4.783739],
                [122.831207, -4.679556],
                [122.850807, -4.590418],
                [122.910202, -4.460138],
                [123.040962, -4.370708],
                [123.080978, -4.45977],
                [123.136269, -4.483249],
                [123.199295, -4.605664],
                [123.21109, -4.722411],
                [123.201141, -4.780681],
                [123.171539, -4.834829],
                [123.149704, -4.735183],
                [123.103752, -4.713612],
                [123.038658, -4.800051],
                [122.993774, -4.828905],
                [123.001671, -4.93299],
                [122.969612, -4.950684],
                [122.955971, -5.000822],
                [122.97496, -5.080378]
              ]
            ],
            [
              [
                [122.311989, -5.189582],
                [122.322166, -5.108933],
                [122.382103, -5.084469],
                [122.377975, -4.944457],
                [122.353973, -4.920957],
                [122.321449, -4.834823],
                [122.374245, -4.743024],
                [122.451523, -4.74067],
                [122.536934, -4.704957],
                [122.596466, -4.641494],
                [122.668556, -4.609775],
                [122.730896, -4.638964],
                [122.721161, -4.689926],
                [122.747383, -4.792154],
                [122.727425, -4.832773],
                [122.762848, -4.887722],
                [122.735123, -4.955379],
                [122.679199, -5.035037],
                [122.637512, -5.059143],
                [122.58609, -5.182532],
                [122.637917, -5.2589],
                [122.640091, -5.352726],
                [122.599274, -5.35376],
                [122.592781, -5.388764],
                [122.545418, -5.438321],
                [122.511826, -5.402669],
                [122.539757, -5.326673],
                [122.524986, -5.288412],
                [122.481499, -5.295955],
                [122.495522, -5.34572],
                [122.463638, -5.400284],
                [122.363136, -5.385505],
                [122.275864, -5.388109],
                [122.268753, -5.306668],
                [122.315941, -5.233175],
                [122.311989, -5.189582]
              ]
            ],
            [
              [
                [121.805817, -5.245513],
                [121.808357, -5.201829],
                [121.843086, -5.139951],
                [121.859879, -5.074867],
                [121.930122, -5.102027],
                [121.94725, -5.05961],
                [121.986343, -5.092185],
                [121.992439, -5.15234],
                [122.026848, -5.14409],
                [122.060753, -5.18434],
                [122.051247, -5.260043],
                [122.043472, -5.455512],
                [122.029358, -5.46955],
                [121.944969, -5.474207],
                [121.945465, -5.446015],
                [121.875435, -5.380198],
                [121.802193, -5.27901],
                [121.805817, -5.245513]
              ]
            ],
            [
              [
                [123.003357, -3.999794],
                [123.03022, -3.981664],
                [123.086868, -4.022165],
                [123.144112, -4.010673],
                [123.215721, -4.022439],
                [123.254143, -4.066495],
                [123.254288, -4.114],
                [123.149765, -4.243471],
                [123.118874, -4.261611],
                [123.073792, -4.249185],
                [122.996155, -4.201651],
                [122.945335, -4.099027],
                [122.980415, -4.061724],
                [123.003357, -3.999794]
              ]
            ],
            [
              [
                [123.537064, -5.338479],
                [123.51709, -5.270007],
                [123.581436, -5.249413],
                [123.647369, -5.299086],
                [123.618355, -5.385718],
                [123.581528, -5.386153],
                [123.537064, -5.338479]
              ]
            ],
            [
              [
                [124.07579, -5.99996],
                [123.987404, -5.965688],
                [123.973602, -5.935432],
                [123.991692, -5.87584],
                [124.050156, -5.91096],
                [124.07579, -5.99996]
              ]
            ],
            [
              [
                [123.092873, -3.570833],
                [123.161133, -3.560991],
                [123.181412, -3.602412],
                [123.133232, -3.610474],
                [123.113304, -3.638883],
                [123.045197, -3.594425],
                [123.092873, -3.570833]
              ]
            ],
            [
              [
                [123.721954, -5.477765],
                [123.767891, -5.50372],
                [123.827209, -5.571645],
                [123.784508, -5.591027],
                [123.718681, -5.512402],
                [123.721954, -5.477765]
              ]
            ],
            [
              [
                [123.959496, -5.727983],
                [123.997734, -5.768229],
                [123.928597, -5.776584],
                [123.896194, -5.737903],
                [123.959496, -5.727983]
              ]
            ],
            [
              [
                [122.547577, -5.634616],
                [122.532173, -5.690142],
                [122.487648, -5.661558],
                [122.507607, -5.62051],
                [122.547577, -5.634616]
              ]
            ],
            [
              [
                [122.417923, -3.397853],
                [122.470146, -3.466125],
                [122.436958, -3.505473],
                [122.400879, -3.439657],
                [122.417923, -3.397853]
              ]
            ],
            [
              [
                [123.062531, -4.839228],
                [123.079178, -4.794402],
                [123.115738, -4.797104],
                [123.108261, -4.845974],
                [123.062531, -4.839228]
              ]
            ]
          ]
        },
        "id": 74,
        "properties": {
          "ID": 4,
          "kode": 74,
          "Propinsi": "SULAWESI TENGGARA",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [110.011826, -7.886903],
              [110.042953, -7.89204],
              [110.06321, -7.813906],
              [110.132286, -7.741732],
              [110.142883, -7.645877],
              [110.25954, -7.645667],
              [110.254532, -7.683113],
              [110.379147, -7.595244],
              [110.444958, -7.536631],
              [110.498291, -7.745311],
              [110.54336, -7.798195],
              [110.588886, -7.804361],
              [110.657083, -7.785867],
              [110.723794, -7.791503],
              [110.750929, -7.82466],
              [110.786538, -7.818944],
              [110.771225, -7.899249],
              [110.758797, -8.030742],
              [110.790009, -8.1165],
              [110.788666, -8.154592],
              [110.836136, -8.202934],
              [110.677467, -8.182687],
              [110.584984, -8.139478],
              [110.438988, -8.095153],
              [110.37883, -8.066612],
              [110.345947, -8.028257],
              [110.217957, -7.985723],
              [110.116516, -7.927296],
              [110.011826, -7.886903]
            ]
          ]
        },
        "id": 34,
        "properties": {
          "ID": 5,
          "kode": 34,
          "Propinsi": "DIY YOGYAKARTA",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [108.829338, -6.74608],
                [108.854889, -6.799195],
                [108.952652, -6.83733],
                [109.027512, -6.774646],
                [109.116945, -6.849453],
                [109.284729, -6.873732],
                [109.375954, -6.859314],
                [109.444908, -6.835716],
                [109.525772, -6.783483],
                [109.574196, -6.837207],
                [109.704632, -6.859836],
                [109.83173, -6.91085],
                [109.994609, -6.926026],
                [110.135114, -6.886767],
                [110.161217, -6.857075],
                [110.218449, -6.858777],
                [110.224055, -6.898671],
                [110.282545, -6.910134],
                [110.360636, -6.958464],
                [110.439422, -6.946518],
                [110.482428, -6.918728],
                [110.567362, -6.805168],
                [110.593362, -6.738227],
                [110.63764, -6.680221],
                [110.692026, -6.53139],
                [110.686522, -6.496921],
                [110.72816, -6.449049],
                [110.8402, -6.409402],
                [110.920682, -6.406552],
                [111.03451, -6.421138],
                [111.051265, -6.504015],
                [111.089495, -6.595203],
                [111.135153, -6.652081],
                [111.208681, -6.687917],
                [111.288116, -6.682676],
                [111.353966, -6.700903],
                [111.460175, -6.661293],
                [111.493095, -6.616364],
                [111.563789, -6.638492],
                [111.694595, -6.752857],
                [111.659874, -6.780993],
                [111.648499, -6.828923],
                [111.615326, -6.836943],
                [111.609039, -6.903624],
                [111.566604, -6.951681],
                [111.612236, -6.971057],
                [111.62486, -7.065532],
                [111.580716, -7.201196],
                [111.544593, -7.209885],
                [111.448722, -7.280662],
                [111.438232, -7.353508],
                [111.346387, -7.352231],
                [111.219641, -7.295056],
                [111.241771, -7.24923],
                [111.146592, -7.262771],
                [111.133153, -7.30824],
                [111.132803, -7.386832],
                [111.112659, -7.415796],
                [111.151654, -7.563807],
                [111.189209, -7.603958],
                [111.180164, -7.709611],
                [111.230736, -7.747669],
                [111.283073, -7.744789],
                [111.281219, -7.782755],
                [111.314003, -7.85079],
                [111.269707, -7.930492],
                [111.231346, -7.948041],
                [111.181503, -7.916514],
                [111.140442, -7.938492],
                [111.134033, -8.020032],
                [111.108658, -8.065952],
                [111.072105, -8.050748],
                [111.031685, -8.082599],
                [110.956131, -8.063713],
                [110.911171, -8.121149],
                [110.904457, -8.210409],
                [110.836136, -8.202934],
                [110.788666, -8.154592],
                [110.790009, -8.1165],
                [110.758797, -8.030742],
                [110.771225, -7.899249],
                [110.786538, -7.818944],
                [110.750929, -7.82466],
                [110.723794, -7.791503],
                [110.657083, -7.785867],
                [110.588886, -7.804361],
                [110.54336, -7.798195],
                [110.498291, -7.745311],
                [110.444958, -7.536631],
                [110.379147, -7.595244],
                [110.254532, -7.683113],
                [110.25954, -7.645667],
                [110.142883, -7.645877],
                [110.132286, -7.741732],
                [110.06321, -7.813906],
                [110.042953, -7.89204],
                [110.011826, -7.886903],
                [109.896675, -7.844425],
                [109.690193, -7.792683],
                [109.47419, -7.756908],
                [109.424683, -7.76845],
                [109.380403, -7.724499],
                [109.277812, -7.701472],
                [109.105467, -7.685618],
                [109.048995, -7.697629],
                [108.993577, -7.744561],
                [108.96375, -7.715322],
                [108.933997, -7.720566],
                [108.93102, -7.713349],
                [108.919786, -7.706645],
                [108.896909, -7.70961],
                [108.876058, -7.694295],
                [108.865476, -7.611036],
                [108.827862, -7.599123],
                [108.804252, -7.673145],
                [108.738433, -7.609161],
                [108.753132, -7.543514],
                [108.724345, -7.486524],
                [108.714968, -7.421927],
                [108.662295, -7.340506],
                [108.603483, -7.350607],
                [108.560766, -7.334588],
                [108.555715, -7.290434],
                [108.582017, -7.240565],
                [108.556373, -7.171165],
                [108.628929, -7.137631],
                [108.696854, -7.151166],
                [108.726479, -7.116722],
                [108.777687, -7.104835],
                [108.798622, -7.004968],
                [108.761101, -6.98307],
                [108.76516, -6.896383],
                [108.824615, -6.829293],
                [108.829338, -6.74608]
              ]
            ],
            [
              [
                [108.864219, -7.706541],
                [108.870832, -7.712705],
                [108.910178, -7.708335],
                [108.92574, -7.719276],
                [108.932456, -7.721225],
                [108.947685, -7.721218],
                [108.957728, -7.718627],
                [108.98867, -7.776379],
                [108.907681, -7.751549],
                [108.811508, -7.734937],
                [108.828885, -7.685307],
                [108.864219, -7.706541]
              ]
            ]
          ]
        },
        "id": 33,
        "properties": {
          "ID": 6,
          "kode": 33,
          "Propinsi": "JAWA TENGAH",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [106.721344, -6.089998],
                [106.692719, -6.095602],
                [106.688759, -6.174209],
                [106.718864, -6.185875],
                [106.775894, -6.316725],
                [106.767433, -6.361234],
                [106.599113, -6.361764],
                [106.532104, -6.333633],
                [106.500175, -6.356728],
                [106.466515, -6.313413],
                [106.432259, -6.359931],
                [106.45713, -6.419496],
                [106.404884, -6.453141],
                [106.401779, -6.529884],
                [106.431358, -6.583818],
                [106.429398, -6.695778],
                [106.511475, -6.765959],
                [106.43618, -6.818825],
                [106.427551, -6.865082],
                [106.390602, -6.90497],
                [106.396103, -6.979798],
                [106.379082, -6.995171],
                [106.263062, -6.972657],
                [106.236198, -6.931706],
                [106.145668, -6.902672],
                [106.093071, -6.874006],
                [106.060333, -6.833915],
                [105.988388, -6.814358],
                [105.913567, -6.815527],
                [105.775536, -6.849614],
                [105.684792, -6.836181],
                [105.604584, -6.845806],
                [105.546936, -6.867788],
                [105.476051, -6.862449],
                [105.372208, -6.813282],
                [105.300941, -6.801612],
                [105.269058, -6.843428],
                [105.241913, -6.822674],
                [105.218208, -6.747766],
                [105.263069, -6.761764],
                [105.374222, -6.655813],
                [105.392738, -6.691132],
                [105.38649, -6.738562],
                [105.467102, -6.827007],
                [105.509209, -6.757093],
                [105.505432, -6.729187],
                [105.595924, -6.650365],
                [105.625389, -6.585471],
                [105.615372, -6.561943],
                [105.648323, -6.488072],
                [105.713791, -6.53336],
                [105.771484, -6.516271],
                [105.813004, -6.468743],
                [105.825912, -6.429081],
                [105.818306, -6.370665],
                [105.825676, -6.252214],
                [105.883545, -6.074547],
                [105.976501, -5.999971],
                [106.021919, -5.888695],
                [106.074554, -5.885445],
                [106.113579, -5.930358],
                [106.099228, -5.986111],
                [106.165306, -6.02962],
                [106.222588, -6.015579],
                [106.245636, -5.961533],
                [106.292465, -5.950864],
                [106.388206, -5.992125],
                [106.396767, -6.018975],
                [106.472466, -6.052454],
                [106.534706, -6.012048],
                [106.570259, -6.035616],
                [106.634544, -6.014261],
                [106.684662, -6.016144],
                [106.721344, -6.089998]
              ]
            ],
            [
              [
                [105.246979, -6.565058],
                [105.242393, -6.63545],
                [105.204712, -6.650276],
                [105.167686, -6.576644],
                [105.183006, -6.542505],
                [105.250877, -6.532654],
                [105.246979, -6.565058]
              ]
            ]
          ]
        },
        "id": 36,
        "properties": {
          "ID": 7,
          "kode": 36,
          "Propinsi": "BANTEN",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [111.694595, -6.752857],
                [111.832825, -6.799014],
                [111.939568, -6.764695],
                [111.972176, -6.765804],
                [112.027229, -6.859045],
                [112.060112, -6.890543],
                [112.16993, -6.89966],
                [112.23793, -6.871494],
                [112.411819, -6.865529],
                [112.5198, -6.905662],
                [112.544864, -6.848775],
                [112.602792, -6.89387],
                [112.608869, -6.982774],
                [112.652966, -7.041649],
                [112.625006, -7.126014],
                [112.66918, -7.166654],
                [112.674713, -7.225679],
                [112.727264, -7.199742],
                [112.782669, -7.214118],
                [112.836922, -7.30876],
                [112.826752, -7.347414],
                [112.830528, -7.46781],
                [112.816696, -7.488018],
                [112.857704, -7.548725],
                [112.843674, -7.586721],
                [112.901512, -7.62622],
                [112.994942, -7.657773],
                [113.048615, -7.659442],
                [113.110321, -7.72505],
                [113.154587, -7.746615],
                [113.212761, -7.73906],
                [113.280533, -7.786507],
                [113.337135, -7.77614],
                [113.481895, -7.698964],
                [113.575294, -7.711989],
                [113.667999, -7.736984],
                [113.706665, -7.709392],
                [113.784576, -7.733948],
                [113.838509, -7.684821],
                [113.931564, -7.699339],
                [114.0299, -7.606591],
                [114.065537, -7.637639],
                [114.095688, -7.699542],
                [114.137779, -7.71711],
                [114.208633, -7.719072],
                [114.239769, -7.700929],
                [114.286819, -7.74787],
                [114.368515, -7.751497],
                [114.449234, -7.804945],
                [114.460304, -7.88781],
                [114.41713, -7.920804],
                [114.42569, -8.064825],
                [114.395546, -8.121264],
                [114.337181, -8.422754],
                [114.372826, -8.53333],
                [114.443161, -8.637075],
                [114.467758, -8.61793],
                [114.513489, -8.648381],
                [114.569321, -8.661139],
                [114.602242, -8.715033],
                [114.591324, -8.750443],
                [114.530891, -8.780732],
                [114.416534, -8.748014],
                [114.349121, -8.744769],
                [114.375259, -8.698574],
                [114.361153, -8.65963],
                [114.317375, -8.624228],
                [114.225525, -8.602414],
                [114.216881, -8.643929],
                [114.090469, -8.615832],
                [114.045151, -8.639547],
                [114.021385, -8.595541],
                [113.970848, -8.609504],
                [113.942947, -8.56012],
                [113.905838, -8.579295],
                [113.8125, -8.546223],
                [113.79007, -8.488746],
                [113.73201, -8.526714],
                [113.646294, -8.494339],
                [113.61882, -8.442991],
                [113.524757, -8.430819],
                [113.425812, -8.380055],
                [113.387894, -8.398775],
                [113.364845, -8.353276],
                [113.284279, -8.295891],
                [113.243156, -8.284298],
                [113.072037, -8.284934],
                [112.972435, -8.326396],
                [112.937477, -8.383437],
                [112.875587, -8.409164],
                [112.781456, -8.387782],
                [112.74437, -8.419105],
                [112.670242, -8.446231],
                [112.552414, -8.401976],
                [112.44194, -8.398211],
                [112.37439, -8.376492],
                [112.329979, -8.334126],
                [112.265106, -8.349245],
                [112.142982, -8.318054],
                [112.031502, -8.319978],
                [111.969475, -8.282909],
                [111.897675, -8.301271],
                [111.776794, -8.257344],
                [111.769699, -8.290105],
                [111.710297, -8.294887],
                [111.710007, -8.325211],
                [111.672142, -8.373858],
                [111.625053, -8.325221],
                [111.513535, -8.335917],
                [111.41066, -8.279475],
                [111.254616, -8.248837],
                [111.188499, -8.278602],
                [111.118698, -8.254065],
                [111.023735, -8.252783],
                [110.904457, -8.210409],
                [110.911171, -8.121149],
                [110.956131, -8.063713],
                [111.031685, -8.082599],
                [111.072105, -8.050748],
                [111.108658, -8.065952],
                [111.134033, -8.020032],
                [111.140442, -7.938492],
                [111.181503, -7.916514],
                [111.231346, -7.948041],
                [111.269707, -7.930492],
                [111.314003, -7.85079],
                [111.281219, -7.782755],
                [111.283073, -7.744789],
                [111.230736, -7.747669],
                [111.180164, -7.709611],
                [111.189209, -7.603958],
                [111.151654, -7.563807],
                [111.112659, -7.415796],
                [111.132803, -7.386832],
                [111.133153, -7.30824],
                [111.146592, -7.262771],
                [111.241771, -7.24923],
                [111.219641, -7.295056],
                [111.346387, -7.352231],
                [111.438232, -7.353508],
                [111.448722, -7.280662],
                [111.544593, -7.209885],
                [111.580716, -7.201196],
                [111.62486, -7.065532],
                [111.612236, -6.971057],
                [111.566604, -6.951681],
                [111.609039, -6.903624],
                [111.615326, -6.836943],
                [111.648499, -6.828923],
                [111.659874, -6.780993],
                [111.694595, -6.752857]
              ]
            ],
            [
              [
                [113.638163, -6.887474],
                [113.797623, -6.884495],
                [113.912071, -6.864436],
                [113.992722, -6.884586],
                [114.086349, -6.927759],
                [114.121918, -6.980549],
                [114.035805, -7.008666],
                [113.985703, -7.011945],
                [113.941284, -7.057282],
                [113.880783, -7.07095],
                [113.883873, -7.135548],
                [113.678551, -7.110577],
                [113.604645, -7.127305],
                [113.573105, -7.163429],
                [113.551178, -7.2346],
                [113.328033, -7.214845],
                [113.092392, -7.229101],
                [112.962181, -7.200501],
                [112.866043, -7.164714],
                [112.801178, -7.157979],
                [112.721176, -7.174918],
                [112.690369, -7.151152],
                [112.708206, -7.102842],
                [112.673004, -7.075121],
                [112.683548, -7.031513],
                [112.725105, -7.040094],
                [112.787674, -6.985271],
                [112.845161, -6.898797],
                [113.042206, -6.880765],
                [113.118645, -6.894146],
                [113.42231, -6.88627],
                [113.499718, -6.897233],
                [113.638163, -6.887474]
              ]
            ],
            [
              [
                [115.499336, -6.946886],
                [115.4478, -6.919424],
                [115.38356, -6.911211],
                [115.390144, -6.98852],
                [115.320747, -6.993716],
                [115.193825, -6.931066],
                [115.233414, -6.885417],
                [115.251526, -6.826602],
                [115.394707, -6.834252],
                [115.439651, -6.846564],
                [115.553787, -6.90412],
                [115.534569, -6.96106],
                [115.499336, -6.946886]
              ]
            ],
            [
              [
                [112.67501, -5.859471],
                [112.564216, -5.833381],
                [112.578552, -5.779873],
                [112.671714, -5.72369],
                [112.71614, -5.742655],
                [112.731986, -5.793182],
                [112.722518, -5.834838],
                [112.67501, -5.859471]
              ]
            ],
            [
              [
                [114.28257, -7.073864],
                [114.325409, -7.053657],
                [114.362137, -7.081872],
                [114.404289, -7.172804],
                [114.383644, -7.186969],
                [114.3144, -7.167445],
                [114.267868, -7.098571],
                [114.28257, -7.073864]
              ]
            ],
            [
              [
                [115.899406, -7.107687],
                [115.891769, -7.186985],
                [115.832756, -7.205947],
                [115.736443, -7.153445],
                [115.778282, -7.109709],
                [115.804092, -7.15064],
                [115.843468, -7.16076],
                [115.899406, -7.107687]
              ]
            ],
            [
              [
                [113.271072, -8.464405],
                [113.306946, -8.450875],
                [113.400581, -8.458902],
                [113.399284, -8.49789],
                [113.30629, -8.509363],
                [113.271072, -8.464405]
              ]
            ],
            [
              [
                [114.48246, -7.141308],
                [114.538559, -7.120292],
                [114.604576, -7.134747],
                [114.60331, -7.171029],
                [114.48246, -7.141308]
              ]
            ]
          ]
        },
        "id": 35,
        "properties": {
          "ID": 8,
          "kode": 35,
          "Propinsi": "JAWA TIMUR",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [127.742072, 0.816295],
                [127.7974, 0.7996],
                [127.8397, 0.80633],
                [127.932, 0.88722],
                [127.9239, 0.95794],
                [127.98, 1.08488],
                [128.0237, 1.11131],
                [128.0883, 1.12649],
                [128.1659, 1.12245],
                [128.2007, 1.19261],
                [128.18, 1.23451],
                [128.1092, 1.23794],
                [128.0916, 1.26956],
                [128.1347, 1.30145],
                [128.1604, 1.36163],
                [128.2424, 1.41862],
                [128.3401, 1.45979],
                [128.3597, 1.49011],
                [128.4626, 1.54464],
                [128.5052, 1.52351],
                [128.5524, 1.56312],
                [128.6649, 1.58865],
                [128.7197, 1.57194],
                [128.6797, 1.48842],
                [128.6905, 1.44729],
                [128.7364, 1.3984],
                [128.7418, 1.33011],
                [128.7071, 1.29313],
                [128.7158, 1.25066],
                [128.6878, 1.19439],
                [128.6842, 1.07075],
                [128.5998, 1.04118],
                [128.5361, 0.97025],
                [128.5073, 0.969],
                [128.3849, 0.91071],
                [128.3338, 0.91042],
                [128.2815, 0.86741],
                [128.3166, 0.81788],
                [128.2052, 0.80334],
                [128.2201, 0.756],
                [128.2597, 0.73909],
                [128.3137, 0.6577],
                [128.4445, 0.62671],
                [128.5697, 0.56647],
                [128.6909, 0.54882],
                [128.6694, 0.49282],
                [128.6897, 0.46074],
                [128.6767, 0.33845],
                [128.7976, 0.31372],
                [128.8473, 0.26065],
                [128.6011, 0.31807],
                [128.5584, 0.31668],
                [128.4994, 0.35481],
                [128.4753, 0.39434],
                [128.3869, 0.40178],
                [128.3124, 0.39049],
                [128.2183, 0.4098],
                [128.1369, 0.46332],
                [128.1165, 0.45372],
                [128.0398, 0.47984],
                [127.9746, 0.4816],
                [127.9154, 0.45615],
                [127.9102, 0.39127],
                [127.8716, 0.30896],
                [127.9191, 0.27721],
                [127.9243, 0.13099],
                [127.8861, 0.07639],
                [127.8873, 0.00948],
                [127.9483, -0.11003],
                [127.9867, -0.26911],
                [128.0997, -0.50944],
                [128.1851, -0.62383],
                [128.2195, -0.70072],
                [128.3036, -0.76573],
                [128.3741, -0.84649],
                [128.3489, -0.88716],
                [128.29, -0.87902],
                [128.2325, -0.8483],
                [128.2714, -0.82066],
                [128.239, -0.77439],
                [128.063, -0.7163],
                [128.0045, -0.66521],
                [127.936, -0.49685],
                [127.8621, -0.36417],
                [127.7496, -0.31774],
                [127.6863, -0.26548],
                [127.6595, -0.21024],
                [127.6996, -0.08177],
                [127.6984, 0.01083],
                [127.7194, 0.06459],
                [127.6883, 0.10672],
                [127.6981, 0.19853],
                [127.7323, 0.31467],
                [127.7144, 0.3493],
                [127.619, 0.36049],
                [127.5751, 0.41658],
                [127.5623, 0.5117],
                [127.5249, 0.55191],
                [127.549, 0.67995],
                [127.5449, 0.72851],
                [127.6029, 0.76229],
                [127.6378, 0.83297],
                [127.5627, 0.8698],
                [127.5083, 0.85684],
                [127.4906, 0.92754],
                [127.51, 0.95334],
                [127.4721, 1.059],
                [127.4192, 1.02931],
                [127.3987, 1.0772],
                [127.4301, 1.14734],
                [127.3989, 1.18898],
                [127.4067, 1.22822],
                [127.5177, 1.42418],
                [127.5333, 1.48478],
                [127.5242, 1.57819],
                [127.5751, 1.68532],
                [127.5565, 1.70354],
                [127.5795, 1.76204],
                [127.607, 1.77525],
                [127.7001, 1.93801],
                [127.7764, 1.99706],
                [127.8375, 2.09089],
                [127.9303, 2.16371],
                [128.0521, 2.20415],
                [128.0675, 2.17477],
                [128.0163, 2.09971],
                [127.9575, 2.04664],
                [127.9438, 1.98637],
                [127.8478, 1.92255],
                [127.8422, 1.81305],
                [127.8788, 1.79133],
                [127.9263, 1.80236],
                [127.9917, 1.76257],
                [128.0203, 1.70558],
                [127.9918, 1.66442],
                [127.9915, 1.61054],
                [128.0335, 1.58746],
                [128.0265, 1.47197],
                [127.9907, 1.36575],
                [127.9981, 1.3337],
                [127.9776, 1.27595],
                [127.9317, 1.236],
                [127.8934, 1.14881],
                [127.8325, 1.14733],
                [127.7644, 1.06888],
                [127.6534, 1.0226],
                [127.6247, 0.96958],
                [127.6535, 0.90266],
                [127.697, 0.87726],
                [127.742072, 0.816295]
              ]
            ],
            [
              [
                [124.936874, -1.929927],
                [124.889656, -1.92754],
                [124.865112, -1.893923],
                [124.77874, -1.916949],
                [124.669197, -1.977551],
                [124.590477, -2.003751],
                [124.534431, -2.007188],
                [124.449631, -2.030549],
                [124.398903, -2.024912],
                [124.384331, -1.94311],
                [124.339363, -1.883676],
                [124.344444, -1.811057],
                [124.375023, -1.733304],
                [124.363998, -1.71271],
                [124.398499, -1.661184],
                [124.473778, -1.65911],
                [124.52462, -1.634892],
                [124.636864, -1.644307],
                [124.798882, -1.686176],
                [124.869881, -1.68325],
                [124.897141, -1.711954],
                [124.955513, -1.699806],
                [125.076996, -1.762628],
                [125.131844, -1.686383],
                [125.202957, -1.727396],
                [125.292274, -1.733136],
                [125.32077, -1.784913],
                [125.311661, -1.895589],
                [125.233017, -1.87573],
                [125.167656, -1.875936],
                [125.108589, -1.898453],
                [125.059608, -1.88656],
                [125.020279, -1.930932],
                [124.936874, -1.929927]
              ]
            ],
            [
              [
                [127.5001, -1.72513],
                [127.4048, -1.66221],
                [127.3915, -1.61568],
                [127.41, -1.56593],
                [127.4126, -1.48042],
                [127.4573, -1.4385],
                [127.5097, -1.41778],
                [127.5734, -1.36802],
                [127.71, -1.33658],
                [127.7904, -1.37072],
                [127.7995, -1.41196],
                [127.8465, -1.43784],
                [127.8983, -1.43163],
                [127.9399, -1.45149],
                [128.0786, -1.57834],
                [128.1245, -1.56462],
                [128.1673, -1.6148],
                [128.159, -1.67794],
                [128.0989, -1.71322],
                [128.0372, -1.71459],
                [127.885, -1.68033],
                [127.8162, -1.69945],
                [127.7037, -1.6869],
                [127.6446, -1.72921],
                [127.5001, -1.72513]
              ]
            ],
            [
              [
                [128.2792, 2.00001],
                [128.3096, 2.08133],
                [128.2571, 2.12537],
                [128.2514, 2.25059],
                [128.2244, 2.30658],
                [128.2787, 2.35976],
                [128.2987, 2.44234],
                [128.3619, 2.50778],
                [128.4117, 2.53086],
                [128.459, 2.59769],
                [128.5255, 2.57971],
                [128.5646, 2.64851],
                [128.6355, 2.54578],
                [128.6942, 2.48003],
                [128.6958, 2.42752],
                [128.6547, 2.3753],
                [128.6571, 2.31508],
                [128.6307, 2.21973],
                [128.5306, 2.07179],
                [128.4704, 2.05684],
                [128.3987, 2.05641],
                [128.3047, 2.03752],
                [128.2792, 2.00001]
              ]
            ],
            [
              [
                [127.3592, -0.57986],
                [127.285, -0.46953],
                [127.3553, -0.32239],
                [127.4094, -0.35825],
                [127.4514, -0.41708],
                [127.481, -0.3414],
                [127.5376, -0.31027],
                [127.6123, -0.36417],
                [127.6887, -0.46297],
                [127.6677, -0.51201],
                [127.6037, -0.57387],
                [127.5993, -0.61639],
                [127.6448, -0.6237],
                [127.6496, -0.67974],
                [127.6861, -0.70913],
                [127.7163, -0.68805],
                [127.8006, -0.67786],
                [127.8704, -0.72379],
                [127.906, -0.77903],
                [127.8678, -0.82488],
                [127.7942, -0.85696],
                [127.693, -0.84385],
                [127.6567, -0.78787],
                [127.6537, -0.74391],
                [127.5675, -0.76966],
                [127.4728, -0.81791],
                [127.438, -0.74074],
                [127.4752, -0.65588],
                [127.4518, -0.62858],
                [127.3592, -0.57986]
              ]
            ],
            [
              [
                [125.9997, -1.91651],
                [125.9263, -1.94223],
                [125.8447, -1.89649],
                [125.7902, -1.9299],
                [125.5886, -1.93069],
                [125.5354, -1.94847],
                [125.4509, -1.94292],
                [125.4385, -1.89255],
                [125.3303, -1.83265],
                [125.372, -1.78057],
                [125.4305, -1.81122],
                [125.4809, -1.78226],
                [125.4913, -1.82035],
                [125.5512, -1.80585],
                [125.7029, -1.83132],
                [125.7522, -1.80754],
                [125.8765, -1.81093],
                [125.9168, -1.79541],
                [126.1406, -1.81564],
                [126.2095, -1.80953],
                [126.2828, -1.8179],
                [126.3354, -1.84281],
                [126.2614, -1.87942],
                [126.2178, -1.86881],
                [126.1545, -1.89238],
                [126.1065, -1.88032],
                [125.9997, -1.91651]
              ]
            ],
            [
              [
                [126, -2.44711],
                [125.9594, -2.37442],
                [125.9575, -2.32236],
                [125.8816, -2.21248],
                [125.8878, -2.17258],
                [125.8583, -2.08804],
                [125.8753, -2.02554],
                [125.9136, -1.9759],
                [125.9811, -2.01085],
                [125.9938, -2.05085],
                [125.9613, -2.12136],
                [125.9634, -2.18431],
                [126.0052, -2.26574],
                [126.0692, -2.41961],
                [126.0422, -2.47304],
                [126, -2.44711]
              ]
            ],
            [
              [
                [127.2619, -0.41957],
                [127.2517, -0.49356],
                [127.214, -0.51609],
                [127.1446, -0.52742],
                [127.1193, -0.51607],
                [127.1134, -0.37269],
                [127.1347, -0.32419],
                [127.1131, -0.29016],
                [127.1443, -0.2604],
                [127.2358, -0.24834],
                [127.2657, -0.28156],
                [127.2481, -0.31237],
                [127.2834, -0.37313],
                [127.2619, -0.41957]
              ]
            ],
            [
              [
                [127.1612, -0.72234],
                [127.2087, -0.64521],
                [127.2975, -0.68594],
                [127.2881, -0.72649],
                [127.3295, -0.78217],
                [127.2906, -0.79626],
                [127.2356, -0.7631],
                [127.191, -0.77644],
                [127.1612, -0.72234]
              ]
            ],
            [
              [
                [127.4999, -1.27043],
                [127.4654, -1.23999],
                [127.5378, -1.17793],
                [127.5999, -1.20978],
                [127.6935, -1.22769],
                [127.6721, -1.29242],
                [127.5595, -1.24815],
                [127.4999, -1.27043]
              ]
            ],
            [
              [
                [129.463684, -0.090001],
                [129.536438, -0.137458],
                [129.562119, -0.181339],
                [129.510452, -0.205838],
                [129.455719, -0.108978],
                [129.375412, -0.064561],
                [129.398895, -0.025581],
                [129.463684, -0.090001]
              ]
            ],
            [
              [
                [127.3835, 0.72289],
                [127.404, 0.75954],
                [127.4478, 0.74363],
                [127.4573, 0.67694],
                [127.4277, 0.63181],
                [127.3691, 0.64163],
                [127.3616, 0.67888],
                [127.3835, 0.72289]
              ]
            ],
            [
              [
                [127.2924, 0.82257],
                [127.3168, 0.86424],
                [127.3848, 0.83086],
                [127.3733, 0.7637],
                [127.3044, 0.76429],
                [127.2924, 0.82257]
              ]
            ],
            [
              [
                [127.3804, 0.28067],
                [127.3442, 0.33348],
                [127.4175, 0.37392],
                [127.4427, 0.33914],
                [127.4215, 0.28311],
                [127.3804, 0.28067]
              ]
            ],
            [
              [
                [127.4613, 0.00001],
                [127.4331, -0.00584],
                [127.4134, 0.05743],
                [127.4404, 0.10221],
                [127.4705, 0.03449],
                [127.4613, 0.00001]
              ]
            ],
            [
              [
                [127.3164, -1.43808],
                [127.2912, -1.40862],
                [127.347, -1.3666],
                [127.3989, -1.40281],
                [127.3713, -1.428],
                [127.3164, -1.43808]
              ]
            ],
            [
              [
                [128.1611, 2.41114],
                [128.1882, 2.36684],
                [128.184, 2.29051],
                [128.129, 2.32644],
                [128.1611, 2.41114]
              ]
            ],
            [
              [
                [128.350814, -1.033596],
                [128.3414, -0.97438],
                [128.4029, -0.99246],
                [128.4037, -1.03638],
                [128.350814, -1.033596]
              ]
            ],
            [
              [
                [128.4984, -0.56781],
                [128.5245, -0.58984],
                [128.5987, -0.61046],
                [128.5917, -0.64074],
                [128.5155, -0.62451],
                [128.4984, -0.56781]
              ]
            ],
            [
              [
                [128.3113, -0.54382],
                [128.3684, -0.51991],
                [128.4003, -0.57154],
                [128.3113, -0.54382]
              ]
            ],
            [
              [
                [127.3309, -0.62605],
                [127.3769, -0.6072],
                [127.4108, -0.633],
                [127.359, -0.6629],
                [127.3309, -0.62605]
              ]
            ],
            [
              [
                [124.303452, -2.000366],
                [124.313477, -1.971169],
                [124.366676, -1.967357],
                [124.365074, -2.013639],
                [124.328445, -2.02773],
                [124.303452, -2.000366]
              ]
            ],
            [
              [
                [126.375684, 1.346717],
                [126.43995, 1.344847],
                [126.412223, 1.282993],
                [126.375684, 1.346717]
              ]
            ]
          ]
        },
        "id": 82,
        "properties": {
          "ID": 9,
          "kode": 82,
          "Propinsi": "MALUKU UTARA",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [130.8313, -3.47141],
                [130.8059, -3.49896],
                [130.8796, -3.58872],
                [130.838, -3.65112],
                [130.8219, -3.70138],
                [130.8327, -3.78794],
                [130.8279, -3.87666],
                [130.7655, -3.83031],
                [130.6133, -3.7912],
                [130.5704, -3.72628],
                [130.4664, -3.69326],
                [130.4058, -3.6243],
                [130.3481, -3.61665],
                [130.2904, -3.58056],
                [130.1187, -3.52129],
                [130.1092, -3.50424],
                [130.0123, -3.47822],
                [129.9645, -3.41722],
                [129.9632, -3.37067],
                [129.9239, -3.33397],
                [129.8779, -3.33584],
                [129.7948, -3.31658],
                [129.6776, -3.31819],
                [129.5451, -3.30253],
                [129.4952, -3.30992],
                [129.5639, -3.40784],
                [129.532, -3.466],
                [129.4135, -3.41433],
                [129.2791, -3.40822],
                [129.1756, -3.37997],
                [129.0764, -3.33637],
                [128.9589, -3.35402],
                [128.918, -3.3311],
                [128.9624, -3.2814],
                [128.9612, -3.22166],
                [128.8912, -3.19893],
                [128.8309, -3.24359],
                [128.7788, -3.25702],
                [128.6635, -3.37588],
                [128.6643, -3.43326],
                [128.5858, -3.42633],
                [128.5119, -3.45503],
                [128.4506, -3.45653],
                [128.3948, -3.42432],
                [128.3877, -3.39445],
                [128.3251, -3.32516],
                [128.2827, -3.22196],
                [128.1976, -3.20564],
                [128.1589, -3.16823],
                [128.1945, -3.12455],
                [128.1891, -3.06464],
                [128.1415, -3.06945],
                [128.0849, -3.12556],
                [128.0515, -3.25021],
                [128.0454, -3.33848],
                [128.0058, -3.37754],
                [127.9723, -3.37984],
                [127.9351, -3.481],
                [127.946, -3.52314],
                [127.9101, -3.53896],
                [127.8864, -3.48258],
                [127.9325, -3.36832],
                [127.9104, -3.28972],
                [127.8799, -3.2655],
                [127.866, -3.18653],
                [127.8952, -3.15067],
                [127.9702, -3.09849],
                [127.9926, -3.06595],
                [128.0965, -3.06632],
                [128.0854, -3.01254],
                [128.1169, -2.9968],
                [128.1079, -2.95342],
                [128.1604, -2.8631],
                [128.2347, -2.84965],
                [128.271, -2.86299],
                [128.3525, -2.85581],
                [128.3975, -2.86835],
                [128.4305, -2.84402],
                [128.5049, -2.8312],
                [128.5428, -2.84749],
                [128.6275, -2.85114],
                [128.6526, -2.8384],
                [128.71, -2.85998],
                [128.7457, -2.84936],
                [128.797, -2.86403],
                [128.9475, -2.83844],
                [129.0271, -2.79063],
                [129.0634, -2.8102],
                [129.0813, -2.93207],
                [129.1264, -2.96181],
                [129.1735, -2.92563],
                [129.213, -2.92231],
                [129.3442, -2.85179],
                [129.3758, -2.78267],
                [129.4677, -2.77901],
                [129.6358, -2.82154],
                [129.6626, -2.81661],
                [129.7802, -2.86243],
                [129.7981, -2.90508],
                [129.8424, -2.88833],
                [129.8639, -2.92376],
                [130.0093, -2.99152],
                [130.0619, -3.00004],
                [130.1593, -2.98459],
                [130.2095, -3.00005],
                [130.2655, -2.96975],
                [130.34, -2.97073],
                [130.4287, -3.02831],
                [130.4896, -3.10319],
                [130.521, -3.1028],
                [130.5932, -3.15459],
                [130.6337, -3.29172],
                [130.6252, -3.31561],
                [130.6609, -3.40861],
                [130.8015, -3.41255],
                [130.8313, -3.47141]
              ]
            ],
            [
              [
                [126.0006, -3.3093],
                [126.0156, -3.18204],
                [126.1009, -3.12067],
                [126.1275, -3.16512],
                [126.2217, -3.18936],
                [126.2245, -3.14467],
                [126.2956, -3.09941],
                [126.3414, -3.10657],
                [126.4018, -3.08571],
                [126.4644, -3.08945],
                [126.5983, -3.06836],
                [126.6736, -3.07402],
                [126.7065, -3.05979],
                [126.7883, -3.06155],
                [126.8791, -3.10877],
                [126.9244, -3.14334],
                [126.9815, -3.14039],
                [127.0466, -3.17702],
                [127.1038, -3.22728],
                [127.076, -3.26191],
                [127.0297, -3.26615],
                [127.0424, -3.33172],
                [127.0728, -3.37474],
                [127.1212, -3.38484],
                [127.159, -3.33658],
                [127.1807, -3.35828],
                [127.2546, -3.37528],
                [127.2462, -3.45383],
                [127.2481, -3.59206],
                [127.2178, -3.66125],
                [127.1869, -3.67576],
                [127.1262, -3.65913],
                [127.045, -3.68912],
                [126.9586, -3.75261],
                [126.8914, -3.78843],
                [126.8629, -3.78245],
                [126.7496, -3.84348],
                [126.6818, -3.85857],
                [126.6689, -3.84196],
                [126.5525, -3.79009],
                [126.5235, -3.79459],
                [126.2828, -3.66723],
                [126.2323, -3.62291],
                [126.198, -3.62769],
                [126.163, -3.58489],
                [126.1605, -3.54538],
                [126.0465, -3.44963],
                [126.0398, -3.40619],
                [126.0059, -3.36454],
                [126.0006, -3.3093]
              ]
            ],
            [
              [
                [131.148111, -7.733321],
                [131.107111, -7.701931],
                [131.164311, -7.667571],
                [131.184811, -7.589211],
                [131.222611, -7.528451],
                [131.233511, -7.473591],
                [131.309711, -7.418691],
                [131.340311, -7.442291],
                [131.365311, -7.366271],
                [131.436911, -7.321361],
                [131.475911, -7.229681],
                [131.529611, -7.228881],
                [131.517611, -7.158241],
                [131.582211, -7.120791],
                [131.646611, -7.112811],
                [131.650311, -7.174691],
                [131.716911, -7.159711],
                [131.738411, -7.219041],
                [131.652611, -7.225321],
                [131.632611, -7.247311],
                [131.637311, -7.304361],
                [131.675411, -7.359271],
                [131.657511, -7.548331],
                [131.621311, -7.645961],
                [131.583311, -7.658131],
                [131.553011, -7.719511],
                [131.483211, -7.727291],
                [131.480311, -7.774321],
                [131.374111, -7.855401],
                [131.333411, -7.936211],
                [131.341811, -7.985141],
                [131.301711, -8.016321],
                [131.271711, -7.956001],
                [131.230311, -8.002691],
                [131.188511, -7.987851],
                [131.105011, -7.990961],
                [131.122011, -7.905691],
                [131.086611, -7.879891],
                [131.086911, -7.837771],
                [131.146711, -7.799841],
                [131.112411, -7.777601],
                [131.148111, -7.733321]
              ]
            ],
            [
              [
                [125.8047, -8.00014],
                [125.8214, -7.90089],
                [125.8037, -7.85616],
                [125.8663, -7.81319],
                [125.8594, -7.76665],
                [125.8904, -7.75561],
                [125.9467, -7.66017],
                [125.9843, -7.65647],
                [126.0316, -7.69334],
                [126.1872, -7.72471],
                [126.2498, -7.68395],
                [126.3278, -7.69185],
                [126.3889, -7.63476],
                [126.4243, -7.63154],
                [126.4899, -7.58823],
                [126.545, -7.56934],
                [126.6433, -7.56392],
                [126.645, -7.60431],
                [126.692, -7.6219],
                [126.7264, -7.66638],
                [126.8208, -7.66033],
                [126.8458, -7.73482],
                [126.8188, -7.75456],
                [126.7227, -7.74454],
                [126.5973, -7.80845],
                [126.5588, -7.81877],
                [126.5236, -7.86325],
                [126.4953, -7.94762],
                [126.4649, -7.97483],
                [126.4293, -7.93509],
                [126.3682, -7.93536],
                [126.3332, -7.91575],
                [126.177, -7.92564],
                [126.1508, -7.90081],
                [126.061, -7.89072],
                [125.9438, -7.9134],
                [125.8653, -7.9728],
                [125.8047, -8.00014]
              ]
            ],
            [
              [
                [134.4336, -6.68109],
                [134.3658, -6.78505],
                [134.3282, -6.86583],
                [134.2755, -6.88061],
                [134.2023, -6.92197],
                [134.1704, -6.9112],
                [134.1372, -6.8557],
                [134.0711, -6.83181],
                [134.0503, -6.77574],
                [134.0766, -6.71568],
                [134.0907, -6.63462],
                [134.1005, -6.48848],
                [134.1407, -6.46422],
                [134.117, -6.41609],
                [134.134, -6.34636],
                [134.1277, -6.25815],
                [134.097, -6.17273],
                [134.1377, -6.17052],
                [134.17, -6.21557],
                [134.2173, -6.22012],
                [134.3115, -6.30646],
                [134.298, -6.36197],
                [134.3474, -6.39878],
                [134.382, -6.40713],
                [134.4026, -6.40139],
                [134.4249, -6.41895],
                [134.4362, -6.4517],
                [134.4599, -6.43869],
                [134.4891, -6.53131],
                [134.4858, -6.58192],
                [134.5276, -6.60318],
                [134.4821, -6.66863],
                [134.4336, -6.68109]
              ]
            ],
            [
              [
                [134.2807, -6.05256],
                [134.3267, -6.02579],
                [134.3607, -6.04227],
                [134.3823, -6.02187],
                [134.4244, -6.02619],
                [134.4382, -6.01891],
                [134.4436, -6.00643],
                [134.4644, -5.98356],
                [134.5357, -5.92285],
                [134.5723, -5.94052],
                [134.6581, -5.94721],
                [134.6703, -5.98357],
                [134.7544, -6.0529],
                [134.7817, -6.09747],
                [134.7604, -6.14324],
                [134.7663, -6.18145],
                [134.7274, -6.3072],
                [134.6866, -6.34413],
                [134.6395, -6.35555],
                [134.5489, -6.3525],
                [134.5054, -6.30132],
                [134.4592, -6.27077],
                [134.3971, -6.25802],
                [134.3863, -6.22362],
                [134.3368, -6.21551],
                [134.3125, -6.17191],
                [134.2849, -6.14636],
                [134.2807, -6.05256]
              ]
            ],
            [
              [
                [134.7577, -5.85453],
                [134.7697, -5.89399],
                [134.7117, -5.97952],
                [134.6304, -5.92205],
                [134.5854, -5.93691],
                [134.5374, -5.92008],
                [134.4715, -5.9604],
                [134.4315, -6.02112],
                [134.419, -6.02472],
                [134.3792, -6.01837],
                [134.3546, -6.0354],
                [134.3167, -5.99975],
                [134.334, -5.95209],
                [134.3007, -5.90053],
                [134.3324, -5.86254],
                [134.3723, -5.85071],
                [134.3688, -5.77119],
                [134.2634, -5.76163],
                [134.2142, -5.72281],
                [134.2405, -5.67587],
                [134.3467, -5.73121],
                [134.355, -5.69253],
                [134.4186, -5.64292],
                [134.4543, -5.5571],
                [134.5289, -5.56647],
                [134.538, -5.63655],
                [134.5491, -5.65535],
                [134.5668, -5.65654],
                [134.5782, -5.68039],
                [134.5952, -5.70553],
                [134.619, -5.7287],
                [134.6405, -5.71887],
                [134.6549, -5.74994],
                [134.6972, -5.81007],
                [134.7577, -5.85453]
              ]
            ],
            [
              [
                [128.2655, -3.61441],
                [128.2877, -3.69248],
                [128.2518, -3.72545],
                [128.1829, -3.74662],
                [128.1211, -3.74962],
                [128.1484, -3.66138],
                [128.0116, -3.74275],
                [127.9853, -3.77568],
                [127.9318, -3.76827],
                [127.9068, -3.71281],
                [127.9109, -3.67892],
                [128.0169, -3.58674],
                [128.1683, -3.58184],
                [128.2057, -3.52147],
                [128.2809, -3.48812],
                [128.3348, -3.50381],
                [128.3097, -3.56217],
                [128.3452, -3.59693],
                [128.3374, -3.63375],
                [128.2655, -3.61441]
              ]
            ],
            [
              [
                [129.789611, -8.048421],
                [129.750711, -8.060451],
                [129.702611, -8.045571],
                [129.576011, -7.901111],
                [129.583011, -7.840591],
                [129.608311, -7.812481],
                [129.675811, -7.791501],
                [129.843311, -7.844541],
                [129.860811, -7.922381],
                [129.798611, -8.013341],
                [129.789611, -8.048421]
              ]
            ],
            [
              [
                [132.918211, -5.790491],
                [132.941011, -5.772471],
                [132.946411, -5.681341],
                [133.006711, -5.643821],
                [133.016311, -5.584311],
                [133.045011, -5.515811],
                [133.054611, -5.449861],
                [133.117011, -5.274701],
                [133.179011, -5.291151],
                [133.122111, -5.555371],
                [133.089711, -5.614901],
                [133.020311, -5.665601],
                [132.968511, -5.737791],
                [132.953611, -5.811861],
                [132.963011, -5.856341],
                [132.900311, -5.950561],
                [132.876411, -5.898311],
                [132.918211, -5.790491]
              ]
            ],
            [
              [
                [131.002945, -4.443814],
                [131.074814, -4.453882],
                [131.155197, -4.44084],
                [131.228928, -4.456224],
                [131.343292, -4.421241],
                [131.390854, -4.377274],
                [131.453949, -4.374352],
                [131.493683, -4.41465],
                [131.455231, -4.502821],
                [131.368332, -4.529637],
                [131.272049, -4.541338],
                [131.22493, -4.506531],
                [131.086044, -4.477498],
                [130.989182, -4.514464],
                [130.957397, -4.476324],
                [131.002945, -4.443814]
              ]
            ],
            [
              [
                [132.696911, -5.752801],
                [132.654111, -5.708561],
                [132.644411, -5.635551],
                [132.692111, -5.601881],
                [132.744211, -5.672451],
                [132.780011, -5.766551],
                [132.811311, -5.784241],
                [132.820311, -5.849941],
                [132.789511, -5.938101],
                [132.729911, -5.942501],
                [132.671911, -5.903691],
                [132.696211, -5.808051],
                [132.696911, -5.752801]
              ]
            ],
            [
              [
                [134.2773, -6.17358],
                [134.3066, -6.17218],
                [134.3385, -6.2284],
                [134.3702, -6.22986],
                [134.3955, -6.26218],
                [134.4229, -6.28203],
                [134.3477, -6.34415],
                [134.3152, -6.27647],
                [134.2868, -6.28248],
                [134.2305, -6.20123],
                [134.1807, -6.20193],
                [134.1708, -6.16644],
                [134.1176, -6.10945],
                [134.1245, -6.0387],
                [134.1945, -6.0387],
                [134.2289, -6.0174],
                [134.2461, -6.07542],
                [134.2339, -6.12277],
                [134.2773, -6.17358]
              ]
            ],
            [
              [
                [134.4041, -6.30569],
                [134.4624, -6.27898],
                [134.5012, -6.30226],
                [134.5468, -6.36615],
                [134.5967, -6.38789],
                [134.6113, -6.43346],
                [134.5735, -6.47648],
                [134.548, -6.53803],
                [134.5202, -6.53328],
                [134.493, -6.45866],
                [134.4547, -6.43217],
                [134.4437, -6.4401],
                [134.4075, -6.39784],
                [134.3848, -6.40067],
                [134.3625, -6.36106],
                [134.4041, -6.30569]
              ]
            ],
            [
              [
                [127.778111, -8.140231],
                [127.804411, -8.104221],
                [127.930311, -8.143591],
                [128.022411, -8.148441],
                [128.097211, -8.140591],
                [128.116711, -8.171331],
                [128.071911, -8.195261],
                [128.044211, -8.257411],
                [127.954811, -8.249611],
                [127.936211, -8.222341],
                [127.859311, -8.217701],
                [127.793211, -8.190191],
                [127.778111, -8.140231]
              ]
            ],
            [
              [
                [131.087511, -8.172361],
                [130.990911, -8.230581],
                [130.891211, -8.238591],
                [130.896411, -8.286951],
                [130.789911, -8.342211],
                [130.767511, -8.313871],
                [130.829211, -8.248821],
                [130.929811, -8.191911],
                [130.912811, -8.147011],
                [130.977011, -8.131751],
                [130.999811, -8.089501],
                [131.035511, -8.071791],
                [131.079511, -8.108431],
                [131.169111, -8.112971],
                [131.145511, -8.157911],
                [131.087511, -8.172361]
              ]
            ],
            [
              [
                [134.6442, -5.58602],
                [134.7514, -5.63225],
                [134.731, -5.72249],
                [134.7029, -5.73076],
                [134.6496, -5.7286],
                [134.6427, -5.71633],
                [134.6165, -5.72584],
                [134.596, -5.7018],
                [134.575, -5.67087],
                [134.576, -5.65725],
                [134.5488, -5.65219],
                [134.5368, -5.61611],
                [134.5345, -5.5749],
                [134.6442, -5.58602]
              ]
            ],
            [
              [
                [134.5938, -5.42747],
                [134.6513, -5.44671],
                [134.6511, -5.47534],
                [134.7046, -5.51648],
                [134.7011, -5.5677],
                [134.6341, -5.58476],
                [134.5092, -5.54835],
                [134.506, -5.42404],
                [134.5577, -5.44406],
                [134.5938, -5.42747]
              ]
            ],
            [
              [
                [131.897411, -7.176801],
                [131.845711, -7.156551],
                [131.723211, -7.169341],
                [131.716611, -7.131491],
                [131.752311, -7.110931],
                [131.884511, -7.102661],
                [131.929811, -7.111851],
                [131.971311, -7.199891],
                [131.949211, -7.248091],
                [131.906911, -7.223991],
                [131.897411, -7.176801]
              ]
            ],
            [
              [
                [128.690311, -7.103501],
                [128.655111, -7.150041],
                [128.674811, -7.177831],
                [128.636911, -7.222311],
                [128.534511, -7.168821],
                [128.519911, -7.132791],
                [128.587111, -7.069881],
                [128.690311, -7.103501]
              ]
            ],
            [
              [
                [127.369111, -7.502501],
                [127.485211, -7.525411],
                [127.481111, -7.583221],
                [127.409411, -7.607281],
                [127.370311, -7.661551],
                [127.325411, -7.631321],
                [127.366611, -7.577711],
                [127.369111, -7.502501]
              ]
            ],
            [
              [
                [128.4108, -3.55199],
                [128.4361, -3.50583],
                [128.4891, -3.50863],
                [128.5416, -3.53703],
                [128.5658, -3.58219],
                [128.5329, -3.61114],
                [128.453, -3.61128],
                [128.4048, -3.63179],
                [128.4108, -3.55199]
              ]
            ],
            [
              [
                [134.7075, -6.72872],
                [134.6705, -6.78497],
                [134.6232, -6.76017],
                [134.6723, -6.66742],
                [134.6857, -6.59563],
                [134.7244, -6.58593],
                [134.7601, -6.63249],
                [134.7382, -6.7057],
                [134.7075, -6.72872]
              ]
            ],
            [
              [
                [128.6085, -3.57854],
                [128.5457, -3.5238],
                [128.5962, -3.49247],
                [128.6756, -3.54095],
                [128.7227, -3.50944],
                [128.7303, -3.60021],
                [128.6678, -3.5731],
                [128.6368, -3.62703],
                [128.6085, -3.57854]
              ]
            ],
            [
              [
                [131.006851, -4.338299],
                [131.056335, -4.294552],
                [131.147736, -4.32551],
                [131.130356, -4.40931],
                [131.043167, -4.401302],
                [130.995056, -4.371799],
                [131.006851, -4.338299]
              ]
            ],
            [
              [
                [131.041011, -7.499981],
                [130.969711, -7.484171],
                [130.990111, -7.424961],
                [131.162611, -7.400661],
                [131.114011, -7.472901],
                [131.041011, -7.499981]
              ]
            ],
            [
              [
                [127.6479, -3.20179],
                [127.7043, -3.15873],
                [127.7537, -3.14518],
                [127.789, -3.18783],
                [127.786, -3.24502],
                [127.6987, -3.25287],
                [127.6609, -3.23991],
                [127.6479, -3.20179]
              ]
            ],
            [
              [
                [134.8759, -6.29141],
                [134.9079, -6.32475],
                [134.8507, -6.4427],
                [134.8068, -6.46157],
                [134.7981, -6.39306],
                [134.8759, -6.29141]
              ]
            ],
            [
              [
                [127.9522, -3.00023],
                [127.9018, -3.03362],
                [127.8416, -3.03203],
                [127.8735, -2.95421],
                [127.9352, -2.92238],
                [128.0001, -2.93658],
                [127.9522, -3.00023]
              ]
            ],
            [
              [
                [128.087511, -8.231111],
                [128.190711, -8.200321],
                [128.218911, -8.218541],
                [128.227411, -8.275191],
                [128.150311, -8.281711],
                [128.086211, -8.269521],
                [128.087511, -8.231111]
              ]
            ],
            [
              [
                [129.000611, -8.266451],
                [128.950511, -8.237551],
                [128.915111, -8.253071],
                [128.825211, -8.210451],
                [128.859611, -8.185831],
                [128.953211, -8.189821],
                [129.020211, -8.221081],
                [129.000611, -8.266451]
              ]
            ],
            [
              [
                [127.5001, -3.32302],
                [127.4816, -3.29371],
                [127.5485, -3.24207],
                [127.6241, -3.31356],
                [127.5758, -3.33546],
                [127.5001, -3.32302]
              ]
            ],
            [
              [
                [134.7314, -6.47601],
                [134.7625, -6.53462],
                [134.7352, -6.55801],
                [134.6709, -6.55956],
                [134.6397, -6.49611],
                [134.6917, -6.44599],
                [134.7314, -6.47601]
              ]
            ],
            [
              [
                [132.763011, -5.660381],
                [132.745511, -5.617491],
                [132.756311, -5.574361],
                [132.797511, -5.559731],
                [132.821311, -5.673781],
                [132.763011, -5.660381]
              ]
            ],
            [
              [
                [131.043911, -7.653431],
                [131.122811, -7.635551],
                [131.105911, -7.688891],
                [130.969211, -7.737981],
                [130.968111, -7.678161],
                [131.043911, -7.653431]
              ]
            ],
            [
              [
                [127.636911, -8.177851],
                [127.684011, -8.153111],
                [127.747611, -8.200721],
                [127.656011, -8.237481],
                [127.600011, -8.206561],
                [127.636911, -8.177851]
              ]
            ],
            [
              [
                [130.872111, -7.499971],
                [130.998211, -7.527311],
                [130.972711, -7.562581],
                [130.846511, -7.562131],
                [130.839211, -7.497951],
                [130.872111, -7.499971]
              ]
            ],
            [
              [
                [127.172, -8.11934],
                [127.1417, -8.11181],
                [127.1359, -8.04633],
                [127.2011, -8.02771],
                [127.2309, -8.11386],
                [127.172, -8.11934]
              ]
            ],
            [
              [
                [131.621311, -6.695521],
                [131.562011, -6.719951],
                [131.581311, -6.772751],
                [131.526611, -6.802571],
                [131.511911, -6.762721],
                [131.559711, -6.681411],
                [131.621311, -6.695521]
              ]
            ],
            [
              [
                [127.1987, -3.89045],
                [127.1543, -3.88116],
                [127.1369, -3.84772],
                [127.1915, -3.81556],
                [127.2362, -3.86309],
                [127.1987, -3.89045]
              ]
            ],
            [
              [
                [131.4279, -4.00002],
                [131.4501, -4.07044],
                [131.4216, -4.07687],
                [131.3673, -3.96939],
                [131.4184, -3.94902],
                [131.4279, -4.00002]
              ]
            ],
            [
              [
                [134.7166, -5.7494],
                [134.7493, -5.7861],
                [134.6982, -5.80898],
                [134.6595, -5.73844],
                [134.7166, -5.7494]
              ]
            ],
            [
              [
                [134.2541, -5.78689],
                [134.2099, -5.83785],
                [134.1901, -5.81428],
                [134.1989, -5.75416],
                [134.2541, -5.78689]
              ]
            ],
            [
              [
                [134.5586, -5.33935],
                [134.5999, -5.34036],
                [134.5386, -5.41645],
                [134.511, -5.37494],
                [134.5586, -5.33935]
              ]
            ]
          ]
        },
        "id": 81,
        "properties": {
          "ID": 10,
          "kode": 81,
          "Propinsi": "MALUKU",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [119.591652, -4.55424],
                [119.59671, -4.416243],
                [119.639999, -4.302221],
                [119.594444, -4.240834],
                [119.624649, -4.236449],
                [119.612373, -4.203055],
                [119.638023, -4.173174],
                [119.605278, -4.083888],
                [119.641388, -3.961668],
                [119.583611, -3.976388],
                [119.583885, -4.022222],
                [119.613777, -4.00442],
                [119.586563, -4.039411],
                [119.570557, -3.948335],
                [119.451942, -3.713609],
                [119.46389, -3.639999],
                [119.516594, -3.571808],
                [119.435226, -3.345711],
                [119.43866, -3.266739],
                [119.389656, -3.21247],
                [119.420471, -3.176799],
                [119.484596, -3.18489],
                [119.545479, -3.13092],
                [119.629059, -3.121519],
                [119.534187, -2.94152],
                [119.5252, -2.793351],
                [119.493317, -2.74063],
                [119.643578, -2.75641],
                [119.727882, -2.72763],
                [119.76104, -2.68366],
                [119.751106, -2.604909],
                [119.700089, -2.553469],
                [119.720917, -2.500069],
                [119.689026, -2.437739],
                [119.715866, -2.39282],
                [119.690308, -2.33612],
                [119.596573, -2.294341],
                [119.629776, -2.163379],
                [119.733871, -2.07421],
                [119.880997, -2.047519],
                [119.99604, -1.963381],
                [120.093803, -1.96225],
                [120.122032, -2.007521],
                [120.336891, -2.053441],
                [120.399567, -1.884479],
                [120.581871, -2.121749],
                [120.706909, -2.212381],
                [120.975296, -2.2411],
                [121.164711, -2.33201],
                [121.328552, -2.372219],
                [121.504707, -2.490139],
                [121.71553, -2.558119],
                [121.800636, -2.709759],
                [121.742439, -2.735281],
                [121.656937, -2.878121],
                [121.57132, -2.912889],
                [121.497948, -2.98648],
                [121.367706, -3.01218],
                [121.305733, -3.069639],
                [121.269417, -3.06436],
                [121.215973, -2.974619],
                [121.172737, -2.96713],
                [121.101349, -2.77383],
                [120.985046, -2.83115],
                [120.972656, -2.81988],
                [121.09816, -2.71633],
                [121.046944, -2.701666],
                [121.058823, -2.672917],
                [121.036942, -2.69417],
                [121.073677, -2.642818],
                [121.054787, -2.639081],
                [121.073402, -2.616626],
                [121.056114, -2.580555],
                [121.072212, -2.61561],
                [121.054573, -2.651976],
                [121.031387, -2.659723],
                [121.041389, -2.624166],
                [121.026947, -2.65389],
                [121.010429, -2.614999],
                [121.006386, -2.679166],
                [120.976669, -2.666945],
                [120.98764, -2.639878],
                [120.970894, -2.670767],
                [120.945709, -2.64595],
                [120.857224, -2.658057],
                [120.793892, -2.62389],
                [120.799156, -2.60833],
                [120.666107, -2.639441],
                [120.632774, -2.683056],
                [120.568611, -2.691945],
                [120.582497, -2.669719],
                [120.564163, -2.670555],
                [120.569725, -2.711666],
                [120.453331, -2.824721],
                [120.353615, -2.860277],
                [120.256111, -2.945834],
                [120.196831, -2.951605],
                [120.189453, -2.967989],
                [120.254723, -3.05167],
                [120.255547, -3.16028],
                [120.382217, -3.202221],
                [120.389168, -3.250832],
                [120.421249, -3.264168],
                [120.379723, -3.503611],
                [120.418892, -3.573057],
                [120.413612, -3.667221],
                [120.442497, -3.739167],
                [120.358917, -3.849969],
                [120.33667, -3.9375],
                [120.361115, -4.02389],
                [120.330696, -4.104164],
                [120.380867, -4.163501],
                [120.365067, -4.298959],
                [120.393501, -4.332041],
                [120.348145, -4.425264],
                [120.402496, -4.480834],
                [120.377472, -4.512521],
                [120.397186, -4.600131],
                [120.458572, -4.658292],
                [120.400108, -4.710047],
                [120.383614, -4.838332],
                [120.303391, -4.87538],
                [120.300552, -5.055555],
                [120.271667, -5.14394],
                [120.35833, -5.331111],
                [120.404861, -5.341689],
                [120.472504, -5.623055],
                [120.314857, -5.507519],
                [120.13028, -5.589722],
                [119.935448, -5.544427],
                [119.840088, -5.635554],
                [119.849953, -5.664043],
                [119.785614, -5.701977],
                [119.646667, -5.682223],
                [119.656113, -5.640278],
                [119.611389, -5.6125],
                [119.558998, -5.650539],
                [119.552902, -5.595309],
                [119.561386, -5.618332],
                [119.579445, -5.603612],
                [119.512871, -5.56993],
                [119.514442, -5.53444],
                [119.460327, -5.576923],
                [119.487984, -5.604735],
                [119.438889, -5.596667],
                [119.430275, -5.487501],
                [119.396111, -5.432501],
                [119.362976, -5.41842],
                [119.353889, -5.325833],
                [119.409447, -5.112136],
                [119.498611, -5.116945],
                [119.450279, -5.108611],
                [119.471939, -5.077922],
                [119.450279, -5.0975],
                [119.447716, -5.086544],
                [119.525558, -4.923611],
                [119.513885, -4.886388],
                [119.56147, -4.891851],
                [119.506111, -4.868611],
                [119.481392, -4.750834],
                [119.591652, -4.55424]
              ]
            ],
            [
              [
                [120.431389, -5.63944],
                [120.445831, -5.646111],
                [120.429443, -5.661112],
                [120.419998, -5.649723],
                [120.431389, -5.63944]
              ]
            ],
            [
              [
                [118.154724, -5.128056],
                [118.138641, -5.126358],
                [118.147217, -5.114721],
                [118.154724, -5.128056]
              ]
            ],
            [
              [
                [117.661667, -5.175556],
                [117.681938, -5.196813],
                [117.656113, -5.222223],
                [117.661667, -5.175556]
              ]
            ],
            [
              [
                [117.929443, -5.351667],
                [117.951668, -5.383333],
                [117.947777, -5.408611],
                [117.926392, -5.389443],
                [117.929443, -5.351667]
              ]
            ],
            [
              [
                [119.312775, -5.511667],
                [119.31591, -5.487446],
                [119.298058, -5.498612],
                [119.297501, -5.46778],
                [119.26667, -5.45472],
                [119.301949, -5.456808],
                [119.299721, -5.493611],
                [119.320763, -5.496492],
                [119.310837, -5.519719],
                [119.291809, -5.512451],
                [119.29583, -5.53139],
                [119.257767, -5.52556],
                [119.248337, -5.494999],
                [119.276108, -5.501112],
                [119.279167, -5.471939],
                [119.27906, -5.4938],
                [119.312775, -5.511667]
              ]
            ],
            [
              [
                [120.489166, -5.738329],
                [120.492775, -5.751945],
                [120.474442, -5.750835],
                [120.489166, -5.738329]
              ]
            ],
            [
              [
                [120.458115, -6.38956],
                [120.480293, -6.281098],
                [120.443039, -6.2589],
                [120.445557, -6.16514],
                [120.426506, -6.179759],
                [120.466187, -6.093651],
                [120.445137, -5.956089],
                [120.461388, -5.792778],
                [120.493111, -5.7675],
                [120.568466, -6.055651],
                [120.524467, -6.222331],
                [120.53833, -6.327239],
                [120.495674, -6.391684],
                [120.509979, -6.443737],
                [120.483971, -6.49799],
                [120.458115, -6.38956]
              ]
            ],
            [
              [
                [120.429733, -6.10583],
                [120.414795, -6.20879],
                [120.392365, -6.178013],
                [120.429733, -6.10583]
              ]
            ],
            [
              [
                [120.992241, -6.504577],
                [120.979668, -6.504577],
                [121.00132, -6.493719],
                [120.992241, -6.504577]
              ]
            ],
            [
              [
                [120.438942, -6.59793],
                [120.440979, -6.641493],
                [120.426071, -6.641673],
                [120.420059, -6.596825],
                [120.438942, -6.59793]
              ]
            ],
            [
              [
                [120.800095, -6.788329],
                [120.81385, -6.832688],
                [120.795654, -6.866649],
                [120.778847, -6.799716],
                [120.800095, -6.788329]
              ]
            ],
            [
              [
                [120.629715, -7.000628],
                [120.736809, -7.075277],
                [120.785904, -7.059813],
                [120.767731, -7.134739],
                [120.631943, -7.133331],
                [120.63475, -7.099226],
                [120.603889, -7.072223],
                [120.629715, -7.000628]
              ]
            ],
            [
              [
                [120.560486, -7.07302],
                [120.611656, -7.08917],
                [120.563332, -7.093056],
                [120.560486, -7.07302]
              ]
            ],
            [
              [
                [121.751389, -7.222219],
                [121.747498, -7.238333],
                [121.738335, -7.229166],
                [121.751389, -7.222219]
              ]
            ],
            [
              [
                [120.906303, -7.277767],
                [121.003609, -7.281388],
                [121.063889, -7.300556],
                [121, -7.33333],
                [120.79528, -7.270555],
                [120.906303, -7.277767]
              ]
            ],
            [
              [
                [121.766113, -7.261667],
                [121.772781, -7.286945],
                [121.727501, -7.294445],
                [121.730278, -7.277778],
                [121.766113, -7.261667]
              ]
            ],
            [
              [
                [121.096947, -7.302219],
                [121.168335, -7.354165],
                [121.099167, -7.398055],
                [121.059723, -7.372499],
                [121.096947, -7.302219]
              ]
            ],
            [
              [
                [121.819725, -7.330278],
                [121.840279, -7.350555],
                [121.823608, -7.39694],
                [121.753891, -7.423055],
                [121.756386, -7.347777],
                [121.819725, -7.330278]
              ]
            ],
            [
              [
                [121.797005, -7.472008],
                [121.797501, -7.488609],
                [121.722778, -7.491661],
                [121.797005, -7.472008]
              ]
            ],
            [
              [
                [120.423058, -5.04861],
                [120.427635, -5.058155],
                [120.412498, -5.051388],
                [120.423058, -5.04861]
              ]
            ]
          ]
        },
        "id": 73,
        "properties": {
          "ID": 13,
          "kode": 73,
          "Propinsi": "SULAWESI SELATAN",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [106.721344, -6.089998],
              [106.741844, -6.105995],
              [106.857552, -6.117222],
              [106.877823, -6.09872],
              [106.972267, -6.089283],
              [106.972893, -6.185318],
              [106.945602, -6.25469],
              [106.909164, -6.263964],
              [106.922569, -6.318879],
              [106.9132, -6.366885],
              [106.775894, -6.316725],
              [106.718864, -6.185875],
              [106.688759, -6.174209],
              [106.692719, -6.095602],
              [106.721344, -6.089998]
            ]
          ]
        },
        "id": 31,
        "properties": {
          "ID": 14,
          "kode": 31,
          "Propinsi": "DKI JAKARTA",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [106.396103, -6.979798],
              [106.390602, -6.90497],
              [106.427551, -6.865082],
              [106.43618, -6.818825],
              [106.511475, -6.765959],
              [106.429398, -6.695778],
              [106.431358, -6.583818],
              [106.401779, -6.529884],
              [106.404884, -6.453141],
              [106.45713, -6.419496],
              [106.432259, -6.359931],
              [106.466515, -6.313413],
              [106.500175, -6.356728],
              [106.532104, -6.333633],
              [106.599113, -6.361764],
              [106.767433, -6.361234],
              [106.775894, -6.316725],
              [106.9132, -6.366885],
              [106.922569, -6.318879],
              [106.909164, -6.263964],
              [106.945602, -6.25469],
              [106.972893, -6.185318],
              [106.972267, -6.089283],
              [107.018662, -6.078872],
              [107.008194, -5.994798],
              [107.038139, -5.911394],
              [107.103302, -5.932775],
              [107.139267, -5.978195],
              [107.191887, -5.985764],
              [107.302711, -5.956646],
              [107.387733, -6.01634],
              [107.465004, -6.141714],
              [107.531631, -6.174138],
              [107.621086, -6.189385],
              [107.654709, -6.242166],
              [107.722885, -6.232824],
              [107.822433, -6.178484],
              [107.893303, -6.238593],
              [107.940582, -6.249246],
              [108.103218, -6.334497],
              [108.156311, -6.331516],
              [108.194878, -6.30224],
              [108.19416, -6.261509],
              [108.301331, -6.245291],
              [108.361443, -6.274672],
              [108.368279, -6.329412],
              [108.429184, -6.416264],
              [108.484001, -6.460444],
              [108.538673, -6.485534],
              [108.539986, -6.577236],
              [108.554527, -6.676128],
              [108.575089, -6.735016],
              [108.605278, -6.770391],
              [108.677612, -6.768246],
              [108.683212, -6.80682],
              [108.763901, -6.815747],
              [108.829338, -6.74608],
              [108.824615, -6.829293],
              [108.76516, -6.896383],
              [108.761101, -6.98307],
              [108.798622, -7.004968],
              [108.777687, -7.104835],
              [108.726479, -7.116722],
              [108.696854, -7.151166],
              [108.628929, -7.137631],
              [108.556373, -7.171165],
              [108.582017, -7.240565],
              [108.555715, -7.290434],
              [108.560766, -7.334588],
              [108.603483, -7.350607],
              [108.662295, -7.340506],
              [108.714968, -7.421927],
              [108.724345, -7.486524],
              [108.753132, -7.543514],
              [108.738433, -7.609161],
              [108.804252, -7.673145],
              [108.76519, -7.696947],
              [108.715416, -7.6797],
              [108.657326, -7.69776],
              [108.586388, -7.684473],
              [108.513016, -7.699345],
              [108.505714, -7.760839],
              [108.48156, -7.801749],
              [108.430328, -7.822663],
              [108.261208, -7.806957],
              [108.123146, -7.780538],
              [107.928497, -7.730316],
              [107.839561, -7.7341],
              [107.782387, -7.677471],
              [107.679466, -7.660038],
              [107.673851, -7.627574],
              [107.602493, -7.571478],
              [107.39267, -7.492356],
              [107.35405, -7.498446],
              [107.242142, -7.487233],
              [107.077705, -7.451569],
              [106.857651, -7.432205],
              [106.788452, -7.434358],
              [106.519707, -7.405331],
              [106.473618, -7.371199],
              [106.421753, -7.357368],
              [106.370895, -7.310125],
              [106.376572, -7.237391],
              [106.398254, -7.188931],
              [106.455795, -7.184799],
              [106.456253, -7.127603],
              [106.539734, -7.056473],
              [106.543465, -6.982475],
              [106.441109, -6.952632],
              [106.396103, -6.979798]
            ]
          ]
        },
        "id": 32,
        "properties": {
          "ID": 15,
          "kode": 32,
          "Propinsi": "JAWA BARAT",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [140.686661, -2.477779],
                [140.747223, -2.534719],
                [140.707947, -2.538949],
                [140.717224, -2.59278],
                [140.698059, -2.588055],
                [140.686951, -2.613889],
                [140.705826, -2.628334],
                [140.736664, -2.627222],
                [140.71167, -2.596666],
                [140.761108, -2.62861],
                [140.789536, -2.595499],
                [140.915283, -2.627222],
                [141.002945, -2.604969],
                [141.000214, -6.313239],
                [140.957016, -6.341135],
                [140.931885, -6.553238],
                [140.893906, -6.623135],
                [140.8564, -6.630125],
                [140.864258, -6.671305],
                [140.834351, -6.67659],
                [140.836395, -6.713049],
                [140.885437, -6.740618],
                [140.858093, -6.780619],
                [140.902603, -6.805129],
                [140.897812, -6.856439],
                [140.936249, -6.846869],
                [140.936356, -6.89572],
                [140.951218, -6.876529],
                [140.982391, -6.891975],
                [141.011765, -7.999899],
                [141.001114, -9.118333],
                [140.892776, -9.084998],
                [140.61322, -8.809982],
                [140.500488, -8.61316],
                [140.355118, -8.463129],
                [140.314621, -8.455412],
                [140.228607, -8.355278],
                [140.169556, -8.336712],
                [139.983337, -8.187221],
                [139.956665, -8.124438],
                [140.074966, -8.065577],
                [140.078033, -8.032313],
                [140.056671, -7.993609],
                [140.066101, -7.9232],
                [140.046387, -8.016963],
                [140.060791, -8.062901],
                [139.924423, -8.107983],
                [139.71257, -8.097499],
                [139.506393, -8.180833],
                [139.343323, -8.206104],
                [139.277191, -8.176252],
                [139.223236, -8.095256],
                [139.08194, -8.1375],
                [139.068802, -8.11299],
                [139.070053, -8.151273],
                [138.988052, -8.203059],
                [138.953613, -8.193334],
                [138.956116, -8.159168],
                [138.948059, -8.18639],
                [138.995285, -8.230279],
                [139.003311, -8.296171],
                [138.976944, -8.329998],
                [138.935272, -8.313889],
                [138.953049, -8.295834],
                [138.92334, -8.279722],
                [138.949448, -8.262499],
                [138.910004, -8.267219],
                [138.835281, -8.115],
                [138.924164, -8.076111],
                [138.902771, -8.031388],
                [138.913086, -7.919674],
                [139.018051, -7.853889],
                [139.018082, -7.751259],
                [139.068344, -7.631122],
                [139.106308, -7.60077],
                [139.114456, -7.636712],
                [139.115234, -7.566709],
                [139.054016, -7.517074],
                [138.964325, -7.519186],
                [138.868591, -7.321462],
                [138.67334, -7.223056],
                [138.671951, -7.19833],
                [138.915115, -7.198061],
                [139.023254, -7.240216],
                [139.170609, -7.236943],
                [139.243729, -7.15057],
                [139.265533, -7.225818],
                [139.42308, -7.233811],
                [139.437164, -7.220868],
                [139.43161, -7.200046],
                [139.383499, -7.200255],
                [139.353729, -7.164835],
                [139.404419, -7.131457],
                [139.472626, -7.171249],
                [139.459442, -7.221426],
                [139.473785, -7.26068],
                [139.541382, -7.284661],
                [139.577698, -7.267271],
                [139.597656, -7.18476],
                [139.648392, -7.177779],
                [139.635056, -7.233941],
                [139.666168, -7.295719],
                [139.690643, -7.288561],
                [139.747726, -7.219747],
                [139.769852, -7.18024],
                [139.763702, -7.129942],
                [139.784821, -7.100969],
                [139.830399, -7.085875],
                [139.802246, -7.078103],
                [139.766602, -7.112126],
                [139.745209, -7.214639],
                [139.698654, -7.24724],
                [139.688766, -7.285228],
                [139.664612, -7.262061],
                [139.656052, -7.18003],
                [139.631943, -7.15506],
                [139.57373, -7.187123],
                [139.56192, -7.26636],
                [139.50708, -7.263996],
                [139.470047, -7.238251],
                [139.474976, -7.148047],
                [139.389664, -7.116319],
                [139.344269, -7.043914],
                [139.258286, -7.016988],
                [139.219482, -7.021232],
                [139.217438, -6.970949],
                [139.134613, -6.956379],
                [138.953888, -6.828889],
                [138.938889, -6.787777],
                [138.91037, -6.780899],
                [138.872955, -6.798277],
                [138.834793, -6.784596],
                [138.824997, -6.691108],
                [138.681107, -6.6075],
                [138.428848, -6.36095],
                [138.441391, -6.327219],
                [138.389999, -6.23194],
                [138.383057, -6.183332],
                [138.403061, -6.18111],
                [138.348969, -6.090442],
                [138.363617, -6.069171],
                [138.288895, -5.939166],
                [138.254166, -5.923331],
                [138.251114, -5.87778],
                [138.401398, -5.83333],
                [138.426193, -5.858562],
                [138.444199, -5.847572],
                [138.466583, -5.884939],
                [138.487595, -5.8637],
                [138.517075, -5.889771],
                [138.53923, -5.887751],
                [138.487228, -5.86228],
                [138.466385, -5.88361],
                [138.444489, -5.846298],
                [138.424393, -5.855866],
                [138.403336, -5.73527],
                [138.405792, -5.818329],
                [138.34938, -5.83769],
                [138.16333, -5.805554],
                [138.15889, -5.753889],
                [138.232498, -5.707777],
                [138.423218, -5.659669],
                [138.339447, -5.653055],
                [138.374756, -5.644679],
                [138.368439, -5.617626],
                [138.307358, -5.589237],
                [138.353058, -5.551871],
                [138.310883, -5.578286],
                [138.286194, -5.61748],
                [138.27417, -5.594721],
                [138.243607, -5.595555],
                [138.262222, -5.565001],
                [138.235001, -5.536666],
                [138.28833, -5.542779],
                [138.254181, -5.540461],
                [138.255646, -5.52691],
                [138.297165, -5.524929],
                [138.259506, -5.532753],
                [138.255829, -5.506388],
                [138.297501, -5.507778],
                [138.255905, -5.505616],
                [138.242188, -5.466599],
                [138.281662, -5.40862],
                [138.198685, -5.481849],
                [138.186066, -5.440681],
                [138.165573, -5.436957],
                [138.133392, -5.481514],
                [138.125793, -5.532678],
                [138.081116, -5.530834],
                [138.094376, -5.501411],
                [138.078049, -5.463055],
                [138.091797, -5.445975],
                [138.076859, -5.463939],
                [138.088699, -5.521059],
                [138.05278, -5.50472],
                [138.058685, -5.386909],
                [138.16185, -5.383855],
                [138.183258, -5.339423],
                [138.239044, -5.326373],
                [138.28566, -5.266903],
                [138.236465, -5.322412],
                [138.177994, -5.333399],
                [138.140244, -5.369929],
                [138.114273, -5.321293],
                [138.131561, -5.312794],
                [138.129532, -5.296389],
                [138.177704, -5.276022],
                [138.181061, -5.234888],
                [138.176834, -5.275101],
                [138.129013, -5.295087],
                [138.112885, -5.320039],
                [138.126999, -5.37387],
                [138.054428, -5.374451],
                [138.051392, -5.433055],
                [138.026642, -5.437757],
                [137.991089, -5.368555],
                [138.049789, -5.371112],
                [138.088348, -5.27899],
                [138.150391, -5.268478],
                [138.141708, -5.197303],
                [138.176849, -5.186413],
                [138.141861, -5.191596],
                [138.147339, -5.267649],
                [138.083145, -5.27842],
                [138.048355, -5.367272],
                [138.001587, -5.358379],
                [137.984024, -5.371354],
                [138.041107, -5.479722],
                [138.028885, -5.505281],
                [137.875275, -5.361939],
                [137.947418, -5.293072],
                [137.994446, -5.2925],
                [138.087784, -5.23],
                [138.047623, -5.189037],
                [138.096298, -5.180214],
                [138.096939, -5.152222],
                [138.067535, -5.139541],
                [138.102127, -5.13235],
                [138.095932, -5.10047],
                [138.111755, -5.11218],
                [138.120285, -5.100556],
                [138.096909, -5.098136],
                [138.102356, -5.130493],
                [138.066498, -5.137029],
                [138.0961, -5.170526],
                [138.044952, -5.18542],
                [138.060837, -5.23278],
                [137.994781, -5.28301],
                [137.960205, -5.27179],
                [137.963333, -5.231944],
                [137.941116, -5.21111],
                [137.951904, -5.197399],
                [137.939163, -5.210278],
                [137.958832, -5.278958],
                [137.909698, -5.321306],
                [137.877228, -5.308056],
                [137.91037, -5.270108],
                [137.876114, -5.303333],
                [137.838608, -5.279999],
                [137.867218, -5.221941],
                [137.912781, -5.210834],
                [137.91806, -5.168331],
                [137.960281, -5.141389],
                [138.021393, -5.139723],
                [137.952774, -5.138611],
                [137.916107, -5.167223],
                [137.902222, -5.214167],
                [137.861389, -5.223611],
                [137.832504, -5.298055],
                [137.746384, -5.35576],
                [137.740829, -5.374444],
                [137.728073, -5.325589],
                [137.825562, -5.23056],
                [137.806107, -5.178473],
                [137.843887, -5.166389],
                [137.887222, -5.100278],
                [137.90889, -5.11889],
                [137.89917, -5.109169],
                [137.914444, -5.095829],
                [137.948059, -5.105557],
                [137.968887, -5.083055],
                [137.930832, -5.040829],
                [137.967224, -5.080279],
                [137.961105, -5.093334],
                [137.915283, -5.09389],
                [137.897781, -5.108889],
                [137.910278, -5.117219],
                [137.88945, -5.0975],
                [137.835831, -5.129166],
                [137.856384, -5.145833],
                [137.802017, -5.179166],
                [137.820282, -5.228612],
                [137.772507, -5.26972],
                [137.777496, -5.202219],
                [137.767776, -5.276389],
                [137.738892, -5.2875],
                [137.735001, -5.255556],
                [137.719528, -5.276592],
                [137.680832, -5.2375],
                [137.685822, -5.20237],
                [137.714172, -5.186945],
                [137.717346, -5.157984],
                [137.74556, -5.184166],
                [137.771118, -5.122233],
                [137.796753, -5.130053],
                [137.778671, -5.09182],
                [137.837708, -5.076876],
                [137.856323, -5.053611],
                [137.83812, -5.075417],
                [137.810272, -5.070417],
                [137.758606, -5.103056],
                [137.748337, -5.138055],
                [137.719452, -5.12028],
                [137.738052, -5.108611],
                [137.73555, -5.090277],
                [137.759583, -5.088611],
                [137.788605, -5.053889],
                [137.804718, -5.068056],
                [137.803604, -5.046939],
                [137.827774, -5.04639],
                [137.804169, -5.04472],
                [137.805832, -5.06639],
                [137.788895, -5.05222],
                [137.734924, -5.086945],
                [137.728394, -5.075208],
                [137.745834, -5.077781],
                [137.756943, -5.04833],
                [137.743896, -5.077222],
                [137.700272, -5.056945],
                [137.749451, -5.010277],
                [137.725006, -5.018056],
                [137.710007, -5.05472],
                [137.681885, -5.05243],
                [137.670502, -5.067969],
                [137.652222, -5.046112],
                [137.668335, -5.028788],
                [137.710556, -5.026332],
                [137.712875, -5.01128],
                [137.665924, -5.02725],
                [137.699081, -4.997439],
                [137.569565, -5.10746],
                [137.595276, -5.059999],
                [137.552505, -5.07263],
                [137.582199, -5.027038],
                [137.595078, -5.022031],
                [137.589127, -5.039639],
                [137.614685, -5.052061],
                [137.640549, -5.02611],
                [137.613556, -5.050661],
                [137.598053, -5.020278],
                [137.625275, -5.025557],
                [137.629379, -5.009692],
                [137.657227, -5.01028],
                [137.679459, -4.99131],
                [137.633636, -5.007355],
                [137.655731, -4.989439],
                [137.628967, -5.005211],
                [137.613617, -4.982547],
                [137.637772, -4.951111],
                [137.619919, -4.95427],
                [137.636337, -4.921067],
                [137.6203, -4.908901],
                [137.677643, -4.86235],
                [137.624222, -4.898518],
                [137.596146, -4.878935],
                [137.614426, -4.885729],
                [137.615723, -4.919128],
                [137.632904, -4.922148],
                [137.615616, -4.929145],
                [137.608093, -4.904179],
                [137.58374, -4.90084],
                [137.618164, -4.93389],
                [137.57988, -4.947223],
                [137.602661, -4.954443],
                [137.570618, -4.987661],
                [137.595551, -5.008055],
                [137.543884, -5.061111],
                [137.551361, -5.116002],
                [137.513962, -5.10728],
                [137.521423, -5.074759],
                [137.496094, -5.079269],
                [137.520874, -5.075976],
                [137.50499, -5.105753],
                [137.530273, -5.123245],
                [137.497177, -5.146317],
                [137.449387, -5.099888],
                [137.453369, -5.069268],
                [137.551117, -5.007791],
                [137.532837, -4.97914],
                [137.567963, -4.952649],
                [137.519592, -5.00266],
                [137.506882, -4.963662],
                [137.534622, -4.90425],
                [137.519028, -4.916176],
                [137.512833, -4.958318],
                [137.498032, -4.863029],
                [137.490372, -4.93494],
                [137.514755, -5.005213],
                [137.455872, -5.045023],
                [137.431671, -5.118589],
                [137.414444, -5.115833],
                [137.434174, -5.051972],
                [137.417221, -5.007223],
                [137.464661, -4.995559],
                [137.45369, -4.940616],
                [137.486435, -4.933999],
                [137.460342, -4.88565],
                [137.469101, -4.924051],
                [137.437317, -4.919075],
                [137.394958, -4.95218],
                [137.336853, -5.042817],
                [137.290665, -5.007679],
                [137.305283, -4.97194],
                [137.336243, -5.005831],
                [137.308609, -4.97],
                [137.364166, -4.97],
                [137.37944, -4.90389],
                [137.424728, -4.911666],
                [137.386383, -4.900277],
                [137.4375, -4.83833],
                [137.37056, -4.91],
                [137.331268, -4.846252],
                [137.368652, -4.891006],
                [137.369171, -4.925279],
                [137.349716, -4.97],
                [137.294647, -4.952153],
                [137.285294, -5.026015],
                [137.235596, -4.98977],
                [137.275009, -4.910381],
                [137.306946, -4.916944],
                [137.32103, -4.888448],
                [137.296661, -4.91278],
                [137.262375, -4.862608],
                [137.283447, -4.895315],
                [137.228455, -4.956563],
                [137.205627, -4.934858],
                [137.216599, -4.898372],
                [137.201218, -4.887019],
                [137.223679, -4.86581],
                [137.215042, -4.851497],
                [137.243774, -4.835383],
                [137.234619, -4.815379],
                [137.241043, -4.837099],
                [137.217072, -4.841949],
                [137.198929, -4.886048],
                [137.196808, -4.931351],
                [137.223663, -4.95997],
                [137.179993, -5.008611],
                [137.125, -4.964166],
                [137.13237, -4.90512],
                [137.16507, -4.90713],
                [137.142685, -4.885969],
                [137.178375, -4.887749],
                [137.211868, -4.843461],
                [137.17778, -4.885],
                [137.158646, -4.875339],
                [137.178055, -4.840555],
                [137.171707, -4.86604],
                [137.154037, -4.860249],
                [137.166626, -4.811603],
                [137.151871, -4.775251],
                [137.164413, -4.813178],
                [137.14856, -4.83271],
                [137.156601, -4.871111],
                [137.121964, -4.898376],
                [137.107773, -4.974721],
                [137.057785, -4.938612],
                [137.10643, -4.8794],
                [137.088455, -4.859736],
                [137.126877, -4.839812],
                [137.130676, -4.821499],
                [137.117126, -4.84292],
                [137.085022, -4.829271],
                [137.092728, -4.786487],
                [137.067596, -4.802947],
                [137.091797, -4.806379],
                [137.082443, -4.826791],
                [137.100662, -4.843472],
                [137.064194, -4.858589],
                [137.051727, -4.81478],
                [137.057465, -4.867086],
                [137.031662, -4.883056],
                [137.06189, -4.918713],
                [137.016602, -4.91996],
                [137.005524, -4.893362],
                [137.026108, -4.923059],
                [136.976257, -4.939583],
                [136.924591, -4.890449],
                [136.964447, -4.884722],
                [136.959946, -4.870778],
                [136.996536, -4.859626],
                [137.009384, -4.83132],
                [136.994171, -4.858056],
                [136.984833, -4.834018],
                [136.988907, -4.860497],
                [136.925003, -4.870833],
                [136.922073, -4.831021],
                [136.912643, -4.873709],
                [136.88269, -4.860162],
                [136.872772, -4.836671],
                [136.884445, -4.820277],
                [136.855881, -4.820006],
                [136.886108, -4.783888],
                [136.834473, -4.81917],
                [136.863327, -4.749166],
                [136.853607, -4.723888],
                [136.830551, -4.815555],
                [136.845551, -4.82758],
                [136.814102, -4.81142],
                [136.787338, -4.831839],
                [136.783127, -4.814169],
                [136.823959, -4.784859],
                [136.790024, -4.801367],
                [136.777283, -4.77061],
                [136.793686, -4.759283],
                [136.775238, -4.770323],
                [136.76973, -4.755557],
                [136.750717, -4.758848],
                [136.743195, -4.735069],
                [136.783615, -4.791945],
                [136.757782, -4.78583],
                [136.766937, -4.809166],
                [136.72226, -4.803013],
                [136.768326, -4.813333],
                [136.743332, -4.872779],
                [136.689438, -4.844445],
                [136.691116, -4.795555],
                [136.679932, -4.817713],
                [136.674576, -4.79347],
                [136.645828, -4.831459],
                [136.654541, -4.808576],
                [136.632034, -4.827103],
                [136.568893, -4.797082],
                [136.610962, -4.776591],
                [136.573486, -4.77887],
                [136.579926, -4.73576],
                [136.583038, -4.762241],
                [136.544449, -4.790555],
                [136.558548, -4.765355],
                [136.543259, -4.748958],
                [136.547531, -4.7741],
                [136.500412, -4.753264],
                [136.518753, -4.72222],
                [136.511093, -4.744961],
                [136.465088, -4.73842],
                [136.458893, -4.682219],
                [136.434174, -4.728512],
                [136.421249, -4.69368],
                [136.323303, -4.665585],
                [136.350357, -4.633609],
                [136.295029, -4.67434],
                [136.285126, -4.646466],
                [136.281799, -4.669376],
                [136.231384, -4.66257],
                [136.213058, -4.614722],
                [136.232224, -4.583329],
                [136.200317, -4.633944],
                [136.209305, -4.659304],
                [136.140549, -4.631945],
                [136.1875, -4.616944],
                [136.133362, -4.62563],
                [136.162018, -4.586597],
                [136.163605, -4.552219],
                [136.113892, -4.622781],
                [136.075485, -4.592074],
                [136.089447, -4.55972],
                [136.053055, -4.5875],
                [136.046036, -4.566249],
                [136.06778, -4.557778],
                [136.056229, -4.546981],
                [136.070343, -4.529861],
                [136.047775, -4.496945],
                [136.072495, -4.4825],
                [136.073059, -4.451805],
                [136.046387, -4.496389],
                [136.059723, -4.518889],
                [136.045013, -4.520808],
                [136.051392, -4.536944],
                [136.021164, -4.538193],
                [136.01915, -4.550833],
                [135.908188, -4.483339],
                [135.718887, -4.497223],
                [135.416977, -4.429519],
                [135.349716, -4.438889],
                [135.40889, -4.428888],
                [135.35611, -4.414719],
                [135.338669, -4.44479],
                [135.199768, -4.463722],
                [135.11908, -4.397203],
                [135.09639, -4.408057],
                [135.060562, -4.385001],
                [135.078873, -4.373259],
                [135.039795, -4.375563],
                [135.015274, -4.337818],
                [135.011948, -4.350281],
                [134.928589, -4.29995],
                [135.000839, -4.335765],
                [134.989441, -4.329445],
                [134.998428, -4.305543],
                [134.998886, -4.322361],
                [134.979721, -4.324169],
                [134.87616, -4.251943],
                [135.25766, -3.75588],
                [134.205246, -3.391239],
                [134.27594, -3.23835],
                [134.425034, -3.110369],
                [134.426788, -3.006559],
                [134.661392, -2.823219],
                [134.688339, -2.965833],
                [134.740005, -2.990555],
                [134.776672, -2.985279],
                [134.777771, -2.940829],
                [134.819168, -2.938611],
                [134.804169, -2.911667],
                [134.831161, -2.91347],
                [134.828888, -2.877499],
                [134.853058, -2.886668],
                [134.83194, -2.963055],
                [134.852753, -3.00157],
                [134.81105, -3.037865],
                [134.837784, -3.095831],
                [134.813553, -3.08191],
                [134.800629, -3.131394],
                [134.831635, -3.124849],
                [134.862289, -3.158792],
                [134.861557, -3.250149],
                [134.935944, -3.271329],
                [134.916977, -3.244816],
                [134.960007, -3.225279],
                [134.945831, -3.284169],
                [134.971939, -3.314721],
                [135.070618, -3.333921],
                [135.113922, -3.381292],
                [135.337784, -3.399168],
                [135.501968, -3.357546],
                [135.611282, -3.180421],
                [135.760956, -3.099078],
                [135.785873, -3.109717],
                [135.733948, -3.06395],
                [135.910553, -2.979166],
                [135.935486, -2.916109],
                [135.965057, -2.92696],
                [135.904999, -2.86861],
                [135.950562, -2.820001],
                [135.947495, -2.77917],
                [135.990082, -2.77009],
                [136.017731, -2.691776],
                [136.185013, -2.639112],
                [136.266083, -2.562176],
                [136.295883, -2.41113],
                [136.346664, -2.370277],
                [136.33223, -2.279722],
                [136.392654, -2.218956],
                [136.518143, -2.19077],
                [136.535141, -2.222489],
                [136.581741, -2.201986],
                [136.598709, -2.27059],
                [136.640823, -2.246155],
                [136.655609, -2.277758],
                [136.706421, -2.22702],
                [136.749146, -2.26798],
                [136.793533, -2.210915],
                [136.81073, -2.234741],
                [136.805161, -2.176717],
                [136.913895, -2.182777],
                [136.942215, -2.120279],
                [137.027176, -2.147546],
                [137.035324, -2.20457],
                [137.038681, -2.183144],
                [137.062576, -2.167899],
                [137.031235, -2.168865],
                [137.030853, -2.146974],
                [137.004715, -2.128333],
                [137.027252, -2.114966],
                [137.031235, -2.144123],
                [137.057602, -2.098139],
                [137.149048, -2.139998],
                [137.121948, -2.102294],
                [137.186279, -2.12408],
                [137.158966, -2.095534],
                [137.175613, -2.078479],
                [137.195541, -2.09764],
                [137.195679, -2.066915],
                [137.21167, -2.086944],
                [137.227066, -2.069857],
                [137.257874, -2.115513],
                [137.225052, -2.066614],
                [137.250519, -2.05105],
                [137.252502, -2.08639],
                [137.275803, -2.104687],
                [137.251404, -2.073809],
                [137.265396, -2.04078],
                [137.211685, -2.029968],
                [137.223068, -2.01325],
                [137.255707, -2.01153],
                [137.206161, -2.021535],
                [137.189163, -1.990279],
                [137.217224, -1.98333],
                [137.221603, -2.009503],
                [137.266937, -1.987779],
                [137.230438, -2.000925],
                [137.196945, -1.963055],
                [137.220551, -1.960279],
                [137.182495, -1.937779],
                [137.219452, -1.938611],
                [137.258057, -1.903059],
                [137.236893, -1.87196],
                [137.21666, -1.886669],
                [137.193329, -1.859169],
                [137.205826, -1.843612],
                [137.153122, -1.771874],
                [137.226944, -1.74833],
                [137.299438, -1.82722],
                [137.365829, -1.840829],
                [137.42778, -1.809439],
                [137.475998, -1.83778],
                [137.428329, -1.807777],
                [137.402222, -1.809446],
                [137.39473, -1.76361],
                [137.360275, -1.76],
                [137.354446, -1.708612],
                [137.331116, -1.689789],
                [137.394791, -1.6584],
                [137.429214, -1.671332],
                [137.401947, -1.653056],
                [137.441666, -1.62889],
                [137.45639, -1.662222],
                [137.44194, -1.639445],
                [137.47319, -1.606318],
                [137.48111, -1.634722],
                [137.503326, -1.643333],
                [137.491318, -1.670689],
                [137.519165, -1.672223],
                [137.492569, -1.670555],
                [137.504166, -1.642223],
                [137.475555, -1.618611],
                [137.524994, -1.62639],
                [137.477966, -1.597864],
                [137.531113, -1.578056],
                [137.52507, -1.604449],
                [137.580826, -1.55583],
                [137.585556, -1.585833],
                [137.603119, -1.57951],
                [137.607773, -1.608057],
                [137.642227, -1.62528],
                [137.603058, -1.57833],
                [137.585831, -1.580556],
                [137.63591, -1.561457],
                [137.633606, -1.593889],
                [137.637497, -1.561667],
                [137.592438, -1.552847],
                [137.636948, -1.528888],
                [137.634995, -1.54778],
                [137.65834, -1.550349],
                [137.636108, -1.54667],
                [137.648056, -1.51972],
                [137.727219, -1.493056],
                [137.75708, -1.546461],
                [137.793549, -1.54771],
                [137.796951, -1.52694],
                [137.753052, -1.538333],
                [137.741394, -1.481111],
                [137.809723, -1.49611],
                [137.778336, -1.480001],
                [137.83667, -1.461668],
                [137.934998, -1.458611],
                [137.970001, -1.531667],
                [137.950623, -1.520478],
                [137.913956, -1.55632],
                [137.953613, -1.537987],
                [137.93486, -1.587294],
                [137.964996, -1.542778],
                [137.962784, -1.584723],
                [138.000443, -1.63421],
                [137.963028, -1.62002],
                [137.921738, -1.634652],
                [137.954361, -1.654879],
                [137.953766, -1.62561],
                [138.00296, -1.640575],
                [138, -1.605278],
                [138.01889, -1.626944],
                [138.128326, -1.646109],
                [138.034485, -1.609399],
                [137.979996, -1.57028],
                [137.981049, -1.52854],
                [138.071808, -1.60333],
                [138.341309, -1.71601],
                [138.652985, -1.787329],
                [138.751114, -1.84611],
                [138.748062, -1.86667],
                [138.789734, -1.920396],
                [138.863342, -1.965819],
                [139, -1.975],
                [139.751251, -2.359654],
                [139.809998, -2.37778],
                [140.055222, -2.360203],
                [140.111282, -2.319439],
                [140.143158, -2.348534],
                [140.143417, -2.322349],
                [140.153885, -2.371111],
                [140.211105, -2.409444],
                [140.253586, -2.400959],
                [140.337006, -2.464859],
                [140.371384, -2.457221],
                [140.348801, -2.395277],
                [140.497955, -2.444],
                [140.612717, -2.43861],
                [140.686661, -2.477779]
              ]
            ],
            [
              [
                [137.988617, -1.571112],
                [138.024155, -1.600109],
                [138.023483, -1.616599],
                [137.980133, -1.583469],
                [137.988617, -1.571112]
              ]
            ],
            [
              [
                [137.978058, -1.581944],
                [138.012772, -1.607222],
                [137.98111, -1.59639],
                [137.975525, -1.569188],
                [137.978058, -1.581944]
              ]
            ],
            [
              [
                [138.716949, -1.57972],
                [138.747498, -1.625],
                [138.710556, -1.609445],
                [138.716949, -1.57972]
              ]
            ],
            [
              [
                [138.799728, -1.66194],
                [138.797577, -1.707807],
                [138.789169, -1.672499],
                [138.799728, -1.66194]
              ]
            ],
            [
              [
                [139.008606, -1.93222],
                [139.025116, -1.934025],
                [139.018188, -1.948651],
                [138.99884, -1.93779],
                [139.008606, -1.93222]
              ]
            ],
            [
              [
                [137.632217, -5.07833],
                [137.648895, -5.05111],
                [137.671387, -5.07],
                [137.694168, -5.05472],
                [137.725281, -5.087779],
                [137.716736, -5.121875],
                [137.742249, -5.136425],
                [137.704727, -5.14889],
                [137.670837, -5.216389],
                [137.550064, -5.169653],
                [137.664169, -5.117221],
                [137.677567, -5.094309],
                [137.665344, -5.075069],
                [137.661667, -5.116667],
                [137.604721, -5.103888],
                [137.632217, -5.07833]
              ]
            ],
            [
              [
                [137.726868, -5.07583],
                [137.732224, -5.111806],
                [137.715134, -5.10132],
                [137.726868, -5.07583]
              ]
            ],
            [
              [
                [137.574783, -5.109547],
                [137.599365, -5.096743],
                [137.62973, -5.127717],
                [137.547607, -5.142501],
                [137.574783, -5.109547]
              ]
            ],
            [
              [
                [137.775558, -5.108889],
                [137.794235, -5.12882],
                [137.771118, -5.119439],
                [137.743332, -5.182222],
                [137.717224, -5.155],
                [137.705826, -5.185],
                [137.705826, -5.153334],
                [137.752441, -5.137569],
                [137.759171, -5.104166],
                [137.775558, -5.108889]
              ]
            ],
            [
              [
                [137.76973, -5.359721],
                [137.780548, -5.334439],
                [137.843338, -5.285556],
                [137.892227, -5.329723],
                [137.838882, -5.375554],
                [137.76973, -5.359721]
              ]
            ],
            [
              [
                [138.156113, -5.462446],
                [138.177704, -5.439199],
                [138.201935, -5.485814],
                [138.240051, -5.447612],
                [138.254654, -5.534579],
                [138.234222, -5.536142],
                [138.231247, -5.553288],
                [138.260788, -5.565427],
                [138.243408, -5.597385],
                [138.277359, -5.60064],
                [138.239975, -5.611981],
                [138.24675, -5.666887],
                [138.213333, -5.67333],
                [138.183304, -5.72805],
                [138.134445, -5.718888],
                [138.089371, -5.745764],
                [138.045273, -5.598889],
                [138.064636, -5.556417],
                [138.133392, -5.536582],
                [138.156113, -5.462446]
              ]
            ],
            [
              [
                [138.372345, -5.631629],
                [138.372818, -5.645044],
                [138.341003, -5.647079],
                [138.3405, -5.659877],
                [138.225037, -5.68639],
                [138.250809, -5.663958],
                [138.243561, -5.611985],
                [138.286118, -5.619167],
                [138.339172, -5.595831],
                [138.372345, -5.631629]
              ]
            ],
            [
              [
                [138.219986, -5.699447],
                [138.204453, -5.712554],
                [138.219543, -5.67475],
                [138.219986, -5.699447]
              ]
            ],
            [
              [
                [135.823059, -1.064444],
                [135.773636, -0.875779],
                [135.740829, -0.810277],
                [135.708328, -0.802501],
                [135.729584, -0.77534],
                [135.642212, -0.886614],
                [135.467422, -0.74415],
                [135.542496, -0.839051],
                [135.541382, -0.875561],
                [135.50972, -0.827266],
                [135.483185, -0.81471],
                [135.489319, -0.805698],
                [135.393799, -0.723137],
                [135.363052, -0.63056],
                [135.533615, -0.673333],
                [135.589767, -0.663129],
                [135.595291, -0.70156],
                [135.64328, -0.662539],
                [135.68512, -0.71941],
                [135.717468, -0.70734],
                [135.748367, -0.739628],
                [135.80545, -0.685597],
                [135.854996, -0.700555],
                [135.924622, -0.790082],
                [136.051392, -0.856669],
                [136.039993, -0.89528],
                [136.091095, -0.916307],
                [136.170898, -1.042747],
                [136.256104, -1.016666],
                [136.323883, -1.073889],
                [136.387589, -1.076979],
                [136.315704, -1.13999],
                [136.139725, -1.20047],
                [136, -1.1425],
                [135.873337, -1.17333],
                [135.802216, -1.09861],
                [135.823059, -1.064444]
              ]
            ],
            [
              [
                [134.865616, -1.132207],
                [134.794449, -1.0325],
                [134.810608, -0.980829],
                [134.791916, -0.98111],
                [134.843536, -0.93096],
                [134.941528, -0.965931],
                [134.946854, -0.99929],
                [134.925003, -1.074723],
                [134.972229, -1.01083],
                [134.98584, -1.036112],
                [134.952164, -1.128141],
                [134.865616, -1.132207]
              ]
            ],
            [
              [
                [136.618332, -1.144722],
                [136.600555, -1.173061],
                [136.597931, -1.140549],
                [136.618332, -1.144722]
              ]
            ],
            [
              [
                [136.597504, -1.189167],
                [136.615051, -1.218769],
                [136.589996, -1.2275],
                [136.575912, -1.198749],
                [136.597504, -1.189167]
              ]
            ],
            [
              [
                [136.448471, -1.240008],
                [136.429825, -1.213009],
                [136.442581, -1.1992],
                [136.448471, -1.240008]
              ]
            ],
            [
              [
                [136.323914, -1.217178],
                [136.344635, -1.236434],
                [136.294739, -1.240503],
                [136.323914, -1.217178]
              ]
            ],
            [
              [
                [136.521667, -1.226666],
                [136.539108, -1.239675],
                [136.500702, -1.239311],
                [136.521667, -1.226666]
              ]
            ],
            [
              [
                [136.220123, -1.22892],
                [136.206329, -1.256306],
                [136.188354, -1.2483],
                [136.220123, -1.22892]
              ]
            ],
            [
              [
                [136.604172, -1.22861],
                [136.616837, -1.236922],
                [136.60878, -1.25436],
                [136.59375, -1.244999],
                [136.604172, -1.22861]
              ]
            ],
            [
              [
                [136.650345, -1.258679],
                [136.662201, -1.34517],
                [136.600372, -1.320721],
                [136.588333, -1.275829],
                [136.619873, -1.24419],
                [136.650345, -1.258679]
              ]
            ],
            [
              [
                [138.736664, -6.651109],
                [138.821671, -6.706112],
                [138.831116, -6.789719],
                [138.875, -6.803611],
                [138.927505, -6.787777],
                [138.944717, -6.82611],
                [138.835281, -6.826391],
                [138.752777, -6.75083],
                [138.672775, -6.724167],
                [138.671951, -6.640279],
                [138.736664, -6.651109]
              ]
            ],
            [
              [
                [138.674728, -6.753055],
                [138.736115, -6.759439],
                [138.798889, -6.834999],
                [138.728333, -6.883611],
                [138.679169, -6.86917],
                [138.66861, -6.838609],
                [138.696671, -6.831667],
                [138.700562, -6.809445],
                [138.675003, -6.834723],
                [138.610275, -6.755277],
                [138.635498, -6.729222],
                [138.674728, -6.753055]
              ]
            ],
            [
              [
                [139.348892, -7.191886],
                [139.429672, -7.217217],
                [139.273407, -7.21773],
                [139.261368, -7.15547],
                [139.238541, -7.139749],
                [139.201248, -7.156321],
                [139.179718, -7.2135],
                [139.159592, -7.202781],
                [139.06456, -7.211061],
                [138.935654, -7.159518],
                [138.855499, -7.157715],
                [138.613663, -7.010989],
                [138.565552, -6.918889],
                [138.626663, -6.87611],
                [138.717224, -6.90222],
                [138.798828, -6.850365],
                [138.934448, -6.842219],
                [139.128784, -6.966357],
                [139.187759, -6.97306],
                [139.224091, -6.99824],
                [139.219162, -7.025279],
                [139.344315, -7.047271],
                [139.380539, -7.112805],
                [139.338058, -7.165287],
                [139.348892, -7.191886]
              ]
            ],
            [
              [
                [139.778641, -7.11203],
                [139.765869, -7.121571],
                [139.782822, -7.101749],
                [139.778641, -7.11203]
              ]
            ],
            [
              [
                [138.765839, -7.16701],
                [138.716339, -7.156767],
                [138.713959, -7.116509],
                [138.759338, -7.144841],
                [138.765839, -7.16701]
              ]
            ],
            [
              [
                [138.791992, -7.16389],
                [138.781143, -7.14949],
                [138.806412, -7.159609],
                [138.791992, -7.16389]
              ]
            ],
            [
              [
                [138.992355, -7.200771],
                [139.029861, -7.223701],
                [138.960587, -7.196493],
                [138.992355, -7.200771]
              ]
            ],
            [
              [
                [139.651947, -7.205833],
                [139.676086, -7.288565],
                [139.655609, -7.286751],
                [139.639862, -7.223539],
                [139.651947, -7.205833]
              ]
            ],
            [
              [
                [139.160416, -7.208007],
                [139.17189, -7.215815],
                [139.144318, -7.211838],
                [139.160416, -7.208007]
              ]
            ],
            [
              [
                [135.239502, -1.483889],
                [135.253326, -1.52139],
                [135.262192, -1.478369],
                [135.337967, -1.482462],
                [135.325317, -1.509429],
                [135.247437, -1.529691],
                [135.092072, -1.501065],
                [135.117783, -1.4625],
                [135.154831, -1.489979],
                [135.215729, -1.474511],
                [135.221893, -1.50746],
                [135.239502, -1.483889]
              ]
            ],
            [
              [
                [136.18689, -1.870264],
                [135.978058, -1.816944],
                [135.911942, -1.754719],
                [135.899445, -1.788056],
                [135.867218, -1.740278],
                [135.864716, -1.767778],
                [135.845001, -1.746667],
                [135.829163, -1.76528],
                [135.821945, -1.73528],
                [135.762497, -1.748889],
                [135.788895, -1.71944],
                [135.751389, -1.721111],
                [135.732224, -1.68861],
                [135.672501, -1.706944],
                [135.553925, -1.66302],
                [135.474167, -1.680834],
                [135.465561, -1.63611],
                [135.410278, -1.601944],
                [135.785553, -1.62611],
                [135.846115, -1.63722],
                [135.85083, -1.66194],
                [135.89917, -1.63472],
                [136.20311, -1.64816],
                [136.370667, -1.717186],
                [136.445633, -1.697759],
                [136.789688, -1.73943],
                [136.903732, -1.793248],
                [136.848068, -1.82756],
                [136.718781, -1.816415],
                [136.630951, -1.872919],
                [136.503586, -1.849189],
                [136.536072, -1.899107],
                [136.341843, -1.860849],
                [136.275558, -1.883385],
                [136.259796, -1.913474],
                [136.251984, -1.887713],
                [136.229462, -1.909598],
                [136.18689, -1.870264]
              ]
            ],
            [
              [
                [135.789169, -1.759168],
                [135.792496, -1.771389],
                [135.765274, -1.772223],
                [135.789169, -1.759168]
              ]
            ],
            [
              [
                [135.813263, -1.784792],
                [135.824982, -1.803397],
                [135.804428, -1.817163],
                [135.794281, -1.80024],
                [135.813263, -1.784792]
              ]
            ],
            [
              [
                [137.02417, -1.830833],
                [137.054443, -1.869444],
                [136.950394, -1.83829],
                [137.02417, -1.830833]
              ]
            ],
            [
              [
                [136.363892, -1.886389],
                [136.372772, -1.898056],
                [136.358612, -1.903059],
                [136.363892, -1.886389]
              ]
            ],
            [
              [
                [136.28215, -1.88671],
                [136.316437, -1.904517],
                [136.299057, -1.934199],
                [136.252518, -1.936765],
                [136.28215, -1.88671]
              ]
            ],
            [
              [
                [136.33139, -1.905832],
                [136.357498, -1.905279],
                [136.36084, -1.962777],
                [136.330551, -1.935001],
                [136.33139, -1.905832]
              ]
            ],
            [
              [
                [139.641342, -7.166201],
                [139.639542, -7.180967],
                [139.629395, -7.166479],
                [139.584839, -7.185022],
                [139.641342, -7.166201]
              ]
            ],
            [
              [
                [139.582672, -7.199419],
                [139.574646, -7.245232],
                [139.566711, -7.226402],
                [139.589844, -7.19128],
                [139.582672, -7.199419]
              ]
            ],
            [
              [
                [138.238281, -7.461789],
                [138.418365, -7.40394],
                [138.749786, -7.370584],
                [138.865005, -7.436666],
                [138.912949, -7.53501],
                [139.087479, -7.572639],
                [138.994293, -7.74785],
                [138.993958, -7.849852],
                [138.896149, -7.899531],
                [138.888885, -8.03513],
                [138.913895, -8.075277],
                [138.827499, -8.079165],
                [138.794449, -8.13972],
                [138.678604, -8.150555],
                [138.615005, -8.249722],
                [138.488052, -8.321387],
                [138.468887, -8.350834],
                [138.340271, -8.379444],
                [138.323883, -8.370279],
                [138.33194, -8.300556],
                [138.300827, -8.391945],
                [137.870834, -8.373609],
                [137.656387, -8.44694],
                [137.63945, -8.416108],
                [137.705826, -8.172501],
                [137.80986, -8.041252],
                [137.79834, -8],
                [137.906097, -7.787568],
                [137.968765, -7.734963],
                [138.049286, -7.599432],
                [138.238281, -7.461789]
              ]
            ],
            [
              [
                [140.057495, -8],
                [140.072754, -8.043889],
                [140.05365, -8.022773],
                [140.057495, -8]
              ]
            ],
            [
              [
                [138.892502, -8.388057],
                [138.59639, -8.363333],
                [138.554031, -8.317221],
                [138.662506, -8.235833],
                [138.679443, -8.16972],
                [138.745285, -8.16528],
                [138.815002, -8.137778],
                [138.846115, -8.263055],
                [138.916107, -8.300055],
                [138.892502, -8.388057]
              ]
            ],
            [
              [
                [138.853058, -8.206389],
                [138.88681, -8.238333],
                [138.890274, -8.267221],
                [138.848343, -8.254039],
                [138.853058, -8.206389]
              ]
            ],
            [
              [
                [138.846939, -8.25472],
                [138.900833, -8.282498],
                [138.880829, -8.286945],
                [138.846939, -8.25472]
              ]
            ],
            [
              [
                [138.9375, -8.320277],
                [138.98111, -8.335279],
                [138.95166, -8.341667],
                [138.9375, -8.320277]
              ]
            ],
            [
              [
                [138.476105, -8.359444],
                [138.34639, -8.41944],
                [138.261673, -8.415559],
                [138.26445, -8.390828],
                [138.303329, -8.394999],
                [138.47139, -8.352778],
                [138.499451, -8.320829],
                [138.476105, -8.359444]
              ]
            ],
            [
              [
                [138.925827, -8.32361],
                [138.970276, -8.365555],
                [138.926117, -8.361945],
                [138.925827, -8.32361]
              ]
            ],
            [
              [
                [136.0336, -4.543684],
                [136.052338, -4.53639],
                [136.047501, -4.520831],
                [136.069244, -4.528965],
                [136.055054, -4.547481],
                [136.06691, -4.554398],
                [136.046677, -4.557589],
                [136.038391, -4.578921],
                [135.986526, -4.542084],
                [136.0336, -4.543684]
              ]
            ],
            [
              [
                [136.212494, -4.625279],
                [136.21167, -4.653333],
                [136.211395, -4.61472],
                [136.212494, -4.625279]
              ]
            ],
            [
              [
                [136.783844, -4.800275],
                [136.792496, -4.807291],
                [136.777893, -4.822709],
                [136.783844, -4.800275]
              ]
            ],
            [
              [
                [136.682083, -4.825362],
                [136.680832, -4.849443],
                [136.651459, -4.8268],
                [136.673447, -4.810445],
                [136.682083, -4.825362]
              ]
            ],
            [
              [
                [136.787216, -4.836388],
                [136.794312, -4.849026],
                [136.777771, -4.869718],
                [136.767227, -4.855556],
                [136.787216, -4.836388]
              ]
            ],
            [
              [
                [136.850891, -4.83077],
                [136.905563, -4.874452],
                [136.831451, -4.914025],
                [136.794998, -4.888334],
                [136.836945, -4.870831],
                [136.789032, -4.88345],
                [136.808517, -4.833339],
                [136.850891, -4.83077]
              ]
            ],
            [
              [
                [137.069458, -4.862466],
                [137.079391, -4.883259],
                [137.05806, -4.893611],
                [137.055145, -4.849776],
                [137.069458, -4.862466]
              ]
            ],
            [
              [
                [137.410934, -4.958318],
                [137.436829, -4.921295],
                [137.476303, -4.92868],
                [137.45314, -4.938498],
                [137.448639, -4.99531],
                [137.414169, -5.004723],
                [137.405579, -5.109431],
                [137.337982, -5.052601],
                [137.410934, -4.958318]
              ]
            ],
            [
              [
                [137.616577, -4.958748],
                [137.618896, -5.02194],
                [137.575302, -4.987336],
                [137.616577, -4.958748]
              ]
            ],
            [
              [
                [137.50972, -5.171941],
                [137.507141, -5.198541],
                [137.496109, -5.185281],
                [137.50972, -5.171941]
              ]
            ],
            [
              [
                [134.817627, -2.681377],
                [134.863327, -2.7075],
                [134.804443, -2.711944],
                [134.817627, -2.681377]
              ]
            ],
            [
              [
                [135.730316, -2.91161],
                [135.718063, -2.929645],
                [135.672134, -2.94229],
                [135.730316, -2.91161]
              ]
            ],
            [
              [
                [135.769165, -2.933611],
                [135.806946, -2.94306],
                [135.708496, -2.972639],
                [135.729828, -2.941041],
                [135.769165, -2.933611]
              ]
            ],
            [
              [
                [135.611801, -3.06994],
                [135.625916, -3.077431],
                [135.619553, -3.097629],
                [135.611801, -3.06994]
              ]
            ],
            [
              [
                [135.268097, -0.391721],
                [135.278336, -0.406945],
                [135.267563, -0.410619],
                [135.268097, -0.391721]
              ]
            ],
            [
              [
                [135.491287, -0.93065],
                [135.509216, -0.967496],
                [135.4935, -0.964363],
                [135.491287, -0.93065]
              ]
            ],
            [
              [
                [137.361664, -1.763056],
                [137.401947, -1.78667],
                [137.360138, -1.839236],
                [137.320007, -1.798611],
                [137.271118, -1.785278],
                [137.241943, -1.74111],
                [137.343338, -1.696667],
                [137.361664, -1.763056]
              ]
            ],
            [
              [
                [137.123337, -1.888055],
                [137.091949, -1.826389],
                [137.140274, -1.772501],
                [137.162781, -1.796666],
                [137.138336, -1.8475],
                [137.160828, -1.932362],
                [137.123337, -1.888055]
              ]
            ],
            [
              [
                [137.274719, -1.79111],
                [137.311951, -1.800001],
                [137.323792, -1.822457],
                [137.299591, -1.825001],
                [137.274719, -1.79111]
              ]
            ],
            [
              [
                [137.165833, -1.86778],
                [137.142105, -1.846445],
                [137.17775, -1.806419],
                [137.213058, -1.890555],
                [137.165833, -1.86778]
              ]
            ],
            [
              [
                [137.087494, -1.85566],
                [137.111938, -1.87611],
                [137.093933, -1.895802],
                [137.087494, -1.85566]
              ]
            ],
            [
              [
                [137.180557, -1.878057],
                [137.212296, -1.892709],
                [137.231674, -1.871939],
                [137.256104, -1.90694],
                [137.174728, -1.940279],
                [137.180557, -1.878057]
              ]
            ],
            [
              [
                [137.199997, -1.972219],
                [137.213333, -1.982222],
                [137.197083, -1.985486],
                [137.199997, -1.972219]
              ]
            ],
            [
              [
                [136.269501, -2.222935],
                [136.248444, -2.228918],
                [136.257401, -2.215674],
                [136.269501, -2.222935]
              ]
            ]
          ]
        },
        "id": 91,
        "properties": {
          "ID": 16,
          "kode": 91,
          "Propinsi": "PAPUA",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [122.6195, -8.40327],
                [122.6706, -8.39535],
                [122.729, -8.36376],
                [122.7693, -8.36789],
                [122.8147, -8.30079],
                [122.877, -8.29701],
                [122.8978, -8.27617],
                [122.9248, -8.19796],
                [122.8688, -8.17643],
                [122.8155, -8.19872],
                [122.7905, -8.23832],
                [122.7432, -8.21051],
                [122.7834, -8.16046],
                [122.7859, -8.11148],
                [122.8321, -8.10133],
                [122.8691, -8.06508],
                [122.9764, -8.14032],
                [122.9647, -8.19849],
                [123.0159, -8.27969],
                [123.0215, -8.32064],
                [122.9648, -8.3543],
                [122.9302, -8.33062],
                [122.8828, -8.38951],
                [122.8928, -8.45255],
                [122.7916, -8.43101],
                [122.783, -8.46163],
                [122.8367, -8.52308],
                [122.8381, -8.59432],
                [122.73, -8.6417],
                [122.6701, -8.63274],
                [122.5684, -8.67269],
                [122.5333, -8.71705],
                [122.3794, -8.76204],
                [122.3508, -8.75008],
                [122.2867, -8.764],
                [122.1999, -8.75926],
                [122.1256, -8.73763],
                [122.0858, -8.75021],
                [122.0352, -8.80331],
                [121.9284, -8.8417],
                [121.8651, -8.84578],
                [121.8184, -8.88101],
                [121.7381, -8.88868],
                [121.718, -8.86764],
                [121.6379, -8.85644],
                [121.5642, -8.81199],
                [121.5405, -8.82249],
                [121.3854, -8.79636],
                [121.3637, -8.82703],
                [121.3611, -8.89566],
                [121.3111, -8.92514],
                [121.2515, -8.90006],
                [121.1267, -8.90792],
                [121.0708, -8.9397],
                [120.9975, -8.9596],
                [120.9069, -8.92252],
                [120.8565, -8.87337],
                [120.827, -8.82198],
                [120.7782, -8.83222],
                [120.7668, -8.87329],
                [120.7266, -8.87758],
                [120.6248, -8.83975],
                [120.6032, -8.80507],
                [120.5406, -8.79251],
                [120.5014, -8.80494],
                [120.3745, -8.79988],
                [120.3207, -8.83812],
                [120.2396, -8.83066],
                [120.1793, -8.77632],
                [120.1168, -8.78019],
                [120.0637, -8.80548],
                [119.9371, -8.8341],
                [119.8216, -8.78411],
                [119.7994, -8.73838],
                [119.8127, -8.65842],
                [119.8019, -8.57549],
                [119.9154, -8.46571],
                [119.9588, -8.44277],
                [120.0002, -8.45481],
                [120.0043, -8.40762],
                [120.034, -8.37535],
                [120.0896, -8.38059],
                [120.1253, -8.35436],
                [120.1542, -8.37109],
                [120.2008, -8.29826],
                [120.26, -8.27393],
                [120.4055, -8.27102],
                [120.4419, -8.23714],
                [120.4648, -8.28285],
                [120.5241, -8.2609],
                [120.5603, -8.29137],
                [120.6582, -8.28036],
                [120.6896, -8.32071],
                [120.7431, -8.34189],
                [120.8114, -8.3341],
                [120.8595, -8.35319],
                [120.9799, -8.34873],
                [120.9947, -8.39561],
                [121.1606, -8.44687],
                [121.1876, -8.44131],
                [121.2386, -8.47818],
                [121.306, -8.49264],
                [121.3439, -8.5619],
                [121.4223, -8.58897],
                [121.4573, -8.56206],
                [121.5044, -8.61735],
                [121.6049, -8.54726],
                [121.6083, -8.49767],
                [121.6751, -8.51677],
                [121.7317, -8.50041],
                [121.808, -8.51648],
                [121.8425, -8.48957],
                [121.9275, -8.49713],
                [121.9504, -8.45808],
                [122.0267, -8.44553],
                [122.0162, -8.52998],
                [122.1068, -8.53397],
                [122.1615, -8.55955],
                [122.227, -8.62796],
                [122.2939, -8.65004],
                [122.3636, -8.61847],
                [122.4794, -8.61589],
                [122.5221, -8.55195],
                [122.4879, -8.51239],
                [122.4979, -8.46913],
                [122.5472, -8.46883],
                [122.6013, -8.39854],
                [122.6195, -8.40327]
              ]
            ],
            [
              [
                [124.046476, -9.342838],
                [124.0983, -9.41876],
                [124.157, -9.42968],
                [124.2065, -9.37529],
                [124.2687, -9.41875],
                [124.292, -9.50619],
                [124.3536, -9.47901],
                [124.3785, -9.35658],
                [124.4542, -9.31137],
                [124.472284, -9.18241],
                [124.5359, -9.19723],
                [124.6204, -9.16331],
                [124.6696, -9.12933],
                [124.7107, -9.05728],
                [124.7393, -9.06466],
                [124.8162, -9.01365],
                [124.8541, -9.01698],
                [124.8877, -8.98574],
                [124.949953, -8.967377],
                [124.9422, -9.04616],
                [124.9889, -9.06706],
                [125.0678, -9.02986],
                [125.1141, -8.97605],
                [125.182, -9.02754],
                [125.1877, -9.05588],
                [125.1722, -9.17896],
                [125.1018, -9.2008],
                [125.0577, -9.17553],
                [125.0155, -9.17832],
                [124.9847, -9.21434],
                [125.0001, -9.298],
                [125.0505, -9.33069],
                [125.0769, -9.38772],
                [125.092, -9.46447],
                [125.0071, -9.53962],
                [124.9834, -9.64711],
                [124.888, -9.70606],
                [124.8459, -9.74556],
                [124.7576, -9.86098],
                [124.7463, -9.89112],
                [124.6665, -9.93153],
                [124.6529, -9.95679],
                [124.5237, -10.03156],
                [124.4956, -10.09939],
                [124.4146, -10.1587],
                [124.379, -10.17015],
                [124.2336, -10.16353],
                [124.1707, -10.15307],
                [124.0928, -10.18045],
                [124.0698, -10.22167],
                [124.015, -10.26787],
                [123.9107, -10.29561],
                [123.8572, -10.3338],
                [123.7969, -10.3545],
                [123.7041, -10.36645],
                [123.6575, -10.3507],
                [123.6052, -10.36942],
                [123.5448, -10.3308],
                [123.4568, -10.3559],
                [123.5192, -10.22132],
                [123.5297, -10.17631],
                [123.6574, -10.13518],
                [123.7442, -10.09523],
                [123.7477, -10.0302],
                [123.6479, -10.00887],
                [123.5803, -10.03243],
                [123.5828, -9.92561],
                [123.6492, -9.89468],
                [123.6688, -9.81791],
                [123.6432, -9.77904],
                [123.6799, -9.75675],
                [123.6675, -9.64933],
                [123.7317, -9.57434],
                [123.7766, -9.55646],
                [123.7921, -9.4971],
                [123.8279, -9.50221],
                [123.9361, -9.42633],
                [123.9833, -9.35745],
                [124.046476, -9.342838]
              ]
            ],
            [
              [
                [119.6801, -9.82812],
                [119.6782, -9.794],
                [119.6154, -9.79994],
                [119.5839, -9.77393],
                [119.5296, -9.76832],
                [119.4709, -9.74123],
                [119.4114, -9.76779],
                [119.389, -9.79727],
                [119.3501, -9.76086],
                [119.3025, -9.74462],
                [119.1805, -9.74634],
                [119.0334, -9.68038],
                [118.9853, -9.60762],
                [118.9281, -9.55858],
                [118.9442, -9.51227],
                [118.9939, -9.45514],
                [119.0562, -9.42444],
                [119.0918, -9.42535],
                [119.1876, -9.37625],
                [119.2117, -9.39543],
                [119.3002, -9.36271],
                [119.3813, -9.38571],
                [119.4637, -9.35966],
                [119.5257, -9.38095],
                [119.5944, -9.34502],
                [119.6311, -9.3427],
                [119.6837, -9.37922],
                [119.7638, -9.38235],
                [119.7973, -9.39664],
                [119.9419, -9.28043],
                [119.9617, -9.32425],
                [120.1028, -9.47526],
                [120.1738, -9.46924],
                [120.2226, -9.4938],
                [120.2371, -9.59441],
                [120.2569, -9.644],
                [120.3607, -9.64701],
                [120.4517, -9.6284],
                [120.4984, -9.70313],
                [120.5523, -9.71446],
                [120.6071, -9.79198],
                [120.6418, -9.87525],
                [120.696, -9.91756],
                [120.7276, -9.91889],
                [120.7962, -9.97115],
                [120.8238, -10.02323],
                [120.8192, -10.08719],
                [120.7595, -10.13127],
                [120.7342, -10.17339],
                [120.6687, -10.22046],
                [120.6116, -10.24588],
                [120.5584, -10.23103],
                [120.4863, -10.26146],
                [120.4706, -10.30381],
                [120.4281, -10.30201],
                [120.3543, -10.24881],
                [120.2091, -10.25562],
                [120.1432, -10.22396],
                [120.0313, -10.1239],
                [119.9956, -10.0374],
                [119.9365, -9.98009],
                [119.8393, -9.92739],
                [119.7904, -9.92132],
                [119.7625, -9.89042],
                [119.7087, -9.86856],
                [119.6801, -9.82812]
              ]
            ],
            [
              [
                [124.3539, -8.38033],
                [124.4209, -8.29541],
                [124.4662, -8.25809],
                [124.5091, -8.26198],
                [124.5163, -8.22269],
                [124.4021, -8.27409],
                [124.3938, -8.22276],
                [124.4739, -8.13038],
                [124.5972, -8.12891],
                [124.5932, -8.19252],
                [124.6703, -8.16927],
                [124.7815, -8.15302],
                [124.8305, -8.17319],
                [125.0117, -8.16488],
                [125.0878, -8.15317],
                [125.1393, -8.22922],
                [125.1257, -8.34491],
                [125.0513, -8.36068],
                [124.8983, -8.37272],
                [124.7889, -8.40916],
                [124.6638, -8.39523],
                [124.5907, -8.4354],
                [124.5477, -8.44335],
                [124.5042, -8.41943],
                [124.4346, -8.45537],
                [124.3665, -8.44637],
                [124.3329, -8.4252],
                [124.3539, -8.38033]
              ]
            ],
            [
              [
                [123.5276, -8.36233],
                [123.5595, -8.36871],
                [123.5963, -8.33362],
                [123.5953, -8.28771],
                [123.6222, -8.2463],
                [123.7002, -8.25179],
                [123.6976, -8.21665],
                [123.7785, -8.16919],
                [123.8399, -8.21125],
                [123.9044, -8.21861],
                [123.911, -8.25849],
                [123.8382, -8.28234],
                [123.7639, -8.28847],
                [123.6734, -8.42844],
                [123.6204, -8.4061],
                [123.5531, -8.4716],
                [123.5829, -8.54344],
                [123.5455, -8.57183],
                [123.5049, -8.52237],
                [123.4487, -8.53498],
                [123.4261, -8.57759],
                [123.3883, -8.58505],
                [123.3148, -8.52816],
                [123.2864, -8.5495],
                [123.234, -8.51171],
                [123.2912, -8.4659],
                [123.3403, -8.39887],
                [123.4284, -8.37077],
                [123.4715, -8.3368],
                [123.4363, -8.27322],
                [123.5372, -8.23502],
                [123.5591, -8.25389],
                [123.5023, -8.33801],
                [123.5276, -8.36233]
              ]
            ],
            [
              [
                [123.1958, -10.58802],
                [123.2635, -10.51636],
                [123.4078, -10.45521],
                [123.3768, -10.55145],
                [123.4286, -10.65562],
                [123.3069, -10.70394],
                [123.2572, -10.73333],
                [123.2161, -10.81699],
                [123.1347, -10.81048],
                [123.0742, -10.84782],
                [122.968, -10.86041],
                [122.9112, -10.91875],
                [122.8847, -10.9029],
                [122.8195, -10.91942],
                [122.8198, -10.82761],
                [122.8044, -10.80657],
                [122.8534, -10.75584],
                [122.983, -10.7299],
                [123.0472, -10.72532],
                [123.1265, -10.64254],
                [123.178, -10.63356],
                [123.1958, -10.58802]
              ]
            ],
            [
              [
                [123.9428, -8.40262],
                [123.9618, -8.34731],
                [124.057, -8.294],
                [124.1009, -8.36503],
                [124.1639, -8.29119],
                [124.2086, -8.25939],
                [124.2258, -8.21369],
                [124.2778, -8.17424],
                [124.3162, -8.18454],
                [124.3197, -8.23096],
                [124.2932, -8.27001],
                [124.2955, -8.33138],
                [124.2429, -8.36111],
                [124.2035, -8.41588],
                [124.2077, -8.4605],
                [124.1385, -8.53854],
                [124.0619, -8.55243],
                [124.0617, -8.50392],
                [124.0329, -8.42446],
                [123.9428, -8.40262]
              ]
            ],
            [
              [
                [123.165, -8.39172],
                [123.1103, -8.40389],
                [123.0019, -8.40353],
                [123.0336, -8.31035],
                [123.1511, -8.23307],
                [123.2464, -8.2287],
                [123.3056, -8.29655],
                [123.308, -8.36417],
                [123.2869, -8.39409],
                [123.165, -8.39172]
              ]
            ],
            [
              [
                [121.8719, -10.6122],
                [121.7565, -10.6104],
                [121.6963, -10.57643],
                [121.8228, -10.5064],
                [121.8566, -10.44256],
                [121.8946, -10.42433],
                [121.9928, -10.43927],
                [121.9944, -10.5213],
                [121.8719, -10.6122]
              ]
            ],
            [
              [
                [119.4425, -8.75035],
                [119.3824, -8.72088],
                [119.4106, -8.6473],
                [119.3781, -8.58177],
                [119.4252, -8.54893],
                [119.4231, -8.44954],
                [119.4765, -8.45059],
                [119.5122, -8.49349],
                [119.5754, -8.4895],
                [119.5624, -8.58388],
                [119.5005, -8.58569],
                [119.4598, -8.61698],
                [119.4358, -8.67357],
                [119.4581, -8.69916],
                [119.4425, -8.75035]
              ]
            ],
            [
              [
                [123.3282, -10.3451],
                [123.3042, -10.26902],
                [123.3686, -10.20903],
                [123.3919, -10.14384],
                [123.4535, -10.11986],
                [123.4662, -10.16577],
                [123.4525, -10.22088],
                [123.4184, -10.2343],
                [123.4236, -10.29639],
                [123.3737, -10.30972],
                [123.3282, -10.3451]
              ]
            ],
            [
              [
                [123, -8.49739],
                [122.9458, -8.59493],
                [122.8954, -8.61934],
                [122.8738, -8.5781],
                [122.8848, -8.53292],
                [122.9503, -8.48984],
                [122.9816, -8.43728],
                [123.12, -8.4369],
                [123.1484, -8.46317],
                [123.0802, -8.49089],
                [123.0229, -8.47906],
                [123, -8.49739]
              ]
            ],
            [
              [
                [119.6352, -8.60281],
                [119.666, -8.61178],
                [119.6742, -8.68424],
                [119.7117, -8.65867],
                [119.7274, -8.61727],
                [119.8027, -8.61757],
                [119.8027, -8.65049],
                [119.7245, -8.68237],
                [119.7173, -8.79128],
                [119.6133, -8.74851],
                [119.6432, -8.65507],
                [119.6352, -8.60281]
              ]
            ],
            [
              [
                [125.5443, -8.30659],
                [125.5038, -8.26816],
                [125.5505, -8.22926],
                [125.6169, -8.14468],
                [125.6448, -8.17069],
                [125.6089, -8.23812],
                [125.5862, -8.31465],
                [125.5443, -8.30659]
              ]
            ],
            [
              [
                [122.3898, -8.50424],
                [122.3571, -8.49548],
                [122.3385, -8.44026],
                [122.3804, -8.43406],
                [122.4208, -8.48212],
                [122.3898, -8.50424]
              ]
            ],
            [
              [
                [121.7251, -8.36166],
                [121.681, -8.3275],
                [121.7321, -8.30295],
                [121.7251, -8.36166]
              ]
            ]
          ]
        },
        "id": 53,
        "properties": {
          "ID": 17,
          "kode": 53,
          "Propinsi": "NUSA TENGGARA TIMUR",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [115.194942, -8.732323],
                [115.180252, -8.757741],
                [115.23497, -8.799568],
                [115.170486, -8.847422],
                [115.087341, -8.835617],
                [115.089859, -8.811961],
                [115.161148, -8.779349],
                [115.166382, -8.717985],
                [115.140114, -8.66686],
                [115.097687, -8.638191],
                [115.068596, -8.591315],
                [114.929085, -8.474201],
                [114.801537, -8.414222],
                [114.714836, -8.394822],
                [114.627663, -8.407767],
                [114.575851, -8.388434],
                [114.522606, -8.33624],
                [114.445526, -8.21899],
                [114.428268, -8.168071],
                [114.438026, -8.0937],
                [114.48764, -8.09358],
                [114.536934, -8.138295],
                [114.582436, -8.116596],
                [114.692444, -8.144426],
                [114.744499, -8.167377],
                [114.853981, -8.195267],
                [114.997383, -8.17379],
                [115.114624, -8.079416],
                [115.179657, -8.058906],
                [115.338425, -8.111131],
                [115.452835, -8.162612],
                [115.564995, -8.233503],
                [115.634735, -8.322159],
                [115.701965, -8.354779],
                [115.716316, -8.397886],
                [115.680428, -8.443698],
                [115.644066, -8.459964],
                [115.609451, -8.505023],
                [115.543221, -8.499585],
                [115.499054, -8.547023],
                [115.44661, -8.566829],
                [115.359718, -8.577768],
                [115.264145, -8.655357],
                [115.260429, -8.703996],
                [115.194942, -8.732323]
              ]
            ],
            [
              [
                [115.623787, -8.773455],
                [115.588226, -8.818159],
                [115.484924, -8.767053],
                [115.445183, -8.722188],
                [115.488922, -8.672191],
                [115.565613, -8.670997],
                [115.623749, -8.750002],
                [115.623787, -8.773455]
              ]
            ]
          ]
        },
        "id": 51,
        "properties": {
          "ID": 18,
          "kode": 51,
          "Propinsi": "BALI",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [101.914047, -0.920961],
                [101.845276, -0.982571],
                [101.650337, -0.93408],
                [101.595917, -0.851199],
                [101.395836, -0.738651],
                [101.407646, -0.720709],
                [101.355362, -0.68732],
                [101.359833, -0.65925],
                [101.253769, -0.62543],
                [101.162247, -0.53627],
                [101.068939, -0.50064],
                [101.024826, -0.398439],
                [101.036423, -0.34944],
                [100.996368, -0.31436],
                [100.915039, -0.32568],
                [100.907509, -0.371669],
                [100.844856, -0.337659],
                [100.838783, -0.2906],
                [100.781006, -0.230419],
                [100.733833, -0.04317],
                [100.776741, -0.01239],
                [100.809166, -0.041148],
                [100.845413, 0.01544],
                [100.824219, 0.118061],
                [100.842186, 0.189851],
                [100.812531, 0.268481],
                [100.74514, 0.250221],
                [100.668938, 0.18083],
                [100.605743, 0.183469],
                [100.451752, 0.229109],
                [100.376022, 0.35071],
                [100.281677, 0.33296],
                [100.280533, 0.41644],
                [100.206459, 0.584121],
                [100.25573, 0.709401],
                [100.130508, 0.80901],
                [100.13134, 0.902851],
                [100.081863, 0.938071],
                [100.14064, 0.951121],
                [100.161377, 0.999161],
                [100.102188, 1.09181],
                [100.183029, 1.12499],
                [100.130478, 1.174599],
                [100.094643, 1.327261],
                [100.053658, 1.361081],
                [100.077881, 1.387211],
                [100.315857, 1.416461],
                [100.337547, 1.431191],
                [100.283859, 1.46392],
                [100.291878, 1.47756],
                [100.331718, 1.479493],
                [100.323486, 1.44348],
                [100.381348, 1.450509],
                [100.434021, 1.511289],
                [100.415894, 1.537212],
                [100.455322, 1.629089],
                [100.411362, 1.69775],
                [100.421158, 1.767701],
                [100.285522, 1.813293],
                [100.361328, 2.201904],
                [100.307129, 2.357482],
                [100.3293, 2.529452],
                [100.361389, 2.393055],
                [100.43, 2.272778],
                [100.645546, 2.110561],
                [100.670403, 2.124381],
                [100.773567, 2.081344],
                [100.795021, 2.112496],
                [100.760902, 2.252232],
                [100.789444, 2.286945],
                [101.053665, 2.28401],
                [101.078056, 2.214441],
                [101.305, 2.041667],
                [101.356941, 1.779443],
                [101.409721, 1.698055],
                [101.561386, 1.637221],
                [101.751945, 1.664723],
                [102.006752, 1.45814],
                [102.149017, 1.378252],
                [102.166061, 1.182529],
                [102.150597, 1.166825],
                [102.152969, 1.132561],
                [102.122726, 1.124511],
                [102.166817, 1.133411],
                [102.180069, 1.230281],
                [102.209427, 1.187251],
                [102.191437, 1.093891],
                [102.229172, 0.995434],
                [102.407532, 0.811556],
                [102.570236, 0.738701],
                [102.674683, 0.755719],
                [102.894142, 0.728491],
                [103.104446, 0.478611],
                [103.10733, 0.455001],
                [102.899178, 0.33559],
                [102.612465, 0.230497],
                [102.573799, 0.169348],
                [102.521767, 0.203079],
                [102.494911, 0.24997],
                [102.430275, 0.26111],
                [102.405815, 0.208622],
                [102.431664, 0.243611],
                [102.484947, 0.239691],
                [102.513992, 0.19311],
                [102.548119, 0.171994],
                [102.527206, 0.168425],
                [102.583611, 0.154167],
                [102.598793, 0.201912],
                [102.69117, 0.248486],
                [102.859177, 0.25005],
                [103.142067, 0.39348],
                [103.195908, 0.475206],
                [103.32621, 0.545462],
                [103.414749, 0.521301],
                [103.585732, 0.393189],
                [103.6007, 0.294215],
                [103.614403, 0.345418],
                [103.651665, 0.337222],
                [103.712776, 0.299446],
                [103.753052, 0.229166],
                [103.811668, -0.010834],
                [103.614273, -0.006715],
                [103.567307, -0.025967],
                [103.59581, -0.037978],
                [103.583702, -0.06066],
                [103.533882, -0.079921],
                [103.504738, -0.07983],
                [103.457008, -0.044549],
                [103.488052, -0.089149],
                [103.454102, -0.11187],
                [103.501549, -0.147554],
                [103.424141, -0.207049],
                [103.521393, -0.21111],
                [103.442245, -0.352848],
                [103.547226, -0.215831],
                [103.709442, -0.255829],
                [103.786392, -0.340556],
                [103.604721, -0.395834],
                [103.55278, -0.385277],
                [103.604164, -0.426389],
                [103.553055, -0.496112],
                [103.400467, -0.517549],
                [103.436386, -0.541945],
                [103.404716, -0.60417],
                [103.430557, -0.61583],
                [103.373337, -0.651111],
                [103.421112, -0.669167],
                [103.362617, -0.696942],
                [103.435143, -0.710556],
                [103.412331, -0.731389],
                [103.459999, -0.735277],
                [103.485001, -0.771112],
                [103.360077, -0.80318],
                [103.126038, -0.746429],
                [103.007942, -0.75602],
                [102.913399, -0.886409],
                [102.789528, -0.976889],
                [102.779449, -1.026619],
                [102.63903, -1.121101],
                [102.616806, -1.094629],
                [102.48381, -1.080979],
                [102.44252, -1.02545],
                [102.382446, -1.02669],
                [102.38623, -0.99338],
                [102.326332, -0.963339],
                [102.306816, -0.91736],
                [102.20359, -0.94852],
                [102.165001, -0.89873],
                [102.116623, -0.921171],
                [102.007767, -0.878599],
                [101.914047, -0.920961]
              ]
            ],
            [
              [
                [103.208931, 0.7028],
                [103.281151, 0.653311],
                [103.30043, 0.550661],
                [103.169434, 0.497803],
                [103.138641, 0.552931],
                [103.148483, 0.66511],
                [103.208931, 0.7028]
              ]
            ],
            [
              [
                [103.109169, 0.666945],
                [103.103752, 0.630071],
                [103.093102, 0.652121],
                [103.109169, 0.666945]
              ]
            ],
            [
              [
                [103.067978, 0.563381],
                [103.028374, 0.636104],
                [103.035622, 0.660158],
                [103.083977, 0.591011],
                [103.067978, 0.563381]
              ]
            ],
            [
              [
                [102.966736, 0.35389],
                [102.986626, 0.335219],
                [102.945236, 0.312279],
                [102.934753, 0.340978],
                [102.966736, 0.35389]
              ]
            ],
            [
              [
                [102.908363, 0.30936],
                [102.937454, 0.316539],
                [102.853996, 0.26006],
                [102.819542, 0.26782],
                [102.908363, 0.30936]
              ]
            ],
            [
              [
                [101.415833, 1.83972],
                [101.383057, 1.91056],
                [101.398056, 2.0075],
                [101.446945, 2.057222],
                [101.484169, 2.062781],
                [101.516388, 2.0275],
                [101.580345, 2.073965],
                [101.592461, 2.056639],
                [101.586113, 2.08611],
                [101.620003, 2.106112],
                [101.621925, 2.064369],
                [101.626564, 2.096252],
                [101.612999, 2.101656],
                [101.655724, 2.124963],
                [101.708885, 2.091945],
                [101.777779, 1.986669],
                [101.784164, 1.936389],
                [101.728333, 1.940555],
                [101.777779, 1.928332],
                [101.732224, 1.763888],
                [101.61528, 1.688055],
                [101.454445, 1.725555],
                [101.415833, 1.83972]
              ]
            ],
            [
              [
                [101.402496, 1.827501],
                [101.406113, 1.757219],
                [101.394165, 1.789166],
                [101.402496, 1.827501]
              ]
            ],
            [
              [
                [102.03833, 1.609171],
                [102.462502, 1.5175],
                [102.497498, 1.469171],
                [102.47139, 1.4425],
                [102.495552, 1.432219],
                [102.508278, 1.254549],
                [102.294716, 1.428889],
                [102.181946, 1.43778],
                [102.084999, 1.481667],
                [101.99855, 1.584105],
                [102.03833, 1.609171]
              ]
            ],
            [
              [
                [102.44252, 1.08239],
                [102.478317, 0.941141],
                [102.44014, 0.94943],
                [102.386307, 0.91344],
                [102.277443, 1.003204],
                [102.236137, 1.101478],
                [102.245789, 1.194704],
                [102.205338, 1.277218],
                [102.229706, 1.352399],
                [102.213898, 1.41431],
                [102.290672, 1.41322],
                [102.484993, 1.238279],
                [102.484642, 1.198129],
                [102.442146, 1.242096],
                [102.483612, 1.195],
                [102.44252, 1.08239]
              ]
            ],
            [
              [
                [102.657677, 1.03797],
                [102.680397, 1.108101],
                [102.73597, 1.1547],
                [102.874504, 1.134391],
                [103.003326, 1.072781],
                [103.154999, 0.894719],
                [103.153206, 0.847245],
                [103.071854, 0.831692],
                [102.88929, 0.964007],
                [102.806084, 0.992549],
                [102.790306, 1.023331],
                [102.821716, 1.04924],
                [102.880127, 1.042189],
                [102.854736, 1.085342],
                [102.811562, 1.080421],
                [102.81189, 1.036981],
                [102.778641, 1.020097],
                [102.73085, 1.039711],
                [102.624474, 1.011149],
                [102.657677, 1.03797]
              ]
            ],
            [
              [
                [102.536591, 1.127383],
                [102.614899, 1.047572],
                [102.6082, 1.005649],
                [102.500656, 0.945325],
                [102.45977, 1.076551],
                [102.48484, 1.12999],
                [102.536591, 1.127383]
              ]
            ],
            [
              [
                [102.865929, 1.059505],
                [102.823753, 1.053876],
                [102.842125, 1.071836],
                [102.865929, 1.059505]
              ]
            ],
            [
              [
                [102.905869, 0.778383],
                [102.691811, 0.792222],
                [102.56028, 0.76921],
                [102.43708, 0.82588],
                [102.408745, 0.887096],
                [102.440979, 0.93514],
                [102.48465, 0.929567],
                [102.589317, 1.001665],
                [102.638046, 0.97948],
                [102.737106, 1.02234],
                [102.743286, 0.995879],
                [102.646317, 0.95067],
                [102.626183, 0.903375],
                [102.646179, 0.948769],
                [102.684723, 0.957781],
                [102.697144, 0.927527],
                [102.782051, 0.904975],
                [102.78083, 0.926944],
                [102.746651, 0.908059],
                [102.69558, 0.937002],
                [102.77578, 1.005175],
                [103.046654, 0.82447],
                [103.048889, 0.718611],
                [102.994003, 0.689499],
                [102.905869, 0.778383]
              ]
            ],
            [
              [
                [103.092308, 0.820251],
                [103.109169, 0.806708],
                [103.082703, 0.795887],
                [103.075043, 0.820691],
                [103.092308, 0.820251]
              ]
            ],
            [
              [
                [103.103416, 0.798351],
                [103.114723, 0.736939],
                [103.087738, 0.717289],
                [103.080353, 0.77615],
                [103.103416, 0.798351]
              ]
            ],
            [
              [
                [103.546112, 0.448891],
                [103.590279, 0.408331],
                [103.558212, 0.423656],
                [103.546112, 0.448891]
              ]
            ],
            [
              [
                [103.628563, -0.016778],
                [103.64669, -0.050217],
                [103.599472, -0.020901],
                [103.628563, -0.016778]
              ]
            ],
            [
              [
                [103.666656, -0.05315],
                [103.635635, -0.016538],
                [103.680496, -0.038186],
                [103.666656, -0.05315]
              ]
            ],
            [
              [
                [103.479721, -0.053889],
                [103.501663, -0.081667],
                [103.528336, -0.082777],
                [103.490547, -0.085829],
                [103.463608, -0.045556],
                [103.479721, -0.053889]
              ]
            ],
            [
              [
                [103.581146, -0.077897],
                [103.590836, -0.097778],
                [103.542999, -0.121347],
                [103.473335, -0.108055],
                [103.581146, -0.077897]
              ]
            ],
            [
              [
                [103.557281, -0.176062],
                [103.566826, -0.195259],
                [103.477783, -0.20167],
                [103.557281, -0.176062]
              ]
            ],
            [
              [
                [100.735832, 2.149445],
                [100.710281, 2.21722],
                [100.739441, 2.261945],
                [100.785553, 2.121667],
                [100.735832, 2.149445]
              ]
            ],
            [
              [
                [100.638336, 2.219241],
                [100.67028, 2.196739],
                [100.684448, 2.161741],
                [100.632797, 2.195269],
                [100.638336, 2.219241]
              ]
            ]
          ]
        },
        "id": 14,
        "properties": {
          "ID": 19,
          "kode": 14,
          "Propinsi": "RIAU",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [103.991943, 1.134721],
                [104.013611, 1.189443],
                [104.071663, 1.118888],
                [104.101944, 1.140834],
                [104.071953, 1.18778],
                [104.098892, 1.199444],
                [104.138885, 1.169166],
                [104.136948, 1.039722],
                [104.101379, 1.042414],
                [104.079712, 1.024154],
                [104.100166, 0.982464],
                [104.030563, 1.00639],
                [104.040527, 0.9825],
                [104.008575, 0.985876],
                [103.990829, 1.017414],
                [103.94265, 1.008161],
                [103.910004, 1.038056],
                [103.888046, 1.0925],
                [103.937225, 1.08389],
                [103.920227, 1.14296],
                [103.991943, 1.134721]
              ]
            ],
            [
              [
                [103.888046, 1.158889],
                [103.889458, 1.14181],
                [103.868889, 1.147222],
                [103.888046, 1.158889]
              ]
            ],
            [
              [
                [103.802505, 1.101735],
                [103.812241, 1.084147],
                [103.790558, 1.096945],
                [103.802505, 1.101735]
              ]
            ],
            [
              [
                [103.849442, 1.069171],
                [103.882149, 1.05321],
                [103.838646, 1.027541],
                [103.83255, 1.06309],
                [103.849442, 1.069171]
              ]
            ],
            [
              [
                [104.181389, 1.068332],
                [104.167503, 1.022221],
                [104.154999, 1.041389],
                [104.181389, 1.068332]
              ]
            ],
            [
              [
                [103.749954, 1.026713],
                [103.791252, 1.04645],
                [103.806206, 0.972775],
                [103.782646, 0.97739],
                [103.749954, 1.026713]
              ]
            ],
            [
              [
                [103.903107, 1.03268],
                [103.919678, 1.012794],
                [103.903259, 1.002832],
                [103.885101, 1.02966],
                [103.903107, 1.03268]
              ]
            ],
            [
              [
                [103.832474, 0.989007],
                [103.868256, 1.037678],
                [103.877739, 1.012594],
                [103.900421, 0.998828],
                [103.925278, 1.008061],
                [103.974998, 0.954444],
                [103.941902, 0.909791],
                [103.832474, 0.989007]
              ]
            ],
            [
              [
                [104.178833, 1.02863],
                [104.174721, 0.998611],
                [104.155556, 0.998333],
                [104.155243, 1.016117],
                [104.178833, 1.02863]
              ]
            ],
            [
              [
                [103.991943, 1.000556],
                [103.981392, 1.005834],
                [103.992226, 1.011944],
                [103.991943, 1.000556]
              ]
            ],
            [
              [
                [104.245827, 0.997499],
                [104.258888, 0.97389],
                [104.232224, 0.979722],
                [104.245827, 0.997499]
              ]
            ],
            [
              [
                [104.152237, 0.97107],
                [104.172226, 0.973541],
                [104.198067, 0.945872],
                [104.153069, 0.93375],
                [104.152237, 0.97107]
              ]
            ],
            [
              [
                [104.0625, 0.967223],
                [104.079666, 0.949464],
                [104.068428, 0.914281],
                [104.044579, 0.938139],
                [104.0625, 0.967223]
              ]
            ],
            [
              [
                [104.178719, 0.78228],
                [104.14537, 0.856911],
                [104.09214, 0.870374],
                [104.094444, 0.926755],
                [104.078018, 0.959827],
                [104.119728, 0.961952],
                [104.140556, 0.942221],
                [104.167175, 0.867541],
                [104.184509, 0.88475],
                [104.265961, 0.85921],
                [104.245346, 0.820324],
                [104.178719, 0.78228]
              ]
            ],
            [
              [
                [104.168579, 0.941861],
                [104.191391, 0.928334],
                [104.161392, 0.910555],
                [104.168579, 0.941861]
              ]
            ],
            [
              [
                [104.21447, 0.916711],
                [104.193329, 0.902286],
                [104.204201, 0.922235],
                [104.21447, 0.916711]
              ]
            ],
            [
              [
                [104.048843, 0.921121],
                [104.051514, 0.900766],
                [104.035652, 0.892237],
                [104.048843, 0.921121]
              ]
            ],
            [
              [
                [104.193886, 0.78806],
                [104.276863, 0.796511],
                [104.264015, 0.743706],
                [104.309097, 0.707096],
                [104.248886, 0.723611],
                [104.253304, 0.692524],
                [104.218613, 0.724445],
                [104.196388, 0.720833],
                [104.170685, 0.77976],
                [104.193886, 0.78806]
              ]
            ],
            [
              [
                [104.30864, 0.76319],
                [104.357208, 0.741919],
                [104.299721, 0.755833],
                [104.30864, 0.76319]
              ]
            ],
            [
              [
                [104.264999, 0.621389],
                [104.212471, 0.718914],
                [104.285561, 0.66168],
                [104.289169, 0.610554],
                [104.264999, 0.621389]
              ]
            ],
            [
              [
                [104.29319, 0.689987],
                [104.301392, 0.671667],
                [104.270416, 0.682853],
                [104.29319, 0.689987]
              ]
            ],
            [
              [
                [104.326897, 0.678119],
                [104.329605, 0.660429],
                [104.312317, 0.66227],
                [104.314339, 0.684932],
                [104.326897, 0.678119]
              ]
            ],
            [
              [
                [104.085518, 0.643431],
                [104.104195, 0.624462],
                [104.085831, 0.6025],
                [104.085518, 0.643431]
              ]
            ],
            [
              [
                [104.198395, 0.608487],
                [104.230003, 0.570278],
                [104.203529, 0.572165],
                [104.197502, 0.541668],
                [104.198395, 0.608487]
              ]
            ],
            [
              [
                [104.22525, 0.557619],
                [104.238327, 0.53083],
                [104.2192, 0.541424],
                [104.22525, 0.557619]
              ]
            ],
            [
              [
                [103.388893, 1.16639],
                [103.40889, 1.129167],
                [103.379448, 1.150279],
                [103.388893, 1.16639]
              ]
            ],
            [
              [
                [103.375, 1.13806],
                [103.389725, 1.066666],
                [103.425003, 1.045559],
                [103.438919, 0.988355],
                [103.350723, 1.004977],
                [103.312225, 1.043889],
                [103.310898, 1.0875],
                [103.375, 1.13806]
              ]
            ],
            [
              [
                [103.444168, 0.962781],
                [103.473892, 0.949719],
                [103.475281, 0.919721],
                [103.425835, 0.95611],
                [103.444168, 0.962781]
              ]
            ],
            [
              [
                [103.418053, 0.95],
                [103.439163, 0.930834],
                [103.419327, 0.913091],
                [103.406944, 0.940555],
                [103.418053, 0.95]
              ]
            ],
            [
              [
                [103.526588, 0.867764],
                [103.501663, 0.86169],
                [103.489807, 0.926629],
                [103.526588, 0.867764]
              ]
            ],
            [
              [
                [103.448593, 0.92195],
                [103.47905, 0.908221],
                [103.486595, 0.854903],
                [103.409561, 0.892529],
                [103.448593, 0.92195]
              ]
            ],
            [
              [
                [103.752777, 0.90139],
                [103.765831, 0.882501],
                [103.744156, 0.894441],
                [103.752777, 0.90139]
              ]
            ],
            [
              [
                [103.932793, 0.76699],
                [103.918098, 0.755371],
                [103.835861, 0.838657],
                [103.810516, 0.899544],
                [103.898209, 0.854561],
                [103.896111, 0.804721],
                [103.906944, 0.833333],
                [103.932793, 0.76699]
              ]
            ],
            [
              [
                [103.505753, 0.723843],
                [103.458176, 0.638205],
                [103.400017, 0.649092],
                [103.34333, 0.800561],
                [103.370277, 0.894444],
                [103.405396, 0.882045],
                [103.412231, 0.84503],
                [103.486664, 0.794171],
                [103.505753, 0.723843]
              ]
            ],
            [
              [
                [103.748337, 0.886391],
                [103.83432, 0.812035],
                [103.829681, 0.752109],
                [103.719788, 0.85222],
                [103.748337, 0.886391]
              ]
            ],
            [
              [
                [103.422485, 0.875584],
                [103.494286, 0.845531],
                [103.527649, 0.76686],
                [103.472771, 0.822776],
                [103.421257, 0.841823],
                [103.422485, 0.875584]
              ]
            ],
            [
              [
                [103.671112, 0.864168],
                [103.693024, 0.823861],
                [103.654678, 0.873375],
                [103.671112, 0.864168]
              ]
            ],
            [
              [
                [103.701981, 0.857501],
                [103.696854, 0.836451],
                [103.691391, 0.856667],
                [103.701981, 0.857501]
              ]
            ],
            [
              [
                [103.699379, 0.80447],
                [103.728027, 0.766115],
                [103.713066, 0.752],
                [103.661285, 0.828747],
                [103.699379, 0.80447]
              ]
            ],
            [
              [
                [103.54023, 0.793539],
                [103.554886, 0.778246],
                [103.543266, 0.754735],
                [103.529892, 0.776238],
                [103.54023, 0.793539]
              ]
            ],
            [
              [
                [103.966095, 0.76825],
                [103.95089, 0.763291],
                [103.962479, 0.777925],
                [103.966095, 0.76825]
              ]
            ],
            [
              [
                [103.654968, 0.74142],
                [103.687973, 0.723951],
                [103.674232, 0.712245],
                [103.654968, 0.74142]
              ]
            ],
            [
              [
                [103.746147, 0.736406],
                [103.741257, 0.684986],
                [103.693207, 0.710456],
                [103.692604, 0.728509],
                [103.746147, 0.736406]
              ]
            ],
            [
              [
                [103.509483, 0.702729],
                [103.516907, 0.69439],
                [103.495583, 0.692653],
                [103.509483, 0.702729]
              ]
            ],
            [
              [
                [103.520882, 0.6907],
                [103.490433, 0.625259],
                [103.46209, 0.622588],
                [103.488167, 0.681338],
                [103.520882, 0.6907]
              ]
            ],
            [
              [
                [103.683189, 0.632263],
                [103.706596, 0.615174],
                [103.673744, 0.613337],
                [103.683189, 0.632263]
              ]
            ],
            [
              [
                [103.64637, 0.621533],
                [103.664047, 0.614349],
                [103.63105, 0.621476],
                [103.64637, 0.621533]
              ]
            ],
            [
              [
                [103.595352, 0.531329],
                [103.623047, 0.507661],
                [103.618752, 0.494904],
                [103.597855, 0.490893],
                [103.576202, 0.558519],
                [103.595352, 0.531329]
              ]
            ],
            [
              [
                [103.636169, 0.532426],
                [103.634186, 0.514899],
                [103.61972, 0.526666],
                [103.636169, 0.532426]
              ]
            ],
            [
              [
                [104.221107, 1.058612],
                [104.238609, 1.124171],
                [104.334999, 1.145],
                [104.311668, 1.182501],
                [104.371727, 1.179231],
                [104.39389, 1.204445],
                [104.499443, 1.169166],
                [104.562225, 1.190556],
                [104.545555, 1.2175],
                [104.584167, 1.228332],
                [104.577156, 1.156674],
                [104.657051, 1.045382],
                [104.634163, 0.986188],
                [104.660164, 0.9152],
                [104.621002, 0.900818],
                [104.594337, 0.810601],
                [104.557312, 0.813413],
                [104.476234, 0.849759],
                [104.491501, 0.875025],
                [104.438919, 0.906104],
                [104.438118, 0.932751],
                [104.476883, 0.919293],
                [104.498665, 0.935507],
                [104.40889, 0.963611],
                [104.415306, 0.978571],
                [104.495552, 0.97972],
                [104.440353, 0.99803],
                [104.445686, 1.017259],
                [104.481018, 1.014196],
                [104.498337, 1.03639],
                [104.416954, 1.039773],
                [104.416809, 1.068339],
                [104.37925, 1.010575],
                [104.316818, 1.039859],
                [104.331375, 0.994548],
                [104.250557, 1.00417],
                [104.221107, 1.058612]
              ]
            ],
            [
              [
                [107.41111, 1.103226],
                [107.388336, 1.088888],
                [107.403893, 1.113334],
                [107.41111, 1.103226]
              ]
            ],
            [
              [
                [107.504906, 1.048235],
                [107.519447, 1.046341],
                [107.503738, 1.035955],
                [107.504906, 1.048235]
              ]
            ],
            [
              [
                [104.821388, 0.968055],
                [104.78611, 1.02889],
                [104.849442, 1.01333],
                [104.854164, 0.9825],
                [104.821388, 0.968055]
              ]
            ],
            [
              [
                [107.531166, 1.02283],
                [107.587502, 1.01111],
                [107.603615, 0.991111],
                [107.547775, 0.949444],
                [107.574539, 1.007085],
                [107.544998, 0.972221],
                [107.531166, 1.02283]
              ]
            ],
            [
              [
                [107.42778, 0.97889],
                [107.471107, 0.969441],
                [107.468613, 0.923334],
                [107.438332, 0.932501],
                [107.42778, 0.97889]
              ]
            ],
            [
              [
                [104.627151, 0.89593],
                [104.661942, 0.880279],
                [104.628212, 0.854511],
                [104.627151, 0.89593]
              ]
            ],
            [
              [
                [104.683609, 0.895834],
                [104.695831, 0.848888],
                [104.676392, 0.84639],
                [104.660278, 0.862499],
                [104.683609, 0.895834]
              ]
            ],
            [
              [
                [104.45945, 0.884146],
                [104.485085, 0.870221],
                [104.416557, 0.87629],
                [104.45945, 0.884146]
              ]
            ],
            [
              [
                [104.648331, 0.86889],
                [104.666389, 0.82],
                [104.62603, 0.803713],
                [104.648331, 0.86889]
              ]
            ],
            [
              [
                [104.623245, 0.849223],
                [104.613647, 0.846101],
                [104.621719, 0.86092],
                [104.623245, 0.849223]
              ]
            ],
            [
              [
                [104.364296, 0.846365],
                [104.356056, 0.81376],
                [104.353302, 0.845016],
                [104.364296, 0.846365]
              ]
            ],
            [
              [
                [104.548058, 0.795278],
                [104.587502, 0.776111],
                [104.50972, 0.743335],
                [104.508789, 0.773499],
                [104.548058, 0.795278]
              ]
            ],
            [
              [
                [104.593887, 0.794171],
                [104.636665, 0.78389],
                [104.585556, 0.751667],
                [104.593887, 0.794171]
              ]
            ],
            [
              [
                [104.706322, 0.790191],
                [104.74704, 0.786283],
                [104.750153, 0.753349],
                [104.687912, 0.772549],
                [104.706322, 0.790191]
              ]
            ],
            [
              [
                [104.696114, 0.763334],
                [104.757629, 0.74733],
                [104.762222, 0.733334],
                [104.734398, 0.717881],
                [104.696114, 0.763334]
              ]
            ],
            [
              [
                [104.659164, 0.753333],
                [104.620163, 0.69751],
                [104.601768, 0.699288],
                [104.615585, 0.738065],
                [104.659164, 0.753333]
              ]
            ],
            [
              [
                [107.241669, 0.14639],
                [107.251389, 0.141109],
                [107.22139, 0.098888],
                [107.204437, 0.12694],
                [107.241669, 0.14639]
              ]
            ],
            [
              [
                [104.408302, 0.472981],
                [104.426178, 0.459885],
                [104.40168, 0.46247],
                [104.408302, 0.472981]
              ]
            ],
            [
              [
                [104.521347, 0.424976],
                [104.5625, 0.4125],
                [104.52916, 0.404983],
                [104.533157, 0.361604],
                [104.521347, 0.424976]
              ]
            ],
            [
              [
                [104.324257, 0.38417],
                [104.325531, 0.369253],
                [104.305267, 0.385],
                [104.324257, 0.38417]
              ]
            ],
            [
              [
                [104.430405, 0.384874],
                [104.433197, 0.372036],
                [104.408951, 0.377782],
                [104.430405, 0.384874]
              ]
            ],
            [
              [
                [104.414612, 0.301367],
                [104.398888, 0.284444],
                [104.389725, 0.308612],
                [104.354446, 0.313888],
                [104.328743, 0.375651],
                [104.424026, 0.326898],
                [104.43354, 0.307211],
                [104.398056, 0.313888],
                [104.414612, 0.301367]
              ]
            ],
            [
              [
                [104.468361, 0.364601],
                [104.490631, 0.346131],
                [104.459511, 0.34875],
                [104.468361, 0.364601]
              ]
            ],
            [
              [
                [104.302498, 0.346945],
                [104.32222, 0.336388],
                [104.319168, 0.308056],
                [104.301392, 0.30944],
                [104.302498, 0.346945]
              ]
            ],
            [
              [
                [104.327499, 0.32806],
                [104.369446, 0.30528],
                [104.386673, 0.273609],
                [104.355278, 0.27861],
                [104.327499, 0.32806]
              ]
            ],
            [
              [
                [104.362251, 0.268636],
                [104.363892, 0.255834],
                [104.351685, 0.274184],
                [104.362251, 0.268636]
              ]
            ],
            [
              [
                [104.498802, 0.261466],
                [104.512192, 0.250579],
                [104.489418, 0.266601],
                [104.498802, 0.261466]
              ]
            ],
            [
              [
                [104.443611, 0.250834],
                [104.473717, 0.227603],
                [104.447258, 0.234139],
                [104.443611, 0.250834]
              ]
            ],
            [
              [
                [104.515556, 0.225561],
                [104.531113, 0.20944],
                [104.514519, 0.247661],
                [104.704475, 0.046888],
                [104.702049, 0.018291],
                [104.621956, 0.074787],
                [104.634163, 0.077501],
                [104.627777, 0.095834],
                [104.605606, 0.095834],
                [104.619156, 0.075001],
                [104.564049, 0.115879],
                [104.545097, 0.145139],
                [104.569168, 0.142219],
                [104.54528, 0.16806],
                [104.536842, 0.15057],
                [104.504723, 0.191389],
                [104.527908, 0.184785],
                [104.52417, 0.19726],
                [104.47583, 0.242777],
                [104.515556, 0.225561]
              ]
            ],
            [
              [
                [104.232468, 0.199581],
                [104.241783, 0.17658],
                [104.227524, 0.154723],
                [104.200279, 0.170834],
                [104.232468, 0.199581]
              ]
            ],
            [
              [
                [104.473442, 0.177131],
                [104.483856, 0.161489],
                [104.461945, 0.170278],
                [104.473442, 0.177131]
              ]
            ],
            [
              [
                [104.298744, 0.166706],
                [104.336342, 0.130701],
                [104.336937, 0.10628],
                [104.300751, 0.128406],
                [104.298744, 0.166706]
              ]
            ],
            [
              [
                [104.435089, 0.064922],
                [104.378044, 0.123239],
                [104.432777, 0.089441],
                [104.401947, 0.162431],
                [104.492004, 0.072191],
                [104.486755, 0.043938],
                [104.462502, 0.060278],
                [104.494789, 0.014492],
                [104.435089, 0.064922]
              ]
            ],
            [
              [
                [104.488892, 0.151944],
                [104.516388, 0.130833],
                [104.516388, 0.108055],
                [104.488892, 0.151944]
              ]
            ],
            [
              [
                [104.54187, 0.102252],
                [104.56105, 0.072029],
                [104.534019, 0.095916],
                [104.54187, 0.102252]
              ]
            ],
            [
              [
                [104.743607, 0.08667],
                [104.754639, 0.078303],
                [104.740501, 0.075097],
                [104.743607, 0.08667]
              ]
            ],
            [
              [
                [104.491394, 0.088336],
                [104.498795, 0.071992],
                [104.480873, 0.087456],
                [104.491394, 0.088336]
              ]
            ],
            [
              [
                [104.769371, 0.059421],
                [104.784454, 0.030865],
                [104.739166, 0.0325],
                [104.738609, 0.048333],
                [104.769371, 0.059421]
              ]
            ],
            [
              [
                [104.539162, 0.044531],
                [104.543839, 0.032846],
                [104.521446, 0.051195],
                [104.539162, 0.044531]
              ]
            ],
            [
              [
                [104.659157, -0.110831],
                [104.682777, -0.083889],
                [104.788055, -0.204863],
                [104.823334, -0.195277],
                [104.827225, -0.132779],
                [104.896942, -0.231112],
                [104.997498, -0.278056],
                [105.001732, -0.30332],
                [104.921944, -0.277777],
                [104.899796, -0.294237],
                [104.929733, -0.324721],
                [104.915833, -0.339722],
                [104.849167, -0.322499],
                [104.703331, -0.20472],
                [104.521667, -0.276239],
                [104.498611, -0.24194],
                [104.429169, -0.236666],
                [104.426109, -0.184169],
                [104.504997, -0.129166],
                [104.493614, 0.003333],
                [104.531387, -0.02444],
                [104.548897, 0.0027],
                [104.565834, -0.011944],
                [104.552177, 0.02917],
                [104.653336, -0.051667],
                [104.668053, -0.077779],
                [104.624443, -0.079445],
                [104.659157, -0.110831]
              ]
            ],
            [
              [
                [104.532501, 0.026389],
                [104.545479, 0.006588],
                [104.522987, -0.001248],
                [104.513245, 0.013289],
                [104.532501, 0.026389]
              ]
            ],
            [
              [
                [104.842224, -0.035555],
                [104.879166, -0.081111],
                [104.837502, -0.053059],
                [104.842224, -0.035555]
              ]
            ],
            [
              [
                [104.731598, -0.098751],
                [104.766388, -0.148334],
                [104.745003, -0.139445],
                [104.731598, -0.098751]
              ]
            ],
            [
              [
                [104.470001, -0.277219],
                [104.491943, -0.307778],
                [104.46389, -0.31333],
                [104.459717, -0.328329],
                [104.385834, -0.298611],
                [104.470001, -0.277219]
              ]
            ],
            [
              [
                [104.233887, -0.331943],
                [104.233055, -0.355555],
                [104.221107, -0.343335],
                [104.233887, -0.331943]
              ]
            ],
            [
              [
                [104.320831, -0.360834],
                [104.324722, -0.339439],
                [104.337776, -0.38889],
                [104.311119, -0.416878],
                [104.346107, -0.44639],
                [104.346947, -0.3875],
                [104.385834, -0.413334],
                [104.430275, -0.383611],
                [104.440277, -0.340278],
                [104.480415, -0.335485],
                [104.597427, -0.45986],
                [104.517776, -0.57167],
                [104.503326, -0.64694],
                [104.428886, -0.57639],
                [104.395279, -0.604722],
                [104.389168, -0.666944],
                [104.355835, -0.679443],
                [104.330559, -0.514445],
                [104.30722, -0.539719],
                [104.2425, -0.485833],
                [104.276947, -0.395556],
                [104.320831, -0.360834]
              ]
            ],
            [
              [
                [104.156113, -0.3375],
                [104.177498, -0.350833],
                [104.157501, -0.36528],
                [104.142776, -0.350277],
                [104.156113, -0.3375]
              ]
            ],
            [
              [
                [104.186111, -0.360556],
                [104.230278, -0.364439],
                [104.234169, -0.386112],
                [104.185555, -0.38889],
                [104.186111, -0.360556]
              ]
            ],
            [
              [
                [104.196114, -0.392222],
                [104.229156, -0.39389],
                [104.223053, -0.429445],
                [104.195274, -0.42361],
                [104.196114, -0.392222]
              ]
            ],
            [
              [
                [107.964722, 4.66889],
                [107.927498, 4.693334],
                [108.007179, 4.775322],
                [107.964722, 4.66889]
              ]
            ],
            [
              [
                [108.120285, 3.726185],
                [108.18882, 3.783804],
                [108.275383, 3.710817],
                [108.208305, 3.802454],
                [108.061745, 3.83516],
                [107.961945, 4.003333],
                [108.07386, 4.06799],
                [108.218857, 4.23043],
                [108.242348, 4.216281],
                [108.233963, 4.128587],
                [108.402809, 3.96678],
                [108.389877, 3.893811],
                [108.362556, 3.90342],
                [108.352882, 3.876487],
                [108.367233, 3.895001],
                [108.378372, 3.856221],
                [108.407944, 3.886981],
                [108.410088, 3.858323],
                [108.387474, 3.793217],
                [108.309113, 3.730069],
                [108.296234, 3.657469],
                [108.223572, 3.663732],
                [108.156494, 3.630646],
                [108.089989, 3.67947],
                [108.120285, 3.726185]
              ]
            ],
            [
              [
                [107.860283, 4.160001],
                [107.863892, 4.1275],
                [107.84417, 4.111667],
                [107.860283, 4.160001]
              ]
            ],
            [
              [
                [107.897781, 3.913612],
                [107.921669, 3.900555],
                [107.911942, 3.88139],
                [107.894302, 3.892223],
                [107.897781, 3.913612]
              ]
            ],
            [
              [
                [108.002502, 3.817779],
                [108.02861, 3.80722],
                [108.040001, 3.765833],
                [108.012573, 3.778473],
                [108.002502, 3.817779]
              ]
            ],
            [
              [
                [108.116943, 3.763059],
                [108.126389, 3.755278],
                [108.113892, 3.751388],
                [108.116943, 3.763059]
              ]
            ],
            [
              [
                [108.05246, 3.715161],
                [108.089447, 3.694444],
                [108.085945, 3.682095],
                [108.044151, 3.676598],
                [108.05246, 3.715161]
              ]
            ],
            [
              [
                [108.053886, 3.669722],
                [108.083054, 3.654445],
                [108.081947, 3.633611],
                [108.050545, 3.631025],
                [108.053886, 3.669722]
              ]
            ],
            [
              [
                [108.105278, 3.637221],
                [108.121407, 3.606961],
                [108.07, 3.583609],
                [108.076401, 3.612536],
                [108.105278, 3.637221]
              ]
            ],
            [
              [
                [108.044167, 3.593891],
                [108.044701, 3.556943],
                [108.033058, 3.574905],
                [108.044167, 3.593891]
              ]
            ],
            [
              [
                [108.087624, 3.583575],
                [108.095558, 3.572221],
                [108.080826, 3.574171],
                [108.087624, 3.583575]
              ]
            ],
            [
              [
                [106.343887, 3.417499],
                [106.338264, 3.351805],
                [106.320557, 3.32194],
                [106.329086, 3.40451],
                [106.343887, 3.417499]
              ]
            ],
            [
              [
                [106.279671, 3.402523],
                [106.290833, 3.387221],
                [106.268394, 3.394186],
                [106.279671, 3.402523]
              ]
            ],
            [
              [
                [106.440002, 3.395834],
                [106.443336, 3.379166],
                [106.427223, 3.3725],
                [106.440002, 3.395834]
              ]
            ],
            [
              [
                [106.185555, 3.300832],
                [106.171387, 3.332779],
                [106.226967, 3.394551],
                [106.226669, 3.31167],
                [106.212502, 3.297222],
                [106.20137, 3.325823],
                [106.199997, 3.288056],
                [106.185555, 3.300832]
              ]
            ],
            [
              [
                [106.260559, 3.34778],
                [106.264488, 3.336977],
                [106.269447, 3.381671],
                [106.302643, 3.38014],
                [106.288055, 3.250001],
                [106.273056, 3.29694],
                [106.260559, 3.289166],
                [106.284164, 3.224999],
                [106.226936, 3.259441],
                [106.260559, 3.34778]
              ]
            ],
            [
              [
                [106.407776, 3.308333],
                [106.394157, 3.289441],
                [106.392776, 3.306391],
                [106.407776, 3.308333]
              ]
            ],
            [
              [
                [106.449928, 3.250835],
                [106.454933, 3.225108],
                [106.437233, 3.24],
                [106.449928, 3.250835]
              ]
            ],
            [
              [
                [106.202011, 3.228541],
                [106.226669, 3.235834],
                [106.288063, 3.179441],
                [106.278267, 3.07611],
                [106.248611, 3.121111],
                [106.236664, 3.095555],
                [106.210556, 3.120833],
                [106.202011, 3.228541]
              ]
            ],
            [
              [
                [106.304733, 3.234441],
                [106.297379, 3.215611],
                [106.291664, 3.230277],
                [106.304733, 3.234441]
              ]
            ],
            [
              [
                [106.486107, 3.21139],
                [106.501877, 3.202296],
                [106.498055, 3.183612],
                [106.47683, 3.19026],
                [106.486107, 3.21139]
              ]
            ],
            [
              [
                [106.316948, 3.163056],
                [106.311386, 3.135834],
                [106.333611, 3.158612],
                [106.343544, 3.123333],
                [106.308411, 3.083893],
                [106.284447, 3.123055],
                [106.316948, 3.163056]
              ]
            ],
            [
              [
                [106.086945, 3.158334],
                [106.099167, 3.13861],
                [106.089157, 3.12278],
                [106.073891, 3.146389],
                [106.086945, 3.158334]
              ]
            ],
            [
              [
                [106.419167, 3.150555],
                [106.436386, 3.128612],
                [106.402779, 3.134444],
                [106.419167, 3.150555]
              ]
            ],
            [
              [
                [106.395554, 3.15139],
                [106.396942, 3.136391],
                [106.379448, 3.138332],
                [106.395554, 3.15139]
              ]
            ],
            [
              [
                [106.118614, 3.133888],
                [106.122498, 3.116945],
                [106.109726, 3.124999],
                [106.118614, 3.133888]
              ]
            ],
            [
              [
                [105.652222, 3.120833],
                [105.674446, 3.090347],
                [105.639168, 3.090277],
                [105.652222, 3.120833]
              ]
            ],
            [
              [
                [105.687767, 3.118059],
                [105.694717, 3.102219],
                [105.673889, 3.109444],
                [105.687767, 3.118059]
              ]
            ],
            [
              [
                [105.960831, 3.102222],
                [105.961388, 3.091111],
                [105.946663, 3.098055],
                [105.960831, 3.102222]
              ]
            ],
            [
              [
                [105.701668, 3.10028],
                [105.735275, 3.083056],
                [105.711388, 3.073609],
                [105.701668, 3.10028]
              ]
            ],
            [
              [
                [106.356041, 3.092223],
                [106.353615, 3.048056],
                [106.34111, 3.064445],
                [106.356041, 3.092223]
              ]
            ],
            [
              [
                [105.98278, 3.087781],
                [105.991669, 3.071941],
                [105.977554, 3.016952],
                [105.963333, 3.040277],
                [105.98278, 3.087781]
              ]
            ],
            [
              [
                [105.835556, 2.99167],
                [105.852219, 2.957777],
                [105.82, 2.96889],
                [105.828056, 2.910556],
                [105.79583, 2.908611],
                [105.821945, 2.88639],
                [105.78389, 2.907499],
                [105.76722, 2.871391],
                [105.722557, 2.873294],
                [105.769997, 2.839166],
                [105.72583, 2.816667],
                [105.693054, 2.850277],
                [105.714722, 2.959167],
                [105.713058, 2.983333],
                [105.675003, 3.008611],
                [105.70472, 3.031945],
                [105.690552, 3.057779],
                [105.719032, 3.029859],
                [105.739998, 3.05],
                [105.7425, 2.97222],
                [105.835556, 2.99167]
              ]
            ],
            [
              [
                [108.845383, 3.052106],
                [108.868889, 3.042221],
                [108.864662, 3.013659],
                [108.842628, 3.011941],
                [108.845383, 3.052106]
              ]
            ],
            [
              [
                [106.408333, 3.037223],
                [106.407776, 3.012779],
                [106.392502, 3.029171],
                [106.408333, 3.037223]
              ]
            ],
            [
              [
                [107.785553, 3.02028],
                [107.813263, 2.98029],
                [107.757477, 2.971379],
                [107.747498, 3.006042],
                [107.785553, 3.02028]
              ]
            ],
            [
              [
                [108.867599, 3.010284],
                [108.890396, 2.99946],
                [108.884453, 2.879908],
                [108.817139, 2.843667],
                [108.774979, 2.89059],
                [108.867599, 3.010284]
              ]
            ],
            [
              [
                [106.12722, 2.939444],
                [106.149445, 2.918888],
                [106.111946, 2.92694],
                [106.12722, 2.939444]
              ]
            ],
            [
              [
                [105.68898, 2.924182],
                [105.6875, 2.908889],
                [105.67778, 2.918888],
                [105.68898, 2.924182]
              ]
            ],
            [
              [
                [106.221657, 2.745001],
                [106.200279, 2.75889],
                [106.222221, 2.767223],
                [106.196114, 2.811112],
                [106.228333, 2.804441],
                [106.254448, 2.72389],
                [106.221657, 2.745001]
              ]
            ],
            [
              [
                [108.921387, 2.767501],
                [108.914436, 2.737501],
                [108.886551, 2.726805],
                [108.921387, 2.767501]
              ]
            ],
            [
              [
                [106.167778, 2.762223],
                [106.193336, 2.746109],
                [106.184723, 2.739167],
                [106.167778, 2.762223]
              ]
            ],
            [
              [
                [108.559059, 2.722721],
                [108.583336, 2.686391],
                [108.535835, 2.652683],
                [108.535767, 2.67608],
                [108.569534, 2.680347],
                [108.545631, 2.708569],
                [108.578888, 2.696112],
                [108.559059, 2.722721]
              ]
            ],
            [
              [
                [109.053337, 2.495001],
                [109.006645, 2.489467],
                [109.031776, 2.516169],
                [108.979179, 2.520744],
                [108.975914, 2.555733],
                [109.120514, 2.530496],
                [109.113327, 2.503611],
                [109.053337, 2.495001]
              ]
            ],
            [
              [
                [108.949013, 2.500911],
                [108.974968, 2.49068],
                [108.973106, 2.454099],
                [108.933647, 2.44834],
                [108.906662, 2.501989],
                [108.949013, 2.533502],
                [108.949013, 2.500911]
              ]
            ],
            [
              [
                [108.985863, 2.506519],
                [108.99971, 2.492658],
                [108.988022, 2.48843],
                [108.985863, 2.506519]
              ]
            ],
            [
              [
                [108.99913, 2.465561],
                [108.984047, 2.465561],
                [108.984047, 2.483391],
                [108.99913, 2.483391],
                [108.99913, 2.465561]
              ]
            ],
            [
              [
                [104.392174, 1.015839],
                [104.397118, 0.996733],
                [104.365883, 0.98954],
                [104.392174, 1.015839]
              ]
            ]
          ]
        },
        "id": 21,
        "properties": {
          "ID": 76,
          "kode": 21,
          "Propinsi": "KEPULAUAN RIAU",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [119.16658, -3.487701],
                [119.108658, -3.516343],
                [119.021446, -3.512368],
                [118.996391, -3.564717],
                [118.967438, -3.545067],
                [118.936943, -3.570281],
                [118.906113, -3.525279],
                [118.845711, -3.379756],
                [118.85833, -3.30111],
                [118.828476, -3.168945],
                [118.775139, -3.10422],
                [118.796806, -3.070608],
                [118.838623, -3.081085],
                [118.886108, -2.890834],
                [118.84639, -2.849721],
                [118.769447, -2.867222],
                [118.756668, -2.785],
                [118.818298, -2.61948],
                [118.868889, -2.679672],
                [118.91111, -2.666111],
                [119.030479, -2.58205],
                [119.044724, -2.506667],
                [119.144447, -2.470001],
                [119.121391, -2.319721],
                [119.144722, -2.200281],
                [119.187775, -2.151111],
                [119.211937, -1.99778],
                [119.288063, -1.9525],
                [119.330559, -1.962223],
                [119.363609, -1.853891],
                [119.311111, -1.798611],
                [119.28167, -1.68972],
                [119.325836, -1.589167],
                [119.28833, -1.450555],
                [119.291656, -1.390281],
                [119.332779, -1.349445],
                [119.30278, -1.273612],
                [119.339722, -1.17],
                [119.376663, -1.165556],
                [119.464722, -1.06861],
                [119.553627, -0.860949],
                [119.550926, -1.008439],
                [119.584396, -1.118209],
                [119.610977, -1.12573],
                [119.581657, -1.25812],
                [119.474258, -1.287229],
                [119.467911, -1.306169],
                [119.508636, -1.31016],
                [119.423126, -1.356679],
                [119.529373, -1.427779],
                [119.623283, -1.395169],
                [119.695396, -1.450899],
                [119.659027, -1.50289],
                [119.777779, -1.692499],
                [119.756668, -1.755519],
                [119.867416, -1.76982],
                [119.909172, -1.834719],
                [119.860428, -1.87573],
                [119.873543, -1.948739],
                [119.817101, -2.044529],
                [119.727409, -2.07925],
                [119.61335, -2.204939],
                [119.596573, -2.294341],
                [119.690308, -2.33612],
                [119.715866, -2.39282],
                [119.689026, -2.437739],
                [119.720917, -2.500069],
                [119.700089, -2.553469],
                [119.751106, -2.604909],
                [119.761757, -2.677659],
                [119.73996, -2.71976],
                [119.643578, -2.75641],
                [119.493317, -2.74063],
                [119.5252, -2.793351],
                [119.534187, -2.94152],
                [119.62973, -3.113259],
                [119.539963, -3.133521],
                [119.484596, -3.18489],
                [119.396072, -3.19317],
                [119.490921, -3.48397],
                [119.476357, -3.504407],
                [119.427223, -3.45972],
                [119.373055, -3.463334],
                [119.304726, -3.425277],
                [119.236168, -3.479168],
                [119.16658, -3.487701]
              ]
            ],
            [
              [
                [118.891388, -2.612777],
                [118.888885, -2.653612],
                [118.877777, -2.62639],
                [118.891388, -2.612777]
              ]
            ]
          ]
        },
        "id": 76,
        "properties": {
          "ID": 77,
          "kode": 76,
          "Propinsi": "SULAWESI BARAT",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [119.551262, -0.861102],
                [119.620102, -0.827921],
                [119.628067, -0.795006],
                [119.680214, -0.767804],
                [119.672073, -0.701232],
                [119.725906, -0.65805],
                [119.761536, -0.681504],
                [119.810547, -0.824481],
                [119.844048, -0.881364],
                [119.877937, -0.860083],
                [119.876678, -0.800739],
                [119.857048, -0.776077],
                [119.858978, -0.711951],
                [119.82312, -0.687051],
                [119.759331, -0.489319],
                [119.755516, -0.43625],
                [119.777512, -0.278784],
                [119.817886, -0.188819],
                [119.793221, -0.1203],
                [119.730637, -0.144836],
                [119.675064, -0.120693],
                [119.606422, -0.011014],
                [119.719109, -0.007734],
                [119.767677, -0.076805],
                [119.777496, -0.112435],
                [119.836311, -0.110336],
                [119.888161, -0.012285],
                [119.869308, 0.103943],
                [119.773888, 0.195469],
                [119.803307, 0.235098],
                [119.886375, 0.210903],
                [119.906456, 0.230215],
                [119.849724, 0.35105],
                [119.889595, 0.39088],
                [119.87677, 0.437173],
                [119.912537, 0.474808],
                [119.999992, 0.48103],
                [120.045471, 0.508192],
                [120.036507, 0.605598],
                [120.017326, 0.662879],
                [120.07708, 0.73949],
                [120.131271, 0.739655],
                [120.231522, 0.792128],
                [120.245316, 0.836357],
                [120.234756, 0.882558],
                [120.246323, 0.957992],
                [120.347801, 0.986282],
                [120.328423, 0.85386],
                [120.375938, 0.833208],
                [120.388908, 0.801644],
                [120.475189, 0.778041],
                [120.500801, 0.794271],
                [120.577087, 0.769187],
                [120.600052, 0.806312],
                [120.606506, 0.872798],
                [120.67897, 0.960633],
                [120.721901, 0.959311],
                [120.78904, 1.036012],
                [120.797989, 1.093499],
                [120.776329, 1.112198],
                [120.824081, 1.183676],
                [120.808014, 1.271],
                [120.83316, 1.320694],
                [120.92836, 1.329284],
                [120.976059, 1.348508],
                [121.065201, 1.308341],
                [121.100578, 1.321008],
                [121.232872, 1.241835],
                [121.301094, 1.256887],
                [121.355553, 1.253551],
                [121.407257, 1.269449],
                [121.44651, 1.318008],
                [121.470947, 1.272841],
                [121.43441, 1.230314],
                [121.431061, 1.172257],
                [121.483253, 1.112933],
                [121.561028, 1.069681],
                [121.707085, 1.060967],
                [121.858894, 1.099807],
                [121.888367, 1.086147],
                [121.936829, 1.106721],
                [121.960297, 1.046818],
                [121.991379, 1.027292],
                [122.105263, 1.073977],
                [122.166107, 1.037744],
                [122.188141, 1.045298],
                [122.067818, 0.997213],
                [122.02533, 0.949058],
                [121.993927, 0.94102],
                [121.968292, 0.894658],
                [121.925529, 0.946584],
                [121.882187, 0.9727],
                [121.831047, 0.963612],
                [121.754608, 0.926077],
                [121.673096, 0.914232],
                [121.620621, 0.883505],
                [121.499672, 0.850688],
                [121.386032, 0.870693],
                [121.294579, 0.82408],
                [121.260277, 0.78299],
                [121.225578, 0.708211],
                [121.168579, 0.690562],
                [121.247383, 0.597502],
                [121.35453, 0.571319],
                [121.345665, 0.474487],
                [121.296829, 0.496804],
                [121.246132, 0.462963],
                [121.183212, 0.459422],
                [121.152977, 0.409079],
                [121.088135, 0.397582],
                [121.032486, 0.439164],
                [120.905403, 0.393952],
                [120.861519, 0.438808],
                [120.730331, 0.473514],
                [120.698936, 0.508861],
                [120.606247, 0.518789],
                [120.528976, 0.514094],
                [120.364449, 0.452948],
                [120.304108, 0.413597],
                [120.24424, 0.338138],
                [120.211548, 0.269989],
                [120.1577, 0.222198],
                [120.121338, 0.157835],
                [120.132538, 0.125113],
                [120.103745, 0.087791],
                [120.104767, 0.006305],
                [120.089226, -0.040358],
                [120.031914, -0.088148],
                [120.040031, -0.140389],
                [120.008202, -0.176373],
                [120.00975, -0.29075],
                [120.029526, -0.303267],
                [120.039871, -0.39192],
                [120.070221, -0.443157],
                [120.046967, -0.490172],
                [120.054924, -0.630386],
                [120.102646, -0.729293],
                [120.218369, -0.845644],
                [120.245003, -0.892794],
                [120.31295, -0.967754],
                [120.40947, -0.924922],
                [120.458672, -0.993287],
                [120.505188, -0.999639],
                [120.591782, -1.168508],
                [120.569252, -1.203504],
                [120.570587, -1.253602],
                [120.637337, -1.313295],
                [120.6409, -1.37013],
                [120.690422, -1.420703],
                [120.729805, -1.384694],
                [120.842125, -1.368169],
                [120.858665, -1.41577],
                [120.959259, -1.414844],
                [120.978638, -1.403112],
                [121.050652, -1.441153],
                [121.117386, -1.423487],
                [121.201614, -1.279033],
                [121.21608, -1.211487],
                [121.277924, -1.157306],
                [121.300293, -1.103074],
                [121.391403, -1.007929],
                [121.446655, -0.974778],
                [121.472191, -0.905653],
                [121.537224, -0.876336],
                [121.587563, -0.869395],
                [121.606941, -0.829822],
                [121.65081, -0.806444],
                [121.667122, -0.85892],
                [121.654663, -0.911058],
                [121.768211, -0.973061],
                [121.79818, -0.96249],
                [121.949707, -0.997026],
                [122.005463, -0.941112],
                [122.101791, -0.963619],
                [122.140907, -0.926582],
                [122.151131, -0.864294],
                [122.202255, -0.790099],
                [122.312874, -0.79761],
                [122.370705, -0.778494],
                [122.417145, -0.786604],
                [122.455696, -0.763],
                [122.526512, -0.787241],
                [122.627403, -0.767298],
                [122.685989, -0.814966],
                [122.72934, -0.814772],
                [122.77961, -0.777899],
                [122.914139, -0.768896],
                [122.879929, -0.722777],
                [122.762383, -0.702591],
                [122.811676, -0.634691],
                [122.838959, -0.646788],
                [122.936707, -0.627541],
                [122.956444, -0.648756],
                [123.014275, -0.603136],
                [123.113899, -0.574524],
                [123.17347, -0.589315],
                [123.19577, -0.574752],
                [123.273453, -0.591004],
                [123.409821, -0.660818],
                [123.381783, -0.675253],
                [123.452087, -0.765581],
                [123.383072, -0.931395],
                [123.390602, -0.960441],
                [123.34938, -1.03729],
                [123.263397, -1.05125],
                [123.228004, -1.006776],
                [123.144241, -0.943434],
                [123.147484, -0.889334],
                [123.101006, -0.840044],
                [123.061485, -0.869743],
                [123.056534, -0.902925],
                [122.978981, -0.905142],
                [122.890213, -0.933499],
                [122.859497, -0.927227],
                [122.790009, -0.950041],
                [122.794479, -0.999178],
                [122.723732, -1.085598],
                [122.708473, -1.134077],
                [122.666412, -1.135592],
                [122.63237, -1.171871],
                [122.618118, -1.238488],
                [122.560532, -1.262113],
                [122.556595, -1.30897],
                [122.495201, -1.351943],
                [122.483078, -1.388393],
                [122.415749, -1.437588],
                [122.390541, -1.48866],
                [122.316772, -1.522827],
                [122.220932, -1.595521],
                [122.023979, -1.613491],
                [121.959595, -1.675115],
                [121.896263, -1.692171],
                [121.8778, -1.675358],
                [121.809525, -1.730459],
                [121.806778, -1.760933],
                [121.749054, -1.887227],
                [121.684959, -1.943017],
                [121.634857, -1.956984],
                [121.565193, -1.949181],
                [121.525887, -1.893084],
                [121.462646, -1.864683],
                [121.451309, -1.804206],
                [121.372246, -1.809819],
                [121.322395, -1.77549],
                [121.295853, -1.7989],
                [121.291077, -1.858329],
                [121.333565, -1.87836],
                [121.317474, -1.937669],
                [121.375557, -1.963591],
                [121.418983, -1.939971],
                [121.447121, -1.985441],
                [121.525383, -2.049364],
                [121.549866, -2.171898],
                [121.609505, -2.189377],
                [121.680809, -2.181712],
                [121.724426, -2.20448],
                [121.795708, -2.291534],
                [121.821411, -2.294693],
                [121.839111, -2.348679],
                [121.901405, -2.429927],
                [121.957466, -2.521174],
                [121.986511, -2.601621],
                [122.033119, -2.65964],
                [122.004349, -2.712314],
                [122.085533, -2.788894],
                [122.13887, -2.790327],
                [122.181404, -2.823496],
                [122.180618, -2.869903],
                [122.292221, -2.890996],
                [122.319016, -2.914855],
                [122.305008, -2.956492],
                [122.25071, -3.022647],
                [122.33329, -3.083526],
                [122.383438, -3.143221],
                [122.412956, -3.128048],
                [122.486099, -3.180516],
                [122.425407, -3.203624],
                [122.428352, -3.248899],
                [122.384857, -3.258627],
                [122.323967, -3.240767],
                [122.312798, -3.271106],
                [122.285965, -3.253934],
                [122.294449, -3.195179],
                [122.274086, -3.138374],
                [122.241173, -3.138603],
                [122.190437, -3.094346],
                [122.191582, -3.032609],
                [122.130539, -3.015782],
                [122.090057, -2.961801],
                [122.000359, -2.962439],
                [121.940231, -2.932159],
                [121.899376, -2.80432],
                [121.872299, -2.777285],
                [121.81871, -2.763949],
                [121.797012, -2.710249],
                [121.74305, -2.608478],
                [121.700546, -2.548773],
                [121.639519, -2.535414],
                [121.56945, -2.49626],
                [121.510712, -2.495261],
                [121.391274, -2.409437],
                [121.304352, -2.365168],
                [121.22654, -2.352717],
                [121.15377, -2.328169],
                [120.953987, -2.237536],
                [120.821678, -2.207236],
                [120.74411, -2.228615],
                [120.70327, -2.212562],
                [120.595131, -2.138367],
                [120.480461, -1.998821],
                [120.469643, -1.962179],
                [120.401123, -1.888412],
                [120.383064, -1.890121],
                [120.354927, -1.999104],
                [120.329796, -2.054484],
                [120.26606, -2.022335],
                [120.181938, -2.029071],
                [120.11956, -2.008276],
                [120.101303, -1.966918],
                [119.994949, -1.963335],
                [119.963005, -2.000149],
                [119.887428, -2.045559],
                [119.83773, -2.051188],
                [119.815765, -2.015383],
                [119.848412, -1.965073],
                [119.869141, -1.892153],
                [119.907639, -1.835855],
                [119.902878, -1.796736],
                [119.865753, -1.770845],
                [119.762688, -1.764873],
                [119.775932, -1.693306],
                [119.732208, -1.607528],
                [119.669128, -1.545653],
                [119.659355, -1.496283],
                [119.696596, -1.459508],
                [119.639478, -1.405974],
                [119.516769, -1.436377],
                [119.497852, -1.394083],
                [119.450577, -1.395969],
                [119.431, -1.336197],
                [119.505386, -1.30987],
                [119.505157, -1.277503],
                [119.570869, -1.272736],
                [119.603714, -1.166935],
                [119.567673, -1.02293],
                [119.551262, -0.861102]
              ],
              [
                [120.531853, -1.873765],
                [120.528267, -1.913569],
                [120.564026, -1.967635],
                [120.606293, -1.995662],
                [120.631844, -2.059787],
                [120.685783, -2.066975],
                [120.707199, -2.024403],
                [120.672348, -1.972287],
                [120.657585, -1.881136],
                [120.599411, -1.790897],
                [120.530083, -1.7894],
                [120.531853, -1.873765]
              ]
            ],
            [
              [
                [123.169479, -1.634564],
                [123.106308, -1.605504],
                [123.103592, -1.583008],
                [123.164406, -1.543593],
                [123.185196, -1.513129],
                [123.157669, -1.31768],
                [123.103828, -1.349413],
                [123.032669, -1.453553],
                [123.021568, -1.495429],
                [122.952698, -1.550409],
                [122.909073, -1.605003],
                [122.865974, -1.600239],
                [122.820557, -1.480622],
                [122.78537, -1.451908],
                [122.791542, -1.383733],
                [122.833527, -1.271164],
                [122.8769, -1.236489],
                [122.904968, -1.184323],
                [122.97934, -1.202721],
                [123.109581, -1.169781],
                [123.145302, -1.208792],
                [123.186867, -1.162364],
                [123.2453, -1.232014],
                [123.191849, -1.327573],
                [123.248268, -1.394754],
                [123.284081, -1.361707],
                [123.360313, -1.231369],
                [123.440392, -1.235385],
                [123.48597, -1.2709],
                [123.556793, -1.288018],
                [123.534393, -1.366252],
                [123.539093, -1.397947],
                [123.51049, -1.458997],
                [123.459213, -1.518635],
                [123.407463, -1.52446],
                [123.367729, -1.503777],
                [123.300964, -1.414419],
                [123.26075, -1.470734],
                [123.229568, -1.599808],
                [123.169479, -1.634564]
              ]
            ],
            [
              [
                [123.476669, -1.656222],
                [123.498703, -1.588354],
                [123.477654, -1.574449],
                [123.501411, -1.507485],
                [123.547562, -1.509387],
                [123.571487, -1.607135],
                [123.627731, -1.62471],
                [123.615463, -1.694854],
                [123.576256, -1.726763],
                [123.492409, -1.71136],
                [123.476669, -1.656222]
              ]
            ],
            [
              [
                [121.914711, -0.453557],
                [121.917236, -0.519645],
                [121.834412, -0.496036],
                [121.73484, -0.512026],
                [121.701439, -0.564406],
                [121.662193, -0.558711],
                [121.694901, -0.492329],
                [121.754837, -0.431368],
                [121.783325, -0.451543],
                [121.843658, -0.414951],
                [121.914711, -0.453557]
              ]
            ],
            [
              [
                [121.96521, -0.420374],
                [121.916389, -0.440287],
                [121.8722, -0.416031],
                [121.872589, -0.35888],
                [122.054825, -0.352623],
                [122.053284, -0.389249],
                [122.059982, -0.404818],
                [121.96521, -0.420374]
              ]
            ],
            [
              [
                [123.135948, -1.780573],
                [123.154221, -1.820214],
                [123.140778, -1.866415],
                [123.063591, -1.920046],
                [123.062195, -1.831136],
                [123.092239, -1.770375],
                [123.135948, -1.780573]
              ]
            ],
            [
              [
                [122.096016, -0.40342],
                [122.077263, -0.429949],
                [122.056259, -0.36745],
                [122.0662, -0.311042],
                [122.098, -0.295803],
                [122.149895, -0.39855],
                [122.096016, -0.40342]
              ]
            ],
            [
              [
                [123.86039, -1.997033],
                [123.820366, -2.012111],
                [123.789017, -1.979887],
                [123.772453, -1.903466],
                [123.80835, -1.893784],
                [123.850426, -1.945709],
                [123.86039, -1.997033]
              ]
            ],
            [
              [
                [122.362381, -0.362636],
                [122.29538, -0.268201],
                [122.306877, -0.23959],
                [122.375923, -0.284257],
                [122.362381, -0.362636]
              ]
            ],
            [
              [
                [121.620766, -0.211886],
                [121.562981, -0.181929],
                [121.601471, -0.129324],
                [121.644028, -0.13074],
                [121.649246, -0.193653],
                [121.620766, -0.211886]
              ]
            ],
            [
              [
                [123.31263, -1.720765],
                [123.360176, -1.677891],
                [123.39093, -1.708563],
                [123.314911, -1.784292],
                [123.267799, -1.787167],
                [123.31263, -1.720765]
              ]
            ],
            [
              [
                [123.325485, -1.200815],
                [123.313316, -1.265722],
                [123.274651, -1.221891],
                [123.325485, -1.200815]
              ]
            ]
          ]
        },
        "id": 72,
        "properties": {
          "ID": 20,
          "kode": 72,
          "Propinsi": "SULAWESI TENGAH",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [123.465736, 0.330308],
                [123.51918, 0.449638],
                [123.527229, 0.57175],
                [123.494171, 0.636227],
                [123.368813, 0.661965],
                [123.257477, 0.749624],
                [123.240799, 0.83596],
                [123.1465, 0.881753],
                [123.117294, 0.92862],
                [123.183815, 0.906404],
                [123.222198, 0.951997],
                [123.266968, 0.919489],
                [123.381279, 0.916597],
                [123.395981, 0.90392],
                [123.553619, 0.891023],
                [123.605904, 0.906905],
                [123.732872, 0.865901],
                [123.784668, 0.835112],
                [123.920517, 0.849172],
                [124.075935, 0.923523],
                [124.137665, 0.928344],
                [124.197304, 0.986574],
                [124.2836, 1.003263],
                [124.308136, 1.021709],
                [124.357475, 1.136346],
                [124.345238, 1.158185],
                [124.41391, 1.196132],
                [124.489716, 1.18741],
                [124.544029, 1.214211],
                [124.583717, 1.194596],
                [124.610794, 1.234678],
                [124.589989, 1.269574],
                [124.51638, 1.302175],
                [124.533218, 1.356623],
                [124.57637, 1.400202],
                [124.626411, 1.418952],
                [124.674355, 1.396016],
                [124.746124, 1.4518],
                [124.829369, 1.468621],
                [124.845001, 1.523865],
                [124.806114, 1.5772],
                [124.86174, 1.604014],
                [124.923584, 1.665811],
                [124.961983, 1.730148],
                [124.994385, 1.754779],
                [125.026825, 1.735152],
                [125.014381, 1.693482],
                [125.111847, 1.663243],
                [125.154076, 1.579512],
                [125.210403, 1.550894],
                [125.242546, 1.505849],
                [125.229156, 1.461808],
                [125.192459, 1.438576],
                [125.147789, 1.439471],
                [125.07621, 1.367611],
                [125.063248, 1.296257],
                [125.020775, 1.146533],
                [124.969742, 1.069223],
                [124.898026, 0.999992],
                [124.883057, 0.963803],
                [124.788437, 0.943151],
                [124.763794, 0.888915],
                [124.725716, 0.893974],
                [124.689034, 0.829098],
                [124.625069, 0.7633],
                [124.63192, 0.712631],
                [124.599297, 0.706245],
                [124.554062, 0.635617],
                [124.560966, 0.58909],
                [124.495369, 0.548974],
                [124.512131, 0.473545],
                [124.376762, 0.446159],
                [124.32338, 0.442996],
                [124.281578, 0.404371],
                [124.08503, 0.38917],
                [124.065895, 0.36997],
                [123.998734, 0.376837],
                [123.978569, 0.360976],
                [123.814941, 0.337797],
                [123.781235, 0.312628],
                [123.74054, 0.324472],
                [123.683281, 0.30451],
                [123.590683, 0.310139],
                [123.548042, 0.324057],
                [123.49649, 0.30875],
                [123.465736, 0.330308]
              ]
            ],
            [
              [
                [126.688919, 4.37135],
                [126.715935, 4.46499],
                [126.720451, 4.525645],
                [126.750732, 4.560368],
                [126.805336, 4.546466],
                [126.855713, 4.498095],
                [126.868073, 4.445125],
                [126.851578, 4.38552],
                [126.9142, 4.283608],
                [126.792336, 4.169112],
                [126.80732, 4.123262],
                [126.799889, 4.058125],
                [126.761673, 4.012424],
                [126.699921, 4.021832],
                [126.664978, 4.057411],
                [126.733093, 4.127583],
                [126.788681, 4.253698],
                [126.714409, 4.282143],
                [126.688919, 4.37135]
              ]
            ],
            [
              [
                [125.650261, 3.509186],
                [125.678253, 3.424114],
                [125.640015, 3.37788],
                [125.523735, 3.433616],
                [125.485748, 3.47762],
                [125.51297, 3.5587],
                [125.482903, 3.598102],
                [125.442543, 3.615681],
                [125.398689, 3.663957],
                [125.422775, 3.724515],
                [125.509193, 3.716769],
                [125.597404, 3.60578],
                [125.573135, 3.580348],
                [125.623062, 3.517487],
                [125.650261, 3.509186]
              ]
            ],
            [
              [
                [125.379097, 2.797428],
                [125.422615, 2.805127],
                [125.449631, 2.75286],
                [125.407181, 2.726557],
                [125.357231, 2.737662],
                [125.379097, 2.797428]
              ]
            ],
            [
              [
                [126.723343, 3.903965],
                [126.678856, 3.872737],
                [126.667656, 3.946121],
                [126.704918, 3.95399],
                [126.723343, 3.903965]
              ]
            ],
            [
              [
                [126.82341, 3.745728],
                [126.790398, 3.759194],
                [126.753197, 3.806978],
                [126.742836, 3.864257],
                [126.798347, 3.854081],
                [126.834442, 3.81489],
                [126.849304, 3.770062],
                [126.82341, 3.745728]
              ]
            ],
            [
              [
                [125.37558, 2.35449],
                [125.434448, 2.386659],
                [125.4674, 2.352841],
                [125.439598, 2.319543],
                [125.37558, 2.35449]
              ]
            ],
            [
              [
                [125.129044, 1.830128],
                [125.181602, 1.774388],
                [125.132607, 1.753805],
                [125.129044, 1.830128]
              ]
            ]
          ]
        },
        "id": 71,
        "properties": {
          "ID": 22,
          "kode": 71,
          "Propinsi": "SULAWESI UTARA",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [134.233337, -1.741944],
                [134.22139, -1.79278],
                [134.198441, -1.791215],
                [134.217102, -1.831198],
                [134.165833, -1.951112],
                [134.13472, -1.907501],
                [134.149719, -1.90222],
                [134.148331, -1.826945],
                [134.203339, -1.736939],
                [134.233337, -1.741944]
              ]
            ],
            [
              [
                [134.661392, -2.823219],
                [134.426788, -3.006559],
                [134.425034, -3.110369],
                [134.27594, -3.23835],
                [134.205246, -3.391239],
                [135.25766, -3.75588],
                [134.87616, -4.251943],
                [134.815277, -4.252777],
                [134.81604, -4.229375],
                [134.723328, -4.198055],
                [134.718613, -4.16],
                [134.735275, -4.171738],
                [134.713333, -4.13028],
                [134.740234, -4.122816],
                [134.656387, -4.12889],
                [134.688614, -4.134166],
                [134.69696, -4.119509],
                [134.712494, -4.133612],
                [134.687775, -4.141108],
                [134.629166, -4.118611],
                [134.65416, -4.052778],
                [134.648819, -4.099074],
                [134.656387, -4.07361],
                [134.675201, -4.088319],
                [134.72139, -4.057779],
                [134.675003, -4.04389],
                [134.661392, -3.982499],
                [134.684601, -3.985601],
                [134.686951, -3.969166],
                [134.734665, -3.96432],
                [134.739716, -3.950554],
                [134.746674, -3.97333],
                [134.765839, -3.94222],
                [134.761459, -3.970805],
                [134.815201, -3.986519],
                [134.768448, -3.96234],
                [134.76889, -3.934001],
                [134.833359, -3.95104],
                [134.830399, -3.964681],
                [134.850372, -3.991606],
                [134.865906, -3.981649],
                [134.868347, -3.999378],
                [134.870102, -3.9765],
                [134.946579, -3.982702],
                [134.963226, -3.948615],
                [134.91806, -3.965],
                [134.933716, -3.936239],
                [134.674438, -3.920278],
                [134.604172, -3.999722],
                [134.506393, -4.044443],
                [134.446457, -3.981382],
                [134.474854, -3.972669],
                [134.438339, -3.919166],
                [134.332489, -3.879719],
                [134.360718, -3.915693],
                [134.34967, -3.966287],
                [134.269501, -3.937431],
                [134.334351, -4.020581],
                [134.291107, -4.048056],
                [134.249115, -3.976414],
                [134.163391, -3.92699],
                [134.167755, -3.894139],
                [134.105377, -3.89813],
                [134.190826, -3.83283],
                [134.172089, -3.776201],
                [134.136383, -3.741941],
                [134.098358, -3.766667],
                [134.117065, -3.792155],
                [134.055283, -3.821945],
                [134.041534, -3.78193],
                [133.997223, -3.816256],
                [134.034973, -3.814554],
                [134.040817, -3.8414],
                [133.998611, -3.852777],
                [133.985596, -3.822526],
                [133.959717, -3.8625],
                [133.913696, -3.784431],
                [133.917694, -3.750667],
                [133.94194, -3.773333],
                [133.933197, -3.749494],
                [133.839478, -3.631398],
                [133.832779, -3.556],
                [133.787811, -3.597464],
                [133.774185, -3.574173],
                [133.816177, -3.727199],
                [133.751892, -3.645586],
                [133.690689, -3.671041],
                [133.707214, -3.60352],
                [133.621094, -3.47574],
                [133.636154, -3.4471],
                [133.69072, -3.410664],
                [133.701111, -3.43861],
                [133.664291, -3.31969],
                [133.676376, -3.316964],
                [133.668091, -3.22749],
                [133.701096, -3.208869],
                [133.701843, -3.17016],
                [133.764084, -3.177371],
                [133.778549, -3.213245],
                [133.771439, -3.114444],
                [133.798782, -3.119128],
                [133.827225, -3.088335],
                [133.835022, -3.018876],
                [133.896698, -3.076146],
                [133.885284, -3.114722],
                [133.891922, -3.100387],
                [133.902847, -3.120379],
                [133.922653, -3.12164],
                [133.900909, -3.09315],
                [133.921387, -3.070279],
                [133.897507, -3.068333],
                [133.914993, -3.056112],
                [133.885895, -3.071519],
                [133.850159, -2.969469],
                [133.90744, -2.932629],
                [133.85527, -2.953029],
                [133.854446, -2.924166],
                [133.79805, -2.902699],
                [133.825806, -2.942955],
                [133.793335, -3.028612],
                [133.73053, -3.030303],
                [133.648102, -3.123012],
                [133.672165, -3.209457],
                [133.648178, -3.234598],
                [133.664597, -3.340282],
                [133.634171, -3.41527],
                [133.538422, -3.428124],
                [133.514999, -3.33222],
                [133.488617, -3.307779],
                [133.513199, -3.333668],
                [133.495377, -3.375529],
                [133.518051, -3.434166],
                [133.483383, -3.441008],
                [133.441391, -3.407778],
                [133.417496, -3.414167],
                [133.442215, -3.35833],
                [133.419449, -3.38361],
                [133.430832, -3.359167],
                [133.402771, -3.32694],
                [133.417221, -3.34778],
                [133.389999, -3.336945],
                [133.428894, -3.362779],
                [133.405838, -3.394167],
                [133.427505, -3.428334],
                [133.439163, -3.41583],
                [133.487686, -3.454665],
                [133.550522, -3.454699],
                [133.596909, -3.526084],
                [133.58223, -3.571995],
                [133.409897, -3.691449],
                [133.439117, -3.59356],
                [133.428284, -3.571705],
                [133.471664, -3.538334],
                [133.459717, -3.528889],
                [133.467499, -3.515278],
                [133.421112, -3.51167],
                [133.461105, -3.542778],
                [133.44809, -3.561629],
                [133.425552, -3.544444],
                [133.443054, -3.552499],
                [133.42337, -3.571976],
                [133.432709, -3.596908],
                [133.377609, -3.592874],
                [133.330551, -3.54444],
                [133.269165, -3.605559],
                [133.246948, -3.583329],
                [133.231949, -3.594444],
                [133.269165, -3.606945],
                [133.328339, -3.554167],
                [133.368378, -3.594658],
                [133.402466, -3.595294],
                [133.394775, -3.74674],
                [133.458252, -3.85824],
                [133.400955, -3.887039],
                [133.367279, -3.873406],
                [133.352539, -3.90133],
                [133.312469, -3.841423],
                [133.272568, -3.834909],
                [133.283615, -3.783056],
                [133.236389, -3.740831],
                [133.216385, -3.754166],
                [133.236664, -3.742219],
                [133.243332, -3.758888],
                [133.260559, -3.756388],
                [133.284729, -3.8075],
                [133.26889, -3.82889],
                [133.281952, -3.848055],
                [133.233887, -3.838056],
                [133.242783, -3.850833],
                [133.297318, -3.840675],
                [133.346725, -3.905551],
                [133.402802, -3.903609],
                [133.324722, -3.983333],
                [133.289993, -3.948888],
                [133.329849, -3.989291],
                [133.25, -4.07361],
                [133.075562, -4.054166],
                [133.028061, -4.07667],
                [133.01889, -4.119169],
                [132.956924, -4.104989],
                [132.91243, -4.08514],
                [132.917496, -4.0475],
                [132.841507, -3.99035],
                [132.838837, -3.957399],
                [132.808365, -3.945114],
                [132.811829, -3.92068],
                [132.845184, -3.955489],
                [132.725403, -3.636369],
                [132.89035, -3.644141],
                [132.903168, -3.573576],
                [132.939285, -3.552016],
                [132.907013, -3.546569],
                [132.895554, -3.462307],
                [132.853043, -3.477164],
                [132.816193, -3.44364],
                [132.797775, -3.355281],
                [132.843002, -3.299095],
                [132.7733, -3.256519],
                [132.728226, -3.280229],
                [132.728088, -3.337392],
                [132.663101, -3.315729],
                [132.596161, -3.229589],
                [132.645004, -3.191944],
                [132.556488, -3.151881],
                [132.561325, -3.109818],
                [132.419998, -2.990829],
                [132.350281, -2.964999],
                [132.350555, -2.937219],
                [132.20694, -2.906389],
                [132.152771, -2.94139],
                [132.101105, -2.928889],
                [132.121384, -2.94167],
                [132.087494, -2.960001],
                [132.057785, -2.887778],
                [132.046387, -2.923334],
                [132.008331, -2.888329],
                [131.98027, -2.901945],
                [131.98172, -2.924059],
                [131.976044, -2.879119],
                [132.028885, -2.87583],
                [132.037506, -2.83806],
                [131.978149, -2.82965],
                [132.064728, -2.787222],
                [132.041107, -2.769999],
                [131.950562, -2.76694],
                [132.049728, -2.729719],
                [132.067215, -2.748055],
                [132.074173, -2.724719],
                [132.096664, -2.745555],
                [132.095276, -2.708334],
                [132.200562, -2.704166],
                [132.111115, -2.690833],
                [132.196671, -2.651666],
                [132.438614, -2.677778],
                [132.418411, -2.695058],
                [132.458328, -2.712223],
                [132.43158, -2.735032],
                [132.494446, -2.71111],
                [132.464996, -2.692221],
                [132.483719, -2.677746],
                [132.579727, -2.689167],
                [132.71611, -2.798055],
                [132.791946, -2.78972],
                [133.042374, -2.49503],
                [133.215057, -2.411247],
                [133.303406, -2.443925],
                [133.30986, -2.509443],
                [133.280548, -2.498611],
                [133.286942, -2.525001],
                [133.259171, -2.51611],
                [133.259018, -2.529652],
                [133.235275, -2.52333],
                [133.209167, -2.547222],
                [133.238327, -2.524167],
                [133.276108, -2.5425],
                [133.293335, -2.51139],
                [133.3125, -2.523888],
                [133.306396, -2.544722],
                [133.325409, -2.51521],
                [133.323822, -2.46779],
                [133.362228, -2.504723],
                [133.324997, -2.531111],
                [133.345276, -2.53889],
                [133.330276, -2.519166],
                [133.364182, -2.500979],
                [133.381012, -2.528588],
                [133.375763, -2.581443],
                [133.342773, -2.607501],
                [133.373032, -2.587],
                [133.373611, -2.618334],
                [133.358887, -2.611667],
                [133.352707, -2.625625],
                [133.373886, -2.621112],
                [133.353333, -2.653334],
                [133.376663, -2.634445],
                [133.371948, -2.671668],
                [133.291458, -2.671319],
                [133.29834, -2.644168],
                [133.264725, -2.680834],
                [133.280716, -2.690391],
                [133.229721, -2.722223],
                [133.284164, -2.70861],
                [133.31218, -2.667299],
                [133.308884, -2.701112],
                [133.319107, -2.671611],
                [133.354446, -2.689169],
                [133.38916, -2.669167],
                [133.38028, -2.72278],
                [133.390549, -2.741111],
                [133.402496, -2.73041],
                [133.410965, -2.764287],
                [133.424362, -2.760695],
                [133.381393, -2.701112],
                [133.407501, -2.693609],
                [133.408615, -2.725555],
                [133.399765, -2.667089],
                [133.435837, -2.6025],
                [133.419724, -2.541944],
                [133.401413, -2.537306],
                [133.424225, -2.50341],
                [133.449448, -2.51639],
                [133.458054, -2.616389],
                [133.427216, -2.67667],
                [133.458618, -2.711666],
                [133.475006, -2.701939],
                [133.488052, -2.727222],
                [133.477219, -2.700556],
                [133.438889, -2.686391],
                [133.438614, -2.643609],
                [133.472504, -2.638055],
                [133.457504, -2.6575],
                [133.479996, -2.68],
                [133.464554, -2.650738],
                [133.490555, -2.643329],
                [133.502502, -2.6825],
                [133.504166, -2.639167],
                [133.523056, -2.688059],
                [133.513062, -2.64639],
                [133.559174, -2.667499],
                [133.527771, -2.641389],
                [133.534927, -2.610388],
                [133.55722, -2.605279],
                [133.575272, -2.632501],
                [133.605835, -2.624439],
                [133.600006, -2.64556],
                [133.626389, -2.64306],
                [133.603607, -2.613055],
                [133.575272, -2.629444],
                [133.568832, -2.592563],
                [133.595001, -2.613609],
                [133.608612, -2.605559],
                [133.568771, -2.568179],
                [133.589981, -2.557057],
                [133.604996, -2.584723],
                [133.591934, -2.554992],
                [133.614258, -2.538354],
                [133.603928, -2.562764],
                [133.618896, -2.57028],
                [133.625549, -2.557777],
                [133.636108, -2.578059],
                [133.626938, -2.55694],
                [133.603653, -2.557449],
                [133.641525, -2.531818],
                [133.658585, -2.584484],
                [133.650558, -2.617499],
                [133.666656, -2.554531],
                [133.664993, -2.685001],
                [133.63916, -2.675278],
                [133.675827, -2.704722],
                [133.656662, -2.738891],
                [133.636948, -2.717221],
                [133.604446, -2.726668],
                [133.63916, -2.719441],
                [133.648331, -2.741325],
                [133.642502, -2.789166],
                [133.632507, -2.797779],
                [133.617996, -2.761741],
                [133.60611, -2.778333],
                [133.631104, -2.800277],
                [133.603333, -2.80778],
                [133.625, -2.809719],
                [133.627777, -2.848889],
                [133.648056, -2.868056],
                [133.625839, -2.813056],
                [133.654449, -2.803333],
                [133.649719, -2.746941],
                [133.71167, -2.7825],
                [133.666382, -2.733061],
                [133.683105, -2.713164],
                [133.694443, -2.738332],
                [133.685272, -2.70389],
                [133.709381, -2.704859],
                [133.669449, -2.67639],
                [133.69017, -2.61626],
                [133.752502, -2.624444],
                [133.731674, -2.702222],
                [133.741394, -2.664167],
                [133.758606, -2.675],
                [133.757782, -2.720833],
                [133.776382, -2.75389],
                [133.759995, -2.723333],
                [133.760559, -2.672221],
                [133.798615, -2.64861],
                [133.830276, -2.67028],
                [133.810837, -2.735],
                [133.836609, -2.66672],
                [133.852493, -2.676112],
                [133.851395, -2.709444],
                [133.92778, -2.713332],
                [133.849167, -2.703612],
                [133.85611, -2.674168],
                [133.895828, -2.67778],
                [133.845276, -2.663611],
                [133.864441, -2.63333],
                [133.88916, -2.650834],
                [133.863739, -2.605839],
                [133.843887, -2.661945],
                [133.799164, -2.638889],
                [133.807739, -2.543266],
                [133.78389, -2.487222],
                [133.821396, -2.506389],
                [133.859375, -2.49312],
                [133.871048, -2.507089],
                [133.858612, -2.49194],
                [133.792007, -2.482524],
                [133.820694, -2.4666],
                [133.821396, -2.445279],
                [133.853333, -2.473055],
                [133.871948, -2.455829],
                [133.903061, -2.482219],
                [133.964447, -2.469166],
                [133.972229, -2.483334],
                [133.947815, -2.500559],
                [133.974762, -2.548512],
                [134.01416, -2.579723],
                [133.949799, -2.50006],
                [133.974716, -2.485556],
                [133.986389, -2.446945],
                [133.948059, -2.420309],
                [133.988327, -2.389445],
                [133.998337, -2.343609],
                [134.031387, -2.338055],
                [134.020203, -2.316109],
                [134.02861, -2.343611],
                [133.996674, -2.34194],
                [133.986664, -2.363332],
                [133.958054, -2.348612],
                [133.949173, -2.32528],
                [133.991394, -2.299722],
                [134.000488, -2.241946],
                [133.98555, -2.225],
                [133.94278, -2.231945],
                [133.963058, -2.181945],
                [133.937225, -2.195609],
                [133.959717, -2.147501],
                [134.003326, -2.16528],
                [134.011322, -2.155835],
                [133.986115, -2.141666],
                [133.937225, -2.160277],
                [133.969162, -2.108334],
                [133.939438, -2.127777],
                [133.968887, -2.093059],
                [133.96611, -2.076109],
                [133.935623, -2.126735],
                [133.901642, -2.135758],
                [133.926941, -2.112222],
                [133.925827, -2.09083],
                [133.947784, -2.08139],
                [133.949158, -2.04542],
                [133.923889, -2.088888],
                [133.934723, -2.051667],
                [133.91861, -2.054719],
                [133.904999, -2.104167],
                [133.888611, -2.080556],
                [133.907227, -2.0575],
                [133.886948, -2.08028],
                [133.893326, -2.097223],
                [133.878616, -2.079719],
                [133.875275, -2.108609],
                [133.83667, -2.094445],
                [133.841949, -2.066666],
                [133.824402, -2.130756],
                [133.781387, -2.151111],
                [133.814728, -2.098333],
                [133.781311, -2.112055],
                [133.761322, -2.088129],
                [133.776947, -2.125065],
                [133.734161, -2.099167],
                [133.734161, -2.113056],
                [133.77594, -2.12671],
                [133.769608, -2.16131],
                [133.754166, -2.163334],
                [133.764999, -2.141388],
                [133.752716, -2.169155],
                [133.717285, -2.156594],
                [133.736389, -2.145281],
                [133.732498, -2.122779],
                [133.696869, -2.195697],
                [133.660599, -2.20266],
                [133.643616, -2.193612],
                [133.679443, -2.17111],
                [133.648331, -2.175554],
                [133.679443, -2.153889],
                [133.665833, -2.15667],
                [133.672745, -2.140027],
                [133.639725, -2.193056],
                [133.657349, -2.209099],
                [133.620285, -2.19361],
                [133.623215, -2.232686],
                [133.61055, -2.213609],
                [133.580276, -2.22361],
                [133.575562, -2.190556],
                [133.657776, -2.156389],
                [133.668884, -2.131109],
                [133.640274, -2.158055],
                [133.626938, -2.149167],
                [133.646393, -2.139444],
                [133.642502, -2.127499],
                [133.615005, -2.178889],
                [133.581665, -2.151667],
                [133.623062, -2.136109],
                [133.579315, -2.143179],
                [133.57135, -2.174381],
                [133.524567, -2.111629],
                [133.499939, -2.124657],
                [133.454163, -2.094167],
                [133.500076, -2.12599],
                [133.525162, -2.113699],
                [133.568054, -2.175279],
                [133.58017, -2.160141],
                [133.555054, -2.223849],
                [133.508606, -2.230172],
                [133.497498, -2.193612],
                [133.529022, -2.204816],
                [133.543091, -2.172551],
                [133.523605, -2.164444],
                [133.540558, -2.17865],
                [133.527573, -2.203779],
                [133.479172, -2.187219],
                [133.478729, -2.172865],
                [133.500473, -2.186073],
                [133.511093, -2.164902],
                [133.488647, -2.158529],
                [133.488831, -2.131351],
                [133.475006, -2.123889],
                [133.460007, -2.14],
                [133.476395, -2.125277],
                [133.487411, -2.159286],
                [133.508896, -2.164444],
                [133.500427, -2.184388],
                [133.474167, -2.174723],
                [133.487305, -2.23156],
                [133.45166, -2.199166],
                [133.454727, -2.227779],
                [133.415833, -2.224722],
                [133.430252, -2.215015],
                [133.40921, -2.200785],
                [133.420273, -2.2],
                [133.426117, -2.157499],
                [133.419235, -2.199747],
                [133.407776, -2.200238],
                [133.428345, -2.216499],
                [133.382889, -2.211253],
                [133.372223, -2.202501],
                [133.385483, -2.192149],
                [133.325668, -2.218295],
                [133.277267, -2.201835],
                [133.317551, -2.168719],
                [133.298935, -2.193211],
                [133.262772, -2.177779],
                [133.274445, -2.157777],
                [133.261108, -2.17694],
                [133.282623, -2.190207],
                [133.263107, -2.20194],
                [133.158218, -2.197325],
                [132.960007, -2.293055],
                [132.944443, -2.262499],
                [132.897217, -2.28111],
                [132.825424, -2.2425],
                [132.714722, -2.29778],
                [132.64917, -2.289999],
                [132.574524, -2.189304],
                [132.315552, -2.283611],
                [132.19722, -2.15194],
                [132.141388, -2.148611],
                [132.134171, -2.083329],
                [132.107498, -2.089444],
                [132.134171, -2.14028],
                [132.038605, -2.070001],
                [132.115204, -1.99272],
                [132.136383, -2.023611],
                [132.231949, -1.962309],
                [132.238815, -1.934907],
                [132.245895, -1.945861],
                [132.272705, -1.934654],
                [132.238266, -1.934189],
                [132.181229, -1.9905],
                [132.113312, -1.985411],
                [132.085007, -2.021667],
                [132.102493, -1.970555],
                [132.074722, -1.949722],
                [132.095276, -1.901666],
                [132.08194, -1.89306],
                [132.086563, -1.870383],
                [132.133606, -1.89722],
                [132.163971, -1.881699],
                [132.170273, -1.8993],
                [132.202835, -1.869528],
                [132.223038, -1.884167],
                [132.259964, -1.85276],
                [132.257721, -1.83868],
                [132.291748, -1.84095],
                [132.288437, -1.817299],
                [132.310471, -1.826728],
                [132.323944, -1.813297],
                [132.324417, -1.827086],
                [132.339996, -1.81417],
                [132.325989, -1.82558],
                [132.324722, -1.811939],
                [132.288116, -1.816461],
                [132.292892, -1.839029],
                [132.257645, -1.837499],
                [132.222351, -1.88199],
                [132.204422, -1.867981],
                [132.169983, -1.898204],
                [132.163239, -1.879998],
                [132.134171, -1.895834],
                [132.08667, -1.868329],
                [132.054184, -1.966268],
                [132.061111, -2.017501],
                [132.04306, -2.026943],
                [132.020554, -2.004444],
                [132.03334, -1.974165],
                [132.01001, -2.006187],
                [131.967209, -1.965001],
                [131.997498, -1.910831],
                [132.033707, -1.930064],
                [132.002701, -1.908547],
                [131.987778, -1.929722],
                [131.973328, -1.913889],
                [131.984726, -1.875556],
                [131.97084, -1.8475],
                [131.986389, -1.861667],
                [131.988617, -1.84611],
                [132.008865, -1.860058],
                [132.030762, -1.836219],
                [132.035492, -1.853579],
                [132.041595, -1.8419],
                [132.073334, -1.84528],
                [132.032043, -1.834575],
                [132.009308, -1.858568],
                [131.99028, -1.844439],
                [131.984161, -1.859999],
                [131.973618, -1.84361],
                [131.950836, -1.86278],
                [131.958893, -1.826667],
                [132.017746, -1.814355],
                [132.014908, -1.80432],
                [131.958054, -1.82444],
                [131.947922, -1.844259],
                [131.920944, -1.813186],
                [131.995148, -1.73766],
                [132.008072, -1.771559],
                [132.038574, -1.791144],
                [132.009491, -1.76774],
                [132.026947, -1.71501],
                [132.067825, -1.765203],
                [132.08667, -1.754163],
                [132.074203, -1.769335],
                [132.089127, -1.769547],
                [132.091644, -1.789774],
                [132.08812, -1.752583],
                [132.039963, -1.729309],
                [132.089722, -1.702777],
                [132.063065, -1.696742],
                [132.054718, -1.722499],
                [132.004608, -1.684923],
                [132.078308, -1.670291],
                [132.074951, -1.650911],
                [132.096176, -1.649316],
                [132.076401, -1.647827],
                [132.071838, -1.666731],
                [132.068665, -1.631956],
                [132.121613, -1.61576],
                [132.137741, -1.599489],
                [132.124725, -1.582779],
                [132.148895, -1.574444],
                [132.122223, -1.582501],
                [132.133224, -1.603972],
                [132.101486, -1.604793],
                [132.049942, -1.644265],
                [132.073654, -1.616485],
                [132.069809, -1.597887],
                [132.106369, -1.56858],
                [132.07605, -1.51959],
                [132.071335, -1.551363],
                [132.096527, -1.54285],
                [132.103378, -1.568281],
                [132.047653, -1.627985],
                [132.053116, -1.662997],
                [132.015976, -1.658186],
                [132.014526, -1.641959],
                [132.038483, -1.645047],
                [132.038208, -1.631901],
                [132.014725, -1.640616],
                [132.026917, -1.667155],
                [131.91156, -1.719559],
                [131.900589, -1.673671],
                [131.94194, -1.676668],
                [131.942215, -1.65972],
                [131.961304, -1.6558],
                [131.942215, -1.657778],
                [131.942215, -1.675277],
                [131.896179, -1.672173],
                [131.880676, -1.638016],
                [131.918213, -1.58029],
                [131.948059, -1.593611],
                [131.936859, -1.573067],
                [132.001724, -1.554855],
                [131.962372, -1.479219],
                [132.01062, -1.448485],
                [131.981949, -1.46639],
                [131.986115, -1.41694],
                [131.958328, -1.425833],
                [131.974655, -1.447619],
                [131.957504, -1.482499],
                [131.969452, -1.549171],
                [131.933334, -1.542499],
                [131.953888, -1.555557],
                [131.869995, -1.583611],
                [131.887161, -1.550018],
                [131.871765, -1.527441],
                [131.920837, -1.524722],
                [131.876389, -1.508055],
                [131.864166, -1.527222],
                [131.882294, -1.553396],
                [131.809998, -1.614445],
                [131.779449, -1.596668],
                [131.807785, -1.545834],
                [131.794724, -1.49111],
                [131.824173, -1.507221],
                [131.834167, -1.498888],
                [131.818893, -1.450279],
                [131.796921, -1.447015],
                [131.82309, -1.44144],
                [131.821487, -1.429119],
                [131.796234, -1.440886],
                [131.81778, -1.45083],
                [131.821396, -1.474166],
                [131.790558, -1.491666],
                [131.79834, -1.548612],
                [131.726898, -1.583829],
                [131.694778, -1.555593],
                [131.741592, -1.50602],
                [131.744675, -1.453821],
                [131.712875, -1.414859],
                [131.686661, -1.43801],
                [131.653366, -1.40664],
                [131.679443, -1.410834],
                [131.733887, -1.366944],
                [131.72139, -1.352499],
                [131.734726, -1.311388],
                [131.717773, -1.289999],
                [131.734161, -1.315001],
                [131.720001, -1.352221],
                [131.733337, -1.36444],
                [131.65152, -1.402579],
                [131.669174, -1.465],
                [131.611389, -1.411666],
                [131.63916, -1.372223],
                [131.633331, -1.360278],
                [131.609726, -1.410556],
                [131.641296, -1.448009],
                [131.577408, -1.454696],
                [131.561386, -1.433055],
                [131.575882, -1.470919],
                [131.524445, -1.479721],
                [131.539444, -1.425566],
                [131.526382, -1.396389],
                [131.556671, -1.381668],
                [131.537506, -1.353055],
                [131.588608, -1.342501],
                [131.588882, -1.275],
                [131.569443, -1.241109],
                [131.585007, -1.345559],
                [131.536179, -1.348839],
                [131.550278, -1.385278],
                [131.514236, -1.376528],
                [131.515411, -1.47501],
                [131.475006, -1.456667],
                [131.500702, -1.468124],
                [131.464996, -1.488329],
                [131.44722, -1.44111],
                [131.450562, -1.472781],
                [131.460556, -1.465278],
                [131.460831, -1.486667],
                [131.478943, -1.487039],
                [131.449127, -1.5144],
                [131.379562, -1.451961],
                [131.426468, -1.425751],
                [131.430557, -1.403055],
                [131.452225, -1.399999],
                [131.432495, -1.377499],
                [131.422012, -1.425539],
                [131.389603, -1.41061],
                [131.412018, -1.358509],
                [131.435272, -1.347779],
                [131.420273, -1.34194],
                [131.380554, -1.401945],
                [131.361511, -1.36567],
                [131.336395, -1.366391],
                [131.354721, -1.321389],
                [131.33667, -1.324439],
                [131.348892, -1.28611],
                [131.324722, -1.351109],
                [131.296951, -1.35528],
                [131.301666, -1.327499],
                [131.279449, -1.31472],
                [131.301117, -1.32889],
                [131.29361, -1.35389],
                [131.313614, -1.373333],
                [131.258667, -1.371336],
                [131.272217, -1.36833],
                [131.29277, -1.4125],
                [131.337219, -1.438056],
                [131.283615, -1.504167],
                [131.208664, -1.523113],
                [131.166672, -1.462778],
                [130.996048, -1.459965],
                [130.931381, -1.426291],
                [130.999207, -1.38162],
                [131.051117, -1.254166],
                [131.176544, -1.220246],
                [131.248886, -1.082777],
                [131.240097, -1.028044],
                [131.283783, -0.990725],
                [131.265518, -0.992109],
                [131.278793, -0.974999],
                [131.267975, -0.921084],
                [131.300446, -0.917804],
                [131.241806, -0.876727],
                [131.225891, -0.821073],
                [131.508682, -0.729509],
                [131.54393, -0.740925],
                [131.507767, -0.774713],
                [131.847595, -0.705874],
                [131.977356, -0.577887],
                [132.070786, -0.539999],
                [132.094193, -0.479821],
                [132.170563, -0.425557],
                [132.419174, -0.34333],
                [132.719208, -0.362848],
                [132.798798, -0.416224],
                [132.766495, -0.493181],
                [132.798859, -0.416281],
                [132.904938, -0.463862],
                [132.953613, -0.452833],
                [132.972412, -0.49383],
                [133.151016, -0.54533],
                [133.3965, -0.740975],
                [133.547165, -0.746956],
                [133.572876, -0.720641],
                [133.721848, -0.751336],
                [133.756668, -0.719219],
                [133.81662, -0.749301],
                [133.872284, -0.714018],
                [133.972809, -0.719501],
                [134.014206, -0.781619],
                [134.136871, -0.863292],
                [134.052551, -0.868969],
                [134.046051, -0.926905],
                [134.010406, -0.959727],
                [134.070816, -1.030581],
                [134.102615, -1.128109],
                [134.190002, -1.20111],
                [134.278992, -1.343317],
                [134.213638, -1.407771],
                [134.200836, -1.55],
                [134.067322, -1.673561],
                [134.095322, -1.684829],
                [134.102814, -1.860669],
                [134.121948, -1.86278],
                [134.13591, -1.960028],
                [134.156525, -1.966227],
                [134.102783, -2.051111],
                [134.172775, -2.347221],
                [134.213882, -2.418611],
                [134.259171, -2.429439],
                [134.279099, -2.481319],
                [134.320007, -2.507321],
                [134.286942, -2.518334],
                [134.339722, -2.551667],
                [134.318893, -2.584999],
                [134.375549, -2.644443],
                [134.367218, -2.668889],
                [134.461395, -2.863056],
                [134.514725, -2.831111],
                [134.524231, -2.79222],
                [134.460281, -2.550001],
                [134.513885, -2.483609],
                [134.569168, -2.484439],
                [134.512833, -2.473574],
                [134.551117, -2.471945],
                [134.531952, -2.449719],
                [134.601669, -2.488334],
                [134.630829, -2.474167],
                [134.661575, -2.611106],
                [134.661392, -2.823219]
              ]
            ],
            [
              [
                [134.348053, -2.165],
                [134.315552, -2.078889],
                [134.388184, -1.99321],
                [134.411667, -2.073333],
                [134.348053, -2.165]
              ]
            ],
            [
              [
                [134.737503, -2.016111],
                [134.738892, -2.053055],
                [134.723892, -2.025829],
                [134.737503, -2.016111]
              ]
            ],
            [
              [
                [134.575348, -2.337501],
                [134.590775, -2.395468],
                [134.562027, -2.403001],
                [134.564194, -2.44134],
                [134.502853, -2.360739],
                [134.562027, -2.36854],
                [134.563309, -2.280778],
                [134.575348, -2.337501]
              ]
            ],
            [
              [
                [134.521225, -2.34373],
                [134.530029, -2.351376],
                [134.501572, -2.342704],
                [134.521225, -2.34373]
              ]
            ],
            [
              [
                [134.377228, -2.521666],
                [134.39389, -2.53639],
                [134.387772, -2.57611],
                [134.36972, -2.549444],
                [134.377228, -2.521666]
              ]
            ],
            [
              [
                [132.471497, -2.624542],
                [132.50528, -2.633611],
                [132.508774, -2.653585],
                [132.460342, -2.668185],
                [132.441116, -2.641111],
                [132.471497, -2.624542]
              ]
            ],
            [
              [
                [132.556946, -2.641109],
                [132.574997, -2.651666],
                [132.536392, -2.646939],
                [132.556946, -2.641109]
              ]
            ],
            [
              [
                [132.286942, -2.985559],
                [132.169586, -2.968609],
                [132.249451, -2.967777],
                [132.321152, -2.992014],
                [132.286942, -2.985559]
              ]
            ],
            [
              [
                [132.132507, -2.965555],
                [132.143616, -2.970556],
                [132.110001, -2.970834],
                [132.132507, -2.965555]
              ]
            ],
            [
              [
                [132.442215, -3.098333],
                [132.547226, -3.135],
                [132.545273, -3.150277],
                [132.472504, -3.13028],
                [132.442215, -3.098333]
              ]
            ],
            [
              [
                [132.745026, -3.42548],
                [132.743271, -3.392599],
                [132.75528, -3.414509],
                [132.745026, -3.42548]
              ]
            ],
            [
              [
                [132.708725, -3.46011],
                [132.724411, -3.495833],
                [132.699402, -3.511349],
                [132.681183, -3.450738],
                [132.629837, -3.405946],
                [132.663986, -3.403756],
                [132.708725, -3.46011]
              ]
            ],
            [
              [
                [132.752228, -3.443889],
                [132.747772, -3.475833],
                [132.740555, -3.441667],
                [132.752228, -3.443889]
              ]
            ],
            [
              [
                [133.776947, -3.05389],
                [133.792496, -3.109722],
                [133.75972, -3.076112],
                [133.776947, -3.05389]
              ]
            ],
            [
              [
                [133.613937, -3.420899],
                [133.628311, -3.428546],
                [133.616882, -3.452399],
                [133.596985, -3.431069],
                [133.613937, -3.420899]
              ]
            ],
            [
              [
                [133.912811, -3.85633],
                [133.883392, -3.796141],
                [133.872772, -3.715555],
                [133.925018, -3.874051],
                [133.912811, -3.85633]
              ]
            ],
            [
              [
                [134.035431, -3.848358],
                [134.023331, -3.868332],
                [134.005981, -3.857598],
                [134.035431, -3.848358]
              ]
            ],
            [
              [
                [134.051834, -3.924399],
                [134.098984, -3.915533],
                [134.1772, -4.00864],
                [134.211349, -4.02673],
                [134.083618, -3.983841],
                [134.051834, -3.924399]
              ]
            ],
            [
              [
                [134.683655, -3.936996],
                [134.738419, -3.953898],
                [134.69278, -3.96389],
                [134.683655, -3.936996]
              ]
            ],
            [
              [
                [134.842865, -3.952499],
                [134.865402, -3.970419],
                [134.86055, -3.984723],
                [134.83139, -3.963612],
                [134.842865, -3.952499]
              ]
            ],
            [
              [
                [134.413757, -4.029144],
                [134.362503, -4.032501],
                [134.376709, -3.977389],
                [134.452011, -4.0193],
                [134.413757, -4.029144]
              ]
            ],
            [
              [
                [134.229965, -4.015519],
                [134.228058, -4.002801],
                [134.246048, -4.005008],
                [134.262711, -4.046109],
                [134.229965, -4.015519]
              ]
            ],
            [
              [
                [134.354019, -4.023539],
                [134.36055, -4.033611],
                [134.339447, -4.027222],
                [134.354019, -4.023539]
              ]
            ],
            [
              [
                [133.507156, -4.199229],
                [133.555069, -4.21874],
                [133.63562, -4.295962],
                [133.63472, -4.314309],
                [133.548859, -4.234501],
                [133.460098, -4.251956],
                [133.417084, -4.205079],
                [133.331375, -4.182169],
                [133.347946, -4.163886],
                [133.318481, -4.098261],
                [133.507156, -4.199229]
              ]
            ],
            [
              [
                [131.26123, -0.938987],
                [131.246231, -0.947331],
                [131.25647, -0.924368],
                [131.26123, -0.938987]
              ]
            ],
            [
              [
                [134.098724, -0.888909],
                [134.110764, -0.92172],
                [134.089935, -0.896301],
                [134.098724, -0.888909]
              ]
            ],
            [
              [
                [131.055069, 0.378053],
                [131.066177, 0.36676],
                [131.049698, 0.361611],
                [131.032379, 0.387192],
                [131.055069, 0.378053]
              ]
            ],
            [
              [
                [129.860672, 0.324739],
                [129.907227, 0.318011],
                [129.925873, 0.281691],
                [129.882324, 0.277128],
                [129.860672, 0.324739]
              ]
            ],
            [
              [
                [130.037506, 0.186941],
                [130.073059, 0.158611],
                [129.997498, 0.180001],
                [130.037506, 0.186941]
              ]
            ],
            [
              [
                [130.076111, 0.149722],
                [130.080765, 0.12944],
                [130.063034, 0.138165],
                [130.076111, 0.149722]
              ]
            ],
            [
              [
                [130.225571, 0.105692],
                [130.230438, 0.093781],
                [130.212143, 0.110941],
                [130.225571, 0.105692]
              ]
            ],
            [
              [
                [130.249466, 0.109571],
                [130.276596, 0.099476],
                [130.249146, 0.098547],
                [130.249466, 0.109571]
              ]
            ],
            [
              [
                [130.872772, 0.0425],
                [130.894058, 0.04013],
                [130.904861, 0.018996],
                [130.880386, 0.01578],
                [130.872772, 0.0425]
              ]
            ],
            [
              [
                [130.104645, -0.06848],
                [130.088333, 0.002499],
                [130.141388, 0.015],
                [130.110275, 0.00389],
                [130.142288, -0.017031],
                [130.116226, -0.035371],
                [130.155273, -0.03592],
                [130.16571, -0.066144],
                [130.131912, -0.097147],
                [130.104645, -0.06848]
              ]
            ],
            [
              [
                [130.429993, -0.201666],
                [130.451111, -0.197221],
                [130.42778, -0.17611],
                [130.376663, -0.182222],
                [130.424438, -0.166111],
                [130.425278, -0.12944],
                [130.38916, -0.171944],
                [130.371948, -0.137777],
                [130.366394, -0.160279],
                [130.335556, -0.141667],
                [130.373062, -0.1825],
                [130.353561, -0.192139],
                [130.301117, -0.172778],
                [130.281281, -0.111369],
                [130.29744, -0.092657],
                [130.345276, -0.109721],
                [130.352783, -0.078889],
                [130.362503, -0.134999],
                [130.368683, -0.07578],
                [130.404984, -0.076803],
                [130.414169, -0.113889],
                [130.437775, -0.073888],
                [130.446945, -0.100281],
                [130.525864, -0.068019],
                [130.512131, -0.049782],
                [130.578049, -0.075],
                [130.569473, -0.039024],
                [130.633896, -0.062499],
                [130.726669, -0.027221],
                [130.708328, -0.061939],
                [130.769775, -0.05362],
                [130.739716, -0.025279],
                [130.797775, -0.004999],
                [130.93837, -0.04075],
                [130.956085, -0.03378],
                [130.967407, -0.061795],
                [130.977158, -0.03512],
                [131.048615, -0.020278],
                [131.033615, -0.060277],
                [131.133499, -0.070622],
                [131.194, -0.130536],
                [131.299164, -0.159734],
                [131.318298, -0.198056],
                [131.295715, -0.238772],
                [131.340775, -0.290732],
                [131.276535, -0.34576],
                [131.245956, -0.34062],
                [131.241455, -0.381259],
                [131.103653, -0.307919],
                [131.023697, -0.357139],
                [131.007202, -0.33551],
                [130.959106, -0.351223],
                [130.919434, -0.30087],
                [130.89505, -0.299295],
                [130.868683, -0.235299],
                [130.799652, -0.241049],
                [130.789993, -0.168889],
                [130.679993, -0.098451],
                [130.698608, -0.07833],
                [130.621017, -0.080327],
                [130.620605, -0.104208],
                [130.666092, -0.116912],
                [130.622772, -0.120556],
                [130.62056, -0.145353],
                [130.675797, -0.173181],
                [130.731461, -0.294659],
                [130.815231, -0.311892],
                [130.826172, -0.2881],
                [130.85527, -0.310556],
                [130.919556, -0.305492],
                [130.944687, -0.354012],
                [130.879807, -0.420643],
                [130.727036, -0.439642],
                [130.672501, -0.339166],
                [130.705002, -0.324721],
                [130.679718, -0.29222],
                [130.578339, -0.321111],
                [130.560013, -0.367291],
                [130.59137, -0.378951],
                [130.552979, -0.43076],
                [130.527466, -0.246599],
                [130.436951, -0.290281],
                [130.429306, -0.2614],
                [130.317703, -0.273057],
                [130.291382, -0.22889],
                [130.21817, -0.208118],
                [130.331116, -0.218609],
                [130.358063, -0.200556],
                [130.395004, -0.23],
                [130.429993, -0.201666]
              ]
            ],
            [
              [
                [130.94278, -0.01583],
                [130.956345, -0.022414],
                [130.947495, -0.033333],
                [130.94278, -0.01583]
              ]
            ],
            [
              [
                [131.081421, -0.038067],
                [131.08017, -0.049059],
                [131.064194, -0.040531],
                [131.081421, -0.038067]
              ]
            ],
            [
              [
                [130.064453, -0.064405],
                [130.064148, -0.076769],
                [130.013748, -0.07078],
                [130.064453, -0.064405]
              ]
            ],
            [
              [
                [130.269058, -0.106489],
                [130.261581, -0.122509],
                [130.250992, -0.109274],
                [130.269058, -0.106489]
              ]
            ],
            [
              [
                [130.256943, -0.148329],
                [130.297226, -0.191945],
                [130.256607, -0.188025],
                [130.256943, -0.148329]
              ]
            ],
            [
              [
                [130.239166, -0.165662],
                [130.250275, -0.180278],
                [130.204163, -0.181388],
                [130.239166, -0.165662]
              ]
            ],
            [
              [
                [130.223724, -0.277919],
                [130.242355, -0.302928],
                [130.230957, -0.32022],
                [130.200058, -0.305715],
                [130.223724, -0.277919]
              ]
            ],
            [
              [
                [130.870697, -0.293889],
                [130.900497, -0.308881],
                [130.852783, -0.3075],
                [130.870697, -0.293889]
              ]
            ],
            [
              [
                [130.499634, -0.302474],
                [130.520157, -0.303769],
                [130.525558, -0.321667],
                [130.498611, -0.332778],
                [130.499634, -0.302474]
              ]
            ],
            [
              [
                [130.668701, -0.307126],
                [130.680466, -0.320065],
                [130.669388, -0.332299],
                [130.65799, -0.322116],
                [130.668701, -0.307126]
              ]
            ],
            [
              [
                [130.14856, -0.309423],
                [130.149689, -0.330528],
                [130.134171, -0.314743],
                [130.14856, -0.309423]
              ]
            ],
            [
              [
                [130.184723, -0.317186],
                [130.225327, -0.326553],
                [130.229416, -0.345949],
                [130.184723, -0.317186]
              ]
            ],
            [
              [
                [130.524445, -0.46778],
                [130.614716, -0.395278],
                [130.69722, -0.44778],
                [130.660553, -0.510001],
                [130.592773, -0.534445],
                [130.571198, -0.517119],
                [130.614716, -0.486667],
                [130.577225, -0.463055],
                [130.544998, -0.511945],
                [130.456711, -0.509812],
                [130.513062, -0.487499],
                [130.468613, -0.483611],
                [130.477219, -0.43889],
                [130.524445, -0.46778]
              ]
            ],
            [
              [
                [129.854721, -0.42],
                [129.890366, -0.397428],
                [129.924683, -0.412727],
                [129.874451, -0.498334],
                [129.854721, -0.42]
              ]
            ],
            [
              [
                [130.454437, -0.430277],
                [130.454727, -0.446939],
                [130.4375, -0.445],
                [130.454437, -0.430277]
              ]
            ],
            [
              [
                [130.26973, -0.561667],
                [130.27861, -0.585832],
                [130.250549, -0.545555],
                [130.26973, -0.561667]
              ]
            ],
            [
              [
                [130.650284, -0.568055],
                [130.660553, -0.57278],
                [130.556107, -0.60778],
                [130.650284, -0.568055]
              ]
            ],
            [
              [
                [130.279999, -0.593889],
                [130.290558, -0.594723],
                [130.281387, -0.607438],
                [130.279999, -0.593889]
              ]
            ],
            [
              [
                [130.22084, -0.740556],
                [130.21611, -0.727777],
                [130.236664, -0.72194],
                [130.22084, -0.740556]
              ]
            ],
            [
              [
                [130.889587, -0.772389],
                [130.927017, -0.784391],
                [130.863983, -0.805019],
                [130.900223, -0.832679],
                [130.850052, -0.860466],
                [130.62883, -0.90996],
                [130.508804, -0.920535],
                [130.523148, -0.897598],
                [130.471329, -0.89566],
                [130.47171, -0.917752],
                [130.454559, -0.898081],
                [130.387772, -0.92278],
                [130.4505, -0.893901],
                [130.433334, -0.861776],
                [130.484329, -0.789238],
                [130.50618, -0.797555],
                [130.486481, -0.829595],
                [130.544174, -0.839999],
                [130.566559, -0.793749],
                [130.585312, -0.833034],
                [130.635941, -0.790829],
                [130.663498, -0.82606],
                [130.688599, -0.82039],
                [130.671143, -0.797819],
                [130.717178, -0.829693],
                [130.797607, -0.793161],
                [130.786148, -0.813076],
                [130.809723, -0.802286],
                [130.815277, -0.825834],
                [130.804077, -0.751899],
                [130.861191, -0.800967],
                [130.850967, -0.766],
                [130.889587, -0.772389]
              ]
            ],
            [
              [
                [130.783142, -0.756945],
                [130.792221, -0.781667],
                [130.773056, -0.801391],
                [130.7603, -0.77286],
                [130.783142, -0.756945]
              ]
            ],
            [
              [
                [130.748337, -0.7803],
                [130.732666, -0.799159],
                [130.717148, -0.77464],
                [130.749695, -0.766659],
                [130.748337, -0.7803]
              ]
            ],
            [
              [
                [130.526215, -0.798327],
                [130.552475, -0.795921],
                [130.538895, -0.827778],
                [130.511948, -0.8175],
                [130.526215, -0.798327]
              ]
            ],
            [
              [
                [130.640656, -0.970106],
                [130.90155, -0.888422],
                [130.927383, -0.893019],
                [130.936493, -0.939985],
                [131.03035, -0.911651],
                [131.051208, -0.931365],
                [131.087753, -1.012213],
                [131.04805, -1.185559],
                [131.033691, -1.19709],
                [131.044449, -1.24167],
                [131.000839, -1.281944],
                [130.973907, -1.35391],
                [130.907471, -1.322],
                [130.858017, -1.345639],
                [130.810043, -1.26672],
                [130.755585, -1.248669],
                [130.737045, -1.165127],
                [130.680222, -1.108429],
                [130.717178, -1.078529],
                [130.640656, -0.970106]
              ]
            ],
            [
              [
                [131.104111, -0.943588],
                [131.096207, -0.95061],
                [131.114868, -0.935128],
                [131.104111, -0.943588]
              ]
            ],
            [
              [
                [130.647385, -0.937229],
                [130.661636, -0.94984],
                [130.636673, -0.944445],
                [130.647385, -0.937229]
              ]
            ],
            [
              [
                [131.079468, -1.07547],
                [131.096985, -1.00298],
                [131.145508, -0.997522],
                [131.103058, -1.066944],
                [131.114166, -1.078333],
                [131.079468, -1.07547]
              ]
            ],
            [
              [
                [131.162384, -1.037279],
                [131.170502, -1.061511],
                [131.157288, -1.078201],
                [131.162384, -1.037279]
              ]
            ],
            [
              [
                [131.126389, -1.050277],
                [131.137222, -1.06139],
                [131.121948, -1.076669],
                [131.110001, -1.055833],
                [131.126389, -1.050277]
              ]
            ],
            [
              [
                [131.172394, -1.071803],
                [131.161728, -1.082853],
                [131.174484, -1.060615],
                [131.172394, -1.071803]
              ]
            ],
            [
              [
                [129.765549, -1.170556],
                [129.866943, -1.143888],
                [129.978333, -1.171944],
                [129.832779, -1.25111],
                [129.887573, -1.197153],
                [129.742218, -1.219721],
                [129.732498, -1.193334],
                [129.765549, -1.170556]
              ]
            ],
            [
              [
                [129.771118, -1.14694],
                [129.782227, -1.153054],
                [129.763062, -1.159999],
                [129.771118, -1.14694]
              ]
            ],
            [
              [
                [129.746948, -1.156389],
                [129.715836, -1.199166],
                [129.67305, -1.198056],
                [129.703568, -1.203722],
                [129.672913, -1.17882],
                [129.746948, -1.156389]
              ]
            ],
            [
              [
                [129.334717, -1.154445],
                [129.353058, -1.161667],
                [129.300552, -1.178057],
                [129.334717, -1.154445]
              ]
            ],
            [
              [
                [129.357483, -1.161041],
                [129.373672, -1.169423],
                [129.372223, -1.187777],
                [129.345276, -1.182499],
                [129.357483, -1.161041]
              ]
            ],
            [
              [
                [129.390549, -1.162219],
                [129.396942, -1.19639],
                [129.383606, -1.1975],
                [129.37645, -1.175764],
                [129.390549, -1.162219]
              ]
            ],
            [
              [
                [129.463608, -1.167778],
                [129.475555, -1.186389],
                [129.446945, -1.194168],
                [129.44278, -1.171939],
                [129.463608, -1.167778]
              ]
            ],
            [
              [
                [129.660828, -1.25944],
                [129.663605, -1.275834],
                [129.651382, -1.27167],
                [129.660828, -1.25944]
              ]
            ],
            [
              [
                [129.755554, -1.265277],
                [129.769165, -1.27167],
                [129.751663, -1.276666],
                [129.755554, -1.265277]
              ]
            ],
            [
              [
                [129.719727, -1.269999],
                [129.714722, -1.279444],
                [129.730835, -1.278888],
                [129.719727, -1.269999]
              ]
            ],
            [
              [
                [128.925278, -1.377777],
                [128.916672, -1.398609],
                [128.911667, -1.38389],
                [128.925278, -1.377777]
              ]
            ],
            [
              [
                [130.261948, -1.458889],
                [130.258606, -1.476111],
                [130.20723, -1.483889],
                [130.261948, -1.458889]
              ]
            ],
            [
              [
                [129.910553, -1.62722],
                [129.923615, -1.638611],
                [129.914444, -1.65139],
                [129.910553, -1.62722]
              ]
            ],
            [
              [
                [129.862778, -1.66472],
                [129.871109, -1.670833],
                [129.858612, -1.674445],
                [129.862778, -1.66472]
              ]
            ],
            [
              [
                [130.186661, -2.06472],
                [130.09166, -2.043611],
                [130.117493, -2.038888],
                [130.085007, -2.02806],
                [130.101669, -2.018055],
                [130.016113, -2.022779],
                [129.862778, -1.976666],
                [129.719162, -1.879169],
                [130.058838, -1.724479],
                [130.258179, -1.729099],
                [130.252975, -1.704291],
                [130.3703, -1.676137],
                [130.352402, -1.736864],
                [130.455826, -1.834167],
                [130.429718, -1.872222],
                [130.410004, -1.859719],
                [130.408188, -1.89784],
                [130.340088, -1.922295],
                [130.432495, -1.952778],
                [130.427536, -1.972821],
                [130.444717, -1.954166],
                [130.439438, -1.9825],
                [130.407227, -1.970829],
                [130.382782, -1.981944],
                [130.478882, -2.00778],
                [130.371674, -2.042223],
                [130.405273, -2.000278],
                [130.326309, -1.985992],
                [130.343887, -1.95611],
                [130.304443, -2.01083],
                [130.26416, -2.01111],
                [130.285202, -2.031591],
                [130.265839, -2.05389],
                [130.186661, -2.06472]
              ]
            ],
            [
              [
                [129.70195, -1.773055],
                [129.700562, -1.78778],
                [129.691605, -1.755847],
                [129.70195, -1.773055]
              ]
            ],
            [
              [
                [129.625, -1.772779],
                [129.628891, -1.794166],
                [129.613327, -1.779167],
                [129.625, -1.772779]
              ]
            ],
            [
              [
                [129.655853, -1.80393],
                [129.656952, -1.839179],
                [129.641388, -1.822519],
                [129.655853, -1.80393]
              ]
            ],
            [
              [
                [130.451385, -1.954166],
                [130.466949, -1.97917],
                [130.45694, -1.98722],
                [130.451385, -1.954166]
              ]
            ],
            [
              [
                [130.483612, -1.97028],
                [130.492493, -1.981671],
                [130.471939, -1.98611],
                [130.483612, -1.97028]
              ]
            ],
            [
              [
                [130.549438, -1.985559],
                [130.599716, -2.004305],
                [130.544724, -1.995833],
                [130.549438, -1.985559]
              ]
            ],
            [
              [
                [130.614716, -1.999721],
                [130.638611, -2.006388],
                [130.600616, -2.004653],
                [130.614716, -1.999721]
              ]
            ],
            [
              [
                [130.661667, -2.00944],
                [130.673615, -2.012779],
                [130.663055, -2.022219],
                [130.661667, -2.00944]
              ]
            ],
            [
              [
                [130.439163, -2.053609],
                [130.440277, -2.066388],
                [130.424728, -2.06361],
                [130.439163, -2.053609]
              ]
            ],
            [
              [
                [130.449722, -2.066391],
                [130.476105, -2.06583],
                [130.409729, -2.089444],
                [130.400284, -2.080909],
                [130.449722, -2.066391]
              ]
            ],
            [
              [
                [130.196106, -2.14083],
                [130.211945, -2.144171],
                [130.169998, -2.14361],
                [130.196106, -2.14083]
              ]
            ],
            [
              [
                [130.310562, -2.193056],
                [130.271118, -2.195556],
                [130.279449, -2.212499],
                [130.2117, -2.193263],
                [130.310562, -2.193056]
              ]
            ],
            [
              [
                [130.41333, -2.205279],
                [130.49028, -2.222778],
                [130.410004, -2.215278],
                [130.41333, -2.205279]
              ]
            ],
            [
              [
                [130.544174, -2.218056],
                [130.553894, -2.221112],
                [130.500443, -2.225796],
                [130.544174, -2.218056]
              ]
            ],
            [
              [
                [130.602219, -2.244439],
                [130.623642, -2.253854],
                [130.571106, -2.2525],
                [130.602219, -2.244439]
              ]
            ],
            [
              [
                [131.957993, -1.837777],
                [131.948608, -1.86306],
                [131.983337, -1.874444],
                [131.97171, -1.923842],
                [131.923889, -1.870834],
                [131.957993, -1.837777]
              ]
            ],
            [
              [
                [132.086395, -1.95444],
                [132.10083, -1.970833],
                [132.063049, -2.024999],
                [132.058136, -1.96633],
                [132.086395, -1.95444]
              ]
            ],
            [
              [
                [131.057785, -1.193612],
                [131.046387, -1.213331],
                [131.037781, -1.197222],
                [131.057785, -1.193612]
              ]
            ],
            [
              [
                [131.181107, -1.20028],
                [131.168884, -1.22139],
                [131.14444, -1.22139],
                [131.185837, -1.189719],
                [131.181107, -1.20028]
              ]
            ],
            [
              [
                [131.022217, -1.298055],
                [131.01358, -1.308412],
                [131.011108, -1.289723],
                [131.022217, -1.298055]
              ]
            ],
            [
              [
                [131.336533, -1.352317],
                [131.362946, -1.397369],
                [131.31813, -1.423989],
                [131.272385, -1.38123],
                [131.305161, -1.380453],
                [131.318268, -1.370726],
                [131.299835, -1.355891],
                [131.336533, -1.352317]
              ]
            ],
            [
              [
                [131.713577, -1.428579],
                [131.740555, -1.457071],
                [131.718613, -1.520834],
                [131.638779, -1.544687],
                [131.58696, -1.490177],
                [131.594162, -1.46389],
                [131.623611, -1.484167],
                [131.605881, -1.463616],
                [131.655273, -1.44333],
                [131.669449, -1.46722],
                [131.713577, -1.428579]
              ]
            ],
            [
              [
                [133.872208, -2.126975],
                [133.836166, -2.130742],
                [133.837692, -2.106504],
                [133.863007, -2.102526],
                [133.872208, -2.126975]
              ]
            ],
            [
              [
                [133.896286, -2.104794],
                [133.914978, -2.118496],
                [133.874084, -2.134626],
                [133.873978, -2.110467],
                [133.896286, -2.104794]
              ]
            ],
            [
              [
                [133.931396, -2.13389],
                [133.929718, -2.16139],
                [133.871109, -2.161939],
                [133.881393, -2.14361],
                [133.931396, -2.13389]
              ]
            ],
            [
              [
                [133.837708, -2.146894],
                [133.859116, -2.152401],
                [133.835831, -2.159443],
                [133.840256, -2.189849],
                [133.775406, -2.194772],
                [133.837708, -2.146894]
              ]
            ],
            [
              [
                [133.853683, -2.157839],
                [133.862961, -2.171997],
                [133.850632, -2.195109],
                [133.836441, -2.162299],
                [133.853683, -2.157839]
              ]
            ],
            [
              [
                [133.921387, -2.168056],
                [133.934174, -2.187777],
                [133.895279, -2.183055],
                [133.921387, -2.168056]
              ]
            ],
            [
              [
                [133.877823, -2.17168],
                [133.889481, -2.191091],
                [133.852081, -2.198414],
                [133.877823, -2.17168]
              ]
            ],
            [
              [
                [133.723892, -2.175554],
                [133.766113, -2.177501],
                [133.699448, -2.234167],
                [133.674438, -2.231945],
                [133.73056, -2.202779],
                [133.723892, -2.175554]
              ]
            ],
            [
              [
                [133.828888, -2.241109],
                [133.806671, -2.237499],
                [133.79277, -2.2775],
                [133.763062, -2.239721],
                [133.842239, -2.20143],
                [133.864716, -2.230279],
                [133.854294, -2.205279],
                [133.904449, -2.193329],
                [133.882507, -2.240833],
                [133.888885, -2.265833],
                [133.849716, -2.283889],
                [133.848053, -2.24028],
                [133.871674, -2.2475],
                [133.85083, -2.235828],
                [133.843887, -2.285279],
                [133.815323, -2.290874],
                [133.804993, -2.252222],
                [133.829437, -2.263333],
                [133.806671, -2.24028],
                [133.828888, -2.241109]
              ]
            ],
            [
              [
                [133.921112, -2.186946],
                [133.934448, -2.2475],
                [133.91777, -2.272219],
                [133.884171, -2.243609],
                [133.912506, -2.240833],
                [133.921112, -2.186946]
              ]
            ],
            [
              [
                [133.751938, -2.214439],
                [133.747498, -2.25028],
                [133.674728, -2.26417],
                [133.751938, -2.214439]
              ]
            ],
            [
              [
                [133.620895, -2.233558],
                [133.596176, -2.225513],
                [133.60466, -2.21703],
                [133.620895, -2.233558]
              ]
            ],
            [
              [
                [133.986664, -2.267221],
                [133.989716, -2.299444],
                [133.945282, -2.32389],
                [133.82695, -2.330278],
                [133.926117, -2.305278],
                [133.964996, -2.245699],
                [133.954727, -2.233055],
                [133.987503, -2.228889],
                [133.986664, -2.267221]
              ]
            ],
            [
              [
                [133.727783, -2.24861],
                [133.746674, -2.258611],
                [133.720276, -2.269443],
                [133.712784, -2.257777],
                [133.727783, -2.24861]
              ]
            ],
            [
              [
                [133.907791, -2.267632],
                [133.924438, -2.304036],
                [133.866943, -2.290279],
                [133.907791, -2.267632]
              ]
            ],
            [
              [
                [133.94194, -2.32722],
                [133.986115, -2.386669],
                [133.895538, -2.38916],
                [133.851395, -2.36],
                [133.94194, -2.32722]
              ]
            ],
            [
              [
                [133.814728, -2.335001],
                [133.847198, -2.34589],
                [133.804718, -2.353612],
                [133.814728, -2.335001]
              ]
            ],
            [
              [
                [133.946106, -2.466666],
                [133.901108, -2.477777],
                [133.838882, -2.394723],
                [133.940002, -2.394999],
                [133.946945, -2.431666],
                [133.985001, -2.449445],
                [133.974442, -2.476111],
                [133.946106, -2.466666]
              ]
            ],
            [
              [
                [133.726669, -2.434444],
                [133.833893, -2.408888],
                [133.869446, -2.455829],
                [133.853882, -2.471945],
                [133.822495, -2.44361],
                [133.768616, -2.481946],
                [133.726669, -2.434444]
              ]
            ],
            [
              [
                [133.295517, -2.411639],
                [133.358612, -2.444445],
                [133.368332, -2.496388],
                [133.295517, -2.411639]
              ]
            ],
            [
              [
                [133.617493, -2.497779],
                [133.56395, -2.514045],
                [133.48555, -2.41722],
                [133.543381, -2.437881],
                [133.545273, -2.473055],
                [133.559357, -2.444075],
                [133.597504, -2.45639],
                [133.617493, -2.497779]
              ]
            ],
            [
              [
                [133.431396, -2.4875],
                [133.442505, -2.49583],
                [133.425003, -2.500555],
                [133.419449, -2.486112],
                [133.431396, -2.4875]
              ]
            ],
            [
              [
                [133.662216, -2.492222],
                [133.684677, -2.51224],
                [133.663269, -2.54998],
                [133.646942, -2.514722],
                [133.606659, -2.515301],
                [133.662216, -2.492222]
              ]
            ],
            [
              [
                [133.696945, -2.49833],
                [133.734161, -2.504167],
                [133.750549, -2.556667],
                [133.70639, -2.54861],
                [133.696945, -2.49833]
              ]
            ],
            [
              [
                [133.469162, -2.563333],
                [133.473831, -2.502535],
                [133.514816, -2.587125],
                [133.481674, -2.607501],
                [133.513885, -2.621944],
                [133.497498, -2.64306],
                [133.463608, -2.62139],
                [133.469162, -2.563333]
              ]
            ],
            [
              [
                [133.765839, -2.512219],
                [133.800278, -2.538612],
                [133.799164, -2.633054],
                [133.761108, -2.550279],
                [133.765839, -2.512219]
              ]
            ],
            [
              [
                [133.556335, -2.52021],
                [133.568878, -2.590512],
                [133.531143, -2.608399],
                [133.499847, -2.54526],
                [133.556335, -2.52021]
              ]
            ],
            [
              [
                [133.788895, -2.627781],
                [133.796112, -2.648056],
                [133.75, -2.671109],
                [133.753891, -2.62167],
                [133.693054, -2.610559],
                [133.71167, -2.58806],
                [133.693604, -2.538329],
                [133.73027, -2.57722],
                [133.752502, -2.55694],
                [133.788895, -2.627781]
              ]
            ],
            [
              [
                [133.399719, -2.549166],
                [133.43306, -2.599444],
                [133.41806, -2.641109],
                [133.401108, -2.62028],
                [133.411942, -2.612223],
                [133.399719, -2.549166]
              ]
            ],
            [
              [
                [133.405533, -2.615578],
                [133.394867, -2.664128],
                [133.389877, -2.580671],
                [133.405533, -2.615578]
              ]
            ],
            [
              [
                [133.516907, -2.594562],
                [133.528336, -2.610833],
                [133.512497, -2.64528],
                [133.514725, -2.621112],
                [133.498611, -2.606391],
                [133.516907, -2.594562]
              ]
            ]
          ]
        },
        "id": 92,
        "properties": {
          "ID": 23,
          "kode": 92,
          "Propinsi": "PAPUA BARAT",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [98.25574, 4.290953],
                [98.251678, 4.20263],
                [98.168282, 4.159943],
                [98.171654, 4.108615],
                [98.234329, 4.129492],
                [98.272324, 4.119934],
                [98.30899, 4.072206],
                [98.387726, 4.076143],
                [98.435066, 4.039406],
                [98.528557, 4.011974],
                [98.558937, 3.939592],
                [98.609138, 3.914186],
                [98.672783, 3.9084],
                [98.697937, 3.874467],
                [98.70237, 3.811335],
                [98.788483, 3.718319],
                [98.991402, 3.652537],
                [99.073792, 3.610853],
                [99.131668, 3.565329],
                [99.205673, 3.535731],
                [99.272652, 3.473173],
                [99.372841, 3.402371],
                [99.485054, 3.348217],
                [99.487228, 3.322032],
                [99.546608, 3.244231],
                [99.670906, 3.209138],
                [99.737755, 3.170621],
                [99.828384, 3.06429],
                [99.917145, 2.979943],
                [99.998863, 2.93651],
                [99.990746, 2.856591],
                [100.000282, 2.799706],
                [99.981476, 2.742654],
                [100.006645, 2.724051],
                [100.060226, 2.733359],
                [100.098732, 2.676424],
                [100.136818, 2.672004],
                [100.176842, 2.706297],
                [100.222885, 2.703859],
                [100.241371, 2.637462],
                [100.33075, 2.534235],
                [100.302208, 2.413565],
                [100.309578, 2.34065],
                [100.360146, 2.220377],
                [100.322708, 2.014011],
                [100.322128, 1.919015],
                [100.2864, 1.830302],
                [100.292068, 1.802315],
                [100.408333, 1.777267],
                [100.455269, 1.629147],
                [100.415886, 1.537141],
                [100.434021, 1.51129],
                [100.408829, 1.470363],
                [100.315857, 1.416456],
                [100.27504, 1.421141],
                [100.242752, 1.392655],
                [100.077881, 1.387209],
                [100.086632, 1.337513],
                [100.123497, 1.254924],
                [100.130478, 1.174596],
                [100.16082, 1.151491],
                [100.108658, 1.071883],
                [100.161377, 0.999156],
                [100.136681, 0.949117],
                [100.092819, 0.915075],
                [100.132614, 0.891546],
                [100.140305, 0.797662],
                [100.183716, 0.761895],
                [100.083336, 0.791869],
                [99.957077, 0.875112],
                [99.881943, 0.90572],
                [99.835236, 0.889678],
                [99.845215, 0.83873],
                [99.845169, 0.686254],
                [99.914688, 0.680979],
                [99.919365, 0.605901],
                [99.964767, 0.554445],
                [99.908188, 0.474805],
                [99.82309, 0.461878],
                [99.765022, 0.481917],
                [99.716553, 0.454064],
                [99.670876, 0.481021],
                [99.640007, 0.547226],
                [99.587463, 0.516625],
                [99.499702, 0.538817],
                [99.407265, 0.493468],
                [99.331474, 0.362899],
                [99.297096, 0.337691],
                [99.235527, 0.327712],
                [99.16198, 0.231812],
                [99.153839, 0.308747],
                [99.109512, 0.342637],
                [99.135689, 0.435732],
                [99.093124, 0.60576],
                [99.071304, 0.629137],
                [99.057587, 0.726479],
                [99.017548, 0.813711],
                [98.991776, 0.822795],
                [98.969215, 0.931165],
                [98.927269, 1.041666],
                [98.910728, 1.122399],
                [98.839851, 1.286661],
                [98.844856, 1.333901],
                [98.777878, 1.434891],
                [98.759827, 1.528909],
                [98.772934, 1.561786],
                [98.840958, 1.628664],
                [98.82769, 1.677764],
                [98.797264, 1.683911],
                [98.769341, 1.7595],
                [98.707138, 1.789281],
                [98.67894, 1.740839],
                [98.611458, 1.838434],
                [98.501572, 1.941459],
                [98.466949, 1.987465],
                [98.389442, 2.007457],
                [98.338593, 2.045849],
                [98.271065, 2.049494],
                [98.224586, 2.096319],
                [98.153148, 2.146027],
                [98.18766, 2.319383],
                [98.155571, 2.413039],
                [98.112091, 2.424448],
                [98.109344, 2.47093],
                [98.083992, 2.50935],
                [98.075233, 2.57553],
                [98.085129, 2.63422],
                [98.12426, 2.655123],
                [98.086838, 2.7349],
                [98.088196, 2.821929],
                [98.042084, 2.828039],
                [98.006783, 2.889557],
                [97.954605, 2.900333],
                [97.958275, 2.961847],
                [97.939224, 3.071936],
                [97.983467, 3.083911],
                [97.978615, 3.127329],
                [97.942329, 3.164997],
                [97.925278, 3.226437],
                [97.872261, 3.249049],
                [97.984924, 3.275573],
                [98.029823, 3.329871],
                [98.003082, 3.369784],
                [97.949715, 3.391531],
                [97.906899, 3.553616],
                [97.869896, 3.57131],
                [97.843933, 3.660525],
                [97.803185, 3.731739],
                [97.908562, 3.820508],
                [97.89991, 3.89141],
                [97.944946, 3.89686],
                [97.95816, 3.928891],
                [98.023132, 3.969775],
                [98.059311, 4.190727],
                [98.090927, 4.19672],
                [98.145401, 4.287369],
                [98.25574, 4.290953]
              ],
              [
                [98.92707, 2.662123],
                [98.922714, 2.694872],
                [98.833435, 2.747945],
                [98.79274, 2.79301],
                [98.692108, 2.850813],
                [98.625786, 2.878684],
                [98.531593, 2.892692],
                [98.527939, 2.792798],
                [98.593155, 2.730381],
                [98.624992, 2.681004],
                [98.616501, 2.648208],
                [98.683029, 2.637003],
                [98.695267, 2.72581],
                [98.723587, 2.754783],
                [98.777992, 2.739367],
                [98.854713, 2.665531],
                [98.921768, 2.541882],
                [98.971985, 2.50286],
                [98.98819, 2.455078],
                [98.922638, 2.428574],
                [98.874153, 2.425913],
                [98.778, 2.504612],
                [98.72554, 2.532148],
                [98.69825, 2.601211],
                [98.667595, 2.545009],
                [98.733681, 2.51731],
                [98.804596, 2.424474],
                [98.817467, 2.378578],
                [98.854034, 2.333492],
                [98.917976, 2.348454],
                [98.991257, 2.324367],
                [99.029228, 2.357742],
                [99.055664, 2.329031],
                [99.151482, 2.391701],
                [99.148209, 2.430922],
                [99.062157, 2.391961],
                [99.04792, 2.48477],
                [99.027946, 2.516789],
                [98.924484, 2.584452],
                [98.909531, 2.623648],
                [98.92707, 2.662123]
              ]
            ],
            [
              [
                [97.500496, 0.916113],
                [97.471382, 0.935922],
                [97.399849, 0.942146],
                [97.358147, 1.075364],
                [97.253624, 1.231068],
                [97.182999, 1.289336],
                [97.136726, 1.3638],
                [97.073105, 1.417242],
                [97.122917, 1.424893],
                [97.19696, 1.395284],
                [97.257141, 1.410369],
                [97.340027, 1.4905],
                [97.386353, 1.493314],
                [97.428909, 1.468561],
                [97.476486, 1.468499],
                [97.530159, 1.407647],
                [97.530495, 1.382853],
                [97.583405, 1.330732],
                [97.678436, 1.177108],
                [97.787994, 1.149787],
                [97.854446, 1.053744],
                [97.889046, 1.051632],
                [97.935623, 0.960954],
                [97.893776, 0.898828],
                [97.885437, 0.823092],
                [97.89946, 0.793721],
                [97.875778, 0.743668],
                [97.8918, 0.628914],
                [97.827011, 0.568907],
                [97.795334, 0.558244],
                [97.733276, 0.577961],
                [97.704224, 0.559856],
                [97.658752, 0.659734],
                [97.645798, 0.737644],
                [97.58802, 0.838179],
                [97.500496, 0.916113]
              ]
            ],
            [
              [
                [98.409668, -0.596789],
                [98.341278, -0.512249],
                [98.370155, -0.452895],
                [98.367058, -0.377264],
                [98.380356, -0.337618],
                [98.436165, -0.295887],
                [98.442184, -0.244816],
                [98.516144, -0.35539],
                [98.501068, -0.394909],
                [98.498665, -0.490648],
                [98.476936, -0.518796],
                [98.498367, -0.555216],
                [98.409668, -0.596789]
              ]
            ],
            [
              [
                [98.371108, 0.005866],
                [98.414833, -0.025506],
                [98.465797, -0.0922],
                [98.488098, -0.203953],
                [98.568024, -0.27773],
                [98.56321, -0.328114],
                [98.51564, -0.321089],
                [98.427185, -0.20239],
                [98.384697, -0.132913],
                [98.356728, -0.054339],
                [98.314896, -0.011829],
                [98.371108, 0.005866]
              ]
            ],
            [
              [
                [98.598198, 0.094976],
                [98.5308, 0.106037],
                [98.522354, 0.144102],
                [98.60891, 0.183891],
                [98.717834, 0.159357],
                [98.796844, 0.171431],
                [98.850594, 0.117946],
                [98.844627, 0.082099],
                [98.777519, 0.098079],
                [98.74559, 0.073061],
                [98.598198, 0.094976]
              ]
            ],
            [
              [
                [98.460686, 1.702748],
                [98.486298, 1.698948],
                [98.50116, 1.625],
                [98.444466, 1.652204],
                [98.460686, 1.702748]
              ]
            ]
          ]
        },
        "id": 12,
        "properties": {
          "ID": 24,
          "kode": 12,
          "Propinsi": "SUMATERA UTARA",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [105.106688, -2.022016],
                [105.124608, -1.957248],
                [105.165632, -1.925504],
                [105.221056, -1.913472],
                [105.34688, -1.841024],
                [105.393152, -1.779392],
                [105.369664, -1.717056],
                [105.334016, -1.693568],
                [105.360896, -1.649792],
                [105.464384, -1.560448],
                [105.534464, -1.563456],
                [105.600512, -1.545152],
                [105.644352, -1.641792],
                [105.714752, -1.655552],
                [105.65472, -1.716672],
                [105.674432, -1.761728],
                [105.731264, -1.780544],
                [105.792768, -1.755264],
                [105.744704, -1.68576],
                [105.764864, -1.648128],
                [105.72064, -1.57952],
                [105.753664, -1.531264],
                [105.876224, -1.51872],
                [105.929792, -1.54816],
                [106.008896, -1.567616],
                [106.029824, -1.616448],
                [106.04224, -1.698368],
                [106.146688, -1.85952],
                [106.192512, -1.896128],
                [106.163776, -1.92672],
                [106.153344, -2.016064],
                [106.175616, -2.150848],
                [106.225216, -2.258176],
                [106.23424, -2.328064],
                [106.281152, -2.397888],
                [106.340864, -2.45632],
                [106.380672, -2.47392],
                [106.444928, -2.470464],
                [106.515712, -2.511936],
                [106.689408, -2.541632],
                [106.798336, -2.5664],
                [106.705984, -2.641472],
                [106.65664, -2.72064],
                [106.587264, -2.916224],
                [106.595136, -2.953152],
                [106.737536, -3.004288],
                [106.742592, -3.053824],
                [106.704064, -3.09504],
                [106.647424, -3.081152],
                [106.562496, -3.0832],
                [106.520832, -3.112192],
                [106.497728, -3.064704],
                [106.412288, -2.966656],
                [106.330944, -2.963264],
                [106.276224, -2.902592],
                [106.236928, -2.899648],
                [106.176896, -2.868672],
                [106.140864, -2.8736],
                [106.097408, -2.833472],
                [105.996096, -2.820352],
                [105.962816, -2.805312],
                [105.91584, -2.735872],
                [105.884544, -2.635776],
                [105.932224, -2.473024],
                [105.909376, -2.44128],
                [105.831104, -2.40768],
                [105.801792, -2.35872],
                [105.82368, -2.30688],
                [105.785856, -2.244864],
                [105.776192, -2.156288],
                [105.733952, -2.128384],
                [105.655808, -2.114432],
                [105.590464, -2.119936],
                [105.55904, -2.08192],
                [105.503616, -2.080512],
                [105.437184, -2.133312],
                [105.358336, -2.12],
                [105.301184, -2.142848],
                [105.253312, -2.083648],
                [105.217216, -2.06432],
                [105.128064, -2.082688],
                [105.106688, -2.022016]
              ]
            ],
            [
              [
                [107.62494, -2.74277],
                [107.618688, -2.695936],
                [107.648512, -2.627072],
                [107.630976, -2.59584],
                [107.662464, -2.5584],
                [107.72224, -2.547584],
                [107.769664, -2.556928],
                [107.81536, -2.53632],
                [107.909824, -2.575872],
                [107.994752, -2.57472],
                [108.00992, -2.596864],
                [108.083328, -2.606464],
                [108.183744, -2.6768],
                [108.26048, -2.766976],
                [108.290432, -2.85312],
                [108.245184, -2.908544],
                [108.235328, -2.95712],
                [108.199616, -3.011008],
                [108.209472, -3.048064],
                [108.173632, -3.109184],
                [108.169472, -3.14816],
                [108.135808, -3.1664],
                [108.073408, -3.166144],
                [108.062976, -3.235776],
                [107.96832, -3.237568],
                [107.961664, -3.149376],
                [107.85152, -3.053248],
                [107.839552, -3.146784],
                [107.758144, -3.1864],
                [107.713856, -3.18752],
                [107.642048, -3.23136],
                [107.589952, -3.197696],
                [107.628416, -3.142848],
                [107.58528, -3.079872],
                [107.569728, -3.027648],
                [107.610816, -2.926144],
                [107.545408, -2.902208],
                [107.600064, -2.858496],
                [107.598656, -2.788224],
                [107.62494, -2.74277]
              ]
            ],
            [
              [
                [106.881216, -2.999872],
                [106.872896, -3.028288],
                [106.793344, -3.019648],
                [106.707712, -2.962624],
                [106.729344, -2.9296],
                [106.803776, -2.885888],
                [106.900608, -2.935168],
                [106.881216, -2.999872]
              ]
            ],
            [
              [
                [107.488, -2.853568],
                [107.472128, -2.908416],
                [107.416256, -2.94496],
                [107.362176, -2.896256],
                [107.38624, -2.850304],
                [107.426752, -2.828096],
                [107.488, -2.853568]
              ]
            ],
            [
              [
                [107.094272, -2.87296],
                [107.0608, -2.907392],
                [107.020736, -2.873088],
                [107.054208, -2.81888],
                [107.094272, -2.87296]
              ]
            ]
          ]
        },
        "id": 19,
        "properties": {
          "ID": 25,
          "kode": 19,
          "Propinsi": "KEPULAUAN BANGKA BELITUNG",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [99.16198, 0.231812],
                [99.235527, 0.327712],
                [99.297096, 0.337691],
                [99.331474, 0.362899],
                [99.407265, 0.493468],
                [99.499702, 0.538817],
                [99.587463, 0.516625],
                [99.640007, 0.547226],
                [99.670876, 0.481021],
                [99.716553, 0.454064],
                [99.765022, 0.481917],
                [99.82309, 0.461878],
                [99.908188, 0.474805],
                [99.964767, 0.554445],
                [99.919365, 0.605901],
                [99.914688, 0.680979],
                [99.845169, 0.686254],
                [99.845215, 0.83873],
                [99.835236, 0.889678],
                [99.881943, 0.90572],
                [99.957077, 0.875112],
                [100.083336, 0.791869],
                [100.183716, 0.761895],
                [100.25573, 0.709395],
                [100.205925, 0.593234],
                [100.219559, 0.524918],
                [100.282326, 0.408069],
                [100.281677, 0.332958],
                [100.361946, 0.354324],
                [100.394623, 0.339763],
                [100.43718, 0.247852],
                [100.464088, 0.222191],
                [100.605743, 0.183473],
                [100.687881, 0.177849],
                [100.727119, 0.193165],
                [100.778122, 0.169268],
                [100.792191, 0.120829],
                [100.753342, 0.048535],
                [100.770103, -0.033094],
                [100.748398, -0.065219],
                [100.781006, -0.23042],
                [100.838776, -0.290596],
                [100.833672, -0.318377],
                [100.910423, -0.370743],
                [100.94133, -0.312437],
                [100.996368, -0.314363],
                [101.031708, -0.340868],
                [101.024826, -0.398442],
                [101.06768, -0.498227],
                [101.181267, -0.549494],
                [101.256569, -0.626754],
                [101.359833, -0.659254],
                [101.407272, -0.736848],
                [101.500015, -0.807856],
                [101.595917, -0.8512],
                [101.6539, -0.935173],
                [101.747887, -0.967631],
                [101.821533, -0.977189],
                [101.830315, -1.10193],
                [101.878525, -1.110225],
                [101.858885, -1.158166],
                [101.815407, -1.209807],
                [101.709229, -1.237597],
                [101.689585, -1.295043],
                [101.719172, -1.344921],
                [101.691318, -1.397239],
                [101.720899, -1.42113],
                [101.694035, -1.480637],
                [101.567154, -1.572305],
                [101.526581, -1.616804],
                [101.492464, -1.681019],
                [101.431198, -1.696775],
                [101.415512, -1.671203],
                [101.319626, -1.693206],
                [101.258324, -1.693337],
                [101.21579, -1.714355],
                [101.18763, -1.677177],
                [101.125816, -1.679094],
                [101.124443, -1.78474],
                [101.144699, -1.917723],
                [101.194092, -1.989151],
                [101.243668, -2.021798],
                [101.291611, -2.133106],
                [101.306076, -2.23891],
                [101.294159, -2.280203],
                [101.244644, -2.344946],
                [101.155373, -2.390157],
                [101.027755, -2.482182],
                [100.992081, -2.431542],
                [100.897308, -2.337769],
                [100.877205, -2.247514],
                [100.831245, -2.188847],
                [100.82914, -2.147044],
                [100.869568, -2.104535],
                [100.884331, -2.042843],
                [100.858192, -1.919218],
                [100.78273, -1.819229],
                [100.684204, -1.661846],
                [100.64151, -1.618727],
                [100.633499, -1.562898],
                [100.555435, -1.403785],
                [100.576469, -1.376961],
                [100.544067, -1.308304],
                [100.492241, -1.299992],
                [100.458786, -1.26039],
                [100.402206, -1.268252],
                [100.417633, -1.200178],
                [100.382729, -1.178114],
                [100.410194, -1.037552],
                [100.364952, -0.995286],
                [100.342545, -0.882115],
                [100.269402, -0.782622],
                [100.117134, -0.631379],
                [100.080696, -0.549656],
                [99.971771, -0.443441],
                [99.918625, -0.404366],
                [99.886421, -0.349305],
                [99.820641, -0.306837],
                [99.806374, -0.245461],
                [99.750984, -0.153287],
                [99.765541, -0.065272],
                [99.735329, -0.027319],
                [99.610626, 0.069696],
                [99.471992, 0.134715],
                [99.394257, 0.152445],
                [99.353981, 0.230505],
                [99.312424, 0.238678],
                [99.253555, 0.211412],
                [99.16198, 0.231812]
              ],
              [
                [100.148926, -0.294198],
                [100.165466, -0.310519],
                [100.165375, -0.388144],
                [100.218018, -0.396025],
                [100.226418, -0.288869],
                [100.194832, -0.252879],
                [100.148926, -0.294198]
              ],
              [
                [100.500008, -0.534535],
                [100.487389, -0.577913],
                [100.540764, -0.666713],
                [100.59713, -0.680994],
                [100.544914, -0.55475],
                [100.500008, -0.534535]
              ]
            ],
            [
              [
                [99.263031, -1.718946],
                [99.241554, -1.791817],
                [99.170258, -1.775342],
                [99.104195, -1.809051],
                [99.009583, -1.762335],
                [98.872444, -1.675564],
                [98.81971, -1.61324],
                [98.831772, -1.58409],
                [98.798141, -1.519863],
                [98.686104, -1.357201],
                [98.628815, -1.283929],
                [98.597008, -1.222869],
                [98.64505, -1.10431],
                [98.65641, -0.979386],
                [98.715179, -0.94198],
                [98.786461, -0.954942],
                [98.905327, -0.907312],
                [98.901535, -0.943195],
                [98.930374, -1.056429],
                [98.997421, -1.109993],
                [99.003639, -1.159117],
                [99.029007, -1.183758],
                [99.069817, -1.264657],
                [99.095047, -1.360714],
                [99.162605, -1.427637],
                [99.159073, -1.492668],
                [99.192131, -1.505583],
                [99.193535, -1.554393],
                [99.22258, -1.605247],
                [99.258133, -1.590373],
                [99.280853, -1.628746],
                [99.263031, -1.718946]
              ]
            ],
            [
              [
                [100.432472, -3.000107],
                [100.465523, -3.033659],
                [100.468422, -3.144241],
                [100.394447, -3.149384],
                [100.389984, -3.197419],
                [100.475174, -3.334633],
                [100.421425, -3.310643],
                [100.342789, -3.24575],
                [100.325836, -3.212034],
                [100.332291, -3.129642],
                [100.296883, -3.080192],
                [100.228333, -3.045524],
                [100.186508, -2.986326],
                [100.200531, -2.933076],
                [100.17469, -2.801497],
                [100.23597, -2.778423],
                [100.261101, -2.820208],
                [100.354225, -2.895449],
                [100.432472, -3.000107]
              ]
            ],
            [
              [
                [99.995766, -2.749434],
                [100.004059, -2.723478],
                [99.970131, -2.509001],
                [99.994774, -2.498358],
                [100.070412, -2.575727],
                [100.168579, -2.640264],
                [100.221405, -2.740431],
                [100.11644, -2.831824],
                [100.015343, -2.805559],
                [99.995766, -2.749434]
              ]
            ],
            [
              [
                [99.822586, -2.306719],
                [99.833534, -2.360941],
                [99.793526, -2.368245],
                [99.74221, -2.344413],
                [99.673538, -2.286497],
                [99.598953, -2.281999],
                [99.52739, -2.152481],
                [99.571342, -2.143682],
                [99.545197, -2.067696],
                [99.565895, -2.036215],
                [99.618942, -2.025629],
                [99.689621, -2.074452],
                [99.710625, -2.154402],
                [99.783386, -2.28467],
                [99.822586, -2.306719]
              ]
            ]
          ]
        },
        "id": 13,
        "properties": {
          "ID": 26,
          "kode": 13,
          "Propinsi": "SUMATERA BARAT",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [105.785599, -4.117105],
              [105.736504, -4.14257],
              [105.705254, -4.098131],
              [105.651817, -4.087366],
              [105.609012, -3.941194],
              [105.51181, -3.870661],
              [105.476404, -3.821803],
              [105.432106, -3.826865],
              [105.414685, -3.776846],
              [105.353288, -3.794026],
              [105.339727, -3.729057],
              [105.281975, -3.798395],
              [105.290791, -3.839618],
              [105.245973, -3.907812],
              [105.201302, -3.892022],
              [105.173737, -3.91232],
              [105.133827, -3.991517],
              [105.112688, -3.999992],
              [105.0635, -4.119611],
              [105.063462, -4.11991],
              [105.011985, -4.173651],
              [104.966435, -4.170873],
              [104.815692, -4.241028],
              [104.788078, -4.261929],
              [104.737687, -4.251162],
              [104.734169, -4.251686],
              [104.655515, -4.250796],
              [104.601004, -4.232642],
              [104.448685, -4.298732],
              [104.330181, -4.424722],
              [104.311634, -4.521019],
              [104.332608, -4.612251],
              [104.294205, -4.647204],
              [104.303461, -4.764368],
              [104.325085, -4.806065],
              [104.32542, -4.808589],
              [104.347231, -4.822632],
              [104.348493, -4.822822],
              [104.349944, -4.85207],
              [104.348405, -4.853417],
              [104.311096, -4.885782],
              [104.309891, -4.88541],
              [104.190925, -4.865033],
              [104.160093, -4.922752],
              [104.110733, -4.923104],
              [104.060074, -4.894125],
              [104.020423, -4.905076],
              [104.019723, -4.90522],
              [103.955718, -4.904517],
              [103.95543, -4.904254],
              [103.93436, -4.886683],
              [103.86399, -4.918034],
              [103.864089, -4.847539],
              [103.823976, -4.802083],
              [103.777597, -4.796539],
              [103.777253, -4.79643],
              [103.762881, -4.7421],
              [103.704562, -4.697992],
              [103.693762, -4.652768],
              [103.638473, -4.598905],
              [103.630332, -4.554627],
              [103.590699, -4.52343],
              [103.521138, -4.57475],
              [103.476627, -4.564854],
              [103.405136, -4.499798],
              [103.428073, -4.40753],
              [103.468517, -4.348306],
              [103.453716, -4.3315],
              [103.329801, -4.292813],
              [103.307721, -4.256566],
              [103.200666, -4.247289],
              [103.185598, -4.163748],
              [103.155336, -4.110564],
              [103.128459, -4.025171],
              [102.956093, -4.012947],
              [102.914916, -3.97235],
              [102.886803, -3.989239],
              [102.79337, -3.954387],
              [102.746036, -3.892661],
              [102.686069, -3.858048],
              [102.63594, -3.812298],
              [102.655215, -3.772596],
              [102.728362, -3.75001],
              [102.735313, -3.702301],
              [102.7655, -3.644093],
              [102.809992, -3.608159],
              [102.813889, -3.534643],
              [102.847676, -3.536874],
              [102.921399, -3.593359],
              [102.952011, -3.531126],
              [102.981817, -3.526327],
              [102.998046, -3.466969],
              [102.989653, -3.370434],
              [102.888499, -3.375246],
              [102.881726, -3.345414],
              [102.816825, -3.333799],
              [102.773751, -3.280437],
              [102.742687, -3.315027],
              [102.686779, -3.317826],
              [102.640813, -3.349581],
              [102.596323, -3.333274],
              [102.512752, -3.274333],
              [102.491776, -3.244821],
              [102.485085, -3.1067],
              [102.441376, -3.057934],
              [102.389915, -3.044319],
              [102.322517, -3.068047],
              [102.247734, -3.027864],
              [102.223949, -2.929184],
              [102.132041, -2.87452],
              [102.12852, -2.834456],
              [102.070271, -2.779745],
              [102.070121, -2.779601],
              [102.066829, -2.766654],
              [102.125106, -2.729362],
              [102.23631, -2.687767],
              [102.25, -2.631226],
              [102.279852, -2.615552],
              [102.33295, -2.65582],
              [102.387126, -2.648317],
              [102.405707, -2.688557],
              [102.490935, -2.70477],
              [102.517768, -2.677439],
              [102.597958, -2.677392],
              [102.64112, -2.609328],
              [102.73603, -2.577646],
              [102.79447, -2.533695],
              [102.793521, -2.505252],
              [102.875275, -2.407647],
              [102.839739, -2.358138],
              [102.878348, -2.304883],
              [102.98499, -2.338936],
              [103.035798, -2.33815],
              [103.054261, -2.312252],
              [103.123599, -2.357747],
              [103.211938, -2.312515],
              [103.194719, -2.25453],
              [103.164581, -2.219417],
              [103.219055, -2.171653],
              [103.273361, -2.154748],
              [103.303619, -2.238668],
              [103.392789, -2.295459],
              [103.409337, -2.332503],
              [103.449943, -2.343645],
              [103.465737, -2.249964],
              [103.441574, -2.213186],
              [103.453247, -2.177541],
              [103.526903, -2.167123],
              [103.513543, -2.084383],
              [103.55055, -2.049371],
              [103.56171, -2.000056],
              [103.441719, -1.913766],
              [103.500085, -1.857687],
              [103.552617, -1.891252],
              [103.597851, -1.813841],
              [103.683861, -1.804445],
              [103.863641, -1.803907],
              [103.925284, -1.750187],
              [103.980489, -1.809214],
              [104.072833, -1.805048],
              [104.11097, -1.786794],
              [104.272829, -1.743732],
              [104.30012, -1.774048],
              [104.351075, -1.741535],
              [104.362201, -1.642867],
              [104.418398, -1.628021],
              [104.515165, -1.67627],
              [104.544899, -1.725683],
              [104.536062, -1.796749],
              [104.482233, -1.837572],
              [104.475063, -1.913292],
              [104.519772, -1.925953],
              [104.552302, -1.854772],
              [104.649983, -1.913998],
              [104.657544, -1.963975],
              [104.686527, -1.992045],
              [104.847588, -2.007696],
              [104.899483, -2.091442],
              [104.908005, -2.147511],
              [104.853889, -2.239455],
              [104.853081, -2.288282],
              [104.913132, -2.283812],
              [104.934425, -2.343508],
              [104.997681, -2.337891],
              [105.122139, -2.382225],
              [105.227684, -2.334802],
              [105.294975, -2.370025],
              [105.415565, -2.389337],
              [105.531751, -2.395946],
              [105.55791, -2.419285],
              [105.615726, -2.390048],
              [105.625537, -2.432843],
              [105.601795, -2.553382],
              [105.633308, -2.645045],
              [105.701469, -2.686577],
              [105.756975, -2.689149],
              [105.787621, -2.712982],
              [105.78997, -2.874425],
              [105.811295, -2.915317],
              [105.858688, -2.955325],
              [106.007011, -2.965389],
              [106.043472, -2.992686],
              [106.056297, -3.072323],
              [106.046761, -3.109661],
              [106.079933, -3.193721],
              [106.07856, -3.250618],
              [106.015045, -3.287257],
              [105.912909, -3.384527],
              [105.843343, -3.525817],
              [105.815506, -3.649148],
              [105.827038, -3.712559],
              [105.92809, -3.777244],
              [105.956879, -3.848718],
              [105.866005, -4.018025],
              [105.840332, -4.159925],
              [105.82164, -4.166204],
              [105.814331, -4.153293],
              [105.812897, -4.150985],
              [105.792374, -4.119071],
              [105.785599, -4.117105]
            ]
          ]
        },
        "id": 16,
        "properties": {
          "ID": 28,
          "kode": 16,
          "Propinsi": "SUMATERA SELATAN",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [101.294159, -2.280203],
              [101.306076, -2.23891],
              [101.291611, -2.133106],
              [101.243668, -2.021798],
              [101.194092, -1.989151],
              [101.144699, -1.917723],
              [101.124443, -1.78474],
              [101.125816, -1.679094],
              [101.18763, -1.677177],
              [101.21579, -1.714355],
              [101.258324, -1.693337],
              [101.319626, -1.693206],
              [101.415512, -1.671203],
              [101.431198, -1.696775],
              [101.492464, -1.681019],
              [101.526581, -1.616804],
              [101.567154, -1.572305],
              [101.694035, -1.480637],
              [101.720899, -1.42113],
              [101.691318, -1.397239],
              [101.719172, -1.344921],
              [101.689585, -1.295043],
              [101.709229, -1.237597],
              [101.815407, -1.209807],
              [101.858885, -1.158166],
              [101.878525, -1.110225],
              [101.830315, -1.10193],
              [101.821533, -0.977189],
              [101.845284, -0.982566],
              [101.924278, -0.941475],
              [101.983551, -0.880408],
              [102.057976, -0.889922],
              [102.116615, -0.921174],
              [102.16272, -0.898907],
              [102.217072, -0.946344],
              [102.254326, -0.921539],
              [102.304298, -0.916958],
              [102.324387, -0.960805],
              [102.38623, -0.993385],
              [102.393372, -1.03016],
              [102.44252, -1.025452],
              [102.495422, -1.084733],
              [102.616814, -1.094634],
              [102.638, -1.12041],
              [102.687668, -1.078578],
              [102.780602, -1.023676],
              [102.808495, -0.96665],
              [102.90477, -0.894179],
              [103.008949, -0.755179],
              [103.126038, -0.746429],
              [103.342834, -0.785774],
              [103.482338, -0.771407],
              [103.489655, -0.798365],
              [103.544289, -0.81498],
              [103.549156, -0.858699],
              [103.588211, -0.875501],
              [103.669304, -0.952914],
              [103.714386, -0.95547],
              [103.748962, -0.987215],
              [103.870873, -1.000657],
              [103.921692, -0.98653],
              [104.05471, -1.011177],
              [104.127846, -1.049528],
              [104.209656, -1.071357],
              [104.264328, -1.046509],
              [104.336029, -1.043973],
              [104.369728, -1.022185],
              [104.413643, -1.130576],
              [104.40168, -1.230084],
              [104.46064, -1.336228],
              [104.46386, -1.401982],
              [104.444763, -1.469791],
              [104.465553, -1.596184],
              [104.515165, -1.67627],
              [104.418398, -1.628021],
              [104.362201, -1.642867],
              [104.351075, -1.741535],
              [104.30012, -1.774048],
              [104.272829, -1.743732],
              [104.11097, -1.786794],
              [104.072833, -1.805048],
              [103.980489, -1.809214],
              [103.925284, -1.750187],
              [103.863641, -1.803907],
              [103.683861, -1.804445],
              [103.597851, -1.813841],
              [103.552617, -1.891252],
              [103.500085, -1.857687],
              [103.441719, -1.913766],
              [103.56171, -2.000056],
              [103.55055, -2.049371],
              [103.513543, -2.084383],
              [103.526903, -2.167123],
              [103.453247, -2.177541],
              [103.441574, -2.213186],
              [103.465737, -2.249964],
              [103.449943, -2.343645],
              [103.409337, -2.332503],
              [103.392789, -2.295459],
              [103.303619, -2.238668],
              [103.273361, -2.154748],
              [103.219055, -2.171653],
              [103.164581, -2.219417],
              [103.194719, -2.25453],
              [103.211938, -2.312515],
              [103.123599, -2.357747],
              [103.054261, -2.312252],
              [103.035798, -2.33815],
              [102.98499, -2.338936],
              [102.878348, -2.304883],
              [102.839739, -2.358138],
              [102.875275, -2.407647],
              [102.793521, -2.505252],
              [102.79447, -2.533695],
              [102.73603, -2.577646],
              [102.64112, -2.609328],
              [102.597958, -2.677392],
              [102.517768, -2.677439],
              [102.490935, -2.70477],
              [102.405707, -2.688557],
              [102.387126, -2.648317],
              [102.33295, -2.65582],
              [102.279852, -2.615552],
              [102.25, -2.631226],
              [102.23631, -2.687767],
              [102.125106, -2.729362],
              [102.066829, -2.766654],
              [101.989708, -2.723145],
              [101.915871, -2.738421],
              [101.867928, -2.728184],
              [101.733284, -2.607907],
              [101.674995, -2.532605],
              [101.601181, -2.537571],
              [101.546486, -2.560535],
              [101.559204, -2.451228],
              [101.330421, -2.309457],
              [101.294159, -2.280203]
            ],
            [
              [101.466292, -2.163241],
              [101.528671, -2.17382],
              [101.524849, -2.128504],
              [101.46859, -2.129224],
              [101.466292, -2.163241]
            ]
          ]
        },
        "id": 15,
        "properties": {
          "ID": 29,
          "kode": 15,
          "Propinsi": "JAMBI",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [103.777539, -4.79652],
              [103.777597, -4.796539],
              [103.823976, -4.802083],
              [103.864089, -4.847539],
              [103.86399, -4.918034],
              [103.93436, -4.886683],
              [103.95543, -4.904254],
              [103.955718, -4.904517],
              [104.019723, -4.90522],
              [104.020423, -4.905076],
              [104.060074, -4.894125],
              [104.110733, -4.923104],
              [104.160093, -4.922752],
              [104.190925, -4.865033],
              [104.309891, -4.88541],
              [104.311096, -4.885782],
              [104.348405, -4.853417],
              [104.349944, -4.85207],
              [104.348493, -4.822822],
              [104.347231, -4.822632],
              [104.32542, -4.808589],
              [104.325085, -4.806065],
              [104.303461, -4.764368],
              [104.294205, -4.647204],
              [104.332608, -4.612251],
              [104.311634, -4.521019],
              [104.330181, -4.424722],
              [104.448685, -4.298732],
              [104.601004, -4.232642],
              [104.655515, -4.250796],
              [104.734169, -4.251686],
              [104.737687, -4.251162],
              [104.788078, -4.261929],
              [104.815692, -4.241028],
              [104.966435, -4.170873],
              [105.011985, -4.173651],
              [105.063462, -4.11991],
              [105.0635, -4.119611],
              [105.112688, -3.999992],
              [105.133827, -3.991517],
              [105.173737, -3.91232],
              [105.201302, -3.892022],
              [105.245973, -3.907812],
              [105.290791, -3.839618],
              [105.281975, -3.798395],
              [105.339727, -3.729057],
              [105.353288, -3.794026],
              [105.414685, -3.776846],
              [105.432106, -3.826865],
              [105.476404, -3.821803],
              [105.51181, -3.870661],
              [105.609012, -3.941194],
              [105.651817, -4.087366],
              [105.705254, -4.098131],
              [105.736504, -4.14257],
              [105.785599, -4.117105],
              [105.792374, -4.119071],
              [105.812897, -4.150985],
              [105.814331, -4.153293],
              [105.82164, -4.166204],
              [105.817635, -4.316465],
              [105.858208, -4.376366],
              [105.898064, -4.465971],
              [105.903992, -4.543446],
              [105.884163, -4.593911],
              [105.912933, -4.647402],
              [105.879227, -4.69676],
              [105.859253, -4.776061],
              [105.870087, -4.876098],
              [105.903664, -4.938873],
              [105.86618, -4.98373],
              [105.855354, -5.079322],
              [105.863403, -5.259794],
              [105.819687, -5.336089],
              [105.821823, -5.593658],
              [105.809662, -5.68149],
              [105.789368, -5.721141],
              [105.791489, -5.787411],
              [105.774124, -5.837541],
              [105.710793, -5.856688],
              [105.603989, -5.830646],
              [105.581818, -5.802879],
              [105.587814, -5.742107],
              [105.552834, -5.69501],
              [105.407997, -5.623131],
              [105.368057, -5.549149],
              [105.292465, -5.446177],
              [105.250793, -5.471121],
              [105.265236, -5.517141],
              [105.24472, -5.589002],
              [105.205086, -5.576796],
              [105.178093, -5.668644],
              [105.194298, -5.706558],
              [105.157707, -5.747686],
              [105.185173, -5.804526],
              [105.148201, -5.810163],
              [104.986458, -5.715514],
              [104.927589, -5.696409],
              [104.824852, -5.621506],
              [104.775848, -5.605471],
              [104.709152, -5.522681],
              [104.595329, -5.497139],
              [104.529739, -5.541902],
              [104.570694, -5.609401],
              [104.639206, -5.688042],
              [104.646019, -5.748027],
              [104.709442, -5.845267],
              [104.695053, -5.875686],
              [104.721489, -5.934022],
              [104.592552, -5.937325],
              [104.561798, -5.865867],
              [104.45295, -5.764961],
              [104.412384, -5.706299],
              [104.29969, -5.641016],
              [104.318222, -5.60884],
              [104.297874, -5.573801],
              [104.166809, -5.476431],
              [104.119209, -5.456481],
              [103.998734, -5.32204],
              [103.996071, -5.26727],
              [103.905228, -5.207043],
              [103.933052, -5.170211],
              [103.908859, -5.127353],
              [103.864105, -5.110247],
              [103.835388, -5.065404],
              [103.770218, -5.028835],
              [103.715195, -4.967688],
              [103.65519, -4.954104],
              [103.598244, -4.922405],
              [103.603287, -4.89334],
              [103.660652, -4.85998],
              [103.663071, -4.858659],
              [103.71843, -4.802312],
              [103.746193, -4.796777],
              [103.772308, -4.802989],
              [103.77594, -4.798273],
              [103.777539, -4.79652]
            ]
          ]
        },
        "id": 18,
        "properties": {
          "ID": 30,
          "kode": 18,
          "Propinsi": "LAMPUNG",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [103.777253, -4.79643],
                [103.777539, -4.79652],
                [103.77594, -4.798273],
                [103.772308, -4.802989],
                [103.746193, -4.796777],
                [103.71843, -4.802312],
                [103.663071, -4.858659],
                [103.660652, -4.85998],
                [103.603287, -4.89334],
                [103.598244, -4.922405],
                [103.535866, -4.914454],
                [103.500389, -4.875894],
                [103.429695, -4.87312],
                [103.401039, -4.816718],
                [103.309036, -4.781792],
                [103.277702, -4.704457],
                [103.244278, -4.672636],
                [103.113358, -4.578361],
                [103.040985, -4.538898],
                [102.900131, -4.49004],
                [102.883217, -4.442],
                [102.792618, -4.35461],
                [102.692581, -4.272606],
                [102.347687, -4.018148],
                [102.277046, -3.951083],
                [102.307587, -3.900745],
                [102.300446, -3.841087],
                [102.254799, -3.797299],
                [102.265976, -3.764632],
                [102.214775, -3.653597],
                [102.087273, -3.550713],
                [101.933876, -3.477239],
                [101.899567, -3.425596],
                [101.799576, -3.372551],
                [101.620651, -3.239907],
                [101.523598, -3.11352],
                [101.48774, -3.022807],
                [101.405663, -2.925677],
                [101.325256, -2.739109],
                [101.292618, -2.709787],
                [101.154854, -2.639229],
                [101.027755, -2.482182],
                [101.155373, -2.390157],
                [101.244644, -2.344946],
                [101.294159, -2.280203],
                [101.330421, -2.309457],
                [101.559204, -2.451228],
                [101.546486, -2.560535],
                [101.601181, -2.537571],
                [101.674995, -2.532605],
                [101.733284, -2.607907],
                [101.867928, -2.728184],
                [101.915871, -2.738421],
                [101.989708, -2.723145],
                [102.066829, -2.766654],
                [102.070121, -2.779601],
                [102.070271, -2.779745],
                [102.12852, -2.834456],
                [102.132041, -2.87452],
                [102.223949, -2.929184],
                [102.247734, -3.027864],
                [102.322517, -3.068047],
                [102.389915, -3.044319],
                [102.441376, -3.057934],
                [102.485085, -3.1067],
                [102.491776, -3.244821],
                [102.512752, -3.274333],
                [102.596323, -3.333274],
                [102.640813, -3.349581],
                [102.686779, -3.317826],
                [102.742687, -3.315027],
                [102.773751, -3.280437],
                [102.816825, -3.333799],
                [102.881726, -3.345414],
                [102.888499, -3.375246],
                [102.989653, -3.370434],
                [102.998046, -3.466969],
                [102.981817, -3.526327],
                [102.952011, -3.531126],
                [102.921399, -3.593359],
                [102.847676, -3.536874],
                [102.813889, -3.534643],
                [102.809992, -3.608159],
                [102.7655, -3.644093],
                [102.735313, -3.702301],
                [102.728362, -3.75001],
                [102.655215, -3.772596],
                [102.63594, -3.812298],
                [102.686069, -3.858048],
                [102.746036, -3.892661],
                [102.79337, -3.954387],
                [102.886803, -3.989239],
                [102.914916, -3.97235],
                [102.956093, -4.012947],
                [103.128459, -4.025171],
                [103.155336, -4.110564],
                [103.185598, -4.163748],
                [103.200666, -4.247289],
                [103.307721, -4.256566],
                [103.329801, -4.292813],
                [103.453716, -4.3315],
                [103.468517, -4.348306],
                [103.428073, -4.40753],
                [103.405136, -4.499798],
                [103.476627, -4.564854],
                [103.521138, -4.57475],
                [103.590699, -4.52343],
                [103.630332, -4.554627],
                [103.638473, -4.598905],
                [103.693762, -4.652768],
                [103.704562, -4.697992],
                [103.762881, -4.7421],
                [103.777253, -4.79643]
              ]
            ],
            [
              [
                [102.156013, -5.285942],
                [102.169739, -5.277181],
                [102.400116, -5.390039],
                [102.370514, -5.503264],
                [102.294418, -5.47437],
                [102.273949, -5.497576],
                [102.215576, -5.409292],
                [102.104958, -5.351578],
                [102.156013, -5.285942]
              ]
            ]
          ]
        },
        "id": 17,
        "properties": {
          "ID": 31,
          "kode": 17,
          "Propinsi": "BENGKULU",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [98.25574, 4.290953],
                [98.145401, 4.287369],
                [98.090927, 4.19672],
                [98.059311, 4.190727],
                [98.023132, 3.969775],
                [97.95816, 3.928891],
                [97.944946, 3.89686],
                [97.89991, 3.89141],
                [97.908562, 3.820508],
                [97.803185, 3.731739],
                [97.843933, 3.660525],
                [97.869896, 3.57131],
                [97.906899, 3.553616],
                [97.949715, 3.391531],
                [98.003082, 3.369784],
                [98.029823, 3.329871],
                [97.984924, 3.275573],
                [97.872261, 3.249049],
                [97.925278, 3.226437],
                [97.942329, 3.164997],
                [97.978615, 3.127329],
                [97.983467, 3.083911],
                [97.939224, 3.071936],
                [97.958275, 2.961847],
                [97.954605, 2.900333],
                [98.006783, 2.889557],
                [98.042084, 2.828039],
                [98.088196, 2.821929],
                [98.086838, 2.7349],
                [98.12426, 2.655123],
                [98.085129, 2.63422],
                [98.075233, 2.57553],
                [98.083992, 2.50935],
                [98.109344, 2.47093],
                [98.112091, 2.424448],
                [98.155571, 2.413039],
                [98.18766, 2.319383],
                [98.153148, 2.146027],
                [98.100174, 2.193217],
                [97.949188, 2.268416],
                [97.847656, 2.247731],
                [97.807373, 2.267888],
                [97.786621, 2.235493],
                [97.660049, 2.402731],
                [97.650513, 2.668232],
                [97.614929, 2.833094],
                [97.58123, 2.890189],
                [97.540428, 2.885374],
                [97.491165, 2.921352],
                [97.446991, 2.924328],
                [97.391426, 2.965523],
                [97.31015, 3.068522],
                [97.265831, 3.209728],
                [97.193413, 3.258642],
                [97.169327, 3.256543],
                [97.124481, 3.346619],
                [97.084641, 3.394672],
                [97.001579, 3.545805],
                [96.904762, 3.60113],
                [96.863861, 3.693694],
                [96.768112, 3.739015],
                [96.669891, 3.745795],
                [96.527695, 3.732057],
                [96.416214, 3.818041],
                [96.358871, 3.885816],
                [96.287979, 3.992574],
                [96.208038, 4.088209],
                [96.143906, 4.141568],
                [96.124184, 4.13581],
                [96.054756, 4.206528],
                [96.014725, 4.214571],
                [95.882767, 4.362245],
                [95.804428, 4.431233],
                [95.730766, 4.513642],
                [95.594887, 4.626799],
                [95.550133, 4.654496],
                [95.503654, 4.70863],
                [95.492639, 4.750018],
                [95.423939, 4.817422],
                [95.380723, 4.964445],
                [95.300005, 5.116235],
                [95.308858, 5.162571],
                [95.268826, 5.198396],
                [95.213608, 5.311016],
                [95.251068, 5.368226],
                [95.243118, 5.460232],
                [95.195138, 5.528332],
                [95.236732, 5.557039],
                [95.267699, 5.550661],
                [95.325329, 5.593556],
                [95.359057, 5.597326],
                [95.440803, 5.657204],
                [95.487781, 5.613286],
                [95.606527, 5.628805],
                [95.684627, 5.585057],
                [95.74082, 5.572229],
                [95.890839, 5.499509],
                [95.904404, 5.441279],
                [95.945007, 5.401387],
                [96.05159, 5.325978],
                [96.085419, 5.287781],
                [96.186943, 5.260334],
                [96.264411, 5.260112],
                [96.392037, 5.209993],
                [96.460292, 5.234842],
                [96.537407, 5.195907],
                [96.635086, 5.209005],
                [96.717148, 5.242064],
                [96.824829, 5.271552],
                [96.911911, 5.249453],
                [97.010216, 5.258295],
                [97.143356, 5.20007],
                [97.156075, 5.147558],
                [97.192665, 5.1418],
                [97.428207, 5.221479],
                [97.494469, 5.248788],
                [97.553452, 5.213005],
                [97.577263, 5.168141],
                [97.647247, 5.083181],
                [97.822609, 4.919947],
                [97.900314, 4.886347],
                [97.960312, 4.727588],
                [97.959435, 4.672001],
                [98.012398, 4.615899],
                [97.970482, 4.559347],
                [97.994522, 4.527931],
                [98.088074, 4.522916],
                [98.169907, 4.50053],
                [98.286339, 4.41748],
                [98.283165, 4.34913],
                [98.25574, 4.290953]
              ]
            ],
            [
              [
                [96.000046, 2.587204],
                [95.937935, 2.571712],
                [95.885063, 2.602638],
                [95.862289, 2.637353],
                [95.813866, 2.644832],
                [95.757629, 2.694828],
                [95.745186, 2.73048],
                [95.690453, 2.772583],
                [95.718544, 2.821893],
                [95.762878, 2.857512],
                [95.77079, 2.901087],
                [95.804932, 2.927299],
                [95.903023, 2.889753],
                [95.866943, 2.850055],
                [95.998611, 2.782477],
                [96.10466, 2.737498],
                [96.093048, 2.651589],
                [96.170616, 2.62701],
                [96.197578, 2.642474],
                [96.245384, 2.584614],
                [96.335121, 2.548106],
                [96.36515, 2.485914],
                [96.424355, 2.488191],
                [96.462692, 2.455348],
                [96.490204, 2.362194],
                [96.424072, 2.32803],
                [96.379951, 2.350147],
                [96.308983, 2.357318],
                [96.300812, 2.422866],
                [96.221466, 2.440987],
                [96.209869, 2.463408],
                [96.123451, 2.517214],
                [96.068642, 2.567691],
                [96.000046, 2.587204]
              ]
            ],
            [
              [
                [97.293701, 2.079459],
                [97.256218, 2.133173],
                [97.133141, 2.200681],
                [97.162033, 2.235634],
                [97.215462, 2.202798],
                [97.271385, 2.222774],
                [97.336807, 2.15933],
                [97.329483, 2.118278],
                [97.293701, 2.079459]
              ]
            ],
            [
              [
                [95.32904, 5.771702],
                [95.286805, 5.779638],
                [95.287225, 5.841259],
                [95.32245, 5.877151],
                [95.375166, 5.846886],
                [95.32904, 5.771702]
              ]
            ],
            [
              [
                [97.111031, 2.111826],
                [97.153557, 2.066705],
                [97.104469, 2.014735],
                [97.069054, 2.070064],
                [97.111031, 2.111826]
              ]
            ],
            [
              [
                [96.629654, 2.119773],
                [96.679573, 2.087339],
                [96.639084, 2.057627],
                [96.607002, 2.083901],
                [96.629654, 2.119773]
              ]
            ]
          ]
        },
        "id": 11,
        "properties": {
          "ID": 32,
          "kode": 11,
          "Propinsi": "ACEH",
          "SUMBER": "Peta Dasar BAKOSURTANAL Skala 1 : 250.000"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [110.1698084443043, -2.8529116617437467],
                [110.1520077259758, -2.8829539688505292],
                [110.14665837228614, -2.8986491647271464],
                [110.17199421596035, -2.9149818962974337],
                [110.20061433068685, -2.893346489448902],
                [110.20170740717117, -2.883666436956446],
                [110.1698084443043, -2.8529116617437467]
              ]
            ],
            [
              [
                [110.11308256590775, -2.6749835594436604],
                [110.09849505060618, -2.674417509960051],
                [110.0915997928962, -2.6908275193744657],
                [110.07560741065356, -2.701345056529078],
                [110.06685466081399, -2.7308944416251393],
                [110.0450861489067, -2.7484538772445717],
                [110.0824536238357, -2.7612889178492424],
                [110.10234963752583, -2.7581592285654892],
                [110.1217303288916, -2.7485678636159605],
                [110.12500395107156, -2.730988079935912],
                [110.11759474700006, -2.7025734081362316],
                [110.12776800658827, -2.6940318937568577],
                [110.11308256590775, -2.6749835594436604]
              ]
            ],
            [
              [
                [108.74493275774063, -1.69992655876365],
                [108.72725208001953, -1.7021835702155386],
                [108.71827301488781, -1.709403280836682],
                [108.70383397136072, -1.7047872235514774],
                [108.68542567555454, -1.7115319248583774],
                [108.69568155675847, -1.7210868771576315],
                [108.71577737822349, -1.726707416014392],
                [108.75322705298618, -1.7163095291613786],
                [108.77291881804905, -1.7165008671212831],
                [108.78422049028347, -1.7038725033133346],
                [108.74493275774063, -1.69992655876365]
              ]
            ],
            [
              [
                [108.89750667559264, -1.5347558307169606],
                [108.89150763996565, -1.550600020874242],
                [108.87262335098694, -1.5683958864609622],
                [108.8581914381844, -1.5721846645905657],
                [108.84266619888604, -1.5682045350112617],
                [108.82964451927921, -1.5552658682252058],
                [108.82008768110165, -1.5709693378645966],
                [108.79931447318444, -1.5718543418043964],
                [108.81710554808262, -1.5909848453742939],
                [108.82670067669494, -1.583294736263042],
                [108.83329955652937, -1.6018192415647263],
                [108.82906220285844, -1.607981641739343],
                [108.83894017275082, -1.633708330774823],
                [108.8282540918529, -1.6388364071743808],
                [108.83064475425209, -1.6655413382621305],
                [108.85768795273691, -1.6770540714881577],
                [108.87552819357131, -1.6738969232043814],
                [108.89679102208805, -1.6607516506680895],
                [108.92197287934499, -1.6591746417962554],
                [108.95751544368284, -1.639416529249388],
                [108.98264949567772, -1.6008401694382428],
                [108.95291481156937, -1.5737200573347536],
                [108.95250467215294, -1.5657297536251917],
                [108.93713154688345, -1.5505602024912832],
                [108.91928964320255, -1.5494359168340566],
                [108.89750667559264, -1.5347558307169606]
              ]
            ],
            [
              [
                [109.1458030920391, -1.2878844961725235],
                [109.13903318273526, -1.3118712704427367],
                [109.14408358918638, -1.3241005097306697],
                [109.1634071915887, -1.2922062143452422],
                [109.1458030920391, -1.2878844961725235]
              ]
            ],
            [
              [
                [109.15803903937024, -1.266228734968081],
                [109.16208293268124, -1.2883041845904017],
                [109.17841317312951, -1.2932011145397837],
                [109.17200766462207, -1.2731755714953579],
                [109.15803903937024, -1.266228734968081]
              ]
            ],
            [
              [
                [109.26090863102854, -1.1825831988554683],
                [109.24312116600908, -1.1971195328401336],
                [109.22700150721386, -1.200931429841546],
                [109.22624541029751, -1.2228432658040447],
                [109.21703689573621, -1.237078390755073],
                [109.23263982027157, -1.2439281337763077],
                [109.24896333289155, -1.2378876843690705],
                [109.25306057758365, -1.2253778458108968],
                [109.27091419493422, -1.2089877797621966],
                [109.26090863102854, -1.1825831988554683]
              ]
            ],
            [
              [
                [109.49809184616186, -0.9790335733407005],
                [109.4876342119494, -0.9738122597279357],
                [109.4723895440423, -1.0026339449005945],
                [109.45889269773988, -1.00262757680116],
                [109.46088539484515, -1.0165874849244574],
                [109.45089392151732, -1.031205595162021],
                [109.45551239528089, -1.045380586944475],
                [109.45044644404823, -1.0927715375763682],
                [109.42490284618947, -1.20563086777031],
                [109.40962078806899, -1.2386310801601894],
                [109.40210319500653, -1.247097130628049],
                [109.37958957769689, -1.2553938927139257],
                [109.40531393209233, -1.2798595119069205],
                [109.43118343167009, -1.294036339205661],
                [109.47175799963202, -1.3071947589309048],
                [109.50358639359723, -1.307665746476971],
                [109.51617250995344, -1.2925067264042696],
                [109.5422228011414, -1.2726505895529954],
                [109.59054067221433, -1.246979034354922],
                [109.65288062064417, -1.220439939151845],
                [109.6837151369441, -1.2145111459725513],
                [109.6894515543458, -1.192919503732071],
                [109.73309645903441, -1.1715939304154972],
                [109.77999977297122, -1.1447058568897432],
                [109.77743800735823, -1.1301540078011953],
                [109.76747572872199, -1.1185475022653577],
                [109.76495515026005, -1.0962417677433223],
                [109.7694708548961, -1.07715540289945],
                [109.75923127120626, -1.0453695234846805],
                [109.75585735573242, -1.0150607247725816],
                [109.76029311153792, -1.004532350787315],
                [109.73750178088255, -1.0021625265792977],
                [109.71767830111276, -0.9892950051603293],
                [109.70030887695316, -0.9940027015633746],
                [109.68570468192559, -1.0191685293041246],
                [109.67543591613469, -1.0207979956309714],
                [109.66228831705223, -1.0108588475576994],
                [109.64229225018727, -1.0116573636963153],
                [109.63772858380713, -0.9869549629011658],
                [109.6130752050567, -0.9860736317926353],
                [109.59956879086707, -0.9814729709008247],
                [109.55233027981865, -1.0032976985299342],
                [109.52869853990575, -0.9985461754656058],
                [109.49809184616186, -0.9790335733407005]
              ]
            ],
            [
              [
                [109.58480012512575, -0.7388330473072529],
                [109.57476399955897, -0.7384299999445147],
                [109.56882876813319, -0.7593525348915477],
                [109.58355293092308, -0.7675894497932063],
                [109.60054975876443, -0.7688873306857431],
                [109.60392211481383, -0.7790948166723979],
                [109.61801757235315, -0.7843403139542602],
                [109.62319677071446, -0.7942613209032174],
                [109.6354860226619, -0.7910927907996097],
                [109.63277503254176, -0.782833363169118],
                [109.6316694815614, -0.7608716876782182],
                [109.62604332194184, -0.751251112748605],
                [109.59874200004333, -0.7482869994454404],
                [109.58480012512575, -0.7388330473072529]
              ]
            ],
            [
              [
                [109.69084138732023, -0.7154599686026017],
                [109.6682583882141, -0.7165931458569617],
                [109.66506821502617, -0.7245600312203919],
                [109.66164156600553, -0.7256123459329729],
                [109.65034669984038, -0.7194444356121608],
                [109.64223718829567, -0.7323634953789337],
                [109.62439742590055, -0.7285372101241023],
                [109.60590694734782, -0.7342441990105044],
                [109.607765477295, -0.7448537125287658],
                [109.63935466688446, -0.7500008905286109],
                [109.65920400036441, -0.7482809991687418],
                [109.6728013081339, -0.7381516462813806],
                [109.69084138732023, -0.7154599686026017]
              ]
            ],
            [
              [
                [109.57146067277188, -0.7318948613587395],
                [109.56868985255471, -0.7122966689560712],
                [109.55319372431097, -0.728399973579883],
                [109.56481903916739, -0.7422176323378267],
                [109.57146067277188, -0.7318948613587395]
              ]
            ],
            [
              [
                [109.57556056686673, -0.7108398814553425],
                [109.57741723431798, -0.7289646506980034],
                [109.59917425468814, -0.7437371358601013],
                [109.6063254900294, -0.7415322679997303],
                [109.60418881955843, -0.7318514052181513],
                [109.60670792493084, -0.726641827758101],
                [109.59422744833813, -0.7159790644822124],
                [109.57556056686673, -0.7108398814553425]
              ]
            ],
            [
              [
                [109.6571311260007, -0.6997473429654519],
                [109.63231606623356, -0.7055216236240085],
                [109.60279172365915, -0.704509805383168],
                [109.59497558625435, -0.714850248939797],
                [109.60690527575792, -0.7249118738837979],
                [109.60864378578214, -0.7301604423504955],
                [109.62654063084551, -0.7269110317187142],
                [109.64181307791125, -0.7304622566277885],
                [109.64727032056891, -0.7178056037455463],
                [109.66041637011944, -0.7241271461508063],
                [109.6642336153891, -0.7240149782086291],
                [109.66648905482606, -0.7176831961225503],
                [109.66969347777183, -0.7146676469942577],
                [109.68731368777651, -0.7142506043818457],
                [109.67999019359263, -0.7021514169542797],
                [109.6571311260007, -0.6997473429654519]
              ]
            ],
            [
              [
                [109.52579318111049, -0.6994373286697169],
                [109.49122647562143, -0.7011700245769248],
                [109.47646754594058, -0.7319683818353155],
                [109.52872157963725, -0.7272280895047061],
                [109.54217870468352, -0.7312369838996347],
                [109.55923906063418, -0.7152471809126268],
                [109.54955706200889, -0.7066211608378694],
                [109.52579318111049, -0.6994373286697169]
              ]
            ],
            [
              [
                [109.66705576711001, -0.6847339294272388],
                [109.66092589811177, -0.6810303899404744],
                [109.65756977572465, -0.677001878633746],
                [109.6561025012295, -0.6718472722337765],
                [109.64740972807652, -0.6843563589587234],
                [109.61762335273562, -0.6906975785303757],
                [109.60760791966982, -0.6867386396764346],
                [109.59784088384976, -0.6952811307195361],
                [109.57377764472261, -0.692111515134286],
                [109.56899755379413, -0.7078555199162793],
                [109.60294097514594, -0.7020514276312042],
                [109.64432977759719, -0.7025184923344909],
                [109.65523002034922, -0.6984778203950555],
                [109.67188534053611, -0.6981902378890936],
                [109.66705576711001, -0.6847339294272388]
              ]
            ],
            [
              [
                [109.44775819328606, -0.6716385198017747],
                [109.42495122072233, -0.6815807615428753],
                [109.43649534658306, -0.6957794369714065],
                [109.45468665936193, -0.7051461926426441],
                [109.4581074196218, -0.7187721878860884],
                [109.48299892625948, -0.7165583429930962],
                [109.48961186699415, -0.6967661785162704],
                [109.44775819328606, -0.6716385198017747]
              ]
            ],
            [
              [
                [109.25225834094971, -0.6617833989846531],
                [109.24498687614346, -0.6671423880107454],
                [109.24125372467972, -0.6935403562024476],
                [109.24120164314138, -0.7631600944627621],
                [109.23686494316263, -0.7952608984716676],
                [109.24440706793189, -0.8304079139940086],
                [109.25607530832889, -0.8528049764684624],
                [109.28461193269459, -0.8858908205049261],
                [109.30733287970867, -0.9064631477560283],
                [109.32566415577622, -0.9172558019136545],
                [109.3551914571201, -0.9187411437886794],
                [109.37322519158937, -0.9264997227839444],
                [109.40599808864096, -0.9325733778590575],
                [109.43231133709861, -0.896104444364596],
                [109.41607988537845, -0.8667978996033412],
                [109.43956932826194, -0.8581599886924778],
                [109.46561325224985, -0.8664624704666153],
                [109.51598654454347, -0.8779857032775453],
                [109.5365787243287, -0.8855821147227516],
                [109.55716488764949, -0.8879473487910445],
                [109.57264140527667, -0.900323757635249],
                [109.58304300273244, -0.8979970298390185],
                [109.59364403384512, -0.9088105203891814],
                [109.59912444124001, -0.9314772158008395],
                [109.60860510504165, -0.9440445417221781],
                [109.60923890495155, -0.9641823714192697],
                [109.62227307973887, -0.9784504034632846],
                [109.64303385632746, -0.9813294346056978],
                [109.64826945926819, -0.9976883778163597],
                [109.6658082518494, -1.002339003242355],
                [109.68338367291824, -1.0139399554645365],
                [109.69825888763762, -0.9871515124323196],
                [109.72147274407195, -0.9735657366693431],
                [109.71967399475909, -0.9189290859085304],
                [109.7273553642587, -0.9059680305001621],
                [109.74389219426939, -0.901343020463969],
                [109.73713599872741, -0.8787490262465099],
                [109.71776446066139, -0.8723095575400066],
                [109.713083959757, -0.8633814623845524],
                [109.71805682486263, -0.8511540801965793],
                [109.71247556839285, -0.8339453891683206],
                [109.69482541366199, -0.8280404397144707],
                [109.67610230837488, -0.8321144684079513],
                [109.65949160535337, -0.8111810822410916],
                [109.6181762946004, -0.7971748248582458],
                [109.59394788688553, -0.7736967943715172],
                [109.58337497757321, -0.7741299825126475],
                [109.56017813361404, -0.7632662342492722],
                [109.55816286892133, -0.7482678933485545],
                [109.53474699957445, -0.73841699934502],
                [109.50935499953368, -0.7394109993181246],
                [109.5044279998524, -0.7453309998257396],
                [109.46640829430737, -0.7549303723387766],
                [109.45399558073154, -0.7495745857984275],
                [109.42169915978843, -0.7234698649422739],
                [109.39104377764829, -0.7069521886925259],
                [109.36865398259528, -0.7146322262961462],
                [109.3560763648324, -0.7144045071622145],
                [109.30640619758799, -0.7023711006457347],
                [109.2882363633176, -0.6921051695179017],
                [109.2650330262532, -0.6653447331728444],
                [109.25225834094971, -0.6617833989846531]
              ]
            ],
            [
              [
                [109.37635654821617, -0.654273868344319],
                [109.37000165175488, -0.650280173391252],
                [109.35202972768985, -0.6624833889971455],
                [109.36206600614133, -0.6743374176376733],
                [109.39968821224124, -0.6747264733480165],
                [109.3874291280519, -0.6584761223750775],
                [109.37635654821617, -0.654273868344319]
              ]
            ],
            [
              [
                [109.45237799984488, -0.6319189993622558],
                [109.43184399990076, -0.6342459996531034],
                [109.42737599980262, -0.6425469993445176],
                [109.47340700013302, -0.6693239994029909],
                [109.48619199976105, -0.663556999655782],
                [109.47569999975525, -0.6466719998128951],
                [109.45237799984488, -0.6319189993622558]
              ]
            ],
            [
              [
                [109.48295633886403, -0.6284685398911734],
                [109.47380893053776, -0.6235676969915858],
                [109.4605650311513, -0.6306704363914832],
                [109.4962670003219, -0.6670309997807635],
                [109.48429049121324, -0.682366600837895],
                [109.50344219540108, -0.6948795213727408],
                [109.5238977681675, -0.6950808894713987],
                [109.54671687798157, -0.7031294746454932],
                [109.57471253225759, -0.6873070669674917],
                [109.58093535068593, -0.679439478449126],
                [109.58374948225287, -0.6532046859474576],
                [109.56866624535098, -0.6571443972960651],
                [109.54487096911998, -0.6545068835857819],
                [109.54101416267355, -0.6460918759392484],
                [109.51730637788711, -0.6448187264030594],
                [109.50148670055086, -0.6375486942168322],
                [109.49245738939709, -0.625983114129383],
                [109.48295633886403, -0.6284685398911734]
              ]
            ],
            [
              [
                [109.36401040875342, -0.6174014036517974],
                [109.35116215980588, -0.63558073116576],
                [109.36113875385047, -0.6531102850116213],
                [109.37317211000493, -0.6484602126686241],
                [109.38727132760977, -0.6558363126956124],
                [109.4047805963475, -0.6722647456214759],
                [109.42504995009517, -0.6677997834332245],
                [109.43480113896146, -0.6741892894041257],
                [109.44930301452257, -0.6601658918423823],
                [109.38561590055343, -0.6246606808456932],
                [109.36401040875342, -0.6174014036517974]
              ]
            ],
            [
              [
                [109.12715201435407, -0.20221439118114404],
                [109.09096501922033, -0.2066026043266902],
                [109.07272238303159, -0.21997423442331865],
                [109.06099539262334, -0.2163955649033369],
                [109.05597610721162, -0.22744881650965],
                [109.05349178491798, -0.25621498023707545],
                [109.06097746014171, -0.2800068363463879],
                [109.09357267723732, -0.3314435023157216],
                [109.10228134320948, -0.3332130407491718],
                [109.10011078538452, -0.3076496766911987],
                [109.08333044864133, -0.28782239458780623],
                [109.08325734455087, -0.2700634992310711],
                [109.11698666700113, -0.25740148189282763],
                [109.13242446072036, -0.23639026569816224],
                [109.15477070520393, -0.23300002123392005],
                [109.16117182771774, -0.22986734350303095],
                [109.17172629013623, -0.23111348459957526],
                [109.1751751443175, -0.22628535998131838],
                [109.1780752321863, -0.22448815120714016],
                [109.19309495716658, -0.21883212240447847],
                [109.17201822356225, -0.20715555698330945],
                [109.15856364000007, -0.2109009356296383],
                [109.12715201435407, -0.20221439118114404]
              ]
            ],
            [
              [
                [109.12653292015818, -0.17620631057275205],
                [109.10270621531964, -0.16857925832607634],
                [109.08600632383286, -0.1870460055686749],
                [109.09220346931471, -0.1931629935772321],
                [109.12052314212934, -0.19780699729506068],
                [109.13465110206675, -0.19649627947967474],
                [109.15853986102582, -0.20783856959428704],
                [109.17270234683588, -0.20165444989288517],
                [109.1604014540639, -0.18845796546270321],
                [109.12653292015818, -0.17620631057275205]
              ]
            ],
            [
              [
                [109.16048246229587, -0.18540466819081303],
                [109.13276748732972, -0.14814958423585267],
                [109.12601883889835, -0.16692985043221142],
                [109.14311935677296, -0.1802775153949483],
                [109.16048246229587, -0.18540466819081303]
              ]
            ],
            [
              [
                [109.1253738568181, -0.15029425327713852],
                [109.11238102476455, -0.11698189879552956],
                [109.09365701116218, -0.13040273799589386],
                [109.09771040131403, -0.15087386273853554],
                [109.11319605965048, -0.1663712383419238],
                [109.12358360070266, -0.16878505648514874],
                [109.1253738568181, -0.15029425327713852]
              ]
            ],
            [
              [
                [109.17795838417214, -0.11744500828046966],
                [109.16578890933079, -0.0933292154708738],
                [109.16426640207288, -0.0614082767705213],
                [109.14148546398303, -0.0765819288129137],
                [109.1259313483531, -0.10029487390926306],
                [109.12397387679306, -0.1159154062709149],
                [109.1316017663085, -0.1405704946325841],
                [109.14366025311824, -0.1604112090100216],
                [109.17627888855992, -0.19448412374327972],
                [109.18653913057648, -0.13777174417060678],
                [109.17795838417214, -0.11744500828046966]
              ]
            ],
            [
              [
                [109.190506945739, 0.05125720275776757],
                [109.17812537208829, 0.04703745241653223],
                [109.17839766072353, 0.03633944470965389],
                [109.21825845026734, 0.01981324108822946],
                [109.23901140455274, 0.018879231291521137],
                [109.22057027359932, 0.03677668249792987],
                [109.190506945739, 0.05125720275776757]
              ]
            ],
            [
              [
                [108.85960840370495, 0.5108399917722295],
                [108.83973789298943, 0.49514859553130464],
                [108.85439621371432, 0.4868575108654909],
                [108.85960840370495, 0.5108399917722295]
              ]
            ],
            [
              [
                [108.70052257225899, 0.8043258193811198],
                [108.69139626202792, 0.7987302690867182],
                [108.69490711097444, 0.7756449392625768],
                [108.70613479238682, 0.7582489644182147],
                [108.7145039813737, 0.7318043762709863],
                [108.72831292839896, 0.7328827200615251],
                [108.72720402204806, 0.7524272031529335],
                [108.71639217995522, 0.7604667771201434],
                [108.70668924601193, 0.7800112602115519],
                [108.71098625980812, 0.7999715826225042],
                [108.70052257225899, 0.8043258193811198]
              ]
            ],
            [
              [
                [114.20538782878532, 1.4103666938678998],
                [114.20213570040232, 1.4211086982078882],
                [114.17785225417822, 1.4363971657966204],
                [114.15868615094519, 1.4420487474517927],
                [114.14253920851775, 1.4661263064838863],
                [114.12030430460004, 1.4633618831444437],
                [114.10449466819443, 1.4564252305399918],
                [114.09590221801307, 1.4668420283369983],
                [114.07786455530504, 1.4659080032517977],
                [114.04679191111791, 1.452547819726135],
                [114.00896792053322, 1.450835276726707],
                [113.99611817871107, 1.44399807924799],
                [113.97469429510568, 1.4497532565076199],
                [113.94641904520279, 1.4367041053102412],
                [113.93617723029502, 1.417289741528407],
                [113.9237535440908, 1.411153267009695],
                [113.9128635482142, 1.4149529701035135],
                [113.90344669760702, 1.4061031060735445],
                [113.88284905893693, 1.399426747846178],
                [113.86750184493428, 1.387383548611922],
                [113.84913450155602, 1.3889270901131567],
                [113.81469639552233, 1.3662670325975341],
                [113.82932224262743, 1.3559680675722916],
                [113.82945347979432, 1.334397561198557],
                [113.8036994315836, 1.300375142498865],
                [113.78815035755304, 1.2974002211433344],
                [113.75561446423615, 1.2767026767871243],
                [113.70327065211893, 1.2654556743399326],
                [113.68985284363384, 1.2424393306106936],
                [113.66460019714145, 1.2243402208245584],
                [113.63255931855781, 1.2192509690570432],
                [113.61885308040542, 1.2299518797754558],
                [113.62810304471975, 1.2414943077161524],
                [113.62516653970408, 1.2531504720167277],
                [113.59725884452007, 1.2627932736907042],
                [113.5926120699911, 1.2882831361578724],
                [113.5746625057701, 1.3063253665225147],
                [113.53766127434835, 1.3226385648179644],
                [113.5148206329659, 1.3142866158347033],
                [113.49984286315771, 1.3180892210407933],
                [113.47898135530534, 1.3050047120978547],
                [113.46597498215976, 1.3039515295395177],
                [113.45286047093407, 1.2857431782051663],
                [113.4275815318623, 1.2861182458603935],
                [113.41136122274224, 1.298713902224847],
                [113.39603553221411, 1.3189375074600207],
                [113.36976537297369, 1.3236420436454637],
                [113.36505418090576, 1.3466046564797693],
                [113.3558837463379, 1.35703258698436],
                [113.33111257438588, 1.3670950105263842],
                [113.32799190889955, 1.3753274620927414],
                [113.30799447686366, 1.3707815141556239],
                [113.26934597253864, 1.3872354887268443],
                [113.24996649435832, 1.3821264671627773],
                [113.24107187107006, 1.392587439658655],
                [113.22313083152267, 1.3897629631902646],
                [113.21958627058996, 1.381413624039567],
                [113.18308682489715, 1.377035821446043],
                [113.16325227537232, 1.3907233258208862],
                [113.15036643476321, 1.3914616512342377],
                [113.13337037404358, 1.401659113377434],
                [113.12035082133343, 1.432925184649605],
                [113.09707364183521, 1.4432343165105408],
                [113.08843690633819, 1.428949575062859],
                [113.07424348115183, 1.431421136875997],
                [113.0572447872172, 1.4210838454431496],
                [113.04366850469785, 1.4240626122997924],
                [113.03759507265454, 1.4153712681695652],
                [113.01500574951581, 1.4052471736181928],
                [113.00622995095159, 1.4089741854103863],
                [112.9810907053718, 1.4081015831216064],
                [112.97514439609711, 1.4347635661856657],
                [112.98589586580158, 1.4522663607040158],
                [113.00717758277926, 1.4581869394757518],
                [113.04132132002292, 1.4748637129899862],
                [113.03174462301604, 1.491441793104343],
                [113.04689296723666, 1.4980987430625987],
                [113.04682722499649, 1.510975024777622],
                [113.06823790655426, 1.5405038333852303],
                [113.06388995066072, 1.552588844799402],
                [113.03427104167531, 1.5664317646143218],
                [113.0115326900816, 1.570537845145509],
                [112.989598027527, 1.5789979771749358],
                [112.9837104161029, 1.5634062644875826],
                [112.95784049384383, 1.5698187535326724],
                [112.93337282509583, 1.567360995413651],
                [112.91550800399125, 1.5830018884264518],
                [112.88449010333568, 1.586636229578403],
                [112.87517457934348, 1.57412161202285],
                [112.854339429721, 1.5592806084134168],
                [112.84084744605286, 1.5381646544752812],
                [112.83044106330453, 1.547130465398709],
                [112.81203811306852, 1.5404879360694395],
                [112.79657607722424, 1.5549559566521225],
                [112.77457699623142, 1.5637564748814725],
                [112.74955828588622, 1.5576420427461244],
                [112.73484332855685, 1.5674728485931269],
                [112.70560207187816, 1.5608476357098766],
                [112.69408519457613, 1.5540134986241014],
                [112.67690989987204, 1.5545601524291328],
                [112.65773497968564, 1.5713798363104274],
                [112.63254380185504, 1.5655852093899512],
                [112.60139371974446, 1.5736903376389932],
                [112.57739101218556, 1.5721081091019755],
                [112.543796199535, 1.5739225848594742],
                [112.52503588659692, 1.5803805517211345],
                [112.51653419060051, 1.5758247741940181],
                [112.50102712929868, 1.583720282165359],
                [112.48598544017602, 1.5796497824115932],
                [112.46469976708067, 1.5612179567433486],
                [112.44331049658217, 1.5492116432027956],
                [112.44476832369924, 1.5393387553793332],
                [112.43397243288155, 1.5271580444082815],
                [112.42598462119338, 1.5299768515504866],
                [112.38630996703137, 1.5136669582635705],
                [112.37423683206418, 1.5166034623799192],
                [112.35204266113999, 1.5037363807294355],
                [112.32927896215449, 1.5083892076958136],
                [112.29695664793098, 1.4862179775776667],
                [112.28761001459054, 1.473520119557918],
                [112.2671818045967, 1.4676640221769048],
                [112.25969469585357, 1.4601946372727639],
                [112.21223019956415, 1.44950618845985],
                [112.20408533027387, 1.435407248745605],
                [112.2118058319736, 1.405908779666504],
                [112.23232133106467, 1.379186160712777],
                [112.22361988754778, 1.3667118237916611],
                [112.22410804315177, 1.3514173514296886],
                [112.20239287183358, 1.3141777124315581],
                [112.1811736370538, 1.3062303387591783],
                [112.17070040859699, 1.281710283602763],
                [112.17388011277706, 1.2529329125239315],
                [112.15763353356078, 1.2422073756699206],
                [112.16473965890816, 1.2098697441933837],
                [112.14894545127163, 1.1853755094722374],
                [112.13503579996222, 1.1764892768586606],
                [112.14762077238083, 1.1626924410034007],
                [112.14295094193244, 1.1350264413172795],
                [112.08201130717612, 1.1329583981116116],
                [112.05680209074399, 1.135746661579219],
                [112.04517858172619, 1.1313867860713458],
                [112.01502192492194, 1.130809250446589],
                [111.99733637557335, 1.1395737301435815],
                [111.98695348131815, 1.1275835594337877],
                [111.95623911174567, 1.1270979354214319],
                [111.94882499377849, 1.119762035989197],
                [111.93533814523926, 1.1221497333235675],
                [111.93369496335191, 1.1052560855999332],
                [111.92569703069347, 1.0925067818625394],
                [111.90944575521752, 1.0857679198536516],
                [111.90219800823155, 1.0745709862620743],
                [111.88692001414739, 1.0786730225419774],
                [111.88017545493335, 1.053779310766629],
                [111.8709712975874, 1.045122734426684],
                [111.87758740663355, 1.0317541386426115],
                [111.86961184371165, 1.013131197147061],
                [111.85942893709586, 1.0039169314213154],
                [111.81802296928464, 0.9863245896081594],
                [111.81301370861581, 0.9989476357248464],
                [111.79241891360203, 0.9943891224600634],
                [111.77805828466217, 0.9994693585241521],
                [111.77189303723395, 1.0162469721201433],
                [111.7619454445267, 1.019735089802623],
                [111.74967254585135, 1.0096762320724793],
                [111.71742338967778, 1.0067706151717744],
                [111.7007597525606, 1.0228616296605537],
                [111.67598972454618, 1.0343107700260816],
                [111.6675769436211, 1.0431767553030795],
                [111.63994072858179, 1.0221111418157989],
                [111.62229523054054, 1.0214629824307622],
                [111.5961549127191, 1.0091429197100297],
                [111.58233326636719, 0.9926517204006018],
                [111.58095474416615, 0.9789796564859898],
                [111.54876840293952, 0.9884302892265282],
                [111.55095458558571, 0.9564692623466158],
                [111.5307504632633, 0.9536841977675863],
                [111.52093482987857, 0.9583450700689582],
                [111.51568976427126, 0.9746330594681467],
                [111.51537079632527, 1.0020068676228675],
                [111.50558773458636, 1.021012495830064],
                [111.48866389931953, 1.0352760132774392],
                [111.46353468657657, 1.035959558287061],
                [111.44516136360613, 1.0140607157294994],
                [111.41343293198429, 1.007577263879682],
                [111.38509354692968, 1.0150170988600848],
                [111.34926393518253, 1.0402717274582756],
                [111.30949217402468, 1.0466493273046353],
                [111.27298537367614, 1.0678752917114025],
                [111.25206550971558, 1.0629570963929496],
                [111.24465493327858, 1.0787341323743362],
                [111.23154362184073, 1.0861536894412893],
                [111.19690015112116, 1.0645410381384295],
                [111.1623932091793, 1.0595688745040093],
                [111.1383178211106, 1.050467716511946],
                [111.11270530280751, 1.0525188110943304],
                [111.10610869915718, 1.0479630875265116],
                [111.06932824590189, 1.0494408984787356],
                [111.03430672537513, 1.0356157645559279],
                [111.00380928037407, 1.033669783633684],
                [110.99793805639626, 1.0293417854950349],
                [110.93148381032967, 1.0261452190266596],
                [110.90689394709801, 1.029224615523617],
                [110.89656072060086, 1.0108227174944773],
                [110.885620573131, 0.9781064003901179],
                [110.84909702201003, 0.9545207687185666],
                [110.81994800969295, 0.9473188556806917],
                [110.80621915988638, 0.9380251825161849],
                [110.81785248770223, 0.9141973580216813],
                [110.8100206202555, 0.8999292792129268],
                [110.7838715871041, 0.9124505301678596],
                [110.77148248702724, 0.9322225228511911],
                [110.76179893707138, 0.9234084734223416],
                [110.76482955703847, 0.9082198404732935],
                [110.75564817951994, 0.8992827754797759],
                [110.73151495515111, 0.9074056941175286],
                [110.71630358464279, 0.9060762074510649],
                [110.6976366636012, 0.8841679112201746],
                [110.69912833310616, 0.873207290683979],
                [110.6902960688086, 0.8634477794914801],
                [110.67534170295727, 0.8762539257780873],
                [110.65815334560045, 0.874202528124215],
                [110.65464531063265, 0.89114911803739],
                [110.63815994252741, 0.8923738741549982],
                [110.63510438525924, 0.8758325880035613],
                [110.62105944713585, 0.866041983650689],
                [110.60181221606098, 0.8688755144934248],
                [110.59559208930352, 0.8577452170681568],
                [110.55348525733882, 0.8595139110382206],
                [110.54512806440869, 0.86859081521402],
                [110.521242252528, 0.86989532950588],
                [110.49048239074978, 0.8766942752207001],
                [110.47960893070763, 0.8919955392627799],
                [110.46730719257295, 0.8925944958393757],
                [110.46274897968175, 0.9047664294272408],
                [110.44446574359927, 0.9094999651513263],
                [110.43306270428042, 0.9253510674978429],
                [110.44073597898233, 0.9381928692055794],
                [110.41782930218062, 0.9506554861617929],
                [110.40544202772747, 0.9530323574574027],
                [110.4091983196468, 0.967549898309187],
                [110.39644546358733, 0.9976922747787285],
                [110.37864496559274, 0.9957914362258293],
                [110.36261752238079, 0.9851201257932303],
                [110.34345137957757, 0.9919346801414122],
                [110.34379257876458, 1.0063336975421748],
                [110.31559729657806, 1.0019392422022975],
                [110.29839613377459, 0.9955984462113747],
                [110.27630537499311, 0.9966582612697721],
                [110.28291324085342, 1.0182448700048212],
                [110.27815141875305, 1.032568783222814],
                [110.28664762708638, 1.0402571026831993],
                [110.2793117366474, 1.0522974456706038],
                [110.26026457594946, 1.0575920783118136],
                [110.26353173380255, 1.074395462880375],
                [110.24707867204313, 1.0890028424072966],
                [110.24736344326834, 1.1086351811687223],
                [110.231901599879, 1.120364936092983],
                [110.21046077214697, 1.1185711366487112],
                [110.20599146983051, 1.1383657994421128],
                [110.21270520298282, 1.1507894226937765],
                [110.19302384667327, 1.182875383392286],
                [110.17217765697336, 1.1880084510292477],
                [110.16513192829592, 1.196073971336773],
                [110.13892755895972, 1.1999330665578327],
                [110.12669076716526, 1.1946141126512089],
                [110.09722857493887, 1.1990372626596013],
                [110.08657589126443, 1.217887024866343],
                [110.06509582611153, 1.2089842537203594],
                [110.05982498863239, 1.2197958754792921],
                [110.0700624049561, 1.2381647126314306],
                [110.06401369077929, 1.262999714864975],
                [110.04448501507147, 1.2715676162525256],
                [110.01494981768002, 1.291565034798566],
                [109.97969215496903, 1.299181893229843],
                [109.96852338454664, 1.3416542231639141],
                [109.9659954100602, 1.3748747280862972],
                [109.97032108075331, 1.3895033666870518],
                [109.96348818113472, 1.4028260763623734],
                [109.9476233227582, 1.407621416195468],
                [109.93031403266656, 1.425038367726529],
                [109.91383003512806, 1.4231744706253835],
                [109.89687604829106, 1.4153051733950974],
                [109.83761208073298, 1.421791304325268],
                [109.8334978586393, 1.452136659579196],
                [109.83823743960619, 1.475719648143297],
                [109.80126270760792, 1.4695278815065649],
                [109.80428134500812, 1.4859744582547023],
                [109.79859834104064, 1.5046809350771468],
                [109.78061958752403, 1.5088191268013134],
                [109.76347701285391, 1.5348143426078877],
                [109.7514410477662, 1.5365689738790707],
                [109.73229484383205, 1.5582884700369277],
                [109.71673110275827, 1.5683109367004135],
                [109.70704250220979, 1.586374994510436],
                [109.69684855371781, 1.5881580029894735],
                [109.687118603241, 1.609946518517063],
                [109.66199466502184, 1.6174194836223137],
                [109.65779651100024, 1.6366229231094849],
                [109.66701489112438, 1.6518581382425168],
                [109.65992184821476, 1.6766141961882681],
                [109.66088523796344, 1.69324670776939],
                [109.66987105880236, 1.6992672857566617],
                [109.66769830303434, 1.7331379465590544],
                [109.67834292968251, 1.749115944145558],
                [109.6854850575894, 1.7821385037889854],
                [109.66037275300022, 1.7981411760744663],
                [109.6492336440175, 1.7959825837293124],
                [109.6228215555704, 1.8032839348057905],
                [109.58632842311829, 1.7918995537214641],
                [109.57990185428719, 1.802284995755997],
                [109.58609842240435, 1.8194842609898956],
                [109.57576463652879, 1.8438050056964812],
                [109.56371787867721, 1.8432604670969113],
                [109.55157294453585, 1.8590830978067743],
                [109.54610431016584, 1.9038840809856197],
                [109.53883087404569, 1.9118291272125134],
                [109.54008167183173, 1.9264616778642107],
                [109.55394850731784, 1.9288183243062917],
                [109.56699668724735, 1.9457400542601704],
                [109.56024416903324, 1.9712199450444814],
                [109.57642463279063, 1.977835720442215],
                [109.62042034412366, 1.9831146338333383],
                [109.62945268419406, 2.0008130479837973],
                [109.62936973882245, 2.0126694157609677],
                [109.63820808073842, 2.0277108710599236],
                [109.62547393957072, 2.0649757630210956],
                [109.60594652111513, 2.0297690172263856],
                [109.59265999836032, 1.9964535016278546],
                [109.57309575986147, 1.9882750786320003],
                [109.53675583231586, 1.9896873730738776],
                [109.47374391135247, 1.9816847522495777],
                [109.45643437754455, 1.9766255054627777],
                [109.43501850240204, 1.9527831910914415],
                [109.39410666978705, 1.945895502075814],
                [109.33757075698492, 1.943304569650195],
                [109.32319783880928, 1.9212626494517622],
                [109.34055513021514, 1.8837996197567008],
                [109.3431374012701, 1.871694368200565],
                [109.33624263369063, 1.861311836372181],
                [109.33871505615497, 1.842343573197411],
                [109.33514663980566, 1.8287683635691678],
                [109.2912431800288, 1.781325357479318],
                [109.30044417086185, 1.7677978811673256],
                [109.291942850782, 1.7569026026730512],
                [109.28051678432217, 1.7201643617959235],
                [109.2703411199468, 1.715270753042887],
                [109.2664085842888, 1.689958498585895],
                [109.25160613731344, 1.6596945283798163],
                [109.23252760826256, 1.641332613309487],
                [109.20398288909911, 1.622973131804656],
                [109.15417605907794, 1.6004190171240111],
                [109.11860978591295, 1.5792830784513399],
                [109.06029112993178, 1.5260460767650557],
                [109.04148880066765, 1.4850006282763957],
                [109.0368549107256, 1.4572432916446587],
                [109.03697256812956, 1.415251027013312],
                [109.04326982794026, 1.3635213700152349],
                [109.03570955755009, 1.3298283253170098],
                [109.02126694461384, 1.309043817418285],
                [108.98724372371896, 1.2714301054151065],
                [108.98721036876356, 1.2565361164486717],
                [108.96393858699628, 1.2166931424745826],
                [108.95983458839561, 1.2047382000079665],
                [108.97110731954723, 1.1766850520735375],
                [108.94790561385304, 1.1646884566070526],
                [108.92738864526984, 1.1756215327076234],
                [108.90667553095108, 1.17385613115556],
                [108.90303581275259, 1.1627692314149272],
                [108.93109597090239, 1.0993921074598347],
                [108.9432522427968, 1.0866615698756164],
                [108.95006530516974, 1.0672166021644784],
                [108.96676658700846, 1.0444720587385632],
                [108.97573879570893, 1.0201017793736469],
                [108.97851521420586, 0.9809411830891577],
                [108.97018241358757, 0.9416539550660445],
                [108.94889686459868, 0.899347406157915],
                [108.92933335185279, 0.8769134894660056],
                [108.91118512289518, 0.8624582950143349],
                [108.89865681406076, 0.865913034359096],
                [108.873647764233, 0.8400073865317381],
                [108.84063381829611, 0.8146871559874853],
                [108.84624350323514, 0.794452549345749],
                [108.86091591363856, 0.7920643187133578],
                [108.87737721408723, 0.7760533232023477],
                [108.88448869579673, 0.7552149881811943],
                [108.87500579987784, 0.7084738813645686],
                [108.89703739316121, 0.693383238545664],
                [108.91125329600288, 0.6723174946566814],
                [108.92318335422846, 0.6453182847110952],
                [108.92859657507194, 0.6238890843138165],
                [108.92998272622191, 0.5847251605378005],
                [108.9267572645362, 0.5620852154603995],
                [108.9072861481373, 0.5053237131189121],
                [108.9121038963778, 0.47241286905870084],
                [108.93477754264956, 0.45557269502393183],
                [108.94616529709083, 0.4238185490868318],
                [108.94401314029426, 0.3822045375094376],
                [108.93600490590177, 0.3645873582200352],
                [108.91335353403844, 0.33959835517998727],
                [108.9222823198732, 0.32455862388144396],
                [108.95837230783582, 0.3126246877791914],
                [108.96473705457146, 0.31531794956379144],
                [109.0069490226793, 0.30139765927458484],
                [109.03507411907538, 0.28701656536225073],
                [109.08659768203745, 0.25095438443736384],
                [109.1158635522612, 0.2157070036753339],
                [109.1302358526026, 0.18016461020863517],
                [109.13287316666339, 0.14122384330579507],
                [109.14307956177242, 0.11901066071345667],
                [109.15830089766837, 0.10725823208179008],
                [109.1758708976239, 0.07421247352687033],
                [109.2294793840664, 0.036962720753024314],
                [109.24760947279901, 0.02664251643903981],
                [109.2409288041211, 0.01626418354527459],
                [109.22178760671272, 0.01726416311066714],
                [109.18115625016088, 0.03331045251542264],
                [109.1704410123429, 0.033679019870930915],
                [109.16762443192204, 0.008121852141812269],
                [109.15962800293005, -0.030190692446467438],
                [109.16013215027857, -0.04612924576804289],
                [109.17666884179368, -0.0634627033410311],
                [109.17974648011602, -0.0826038503873292],
                [109.18795802064619, -0.0917639166713684],
                [109.18172368370108, -0.10608812576634818],
                [109.18960479622444, -0.13682269950794534],
                [109.18363661903481, -0.16235773042484425],
                [109.18138454665956, -0.1943727094329688],
                [109.19847101082854, -0.22064116625676888],
                [109.18616059693409, -0.22436040970507065],
                [109.17609004531937, -0.22623973827325017],
                [109.17387535686231, -0.2305406946972539],
                [109.17055786935026, -0.2317426008403345],
                [109.16442502269672, -0.23026395171996228],
                [109.15242480835826, -0.23455923611982143],
                [109.13600551164512, -0.237027411985423],
                [109.13031265830261, -0.24119776976124285],
                [109.11959473690763, -0.2596716828018657],
                [109.08971142813994, -0.273541266616121],
                [109.08864407676282, -0.2889703261196246],
                [109.1045729353927, -0.30636870485182044],
                [109.11431975535248, -0.3427876153561442],
                [109.14931405519957, -0.35776849861724713],
                [109.12495370524937, -0.3615574125445278],
                [109.09950011783621, -0.3491811367999844],
                [109.09757479614001, -0.36193853623439054],
                [109.11619900208234, -0.4362637234920044],
                [109.11264301796098, -0.4666561051950566],
                [109.11433985430091, -0.5042460822908197],
                [109.12007665301519, -0.5245163479510211],
                [109.13166727382571, -0.5471380646699231],
                [109.14999132024332, -0.5690828553894107],
                [109.18110205611504, -0.5700240993326702],
                [109.20174242607214, -0.5516191867760085],
                [109.22651680500661, -0.5713193901776776],
                [109.22868014127556, -0.5908445378451006],
                [109.25390487011373, -0.6048667492010509],
                [109.2590579998269, -0.5992729993494095],
                [109.28528339890977, -0.597375595307426],
                [109.29432615312953, -0.5918744009827606],
                [109.3151388511771, -0.5941783201732846],
                [109.3238996391571, -0.588049589716718],
                [109.37415375329311, -0.5846175249787393],
                [109.38889389613644, -0.6090160052843885],
                [109.40227155438879, -0.6232294475809113],
                [109.41837755158281, -0.6313176541863186],
                [109.46216039968277, -0.6252008415455634],
                [109.47292214324239, -0.6172303858735404],
                [109.48124728973067, -0.6253584144591855],
                [109.494961260254, -0.6244057967900289],
                [109.50344375482547, -0.6361896828075828],
                [109.51738563154174, -0.6424069497209075],
                [109.54288118132155, -0.6445735118580274],
                [109.54591231929817, -0.6515850474119702],
                [109.58615170363828, -0.6527178424544218],
                [109.58390447231363, -0.6788461165550075],
                [109.59596728396303, -0.6936104673491172],
                [109.6073075092342, -0.6850895339547947],
                [109.61419170618234, -0.683041171512798],
                [109.61939306563755, -0.6896345834692283],
                [109.64639704558726, -0.6836320503787761],
                [109.6543690103216, -0.6717105689875211],
                [109.65692319195182, -0.6708767904314641],
                [109.65800840386481, -0.6721185824065969],
                [109.65988162153968, -0.6788231047024738],
                [109.66756710094103, -0.6830899579351808],
                [109.66983974800604, -0.689950293171421],
                [109.68882303075833, -0.7120552585432733],
                [109.69679912338091, -0.7160939807525892],
                [109.69465523764916, -0.7240485975646607],
                [109.68499754859806, -0.7278078536494945],
                [109.67133000017941, -0.7460979992232524],
                [109.66157901366063, -0.7509367529287374],
                [109.63319421104404, -0.7510236346329862],
                [109.63387012980269, -0.7809135687061826],
                [109.6378263949722, -0.7868616847189287],
                [109.63543436470422, -0.7955836948315778],
                [109.66086729019332, -0.805960138249759],
                [109.67840447478676, -0.8290835165909698],
                [109.6916360786422, -0.8243016602933153],
                [109.71322582601113, -0.8293056626261546],
                [109.72094118179018, -0.8490853184326284],
                [109.71609613822625, -0.8630310568377695],
                [109.72702026877045, -0.8705014525799015],
                [109.74079871528852, -0.8596506185811563],
                [109.75044801906091, -0.8752349928006424],
                [109.73929020507899, -0.8847360757092702],
                [109.75797251621873, -0.9036226057985459],
                [109.74867277532837, -0.9133226556160139],
                [109.72721059409372, -0.9217746514789269],
                [109.73420963536643, -0.9619227393429242],
                [109.73222389721514, -0.9876025889882385],
                [109.75458969835597, -0.9894897317656728],
                [109.76834742629273, -1.0017814541507164],
                [109.76761639617979, -1.032930382431175],
                [109.77525358468523, -1.0442580477706542],
                [109.80010352915463, -1.0463210925443605],
                [109.82003266937227, -1.084148180394152],
                [109.86138210130298, -1.0926937983800258],
                [109.93969781790003, -1.1236674622834926],
                [109.94251104834626, -1.1294316849242705],
                [109.9181419488919, -1.190532725504852],
                [109.90689417482639, -1.2041797837699733],
                [109.91714081819424, -1.2207840539408608],
                [109.92749178561746, -1.2144219431181114],
                [109.93394344913094, -1.2311779307168536],
                [109.94842472482128, -1.2433706908046815],
                [109.94132807563022, -1.2552745769601188],
                [109.9610079678435, -1.2647182381560924],
                [109.96169980460235, -1.2769773385332996],
                [109.9934607395216, -1.2740921038674742],
                [110.01852790803656, -1.2883270318669702],
                [110.04780191802416, -1.3226283204405718],
                [110.06343543839483, -1.3518054617526332],
                [110.07168466231735, -1.3775668924980664],
                [110.07440403861557, -1.4050215604965501],
                [110.07123090038579, -1.4211331065076251],
                [110.05705635466711, -1.4346119232016576],
                [110.06330091330659, -1.4590395226558144],
                [110.04708054213336, -1.4896421721484785],
                [110.0358478991613, -1.5228469299418066],
                [110.04087893691349, -1.560186914394535],
                [110.04129780514967, -1.5836757790139586],
                [110.03311539815718, -1.6636400842224361],
                [110.0166779666149, -1.7003868488739045],
                [109.99716658936666, -1.7181025668799066],
                [109.98410150667922, -1.7399567814802595],
                [109.95794997161397, -1.7623176894097696],
                [109.93967984764691, -1.7646572892024892],
                [109.92976237323501, -1.7767777482944211],
                [109.91416661629637, -1.7731731081523776],
                [109.90852385519185, -1.805234676539044],
                [109.9241978738329, -1.819893998209409],
                [109.90183275437818, -1.8308161115742223],
                [109.9236476533166, -1.8571067762566713],
                [109.94363227001332, -1.8648443075187515],
                [109.97571407494468, -1.8830633733759328],
                [109.99370517885097, -1.898239571399074],
                [110.01644105640969, -1.9104463276358388],
                [110.04572356229261, -1.9182175978638156],
                [110.07507067007543, -1.9394013265099375],
                [110.09603411967896, -1.9721837833548648],
                [110.10367449628103, -1.9908137692399919],
                [110.10813975164825, -2.024775507983236],
                [110.11761409051792, -2.0324310858259196],
                [110.11238494560882, -2.044018086865151],
                [110.11817590239605, -2.0609238943222294],
                [110.11962733713199, -2.090578448836311],
                [110.11594340556377, -2.1263944142707487],
                [110.10889621998462, -2.1557866797114684],
                [110.08957063367785, -2.1795862735876312],
                [110.0943704638259, -2.2066952269031503],
                [110.08635590899809, -2.2418545937144927],
                [110.10124074106739, -2.249331394428282],
                [110.11395518258564, -2.2625784837100014],
                [110.13308833375964, -2.294273657503254],
                [110.14447409979994, -2.3233372324272636],
                [110.14394219937304, -2.35539848155463],
                [110.15306288614335, -2.4082477882047897],
                [110.14851680240855, -2.429092021879285],
                [110.1602943329172, -2.4350067595542555],
                [110.17991573682184, -2.454226166504327],
                [110.18562773673074, -2.489152483899943],
                [110.19738684192924, -2.524339792647254],
                [110.19107814903742, -2.5465494642862723],
                [110.18989369064138, -2.571863110893787],
                [110.17407932470115, -2.5979898507509915],
                [110.16263767119165, -2.601882723608753],
                [110.15622240324137, -2.618194185313371],
                [110.1591333000618, -2.6311325526251608],
                [110.19908164040544, -2.6381695165099472],
                [110.2166980426806, -2.6533580937010015],
                [110.22236927272382, -2.6834170076887176],
                [110.2325041501465, -2.6927702879183926],
                [110.23210383132681, -2.718718651744325],
                [110.2216433426662, -2.739430168867159],
                [110.25614946532562, -2.758759076370211],
                [110.26384903620414, -2.7791183776109847],
                [110.26281741579118, -2.814924751775834],
                [110.24980336513323, -2.8539097419409813],
                [110.22410239401034, -2.8872391313807384],
                [110.24617769794247, -2.915124023354963],
                [110.2572750523019, -2.955847338284002],
                [110.2633069851293, -2.9639256082801353],
                [110.28071274279893, -2.9700170285629497],
                [110.29777348276008, -2.981950029370296],
                [110.3034491266759, -2.9944567104088264],
                [110.32016722134023, -2.975932605305445],
                [110.35174742760177, -2.946615266880997],
                [110.39290734359906, -2.9145617851982024],
                [110.42514776375833, -2.8932686666155973],
                [110.48835466133863, -2.8607740701671673],
                [110.50882744083378, -2.85327870744635],
                [110.53200764913379, -2.8512570647616826],
                [110.53509531579624, -2.8450161897453086],
                [110.56357089777879, -2.8388852856273843],
                [110.59651835503814, -2.8529562789091187],
                [110.61612869458367, -2.874486772646378],
                [110.62351206023345, -2.889745698405193],
                [110.63352534391953, -2.9259347574830485],
                [110.63518407958168, -2.9924577944915995],
                [110.61085704681534, -2.9904587068038495],
                [110.62673852466327, -3.0118794238962323],
                [110.62672071538879, -3.0291855492736204],
                [110.64963074216507, -3.039549807777348],
                [110.6724003685822, -3.031522346778843],
                [110.70504983458227, -3.0106158485425567],
                [110.72641704561022, -3.0017345666968254],
                [110.73447882204005, -2.9882708352308214],
                [110.73677950726852, -2.982337768473201],
                [110.73693719079876, -2.9820561511704113],
                [110.75517328621822, -2.942372122475433],
                [110.79969480866856, -2.9070457812377413],
                [110.82478396920487, -2.9067134115946374],
                [110.8381317591734, -2.895586385902959],
                [110.87983397086111, -2.8818107101960777],
                [110.88856497419425, -2.8715180422238404],
                [110.91482624273698, -2.866549033411104],
                [110.9270328685721, -2.8683149107044983],
                [110.96664269240637, -2.8578475134519294],
                [110.97550755802746, -2.8423193063908343],
                [110.99588158296876, -2.830537652490648],
                [111.00686539791985, -2.8004546483632566],
                [111.03291925581908, -2.785654777945581],
                [111.05786343664835, -2.782491518768552],
                [111.10152467392464, -2.767778656859491],
                [111.11236477001819, -2.758616191184217],
                [111.14637282114882, -2.741369858132373],
                [111.15730787216063, -2.718254888452293],
                [111.16931709231, -2.7062645324821233],
                [111.1716308293993, -2.6859221259053356],
                [111.16380689037572, -2.671698918770062],
                [111.14396667512204, -2.6719565295701386],
                [111.12815853608765, -2.6660590013217984],
                [111.11857604384943, -2.6526453297181547],
                [111.1294403020284, -2.6329369298953793],
                [111.13629911920907, -2.61128377942282],
                [111.13442990711457, -2.595491646522248],
                [111.11608886980554, -2.541728975811509],
                [111.11429594540164, -2.5151555444927567],
                [111.10546109015723, -2.484213107748701],
                [111.10498044479175, -2.4333453025436143],
                [111.09503936677339, -2.3947326955574226],
                [111.08951568130374, -2.3629221810138006],
                [111.07978820666045, -2.3388810184442264],
                [111.06958388538806, -2.29957650994794],
                [111.05852507687086, -2.278117643614337],
                [111.04471587353879, -2.267399309430516],
                [111.03189085767701, -2.2410564202175465],
                [111.04064178638941, -2.2222206532604787],
                [111.06300352169524, -2.202901353214827],
                [111.06361768670695, -2.185128322314199],
                [111.07233808793245, -2.1782271785414196],
                [111.0787810981692, -2.1611959930006037],
                [111.07075500593885, -2.141464706331078],
                [111.05731572254263, -2.0717972131707825],
                [111.06005292700104, -2.058235266744134],
                [111.0830708760202, -2.04510371336562],
                [111.09001131027384, -2.0162046730072234],
                [111.05933793359884, -2.0145866622445965],
                [111.05531885797899, -1.9932539123381616],
                [111.01798996936168, -1.956085626450431],
                [110.98458637506121, -1.9557795287022373],
                [110.9795796477826, -1.9457392293818998],
                [110.99043055102919, -1.9295788708681698],
                [110.99602187540927, -1.909078895874302],
                [110.99355486596437, -1.8972944100089535],
                [110.97462858853856, -1.878446858335792],
                [110.97672700274904, -1.844688490481417],
                [110.98296716280436, -1.8235993048639898],
                [111.00753395213025, -1.7963084357856474],
                [111.01385720054941, -1.7776858648107918],
                [111.01215230628395, -1.7578760295550637],
                [111.01614834037366, -1.744279995225554],
                [111.00493721817816, -1.7237807954472828],
                [111.00327986567333, -1.6967814137312303],
                [110.98602423632951, -1.6764151913079672],
                [110.97449408863133, -1.6691206085292265],
                [110.96296394183253, -1.6721796263885835],
                [110.9526748657525, -1.684106945279268],
                [110.94292449976592, -1.7103799571692662],
                [110.91660240377797, -1.7202958110028135],
                [110.90060424788777, -1.7130919689191728],
                [110.89265341272488, -1.689234019834089],
                [110.88245400085151, -1.6794259974117836],
                [110.86384582532463, -1.6825920336953573],
                [110.85514831454316, -1.6738330128831649],
                [110.85889900008885, -1.6304969992370957],
                [110.8896592161999, -1.6124597897874082],
                [110.89976701953236, -1.5919436413859103],
                [110.91352081203593, -1.5846040259550023],
                [110.9519271846919, -1.579167962331553],
                [110.96243286068801, -1.5590829857911217],
                [110.97866512809327, -1.5395948551184233],
                [110.98973846425878, -1.5405100573931918],
                [110.99589157083938, -1.5558540815006268],
                [111.0168779342693, -1.5625089657161197],
                [111.03562543636485, -1.5404940341722977],
                [111.05980770526145, -1.535520786854761],
                [111.06768233007551, -1.5386907576722706],
                [111.07476807138943, -1.5592573706310873],
                [111.09720392162376, -1.5526837734373657],
                [111.11867348269516, -1.5527542191318844],
                [111.11914505030387, -1.5127599117404884],
                [111.12774381822271, -1.4988365044010834],
                [111.12896340323852, -1.473428828277747],
                [111.13837627254702, -1.451035206609447],
                [111.15726538099261, -1.4342910145186352],
                [111.19648101128968, -1.4379636847762072],
                [111.21861469561179, -1.4227585357777457],
                [111.25962713898133, -1.4162588214873608],
                [111.28284923230626, -1.401872420675602],
                [111.32459805855365, -1.3651841749097002],
                [111.33174535126699, -1.3498384726673294],
                [111.3320895532903, -1.3342994107891286],
                [111.3931387506529, -1.3277580812703604],
                [111.38440318685764, -1.2975487619657429],
                [111.38900164980635, -1.2710486865476014],
                [111.42740388737957, -1.2539442916956318],
                [111.44630972411454, -1.2240332784130032],
                [111.47079289267793, -1.2132016683223128],
                [111.49500591658983, -1.21272596113414],
                [111.51660577413725, -1.2221351522154578],
                [111.55033359561901, -1.2086413968829106],
                [111.568018450691, -1.1813690223624462],
                [111.55669094363219, -1.1456730768509829],
                [111.56827144886836, -1.1096268166242567],
                [111.59552013614541, -1.0818436253829304],
                [111.61939104086389, -1.070966792883155],
                [111.62405199230568, -1.054759059882656],
                [111.61683889799679, -1.0300238032558582],
                [111.6220420039354, -0.9990841732958984],
                [111.64343677019087, -0.9699437584976067],
                [111.70088920187521, -0.9532301163767443],
                [111.70513422226662, -0.9420748213958632],
                [111.73493260715418, -0.9341655196229794],
                [111.74982367422325, -0.912642485762035],
                [111.78348733807024, -0.9095345636691263],
                [111.82530894375282, -0.9114859054501494],
                [111.84403147617172, -0.8993835802879744],
                [111.85211391362628, -0.88439310395853],
                [111.8846641331433, -0.859547422275682],
                [111.88849004697812, -0.8413462745108973],
                [111.91508806216439, -0.8434812776388299],
                [111.92547551238505, -0.791747516134933],
                [111.94644511065337, -0.7872789764435879],
                [111.96932099466824, -0.7988359383278407],
                [111.99431603305857, -0.8004438335160557],
                [112.01322745638208, -0.7946546555878626],
                [112.02768465452323, -0.7802766085785606],
                [112.0456197063844, -0.7706663299870797],
                [112.04154753029434, -0.7516267496746991],
                [112.06173615190198, -0.7387559745085923],
                [112.07770017762118, -0.7223411249428864],
                [112.08504891217763, -0.7019871926547694],
                [112.10850232012604, -0.7015380109704097],
                [112.11765173933634, -0.713254335419208],
                [112.14630962379022, -0.7303733028855959],
                [112.17514214129687, -0.7257830975119077],
                [112.17637340940541, -0.7158088174229533],
                [112.16538468595456, -0.6898390308465423],
                [112.17495686027098, -0.6825572112462623],
                [112.17771325716114, -0.6427337138897542],
                [112.20419845059803, -0.6328789348149257],
                [112.2104093431168, -0.615172810776528],
                [112.23137978674777, -0.6154532607593524],
                [112.27372362064864, -0.646700903124156],
                [112.29525234362086, -0.6964008397269481],
                [112.3101588799284, -0.7204847749523537],
                [112.35016459611927, -0.705096873821617],
                [112.35708779036929, -0.7154374172030202],
                [112.38106450122586, -0.7101716366116761],
                [112.38754538910848, -0.7135410204017489],
                [112.40464142206406, -0.7033779402397045],
                [112.43143639507366, -0.6713648668950327],
                [112.44941346505948, -0.6758551665826076],
                [112.46363305062482, -0.6728678678577467],
                [112.47302962421782, -0.6865517902328975],
                [112.4976365637765, -0.6982751887489371],
                [112.52375094155275, -0.69317383840189],
                [112.56797803585778, -0.6961957592268391],
                [112.57964035781629, -0.6817554359645328],
                [112.59612788789173, -0.6879827752848087],
                [112.6147587362268, -0.6760945867956707],
                [112.63905109293364, -0.6782721917082313],
                [112.64656001023639, -0.6578454440119685],
                [112.68697738210051, -0.6574357011966185],
                [112.71047704218176, -0.626503285598119],
                [112.73335944987878, -0.632519199701278],
                [112.73162222318706, -0.5849505214493433],
                [112.74526516795322, -0.5724051722608579],
                [112.7733460815565, -0.5731885438161726],
                [112.78746488189881, -0.5849300771612889],
                [112.83563800888703, -0.5769208562124959],
                [112.84457589406236, -0.5620103682838362],
                [112.86648371365254, -0.548011135505476],
                [112.88275184692031, -0.5421979573698081],
                [112.92118202822803, -0.5211662015589127],
                [112.93633970561302, -0.5226095441950633],
                [112.9640239696306, -0.5115227667622548],
                [112.97760669039656, -0.5108283399558786],
                [112.99376574759128, -0.4905983036687189],
                [113.01538709174099, -0.4952414467353492],
                [113.02663773014717, -0.47562395218358233],
                [113.04667600669245, -0.4991662814986171],
                [113.06255503298848, -0.5094070001329101],
                [113.0809741962023, -0.49650422528969784],
                [113.09509420973006, -0.49914248543728945],
                [113.11481134466783, -0.4958928445790889],
                [113.11275300065051, -0.47541290558109495],
                [113.1332037980169, -0.45407030899752954],
                [113.15182240837748, -0.4490945453769086],
                [113.15891297995017, -0.47166082518685926],
                [113.177723451676, -0.468766254658874],
                [113.19974578913695, -0.4840860492317347],
                [113.21065463390426, -0.5062690604686964],
                [113.22614922340802, -0.4984187479268485],
                [113.22706212701564, -0.4819218936804077],
                [113.23965076597017, -0.4684566036892761],
                [113.2464920436729, -0.4501844418584824],
                [113.26422615281479, -0.44191157116205204],
                [113.26522114653869, -0.41911853089544593],
                [113.2574925680276, -0.4082737484346808],
                [113.26231431465396, -0.3916585326151676],
                [113.23046098158909, -0.36435296591662336],
                [113.22656234317765, -0.353960404848749],
                [113.20861038316275, -0.3449153555591806],
                [113.22514330831712, -0.3310861072380362],
                [113.23541245452122, -0.29567593569584005],
                [113.2574846998591, -0.2769704211479507],
                [113.27465847740689, -0.2706754968766063],
                [113.27925898091735, -0.25482892891187703],
                [113.29174065001109, -0.24179568132558416],
                [113.32365438424245, -0.23793400954684785],
                [113.33608266688168, -0.22768641019962388],
                [113.35445423437545, -0.22185275899755652],
                [113.37496963723902, -0.19457394892856428],
                [113.38830581695993, -0.18293347420041073],
                [113.39358532929953, -0.16408419762757376],
                [113.41407784531634, -0.15927261873633825],
                [113.42073067008437, -0.14150873933681396],
                [113.40010841337266, -0.13569268247124455],
                [113.39849094220324, -0.12482982993265068],
                [113.42091371809363, -0.11726869979059984],
                [113.42310334963997, -0.09634811996971848],
                [113.43997952083828, -0.07586187942217748],
                [113.44001001235222, -0.06213189017455534],
                [113.42058556806955, -0.05417220478335594],
                [113.41896047334728, -0.039121641150757114],
                [113.42967975361785, -0.027499235601169403],
                [113.42827589931335, -0.010558302423646637],
                [113.43904859751478, 0.009998944995345482],
                [113.43827039796054, 0.01904372898496831],
                [113.41255169940143, 0.04414342957568351],
                [113.41036208134506, 0.05797294319683033],
                [113.41505417810788, 0.08740463221806749],
                [113.41120895133213, 0.0993709547058188],
                [113.42036421073976, 0.11402339926053173],
                [113.41800673782075, 0.12635147314199457],
                [113.40589125287909, 0.13858021780288254],
                [113.37870774666521, 0.15469843768278224],
                [113.3508527940213, 0.16425014882537425],
                [113.32508834605039, 0.17974429046665819],
                [113.29141984295143, 0.2215859753125642],
                [113.27405532909017, 0.2313998676098663],
                [113.27314743110912, 0.2427239527482925],
                [113.29441055910013, 0.2575932643177339],
                [113.32313523080632, 0.2729870847861662],
                [113.35679611934552, 0.27815116914121063],
                [113.39385205472865, 0.2707610028180625],
                [113.40727217986728, 0.2642394925586018],
                [113.43796522471541, 0.2654058251187621],
                [113.46134168577021, 0.2783214656631685],
                [113.4958036697036, 0.3085396001225149],
                [113.51375562881924, 0.320514673013804],
                [113.51544170477086, 0.3337109452040181],
                [113.53100567517185, 0.3434338971566717],
                [113.55543499053249, 0.36549379660146997],
                [113.56294230704191, 0.37951309505336894],
                [113.57474500417868, 0.3818465102083337],
                [113.58845501768496, 0.4059597021785635],
                [113.63964824886762, 0.42768451872558444],
                [113.65720346756609, 0.4385018430856462],
                [113.66863993558457, 0.4903102591111974],
                [113.68513159264899, 0.5011680520639743],
                [113.69469659397271, 0.5182884476537879],
                [113.70473340302419, 0.5191380686669618],
                [113.72107761983636, 0.5360473924732219],
                [113.73242891036602, 0.5372740857305303],
                [113.74865016467345, 0.5498353637111109],
                [113.75859048276459, 0.5652902337573096],
                [113.7723996833987, 0.5637071877365543],
                [113.77999856708027, 0.5922343854086307],
                [113.80619788582385, 0.6164288203338515],
                [113.8085096111297, 0.6338852170292171],
                [113.81630684118784, 0.647090506721554],
                [113.83728005383148, 0.6578534994395113],
                [113.84323861097323, 0.6656452661162007],
                [113.83634925641309, 0.6782975905613853],
                [113.84168221186462, 0.702718638454428],
                [113.86021399897697, 0.7128339043612186],
                [113.87264991326299, 0.7118232408499239],
                [113.89486671202917, 0.7385810152017598],
                [113.89472921468155, 0.7555886142231998],
                [113.87395538982946, 0.7797888452790289],
                [113.8881432266287, 0.7952557194759038],
                [113.9097767628873, 0.8117954183239249],
                [113.9263923321405, 0.8403579980232507],
                [113.91081871291328, 0.8510902537123002],
                [113.87119985988568, 0.8886867041281334],
                [113.87851815868612, 0.8964992397481524],
                [113.87216629024215, 0.9167375210198543],
                [113.8843712721166, 0.9370199922788629],
                [113.91188690605588, 0.9679100749900158],
                [113.91572475659234, 1.0029986948339342],
                [113.90813835010488, 1.0137889846739654],
                [113.89336127300442, 1.019242624647461],
                [113.88969530629345, 1.0473933796008623],
                [113.90265380672781, 1.0586908403100779],
                [113.9482388206651, 1.0612406296513655],
                [113.96215106831721, 1.0881880404457434],
                [113.96281893454625, 1.11006139082059],
                [113.97184986358036, 1.1197739636975257],
                [113.9887679755617, 1.1265689514959831],
                [114.03631021554406, 1.1108909632474138],
                [114.05461915155263, 1.1275044172950857],
                [114.07846659655627, 1.105617657129244],
                [114.09370742975409, 1.106362194159999],
                [114.1282315775253, 1.127437811705704],
                [114.16287952057348, 1.138232462358303],
                [114.18018787537017, 1.1520228617657153],
                [114.22046554025258, 1.160452624589027],
                [114.19775666483827, 1.1959531654063085],
                [114.20117482335945, 1.2139593104738537],
                [114.20897344107152, 1.2185271173326555],
                [114.2078015300167, 1.2361225546122796],
                [114.18700366089035, 1.2688401154790085],
                [114.1963223199192, 1.2988002182555078],
                [114.20901272076048, 1.3088389104873954],
                [114.20918670899948, 1.3339041877281375],
                [114.20299125334373, 1.349370617659929],
                [114.19043074068622, 1.3600269984472675],
                [114.16907164783834, 1.369966057487602],
                [114.16565441921614, 1.3815464152349364],
                [114.17874755265746, 1.394646593065886],
                [114.19765655410674, 1.3969001538191037],
                [114.20538782878532, 1.4103666938678998]
              ]
            ]
          ]
        },
        "id": 61,
        "properties": {
          "ID": 12,
          "kode": 61,
          "Propinsi": "KALIMANTAN BARAT",
          "SUMBER": "Batas Provinsi Desember 2019 Dukcapil (Skala tidak diketahui)"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [113.40202041521002, -3.2592170443310806],
                [113.3966856215443, -3.2494998777171418],
                [113.37281259082852, -3.262926445598964],
                [113.35889449128786, -3.2788489748003258],
                [113.37393163253887, -3.3014688470096303],
                [113.38765528066676, -3.290811850186685],
                [113.40549890385137, -3.2699358209812885],
                [113.40202041521002, -3.2592170443310806]
              ]
            ],
            [
              [
                [115.75398994737827, -1.3150423402317415],
                [115.75758265171805, -1.30744616350961],
                [115.75463009290456, -1.2829590154461812],
                [115.77049158691727, -1.231230668760304],
                [115.78364466377025, -1.2130490012103792],
                [115.81861014754031, -1.1820016468584527],
                [115.84120839465174, -1.1690728006691415],
                [115.84696093779712, -1.1572596597054599],
                [115.82073742099521, -1.1312894531457118],
                [115.79212553596483, -1.1156163005517783],
                [115.758697561943, -1.1360727078891273],
                [115.74909344011019, -1.1234508003141173],
                [115.74110959802954, -1.0934428823830444],
                [115.70643750368788, -1.071801550800899],
                [115.6916979427059, -1.0515466752387965],
                [115.67874047739122, -1.0648072912234738],
                [115.65524246666655, -1.0520492622636084],
                [115.66422082133386, -1.035728539340596],
                [115.6566934211645, -1.0048243363739857],
                [115.64891725592668, -0.9896177619501145],
                [115.6309882043422, -0.976745844644995],
                [115.58238135321699, -0.947523255194028],
                [115.557852863319, -0.9236323576471364],
                [115.53359902997136, -0.8830725511572837],
                [115.49765697239138, -0.8499946724161305],
                [115.48215403717916, -0.8428162523680953],
                [115.44791335931416, -0.8387708248136505],
                [115.40268631961806, -0.8274375863754813],
                [115.39253159595648, -0.8222751486791253],
                [115.37969897003154, -0.8016109071176061],
                [115.36704945596773, -0.7494720154211336],
                [115.36849902910694, -0.7031820957770378],
                [115.3663399025645, -0.6796397664620031],
                [115.3564293205568, -0.6326647163035659],
                [115.35735247733498, -0.6052144864593174],
                [115.35156941929336, -0.5798368216119911],
                [115.33520435709545, -0.5689412787169772],
                [115.31686391873495, -0.5465224823266226],
                [115.3186443299719, -0.5293390172817567],
                [115.30253163102998, -0.5016796599882696],
                [115.28540040580413, -0.48780179251866684],
                [115.25309754211798, -0.45188281154247534],
                [115.25573256830342, -0.43573668659877285],
                [115.25232974951837, -0.4099125838985742],
                [115.23403149925434, -0.37084343877364745],
                [115.23743775614764, -0.3517783213124517],
                [115.23166148169207, -0.33829842453263836],
                [115.2326099067219, -0.3232579252130563],
                [115.22189161300764, -0.30683876159952206],
                [115.23285580565096, -0.2947650708512697],
                [115.25384151077719, -0.3008722175787284],
                [115.25458178592078, -0.2832190869912097],
                [115.25013871682836, -0.25753504380719505],
                [115.23852890538478, -0.2464740895075579],
                [115.2387173340378, -0.23291629974738726],
                [115.25896013800002, -0.21519371901484874],
                [115.26868370329032, -0.18969211320040813],
                [115.26634912890927, -0.16873652996684996],
                [115.2601316488566, -0.15899691177799014],
                [115.27070898373267, -0.14397187630100916],
                [115.28433494210401, -0.14585700639565857],
                [115.28637888887465, -0.12869692462334115],
                [115.30871510681675, -0.11746447590479647],
                [115.3151849501254, -0.1007616238497917],
                [115.31004843898438, -0.08420758171445186],
                [115.30978730733841, -0.05993801472754967],
                [115.28742731761668, -0.04651052144333789],
                [115.26239580452284, -0.046852824997813514],
                [115.24595575235617, -0.054997122319264236],
                [115.22524194466007, -0.07339749240031779],
                [115.18251707070635, -0.08256981374586303],
                [115.18232068215377, -0.09399670938057625],
                [115.15861294772947, -0.09852041078829643],
                [115.14609500088363, -0.12671120895510057],
                [115.14951286082987, -0.13534743543573313],
                [115.11901672164447, -0.14720155131050205],
                [115.10493152357105, -0.14450706374992706],
                [115.08123719534035, -0.15319693658932465],
                [115.06159914318596, -0.16935403685926076],
                [115.0447915902597, -0.18981582394104635],
                [115.02694646161001, -0.1852426921964252],
                [115.00081579621212, -0.1869298806094548],
                [114.97929327496468, -0.17845927057879862],
                [114.95963996401315, -0.18140244856408572],
                [114.94773810852689, -0.19044012161430146],
                [114.92876529927923, -0.19087085370688328],
                [114.92241775027901, -0.18244368632701935],
                [114.93033369984039, -0.16073262491067908],
                [114.91700659967489, -0.13320046413008413],
                [114.92455121376759, -0.09955558352334037],
                [114.91164672391722, -0.07969083386484499],
                [114.90521749219351, -0.04792116832720694],
                [114.89346695483619, -0.02756507586752832],
                [114.89402227270978, -0.002117777413445765],
                [114.88912310075057, 0.024374890288981987],
                [114.89469522100774, 0.04036379755126518],
                [114.87501819673241, 0.055493521309017524],
                [114.86046434755167, 0.05476938629817596],
                [114.84947333712489, 0.06793171717504265],
                [114.83358040731002, 0.07505292325384971],
                [114.8141484410977, 0.06725484513503943],
                [114.79467950015885, 0.08393383907548468],
                [114.79309047274717, 0.11948450270767808],
                [114.80900393768161, 0.14030659777131405],
                [114.81342349579779, 0.16213342711603218],
                [114.80239278839656, 0.17154059022612955],
                [114.82999005289867, 0.1989630867770984],
                [114.83330884802501, 0.22726005800529947],
                [114.86394122910451, 0.2376325255206666],
                [114.87237053417289, 0.2705888051293073],
                [114.88488297986567, 0.2811191153549544],
                [114.88524014022039, 0.29712336684997354],
                [114.89719808822122, 0.3119646132763023],
                [114.93393276238714, 0.33224821228520796],
                [114.94953417606143, 0.33216009311382777],
                [114.9547651807685, 0.3192054300865834],
                [114.97928427365036, 0.32997732069685526],
                [114.99636211422023, 0.3206742891871386],
                [115.02346646660419, 0.3299461708791114],
                [115.03605644914592, 0.31574186119797787],
                [115.03756982828497, 0.3027468455904909],
                [115.04890853010465, 0.2995491810499402],
                [115.05534000114017, 0.3177040002394733],
                [115.07687620916977, 0.33074989319305814],
                [115.08487742525301, 0.342343564503949],
                [115.1286699986216, 0.37463400135811753],
                [115.133437238238, 0.415183631119703],
                [115.1327772212918, 0.4324947756134634],
                [115.0940112286354, 0.46996692083678226],
                [115.0823653112102, 0.5000000017986963],
                [115.06667850690758, 0.524491406353377],
                [115.0497112613657, 0.5394148382242747],
                [115.03249557181323, 0.5731747214363168],
                [115.01075086585979, 0.606699075801771],
                [114.99575684889953, 0.6901332388579249],
                [114.9893489383029, 0.7177897794747992],
                [114.97913733252199, 0.7389088945299136],
                [114.95812155316719, 0.7624179768082513],
                [114.94137375209709, 0.77215415598954],
                [114.91735663470104, 0.7774846895667906],
                [114.8913118689477, 0.7587498382345075],
                [114.87997048804766, 0.7468010084598404],
                [114.86205044497217, 0.7366982161498186],
                [114.83425143015938, 0.7537362680143929],
                [114.81292837134731, 0.7499999680241558],
                [114.80975734562503, 0.7428224499961402],
                [114.78214249667894, 0.7274340713253764],
                [114.76579465829695, 0.730203063220813],
                [114.75000002438173, 0.7258570903325108],
                [114.73689190463779, 0.7127336281544672],
                [114.7159153258317, 0.7013624482184468],
                [114.7007912183401, 0.7024828416098785],
                [114.69148636210593, 0.7103621186169562],
                [114.66808325953491, 0.7038549255644284],
                [114.65599920679801, 0.6899820826070595],
                [114.65272651091982, 0.676263419421332],
                [114.63852730489123, 0.6697469903313618],
                [114.61988269423102, 0.6425123234849934],
                [114.60657940901262, 0.6425587608781598],
                [114.60201380009687, 0.6332600083427451],
                [114.5671692229813, 0.6251847861490205],
                [114.54528424886905, 0.6306128116319769],
                [114.53982709434501, 0.6197499644893014],
                [114.54649221374473, 0.593886201686928],
                [114.5249766127805, 0.5587300481532225],
                [114.49038877592136, 0.5710416617433225],
                [114.46261019370559, 0.5613308362491694],
                [114.43596910009398, 0.5440140557041673],
                [114.4372060753002, 0.5113529605707186],
                [114.43135752413048, 0.5039427771375244],
                [114.41005289062844, 0.49542720100458837],
                [114.40069543934317, 0.4969267917380762],
                [114.38921440631987, 0.5119654600379704],
                [114.37646821333749, 0.5151292687008322],
                [114.3637688075845, 0.5298164432742851],
                [114.319796555067, 0.5405255396220241],
                [114.31191559812646, 0.5342983073210803],
                [114.29083845083392, 0.5380823397282484],
                [114.26928559275598, 0.5288055045704141],
                [114.25642943876767, 0.5323015236963897],
                [114.24769966840506, 0.5226097962051881],
                [114.21941258252468, 0.5294829296934722],
                [114.2029683179336, 0.5395783574552979],
                [114.19357954236204, 0.5864325333348006],
                [114.17804240413375, 0.5813194000702993],
                [114.16489955976692, 0.5841325882483943],
                [114.15858478655866, 0.5988142778566612],
                [114.17948950593598, 0.6159647413797416],
                [114.17657412419658, 0.6396731961611408],
                [114.15767692275188, 0.659763996711149],
                [114.14389228170353, 0.6689896037872245],
                [114.1308862278172, 0.6697062051772491],
                [114.12294780599655, 0.6948502324523247],
                [114.10255086183292, 0.6914729490174523],
                [114.09145017008939, 0.6977617480063145],
                [114.07479619978483, 0.6899051563979128],
                [114.05402054031572, 0.6699554963491323],
                [114.02778597354393, 0.6711524283419408],
                [114.01171196073847, 0.6502201528378464],
                [113.97751590183748, 0.6674166418645768],
                [113.96170019680665, 0.6569672832135893],
                [113.94802831365587, 0.6601030779946768],
                [113.93183874156477, 0.6539136298102335],
                [113.91220832555382, 0.6568041695767874],
                [113.88933776462625, 0.6412416785606752],
                [113.88014197097527, 0.6604249615427875],
                [113.84323861097323, 0.6656452661162007],
                [113.83728005383148, 0.6578534994395113],
                [113.81630684118784, 0.647090506721554],
                [113.8085096111297, 0.6338852170292171],
                [113.80619788582385, 0.6164288203338515],
                [113.77999856708027, 0.5922343854086307],
                [113.7723996833987, 0.5637071877365543],
                [113.75859048276459, 0.5652902337573096],
                [113.74865016467345, 0.5498353637111109],
                [113.73242891036602, 0.5372740857305303],
                [113.72107761983636, 0.5360473924732219],
                [113.70473340302419, 0.5191380686669618],
                [113.69469659397271, 0.5182884476537879],
                [113.68513159264899, 0.5011680520639743],
                [113.66863993558457, 0.4903102591111974],
                [113.65720346756609, 0.4385018430856462],
                [113.63964824886762, 0.42768451872558444],
                [113.58845501768496, 0.4059597021785635],
                [113.57474500417868, 0.3818465102083337],
                [113.56294230704191, 0.37951309505336894],
                [113.55543499053249, 0.36549379660146997],
                [113.53100567517185, 0.3434338971566717],
                [113.51544170477086, 0.3337109452040181],
                [113.51375562881924, 0.320514673013804],
                [113.4958036697036, 0.3085396001225149],
                [113.46134168577021, 0.2783214656631685],
                [113.43796522471541, 0.2654058251187621],
                [113.40727217986728, 0.2642394925586018],
                [113.39385205472865, 0.2707610028180625],
                [113.35679611934552, 0.27815116914121063],
                [113.32313523080632, 0.2729870847861662],
                [113.29441055910013, 0.2575932643177339],
                [113.27314743110912, 0.2427239527482925],
                [113.27405532909017, 0.2313998676098663],
                [113.29141984295143, 0.2215859753125642],
                [113.32508834605039, 0.17974429046665819],
                [113.3508527940213, 0.16425014882537425],
                [113.37870774666521, 0.15469843768278224],
                [113.40589125287909, 0.13858021780288254],
                [113.41800673782075, 0.12635147314199457],
                [113.42036421073976, 0.11402339926053173],
                [113.41120895133213, 0.0993709547058188],
                [113.41505417810788, 0.08740463221806749],
                [113.41036208134506, 0.05797294319683033],
                [113.41255169940143, 0.04414342957568351],
                [113.43827039796054, 0.01904372898496831],
                [113.43904859751478, 0.009998944995345482],
                [113.42827589931335, -0.010558302423646637],
                [113.42967975361785, -0.027499235601169403],
                [113.41896047334728, -0.039121641150757114],
                [113.42058556806955, -0.05417220478335594],
                [113.44001001235222, -0.06213189017455534],
                [113.43997952083828, -0.07586187942217748],
                [113.42310334963997, -0.09634811996971848],
                [113.42091371809363, -0.11726869979059984],
                [113.39849094220324, -0.12482982993265068],
                [113.40010841337266, -0.13569268247124455],
                [113.42073067008437, -0.14150873933681396],
                [113.41407784531634, -0.15927261873633825],
                [113.39358532929953, -0.16408419762757376],
                [113.38830581695993, -0.18293347420041073],
                [113.37496963723902, -0.19457394892856428],
                [113.35445423437545, -0.22185275899755652],
                [113.33608266688168, -0.22768641019962388],
                [113.32365438424245, -0.23793400954684785],
                [113.29174065001109, -0.24179568132558416],
                [113.27925898091735, -0.25482892891187703],
                [113.27465847740689, -0.2706754968766063],
                [113.2574846998591, -0.2769704211479507],
                [113.23541245452122, -0.29567593569584005],
                [113.22514330831712, -0.3310861072380362],
                [113.20861038316275, -0.3449153555591806],
                [113.22656234317765, -0.353960404848749],
                [113.23046098158909, -0.36435296591662336],
                [113.26231431465396, -0.3916585326151676],
                [113.2574925680276, -0.4082737484346808],
                [113.26522114653869, -0.41911853089544593],
                [113.26422615281479, -0.44191157116205204],
                [113.2464920436729, -0.4501844418584824],
                [113.23965076597017, -0.4684566036892761],
                [113.22706212701564, -0.4819218936804077],
                [113.22614922340802, -0.4984187479268485],
                [113.21065463390426, -0.5062690604686964],
                [113.19974578913695, -0.4840860492317347],
                [113.177723451676, -0.468766254658874],
                [113.15891297995017, -0.47166082518685926],
                [113.15182240837748, -0.4490945453769086],
                [113.1332037980169, -0.45407030899752954],
                [113.11275300065051, -0.47541290558109495],
                [113.11481134466783, -0.4958928445790889],
                [113.09509420973006, -0.49914248543728945],
                [113.0809741962023, -0.49650422528969784],
                [113.06255503298848, -0.5094070001329101],
                [113.04667600669245, -0.4991662814986171],
                [113.02663773014717, -0.47562395218358233],
                [113.01538709174099, -0.4952414467353492],
                [112.99376574759128, -0.4905983036687189],
                [112.97760669039656, -0.5108283399558786],
                [112.9640239696306, -0.5115227667622548],
                [112.93633970561302, -0.5226095441950633],
                [112.92118202822803, -0.5211662015589127],
                [112.88275184692031, -0.5421979573698081],
                [112.86648371365254, -0.548011135505476],
                [112.84457589406236, -0.5620103682838362],
                [112.83563800888703, -0.5769208562124959],
                [112.78746488189881, -0.5849300771612889],
                [112.7733460815565, -0.5731885438161726],
                [112.74526516795322, -0.5724051722608579],
                [112.73162222318706, -0.5849505214493433],
                [112.73335944987878, -0.632519199701278],
                [112.71047704218176, -0.626503285598119],
                [112.68697738210051, -0.6574357011966185],
                [112.64656001023639, -0.6578454440119685],
                [112.63905109293364, -0.6782721917082313],
                [112.6147587362268, -0.6760945867956707],
                [112.59612788789173, -0.6879827752848087],
                [112.57964035781629, -0.6817554359645328],
                [112.56797803585778, -0.6961957592268391],
                [112.52375094155275, -0.69317383840189],
                [112.4976365637765, -0.6982751887489371],
                [112.47302962421782, -0.6865517902328975],
                [112.46363305062482, -0.6728678678577467],
                [112.44941346505948, -0.6758551665826076],
                [112.43143639507366, -0.6713648668950327],
                [112.40464142206406, -0.7033779402397045],
                [112.38754538910848, -0.7135410204017489],
                [112.38106450122586, -0.7101716366116761],
                [112.35708779036929, -0.7154374172030202],
                [112.35016459611927, -0.705096873821617],
                [112.3101588799284, -0.7204847749523537],
                [112.29525234362086, -0.6964008397269481],
                [112.27372362064864, -0.646700903124156],
                [112.23137978674777, -0.6154532607593524],
                [112.2104093431168, -0.615172810776528],
                [112.20419845059803, -0.6328789348149257],
                [112.17771325716114, -0.6427337138897542],
                [112.17495686027098, -0.6825572112462623],
                [112.16538468595456, -0.6898390308465423],
                [112.17637340940541, -0.7158088174229533],
                [112.17514214129687, -0.7257830975119077],
                [112.14630962379022, -0.7303733028855959],
                [112.11765173933634, -0.713254335419208],
                [112.10850232012604, -0.7015380109704097],
                [112.08504891217763, -0.7019871926547694],
                [112.07770017762118, -0.7223411249428864],
                [112.06173615190198, -0.7387559745085923],
                [112.04154753029434, -0.7516267496746991],
                [112.0456197063844, -0.7706663299870797],
                [112.02768465452323, -0.7802766085785606],
                [112.01322745638208, -0.7946546555878626],
                [111.99431603305857, -0.8004438335160557],
                [111.96932099466824, -0.7988359383278407],
                [111.94644511065337, -0.7872789764435879],
                [111.92547551238505, -0.791747516134933],
                [111.91508806216439, -0.8434812776388299],
                [111.88849004697812, -0.8413462745108973],
                [111.8846641331433, -0.859547422275682],
                [111.85211391362628, -0.88439310395853],
                [111.84403147617172, -0.8993835802879744],
                [111.82530894375282, -0.9114859054501494],
                [111.78348733807024, -0.9095345636691263],
                [111.74982367422325, -0.912642485762035],
                [111.73493260715418, -0.9341655196229794],
                [111.70513422226662, -0.9420748213958632],
                [111.70088920187521, -0.9532301163767443],
                [111.64343677019087, -0.9699437584976067],
                [111.6220420039354, -0.9990841732958984],
                [111.61683889799679, -1.0300238032558582],
                [111.62405199230568, -1.054759059882656],
                [111.61939104086389, -1.070966792883155],
                [111.59552013614541, -1.0818436253829304],
                [111.56827144886836, -1.1096268166242567],
                [111.55669094363219, -1.1456730768509829],
                [111.568018450691, -1.1813690223624462],
                [111.55033359561901, -1.2086413968829106],
                [111.51660577413725, -1.2221351522154578],
                [111.49500591658983, -1.21272596113414],
                [111.47079289267793, -1.2132016683223128],
                [111.44630972411454, -1.2240332784130032],
                [111.42740388737957, -1.2539442916956318],
                [111.38900164980635, -1.2710486865476014],
                [111.38440318685764, -1.2975487619657429],
                [111.3931387506529, -1.3277580812703604],
                [111.3320895532903, -1.3342994107891286],
                [111.33174535126699, -1.3498384726673294],
                [111.32459805855365, -1.3651841749097002],
                [111.28284923230626, -1.401872420675602],
                [111.25962713898133, -1.4162588214873608],
                [111.21861469561179, -1.4227585357777457],
                [111.19648101128968, -1.4379636847762072],
                [111.15726538099261, -1.4342910145186352],
                [111.13837627254702, -1.451035206609447],
                [111.12896340323852, -1.473428828277747],
                [111.12774381822271, -1.4988365044010834],
                [111.11914505030387, -1.5127599117404884],
                [111.11867348269516, -1.5527542191318844],
                [111.09720392162376, -1.5526837734373657],
                [111.07476807138943, -1.5592573706310873],
                [111.06768233007551, -1.5386907576722706],
                [111.05980770526145, -1.535520786854761],
                [111.03562543636485, -1.5404940341722977],
                [111.0168779342693, -1.5625089657161197],
                [110.99589157083938, -1.5558540815006268],
                [110.98973846425878, -1.5405100573931918],
                [110.97866512809327, -1.5395948551184233],
                [110.96243286068801, -1.5590829857911217],
                [110.9519271846919, -1.579167962331553],
                [110.91352081203593, -1.5846040259550023],
                [110.89976701953236, -1.5919436413859103],
                [110.8896592161999, -1.6124597897874082],
                [110.85889900008885, -1.6304969992370957],
                [110.85514831454316, -1.6738330128831649],
                [110.86384582532463, -1.6825920336953573],
                [110.88245400085151, -1.6794259974117836],
                [110.89265341272488, -1.689234019834089],
                [110.90060424788777, -1.7130919689191728],
                [110.91660240377797, -1.7202958110028135],
                [110.94292449976592, -1.7103799571692662],
                [110.9526748657525, -1.684106945279268],
                [110.96296394183253, -1.6721796263885835],
                [110.97449408863133, -1.6691206085292265],
                [110.98602423632951, -1.6764151913079672],
                [111.00327986567333, -1.6967814137312303],
                [111.00493721817816, -1.7237807954472828],
                [111.01614834037366, -1.744279995225554],
                [111.01215230628395, -1.7578760295550637],
                [111.01385720054941, -1.7776858648107918],
                [111.00753395213025, -1.7963084357856474],
                [110.98296716280436, -1.8235993048639898],
                [110.97672700274904, -1.844688490481417],
                [110.97462858853856, -1.878446858335792],
                [110.99355486596437, -1.8972944100089535],
                [110.99602187540927, -1.909078895874302],
                [110.99043055102919, -1.9295788708681698],
                [110.9795796477826, -1.9457392293818998],
                [110.98458637506121, -1.9557795287022373],
                [111.01798996936168, -1.956085626450431],
                [111.05531885797899, -1.9932539123381616],
                [111.05933793359884, -2.0145866622445965],
                [111.09001131027384, -2.0162046730072234],
                [111.0830708760202, -2.04510371336562],
                [111.06005292700104, -2.058235266744134],
                [111.05731572254263, -2.0717972131707825],
                [111.07075500593885, -2.141464706331078],
                [111.0787810981692, -2.1611959930006037],
                [111.07233808793245, -2.1782271785414196],
                [111.06361768670695, -2.185128322314199],
                [111.06300352169524, -2.202901353214827],
                [111.04064178638941, -2.2222206532604787],
                [111.03189085767701, -2.2410564202175465],
                [111.04471587353879, -2.267399309430516],
                [111.05852507687086, -2.278117643614337],
                [111.06958388538806, -2.29957650994794],
                [111.07978820666045, -2.3388810184442264],
                [111.08951568130374, -2.3629221810138006],
                [111.09503936677339, -2.3947326955574226],
                [111.10498044479175, -2.4333453025436143],
                [111.10546109015723, -2.484213107748701],
                [111.11429594540164, -2.5151555444927567],
                [111.11608886980554, -2.541728975811509],
                [111.13442990711457, -2.595491646522248],
                [111.13629911920907, -2.61128377942282],
                [111.1294403020284, -2.6329369298953793],
                [111.11857604384943, -2.6526453297181547],
                [111.12815853608765, -2.6660590013217984],
                [111.14396667512204, -2.6719565295701386],
                [111.16380689037572, -2.671698918770062],
                [111.1716308293993, -2.6859221259053356],
                [111.16931709231, -2.7062645324821233],
                [111.15730787216063, -2.718254888452293],
                [111.14637282114882, -2.741369858132373],
                [111.11236477001819, -2.758616191184217],
                [111.10152467392464, -2.767778656859491],
                [111.05786343664835, -2.782491518768552],
                [111.03291925581908, -2.785654777945581],
                [111.00686539791985, -2.8004546483632566],
                [110.99588158296876, -2.830537652490648],
                [110.97550755802746, -2.8423193063908343],
                [110.96664269240637, -2.8578475134519294],
                [110.9270328685721, -2.8683149107044983],
                [110.91482624273698, -2.866549033411104],
                [110.88856497419425, -2.8715180422238404],
                [110.87983397086111, -2.8818107101960777],
                [110.8381317591734, -2.895586385902959],
                [110.82478396920487, -2.9067134115946374],
                [110.79969480866856, -2.9070457812377413],
                [110.75517328621822, -2.942372122475433],
                [110.73693719079876, -2.9820561511704113],
                [110.73677950726852, -2.982337768473201],
                [110.73447882204005, -2.9882708352308214],
                [110.74585505616596, -2.988243673906368],
                [110.77813829214853, -2.9767129605346554],
                [110.8018742715804, -2.9769096611519217],
                [110.83138369979798, -2.986234180163251],
                [110.84797006716491, -2.9950900417719595],
                [110.91511560230327, -3.0443390969717257],
                [110.94180475928226, -3.0536850585185675],
                [110.9540167549693, -3.0633010891738763],
                [110.9827150882308, -3.0655156921953903],
                [111.03680032867231, -3.0545821061792253],
                [111.11071166501137, -3.0285285819284695],
                [111.19124009695298, -2.9898941618860135],
                [111.26673340462298, -2.9432195302570676],
                [111.33430701525663, -2.9117117446012912],
                [111.35382027118862, -2.906677132943287],
                [111.38859503213507, -2.9199245279945103],
                [111.41181575669185, -2.9037981899344913],
                [111.42645284532261, -2.9185193103178904],
                [111.46062046316388, -2.9276177937261423],
                [111.48992743060649, -2.9453769579801587],
                [111.50792709875668, -2.9667006157407627],
                [111.52256198314905, -2.976020907938505],
                [111.54254059327383, -3.0036376661453232],
                [111.55471880772683, -3.010966642596429],
                [111.58339348072462, -2.99254470317544],
                [111.59925715379467, -2.9880473869772572],
                [111.63218153175234, -2.9597385428994016],
                [111.66210494218939, -2.946439741700658],
                [111.69044709265927, -2.94180993827797],
                [111.69810661223045, -2.9245995106588225],
                [111.68062982970292, -2.915566251481266],
                [111.6692711512427, -2.8942623257456717],
                [111.68119236283735, -2.87483043417717],
                [111.71792006815667, -2.84707178543448],
                [111.73181979185, -2.855701496326958],
                [111.7271907429585, -2.8673512313741867],
                [111.75012235616185, -2.8873539451284387],
                [111.7696161435702, -2.934982384229613],
                [111.7909600738484, -2.962630730223964],
                [111.81402940203532, -3.001186972200969],
                [111.82497672879305, -3.0506177075411074],
                [111.81720626435765, -3.103578317885024],
                [111.80765742385108, -3.1290150915731942],
                [111.80897427054623, -3.147392164716564],
                [111.80328676277395, -3.1609041762866354],
                [111.79788446689469, -3.203948781710835],
                [111.81686380701916, -3.303299366672377],
                [111.8198444840358, -3.374512378943109],
                [111.81674564509552, -3.4025165956643377],
                [111.8077601273281, -3.4426216869412656],
                [111.79654947727664, -3.4717444489471063],
                [111.77186444059151, -3.5093597005896413],
                [111.78229694235006, -3.5189209058750635],
                [111.80623900682474, -3.5307573096021088],
                [111.84083680187695, -3.53900539678159],
                [111.8901951687721, -3.543584050363563],
                [111.92176646814806, -3.527393419770476],
                [111.95986427621546, -3.492016452996836],
                [112.01187037221996, -3.4512171183752685],
                [112.08472216059931, -3.4030096273923505],
                [112.17905978598037, -3.344040640163371],
                [112.2225617901945, -3.3236133115050848],
                [112.25642831681341, -3.3135943683853384],
                [112.30897391431472, -3.3106688225080916],
                [112.33590723722932, -3.3151956958246274],
                [112.40604070511449, -3.344567782277295],
                [112.42087733532082, -3.352781241960713],
                [112.43116686196117, -3.3738967147615426],
                [112.44577166852775, -3.3876074944902257],
                [112.5094291813117, -3.428988230922073],
                [112.54377820589957, -3.444778372723647],
                [112.56323157687586, -3.446816290439415],
                [112.57440658769394, -3.433013628775882],
                [112.60764900369861, -3.415098989726573],
                [112.64103816298086, -3.4038366034764067],
                [112.66644389566932, -3.3751421166153364],
                [112.68741770995939, -3.3626443658447442],
                [112.73784170872796, -3.338927498804992],
                [112.79175747192983, -3.308838240792113],
                [112.81954458331597, -3.296408560606551],
                [112.89220043460239, -3.254647535954973],
                [112.91000227798281, -3.2464195657103687],
                [112.93854755922257, -3.2261447548764863],
                [112.97390071914299, -3.1950599788349336],
                [113.00936342997841, -3.160173449245235],
                [112.97650000357237, -3.151583078296369],
                [112.9475741427326, -3.1307011036729477],
                [112.94215815377584, -3.117680041900371],
                [112.94538692136939, -3.1023041916850502],
                [112.98155576188901, -3.05421382480813],
                [113.01129759920491, -3.0333713645967464],
                [113.01825725197068, -3.0222004771702586],
                [113.03249378885732, -2.976400215897172],
                [113.05273853805318, -2.9836510331685986],
                [113.05804052984661, -3.0069324916411233],
                [113.08242327561379, -3.0227451956342293],
                [113.09705971853123, -3.039807268390689],
                [113.13251962438119, -3.057071110343429],
                [113.1679188430802, -3.0692609071650736],
                [113.1909798300552, -3.0947002520149454],
                [113.18951758636763, -3.106790371578313],
                [113.21412324079495, -3.1342026203545856],
                [113.23697266135935, -3.1541345646583636],
                [113.29176527704601, -3.2118149169248227],
                [113.33664350929064, -3.2747581449723384],
                [113.31875703520905, -3.2314956643933215],
                [113.32742459676774, -3.2128123648986957],
                [113.33558015719859, -3.233549766308613],
                [113.32922938502816, -3.2420392639008355],
                [113.34961127004419, -3.266590946415022],
                [113.36383925617679, -3.2716031712492963],
                [113.37247617963203, -3.260474221008451],
                [113.39166626238818, -3.247751986864671],
                [113.4276201478516, -3.236502091298405],
                [113.43567505795761, -3.225831984158674],
                [113.43650931405364, -3.21194354335978],
                [113.45041435723681, -3.1904394636102893],
                [113.49405129483148, -3.1731635968224623],
                [113.54044849370314, -3.162282234437498],
                [113.56074254823011, -3.1614160362210555],
                [113.59557142318272, -3.166918728816256],
                [113.60266758673981, -3.1774098088155824],
                [113.61713866591674, -3.2127664122400574],
                [113.63008009721875, -3.2633955499646277],
                [113.63331467892931, -3.297551291358957],
                [113.62846277893414, -3.3416365517853706],
                [113.61798232082754, -3.3762229002666118],
                [113.59718898878089, -3.4206191147802656],
                [113.58195872531496, -3.444503694589798],
                [113.62690177259879, -3.464776531411758],
                [113.6935975154131, -3.469261498119579],
                [113.76816814834149, -3.4632578426752616],
                [113.79737008396228, -3.457684252925901],
                [113.83302868602505, -3.444682281961718],
                [113.85256128637434, -3.434448219209912],
                [113.90837920113927, -3.3968646956491284],
                [113.96222097351915, -3.365278274172965],
                [113.99900442061755, -3.3543638706960337],
                [114.03533415974368, -3.351101871354217],
                [114.04408631603076, -3.34658512440393],
                [114.0467771100756, -3.3221318169297547],
                [114.05287617639453, -3.3108106096219103],
                [114.06774125935169, -3.3228409233698812],
                [114.08019513309898, -3.3496457619423268],
                [114.1154258269404, -3.373773172194092],
                [114.13482314677083, -3.382541983925364],
                [114.15353419124995, -3.3846796634356906],
                [114.18319375588715, -3.3933855334155965],
                [114.22368861513758, -3.375270940001826],
                [114.25321857667609, -3.3681524049094946],
                [114.26257768541188, -3.375813569340721],
                [114.24785631591635, -3.3876616696502992],
                [114.25462738264764, -3.40339176551754],
                [114.28196899507316, -3.4215606698889474],
                [114.30618168803448, -3.446190099693297],
                [114.32178220958133, -3.4556474863424],
                [114.3471385398118, -3.4608900302506527],
                [114.35434031885075, -3.441700046419953],
                [114.3476342991836, -3.4359978041553063],
                [114.3627464600811, -3.386775132366438],
                [114.35150261425429, -3.368082681370595],
                [114.36255883811998, -3.359101633233763],
                [114.34926948609791, -3.3483473522484815],
                [114.3621744795687, -3.329721031999952],
                [114.3577614316448, -3.3216436703190766],
                [114.37622297181281, -3.2884838948158404],
                [114.43113441312278, -3.247900221218174],
                [114.44936603711301, -3.2096548982548825],
                [114.43378410185483, -3.186586174412355],
                [114.42345527304246, -3.1798829876097443],
                [114.44394311528265, -3.1642934270000183],
                [114.4582813645934, -3.1433042675778324],
                [114.50363189019959, -3.104187050991584],
                [114.53334396894911, -3.0810124427699748],
                [114.54594877411671, -3.0677356956458084],
                [114.52800052110717, -3.044675067500293],
                [114.53096099575339, -3.0232594387720155],
                [114.50671369418171, -2.999378926596137],
                [114.54626840666174, -2.951662966372737],
                [114.54963794693379, -2.9432876017413605],
                [114.53822078915812, -2.9271226917587683],
                [114.56727600488375, -2.864029777851897],
                [114.59231404255911, -2.881108244349946],
                [114.59909804783672, -2.8909082574101035],
                [114.61545093411337, -2.884553995870192],
                [114.60312974004557, -2.8465332866817903],
                [114.59691411529423, -2.8157670316231815],
                [114.62705723460374, -2.8069137114985665],
                [114.65685591356964, -2.803326775410312],
                [114.66649888251743, -2.7984431005685906],
                [114.67884118097584, -2.7816058996924653],
                [114.67898449963502, -2.769109293758845],
                [114.67086981788782, -2.752769691368087],
                [114.6796372005964, -2.7385046621603806],
                [114.69301809460944, -2.7354025002251774],
                [114.71770763779728, -2.740214474818117],
                [114.7626184021176, -2.7198655616463157],
                [114.77347104825037, -2.6948192016448047],
                [114.78693213481029, -2.682450607009855],
                [114.79383361133216, -2.6497671763140715],
                [114.7941057605725, -2.600536850395258],
                [114.80246401200463, -2.5836117749634013],
                [114.83823907267515, -2.5576404856198565],
                [114.84899079239119, -2.5343969209810666],
                [114.86951967069626, -2.511870573767908],
                [114.88614588162716, -2.5036779486783303],
                [114.91457911273596, -2.459341385296625],
                [114.97891532228437, -2.4203059323728553],
                [115.00399599861646, -2.399196988650033],
                [115.2490526505594, -2.244720914738366],
                [115.25357663885086, -2.239225600181271],
                [115.24282954524733, -2.2154492285989704],
                [115.2550401218042, -2.2084366489321496],
                [115.26388337671654, -2.221148827756167],
                [115.29072897436276, -2.225570455212278],
                [115.31473209381113, -2.1934346972534513],
                [115.32051803777733, -2.1680468898520644],
                [115.32993515908038, -2.16262783960309],
                [115.30940275363423, -2.149047985875825],
                [115.30356057326196, -2.1369284584815205],
                [115.31766073875212, -2.1240893151697264],
                [115.31691565313497, -2.0857686667905],
                [115.32289414914578, -2.072138089501209],
                [115.34537575328841, -2.0822540721676432],
                [115.35201440564038, -2.0736532906668117],
                [115.40846593775291, -2.0580233819734417],
                [115.40428171063252, -1.989445387794376],
                [115.42939173004436, -1.939021375536015],
                [115.43151229275816, -1.9256539722529737],
                [115.4203790878247, -1.9001861314847588],
                [115.4053900315248, -1.8866824997974732],
                [115.3747776090994, -1.8785855796607507],
                [115.35568662263961, -1.8503283063062668],
                [115.36680038423037, -1.8415724385171757],
                [115.36024705955424, -1.8265675964174193],
                [115.36614276127943, -1.8069940958008601],
                [115.37744542996268, -1.801392187299541],
                [115.39698548479237, -1.741857300773745],
                [115.3883312089182, -1.7372522736734481],
                [115.38168032308852, -1.7191038594555152],
                [115.3980519917061, -1.6776651005638996],
                [115.41369790833187, -1.6501835689165318],
                [115.42869196756033, -1.6090557209446956],
                [115.45054883426133, -1.5594019627303624],
                [115.4686402782263, -1.5368468093327579],
                [115.48858187765154, -1.5036649509766562],
                [115.50000001299031, -1.4787196136192051],
                [115.50724428589513, -1.4733425275355785],
                [115.51359817421644, -1.4503599353626555],
                [115.52376341814727, -1.4302425157794119],
                [115.56272054191959, -1.4004259654858515],
                [115.57724158832866, -1.3805388164132069],
                [115.59827043932933, -1.377760291704078],
                [115.61529779285888, -1.3668054861842052],
                [115.62616860080038, -1.3504280708987153],
                [115.64126722060576, -1.3512238216219998],
                [115.66737161680669, -1.3349505739097935],
                [115.69035717494376, -1.338234440267172],
                [115.71631180794361, -1.3363069754864796],
                [115.75398994737827, -1.3150423402317415]
              ]
            ]
          ]
        },
        "properties": {
          "ID": 27,
          "kode": 62,
          "Propinsi": "KALIMANTAN TENGAH",
          "SUMBER": "Batas Provinsi Desember 2019 Dukcapil (Skala tidak diketahui)"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [115.77170848835613, -4.665430925196972],
                [115.7614740721707, -4.661880812749246],
                [115.74891611358782, -4.673556234232763],
                [115.72551143270653, -4.687003603433482],
                [115.71993773323982, -4.70440905353496],
                [115.73774966701353, -4.702080893118648],
                [115.7654114120071, -4.678722333968494],
                [115.77170848835613, -4.665430925196972]
              ]
            ],
            [
              [
                [115.69636690261052, -4.640895064455606],
                [115.70917770468736, -4.624403754529624],
                [115.70407062565891, -4.619202989526286],
                [115.68642574994851, -4.632097609317157],
                [115.69636690261052, -4.640895064455606]
              ]
            ],
            [
              [
                [116.32133442324505, -3.8158271582966563],
                [116.31433985070362, -3.8193126328716858],
                [116.29701009923679, -3.8460102875446864],
                [116.3139437416105, -3.84140555452268],
                [116.32133442324505, -3.8158271582966563]
              ]
            ],
            [
              [
                [116.02726517394865, -3.4573415851459686],
                [116.03484719354321, -3.4478691519397557],
                [116.03123389472853, -3.420879577330595],
                [116.02118664185014, -3.4284133240156507],
                [116.01621074872719, -3.4452531077446906],
                [116.02726517394865, -3.4573415851459686]
              ]
            ],
            [
              [
                [116.41050744806034, -3.361709571839242],
                [116.39983041524158, -3.3833542884696044],
                [116.39815173261638, -3.400102994257736],
                [116.39023245826138, -3.413483076182956],
                [116.36955049735764, -3.4263915248489525],
                [116.34523891224603, -3.451592698644106],
                [116.33320485282172, -3.5042352232769076],
                [116.31338357459231, -3.551338434920808],
                [116.33339886266742, -3.558712016846073],
                [116.34336156668303, -3.568576003179942],
                [116.3539800815156, -3.6007709734016657],
                [116.34989217538362, -3.6105281697392115],
                [116.3623216945906, -3.6523041582103133],
                [116.38438485767506, -3.647094174256665],
                [116.38669401631591, -3.6274851063525375],
                [116.3991335386819, -3.6259856433227355],
                [116.40243291564661, -3.5906339340087925],
                [116.4082004693762, -3.5701627472129758],
                [116.40559239857043, -3.554307568239608],
                [116.40993912868794, -3.520949053356105],
                [116.4200879662867, -3.4919753063152825],
                [116.4202164892996, -3.463128381669435],
                [116.43475387279307, -3.4216783129037935],
                [116.43148745418273, -3.4079916475964183],
                [116.43522975265103, -3.383933171278784],
                [116.43138345298405, -3.367549604630483],
                [116.41050744806034, -3.361709571839242]
              ]
            ],
            [
              [
                [116.0608697802162, -3.3837404015982315],
                [116.0689539299799, -3.3786866343806423],
                [116.07237945215002, -3.351391160773403],
                [116.05823204167541, -3.3531265861229826],
                [116.05355716872839, -3.3790002027971013],
                [116.0608697802162, -3.3837404015982315]
              ]
            ],
            [
              [
                [116.25975486689367, -3.2120414291685506],
                [116.23607808173813, -3.22391967295448],
                [116.22884140360793, -3.234725121766928],
                [116.19528489308345, -3.2585217029142086],
                [116.13874550260289, -3.301871287330016],
                [116.1248215475764, -3.298621057415801],
                [116.1139383829501, -3.3081104896073725],
                [116.10391798652597, -3.3427688971668204],
                [116.10302786634304, -3.3562641085017617],
                [116.08936441163837, -3.391489413605484],
                [116.0790621775775, -3.408735798817986],
                [116.06948326554038, -3.439423782281665],
                [116.04608876498457, -3.4687585378761696],
                [116.04144757164818, -3.487624856601826],
                [116.0403510498611, -3.520621328711286],
                [116.04672185622871, -3.531918514227982],
                [116.03657660152896, -3.5819757514797175],
                [116.02869660956094, -3.595217524868815],
                [116.01003003565768, -3.611988794647118],
                [116.00238198963723, -3.63791014733539],
                [116.01221622280048, -3.65412229870509],
                [116.00612475575292, -3.6825611793548774],
                [116.01371155704283, -3.689821582499974],
                [116.02422320633593, -3.7295671967686417],
                [116.05263045423214, -3.761963809382223],
                [116.07114908965877, -3.7945196082932284],
                [116.0816578242493, -3.833398344457976],
                [116.07396404320605, -3.848565506992486],
                [116.06358433165167, -3.847315972753904],
                [116.05985938560218, -3.8734596189661943],
                [116.0651156972317, -3.894836553464131],
                [116.07855007932278, -3.9254545777665157],
                [116.06854120411333, -3.937305318485585],
                [116.0651743258345, -3.963078666147453],
                [116.05002020167103, -3.989353804934126],
                [116.05669072239903, -4.004298957231072],
                [116.03893061025951, -4.022375226935139],
                [116.05578401961691, -4.042292796475351],
                [116.07329024684748, -4.045849691609931],
                [116.09084106696173, -4.060652087323945],
                [116.12290668409628, -4.027387926611482],
                [116.14116260686887, -4.035779821681388],
                [116.1490573791948, -4.030340231816183],
                [116.17213661251469, -3.9971911645405385],
                [116.19383911508316, -3.984495217580161],
                [116.20914644415313, -3.9835155977665977],
                [116.20264711926916, -3.9595894809697256],
                [116.21489702677638, -3.9474164798866127],
                [116.2455025639922, -3.939202958149963],
                [116.27211427585326, -3.9075590430153966],
                [116.28653184087182, -3.909177332567822],
                [116.29135799057883, -3.9190777504377934],
                [116.3099717410031, -3.9152277689463517],
                [116.30448135563017, -3.885048721721091],
                [116.30948466458563, -3.870691172959198],
                [116.29497042514504, -3.8629371049633505],
                [116.29847037520767, -3.848216977431491],
                [116.28539748010974, -3.8412759154510923],
                [116.27191444829316, -3.820934609644496],
                [116.27638378298525, -3.7974954226755813],
                [116.2858554517677, -3.7969628855286714],
                [116.29412855269061, -3.7844383223706473],
                [116.31289049609961, -3.7742124497447094],
                [116.33673014667897, -3.7258363691530576],
                [116.31497684230749, -3.7286261983403506],
                [116.2993832338492, -3.7086470162467435],
                [116.30259274695777, -3.6867770734031637],
                [116.29534332723279, -3.6704225305752516],
                [116.30094184439065, -3.6400643932570915],
                [116.28954736391972, -3.6169349345994988],
                [116.29323622916866, -3.6018834392692725],
                [116.3064557623236, -3.5905062356741837],
                [116.29144274358782, -3.5663800404065],
                [116.27292248668346, -3.553047902238859],
                [116.27015657586537, -3.525976175460073],
                [116.30234567171544, -3.505379139335787],
                [116.31170264186346, -3.5070909260053895],
                [116.31416885540841, -3.4794924896866064],
                [116.30990682793777, -3.4556162546863334],
                [116.2879236379656, -3.4231466468002623],
                [116.26236457626419, -3.401109966951367],
                [116.25311504452372, -3.3886253237996016],
                [116.2506012037826, -3.3613844273493783],
                [116.26191226763706, -3.336422298750051],
                [116.26849964514258, -3.30475920647217],
                [116.2679462671066, -3.288713434177623],
                [116.27587378015085, -3.264477833685021],
                [116.2698979119591, -3.2528511707448615],
                [116.28133554190185, -3.22267904560141],
                [116.26708262026682, -3.2206849122788412],
                [116.25975486689367, -3.2120414291685506]
              ]
            ],
            [
              [
                [116.17562017153375, -3.036957093794797],
                [116.16715861497812, -3.0405863834548086],
                [116.17234667615821, -3.057235238415501],
                [116.18688597610696, -3.043449183632845],
                [116.17562017153375, -3.036957093794797]
              ]
            ],
            [
              [
                [116.12849906877705, -2.994194897976115],
                [116.11335937963167, -2.9968603086562666],
                [116.11041831954992, -3.0071341376303167],
                [116.11801707822565, -3.0075293114285273],
                [116.12289997785172, -3.011649195653831],
                [116.13574408901854, -3.005349253165434],
                [116.12849906877705, -2.994194897976115]
              ]
            ],
            [
              [
                [116.14854505790811, -2.9900271860057615],
                [116.13373602699608, -2.9950493942141065],
                [116.1384420497609, -3.0056678667785945],
                [116.1602159000438, -3.014175449737195],
                [116.155348323966, -3.030364144989278],
                [116.16925678727102, -3.037458054845331],
                [116.17703592476812, -2.998348842225255],
                [116.14854505790811, -2.9900271860057615]
              ]
            ],
            [
              [
                [116.1501472253143, -2.8990178265114537],
                [116.14494811956013, -2.9165245825433885],
                [116.1500421629155, -2.935760445288281],
                [116.18689182170033, -2.9506457539982875],
                [116.20342816877508, -2.9429438134062025],
                [116.18824803621794, -2.9275617299898045],
                [116.1800021038141, -2.927434826655883],
                [116.16972821098818, -2.9117264269330008],
                [116.1501472253143, -2.8990178265114537]
              ]
            ],
            [
              [
                [116.31428368084721, -2.537824704946104],
                [116.30638907219793, -2.5400556549444673],
                [116.30722820172025, -2.565630478163996],
                [116.29669964067591, -2.5725904681755196],
                [116.29029836527741, -2.586572691176684],
                [116.2907026977714, -2.614553532719299],
                [116.31617771333083, -2.5930099781279523],
                [116.32409312509776, -2.5757883863249162],
                [116.32275180784973, -2.5542414376921556],
                [116.31428368084721, -2.537824704946104]
              ]
            ],
            [
              [
                [116.55893137508292, -2.4051927633353216],
                [116.53876748573089, -2.3999790885640664],
                [116.49283823739484, -2.38097350622229],
                [116.45450760294364, -2.3705188478664923],
                [116.37429890157307, -2.3549788363042126],
                [116.30809199964608, -2.340669654880628],
                [116.27293199971211, -2.3392936543785936],
                [116.23623299985309, -2.327163654379092],
                [116.22664600021278, -2.308784654887859],
                [116.16113900016478, -2.3065289996135903],
                [116.1481577702649, -2.319511344672776],
                [116.08147950397597, -2.317039655155895],
                [116.03434217025506, -2.3249118841760605],
                [116.00612699956139, -2.3146786541964843],
                [115.99317075552597, -2.3213936221179097],
                [115.98341690556583, -2.3139077067751828],
                [115.96687404829879, -2.327860325893255],
                [115.97044729041022, -2.3351176029949556],
                [115.94539416480893, -2.341479561832273],
                [115.93599997172134, -2.3340338138094125],
                [115.91970893002315, -2.336917308287127],
                [115.8903077118315, -2.3356261444309894],
                [115.89127671874564, -2.351257472254474],
                [115.88425769004039, -2.3745528971983845],
                [115.87183888355764, -2.372384646134492],
                [115.85808061063176, -2.386906717770785],
                [115.84488374129171, -2.3530533014686625],
                [115.83116277404281, -2.3253153101533712],
                [115.83264928683514, -2.3129779759527764],
                [115.85132467229585, -2.3012908911616705],
                [115.83938794110065, -2.290349990959271],
                [115.8220234605144, -2.2577982434941077],
                [115.81463285622135, -2.2341891485103247],
                [115.81704122357371, -2.218701456806741],
                [115.80211892304987, -2.206727209493067],
                [115.80711889552072, -2.1986309827336186],
                [115.79960068462401, -2.184475020605646],
                [115.8082905529667, -2.170914481618013],
                [115.81215100976135, -2.0938492894131286],
                [115.83201030233363, -2.0813992944418374],
                [115.8365955938117, -2.0591879851373847],
                [115.82156568670712, -2.054590585187327],
                [115.80376968981943, -2.037827129618279],
                [115.78493119521852, -2.0429552869568965],
                [115.77667436572949, -2.050854094540796],
                [115.75367176184238, -2.0504681019212967],
                [115.74098911315707, -2.0364090957031635],
                [115.72471057833059, -2.026200929828974],
                [115.74308128877055, -2.0094065168971724],
                [115.7476063805301, -1.9951180774372688],
                [115.73552144106168, -1.9849097029203904],
                [115.71689171597995, -1.9902360681400069],
                [115.71062182253911, -1.9798968746409855],
                [115.6961962139842, -1.9744231932756975],
                [115.7067794043461, -1.9603443848853885],
                [115.70621607531166, -1.9497067306810436],
                [115.72395225559217, -1.9222646343054066],
                [115.73053737939665, -1.8698523469082602],
                [115.72553301014034, -1.853118697569812],
                [115.72974115293198, -1.8443941864425142],
                [115.72170698568891, -1.8256783332884083],
                [115.72672172682633, -1.8036336431780455],
                [115.70811834288725, -1.7882081782647674],
                [115.69435486198734, -1.8008066647956866],
                [115.68083629166165, -1.7891570493582662],
                [115.69008613906408, -1.7790479456061803],
                [115.68960072189589, -1.7532238707849501],
                [115.70168389689434, -1.7426786192228292],
                [115.70774264570662, -1.7275772555858566],
                [115.6908865473763, -1.7239436311935492],
                [115.6793399798562, -1.7102153533558067],
                [115.66591440662467, -1.7080954066776144],
                [115.66762667982744, -1.6793759015765772],
                [115.67447858481887, -1.6659136557905185],
                [115.6654484759664, -1.6281206468503342],
                [115.63652150266523, -1.60348628696255],
                [115.63996853122285, -1.5853772702448055],
                [115.63088247914311, -1.5783974312963664],
                [115.64539849024823, -1.5609510395588018],
                [115.63141932674057, -1.530270241678238],
                [115.64719851390032, -1.511441892329401],
                [115.62737132802442, -1.5030359462518845],
                [115.61889672230598, -1.4878552102497338],
                [115.63525946425295, -1.4638232207649935],
                [115.67883333329178, -1.4563055548574084],
                [115.6877165001165, -1.4450292075318316],
                [115.69904548116403, -1.4149444524244927],
                [115.71748917878267, -1.3978117945786153],
                [115.7221071291408, -1.3751254157053268],
                [115.74159515998872, -1.36403570288536],
                [115.74471992368558, -1.3348671267164605],
                [115.75398994737827, -1.3150423402317415],
                [115.71631180794361, -1.3363069754864796],
                [115.69035717494376, -1.338234440267172],
                [115.66737161680669, -1.3349505739097935],
                [115.64126722060576, -1.3512238216219998],
                [115.62616860080038, -1.3504280708987153],
                [115.61529779285888, -1.3668054861842052],
                [115.59827043932933, -1.377760291704078],
                [115.57724158832866, -1.3805388164132069],
                [115.56272054191959, -1.4004259654858515],
                [115.52376341814727, -1.4302425157794119],
                [115.51359817421644, -1.4503599353626555],
                [115.50724428589513, -1.4733425275355785],
                [115.50000001299031, -1.4787196136192051],
                [115.48858187765154, -1.5036649509766562],
                [115.4686402782263, -1.5368468093327579],
                [115.45054883426133, -1.5594019627303624],
                [115.42869196756033, -1.6090557209446956],
                [115.41369790833187, -1.6501835689165318],
                [115.3980519917061, -1.6776651005638996],
                [115.38168032308852, -1.7191038594555152],
                [115.3883312089182, -1.7372522736734481],
                [115.39698548479237, -1.741857300773745],
                [115.37744542996268, -1.801392187299541],
                [115.36614276127943, -1.8069940958008601],
                [115.36024705955424, -1.8265675964174193],
                [115.36680038423037, -1.8415724385171757],
                [115.35568662263961, -1.8503283063062668],
                [115.3747776090994, -1.8785855796607507],
                [115.4053900315248, -1.8866824997974732],
                [115.4203790878247, -1.9001861314847588],
                [115.43151229275816, -1.9256539722529737],
                [115.42939173004436, -1.939021375536015],
                [115.40428171063252, -1.989445387794376],
                [115.40846593775291, -2.0580233819734417],
                [115.35201440564038, -2.0736532906668117],
                [115.34537575328841, -2.0822540721676432],
                [115.32289414914578, -2.072138089501209],
                [115.31691565313497, -2.0857686667905],
                [115.31766073875212, -2.1240893151697264],
                [115.30356057326196, -2.1369284584815205],
                [115.30940275363423, -2.149047985875825],
                [115.32993515908038, -2.16262783960309],
                [115.32051803777733, -2.1680468898520644],
                [115.31473209381113, -2.1934346972534513],
                [115.29072897436276, -2.225570455212278],
                [115.26388337671654, -2.221148827756167],
                [115.2550401218042, -2.2084366489321496],
                [115.24282954524733, -2.2154492285989704],
                [115.25357663885086, -2.239225600181271],
                [115.2490526505594, -2.244720914738366],
                [115.00399599861646, -2.399196988650033],
                [114.97891532228437, -2.4203059323728553],
                [114.91457911273596, -2.459341385296625],
                [114.88614588162716, -2.5036779486783303],
                [114.86951967069626, -2.511870573767908],
                [114.84899079239119, -2.5343969209810666],
                [114.83823907267515, -2.5576404856198565],
                [114.80246401200463, -2.5836117749634013],
                [114.7941057605725, -2.600536850395258],
                [114.79383361133216, -2.6497671763140715],
                [114.78693213481029, -2.682450607009855],
                [114.77347104825037, -2.6948192016448047],
                [114.7626184021176, -2.7198655616463157],
                [114.71770763779728, -2.740214474818117],
                [114.69301809460944, -2.7354025002251774],
                [114.6796372005964, -2.7385046621603806],
                [114.67086981788782, -2.752769691368087],
                [114.67898449963502, -2.769109293758845],
                [114.67884118097584, -2.7816058996924653],
                [114.66649888251743, -2.7984431005685906],
                [114.65685591356964, -2.803326775410312],
                [114.62705723460374, -2.8069137114985665],
                [114.59691411529423, -2.8157670316231815],
                [114.60312974004557, -2.8465332866817903],
                [114.61545093411337, -2.884553995870192],
                [114.59909804783672, -2.8909082574101035],
                [114.59231404255911, -2.881108244349946],
                [114.56727600488375, -2.864029777851897],
                [114.53822078915812, -2.9271226917587683],
                [114.54963794693379, -2.9432876017413605],
                [114.54626840666174, -2.951662966372737],
                [114.50671369418171, -2.999378926596137],
                [114.53096099575339, -3.0232594387720155],
                [114.52800052110717, -3.044675067500293],
                [114.54594877411671, -3.0677356956458084],
                [114.53334396894911, -3.0810124427699748],
                [114.50363189019959, -3.104187050991584],
                [114.4582813645934, -3.1433042675778324],
                [114.44394311528265, -3.1642934270000183],
                [114.42345527304246, -3.1798829876097443],
                [114.43378410185483, -3.186586174412355],
                [114.44936603711301, -3.2096548982548825],
                [114.43113441312278, -3.247900221218174],
                [114.37622297181281, -3.2884838948158404],
                [114.3577614316448, -3.3216436703190766],
                [114.3621744795687, -3.329721031999952],
                [114.34926948609791, -3.3483473522484815],
                [114.36255883811998, -3.359101633233763],
                [114.35150261425429, -3.368082681370595],
                [114.3627464600811, -3.386775132366438],
                [114.3476342991836, -3.4359978041553063],
                [114.35434031885075, -3.441700046419953],
                [114.3471385398118, -3.4608900302506527],
                [114.36078086584439, -3.4650157016133107],
                [114.40667863071428, -3.486616077170197],
                [114.42933386876314, -3.4996596561681486],
                [114.48022804658717, -3.501677373312077],
                [114.50285735268471, -3.515209107913961],
                [114.51385934563234, -3.5424341079476562],
                [114.5551631045937, -3.556845689776708],
                [114.57111950316255, -3.571404590449447],
                [114.59701323227489, -3.6089967286160913],
                [114.61552742055403, -3.6494697343406983],
                [114.62036858624128, -3.673183461847259],
                [114.62302122205176, -3.711367267585274],
                [114.61171206835729, -3.7404831245964942],
                [114.60301369422666, -3.8059525203924522],
                [114.61121361731466, -3.861649087835076],
                [114.6095250628315, -3.8865884222178693],
                [114.62778560452205, -3.9632895625632045],
                [114.63731323811555, -4.026860596539279],
                [114.63512727940054, -4.060148142322873],
                [114.62752368507006, -4.0714642351862835],
                [114.63025475525285, -4.149130469495503],
                [114.61969648690342, -4.161193247870074],
                [114.6359443521502, -4.172940599828792],
                [114.6594542869858, -4.177649485135589],
                [114.6842827718325, -4.176990339632425],
                [114.7030143847062, -4.171919129164451],
                [114.74059166787129, -4.154741347834886],
                [114.83565394627271, -4.101231756240509],
                [114.93396927216429, -4.053573330145014],
                [115.00706889481376, -4.0130195697972795],
                [115.08446343853689, -3.979603040547829],
                [115.12103021017617, -3.968989080781398],
                [115.14379587418034, -3.956775566139413],
                [115.21602969701291, -3.924137701393761],
                [115.24719975666346, -3.9143286519457092],
                [115.2723311565577, -3.90125267127371],
                [115.37681888218572, -3.8611146639105414],
                [115.41161005126287, -3.8455681826254704],
                [115.4509976571162, -3.824427061159895],
                [115.475687870299, -3.8145276487319393],
                [115.48983901925544, -3.813558801897159],
                [115.58035416621192, -3.7737683114583547],
                [115.60900264465636, -3.7675520445911275],
                [115.63499138174689, -3.7509927315292657],
                [115.65618064302237, -3.7430773701244675],
                [115.7175543193764, -3.730662199600772],
                [115.73184432095866, -3.711449271366746],
                [115.76764987404249, -3.6931091576614676],
                [115.78822187394042, -3.67841672115469],
                [115.80058993527734, -3.654674133504386],
                [115.82346783107562, -3.636583248917759],
                [115.8557052430026, -3.623831286784423],
                [115.9153092217432, -3.6131204745302057],
                [115.95572520505425, -3.6115334589019312],
                [115.96977191124472, -3.6074743706456616],
                [115.97795637408751, -3.590594415999476],
                [115.99569528021311, -3.566133629763158],
                [115.99138843682692, -3.548177156950203],
                [115.99283778423637, -3.5182350064403636],
                [116.01465456365725, -3.4781314889769988],
                [116.00836826118632, -3.4555462577535536],
                [116.0072101016649, -3.432706582242929],
                [116.0124742131145, -3.4190192964033486],
                [116.02334779906175, -3.410755433316524],
                [116.02256779007155, -3.397457286824306],
                [116.031335974076, -3.364342329934516],
                [116.05052025440625, -3.350794305013096],
                [116.05992840497197, -3.335326654701305],
                [116.08610373160195, -3.281939099309966],
                [116.09234021702741, -3.282902666225027],
                [116.14522224910752, -3.2663038558382027],
                [116.1522065891628, -3.2584392476730955],
                [116.17687846696776, -3.2505816128510787],
                [116.18394189520234, -3.229057392784398],
                [116.17963285117526, -3.20797100315923],
                [116.17024465836437, -3.1843843867300166],
                [116.16798260171572, -3.165631162248303],
                [116.1388610717811, -3.152336771324883],
                [116.10960951786501, -3.14687544034922],
                [116.09117661930554, -3.1548560457698613],
                [116.08060992161063, -3.1714639842754195],
                [116.06818895765434, -3.1547813417854513],
                [116.07701636291483, -3.142830613541946],
                [116.08810211512059, -3.1489101915520337],
                [116.10670262392705, -3.137817957932384],
                [116.13161296776434, -3.1374410061974345],
                [116.16015487115044, -3.1532769432762393],
                [116.18127217497101, -3.160693721438065],
                [116.20844499993689, -3.1288437275559318],
                [116.27459773839496, -3.1250123890105215],
                [116.26490855958241, -3.1111834059893226],
                [116.23687849005034, -3.0959239802074876],
                [116.21821516359898, -3.0915165476504853],
                [116.21211445421875, -3.0777762305885403],
                [116.19766585179764, -3.075702755477721],
                [116.18848002893026, -3.065118400493759],
                [116.17257566603428, -3.061885116504868],
                [116.1629395391285, -3.038348719118062],
                [116.15388335083605, -3.033909056657592],
                [116.15629436039649, -3.0145885236414074],
                [116.1343749152702, -3.011432263887798],
                [116.12032898789255, -3.013854336908082],
                [116.11728471621677, -3.009464557143531],
                [116.10183508562079, -3.0088819889126057],
                [116.08467771440507, -2.9899255257423647],
                [116.11292323092243, -2.9734127696835344],
                [116.12090964416484, -2.9558806293874795],
                [116.10300620527221, -2.9439350479640325],
                [116.10537151398421, -2.9168676325351726],
                [116.13135357360864, -2.8888236091221984],
                [116.13202514324587, -2.841931242632711],
                [116.11912342240805, -2.8259496981200414],
                [116.12755216090352, -2.819341826863763],
                [116.14450587074941, -2.8389495215031957],
                [116.15251771771852, -2.8555373214901465],
                [116.15463566610322, -2.89813986786055],
                [116.171810951814, -2.9107690752325652],
                [116.18299053928206, -2.924265091460768],
                [116.20538589484329, -2.9357880203008335],
                [116.21310049519172, -2.9930185154920537],
                [116.22167759934177, -3.006860909203567],
                [116.26393635018246, -3.0048824537622068],
                [116.31611078398555, -2.9465171922146283],
                [116.33632088500087, -2.905919315623862],
                [116.34949205261614, -2.8884084694752232],
                [116.36391593896951, -2.8778650345436745],
                [116.35935769100467, -2.864496590745034],
                [116.37212100617955, -2.803254679459826],
                [116.36719803186372, -2.79816081327084],
                [116.35910690875687, -2.765185048950798],
                [116.36069957302698, -2.7197636612639258],
                [116.3692116768774, -2.658868108576314],
                [116.38195540212018, -2.628838988228665],
                [116.41076560295028, -2.5879138771236967],
                [116.3933260496575, -2.58569283135904],
                [116.37813912631964, -2.567255564792447],
                [116.35867171782559, -2.5661718736330386],
                [116.35323804280154, -2.5539143749484197],
                [116.32906380035422, -2.5826858006091697],
                [116.31607842637834, -2.608253244891273],
                [116.28710176373545, -2.625949973712295],
                [116.26262639409288, -2.6559606013006487],
                [116.246055129041, -2.636410467768826],
                [116.25029712231435, -2.629212151119816],
                [116.27204875934285, -2.638090553336326],
                [116.28755942063083, -2.615483524345109],
                [116.28768155126261, -2.5937359882252053],
                [116.29633331802825, -2.56909833771806],
                [116.30499272453471, -2.5670586015731374],
                [116.30252846431722, -2.5329430454851263],
                [116.33794850501954, -2.533980434149669],
                [116.35251974259211, -2.5183982102091704],
                [116.361471623017, -2.5246272177718083],
                [116.38292290896516, -2.5228112401465523],
                [116.39862779054022, -2.528595997007585],
                [116.42590610598211, -2.5000356494256266],
                [116.45548347078557, -2.5035382974544405],
                [116.4755861109104, -2.5125328615023363],
                [116.48157194808698, -2.5605142044669265],
                [116.48079989719758, -2.5711790595659068],
                [116.5094582960636, -2.5624220676242544],
                [116.52621627655992, -2.540517058415446],
                [116.52319563636956, -2.5195178931361966],
                [116.52803237289572, -2.4935240290107004],
                [116.54606480424741, -2.4598217563689104],
                [116.54373642169855, -2.454395821809669],
                [116.55893137508292, -2.4051927633353216]
              ]
            ]
          ]
        },
        "id": 63,
        "properties": {
          "ID": 11,
          "kode": 63,
          "Propinsi": "KALIMANTAN SELATAN",
          "SUMBER": "Batas Provinsi Desember 2019 Dukcapil (Skala tidak diketahui)"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [117.27233051494136, -0.8267141762182177],
                [117.26282872606487, -0.8353682002821756],
                [117.26839299973722, -0.8845819993597388],
                [117.2774709687103, -0.8809178456820064],
                [117.27303061467114, -0.8504031247043713],
                [117.26789724666071, -0.8443712179573026],
                [117.27620810382098, -0.8312452565633066],
                [117.27233051494136, -0.8267141762182177]
              ]
            ],
            [
              [
                [117.47590405612732, -0.8172677550028311],
                [117.46705370556413, -0.8264819559774423],
                [117.48209883279492, -0.839220243919101],
                [117.49335663340196, -0.8349175805818163],
                [117.47590405612732, -0.8172677550028311]
              ]
            ],
            [
              [
                [117.27598533275693, -0.8094139998561332],
                [117.2647892822996, -0.8161223784449589],
                [117.26395544079105, -0.8286752207847599],
                [117.27264436034898, -0.8260247568370005],
                [117.28191885187357, -0.832049146151121],
                [117.2845507151435, -0.8243542841228759],
                [117.27598533275693, -0.8094139998561332]
              ]
            ],
            [
              [
                [117.28657459803935, -0.7958787111335823],
                [117.28025961618835, -0.8132116102275972],
                [117.28790990331947, -0.825478496935034],
                [117.27648751688548, -0.8582137663522076],
                [117.28042856102934, -0.8741160122441443],
                [117.29381868928112, -0.8717610898024191],
                [117.30248398364904, -0.8478515322223643],
                [117.29613854086108, -0.8184210428967731],
                [117.29736828731666, -0.8068412535209859],
                [117.28657459803935, -0.7958787111335823]
              ]
            ],
            [
              [
                [117.44077678611984, -0.7799014267094435],
                [117.40654021006276, -0.7530828572880068],
                [117.410720996377, -0.781957759842669],
                [117.42771628457888, -0.7799489576781866],
                [117.43577957007119, -0.7851477162940341],
                [117.44394981103505, -0.8054317271923992],
                [117.46267303053594, -0.8204396917383292],
                [117.46645213915497, -0.8084321362340461],
                [117.44077678611984, -0.7799014267094435]
              ]
            ],
            [
              [
                [117.47207113297645, -0.7462227504794896],
                [117.470247020084, -0.7640682487506183],
                [117.48246523638159, -0.7702378012335203],
                [117.50087446298619, -0.7993763607304345],
                [117.52583235308066, -0.8137595158888757],
                [117.54439715070805, -0.8108887540127512],
                [117.55264599871396, -0.7921447808569155],
                [117.51760041053058, -0.7780566015308636],
                [117.4980757413025, -0.74966693717937],
                [117.48101066391109, -0.7500000001998046],
                [117.47207113297645, -0.7462227504794896]
              ]
            ],
            [
              [
                [117.46416061621949, -0.7503679784993551],
                [117.44257578904148, -0.7412219605250243],
                [117.43960682029854, -0.7535722368691609],
                [117.45438900195097, -0.7656379919997107],
                [117.46617813011665, -0.7827514385280097],
                [117.49243230120976, -0.80239574097061],
                [117.48239414497391, -0.7724160617518123],
                [117.46707865365704, -0.7619382332628106],
                [117.46416061621949, -0.7503679784993551]
              ]
            ],
            [
              [
                [117.38738846630474, -0.7395510894111794],
                [117.38214946482606, -0.7579825841289107],
                [117.38314251061843, -0.7746217578877577],
                [117.3926877286558, -0.7766466021589054],
                [117.41107011589315, -0.8199402775217663],
                [117.41410053261359, -0.8339262722795979],
                [117.42694712231196, -0.8313358371790969],
                [117.43995302691076, -0.8150355846244679],
                [117.4259518866703, -0.7857271162134225],
                [117.40764649399057, -0.7829929469668855],
                [117.39885485027116, -0.75179178156543],
                [117.38738846630474, -0.7395510894111794]
              ]
            ],
            [
              [
                [117.46009969424551, -0.7026600312353821],
                [117.44237615483576, -0.7116309449121445],
                [117.44689408529382, -0.7116185378652062],
                [117.44769091790158, -0.7142156198549401],
                [117.44557482391906, -0.7162983426943015],
                [117.44640725529064, -0.7181533751780762],
                [117.44915371737022, -0.718488222453459],
                [117.45194792175926, -0.7207140804903815],
                [117.45264982103242, -0.7250585659000421],
                [117.44605935615448, -0.7410420817266186],
                [117.49640629831208, -0.7480226707096449],
                [117.49824683732174, -0.7486902977190084],
                [117.50328058473121, -0.7561023526413919],
                [117.51259119302915, -0.7669924663292136],
                [117.51501532459758, -0.772760921179497],
                [117.55372727519364, -0.784963666440035],
                [117.58380621798267, -0.7807108318393716],
                [117.59805912432921, -0.785301706308644],
                [117.611492279745, -0.774049681147801],
                [117.61680436193183, -0.7585029039858],
                [117.6091108291015, -0.7307825375839911],
                [117.59141892424407, -0.7358680355813476],
                [117.57118099820457, -0.7295033428050033],
                [117.54261492553849, -0.7279567993667229],
                [117.5151735126476, -0.7363204512278116],
                [117.48982472053444, -0.7146541976330809],
                [117.47204180968185, -0.7132021333717375],
                [117.46009969424551, -0.7026600312353821]
              ]
            ],
            [
              [
                [117.40427302637909, -0.702104285284463],
                [117.38670782790405, -0.6947611517057339],
                [117.37919516582429, -0.7121401482492047],
                [117.38283402876743, -0.7206854110028758],
                [117.40124010594616, -0.7353093857195176],
                [117.42168731411834, -0.7584834066838084],
                [117.43910866783085, -0.7565151522524047],
                [117.4304705662637, -0.7220137087655871],
                [117.40427302637909, -0.702104285284463]
              ]
            ],
            [
              [
                [117.36774506228483, -0.6960032188733862],
                [117.36180388813898, -0.6935338927748944],
                [117.36221235481639, -0.6865633438231953],
                [117.35316199952672, -0.6831039999495943],
                [117.34875713633289, -0.693716026173604],
                [117.35726674636328, -0.6961325701619216],
                [117.36465071905548, -0.7048032559654303],
                [117.36544253254692, -0.7196562286519566],
                [117.37506523527247, -0.7158612245158338],
                [117.37806855182225, -0.6938294333816089],
                [117.36774506228483, -0.6960032188733862]
              ]
            ],
            [
              [
                [117.32034899990651, -0.7194399992565081],
                [117.31505400034189, -0.6860739993155676],
                [117.30676400025845, -0.6773979994180763],
                [117.29087899976605, -0.7281540000070663],
                [117.29298244928066, -0.7548404913954982],
                [117.3002002677282, -0.7711160809423063],
                [117.29126732432758, -0.7839641347370048],
                [117.29891413467965, -0.8017297165531545],
                [117.30529761069624, -0.8316389509767532],
                [117.34360285908474, -0.8323501168643475],
                [117.31966699993211, -0.7975979999476976],
                [117.34340320599324, -0.8129941127880329],
                [117.34619340120457, -0.8367994605266063],
                [117.31631299994501, -0.8491039991502021],
                [117.31051999989813, -0.8575089991409754],
                [117.31492399974286, -0.8782449994071726],
                [117.33216499981495, -0.9082259988307442],
                [117.343904999816, -0.9137789995011758],
                [117.36179600018534, -0.8879929992774578],
                [117.37568428809948, -0.8935365777508082],
                [117.39170699997817, -0.8793869999100821],
                [117.39021499952264, -0.8670829999804255],
                [117.3746250000438, -0.8478550000082237],
                [117.37043300008293, -0.8254319992872752],
                [117.3593909998948, -0.8130839991272296],
                [117.3485019995677, -0.7876979993631608],
                [117.32893099996579, -0.7665479997903049],
                [117.32748599987906, -0.7438299998545403],
                [117.32034899990651, -0.7194399992565081]
              ]
            ],
            [
              [
                [117.45409800022264, -0.6810959999803572],
                [117.43498377977767, -0.6630016979699462],
                [117.42771636731652, -0.6818455624227227],
                [117.40544948890283, -0.7010769141681976],
                [117.4218069176552, -0.7085464996212067],
                [117.44056015382807, -0.7295811602422759],
                [117.4464073614107, -0.7189108957211943],
                [117.44530150466073, -0.7173551018564126],
                [117.44545586429672, -0.7152606014955154],
                [117.44618077452321, -0.7117777025788428],
                [117.44027400034486, -0.7137659993013585],
                [117.43668300022034, -0.7114139996563154],
                [117.44798399976185, -0.7015519999247886],
                [117.46709094199343, -0.6991813789174444],
                [117.45409800022264, -0.6810959999803572]
              ]
            ],
            [
              [
                [117.49393087481008, -0.6598851217009383],
                [117.4749447160259, -0.6753022292144806],
                [117.47433399721626, -0.6862803496583751],
                [117.5089966765554, -0.6939895261956508],
                [117.52820665501315, -0.6898391819326548],
                [117.53061827611259, -0.6709076551641147],
                [117.52292731984005, -0.6602614457091249],
                [117.50888831634279, -0.6663034401515802],
                [117.49393087481008, -0.6598851217009383]
              ]
            ],
            [
              [
                [117.32948286804003, -0.6346442176564437],
                [117.3206941399227, -0.6396161861380278],
                [117.32350335039939, -0.6409729249604652],
                [117.32471091777472, -0.6424829649168373],
                [117.32341919633893, -0.6461198970155806],
                [117.3254330095258, -0.6497595846370814],
                [117.32499172838561, -0.6542698159991573],
                [117.34881011989114, -0.6789523013076177],
                [117.36318254523837, -0.6868217918921573],
                [117.36422939387148, -0.6945199184594344],
                [117.37543373517883, -0.6920774434192367],
                [117.3833787355403, -0.693803937601615],
                [117.36789652970117, -0.6637400503630033],
                [117.34471095064987, -0.6426290633805252],
                [117.32948286804003, -0.6346442176564437]
              ]
            ],
            [
              [
                [117.36430914215327, -0.6458856784812497],
                [117.3606669571144, -0.6514428025432721],
                [117.37745607393867, -0.671117924752366],
                [117.4108938145979, -0.6961213996827951],
                [117.41512415985221, -0.6763369756708357],
                [117.42503598292433, -0.6810147075626674],
                [117.43252910993056, -0.6599362652464151],
                [117.41216459621921, -0.6385421779791045],
                [117.39558571301029, -0.6304718894660937],
                [117.37979116902727, -0.6341310492085199],
                [117.37483918258226, -0.6309133082025369],
                [117.36972196819431, -0.6518485829457177],
                [117.36430914215327, -0.6458856784812497]
              ]
            ],
            [
              [
                [117.49051676863405, -0.6275616284663101],
                [117.46417615920245, -0.6263827215852871],
                [117.45915511381747, -0.6320157538239073],
                [117.47228643427161, -0.6597442482983524],
                [117.4889799999271, -0.647026999996001],
                [117.49796321003419, -0.6600572213636156],
                [117.51029246832286, -0.6630001835115991],
                [117.51313186364462, -0.6423903599271057],
                [117.49833454100633, -0.6390900009027973],
                [117.49051676863405, -0.6275616284663101]
              ]
            ],
            [
              [
                [117.32256377110127, -0.6328287571413966],
                [117.31141955306998, -0.6192444283876171],
                [117.30044300014436, -0.6326759488359244],
                [117.30713899956527, -0.6632999482339983],
                [117.31995199958521, -0.6878529482548856],
                [117.32364199977849, -0.7163329486066345],
                [117.33884000006606, -0.7377679999931388],
                [117.33201800023062, -0.7500089997155328],
                [117.3380240002075, -0.7630279993426257],
                [117.3538909998698, -0.7777639999086432],
                [117.36400200029209, -0.8074109992181775],
                [117.37644400029046, -0.818536999682749],
                [117.38567899988595, -0.8405479993905942],
                [117.40195899970809, -0.8609379998887334],
                [117.40998048021504, -0.8844199981843417],
                [117.41957248997846, -0.8649960008647213],
                [117.43624818970227, -0.8638187361457312],
                [117.43416527800525, -0.8441427739697929],
                [117.41123638255306, -0.8383775279005476],
                [117.38946799834946, -0.7768419286120434],
                [117.37322691758823, -0.7804720213666201],
                [117.3784596319066, -0.7653818219134791],
                [117.37230205659466, -0.7516663594149122],
                [117.3870199807875, -0.7310498376797909],
                [117.37512945406115, -0.7178135942219228],
                [117.36831389517022, -0.720463876506642],
                [117.36349862995792, -0.7198897726947848],
                [117.35660690928148, -0.6964438048376564],
                [117.3506383318936, -0.6950199891811053],
                [117.34845059881616, -0.693853211456485],
                [117.34723175753959, -0.6914057172999719],
                [117.34686000028887, -0.686086948653724],
                [117.35188900017727, -0.6835169488480801],
                [117.33161276661599, -0.6703360928363509],
                [117.32484262438766, -0.6549989593344776],
                [117.32498829567339, -0.650286370619483],
                [117.32018093190459, -0.6483103712266143],
                [117.32340513633801, -0.6455142863551941],
                [117.32341178412662, -0.6413242756947284],
                [117.32137420275728, -0.641679657190366],
                [117.32001872298576, -0.6400615798779654],
                [117.3224629597978, -0.6368250996985694],
                [117.32256377110127, -0.6328287571413966]
              ]
            ],
            [
              [
                [117.49987228997429, -0.6140052686282047],
                [117.49178401232234, -0.6028295545402784],
                [117.47875180974825, -0.6228985887987619],
                [117.5008292972343, -0.6244348376975495],
                [117.50189120501511, -0.6391175552309392],
                [117.51512126023806, -0.638814635686856],
                [117.52610819763527, -0.6535776626789698],
                [117.55738762923579, -0.65293402688377],
                [117.56877429369877, -0.6263153371830299],
                [117.56125979160618, -0.6193266812812794],
                [117.5102837799725, -0.6077073163395426],
                [117.49987228997429, -0.6140052686282047]
              ]
            ],
            [
              [
                [117.37279536171661, -0.5990033861972393],
                [117.36326799971846, -0.5885759485211679],
                [117.33821299993008, -0.5998049483396244],
                [117.34486500001981, -0.6084809482371156],
                [117.34563400040884, -0.6063459487065188],
                [117.34877300037374, -0.605756948524288],
                [117.3520440001306, -0.6080729483078926],
                [117.3628771543572, -0.6053097813212958],
                [117.37511576188297, -0.6223493728252265],
                [117.38039439918225, -0.6201371979732357],
                [117.38397069808934, -0.6206635549790462],
                [117.38900294902692, -0.6309465831182592],
                [117.3987995581532, -0.6300075011452009],
                [117.41416098953812, -0.638106600339313],
                [117.42315469653204, -0.6472258787712235],
                [117.45474352639269, -0.6665917376185462],
                [117.46327657365498, -0.6812977754719327],
                [117.47571923735302, -0.6676043661485096],
                [117.45722173779302, -0.6341748093199726],
                [117.42143521436367, -0.6305453847616604],
                [117.41170850414426, -0.6182906766733254],
                [117.39488341893468, -0.6238932524715892],
                [117.37974533597855, -0.6127252887410464],
                [117.37279536171661, -0.5990033861972393]
              ]
            ],
            [
              [
                [117.43294580900192, -0.5873717554021596],
                [117.42382077131163, -0.584589777296344],
                [117.41632271512128, -0.5973876983834998],
                [117.40788067924996, -0.5965027591949479],
                [117.4086524891211, -0.6057416348684797],
                [117.42198199497318, -0.6290672626440141],
                [117.4335258510373, -0.6232256785222035],
                [117.44391734910641, -0.6283832266045692],
                [117.44691897493078, -0.6088549178201674],
                [117.43410197562571, -0.6050794307710703],
                [117.42469297430137, -0.5927664861843596],
                [117.43294580900192, -0.5873717554021596]
              ]
            ],
            [
              [
                [117.34614492324965, -0.5898620896636544],
                [117.32639949564032, -0.5811830212793438],
                [117.30634540361757, -0.577715232771709],
                [117.2904780909912, -0.5920159488769059],
                [117.3257983833887, -0.6299735238590074],
                [117.3677068428965, -0.6436299116910504],
                [117.37495003751428, -0.6303436101694047],
                [117.38600310386016, -0.6314013747735885],
                [117.38588866512987, -0.6272841930110644],
                [117.38175550870983, -0.6206634767380024],
                [117.37649597480947, -0.6239959127810835],
                [117.37134197635123, -0.6213549609626057],
                [117.35778034828456, -0.608445911549552],
                [117.35055499981343, -0.6081369485612527],
                [117.34879399954355, -0.606136948960966],
                [117.34527900022579, -0.6081369485612527],
                [117.34828300026027, -0.6108689486430308],
                [117.34321500037208, -0.6124529488444637],
                [117.34242499991387, -0.6070149489806909],
                [117.3376659998861, -0.5994429487330422],
                [117.34614492324965, -0.5898620896636544]
              ]
            ],
            [
              [
                [117.47524392226921, -0.5558609543195416],
                [117.45466544455473, -0.5548180186365812],
                [117.44630974899576, -0.5653525286961667],
                [117.417699292988, -0.5649261978856543],
                [117.38717625957679, -0.5752487611213724],
                [117.37405163817266, -0.5921027595346686],
                [117.38751365283292, -0.5933040541387413],
                [117.3910490002113, -0.6008801705833662],
                [117.3997605179336, -0.593132491371307],
                [117.41885100796776, -0.5921865646581637],
                [117.42374411130174, -0.5834084368498225],
                [117.43292111361859, -0.5862746031903612],
                [117.43341828132873, -0.6041411545905362],
                [117.44878556010553, -0.6089146031263795],
                [117.45580645490406, -0.6286455021881352],
                [117.47249599969075, -0.6090619993117912],
                [117.46324331222911, -0.595798989331854],
                [117.47314867187379, -0.5894464895637839],
                [117.50132292611227, -0.5890682949657844],
                [117.50470429516713, -0.570377070645236],
                [117.48057842095739, -0.565766276192619],
                [117.47524392226921, -0.5558609543195416]
              ]
            ],
            [
              [
                [117.36095671597968, -0.5831391249706144],
                [117.38740491040835, -0.5717700782265069],
                [117.41829604352768, -0.5530586857097433],
                [117.41304195772022, -0.5408281163244624],
                [117.39720403853562, -0.5452315594888546],
                [117.38647016766413, -0.5520730278478823],
                [117.38046780274692, -0.5612329268580538],
                [117.37008338414091, -0.5694046004418851],
                [117.3525696223902, -0.5740452343999891],
                [117.36095671597968, -0.5831391249706144]
              ]
            ],
            [
              [
                [117.50361612088739, -0.5273191174832164],
                [117.4856976875983, -0.5262515925369371],
                [117.43735409771352, -0.5436885180106401],
                [117.44005422492262, -0.5619211312551329],
                [117.45319728512663, -0.5533404271189966],
                [117.47354157398456, -0.5483389258007492],
                [117.4833721280213, -0.5635494905165501],
                [117.49855479217001, -0.5562720623057658],
                [117.49817778467707, -0.5451925334086241],
                [117.51025978336236, -0.5391375491585109],
                [117.50361612088739, -0.5273191174832164]
              ]
            ],
            [
              [
                [117.55598244213604, -0.5080365628370487],
                [117.5487961386309, -0.5053761604813758],
                [117.53193523162565, -0.5291658384326752],
                [117.52763651181567, -0.5320721226303249],
                [117.52365954964853, -0.5283014496521332],
                [117.52010708637295, -0.5270139541306094],
                [117.5162171471037, -0.5277563183993266],
                [117.50832772574404, -0.5272902069773977],
                [117.50503316464892, -0.5264929094201989],
                [117.51378839212066, -0.5403064339946582],
                [117.49842572686589, -0.5458503218348483],
                [117.49871129938697, -0.5564932379725178],
                [117.51102500030356, -0.5676399992864845],
                [117.50873523464338, -0.5962416644212567],
                [117.51956899681181, -0.6025790888538154],
                [117.5328437177634, -0.5973813554651315],
                [117.55245560324352, -0.5978150508238969],
                [117.57807884686133, -0.5775543026888954],
                [117.58376805255364, -0.5596689014985827],
                [117.5858256393418, -0.5222478053918849],
                [117.58254482888083, -0.5126495894069762],
                [117.56855379791955, -0.5062526676264838],
                [117.55598244213604, -0.5080365628370487]
              ]
            ],
            [
              [
                [117.53878255996722, -0.5163095207677202],
                [117.5356877239094, -0.5047562389092377],
                [117.51535850409175, -0.511261209736972],
                [117.50137233216742, -0.521837613814796],
                [117.5015014379411, -0.5235844137895924],
                [117.50779904219212, -0.5268598067346488],
                [117.52313144256209, -0.5269010595362147],
                [117.53075381024018, -0.5284211053497074],
                [117.53878255996722, -0.5163095207677202]
              ]
            ],
            [
              [
                [117.55093414099792, -0.44031768021670814],
                [117.54344448087818, -0.4420416706864785],
                [117.5189316886466, -0.46255721294500063],
                [117.49382342381205, -0.47532036444323467],
                [117.47665134688225, -0.49522068768453664],
                [117.45793899209093, -0.5027234122556479],
                [117.42352010906586, -0.5283546751281278],
                [117.41558863270927, -0.5404661796704318],
                [117.42186662184713, -0.5494421403424781],
                [117.41912890117794, -0.561529424343405],
                [117.43623494178917, -0.5639984644575406],
                [117.43607744711653, -0.5433328838054763],
                [117.49660368601133, -0.516190089001384],
                [117.50974536036017, -0.506537823761505],
                [117.56091125574994, -0.488890198823583],
                [117.57496345140225, -0.48759643143012],
                [117.58751701589767, -0.47887050476992954],
                [117.59122399978787, -0.4644140000060588],
                [117.56292599973517, -0.465763999309047],
                [117.56575599983955, -0.451358999618094],
                [117.55093414099792, -0.44031768021670814]
              ]
            ],
            [
              [
                [117.6201877670519, -0.41765194797898175],
                [117.6065922705169, -0.40767010728956166],
                [117.59328939089278, -0.41068709533311676],
                [117.55237845130455, -0.438312882733328],
                [117.56627599953777, -0.45024599955320355],
                [117.56342100007919, -0.4642739998451475],
                [117.59149599974069, -0.462794999989228],
                [117.59232900038307, -0.4753369992030798],
                [117.60422915794936, -0.47745683616403767],
                [117.62043468940942, -0.4612744019920001],
                [117.62628208764829, -0.44138283994607264],
                [117.62656581565989, -0.4219226215317349],
                [117.6201877670519, -0.41765194797898175]
              ]
            ],
            [
              [
                [117.5240086421851, -0.3505724464611717],
                [117.51475951424027, -0.35244002988343937],
                [117.49619507814043, -0.3852629975892228],
                [117.4693633282551, -0.41839947795347143],
                [117.4570293026602, -0.46496268717822886],
                [117.44384330702303, -0.47774748355948304],
                [117.44262639659098, -0.5003870293379009],
                [117.46871326320661, -0.49096169452656113],
                [117.48457539214064, -0.4767329889362486],
                [117.49831434223313, -0.45302872679428674],
                [117.50996624104926, -0.4423791763578606],
                [117.5374969413308, -0.39409697959189316],
                [117.53965540867023, -0.37699761734609183],
                [117.5240086421851, -0.3505724464611717]
              ]
            ],
            [
              [
                [117.50990888678575, -0.3501423681756819],
                [117.4984149853633, -0.35108867170481517],
                [117.48539510799787, -0.37823225161707796],
                [117.4975642752712, -0.37887466073698306],
                [117.50990888678575, -0.3501423681756819]
              ]
            ],
            [
              [
                [117.48890789318477, -0.3287690489523811],
                [117.47727527853135, -0.32916648094828815],
                [117.45809588691566, -0.35298406036605456],
                [117.46495516724724, -0.36211049200557],
                [117.46726679722497, -0.37561838468064934],
                [117.46144889315201, -0.3878845177562198],
                [117.455051054063, -0.3884126770033731],
                [117.44563369713751, -0.3813884718005056],
                [117.43080075505088, -0.39825644747702427],
                [117.4391845067596, -0.4238768222575118],
                [117.43415607164548, -0.43366575567006294],
                [117.44485089755096, -0.44082488436089307],
                [117.45297563102065, -0.4388097563651172],
                [117.45558585030676, -0.42471312341189105],
                [117.47892260719266, -0.3825886467486157],
                [117.4938736788273, -0.3466945104432284],
                [117.48890789318477, -0.3287690489523811]
              ]
            ],
            [
              [
                [118.01303441660514, 0.7437243441032706],
                [117.99684531575883, 0.7326065103821975],
                [118.0011710296194, 0.7199569693387389],
                [118.01626202407317, 0.7138469915454948],
                [118.02252499183498, 0.7352099838537356],
                [118.01303441660514, 0.7437243441032706]
              ]
            ],
            [
              [
                [118.00525658312483, 0.9293443235816881],
                [117.99262787397731, 0.9322682066124912],
                [118.00042600155882, 0.9031229984277047],
                [118.00968238073733, 0.9045979288513877],
                [118.00525658312483, 0.9293443235816881]
              ]
            ],
            [
              [
                [117.95482427192269, 1.0458957529866666],
                [117.94626175387691, 1.0350906710976346],
                [117.95400879367094, 1.0098108551868563],
                [117.95971713873507, 1.0057334657269053],
                [117.96562935381166, 0.992074210856174],
                [117.97772511212429, 0.9761738850168058],
                [117.98710317317295, 0.9786695864323178],
                [117.9745996118827, 1.01184955081618],
                [117.9745996118827, 1.0230623718310312],
                [117.95482427192269, 1.0458957529866666]
              ]
            ],
            [
              [
                [118.46831485704513, 1.4661547232619228],
                [118.46116464603165, 1.4514622453863808],
                [118.47839640467043, 1.4389145543632367],
                [118.48053712658714, 1.4304436736366597],
                [118.46577114352351, 1.4229770838254012],
                [118.480182371919, 1.3930329161362351],
                [118.50434920125474, 1.3802271196858555],
                [118.52007267901104, 1.3859830352889162],
                [118.51611801463469, 1.4059530586867481],
                [118.49811502798616, 1.4159393717046669],
                [118.48872569033836, 1.4457652461692305],
                [118.46831485704513, 1.4661547232619228]
              ]
            ],
            [
              [
                [117.87218007335173, 1.9497810436602663],
                [117.87288454918269, 1.971959414395485],
                [117.85648343586195, 1.9582804077147102],
                [117.84899129361338, 1.957442945535547],
                [117.84315496782756, 1.9494309555742007],
                [117.83673880055528, 1.9539753863550686],
                [117.82733942737275, 1.9496740486184763],
                [117.82649989765218, 1.9357974950584094],
                [117.83599829518528, 1.9117298024884235],
                [117.85979232855323, 1.8928410888452163],
                [117.8709449039842, 1.90694008971343],
                [117.87218007335173, 1.9497810436602663]
              ]
            ],
            [
              [
                [117.85020899275082, 1.9858463973933453],
                [117.8384927861133, 2.0068604518484108],
                [117.8288007690403, 2.0103513232550085],
                [117.82057979192393, 1.996473754360295],
                [117.82164755069402, 1.98749931713985],
                [117.81974003017831, 1.9858176667519842],
                [117.81188940377308, 1.9877393713749143],
                [117.81616224379263, 1.9700246300326967],
                [117.82092261708522, 1.9657162542018227],
                [117.82436395203479, 1.9576905100090585],
                [117.83685317633308, 1.9545814277906857],
                [117.84327688082351, 1.9505073954999261],
                [117.84791578718387, 1.9586375941497067],
                [117.86314512434808, 1.967372661519164],
                [117.85020899275082, 1.9858463973933453]
              ]
            ],
            [
              [
                [117.8829344640543, 2.053160440302122],
                [117.87841797790736, 2.0562748096348287],
                [117.8703234427727, 2.0469436473314886],
                [117.86318982604973, 2.0426415496678487],
                [117.86413591464077, 2.036896721806329],
                [117.8587801649727, 2.0342671617001997],
                [117.86081792530706, 2.0253633617298306],
                [117.88388083952918, 2.039880216398899],
                [117.8829344640543, 2.053160440302122]
              ]
            ],
            [
              [
                [117.85642236200249, 2.077834840705407],
                [117.83299990961825, 2.095315707054283],
                [117.81325510793693, 2.089943609510044],
                [117.81265278879528, 2.0814404206389554],
                [117.81919858524657, 2.081084212666326],
                [117.82335656925034, 2.0786847746826425],
                [117.82442459691765, 2.074857647662384],
                [117.82406631870572, 2.072107773554933],
                [117.8213270871754, 2.0663648306724554],
                [117.8159715245663, 2.059899521745592],
                [117.80538179793166, 2.055716500616086],
                [117.80657175028614, 2.0494920436229904],
                [117.806327605035, 2.046742092173872],
                [117.80299393961866, 2.040999373222519],
                [117.80168178288807, 2.0262816846520764],
                [117.82595053239118, 2.032493242669034],
                [117.84272779165428, 2.041937824770855],
                [117.86366243957002, 2.037376424682293],
                [117.86247246922915, 2.044197878629234],
                [117.8693770978748, 2.0469441635423777],
                [117.87830366508217, 2.056871918104605],
                [117.88246138108798, 2.05639921555138],
                [117.88531485979297, 2.0458589048645877],
                [117.8842389621584, 2.0411917058326026],
                [117.89281636938006, 2.0384183935940428],
                [117.91050702417988, 2.0455221213488812],
                [117.8771211312353, 2.0699170969965053],
                [117.85642236200249, 2.077834840705407]
              ]
            ],
            [
              [
                [117.77761854735809, 2.0836202675613436],
                [117.77130144400655, 2.0990293585181234],
                [117.75522594731967, 2.0951834806331817],
                [117.73557262827421, 2.08372199707253],
                [117.71241768282982, 2.0758532052612395],
                [117.72198503588072, 2.0621162921332257],
                [117.705497823266, 2.050308990467556],
                [117.71440142808353, 2.032628170653595],
                [117.72406021700544, 2.0466096921836083],
                [117.7414701448314, 2.052138110785222],
                [117.76068878735771, 2.0258221086034496],
                [117.78466784634406, 2.021956861120316],
                [117.78908536659651, 2.034520074441957],
                [117.7798538842494, 2.050862305550993],
                [117.75221261861748, 2.0561496743680436],
                [117.751053054355, 2.0629350340246333],
                [117.75621815764191, 2.072232280195635],
                [117.76113149212472, 2.0731526427803146],
                [117.77878563624813, 2.0729721281612115],
                [117.77761854735809, 2.0836202675613436]
              ]
            ],
            [
              [
                [117.82750694319088, 2.096159935732487],
                [117.82584407604338, 2.106962042664179],
                [117.79562377421792, 2.1097460307547635],
                [117.778457784799, 2.091996774331733],
                [117.77978528666176, 2.0727273803643698],
                [117.7687835617121, 2.0651066935191125],
                [117.75662997609254, 2.0719787792972966],
                [117.75212866060929, 2.058076599555477],
                [117.77078236251612, 2.0553899145130003],
                [117.80149841382081, 2.0418866596417047],
                [117.80525228026863, 2.047330647191643],
                [117.80474860596348, 2.0537809122593558],
                [117.80508401351642, 2.0569650411038083],
                [117.80633534819788, 2.057715250158708],
                [117.81499493456863, 2.0601442713410734],
                [117.81991604099255, 2.065415705070791],
                [117.82308203680657, 2.071610746139072],
                [117.82366196462806, 2.0758893193366816],
                [117.8135071357458, 2.079495026074653],
                [117.8090970537861, 2.0846084597126833],
                [117.80976886174369, 2.0883713670449993],
                [117.82467633784279, 2.0953201371146974],
                [117.82750694319088, 2.096159935732487]
              ]
            ],
            [
              [
                [117.78316512866786, 2.098996288647868],
                [117.78923030583405, 2.1093422225655445],
                [117.77770206020193, 2.1195161602426538],
                [117.77150721698456, 2.1049184403337904],
                [117.78316512866786, 2.098996288647868]
              ]
            ],
            [
              [
                [117.89746078678593, 2.0794854464962214],
                [117.91421621410711, 2.0889368184794534],
                [117.89795663339203, 2.103538683363581],
                [117.90001693613272, 2.1232126913126876],
                [117.88015763096996, 2.139380556467529],
                [117.86445602652452, 2.1453690412483297],
                [117.85053225675836, 2.128623349987663],
                [117.83206203363602, 2.1124316842748385],
                [117.86415850471133, 2.0860807002641764],
                [117.89746078678593, 2.0794854464962214]
              ]
            ],
            [
              [
                [118.53199678372471, 2.162427242423348],
                [118.51624525161708, 2.160980249439035],
                [118.50516439532066, 2.1377935965972483],
                [118.52829263809485, 2.1406046704692017],
                [118.53976482898372, 2.137683607712347],
                [118.53199678372471, 2.162427242423348]
              ]
            ],
            [
              [
                [117.80384082489422, 2.1650779608792163],
                [117.79647071794932, 2.1804063151993205],
                [117.7795793769867, 2.1808856151790224],
                [117.77576458506758, 2.1714341640554835],
                [117.76790637557883, 2.1646896066400814],
                [117.75149528158204, 2.169058243343329],
                [117.7311551583839, 2.168109341672846],
                [117.71354676816884, 2.161893811350353],
                [117.72365559403045, 2.1505175988081646],
                [117.72904958467063, 2.1225343425858796],
                [117.72606661977875, 2.108414228334027],
                [117.7339096352215, 2.0860296178726117],
                [117.74961872922108, 2.098352434317462],
                [117.77114869505624, 2.103842103830061],
                [117.7661593408809, 2.1177307640635377],
                [117.77960178179683, 2.1209535691519363],
                [117.78792558919463, 2.1131694862828],
                [117.80236829566036, 2.1207157092626403],
                [117.80384082489422, 2.1650779608792163]
              ]
            ],
            [
              [
                [117.77448248817632, 2.172285180816516],
                [117.77681739081004, 2.180498059537854],
                [117.75258649467105, 2.184445621068619],
                [117.73043840410912, 2.181778868599963],
                [117.73692315907658, 2.1714627553019454],
                [117.75374606522882, 2.1699527108489747],
                [117.76806665185461, 2.1660102961383245],
                [117.77448248817632, 2.172285180816516]
              ]
            ],
            [
              [
                [117.83913402521512, 2.19038811272128],
                [117.84542848183901, 2.168963165217974],
                [117.86636345710792, 2.157336007650656],
                [117.88338498572853, 2.160573717203306],
                [117.88183570115677, 2.1754011932105186],
                [117.83913402521512, 2.19038811272128]
              ]
            ],
            [
              [
                [117.87445819459003, 2.152790195511159],
                [117.84198762353003, 2.169562145176883],
                [117.82713334502796, 2.190630023157098],
                [117.81118778598761, 2.1908739921410643],
                [117.80166627407937, 2.1819052260453873],
                [117.80760933140562, 2.1696895485338814],
                [117.81056224455199, 2.122900617569485],
                [117.82970423965901, 2.1172726916814213],
                [117.85279110282715, 2.13305472519545],
                [117.8666002755823, 2.147765047419057],
                [117.88027974091733, 2.1426099490899446],
                [117.9004898770063, 2.127635939347158],
                [117.9014284463658, 2.1072998343199743],
                [117.91281779800306, 2.0985121763959],
                [117.94796507612739, 2.114654210323579],
                [117.95677425033148, 2.129502409408701],
                [117.94610376008052, 2.136863361255166],
                [117.9132234183262, 2.142970484600255],
                [117.89027422787342, 2.1542102690887077],
                [117.87445819459003, 2.152790195511159]
              ]
            ],
            [
              [
                [117.91788497860898, 2.156676853154295],
                [117.92680339436117, 2.1737005770200426],
                [117.90552498329146, 2.2056642038400014],
                [117.89266966926994, 2.18903627250603],
                [117.90538026708577, 2.164857566723356],
                [117.91788497860898, 2.156676853154295]
              ]
            ],
            [
              [
                [118.57917498084078, 2.3057793924050998],
                [118.56706370382688, 2.3108813713782297],
                [118.56047444853698, 2.276544886037698],
                [118.56460168382068, 2.2417160191789662],
                [118.5966102893334, 2.1964300208288705],
                [118.62612267811915, 2.186464377828827],
                [118.60638138739114, 2.210395570399328],
                [118.59276398966642, 2.214950711206427],
                [118.57231917728825, 2.248437427145575],
                [118.56420997511032, 2.276858697271109],
                [118.56920212437865, 2.3043654791835593],
                [118.57917498084078, 2.3057793924050998]
              ]
            ],
            [
              [
                [118.20380603472074, 2.3826490288296895],
                [118.19397043728588, 2.3788314984749377],
                [118.21317826636391, 2.3495085536768556],
                [118.22035936729105, 2.360793750331368],
                [118.20380603472074, 2.3826490288296895]
              ]
            ],
            [
              [
                [117.98593392680937, 2.435721765746962],
                [117.97195792351943, 2.425344374443398],
                [117.95837634399311, 2.4277689214985685],
                [117.95187568091796, 2.414129836622692],
                [117.91935357240948, 2.395732575498016],
                [117.89868407341135, 2.404681319087672],
                [117.85696421072976, 2.39190943966895],
                [117.83017831727557, 2.3663867762288078],
                [117.82104078440477, 2.363132129744031],
                [117.81899427186818, 2.3391406223419153],
                [117.80901027460459, 2.3267096480318514],
                [117.79783086700093, 2.325750083999253],
                [117.74173198514302, 2.336141771825339],
                [117.72082870917768, 2.346972762283201],
                [117.70835577789694, 2.3899416690728117],
                [117.69970757424517, 2.403711459616204],
                [117.68108063166585, 2.4157958280151206],
                [117.66725055057248, 2.448952677124396],
                [117.63791506922507, 2.478019859229164],
                [117.6223814959094, 2.5102690531743406],
                [117.61178313308517, 2.521811781488566],
                [117.59039793561635, 2.5202241804016694],
                [117.57448505703985, 2.504078438569138],
                [117.5407941239497, 2.5198995979881147],
                [117.49855600895273, 2.52293414979124],
                [117.48673832371469, 2.539727329752509],
                [117.47031859532694, 2.544730598238573],
                [117.45168446176854, 2.5576197015880098],
                [117.42794724037287, 2.5913456272988924],
                [117.40273447071934, 2.6096855602404503],
                [117.37446647384877, 2.6039748544559984],
                [117.33544188563462, 2.621915776192168],
                [117.31459780664352, 2.626341573804609],
                [117.28240369977095, 2.6217492136553346],
                [117.24202064513668, 2.6037179855966315],
                [117.21796788760798, 2.596475878259241],
                [117.2009314311407, 2.5962774194673557],
                [117.14461192759848, 2.604940766802997],
                [117.07413251660239, 2.6080991120844033],
                [117.00087549730642, 2.5827800443636306],
                [116.95298612980616, 2.5508460924732503],
                [116.91652673002466, 2.533530129411986],
                [116.84862549457102, 2.519400410400692],
                [116.77653924688877, 2.482268965240621],
                [116.75409447178254, 2.475823604176014],
                [116.7499873309506, 2.4646609788211435],
                [116.73658751969833, 2.4613722075613964],
                [116.72694190224706, 2.4436226624559367],
                [116.70797890820006, 2.4377413193064967],
                [116.69256000024393, 2.4397971731011125],
                [116.64613195732409, 2.4305458292266735],
                [116.58116315385655, 2.4280521539837423],
                [116.56898031329092, 2.433382147068471],
                [116.56450692715305, 2.4544998636778246],
                [116.55260943068072, 2.4546189348160397],
                [116.52750195365229, 2.467172419271776],
                [116.5170522667488, 2.4795814051577736],
                [116.50392498514998, 2.4799414640273767],
                [116.49156800705794, 2.494402622782843],
                [116.4490393741678, 2.509853889245619],
                [116.43710399735164, 2.510082318844013],
                [116.42402789886853, 2.4986686981019943],
                [116.44138844582017, 2.484506146641422],
                [116.44287323011554, 2.4667458492415903],
                [116.42753046505902, 2.4360032130778677],
                [116.41877404868342, 2.4278178671009414],
                [116.40186939775458, 2.392706606824504],
                [116.37499767660154, 2.355547093823361],
                [116.36006854212962, 2.3239994061477205],
                [116.33037401994511, 2.283390644162921],
                [116.32010440879151, 2.2796586825022587],
                [116.31008545308123, 2.263509384750364],
                [116.32261307753151, 2.251490208206576],
                [116.32169022562346, 2.1297040814782235],
                [116.31601225336283, 2.1167748647682174],
                [116.29567416533052, 2.1188089028086665],
                [116.28619088630046, 2.1026293024004303],
                [116.26551400117023, 2.105003361515969],
                [116.23401655771806, 2.103215939164329],
                [116.22083725933214, 2.091965842149932],
                [116.22558387389677, 2.0525471979948406],
                [116.23289382698863, 2.028139744253849],
                [116.22937818735352, 2.0106005406824465],
                [116.19395935623936, 1.9810038944142434],
                [116.19527406884322, 1.9553962594299037],
                [116.17953744479735, 1.9106968852531168],
                [116.17563888732491, 1.8709498284718507],
                [116.18412555385135, 1.8467056817218577],
                [116.17519365816088, 1.8156614983794839],
                [116.16817346303492, 1.7786364744937373],
                [116.15738521735398, 1.7524250122045828],
                [116.16186433936787, 1.7443182516860247],
                [116.14238126018711, 1.7018892474908398],
                [116.14131207868957, 1.6867347923767966],
                [116.13078922923955, 1.6774952350171475],
                [116.12935223132047, 1.6626557953287602],
                [116.1150839789425, 1.654798444692517],
                [116.10293203998174, 1.6656819726449612],
                [116.0765520960025, 1.6540164589924302],
                [116.05488830565082, 1.6253433436204432],
                [116.03623285456263, 1.605871908861559],
                [116.04577145988503, 1.579477487596023],
                [116.04082283420655, 1.5598521815330173],
                [116.00947217899795, 1.5272086771387876],
                [115.97274677054713, 1.5312360247228298],
                [115.96274087299298, 1.521757966257212],
                [115.95020660525074, 1.4884599982303826],
                [115.91198651276738, 1.4785554920423465],
                [115.88268939919647, 1.4530952595386566],
                [115.85249998818392, 1.43599288366471],
                [115.84836567433649, 1.4227273888579361],
                [115.85505790829586, 1.402264023465932],
                [115.83194739295789, 1.378023264462115],
                [115.82567156489085, 1.3665721087159],
                [115.8124952990189, 1.3792724812400934],
                [115.79298371852383, 1.3615157541487974],
                [115.7690106499216, 1.355388642471894],
                [115.75845179071757, 1.3312668565802142],
                [115.77103914004431, 1.3067450234640887],
                [115.7721991269882, 1.2709043914245512],
                [115.76050752400306, 1.2599315320146616],
                [115.71810690870689, 1.2658182836868832],
                [115.70848347393326, 1.2626162295554195],
                [115.69142878281218, 1.2709070327334189],
                [115.66699654735282, 1.2736943950803266],
                [115.65271350922114, 1.2813765955534677],
                [115.6317890838992, 1.2851984804256062],
                [115.59955083020668, 1.2804067747529189],
                [115.59377598927051, 1.2832141820888978],
                [115.57184375938209, 1.2719278387987742],
                [115.53853974431377, 1.2624641883716663],
                [115.4951683513384, 1.255495005490161],
                [115.46389479590812, 1.2394197606134867],
                [115.4363746437748, 1.2285489625646164],
                [115.42632603112145, 1.2185179847172662],
                [115.41753466529258, 1.1924667645296267],
                [115.40816449669421, 1.190328357467763],
                [115.40633790796812, 1.1727658650526678],
                [115.41542188261383, 1.160293143313936],
                [115.41815044638611, 1.1439773855479416],
                [115.41088366794702, 1.1277153523816423],
                [115.39389609772684, 1.1050018463581637],
                [115.37844163687214, 1.099530700181731],
                [115.37147528595574, 1.0719765150042235],
                [115.36452103811553, 1.065893949446263],
                [115.34225068472142, 1.0607393016774722],
                [115.31663535333894, 1.0593745615868215],
                [115.29964545207599, 1.0648039990053917],
                [115.28796700257521, 1.0469506478541462],
                [115.25240368638106, 1.046624899019946],
                [115.2300077948239, 1.0581766358430968],
                [115.20031823779652, 1.061927278221333],
                [115.18553021324885, 1.0676532293131231],
                [115.18979126405566, 1.087810033806079],
                [115.17354149494406, 1.1160348596210952],
                [115.14195932366397, 1.116026792702371],
                [115.12290523458898, 1.1294028924295958],
                [115.09327377466502, 1.1174408597079832],
                [115.06035755985351, 1.122818140045183],
                [115.0454526513189, 1.115960779766226],
                [115.01564552951766, 1.12078534666648],
                [115.00679722131474, 1.1273422236647548],
                [114.9993980923482, 1.153279244341718],
                [114.98914503501544, 1.1572735346459808],
                [114.99028364057756, 1.182889632250749],
                [114.97432216353707, 1.1932353206535709],
                [114.93587968759755, 1.1781109433653683],
                [114.91914849430759, 1.194321873455749],
                [114.89608083436303, 1.2093574804633818],
                [114.88042781938839, 1.2107804813342113],
                [114.85852113384328, 1.1923601930685095],
                [114.84826798837707, 1.1680904408212314],
                [114.80956235102178, 1.1931352656800414],
                [114.75679669211377, 1.2477069584094238],
                [114.75776510097865, 1.2548935254159232],
                [114.72731287029274, 1.264000733147384],
                [114.71592885882978, 1.278230747251257],
                [114.72558478341114, 1.310535346628967],
                [114.7145468643464, 1.3324879866313495],
                [114.70408907455123, 1.3370102734054399],
                [114.69984943210568, 1.35396884858352],
                [114.68854371562009, 1.3590564204175166],
                [114.67243306893101, 1.3528382775645014],
                [114.63882155766885, 1.3862095853041865],
                [114.6464117817784, 1.4087940646944048],
                [114.6337998485842, 1.4323276094316952],
                [114.60400587487129, 1.4442305207219874],
                [114.58958656426864, 1.463283702381034],
                [114.58546676098229, 1.4454422321733205],
                [114.5692285031929, 1.4298677657848202],
                [114.5260019802073, 1.4428451016202075],
                [114.51197971489194, 1.462059134221704],
                [114.47456327007887, 1.473996781826088],
                [114.45880120061497, 1.4871328614923982],
                [114.414297657411, 1.5114452317121163],
                [114.40540746598185, 1.5229135726033292],
                [114.38687375521965, 1.5186206534204416],
                [114.38648955674773, 1.501517396210886],
                [114.37914576306662, 1.4929416815134573],
                [114.33666015284382, 1.4858423928122306],
                [114.32483053180363, 1.4762436768043017],
                [114.30716589079327, 1.471890210764684],
                [114.30062778624335, 1.4586956284005623],
                [114.26935930838545, 1.4501666505705089],
                [114.24967770206433, 1.4526553957299484],
                [114.23831916479764, 1.44827575042558],
                [114.22278191955002, 1.4255188880862875],
                [114.22299072953865, 1.4165428230928683],
                [114.20538782878532, 1.4103666938678998],
                [114.19765655410674, 1.3969001538191037],
                [114.17874755265746, 1.394646593065886],
                [114.16565441921614, 1.3815464152349364],
                [114.16907164783834, 1.369966057487602],
                [114.19043074068622, 1.3600269984472675],
                [114.20299125334373, 1.349370617659929],
                [114.20918670899948, 1.3339041877281375],
                [114.20901272076048, 1.3088389104873954],
                [114.1963223199192, 1.2988002182555078],
                [114.18700366089035, 1.2688401154790085],
                [114.2078015300167, 1.2361225546122796],
                [114.20897344107152, 1.2185271173326555],
                [114.20117482335945, 1.2139593104738537],
                [114.19775666483827, 1.1959531654063085],
                [114.22046554025258, 1.160452624589027],
                [114.18018787537017, 1.1520228617657153],
                [114.16287952057348, 1.138232462358303],
                [114.1282315775253, 1.127437811705704],
                [114.09370742975409, 1.106362194159999],
                [114.07846659655627, 1.105617657129244],
                [114.05461915155263, 1.1275044172950857],
                [114.03631021554406, 1.1108909632474138],
                [113.9887679755617, 1.1265689514959831],
                [113.97184986358036, 1.1197739636975257],
                [113.96281893454625, 1.11006139082059],
                [113.96215106831721, 1.0881880404457434],
                [113.9482388206651, 1.0612406296513655],
                [113.90265380672781, 1.0586908403100779],
                [113.88969530629345, 1.0473933796008623],
                [113.89336127300442, 1.019242624647461],
                [113.90813835010488, 1.0137889846739654],
                [113.91572475659234, 1.0029986948339342],
                [113.91188690605588, 0.9679100749900158],
                [113.8843712721166, 0.9370199922788629],
                [113.87216629024215, 0.9167375210198543],
                [113.87851815868612, 0.8964992397481524],
                [113.87119985988568, 0.8886867041281334],
                [113.91081871291328, 0.8510902537123002],
                [113.9263923321405, 0.8403579980232507],
                [113.9097767628873, 0.8117954183239249],
                [113.8881432266287, 0.7952557194759038],
                [113.87395538982946, 0.7797888452790289],
                [113.89472921468155, 0.7555886142231998],
                [113.89486671202917, 0.7385810152017598],
                [113.87264991326299, 0.7118232408499239],
                [113.86021399897697, 0.7128339043612186],
                [113.84168221186462, 0.702718638454428],
                [113.83634925641309, 0.6782975905613853],
                [113.84323861097323, 0.6656452661162007],
                [113.88014197097527, 0.6604249615427875],
                [113.88933776462625, 0.6412416785606752],
                [113.91220832555382, 0.6568041695767874],
                [113.93183874156477, 0.6539136298102335],
                [113.94802831365587, 0.6601030779946768],
                [113.96170019680665, 0.6569672832135893],
                [113.97751590183748, 0.6674166418645768],
                [114.01171196073847, 0.6502201528378464],
                [114.02778597354393, 0.6711524283419408],
                [114.05402054031572, 0.6699554963491323],
                [114.07479619978483, 0.6899051563979128],
                [114.09145017008939, 0.6977617480063145],
                [114.10255086183292, 0.6914729490174523],
                [114.12294780599655, 0.6948502324523247],
                [114.1308862278172, 0.6697062051772491],
                [114.14389228170353, 0.6689896037872245],
                [114.15767692275188, 0.659763996711149],
                [114.17657412419658, 0.6396731961611408],
                [114.17948950593598, 0.6159647413797416],
                [114.15858478655866, 0.5988142778566612],
                [114.16489955976692, 0.5841325882483943],
                [114.17804240413375, 0.5813194000702993],
                [114.19357954236204, 0.5864325333348006],
                [114.2029683179336, 0.5395783574552979],
                [114.21941258252468, 0.5294829296934722],
                [114.24769966840506, 0.5226097962051881],
                [114.25642943876767, 0.5323015236963897],
                [114.26928559275598, 0.5288055045704141],
                [114.29083845083392, 0.5380823397282484],
                [114.31191559812646, 0.5342983073210803],
                [114.319796555067, 0.5405255396220241],
                [114.3637688075845, 0.5298164432742851],
                [114.37646821333749, 0.5151292687008322],
                [114.38921440631987, 0.5119654600379704],
                [114.40069543934317, 0.4969267917380762],
                [114.41005289062844, 0.49542720100458837],
                [114.43135752413048, 0.5039427771375244],
                [114.4372060753002, 0.5113529605707186],
                [114.43596910009398, 0.5440140557041673],
                [114.46261019370559, 0.5613308362491694],
                [114.49038877592136, 0.5710416617433225],
                [114.5249766127805, 0.5587300481532225],
                [114.54649221374473, 0.593886201686928],
                [114.53982709434501, 0.6197499644893014],
                [114.54528424886905, 0.6306128116319769],
                [114.5671692229813, 0.6251847861490205],
                [114.60201380009687, 0.6332600083427451],
                [114.60657940901262, 0.6425587608781598],
                [114.61988269423102, 0.6425123234849934],
                [114.63852730489123, 0.6697469903313618],
                [114.65272651091982, 0.676263419421332],
                [114.65599920679801, 0.6899820826070595],
                [114.66808325953491, 0.7038549255644284],
                [114.69148636210593, 0.7103621186169562],
                [114.7007912183401, 0.7024828416098785],
                [114.7159153258317, 0.7013624482184468],
                [114.73689190463779, 0.7127336281544672],
                [114.75000002438173, 0.7258570903325108],
                [114.76579465829695, 0.730203063220813],
                [114.78214249667894, 0.7274340713253764],
                [114.80975734562503, 0.7428224499961402],
                [114.81292837134731, 0.7499999680241558],
                [114.83425143015938, 0.7537362680143929],
                [114.86205044497217, 0.7366982161498186],
                [114.87997048804766, 0.7468010084598404],
                [114.8913118689477, 0.7587498382345075],
                [114.91735663470104, 0.7774846895667906],
                [114.94137375209709, 0.77215415598954],
                [114.95812155316719, 0.7624179768082513],
                [114.97913733252199, 0.7389088945299136],
                [114.9893489383029, 0.7177897794747992],
                [114.99575684889953, 0.6901332388579249],
                [115.01075086585979, 0.606699075801771],
                [115.03249557181323, 0.5731747214363168],
                [115.0497112613657, 0.5394148382242747],
                [115.06667850690758, 0.524491406353377],
                [115.0823653112102, 0.5000000017986963],
                [115.0940112286354, 0.46996692083678226],
                [115.1327772212918, 0.4324947756134634],
                [115.133437238238, 0.415183631119703],
                [115.1286699986216, 0.37463400135811753],
                [115.08487742525301, 0.342343564503949],
                [115.07687620916977, 0.33074989319305814],
                [115.05534000114017, 0.3177040002394733],
                [115.04890853010465, 0.2995491810499402],
                [115.03756982828497, 0.3027468455904909],
                [115.03605644914592, 0.31574186119797787],
                [115.02346646660419, 0.3299461708791114],
                [114.99636211422023, 0.3206742891871386],
                [114.97928427365036, 0.32997732069685526],
                [114.9547651807685, 0.3192054300865834],
                [114.94953417606143, 0.33216009311382777],
                [114.93393276238714, 0.33224821228520796],
                [114.89719808822122, 0.3119646132763023],
                [114.88524014022039, 0.29712336684997354],
                [114.88488297986567, 0.2811191153549544],
                [114.87237053417289, 0.2705888051293073],
                [114.86394122910451, 0.2376325255206666],
                [114.83330884802501, 0.22726005800529947],
                [114.82999005289867, 0.1989630867770984],
                [114.80239278839656, 0.17154059022612955],
                [114.81342349579779, 0.16213342711603218],
                [114.80900393768161, 0.14030659777131405],
                [114.79309047274717, 0.11948450270767808],
                [114.79467950015885, 0.08393383907548468],
                [114.8141484410977, 0.06725484513503943],
                [114.83358040731002, 0.07505292325384971],
                [114.84947333712489, 0.06793171717504265],
                [114.86046434755167, 0.05476938629817596],
                [114.87501819673241, 0.055493521309017524],
                [114.89469522100774, 0.04036379755126518],
                [114.88912310075057, 0.024374890288981987],
                [114.89402227270978, -0.002117777413445765],
                [114.89346695483619, -0.02756507586752832],
                [114.90521749219351, -0.04792116832720694],
                [114.91164672391722, -0.07969083386484499],
                [114.92455121376759, -0.09955558352334037],
                [114.91700659967489, -0.13320046413008413],
                [114.93033369984039, -0.16073262491067908],
                [114.92241775027901, -0.18244368632701935],
                [114.92876529927923, -0.19087085370688328],
                [114.94773810852689, -0.19044012161430146],
                [114.95963996401315, -0.18140244856408572],
                [114.97929327496468, -0.17845927057879862],
                [115.00081579621212, -0.1869298806094548],
                [115.02694646161001, -0.1852426921964252],
                [115.0447915902597, -0.18981582394104635],
                [115.06159914318596, -0.16935403685926076],
                [115.08123719534035, -0.15319693658932465],
                [115.10493152357105, -0.14450706374992706],
                [115.11901672164447, -0.14720155131050205],
                [115.14951286082987, -0.13534743543573313],
                [115.14609500088363, -0.12671120895510057],
                [115.15861294772947, -0.09852041078829643],
                [115.18232068215377, -0.09399670938057625],
                [115.18251707070635, -0.08256981374586303],
                [115.22524194466007, -0.07339749240031779],
                [115.24595575235617, -0.054997122319264236],
                [115.26239580452284, -0.046852824997813514],
                [115.28742731761668, -0.04651052144333789],
                [115.30978730733841, -0.05993801472754967],
                [115.31004843898438, -0.08420758171445186],
                [115.3151849501254, -0.1007616238497917],
                [115.30871510681675, -0.11746447590479647],
                [115.28637888887465, -0.12869692462334115],
                [115.28433494210401, -0.14585700639565857],
                [115.27070898373267, -0.14397187630100916],
                [115.2601316488566, -0.15899691177799014],
                [115.26634912890927, -0.16873652996684996],
                [115.26868370329032, -0.18969211320040813],
                [115.25896013800002, -0.21519371901484874],
                [115.2387173340378, -0.23291629974738726],
                [115.23852890538478, -0.2464740895075579],
                [115.25013871682836, -0.25753504380719505],
                [115.25458178592078, -0.2832190869912097],
                [115.25384151077719, -0.3008722175787284],
                [115.23285580565096, -0.2947650708512697],
                [115.22189161300764, -0.30683876159952206],
                [115.2326099067219, -0.3232579252130563],
                [115.23166148169207, -0.33829842453263836],
                [115.23743775614764, -0.3517783213124517],
                [115.23403149925434, -0.37084343877364745],
                [115.25232974951837, -0.4099125838985742],
                [115.25573256830342, -0.43573668659877285],
                [115.25309754211798, -0.45188281154247534],
                [115.28540040580413, -0.48780179251866684],
                [115.30253163102998, -0.5016796599882696],
                [115.3186443299719, -0.5293390172817567],
                [115.31686391873495, -0.5465224823266226],
                [115.33520435709545, -0.5689412787169772],
                [115.35156941929336, -0.5798368216119911],
                [115.35735247733498, -0.6052144864593174],
                [115.3564293205568, -0.6326647163035659],
                [115.3663399025645, -0.6796397664620031],
                [115.36849902910694, -0.7031820957770378],
                [115.36704945596773, -0.7494720154211336],
                [115.37969897003154, -0.8016109071176061],
                [115.39253159595648, -0.8222751486791253],
                [115.40268631961806, -0.8274375863754813],
                [115.44791335931416, -0.8387708248136505],
                [115.48215403717916, -0.8428162523680953],
                [115.49765697239138, -0.8499946724161305],
                [115.53359902997136, -0.8830725511572837],
                [115.557852863319, -0.9236323576471364],
                [115.58238135321699, -0.947523255194028],
                [115.6309882043422, -0.976745844644995],
                [115.64891725592668, -0.9896177619501145],
                [115.6566934211645, -1.0048243363739857],
                [115.66422082133386, -1.035728539340596],
                [115.65524246666655, -1.0520492622636084],
                [115.67874047739122, -1.0648072912234738],
                [115.6916979427059, -1.0515466752387965],
                [115.70643750368788, -1.071801550800899],
                [115.74110959802954, -1.0934428823830444],
                [115.74909344011019, -1.1234508003141173],
                [115.758697561943, -1.1360727078891273],
                [115.79212553596483, -1.1156163005517783],
                [115.82073742099521, -1.1312894531457118],
                [115.84696093779712, -1.1572596597054599],
                [115.84120839465174, -1.1690728006691415],
                [115.81861014754031, -1.1820016468584527],
                [115.78364466377025, -1.2130490012103792],
                [115.77049158691727, -1.231230668760304],
                [115.75463009290456, -1.2829590154461812],
                [115.75758265171805, -1.30744616350961],
                [115.75398994737827, -1.3150423402317415],
                [115.74471992368558, -1.3348671267164605],
                [115.74159515998872, -1.36403570288536],
                [115.7221071291408, -1.3751254157053268],
                [115.71748917878267, -1.3978117945786153],
                [115.69904548116403, -1.4149444524244927],
                [115.6877165001165, -1.4450292075318316],
                [115.67883333329178, -1.4563055548574084],
                [115.63525946425295, -1.4638232207649935],
                [115.61889672230598, -1.4878552102497338],
                [115.62737132802442, -1.5030359462518845],
                [115.64719851390032, -1.511441892329401],
                [115.63141932674057, -1.530270241678238],
                [115.64539849024823, -1.5609510395588018],
                [115.63088247914311, -1.5783974312963664],
                [115.63996853122285, -1.5853772702448055],
                [115.63652150266523, -1.60348628696255],
                [115.6654484759664, -1.6281206468503342],
                [115.67447858481887, -1.6659136557905185],
                [115.66762667982744, -1.6793759015765772],
                [115.66591440662467, -1.7080954066776144],
                [115.6793399798562, -1.7102153533558067],
                [115.6908865473763, -1.7239436311935492],
                [115.70774264570662, -1.7275772555858566],
                [115.70168389689434, -1.7426786192228292],
                [115.68960072189589, -1.7532238707849501],
                [115.69008613906408, -1.7790479456061803],
                [115.68083629166165, -1.7891570493582662],
                [115.69435486198734, -1.8008066647956866],
                [115.70811834288725, -1.7882081782647674],
                [115.72672172682633, -1.8036336431780455],
                [115.72170698568891, -1.8256783332884083],
                [115.72974115293198, -1.8443941864425142],
                [115.72553301014034, -1.853118697569812],
                [115.73053737939665, -1.8698523469082602],
                [115.72395225559217, -1.9222646343054066],
                [115.70621607531166, -1.9497067306810436],
                [115.7067794043461, -1.9603443848853885],
                [115.6961962139842, -1.9744231932756975],
                [115.71062182253911, -1.9798968746409855],
                [115.71689171597995, -1.9902360681400069],
                [115.73552144106168, -1.9849097029203904],
                [115.7476063805301, -1.9951180774372688],
                [115.74308128877055, -2.0094065168971724],
                [115.72471057833059, -2.026200929828974],
                [115.74098911315707, -2.0364090957031635],
                [115.75367176184238, -2.0504681019212967],
                [115.77667436572949, -2.050854094540796],
                [115.78493119521852, -2.0429552869568965],
                [115.80376968981943, -2.037827129618279],
                [115.82156568670712, -2.054590585187327],
                [115.8365955938117, -2.0591879851373847],
                [115.83201030233363, -2.0813992944418374],
                [115.81215100976135, -2.0938492894131286],
                [115.8082905529667, -2.170914481618013],
                [115.79960068462401, -2.184475020605646],
                [115.80711889552072, -2.1986309827336186],
                [115.80211892304987, -2.206727209493067],
                [115.81704122357371, -2.218701456806741],
                [115.81463285622135, -2.2341891485103247],
                [115.8220234605144, -2.2577982434941077],
                [115.83938794110065, -2.290349990959271],
                [115.85132467229585, -2.3012908911616705],
                [115.83264928683514, -2.3129779759527764],
                [115.83116277404281, -2.3253153101533712],
                [115.84488374129171, -2.3530533014686625],
                [115.85808061063176, -2.386906717770785],
                [115.87183888355764, -2.372384646134492],
                [115.88425769004039, -2.3745528971983845],
                [115.89127671874564, -2.351257472254474],
                [115.8903077118315, -2.3356261444309894],
                [115.91970893002315, -2.336917308287127],
                [115.93599997172134, -2.3340338138094125],
                [115.94539416480893, -2.341479561832273],
                [115.97044729041022, -2.3351176029949556],
                [115.96687404829879, -2.327860325893255],
                [115.98341690556583, -2.3139077067751828],
                [115.99317075552597, -2.3213936221179097],
                [116.00612699956139, -2.3146786541964843],
                [116.03434217025506, -2.3249118841760605],
                [116.08147950397597, -2.317039655155895],
                [116.1481577702649, -2.319511344672776],
                [116.16113900016478, -2.3065289996135903],
                [116.22664600021278, -2.308784654887859],
                [116.23623299985309, -2.327163654379092],
                [116.27293199971211, -2.3392936543785936],
                [116.30809199964608, -2.340669654880628],
                [116.37429890157307, -2.3549788363042126],
                [116.45450760294364, -2.3705188478664923],
                [116.49283823739484, -2.38097350622229],
                [116.53876748573089, -2.3999790885640664],
                [116.55893137508292, -2.4051927633353216],
                [116.57617162152314, -2.336304022812328],
                [116.57942743532794, -2.306538673620821],
                [116.58913083601931, -2.2607622758582693],
                [116.59574454387564, -2.202063903626197],
                [116.5863767072193, -2.170044513443486],
                [116.57003247871603, -2.1963728606189648],
                [116.51589199202192, -2.2214798655033405],
                [116.50454468189162, -2.2324358896045737],
                [116.49750152437593, -2.223436298346428],
                [116.53741275329594, -2.2007416789852527],
                [116.54786485038255, -2.189753576965984],
                [116.55237516718023, -2.173778370875084],
                [116.54233772770408, -2.1673364263349413],
                [116.50493596881995, -2.1666997476951337],
                [116.48028491571631, -2.1839163563547004],
                [116.43176538156717, -2.207002263543586],
                [116.41102719504909, -2.210523842301427],
                [116.40672304333384, -2.2042632577430936],
                [116.4509384239692, -2.1901769418123536],
                [116.49476251767612, -2.164352028823032],
                [116.5242887380349, -2.154871402792935],
                [116.51481013278146, -2.126735837388935],
                [116.50217341786902, -2.109271333305344],
                [116.49093392566022, -2.119260775963994],
                [116.46107342010237, -2.1326913997882],
                [116.43161791613397, -2.134745185142151],
                [116.39312187117753, -2.126869800400925],
                [116.387264510249, -2.116396024257085],
                [116.36947298209247, -2.1188559749232354],
                [116.36450557497233, -2.108387675650647],
                [116.39324260696083, -2.0835450004013865],
                [116.4269150257079, -2.075639536935057],
                [116.45770763270116, -2.0625581396463986],
                [116.4604820699916, -2.035801410306817],
                [116.45520507575372, -1.9941565762649134],
                [116.45476449338673, -1.9590896651558865],
                [116.45718585864051, -1.91952960890427],
                [116.44396371565301, -1.876632454524099],
                [116.43854568433596, -1.86912525492653],
                [116.42084931884108, -1.8640030169256647],
                [116.42657906057525, -1.8522008899590787],
                [116.45003610088543, -1.8211656314886682],
                [116.45385720154866, -1.8012042829512325],
                [116.45068450398674, -1.7732516144703823],
                [116.41175025986661, -1.7675587035712397],
                [116.39198169809697, -1.7689673449669385],
                [116.37503041821924, -1.7622953358610403],
                [116.36825882358585, -1.7779216832390148],
                [116.3543671776032, -1.7756488706987739],
                [116.31576518261716, -1.7863516852819998],
                [116.30033913674185, -1.795260388356212],
                [116.26243610833978, -1.8103140483545985],
                [116.25313062078158, -1.8060538159307953],
                [116.24999999980025, -1.7897317044545389],
                [116.26421865726525, -1.7616868194911035],
                [116.27934859416234, -1.750341418621531],
                [116.27964051499782, -1.7355631373288816],
                [116.30884588782749, -1.7239687933251275],
                [116.31292040485289, -1.704235220578937],
                [116.38040392410187, -1.685270827186855],
                [116.42871027665797, -1.657441508818522],
                [116.45177361985668, -1.6479812092653674],
                [116.48137220056662, -1.6456793063548503],
                [116.51912803513073, -1.6310994991425218],
                [116.53438013953189, -1.616583859457478],
                [116.55899381501263, -1.6002855827134113],
                [116.54946766530054, -1.5348696767940737],
                [116.54299949922756, -1.5212811086362308],
                [116.53267796751425, -1.5156455726850027],
                [116.53991026684537, -1.4646561251799994],
                [116.55581638162073, -1.4479477818645705],
                [116.58100369236661, -1.43055403554024],
                [116.62589285587069, -1.408977443454205],
                [116.65149848626618, -1.4051367573554217],
                [116.7110409387883, -1.3807216458872063],
                [116.74901924134497, -1.370952729584758],
                [116.76200465399165, -1.3622081941527995],
                [116.76569630623965, -1.3517650147435916],
                [116.76008525163309, -1.298523933732497],
                [116.76257429266957, -1.2776639933985052],
                [116.77236326655157, -1.2654986815188636],
                [116.77822065086241, -1.248233828728189],
                [116.76687612323451, -1.2363824755707356],
                [116.78014654362812, -1.2248811493454923],
                [116.7918630578339, -1.2357642923881258],
                [116.81579354263795, -1.24089131028569],
                [116.815875282918, -1.2593164414005855],
                [116.80626521117063, -1.27096363766168],
                [116.81081164384238, -1.2812338414685769],
                [116.84229548235066, -1.2787394827408889],
                [116.85431281798219, -1.274530981119824],
                [116.8841287477436, -1.2766494052457915],
                [116.9327605748407, -1.2534053639663512],
                [116.97255127392225, -1.2238538483763364],
                [116.9818240090708, -1.21214881311721],
                [117.00018557610349, -1.2007159612725218],
                [117.01105521582565, -1.183952945472015],
                [117.02255745576201, -1.1408558753983016],
                [117.05153326764628, -1.119725754439969],
                [117.06483519219046, -1.1064425007207888],
                [117.07945491311386, -1.0832147263784577],
                [117.0987957753049, -1.0589916849182828],
                [117.12064497618474, -1.0164044396131544],
                [117.14511583213005, -0.9936281797965876],
                [117.15753783929995, -0.9687708339068308],
                [117.1788822156418, -0.9450285079592504],
                [117.2031374150597, -0.9280650413685407],
                [117.24878006205029, -0.9071503557042888],
                [117.25148422901407, -0.8436669498697711],
                [117.25011870381525, -0.8348053946531877],
                [117.25749541268317, -0.804246678841082],
                [117.27079297101886, -0.8046982293397491],
                [117.28207730432416, -0.7910772253336518],
                [117.29024510272939, -0.725565949009308],
                [117.30385677536992, -0.683061509680897],
                [117.30223500004524, -0.648003999185164],
                [117.29534263275639, -0.607318951308514],
                [117.27710322423445, -0.5814296045925289],
                [117.31110099971147, -0.5669289485120999],
                [117.33100343006436, -0.5784245757335498],
                [117.3677525356511, -0.5692860311253867],
                [117.37759583128263, -0.5624982001317562],
                [117.38576006387586, -0.5513037153941127],
                [117.39469620256773, -0.5452605869065223],
                [117.41256936128707, -0.5391013748285332],
                [117.42818407323648, -0.5184665617823612],
                [117.43883893327018, -0.4970453878343051],
                [117.4373158990096, -0.4849168834073794],
                [117.45270643695255, -0.46341281265114276],
                [117.45457138805921, -0.4473959031982986],
                [117.43507479116874, -0.43518645978724635],
                [117.42685357663129, -0.43462752394100335],
                [117.42482325908622, -0.4286369311494127],
                [117.43510490316885, -0.42740767212632136],
                [117.43804303865534, -0.4209794197643646],
                [117.42842534155636, -0.3985616737821829],
                [117.43666293904346, -0.3796568325967087],
                [117.44846249215038, -0.38043918791811393],
                [117.45780344357411, -0.38712069296553864],
                [117.46403753590357, -0.3759323101279506],
                [117.46288504301037, -0.36403122985734626],
                [117.45291402476255, -0.35598248370462215],
                [117.44340978433411, -0.29883471624913227],
                [117.42688920507283, -0.27685870736343077],
                [117.41725962073497, -0.2467027286479606],
                [117.41974576865232, -0.21298060167339372],
                [117.44276153321823, -0.20067632834980031],
                [117.44480580464426, -0.17949202593621294],
                [117.47221898331952, -0.10516722268914691],
                [117.47321737738014, -0.088722549805766],
                [117.48172919794422, -0.06895057690752537],
                [117.50101172111408, -0.05415079732136974],
                [117.51772447380552, -0.03150952844191579],
                [117.51463783866541, -0.008475394323966157],
                [117.50724451302392, 0.010644200469528187],
                [117.51880869376623, 0.02663417882433805],
                [117.50453192570785, 0.03448639972759793],
                [117.49088871024583, 0.029780156628987697],
                [117.49906163838398, 0.060104662000583176],
                [117.47239263880931, 0.06558693998533727],
                [117.4858746651837, 0.09139628750870088],
                [117.48011636907529, 0.100937925345022],
                [117.49470246524663, 0.11019402933101219],
                [117.50962159566018, 0.10949295923273894],
                [117.52162835616377, 0.12476909801551983],
                [117.51427582197164, 0.1414443615680625],
                [117.4999133943877, 0.1549787356801744],
                [117.48839927260838, 0.1580901993033308],
                [117.4854011496434, 0.17265250121198505],
                [117.50672817086843, 0.2046809169907533],
                [117.52807193567139, 0.22033777027195356],
                [117.52562109064093, 0.23794578187238358],
                [117.51702880863263, 0.24645996135819814],
                [117.5303398136316, 0.2557059227901277],
                [117.51889460420273, 0.2773352223426855],
                [117.53336172816125, 0.2859964607681036],
                [117.52369398918631, 0.3178258862557186],
                [117.54382183910661, 0.3189139247378989],
                [117.55318892662774, 0.3339121963908269],
                [117.55345865669176, 0.36191078425531487],
                [117.56664086798173, 0.39132092372170746],
                [117.55907319167454, 0.4176349860658206],
                [117.57392103013149, 0.4156124440590929],
                [117.58591351209895, 0.40552352918757606],
                [117.60325978411186, 0.41732565525484233],
                [117.61213517270016, 0.43243523329942946],
                [117.60442571917167, 0.4506143152984805],
                [117.60116585751837, 0.47214843780022875],
                [117.60489347995019, 0.48762681432606314],
                [117.63471703866048, 0.5157163830047011],
                [117.64422536291022, 0.5351485317793845],
                [117.64784384181985, 0.5533317344720103],
                [117.65902887917946, 0.5618761734467057],
                [117.65904932706485, 0.5784907030835029],
                [117.6684009408508, 0.5880475358651438],
                [117.67340642706506, 0.6153902823356816],
                [117.68460748674613, 0.621456597144288],
                [117.70847823857991, 0.6237951321396622],
                [117.71416895783125, 0.6436526854231488],
                [117.7106283197412, 0.6559749622747404],
                [117.71163328784598, 0.6970389592805759],
                [117.72148449391432, 0.7150905911578889],
                [117.73470751374077, 0.7266478093489468],
                [117.75142167027388, 0.7558392022174303],
                [117.81476134016373, 0.7986252237751046],
                [117.85259762538021, 0.8113686648320595],
                [117.87320494705068, 0.8104406750971407],
                [117.87287182287639, 0.821895400252572],
                [117.89174213818922, 0.8321651534990906],
                [117.91439936553832, 0.8372286477838884],
                [117.92501898024182, 0.8051608093239224],
                [117.9605109766004, 0.7912839356051791],
                [117.9732730372209, 0.7728789780824172],
                [118.00000001878595, 0.7741690150880345],
                [118.02946113902044, 0.7804561260494438],
                [118.02994844386569, 0.8020798749863047],
                [118.019742180957, 0.8165288254450047],
                [118.02851798851441, 0.8300949636117139],
                [118.0194162981237, 0.8525990175306788],
                [118.02156798187696, 0.8589109228008738],
                [118.0104321050577, 0.8849660208651926],
                [117.99653036058771, 0.8940399177080849],
                [117.9869890150311, 0.9187009964538788],
                [117.97712102221749, 0.9300610167026093],
                [117.9791960117866, 0.9449529875903409],
                [117.96882118624876, 0.9588755180907356],
                [117.97153932238189, 0.980413515771204],
                [117.96394054302175, 0.9856126808805925],
                [117.95875720777894, 1.0054229604013472],
                [117.95114259765285, 1.0096088260864917],
                [117.94074426833333, 1.0364045230503507],
                [117.92954606579838, 1.0372043953665866],
                [117.91907831421292, 1.0640747108792539],
                [117.90368938066047, 1.0726194673146097],
                [117.88852827056326, 1.089485814318948],
                [117.89142397783439, 1.1059954137573982],
                [117.9018561072736, 1.1124387873202295],
                [117.92486815584073, 1.1046146900159783],
                [117.95074266194433, 1.0795975876586112],
                [117.98433726685141, 1.0592008620301954],
                [117.98713681681068, 1.0356046525327542],
                [117.99593540419562, 1.0200071563052688],
                [117.99073623908623, 1.0000103681840073],
                [118.00753354153983, 0.987612359422883],
                [118.00845301109769, 0.9610120185898268],
                [118.02192102153799, 0.9514180033382331],
                [118.04804569655175, 0.9481765373174085],
                [118.05625469020185, 0.9358583218041758],
                [118.0520495079785, 0.9134186557463977],
                [118.06490118334295, 0.90344801161649],
                [118.10053298111222, 0.893161010272479],
                [118.1744324428031, 0.8768301115204622],
                [118.21111540415268, 0.8670648032980921],
                [118.32032368289174, 0.8497349627983226],
                [118.34227421038656, 0.8379566300944248],
                [118.33966012131759, 0.8174046347161834],
                [118.36102768128251, 0.8044603660530925],
                [118.37889743336996, 0.8038892956545283],
                [118.40459561209786, 0.8195223546729835],
                [118.42376575328694, 0.8392919344752272],
                [118.44268246822139, 0.8436106552076126],
                [118.4710218487794, 0.8301904949954064],
                [118.50261835165566, 0.8204397539914794],
                [118.52791678083486, 0.8160044397531578],
                [118.5464099519578, 0.8084472711246917],
                [118.57278056726943, 0.8147468943537319],
                [118.59332007376247, 0.8118058801372854],
                [118.62960128996758, 0.8474551085664643],
                [118.65149233180568, 0.8461083333329498],
                [118.66384328174217, 0.8556662084288291],
                [118.67749186975402, 0.8570510546624632],
                [118.69232542967495, 0.8481709068619239],
                [118.7093399040134, 0.8291725101033194],
                [118.70627634817504, 0.8179652495966252],
                [118.73128686299845, 0.8222708833945944],
                [118.75896026500425, 0.8059751966980002],
                [118.78618364805698, 0.8075470794342436],
                [118.79511138618159, 0.8016983645878781],
                [118.81709998467659, 0.82100174123741],
                [118.82689552900547, 0.8437229265718429],
                [118.83768426121969, 0.8499441145293076],
                [118.86552102597466, 0.8794263175705623],
                [118.88818995170493, 0.8726303944771985],
                [118.90477003640808, 0.8873426016797907],
                [118.90079133495226, 0.8944114816046635],
                [118.93532346060931, 0.9159095152119789],
                [118.96424580869723, 0.9505473282966364],
                [118.9875522287524, 0.9884438788819807],
                [118.97916228880865, 1.0056194784561967],
                [118.98895834035523, 1.026175144867068],
                [118.9805434181443, 1.037625429170248],
                [118.96636060136075, 1.0359260837218471],
                [118.945911635014, 1.058044889659982],
                [118.92029911671091, 1.0643738595659897],
                [118.87192860651999, 1.0673820567365624],
                [118.85318777379678, 1.0473450122625536],
                [118.83641126996417, 1.0514499335676533],
                [118.83230789009713, 1.0761463592671134],
                [118.82308747840443, 1.1046442120608049],
                [118.79806268684354, 1.1159147272828704],
                [118.76888717420377, 1.1675091694127104],
                [118.76808291589396, 1.1949788569887119],
                [118.75142668919045, 1.2147188652834302],
                [118.73026221502926, 1.2298012766077022],
                [118.71179544800157, 1.2514301651700066],
                [118.67042332257836, 1.2792807650952795],
                [118.65759951000939, 1.2839475846133723],
                [118.64491550064986, 1.3028469471289554],
                [118.60767214835107, 1.3248002166567403],
                [118.5803277246448, 1.354317035502845],
                [118.55083385559806, 1.366889299601553],
                [118.54435851203573, 1.359873692816734],
                [118.51642890576943, 1.362874528137013],
                [118.51673228666573, 1.3754618774637493],
                [118.50205251890873, 1.3747664281282255],
                [118.46846165877855, 1.3854350522858567],
                [118.45477949098074, 1.3862576324838187],
                [118.44199191119776, 1.3756371454379632],
                [118.42958482557935, 1.4002129720510652],
                [118.42888030658094, 1.4264221401696773],
                [118.41912653036513, 1.43022832647614],
                [118.38923235778714, 1.422838185334058],
                [118.38518954186395, 1.4344030846347096],
                [118.41430946471007, 1.4383779738644762],
                [118.42153181664014, 1.4523030530435221],
                [118.40717795329999, 1.4865899443705075],
                [118.3763497385496, 1.4829989334540414],
                [118.37635102458023, 1.5010448231601003],
                [118.34703236235373, 1.511099844382386],
                [118.27513744813484, 1.5495469446358356],
                [118.2457731839853, 1.5625447310545155],
                [118.2113685363289, 1.5887822763809822],
                [118.17690002421682, 1.6088069362911028],
                [118.16355434315847, 1.6271862838199809],
                [118.13462905518679, 1.6338887376751359],
                [118.11506100536121, 1.6520940304152418],
                [118.07276706494815, 1.7098970942755045],
                [118.06144484320498, 1.7185560214433053],
                [118.05249833622929, 1.7341596492484541],
                [118.06362038867007, 1.7469626308208035],
                [118.0666591448038, 1.7618290141977013],
                [118.04814926157917, 1.7832008693247303],
                [118.01196418290078, 1.7833382281767172],
                [117.95845028535246, 1.7960044111746925],
                [117.92942771861442, 1.8056647037631137],
                [117.91096642306206, 1.8211724107781606],
                [117.89838424933373, 1.8380528213806429],
                [117.88843637783668, 1.8600337522559016],
                [117.88966102693496, 1.8726919142005158],
                [117.85874713107535, 1.8833634206853844],
                [117.84488352955134, 1.893660116721037],
                [117.83314008784487, 1.9100033667619982],
                [117.82459277193891, 1.9371101383222822],
                [117.81568143205254, 1.9495621675600319],
                [117.82032802311983, 1.9655989457348255],
                [117.81557478055174, 1.969545469447894],
                [117.81355308660568, 1.9803205940202702],
                [117.80760962458453, 1.9831911967164046],
                [117.8064191794017, 1.98618610550443],
                [117.80796828590769, 1.9881031633303792],
                [117.81153853417607, 1.9884542073958187],
                [117.81950380795445, 1.9865414807048296],
                [117.8190306683307, 1.9945567217153553],
                [117.82415769522152, 2.0098694656034013],
                [117.7896653690616, 2.0137222583755943],
                [117.76694481774916, 2.0130094584198446],
                [117.74399569204763, 2.0301360080703716],
                [117.73686245393912, 2.043546127351931],
                [117.7213895587812, 2.0273961820881823],
                [117.71139534701774, 2.0260798390133914],
                [117.7012941447091, 2.0360173476225327],
                [117.69880302533943, 2.0577754076090287],
                [117.71759798376002, 2.05923246040976],
                [117.71105945562942, 2.0735921954235437],
                [117.71296679178408, 2.0822126352049963],
                [117.72629529039534, 2.0838801140760665],
                [117.7193983437586, 2.1044730906606333],
                [117.72339635096091, 2.1370294462519723],
                [117.71865828175441, 2.149687697229467],
                [117.70375851554309, 2.1655260507866387],
                [117.68293367394995, 2.1781067855997094],
                [117.74134053813543, 2.1918331262978086],
                [117.79038231489199, 2.188967360155573],
                [117.80513010557002, 2.1989284741698043],
                [117.86891965321945, 2.1988925021872774],
                [117.89215080721408, 2.194518508222643],
                [117.90831000560229, 2.20883765413447],
                [117.93386092394258, 2.180001252455895],
                [118.01049789765989, 2.1999726653062908],
                [118.02068208940693, 2.1980055017525615],
                [118.0533106551627, 2.227128504776715],
                [118.07584701722612, 2.2586336068555397],
                [118.09449360999213, 2.2755681314641265],
                [118.09478135527536, 2.310455610737904],
                [118.0864762070114, 2.32552483170565],
                [118.06499596109484, 2.349109656685357],
                [118.05391797903155, 2.3672750806804856],
                [118.04156500202328, 2.3674583373324367],
                [118.0308934658608, 2.358328044004054],
                [118.01508345893455, 2.3663088373829737],
                [118.01663254925279, 2.3964170000445506],
                [117.99809527987338, 2.4143591727377043],
                [117.99967408469126, 2.4334812982233984],
                [117.98593392680937, 2.435721765746962]
              ]
            ]
          ]
        },
        "id": 64,
        "properties": {
          "ID": 21,
          "kode": 64,
          "Propinsi": "KALIMANTAN TIMUR",
          "SUMBER": "Batas Provinsi Desember 2019 Dukcapil (Skala tidak diketahui)"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [117.70565965357025, 2.8393553992323177],
                [117.69280678300674, 2.8518314610531093],
                [117.68042758536478, 2.8499858273841028],
                [117.65756240058442, 2.835162927127442],
                [117.64136507453861, 2.815179237631753],
                [117.65587632013614, 2.798534608585385],
                [117.67753606084057, 2.7964958248225003],
                [117.700391845907, 2.8056672657317563],
                [117.7296656553458, 2.8071669356056645],
                [117.72804155167637, 2.828907575724088],
                [117.70565965357025, 2.8393553992323177]
              ]
            ],
            [
              [
                [117.58087911987559, 2.9032799629999317],
                [117.5463563237854, 2.9079924797691774],
                [117.54576863661339, 2.8915278266479163],
                [117.57093797980394, 2.883581485397258],
                [117.58535024691639, 2.8886583247219733],
                [117.5941542356295, 2.8600302950621312],
                [117.62129859615334, 2.8557992141624595],
                [117.63526351799703, 2.8504350963027036],
                [117.64881111394186, 2.8605581368485673],
                [117.6669545036475, 2.8550556322117018],
                [117.68494917786234, 2.8632350300726443],
                [117.70490611522507, 2.8612674888036054],
                [117.71725567391024, 2.8716797881917273],
                [117.71871188764351, 2.892931984023903],
                [117.71258517616502, 2.895560063845892],
                [117.65342705251658, 2.892610541143597],
                [117.60543050831131, 2.904216911781077],
                [117.58087911987559, 2.9032799629999317]
              ]
            ],
            [
              [
                [117.68736278556412, 2.935048103287386],
                [117.66252154023698, 2.9276603246643162],
                [117.63944265675332, 2.9266605807212613],
                [117.60560624663106, 2.91738862618422],
                [117.61518074479557, 2.9074955216011062],
                [117.64276116499582, 2.9018621961834583],
                [117.71057515991572, 2.9042087333464224],
                [117.71398029625368, 2.9157081224319654],
                [117.70368217569194, 2.9317512893905473],
                [117.68736278556412, 2.935048103287386]
              ]
            ],
            [
              [
                [117.46262382862687, 2.9531572567020703],
                [117.44834895813801, 2.9466657091551838],
                [117.45584110668176, 2.935462729421488],
                [117.46677385452983, 2.9394206844170867],
                [117.50700358554707, 2.915610181764521],
                [117.54050473020925, 2.912328670731597],
                [117.56591820875838, 2.9204400105980426],
                [117.57326521211985, 2.9451516438333556],
                [117.55902098602974, 2.9503516857816976],
                [117.52484885539047, 2.937549247399886],
                [117.50501987806501, 2.938987812837354],
                [117.48027814272223, 2.9544144495672526],
                [117.46262382862687, 2.9531572567020703]
              ]
            ],
            [
              [
                [117.66728803071805, 2.987271695832135],
                [117.65562912528401, 2.9875690332843305],
                [117.63557151511338, 2.978665982449172],
                [117.61391858227682, 2.9821977954919134],
                [117.59026697993727, 2.974033145613248],
                [117.56365178680062, 2.9539498993684106],
                [117.57456188702156, 2.945901840297722],
                [117.58271797625366, 2.928301936085518],
                [117.6169662066252, 2.9260218804055285],
                [117.643478389616, 2.9339045154810606],
                [117.6658857888981, 2.9365509954321283],
                [117.693154919564, 2.9457601638005713],
                [117.69049326265406, 2.9585540325426223],
                [117.66735116672305, 2.963963838660277],
                [117.66728803071805, 2.987271695832135]
              ]
            ],
            [
              [
                [117.54684432290719, 2.957495583554362],
                [117.55992902880166, 2.9645182626077826],
                [117.56359849477474, 2.9753271198507605],
                [117.55593877375543, 2.986811211468307],
                [117.53659053532499, 2.9580795996938036],
                [117.53134146323805, 2.9563270800307464],
                [117.53018180274807, 2.969309627437383],
                [117.50468459911497, 2.969909658703841],
                [117.503425460117, 2.961370128228907],
                [117.52100352968921, 2.94050027826529],
                [117.54684432290719, 2.957495583554362]
              ]
            ],
            [
              [
                [117.47901174439653, 2.9586126953196867],
                [117.49901555712233, 2.9610283588717152],
                [117.50476840513784, 2.9711671123723704],
                [117.53009771973404, 2.9710737735358634],
                [117.53408829403963, 2.9577460861130476],
                [117.5475160796035, 2.978601098162187],
                [117.54802717421478, 2.993093583002519],
                [117.5572737957491, 2.9899044836050734],
                [117.5588610209195, 3.0056538636349615],
                [117.54236618834898, 3.0117777620341144],
                [117.52386458967908, 3.006512635249976],
                [117.48085808313408, 2.981988694123004],
                [117.47901174439653, 2.9586126953196867]
              ]
            ],
            [
              [
                [117.49327839238401, 2.9998233285837728],
                [117.48837295215003, 3.0161639400138824],
                [117.4560014441114, 2.976933127361974],
                [117.47734858137198, 2.9687457821921157],
                [117.48160527766152, 2.986593160046368],
                [117.49327839238401, 2.9998233285837728]
              ]
            ],
            [
              [
                [117.51065813716332, 3.0142809943724274],
                [117.49459843526961, 3.028582268057278],
                [117.48759463028807, 3.018851171844176],
                [117.49594094951522, 3.0049877960499884],
                [117.51065813716332, 3.0142809943724274]
              ]
            ],
            [
              [
                [117.62281549793693, 3.0590440036778546],
                [117.5942227837545, 3.0564044727882447],
                [117.57430254592578, 3.046111279611921],
                [117.56363679060541, 3.0487133168661558],
                [117.54821037870613, 3.0344004832957125],
                [117.52829744427174, 3.0388433104704404],
                [117.51979090992256, 3.015814589371871],
                [117.55361963623727, 3.0162953957159857],
                [117.56503293951857, 3.005650672840318],
                [117.5714338875639, 2.983112207262593],
                [117.5776040398946, 2.983527078012287],
                [117.60352641601139, 3.0104177730618176],
                [117.61891437110137, 3.017807544582581],
                [117.62743009382382, 3.051310114670116],
                [117.62281549793693, 3.0590440036778546]
              ]
            ],
            [
              [
                [117.5165557526459, 3.0582089274001305],
                [117.51130697193923, 3.059297056713831],
                [117.50714109008925, 3.064410428298686],
                [117.50054910086033, 3.041136358656047],
                [117.49479664315061, 3.031576640849323],
                [117.50811767289235, 3.0179099153103834],
                [117.5178757838404, 3.0215963433965953],
                [117.51996631998975, 3.0392725658762743],
                [117.52872443518459, 3.0551813885103343],
                [117.5165557526459, 3.0582089274001305]
              ]
            ],
            [
              [
                [117.53000630454733, 3.0679094133887475],
                [117.48875516401972, 3.064047806361202],
                [117.46476003594694, 3.0543935671094573],
                [117.44619272831187, 3.053827917824208],
                [117.41939325869203, 3.03002048815506],
                [117.41400919656724, 3.0189632444582344],
                [117.39845478775828, 3.021040103717837],
                [117.37838365178413, 3.0036779982410735],
                [117.37704462510942, 2.9868965634257165],
                [117.36036673553645, 2.9629188795027517],
                [117.37379431605495, 2.961575470431683],
                [117.38188949960079, 2.9563978872527628],
                [117.40360258629039, 2.9575934010147193],
                [117.40894307626206, 2.9650550787289376],
                [117.42037182360082, 2.9683528674908644],
                [117.45103474565701, 2.96566288013787],
                [117.45551279657832, 2.9757662947787367],
                [117.451042212728, 2.989555445027804],
                [117.45952590969307, 3.0049585635869107],
                [117.47819505475809, 3.0052852432194754],
                [117.48745734518036, 3.031326676085257],
                [117.4987184022325, 3.041209583255693],
                [117.50264004170458, 3.0649101302983013],
                [117.50897956425558, 3.0652418515302884],
                [117.51280977774911, 3.0603819421729668],
                [117.52622232789815, 3.0602125746506204],
                [117.52980810565964, 3.056022191670877],
                [117.52513113909083, 3.045050881792804],
                [117.55313106874394, 3.039925461990549],
                [117.54922493987908, 3.06421769369166],
                [117.53000630454733, 3.0679094133887475]
              ]
            ],
            [
              [
                [117.56993845439627, 3.0511254289954195],
                [117.58869918282107, 3.060686556039798],
                [117.61930894055524, 3.066914801876635],
                [117.62001617730482, 3.0761155085239693],
                [117.60220352137549, 3.0831144733543283],
                [117.57017529265568, 3.0788531500529075],
                [117.55140667627711, 3.0719242990671773],
                [117.56993845439627, 3.0511254289954195]
              ]
            ],
            [
              [
                [117.4935149707394, 3.082647280047979],
                [117.46819879793622, 3.081799366847349],
                [117.44900686077801, 3.0678385162345876],
                [117.45704174478078, 3.057557232780141],
                [117.48810928171804, 3.0736558033726737],
                [117.4935149707394, 3.082647280047979]
              ]
            ],
            [
              [
                [117.52895223975418, 3.09818205575732],
                [117.5054705058592, 3.092817857857881],
                [117.49991699976977, 3.0797989994243267],
                [117.51975224357125, 3.0747718521392926],
                [117.53712466517118, 3.0791960705422525],
                [117.55106366225823, 3.076465886191727],
                [117.5673753263103, 3.081867171233],
                [117.56176124278716, 3.0935215377887744],
                [117.52895223975418, 3.09818205575732]
              ]
            ],
            [
              [
                [117.38847333917329, 3.090216637188121],
                [117.37876123214517, 3.096806271092589],
                [117.37663246221837, 3.110078764423463],
                [117.36254894587717, 3.1082291125835013],
                [117.37156699302238, 3.0801173711198544],
                [117.36505880891696, 3.0672368940674346],
                [117.35534689074643, 3.0675296755541126],
                [117.3561172095416, 3.0480062491890862],
                [117.37078099634596, 3.048390292078352],
                [117.39302035280707, 3.0610208088633044],
                [117.39584369253248, 3.077322165785347],
                [117.39982585637824, 3.084648605248958],
                [117.38847333917329, 3.090216637188121]
              ]
            ],
            [
              [
                [117.58706511106607, 3.1112282400912363],
                [117.56203828254081, 3.108656863423562],
                [117.56805637570119, 3.0972123149963977],
                [117.5832982620052, 3.102661345045078],
                [117.58706511106607, 3.1112282400912363]
              ]
            ],
            [
              [
                [117.45888536037114, 3.112244418245382],
                [117.44422169857262, 3.120646128516057],
                [117.43139635805551, 3.1092344458130583],
                [117.40138980079871, 3.1078259851811367],
                [117.38973228032057, 3.0951919403557895],
                [117.40266398005872, 3.090392265790456],
                [117.40711205207958, 3.077227438395596],
                [117.39642313292131, 3.0730337495079993],
                [117.39515678315911, 3.0600972410948657],
                [117.38486453517021, 3.055704230066226],
                [117.38291915499508, 3.0417999135352147],
                [117.39116644267722, 3.031248317256086],
                [117.40180538471077, 3.042404243561066],
                [117.42388077497787, 3.0365053303567606],
                [117.43860746036603, 3.0585297308624604],
                [117.44887746452343, 3.0828990515500436],
                [117.47748972007457, 3.0958731336382357],
                [117.47613847521052, 3.1050100136013157],
                [117.45888536037114, 3.112244418245382]
              ]
            ],
            [
              [
                [117.59703161831465, 3.1258684682552484],
                [117.58219261569673, 3.119515406410926],
                [117.60082511428766, 3.1063375820138504],
                [117.61604190500998, 3.1092010512875277],
                [117.61126244720424, 3.125295051525484],
                [117.59703161831465, 3.1258684682552484]
              ]
            ],
            [
              [
                [117.30586219158693, 3.230271816790946],
                [117.27042408080672, 3.2298114691240585],
                [117.2781216992571, 3.2098428315814544],
                [117.28926822584799, 3.2070170897669072],
                [117.30586219158693, 3.230271816790946]
              ]
            ],
            [
              [
                [117.45569611978021, 3.2780816923162774],
                [117.47087123412018, 3.2933635786662876],
                [117.45831317031661, 3.3026694898051687],
                [117.45139291954763, 3.2853481775763953],
                [117.43833937516206, 3.2772300334393663],
                [117.39920039498236, 3.2748490684298304],
                [117.40810192776178, 3.2499315596937777],
                [117.42290904671518, 3.248299117510328],
                [117.45569611978021, 3.2780816923162774]
              ]
            ],
            [
              [
                [117.38615408474323, 3.2975164563194426],
                [117.36843455541043, 3.2945426546198746],
                [117.36287711208911, 3.2787802164339155],
                [117.35625472127845, 3.2667412566037797],
                [117.36602042340382, 3.2544973637623684],
                [117.37927218556297, 3.2712109735076638],
                [117.37542746510553, 3.2799516553446892],
                [117.38615408474323, 3.2975164563194426]
              ]
            ],
            [
              [
                [117.44676098473167, 3.3409000081289264],
                [117.43432756066841, 3.336617658678051],
                [117.4143189716433, 3.321464214401999],
                [117.39629349474899, 3.3130186648795075],
                [117.3835220568975, 3.2865527475113367],
                [117.39607981133429, 3.276270658264309],
                [117.43610373519925, 3.2785518416941954],
                [117.44924917321089, 3.284571126456342],
                [117.45744341168415, 3.305112509834487],
                [117.47360209116368, 3.2971799866671176],
                [117.46985398217555, 3.2751350015790877],
                [117.5166776548499, 3.2758979971948747],
                [117.52257289432373, 3.3006925164465315],
                [117.50451331603676, 3.322016429614564],
                [117.47370362102538, 3.3295706017019597],
                [117.44676098473167, 3.3409000081289264]
              ]
            ],
            [
              [
                [117.48858721292959, 3.4078995680106914],
                [117.45487737429141, 3.406446479421561],
                [117.44928904375445, 3.4020922561527414],
                [117.45112619052134, 3.353606174085712],
                [117.4553246647016, 3.3473975235768876],
                [117.49652622200756, 3.341256729614429],
                [117.50508342326145, 3.355352799592083],
                [117.5022946381871, 3.388189133022138],
                [117.49770724319478, 3.405363942092322],
                [117.48858721292959, 3.4078995680106914]
              ]
            ],
            [
              [
                [117.4172208626527, 3.4211427371476475],
                [117.37246779265456, 3.4404725322816034],
                [117.36669969123784, 3.4258754967568166],
                [117.3718916213013, 3.414138402465369],
                [117.39693432555873, 3.4074846783752832],
                [117.41104041578262, 3.3968026866946843],
                [117.42173819596098, 3.370768793744901],
                [117.41628336798306, 3.345985291188299],
                [117.43428482128752, 3.347817155337509],
                [117.43694775973142, 3.3726909237472],
                [117.44426654776305, 3.3986992813468078],
                [117.43811320376142, 3.417575697660368],
                [117.4172208626527, 3.4211427371476475]
              ]
            ],
            [
              [
                [117.61864830577474, 3.438541292146965],
                [117.59747652282329, 3.4434137461475416],
                [117.56349486679471, 3.43847232763585],
                [117.54857758089065, 3.4422965822215588],
                [117.52141850476028, 3.414232918514415],
                [117.51495792626736, 3.381084482562869],
                [117.51955831196653, 3.3651773848284847],
                [117.53392314613632, 3.3488573310010565],
                [117.54117523864636, 3.326880271707239],
                [117.5709958511776, 3.306151824846893],
                [117.58513310470903, 3.2903460024660944],
                [117.60409196906915, 3.2784578697349502],
                [117.62708585582766, 3.244539484609561],
                [117.64809554587282, 3.241068097015102],
                [117.65277149710698, 3.260631930818988],
                [117.65739516265671, 3.305165219099649],
                [117.65709929379739, 3.3263787332919605],
                [117.66335998537511, 3.3576100448677266],
                [117.66805502921625, 3.362322074204428],
                [117.66484185676632, 3.3841605668567354],
                [117.66865495166473, 3.4036577833800266],
                [117.66578098010825, 3.4152204901335494],
                [117.65327922105928, 3.4191066207739595],
                [117.61864830577474, 3.438541292146965]
              ]
            ],
            [
              [
                [117.4448693588339, 3.5503453731404875],
                [117.41552711098745, 3.556924992194695],
                [117.41649108439606, 3.5416575948221976],
                [117.43970412514534, 3.537263985744403],
                [117.45625424533716, 3.5154866541857928],
                [117.47196596715571, 3.51155148531285],
                [117.48605562406783, 3.498318548662155],
                [117.50138831491881, 3.5062614832808663],
                [117.51139068623127, 3.5039853540408785],
                [117.54718979567258, 3.4712424116080456],
                [117.56305271870804, 3.47455113882404],
                [117.60606753319018, 3.4743858632170372],
                [117.61509514282659, 3.4866187564505253],
                [117.60942869987468, 3.5006169882834115],
                [117.56891761415659, 3.520175166250965],
                [117.54305862405636, 3.527234405443494],
                [117.50817147663236, 3.5431954840843787],
                [117.47863399766095, 3.5489222013985113],
                [117.4448693588339, 3.5503453731404875]
              ]
            ],
            [
              [
                [117.2480673322417, 3.5881477377279793],
                [117.23486939360771, 3.5855771074975564],
                [117.21955353716612, 3.5720315997785406],
                [117.20141585186013, 3.539053423476787],
                [117.23076864155962, 3.4967593733464355],
                [117.2651163792176, 3.4873721994674725],
                [117.29965768335546, 3.4847977911851444],
                [117.32304983398274, 3.491269501486329],
                [117.34764891796328, 3.484100243731291],
                [117.38405703570368, 3.469403155031614],
                [117.40764213857392, 3.466511617017545],
                [117.44464048766838, 3.456700062058246],
                [117.46177772216424, 3.4492705180200574],
                [117.47668329439853, 3.4350775766689026],
                [117.49289285482143, 3.4594457183452505],
                [117.51790566111742, 3.481845526449945],
                [117.50528381199842, 3.4972583450966113],
                [117.48012155906281, 3.4935851307492385],
                [117.46900293034105, 3.505291661580941],
                [117.43543611628377, 3.508632950550293],
                [117.43765809284673, 3.5289191153249817],
                [117.40713695610566, 3.537931451775137],
                [117.40038312038473, 3.5481512737623007],
                [117.37109588226917, 3.562561659493042],
                [117.33620669878019, 3.565389339346609],
                [117.31105172675586, 3.5598601041605207],
                [117.28655386776688, 3.5683675495228613],
                [117.27267132292332, 3.5873615261135683],
                [117.2480673322417, 3.5881477377279793]
              ]
            ],
            [
              [
                [117.62244393134245, 3.5758667496507996],
                [117.59636105322011, 3.5824601661038287],
                [117.55995623239426, 3.5879008873161524],
                [117.55143739891685, 3.577455849008288],
                [117.53718593868018, 3.575720870621751],
                [117.5232617678164, 3.5827577274872056],
                [117.50985307126234, 3.5792660062212462],
                [117.53469829339156, 3.562140127464943],
                [117.5625263626016, 3.5606227265576536],
                [117.58201891434146, 3.5552753387859184],
                [117.60454638570718, 3.5421396134524343],
                [117.62813720556767, 3.5369073362046493],
                [117.65696083687317, 3.544285688134039],
                [117.65566210162478, 3.5597805941990828],
                [117.62244393134245, 3.5758667496507996]
              ]
            ],
            [
              [
                [117.81739809124895, 3.576855824037125],
                [117.80089261340345, 3.589364152633607],
                [117.79513709979733, 3.583337394505179],
                [117.75623589137331, 3.5639538298054276],
                [117.75141900288475, 3.5353222694072315],
                [117.7591270031088, 3.5170494708564206],
                [117.77218156732556, 3.5058216140762966],
                [117.79694501589984, 3.492660606102163],
                [117.81581313416882, 3.4778177435940165],
                [117.82633892709987, 3.475256594016628],
                [117.84234363275255, 3.4554651525947406],
                [117.86709082338291, 3.450069987440031],
                [117.87325342140832, 3.4761518924958636],
                [117.85726215792226, 3.505000216486849],
                [117.85010440339556, 3.5372416447861497],
                [117.81739809124895, 3.576855824037125]
              ]
            ],
            [
              [
                [117.49285474155317, 3.569078499573152],
                [117.48875981891058, 3.578044293409448],
                [117.46255759625615, 3.591194674094993],
                [117.47050784506109, 3.5730994952455717],
                [117.49285474155317, 3.569078499573152]
              ]
            ],
            [
              [
                [117.42231988555432, 3.600744274528722],
                [117.38953610041074, 3.6136810347520054],
                [117.36902627694121, 3.6070613850749282],
                [117.32684593978831, 3.6006652861740918],
                [117.2988405143783, 3.6006281702539127],
                [117.29193686239637, 3.5965759510193607],
                [117.30287671129122, 3.5784944940257333],
                [117.31203462190035, 3.584243083751346],
                [117.34427461128439, 3.5880575015524983],
                [117.39116285618093, 3.5903035862415322],
                [117.40940923984465, 3.588308349450813],
                [117.42424172327696, 3.5777883922205547],
                [117.43562090987712, 3.5622010601308602],
                [117.46037787074215, 3.5687366312905624],
                [117.45556234832372, 3.582935666447895],
                [117.42231988555432, 3.600744274528722]
              ]
            ],
            [
              [
                [117.54544192370588, 3.5805492407549195],
                [117.54937498456798, 3.598695276266028],
                [117.54353975595507, 3.60709380653401],
                [117.5227499627407, 3.615303372152937],
                [117.51287988619788, 3.610413246474195],
                [117.50697295823545, 3.5950098644137825],
                [117.54544192370588, 3.5805492407549195]
              ]
            ],
            [
              [
                [117.37001504555792, 3.622581929012881],
                [117.32763385471867, 3.6191863299852116],
                [117.33907133627326, 3.6114672761938778],
                [117.3575424317379, 3.613499580341397],
                [117.37001504555792, 3.622581929012881]
              ]
            ],
            [
              [
                [117.48215589208087, 3.637394906150064],
                [117.45568535129803, 3.6334589341824994],
                [117.43654606674761, 3.6408400227488755],
                [117.43022668631261, 3.6237290250745104],
                [117.49060686181724, 3.598143073643996],
                [117.50337065363283, 3.605644382682158],
                [117.52047805851566, 3.6338439582321485],
                [117.48215589208087, 3.637394906150064]
              ]
            ],
            [
              [
                [117.49342175510992, 3.6675944768036857],
                [117.48908511988247, 3.654024107326734],
                [117.49783000964726, 3.639656048188101],
                [117.5078353532191, 3.635723975681003],
                [117.51817577159466, 3.6511925459992653],
                [117.50326524229718, 3.667841124868062],
                [117.49342175510992, 3.6675944768036857]
              ]
            ],
            [
              [
                [117.49139363910524, 3.684123613114309],
                [117.49283488811966, 3.699016525592242],
                [117.48434469715016, 3.708220640670106],
                [117.44935894625837, 3.698815076554581],
                [117.42953740272901, 3.6749087403461544],
                [117.45850271682696, 3.678805115163982],
                [117.46659419876335, 3.671395835549447],
                [117.49139363910524, 3.684123613114309]
              ]
            ],
            [
              [
                [117.74452200588587, 3.6994613842355193],
                [117.7331086207663, 3.7178980230753496],
                [117.72824878875065, 3.6854804598553983],
                [117.7088396401374, 3.6685525551563387],
                [117.71096036293045, 3.6488029061292764],
                [117.72129160372458, 3.6351762238037395],
                [117.73431488772201, 3.631474938018016],
                [117.76136739868662, 3.6574329722539005],
                [117.76852405064449, 3.6820994217509337],
                [117.74452200588587, 3.6994613842355193]
              ]
            ],
            [
              [
                [117.77493861892219, 3.752648336851337],
                [117.75442220881791, 3.7604659887144294],
                [117.74901749286312, 3.7543833655998924],
                [117.78896336456773, 3.7422650432971523],
                [117.80990587525605, 3.7302898723797284],
                [117.80948663919708, 3.7530597137348423],
                [117.77493861892219, 3.752648336851337]
              ]
            ],
            [
              [
                [117.68013138375636, 3.7755859934988507],
                [117.67910768097477, 3.7628471740578675],
                [117.71228811840206, 3.742882729154701],
                [117.72496053909765, 3.744211144728581],
                [117.7303772933775, 3.7410223699864105],
                [117.72853864204478, 3.7315615812020724],
                [117.73503140367643, 3.720239380143312],
                [117.7491073252429, 3.7011310484591604],
                [117.77704999326261, 3.686331563850615],
                [117.7919528099743, 3.6890092035120006],
                [117.81109031476626, 3.7025172445752332],
                [117.82595065919566, 3.7041994957102133],
                [117.81010175389292, 3.726024769227763],
                [117.74999999770182, 3.7471616737722684],
                [117.72556051280753, 3.762372080207399],
                [117.68013138375636, 3.7755859934988507]
              ]
            ],
            [
              [
                [117.79872759899956, 3.7819444314903876],
                [117.78149536461945, 3.7850234700571264],
                [117.77903646975733, 3.774389208994819],
                [117.79505068394201, 3.7596017591139343],
                [117.81481125433595, 3.7575297427035252],
                [117.8135933663408, 3.779350139197618],
                [117.79872759899956, 3.7819444314903876]
              ]
            ],
            [
              [
                [117.74175281883754, 3.7821457078582057],
                [117.73876171777908, 3.789267602817688],
                [117.72874446088827, 3.782907604502384],
                [117.7230757822216, 3.781908237375319],
                [117.71749104357684, 3.785097150613069],
                [117.7076923138493, 3.7722561097317],
                [117.7538176035996, 3.765092427773368],
                [117.78372328570117, 3.7549273574116455],
                [117.76582335416458, 3.7854446468530227],
                [117.74175281883754, 3.7821457078582057]
              ]
            ],
            [
              [
                [117.59534466552395, 3.7798254210064215],
                [117.55942547410643, 3.782843780516089],
                [117.53165418089577, 3.765980475018978],
                [117.51908869318868, 3.774012371473816],
                [117.50490559491732, 3.765046552456454],
                [117.49728398266973, 3.7740251580346467],
                [117.48381814409208, 3.7650585548084905],
                [117.47833244958292, 3.750460366352854],
                [117.48636643786085, 3.74207877031904],
                [117.48606834587747, 3.717122159060466],
                [117.50236786728328, 3.7020129335500656],
                [117.5204235667942, 3.6740515056724803],
                [117.5516840146056, 3.649191866511501],
                [117.57660404594003, 3.6338957196118145],
                [117.60094685371439, 3.625737173431844],
                [117.66853000165827, 3.6156727470996657],
                [117.71754357207828, 3.6343690256101127],
                [117.71021245254269, 3.650232807498128],
                [117.70805340514062, 3.6684989016030727],
                [117.7277448806218, 3.6859060460272417],
                [117.73136114809859, 3.7206676121206783],
                [117.72586822304015, 3.728967538021493],
                [117.72931643690413, 3.7404081150424986],
                [117.71261612273588, 3.742041163369038],
                [117.66893778934752, 3.768047640486259],
                [117.66494759365662, 3.7840536330687655],
                [117.64958975236527, 3.7676462092052247],
                [117.63101970719379, 3.776091125605035],
                [117.61314365175815, 3.7722773364299655],
                [117.59879280384484, 3.7733638937287424],
                [117.59534466552395, 3.7798254210064215]
              ]
            ],
            [
              [
                [117.70397972088745, 3.781417405388993],
                [117.68015314375259, 3.79355848863338],
                [117.67823857794804, 3.781460702349591],
                [117.69313892871867, 3.775076759584863],
                [117.70397972088745, 3.781417405388993]
              ]
            ],
            [
              [
                [117.74175281883754, 3.7821457078582057],
                [117.76480088525273, 3.786332072667051],
                [117.75620039872945, 3.799536681572647],
                [117.74368853220005, 3.8015643946811224],
                [117.73163626600092, 3.8108849953463277],
                [117.7293397788078, 3.7961416716009353],
                [117.71561418724502, 3.7963431521148436],
                [117.71566011562186, 3.7919647487742054],
                [117.71265395361718, 3.784431739533204],
                [117.7183225765258, 3.785349756685946],
                [117.72640978218578, 3.7829095695210526],
                [117.73200248522653, 3.7906121971951734],
                [117.73917407582303, 3.789683373788705],
                [117.74175281883754, 3.7821457078582057]
              ]
            ],
            [
              [
                [117.70397972088745, 3.781417405388993],
                [117.71006761492856, 3.786442127202463],
                [117.71508044590587, 3.792046646435608],
                [117.71391268162506, 3.794978588291258],
                [117.71624749522573, 3.797826203416605],
                [117.71908541116284, 3.7978238381996334],
                [117.72917176206727, 3.7971459418317863],
                [117.72592899382812, 3.8056973280693],
                [117.73116281790976, 3.8114553058177307],
                [117.72567726009754, 3.8169509980901353],
                [117.69483939921895, 3.812788149653443],
                [117.69376375429385, 3.8071622526359192],
                [117.6862488133321, 3.802020963396785],
                [117.69914991993289, 3.7912274503866],
                [117.70397972088745, 3.781417405388993]
              ]
            ],
            [
              [
                [117.7354172892766, 3.87298092622882],
                [117.71877387881148, 3.8724317588186636],
                [117.71012508880187, 3.8605618895196585],
                [117.72286786705843, 3.844752572373352],
                [117.73499654685327, 3.8408271242724368],
                [117.73480224292928, 3.819821674530715],
                [117.74385468374669, 3.805990188696285],
                [117.76103864521735, 3.802542364238775],
                [117.78613768930302, 3.789293706539354],
                [117.80708943782736, 3.789015901462733],
                [117.82650384117937, 3.7827752881490824],
                [117.8356639740133, 3.7939676612786],
                [117.82917815964436, 3.807536623316537],
                [117.77283590325442, 3.8458961925553012],
                [117.75852035373146, 3.865521452752887],
                [117.7354172892766, 3.87298092622882]
              ]
            ],
            [
              [
                [117.5228192393165, 4.027963962367039],
                [117.49966043937786, 4.05276963770865],
                [117.4644354022721, 4.030095521990802],
                [117.44327887721852, 4.005761437640274],
                [117.45659638521511, 3.9851464861214936],
                [117.4631909150288, 3.9677816242226527],
                [117.46530730039171, 3.953053070043211],
                [117.4616601537931, 3.930030074028764],
                [117.4965689182211, 3.9162591908090576],
                [117.52637384207924, 3.9296384444587034],
                [117.54225079094135, 3.9278390755129635],
                [117.56527800657477, 3.918393216373886],
                [117.59334442220734, 3.8869616635499824],
                [117.60620947318864, 3.8821056059889543],
                [117.65407847554127, 3.8896542157694967],
                [117.6816598139493, 3.887949378161295],
                [117.68272444307865, 3.913798233801515],
                [117.66557121503956, 3.9102120612377007],
                [117.64405274164073, 3.9114077071999986],
                [117.62213732008126, 3.923882546842094],
                [117.59745398401424, 3.933491176076984],
                [117.5775234552433, 3.969789705130836],
                [117.55465393033467, 3.9850926634956636],
                [117.54750610252472, 4.015362631280482],
                [117.53133992090068, 4.0308481861948735],
                [117.5228192393165, 4.027963962367039]
              ]
            ],
            [
              [
                [117.55434421461382, 4.0991712225738866],
                [117.57547526457188, 4.11834558787865],
                [117.5887401010749, 4.1407322802705835],
                [117.57498455309474, 4.1445304931877445],
                [117.55378034958358, 4.139266491455487],
                [117.53571589517253, 4.116229529868974],
                [117.53930119539393, 4.103953384641045],
                [117.55434421461382, 4.0991712225738866]
              ]
            ],
            [
              [
                [117.66086041237816, 4.146907465207391],
                [117.62941113481611, 4.135183756425249],
                [117.58927553763601, 4.080048629440739],
                [117.603026533248, 4.010830168677387],
                [117.62412210764921, 3.99038929083315],
                [117.66906204597672, 3.966490659116687],
                [117.7017591832398, 3.979436113985514],
                [117.72403513401423, 3.984371262423849],
                [117.72728396458331, 4.01177095666435],
                [117.74319999978024, 4.0340270000460805],
                [117.75350656767398, 4.0617375620389],
                [117.73112628385093, 4.077652763564231],
                [117.71043248607043, 4.097544401153243],
                [117.6916148656336, 4.120968537068393],
                [117.66877544373176, 4.144088210202767],
                [117.66086041237816, 4.146907465207391]
              ]
            ],
            [
              [
                [117.90110403482345, 4.166424557647247],
                [117.68345039651865, 4.166558506270121],
                [117.70490269690197, 4.150010749496516],
                [117.73112657253341, 4.120837646140501],
                [117.76633350178975, 4.094221154382808],
                [117.81499267727031, 4.06656931182431],
                [117.86121902563764, 4.028976658346153],
                [117.89294739340755, 4.042314167265033],
                [117.90598565921084, 4.0407318793727995],
                [117.92627782426223, 4.063103457758416],
                [117.91755975767671, 4.083067039312482],
                [117.92567868782123, 4.124770595486666],
                [117.92380203113885, 4.134401846268247],
                [117.90110403482345, 4.166424557647247]
              ]
            ],
            [
              [
                [117.98593392680937, 2.435721765746962],
                [117.95899707315641, 2.4522190732516833],
                [117.93816395690112, 2.4728579053680733],
                [117.88618696385731, 2.5399855520915935],
                [117.86406716686633, 2.5594527042789537],
                [117.83809083862138, 2.5900974690230214],
                [117.81189906698842, 2.60968878610862],
                [117.79857597779915, 2.6289659250374484],
                [117.78962544635738, 2.6527063237379025],
                [117.79018437860623, 2.7185172631612318],
                [117.78520058224115, 2.748287808635496],
                [117.7677057907897, 2.747366868686072],
                [117.7512112756799, 2.7541079754028033],
                [117.73234739321765, 2.7715308111980335],
                [117.69706830685698, 2.7837322821191606],
                [117.68495964866895, 2.782161914740641],
                [117.64605697704792, 2.791574261542962],
                [117.61522680177552, 2.7951914184492352],
                [117.59953574817632, 2.8140638129946183],
                [117.63559810626771, 2.817016242305783],
                [117.64532682197535, 2.823082337679807],
                [117.66469384184734, 2.845320448579912],
                [117.64985212777356, 2.8560966478420937],
                [117.6320061699488, 2.847471100810708],
                [117.59508529205266, 2.857505809045904],
                [117.5918580119378, 2.86183176482416],
                [117.5858536586195, 2.8866768592496896],
                [117.56864156455663, 2.882895098530014],
                [117.54555537677925, 2.8900804595456293],
                [117.5390398470114, 2.906593779479408],
                [117.5303648966227, 2.903205397511158],
                [117.48448204880754, 2.92312894934156],
                [117.47308324439632, 2.9353198316449607],
                [117.45959632660924, 2.931599300367452],
                [117.44841785609924, 2.940351015986664],
                [117.44741828392671, 2.9468832092927073],
                [117.45535265340231, 2.9499649314365115],
                [117.44921860435522, 2.9597289257493458],
                [117.43084721132982, 2.9654809787642193],
                [117.4090345355155, 2.962947130805503],
                [117.40665445903608, 2.9568232962581646],
                [117.38655828323829, 2.953962657150953],
                [117.381126375382, 2.9549144654303063],
                [117.37646509838567, 2.9596566274514657],
                [117.36922452079511, 2.955732722587129],
                [117.35431648621693, 2.9562351837068377],
                [117.37053690505422, 2.9836238162861264],
                [117.37075813737817, 2.9926072907938988],
                [117.38526144466005, 3.0138895284790124],
                [117.38649012182168, 3.0370490397814365],
                [117.37148277960671, 3.0463002244758286],
                [117.35376744201392, 3.0467585585606685],
                [117.34501653038865, 3.073956362196384],
                [117.36612718731988, 3.0795764540901587],
                [117.35951227796932, 3.1054256253923427],
                [117.36711862512448, 3.1165687273649496],
                [117.3782961117762, 3.111172834658646],
                [117.38589480120413, 3.095971374679266],
                [117.4060290246199, 3.114889765050691],
                [117.42633053992256, 3.11309044556765],
                [117.44887537899558, 3.127872512106819],
                [117.46220421934936, 3.1269620222795425],
                [117.46045647958294, 3.141867119671872],
                [117.4442725102682, 3.1616515140062234],
                [117.42655361136019, 3.1726771825059927],
                [117.40400948275146, 3.1642806118608178],
                [117.36844475192379, 3.181437211458217],
                [117.3176514906238, 3.177713195307774],
                [117.30918596715867, 3.2022113357845683],
                [117.29352574861446, 3.203849449092843],
                [117.2662507696574, 3.1808053477886347],
                [117.23845700418747, 3.1887373070811122],
                [117.23216232722973, 3.1764348845622976],
                [117.21527855226338, 3.1839837326631937],
                [117.23796091296583, 3.193903231449042],
                [117.26792907456718, 3.1862873541780914],
                [117.27759570108083, 3.1951507691927077],
                [117.27442175975648, 3.2094638617678584],
                [117.26258821857152, 3.231794811481393],
                [117.22425062876357, 3.249644632793604],
                [117.26506820433417, 3.2521859352327738],
                [117.28648359564147, 3.236990297464331],
                [117.30923440104687, 3.236748367243422],
                [117.3159256223696, 3.2136857758778774],
                [117.33017074508393, 3.2114138087003425],
                [117.33973209066346, 3.221289277578137],
                [117.379139655171, 3.229498471777106],
                [117.39683304930588, 3.2568810204427905],
                [117.37981426091949, 3.267564899800334],
                [117.3688280483758, 3.252623643366519],
                [117.36074362791612, 3.252416991750806],
                [117.35923745414527, 3.2619725088242717],
                [117.35266117966978, 3.267176780284217],
                [117.3599088259316, 3.273678877778025],
                [117.36740100235443, 3.2942409356707003],
                [117.38371710276215, 3.320996298040768],
                [117.40912968198927, 3.326409205020866],
                [117.41523354709057, 3.336758867527749],
                [117.41234883762877, 3.3913389455359493],
                [117.39156912671388, 3.4071957001215196],
                [117.37024086802171, 3.4120718152620952],
                [117.36087866292007, 3.4431054513555637],
                [117.35504274092978, 3.4491453665654603],
                [117.32589127796018, 3.4512619641683386],
                [117.31142641129986, 3.466218059415951],
                [117.28952963718302, 3.4636222535642105],
                [117.25546408070284, 3.470618751554184],
                [117.2297545318462, 3.4862031212770717],
                [117.22114571939949, 3.4860688138247724],
                [117.20635326479135, 3.5178085912934876],
                [117.19625055881625, 3.527169390754807],
                [117.19436796301113, 3.541776245077699],
                [117.21226287633078, 3.576363515363994],
                [117.22431903839299, 3.587407714394544],
                [117.22609190611661, 3.603621242626275],
                [117.17637412876309, 3.619094075731084],
                [117.13456080133994, 3.6211529062816226],
                [117.10274883079398, 3.6098821365513913],
                [117.07486927359821, 3.6113047192374665],
                [117.02714051062617, 3.587460773495934],
                [117.01643494321809, 3.5956352943334196],
                [117.06594675334009, 3.631378211435333],
                [117.088257089693, 3.6380524760409685],
                [117.12499740980263, 3.636564914639166],
                [117.15005158761073, 3.6432363994403545],
                [117.16868258972977, 3.6442683103343256],
                [117.201519604846, 3.6333857185761076],
                [117.22236822181605, 3.6294988657800786],
                [117.25772799625008, 3.617608708674993],
                [117.3233373832137, 3.6242234174767987],
                [117.37039393713053, 3.638041405386616],
                [117.39946354470692, 3.6378891645533713],
                [117.42841260805551, 3.6247408963753287],
                [117.43240910259806, 3.6384834113803777],
                [117.4371970374134, 3.6417424456573713],
                [117.45706302363965, 3.63451099978289],
                [117.47524376039132, 3.647969495395955],
                [117.48883859502519, 3.6525066578560086],
                [117.48542709418507, 3.673463969586578],
                [117.46873423989337, 3.667512205024309],
                [117.45715550991895, 3.6751510671680876],
                [117.42872284898033, 3.671145711605334],
                [117.42344342027764, 3.691626183001972],
                [117.43459327705818, 3.690802047876275],
                [117.44315683472018, 3.701165084201307],
                [117.47309371430356, 3.712591573342479],
                [117.48101037073206, 3.726485379496694],
                [117.48351272533205, 3.744984314374676],
                [117.47729481720216, 3.7488596846267797],
                [117.47611227616073, 3.755111538566723],
                [117.48083473133784, 3.7645807414081105],
                [117.49313374182873, 3.774579371540824],
                [117.5001982861221, 3.773670664169856],
                [117.50706477451979, 3.768066850005198],
                [117.52050755268147, 3.7766621582320568],
                [117.53210466030191, 3.768151367391738],
                [117.55609878383189, 3.7852341982996336],
                [117.57964333357302, 3.782622391710049],
                [117.59119430597252, 3.795813320128673],
                [117.6006547323301, 3.7938075190018594],
                [117.59944169258006, 3.775706471176761],
                [117.63333902727436, 3.780485968552682],
                [117.6489409553609, 3.771654219566642],
                [117.65728735192977, 3.785054588398566],
                [117.67971827657732, 3.7938392668687015],
                [117.69506094630572, 3.7907241672865553],
                [117.68541700698938, 3.802148274123624],
                [117.69304650269396, 3.8083659808054335],
                [117.69340502462228, 3.8125541055875374],
                [117.69710545155544, 3.814821716449103],
                [117.70871707873027, 3.8198871235919114],
                [117.73150932759347, 3.816288726543519],
                [117.73438227662086, 3.835576351567397],
                [117.7259012812192, 3.8388159182201207],
                [117.70507841993322, 3.859039713212269],
                [117.66892531125416, 3.8787979453689445],
                [117.62428727803547, 3.873724477602593],
                [117.59807156465104, 3.8763186835604415],
                [117.57931666383308, 3.88823014560478],
                [117.55701842785822, 3.9156551332778236],
                [117.53325733216025, 3.924778914615274],
                [117.49948246174631, 3.9127494678137396],
                [117.4873763819146, 3.9146223923095818],
                [117.45756846691131, 3.929949820327977],
                [117.45539162732177, 3.9365840704984976],
                [117.46412132483931, 3.9583310517367067],
                [117.45669220707975, 3.9710419939928556],
                [117.45486178724173, 3.985074062817773],
                [117.43973067313209, 4.004402550337488],
                [117.42346375934505, 4.008211549723285],
                [117.41266121534284, 4.018774214858183],
                [117.415990699811, 4.0287077961279465],
                [117.43822656600332, 4.013427105876303],
                [117.45119362168839, 4.036945049174733],
                [117.46494398327832, 4.048155528354982],
                [117.47382426058118, 4.070673117070726],
                [117.468357083113, 4.0828682225904345],
                [117.43036689871349, 4.093806772864298],
                [117.41840123003283, 4.094335871003693],
                [117.41868054057454, 4.114013560777266],
                [117.40723966767655, 4.128033357453546],
                [117.39120974683237, 4.13568032698555],
                [117.40746384617967, 4.1458819332046914],
                [117.44368800040093, 4.1274670004017935],
                [117.48479881161597, 4.109652366863315],
                [117.52396113729674, 4.103228856626458],
                [117.53418463031653, 4.124513275167544],
                [117.54813194613246, 4.142347875454163],
                [117.58779380474039, 4.161929412412519],
                [117.58373012184768, 4.178782320847461],
                [117.56663407630163, 4.1794223800388295],
                [117.54959739230583, 4.168447917137712],
                [117.53802276323995, 4.171837495204272],
                [117.52874137931121, 4.161246658806306],
                [117.50902971315077, 4.1705766582862225],
                [117.48923684090755, 4.174841177778205],
                [117.46782351983961, 4.1735539844289065],
                [117.44157662156385, 4.194236728642181],
                [117.438923856251, 4.207869266453542],
                [117.42648154958385, 4.2156920930157185],
                [117.42974816424646, 4.2291752417440875],
                [117.40970490455777, 4.242161799227688],
                [117.40616304788637, 4.255078881385316],
                [117.38915804790565, 4.257492215693276],
                [117.3776266582438, 4.268478882394561],
                [117.37365943663372, 4.285156937442764],
                [117.34361082617875, 4.289718882520447],
                [117.3241186035907, 4.302985271253306],
                [117.29079999252565, 4.315319160151148],
                [117.28146610437727, 4.335731104107197],
                [117.24883721576498, 4.373868883442242],
                [117.2311097157409, 4.370117772517233],
                [117.21374777124277, 4.353846383703683],
                [117.20100249287077, 4.335061383476102],
                [117.17924666140436, 4.335559161825927],
                [117.15210277122594, 4.353490828638883],
                [117.1088258277498, 4.332325828374337],
                [117.08535888320591, 4.33378055103168],
                [117.04417805077651, 4.347096384490612],
                [117.03238749358798, 4.34088082898711],
                [117.02217082822119, 4.318810273475378],
                [117.00754916206427, 4.320896662850771],
                [117.01061082752778, 4.3440155517763515],
                [116.97553193924762, 4.343415829876676],
                [116.95653249477891, 4.355955273217319],
                [116.94063610617138, 4.356146940329097],
                [116.91245943995125, 4.368634996407991],
                [116.90224527380042, 4.3662302740079895],
                [116.8889688837071, 4.3491274961371005],
                [116.87407805136104, 4.339488330422171],
                [116.85114027330508, 4.3388524962457495],
                [116.84393916156307, 4.3258122195583155],
                [116.82215305172201, 4.33655305208174],
                [116.8170208285485, 4.35034055207484],
                [116.80761527252685, 4.350633608754094],
                [116.79812527376248, 4.338616386437138],
                [116.78597749596474, 4.342299163275811],
                [116.78445944035093, 4.358922774266659],
                [116.76992444006089, 4.376955275765397],
                [116.75241027451466, 4.389533053607579],
                [116.7419188851485, 4.382286941589143],
                [116.74231249592503, 4.3670758300855255],
                [116.73502971854668, 4.35316860874434],
                [116.70241721828006, 4.333903609763809],
                [116.67438638522356, 4.351276109604839],
                [116.65887166259722, 4.340376386660978],
                [116.64246916252193, 4.336342775298874],
                [116.61823138567001, 4.345589443597987],
                [116.62058471900968, 4.35946722023607],
                [116.61117527431952, 4.379656943513169],
                [116.59926999691288, 4.373411665416086],
                [116.58770610755084, 4.382271665704877],
                [116.56727166255723, 4.408174443795588],
                [116.56564083016019, 4.3911758325986625],
                [116.55012944132068, 4.387098887403795],
                [116.53780110753519, 4.374338331348952],
                [116.53086944167148, 4.3474752760631645],
                [116.54181305153122, 4.3342113874456345],
                [116.53047666366717, 4.325887220319032],
                [116.50902221930005, 4.324129721109784],
                [116.49854916441245, 4.333473332728147],
                [116.48767555238487, 4.329472220166849],
                [116.48009721911149, 4.302441109469669],
                [116.45427860767165, 4.295759443443615],
                [116.44160138459631, 4.288278054388741],
                [116.43837694184242, 4.323007221506089],
                [116.42022277556066, 4.33096111076668],
                [116.40819972024099, 4.345279999472439],
                [116.40544166410155, 4.358884443362399],
                [116.38693110818417, 4.359013609390615],
                [116.38062666368967, 4.375752776069817],
                [116.36790305358306, 4.377924721548709],
                [116.34922860791403, 4.3901499984237375],
                [116.32521610853667, 4.38395305439002],
                [116.30792055289123, 4.368587499613454],
                [116.29360055352277, 4.3724294447631],
                [116.28051583054116, 4.360032221110146],
                [116.26260499652346, 4.361909999247132],
                [116.25309360918277, 4.377096665378872],
                [116.22129833095323, 4.3819299997697385],
                [116.20833610912416, 4.378150833594134],
                [116.17813610859457, 4.380682499797558],
                [116.16793277567717, 4.390766943239896],
                [116.16172222040416, 4.374623611590437],
                [116.15637499810771, 4.338375555187781],
                [116.1413508313758, 4.338024167581295],
                [116.13460055337293, 4.329386666761309],
                [116.11723555387778, 4.33540805503776],
                [116.08876833109366, 4.304100000714527],
                [116.08413888739983, 4.281181110220132],
                [116.0757249994092, 4.276627499159815],
                [116.03571360939566, 4.295041944530169],
                [116.03269110940721, 4.328705000435491],
                [116.00604805437422, 4.33863527759604],
                [116.00424194321113, 4.3474691660692315],
                [115.98518194289238, 4.350595277849834],
                [115.97786999870152, 4.357803611147801],
                [115.94278888729741, 4.3570063891335735],
                [115.92913472079078, 4.353913055842895],
                [115.91728888767204, 4.3692125005565],
                [115.90953860852005, 4.390997222743692],
                [115.90159083105209, 4.3954936117408465],
                [115.87852638708318, 4.391395000079228],
                [115.87191277635361, 4.377130555430824],
                [115.87356055467592, 4.354423889650832],
                [115.8660794426122, 4.341526111440942],
                [115.86566499814126, 4.318427500428868],
                [115.87127166495543, 4.285576388439608],
                [115.83807777636832, 4.264693055839928],
                [115.82555666570761, 4.261682500646941],
                [115.8300074986472, 4.2344024998755],
                [115.8181374989465, 4.224518334553522],
                [115.8038411085804, 4.2214777788764195],
                [115.79728499870384, 4.237691388047153],
                [115.76771472055793, 4.24488333299945],
                [115.75300360962899, 4.211545556482292],
                [115.73333055406192, 4.208524722038362],
                [115.72917444154734, 4.189664722848704],
                [115.70368138738615, 4.192086667265926],
                [115.69028166606608, 4.174886389395397],
                [115.68197777595367, 4.153115833329082],
                [115.68526249936497, 4.132679722791011],
                [115.67636416457458, 4.123233334850568],
                [115.67840743505519, 4.089664226622801],
                [115.65478883513674, 4.035064800600139],
                [115.6473001462848, 3.978251003581022],
                [115.64088941451496, 3.9615351931946634],
                [115.61673484024072, 3.936570812692878],
                [115.59040099011361, 3.943082147123448],
                [115.58113354567661, 3.9400355587940794],
                [115.56774449571196, 3.920089267605647],
                [115.57720618471774, 3.893489456473276],
                [115.6052868348196, 3.874901375398224],
                [115.61748613657596, 3.8752571175219828],
                [115.61795725182583, 3.841943250380723],
                [115.6139560844058, 3.8240244942350614],
                [115.60229948483345, 3.7985495038565773],
                [115.58875070717943, 3.7771652830514313],
                [115.59325143540548, 3.7689970457770983],
                [115.5787407491863, 3.74780506315318],
                [115.58183233959085, 3.728204766336944],
                [115.57504304360168, 3.721463697391755],
                [115.5840084165552, 3.6976628282769752],
                [115.57236785998884, 3.680853250077462],
                [115.57265260693237, 3.646302542628291],
                [115.58071593469265, 3.596088642576831],
                [115.58935486273674, 3.5787639363066432],
                [115.60420719060937, 3.5748941625355997],
                [115.61278887680533, 3.5535792389907215],
                [115.61422077916563, 3.537400875150297],
                [115.61030221267708, 3.5106178954995357],
                [115.62360660406159, 3.494678875429827],
                [115.63839979611373, 3.4565028362369503],
                [115.65833853479558, 3.43884030683472],
                [115.64244537025775, 3.418661555286576],
                [115.62528602682869, 3.4079978522191254],
                [115.61738281796272, 3.4211948366724414],
                [115.61140452429947, 3.4457500643140406],
                [115.58787340053709, 3.449885986149411],
                [115.57938350454515, 3.432494420680996],
                [115.58024032103401, 3.4188249890820543],
                [115.564875393085, 3.4048021194222997],
                [115.55721878821657, 3.3804847813476044],
                [115.54436934586863, 3.357704839706628],
                [115.54961377115865, 3.3287691672633173],
                [115.53394208266104, 3.3190156347637867],
                [115.53174351814891, 3.2810127420444815],
                [115.52577122925891, 3.259802050824021],
                [115.52968375679995, 3.225047183780532],
                [115.51681001297175, 3.2217239316141786],
                [115.5193058852584, 3.1968951751722443],
                [115.53195069856599, 3.1820935582711627],
                [115.54377099363649, 3.1817187864928655],
                [115.56292409087416, 3.1701157451455515],
                [115.5630309267359, 3.151700622585736],
                [115.55356888429662, 3.142237863386754],
                [115.53626609090747, 3.142172136435022],
                [115.52790041826972, 3.1169184889971007],
                [115.51515606260227, 3.1086687065956085],
                [115.51180367420022, 3.093302242604466],
                [115.51673433771953, 3.0557795563814807],
                [115.49916516873759, 3.0304004391289823],
                [115.48680679219979, 3.0180646193867346],
                [115.46897454993564, 3.0251790832481333],
                [115.44100055762965, 3.0227447758507537],
                [115.4250075611501, 3.0122440677096165],
                [115.41905822835463, 2.999121064185829],
                [115.4041163891601, 2.9841710177782943],
                [115.39088922525764, 2.9796750478652143],
                [115.35139160264043, 2.9812710252376746],
                [115.33184404206895, 2.97589048935464],
                [115.3235999002153, 3.0105765034029446],
                [115.3089577106299, 3.0188700288288146],
                [115.3062695776789, 3.0327091562026567],
                [115.28582957757305, 3.04734976747784],
                [115.27930708885117, 3.027247319807998],
                [115.25303241681263, 2.994650458751721],
                [115.24982612777364, 2.9646043645997793],
                [115.2106502358198, 2.957121061787632],
                [115.19206371416908, 2.934089525811032],
                [115.1650963330294, 2.9274538646042174],
                [115.14938240337528, 2.908015014081684],
                [115.15329064204957, 2.8703106890870913],
                [115.1430354110554, 2.86842563363615],
                [115.13735146729641, 2.852833903861608],
                [115.11910749201718, 2.835822397285881],
                [115.10419881082657, 2.8315706032008165],
                [115.10557797154627, 2.816151331019171],
                [115.12122518589365, 2.804529422794701],
                [115.14334582465017, 2.8049320007112897],
                [115.15526305584547, 2.792696056078171],
                [115.14242062461221, 2.777483517350504],
                [115.14175033920685, 2.74721159713755],
                [115.13106259366384, 2.7361506473345685],
                [115.10762663616038, 2.7217792175368913],
                [115.09606343567907, 2.6975804757584],
                [115.10821858252166, 2.6899443835265515],
                [115.12056947490146, 2.654864742513894],
                [115.11435743574714, 2.6364818813337934],
                [115.08934280832398, 2.6161578946711757],
                [115.09371319420859, 2.605244350046746],
                [115.10833270289197, 2.600369133328911],
                [115.1149553500095, 2.5831871890375737],
                [115.13707468924554, 2.5855877062077184],
                [115.1406954191583, 2.5970517087694134],
                [115.1547115331108, 2.6084248474288074],
                [115.17103997491495, 2.6134110287960084],
                [115.19448415312127, 2.583984994711784],
                [115.22044161898555, 2.5604592830695196],
                [115.22477594655277, 2.542141622737745],
                [115.23898126707184, 2.535067722797237],
                [115.25251939675286, 2.541072694849049],
                [115.25056591098803, 2.509306200320964],
                [115.2271829442376, 2.485523892313836],
                [115.20570209397647, 2.471044097806896],
                [115.19044425639743, 2.482658497591615],
                [115.15061313908518, 2.477096978542761],
                [115.13796293074472, 2.469379364566066],
                [115.13240167159995, 2.457034881654522],
                [115.11940819383312, 2.456140592214581],
                [115.09979195563426, 2.4253846586751138],
                [115.09794540285816, 2.40244770799535],
                [115.06456486357763, 2.407549132986162],
                [115.05199858266849, 2.404098656427891],
                [115.03932029389648, 2.391304797578414],
                [115.02755401930256, 2.364700153489366],
                [115.01367677776113, 2.364281381480623],
                [114.99450828323074, 2.3476112170840224],
                [114.97027015564322, 2.367794103714971],
                [114.9629217169637, 2.35932068330294],
                [114.94918355287871, 2.3241147972601084],
                [114.95219226715949, 2.308314703560768],
                [114.96902265297308, 2.2942760507991693],
                [114.95473721095357, 2.2791782610679547],
                [114.94033725286204, 2.2792813944208774],
                [114.91838325578271, 2.254812456729553],
                [114.87197375598407, 2.263658075060164],
                [114.85753300554381, 2.2538975728147648],
                [114.8312202355255, 2.2456306394424814],
                [114.81388720782843, 2.2620263082675365],
                [114.80038657807813, 2.2487269315027447],
                [114.8027847471185, 2.2343820110210117],
                [114.79093064383426, 2.2230357755806267],
                [114.78929093063198, 2.200928489058583],
                [114.74239445334138, 2.1910987830823956],
                [114.74546749702745, 2.180680150668479],
                [114.73593983555497, 2.1500621947145646],
                [114.74166835331187, 2.132228658325971],
                [114.76009078286336, 2.141820819175507],
                [114.7924041002691, 2.1457686361137007],
                [114.81393636387213, 2.1346047148357457],
                [114.82358215309398, 2.118225197834647],
                [114.81608344219717, 2.0999153696985786],
                [114.80132349987969, 2.0851244610250887],
                [114.81122511654598, 2.0746166752194313],
                [114.81311720559461, 2.0622368831257063],
                [114.79898830316768, 2.0597449534668613],
                [114.80005655026969, 2.042530522065988],
                [114.80991747171402, 2.025700789160112],
                [114.82934082781696, 2.0270991863783934],
                [114.86029706735587, 2.0438854064863676],
                [114.87160255271567, 2.0321157927097033],
                [114.88228603906953, 2.03059725326068],
                [114.87134108652197, 1.9889208062502348],
                [114.85182551123842, 1.9721687540848052],
                [114.85218701621784, 1.945629012269137],
                [114.881289231413, 1.9204002068041746],
                [114.86951849258446, 1.9029931595067637],
                [114.85595606412107, 1.8952473760655835],
                [114.83248716894775, 1.8960857456606846],
                [114.81722998697433, 1.8897976553375884],
                [114.79058829261567, 1.8474886251999578],
                [114.76930248282338, 1.8521027894122426],
                [114.7473284569428, 1.8693627435957865],
                [114.7318504329512, 1.858272190809032],
                [114.7279308718123, 1.8336393633659895],
                [114.70619420040214, 1.8206806506915427],
                [114.69767774832951, 1.8032980955307494],
                [114.70948172879878, 1.7959088258317024],
                [114.71435933411601, 1.7713111510888098],
                [114.70526881240585, 1.7605019673919173],
                [114.71921791427542, 1.715850724031725],
                [114.71115032462785, 1.7079460384789513],
                [114.71365324040482, 1.6854042867784642],
                [114.7112395256836, 1.6585366854197332],
                [114.70507691596697, 1.6373121662235235],
                [114.6934817463856, 1.632992213419982],
                [114.68085246526914, 1.6103644757400275],
                [114.65784765804301, 1.6008653047900339],
                [114.65168340616435, 1.5893350230928718],
                [114.63939651223927, 1.5895603194532555],
                [114.6104767479037, 1.5752155725406851],
                [114.59957829660118, 1.5314965969903938],
                [114.615963311158, 1.5155246617338207],
                [114.60534964457054, 1.499773950707322],
                [114.60625753355839, 1.487662332850448],
                [114.59481679016278, 1.4823705690465658],
                [114.58958656426864, 1.463283702381034],
                [114.60400587487129, 1.4442305207219874],
                [114.6337998485842, 1.4323276094316952],
                [114.6464117817784, 1.4087940646944048],
                [114.63882155766885, 1.3862095853041865],
                [114.67243306893101, 1.3528382775645014],
                [114.68854371562009, 1.3590564204175166],
                [114.69984943210568, 1.35396884858352],
                [114.70408907455123, 1.3370102734054399],
                [114.7145468643464, 1.3324879866313495],
                [114.72558478341114, 1.310535346628967],
                [114.71592885882978, 1.278230747251257],
                [114.72731287029274, 1.264000733147384],
                [114.75776510097865, 1.2548935254159232],
                [114.75679669211377, 1.2477069584094238],
                [114.80956235102178, 1.1931352656800414],
                [114.84826798837707, 1.1680904408212314],
                [114.85852113384328, 1.1923601930685095],
                [114.88042781938839, 1.2107804813342113],
                [114.89608083436303, 1.2093574804633818],
                [114.91914849430759, 1.194321873455749],
                [114.93587968759755, 1.1781109433653683],
                [114.97432216353707, 1.1932353206535709],
                [114.99028364057756, 1.182889632250749],
                [114.98914503501544, 1.1572735346459808],
                [114.9993980923482, 1.153279244341718],
                [115.00679722131474, 1.1273422236647548],
                [115.01564552951766, 1.12078534666648],
                [115.0454526513189, 1.115960779766226],
                [115.06035755985351, 1.122818140045183],
                [115.09327377466502, 1.1174408597079832],
                [115.12290523458898, 1.1294028924295958],
                [115.14195932366397, 1.116026792702371],
                [115.17354149494406, 1.1160348596210952],
                [115.18979126405566, 1.087810033806079],
                [115.18553021324885, 1.0676532293131231],
                [115.20031823779652, 1.061927278221333],
                [115.2300077948239, 1.0581766358430968],
                [115.25240368638106, 1.046624899019946],
                [115.28796700257521, 1.0469506478541462],
                [115.29964545207599, 1.0648039990053917],
                [115.31663535333894, 1.0593745615868215],
                [115.34225068472142, 1.0607393016774722],
                [115.36452103811553, 1.065893949446263],
                [115.37147528595574, 1.0719765150042235],
                [115.37844163687214, 1.099530700181731],
                [115.39389609772684, 1.1050018463581637],
                [115.41088366794702, 1.1277153523816423],
                [115.41815044638611, 1.1439773855479416],
                [115.41542188261383, 1.160293143313936],
                [115.40633790796812, 1.1727658650526678],
                [115.40816449669421, 1.190328357467763],
                [115.41753466529258, 1.1924667645296267],
                [115.42632603112145, 1.2185179847172662],
                [115.4363746437748, 1.2285489625646164],
                [115.46389479590812, 1.2394197606134867],
                [115.4951683513384, 1.255495005490161],
                [115.53853974431377, 1.2624641883716663],
                [115.57184375938209, 1.2719278387987742],
                [115.59377598927051, 1.2832141820888978],
                [115.59955083020668, 1.2804067747529189],
                [115.6317890838992, 1.2851984804256062],
                [115.65271350922114, 1.2813765955534677],
                [115.66699654735282, 1.2736943950803266],
                [115.69142878281218, 1.2709070327334189],
                [115.70848347393326, 1.2626162295554195],
                [115.71810690870689, 1.2658182836868832],
                [115.76050752400306, 1.2599315320146616],
                [115.7721991269882, 1.2709043914245512],
                [115.77103914004431, 1.3067450234640887],
                [115.75845179071757, 1.3312668565802142],
                [115.7690106499216, 1.355388642471894],
                [115.79298371852383, 1.3615157541487974],
                [115.8124952990189, 1.3792724812400934],
                [115.82567156489085, 1.3665721087159],
                [115.83194739295789, 1.378023264462115],
                [115.85505790829586, 1.402264023465932],
                [115.84836567433649, 1.4227273888579361],
                [115.85249998818392, 1.43599288366471],
                [115.88268939919647, 1.4530952595386566],
                [115.91198651276738, 1.4785554920423465],
                [115.95020660525074, 1.4884599982303826],
                [115.96274087299298, 1.521757966257212],
                [115.97274677054713, 1.5312360247228298],
                [116.00947217899795, 1.5272086771387876],
                [116.04082283420655, 1.5598521815330173],
                [116.04577145988503, 1.579477487596023],
                [116.03623285456263, 1.605871908861559],
                [116.05488830565082, 1.6253433436204432],
                [116.0765520960025, 1.6540164589924302],
                [116.10293203998174, 1.6656819726449612],
                [116.1150839789425, 1.654798444692517],
                [116.12935223132047, 1.6626557953287602],
                [116.13078922923955, 1.6774952350171475],
                [116.14131207868957, 1.6867347923767966],
                [116.14238126018711, 1.7018892474908398],
                [116.16186433936787, 1.7443182516860247],
                [116.15738521735398, 1.7524250122045828],
                [116.16817346303492, 1.7786364744937373],
                [116.17519365816088, 1.8156614983794839],
                [116.18412555385135, 1.8467056817218577],
                [116.17563888732491, 1.8709498284718507],
                [116.17953744479735, 1.9106968852531168],
                [116.19527406884322, 1.9553962594299037],
                [116.19395935623936, 1.9810038944142434],
                [116.22937818735352, 2.0106005406824465],
                [116.23289382698863, 2.028139744253849],
                [116.22558387389677, 2.0525471979948406],
                [116.22083725933214, 2.091965842149932],
                [116.23401655771806, 2.103215939164329],
                [116.26551400117023, 2.105003361515969],
                [116.28619088630046, 2.1026293024004303],
                [116.29567416533052, 2.1188089028086665],
                [116.31601225336283, 2.1167748647682174],
                [116.32169022562346, 2.1297040814782235],
                [116.32261307753151, 2.251490208206576],
                [116.31008545308123, 2.263509384750364],
                [116.32010440879151, 2.2796586825022587],
                [116.33037401994511, 2.283390644162921],
                [116.36006854212962, 2.3239994061477205],
                [116.37499767660154, 2.355547093823361],
                [116.40186939775458, 2.392706606824504],
                [116.41877404868342, 2.4278178671009414],
                [116.42753046505902, 2.4360032130778677],
                [116.44287323011554, 2.4667458492415903],
                [116.44138844582017, 2.484506146641422],
                [116.42402789886853, 2.4986686981019943],
                [116.43710399735164, 2.510082318844013],
                [116.4490393741678, 2.509853889245619],
                [116.49156800705794, 2.494402622782843],
                [116.50392498514998, 2.4799414640273767],
                [116.5170522667488, 2.4795814051577736],
                [116.52750195365229, 2.467172419271776],
                [116.55260943068072, 2.4546189348160397],
                [116.56450692715305, 2.4544998636778246],
                [116.56898031329092, 2.433382147068471],
                [116.58116315385655, 2.4280521539837423],
                [116.64613195732409, 2.4305458292266735],
                [116.69256000024393, 2.4397971731011125],
                [116.70797890820006, 2.4377413193064967],
                [116.72694190224706, 2.4436226624559367],
                [116.73658751969833, 2.4613722075613964],
                [116.7499873309506, 2.4646609788211435],
                [116.75409447178254, 2.475823604176014],
                [116.77653924688877, 2.482268965240621],
                [116.84862549457102, 2.519400410400692],
                [116.91652673002466, 2.533530129411986],
                [116.95298612980616, 2.5508460924732503],
                [117.00087549730642, 2.5827800443636306],
                [117.07413251660239, 2.6080991120844033],
                [117.14461192759848, 2.604940766802997],
                [117.2009314311407, 2.5962774194673557],
                [117.21796788760798, 2.596475878259241],
                [117.24202064513668, 2.6037179855966315],
                [117.28240369977095, 2.6217492136553346],
                [117.31459780664352, 2.626341573804609],
                [117.33544188563462, 2.621915776192168],
                [117.37446647384877, 2.6039748544559984],
                [117.40273447071934, 2.6096855602404503],
                [117.42794724037287, 2.5913456272988924],
                [117.45168446176854, 2.5576197015880098],
                [117.47031859532694, 2.544730598238573],
                [117.48673832371469, 2.539727329752509],
                [117.49855600895273, 2.52293414979124],
                [117.5407941239497, 2.5198995979881147],
                [117.57448505703985, 2.504078438569138],
                [117.59039793561635, 2.5202241804016694],
                [117.61178313308517, 2.521811781488566],
                [117.6223814959094, 2.5102690531743406],
                [117.63791506922507, 2.478019859229164],
                [117.66725055057248, 2.448952677124396],
                [117.68108063166585, 2.4157958280151206],
                [117.69970757424517, 2.403711459616204],
                [117.70835577789694, 2.3899416690728117],
                [117.72082870917768, 2.346972762283201],
                [117.74173198514302, 2.336141771825339],
                [117.79783086700093, 2.325750083999253],
                [117.80901027460459, 2.3267096480318514],
                [117.81899427186818, 2.3391406223419153],
                [117.82104078440477, 2.363132129744031],
                [117.83017831727557, 2.3663867762288078],
                [117.85696421072976, 2.39190943966895],
                [117.89868407341135, 2.404681319087672],
                [117.91935357240948, 2.395732575498016],
                [117.95187568091796, 2.414129836622692],
                [117.95837634399311, 2.4277689214985685],
                [117.97195792351943, 2.425344374443398],
                [117.98593392680937, 2.435721765746962]
              ]
            ]
          ]
        },
        "id": 65,
        "properties": {
          "ID": 33,
          "kode": 65,
          "Propinsi": "KALIMANTAN UTARA",
          "SUMBER": "Batas Provinsi Desember 2019 Dukcapil (Skala tidak diketahui)"
        }
      }
    ]
  }