import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import { month_source } from '../../components/utility/utility';
import Swal from 'sweetalert2/dist/sweetalert2.js';

function EditorContribution({
    onSave = (data) => { },
    mode = 'Add',
    id_edit_p = '',
    id_product_p = '',
    year_p = '',
    predic = 0
}) {
    const [state, dispatch, action] = useGlobalState();
    const [month, setMonth] = useState('');
    const [customer, setCustomer] = useState('');
    const [product_contribution_status_id, setProduct_contribution_status_id] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);
    const [notes, setNotes] = useState('');
    const [id_edit, setId_edit] = useState('');
    const [id_product, setId_product] = useState('');
    const [year, setYear] = useState('');

    useEffect(() => {
        if (state.modal.modalAddContribution === true) {
            resetData();
            setDataEdit(id_edit_p);
        }
    }, [state.modal.modalAddContribution]);
    function resetData() {
        setMonth('');
        setCustomer('');
        setProduct_contribution_status_id('');
        setQuantity(0);
        setPrice(0);
        setNotes('');
        setId_edit(id_edit_p);
        setId_product(id_product_p);
        setYear(year_p);
    }
    function setDataEdit(id) {
        action.getData(`/api/ext-product/ContributionEdit/${id}`).then((resp) => {
            if (resp.code === '00') {
                if (mode === 'Edit') {
                    setId_edit(resp.data.Contribution?.id);
                    setId_product(resp.data.idProduct);
                    setMonth(resp.data.Contribution?.month?.toString());
                    setProduct_contribution_status_id(resp.data.Contribution?.product_contribution_status_id);
                    setCustomer(resp.data?.Contribution?.product_location_id || '');
                    setYear(resp.data?.Contribution?.year);
                    setQuantity(resp.data?.Contribution?.quantity);
                    setPrice(resp.data?.Contribution?.price);
                    setNotes(resp.data?.Contribution?.notes);
                }else{
                    setPrice(predic)
                }
            }
        })
    }
    function saveClick() {
        const data = {
            "id": id_edit,
            "product_id": id_product,
            "product_contribution_status_id": product_contribution_status_id,
            "product_location_id": customer || '',
            "year": year,
            "month": month || '',
            "quantity": quantity || '',
            "price": price || '',
            "notes": notes,
            "created_at": "",
            "updated_at": ""
        }
        const val = validation(data);
        if (val.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Save product contribution ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.toggleModal('modalAddContribution');
                onSave(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function validation(data) {
        var isValid = true;
        var message = '';
        if (data?.month === '' ||
            data?.product_location_id === '' ||
            data?.quantity === '' ||
            data?.price === ''
        ) {
            isValid = false;
            message = 'Lengkapi Semua data';
        }
        return {
            isValid,
            message
        }
    }
    return (
        <div>
            <h5>{mode} Product Contribution</h5>
            <hr />
            <div className="row mb-2">
                <div className="col-md-12">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend mr-3">
                            <span className="text-wrap" style={{ width: '140px' }}>Implementation Time</span>
                        </div>
                        <select className="form-control form-control-sm" placeholder='Bulan' value={month} onChange={(e) => setMonth(e.target.value)}>
                            <option value='' > - Pilih Bulan - </option>
                            {
                                month_source.map((d, i) => (
                                    <option value={d.value} key={i}>{d.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend mr-3">
                            <span className="text-wrap" style={{ width: '140px' }}>Customer</span>
                        </div>
                        <select className="form-control form-control-sm" placeholder='- Pilih -' value={customer} onChange={(e) => setCustomer(e.target.value)}>
                            <option value='' > - Pilih - </option>
                            {
                                [...(state.data_Product_Location || [])].map((d, i) => (
                                    <option value={d.id} key={i}>{d.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend mr-3">
                            <span className="text-wrap" style={{ width: '140px' }}>Contribution Status</span>
                        </div>
                        <select className="form-control form-control-sm" placeholder='- Pilih -' value={product_contribution_status_id} onChange={(e) => setProduct_contribution_status_id(e.target.value)}>
                            <option value='' > - Pilih - </option>
                            {
                                [...(state.data_Product_Status || [])].map((d, i) => (
                                    <option value={d.id}  key={i}>{d.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend mr-3">
                            <span className="text-wrap" style={{ width: '140px' }} >Product QTY</span>
                        </div>
                        <input type='number' className="form-control form-control-sm" placeholder='Product QTY' value={quantity} onChange={(e) => setQuantity(e.target.value)}></input>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend mr-3">
                            <span className="text-wrap" style={{ width: '140px' }}>Predicted / Actual Price per Quantity (USD)</span>
                        </div>
                        <input type='number' className="form-control form-control-sm" placeholder='Predicted / Actual Price per Quantity (USD)' value={price} onChange={(e) => setPrice(e.target.value)}></input>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend mr-3">
                            <span className="text-wrap" style={{ width: '140px' }}>Notes</span>
                        </div>
                        <textarea rows={4} className="form-control form-control-sm" placeholder='Note' value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-md-right">
                    <button className="btn btn-sm btn-secondary mx-1"
                        onClick={() => action.toggleModal('modalAddContribution')}
                    >Kembali</button>
                    <button className="btn btn-sm btn-primary mx-1"
                        onClick={() => saveClick()}
                    ><i className="fa fa-save icon-nm"></i> Save</button>
                </div>
            </div>
        </div>
    );
}

export default EditorContribution;