import React, { useRef } from 'react';
import { FormResearch, Modal, RadioGrp } from '../../../components';
import { useGlobalState } from '../../../contexts/useGlobalState';
import { Accordion } from 'devextreme-react';
import Tags from "@yaireo/tagify/dist/react.tagify"

const formatter = new Intl.NumberFormat('en-US', {
    valuta: 'USD',
    minimumFractionDigits: 2
})
function NotOperational(props) {
    const [state, dispatch, action] = useGlobalState();
    const tagref = useRef();

    function renderTitle(data) {
        return (
            <div className="m-2" style={{ fontSize: "10px", fontFamily: 'Poppins' }}><i className="fa flaticon-user-ok p-2"></i>{data?.name}</div>
        )
    }
    function renderItem(data) {
        return (
            <div className="row h-100 p-5 border-top" style={{ fontFamily: 'Poppins' }}>
                <div className="col-md-4 mb-5">
                    <img src={data?.image || "/media/svg/icons/General/User.svg"}
                        style={{ width: '100%' }}
                    ></img>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>Nama</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>Posisi</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.position_name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>No. Pegawai</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.employee_id}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>Expertise</div>
                        <div className="col-auto"></div>
                    </div>
                    {
                        data.technical_competency?.split(',').map((s, i) => (
                            <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                <div className="col-4"></div>
                                <div className="col-auto" style={{ fontSize: '8pt' }}>{i + 1}.&nbsp;{s}</div>
                            </div>
                        ))
                    }
                    <div className="d-flex flex-row-reverse" style={{ paddingBottom: 10 }}>
                    </div>
                </div>
            </div>
        )
    }
    function getFungsi() {
        let newDs = [
            ...([...(state.formData.anggota || [])].map(d => {
                return {
                    user_id: d.user_id,
                    section_name: d.section_name,
                    function_short_name: d.function_short_name
                }
            }))
        ]
        if (state.formData.leader) {
            newDs = [
                { user_id: state.formData.leader?.user_id, section_name: state.formData.leader?.section_name, function_short_name: state.formData.leader?.function_short_name },
                ...newDs
            ]
        }
        return newDs
    }
    return (
        <>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: true,
                        name: <>Project Type & Initiator <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "text",
                                label: "Project type",
                                value: state.data_view_header?.project_type_name,
                                disabled: true,
                            },
                            {
                                type: "custom",
                                label: "Nama",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_name}</div>)
                            },
                            {
                                label: "Jabatan",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_position_name}</div>)
                            },
                            {
                                label: "Fungsi",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_function_name}</div>)
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Latar Belakang <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "array",
                                label: "Judul",
                                forms: [
                                    {
                                        type: "custom",
                                        component: (<div className='form-group style-view-disable'>{state.formData.title}</div>)
                                    },
                                ]
                            },
                            {
                                label: "Pain Point",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.formData.description}</div>)
                            },
                            {
                                label: "Commercialization /Implementation- Objective",
                                component: (<div className='form-group style-view-disable'>{state.formData.purpose}</div>),
                                type: "custom",
                            },
                            {
                                label: <> Dukungan Operational</>,
                                type: "custom",
                                component: (
                                    <>
                                        <div className="row mb-5">
                                            <div className="col-md-12">
                                                <ul>
                                                    {
                                                        state.formData.dukungan_operational?.map((duk, key) => (
                                                            <li key={key}>{duk?.name}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )

                            },
                            {
                                label: "Detail Dukungan dan Operational",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.formData.duk_operasional_ket}</div>),
                            },
                            {
                                label: "Pemakai Potensial",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.formData.potential_user}</div>),
                            },
                            {
                                label: <> Keyword <i title="Kata kunci terkait riset yang dijalankan, dipisah dengan tanda koma (,)" className="fa fa-exclamation-circle"></i></>,
                                type: "custom",
                                component: (
                                    <Tags
                                        readOnly={true}
                                        tagifyRef={tagref}
                                        value={[...(state.data_view_keywords || '')].map(d => d?.name)}
                                    />
                                )
                            },
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Susunan tim <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: 'bold' }}>Leader</span>
                                        <div className='rounded border p-5'>
                                            <div className="row">
                                                {
                                                    state.formData.leader && (
                                                        <>
                                                            <div className="col-md-4 mb-5">
                                                                <img src={state.formData.leader?.image || "/media/svg/icons/General/User.svg"}
                                                                    style={{ width: '100%' }}
                                                                ></img>
                                                            </div>
                                                            <div className="col-md-8 mb-5">
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Nama</div>
                                                                    <div className="col-auto">: {state.formData.leader.name}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Posisi</div>
                                                                    <div className="col-auto">: {state.formData.leader.position_name}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">No. Pegawai</div>
                                                                    <div className="col-auto">: {state.formData.leader.employee_id}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Expertise</div>
                                                                    <div className="col-auto"></div>
                                                                </div>
                                                                {
                                                                    state.formData.leader.technical_competency?.split(',').map((ex, i) => (
                                                                        <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                                                            <div className="col-3"></div>
                                                                            <div className="col-auto">{i + 1}.&nbsp;{ex}</div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: "bold" }}>Anggota</span>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    state.formData.anggota?.length > 0 ? (
                                                        <Accordion
                                                            className="accordion-cstm py-5"
                                                            multiple={true}
                                                            itemRender={renderItem}
                                                            dataSource={[...state.formData.anggota]} collapsible={true}
                                                            itemTitleRender={renderTitle}
                                                        >
                                                        </Accordion>
                                                    ) : (
                                                        <div className="mb-5"></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: "Fungsi",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="row mb-5">
                                        <div className="col-md-12">
                                            <ul>
                                                {
                                                    getFungsi()?.map((d, i) => (
                                                        <li key={i}>{d.section_name} ({d.function_short_name})</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Keahlian Peneliti yang Dibutuhkan <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <ul style={{ paddingBottom: '25px' }}>
                                        {
                                            state.formData.keahlian?.map((kh, key) => (
                                                <li key={key}>{kh.name}</li>
                                            ))
                                        }
                                    </ul>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Klasifikasi Research <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Area Research",
                                component: (
                                    <div className='form-group style-view-disable'>{state.data_view_detail?.area_name}</div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Kategory Research",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.research_category_name}</div>)
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Ruang Lingkup Riset <span className="text-danger"></span></>,
                        childs: [
                            {
                                type: "array",
                                withLabel: false,
                                forms: [
                                    {
                                        type: "custom",
                                        component: (
                                            <>{
                                                state.formData.focus ?
                                                    <div className='form-group style-view-disable'>{state.formData.focus}</div>
                                                    :
                                                    <div className='text-muted text-center mb-5'>tidak ada data</div>
                                            }
                                            </>
                                        ),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: "Dokumen Pendukung ",
                        childs: [
                            {
                                type: "custom",
                                label: "Sumber File",
                                disabled: false,
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        [...(state.formData.sumberFileTemp || [])].length < 1 && <div className='text-muted'>tidak ada data</div>
                                                    }
                                                    {
                                                        [...(state.formData.sumberFileTemp || [])]?.map((file, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                {
                                                                    file.tipe_document === "file" ? (
                                                                        <>
                                                                            <span style={{ fontSize: '8pt', width: '250px' }}>{file.file_name}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span>{file.link}</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: "Proyek berkaitan",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        [...(state.formData.realated_document || [])].length < 1 && <div className='text-muted text-center'>tidak ada data</div>
                                                    }
                                                    {
                                                        [...(state.formData.realated_document || [])]?.map((d, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                <span className="pl-2 text-wrap" style={{ fontSize: '8pt' }}>{d.title}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: <>Metode Riset <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="d-inline-flex">
                                        <RadioGrp
                                            disable={true}
                                            value={state.formData.method?.toString()}
                                            data={[
                                                {
                                                    label: (
                                                        <span>
                                                            Inhouse
                                                            <i title="Dikerjakan dengan SDM dan Anggaran Pertamina" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '1'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Procurement
                                                            <i title="Dikerjakan oleh pihak ketiga dengan Anggaran Pertamina" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '2'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Partnership
                                                            <i title="SDM dan anggaran berasal dari Pertamina dan pihak ketiga" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '3'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Swakelola
                                                            <i title="Kerjasama yang dilaksanakan melalui metode swakelola" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '4'
                                                }
                                            ]}
                                        />
                                    </div>
                                )
                            }
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        childs: [
                            ...(state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ?
                                [{
                                    type: "custom",
                                    label: "Potensial Partner",
                                    component: (<div className='form-group style-view-disable'>{state.data_view_detail?.potensial_partner_name}</div>),
                                },
                                {
                                    type: "custom",
                                    label: "Detail Partner",
                                    component: (<div className='form-group style-view-disable'>{state.data_view_detail?.potensial_partner_ket}</div>),
                                }] : []
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: <span>
                            Value Creation
                            <i title="Nilai yang dapat dihasilkan setelah riset dilaksanakan, dapat berupa pendapatan, efisiensi, produksi minyak, atau intangible asset" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                            <span className="text-danger">(*)</span>
                        </span>,
                        childs: [
                            {
                                type: "custom",
                                label: "Pendapatan (Revenus)",
                                withLabel: false,
                                component: (
                                    <div className="border bg-light-secondary rounded p-3 mb-5">
                                        {
                                            (state.formData.pendapatan || state.formData.sat_pendapatan_name || state.formData.ket_pendapatan) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Pendapatan (Revenus)
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.pendapatan} {state.formData.sat_pendapatan_name} {state.formData.ket_pendapatan}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (state.formData.efisiensi || state.formData.sat_efisiensi_name || state.formData.ket_efisiensi) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Efisiensi
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.efisiensi} {state.formData.sat_efisiensi_name} {state.formData.ket_efisiensi}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (state.formData.produksi || state.formData.sat_produksi_name || state.formData.ket_produksi) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <span>
                                                            Production
                                                            <i title="Penambahan Produksi Migas, Cadangan Migas, Penjualan, Kapasitas listrik" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.produksi} {state.formData.sat_produksi_name} {state.formData.ket_produksi}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Tangible value
                                </span>,
                                component: (<div className='form-group style-view-disable'>{formatter.format(state.formData.tangible_angka || 0)}</div>),
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Justifikasi Perhitungan Tangible Value
                                </span>,
                                component: (<div className='form-group style-view-disable'>{state.formData.tangible_keterangan}</div>),
                            }
                            , {
                                type: "custom",
                                label: "Intangible Value",
                                component: (<div className='form-group style-view-disable'>{state.formData.intangible}</div>),
                            }
                        ]
                    },
                    ...(state.data_view_header?.project_type?.toString() === '1' ? [
                        {
                            rows: 2,
                            header: true,
                            name: <>Tingkat Kesiapan Riset <span className="text-danger">(*)</span><span role={'button'} className="badge badge-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('viewTableTrl')}>Lihat Table TRL</span></>,
                            childs: [
                                {
                                    type: "custom",
                                    withLabel: false,
                                    component: (
                                        <div className="d-inline-flex">
                                            <RadioGrp
                                                disable={true}
                                                value={state.formData.stage}
                                                data={[
                                                    { label: 'TRL I', value: 'I' },
                                                    { label: 'TRL II', value: 'II' },
                                                    { label: 'TRL III', value: 'III' },
                                                    { label: 'TRL IV', value: 'IV' },
                                                    { label: 'TRL V', value: 'V' },
                                                    { label: 'TRL VI', value: 'VI' },
                                                    { label: 'TRL VII', value: 'VII' },
                                                    { label: 'TRL VIII', value: 'VIII' },
                                                    { label: 'TRL IX', value: 'IX' }
                                                ]}
                                            />
                                        </div>
                                    )
                                }
                            ]
                        }
                    ] : []),
                    ...((state.data_view_header?.project_type?.toString() === '2' || state.data_view_header?.project_type?.toString() === '4') ? [
                        {
                            rows: 2,
                            header: true,
                            name: <>Tingkat Kesiapan Riset <span className="text-danger">(*)</span> <button className="btn mb-1 btn-sm btn-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('viewTableTrl')}>Lihat Table TRL</button></>,
                            childs: [
                                {
                                    type: "custom",
                                    withLabel: false,
                                    component: (
                                        <div className="d-inline-flex">
                                            <RadioGrp
                                                disable={true}
                                                value={state.formData.stage}
                                                data={[
                                                    { label: 'TRL I', value: 'I', disabled: true },
                                                    { label: 'TRL II', value: 'II', disabled: true },
                                                    { label: 'TRL III', value: 'III', disabled: true },
                                                    { label: 'TRL IV', value: 'IV', disabled: true },
                                                    { label: 'TRL V', value: 'V', disabled: true },
                                                    { label: 'TRL VI', value: 'VI', disabled: true },
                                                    { label: 'TRL VII', value: 'VII' },
                                                    { label: 'TRL VIII', value: 'VIII' },
                                                    { label: 'TRL IX', value: 'IX' }
                                                ]}
                                            />
                                        </div>
                                    )
                                }
                            ]
                        }
                    ] : [])
                ]}
            />
        </>
    );
}

export default NotOperational;