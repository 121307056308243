import React, { memo, useEffect, useState } from 'react'
import { FormResearch } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import moment from 'moment';
import { useHistory } from 'react-router';
import { toInteger, toUpper } from 'lodash';

const filterType = [
    { text: 'Ya', value: 1 },
    { text: 'Tidak', value: 0 },
]
const column = [
    { fieldName: "date", caption: "Tanggal", width: 100 },
    { fieldName: "user_name", caption: "Create By", width: 100 },
    { fieldName: "type", caption: "Type", width: 100, headerFilterSource: filterType },
    { fieldName: "description", caption: "Deskripsi", minWidth: 200 },
    { fieldName: "way_forward", caption: "Way Forward", width: 200 },
    { fieldName: "uploaded_file", caption: "File Download", width: 100, allowSorting: false, allowFiltering: false },
]

const TabAchievement = memo(({ param }) => {
    const [state, , action] = useGlobalState();

    useEffect(() => {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-achieve-con-4/" + param?.id, {}, null, null, true).then((resp) => {
                if (resp.code === "00") {
                    const achievement = resp.data.data_view_achieve_con.find(d => d.type === "Achievement");
                    const way = resp.data.data_view_achieve_con.find(d => d.type === "Way Forward");
                    const concern = resp.data.data_view_achieve_con.find(d => d.type === "Concern");
                    const ac = (achievement?.from_logbook_research === "" ? [] : achievement?.from_logbook_research.split(','))
                    const conc = (concern?.from_logbook_research === "" ? [] : concern?.from_logbook_research.split(','))
                    getDataFromLogbook(ac, conc)

                    action.setForm('descriptionAchievement', achievement?.description)
                    action.setForm('fileAchievement', [...(achievement?.file_location ? achievement?.file_location.split('?') : [])].map(d => {
                        return {
                            "file_location": '',
                            "file_name": d,
                            "is_new": false,
                        }
                    }))
                    action.setForm('descriptionWayforward', way?.description)

                    action.setForm('descriptionConcern', concern?.description)
                    action.setForm('management_support', concern?.management_review)
                    action.setForm('fileConcern', [...(concern?.file_location ? concern?.file_location.split('?') : [])].map(d => {
                        return {
                            "file_location": '',
                            "file_name": d,
                            "is_new": false,
                        }
                    }))
                    action.setForm('achievement', achievement)
                    action.setForm('way', way)
                    action.setForm('concern', concern);
                    const mont_year = moment(resp.data.data_cut_off_date + "/" + resp.data.data_year_month, "D/M/YYYY");
                    // setDate({
                    //     month: mont_year.format("M"),
                    //     year: mont_year.format("YYYY")
                    // })
                    action.setForm('mon', resp.data_page);
                }
            })
        }
    }, [])

    function getDataFromLogbook(from_logbook_research_achievement, from_logbook_research_concern) {
        action.getData("/api/project-ongoing-crud/view-logbook-4/" + param?.id, {}, null, null, true).then((resp) => {
            if (resp.code === "00") {
                const ac = [...(from_logbook_research_achievement || [])].map(d => {
                    return [...(resp.data.data_view_logbook || [])].find(s => s?.id?.toString() === d)
                })
                const con = [...(from_logbook_research_concern || [])].map(d => {
                    return [...(resp.data.data_view_logbook || [])].find(s => s?.id?.toString() === d)
                })
                action.setForm('from_logbook_research_achievement', ac)
                action.setForm('from_logbook_research_concern', con)
            }
        })
    }
    return (
        <>            
            <span className='d-block text-center mb-5 px-2 py-1 bg-primary rounded text-white'>Pengisian realisasi bulan {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).format('MMM'))} dapat dilakukan s.d. tanggal {state.data_cut_off_date} {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).add(1, 'month').format('MMM'))} {state.data_year_month?.split('/')?.[1]}</span>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: true,
                        name: <>Achievement <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Description",
                                component: (                                    
                                        <div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.descriptionAchievement }}></div>
                                )
                            },
                            {
                                label: "Attachment",
                                type: "custom",
                                component: (<></>)
                            }, {
                                label: "",
                                type: "custom",
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    [...(state.formData.fileAchievement || [])].map((d, i) => (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span className="d-block pl-2" style={{ fontSize: '8pt' }}>{d.file_name}</span>                                                            
                                                        </div>
                                                    ))
                                                }
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span><strong>Additional Data From Log Book</strong></span>
                                                <hr />
                                                {
                                                    [...(state.formData.from_logbook_research_achievement || [])].map((d, i) => (
                                                        <div className="d-block mb-5" dangerouslySetInnerHTML={{ __html: d?.description }}></div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Way Forward <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Description",
                                component: (<div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.descriptionWayforward }}></div>)
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Concern <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Description",
                                component: (<div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.descriptionConcern }}></div>)
                            },
                            {
                                type: "custom",
                                label: "Need Management Support",
                                component: (<div className="mb-5" dangerouslySetInnerHTML={{ __html: state.formData.concern?.management_review === "0" ? "Tidak" : "Ya" }}></div>)
                            },
                            {
                                label: "Attachment",
                                type: "custom",
                                component: (<></>)
                            }, {
                                label: "",
                                type: "custom",
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    [...(state.formData.fileConcern || [])].map((d, i) => (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span className="d-block pl-2" style={{ fontSize: '8pt' }}>{d.file_name}</span>
                                                        </div>
                                                    ))
                                                }
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span><strong>Additional Data From Log Book</strong></span>
                                                <hr />
                                                {
                                                    [...(state.formData.from_logbook_research_concern || [])].map((d, i) => (
                                                        <div className="d-block mb-5" dangerouslySetInnerHTML={{ __html: d?.description }}></div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    }
                ]}
            />            
        </>
    )
})
export default TabAchievement