import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import VectorMap, {
    Label,
    Layer,
    Legend,
    Source,
    Tooltip,
} from 'devextreme-react/vector-map';
import { mapindonesia } from './indonesiageojson.js';
import { useResizeDetector } from 'react-resize-detector';
import { Overlay, Popover } from 'react-bootstrap';
import { toLower } from 'lodash';
import { belumtersediaAlert } from '../../../components/utility/utility.js';

const MapIndonesia = memo(function ({
    dataPartnership = [],
    nameField = 'Propinsi',
    valueField = 'total'
}) {
    const refMap = useRef();
    const refTarget = useRef();
    const [sw, setSw] = useState(false);
    const onResize = useCallback(() => {
        if (refMap.current) {
            refMap.current.instance.render()
        }
    }, []);
    const { width, ref } = useResizeDetector({ onResize });
    const parentRef = ref;
    const palette = ['#e5e7eb', '#bae6fd', '#7dd3fc', '#38bdf8', '#0284c7', '#0369a1', '#075985', '#0c4a6e'];
    const [colorGroups, setColorGroups] = useState([0, 1, 2, 5, 10, 15, 20, 10000]);
    const bounds = [103, -19, 135, 11];
    const [ds, setDs] = useState((dataPartnership || [])?.reduce((prev, curr) => ({ ...prev, [curr?.province]: { ...curr } }), {}))


    function customizeItems(items) {
        return items.reverse();
    }
    const customizeLayer = (elements) => {
        elements.forEach((element) => {
            const province = (dataPartnership || []).find((d) => toLower(d[nameField]) === toLower(element.attribute('Propinsi')));
            element.attribute(valueField, (province && province[valueField]) || 0);
        });
    };
    function customizeText(arg) {
        let text;
        if (arg.index === 0) {
            text = '< 1';
        } else if (arg.index === 1) {
            text = `${arg.start} to ${arg.end}`;
        } else if (arg.index === 6) {
            text = '> 20';
        } else {
            text = `${arg.start + 1} to ${arg.end}`;
        }
        return text;
    }
    const TooltipTemplate = (info) => {
        const name = toLower(info.attribute('Propinsi'));
        const province = (dataPartnership || []).find((d) => toLower(d[nameField]) === toLower(name));
        return (
            <div>
                {province ? <div>
                    <div id='target' ref={refTarget} style={{ color: 'transparent' }}>Test</div>
                    <Overlay target={refTarget} show={sw} placement={'bottom-end'} flip={false}>
                        <Popover
                        >
                            <Popover.Content
                                className='p-1'
                            >
                                <span><b className='text-capitalize'>{province[nameField] || ''}</b></span>
                                <span className='d-block'>Partnership : {province[valueField]}</span>
                                <div role='button' className='d-inline-block bg-white border border-primary rounded p-1 text-primary'
                                    onClick={() => window.open(`/kerjasama/map-detail?country=Indonesia&province=${province[nameField]}`, '_blank')}
                                >Lihat detail <i className='fas fa-angle-double-right icon-sm text-primary'></i></div>
                            </Popover.Content>
                        </Popover>
                    </Overlay>
                </div> : <div className='p-1 bg-white customShadow rounded'>
                    <span><b className='text-capitalize'>{name || ''}</b></span>
                    <span className='d-block'>Tidak ada partnership</span>
                </div>
                }
            </div>
        )
    }
    const UpdateLayer = useCallback(() => {
        if (refMap.current) {
            const elements = refMap.current.instance?.getLayers()[0].getElements();
            customizeLayer(elements);
            setColorGroups([0, 1, 2, 5, 10, 15, 20, 10000]);
        }
    }, [customizeLayer, refMap]);
    useEffect(() => {
        if (refMap.current) { UpdateLayer() }
    }, [dataPartnership])
    return (
        <div ref={parentRef} onMouseLeave={() => setSw(false)}>
            <VectorMap
                height={300}
                className='border-none'
                ref={refMap}
                id="vector-map"
                bounds={bounds}
                width="100%"
                zoomingEnabled={true}
                onClick={() => setSw(false)}
                onTooltipShown={(e) => setSw(true)}
            >
                <Layer
                    dataSource={mapindonesia}
                    name="areas"
                    palette={palette}
                    colorGroups={colorGroups}
                    colorGroupingField={valueField}
                    customize={customizeLayer}>
                    <Label enabled={true} dataField={nameField}></Label>
                </Layer>
                <Legend
                    customizeText={customizeText}
                    customizeItems={customizeItems}>
                    <Source layer="areas" grouping="color"></Source>
                </Legend>
                <Tooltip enabled={true}
                    opacity={0}
                    arrowLength={1}
                    paddingLeftRight={0}
                    paddingTopBottom={0}
                    contentRender={TooltipTemplate} />
            </VectorMap>
            <button className='btn btn-secondary btn-sm mt-3' onClick={() => {
                if (refMap.current) {
                    refMap.current.instance.center(null)
                        .zoomFactor(null);
                }
            }}>Reset</button>
        </div >
    );
});

export default MapIndonesia;