import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import useProfile, { ProfileProvider } from './useProfile';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { pathProfile } from '../../app.config';
import { Card } from 'react-bootstrap';
import { HeaderList } from '../../components';

function EditProfile(props) {
    const { profileState, profileAction } = useProfile();
    const [state, dispatch, action] = useGlobalState();
    const [name, setName] = useState('');
    const [place_of_birth, setPlace_of_birth] = useState('');
    const [date_of_birth, setDate_of_birth] = useState('');
    const [function_id, setFunction_id] = useState('');
    const [section_id, setSection_id] = useState('');
    const [email, setEmail] = useState();
    const [position_id, setPosition_id] = useState();
    const [employee_id, setEmployee_id] = useState();
    const [linked_in, setLinked_in] = useState();
    const [facebook, setFacebook] = useState();
    const [handphone, setHandphone] = useState();
    const [twitter, setTwitter] = useState();
    const [instagram, setInstagram] = useState();
    const [blog, setBlog] = useState();
    const [status_profil, setStatus_profil] = useState();
    const [gender, setGender] = useState();
    const [photo_location, setPhoto_location] = useState();
    const [photo_link, setPhoto_link] = useState();
    const [join_date, setJoin_date] = useState();
    const [end_date, setEnd_date] = useState();
    const [usia, setUsia] = useState();
    const [tahun_pengalaman, setTahun_pengalaman] = useState();
    const [image, setImage] = useState();
    const [avatar, setAvatar] = useState();
    const param = useParams();
    const history = useHistory();

    useEffect(() => {
        if (param?.id) {
            action.getData('/api/param-function', {}, 'data_function');
            action.getData(`/api/people-hrd/view-update-profile?id_user=${param.id}`).then((resp) => {
                if (resp.code === '00') {
                    setName(resp.data.data_user_profile.name);
                    setPlace_of_birth(resp.data.data_user_profile.place_of_birth);
                    setDate_of_birth(moment(resp.data.data_user_profile.date_of_birth).format('YYYY-MM-DD'));
                    setEmail(resp.data.data_user_profile.email);
                    setEmployee_id(resp.data.data_user_profile.employee_id);
                    setLinked_in(resp.data.data_user_profile.linked_in);
                    setFacebook(resp.data.data_user_profile.facebook);
                    setHandphone(resp.data.data_user_profile.handphone);
                    setTwitter(resp.data.data_user_profile.twitter);
                    setInstagram(resp.data.data_user_profile.instagram);
                    setBlog(resp.data.data_user_profile.blog);
                    setStatus_profil(resp.data.data_user_profile.status_profil);
                    setGender(resp.data.data_user_profile.gender);
                    setPhoto_location({
                        name: resp.data.data_user_profile.photo_location,
                        is_new: false
                    });
                    setPhoto_link(resp.data.data_user_profile.photo_link);
                    setJoin_date(moment(resp.data.data_user_profile.join_date).format('YYYY-MM-DD'));
                    setUsia(resp.data.data_user_profile.usia);
                    setTahun_pengalaman(resp.data.data_user_profile.tahun_pengalaman);
                    setFunction_id(resp.data.data_user_profile.function_id)
                    setSection_id(resp.data.data_user_profile.section_id);
                    setImage(resp.data.data_user_profile.photo_location);
                    functionChange(resp.data.data_user_profile?.function_id)
                    sectionChange(resp.data.data_user_profile?.section_id);
                    setPosition_id(resp.data.data_user_profile.position_id);
                }
            })
        }
    }, []);
    function functionChange(val) {
        setFunction_id(val);
        setSection_id('');
        setPosition_id('');
        if (val) {
            action.getData(`/api/param-function-manager/${val}`, {}, 'data_section_manager');
        }
    }
    function sectionChange(val) {
        setSection_id(val);
        setPosition_id('');
        if (val) {
            action.getData(`/api/param-function-manager-position/${val}`, {}, 'data_position')
        }
    }
    function onChangeFoto(files) {
        const file = files?.[0]
        if (file) {
            if (file.size > 10000000) {
                return Swal.fire({
                    title: 'Error',
                    text: "File lebih dari 10Mb",
                    icon: 'error',
                })
            } else {
                action.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": file,
                        },
                        withFile: true,
                        withLoading: false,
                        withAlert: false
                    }
                ).then((resp) => {
                    if (resp.code === "00") {
                        // console.log(resp)
                        setPhoto_location(
                            {
                                name: resp.data?.data_filename,
                                is_new: true
                            }
                        );
                        setImage(URL.createObjectURL(file))
                    }
                })
            }
        }

    }
    function deleteFoto() {
        setImage('');
        setPhoto_location('');
    }
    function onSave(data) {
        const dataKirim = {
            "id": state.data_user_profile.id,
            "employee_id": data.employee_id,
            "join_date": data.join_date,
            "linked_in": data.linked_in,
            "facebook": data.facebook,
            "handphone": data.handphone,
            "twitter": data.twitter,
            "instagram": data.instagram,
            "blog": data.blog,
            "photo_location": data.photo_location,
            "photo_link": data.photo_link,
            "date_of_birth": data.date_of_birth,
            "type": data.type,
            "user_id": state.data_user_profile.user_id,
            "status_profil": data.status_profil,
            "end_date": data.end_date,
            "position_id": data.position_id,
            "gender": data.gender
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update Profile?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                //console.log('semua data', state)
                action.postData(
                    {
                        url: "/api/people-hrd/update-profile",
                        data: dataKirim,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.goBack();
                        //window.location.href = '/profile'
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div className="row justify-content-center">
            <div className="col-md-8">
                <HeaderList
                    title="Edit Profile"
                    someText="People Management\Edit Profile"
                />
                <Card
                    className="p-0 customShadow"
                >
                    <Card.Body className="p-5">
                        <p><b>Profile Overview</b></p>
                        <hr />
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-10">
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        Foto Profile
                                    </div>
                                    <div className="col-md-9">
                                        <div className="d-block">
                                            <div className="d-flex text-center">
                                                <div className="position-relative justify-content-center">
                                                    <div className="symbol symbol-120 mr-3 customShadow">
                                                        <img alt="Pic" src={image || ''}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = "/media/svg/icons/General/User.svg" }}
                                                        />

                                                    </div>
                                                    <label style={{ cursor: 'pointer', width: '20px', height: '20px', right: '5px' }} htmlFor="files" className="bg-white rounded-circle py-0 m-0 customShadow position-absolute"><i className="fa fa-pencil-alt icon-sm m-auto"></i></label>
                                                    <input id="files" style={{ display: 'none' }} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => onChangeFoto(e.target.files)} />
                                                    {/* <label style={{ cursor: 'pointer', width: '20px', bottom: '25px', right: '5px' }} className="bg-secondary rounded py-0 m-0 customShadow position-absolute" onClick={() => deleteFoto()}><i className="fas fa-times icon-nm"></i></label> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label>Nama</label>
                                    </div>
                                    <div className="col-md-9">
                                        {/* <span className='form-control form-control-sm bg-disable'>{name || ''}</span> */}
                                        <input className="form-control form-control-sm " value={name || ''} onChange={(e) => setName(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Jenis Kelamin</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-control form-control-sm " value={gender || ''} onChange={(e) => setGender(e.target.value)}>
                                            <option value=""> - </option>
                                            <option value="Laki">Laki - Laki</option>
                                            <option value="Perempuan">Perempuan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label>Tanggal Lahir</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="date" className="form-control form-control-sm " value={date_of_birth || ''} onChange={(e) => setDate_of_birth(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>No Pegawai</label>
                                    </div>
                                    <div className="col-md-9">
                                        {/* <span className='form-control form-control-sm bg-disable'>{employee_id || ''}</span> */}
                                        <input className="form-control form-control-sm " value={employee_id || ''} onChange={(e) => setEmployee_id(e.target.value)}></input>
                                    </div>
                                </div>
                                {/* <div className="row  mb-3">
                                    <div className="col-md-2">
                                        <label>Status Pegawai</label>
                                    </div>
                                    <div className="col-md-10">
                                        <select className="form-control form-control-sm " value={status_profil} onChange={(e) => setStatus_profil(e.target.value)}>
                                            <option value="">- Pilih Status -</option>
                                            <option value="Non Active">Non Active</option>
                                            <option value="Retired">Retired</option>
                                            <option value="Contract">Contract</option>
                                            <option value="Active">Active</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Masuk Pertamina</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="date" className="form-control form-control-sm " value={join_date || ''} onChange={(e) => setJoin_date(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Fungsi</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-control form-control-sm " value={function_id || ''} onChange={(e) => functionChange(e.target.value)}>
                                            <option value="" >- Pilih Fungsi -</option>
                                            {
                                                [...(state.data_function || [])].map((d, i) => (
                                                    <option value={d.id} key={i}>{d.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Section Manager</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-control form-control-sm " value={section_id || ''} onChange={(e) => sectionChange(e.target.value)}>
                                            <option value="" >- Pilih Section -</option>
                                            {
                                                [...(state.data_section_manager || [])].map((d, i) => (
                                                    <option value={d.id} key={i}>{d.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Position</label>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-control form-control-sm " value={position_id || ''} onChange={(e) => setPosition_id(e.target.value)}>
                                            <option value="" >- Pilih Position -</option>
                                            {
                                                [...(state.data_position || [])].map((d, i) => (
                                                    <option value={d.id} key={i}>{d.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p><b>Contact</b></p>
                        <hr />
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Email</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input disabled className="form-control form-control-sm " value={email || ''} onChange={(e) => setEmail(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>No. Hp/Whatsapp</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm " value={handphone || ''} onChange={(e) => setHandphone(e.target.value)}></input>
                                    </div>
                                </div>

                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Linkedin</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm " value={linked_in || ''} onChange={(e) => setLinked_in(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label>Facebook</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm " value={facebook || ''} onChange={(e) => setFacebook(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label>Twitter</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm " value={twitter || ''} onChange={(e) => setTwitter(e.target.value)}></input>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label>Instagram</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm " value={instagram || ''} onChange={(e) => setInstagram(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-md-3">
                                        <label>Web/Blog</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm " value={blog || ''} onChange={(e) => setBlog(e.target.value)}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="text-md-right">
                            <button className="btn btn-sm btn-secondary mx-1" onClick={() => history.goBack()}>Batal</button>
                            <button className="btn btn-sm btn-primary mx-1"
                                onClick={() => onSave({
                                    name,
                                    place_of_birth,
                                    date_of_birth,
                                    function_id,
                                    section_id,
                                    email,
                                    position_id,
                                    employee_id,
                                    linked_in,
                                    facebook,
                                    handphone,
                                    twitter,
                                    instagram,
                                    blog,
                                    status_profil,
                                    gender,
                                    photo_location,
                                    photo_link,
                                    join_date,
                                    end_date,
                                    usia,
                                    tahun_pengalaman,
                                })}
                            ><i className="fa fa-save icon-nm mx-1"></i>Simpan</button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}
export default () => <ProfileProvider><EditProfile /></ProfileProvider>