import React from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { useGlobalState } from '../../../contexts/useGlobalState';
import useLocalStorage from '../../../contexts/useLocalStorage';
import { HomeLandPage, ProductLandPage, AchievementLandPage, EventLandPage, CreateResearch, LaboratoryPage, LaboratoryPageDetail } from '../page';
import Product_detail from '../product/product_detail';
import EventDetail from '../event/event-detail';


function RoutePage(props) {
    const [token] = useLocalStorage("token");
    const [pathBefore, setPathBefore] = useLocalStorage("pathBefore", "/");
    const [stateGlobal] = useGlobalState()
    const isAuthorized = stateGlobal.isAuthenticated || (token != null || token != undefined);
    const { path, url } = useRouteMatch();

    return (
        <>
            <Switch>
                {
                    // isAuthorized && <Redirect exact from='/landing-page' to={"/home"} />
                    // isAuthorized && <Redirect exact to={pathBefore} />
                }
                {/* <Route
                    key="/landing-page"
                    path="/landing-page"
                    exact={true}
                    // render={() => <Redirect exact from='/landing-page' to={isAuthorized ? "/home" : "/landing-page/beranda"} />}
                    render={() => <Redirect exact from='/landing-page' to={isAuthorized ? pathBefore : "/landing-page/beranda"} />}
                /> */}
                <Route
                    key="/landing-page/beranda"
                    path="/landing-page/beranda"
                    exact={true}
                    render={() => <HomeLandPage />}
                />
                <Route
                    key="/landing-page/product"
                    path="/landing-page/product"
                    exact={true}
                    render={() => <ProductLandPage />}
                />                
                <Route
                    key="/landing-page/product/detail"
                    path="/landing-page/product/detail"
                    exact={true}
                    render={() => <Product_detail />}
                />
                <Route
                    key="/landing-page/laboratory"
                    path="/landing-page/laboratory"
                    exact={true}
                    render={() => <LaboratoryPage />}
                />
                <Route
                    key="/landing-page/laboratory/detail"
                    path="/landing-page/laboratory/detail"
                    exact={true}
                    render={() => <LaboratoryPageDetail />}
                />
                <Route
                    key="/landing-page/achievement"
                    path="/landing-page/achievement"
                    exact={true}
                    render={() => <AchievementLandPage />}
                />
                <Route
                    key="/landing-page/event"
                    path="/landing-page/event"
                    exact={true}
                    render={() => <EventLandPage />}
                />
                <Route
                    key="/landing-page/event/detail"
                    path="/landing-page/event/detail"
                    exact={true}
                    render={() => <EventDetail />}
                />
                <Route
                    key="/landing-page/create-research"
                    path="/landing-page/create-research"
                    exact={true}
                    render={() => <CreateResearch />}
                />
                <Redirect to="/landing-page/beranda" />
            </Switch>

        </>
    );
}

export default RoutePage;