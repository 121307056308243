import React, { memo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';

import { AbiIcon, CommercialIcon, OperationalIcon, ProductIcon, ResearchIcon, TechIcon } from './icon';

const CreateProject = memo(() => {
    const a = useLocation();
    const location = useHistory();
    const [store, dispatch, actions] = useGlobalState();

    function getLinkByProjectType() {
        if (store.formData.project_type === 1 ||
            store.formData.project_type === 2 ||
            store.formData.project_type === 3) {
            return "/create-project"
        } else if (store.formData.project_type === 4) {
            return "/create-project"
        } else if (store.formData.project_type === 5) {
            return "/create-project-operasional"
        } 
        // else if (store.formData.project_type === 6) {
        //     return "/create-project-abiabo"
        // } 
        else {
            return "/project/create"
        }
    }
    useEffect(() => {
        if(store.modal.createProject === true){
            actions.resetForm();
        }
    },[store.modal.createProject])
    return (
        <>
            <div className="c-project-base">
                <div className="c-project-head">
                    <span className="title">Create Project <i className="fa fa-pencil"></i></span><br />
                    <span> Pilih salah satu tipe projek yang akan dibuat</span>
                </div>

                <div className="row" style={{ marginBottom: 15 }}>
                    <div className="col-6">
                        <div onClick={() => {
                            actions.setForm("project_type", 1)
                            actions.setForm("project_type_str", "Product Development")
                        }} className={`c-project-choice ${store.formData?.project_type === 1 ? "active" : ""}`}>
                            <div className="row p-3">
                                <div className="col-2">
                                    <ProductIcon
                                        {
                                        ...({ filla: "#369AFF" })
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <span className={`title-choice ${store.formData?.project_type === 1 ? "choice-active" : ""}`}>Product Development</span>
                                    <p style={{
                                        fontSize: "7pt"
                                    }}>
                                        Pengembangan produk/teknologi yang mencakup pembuatan konsep, desain, pengembangan, s.d. produk siap dikomersialkan. Tahapan proyek ini berbasis pada tingkat kesiapan teknologi (TRL)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div onClick={() => {
                            actions.setForm("project_type", 2)
                            actions.setForm("project_type_str", "Technology Selection")
                        }} className={`c-project-choice ${store.formData?.project_type === 2 ? "active" : ""}`}>
                            <div className="row p-3">
                                <div className="col-2">
                                    <TechIcon
                                        {
                                        ...({ fill: "#369AFF" })
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <span className={`title-choice ${store.formData?.project_type === 2 ? "choice-active" : ""}`}>Technology Selection</span>
                                    <p style={{
                                        fontSize: "7pt"
                                    }}>
                                        Pengembangan proyek yang berdasarkan pada beberapa teknologi yang sudah terbukti (proven) atau telah dikomersialkan. Tahapan proyek ini minimal dimulai pada TKT/TRL 8.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginBottom: 15 }}>
                    <div className="col-6">
                        <div onClick={() => {
                            actions.setForm("project_type", 3)
                            actions.setForm("project_type_str", "Research Study")
                        }} className={`c-project-choice ${store.formData?.project_type === 3 ? "active" : ""}`}>
                            <div className="row p-3">
                                <div className="col-2">
                                    <ResearchIcon

                                        {
                                        ...({ fill: "#369AFF" })
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <span className={`title-choice ${store.formData?.project_type === 3 ? "choice-active" : ""}`}>Research Study</span>
                                    <p style={{
                                        fontSize: "6pt"
                                    }}>
                                        Pengembangan proyek inovasi yang bertujuan untuk mengkaji suatu permasalahan / kondisi / fenomena yang memberi manfaat untuk keberlangsungan operasional dan bisnis perusahaan. Proyek ini tidak bertujuan untuk dikomersialkan dan tahapannya tidak berbasis pada TKT/TRL.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div onClick={() => {
                            actions.setForm("project_type", 4)
                            actions.setForm("project_type_str", "Commercial")
                        }} className={`c-project-choice ${store.formData?.project_type === 4 ? "active" : ""}`}>
                            <div className="row p-3">
                                <div className="col-2">
                                    <CommercialIcon
                                        {
                                        ...({ fill: "#369AFF" })
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <span className={`title-choice ${store.formData?.project_type === 4 ? "choice-active" : ""}`}>Commercial</span>
                                    <p style={{
                                        fontSize: "7pt"
                                    }}>
                                        Pengembangan proyek yang bertujuan untuk menginisiasi proses komersial dari suatu produk inovasi maupun hasil seleksi teknologi.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginBottom: 15 }}>
                    <div className="col-6">
                        <div onClick={() => {
                            actions.setForm("project_type", 5)
                            actions.setForm("project_type_str", "Operational")
                        }} className={`c-project-choice ${store.formData?.project_type === 5 ? "active" : ""}`}>
                            <div className="row p-3">
                                <div className="col-2">
                                    <OperationalIcon

                                        {
                                        ...({ fill: "#369AFF" })
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <span className={`title-choice ${store.formData?.project_type === 5 ? "choice-active" : ""}`}>Operational</span>
                                    <p style={{
                                        fontSize: "7pt"
                                    }}>
                                        Kegiatan rutinitas yang bertujuan untuk mendukung operasional RTI.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-6">
                        <div onClick={() => {
                            actions.setForm("project_type", 6)
                            actions.setForm("project_type_str", "ABI / ABO Pra Proyek")
                        }} className={`c-project-choice ${store.formData?.project_type === 6 ? "active" : ""}`}>
                            <div className="row p-3">
                                <div className="col-2">
                                    <AbiIcon

                                        {
                                        ...({ fillb: "#369AFF", fillc: "#369AFF", stroke: "#369AFF" })
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <span className={`title-choice ${store.formData?.project_type === 6 ? "choice-active" : ""}`}>ABI / ABO Pra Proyek</span>
                                    <p style={{
                                        fontSize: "7pt"
                                    }}>
                                        Pengembangan proyek investasi yang berdasarkan pada pedoman investasi korporat.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="row" style={{ marginBottom: 15 }}>
                    <div className="col-12 text-center">
                        <button className={`btn-create rounded text-white ${store.formData.project_type !== null
                            && store.formData.project_type !== undefined ? "active" : ""}`}
                            disabled={store.formData.project_type === undefined || store.formData.project_type === null}
                            onClick={() => {
                                actions.toggleModal("createProject");
                                location.push(getLinkByProjectType(), { project_type: store.formData.project_type, project_type_str: store.formData.project_type_str })
                            }
                            }
                        >
                            <i className="fa fa-pencil" /> Create Project
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
})

export default CreateProject;