import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { pathImage, pathProfile } from '../../app.config';

function ModalProfileEdit({ id = '', onSave = (e) => { }, onClose = () => { } }) {

    const [state, dispatch, action] = useGlobalState();
    const [name, setName] = useState('');
    const [place_of_birth, setPlace_of_birth] = useState('');
    const [date_of_birth, setDate_of_birth] = useState('');
    const [function_id, setFunction_id] = useState('');
    const [section_id, setSection_id] = useState('');
    const [email, setEmail] = useState();
    const [position_id, setPosition_id] = useState();
    const [employee_id, setEmployee_id] = useState();
    const [linked_in, setLinked_in] = useState();
    const [facebook, setFacebook] = useState();
    const [handphone, setHandphone] = useState();
    const [twitter, setTwitter] = useState();
    const [instagram, setInstagram] = useState();
    const [blog, setBlog] = useState();
    const [status_profil, setStatus_profil] = useState();
    const [gender, setGender] = useState();
    const [photo_location, setPhoto_location] = useState();
    const [photo_link, setPhoto_link] = useState();
    const [join_date, setJoin_date] = useState();
    const [end_date, setEnd_date] = useState();
    const [usia, setUsia] = useState();
    const [tahun_pengalaman, setTahun_pengalaman] = useState();
    const [image, setImage] = useState();
    const [avatar, setAvatar] = useState();

    useEffect(() => {
        if (state.modal.profileEdit === true) {
            action.getData('/api/param-function', {}, 'data_function');
            action.getData(`/api/people-hrd/view-update-profile?user_id=${id}`).then((resp) => {
                if (resp.code === '00') {
                    setName(resp.data.data_user_profile.name);
                    setPlace_of_birth(resp.data.data_user_profile.place_of_birth);
                    setDate_of_birth(moment(resp.data.data_user_profile.date_of_birth).format('YYYY-MM-DD'));
                    setEmail(resp.data.data_user_profile.email);
                    setPosition_id(resp.data.data_user_profile.position_id);
                    setEmployee_id(resp.data.data_user_profile.employee_id);
                    setLinked_in(resp.data.data_user_profile.linked_in);
                    setFacebook(resp.data.data_user_profile.facebook);
                    setHandphone(resp.data.data_user_profile.handphone);
                    setTwitter(resp.data.data_user_profile.twitter);
                    setInstagram(resp.data.data_user_profile.instagram);
                    setBlog(resp.data.data_user_profile.blog);
                    setStatus_profil(resp.data.data_user_profile.status_profil);
                    setGender(resp.data.data_user_profile.gender);
                    setPhoto_location({
                        name: resp.data.data_user_profile.photo_location,
                        is_new: false
                    });
                    setPhoto_link(resp.data.data_user_profile.photo_link);
                    setJoin_date(moment(resp.data.data_user_profile.join_date).format('YYYY-MM-DD'));
                    setUsia(resp.data.data_user_profile.usia);
                    setTahun_pengalaman(resp.data.data_user_profile.tahun_pengalaman);
                    setFunction_id(resp.data.data_user_profile.function_id)
                    setSection_id(resp.data.data_user_profile.section_id);
                    setImage(pathProfile + resp.data.data_user_profile.photo_location);
                    functionChange(resp.data.data_user_profile?.function_id)
                    sectionChange(resp.data.data_user_profile?.section_id);
                }
            })

        }
    }, [state.modal.profileEdit])

    function functionChange(val) {
        setFunction_id(val)
        // setSection_id('');
        action.getData(`/api/param-function-manager/${val}`, {}, 'data_section_manager')
    }
    function sectionChange(val) {
        setSection_id(val);
        action.getData(`/api/param-function-manager-position/${val}`, {}, 'data_position')
    }
    function onchageFoto(files) {
        const file = files?.[0]
        if (file) {
            if (file.size > 10000000) {
                return Swal.fire({
                    title: 'Error',
                    text: "File lebih dari 10Mb",
                    icon: 'error',
                })
            } else {
                action.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": file,
                        },
                        withFile: true,
                        withLoading: false,
                        withAlert: false
                    }
                ).then((resp) => {
                    if (resp.code === "00") {
                        // console.log(resp)
                        setPhoto_location(
                            {
                                name: resp.data?.data_filename,
                                is_new: true
                            }
                        );
                        setImage(URL.createObjectURL(file))
                    }
                })
            }
        }

    }
    function deleteFoto() {
        setImage('');
        setPhoto_location('');
    }
    return (
        <>
            <p><b>Edit Profile</b></p>
            <hr />
            <div className="d-block text-center mb-5">
                <div className="d-flex justify-content-center">
                    <div className="position-relative">
                        <div className="symbol symbol-120 mr-3 customShadow">
                            <img alt="Pic" src={image}
                                onError={(e) => { e.target.onerror = null; e.target.src = "/media/svg/icons/General/User.svg" }}
                            />
                            {/* <i className="symbol-badge bg-success"></i> */}
                        </div>
                        <label style={{ cursor: 'pointer', width: '20px', bottom: 0, right: '5px' }} htmlFor="files" className="bg-secondary rounded py-0 m-0 customShadow position-absolute"><i className="fa fa-pencil-alt icon-sm"></i></label>
                        <input id="files" style={{ display: 'none' }} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => onchageFoto(e.target.files)} />
                        <label style={{ cursor: 'pointer', width: '20px', bottom: '25px', right: '5px' }} className="bg-secondary rounded py-0 m-0 customShadow position-absolute" onClick={() => deleteFoto()}><i className="fas fa-times icon-nm"></i></label>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2">
                    <label>Nama</label>
                </div>
                <div className="col-md-10">
                    <input className="form-control form-control-sm " value={name} onChange={(e) => setName(e.target.value)} disabled></input>
                </div>
            </div>
            <div className="row  mb-3">
                {/* <div className="col-md-2">
                    <label>Tempat Lahir</label>
                </div>
                <div className="col-md-4">
                    <input className="form-control form-control-sm " value={place_of_birth} onChange={(e) => setPlace_of_birth(e.target.value)}></input>
                </div> */}
                <div className="col-md-2">
                    <label>Tanggal Lahir</label>
                </div>
                <div className="col-md-4">
                    <input type="date" className="form-control form-control-sm " value={date_of_birth} onChange={(e) => setDate_of_birth(e.target.value)}></input>
                </div>
                <div className="col-md-2">
                    <label>Jenis Kelamin</label>
                </div>
                <div className="col-md-4">
                    <select className="form-control form-control-sm " value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value=""> - </option>
                        <option value="Laki">Laki - Laki</option>
                        <option value="Perempuan">Perempuan</option>
                    </select>
                </div>
            </div>
            {/* <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Umur</label>
                </div>
                <div className="col-md-4">
                    <input type="number" min={0} className="form-control form-control-sm " value={usia} onChange={(e) => setUsia(e.target.value)}></input>
                </div>
                <div className="col-md-2">
                    <label>Jenis Kelamin</label>
                </div>
                <div className="col-md-4">
                    <select className="form-control form-control-sm " value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value=""> - </option>
                        <option value="Laki - Laki">Laki - Laki</option>
                        <option value="Laki - Laki">Perempuan</option>
                    </select>
                </div>
            </div> */}
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>No Pegawai</label>
                </div>
                <div className="col-md-10">
                    <input className="form-control form-control-sm " value={employee_id}></input>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Status Pegawai</label>
                </div>
                <div className="col-md-10">
                    <select className="form-control form-control-sm " value={status_profil} onChange={(e) => setStatus_profil(e.target.value)}>
                        <option value="">- Pilih Status -</option>
                        <option value="Non Active">Non Active</option>
                        <option value="Retired">Retired</option>
                        <option value="Contract">Contract</option>
                        <option value="Active">Active</option>
                    </select>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Masuk Pertamina</label>
                </div>
                <div className="col-md-10">
                    <input type="date" className="form-control form-control-sm " value={join_date} onChange={(e) => setJoin_date(e.target.value)}></input>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Fungsi</label>
                </div>
                <div className="col-md-10">
                    <select className="form-control form-control-sm " value={function_id} onChange={(e) => functionChange(e.target.value)}>
                        <option value="">- Pilih Fungsi -</option>
                        {
                            [...(state.data_function || [])].map((d, i) => (
                                <option value={d.id} key={i}>{d.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Section Manager</label>
                </div>
                <div className="col-md-10">
                    <select className="form-control form-control-sm " value={section_id} onChange={(e) => sectionChange(e.target.value)}>
                        <option value="">- Pilih Section -</option>
                        {
                            [...(state.data_section_manager || [])].map((d, i) => (
                                <option value={d.id} key={i}>{d.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Position</label>
                </div>
                <div className="col-md-10">
                    <select className="form-control form-control-sm " value={position_id} onChange={(e) => setPosition_id(e.target.value)}>
                        <option value="">- Pilih Section -</option>
                        {
                            [...(state.data_position || [])].map((d, i) => (
                                <option value={d.id} key={i}>{d.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Whatsapp</label>
                </div>
                <div className="col-md-4">
                    <input className="form-control form-control-sm " value={handphone} onChange={(e) => setHandphone(e.target.value)}></input>
                </div>
                <div className="col-md-2">
                    <label>Twitter</label>
                </div>
                <div className="col-md-4">
                    <input className="form-control form-control-sm " value={twitter} onChange={(e) => setTwitter(e.target.value)}></input>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Linkedin</label>
                </div>
                <div className="col-md-4">
                    <input className="form-control form-control-sm " value={linked_in} onChange={(e) => setLinked_in(e.target.value)}></input>
                </div>
                <div className="col-md-2">
                    <label>Instagram</label>
                </div>
                <div className="col-md-4">
                    <input className="form-control form-control-sm " value={instagram} onChange={(e) => setInstagram(e.target.value)}></input>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-2">
                    <label>Facebook</label>
                </div>
                <div className="col-md-4">
                    <input className="form-control form-control-sm " value={facebook} onChange={(e) => setFacebook(e.target.value)}></input>
                </div>
                <div className="col-md-2">
                    <label>Website</label>
                </div>
                <div className="col-md-4">
                    <input className="form-control form-control-sm " value={blog} onChange={(e) => setBlog(e.target.value)}></input>
                </div>
            </div>
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1" onClick={onClose}>Batal</button>
                <button className="btn btn-sm btn-primary mx-1"
                    onClick={() => onSave({
                        name,
                        place_of_birth,
                        date_of_birth,
                        function_id,
                        section_id,
                        email,
                        position_id,
                        employee_id,
                        linked_in,
                        facebook,
                        handphone,
                        twitter,
                        instagram,
                        blog,
                        status_profil,
                        gender,
                        photo_location,
                        photo_link,
                        join_date,
                        end_date,
                        usia,
                        tahun_pengalaman,
                    })}
                ><i className="fa fa-save icon-nm mx-1"></i>Simpan</button>
            </div>
        </>
    );
}

export default ModalProfileEdit;