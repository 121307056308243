import React from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../../components';
import SVG from "react-inlinesvg";
import useApprovalPreIdeasi, { ApPReIdProvider } from './useApprovalPreIdeasi';
import moment from 'moment';

function Index(props) {
    const { apPreIdAction, apPreIdState } = useApprovalPreIdeasi();
    return (
        <div>
            <div className="pb-5">
                <HeaderList
                    title={apPreIdState.data_page?.page_title}
                    someText={apPreIdState.data_page?.page_breadcrumbs}
                    optionList={[{
                        withLabel: true,
                        label: "Rekapitulasi usulan riset pada tahun ",
                        option: [...(apPreIdState.data?.data_allyear || [])].map(d => ({ value: d?.idYear, name: d?.idYear })),
                        value: apPreIdState.data?.data_tahun_query,
                        onChange: (val) => apPreIdAction.getData(val)
                    }]}
                ></HeaderList>
            </div>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row" style={{ fontSize: '8pt' }}>
                        <div className="col-md-2">
                            <div className="d-flex">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Project</span>
                                    <span className="d-block"><strong>{apPreIdState.data?.data_jumlah}</strong></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1" style={{ width: 15, height: 15, backgroundColor: '#006CB8' }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Pre Ideation Review</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ideation_review}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Portfolio Review</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.portfolio_review}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Approve to Implementation</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.approved_to_implementation}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Monitoring Revision Review</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ongoing_revision_review}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Continue Request</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ongoing_continue_request}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Aborted/Finished Request</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ongoing_aborted_finished_request}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5 px-5">
                        <div className="col-md-12">
                            <GridDx
                                searchForm={true}
                                loading={apPreIdState.loading}
                                ref={apPreIdState.refTable}
                                selectionMode={"multiple"}
                                exportMode="selection"
                                exportExcel={true}
                                // exportPdf={reporting ? true : false}
                                filenameExport={`Pre Ideation Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                data={apPreIdState.data.data_table}
                                cellRender={apPreIdAction.cellRender}
                                columns={apPreIdState.column}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >

                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default (props) => <ApPReIdProvider><Index {...props} /></ApPReIdProvider>;