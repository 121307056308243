import { FileUploader } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
// import { fileExtensions, fileExtensions_string } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// import SVG from "react-inlinesvg";
import { pathFile } from '../../app.config';

function ModalExpertise({ data = [], onSave = (e) => { }, onClose = () => { } }) {
    const [state, dispatch, action] = useGlobalState();
    const [group, setGroup] = useState();
    const [subGroup, setSubGroup] = useState();
    const [expertise, setExpertise] = useState();
    const [statusEdit, setStatusEdit] = useState(0);
    const [uploaded_files, setUploaded_files] = useState();
    const [user_profile_id, setUser_profile_id] = useState();
    const [editId, setIdEdit] = useState();

    useEffect(() => {
        if (state.modal.expertiseEdit === true) {
            action.getData('/api/param-expertise-group', {}, 'group_expertise');
        }

    }, [state.modal.expertiseEdit])

    function groupChange(val) {
        if (val) {
            setGroup(val);
            setSubGroup('');
            setExpertise('');
            action.getData(`/api/param-expertise-sub-group/${val}`, {}, 'sub_group_expertise');
        }
    }
    function subGroupChange(val) {
        if (val) {
            setSubGroup(val)
            setExpertise('');
            action.getData(`/api/param-expertise-detail/${val}`, {}, 'data_expertise');
        }
    }
    function addExpertise() {
        const dataKirim = {
            "id": '',
            "uploaded_files": {
                name: uploaded_files?.name,
                is_new: uploaded_files?.is_new
            },
            "user_profile_id": state.data_user_profile?.id,
            "expertise_id": expertise || ''
        }
        if (dataKirim.expertise_id === '') {
            return Swal.fire({
                title: 'Warning',
                text: "Expertise Tidak Boleh Kosong",
                icon: 'warning',
            })
        } else {

            // console.log(dataKirim)
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Tambah Expertise?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/people-hrd/create-expertise`,
                            data: dataKirim,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            cancelEdit();
                            onSave();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function updateExpertise() {
        const dataKirim = {
            "id": editId,
            "uploaded_files": {
                name: uploaded_files?.name,
                is_new: uploaded_files?.is_new
            },
            "user_profile_id": state.data_user_profile?.id,
            "expertise_id": expertise
        }
        if (dataKirim.expertise_id === '') {
            return Swal.fire({
                title: 'Warning',
                text: "Expertise Tidak Boleh Kosong",
                icon: 'warning',
            })
        } else {

            // console.log(dataKirim)
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Expertise?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/people-hrd/update-expertise`,
                            data: dataKirim,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            cancelEdit();
                            onSave();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    // function validation() {

    // }
    function deleteExpertise(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Hapus Expertise ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/people-hrd/delete-expertise?id=${id}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        cancelEdit();
                        onSave();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function setEdit(id) {
        action.getData(`/api/people-hrd/view-update-expertise?id=${id}`, {}, 'data_edit_expertise').then((resp) => {
            if (resp.code === '00') {
                setUser_profile_id(resp.data.data_user_expertise.user_profile_id)
                groupChange(resp.data.data_user_expertise?.skill_group_id);
                subGroupChange(resp.data.data_user_expertise?.sub_skill_group_id);
                setGroup(resp.data.data_user_expertise?.skill_group_id);
                setSubGroup(resp.data.data_user_expertise?.sub_skill_group_id);
                setExpertise(resp.data.data_user_expertise.expertise_id);
                setUploaded_files({
                    name: resp.data.data_user_expertise.uploaded_files || '',
                    is_new: false
                })
                setIdEdit(resp.data.data_user_expertise?.id)
                setStatusEdit(1);
            }
        })
    }
    function cancelEdit() {
        setExpertise('');
        setUser_profile_id('')
        setGroup('');
        setSubGroup('');
        setUploaded_files('')
        setIdEdit('')
        setStatusEdit(0);
    }
    function fileOnchange(data) {
        if (data.value?.[0] && data.value?.[0]?.size <= 10000000) {
            // var file = data.value?.[0];
            // var ext = file?.name?.split('.').pop();
            // var result = fileExtensions_string.filter(d => d === ext)
            // if (result.length > 0) {
            action.postData(
                {
                    url: "/api/base/upload-file-temp",
                    data: {
                        "File": data.value?.[0],
                    },
                    withFile: true,
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    setUploaded_files({
                        "name": resp.data?.data_filename,
                        "is_new": true
                    })
                }
            })
            // }
        }
    }
    return (
        <div className="px-5">
            <p><b>{statusEdit === 0 ? 'Add' : 'Edit'} Expertise</b></p>
            <hr />
            <div className="px-2 py-5 my-5 rounded bg-warning text-light-warning mb-5 text-center">
                <div className="d-inline-flex">
                    <i className="fas fa-exclamation-triangle text-light-warning mx-2"></i>
                    <span>Masuken Expertise anda yang sudah dijalani selama minimal 2 tahun</span>
                </div>

            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    <label>Group</label>
                </div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" placeholder="Group" value={group} onChange={(e) => groupChange(e.target.value)}>
                        <option value="">- Group -</option>
                        {
                            [...(state.group_expertise || [])].map((d, i) => (
                                <option key={i} value={d.id}>{d.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    <label>Sub Group</label>
                </div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" value={subGroup} onChange={(e) => subGroupChange(e.target.value)}>
                        <option value="">- Sub Group -</option>
                        {
                            [...(state.sub_group_expertise || [])].map((d, i) => (
                                <option key={i} value={d.id}>{d.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    <label>Expertise</label>
                </div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" value={expertise} onChange={(e) => setExpertise(e.target.value)}>
                        <option value="">- Tittle Expertise-</option>
                        {
                            [...(state.data_expertise || [])].map((d, i) => (
                                <option key={i} value={d.id}>{d.technical_competency}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">File Pendukung</div>
                <div className="col-md-9">
                    <FileUploader className="custom-dx-fileuploader-file-name p-0 custom-uploader"
                        //allowedFileExtensions={fileExtensions}
                        accept="/*"
                        invalidFileExtensionMessag="File "
                        onValueChanged={(e) => fileOnchange(e)}
                        showFileList={false} multiple={false}
                        selectButtonText="Select File"
                        readyToUploadMessage=" "
                        labelText="or Drop file hire | Max size 10 Mb" maxFileSize={10000000} uploadMode="useForm" >

                    </FileUploader>
                    <span>{uploaded_files?.name}</span>
                </div>
            </div>
            <div className="d-block text-right mb-5">
                {
                    statusEdit === 0 && (
                        <>
                            <button className="btn btn-sm btn-success" onClick={addExpertise}><i className="fa fa-plus icon-nm mr-3"></i>Expertise</button>
                        </>
                    )
                }
                {
                    statusEdit === 1 && (
                        <>
                            <button className="btn btn-sm btn-secondary mx-1" onClick={() => cancelEdit()}>Cancel Edit</button>
                            <button className="btn btn-sm btn-success mx-1" onClick={updateExpertise}><i className="fa fa-save icon-nm mr-3"></i>Update</button>
                        </>
                    )
                }
            </div>
            {/* <hr /> */}
            {
                [...(data || [])].map((d, i) => (
                    <div className="row mb-3" key={i}>
                        <div className="col-6">
                            <div className="d-inline-flex">
                                <i className="fa fa-circle icon-xs mr-5 mt-1 text-dark"></i>
                                <div>
                                    <span className="d-block"><b>{d.technical_competency}</b></span>
                                    <span className="d-block">{d.sub_skill_group} - {d.skill_group}</span>                                    
                                    <span className="d-block">
                                        {
                                            d.uploaded_files && (
                                                <a target="_blank" href={pathFile + d.uploaded_files} download>{d.uploaded_files}</a>
                                            )
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-md-right">
                            <button className="btn btn-sm btn-outline-warning text-warning mx-1" onClick={() => setEdit(d?.id)}>
                                <i className="fa fa-pencil-alt icon-nm p-0"></i>
                            </button>
                            <button className="btn btn-sm btn-outline-danger text-danger mx-1" onClick={() => deleteExpertise(d.id)}>
                                <i className="fa fa-trash-alt icon-nm p-0"></i>
                            </button>
                        </div>
                    </div>
                ))
            }
            <hr />
            <div className="text-md-right">
                {/* <button className="btn btn-sm btn-secondary mx-1" onClick={onClose}>Batal</button>
                <button className="btn btn-sm btn-primary mx-1" onClick={onSave}><i className="fa fa-save icon-nm mx-1"></i>Simpan</button> */}
            </div>
        </div>
    );
}

export default ModalExpertise;