import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    // Tab,
    // TabContent, 
    HeaderList
} from '../../components';
// import DashboardEquipment from '../equipmentMng/dashboard';
import ListEquipmentAdmin from '../equipmentMng/listAdmin';
import useEquipment, { EqProvider } from '../equipmentMng/useEquipment';
import useCrudEquipment, { CrudEquipmentProvider } from './useCrudEquipment';


function CrudEquipment(props) {
    const {
        // eqAction,
        eqState } = useEquipment();
    const { ceqAction, ceqState } = useCrudEquipment();
    // const { pathname } = useLocation();
    // const history = useHistory();
    return (
        <>
            <div style={{ paddingBottom: '20px' }}>
                <HeaderList
                    title={eqState.data_page?.page_title}
                    someText={eqState.data_page?.page_breadcrumbs}
                />
                <div className="row">
                    <div className="col-12">
                        {/* <Tab
                            withCard={false}
                            itemsTab={[
                                {
                                    id: "dashboard",
                                    title: "Dashboard",
                                    onClick: () => history.push(`/admin/equipment-crud/dashboard`)
                                },
                                {
                                    id: "table",
                                    title: "Equipment Table",
                                    onClick: () => history.push(`/admin/equipment-crud/table`)
                                },
                            ]}
                            manualActive={pathname === '/admin/equipment-crud/table' ? 'table' : 'dashboard'}
                        >
                            <TabContent id="dashboard">
                                <DashboardEquipment />
                            </TabContent>
                            <TabContent id="table"> */}
                        <ListEquipmentAdmin editing={true} view_admin={true} ceqAction={ceqAction} ceqState={ceqState} />
                        {/* </TabContent>
                        </Tab> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <CrudEquipmentProvider><EqProvider><CrudEquipment /></EqProvider></CrudEquipmentProvider>;