import React, { useEffect, useState } from 'react'
import { RadioGrp } from '../../components';
// import FileUploader from 'devextreme-react/file-uploader';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Button, Spinner } from 'react-bootstrap';
import './modalStopProject.css';
import FileUploadDx from '../fileUploadDx/fileUploadDx';

export default function ({ id }) {
    const [state, dispatch, action] = useGlobalState();
    const [status, setStatus] = useState('1');
    const [reason, setReason] = useState('');
    const [actual, setActual] = useState(0);
    const [year_form, setYear_form] = useState(0);
    // const [data_is_oke, setData_is_oke] = useState(0);
    const [document, setDocument] = useState([]);
    const [document_delete, setDocument_delete] = useState([]);
    const [cover_file, setCover_file] = useState([]);
    const [cover_file_delete, setCover_file_delete] = useState([]);

    useEffect(() => {
        if (state.modal?.modalStopProjectForm === true) {
            resetEditor();
            getActualTimeline(id);
            getDataReport(id);
        }
    }, [state.modal?.modalStopProjectForm]);


    function resetEditor() {
        setStatus('1');
        setReason('');
        setDocument([]);
        setDocument_delete([]);
        setCover_file([]);
        setCover_file_delete([]);
    }
    function getActualTimeline(id_research) {
        if (id_research) {
            action.getData("/api/get-actual-timeline?id=" + id_research, {}, null, 'loadingStop').then((resp) => {
                if (resp.code === '00') {
                    setActual(resp.data.data_actual_timeline);
                    setYear_form(resp.data.data_year_form);
                    //setData_is_oke()
                }
            })
        }
    }
    function getDataReport(id_research) {
        action.getData(`/api/get-data-report-request?research_id=${id_research}&tipe=${1}`, {}, null, 'loadingStop').then((resp) => {
            // console.log(resp);
            if (resp.code === '00') {
                if (resp.data.data_request) {
                    // console.log('ress', resp.data);
                    setDocument([...(resp.data?.data_report || [])].map(d => {
                        return {
                            file_location: d?.file_location,
                            file_name: d?.file_name,
                            is_new: false
                        }
                    }));
                    if (resp.data.data_request?.cover_file_name) {
                        setCover_file([{
                            file_name: resp.data?.data_request?.cover_file_name,
                            file_location: resp.data?.data_request?.cover_file_location,
                            is_new: false
                        }])
                    }
                }
            }
        })
    }
    // function fileFinalReport(data) {
    //     const file = data.value?.[0]
    //     if (data.value?.[0] && file.size <= 10000000) {
    //         action.postData(
    //             {
    //                 url: "/api/base/upload-file-temp",
    //                 data: {
    //                     "File": data.value?.[0],
    //                 },
    //                 withFile: true,
    //                 withLoading: false,
    //                 withAlert: false
    //             }
    //         ).then((resp) => {
    //             if (resp.code === "00") {
    //                 setDocument([{
    //                     "file_location": resp.data?.data_file_location,
    //                     "file_name": resp.data?.data_filename,
    //                     "is_new": true
    //                 }, ...document])
    //             }
    //         })
    //     } else if (data.value?.[0] && file.size > 10000000) {
    //         return Swal.fire({
    //             title: 'Error',
    //             text: "File lebih dari 10Mb",
    //             icon: 'error',
    //         })
    //     }
    // }
    function deleteSumberFile(file, index) {
        if (file?.is_new === true) {
            action.postData(
                {
                    url: "/api/base/delete-file-temp?fileName=" + file?.file_name,
                    withFile: false,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code !== "00") {
                    return Swal.fire({
                        title: 'Warning',
                        text: resp.message,
                        icon: 'warning',
                    })
                } else {
                    setDocument(document.filter(d => d !== file));
                    setDocument_delete([{ file }, ...document_delete]);
                }
            })
        }
        if (file?.is_new === false) {
            setDocument(document.filter(d => d !== file));
            setDocument_delete([file, ...document_delete]);
        }
    }
    // function fileCoverReport(data) {
    //     const file = data.value?.[0]
    //     if (data.value?.[0] && file.size <= 10000000) {
    //         action.postData(
    //             {
    //                 url: "/api/base/upload-file-temp",
    //                 data: {
    //                     "File": data.value?.[0],
    //                 },
    //                 withFile: true,
    //                 withLoading: false,
    //                 withAlert: false
    //             }
    //         ).then((resp) => {
    //             if (resp.code === "00") {
    //                 setCover_file([{
    //                     "file_location": resp.data?.data_file_location,
    //                     "file_name": resp.data?.data_filename,
    //                     "is_new": true
    //                 }])
    //             }
    //         })
    //     } else if (data.value?.[0] && file.size > 10000000) {
    //         return Swal.fire({
    //             title: 'Error',
    //             text: "File lebih dari 10Mb",
    //             icon: 'error',
    //         })
    //     }
    // }

    function fileFinalSet(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setDocument([{
                "file_location": resp.data?.data_file_location,
                "file_name": resp.data?.data_filename,
                "is_new": true
            }, ...document])
        }
    }
    function fileCoverSet(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setCover_file([{
                "file_location": resp.data?.data_file_location,
                "file_name": resp.data?.data_filename,
                "is_new": true
            }])
        }
    }

    function deleteFileCover(file) {
        if (file?.is_new === true) {
            action.postData(
                {
                    url: "/api/base/delete-file-temp?fileName=" + file.file_name,
                    withFile: false,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code !== "00") {
                    return Swal.fire({
                        title: 'Error',
                        text: resp.message,
                        icon: 'error',
                    })
                } else {
                    setCover_file([]);
                }
            })
        }
        if (file?.is_new === false) {
            setCover_file([]);
            setCover_file_delete([file]);
        }
    }
    function sendRequestClick() {
        if (status === '1') {
            finishedRequest()
        } else {
            abbortedRequest();
        }
    }
    function finishedRequest() {
        const data = {
            "id": null,
            "research_id": id || '',
            'document': document || [],
            'document_delete': document_delete || [],
            "year": year_form,
            "is_final_report": true,
            'cover_file': cover_file || [],
            'cover_file_delete': cover_file_delete || [],
            "reason": reason || ''
        };
        const validate = validationFinish(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message.join(', ') + " Tidak Boleh Kosong",
                icon: 'warning',
            })
        } else {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-success mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                    '<strong>Finished Project ?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-ongoing-crud/submit-finished-request",
                            data: data,
                        },
                        () => window.location.reload()
                    ).then((resp) => {
                        if (resp.code === "00") {
                            //action.toggleModal("modalStopProject")
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function abbortedRequest() {
        const data = {
            "id": null,
            "research_id": id || '',
            'document': document || [],
            'document_delete': document_delete || [],
            "year": year_form,
            "is_final_report": true,
            'cover_file': cover_file || [],
            'cover_file_delete': cover_file_delete || [],
            "reason": reason || ''
        };
        // console.log('Firing')
        const validate = validationAbort(data);
        // console.log('validasi', validate)
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message.join(', ') + " Tidak Boleh Kosong",
                icon: 'warning',
            })
        } else {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-danger mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                    '<strong>Aborted Project ?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-ongoing-crud/submit-aborted-request",
                            data: data,
                        },
                        () => window.location.reload()
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal("modalStopProject")
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function validationAbort(data) {
        var isValid = true;
        var message = [];
        if (data.reason === '') {
            isValid = false;
            message = [...message, 'Reason']
        }
        if ([...(data.document || [])].length === 0) {
            isValid = false;
            message = [...message, 'Final Report']
        }
        return {
            isValid,
            message
        }
    }
    function validationFinish(data) {
        var isValid = true;
        var message = [];
        if (data.reason === '') {
            isValid = false;
            message = [...message, 'Reason']
        }
        if ([...(data.document || [])].length === 0) {
            isValid = false;
            message = [...message, 'Final Report']
        }
        if ([...(data.cover_file || [])].length === 0) {
            isValid = false;
            message = [...message, 'Cover Book/Foto']
        }
        return {
            isValid,
            message
        }
    }
    return (
        <div className={state.loadingStop && 'overlay overlay-block'}>
            <div className="overlay-wrapper p-2 rounded">
                <span>Stop Project</span>
                <hr />
                <span className="d-block p-3 bg-danger rounded text-white text-center mb-2">Total Actual Timeline {parseFloat(actual || '0').toFixed(2)}%</span>
                {
                    state.data_request && (
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div className="p-3 bg-light-warning rounded">
                                    <span className="text-wrap">{state.data_request?.reason}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                <form>
                    <div className="form-group row">
                        <label htmlFor="status" className="col-sm-2 col-form-label">Status</label>
                        <div className="col-sm-10 my-auto">
                            <div className="form-control border-0" id="status">
                                <RadioGrp
                                    modeRow={true}
                                    onChange={(e) => setStatus(e)}
                                    value={status}
                                    data={[
                                        { label: 'Finished', value: '1' },
                                        { label: 'Aborted', value: '0' },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="reason" className="col-sm-2 col-form-label">Reason</label>
                        <div className="col-sm-10">
                            <textarea
                                disabled={status === '1' ? ((parseFloat(actual) || 0) >= 100 ? false : true) : false}
                                rows={4}
                                className="form-control"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                id="reason" placeholder="Reason" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="reason" className="col-sm-2 col-form-label">Final report<br /><span className="text-danger">*max file 15 Mb</span></label>
                        <div className="col-sm-10">
                            <div className="p-3 border rounded">
                                {/* <FileUploader
                                    onValueChanged={(e) => fileFinalReport(e)}
                                    disabled={status === '1' ? ((parseFloat(actual) || 0) >= 100 ? false : true) : false}
                                    className="custom-dx-fileuploader-file-name"
                                    showFileList={false} multiple={false}
                                    selectButtonText="Select File"
                                    readyToUploadMessage=" "
                                    labelText="or Drop file hire | Max size 15 Mb" accept="*" maxFileSize={15000000} uploadMode="useForm" >

                                </FileUploader> */}
                                <FileUploadDx
                                    disabled={status === '1' ? ((parseFloat(actual) || 0) >= 100 ? false : true) : false}
                                    className="custom-dx-fileuploader-file-name"
                                    showFileList={true}
                                    multiple={false}
                                    selectButtonText="Select File"
                                    readyToUploadMessage=" "
                                    labelText="or Drop file hire | Max size 15 Mb"
                                    accept='*'
                                    allowedFileExtensions={['.pdf', '.pptx', '.docx', '.xlsx']}
                                    onUploaded={fileFinalSet}
                                    maxFileSize={15000000}
                                    uploadMode="instantly"
                                />
                                <div className="d-block">
                                    {
                                        [...(document || [])].map((d, i) => (
                                            <div className="d-flex flex-row justify-content-between mb-1">
                                                <span style={{ fontSize: '8pt' }}>{d.file_name}</span>
                                                <div>
                                                    {/* <a target="_blank" href={d?.file_name} download>
                                                        <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1"></i>
                                                    </a> */}
                                                    <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger" onClick={() => deleteSumberFile(d, i)}></i>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="reason" className="col-sm-2 col-form-label">Cover Book/Foto<br /><span className="text-danger">*max file 1 Mb</span></label>
                        <div className="col-sm-10">
                            <div className="p-3 border rounded">
                                {/* <FileUploader
                                    onValueChanged={(e) => fileCoverReport(e)}
                                    disabled={status === '1' ? ((parseFloat(actual) || 0) >= 100 ? false : true) : false}
                                    className="custom-dx-fileuploader-file-name"
                                    showFileList={false} multiple={false}
                                    selectButtonText="Select File"
                                    readyToUploadMessage=" "
                                    labelText="or Drop file hire | Max size 1 Mb" accept="*" maxFileSize={1000000} uploadMode="useForm" >
                                </FileUploader> */}
                                <FileUploadDx
                                    disabled={status === '1' ? ((parseFloat(actual) || 0) >= 100 ? false : true) : false}
                                    className="custom-dx-fileuploader-file-name"
                                    showFileList={true}
                                    multiple={false}
                                    selectButtonText="Select File"
                                    readyToUploadMessage=" "
                                    labelText="or Drop file hire | Max size 1 Mb"
                                    accept="image/*"
                                    allowedFileExtensions={ ['.jpg', '.jpeg', '.gif', '.png']}
                                    onUploaded={fileCoverSet}
                                    maxFileSize={1000000}
                                    uploadMode="instantly"
                                />
                                {
                                    cover_file.map(d => (
                                        <div className="mt-3 mb-5">
                                            <div className="d-flex flex-row justify-content-between mb-1">
                                                <span style={{ fontSize: '8pt' }}>{d?.file_name}</span>
                                                <div>
                                                    {/* <a target="_blank" href={d?.file_name} download>
                                                        <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1"></i>
                                                    </a> */}
                                                    <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deleteFileCover(d)}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12 text-md-right">
                        <button disabled={status === '1' ? (((parseFloat(actual) || 0) % 100 === 0 && (parseFloat(actual) || 0) > 0) ? false : true) : false} className="btn btn-sm btn-danger mx-1" onClick={() => sendRequestClick()}>Send Request</button>
                        <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => action.toggleModal("modalStopProjectForm")}>Close</button>
                    </div>
                </div>
            </div>
            {
                state.loadingStop && (
                    <div className="overlay-layer bg-overlay-stop">
                        <Button variant="primary" disabled>
                            <Spinner
                                className="mx-2"
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    </div>
                )
            }
        </div>
    )
}