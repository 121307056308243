import React from 'react';
import { risk_priority } from '../../../components/utility/utility'; 
const DetilProjectRisk = ({
        id,
        research_id,
        baseline_name,
        risk_id,
        tipe_risk,
        kejadian,
        penyebab,
        gejala,
        faktor_positif,
        dampak_kual,
        inher_kuant,
        inher_probabil,
        inher_dampak,
        mit_rencana,
        mit_biaya,
        mit_tahun_berlaku,
        mit_tahun_selesai,
        res_kuant,
        res_probabil,
        res_dampak
}) => {
    return (
        <div className="page-content-pp">
            <span className="page-title-pp">
                Project Risk {risk_id}
            </span>
            <hr />

            <div className="row">
                <div className="col-5">

                    <div className="row mb-3">
                        <div className="col-4">
                            <span className="text-head">
                                Kuantitatif / Kualitatif Risk
                            </span>
                        </div>
                        <div className="col-8">
                            <p>
                                {tipe_risk}
                            </p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <span className="text-head">
                                Kejadian Resiko (Risk Event)

                            </span>
                        </div>
                        <div className="col-8">
                            <p>
                                {kejadian}
                            </p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <span className="text-head">
                                Penyebab Resiko (Risk Agent)
                            </span>
                        </div>
                        <div className="col-8">
                            <p>
                                {penyebab}
                            </p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <span className="text-head">
                                Gejala Resiko (KRI)

                            </span>
                        </div>
                        <div className="col-8">
                            <p>
                                {gejala}
                            </p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <span className="text-head">
                                Faktor Positif (Control)
                            </span>
                        </div>
                        <div className="col-8">
                            <p>
                                {faktor_positif}
                            </p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <span className="text-head">
                                Dampak Kualitatif
                            </span>
                        </div>
                        <div className="col-8">
                            <p>
                               {dampak_kual}
                            </p>
                        </div>
                    </div>

                </div>
                <div className="col-7">
                    <div className="row">
                        <div className="col-6">
                            <span className="page-title-pp">
                                Inherent
                            </span>
                            <hr />

                            <div class="row mb-3">
                                <div class="col-5">
                                    <span class="text-head">
                                        Probabilitas
                                    </span>
                                </div>
                                <div class="col-7">
                                    <p>
                                        {inher_probabil}
                                    </p>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-5">
                                    <span class="text-head">
                                        Dampak
                                    </span>
                                </div>
                                <div class="col-7">
                                    <p>
                                        {inher_dampak}
                                    </p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-5">
                                    <span class="text-head">
                                        Risk Priority Number (RPN)
                                    </span>
                                </div>
                                <div class="col-7">
                                    <p>
                                        {
                                            risk_priority(inher_probabil, inher_dampak)
                                        }
                                        {/* <div class="rpn" style={{ background: "#20d873" }}> */}
                                            {/* //@if (rpn <= 3) { @Html.Raw("style='background:#20d873;'")  } else if (rpn > 3 && rpn <= 6) { @Html.Raw("style='background:#f2f22a;'")  } else if (rpn > 8 && rpn <= 12) { @Html.Raw("style='background:#eb9015;'")  } else if (rpn > 12) { @Html.Raw("style='background:#c73636;'")  }> */}
                                            {null}
                                        {/* </div> */}

                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col-6">
                            <span className="page-title-pp">
                                Residual
                            </span>
                            <hr />

                            <div class="row mb-3">
                                <div class="col-5">
                                    <span class="text-head">
                                        Probabilitas
                                    </span>
                                </div>
                                <div class="col-7">
                                    <p>
                                        {res_probabil}
                                    </p>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-5">
                                    <span class="text-head">
                                        Dampak
                                    </span>
                                </div>
                                <div class="col-7">
                                    <p>
                                        {res_dampak}
                                    </p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-5">
                                    <span class="text-head">
                                        Risk Priority Number (RPN)
                                    </span>
                                </div>
                                <div class="col-7">
                                    <p>
                                        {
                                            risk_priority(res_probabil, res_dampak)
                                        }
                                        {/* <div class="rpn" style={{ background: "#20d873" }}> */}
                                            {/* //@if (rpn <= 3) { @Html.Raw("style='background:#20d873;'")  } else if (rpn > 3 && rpn <= 6) { @Html.Raw("style='background:#f2f22a;'")  } else if (rpn > 8 && rpn <= 12) { @Html.Raw("style='background:#eb9015;'")  } else if (rpn > 12) { @Html.Raw("style='background:#c73636;'")  }> */}
                                            {null}
                                        {/* </div> */}

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className="page-title-pp">
                                Mitigasi
                            </span>
                            <hr />
                            <div className="row mb-3">
                                <div className="col-5">
                                    <span className="text-head">
                                        Rencana Mitigasi
                                    </span>
                                </div>
                                <div className="col-7">
                                    <p>
                                        {mit_rencana}
                                    </p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-5">
                                    <span className="text-head">
                                        Biaya Mitigasi (USD)
                                    </span>
                                </div>
                                <div className="col-7">
                                    <p>
                                        $ {mit_biaya} USD
                                    </p>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-5">
                                    <span className="text-head">
                                        Tahun Berlaku
                                    </span>
                                </div>
                                <div className="col-7">
                                    <p>
                                        {mit_tahun_berlaku}
                                    </p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-5">
                                    <span className="text-head">
                                    Tahun Selesai
                                    </span>
                                </div>
                                <div className="col-7">
                                    <p>
                                        {mit_tahun_selesai}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DetilProjectRisk;