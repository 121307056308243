import { toLower } from 'lodash';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { ChartDx, CstmFilterCahrtDx, SwitchBtn } from '../../components';
import useSwakelola from './useSwakelola';
import { formatter } from '../../components/utility/utility';

const DashboardSwakelola = () => {
    const colordicapai = [
        { val: '0', color: '#369AFF' },
        { val: '4', color: '#366ED2' },
        { val: '8', color: '#F2BA22' },
        { val: '5', color: '#414346' }]
    const {
        swaAction,
        swaState
    } = useSwakelola()

    return (
        <>
            <div className="row">
                <div className="col-12">

                    <Card
                        className="m-0 p-0  mb-5 customShadow bg-primary"
                    >
                        <Card.Body className="p-3">
                            <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                                <div className="col-lg-2 col-md-2 col-6 mb-5">
                                    <div className="d-flex border-right-custom">
                                        <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                            <SVG src="/media/svg/icons/Shopping/Cart1.svg" />
                                        </span>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Total Swakelola</span>
                                            <span className="d-block"><strong>
                                                {/* {[...(krjState.dashboard_box || [])].reduce((tot, val) => tot = tot + val?.jumlah, 0)} */}
                                                {swaState.dataHeader?.jumlahTotal}
                                            </strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-purple" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Validasi</span>
                                            {/* <span> Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block"><strong>{swaState.dataHeader?.jumlahValidasi}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Revisi</span>

                                            {/* <span> Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block"><strong>{swaState.dataHeader?.jumlahRevisi}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Pengadaan</span>

                                            {/* <span> Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block"><strong>{swaState.dataHeader?.jumlahPengadaan}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-primary" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">K. Berjalan</span>

                                            {/* <span> Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block"><strong>{swaState.dataHeader?.kontrakBerjalan}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Addendum</span>

                                            {/* <span> Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block"><strong>{swaState.dataHeader?.addendum}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Rejected</span>

                                            {/* <span> Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block"><strong>{swaState.dataHeader?.rejected}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Selesai</span>

                                            {/* <span> Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block"><strong>{swaState.dataHeader?.Selesai}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-5">
                    <div className="card customShadow">
                        <div className="card-header">
                            <div class="row m-0">
                                <div class="col p-0">
                                    <span className="ctsmFilterTitle">Status by Function</span>
                                    <span className="ctsmFilterSubTitle">Perbandingan status swakelola berdasarkan Fungsi</span>
                                </div>
                                <div className='col-auto'>
                                    <SwitchBtn className='my-2' value={swaState.sw1} onChange={(e) => swaAction.setSw1(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <ChartDx
                                id="3"
                                height={270}
                                LegendverticalAlignment="bottom"
                                LegendhorizontalAlignment="center"
                                //  labelChart={true}
                                //  loading={state.loadingAll || state.loadingChart3}
                                argumentField="status_swakelola"
                                type={swaState.sw1 === true ? "bar" : "stackedBar"}
                                dataSource={swaState?.dataDashboard?.chart_status || []}
                                withTitle={false}
                                showLegend={true}
                                toolsExport={false}
                                //onRenderLabelChart={() => { }}
                                onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                                rotate={false}
                                yAxisArgs={swaState.sw1 === true ? [{ name: "Total", valueField: "rti", color: "#369AFF" },] : [
                                    { name: "TD I", valueField: "td_i", },
                                    { name: "TD II", valueField: "td_ii", },
                                    { name: "TA", valueField: "ta", },
                                    { name: "TISPOM", valueField: "tispom", },
                                    { name: "TINC", valueField: "tinc", },
                                    { name: "FL", valueField: "fl", },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-5">
                    <CstmFilterCahrtDx
                        title="Monitoring Line"
                        subtitle="Perbandingan kumulatif plan kontrak pembayaran dengan realisasi pembayaran (Juta IDR)"
                        option={(swaState?.dropDownChart2 || []).map(({ idYear }) => ({ value: idYear, name: idYear }))}
                        value={swaState.ddSelectedChart2}
                        onChange={(e) => swaAction.setDdSelectedChart2(e)}
                    >
                        <ChartDx
                            onRenderLabelChart={(e) => {
                                return `${formatter.format(e.value)}`
                            }}
                            onRenderTooltip={(arg) => ({ text: `${arg.seriesName}: ${formatter.format(arg.valueText)}` })}

                            loading={swaState.loadingChart2}
                            dataSource={(swaState.dataChart2 || []).map(d => ({ ...d, name: toLower(d.bulan_str), arg: d.bulan_str }))}
                            type="spline"
                            withTitle={false}
                            //yTitle='(Juta IDR)'
                            showLegend={false}
                            toolsExport={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="right"
                            // onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: ${e.valueText}` })}
                            yAxisArgs={[
                                {
                                    name: "Realisasi Pembayaran",
                                    valueField: "real_pembayaran",
                                    color: '#46CCB1'
                                },
                                {
                                    name: "Plan Pembayaran",
                                    valueField: "plan_pembayaran",
                                    color: '#D1D1D1'
                                },

                            ]}
                        >

                        </ChartDx>
                        <div className="row mt-2">
                            <div className="col-5" style={{ fontSize: '9pt' }}>
                                <div className='d-flex mt-4 mb-2'>
                                    <div style={{
                                        background: '#46CCB1',
                                        display: "inline-flex",
                                        width: "20px",
                                        height: "10px",
                                        borderRadius: "5px",
                                    }} className="my-auto mr-2"> &nbsp; </div>
                                    <span>Realisasi Pembayaran</span>
                                </div>
                                <div className='d-flex'>
                                    <div style={{
                                        background: "#D1D1D1",
                                        display: "inline-flex",
                                        width: "20px",
                                        height: "10px",
                                        marginTop: "5px",
                                        borderRadius: "5px",

                                    }} className="my-auto mr-2"> &nbsp;</div>
                                    <span>Plan Pembayaran</span>
                                </div>
                            </div>
                            <div className="col mt-2">
                                <div className="alert alert-success text-center">
                                    Realisasi Pembayaran : <b>{swaState?.data_persen_pembayaran} % / {formatter.format(swaState?.data_plan_pembayaran)} </b> (Juta IDR)
                                </div>
                            </div>
                        </div>
                    </CstmFilterCahrtDx>
                    {/* <div className="card customShadow">
                        <div className="card-header">
                            <div class="row m-0">
                                <div class="col-12 p-0">
                                    <span className="ctsmFilterTitle">Monitoring Line</span>
                                    <span className="ctsmFilterSubTitle">Perbandingan kumulatif plan kontrak pembayaran dengan realisasi pembayaran (Juta IDR)</span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            <ChartDx
                                onRenderLabelChart={(e) => {
                                    return `${parseFloat(e.value).toFixed(2)} (Juta IDR)`
                                }}
                                onRenderTooltip={(arg) => ({ text: `${arg.seriesName}: ${parseFloat(arg.valueText).toFixed(2)} (Juta IDR)` })}

                                loading={swaState.loadingChart2}
                                dataSource={(swaState.dataChart2 || []).map(d => ({ ...d, name: toLower(d.bulan_str), arg: d.bulan_str }))}
                                type="spline"
                                withTitle={false}
                                //yTitle='(Juta IDR)'
                                showLegend={false}
                                toolsExport={false}
                                LegendverticalAlignment="bottom"
                                LegendhorizontalAlignment="center"
                                LegenditemTextPosition="right"
                                onRenderLabelChart={function (e) { }}
                                // onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: ${e.valueText}` })}
                                yAxisArgs={[
                                    {
                                        name: "Realisasi Pembayaran",
                                        valueField: "real_pembayaran",
                                        color: '#46CCB1'
                                    },
                                    {
                                        name: "Plan Pembayaran",
                                        valueField: "plan_pembayaran",
                                        color: '#D1D1D1'
                                    },

                                ]}
                            >

                            </ChartDx>
                            <div className="row mt-2">
                                <div className="col-4" style={{ fontSize: '9pt' }}>
                                    <div style={{
                                        background: '#46CCB1',
                                        display: "inline-flex",
                                        width: "20px",
                                        height: "10px",
                                        marginTop: "7px",
                                        borderRadius: "5px",

                                    }} > &nbsp;</div> Realisasi Pembayaran <br />
                                    <div style={{
                                        background: "#D1D1D1",
                                        display: "inline-flex",
                                        width: "20px",
                                        height: "10px",
                                        marginTop: "5px",
                                        borderRadius: "5px",

                                    }} > &nbsp;</div> Plan Pembayaran
                                </div>
                                <div className="col mt-2">
                                    <div className="alert alert-success text-center">
                                        Realisasi Pembayaran : <b>{parseFloat(swaState?.dataDashboard?.presentase_persen || 0).toFixed(2)} % / {parseFloat(swaState?.dataDashboard?.presentase_nilai || 0).toFixed(2)} </b> (Juta IDR)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-5 mb-5">
                    <div className="card customShadow">
                        <div className="card-header">
                            <div class="row m-0">
                                <div class="col-12 p-0">
                                    <span className="ctsmFilterTitle">Nilai Kontrak vs Disbursement</span>
                                    <span className="ctsmFilterSubTitle">Perbandingan antara nilai kontrak dan disbursment disetiap fungsi (Juta IDR)</span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            <ChartDx
                                loading={swaState.loadingDashboard}
                                withTitle={false}
                                showLegend={true}
                                toolsExport={false}
                                argumentField="short_name"
                                height={255}
                                LegendverticalAlignment="bottom"
                                LegendhorizontalAlignment="center"
                                dataSource={swaState?.dataDashboard?.chart_kontrak || []}
                                onRenderLabelChart={(e) => formatter.format(e.value)}
                                onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${formatter.format(e.value)} (Juta IDR)` })}
                                yAxisArgs={[
                                    {
                                        name: "Nilai Kontrak",
                                        valueField: "nilai_kontrak",
                                        color: "#6C7AB5"
                                    },
                                    {
                                        name: "Disbursment",
                                        valueField: "disbursement",
                                        color: "#46CCB1"
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-7 mb-5">
                    <div className="card customShadow">
                        <div className="card-header">

                            <div class="row m-0">
                                <div class="col p-0">
                                    <span className="ctsmFilterTitle">Cost Saving</span>
                                    <span className="ctsmFilterSubTitle">Penghematan dengan membandingkan Estimasi dengan Nilai Kontrak (Juta IDR)</span>
                                </div>
                                <div className='col-auto'>
                                    <SwitchBtn className='my-2' value={swaState.swCost} onChange={(e) => swaAction.setSwCost(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <ChartDx
                                loading={swaState.loadingDashboard}
                                onRenderLabelChart={(e) => {
                                    if (swaState.swCost) {
                                        return `${formatter.format(e.value)} %`
                                    }
                                    return `${formatter.format(e.value)}`
                                }}
                                onRenderTooltip={(arg) => {
                                    if (swaState.swCost) {
                                        return { text: `${arg.seriesName}: ${formatter.format(arg.valueText)} %` }
                                    }
                                    return { text: `${arg.seriesName}: ${formatter.format(arg.valueText)}` }
                                }}
                                withTitle={false}
                                showLegend={true}
                                toolsExport={false}
                                argumentField="tahun"
                                // height={100}
                                LegendverticalAlignment="bottom"
                                LegendhorizontalAlignment="center"
                                dataSource={(swaState?.dataDashboard?.chart_cost || []).map(v => ({ ...v, tahun: v.tahun?.toString() }))}
                                yAxisArgs={swaState.swCost ? [{
                                    name: "Persentase penghematan",
                                    valueField: "persen_penghematan",
                                    type: "spline",
                                    color: "#46CCB1"
                                }] : [
                                    {
                                        name: "Owner Estimasi",
                                        valueField: "owner_estimate",
                                        type: "bar",
                                        color: "#F2BA22"
                                    },
                                    {
                                        name: "Nilai Kontrak",
                                        valueField: "nilai_kontrak",
                                        color: "#6C7AB5",
                                        type: "bar"
                                    },
                                    {
                                        name: "Nilai penghematan",
                                        valueField: "nilai_penghematan",
                                        type: "spline",
                                        color: "#46CCB1"
                                    }
                                ]}
                            />
                            <div className="row">
                                <div className="col-md-12 mt-4">

                                    <div style={{
                                        width: "80%",
                                        margin: "auto"
                                    }} className="alert alert-success text-center">
                                        Akumulasi Penghematan : <b>{swaState?.dataDashboard?.chart_4_akumulasi?.[0]?.persen_penghematan} %  / {formatter.format(swaState?.dataDashboard?.chart_4_akumulasi?.[0]?.nilai_penghematan || 0)}</b> (Juta IDR)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-4 mb-5">
                    <CstmFilterCahrtDx
                        title="Swakelola yang telah dicapai"
                        subtitle="Swakelola yang telah dicapai dalam 5 tahun terakhir"
                        option={(swaState?.dropDownChart5 || []).map(({ title, param_id }) => ({ value: param_id, name: title }))}
                        value={swaState.ddSelectedChart5}
                        onChange={(e) => swaAction.setDdSelectedChart5(e)}
                    >
                        <ChartDx

                            loading={swaState.loadingChart5}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            argumentField="tahun"
                            height={180}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            dataSource={(swaState.dataChart5 || []).map(v => ({ ...v, tahun: v.tahun?.toString() }))}
                            yAxisArgs={[
                                {
                                    name: "Jumlah",
                                    valueField: "jumlah",
                                    color: colordicapai.find(d => d?.val === swaState.ddSelectedChart5)?.color || '#369AFF'
                                },
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="card customShadow justify-content-center">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <CstmFilterCahrtDx
                                    title="Swakelola yang akan berakhir"
                                    subtitle="Swakelola yang akan berakhir masa berlakunya 5 tahun kedepan"
                                    option={(swaState?.dropDownChart6 || []).map(({ title, param_id }) => ({ value: param_id, name: title }))}
                                    //value={state.formData.jumlahIdeasi}
                                    cardStyle={false}
                                    withBorder={false}
                                    onChange={(e) => swaAction.setDdSelectedChart6(e)}
                                    value={swaState.ddSelectedChart6}
                                >
                                    <ChartDx
                                        loading={swaState.loadingChart6}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        argumentField="tahun_string"
                                        height={180}
                                        dataSource={(swaState.dataChart6 || []).map(v => ({ ...v, tahun: v.tahun?.toString() }))}
                                        yAxisArgs={[
                                            {
                                                name: "Jumlah",
                                                valueField: "jumlah",
                                                color: colordicapai.find(d => d?.val === swaState.ddSelectedChart6)?.color || '#369AFF'
                                            },
                                        ]}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                    />

                                </CstmFilterCahrtDx>
                                {/* <div className="card-header">
                                    <div class="row m-0">
                                        <div class="col-12 p-0">
                                            <span className="ctsmFilterTitle"></span>
                                            <span className="ctsmFilterSubTitle">Swakelola yang akan berakhir masa berlakunya 5 tahun kedepan</span>
                                        </div>

                                    </div>
                                </div> */}
                                {/* <div className="card-body"> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <img src="/svg/swakelolaimg.svg" className='swa-chart6-bg' />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardSwakelola;