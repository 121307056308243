import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useGlobalState } from "../../../contexts/useGlobalState";
import Swal from 'sweetalert2';
// import moment from "moment";
// import { Rowing } from "@material-ui/icons";

const pageSessionContext = createContext({
    pgSesAction: {},
    pgSesState: {}
})
const column_session = [
    { fieldName: 'id_session', caption: 'ID Session', allowFiltering: false },
    { fieldName: 'user_name', caption: 'Name User' },
    { fieldName: 'user_roles', caption: 'Roles Name' },
    { fieldName: 'user_position', caption: 'Position' },
    { fieldName: 'user_chief', caption: 'Chief' },
    { fieldName: 'user_function', caption: 'Fungsi' },
    { fieldName: 'device_type', caption: 'Device Type' },
    { fieldName: 'browser_type', caption: 'Browser Type' },
    { fieldName: 'created_at', caption: 'Start', allowFiltering: false },
    { fieldName: 'updated_at', caption: 'End', allowFiltering: false },
    { fieldName: 'user_logon', caption: 'Status Logon', allowFiltering: false },
]
const column_activity = [
    { fieldName: 'user_name', caption: 'User', allowFiltering: false },
    { fieldName: 'created_at', caption: 'Date Time', allowFiltering: false },
    { fieldName: 'page_name', caption: 'Page Name' },
    { fieldName: 'act_name', caption: 'Action Name' },
    { fieldName: 'act_type', caption: 'Action Type', allowFiltering: false },
    { fieldName: 'data_id', caption: 'Data ID', allowFiltering: false },
    { fieldName: 'data_summary', caption: 'Keterangan', allowFiltering: false },
]

const column_wa = [
    { fieldName: 'creator', caption: 'Pengirim', allowFiltering: true },
    { fieldName: 'receiver', caption: 'Penerima', allowFiltering: true },
    { fieldName: 'description', caption: 'Isi Pesan', allowFiltering:false },
    { fieldName: 'module', caption: 'Modul' },
    { fieldName: 'module_act', caption: 'Sub Modul', allowFiltering: true },
    { fieldName: 'module_id', caption: 'Data ID', allowFiltering: false },
    { fieldName: 'created_at', caption: 'Tanggal Kirim', allowFiltering: false },
]
export function PageSessionProvider({ children }) {
    const [, , action] = useGlobalState();
    const [data_page, setData_page] = useState({});

    const [loadingSes, setLoadingSes] = useState(false);
    const [dataSes, setDataSes] = useState({});

    const [loadingAct, setLoadingAct] = useState(false);
    const [dataAct, setDataAct] = useState({});

    // const [loadingUsr, setLoadingUsr] = useState(false);
    const [dataUsr, setDataUsr] = useState([]);

    const [id_user, setId_user] = useState('')
    const [date_start, setDate_start] = useState('')
    const [date_end, setDate_end] = useState('')

    const [id_userAct, setId_userAct] = useState('')
    const [date_startAct, setDate_startAct] = useState('')
    const [date_endAct, setDate_endAct] = useState('')
    const [actionType, setActionType] = useState('')

    async function getLogSession(id, start, end) {
        try {
            setLoadingSes(true);
            const resp = await action.getData("/api/user/table-page-session", {
                id_user: id,
                date_start: start,
                date_end: end
            }, null, null, true);
            if (resp?.code === "00") {
                setDataSes(resp?.data);
                setData_page(resp.data_page);
                setId_user(resp?.data?.id_user || '');
                setDate_start(resp?.data?.date_start);
                setDate_end(resp?.data?.date_end);
            }
            setLoadingSes(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function getLogActivity(id, start, end, type) {
        try {
            setLoadingAct(true);
            const resp = await action.getData("/api/user/table-page-log-search", {
                id_user: id,
                date_start: start,
                date_end: end,
                act_type: type
            }, null, null, true);
            if (resp?.code === "00") {
                setDataAct(resp?.data);
                setData_page(resp.data_page);
                setId_userAct(resp?.data?.id_user || '');
                setDate_startAct(resp?.data?.date_start);
                setDate_endAct(resp?.data?.date_end);
                setActionType(resp?.data?.act_type || '');
            }
            setLoadingAct(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }

    const [dataLogWa, setDataLogWa] = useState({});
    const [loadingWa, setLoadingWa] = useState(false);
    const [filterWa, setFilterWa] = useState({
        date_start: '',
        date_end: '',
        module_act: ''
    })
    const handleFilterWa = (name, val) => {
        setFilterWa((e) => ({
            ...e,
            [name]:val
        }))
    }
    async function getLogWa() {
        try {
            setLoadingWa(true);
            const resp = await action.getData("/api/user/table-wa-logs", {
                ...filterWa
            }, null, null, true);
            if (resp?.code === "00") {
                setDataLogWa(resp.data);
            }
            setLoadingWa(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    const getDataUser = useCallback(async function () {
        try {
            // setLoadingUsr(true);
            const resp = await action.getData("/api/param-user", {
            }, null, null, true);
            if (resp?.code === "00") {
                setDataUsr([...(resp?.data || [])].sort((pre, cur) => pre?.name > cur?.name ? 1 : -1));
            }
            // setLoadingUsr(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }, []);
    function filterSessionChange() {
        getLogSession(id_user, date_start, date_end);
    }
    function filterActivityChange() {
        getLogActivity(id_userAct, date_startAct, date_endAct, actionType);
    }
    function cellRenderActivity(rowInfo) {
        if (rowInfo.column.name === 'page_name') {
            return (
                <span className='d-block text-wrap'>{rowInfo.data?.grp_name + "/" + rowInfo.data?.side1_name + "/" + rowInfo.data?.side2_name + "/" + rowInfo.data?.side3_name + "/" + rowInfo.data?.act_name}</span>
            )
        } else {
            return (
                <span className='d-block text-wrap'>{rowInfo.value}</span>
            )
        }
    }
    useEffect(() => {
        getDataUser();
    }, [getDataUser])
    return <pageSessionContext.Provider
        value={{
            pgSesAction: {
                getLogSession,
                getLogActivity,
                getDataUser,
                getLogWa,
                setDate_start,
                setDate_end,
                setId_user,
                filterSessionChange,
                filterActivityChange,
                cellRenderActivity,
                handleFilterWa,
                setId_user,
                setDate_start,
                setDate_end,
                setId_userAct,
                setDate_startAct,
                setDate_endAct,
                setActionType
            },
            pgSesState: {
                column_session,
                column_activity,
                column_wa,
                data_page,
                dataSes,
                dataAct,
                loadingSes,
                loadingAct,
                date_start,
                date_end,
                dataUsr,
                id_user,
                id_userAct,
                date_startAct,
                date_endAct,
                actionType,
                loadingWa,
                dataLogWa,
                filterWa
            }
        }}
    >{children}</pageSessionContext.Provider>
}
export default function usePageSession() {
    return useContext(pageSessionContext);
}