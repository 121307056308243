import React from 'react';
import useMpps, { MppsProvider } from './_context';
import { HeaderList, Tab, TabContent } from '../../components';
import Dashboard from './components/dashboard';
import Table from './components/table';

function Index(props) {
    const { mppsAction, mppsState } = useMpps();
    return (
        <React.Fragment>
            <HeaderList
                title={mppsState.data_page?.page_title}
                someText={mppsState.data_page?.page_breadcrumbs}
                optionList={[
                    {
                        withLabel: true,
                        label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                        option: mppsState.year_source,
                        value: mppsState.filter.tahun,
                        onChange: (val) => mppsAction.filterChange('tahun', val)
                    },
                    {
                        withLabel: false,
                        option: mppsState.month,
                        value: mppsState.filter.bulanGet,
                        onChange: (val) => mppsAction.filterChange('bulan', val)
                    }]}
            ></HeaderList>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        title: 'Dashboard Project Unggulan Sub-Holding',
                        onClick: () => mppsAction.handleSelTabChange("dashboard")
                    },
                    {
                        id: "table",
                        title: "Table Project Unggulan Sub-Holding",
                        onClick: () => mppsAction.handleSelTabChange("table")
                    }
                ]}
                manualActive={mppsState.selTab}
            >
                <TabContent id="dashboard">
                    <Dashboard />
                </TabContent>
                <TabContent id="table">
                    <Table />
                </TabContent>

            </Tab>
        </React.Fragment>
    );
}

export default () => <MppsProvider><Index /></MppsProvider>;