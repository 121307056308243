import React, { memo, useRef } from 'react';
import { GridDx, HeaderList, Modal } from '../../components'
import { Card } from 'react-bootstrap'
import { useState } from 'react';
import ModalAbout from './modalAbout';
import { useGlobalState } from '../../contexts/useGlobalState';


const columns = [
    { fieldName: "date", caption: "Date", width: 200 },
    { fieldName: "version", caption: "Version", width: 300 },
    { fieldName: "update", caption: "update", minWidth: 300 },
]
const dummy = [
    {
        date: '01 Feb 2021',
        version: '2.1.2',
        update: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero'
    }
]
export default memo(function () {
    const tableRef = useRef()
    const [state, dispatch, action] = useGlobalState();
    const [mode, setMode] = useState()
    const [data, setData] = useState()

    function addAbout() {
        setMode("Add")
        action.toggleModal("modalabout")
    }
    function editAbout(data) {
        setMode("Edit")
        setData(data)
        action.toggleModal("modalabout")
    }
    return (
        <>
            <Modal
                name="modalabout"
                baseColor={false}
            >
                <ModalAbout mode={mode} data={data} />
            </Modal>
            <HeaderList
                title="Master About"
                someText="Master About\CRUD"
            />
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body>
                    {/* <div className="row justify-content-between">
                        <div className="col-md-3">
                            <div className="input-group mb-2">
                                <input className="form-control form-control-sm mr-1">
                                </input>
                                <div>
                                    <button type="submit" className="btn btn-sm btn-primary">
                                        <i className="fa fa-search icon-nm text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 text-right">
                            <button className="btn btn-sm btn-success mx-1"
                                onClick={() => addAbout()}
                            >
                                <i className="fa fa-plus icon-nm text-white"></i>
                                Add About
                            </button>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-12">
                            <GridDx
                                data={dummy}
                                searchForm={true}
                                toolbar={
                                    <>
                                        <button className="btn btn-sm btn-success mx-1"
                                            onClick={() => addAbout()}
                                        >
                                            <i className="fa fa-plus icon-nm text-white"></i>
                                            Add About
                                        </button>
                                    </>
                                }
                                ref={tableRef}
                                withAction={true}
                                columns={columns}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                actions={[
                                    {
                                        name: "Edit",
                                        icon: "fa fa-pencil-alt icon-nm text-success",
                                        action: (v, c) => editAbout(v)
                                    },
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => { }
                                    }
                                ]}
                                show

                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})