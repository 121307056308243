import { FileUploader } from 'devextreme-react';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import useModalEditorSwa, { SwaEditModalProvider } from './useModalEditorSwa';
import './crudSwakelola.css';
import { useGlobalState } from '../../contexts/useGlobalState';

const ModalEditorKontrak = ({ mode = 'Add', id_swakelola, data = {} }) => {
    const { swaEditState, swaEditAction } = useModalEditorSwa();
    const [state, dispatch, action] = useGlobalState();
    useEffect(() => {
        if (state.modal?.modalEditorKontrak === true) {
            swaEditAction.resetEditorKontrak();
            swaEditAction.setSwakelola_id(id_swakelola);
            swaEditAction.setEditorKontrak(data)
            swaEditAction.handleKontrak.setModeEditKontrak(mode);
        }
    }, [state.modal?.modalEditorKontrak])
    return (
        <div>
            <Card
                className="customShadow"
            >
                <Card.Body>
                    <h5>{`${mode} Kontrak`}</h5>
                    <hr />
                    <div className="row mb-3">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text bg-transparent border-0" id="basic-addon2">Nilai Kontrak</span>
                                </div>
                                <input type="number" className="form-control" placeholder="IDR Juta" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataKontrak.nilai_kontrak} onChange={(e) => swaEditAction.handleKontrak.setnilai_kontrak(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2">IDR (Juta)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text bg-transparent border-0">Periode Proyek</span>
                                </div>
                                <input type="date" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    value={swaEditState.dataKontrak.start_date} onChange={(e) => swaEditAction.handleKontrak.setStart_date(e.target.value)}
                                />
                                {/* <div className="input-group-append">
                                    <span className="input-group-text"><i className="far fa-calendar-alt icon-nm"></i></span>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text bg-transparent border-0">Sampai</span>
                                </div>
                                <input type="date" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    value={swaEditState.dataKontrak.end_date} onChange={(e) => swaEditAction.handleKontrak.setEnd_date(e.target.value)}
                                />
                                {/* <div className="input-group-append">
                                    <span className="input-group-text"><i className="far fa-calendar-alt icon-nm"></i></span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend d-block">
                                    <span style={{ width: '128px' }} className="input-group-text bg-transparent border-0" id="basic-addon2">Note (Optional)</span>
                                </div>
                                <textarea rows={4} className="form-control" placeholder="Notes" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataKontrak.note} onChange={(e) => swaEditAction.handleKontrak.setNote(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Absensi Rapat Pengadaan (Optional)</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader btn-outline"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_1')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok1_absensi}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Berita Acara Klarifikasi dan Negosiasi</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_5')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok5_ba_klarifikasi}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Berita Acara Penjelasan Pekerjaan</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader btn-outline"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_2')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok2_ba_penjelasan}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Berita Acara Negosiasi Lanjutan (Optional)</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_6')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok6_ba_negosiasi}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Berita Acara Pembukaan Penawaran</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader btn-outline"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_3')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok3_ba_pembukaan}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Kontrak (Final)</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_7')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok7_kontrak_final}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Berita Acara Evaluasi Administrasi, Teknis, dan Harga</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader btn-outline"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_4')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok4_ba_evaluasi}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className='col-5 px-5'>
                                    <span className="text-wrap text-left d-block px-2">Purchase Order</span>
                                </div>
                                <div className='col-7'>
                                    <FileUploader className="custom-dx-fileuploader-file-name custom-file-uploader"
                                        onValueChanged={(e) => swaEditAction.handleKontrak.uploadFileKontrak(e, 'dok_8')}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "

                                        labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                    </FileUploader>
                                    <span>{swaEditState.dataKontrak.dok8_po}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="text-md-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => swaEditAction.handleKontrak.batalKontrak()}
                        >
                            Batal
                        </button>
                        <button className="btn btn-sm btn-primary mx-1"
                            onClick={() => swaEditAction.saveButtonClick()}
                        >
                            <i className="fa fa-save icon-nm mr-1"></i>
                            Simpan
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default (props) => <SwaEditModalProvider><ModalEditorKontrak data={props?.data} id_swakelola={props.id_swakelola} mode={props.mode} /></SwaEditModalProvider>;