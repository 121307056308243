import React from 'react';
import { Modal } from 'react-bootstrap';
import useAddEditKerjasama from './useAddEditKerjasama';
import InformationJPG from '../component/information-kerjasama.jpeg'

function ModalInformation(props) {
    const { addEditKState, addEditKAction } = useAddEditKerjasama();
    const handleCloseModal = () => {
        addEditKAction.setShowInfo(false);
    }
    return (
        <Modal backdropClassName='' show={addEditKState.showInfo} onHide={handleCloseModal} size="lg">
            <Modal.Header>
                <Modal.Title>Information</Modal.Title>
                <i role={'button'} className='fas fa-times' onClick={handleCloseModal}></i>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <img style={{
                        'width': '100%',
                        'objectFit': 'cover'
                    }} src={InformationJPG}></img>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalInformation;