import React, { createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";
import useIndexLandPage from "../use_index";
import { useGlobalState } from "../../../contexts/useGlobalState";

const lndProdContext = createContext({
    lndProdAction: {},
    lndProdState: {}
});

export function LndProdProvider({ children }) {
    const [state, , action] = useGlobalState();
    const { indState } = useIndexLandPage();
    const { doGet } = useFetch();
    const [tahun, setTahun] = useState('');
    const [product_status, setproduct_status] = useState(0);
    const [product_type, setProduct_type] = useState(state.formData?.product_type || 0);
    const [judul, setJudul] = useState('');
    const [dataProduct, setDataProduct] = useState({});


    async function getData(thn = '', status = '', judul = '', type = '') {
        // setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/web/all-product',
                param: {
                    tahun: thn,
                    product_status: status,
                    judul: judul,
                    product_type_id: type
                },
                token: false
            });
            if (resp?.code === '00') {
                setDataProduct({ ...resp?.data })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        // setLoading(false);
    }
    async function getDataType() {
        try {
            const resp = await doGet({
                url: 'api/web/get-product-type',
                token: false
            });
            if (resp?.code === '00') {
                action.setForm('landingpage_product_type', [...resp?.data])
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
    }

    function tahunChange(val) {
        setTahun(val);
        getData(val, product_status, judul, product_type);
    }
    function productStatusChange(val) {
        setproduct_status(val);
        action.setForm('product_type', val);
        getData(tahun, val, judul);
    }
    function productTypeChange(val) {
        setProduct_type(val);
        action.setForm('product_type', val);
        getData(tahun, product_status, judul, val);
    }
    function judulEnter(e) {
        if (e?.key === 'Enter') {
            getData(tahun, product_status, judul, product_type);
        }
    }

    useEffect(() => {
        getData(tahun, product_status, judul, product_type);
        getDataType();
        // indAction.setScrollElement('beranda');
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])
    return <lndProdContext.Provider
        value={{
            lndProdAction: {
                tahunChange,
                productStatusChange,
                productTypeChange,
                setJudul,
                judulEnter
            },
            lndProdState: {
                judul,
                tahun,
                product_status,
                product_type,
                dataProduct,
            }
        }}
    >{children}</lndProdContext.Provider>
}

export default function useLndProd() {
    return useContext(lndProdContext)
}