import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { HeaderList, GridDx, Modal } from '../../components';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import ModalChangeStatus from './modalChangeStatus';
import { useHistory } from 'react-router';
import { status_pegawai } from '../../components/utility/utility';
import moment from 'moment';
import { Link } from 'react-router-dom';

const columns = [
    { fieldName: "name", caption: "Nama" },
    { fieldName: "status_profil", caption: "Status Pekerja", width: 100 },
    { fieldName: "end_date", caption: "End Date", width: 100 },
    { fieldName: "employee_id", caption: "No Peg" },
    { fieldName: "email", caption: "Email" },
    { fieldName: "gender", caption: "Gender" },
    { fieldName: "usia", caption: "Usia" },
    { fieldName: "position_name", caption: "Posisi" },
    { fieldName: "function_sn", caption: "Fungsi" },
    { fieldName: "section_sn", caption: "Chief" },
    { fieldName: "pendidikan", caption: "Pendidikan" },
    { fieldName: "join_date", caption: "Masuk Pertamina" },
    { fieldName: "pengalaman_kerja", caption: "Total Pengalaman Kerja" },
    { fieldName: "as_leader", caption: "As Leader" },
    { fieldName: "as_member", caption: "As Member" },
]
export default memo(function () {
    const [state, dispatch, action] = useGlobalState();
    const [name, setName] = useState();
    const history = useHistory();
    const [status, setStatus] = useState();
    const [end_date, setEnd_date] = useState();
    const [user_id, setUser_id] = useState();
    const tableRef = useRef();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        getData();
    }, [])

    const getData = useCallback(() => {
        action.getData("/api/people-hrd/table", {
        }, null, 'loadingGrid', true).then((resp) => {
            if (resp.code === "00") {
                setData_page(resp.data_page);
            }
        })
    }, []);

    function changeStatusOnclick(data) {
        setName(data.name);
        setUser_id(data.user_id);
        setStatus(data.status_profil);
        setEnd_date(data.end_date);
        action.toggleModal("modalChangeStatus")
    }
    function successUpdate() {
        action.toggleModal('modalChangeStatus');
        getData();
    }
    const customCellRender = (e) => {
        if (e.column.name === "name") {
            return (
                <Link to={`/admin/people/view/${e.data?.user_id}`} target="_blank">
                    <span className='text-wrap'>{e.value}</span>
                </Link>
            )
        } else if (e.column.name === "status_profil") {

            return (
                <div className="text-center">
                    {
                        status_pegawai(e.value)
                    }
                </div>
            )
        } else if (e.column.name === "end_date") {
            return (
                <>
                    {
                        (e.data.status_profil === 'Retired' || e.data.status_profil === 'Contract') ? (
                            <span className="p-1 text-wrap d-blok">{moment(e.value).format('YYYY-MM-DD')}</span>
                        ) : (
                            <span className="p-1 text-wrap d-blok"> - </span>
                        )
                    }
                </>
            )
        } else if (e.column.name === "join_date") {
            return (
                <>
                    {
                        e.value && (
                            <span>{moment(e.value).format("YYYY-MM-DD")}</span>
                        )
                    }
                </>
            )
        } else if (e.column.name === "gender") {
            return (
                <span className='text-wrap'>{e.value === "Laki" ? "Laki - Laki" : e.value}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function searchChange(value) {
        if (tableRef) {
            tableRef.current.instance.searchByText(value)
        }
    }
    return (
        <>
            <Modal
                name="modalChangeStatus"
                baseColor={false}
            >
                <ModalChangeStatus name={name} end_dates={end_date} user_id={user_id} status={status} onSuccess={successUpdate} />
            </Modal>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/svg/open-people.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total People</span>
                                    <span className="d-block"><strong>{state.data_box?.total}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-success" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PWTT</span>
                                    <span className="d-block"><strong>{state.data_box?.active}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Mitra Kerja</span>
                                    <span className="d-block"><strong>{state.data_box?.contract}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-purple" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PWT</span>
                                    <span className="d-block"><strong>{state.data_box?.pwt}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Retired</span>
                                    <span className="d-block"><strong>{state.data_box?.retired}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Non Active</span>
                                    <span className="d-block"><strong>{state.data_box?.non_active}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5 px-5">
                        <div className="col-md-12">
                            <GridDx
                                ref={tableRef}
                                searchForm={true}
                                cellRender={customCellRender}
                                loading={state.loadingGrid}
                                data={state.data_table}
                                columns={columns}
                                withAction={true}
                                pagination={true}
                                orderingDrag={false}
                                actions={[
                                    {
                                        name: "View",
                                        action: (v, c) => { window.open("/admin/people/view/" + v?.user_id, '_blank') }
                                    },
                                    {
                                        name: "Edit Status Pekerja",
                                        action: (v, c) => { changeStatusOnclick(v) }
                                    }
                                ]}
                                currentPage
                                show
                            >

                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})