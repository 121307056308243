import React from 'react';
import useProjectProfile from './useProjectProfile';

const DrawdownBudget = () => {
    const { pfState } = useProjectProfile()
    return (
        <div className="page-content-pp">
            <div className="row mt-3">
                <div className="col">
                    <table style={{ textAlign: "left", width: "100%", marginTop: "10px" }} border="1">
                        <thead>
                            <tr style={{ backgroundColor: "#cccccc" }}>
                                <th style={{ padding: "10px" }}><center><span className="normal">#</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Year</span></center></th>
                                <th style={{ padding: "10px" }}><span className="normal">Description</span></th>
                                <th style={{ padding: "10px" }}><span className="normal">Cost Element</span></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Jan</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Feb</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Mar</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Apr</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">May</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Jun</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Jul</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Aug</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Sep</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Oct</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Nov</span></center></th>
                                <th style={{ padding: "10px" }}><center><span className="normal">Dec</span></center></th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                pfState.data?.data_view_budget?.map((y) => (
                                    y?.data.map((bud, k) => (
                                        <tr key={k}>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{k + 1}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.year}</span></center></td>
                                            <td style={{ padding: "10px" }}><span className="normal">{bud.description}</span></td>
                                            <td style={{ padding: "10px" }}><span className="normal">{bud.cost_element_name}</span></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_jan || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_feb || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_mar || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_apr || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_may || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_jun || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_jul || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_aug || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_sep || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_oct || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_nov || 0}</span></center></td>
                                            <td style={{ padding: "10px" }}><center><span className="normal">{bud.plan_dec || 0}</span></center></td>
                                        </tr>
                                    ))
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr style={{ backgroundColor: "#cccccc" }}>
                                <td colSpan="4"><center><span className="normal"><b>TOTAL</b></span></center></td>
                                <td colSpan="14"><center><span className="normal"><b>$
                                    {
                                        pfState.data?.data_view_budget?.map((y) => (
                                            y?.data?.reduce((sum, cur, i) =>
                                                sum + ((cur.plan_jan || 0) +
                                                    (cur.plan_feb || 0) +
                                                    (cur.plan_mar || 0) +
                                                    (cur.plan_apr || 0) +
                                                    (cur.plan_mar || 0) +
                                                    (cur.plan_may || 0) +
                                                    (cur.plan_jun || 0) +
                                                    (cur.plan_jul || 0) +
                                                    (cur.plan_aug || 0) +
                                                    (cur.plan_sep || 0) +
                                                    (cur.plan_oct || 0) +
                                                    (cur.plan_nov || 0) +
                                                    (cur.plan_dec || 0))
                                                , 0))
                                        ).reduce((s, t) => s + t, 0)
                                    }
                                </b> (Ribu USD) </span></center></td>
                            </tr>


                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default DrawdownBudget;