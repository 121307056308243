import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import './header-list.css';

export default memo(function ({ title, optionList = [], someText, customOption }) {
    const [state, dispatch, action] = useGlobalState();
    return (
        <React.Fragment>
            <div className="m-subheader" style={{ marginBottom: 10 }}>
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="d-md-flex align-items-center">
                            <span className={optionList.length > 0 ? "d-md-flex titlePage border-right-custom mb-3 mb-sm-3" : "d-md-flex titlePage mb-3 mb-sm-3"}>
                                {title}
                            </span>
                            {
                                optionList.map((opt, i) => (
                                    <React.Fragment key={i}>
                                        <div className="containerGroup">
                                            {
                                                opt.withLabel ? (
                                                    <div className="m-0 p-0 textsubtitle">
                                                        {opt.label}
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className="py-2">
                                            {
                                                Array.isArray(opt.option) ? (

                                                    <div className="mx-md-1 mx-sm-0 px-sm-0 ">
                                                        <select className="form-control form-control-sm textsubtitle" value={opt.value} onChange={(e) => opt.onChange ? opt.onChange(e.target.value) : null}>
                                                            {
                                                                opt.option.map((op, i) => (
                                                                    <option key={i} value={op.value}>{op.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </React.Fragment>

                                ))
                            }
                            {
                                customOption
                            }
                        </div>
                    </div>
                    <div className="col-md-3 text-md-right d-none d-md-block textsubtitle">
                        {/* <Link to="/home">Home</Link> */}
                        <span>{someText}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
});