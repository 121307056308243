import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import SVG from "react-inlinesvg";
// import { useHistory } from 'react-router-dom';
import { GridDx, SwitchBtn } from '../../components';
import useSwakelola from './useSwakelola';

const column_detail = [
    { fieldName: 'judul', caption: 'Judul', width: 300 },
    { fieldName: 'show_create', caption: 'Date Create' },
    { fieldName: 'show_creator', caption: 'Creator' },
    { fieldName: 'show_end_status', caption: 'Status Expired' },    
    { fieldName: 'nama_pihak3', caption: 'Partner', onExport: (e) => (e?.nama_pihak3 || '').split(',').map(d => " - " + d).join(`\u2029`) },
    { fieldName: 'fungsi', caption: 'Fungsi VP' },
    { fieldName: 'status_swakelola', caption: 'Status' },
    { fieldName: 'periode', caption: 'Periode Proyek', onExport: (e) => `${e.show_start} - ${e.show_end}` },
    { fieldName: 'owner_est', caption: 'Owner Estimate' },
    { fieldName: 'nilai_kontrak_terbaru', caption: 'Nilai Kontrak (Juta IDR)' },
    { fieldName: 'totalReal', caption: 'Total Pembayaran (Juta IDR)' },
    { fieldName: 'presentasePlanReal', caption: '% Realisasi' },

]
const column_plan = [
    { fieldName: 'judul', caption: 'Judul', width: 300 },
    { fieldName: 'fungsi', caption: 'Fungsi VP' },
    { fieldName: 'status_swakelola', caption: 'Status' },
    { fieldName: '1', caption: '1', onExport: (e) => ` ${e?.plan_1} \u2029 ${e?.real_1} `, allowSorting: false, allowFiltering: false, },
    { fieldName: '2', caption: '2', onExport: (e) => ` ${e?.plan_2} \u2029 ${e?.real_2} `, allowSorting: false, allowFiltering: false },
    { fieldName: '3', caption: '3', onExport: (e) => ` ${e?.plan_3} \u2029 ${e?.real_3} `, allowSorting: false, allowFiltering: false },
    { fieldName: '4', caption: '4', onExport: (e) => ` ${e?.plan_4} \u2029 ${e?.real_4} `, allowSorting: false, allowFiltering: false },
    { fieldName: '5', caption: '5', onExport: (e) => ` ${e?.plan_5} \u2029 ${e?.real_5} `, allowSorting: false, allowFiltering: false },
    { fieldName: '6', caption: '6', onExport: (e) => ` ${e?.plan_6} \u2029 ${e?.real_6} `, allowSorting: false, allowFiltering: false },
    { fieldName: '7', caption: '7', onExport: (e) => ` ${e?.plan_7} \u2029 ${e?.real_7} `, allowSorting: false, allowFiltering: false },
    { fieldName: '8', caption: '8', onExport: (e) => ` ${e?.plan_8} \u2029 ${e?.real_8} `, allowSorting: false, allowFiltering: false },
    { fieldName: '9', caption: '9', onExport: (e) => ` ${e?.plan_9} \u2029 ${e?.real_9} `, allowSorting: false, allowFiltering: false },
    { fieldName: '10', caption: '10', onExport: (e) => ` ${e?.plan_10} \u2029 ${e?.real_10} `, allowSorting: false, allowFiltering: false },
    { fieldName: '11', caption: '11', onExport: (e) => ` ${e?.plan_11} \u2029 ${e?.real_11} `, allowSorting: false, allowFiltering: false },
    { fieldName: '12', caption: '12', onExport: (e) => ` ${e?.plan_12} \u2029 ${e?.real_12} `, allowSorting: false, allowFiltering: false },
    { fieldName: 'total', caption: 'Total (Juta IDR)', onExport: (e) => ` ${e?.totalPlan} \u2029 ${e?.totalReal} `, allowSorting: false, allowFiltering: false },
]
const ListSwakelola = ({ reporting = false, editing = false, menu_admin = false }) => {
    // const history = useHistory();
    const {
        swaAction,
        swaState
    } = useSwakelola();
    useEffect(() => {
        if (reporting === false) {
            if (menu_admin === true) {
                swaAction.getTablePlanAdmin('');
            } else {
                swaAction.getTablePlan('');
            }
        } else {
            swaAction.getTableReport();
        }
    }, [])
    return (
        <>
            {
                reporting === false && (
                    <div className="row">
                        <div className="col-12">

                            <Card
                                className="m-0 p-0  mb-5 customShadow bg-primary"
                            >
                                <Card.Body className="p-3">
                                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                                            <div className="d-flex border-right-custom">
                                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                                    <SVG src="/media/svg/icons/Shopping/Cart1.svg" />
                                                </span>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">Total Swakelola</span>
                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.jumlahTotal}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="shadow rounded mt-1 bg-purple" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                                    </div>
                                                </div>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">Validasi</span>
                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.jumlahValidasi}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                                    </div>
                                                </div>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">Revisi</span>

                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.jumlahRevisi}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                                    </div>
                                                </div>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">Pengadaan</span>

                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.jumlahPengadaan}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="shadow rounded mt-1 bg-primary" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                                    </div>
                                                </div>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">K. Berjalan</span>

                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.kontrakBerjalan}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                                    </div>
                                                </div>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">Addendum</span>

                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.addendum}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 px-md-0 col-6 mb-5">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                                    </div>
                                                </div>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">Rejected</span>

                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.rejected}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                                    </div>
                                                </div>
                                                <div className="pl-3 text-white">
                                                    <span className="d-block">Selesai</span>

                                                    {/* <span> Nilai Investasi</span> */}
                                                    <div className="pt-3">
                                                        <span className="d-block"><strong>{swaState.dataHeader?.Selesai}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                )
            }
            <div className="row">
                <div className="col-md-12">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-2">
                            <div className="row justify-content-between mb-3">
                                <div className="col-md-4 mb-3">
                                    {
                                        reporting === false &&
                                        <>
                                            {
                                                (swaState.switchBtn === false) && (
                                                    <div className="formSearch">
                                                        <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => swaAction.detailSearch(e.target.value)} />
                                                        <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                                                    </div>
                                                )
                                            }
                                            {
                                                (swaState.switchBtn === true) && (
                                                    <div className="formSearch">
                                                        <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => swaAction.realSearch(e.target.value)} />
                                                        <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                                                    </div>
                                                )
                                            }
                                        </>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    {
                                        reporting === false &&
                                        (
                                            <div className="d-lg-flex d-md-flex d-block justify-content-end">
                                                {
                                                    swaState.switchBtn === true && (
                                                        <select style={{ width: 100 }} className="form-control form-control-sm mb-3" value={swaState.dataTable?.year_filter} onChange={(e) => swaAction.yearFilterChange(e.target.value)}>
                                                            {
                                                                [...(swaState.dataTable?.data_tahun || '')].map((d, i) => (
                                                                    <option key={i} value={d}>{d}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    )
                                                }
                                                <div className="my-auto d-inline-flex mx-1">
                                                    <label className={`my-auto mx-1`}>Detail</label>
                                                    <SwitchBtn className='my-auto' value={swaState.switchBtn} onChange={(e) => swaAction.switchBtnChange(e, reporting, menu_admin)} />
                                                    <label className={`my-auto mx-1`}>Plan/Pembayaran</label>
                                                    {
                                                        (editing === true && menu_admin === true) && (
                                                            <div className="border-left mx-2 px-2">
                                                                <button className="btn btn-primary btn-sm"
                                                                    onClick={() => swaAction.buttonAddClick()}
                                                                >
                                                                    <i className="fa fa-plus icon-nm"></i>
                                                                    Swakelola
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (editing === true && menu_admin === false) && (
                                                            <div className="border-left mx-2 px-2">
                                                                <button className="btn btn-primary btn-sm"
                                                                    style={{ width: 100 }}
                                                                    onClick={() => swaAction.buttonAddClickResearcher()}
                                                                >
                                                                    <i className="fa fa-plus icon-nm"></i>
                                                                    Swakelola
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                reporting === true && (
                                    <GridDx
                                        searchForm={true}
                                        ref={swaState.detailRef}
                                        columns={column_detail}
                                        loading={swaState.loadingTable}
                                        cellRender={swaAction?.cellRenderGridDetailReport}
                                        data={swaState.dataTable?.tabelDetail}
                                        filenameExport={`Swakelola Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        selectionMode={"multiple"}
                                        exportMode="selection"
                                        exportExcel={true}
                                        // exportPdf={true}
                                        settingColumn={true}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show

                                    />
                                )
                            }
                            {
                                (swaState.switchBtn === false && reporting === false) && (
                                    <GridDx
                                        //searchForm={reporting}
                                        ref={swaState.detailRef}
                                        columns={menu_admin ? [{ fieldName: 'action', caption: 'Action', width: 100 }, ...column_detail] : column_detail}
                                        loading={swaState.loadingTable}
                                        cellRender={swaAction?.cellRenderGridDetail}
                                        data={swaState.dataTable?.tabelDetail}
                                        filenameExport={`Swakelola Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        selectionMode={reporting ? "multiple" : false}
                                        exportMode="selection"
                                        exportExcel={reporting}
                                        exportPdf={reporting}
                                        settingColumn={reporting}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                        // withAction={editing && menu_admin}
                                        withAction={false}
                                        withParamAction={true}
                                        paramAction={(e) => {
                                            console.log(e.data?.status_swakelola);
                                            return e.data?.status_swakelola === "Selesai" ? false : true;
                                        }}
                                        actions={[
                                            {
                                                name: "Delete",
                                                icon: "fa fa-trash-alt icon-nm text-danger",
                                                action: (v, c) => { swaAction.buttonDeleteClick(v?.id) }
                                            },
                                            {
                                                name: "Edit Data Swakelola",
                                                icon: "fa fa-pencil-alt icon-nm text-secondary",
                                                action: (v, c) => { swaAction.buttonEditClick(v?.id) }
                                            },
                                            {
                                                name: "Update Data Kontrak dan Pembayaran",
                                                icon: "fa fa-pencil-alt icon-nm text-secondary",
                                                action: (v, c) => { window.open(`/admin/swakelola-detail/${v?.id}`, '_blank') }
                                            }

                                        ]}
                                    />
                                )
                            }
                            {
                                (swaState.switchBtn === true && reporting === false) && (
                                    <GridDx
                                        ref={swaState.realRef}
                                        columns={menu_admin ? [{ fieldName: 'action', caption: 'Action', width: 100 }, ...column_plan] : column_plan}
                                        loading={swaState.loadingTable}
                                        cellRender={swaAction?.cellRenderGridDetail}
                                        data={swaState.dataTable?.tabelPlan}
                                        filenameExport={`Swakelola Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        selectionMode={reporting ? "multiple" : false}
                                        exportMode="selection"
                                        exportExcel={reporting}
                                        exportPdf={reporting}
                                        settingColumn={reporting}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                        // withAction={editing && menu_admin}
                                        withAction={false}
                                        withParamAction={false}
                                        paramAction={(e) => {
                                            return e.data?.status_swakelola === "Selesai" ? false : true;
                                        }}
                                        actions={[
                                            {
                                                name: "Delete",
                                                icon: "fa fa-trash-alt icon-nm text-danger",
                                                action: (v, c) => { swaAction.buttonDeleteClick(v?.id) }
                                            },
                                            {
                                                name: "Edit Data Swakelola",
                                                icon: "fa fa-pencil-alt icon-nm text-secondary",
                                                action: (v, c) => { swaAction.buttonEditClick(v?.id) }
                                            },
                                            {
                                                name: "Update Data Kontrak dan Pembayaran",
                                                icon: "fa fa-pencil-alt icon-nm text-secondary",
                                                action: (v, c) => { window.open(`/admin/swakelola-detail/${v?.id}`, '_blank') }
                                            }
                                        ]}
                                    />
                                )
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default ListSwakelola;