import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";
// import { pathProfile } from "../../../../../app/app.config";
import { useGlobalState } from "../../../../../app/contexts/useGlobalState";
import useLocalStorage from "../../../../../app/contexts/useLocalStorage";
import { AuthConsumer } from "../../../../../app/service/authenticationProvider";


export function QuickPanelUser() {
    const [state, dispatch, actions] = useGlobalState();
    const [token] = useLocalStorage("token", null)
    const [idaman] = useLocalStorage("idaman", null)
    const { pathname } = useLocation()
    function isRouteAdmin() {
        let path = pathname.split("/");
        if (path.length >= 2)
            return (path[1] === "admin")
        return false
    }
    const first_menu = () => {
        const parent = [...(state?.data_menu_admin?.data_menu || [])][0];
        const fchild = [...(parent?.childs || [])][0];
        const schild = [...(fchild?.childs || [])][0];
        const tchild = [...(schild?.childs || [])][0];
        var url = '';
        if (tchild) {
            url = tchild?.url;
        } else if (schild) {
            url = schild?.url;
        } else if (fchild) {
            url = fchild?.url;
        } else {
            url = parent?.url
        }
        // console.log(parent, fchild, schild, tchild, url);
        return `/admin${url}`
    };

    useEffect(() => {
        if (token) {
            actions.getData('/api/me').then((resp) => {
                if (resp.code === "00") {
                    dispatch({
                        type: "getSuccess",
                        payload: { dataUser: resp.data.data_user_me?.[0], loadingGet: false }
                    })
                }
            })
        }
    }, [])
    return (
        <div id="kt_quick_panel_user" className="offcanvas offcanvas-right pt-5 pb-10">
            <div className="row mx-5">
                <div className="col-md-12 d-flex justify-content-between">
                    <h5>User Profile</h5>
                    <a
                        href="#"
                        className="btn btn-xs btn-icon btn-light btn-hover-primary"
                        id="kt_quick_panel_user_close"
                    >
                        <i className="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
            </div>
            <div className="row mt-5 mx-5">
                <div className="col-4">
                    <div className="symbol symbol-70 mr-3">
                        <img alt="Pic" src={state.dataUser?.photo_location}
                            onError={(e) => { e.target.onerror = null; e.target.src = "/media/svg/icons/General/User.svg" }} />
                    </div>
                    {/* <img className="bg-secondary" width="100%" height="100px" src="/media/svg/icons/General/User.svg" /> */}
                </div>
                <div className="col-8">
                    <span className="d-block py-1"><strong>{state.dataUser?.name}</strong></span>
                    <span className="d-blok py-1">{state.dataUser?.role_name}</span>
                    <div className="d-inline-flex">
                        <span className="mt-1"><i className="fa fa-envelope icon-nm mr-2 text-primary"></i></span>
                        <span className="d-block text-primary py-1" style={{ wordBreak: 'break-all' }}> {state.dataUser?.email}</span>
                    </div>
                </div>
            </div>
            <hr style={{ borderTop: '1px dashed #00000029', marginLeft: '30px', marginRight: '30px' }} />
            <div className="d-block mb-3" style={{ marginLeft: '30px', marginRight: '30px' }}>
                {
                    (state.dataUser?.is_admin === 1 && [...(state?.data_menu_admin?.data_menu || [])].length > 0) && (
                        <a href={isRouteAdmin() ? "/" : first_menu()}>
                            <div className="d-flex">
                                <span className="p-3 rounded svg-icon svg-icon-lg svg-icon-warning" style={{ backgroundColor: '#EDF3F8' }}>
                                    <SVG src="/media/svg/icons/Code/Settings4.svg" />
                                </span>
                                <span className="pl-3 text-dark align-self-center">
                                    {isRouteAdmin() ? "Back to SPIIRIT" : "SPIIRIT Admin Centre"}
                                </span>
                            </div>
                        </a>
                    )
                }
            </div>
            <div className="d-block mb-3" style={{ marginLeft: '30px', marginRight: '30px' }}>
                <Link to={"/profile"}>
                    <div className="d-flex">
                        <span className="p-3 rounded svg-icon svg-icon-lg svg-icon-success" style={{ backgroundColor: '#EDF3F8' }}>
                            <SVG src="/media/svg/icons/General/Notification2.svg" />
                        </span>
                        <span className="pl-3 text-dark align-self-center">
                            My Profile
                        </span>
                    </div>
                </Link>
            </div>
            <div className="d-block mb-3" style={{ marginLeft: '30px', marginRight: '30px' }}>
                <Link to={"/changePassword"}>
                    <div className="d-flex">
                        <span className="p-3 rounded svg-icon svg-icon-lg svg-icon-danger" style={{ backgroundColor: '#EDF3F8' }}>
                            <SVG src="/media/svg/icons/General/Lock.svg" />
                        </span>
                        <span className="pl-3 text-dark align-self-center">
                            Change Password
                        </span>
                    </div>
                </Link>
            </div>
            <div className="d-block mb-3" style={{ marginLeft: '30px', marginRight: '30px' }}>
                <Link
                    // to={'/comming-soon'}
                    to={"/faq"}
                >
                    <div className="d-flex">
                        <span className="p-3 rounded svg-icon svg-icon-lg svg-icon-primary" style={{ backgroundColor: '#EDF3F8' }}>
                            <SVG src="/media/svg/icons/Communication/Chat-smile.svg" />
                        </span>
                        <span className="pl-3 text-dark align-self-center">
                            FAQ
                        </span>
                    </div>
                </Link>

            </div>
            <div className="d-block mb-3" style={{ marginLeft: '30px', marginRight: '30px' }}>
                <Link
                    to={'/comming-soon'}
                >
                    <div className="d-flex">
                        <span className="p-3 rounded svg-icon svg-icon-lg svg-icon-warning" style={{ backgroundColor: '#EDF3F8' }}>
                            <SVG src="/media/svg/icons/Tools/Road-Cone.svg" />
                        </span>
                        <span className="pl-3 text-dark align-self-center">
                            About Version
                        </span>
                    </div>
                </Link>

            </div>
            <hr style={{ borderTop: '1px dashed #00000029', marginLeft: '30px', marginRight: '30px' }} />
            <div className="d-block mb-3" style={{ marginLeft: '30px', marginRight: '30px' }}>
                <AuthConsumer>
                    {({logout}) => (
                        <button className="btn btn-sm btn-light-danger" onClick={() => {
                            if(idaman === true || idaman === 'true'){
                                actions.doLogout();
                                logout();
                            }else{
                                actions.doLogout();                                
                            }
                        }}>Logout</button>
                    )}
                </AuthConsumer>
            </div>
        </div>
    )
}