import React from 'react';
import './footer_page.css';
import LogoSpiiritWhite from '../../images/logospiiritwhite.png';
import { alamat, desc_spiirit_footer } from '../constanta'
import useIndexLandPage from '../../use_index';
import BgFooter from './image/LP-Footer-no-bottom.png';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../../../contexts/useGlobalState';

function FooterPage(props) {
    const { indAction: {
        toHome = () => null,
        setScrollElement = () => null,
        berandaScroll = () => null,
    }, indState: {
        pageState = {},
        displayLayout = false
    } } = useIndexLandPage();
    const history = useHistory();
    const [, , actions] = useGlobalState();
    return (
        // <div className={`bg-transparent footer-base-card ${displayLayout === false ? "d-none" : ''}`}>
        //     <div className={`container-fluid footer-card py-5`}>
        //         <div className='container'>
        //             <div className='row mb-5'>
        //                 <div className='col-lg-6 col-md-6 col-auto mb-3 d-flex flex-column'>
        //                     <div className='icon-wrapper mb-3'>
        //                         <img src={LogoSpiiritWhite} className='icon-img'></img>
        //                     </div>
        //                     <div className='text-wrap mb-5'>
        //                         {desc_spiirit_footer}
        //                     </div>
        //                 </div>
        //                 <div className='col-lg-3 col-md-3 col-auto mb-3 text-start'>
        //                     <span className='d-block font-weight-boldest'>Laboratorium</span>
        //                     <span className='d-block mb-5'>{alamat.laboratorium}</span>
        //                     <span className='d-block font-weight-boldest'>Kantor</span>
        //                     <span className='d-block mb-5'>{alamat.kantor}</span>
        //                     <span className='d-block font-weight-boldest'>Contact</span>
        //                     <span className='d-block mb-5'>{alamat.contact}</span>
        //                 </div>
        //                 <div className='col-lg-3 col-md-3 col-auto mb-3 text-start'>
        //                     <span className='d-block font-weight-boldest mb-3'>Sitemap</span>
        //                     {
        //                         pageState?.type === 'HomeLandPage' ? <>
        //                             <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => berandaScroll()}>Beranda</span>
        //                             <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => setScrollElement('product')}>Product</span>
        //                             <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => setScrollElement('achievement')}>Achievement</span>
        //                             <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => setScrollElement('event')}>Event</span>
        //                             <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => setScrollElement('rti')}>Tentang RTI</span>
        //                         </> : <>
        //                             <span className="nav-link font-weight-boldest px-0"
        //                                 onClick={toHome}
        //                             >Beranda</span>
        //                             <span className="nav-link disabled font-weight-boldest px-0">Product</span>
        //                             <span className="nav-link disabled font-weight-boldest px-0">Achievement</span>
        //                             <span className="nav-link disabled font-weight-boldest px-0">Event</span>
        //                             <span className="nav-link disabled font-weight-boldest px-0">Tentang RTI</span>
        //                         </>
        //                     }
        //                 </div>
        //             </div>
        //             <span style={{ bottom: 20 }} className='d-block mr-1 text-muted position-absolute'>Copyright &copy; 2022 SPIIRIT. All Rights Reserved.</span>
        //         </div>
        //     </div>
        // </div>

        // new ui
        <div className='position-relative overflow-hidden'>
            <img src={BgFooter} className='footer-card-new bg-white'></img>
            <div className='row mx-auto text-white container' style={{ paddingTop: '5%', marginBottom: '75px' }}>
                <div className='col-12 col-md-4 col-lg-4'>
                    <div className='d-flex flex-column'>
                        <div className='icon-wrapper mb-3'>
                            <img src={'/images/logo-spiirit registered putih.png'} className='icon-img'></img>
                        </div>
                        <div className='text-wrap mb-5 text-white'>
                            {desc_spiirit_footer}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4 col-lg-4'>
                    <div>
                        <div className='d-flex'>
                            <div className='d-inline-flex bg-white rounded customShadow p-3 mr-2 mb-auto'>
                                <i className='fas fa-map-marker-alt icon-nm m-auto text-blue'></i>
                            </div>
                            <div>
                                <p><b>Laboratorium</b></p>
                                <p>{alamat.laboratorium}</p>
                                <p><b>Kantor</b></p>
                                <p>{alamat.kantor}</p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='d-inline-flex bg-white rounded customShadow p-3 mr-2 mb-auto'>
                                <i className='fas fa-envelope icon-nm m-auto text-blue'></i>
                            </div>
                            <div>
                                <p>{alamat.contact}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4 col-lg-4' style={{ paddingLeft: '10%' }}>
                    <span className='d-block font-weight-boldest mb-3'>Sitemap</span>
                    {
                        pageState?.type === 'HomeLandPage' ? <>
                            <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => history.push('/landing-page/beranda')}>Beranda</span>
                            <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => {
                                actions.setForm('product_type', 0);
                                history.push('/landing-page/product');
                            }}>Product</span>
                            <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => history.push('/landing-page/achievement')}>Achievement</span>
                            <span className="nav-link font-weight-boldest pointer-style px-0" onClick={() => history.push('/landing-page/event')}>Event</span>
                            {/* <span className="nav-link font-weight-boldest pointer-style px-0" >Tentang RTI</span> */}
                        </> : <>
                            <span className="nav-link font-weight-boldest px-0"
                                onClick={() => history.push('/landing-page/beranda')}
                            >Beranda</span>
                            <span className="nav-link font-weight-boldest px-0" onClick={() => {
                                actions.setForm('product_type', 0);
                                history.push('/landing-page/product');
                            }}>Product</span>
                            <span className="nav-link font-weight-boldest px-0" onClick={() => history.push('/landing-page/achievement')}>Achievement</span>
                            <span className="nav-link font-weight-boldest px-0" onClick={() => history.push('/landing-page/event')}>Event</span>
                            {/* <span className="nav-link disabled font-weight-boldest px-0">Tentang RTI</span> */}
                        </>
                    }
                </div>
                <div className='col-12'>

                </div>
            </div>
            <div className='p-4 position-absolute left-0 bottom-0 w-100 text-white' style={{ backgroundColor: '#f51d33' }}>
                <div className='text-center'>Copyright &copy; 2022 SPIIRIT. All Rights Reserved.</div>
            </div>
        </div>
    );
}

export default FooterPage;