import React, { forwardRef, memo, useEffect, useState } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Scrolling,
  Grouping,
  GroupPanel,
  RowDragging,
  HeaderFilter,
  ColumnChooser,
  ColumnFixing,
  Selection,
  Export,
  MasterDetail,
  Editing
} from "devextreme-react/data-grid";
import "./style.css";
import DropDownButton from "devextreme-react/drop-down-button";
import { Link } from "react-router-dom";
import { ExportExcel, SkeletonLoading } from "..";
import CustomStore from 'devextreme/data/custom_store';
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Modal } from "react-bootstrap";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { Toolbar } from "devextreme-react/gantt";

export default memo(
  forwardRef(function (
    {
      data = [],
      columns = [],
      pagination = true,
      withAction = false,
      actions = [],
      dataGroup = false,
      orderingDrag = false,
      cellRender,
      onReorder = (data) => null,
      settingColumn = false,
      columnResizing = false,
      loading,
      selectionMode = false,
      onSelectionData = () => null,
      exportPdf = false,
      exportExcel = false,
      exportMode = "all",
      filenameExport = "File",
      defaultColumn = null,
      searchForm = false,
      searchFormWidth = "300px",
      toolbar = null,
      masterDetail = false,
      ComponenMasterDetail,
      defaultSelected = [],
      recordKey = 'id',
      allowUpdating = false,
      allowDeleting = false,
      allowAdding = false,
      onRowInserting = (e) => { console.log(e) },
      onRowUpdating = (e) => { console.log(e) },
      onRowRemoving = (e) => { console.log(e) },
      headerCellRender = (e) => (<p className="text-wrap">{e.column.caption}</p>),
      editingMode = 'row',
      children,
      onRowExpanded,
      className = '',
      repaintChangesOnly = false,
      onEditorPreparing,
      withParamAction = false,
      paramAction = (e) => (true),
      toolbarPosition = "right",
      filterOperations,
      height = 600
    },
    ref
  ) {
    // const datas = new CustomStore({
    //   key: recordKey,
    //   load: () => data.map((v, key) => ({ no: key + 1, ...v })),
    //   insert: (values) => {
    //     console.log(values);
    //   },
    //   update: (key, values) => {
    //     console.log(key, values)
    //   },
    //   remove: (key) => {
    //     console.log(key)
    //   }
    // })
    const datas = data.map((v, key) => ({ no: key + 1, ...v }));

    const clickBtn = function (item, ev) {
      const { name } = ev.itemData;
      const fn = actions.find((v) => v.name === name)?.action;
      if (fn) {
        fn(item, ev);
      }
      return null;
    };

    const [columnShow, setColumnShow] = useState([]);

    const [columnSelected, setColumnSelected] = useState([])

    const [modalShow, setModalShow] = useState(false)

    const [dataSelected, setDataSelected] = useState({
      dataSelected: [],
      currentSelected: [],
      countSelected: 0
    })

    const [search, setSearch] = useState("")

    function searchChange(searchText) {
      if (ref) {
        ref.current.instance.searchByText(searchText)
      }
    }

    useEffect(function () {
      if (defaultSelected.length > 0) {
        const newData = datas.filter(v => {
          const newV = { ...v };
          delete newV["no"];
          return defaultSelected.some(vx => {
            const newX = { ...vx }
            delete newX["no"]
            return JSON.stringify(newV) === JSON.stringify(newX)
          });
        })
        setDataSelected(prev => ({ ...prev.dataSelected, dataSelected: newData }))
      }
    }, [data])

    // function headerCellRender(e) {
    //   return <p className="text-wrap">{e.column.caption}</p>;
    // }
    const onDrop = function (e) {
      const visibleRows = e.component.getVisibleRows();
      const newRows = data;
      const toIndex = e.toIndex;
      const fromIndex = e.fromIndex;
      newRows.splice(fromIndex, 1);
      newRows.splice(toIndex, 0, data[toIndex]);
    };

    const onChangeSelected = function ({
      selectedRowsData,
      currentSelectedRowKeys,
    }) {
      setDataSelected({
        dataSelected: selectedRowsData,
        currentSelected: currentSelectedRowKeys,
        countSelected: selectedRowsData.length,
      });
      return onSelectionData({
        dataSelected: selectedRowsData,
        currentSelected: currentSelectedRowKeys,
        countSelected: selectedRowsData.length,
      });
    };

    useEffect(function () {
      let defColumn = defaultColumn;
      let dataColumnSelected = defaultColumn?.map(v => v.fieldName);
      if (defaultColumn === null) {
        defColumn = columns;
        dataColumnSelected = columns.map(v => v.fieldName)
      }
      setColumnShow(defColumn);
      setColumnSelected(dataColumnSelected)
    }, [columns])
    const setChecked = (columName, isChecked) => {
      let newColumnSelected = columnSelected;
      if (isChecked) {
        newColumnSelected = [...newColumnSelected, columName]
      } else {
        newColumnSelected = newColumnSelected?.filter(v => v !== columName)
      }

      setColumnSelected(newColumnSelected);
    }

    const saveColumnSelected = function () {
      const newColumnShow = columns.filter(({ fieldName }) => columnSelected.some(v => v === fieldName))
      setColumnShow(newColumnShow)
    }

    const resetSelected = function () {
      let dataColumnSelected = columnShow?.map(v => v.fieldName);
      setColumnSelected(dataColumnSelected);
      setModalShow(false);
    }


    function dataArray(data) {
      let str = ""
      data.forEach(function (d, i) {
        // console.log(d)
        if (typeof d === "object") return dataObject(d)
        else return str = `${str} - ${d} \u2029`;
      })
      return str;
    }
    function dataObject(data) {
      let keys = Object.keys(data || {});
      let str = ''
      keys.forEach(function (d, i) {
        if (typeof data[d] === "object") dataObject(d)
        else if (Array.isArray(data[d])) dataArray(data[d])
        else str = `${str} - ${d} : ${data[d]} \u2029`
      })
      return str;
    }

    function exportGrid() {
      let width = 0;
      let columnStyles = []
      let dataColumnSelected = columnShow?.map((v, i) => {
        width = width + (v.width || 50) * 0.628
        columnStyles = {
          ...columnStyles,
          [i]: {
            cellWidth: v.width || 'auto'//(v.width || 50) * 0.628
          }
        }
        return v.caption
      });
      let body = (exportMode === "selection" ? (
        dataSelected?.dataSelected || []
      ) : data
      ).map((data) => columnShow?.map(val => {
        if (data.onExport === null || data.onExport === undefined) {
          if (Array.isArray(data[val.fieldName])) return dataArray(data[val.fieldName])
          else if (typeof data[val.fieldName] === "object") return dataObject(data[val.fieldName])
          else return data[val.fieldName]
        } else if (typeof data.onExport === "function") return data.onExport(data)
        else throw Error(`onExport must be function returning data "String or Integer or Boolean" `)
        // return ""
        // data[val.fieldName]

      }))


      const doc = new jsPDF('landscape', 'px', [(width) + 50, 510]);
      // console.log(columnStyles, columnShow)
      doc.autoTable({
        head: [dataColumnSelected],
        columnStyles,
        // styles: {overflow: 'linebreak', columnWidth: 'wrap'},
        body
        // body: [
        //   ['David', 'david@example.com', 'Sweden'],
        //   ['Castille', 'castille@example.com', 'Spain'],
        //   // ...
        // ],
      })
      doc.save(`${filenameExport}`);
      // const dataGrid = ref.current.instance;

      // exportDataGridToPdf({
      //   jsPDFDocument: doc,
      //   component: dataGrid
      // }).then(() => {
      //   doc.save('Customers.pdf');
      // });
    }
    function renderCellRowNum(rowInfo) {
      return (
        <span>{rowInfo?.rowIndex + 1}</span>
      )
    }
    return (
      <>

        {/* <div className="col-md-12">
        <div className="mb-2" style={{width:"100%"}}>
            <button className="btn btn-sm btn-outline-danger btn-export"> <i className="far fa-file-pdf"></i> Export To PDF <span className="badge badge-pill badge-danger">{dataSelected.countSelected}</span> </button>
            <button className="btn btn-sm btn-outline-success btn-export"> <i className="far fa-file-excel"></i> Export To Excel <span className="badge badge-pill badge-success">{dataSelected.countSelected}</span> </button>
        </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-12"> */}
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          {/* <Modal.Dialog> */}

          <Modal.Header closeButton={true}>
            <Modal.Title> Setting Column </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              columns.map(({ caption, fieldName }, key) => (
                <div className="row" key={key}>
                  <div className="col-md-12">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={columnSelected?.some(v => v === fieldName)}
                          onChange={(e) => setChecked(fieldName, e.target.checked)}
                          value={caption}
                        />
                      }
                      label={caption}
                    />
                  </div>
                </div>
              ))
            }
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-sm btn-primary" onClick={saveColumnSelected}> Tampilkan</button>
            <button className="btn btn-sm btn-secondary" onClick={() => {
              resetSelected()
              setModalShow(false)
            }}>Close</button>
          </Modal.Footer>
          {/* </Modal.Dialog> */}
        </Modal>
        {
          ((toolbar !== null) || settingColumn || exportPdf || exportExcel || searchForm) && <div style={{ width: "100%", marginBottom: 15, paddingBottom: 25 }}>
            {searchForm && <div className="formSearch" style={{ width: searchFormWidth, float: "left", marginBottom: 15 }}>
              <input onChange={(e) => searchChange(e.target.value)} className="form-control form-control-sm" placeholder="Cari" />
              <span className="formSearchIcon"><i className="fa fa-search"></i></span>
            </div>
            }
            {
              toolbar != null && (
                <div style={{
                  width: "max-content", float: toolbarPosition
                }}>{toolbar}</div>
              )
            }
            {
              exportPdf && <button onClick={exportGrid} className="btn btn-sm btn-outline-danger btn-export mb-3">
                <i className="far fa-file-pdf"></i>
                Export To PDF {
                  exportMode === "selection" && <span className="badge badge-pill badge-danger">
                    {dataSelected.countSelected}
                  </span>
                }
              </button>
            }
            {
              exportExcel && <ExportExcel
                fileName={`${filenameExport}`}
                sheets={[
                  {
                    data: exportMode === "selection" ? dataSelected.dataSelected : data,
                    sheetName: "sheet1",
                    columns: columnShow
                  }
                ]}
              >
                <button className="btn btn-sm btn-outline-success btn-export mb-3">
                  <i className="far fa-file-excel"></i>
                  Export To Excel {
                    exportMode === "selection" && <span className="badge badge-pill badge-success">
                      {dataSelected.countSelected}
                    </span>
                  }
                </button>
              </ExportExcel>
            }

            {settingColumn && <button onClick={() => setModalShow(true)} className="btn btn-sm btn-outline-primary btn-export mb-3">  <i className="flaticon2-gear"></i> Setting Column </button>}
          </div>
        }
        <div className="table-responsive ts">
          {loading ? (
            <SkeletonLoading type="table" rows={15} cols={columns.length} />
          ) : (
            <DataGrid
              ref={ref}
              // height={height}              
              repaintChangesOnly={repaintChangesOnly}
              onRowInserting={onRowInserting}
              onRowUpdating={onRowUpdating}
              onRowRemoving={onRowRemoving}
              className={`striped fontContent footer-grid ${className}`}
              onRowExpanded={onRowExpanded}
              dataSource={datas}
              showBorders={false}
              showColumnLines={false}
              allowColumnResizing={columnResizing}
              onEditorPrepared={onEditorPreparing}
              columnAutoWidth={true}
              onSelectionChanged={onChangeSelected}
              onExporting={(e) => console.log(e)}
              groupIndex
              selectedRowKeys={dataSelected.dataSelected}
              onSelectedRowKeysChange={(e) => setDataSelected(prev => ({ ...prev, dataSelected: e }))}
              onDataErrorOccurred={() => null}
              onRowPrepared={(e) => {
                if (e.rowType === "data") {
                  if (e.rowIndex % 2 === 0) e.rowElement.style.backgroundColor = "#faf9fc";
                }
              }}
            >
              <Editing
                mode={editingMode}
                allowUpdating={allowUpdating}
                allowDeleting={allowDeleting}
                allowAdding={allowAdding} />
              <HeaderFilter visible={true} />
              {selectionMode && (
                <Selection
                  mode={selectionMode}
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
              )}
              {orderingDrag && (
                <RowDragging
                  allowReordering={true}
                  onReorder={onDrop}
                  dropFeedbackMode="push"
                />
              )}
              {pagination && <Paging defaultPageSize={25} />}
              {pagination && (
                <Pager
                  visible={true}
                  displayMode={'full'}
                  showPageSizeSelector={true}
                  allowedPageSizes={[25, 50, 100, 150]}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              )}
              {dataGroup && <GroupPanel visible={false} />}
              <Grouping autoExpandAll={false} />
              <ColumnFixing enabled={true} />
              {/* <Export enabled={true} /> */}

              <Scrolling />
              {[
                {
                  fieldName: "no",
                  caption: "#",
                  width: 50,
                  allowSorting: false,
                  allowFiltering: false,
                },
                ...(withAction && actions.length > 0
                  ? [
                    {
                      fieldName: "__action",
                      caption: "Action",
                      width: 80,
                      allowSorting: false,
                      allowFiltering: false,
                    },
                  ]
                  : []),
                ...(columnShow === null ? columns : columnShow),
              ].map(
                (
                  {
                    fieldName,
                    caption,
                    format = null,
                    dataType = null,
                    width = null,
                    groupIndex = null,
                    allowSorting = true,
                    allowFiltering = true,
                    headerFilterSource = null,
                    link,
                    url,
                    selectedFilterOperation = '=',
                    calculateFilterExpression,
                    allowEditing = false,
                    visible = true,
                    allowGrouping = null,
                    allowSearch = true
                  },
                  index
                ) =>
                  fieldName === "__action" ? (
                    <Column
                      allowFiltering={false}
                      allowGrouping={false}
                      key={index}
                      allowSorting={false}
                      dataField={fieldName}
                      headerCellRender={headerCellRender}
                      caption={caption}
                      dataType={dataType}
                      allowEditing={allowEditing}
                      format={format}
                      width={width}
                      cellRender={(e) => (
                        <>
                          {
                            withParamAction === true ?
                              paramAction(e) === true ? (
                                <DropDownButton
                                  text=" "
                                  className="bg-primary rounded customColorIcon customColotText"
                                  icon="preferences"
                                  dropDownOptions={{ width: 250 }}
                                  displayExpr="name"
                                  keyExpr="id"
                                  items={actions}
                                  onItemClick={(btn) => {
                                    clickBtn(e.data, btn);
                                  }}
                                />
                              ) : (
                                <></>
                              ) : (
                                <DropDownButton
                                  text=" "
                                  className="bg-primary rounded customColorIcon customColotText"
                                  icon="preferences"
                                  dropDownOptions={{ width: 250 }}
                                  displayExpr="name"
                                  keyExpr="id"
                                  items={actions}
                                  onItemClick={(btn) => {
                                    clickBtn(e.data, btn);
                                  }}
                                />
                              )
                          }
                        </>
                      )}
                    />
                  ) :
                    fieldName === "__no" ? (
                      <Column
                        key={index}
                        allowGrouping={false}
                        allowEditing={allowEditing}
                        dataField={fieldName}
                        allowSorting={allowSorting}
                        allowFiltering={allowFiltering}
                        caption={caption}
                        dataType={dataType}
                        headerCellRender={headerCellRender}
                        format={format}
                        width={width}
                        groupIndex={groupIndex}
                        cellRender={renderCellRowNum}></Column>
                    ) :
                      (
                        <Column
                          allowSearch={true}
                          visible={visible}
                          key={index}
                          allowEditing={allowEditing}
                          dataField={fieldName}
                          allowSorting={allowSorting}
                          allowFiltering={allowFiltering}
                          allowGrouping={allowGrouping}
                          caption={caption}
                          dataType={dataType}
                          headerCellRender={headerCellRender}
                          format={format}
                          width={width}
                          groupIndex={groupIndex}
                          calculateFilterExpression={calculateFilterExpression}
                          filterOperations={filterOperations}
                          selectedFilterOperation={selectedFilterOperation}
                          cellRender={
                            cellRender
                              ? (e) => cellRender(e)
                              : (e) =>
                                link ? (
                                  <Link
                                    target="blank"
                                    to={{
                                      pathname: url + "/" + e.data.id,
                                    }}
                                  >
                                    <span className="text-wrap">
                                      {e.value}
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="text-wrap">{e.value}</span>
                                )
                          }
                        >

                          {headerFilterSource && (
                            <HeaderFilter dataSource={headerFilterSource} />
                          )}
                        </Column>
                      )
              )}
              {children}
              <MasterDetail
                enabled={masterDetail}
                render={(item) => <ComponenMasterDetail data={item} />}

              />
            </DataGrid>
          )}
        </div>
        {/* </div>
        </div> */}
      </>
    );
  })
);
