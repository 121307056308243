import React, { createContext, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { status_pegawai } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';

const peopleRepContext = createContext({
    pReportAction: {},
    pReportState: {}
});

export function PeopleReportProvider({ children }) {
    const [glblState, glblDispatch, glblAction] = useGlobalState();
    const [data_page, setData_page] = useState({});
    const [data, setData] = useState([]);
    const columns = [
        { fieldName: "name", caption: "Nama" },
        { fieldName: "status_profil", caption: "Status", width: 100 },
        { fieldName: "end_date", caption: "End Date", width: 100 },
        { fieldName: "employee_id", caption: "No Peg" },
        { fieldName: "email", caption: "Email" },
        { fieldName: "gender", caption: "Gender", onExport: (e) => e.gender === "Laki" ? "Laki - Laki" : e.gender },
        { fieldName: "usia", caption: "Usia" },
        { fieldName: "position_name", caption: "Posisi" },
        { fieldName: "function_sn", caption: "Fungsi" },
        { fieldName: "section_sn", caption: "Chief" },
        { fieldName: "pendidikan", caption: "Pendidikan" },
        { fieldName: "join_date", caption: "Masuk Pertamina" },
        { fieldName: "pengalaman_kerja", caption: "Total Pengalaman Kerja" },
        { fieldName: "expertise", caption: "Keahlian", width: 200, onExport: (e) => (e?.expertise || '').split(',').map(d => d).join(`\u2029`) },
        { fieldName: "as_leader", caption: "As Leader" },
        { fieldName: "as_member", caption: "As Member" },
    ]


    async function getDataTable() {
        try {
            const resp = await glblAction.getData("/api/people-hrd/table-3", {}, null, null, true);
            if (resp.code === "00") {
                const { data, data_page } = resp;
                setData(data);
                setData_page(data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error");
        }
    }

    function customCellRender(e) {
        if (e.column.name === "status_profil") {

            return (
                <div className="text-center">
                    {
                        status_pegawai(e.value)
                    }
                </div>
            )
        } else if (e.column.name === "expertise") {
            return (
                <ul>
                    {
                        e.value && (e.value || '').split(',').map((d, i) => (
                            <li className='text-wrap' key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "gender") {
            return (
                <span className='text-wrap'>{e.value === "Laki" ? "Laki - Laki" : e.value}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    useEffect(() => {
        getDataTable();
    }, [])
    return <peopleRepContext.Provider
        value={{
            pReportAction: {
                customCellRender
            },
            pReportState: {
                data_page,
                data,
                columns
            }
        }}
    >{children}</peopleRepContext.Provider>
}
export default function usePeopleReport() {
    return useContext(peopleRepContext);
}