import React, { memo, useEffect, useState } from 'react'
import { HeaderList, Tab, TabContent } from '../../components'
import { useGlobalState } from '../../contexts/useGlobalState';
import './kerjasama.css';
import TabDashboard from './tabDashboard';
import TabTable from './tabTable';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import useKejasama, { KerjasamaProvider } from './useKerjasama';
import TabMonitoring from './tabMonitoring';

function Kerjasama() {
    const { krjState } = useKejasama();
    const { tabContent } = useParams();
    const [activeTab, setActiveTab] = useState("");
    const history = useHistory();

    useEffect(() => {
        if (tabContent === "table") {
            setActiveTab(tabContent);
        }
        if (tabContent === "monitoring") {
            setActiveTab(tabContent);
        }
        if (tabContent === "dashboard") {
            setActiveTab(tabContent);
        }
    }, [tabContent])

    return (
        <>
            <div className="mb-5">
                <HeaderList
                    title={krjState.data_page?.page_title}
                    someText={krjState.data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            {
                React.useMemo(() => (
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "dashboard",
                                onClick: () => history.push('/Kerjasama/dashboard'),
                                title: "Partnership Dashboard"
                            },
                            {
                                id: "table",
                                onClick: () => history.push('/Kerjasama/table'),
                                title: "Partnership Profile"
                            },
                            {
                                id: "monitoring",
                                onClick: () => history.push('/Kerjasama/monitoring'),
                                title: "Partnership Monitoring"
                            }
                        ]}
                        manualActive={activeTab}
                    >
                        <TabContent id="dashboard">
                            <TabDashboard></TabDashboard>
                        </TabContent>
                        <TabContent id="table">
                            <TabTable></TabTable>
                        </TabContent>
                        <TabContent id="monitoring">
                            <TabMonitoring></TabMonitoring>
                        </TabContent>
                    </Tab>
                ),[activeTab])
            }

        </>
    )
}
export default () => <KerjasamaProvider><Kerjasama /></KerjasamaProvider>

