import React from 'react';
import usePermohonanPengajuan from '../usePermohonanPengajuan';
import { formatter } from '../../../../components/utility/utility';
import { toString } from 'lodash';

function StepTwo(props) {
    const { ppAction, ppState } = usePermohonanPengajuan();
    return (
        <div className='d-flex flex-column justify-content-between h-100'>
            {/* <button onClick={() => ppAction.generateDataKomoditiSample()}>Generate</button> */}
            <div className='d-flex rounded mb-5' style={{ padding: '10px', minHeight: '500px' }}>
                <div className='tab-stepTwo'>
                    {[...(ppState.dataStepTwo || [])].map((d, i) => (
                        <div key={i} role='button' className={`px-3 py-4 border-bottom d-flex komoditi ${toString(d?.id) === ppState.activeKomoditi ? 'active' : ''}`} onClick={() => ppAction.handleChangeKomoditi(d?.id)}>
                            <div style={{ width: '20px', flex: 'none' }} className={`label label-rounded ${toString(d?.id) === toString(ppState.activeKomoditi) ? "label-primary" : "label-light-primary"} mr-2`}>{i + 1}</div>
                            <span style={{ flexGrow: 1 }} className='text-truncate'>{d?.lab_master_como_nama}</span>
                        </div>
                    ))}
                </div>
                <div className='bg-white p-5' style={{ flexGrow: 1 }}>
                    <form className='p-3 border rounded'>
                        <div className="form-group row">
                            <label htmlFor="jenis" className="col-sm-2 col-form-label col-form-label-sm text-left">Jenis Komoditi</label>
                            <div className="col-sm-4">
                                <div className='bg-secondary px-5 py-2 rounded text-left' style={{ minHeight: '32px' }}>{ppState.formDataStepTwo?.jenis_komoditi}</div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="namaSample" className="col-sm-2 col-form-label col-form-label-sm text-left">Nama Sample</label>
                            <div className="col-sm-4">
                                <div className='bg-secondary px-5 py-2 rounded text-left' style={{ minHeight: '32px' }}>{ppState.formDataStepTwo?.nama}</div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label col-form-label-sm text-left">Volume/Berat Sample</label>
                            <div className="col-sm-4">
                                <div className='d-flex'>
                                    <div className='bg-secondary px-5 py-2 rounded text-left' style={{ minHeight: '32px' }}>{ppState.formDataStepTwo?.volume_berat} {ppState.formDataStepTwo?.satuan}</div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className='float-left'>Parameter Analisis</label>
                            <table className="table tblecs table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Parameter Analisis</th>
                                        <th scope="col">Metode Pengujian</th>
                                        <th scope="col">Harga</th>
                                        <th scope="col">Pilih</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...(ppState.formDataStepTwo?.tabel_parameter_uji || [])].map((d, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{d?.parameter_analisis}</td>
                                                <td>{d?.metode}</td>
                                                <td>{formatter.format(d?.harga)}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <input disabled={!d?.active} type="checkbox" className="form-check-input"
                                                        checked={[...(ppState.formDataStepTwo.parameter_uji || [])].includes(d?.id)}
                                                        onChange={e => ppAction.handleChangeParameterUji(e.target.checked, d?.id)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="ket" className="col-sm-2 col-form-label col-form-label-sm text-left">Keterangan</label>
                            <div className="col-sm-4">
                                <div className='bg-secondary px-5 py-2 rounded text-left' style={{ minHeight:'100px', overflowY:'auto' }}>
                                    {ppState.formDataStepTwo?.note}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='d-flex justify-content-end'>
                <button onClick={ppAction.backTostep1} className='btn btn-sm btn-secondary mr-5'><i className='fas fa-angle-double-left icon-nm'></i> Sebelumnya</button>
                <button onClick={ppAction.handleNextToStepThree} className='btn btn-sm btn-primary'>Selanjutnya <i className='fas fa-angle-double-right icon-nm'></i></button>
            </div>
        </div>
    );
}

export default StepTwo;