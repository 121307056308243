import { FileUploader } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import useModalEditorSwa, { SwaEditModalProvider } from './useModalEditorSwa';

const ModalEditorAddendum = ({ mode = 'Add', id_swakelola, data = {}, nilai_kontrak_terbaru = 0, start_date }) => {
    const { swaEditAction, swaEditState } = useModalEditorSwa();
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        if (state.modal?.modalEditorAddendum === true) {
            // console.log(nilai_kontrak_terbaru)
            swaEditAction.resetEditorAddendum();
            swaEditAction.setSwakelola_id(id_swakelola);
            swaEditAction.setEditorAddendum(data, nilai_kontrak_terbaru, start_date)
            swaEditAction.handleEditorAddendum.setModeEditAddendum(mode);
        }
    }, [state.modal?.modalEditorAddendum])
    return (
        <div>
            <Card>
                <Card.Body>
                    <h5>{`${mode} Addendum`}</h5>
                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text text-wrap bg-transparent border-0" id="basic-addon2">Nilai Addendum</span>
                                </div>
                                <input type="number" className="form-control" placeholder="IDR Juta" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataEditorAddendum.nilai_addendum} onChange={(e) => swaEditAction.handleEditorAddendum.nilaiAddendumChange(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2">IDR (Juta)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Nilai Kontrak Akumulasi</span>
                                </div>
                                <span className="input-group-text bg-white border-0"><b>{parseFloat(((parseFloat(swaEditState.dataEditorAddendum.update_nilai_kontrak, 0) - (data?.nilai_adendum || 0)) + parseFloat(swaEditState.dataEditorAddendum.nilai_addendum || '0', 0)), 0).toFixed(2)} IDR(Juta)</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0">Periode Proyek</span>
                                </div>
                                <span className="input-group-text bg-white border-0"><b>{swaEditState.dataEditorAddendum.start_date_adden}</b></span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0">Sampai</span>
                                </div>
                                <input type="date" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    value={swaEditState.dataEditorAddendum.end_date_adden} onChange={(e) => swaEditAction.handleEditorAddendum.setEnd_date_adden(moment(e.target.value).format("YYYY-MM-DD"))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Note (Optional)</span>
                                </div>
                                <textarea className="form-control" rows={4}
                                    value={swaEditState.dataEditorAddendum.note_adden} onChange={(e) => swaEditAction.handleEditorAddendum.setNote_adden(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Berita Acara Addendum (Optional)</span>
                        </div>
                        <div className="col pl-0">
                            <FileUploader className="custom-dx-fileuploader-file-name"
                                onValueChanged={(e) => swaEditAction.handleEditorAddendum.uploadFileAddendum(e, 'dok_1')}
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                            </FileUploader>
                            <span>{swaEditState.dataEditorAddendum.dok1_ba_addendum}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Surat Permintaan dan Persetujuan</span>
                        </div>
                        <div className="col pl-0">
                            <FileUploader className="custom-dx-fileuploader-file-name"
                                onValueChanged={(e) => swaEditAction.handleEditorAddendum.uploadFileAddendum(e, 'dok_2')}
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                            </FileUploader>
                            <span>{swaEditState.dataEditorAddendum.dok2_permintaan}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Dokumen Revisi PO</span>
                        </div>
                        <div className="col pl-0">
                            <FileUploader className="custom-dx-fileuploader-file-name"
                                onValueChanged={(e) => swaEditAction.handleEditorAddendum.uploadFileAddendum(e, 'dok_3')}
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                            </FileUploader>
                            <span>{swaEditState.dataEditorAddendum.dok3_rev_po}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '128px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Kontrak Addendum</span>
                        </div>
                        <div className="col pl-0">
                            <FileUploader className="custom-dx-fileuploader-file-name"
                                onValueChanged={(e) => swaEditAction.handleEditorAddendum.uploadFileAddendum(e, 'dok_4')}
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                            </FileUploader>
                            <span className="d-block text-left">{swaEditState.dataEditorAddendum.dok4_kontrak_addendum}</span>
                        </div>
                    </div>
                    <hr />
                    <div className="text-md-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => swaEditAction.handleEditorAddendum.batalEditorAddendum()}
                        >
                            Batal
                        </button>
                        <button className="btn btn-sm btn-primary mx-1"
                            onClick={() => swaEditAction.saveButtonClickAddendum()}
                        >
                            <i className="fa fa-save icon-nm mr-1"></i>
                            Simpan
                        </button>
                    </div>
                </Card.Body>
            </Card>

        </div>
    );
}

export default (props) => <SwaEditModalProvider><ModalEditorAddendum mode={props?.mode} id_swakelola={props?.id_swakelola} data={props?.data} nilai_kontrak_terbaru={props?.nilai_kontrak_terbaru} start_date={props?.start_date} /></SwaEditModalProvider>;