import React from 'react';
import { HeaderList, Tab, TabContent } from '../../../components';
import TabWhatsapp from './tab-content/wa-broadcast';
import LogWhastapp from './tab-content/logWhastapp';
import useWhatsapp, { WaProvider } from './useWhatsapp';
import './wa.css';
import WaProjectNotif from './tab-content/wa-project-notif';
import WaEventReminder from './tab-content/wa-event-reminder';

function Index(props) {
    const { waAction, waState } = useWhatsapp();
    return (
        <div>
            <HeaderList
                title={waState.data_page?.page_title}
                someText={waState.data_page?.page_breadcrumbs}
            />
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "wa",
                        title: "WA Personal",
                    },
                    {
                        id: "wap",
                        title: "WA Project Notification",
                    },
                    {
                        id: "wae",
                        title: "WA Event Reminder",
                    },
                    {
                        id: "log",
                        title: "Log Whatsapp",
                    },
                ]}
            >
                <TabContent id="wa">
                    <TabWhatsapp />
                </TabContent>
                <TabContent id="wap">
                    <WaProjectNotif />
                </TabContent>
                <TabContent id="wae">
                    <WaEventReminder />
                </TabContent>
                <TabContent id="log">
                    <LogWhastapp />
                </TabContent>
            </Tab>
        </div>
    );
}

export default () => <WaProvider><Index /></WaProvider>;