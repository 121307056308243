import React, { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useGlobalState } from "../../contexts/useGlobalState";
import useFetch from "../../modules/useFetch";
import ModalApproval from "./component/modalApproval";
import ModalRejected from "./component/modalRejected";

const appKerjContext = createContext({
    appKrjState: {},
    appKrjAction: {}
});
const iniStateColumn = [
    { fieldName: "update_at_form", caption: "Created Date", width: 150 },
    { fieldName: "created_by_name", caption: "Created By", width: 150 },
    { fieldName: "judul", caption: "Judul Partnership" },
    { fieldName: "project_cluster_name", caption: "Project Cluster", width: 150 },
    { fieldName: "total_sa_cs_pc_tpta", caption: "Partnership Score", width: 150 },
    { fieldName: "reviewer", caption: "Status", width: 150 },
]
export function AppKerjProvider({ children }) {
    const [column, setColumn] = useState(iniStateColumn);
    const [, , gaction] = useGlobalState();
    const [selTab, setSelTab] = useState("waiting");
    const [dataPage, setDataPage] = useState({});
    const { doGet, doPost } = useFetch();

    const [dataTable, setDataTable] = useState({});
    const [loadingTable, setLoadingTable] = useState(false);
    const getTable = async function (reporting = false, view_admin = false) {
        setLoadingTable(true);
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        try {
            const resp = await doGet({
                url: 'api/ext-partnership/table-approval',
            });
            if (resp?.statusCode === 401) {
                Swal.close();
                return Swal.fire({
                    title: 'Warning',
                    text: "Session expired",
                    icon: 'warning',
                }).then(res =>
                    gaction.doLogout()
                )
            }
            if (resp?.statusCode !== undefined && resp?.statusCode !== 200) {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })

            } else {
                if (resp?.code === '00') {
                    const data_table = (resp.data?.data_table?.tabel_kerjasama || []).map(d => ({
                        ...d,
                        kerjasama_fungsies: [...(d?.kerjasama_fungsies || [])].map(x => x?.show_chief_manager).join(','),
                        kerjasama_rkaps: [...(d?.kerjasama_rkaps || [])].map(x => x?.show_research).join(','),
                        show_jenis_dokumen: [...(d?.show_jenis_dokumen || [])].join(','),
                        show_status_dokumen: [...(d?.show_status_dokumen || [])].join(','),
                        show_berlaku_start: [...(d?.show_berlaku_start || [])].join(','),
                        show_berlaku_end: [...(d?.show_berlaku_end || [])].join(','),
                    }));
                    const data_header = (resp.data?.date_header || [])?.[0];
                    setDataTable({
                        ...resp.data,
                        tabel_kerjasama: data_table,
                        data_header
                    });
                    setDataPage({ ...resp?.data_page });
                } else {
                    return Swal.fire({
                        title: 'Error!',
                        text: resp?.message,
                        icon: 'error',
                    })
                }
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingTable(false);
        Swal.close();
    }

    const [dataTableReject, setDataTableReject] = useState({});
    const [loadingTableReject, setLoadingTableReject] = useState(false);
    const getTableReject = async function (reporting = false, view_admin = false) {
        setLoadingTableReject(true);
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        try {
            const resp = await doGet({
                url: 'api/ext-partnership/table-rejected',
            });
            if (resp?.statusCode === 401) {
                Swal.close();
                return Swal.fire({
                    title: 'Warning',
                    text: "Session expired",
                    icon: 'warning',
                }).then(res =>
                    gaction.doLogout()
                )
            }
            if (resp?.statusCode !== undefined && resp?.statusCode !== 200) {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })

            } else {
                if (resp?.code === '00') {
                    const data_table = (resp.data?.data_table?.tabel_kerjasama || []).map(d => ({
                        ...d,
                        kerjasama_fungsies: [...(d?.kerjasama_fungsies || [])].map(x => x?.show_chief_manager).join(','),
                        kerjasama_rkaps: [...(d?.kerjasama_rkaps || [])].map(x => x?.show_research).join(','),
                        show_jenis_dokumen: [...(d?.show_jenis_dokumen || [])].join(','),
                        show_status_dokumen: [...(d?.show_status_dokumen || [])].join(','),
                        show_berlaku_start: [...(d?.show_berlaku_start || [])].join(','),
                        show_berlaku_end: [...(d?.show_berlaku_end || [])].join(','),
                    }));
                    const data_header = (resp.data?.date_header || [])?.[0];
                    setDataTableReject({
                        ...resp.data,
                        tabel_kerjasama: data_table,
                        data_header
                    });
                    setDataPage({ ...resp?.data_page });
                } else {
                    return Swal.fire({
                        title: 'Error!',
                        text: resp?.message,
                        icon: 'error',
                    })
                }
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingTableReject(false);
        Swal.close();
    }

    const [dataTableReevaluated, setDataTableReevaluated] = useState({});
    const [loadingTableReevaluated, setLoadingTableReevaluated] = useState(false);
    const getTableReevaluated = async function (reporting = false, view_admin = false) {
        setLoadingTable(true);
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        try {
            const resp = await doGet({
                url: 'api/ext-partnership/table-reevaluate',
            });
            if (resp?.statusCode === 401) {
                Swal.close();
                return Swal.fire({
                    title: 'Warning',
                    text: "Session expired",
                    icon: 'warning',
                }).then(res =>
                    gaction.doLogout()
                )
            }
            if (resp?.statusCode !== undefined && resp?.statusCode !== 200) {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })

            } else {
                if (resp?.code === '00') {
                    const data_table = (resp.data?.data_table?.tabel_kerjasama || []).map(d => ({
                        ...d,
                        kerjasama_fungsies: [...(d?.kerjasama_fungsies || [])].map(x => x?.show_chief_manager).join(','),
                        kerjasama_rkaps: [...(d?.kerjasama_rkaps || [])].map(x => x?.show_research).join(','),
                        show_jenis_dokumen: [...(d?.show_jenis_dokumen || [])].join(','),
                        show_status_dokumen: [...(d?.show_status_dokumen || [])].join(','),
                        show_berlaku_start: [...(d?.show_berlaku_start || [])].join(','),
                        show_berlaku_end: [...(d?.show_berlaku_end || [])].join(','),
                    }));
                    const data_header = (resp.data?.date_header || [])?.[0];
                    setDataTable({
                        ...resp.data,
                        tabel_kerjasama: data_table,
                        data_header
                    });
                    setDataPage({ ...resp?.data_page });
                } else {
                    return Swal.fire({
                        title: 'Error!',
                        text: resp?.message,
                        icon: 'error',
                    })
                }
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingTable(false);
        Swal.close();
    }

    const CustomCellRender = (e) => {
        if (e.column.name === "judul") {
            return (
                <Link to={`/admin/partnership/detail-approval/${e?.data?.id}`}
                    // target="_blank"
                // onClick={() => setModal({ show: true, data: { ...e?.data } })}
                >
                    <span className="text-wrap">{e.value}</span>
                </Link>
            )
        } else if (e.column.name === 'kerjasama_fungsies') {
            return (
                <ul>
                    {
                        [...(e.value?.split(',') || [])].map((d, i) => (
                            <li className=" text-wrap p-2" key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'reviewer') {
            return (
                <span className="badge bagde-success rounded">{e.value}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }

    }
    const CustomCellRenderReject = (e) => {
        if (e.column.name === "judul") {
            return (
                <Link to={`/admin/partnership/detail-approval/${e?.data?.id}`}
                    // target="_blank"
                //onClick={() => setModalWaiting({ show: true, data: { ...e?.data } })}
                >
                    <span className="text-wrap">{e.value}</span>
                </Link>
            )
        } else if (e.column.name === 'kerjasama_fungsies') {
            return (
                <ul>
                    {
                        [...(e.value?.split(',') || [])].map((d, i) => (
                            <li className=" text-wrap p-2" key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'reviewer') {
            return (
                <span className="badge bagde-success rounded">{e.value}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }

    }

    const [modal, setModal] = useState({
        show: false,
        data: {}
    });
    const [modalWaiting, setModalWaiting] = useState({
        show: false,
        data: {}
    });
    const handleCloseModal = () => {
        setModal({
            show: false,
            data: {}
        })
    }
    const handleCloseModalWaiting = () => {
        setModalWaiting({
            show: false,
            data: {}
        })
    }
    const confirm = (id = 0, approve = true) => {
        Swal.mixin({
            customClass: {
                confirmButton: `btn btn-sm btn-${approve ? 'primary' : 'danger'} mx-1`,
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-${approve ? 'primary' : 'danger'} text-white customShadow mb-5"></i></div>` +
                `<strong>${approve ? "Approve" : "Reject"} Partnership?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (approve) {
                    doNextApprove(id);
                } else {
                    doNextReject(id);
                }
                return true;
            } else {
                return true;
            }
        })
    }
    const confirmWaiting = (id = 0) => {
        Swal.mixin({
            customClass: {
                confirmButton: `btn btn-sm btn-primary mx-1`,
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>` +
                `<strong>Waiting Approval Partnership?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                doNextWaitingApproval(id);
                return true;
            } else {
                return true;
            }
        })
    }
    const doNextWaitingApproval = async function (id) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-partnership/waiting-kerjasama?id=${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalWaiting({ show: false, data: {} });
                    getTableReject();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const doNextApprove = async function (id) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-partnership/approve-kerjasama?id=${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModal({ show: false, data: {} });
                    getTable();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const doNextReject = async function (id) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-partnership/reject-kerjasama?id=${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModal({ show: false, data: {} });
                    getTable();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const tabChange = (val) => setSelTab(val);
    useEffect(() => {
        if (selTab === 'waiting') getTable();
        if (selTab === 'reject') getTableReject();
        if (selTab === 'reevaluated') getTableReevaluated();
    }, [selTab])
    return <appKerjContext.Provider
        value={{
            appKrjState: {
                column,
                dataTable,
                dataPage,
                loadingTable,
                modal,
                modalWaiting,
                selTab,
                dataTableReject,
                loadingTableReject,
                dataTableReevaluated,
                loadingTableReevaluated
            },
            appKrjAction: {
                CustomCellRender,
                CustomCellRenderReject,
                setModal,
                setModalWaiting,
                confirm,
                confirmWaiting,
                handleCloseModal,
                handleCloseModalWaiting,
                tabChange
            }
        }}
    >
        {children}
        <ModalApproval />
        <ModalRejected />
    </appKerjContext.Provider >
};

export default function useApprovalKerjasama() {
    return useContext(appKerjContext);
}