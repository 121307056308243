import React from 'react';
import { useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GridDx } from '../../components';
import useApprovalKerjasama from './useApprovalKerjasama';
import { stubFalse } from 'lodash';

const TabRejected = (props) => {
    const refTable = useRef();
    const history = useHistory();
    const { appKrjAction, appKrjState } = useApprovalKerjasama();
    return (
        <Card
            className="m-0 p-0 mb-5 shadow"
        >
            <Card.Body className="p-2">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 ">
                        <GridDx
                            className='custom-style-master'
                            masterDetail={false}
                            repaintChangesOnly={true}
                            searchForm={true}
                            ref={refTable}
                            loading={appKrjState.loadingTableReject}
                            data={(appKrjState.dataTableReject?.tabel_kerjasama || [])}
                            cellRender={appKrjAction.CustomCellRenderReject}
                            columns={appKrjState.column}
                            withAction={stubFalse}
                            pagination={true}
                            orderingDrag={false}
                            currentPage
                            show
                            // actions={[
                            //     {
                            //         name: "Waiting Approval",
                            //         icon: "fas fa-clipboard-check icon-nm text-secondary",
                            //         action: (v, c) => history.push(`/admin/partnership/detail-approval/${v?.id}`)//appKrjAction.setModalWaiting({ show: true, data: { ...v } })
                            //     }
                            // ]}
                        >
                        </GridDx>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default TabRejected;