/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
// import { menuItems } from "../../../menu-items";
// import { roundToNearestMinutes } from "date-fns/fp";
// import { menuAdmin } from "../../../menu-admin";
import { useGlobalState } from "../../../../../app/contexts/useGlobalState";
import useLocalStorage from "../../../../../app/contexts/useLocalStorage";
import { react_app_dummy } from "../../../../../app/app.config";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const [state, dispatch, action] = useGlobalState();
  const [mnItems, setMnItems] = useState([]);
  const [mnAdmin, setMnAdmin] = useState([]);
  const [token] = useLocalStorage("token", null)
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  useEffect(() => {
    if (token) {
      action.getData('/api/base/get-sidemenu-admin', {}, 'data_menu_admin').then((resp) => {
        if (resp.code === '00') {
          setMnAdmin([
            ...(resp.data?.data_menu || []),
            // ...(react_app_dummy ? [{
            //   "id": 12,
            //   "name": "Laboratory",
            //   "url": null,
            //   "icon": "/media/svg/icons/Weather/Temperature-half.svg",
            //   "iconType": "svg",
            //   "param": null,
            //   "childs": [
            //     {
            //       "id": 1,
            //       "name": "Data Master",
            //       "url": "/laboratory/data-master",
            //       "icon": "la la-dot",
            //       "iconType": "css",
            //       "param": null,
            //       "childs": []
            //     },
            //     {
            //       "id": 2,
            //       "name": "Data CRUD Customer",
            //       "url": "/laboratory/data-crud-customer",
            //       "icon": "la la-dot",
            //       "iconType": "css",
            //       "param": null,
            //       "childs": []
            //     },
            //     {
            //       "id": 3,
            //       "name": "Data CRUD Pengujian",
            //       "url": "/laboratory/data-crud-pengujian",
            //       "icon": "la la-dot",
            //       "iconType": "css",
            //       "param": null,
            //       "childs": []
            //     }
            //   ]
            // }] : [])
          ]);
        }
      });
      action.getData('/api/base/get-sidemenu-spiirit').then((resp) => {
        if (resp.code === '00') {
          setMnItems([
            ...(resp.data?.data_menu || []),
            // ...(react_app_dummy ? [{
            //   "id": 12,
            //   "name": "Laboratory",
            //   "url": "/laboratory",
            //   "icon": "/media/svg/icons/Weather/Temperature-half.svg",
            //   "iconType": "svg",
            //   "param": null,
            //   "childs": []
            // }] : []),
          ]);
        }
      });
    }
  }, [])

  const { pathname } = useLocation()

  function isRouteAdmin() {
    let path = pathname.split("/");
    if (path.length >= 2)
      return (path[1] === "admin")
    return false
  }
  const routes = state.dataUser?.is_admin === 1 ? (isRouteAdmin() ? mnAdmin : mnItems) : mnItems;
  // const routes = state.dataUser?.is_admin === 1 ? (isRouteAdmin() ? menuAdmin : menuItems) : menuItems;

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`} style={{ marginBottom: "75px" }}>
        {/*begin::1 Level*/}

        {routes.map(
          ({ url, icon, iconType, name, params = {}, childs = [] }, i) =>
            [...(childs || [])].length === 0 ? (
              <li key={i}
                className={`menu-item ${getMenuItemActive((isRouteAdmin() ? "/admin" + url : url), false)}`}
                aria-haspopup="true"
              >
                <NavLink
                  key={i}
                  className="menu-link"
                  to={{
                    pathname: (isRouteAdmin() ? "/admin" + url : url),
                    state: params,
                  }}
                >
                  {iconType === "svg" ? (
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl(icon)} />
                    </span>
                  ) : iconType === "image" ? (
                    icon
                  ) : (
                    <i className={`menu-icon ${icon}`}></i>
                  )}
                  <span className="menu-text">{name}</span>
                </NavLink>
              </li>
            ) : (
              <li key={i}
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  url,
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  key={i}
                  className="menu-link menu-toggle"
                  to="#"
                >
                  {iconType === "svg" ? (
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl(icon)} />
                    </span>
                  ) : iconType === "image" ? (
                    icon
                  ) : (
                    <i className={`menu-icon ${icon}`}></i>
                  )}
                  <span className="menu-text">{name}</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <ul className="menu-subnav">
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text">{name}</span>
                        </span>
                      </li>

                      {/*begin::2 Level*/}
                      {childs.map((g, j) =>
                        [...(g.childs || [])]?.length === 0 ? (
                          <li key={i.toString() + j.toString() + g.url}
                            className={`menu-item ${getMenuItemActive((isRouteAdmin() ? "/admin" + g.url : g.url))}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              key={i.toString() + j.toString()}
                              className="menu-link"
                              to={{
                                pathname: (isRouteAdmin() ? "/admin" + g.url : g.url),
                                state: g.params || {},
                              }}
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{g.name}</span>
                            </NavLink>
                          </li>
                        ) : (
                          <li
                            key={i.toString() + j.toString() + g.url}
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                              (isRouteAdmin() ? "/admin" + g.url : g.url),
                              true
                            )}`}
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                          >
                            <NavLink
                              key={i.toString() + j.toString()}
                              className="menu-link menu-toggle"
                              to="/google-material/inputs"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{g.name}</span>
                              <i className="menu-arrow" />
                            </NavLink>
                            <div className="menu-submenu ">
                              <i className="menu-arrow" />
                              <ul className="menu-subnav">
                                {/*begin::3 Level*/}
                                {
                                  g.childs.map((gc, k) => (
                                    <li
                                      key={i.toString() + j.toString() + k.toString() + g.url}
                                      className={`menu-item  ${getMenuItemActive(
                                        (isRouteAdmin() ? "/admin" + gc.url : gc.url)
                                      )}`}
                                      aria-haspopup="true"
                                    >
                                      <NavLink
                                        key={i.toString() + j.toString() + k.toString()}
                                        className="menu-link"
                                        to={(isRouteAdmin() ? "/admin" + gc.url : gc.url)}
                                      >
                                        <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                        </i>
                                        <span className="menu-text">
                                          {gc.name}
                                        </span>
                                      </NavLink>
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </ul>
                </div>
              </li>
            )
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
