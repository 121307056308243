import React, { useEffect, useRef } from 'react';
import { GridDetailLogSession } from '../../../component';
import usePageSession from '../../usePageSession';
import moment from 'moment';
import { GridDx } from '../../../../../components';

function LogSession(props) {
    const tableRef = useRef();
    const { pgSesAction, pgSesState } = usePageSession();

    useEffect(() => {
        pgSesAction.getLogSession(pgSesState.id_user, pgSesState.date_start, pgSesState.date_end);
    }, []);
    return (
        <div className='card customShadow p-5'>
            <div className='row mb-5'>
                <div className='col-12'>
                    <GridDx
                        className='custom-style-master'
                        masterDetail={true}
                        repaintChangesOnly={true}
                        ComponenMasterDetail={GridDetailLogSession}
                        searchForm={false}
                        ref={tableRef}
                        loading={pgSesState.loadingSes}
                        data={pgSesState.dataSes?.dataSession}
                        columns={pgSesState.column_session}
                        withAction={false}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        selectionMode={"multiple"}
                        exportMode="selection"
                        exportExcel={true}
                        filenameExport={`Log Session Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                        show
                        toolbarPosition="left"
                        toolbar={
                            <div className='d-block d-sm-block d-md-flex d-lg-flex mb-5'>
                                <div className='input-group mb-3 mr-1'>
                                    <div className="input-group-prepend">
                                        <span className='input-group-text'>User</span>
                                    </div>
                                    <select className="form-control"
                                        style={{ width: '160px' }}
                                        value={pgSesState?.id_user}
                                        onChange={(e) => pgSesAction.setId_user(e.target.value)}
                                    >
                                        <option value={''}>- Pilih User-</option>
                                        {
                                            [...(pgSesState.dataUsr || [])].map((d, i) => (
                                                <option value={d?.id} key={i}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='input-group mb-3 mr-1'>
                                    <div className="input-group-prepend">
                                        <span className='input-group-text'>Tanggal Start</span>
                                    </div>
                                    <input
                                        value={pgSesState?.date_start}
                                        type="date"
                                        className="form-control"
                                        id="dateStart"
                                        placeholder="Start Date"
                                        onChange={(e) => pgSesAction.setDate_start(moment(e.target.value).format('YYYY-MM-DD'))}
                                    />
                                </div>
                                <div className='input-group mb-3 mr-1'>
                                    <div className="input-group-prepend">
                                        <span className='input-group-text'>Tanggal End</span>
                                    </div>
                                    <input
                                        value={pgSesState?.date_end}
                                        type="date"
                                        className="form-control"
                                        id="dateEnd"
                                        placeholder="End Date"
                                        onChange={(e) => pgSesAction.setDate_end(moment(e.target.value).format('YYYY-MM-DD'))}
                                    />

                                </div>
                                <div>
                                    <button className='btn btn-primary btn-nm'
                                        style={{ width:'100px' }}
                                        onClick={() => pgSesAction.filterSessionChange()}
                                    >
                                        <i className='fa fa-search icon-nm mr-0'></i>
                                        Search
                                    </button>
                                </div>
                            </div>
                        }
                    >
                    </GridDx>
                </div>
            </div>
        </div>
    );
}

export default LogSession