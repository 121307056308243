import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../components';
// import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { achievement_status_filter } from '../../components/utility/utility';

const columns = [
    { fieldName: "name", caption: "Judul", width: 200 },
    { fieldName: "description", caption: "Description", width: 500 },
    { fieldName: "status_approval", caption: "Status", width: 100, headerFilterSource: achievement_status_filter },
    { fieldName: "owner_name", caption: "Owner", width: 200 },
    { fieldName: "level", caption: "Level", width: 100 },
    { fieldName: "year_of_achievement", caption: "Year", width: 100 },
    { fieldName: "creator_name", caption: "Creator", width: 100 },
]

function ApprovalPeopleAchievement(props) {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const tableRef = useRef();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData('/api/people-achievement/table-approval', {}, null, null, true).then((resp) => {
            if(resp.code === "00"){
                setData_page(resp.data_page);
            }
        });
    }, [])
    const customRender = (e) => {
        if (e.column.name === "name") {
            return (
                <Link to={{
                    pathname: `/admin/people/achievement-detail-approval/${e.data.id}`,
                    state: { view_admin: true }
                }}>
                    {
                        e.value
                    }
                </Link>
            )
        } else if (e.column.name === "status_approval") {
            return (
                <div className="my-2">
                    {
                        e.value === 1 && (
                            <div className="bg-light-warning text-warning p-2 rounded text-center">
                                <span className="text-wrap">Waiting Approval</span>
                            </div>
                        )
                    }
                    {
                        e.value === 2 && (
                            <div className="bg-light-success text-success p-2 rounded text-center">
                                <span className="text-wrap">Approved</span>
                            </div>
                        )
                    }

                </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    const deleteOnclick = (id) => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Achievement ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-hrd/delete-achievement?id=" + id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div>
            <div className="pb-5">
                <HeaderList
                    title={data_page?.page_title}
                    someText={data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            <Card
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        ref={tableRef}
                        cellRender={customRender}
                        searchForm={true}
                        withAction={true}
                        data={state.data_table || []}
                        columns={columns}
                        actions={[
                            {
                                name: "View",
                                icon: "fas fa-eye icon-nm text-primary",
                                action: (v, c) => { history.push(`/admin/people/achievement-detail-approval/${v.id}`) }
                            },
                            {
                                name: "Edit",
                                icon: "fa fa-pencil-alt icon-nm text-success",
                                action: (v, c) => { history.push(`/admin/people-achievement/edit/${v.id}`) }
                            }, {
                                name: "Delete",
                                icon: "fa fa-trash-alt icon-nm text-danger",
                                action: (v, c) => { deleteOnclick(v.id) }
                            }
                        ]}
                    />
                </Card.Body>
            </Card>
        </div>
    );
}

export default ApprovalPeopleAchievement;