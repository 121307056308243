import React, { useEffect } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

const TabVersion = () => {
    const param = useParams();
    const [state, , action] = useGlobalState();
    const history = useHistory();
    useEffect(() => {
        action.getData('/api/project-ongoing-crud/view-version/' + param?.id).then((resp) => {
            if (resp.code === '00') {
                action.setForm('prohis', resp.data_page);
            }
        })
    }, [])
    return (
        <div>
            <table>
                <tbody>
                    {
                        [...(state.data_view_version || [])].map((d, i) => (
                            <tr key={i}>
                                <td width="150px">
                                    <span className="d-block text-dark-25">{d.date_created}</span>
                                    <span className="d-block font-weight-bold">Version {d.baseline_name}</span>
                                </td>
                                <td>
                                    <button className="btn btn-sm btn-success"
                                        onClick={() => history.push('/research/project-profile/' + d.research_id + '/' + d.baseline_name)}
                                    >
                                        <i className="fa fa-search icon-nm"></i>
                                        View Project Profile
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default TabVersion;