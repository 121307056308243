import { Autocomplete, FileUploader, TagBox, Tooltip } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { baseUrl } from '../../app.config';
import { fileExtensions, fileExtensions_string } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useLocation, useParams } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { FileUploadDx } from '../../components';

function ModalAchievement({ data = [], onClose = () => { }, onFinish = () => { } }) {
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [judul, setJudul] = useState("");
    const [description, setDescription] = useState("");
    const [level, setLevel] = useState("");
    const [owner, setOwner] = useState([]);
    const [defOwner, setDefOwner] = useState([]);
    const [ownerName, setOwnerName] = useState();
    const [tahun, setTahun] = useState("");
    const [cover, setCover] = useState("");
    const [statusEdit, setStatusEdit] = useState(0);
    const [idEdit, setIdEdit] = useState('');
    const [status_approval, setStatus_approval] = useState();
    const [data_user, setData_user] = useState([])

    useEffect(() => {
        if (state.modal.achievement === true) {
            action.getData('/api/param-user').then((resp) => {
                if(resp.code === '00'){
                    setData_user(resp.data)
                }
            })
        }
        cancelEdit();
    }, [state.modal.achievement])

    function onUploadedCover(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setCover({
                "name": resp.data?.data_filename,
                "is_new": true
            })
        }
    }
    function fileOnchange(data) {
        const f = data.value?.[0];
        if (data.value?.[0] && f.size <= 10000000) {
            var file = data.value?.[0];
            var ext = file?.name?.split('.').pop();
            var result = fileExtensions_string.filter(d => d === ext)
            if (result.length > 0) {
                action.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": data.value?.[0],
                        },
                        withFile: true,
                    }
                ).then((resp) => {
                    if (resp.code === "00") {
                        setCover({
                            "name": resp.data?.data_filename,
                            "is_new": true
                        })
                    }
                })
            }
        } else if (data.value?.[0] && f.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageYear = '';
        if (data.name === '') {
            isValid = false;
            message = [...message, 'Judul'];
        }
        if (data.description === '') {
            isValid = false;
            message = [...message, 'Description'];
        }
        if (data.level === '') {
            isValid = false;
            message = [...message, 'Level'];
        }
        // if ([...(data.owner || [])].length === 0) {
        //     isValid = false;
        //     message = [...message, 'Owner'];
        // }
        if (data.year_of_achievement === '') {
            isValid = false;
            message = [...message, 'Tahun'];
        }
        if (data.photo_location?.name === '') {
            isValid = false;
            message = [...message, 'Cover'];
        }
        if (isValid && parseInt(data?.year_of_achievement, 0) < 1950) {
            isValid = false;
            messageYear = 'Tahun minimal 1950';
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageYear ? ' dan ' : '') + messageYear
        }
    }
    function add() {
        const data = {
            "id": '',
            "name": judul || '',
            "description": description || '',
            "photo_location": {
                name: cover?.name || '',
                is_new: cover?.is_new
            },
            "year_of_achievement": tahun || '',
            "level": level || '',
            "creator": '',
            "created_at": "",
            "updated_at": "",
            "owner": [...(owner || [])].map(d => {
                return {
                    user_id: d,
                    is_new: true
                }
            }) || [],
            "owner_delete": [],
            "status_approval": ""
        }
        const valid = validation(data);
        if (valid.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: valid.message,
                icon: 'warning',
            })
        } else {

            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Achievement? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/people-hrd/create-achievement?id_user=${state.data_user_profile.user_id}`,
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            cancelEdit();
                            onFinish();
                            // history.push('/admin/people-achievement/table')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function update() {
        const fixowner = [...(owner || [])].map(d => {
            const ifAny = [...(defOwner || [])].find(x => x == d);
            return {
                user_id: d,
                is_new: ifAny ? false : true
            }
        })
        const deleteOwner = [...(defOwner || [])].filter(x => ![...(fixowner || [])].filter(d => d.is_new === false).find(y => y.user_id === x)).map(s => {
            return {
                user_id: s,
                is_new: false
            }
        })
        // console.log(fixowner, deleteOwner)
        const data = {
            "id": idEdit,
            "name": judul || '',
            "description": description || '',
            "photo_location": {
                name: cover?.name || '',
                is_new: cover?.is_new
            },
            "year_of_achievement": tahun || '',
            "level": level || '',
            "creator": "",
            "created_at": "",
            "updated_at": "",
            "owner": fixowner,
            "owner_delete": deleteOwner,
            "status_approval": status_approval || ''
        }
        const valid = validation(data);
        // console.log(data);
        if (valid.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: valid.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Achievement? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/people-hrd/update-achievement",
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            cancelEdit();
                            onFinish();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function setEdit(data) {
        action.getData(`/api/people-hrd/view-update-achievement?id=${data.id}`, {}, 'data_achievement_edit').then((resp) => {
            if (resp.code === '00') {
                setJudul(resp.data.data_user_achievement?.name);
                setDescription(resp.data.data_user_achievement?.description);
                setLevel(resp.data.data_user_achievement?.level);
                setTahun(resp.data.data_user_achievement?.year_of_achievement);
                const ownerArr = [...((resp.data.data_user_achievement?.owner_id || '').split(',') || [])].map(d => parseInt(d, 0));
                setDefOwner(ownerArr)
                setOwner(ownerArr);
                setCover({
                    name: resp.data.data_user_achievement?.photo_location,
                    is_new: false
                });
                setStatusEdit(1);
                setIdEdit(resp.data.data_user_achievement?.id);
                setStatus_approval(resp.data.data_user_achievement?.status_approval);
            }
        })
    }
    function cancelEdit() {
        setJudul('');
        setDescription('');
        setLevel('');
        setOwner([state.dataUser?.id]);
        setDefOwner([]);
        setOwnerName('');
        setTahun('');
        setCover('');
        setStatusEdit(0);
        setIdEdit('');
        setStatus_approval('');
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Hapus Achievement ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/people-hrd/delete-achievement?id=${id}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        cancelEdit();
                        onFinish();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function ownerChange(e) {
        const fnd = [...(e || [])].find(d => d?.toString() === state.dataUser?.id?.toString());
        if (fnd) {
            setOwner(e)
        } else {
            setOwner([state.dataUser?.id, ...e])
        }
    }
    return (
        <>
            <h5>{statusEdit === 0 ? 'Add' : 'Edit'} Achievement</h5>
            <hr />
            <div className="px-2 py-5 my-5 rounded bg-warning text-light-warning mb-5 text-center">
                <div className="d-inline-flex">
                    {/* <i className="fas fa-exclamation-triangle icon-2x text-light-warning mx-2"></i> */}
                    <span className="svg-icon svg-icon-4x svg-icon-light my-auto mr-5">
                        <SVG src="/media/svg/icons/Code/Warning-2.svg" />
                    </span>
                    <div className="text-left my-auto">
                        <span className="d-block" style={{ fontWeight: '900' }}>Penghargaan prestasi dari perusahaan atau pihak luar</span>
                        <span className="d-block">(tidak termasuk: pembicara, trainer, peserta training, keanggotaan dsb)</span>
                    </div>
                </div>

            </div>
            <div className="row mb-3">
                <div className="col-md-3">Judul Achievement</div>
                <div className="col-md-9">
                    <input className="form-control form-control-sm" placeholder="Judul Achievement" value={judul} onChange={(e) => setJudul(e.target.value)}></input>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Description</div>
                <div className="col-md-9">
                    <textarea rows={3} placeholder="Deskripsi..." className="form-control form-control-sm" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Level</div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" value={level} onChange={(e) => setLevel(e.target.value)}>
                        <option value=""> - Pilih Level -</option>
                        <option value="Internal">Internal</option>
                        <option value="National">National</option>
                        <option value="International">International</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Owner</div>
                <div className="col-md-9">
                    {/* <Autocomplete dataSource={state.data_users}
                        value={ownerName}
                        placeholder="Nama Owner"
                        valueExpr="name"
                        displayExpr="name"
                        onValueChanged={(e) => setOwnerName(e.value)}
                        onSelectionChanged={(e) => setOwner(e.selectedItem)}
                        className="form-control form-control-sm mr-1">
                    </Autocomplete> */}
                    <TagBox

                        searchEnabled={true}
                        value={owner}
                        id="inventor"
                        items={data_user}
                        displayExpr="name"
                        displayValue="name"
                        valueExpr="id"
                        onValueChange={ownerChange}
                    />
                    {/* <Tooltip
                        target="#inventor"
                        hideEvent={{ delay: 2000, name: "mouseout" }}
                        contentRender={() => (<p>Limit 6</p>)}
                        position="bottom"
                        visible={isTooltipVisible}
                        onHiding={() => setIsTooltipVisible(false)}
                    /> */}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Tahun</div>
                <div className="col-md-9">
                    <input type="number" placeholder="2021" min={0} className="form-control form-control-sm" value={tahun} onChange={(e) => setTahun(e.target.value)} />
                    {/* <NumberBox className="form-control form-control-sm" onValueChanged={(e) => setTahun(e.value)}></NumberBox> */}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Cover</div>
                <div className="col-md-9">
                    {/* <FileUploader className="custom-dx-fileuploader-file-name p-0 custom-uploader"
                        uploadUrl={baseUrl + "/api/base/upload-file-temp"}
                        allowedFileExtensions={fileExtensions}
                        accept="image/*"
                        invalidFileExtensionMessag="File "
                        onValueChanged={(e) => fileOnchange(e)}
                        showFileList={false} multiple={false}
                        selectButtonText="Select File"
                        readyToUploadMessage=" "
                        labelText="or Drop file hire | Max size 10 Mb" maxFileSize={10000000} uploadMode="useForm" >

                    </FileUploader> */}
                    <FileUploadDx
                        className="custom-dx-fileuploader-file-name"
                        showFileList={true}
                        multiple={false}
                        selectButtonText="Select File"
                        allowedFileExtensions={fileExtensions}
                        readyToUploadMessage=" "
                        labelText="or Drop file hire | Max size 10 Mb"
                        accept="image/*"
                        onUploaded={onUploadedCover}
                        maxFileSize={10000000}
                        uploadMode="instantly"
                    />
                    <span>{cover.name}</span>
                </div>
            </div>
            <hr />
            <div className="d-block text-right mb-5">
                {
                    statusEdit === 0 && (
                        <>
                            <button className="btn btn-sm btn-success" onClick={add}><i className="fa fa-plus icon-nm mr-3"></i>Achievement</button>
                        </>
                    )
                }
                {
                    statusEdit === 1 && (
                        <>
                            <button className="btn btn-sm btn-secondary mx-1" onClick={() => cancelEdit()}>Cancel Edit</button>
                            <button className="btn btn-sm btn-success mx-1" onClick={update}><i className="fa fa-save icon-nm mr-3"></i>Update</button>
                        </>
                    )
                }
            </div>
            {/* <hr /> */}
            {
                [...(data || [])].map((d, i) => (
                    <div className="row mb-3">
                        <div className="col-6">
                            <div className="d-inline-flex">
                                <i className="fa fa-circle icon-xs mr-5 mt-1 text-dark"></i>
                                <div>
                                    <span className="d-block mb-3"><b>{d.name}</b></span>
                                    {
                                        d.status_approval === 1 && (
                                            <span className="py-1 px-3 bg-light-secondary rounded mt-3 text-dark">Review</span>
                                        )
                                    }
                                    {
                                        d.status_approval === 2 && (
                                            <span className="py-1 px-3 bg-light-success rounded mt-3 text-success">Approve</span>
                                        )
                                    }
                                    {/* <span className="d-block">{d.year_of_achievement}</span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-md-right">
                            {
                                d.status_approval === 1 && (
                                    <>
                                        <button className="btn btn-sm btn-outline-warning text-warning mx-1" onClick={() => setEdit(d)}>
                                            <i className="fa fa-pencil-alt icon-nm p-0"></i>
                                        </button>
                                        <button className="btn btn-sm btn-outline-danger text-danger mx-1" onClick={() => deleteData(d.id)}>
                                            <i className="fa fa-trash-alt icon-nm p-0"></i>
                                        </button>
                                    </>
                                )
                            }

                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default ModalAchievement;