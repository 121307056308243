import React from 'react';
import useCrudPengujian, { CrudPengujianProvider } from './useCrudPengujian';
import { HeaderList, Tab, TabContent } from '../../../../components';
import { Card } from 'react-bootstrap';
import TabTestingReq from './component/tabTestingReq';
import TabSampleDeivery from './component/tabSampleDeivery';
import TabSampleProcessing from './component/tabSampleProcessing';
import TabTestingReport from './component/tabTestingReport';

function Index(props) {
    const { cpAction, cpState } = useCrudPengujian();
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={"Admin Laboratory CRUD Pengujian"}
                someText={""}
            />
            <div className="row">
                <div className="col-12">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "tr",
                                title: "Testing Request",
                                onClick: () => cpAction.tabChangeHandle("tr")
                            },
                            {
                                id: "sd",
                                title: "Sample Delivery",
                                onClick: () => cpAction.tabChangeHandle("sd")
                            },
                            {
                                id: "sp",
                                title: "Testing Process",
                                onClick: () => cpAction.tabChangeHandle("sp")
                            },
                            {
                                id: "tre",
                                title: "Testing Report",
                                onClick: () => cpAction.tabChangeHandle("tre")
                            },
                        ]}
                        manualActive={cpState.tabActive}
                    >
                        <TabContent id="tr">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabTestingReq />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                        <TabContent id="sd">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabSampleDeivery />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                        <TabContent id="sp">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabSampleProcessing />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                        <TabContent id="tre">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabTestingReport/>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                    </Tab>
                </div>
            </div>
        </div>
    );
}

export default () => <CrudPengujianProvider><Index /></CrudPengujianProvider>;