import React, { useEffect, useRef } from 'react';
import { GridDx } from '../../../../components';
import useWhatsapp from '../useWhatsapp';

function LogWhastapp(props) {
    const tableRef = useRef();
    const { waState, waAction } = useWhatsapp();

    useEffect(() => {
        waAction.getLogWa();
    }, [waState.filterWa])

    return (
        <div className='card customShadow p-5'>
            <div className='row'>
                <div className='col-lg-9 col-md-9 col-12 mb-5'>
                    <div className='d-block d-sm-block d-md-flex d-lg-flex mb-5'>
                        <div className='input-group mb-3 mr-1'>
                            <div className="input-group-prepend">
                                <span className='input-group-text'>Tanggal Start</span>
                            </div>
                            <input
                                type="date"
                                className="form-control"
                                id="dateStart"
                                placeholder="Start Date"
                                value={waState.dataLogWa?.date_start}
                                onChange={(e) => waAction.handleFilterWa("date_start", e.target.value)}
                            />
                        </div>
                        <div className='input-group mb-3 mr-1'>
                            <div className="input-group-prepend">
                                <span className='input-group-text'>Tanggal End</span>
                            </div>
                            <input
                                type="date"
                                className="form-control"
                                id="dateEnd"
                                placeholder="End Date"
                                value={waState.dataLogWa?.date_end}
                                onChange={(e) => waAction.handleFilterWa("date_end", e.target.value)}
                            />
                        </div>
                        <div className='input-group mb-3 mr-1'>
                            <div className="input-group-prepend">
                                <span className='input-group-text'>Module</span>
                            </div>
                            <select className="form-control" id='selUser'
                                value={waState.filterWa?.module}
                                onChange={(e) => waAction.handleFilterWa("module", e.target.value)}
                            >
                                <option value={''}>- All-</option>
                                {
                                    [...(waState.dataLogWa?.module_act_list || [])].map((d, i) => (
                                        <option value={d} key={i}>{d}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    {/* <form>
                        <div className="form-row">
                            <div className="form-group form-group-sm col-md-3 mb-md-0 mb-3">
                                <label htmlFor="dateStart">Tanggal start</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="dateStart"
                                    placeholder="Start Date"
                                    value={waState.dataLogWa?.date_start}
                                    onChange={(e) => waAction.handleFilterWa("date_start", e.target.value)}
                                />
                            </div>
                            <div className="form-group form-group-sm col-md-3 mb-md-0 mb-3">
                                <label htmlFor="dateEnd">Tanggal End</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="dateEnd"
                                    placeholder="End Date"
                                    value={waState.dataLogWa?.date_end}
                                    onChange={(e) => waAction.handleFilterWa("date_end", e.target.value)}
                                />
                            </div>
                            <div className="form-group form-group-sm col-md-3 mb-md-0 mb-3">
                                <label htmlFor="selUser">Module</label>
                                <select className="form-control" id='selUser'
                                    value={waState.filterWa?.module_act}
                                    onChange={(e) => waAction.handleFilterWa("module_act", e.target.value)}
                                >
                                    <option value={''}>- All-</option>
                                    {
                                        [...(waState.dataLogWa?.module_act_list || [])].map((d, i) => (
                                            <option value={d} key={i}>{d}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </form> */}
                </div>
            </div>

            <div className='row mb-5'>
                <div className='col-12'>
                    <GridDx
                        className='custom-style-master'
                        repaintChangesOnly={true}
                        searchForm={false}
                        ref={tableRef}
                        loading={waState.loadingWa}
                        data={waState.dataLogWa?.dataLogList}
                        cellRender={waAction.cellRender}
                        columns={waState.column_wa}
                        withAction={false}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                    >
                    </GridDx>
                </div>
            </div>
        </div>
    );
}

export default LogWhastapp;