import React, { useState } from 'react';
import useIndexLandPage from '../use_index';
import ImageCover from '../images/bg-land-detail-event.svg';
import { Container, Modal } from 'react-bootstrap';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function EventDetail(props) {
    const { indAction, indState } = useIndexLandPage();
    const [showMod, setShowMod] = useState({
        show: false,
        url: ""
    });
    const history = useHistory();
    const { state } = useLocation();
    useEffect(() => {
        indAction.setPageState({
            title: "Event Detail",
            type: "EventLandPageDetail",
            dataPage: {
                ...state
            }
        })
    }, [])
    return (
        <div className='mt-5' style={{ 'paddingTop': '35px' }}>
            <Modal size='xl' show={showMod.show} onHide={() => setShowMod({ show: false, url: '' })}>
                <div className="customShadow">
                    <img className="w-100" src={showMod.url}></img>
                </div>
            </Modal>
            <img src={ImageCover} style={{ 'position': 'absolute', width: '100%', height: '220px', top: '10px', objectFit: 'cover' }}></img>
            <Container>
                <div className='row mb-2'>
                    <div className='col-md-12 mb-5'>
                        <div className="overlay">
                            <div className="overlay-wrapper">
                                <img src={indState.pageState?.dataPage?.poster_location}
                                    style={{ maxHeight: '500px', width: '100%', objectFit: 'contain' }}
                                ></img>
                            </div>
                            <div class="overlay-layer">
                                <button className="btn btn-sm btn-light mx-1"
                                    onClick={() => setShowMod({ show: true, url: indState.pageState?.dataPage?.poster_location })}
                                ><i className="fa fa-search"></i>
                                    Lihat Detail
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2 bg-secondary rounded mx-3 p-3'>
                    <div className='col-md-4'>
                        <p><b>Deskripsi</b></p>
                        <p>{indState.pageState?.dataPage?.description}</p>
                        <div className='d-flex'>
                            <span className='mr-2'>Start Date : {moment(indState.pageState?.dataPage?.date_start).format('DD-MM-YYYY')}</span>
                            <span>End Date : {moment(indState.pageState?.dataPage?.date_end).format('DD-MM-YYYY')}</span>
                        </div>
                    </div>
                </div>
                <div className='d-inline-flex bg-transparent py-3 position-sticky my-3' style={{ bottom: '10px' }}>
                    <button className='btn btn-sm btn-secondary'
                        onClick={() => history.goBack()}
                    >
                        <i className='fas fa-chevron-circle-left icon-nm'></i>
                        Kembali
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default () => <EventDetail />;