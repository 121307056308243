import React, { memo } from 'react';
import PieChart, {
    Legend,
    Series,
    Tooltip,
    Format,
    Label,
    Connector,
    Export,
    Size
} from 'devextreme-react/pie-chart';
import { SkeletonLoading } from '..';
import { chart_color } from '../utility/utility';

const populationByRegions = [
    {
        region: 'Asia',
        val: 4119626293,
        val2: 35104756
    }, {
        region: 'Africa',
        val: 1012956064,
        val2: 35104756
    }, {
        region: 'Northern America',
        val: 344124520,
        val2: 35104756
    }, {
        region: 'Latin America and the Caribbean',
        val: 590946440,
        val2: 35104756
    }, {
        region: 'Europe',
        val: 727082222,
        val2: 35104756
    }, {
        region: 'Oceania',
        val: 35104756,
        val2: 35104756
    }
]
export default memo(
    function ({
        type = "doughnut",
        title = "Your title here",
        dataSource = populationByRegions,
        withTitle = true,
        id = "chart",
        labelFormat = "decimal",
        rotate = false,
        onrenderLabel = (e) => e.value,
        showLegend = true,
        toolsExport = true,
        colors = [],
        argumentField = "arg",
        yAxisArgs = [],
        labelChart = true,
        onRenderLabelChart = (e) => e.value,
        tooltip = true,
        children,
        LegendverticalAlignment = "top",
        LegendhorizontalAlignment = "right",
        LegenditemTextPosition = "right",
        height = 200,
        backgroundColorLabel = '',
        connectorVisible = true,
        centerRender = null,
        onRenderTooltip = (arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` }),
        loading = false,
        ...props
    }) {

        return (
            <>
                {
                    loading ? (
                        <SkeletonLoading type="doughnat" heightChart={height} />
                    ) : (
                        <PieChart
                            //height={height}
                            id="pie"
                            type={type}
                            title={withTitle ? title : null}
                            palette={colors.length > 0 ? colors : chart_color}
                            centerRender={centerRender}
                            dataSource={dataSource}
                            resolveLabelOverlapping='shift'
                            onIncidentOccurred={() => null}
                        >
                            <Size
                                height={height}
                            />
                            {yAxisArgs.map(({ valueField, name, argumentField, color, format = null }, i) => (
                                <Series
                                    key={i}
                                    argumentField={argumentField}
                                    valueField={valueField}
                                    color={color}
                                >
                                    <Label backgroundColor={backgroundColorLabel} visible={true} format={format} customizeText={(e) => onRenderLabelChart(e)}>
                                        <Connector visible={connectorVisible} />
                                    </Label>
                                </Series>
                            ))}
                            {children}
                            <Export enabled={toolsExport} />
                            <Legend visible={showLegend}
                                margin={0}
                                verticalAlignment={LegendverticalAlignment}
                                horizontalAlignment={LegendhorizontalAlignment}
                                itemTextPosition={LegenditemTextPosition} />
                            <Tooltip enabled={true} customizeTooltip={(e) => onRenderTooltip(e)}>
                                <Format type="millions" />
                            </Tooltip>
                        </PieChart>
                    )
                }
            </>
        )
    }
);
