import React, { useRef } from 'react';
import { GridDx } from '../../../../components';
import useCrudCustomer from './useCrudCustomer';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const column = [
    { fieldName: "action_", caption: "Action", allowSorting: false, allowFiltering: false },
    { fieldName: "nama", caption: "Nama" },
    { fieldName: "email", caption: "Parameter Analisis", allowSorting: false, allowFiltering: false },
    { fieldName: "institution_nama", caption: "Holding/Sub Holding" },
    { fieldName: "institution_sub_nama", caption: "Direktorat" },
    { fieldName: "hp", caption: "No. HP" },
];

function TabCustomerRegister(props) {
    const { ccState: {
        dataCR = null,
    }, ccAction } = useCrudCustomer();
    const tableRef = useRef();

    const cellRender = (rowInfo) => {
        if (rowInfo?.column?.name === "action_") {
            return <Link to="#" onClick={e => approvalClick(rowInfo.data)}>Approval</Link>
        } else {
            return rowInfo.value
        }
    }
    const approvalClick = (data = {}) => {
        ccAction.setModalCu({ show: true, data });
        // Swal.mixin({
        //     customClass: {
        //         confirmButton: 'btn btn-sm btn-success mx-1',
        //         denyButton: 'btn btn-sm btn-danger mx-1'
        //     },
        //     buttonsStyling: false
        // }).fire({
        //     html:
        //         '<strong>Approval</strong>',
        //     showDenyButton: true,
        //     confirmButtonText: 'Approve',
        //     denyButtonText:"Reject",
        //     reverseButtons: true
        // }).then((result) => {
        //     /* Read more about isConfirmed, isDenied below */
        //     if (result.isConfirmed) {
        //         ccAction.approve(id);
        //     }
        //     if (result.isDenied){
        //         ccAction.reject(id);
        //     }
        // })
    }
    return (
        <>
            {React.useMemo(() => (
                <GridDx
                    className='custom-style-master'
                    cellRender={cellRender}
                    searchForm={true}
                    ref={tableRef}
                    data={dataCR}
                    columns={column}
                    pagination={true}
                    orderingDrag={false}
                    withAction={false}
                    currentPage
                    show

                ></GridDx>
            ), [dataCR])}
        </>
    );
}

export default TabCustomerRegister;