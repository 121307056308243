import React, { useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useGlobalState } from '../../../../contexts/useGlobalState';
import useIndexLandPage from '../../use_index';
import './asset/style.css'
import { useHistory } from 'react-router-dom';
import { AuthConsumer } from '../../../../service/authenticationProvider';

const LoginDrawer = ({ onCloseDrawer = () => null }) => {
    const [state, dispatch, actions] = useGlobalState();
    const [showPass, setShowPass] = useState(false);
    const [login, setLogin] = useState({ auth: true, message: "" });
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const { indAction } = useIndexLandPage();
    const history = useHistory();


    function onSubmit() {
        actions.doLogin(false, { Email, Password }).then((resp) => {
            if (resp.code !== "00") {
                setLogin({ auth: false, message: "Email atau password salah" })
            }
            console.log('response login ', resp);
        }).catch((err) => {
            setLogin({ auth: false, message: "Email atau password salah" })
        })
    }
    return (<>
        <div className="drawer-login-card">
            <button
                className='btn'
                style={{
                    position: "absolute",
                    right: "15px",
                    background: "none",
                    border: "none",
                    fontSize: "17pt",
                    padding: "15px",
                    zIndex: 9
                }}
                onClick={() => {
                    onCloseDrawer()
                }}
            >X</button>
            <div className="drawer-login-card-body">
                <form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        setLogin({ auth: true, message: "" });
                        onSubmit();
                    }}
                >
                    <div className="row">
                        <div className="col-md">
                            <div className=" text-center" style={{ width: "100%" }}>
                                <img
                                    className="logospiirit"
                                    src="/loginasset/logospiirit.png"
                                />
                            </div>
                            <div
                                style={{ width: "100%", wordWrap: "break-word" }}
                                className="title"
                            >
                                Sistem Pengelolaan Inovasi dan Informasi Riset & Teknologi
                                Pertamina
                            </div>
                        </div>
                    </div>

                    <div className="row formLogin_">
                        <div className="col text-center" >
                            <h3 style={{ fontWeight: "bold", color: "#5867DD" }}>Masuk</h3>
                            <span className="title-text">Masukkan Email dan Password</span>
                        </div>
                    </div>
                    <div className="row text-center" style={{ marginTop: "30px" }}>
                        <div className="col-12">
                            <input
                                onChange={(e) => setEmail(e.target.value)
                                    // actions.setForm("Email", e.target.value)
                                }
                                value={Email}
                                id="email"
                                className="form-control form-control-sm col-10 form-login"
                                name="email"
                                placeholder="Username"
                                required
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className="row text-center" style={{ marginTop: "10px" }}>
                        <div className="col-12">
                            <input
                                onChange={(e) => setPassword(e.target.value)
                                    //actions.setForm("Password", e.target.value)
                                }
                                value={Password}
                                autoComplete="off"
                                id="password"
                                type={showPass ? 'text' : 'password'}
                                className="form-control form-control-sm col-10 form-login"
                                name="password"
                                placeholder="******"
                                required
                            />
                            <span className="showpass" onClick={() => setShowPass(!showPass)}><i className={showPass === true ? 'far fa-eye-slash icon-nm' : 'far fa-eye icon-nm'}></i></span>
                        </div>
                    </div>
                    <AuthConsumer>
                        {({ signinRedirect }) => (
                            <div style={{ textAlign: 'center', marginTop:'10px' }}>
                                <div role='button' className='text-primary' onClick={() => signinRedirect()}>Login With IdAMan</div>
                            </div>
                        )}
                    </AuthConsumer>
                    {
                        login.auth === false && (
                            <div className="row text-center" style={{ marginTop: "10px" }}>
                                <div className="col-12">
                                    <small className='text-danger text-wrap font-weight-normal'>{login.message}</small>
                                </div>
                            </div>
                        )
                    }
                    <div
                        className="row text-center"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-md-12">
                            <button
                                type="submit"
                                disabled={state.loadingLogin}
                                className="btn btn-sm btn-primary"
                            >
                                {state.loadingLogin ? (
                                    <>
                                        <Spinner
                                            animation="grow"
                                            variant="light"
                                            size="sm"
                                            style={{ marginRight: 5 }}
                                        />{" "}
                                        Login
                                    </>
                                ) : (
                                    <>
                                        <i className="la la-sign-in"></i>
                                        Login
                                    </>
                                )}
                            </button>
                            <span>&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                            <button
                                type='button'
                                onClick={() => {
                                    onCloseDrawer();
                                    history.push('/landing-page/create-research')
                                }}
                                //onClick={() => { onCloseDrawer(); indAction.toCreateResearch(); }}
                                className="btn bg-white btn-sm btn-outline-primary"
                            >
                                <i className="la la-pencil"></i> Create Research
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>);
}

export default LoginDrawer;