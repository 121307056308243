import React, { memo, useEffect, useState } from 'react';
import { Autocomplete } from 'devextreme-react';
import Card from 'react-bootstrap/Card'
import { useHistory, useLocation } from 'react-router'
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';
import { toString } from 'lodash';

const ModalKerjasamaMasterEdit = ({ mode, data, onSave = (e) => { } }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [state, dispatch, action] = useGlobalState();
    const [id_edit, setId_edit] = useState('');
    const [judul, setJudul] = useState();
    const [partner, setPartner] = useState([]);
    const [tempPartner, setTempPartner] = useState();
    const [tempSection, setTempSection] = useState();
    const [tempReviewer, setTempReviewer] = useState('');
    const [reviewer, setReviewer] = useState();
    const [reviewerName, setReviewerName] = useState();
    const [tempSectionName, setTempSectionName] = useState();
    const [tempReviewerName, setTempReviewerName] = useState();
    const [sectionManager, setSectionManager] = useState([]);
    const [reviewfungsi, setReviewfungsi] = useState([]);
    const [functChange, setFunctChange] = useState();
    const [funct, setFunct] = useState([]);
    const [sect, setSect] = useState([]);
    const [revi, setRevi] = useState([]);
    const [reviFungsi, setReviFungsi] = useState([]);

    useEffect(() => {
        if (state.modal.modalMasterEdit === true) {
            resetData();
            // getReviewerSource();
            getReviewerFungsiSource();
            getFunctionSource();
            if (mode === "Edit") {
                getDataEdit(data?.id);
            }
        }
    }, [state.modal.modalMasterEdit])

    function addPartner(name_partner) {
        const p = partner || [];
        if (!p.includes(name_partner)) {
            setPartner([name_partner, ...p]);
            setTempPartner('');
        }
    }
    function deletePartner(name_partner) {
        const p = partner || [];
        if (name_partner) {
            setPartner(p.filter(d => d?.nama_partner?.toString() !== name_partner));
        }
    }
    function addSectionManager(section) {
        const data = {
            "id": '',
            "kerjasama_id": id_edit,
            "chief_manager_id": section?.id,
            "show_chief_manager": section?.name
        }
        const p = sectionManager || [];
        if (section && !p.find(d => {
            return d.chief_manager_id === data?.chief_manager_id
        })) {
            setSectionManager([data, ...p]);
            setTempSectionName('');
            setTempSection();
        }
    }
    function addSectionReviewer(reviewer) {
        // const data = {
        //     "id": '',
        //     "kerjasama_id": id_edit,
        //     "reviewer_id": reviewer?.value,
        //     "reviewer_name": reviewer?.name
        // }
        const p = reviewfungsi || [];
        if (reviewer && !p.find(d => {
            // return d.reviewer_id === data?.reviewer_id
            return toString(d) === toString(reviewer)
        })) {
            setReviewfungsi([...p, reviewer]);
            //setTempReviewerName('');
            setTempReviewer('');
        }
    }
    function deleteSectionManager(section) {
        const p = sectionManager || [];
        console.log(section);
        if (section) {
            setSectionManager(p.filter(d => d?.chief_manager_id?.toString() !== section?.chief_manager_id?.toString()));
        }
    }
    function deleteReviewrFungsi(reviewer) {
        const p = reviewfungsi || [];
        if (reviewer) {
            // setReviewfungsi(p.filter(d => toString(d?.reviewer_id) !== toString(reviewer?.reviewer_id)));
            setReviewfungsi(p.filter(d => toString(d) !== toString(reviewer)));
        }
    }
    function resetData() {
        setId_edit('');
        setJudul('');
        setPartner([]);
        setReviewer();
        setTempPartner('');
        setTempSection('');
        setTempSectionName('');
        setTempReviewer('');
        setReviewfungsi([]);
        setSectionManager([]);
        setReviewerName('');
        setFunctChange('');
    }
    function getReviewerSource() {
        //try {
        action.getData('/api/ext-partnership/reviewer').then((resp) => {
            // console.log('revi', resp)
            // if (resp.code === '00') {
            //     console.log('revi', resp)
            setRevi([...(resp || [])].map(d => {
                return {
                    name: d.name,
                    value: d.id
                }
            }))
            // }
        })
        // fetch(`${baseUrl}/api/ext-partnership/reviewer`, {
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + token
        //     }
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         // console.log('reviewer', data)
        //         setRevi([...(data || [])].map(d => {
        //             return {
        //                 name: d.name,
        //                 value: d.id
        //             }
        //         }))
        //     })
        //     .catch(() => { throw new Error('Data Loading Error'); });
        // } catch (er) {
        //     return Swal.fire({
        //         title: 'Warning',
        //         text: er,
        //         icon: 'warning',
        //     })
        // }
    }
    function getReviewerFungsiSource() {
        action.getData('/api/reviewer-partnership').then((resp) => {
            setReviFungsi([...(resp?.data || [])].map(d => {
                return {
                    name: d.name,
                    value: d.id
                }
            }))
        })
    }
    function getFunctionSource() {
        try {
            action.getData('/api/param-function').then((resp) => {
                if (resp.code === '00') {
                    setFunct([...(resp.data || [])].map(d => {
                        return {
                            name: d.name,
                            value: d.id
                        }
                    }))
                }
            })
            // fetch(`${baseUrl}/api/param-function`, {
            //     headers: {
            //         Accept: 'application/json',
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + token
            //     }
            // })
            //     .then((response) => response.json())
            //     .then((data) => {
            //         // console.log('function', data)
            //         setFunct([...(data.data || [])].map(d => {
            //             return {
            //                 name: d.name,
            //                 value: d.id
            //             }
            //         }))
            //     })
            //     .catch(() => { throw new Error('Data Loading Error'); });
        } catch (er) {
            return Swal.fire({
                title: 'Warning',
                text: er,
                icon: 'warning',
            })
        }
    }
    function getSectionSourceById(id) {
        setFunctChange(id);
        if (id) {
            try {
                action.getData(`/api/param-function-manager/${id}`).then((resp) => {
                    if (resp.code === '00') {
                        setSect([...(resp.data || [])].map(d => {
                            return d
                        }))
                    }
                })
                // fetch(`${baseUrl}/api/param-function-manager/${id}`, {
                //     headers: {
                //         Accept: 'application/json',
                //         'Content-Type': 'application/json',
                //         'Authorization': 'Bearer ' + token
                //     }
                // })
                //     .then((response) => response.json())
                //     .then((data) => {
                //         // console.log('section', data)
                //         setSect([...(data.data || [])].map(d => {
                //             return d
                //         }))
                //     })
                //     .catch(() => { throw new Error('Data Loading Error'); });
            } catch (er) {
                return Swal.fire({
                    title: 'Warning',
                    text: er,
                    icon: 'warning',
                })
            }
        }
    }
    function saveClick() {
        const data = {
            "id": id_edit || '',
            "judul": judul || '',
            "reviewer": reviewfungsi.map(d => d) || [],
            "update_at": '',
            "created_by": '',
            "kerjasama_partners": [...(partner || [])],
            "kerjasama_fungsies": [...(sectionManager || [])]
        }
        const val = validation(data);
        if (val.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        } else {
            onSave(data)
        }
    }
    function getDataEdit(id) {
        action.getData(`/api/ext-partnership/view/${id}`).then((resp) => {
            if (resp.code === '00') {
                setJudul(resp.data?.header_kerjasama?.judul);
                setId_edit(id);
                setPartner(resp.data?.header_kerjasama?.kerjasama_partners);
                setReviewerName(resp.data?.header_kerjasama?.reviewer_name);
                setReviewer(resp.data?.header_kerjasama?.reviewer);
                setReviewfungsi(resp?.data?.header_kerjasama?.reviewer_array_id);
                setSectionManager(resp.data?.header_kerjasama?.kerjasama_fungsies);

            }
        })
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        if (data?.judul === '') {
            isValid = false;
            message = [...message, 'Judul'];
        }
        if (data?.reviewer?.length === 0) {
            isValid = false;
            message = [...message, 'Reviewer'];
        }
        if (data?.kerjasama_partners?.length === 0) {
            isValid = false;
            message = [...message, 'Partner'];
        }
        if (data?.kerjasama_fungsies.length === 0) {
            isValid = false;
            message = [...message, 'Fungsi'];
        }
        return {
            isValid,
            message : message.join(', ') + " Tidak boleh kosong"
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Card
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div>
                                <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>{mode === "Add" ? "Tambah" : "Edit"} Partnership MS</span>
                                <hr />
                                <div className="row px-3">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}>Judul Kerjasama</div>
                                    <div className="col-md ">
                                        <div className="form-group">
                                            <input className="form-control form-control-sm" placeholder="Judul Kerjasama" value={judul} onChange={(e) => setJudul(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-3">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}>Nama Partner</div>
                                    <div className="col-md ">
                                        <div className="col p-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="input-group mb-2">
                                                        <input className="form-control form-control-sm" placeholder="Ketik nama partner dan tekan tombol '+ Add' " value={tempPartner} onChange={(e) => setTempPartner(e.target.value)} />
                                                        <div className="text-right" style={{ width: '65px' }}>
                                                            <button type="submit" className="btn btn-sm btn-outline-primary" onClick={() => addPartner({
                                                                "id": '',
                                                                "kerjasama_id": id_edit,
                                                                "nama_partner": tempPartner
                                                            })}>+ Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5 justify-content-end">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}></div>
                                    <div className="col-md">
                                        {
                                            partner.map(d => (
                                                <div className="row px-3 mb-2">
                                                    <div className="col text-left">
                                                        <span className="d-block text-left mb-3">
                                                            <i className="fa fa-circle icon-sm text-dark mr-3"></i>
                                                            {d?.nama_partner}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto text-md-right">
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => deletePartner(d?.nama_partner)}>
                                                            <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="row px-3">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}>Reviewer</div>
                                    <div className="col-md ">
                                        {/* <div className="form-group">
                                            <Autocomplete dataSource={revi}
                                                value={reviewerName}
                                                placeholder="Reviewer"
                                                valueExpr="name"
                                                displayExpr="name"
                                                showClearButton={true}
                                                onValueChanged={(e) => setReviewerName(e.value)}
                                                onSelectionChanged={(e) => { console.log(e.selectedItem); setReviewer(e.selectedItem?.value) }}
                                                className="form-control form-control-sm p-0 mr-1">
                                            </Autocomplete>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group input-group-sm mb-2">
                                                    {/* <Autocomplete dataSource={reviFungsi}
                                                        value={tempReviewerName}
                                                        placeholder="Pilih dan tekan tombol '+ Add'"
                                                        valueExpr="name"
                                                        displayExpr="name"
                                                        onValueChanged={(e) => setTempReviewerName(e.value)}
                                                        onSelectionChanged={(e) => setTempReviewer(e.selectedItem)}
                                                        className="form-control form-control-sm p-0 mr-1">
                                                    </Autocomplete> */}
                                                    <select className='form-control' value={tempReviewer} onChange={(e) => setTempReviewer(e.target.value)}>
                                                        <option value={''}>- Pilih -</option>
                                                        {
                                                            reviFungsi.map((d, i) => (
                                                                <option key={i} value={d?.value}>{d?.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <div className="text-right" style={{ width: '65px' }}>
                                                        <button type="submit" className="btn btn-sm btn-outline-primary" onClick={() => addSectionReviewer(tempReviewer)}>+ Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  mb-5 px-3">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}></div>
                                    <div className="col-md ">
                                        {
                                            reviewfungsi.map((d, i) => (
                                                <div className="row mb-2" key={i}>
                                                    <div className="col">
                                                        <span className="d-block text-left mb-3">
                                                            <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                                            {reviFungsi.find(x => toString(x?.value) === toString(d))?.name}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => deleteReviewrFungsi(d)}>
                                                            <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="row px-3">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}>Fungsi VP</div>
                                    <div className="col-md ">
                                        <div className="form-group">
                                            <select className="form-control form-control-sm" placeholder="Judul Kerjasama" value={functChange} onChange={(e) => getSectionSourceById(e.target.value)} >
                                                <option value="">- Pilih Fungsi VP -</option>
                                                {
                                                    funct.map((d, i) => (
                                                        <option key={i} value={d.value}>{d.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-3">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}>Fungsi Manager</div>
                                    <div className="col-md ">
                                        <div className="col p-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="input-group mb-2">
                                                        <Autocomplete dataSource={sect}
                                                            value={tempSectionName}
                                                            placeholder="Pilih dan tekan tombol '+ Add'"
                                                            valueExpr="name"
                                                            displayExpr="name"
                                                            onValueChanged={(e) => setTempSectionName(e.value)}
                                                            onSelectionChanged={(e) => setTempSection(e.selectedItem)}
                                                            className="form-control form-control-sm p-0 mr-1">
                                                        </Autocomplete>
                                                        <div className="text-right" style={{ width: '65px' }}>
                                                            <button type="submit" className="btn btn-sm btn-outline-primary" onClick={() => addSectionManager(tempSection)}>+ Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-3">
                                    <div className="col-md-4 text-left" style={{ fontSize: '8pt' }}></div>
                                    <div className="col-md ">
                                        {
                                            sectionManager.map((d, i) => (
                                                <div className="row mb-2" key={i}>
                                                    <div className="col">
                                                        <span className="d-block text-left mb-3">
                                                            <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                                            {d.show_chief_manager}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => deleteSectionManager(d)}>
                                                            <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="d-block text-right">
                                <button className="btn btn-secondary btn-sm mx-1" onClick={() => { action.toggleModal('modalMasterEdit'); }}>Batal</button>
                                <button className="btn btn-primary btn-sm mx-1" onClick={() => saveClick()}>Simpan</button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}
export default ModalKerjasamaMasterEdit;