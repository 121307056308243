import React, { useEffect } from 'react';
import useCrudEquipment from './useCrudEquipment';
import { TagBox } from 'devextreme-react';

function ModalEditingLogbook({ mode = 'Add', data = {} }) {
    const { ceqAction, ceqState } = useCrudEquipment();

    useEffect(() => {
        if (ceqState.globalState?.modal?.modalEqL === true) {
            ceqAction.setEditorLogbook(data, ceqState.logbook_eq_id);
        }
    }, [ceqState.globalState?.modal?.modalEqL])
    return (
        <div>
            <h5>{mode} Logbook</h5>
            <hr />
            <div className="row mb-3">
                <div className="col-md-3">
                    <span className="d-block text-wrap">Tanggal pemakaian</span>
                </div>
                <div className="col-md">
                    <div className='d-flex'>
                        <input type='date' className="form-control form-control-sm"
                            onChange={(e) => ceqAction.setTanggal_mulai_l(e.target.value)} value={ceqState.tanggal_mulai_l}
                        ></input>
                        <span className="d-block text-wrap my-auto mx-5">Sampai</span>
                        <input type='date' className="form-control form-control-sm"
                            onChange={(e) => ceqAction.setTanggal_selesai_l(e.target.value)} value={ceqState.tanggal_selesai_l}
                        ></input>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    <span className="d-block text-wrap">Pemakai</span>
                </div>
                <div className="col-md">
                    <TagBox
                        searchEnabled={true}
                        id="inventor"
                        value={ceqState.pemakai}
                        items={ceqState.source_pemakai}
                        displayExpr="name"
                        displayValue="name"
                        valueExpr="id"
                        className="mb-5"
                        onValueChange={(e) => ceqAction.setPemakai(e)}
                    />
                    {/* <div className="input-group input-group-sm mb-2" >

                        <select className="form-control"
                            onChange={(e) => ceqAction.setPemakaiTemp(e.target.value)} value={ceqState.pemakaiTemp}
                        >
                            <option value={''}>- Pilih -</option>
                            {
                                [...(ceqState.data_edit?.data_users || [])].map((d, i) => (
                                    <option value={JSON.stringify({ id: d?.id, name: d?.name })} key={i}>{d?.name}</option>
                                ))
                            }
                        </select>
                        <div className="text-right" style={{ width: '65px' }}>
                            <button type="submit" className="btn btn-sm btn-primary"
                                onClick={() => ceqAction.addPemakai(ceqState.pemakaiTemp)}
                            >+ Add</button>
                        </div>
                    </div>
                    <div>
                        {
                            [...(ceqState.pemakai || [])].map((d, i) => (
                                <div className="row mb-2" key={i}>
                                    <div className="col-md-10">
                                        <span className="d-block text-left mb-3">
                                            <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                            {d?.name}
                                        </span>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn btn-sm btn-outline-danger" onClick={() => ceqAction.deletePemakai(d)}>
                                            <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div> */}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    <span className="d-block text-wrap">Deskripsi</span>
                </div>
                <div className="col-md">
                    <textarea rows={5} className="form-control form-control sm"
                        onChange={(e) => ceqAction.setDeskripsi_logbook(e.target.value)} value={ceqState.deskripsi_logbook}
                    ></textarea>
                </div>
            </div>
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={() => ceqAction.globalAction.toggleModal('modalEqL')}
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-primary mx-1"
                    onClick={() => ceqAction.simpanLogbookClick()}
                >
                    <i className="fa fa-save icon-nm"></i>
                    Simpan
                </button>
            </div>
        </div>
    );
}

export default ModalEditingLogbook;