import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Spinner } from "react-bootstrap";

function Index(props) {
    const [state, , actions] = useGlobalState();
    const [showPass, setShowPass] = useState(false);
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');

    function onSubmit() {
        actions.doLogin(true, { Email, Password });
    }
    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div
                className='customShadow rounded'
                style={{
                    padding: '30px',
                    width: '350px'
                }}
            >
                <form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        onSubmit();
                    }}
                >
                    <div className="row">
                        <div className="col-md">
                            <div className=" text-center" style={{ width: "100%" }}>
                                <img
                                    style={{ height: '50px' }}
                                    src="/loginasset/logospiirit.png"
                                />
                            </div>
                            <div
                                style={{ width: "100%", wordWrap: "break-word", textAlign: 'center', marginTop: '25px' }}
                            >
                                Sistem Pengelolaan Inovasi dan Informasi Riset & Teknologi
                                Pertamina
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ paddingTop: '50px' }}>
                        <div className="col text-center" >
                            <h3 style={{ fontWeight: "bold", color: "#5867DD" }}>Masuk</h3>
                            <span className="title-text">Masukkan Email dan Password</span>
                        </div>
                    </div>
                    <div className="row text-center" style={{ marginTop: "30px" }}>
                        <div className="col-12">
                            <input
                                onChange={(e) => setEmail(e.target.value)
                                    // actions.setForm("Email", e.target.value)
                                }
                                value={Email}
                                id="email"
                                className="form-control form-control-sm col-10 form-login"
                                name="email"
                                placeholder="Username"
                                required
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className="row text-center" style={{ marginTop: "10px" }}>
                        <div className="col-12">
                            <input
                                onChange={(e) => setPassword(e.target.value)
                                    //actions.setForm("Password", e.target.value)
                                }
                                value={Password}
                                autoComplete="off"
                                id="password"
                                type={showPass ? 'text' : 'password'}
                                className="form-control form-control-sm col-10 form-login"
                                name="password"
                                placeholder="******"
                                required
                            />
                            <span className="showpass" onClick={() => setShowPass(!showPass)}><i className={showPass === true ? 'far fa-eye-slash icon-nm' : 'far fa-eye icon-nm'}></i></span>
                        </div>
                    </div>
                    <div
                        className="row text-center"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-md-12">
                            <button
                                type="submit"
                                disabled={state.loadingLogin}
                                className="btn btn-sm btn-primary"
                            >
                                {state.loadingLogin ? (
                                    <>
                                        <Spinner
                                            animation="grow"
                                            variant="light"
                                            size="sm"
                                            style={{ marginRight: 5 }}
                                        />{" "}
                                        Login
                                    </>
                                ) : (
                                    <>
                                        <i className="la la-sign-in"></i>
                                        Login
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Index;