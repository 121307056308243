// export { default as HomePage } from './home/home';
// export { default as HighlightDashboard } from './highlightDashboard/index'
// export { default as Portfolio } from './portfolio/index'
// export { default as PortfolioChart12 } from './portfolio/detail_leader';
// export { default as CreateProjectABI } from './createProject/createProjectABIABO'
// export { default as AbiAboRequest } from './abiAboRequest/abiAboRequest'
// export { default as AbiAboMon } from './abiMonitoring/abiMonitoring'
// export { default as AbiAboApproval } from './approvalABIABOPort/approvalABIABOPort'
// export { default as AbiAboMonApproval } from './approvalABIABOMon/approvalABIABOMon'
// export { default as AbiAboCRUD } from './crudAbi/abiCRUD'
// export { default as AbiAboMonirotingCRUD } from './crudAbiMonitoring/abiMonitoringCRUD'
// export { default as AbiAboRequestViewEdit } from './abiAboRequestViewEdit/abiAboRequestViewEdit'
// export { default as AbiAboRequestViewPublic } from './abiAboRequestViewPublic/abiAboRequestViewEdit'
// export { default as CreateProjectNew } from './createProjectNew';
// export { default as ReportingAbiPraProyek } from './reportingAbiPraProyek/reportingAbiPraProyek'
// export { default as ReportingAbiMonitoring } from './reportingAbiMonitoring/reportingAbiMonitoring'
// export { default as OngoingAbi } from './monitoringAbiViewEdit/index'
// export { default as OngoingAbiPublic } from './monitoringAbiViewPublic/index'
// export { default as OngoingAbiAdmin } from './monitoringAbiViewEditAdmin/index'
// export { default as AbiHistories } from './abiHistories/abiHistories'
// export { default as AbiHistoriesAdmin } from './abiHistoriesAdmin/abiHistories'
// export { default as AbiMonthly } from './abiMonthly/abiMonthly'
// export { default as AbiMonthlyAdmin } from './abiMonthlyAdmin/abiMonthly'
// export { default as IdeationCRUD } from './crudIdeation/ideationCrud'
// export { default as ReportingIdeations } from './reportingIdeations/reportingIdeations'
// export { default as ViewEditProject } from './ideationViewEdit/viewEditProject'
// export { default as ViewEditProjectPublic } from './ideationViewPublic/viewEditProject';
// export { default as ViewEditProjectAdmin } from './ideationViewEditAdmin/viewEditProject';
export { default as AdminHome } from './adminHome/dashboard'
export { default as Login } from './login/login'
export { default as ProfilePage } from './profile/profile';
export { default as MyProject } from './myproject'
export { default as CreateProject } from './createProject/createProject'
export { default as CreateProjectOperasional } from './createProject/createProjectOperational'
export { default as CreateProjectCommercial } from './createProject/createProjectCommercial'
// export { default as Ideations } from './ideation/index'
export { default as Monitoring } from './monitoring/monitoring'
export { default as Kerjasama } from './kerjasama/kerjasama'
export { default as KerjasamaMapDetail } from './kerjasama/mapDetail';
export { default as KerjasamaDetail } from './kerjasama/kerjasamaDetail'
export { default as PortFolioViewEdit } from './portFolioViewEdit/index'
export { default as PortFolioViewPublic } from './portFolioViewPublic/index'
export { default as PortFolioViewEditAdmin } from './portFolioViewEditAdmin/index'
export { default as MonitoringViewEdit } from './monitoringViewEdit/index'
export { default as MonitoringViewPublic } from './monitoringViewPublic/index'
export { default as MonitoringViewEditAdmin } from './monitoringViewEditAdmin/index'
export { default as MonitoringEditMember } from './monitoringViewEdit/editTeamMember'
export { default as MonitoringEditProjectUnggulan } from './monitoringViewEdit/editProjectUnggulan'
export { default as MonitoringProjectPrioritySubholding } from './monitoringViewEditAdmin/projectPrioritySubholding'
export { default as People } from './people/index'
export { default as Achievement } from './people/achievement'
export { default as Event } from './people/event'
export { default as Patent } from './people/patent'
export { default as AchievementDetail } from './people/achievementDetail'
export { default as AchievementDetailHrd } from './people/achievementDetailHrd'
export { default as AchievementDetailAdmin } from './people/achievementDetailAdmin'
export { default as PortfolioCRUD } from './crudPortfolio/portfolioCrud'
export { default as MonitoringCRUD } from './crudMonitoring/projectMonitoringCrud'
export { default as KerjasamaCRUD } from './crudKerjasama/kerjasamaCRUD'
export { default as KerjasamaReporting } from './reportingKerjasama/reportingKerjasama'
export { default as KerjasamaAdd } from './crudKerjasama/add-edit-kerjasama/addPage';
export { default as KerjasamaEdit } from './crudKerjasama/add-edit-kerjasama/editPage';
export { default as ProjectApproval } from './approvalProject/index'
export { default as PortfolioApproval } from './approvalPortfolio/index'
export { default as KerjasamaDetailEdit } from './crudKerjasama/kerjasamaDetailEdit'
export { default as ReportingHighlight } from './reportingHighlight/reportingHighlight'
export { default as ReportingPortfolio } from './reportingPortfolio/reportingPortfolio'
export { default as ReportingMonitoring } from './reportingMonitoring/reportingMonitoring'
export { default as SettingCutOff } from './settingCutOff/settingCutOff'
export { default as SettingResetCache } from './settingResetCache/settingResetCache'
export { default as SettingMigrationProject } from './settingMigrationProject/settingMigrationProject'
export { default as SettingMasterFAQ } from './settingMasterFAQ/settingMasterFAQ'
export { default as SettingMasterAbaout } from './settingMasterAbout/settingMasterAbout'
export { default as Screensaver } from './screensaver/screensaver'
export { default as AdminPeopleCrud } from './adminPeopleCrud/adminPeopleCrud'
export { default as AdminPeopleCrudView } from './adminPeopleCrud/adminPeopleView'
export { default as MonthlyReportPage } from './report/monthlyReport'
export { default as MonthlyReportPageAdmin } from './reportAdmin/monthlyReport';
export { default as FinalReportPage } from './report/finalReport'
export { default as ProjectHistories } from './projectHistories/projectHistories'
export { default as ProjectHistoriesAdmin } from './projectHistoriesAdmin/projectHistories'
export { default as AllInventor } from './people/allInventor'
export { default as EventDetail } from './people/eventDetail'
export { default as EventDetailAdmin } from './people/eventDetailAdmin'
export { default as AdminCRUDAchievement } from './adminPeopleAchievement/dataCRUD'
export { default as AdminApprovalAchievement } from './adminPeopleAchievement/approval'
export { default as AdminReportingAchievement } from './adminPeopleAchievement/reporting'
export { default as AddAchievement } from './adminPeopleAchievement/addAchievement'
export { default as EditAchievement } from './adminPeopleAchievement/editAchievement'
export { default as PatentDetail } from './people/patentDetail'
export { default as PatentDetailPublic } from './people/patentDetailPublic'
export { default as PatentDetailAdmin } from './people/patentDetailAdmin'
export { default as AdminCrudPatent } from './adminPeoplePatent/tableCrud'
export { default as AddPatent } from './adminPeoplePatent/addPatent';
export { default as AdminApprovalPatent } from './adminPeoplePatent/tableApproval';
export { default as EditPatent } from './adminPeoplePatent/editPatent';
export { default as AdminCrudEvent } from './adminPeopleEvent/tableCRUD';
export { default as AddEvent } from './adminPeopleEvent/addEvent';
export { default as EditEvent } from './adminPeopleEvent/editEvent';
export { default as ChangePassword } from './changePassword/index'
export { default as FaqPage } from './faq/faq'
export { default as PeopleReporting } from './adminPeopleCrud/adminPeopleReporting'
export { default as PeoplePatentReporting } from './adminPeoplePatent/reporting'
export { default as KnowledgeResearch } from './knowledgeMng/research'
export { default as KnowledgeLibrary } from './knowledgeMng/library'
export { default as Inventory } from './inventory/inventory'
export { default as ProducrMng } from './productMng/productMng'
export { default as ProductCRUD } from './crudProduct/crudProduct';
export { default as ProductAdd } from './crudProduct/formAddMaster';
export { default as ProductEdit } from './crudProduct/formEditMaster';
export { default as ProductApproval } from './approvalProduct/approvalProduct';
export { default as ProductDetail } from './productMng/productDetail';
export { default as ProductDetailAdmin } from './productMng/productDetailAdmin';
export { default as ProductReport } from './reportingProduct/reportingProduct';
export { default as EditProfile } from './profile/editProfile'
export { default as EquipmentCRUD } from './crudEquipment/crudEquipment';
export { default as AddEquipment } from './crudEquipment/add-edit-equipment/addPage';
export { default as EditEquipment } from './crudEquipment/add-edit-equipment/editPage';
export { default as EquipmentReporting } from './reportingEquipment/reportingEquipment';
export { default as NotAllowPage } from './pageNotAllow/index';
export { default as SessionExpired } from './sessionExpired/index';
export { default as OngoingTableSearch } from './monitoring/detail';
export { default as InventorDetail } from './people/inventorDetail';
export { default as LibraryDetail } from './knowledgeMng/libraryDetail';
export { default as LibraryDetailAdmin } from './knowledgeMng/libraryDetailAdmin';
export { default as ResearchDetail } from './knowledgeMng/researchDetail';
export { default as ResearchDetailAdmin } from './knowledgeMng/researchDetailAdmin';
export { default as LibraryCRUD } from './crudKmLibrary/crudKmLibrary';
export { default as LibraryReport } from './reportingKmLibrary/reportingKmLibrary';
export { default as LibraryAdd } from './crudKmLibrary/addKmLibrary';
export { default as LibraryEdit } from './crudKmLibrary/editLibrary';
export { default as ResearchCRUD } from './crudKmResearch/crudKmResearch';
export { default as ResearchReport } from './reportingKmResearch/reportingKmResearch';
export { default as ResearchAdd } from './crudKmResearch/addKmResearch';
export { default as ResearchEdit } from './crudKmResearch/editKmResearch';
export { default as MonitoringSub } from './monitoringSubholdings/index';
export { default as Commingsoon } from './commingsoon/index';
export { default as AllNotif } from './allNotification/index';
export { default as ErrorPage } from './errorpage/index';

// export { default as CardMenu } from './'
export { default as TasksPage } from './tasks/tasks';
export { default as TestPage } from './Test/Test';
export { default as NewLogin } from './newLogin';

export { default as UserMngUserRole } from './usermng/user-role/index';
export { default as UserMngDash } from './usermng/dashboard/dashboard';
export { default as UserMngTable } from './usermng/tableDetail';
export { default as UserMngWhatsapp } from './usermng/whatsapp';
// export { default as UserMngPageSession } from './usermng/pageSession';

export { default as SettingApplication } from './settingAplication/index';
export { default as SettingParameter } from './settingParameter/index';

export { MonthlyReport, ProjectProfile } from './reportingPdf';
export { MonthlyReportAdmin, ProjectProfileAdmin } from './reportingPdfAdmin';
export { MonthlyReportMultiple, ProjectProfileMultiple } from './reportingPdfMultiple';

export { default as Swakelola } from './swakelola'
export { default as SwakelolaAdmin } from './crudSwakelola/crudSwakelola'
export { default as SwakelolaReport } from './reportingSwakelola/reportingSwakelola'
export { default as SwakelolaAdd } from './crudSwakelola/addSwakelola';
export { default as SwakelolaEdit } from './crudSwakelola/editSwakelola';
export { default as SwakelolaDetail } from './swakelola/detail';
export { default as SwakelolaDetailAdmin } from './swakelola/detailAdmin';
export { default as EquipmentMng } from './equipmentMng';
export { default as EquipmentMngDetail } from './equipmentMng/equipmentDetail';
export { default as EquipmentMngDetailAdmin } from './equipmentMng/equipmentDetailAdmin';

export { default as MonitoringChart12 } from './monitoring/detail_leader';
export { default as LandingPage } from './landing_page/index';
export { default as ApprovalKerjasama } from './approvalKerjasama';
export * from './reportingPreIdeasi';

export { default as MonitoringProjectChampions } from './projectChampions';
export { default as MonitoringProjectChampionsReporting } from './reportingProjectChampions';

export { default as BroadcastEmail } from './broadcast-email/index.js';
export { default as UserLoginReminder } from './usermng/whatsapp/page-detail/detail-user-login-reminder';
export { default as UserProfileReminder } from './usermng/whatsapp/page-detail/detail-user-profile-reminder';
export { default as EquipmentReminder } from './usermng/whatsapp/page-detail/detail-equipment-expired';
export { default as DetailReminder } from './usermng/whatsapp/page-detail/detail-reminder';
export { default as DetailNeedAttention } from './usermng/whatsapp/page-detail/detail-need-attention';
export { default as dashboardUserTabel } from './dashboardUserTable';
export { default as MppsPage } from './monitorinProjectPrioritySubholding';
export { default as MonitoringDetailSubholding } from './monitoringViewSubholding/index';
export { default as ReportStatusAlat } from './reporting-status-equipment';

export { default as ApprovalKerjasamaDetail } from './approvalKerjasama/detail-approval/detailApproval';
export { default as PrivateLogin } from './privateLogin';
export { default as MaintenancePage } from './maintenance';
export * from './admin';
export * from './nonAdmin/index.js';