import React, { useEffect, useRef, useMemo } from 'react';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { HeaderList, CstmFilterCahrtDx, ChartDx, DoughnatDx, SwitchBtn, GridDx } from '../../../components';
import useUserMngDash, { UsrMngDashProvider } from './usedashboardUsrMng';
import './userMng.css';
import SVG from "react-inlinesvg";
import { generate_prev_year, month_source_mm, formatter_int } from '../../../components/utility/utility';
import { Link } from 'react-router-dom';

const columns = [
    { fieldName: 'page', caption: 'Page', width: 200 },
    { fieldName: 'module', caption: 'Module', width: 200 },
    { fieldName: 'total_hits', caption: 'Total Hits', width: 150 },
    { fieldName: 'jan', caption: 'JAN', width: 100 },
    { fieldName: 'feb', caption: 'FEB', width: 100 },
    { fieldName: 'mar', caption: 'MAR', width: 100 },
    { fieldName: 'apr', caption: 'APR', width: 100 },
    { fieldName: 'may', caption: 'MAY', width: 100 },
    { fieldName: 'jun', caption: 'JUN', width: 100 },
    { fieldName: 'jul', caption: 'JUL', width: 100 },
    { fieldName: 'aug', caption: 'AUG', width: 100 },
    { fieldName: 'sep', caption: 'SEP', width: 100 },
    { fieldName: 'oct', caption: 'OCT', width: 100 },
    { fieldName: 'nov', caption: 'NOV', width: 100 },
    { fieldName: 'dec', caption: 'DEC', width: 100 },
]

function DashboardUsrMng(props) {
    const { userDashState, usrDashAction } = useUserMngDash();
    const ChartPosition = useMemo(() => (
        <>
            <ChartDx
                height="not-set"
                loading={userDashState.loadingcp}
                colorBarDifferent={true}
                argumentField="nama"
                colorBarDiffirentField="nama"
                coloBarDifferentValueField="jumlah"
                rotate={true}
                dataSource={userDashState.data_cposition}
                withTitle={false}
                showLegend={false}
                toolsExport={false}
                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
            />
        </>
    ), [userDashState.loadingcp, userDashState.data_cposition]);

    const ChartUserAdmin = useMemo(() => (
        <>
            <ChartDx
                height="auto"
                loading={userDashState.loadingca}
                colorBarDifferent={true}
                argumentField="nama"
                colorBarDiffirentField="nama"
                coloBarDifferentValueField="jumlah"
                rotate={true}
                dataSource={userDashState.data_cadmin}
                withTitle={false}
                showLegend={false}
                toolsExport={false}
                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
            />
        </>
    ), [userDashState.loadingca, userDashState.data_cadmin]);

    const customCellRender = (rowInfo) => {
        if (rowInfo.column.name === 'jumlah') {
            return (<span className='d-block text-wrap text-right'>{formatter_int.format(rowInfo.value)}</span>)
        } else {
            return (<span className='d-block text-wrap'>{rowInfo.value}</span>)
        }
    }

    useEffect(() => {
        usrDashAction.getDashboardDaily();
    }, [])
    return (
        <div>
            <HeaderList
                title={userDashState.data_page?.page_title}
                someText={userDashState.data_page?.page_breadcrumbs}
                customOption={<>
                    <SplitButton
                        id={`dropdown-split-variants-primary`}
                        variant={'primary'}
                        title={userDashState.downFilter?.name}
                        size="sm"
                        className="mb-lg-0 mb-md-0 mb-5"
                    >
                        <Dropdown.Item eventKey="1" onClick={(e) => usrDashAction.dropdownChange({ val: '1', name: 'Daily' })} active={userDashState.downFilter?.val === '1' ? true : false}>Daily</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={(e) => usrDashAction.dropdownChange({ val: '2', name: 'Monthly' })} active={userDashState.downFilter?.val === '2' ? true : false}>Monthly</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={(e) => usrDashAction.dropdownChange({ val: '3', name: 'Yearly' })} active={userDashState.downFilter?.val === '3' ? true : false}>Yearly</Dropdown.Item>
                        {/* <Dropdown.Item eventKey="4" onClick={(e) => usrDashAction.dropdownChange({ val: '4', name: 'Custom Range' })} active={userDashState.downFilter?.val === '4' ? true : false}>Custom Range</Dropdown.Item> */}
                    </SplitButton>
                    {
                        userDashState.downFilter?.val === '1' &&
                        <>
                            <input type='date' className='form-control form-control-sm mx-md-1 mb-lg-0 mb-md-0 mb-5' style={{ width: 200 }}
                                value={userDashState.ftrDaily}
                                onChange={(e) => usrDashAction.ftrDailyChange(e.target.value)}
                            >
                            </input>
                        </>
                    }
                    {
                        userDashState.downFilter?.val === '2' &&
                        <>
                            <select className='form-control form-control-sm mx-md-1 mb-lg-0 mb-md-0 mb-5' style={{ width: 200 }}
                                value={userDashState.ftrMonthly?.bulan}
                                onChange={(e) => usrDashAction.getDashboardMonthly(userDashState.ftrMonthly?.tahun, e.target.value)}
                            >
                                <option value={''}> - Pilih Bulan -</option>
                                {
                                    month_source_mm.map((d, i) => (
                                        <option key={i} value={d?.value}>{d?.name}</option>
                                    ))
                                }
                            </select>
                            <select className='form-control form-control-sm mx-md-1 mb-lg-0 mb-md-0 mb-5' style={{ width: 200 }}
                                value={userDashState.ftrMonthly?.tahun}
                                onChange={(e) => usrDashAction.getDashboardMonthly(e.target.value, userDashState.ftrMonthly?.bulan)}
                            >
                                <option value={''}> - Pilih Tahun -</option>
                                {
                                    generate_prev_year().map((d, i) => (
                                        <option key={i} value={d?.value}>{d?.name}</option>
                                    ))
                                }
                            </select>
                        </>
                    }
                    {
                        userDashState.downFilter?.val === '3' &&
                        <>
                            <select className='form-control form-control-sm mx-md-1 mb-lg-0 mb-md-0 mb-5' style={{ width: 200 }}
                                value={userDashState.ftrYearly}
                                onChange={(e) => usrDashAction.getDashboardYearly(e.target.value)}
                            >
                                <option value={''}> - Pilih Tahun -</option>
                                {
                                    generate_prev_year().map((d, i) => (
                                        <option key={i} value={d?.value}>{d?.name}</option>
                                    ))
                                }
                            </select>
                        </>
                    }
                    {
                        userDashState.downFilter?.val === '4' &&
                        <>
                            <input type='date' className='form-control form-control-sm mx-md-1 mb-lg-0 mb-md-0 mb-5' style={{ width: 200 }}>
                            </input>
                            <input type='date' className='form-control form-control-sm mx-md-1 mb-lg-0 mb-md-0 mb-5' style={{ width: 200 }}>
                            </input>
                        </>
                    }
                </>}
            />
            <div className='row mb-5'>
                <div className='col-lg-2 col-md-2 col-12 mb-5'>
                    <div className='card p-5 customShadow bg-primary card-style'>
                        <div className="d-flex mb-5">
                            <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                <SVG src="/media/svg/icons/General/User.svg" />
                            </span>
                            <div className="pl-3 text-white">
                                <span className="d-block">Users</span>
                            </div>
                        </div>
                        <h5 className="d-block text-right text-white">{userDashState.data?.databox1 || 0}</h5>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-12 mb-5'>
                    <div className='card p-5 customShadow bg-blue card-style'>
                        <div className="d-flex mb-5">
                            <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                <SVG src="/media/svg/icons/Communication/Flag.svg" />
                            </span>
                            <div className="pl-3 text-white">
                                <span className="d-block">Visit</span>
                            </div>
                        </div>
                        <h5 className="d-block text-right text-white">{userDashState.data?.databox2 || 0}</h5>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-12 mb-5'>
                    <div className='card p-5 customShadow bg-purple card-style'>
                        <div className="d-flex mb-5">
                            <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                <SVG src="/media/svg/icons/Files/Selected-file.svg" />
                            </span>
                            <div className="pl-3 text-white">
                                <span className="d-block">Page View</span>
                            </div>
                        </div>
                        <h5 className="d-block text-right text-white">{userDashState.data?.databox3 || 0}</h5>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-12 mb-5'>
                    <div className='card p-5 customShadow bg-orange card-style'>
                        <div className="d-flex mb-5">
                            <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                <SVG src="/media/svg/icons/Code/Warning-2.svg" />
                            </span>
                            <div className="pl-3 text-white">
                                <span className="d-block">Bounce Rate</span>
                            </div>
                        </div>
                        <h5 className="d-block text-right text-white">{parseFloat(userDashState.data?.databox4).toFixed(2) || 0} %</h5>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-12 mb-5'>
                    <div className='card p-5 customShadow bg-success card-style'>
                        <div className="d-flex mb-5">
                            <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                <SVG src="/media/svg/icons/Files/File.svg" />
                            </span>
                            <div className="pl-3 text-white">
                                <span className="d-block">Avg Page Per Visit</span>
                            </div>
                        </div>
                        <h5 className="d-block text-right text-white">{userDashState.data?.databox5 || 0}</h5>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-12 mb-5'>
                    <div className='card p-5 customShadow bg-warning card-style'>
                        <div className="d-flex mb-5">
                            <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                <SVG src="/media/svg/icons/Home/Timer.svg" />
                            </span>
                            <div className="pl-3 text-white">
                                <span className="d-block">Avg Minute Per Visit</span>
                            </div>
                        </div>
                        <h5 className="d-block text-right text-white">{userDashState.data?.databox6 || 0} m</h5>
                    </div>
                </div>
            </div>
            <div className='card customShadow p-5 mb-5'>
                <div className='row'>
                    <div className='col-md-6 mb-5'>
                        <CstmFilterCahrtDx
                            title="Visit"
                            subtitle="Grafik kunjungan ke aplikasi SPIIRIT"
                            cardStyle={false}
                            withBorder={false}
                        >
                            <ChartDx
                                argumentField="nama"
                                dataSource={userDashState.data?.datascurve1}
                                type="spline"
                                withTitle={false}
                                showLegend={false}
                                toolsExport={false}
                                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                onRenderLabelChart={(e) => { }}
                                yAxisArgs={[
                                    {
                                        valueField: "jumlah",
                                        color: '#0F6EB5'
                                    }
                                ]}
                            />
                        </CstmFilterCahrtDx>
                    </div>
                    <div className='col-md-6 mb-5'>
                        <CstmFilterCahrtDx
                            title="Page View"
                            subtitle="Grafik kunjungan setiap halaman di aplikasi SPIIRIT"
                            cardStyle={false}
                            withBorder={false}
                        >
                            <ChartDx
                                argumentField="nama"
                                type="spline"
                                dataSource={userDashState.data?.datascurve2}
                                withTitle={false}
                                showLegend={false}
                                toolsExport={false}
                                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                onRenderLabelChart={(e) => { }}
                                yAxisArgs={[
                                    {
                                        valueField: "jumlah",
                                        color: '#8853E7'
                                    }
                                ]}
                            />
                        </CstmFilterCahrtDx>
                    </div>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-lg-8 col-md-8 col-12 mb-5'>
                    <CstmFilterCahrtDx
                        title={`TOP 10 User by ${!userDashState.sw1 ? "Page View" : "Visit"}`}
                        subtitle="User yang paling sering mengunjungi SPIIRIT"
                        customOption={
                            <div className='d-inline-flex'>
                                {/* <SwitchBtn className='my-2' value={userDashState.sw1} onChange={(e) => usrDashAction.setSw1(e)} /> */}
                                {/* <Link onClick={() => usrDashAction.tableUserClick(userDashState.downFilter?.name)} className="d-flex my-auto ">
                                    <i class="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                </Link> */}
                                {
                                    userDashState.downFilter?.val === '1' && <Link
                                        target={'_blank'}
                                        to={`/admin/usr-mng/table-user-daily/${userDashState.ftrD.tahun}/${userDashState.ftrD.bulan}/${userDashState.ftrD.hari}`}
                                        className="d-flex my-auto ">
                                        <i class="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                    </Link>
                                }
                                {
                                    userDashState.downFilter?.val === '2' && <Link
                                        target={'_blank'}
                                        to={`/admin/usr-mng/table-user-monthly/${userDashState.ftrMonthly.tahun}/${userDashState.ftrMonthly.bulan}`}
                                        className="d-flex my-auto ">
                                        <i class="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                    </Link>
                                }
                                {
                                    userDashState.downFilter?.val === '3' && <Link
                                        target={'_blank'}
                                        to={`/admin/usr-mng/table-user-yearly/${userDashState.ftrYearly}`}
                                        className="d-flex my-auto ">
                                        <i class="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                    </Link>
                                }
                            </div>
                        }
                    >
                        <ChartDx
                            colorBarDifferent={true}
                            argumentField="nama"
                            colorBarDiffirentField="nama"
                            coloBarDifferentValueField={!userDashState.sw1 ? "jumlah_b" : "jumlah"}
                            rotate={true}
                            overlappingBehavior={'stagger'}
                            dataSource={!userDashState.sw1 ? [...(userDashState.data?.datachart3 || [])].sort((a, b) => parseFloat(a.jumlah_b) - parseFloat(b.jumlah_b)) :
                                [...(userDashState.data?.datachart3 || [])].sort((a, b) => parseFloat(a.jumlah) - parseFloat(b.jumlah))}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-lg-4 col-md-4 col-12 mb-5'>
                    <CstmFilterCahrtDx
                        title={`TOP Function by ${!userDashState.sw2 ? "Page View" : "Visit"}`}
                        subtitle="Fungsi yang paling sering mengunjungi SPIIRIT"
                        customOption={
                            <div className='d-inline-flex'>
                                {/* <SwitchBtn className='my-2' value={userDashState.sw2} onChange={(e) => usrDashAction.setSw2(e)} /> */}
                            </div>
                        }
                    >
                        <ChartDx
                            colorBarDifferent={true}
                            argumentField="nama"
                            colorBarDiffirentField="nama"
                            coloBarDifferentValueField={!userDashState.sw2 ? "jumlah_b" : "jumlah"}
                            rotate={true}
                            dataSource={!userDashState.sw2 ? [...(userDashState.data?.datachart4 || [])].sort((a, b) => parseFloat(a.jumlah_b) - parseFloat(b.jumlah_b)) :
                                [...(userDashState.data?.datachart4 || [])].sort((a, b) => parseFloat(a.jumlah) - parseFloat(b.jumlah))}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                        />
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-lg-8 col-md-8 col-12 mb-5'>
                    <CstmFilterCahrtDx
                        title="10 SPIIRIT Module by Page View"
                        subtitle="Page yang paling sering dikunjungi"
                    >
                        <ChartDx
                            colorBarDifferent={true}
                            argumentField="nama"
                            colorBarDiffirentField="nama"
                            coloBarDifferentValueField="jumlah"
                            rotate={true}
                            dataSource={[...(userDashState.data?.datachart5 || [])].sort((a, b) => parseFloat(a.jumlah) - parseFloat(b.jumlah))}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-lg-4 col-md-4 col-12 mb-5'>
                    <CstmFilterCahrtDx
                        title="Device Session"
                        subtitle="Presentase perangkat yang digunakan user"
                    >
                        <DoughnatDx
                            withTitle={false}
                            dataSource={userDashState.data?.datachart6}
                            showLegend={true}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="top"
                            toolsExport={false}
                            onRenderLabelChart={(e) => (`${parseFloat(e.percentText).toFixed(0)}%`)}
                            onRenderTooltip={(e) => ({ text: `${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                            yAxisArgs={[
                                {
                                    valueField: "jumlah",
                                    argumentField: "nama",
                                    color: null,
                                    format: null
                                }
                            ]}

                        />
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-lg-12 col-md-12 col-12 mb-5'>
                    <CstmFilterCahrtDx
                        title="User Position by Page View"
                        subtitle="Jumlah kunjungan user berdasarkan jabatan dan fungsi user"
                        customOption={
                            <div className='d-inline-flex'>
                                <select className='form-control'
                                    value={userDashState.fc}
                                    onChange={(e) => usrDashAction.handleChartPositionChange(e.target.value)}
                                >
                                    {
                                        [{ id: '0', name: 'RTI' }, ...(userDashState.data?.datafunctionid || [])].map((d, i) => (
                                            <option value={d?.id} key={i}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                                <Link
                                    target={'_blank'}
                                    to={`/admin/dashboard-user-position-tabel?q=${usrDashAction.generateLinkTableDetailFunction()}`}
                                    className="d-flex my-auto ">
                                    <i class="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                </Link>
                            </div>
                        }
                    >
                        {ChartPosition}
                        {/* <GridDx
                            cellRender={customCellRender}
                            columns={[{ fieldName: 'nama', caption: 'Nama' }, { fieldName: 'jumlah', caption: 'Jumlah' }]}
                            data={userDashState.data_cposition}
                            loading={userDashState.loadingcp}
                        /> */}
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-lg-12 col-md-12 col-12 mb-5'>
                    <CstmFilterCahrtDx
                        title="Top 20 User Admin by Page View"
                        subtitle="Jumlah kunjungan user berdasarkan user role admin"
                        customOption={
                            <div className='d-inline-flex'>
                                <select className='form-control'
                                    value={userDashState.fa}
                                    onChange={(e) => usrDashAction.handleChartUserDminChange(e.target.value)}
                                >
                                    {
                                        [{ id: '0', name: 'All Admin' }, ...(userDashState.data?.dataroleid || [])].map((d, i) => (
                                            <option value={d?.id} key={i}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                                <Link
                                    target={'_blank'}
                                    to={`/admin/dashboard-user-admin-tabel?q=${usrDashAction.generateLinkTableDetailRoleId()}`}
                                    className="d-flex my-auto ">
                                    <i class="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                </Link>
                            </div>
                        }
                    >
                        {ChartUserAdmin}
                        {/* <GridDx
                            cellRender={customCellRender}
                            columns={[{ fieldName: 'nama', caption: 'Nama' }, { fieldName: 'jumlah', caption: 'Jumlah' }]}
                            data={userDashState.data_cadmin}
                            loading={userDashState.loadingca}
                        /> */}
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-lg-12 col-md-12 col-12 mb-5'>
                    <CstmFilterCahrtDx
                        title="SPIIRIT Module by Page View (Yearly)"
                        subtitle="Jumlah page view per module spiirit"
                    >
                        <div className="table-responsive">
                            <table className='table table-bordered'>
                                <thead style={{ background: '#006CB8', color: 'white' }}>
                                    <tr>
                                        {
                                            columns.slice(0, 3).map((d, i) => (
                                                <th key={i} rowSpan={2} className='text-center align-middle'>
                                                    {d?.caption}
                                                </th>
                                            ))
                                        }
                                        <th colSpan={12} className='text-center'>
                                            Bulan
                                        </th>
                                    </tr>
                                    <tr>
                                        {
                                            columns.slice(3, 15).map((d, i) => (
                                                <th key={i}>
                                                    {d?.caption}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...(userDashState.data_tablehits || [])].map((d, i) => (
                                        <tr key={i}>
                                            {
                                                columns.map((x, j) => (
                                                    <td key={j} className={j < 2 ? "text-left" : "text-right"} style={j === 2 ? { background: 'silver' } : {}}>
                                                        {
                                                            j < 2 ? d[x.fieldName] : formatter_int.format(d[x.fieldName])
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </CstmFilterCahrtDx>
                </div>
            </div>
        </div>
    );
}

export default () => <UsrMngDashProvider><DashboardUsrMng /></UsrMngDashProvider>;