import React from 'react';

function CustomerDetailForm({ data = {} }) {
    return (
        <div className='row mb-5'>
            <div className='col-md-6'>
                <div className="form-group row">
                    <label className="col-sm-5">Nama</label>
                    <div className="col-sm-7">
                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{data?.nama || ""}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Email</label>
                    <div className="col-sm-7">
                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{data?.email || ""}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Holding / Subolding</label>
                    <div className="col-sm-7">
                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{data?.institution_nama || ""}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Direktorat / Entitas</label>
                    <div className="col-sm-7">
                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{data?.institution_sub_nama || ""}</p>
                    </div>
                </div>
            </div>
            <div className='col-md-6'>
                <div className="form-group row">
                    <label className="col-sm-5">Fungsi</label>
                    <div className="col-sm-7">
                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{data?.fungsi || ""}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">Alamat</label>
                    <div className="col-sm-7">
                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{data?.alamat || ""}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-5">No. HP</label>
                    <div className="col-sm-7">
                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{data?.hp || ""}</p>
                    </div>
                </div>                
            </div>
        </div>
    );
}

export default CustomerDetailForm;