import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
// import { baseUrl } from '../../app.config';
import {
    fileExtensions,
    // fileExtensions_string
} from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import './achievement.css';
import { useHistory, useParams } from 'react-router-dom';
import {
    // Autocomplete, FileUploader, NumberBox,
    TagBox
} from 'devextreme-react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { FileUploadDx, HeaderList } from '../../components';
import { toString } from 'lodash';

function EditAchievement(props) {
    const history = useHistory();
    const param = useParams();
    const [state, dispatch, action] = useGlobalState();
    const [judul, setJudul] = useState("");
    const [description, setDescription] = useState("");
    const [level, setLevel] = useState("");
    const [owner, setOwner] = useState([]);
    const [defOwner, setDefOwner] = useState([]);
    const [tahun, setTahun] = useState("");
    const [cover, setCover] = useState("")
    // const [ownerName, setOwnerName] = useState("");
    const [status_approval, setStatus_approval] = useState();

    useEffect(() => {
        action.getData('/api/people-achievement/table');
        action.getData(`/api/people-achievement/view-update-achievement?id=${param.id}`).then((resp) => {
            if (resp.code === "00") {
                setJudul(resp.data.data_user_achievement?.name);
                setDescription(resp.data.data_user_achievement?.description);
                setLevel(resp.data.data_user_achievement?.level);
                // const ow = [...(resp.data.data_users || [])].find(d => d.id === resp.data.data_achievement?.owner)
                // setOwner(ow);
                // setOwnerName(ow.name);
                const strOwner = (resp.data.data_user_achievement?.owner_id || resp.data.data_user_achievement?.owner);
                const ownerArr = [...((toString(strOwner) || '').split(',') || [])].map(d => parseInt(d, 0));
                setDefOwner(ownerArr)
                setOwner(ownerArr);
                setTahun(resp.data.data_user_achievement?.year_of_achievement);
                setStatus_approval(resp.data.data_user_achievement?.status_approval);
                setCover({
                    name: resp.data.data_user_achievement?.photo_location,
                    is_new: false
                });
            }
        })
    }, [])
    function onUploadedCover(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setCover({
                name: resp.data?.data_filename,
                is_new: true
            })
        }
    }
    // function fileOnchange(data) {
    //     // console.log(data)
    //     if (data.value?.[0]) {
    //         var file = data.value?.[0];
    //         var ext = file?.name?.split('.').pop();
    //         var result = fileExtensions_string.filter(d => d === ext)
    //         if (result.length > 0) {
    //             action.postData(
    //                 {
    //                     url: "/api/base/upload-file-temp",
    //                     data: {
    //                         "File": data.value?.[0],
    //                     },
    //                     withFile: true,
    //                 }
    //             ).then((resp) => {
    //                 if (resp.code === "00") {
    //                     // console.log(resp)
    //                     setCover({
    //                         name: resp.data?.data_filename,
    //                         is_new: true
    //                     })
    //                 }
    //             })
    //         }
    //     }
    // }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageYear = '';
        if (data.name === '') {
            isValid = false;
            message = [...message, 'Judul'];
        }
        if (data.description === '') {
            isValid = false;
            message = [...message, 'Description'];
        }
        if (data.level === '') {
            isValid = false;
            message = [...message, 'Level'];
        }
        // if ([...(data.owner || [])].length === 0) {
        //     isValid = false;
        //     message = [...message, 'Owner'];
        // }
        if (data.year_of_achievement === '') {
            isValid = false;
            message = [...message, 'Tahun'];
        }
        if (data.photo_location?.name === '') {
            isValid = false;
            message = [...message, 'Cover'];
        }
        if (isValid && parseInt(data?.year_of_achievement, 0) < 1950) {
            isValid = false;
            messageYear = 'Tahun minimal 1950';
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageYear ? ' dan ' : '') + messageYear
        }
    }
    function save() {
        const fixowner = [...(owner || [])].map(d => {
            const ifAny = [...(defOwner || [])].find(x => x === d);
            return {
                user_id: d,
                is_new: ifAny ? false : true
            }
        })
        const deleteOwner = [...(defOwner || [])].filter(x => ![...(fixowner || [])].filter(d => d.is_new === false).find(y => y.user_id === x)).map(s => {
            return {
                user_id: s,
                is_new: false
            }
        })
        const data = {
            "id": param.id,
            "name": judul || '',
            "description": description || '',
            "photo_location": {
                name: cover?.name || '',
                is_new: cover?.is_new
            },
            "year_of_achievement": tahun || '',
            "level": level || '',
            "creator": "",
            "created_at": "",
            "updated_at": "",
            "owner": fixowner,
            "owner_delete": deleteOwner,
            "status_approval": state.data_achievement?.status_approval
        }
        // console.log(data)
        const valid = validation(data);
        if (valid.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: valid.message,
                icon: 'warning',
            })
        } else {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Achievement? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/people-achievement/update-achievement",
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            history.push('/admin/people-achievement/table')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function ownerChange(e) {
        setOwner(e)
    }
    return (
        <>
            <HeaderList
                title="Edit Achievement"
                someText="People Management\Achievement\Edit Achievement"
            >

            </HeaderList>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <h5>Detail</h5>
                            <hr />
                            <div className="row mb-3">
                                <div className="col-md-3">Judul Achievement</div>
                                <div className="col-md-9">
                                    <input className="form-control form-control-sm" value={judul} onChange={(e) => setJudul(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Description</div>
                                <div className="col-md-9">
                                    <input type="textarea" className="form-control form-control-sm" value={description} onChange={(e) => setDescription(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Level</div>
                                <div className="col-md-9">
                                    <select className="form-control form-control-sm" value={level} onChange={(e) => setLevel(e.target.value)}>
                                        <option value="" selected disabled> - Pilih Level -</option>
                                        <option value="Internal">Internal</option>
                                        <option value="National">National</option>
                                        <option value="International">International</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Owner</div>
                                <div className="col-md-9">
                                    {/* <Autocomplete dataSource={state.data_users}
                                        //defaultValue={ownerName}
                                        value={ownerName}
                                        valueExpr="name"
                                        //displayExpr="id"
                                        searchExpr="name"
                                        onValueChanged={(e) => setOwnerName(e.value)}
                                        onSelectionChanged={(e) => setOwner(e.selectedItem)}
                                        className="form-control form-control-sm mr-1">
                                    </Autocomplete> */}
                                    <TagBox

                                        searchEnabled={true}
                                        value={owner}
                                        id="inventor"
                                        items={state.data_users}
                                        displayExpr="name"
                                        displayValue="name"
                                        valueExpr="id"
                                        onValueChange={ownerChange}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Tahun</div>
                                <div className="col-md-9">
                                    <input type="number" placeholder="2000" min={0} className="form-control form-control-sm" value={tahun} onChange={(e) => setTahun(e.target.value)} />
                                    {/* <NumberBox className="form-control form-control-sm" value={tahun} onValueChanged={(e) => setTahun(e.value)}></NumberBox> */}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Cover</div>
                                <div className="col-md-9">
                                    {/* <FileUploader className="custom-dx-fileuploader-file-name p-0 custom-uploader"
                                        uploadUrl={baseUrl + "/api/base/upload-file-temp"}
                                        allowedFileExtensions={fileExtensions}
                                        accept="image/*"
                                        invalidFileExtensionMessag="File "
                                        onValueChanged={(e) => fileOnchange(e)}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb" maxFileSize={10000000} uploadMode="useForm" >

                                    </FileUploader> */}
                                    <FileUploadDx
                                        className="custom-dx-fileuploader-file-name"
                                        showFileList={true}
                                        multiple={false}
                                        selectButtonText="Select File"
                                        allowedFileExtensions={fileExtensions}
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb"
                                        accept="image/*"
                                        onUploaded={onUploadedCover}
                                        maxFileSize={10000000}
                                        uploadMode="instantly"
                                    />
                                    <span>{cover.name}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="d-block text-right">
                                <button className="btn btn-sm btn-secondary mx-1"
                                    onClick={() => history.goBack()}
                                >
                                    Batal
                                </button>
                                <button className="btn btn-sm btn-primary"
                                    onClick={() => save()}
                                >
                                    <i className="fa fa-save icon-nm mx-1 text-white"></i>
                                    Save
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default EditAchievement;