import React, { useEffect, useRef, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
// import { Autocomplete, FileUploader, TagBox, Tooltip } from 'devextreme-react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// import { fileExtensions, fileExtensions_string } from '../../components/utility/utility';
// import { baseUrl } from '../../app.config';
import { FileUploadDx } from '../../components';

function ModalLibrary({ data = [], onClose = () => { }, onFinish = () => { } }) {
    const [state, dispatch, action] = useGlobalState();
    const [dataArea, setDataArea] = useState([]);
    const [dataKateg, setDataKateg] = useState([]);
    const [judul, setJudul] = useState("");
    const [description, setDescription] = useState("");
    const [area, setArea] = useState("");
    const [kategori, setKategori] = useState("");
    const [url, setUrl] = useState("");
    const [ownerName, setOwnerName] = useState('');
    const [owner, setOwner] = useState('');
    const [author, setAuthor] = useState('');
    const [cover, setCover] = useState({});
    const [file_lib, setFile_lib] = useState({});
    const [statusEdit, setStatusEdit] = useState(0);
    const [idEdit, setIdEdit] = useState('');
    const refFile = useRef();
    const refPhoto = useRef();

    useEffect(() => {
        if (state.modal.modalLibrary === true) {
            clearEditor();
            getDataArea();
            getDataKateg();
        }
    }, [state.modal.modalLibrary])

    function onUploadedFile_lib(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setFile_lib({
                "name": resp.data?.data_filename,
                "is_new": true
            })
        }
    }
    function onUploadedCover_lib(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setCover({
                "name": resp.data?.data_filename,
                "is_new": true
            })
        }
    }
    function clearEditor() {
        setJudul('');
        setDescription('');
        setArea('');
        setOwner('');
        setAuthor('');
        setOwnerName('');
        setKategori('');
        setCover({});
        setStatusEdit(0);
        setIdEdit('');
        setFile_lib({});
        setUrl('');
        if (refFile.current)
            refFile.current.instance.reset();
        if (refPhoto.current)
            refPhoto.current.instance.reset();

    }
    function add() {
        const data = {
            "id": idEdit,
            "book_category_id": kategori || '',
            "area_id": area || '',
            "title": judul || '',
            "description": description || '',
            "author": author || '',
            "file_location": file_lib || {},
            "cover_location": cover || {},
            "file_size": 0,
            "sitelink": url || '',
            "hit": 0,
            "creator": 0,
            "created_at": "",
            "updated_at": ""
        }
        // console.log(data)
        const valid = validation(data);
        if (valid.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: valid.message,
                icon: 'warning',
            })
        } else {

            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Library? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/km-library/create",
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            clearEditor();
                            onFinish();
                            // history.push('/admin/people-achievement/table')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function update() {
        const data = {
            "id": idEdit,
            "book_category_id": kategori || '',
            "area_id": area || '',
            "title": judul || '',
            "description": description || '',
            "author": author || '',
            "file_location": file_lib || {},
            "cover_location": cover || {},
            "file_size": 0,
            "sitelink": url || '',
            "hit": 0,
            "creator": 0,
            "created_at": "",
            "updated_at": ""
        }
        const valid = validation(data);
        // console.log(data);
        if (valid.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: valid.message,
                icon: 'warning',
            })
        } else {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Library? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/km-library/update",
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            clearEditor();
                            onFinish();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function getDataArea() {
        action.getData('/api/book-area').then((resp) => {
            if (resp?.code === '00') {
                setDataArea(resp?.data);
            }
        })
    }
    function getDataKateg() {
        action.getData('/api/book-category', {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                setDataKateg(resp?.data);
            }
        })
    }
    function getDataEdit(id) {
        action.getData(`/api/km-library/view/${id}`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                const { detail_book } = resp?.data;
                setJudul(detail_book?.title);
                setIdEdit(detail_book?.id);
                setDescription(detail_book?.description);
                setKategori(detail_book?.book_category_id);
                setArea(detail_book?.area_id);
                setAuthor(detail_book?.author);
                setUrl(detail_book?.sitelink);
                setFile_lib({
                    name: detail_book?.file_location,
                    is_new: false
                })
                setCover({
                    name: detail_book?.cover_location,
                    is_new: false
                })
            }
        })
    }
    function setEdit(data) {
        setStatusEdit(1);
        getDataEdit(data?.id);
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        if (data?.title === "") {
            isValid = false;
            message = [...message, "Judul"];
        }
        if (data?.description === "") {
            isValid = false;
            message = [...message, "Deskripsi"];
        }
        if (data?.area_id === "") {
            isValid = false;
            message = [...message, "Area"];
        }
        if (data?.book_category_id === "") {
            isValid = false;
            message = [...message, "Kategori"];
        }
        if (data?.author === "") {
            isValid = false;
            message = [...message, "Author"];
        }
        if (data?.file_location?.name === "" || data?.file_location?.name === null || data?.file_location?.name === undefined) {
            isValid = false;
            message = [...message, "File Library"];
        }
        if (data?.cover_location?.name === "" || data?.cover_location?.name === null || data?.cover_location?.name === undefined) {
            isValid = false;
            message = [...message, "Cover"];
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Hapus Library ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/km-library/delete?id=" + id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        clearEditor();
                        onFinish();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <>
            <h5>{statusEdit === 0 ? "Add" : "Edit"} Library</h5>
            <hr />
            <div className="row mb-3">
                <div className="col-md-3">Judul Library</div>
                <div className="col-md-9">
                    <input className="form-control form-control-sm" placeholder="Judul Library" value={judul} onChange={(e) => setJudul(e.target.value)}></input>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Description</div>
                <div className="col-md-9">
                    <textarea rows={3} placeholder="Deskripsi..." className="form-control form-control-sm" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Area</div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" value={area} onChange={(e) => setArea(e.target.value)}>
                        <option value=""> - Pilih Area -</option>
                        {
                            dataArea.map((d, i) => (
                                <option key={i} value={d?.param_id}>{d?.title}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Kategori Library</div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" value={kategori} onChange={(e) => setKategori(e.target.value)}>
                        <option value=""> - Pilih Kategori -</option>
                        {
                            dataKateg.map((d, i) => (
                                <option key={i} value={d?.id}>{d?.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Author</div>
                <div className="col-md-9">
                    <input className="form-control form-control-sm" placeholder='Nama Author' value={author} onChange={(e) => setAuthor(e.target.value)} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Site/Url</div>
                <div className="col-md-9">
                    <input className="form-control form-control-sm" placeholder='Site/Url (Contoh: https://spiirit.net)' value={url} onChange={(e) => setUrl(e.target.value)} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">File Library</div>
                <div className="col-md-9">
                    <FileUploadDx
                        ref={refFile}
                        className="custom-dx-fileuploader-file-name"
                        showFileList={true}
                        multiple={false}
                        selectButtonText="Select File"
                        readyToUploadMessage=" "
                        labelText="or Drop file hire | Max size 10 Mb"
                        accept="*"
                        onUploaded={onUploadedFile_lib}
                        maxFileSize={10000000}
                        uploadMode="instantly"
                    />
                    <span>{file_lib.name}</span>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">Cover</div>
                <div className="col-md-9">
                    <FileUploadDx
                        ref={refPhoto}
                        className="custom-dx-fileuploader-file-name"
                        showFileList={true}
                        multiple={false}
                        selectButtonText="Select File"
                        readyToUploadMessage=" "
                        labelText="or Drop file hire | Max size 10 Mb"
                        accept="image/*"
                        onUploaded={onUploadedCover_lib}
                        maxFileSize={10000000}
                        uploadMode="instantly"
                    />
                    <span>{cover.name}</span>
                </div>
            </div>
            <hr />
            <div className="d-block text-right">
                {
                    statusEdit === 0 && (
                        <>
                            <button className="btn btn-sm btn-success" onClick={add}><i className="fa fa-plus icon-nm mr-3"></i>Library</button>
                        </>
                    )
                }
                {
                    statusEdit === 1 && (
                        <>
                            <button className="btn btn-sm btn-secondary mx-1" onClick={() => clearEditor()}>Cancel Edit</button>
                            <button className="btn btn-sm btn-success mx-1" onClick={update}><i className="fa fa-save icon-nm mr-3"></i>Update</button>
                        </>
                    )
                }
            </div>
            <hr />
            {
                [...(data || [])].map((d, i) => (
                    <div className="row mb-3" key={i}>
                        <div className="col-6">
                            <div className="d-inline-flex">
                                <i className="fa fa-circle icon-xs mr-5 mt-1 text-dark"></i>
                                <div>
                                    <span className="d-block mb-3"><b>{d.title}</b></span>
                                    {
                                        d.status_approval === 1 && (
                                            <span className="py-1 px-3 bg-light-secondary rounded mt-3 text-dark">Review</span>
                                        )
                                    }
                                    {
                                        d.status_approval === 2 && (
                                            <span className="py-1 px-3 bg-light-success rounded mt-3 text-success">Approve</span>
                                        )
                                    }
                                    {/* <span className="d-block">{d.year_of_achievement}</span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-md-right">
                            {/* {
                                d.status_approval === 1 && ( */}
                            <>
                                <button className="btn btn-sm btn-outline-warning text-warning mx-1" onClick={() => setEdit(d)}>
                                    <i className="fa fa-pencil-alt icon-nm  p-0"></i>
                                </button>
                                <button className="btn btn-sm btn-outline-danger text-danger mx-1" onClick={() => deleteData(d.id)}>
                                    <i className="fa fa-trash-alt icon-nm  p-0"></i>
                                </button>
                            </>
                            {/* } */}

                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default ModalLibrary;