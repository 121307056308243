import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../components';
import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2/dist/sweetalert2.js'

const columns = [
    { fieldName: "name", caption: 'Judul', width: 200 },
    { fieldName: 'description', caption: 'Deskripsi', width: 500 },
    { fieldName: 'patent_category_name', caption: 'Category', width: 100 },
    { fieldName: 'patent_year', caption: 'Tahun', width: 100 },
    { fieldName: "pemilik", caption: 'Pemilik', width: 100 },
    { fieldName: "area_name", caption: 'Area', width: 100 },
    { fieldName: "direktorat_fungsi", caption: 'Direktorat', width: 100 },
    { fieldName: 'patent_owners_name', caption: "Inventor", width: 200 },
    { fieldName: 'owner_ext', caption: 'Inventor Ext.', width: 200 },
    { fieldName: "keyword", caption: 'Keyword', width: 150 },
    { fieldName: 'patent_status_name', caption: "Status", width: 100 },
    { fieldName: 'no_pengajuan', caption: "No. Pengajuan", width: 100 },
    { fieldName: 'no_paten', caption: "No. Paten", width: 100 },
]


function TableApproval(props) {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    useEffect(() => {
        action.getData('/api/people-intel-property/table-approval');
    }, [])
    const customRender = (e) => {
        if (e.column.name === "name") {
            return (
                <Link to={`/admin/people/patent-detail-approval/${e.data.id}`}>
                    <span className="text-wrap">
                        {
                            e.value
                        }
                    </span>
                </Link>
            )
        } else if (e.column.name === "inventor") {
            return (
                <>
                    <span className="text-wrap">
                        {
                            [e.data.owner1_name, e.data.owner2_name, e.data.owner3_name, e.data.owner4_name, e.data.owner5_name, e.data.owner6_name].filter(s => s).join(', ')
                        }
                    </span>
                </>
            )
        } else if (e.column.name === "status_approval") {
            return (
                <div className="my-2">
                    {
                        e.value === 1 && (
                            <span className="bg-light-warning text-warning p-2 rounded">Waiting Approval</span>
                        )
                    }
                    {
                        e.value === 2 && (
                            <span className="bg-light-success text-success p-2 rounded">Approved</span>
                        )
                    }

                </div>
            )
        } else if (e.column.name === "keyword") {
            return (
                <div className="text-wrap">
                    {
                        (e.value || "").split(',').map((d, i) => (
                            <span className="badge badge-secondary mr-1 mb-1" key={i}>{d}</span>
                        ))
                    }
                </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    const deleteOnclick = (id) => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Patent ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-intel-property/delete?id=" + id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div>
            <div className="pb-5">
                <HeaderList
                    title="Intellectual Property Approval"
                    someText={"People Management\\ Patent \\ Approval"}
                ></HeaderList>
            </div>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row" style={{ fontSize: '8pt' }}>
                        <div className="col-md-2">
                            <div className="d-flex border-right">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Design/Image.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Intellectual Prop.</span>
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Upstream</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Refining</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">P&NF</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Gas & LNG</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">NRE</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Digital</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        cellRender={customRender}
                        searchForm={true}
                        withAction={true}
                        data={state.data_table || []}
                        columns={columns}
                        actions={[
                            {
                                name: "View",
                                icon: "fas fa-eye icon-nm text-primary",
                                action: (v, c) => {
                                    history.push(`/admin/people/patent-detail-approval/${v.id}`)
                                }
                            },
                            {
                                name: "Edit",
                                icon: "fa fa-pencil-alt icon-nm text-success",
                                action: (v, c) => {
                                    history.push(`/admin/people-patent/edit/${v.id}`)
                                }
                            }, {
                                name: "Delete",
                                icon: "fa fa-trash-alt icon-nm text-danger",
                                action: (v, c) => {
                                    deleteOnclick(v.id)
                                }
                            }
                        ]}
                    />
                </Card.Body>
            </Card>
        </div>
    );
}

export default TableApproval;