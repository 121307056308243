import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';
import './style.css';
import { HeaderList, Tab, TabContent } from '../../components';
import Card from 'react-bootstrap/Card'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import TabDetailOperational from './tabDetailOperational';

export default memo(function (props) {
    const location = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [selTab, setSelTab] = useState("Detail");

    useEffect(function () {
        var project_type = state.formData.project_type;
        action.resetForm();
        action.setForm('disable', false);
        action.setForm('project_type', project_type)
        // if (state.formData.project_type === null || state.formData.project_type === undefined) {
        //     return Swal.fire({
        //         title: 'Warning',
        //         text: "Pilih tipe project terlebih dahulu",
        //         icon: 'warning',
        //     }).then(res => location.goBack())

        // }
        action.getData('/api/param-area', null, 'areas').then((resp) => {
            if (resp.code === "00") {
                const newDs = resp.data.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { areas: newDs, loadingGet: false }
                })
            }
        });
    }, [])

    function tabChange(val) {
        if (selTab === "Detail") {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Save Project Detail?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    updateDetail(val)
                    return true;
                }
            })
        }
    }
    function updateDetail() {
        const tmp = state.formData.keyword ? [...((typeof state.formData.keyword === 'string' ? JSON.parse(state.formData.keyword) : state.formData.keyword) || [])] : [];
        const keyword = tmp.map(d => {
            return {
                "name": d.value,
                "tag_id": '',
                "table_name": '',
                "data_id": '',
                "is_new": d?.is_new === false ? d?.is_new : true
            }
        })
        const data = {
            "id": '',
            "project_type": state.formData.project_type || '',
            "research_category_id": state.formData.research_category_id || '',
            "chief_manager_id": state.formData.chief_manager_id || '',
            "title": state.formData.title || '',
            "description": state.formData.description || '',
            "purpose": state.formData.purpose || '',
            "initiator": state.formData.initiator || '',
            "created_at": state.formData.created_at_form || '',
            "updated_at": state.formData.updated_at_form || '',
            "year_ideation": state.formData.year_ideation || '',
            "baseline_name": state.formData.baseline_name || '',
            "jangka_waktu": state.formData.jangka_waktu || '',
            "duk_operasional_opt_0": [...(state.formData.dukungan_operational || [])]?.[0]?.value || '',
            "duk_operasional_opt_1": [...(state.formData.dukungan_operational || [])]?.[1]?.value || '',
            "duk_operasional_opt_2": [...(state.formData.dukungan_operational || [])]?.[2]?.value || '',
            "duk_operasional_ket": state.formData.duk_operasional_ket || '',
            "potential_user": state.formData.potential_user || '',
            "owner": state.formData.leader?.user_id || '',
            "method": state.formData.method || '',
            "potensial_partner_opt": (state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ? (state.formData.potensial_partner_opt || '') : '',
            "potensial_partner_ket": (state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ? (state.formData.potensial_partner_ket || '') : '',
            "project_code": state.formData.project_code || '',
            "project_unggulan": state.formData.project_unggulan || '',
            "stage": state.formData.stage || '',
            "document": [...(state.formData.sumberFileTemp || [])],
            "document_delete": state.data_view_header?.submit_status === 0 ? [] : (state.formData.deletedDocument || []),
            "expertise_neededs": [...(state.formData.keahlian || [])].map(d => {
                return {
                    "expertise_id": d?.value,
                    "is_new": d?.is_new
                }
            }),
            "expertise_neededs_delete": [...(state.formData.deletedKeahlian || [])].map(d => {
                return {
                    "expertise_id": d?.value,
                    "is_new": d?.is_new
                }
            }),
            "keyword": keyword,
            "keyword_delete": state.formData.deletekeyword || [],
            "related_researchs": state.formData.realated_document || [],
            "related_researchs_delete": state.formData.realated_document_deleted || [],
            "team_members": [...(state.formData.anggota || [])].map(d => { return { user_id: d.user_id, is_new: d.is_new } }),
            "team_members_delete": [...(state.formData.deleteAnggota || [])].map(d => { return { user_id: d.user_id, is_new: d.is_new } }),
            "pendapatan": state.formData.pendapatan || '',
            "efisiensi": state.formData.efisiensi || '',
            "produksi": state.formData.produksi || '',
            "sat_pendapatan": state.formData.sat_pendapatan || '',
            "sat_efisiensi": state.formData.sat_efisiensi || '',
            "sat_produksi": state.formData.sat_produksi || '',
            "ket_pendapatan": state.formData.ket_pendapatan || '',
            "ket_efisiensi": state.formData.ket_efisiensi || '',
            "ket_produksi": state.formData.ket_produksi || '',
            "intangible": state.formData.intangible || '',
            "tangible_angka": state.formData.tangible_angka || '',
            "tangible_keterangan": state.formData.tangible_keterangan || '',
            "focus": state.formData.focus || ''
        }
        const validation = validateUpdateDetail(data);
        if (validation.isValid === true) {
            // console.log('data yang dikirim', data)
            action.postData(
                {
                    url: "/api/project-portfolio-crud/update-detail-comm-oper",
                    data: data,
                    withFile: false
                }
            ).then((resp) => {
                // console.log(resp);
                if (resp.code === "00") {
                    // location.replace('/portfolio-research/edit/' + resp?.data?.id)
                    window.location.replace('/portfolio-research/edit/' + resp?.data?.id);
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validation.message,
                icon: 'warning',
            })
        }
    }
    function validateUpdateDetail(data) {
        var isValid = true;
        var message = [];
        if (data.project_type === 5) {
            if (data.title === ''
            ) {
                isValid = false
                message = [...message, `Judul`];
            }
            if (data.description === '') {
                isValid = false
                message = [...message, `Pain Point`];
            }
            if (data.purpose === '') {
                isValid = false
                message = [...message, `Commercialization /Implementation- Objective`];
            }
            if (data.keyword.length === 0) {
                isValid = false
                message = [...message, `Keyword`];
            }
            if (data.owner === '') {
                isValid = false
                message = [...message, `Leader`];
            }
            if (data.team_members.length === 0) {
                isValid = false
                message = [...message, `Member`];
            }
        } else {
            if (data.title === '') {
                isValid = false
                message = [...message, `Title`];
            }
            if (data.description === '') {
                isValid = false
                message = [...message, `Deskripsi`];
            }
            if (data.purpose === '') {
                isValid = false
                message = [...message, `Purpose`];
            }
            if ([...(state.formData.dukungan_operational || [])].length === 0) {
                isValid = false
                message = [...message, `Dukungan OP`];
            }
            if (data.potential_user === '') {
                isValid = false
                message = [...message, `Pemakai Potensial`];
            }
            if (data.owner === '') {
                isValid = false
                message = [...message, `Leader`];
            }
            if (data.expertise_neededs.length === 0) {
                isValid = false
                message = [...message, `Keahlian`];
            }
            if (data.keyword.length === 0) {
                isValid = false
                message = [...message, `Keyword`];
            }
            if (data.tangible_angka === '') {
                isValid = false
                message = [...message, `Tangible value`];
            }
            if (data.tangible_keterangan === '') {
                isValid = false
                message = [...message, `Tangible ket.`];
            }
            if (data.team_members.length === 0) {
                isValid = false
                message = [...message, `Member`];
            }
            if (data.method === '') {
                isValid = false
                message = [...message, `Metode Riset`];
            }
            if (data.method === '2' || data.method === '3' || data.method === '4') {
                if (data.potensial_partner_opt === '' || data.potensial_partner_ket === '') {
                    isValid = false;
                    message = [...message, `Potensial partner`];
                }
            }
            if (toString(data.project_type) === '1' || toString(data.project_type) === '2' || toString(data.project_type) === '4') {
                if (data.stage === '') {
                    isValid = false;
                    message = [...message, `Stage`];
                }
            }
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        };
    }

    return (
        <>
            {/* <h3><strong>{state.formData.project_type_str}</strong></h3> */}
            <HeaderList
                title={state?.data_page?.page_title}
                someText={state?.data_page?.page_breadcrumbs}
            >
            </HeaderList>
            <Card
                className="m-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "Detail",
                                onClick: () => tabChange("Detail"),
                                title: "Project Detail"
                            },
                            {
                                id: "Timeline",
                                onClick: () => tabChange("Timeline"),
                                title: "Project Timeline"
                            },
                            {
                                id: "Budget",
                                onClick: () => tabChange("Budget"),
                                title: "Drawdown Budget"
                            },
                        ]}
                        manualActive={selTab}
                    >
                        <TabContent id="Detail">
                            <TabDetailOperational />
                        </TabContent>
                        <TabContent id="Timeline">
                        </TabContent>
                        <TabContent id="Budget">
                        </TabContent>
                    </Tab>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer">
                <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-sm btn-secondary" onClick={() => location.goBack()}>Batal</button>
                    </div>
                    <div className="col-md-6 text-right">
                        {/* <button onClick={(e) => console.log(e)}
                            className="btn btn-sm btn-success">
                            Submit ideasi
                        </button> */}
                    </div>
                </div>
            </div>

        </>
    )
})