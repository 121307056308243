import { Autocomplete, FileUploader, TagBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { FileUploadDx, FormResearch, HeaderList, RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';
import { fileExtensions, fileExtensions_string } from '../../components/utility/utility';
import moment from 'moment';
import { useRef } from 'react';

function FormEditMaster(props) {
    const history = useHistory();
    const { id } = useParams();
    const [state, dispatch, action] = useGlobalState();
    const [data_type, setData_type] = useState([]);
    const [data_status, setData_status] = useState([]);
    const [data_user, setData_user] = useState([]);
    const [data_ipr, setData_ipr] = useState([]);
    const [id_edit, setId_edit] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [photo_location, setPhoto_location] = useState();
    const [file_location, setFile_location] = useState();
    const [file_size, setFile_size] = useState();
    const [release_year, setRelease_year] = useState();
    const [creator, setCreator] = useState();
    const [product_status_id, setProduct_status_id] = useState();
    const [product_type_id, setProduct_type_id] = useState();
    const [background, setBackground] = useState();
    const [contact_person, setContact_person] = useState();
    const [ipr, setIpr] = useState([]);
    const [tempIpr_name, setTemIpr_name] = useState();
    const [tempIpr, setTempIpr] = useState();
    const [price, setPrice] = useState();
    const [pic_contributor, setPic_contributor] = useState([]);
    const [data_pic, setData_Pic] = useState([]);
    const [contrib_type, setContrib_type] = useState('Direct');
    const [photo_pendukung, setPhoto_pendukung] = useState({
        'satu': '',
        'dua': '',
        'tiga': ''
    });
    const [is_displayed, setis_displayed] = useState(false);
    const refSampul = useRef();
    const refSatu = useRef();
    const refDua = useRef();
    const refTiga = useRef();

    useEffect(() => {
        if (id) {
            action.getData(`/api/ext-product/view/${id}`, {}, null, "loadingGet", true).then((resp) => {
                if (resp.code === '00') {
                    setId_edit(resp.data.detail_product?.id);
                    setName(resp.data.detail_product?.name);
                    setDescription(resp.data.detail_product?.description);
                    setBackground(resp.data.detail_product?.background);
                    setIpr([...(resp.data.detail_product?.list_ipr || [])].map(d => {
                        return {
                            ...d,
                            patent_name: d.title
                        }
                    }));
                    setProduct_type_id(resp.data.detail_product?.product_type_id);
                    setProduct_status_id(resp.data.detail_product?.product_status_id);
                    setRelease_year(resp.data.detail_product?.release_year);
                    setCreator([...(resp.data.detail_product?.inventor_arrray_id || [])].map(d => {
                        return parseInt(d, 0);
                    }));
                    setContact_person(resp.data.detail_product?.kontak_id);
                    setPrice(resp.data.detail_product?.price);
                    setPhoto_location(resp.data.detail_product?.photo_location);
                    setFile_location(resp.data.detail_product?.file_location);
                    setPic_contributor(resp?.data?.detail_product?.nama_PIC_array_id);
                    setContrib_type(resp?.data?.detail_product?.contrib_type);
                    setPhoto_pendukung({
                        'satu': resp.data.detail_product?.photo_location_1,
                        'dua': resp.data.detail_product?.photo_location_2,
                        'tiga': resp.data.detail_product?.photo_location_3,
                    });
                    setis_displayed(resp.data.detail_product?.is_displayed);
                }
            });
            action.getData('/api/ext-product/type').then((resp) => {
                if (resp.code === '00') {
                    setData_type(resp.data)
                }
            });
            action.getData('/api/ext-product/status').then((resp) => {
                if (resp.code === '00') {
                    setData_status(resp.data)
                }
            });
            action.getData('/api/param-user-active-contract').then((resp) => {
                if (resp.code === '00') {
                    setData_user(resp.data);
                    setData_Pic(resp.data);
                }
            });
            action.getData('/api/ext-product/related-ipr').then((resp) => {
                if (resp.code === '00') {
                    setData_ipr(resp.data)
                }
            });
            // action.getData('/api/ext-product/admin-product').then((resp) => {
            //     if (resp.code === '00') {
            //         setData_Pic(resp.data)
            //     }
            // });
        }
    }, [])

    function validation(data) {
        var isValid = true;
        var message = '';
        // if (data?.name === '' ||
        //     data?.description === '' ||
        //     data?.background === '' ||
        //     data?.product_type_id === '' ||
        //     data?.release_year === '' ||
        //     data?.product_status_id === '' ||
        //     data?.creator?.length === 0 ||
        //     data?.contact_person === '' ||
        //     data?.photo_location === ''
        // ) {
        //     isValid = false;
        //     message = 'Lengkapi Semua data';
        // }
        if (data?.name === '') {
            isValid = false;
            message = 'Nama Product';
        }
        if (data?.description === '') {
            isValid = false;
            message = message + ', Deskripsi';
        }
        if (data?.background === '') {
            isValid = false;
            message = message + ', Latar belakang';
        }
        if (data?.product_type_id === '') {
            isValid = false;
            message = message + ', Tipe produk';
        }
        if (data?.release_year === '') {
            isValid = false;
            message = message + ', Tahun Rilis';
        }
        if (data?.contrib_type === null || data?.contrib_type === undefined) {
            isValid = false;
            message = message + ', Contribution Type';
        }
        if (data?.creator?.length === 0) {
            isValid = false;
            message = message + ', Initiator';
        }
        if (data?.contact_person === '') {
            isValid = false;
            message = message + ', Kontak';
        }
        if (data?.photo_location === '') {
            isValid = false;
            message = message + ', Foto sampul';
        }
        if (data?.list_pic?.length < 1) {
            isValid = false;
            message = message + ', PIC Contributor';
        }
        message = message + " Tidak boleh kosong"
        if (isValid === true && (parseInt(data?.release_year, 0) < 1900 || parseInt(data?.release_year, 0) > 2100)) {
            isValid = false;
            message = "Tahun release minimal 1900 dan maksimal 2100";
        }
        return {
            isValid,
            message
        }
    }

    function saveData() {
        console.log(photo_pendukung);
        const data = {
            "id": id_edit || '0',
            "name": name || '',
            "description": description || '',
            "photo_location": photo_location || '',
            "file_location": file_location || '',
            "file_size": file_size || 0,
            "list_ipr": [],
            "related_ipr": ipr || [],
            "release_year": release_year || '',
            "creator": creator || [],
            "creator_name": "",
            "product_status_id": product_status_id || '',
            "created_at": '',
            "updated_at": '',
            "status_approval": '',
            "product_status_name": "",
            "product_types_name": "",
            "date_created": "",
            "date_created_sort": "",
            "product_type_id": product_type_id || '',
            "background": background || '',
            "contact_person": contact_person || '',
            "ipr": "",
            "price": price,
            "inventor_name": "",
            "kontak_name": "",
            "list_pic": (pic_contributor || []).map(d => {
                const bantu = (state?.detail_product?.nama_PIC_array_id || []).find(x => x === d);
                var newOjb = {};
                if (bantu) {
                    newOjb = { is_new: false, user_id: d }
                } else {
                    newOjb = { is_new: true, user_id: d }
                }
                return newOjb;
            }),
            "list_pic_delete": (state?.detail_product?.nama_PIC_array_id || []).filter(d => {
                return !(pic_contributor || []).some(x => x === d)
            }).map(d => ({ is_new: false, user_id: d })),
            "contrib_type": contrib_type,
            "photo_location_1": photo_pendukung.satu,
            "photo_location_2": photo_pendukung.dua,
            "photo_location_3": photo_pendukung.tiga,
            "is_displayed": is_displayed
        }
        // console.log(data);
        const val = validation(data);
        if (val.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Product?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/ext-product/update-product",
                            data: data,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            history.goBack();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function addIpr(data_ipr) {
        // console.log('ipr', data_ipr);
        const data = {
            "id": '',
            "patent_id": data_ipr?.id,
            "patent_name": data_ipr?.name,
            "product_id": id_edit || ''
        }
        const t = ipr || [];
        if (data_ipr && !t.find(d => {
            return d.patent_id === data_ipr?.id
        })) {
            setTempIpr();
            setTemIpr_name();
            setIpr([data, ...t]);
        }
    }
    function iprDelete(data_ipr) {
        const t = ipr || [];
        if (data_ipr) {
            setIpr(t.filter(d => d?.patent_id?.toString() !== data_ipr?.patent_id?.toString()));
        }
    }
    function fileOnchangePhoto(data) {
        const file = data.value?.[0]
        if (file && file.size <= 10000000) {
            action.postData(
                {
                    url: "/api/base/upload-file-temp",
                    data: {
                        "File": file,
                    },
                    withFile: true,
                    withLoading: false,
                    withAlert: false,
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    setPhoto_location(resp.data?.data_filename);
                }
            })
        } else if (file && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    function fileOnchangeFile(data) {
        const file = data.value?.[0]
        if (file && file.size <= 10000000) {
            action.postData(
                {
                    url: "/api/base/upload-file-temp",
                    data: {
                        "File": file,
                    },
                    withFile: true,
                    withLoading: false,
                    withAlert: false,
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    setFile_location(resp.data?.data_filename);
                    setFile_size(resp.data?.data_file_size);
                }
            })
        } else if (file && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    function fileUploadedFoto(res, poto) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            if (poto === 'sampul') {
                setPhoto_location(resp.data?.data_filename);
            } else {
                setPhoto_pendukung((e) => ({
                    ...e,
                    [poto]: resp.data?.data_filename
                }))
            }
        }
    }
    const handleDeleteFoto = (poto) => {
        if (poto === 'sampul') {
            setPhoto_location('');
            if (refSampul.current)
                refSampul.current.instance.reset();
        } else {
            setPhoto_pendukung((e) => ({
                ...e,
                [poto]: ''
            }));
            if (poto === 'satu' && refSatu.current) refSatu.current.instance.reset();
            if (poto === 'dua' && refDua.current) refDua.current.instance.reset();
            if (poto === 'tiga' && refTiga.current) refTiga.current.instance.reset();
        }
    }
    function fileUploadedFile(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setFile_location(resp.data?.data_filename);
            setFile_size(resp.data?.data_file_size);
        }
    }
    return (
        <>
            <HeaderList
                title="Edit Product"
                someText='Product \ Edit Product'
            ></HeaderList>
            <Card
                className="m-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <FormResearch
                        formLists={[
                            {
                                rows: 1,
                                header: true,
                                name: "Detail",
                                childs: [
                                    {
                                        type: "text",
                                        label: "Nama Produk",
                                        placeholder: "Nama Produk",
                                        value: name,
                                        onChange: (val) => setName(val)
                                    },
                                    {
                                        label: "Deskripsi Produk dan Benefit",
                                        type: "textarea",
                                        placeholder: "Deskripsi Produk dan Benefit",
                                        value: description,
                                        onChange: (val) => setDescription(val)
                                    },
                                    {
                                        label: "Latar Belakang",
                                        type: "textarea",
                                        placeholder: "Latar Belakang",
                                        value: background,
                                        onChange: (val) => setBackground(val)
                                    }
                                ]
                            }, {
                                rows: 1,
                                header: true,
                                name: "Informasi Produk",
                                childs: [
                                    {
                                        type: "custom",
                                        label: "IPR",
                                        component: (
                                            <>
                                                <div className="input-group mb-2" >
                                                    <Autocomplete dataSource={data_ipr}
                                                        placeholder="Nama Produk"
                                                        value={tempIpr_name}
                                                        valueExpr="name"
                                                        displayExpr="name"
                                                        onValueChanged={(e) => setTemIpr_name(e.value)}
                                                        onSelectionChanged={(e) => setTempIpr(e.selectedItem)}
                                                        className="form-control form-control-sm p-0 mr-1 mb-5">
                                                    </Autocomplete>
                                                    <div className="text-right" style={{ width: '65px' }}>
                                                        <button type="submit" className="btn btn-sm btn-primary" onClick={() => addIpr(tempIpr)}>+ Add</button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <ul style={{ 'paddingLeft': '15px' }}>
                                                        {
                                                            [...(ipr || [])].map((d, i) => (
                                                                <li key={i}>
                                                                    <div className="row mb-2" key={i}>
                                                                        <div className="col-md-10">
                                                                            <span className="d-block text-left mb-3">
                                                                                {d.patent_name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <button className="btn btn-sm btn-outline-danger" onClick={() => iprDelete(d)}>
                                                                                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </>
                                        )
                                    },
                                    {
                                        label: "Tipe Produk",
                                        type: "custom",
                                        component: (
                                            <>
                                                <select className="form-control form-control-sm mb-5"
                                                    value={product_type_id}
                                                    onChange={(e) => setProduct_type_id(e.target.value)}
                                                >
                                                    <option value="">- Pilih Tipe -</option>
                                                    {
                                                        data_type.map((d, i) => (
                                                            <option key={i} value={d.id}>{d.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </>
                                        )
                                    },
                                    {
                                        label: "Tahun Rilis",
                                        type: "custom",
                                        component: (
                                            <>
                                                <input type="number" min={1900} max={2100} className="form-control form-control-sm mb-5"
                                                    value={release_year} onChange={(e) => setRelease_year(e.target.value)}
                                                ></input>
                                            </>
                                        )
                                    }, {
                                        label: "Status",
                                        type: "custom",
                                        component: (
                                            <>
                                                <select className="form-control form-control-sm mb-5"
                                                    value={product_status_id}
                                                    onChange={(e) => setProduct_status_id(e.target.value)}
                                                >
                                                    <option value="">- Pilih Status -</option>
                                                    {
                                                        data_status.map((d, i) => (
                                                            <option key={i} value={d.id}>{d.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </>
                                        )
                                    }, {
                                        label: "Contribution Type",
                                        type: "custom",
                                        component: (
                                            <>
                                                <RadioGrp
                                                    modeRow={true}
                                                    onChange={(e) => setContrib_type(e)}
                                                    value={contrib_type}
                                                    data={[
                                                        {
                                                            label: "Direct", value: 'Direct'
                                                        },
                                                        {
                                                            label: "Indirect", value: 'Indirect'
                                                        },
                                                    ]}
                                                />
                                            </>
                                        )
                                    },
                                ]
                            }, {
                                rows: 2,
                                header: true,
                                name: "Informasi Initiator",
                                childs: [
                                    {
                                        label: "Inventor",
                                        type: "custom",
                                        component: (
                                            <>
                                                <TagBox
                                                    searchEnabled={true}
                                                    id="inventor"
                                                    value={creator}
                                                    items={data_user}
                                                    displayExpr="name"
                                                    displayValue="name"
                                                    valueExpr="id"
                                                    className="mb-5"
                                                    onValueChange={(e) => setCreator(e)}
                                                />
                                                {/* <select className="form-control form-control-sm mb-5"
                                                    value={creator}
                                                    onChange={(e) => setCreator(e.target.value)}
                                                >
                                                    <option value="">- Pilih Inventor -</option>
                                                    {
                                                        data_user.map((d, i) => (
                                                            <option key={i} value={d.id}>{d.name}</option>
                                                        ))
                                                    }
                                                </select> */}
                                            </>
                                        )
                                    },
                                    {
                                        label: "Kontak",
                                        type: "custom",
                                        component: (
                                            <>
                                                <select className="form-control form-control-sm mb-5"
                                                    value={contact_person}
                                                    onChange={(e) => setContact_person(e.target.value)}
                                                >
                                                    <option value="">- Pilih Kontak -</option>
                                                    {
                                                        data_user.map((d, i) => (
                                                            <option key={i} value={d.id}>{d.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </>
                                        )
                                    },
                                ]
                            }, {
                                rows: 2,
                                header: true,
                                name: "Harga Produk",
                                childs: [
                                    {
                                        label: "Predicted / Actual Price Per Quantity (USD)",
                                        type: "custom",
                                        component: (
                                            <>
                                                <input type="number" min={0} className="form-control form-control-sm mb-5" placeholder="Price (USD)"
                                                    value={price}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                ></input>
                                            </>
                                        )
                                    },
                                ]
                            }
                            , {
                                rows: 2,
                                header: true,
                                name: "Dokumen",
                                childs: [
                                    // {
                                    //     label: "Foto Sampul",
                                    //     type: "custom",
                                    //     component: (
                                    //         <div className='mb-2'>
                                    //             <FileUploadDx
                                    //                 className="custom-dx-fileuploader-file-name"
                                    //                 showFileList={true}
                                    //                 allowedFileExtensions={fileExtensions}
                                    //                 multiple={false}
                                    //                 selectButtonText="Select File"
                                    //                 readyToUploadMessage=" "
                                    //                 labelText="or Drop file hire | Max size 10 Mb"
                                    //                 accept="image/*"
                                    //                 onUploaded={fileUploadedFoto}
                                    //                 maxFileSize={10000000}
                                    //                 uploadMode="instantly"
                                    //             />
                                    //             <span>{photo_location}</span>
                                    //         </div>
                                    //     )
                                    // }, 
                                    {
                                        label: "File Pendukung",
                                        type: "custom",
                                        component: (
                                            <div className='mb-2'>
                                                <FileUploadDx
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 20 Mb"
                                                    accept="*"
                                                    onUploaded={fileUploadedFile}
                                                    maxFileSize={20000000}
                                                    uploadMode="instantly"
                                                />
                                                <span>{file_location}</span>
                                            </div>
                                        )
                                    },
                                ]
                            }, {
                                rows: 2,
                                header: true,
                                name: "Foto Produk",
                                childs: [
                                    {
                                        withLabel: false,
                                        type: 'custom',
                                        component: (<div className='text-danger mb-5'><small>* Max size 10 Mb</small></div>)
                                    },
                                    {
                                        label: "Foto Produk Utama",
                                        type: "custom",
                                        component: (
                                            <div className='mb-2'>
                                                <FileUploadDx
                                                    ref={refSampul}
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    allowedFileExtensions={fileExtensions}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText=""
                                                    accept="image/*"
                                                    onUploaded={(e) => fileUploadedFoto(e, 'sampul')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    photo_location && (
                                                        <div>
                                                            <span style={{ fontSize: '9pt' }} className='text-wrap mr-2'>{photo_location}</span>
                                                            <div className='btn btn-sm btn-icon btn-outline-danger'
                                                                onClick={() => handleDeleteFoto('sampul')}
                                                            >
                                                                <i className='fa fa-trash-alt icon-nm'></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    },
                                    {
                                        label: "Foto Produk Pendukung 1",
                                        type: "custom",
                                        component: (
                                            <div className='mb-2'>
                                                <FileUploadDx
                                                    ref={refSatu}
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    allowedFileExtensions={fileExtensions}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText=""
                                                    accept="image/*"
                                                    onUploaded={(e) => fileUploadedFoto(e, 'satu')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    photo_pendukung.satu && (
                                                        <div>
                                                            <span style={{ fontSize: '9pt' }} className='text-wrap mr-2'>{photo_pendukung.satu}</span>
                                                            <div className='btn btn-sm btn-icon btn-outline-danger'
                                                                onClick={() => handleDeleteFoto('satu')}
                                                            >
                                                                <i className='fa fa-trash-alt icon-nm'></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    },
                                    {
                                        label: "Foto Produk Pendukung 2",
                                        type: "custom",
                                        component: (
                                            <div className='mb-2'>
                                                <FileUploadDx
                                                    ref={refDua}
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    allowedFileExtensions={fileExtensions}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText=""
                                                    accept="image/*"
                                                    onUploaded={(e) => fileUploadedFoto(e, 'dua')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    photo_pendukung.dua && (
                                                        <div>
                                                            <span style={{ fontSize: '9pt' }} className='text-wrap mr-2'>{photo_pendukung.dua}</span>
                                                            <div className='btn btn-sm btn-icon btn-outline-danger'
                                                                onClick={() => handleDeleteFoto('dua')}
                                                            >
                                                                <i className='fa fa-trash-alt icon-nm'></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    },
                                    {
                                        label: "Foto Produk Pendukung 3",
                                        type: "custom",
                                        component: (
                                            <div className='mb-2'>
                                                <FileUploadDx
                                                    ref={refTiga}
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    allowedFileExtensions={fileExtensions}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText=""
                                                    accept="image/*"
                                                    onUploaded={(e) => fileUploadedFoto(e, 'tiga')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    photo_pendukung.tiga && (
                                                        <div>
                                                            <span style={{ fontSize: '9pt' }} className='text-wrap mr-2'>{photo_pendukung.tiga}</span>
                                                            <div className='btn btn-sm btn-icon btn-outline-danger'
                                                                onClick={() => handleDeleteFoto('tiga')}
                                                            >
                                                                <i className='fa fa-trash-alt icon-nm'></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    },
                                ]
                            }, {
                                rows: 2,
                                header: true,
                                name: "PIC Contributor",
                                childs: [
                                    {
                                        label: "PIC Contributor",
                                        type: "custom",
                                        component: (
                                            <TagBox
                                                searchEnabled={true}
                                                value={pic_contributor}
                                                items={[...(data_pic || [])]}
                                                displayExpr="name"
                                                displayValue="name"
                                                valueExpr="id"
                                                className="mb-5"
                                                onValueChange={(e) => setPic_contributor(e)}
                                            />
                                        )
                                    }]
                            }, {
                                rows: 2,
                                header: true,
                                name: "Tampilkan di web",
                                childs: [
                                    {
                                        label: "Tampilkan di web",
                                        type: "custom",
                                        component: (
                                            <div className='mb-5'>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={is_displayed} onChange={(e) => setis_displayed(e.target.checked)} />
                                                    <label className="form-check-label" for="exampleCheck1">Tampilkan di web</label>
                                                </div>
                                            </div>
                                        )
                                    }]
                            }
                        ]}
                    />
                    {/* <hr />
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button className="btn btn-sm btn-secondary mx-1" onClick={() => history.goBack()}>Kembali</button>
                            <button className="btn btn-sm btn-success mx-1"
                                onClick={() => saveData()}
                            >
                                <i className="fab fa-telegram-plane icon-sm"></i> Submit
                            </button>
                        </div>
                    </div> */}

                </Card.Body>
            </Card>
            <div className={`p-2 bg-white rounded shadow-lg floating-footer`} style={{
                'left': 0,
                'width': '100%'
            }}>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-sm btn-secondary mx-1" onClick={() => history.goBack()}>Kembali</button>
                        {/* {
                            history.length > 2 &&
                        } */}
                        <button className="btn btn-sm btn-success mx-1 float-md-right float-lg-right"
                            onClick={() => saveData()}
                        >
                            <i class="fa fa-save icon-nm"></i> Simpan
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormEditMaster;