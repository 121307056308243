import React, { useRef } from 'react';
import useProjectProfile, { PfProvider } from './useProjectProfile';
import './pdfasset/newStyle.css';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ProjectProfileAdmin from './profilePage';
import ChartProjectRisk from './chartProjectRisk';
import DetilProjectRisk from './detilProjectRisk';
import ProjectMilestone from './projectMilestone';
import DrawdownBudget from './drawdownBudget';
import { PDFExport } from '@progress/kendo-react-pdf';
import { useHistory, useParams } from 'react-router';
import ProjectTimelineGantt from './projectTimelineGantt';

function PfPage() {
    const { pfState, pfAction } = useProjectProfile();
    const { baseline_name = undefined, id = undefined } = useParams();
    const refPDf = useRef(null);
    const history = useHistory();
    // console.log(pfState.data_view_version)
    return (
        <>
            <div style={{ zoom: 0.8 }}>
                <div className="toolbar-pdf">
                    <div className="row">
                        <div className="col-4 form-inline">
                            <button className="btn btn-sm btn-outline-primary mr-3" onClick={() => history.goBack()}>Back</button>
                            {/* <button className="btn btn-sm btn-outline-primary mr-3">?</button> */}
                            <div className="form-group form-group-sm col-6">
                                <select
                                    onChange={(e) => history.push(`/research/project-profile/${id}/${e.target.value}`)}
                                    value={baseline_name}
                                    className="form-control form-control-sm" id="zoom">
                                    {
                                        (pfState?.data?.data_view_version || []).map((v, i) => {
                                            // console.log(v)
                                            return <option value={v.baseline_name} key={i}> Version {v.baseline_name} </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-4">
                            <div
                                className="alert bg-blue-sky text-center"
                                style={{
                                    borderRadius: "5px",
                                    width: "100%",
                                    margin: "auto",
                                }}
                            >
                                <span style={{ fontWeight: "bold" }}>Version {baseline_name}</span>
                            </div>
                        </div>
                        <div className="col-4">
                            {/* <ExportPdf targetRef={pdfRef}> */}
                            <button
                                onClick={() => {
                                    if (refPDf.current) {
                                        refPDf.current.save();
                                    }
                                }}
                                // onClick={() => rAction.getChildren(document.querySelectorAll("#page"), document.querySelectorAll("#cover"))} 
                                className="btn btn-sm btn-success mr-3 float-right"> <i className="fa fa-download"></i> Download PDF</button>
                            {/* </ExportPdf> */}
                            <div className="form-group-sm float-right col-7">
                                <select
                                    onChange={(e) => pfAction.setZoom(e.target.value)}
                                    value={pfState.zoom}
                                    className="form-control form-control-sm" id="zoom">
                                    <option value="10%">
                                        60%
                                    </option>
                                    <option value="30%">
                                        70%
                                    </option>
                                    <option value="60%">
                                        80%
                                    </option>
                                    <option value="80%">
                                        100%
                                    </option>
                                    <option value="100%">
                                        120%
                                    </option>
                                    <option value="120%">
                                        150%
                                    </option>
                                </select>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container-pdf">
                    <PDFExport forcePageBreak=".page-break" ref={refPDf} fileName={`project-profile-${pfAction.getObject(pfState.data?.data_view_header)?.title}.pdf`}>
                        {/* cover */}
                        <div className="page-root-pp cover-pp"
                            style={{
                                paddingTop: 10,
                                zoom: pfState.zoom
                            }}>
                            <div className="row">
                                <div className="col">
                                    <img src={toAbsoluteUrl("/pdfasset/logospiirit@2x.png")} alt="spiirit" style={{ width: "200px", margin: "30px" }} />
                                    <img
                                        src={toAbsoluteUrl("/pdfasset/logopertamina.png")} alt="pertamina" style={{ float: "right", margin: "30px" }} />
                                </div>
                            </div>
                            <div className="body-cover">
                                <div className="row">
                                    <div className="col">
                                        <div style={{ wordWrap: "break-word", width: "500px", marginTop: "50px" }}>
                                            <span style={{ fontSize: "40pt", fontWeight: "bolder" }}>PROJECT PROFILE</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="type_p" style={{
                                            fontSize: "14pt"
                                        }}>
                                            {pfAction.getObject(pfState.data?.data_view_header)?.project_type_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div
                                            style={{
                                                wordWrap: "break-word",
                                                width: "600px",
                                                marginTop: "0px"
                                            }}>
                                            <p style={{ fontSize: "17pt", fontWeight: "bold" }}>
                                                {pfAction.getObject(pfState.data?.data_view_header)?.title}
                                            </p>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                        {/* end of cover */}

                        {/* Page 1 Profile */}
                        <div className="page-root-pp page-break" style={{ zoom: pfState.zoom }}>
                            <div className="header-pp">
                                <div className="sub-header-pp">
                                    <h1 style={{
                                        margin: "auto", fontWeight: "bold",
                                    }}>PROJECT PROFILE</h1>
                                </div>
                            </div>

                            <ProjectProfileAdmin />
                        </div>
                        {/* End Of Page 1 */}
                        {/* Page 2 Project Risk 1*/}
                        {
                            pfState.data?.data_view_header?.project_type !== 5 && (
                                <div className="page-root-pp page-break" style={{ zoom: pfState.zoom }}>
                                    <div className="header-pp">
                                        <div className="sub-header-pp">
                                            <h1 style={{
                                                margin: "auto", fontWeight: "bold"
                                            }}>PROJECT RISK</h1>
                                        </div>
                                    </div>
                                    <ChartProjectRisk />
                                </div>
                            )
                        }
                        {/* End Of Page 2 Project Risk 1 */}

                        {/* Page 3 Project Risk 2*/}
                        {
                            pfState.data?.data_view_risk?.map((risk, k) => (
                                <div className="page-root-pp page-break" style={{ zoom: pfState.zoom }} key={k}>
                                    <div className="header-pp">
                                        <div className="sub-header-pp">
                                            <h1 style={{
                                                margin: "auto", fontWeight: "bold"
                                            }}>PROJECT RISK</h1>
                                        </div>
                                    </div>
                                    <DetilProjectRisk {...risk} key={k} />
                                </div>
                            ))
                        }
                        {/* End Of Page 3 Project Risk 2 */}

                        {/* Page 4 Project Milestone*/}
                        <div className="page-root-pp page-break" style={{ zoom: pfState.zoom }}>
                            <div className="header-pp">
                                <div className="sub-header-pp">
                                    <h1 style={{
                                        margin: "auto", fontWeight: "bold"
                                    }}>PROJECT MILESTONE</h1>
                                </div>
                            </div>
                            <ProjectMilestone />
                        </div>
                        {/* End Of 4 Project Milestone */}

                        {/* Page 5 Project Timeline*/}
                        {
                            pfState.data?.data_view_timeline?.map((gantt, k) => (
                                <div key={k} className="page-root-pp page-break" style={{ zoom: pfState.zoom }}>
                                    <div className="header-pp">
                                        <div className="sub-header-pp">
                                            <h1 style={{
                                                margin: "auto", fontWeight: "bold"
                                            }}>PROJECT TIMELINE</h1>
                                        </div>
                                    </div>
                                    <ProjectTimelineGantt {...gantt} />
                                </div>
                            ))
                        }
                        {/* End Of 5 Project Timeline */}

                        {/* Page 6 Project Timeline*/}
                        <div className="page-root-pp page-break" style={{ zoom: pfState.zoom }}>
                            <div className="header-pp">
                                <div className="sub-header-pp">
                                    <h1 style={{
                                        margin: "auto", fontWeight: "bold"
                                    }}>DRAWDOWN BUDGET</h1>
                                </div>
                            </div>
                            <DrawdownBudget />
                        </div>
                        {/* End Of Page 6 Project Drawdown Budget 2 */}

                    </PDFExport>
                </div>

            </div>

        </>
    )
}

export default () => <PfProvider> <PfPage /> </PfProvider>