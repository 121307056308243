import React, { useRef } from 'react';
import { Card } from 'react-bootstrap';
import useApprovalKerjasama, { AppKerjProvider } from './useApprovalKerjasama';
import { HeaderList, Tab, TabContent } from '../../components';
import SVG from "react-inlinesvg";
import TabWaitingApprov from './tabWaitingApprov';
import TabRejected from './tabRejected';
import TabReevaluated from './tabReevaluated';

function Index(props) {
    const { appKrjAction, appKrjState } = useApprovalKerjasama();
    return (
        <>
            <HeaderList
                title={appKrjState.dataPage?.page_title}
                someText={appKrjState.dataPage?.page_breadcrumbs}
            ></HeaderList>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-3">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded icon-header shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Design/Difference.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block text-wrap">Total partnership</span>
                                    <span className="d-block"><strong>
                                        {appKrjState.dataTable?.data_header?.total || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-purple" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Waiting Approval</span>
                                    <span className="d-block"><strong>
                                        {appKrjState.dataTable?.data_header?.waiting_approval || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Approved</span>
                                    <span className="d-block"><strong>
                                        {appKrjState.dataTable?.data_header?.approved || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Reevaluate</span>
                                    <span className="d-block"><strong>
                                        {appKrjState.dataTable?.data_header?.reevaluate || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Rejected</span>
                                    <span className="d-block"><strong>
                                        {appKrjState.dataTable?.data_header?.rejected || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "waiting",
                        title: "Waiting Approval",
                        onClick: () => appKrjAction.tabChange('waiting')
                    },
                    {
                        id: "reevaluated",
                        title: "Reevaluate",
                        onClick: () => appKrjAction.tabChange('reevaluated')
                    },                    
                    {
                        id: "reject",
                        title: "Rejected",
                        onClick: () => appKrjAction.tabChange('reject')
                    }
                ]}
                manualActive={appKrjState.selTab}
            >
                <TabContent id="waiting">
                    <TabWaitingApprov />
                </TabContent>
                <TabContent id="reevaluated">
                    <TabReevaluated />
                </TabContent>
                <TabContent id="reject">
                    <TabRejected />
                </TabContent>
            </Tab>
        </>
    );
}

export default () => <AppKerjProvider><Index /></AppKerjProvider>;