import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';

function ModalContinueReqRevision({ onRevison = (data) => { } }) {
    const [reason, setReason] = useState('');
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        if (state.modal.modalProjectContinueRevision === true) {
            resetEditor();
        }
    }, [state.modal.modalProjectContinueRevision])

    function resetEditor() {
        setReason('');
    }
    function revisionClick() {
        onRevison(reason)
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="text-wrap" style={{ width: '150px' }}>Reason</span>
                        </div>
                        <textarea value={reason} onChange={(e) => setReason(e.target.value)} className="form-control" placeholder="Reason" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
            </div>
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={() => action.toggleModal('modalProjectContinueRevision')}
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-warning mx-1"
                    onClick={() => revisionClick()}
                >
                   Need Revision
                </button>
            </div>
        </div>
    );
}

export default ModalContinueReqRevision;