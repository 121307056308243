import React, { useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'

export default function ({ children, contentPopup = { title: '', content: <></> }, placement = 'bottom', keepOpenContent = true }) {
    const [show, setShow] = useState();
    const childRef = useRef();

    const dispayChildren = React.Children.map(children, child =>
        React.cloneElement(child, {
            onMouseEnter: () => setShow(true),
            onMouseLeave: () => setShow(false),
            ref: node => {
                childRef.current = node;
                const { ref } = child;
                if (typeof ref === 'function') {
                    ref(node);
                }
            }
        })
    )[0];

    return (
        <>
            {dispayChildren}
            <Overlay
                show={show}
                target={childRef}
                placement={placement}
            >
                <Popover
                    onClick={() => setShow(false)}
                    onMouseEnter={() => keepOpenContent ? setShow(true) : null}
                    onMouseLeave={() => keepOpenContent ? setShow(false) : null}
                >
                    <Popover.Title>{contentPopup.title}</Popover.Title>
                    <Popover.Content>{contentPopup.content}</Popover.Content>
                </Popover>
            </Overlay>
        </>
    )

}