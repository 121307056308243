import React from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { baseUrl } from '../app.config';
import { useGlobalState } from '../contexts/useGlobalState';
import useLocalStorage from '../contexts/useLocalStorage';


export default function () {

    const [tokenJWT, setTokenJWT] = useLocalStorage("token", null);
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    async function wait(n) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), n);
        });
    }
    const doGet = async function ({
        param = {},
        url,
        token = true
    }) {

        let uri = url


        if (param != null) {
            var keys = Object.keys(param);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + param[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }

        try {

            let resp = await fetchApi({ url: `/${uri}`, token });
            if (resp.statusCode === 401) {
                return Swal.fire({
                    title: 'Warning',
                    text: "Session expired",
                    icon: 'warning',
                }).then(res =>
                    action.doLogout()
                )
            }
            if (resp.statusCode !== undefined && resp.statusCode !== 200) {
                const newResponse = Object.keys(resp).reduce((accumulator, key) => {
                    accumulator[key.toLowerCase()] = resp[key];
                    return accumulator;
                }, {});
                resp = {
                    code: '02',
                    message: newResponse.message
                }
            }
            if (resp.code === "02") {
                return Swal.fire({
                    title: 'Error!',
                    text: resp.message,
                    icon: 'error',
                })
            }
            if (resp.code === "01") {
                return Swal.fire({
                    title: 'Warning',
                    text: resp.message,
                    icon: 'warning',
                })
            }
            if (resp?.code === '04') {
                Swal.close();
                action.doLogout();
            }
            if (resp?.code === '05') {
                Swal.close();
                history.replace('/error-page');
            }
            return resp;
        } catch (error) {
            return {
                statusCode: 500,
                statusText: error.toString(),
                remark: error.toString()
            }
        }
    }


    async function doGetMultiple(options = []) {
        const response = await Promise.all(options.map(v => doGet(v)));
        var resp = {

        }
        options.map(({ responseVar }, i) => {
            resp = {
                ...resp,
                [responseVar]: response[i]
            }
        });
        return resp;
    }

    // contoh multiple get
    // function getMultiple(){
    //     let { view, detail } = doGetMultiple([
    //         { url: `/api/ext-partnership/view/${props.data.data?.id}`, responseVar: "view"},
    //         { url: `/api/ext-partnership/detail/${props.data.data?.id}`, responseVar: "detail"},
    //     ])
    // }
    async function doPostMultiple(options = []) {
        const response = await Promise.all(options.map(v => doPost(v)));
        var resp = {

        }
        options.map(({ responseVar }, i) => {
            resp = {
                ...resp,
                [responseVar]: response[i]
            }
        });
        return resp;
    }

    async function doPost({
        url = "/",
        data = {},
        type = 0,
        paramImage = '',
        method = 'POST',
        token = true,
        headers = null,
        queryParam = null
    }, callback = () => null) {
        var opts = {
            ...(data === null ? {} : { body: type === 0 ? JSON.stringify(data) : data }),
            method,
        };
        if (headers !== null) opts = {
            ...(data === null ? {} :
                {
                    body: JSON.stringify(data)
                }
            ), headers,
            method
        };

        if (type == 1) {
            var keys = Object.keys(data);
            var form = new FormData();

            keys.forEach((val, key) => {
                if (val === 'img' && val === 'image' && val === paramImage) {
                    form.append(val, data[val], data[val].name);
                } else {
                    form.append(val, data[val]);
                }
                // console.log(form.values())
            });
            opts = {
                body: form,
                method,
                headers: {}
            };
        }
        if (type == 2) {
            opts = {
                body: JSON.stringify(data),
                method,
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            };
        }


        let uri = url


        if (queryParam != null) {
            var keys = Object.keys(queryParam);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + queryParam[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }
        const resp = await fetchApi({ url: `/${uri}`, opts, token });
        // .then(resp => {
        const { statusCode, status, message_id, list } = resp;
        if (resp.statusCode === 401) {
            return Swal.fire({
                title: 'Warning',
                text: "Session expired",
                icon: 'warning',
            }).then(res =>
                action.doLogout()
            )
        }
        if (resp.statusCode !== undefined && resp.statusCode !== 200) {
            const newResponse = Object.keys(resp).reduce((accumulator, key) => {
                accumulator[key.toLowerCase()] = resp[key];
                return accumulator;
            }, {});
            return Swal.fire({
                title: 'Error',
                text: newResponse.message,
                icon: 'error',
            })
        }
        if (resp.code === "02") {
            return Swal.fire({
                title: 'Error!',
                text: resp.message,
                icon: 'error',
            })
        }
        if (resp.code === "01") {
            return Swal.fire({
                title: 'Warning',
                text: resp.message,
                icon: 'warning',
            })
        }
        if (resp?.code === '04') {
            Swal.close();
            action.doLogout();
        }
        if (resp?.code === '05') {
            Swal.close();
            history.replace('/error-page');
        }

        callback();
        return resp;
    }

    async function fetchApi(
        {
            url = "/",
            opts = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
            token = true,
            typeResponse = 'json'
        }
    ) {

        var data = {};
        let headers = opts.headers
        if (token) {
            if (headers === undefined || headers === null) {
                opts = {
                    ...opts,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokenJWT}`
                    }
                }
            } else {
                opts = {
                    ...opts,
                    headers: {
                        ...headers,
                        Authorization: `Bearer ${tokenJWT}`
                    }
                }
            }
        }
        try {
            var fetchResponse = await fetch(`${baseUrl + url}`, opts);
            // if(moment(lastActivity).add(5, "minutes") < moment(Date.now())){
            //     setLastActivity(undefined)
            //     setDataAlert({ show: true, body: "Maaf sesi login telah habis. silahkan login kembali", titile: `Session habis`, callBack: () =>  keycloak.logout() });
            //     return { statusCode : 401, statusText: "Session Habis", remark:"Maaf sesi login telah habis. silahkan login kembali"  }
            // }else{
            //     setLastActivity((Date.now()))
            // }
            // if (fetchResponse.status === 401 || fetchResponse.status === 403) {

            //     setDataAlert({ show: true, body: "Maaf sesi login telah habis. silahkan login kembali", titile: `Session habis`, callBack: () => keycloak.logout() });
            //     return { statusCode: 401, statusText: "Session Habis", remark: "Maaf sesi login telah habis. silahkan login kembali" }
            // }

            if (fetchResponse.status !== 200) {
                data = await fetchResponse.json();
            } else {
                if (typeResponse == 'blob') {
                    data = await fetchResponse.blob();
                    // console.log("res")
                } else {
                    data = await fetchResponse.json();
                }
            }
            // console.log(data)

            if (fetchResponse.status === 400) return { ...data, statusCode: 200, statusText: "" }

            return { ...data, statusCode: fetchResponse.status, statusText: fetchResponse.statusText }
        } catch (err) {
            return { statusCode: 500, statusText: "Error", remark: err.toString() }
        }
    }


    return {
        doGet,
        doPost,
        doGetMultiple,
        doPostMultiple,
        wait
    };
}