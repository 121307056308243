import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import usePermohonanPengajuan from '../usePermohonanPengajuan';
import { PDFExport } from '@progress/kendo-react-pdf';
import { formatTanggalId, formatterIdr, nilaiTerbilang } from '../../../../components/utility/utility';

function ModalDraftSPK(props) {
    const pdfRef = useRef();
    const { ppState, ppAction } = usePermohonanPengajuan();
    function onClose() {
        ppAction.setModalDraftSpk({ show: false, data: {} });
    }
    return (
        <Modal size='lg' show={ppState.modalDraftSpk?.show} onHide={onClose}>
            <Modal.Body>
                <PDFExport pageTemplate={() => <div>
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        margin: 'auto',
                        transform: 'rotate(-45deg)',
                        height: 'fit-content',
                        textAlign: 'center',
                        opacity: 0.4
                    }}>
                        <img crossOrigin="anonymous" style={{
                            width: '300px',
                        }} src="/loginasset/logopertamina@2x.png" />
                        <h5 className='text-center' style={{ fontSize: '26px' }}>DRAFT SPK</h5>
                    </div>
                </div>} paperSize={'A4'} margin={"1cm"} scale={0.6} forcePageBreak=".page-break" ref={pdfRef} fileName={`Surat Perjanjian Kerja ${ppState.dataStepOne?.judul_pengujian}.pdf`}>
                    <div className='rounded mb-5 p-5'>
                        <div className='row mb-5'>
                            <div className='col-md-12'>
                                <h5 className='mb-3 font-weight-bold text-center'> <u>DRAFT SURAT PERJANJIAN KERJA</u></h5>
                                <h5 className='mb-3 font-weight-bold text-center'>{ppState.modalDraftSpk.data?.no}</h5> {/*  no. SPK */}
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-md-12'>
                                <table className="table tblecs table-bordered" style={{ marginBottom: '30px' }}>
                                    <colgroup>
                                        <col width={50}></col>
                                        <col></col>
                                        <col width={250}></col>
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className='d-flex'>
                                                    <p style={{ width: '75px' }} className='d-flex justify-content-between mr-2'><span>Kepada</span><span>:</span></p>
                                                    <div>
                                                        <p>Manager Laboratory</p>
                                                        <p className='text-wrap'>Research & Technology Innovation PT. Pertamina (Persero)</p>
                                                        <p className='text-wrap'>Jl. Bekasi Raya KM 20 Pulogadung - Jakarta 13920</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Tanggal : {formatTanggalId(ppState.modalDraftSpk.data?.tglSpk)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className='d-flex'>
                                                    <p style={{ width: '75px' }} className='d-flex justify-content-between mr-2'><span>Dari</span><span>:</span></p>
                                                    <div>
                                                        <p>{ppState.modalDraftSpk.data?.user_nama}</p>
                                                        <p className='text-wrap'>{ppState.modalDraftSpk.data?.institution_nama} / {ppState.modalDraftSpk.data?.institution_sub_nama}</p>
                                                        <p className='text-wrap'>{ppState.modalDraftSpk.data?.user_fungsi}</p>
                                                        <p className='text-wrap'>{ppState.modalDraftSpk.data?.user_alamat}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><span>1.</span></td>
                                            <td colSpan={2}>
                                                <p><u>HARAP DILAKSANAKAN PEKERJAAN SEBAGAI BERIKUT:</u></p>
                                                <p className='text-wrap'>Pekerjaan Jasa Pengujian Sample Napthe RU III Plaju</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>2.</span></td>
                                            <td colSpan={2}>
                                                <p><u>DASAR PELAKSANAAN PEKERJAAN:</u></p>

                                                <div className="mb-5" dangerouslySetInnerHTML={{ __html: ppState.modalDraftSpk.data?.dpk }}></div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>3.</span></td>
                                            <td colSpan={2}>
                                                <p><u>WAKTU PELAKSANAAN PEKERJAAN:</u></p>
                                                <p className='text-wrap'>Pekerjaan tersebut di atas dimulai sejak Surat Perintah Kerja (SPK) ini ditandatangani dan harus dapat selesai pada tanggal {formatTanggalId(ppState.modalDraftSpk.data?.tglSel)}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>4.</span></td>
                                            <td colSpan={2}>
                                                <p><u>HARGA PEKERJAAN:</u></p>
                                                <p className='text-wrap'>Harga pekerjaan adalah {formatterIdr(ppState.modalDraftSpk.data?.total_harga)} ({nilaiTerbilang(ppState.modalDraftSpk.data?.total_harga)}), tidak termasuk pajak sesuai ketentuan dan perundangan yang berlaku.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>5.</span></td>
                                            <td colSpan={2}>
                                                <p><u>CARA PEMBAYARAN:</u></p>
                                                <p className='text-wrap'>Pembayaran 100% dari harga pekerjaan pada butir 4, setelah Berita Acara Serah TTerima Barang ditandatangani oleh kedua pihak.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>
                                                <p>
                                                    Demikian Surat Perintah Kerja ini ditandatangani oleh kedua belah pihak di atas Materai tempel yang cukup pada hari dan tanggal tersebut diatas dan dibuat dalam rangkap dua yang masing-masing berkekuatan hukum sama.
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table width={"100%"}>
                                    <colgroup>
                                        <col width={"30%"}></col>
                                        <col width={"40%"}></col>
                                        <col width={"30%"}></col>
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='d-flex flex-column justify-content-between' style={{ height: '200px' }}>
                                                    <div>
                                                        <p>Pihak Kedua / Penerima Perintah</p>
                                                        <p>PT. Pertamina (Persero)</p>
                                                    </div>
                                                    <div>
                                                        <p>Hartoto Nursukatmo</p>
                                                        <p>Manager Laboratory</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td>
                                                <div className='d-flex flex-column justify-content-between' style={{ height: '200px' }}>
                                                    <div>
                                                        <p>Pihak Pertama / Pemberi Perintah</p>
                                                        <p>{ppState.modalDraftSpk.data?.user_nama}</p>
                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </PDFExport>
                <hr />
                <div className='row'>
                    <div className='col-md-6'>
                        <button className='btn btn-sm btn-danger'
                            onClick={() => {
                                if (pdfRef.current) {
                                    pdfRef.current.save();
                                }
                            }}
                        >Download PDF</button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default ModalDraftSPK;