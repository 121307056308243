import React, { memo } from 'react'
import { Card } from 'react-bootstrap'
import FileUploader from 'devextreme-react/file-uploader';
import { useGlobalState } from '../../contexts/useGlobalState';

const ModalEditScreensaver = memo((props) => {
    const [state, dispatch, action] = useGlobalState();

    function onSaveClick() {
        action.toggleModal("modalEditScreensaver")
        props.onSave(state.formData)
    }
    function fileSelected(e) {
        let reader = new FileReader;
        let file = e;
        reader.onloadend = () => {
            action.setForm("image", {
                imagePreview: reader.result,
                file: file
            });
        };
        reader.readAsDataURL(file);
    }
    return (
        <>
            <Card>
                <Card.Body>
                    <span className="d-block">{props?.mode}&nbsp;Screensaver</span>
                    <hr />
                    <div className="row">
                        <div className="col-md-2">
                            Judul
                        </div>
                        <div className="col-md">
                            <input type='text' className="form-control form-control-sm" onChange={(e) => action.setForm("judul", e.target.value)} value={state.formData.judul !== undefined ? state.formData.judul : props.data?.judul}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            Foto
                        </div>
                        <div className="col-md">
                            <FileUploader className="custom-dx-fileuploader-file-name" showFileList={true} multiple={false}
                                readyToUploadMessage=" "
                                onValueChanged={(e) => fileSelected(e.value?.[0])}
                                selectButtonText="Select File" labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000}
                                uploadMode="useForm" ></FileUploader>
                        </div>
                    </div>
                    <hr />
                    <div className="d-block text-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => action.toggleModal("modalEditScreensaver")}
                        >
                            Batal
                        </button>
                        <button className="btn btn-sm btn-primary mx-1"
                            onClick={() => onSaveClick()}
                        >
                            Simpan
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})
export default ModalEditScreensaver