import React from 'react';
import { FooterCard, Navb, } from './component';
import './index.css';
import useIndexLandPage, { IndProvider } from './use_index';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutePage from './routes/routes';
import * as pages from './page';
import { Container } from 'react-bootstrap';

function Index(props) {
    const { indState } = useIndexLandPage();
    const PageRender = pages[indState.pageState.type];
    return (
        // <Router>
        <div className='bg-white'>
            <Navb />
            <div style={{ flex: 1 }}>
                {/* <PageRender /> */}
               
                    <RoutePage />
               
            </div>
            <FooterCard />
        </div>
        // </Router>
    );
}

export default (props) => <IndProvider><Index {...props} /></IndProvider>;