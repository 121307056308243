import React, { createContext } from "react";
import { useState } from "react";
import { useContext } from "react";
import useFetch from "../../modules/useFetch";
import Swal from "sweetalert2";

const subhContext = createContext({
    subhAction: {},
    subhState: {}
});

const month = [{ name: 'JAN', value: '1' }, { name: 'FEB', value: '2' }, { name: 'MAR', value: '3' }, { name: 'APR', value: '4' },
{ name: 'MAY', value: '5' }, { name: 'JUN', value: '6' }, { name: 'JUL', value: '7' }, { name: 'AUG', value: '8' }, { name: 'SEP', value: '9' },
{ name: 'OCT', value: '10' }, { name: 'NOV', value: '11' }, { name: 'DEC', value: '12' }]

export function SubhProvider({ children }) {
    const { doGet } = useFetch();
    const [selectedTab, setSelectedTab] = useState('dashboard')
    const [filter, setFilter] = useState({
        tahun: '',
        bulan: ''
    });
    const [year_source, setYear_source] = useState([]);
    const [data_page, setData_page] = useState({
        "page_title": "",
        "page_breadcrumbs": ""
    });

    //Header Filter
    const filterChange = (name, val) => {
        setFilter((e) => ({
            ...e,
            [name]: val
        }));

        if (selectedTab === 'dashboard') {
            getDataTabDashboard(name === 'tahun' ? val : filter.tahun, name === 'bulan' ? val : filter.bulan);
        } else {
            getDataTabTable(name === 'tahun' ? val : filter.tahun, name === 'bulan' ? val : filter.bulan, subHoldingFilter);
        }
    };

    //TabTable Function Handler
    const [subHoldingFilter, setSubHoldingFilter] = useState(0);
    const [dataTabTable, setDataTabTable] = useState({});
    const [loadingTabTable, setLoadingTabTable] = useState(false);
    const getDataTabTable = async (tahun, bulan, subHolding) => {
        setLoadingTabTable(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/project-ongoing/table-sub-holdings',
                param: {
                    tahun,
                    bulanGet: bulan,
                    subHolding
                }
            });
            if (resp?.code === '00') {
                const data_table = [...(resp?.data?.data_table || [])].map(d => {
                    var str = "";
                    if (d?.subholding1 != 0) str = d?.subholding1_name;
                    if (d?.subholding2 != 0) str = str + "; " + d?.subholding2_name;
                    if (d?.subholding3 != 0) str = str + "; " + d?.subholding3_name;
                    return {
                        ...d,
                        'subholding': str
                    }
                });
                setFilter({
                    tahun: resp.data?.data_tahunGet,
                    bulan: resp.data?.data_bulanNow
                });
                setYear_source([...(resp.data?.data_allyear || [])].map(d => ({ name: d?.idYear, value: d?.idYear })));
                setData_page(resp?.data_page);
                setDataTabTable({ ...resp.data, data_table });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoadingTabTable(false);
    }
    const subholdingFilterChange = (val) => {
        setSubHoldingFilter(val);
        getDataTabTable(filter.tahun, filter.bulan, val)
    }

    //Tab Dashboard Function Handler
    const [perfomAll, setPerfomAll] = useState(0);
    const [dataTabDash, setDataTabDash] = useState({});
    const [loadingTabDash, setLoadingTabDash] = useState(false);
    const getDataTabDashboard = async (tahun, bulan) => {
        setLoadingTabDash(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/project-ongoing/dashboard-sub-holdings',
                param: {
                    idFunction: 0,
                    tahun,
                    bulanGet: bulan,
                }
            });
            if (resp?.code === '00') {
                calculatePerformAll(resp.data?.data_HeaderMonitoring_SH);
                const data_SCurve1_SH = dsCurve1(resp.data?.data_SCurve1_SH, resp.data?.data_bulan_default);
                const data_SCurve2_SH = dsCurve2(resp.data?.data_SCurve2_SH, resp.data?.data_bulan_default);
                setFilter({
                    tahun: resp.data?.data_tahun_default,
                    bulan: resp.data?.data_bulan_default
                });
                setYear_source([...(resp.data?.data_year_portfolio || [])].map(d => ({ name: d?.idYear, value: d?.idYear })));
                setData_page(resp?.data_page);
                setDataTabDash({ ...resp.data, data_SCurve1_SH, data_SCurve2_SH });

                getDataChart3(tahun);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoadingTabDash(false);
    }
    const calculatePerformAll = (data = []) => {
        var actual_tot = 0
        var plan_tot = 0
        var performAll = 0
        var plan_dec = 0
        if (data) {
            data.forEach(e => {
                actual_tot += e.actual_tot;
                plan_tot += e.plan_tot;
                plan_dec += e.plan_dec;
            });
        }
        if (plan_tot != 0) {
            performAll = actual_tot / plan_dec;
        } else {
            performAll = 0;
        }
        setPerfomAll(parseFloat(performAll.toFixed(2)) * 100);
    }
    const dsCurve1 = (data = {}, data_bulan_default = 0) => {
        var ds = [
            { arg: "JAN", name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", name: "dec", plan: null, actual: null, perform: null },
        ]
        if (data) {
            Object.keys(data).map((key) => {
                var tempKey = key.substr(0, key.indexOf('_'))
                var tempName = key.substr(key.indexOf('_') + 1, key.length)
                if (tempKey === "plan") {
                    ds.find(e => e.name === tempName).plan = data[key]
                } else if (tempKey === "actual") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        ds.find(e => e.name === tempName).actual = data[key]
                    }
                } else if (tempKey === "perform") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        ds.find(e => e.name === tempName).perform = Number(parseFloat(data[key] * 100).toFixed(1))
                    }
                }
            })
        }
        return ds
    }
    const dsCurve2 = (data = {}, data_bulan_default = 0) => {
        var ds = [
            { arg: "JAN", name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", name: "dec", plan: null, actual: null, perform: null },
        ]
        if (data) {
            Object.keys(data).map((key) => {
                var tempKey = key.substr(0, key.indexOf('_'))
                var tempName = key.substr(key.indexOf('_') + 1, key.length)
                if (tempKey === "plan") {
                    var index = ds.findIndex(e => e.name === tempName)
                    if (index !== -1) {
                        ds[index].plan = Math.round(data[key]);
                    }
                } else if (tempKey === "actual") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        var index = ds.findIndex(e => e.name === tempName)
                        if (index !== -1) {
                            ds[index].actual = Math.round(data[key])
                        }
                    }
                } else if (tempKey === "perform") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        var index = ds.findIndex(e => e.name === tempName)
                        if (index !== -1) {
                            ds[index].perform = Math.round(data[key] * 100)
                        }
                    }
                }
            })
        }
        return ds
    }
    const [loadingChart3, setLoadingChart3] = useState(false);
    const [data_chart3, setData_chart3] = useState({});
    const getDataChart3 = async (tahunQuery, tipe) => {
        setLoadingChart3(true)
        try {
            const resp = await doGet({
                url: 'api/project-ongoing/dashboard-sub-holdings-barchart2',
                param: {
                    tipe: tipe || '3',
                    tahunQuery,
                }
            });
            if (resp?.code === '00') {
                setData_chart3(resp.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoadingChart3(false);
    }

    return <subhContext.Provider
        value={{
            subhAction: {
                filterChange,
                setData_page,
                subholdingFilterChange,
                getDataTabTable,
                getDataTabDashboard,
                setSelectedTab,
                getDataChart3
            },
            subhState: {
                year_source,
                filter,
                data_page,
                subHoldingFilter,
                loadingTabTable,
                dataTabTable,
                dataTabDash,
                loadingTabDash,
                selectedTab,
                perfomAll,
                loadingChart3,
                data_chart3
            }
        }}
    >{children}</subhContext.Provider>
};

export default function useSubholdings() {
    return useContext(subhContext);
}