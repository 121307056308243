import React, { createContext, useContext, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2';
import useFetch from '../../modules/useFetch';

const useKerjasamaContext = createContext({
    krjAction: {},
    krjState: {}
})
export function KerjasamaProvider({ children }) {
    const [globalState, dispatch, globalAction] = useGlobalState();
    const { doGet } = useFetch();
    const [data_page, setData_page] = useState({});

    const color = ['#F2BA22', '#C070CC', '#874BE6', '#ACC429', '#326BD5'];
    const [dashboard_box, setDashboard_box] = useState([]);
    const [header, setHeader] = useState({});
    const [data_chart1, setData_chart1] = useState({});
    const [data_chart2, setData_chart2] = useState({});
    const [data_chart3, setData_chart3] = useState({});
    const [data_chart4, setData_chart4] = useState({});
    const [data_chart5, setData_chart5] = useState({});
    const [data_chart6, setData_chart6] = useState({});
    const [data_chart7, setData_chart7] = useState([]);
    const [data_chart8, setData_chart8] = useState([]);
    const [data_chart9, setData_chart9] = useState([]);
    const [data_chart_cluster, setData_chart_cluster] = useState({});

    const [filter_chart1, setFilter_chart1] = useState('0');
    const [filter_chart2, setFilter_chart2] = useState('0');
    const [filter_chart3, setFilter_chart3] = useState('0');
    const [filter_chart4, setFilter_chart4] = useState('0');
    const [filter_chart5, setFilter_chart5] = useState('0');
    const [filter_chart6, setFilter_chart6] = useState('0');

    async function getDashboardBox() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-box` })
            if (resp.code === "00") {
                setDashboard_box(resp.data);
                setData_page(resp.data_page);
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
        Swal.close();
    }
    async function getHeader() {
        try {
            // const resp = await globalAction.getData(`/api/ext-partnership/header`, {}, null, null, true)
            // if (resp.code === "00") {
            //     // console.log('box', resp.data);
            //     setHeader(resp.data)
            // }
        } catch (err) {
            Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart1(fungsi) {
        setFilter_chart1(fungsi);
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart1?fungsi=${fungsi}` })
            if (resp.code === "00") {
                setData_chart1(resp.data)
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart2(dok_status) {
        setFilter_chart2(dok_status);
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart2?dok_status=${dok_status}` })
            if (resp.code === "00") {
                setData_chart2(resp.data)
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart3(dok_status) {
        setFilter_chart3(dok_status);
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart3?dok_status=${dok_status}` })
            if (resp.code === "00") {
                setData_chart3(resp.data)
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart4(jenis_dok) {
        setFilter_chart4(jenis_dok);
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart4?jenis_dok=${jenis_dok}` })
            if (resp.code === "00") {
                setData_chart4(resp.data)
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart5(dok_status) {
        setFilter_chart5(dok_status);
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart5?dok_status=${dok_status}` })
            if (resp.code === "00") {
                setData_chart5({
                    data_dok_status: resp.data?.data_dok_status,
                    data_chart: [
                        { state: "0 - 1", jumlah: resp.data?.data_chart?.jw_01 },
                        { state: "1 - 2", jumlah: resp.data?.data_chart?.jw_12 },
                        { state: "2 - 3", jumlah: resp.data?.data_chart?.jw_23 },
                        { state: "3 - 4", jumlah: resp.data?.data_chart?.jw_34 },
                        { state: "4 - 5", jumlah: resp.data?.data_chart?.jw_45 },
                        { state: "> 5", jumlah: resp.data?.data_chart?.jw_50 },
                    ]
                })
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart6(dok_status) {
        setFilter_chart6(dok_status);
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart6?dok_status=${dok_status}` })
            if (resp.code === "00") {
                setData_chart6({
                    ...resp.data
                })
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart7() {
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart7` })
            if (resp.code === "00") {
                setData_chart7(resp?.data)
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart8() {
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart8` })
            if (resp.code === "00") {
                setData_chart8(resp?.data)
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChart9() {
        try {
            const resp = await doGet({ url: `api/ext-partnership/dashboard-chart9` })
            if (resp.code === "00") {
                setData_chart9(resp?.data)
            }
        } catch (err) {
            return Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getChartCluster() {
        try {
            const resp = await doGet({ url: `/api/ext-partnership/dashboard-chart-cluster` })
            if (resp.code === "00") {
                setData_chart_cluster({ ...resp.data });
            }
        } catch (err) {
            Swal.fire("Error", err.toString(), "error")
        }
    }

    function getDashboard() {
        getDashboardBox();
        getHeader();
        getChart1(filter_chart1);
        getChart2(filter_chart2);
        getChart3(filter_chart3);
        getChart4(filter_chart4);
        getChart5(filter_chart5);
        getChart6(filter_chart6);
        getChart7();
        getChart8();
        getChart9();
        getChartCluster();
    }
    function renderLabelChart5(value = '0') {
        var txt = value;
        if (value === '0') {
            txt = '< 1 tahun'
        }
        if (value === '1') {
            txt = '1 - 2 tahun'
        }
        if (value === '2') {
            txt = '2 - 3 tahun'
        }
        if (value === '3') {
            txt = '3 - 4 tahun'
        }
        if (value === '4') {
            txt = '4 - 5 tahun'
        }
        if (parseInt(value) >= 5) {
            txt = ' > 5 tahun'
        }
        return txt;
    }
    // function MarkerTemplate(item) {
    //     const color = item.series.isVisible() ? item.marker.fill : '#888';
    //     return (
    //         <svg>
    //             <rect x={0} y={0} width={18} height={18} fill={color} opacity={0.3}></rect>
    //             <path d={markerPath[item.series.name]} fill={color}></path>
    //         </svg>
    //     );
    // }
    return <useKerjasamaContext.Provider value={{
        krjAction: {
            getDashboardBox,
            getHeader,
            getDashboard,
            getChart1,
            getChart2,
            getChart3,
            getChart4,
            getChart5,
            renderLabelChart5,
            setData_page,
        },
        krjState: {
            dashboard_box,
            data_page,
            header,
            color,
            data_chart1,
            data_chart2,
            data_chart3,
            data_chart4,
            data_chart5,
            data_chart6,
            data_chart7,
            data_chart8,
            data_chart9,
            filter_chart5,
            data_chart_cluster
        }
    }}>
        {children}
    </useKerjasamaContext.Provider>
}

export default function useKejasama() {
    return useContext(useKerjasamaContext)
};