import React, { useState, useRef, useEffect, memo } from 'react';
import { FormResearch, RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import {
    jangkaWaktu
} from './dummy-data';
import { Accordion } from 'devextreme-react';
import Tags from "@yaireo/tagify/dist/react.tagify"
import { Link } from 'react-router-dom';
import { pathFile } from '../../app.config';


const TabDetailMon = memo(({ param }) => {
    const [state, dispatch, action] = useGlobalState();
    const tagref = useRef()

    useEffect(() => {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-detail/" + param?.id, {}, null, "isLoading", true).then((resp) => {
                if (resp.code === "00") {
                    getLeader(resp.data.data_view_team_members)
                    getMember(resp.data.data_view_team_members)
                    setKeahlian(resp.data.data_view_expertise_needed)
                    getArea();
                    getAreaKategory(resp.data.data_view_detail?.area_id)
                    const t = [...(resp.data.data_view_keywords || [])].map(d => { return { value: d.name } })
                    const dukOp = resp.data.data_dukungan_operasional.map((d, i) => {
                        return {
                            name: d.title,
                            value: d.param_id
                        }
                    })
                    const data_duk = [];
                    if (resp.data.data_view_detail?.duk_operasional_opt_0) {
                        const t = dukOp.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_0.toString())
                        if (t) {
                            data_duk.push({
                                name: t?.name,
                                value: t?.value
                            })
                        }
                    }
                    if (resp.data.data_view_detail?.duk_operasional_opt_1) {
                        const t = dukOp.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_1.toString())
                        if (t) {
                            data_duk.push({
                                name: t?.name,
                                value: t?.value
                            })
                        }
                    }
                    if (resp.data.data_view_detail?.duk_operasional_opt_2) {
                        const t = dukOp.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_2.toString())
                        if (t) {
                            data_duk.push({
                                name: t?.name,
                                value: t?.value
                            })
                        }
                    }
                    action.setForm("dukungan_operational", data_duk);
                    action.setForm("document", [...(resp.data.data_view_documents || [])]);
                    action.setForm('keyword', t)
                    action.setForm('pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.pendapatan)
                    action.setForm('efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.efisiensi)
                    action.setForm('produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.produksi)
                    action.setForm('sat_pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_pendapatan)
                    action.setForm('sat_efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_efisiensi)
                    action.setForm('sat_produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_produksi)
                    action.setForm('ket_pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_pendapatan)
                    action.setForm('ket_efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_efisiensi)
                    action.setForm('ket_produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_produksi)
                    action.setForm('tangible_angka', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.tangible_angka);
                    action.setForm('tangible_keterangan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.tangible_keterangan);
                    action.setForm('intangible', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.intangible)
                    action.setForm('mon', resp.data_page);
                    action.setForm('focus', resp.data.data_view_detail?.focus)
                }
            })
        }
    }, [])

    function renderTitle(data) {
        return (
            <div className="m-2" style={{ fontSize: "10px", fontFamily: 'Poppins' }}><i className="fa flaticon-user-ok p-2"></i>{data?.name}</div>
        )
    }
    function renderItem(data) {
        return (
            <div className="row h-100 p-5 border-top" style={{ fontFamily: 'Poppins' }}>
                <div className="col-md-4 mb-5">
                    <img src={data?.image || "/media/svg/icons/General/User.svg"}
                        style={{ width: '100%' }}
                    ></img>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Nama</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Posisi</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.position_name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>No. Pegawai</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.employee_id}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Expertise</div>
                        <div className="col-auto"></div>
                    </div>
                    {
                        data.technical_competency?.split(',').map((s, i) => (
                            <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                <div className="col-3"></div>
                                <div className="col-9" style={{ fontSize: '8pt' }}>{i + 1}.&nbsp;{s}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
    function getLeader(data) {
        if (data) {
            const disctinctLeaderName = Array.from([... new Set((data.filter(obj => obj.team_role === "Leader")).map(s => s))]).map(d => {
                if (d.user_id !== null) {
                    return {
                        user_id: d.user_id,
                        employee_id: d.employee_id,
                        name: d.name,
                        position_name: d.position_name,
                        technical_competency: d.technical_competency
                    }
                }
            })
            action.setForm("leader", disctinctLeaderName?.[0])
        }
    }
    function getMember(data) {
        if (data) {
            const disctinctMamberName = Array.from([... new Set((data.filter(obj => obj.team_role === "Member")).map(s => s))]).map(d => {
                return {
                    user_id: d.user_id,
                    employee_id: d.employee_id,
                    name: d.name,
                    is_new: false,
                    position_name: d.position_name,
                    technical_competency: d.technical_competency
                }

            })
            action.setForm("anggota", disctinctMamberName)
        }
    }
    function getFungsi() {
        let newDs = []
        if (state.data_view_team_members) {
            const distinctFunction = Array.from([... new Set((state.data_view_team_members.map(s => s.employee_id)))]).map(id => {
                return {
                    employee_id: id,
                    section_name: [... new Set(state.data_view_team_members.filter(obj => obj.employee_id === id).map(ob => ob.section_name))],
                    function_short_name: [... new Set(state.data_view_team_members.filter(obj => obj.employee_id === id).map(ob => ob.function_short_name))]
                }
            })
            newDs = distinctFunction;
        }
        return newDs
    }
    function setKeahlian(data) {
        if (data.length > 0) {
            dispatch({
                type: "getSuccess",
                payload: { data_view_keahlian: data, loadingGet: false }
            })
        }
    }
    function getArea() {
        action.getData("/api/param-area", {},).then((resp) => {
            if (resp.code === "00") {
                const newDs = resp.data.map((d, i) => {
                    return {
                        value: d.id,
                        name: d.name
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { area: newDs, loadingGet: false }
                })
            }
        })
    }
    function getAreaKategory(value) {
        if (value) {
            action.getData("/api/param-area-category/" + value, null, 'area_kategori').then((resp) => {
                if (resp.code === "00") {
                    const newDs = resp.data.map((d, i) => {
                        return {
                            value: d.id,
                            name: d.name
                        }
                    })
                    dispatch({
                        type: "getSuccess",
                        payload: { area_kategori: newDs, loadingGet: false }
                    })
                }
            })
        }
    }
    return (
        <FormResearch
            formLists={[
                {
                    rows: 1,
                    header: true,
                    name: "Initiator Ide",
                    childs: [
                        {
                            type: "text",
                            label: "Nama",
                            value: state.data_view_detail?.initiator_name,
                            disabled: true,
                            placeholder: "Administrator",
                        },
                        {
                            label: "Jabatan",
                            type: "text",
                            value: state.data_view_detail?.initiator_position_name,
                            disabled: true,
                            placeholder: "Administrator",
                        },
                        {
                            label: "Fungsi",
                            disabled: true,
                            type: "text",
                            value: state.data_view_detail?.function_name,
                            placeholder: "Fungsi",
                        }
                    ]
                },
                {
                    rows: 1,
                    header: true,
                    name: "Latar Belakang",
                    childs: [
                        {
                            label: "Judul",
                            value: state.data_view_detail?.title,
                            type: "textarea",
                            disabled: true
                        }
                        ,
                        {
                            label: "Pain Point",
                            value: state.data_view_detail?.description,
                            placeholder: "Tulis disini...",
                            type: "textarea",
                            disabled: true
                        },
                        {
                            label: "Commercialization /Implementation- Objective",
                            value: state.data_view_detail?.purpose,
                            placeholder: "Tulis disini...",
                            type: "textarea",
                            disabled: true
                        },
                        // {
                        //     label: "Jangka Waktu (Tahun)",
                        //     options: jangkaWaktu,
                        //     value: state.data_view_detail?.jangka_waktu,
                        //     placeholder: "- Pilih jangka waktu -",
                        //     type: "select",
                        //     disabled: true
                        // },
                        ...(state.data_view_header?.project_type?.toString() !== '5' ? [
                            {
                                label: <> Dukungan Operational </>,
                                type: "custom",
                                component: (
                                    <>
                                        {
                                            [...(state.formData.dukungan_operational || [])].length > 0 ? (
                                                [...(state.formData.dukungan_operational || [])].map((d, i) => (
                                                    <span className="d-block text-wrap mb-3" key={i}>{d.name}</span>
                                                ))

                                            ) : (
                                                <span className="d-block text-wrap mb-5">&nbsp;</span>
                                            )


                                        }
                                    </>
                                )

                            }, {
                                label: "Detail Dukungan dan Operational",
                                type: "textarea",
                                value: state.data_view_detail?.duk_operasional_ket,
                                disabled: true
                            },

                            {
                                label: "Pemakai Potensial",
                                type: "textarea",
                                placeholder: "Pemakaian potensial",
                                value: state.data_view_detail?.potential_user,
                                disabled: true
                            }

                            ,
                        ] : []),
                        {
                            label: <> Keywoard <i title="Kata kunci terkait riset yang dijalankan, dipisah dengan tanda (,) " className="fa fa-exclamation-circle"></i> </>,
                            type: "custom",
                            component: (
                                <Tags
                                    readOnly={true}
                                    tagifyRef={tagref}
                                    value={state.formData.keyword}
                                />
                            )
                        },
                    ]
                },
                {
                    rows: 1,
                    header: true,
                    name: "Susunan tim",
                    childs: [
                        {
                            type: "custom",
                            withLabel: false,
                            component: (
                                <>
                                    <span style={{ fontSize: '8pt', fontWeight: 'bold' }}>Leader</span>
                                    {
                                        state.formData.leader && (
                                            <div className="row mb-5">
                                                <div className="col" style={{ marginLeft: 10, marginRight: 10 }}>

                                                    <div className="row" style={{ paddingTop: '20px', paddingLeft: '7px', paddingBottom: '20px', border: '1px solid #ebedf2', borderRadius: '3px' }}>
                                                        <div className="col-md-4 mb-5">
                                                            <img src={state.formData.leader?.image || "/media/svg/icons/General/User.svg"}
                                                                style={{ width: '100%' }}
                                                            ></img>
                                                        </div>
                                                        <div className="col-md-8 mb-5">
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">Nama</div>
                                                                <div className="col-auto">: {state.formData.leader.name}</div>
                                                            </div>
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">Posisi</div>
                                                                <div className="col-auto">: {state.formData.leader.position_name}</div>
                                                            </div>
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">No. Pegawai</div>
                                                                <div className="col-auto">: {state.formData.leader.employee_id}</div>
                                                            </div>
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">Expertise</div>
                                                                <div className="col-auto"></div>
                                                            </div>
                                                            {
                                                                state.formData.leader.technical_competency?.split(',').map((ex, i) => (
                                                                    <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                                                        <div className="col-3"></div>
                                                                        <div className="col-auto">{i + 1}.&nbsp;{ex}</div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        },
                        {
                            type: "custom",
                            withLabel: false,
                            component: (
                                <>
                                    <span style={{ fontSize: '8pt', fontWeight: "bold" }}>Anggota</span>
                                    {
                                        state.formData.anggota?.length > 0 ? (
                                            <div id="accordion" className="row">
                                                <div className="col-md-12">
                                                    <Accordion
                                                        multiple={true}
                                                        className="m-0 p-0 ctsm-acordion mb-5"
                                                        itemRender={renderItem}
                                                        dataSource={state.formData.anggota} collapsible={true}
                                                        itemTitleRender={renderTitle}
                                                    >
                                                    </Accordion>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mb-5"></div>
                                        )
                                    }
                                </>
                            )
                        }
                    ]
                },
                {
                    rows: 1,
                    header: true,
                    name: "Fungsi",
                    childs: [
                        {
                            type: "custom",
                            withLabel: false,
                            component: (
                                <>
                                    {
                                        getFungsi().map((d, i) => (
                                            <span className="d-block" key={i}><i className="fa fa-circle icon-sm text-dark"></i> {d.section_name[0]} ({d.function_short_name[0]})</span>
                                        ))
                                    }
                                </>
                            )
                        }

                    ]
                },
                ...(state.data_view_header?.project_type?.toString() !== '5' ? [
                    {
                        rows: 2,
                        header: true,
                        name: "Keahlian Peneliti yang Dibutuhkan",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div style={{ paddingBottom: '25px' }}>
                                        {
                                            state.data_view_keahlian?.map((kh, key) => (
                                                <span className="d-block" style={{ fontSize: '8pt' }} key={key}>{kh.technical_competency}</span>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: "Klasifikasi Research",
                        childs: [
                            {
                                type: "select",
                                label: "Area Riset",
                                value: state.data_view_detail?.area_id,
                                options: state.area,
                                disabled: true
                            },
                            {
                                type: "select",
                                options: state.area_kategori,
                                value: state.data_view_detail?.research_category_id,
                                label: "Kategory Riset",
                                disabled: true
                            },
                        ]
                    },
                ] : []),
                {
                    rows: 2,
                    header: true,
                    name: <>Ruang Lingkup Riset <span className="text-danger"></span></>,
                    childs: [
                        {
                            type: "array",
                            withLabel: false,
                            forms: [
                                {
                                    type: "textarea",
                                    value: state.formData.focus,
                                    // onChange: function (e) { action.setForm("focus", e) },
                                    // placeholder: "Ruang Lingkup Riset",
                                    disabled: true
                                },
                            ]
                        },
                    ]
                },
                {
                    rows: 2,
                    header: true,
                    name: "Dokumen Pendukung",
                    childs: [
                        {
                            type: "custom",
                            label: "Sumber File",
                            component: (
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                [...(state.formData.document || [])].map((file, key) => (
                                                    <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                        {
                                                            file.link === null ? (
                                                                <>
                                                                    <span style={{ fontSize: '8pt' }}>{file.name}</span>
                                                                    <a target="_blank" href={file.file_location} download>
                                                                        <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1"></i>
                                                                    </a>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <a target="_blank" href={file.link} style={{ fontFamily: 'Poppins', fontSize: '10px' }}>{file.link}</a>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    ]
                }, {
                    rows: 2,
                    header: true,
                    name: "Proyek berkaitan",
                    childs: [
                        {
                            type: "custom",
                            component: (
                                <>
                                    {
                                        [...(state.data_view_related_research || [])].map((d, i) => (
                                            <span className="d-block  pl-2" key={i} style={{ fontSize: '8pt' }}>{i + 1}.&nbsp;{d.title}</span>
                                        ))
                                    }
                                </>
                            )
                        }
                    ]
                },
                ...(state.data_view_header?.project_type?.toString() !== '5' ? [
                    {
                        rows: 2,
                        header: true,
                        name: "Metode Riset",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <RadioGrp
                                            disable={true}
                                            value={state.data_view_detail?.method + ""}
                                            data={[
                                                { label: 'Inhouse', value: '1', disable: true },
                                                { label: 'Procurement', value: '2', disable: true },
                                                { label: 'Partnership', value: '3', disable: true },
                                                { label: 'Swakelola', value: '4', disable: true }
                                            ]}
                                        />
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        childs: [
                            {
                                type: 'custom',
                                withLabel: false,
                                component: (
                                    <>
                                        {
                                            (state.data_view_detail?.method === '2' || state.data_view_detail?.method === '3' || state.data_view_detail?.method === '4') && (
                                                <>
                                                    <div className="row mb-5">
                                                        <div className="col-md-4" style={{ fontSize: "8pt" }}>
                                                            Potensial Partner
                                                        </div>
                                                        <div className="col-md">
                                                            <select disabled className="form-control form-control-sm" value={state.data_view_detail?.potensial_partner_opt}>
                                                                {
                                                                    [...(state.data_potensial_partner || [])].map((d, i) => (
                                                                        <option value={d.param_id} key={i}>{d.title}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-4" style={{ fontSize: "8pt" }}>
                                                            Detail Partner
                                                        </div>
                                                        <div className="col-md">
                                                            <input disabled className="form-control form-control-sm" value={state.data_view_detail?.potensial_partner_ket}>
                                                            </input>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            },
                        ]
                    }
                    , {
                        rows: 2,
                        header: true,
                        name: "Value Creation",
                        childs: [
                            // {
                            //     type: "custom",
                            //     label: "Pendapatan (Revenus)",
                            //     component: (
                            //         <>
                            //             <div className="form-group row">
                            //                 <div className="col-md-4" >
                            //                     <input disabled={true} placeholder="Berat Angka" className="form-control form-control-sm" value={state.data_view_value_creations?.[0]?.pendapatan}></input>
                            //                 </div>
                            //                 <div className="col-md-4" >
                            //                     <select disabled={true} className="form-control form-control-sm" value={state.data_view_value_creations?.[0]?.sat_pendapatan}>
                            //                         <option value="">Pilih Satuan</option>
                            //                         {
                            //                             state.data_sat_pendapatan?.map((d, i) => (
                            //                                 <option key={i} value={d.param_id}>{d.title}</option>
                            //                             ))
                            //                         }
                            //                     </select>
                            //                 </div>
                            //                 <div className="col-md-4" >
                            //                     <input className="form-control form-control-sm" placeholder="Keterangan" disabled={true} value={state.data_view_value_creations?.[0]?.ket_pendapatan}></input>
                            //                 </div>
                            //             </div>
                            //         </>
                            //     )
                            // },
                            // {
                            //     type: "custom",
                            //     label: "Efisiensi",
                            //     component: (
                            //         <>
                            //             <div className="form-group row">
                            //                 <div className="col-md-4" >
                            //                     <input className="form-control form-control-sm" placeholder="Berat Angka" disabled={true} value={state.data_view_value_creations?.[0]?.efisiensi}></input>
                            //                 </div>
                            //                 <div className="col-md-4" >
                            //                     <select className="form-control form-control-sm" disabled={true} value={state.data_view_value_creations?.[0]?.sat_efisiensi}>
                            //                         <option value="">Pilih Satuan</option>
                            //                         {
                            //                             state.data_sat_efisiensi?.map((d, i) => (
                            //                                 <option key={i} value={d.param_id}>{d.title}</option>
                            //                             ))
                            //                         }
                            //                     </select>
                            //                 </div>
                            //                 <div className="col-md-4" >
                            //                     <input className="form-control form-control-sm" placeholder="Keterangan" disabled={true} value={state.data_view_value_creations?.[0]?.ket_efisiensi}></input>
                            //                 </div>
                            //             </div>
                            //         </>
                            //     )
                            // },
                            // {
                            //     type: "custom",
                            //     label: "Production",
                            //     component: (
                            //         <>
                            //             <div className="form-group row">
                            //                 <div className="col-md-4" >
                            //                     <input className="form-control form-control-sm" placeholder="Berat Angka" disabled={true} value={state.data_view_value_creations?.[0]?.produksi}></input>
                            //                 </div>
                            //                 <div className="col-md-4" >
                            //                     <select className="form-control form-control-sm" disabled={true} value={state.data_view_value_creations?.[0]?.sat_produksi}>
                            //                         <option value="">Pilih Satuan</option>
                            //                         {
                            //                             state.data_sat_produksi?.map((d, i) => (
                            //                                 <option key={i} value={d.param_id}>{d.title}</option>
                            //                             ))
                            //                         }
                            //                     </select>
                            //                 </div>
                            //                 <div className="col-md-4" >
                            //                     <input className="form-control form-control-sm" placeholder="Keterangan" disabled={true} value={state.data_view_value_creations?.[0]?.ket_produksi}></input>
                            //                 </div>
                            //             </div>
                            //         </>
                            //     )
                            // }, 
                            {
                                type: "custom",
                                label: "Pendapatan (Revenus)",
                                withLabel: false,
                                component: (
                                    <div className="border bg-light-secondary rounded p-3 mb-5">
                                        <div className="row">
                                            <div className="col-md-4">
                                                Pendapatan (Revenus)
                                            </div>
                                            <div className="col-md-8">
                                                <span className="d-block rounded  p-2 border">{state.formData.pendapatan} {state.formData.sat_pendapatan_name} {state.formData.ket_pendapatan}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                Efisiensi
                                            </div>
                                            <div className="col-md-8">
                                                <span className="d-block rounded  p-2 border">{state.formData.efisiensi} {state.formData.sat_efisiensi_name} {state.formData.ket_efisiensi}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span>
                                                    Production
                                                    <i title="Penambahan Produksi Migas, Cadangan Migas, Penjualan, Kapasitas listrik" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                </span>
                                            </div>
                                            <div className="col-md-8">
                                                <span className="d-block rounded p-2 border">{state.formData.produksi} {state.formData.sat_produksi_name} {state.formData.ket_produksi}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Tangible value
                                </span>,
                                component: (
                                    <>
                                        <div className="form-group row">
                                            <div className="col-md-12" >
                                                <input className="form-control form-control-sm"
                                                    type="number"
                                                    min={0}
                                                    value={state.formData.tangible_angka ||0}
                                                    onChange={(e) => action.setForm('tangible_angka', e.target.value)}
                                                    disabled={true} placeholder="Angka dalam IDR/Tahun"></input>
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Justifikasi Perhitungan Tangible Value
                                </span>,
                                component: (
                                    <div className="form-group row">
                                        <div className="col-md-12" >
                                            <textarea className="form-control form-control-sm"
                                                rows={2}
                                                value={state.formData.tangible_keterangan}
                                                onChange={(e) => action.setForm('tangible_keterangan', e.target.value)}
                                                disabled={true} placeholder="Keterangan"></textarea>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                type: "text",
                                label: "Intangible Value",
                                value: state.data_view_value_creations?.[0]?.intangible,
                                disabled: true,
                                placeholder: "Intangible Value"
                            }
                        ]
                    },
                ] : []),
                ...(state.data_view_header?.project_type?.toString() === '1' ? [
                    {
                        rows: 2,
                        header: true,
                        name: "Tingkat Kesiapan Riset",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <RadioGrp
                                            disable={true}
                                            value={state.data_view_detail?.stage}
                                            data={[
                                                { label: 'TRL I', value: 'I', disable: true },
                                                { label: 'TRL II', value: 'II', disable: true },
                                                { label: 'TRL III', value: 'III', disable: true },
                                                { label: 'TRL IV', value: 'IV', disable: true },
                                                { label: 'TRL V', value: 'V', disable: true },
                                                { label: 'TRL VI', value: 'VI', disable: true },
                                                { label: 'TRL VII', value: 'VII', disable: true },
                                                { label: 'TRL VIII', value: 'VIII', disable: true },
                                                { label: 'TRL IX', value: 'IX', disable: true }
                                            ]}
                                        />
                                    </>
                                )
                            }
                        ]
                    }
                ] : []),
                ...((state.data_view_header?.project_type?.toString() === '2' || state.data_view_header?.project_type?.toString() === '4') ? [
                    {
                        rows: 2,
                        header: true,
                        name: <>Tingkat Kesiapan Riset <span className="text-danger">(*)</span> <button className="btn mb-1 btn-sm btn-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('viewTableTrl')}>Lihat Table TRL</button></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="d-inline-flex">
                                        <RadioGrp
                                            disable={true}
                                            value={state.data_view_detail?.stage}
                                            data={[
                                                { label: 'TRL I', value: 'I', disabled: true },
                                                { label: 'TRL II', value: 'II', disabled: true },
                                                { label: 'TRL III', value: 'III', disabled: true },
                                                { label: 'TRL IV', value: 'IV', disabled: true },
                                                { label: 'TRL V', value: 'V', disabled: true },
                                                { label: 'TRL VI', value: 'VI', disabled: true },
                                                { label: 'TRL VII', value: 'VII' },
                                                { label: 'TRL VIII', value: 'VIII' },
                                                { label: 'TRL IX', value: 'IX' }
                                            ]}
                                        />
                                    </div>
                                )
                            }
                        ]
                    }
                ] : [])
            ]}
        />

    )
});
export default TabDetailMon;