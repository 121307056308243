import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { GridDx, HeaderList, Modal } from '../../components';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Button, Column } from 'devextreme-react/data-grid';
import { Dropdown } from 'react-bootstrap';
import DropDownButton from "devextreme-react/drop-down-button";
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import ModalKerjasamaDetailEdit from './modalKerjasamaDetailEdit';
import SVG from "react-inlinesvg";
import ModalMonitoring from './modalMonitoring';

const column = [
    // { fieldName: "judul", caption: "Judul", minWidth: 300 },
    // { fieldName: "kerjasama_partners", caption: "Partner", width: 200 },
    // { fieldName: "fungsi_manager", caption: "Fungsi Manager", width: 100 },
    { fieldName: "created_at", caption: "Date Created", allowFiltering: false },
    { fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", allowFiltering: false },
    { fieldName: "status_dokumen_name", caption: "Status", allowFiltering: false },
    { fieldName: "berlaku_start", caption: "Mulai Berlaku", allowFiltering: false },
    { fieldName: "berlaku_end", caption: "Sampai Dengan", allowFiltering: false },
    // { fieldName: "rkap", caption: "Related RKAP", width: 300, allowFiltering: false },
    // { fieldName: "file_name", caption: "Dokumen", width: 300, allowFiltering: false },
]

const columnMonitoring = [
    { fieldName: "created_at_form", caption: "Date Created", allowFiltering: false },
    { fieldName: "status_monitoring", caption: "Status Kerjasama", allowFiltering: false },
    { fieldName: "kendala", caption: "Kendala Kerjasama", allowFiltering: false },
    { fieldName: "dukungan", caption: "Dukungan Kerjasama", allowFiltering: false },
    { fieldName: "status_kerjasama", caption: "Status Dokumen", allowFiltering: false },
]

function KerjasamaDetailEdit(props) {
    const param = useParams();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [id, setId] = useState();
    const [modeEdit, setModeEdit] = useState('Add');
    const [dataEdit, setDataEdit] = useState({});

    useEffect(() => {
        if (param.id === null) {
            history.push('/error/error-v1')
        } else {
            setId(param?.id)
            getData();
        }
    }, [])

    // function cstmCellRender(e) {
    //     if (e.column.name === "kerjasama_rkaps") {
    //         return (
    //             <>
    //                 {
    //                     [...(e.value || [])].map(d => (
    //                         <span className="d-block text-wrap p-2"><i className="fa fa-circle icon-xs mx-1 text-dark"></i>{d.show_research}</span>
    //                     ))
    //                 }
    //             </>
    //         )
    //     } else {
    //         return (
    //             <span className="text-wrap">{e.value}</span>
    //         )
    //     }
    // }
    function getData() {
        action.getData(`/api/ext-partnership/view/${param?.id}`, {}, null, null, true);
    }
    function customRender(e) {
        if (e.column.name === "rkap") {
            return (
                // <span className="text-wrap">{e.value}</span>
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map(d => (
                            <li className="text-wrap p-2">{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'file_name') {
            const fname = (e.data?.file_name?.split(';') || []);
            const flocat = (e.data?.file_location?.split(';') || []);
            const file = (e.data?.file_id?.split(';') || []).map((d, i) => ({
                file_id: d,
                file_name: fname[i],
                file_location: flocat[i]
            }))
            return (
                <div className="d-block text-center">
                    {/* <span className="text-wrap">{e.value}</span> */}
                    <DropDownButton
                        text=""
                        className="btn btn-sm btn-outline-primary rounded custom-dropdown-download p-0"
                        stylingMode="text"
                        icon="fa fa-download icon-sm m-0 p-0"
                        showArrowIcon={false}
                        dropDownOptions={{ width: 250 }}
                        displayExpr="file_name"
                        keyExpr="id"
                        noDataText="No File"
                        itemRender={(e) => {
                            return (
                                <>
                                    {
                                        e?.file_location && (
                                            <a
                                                href={`${e?.file_location}`}
                                                target="_blank" download
                                                rel="noopener noreferrer"
                                            >
                                                <span>{e?.file_name}</span>
                                            </a>
                                        )
                                    }
                                </>
                            )
                        }}
                        items={[...(file || [])]}
                    />
                    {/* <Dropdown
                        className="mb-3"
                    >
                        <Dropdown.Toggle className="btn btn-sm btn-outline-primary d-flex justify-content-between custom-dropdown-icon" style={{ width: '100%' }} id="dropdown-basic">
                            <span style={{ float: 'left' }}><i className="fa fa-download icon-sm"></i> Download</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                [...(e.value || [])].map((d, i) => (
                                    <a className="dropdown-item" target="_blank" href={d.file_location} download>
                                        {d.file_name}
                                    </a>
                                    // <span className="dropdown-item">Final Report&nbsp;{d.year}&nbsp;({i + 1})</span>
                                    // <Link className="dropdown-item">Report {i + 1}</Link>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function editBtn(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => { setModeEdit('Edit'); setDataEdit(data?.data); action.toggleModal('modalDetailEdit') }}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtn(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger" onClick={() => deleteClick(data?.data?.id)}>
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function downloadbtn(e) {
        const fname = (e.data?.file_name?.split(';') || []);
        const flocat = (e.data?.file_location?.split(';') || []);
        const file = (e.data?.file_id?.split(';') || []).map((d, i) => ({
            file_id: d,
            file_name: fname[i],
            file_location: flocat[i]
        }))
        return (
            <DropDownButton
                text=""
                className="btn btn-sm btn-outline-primary rounded custom-dropdown-download p-0"
                stylingMode="text"
                icon="fa fa-download icon-sm m-0 p-0"
                showArrowIcon={false}
                dropDownOptions={{ width: 250 }}
                displayExpr="file_name"
                keyExpr="id"
                noDataText="No File"
                itemRender={(e) => {
                    return (
                        <>
                            {
                                e?.file_location && (
                                    <a
                                        href={`${e?.file_location}`}
                                        target="_blank" download
                                        rel="noopener noreferrer"
                                    >
                                        <span>{e?.file_name}</span>
                                    </a>
                                )
                            }
                        </>
                    )
                }}
                items={[...(file || [])]}
            />
        )
    }
    function headerCellRender(e) {
        return <p style={{ fontWeight: 'bolder', fontSize: '9pt', fontFamily: 'Poppins' }} className="text-wrap">{e.column.caption}</p>;
    }
    function saveData(data) {
        // console.log(data);
        if (modeEdit === 'Add') {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Dokumen?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/ext-partnership/store-dokumen",
                            data: data,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal('modalDetailEdit');
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
        if (modeEdit === 'Edit') {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Dokumen?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/ext-partnership/update-dokumen",
                            data: data,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal('modalDetailEdit');
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function deleteClick(id_dokumen) {
        if (id_dokumen) {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-danger mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                    '<strong>Delete Document?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/ext-partnership/delete-dokumen?id=${id_dokumen}`,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function addClick() {
        setModeEdit('Add');
        setDataEdit({});
        action.toggleModal('modalDetailEdit')
    }

    function saveDataMonitoring(data) {
        if (modeEdit === 'Add') {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Monitoring?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/ext-partnership/store-monitoring",
                            data: data,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal('modalMonitoring');
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
        if (modeEdit === 'Edit') {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Monitoring?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/ext-partnership/update-monitoring",
                            data: data,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal('modalMonitoring');
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function addClickMonitoring() {
        if([...(state.header_kerjasama?.kerjasama_rkaps || [])].length > 0){
            setModeEdit('Add');
            setDataEdit({});
            action.toggleModal('modalMonitoring')
        }else{
            return Swal.fire("Warning", "RKAP harus sudah harus terisi", "warning")
        }
    }
    function customRenderMonitoring(e) {
        if (e.column.name === "created_at") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "kendala") {
            return (
                <ul>
                    {
                        [...((e.value || '').split(";") || [])].filter(d => d).map((d, i) => (
                            <li key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "dukungan") {
            return (
                <ul>
                    {
                        [...((e.value || '').split(";") || [])].filter(d => d).map((d, i) => (
                            <li key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "status_kerjasama") {
            return (
                <div>
                    {e.data?.jenis_dokumen_name} {`(${e.data?.status_dokumen_name})`}
                </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function editBtnMinitoring(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => { setModeEdit('Edit'); setDataEdit(data?.data); action.toggleModal('modalMonitoring') }}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtnMonitoring(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deleteMonitoringClick(data?.data?.id)}
            >

                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteMonitoringClick(id_mon) {
        if (id_mon) {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-danger mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                    '<strong>Delete Monitoring?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/ext-partnership/delete-monitoring?id=${id_mon}`,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    return (
        <div style={{ marginBottom: '50px' }}>
            <Modal
                name="modalDetailEdit"
                baseColor={true}
            >
                <ModalKerjasamaDetailEdit mode={modeEdit} data={dataEdit} id_kerjasama={id} onSave={saveData} />
            </Modal>
            <Modal
                name="modalMonitoring"
                baseColor={true}
                size="90%"
            >
                <ModalMonitoring mode={modeEdit} data={dataEdit} id_kerjasama={id} onSave={saveDataMonitoring} />
            </Modal>
            <HeaderList
                title="Partnership Management System"
                someText="Partnership\Detail"
            />
            <Card
                className="p-0 customShadow mb-3"
            >
                <Card.Body className="p-5">
                    <div className="row px-3 mb-3">
                        <div className='col-md-1 mb-3'>
                            <div style={{ width: '78px', height: '78px' }} className="bg-light-primary ">
                                <div className="p-2 rounded bg-white svg-icon svg-icon-5x svg-icon-primary bg-light-primary">
                                    <SVG src="/media/svg/icons/Design/Difference.svg" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 mb-3'>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Judul Kerjasama</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.judul}</span>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Usulan Dari</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.usulan_dari === 1 ? "Top Down" : state.header_kerjasama?.usulan_dari === 2 ? "Bottom Up" : "" }</span>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>RKAP Berkaitan</b></div>
                                <div className="col-md">
                                    <ul>
                                        {
                                            [...(state.header_kerjasama?.kerjasama_rkaps || [])].map((d, i) => (
                                                <li key={i} style={{ fontSize: '9pt' }}>{d?.title}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Stream Business</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.stream_business_name}</span>
                                    {
                                        state.header_kerjasama?.stream_business === 7 && (
                                            <span style={{ fontSize: '9pt' }} className='d-block rounded px-3 py-2 bg-disable mt-2'>{state.header_kerjasama?.stream_business_note}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Project Cluster</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.project_cluster_name}</span>
                                    {
                                        state.header_kerjasama?.project_cluster === 5 && (
                                            <span style={{ fontSize: '9pt' }} className='d-block rounded px-3 py-2 bg-disable mt-2'>{state.header_kerjasama?.project_cluster_note}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Lokasi Proyek</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.lokasi_proyek}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Pertamina Expected Value</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.pertam_expected_value}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Pertamina Value Offer</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.pertam_value_offer}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Partner Expected Value</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.partner_expected_value}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Partner Value Offer</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.partner_value_offer}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Aktivitas Kerjasama</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.aktifitas_kerjasama}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Fungsi</b></div>
                                <div className="col-md">
                                    {
                                        [...(state.header_kerjasama?.kerjasama_fungsies || [])].map((d, i) => (
                                            <span className='badge badge-secondary mr-1' key={i}>{d?.chief_manager_name}</span>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Tahapan</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state?.tahapan_jenis_dokumen} {`(${state.tahapan_status_dokumen})`}</span>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Dokumen Pendukung</b></div>
                                <div className="col-md">
                                    <a href={state?.header_kerjasama?.file_location} download target='_blank'>{state?.header_kerjasama?.file_name}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3 mb-3">
                        <div className='col-md-6'>
                            <div className="d-block mb-2" style={{ fontSize: '9pt' }}><b>Partner</b></div>
                            <div className="table-responsive">
                                <table className="table table-bordered" style={{ 'fontSize': '8pt' }}>
                                    <thead>
                                        <tr className='bg-blue'>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>#</th>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Nama Partner</th>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Strategic Advantage</th>
                                            {/* <th scope="col" style={{ 'fontSize': '8pt' }}>Company Size</th> */}
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Country</th>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Province</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            [...(state.header_kerjasama?.kerjasama_partners || [])].map((d, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th scope="row">{i + 1}</th>
                                                        <td>{d?.nama_partner}</td>
                                                        <td className='text-right'>{d?.score_sa}</td>
                                                        <td className='text-right'>{d?.country}</td>
                                                        <td>{d?.province}</td>
                                                        {/* <td>{(p[4] || '').split('=')[1] || ''}</td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-block mb-2" style={{ fontSize: '9pt' }}><b>Partnership Scoring</b></div>
                            <div className="table-responsive">
                                <table className="table table-bordered" style={{ 'fontSize': '8pt' }}>
                                    <thead>
                                        <tr className='bg-blue'>
                                            <th colSpan={2} scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Scoring</th>
                                            <th scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='align-middle text-center font-weight-bold' rowSpan={2}>Partner Scoring</td>
                                            <td>Strategic Advantage (Average)</td>
                                            <td className='text-right'>{state?.total_sa}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Company Size</td>
                                            <td className='text-right'>{state?.total_cs}</td>
                                        </tr> */}
                                        <tr>
                                            <td className='bg-light-metal'>Total Partner Scoring</td>
                                            <td className='bg-light-metal text-right'>{state?.total_sa}</td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle text-center font-weight-bold' rowSpan={3}>Project Scoring</td>
                                            <td>Project Cluster</td>
                                            <td className='text-right'>{state?.total_pc}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Partnership in the Topic Area</td>
                                            <td className='text-right'>{state?.total_tpta}</td>
                                        </tr>
                                        <tr>
                                            <td className='bg-light-metal'>Total Project Scoring</td>
                                            <td className='bg-light-metal text-right'>{state?.total_pc_tpta}</td>
                                        </tr>
                                        <tr className='bg-light-blue'>
                                            <td colSpan={2} className='text-center'>Partnership Score</td>
                                            <td className='text-right'>{state?.total_sa_cs_pc_tpta}</td>
                                        </tr>
                                        <tr className='bg-light-blue'>
                                            <td colSpan={3} className='text-center'>{`Recommendation : "${state?.translation_scoring}"`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='text-right'>
                                <button className='btn btn-sm btn-primary'
                                    onClick={() => history.push(`/admin/update/partnership-edit/${state.header_kerjasama?.id}`)}
                                ><i className='fa fa-pencil-alt icon-sm'></i> Edit</button>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="p-0 customShadow"
            >
                <Card.Body className="p-5">
                    <div className="row mx-1 mt-5">
                        <div className="col-md-12">
                            <div className='d-flex w-100 border-bottom justify-content-between pb-1 mb-2'>
                                <span className='Dokumen Kerjasama' style={{ fontSize: '12pt', fontWeight: 700 }}>Dokumen Kerjasama</span>
                                <button className="btn btn-sm btn-primary" onClick={() => addClick()}>+ Dokumen</button>
                            </div>
                            <GridDx
                                className='custom-style'
                                searchForm={false}
                                data={state.tabel_dokumen}
                                cellRender={customRender}
                                columns={column}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                // toolbar={
                                //     <button className="btn btn-sm btn-primary" onClick={() => addClick()}>+ Dokumen</button>
                                // }

                                currentPage
                                show
                            >
                                <Column caption="Action" type="buttons" headerCellRender={headerCellRender} >
                                    <Button name="customEdit" visible={true} render={editBtn} />
                                    <Button name="delete" visible={true} render={deleteBtn} />
                                    <Button name="customEdit" visible={true} render={downloadbtn} />
                                </Column>
                            </GridDx>
                        </div>
                    </div>
                    <div className="row mx-1 mt-5">
                        <div className="col-md-12">
                            <div className='d-flex w-100 border-bottom justify-content-between pb-1 mb-2'>
                                <span className='Dokumen Kerjasama' style={{ fontSize: '12pt', fontWeight: 700 }}>Monitoring Kerjasama</span>
                                <button className="btn btn-sm btn-primary" onClick={() => addClickMonitoring()}>+ Monitoring</button>
                            </div>
                            <GridDx
                                className='custom-style'
                                searchForm={false}
                                data={state.tabel_monitoring}
                                cellRender={customRenderMonitoring}
                                columns={columnMonitoring}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                                <Column caption="Action" type="buttons" headerCellRender={headerCellRender} >
                                    <Button name="customEdit" visible={true} render={editBtnMinitoring} />
                                    <Button name="delete" visible={true} render={deleteBtnMonitoring} />
                                    {/* <Button name="customEdit" visible={true} render={downloadbtn} /> */}
                                </Column>
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                history.length > 2 &&
                <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default KerjasamaDetailEdit;