export const about_rti = "RTI merupakan fungsi yang didirikan pada tahun 2017 yang bertujuan untuk mengintegrasikan seluruh fungsi – fungsi riset di Pertamina dalam mengoptimalkan inovasi bagi mendukung operasional dan keberlanjutan bisnis perusahaan di masa depan.";
export const desc_spiirit_footer = "Sistem Pengelolaan Inovasi & Informasi Riset dan Teknologi PERTAMINA (SPIIRIT) merupakan aplikasi pengelolaan inovasi Pertamina yang mencakup pengelolaan proyek inovasi, operasional dan sumber daya manusia."
export const alamat = {
    laboratorium: "Jl. Raya Bekasi No. 20, RT 1/RW 2, Rawa Terate, Cakung Jakarta Timur 13920",
    kantor: 'Sopo Del Tower, Lt. 50 & 51 Jl. Mega Kuningan Barat III no. 3 Kuningan Kec. Setiabudi Jakarta Selatan 12950',
    contact: 'info.rti@pertamina.com'
}
export const desc_product = "Portofolio produk inovasi RTI yang telah dikembangkan berdasarkan klasifikasinya";
export const desc_achievement = "Penghargaan Yang Telah Diraih oleh RTI";
export const desc_event = "Kumpulan penyelenggaraan event di internal dan eksternal RTI";

