import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useHighlightDash from './useHighlightDashboard';
import Swal from 'sweetalert2';

function ModalNotes({ data = {}, mode = 'Add' }) {
    const { hdAction, hdState } = useHighlightDash();
    const [notes_status, setNotes_status] = useState('');
    const [id_edit, setId_edit] = useState('');
    const [activity_id, setActivity_id] = useState('');
    const [tindak_lanjut, setTindak_lanjut] = useState('');
    const [deadline, setDeadline] = useState('');

    useEffect(() => {
        if (hdState.globalState.modal?.modalNotes === true) {
            resetEditor();
            setEditor();
        }
    }, [hdState.globalState.modal?.modalNotes])

    function resetEditor() {
        setNotes_status('');
        setId_edit('');
        setActivity_id('');
        setTindak_lanjut('');
        setDeadline('');
    }
    function setEditor() {
        setId_edit(data?.id || '');
        setActivity_id(data?.activity_id || '');
        setNotes_status(data?.notes_status || '');
        setTindak_lanjut(data?.tindak_lanjut || '');
        setDeadline(data?.deadline ? moment(data?.deadline).format('YYYY-MM-DD') : '');
    }
    function simpanClick() {
        const data = {
            "id": id_edit,
            "activity_id": activity_id,
            "notes_status": notes_status || '',
            "tindak_lanjut": tindak_lanjut || '',
            "deadline": deadline || '',
            "created_at": "",
            "updated_at": ""
        }
        // console.log(data);
        if (mode === 'Add') {
            addNotes(data);
        }
        if (mode === 'Edit') {
            updateNotes(data);
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        if (data?.notes_status === '') {
            isValid = false;
            message = [...message, "Status"]
        }
        if (data?.tindak_lanjut === '') {
            isValid = false;
            message = [...message, "Tindak Lanjut"]
        }
        if (data?.deadline === '') {
            isValid = false;
            message = [...message, "Deadline"]
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }
    function addNotes(data) {
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Tambah Note?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                hdAction.postAddNotes(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function updateNotes(data) {
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Update Notes?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                hdAction.postUpdateNotes(data);
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div>
            <div className="row mb-3">
                <div className="col-3">
                    <span className="text-wrap">Status</span>
                </div>
                <div className="col-9">
                    <textarea rows={4} value={notes_status} onChange={(e) => setNotes_status(e.target.value)} className="form-control form-control-sm" placeholder='Status'></textarea>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-3">
                    <span className="text-wrap">Tindak Lanjut</span>
                </div>
                <div className="col-9">
                    <textarea rows={4} value={tindak_lanjut} onChange={(e) => setTindak_lanjut(e.target.value)} className="form-control form-control-sm" placeholder='Tindak Lanjut'></textarea>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-3">
                    <span className="text-wrap">Deadline</span>
                </div>
                <div className="col-5">
                    <input value={deadline} onChange={(e) => setDeadline(moment(e.target.value).format('YYYY-MM-DD'))} type='date' className="form-control form-control-sm" placeholder='Date'></input>
                </div>
            </div>
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={() => hdAction.globalAction.toggleModal('modalNotes')}
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-primary mx-1"
                    onClick={() => simpanClick()}
                >
                    <i className="fa fa-save icon-nm"></i>
                    Simpan
                </button>
            </div>
        </div>
    );
}

export default ModalNotes;