import React from 'react';
import {
    CircularGauge,
    Scale,
    Label,
    RangeContainer,
    Range,
    Title,
    Font,
    Geometry,
    Tooltip,
    ValueIndicator,
    SubvalueIndicator,
    Tick,
    Size
} from 'devextreme-react/circular-gauge';
import { SkeletonLoading } from '..';

export default ({
    tooltipCustom = ({ valueText, labelText, unit }) => ({ text: `${labelText} : ${valueText} ${unit}` }),
    onHover = (e) => null,
    id = "gauge",
    startValue = 0,
    height = 200,
    endValue = 150,
    withTitle = true,
    fontSizeTitle = 20,
    tickInterval = 25,
    title = "This is your title",
    subvalues = [

    ],
    startAngle = 225,
    endAngle = -45,
    rangeOptions = [

    ],
    indicatorType = "", // rectangleNeedle, twoColorNeedle, triangleNeedle, rangebar, textCloud, triangleMarker
    indicatorOffset = 25,
    indicatorColor = "gray", // color indicator
    subIndicatorType = "", // rectangleNeedle, twoColorNeedle, triangleNeedle, rangebar, textCloud, triangleMarker
    subIndicatorOffset = -25,
    subIndicatorColor = "gray", //color sub indikator
    tickColor = null,
    thickness = 15,
    color = "gray",
    data = {

    },
    loading = false,
    customizeLabel = (valueText) => `${valueText}`
}) => {


    function customizeTooltip(p) {
        onHover(p)
        // return p.type
        // if(p.type === "value-indicator") return `${data.label} : ${p.valueText} ${data.unit}`;
        // else if(p.type === "subvalue-indicator") return `${subvalues[p.index]?.label} : ${p.valueText} ${subvalues[p.index]?.unit}`
        if (p.type === "value-indicator") return tooltipCustom({ valueText: p.valueText || 0, labelText: data.label || "", unit: data.unit || "" });
        else if (p.type === "subvalue-indicator") return tooltipCustom({ valueText: p.valueText || 0, labelText: subvalues[p.index]?.label || "", unit: subvalues[p.index]?.unit || "" });

    }

    function customizeLabelText({ valueText }) {
        // console.log(p)
        return customizeLabel(valueText);
    }
    return (
        <>
            {

                loading ? (
                    <SkeletonLoading heightChart={height} type="doughnat" />
                ) : (

                    <CircularGauge
                        id={id}
                        value={data?.value}
                        subvalues={subvalues.map(v => v.value)}
                        onIncidentOccurred={() => null}
                    // width={9}
                    // size={170}


                    >
                        <Size height={height}>

                        </Size>
                        <Geometry
                            startAngle={startAngle}
                            endAngle={endAngle}
                        />
                        <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
                            <Font size={12} color="#000000" />
                        </Tooltip>

                        <Scale startValue={startValue} endValue={endValue} tickInterval={tickInterval}>
                            {tickColor !== null && <Tick color={tickColor} />}
                            <Label indentFromTick={thickness + 5} customizeText={customizeLabelText} useRangeColors={true} />
                        </Scale>
                        <ValueIndicator offset={indicatorOffset} type={indicatorType} color={indicatorColor} />
                        <SubvalueIndicator offset={subIndicatorOffset} type={subIndicatorType} color={subIndicatorColor} />

                        <RangeContainer backgroundColor={color} width={thickness}>
                            {
                                rangeOptions.map(({ startValue, endValue, color }, k) => <Range key={k} startValue={startValue} endValue={endValue} color={color} />)
                            }

                        </RangeContainer>


                        {
                            withTitle && <Title text={title}>
                                <Font size={fontSizeTitle} />
                            </Title>
                        }
                        {/* <Export enabled={true} /> */}
                    </CircularGauge>
                )
            }
        </>
    );
}