import React, { memo, useEffect, useRef, useState } from 'react'
import { useGlobalState } from '../../contexts/useGlobalState';
import { HeaderList, GridDx } from '../../components';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import { workflow_state_name_light_style, stage, workflow_state_id } from '../../components/utility/utility';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const columns = [
    { fieldName: "updated_at_form", caption: "Date Created", width: 100 },
    { fieldName: "title", caption: "Title", width: 500, link: true, url: "#" },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    // { fieldName: "area_name", caption: "Area", width: 200 },
    // { fieldName: "cat_name", caption: "Category", width: 200 },
    { fieldName: "function_name", caption: "Fungsi", width: 100 },
    { fieldName: "section_name", caption: "Chief Manager", width: 100 },
    { fieldName: "project_type_short_name", caption: "Project Type", width: 100 },
    { fieldName: "owner_name", caption: "Leader", width: 200 },
    { fieldName: "initiator_name", caption: "Initiator", width: 200 },
]
export default memo(function () {
    const tableRef = useRef();
    const history = useHistory();
    const [year, setYear] = useState();
    const [state, dispatch, action] = useGlobalState();
    const [year_select, setYear_select] = useState();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData("/api/project-admin/table-approval-project", {
            tahun: year_select ? year_select : '0'
        }, null, "loadingGrid").then((resp) => {
            if (resp.code === "00") {
                getYear(resp.data.data_year_portfolio);
                setData_page(resp.data_page);
            }
        })
    }, [year_select])
    function getYear(value) {
        if (value) {
            const newDs = [{ value: '0', name: 'Semua Tahun' }, ...[...(value || [])].map((d, i) => {
                return {
                    value: d.idYear,
                    name: d.idYear
                }
            })]

            setYear(newDs)
        }
    }
    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <>
                    {
                        workflow_state_id(e.data.workflow_state_id) === 1 && (
                            <Link target={"_blank"}
                                to={"/admin/ideation/approval/view/" + e.data.id}
                            >
                                <span className="text-wrap">{e.value}</span>
                            </Link>
                        )
                    }
                    {
                        workflow_state_id(e.data.workflow_state_id) === 2 && (
                            <Link target={"_blank"}
                                to={"/admin/portfolio-research/approval/view/" + e.data.id}
                            >
                                <span className="text-wrap">{e.value}</span>
                            </Link>
                        )
                    }
                    {
                        workflow_state_id(e.data.workflow_state_id) === 3 && (
                            <Link target={"_blank"}
                                to={"/admin/OngoingResearch/approval/view/" + e.data.id}
                            >
                                <span className="text-wrap">{e.value}</span>
                            </Link>
                        )
                    }
                </>
            )
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "value_creation") {
            return (
                <span className="text-wrap">
                    {e.data.vc_pendapatan === "1" && "Pendapatan "}
                    {e.data.vc_efisiensi === "1" && "Efisiensi "}
                    {e.data.vc_produksi === "1" && "Produksi "}
                    {e.data.vc_intangible === "1" && "Intangible "}
                </span>
            )
        } else if (e.column.name === "stage") {
            return (stage(e))
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <div className="pb-5">
                <HeaderList
                    title={data_page?.page_title}
                    someText={data_page?.page_breadcrumbs}
                    optionList={[{
                        withLabel: true,
                        label: "Rekapitulasi usulan riset pada tahun ",
                        option: year,
                        value: year_select,
                        onChange: function (e) { setYear_select(e) }
                    }]}
                ></HeaderList>
            </div>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row" style={{ fontSize: '8pt' }}>
                        <div className="col-md-2">
                            <div className="d-flex">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Project</span>
                                    <span className="d-block"><strong>{state.data_jumlah_project?.total}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1" style={{ width: 15, height: 15, backgroundColor: '#006CB8' }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Ideation Review</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ideation_review}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Portfolio Review</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.portfolio_review}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Approve to Implementation</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.approved_to_implementation}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Ongoing Revision Review</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ongoing_revision_review}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Continue Request</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ongoing_continue_request}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Aborted/Finished Request</span>
                                            <span className="d-block"><strong>{state.data_jumlah_project?.ongoing_aborted_finished_request}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5 px-5">
                        <div className="col-md-12">
                            <GridDx
                                searchForm={true}
                                loading={state.loadingGrid}
                                ref={tableRef}
                                data={state.data_table}
                                cellRender={(e) => cstmCellRender(e)}
                                columns={columns}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >

                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})