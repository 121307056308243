import React, { useState, useEffect } from 'react';
import { GridDxTimelineMon, GanttChartP, SwitchBtn, SkeletonLoading, MilestonesBreadcrumbs } from '../../components/index'
import { columnSummary, rowSummary } from './dummy-data';
import Card from 'react-bootstrap/Card'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useGlobalState } from '../../contexts/useGlobalState';
import moment from 'moment';
import { toInteger, toUpper } from 'lodash';

const colomn = [
    { "fieldName": "task", "caption": "Task", minWidth: 300, witdh: "auto" },
    { "fieldName": "sometext", "caption": "", minWidth: 100, width: 100, allowSorting: false, allowFiltering: false },
    { "fieldName": "actual_jan", "caption": "JAN", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_feb", "caption": "FEB", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_mar", "caption": "MAR", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_apr", "caption": "APR", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_may", "caption": "MAY", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_jun", "caption": "JUN", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_jul", "caption": "JUL", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_aug", "caption": "AUG", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_sep", "caption": "SEP", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_oct", "caption": "OCT", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_nov", "caption": "NOV", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_dec", "caption": "DEC", "visible": true, "dataType": "number", width: 50 }
]

const TabTimelineMon = ({ param, view_admin = false }) => {
    const [sw, setSw] = useState(false);
    const [data, setData] = useState();
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-timeline/" + param?.id, {}, null, 'loadingTimeline', true).then((resp) => {
                if (resp.code === "00") {
                    setData(resp.data.data_view_timeline)
                    action.setForm('cut_off_timeline', resp.data.data_cut_off_day || 1);
                    action.setForm('mon', resp.data_page);
                }
            })
        }
    }, [])

    function btnProjectOnClick(e) {
        let max = data.reduce((mx, dt) => {
            return dt.year >= mx.year ? dt : mx;
        }, {
            year: Number.MIN_SAFE_INTEGER
        });
        setData(data.concat({ year: (max.year === Number.MIN_SAFE_INTEGER ? parseInt(new Date().getFullYear()) : max.year) + 1, data: [] }))
    }
    function getData() {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-timeline/" + param?.id, {}, null, 'loadingTimeline', true).then((resp) => {
                if (resp.code === "00") {
                    setData(resp.data.data_view_timeline);
                    action.setForm('mon', resp.data_page);
                }
            })
        }
    }
    function deleteYear(e) {
        const d = [...data];
        d.splice(e, 1)
        data(d);
    }
    function updateTimelineRealisasi(data) {
        var result = false;
        [...(data || [])].forEach((x, i) => {
            if (checkBiggerActual(x)) {
                result = true;
            }
        })
        if (result === true) {
            return Swal.fire({
                title: 'Warning',
                text: 'Realisasi melebihi Rencana',
                icon: 'warning',
            })
        } else {
            const month = toInteger(state.data_year_month.split('/')?.[0]);
            // const year = toInteger(data_year_month.split('/')?.[1]);
            // const month = parseInt(moment(new Date()).format('M'));
            // const days = parseInt(moment(new Date()).format('D'));
            // const disableInput = {
            //     jan: month === 1 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 2 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     feb: month === 2 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 3 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     mar: month === 3 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 4 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     apr: month === 4 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 5 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     may: month === 5 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 6 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     jun: month === 6 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 7 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     jul: month === 7 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 8 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     aug: month === 8 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 9 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     sep: month === 9 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 10 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     oct: month === 10 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 11 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     nov: month === 11 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 12 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            //     dec: month === 12 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 1 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
            // }
            const disableInput = {
                jan: month === 1 ? false : true,
                feb: month === 2 ? false : true,
                mar: month === 3 ? false : true,
                apr: month === 4 ? false : true,
                may: month === 5 ? false : true,
                jun: month === 6 ? false : true,
                jul: month === 7 ? false : true,
                aug: month === 8 ? false : true,
                sep: month === 9 ? false : true,
                oct: month === 10 ? false : true,
                nov: month === 11 ? false : true,
                dec: month === 12 ? false : true,
            }
            const dataKirim = [...(data || [])].map(d => {
                return {
                    id: d.id,
                    actual_jan: disableInput.jan === false ? d.actual_jan : null,
                    actual_feb: disableInput.feb === false ? d.actual_feb : null,
                    actual_mar: disableInput.mar === false ? d.actual_mar : null,
                    actual_apr: disableInput.apr === false ? d.actual_apr : null,
                    actual_may: disableInput.may === false ? d.actual_may : null,
                    actual_jun: disableInput.jun === false ? d.actual_jun : null,
                    actual_jul: disableInput.jul === false ? d.actual_jul : null,
                    actual_aug: disableInput.aug === false ? d.actual_aug : null,
                    actual_sep: disableInput.sep === false ? d.actual_sep : null,
                    actual_oct: disableInput.oct === false ? d.actual_oct : null,
                    actual_nov: disableInput.nov === false ? d.actual_nov : null,
                    actual_dec: disableInput.dec === false ? d.actual_dec : null,
                }
            })
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-success mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Realisasi Timeline?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-ongoing-crud/update-timeline?research_id=" + state.data_view_header?.id,
                            data: dataKirim,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            getData()
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function checkBiggerActual(data) {
        var plan = ((isNaN(Number(data.plan_jan))) ? 0 : Number(data.plan_jan)) +
            ((isNaN(Number(data.plan_feb))) ? 0 : Number(data.plan_feb)) +
            ((isNaN(Number(data.plan_mar))) ? 0 : Number(data.plan_mar)) +
            ((isNaN(Number(data.plan_apr))) ? 0 : Number(data.plan_apr)) +
            ((isNaN(Number(data.plan_may))) ? 0 : Number(data.plan_may)) +
            ((isNaN(Number(data.plan_jun))) ? 0 : Number(data.plan_jun)) +
            ((isNaN(Number(data.plan_jul))) ? 0 : Number(data.plan_jul)) +
            ((isNaN(Number(data.plan_aug))) ? 0 : Number(data.plan_aug)) +
            ((isNaN(Number(data.plan_sep))) ? 0 : Number(data.plan_sep)) +
            ((isNaN(Number(data.plan_oct))) ? 0 : Number(data.plan_oct)) +
            ((isNaN(Number(data.plan_nov))) ? 0 : Number(data.plan_nov)) +
            ((isNaN(Number(data.plan_dec))) ? 0 : Number(data.plan_dec));
        var actual = ((isNaN(Number(data.actual_jan))) ? 0 : Number(data.actual_jan)) +
            ((isNaN(Number(data.actual_feb))) ? 0 : Number(data.actual_feb)) +
            ((isNaN(Number(data.actual_mar))) ? 0 : Number(data.actual_mar)) +
            ((isNaN(Number(data.actual_apr))) ? 0 : Number(data.actual_apr)) +
            ((isNaN(Number(data.actual_may))) ? 0 : Number(data.actual_may)) +
            ((isNaN(Number(data.actual_jun))) ? 0 : Number(data.actual_jun)) +
            ((isNaN(Number(data.actual_jul))) ? 0 : Number(data.actual_jul)) +
            ((isNaN(Number(data.actual_aug))) ? 0 : Number(data.actual_aug)) +
            ((isNaN(Number(data.actual_sep))) ? 0 : Number(data.actual_sep)) +
            ((isNaN(Number(data.actual_oct))) ? 0 : Number(data.actual_oct)) +
            ((isNaN(Number(data.actual_nov))) ? 0 : Number(data.actual_nov)) +
            ((isNaN(Number(data.actual_dec))) ? 0 : Number(data.actual_dec));
        return Number(actual.toFixed(2) || 0) > Number(plan.toFixed(2) || 0);
    }
    function allowEditing(e) {
        const yearMonth = (state.data_year_month || '')?.split('/');
        const max_year_month = `01/${new Date().getFullYear()}`;
        const curent_day = toInteger(moment().format('D'));
        const year = yearMonth[1] || '';
        if (state.filterData.mode === "edit" && year.toString() === e.year.toString() && state.data_view_header?.workflow_state_id === 45) {
            // if (state.data_view_header?.is_continue === 1 &&
            //     curent_day > toInteger(state.data_cut_off_date) &&
            //     state.data_year_month === max_year_month) {
            //     return false
            // } else {
            //     return true
            // }
            if (state.data_view_header?.is_continue === 2) {
                return true;
            } else {
                if (moment().format('M').toString() === '1' && toInteger(moment().format('D')) < toInteger(state?.data_cut_off_date)) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false
        }
    }
    function renderMilestonesItem(item) {
        return (
            <ol style={{ paddingLeft: '15px' }}>
                {
                    [...(item?.data || [])].filter(d => d?.type === "milestones" || d?.type === "submilestones").map((d, i) => (
                        <li className='text-wrap' key={i}>{d?.task} ({
                            ["plan_jan", "plan_feb", "plan_mar", "plan_apr", "plan_may", "plan_jun", "plan_jul", "plan_aug", "plan_sep", "plan_oct", "plan_nov", "plan_dec"].map(x => d[x] > 0 ? x.split('_')[1] : '').filter(x => x).join(",").toUpperCase()
                        })</li>
                    ))
                }
            </ol>
        )
    }
    return (
        <div className="row h-100 p-3">
            <div className="col-md-12">
                <span className='d-block text-center mb-5 px-2 py-1 bg-primary rounded text-white'>Pengisian realisasi bulan {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).format('MMM'))} dapat dilakukan s.d. tanggal {state.data_cut_off_date} {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).add(1, 'month').format('MMM'))} {state.data_year_month?.split('/')?.[1]}</span>
                <p><i className="fa fa-circle icon-sm"></i> Pengisian estimasi progres dapat diisi pada Sub Task, Milestone, Sub Milestone.
                    Sedangkan untuk Task hanya berupa keterangan.
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Jumlah [Sub Task + Milestone + Sub Milestone] minimal 8 baris
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Bobot isian adalah dalam bentuk persentase (%) dan total plan untuk setiap tahun
                    harus berjumlah 100%
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Pastikan timeline project diisi sampai dengan komersial / implementasi
                </p>
                <div className="row">
                    <div className="col-md-12 text-left text-md-right text-lg-right">
                        <label style={{ paddingRight: 10 }}>Ganttchart view</label>
                        <SwitchBtn value={sw} onChange={(e) => setSw(e)} />
                        <label style={{ paddingLeft: 10 }}>Weight view</label>
                    </div>
                </div>
                <hr style={{ margin: 0 }} />
                <MilestonesBreadcrumbs data={[...(data || [])]} titleField="year" renderItem={renderMilestonesItem} />
                {
                    state.loadingTimeline ? (
                        <SkeletonLoading type="table" rows={5} cols={colomn.length} />
                    ) : (
                        [...(data || [])].map((e, i) => (
                            <Card className="p-0 my-2" key={i}>
                                <Card.Body className="p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="d-flex py-2 justify-content-between border-bottom">
                                                        <span className="align-self-center">{e.year}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        sw === true ? (
                                                            <>
                                                                <GridDxTimelineMon
                                                                    dataSource={e}
                                                                    allowAdding={allowEditing(e)}
                                                                    allowDeleting={allowEditing(e)}
                                                                    allowEditing={allowEditing(e)}
                                                                    showTotalActual={true}
                                                                    columns={colomn}
                                                                    summaryC={true}
                                                                    columnSummary={columnSummary}
                                                                    summaryR={true}
                                                                    rowSummary={rowSummary}
                                                                    data_year_month={state.data_year_month}
                                                                />
                                                                <div className="row mt-3">
                                                                    <div className="col-md-12 text-right">
                                                                        {
                                                                            allowEditing(e) &&
                                                                            (
                                                                                <button className="btn btn-sm btn-success"
                                                                                    onClick={() => updateTimelineRealisasi(e.data)}
                                                                                >Update Realisasi Timeline</button>
                                                                            )

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <GanttChartP
                                                                dataSource={e.data}
                                                                column={colomn.filter(c => c.fieldName !== "sometext")}
                                                                showActualPlan={true}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))
                    )
                }
            </div>
        </div>
    )
}
export default TabTimelineMon;