import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FileUploadDx } from '../../../components';
import { TagBox } from 'devextreme-react';
import useBroadcastEmail from '../useBroadcastEmail';

function TabBroadcastEmail(props) {
    const { beAction, beState } = useBroadcastEmail();
    const refFile = useRef();
    const [personal, setPersonal] = useState({
        enable: false,
        value: []
    });
    const [fungsi, setFungsi] = useState({
        enable: false,
        value: []
    });
    const [judul, setJudul] = useState('');
    const [photo_filename, setphoto_filename] = useState('');

    const handleChangePersonal = (check, val) => {
        if (check) {
            setPersonal((e) => ({
                enable: val,
                value: !val ? [] : e.value
            }))
        } else {
            setPersonal((e) => ({
                ...e,
                value: val
            }))
        }
    }
    const handleChangeFungsi = (check, val) => {
        if (check) {
            setFungsi((e) => ({
                enable: val,
                value: !val ? [] : e.value
            }))
        } else {
            setFungsi((e) => ({
                ...e,
                value: val
            }))
        }
    }
    function onFileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setphoto_filename(resp.data?.data_filename);
        }
    }
    function resetForm() {
        setPersonal({ enable: false, value: [] });
        setFungsi({ enable: false, value: [] });
        setJudul('');
        setphoto_filename('');
        if (refFile.current) {
            refFile.current.instance.reset();
        }
    }
    useEffect(() => {
        beAction.getMasterBc();
    }, [])
    useEffect(() => {
        if (beState.clearBc) {
            resetForm();
        }
    }, [beState.clearBc])
    return (
        <div>
            <Card className='customShadow'>
                <Card.Body>
                    <div className='row mx-5'>
                        <div className='col-12 px-1 mb-3'><b>Tujuan Broadcast</b></div>
                        <div className='col-12 px-1 mb-3'>
                            <div className='row'>
                                <div className='col-12 col-md-2 col-lg-2'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="personal"
                                            checked={personal.enable}
                                            onChange={(e) => handleChangePersonal(true, e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="personal">Personal</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <TagBox
                                        disabled={!personal.enable}
                                        value={personal.value}
                                        onValueChange={(e) => handleChangePersonal(false, e)}
                                        dataSource={[...(beState.dataViewBc?.data_users || [])]} searchEnabled={true}
                                        valueExpr={"id"}
                                        displayExpr={"name"} />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 px-1 mb-3'>
                            <div className='row'>
                                <div className='col-12 col-md-2 col-lg-2'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="function"
                                            checked={fungsi.enable}
                                            onChange={(e) => handleChangeFungsi(true, e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="function">Group Function</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <TagBox
                                        disabled={!fungsi.enable}
                                        value={fungsi.value}
                                        onValueChange={(e) => handleChangeFungsi(false, e)}
                                        dataSource={[...(beState.dataViewBc?.data_functions || [])]} searchEnabled={true}
                                        valueExpr={"id"}
                                        displayExpr={"name"} />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 px-1 mb-3'><b>Broadcast Message</b></div>
                        <div className='col-12 px-1 mb-3'>
                            <div className='row'>
                                <div className='col-12 col-md-2 col-lg-2'>
                                    <div className="form-check mt-1">
                                        <label className="form-check-label">Judul</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control"
                                        value={judul} onChange={(e) => setJudul(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 px-1 mb-3'>
                            <div className='row'>
                                <div className='col-12 col-md-2 col-lg-2'>
                                    <div className="form-check mt-1">
                                        <label className="form-check-label">Upload Image</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <FileUploadDx
                                        ref={refFile}
                                        className="custom-dx-fileuploader-file-name mb-3"
                                        showFileList={true}
                                        multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb"
                                        accept="image/*"
                                        allowedFileExtensions={['.jpg', '.jpeg', '.gif', '.png']}
                                        onUploaded={onFileUploaded}
                                        maxFileSize={10000000}
                                        uploadMode="instantly"
                                    />
                                    <button className='btn btn-sm btn-success'
                                        onClick={() => beAction.onSendBc({
                                            "title": judul || '',
                                            "photo_filename": photo_filename || '',
                                            "cbUserPersonal": personal.enable,
                                            "cbGroupFunction": fungsi.enable,
                                            "listUserPersonal": personal.value || [],
                                            "listGroupFunction": fungsi.value || []
                                        })}

                                    >Kirim</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TabBroadcastEmail;