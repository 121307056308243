import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, SwitchBtn, ChartDx, HeaderList, ExportPdf } from '../../components';
import { month_source } from '../../components/utility/utility';
import useProjectChampionsReporting, { PchamProvider } from './useProjectChampionsReporting';
import SVG from "react-inlinesvg";
import Skeleton from 'react-loading-skeleton';
import { useRef } from 'react';

const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]

const columns = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "perform_work", caption: "Performance/Progress", width: 115 },
    { fieldName: "status_progress_1", caption: "1", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_2", caption: "2", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_3", caption: "3", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_4", caption: "4", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_5", caption: "5", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_6", caption: "6", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_7", caption: "7", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_8", caption: "8", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_9", caption: "9", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_10", caption: "10", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_11", caption: "11", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_12", caption: "12", width: 50, headerFilterSource: progresFilter },
]

const month = [{ name: 'JAN', value: '1' }, { name: 'FEB', value: '2' }, { name: 'MAR', value: '3' }, { name: 'APR', value: '4' },
{ name: 'MAY', value: '5' }, { name: 'JUN', value: '6' }, { name: 'JUL', value: '7' }, { name: 'AUG', value: '8' }, { name: 'SEP', value: '9' },
{ name: 'OCT', value: '10' }, { name: 'NOV', value: '11' }, { name: 'DEC', value: '12' }]

function Index(props) {
    const {
        pchamState,
        pchamAction
    } = useProjectChampionsReporting();
    const refEx = useRef();
    const Chart1 = useMemo(() => (
        <ChartDx
            id="type"
            height={70}
            yVisible={false}
            xVisible={false}
            yLabel={false}
            xLabel={true}
            dataSource={pchamState.dataDahsboard?.data_CountProjectType_Unggulan}
            onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
            colorLabel='#181C32'
            argumentField='short_name'
            withTitle={false}
            showLegend={false}
            toolsExport={false}
            color={["#ffaa66"]}
            yAxisArgs={[
                {
                    color: 'white',
                    valueField: "jumlahProject",
                }
            ]}
        >

        </ChartDx>
    ), [pchamState.dataDahsboard?.data_CountProjectType_Unggulan]);

    const ChartSCurve1 = useMemo(() => (
        <ChartDx
            dataSource={pchamAction.getScurve1Unggulan()}
            type="spline"
            withTitle={false}
            showLegend={true}
            toolsExport={false}
            LegendverticalAlignment="bottom"
            LegendhorizontalAlignment="center"
            LegenditemTextPosition="right"
            onRenderLabelChart={function (e) { }}
            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
            yAxisArgs={pchamState.yAxis_chart1Unggulan}
        >
        </ChartDx>
    ), [pchamState.dataDahsboard?.data_SCurve1_Unggulan, pchamState.yAxis_chart1Unggulan]);
    const ChartSCurve2 = useMemo(() => (
        <ChartDx
            dataSource={pchamAction.getScurve2Unggulan()}
            type="spline"
            withTitle={false}
            showLegend={true}
            toolsExport={false}
            LegendverticalAlignment="bottom"
            LegendhorizontalAlignment="center"
            LegenditemTextPosition="right"
            onRenderLabelChart={function (e) { }}
            onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: $ ${e.valueText}` })}
            yAxisArgs={pchamState.yAxis_chart2Unggulan}
        >

        </ChartDx>
    ), [pchamState.dataDahsboard?.data_SCurve2_Unggulan, pchamState.yAxis_chart2Unggulan]);

    return (
        <>
            <HeaderList
                title={pchamState.data_page?.page_title}
                someText={pchamState.data_page?.page_breadcrumbs}
                optionList={
                    [
                        {
                            label: "Progress kinerja dan anggaran : ",
                            option: [...(pchamState.dataDahsboard?.data_year_portfolio || [])].map(d => ({ name: d?.idYear, value: d?.idYear })),
                            withLabel: true,
                            value: (pchamState.dataDahsboard?.data_tahun_default || ''),
                            onChange: (val) => pchamAction.filterChange('tahun', val)
                        }, {
                            withLabel: false,
                            option: month_source,
                            value: (pchamState.dataDahsboard?.data_bulan_default || ''),
                            onChange: (val) => pchamAction.filterChange('bulanGet', val)
                        }
                    ]
                }
            />
            <div className="row mb-5">
                <div className="col-md-12 text-right">
                    <ExportPdf
                        targetRef={refEx}
                        fileName={`Dashboard Report Project Champions - ${pchamState.dataDahsboard?.data_bulan_default}-${pchamState.dataDahsboard?.data_tahun_default}`}
                        scale={0.8}
                    >
                        <button className="btn btn-sm btn-primary">
                            Export PDF
                        </button>
                    </ExportPdf>
                </div>
            </div>
            <div ref={refEx}>
                <div className='row mb-5'>
                    <div className='col-6'>
                        <h5 className='font-bold'>Dashboard Report</h5>
                    </div>
                    <div className='col-6 text-right'>
                        <span className='font-bold'>
                            {
                                pchamState.filter?.idFunction?.toString() === "0" ? "RTI " : [...(pchamState.dataDahsboard?.data_HeaderMonitoring_Unggulan || [])].find(d => d?.function_id?.toString() === pchamState.filter?.idFunction?.toString())?.short_name + ' '
                            }
                            - <span className='font-bold'>{`${month.find(d => d.value === toString(pchamState.dataDahsboard?.data_bulan_default))?.name || ""} ${(pchamState.dataDahsboard?.data_tahun_default) || ""}`.toUpperCase()}</span>
                        </span>
                    </div>
                </div>
                <hr />
                <Card
                    className="m-0 p-0  mb-5 customShadow bg-primary"
                >
                    <Card.Body className="p-3">
                        <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                                <div className="d-flex border-right-custom">
                                    <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Project</span>
                                        <span className="d-block"><strong>{(pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.total || 0) - (pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.continue_request || 0)}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Project Running</span>
                                        <span className="d-block"><strong>{pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.project_running || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Finished</span>
                                        <span className="d-block"><strong>{pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.finished || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Aborted</span>
                                        <span className="d-block"><strong>{pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.rejected || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Review</span>
                                        <span className="d-block"><strong>{pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.review || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Need Revision</span>
                                        <span className="d-block"><strong>{pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.need_revision || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Not Yet Reported</span>
                                        <span className="d-block"><strong>{pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.project_continue || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                                <div className="d-flex border-left-custom pl-md-5">
                                    <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                        <span className="d-block">$ <strong>{(pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.total_plan_budget || 0)}/{(pchamState.dataDahsboard?.data_JumlahProjectMonitoring_Unggulan?.total_actual_budget || 0)}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <Card
                            className="m-0 p-0 h-100 customShadow"
                        >
                            <Card.Body className="p-5">
                                <div className='row mb-5'>
                                    <div className='col-md-12'>
                                    <div className='overflow-auto'>
                                        <table className="table m-0 cstm-perform-table">
                                            <tbody>
                                                <tr className="overflow-auto">
                                                    {
                                                        pchamState.loadingDashboard ? (
                                                            <>
                                                                <td><Skeleton height={100} /></td>
                                                                <td><Skeleton height={100} /></td>
                                                                <td><Skeleton height={100} /></td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    [{
                                                                        "function_id": 0,
                                                                        "area_id": 0,
                                                                        "name": "RTI",
                                                                        "short_name": "RTI",
                                                                        "plan_tot": 0,
                                                                        "actual_tot": 0,
                                                                        "plan_dec": 0,
                                                                        "perform": 0,
                                                                        "perform_all": 0
                                                                    },
                                                                    ...(pchamState.dataDahsboard?.data_HeaderMonitoring_Unggulan || [])].map((d, i) => (
                                                                        <td key={i}>
                                                                            <Card
                                                                                style={{ cursor: 'pointer' }}
                                                                                key={i}
                                                                                onClick={(e) => pchamAction.filterChange("idFunction", d?.function_id.toString())}
                                                                                className={d.function_id.toString() === pchamState.filter?.idFunction ? 'cstm-Active customShadow m-0 p-0 h-100' : 'cstm customShadow m-0 p-0 h-100'}
                                                                            >
                                                                                <Card.Body className="p-2">
                                                                                    <div className="justify-content-center">
                                                                                        {
                                                                                            d.function_id === 0 ? (
                                                                                                <>
                                                                                                    <span className="d-flex font-weight-bold" style={{ fontSize: '12px' }}>RTI</span>
                                                                                                    <div className="col"><h5 style={{ textAlign: 'end' }}><strong>{parseFloat((pchamState.dataDahsboard?.data_HeaderMonitoring_Unggulan ? pchamAction.perform() : 0) * 100).toFixed(1)}</strong>%</h5></div>
                                                                                                    <div className="progress border border-primary" style={{ marginLeft: '10px', backgroundColor: 'white', marginRight: '10px' }}>
                                                                                                        <div className="progress-bar" role="progressbar" style={{ width: parseFloat((pchamState.dataDahsboard?.data_HeaderMonitoring_Unggulan ? pchamAction.performAll() : 0) * 100).toFixed(1) + '%' }} aria-valuenow={parseFloat((pchamState.dataDahsboard?.data_HeaderMonitoring_Unggulan ? pchamAction.performAll() : 0) * 100).toFixed(1)} aria-valuemin="0" aria-valuemax={parseFloat((pchamState.dataDahsboard?.data_HeaderMonitoring_Unggulan ? pchamAction.perform() : 0) * 100).toFixed(1)}></div>
                                                                                                    </div>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <span style={{ textAlign: 'start', paddingLeft: '10px', paddingTop: '7px' }}>{parseFloat((pchamState.dataDahsboard?.data_HeaderMonitoring_Unggulan ? pchamAction.performAll() : 0) * 100).toFixed(1)}%</span>
                                                                                                        <span className="text-right" style={{ textAlign: 'end', paddingRight: '10px', paddingTop: '7px' }}>{pchamState.dataDahsboard?.data_tahun_default}</span>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <span className="d-flex font-weight-bold" style={{ fontSize: '12px' }}>{d.short_name}</span>
                                                                                                    <div className="col"><h5 style={{ textAlign: 'end' }}><strong>{parseFloat((d.perform * 100)).toFixed(1)}</strong>%</h5></div>
                                                                                                    <div className="progress border border-primary" style={{ marginLeft: '10px', backgroundColor: 'white', marginRight: '10px' }}>
                                                                                                        <div className="progress-bar" role="progressbar" style={{ width: (parseFloat((d.perform_all * 100)).toFixed(1)) + '%' }} aria-valuenow={parseFloat((d.perform_all * 100)).toFixed(1)} aria-valuemin="0" aria-valuemax={parseFloat((d.perform * 100)).toFixed(1)}></div>
                                                                                                    </div>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <span style={{ textAlign: 'start', paddingLeft: '10px', paddingTop: '7px' }}>{parseFloat((d.perform_all * 100)).toFixed(1)}%</span>
                                                                                                        <span className="text-right" style={{ textAlign: 'end', paddingRight: '10px', paddingTop: '7px' }}>{pchamState.dataDahsboard?.data_tahun_default}</span>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </td>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-6 mb-5">
                                        <div className="row">
                                            <div className="col-8">
                                                <span className="ctsmFilterTitle">S-Curve Projects Champion</span>
                                                <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI</span>
                                            </div>
                                            <div className="col-4 text-md-right" style={{ paddingRight: 0 }}>
                                                <SwitchBtn value={pchamState.swChart1Unggulan} onChange={(val) => pchamAction.switch1UnggulanChange(val)} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row ">
                                            <div className="col-md-12">
                                                {
                                                    ChartSCurve1
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <div className="row">
                                            <div className="col-8">
                                                <span className="ctsmFilterTitle">S-Curve Budget Champion</span>
                                                <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI</span>
                                            </div>
                                            <div className="col-4 text-md-right" style={{ paddingRight: 0 }}>
                                                <SwitchBtn value={pchamState.swChart2Unggulan} onChange={(val) => pchamAction.switch2UnggulanChange(val)} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row ">
                                            <div className="col-md-12">
                                                {
                                                    ChartSCurve2
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <GridDx
                                            data={pchamState.dataDahsboard?.data_TableResearch_u}
                                            columns={columns}
                                            cellRender={pchamAction.cstmCellRender}
                                        >

                                        </GridDx>
                                    </div>
                                </div>
                                {/* <div className="row mb-5">
                                <div className="col-md-12">
                                    <Tab
                                        withCard={false}
                                        itemsTab={[
                                            {
                                                id: "scurve",
                                                title: "S-Curve"
                                            },
                                            {
                                                id: "table",
                                                title: "Table Detail"
                                            }
                                        ]}
                                    >
                                        <TabContent id="scurve">

                                        </TabContent>
                                        <TabContent id="table">

                                        </TabContent>
                                    </Tab>
                                </div>
                            </div> */}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <PchamProvider><Index /></PchamProvider>;