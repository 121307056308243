import DataGrid, { Button, Column, Editing, Lookup, Texts, LoadPanel } from 'devextreme-react/data-grid';
import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import CustomStore from 'devextreme/data/custom_store';
import { useGlobalState } from '../../contexts/useGlobalState';
import '../grid-timeline/grid-timeline.css';
import './grid-dx-drawdown.css'
import { TextBox } from 'devextreme-react';

export default function ({
    dataSource,
    columns,
    allowAdding = false,
    allowEditing = false,
    allowDeleting = false,
    modeEditing = "row",
    showTotalActual = false,
    baseline_drawdown_budget_id,
    editCellTemplate = (props) => {
        return (
            <TextBox defaultValue={props.value} onValueChanged={(e) => props.setValue(e.value)} />
        )
    },
    cellRender = (e) => {
        return (
            <span style={{ minHeight: '30px', padding: '7px' }} className="d-block text-wrap textContent">{e.value}</span>
        )
    },
    year,
    insert,
    update,
    remove,
    validationNAme = 'Project',
    onRefresh = () => { }
}) {
    const [ds, setDs] = useState(dataSource);
    const [sum, setSum] = useState(0);
    const [sumActual, setSumActual] = useState(0)
    const refGrid = useRef(null);
    const [dataSend, setDataSend] = useState();
    const [defData, setDefData] = useState();
    const [state, , action] = useGlobalState();
    const data = new CustomStore({
        key: 'id',
        load: () => dataSource,
        insert: insert ? (values) => insert(values, defData) : (values) => {
            action.postData(
                {
                    url: "/api/project-portfolio-crud/update-budget-detail?research_id=" + state.data_view_header?.id,
                    'data': dataSend,
                    withFile: false
                }
            ).then((resp) => {
                onRefresh()
            })
        },
        update: update ? (key, values) => update(key, values, defData) : (key, values) => {
            action.postData(
                {
                    url: "/api/project-portfolio-crud/update-budget-detail?research_id=" + state.data_view_header?.id,
                    'data': dataSend,
                    withFile: false
                }
            ).then((resp) => {
                onRefresh()
            })
        },
        remove: remove ? (key) => remove(key) : (key) => {
            action.postData(
                {
                    url: "/api/project-portfolio-crud/delete-budget-detail?id=" + key + "&research_id=" + state.data_view_header?.id,
                    withFile: false
                }
            ).then((resp) => {
                onRefresh()
            })
        }
    })

    useEffect(() => {
        setDs(dataSource)
    }, [dataSource])
    useEffect(() => {
        Summary();
    }, [ds])

    function Summary() {
        const tmp = ds;
        let total = 0;
        let totalActual = 0;
        if (Array.isArray(tmp)) {
            tmp.forEach((dt, i) => {
                total += ((isNaN(Number(dt.plan_jan))) ? 0 : Number(dt.plan_jan)) +
                    ((isNaN(Number(dt.plan_feb))) ? 0 : Number(dt.plan_feb)) +
                    ((isNaN(Number(dt.plan_mar))) ? 0 : Number(dt.plan_mar)) +
                    ((isNaN(Number(dt.plan_apr))) ? 0 : Number(dt.plan_apr)) +
                    ((isNaN(Number(dt.plan_may))) ? 0 : Number(dt.plan_may)) +
                    ((isNaN(Number(dt.plan_jun))) ? 0 : Number(dt.plan_jun)) +
                    ((isNaN(Number(dt.plan_jul))) ? 0 : Number(dt.plan_jul)) +
                    ((isNaN(Number(dt.plan_aug))) ? 0 : Number(dt.plan_aug)) +
                    ((isNaN(Number(dt.plan_sep))) ? 0 : Number(dt.plan_sep)) +
                    ((isNaN(Number(dt.plan_oct))) ? 0 : Number(dt.plan_oct)) +
                    ((isNaN(Number(dt.plan_nov))) ? 0 : Number(dt.plan_nov)) +
                    ((isNaN(Number(dt.plan_dec))) ? 0 : Number(dt.plan_dec));

                totalActual += ((isNaN(Number(dt.actual_jan))) ? 0 : Number(dt.actual_jan)) +
                    ((isNaN(Number(dt.actual_feb))) ? 0 : Number(dt.actual_feb)) +
                    ((isNaN(Number(dt.actual_mar))) ? 0 : Number(dt.actual_mar)) +
                    ((isNaN(Number(dt.actual_apr))) ? 0 : Number(dt.actual_apr)) +
                    ((isNaN(Number(dt.actual_may))) ? 0 : Number(dt.actual_may)) +
                    ((isNaN(Number(dt.actual_jun))) ? 0 : Number(dt.actual_jun)) +
                    ((isNaN(Number(dt.actual_jul))) ? 0 : Number(dt.actual_jul)) +
                    ((isNaN(Number(dt.actual_aug))) ? 0 : Number(dt.actual_aug)) +
                    ((isNaN(Number(dt.actual_sep))) ? 0 : Number(dt.actual_sep)) +
                    ((isNaN(Number(dt.actual_oct))) ? 0 : Number(dt.actual_oct)) +
                    ((isNaN(Number(dt.actual_nov))) ? 0 : Number(dt.actual_nov)) +
                    ((isNaN(Number(dt.actual_dec))) ? 0 : Number(dt.actual_dec));
            })
        }
        // console.log('summary', total, totalActual)
        setSum(total)
        setSumActual(totalActual)
    }
    function addNewRow() {
        refGrid.current.instance.addRow();
    }
    // function editCellTemplate(props) {
    //     //console.log('propsss', props)
    //     //var disable = props.data.type === 'task' ? true : false;
    //     const actual = {
    //         plan_jan: 'actual_jan',
    //         plan_feb: 'actual_feb',
    //         plan_mar: 'actual_mar',
    //         plan_apr: 'actual_apr',
    //         plan_may: 'actual_may',
    //         plan_jun: 'actual_jun',
    //         plan_jul: 'actual_jul',
    //         plan_aug: 'actual_aug',
    //         plan_sep: 'actual_sep',
    //         plan_oct: 'actual_oct',
    //         plan_nov: 'actual_nov',
    //         plan_dec: 'actual_dec',
    //     }
    //     // if (props.data.type === 'task' && (
    //     //     props.column.name === 'plan_jan' ||
    //     //     props.column.name === 'plan_feb' ||
    //     //     props.column.name === 'plan_mar' ||
    //     //     props.column.name === 'plan_apr' ||
    //     //     props.column.name === 'plan_may' ||
    //     //     props.column.name === 'plan_jun' ||
    //     //     props.column.name === 'plan_jul' ||
    //     //     props.column.name === 'plan_aug' ||
    //     //     props.column.name === 'plan_sep' ||
    //     //     props.column.name === 'plan_oct' ||
    //     //     props.column.name === 'plan_nov' ||
    //     //     props.column.name === 'plan_dec'
    //     // )) {
    //     //     props.setValue(null)
    //     // }
    //     return (
    //         <>
    //             {
    //                 props.column.name === "description" && (
    //                     <TextBox defaultValue={props.value} onValueChanged={(e) => props.setValue(e.value)} />
    //                 )

    //             }
    //             {
    //                 (props.column.name === 'plan_jan' ||
    //                     props.column.name === 'plan_feb' ||
    //                     props.column.name === 'plan_mar' ||
    //                     props.column.name === 'plan_apr' ||
    //                     props.column.name === 'plan_may' ||
    //                     props.column.name === 'plan_jun' ||
    //                     props.column.name === 'plan_jul' ||
    //                     props.column.name === 'plan_aug' ||
    //                     props.column.name === 'plan_sep' ||
    //                     props.column.name === 'plan_oct' ||
    //                     props.column.name === 'plan_nov' ||
    //                     props.column.name === 'plan_dec') && (
    //                     <div>
    //                         <NumberBox min={0} format="#,##0.##" defaultValue={props.value} onValueChanged={(e) => props.setValue(e.value)} />
    //                         <span className="d-block  p-2 cstmbgColorEditorActualDisable" style={{ height: '32px' }}>{props.data[actual[props.column.name]] || " "}</span>
    //                     </div>
    //                 )
    //             }
    //         </>
    //     )
    // }
    function onToolbarPreparing(e) {
        let toolbarItems = e.toolbarOptions.items;
        toolbarItems.forEach(function (item) {
            if (item.name === "addRowButton") {
                item.options = {
                    icon: "fa fa-plus-square",
                    text: "Add New",
                    hint: "Add New",
                    onClick: addNewRow.bind(this)
                }
            }
        });
    }
    // function onReorder(e) {
    //     const visibleRows = e.component.getVisibleRows();
    //     const newDS = ds;
    //     const toIndex = newDS.indexOf(visibleRows[e.toIndex].data);
    //     const fromIndex = newDS.indexOf(e.itemData);
    //     newDS.splice(fromIndex, 1);
    //     newDS.splice(toIndex, 0, e.itemData);
    //     setDs(newDS);
    //     refGrid.current.instance.refresh()
    // }
    function onEditorPreparing(e) {
        var sumActual = ((isNaN(Number(e.dataField["actual_jan"]))) ? 0 : Number(e.dataField["actual_jan"])) +
            ((isNaN(Number(e.dataField["actual_feb"]))) ? 0 : Number(e.dataField['actual_feb'])) +
            ((isNaN(Number(e.dataField["actual_mar"]))) ? 0 : Number(e.dataField["actual_mar"])) +
            ((isNaN(Number(e.dataField["actual_apr"]))) ? 0 : Number(e.dataField['actual_apr'])) +
            ((isNaN(Number(e.dataField['actual_may']))) ? 0 : Number(e.dataField['actual_may'])) +
            ((isNaN(Number(e.dataField['actual_jun']))) ? 0 : Number(e.dataField['actual_jun'])) +
            ((isNaN(Number(e.dataField['actual_jul']))) ? 0 : Number(e.dataField['actual_jul'])) +
            ((isNaN(Number(e.dataField['actual_aug']))) ? 0 : Number(e.dataField['actual_aug'])) +
            ((isNaN(Number(e.dataField['actual_sep']))) ? 0 : Number(e.dataField['actual_sep'])) +
            ((isNaN(Number(e.dataField['actual_oct']))) ? 0 : Number(e.dataField['actual_oct'])) +
            ((isNaN(Number(e.dataField['actual_nov']))) ? 0 : Number(e.dataField['actual_nov'])) +
            ((isNaN(Number(e.dataField['actual_dec']))) ? 0 : Number(e.dataField['actual_dec']));

        // if ((e.dataField === "plan_jan" ||
        //     e.dataField === "plan_jan" ||
        //     e.dataField === "plan_feb" ||
        //     e.dataField === "plan_mar" ||
        //     e.dataField === "plan_apr" ||
        //     e.dataField === "plan_may" ||
        //     e.dataField === "plan_jun" ||
        //     e.dataField === "plan_jul" ||
        //     e.dataField === "plan_aug" ||
        //     e.dataField === "plan_sep" ||
        //     e.dataField === "plan_oct" ||
        //     e.dataField === "plan_nov" ||
        //     e.dataField === "plan_dec") && e.parentType === "dataRow") {
        //     var grid = e.component;
        //     var index = e.row.rowIndex;
        //     var value = grid.cellValue(index, "type");
        //     //console.log('dataaaa', e, value)
        //     if (value === "task") {
        //         e.editorOptions.value = null;
        //         e.setValue(e.editorOptions.value)
        //         e.editorOptions.disabled = true;
        //     } else {
        //         e.editorOptions.readOnly = sumActual > 0 ? true : false;
        //         e.editorOptions.disabled = false;
        //     }
        // }
        if ((e.dataField === "plan_jan" ||
            e.dataField === "plan_jan" ||
            e.dataField === "plan_feb" ||
            e.dataField === "plan_mar" ||
            e.dataField === "plan_apr" ||
            e.dataField === "plan_may" ||
            e.dataField === "plan_jun" ||
            e.dataField === "plan_jul" ||
            e.dataField === "plan_aug" ||
            e.dataField === "plan_sep" ||
            e.dataField === "plan_oct" ||
            e.dataField === "plan_nov" ||
            e.dataField === "plan_dec") &&
            e.parentType === "dataRow") {
            e.editorOptions.readOnly = sumActual > 0 ? true : false;
            e.editorOptions.disabled = false;
        }
    }
    function onRowRemoving(e) {
        // const res = Swal.fire({
        //     text: 'Are you sure you want to delete this record?',
        //     showCancelButton: true,
        //     confirmButtonText: `Yes`,
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         Swal.fire('Deleted!', '', 'success')
        //         return false;
        //     } else {
        //         return true;
        //     }
        // })
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Budget ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                return false;
            } else {
                return true;
            }
        })
        e.cancel = res;
    }
    function addOnclick() {
        refGrid.current.instance.addRow();
    }
    function updateSum() {
        Summary();
    }
    // function cellRender(e) {
    //     const actual = {
    //         plan_jan: 'actual_jan',
    //         plan_feb: 'actual_feb',
    //         plan_mar: 'actual_mar',
    //         plan_apr: 'actual_apr',
    //         plan_may: 'actual_may',
    //         plan_jun: 'actual_jun',
    //         plan_jul: 'actual_jul',
    //         plan_aug: 'actual_aug',
    //         plan_sep: 'actual_sep',
    //         plan_oct: 'actual_oct',
    //         plan_nov: 'actual_nov',
    //         plan_dec: 'actual_dec',
    //     }
    //     if (e.column.name === "no") {
    //         return (
    //             <span className="d-block text-wrap textContent m-2">{e.rowIndex + 1}</span>
    //         )
    //     } else if (e.column.name === "description") {
    //         return (
    //             <span className="d-block text-wrap textContent m-2">{e.value}</span>
    //         )
    //     }
    //     else if (e.column.name === "cost_element_id") {
    //         return (
    //             <div>
    //                 <span style={{ minHeight: '43px', padding: '7px' }} className="d-block text-wrap textContent">{e.data.cost_element_name}</span>
    //                 <span style={{ minHeight: '30px', padding: '7px' }} className="d-block text-wrap textContent cstmbgColorEditorActualDisable">Realisasi</span>
    //             </div>
    //         )
    //     }
    //     else {
    //         return (
    //             <div>
    //                 <span style={{ minHeight: '43px', padding: '7px' }} className="d-block text-wrap textContent">{e.text}</span>
    //                 <span style={{ minHeight: '30px', padding: '7px' }} className="d-block text-wrap textContent cstmbgColorEditorActualDisable">{e.data[actual[e.column.name]] || " "}</span>
    //             </div>
    //         )
    //     }
    // }
    function rowValidating(e) {
        // const temp = refGrid.current.instance.getDataSource()?._items;
        // var index = refGrid.current.instance.getRowIndexByKey(e.key)
        let d = {};
        let dn = {};
        if (e.oldData) {
            d = {
                "id": e.newData?.id || e.oldData?.id || 0,
                "description": e.newData?.description || e.oldData?.description || '',
                "cost_element_id": e.newData?.cost_element_id || e.oldData?.cost_element_id || '',
                "year": year,
                "stream": e.newData?.stream || e.oldData?.stream || '',
                // "plan_jan": e.newData?.plan_jan || e.oldData?.plan_jan || '',
                // "plan_feb": e.newData?.plan_feb || e.oldData?.plan_feb || '',
                // "plan_mar": e.newData?.plan_mar || e.oldData?.plan_mar || '',
                // "plan_apr": e.newData?.plan_apr || e.oldData?.plan_apr || '',
                // "plan_may": e.newData?.plan_may || e.oldData?.plan_may || '',
                // "plan_jun": e.newData?.plan_jun || e.oldData?.plan_jun || '',
                // "plan_jul": e.newData?.plan_jul || e.oldData?.plan_jul || '',
                // "plan_aug": e.newData?.plan_aug || e.oldData?.plan_aug || '',
                // "plan_sep": e.newData?.plan_sep || e.oldData?.plan_sep || '',
                // "plan_oct": e.newData?.plan_oct || e.oldData?.plan_oct || '',
                // "plan_nov": e.newData?.plan_nov || e.oldData?.plan_nov || '',
                // "plan_dec": e.newData?.plan_dec || e.oldData?.plan_dec || '',
                // "actual_jan": e.newData?.actual_jan || e.oldData?.actual_jan || '',
                // "actual_feb": e.newData?.actual_feb || e.oldData?.actual_feb || '',
                // "actual_mar": e.newData?.actual_mar || e.oldData?.actual_mar || '',
                // "actual_apr": e.newData?.actual_apr || e.oldData?.actual_apr || '',
                // "actual_may": e.newData?.actual_may || e.oldData?.actual_may || '',
                // "actual_jun": e.newData?.actual_jun || e.oldData?.actual_jun || '',
                // "actual_jul": e.newData?.actual_jul || e.oldData?.actual_jul || '',
                // "actual_aug": e.newData?.actual_aug || e.oldData?.actual_aug || '',
                // "actual_sep": e.newData?.actual_sep || e.oldData?.actual_sep || '',
                // "actual_oct": e.newData?.actual_oct || e.oldData?.actual_oct || '',
                // "actual_nov": e.newData?.actual_nov || e.oldData?.actual_nov || '',
                // "actual_dec": e.newData?.actual_dec || e.oldData?.actual_dec || '',
                ...e.oldData,
                ...e.newData,
                "baseline_drawdown_budget_id": baseline_drawdown_budget_id
            }
            dn = {
                "id": e.newData?.id || e.oldData?.id || 0,
                "description": e.newData?.description || e.oldData?.description || '',
                "year": e.newData?.year || e.oldData?.year || '',
                // "plan_jan": e.newData?.plan_jan || e.oldData?.plan_jan || '',
                // "plan_feb": e.newData?.plan_feb || e.oldData?.plan_feb || '',
                // "plan_mar": e.newData?.plan_mar || e.oldData?.plan_mar || '',
                // "plan_apr": e.newData?.plan_apr || e.oldData?.plan_apr || '',
                // "plan_may": e.newData?.plan_may || e.oldData?.plan_may || '',
                // "plan_jun": e.newData?.plan_jun || e.oldData?.plan_jun || '',
                // "plan_jul": e.newData?.plan_jul || e.oldData?.plan_jul || '',
                // "plan_aug": e.newData?.plan_aug || e.oldData?.plan_aug || '',
                // "plan_sep": e.newData?.plan_sep || e.oldData?.plan_sep || '',
                // "plan_oct": e.newData?.plan_oct || e.oldData?.plan_oct || '',
                // "plan_nov": e.newData?.plan_nov || e.oldData?.plan_nov || '',
                // "plan_dec": e.newData?.plan_dec || e.oldData?.plan_dec || '',
                // "actual_jan": e.newData?.actual_jan || e.oldData?.actual_jan || '',
                // "actual_feb": e.newData?.actual_feb || e.oldData?.actual_feb || '',
                // "actual_mar": e.newData?.actual_mar || e.oldData?.actual_mar || '',
                // "actual_apr": e.newData?.actual_apr || e.oldData?.actual_apr || '',
                // "actual_may": e.newData?.actual_may || e.oldData?.actual_may || '',
                // "actual_jun": e.newData?.actual_jun || e.oldData?.actual_jun || '',
                // "actual_jul": e.newData?.actual_jul || e.oldData?.actual_jul || '',
                // "actual_aug": e.newData?.actual_aug || e.oldData?.actual_aug || '',
                // "actual_sep": e.newData?.actual_sep || e.oldData?.actual_sep || '',
                // "actual_oct": e.newData?.actual_oct || e.oldData?.actual_oct || '',
                // "actual_nov": e.newData?.actual_nov || e.oldData?.actual_nov || '',
                // "actual_dec": e.newData?.actual_dec || e.oldData?.actual_dec || ''
                ...e.oldData,
                ...e.newData,
            }
        } else {
            d = {
                "id": e.newData?.id || 0,
                "description": e.newData?.description || '',
                "cost_element_id": e.newData?.cost_element_id || '',
                "year": year,
                // "plan_jan": e.newData?.plan_jan || '',
                // "plan_feb": e.newData?.plan_feb || '',
                // "plan_mar": e.newData?.plan_mar || '',
                // "plan_apr": e.newData?.plan_apr || '',
                // "plan_may": e.newData?.plan_may || '',
                // "plan_jun": e.newData?.plan_jun || '',
                // "plan_jul": e.newData?.plan_jul || '',
                // "plan_aug": e.newData?.plan_aug || '',
                // "plan_sep": e.newData?.plan_sep || '',
                // "plan_oct": e.newData?.plan_oct || '',
                // "plan_nov": e.newData?.plan_nov || '',
                // "plan_dec": e.newData?.plan_dec || '',
                // "actual_jan": e.newData?.actual_jan || '',
                // "actual_feb": e.newData?.actual_feb || '',
                // "actual_mar": e.newData?.actual_mar || '',
                // "actual_apr": e.newData?.actual_apr || '',
                // "actual_may": e.newData?.actual_may || '',
                // "actual_jun": e.newData?.actual_jun || '',
                // "actual_jul": e.newData?.actual_jul || '',
                // "actual_aug": e.newData?.actual_aug || '',
                // "actual_sep": e.newData?.actual_sep || '',
                // "actual_oct": e.newData?.actual_oct || '',
                // "actual_nov": e.newData?.actual_nov || '',
                // "actual_dec": e.newData?.actual_dec || '',
                "plan_jan": '',
                "plan_feb": '',
                "plan_mar": '',
                "plan_apr": '',
                "plan_may": '',
                "plan_jun": '',
                "plan_jul": '',
                "plan_aug": '',
                "plan_sep": '',
                "plan_oct": '',
                "plan_nov": '',
                "plan_dec": '',
                "actual_jan": '',
                "actual_feb": '',
                "actual_mar": '',
                "actual_apr": '',
                "actual_may": '',
                "actual_jun": '',
                "actual_jul": '',
                "actual_aug": '',
                "actual_sep": '',
                "actual_oct": '',
                "actual_nov": '',
                "actual_dec": '',
                ...e.newData,
                "baseline_drawdown_budget_id": baseline_drawdown_budget_id
            }
            dn = {
                "id": e.newData?.id || 0,
                "description": e.newData?.description || '',
                "year": e.newData?.year || '',
                // "plan_jan": e.newData?.plan_jan || '',
                // "plan_feb": e.newData?.plan_feb || '',
                // "plan_mar": e.newData?.plan_mar || '',
                // "plan_apr": e.newData?.plan_apr || '',
                // "plan_may": e.newData?.plan_may || '',
                // "plan_jun": e.newData?.plan_jun || '',
                // "plan_jul": e.newData?.plan_jul || '',
                // "plan_aug": e.newData?.plan_aug || '',
                // "plan_sep": e.newData?.plan_sep || '',
                // "plan_oct": e.newData?.plan_oct || '',
                // "plan_nov": e.newData?.plan_nov || '',
                // "plan_dec": e.newData?.plan_dec || '',
                // "actual_jan": e.newData?.actual_jan || '',
                // "actual_feb": e.newData?.actual_feb || '',
                // "actual_mar": e.newData?.actual_mar || '',
                // "actual_apr": e.newData?.actual_apr || '',
                // "actual_may": e.newData?.actual_may || '',
                // "actual_jun": e.newData?.actual_jun || '',
                // "actual_jul": e.newData?.actual_jul || '',
                // "actual_aug": e.newData?.actual_aug || '',
                // "actual_sep": e.newData?.actual_sep || '',
                // "actual_oct": e.newData?.actual_oct || '',
                // "actual_nov": e.newData?.actual_nov || '',
                // "actual_dec": e.newData?.actual_dec || ''
                "plan_jan": '',
                "plan_feb": '',
                "plan_mar": '',
                "plan_apr": '',
                "plan_may": '',
                "plan_jun": '',
                "plan_jul": '',
                "plan_aug": '',
                "plan_sep": '',
                "plan_oct": '',
                "plan_nov": '',
                "plan_dec": '',
                "actual_jan": '',
                "actual_feb": '',
                "actual_mar": '',
                "actual_apr": '',
                "actual_may": '',
                "actual_jun": '',
                "actual_jul": '',
                "actual_aug": '',
                "actual_sep": '',
                "actual_oct": '',
                "actual_nov": '',
                "actual_dec": '',
                ...e.newData,
            }
        }
        let val = {
            errorText: '',
            isValid: true
        }
        if (validationNAme === 'Project') {
            val = customValidation('Project', d);
        } else if (validationNAme === 'ABI') {
            val = customValidation('ABI', dn);
        }

        e.errorText = val.errorText;
        e.isValid = val.isValid;
        if (e.isValid === true) {
            setDataSend(d);
            setDefData(dn);
        }
    }
    function customValidation(validationName, editingData, index) {
        var result = {
            errorText: '',
            isValid: true
        }
        switch (validationName) {
            case 'Project':
                if (editingData.description === '') {
                    result.errorText = 'Description tidak boleh kosong'
                    result.isValid = false
                } else if (editingData.cost_element_id === '') {
                    result.errorText = 'Cost Element tidak boleh kosong'
                    result.isValid = false
                } else if ((editingData.plan_jan === '' &&
                    editingData.plan_feb === '' &&
                    editingData.plan_mar === '' &&
                    editingData.plan_apr === '' &&
                    editingData.plan_may === '' &&
                    editingData.plan_jun === '' &&
                    editingData.plan_jul === '' &&
                    editingData.plan_aug === '' &&
                    editingData.plan_sep === '' &&
                    editingData.plan_oct === '' &&
                    editingData.plan_nov === '' &&
                    editingData.plan_dec === '') ||
                    (editingData.plan_jan === null &&
                        editingData.plan_feb === null &&
                        editingData.plan_mar === null &&
                        editingData.plan_apr === null &&
                        editingData.plan_may === null &&
                        editingData.plan_jun === null &&
                        editingData.plan_jul === null &&
                        editingData.plan_aug === null &&
                        editingData.plan_sep === null &&
                        editingData.plan_oct === null &&
                        editingData.plan_nov === null &&
                        editingData.plan_dec === null)) {
                    result.errorText = 'Plan Budget tidak boleh kosong'
                    result.isValid = false
                } else {
                    const tempPlan = ((isNaN(Number(editingData['plan_jan']))) ? 0 : Number(editingData['plan_jan'])) +
                        ((isNaN(Number(editingData['plan_feb']))) ? 0 : Number(editingData['plan_feb'])) +
                        ((isNaN(Number(editingData['plan_mar']))) ? 0 : Number(editingData['plan_mar'])) +
                        ((isNaN(Number(editingData['plan_apr']))) ? 0 : Number(editingData['plan_apr'])) +
                        ((isNaN(Number(editingData['plan_may']))) ? 0 : Number(editingData['plan_may'])) +
                        ((isNaN(Number(editingData['plan_jun']))) ? 0 : Number(editingData['plan_jun'])) +
                        ((isNaN(Number(editingData['plan_jul']))) ? 0 : Number(editingData['plan_jul'])) +
                        ((isNaN(Number(editingData['plan_aug']))) ? 0 : Number(editingData['plan_aug'])) +
                        ((isNaN(Number(editingData['plan_sep']))) ? 0 : Number(editingData['plan_sep'])) +
                        ((isNaN(Number(editingData['plan_oct']))) ? 0 : Number(editingData['plan_oct'])) +
                        ((isNaN(Number(editingData['plan_nov']))) ? 0 : Number(editingData['plan_nov'])) +
                        ((isNaN(Number(editingData['plan_dec']))) ? 0 : Number(editingData['plan_dec']));
                    const tempActual = ((isNaN(Number(editingData['actual_jan']))) ? 0 : Number(editingData['actual_jan'])) +
                        ((isNaN(Number(editingData['actual_feb']))) ? 0 : Number(editingData['actual_feb'])) +
                        ((isNaN(Number(editingData['actual_mar']))) ? 0 : Number(editingData['actual_mar'])) +
                        ((isNaN(Number(editingData['actual_apr']))) ? 0 : Number(editingData['actual_apr'])) +
                        ((isNaN(Number(editingData['actual_may']))) ? 0 : Number(editingData['actual_may'])) +
                        ((isNaN(Number(editingData['actual_jun']))) ? 0 : Number(editingData['actual_jun'])) +
                        ((isNaN(Number(editingData['actual_jul']))) ? 0 : Number(editingData['actual_jul'])) +
                        ((isNaN(Number(editingData['actual_aug']))) ? 0 : Number(editingData['actual_aug'])) +
                        ((isNaN(Number(editingData['actual_sep']))) ? 0 : Number(editingData['actual_sep'])) +
                        ((isNaN(Number(editingData['actual_oct']))) ? 0 : Number(editingData['actual_oct'])) +
                        ((isNaN(Number(editingData['actual_nov']))) ? 0 : Number(editingData['actual_nov'])) +
                        ((isNaN(Number(editingData['actual_dec']))) ? 0 : Number(editingData['actual_dec']));
                    if (tempActual !== 0 && (tempPlan < tempActual)) {
                        result.isValid = false;
                        result.errorText = "Realisasi yang telah di isi melebihi planning"
                    }
                }
                break;
            case 'ABI':
                if (editingData.year === '') {
                    result.errorText = 'Year tidak boleh kosong'
                    result.isValid = false
                } else if (editingData.description === '') {
                    result.errorText = 'Description tidak boleh kosong'
                    result.isValid = false
                } else if ((editingData.plan_jan === '' &&
                    editingData.plan_feb === '' &&
                    editingData.plan_mar === '' &&
                    editingData.plan_apr === '' &&
                    editingData.plan_may === '' &&
                    editingData.plan_jun === '' &&
                    editingData.plan_jul === '' &&
                    editingData.plan_aug === '' &&
                    editingData.plan_sep === '' &&
                    editingData.plan_oct === '' &&
                    editingData.plan_nov === '' &&
                    editingData.plan_dec === '') ||
                    (editingData.plan_jan === null &&
                        editingData.plan_feb === null &&
                        editingData.plan_mar === null &&
                        editingData.plan_apr === null &&
                        editingData.plan_may === null &&
                        editingData.plan_jun === null &&
                        editingData.plan_jul === null &&
                        editingData.plan_aug === null &&
                        editingData.plan_sep === null &&
                        editingData.plan_oct === null &&
                        editingData.plan_nov === null &&
                        editingData.plan_dec === null)) {
                    result.errorText = 'Plan Budget tidak boleh kosong'
                    result.isValid = false
                } else {
                    const tempPlan = ((isNaN(Number(editingData['plan_jan']))) ? 0 : Number(editingData['plan_jan'])) +
                        ((isNaN(Number(editingData['plan_feb']))) ? 0 : Number(editingData['plan_feb'])) +
                        ((isNaN(Number(editingData['plan_mar']))) ? 0 : Number(editingData['plan_mar'])) +
                        ((isNaN(Number(editingData['plan_apr']))) ? 0 : Number(editingData['plan_apr'])) +
                        ((isNaN(Number(editingData['plan_may']))) ? 0 : Number(editingData['plan_may'])) +
                        ((isNaN(Number(editingData['plan_jun']))) ? 0 : Number(editingData['plan_jun'])) +
                        ((isNaN(Number(editingData['plan_jul']))) ? 0 : Number(editingData['plan_jul'])) +
                        ((isNaN(Number(editingData['plan_aug']))) ? 0 : Number(editingData['plan_aug'])) +
                        ((isNaN(Number(editingData['plan_sep']))) ? 0 : Number(editingData['plan_sep'])) +
                        ((isNaN(Number(editingData['plan_oct']))) ? 0 : Number(editingData['plan_oct'])) +
                        ((isNaN(Number(editingData['plan_nov']))) ? 0 : Number(editingData['plan_nov'])) +
                        ((isNaN(Number(editingData['plan_dec']))) ? 0 : Number(editingData['plan_dec']));
                    const tempActual = ((isNaN(Number(editingData['actual_jan']))) ? 0 : Number(editingData['actual_jan'])) +
                        ((isNaN(Number(editingData['actual_feb']))) ? 0 : Number(editingData['actual_feb'])) +
                        ((isNaN(Number(editingData['actual_mar']))) ? 0 : Number(editingData['actual_mar'])) +
                        ((isNaN(Number(editingData['actual_apr']))) ? 0 : Number(editingData['actual_apr'])) +
                        ((isNaN(Number(editingData['actual_may']))) ? 0 : Number(editingData['actual_may'])) +
                        ((isNaN(Number(editingData['actual_jun']))) ? 0 : Number(editingData['actual_jun'])) +
                        ((isNaN(Number(editingData['actual_jul']))) ? 0 : Number(editingData['actual_jul'])) +
                        ((isNaN(Number(editingData['actual_aug']))) ? 0 : Number(editingData['actual_aug'])) +
                        ((isNaN(Number(editingData['actual_sep']))) ? 0 : Number(editingData['actual_sep'])) +
                        ((isNaN(Number(editingData['actual_oct']))) ? 0 : Number(editingData['actual_oct'])) +
                        ((isNaN(Number(editingData['actual_nov']))) ? 0 : Number(editingData['actual_nov'])) +
                        ((isNaN(Number(editingData['actual_dec']))) ? 0 : Number(editingData['actual_dec']));
                    if (tempActual !== 0 && (tempPlan < tempActual)) {
                        result.isValid = false;
                        result.errorText = "Planning tidak boleh kurang dari realisasi"
                    }
                }
                break;
            default:
        }
        return result;
    }
    function headerRender(e) {
        return (<span className="d-block text-center p-3" style={{ fontWeight: 'bold' }}>{e.column.caption}</span>)
    }
    function allowDelete(e) {
        if (allowDeleting) {
            var sumActual = ((isNaN(Number(e.row.data["actual_jan"]))) ? 0 : Number(e.row.data["actual_jan"])) +
                ((isNaN(Number(e.row.data["actual_feb"]))) ? 0 : Number(e.row.data['actual_feb'])) +
                ((isNaN(Number(e.row.data["actual_mar"]))) ? 0 : Number(e.row.data["actual_mar"])) +
                ((isNaN(Number(e.row.data["actual_apr"]))) ? 0 : Number(e.row.data['actual_apr'])) +
                ((isNaN(Number(e.row.data['actual_may']))) ? 0 : Number(e.row.data['actual_may'])) +
                ((isNaN(Number(e.row.data['actual_jun']))) ? 0 : Number(e.row.data['actual_jun'])) +
                ((isNaN(Number(e.row.data['actual_jul']))) ? 0 : Number(e.row.data['actual_jul'])) +
                ((isNaN(Number(e.row.data['actual_aug']))) ? 0 : Number(e.row.data['actual_aug'])) +
                ((isNaN(Number(e.row.data['actual_sep']))) ? 0 : Number(e.row.data['actual_sep'])) +
                ((isNaN(Number(e.row.data['actual_oct']))) ? 0 : Number(e.row.data['actual_oct'])) +
                ((isNaN(Number(e.row.data['actual_nov']))) ? 0 : Number(e.row.data['actual_nov'])) +
                ((isNaN(Number(e.row.data['actual_dec']))) ? 0 : Number(e.row.data['actual_dec']));
            return !sumActual > 0;
        } else {
            return false
        }
    }
    return (
        <>
            {
                allowAdding &&
                <div className="d-flex flex-row-reverse" style={{ paddingBottom: 10 }}>
                    <button className="btn btn-primary btn-sm" onClick={addOnclick} style={{ fontSize: '8pt', fontFamily: 'Poppins' }}>
                        <i className="fa fa-plus icon-nm text-white"></i>
                        Budget
                    </button>
                </div>
            }
            <DataGrid
                dataSource={data}
                onRowValidating={rowValidating}
                rowAlternationEnabled={true}
                ref={refGrid}
                onToolbarPreparing={onToolbarPreparing}
                onEditorPreparing={onEditorPreparing}
                onRowRemoving={onRowRemoving}
                onRowInserted={updateSum}
                onRowUpdated={updateSum}
                onRowRemoved={updateSum}
                showRowLines={true}
                showColumnLines={true}
                showBorders={true}
                className="headerCustomTimeline gridBorder gridBorder-col gridBorder-content customPadding-col command-button-padding"
            >
                <LoadPanel enabled={false} />
                <Editing mode={modeEditing} allowDeleting={allowDelete} allowEditing={allowEditing} allowUpdating={allowEditing} >
                    <Texts confirmDeleteMessage=""></Texts>
                </Editing>
                {
                    // allowEditing && (<RowDragging
                    //     allowReordering={true}
                    //     onReorder={onReorder}
                    //     showDragIcons={true}
                    // />)
                }
                {
                    columns.map(({
                        dataField,
                        minWidth = null,
                        caption,
                        custom = false,
                        width = null,
                        dataType,
                        dSource = [],
                        displayExpr,
                        valueExpr,
                        visible = true,
                        allowSorting = true,
                        allowFiltering = true,
                        allowEditing = true,
                        customType = "lookup",
                        format }, i) => custom === true ?
                            (
                                <Column
                                    minWidth={minWidth}
                                    allowEditing={allowEditing}
                                    allowSorting={allowSorting}
                                    allowFiltering={allowFiltering}
                                    width={width}
                                    cellRender={cellRender}
                                    dataField={dataField}
                                    caption={caption}
                                    key={i}
                                    headerCellRender={headerRender}
                                    visible={visible}
                                    dataType={dataType}
                                >
                                    {
                                        CreateCustom({
                                            type: customType,
                                            dataType: dataType,
                                            dataSource: dSource,
                                            displayExpr: displayExpr,
                                            valueExpr: valueExpr
                                        })
                                    }
                                </Column>

                            ) : (
                                < Column
                                    editCellRender={editCellTemplate}
                                    allowEditing={allowEditing}
                                    allowSorting={allowSorting}
                                    allowFiltering={allowFiltering}
                                    minWidth={minWidth}
                                    width={width}
                                    headerCellRender={headerRender}
                                    dataField={dataField}
                                    cellRender={cellRender}
                                    caption={caption}
                                    key={i}
                                    visible={visible}
                                    format={format}
                                    dataType={dataType}
                                ></Column>
                            )
                    )
                }
                {
                    allowEditing &&
                    <Column type="buttons" caption="Action" width={100} headerCellRender={headerRender}>
                        <Button name="edit" icon="fa fa-pencil-alt icon-nm" cssClass="text-success" />
                        <Button name="delete" icon="fa fa-trash-alt icon-nm" cssClass="text-danger" />
                        <Button name="save" icon="fa fa-save icon-nm" cssClass="text-success" />
                        <Button name="cancel" icon="fa fa-undo icon-nm" cssClass="text-light" />
                    </Column>
                }
            </DataGrid>
            <div style={{ padding: 10, borderLeft: '1px solid #006CB8', borderRight: '1px solid #006CB8', borderBottom: '1px solid #006CB8' }} >
                <span style={{ display: "block", textAlign: "center" }}>Total Plan $ {sum.toFixed(2)} (Ribu USD)</span>
            </div>
            {
                showTotalActual &&
                <div style={{ padding: 10, borderLeft: '1px solid #006CB8', borderRight: '1px solid #006CB8', borderBottom: '1px solid #006CB8' }} >
                    <span style={{ display: "block", textAlign: "center" }}>Total Realisasi $ {sumActual.toFixed(2)} (Ribu USD)</span>
                </div>
            }
        </>
    )
}
function CreateCustom(props) {
    if (props.type === "lookup") {
        return (
            <Lookup dataSource={props.dataSource} dataType={props.dataType} displayExpr={props.displayExpr} valueExpr={props.valueExpr} />
        )
    }
}
