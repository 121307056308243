import { Autocomplete } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { FileUploadDx, HeaderList } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { fileExtensions } from '../../components/utility/utility';

function EditKmLibrary(props) {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const { id } = useParams();

    const [dataArea, setDataArea] = useState([]);
    const [dataKateg, setDataKateg] = useState([]);
    const [judul, setJudul] = useState("");
    const [description, setDescription] = useState("");
    const [area, setArea] = useState("");
    const [kategori, setKategori] = useState("");
    const [url, setUrl] = useState("");
    const [ownerName, setOwnerName] = useState('');
    const [owner, setOwner] = useState('');
    const [author, setAuthor] = useState('');
    const [cover, setCover] = useState('');
    const [file_lib, setFile_lib] = useState('');
    const [statusEdit, setStatusEdit] = useState(0);
    const [idEdit, setIdEdit] = useState('');


    useEffect(() => {
        getDataArea();
        getDataKateg();
        getDataEdit(id)

    }, [id])

    function getDataArea() {
        action.getData('/api/book-area', {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                setDataArea(resp?.data);
            }
        })
    }
    function getDataKateg() {
        action.getData('/api/book-category', {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                setDataKateg(resp?.data);
            }
        })
    }
    function getDataEdit(id) {
        action.getData(`/api/km-library/view/${id}`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                const { detail_book } = resp?.data;
                setJudul(detail_book?.title);
                setIdEdit(detail_book?.id);
                setDescription(detail_book?.description);
                setKategori(detail_book?.book_category_id);
                setArea(detail_book?.area_id);
                setAuthor(detail_book?.author);
                setUrl(detail_book?.sitelink);
                setFile_lib({
                    name: detail_book?.file_location,
                    is_new: false
                })
                setCover({
                    name:detail_book?.cover_location,
                    is_new:false
                })
            }
        })
    }
    function onUploadedFile_lib(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setFile_lib({
                "name": resp.data?.data_filename,
                "is_new": true
            })
        }
    }
    function onUploadedCover_lib(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setCover({
                "name": resp.data?.data_filename,
                "is_new": true
            })
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        if (data?.title === "") {
            isValid = false;
            message = [...message, "Judul"];
        }
        if (data?.description === "") {
            isValid = false;
            message = [...message, "Deskripsi"];
        }
        if (data?.area_id === "") {
            isValid = false;
            message = [...message, "Area"];
        }
        if (data?.book_category_id === "") {
            isValid = false;
            message = [...message, "Kategori"];
        }
        if (data?.author === "") {
            isValid = false;
            message = [...message, "Author"];
        }
        if (data?.file_location?.name === "" || data?.file_location?.name === null || data?.file_location?.name === undefined) {
            isValid = false;
            message = [...message, "File Library"];
        }
        if (data?.cover_location?.name === "" || data?.cover_location?.name === null || data?.cover_location?.name === undefined) {
            isValid = false;
            message = [...message, "Cover"];
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }
    function saveClick() {
        const data = {
            "id": idEdit,
            "book_category_id": kategori || '',
            "area_id": area || '',
            "title": judul || '',
            "description": description || '',
            "author": author || '',
            "file_location": file_lib || {},
            "cover_location": cover || {},
            "file_size": 0,
            "sitelink": url || '',
            "hit": 0,
            "creator": 0,
            "created_at": "",
            "updated_at": ""
        }
        // console.log(data);
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update Library? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/km-library/update",
                        data: data,
                        withFile: false
                    }
                ).then((res) => {
                    if (res.code === "00") {
                        history.goBack();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }

    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <HeaderList
                        title="Edit Library"
                        someText={"Knowladge Management\\Library\\Edit"}
                    ></HeaderList>
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-4">
                            <div>
                                <h5>Library</h5>
                                <hr />
                                <div className="row mb-3">
                                    <div className="col-md-3">Judul Library</div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm" placeholder="Judul Library" value={judul} onChange={(e) => setJudul(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">Description</div>
                                    <div className="col-md-9">
                                        <textarea rows={3} placeholder="Deskripsi..." className="form-control form-control-sm" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">Area</div>
                                    <div className="col-md-9">
                                        <select className="form-control form-control-sm" value={area} onChange={(e) => setArea(e.target.value)}>
                                            <option value="" selected> - Pilih Area -</option>
                                            {
                                                dataArea.map((d, i) => (
                                                    <option key={i} value={d?.param_id}>{d?.title}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">Kategori Library</div>
                                    <div className="col-md-9">
                                        <select className="form-control form-control-sm" value={kategori} onChange={(e) => setKategori(e.target.value)}>
                                            <option value="" selected> - Pilih Kategori -</option>
                                            {
                                                dataKateg.map((d, i) => (
                                                    <option key={i} value={d?.id}>{d?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">Author</div>
                                    <div className="col-md-9">
                                        {/* <Autocomplete dataSource={state.data_users}
                                            value={ownerName}
                                            placeholder="Nama Owner"
                                            valueExpr="name"
                                            displayExpr="name"
                                            onValueChanged={(e) => setOwnerName(e.value)}
                                            onSelectionChanged={(e) => setOwner(e.selectedItem)}
                                            className="form-control form-control-sm p-0 mr-1">
                                        </Autocomplete> */}
                                                                                <input className="form-control form-control-sm" placeholder='Nama Author' value={author} onChange={(e) => setAuthor(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">Site/Url</div>
                                    <div className="col-md-9">
                                        <input className="form-control form-control-sm" placeholder='Site/Url (Contoh: https://spiirit.net)' value={url} onChange={(e) => setUrl(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">File Library</div>
                                    <div className="col-md-9">
                                        <FileUploadDx
                                            className="custom-dx-fileuploader-file-name"
                                            showFileList={true}
                                            multiple={false}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb"
                                            accept="*"
                                            onUploaded={onUploadedFile_lib}
                                            maxFileSize={10000000}
                                            uploadMode="instantly"
                                        />
                                        <span>{file_lib.name}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">Cover</div>
                                    <div className="col-md-9">
                                        <FileUploadDx
                                            className="custom-dx-fileuploader-file-name"
                                            showFileList={true}
                                            multiple={false}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb"
                                            accept="image/*"
                                            allowedFileExtensions={fileExtensions}
                                            onUploaded={onUploadedCover_lib}
                                            maxFileSize={10000000}
                                            uploadMode="instantly"
                                        />
                                        <span>{cover.name}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className='text-right'>
                                    <button className='btn btn-sm btn-secondary mr-1'
                                        onClick={() => history.goBack()}
                                    >
                                        Kembali
                                    </button>
                                    <button className='btn btn-sm btn-primary'
                                        onClick={() => saveClick()}
                                    >
                                        <i className='fa fa-save icon-nm'></i>
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default EditKmLibrary;