import React, { useRef } from 'react'
import { HeaderList, Tab, TabContent, ExportPdf } from '../../components'
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import TableReportPeople from './tableReporting';
import usePeopleReport, { PeopleReportProvider } from './usePeopleReporting';
import TabDashboard from '../people/tabDashboard';

function ReportingPeople(props) {
    const refDashboard = useRef();
    const { pReportAction, pReportState } = usePeopleReport();
    return (
        <>
            <div className="mb-5">
                <HeaderList
                    title={pReportState.data_page?.page_title}
                    someText={pReportState.data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        title: "Dashboard"
                    },
                    {
                        id: "table",
                        title: "Tabel Pekerja"
                    }
                ]}
            >
                <TabContent id="dashboard">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-2">
                            <div className="d-block text-md-right mt-5">
                                <ExportPdf
                                    targetRef={refDashboard}
                                    scale={0.8}
                                    fileName={`Demography Dashboard-${moment(new Date()).format('YYYY-MM-DD')}`}
                                >
                                    <button className="btn btn-sm btn-primary mb-5">
                                        Export PDF
                                    </button>
                                </ExportPdf>
                            </div>
                            <div ref={refDashboard}>
                                <div className="mb-5 px-2 font-bold" style={{ fontSize: '12pt' }}>Demography Dashboard</div>
                                <TabDashboard />
                            </div>
                        </Card.Body>
                    </Card>
                </TabContent>
                <TabContent id="table">
                    <TableReportPeople />
                </TabContent>
            </Tab>
        </>
    );
}

export default () => <PeopleReportProvider><ReportingPeople /></PeopleReportProvider>