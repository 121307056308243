import React, { useEffect, useRef, useState } from 'react';
import { GridDx, HeaderList } from '../../../../components';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../modules/useFetch';
import Swal from 'sweetalert2';
import LabStatus from '../component/labStatus';

const column = [
    { fieldName: "created_at_str", caption: "Tanggal" },
    { fieldName: "lab_test_status_id_str", caption: "Status Pengujian" },
    { fieldName: "note_history", caption: "Keterangan" },
    { fieldName: "file_download", caption: "File Download" },
];

function Index(props) {
    const { id } = useParams();
    const { doGet } = useFetch();
    const [detail, setDetail] = useState({});
    const tableRef = useRef();


    useEffect(() => {
        async function getData() {
            Swal.fire({
                title: 'Please Wait',
                html: 'Retrieving data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            try {
                const resp = await doGet({
                    url: 'api/ext-lab/view-mylab-detail',
                    param: { id_lab_test: id }
                });
                if (resp?.code === '00') {
                    setDetail(resp?.data)
                } else {
                    return Swal.fire({
                        title: 'Error!',
                        text: resp?.message,
                        icon: 'error',
                    })
                }
            } catch (error) {
                return Swal.fire({
                    title: 'Error!',
                    text: error.toString(),
                    icon: 'error',
                })
            }
            Swal.close();
        }
        getData();
    }, []);
    const cellrender = (rowInfo) => {
        if (rowInfo.column.name === "file_download") {
            return rowInfo.value ? <a href={rowInfo.value} target='_blank' download>Download File</a> : <></>
        } else if (rowInfo.column.name === "lab_test_status_id_str") {
            return <LabStatus status={rowInfo.data?.status_id} >{rowInfo.value}</LabStatus>
        } else {
            return <span>{rowInfo.value}</span>
        }
    }
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={"Laboratory Detail"}
                someText={""}
            />
            <div className="row">
                <div className="col-12">
                    <div className='rounded customShadow p-5'>
                        <div className='row mb-5'>
                            <div className='col-md-6'>
                                <div className="form-group row">
                                    <label className="col-sm-5">Judul Pengujian</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.judul || ""}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-5">No. Pengujian</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.no_lab_test || ""}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-5">Tanggal Pengujian</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.created_at_str || ""}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-5">Status Pengujian</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.lab_test_status_id_str || ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group row">
                                    <label className="col-sm-5">Holding/Sub Holding</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.institution_nama || ""}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-5">Direktorat (Holding)</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.institution_sub_nama || ""}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-5">Fungsi</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.user_fungsi || ""}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-5">Alamat</label>
                                    <div className="col-sm-7">
                                        <p className='bg-secondary p-2 rounded' style={{ minHeight: '32px' }}>{detail?.data_customer?.user_alamat || ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5><b>History Pengujian Lab</b></h5>
                        {
                            React.useMemo(() => (
                                <GridDx
                                    className='custom-style-master'
                                    searchForm={true}
                                    cellRender={cellrender}
                                    ref={tableRef}
                                    data={detail?.data_history_lab_test || []}
                                    columns={column}
                                    pagination={true}
                                    orderingDrag={false}
                                    withAction={false}
                                    currentPage
                                    show
                                />

                            ), [detail.data_history_lab_test])
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;