import React, { useEffect, useRef } from 'react'
import { ChartDx, CstmFilterCahrtDx, DoughnatDx, GridDx, Tab, TabContent, ExportPdf } from '../../../components'
import { useGlobalState } from '../../../contexts/useGlobalState';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import { chart_color } from '../../../components/utility/utility';
import usePortfolio from './usePortfolio';
import { Link } from 'react-router-dom';

const columns = [
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "intangible", caption: "Intangible", minWidth: 500 },
]
const TabDashboard = ({ reporting = false }) => {
    const [state, dispatch, action] = useGlobalState();
    const refEx = useRef();
    const { portAction, portState } = usePortfolio();
    const colorX = ['bg-primary', 'bg-success', 'bg-warning', 'bg-purple', 'bg-danger', 'bg-orange'];

    useEffect(() => {
        if (reporting === false) {
            portAction.getDashboard();
        } else {
            portAction.getDashboardAdmin();
        }
        portAction.getProyekType();
        portAction.getChart1();
        portAction.getChart2();
        portAction.getChart3();
        portAction.getChart4();
        portAction.getChart8();
        portAction.getChart9();
        portAction.getChart11();
        portAction.getChart12();
        portAction.getChart13();
        portAction.getChart14();
        portAction.getTangibleTable();
    }, [portState.tahun])

    return (
        <>
            {
                reporting && (
                    <div className="row mb-5">
                        <div className="col-md-12 text-right">
                            <ExportPdf
                                targetRef={refEx}
                                fileName={`Dashboard Portfolio-${portState.tahun}`}
                                scale={0.8}
                            >
                                <button className="btn btn-sm btn-primary">
                                    Export PDF
                                </button>
                            </ExportPdf>
                        </div>
                    </div>
                )
            }
            <div ref={refEx}>
                <Card
                    className="m-0 p-0 mb-5 customShadow bg-primary"
                >
                    <Card.Body className="p-2">
                        <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                                <div className="d-flex border-right-custom">
                                    <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Research</span>
                                        <span className="d-block"><strong>{
                                            portState.dataDashboard?.data_JumlahProject?.total
                                        }</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-pink" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block title-box">Review</span>
                                        <span className="d-block"><strong>{portState.dataDashboard?.data_JumlahProject?.review || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block title-box">Need Revision</span>
                                        <span className="d-block"><strong>{portState.dataDashboard?.data_JumlahProject?.draft_need_revision || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block title-box">Rejected</span>
                                        <span className="d-block"><strong>{portState.dataDashboard?.data_JumlahProject?.rejected || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block title-box">Waiting Implementation</span>
                                        <span className="d-block"><strong>{portState.dataDashboard?.data_JumlahProject?.waiting_implementation || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block title-box">Running</span>
                                        <span className="d-block"><strong>{portState.dataDashboard?.data_JumlahProject?.running || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-secondary" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block title-box">Finished</span>
                                        <span className="d-block"><strong>{portState.dataDashboard?.data_JumlahProject?.finished || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block title-box">Aborted</span>
                                        <span className="d-block"><strong>{portState.dataDashboard?.data_JumlahProject?.aborted || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                                <div className="d-flex border-left-custom pl-md-5">
                                    <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Budget (Ribu USD)</span>
                                        <span className="d-block">$ <strong>{parseFloat(portState.dataDashboard?.data_JumlahProject?.total_plan_budget || 0).toFixed(1)}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <div className="row mb-5">
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Jumlah usulan Proyek"
                            subtitle="Jumlah usulan proyek inovasi dan operasional"
                            option={portState.data_type_proyek_1}
                            value={portState.typeChart1}
                            onChange={(val) => portAction.getChart1(val)}
                        >
                            {
                                React.useMemo(() => (

                                    <ChartDx
                                        id="1"
                                        loading={portState.loadingchart1}
                                        argumentField="name"
                                        dataSource={portState.chart1?.data_chart}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah",
                                                color: '#326BD5'
                                            }
                                        ]}
                                    />
                                ), [portState.loadingchart1, portState.chart1?.data_chart])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Usulan Anggaran"
                            subtitle="Jumlah usulan anggaran inovasi dan operasional (Ribu USD)"
                            option={portState.data_type_proyek_1}
                            value={portState.typeChart2}
                            onChange={(val) => portAction.getChart2(val)}
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="2"
                                        argumentField="name"
                                        loading={portState.loadingchart2}
                                        dataSource={portState.chart2?.data_chart}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah_des",
                                                color: '#ACC429'
                                            }
                                        ]}
                                    />
                                ), [portState.loadingchart2, portState.chart2?.data_chart])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Periode Pelaksaan Proyek"
                            subtitle="Jumlah usulan proyek berdasarkan periode pelaksanannya s.d komersial/terimplementasi"
                            option={portState.data_type_proyek_2}
                            value={portState.typeChart3}
                            onChange={(val) => portAction.getChart3(val)}
                        >
                            {
                                React.useMemo(() => (

                                    <ChartDx
                                        id="3"
                                        labelChart={true}
                                        argumentField="state"
                                        type="stackedBar"
                                        loading={portState.loadingchart3}
                                        dataSource={portState.chart3?.data_chart_fix}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        onRenderLabelChart={() => { }}
                                        onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                                        rotate={true}
                                        yAxisArgs={[...(portState.chart3?.data_chart || [])].map(d => {
                                            return {
                                                valueField: d?.name,
                                                name: d?.name
                                            }
                                        })}
                                    />
                                ), [portState.loadingchart3, portState.chart3?.data_chart_fix, portState.chart3?.data_chart])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Dukungan Operasional/ Sub-holding"
                            subtitle="Jumlah usulan keterlibatan operasional sub-holding untuk mendukung proyek inovasi RTI"
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="4"
                                        loading={portState.loadingDashboard}
                                        dataSource={portState.dataDashboard?.data_chart6_DukunganOperasional}
                                        colorBarDifferent={true}
                                        argumentField="name"
                                        overlappingBehavior="stagger"
                                        colorBarDiffirentField="name"
                                        coloBarDifferentValueField="jumlah"
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                    />
                                ), [portState.loadingDashboard, portState.dataDashboard?.data_chart6_DukunganOperasional])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Kategori Inovasi"
                            subtitle="Jumlah usulan proyek inovasi berdasarkan area dan kategori inovasi"
                            option={portAction.getAreaOption()}
                            onChange={(val) => portAction.getChart8(val)}
                        >
                            <div className="row">
                                <div className="col-9">
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                loading={portState.loadingchart8}
                                                argumentField="name"
                                                dataSource={portState.chart8?.data_chart}
                                                overlappingBehavior="rotate"
                                                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                                onrenderLabel={(e) => `${e.valueText?.substr(0, 8)}`}
                                                withTitle={false}
                                                showLegend={false}
                                                toolsExport={false}
                                                yAxisArgs={[
                                                    {
                                                        valueField: "jumlah",
                                                        color: "#775DD0"
                                                    }
                                                ]} />
                                        ), [portState.loadingchart8, portState.chart8?.data_chart])
                                    }
                                </div>
                                <div className="col-3 px-0">
                                    {
                                        [...(portState.dataDashboard?.data_chart8a_KategoriInovasi || [])].map((d, i) => (
                                            <div key={i} className={`rounded ${colorX[i]}`} style={{ color: 'white', padding: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span style={{ fontSize: 9, width: '50px' }}
                                                >
                                                    {d.name}
                                                </span>
                                                <span style={{ fontSize: 11, fontWeight: 'bold' }}>
                                                    {
                                                        d.jumlah
                                                    }
                                                </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Kebutuhan Tenaga Ahli Proyek"
                            subtitle="Perbandingan antara jumlah tenaga ahli yang diperlukan dengan ketersediaan tenaga ahli di RTI"
                        >
                            {
                                React.useMemo(() => (

                                    <ChartDx
                                        id="6"
                                        loading={portState.loadingDashboard}
                                        argumentField="name"
                                        dataSource={portState.dataDashboard?.data_chart10_KebutTenagaAhli}
                                        overlappingBehavior="rotate"
                                        rotationAngle={45}
                                        onRenderTooltip={(e) => (`${e.argumentText}: ${e.valueText}`)}
                                        onrenderLabel={(e) => `${e.valueText?.substr(0, 8)}`}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        LegenditemTextPosition="right"
                                        onRenderLabelChart={(e) => {
                                            if (e.seriesName === "Expertise proyek") {
                                                return e.value;
                                            } else {
                                                return "";
                                            }
                                        }}
                                        yAxisArgs={[
                                            {
                                                valueField: "data_1",
                                                name: "Expertise proyek",
                                                type: "bar",
                                                color: '#326BD5'
                                            },
                                            {
                                                valueField: "data_2",
                                                type: "spline",
                                                name: 'Ketersediaan expertise',
                                                color: '#01E396'
                                            }
                                        ]}

                                    />
                                ), [portState.loadingDashboard, portState.dataDashboard?.data_chart10_KebutTenagaAhli])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5" >
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Metode Pelaksanaan Proyek"
                            subtitle="Jumlah usulan proyek inovasi berdasarkan metode pelaksanaan"
                            option={portState.data_type_proyek_2}
                            onChange={(val) => portAction.getChart4(val)}
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="7"
                                        loading={portState.loadingchart4}
                                        argumentField="state"
                                        type="stackedBar"
                                        dataSource={portState.chart4?.data_chart_fix}
                                        overlappingBehavior="none"
                                        onrenderLabel={(e) => `${e.value === "SemiPartnership" ? "Swakelola" : e.value}`}
                                        onRenderTooltip={(e) => ({ text: `${e.argumentText === "SemiPartnership" ? "Swakelola" : e.argumentText} ; ${e.seriesName} ; ${e.value}` })}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        yAxisArgs={[...(portState.chart4?.data_chart || [])].map(d => {
                                            return {
                                                valueField: d?.name,
                                                name: d?.name
                                            }
                                        })}
                                    />
                                ), [portState.loadingchart4, portState.chart4?.data_chart_fix, portState.chart4?.data_chart])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Partner"
                            subtitle="Jumlah proyek berdasarkan klasifikasi partner"
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        loading={portState.loadingDashboard}
                                        id="4"
                                        argumentField="state"
                                        type="stackedBar"
                                        dataSource={portAction.getChart5()}
                                        withTitle={false}
                                        showLegend={true}
                                        overlappingBehavior="stagger"
                                        onrenderLabel={(e) => `${e.value.substr(0, 7)}`}
                                        onRenderTooltip={(e) => ({ text: `${e.argumentText} ; ${e.seriesName} ; ${e.value}` })}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        LegenditemTextPosition="right"
                                        toolsExport={false}
                                        yAxisArgs={[...(portState.dataDashboard?.data_chart5_Partner || [])].map(d => {
                                            return {
                                                valueField: d?.name,
                                                name: d?.name
                                            }
                                        })}
                                    />
                                ), [portState.loadingDashboard, portState.dataDashboard?.data_chart5_Partner])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Sinergisitas"
                            subtitle="Sinergisitas pelaksanaan proyek dan fungsi-fungsi RTI"
                        >
                            {
                                React.useMemo(() => (
                                    <DoughnatDx
                                        withTitle={false}
                                        loading={portState.loadingDashboard}
                                        dataSource={portAction.getChart7()}
                                        showLegend={true}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        LegenditemTextPosition="top"
                                        toolsExport={false}
                                        onRenderLabelChart={(e) => (`${parseFloat(e.percentText).toFixed(0)}%`)}
                                        onRenderTooltip={(e) => ({ text: `${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                                        yAxisArgs={[
                                            {
                                                valueField: "val",
                                                argumentField: "state",
                                                color: null,
                                                format: null
                                            }
                                        ]}

                                    />
                                ), [portState.loadingDashboard])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-6 mb-5">
                        <CstmFilterCahrtDx
                            title="Tingkat Kesiapan Teknologi"
                            subtitle="Jumlah usulan proyek inovasi berdasarkan tingkat kesiapan teknologi"
                            option={portState.data_type_proyek_2}
                            value={portState.typeChart9}
                            onChange={(val) => portAction.getChart9(val)}
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="8"
                                        loading={portState.loadingchart9}
                                        argumentField="state"
                                        type="stackedBar"
                                        dataSource={portState.chart9?.data_chart_fix}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        yAxisArgs={[...(portState.chart9?.data_chart || [])].map(d => {
                                            return {
                                                valueField: d?.name,
                                                name: d?.name
                                            }
                                        })}
                                    />
                                ), [portState.loadingchart9, portState.chart9?.data_chart_fix, portState.chart9?.data_chart])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <CstmFilterCahrtDx
                            title="10 Besar Project Leader Terbanyak"
                            subtitle="10 Besar Project Leader Terbanyak"
                            customOption={
                                <div className='d-inline-flex'>
                                    <select className='form-control form-control-sm'
                                        onChange={(e) => portAction.getChart12(e.target.value)}
                                        value={portState.typeChart12}
                                    >
                                        {
                                            [{ name: 'All', value: '' }, ...(portAction.getFunctionOption() || [])].map((d, i) => (
                                                <option key={i} value={d.value}>{d.name}</option>
                                            ))
                                        }
                                    </select>
                                    <Link
                                        target={'_blank'}
                                        to={`/project-portfolio/tabel-dashboard-project-profile-chart12/${portState.tahun}`}
                                        className="d-flex my-auto ">
                                        <i className="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                    </Link>
                                </div>
                            }
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="9"
                                        loading={portState.loadingchart12}
                                        colorBarDifferent={true}
                                        argumentField="name"
                                        colorBarDiffirentField="name"
                                        coloBarDifferentValueField="jumlah"
                                        dataSource={[...(portState.chart12?.data_chart1_JumlUsulanProyek || [])].sort((a, b) => parseFloat(a.jumlah) - parseFloat(b.jumlah))}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        onRenderLabelChart={() => { }}
                                        onRenderTooltip={(e) => (`${e.seriesName}: ${e.valueText}`)}
                                        rotate={true}
                                    />
                                ), [portState.loadingchart12, portState.chart12?.data_chart1_JumlUsulanProyek])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="rounded p-5 bg-white customShadow pt-2 pb-2">
                            <Tab
                                withCard={false}
                                itemsTab={[
                                    {
                                        id: "cost",
                                        title: <span>Benefit Cost Analysis <i title="Perbandingan antara biaya dan manfaat terhadap proyek yang diusulkan" className="fas fa-info-circle icon-nm mx-1"></i></span>
                                    },
                                    {
                                        id: "risk",
                                        title: <span>Benefit Risk Analysis <i title="Perbandingan antara resiko dan manfaat terhadap proyek yang diusulkan" className="fas fa-info-circle icon-nm mx-1"></i></span>
                                    },
                                    {
                                        id: "intangible",
                                        title: "Intangible Value"
                                    }
                                ]}
                            >
                                <TabContent id="cost">
                                    <div className="row p-2">
                                        <div className="col-md-12">
                                            <CstmFilterCahrtDx
                                                title="Benefit Cost Analysis"
                                                subtitle=""
                                                withBorder={false}
                                                cardStyle={false}
                                                option={[{ name: 'RTI', value: '0' }, ...portState.dataProyekType].filter(d => d.value !== '4' && d.value !== '5' && d.value !== '6')}
                                                value={portState.typeChart13}
                                                onChange={(val) => portAction.getChart13(val)}
                                            >
                                                {
                                                    React.useMemo(() => (
                                                        <ChartDx
                                                            id="5"
                                                            height={300}
                                                            type="bubble"
                                                            loading={portState.loadingchart13}
                                                            dataSource={portState.chart13?.data_chart}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onRenderTooltip={(e) => ({ text: `<b>Title</b> : ${e.point.data?.title}<br/> <b>Jumlah Budget</b> : ${e.argumentText} <br/> <b>Tangible Value</b> : ${e.originalValue} <br/> <b>Jangka Waktu</b> : ${e.sizeText}` })}
                                                            onRenderLabelChart={(e) => { }}
                                                            withTitle={false}
                                                            showLegend={true}
                                                            onSeriesClick={portAction.seriesBubleClick}
                                                            toolsExport={false}
                                                            yTitle="Tangible Value"
                                                            xTitle="Total Budget"
                                                            overlappingBehavior="rotate"
                                                            rotationAngle={45}
                                                            yAxisArgs={[...(portAction.getAreaOption() || [])].map((d, i) => {
                                                                return {
                                                                    argumentField: 'jumlah_budget' + d.value,
                                                                    valueField: "tangible_angka" + d.value,
                                                                    sizeField: "jangka_waktu" + d.value,
                                                                    tagField: "area_name" + d.value,
                                                                    name: d.name,
                                                                    color: chart_color[i]

                                                                }
                                                            })}
                                                        />
                                                    ), [portState.loadingchart13, portState.chart13?.data_chart])
                                                }
                                            </CstmFilterCahrtDx>
                                        </div>
                                    </div>
                                </TabContent>
                                <TabContent id="risk">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <CstmFilterCahrtDx
                                                title="Benefit Risk Analysis"
                                                withBorder={false}
                                                cardStyle={false}
                                                option={[{ name: 'RTI', value: '0' }, ...portState.dataProyekType].filter(d => d.value !== '4' && d.value !== '5' && d.value !== '6')}
                                                value={portState.typeChart14}
                                                onChange={(val) => portAction.getChart14(val)}
                                            >
                                                {
                                                    React.useMemo(() => (
                                                        <ChartDx
                                                            id="5"
                                                            height={300}
                                                            type="bubble"
                                                            loading={portState.loadingchart14}
                                                            dataSource={portState.chart14?.data_chart}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onRenderTooltip={(e) => ({ text: `<b>Title</b> : ${e.point.data?.title}<br/> <b>Jumlah Budget</b> : ${e.argumentText} <br/> <b>Risk Priority</b> : ${e.originalValue} <br/> <b>Jangka Waktu</b> : ${e.sizeText}` })}
                                                            onRenderLabelChart={(e) => { }}
                                                            withTitle={false}
                                                            showLegend={true}
                                                            onSeriesClick={portAction.seriesBubleClick}
                                                            toolsExport={false}
                                                            yTitle="Risk Priority"
                                                            xTitle="Total Budget"
                                                            overlappingBehavior="rotate"
                                                            rotationAngle={45}
                                                            yAxisArgs={[...(portAction.getAreaOption() || [])].map((d, i) => {
                                                                return {
                                                                    argumentField: 'jumlah_budget' + d.value,
                                                                    valueField: "risk" + d.value,
                                                                    sizeField: "jangka_waktu" + d.value,
                                                                    tagField: "area_name" + d.value,
                                                                    name: d.name,
                                                                    color: chart_color[i]

                                                                }
                                                            })}
                                                        />
                                                    ), [portState.loadingchart14, portState.chart14?.data_chart])
                                                }
                                            </CstmFilterCahrtDx>
                                        </div>
                                    </div>
                                </TabContent>
                                <TabContent id="intangible">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <CstmFilterCahrtDx
                                                withBorder={false}
                                                title="Intangible value"
                                                cardStyle={false}
                                                option={[{ name: 'RTI', value: '0' }, ...portState.dataProyekType].filter(d => d.value !== '4' && d.value !== '5' && d.value !== '6')}
                                                value={portState.typeTableIn}
                                                onChange={(val) => portAction.getTangibleTable(val)}
                                            >
                                                {
                                                    React.useMemo(() => (
                                                        <GridDx
                                                            searchForm={true}
                                                            data={portState.tableIntangible?.data_table}
                                                            loading={portState.tableIntangibleLoading}
                                                            columns={columns}
                                                        >
                                                        </GridDx>
                                                    ), [portState.tableIntangible?.data_table, portState.tableIntangibleLoading])
                                                }
                                            </CstmFilterCahrtDx>
                                        </div>
                                    </div>
                                </TabContent>
                            </Tab>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TabDashboard;