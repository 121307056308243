import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { GridDx, HeaderList } from '../../components';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import DropDownButton from "devextreme-react/drop-down-button";
import SVG from "react-inlinesvg";

const column = [
    { fieldName: "created_at", caption: "Date Created", allowFiltering: false },
    { fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", allowFiltering: false },
    { fieldName: "status_dokumen_name", caption: "Status", allowFiltering: false },
    { fieldName: "berlaku_start", caption: "Mulai Berlaku", allowFiltering: false },
    { fieldName: "berlaku_end", caption: "Sampai Dengan", allowFiltering: false },
    // { fieldName: "rkap", caption: "Related RKAP", allowFiltering: false },
    { fieldName: "file_name", caption: "Dokumen", allowFiltering: false },
]

const columnMonitoring = [
    { fieldName: "created_at_form", caption: "Date Created", allowFiltering: false },
    { fieldName: "status_monitoring", caption: "Status Kerjasama", allowFiltering: false },
    { fieldName: "kendala", caption: "Kendala Kerjasama", allowFiltering: false },
    { fieldName: "dukungan", caption: "Dukungan Kerjasama", allowFiltering: false },
    { fieldName: "status_kerjasama", caption: "Status Dokumen", allowFiltering: false },
]

function KerjasamaDetail(props) {
    const param = useParams();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        if (param.id === null) {
            history.push('/error/error-v1')
        } else {
            action.getData(`/api/ext-partnership/view-2/${param?.id}`, {}, null, null, true).then((resp) => {
                if (resp.code === '00') {
                    setData_page(resp.data_page);
                }
            })
        }
    }, [])

    function customRenderMonitoring(e) {
        if (e.column.name === "created_at") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "kendala") {
            return (
                <ul>
                    {
                        [...((e.value || '').split(";") || [])].filter(d => d).map((d, i) => (
                            <li key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "dukungan") {
            return (
                <ul>
                    {
                        [...((e.value || '').split(";") || [])].filter(d => d).map((d, i) => (
                            <li key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "status_kerjasama") {
            return (
                <div>
                    {e.data?.jenis_dokumen_name} {`(${e.data?.status_dokumen_name})`}
                </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }

    function cstmCellRender(e) {
        if (e.column.name === "rkap") {
            return (
                <span className="text-wrap">{e.value}</span>
                // <ul>
                //     {
                //         [...(e.value || [])].map(d => (
                //             <li className="text-wrap p-2">{d.show_research}</li>
                //         ))
                //     }
                // </ul>
            )
        } else if (e.column.name === 'file_name') {
            return (
                <span className="text-wrap">{e.value}</span>
                // <div className="d-block text-center">
                //     <DropDownButton
                //         text=""
                //         className="btn btn-sm btn-outline-primary rounded custom-dropdown-download p-0"
                //         stylingMode="text"
                //         icon="fa fa-download icon-sm m-0 p-0"
                //         showArrowIcon={false}
                //         dropDownOptions={{ width: 250 }}
                //         displayExpr="file_name"
                //         keyExpr="id"
                //         noDataText="No File"
                //         itemRender={(e) => {
                //             return (
                //                 <>
                //                     {
                //                         e.file_name_url && (
                //                             <a
                //                                 href={`${state.base_url + e.file_name_url}`}
                //                                 target="_blank" download
                //                                 rel="noopener noreferrer"
                //                             >
                //                                 <span>{e.file_name}</span>
                //                             </a>
                //                         )
                //                     }
                //                 </>
                //             )
                //         }}
                //         items={[...(e.value || [])].filter(d => d?.file_name_url !== '' && d?.file_name_url !== null)}
                //     />                    
                // </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            />
            <Card
                className="p-0 customShadow mb-3"
            >
                <Card.Body className="p-5">
                    <div className="row px-3 mb-3">
                        <div className='col-md-1 mb-3'>
                            <div style={{ width: '78px', height: '78px' }} className="bg-light-primary ">
                                <div className="p-2 rounded bg-white svg-icon svg-icon-5x svg-icon-primary bg-light-primary">
                                    <SVG src="/media/svg/icons/Design/Difference.svg" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 mb-3'>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Judul Kerjasama</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.judul}</span>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Usulan Dari</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.usulan_dari === 1 ? "Top Down" : state.header_kerjasama?.usulan_dari === 2 ? "Bottom Up" : "" }</span>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>RKAP Berkaitan</b></div>
                                <div className="col-md">
                                    <ul>
                                        {
                                            [...(state.header_kerjasama?.kerjasama_rkaps || [])].map((d, i) => (
                                                <li key={i} style={{ fontSize: '9pt' }}>{d?.title}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Stream Business</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.stream_business_name}</span>
                                    {
                                        state.header_kerjasama?.stream_business === 7 && (
                                            <span style={{ fontSize: '9pt' }} className='d-block rounded px-3 py-2 bg-disable mt-2'>{state.header_kerjasama?.stream_business_note}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Project Cluster</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.project_cluster_name}</span>
                                    {
                                        state.header_kerjasama?.project_cluster === 5 && (
                                            <span style={{ fontSize: '9pt' }} className='d-block rounded px-3 py-2 bg-disable mt-2'>{state.header_kerjasama?.project_cluster_note}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Lokasi Proyek</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state.header_kerjasama?.lokasi_proyek}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Pertamina Expected Value</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.pertam_expected_value}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Pertamina Value Offer</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.pertam_value_offer}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Partner Expected Value</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.partner_expected_value}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Partner Value Offer</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.partner_value_offer}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Aktivitas Kerjasama</b></div>
                                <div className="col-md">
                                    <div className='d-block px-3 py-2 rounded bg-disable' style={{ fontSize: '9pt', minHeight: '50px', wordBreak: 'break-word' }}>{state?.header_kerjasama?.aktifitas_kerjasama}</div>
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Fungsi</b></div>
                                <div className="col-md">
                                    {
                                        [...(state.header_kerjasama?.kerjasama_fungsies || [])].map((d, i) => (
                                            <span className='badge badge-secondary mr-1' key={i}>{d?.chief_manager_name}</span>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="row px-3 mb-3">
                                <div className="col-md-4 text-left" style={{ fontSize: '9pt' }}><b>Tahapan</b></div>
                                <div className="col-md">
                                    <span style={{ fontSize: '9pt' }}>{state?.tahapan_jenis_dokumen} {`(${state.tahapan_status_dokumen})`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3 mb-3">
                        <div className='col-md-6'>
                            <div className="d-block mb-2" style={{ fontSize: '9pt' }}><b>Partner</b></div>
                            <div className="table-responsive">
                                <table className="table table-bordered" style={{ 'fontSize': '8pt' }}>
                                    <thead>
                                        <tr className='bg-blue'>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>#</th>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Nama Partner</th>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Strategic Advantage</th>
                                            {/* <th scope="col" style={{ 'fontSize': '8pt' }}>Company Size</th> */}
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Country</th>
                                            <th scope="col" style={{ 'fontSize': '8pt' }}>Province</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            [...(state.header_kerjasama?.kerjasama_partners || [])].map((d, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th scope="row">{i + 1}</th>
                                                        <td>{d?.nama_partner}</td>
                                                        <td className='text-right'>{d?.score_sa}</td>
                                                        <td className='text-right'>{d?.country}</td>
                                                        <td>{d?.province}</td>
                                                        {/* <td>{(p[4] || '').split('=')[1] || ''}</td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-block mb-2" style={{ fontSize: '9pt' }}><b>Partnership Scoring</b></div>
                            <div className="table-responsive">
                                <table className="table table-bordered" style={{ 'fontSize': '8pt' }}>
                                    <thead>
                                        <tr className='bg-blue'>
                                            <th colSpan={2} scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Scoring</th>
                                            <th scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='align-middle text-center font-weight-bold' rowSpan={2}>Partner Scoring</td>
                                            <td>Strategic Advantage (Average)</td>
                                            <td className='text-right'>{state?.total_sa}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Company Size</td>
                                            <td className='text-right'>{state?.total_cs}</td>
                                        </tr> */}
                                        <tr>
                                            <td className='bg-light-metal'>Total Partner Scoring</td>
                                            <td className='bg-light-metal text-right'>{state?.total_sa}</td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle text-center font-weight-bold' rowSpan={3}>Project Scoring</td>
                                            <td>Project Cluster</td>
                                            <td className='text-right'>{state?.total_pc}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Partnership in the Topic Area</td>
                                            <td className='text-right'>{state?.total_tpta}</td>
                                        </tr>
                                        <tr>
                                            <td className='bg-light-metal'>Total Project Scoring</td>
                                            <td className='bg-light-metal text-right'>{state?.total_pc_tpta}</td>
                                        </tr>
                                        <tr className='bg-light-blue'>
                                            <td colSpan={2} className='text-center'>Partnership Score</td>
                                            <td className='text-right'>{state?.total_sa_cs_pc_tpta}</td>
                                        </tr>
                                        <tr className='bg-light-blue'>
                                            <td colSpan={3} className='text-center'>{`Recommendation : "${state?.translation_scoring}"`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="p-0 customShadow"
                style={{ marginBottom: '50px' }}
            >
                <Card.Body className="p-5">
                    <div className="row mx-1 mt-5">
                        <div className="col-md-12">
                            <div className='d-flex w-100 border-bottom justify-content-between pb-1 mb-2'>
                                <span className='Dokumen Kerjasama' style={{ fontSize: '12pt', fontWeight: 700 }}>Dokumen Kerjasama</span>
                            </div>
                            <GridDx
                                className='custom-style'
                                searchForm={false}
                                data={state.tabel_dokumen}
                                cellRender={cstmCellRender}
                                columns={column}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}

                                currentPage
                                show
                            >

                            </GridDx>
                        </div>
                    </div>
                    <div className="row mx-1 mt-5">
                        <div className="col-md-12">
                            <div className='d-flex w-100 border-bottom justify-content-between pb-1 mb-2'>
                                <span className='Dokumen Kerjasama' style={{ fontSize: '12pt', fontWeight: 700 }}>Monitoring Kerjasama</span>                                
                            </div>
                            <GridDx
                                className='custom-style'
                                searchForm={false}
                                data={state.tabel_monitoring}
                                cellRender={customRenderMonitoring}
                                columns={columnMonitoring}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                history.length > 2 &&
                <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default KerjasamaDetail;