import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import Swal from "sweetalert2";
import useFetch from "../../modules/useFetch";
import { Modal } from "react-bootstrap";

const beContext = createContext({
    beAction: {},
    beState: {}
});

const column_be = [
    { fieldName: 'creator_name', caption: 'Pengirim', allowFiltering: true },
    { fieldName: 'recipient_name', caption: 'Penerima', allowFiltering: true, width: 150 },
    { fieldName: 'title', caption: 'Judul', allowFiltering: false },
    { fieldName: 'photo_location', caption: 'Image' },
    { fieldName: 'created_at', caption: 'Created at', allowFiltering: false },
]

export function BeProvider({ children }) {
    const { doGet, doPost, doPostMultiple } = useFetch();
    const [data_page, setData_page] = useState({
        "page_title": "",
        "page_breadcrumbs": ""
    })
    const [loadingBc, setLoadingBc] = useState(false);
    const [dataViewBc, setDataViewBc] = useState({});
    const [clearBc, setClearBc] = useState(false);
    async function getMasterBc() {
        setLoadingBc(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/people-bcemail/view',
            });
            if (resp?.code === '00') {
                setData_page(resp?.data_page);
                setDataViewBc(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoadingBc(false);
    }
    async function doSendBc(data = {}) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `/api/people-bcemail/create`,
                data: {
                    ...data
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { setClearBc(true) })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [data_log, setData_log] = useState({});
    const [loadinglog, setLoadingLog] = useState(false);
    const [modal, setModal] = useState({
        show: false,
        url: ''
    });
    async function getLogBe() {
        try {
            setLoadingLog(true);
            Swal.fire({
                title: 'Please Wait',
                html: 'Retrieving data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doGet({
                url: 'api/people-bcemail/table',
            });
            if (resp?.code === "00") {
                setData_log(resp.data);
            }
            setLoadingLog(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
    }
    function onSendBc(data = {}) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Kirim Broadcast?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validation(data);
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendBc(data);
                }
                return true;
            } else {
                return true;
            }
        })
    }
    function validation(data = {}) {
        var message = ""
        var isValid = true;
        if ([...(data?.listUserPersonal || [])].length < 1 &&
            [...(data?.listGroupFunction || [])].length < 1) {
            isValid = false;
            message = "Silahkan pilih penerima broadcast"
        } else if (data?.title === "") {
            message = "Judul tidak boleh kosong";
            isValid = false;
        } else if (data?.photo_filename === "") {
            message = "Silahkan Upload Image";
            isValid = false;
        }
        return {
            message,
            isValid
        }
    }
    function cellRender(rowInfo) {
        if (rowInfo.column?.name === 'photo_location') {
            return (
                <div className="text-wrap">
                    <button className="btn btn-tm btn-primary"
                        onClick={() => setModal({
                            show: true,
                            url: rowInfo.value
                        })}
                    >Show</button>
                </div>
            )
        } else {
            return (
                <div className="text-wrap">
                    {rowInfo.value}
                </div>
            )
        }
    }
    return <beContext.Provider
        value={{
            beAction: {
                onSendBc,
                getMasterBc,
                cellRender,
                getLogBe
            },
            beState: {
                data_page,
                dataViewBc,
                loadingBc,
                column_be,
                clearBc,
                loadinglog,
                data_log
            }
        }}
    >
        <Modal show={modal.show} onHide={() => setModal({ show: false, url: "" })} size="lg">
            <Modal.Body>
                <img src={modal.url} width={'100%'}></img>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-secondary float-right"
                    onClick={() => setModal({ show: false, url: "" })}
                >Close</button>
            </Modal.Footer>
        </Modal>
        {children}
    </beContext.Provider>
}

export default function useBroadcastEmail() {
    return useContext(beContext);
}