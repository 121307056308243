import React from 'react';
import useProjectProfile from './useProjectProfile';

const ProjectProfileAdmin = () => {
    function getStage(stage) {
        switch (stage) {
            case "I":
                return "Stage I (Idea generation)";
            case "II":
                return "Stage II (Concept Proof)";
            case "III":
                return "Stage III (Prototype Development)";
            case "IV":
                return "Stage IV (Pilot run & Testing)";
            case "V":
                return "Stage V / TRL : 8 (System Complete and Qualified)";
            case "VI":
                return "Stage VI / TRL : 9 (Proven in Operational Environment)";
            default:
                return "";
        }
    }
    const { pfState, pfAction } = useProjectProfile();
    return (
        <div className="page-content-pp">
            {/* row 1 */}
            <div className="row">
                <div className="col" style={{ paddingRight: "20px" }}>
                    <span className="page-title-pp">Judul Riset</span>
                    <p className="font-9">
                        {pfAction.getObject(pfState.data?.data_view_header)?.title}
                    </p>
                    <span className="page-title-pp">Pain Point</span>
                    <p className="font-9">
                        {pfAction.getObject(pfState.data?.data_view_header)?.description}
                    </p>

                    <span className="page-title-pp">Commercialization /Implementation- Objective</span>
                    <p className="font-9">
                        {pfAction.getObject(pfState.data?.data_view_header)?.purpose}
                    </p>
                </div>
            </div>
            {/* end row 1 */}
            {/* row 2 */}
            <div className="row">
                {/* col-10 row 2 */}
                <div className="col-9">
                    {/*  row 2-1 */}
                    <div className="row">
                        {/* col-4 1 row 2-1 */}
                        <div className="col-4">
                            <span className="page-title-pp">
                                Jangka Waktu
                            </span>
                            <hr />
                            <span className="font-9">{pfAction.getObject(pfState.data?.data_view_header)?.jangka_waktu_real || 1} Tahun</span>
                            <span className="page-title-pp">
                                Dukungan Operasional
                            </span>
                            <hr />
                            <ul>
                                {
                                    pfState.data?.data_view_header?.duk_operasional_opt_0_str && <li className="font-9">{pfState.data?.data_view_header?.duk_operasional_opt_0_str}</li>
                                }
                                {
                                    pfState.data?.data_view_header?.duk_operasional_opt_1_str && <li className="font-9">{pfState.data?.data_view_header?.duk_operasional_opt_1_str}</li>
                                }
                                {
                                    pfState.data?.data_view_header?.duk_operasional_opt_2_str && <li className="font-9">{pfState.data?.data_view_header?.duk_operasional_opt_2_str}</li>
                                }
                            </ul>
                            <br />
                            <b className="font-9">Detail Dukungan Operasional :</b>

                            <br />
                            <span className="font-9 text-muted">
                                {pfState.data?.data_view_header?.duk_operasional_ket}
                            </span>
                            <span className="page-title-pp">
                                Keyword
                            </span>
                            <hr />
                            {
                                pfState.data?.data_view_keywords?.map((keyword, l) => (

                                    <span key={l} className="font-9 badge"
                                        style={{
                                            background: "#93be4b",
                                            padding: "3px",
                                            marginRight: "3px",
                                            borderradius: "5px",
                                            fontweight: "700",
                                            marginright: "10px"

                                        }}>
                                        {keyword.name}
                                    </span>
                                ))
                            }
                            <span className="page-title-pp">
                                Keahlian yang dibutuhkan
                            </span>
                            <hr />
                            <ul>
                                {
                                    pfState.data?.data_view_expertise_needed?.map((need, k) => (
                                        <li key={k} className="font-9">
                                            {need.technical_competency}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {/* end col-4 1 row 2-1 */}
                        {/* col-4 2 row 2-1 */}
                        <div className="col-4">
                            <span className="page-title-pp">
                                Team Research
                            </span>
                            <hr />
                            <table style={{ width: "100%", fontSize: "9pt" }}>
                                <tbody>
                                    {
                                        pfState.data?.data_view_team_members?.map((team, i) => (
                                            <tr key={i} style={{ borderBottom: "1px solid black", borderTop: "1px solid black", width: "100%" }}>
                                                {/* <td>
                                                    <img src="~/uploads/spiirit/profil/@ViewBag.research.Owner().user_profile().photo_location "
                                                        className="m--img-rounded m--marginless m--img-centered"
                                                        height="40px" width="40px"
                                                        style={{ objectFit: "cover" }}
                                                        alt=""
                                                    />
                                                </td> */}
                                                <td style={{ padding: 3 }}><span className="normal font-9">{team.name}</span> </td>
                                                <td style={{ padding: 3 }}><span className="normal font-9">{team.function_name} ({team.function_short_name})</span></td>
                                                <td style={{ padding: 3 }}><span className="badge-leader font-9">{team.team_role}</span></td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        {/* end col-4 2 row 2-1 */}
                        {/* col-4 3 row 2-1 */}
                        <div className="col">
                            <span className="page-title-pp">
                                Ruang Lingkup Riset
                            </span>
                            <hr />
                            <span className="font-9">{pfState.data?.data_view_header?.focus}</span>
                            <span className="page-title-pp">
                                Klasifikasi Riset
                            </span>
                            <hr />
                            <table border="0" style={{ border: "0px !important", width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "0px !important", padding: "2px !important" }}><span className="normal"> Area Riset </span></td>
                                        <td style={{ border: "0px !important", padding: "2px !important" }}><span className="normal"> 	{pfAction.getObject(pfState.data?.data_view_header)?.area_name || 1} </span></td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "0px !important", padding: "2px !important" }}><span className="normal"> Kategori Riset </span></td>
                                        <td style={{ border: "0px !important", padding: "2px !important" }}><span className="normal">{pfAction.getObject(pfState.data?.data_view_header)?.research_category_name || 1} </span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <span className="page-title-pp">
                                Proyek Berkaitan
                            </span>
                            <hr />
                            {
                                pfState.data?.data_view_related_research?.map((rel, p) => (
                                    <li key={p} className="font-9">
                                        {rel.title}
                                    </li>
                                ))

                            }
                            <span className="page-title-pp">
                                Metode Riset
                            </span>
                            <hr />
                            <div className="form-check">

                                <input className="form-check-input" id="radioInput" type="radio" value="I" disabled="disabled" checked={true} />
                                <label className="form-check-label">{pfAction.getObject(pfState.data?.data_view_header)?.method_name}</label>
                            </div>
                            <span className="page-title-pp">
                                Value Creation
                            </span>
                            <hr />
                            {
                                pfAction.getObject(pfState.data?.data_view_value_creations)?.pendapatan && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <span className="header-st">
                                                    <i style={{ color: "#7ebf40" }} className="fa fa-caret-right"></i> Pendapatan
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ paddingLeft: "15px" }}>
                                            <div className="col">
                                                {pfAction.getObject(pfState.data?.data_view_value_creations)?.pendapatan} {pfAction.getObject(pfState.data?.data_view_value_creations)?.sat_pendapatan_name}
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {
                                pfAction.getObject(pfState.data?.data_view_value_creations)?.produksi && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <span className="header-st">
                                                    <i style={{ color: "#7ebf40" }} className="fa fa-caret-right"></i> Production
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ paddingLeft: "15px" }}>
                                            <div className="col">
                                                {pfAction.getObject(pfState.data?.data_view_value_creations)?.produksi} {pfAction.getObject(pfState.data?.data_view_value_creations)?.sat_produksi_name}
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {
                                pfAction.getObject(pfState.data?.data_view_value_creations)?.efisiensi && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <span className="header-st">
                                                    <i style={{ color: "#7ebf40" }} className="fa fa-caret-right"></i> Efesiensi
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ paddingLeft: "15px" }}>
                                            <div className="col">
                                                {pfAction.getObject(pfState.data?.data_view_value_creations)?.efisiensi} {pfAction.getObject(pfState.data?.data_view_value_creations)?.sat_efisiensi_name}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            <div className="row">
                                <div className="col">
                                    <span className="header-st">
                                        <i style={{ color: "#7ebf40" }} className="fa fa-caret-right"></i> Intangible Value
                                    </span>
                                </div>
                            </div>
                            <div className="row" style={{ paddingLeft: "15px" }}>
                                <div className="col">
                                    {pfAction.getObject(pfState.data?.data_view_value_creations)?.intangible}
                                </div>
                            </div>

                            {
                                pfAction.getObject(pfState.data?.data_view_header)?.project_type === 1 && (
                                    <>
                                        <span className="page-title-pp">
                                            Tahapan Riset
                                        </span>
                                        <hr />
                                        <div className="form-check">

                                            <input className="form-check-input" id="radioInput" type="radio" name="stage" value="I" disabled="disabled" checked={true} />
                                            <label className="form-check-label">TRL {pfAction.getObject(pfState.data?.data_view_header)?.stage}</label>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                    {/* end row 2-1 */}
                </div>
                {/* end col-10 row 1 */}
                <div className="col-3">
                    <span className="page-title-pp">
                        Total Estimate Cost
                    </span>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <div className="estimate">
                                $ {pfAction.getObject(pfState.data?.data_view_BudgetTotal)?.jumlah} <span style={{ fontSize: "10pt" }}> RIBU USD </span>
                            </div>
                        </div>
                    </div>
                    {
                        pfState.data?.data_view_BudgetTahunan?.map((bu, k) => (
                            <div className="row" key={k}>
                                <div className="col">
                                    <i style={{ color: "#7ebf40" }} className="fa fa-caret-right"></i> {bu.year}
                                    <div className="estimate" style={{
                                        background: "#a3d354",
                                        marginLeft: "25px"
                                    }}>
                                        $ {bu.jumlah} <span style={{ fontSize: "10pt" }}> RIBU USD </span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }


                </div>
            </div>
            {/* end row 2 */}
        </div>
    );
}

export default ProjectProfileAdmin;