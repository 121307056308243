import { toNumber, toString } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap'
import { ChartDx, CstmFilterCahrtDx, DoughnatDx, ExportPdf } from '../../components';
import { formatter } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';

function TabDashoard({ reporting = false }) {
    const [state, dispatch, action] = useGlobalState();
    const [select, setSelect] = useState(0);
    const [dsCurve, setDsCurve] = useState([]);
    const [dsTahun, setDsTahun] = useState([]);
    const [chart3, setChart3] = useState([]);
    const [chart4, setChart4] = useState([]);
    const [opt, setOpt] = useState('0');
    const refEx = useRef();
    useEffect(() => {
        getData(select);
    }, []);

    function getData(select) {
        action.getData('/api/ext-product/dashboard', {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                getScurve(select, opt, resp.data.data_TipeCurve, resp.data.data_TipeCurve0);
                setDsTahun(resp.data.data_TipeTahun);
                setChart3(resp.data?.data_productOveral);
                setChart4(resp.data?.data_pCustomerOveral);
                action.setForm('pro', resp.data_page);
            }
        })
    }
    function getScurve(select, type, data, data1) {
        setOpt(type);
        var tempdata = [...(data || [])];
        if (select === 0) {
            if (type !== '0') {
                tempdata = [...(data || [])].filter(d => d.id?.toString() === type);
                const dist = [...new Set([...(data || [])].map(d => d.year).filter(d => d !== null))].sort();
                var tdata = dist.map(d => {
                    const jumlah = tempdata.filter(x => x.year === d).reduce((tot, x) => tot = tot + (((isNaN(Number(x.total))) ? 0 : Number(x.total))), 0);
                    return {
                        year: d?.toString(),
                        jumlah: jumlah
                    }
                })
                setDsCurve(tdata);
            } else {
                tempdata = [...(data1 || [])];
                setDsCurve(tempdata.map(d => {
                    return {
                        year: d.year?.toString(),
                        jumlah: d?.total
                    }
                }));
            }
        } else {
            if (type !== '0') {
                tempdata = [...(data || [])].filter(d => d.id?.toString() === type).map(d => {
                    return {
                        ...d,
                        triwulan: d.triwulan?.toString()
                    }
                });
                setDsCurve(tempdata);
            } else {
                setDsCurve([...(data1 || [])].map(d => {
                    return {
                        ...d,
                        triwulan: d.triwulan?.toString()
                    }
                }))
            }
        }
    }
    function selectChange(val) {
        setSelect(val)
        if (val === 1) {
            getByYearly(val, '')
        }
        if (val === 0) {
            getData(val);
        }

    }
    function getByYearly(select, year) {
        action.getData('/api/ext-product/dashboardMonth', {
            year: year
        }, null, null, true).then((resp) => {
            if (resp.code === '00') {
                getScurve(select, '0', resp.data.data_TipeCurve, resp.data.data_TipeCurve0);
                setChart3(resp.data?.data_productOveral);
                setChart4(resp.data?.data_pCustomerOveral);
                setDsTahun(resp.data.data_TipeTahun);
            }
        })
    }
    function getPercentage(lastMonth = 0, thisMonth = 0) {
        var naik = 0;
        var icon = '';
        var nil = 0;
        if (lastMonth > thisMonth) {
            naik = 1; //turun
            icon = 'fas fa-caret-down icon-nm text-danger';
            nil = lastMonth === 0 ? 0 : ((thisMonth - lastMonth) / lastMonth) * 100;
        }
        if (thisMonth > lastMonth) {
            naik = 2; //naik
            icon = 'fas fa-caret-up icon-nm text-success';
            nil = thisMonth === 0 ? 0 : ((lastMonth - thisMonth) / thisMonth) * 100;
        }
        return {
            naik,
            icon,
            nil
        }
    }
    return (
        <>
            {
                reporting && (
                    <>
                        <div className="row mb-5">
                            <div className="col-md-12 text-right">
                                <ExportPdf
                                    targetRef={refEx}
                                    scale={0.8}
                                    fileName={`Product Dashboard-${moment(new Date()).format('YYYY-MM-DD')}`}
                                >
                                    <button className="btn btn-sm btn-primary">
                                        Export PDF
                                    </button>
                                </ExportPdf>
                            </div>
                        </div>
                        <h5 className="mb-5"><b>Product & Contribution</b></h5>
                    </>
                )
            }
            <div ref={refEx}>
                <div className="row mb-5">
                    <div className="col-md-3 mb-5">
                        <Card
                            className="p-0 customShadow h-100"
                        >
                            <Card.Body className="p-5">
                                <div className="background_2"></div>
                                <div className="row mb-5">
                                    <div className="col-2">
                                        <span className="d-inline-flex justify-content-center align-items-center rounded customShadow bg-primary" style={{ width: '33px', height: '33px' }}>
                                            <i className="fas fa-dollar-sign text-white"></i>
                                        </span>
                                    </div>
                                    <div className="col-10">
                                        <div className="pl-3 text-dark">
                                            <span className="d-block box-header-product">Total Kontribusi Thn. {state.data_Tahun}</span>
                                            {/* <span className="d-block box-header-product">{state.data_Tahun}</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className='d-block mb-1'><b> </b></div>
                                    <span><b>$ {formatter.format(state.data_SalesThisYear || 0)}  <sup><i className={`ml-5 ${getPercentage(state.data_SalesLastYear, state.data_SalesThisYear).icon} `}></i> {parseFloat(getPercentage(state.data_SalesLastYear, state.data_SalesThisYear).nil || 0).toFixed(2)}%</sup></b></span>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 mb-5">
                        <Card
                            className="p-0 customShadow background_1 h-100"
                        >
                            <Card.Body className="p-5">
                                <div className="row mb-5">
                                    <div className="col-2">
                                        <span className="d-inline-flex justify-content-center align-items-center rounded customShadow bg-white" style={{ width: '33px', height: '33px' }}>
                                            <i className="fas fa-dollar-sign text-dark"></i>
                                        </span>
                                    </div>
                                    <div className="col-10">
                                        <div className="pl-3 text-dark">
                                            <span className="d-block box-header-product">Total Kontribusi Q{state.data_Triwulan} - {state.data_Tahun}</span>
                                            {/* <span className="d-block box-header-product">{state.monthNow} {state.data_Tahun}</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className='d-block mb-1'><b> </b></div>
                                    <span><b>$ {formatter.format(state.data_SalesThisMonth || 0)} <sup><i className={`ml-5 ${getPercentage(state.data_SalesLastMonth, state.data_SalesThisMonth).icon} `}></i> {parseFloat(getPercentage(state.data_SalesLastMonth, state.data_SalesThisMonth).nil || 0).toFixed(2)}%</sup></b></span>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 mb-5">
                        <Card
                            className="p-0 customShadow background_3 h-100"
                        >
                            <Card.Body className="p-5">
                                <div className="row">
                                    <div className="col-2">
                                        <span className="d-inline-flex justify-content-center align-items-center rounded customShadow bg-dark" style={{ width: '33px', height: '33px' }}>
                                            <i className="fas fa-shopping-cart text-white"></i>
                                        </span>
                                    </div>
                                    <div className="col-10">
                                        <div className="pl-3 text-dark">
                                            <span className="d-block box-header-product">TOP Customer Q{state.data_Triwulan} - {state.data_Tahun}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <span className='d-block mb-1'><b>{state.data_topcustomer_name}</b></span>
                                    <span><b>$ {formatter.format(state.data_topcustomer_total || 0)}</b></span>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 mb-5">
                        <Card
                            bg="success"
                            className="p-0 customShadow background_4 h-100"
                        >
                            <Card.Body className="p-5">
                                <div className="row">
                                    <div className="col-2">
                                        <span className="d-inline-flex justify-content-center align-items-center rounded customShadow bg-white" style={{ width: '33px', height: '33px' }}>
                                            <i className="fas fa-briefcase text-dark"></i>
                                        </span>
                                    </div>
                                    <div className="col-10">
                                        <div className="pl-3 text-dark">
                                            <span className="d-block box-header-product">TOP Produk Q{state.data_Triwulan} - {state.data_Tahun}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <span className='d-block mb-1'><b>{state.data_topproduct_name}</b></span>
                                    <span><b>$ {formatter.format(state.data_topproduct_total || 0)}</b></span>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <Card
                    className="p-0 customShadow"
                >
                    <Card.Body className="p-5">
                        <div className="d-block text-center mb-5">

                            <button className={`btn btn-sm mx-1 ${select === 0 ? 'btn-primary' : 'btn-secondary'}`} onClick={() => selectChange(0)}>
                                Year on Year
                            </button>
                            <button className={`btn btn-sm mx-1 ${select === 1 ? 'btn-primary' : 'btn-secondary'}`} onClick={() => selectChange(1)}>
                                Yearly
                            </button>
                            <div className="d-inline-flex">
                                {
                                    select === 1 && (
                                        <select className="form-control form-control-sm mx-1" value={toString(state.data_year_select)} onChange={(e) => getByYearly(select, e.target.value)}>
                                            {
                                                [...(state.data_Year || [])].map((d, i) => (
                                                    <option key={i} value={d.year}>{d.year}</option>
                                                ))
                                            }
                                        </select>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 mb-5">
                                <CstmFilterCahrtDx
                                    cardStyle={false}
                                    title={"Value Creation"}
                                    subtitle={select === 0 ? "Kontribusi Produk RTI Per Tahun (USD)" : "Kontribusi Produk RTI per Triwulan (USD)"}
                                    value={opt}
                                    option={[{ name: 'All', value: 0 }, ...[...(state.data_TipeTahun || [])].map(d => {
                                        return {
                                            name: d.name,
                                            value: d.id
                                        }
                                    })]}
                                    onChange={(val) => {
                                        if (select === 0) {
                                            getScurve(select, val, state.data_TipeCurve, state.data_TipeCurve0);
                                        }
                                        if (select === 1) {
                                            getScurve(select, val, state.data_TipeCurve, state.data_TipeCurve0);
                                        }
                                    }}
                                >
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                dataSource={dsCurve}
                                                type="spline"
                                                argumentField={select === 0 ? 'year' : 'triwulan'}
                                                withTitle={false}
                                                showLegend={select === 0 ? false : true}
                                                toolsExport={false}
                                                LegendverticalAlignment="bottom"
                                                LegendhorizontalAlignment="center"
                                                LegenditemTextPosition="right"
                                                onrenderLabel={(e) => select === 0 ? e?.valueText : `Q${e?.valueText}`}
                                                onRenderLabelChart={function (e) { }}
                                                onRenderTooltip={(e) => ({ text: `${select === 0 ? 'Tahun ' : 'Q'}${e.argumentText} : $ ${formatter.format(e?.value)}` })}
                                                yAxisArgs={
                                                    select === 0 ? [
                                                        {
                                                            name: state.data_yearNow,
                                                            valueField: "jumlah",
                                                        }
                                                    ] :
                                                        [
                                                            {
                                                                name: `Last year (${state.data_last_year})`,
                                                                valueField: `total_1`,
                                                                color: '#bdbfc9'
                                                            },
                                                            {
                                                                name: state.data_year_select,
                                                                valueField: `total_2`,
                                                                color: '#006CB8'
                                                            }
                                                        ]
                                                }
                                            >

                                            </ChartDx>
                                        ), [dsCurve, select])
                                    }
                                </CstmFilterCahrtDx>
                            </div>
                            <div className="col-md-6 mb-5">
                                <CstmFilterCahrtDx
                                    cardStyle={false}
                                    title="Value Creation by Product Type"
                                    subtitle={select === 0 ? "Kontribusi berdasar tipe produk sepanjang tahun" : "Kontribusi Per Tahun Berdasarkan Tipe Product"}
                                >
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                id="1"
                                                argumentField="name"
                                                showLegend={select === 0 ? false : true}
                                                dataSource={dsTahun}
                                                onrenderLabel={(e) => `${e.value.substr(0, 7)}`}
                                                overlappingBehavior="stagger"
                                                LegendverticalAlignment="bottom"
                                                LegendhorizontalAlignment="center"
                                                LegenditemTextPosition="right"
                                                withTitle={false}
                                                toolsExport={false}
                                                onRenderLabelChart={(e) => ``}
                                                onRenderTooltip={(e) => ({ text: `${e.argumentText}: $ ${formatter.format(e.value)}` })}
                                                yAxisArgs={
                                                    select === 0 ? [
                                                        {
                                                            valueField: "total",
                                                        }
                                                    ] : [
                                                        {
                                                            name: `Last year (${state.data_last_year})`,
                                                            valueField: `total_1`,
                                                            color: '#bdbfc9'
                                                        },
                                                        {
                                                            name: state.data_year_select,
                                                            valueField: `total_2`,
                                                            color: '#006CB8'
                                                        }
                                                    ]
                                                }
                                            />
                                        ), [dsTahun, select])
                                    }
                                </CstmFilterCahrtDx>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 mb-5">
                                <CstmFilterCahrtDx
                                    cardStyle={false}
                                    title="TOP Product Type"
                                    subtitle="Produk yang memiliki kontribusi tertinggi"
                                >
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                id="9"
                                                colorBarDifferent={true}
                                                argumentField="name"
                                                colorBarDiffirentField="name"
                                                coloBarDifferentValueField="total"
                                                dataSource={[...(chart3 || [])].sort((a, b) => parseFloat(a.total) - parseFloat(b.total))}
                                                withTitle={false}
                                                showLegend={false}
                                                toolsExport={false}
                                                onRenderLabelChart={() => { }}
                                                onRenderTooltip={(e) => ({ text: `${e.seriesName}: $ ${toNumber(e.valueText).toFixed(2)}` })}
                                                rotate={true}
                                            />
                                        ), [chart3])
                                    }
                                </CstmFilterCahrtDx>
                            </div>
                            <div className="col-md-6 mb-5">
                                <CstmFilterCahrtDx
                                    cardStyle={false}
                                    title="TOP Customer"
                                    subtitle="Customer yang memiliki pembelian tertinggi (USD)"
                                >
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                id="10"
                                                colorBarDifferent={true}
                                                argumentField="name"
                                                colorBarDiffirentField="name"
                                                coloBarDifferentValueField="total"
                                                dataSource={[...(chart4 || [])].sort((a, b) => parseFloat(a.total) - parseFloat(b.total))}
                                                withTitle={false}
                                                showLegend={false}
                                                toolsExport={false}
                                                onRenderLabelChart={() => { }}
                                                onRenderTooltip={(e) => ({ text: `${e.seriesName}: $ ${toNumber(e.valueText).toFixed(2)}` })}
                                                rotate={true}
                                            />
                                        ), [chart4])
                                    }
                                </CstmFilterCahrtDx>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default TabDashoard;