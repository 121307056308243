import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { Modal, GridDx } from '../../../components';
import ModalAbout from '../../settingMasterAbout/modalAbout';
import useSettingApplication from '../useSettingAplication';

const dummy = [
    {
        date: '01 Feb 2021',
        version: '2.1.2',
        update: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero'
    }
]
function TabAbout(props) {
    const tableRef = useRef();
    const { setAppAction, setAppState } = useSettingApplication();

    useEffect(() => {
        setAppAction.getMasterAbout();
    },[])
    return (
        <div>
            <Modal
                name="modalabout"
                baseColor={false}
            >
                <ModalAbout mode={setAppState.modeAb} data={setAppState.dataEditAb} />
            </Modal>
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <GridDx
                                data={dummy}
                                searchForm={true}
                                toolbar={
                                    <>
                                        <button className="btn btn-sm btn-success mx-1"
                                            onClick={() => setAppAction.addAbout()}
                                        >
                                            <i className="fa fa-plus icon-nm text-white"></i>
                                            Add About
                                        </button>
                                    </>
                                }
                                ref={tableRef}
                                withAction={true}
                                columns={setAppState.columns_about}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                actions={[
                                    {
                                        name: "Edit",
                                        icon: "fa fa-pencil-alt icon-nm text-success",
                                        action: (v, c) => setAppAction.editAbout(v)
                                    },
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => { }
                                    }
                                ]}
                                show

                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>

        </div>
    );
}

export default TabAbout;