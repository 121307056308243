import Tags from '@yaireo/tagify/dist/react.tagify';
import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { captcha_client_key } from '../../../app.config';
import { FileUploadDx, FormResearch, Modal, RadioGrp } from '../../../components';
import { sector_source } from '../../../components/utility/utility';
import { useGlobalState } from '../../../contexts/useGlobalState';
import useIndexLandPage from '../use_index';
import useCreateResearch, { CrtResProvider } from './useCreateResearch';
import { useHistory } from 'react-router-dom'

function Index(props) {
    const { indAction } = useIndexLandPage();
    const { crtResAction, crtResState } = useCreateResearch();
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    useEffect(() => {
        indAction.setDisplayLayout(false);
        crtResAction.getDataArea();
    }, [])
    return (
        <div className='container my-5'>
            <Modal
                name="crtResearchViewTable"
                baseColor={false}

            >
                <div className="customShadow">
                    <img className="w-100" src='/images/trl.jpg'></img>
                </div>
            </Modal>
            <div className='card customShadow'>
                <div className='card-body'>
                    <h3 className='text-center font-weight-bolder' style={{
                        marginBottom: '70px'
                    }}>Create New Ideation (External)</h3>
                    <FormResearch
                        formLists={[
                            {
                                rows: 1,
                                header: true,
                                name: <>Initiator Information <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "text",
                                        label: "Nama",
                                        onChange: (val) => crtResAction.handleChange('name_initiator', val),
                                        value: crtResState.formData?.name_initiator,
                                        disabled: false
                                    },
                                    {
                                        label: "Lembaga/Univ",
                                        type: "custom",
                                        component: (
                                            <div className='row mb-5'>
                                                <div className='col-md-12'>
                                                    <RadioGrp
                                                        onChange={(val) => crtResAction.handleChange('from', val)}
                                                        value={crtResState.formData?.from}
                                                        modeRow={true}
                                                        data={[{ label: "Lembaga", value: 'Lembaga' }, { label: "Universitas", value: 'Universitas' }]}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        label: crtResState.formData?.from === 'Universitas' ? 'Nama Universitas' : 'Nama Lembaga',
                                        type: "text",
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('name_institution', val),
                                        value: crtResState.formData?.name_institution,
                                    },
                                    ...(crtResState.formData?.from === 'Universitas' ? [{
                                        label: "Fakultas",
                                        type: "text",
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('faculty', val),
                                        value: crtResState.formData?.faculty,
                                    }] : []),
                                    ...(crtResState.formData?.from === 'Lembaga' ? [{
                                        label: "Jabatan",
                                        type: "text",
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('department', val),
                                        value: crtResState.formData?.department,
                                    }] : []),
                                    {
                                        label: "No Telepon/HP",
                                        type: "custom",
                                        component: (
                                            <div className='row mb-3'>
                                                <div className='col-12'>
                                                    <input type='tel' className='form-control form-control-sm'
                                                        onChange={(val) => crtResAction.handleChange('telp', val.target.value)}
                                                        value={crtResState.formData?.telp}
                                                    ></input>
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        label: "Email",
                                        type: "text",
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('email', val),
                                        value: crtResState.formData?.email,
                                    }
                                ]
                            },
                            {
                                rows: 1,
                                header: true,
                                name: <>Research Background <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "textarea",
                                        label: "Judul",
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('title', val),
                                        value: crtResState.formData?.title,
                                    },
                                    {
                                        label: "Deskripsi/Latar Belakang",
                                        type: "textarea",
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('description', val),
                                        value: crtResState.formData?.description,
                                    },
                                    {
                                        label: "Tujuan",
                                        type: "textarea",
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('purpose', val),
                                        value: crtResState.formData?.purpose,
                                    },
                                    {
                                        label: "Kata Kunci",
                                        type: "custom",
                                        component: (
                                            <Tags
                                                value={crtResState.formData?.tags}
                                                onChange={(e) => crtResAction.handleChange('tags', e.detail.value)}
                                                className='mb-5'
                                            />
                                        )
                                    }
                                ]
                            },
                            {
                                rows: 1,
                                header: true,
                                name: <>Research Classification <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "select",
                                        label: "Sektor",
                                        options: [{ name: '- Pilih Sector -', value: '' }, ...sector_source],
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('sector', val),
                                        value: crtResState.formData?.sector,
                                    },
                                    {
                                        type: "select",
                                        label: "Area Riset",
                                        options: [{ name: '- Pilih Area -', value: '' }, ...[...(crtResState.dataArea || [])].map(d => ({
                                            name: d?.name,
                                            value: d?.id
                                        }))],
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('area', val),
                                        value: crtResState.formData?.area,
                                    },
                                    {
                                        type: "select",
                                        label: "Kategori Riset",
                                        options: [{ name: '- Pilih Category -', value: '' }, ...[...(crtResState.dataCategory || [])].map(d => ({
                                            name: d?.name,
                                            value: d?.id
                                        }))],
                                        disabled: false,
                                        onChange: (val) => crtResAction.handleChange('research_category_id', val),
                                        value: crtResState.formData?.research_category_id,
                                    },
                                ]
                            },
                            {
                                rows: 1,
                                header: true,
                                name: <>Indikator TRL <span className="text-danger">(*)</span><span role={'button'} className="badge badge-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('crtResearchViewTable')}>Lihat Table TRL</span></>,
                                childs: [
                                    {
                                        type: 'custom',
                                        withLabel: false,
                                        component: (
                                            <div className="d-inline-flex">
                                                <RadioGrp
                                                    value={crtResState.formData?.stage}
                                                    onChange={(e) => crtResAction.handleChange('stage', e)}
                                                    data={[
                                                        { label: 'TRL I', value: 'I', disabled: false },
                                                        { label: 'TRL II', value: 'II', disabled: false },
                                                        { label: 'TRL III', value: 'III', disabled: false },
                                                        { label: 'TRL IV', value: 'IV', disabled: false },
                                                        { label: 'TRL V', value: 'V', disabled: false },
                                                        { label: 'TRL VI', value: 'VI', disabled: false },
                                                        { label: 'TRL VII', value: 'VII', disabled: false },
                                                        { label: 'TRL VIII', value: 'VIII', disabled: false },
                                                        { label: 'TRL IX', value: 'IX', disabled: false }
                                                    ]}
                                                />
                                            </div>
                                        )
                                    },
                                ]
                            },
                            {
                                rows: 2,
                                header: true,
                                name: <>Proposal <span className='font-weight-normal'>(Idea, Timeline, Max 5 pages)</span> <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "custom",
                                        label: "Durasi Riset",
                                        component: (
                                            <>
                                                <div className='row mb-3'>
                                                    <div className='col-12'>
                                                        <div className="">
                                                            <div className="input-group mb-3 mr-2">
                                                                <input type="number" min={1} className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                                    value={crtResState.formData?.durasi}
                                                                    onChange={(e) => crtResAction.handleChange('durasi', `${e.target.value}`)}
                                                                // value={crtResState.formData?.durasi.split(',')[0]}
                                                                // onChange={(e) => crtResAction.handleChange('durasi', `${e.target.value},${crtResState.formData?.durasi.split(',')[1]}`)}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon2">Tahun</span>
                                                                </div>
                                                            </div>
                                                            {/* <div className="input-group mb-3">
                                                                <input type={'number'} className='form-control'
                                                                    min={1}
                                                                    value={crtResState.formData?.durasi.split(',')[1]}
                                                                    onChange={(e) => crtResAction.handleChange('durasi', `${crtResState.formData?.durasi.split(',')[0]},${e.target.value}`)}
                                                                >
                                                                </input>
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" id="basic-addon2">Bulan</span>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                    },
                                    {
                                        type: "custom",
                                        label: "Sumber File (.doc/.docx)",
                                        component: (
                                            <>
                                                <div className='row mb-3'>
                                                    <div className='col-12'>
                                                        <FileUploadDx
                                                            uploadUrl='/api/web/upload-file-temp'
                                                            className="custom-dx-fileuploader-file-name"
                                                            showFileList={true}
                                                            multiple={false}
                                                            selectButtonText="Select File"
                                                            readyToUploadMessage=" "
                                                            labelText="or Drop file hire | Max size 10 Mb"
                                                            accept="*"
                                                            withToken={false}
                                                            allowedFileExtensions={['.doc', '.docx']}
                                                            onUploaded={(e) => crtResAction.fileUploaded(e, 'summary_file_name')}
                                                            maxFileSize={10000000}
                                                            uploadMode="instantly"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span className='font-weight-bold'>Your Documents</span>
                                                        <div style={{ paddingBottom: '25px' }}>
                                                            {
                                                                crtResState.formData?.summary_file_name && (
                                                                    <div className="d-flex flex-row justify-content-between mb-1">
                                                                        <span style={{ fontSize: '8pt', width: '250px' }}>{crtResState.formData?.summary_file_name}</span>
                                                                        <div>
                                                                            <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1"
                                                                                onClick={() => crtResAction.deleteFileUploaded(crtResState.formData?.summary_file_name, 'summary_file_name')}
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                    },
                                ]
                            },
                            {
                                rows: 2,
                                header: true,
                                name: <>Budget Estimation <span className='font-weight-normal'>(Rate 1 USD = Rp 14.000)</span> <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "custom",
                                        label: "Total Biaya (USD)",
                                        component: (
                                            <div className='row mb-3'>
                                                <div className='col-12'>
                                                    <input type='number' min={1} placeholder="Total biaya ($USD)" className='form-control form-control-sm'
                                                        value={crtResState.formData?.budget}
                                                        onChange={(e) => crtResAction.handleChange('budget', e.target.value)}
                                                    ></input>
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        type: "custom",
                                        label: "Rincian (.xls/.xlsx)",
                                        component: (
                                            <>
                                                <div className='row mb-3'>
                                                    <div className='col-12'>
                                                        <FileUploadDx
                                                            uploadUrl='/api/web/upload-file-temp'
                                                            className="custom-dx-fileuploader-file-name"
                                                            showFileList={true}
                                                            multiple={false}
                                                            selectButtonText="Select File"
                                                            readyToUploadMessage=" "
                                                            labelText="or Drop file hire | Max size 10 Mb"
                                                            accept="*"
                                                            withToken={false}
                                                            allowedFileExtensions={['.xls', '.xlsx']}
                                                            onUploaded={(e) => crtResAction.fileUploaded(e, 'budget_file_name')}
                                                            maxFileSize={10000000}
                                                            uploadMode="instantly"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span className='font-weight-bold'>Your Documents</span>
                                                        <div style={{ paddingBottom: '25px' }}>
                                                            {
                                                                crtResState.formData?.budget_file_name && (
                                                                    <div className="d-flex flex-row justify-content-between mb-1">
                                                                        <span style={{ fontSize: '8pt', width: '250px' }}>{crtResState.formData?.budget_file_name}</span>
                                                                        <div>
                                                                            <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1"
                                                                                onClick={() => crtResAction.deleteFileUploaded(crtResState.formData?.budget_file_name, 'budget_file_name')}
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                    },
                                ]
                            },
                            {
                                rows: 2,
                                header: true,
                                name: <>Research Staff <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "text",
                                        label: "Nama",
                                        placeholder: 'Nama Lengkap',
                                        onChange: (val) => crtResAction.handleChangeStaff('nama', val),
                                        value: crtResState.formStaff?.nama,
                                        disabled: false
                                    },
                                    {
                                        label: "Jabatan",
                                        type: "text",
                                        placeholder: 'Jabatan',
                                        onChange: (val) => crtResAction.handleChangeStaff('jabatan', val),
                                        value: crtResState.formStaff?.jabatan,
                                        disabled: false
                                    },
                                    {
                                        type: "custom",
                                        label: "No. Telp/HP",
                                        component: (
                                            <div className='row mb-3'>
                                                <div className='col-12'>
                                                    <input type='tel' placeholder='No. Telp/HP' className='form-control form-control-sm'
                                                        onChange={(val) => crtResAction.handleChangeStaff('telp', val.target.value)}
                                                        value={crtResState.formStaff?.telp}
                                                    ></input>
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        label: "Email",
                                        type: "custom",
                                        component: (
                                            <div className='row mb-3'>
                                                <div className='col-12'>
                                                    <input type='email' placeholder='Email' className='form-control form-control-sm'
                                                        onChange={(val) => crtResAction.handleChangeStaff('email', val.target.value)}
                                                        value={crtResState.formStaff?.email}
                                                    ></input>
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        label: "",
                                        type: "custom",
                                        component: (
                                            <div className='row mb-3'>
                                                <div className='col-12 text-right'>
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() => crtResAction.addStaff()}
                                                    >Add</button>
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        label: "",
                                        type: "custom",
                                        component: (
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className='font-weight-bold'>Your Staff</span>
                                                    <div style={{ paddingBottom: '25px' }}>
                                                        {
                                                            [...(crtResState.formData?.staffs || [])].map((d, i) => (
                                                                <div className="d-flex flex-row justify-content-between mb-1" key={i}>
                                                                    <span style={{ fontSize: '8pt', width: '250px' }}>{d?.nama}</span>
                                                                    <div>
                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1"
                                                                            onClick={() => crtResAction.deleteStaff(d)}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    },

                                ]
                            },
                            {
                                rows: 2,
                                header: true,
                                name: <>Verifikasi <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "custom",
                                        withLabel: false,
                                        component: (
                                            <div className='row mb-5'>
                                                <div className='col-md-12'>
                                                    <ReCAPTCHA
                                                        sitekey={captcha_client_key}
                                                        onChange={(e) => crtResAction.captchaChange({
                                                            key: e,
                                                            val: e ? true : false
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    },
                                ]
                            },
                        ]}
                    />
                </div>
                <div className='card-footer'>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                onClick={() => history.push('/landing-page/beranda')}
                                //onClick={() => indAction.toHome()}
                                className='btn btn-sm btn-secondary'
                            >
                                Kembali
                            </button>
                        </div>
                        <div className='col-6 text-right'>
                            <button
                                disabled={!crtResState.isVerified?.val}
                                className='btn btn-sm btn-success'
                                onClick={() => crtResAction.onSubmit()}
                            >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default (props) => <CrtResProvider><Index {...props} /></CrtResProvider>;