import { toInteger, toString } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";
import { countriesGDP, provinsiIndonesia } from '../../../components/utility/utility';
import ModalInformation from "./modalInformation";
import { useRef } from "react";

const addEditKContext = createContext({
    addEditKState: {},
    addEditKAction: {}
});
const iniStateFormData = {
    "id": "",
    "judul": "",
    "reviewer_temp": "",
    "reviewer": [],
    "update_at": '',
    "created_by": '',
    "kerjasama_partners_temp": "",
    "kerjasama_partners": [],
    "kerjasama_fungsies": "",
    "kerjasama_section_temp": '',
    "kerjasama_section_temp_name": "",
    "kerjasama_section": [],
    "project_cluster": '0',
    "project_cluster_note": '',
    "stream_business": '0',
    "stream_business_note": '',
    "kerjasama_rkaps_temp": null,
    "kerjasama_rkaps_temp_name": "",
    "kerjasama_rkaps": [],
    "pertam_expected_value": "",
    "pertam_value_offer": "",
    "partner_expected_value": "",
    "partner_value_offer": "",
    "aktifitas_kerjasama": "",
    "lokasi_proyek": "",
    "pic": "0",
    "file_name": "",
    "usulan_dari": ""
}
const iniStateRadioOptionSa = [
    { label: '6', value: '6', hint: 'Memiliki kapabilitas sebagai Offtake/User' },
    { label: '5', value: '5', hint: 'Memiliki akses ke pasar' },
    { label: '4', value: '4', hint: 'Memiliki bahan baku' },
    { label: '3', value: '3', hint: 'Bersedia sharing cost penelitian' },
    { label: '2', value: '2', hint: 'Memiliki teknologi' },
    // { label: '1', value: '1', hint: 'Memiliki kapabilitas dan reputasi pada riset terkait' },
]
const iniStateRadioOptionCs = [
    { label: '5', value: '5', hint: '>10 Billion USD' },
    { label: '4', value: '4', hint: '2 - 10 Billion USD' },
    { label: '3', value: '3', hint: '251 Million - 1 Billion USD' },
    { label: '2', value: '2', hint: '51 - 250 Million USD' },
    { label: '1', value: '1', hint: '0 - 50 Million USD' },
]
export function AddEditKerjasamaProvider({ children }) {
    const [showInfo, setShowInfo] = useState(false);
    const history = useHistory();
    const [formData, setFormData] = useState(iniStateFormData);
    const [viewerSource, setViewerSource] = useState([]);
    const [functSource, setFunctSource] = useState([]);
    const [projCluster, setProjCluster] = useState([]);
    const [data_stream_business, setData_stream_business] = useState([]);
    const [sourceRKAP, setSourceRKAP] = useState([]);
    const [data_users, setData_users] = useState([]);
    const optRadioSa = iniStateRadioOptionSa;
    const optRadioCs = iniStateRadioOptionCs;
    const { doGetMultiple, doGet, doPost } = useFetch();
    const [urlAction, setUrlAction] = useState('/admin/partnership-crud/dashboard');
    const { pathname } = useLocation();
    const path = pathname.split('/')[1];
    const [data_page, setData_page] = useState({
        "page_title": "",
        "page_breadcrumbs": ""
    });
    const refFile = useRef();

    const OnFileUploaded = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setFormData((prev) => ({
                ...prev,
                "file_name": resp.data?.data_filename
            }));
            if (refFile.current)
                refFile.current.instance.reset();

        }
    }
    const getResources = async function (edit = false, id = '') {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            var opt = [{
                url: 'api/reviewer-partnership',
                responseVar: 'resp1'
            }, {
                url: 'api/param-function',
                responseVar: 'resp2'
            },
            // {
            //     url: 'api/ext-partnership/param-project-cluster',
            //     responseVar: 'resp4'
            // },
            {
                url: path.toLowerCase() === 'admin' ? 'api/ext-partnership/view-add-kerjasama' : 'api/ext-partnership/view-add-kerjasama-2',
                responseVar: 'resp5'
            }];
            if (edit) {
                opt = [
                    ...opt,
                    {
                        url: `api/ext-partnership/view/${id}`,
                        responseVar: 'resp3'
                    }
                ]

            }
            const {
                resp1,
                resp2,
                resp3,
                // resp4,
                resp5
            } = await doGetMultiple(opt);
            //response handle request 1      
            if (resp1?.code === '00') {
                setViewerSource([...(resp1?.data || [])].map(d => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                }))
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp1?.message,
                    icon: 'error',
                })
            }
            //response handle request 2
            if (resp2?.code === '00') {
                setFunctSource([...(resp2.data || [])].map(d => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                }))
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp2?.message,
                    icon: 'error',
                })
            }
            //response handle request 4
            // if (resp4?.code === '00') {
            //     setProjCluster([...(resp4?.data || [])].map(d => {
            //         return {
            //             name: d.title,
            //             value: d.param_id
            //         }
            //     }));
            // } else {
            //     return Swal.fire({
            //         title: 'Error!',
            //         text: resp4?.message,
            //         icon: 'error',
            //     })
            // }
            if (resp5?.code === '00') {
                setData_page({ ...resp5?.data_page });
                setData_stream_business([...(resp5?.data?.data_stream_business || [])]);
                setProjCluster([...(resp5?.data?.data_project_cluster || [])]);
                setSourceRKAP([...(resp5?.data?.data_researchs || [])].map(d => ({
                    id: d?.id,
                    name: (d?.name || "").replace("\r\n", "")
                })));
                setData_users([...(resp5?.data?.data_users || [])]);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp1?.message,
                    icon: 'error',
                })
            }
            //response handle request 3
            if (edit) {
                if (resp3?.code === '00') {
                    // const p = resp3?.data?.header_kerjasama?.nama_partner?.split(';') || [];
                    // const kerjasama_partners = (p || [])?.map(d => {
                    //     const splitcontent_p = (d || '')?.split('|');
                    //     return {
                    //         nama_partner: (splitcontent_p[0] || ''),
                    //         score_sa: (splitcontent_p[1] || '')?.split('=')[1],
                    //         // score_cs: (splitcontent_p[2] || '')?.split('=')[1],
                    //         country: (splitcontent_p[2] || '')?.split('=')[1],
                    //         province: (splitcontent_p[3] || '')?.split('=')[1],
                    //     }
                    // });
                    // const chief_name = (resp3?.data?.header_kerjasama?.chief_long_name)?.split(';') || [];
                    const kerjasama_rk = [...(resp3?.data?.header_kerjasama?.kerjasama_rkaps || [])].map(d => (
                        {
                            "id": d?.id,
                            "kerjasama_id": d?.kerjasama_id,
                            "research_id": toString(d?.research_id),
                            "show_research": d?.title
                        }))
                    // console.log((resp3?.data?.header_kerjasama?.chief_manager_id)?.split(';'))
                    setDefaultValueForm({
                        id,
                        judul: resp3?.data?.header_kerjasama?.judul,
                        lokasi_proyek: resp3?.data?.header_kerjasama?.lokasi_proyek,
                        pertam_expected_value: resp3?.data?.header_kerjasama?.pertam_expected_value,
                        pertam_value_offer: resp3?.data?.header_kerjasama?.pertam_value_offer,
                        partner_expected_value: resp3?.data?.header_kerjasama?.partner_expected_value,
                        partner_value_offer: resp3?.data?.header_kerjasama?.partner_value_offer,
                        aktifitas_kerjasama: resp3?.data?.header_kerjasama?.aktifitas_kerjasama,
                        kerjasama_partners: [...(resp3?.data?.header_kerjasama?.kerjasama_partners || [])].map(d => {
                            const bantu = (d?.score_sa === 1) ? true : false;
                            const sa1 = (d?.score_sa1 === 1 ? "a" : d?.score_sa1 === 2 ? "b" : d?.score_sa1 === 3 ? "c" : null);
                            return {
                                ...d,
                                score_sa: bantu ? sa1 : d?.score_sa
                            }
                        }),
                        project_cluster: toString(resp3.data?.header_kerjasama?.project_cluster || ""),
                        project_cluster_note: resp3.data?.header_kerjasama?.project_cluster_note,
                        pic: resp3?.data?.header_kerjasama?.pic,
                        kerjasama_section: [...(resp3?.data?.header_kerjasama?.kerjasama_fungsies || [])].map(d => ({
                            "id": d?.id,
                            "kerjasama_id": d?.kerjasama_id,
                            "chief_manager_id": d?.chief_manager_id,
                            "show_chief_manager": d?.chief_manager_name
                        })),
                        // [...(resp3?.data?.header_kerjasama?.chief_manager_id?.split(';') || [])]?.map((d, i) => ({
                        //     "kerjasama_id": id,
                        //     "chief_manager_id": d,
                        //     "show_chief_manager": chief_name[i]
                        // })),
                        stream_business: toString(resp3?.data?.header_kerjasama?.stream_business || ''),
                        stream_business_note: resp3?.data?.header_kerjasama?.stream_business_note,
                        kerjasama_rkaps: kerjasama_rk,
                        file_name: resp3?.data?.header_kerjasama?.file_name,
                        usulan_dari: resp3?.data?.header_kerjasama?.usulan_dari || ''

                    });
                } else {
                    return Swal.fire({
                        title: 'Error!',
                        text: resp3?.message,
                        icon: 'error',
                    })
                }
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
    }
    const [loadingGetSection, setLoadingGetSection] = useState(false);
    const [sectionSource, setSectionSource] = useState([]);
    const getSectionSourceById = async function (id) {
        setLoadingGetSection(true)
        try {
            const resp = await doGet({
                url: `api/param-function-chief-manager/${id}`,
            });
            if (resp?.code === '00') {
                setSectionSource([...(resp.data || [])].map(d => {
                    return d
                }))
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingGetSection(false)
    }
    const resetForm = () => setFormData({ ...iniStateFormData });
    const setDefaultValueForm = (data = iniStateFormData) => {
        setFormData({
            ...data,
            kerjasama_partners: data?.kerjasama_partners.map(d => ({ ...d, score_cs: toString(d?.score_cs), score_sa: toString(d?.score_sa) }))
        });
    }
    const handleChange = (val, name) => {
        // console.log(val, name);
        if (name === "kerjasama_partners") {
            if (val) {
                setFormData((e) => ({
                    ...e,
                    [name]: [...e.kerjasama_partners, {
                        "id": "",
                        "kerjasama_id": formData?.id,
                        "nama_partner": val
                    }],
                    kerjasama_partners_temp: ""
                }))
            }
        } else if (name === "reviewer") {
            const bantu = !formData.reviewer.find(d => toString(d) === val);
            if (val && bantu) {
                setFormData((e) => ({
                    ...e,
                    reviewer_temp: "",
                    [name]: [...e.reviewer, val]
                }))
            }
        } else if (name === "kerjasama_fungsies") {
            setFormData((e) => ({
                ...e,
                [name]: val
            }));
            getSectionSourceById(val);
        } else if (name === "kerjasama_section") {
            const bantu = !formData.kerjasama_section.find(d => toString(d?.chief_manager_id) === toString(val?.id));
            if (val?.id && bantu) {
                setFormData((e) => ({
                    ...e,
                    kerjasama_section_temp_name: "",
                    kerjasama_section_temp: null,
                    [name]: [...e.kerjasama_section, {
                        "id": "",
                        "kerjasama_id": formData?.id,
                        "chief_manager_id": val?.id,
                        "show_chief_manager": val?.short_name
                    }]
                }))
            }
        } else if (name === "add_rkaps") {
            const bantu = !formData.kerjasama_rkaps.find(d => d?.research_id === toString(val?.id));
            // console.log(bantu, val);
            if (val !== null && bantu) {
                setFormData((e) => ({
                    ...e,
                    "kerjasama_rkaps": [...e.kerjasama_rkaps, {
                        "id": 0,
                        "kerjasama_id": formData?.id,
                        "research_id": toString(val?.id),
                        "show_research": val?.name
                    }],
                    "kerjasama_rkaps_temp_name": '',
                    "kerjasama_rkaps_temp": null
                }))
            }
        } else if (name === "delete_rkaps") {
            setFormData((prev) => ({
                ...prev,
                "kerjasama_rkaps": prev.kerjasama_rkaps.filter(d => d?.research_id !== val)
            }))
        } else if (name === "stream_business") {
            setFormData((prev) => ({
                ...prev,
                "stream_business": val,
                "stream_business_note": toString(val) !== "7" ? "" : prev.stream_business_note
            }));
        } else if (name === "project_cluster") {
            setFormData((prev) => ({
                ...prev,
                "project_cluster": val,
                "project_cluster_note": toString(val) !== "5" ? "" : prev.project_cluster_note
            }));
        } else if (name === "delete_file") {
            setFormData((prev) => ({
                ...prev,
                "file_name": ""
            }));
        } else {
            setFormData((e) => ({
                ...e,
                [name]: val
            }))
        }
    }
    const handleChangePartnerContent = (rowNum = 0, val, name) => {
        const temp = [...(formData.kerjasama_partners || [])];
        if (temp[rowNum]) {
            if (name === "country") {
                const country = countriesGDP.find(d => d.name === val)
                temp[rowNum] = {
                    ...temp[rowNum],
                    [name]: val,
                    country_coord1: country?.latitude,
                    country_coord2: country?.longitude,
                    province: "",
                    province_coord1: null,
                    province_coord2: null
                }
            }
            if (name === "province") {
                const provinsi = provinsiIndonesia.find(d => d.name === val)
                temp[rowNum] = {
                    ...temp[rowNum],
                    [name]: val,
                    province_coord1: provinsi?.latitude,
                    province_coord2: provinsi?.longitude
                }
            }
            if (name === "score_sa") {
                temp[rowNum] = {
                    ...temp[rowNum],
                    [name]: val
                }
            }
            setFormData((e) => ({ ...e, kerjasama_partners: [...temp] }));
        }
    }
    const deletePartner = (rowNum) => {
        if (rowNum != null || rowNum != undefined || rowNum != "") {
            const bantu = [...(formData.kerjasama_partners || [])].filter((d, i) => i !== rowNum)
            setFormData((e) => ({ ...e, kerjasama_partners: bantu }));
        }
    }
    const deleteSection = (chief_manager_id) => {
        if (chief_manager_id) {
            const bantu = [...(formData.kerjasama_section || [])].filter((d, i) => d?.chief_manager_id !== chief_manager_id)
            setFormData((e) => ({ ...e, kerjasama_section: bantu }));
        }
    }
    const deleteReviewer = (reviewer_id) => {
        if (reviewer_id) {
            const bantu = [...(formData.reviewer || [])].filter((d, i) => d !== reviewer_id)
            setFormData((e) => ({ ...e, reviewer: bantu }));
        }
    }
    const submitButton = (edit = false) => {
        const data = {
            "id": formData?.id,
            "judul": formData?.judul,
            "reviewer": '',
            "update_at": '',
            "created_by": '',
            "kerjasama_partners": formData?.kerjasama_partners.map(d => {
                const bantu = (d?.score_sa === "a" || d?.score_sa === "b" || d?.score_sa === "c") ? true : false;
                const sa1 = bantu ? (d?.score_sa === "a" ? "1" : d?.score_sa === "b" ? "2" : d?.score_sa === "c" ? "3" : "0") : "0";
                return {
                    ...d,
                    score_sa: bantu ? 1 : toInteger(d?.score_sa),
                    score_sa1: bantu ? toInteger(sa1) : 0
                }
            }),
            "kerjasama_fungsies": formData?.kerjasama_section,
            "project_cluster": formData?.project_cluster,
            "project_cluster_note": formData?.project_cluster_note,
            "stream_business": formData?.stream_business,
            "stream_business_note": formData?.stream_business_note,
            "kerjasama_rkaps": formData.kerjasama_rkaps || [],
            "pertam_expected_value": formData?.pertam_expected_value,
            "pertam_value_offer": formData?.pertam_value_offer,
            "partner_expected_value": formData?.partner_expected_value,
            "partner_value_offer": formData?.partner_value_offer,
            "aktifitas_kerjasama": formData?.aktifitas_kerjasama,
            "lokasi_proyek": formData?.lokasi_proyek,
            "pic": formData?.pic,
            "file_name": formData?.file_name,
            "usulan_dari": formData?.usulan_dari

        }
        // console.log(data);
        const val = validation(data);
        if (!val?.isValid) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        } else {
            confirm(edit, data);
        }
    }
    const confirm = (edit = false, data) => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>` +
                `<strong>${edit ? "Update" : "Add"} Partnership?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (edit) {
                    doNextEdit(data);
                } else {
                    doNextAdd(data);
                }
                return true;
            } else {
                return true;
            }
        })
    }
    const doNextAdd = async function (data = {}) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: path.toLowerCase() === 'admin' ? "api/ext-partnership/store-kerjasama" : "api/ext-partnership/store-kerjasama-2",
                data: data,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    history.push(urlAction);
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const doNextEdit = async function (data = {}) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: "api/ext-partnership/update-kerjasama",
                data: data,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    history.goBack();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const validation = (data = iniStateFormData) => {
        console.log(data);
        var isValid = true;
        var message = [];
        if (data?.judul === '') {
            isValid = false;
            message = [...message, 'Judul'];
        }
        if (data?.usulan_dari === '') {
            isValid = false;
            message = [...message, 'Usulan Dari'];
        }
        if (data?.kerjasama_partners?.length === 0) {
            isValid = false;
            message = [...message, 'Partner'];
        }

        // if (data?.kerjasama_rkaps?.length === 0) {
        //     isValid = false;
        //     message = [...message, 'RKAP Berkaitan'];
        // }

        if (data?.stream_business === '' || data?.stream_business === null || data?.stream_business.toString() === '0') {
            isValid = false;
            message = [...message, 'Stream Business'];
        }
        if (data?.stream_business === '7' && data.stream_business_note === "") {
            isValid = false;
            message = [...message, 'Silahkan isi (Jika Klik Other di Stream Business)'];
        }

        if (data?.project_cluster === '' || data?.project_cluster === null || data?.project_cluster.toString() === '0') {
            isValid = false;
            message = [...message, 'Project cluster'];
        }
        if (data?.project_cluster === '5' && data.project_cluster_note === "") {
            isValid = false;
            message = [...message, 'Silahkan isi (Jika Klik Other di Project Cluster)'];
        }

        if (data?.pertam_expected_value === '') {
            isValid = false;
            message = [...message, 'Pertamina expected value'];
        }
        if (data?.pertam_value_offer === '') {
            isValid = false;
            message = [...message, 'Pertamina value offer'];
        }
        if (data?.partner_expected_value === '') {
            isValid = false;
            message = [...message, 'Partner expected value'];
        }
        if (data?.partner_value_offer === '') {
            isValid = false;
            message = [...message, 'Partner value offer'];
        }
        if (data?.aktifitas_kerjasama === '') {
            isValid = false;
            message = [...message, 'Aktifitas kerjasama'];
        }

        if (data?.pic === '0') {
            isValid = false;
            message = [...message, 'PIC'];
        }

        if (data?.kerjasama_fungsies.length === 0) {
            isValid = false;
            message = [...message, 'Fungsi'];
        }

        if (isValid) {
            //cek partner konten
            var bantu = true;
            [...(data?.kerjasama_partners || [])].forEach(({
                nama_partner,
                score_sa,
                score_cs,
                country,
                country_coord1,
                country_coord2,
                province,
                province_coord1,
                province_coord2
            }) => {
                if (score_sa === "" || score_sa === undefined || score_sa === null || score_sa === 0) {
                    isValid = false;
                    message = [...message, `Partner '${nama_partner}' Strategic Advantage`];
                }
                // if (score_cs === "" || score_cs === undefined || score_cs === null) {
                //     isValid = false;
                //     message = [...message, `Partner '${nama_partner}' Company Size`];
                // }
                if (country === "" || country === undefined || country === null) {
                    isValid = false;
                    message = [...message, `Partner '${nama_partner}' Country`];
                }
                if (country === "Indonesia" && (province === "" || province === undefined || province === null)) {
                    isValid = false;
                    message = [...message, `Partner '${nama_partner}' Province`];
                }
            })
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }

    useEffect(() => {
        // console.log(history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/')));
        if (history.location && history.location.pathname === '/partnership-add') {
            setUrlAction('/Kerjasama/tabel');
        }
        if (history.location && history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/')) === '/admin/partnership-approval-edit') {
            setUrlAction('/admin/partnership-approval')
        }
    }, [history, setUrlAction]);
    return <addEditKContext.Provider
        value={{
            addEditKState: {
                formData,
                functSource,
                viewerSource,
                sectionSource,
                projCluster,
                loadingGetSection,
                optRadioSa,
                optRadioCs,
                showInfo,
                data_page,
                data_stream_business,
                sourceRKAP,
                data_users,
                refFile
            },
            addEditKAction: {
                resetForm,
                handleChange,
                getResources,
                handleChangePartnerContent,
                deletePartner,
                deleteReviewer,
                deleteSection,
                submitButton,
                setShowInfo,
                OnFileUploaded
            }
        }}
    >
        {children}
        <ModalInformation />
    </addEditKContext.Provider>
}
export default function useAddEditKerjasama() {
    return useContext(addEditKContext);
}