import { FileUploader } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { tipe_pembayaran } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import useModalEditorSwa, { SwaEditModalProvider } from './useModalEditorSwa';

const ModalEditorReal = ({ mode = 'Add', id_swakelola, data = {}, data_plan = [] }) => {
    const { swaEditState, swaEditAction } = useModalEditorSwa();
    const [state, dispatch, action] = useGlobalState();
    useEffect(() => {
        if (state.modal?.modalEditorReal === true) {
            swaEditAction.resetEditorReal();
            swaEditAction.setSwakelola_id(id_swakelola);
            swaEditAction.setEditorReal(data);
            swaEditAction.handleReal.setModeEditReal(mode);
        }
    }, [state.modal?.modalEditorReal])
    return (
        <div>
            <Card>
                <Card.Body>
                    <h5>{`${mode} Realisasi`}</h5>
                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '150px' }} className="input-group-text text-wrap bg-transparent border-0" id="basic-addon2">Tanggal SA</span>
                                </div>
                                <input type="date" className="form-control" placeholder="Tanggal" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataReal.tanggal_real} onChange={(e) => swaEditAction.handleReal.setTanggal_real(moment(e.target.value).format("YYYY-MM-DD"))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '150px' }} className="input-group-text text-left text-wrap bg-transparent border-0" id="basic-addon2">Plan</span>
                                </div>
                                <select className="form-control" placeholder="IDR Juta" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataReal.plan_id} onChange={(e) => swaEditAction.handleReal.setPlan_id(e.target.value)}
                                >
                                    <option value={''}>- Pilih Plan -</option>
                                    {
                                        [...(data_plan || [])].map((d, i) => (
                                            <option value={d?.id}>{`Plan ${moment().month(d?.bulan - 1).format("MMMM")} ${d?.tahun} (${d?.tipe}) - ${d?.jumlah} Juta`}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '150px' }} className="input-group-text text-wrap text-left bg-transparent border-0" id="basic-addon2">Tipe Pembayaran</span>
                                </div>
                                <select className="form-control" placeholder="IDR Juta" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataReal.tipe_real} onChange={(e) => swaEditAction.handleReal.settipe_real(e.target.value)}
                                >
                                    <option value={''}>- Pilih tipe pembayaran -</option>
                                    {
                                        [...(tipe_pembayaran || [])].map((d, i) => (
                                            <option value={d?.value}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '150px' }} className="input-group-text text-wrap text-left bg-transparent border-0" >Jumlah Pembayaran (Juta IDR)</span>
                        </div>
                        <div className="col pl-0">
                            <div className="input-group input-group-sm mb-3">
                                <input type="number" className="form-control" placeholder="Jumlah Pembayaran" aria-label="IDR Juta"
                                    value={swaEditState.dataReal.jumlah_real} onChange={(e) => swaEditAction.handleReal.setJumlah_real(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text" >IDR (Juta)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '150px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Kompilasi Dokumen BAST, Invoice Faktur Pajak dan Payment Approval</span>
                        </div>
                        <div className="col pl-0">
                            <FileUploader className="custom-dx-fileuploader-file-name"
                                onValueChanged={(e) => swaEditAction.handleReal.uploadFileReal(e, 'dok_kompilasi')}
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                            </FileUploader>
                            <span>{swaEditState.dataReal.dok_kompilasi}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '150px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Laporan Progres</span>
                        </div>
                        <div className="col pl-0">
                            <FileUploader className="custom-dx-fileuploader-file-name"
                                onValueChanged={(e) => swaEditAction.handleReal.uploadFileReal(e, 'dok_laporan')}
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                            </FileUploader>
                            <span>{swaEditState.dataReal.dok_laporan}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-auto pl-3 pr-0">
                            <span style={{ width: '150px' }} className="input-group-text text-wrap text-left d-block bg-transparent border-0" id="basic-addon2">Dokumen Lain (Optional)</span>
                        </div>
                        <div className="col pl-0">
                            <FileUploader className="custom-dx-fileuploader-file-name"
                                onValueChanged={(e) => swaEditAction.handleReal.uploadFileReal(e, 'dok_lain')}
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                            </FileUploader>
                            <span>{swaEditState.dataReal.dok_lain}</span>
                        </div>
                    </div>
                    <hr />
                    <div className="text-md-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => swaEditAction.handleReal.batalReal()}
                        >
                            Batal
                        </button>
                        <button className="btn btn-sm btn-primary mx-1"
                            onClick={() => swaEditAction.saveButtonClickReal()}
                        >
                            <i className="fa fa-save icon-nm mr-1"></i>
                            Simpan
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default (props) => <SwaEditModalProvider><ModalEditorReal mode={props?.mode} id_swakelola={props?.id_swakelola} data={props?.data} data_plan={props?.data_plan} /></SwaEditModalProvider>;