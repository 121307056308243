import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Button, Column } from 'devextreme-react/data-grid';
import useCrudEquipment from '../crudEquipment/useCrudEquipment';
import Popup, { Position } from 'devextreme-react/popup';
import ModalEditingHistory from '../crudEquipment/modalEditingHistory';
import ModalEditingLogbook from '../crudEquipment/modalEditingLogbook';
import overlay from 'devextreme/ui/overlay';
import useFetch from '../../modules/useFetch';
import { status_equipment } from '../../components/utility/utility';

const column_history = [
    { fieldName: 'tanggal_mulai_str', caption: 'Tanggal', allowFiltering: false, width: 100 },
    { fieldName: 'equipment_status_str', caption: 'Status', allowFiltering: false, width: 120 },
    { fieldName: 'tanggal_selesai_str', caption: 'Sampai Dengan', allowFiltering: false, width: 100 },
    { fieldName: 'keterangan', caption: 'Keterangan', allowFiltering: false },
    { fieldName: 'biaya', caption: 'Biaya', allowFiltering: false, width: 150 },
]
const column_logbook = [
    { fieldName: 'status', caption: 'Status Alat', allowFiltering: false },
    { fieldName: 'show_start', caption: 'Mulai', allowFiltering: false },
    { fieldName: 'show_end', caption: 'Sampai Dengan', allowFiltering: false },
    { fieldName: 'pemakai_name', caption: 'Pemakai', allowFiltering: false },
    { fieldName: 'deskripsi', caption: 'Keterangan', allowFiltering: false },
]
const EquipmentGridDetailAdmin = (props) => {
    const { id } = props?.data?.data;
    const [state, dispatch, action] = useGlobalState();
    const { ceqAction, ceqState } = useCrudEquipment();
    const [dataDetail, setDataDetail] = useState();
    const { doGet } = useFetch();

    useEffect(() => {
        if (id) {
            // console.log(id);
            getData(id);
            overlay.baseZIndex(1050);
        }
    }, [id]);

    useEffect(() => {
        if (ceqState.refreshDetail === true) {
            getData(id);
        }
    }, [ceqState.refreshDetail])
    function getData(id) {
        doGet({ url: `/api/ext-equipment/view/${id}` }).then((resp) => {
            if (resp.code === '00') {
                setDataDetail(resp.data);
                ceqAction.setRefreshDetail(false);
            }
        })
        // action.getData(`/api/ext-equipment/view/${id}`).then((resp) => {
        //     if (resp.code === '00') {
        //         setDataDetail(resp.data);
        //         ceqAction.setRefreshDetail(false);
        //     }
        // })
    }
    function cellRenderLoogbook(rowInfo) {
        if (rowInfo.column.name === 'status') {
            return (
                <span className="d-block text-wrap">{dataDetail?.dataEquipment?.nama_status}</span>
            )
        } else {
            return (
                <span className="d-block text-wrap">{rowInfo.value}</span>
            )
        }
    }
    return (
        <div style={{ color: 'black' }}>
            <Popup
                className="detail-popup mb-5"
                showTitle={false}
                width='100vh'
                height='auto'
                closeOnOutsideClick={false}
                visible={ceqState.globalState.modal.modalEqH}
                shading={true}
                shadingColor='transparent'
                onHiding={() => ceqAction.globalAction.toggleModal('modalEqH')}
            >
                <ModalEditingHistory mode={ceqState.modeEditH} data={ceqState.dataEditH} />
            </Popup>
            <Popup
                className="detail-popup mb-5"
                showTitle={false}
                width='100vh'
                height='auto'
                closeOnOutsideClick={false}
                visible={ceqState.globalState.modal.modalEqL}
                shading={true}
                shadingColor='transparent'
                onHiding={() => ceqAction.globalAction.toggleModal('modalEqL')}
            >
                <ModalEditingLogbook mode={ceqState.modeEditL} data={ceqState.dataEditL} />
            </Popup>
            {/* <Card
                className="m-0 p-0 mb-5"
            >
                <Card.Body className="p-4"> */}
            <div className="row mb-5">
                <div className="col-md-3 mb-5">
                    <div className="pr-5">
                        <img style={{ width: '100%', height: 200, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                            src={dataDetail?.dataEquipment?.path}
                            onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                    </div>
                </div>
                <div className="col-md-9 mb-5">
                    <h5 className='font-bold text-wrap'>{dataDetail?.dataEquipment?.nama}</h5>
                    <br />
                    <span className="d-block text-wrap text-dark-50 mb-1">Deskripsi Alat</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.deskripsi}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Informasi Tambahan</span>
                    <span className="d-block text-wrap font-weight-bold">{dataDetail?.dataEquipment?.information}</span>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-6">
                    <span className="d-block mb-3 font-bold">Detail Alat</span>
                    <hr />
                    <span className="d-block text-wrap text-dark-50 mb-1">Status Alat</span>
                    <span className="d-flex text-wrap font-weight-bold mb-5">{status_equipment(dataDetail?.dataEquipment?.nama_status)}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Nomor Asset</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.nomor}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Nomor Seri</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.nomor_seri}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Tipe Alat</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.nama_tipe}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Merk</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.merk}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Utilitas Pendukung</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.utilitas}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Supporting Equipment</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.supporting}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Daya Listrik (Watt)</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.daya_listrik}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">PC</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.pc ? "Ada" : "Tidak Ada"}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Lingkup Akreditasi ISO 17025</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.iso_17025 ? "Ada" : "Tidak"}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Tahun Pembelian / Umur</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.tahun_pembelian} / {dataDetail?.dataEquipment?.usia} Tahun</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Dokumen Terkait</span>
                    <div className="d-block">
                        <ul>
                            {
                                [...(dataDetail?.dataEquipment?.list_dokumen || [])].map((d, i) => (
                                    <li>
                                        <a href={`${d.file_doc_name_with_url}`} download>
                                            {d.file_doc_name}
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <span className="d-block text-wrap mb-3 font-bold">Lokasi</span>
                    <hr />
                    <span className="d-block text-wrap text-dark-50 mb-1">Lokasi Alat</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.nama_lokasi}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Lab</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.nama_lab}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">PIC</span>
                    <span className="d-block text-wrap font-weight-bold mb-5">{dataDetail?.dataEquipment?.penanggung_jawab_name}</span>
                    <span className="d-block text-wrap text-dark-50 mb-1">Operator</span>
                    <ul>
                        {
                            [...(dataDetail?.dataEquipment?.nama_PIC_array_name || [])].map((d, i) => (
                                <li key={i}>{d}</li>
                            ))
                        }
                    </ul>
                    <span className="d-block text-wrap text-dark-50 mb-5">Product Price</span>
                    <span className="d-block text-wrap font-bold mb-3">Harga</span>
                    <hr />
                    <div className="mb-2">
                        <label className="text-wrap text-dark-50 mb-1" style={{ width: '100px' }}>Harga Alat</label>
                        <span className="font-weight-bold mb-5 bg-light-primary mx-2 px-2 py-1 rounded text-primary">$ {dataDetail?.dataEquipment?.harga_alat}</span>
                    </div>
                    <div className="mb-2">
                        <label className="text-wrap text-dark-50 mb-1" style={{ width: '100px' }}>Biaya Perbaikan / Pemeliharaan (USD)</label>
                        <span className="font-weight-bold mb-5 bg-light-warning mx-2 px-2 py-1 rounded text-warning">$ {dataDetail?.dataEquipment?.biaya_total}</span>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between py-1">
                        <span className="d-block text-wrap font-bold my-auto">History Status Alat</span>
                        {/* {
                            (ceqState.editingH || state.dataUser?.id === dataDetail?.dataEquipment?.penanggung_jawab || state.dataUser?.role_name === 'Admin Equipment' || state.dataUser?.role_name === 'Administrator') && (
                                <button className="btn btn-sm btn-primary"
                                    onClick={() => ceqAction.addHistory(id)}
                                >
                                    <i className="fa fa-plus icon-nm"></i>
                                    Status
                                </button>
                            )
                        } */}
                    </div>
                    <hr className="mt-1" />
                    <GridDx
                        className='custom-style'
                        columns={column_history}
                        data={dataDetail?.dataHistory}
                    >
                        {/* {
                            (ceqState.editingH || state.dataUser?.id === dataDetail?.dataEquipment?.penanggung_jawab || state.dataUser?.role_name === 'Admin Equipment' || state.dataUser?.role_name === 'Administrator') && (

                                <Column caption="Action" type="buttons" headerCellRender={ceqAction.headerCellRender} >
                                    <Button name="customEdit" visible={true} render={(e) => ceqAction.editBtnH(e, id)} />
                                </Column>
                            )
                        } */}
                    </GridDx>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between py-1">
                        <span className="d-block text-wrap font-bold my-auto">Log Book Pemakaian</span>
                        {/* {
                            (ceqState.editingL || state.dataUser?.id === dataDetail?.dataEquipment?.penanggung_jawab || state.dataUser?.role_name === 'Admin Equipment' || state.dataUser?.role_name === 'Administrator') && (
                                <button className="btn btn-sm btn-primary"
                                    onClick={() => ceqAction.addLogbook(id)}
                                >
                                    <i className="fa fa-plus icon-nm"></i>
                                    Logbook
                                </button>
                            )
                        } */}
                    </div>
                    <hr className="mt-1" />
                    <GridDx
                        cellRender={cellRenderLoogbook}
                        className='custom-style'
                        columns={column_logbook}
                        data={dataDetail?.dataLogBook}
                    >
                        {/* {
                            (ceqState.editingL || state.dataUser?.id === dataDetail?.dataEquipment?.penanggung_jawab || state.dataUser?.role_name === 'Admin Equipment' || state.dataUser?.role_name === 'Administrator') && (
                                <Column caption="Action" type="buttons" headerCellRender={ceqAction.headerCellRender} >
                                    <Button name="customEdit" visible={true} render={(e) => ceqAction.editBtnL(e, id)} />
                                    <Button name="delete" visible={true} render={ceqAction.deleteBtnL} />
                                </Column>
                            )
                        } */}
                    </GridDx>
                </div>
            </div>
            {/* </Card.Body>
            </Card> */}
        </div>
    );
}

export default EquipmentGridDetailAdmin;