import React from 'react';
import useCrudCustomer, { CrudCustomerProvider } from './useCrudCustomer';
import { HeaderList, Tab, TabContent } from '../../../../components';
import { Card } from 'react-bootstrap';
import TabCustomerRegister from './tabCustomerRegister';
import TabCustomerList from './tabCustomerList';

function Index(props) {
    const {ccAction, ccState} = useCrudCustomer();
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={"Admin Laboratory CRUD Customer"}
                someText={""}
            />
            <div className="row">
                <div className="col-12">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "cl",
                                title: "Customer List",
                                onClick: () => ccAction.tabChangeHandle("cl")
                            },
                            {
                                id: "cr",
                                title: "Customer Request",
                                onClick: () => ccAction.tabChangeHandle("cr")
                            },
                        ]}
                        manualActive={ccState.tabActive}
                    >
                        <TabContent id="cl">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabCustomerList/>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                        <TabContent id="cr">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <TabCustomerRegister/>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                    </Tab>
                </div>
            </div>
        </div>
    );
}

export default () => <CrudCustomerProvider><Index /></CrudCustomerProvider>;