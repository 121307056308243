import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { ChartDx, CstmFilterCahrtDx, DoughnatDx, Tab, TabContent, GridDx, ExportPdf, SwitchBtn } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import { chart_color } from '../../components/utility/utility';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useMonitoring from './useMonitoring';

const columns = [
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "intangible", caption: "Intangible", minWidth: 500 },
]
const usulanProyek = [
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '3', name: 'Area Inovasi' },
    { value: '4', name: 'Metode Proyek' }
]
const usulanAnggaran = [
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '3', name: 'Area Inovasi' },
    { value: '4', name: 'Metode Riset' }
]
const metodePelaksanaan = [
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '3', name: 'Area Inovasi' }
]
const KesiapanTek = [
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '3', name: 'Area Inovasi' }
]
const nilaiTambah = [
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '3', name: 'Area Inovasi' }
]

const TabProfile = memo(function ({ reporting = false }) {
    const [state, dispatch, action] = useGlobalState();
    const [dsChart5, setDsChart5] = useState()
    const [dsChart7, setDsChart7] = useState()
    const [yargChart5, setYargChart5] = useState();
    const [selCost, setSelCost] = useState('0');
    const [selRisk, setSelRisk] = useState('0');
    const [seltangible, setTangible] = useState('0');
    const refEx = useRef();
    const colorX = ['bg-primary', 'bg-success', 'bg-warning', 'bg-purple', 'bg-danger', 'bg-orange'];

    const [swChart3, setSwChart3] = useState(false);
    const [swChart4, setSwChart4] = useState(false);
    const [swChart5, setSwChart5] = useState(false);
    const [swChart9, setSwChart9] = useState(false);
    const {
        monState: {
            tahun,
        }
    } = useMonitoring();

    useEffect(() => {
        getDataProfile();
    }, [tahun])

    const getDataProfile = useCallback(() => {
        var url = "/api/project-ongoing/dashboard-project-profile";
        if (reporting) url = "/api/project-ongoing/dashboard-project-profile-3";
        action.getData(url, {
            tahun: tahun,
        }, null, "loadingAll", true).then((resp) => {
            if (resp.code === "00") {
                var data_area = resp.data.data_area.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                var data_function = resp.data.data_function.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                const option_year = (resp?.data?.data_year_portfolio || [])?.map(e => ({ name: e?.idYear, value: e?.idYear }))
                dispatch({
                    type: 'getSuccess',
                    payload: {
                        option_year,
                        data_page: resp.data_page,
                        data_area: data_area,
                        data_function: data_function,
                        data_tahun_default: resp.data.data_tahunGet,
                        loadingGet: false
                    }
                })

                getProject_type();
                chart1(resp.data.data_tahunGet);
                chart2(resp.data.data_tahunGet);
                chart3(resp.data.data_tahunGet);
                chart4(resp.data.data_tahunGet);
                chart5(resp.data.data_tahunGet);
                chart7(resp.data.data_chart7_Sinergisitas);
                chart8(resp.data.data_tahunGet);
                chart9(resp.data.data_tahunGet);
                chart11(resp.data.data_tahunGet);
                chart12(resp.data.data_tahunGet);
                chart13(resp.data.data_tahunGet);
                chart14(resp.data.data_tahunGet);
                tangibleTable(resp.data.data_tahunGet);
            }
        })
    }, [reporting, tahun]);

    function getProject_type() {
        action.getData("/api/param-project-type", {}, "project_type");
    }
    function chart1(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart1", {
                tipe: tahunGet ? "1" : type,
                tahunQuery: thnGet
            }, "dsChart1", "loadingChart1")
        }
    }
    function chart2(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart2", {
                tipe: tahunGet ? "1" : type,
                tahunQuery: thnGet
            }, "dsChart2", "loadingChart2")
        }
    }
    function chart3(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart3", {
                tipe: tahunGet ? "1" : type,
                tahunQuery: thnGet
            }, null, "loadingChart3").then((resp) => {
                if (resp.code === "00") {
                    var arrayname = resp.data.data_chart.map((d, i) => {
                        return d.name
                    })
                    var ob = {}
                    var arg = arrayname.map((d, i) => {
                        ob[d] = null;
                        return {
                            name: d,
                            valueField: d,
                        }
                    })
                    var newDs = [];
                    for (let i = 1; i <= 4; i++) {
                        var obj = {
                            state: i,
                            stateName: i > 3 ? "> 3 Tahun" : i + " Tahun",
                            ...ob,
                            total: 0

                        }
                        resp.data.data_chart.map((d, j) => {
                            obj[d.name] = d['data_' + i];
                            obj.total = obj.total + (d['data_' + i] || 0)
                        })
                        newDs.push(obj);
                    }
                    dispatch({
                        type: "getSuccess",
                        payload: { dsChart3: newDs, loadingGet: false }
                    })
                    dispatch({
                        type: "getSuccess",
                        payload: { yargChart3: arg, loadingGet: false }
                    })
                }
            })
        }
    }
    function chart4(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart4", {
                tipe: tahunGet ? "1" : type,
                tahunQuery: thnGet
            }, null, "loadingChart4").then((resp) => {
                if (resp.code === "00") {
                    var fieldName = ["InHouse", "Procurement", "Partnership", "Swakelola"]
                    var arrayname = resp.data.data_chart.map((d, i) => {
                        return d.name
                    })
                    var ob = {}
                    var arg = arrayname.map((d, i) => {
                        ob[d] = null;
                        return {
                            name: d,
                            valueField: d,
                        }
                    })
                    var newDS = []
                    for (let i = 0; i < fieldName.length; i++) {
                        var obj = {
                            state: fieldName[i],
                            ...ob,
                            total: 0,

                        }
                        resp.data.data_chart.map((d, j) => {
                            obj[d.name] = d[fieldName[i]];
                            obj.total = obj.total + (d[fieldName[i]] || 0)
                        })
                        newDS.push(obj);
                    }
                    dispatch({
                        type: "getSuccess",
                        payload: { dsChart4: newDS, loadingGet: false }
                    })
                    dispatch({
                        type: "getSuccess",
                        payload: { yargChart4: arg, loadingGet: false }
                    })
                }
            })
        }
    }
    function chart5(tahunGet) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart5", {
                tahunQuery: thnGet
            }, null, "loadingChart5").then((resp) => {
                if (resp.code === "00") {
                    var fieldName = ['Universitas', 'Lembaga Penelitian', 'Entitas Bisnis']
                    var arrayname = resp.data.data_chart.map((d, i) => {
                        return d.name
                    })
                    var ob = {}
                    var arg = arrayname.map((d, i) => {
                        ob[d] = null;
                        return {
                            name: d,
                            valueField: d,
                        }
                    })
                    var newDS = []
                    for (let i = 0; i < fieldName.length; i++) {
                        var obj = {
                            state: fieldName[i],
                            ...ob,
                            total: 0,
                        }
                        resp.data.data_chart.map((d, j) => {
                            obj[d.name] = d['data_' + (i + 1)];
                            obj.total = obj.total + (d['data_' + (i + 1)] || 0)
                        })
                        newDS.push(obj);
                    }
                    setDsChart5(newDS);
                    setYargChart5(arg)
                }
            })
        }
        // if (data) {
        //     var fieldName = ['Universitas', 'Lembaga Penelitian', 'Entitas Bisnis']
        //     var arrayname = data.map((d, i) => {
        //         return d.name
        //     })
        //     var ob = {}
        //     var arg = arrayname.map((d, i) => {
        //         ob[d] = null;
        //         return {
        //             name: d,
        //             valueField: d,
        //         }
        //     })
        //     var newDS = []
        //     for (let i = 0; i < fieldName.length; i++) {
        //         var obj = {
        //             state: fieldName[i],
        //             ...ob

        //         }
        //         data.map((d, j) => {
        //             obj[d.name] = d['data_' + (i + 1)];
        //         })
        //         newDS.push(obj);
        //     }
        //     setDsChart5(newDS);
        //     setYargChart5(arg)
        // }
    }

    function chart7(data) {
        if (data) {
            var newDS = [{ state: 'Satu Fungsi', val: data[0]['data_1'] }, { state: 'Multi Fungsi', val: data[0]['data_2'] }]
            setDsChart7(newDS);
        }
    }
    function chart8(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart8", {
                area_id: tahunGet ? "1" : type,
                tahunQuery: thnGet
            }, "dsChart8", "loadingChart8")
        }
    }
    function chart9(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart9", {
                tipe: tahunGet ? "1" : type,
                tahunQuery: thnGet
            }, null, "loadingChart9").then((resp) => {
                if (resp.code === "00") {
                    var stateName = ['TRL 1', 'TRL 2', 'TRL 3', 'TRL 4', 'TRL 5', 'TRL 6', 'TRL 7', 'TRL 8', 'TRL 9']
                    var arrayname = resp.data.data_chart.map((d, i) => {
                        return d.name
                    })
                    var ob = {}
                    var arg = type === "4" ? [{
                        name: "Jumlah",
                        valueField: "jumlah",
                    }] : arrayname.map((d, i) => {
                        ob[d] = null;
                        return {
                            name: d,
                            valueField: d,
                        }
                    })
                    var newDS = []
                    for (let i = 0; i < stateName.length; i++) {
                        var obj = {
                            state: stateName[i],
                            ...ob,
                            total: 0,
                        }
                        resp.data.data_chart.map((d, j) => {
                            obj[d.name] = d['data_' + (i + 1)];
                            obj.total = obj.total + (d['data_' + (i + 1)] || 0)
                        })
                        newDS.push(obj);
                    }

                    dispatch({
                        type: "getSuccess",
                        payload: { dsChart9: newDS, loadingGet: false }
                    })
                    dispatch({
                        type: "getSuccess",
                        payload: { yargChart9: arg, loadingGet: false }
                    })
                }
            })
        }
    }
    function chart11(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart11", {
                tipe: tahunGet ? "1" : type,
                tahunQuery: thnGet
            }, null, "loadingChart11").then((resp) => {
                if (resp.code === "00") {
                    var stateName = ['Pendapatan', 'Efisiensi', 'Penambahan Produksi Migas dll', 'Intangible Value'];
                    var arrayname = resp.data.data_chart.map((d, i) => {
                        return d.name
                    })
                    var ob = {}
                    var arg = arrayname.map((d, i) => {
                        ob[d] = null;
                        return {
                            name: d,
                            valueField: d,
                        }
                    })
                    var newDS = []
                    for (let i = 0; i < stateName.length; i++) {
                        var obj = {
                            state: stateName[i],
                            ...ob

                        }
                        resp.data.data_chart.map((d, j) => {
                            obj[d.name] = d['data_' + (i + 1)];
                        })
                        newDS.push(obj);
                    }
                    dispatch({
                        type: "getSuccess",
                        payload: { dsChart11: newDS, loadingGet: false }
                    })
                    dispatch({
                        type: "getSuccess",
                        payload: { yargChart11: arg, loadingGet: false }
                    })
                }
            })
        }
    }
    function chart12(tahunGet, type) {
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart12", {
                func_id: tahunGet ? "" : type,
                tahunQuery: thnGet
            }, "dsChart12", "loadingChart12")
        }
    }
    function chart13(tahunGet, type) {
        setSelCost(type);
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart13", {
                project_type: tahunGet ? "0" : type,
                tahun: thnGet
            }, "dsChart13", "loadingChart13")
        }
    }
    function chart14(tahunGet, type) {
        setSelRisk(type)
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-chart14", {
                project_type: tahunGet ? "0" : type,
                tahun: thnGet
            }, "dsChart14", "loadingChart14")
        }
    }
    function tangibleTable(tahunGet, type) {
        setTangible(type)
        var thnGet = tahunGet ? tahunGet : state.data_tahunGet;
        if (thnGet) {
            action.getData("/api/project-ongoing/dashboard-project-profile-tab-intangible", {
                project_type: tahunGet ? "0" : type,
                tahun: thnGet
            }, "tangibleTable", "tangibleTableLoading")
        }
    }
    function seriesBubleClick(e) {
        const series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }

    return (
        <>
            {
                reporting && (
                    <div className="row mb-5">
                        <div className="col-md-12 text-right">
                            <ExportPdf
                                targetRef={refEx}
                                fileName={`Dashboard Project Profile-${state.data_tahun_default}`}
                                scale={0.8}
                            >
                                <button className="btn btn-sm btn-primary">
                                    Export PDF
                                </button>
                            </ExportPdf>
                        </div>
                    </div>
                )
            }
            <div ref={refEx}>
                <Card
                    className="m-0 p-0  mb-5 customShadow bg-primary"
                >
                    <Card.Body className="p-3">
                        <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                            <div className="col-lg-auto col-md-auto col-sm-6 col-6 mb-3">
                                <div className="d-flex border-right-custom pr-3">
                                    <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Project</span>
                                        <span className="d-block"><strong>{(state.data_JumlahProject?.total || 0) - (state.data_JumlahProject?.continue_request || 0)}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Project Running</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.project_running || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Finished</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.finished || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Aborted</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.aborted || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Review</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.review || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Need Revision</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.need_revision || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Not Yet Reported</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.project_continue || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                                <div className="d-flex border-left-custom border-right-custom px-md-3">
                                    <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                        <span className="d-block">$ <strong>{(state.data_JumlahProject?.total_plan_budget || 0)}/{(state.data_JumlahProject?.total_actual_budget || 0)}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto col-md-auto col-sm-6 col-6 mb-3">
                                <div className="text-white rounded p-2 d-flex flex-column" style={{ backgroundColor: '#F6406A' }}>
                                    <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex" >
                                        Need Attention
                                        <Link
                                            to={`/ongoing/table-detail-search/${state?.data_tahun_default}/${state?.data_bulan_default}?q=${btoa('3/0/0/0/1')}`}
                                            target={"_blank"}>
                                            <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                        </Link>
                                    </span>
                                    <span className="ctsmFilterTitle text-tittle-card mt-auto">{state.data_need_attention || 0}</span>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <div className="row mt-5 mb-5">
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Jumlah Proyek"
                            subtitle="Statistik proyek berdasarkan tipe proyek"
                            option={usulanProyek}
                            onChange={function (e) { chart1(null, e) }}
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="1"
                                        loading={state.loadingAll || state.loadingChart1}
                                        argumentField="name"
                                        dataSource={[...(state.dsChart1?.data_chart || [])]}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah",
                                                color: '#326BD5'
                                            }
                                        ]}
                                    />
                                ), [state.loadingAll, state.loadingChart1, state.dsChart1?.data_chart])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Anggaran"
                            subtitle="Jumlah anggaran proyek inovasi dan operasional"
                            option={usulanAnggaran}
                            onChange={function (e) { chart2(null, e) }}
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="2"
                                        argumentField="name"
                                        loading={state.loadingAll || state.loadingChart2}
                                        dataSource={[...(state.dsChart2?.data_chart || [])]}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah_des",
                                                color: '#ACC429'
                                            }
                                        ]}
                                    />
                                ), [state.loadingAll, state.loadingChart2, state.dsChart2?.data_chart])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Perioda Pelaksaan Proyek"
                            subtitle="Jumlah proyek berdasarkan periode pelaksanannya s.d komersial/terimplementasi"
                            customOption={
                                <div>
                                    <select className='form-control form-control-sm'
                                        onChange={(e) => chart3(null, e.target.value)}
                                    >
                                        {
                                            metodePelaksanaan.map((d, i) => (
                                                <option key={i} value={d?.value}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                    <SwitchBtn
                                        className='my-2'
                                        value={swChart3}
                                        onChange={(val) => setSwChart3(val)} />
                                </div>
                            }
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        showTotal={true}
                                        labelChart={true}
                                        loading={state.loadingAll || state.loadingChart3}
                                        argumentField="stateName"
                                        type="stackedBar"
                                        dataSource={[...(state.dsChart3 || [])]}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        onRenderLabelChart={(e) => swChart3 ? e?.value : null}
                                        onRenderTooltip={(e) => (`${e.seriesName}: ${e.valueText}`)}
                                        rotate={true}
                                        yAxisArgs={!swChart3 ? [{
                                            name: "Jumlah",
                                            valueField: "total",
                                        }] : state.yargChart3}
                                    />
                                ), [state.loadingAll, state.loadingChart3, state.dsChart3, state.yargChart3, swChart3])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Dukungan Operasional/ Sub-holding"
                            subtitle="Jumlah keterlibatan operasional/ sub-holding untuk mendukung proyek inovasi RTI"
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="5"
                                        loading={state.loadingAll}
                                        dataSource={[...(state.data_chart6_DukunganOperasional || [])]}
                                        overlappingBehavior="stagger"
                                        colorBarDifferent={true}
                                        argumentField="name"
                                        colorBarDiffirentField="name"
                                        coloBarDifferentValueField="jumlah"
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}

                                    />
                                ), [state.loadingAll, state.data_chart6_DukunganOperasional])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Kategori Inovasi"
                            subtitle="Jumlah proyek inovasi berdasarkan area dan kategori inovasi"
                            option={state.data_area}
                            onChange={function (e) { chart8(null, e) }}
                        >
                            <div className="row">
                                <div className="col-9">
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                loading={state.loadingAll || state.loadingChart8}
                                                id="kategory"
                                                argumentField="name"
                                                dataSource={[...(state.dsChart8?.data_chart || [])]}
                                                overlappingBehavior="rotate"
                                                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                                onrenderLabel={(e) => `${e.valueText?.substr(0, 8)}`}
                                                withTitle={false}
                                                showLegend={false}
                                                toolsExport={false}
                                                yAxisArgs={[
                                                    {
                                                        valueField: "jumlah",
                                                        color: "#775DD0"
                                                    }
                                                ]} />
                                        ), [state.loadingAll, state.loadingChart8, state.dsChart8?.data_chart])
                                    }

                                </div>
                                <div className="col-3 p-0">
                                    {
                                        [...(state.data_chart8a_KategoriInovasi || [])].map((d, i) => (
                                            <div key={i} className={`rounded ${colorX[i]}`} style={{ color: 'white', padding: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'space-between' }}>
                                                <span style={{ fontSize: 9, width: '50px' }}
                                                >
                                                    {d.name}
                                                </span>
                                                <span style={{ fontSize: 11, fontWeight: 'bold' }}>
                                                    {
                                                        d.jumlah
                                                    }
                                                </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Kebutuhan Tenaga Ahli Proyek"
                            subtitle="Perbandingan antara jumlah tenaga ahli yang diperlukandengan ketersediaan tenaga ahli di RTI"
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="5"
                                        loading={state.loadingAll}
                                        argumentField="name"
                                        dataSource={state.data_chart10_KebutTenagaAhli}
                                        overlappingBehavior="rotate"
                                        rotationAngle={45}
                                        onRenderTooltip={(e) => (`${e.argumentText}: ${e.valueText}`)}
                                        onrenderLabel={(e) => `${e.valueText?.substr(0, 8)}`}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        LegenditemTextPosition="right"
                                        onRenderLabelChart={(e) => {
                                            if (e.seriesName === "Expertise proyek") {
                                                return e.value;
                                            } else {
                                                return "";
                                            }
                                        }}
                                        yAxisArgs={[
                                            {
                                                valueField: "data_1",
                                                name: 'Expertise proyek',
                                                type: "bar",
                                                color: '#326BD5'
                                            },
                                            {
                                                valueField: "data_2",
                                                name: 'Ketersediaan expertise',
                                                type: "spline",
                                                color: '#01E396'
                                            }
                                        ]}

                                    />
                                ), [state.loadingAll, state.data_chart10_KebutTenagaAhli])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5" >
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Metode Pelaksanaan Proyek"
                            subtitle="Jumlah proyek inovasi berdasarkan metode pelaksanaannya"
                            customOption={
                                <div>
                                    <select className='form-control form-control-sm'
                                        onChange={(e) => chart4(null, e.target.value)}
                                    >
                                        {
                                            metodePelaksanaan.map((d, i) => (
                                                <option key={i} value={d?.value}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                    <SwitchBtn
                                        className='my-2'
                                        value={swChart4}
                                        onChange={(val) => setSwChart4(val)} />
                                </div>
                            }
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="3"
                                        showTotal={true}
                                        loading={state.loadingAll || state.loadingChart4}
                                        argumentField="state"
                                        type="stackedBar"
                                        overlappingBehavior="none"
                                        onrenderLabel={(e) => `${e.value === "SemiPartnership" ? "Swakelola" : e.value}`}
                                        onRenderTooltip={(e) => ({ text: `${e.argumentText === "SemiPartnership" ? "Swakelola" : e.argumentText} ; ${e.seriesName} ; ${e.value}` })}
                                        dataSource={state.dsChart4}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        yAxisArgs={!swChart4 ? [{
                                            name: "Jumlah",
                                            valueField: "total",
                                        }] : state.yargChart4}
                                    />
                                ), [state.loadingAll, state.loadingChart4, state.dsChart4, state.yargChart4, swChart4])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Partner"
                            subtitle="Jumlah proyek berdasarkan klasifikasi partner"
                            customOption={
                                <div>
                                    <SwitchBtn
                                        className='my-2'
                                        value={swChart5}
                                        onChange={(val) => setSwChart5(val)} />
                                </div>
                            }
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        loading={state.loadingChart5}
                                        id="4"
                                        showTotal={true}
                                        argumentField="state"
                                        type="stackedBar"
                                        dataSource={dsChart5}
                                        withTitle={false}
                                        showLegend={true}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        LegenditemTextPosition="right"
                                        toolsExport={false}
                                        yAxisArgs={!swChart5 ? [{
                                            name: "Jumlah",
                                            valueField: "total",
                                        }] : yargChart5}
                                    />
                                ), [state.loadingAll, yargChart5, swChart5])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-4 mb-5">
                        <CstmFilterCahrtDx
                            title="Sinergisitas"
                            subtitle="Sinergisitas pelaksanaan proyek oleh fungsi-fungsi RTI"
                        >
                            {
                                React.useMemo(() => (
                                    <DoughnatDx
                                        withTitle={false}
                                        loading={state.loadingAll}
                                        dataSource={dsChart7}
                                        showLegend={true}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        LegenditemTextPosition="top"
                                        toolsExport={false}
                                        onRenderLabelChart={(e) => (`${parseFloat(e.percentText).toFixed(0)}%`)}
                                        onRenderTooltip={(e) => ({ text: `${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                                        yAxisArgs={[
                                            {
                                                valueField: "val",
                                                argumentField: "state",
                                                color: null,
                                                format: null
                                            }
                                        ]}

                                    />
                                ), [state.loadingAll, dsChart7])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-6 mb-5 mb-sm-5">
                        <CstmFilterCahrtDx
                            title="Tingkat Kesiapan Teknologi"
                            subtitle="Jumlah proyek inovasi berdasarkan tingkat kesiapan teknologi"
                            customOption={
                                <div>
                                    <select className='form-control form-control-sm'
                                        onChange={(e) => chart9(null, e.target.value)}
                                    >
                                        {
                                            KesiapanTek.map((d, i) => (
                                                <option key={i} value={d?.value}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                    <SwitchBtn
                                        className='my-2'
                                        value={swChart9}
                                        onChange={(val) => setSwChart9(val)} />
                                </div>
                            }
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        id="3"
                                        showTotal={true}
                                        loading={state.loadingAll || state.loadingChart9}
                                        argumentField="state"
                                        type="stackedBar"
                                        dataSource={state.dsChart9}
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        yAxisArgs={!swChart9 ? [{
                                            name: "Jumlah",
                                            valueField: "total",
                                        }] : state.yargChart9}
                                    />
                                ), [state.loadingAll, state.loadingChart9, state.dsChart9, state.yargChart9, swChart9])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                    <div className="col-md-6 mb-5 col-sm-12 mb-sm-5">
                        <CstmFilterCahrtDx
                            title="10 Besar Project Leader Terbanyak"
                            subtitle="10 Besar Project Leader Terbanyak"
                            customOption={
                                <div className='d-inline-flex'>
                                    <select className='form-control form-control-sm' onChange={(e) => chart12(null, e.target.value)}>
                                        {
                                            [{ name: 'All', value: '' }, ...(state.data_function || [])].map((d, i) => (
                                                <option key={i} value={d.value}>{d.name}</option>
                                            ))
                                        }
                                    </select>
                                    <Link
                                        target={'_blank'}
                                        to={`/project-ongoing/tabel-dashboard-project-profile-chart12/${state.data_tahunGet}`}
                                        className="d-flex my-auto ">
                                        <i className="far fa-arrow-alt-circle-right icon-lg text-primary ml-2"></i>
                                    </Link>
                                </div>
                            }
                        >
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        showTotal={true}
                                        loading={state.loadingAll || state.loadingChart12}
                                        colorBarDifferent={true}
                                        argumentField="name"
                                        colorBarDiffirentField="name"
                                        coloBarDifferentValueField="jumlah"
                                        dataSource={[...(state.dsChart12?.data_chart1_JumlUsulanProyek || [])].sort((a, b) => parseFloat(a.jumlah) - parseFloat(b.jumlah))}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        onRenderLabelChart={() => { }}
                                        onRenderTooltip={(e) => (`${e.seriesName}: ${e.valueText}`)}
                                        rotate={true}

                                    />
                                ), [state.loadingAll, state.loadingChart12, state.dsChart12?.data_chart1_JumlUsulanProyek])
                            }
                        </CstmFilterCahrtDx>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-12 mb-5">
                        <div className="rounded p-5 bg-white customShadow pt-2 pb-2">
                            <Tab
                                withCard={false}
                                itemsTab={[
                                    {
                                        id: "cost",
                                        title: <span>Benefit Cost Analysis <i title="Perbandingan antara biaya dan manfaat terhadap proyek yang diusulkan" className="fas fa-info-circle icon-nm mx-1"></i></span>
                                    },
                                    {
                                        id: "risk",
                                        title: <span>Benefit Risk Analysis <i title="Perbandingan antara resiko dan manfaat terhadap proyek yang diusulkan" className="fas fa-info-circle icon-nm mx-1"></i></span>
                                    },
                                    {
                                        id: "intangible",
                                        title: "Intangible Value"
                                    }
                                ]}
                            >
                                <TabContent id="cost">
                                    <div className="row p-2">
                                        <div className="col-md-12">
                                            <CstmFilterCahrtDx
                                                title="Benefit Cost Analysis"
                                                withBorder={false}
                                                subtitle=""
                                                cardStyle={false}
                                                option={[{ name: 'RTI', value: '0' }, ...[...(state.project_type || [])].map(d => {
                                                    return {
                                                        name: d.title,
                                                        value: d.param_id?.toString()
                                                    }
                                                })].filter(d => d.value !== '4' && d.value !== '5' && d.value !== '6')}
                                                value={selCost}
                                                onChange={(e) => chart13(null, e)}
                                            >
                                                {
                                                    React.useMemo(() => (
                                                        <ChartDx
                                                            id="5"
                                                            height={300}
                                                            type="bubble"
                                                            loading={state.loadingAll || state.loadingChart13}
                                                            dataSource={[...(state.dsChart13?.data_chart || [])]}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onRenderTooltip={(e) => ({ text: `<b>Title</b> : ${e.point.data?.title}<br/> <b>Jumlah Budget</b> : ${e.argumentText} <br/> <b>Tangible Value</b> : ${e.originalValue} <br/> <b>Jangka Waktu</b> : ${e.sizeText}` })}
                                                            onRenderLabelChart={(e) => { }}
                                                            withTitle={false}
                                                            showLegend={true}
                                                            onSeriesClick={seriesBubleClick}
                                                            toolsExport={false}
                                                            yTitle="Tangible Value"
                                                            xTitle="Total Budget"
                                                            overlappingBehavior="rotate"
                                                            rotationAngle={45}
                                                            yAxisArgs={[...(state.data_area || [])].map((d, i) => {
                                                                return {
                                                                    argumentField: 'jumlah_budget' + d.value,
                                                                    valueField: "tangible_angka" + d.value,
                                                                    sizeField: "jangka_waktu" + d.value,
                                                                    tagField: "area_name" + d.value,
                                                                    name: d.name,
                                                                    color: chart_color[i]

                                                                }
                                                            })}

                                                        />
                                                    ), [state.loadingAll, state.loadingChart13, state.dsChart13?.data_chart, state.data_area])
                                                }
                                            </CstmFilterCahrtDx>
                                        </div>
                                    </div>
                                </TabContent>
                                <TabContent id="risk">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <CstmFilterCahrtDx
                                                title="Benefit Risk Analysis"
                                                withBorder={false}
                                                cardStyle={false}
                                                option={[{ name: 'RTI', value: '0' }, ...[...(state.project_type || [])].map(d => {
                                                    return {
                                                        name: d.title,
                                                        value: d.param_id.toString()
                                                    }
                                                })].filter(d => d.value !== '4' && d.value !== '5' && d.value !== '6')}
                                                value={selRisk}
                                                onChange={(e) => chart14(null, e)}
                                            >
                                                {
                                                    React.useMemo(() => (
                                                        <ChartDx
                                                            id="5"
                                                            height={300}
                                                            type="bubble"
                                                            loading={state.loadingAll || state.loadingChart14}
                                                            dataSource={[...(state.dsChart14?.data_chart || [])]}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onRenderTooltip={(e) => ({ text: `<b>Title</b> : ${e.point.data?.title}<br/> <b>Jumlah Budget</b> : ${e.argumentText} <br/> <b>Risk Priority</b> : ${e.originalValue} <br/> <b>Jangka Waktu</b> : ${e.sizeText}` })}
                                                            onRenderLabelChart={(e) => { }}
                                                            withTitle={false}
                                                            showLegend={true}
                                                            onSeriesClick={seriesBubleClick}
                                                            toolsExport={false}
                                                            yTitle="Risk Priority"
                                                            xTitle="Total Budget"
                                                            overlappingBehavior="rotate"
                                                            rotationAngle={45}
                                                            yAxisArgs={[...(state.data_area || [])].map((d, i) => {
                                                                return {
                                                                    argumentField: 'jumlah_budget' + d.value,
                                                                    valueField: "risk" + d.value,
                                                                    sizeField: "jangka_waktu" + d.value,
                                                                    tagField: "area_name" + d.value,
                                                                    name: d.name,
                                                                    color: chart_color[i]

                                                                }
                                                            })}
                                                        />
                                                    ), [state.loadingAll, state.loadingChart14, state.dsChart14?.data_chart, state.data_area])
                                                }

                                            </CstmFilterCahrtDx>
                                        </div>
                                    </div>
                                </TabContent>
                                <TabContent id="intangible">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <CstmFilterCahrtDx
                                                withBorder={false}
                                                title="Intangible value"
                                                cardStyle={false}
                                                option={[{ name: 'RTI', value: '0' }, ...[...(state.project_type || [])].map(d => {
                                                    return {
                                                        name: d.title,
                                                        value: d.param_id.toString()
                                                    }
                                                })].filter(d => d.value !== '4' && d.value !== '5' && d.value !== '6')}
                                                value={seltangible}
                                                onChange={(e) => tangibleTable(null, e)}
                                            >
                                                {
                                                    React.useMemo(() => (
                                                        <GridDx
                                                            searchForm={true}
                                                            data={state.tangibleTable?.data_table}
                                                            loading={state.loadingAll || state.tangibleTableLoading}
                                                            columns={columns}
                                                        >

                                                        </GridDx>
                                                    ), [state.loadingAll, state.tangibleTableLoading, columns, state.tangibleTable?.data_table])
                                                }
                                            </CstmFilterCahrtDx>
                                        </div>
                                    </div>
                                </TabContent>
                            </Tab>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
export default TabProfile;