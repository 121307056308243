import { Autocomplete, FileUploader } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import { Card } from 'react-bootstrap';
import { FileUploadDx } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { toString } from 'lodash';

function ModalKerjasamaDetailEdit({ mode, data, id_kerjasama, onSave = (e) => { } }) {
    const [state, dispatch, action] = useGlobalState();
    const [jenis_dokumen, setJenis_dokumen] = useState();
    const [status_dokumen, setStatus_dokumen] = useState();
    const [berlaku_start, setBerlaku_start] = useState('');
    const [berlaku_end, setBerlaku_end] = useState('');
    const [tempRKAP, setTempRKAP] = useState();
    const [tempRKAP_name, setTempRKAP_name] = useState();
    const [kerjasama_rkaps, setKerjasama_rkaps] = useState();
    const [id_edit, setId_edit] = useState();
    const [kerjasama_id, setKerjasama_id] = useState(id_kerjasama);
    const [kerjasama_dokumen_file, setKerjasama_dokumen_file] = useState([]);
    const [kerjasama_dokumen_files_delete, setkerjasama_dokumen_files_delete] = useState([]);
    const refFile = useRef();
    useEffect(() => {
        if (state.modal.modalDetailEdit === true) {
            resetData();
            getSumberData();
            if (mode === "Edit") {
                setdataEdit();
            }
        }
    }, [state.modal.modalDetailEdit])

    function resetData() {
        setJenis_dokumen('');
        setStatus_dokumen('');
        setBerlaku_start('');
        setBerlaku_end('');
        setKerjasama_rkaps('');
        setTempRKAP('');
        setTempRKAP_name('');
        setId_edit('');
        setKerjasama_id(id_kerjasama);
        setKerjasama_dokumen_file([]);
        setkerjasama_dokumen_files_delete([]);
        if (refFile.current)
                refFile.current.instance.reset();
    }
    function getSumberData() {
        action.getData('/api/ext-partnership/add-dokumen');
    }
    function rkapAdd(rkap) {
        const data = {
            "id": '',
            "kerjasama_dokumen_id": id_edit || '0',
            "research_id": rkap?.id,
            "show_research": rkap?.name
        }
        const t = kerjasama_rkaps || [];
        if (rkap && !t.find(d => {
            return d.research_id === data?.research_id
        })) {
            setTempRKAP_name('');
            setKerjasama_rkaps([data, ...t]);
        }
    }
    function rkapDelete(rkap) {
        const t = kerjasama_rkaps || [];
        if (rkap) {
            setKerjasama_rkaps(t.filter(d => d?.research_id?.toString() !== rkap?.research_id?.toString()));
        }
    }
    function buttonSave() {
        console.log(berlaku_start, berlaku_end);
        const data = {
            "id": id_edit || '',
            "kerjasama_id": kerjasama_id,
            "jenis_dokumen": jenis_dokumen || '',
            "status_dokumen": status_dokumen || '',
            "berlaku_start": berlaku_start ? moment(berlaku_start).format('YYYY-MM-DD') : '',
            "berlaku_end": berlaku_start ? moment(berlaku_end).format('YYYY-MM-DD') : '',
            "created_at": "",
            // "kerjasama_rkaps": kerjasama_rkaps || [],
            "kerjasama_dokumen_files": kerjasama_dokumen_file || [],
            "list_file": kerjasama_dokumen_file || [],
            "list_file_delete": kerjasama_dokumen_files_delete || []
        }
        const val = validation(data);
        if (val.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: val.message,
                icon: 'warning',
            })
        } else {
            onSave(data);
        }
    }
    function validation(data) {
        var isValid = true;
        var message = '';
        if (data?.jenis_dokumen === '') {
            isValid = false;
            message = 'Lengkapi Semua data';
        }
        if (data?.status_dokumen === '') {
            isValid = false;
            message = 'Lengkapi Semua data';
        }
        // if (data?.kerjasama_rkaps?.length === 0) {
        //     isValid = false;
        //     message = 'Lengkapi Semua data';
        // }
        if (data?.kerjasama_dokumen_files?.length === 0) {
            isValid = false;
            message = 'Lengkapi Semua data';
        }
        if (isValid) {
            if (data?.status_dokumen === '5' || data?.status_dokumen === '6' || data?.status_dokumen === '7') {
                if (data?.berlaku_start === '' || data?.berlaku_end === '') {
                    isValid = false;
                    message = 'Masa berlaku tidak boleh kosong';
                } else {
                    if (moment(data?.berlaku_end).isBefore(data?.berlaku_start)) {
                        isValid = false;
                        message = "Masa berlaku salah";
                    }
                }
            }
        }
        return {
            isValid,
            message
        }
    }
    function fileOnchange(data) {
        const file = data.value?.[0]
        if (file && file.size <= 15000000) {
            action.postData(
                {
                    url: "/api/base/upload-file-temp",
                    data: {
                        "File": file,
                    },
                    withFile: true,
                    withLoading: false,
                    withAlert: false,
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    const newData = [...(kerjasama_dokumen_file || [])]

                    setKerjasama_dokumen_file([
                        ...newData,
                        {
                            "id": '',
                            "kerjasama_dokumen_id": id_edit,
                            "file_location": '',
                            "file_name": resp.data?.data_filename,
                        }
                    ])
                }
            })
        } else if (file && file.size > 15000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 15Mb",
                icon: 'error',
            })
        }
    }
    function onFileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            const newData = [...(kerjasama_dokumen_file || [])]
            setKerjasama_dokumen_file([
                ...newData,
                {
                    "id": '',
                    "kerjasama_dokumen_id": id_edit,
                    "file_location": resp.data?.data_file_location,
                    "file_name": resp.data?.data_filename,
                    "is_new": true
                }
            ]);
        }
    }
    function deletedDocument(key, data) {
        const newData = kerjasama_dokumen_file || [];
        if (data?.is_new === false) setkerjasama_dokumen_files_delete((e) => [...e, { ...data }]);
        setKerjasama_dokumen_file(newData.filter(d => d?.file_name !== data?.file_name));
    }
    function setdataEdit() {
        const rkaps_id = (data?.rkap_id?.split(';') || []);
        const rkaps = (data?.rkap?.split(';') || [])?.map((d, i) => ({
            show_research: d,
            research_id: rkaps_id[i]
        }))
        const file_loc = (data?.file_location?.split(';') || []);
        const file = (data?.file_name?.split(';') || [])?.map((d, i) => ({
            file_location: file_loc[i],
            is_new: false,
            file_name: d
        }));
        const str = moment(data?.berlaku_start).format('YYYY-MM-DD');
        const end = moment(data?.berlaku_end).format('YYYY-MM-DD');
        setId_edit(data?.id);
        setJenis_dokumen(data?.jenis_dokumen);
        setStatus_dokumen(toString(data?.status_dokumen));
        setBerlaku_start(str === "Invalid date" ? '' : str);
        setBerlaku_end(end === "Invalid date" ? "" : end);
        setKerjasama_rkaps(rkaps);
        setKerjasama_id(id_kerjasama);
        setKerjasama_dokumen_file([...(file || [])]);
    }
    function status_dokumenChange(val) {
        setBerlaku_start('');
        setBerlaku_end('');
        setStatus_dokumen(val)
    }

    return (
        <div>
            {/* <Card
                className="m-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-5"> */}
            <h5>{mode === 'Edit' ? 'Edit' : 'Add'} Dokumen</h5>
            <hr />
            <div className="row mb-3">
                <div className="col-md-3">
                    Jenis Dokumen
                </div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" value={jenis_dokumen} onChange={(e) => setJenis_dokumen(e.target.value)}>
                        <option value="">- Jenis Dokumen - </option>
                        {
                            [...(state.data_jenis || [])].map((d, i) => (
                                <option value={d.param_id} key={i}>{d.title}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    Status
                </div>
                <div className="col-md-9">
                    <select className="form-control form-control-sm" value={status_dokumen} onChange={(e) => status_dokumenChange(e.target.value)}>
                        <option value="">- Status - </option>
                        {
                            [...(state.data_status || [])].map((d, i) => (
                                <option value={d.param_id} key={i}>{d.title}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            {
                (status_dokumen === '5' || status_dokumen === '6' || status_dokumen === '7') && (
                    <div className="row mb-3">
                        <div className="col-md-3">
                            Masa Berlaku
                        </div>
                        <div className="col-md-9">
                            <div className="input-group input-group-sm">
                                <input type="date" className="form-control" placeholder="mulai" value={berlaku_start} onChange={(e) => setBerlaku_start(e.target.value)} />
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="">....</span>
                                </div>
                                <input type="date" className="form-control" placeholder="Sampai Dengan" value={berlaku_end} onChange={(e) => setBerlaku_end(e.target.value)} />
                            </div>
                        </div>
                    </div>
                )
            }
            {/* <div className="row mb-5">
                <div className="col-md-3">
                    RKAP Berkaitan
                </div>
                <div className="col-md-9">
                    <div className="input-group mb-2" >
                        <Autocomplete
                            dataSource={state.data_rkap}
                            value={tempRKAP_name}
                            valueExpr="name"
                            displayExpr="name"
                            placeholder="Pilih dan tekan tombol '+ Add'"
                            onValueChanged={(e) => setTempRKAP_name(e.value)}
                            onSelectionChanged={(e) => setTempRKAP(e.selectedItem)}
                            className="form-control form-control-sm p-0 mr-1">
                        </Autocomplete>
                        <div className="text-right" style={{ width: '65px' }}>
                            <button type="submit" className="btn btn-sm btn-primary" onClick={() => rkapAdd(tempRKAP)}>+ Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row px-3">
                <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}></div>
                <div className="col-md-9">
                    {
                        [...(kerjasama_rkaps || [])].map((d, i) => (
                            <div className="row mb-2" key={i}>
                                <div className="col-md-10">
                                    <span className="d-block text-left mb-3">
                                        <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                        {d.show_research}
                                    </span>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-sm btn-outline-danger" onClick={() => rkapDelete(d)}>
                                        <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-md-3">
                    Dokumen
                </div>
                <div className="col-md-9">
                    <div>
                        <FileUploadDx
                            ref={refFile}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 10 Mb"
                            accept="*"
                            onUploaded={onFileUploaded}
                            maxFileSize={15000000}
                            uploadMode="instantly"
                        />
                        {/* <FileUploader className="custom-dx-fileuploader-file-name"
                            onValueChanged={(e) => fileOnchange(e)}
                            showFileList={false} multiple={false}
                            selectButtonText="Select File"
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 15 Mb" accept="*" maxFileSize={15000000} uploadMode="useForm" ></FileUploader> */}
                    </div>
                    {
                        [...(kerjasama_dokumen_file || [])].map((file, key) => (
                            <div className="d-flex flex-row justify-content-between mb-1">
                                <span style={{ fontSize: '8pt' }}>{file.file_name}</span>
                                <div>
                                    <a target="_blank" href={file.file_location} download>
                                        <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1"></i>
                                    </a>
                                    <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deletedDocument(key, file)}></i>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <hr />
            <div className="row ">
                <div className="col-md-12 text-md-right">
                    <button className="btn btn-sm btn-secondary mx-1"
                        onClick={() => action.toggleModal('modalDetailEdit')}
                    >
                        Kembali
                    </button>
                    <button className="btn btn-sm btn-primary"
                        onClick={() => buttonSave()}
                    >
                        <i className="fa fa-save icon-sm" ></i> Save
                    </button>
                </div>
            </div>
            {/* </Card.Body>
            </Card> */}
        </div>
    );
}

export default ModalKerjasamaDetailEdit;