import React, { createContext, useContext, useEffect, useState } from "react";
import { useGlobalState } from "../../contexts/useGlobalState";
import Swal from 'sweetalert2';

const knoContext = createContext({
    knoState: {},
    knoAction: {}
})

export function KnoProvider({ children }) {
    const [globalState, dispatch, globalActon] = useGlobalState();

    //library
    const [data_pagel, setData_pagel] = useState({});
    const [dataLibrary, setDataLibrary] = useState({});
    const [loadingLibrary, setLoadingLibrary] = useState(false);
    const [larea, setLarea] = useState('0');
    const [lcat, setLcat] = useState('0');
    const [lown, setLown] = useState('0');
    const [lkeyw, setLkeyw] = useState('');
    const [btnLibrary, setBtnLibrary] = useState('0');

    //research
    const [data_pager, setData_pager] = useState({});
    const [dataResearch, setDataResearch] = useState({});
    const [loadingResearch, setLoadingResearch] = useState(false);
    const [dataArea, setDataArea] = useState([]);
    const [dataKategori, setDataKategori] = useState([]);
    const [year_search, setYear_search] = useState('0');
    const [area_search, setArea_search] = useState('0');
    const [status_workflow_search, setStatus_workflow_search] = useState('0');
    const [category_search, setCategory_search] = useState('0');
    const [keyword_search, setKeyword_search] = useState('');
    const [btnResearch, setBtnResearch] = useState('1');

    //LibraryDetail
    const [dataLibraryD, setDataLibraryD] = useState({});
    const [loadingLibraryD, setLoadingLibraryD] = useState(false);

    //ResearchDetail
    const [dataResearchD, setDataResearchD] = useState({});
    const [loadingResearchD, setLoadingResearchD] = useState(false);

    async function getDataResearch(keyword) {
        setLoadingResearch(true)

        try {

            const resp = await globalActon.getData("/api/km-research/table", {
                year_search,
                area_search,
                status_workflow_search,
                category_search,
                keyword_search: keyword
            }, null, null, true)
            if (resp.code === "00") {
                setDataResearch(resp.data);
                setData_pager(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingResearch(false)
    }
    async function getDataLibrary(area_search, cat_search, owner_search, keyword_search) {
        setLoadingLibrary(true)
        try {
            const resp = await globalActon.getData("/api/km-library/table", {
                area_search: area_search,
                cat_search: cat_search,
                owner_search: owner_search,
                keyword_search: keyword_search
            }, null, null, true)
            if (resp.code === "00") {
                setDataLibrary(resp.data);
                setData_pagel(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingLibrary(false)
    }
    async function getArea() {
        try {
            const resp = await globalActon.getData("/api/param-area")
            if (resp.code === "00") {
                setDataArea(resp.data);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getKategori(area_id) {
        if (area_id?.toString() !== '0') {
            try {
                const resp = await globalActon.getData(`/api/param-area-category/${area_id}`)
                if (resp.code === "00") {
                    setDataKategori(resp.data)
                }
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }
        } else {
            setDataKategori([]);
        }
    }
    async function getLibraryDetail(id) {
        setLoadingLibraryD(true)
        try {
            const resp = await globalActon.getData(`/api/km-library/view-2/${id}`, {
            }, null, null, true)
            if (resp.code === "00") {
                setDataLibraryD(resp.data);
                setData_pagel(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingLibraryD(false)
    }
    async function getLibraryDetailAdmin(id) {
        setLoadingLibraryD(true)
        try {
            const resp = await globalActon.getData(`/api/km-library/view/${id}`, {
            }, null, null, true)
            if (resp.code === "00") {
                setDataLibraryD(resp.data);
                setData_pagel(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingLibraryD(false)
    }
    async function getResearchDetail(id) {
        setLoadingResearchD(true)
        try {
            const resp = await globalActon.getData(`/api/km-research/view-2/${id}`, {
            }, null, null, true)
            if (resp.code === "00") {
                setDataResearchD(resp.data);
                setData_pager(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingResearchD(false)
    }
    async function getResearchDetailAdmin(id) {
        setLoadingResearchD(true)
        try {
            const resp = await globalActon.getData(`/api/km-research/view/${id}`, {
            }, null, null, true)
            if (resp.code === "00") {
                setDataResearchD(resp.data);
                setData_pager(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingResearchD(false)
    }
    function areaChange(area_id) {
        setArea_search(area_id);
        setCategory_search('0');
        getKategori(area_id);
    }
    function getChart1() {
        const tmpChart = dataResearch?.data_dataChart1;
        var ds = [];
        if (tmpChart) {
            ds = dataArea.map((d, i) => {
                return {
                    state: d.name,
                    state_id: d.id,
                    value: tmpChart[`area${d.id}`]
                }
            })
        }
        return ds;

    }
    function getChart2() {
        const tmpChart = dataResearch?.data_dataChart2;
        var ds = [];
        if (tmpChart) {
            ds = [
                { state: 'Project Running', value: tmpChart.project_running },
                { state: 'Finished', value: tmpChart.finished },
                { state: 'Aborted', value: tmpChart.aborted },
            ]
        }
        return ds;

    }
    function onEnterLibrary(e) {
        if (e?.key === 'Enter') {
            setLkeyw(e.target.value);
            getDataLibrary(larea, btnLibrary, lown, e.target.value);
        }
    }
    function onEnterResearch(e) {
        if (e?.key === 'Enter') {
            setKeyword_search(e.target.value);
            getDataResearch(e.target.value);
        }
    }
    // useEffect(() => {
    //     getDataResearch();
    // }, [year_search, area_search, status_workflow_search, category_search, keyword_search]);

    return <knoContext.Provider value={{
        knoState: {
            dataLibrary,
            dataResearch,
            dataLibraryD,
            loadingLibraryD,
            dataResearchD,
            loadingResearchD,
            loadingLibrary,
            loadingResearch,
            btnResearch,
            btnLibrary,
            dataArea,
            dataKategori,
            year_search,
            area_search,
            category_search,
            status_workflow_search,
            keyword_search,
            larea,
            lown,
            lcat,
            lkeyw,
            data_pagel,
            data_pager

        },
        knoAction: {
            getDataResearch,
            getDataLibrary,
            getLibraryDetail,
            getLibraryDetailAdmin,
            getResearchDetail,
            getResearchDetailAdmin,
            setBtnResearch,
            setBtnLibrary,
            getArea,
            getKategori,
            setYear_search,
            areaChange,
            setStatus_workflow_search,
            setCategory_search,
            setKeyword_search,
            getChart1,
            getChart2,
            setLarea,
            setLown,
            setLcat,
            setLkeyw,
            onEnterLibrary,
            onEnterResearch
        }
    }}>
        {children}
    </knoContext.Provider>
}
export default function useKnowledgeMng() {
    return useContext(knoContext)
}