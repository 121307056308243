import React from 'react';
import { Modal } from 'react-bootstrap';
import { RadioGrp } from '../../../components';
import useApprovalKerjasama from '../useApprovalKerjasama';

const iniStateRadioOptionSa = [
    { label: '1', value: '1', hint: 'Memiliki Kapabilitas Offtake' },
    { label: '2', value: '2', hint: 'Memiliki Kapabilitas Pasar' },
    { label: '3', value: '3', hint: 'Bersedia sharing cost penelitian' },
    { label: '4', value: '4', hint: 'Memiliki Teknologi' },
    { label: '5', value: '5', hint: 'Memiliki kapabilitas dan reputasi pada riset terkait' },
]
const iniStateRadioOptionCs = [
    { label: '1', value: '1', hint: '>10 Billion USD' },
    { label: '2', value: '2', hint: '2 - 10 Billion USD' },
    { label: '3', value: '3', hint: '251 - 1 Billion USD' },
    { label: '4', value: '4', hint: '51 - 250 Billion USD' },
    { label: '5', value: '5', hint: '0 - 50 Billion USD' },
]

function ModalRejected() {
    const {
        appKrjState: {
            modalWaiting: {
                show = false,
                data = {}
            },
        },
        appKrjAction: {
            confirmWaiting = () => null,
            handleCloseModalWaiting = () => null,
        }
    } = useApprovalKerjasama();
    const GeneratePartnerTable = (dt = '', key) => {
        const bantu = dt.split('|');
        const country = (data?.country || '')?.split(';');
        const province = (data?.province || '')?.split(';');
        const partner = {
            nama_partner: (bantu[0] || ''),
            score_sa: (bantu[1] || '')?.split('=')[1],
            score_cs: (bantu[2] || '')?.split('=')[1],
            country: country[key],
            province: province[key]
        }
        return (
            <tr key={key}>
                <th scope="row">{key + 1}</th>
                <td>{partner.nama_partner || ''}</td>
                <td className='text-right'>{partner.score_sa || ''}</td>
                <td className='text-right'>{partner.score_cs || ''}</td>
                <td>{partner.country || ''}</td>
                <td>{partner.province || ''}</td>
            </tr>
        )
    }
    const GeneratePartner = (dt = '', key) => {
        const bantu = dt.split('|');
        const country = (data?.country || '')?.split(';');
        const province = (data?.province || '')?.split(';');
        const partner = {
            nama_partner: (bantu[0] || ''),
            score_sa: (bantu[1] || '')?.split('=')[1],
            score_cs: (bantu[2] || '')?.split('=')[1],
            country: country[key],
            province: province[key]
        }
        return (
            <li key={key} className="mb-1">
                <div className="row px-3 mb-0">
                    <div className="col text-left">
                        <span className="d-block text-left mb-3  font-weight-bold">
                            {partner?.nama_partner}
                        </span>
                    </div>
                </div>
                <form className='px-5 mb-3'>
                    <div className="form-group row mb-0">
                        <label htmlFor="status" className="col-sm-4 col-form-label text-sm-right text-md-right text-lg-right text-content-partners">Strategic Advantage :</label>
                        <div className="col-sm-8 my-auto">
                            <div className="form-control border-0" id="status">
                                <RadioGrp
                                    modeRow={true}
                                    className="text-content-partners-grp"
                                    disable={true}
                                    value={partner?.score_sa}
                                    data={iniStateRadioOptionSa}
                                    withPopup={true}
                                    contentPopups={(props) => <span>{props?.hint}</span>}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <label htmlFor="status" className="col-sm-4 col-form-label text-sm-right text-md-right text-lg-right text-content-partners">Company Size :</label>
                        <div className="col-sm-8 my-auto">
                            <div className="form-control border-0" id="status">
                                <RadioGrp
                                    modeRow={true}
                                    disable={true}
                                    value={partner?.score_cs}
                                    className="text-content-partners-grp"
                                    data={iniStateRadioOptionCs}
                                    withPopup={true}
                                    contentPopups={(props) => <span>{props?.hint}</span>}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row mb-1">
                        <label htmlFor="status" className="col-sm-4 col-form-label text-sm-right text-md-right text-lg-right text-content-partners"> Country Base :</label>
                        <div className="col-sm-8 my-auto">
                            <div className="form-control border-0" id="status">
                                <span className='badge badge-secondary'>{partner?.country}</span>
                            </div>
                        </div>
                    </div>
                    {
                        partner?.country === 'Indonesia' && (
                            <div className="form-group row mb-0">
                                <label htmlFor="status" className="col-sm-4 col-form-label text-sm-right text-md-right text-lg-right text-content-partners">Province :</label>
                                <div className="col-sm-8 my-auto">
                                    <div className="form-control border-0" id="status">
                                        <span className='badge badge-secondary'>{partner?.province}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </form>
                <hr className='mx-3' />
            </li>
        )
    }

    return (
        <Modal backdropClassName='' show={show} onHide={handleCloseModalWaiting} size="lg">
            <Modal.Header>
                <Modal.Title>Partnership Detail [B2-003-G40000-2023-S9-Form: 2]</Modal.Title>
                <i role={'button'} className='fas fa-times' onClick={handleCloseModalWaiting}></i>
            </Modal.Header>
            <Modal.Body>
                <div className="row px-3">
                    <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}><b>Judul Kerjasama</b></div>
                    <div className="col-md ">
                        <div className="form-group">
                            <span>{data?.judul}</span>
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}><b>Project Cluster</b></div>
                    <div className="col-md ">
                        <div className="form-group">
                            <span>{data?.project_cluster_name}</span>
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}><b>Number of Project</b></div>
                    <div className="col-md ">
                        <div className="form-group">
                            <span>{data?.tpta}</span>
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}><b>Fungsi Manager</b></div>
                    <div className="col-md ">
                        <div className="form-group">
                            {
                                [...(data?.chief_name?.split(';') || [])].map((d, i) => (
                                    <span className='badge badge-secondary mr-1' key={i}>{d}</span>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col-md-3 text-left mb-3" style={{ fontSize: '8pt' }}><b>Nama Partner</b></div>
                    <div className="col-md-12">
                        <table className="table table-bordered" style={{ 'fontSize': '8pt' }}>
                            <thead>
                                <tr className='bg-blue'>
                                    <th scope="col" style={{ 'fontSize': '8pt' }}>#</th>
                                    <th scope="col" style={{ 'fontSize': '8pt' }}>Nama Partner</th>
                                    <th scope="col" style={{ 'fontSize': '8pt' }}>Strategic Advantage</th>
                                    <th scope="col" style={{ 'fontSize': '8pt' }}>Company Size</th>
                                    <th scope="col" style={{ 'fontSize': '8pt' }}>Country</th>
                                    <th scope="col" style={{ 'fontSize': '8pt' }}>Province</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    [...((data?.nama_partner || '')?.split(';') || [])].map((d, i) => GeneratePartnerTable(d, i))
                                }
                            </tbody>
                        </table>
                        {/* <div className="form-group">                            
                            <ul className='px-5'>
                                {
                                    ((data?.nama_partner || '')?.split(';') || []).map((d, i) => GeneratePartner(d, i))
                                }
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col-md-3 text-left mb-3" style={{ fontSize: '8pt' }}><b>Project Priority</b></div>
                    <div className="col-md-12">
                        <table className="table table-bordered" style={{ 'fontSize': '8pt' }}>
                            <thead>
                                <tr className='bg-blue'>
                                    <th colSpan={2} scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Scoring</th>
                                    <th scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='align-middle text-center font-weight-bold' rowSpan={3}>Partner Scoring</td>
                                    <td>Strategic Advantage</td>
                                    <td className='text-right'>{data?.total_sa}</td>
                                </tr>
                                <tr>
                                    <td>Company Size</td>
                                    <td className='text-right'>{data?.total_cs}</td>
                                </tr>
                                <tr>
                                    <td className='bg-light-metal'>Total Partner Scoring</td>
                                    <td className='bg-light-metal text-right'>{data?.total_sa_cs}</td>
                                </tr>
                                <tr>
                                    <td className='align-middle text-center font-weight-bold' rowSpan={3}>Project Scoring</td>
                                    <td>Project Cluster</td>
                                    <td className='text-right'>{data?.total_pc}</td>
                                </tr>
                                <tr>
                                    <td>Total Partnership in the Topic Area</td>
                                    <td className='text-right'>{data?.total_tpta}</td>
                                </tr>
                                <tr>
                                    <td className='bg-light-metal'>Total Project Scoring</td>
                                    <td className='bg-light-metal text-right'>{data?.total_pc_tpta}</td>
                                </tr>
                                <tr className='bg-light-blue'>
                                    <td colSpan={2} className='text-center'>Partnership Score</td>
                                    <td className='text-right'>{data?.total_sa_cs_pc_tpta}</td>
                                </tr>
                                <tr className='bg-light-blue'>
                                    <td colSpan={3} className='text-center'>{`Recommendation : "${data?.translation_scoring}"`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='text-right'>
                    <button className='btn btn-sm btn-secondary mr-1' onClick={handleCloseModalWaiting}>Batal</button>
                    <button className='btn btn-sm btn-primary mr-1' onClick={() => confirmWaiting(data?.id)}>Waiting Approval</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalRejected;