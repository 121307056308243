import { Autocomplete, FileUploader } from 'devextreme-react';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FileUploadDx, FormResearch, HeaderList } from '../../components';
import useEditingSwakelola, { EdtSwaPrvider } from './useEditingSwakelola';
import moment from 'moment';

function AddSwakelola(props) {
    const { edtSwaState, edtSwaAction } = useEditingSwakelola();
    useEffect(() => {
        edtSwaAction.getDataEdit('0');
    }, [])
    return (
        <div>
            <HeaderList
                title="Swakelola"
                someText={"Swakelola Add"}
            />
            <Card
                style={{ marginBottom: '50px' }}
                className="customShadow"
            >
                <Card.Body >

                    <FormResearch
                        formLists={[
                            {
                                rows: 1,
                                header: true,
                                name: "Detail",
                                childs: [
                                    {
                                        type: "text",
                                        label: "Judul Swakelola",
                                        value: edtSwaState.judul,
                                        onChange: (val) => edtSwaAction.setJudul(val),
                                        placeholder: "Judul Swakelola",
                                    },
                                    {
                                        type: "custom",
                                        label: "Pihak ke III",
                                        component: (
                                            <>
                                                <div className="input-group mb-3" >
                                                    <input className="form-control form-control-sm" placeholder="Ketik nama pihak ke III dan tekan tombol '+ Add' " value={edtSwaState.nama_pihak3_temp} onChange={(e) => edtSwaAction.setNama_pihak3_temp(e.target.value)} />
                                                    <div className="text-right" style={{ width: '65px' }}>
                                                        <button type="submit" className="btn btn-sm btn-outline-primary" onClick={() => edtSwaAction.addPihak3({
                                                            "id": '',
                                                            "pengajuan_id": edtSwaState.dataEdit?.data_pengajuan?.id,
                                                            "nama": edtSwaState.nama_pihak3_temp
                                                        })}>+ Add</button>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        [...(edtSwaState.nama_pihak3 || [])].map((d, i) => (
                                                            <div className="row mb-2" key={i}>
                                                                <div className="col-md-10">
                                                                    <span className="d-block text-left mb-3">
                                                                        <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                                                        {d.nama}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <button className="btn btn-sm btn-outline-danger" onClick={() => edtSwaAction.deletePihak3(d)}>
                                                                        <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        )
                                    },
                                    {
                                        label: "PIC",
                                        type: "custom",
                                        component: (
                                            <select className="form-control form-control-sm" value={edtSwaState.pic} onChange={(e) => edtSwaAction.setPic(e.target.value)}>
                                                <option value={''}>- Pilih -</option>
                                                {
                                                    [...(edtSwaState.dataEdit?.data_users || [])].map((d, i) => (
                                                        <option value={d?.id}>{d?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        )
                                    }
                                ]
                            },
                            {
                                rows: 1,
                                header: true,
                                name: "Fungsi",
                                childs: [
                                    {
                                        label: "Fungsi",
                                        type: "custom",
                                        component: (
                                            <select className="form-control form-control-sm mb-3" value={edtSwaState.fungsi} onChange={(e) => edtSwaAction.fChange(e.target.value)}>
                                                <option value={''}>- Pilih Fungsi -</option>
                                                {
                                                    [...(edtSwaState.dataEdit?.data_function || [])].map((d, i) => (
                                                        <option value={d?.id}>{d?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        )
                                    },
                                    {
                                        label: "Manager",
                                        type: "custom",
                                        component: (
                                            <select className="form-control form-control-sm mb-3"
                                                value={edtSwaState.section_manager}
                                                onChange={(e) => edtSwaAction.setSection_manager(e.target.value)}
                                            >
                                                <option value={''}>- Pilih Manager -</option>
                                                {
                                                    [...(edtSwaState.dataSection || [])].map((d, i) => (
                                                        <option value={d?.section_manager_id}>{d?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        )
                                    },
                                    {
                                        label: "Persetujuan Oleh",
                                        type: "custom",
                                        component: (
                                            <select className="form-control form-control-sm mb-3"
                                                value={edtSwaState.persetujuan_oleh}
                                                onChange={(e) => edtSwaAction.setPersetujuan_oleh(e.target.value)}
                                            >
                                                <option value={''}>- Pilih User -</option>
                                                {
                                                    [...(edtSwaState.dataEdit?.data_persetujuan || [])].map((d, i) => (
                                                        <option value={d?.id}>{d?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        )
                                    }
                                ]
                            },
                            {
                                rows: 1,
                                header: true,
                                name: "Proyek Berkaitan",
                                childs: [
                                    {
                                        type: "custom",
                                        withLabel: false,
                                        component: (
                                            <>
                                                <div className="input-group mb-2" >
                                                    <Autocomplete
                                                        dataSource={edtSwaState.dataEdit?.data_research}
                                                        value={edtSwaState.related_list_name}
                                                        placeholder="Pilih dan tekan tombol '+ Add'"
                                                        valueExpr="name"
                                                        displayExpr="name"
                                                        className="form-control form-control-sm p-0 mr-1 mb-5"
                                                        onValueChanged={(e) => edtSwaAction.setRelated_list_name(e.value)}
                                                        onSelectionChanged={(e) => { edtSwaAction.setRelated_list_sel(e.selectedItem) }}
                                                    >
                                                    </Autocomplete>
                                                    <div className="text-right" style={{ width: '65px' }}>
                                                        <button type="submit" className="btn btn-sm btn-primary"
                                                            onClick={() => edtSwaAction.addRelatedList(edtSwaState.related_list_sel)}
                                                        >+ Add</button>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        [...(edtSwaState.related_list || [])].map((d, i) => (
                                                            <div className="row mb-2" key={i}>
                                                                <div className="col-md-10">
                                                                    <span className="d-block text-left mb-3">
                                                                        <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                                                        {d.title}
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                        onClick={() => edtSwaAction.deleteRelatedList(d)}>
                                                                        <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        )
                                    },
                                    {
                                        type: "custom",
                                        withLabel: false,
                                        component: (
                                            <>
                                                <div className="p-5 bg-light-primary rounded">
                                                    <span style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nilai Kontrak dan Jangka Waktu</span>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-12 text-md-right">
                                                            <div className="input-group input-group-sm mb-3">
                                                                <div className="input-group-prepend">
                                                                    <span style={{ width: '100px' }} className="input-group-text bg-transparent border-0 text-wrap text-left" id="basic-addon2">Owner Estimate</span>
                                                                </div>
                                                                <input type="number" className="form-control" placeholder="IDR Juta" aria-label="IDR Juta" aria-describedby="basic-addon2" value={edtSwaState.owner_est} onChange={(e) => edtSwaAction.setOwner_est(e.target.value)} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" style={{ height: 'fit-content' }}>IDR (Juta)</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-prepend">
                                                                    <span style={{ width: '100px' }} className="input-group-text bg-transparent border-0  text-wrap text-left">Periode Proyek</span>
                                                                </div>
                                                                <input type="date" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                                    value={edtSwaState.start_date} onChange={(e) => edtSwaAction.setStart_date(moment(e.target.value).format("YYYY-MM-DD"))}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-prepend">
                                                                    <span style={{ width: '100px' }} className="input-group-text bg-transparent border-0  text-wrap text-left">Sampai</span>
                                                                </div>
                                                                <input type="date" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                                    value={edtSwaState.end_date} onChange={(e) => edtSwaAction.setEnd_date(moment(e.target.value).format("YYYY-MM-DD"))}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                ]
                            },
                            {
                                rows: 2,
                                header: true,
                                name: "File Pendukung",
                                childs: [
                                    {
                                        label: "Kerangka Acuan Kerja",
                                        type: "custom",
                                        component: (
                                            <>
                                                {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                                    onValueChanged={(e) => edtSwaAction.uploadFile(e, 'dok_1')}
                                                    showFileList={false} multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                                </FileUploader> */}
                                                <FileUploadDx
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb"
                                                    accept="*"
                                                    onUploaded={(e) => edtSwaAction.onFileUploaded(e, 'dok_1')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    edtSwaState.dok1_kak && (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span style={{ fontSize: '8pt' }}>{edtSwaState.dok1_kak}</span>
                                                            <div>
                                                                <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => edtSwaAction.setDok1_kak('')}></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    },
                                    {
                                        label: "Owner Estimate",
                                        type: "custom",
                                        component: (
                                            <>
                                                {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                                    onValueChanged={(e) => edtSwaAction.uploadFile(e, 'dok_2')}
                                                    showFileList={false} multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                                </FileUploader> */}
                                                <FileUploadDx
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb"
                                                    accept="*"
                                                    onUploaded={(e) => edtSwaAction.onFileUploaded(e, 'dok_2')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    edtSwaState.dok2_owner_est && (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span style={{ fontSize: '8pt' }}>{edtSwaState.dok2_owner_est}</span>
                                                            <div>
                                                                <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => edtSwaAction.setDok2_owner_est('')}></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    },
                                    {
                                        label: "Purchase Requisition",
                                        type: "custom",
                                        component: (
                                            <>
                                                {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                                    onValueChanged={(e) => edtSwaAction.uploadFile(e, 'dok_3')}
                                                    showFileList={false} multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                                </FileUploader> */}
                                                <FileUploadDx
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb"
                                                    accept="*"
                                                    onUploaded={(e) => edtSwaAction.onFileUploaded(e, 'dok_3')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    edtSwaState.dok3_purchase_req && (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span style={{ fontSize: '8pt' }}>{edtSwaState.dok3_purchase_req}</span>
                                                            <div>
                                                                <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => edtSwaAction.setDok3_purchase_req('')}></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    },
                                    {
                                        label: "Pakta Integritas",
                                        type: "custom",
                                        component: (
                                            <>
                                                {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                                    onValueChanged={(e) => edtSwaAction.uploadFile(e, 'dok_4')}
                                                    showFileList={false} multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                                </FileUploader> */}
                                                <FileUploadDx
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb"
                                                    accept="*"
                                                    onUploaded={(e) => edtSwaAction.onFileUploaded(e, 'dok_4')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    edtSwaState.dok4_pakta_int && (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span style={{ fontSize: '8pt' }}>{edtSwaState.dok4_pakta_int}</span>
                                                            <div>
                                                                <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => edtSwaAction.setDok4_pakta_int('')}></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    },
                                    {
                                        label: "Justifikasi",
                                        type: "custom",
                                        component: (
                                            <>
                                                {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                                    onValueChanged={(e) => edtSwaAction.uploadFile(e, 'dok_5')}
                                                    showFileList={false} multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                                </FileUploader> */}
                                                <FileUploadDx
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb"
                                                    accept="*"
                                                    onUploaded={(e) => edtSwaAction.onFileUploaded(e, 'dok_5')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    edtSwaState.dok5_justifikasi && (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span style={{ fontSize: '8pt' }}>{edtSwaState.dok5_justifikasi}</span>
                                                            <div>
                                                                <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => edtSwaAction.setDok5_justifikasi('')}></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    },
                                    {
                                        label: "Dokumen lainnya (Optional)",
                                        type: "custom",
                                        component: (
                                            <>
                                                {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                                    onValueChanged={(e) => edtSwaAction.uploadFile(e, 'dok_6')}
                                                    showFileList={false} multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                                </FileUploader> */}
                                                <FileUploadDx
                                                    className="custom-dx-fileuploader-file-name"
                                                    showFileList={true}
                                                    multiple={false}
                                                    selectButtonText="Select File"
                                                    readyToUploadMessage=" "
                                                    labelText="or Drop file hire | Max size 10 Mb"
                                                    accept="*"
                                                    onUploaded={(e) => edtSwaAction.onFileUploaded(e, 'dok_6')}
                                                    maxFileSize={10000000}
                                                    uploadMode="instantly"
                                                />
                                                {
                                                    edtSwaState.dok6_lainnya && (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span style={{ fontSize: '8pt' }}>{edtSwaState.dok6_lainnya}</span>
                                                            <div>
                                                                <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => edtSwaAction.setDok6_lainnya('')}></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                ]
                            }
                        ]}
                    />
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-sm btn-secondary" onClick={edtSwaAction.btnBackClick}>Kembali</button>
                    </div>
                    <div className="col-md-6 text-right">
                        <button className="btn btn-sm btn-success"
                            onClick={() => edtSwaAction.addSwakelola()}
                        ><i class="fa fa-save icon-nm"></i> Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default () => <EdtSwaPrvider><AddSwakelola /></EdtSwaPrvider>;