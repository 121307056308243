import React, { useRef } from 'react';
import { GridDx, PaginationCs } from '../../../../components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useLab from '../useLaboratory';
import { formatter } from '../../../../components/utility/utility';
import moment from 'moment';
import { Card } from 'react-bootstrap';

const column = [
    { fieldName: "lab_master_como_nama", caption: "Jenis Komoditi", groupIndex: 0 },
    { fieldName: "parameter_analisis", caption: "Parameter Analisis", allowSorting: false, allowFiltering: false, allowGrouping: false },
    { fieldName: "metode", caption: "Metode Pengujian", allowSorting: false, allowFiltering: false, allowGrouping: false },
    { fieldName: "harga", caption: "Harga", allowFiltering: false, allowGrouping: false },
    {
        fieldName: "active", caption: "Active", allowFiltering: true, allowGrouping: false, headerFilterSource: [
            { text: 'Aktif', value: true },
            { text: 'Tidak Aktif', value: false },
        ]
    },
    { fieldName: "note", caption: "Keterangan", allowFiltering: false, allowGrouping: false },
];
const data = {
    "id": 7,
    "name": "Laboratory",
    "short_name": null,
    "image_link": "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_118.jpg",
    "keterangan": "Laboratory RTI merupakan laboratorium yang melayani pengujian untuk mendukung kegiatan riset RTI, pengembangan laboratorium serta layanan teknis di PErtamina Group. Laboratory RTI telah bersertifikasi ISO 17025:2017 untuk 24 komoditi dengan 191 parameter uji. Laboratory RTI menerima jasa pengujian untuk Pertamina Group(Sub Holding dan Anak Perusahaan), sesuai dengan Surat Keputusan Direktur SPPU No. Kpts - 01 / G00000 / 2023 - S0 tentang Tarif Jasa Laboratorium RTI dan TKO No. B6 - 007 / G40010 / 2023 - S9 tentang Pembebanan atau Penagihan Biaya Jasa Analisis Laboratorium."
}
// const data_product = [
//     {
//         "id": 78,
//         "name": "CHEMICAL PHYSICAL ANALYSIS (CPA) LABORATORY",
//         "description": "Mencakup pengujian komoditas crude oil, gas, dan petroleum product",
//         "photo_location": "07_LAB_Product_120.jpg",
//         "photo_location_name": "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_120.jpg",
//         "photo_location_1": null,
//         "photo_location_2": null,
//         "photo_location_3": null,
//         "photo_location_1_name": "",
//         "photo_location_2_name": "",
//         "photo_location_3_name": "",
//         "file_location": null,
//         "file_location_name": "",
//         "file_size": null,
//         "list_ipr": null,
//         "related_ipr": null,
//         "list_contribution": null,
//         "list_field_test": null,
//         "list_commercial": null,
//         "release_year": "2020",
//         "creator": null,
//         "creator_name": null,
//         "product_status_id": 1,
//         "created_at": "2021-01-20T07:24:46.763",
//         "updated_at": "2021-01-20T08:00:31.017",
//         "created_by": null,
//         "status_approval": 2,
//         "status_approval_name": null,
//         "product_status_name": "Commercial",
//         "product_types_name": null,
//         "date_created": null,
//         "date_created_sort": null,
//         "product_type_id": 7,
//         "background": "-",
//         "contact_person": 90,
//         "ipr": null,
//         "price": 0,
//         "field_test_total": null,
//         "field_test_year_select": null,
//         "commercial_total": null,
//         "commercial_year_select": null,
//         "contribution_year_select": null,
//         "inventor_name": null,
//         "inventor_arrray_id": null,
//         "kontak_name": null,
//         "kontak_id": null,
//         "year_select": 0,
//         "list_pic": null,
//         "list_pic_delete": null,
//         "nama_PIC_array_id": null,
//         "nama_PIC_array_name": null,
//         "nama_PIC_str": null,
//         "is_pic_contributor": false,
//         "contrib_type": null,
//         "is_displayed": false
//     },
//     {
//         "id": 80,
//         "name": "ENHANCED OIL RECOVERY (EOR) LABORATORY",
//         "description": "Mencakup pekerjaan surface sampling & bottom hole sampling, reservoir fluid (water, oil, gas) analysis, core analysis, PVT, chemical EOR, CO2 EOR, & thermal EOR",
//         "photo_location": "07_LAB_Product_123.jpg",
//         "photo_location_name": "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_123.jpg",
//         "photo_location_1": null,
//         "photo_location_2": null,
//         "photo_location_3": null,
//         "photo_location_1_name": "",
//         "photo_location_2_name": "",
//         "photo_location_3_name": "",
//         "file_location": null,
//         "file_location_name": "",
//         "file_size": null,
//         "list_ipr": null,
//         "related_ipr": null,
//         "list_contribution": null,
//         "list_field_test": null,
//         "list_commercial": null,
//         "release_year": "2020",
//         "creator": null,
//         "creator_name": null,
//         "product_status_id": 1,
//         "created_at": "2021-01-20T07:41:01.13",
//         "updated_at": "2021-01-20T07:41:01.13",
//         "created_by": null,
//         "status_approval": 2,
//         "status_approval_name": null,
//         "product_status_name": "Commercial",
//         "product_types_name": null,
//         "date_created": null,
//         "date_created_sort": null,
//         "product_type_id": 7,
//         "background": "-",
//         "contact_person": 82,
//         "ipr": null,
//         "price": 0,
//         "field_test_total": null,
//         "field_test_year_select": null,
//         "commercial_total": null,
//         "commercial_year_select": null,
//         "contribution_year_select": null,
//         "inventor_name": null,
//         "inventor_arrray_id": null,
//         "kontak_name": null,
//         "kontak_id": null,
//         "year_select": 0,
//         "list_pic": null,
//         "list_pic_delete": null,
//         "nama_PIC_array_id": null,
//         "nama_PIC_array_name": null,
//         "nama_PIC_str": null,
//         "is_pic_contributor": false,
//         "contrib_type": null,
//         "is_displayed": false
//     },
//     {
//         "id": 79,
//         "name": "GEOSCIENCE & DRILLING (GD) LABORATORY",
//         "description": "Mencakup pengujian komoditas drilling fluid dan drilling cementing",
//         "photo_location": "07_LAB_Product_124.jpg",
//         "photo_location_name": "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_124.jpg",
//         "photo_location_1": null,
//         "photo_location_2": null,
//         "photo_location_3": null,
//         "photo_location_1_name": "",
//         "photo_location_2_name": "",
//         "photo_location_3_name": "",
//         "file_location": null,
//         "file_location_name": "",
//         "file_size": null,
//         "list_ipr": null,
//         "related_ipr": null,
//         "list_contribution": null,
//         "list_field_test": null,
//         "list_commercial": null,
//         "release_year": "2020",
//         "creator": null,
//         "creator_name": null,
//         "product_status_id": 1,
//         "created_at": "2021-01-20T07:36:21.75",
//         "updated_at": "2021-01-20T08:00:59.15",
//         "created_by": null,
//         "status_approval": 2,
//         "status_approval_name": null,
//         "product_status_name": "Commercial",
//         "product_types_name": null,
//         "date_created": null,
//         "date_created_sort": null,
//         "product_type_id": 7,
//         "background": "-",
//         "contact_person": 87,
//         "ipr": null,
//         "price": 0,
//         "field_test_total": null,
//         "field_test_year_select": null,
//         "commercial_total": null,
//         "commercial_year_select": null,
//         "contribution_year_select": null,
//         "inventor_name": null,
//         "inventor_arrray_id": null,
//         "kontak_name": null,
//         "kontak_id": null,
//         "year_select": 0,
//         "list_pic": null,
//         "list_pic_delete": null,
//         "nama_PIC_array_id": null,
//         "nama_PIC_array_name": null,
//         "nama_PIC_str": null,
//         "is_pic_contributor": false,
//         "contrib_type": null,
//         "is_displayed": false
//     },
//     {
//         "id": 81,
//         "name": "INSTRUMENT ANALYSIS (IA) LABORATORY",
//         "description": "Mencakup pengujian komoditas produk petroleum non-fuel, produk petrokimia, dan air limbah",
//         "photo_location": "07_LAB_Product_122.jpg",
//         "photo_location_name": "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_122.jpg",
//         "photo_location_1": null,
//         "photo_location_2": null,
//         "photo_location_3": null,
//         "photo_location_1_name": "",
//         "photo_location_2_name": "",
//         "photo_location_3_name": "",
//         "file_location": null,
//         "file_location_name": "",
//         "file_size": null,
//         "list_ipr": null,
//         "related_ipr": null,
//         "list_contribution": null,
//         "list_field_test": null,
//         "list_commercial": null,
//         "release_year": "2020",
//         "creator": null,
//         "creator_name": null,
//         "product_status_id": 1,
//         "created_at": "2021-01-20T07:54:29.203",
//         "updated_at": "2021-01-20T07:54:29.203",
//         "created_by": null,
//         "status_approval": 2,
//         "status_approval_name": null,
//         "product_status_name": "Commercial",
//         "product_types_name": null,
//         "date_created": null,
//         "date_created_sort": null,
//         "product_type_id": 7,
//         "background": "-",
//         "contact_person": 81,
//         "ipr": null,
//         "price": 0,
//         "field_test_total": null,
//         "field_test_year_select": null,
//         "commercial_total": null,
//         "commercial_year_select": null,
//         "contribution_year_select": null,
//         "inventor_name": null,
//         "inventor_arrray_id": null,
//         "kontak_name": null,
//         "kontak_id": null,
//         "year_select": 0,
//         "list_pic": null,
//         "list_pic_delete": null,
//         "nama_PIC_array_id": null,
//         "nama_PIC_array_name": null,
//         "nama_PIC_str": null,
//         "is_pic_contributor": false,
//         "contrib_type": null,
//         "is_displayed": false
//     },
//     {
//         "id": 2153,
//         "name": "LABORATORY SUPPORT",
//         "description": null,
//         "photo_location": "07_LAB_Product_125.jpg",
//         "photo_location_name": "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_125.jpg",
//         "photo_location_1": null,
//         "photo_location_2": null,
//         "photo_location_3": null,
//         "photo_location_1_name": "",
//         "photo_location_2_name": "",
//         "photo_location_3_name": "",
//         "file_location": null,
//         "file_location_name": "",
//         "file_size": null,
//         "list_ipr": null,
//         "related_ipr": null,
//         "list_contribution": null,
//         "list_field_test": null,
//         "list_commercial": null,
//         "release_year": null,
//         "creator": null,
//         "creator_name": null,
//         "product_status_id": 1,
//         "created_at": null,
//         "updated_at": null,
//         "created_by": null,
//         "status_approval": 2,
//         "status_approval_name": null,
//         "product_status_name": "Commercial",
//         "product_types_name": null,
//         "date_created": null,
//         "date_created_sort": null,
//         "product_type_id": 7,
//         "background": null,
//         "contact_person": null,
//         "ipr": null,
//         "price": null,
//         "field_test_total": null,
//         "field_test_year_select": null,
//         "commercial_total": null,
//         "commercial_year_select": null,
//         "contribution_year_select": null,
//         "inventor_name": null,
//         "inventor_arrray_id": null,
//         "kontak_name": null,
//         "kontak_id": null,
//         "year_select": 0,
//         "list_pic": null,
//         "list_pic_delete": null,
//         "nama_PIC_array_id": null,
//         "nama_PIC_array_name": null,
//         "nama_PIC_str": null,
//         "is_pic_contributor": false,
//         "contrib_type": null,
//         "is_displayed": false
//     },
//     {
//         "id": 82,
//         "name": "PILOT PLANT (PP) LABORATORY",
//         "description": "Mencakup pilot testing, catalyst acceptance, dan pilotproduction",
//         "photo_location": "07_LAB_Product_121.jpg",
//         "photo_location_name": "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_121.jpg",
//         "photo_location_1": null,
//         "photo_location_2": null,
//         "photo_location_3": null,
//         "photo_location_1_name": "",
//         "photo_location_2_name": "",
//         "photo_location_3_name": "",
//         "file_location": null,
//         "file_location_name": "",
//         "file_size": null,
//         "list_ipr": null,
//         "related_ipr": null,
//         "list_contribution": null,
//         "list_field_test": null,
//         "list_commercial": null,
//         "release_year": "2020",
//         "creator": null,
//         "creator_name": null,
//         "product_status_id": 1,
//         "created_at": "2021-01-20T07:58:04.76",
//         "updated_at": "2021-01-20T08:00:18.55",
//         "created_by": null,
//         "status_approval": 2,
//         "status_approval_name": null,
//         "product_status_name": "Commercial",
//         "product_types_name": null,
//         "date_created": null,
//         "date_created_sort": null,
//         "product_type_id": 7,
//         "background": "-",
//         "contact_person": 88,
//         "ipr": null,
//         "price": 0,
//         "field_test_total": null,
//         "field_test_year_select": null,
//         "commercial_total": null,
//         "commercial_year_select": null,
//         "contribution_year_select": null,
//         "inventor_name": null,
//         "inventor_arrray_id": null,
//         "kontak_name": null,
//         "kontak_id": null,
//         "year_select": 0,
//         "list_pic": null,
//         "list_pic_delete": null,
//         "nama_PIC_array_id": null,
//         "nama_PIC_array_name": null,
//         "nama_PIC_str": null,
//         "is_pic_contributor": false,
//         "contrib_type": null,
//         "is_displayed": false
//     }
// ]
function ProductLab(props) {
    const tableRef = useRef();
    const { labState, labAction } = useLab();
    function onSearch(val) {
        if (tableRef.current) {
            tableRef.current.instance.searchByText(val)
        }
        labAction.setSearchParam(val)
    }

    const customCellRender = (rowInfo) => {
        if (rowInfo.column.name === 'harga') {
            return (<span className='d-block text-wrap text-right'>Rp. {formatter.format(rowInfo.value)}</span>)
        } else if (rowInfo.column.name === 'active') {
            return (<span className={`badge ${rowInfo.value ? 'badge-success' : 'badge-danger'}`}>{rowInfo.value ? "Aktif" : "Tidak Aktif"}</span>)
        } else {
            return (<span className='d-block text-wrap text-left'>{rowInfo.value}</span>)
        }
    }
    function expanCollapse() {
        if (labState.expand) {
            if (tableRef.current) {
                tableRef.current.instance.collapseAll();
            }
        } else {
            if (tableRef.current) {
                tableRef.current.instance.expandAll();
            }
        }
        labAction.setExpand(!labState.expand);
    }
    return (
        <div>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-5">
                    <div className="row">
                        <div className="col-md-12 ">
                            <img src={data.image_link}
                                style={{ maxHeight: '500px', width: '100%' }}
                            ></img>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-5">
                    <div className="row">
                        <div className="col-md-12 ">
                            <h5 className='my-5'><b>Daftar Produk Layanan Laboratory Testi RTI-Pertamina</b></h5>
                            <div>
                                <div className='d-sm-block d-md-flex justify-content-between mb-2'>
                                    <div className='d-sm-block d-md-inline-flex mb-1'>
                                        <button className='mr-2 btn btn-sm btn-warning mb-1' onClick={expanCollapse}>{labState.expand ? 'Collapse' : 'Expand'} All</button>
                                        <div className="formSearch" style={{ width: 300 }}>
                                            <input value={labState.searchParam} onChange={val => onSearch(val.target.value)} className="form-control form-control-sm" placeholder="Cari" />
                                            <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                                        </div>
                                    </div>
                                </div>
                                {React.useMemo(() => (
                                    <GridDx
                                        className='custom-style-master'
                                        ref={tableRef}
                                        selectionMode="multiple"
                                        exportMode="selection"
                                        exportPdf={true}                                        
                                        settingColumn={true}
                                        filenameExport={`Tabel Produk Uji Laboratorium RTI - PT Pertamina-${moment().format('DDMMyyyy')}`}
                                        data={labState?.productLab || []}
                                        columns={column}
                                        cellRender={customCellRender}
                                        pagination={true}
                                        orderingDrag={false}
                                        withAction={false}
                                        searchForm={false}
                                        currentPage
                                        show
                                        dataGroup={true}
                                    ></GridDx>
                                ), [labState?.productLab])}
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            {/* <PaginationCs
                data={data_product}
                dataPerPage={8}
                RenderContainer={({ children }) => (
                    <div className='row mb-5'>
                        {children}
                    </div>
                )}
                renderComponent={(d, i) => (
                    <div className='col-lg-3 col-md-3 mb-3' key={i}>
                        <div className='card p-3 h-100 rounded-cs card-land-produk'

                        >
                            <LazyLoadImage
                                key={i}
                                src={d?.photo_location_name}
                                width={'100%'}
                                height={'180px'}
                                style={{ objectFit: 'contain', backgroundColor: 'whitesmoke' }}
                                onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                effect='blur'
                            />
                            <span className='text-wrap font-weight-boldest m-3'>{d?.name}</span>
                            <div className='footer-card-product'>
                                <div>
                                    <span className='bg-light-primary text-primary px-2 rounded'>{d?.product_status_name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            /> */}
        </div>
    );
}

export default ProductLab;