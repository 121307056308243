import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../../../modules/useFetch";
import Swal from "sweetalert2";
import ModalDetailCustomer from "./modalDetailCustomer";

const contexts = createContext({
    ccState: {},
    ccAction: {}
});

export function CrudCustomerProvider({ children }) {
    const history = useHistory();
    const { doGet, doPost } = useFetch();
    const [tabActive, setTabActive] = useState(history.location.state?.tabActive || "cl");
    const [modalCu, setModalCu] = useState({ show: false, data: {} });
    //Hanlde Tab
    const tabChangeHandle = (name) => {
        setTabActive(name);
    }
    const [dataCL, setDataCL] = useState([]);
    const [dataCR, setDataCR] = useState([]);
    async function getDataCL() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-customer-list',
            });
            if (resp?.code === '00') {
                setDataCL(resp.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getDataCR() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-customer-approval-list',
            });
            if (resp?.code === '00') {
                console.log(resp.data);
                setDataCR(resp.data || []);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    async function approve(id = "") {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-lab/admin-add-customer-approval-approve`,
                queryParam: {
                    "id_customer": id,
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalCu({show:false, data:{}});
                    getDataCR();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function reject(id = "") {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-lab/admin-add-customer-approval-reject`,
                queryParam: {
                    "id_customer": id,
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalCu({show:false, data:{}});
                    getDataCR();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }


    useEffect(() => {
        if (tabActive === "cl") {
            getDataCL();
        }
        if (tabActive === "cr") {
            getDataCR();
        }
    }, [tabActive]);
    useEffect(() => {
        setTabActive(history.location.state?.tabActive);
    }, [history.location.state?.tabActive]);

    return <contexts.Provider
        value={{
            ccState: {
                tabActive,
                dataCL,
                dataCR,
                modalCu
            },
            ccAction: {
                tabChangeHandle,
                approve,
                reject,
                setModalCu
            }
        }}
    >
        <ModalDetailCustomer/>
        {children}
        </contexts.Provider>
}

export default function useCrudCustomer() {
    return useContext(contexts)
}