import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";

const apPreIdContext = createContext({
    apPreIdState: {},
    apPreIdAction: {}
});

export function ApPReIdProvider({ children }) {
    const iniStateColumn = [
        { fieldName: "created_at", caption: "Date Created", width: 100 },
        { fieldName: "title", caption: "Title", width: 500, link: false, url: "#" },
        { fieldName: "description", caption: "Description", width: 300 },
        { fieldName: "purpose", caption: "Purpose", width: 300 },
        { fieldName: "from", caption: "Lembaga/Univ", width: 100 },
        { fieldName: "name_institution", caption: "Nama Lembaga", width: 200 },
        // { fieldName: "cat_name", caption: "Category", width: 200 },
        { fieldName: "sector", caption: "Sector", width: 100 },
        { fieldName: "area", caption: "Area Riset", width: 100 },
        { fieldName: "category", caption: "Kategori Riset", width: 100 },
        { fieldName: "stage", caption: "TRL", width: 100, onExport: (e) => convertStage(e?.stage) },
        { fieldName: "durasi", caption: "Durasi", width: 200 },
        { fieldName: "budget", caption: "Initiator", width: 200 },

    ]
    const [data_page, setData_page] = useState({
        page_title: '',
        page_breadcrumbs: ''
    });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { doGet } = useFetch();
    const [column, setColumn] = useState(iniStateColumn);
    const refTable = useRef();

    async function getData(tahun = '') {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/project-preideation/table-reporting',
                param: {
                    tahun
                },
                token: true
            });
            if (resp?.code === '00') {
                setData({ ...resp?.data });
                setData_page({ ...resp?.data_page });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }
    function cellRender(e) {
        if (e.column.name === "title") {
            return (
                <>
                    <Link
                        target={'_blank'}
                        to={`/admin/preideasi/view/${e.data?.id}`}
                    >
                        <span className="text-wrap">{e.value}</span>
                    </Link>
                </>
            )
        } else if (e.column.name === "durasi") {
            return (
                <>
                    <span className="mr-1">
                        {
                            e?.value?.split(',')[0]
                        }
                        Tahun
                    </span>
                    <span>
                        {
                            e?.value?.split(',')[1]
                        }
                        Bulan
                    </span>
                </>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function convertStage(val) {
        return [
            { label: 'I', value: '1', disabled: false },
            { label: 'II', value: '2', disabled: false },
            { label: 'III', value: '3', disabled: false },
            { label: 'IV', value: '4', disabled: false },
            { label: 'V', value: '5', disabled: false },
            { label: 'VI', value: '6', disabled: false },
            { label: 'VII', value: '7', disabled: false },
            { label: 'VIII', value: '8', disabled: false },
            { label: 'IX', value: '9', disabled: false }
        ].find(d => d.value === val).label
    }

    useEffect(() => {
        getData();
    }, [])
    return <apPreIdContext.Provider
        value={{
            apPreIdState: {
                data_page,
                data,
                loading,
                column,
                refTable
            },
            apPreIdAction: {
                getData,
                cellRender
            }
        }}
    >{children}</apPreIdContext.Provider>
}

export default function useApprovalPreIdeasi() {
    return useContext(apPreIdContext);
}