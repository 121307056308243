import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { HeaderList } from '../../components';
import useKnowledgeMng, { KnoProvider } from './useKnowledgeMng';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { isValidHttpUrl } from '../../components/utility/utility';

function LibraryDetail(props) {
    const { id } = useParams();
    const { knoAction, knoState } = useKnowledgeMng();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            knoAction.getLibraryDetailAdmin(id);
        }
    }, [])

    return (
        <>
            <HeaderList
                title={knoState.data_pagel?.page_title}
                someText={knoState.data_pagel?.page_breadcrumbs}
            />
            <Card
                style={{ marginBottom: '40px' }}
                className="p-0customShadow"
            >
                <Card.Body className="p-4">
                    <div className="row">
                        <div className="col-md-4 mb-5">
                            <div className="pl-5 pr-5">
                                <img style={{ width: '100%', minHeight: 200, maxHeight: 300, objectFit: 'cover', borderRadius: '3%' }} className="bg-secondary border"
                                    src={knoState.dataLibraryD?.detail_book?.cover_name}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                            </div>
                        </div>
                        <div className="col-md-7 mb-5">
                            <h5 className='font-bold'>{knoState.dataLibraryD?.detail_book?.title}</h5>
                            <br />
                            <p className="text-dark">Deskripsi</p>
                            <p>{knoState.dataLibraryD?.detail_book?.description}</p>
                            <hr />
                            <div className="row mb-5">
                                <div className="col-md-4">
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Author</span>
                                        <span className='font-bold'>{knoState.dataLibraryD?.detail_book?.author}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Uploaded</span>
                                        <span className='font-bold'>{knoState.dataLibraryD?.detail_book?.creator_name}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Date uploaded</span>
                                        <span className='font-bold'>{moment(knoState.dataLibraryD?.detail_book?.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Kategori</span>
                                        <span className='font-bold'>{knoState.dataLibraryD?.detail_book?.book_category_name}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Area</span>
                                        <span className='font-bold'>{knoState.dataLibraryD?.detail_book?.area_name}</span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-2">Site Link</span>
                                        {
                                            isValidHttpUrl(knoState.dataLibraryD?.detail_book?.sitelink) ? (
                                                <a href={knoState.dataLibraryD?.detail_book?.sitelink} target={'_blank'}>
                                                    <span className='font-bold '>Link <i className='fas fa-external-link-alt icon-nm text-primary'></i></span>
                                                </a>
                                            ) : (
                                                <span className='text-wrap'>{knoState.dataLibraryD?.detail_book?.sitelink}</span>
                                            )
                                            // knoState.dataLibraryD?.detail_book?.sitelink && (
                                            //     <a href={knoState.dataLibraryD?.detail_book?.sitelink} target="_blank">
                                            //         <span className='font-bold '>Link <i className='fas fa-external-link-alt icon-nm text-primary'></i></span>
                                            //     </a>
                                            // )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer-nolayout" >
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        {
                            history.length > 1 && (
                                <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            )
                        }
                    </div>
                    <div className="col-md-6 text-md-right">
                        {
                            knoState.dataLibraryD?.detail_book?.file_location_name && (
                                <a target="_blank" href={knoState.dataLibraryD?.detail_book?.file_location_name} download>
                                    <button className="btn btn-sm btn-light-primary mx-1">
                                        <i className="fas fa-file-download icon-nm"></i>
                                        Download {(knoState.dataLibraryD?.detail_book?.file_size || 0) < 1 ? ((knoState.dataLibraryD?.detail_book?.file_size || 0) * 1000) + " Kb" : knoState.dataLibraryD?.detail_book?.file_size + " Mb"}
                                    </button>
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <KnoProvider><LibraryDetail /></KnoProvider>;