import React, { memo, useEffect, useState } from 'react';
import { HeaderList, Modal, Tab, TabContent } from '../../components';
import TabTable from './tabTable';
import TabDashboard from './tabDashboard';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';

export default memo(function () {
    const { pathname, param } = useLocation();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    return (
        <div>
            <HeaderList
                title={state.formData?.people_page?.page_title}
                someText={state.formData?.people_page?.page_breadcrumbs}
                optionList={[{
                    withLabel: true,
                    label: "Demografi pekerja RTI "
                }]}
            >

            </HeaderList>

            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/People/dashboard',
                                param: 0
                            })
                        },
                        title: "Dashboard"
                    },
                    {
                        id: "table",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/People/table',
                                param: 0
                            })
                        },
                        title: "Tabel Pekerja"
                    }
                ]}
                manualActive={pathname === "/People/table" ? "table" : "dashboard"}
            >
                <TabContent id="dashboard">
                    <TabDashboard>
                    </TabDashboard>

                </TabContent>
                <TabContent id="table">
                    <TabTable>

                    </TabTable>
                </TabContent>

            </Tab>

        </div>

    )
})