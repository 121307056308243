import React, { useRef } from 'react';
import { useGlobalState } from '../../../contexts/useGlobalState';
import Tags from "@yaireo/tagify/dist/react.tagify"
import { Accordion } from 'devextreme-react';
import { FormResearch } from '../../../components';

const Operational = ({ param }) => {
    const [state] = useGlobalState();
    const tagref = useRef();

    function renderTitle(data) {
        return (
            <div className="m-2" style={{ fontSize: "10px", fontFamily: 'Poppins' }}><i className="fa flaticon-user-ok p-2"></i>{data?.name}</div>
        )
    }
    function renderItem(data) {
        return (
            <div className="row h-100 p-5 border-top" style={{ fontFamily: 'Poppins' }}>
                <div className="col-md-4 mb-5">
                    <img src={data?.image || "/media/svg/icons/General/User.svg"}
                        style={{ width: '100%' }}
                    ></img>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>Nama</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>Posisi</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.position_name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>No. Pegawai</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.employee_id}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-4" style={{ fontSize: '9pt' }}>Expertise</div>
                        <div className="col-auto"></div>
                    </div>
                    {
                        data.technical_competency?.split(',').map((s, i) => (
                            <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                <div className="col-4"></div>
                                <div className="col-auto" style={{ fontSize: '8pt' }}>{i + 1}.&nbsp;{s}</div>
                            </div>
                        ))
                    }
                    <div className="d-flex flex-row-reverse" style={{ paddingBottom: 10 }}>
                    </div>
                </div>
            </div>
        )
    }
    function getFungsi() {
        let newDs = [
            ...([...(state.formData.anggota || [])].map(d => {
                return {
                    user_id: d.user_id,
                    section_name: d.section_name,
                    function_short_name: d.function_short_name
                }
            }))
        ]
        if (state.formData.leader) {
            newDs = [
                { user_id: state.formData.leader?.user_id, section_name: state.formData.leader?.section_name, function_short_name: state.formData.leader?.function_short_name },
                ...newDs
            ]
        }
        return newDs
    }
    return (
        <>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: true,
                        name: <>Project Type & Initiator <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "text",
                                label: "Project type",
                                value: state.data_view_header?.project_type_name,
                                disabled: true,
                            },
                            {
                                type: "custom",
                                label: "Nama",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_name}</div>)
                            },
                            {
                                label: "Jabatan",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_position_name}</div>)
                            },
                            {
                                label: "Fungsi",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_function_name}</div>)
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Latar Belakang <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "array",
                                label: "Judul",
                                forms: [
                                    {
                                        type: "custom",
                                        component: (<div className='form-group style-view-disable'>{state.formData.title}</div>)
                                    },
                                ]
                            },
                            {
                                label: "Pain Point",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.formData.description}</div>)
                            },
                            {
                                label: "Commercialization /Implementation- Objective",
                                component: (<div className='form-group style-view-disable'>{state.formData.purpose}</div>),
                                type: "custom",
                            },
                            {
                                label: <> Keyword <i title="Kata kunci terkait riset yang dijalankan, dipisah dengan tanda koma (,)" className="fa fa-exclamation-circle"></i></>,
                                type: "custom",
                                component: (
                                    <Tags
                                        readOnly={true}
                                        tagifyRef={tagref}
                                        value={[...(state.data_view_keywords || '')].map(d => d?.name)}
                                    />
                                )
                            },
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Susunan tim <span className="text-danger ml-2">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: 'bold' }}>Leader</span>
                                        <div className='rounded border p-5'>
                                            <div className="row">
                                                {
                                                    state.formData.leader && (
                                                        <>
                                                            <div className="col-4">
                                                                <img src={state.formData.leader?.image || "/media/svg/icons/General/User.svg"}
                                                                    style={{ width: '100%' }}
                                                                ></img>
                                                            </div>
                                                            <div className="col-8">
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Nama</div>
                                                                    <div className="col-auto">: {state.formData.leader.name}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Posisi</div>
                                                                    <div className="col-auto">: {state.formData.leader.position_name}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">No. Pegawai</div>
                                                                    <div className="col-auto">: {state.formData.leader.employee_id}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Expertise</div>
                                                                    <div className="col-auto"></div>
                                                                </div>
                                                                {
                                                                    state.formData.leader.technical_competency?.split(',').map((ex, i) => (
                                                                        <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                                                            <div className="col-3"></div>
                                                                            <div className="col-auto">{i + 1}.&nbsp;{ex}</div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: "bold" }}>Anggota</span>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    state.formData.anggota?.length > 0 ? (
                                                        <Accordion
                                                            className="accordion-cstm py-5"
                                                            multiple={true}
                                                            itemRender={renderItem}
                                                            dataSource={[...state.formData.anggota]} collapsible={true}
                                                            itemTitleRender={renderTitle}
                                                        >
                                                        </Accordion>
                                                    ) : (
                                                        <div className="mb-5"></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: "Fungsi",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="row mb-5">
                                        <div className="col-md-12">
                                            <ul>
                                                {
                                                    getFungsi()?.map((d, i) => (
                                                        <li key={i}>{d.section_name} ({d.function_short_name})</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: "Dokumen Pendukung",
                        childs: [
                            {
                                type: "custom",
                                label: "Sumber File",
                                disabled: false,
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        [...(state.formData.sumberFileTemp || [])].length < 1 && <div className='text-muted'>tidak ada data</div>
                                                    }
                                                    {
                                                        [...(state.formData.sumberFileTemp || [])]?.map((file, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                {
                                                                    file.tipe_document === "file" ? (
                                                                        <>
                                                                            <span style={{ fontSize: '8pt', width: '250px' }}>{file.file_name.split('/')?.[6] || file.file_name}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span>{file.link}</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: "Proyek berkaitan",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        [...(state.formData.realated_document || [])].length < 1 && <div className='text-muted text-center'>tidak ada data</div>
                                                    }
                                                    {
                                                        [...(state.formData.realated_document || [])]?.map((d, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                <span className="pl-2 text-wrap" style={{ fontSize: '8pt' }}>{d.title}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    }
                ]}
            />
            {/* <div className="row mt-5">
                <div className="col-6">
                    <span className="d-block text-danger">(*) Mandatori tidak boleh kosong</span>
                </div>
                <div className="col-6 text-right">

                </div>
            </div> */}
        </>
    )
}
export default Operational;