import React, { createContext, useContext, useState } from "react";
import { useGlobalState } from "../../../contexts/useGlobalState";
import Swal from 'sweetalert2';
import moment from "moment";
import { useHistory } from "react-router-dom";

const contextUsrDashb = createContext({
    usrDashAction: {},
    userDashState: {}
})

export function UsrMngDashProvider({ children }) {
    const [state, dispatch, action] = useGlobalState();
    const [data_page, setData_page] = useState({});
    const history = useHistory();

    const [loadingDash, setLoadingDash] = useState(false);
    const [data, setData] = useState({});

    const [sw1, setSw1] = useState(false);
    const [sw2, setSw2] = useState(false);

    const [downFilter, setDownFilter] = useState({
        val: '1',
        name: 'Daily'
    })

    const [ftrDaily, setFtrDaily] = useState('');
    const [ftrD, setFtrD] = useState({
        tahun: '',
        bulan: '',
        hari: ''
    })
    const [ftrMonthly, setFtrMonthly] = useState({
        tahun: '',
        bulan: ''
    })
    const [ftrYearly, setFtrYearly] = useState('');

    async function getDashboardDaily(tahun, bulan, hari) {
        try {
            setLoadingDash(true);
            const resp = await action.getData("/api/user/dashboard-daily", {
                tahun: tahun || '',
                bulan: bulan || '',
                hari: hari || ''
            }, null, null, true);
            if (resp?.code === "00") {
                setData(resp?.data);
                setData_page(resp.data_page);
                setFtrDaily(`${resp?.data?.tahun}-${resp?.data?.bulan}-${resp?.data?.hari}`);
                setFtrD({
                    tahun: resp?.data?.tahun,
                    bulan: resp?.data?.bulan,
                    hari: resp?.data?.hari
                });
                getChartPosition({ tahun: resp?.data?.tahun, bulan: resp?.data?.bulan, hari: resp?.data?.hari }, '0', 'daily');
                getChartUserAdmin({ tahun: resp?.data?.tahun, bulan: resp?.data?.bulan, hari: resp?.data?.hari }, '0', 'daily');
                getTableHits(resp?.data?.tahun);
            }
            setLoadingDash(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function getDashboardMonthly(tahun, bulan) {
        try {
            setLoadingDash(true);
            const resp = await action.getData("/api/user/dashboard-monthly", {
                tahun: tahun || '',
                bulan: bulan || ''
            }, null, null, true);
            if (resp?.code === "00") {
                setData(resp?.data);
                setData_page(resp.data_page);
                setFtrMonthly({
                    tahun: resp?.data?.tahun,
                    bulan: resp?.data?.bulan
                });
                getChartPosition({ tahun: resp?.data?.tahun, bulan: resp?.data?.bulan }, '0', 'monthly');
                getChartUserAdmin({ tahun: resp?.data?.tahun, bulan: resp?.data?.bulan }, '0', 'monthly');
                getTableHits(resp?.data?.tahun);
            }
            setLoadingDash(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function getDashboardYearly(tahun) {
        try {
            setLoadingDash(true);
            const resp = await action.getData("/api/user/dashboard-yearly", {
                tahun: tahun || ''
            }, null, null, true);
            if (resp?.code === "00") {
                setData(resp?.data);
                setData_page(resp.data_page);
                setFtrYearly(resp?.data?.tahun);

                getChartPosition({ tahun: resp?.data?.tahun }, '0', 'yearly');
                getChartUserAdmin({ tahun: resp?.data?.tahun }, '0', 'yearly');
                getTableHits(resp?.data?.tahun);
            }
            setLoadingDash(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }

    const [fc, setFc] = useState('0');
    const [data_cposition, setData_cposition] = useState([]);
    const [loadingcp, setLoadingcp] = useState(false);
    async function getChartPosition(filter = {}, functionID = '0', tipe = '') {
        try {
            setLoadingcp(true);
            const resp = await action.getData("/api/home-admin/dashboard-user-position", {
                tahun: '',
                bulan: '',
                hari: '',
                ...filter,
                functionID,
                tipe
            }, null, null, false);
            if (resp?.code === "00") {
                setData_cposition([...(resp?.data || [])].sort((a, b) => a.jumlah - b.jumlah));
                setFc(functionID);
            }
            setLoadingcp(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }

    const [fa, setFa] = useState('0');
    const [data_cadmin, setData_cadmin] = useState([]);
    const [loadingca, setLoadingca] = useState(false);
    async function getChartUserAdmin(filter = {}, roleID = '0', tipe = '') {
        try {
            setLoadingca(true);
            const resp = await action.getData("/api/home-admin/dashboard-user-admin", {
                tahun: '',
                bulan: '',
                hari: '',
                ...filter,
                roleID,
                tipe
            }, null, null, false);
            if (resp?.code === "00") {
                setData_cadmin([...(resp?.data || [])].sort((a, b) => a.jumlah - b.jumlah));
                setFa(roleID);
            }
            setLoadingca(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }

    const [data_tablehits, setData_tablehits] = useState([]);
    const [loadingth, setLoadingth] = useState(false);
    async function getTableHits(tahun = '') {
        try {
            setLoadingth(true);
            const resp = await action.getData("/api/home-admin/dashboard-table-hits", {
                tahun,
            }, null, null, false);
            if (resp?.code === "00") {
                setData_tablehits(resp?.data);
            }
            setLoadingth(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }

    function dropdownChange(obj) {
        setDownFilter(obj)
        setFtrDaily('');
        setFtrMonthly({
            tahun: '',
            bulan: ''
        });
        setFtrYearly('');

        if (obj?.val === '1') {
            getDashboardDaily();
        }
        if (obj?.val === '2') {
            getDashboardMonthly();
        }
        if (obj?.val === '3') {
            getDashboardYearly();
        }
    }
    function ftrDailyChange(val) {
        const tanggal = moment(val).format('YYYY-MM-DD')?.split('-');
        if (tanggal.length > 1) {
            getDashboardDaily(tanggal[0], tanggal[1], tanggal[2])
        } else {
            getDashboardDaily();
        }
    }
    function tableUserClick(param) {
        if (param === "Daily") {
            const tanggal = moment(ftrDaily).format('YYYY-MM-DD')?.split('-');
            if (tanggal.length > 1) {
                history.push(`/admin/usr-mng/table-user-daily/${tanggal[0]}/${tanggal[1]}/${tanggal[2]}`);
            } else {
                history.push(`/admin/usr-mng/table-user-daily///`);
            }
        }
        if (param === "Monthly") {
            history.push(`/admin/usr-mng/table-user-monthly/${ftrMonthly.tahun || ''}/${ftrMonthly.bulan || ''}`);
        }
        if (param === "Yearly") {
            history.push(`/admin/usr-mng/table-user-yearly/${ftrYearly || ''}`);
        }
    }

    const handleChartPositionChange = (val) => {
        if (downFilter.val === '1') {
            getChartPosition(ftrD, val, 'daily');
        }
        if (downFilter.val === '2') {
            getChartPosition(ftrMonthly, val, 'monthly');
        }
        if (downFilter.val === '3') {
            getChartPosition(ftrYearly, val, 'yearly');
        }
    }
    const handleChartUserDminChange = (val) => {
        if (downFilter.val === '1') {
            getChartUserAdmin(ftrD, val, 'daily');
        }
        if (downFilter.val === '2') {
            getChartUserAdmin(ftrMonthly, val, 'monthly');
        }
        if (downFilter.val === '3') {
            getChartUserAdmin(ftrYearly, val, 'yearly');
        }
    }
    const generateLinkTableDetailFunction = () => {
        var parameter = {};
        if (downFilter.val === '1') {
            parameter = {
                ...ftrD,
                param_filter_name: 'functionID',
                param_filter_value: fc,
                tipe: 'daily',
                tipe_table: 1
            }
        }
        if (downFilter.val === '2') {
            parameter = {
                ...ftrMonthly,
                param_filter_name: 'functionID',
                param_filter_value: fc,
                tipe: 'monthly',
                tipe_table: 1
            }
        }
        if (downFilter.val === '3') {
            parameter = {
                ...ftrYearly,
                param_filter_name: 'functionID',
                param_filter_value: fc,
                tipe: 'yearly',
                tipe_table: 1
            }
        }
        return btoa(JSON.stringify(parameter))
    }
    const generateLinkTableDetailRoleId = () => {
        var parameter = {};
        if (downFilter.val === '1') {
            parameter = {
                ...ftrD,
                param_filter_name: 'roleID',
                param_filter_value: fa,
                tipe: 'daily',
                tipe_table: 2
            }
        }
        if (downFilter.val === '2') {
            parameter = {
                ...ftrMonthly,
                param_filter_name: 'roleID',
                param_filter_value: fa,
                tipe: 'monthly',
                tipe_table: 2
            }
        }
        if (downFilter.val === '3') {
            parameter = {
                ...ftrYearly,
                param_filter_name: 'roleID',
                param_filter_value: fa,
                tipe: 'yearly',
                tipe_table: 2
            }
        }
        return btoa(JSON.stringify(parameter))
    }
    return <contextUsrDashb.Provider
        value={{
            usrDashAction: {
                getDashboardDaily,
                getDashboardMonthly,
                getDashboardYearly,
                dropdownChange,
                ftrDailyChange,
                setSw1,
                setSw2,
                tableUserClick,
                handleChartPositionChange,
                handleChartUserDminChange,
                generateLinkTableDetailFunction,
                generateLinkTableDetailRoleId
            },
            userDashState: {
                data_page,
                data,
                loadingDash,
                downFilter,
                ftrDaily,
                ftrMonthly,
                ftrYearly,
                sw1,
                sw2,
                ftrD,
                data_cposition,
                loadingcp,
                data_cadmin,
                loadingca,
                fc,
                fa,
                data_tablehits,
                loadingth
            }
        }}
    >
        {children}
    </contextUsrDashb.Provider>
}
export default function useUserMngDash() {
    return useContext(contextUsrDashb);
}