import React from 'react';
import { VdPreProvider } from './useViewDetailPreideasi';

function Index(props) {
    return (
        <div>
            Detail Data
        </div>
    );
}

export default (props) => <VdPreProvider><Index {...props}/></VdPreProvider>;