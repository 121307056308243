import React, { useState } from 'react';
import useWhatsapp from '../useWhatsapp';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import { RadioGrp } from '../../../../components';
import { DateBox } from 'devextreme-react';
import Swal from 'sweetalert2';
import useFetch from '../../../../modules/useFetch';
const iniStateFormProjectReminder = {
    "reminder": false,
    'cbTeamLeader': false,
    "cbTeamMember": false,
    "cbManager": false,
    "withSchedulledTime": false,
    "schedulled_at": ""
}
const iniStateFormNeedAttention = {
    "attention": false,
    'cbTeamLeader': false,
    "cbTeamMember": false,
    "cbManager": false,
    "withSchedulledTime": false,
    "schedulled_at": ""
}
const iniStateFormLogin = {
    "login": false,
    "withSchedulledTime": false,
    "schedulled_at": ""
}
const iniStateFormProfile = {
    "profile": false,
    "withSchedulledTime": false,
    "schedulled_at": ""
}
const iniStateFormEquipmet = {
    "equipment": false,
    "withSchedulledTime": false,
    "schedulled_at": ""
}
function WaProjectNotif(props) {
    const { doPost } = useFetch();
    const [formReminder, setFormReminder] = useState(iniStateFormProjectReminder);
    const [formAttention, setFormAttention] = useState(iniStateFormNeedAttention);
    const [formLogin, setFormLogin] = useState(iniStateFormLogin);
    const [formProfile, setFormProfile] = useState(iniStateFormProfile);
    const [formEquipment, setFormEquipment] = useState(iniStateFormEquipmet);

    const {
        waAction: {
            onSendProjectNotification = () => null,
            getProjectNotifSource = () => null
        },
        waState: {
            loadingp = false,
            data_viewp = {}
        }
    } = useWhatsapp();

    const resetFormProjectReminder = () => { setFormReminder({ ...iniStateFormProjectReminder }) };
    const handleChangeProjectReminder = (name, val) => {
        if (name === 'reminder' && val === false) {
            resetFormProjectReminder();
        } else if (name === 'withSchedulledTime' && val === false) {
            setFormReminder((e) => ({ ...e, [name]: val, schedulled_at: "" }));
        } else {
            setFormReminder((e) => ({ ...e, [name]: val }));
        }

    }
    const validationProjectReminder = () => {
        var message = ""
        var isValid = true;
        if (formReminder.cbTeamLeader === false && formReminder.cbTeamMember === false && formReminder.cbManager === false) {
            isValid = false;
            message = "Silahkan tentukan penerima pesan";
        } else if (formReminder.withSchedulledTime && formReminder.schedulled_at === "") {
            isValid = false;
            message = "Silahkan tentukan tanggal pengiriman";
        }
        return {
            isValid,
            message
        }
    }
    const onSendProjectReminder = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Project Reminder?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validationProjectReminder();
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendProjectReminder();
                }
                return true;
            } else {
                return true;
            }
        })
    }
    async function doSendProjectReminder() {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/user/send-wa-smart-project-update-reminder`,
                data: {
                    'cbTeamLeader': formReminder.cbTeamLeader,
                    "cbTeamMember": formReminder.cbTeamMember,
                    "cbManager": formReminder.cbManager,
                    "withSchedulledTime": formReminder.withSchedulledTime,
                    "schedulled_at": formReminder.schedulled_at
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { resetFormProjectReminder() })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const resetFormNeedAttention = () => { setFormAttention({ ...iniStateFormNeedAttention }) };
    const handleChangeNeedAttention = (name, val) => {
        if (name === 'attention' && val === false) {
            resetFormNeedAttention();
        } else if (name === 'withSchedulledTime' && val === false) {
            setFormAttention((e) => ({ ...e, [name]: val, schedulled_at: "" }));
        } else {
            setFormAttention((e) => ({ ...e, [name]: val }));
        }

    }
    const validationNeedAttention = () => {
        var message = ""
        var isValid = true;
        if (formAttention.cbTeamLeader === false && formAttention.cbTeamMember === false && formAttention.cbManager === false) {
            isValid = false;
            message = "Silahkan tentukan penerima pesan";
        } else if (formAttention.withSchedulledTime && formAttention.schedulled_at === "") {
            isValid = false;
            message = "Silahkan tentukan tanggal pengiriman";
        }
        return {
            isValid,
            message
        }
    }
    const onSendNeedAttention = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Project Need Attention?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validationNeedAttention();
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendNeedAttention();
                }
                return true;
            } else {
                return true;
            }
        })
    }
    async function doSendNeedAttention() {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/user/send-wa-smart-project-need-support`,
                data: {
                    'cbTeamLeader': formAttention.cbTeamLeader,
                    "cbTeamMember": formAttention.cbTeamMember,
                    "cbManager": formAttention.cbManager,
                    "withSchedulledTime": formAttention.withSchedulledTime,
                    "schedulled_at": formAttention.schedulled_at
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { resetFormNeedAttention() })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const resetFormLogin = () => { setFormLogin({ ...iniStateFormLogin }) };
    const handleChangeFormLogin = (name, val) => {
        if (name === 'login' && val === false) {
            resetFormLogin();
        } else if (name === 'withSchedulledTime' && val === false) {
            setFormLogin((e) => ({ ...e, [name]: val, schedulled_at: "" }));
        } else {
            setFormLogin((e) => ({ ...e, [name]: val }));
        }
    }
    const validationFormLogin = () => {
        var message = ""
        var isValid = true;
        if (formLogin.withSchedulledTime && formLogin.schedulled_at === "") {
            isValid = false;
            message = "Silahkan tentukan tanggal pengiriman";
        }
        return {
            isValid,
            message
        }
    }
    const onSendFormLogin = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>User login reminder?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validationFormLogin();
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendFormLogin();
                }
                return true;
            } else {
                return true;
            }
        })
    }
    async function doSendFormLogin() {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/user/send-wa-smart-user-login`,
                data: {
                    withSchedulledTime: formLogin.withSchedulledTime,
                    schedulled_at: formLogin.schedulled_at
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { resetFormLogin() })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const resetFormProfile = () => { setFormProfile({ ...iniStateFormProfile }) };
    const handleChangeFormProfile = (name, val) => {
        if (name === 'profile' && val === false) {
            resetFormProfile();
        } else if (name === 'withSchedulledTime' && val === false) {
            setFormProfile((e) => ({ ...e, [name]: val, schedulled_at: "" }));
        } else {
            setFormProfile((e) => ({ ...e, [name]: val }));
        }
    }
    const validationFormProfile = () => {
        var message = ""
        var isValid = true;
        if (formProfile.withSchedulledTime && formProfile.schedulled_at === "") {
            isValid = false;
            message = "Silahkan tentukan tanggal pengiriman";
        }
        return {
            isValid,
            message
        }
    }
    const onSendFormProfile = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>User profile reminder?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validationFormProfile();
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendFormProfile();
                }
                return true;
            } else {
                return true;
            }
        })
    }
    async function doSendFormProfile() {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/user/send-wa-smart-user-profile`,
                data: {
                    withSchedulledTime: formProfile.withSchedulledTime,
                    schedulled_at: formProfile.schedulled_at
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { resetFormProfile() })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const resetFormEquipment = () => { setFormEquipment({ ...iniStateFormEquipmet }) };
    const handleChangeFormEquipment = (name, val) => {
        if (name === 'equipment' && val === false) {
            resetFormEquipment();
        } else if (name === 'withSchedulledTime' && val === false) {
            setFormEquipment((e) => ({ ...e, [name]: val, schedulled_at: "" }));
        } else {
            setFormEquipment((e) => ({ ...e, [name]: val }));
        }
    }
    const validationFormEquipment = () => {
        var message = ""
        var isValid = true;
        if (formEquipment.withSchedulledTime && formEquipment.schedulled_at === "") {
            isValid = false;
            message = "Silahkan tentukan tanggal pengiriman";
        }
        return {
            isValid,
            message
        }
    }
    const onSendFormEquipment = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Equipment Expired Reminder?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validationFormEquipment();
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendFormEquipment();
                }
                return true;
            } else {
                return true;
            }
        })
    }
    async function doSendFormEquipment() {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/user/send-wa-smart-equipment-expire`,
                data: {
                    withSchedulledTime: formEquipment.withSchedulledTime,
                    schedulled_at: formEquipment.schedulled_at
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { resetFormEquipment() })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    useEffect(() => {
        getProjectNotifSource();
    }, [])
    return (
        <div className='card customShadow'>
            <div className='card-body p-5'>
                <div className='row'>
                    <div className='col-12 px-1 mb-3'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-8 col-lg-8'>
                                <div className='bg-light-purple p-5 rounded mb-5'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="reminder"
                                            checked={formReminder.reminder}
                                            onChange={(e) => handleChangeProjectReminder('reminder', e.target.checked)}
                                        />
                                        <label className="form-check-label mb-0" htmlFor="reminder" aria-describedby="passwordHelpBlock">Project Update Reminder</label>
                                        <small id="passwordHelpBlock" className="form-text text-muted font-weight-normal mb-5">Pesan reminder untuk project leader dan team member untuk segera melakukan Update
                                            data terhadap project-project yang masih memiliki status cut off 2 bulan sebelumnya atau lebih,
                                            atau belum melakukan update data sampai dengan bulan monitoring terakhir.
                                        </small>
                                        <div className='mb-3'>
                                            <span className='d-block mb-2'>Jumlah project update reminder {data_viewp?.bulanTahun} :</span>
                                            <div className="text-white rounded p-2 d-inline-block bg-purple">
                                                <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex">
                                                    Project Reminder
                                                    <Link
                                                        to={`/table-project-reminder`} target={"_blank"}
                                                    >
                                                        <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                    </Link>
                                                </span>
                                                <span className="ctsmFilterTitle text-tittle-card mt-auto">{data_viewp?.data_project_update_reminder || 0}</span>
                                            </div>
                                            {
                                                formReminder.reminder && (
                                                    <>
                                                        <div className='mb-1 mt-2'><b>WA Recipient</b></div>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="rleader"
                                                                onChange={(e) => handleChangeProjectReminder("cbTeamLeader", e.target.checked)}
                                                            />
                                                            <label className="form-check-label mb-0" htmlFor="rleader" aria-describedby="passwordHelpBlock">Project Leader</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="rmember"
                                                                onChange={(e) => handleChangeProjectReminder("cbTeamMember", e.target.checked)}
                                                            />
                                                            <label className="form-check-label mb-0" htmlFor="rmember" aria-describedby="passwordHelpBlock">Project Member</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="rmanager"
                                                                onChange={(e) => handleChangeProjectReminder("cbManager", e.target.checked)}
                                                            />
                                                            <label className="form-check-label mb-0" htmlFor="rmanager" aria-describedby="passwordHelpBlock">Manager</label>
                                                        </div>
                                                        <div className='mb-1 mt-2'><b>WA Delivery Time</b></div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value={'opt1'} checked={!formReminder.withSchedulledTime} onChange={(e) => handleChangeProjectReminder('withSchedulledTime', false)} />
                                                            <label className="form-check-label" for="exampleRadios1">
                                                                Now
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value={'opt2'} checked={formReminder.withSchedulledTime} onChange={(e) => handleChangeProjectReminder('withSchedulledTime', true)} />
                                                            <label className="form-check-label" for="exampleRadios2">
                                                                Scheduled at
                                                            </label>
                                                        </div>
                                                        {
                                                            formReminder.withSchedulledTime === true && (
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <DateBox
                                                                            className='mb-5'
                                                                            min={new Date().getTime()}
                                                                            type="datetime"
                                                                            displayFormat="yyyy-MM-dd HH:mm:ss"
                                                                            onValueChanged={(e) => handleChangeProjectReminder('schedulled_at', e.value ? moment(e.value).format('yyyy-MM-DD-HH-mm-ss') : "")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className='text-right'>
                                            {
                                                formReminder.reminder && (
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() => onSendProjectReminder()}
                                                    >Submit Reminder</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-light-warning p-5 rounded mb-5'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="attention"
                                            checked={formAttention.attention}
                                            onChange={(e) => handleChangeNeedAttention('attention', e.target.checked)}
                                        />
                                        <label className="form-check-label mb-0" htmlFor="attention" aria-describedby="passwordHelpBlock">Project Need Attention Notification</label>
                                        <small id="passwordHelpBlock" className="form-text text-muted font-weight-normal mb-5">Pesan reminder untuk project leader dan team member untuk segera melakukan update data terhadap project yang memiliki status "Need Attention", yaitu project yang memiliki status performance "Delay" selama 3 bulan berturut-turut atau memiliki catatan khusus dari manajemen.
                                        </small>
                                        <div className='mb-3'>
                                            <span className='d-block mb-2'>Jumlah project need attention {data_viewp?.bulanTahun} :</span>
                                            <div className="text-white rounded p-2 d-inline-block bg-warning">
                                                <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex">
                                                    Project Need Attention
                                                    <Link to={`/table-project-need-attention`} target={"_blank"}>
                                                        <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                    </Link>
                                                </span>
                                                <span className="ctsmFilterTitle text-tittle-card mt-auto">{data_viewp?.data_project_need_attention_reminder || 0}</span>
                                            </div>
                                            {
                                                formAttention.attention && (
                                                    <>
                                                        <div className='mb-1 mt-2'><b>WA Recipient</b></div>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="aleader"
                                                                onChange={(e) => handleChangeNeedAttention("cbTeamLeader", e.target.checked)}
                                                            />
                                                            <label className="form-check-label mb-0" htmlFor="aleader" aria-describedby="passwordHelpBlock">Project Leader</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="amember"
                                                                onChange={(e) => handleChangeNeedAttention("cbTeamMember", e.target.checked)}
                                                            />
                                                            <label className="form-check-label mb-0" htmlFor="amember" aria-describedby="passwordHelpBlock">Project Member</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="amanager"
                                                                onChange={(e) => handleChangeNeedAttention("cbManager", e.target.checked)}
                                                            />
                                                            <label className="form-check-label mb-0" htmlFor="amanager" aria-describedby="passwordHelpBlock">Manager</label>
                                                        </div>
                                                        <div className='mb-1 mt-2'><b>WA Delivery Time</b></div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="attention" id="attention1" value={'opt1'} checked={!formAttention.withSchedulledTime} onChange={(e) => handleChangeNeedAttention('withSchedulledTime', false)} />
                                                            <label className="form-check-label" htmlFor="attention1">
                                                                Now
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="attention" id="attention2" value={'opt2'} checked={formAttention.withSchedulledTime} onChange={(e) => handleChangeNeedAttention('withSchedulledTime', true)} />
                                                            <label className="form-check-label" htmlFor="attention2">
                                                                Scheduled at
                                                            </label>
                                                        </div>
                                                        {
                                                            formAttention.withSchedulledTime === true && (
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <DateBox
                                                                            className='mb-5'
                                                                            min={new Date().getTime()}
                                                                            type="datetime"
                                                                            displayFormat="yyyy-MM-dd HH:mm:ss"
                                                                            onValueChanged={(e) => handleChangeNeedAttention('schedulled_at', e.value ? moment(e.value).format('yyyy-MM-DD-HH-mm-ss') : "")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className='text-right'>
                                            {
                                                formAttention.attention && (
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() => onSendNeedAttention()}
                                                    >Submit Project Need Attention</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='bg-light-danger p-5 rounded mb-5'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="delay"
                                            checked={delay}
                                            onChange={(e) => setDelay(e.target.checked)}
                                        />
                                        <label className="form-check-label mb-0" htmlFor="delay" aria-describedby="passwordHelpBlock">Project Delay Notification</label>
                                        <small id="passwordHelpBlock" className="form-text text-muted font-weight-normal mb-5">Pesan reminder untuk project leader dan team member untuk segera melakukan Update
                                            data terhadap project-project yang masih memiliki status cut off 2 bulan sebelumnya atau lebih,
                                            atau belum melakukan update data sampai dengan bulan monitoring terakhir.
                                        </small>
                                        <div className='mb-3'>
                                            <span className='d-block mb-2'>Jumlah project belum melakukan update data sampai dengan MAR 2023 :</span>
                                            <div className="text-white rounded p-2 d-inline-block bg-danger">
                                                <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex">
                                                    Project Delay
                                                    <Link>
                                                        <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                    </Link>
                                                </span>
                                                <span className="ctsmFilterTitle text-tittle-card mt-auto">0</span>
                                            </div>
                                            <div className='mb-1 mt-2'><b>WA Recipient</b></div>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="dleader"
                                                />
                                                <label className="form-check-label mb-0" htmlFor="dleader" aria-describedby="passwordHelpBlock">Project Leader</label>
                                            </div>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="dmember"
                                                />
                                                <label className="form-check-label mb-0" htmlFor="dmember" aria-describedby="passwordHelpBlock">Project Member</label>
                                            </div>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="dmanager"
                                                />
                                                <label className="form-check-label mb-0" htmlFor="dmanager" aria-describedby="passwordHelpBlock">Manager</label>
                                            </div>
                                        </div>
                                        <div className='text-right'>
                                            {
                                                delay && (
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() => onSendProjectNotification('delay')}
                                                    >Submit Project Delay</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div> */}
                                <div className='bg-light-primary p-5 rounded mb-5'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="delay"
                                            checked={formLogin.login}
                                            onChange={(e) => handleChangeFormLogin('login', e.target.checked)}
                                        />
                                        <label className="form-check-label mb-0" htmlFor="delay" aria-describedby="passwordHelpBlock">User Login Reminder</label>
                                        <small id="passwordHelpBlock" className="form-text text-muted font-weight-normal mb-5">Pesan reminder untuk user yang tercatat belum melakukan login ke aplikasi SPIIRIT dalam 3 bulan terakhir.
                                        </small>
                                        <div className='mb-3'>
                                            <span className='d-block mb-2'>Jumlah user login reminder :</span>
                                            <div className="text-white rounded p-2 d-inline-block bg-primary">
                                                <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex">
                                                    User Login Reminder
                                                    <Link
                                                        to={`/table-user-login-reminder`}
                                                        target={"_blank"}
                                                    >
                                                        <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                    </Link>
                                                </span>
                                                <span className="ctsmFilterTitle text-tittle-card mt-auto">{data_viewp?.data_user_login_reminder || 0}</span>
                                            </div>
                                            {
                                                formLogin.login && (
                                                    <>
                                                        <div className='mb-1 mt-2'><b>WA Delivery Time</b></div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="login" id="login1" value={'opt1'} checked={!formLogin.withSchedulledTime} onChange={(e) => handleChangeFormLogin('withSchedulledTime', false)} />
                                                            <label className="form-check-label" htmlFor="login1">
                                                                Now
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="login" id="login2" value={'opt2'} checked={formLogin.withSchedulledTime} onChange={(e) => handleChangeFormLogin('withSchedulledTime', true)} />
                                                            <label className="form-check-label" htmlFor="login2">
                                                                Scheduled at
                                                            </label>
                                                        </div>
                                                        {
                                                            formLogin.withSchedulledTime === true && (
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <DateBox
                                                                            className='mb-5'
                                                                            min={new Date().getTime()}
                                                                            type="datetime"
                                                                            displayFormat="yyyy-MM-dd HH:mm:ss"
                                                                            onValueChanged={(e) => handleChangeFormLogin('schedulled_at', e.value ? moment(e.value).format('yyyy-MM-DD-HH-mm-ss') : "")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className='text-right'>
                                            {
                                                formLogin.login && (
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() => onSendFormLogin()}
                                                    >Submit User Login</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-light-success p-5 rounded mb-5'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="profile"
                                            checked={formProfile.profile}
                                            onChange={(e) => handleChangeFormProfile('profile', e.target.checked)}
                                        />
                                        <label className="form-check-label mb-0" htmlFor="profile" aria-describedby="passwordHelpBlock">User Update Profile Reminder</label>
                                        <small id="passwordHelpBlock" className="form-text text-muted font-weight-normal mb-5">Pesan reminder untuk user yang tercatat masih belum melengkapi data prodile di aplikasi SPIIRIT.
                                        </small>
                                        <div className='mb-3'>
                                            <span className='d-block mb-2'>Jumlah user profile reminder :</span>
                                            <div className="text-white rounded p-2 d-inline-block bg-success">
                                                <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex">
                                                    User Update Profile Reminder
                                                    <Link
                                                        to={`/table-user-profile-reminder`}
                                                        target={"_blank"}
                                                    >
                                                        <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                    </Link>
                                                </span>
                                                <span className="ctsmFilterTitle text-tittle-card mt-auto">{data_viewp?.data_user_profile_reminder || 0}</span>
                                            </div>
                                            {
                                                formProfile.profile && (
                                                    <>
                                                        <div className='mb-1 mt-2'><b>WA Delivery Time</b></div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="profile" id="profile1" value={'opt1'} checked={!formProfile.withSchedulledTime} onChange={(e) => handleChangeFormProfile('withSchedulledTime', false)} />
                                                            <label className="form-check-label" htmlFor="profile1">
                                                                Now
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="profile" id="profile2" value={'opt2'} checked={formProfile.withSchedulledTime} onChange={(e) => handleChangeFormProfile('withSchedulledTime', true)} />
                                                            <label className="form-check-label" htmlFor="profile2">
                                                                Scheduled at
                                                            </label>
                                                        </div>
                                                        {
                                                            formProfile.withSchedulledTime === true && (
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <DateBox
                                                                            className='mb-5'
                                                                            min={new Date().getTime()}
                                                                            type="datetime"
                                                                            displayFormat="yyyy-MM-dd HH:mm:ss"
                                                                            onValueChanged={(e) => handleChangeFormProfile('schedulled_at', e.value ? moment(e.value).format('yyyy-MM-DD-HH-mm-ss') : "")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className='text-right'>
                                            {
                                                formProfile.profile && (
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() => onSendFormProfile()}
                                                    >Submit User Update Profile</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-light-danger p-5 rounded mb-5'>
                                    <div className="form-check mt-1">
                                        <input type="checkbox" className="form-check-input" id="equipment"
                                            checked={formEquipment.equipment}
                                            onChange={(e) => handleChangeFormEquipment('equipment', e.target.checked)}
                                        />
                                        <label className="form-check-label mb-0" htmlFor="equipment" aria-describedby="passwordHelpBlock">Equipment Expired Reminder</label>
                                        <small id="passwordHelpBlock" className="form-text text-muted font-weight-normal mb-5">Pesan reminder untuk Admin dan PIC Equipment untuk update status terbaru equipment yang sudah habis masa berlaku statusnya.
                                        </small>
                                        <div className='mb-3'>
                                            <span className='d-block mb-2'>Equipment Expired Reminder :</span>
                                            <div className="text-white rounded p-2 d-inline-block bg-danger">
                                                <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex">
                                                    Equipment Expired Reminder
                                                    <Link
                                                        to={`/table-user-equipment-expired-reminder`}
                                                        target={"_blank"}
                                                    >
                                                        <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                    </Link>
                                                </span>
                                                <span className="ctsmFilterTitle text-tittle-card mt-auto">{data_viewp?.data_equipment_expire || 0}</span>
                                            </div>
                                            {
                                                formEquipment.equipment && (
                                                    <>
                                                        <div className='mb-1 mt-2'><b>WA Delivery Time</b></div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="equipment" id="equipment1" value={'opt1'} checked={!formEquipment.withSchedulledTime} onChange={(e) => handleChangeFormEquipment('withSchedulledTime', false)} />
                                                            <label className="form-check-label" htmlFor="equipment1">
                                                                Now
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="equipment" id="equipment2" value={'opt2'} checked={formEquipment.withSchedulledTime} onChange={(e) => handleChangeFormEquipment('withSchedulledTime', true)} />
                                                            <label className="form-check-label" htmlFor="equipment2">
                                                                Scheduled at
                                                            </label>
                                                        </div>
                                                        {
                                                            formEquipment.withSchedulledTime === true && (
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <DateBox
                                                                            className='mb-5'
                                                                            min={new Date().getTime()}
                                                                            type="datetime"
                                                                            displayFormat="yyyy-MM-dd HH:mm:ss"
                                                                            onValueChanged={(e) => handleChangeFormEquipment('schedulled_at', e.value ? moment(e.value).format('yyyy-MM-DD-HH-mm-ss') : "")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className='text-right'>
                                            {
                                                formEquipment.equipment && (
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() => onSendFormEquipment()}
                                                    >Submit Equipment Expired Reminder</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WaProjectNotif;