import React, { useRef } from 'react';
import useDut, { DutProvider } from './useDut';
import { GridDx, HeaderList } from '../../components';
import { Card } from 'react-bootstrap';
import { formatter } from '../../components/utility/utility';

function Index(props) {
    const { dutState } = useDut();
    const tableRef = useRef();

    const customCellRender = (rowInfo) => {
        if (rowInfo.column.name === 'jumlah') {
            return (<span className='d-block text-wrap text-right'>{formatter.format(rowInfo.value)}</span>)
        } else {
            return (<span className='d-block text-wrap'>{rowInfo.value}</span>)
        }
    }
    return (
        <div>
            <HeaderList
                title={dutState.data_page?.page_title}
                someText={dutState.data_page?.page_breadcrumbs}
            />
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <GridDx
                                ref={tableRef}
                                searchForm={true}
                                cellRender={customCellRender}
                                columns={dutState.column}
                                data={dutState.data_table}
                                loading={dutState.loading}
                            >
                            </GridDx>
                        </Card.Body>
                    </Card>
                </div>
            </div>

        </div>
    );
}

export default () => <DutProvider><Index /></DutProvider>;