import React, { memo } from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];


export default function ({
    children,
    fileName = `file`,
    sheets = [],
}) {

    function dataArray(data) {
        let str = ""
        data.forEach(function (d, i) {
            // console.log(d)
            if (typeof d === "object") return dataObject(d)
            else return str = `${str} - ${d} \u2029`;
        })
        return str;
    }
    function dataObject(data) {
        let keys = Object.keys(data || {});
        let str = ''
        keys.forEach(function (d, i) {
            if (typeof data[d] === "object") dataObject(d)
            else if (Array.isArray(data[d])) dataArray(data[d])
            else str = `${str} - ${d} : ${data[d]} \u2029`
        })
        return str;
    }
    return (
        <ExcelFile
            wrapText={true}
            element={children}
            filename={fileName}
        >
            {
                sheets.map(({ data = [], sheetName = null, columns = [] }, i) => (
                    <ExcelSheet
                        data={data}
                        name={sheetName === null ? `Sheet${i + 1}` : sheetName}
                        key={i}
                    >
                        {
                            columns.map(({ fieldName, caption, onExport = null }, j) => (
                                <ExcelColumn
                                    label={caption}
                                    key={`${i}${j}`}
                                    value={
                                        (col) => {
                                            if (onExport === null) {
                                                if (Array.isArray(col[fieldName])) return dataArray(col[fieldName])
                                                else if (typeof col[fieldName] === "object") return dataObject(col[fieldName])
                                                else return col[fieldName]
                                            } else if (typeof onExport === "function") return onExport(col)
                                            else throw Error(`onExport must be function returning data "String or Integer or Boolean" `)
                                            return ""
                                            // onExport === null ? col[fieldName] : onExport(col)
                                        }
                                    }
                                />
                            ))
                        }
                    </ExcelSheet>
                ))
            }
        </ExcelFile>
    );
}