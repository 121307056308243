import React, { memo, useState, useEffect, useRef } from 'react'
import { GridDx } from '../../../components';
import Card from 'react-bootstrap/Card'
import usePortfolio from './usePortfolio';
import SVG from "react-inlinesvg";
import moment from 'moment';

const columns = [
    { fieldName: "created_at", caption: "Date Created", width: 200 },
    { fieldName: "title", caption: "Title", link: true, url: "/portfolio-research/view", width: 500 },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "function_name", caption: "Function", width: 200 },
    { fieldName: "chief_name", caption: "Chief", width: 200 },
    { fieldName: "area_name", caption: "Area", width: 200 },
    { fieldName: "cat_name", caption: "Category", width: 200 },
    { fieldName: "project_type_short_name", caption: "Project Type", width: 200 },
    { fieldName: "focus", caption: "Ruang Lingkup Riset", width: 400 },    
    { fieldName: "leader", caption: "Owner", width: 200 },
    { fieldName: "plan_tot_budget", caption: "Budget Total (Ribu USD)", width: 200 },
    { fieldName: "method_name", caption: "Metode", width: 200 },
    { fieldName: "potensial_partner_opt_name", caption: "Potential Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potential User", width: 200 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_tangible_keterangan", caption: "Justifikasi", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "stage", caption: "Stage", width: 200 },
    { fieldName: "year_start", caption: "Year Start", width: 200 },
    { fieldName: "year_end", caption: "Year End", width: 200 },
    { fieldName: "keyword", caption: "Keyword", width: 300 },
]

const TabTable = memo(function ({ reporting = false, view_admin = false }) {
    const { portAction, portState } = usePortfolio();
    const tableRef = useRef(null);

    useEffect(() => {
        if (reporting === false) {
            if (view_admin === true) {
                portAction.getTable();
            } else {
                portAction.getTableSpiirit();
            }
        } else {
            portAction.getTableAdmin();
        }
    }, [portState.tahun]);


    return (
        <>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Research</span>
                                    <span className="d-block"><strong>{
                                        portState.dataTable?.data_jumlah_project?.total
                                    }</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-pink" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Review</span>
                                    <span className="d-block"><strong>{portState.dataTable?.data_jumlah_project?.review || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Need Revision</span>
                                    <span className="d-block"><strong>{portState.dataTable?.data_jumlah_project?.draft_need_revision || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Rejected</span>
                                    <span className="d-block"><strong>{portState.dataTable?.data_jumlah_project?.rejected || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Waiting Implementation</span>
                                    <span className="d-block"><strong>{portState.dataTable?.data_jumlah_project?.waiting_implementation || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Running</span>
                                    <span className="d-block"><strong>{portState.dataTable?.data_jumlah_project?.running || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-secondary" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Finished</span>
                                    <span className="d-block"><strong>{portState.dataTable?.data_jumlah_project?.finished || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Aborted</span>
                                    <span className="d-block"><strong>{portState.dataTable?.data_jumlah_project?.aborted || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom pl-md-5">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Budget (Ribu USD)</span>
                                    <span className="d-block">$ <strong>{parseFloat(portState.dataTable?.data_jumlah_project?.total_plan_budget || 0).toFixed(1)}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 h-100 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12">
                            <GridDx
                                selectionMode={reporting ? "multiple" : false}
                                exportMode="selection"
                                exportExcel={reporting ? true : false}
                                // exportPdf={reporting ? true : false}
                                settingColumn={reporting ? true : false}
                                defaultColumn={columns}
                                filenameExport={`Porfolio Table-${portState.tahun}`}

                                searchForm={true}
                                ref={tableRef}
                                loading={portState.loadingTable}
                                cellRender={portAction.cellRenderGrid}
                                data={portState.dataTable?.data_table}
                                columns={columns}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >

                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})
export default TabTable;