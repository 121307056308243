import React from 'react';
import { useParams } from 'react-router';
import { GanttChartP } from '../../../components';
import useMonthlyReport from './useMonthlyReport';
const PageTimeline = () => {

    const { rState, rAction } = useMonthlyReport();
    const type = [
        { "value": "milestones", "name": "Milestone" },
        { "value": "submilestones", "name": "Sub Milestone" },
        { "value": "task", "name": "Task" },
        { "value": "subtask", "name": "Sub Task" }
    ];

    const { id, year, month } = useParams();
    return (
        <div className="page-content parent-dok">
            <div className="row">
                <div className="col">

                    <GanttChartP
                        dataSource={(rState?.data?.data_view_timeline || []).find(v => v.year === parseInt(year))?.data}
                        showActualPlan={true}
                        defaultStyle=''
                        column={[
                            { "fieldName": "task", "caption": "Task", minWidth: 100 },
                            { "fieldName": "type", "caption": "Type", "custom": true, "customType": "lookup", "dsCustom": type, "displayExpr": "name", "valueExpr": "value", "visible": true, width: 100 },
                            { "fieldName": "plan_jan", "caption": "JAN", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_feb", "caption": "FEB", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_mar", "caption": "MAR", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_apr", "caption": "APR", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_may", "caption": "MAY", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_jun", "caption": "JUN", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_jul", "caption": "JUL", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_aug", "caption": "AUG", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_sep", "caption": "SEP", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_oct", "caption": "OCT", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_nov", "caption": "NOV", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_dec", "caption": "DEC", "visible": true, "dataType": "number", width: 50 }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}

export default PageTimeline;