import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { MonitoringSub } from '..';
import { HeaderList, Tab, TabContent } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import TabTableProgress from '../monitoring/tableProgress';
import TabPerformance from '../monitoring/tabPerformance';
import TabProfile from '../monitoring/tabProfile';
import TabTable from '../monitoring/tabTable';
import useMonitoring, { MonProvider } from '../monitoring/useMonitoring';
import TabSubholdings from './tabSubholdings';

const month = [{ name: 'JAN', value: '1' }, { name: 'FEB', value: '2' }, { name: 'MAR', value: '3' }, { name: 'APR', value: '4' },
{ name: 'MAY', value: '5' }, { name: 'JUN', value: '6' }, { name: 'JUL', value: '7' }, { name: 'AUG', value: '8' }, { name: 'SEP', value: '9' },
{ name: 'OCT', value: '10' }, { name: 'NOV', value: '11' }, { name: 'DEC', value: '12' }]

const ReportingMonitoring = memo(function () {
    const [tabName, setTabName] = useState("performanceFunction");
    const [state] = useGlobalState();
    const {
        monState: {
            tahun,
            bulan,
        },
        monAction: {
            setTahun,
            setBulan
        } } = useMonitoring();

    return (
        <>
            <div style={{ paddingBottom: 20 }}>
                <HeaderList
                    title={state?.data_page?.page_title || ''}
                    someText={state?.data_page?.page_breadcrumbs || ''}
                    optionList={[
                        {
                            tabName: "performanceFunction",
                            withLabel: true,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                            option: state.option_year,
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "performanceFunction",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                        {
                            tabName: "performanceArea",
                            withLabel: true,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                            option: state.option_year,
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "performanceArea",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                        {
                            tabName: "profile",
                            withLabel: true,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                            option: state.option_year,
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "table",
                            withLabel: true,
                            option: state.option_year,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI ",
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "table",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                        {
                            tabName: "table-progress",
                            withLabel: true,
                            option: state.option_year,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI ",
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "table-progress",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                        {
                            tabName: "subholding",
                            withLabel: true,
                            option: state.option_year,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "subholding",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                    ].filter(op => op.tabName === tabName)}
                >
                </HeaderList>
                <Tab
                    withCard={false}
                    itemsTab={[
                        {
                            id: "performanceFunction",
                            title: "Project Performance",
                            onClick: (e) => setTabName("performance")
                        },
                        {
                            id: "performanceArea",
                            title: "Performance by Area",
                            onClick: (e) => setTabName("performanceArea")
                        },
                        {
                            id: "profile",
                            title: "Project Profile",
                            onClick: (e) => setTabName("profile")
                        },
                        {
                            id: "table",
                            title: "Table Detail",
                            onClick: (e) => setTabName("table")
                        },
                        {
                            id: "table-progress",
                            title: "Table Progress",
                            onClick: (e) => setTabName("table-progress")
                        },
                        {
                            id: "subholding",
                            title: "Table Subholdings",
                            onClick: (e) => setTabName("subholding")
                        }
                    ]}
                    manualActive={tabName}
                >
                    <TabContent id="performanceFunction">
                        <TabPerformance reporting={true}></TabPerformance>
                    </TabContent>
                    <TabContent id="performanceArea">
                        <TabPerformance byParam='area' reporting={true}></TabPerformance>
                    </TabContent>
                    <TabContent id="profile">
                        <TabProfile reporting={true}></TabProfile>
                    </TabContent>
                    <TabContent id="table">
                        <TabTable reporting={true}></TabTable>
                    </TabContent>
                    <TabContent id="table-progress">
                        <TabTableProgress reporting={true}></TabTableProgress>
                    </TabContent>
                    <TabContent id="subholding">
                        <TabSubholdings reporting={true} />
                    </TabContent>
                </Tab>
            </div>
        </>
    )
})

export default () => <MonProvider><ReportingMonitoring /></MonProvider>