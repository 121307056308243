import React, { memo, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Card } from 'react-bootstrap'
import { RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import { HeaderProject, HeaderList } from '../../components';
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default memo(function () {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const param = useParams()
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))

    useEffect(() => {
        if (param.id && param.tahun) {
            if (path !== "admin") {
                history.push("/error-page")
            } else {
                getProjectDetail(param.id, param.tahun)
            }
        }
    }, [])
    function getProjectDetail(id, tahun) {
        if (id) {
            action.getData("/api/project-ongoing-crud/view-update-project-unggulan/" + id + "/" + tahun).then((resp) => {
                if (resp.code === '00') {
                    action.setForm('project_unggulan', `${resp.data.data_view_project_unggulan?.project_unggulan}`)
                    dispatch({
                        type: 'getSuccess',
                        payload: { data_page: resp?.data_page }
                    })
                }
            })
        }
    }
    function saveData() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Save Project Unggulan?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // console.log('semua data', state)
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/update-project-unggulan",
                        data: {
                            "research_id": state.data_view_header?.id,
                            "project_unggulan": state.formData.project_unggulan,
                            "year":param.tahun
                        },
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        getProjectDetail(param.id, param.tahun)
                        //history.push('/admin/update/ProjectMonitoring')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <>
            <HeaderList
                title={state.data_page?.page_title}
                someText={state.data_page?.page_breadcrumbs}
            />
            <HeaderProject
                workflow_stage="Project Monitoring"
                rightMenu={true}
                dataHeader={state.data_view_header}
            />
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body className="p-3">
                    <span className="d-block px-1" style={{ fontWeight: 'bold' }}>Project Unggulan</span>
                    <hr />
                    <div className="row px-1 mb-3">
                        <div className="col-md-3">
                            Tahun
                        </div>
                        <div className="col-md-9">
                            {param?.tahun}
                        </div>
                    </div>
                    <div className="row px-1 mb-3">
                        <div className="col-md-3">
                            Project Unggulan
                        </div>
                        <div className="col-md-9">
                            <RadioGrp
                                value={state.formData.project_unggulan}
                                onChange={(e) => action.setForm('project_unggulan', e)}
                                modeRow={true}
                                data={[{ label: "Ya", value: '1' }, { label: "Tidak", value: '0' }]}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className={`p-2 bg-white rounded shadow-lg floating-footer${path.toLowerCase() === 'admin' ? '-nolayout' : ''}`}>
                {
                    path.toLocaleLowerCase() !== 'admin' && history.length > 1 && <button className="btn btn-sm btn-secondary m-1"
                        onClick={() => history.goBack()}
                    >
                        Kembali
                    </button>
                }

                <button className="btn btn-sm btn-primary m-1 float-md-right float-lg-right"
                    onClick={() => saveData()}
                >
                    <i className="fa fa-save icon-nm"></i>
                    &nbsp;Save
                </button>
            </div>
        </>
    )
})