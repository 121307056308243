import React, { useEffect, useState } from 'react';
import usePermohonanPengajuan from '../usePermohonanPengajuan';
import { Modal } from 'react-bootstrap';
import { toString } from 'lodash';

const inistateFormSample = {
    "lab_master_como_id": "",
    "satuan": "",
    "nama": "",
    "volume_berat": "",
    "note":""
}

function ModalAddEditSample(props) {
    const {
        ppState: {
            modal: {
                show = false,
                editMode = false,
                defaultData = {}
            },
            jenisKomoditi = []
        },
        ppAction: {
            closeModal = () => null,
            onClickSimpanModal = () => null
        }
    } = usePermohonanPengajuan();
    const [formSample, setFormsample] = useState({ ...inistateFormSample });

    const handleChange = (name, val) => {
        setFormsample(prev => ({ ...prev, [name]: val }));
    }


    useEffect(() => {
        const setdefaultValue = () => {
            setFormsample(prev => ({ ...prev, ...defaultData }));
        }
        const resetForm = () => {
            setFormsample({ ...inistateFormSample });
        }
        if (show) {
            setdefaultValue();
        } else {
            resetForm();
        }
    }, [show])
    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {` ${editMode === true ? 'Edit' : 'Add'} Sample`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Jenis Komoditi</label>
                    <div className="col-sm-8">
                        <select className='form-control form-control-sm mb-2'
                            value={toString(formSample.lab_master_como_id)}
                            onChange={e => handleChange('lab_master_como_id', e.target.value)}
                        >
                            {
                                [{
                                    "id": 0,
                                    "nama": "-Pilih-",
                                    "note": null,
                                    "note_int": null
                                }, ...jenisKomoditi].map((d, i) => <option value={toString(d?.id)} key={i}>{d?.nama}</option>)
                            }
                        </select>
                    </div>
                </div>
                {/* <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Satuan</label>
                    <div className="col-sm-8">
                        <select className='form-control form-control-sm'
                            value={formSample.satuan}
                            onChange={e => handleChange('satuan', e.target.value)}
                        >
                            <option value={""}>-Pilih-</option>
                            <option value={"Botol"}>Botol</option>
                            <option value={"Pack"}>Pack</option>
                            <option value={"Jerigen"}>Jerigen</option>
                        </select>
                    </div>
                </div> */}
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Nama Sample</label>
                    <div className="col-sm-8">
                        <textarea className="form-control form-control-sm" id="namaSample" rows="2"
                            value={formSample.nama || ""}
                            onChange={e => handleChange("nama", e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Volume/Berat Sample</label>
                    <div className="col-sm-8">
                        <div className='d-flex'>
                            <input className='form-control form-control-sm mr-2'
                                type='number'
                                min={0}
                                value={formSample.volume_berat || 0}
                                onChange={e => handleChange("volume_berat", e.target.value)}
                            />
                            <select className='form-control form-control-sm'
                                value={formSample.satuan || ""}
                                onChange={e => handleChange("satuan", e.target.value)}
                            >
                                <option value={""}>-Pilih-</option>
                                <option value={"Kg"}>Kg</option>
                                <option value={"Liter"}>Liter</option>
                                <option value={"Botol"}>Botol</option>
                                <option value={"Pack"}>Pack</option>
                                <option value={"Jerigen"}>Jerigen</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan (Optional)</label>
                    <div className="col-sm-8">
                        <textarea className='form-control form-control-sm' rows={3}
                            value={formSample?.note || ""}
                            onChange={e => handleChange("note", e.target.value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={closeModal} className="btn btn-sm btn-secondary">Close</button>
                <button onClick={() => onClickSimpanModal(editMode, formSample)} className="btn btn-sm btn-primary"><i className="fa fa-save icon-nm"></i>Simpan</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalAddEditSample;