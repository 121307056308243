import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import useFetch from '../../../../modules/useFetch';
import { useParams } from 'react-router-dom';
import { GridDx } from '../../../../components';
import CustomerDetailForm from '../../../nonAdmin/laboratory/component/customerDetailForm';


const column = [
    { fieldName: "action_", caption: "Action", allowSorting: false, allowFiltering: false },
    { fieldName: "tanggal", caption: "Tanggal" },
    { fieldName: "no", caption: "No. Pengujian", allowSorting: false, allowFiltering: false },
    { fieldName: "jujdul", caption: "Judul Pengujian" },
    { fieldName: "status", caption: "Status Pengujian" },
];

function Index(props) {
    const { id } = useParams();
    const { doGet } = useFetch();
    const tableRef = useRef();

    const [dataCu, setDataCu] = useState({});
    const getData = async () => {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-customer-detail',
                param: {
                    id_customer: id
                }
            });
            if (resp?.code === '00') {
                setDataCu({ ...resp?.data });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <div className='row'>
            <div className='col-md-12'>
                <div className='rounded customShadow p-5'>
                <CustomerDetailForm data={dataCu.data_customer}/>
                    <h5><b>History Pengujian Lab</b></h5>
                    {
                        React.useMemo(() => (
                            <GridDx
                                className='custom-style-master'
                                searchForm={true}
                                ref={tableRef}
                                data={dataCu?.data_history_lab_test || []}
                                columns={column}
                                pagination={true}
                                orderingDrag={false}
                                withAction={false}
                                currentPage
                                show
                            />

                        ), [])
                    }
                </div>
            </div>
        </div>
    );
}

export default Index;