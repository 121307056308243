import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GridDx, HeaderList } from '../../components';
import {
    month_source,
    status_progress_shortName,
    status_progress_shortName_2,
    perform_work,
    workflow_state_name_light_style,
    status_budget_light,
    subholding,
    potensial_partner_opt_name,
    need_management_support,
    value_creation
} from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import useSubholdings from './useSubholdings';
import SVG from "react-inlinesvg";

const budgetFilter = [
    { text: 'Under Budget', value: 1 },
    { text: 'Within Budget', value: 2 },
    { text: 'Over Budget', value: 3 },
    { text: 'Aborted', value: 4 },
    { text: '-', value: null },
]
const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]
const needMenagSupot = [
    { text: 'Tidak', value: '0' },
    { text: 'Ya', value: '1' },
]
const columnSubHol = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "function_sn", caption: "Fungsi", width: 100 },
    { fieldName: "chief_sn", caption: "Chief", width: 100 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
    { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
    { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
    { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "area_name", caption: "Area", width: 100 },
    { fieldName: "research_category_id", caption: "Research Kategori", width: 115 },
    { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
    { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
    { fieldName: "leader", caption: "Leader", width: 100 },
    { fieldName: "method_name", caption: "Metode", width: 100 },
    { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potensial User", width: 200 },
    { fieldName: "need_management_support", caption: "Need Management Support", width: 120, headerFilterSource: needMenagSupot },
    { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "year_start", caption: "Start", width: 100 },
    { fieldName: "year_end", caption: "End", width: 100 },
]
function TabTable(props) {
    const { subhState, subhAction } = useSubholdings();
    const tableRef = useRef();

    useEffect(() => {
        subhAction.getDataTabTable(subhState.filter?.tahun, subhState.filter?.bulan, subhState.subHoldingFilter);
    }, []);

    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/Ongoingresearch-public/view/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span></Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName(e, subhState.dataTabTable?.data_bulanNow))
        } else if (e.column.name === "status_progress") {
            return (status_progress_shortName_2(e))
        } else if (e.column.name === "status_budget") {
            return (status_budget_light(e))
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "potensial_partner_opt_name") {
            return (potensial_partner_opt_name(e))
        } else if (e.column.name === "need_management_support") {
            return (need_management_support(e))
        }
        // else if (e.column.name === "subholding") {
        //     return (subholding(e))
        // } 
        else if (e.column.name === "value_creation") {
            return (value_creation(e))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }
    return (
        <div>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-3">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-auto col-md-auto col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom pr-3">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Project</span>
                                    <span className="d-block"><strong>{(subhState.dataTabTable?.data_jumlah_project?.total || 0) - (subhState.dataTabTable?.data_jumlah_project?.continue_request || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Project Running</span>
                                    <span className="d-block"><strong>{subhState.dataTabTable?.data_jumlah_project?.project_running || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Finished</span>
                                    <span className="d-block"><strong>{subhState.dataTabTable?.data_jumlah_project?.finished || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Aborted</span>
                                    <span className="d-block"><strong>{subhState.dataTabTable?.data_jumlah_project?.aborted || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Review</span>
                                    <span className="d-block"><strong>{subhState.dataTabTable?.data_jumlah_project?.review || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Need Revision</span>
                                    <span className="d-block"><strong>{subhState.dataTabTable?.data_jumlah_project?.need_revision || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Not Yet Reported</span>
                                    <span className="d-block"><strong>{subhState.dataTabTable?.data_jumlah_project?.project_continue || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom px-md-3">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                    <span className="d-block">$ <strong>{(subhState.dataTabTable?.data_jumlah_project?.total_plan_budget || 0)}/{(subhState.dataTabTable?.data_jumlah_project?.total_actual_budget || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 h-100 mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        ref={tableRef}
                        searchForm={true}
                        loading={subhState.loadingTable}
                        data={subhState.dataTabTable?.data_table}
                        columns={columnSubHol}
                        cellRender={(e) => cstmCellRender(e)}
                        withAction={false}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                        toolbarPosition="left"
                        toolbar={
                            <div className="input-group input-group-sm mb-3 ml-3" style={{ width: '350px' }}>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">Dukungan Operasional :</span>
                                </div>
                                <select className="form-control"
                                    value={subhState.subHoldingFilter}
                                    onChange={(e) => subhAction.subholdingFilterChange(e.target.value)}
                                >
                                    <option value={0}>-All-</option>
                                    {
                                        [...(subhState.dataTabTable?.data_sub_holdings || [])].map((d, i) => (
                                            <option value={d?.param_id} key={i}>{d?.title}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        }
                    >

                    </GridDx>
                </Card.Body>
            </Card>

        </div>
    );
}

export default TabTable;