import React, { Suspense, lazy, useMemo } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import {
  Layout,
  LayoutSplashScreen,
  ContentRoute
} from "../_metronic/layout";
import { FooterCS } from "./components";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";
import routes from "./routes/app-routes";
import { useGlobalState } from "./contexts/useGlobalState";
import useLocalStorage from "./contexts/useLocalStorage";
// import { useGlobalState } from "./contexts/useGlobalState";
// import AdminPage from "./AdminPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const AdminPage = lazy(() =>
  import("./AdminPage")
);

export default function BasePage() {
  // const [state, dispatch, action] = useGlobalState();
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const [curentUser] = useLocalStorage('current_user');
  const objCurrentUser = JSON.parse(curentUser);

  const customRedirect = () => {
    if (objCurrentUser?.role_id === '10') {
      return <Redirect exact from="/" to={"/Monitoring/sub-holdings"} />
    } else if (objCurrentUser?.role_id === '22') {
      return <Redirect exact from="/" to={"/lab/data-product"} />
    } else {
      return <Redirect exact from="/" to={"/home"} />
    }
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          customRedirect()
        }
        <Route path={routes.filter(d => d.noLayout).map(d => d.path)}>
          <IsAuthNonLayout>
            <Switch>
              {
                routes
                  .filter(v => v.noLayout)
                  .map((r, i) => <ContentRoute key={i} path={r.path} component={r.component} />)
              }
            </Switch>
          </IsAuthNonLayout>
        </Route>
        <Route path={routes.filter(d => !d.noLayout).map(d => d.path)}>
          <Layout>
            <Switch>
              {
                routes.filter(r => !r.noLayout).map(({ path, component }, i) => (
                  <ContentRoute
                    key={i}
                    path={path}
                    component={component}
                  />
                ))
              }
              <Route path="/google-material" component={GoogleMaterialPage} />
              <Route path="/react-bootstrap" component={ReactBootstrapPage} />
              <Route path="/e-commerce" component={ECommercePage} />
              <Route path="/user-profile" component={UserProfilepage} />
            </Switch>
            <FooterCS />
          </Layout>
        </Route>
        <Route path="/admin" component={AdminPage} />
        <Redirect to="/page/error" />
      </Switch>
    </Suspense>
  );
}

const IsAuthNonLayout = ({ children }) => {
  return (
    <div className="container">
      <div style={{
        'marginBottom': '50px'
      }}>
        {children}
        <FooterCS />
      </div>
    </div>
  )
}
