import React, { memo, useEffect } from 'react'
import { Card, Dropdown, DropdownButton } from 'react-bootstrap'
import { AbiIcon, CommercialIcon, OperationalIcon, ProductIcon, ResearchIcon, TechIcon } from '../../pages/myproject/icon';
import { color_workflow_stage, project_type_string } from '../utility/utility'
import { Link } from 'react-router-dom'
import { status_progress_shortName_3, status_budget_light_2, status_budget_light_string } from "../utility/utility";
import './header-project.css'
import { useLocation } from 'react-router-dom';
import { pathFile } from '../../app.config';

export default memo(function ({
    workflow_stage = '',
    dataHeader = {},
    dataReport = [],
    version_onChange = function () { },
    history_onClick = function () { },
    profile_onClick = function () { },
    monthly_onClick = function () { },
    rightMenu = false,
    view_admin = false
}) {
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))

    function iconProject(project_type) {
        let fill = '';
        let filla = '';
        let fillb = '';
        let fillc = '';
        let filld = '';
        let str = ''
        if (workflow_stage === "Project Ideation") {
            fill = '#1BC5BD';
            filla = '#1BC5BD';
            fillb = '#1BC5BD';
            fillc = '#1BC5BD';
            filld = '#1BC5BD';
            str = 'Ideation'
        }
        else if (workflow_stage === "Project Portfolio") {
            fill = '#3699ff';
            filla = '#3699ff';
            fillb = '#3699ff';
            fillc = '#3699ff';
            filld = '#3699ff';
            str = 'Portfolio'
        }
        else if (workflow_stage === "Project Monitoring") {
            fill = '#6f42c1';
            filla = '#6f42c1';
            fillb = '#6f42c1';
            fillc = '#6f42c1';
            filld = '#6f42c1';
            str = 'Monitoring'
        }
        else if (workflow_stage === "ABI Request") {
            fill = '#3699ff';
            filla = '#3699ff';
            fillb = '#3699ff';
            fillc = '#3699ff';
            filld = '#3699ff';
            str = 'ABI/ABO PP Request'
        }
        else if (workflow_stage === "ABI Monitoring") {
            fill = '#6f42c1';
            filla = '#6f42c1';
            fillb = '#6f42c1';
            fillc = '#6f42c1';
            filld = '#6f42c1';
            str = 'ABI/ABO PP Monitoring'
        }
        else {
            fill = '#0000';
            filla = '#0000';
            fillb = '#0000';
            fillc = '#0000';
            filld = '#0000';
            str = 'Finished'
        }

        if (project_type === 1) {
            return (
                <>
                    <div className="d-block"><ProductIcon fillb={fillb} filla={filla} /></div>
                    <span style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
                </>
            )
        } else if (project_type === 2) {
            return (
                <>
                    <div className="d-block"><TechIcon fill={fill} /></div>
                    <span style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
                </>
            )
        } else if (project_type === 3) {
            return (
                <>
                    <div className="d-block"><ResearchIcon fill={fill} /></div>
                    <span style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
                </>
            )
        } else if (project_type === 4) {
            return (
                <>
                    <div className="d-block"><CommercialIcon fill={fill} /></div>
                    <span style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
                </>
            )
        } else if (project_type === 5) {
            return (
                <>
                    <div className="d-block"><OperationalIcon fill={fill} /></div>
                    <span style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
                </>
            )
        } else if (project_type === 6 || project_type === 7) {
            return (
                <>
                    <div className="d-block"><AbiIcon fillb={fillb} fillc={fillc} stroke={fillb} /></div>
                    <span style={{ color: fill, fontSize: '8pt' }}>{dataHeader.tipe_proyek} - {str}</span>
                </>
            )
        }
    }

    return (
        <>
            <Card
                className="mx-0 mt-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <div className="row">
                        <div className="col-md-2 mb-5">
                            <div className={color_workflow_stage(workflow_stage) + " text-center p-5 rounded"} style={{ width: '120px' }}>
                                {iconProject(dataHeader?.project_type)}

                            </div>
                        </div>
                        <div className="col-md-8 mb-5">
                            <span className="d-block mb-5"><strong>{(dataHeader?.project_type?.toString() === "6" || dataHeader?.project_type?.toString() === "7") ? dataHeader?.name : dataHeader?.title}</strong></span>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <span className={color_workflow_stage(workflow_stage) + " py-2 pr-2 pl-1 rounded"} style={{ fontSize: '8pt' }}><strong>Status Project&nbsp;:&nbsp;</strong>{dataHeader?.workflow_state_state}</span>
                                    {
                                        (dataHeader?.project_type?.toString() !== "6" && dataHeader?.project_type?.toString() !== "7") && (
                                            <span className={color_workflow_stage(workflow_stage) + " py-2 pr-2 pl-1 rounded ml-5"} style={{ fontSize: '8pt' }}>Version {dataHeader?.baseline_name}</span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ fontSize: '8pt' }}>
                                <div className="col-md-4">
                                    <table className="custom-tr-padding">
                                        <tbody>
                                            {
                                                workflow_stage !== "ABI Request" && workflow_stage !== "ABI Monitoring" && (
                                                    <tr>
                                                        <td width='100px'><strong>No. Project</strong></td>
                                                        <td>:&nbsp;</td>
                                                        <td><span className="text-wrap">{dataHeader?.project_code}</span></td>
                                                    </tr>
                                                )
                                            }
                                            <tr>
                                                <td width='100px'><strong>Created</strong></td>
                                                <td>:&nbsp;</td>
                                                <td><span className="text-wrap">{dataHeader?.created_at_form}</span></td>
                                            </tr>
                                            <tr>
                                                <td width='100px'><strong>Last Update</strong></td>
                                                <td>:&nbsp;</td>
                                                <td><span className="text-wrap">{dataHeader?.updated_at_form}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4">
                                    <table className="custom-tr-padding">
                                        <tbody>

                                            {
                                                workflow_stage === "ABI Request" || workflow_stage === "ABI Monitoring" ? (
                                                    <>
                                                        <tr>
                                                            <td width='100px'><strong>Fungsi</strong></td>
                                                            <td>:&nbsp;</td>
                                                            <td><span className="text-wrap">{dataHeader?.creator_function_short_name}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td width='100px'><strong>Cut Off</strong></td>
                                                            <td>:&nbsp;</td>
                                                            <td><span className="text-wrap">{dataHeader?.cut_off_mtyr}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td width='100px'><strong>Status Budget</strong></td>
                                                            <td>:&nbsp;</td>
                                                            <td style={{height:'30px'}}><span>{status_budget_light_string(dataHeader?.status_budget)}</span></td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td width='100px'><strong>Area</strong></td>
                                                            <td>:&nbsp;</td>
                                                            <td><span className="text-wrap">{dataHeader?.area_name || '-'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td width='100px'><strong>Category</strong></td>
                                                            <td>:&nbsp;</td>
                                                            <td><span className="text-wrap">{dataHeader?.research_category_name || '-'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td width='100px'><strong>Fungsi</strong></td>
                                                            <td>:&nbsp;</td>
                                                            <td><span className="text-wrap">{dataHeader?.function_short}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td width='100px'><strong>Fungsi Sblmnya</strong></td>
                                                            <td>:&nbsp;</td>
                                                            <td><span className="text-wrap">{dataHeader?.focus}</span></td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4">
                                    {
                                        workflow_stage !== "ABI Request" && workflow_stage !== "ABI Monitoring" && (
                                            <table className="custom-tr-padding">
                                                <tbody>
                                                    <tr>
                                                        <td width='100px'><strong>Cut Off</strong></td>
                                                        <td>:&nbsp;</td>
                                                        <td><span className="text-wrap">{workflow_stage === "Project Monitoring" ? dataHeader?.cut_off : '-'}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td width='100px'><strong>Status Performance</strong></td>
                                                        <td>:&nbsp;</td>
                                                        <td style={{height:'30px'}}><span>{
                                                            workflow_stage === "Project Portfolio" ? <> - </> :
                                                                status_progress_shortName_3(dataHeader?.status_progress)
                                                        }</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td width='100px'><strong>Status Budget</strong></td>
                                                        <td>:&nbsp;</td>
                                                        <td style={{height:'30px'}}><span>{status_budget_light_2(dataHeader?.status_budget)}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 mb-5">
                            {
                                rightMenu && (
                                    <>
                                        <button
                                            className="btn btn-sm btn-light-warning mb-3 text-left"
                                            style={{ width: '100%' }}
                                            onClick={() => history_onClick({ id: dataHeader?.id, baseline_name: dataHeader?.baseline_name })}
                                        >
                                            <i className="fas fa-history pr-3"></i>
                                            History
                                        </button>
                                        {
                                            workflow_stage !== 'Project Portfolio' && (
                                                <button
                                                    className="btn btn-sm btn-light-primary mb-3 text-left"
                                                    style={{ width: '100%' }}
                                                    onClick={() => monthly_onClick({ id: dataHeader?.id, baseline_name: dataHeader?.baseline_name })}
                                                >
                                                    <i className="fas fa-clipboard-list pr-3">
                                                    </i>Monthly Report
                                                </button>
                                            )
                                        }
                                        {
                                            workflow_stage !== "ABI Monitoring" && (
                                                <button className="btn btn-sm btn-light-success mb-3 text-left" style={{ width: '100%' }}
                                                    onClick={() => profile_onClick({ id: dataHeader?.id, baseline_name: dataHeader?.baseline_name })}
                                                ><i className="fa fa-file-alt pr-3"></i>Project Profile</button>
                                            )
                                        }
                                        {
                                            (
                                                // dataHeader?.workflow_state_name === 'monitoring_aborted_request' ||
                                                // dataHeader?.workflow_state_name === 'monitoring_finished_request' ||
                                                // dataHeader?.workflow_state_name === 'monitoring_continue_request' ||
                                                // dataHeader?.workflow_state_name === 'monitoring_finished' ||
                                                // dataHeader?.workflow_state_name === 'monitoring_aborted' ||
                                                // dataHeader?.workflow_state_name === 'abi_monitoring_aborted_req' ||
                                                // dataHeader?.workflow_state_name === 'abi_monitoring_finished_req'
                                                [...(dataReport || [])].length > 0
                                            ) && (
                                                <Dropdown
                                                    className="mb-3"
                                                >
                                                    <Dropdown.Toggle className="btn btn-sm btn-light-primary d-flex justify-content-between custom-dropdown-icon" style={{ width: '100%' }} id="dropdown-basic">
                                                        <span style={{ float: 'left' }}><i className="fa fa-file"></i> Final Report</span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            dataReport.map((d, i) => (
                                                                <a className="dropdown-item" target="_blank" href={d.file_location}>
                                                                    Final Report&nbsp;{d.year}&nbsp;({i + 1})
                                                                </a>
                                                                // <span className="dropdown-item">Final Report&nbsp;{d.year}&nbsp;({i + 1})</span>
                                                                // <Link className="dropdown-item">Report {i + 1}</Link>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )

                                        }
                                    </>
                                )
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
});