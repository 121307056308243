import React, { useEffect, useState } from 'react';
import { SwitchBtn } from '../../../../../components';
import useUserAccessPage from './useUserAccessPage';
function ContentSpiirit({ dataSource = [], modul = 0 }) {
    const { acsAction, acsState } = useUserAccessPage();
    const [data, setData] = useState(dataSource);
    const [selAll, setSelAll] = useState(false);

    useEffect(() => {
        setData(dataSource);
    }, [dataSource])
    function selectAll(val) {
        setSelAll(val);
        const temp = [...(data || [])];
        const dataMaster = [...(acsState.dataUserAccessSpiirit?.data_table || [])];
        const indexMaster = [...(acsState.dataUserAccessSpiirit?.data_table || [])].findIndex(x => x.modul === modul);
        const mdata = {
            ...dataMaster[indexMaster]
        }
        dataMaster[indexMaster] = {
            ...mdata,
            data_child: temp.map(d => {
                return {
                    ...d,
                    access: val
                }
            })
        }
        acsAction.setDataUserAccessSpiirit({
            ...acsState.dataUserAccessSpiirit,
            data_table: dataMaster
        });
    }
    function changeData(d, val, index) {
        const temp = [...(data || [])];
        const dataMaster = [...(acsState.dataUserAccessSpiirit?.data_table || [])];
        const indexMaster = [...(acsState.dataUserAccessSpiirit?.data_table || [])].findIndex(x => x.modul === modul);
        const mdata = {
            ...dataMaster[indexMaster]
        }
        const newdata = {
            ...d,
            access: val
        }
        temp[index] = newdata
        dataMaster[indexMaster] = {
            ...mdata,
            data_child: temp
        }
        // console.log(temp, dataMaster, modul)
        acsAction.setDataUserAccessSpiirit({
            ...acsState.dataUserAccessSpiirit,
            data_table: dataMaster
        });
    }
    return (
        <div className='overflow-auto'>
            {/* <GridDx
                searchForm={false}
                cellRender={acsAction.renderGridContent}
                onEditorPreparing={acsAction.onEditorPreparing}
                loading={acsState.loadingAccessSpiirit}
                data={props?.activity_related_project}
                columns={[
                    { fieldName: "menu1_name", caption: "Menu 1" },
                    { fieldName: "menu2_name", caption: "Menu 2" },
                    { fieldName: "action_name", caption: "Action Name" },
                    { fieldName: "action_type", caption: "Type Action" },
                    { fieldName: "action_type", caption: "Type Action" },
                ]}
            >
                <Column dataField={'acces'} caption={'Access'} cellRender={acsAction.renderGridContent} setCellValue={acsAction.setBtnSwitch} >
                    
                </Column>
            </GridDx> */}
            <table className="table bg-white">
                <thead>
                    <tr>
                        <th scope="col">No</th>
                        <th scope="col">Menu 1</th>
                        <th scope="col">Menu 2</th>
                        <th scope="col">Action Name</th>
                        <th scope="col">Type Action</th>
                        <th scope="col"><SwitchBtn className='custom-sw' value={selAll} onChange={(val) => selectAll(val)} /> Select All</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        [...(data || [])].map((d, i) => (
                            <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{d?.menu1_name}</td>
                                <td>{d?.menu2_name}</td>
                                <td>{d?.action_name}</td>
                                <td>{d?.action_type}</td>
                                <td>
                                    <SwitchBtn className='custom-sw' value={d?.access} onChange={(val) => changeData(d, val, i)} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

        </div>
    );
}

export default ContentSpiirit;