import React from 'react';
import { HeaderList, Tab, TabContent } from '../../components';
import TabBroadcastEmail from './component/tab-boradcast-email';
import TabLogBroadcast from './component/tab-log-broadcast';
import useBroadcastEmail, { BeProvider } from './useBroadcastEmail';

function Index(props) {
    const { beState } = useBroadcastEmail();
    return (
        <div>
            <HeaderList
                title={beState.data_page?.page_title}
                someText={beState.data_page?.page_breadcrumbs}
            />
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "br",
                        title: "Broadcast Email",
                    },
                    {
                        id: "log",
                        title: "Log Broadcast Email",
                    },
                ]}
            >
                <TabContent id="br">
                    <TabBroadcastEmail />
                </TabContent>
                <TabContent id="log">
                    <TabLogBroadcast />
                </TabContent>
            </Tab>
        </div>
    );
}

export default () => <BeProvider><Index /></BeProvider>;