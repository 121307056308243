import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import { useHistory, useParams } from 'react-router-dom';
import {
    // FileUploader,
    TagBox,
    // Tooltip, 
    Autocomplete, NumberBox
} from 'devextreme-react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Tags from "@yaireo/tagify/dist/react.tagify"
import { FileUploadDx, HeaderList, RadioGrp } from '../../components';
import moment from 'moment';
import { toInteger } from 'lodash';

function EditPatent(props) {
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [judul, setJudul] = useState("");
    const [description, setDescription] = useState("");
    const [kategori, setKategori] = useState("");
    const [inventor, setInventor] = useState("");
    const [defInventor, setDefInventor] = useState([]);
    const [inventorExt, setInventorExt] = useState("");
    const [area, setArea] = useState("");
    const [status, setStatus] = useState("");
    const [tahun, setTahun] = useState("");
    const [cover, setCover] = useState("");
    const [file_location2, setFile_location2] = useState("");
    const [dataArea, setDataArea] = useState("");
    const [dataStatus, setDataStatus] = useState();
    const [dataKategori, setDataKategori] = useState();
    const [noPatent, setNoPatent] = useState();
    const [noPengajuan, setNopengajuan] = useState();
    const [pemegangPatent, setPemegangPatent] = useState([]);
    const [pemegangPatent_delete, setPemegangPatent_delete] = useState([]);
    const [pemegangPatent_delete_temp, setPemegangPatent_delete_temp] = useState([]);
    const [related_patent_researchs, setRelated_patent_researchs] = useState([]);
    const [related_patent_researchs_delete, setRelated_patent_researchs_delete] = useState([]);
    const [keyword, setKeyword] = useState([]);
    const [tempRkap, setTempRkap] = useState();
    const [tempRkapName, setTempRkapName] = useState();
    const [keyword_delete, setKeyword_delete] = useState([]);
    const [keyword_delete_temp, setKeyword_delete_temp] = useState([]);
    // const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [tglPnrma, setTglPnrma] = useState();
    const [tglPmbr, setTglPmbr] = useState();
    const param = useParams();
    const [document_patent, setDocument_patent] = useState([]);
    const [document_patent_delete, setDocument_patent_delete] = useState([]);

    const [pemilik, setPemilik] = useState('1');
    const [direcSources, setDirecSources] = useState([]);
    const [direc, setDirec] = useState(0);
    const [direcFSources, setDirecFSources] = useState([]);
    const [fungsi, setFungsi] = useState(0);
    function pemilikChange(val) {
        if (val === '1') {
            setDirec(0);
            setFungsi(0);
            setDirecFSources([]);
        }
        if (val === '2') {
            setArea(state.data_detail?.data_patent.area_id?.toString());
            getParamRelated([...(state.data_detail?.data_patent?.rkap_berkaitan || [])])
            setKeyword_delete([]);
            getDirektorat();
        }
        setPemilik(val)
    }
    function direcChange(val) {
        if (toInteger(val) !== 0) {
            getDirektoratVp(val);
        } else {
            setDirecFSources([]);
        }
        setDirec(val);
        setFungsi(0);
    }
    const getDirektoratVp = async (id) => {
        action.getData(`/api/people-intel-property/direktorat-svp/${id}`, {
        }, null, null, false).then((resp) => {
            if (resp.code === "00") {
                setDirecFSources(resp.data);
            }
        })
    }
    const getDirektorat = async () => {
        action.getData("/api/people-intel-property/direktorat", {
        }, null, null, false).then((resp) => {
            if (resp.code === "00") {
                setDirecSources(resp.data);
            }
        })
    }

    useEffect(() => {
        action.getData("/api/people-intel-property/table-dashboard?category_search=&status_search=&area_search=&year_search=&keyword_search=", {
        }).then((resp) => {
            if (resp.code === "00") {
                setDataArea(resp.data.data_Areas);
                setDataStatus(resp.data.data_PatentStatus);
                setDataKategori(resp.data.data_PatentCategories);
            }
        })
        action.getData(`/api/people-intel-property/view?id=${param.id}`, {}, "data_detail", null, true).then((resp) => {
            if (resp.code === "00") {
                getParamRelated(resp.data.data_patent?.rkap_berkaitan);
                setJudul(resp.data.data_patent?.name);
                setDescription(resp.data.data_patent?.description);
                setKategori(resp.data.data_patent?.patent_category_id);
                setTahun(resp.data.data_patent?.patent_year);
                const ownerArr = [...(resp.data.data_patent?.paten_owner_id || [])].map(d => d?.user_id);
                const pPaten = [...(resp.data.data_patent?.pemegang_patent || [])].map(d => { return { value: d?.nama, is_new: false } })
                const keyw = resp.data.data_patent?.tag ? [...((resp.data.data_patent?.tag || '').split(',') || [])].map(d => { return { value: d, is_new: false } }) : []
                setKeyword(keyw);
                setPemegangPatent(pPaten);
                setDefInventor(ownerArr);
                setInventor(ownerArr);
                setNoPatent(resp.data.data_patent?.no_paten);
                setNopengajuan(resp.data.data_patent?.no_pengajuan);
                setInventorExt(resp.data.data_patent.owner_ext);
                setArea(resp.data.data_patent.area_id?.toString());
                setStatus(resp.data.data_patent.patent_status_id?.toString());
                // setCover({
                //     name: resp.data.data_patent?.file_location,
                //     is_new: false
                // });
                // setFile_location2({
                //     name: resp.data.data_patent?.file2_location,
                //     is_new: false
                // })
                setDocument_patent((resp.data?.data_patent?.list_dokumen || []).map(d => ({ ...d, is_new: false })));
                setTglPnrma(resp.data.data_patent.tanggal_penerimaan ? moment(resp.data.data_patent.tanggal_penerimaan).format('YYYY-MM-DD') : '');
                setTglPmbr(resp.data.data_patent.tanggal_pemberian ? moment(resp.data.data_patent.tanggal_pemberian).format('YYYY-MM-DD') : '');
                pemilikChange(resp.data?.data_patent?.number?.toString() || '1');
                direcChange(resp.data?.data_patent?.owner1 || 0);
                setFungsi(resp.data?.data_patent?.owner2 || 0);
            }
        })
    }, [])
    function getParamRelated(rkap_berkaitan) {
        const defRel = [...(rkap_berkaitan || [])].map((d) => d?.related_id);
        action.getData("/api/param-related-research-monitoring", {
        }, 'source_related').then((resp) => {
            if (resp.code === '00') {
                const t = [...(resp.data || [])].filter(d => defRel.includes(d.related_id));
                setRelated_patent_researchs(t);
            }
        })
    }
    useEffect(() => {
        if (pemegangPatent_delete_temp) {
            if (pemegangPatent_delete_temp?.is_new === false) {
                const d = {
                    "nama": pemegangPatent_delete_temp?.value,
                    "is_new": pemegangPatent_delete_temp?.is_new
                }
                setPemegangPatent_delete([d, ...(pemegangPatent_delete || [])])
            }
        }
    }, [pemegangPatent_delete_temp]);

    useEffect(() => {
        if (keyword_delete_temp) {
            if (keyword_delete_temp?.is_new === false) {
                const d = {
                    "name": keyword_delete_temp?.value,
                    "tag_id": '',
                    "table_name": '',
                    "data_id": '',
                    "is_new": keyword_delete_temp?.is_new
                }
                setKeyword_delete([d, ...(keyword_delete || [])])
            }
        }
    }, [keyword_delete_temp]);

    // function fileOnchange(data) {
    //     // console.log(data)
    //     if (data.value?.[0]) {
    //         action.postData(
    //             {
    //                 url: "/api/base/upload-file-temp",
    //                 data: {
    //                     "File": data.value?.[0],
    //                 },
    //                 withFile: true,
    //             }
    //         ).then((resp) => {
    //             if (resp.code === "00") {
    //                 setCover({
    //                     "name": resp.data?.data_filename,
    //                     "is_new": true
    //                 })
    //             }
    //         })
    //     }
    // }
    // function fileUraian(res) {
    //     const resp = JSON.parse(res);
    //     if (resp?.code === '00') {
    //         setCover({
    //             "name": resp.data?.data_filename,
    //             "is_new": true
    //         })
    //     }
    // }
    // function fileOnchange2(data) {
    //     // console.log(data)
    //     if (data.value?.[0] && data.value?.[0].size <= 10000000) {
    //         action.postData(
    //             {
    //                 url: "/api/base/upload-file-temp",
    //                 data: {
    //                     "File": data.value?.[0],
    //                 },
    //                 withFile: true,
    //             }
    //         ).then((resp) => {
    //             if (resp.code === "00") {
    //                 setFile_location2({
    //                     "name": resp.data?.data_filename,
    //                     "is_new": true
    //                 })
    //             }
    //         })
    //     } else if (data.value?.[0] && data.value?.[0].size > 10000000) {
    //         return Swal.fire({
    //             title: 'Error',
    //             text: "File lebih dari 10Mb",
    //             icon: 'error',
    //         })
    //     }
    // }
    // function fileSertifikat(res) {
    //     const resp = JSON.parse(res);
    //     if (resp?.code === '00') {
    //         setFile_location2({
    //             "name": resp.data?.data_filename,
    //             "is_new": true
    //         })
    //     }
    // }
    function filepatent(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setDocument_patent([
                ...document_patent,
                {
                    "id": 0,
                    "size": 0,
                    "name": resp.data?.data_filename,
                    "patent_id": param?.id,
                    "file_location": resp.data?.data_file_location,
                    "is_new": true
                }
            ])
        }
    }
    function deletedDocument(file) {
        setDocument_patent(
            document_patent?.filter(d => d?.name !== file?.name)
        )
        if (file.is_new === false) {
            setDocument_patent_delete((e) => [...e, file]);
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageYear = '';
        if (data.name === '') {
            isValid = false;
            message = [...message, 'Judul'];
        }
        if (data.description === '') {
            isValid = false;
            message = [...message, 'Deskripsi'];
        }
        if (data.patent_category_id === '') {
            isValid = false;
            message = [...message, 'Kategori'];
        }
        if (data.patent_year === '') {
            isValid = false;
            message = [...message, 'Tahun'];
        }
        if (data.owner?.length === 0) {
            isValid = false;
            message = [...message, 'Inventor'];
        }
        if (data.pemegang_paten?.length === 0) {
            isValid = false;
            message = [...message, 'Pemegang Patent'];
        }
        if (data?.number === 1) {
            if (data.area_id === '') {
                isValid = false;
                message = [...message, 'Area'];
            }
        }
        if (data?.number === 2) {
            if (data.owner2 === 0) {
                isValid = false;
                message = [...message, 'Fungsi Direktorat'];
            }
        }
        if (data.patent_status_id === '') {
            isValid = false;
            message = [...message, 'Status'];
        }
        if ([...(data.owner || [])].length === 0) {
            isValid = false;
            message = [...message, 'Owner'];
        }
        // if (data?.patent_status_id === '1') {
        //     if (data?.no_pengajuan === '') {
        //         isValid = false;
        //         message = [...message, 'No. Pengajuan'];
        //     }
        // }
        // if (data?.patent_status_id === '2' || data?.patent_status_id === '3') {
        //     if (data?.tanggal_penerimaan === '') {
        //         isValid = false;
        //         message = [...message, 'Tanggal Penerimaan'];
        //     }
        // }
        // if ((data?.patent_status_id === '5' || data?.patent_status_id === '7' || data?.patent_status_id === '8')) {
        //     if (data?.no_paten === '') {
        //         isValid = false;
        //         message = [...message, 'No. Patent'];
        //     }
        //     if (data?.tanggal_pemberian === '') {
        //         isValid = false;
        //         message = [...message, 'Tanggal Pemberian'];
        //     }
        // }
        if (isValid && parseInt(data?.patent_year, 0) < 1950) {
            isValid = false;
            messageYear = 'Tahun minimal 1950';
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageYear ? ' dan ' : '') + messageYear
        }
    }
    function save() {
        const fixowner = [...(inventor || [])].map(d => {
            const ifAny = [...(defInventor || [])].find(x => x === d);
            return {
                user_id: d,
                is_new: ifAny ? false : true
            }
        })
        const deleteOwner = [...(defInventor || [])].filter(x => ![...(fixowner || [])].filter(d => d.is_new === false).find(y => y.user_id === x)).map(s => {
            return {
                user_id: s,
                is_new: false
            }
        })
        const data = {
            "id": param.id,
            "name": judul || '',
            "description": description || '',
            "patent_year": tahun || '',
            "file_location": cover,
            "file_size": "",
            "file2_location": file_location2,
            "file2_size": "",
            "creator": "",
            "owner": fixowner,
            "owner_delete": deleteOwner,
            "pemegang_paten": [...(typeof pemegangPatent === 'string' ? JSON.parse(pemegangPatent) : pemegangPatent)].map(d => {
                return {
                    nama: d?.value,
                    is_new: d?.is_new === false ? d?.is_new : true
                }
            }),
            "pemegang_paten_delete": [...(pemegangPatent_delete || [])],
            "owner1": direc,
            "owner2": fungsi,
            "owner3": "",
            "owner4": "",
            "owner5": "",
            "owner6": "",
            "owner_ext": (inventorExt || "").toString(),
            "area_id": area || '',
            "patent_category_id": kategori || '',
            "patent_status_id": status || '',
            "number": toInteger(pemilik),
            "created_at": "",
            "updated_at": "",
            "status_approval": "",
            "no_paten": noPatent || '',
            "no_pengajuan": noPengajuan || '',
            "keyword": [...(typeof keyword === 'string' ? JSON.parse(keyword) : keyword)].map(d => {
                return {
                    "name": d.value,
                    "tag_id": '',
                    "table_name": '',
                    "data_id": '',
                    "is_new": d?.is_new === false ? d?.is_new : true
                }
            }),
            "keyword_delete": [...(keyword_delete || [])],
            "related_patent_researchs": [...(related_patent_researchs || [])].map(d => {
                return {
                    related_id: d.related_id,
                    is_new: d.is_new

                }
            }),
            "related_patent_researchs_delete": [...(related_patent_researchs_delete || [])].map(d => {
                return {
                    related_id: d.related_id,
                    is_new: d.is_new
                }
            }),
            "tanggal_penerimaan": tglPnrma ? moment(tglPnrma).format('DD/MM/YYYY') : '',
            "tanggal_pemberian": tglPmbr ? moment(tglPmbr).format('DD/MM/YYYY') : '',
            "document_patent": document_patent || [],
            "document_patent_delete": document_patent_delete || []
        }
        const validate = validation(data)
        // console.log('data kirim', data, inventor, inventorExt)
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Intellectual Property? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/people-intel-property/update-detail",
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            history.push('/admin/people-patent/table')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function onValueChanged(e) {
        // console.log(e)
        // if ([...(e || [])].length > 6) {
        //     // const newValue = [...(e || [])].slice(0, 6);
        //     // e.component.option('value', newValue);
        //     setIsTooltipVisible(true);
        // } else {
        //     setInventor(e)
        // }
        setInventor(e)
    }
    // function onValueChangedExt(e) {
    //     setInventorExt(e.value)
    //     // console.log(e.value)
    // }
    // function deleteKeyword(data) {
    //     if (data) {
    //         if (data.is_new === false) {
    //             const newDs = [...(keyword_delete || [])]
    //             newDs.splice(newDs, 0, {
    //                 "name": data.value,
    //                 "tag_id": '',
    //                 "table_name": '',
    //                 "data_id": '',
    //                 "is_new": data.is_new
    //             })
    //             setKeyword_delete(newDs)
    //         }
    //     }
    // }
    // function deletePemegangPatent(data) {
    //     if (data) {
    //         if (data.is_new === false) {
    //             const newDs = [...(pemegangPatent_delete || [])]
    //             newDs.splice(newDs, 0, {
    //                 "name": data.value,
    //                 "is_new": data.is_new
    //             })
    //             setPemegangPatent_delete(newDs)
    //         }
    //     }
    // }
    function addRkap(rkap) {
        const d = {
            related_id: rkap.related_id,
            title: rkap.title,
            is_new: true
        }
        const p = related_patent_researchs || [];
        if (rkap && !p.includes(d)) {
            setRelated_patent_researchs([d, ...p]);
            setTempRkap('');
            setTempRkapName();
        }
    }
    function deleteRkap(rkap) {
        const p = related_patent_researchs || [];
        // console.log(rkap)
        if (p.includes(rkap)) {
            if (rkap?.is_new === false) {
                setRelated_patent_researchs_delete([rkap, ...[...(related_patent_researchs_delete || [])]])
            }
            setRelated_patent_researchs(p.filter(d => d?.related_id?.toString() !== rkap?.related_id?.toString()));
        }
    }
    function statusChange(val) {
        // setNopengajuan('');
        // setNoPatent('');
        // setTglPnrma('');
        // setTglPmbr('');
        setStatus(val)
    }
    return (
        <>
            <HeaderList
                title="Edit Intellectual Property"
                someText="People Management\Intellectual Property\Edit"
            />
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <h5>Detail</h5>
                            <hr />
                            <div className="row mb-3">
                                <div className="col-md-3">Judul</div>
                                <div className="col-md-9">
                                    <textarea rows={2} className="form-control form-control-sm" value={judul} onChange={(e) => setJudul(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Description</div>
                                <div className="col-md-9">
                                    <textarea rows={4} className="form-control form-control-sm" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Kategori</div>
                                <div className="col-md-9">
                                    <select className="form-control form-control-sm" value={kategori} onChange={(e) => setKategori(e.target.value)}>
                                        <option value="">- Pilih Kategori -</option>
                                        {
                                            [...(dataKategori || [])].map((d, i) => (
                                                <option key={i} value={d.id}>{d.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Tahun Patent</div>
                                <div className="col-md-9">
                                    <NumberBox placeholder='Tahun Patent' className="form-control form-control-sm p-0" value={tahun} onValueChanged={(e) => setTahun(e.value)}></NumberBox>
                                    {/* <input placeholder='Tahun Patent' className="form-control form-control-sm" value={tahun} onChange={(e) => setTahun(e.target.value)}></input> */}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Inventor</div>
                                <div className="col-md-9">
                                    <TagBox
                                        value={[...(inventor || [])]}
                                        searchEnabled={true}
                                        id="inventor"
                                        items={state.data_Users}
                                        displayExpr="name"
                                        displayValue="name"
                                        valueExpr="id"
                                        onValueChange={onValueChanged}
                                    //onValueChanged={onValueChanged}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    Inventor Ext.
                                </div>
                                <div className="col-md-9">
                                    <textarea
                                        value={inventorExt}
                                        className="form-control form-control-sm"
                                        rows={2}
                                        onChange={(e) => setInventorExt(e.target.value)}
                                    >

                                    </textarea>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    Pemegang Paten
                                </div>
                                <div className="col-md-9">
                                    <Tags
                                        onChange={(e) => setPemegangPatent(e.detail.value)}
                                        onRemove={(e) => setPemegangPatent_delete_temp(e.detail.data)}
                                        value={pemegangPatent}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    Keyword
                                </div>
                                <div className="col-md-9">
                                    <Tags
                                        onChange={(e) => setKeyword(e.detail.value)}
                                        onRemove={(e) => setKeyword_delete_temp(e.detail.data)}
                                        value={keyword}
                                    />
                                </div>
                            </div>
                            {/* <div className="row mb-3">
                                <div className="col-md-3">File Uraian Invensi</div>
                                <div className="col-md-9">
                                    <FileUploadDx
                                        className="custom-dx-fileuploader-file-name"
                                        showFileList={true}
                                        multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb"
                                        accept="*"
                                        onUploaded={fileUraian}
                                        maxFileSize={10000000}
                                        uploadMode="instantly"
                                    />
                                    <span>{cover?.name}</span>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">File Sertifikat Paten</div>
                                <div className="col-md-9">
                                    <FileUploadDx
                                        className="custom-dx-fileuploader-file-name"
                                        showFileList={true}
                                        multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb"
                                        accept="*"
                                        onUploaded={fileSertifikat}
                                        maxFileSize={10000000}
                                        uploadMode="instantly"
                                    />
                                    <span>{file_location2?.name}</span>
                                </div>
                            </div> */}
                            <div className="row mb-3">
                                <div className="col-md-3">File Patent</div>
                                <div className="col-md-9">
                                    <FileUploadDx
                                        className="custom-dx-fileuploader-file-name"
                                        showFileList={true}
                                        multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb"
                                        accept="*"
                                        onUploaded={filepatent}
                                        maxFileSize={10000000}
                                        uploadMode="instantly"
                                    />
                                    {
                                        document_patent?.map((file, key) => (
                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                <span style={{ fontSize: '8pt', width: '250px' }}>{file?.name}</span>
                                                <div>
                                                    <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deletedDocument(file)}></i>
                                                </div>
                                            </div>
                                        )
                                        )}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className='col-12'>
                                    <div className='p-3 rounded bg-light-primary'>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                Pemilik Kekayaan Intelektual
                                            </div>
                                            <div className="col-md-9">
                                                <div className="px-3">
                                                    <RadioGrp
                                                        // disable={true}
                                                        value={pemilik}
                                                        modeRow={true}
                                                        onChange={(val) => pemilikChange(val)}
                                                        data={
                                                            [
                                                                { label: 'RTI', value: '1' },
                                                                { label: 'Non RTI', value: '2' },
                                                            ]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            pemilik === '2' && (
                                                <>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            Direktorat
                                                        </div>
                                                        <div className="col-md-9">
                                                            <select className="form-control form-control-sm"
                                                                value={direc}
                                                                onChange={(e) => direcChange(e.target.value)}
                                                            >
                                                                <option value={0}>- Pilih Direktorat -</option>
                                                                {
                                                                    [...(direcSources || [])].map((d, i) => (
                                                                        <option key={i} value={d.id}>{d.nama_short}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            Fungsi
                                                        </div>
                                                        <div className="col-md-9">
                                                            <select className="form-control form-control-sm"
                                                                value={fungsi}
                                                                onChange={(e) => setFungsi(e.target.value)}
                                                            >
                                                                <option value={0}>- Pilih Fungsi -</option>
                                                                {
                                                                    [...(direcFSources || [])].map((d, i) => (
                                                                        <option key={i} value={d.id}>{d.nama}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            Area
                                                        </div>
                                                        <div className="col-md-9">
                                                            <span className='d-block px-5 py-5 bg-secondary rounded'></span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            RKAP Berkaitan
                                                        </div>
                                                        <div className="col-md-9">
                                                            <span className='d-block px-5 py-5 bg-secondary rounded'></span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            pemilik === '1' && (
                                                <>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            Direktorat
                                                        </div>
                                                        <div className="col-md-9">
                                                            <span className='d-block px-5 py-3 bg-secondary rounded'>Direktorat Strategi, Portfolio dan Pengembangan Usaha</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            Fungsi
                                                        </div>
                                                        <div className="col-md-9">
                                                            <span className='d-block px-5 py-3 bg-secondary rounded'>SVP Research & Technology Innovation</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            Area
                                                        </div>
                                                        <div className="col-md-9">
                                                            <select className="form-control form-control-sm" value={area} onChange={(e) => setArea(e.target.value)}>
                                                                <option value="">- Pilih Area -</option>
                                                                {
                                                                    [...(dataArea || [])].map((d, i) => (
                                                                        <option key={i} value={d.id}>{d.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            RKAP Berkaitan
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="input-group mb-2">
                                                                <Autocomplete dataSource={state.source_related}
                                                                    value={tempRkapName}
                                                                    placeholder="Pilih dan tekan tombol '+ Add'"
                                                                    valueExpr="title"
                                                                    displayExpr="title"
                                                                    onValueChanged={(e) => setTempRkapName(e.value)}
                                                                    onSelectionChanged={(e) => setTempRkap(e.selectedItem)}
                                                                    className="form-control form-control-sm p-0 mr-1">
                                                                </Autocomplete>
                                                                <div className="text-right" style={{ width: '65px' }}>
                                                                    <button type="submit" className="btn btn-sm btn-outline-primary" onClick={() => addRkap(tempRkap)}>+ Add</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                related_patent_researchs.map((d, i) => (
                                                                    <div className="row mb-2" key={i}>
                                                                        <div className="col-md-10">
                                                                            <span className="d-block text-left mb-3">
                                                                                <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                                                                {d.title}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <button className="btn btn-sm btn-outline-danger" onClick={() => deleteRkap(d)}>
                                                                                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className='col-12'>
                                    <div className='p-3 rounded bg-light-primary'>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                Status
                                            </div>
                                            <div className="col-md-9">
                                                <select className="form-control form-control-sm" value={status} onChange={(e) => statusChange(e.target.value)}
                                                >
                                                    <option value="">- Pilih Status - </option>
                                                    {
                                                        [...(dataStatus || [])].map((d, i) => (
                                                            <option key={i} value={d.id}>{d.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                No. Pengajuan
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    // disabled={!(status === '1' || status === '3')}
                                                    className={`form-control form-control-sm ${(status === '1' || status === '3') ? "" : ""}`} value={noPengajuan} onChange={(e) => setNopengajuan(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                Tanggal Penerimaan
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    // disabled={!(status === '2')}
                                                    type='date' className={`form-control form-control-sm ${(status === '2') ? "" : ""}`} value={tglPnrma} onChange={(e) => setTglPnrma(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                Tanggal Pemberian Paten
                                            </div>
                                            <div className="col-md-9">
                                                <input type='date'
                                                    // disabled={!(status === '5' || status === '7' || status === '8')}
                                                    className={`form-control form-control-sm ${(status === '5' || status === '7' || status === '8') ? "" : ""}`} value={tglPmbr} onChange={(e) => setTglPmbr(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                No. Paten
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    // disabled={!(status === '5' || status === '7' || status === '8')}
                                                    className={`form-control form-control-sm ${(status === '5' || status === '7' || status === '8') ? "" : ""}`} value={noPatent} onChange={(e) => setNoPatent(e.target.value)}></input>
                                            </div>
                                        </div>
                                        {/* {
                                            status === '1' && (
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        No. Pengajuan
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input className="form-control form-control-sm" value={noPengajuan} onChange={(e) => setNopengajuan(e.target.value)}></input>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (status === '2' || status === '3') && (
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        Tanggal Penerimaan
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input type='date' className="form-control form-control-sm" value={tglPnrma} onChange={(e) => setTglPnrma(e.target.value)}></input>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (status === '5' || status === '7' || status === '8') && (
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        Tanggal Pemberian
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input type='date' className="form-control form-control-sm" value={tglPmbr} onChange={(e) => setTglPmbr(e.target.value)}></input>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (status === '5' || status === '7' || status === '8') && (
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        No. Paten
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input className="form-control form-control-sm" value={noPatent} onChange={(e) => setNoPatent(e.target.value)}></input>
                                                    </div>
                                                </div>
                                            )
                                        } */}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="d-block text-right">
                                <button className="btn btn-sm btn-secondary mx-1"
                                    onClick={() => history.goBack()}
                                >
                                    Batal
                                </button>
                                <button className="btn btn-sm btn-primary"
                                    onClick={() => save()}
                                >
                                    <i className="fa fa-save icon-nm mx-1 text-white"></i>
                                    Submit
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default EditPatent;