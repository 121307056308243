import React from 'react';
import { GridRisk } from '../../components/index'
import { kuantitatif, probabilitas, dampak } from '../../components/utility/utility';
import ModalViewEdit from './modalViewEdit'
// import { useGlobalState } from '../../contexts/useGlobalState';
const column = [
    { "multiHeader": false, "dataField": "risk_id", "caption": "ID Risk", width: 100 },
    { "multiHeader": false, "dataField": "tipe_risk", "caption": "Kuantitatif/Kualitatif Risk", width: 100 },
    { "multiHeader": false, "dataField": "kejadian", "caption": "Kejadian Resiko (Risk Event)", width: 200 },
    { "multiHeader": false, "dataField": "penyebab", "caption": "Penyebab Resiko (Risk Agent)", width: 300 },
    {
        "multiHeader": true, "caption": "Inherent", "column": [
            { "dataField": "inher_probabil", "caption": "Probabilitas", width: 100 },
            { "dataField": "inher_dampak", "caption": "Dampak", width: 100 },
            { "dataField": "risk_priority", "caption": "Risk Priority", width: 100 },
        ]
    },
    {
        "multiHeader": true, "caption": "Mitigasi", "column": [
            { "dataField": "mit_rencana", "caption": "Rencana Mitigasi", width: 300 },
            { "dataField": "mit_tahun_berlaku", "caption": "Tahun Berlaku", width: 100 },
            { "dataField": "mit_tahun_selesai", "caption": "Tahun Selesai", width: 100 },
        ]
    },
    {
        "multiHeader": true, "caption": "Residual", "column": [
            { "dataField": "risk_priority_residu", "caption": "Risk Priority Number", width: 100 }
        ]
    }
]
const dataSource = [
    // {
    //     "id": 237,
    //     "research_id": 588,
    //     "baseline_name": 1,
    //     "risk_id": 1,
    //     "tipe_risk": "Kualitatif",
    //     "kejadian": "Progress pelaksanaan riset tidak mencapai target yang ditetapkan",
    //     "penyebab": "Adanya kondisi major force (misal. pandemi global, bencana alam, dll) yang membuat pelaksanaan penelitian menjadi lambat (stagnant)",
    //     "gejala": "1. Progress penelitian berjalan lamban (stagnant) 2. Terjadinya pergeseran rencana kerja yang telah disusun 3. Anggaran yang tersedia kurang dari rencana awal 4. Dukungan manajemen internal dan stakeholder terhadap pelaksanaan proyek riset berkurang",
    //     "faktor_positif": "1. Adanya sistim monitoring progress riset di RTC (SPIIRIT) yang dapat mengakomodasi kebutuhan resetting target riset 2. Adanya dukungan managemen terhadap pelaksanaan task force 3. Ada kesempatan untuk pengajuan penghentian kegiatan riset berdasarkan justifikasi yang cukup ke manajemen 4. Kebijakan/SOP dari perusahaan terkait force major sudah tersedia",
    //     "dampak_kual": "1. Berpotensi terjadinya delay terhadap kontrak kerjasama yang telah disusun 2. Berpotensi untuk tidak tercapainya KPI VP DRT\r\n",
    //     "inher_kuant": null,
    //     "inher_probabil": 1,
    //     "inher_dampak": 2,
    //     "mit_rencana": "1. Penyusunan timeline proyek riset secara detail dengan memperhitungkan kemungkinan adanya force major 2. Penyusunan anggaran proyek riset dengan memperhitungkan kemungkinan adanya perubahan anggaran",
    //     "mit_biaya": 0,
    //     "mit_tahun_berlaku": 2021,
    //     "mit_tahun_selesai": 2021,
    //     "res_kuant": null,
    //     "res_probabil": 1,
    //     "res_dampak": 2
    // }
]
const TabRisk = () => {
    // const [state, dispatch, action] = useGlobalState();;
    return (
        <>
            <GridRisk
                dataSource={dataSource}
                collumns={column}
                dataSourceProb={probabilitas}
                dataSourceDam={dampak}
                dataSourceKuant={kuantitatif}
                bodyForm={(disable, onFinish) => <ModalViewEdit disable={disable} onFinish={onFinish} />}
            />
        </>
    )
}
export default TabRisk;