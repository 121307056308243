import React, { useEffect, useState } from 'react';
import { HeaderList } from '../../components';
import Card from 'react-bootstrap/Card'
import { Link, useHistory } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';

function AllInventor(props) {
    const history = useHistory()
    const [state, , action] = useGlobalState();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData("/api/people-intel-property/table-top-inventor", {
        }, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setData_page(resp.data_page);
            }
        })
    }, [action])

    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            />
            <Card
                className="mx-0 mt-0 p-0 customShadow"
                style={{marginBottom:'40px'}}
            >
                <Card.Body className="p-5">
                    <h5>Top Inventor</h5>
                    <hr />
                    <div className="row" >
                        {
                            [...(state.data_Owners || [])].map((d, i) => (
                                <div className="col-lg-2 col-md-2 col-6 mb-5" style={{ textAlign: "center" }} key={i}>
                                    <div className='overlay'>
                                        <div className="overlay-wrapper">
                                            <img style={{ height: 150, borderRadius: '3%' }} className="bg-secondary mb-3"
                                                src={d.photo_location}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                        </div>
                                        <div className="overlay-layer">
                                            <Link to={`/inventor-detail/${d?.owner_id}`}>
                                                <button className="btn btn-sm btn-primary"><i className="fa fa-search"></i>
                                                    Lihat Detail
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <br />
                                    <h5>{d?.owner_name}</h5>
                                    {/* <p>{d?.role_name}</p> */}
                                    <p>
                                        <span className="badge badge-primary badge-wide">
                                            {d?.jumlah} Patents
                                        </span>
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AllInventor;