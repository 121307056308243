import React, { useEffect } from 'react';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import HeaderList from '../../../components/header-list/header-list';
import { useGlobalState } from '../../../contexts/useGlobalState';
import useCrudEquipment, { CrudEquipmentProvider } from '../useCrudEquipment';
import FormAddEdit from './component/formAddEdit';

function AddPage(props) {
    const { ceqAction } = useCrudEquipment();
    const [state] = useGlobalState();
    const [validate, setValidate] = useState(false);
    useEffect(() => {
        if (state.dataUser) {
            if (validationRole()) {
                ceqAction.resetEditorEquipment();
                ceqAction.getEdit(0);
            } else {
                Swal.fire({
                    title: 'Warning',
                    text: "Anda tidak memiliki access !",
                    icon: 'warning',
                    allowOutsideClick: false
                }).then(() => window.location.href = '/')
            }
        }
    }, [state.dataUser]);

    function validationRole() {
        const data_role = state.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Admin Equipment" ||
                d === "Administrator") {
                result = true;
                setValidate(true);
            }
        });
        return result;
    }
    const renderContent = () => {
        if (validate) {
            return (
                <div className='container'>
                    <div style={{ paddingBottom: '20px' }}>
                        <HeaderList
                            title={"Equipment MS"}
                            someText={"Add Equipment"}
                        />
                        <Card
                            style={{ marginBottom: '50px' }}
                            className="mx-0 mt-0 p-1 customShadow"
                        >
                            <Card.Header className='text-center'><h3>Form Add Equipment</h3></Card.Header>
                            <Card.Body className="p-5">
                                <FormAddEdit />
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="p-2 bg-white rounded shadow-lg floating-footer-nolayout" >
                        <div className="row">
                            <div className="col-md-12 text md-right text-lg-right">
                                <button className="btn btn-sm btn-primary mx-1"
                                    onClick={() => ceqAction.simpanClickEquipment()}
                                >
                                    <i className="fa fa-save icon-nm"></i>
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    return (
        <>
            {renderContent()}
        </>
    );
}

export default (props) => <CrudEquipmentProvider><AddPage {...props} /></CrudEquipmentProvider>;