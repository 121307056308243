import React, { useState } from 'react';
import ImageCover from '../images/bg-land-detail.svg';
import { Container, Modal } from 'react-bootstrap';
import useIndexLandPage from '../use_index';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CarouselSpiirit } from '../../../components';

function LaboratoryDetail(props) {
    const { indState, indAction } = useIndexLandPage();
    const [showMod, setShowMod] = useState({
        show: false,
        url: ""
    });
    const history = useHistory();
    const { state } = useLocation();
    const [photo_pendukung, setPhoto_pendukung] = useState({
        photo_location_1_name: '',
        photo_location_2_name: '',
        photo_location_3_name: ''
    })
    useEffect(() => {
        indAction.setPageState({
            title: "Laboratory Detail",
            type: "ProductLandPageDetail",
            dataPage: {
                ...state
            }
        })
        const detail = [...(state?.all_data || [])][state?.selected_index || 0]
        setPhoto_pendukung({
            photo_location_1_name: detail?.photo_location_1_name,
            photo_location_2_name: detail?.photo_location_2_name,
            photo_location_3_name: detail?.photo_location_3_name,
        })
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, []);

    const handleDetailChange = (index) => {
        const detail = [...(state?.all_data || [])][index]
        setPhoto_pendukung({
            photo_location_1_name: detail?.photo_location_1_name,
            photo_location_2_name: detail?.photo_location_2_name,
            photo_location_3_name: detail?.photo_location_3_name,
        })
    }
    return (
        <div className='mt-5' style={{ 'paddingTop': '35px' }}>
            <Modal size='xl' show={showMod.show} onHide={() => setShowMod({ show: false, url: '' })}>
                <div className="customShadow">
                    <img className="w-100" src={showMod.url}></img>
                </div>
            </Modal>
            <img src={ImageCover} style={{ 'position': 'absolute', width: '100%', height: '220px', top: '10px', objectFit: 'cover' }}></img>
            <Container>
                <div className='row justify-content-center text-center mb-5 text-white'>
                    <div className='col-lg-6 col-md-6'>
                        <h5 className='fw-bold'>Detail Produk Laboratory RTI</h5>
                    </div>
                </div>
                <CarouselSpiirit
                    autoPlay={false}
                    showItem={1}
                    withButton={true}
                    showIndicators={false}
                    modeNextPrev={2}
                    infiniteLoop={false}
                    onIndexChange={handleDetailChange}
                    defaultCurrentIndex={state?.selected_index || 0}
                >
                    {
                        [...(indState.pageState?.dataPage?.all_data || [])].map((d, i) => (
                            <div key={i}>
                                <div className='row mb-2'>
                                    <div className='col-md-12 mb-5'>
                                        <img src={d?.photo_location_name}
                                            style={{ maxHeight: '500px', width: '100%' }}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </CarouselSpiirit>
                <div className='row mb-2'>
                    {
                        photo_pendukung?.photo_location_1_name && (
                            <div className='col-md-4 col-lg-4 mb-3'>
                                <div className="card overlay border-none">
                                    <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                        <div className="overlay-wrapper">
                                            <img style={{ width: '100%', height: 250, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                                src={photo_pendukung?.photo_location_1_name}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                        </div>
                                        <div class="overlay-layer">
                                            <button className="btn btn-sm btn-light mx-1"
                                                onClick={() => setShowMod({ show: true, url: photo_pendukung?.photo_location_1_name })}
                                            ><i className="fa fa-search"></i>
                                                Lihat Detail
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        photo_pendukung?.photo_location_2_name && (
                            <div className='col-md-4 col-lg-4 mb-3'>
                                <div className="card overlay border-none">
                                    <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                        <div className="overlay-wrapper">
                                            <img style={{ width: '100%', height: 250, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                                src={photo_pendukung?.photo_location_2_name}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                        </div>
                                        <div class="overlay-layer">
                                            <button className="btn btn-sm btn-light mx-1"
                                                onClick={() => setShowMod({ show: true, url: photo_pendukung?.photo_location_2_name })}
                                            ><i className="fa fa-search"></i>
                                                Lihat Detail
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        photo_pendukung?.photo_location_3_name && (
                            <div className='col-md-4 col-lg-4 mb-3'>
                                <div className="card overlay border-none">
                                    <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                        <div className="overlay-wrapper">
                                            <img style={{ width: '100%', height: 250, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                                src={photo_pendukung?.photo_location_3_name}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                        </div>
                                        <div class="overlay-layer">
                                            <button className="btn btn-sm btn-light mx-1"
                                                onClick={() => setShowMod({ show: true, url: photo_pendukung?.photo_location_3_name })}
                                            ><i className="fa fa-search"></i>
                                                Lihat Detail
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='d-inline-flex bg-transparent py-3 position-sticky my-3' style={{ bottom: '10px' }}>
                    <button className='btn btn-sm btn-secondary'
                        onClick={() => history.push('/landing-page/laboratory')}
                    >
                        <i className='fas fa-chevron-circle-left icon-nm'></i>
                        Kembali
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default () => <LaboratoryDetail />;