import React from 'react';
import './stacked_card.css';
function StackedCard({
    title = '',
    description = '',
    cardColor = '',
    baseColor = '',
    buttonAction = <></>,
    withIcon = false,
    styleIcon='',
    icon = "",
    children
}) {

    return (
        <div className={`${baseColor} base-card`}>
            <div className={`container-fluid stacked-card stacked-card-padding ${cardColor}`}>
                <div className='container'>
                    <div className='row mb-5'>
                        <div className='col-lg-8 col-md-8 col'>
                            <h5 className='font-weight-boldest'>{title}</h5>
                            <span className='text-wrap'>
                                {description}
                            </span>
                        </div>
                        <div className='col-lg-4 col-md-4 col-auto text-right'>
                            {
                                withIcon &&
                                <img src={icon}className={`icon-card ${styleIcon}`} />
                            }
                            {
                                buttonAction
                            }
                        </div>
                    </div>
                    {
                        children
                    }
                </div>
            </div>
        </div>
    );
}

export default StackedCard;