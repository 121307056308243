import React, { memo, useEffect, useState, useRef } from 'react';
import { HeaderList, Modal, CardBerita, PopularCard } from '../../components';
import TabTable from './tabTable';
import TabDashboard from './tabDashboard';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';
import { blue } from '@material-ui/core/colors';
import Card from 'react-bootstrap/Card'
import {
    Button,
} from "react-bootstrap";
import {
    DatePickerField,
} from "../../../_metronic/_partials/controls";
import moment from 'moment';
import { pathFile } from '../../app.config';
import { isValidHttpUrl } from '../../components/utility/utility';

export default memo(function () {
    const { pathname, param } = useLocation();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [search_text, setSearch_text] = useState('');
    const refDate = useRef();
    const refDateEnd = useRef();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        getData(search_text);
    }, [startDate, endDate])

    function getData(keyword = '') {
        action.getData("/api/people-event/table-search", {
            date_start: startDate,
            date_end: endDate,
            keyword_search: keyword
        }, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setData_page(resp.data_page);
            }
        })
    }

    function onEnterx(e) {
        if (e?.key === 'Enter') {
            getData(search_text);
        }
    }
    return (
        <div>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            >
            </HeaderList>
            <Card
                style={{ backgroundColor: '#C4E0FF' }}
                className="m-0 p-0  mb-5 customShadow header_event_display"
            >
                <Card.Body className="p-5">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-2 col-md-2 col-6 mb-3">
                            <input ref={refDate} type="text" onFocus={(e) => refDate.current.type = 'date'} onBlur={() => refDate.current.type = 'text'} placeholder='Event Date Start' className="form-control form-control-sm"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-3">
                            <input ref={refDateEnd} type="text" onFocus={(e) => refDateEnd.current.type = 'date'} onBlur={() => refDateEnd.current.type = 'text'} placeholder='Event Date End' className="form-control form-control-sm"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 mb-3">
                            <div className="formSearch">
                                <input className="form-control form-control-sm" placeholder="Cari Event di SPIIRIT"
                                    value={search_text}
                                    onChange={(e) => setSearch_text(e.target.value)}
                                    onKeyUp={(e) => onEnterx(e)}
                                />
                                <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className="col" style={{ marginTop: "20px" }}>
                <h4>Event akan datang</h4>
            </div>
            <div class="row mb-5">
                {
                    [...(state.data_event_list || [])].filter(d => d.status_event === 'Upcoming').map((d, i) => (
                        <div className="col-lg-3 col-md-3 col-12 mb-5">
                            <Card
                                className="m-0 p-0  mb-5 customShadow h-100"
                            >
                                <Card.Body className="p-5">
                                    <div className="card card-custom overlay my-5">
                                        <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                            <div className="overlay-wrapper">
                                                <img style={{ width: '100%', height: 160, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border" src={d.poster_location}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }}
                                                />
                                            </div>
                                            <div class="overlay-layer">
                                                <Link to={`/people/event-detail/${d.id}`}>
                                                    <button className="btn btn-sm btn-primary"><i className="fa fa-search"></i>
                                                        Lihat Detail
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: '8' }}>
                                        {d.organizer}
                                    </p>
                                    <p>
                                        <span className="text-wrap"><b>{d.name}</b></span>
                                    </p>

                                    <p style={{ fontSize: '8' }}>
                                        <i className="flaticon-clock-1 mr-2"></i> {d.time_start} - {d.time_end}
                                    </p>
                                    <p style={{ fontSize: '8' }}>
                                        <i className="flaticon-calendar-with-a-clock-time-tools mr-2"></i> {d.date_start ? moment(d.date_start).format("YYYY-MM-DD") : '-'}
                                    </p>
                                    <p style={{ fontSize: '8' }}>

                                        <i className="flaticon-placeholder-3 mr-2"></i>  {d.venue.substr(0, 40)}
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }

            </div>

            <div className="col" style={{ marginTop: "20px" }}>
                <h4>Event berlalu</h4>
            </div>
            <div class="row mb-5">
                {
                    [...(state.data_event_list || [])].filter(d => d.status_event === 'Previous').map((d, i) => (
                        <div className="col-lg-3 col-md-3 col-12 mb-5">
                            <Card
                                className="m-0 p-0  mb-5 customShadow h-100"
                            >
                                <Card.Body className="p-5">
                                    <div className="card card-custom overlay my-5">
                                        <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                            <div className="overlay-wrapper">
                                                <img style={{ width: '100%', minHeight: 150, maxHeight: 150, objectFit: 'scale-down', borderRadius: '3%' }} className="bg-secondary" src={d.poster_location}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }}
                                                />
                                            </div>
                                            <div class="overlay-layer">
                                                <Link to={`/people/event-detail/${d.id}`} target="_blank">
                                                    <button className="btn btn-sm btn-primary"><i className="fa fa-search"></i>
                                                        Lihat Detail
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: '8' }}>
                                        {d.organizer}
                                    </p>
                                    <p>
                                        <span style={{ fontSize: '10pt' }} className="text-wrap"><b>{d.name}</b></span>
                                    </p>

                                    <p style={{ fontSize: '8' }}>
                                        <i className="flaticon-clock-1 mr-2"></i> {d.time_start} - {d.time_end}
                                    </p>
                                    <p style={{ fontSize: '8' }}>
                                        <i className="flaticon-calendar-with-a-clock-time-tools mr-2"></i> {d.date_start ? moment(d.date_start).format("YYYY-MM-DD") : '-'}
                                    </p>
                                    <p style={{ fontSize: '8' }}>
                                        {
                                            isValidHttpUrl(d?.venue) ? (
                                                <a href={d?.venue} target={'_blank'}>
                                                    <span><strong>{d.venue.substr(0, 40)}</strong></span>
                                                </a>
                                            ) : (
                                                <span className='text-wrap'>{d.venue.substr(0, 40)}</span>
                                            )
                                        }
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }

            </div>
        </div>

    )
})