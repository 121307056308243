import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "../../modules/useFetch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { perform_work, status_progress_shortName } from "../../components/utility/utility";

const mppsContext = createContext({
    mppsAction: {},
    mppsState: {}
});

const month = [{ name: 'JAN', value: '1' }, { name: 'FEB', value: '2' }, { name: 'MAR', value: '3' }, { name: 'APR', value: '4' },
{ name: 'MAY', value: '5' }, { name: 'JUN', value: '6' }, { name: 'JUL', value: '7' }, { name: 'AUG', value: '8' }, { name: 'SEP', value: '9' },
{ name: 'OCT', value: '10' }, { name: 'NOV', value: '11' }, { name: 'DEC', value: '12' }]

export function MppsProvider({ children }) {
    const { doGet } = useFetch();
    const [selTab, setSelTab] = useState('dashboard');
    const [data_page, setData_page] = useState({
        "page_title": "",
        "page_breadcrumbs": ""
    });
    const [filter, setFilter] = useState({
        tahun: '',
        bulanGet: ''
    });
    const filterChange = (name, val) => {
        if (selTab === 'dashboard') {
            GetDashboard(name === 'tahun' ? val : filter.tahun, name === 'bulan' ? val : filter.bulanGet, active, true);
        } else {
            GetTable(name === 'tahun' ? val : filter.tahun, name === 'bulan' ? val : filter.bulanGet);
        }
    };
    const handleSelTabChange = (val) => {
        setSelTab(val);
    }
    const [year_source, setYear_source] = useState([]);

    const [helperPerformance, setHelperPerformance] = useState({
        early: 0,
        ontime: 0,
        delayed: 0,
        finished: 0,
        stopped: 0,
        actual_budget: 0,
        plan_budget: 0,
        perform_budget: 0,
        text: ''
    });
    const [helperBudget, setHelperBudget] = useState({
        under: 0,
        within: 0,
        over: 0,
        stopped: 0,
        actual_budget: 0,
        plan_tot: 0,
        perform_budget: 0
    })
    const [active, setActive] = useState(0);
    const [data_dash, setData_dash] = useState({});
    const [loading_dash, setloading_dash] = useState(false);
    const [loadingActive, setLoadingActive] = useState(false);
    const GetDashboard = async function (tahun, bulanGet, idFunction, loadingAll = false) {
        setloading_dash(true)
        loadingAll && setLoadingActive(true);
        loadingAll && Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        try {
            const resp = await doGet({
                url: `api/project-ongoing/dashboard-project-unggulan-sh`,
                param: {
                    tahun,
                    bulanGet,
                    idFunction
                }
            });
            if (resp?.code === '00') {
                setData_dash({
                    ...resp.data,
                    data_HeaderMonitoring: GenerateDashboardHeader(resp.data?.data_HeaderMonitoring),
                    dsCurve1: dsCurve1(resp.data?.data_SCurve1, resp.data?.data_bulan_default),
                    dsCurve2: dsCurve2(resp.data?.data_SCurve2, resp.data?.data_bulan_default),
                    dsChartSpend: generateChartSpend(resp.data?.data_SpendYearToDate2, resp.data?.data_SpendYearToDate)
                });
                setData_page(resp.data_page);
                setYear_source((resp.data?.data_year_portfolio || []).map(d => ({ value: d?.idYear, name: d?.idYear })));
                setFilter({
                    tahun: resp.data?.data_tahun_default,
                    bulanGet: resp.data?.data_bulan_default
                });
                getperformance(resp.data?.data_WorkRealizationTotal, resp.data?.data_SpendYearToDate2);
                getBudget(resp.data?.data_BudgetRealizationTotal, resp.data?.data_SpendYearToDate);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        loadingAll && Swal.close();
        setLoadingActive(false);
        setloading_dash(false)
    }

    const [data_table, setData_table] = useState({});
    const [loading_table, setloading_table] = useState(false);
    const GetTable = async function (tahun, bulan) {
        setloading_table(true);
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        try {
            const resp = await doGet({
                url: `api/project-ongoing/table-project-unggulan-sh`,
                param: {
                    ...filter,
                }
            });
            if (resp?.code === '00') {
                setData_table(resp.data);
                setData_page(resp.data_page);
                setYear_source((resp.data?.data_allyear || []).map(d => ({ value: d?.idYear, name: d?.idYear })));
                setFilter({
                    tahun: resp.data?.data_tahunGet,
                    bulanGet: resp.data?.data_bulanNow
                });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setloading_table(false);
    }

    const GenerateDashboardHeader = (data = []) => {
        var result = {
            "function_id": 0,
            "area_id": 0,
            "name": "RTI",
            "short_name": "RTI",
            "plan_tot": 0,
            "actual_tot": 0,
            "plan_dec": 0,
            "perform": perform(data),
            "perform_all": performAll(data)
        }
        return [result, ...data];
    }
    function perform(data = []) {
        var actual_tot = calculateSum(data, 'actual_tot');
        var plan_tot = calculateSum(data, 'plan_tot');
        var perform = 0;
        if (plan_tot != 0) {
            perform = actual_tot / plan_tot;
        } else {
            perform = 0;
        }
        return perform
    }
    function performAll(data = []) {
        var actual_tot = calculateSum(data, 'actual_tot');
        var plan_tot = calculateSum(data, 'plan_tot');
        var plan_dec = calculateSum(data, 'plan_dec');
        var performAll = 0;
        if (plan_tot != 0) {
            performAll = actual_tot / plan_dec;
        } else {
            performAll = 0;
        }
        return performAll
    }
    function calculateSum(array, property) {
        const total = array.reduce((accumulator, object) => {
            return accumulator + object[property];
        }, 0);
        return total;
    }
    function dsCurve1(data = {}, data_bulan_default = 1) {
        var ds = [
            { arg: "JAN", name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", name: "dec", plan: null, actual: null, perform: null },
        ]
        if (data) {
            Object.keys(data).map((key) => {
                var tempKey = key.substr(0, key.indexOf('_'))
                var tempName = key.substr(key.indexOf('_') + 1, key.length)
                if (tempKey === "plan") {
                    ds.find(e => e.name === tempName).plan = data[key]
                } else if (tempKey === "actual") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        ds.find(e => e.name === tempName).actual = data[key]
                    }
                } else if (tempKey === "perform") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        ds.find(e => e.name === tempName).perform = Number(parseFloat(data[key] * 100).toFixed(1))
                    }
                }
            })
        }
        return ds
    }
    function dsCurve2(data = {}, data_bulan_default = 1) {
        var ds = [
            { arg: "JAN", name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", name: "dec", plan: null, actual: null, perform: null },
        ]
        if (data) {
            Object.keys(data).map((key) => {
                var tempKey = key.substr(0, key.indexOf('_'))
                var tempName = key.substr(key.indexOf('_') + 1, key.length)
                if (tempKey === "plan") {
                    var index = ds.findIndex(e => e.name === tempName)
                    if (index !== -1) {
                        ds[index].plan = Math.round(data[key]);
                    }
                } else if (tempKey === "actual") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        var index = ds.findIndex(e => e.name === tempName)
                        if (index !== -1) {
                            ds[index].actual = Math.round(data[key])
                        }
                    }
                } else if (tempKey === "perform") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= data_bulan_default) {
                        var index = ds.findIndex(e => e.name === tempName)
                        if (index !== -1) {
                            ds[index].perform = Math.round(data[key] * 100)
                        }
                    }
                }
            })
        }
        return ds
    }
    const getperformance = (data_WorkRealizationTotal = [], data_SpendYearToDate2 = []) => {
        var actual_budget = calculateSum(data_SpendYearToDate2, 'actual_budget');
        var plan_budget = calculateSum(data_SpendYearToDate2, 'plan_budget');
        var helper = {
            early: calculateSum(data_WorkRealizationTotal, 'early'),
            ontime: calculateSum(data_WorkRealizationTotal, 'ontime'),
            delayed: calculateSum(data_WorkRealizationTotal, 'delayed'),
            finished: calculateSum(data_WorkRealizationTotal, 'finished'),
            stopped: calculateSum(data_WorkRealizationTotal, 'stopped'),
            actual_budget: actual_budget,
            plan_budget: plan_budget,
            perform_budget: plan_budget !== 0 ? (actual_budget) / (plan_budget) : 0,
        }
        setHelperPerformance({
            ...helper,
            text: (parseFloat(helper.perform_budget * 100).toFixed(1)) + "% / " + (helper.early + helper.ontime + helper.delayed + helper.finished + helper.stopped),
        })
    }
    const getBudget = (data_BudgetRealizationTotal = [], data_SpendYearToDate = []) => {
        var actual_budget = calculateSum(data_SpendYearToDate, 'actual_budget');
        var plan_tot = calculateSum(data_SpendYearToDate, 'plan_tot');
        setHelperBudget({
            under: calculateSum(data_BudgetRealizationTotal, 'under'),
            within: calculateSum(data_BudgetRealizationTotal, 'within'),
            over: calculateSum(data_BudgetRealizationTotal, 'over_'),
            stopped: calculateSum(data_BudgetRealizationTotal, 'stopped'),
            actual_budget: actual_budget,
            plan_tot: plan_tot,
            perform_budget: plan_tot !== 0 ? actual_budget / plan_tot : 0
        });
    }
    const generateChartSpend = (data_SpendYearToDate2 = [], data_SpendYearToDate = []) => {
        var ds = data_SpendYearToDate2.map((element, i) => {
            return {
                id: element.function_id,
                category: element.short_name,
                actual_budget: null,
                remain_budget: null,
            }
        });
        [...(data_SpendYearToDate || [])].forEach(d => {
            var index = ds.findIndex(r => r.category === d.short_name)
            if (index >= 0) {
                ds[index].actual_budget = parseFloat(parseFloat(d.actual_budget || 0).toFixed(1));
                ds[index].remain_budget = parseFloat(parseFloat(d.remain_budget || 0).toFixed(1));
            }
        })
        return ds.map(d => ({ ...d, _total: 0 })).sort((a, b) => b.id - a.id);
    }
    const handleActiveChange = (val) => {
        setActive(val);
        GetDashboard(filter.tahun, filter.bulanGet, val);
    }

    function custmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/ongoing-research/view-sub-holdings/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span>
                </Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName(e, data_dash?.data_bulan_default))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }
    return <mppsContext.Provider
        value={{
            mppsAction: {
                handleSelTabChange,
                calculateSum,
                custmCellRender,
                GetDashboard,
                GetTable,
                filterChange,
                handleActiveChange
            },
            mppsState: {
                selTab,
                data_page,
                data_dash,
                helperPerformance,
                helperBudget,
                data_table,
                loading_table,
                active,
                loading_dash,
                loadingActive,
                year_source,
                month,
                filter
            }
        }}
    >{children}</mppsContext.Provider>
}

export default function useMpps() {
    return useContext(mppsContext);
}