import React, { useState } from 'react';
import usePartApproval, { PartAppProvider } from './service';
import { useHistory } from 'react-router-dom';
import { HeaderList, RadioGrp } from '../../../components';
import { Card } from 'react-bootstrap';

function DetailApproval(props) {
    const history = useHistory();
    const [isediting, setIsediting] = useState(false);

    const { partAppstate: {
        optRadioSa = [],
        approvalOption = [],
        data_detail = {},
        data_page: {
            page_title = '',
            page_breadcrumbs = ''
        },
        status_approval,
        status_approval_note,
        reviewer
    }, partappAction: {
        confirm = () => null,
        confirmReviewer = () => null,
        setStatus_approval,
        setStatus_approval_note,
        setReviewer
    } } = usePartApproval();
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={page_title}
                someText={page_breadcrumbs}
            />
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <Card
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div className='row p-5'>
                                <div className='col-md-6'>
                                    <div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Detail</span>
                                        <hr />
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Judul Kerjasama</div>
                                            <div className="col-md ">
                                                <span className=''>{data_detail?.judul}</span>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Usulan Dari</div>
                                            <div className="col-md ">
                                                <span className=''>{data_detail?.usulan_dari === 1 ? "Top Down" : data_detail?.usulan_dari === 2 ? "Bottom Up" : ""}</span>
                                            </div>
                                        </div>                                        
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Nama Partner</div>
                                            <div className="col-md ">
                                                <ul className='pl-6'>
                                                    {
                                                        (data_detail.kerjasama_partners || []).map((d, i) => (
                                                            <li key={i} className="mb-1">
                                                                <div className="row px-3 mb-2">
                                                                    <div className="col text-left">
                                                                        <span className="d-block text-left mb-3  font-weight-bold">
                                                                            {d?.nama_partner}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <form className='px-5 mb-3'>
                                                                    <div className="form-group row mb-0">
                                                                        <div className='table-responsive'>
                                                                            <table className='table table-bordered' style={{ 'fontSize': '8pt' }}>
                                                                                <thead>
                                                                                    <tr className='bg-light-metal'>
                                                                                        <th scope="col" style={{ 'fontSize': '8pt' }}>
                                                                                            Strategic Advantage
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='p-5'>
                                                                                            {
                                                                                                [...(optRadioSa || [])].map((x, j) => (
                                                                                                    <div className="form-check" key={`${i}${j}`}>
                                                                                                        <input className="form-check-input" type="radio" name="partner" id={`partner${j}`} value={x?.value} style={{
                                                                                                            top: '3px',
                                                                                                            position: 'relative',
                                                                                                            marginRight: '4px'
                                                                                                        }}
                                                                                                            checked={d?.score_sa === x?.value}
                                                                                                            readOnly
                                                                                                            disabled
                                                                                                        />
                                                                                                        <label className="form-check-label" htmlFor={`partner${i}`} style={{ fontSize:'8pt' }}>
                                                                                                            {`(${x?.value}) ${x?.hint}`}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <div style={{ marginLeft: '18px', marginTop: '5px' }} className='d-block'>
                                                                                                <span className='text-muted'>(1) Memiliki kapabilitas dan reputasi pada riset terkait</span>
                                                                                                <div className="form-check mb-1">
                                                                                                    <input className="form-check-input" type="radio" name="partner" id="partnerq" value="a" style={{
                                                                                                        top: '3px',
                                                                                                        position: 'relative',
                                                                                                        marginRight: '4px'
                                                                                                    }}
                                                                                                        checked={d?.score_sa === "a"}
                                                                                                        readOnly
                                                                                                        disabled
                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor="partnerq" style={{ fontSize:'8pt' }}>
                                                                                                        (a) Jumlah implementasi proyek
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="form-check mb-1">
                                                                                                    <input className="form-check-input" type="radio" name="partner" id="partnerw" value="b" style={{
                                                                                                        top: '3px',
                                                                                                        position: 'relative',
                                                                                                        marginRight: '4px'
                                                                                                    }}
                                                                                                        checked={d?.score_sa === "b"}
                                                                                                        readOnly
                                                                                                        disabled
                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor="partnerw" style={{ fontSize:'8pt' }}>
                                                                                                        (b) Fasilitas mendukung
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="form-check mb-1">
                                                                                                    <input className="form-check-input" type="radio" name="partner" id="partnere" value="c" style={{
                                                                                                        top: '3px',
                                                                                                        position: 'relative',
                                                                                                        marginRight: '4px'
                                                                                                    }}
                                                                                                        checked={d?.score_sa === "c"}
                                                                                                        readOnly
                                                                                                        disabled
                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor="partnere" style={{ fontSize:'8pt' }}>
                                                                                                        (c) Memiliki added value di ekosistem proyek
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mb-1">
                                                                        <label htmlFor="status" className="col-sm-3 col-form-label text-content-partners px-0"> Country Base <span className='float-md-right float-lg-right'>:</span></label>
                                                                        <div className="col-sm-9 my-auto">
                                                                            <div className="form-control border-0" id="status">
                                                                                <span className=''>{d?.country}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        d?.country === 'Indonesia' && (
                                                                            <div className="form-group row mb-0">
                                                                                <label htmlFor="status" className="col-sm-3 col-form-label text-content-partners px-0">Province <span className='float-md-right float-lg-right'>:</span></label>
                                                                                <div className="col-sm-9 my-auto">
                                                                                    <div className="form-control border-0" id="status">
                                                                                        <span className=''>{d?.province}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </form>
                                                                <hr className='mx-3' />
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold"> RKAP Berkaitan</div>
                                            <div className="col-md">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div style={{ paddingBottom: '25px' }}>
                                                            <ul>
                                                                {
                                                                    [...(data_detail?.kerjasama_rkaps || [])]?.map((d, key) => (
                                                                        <li key={key}> <span className=''>{d?.show_research}</span></li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Stream Business</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.stream_business_name}</span>
                                                    {
                                                        data_detail?.stream_business === 7 && (
                                                            <span className=''>{data_detail?.stream_business_note}</span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Project Cluster</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.project_cluster_name}</span>
                                                    {
                                                        data_detail?.project_cluster === 5 && (
                                                            <span className=''>{data_detail?.project_cluster_note}</span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Lokasi Project</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.lokasi_proyek}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Aktivitas Kerjasama</span>
                                        <hr />
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Pertamina Expected Value</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.pertam_expected_value}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Pertamina Value Offer</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.pertam_value_offer}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Partner Expected Value</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.partner_expected_value}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Partner Value Offer</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.partner_value_offer}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Aktivitas Kerjasama</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.aktifitas_kerjasama}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Pengelola</span>
                                        <hr />
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Fungsi VP</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <span className=''>{data_detail?.function_long_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Fungsi Manager</div>
                                            <div className="col-md ">
                                                <ul>
                                                    {
                                                        [...(data_detail?.kerjasama_fungsies || [])].map((d, i) => (
                                                            <li key={i}>
                                                                <span className=''>{d?.chief_manager_name}</span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left d-block text-wrap font-weight-bold">Nama PIC</div>
                                            <div className="col-md ">
                                                <span className=''>{data_detail?.pic_name}</span>
                                            </div>
                                        </div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Dokumen Pendukung (Pemilihan Partner Terpilih)(Optional)</span>
                                        <hr />
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-12">
                                                {
                                                    data_detail?.file_name && (
                                                        <span className='d-block text-wrap'>
                                                            {data_detail?.file_name}
                                                            <a title='Download' className='btn btn-icon fa fa-file' href={data_detail?.file_location} target='_blank' download />
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body>
                            <div className='row p-5'>
                                <div className='col-md-6'>
                                    <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Partnership Scoring</span>
                                    <hr />
                                    <div className='row px-3 mb-5'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered" style={{ 'fontSize': '8pt' }}>
                                                <thead>
                                                    <tr className='bg-blue'>
                                                        <th colSpan={2} scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Scoring</th>
                                                        <th scope="col" style={{ 'fontSize': '8pt', 'textAlign': 'center' }}>Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='align-middle text-center font-weight-bold' rowSpan={2}>Partner Scoring</td>
                                                        <td>Strategic Advantage (Average)</td>
                                                        <td className='text-right'>{data_detail?.total_sa}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-light-metal'>Total Partner Scoring</td>
                                                        <td className='bg-light-metal text-right'>{data_detail?.partnership_scoring?.total_sa}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='align-middle text-center font-weight-bold' rowSpan={3}>Project Scoring</td>
                                                        <td>Project Cluster</td>
                                                        <td className='text-right'>{data_detail?.partnership_scoring?.total_pc}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Partnership in the Topic Area</td>
                                                        <td className='text-right'>{data_detail?.partnership_scoring?.total_tpta}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-light-metal'>Total Project Scoring</td>
                                                        <td className='bg-light-metal text-right'>{data_detail?.partnership_scoring?.total_pc_tpta}</td>
                                                    </tr>
                                                    <tr className='bg-light-blue'>
                                                        <td colSpan={2} className='text-center'>Partnership Score</td>
                                                        <td className='text-right'>{data_detail?.partnership_scoring?.total_sa_cs_pc_tpta}</td>
                                                    </tr>
                                                    <tr className='bg-light-blue'>
                                                        <td colSpan={3} className='text-center'>{`Recommendation : "${data_detail?.partnership_scoring?.translation_scoring}"`}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Reviewer Note</span>
                                    <hr />
                                    <div className='row px-3' style={{ marginBottom: '50px' }}>
                                        <div className='col-md-12 rounded' style={{ backgroundColor: 'rgba(54, 154, 255, 0.2)', padding: '15px' }}>
                                            <div className="row px-3">
                                                <div className="col-md-2 text-left">Catatan</div>
                                                <div className="col-md">
                                                    <div className="form-group">
                                                        {
                                                            (data_detail?.status_approval === "Rejected" || (data_detail?.reviewer !== "" && isediting === false)) && (
                                                                <div style={{
                                                                    padding: '5px',
                                                                    border: '1px dashed',
                                                                    minHeight: '50px',
                                                                    borderRadius: '5px'
                                                                }}>{data_detail?.reviewer}</div>
                                                            )
                                                        }
                                                        {
                                                            (data_detail?.status_approval !== "Rejected" && (data_detail?.reviewer === "" || isediting === true)) && (
                                                                <textarea rows={4} className='form-control' placeholder='Catatan Approval'
                                                                    value={reviewer}
                                                                    onChange={(e) => setReviewer(e.target.value)}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-12 text-right'>
                                                    {
                                                        (data_detail?.status_approval !== "Rejected" && data_detail?.reviewer !== "" && isediting === false) && (
                                                            <button className='btn btn-info btn-sm' onClick={() => setIsediting(true)}><i className="fa fa-pencil-alt icon-nm"></i> Edit</button>
                                                        )
                                                    }
                                                    {
                                                        (data_detail?.status_approval !== "Rejected" && (data_detail?.reviewer == "" || isediting === true)) && (
                                                            <button className='btn btn-primary btn-sm'
                                                                onClick={confirmReviewer}
                                                            ><i className="fa fa-save icon-nm"></i> Save Note</button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>SVP Approval</span>
                                    <hr />
                                    <div className='row px-3 mb-5'>
                                        <div className='col-md-12 rounded' style={{ backgroundColor: 'rgba(54, 154, 255, 0.2)', padding: '15px' }}>
                                            <div className="row px-3 mb-5">
                                                <div className="col-md-12">
                                                    {
                                                        [...(approvalOption || [])].map((x, j) => (
                                                            <>
                                                                {
                                                                    data_detail?.status_approval === "Rejected" ? (
                                                                        <>
                                                                            <div className="form-check" key={j}>
                                                                                <input className="form-check-input" type="radio" name="status" id={`status${j}`} value={x?.value} style={{
                                                                                    top: '3px',
                                                                                    position: 'relative',
                                                                                    marginRight: '4px'
                                                                                }}
                                                                                    disabled
                                                                                    checked={status_approval === "Rejected"}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`status${j}`} >
                                                                                    {x?.label}
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="form-check" key={j}>
                                                                                <input className="form-check-input" type="radio" name="status" id={`status${j}`} value={x?.value} style={{
                                                                                    top: '3px',
                                                                                    position: 'relative',
                                                                                    marginRight: '4px'
                                                                                }}
                                                                                    checked={status_approval === x?.value}
                                                                                    onChange={(e) => setStatus_approval(e.target.value)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`status${j}`} >
                                                                                    {x?.label}
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="row px-3">
                                                <div className="col-md-2 text-left">Catatan</div>
                                                <div className="col-md">
                                                    <div className="form-group">
                                                        {
                                                            data_detail?.status_approval === "Rejected" ? (
                                                                <textarea rows={4} className='form-control' placeholder='Catatan Approval'
                                                                    value={data_detail?.status_approval_note}
                                                                    disabled
                                                                />
                                                            ) : (
                                                                <textarea rows={4} className='form-control' placeholder='Catatan Approval'
                                                                    value={status_approval_note}
                                                                    onChange={(e) => setStatus_approval_note(e.target.value)}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-12 text-right'>
                                                    {
                                                        data_detail?.status_approval !== "Rejected" && (
                                                            <button className="btn btn-success btn-sm mx-1 float-md-right float-lg-right"
                                                                onClick={() => confirm()}
                                                            ><i className="fa fa-save icon-nm"></i> Save Approval</button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className='col-md-6'>
                        <button className="btn btn-sm btn-secondary mx-1" onClick={() => history.goBack()}>Kembali</button>
                    </div>
                    <div className="col-md-6">

                    </div>
                </div>
            </div>
        </div >
    );
}

export default () => <PartAppProvider><DetailApproval /></PartAppProvider>;