import React, { memo, useEffect, useState } from 'react';
import { Tab, TabContent, HeaderList, HeaderProject, Modal } from '../../components';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useGlobalState } from '../../contexts/useGlobalState';
import TabBudget from './tabbudget';
import TabDetail from './tabdetail';
import TabRisk from './tabrisk';
import TabTimeline from './tabtimeline';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card'
import TabDetailOperational from './tabDetailOperational';
import { toLower, toString } from 'lodash';
import ModalSubmitRevision from '../portFolioViewEditAdmin/modalSubmitRevision';


export default memo(function (props) {
    const [state, dispatch, action] = useGlobalState();
    const [selectedTab, setSelectedTab] = useState('detail')
    const history = useHistory()
    const param = useParams()
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))
    const [komentarPortfolio, setKomentarPortfolio] = useState('');
    const [komentarOngoing, setKomentarOngoing] = useState('');

    useEffect(() => {
        action.resetForm();
        let disable = param.mode === "view" ? (path === "admin" ? false : true) : false;
        action.setForm('disable', disable);
        getDetail();
    }, [])
    function getDetail() {
        action.getData("/api/project-portfolio-crud/view-detail/" + param?.id, {}, null, null, false).then((resp) => {
            if (resp.code === "00") {
                // if (resp.data.data_view_header?.project_type !== 5) {
                //     action.getData("/api/project-portfolio-crud/view-risk/" + param.id).then((resp) => {
                //         if (resp.code === "00") {
                //             action.setForm("data_view_risk", resp.data.data_view_risk)
                //             if (resp.data.data_view_header?.submit_status !== 0 && resp.data.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio') {
                //                 action.setForm('disable', true)
                //             }
                //         }
                //     })
                // }
                // if (resp.data.data_view_header?.submit_status !== 0 && resp.data.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio') {
                //     action.setForm('disable', true)
                // }
                action.setForm('portfolio_data_page', resp.data_page);
            }
        })
    }
    function getdata() {
        action.getData("/api/project-portfolio-crud/view-risk/" + param.id, {}, null, 'loadingRisk', true).then((resp) => {
            if (resp.code === "00") {
                action.setForm("data_view_risk", resp.data.data_view_risk);
                action.setForm('portfolio_data_page', resp.data_page);
            }
        })
        action.getData("/api/project-portfolio-crud/view-timeline/" + param.id, {}, null, null, true).then((resp) => {
            if (resp.code === "00") {
                if (resp.data.data_view_timeline?.length > 0) {
                    action.setForm("dataSource", resp.data.data_view_timeline)
                }
                if (resp.data.data_view_header?.submit_status !== 0 && resp.data.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio') {
                    action.setForm('disable', true)
                }
                action.setForm('portfolio_data_page', resp.data_page);
            }
        })
        action.getData("/api/project-portfolio-crud/view-budget/" + param.id, {}, "data_view_budget", null, true).then((resp) => {
            if (resp.code === "00") {
                action.setForm("dataSourceBudget", resp.data.data_view_budget)
                if (resp.data.data_view_header?.submit_status !== 0 && resp.data.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio') {
                    action.setForm('disable', true)
                }
                action.setForm('portfolio_data_page', resp.data_page);
            }
        })
    }
    useEffect(() => {
        if ((state.data_view_header?.workflow_state_id === 39 ||
            state.data_view_header?.workflow_state_id === 41 ||
            state.data_view_header?.workflow_state_id === 75) && toLower(param.mode) === "edit") {
            if (state.data_view_header?.workflow_state_id === 75 && state.data_view_header?.submit_status !== 0) {
                action.setForm('disable', true);
            } else {
                action.setForm('disable', false);
            }
        } else {
            action.setForm('disable', true);
        }
    }, [state.data_view_header?.workflow_state_id])
    useEffect(() => {
        if (state.modal.modalRevisionPortfolio === true) {
            setKomentarPortfolio('');
        }
        if (state.modal.modalRejectPortfolio === true) {
            setKomentarPortfolio('');
        }
        if (state.modal.modalRevisionOngoing === true) {
            setKomentarOngoing('');
        }
    }, [state.modal.modalRevisionPortfolio, state.modal.modalRevisionOngoing, state.modal.modalRejectPortfolio])
    function onTabChanged(val) {
        if (selectedTab === 'detail' && state.formData.disable === false) {
            //setSelectedTab(val)
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Save Project Detail?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    updateDetail(val)
                    return true;
                } else {
                    // setSelectedTab(val)
                    return true;
                }
            })
        } else {
            setSelectedTab(val)
        }

    }

    function validation() {
        let valid = {
            isValid: true,
            message: ''
        }
        // const tmp = state.formData.keyword ? [...((typeof state.formData.keyword === 'string' ? JSON.parse(state.formData.keyword) : state.formData.keyword) || [])] : [];
        // const keyword = tmp.map(d => {
        //     return {
        //         "name": d.value,
        //         "tag_id": '',
        //         "table_name": '',
        //         "data_id": '',
        //         "is_new": d?.is_new === false ? d?.is_new : true
        //     }
        // })
        // const data = {
        //     "id": state.data_view_header?.id || '',
        //     "project_type": state.data_view_header?.project_type || '',
        //     "research_category_id": state.formData.research_category_id || '',
        //     "chief_manager_id": state.data_view_header?.chief_manager_id || '',
        //     "title": state.data_view_detail?.title || '',
        //     "description": state.data_view_detail?.description || '',
        //     "purpose": state.data_view_detail?.purpose || '',
        //     "initiator": state.data_view_detail?.initiator || '',
        //     "created_at": state.data_view_header?.created_at_form || '',
        //     "updated_at": state.data_view_header?.updated_at_form || '',
        //     "year_ideation": state.data_view_header?.year_ideation || '',
        //     "baseline_name": state.data_view_detail?.baseline_name || '',
        //     "jangka_waktu": state.data_view_detail?.jangka_waktu || '',
        //     "duk_operasional_opt_0": state.data_view_detail?.duk_operasional_opt_0 || '',
        //     "duk_operasional_opt_1": state.data_view_detail?.duk_operasional_opt_1 || '',
        //     "duk_operasional_opt_2": state.data_view_detail?.duk_operasional_opt_2 || '',
        //     "duk_operasional_ket": state.data_view_detail?.duk_operasional_ket || '',
        //     "potential_user": state.data_view_detail?.potential_user || '',
        //     "owner": state.data_view_detail?.owner || '',
        //     "method": state.data_view_detail?.method || '',
        //     "potensial_partner_opt": state.data_view_detail?.potensial_partner_opt || '',
        //     "potensial_partner_ket": state.data_view_detail?.potensial_partner_ket || '',
        //     "project_code": state.data_view_detail?.project_code || '',
        //     "project_unggulan": state.data_view_detail?.project_unggulan || '',
        //     "stage": state.data_view_detail?.stage || '',
        //     "document": [...(state.data_view_documents || [])],
        //     "document_delete": [],
        //     "expertise_neededs": [...(state.data_view_expertise_needed || [])],
        //     "expertise_neededs_delete": [],
        //     "keyword": [...(state.data_view_keywords || [])],
        //     "keyword_delete": [],
        //     "related_researchs": state.data_view_related_research || [],
        //     "related_researchs_delete": [],
        //     "team_members": Array.from([... new Set(((state.data_view_team_members || []).filter(obj => obj.team_role === "Member")).map(s => s))]),
        //     "team_members_delete": [],
        //     "pendapatan": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.pendapatan || '',
        //     "efisiensi": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.efisiensi || '',
        //     "produksi": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.produksi || '',
        //     "sat_pendapatan": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.sat_pendapatan || '',
        //     "sat_efisiensi": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.sat_efisiensi || '',
        //     "sat_produksi": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.sat_produksi || '',
        //     "ket_pendapatan": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.ket_pendapatan || '',
        //     "ket_efisiensi": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.ket_efisiensi || '',
        //     "ket_produksi": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.ket_produksi || '',
        //     "intangible": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.intangible || '',
        //     "tangible_angka": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.tangible_angka || '',
        //     "tangible_keterangan": (Array.isArray(state.data_view_value_creations) ? { ...state.data_view_value_creations?.[0] } : state.data_view_value_creations)?.tangible_keterangan || ''
        // }
        // if (data.project_type === 5) {
        //     if (data.title === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Title tidak boleh kosong \n`;
        //     }
        //     if (data.description === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Deskripsi, `;
        //     }
        //     if (data.purpose === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Tujuan, `;
        //     }
        //     if (data.owner === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Owner, `;
        //     }
        //     if (data.keyword.length === 0) {
        //         valid.isValid = false
        //         valid.message = valid.message + `Keyword, `;
        //     }
        //     if (data.team_members.length === 0) {
        //         valid.isValid = false
        //         valid.message = valid.message + `Member, `;
        //     }
        //     if (valid.isValid) {
        //         if ([...(state.formData.dataSource || [])].length === 0) {
        //             valid.isValid = false
        //             valid.message = 'Harap isi Table Timeline'
        //         }
        //         else if ([...(state.formData.dataSourceBudget || [])]?.length === 0) {
        //             valid.isValid = false
        //             valid.message = 'Harap isi Table Budget'
        //         } else {
        //             if ([...(state.formData.dataSource || [])]?.length > 0) {
        //                 const x = [...(state.formData.dataSource || [])].every(d => {
        //                     const filtered = [...(d.data || [])].filter(x => x.type?.toString() !== 'task')
        //                     const filteredMilestone = [...(d.data || [])].filter(x => (x.type?.toString() === 'milestones' || x.type?.toString() === 'submilestones'))
        //                     if (filtered.length < 8) {
        //                         valid.isValid = false;
        //                         valid.message = 'Timeline ' + d.year + ' minimal 8 kegiatan (kombinasi sub task, milestone, sub milestone)'
        //                         return false;
        //                     } else if (filteredMilestone < 1) {
        //                         valid.isValid = false;
        //                         valid.message = 'Timeline ' + d.year + ' minimal ada 1 milestone/sub milestone'
        //                         return false;
        //                     } else {
        //                         let total = 0;
        //                         [...(d.data || [])].forEach(x => {
        //                             total += ((isNaN(Number(x.plan_jan))) ? 0 : Number(x.plan_jan)) +
        //                                 ((isNaN(Number(x.plan_feb))) ? 0 : Number(x.plan_feb)) +
        //                                 ((isNaN(Number(x.plan_mar))) ? 0 : Number(x.plan_mar)) +
        //                                 ((isNaN(Number(x.plan_apr))) ? 0 : Number(x.plan_apr)) +
        //                                 ((isNaN(Number(x.plan_may))) ? 0 : Number(x.plan_may)) +
        //                                 ((isNaN(Number(x.plan_jun))) ? 0 : Number(x.plan_jun)) +
        //                                 ((isNaN(Number(x.plan_jul))) ? 0 : Number(x.plan_jul)) +
        //                                 ((isNaN(Number(x.plan_aug))) ? 0 : Number(x.plan_aug)) +
        //                                 ((isNaN(Number(x.plan_sep))) ? 0 : Number(x.plan_sep)) +
        //                                 ((isNaN(Number(x.plan_oct))) ? 0 : Number(x.plan_oct)) +
        //                                 ((isNaN(Number(x.plan_nov))) ? 0 : Number(x.plan_nov)) +
        //                                 ((isNaN(Number(x.plan_dec))) ? 0 : Number(x.plan_dec));
        //                         })
        //                         if (total < 100) {
        //                             valid.isValid = false;
        //                             valid.message = 'Timeline ' + d.year + ' masih kurang dari 100%'
        //                             return false;
        //                         }
        //                     }
        //                     return true
        //                 })
        //             }
        //             if (valid.isValid) {
        //                 if ([...(state.formData.dataSourceBudget || [])].length > 0) {
        //                     const x = [...(state.formData.dataSourceBudget || [])].every(d => {
        //                         if ([...(d.data || [])].length === 0) {
        //                             valid.isValid = false;
        //                             valid.message = 'Drawdown Budget ' + d.year + ' tidak boleh kosong'
        //                             return false;
        //                         }
        //                     })
        //                 }
        //             }
        //         }
        //     }

        // } else {
        //     if (data.title === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Title, `;
        //     }
        //     if (data.description === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Deskripsi, `;
        //     }
        //     if (data.purpose === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Purpose, `;
        //     }
        //     if ([...(state.formData.dukungan_operational || [])].length === 0) {
        //         valid.isValid = false
        //         valid.message = valid.message + `Dukungan OP, `;
        //     }
        //     if (data.potential_user === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Pemakai Potensial, `;
        //     }
        //     if (data.owner === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Owner, `;
        //     }
        //     if (data.expertise_neededs.length === 0) {
        //         valid.isValid = false
        //         valid.message = valid.message + `Keahlian, `;
        //     }
        //     if (data.keyword.length === 0) {
        //         valid.isValid = false
        //         valid.message = valid.message + `Keyword, `;
        //     }
        //     if (data.tangible_angka === '') {
        //         valid.isValid = false
        //         valid.message = valid.message + `Tangible value, `;
        //     }
        //     if (data.tangible_keterangan === 0) {
        //         valid.isValid = false
        //         valid.message = valid.message + `Tangible ket., `;
        //     }
        //     if (data.team_members.length === 0) {
        //         valid.isValid = false
        //         valid.message = valid.message + `Member, `;
        //     }
        //     if (state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') {
        //         if (data.potensial_partner_opt === '' || data.potensial_partner_ket === '') {
        //             valid.isValid = false;
        //             valid.message = valid.message + `Potensial partner, `;
        //         }
        //     }
        //     if (toString(data.project_type) === '1' || toString(data.project_type) === '2' || toString(data.project_type) === '4') {
        //         if (data.stage === '') {
        //             valid.isValid = false;
        //             valid.message = valid.message + `Stage, `;
        //         }
        //     }
        //     //validate project risk, timeline and budget
        //     if (valid.isValid) {
        //         if ([...(state.formData.data_view_risk || [])].length === 0) {
        //             valid.isValid = false
        //             valid.message = 'Harap isi Table Risk'
        //         }
        //         else if ([...(state.formData.dataSource || [])].length === 0) {
        //             valid.isValid = false
        //             valid.message = 'Harap isi Table Timeline'
        //         }
        //         else if ([...(state.formData.dataSourceBudget || [])]?.length === 0) {
        //             valid.isValid = false
        //             valid.message = 'Harap isi Table Budget'
        //         } else {
        //             if ([...(state.formData.dataSource || [])]?.length > 0) {
        //                 const x = [...(state.formData.dataSource || [])].every(d => {
        //                     const filtered = [...(d.data || [])].filter(x => x.type?.toString() !== 'task')
        //                     const filteredMilestone = [...(d.data || [])].filter(x => (x.type?.toString() === 'milestones' || x.type?.toString() === 'submilestones'))
        //                     if (filtered.length < 8) {
        //                         valid.isValid = false;
        //                         valid.message = 'Timeline ' + d.year + ' minimal 8 kegiatan (kombinasi sub task, milestone, sub milestone)'
        //                         return false;
        //                     } else if (filteredMilestone < 1) {
        //                         valid.isValid = false;
        //                         valid.message = 'Timeline ' + d.year + ' minimal ada 1 milestone/sub milestone'
        //                         return false;
        //                     } else {
        //                         let total = 0;
        //                         [...(d.data || [])].forEach(x => {
        //                             total += ((isNaN(Number(x.plan_jan))) ? 0 : Number(x.plan_jan)) +
        //                                 ((isNaN(Number(x.plan_feb))) ? 0 : Number(x.plan_feb)) +
        //                                 ((isNaN(Number(x.plan_mar))) ? 0 : Number(x.plan_mar)) +
        //                                 ((isNaN(Number(x.plan_apr))) ? 0 : Number(x.plan_apr)) +
        //                                 ((isNaN(Number(x.plan_may))) ? 0 : Number(x.plan_may)) +
        //                                 ((isNaN(Number(x.plan_jun))) ? 0 : Number(x.plan_jun)) +
        //                                 ((isNaN(Number(x.plan_jul))) ? 0 : Number(x.plan_jul)) +
        //                                 ((isNaN(Number(x.plan_aug))) ? 0 : Number(x.plan_aug)) +
        //                                 ((isNaN(Number(x.plan_sep))) ? 0 : Number(x.plan_sep)) +
        //                                 ((isNaN(Number(x.plan_oct))) ? 0 : Number(x.plan_oct)) +
        //                                 ((isNaN(Number(x.plan_nov))) ? 0 : Number(x.plan_nov)) +
        //                                 ((isNaN(Number(x.plan_dec))) ? 0 : Number(x.plan_dec));
        //                         })
        //                         if (total < 100) {
        //                             valid.isValid = false;
        //                             valid.message = 'Timeline ' + d.year + ' masih kurang dari 100%'
        //                             return false;
        //                         }
        //                     }
        //                     return true
        //                 })
        //             }
        //             if (valid.isValid) {
        //                 if ([...(state.formData.dataSourceBudget || [])].length > 0) {
        //                     const x = [...(state.formData.dataSourceBudget || [])].every(d => {
        //                         if ([...(d.data || [])].length === 0) {
        //                             valid.isValid = false;
        //                             valid.message = 'Drawdown Budget ' + d.year + ' tidak boleh kosong'
        //                             return false;
        //                         }
        //                     })
        //                 }
        //             }
        //         }
        //     }
        // }
        return valid
    }
    //submit portfolio
    function submitDraft() {
        const validate = validation();
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                html: 'Harap Lengkapi data <br/>' + validate.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Submit Portfolio?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-portfolio-crud/submit-draft?id=" + state.data_view_header?.id,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            action.resetForm();
                            history.push('/myproject')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    //submit portfolio revision
    function submitRevision() {
        const validate = validation();
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                html: 'Harap Lengkapi data <br/>' + validate.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Submit Revision?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-portfolio-crud/submit-revision?id=" + state.data_view_header?.id,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            action.resetForm();
                            history.push('/myproject')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function submitOngoingRevision(data) {
        const validate = validation();
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                html: 'Harap Lengkapi data <br/>' + validate.message,
                icon: 'warning',
            })
        }
        else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-success mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                    '<strong>Submit Ongoing Revision?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/project-ongoing-crud/submit-revision?id=${state.data_view_header?.id}&baseline_name=${state.data_view_header?.baseline_name}&note=${data?.note}&comment=${data?.comment}`,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            action.resetForm();
                            action.toggleModal('revisionReview')
                            history.push('/myproject')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    //approve admin
    function submitApprove() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Portfolio?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-portfolio-crud/submit-approve?id=" + state.data_view_header?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    //approve ongoing revision
    function submitOngoingApproveRevision() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Ongoing Revision?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/submit-revision-approve?id=" + state.data_view_header?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function createNewVersion() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Create New Version?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/create-new-version?id=" + state.data_view_header?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        // action.resetForm();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function revisionOngoing() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-orange mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-orange text-white customShadow mb-5"></i></div>' +
                '<strong>Revision ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/project-ongoing-crud/submit-revision-need-revision?id=${state.data_view_header?.id}&comment=${komentarOngoing}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.toggleModal('modalRevisionOngoing')
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                action.toggleModal('modalRevisionOngoing')
                return true;
            }
        })
    }
    function revision() {
        if (komentarPortfolio === "") {
            return Swal.fire({
                title: 'Warning',
                html: "Komentar tidak boleh kosong",
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-orange mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-orange text-white customShadow mb-5"></i></div>' +
                '<strong>Revision Portfolio ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/project-portfolio-crud/submit-need-revision?id=${state.data_view_header?.id}&comment=${komentarPortfolio}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        action.toggleModal('modalRevisionPortfolio')
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                action.toggleModal('modalRevisionPortfolio')
                return true;
            }
        })
    }
    function reject() {
        if (komentarPortfolio === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Komentar tidak boleh kosong',
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Reject Portfolio ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/project-portfolio-crud/submit-reject?id=${state.data_view_header?.id}&comment=${komentarPortfolio}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.toggleModal('modalRejectPortfolio')
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function approveImplementation() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Implementation?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-portfolio-crud/submit-implementation?id=" + state.data_view_header?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function resetVersion() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Apakah anda yakin akan melakukan reset ke versi sebelumnya?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Reset to previous version',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/reset-new-version?idRes=" + state.data_view_header?.id + '&baseline_name=' + state.data_view_header?.baseline_name,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        // action.resetForm();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function validateUpdateDetail(data) {
        var isValid = true;
        var message = [];
        if (data.project_type === 5) {
            if (data.title === ''
            ) {
                isValid = false
                message = [...message, `Judul`];
            }
            if (data.description === '') {
                isValid = false
                message = [...message, `Pain Point`];
            }
            if (data.purpose === '') {
                isValid = false
                message = [...message, `Commercialization /Implementation- Objective`];
            }
            if (data.keyword.length === 0) {
                isValid = false
                message = [...message, `Keyword`];
            }
            if (data.owner === '') {
                isValid = false
                message = [...message, `Leader`];
            }
            if (data.team_members.length === 0) {
                isValid = false
                message = [...message, `Member`];
            }
        } else {
            if (data.title === '') {
                isValid = false
                message = [...message, `Judul`];
            }
            if (data.description === '') {
                isValid = false
                message = [...message, `Pain Point`];
            }
            if (data.purpose === '') {
                isValid = false
                message = [...message, `Commercialization /Implementation- Objective`];
            }
            if ([...(state.formData.dukungan_operational || [])].length === 0) {
                isValid = false
                message = [...message, `Dukungan OP`];
            }
            if (data.potential_user === '') {
                isValid = false
                message = [...message, `Pemakai Potensial`];
            }
            if (data.keyword.length === 0) {
                isValid = false
                message = [...message, `Keyword`];
            }
            if (data.owner === '') {
                isValid = false
                message = [...message, `Leader`];
            }
            if (data.team_members.length === 0) {
                isValid = false
                message = [...message, `Member`];
            }
            if (data.expertise_neededs.length === 0) {
                isValid = false
                message = [...message, `Keahlian`];
            }
            if (data.research_category_id === "" || data.research_category_id === null) {
                isValid = false
                message = [...message, `Kategory Research`];
            }
            if (data.method === '') {
                isValid = false
                message = [...message, `Metode Riset`];
            }
            if (data.method === '2' || data.method === '3' || data.method === '4') {
                if (data.potensial_partner_opt === '' || data.potensial_partner_ket === '') {
                    isValid = false;
                    message = [...message, `Potensial partner`];
                }
            }
            if (data.tangible_angka === '') {
                isValid = false
                message = [...message, `Tangible value`];
            }
            if (data.tangible_keterangan === '') {
                isValid = false
                message = [...message, `Tangible ket.`];
            }
            if (toString(data.project_type) === '1' || toString(data.project_type) === '2' || toString(data.project_type) === '4') {
                if (data.stage === '') {
                    isValid = false;
                    message = [...message, `Stage`];
                }
            }
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        };
    }
    function updateDetail(tabName) {
        const tmp = state.formData.keyword ? [...((typeof state.formData.keyword === 'string' ? JSON.parse(state.formData.keyword) : state.formData.keyword) || [])] : [];
        const keyword = tmp.map(d => {
            return {
                "name": d.value,
                "tag_id": '',
                "table_name": '',
                "data_id": '',
                "is_new": d?.is_new === false ? d?.is_new : true
            }
        })
        const data = {
            "id": state.data_view_header?.id || '',
            "project_type": state.data_view_header?.project_type || '',
            "research_category_id": state.formData.research_category_id || '',
            "chief_manager_id": state.data_view_header?.chief_manager_id || '',
            "title": state.formData.title || '',
            "description": state.formData.description || '',
            "purpose": state.formData.purpose || '',
            "initiator": state.data_view_detail?.initiator || '',
            "created_at": state.data_view_header?.created_at_form || '',
            "updated_at": state.data_view_header?.updated_at_form || '',
            "year_ideation": state.data_view_header?.year_ideation || '',
            "baseline_name": state.data_view_detail?.baseline_name || '',
            "jangka_waktu": state.formData.jangka_waktu || '',
            "duk_operasional_opt_0": [...(state.formData.dukungan_operational || [])]?.[0]?.value || '',
            "duk_operasional_opt_1": [...(state.formData.dukungan_operational || [])]?.[1]?.value || '',
            "duk_operasional_opt_2": [...(state.formData.dukungan_operational || [])]?.[2]?.value || '',
            "duk_operasional_ket": state.formData.duk_operasional_ket || '',
            "potential_user": state.formData.potential_user || '',
            "owner": state.formData.leader?.user_id || '',
            "method": state.formData.method || '',
            "potensial_partner_opt": (state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ? (state.formData.potensial_partner_opt || '') : '',
            "potensial_partner_ket": (state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ? (state.formData.potensial_partner_ket || '') : '',
            "project_code": state.data_view_header?.project_code || '',
            "project_unggulan": state.data_view_header?.project_unggulan || '',
            "stage": state.formData.stage || '',
            "document": [...(state.formData.sumberFileTemp || [])],
            "document_delete": (state.formData.deletedDocument || []),//state.data_view_header?.submit_status === 0 ? [] : (state.formData.deletedDocument || []),
            "expertise_neededs": [...(state.formData.keahlian || [])].map(d => {
                return {
                    "expertise_id": d?.value,
                    "is_new": d?.is_new
                }
            }),
            "expertise_neededs_delete": [...(state.formData.deletedKeahlian || [])].map(d => {
                return {
                    "expertise_id": d?.value,
                    "is_new": d?.is_new
                }
            }),
            "keyword": keyword,
            "keyword_delete": state.formData.deletekeyword || [],
            "related_researchs": state.formData.realated_document || [],
            "related_researchs_delete": state.formData.realated_document_deleted || [],
            "team_members": [...(state.formData.anggota || [])].map(d => { return { user_id: d.user_id, is_new: d.is_new } }),
            "team_members_delete": [...(state.formData.deleteAnggota || [])].map(d => { return { user_id: d.user_id, is_new: d.is_new } }),
            "pendapatan": state.formData.pendapatan || '',
            "efisiensi": state.formData.efisiensi || '',
            "produksi": state.formData.produksi || '',
            "sat_pendapatan": state.formData.sat_pendapatan || '',
            "sat_efisiensi": state.formData.sat_efisiensi || '',
            "sat_produksi": state.formData.sat_produksi || '',
            "ket_pendapatan": state.formData.ket_pendapatan || '',
            "ket_efisiensi": state.formData.ket_efisiensi || '',
            "ket_produksi": state.formData.ket_produksi || '',
            "intangible": state.formData.intangible || '',
            "tangible_angka": (state.formData.tangible_angka === null || state.formData.tangible_angka === undefined) ? '' : state.formData.tangible_angka,
            "tangible_keterangan": state.formData.tangible_keterangan || '',
            "focus": state.formData?.focus || ''
        }
        const validation = validateUpdateDetail(data);
        // console.log(data);
        if (validation.isValid === true) {
            action.postData(
                {
                    url: "/api/project-portfolio-crud/update-detail",
                    data: data,
                    withFile: false
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    getDetail();
                    setSelectedTab(tabName)
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validation.message,
                icon: 'warning',
            })
        }
    }
    return (
        <>
            <Modal
                title="Submit Revision Review"
                name="revisionReview"
                baseColor={true}
            >
                <ModalSubmitRevision onValidSubmit={(data) => submitOngoingRevision(data)} onClose={() => action.toggleModal('revisionReview')} />
            </Modal>
            < Modal
                name="modalRevisionPortfolio"
                baseColor={true}
            >
                <h5>Revisi Project</h5>
                <hr />
                <div className="row">
                    <div className="col-md-3">
                        Komentar
                    </div>
                    <div className="col-md-9">
                        <textarea className="form-control form-control-sm" value={komentarPortfolio} onChange={(e) => setKomentarPortfolio(e.target.value)}></textarea>
                    </div>
                </div>
                <hr />
                <div className="text-right">
                    <button className="btn btn-sm btn-secondary mx-1" onClick={() => action.toggleModal('modalRevisionPortfolio')}>Batal</button>
                    <button className="btn btn-sm btn-warning mx-1" onClick={() => revision()}>Revisi</button>
                </div>
            </Modal>
            < Modal
                name="modalRevisionOngoing"
                baseColor={true}
            >
                <h5>Revisi Project</h5>
                <hr />
                <div className="row">
                    <div className="col-md-3">
                        Komentar
                    </div>
                    <div className="col-md-9">
                        <textarea className="form-control form-control-sm" value={komentarOngoing} onChange={(e) => setKomentarOngoing(e.target.value)}></textarea>
                    </div>
                </div>
                <hr />
                <div className="text-right">
                    <button className="btn btn-sm btn-secondary mx-1" onClick={() => action.toggleModal('modalRevisionOngoing')}>Batal</button>
                    <button className="btn btn-sm btn-warning mx-1" onClick={() => revisionOngoing()}>Revisi</button>
                </div>
            </Modal>
            < Modal
                name="modalRejectPortfolio"
                baseColor={true}
            >
                <h5>Reject Project</h5>
                <hr />
                <div className="row">
                    <div className="col-md-3">
                        Komentar
                    </div>
                    <div className="col-md-9">
                        <textarea className="form-control form-control-sm" value={komentarPortfolio} onChange={(e) => setKomentarPortfolio(e.target.value)}></textarea>
                    </div>
                </div>
                <hr />
                <div className="text-right">
                    <button className="btn btn-sm btn-secondary mx-1" onClick={() => action.toggleModal('modalRejectPortfolio')}>Batal</button>
                    <button className="btn btn-sm btn-danger mx-1" onClick={() => reject()}>Reject</button>
                </div>
            </Modal>
            <HeaderList
                title={state.formData?.portfolio_data_page?.page_title}
                someText={state.formData?.portfolio_data_page?.page_breadcrumbs}
            >
            </HeaderList>
            <HeaderProject
                workflow_stage={state.data_view_header?.workflow_state_name == 'monitoring_revise_portfolio' ? "Project Monitoring" : "Project Portfolio"}
                rightMenu={true}//{state.data_view_header?.workflow_state_name == 'monitoring_revise_portfolio' ? true : false}
                dataHeader={state.data_view_header}
                history_onClick={({ id, baseline_name }) => history.push((path === 'admin' ? '/admin/Projects/ResearchHistories/' : '/Projects/ResearchHistories/') + id)}
                monthly_onClick={({ id, baseline_name }) => history.push((path === 'admin' ? '/admin/reportResearch/index/' : '/reportResearch/index/') + id)}
                profile_onClick={({ id, baseline_name }) => history.push(`/research/project-profile/${id}/${baseline_name}`)}
            />
            {
                (state.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio' ||
                    // state.data_view_header?.workflow_state_name === 'portfolio_waiting_approval' || 
                    state.data_view_header?.workflow_state_name === 'portfolio_waiting_approval_revise' || state.data_view_header?.workflow_state_name === 'monitoring_waiting_approval') && (
                    <Card
                        bg="warning"
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div className="row m-5 text-white">
                                <div className="col-md-12">
                                    <span className="d-block mb-3">
                                        <b>
                                            Note :
                                        </b>
                                    </span>
                                    <span className="d-block text-wrap">{state.data_reason}</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )
            }
            <Card
                className="mx-0 p-3 customShadow"
                style={{ marginBottom: '40px' }}
            >
                <Card.Body>
                    {/* <div className="row m-5">
                        <div className="col-md-12 p-0">
                            <HeaderPortFolio
                                judulproyek={state.data_view_header.title}
                                tipeproyek={state.data_view_header.project_type_name}
                                versi={state.data_view_detail?.[0].baseline_name}
                                status={state.data_view_header.workflow_state_state}
                                terakhir={state.data_view_header.updated_at_form}>
                            </HeaderPortFolio>
                        </div>
                    </div> */}
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "detail",
                                title: "Project Detail",
                                onClick: () => onTabChanged("detail")
                            },
                            ...(state.data_view_header?.project_type !== 5 ? [{
                                id: "risk",
                                title: "Project Risk",
                                onClick: () => onTabChanged("risk")
                            }] : []),
                            {
                                id: "timeline",
                                title: "Project Timeline",
                                onClick: () => onTabChanged("timeline")
                            },
                            {
                                id: "budget",
                                title: "Drawdown Budget",
                                onClick: () => onTabChanged("budget")
                            },
                        ]}
                        manualActive={selectedTab}
                    >
                        <TabContent id="detail">
                            {
                                (state.data_view_header?.project_type === 1 ||
                                    state.data_view_header?.project_type === 2 ||
                                    state.data_view_header?.project_type === 3 ||
                                    state.data_view_header?.project_type === 4 ||
                                    state.data_view_header?.project_type === 6) && (
                                    <TabDetail param={param}></TabDetail>
                                )
                            }
                            {
                                state.data_view_header?.project_type === 5 && (
                                    <TabDetailOperational param={param} />
                                )
                            }
                        </TabContent>
                        <TabContent id="risk">
                            <TabRisk param={param}></TabRisk>
                        </TabContent>
                        <TabContent id="timeline">
                            <TabTimeline param={param}></TabTimeline>
                        </TabContent>
                        <TabContent id="budget">
                            <TabBudget param={param} addTimeline={() => onTabChanged("timeline")}></TabBudget>
                        </TabContent>
                    </Tab>
                </Card.Body>
            </Card>
            {
                path !== "admin" && (
                    <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                        <div className="row">
                            <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                                {
                                    history.length > 1 && (
                                        <button className=" btn btn-sm btn-secondary px-3 mx-1"
                                            onClick={() => history.goBack()}
                                        >
                                            Kembali
                                        </button>
                                    )
                                }
                            </div>
                            <div className="col-md-9 mb-lg-0 mb-md-0 mb-5 text-right">
                                {
                                    toLower(param.mode) === "edit" && (
                                        <>
                                            {
                                                state.data_view_header?.workflow_state_name === "portfolio_waiting_approval_revise" && (
                                                    <button
                                                        onClick={() => submitRevision()}
                                                        className="btn btn-sm btn-success">
                                                        <i className="fab fa-telegram-plane icon-nm text-white"></i>
                                                        Submit Revision
                                                    </button>
                                                )
                                            }
                                            {
                                                (state.data_view_header?.workflow_state_name === "monitoring_revise_portfolio" && state.data_view_header?.submit_status !== 0) && (

                                                    <button
                                                        onClick={() => createNewVersion()}
                                                        className="btn btn-sm btn-outline-primary">
                                                        <i className="fa fa-pencil-alt icon-nm"></i>
                                                        Create New Version
                                                    </button>
                                                )
                                            }
                                            {
                                                (state.data_view_header?.workflow_state_name === "monitoring_revise_portfolio" && state.data_view_header?.submit_status === 0) && (
                                                    <>
                                                        < button
                                                            onClick={() => resetVersion()}
                                                            className="btn btn-sm btn-outline-danger mx-1">
                                                            <i className="fa fa-trash-alt icon-nm"></i>
                                                            Reset to previous version
                                                        </button>
                                                        < button
                                                            // onClick={() => submitOngoingRevision()}
                                                            onClick={() => action.toggleModal('revisionReview')}
                                                            className="btn btn-sm btn-success mx-1">
                                                            <i className="fab fa-telegram-plane icon-nm text-white"></i>
                                                            Submit Revision
                                                        </button>
                                                    </>
                                                )
                                            }
                                            {
                                                (state.data_view_header?.workflow_state_name === 'ideation_approve_2' && state.data_view_header?.chief_manager_id !== null) && (
                                                    <button
                                                        onClick={() => submitDraft()}
                                                        className="btn btn-sm btn-success">
                                                        <i className="fab fa-telegram-plane icon-nm text-white"></i>
                                                        Submit Portfolio
                                                    </button>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                path === "admin" && (
                    <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                        <div className="row">
                            <div className="col-md-3 mb-lg-0 mb-md-0 mb-5">
                                {
                                    history.length > 1 && (
                                        <button className=" btn btn-sm btn-secondary px-3 mx-1"
                                            onClick={() => history.goBack()}
                                        >
                                            Kembali
                                        </button>
                                    )
                                }
                            </div>
                            <div className="col-md-9 mb-lg-0 mb-md-0 mb-5 text-right">
                                {
                                    toLower(param?.menu) === 'approval' && (
                                        <>
                                            {
                                                state.data_view_header?.workflow_state_name === 'portfolio_new_approve_2' && (
                                                    <button className=" btn btn-sm btn-success px-3 mx-1"
                                                        onClick={() => approveImplementation()}
                                                    >
                                                        <i className="fa fa-check text-white"></i>
                                                        Approve to Implementation
                                                    </button>
                                                )
                                            }
                                            {
                                                state.data_view_header?.workflow_state_name === "monitoring_waiting_approval" && (
                                                    <>
                                                        <button className=" btn btn-sm btn-success px-3 mx-1"
                                                            onClick={() => submitOngoingApproveRevision()}
                                                        >
                                                            <i className="fa fa-check text-white"></i>
                                                            Approve Revision
                                                        </button>
                                                        <button className=" btn btn-sm btn-warning text-dark px-3 mx-1"
                                                            onClick={() => action.toggleModal('modalRevisionOngoing')}
                                                        >
                                                            <i className="fa fa-check text-dark"></i>
                                                            Need Revision
                                                        </button>
                                                    </>
                                                )
                                            }
                                            {
                                                state.data_view_header?.workflow_state_name === "portfolio_waiting_approval" && (
                                                    <>
                                                        <button className=" btn btn-sm btn-success px-3 mx-1"
                                                            onClick={() => submitApprove()}
                                                        >
                                                            <i className="fa fa-check text-white"></i>
                                                            Approve Portfolio
                                                        </button>
                                                        <button className=" btn btn-sm btn-warning text-dark px-3 mx-1"
                                                            onClick={() => action.toggleModal('modalRevisionPortfolio')}
                                                        >
                                                            <i className="fa fa-check text-dark"></i>
                                                            Need Revision
                                                        </button>
                                                        <button className=" btn btn-sm btn-danger px-3 mx-1"
                                                            onClick={() => action.toggleModal('modalRejectPortfolio')}
                                                        >
                                                            <i className="fa fa-times text-white"></i>
                                                            Reject Portfolio
                                                        </button>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    toLower(param?.menu) === 'crud' && (
                                        <>
                                            {
                                                toLower(param.mode) === "edit" && (
                                                    <>
                                                        {
                                                            state.data_view_header?.workflow_state_name === "portfolio_waiting_approval_revise" && (
                                                                <button
                                                                    onClick={() => submitRevision()}
                                                                    className="btn btn-sm btn-success">
                                                                    <i className="fab fa-telegram-plane icon-nm text-white"></i>
                                                                    Submit Revision
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            (state.data_view_header?.workflow_state_name === "monitoring_revise_portfolio" && state.data_view_header?.submit_status !== 0) && (

                                                                <button
                                                                    onClick={() => createNewVersion()}
                                                                    className="btn btn-sm btn-outline-primary">
                                                                    <i className="fa fa-pencil-alt icon-nm"></i>
                                                                    Create New Version
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            (state.data_view_header?.workflow_state_name === "monitoring_revise_portfolio" && state.data_view_header?.submit_status === 0) && (
                                                                <>
                                                                    < button
                                                                        onClick={() => resetVersion()}
                                                                        className="btn btn-sm btn-outline-danger mx-1">
                                                                        <i className="fa fa-trash-alt icon-nm"></i>
                                                                        Reset to previous version
                                                                    </button>
                                                                    < button
                                                                        // onClick={() => submitOngoingRevision()}
                                                                        onClick={() => action.toggleModal('revisionReview')}
                                                                        className="btn btn-sm btn-success mx-1">
                                                                        <i className="fab fa-telegram-plane icon-nm text-white"></i>
                                                                        Submit Revision
                                                                    </button>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            (state.data_view_header?.workflow_state_name === 'ideation_approve_2' && state.data_view_header?.chief_manager_id !== null) && (
                                                                <button
                                                                    onClick={() => submitDraft()}
                                                                    className="btn btn-sm btn-success">
                                                                    <i className="fab fa-telegram-plane icon-nm text-white"></i>
                                                                    Submit Portfolio
                                                                </button>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
})