import React from 'react';

function Index(props) {
    return (
        <div style={{ 
            height:'100vh',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center'
         }}>
            <img width={'350px'} src='/images/oops.png'></img>
            <p style={{ 
                fontSize:'16px',
                fontWeight:'700'
             }}>Website Is Under Maintenance</p>
        </div>
    );
}

export default Index;