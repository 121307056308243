import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Legend,
    ValueAxis,
    Export,
    Tooltip,
    ArgumentAxis,
    Label,
    LoadingIndicator,
    SeriesTemplate,
    Size,
    Tick,
    Grid,
    CommonAnnotationSettings,
    Annotation
} from 'devextreme-react/chart';
import { SkeletonLoading } from '..';
import { useResizeDetector } from 'react-resize-detector';
import { chart_color } from '../utility/utility';
const ds = [{
    arg: 1950,
    val: 2525778669
}, {
    arg: 1960,
    val: 3026002942
}, {
    arg: 1970,
    val: 3691172616
}, {
    arg: 1980,
    val: 4449048798
}, {
    arg: 1990,
    val: 5320816667
}, {
    arg: 2000,
    val: 6127700428
}, {
    arg: 2010,
    val: 6916183482
}]
export default memo(
    function ({
        height = 200,
        type = "bar",
        title = "Your title here",
        dataSource = ds,
        withTitle = true,
        id = "chart",
        labelFormat = "decimal",
        rotate = false,
        onrenderLabel = (e) => e.value,
        onrenderLegendText = (e) => e.seriesName,
        legendMarkerRender,
        showLegend = true,
        toolsExport = true,
        colors = [],
        colorLabel,
        argumentField = "arg",
        yAxisArgs = [],
        labelChart = true,
        onRenderLabelChart = (e) => e.value,
        tooltip = true,
        children,
        LegendverticalAlignment = "top",
        LegendhorizontalAlignment = "right",
        LegenditemTextPosition = "right",
        colorBarDifferent = false,
        coloBarDifferentValueField,
        colorBarDiffirentField = "arg",
        onRenderTooltip = (arg) => ({ text: `${arg.seriesName}: ${arg.valueText}` }),
        loading = false,
        onSeriesClick,
        overlappingBehavior = "hide", //'stagger', 'rotate', 'hide', 'none'
        yTitle,
        xTitle,
        yLabel = true,
        xLabel = true,
        yVisible = true,
        xVisible = true,
        ignoreEmptyPoints = true,
        barWidth,
        rotationAngle = 90,
        withAnnotation = false,
        annotationType = 'text',
        annotationSeries = '',
        annotationCustomRender = (props) => { },
        orientationLegend,
        // showTotal = false
    }) {

        const onResize = useCallback(() => {
            if (refChart.current) refChart.current.instance.render();
        }, []);
        const refChart = useRef(null);
        const { width, ref } = useResizeDetector({ onResize });
        const parentRef = ref;

        let colorIndex = 0;
        const customizePoint = useCallback(function () {
            const color = colors[colorIndex];
            colorIndex = colorIndex === colors.length - 1 ? 0 : colorIndex + 1;
            return { color };
        })
        const heightCustom = () => {
            if (height === "not-set") {
                return undefined
            } else {
                return ([...(dataSource || [])].length * (parseInt(barWidth, 0) + 15)) + 20;
            }
        }
        // const calculateTotal = React.useMemo(() => {
        //     if (yAxisArgs.length > 0) {
        //         return [...(dataSource || [])].reduce((accumulator, object) => {
        //             const sumYaxis = [...(yAxisArgs || [])].reduce((sm, objx) => {
        //                 return sm + object?.[objx.valueField]
        //             }, 0);
        //             return accumulator + sumYaxis
        //         }, 0);
        //     } else {
        //         if (colorBarDifferent) {
        //             return [...(dataSource || [])].reduce((tot, obj) => {
        //                 return tot + obj?.[coloBarDifferentValueField]
        //             }, 0)
        //         } else {
        //             return 0;
        //         }
        //     }
        // }, [dataSource, yAxisArgs]);
        return (
            <div ref={parentRef}>
                {
                    loading ? (
                        <SkeletonLoading heightChart={height} type="chart" />
                    ) : (
                        <>
                            <Chart
                                onSeriesClick={onSeriesClick}
                                height={height !== 'auto' ? height : heightCustom}
                                ref={refChart}
                                title={withTitle ? title : null}
                                dataSource={dataSource}
                                rotated={rotate}
                                id={id}
                                palette={chart_color}
                                customizePoint={customizePoint}
                                onIncidentOccurred={() => null}
                                redrawOnResize={true}
                                adaptiveLayout={{ width: 0, height: 0 }}
                            >
                                <Size
                                    height={height !== 'auto' ? height : heightCustom}
                                />
                                <CommonSeriesSettings
                                    argumentField={argumentField}
                                    valueField={coloBarDifferentValueField}
                                    type={type}
                                    ignoreEmptyPoints={true}
                                >
                                    <Label
                                        font={{ 'size': 8, 'family': 'Poppins', 'color': colorLabel }}
                                        format={labelFormat}
                                        customizeText={(e) => onRenderLabelChart(e)}
                                        visible={labelChart}
                                    />
                                </CommonSeriesSettings>
                                {
                                    yAxisArgs.map(({ valueField, name, color, type, argumentField, sizeField, tagField, label }, i) =>
                                        <Series
                                            barWidth={barWidth}
                                            key={i}
                                            type={type}
                                            argumentField={argumentField}
                                            sizeField={sizeField}
                                            tagField={tagField}
                                            color={color}
                                            valueField={valueField}
                                            name={name}
                                            label={label}
                                        >
                                        </Series>)
                                }
                                <ArgumentAxis visible={xVisible} tickInterval={10} title={xTitle}>
                                    <Label
                                        textOverflow={'ellipsis'}
                                        font={{ 'size': 9, 'family': 'Poppins' }}
                                        format={labelFormat}
                                        customizeText={(e) => onrenderLabel(e)}
                                        visible={xLabel}
                                        rotationAngle={rotationAngle}
                                        overlappingBehavior={overlappingBehavior}
                                    />

                                    <Tick visible={xVisible} />
                                    {
                                        xVisible === false && (
                                            <Grid visible={xVisible} />
                                        )
                                    }
                                </ArgumentAxis>
                                <ValueAxis title={yTitle} visible={yVisible}>
                                    <Label
                                        textOverflow={'ellipsis'}
                                        font={{ 'size': 9, 'family': 'Poppins' }}
                                        visible={yLabel}
                                    />
                                    <Tick visible={yVisible} />
                                    {
                                        yVisible === false && (
                                            <Grid visible={yVisible} />
                                        )
                                    }
                                </ValueAxis>
                                {
                                    colorBarDifferent ?
                                        (
                                            <SeriesTemplate customizeSeries={(e) => { return { barPadding: 0 } }} nameField={colorBarDiffirentField} />
                                        ) : null
                                }
                                {children}
                                {
                                    withAnnotation === true && (
                                        <CommonAnnotationSettings
                                            type={annotationType}
                                            series={annotationSeries}
                                            render={annotationCustomRender}
                                            arrowLength={10}
                                            allowDragging={true}
                                        />
                                    )
                                }
                                {
                                    withAnnotation === true &&
                                    [...(dataSource || [])].map((d, i) => {
                                        return (
                                            <Annotation
                                                y={20}
                                                x={30 * (i + 1)}
                                                arrowLength={10}
                                                labelMode={true}
                                                data={d}
                                            >
                                            </Annotation>
                                        )
                                    })
                                }
                                <Legend
                                    visible={showLegend}
                                    verticalAlignment={LegendverticalAlignment}
                                    horizontalAlignment={LegendhorizontalAlignment}
                                    itemTextPosition={LegenditemTextPosition}
                                    markerRender={legendMarkerRender}
                                    orientation={orientationLegend}
                                    customizeText={(e) => onrenderLegendText(e)}
                                ></Legend>
                                <Export enabled={toolsExport} />
                                <LoadingIndicator enabled={false} verticalAlignment="bottom" horizontalAlignment="center" />
                                <Tooltip
                                    enabled={tooltip}
                                    location="edge"
                                    customizeTooltip={(e) => onRenderTooltip(e)}
                                />
                            </Chart>
                            {
                                // showTotal && (
                                //     <div className='px-5' style={{
                                //         fontSize: '8pt'
                                //     }}>
                                //         Total = {calculateTotal}
                                //     </div>
                                // )
                            }
                        </>
                    )
                }
            </div>
        );
    })