import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { HeaderList, Tab, TabContent } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import TabPerformance from './tabPerformance';
import TabProfile from './tabProfile';
import TabTable from './tabTable';
import './monitoring.css'
// import moment from 'moment';
import TabTableProgress from './tableProgress';
import useMonitoring, { MonProvider } from './useMonitoring';

const month = [{ name: 'JAN', value: '1' }, { name: 'FEB', value: '2' }, { name: 'MAR', value: '3' }, { name: 'APR', value: '4' },
{ name: 'MAY', value: '5' }, { name: 'JUN', value: '6' }, { name: 'JUL', value: '7' }, { name: 'AUG', value: '8' }, { name: 'SEP', value: '9' },
{ name: 'OCT', value: '10' }, { name: 'NOV', value: '11' }, { name: 'DEC', value: '12' }]

const Monitoring = memo(function () {
    const {
        monState: {
            tahun,
            bulan,
        },
        monAction: {
            setTahun,
            setBulan
        }
    } = useMonitoring();
    const history = useHistory();
    const [state] = useGlobalState();
    const [tabState, setTabstate] = useState({});
    const { pathname } = useLocation();
    const [tabsList, setTabList] = useState([
        {
            id: 'performanceFunction',
            url: '/Monitoring/dashboard-performance-byfunction',
            title: 'Performance by Function',
            // onClick: () => ToTabPerformanceByFunction()
        },
        {
            id: 'performanceArea',
            url: '/Monitoring/dashboard-performance-byarea',
            title: 'Performance by Area',
            // onClick: () => ToTabPerformanceByArea()
        },
        {
            id: 'profile',
            url: '/Monitoring/profile',
            title: 'Project Portfolio',
            // onClick: () => ToTabProfile()
        },
        {
            id: 'table-progress',
            url: '/Monitoring/table-progress',
            title: 'Table Progress',
            // onClick: () => ToTabTableProgress()
        },
        {
            id: 'table',
            url: '/Monitoring/table',
            title: 'Table Detail',
            // onClick: () => ToTabTable()
        },
    ]);
    // const ToTabPerformanceByFunction = useCallback(() => {
    //     const tab = tabsList.find(d => d.id === "performanceFunction");
    //     history.push({
    //         pathname: tab.url,
    //         param: { tahun, bulan }
    //     })
    // }, [tabsList, history, tahun, bulan]);
    // const ToTabPerformanceByArea = useCallback(() => {
    //     const tab = tabsList.find(d => d.id === "performanceArea");
    //     history.push({
    //         pathname: tab.url,
    //         param: { tahun, bulan }
    //     })
    // }, [tabsList, history, tahun, bulan]);
    // const ToTabProfile = useCallback(() => {
    //     const tab = tabsList.find(d => d.id === "profile");
    //     history.push({
    //         pathname: tab.url,
    //         param: { tahun, bulan }
    //     })
    // }, [tabsList, history, tahun, bulan]);
    // const ToTabTable = useCallback(() => {
    //     const tab = tabsList.find(d => d.id === "table");
    //     history.push({
    //         pathname: tab.url,
    //         param: { tahun, bulan }
    //     })
    // }, [tabsList, history, tahun, bulan]);
    // const ToTabTableProgress = useCallback(() => {
    //     const tab = tabsList.find(d => d.id === "table-progress");
    //     history.push({
    //         pathname: tab.url,
    //         param: { tahun, bulan }
    //     })
    // }, [tabsList, history, tahun, bulan]);
    const handleTabchange = useCallback((id = '') => {
        const tab = tabsList.find(d => d.id === id);
        setTabstate({ ...tab });
    }, [setTabstate, tabsList]);
    useEffect(() => {
        if (pathname) {
            const tab = tabsList.find(d => d?.url === pathname);
            switch (tab.id) {
                case "performanceFunction":
                    handleTabchange(tab.id);
                    break;
                case "performanceArea":
                    handleTabchange(tab.id);
                    break;
                case "profile":
                    handleTabchange(tab.id);
                    break;
                case "table":
                    handleTabchange(tab.id);
                    break;
                case "table-progress":
                    handleTabchange(tab.id);
                    break;
                default:
                    break;
            }
        }
    }, [pathname]);
    return (
        <>
            <div style={{ paddingBottom: 20 }}>
                <HeaderList
                    title={state?.data_page?.page_title || ''}
                    someText={state?.data_page?.page_breadcrumbs || ''}
                    optionList={[
                        {
                            tabName: "performanceFunction",
                            withLabel: true,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                            option: state.option_year,
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "performanceFunction",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                        {
                            tabName: "performanceArea",
                            withLabel: true,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                            option: state.option_year,
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "performanceArea",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                        {
                            tabName: "profile",
                            withLabel: true,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI : ",
                            option: state.option_year,
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "table",
                            withLabel: true,
                            option: state.option_year,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI ",
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "table",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                        {
                            tabName: "table-progress",
                            withLabel: true,
                            option: state.option_year,
                            label: "Progress kinerja dan anggaran proyek-proyek fungsi RTI ",
                            value: tahun,
                            onChange: (val) => setTahun(val)
                        },
                        {
                            tabName: "table-progress",
                            withLabel: false,
                            option: month,
                            value: bulan,
                            onChange: (val) => setBulan(val)
                        },
                    ].filter(op => op.tabName === (tabState?.id || ''))}
                >
                </HeaderList>
                <Tab
                    withCard={false}
                    itemsTab={[...(tabsList || [])].map(d => ({
                        id: d?.id,
                        title: d?.title,
                        onClick: () => history.push({
                            pathname: d?.url,
                            param: { tahun, bulan }
                        })
                    }))}
                    manualActive={tabState?.id}
                >
                    <TabContent id="performanceFunction">
                        <TabPerformance byParam='function'></TabPerformance>
                    </TabContent>
                    <TabContent id="performanceArea">
                        <TabPerformance byParam='area'></TabPerformance>
                    </TabContent>
                    <TabContent id="profile">
                        <TabProfile></TabProfile>
                    </TabContent>
                    <TabContent id="table">
                        <TabTable></TabTable>
                    </TabContent>
                    <TabContent id="table-progress">
                        <TabTableProgress></TabTableProgress>
                    </TabContent>
                </Tab>
            </div>
        </>
    )
});
export default (props) => <MonProvider><Monitoring /></MonProvider>;