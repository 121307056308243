import React from "react";
import './style.css';


function Index({ data = [], titleField = "", renderItem = (item) => <></> }) {
    return (
        <div className="px-3">
        <div className="row mb-2 mt-5">
            <div className="col-md-6 border-bottom px-0"><b>Milestone Summary</b></div>
        </div>
        <div className="row mt-5">
            {
                data.map((d, i) => (
                    <div className="col-12 col-md col-lg mb-3 px-0" key={i}>
                        <div className={`text-white text-center ${i === 0 ? 'left-style-ms' : 'right-style-ms'}`}>{d[titleField]}</div>
                        <div className="content-ms">{renderItem(d)}</div>
                    </div>
                ))
            }
        </div>
        </div>
    );
}

export default Index;