import React, { memo, useEffect, useState } from 'react';
import { HeaderList, Modal, CardBerita, PopularCard, PaginationCs } from '../../components';
import Card from 'react-bootstrap/Card'
import TabTable from './tabTable';
import TabDashboard from './tabDashboard';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';
import { achievement_level, generate_year } from '../../components/utility/utility';
import {
    Button,
} from "react-bootstrap";
import { pathImage } from '../../app.config';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default memo(function () {
    const { pathname, param } = useLocation();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [level, setLevel] = useState('');
    const [keyword_search, setkeyword_search] = useState('');
    const [year_search, setyear_search] = useState('');
    const [data_page, setData_page] = useState({});
    useEffect(() => {
        getData(keyword_search);
    }, [level, year_search])
    function getData(keyword_search) {
        action.getData("/api/people-achievement/table-search", {
            level_search: level,
            owner_search: '',
            year_search: year_search,
            keyword_search: keyword_search
        }, null, 'loadingData', true).then((resp) => {
            if (resp.code === '00') {
                setData_page(resp.data_page);
            }
        })
    }
    function onEnterx(e) {
        if (e?.key === 'Enter') {
            setkeyword_search(e.target.value);
            getData(keyword_search);
        }
    }
    return (
        <div>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            >
            </HeaderList>
            <Card
                style={{ backgroundColor: '#C4E0FF' }}
                className="m-0 p-0  mb-5 customShadow header_achievement_display"
            >
                <Card.Body className="p-5">
                    <div className="row mb-5">
                        <div className="col-md-12 col-lg-6 col-xl-2 mb-5">
                            <select className="form-control form-control-sm" name="level_search" value={year_search} onChange={(e) => setyear_search(e.target.value)}>
                                <option value="">-Semua Tahun-</option>
                                {
                                    [...(generate_year() || [])].map((d, i) => (
                                        <option key={i} value={d?.value}>{d?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-4 mb-5">
                            <div className="formSearch">
                                <input className="form-control form-control-sm" placeholder="Cari Achievement"
                                    //value={keyword_search} onChange={(e) => setkeyword_search(e.target.value)}
                                    onKeyUp={(e) => onEnterx(e)}
                                />
                                <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-auto">
                            <span>
                                Level :&nbsp;&nbsp;
                            </span>
                        </div>
                        <div className='col'>
                            <button className={`btn btn-sm mr-2 mb-2 ${level === '' ? 'btn-primary' : 'btn-light'} mr-2`} onClick={() => setLevel('')}>
                                Semua Level
                            </button>
                            <button className={`btn btn-sm mr-2 mb-2 ${level === 'Internal' ? 'btn-primary' : 'btn-light'} mr-2`} onClick={() => setLevel('Internal')}>
                                Internal
                            </button>
                            <button className={`btn btn-sm mr-2 mb-2 ${level === 'National' ? 'btn-primary' : 'btn-light'} mr-2`} onClick={() => setLevel('National')}>
                                Nasional
                            </button>
                            <button className={`btn btn-sm mr-2 mb-2 ${level === 'International' ? 'btn-primary' : 'btn-light'} mr-2`} onClick={() => setLevel('International')}>
                                Internasional
                            </button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className='row'>
                <div className='col-md-12'>
                    {
                        state.loadingData ?
                            <div className="card-columns px-1 my-1">
                                {
                                    [1, 2, 3].map(d => (
                                        <div>
                                            <Card
                                                className="p-0 customShadow"
                                            >
                                                <Card.Body className="p-5">
                                                    <div>
                                                        <div className="card card-custom overlay my-5">
                                                            <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                                <Skeleton height={150} />
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <h5><Skeleton /></h5>
                                                        </p>
                                                        <p><Skeleton /></p>
                                                        <div>
                                                            <Skeleton />
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <p>
                                                            <Skeleton />
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))
                                }
                            </div> :
                            <PaginationCs
                                data={[...(state.data_table || [])]}
                                dataPerPage={10}
                                RenderContainer={({ children }) => (
                                    <div className="card-columns px-1 my-1">
                                        {children}
                                    </div>
                                )}
                                renderComponent={(d, i) => (
                                    <Card
                                        className="p-0 mb-5 customShadow"
                                    >
                                        <Card.Body className="p-4">
                                            <div className="col-md-12">
                                                <div className="card card-custom overlay">
                                                    <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                        <div className="overlay-wrapper">
                                                            <LazyLoadImage
                                                                height={200}
                                                                width={'100%'}
                                                                effect='blur'
                                                                className="border image_card"
                                                                onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                                                src={d.photo_location}
                                                            />
                                                        </div>
                                                        <div class="overlay-layer">
                                                            <Link to={`/People/achievement-detail-public/${d.id}`}>
                                                                <button className="btn btn-sm btn-primary"><i className="fa fa-search"></i>
                                                                    Lihat Detail
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <Link className="textTitleAchievement" to={`/People/achievement-detail-public/${d.id}`}>
                                                        <h5>{d.name}</h5>
                                                    </Link>
                                                </p>
                                                <p>{d.description}</p>
                                                <div>
                                                    {achievement_level(d.level)}
                                                </div>
                                                <br />
                                                <br />
                                                <p>
                                                    {d.year_of_achievement}
                                                </p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )}
                            />
                    }
                </div>
            </div>
        </div>
    )
})