import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ChartDx, SwitchBtn, ExportPdf } from '../../components'
import { useGlobalState } from '../../contexts/useGlobalState'
import Card from 'react-bootstrap/Card'
import Skeleton from 'react-loading-skeleton';
import SVG from "react-inlinesvg";
import { useHistory, Link, useLocation } from 'react-router-dom';
// import moment from 'moment';
import { toString } from 'lodash';
import Swal from 'sweetalert2';
import useMonitoring from './useMonitoring';

// const performBy = [{ name: 'Function', value: 'function' }, { name: 'Research Area', value: 'area' }]
const month = [{ name: 'JAN', value: '1' }, { name: 'FEB', value: '2' }, { name: 'MAR', value: '3' }, { name: 'APR', value: '4' },
{ name: 'MAY', value: '5' }, { name: 'JUN', value: '6' }, { name: 'JUL', value: '7' }, { name: 'AUG', value: '8' }, { name: 'SEP', value: '9' },
{ name: 'OCT', value: '10' }, { name: 'NOV', value: '11' }, { name: 'DEC', value: '12' }]


const TabPerformance = function ({ reporting = false, byParam = 'function' }) {
    const { param } = useLocation();
    const [ky, setKy] = useState(param?.function_id || 0);
    const [by,] = useState(byParam);
    // const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [scurve1, setScurve1] = useState(true);
    const [scurve2, setScurve2] = useState(true);
    const [sName, setSName] = useState("RTI");
    const refEx = useRef();
    var work = {
        early: 0,
        ontime: 0,
        delayed: 0,
        finished: 0,
        stopped: 0
    };
    var budget = {
        under: 0,
        within: 0,
        over: 0,
        stopped: 0
    }
    const {
        monState: {
            tahun,
            bulan,
        }
    } = useMonitoring();

    useEffect((e) => {
        if (byParam === 'function') {
            getDataByFunction(ky);
        }
        if (byParam === 'area') {
            getDataByArea(ky);
        }
    }, [ky, tahun, bulan])

    const getDataByFunction = useCallback((id) => {
        var url = '/api/project-ongoing/dashboard-performance-by-function-by-id';
        if (reporting) url = '/api/project-ongoing/dashboard-performance-by-function-by-id-3';
        action.getData(url, {
            idFunction: id || '0',
            tahun: tahun,
            bulanGet: bulan
        }, null, "loadingAll", true).then((resp) => {
            if (resp.code === '00') {
                const option_year = (resp?.data?.data_year_portfolio || [])?.map(e => ({ name: e?.idYear, value: e?.idYear }))
                dispatch({
                    type: 'getSuccess',
                    payload: {
                        option_year,
                        data_page: resp.data_page,
                        loadingAll: false
                    }
                })
            }
        })
            .catch((err) => Swal.fire({
                title: 'Error',
                icon: 'error',
                text: err
            }))
    }, [reporting, tahun, bulan]);

    const getDataByArea = useCallback((id) => {
        var url = '/api/project-ongoing/dashboard-performance-by-area-by-id';
        if (reporting) url = '/api/project-ongoing/dashboard-performance-by-area-by-id-3';
        action.getData(url, {
            idArea: id || '0',
            tahun: tahun,
            bulanGet: bulan
        }, null, "loadingAll", true).then((resp) => {
            if (resp.code === '00') {
                const option_year = (resp?.data?.data_year_portfolio || [])?.map(e => ({ name: e?.idYear, value: e?.idYear }))
                dispatch({
                    type: 'getSuccess',
                    payload: {
                        option_year,
                        data_page: resp.data_page,
                        loadingAll: false
                    }
                })
            }
        })
            .catch((err) => Swal.fire({
                title: 'Error',
                icon: 'error',
                text: err
            }))
    }, [reporting, state]);

    function functionChange(val) {
        setKy(val)
        if (state.data_HeaderMonitoring) {
            var x = state.data_HeaderMonitoring.find(s => (by === "area" ? s.area_id : s.function_id) === val)
            if (x) {
                setSName(by === "area" ? x.name : x.short_name)
            } else {
                setSName("RTI")
            }
        }
    }
    function perform() {
        var actual_tot = 0
        var plan_tot = 0
        var perform = 0
        if (state.data_HeaderMonitoring) {
            state.data_HeaderMonitoring.forEach(e => {
                actual_tot += e.actual_tot;
                plan_tot += e.plan_tot;
            });
        }
        if (plan_tot !== 0) {
            perform = actual_tot / plan_tot;
        } else {
            perform = 0;
        }
        return perform
    }
    function performAll() {
        var actual_tot = 0
        var plan_tot = 0
        var performAll = 0
        var plan_dec = 0
        if (state.data_HeaderMonitoring) {
            state.data_HeaderMonitoring.forEach(e => {
                actual_tot += e.actual_tot;
                plan_tot += e.plan_tot;
                plan_dec += e.plan_dec;
            });
        }
        if (plan_tot !== 0) {
            performAll = actual_tot / plan_dec;
        } else {
            performAll = 0;
        }
        return performAll
    }
    function dsCurve1() {
        var ds = [
            { arg: "JAN", name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", name: "dec", plan: null, actual: null, perform: null },
        ]
        if (state.data_SCurve1) {
            Object.keys(state.data_SCurve1).map((key) => {
                var tempKey = key.substr(0, key.indexOf('_'))
                var tempName = key.substr(key.indexOf('_') + 1, key.length)
                if (tempKey === "plan") {
                    ds.find(e => e.name === tempName).plan = state.data_SCurve1[key]
                } else if (tempKey === "actual") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= state.data_bulan_default) {
                        ds.find(e => e.name === tempName).actual = state.data_SCurve1[key]
                    }
                } else if (tempKey === "perform") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= state.data_bulan_default) {
                        ds.find(e => e.name === tempName).perform = Number(parseFloat(state.data_SCurve1[key] * 100).toFixed(1))
                    }
                }
            })
        }
        return ds
    }
    function dsCurve2() {
        var ds = [
            { arg: "JAN", name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", name: "dec", plan: null, actual: null, perform: null },
        ]
        if (state.data_SCurve2) {
            Object.keys(state.data_SCurve2).map((key) => {
                var tempKey = key.substr(0, key.indexOf('_'))
                var tempName = key.substr(key.indexOf('_') + 1, key.length)
                if (tempKey === "plan") {
                    var index = ds.findIndex(e => e.name === tempName)
                    if (index !== -1) {
                        ds[index].plan = Math.round(state.data_SCurve2[key]);
                    }
                } else if (tempKey === "actual") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= state.data_bulan_default) {
                        var index = ds.findIndex(e => e.name === tempName)
                        if (index !== -1) {
                            ds[index].actual = Math.round(state.data_SCurve2[key])
                        }
                    }
                } else if (tempKey === "perform") {
                    if (month.find(r => r.name === tempName.toUpperCase())?.value <= state.data_bulan_default) {
                        var index = ds.findIndex(e => e.name === tempName)
                        if (index !== -1) {
                            ds[index].perform = Math.round(state.data_SCurve2[key] * 100)
                        }
                    }
                }
            })
        }
        return ds
    }
    function dsWorkReal() {
        var ds = []
        if (by === "area") {
            if (state.data_WorkRealizationRes) {
                ds = [...ds, ...[...(state.data_WorkRealizationRes || [])].map(d => ({ ...d, short_name: d?.name }))];
            }
            if (ky === 0 && state.data_WorkRealizationNonRes) {
                ds = [...ds, ...[...(state.data_WorkRealizationNonRes || [])].map(d => ({ ...d, short_name: 'Operational' }))];
            }
            if (ky === 100 && state.data_WorkRealizationNonRes) {
                ds = [...ds, ...[...(state.data_WorkRealizationNonRes || [])].map(d => ({ ...d, short_name: 'Operational' }))];
            }
        } else {
            ds = [...(state.data_WorkRealizationTotal || [])]
        }
        return ds.reverse()
    }
    function dsBgReal() {
        var ds = []
        if (by === "area") {
            if (state.data_BudgetRealizationRes) {
                ds = [...ds, ...[...(state.data_BudgetRealizationRes || [])].map(d => ({ ...d, category: d?.name }))]
            }
            if (ky === 0 && state.data_BudgetRealizationNonRes) {
                ds = [...ds, ...[...(state.data_BudgetRealizationNonRes || [])].map(d => ({ ...d, category: 'Operational' }))]
            }
            if (ky === 100 && state.data_BudgetRealizationNonRes) {
                ds = [...ds, ...[...(state.data_BudgetRealizationNonRes || [])].map(d => ({ ...d, category: 'Operational' }))]
            }
        } else {
            ds = [...ds, ...[...(state.data_BudgetRealizationTotal || [])].map(d => ({ ...d, category: d?.short_name }))]
        }
        ds.forEach((d, i) => {
            budget.under += d.under
            budget.within += d.within
            budget.over += d.over_
            budget.stopped += d.stopped
        })
        return ds.reverse()
    }
    function dsSpend() {
        var ds = []
        if (by === "area") {
            if (state.data_SpendYearToDateRes2) {
                ds = [
                    ...ds,
                    ...state.data_SpendYearToDateRes2.map((element, i) => {
                        return {
                            id: ky === 0 ? element.area_id : element.research_category_id,
                            category: element.name,
                            actual_budget: null,
                            remain_budget: null,
                        }
                    })
                ]
            }
            if (ky === 0 || ky === 100) {
                ds = [
                    ...ds,
                    {
                        id: 100,
                        category: "Operational",
                        actual_budget: null,
                        remain_budget: null,
                    }
                ]
            }
            if (state.data_SpendYearToDateRes) {
                [...(state.data_SpendYearToDateRes || [])].forEach((e, i) => {
                    var index = ds.findIndex(r => r.category === e.name)
                    if (index >= 0) {
                        ds[index].actual_budget = parseFloat(parseFloat(e.actual_budget || 0).toFixed(1));
                        ds[index].remain_budget = parseFloat(parseFloat(e.remain_budget || 0).toFixed(1));
                    }
                })
            }
            if (ky === 0 && state.data_SpendYearToDateNonRes) {
                [...(state.data_SpendYearToDateNonRes || [])].forEach((e, i) => {
                    var index = ds.findIndex(r => r.category === "Operational")
                    if (index >= 0) {
                        ds[index].actual_budget = parseFloat(parseFloat(e.actual_budget || 0).toFixed(1));
                        ds[index].remain_budget = parseFloat(parseFloat(e.remain_budget || 0).toFixed(1));
                    }
                })
            }
            if (ky === 100 && state.data_SpendYearToDateNonRes2) {
                [...(state.data_SpendYearToDateNonRes2 || [])].forEach((e, i) => {
                    var index = ds.findIndex(r => r.category === "Operational")
                    if (index >= 0) {
                        ds[index].actual_budget = parseFloat(parseFloat(e.actual_budget || 0).toFixed(1));
                        ds[index].remain_budget = parseFloat(parseFloat(e.remain_budget || 0).toFixed(1));
                    }
                })
            }
        } else {
            if (state.data_SpendYearToDate2) {
                ds = [
                    ...ds,
                    ...state.data_SpendYearToDate2.map((element, i) => {
                        return {
                            id: ky === 0 ? element.function_id : element.chief_id,
                            category: element.short_name,
                            actual_budget: null,
                            remain_budget: null,
                        }
                    })
                ]
            }
            if (state.data_SpendYearToDate) {
                [...(state.data_SpendYearToDate || [])].forEach((d, i) => {
                    var index = ds.findIndex(r => r.category === d.short_name)
                    if (index >= 0) {
                        ds[index].actual_budget = parseFloat(parseFloat(d.actual_budget || 0).toFixed(1));
                        ds[index].remain_budget = parseFloat(parseFloat(d.remain_budget || 0).toFixed(1));
                    }
                })
            }
        }
        return ds.map(d => ({ ...d, _total: 0 })).sort((a, b) => b.id - a.id)
    }
    function getperformance() {
        var text = ""
        var early = 0
        var ontime = 0
        var delayed = 0
        var finished = 0
        var stopped = 0
        var actual_budget = 0;
        var plan_budget = 0;
        var perform_budget = 0;
        if (by === "area") {
            if (state.data_WorkRealizationRes) {
                state.data_WorkRealizationRes.forEach((e, i) => {
                    early += e.early;
                    ontime += e.ontime;
                    delayed += e.delayed;
                    finished += e.finished;
                    stopped += e.stopped;
                })
            }
            if ((ky === 0 || ky === 100) && state.data_WorkRealizationNonRes) {
                state.data_WorkRealizationNonRes.forEach((e, i) => {
                    early += e.early;
                    ontime += e.ontime;
                    delayed += e.delayed;
                    finished += e.finished;
                    stopped += e.stopped;
                })
            }
            if (state.data_SpendYearToDateRes2) {
                state.data_SpendYearToDateRes2.forEach((e, i) => {
                    actual_budget += e.actual_budget;
                    plan_budget += e.plan_budget;
                })
                if (plan_budget !== 0) {
                    perform_budget = (actual_budget) / (plan_budget);
                }
            }
            if ((ky === 0 || ky === 100) && state.data_SpendYearToDateNonRes2) {
                state.data_SpendYearToDateNonRes2.forEach((e, i) => {
                    actual_budget += e.actual_budget;
                    plan_budget += e.plan_budget;
                })
                if (plan_budget !== 0) {
                    perform_budget = (actual_budget) / (plan_budget);
                }
            }
            text = (parseFloat(perform_budget * 100).toFixed(1)) + "% / " + (early + ontime + delayed + finished + stopped)
        } else {
            if (state.data_WorkRealizationTotal) {
                state.data_WorkRealizationTotal.forEach((e, i) => {
                    early += e.early;
                    ontime += e.ontime;
                    delayed += e.delayed;
                    finished += e.finished;
                    stopped += e.stopped;
                })
            }

            if (state.data_SpendYearToDate2) {
                state.data_SpendYearToDate2.forEach((e, i) => {
                    actual_budget += e.actual_budget;
                    plan_budget += e.plan_budget;
                })
                if (plan_budget !== 0) {
                    perform_budget = (actual_budget) / (plan_budget);
                }
            }
            text = (parseFloat(perform_budget * 100).toFixed(1)) + "% / " + (early + ontime + delayed + finished + stopped)
        }
        work.early = early;
        work.ontime = ontime;
        work.delayed = delayed;
        work.finished = finished;
        work.stopped = stopped;
        return text
    }
    function getSpandBudget() {
        var actual_budget = 0;
        var perform_budget = 0;
        var plan_tot = 0;
        if (by === "area") {
            if (state.data_SpendYearToDateRes) {
                [...(state.data_SpendYearToDateRes || [])].forEach((e, i) => {
                    actual_budget += e.actual_budget || 0;
                    plan_tot += e.plan_tot || 0;
                })
            }
            if ((ky === 0 || ky === 100) && state.data_SpendYearToDateNonRes) {
                [...(state.data_SpendYearToDateNonRes || [])].forEach((e, i) => {
                    actual_budget += e.actual_budget || 0;
                    plan_tot += e.plan_tot || 0;
                })
            }
            // if (ky === 100 && state.data_SpendYearToDateNonRes2) {
            //     state.data_SpendYearToDateNonRes2.map((e, i) => {
            //         actual_budget += e.actual_budget || 0;
            //         plan_tot += e.plan_tot || 0;
            //     })
            // }
            if (plan_tot !== 0) {
                perform_budget = (actual_budget) / (plan_tot);
            } else {
                perform_budget = 0;
            }
        } else {
            if (state.data_SpendYearToDate) {
                [...(state.data_SpendYearToDate || [])].map((d, i) => {
                    actual_budget += d.actual_budget || 0;
                    plan_tot += d.plan_tot || 0;
                })
            }
            if (plan_tot !== 0) {
                perform_budget = (actual_budget) / (plan_tot);
            } else {
                perform_budget = 0;
            }
        }
        return (
            <>
                <span className="d-block text-center" style={{ fontSize: 9 }}>
                    {sName} : {parseFloat(perform_budget * 100).toFixed(1)} %
                </span>
                <span className="d-block text-center" style={{ fontSize: 12 }}>
                    $ {parseFloat(actual_budget).toFixed(1)} / $ {parseFloat(plan_tot).toFixed(1)}
                </span>
            </>
        )
    }
    function generateName() {
        var name = 'RTI'
        if (ky !== 0) {
            if (byParam === 'area') {
                name = [...(state.data_HeaderMonitoring || [])].find(d => d?.area_id === ky)?.name + ' '
            } else {
                name = [...(state.data_HeaderMonitoring || [])].find(d => d?.function_id === ky)?.short_name + ' '
            }
        }
        return name;
    }
    return (
        <>
            {
                reporting && (
                    <div className="row mb-5">
                        <div className="col-md-12 text-right">
                            <ExportPdf
                                targetRef={refEx}
                                fileName={`Dashboard Report Performance by ${byParam === 'area' ? 'Area' : 'Function'} ${generateName()}-${state.data_bulan_default}-${state.data_tahun_default}`}
                                scale={0.8}
                            >
                                <button className="btn btn-sm btn-primary">
                                    Export PDF
                                </button>
                            </ExportPdf>
                        </div>
                    </div>
                )
            }
            <div ref={refEx}>
                {
                    reporting && (
                        <>
                            <div className='row mb-5'>
                                <div className='col-6'>
                                    <h5 className='font-bold'>Dashboard Report</h5>
                                </div>
                                <div className='col-6 text-right'>
                                    {
                                        by === "area" && (
                                            <span className='font-bold'>
                                                {
                                                    ky === 0 ? "RTI " : [...(state.data_HeaderMonitoring || [])].find(d => d?.area_id === ky)?.name + ' '
                                                }
                                                - <span className='font-bold'>{`${month.find(d => d.value === toString(bulan))?.name || ""} ${(tahun) || ""}`.toUpperCase()}</span>
                                            </span>
                                        )
                                    }
                                    {
                                        by === "function" && (
                                            <span className='font-bold'>
                                                {
                                                    ky === 0 ? "RTI " : [...(state.data_HeaderMonitoring || [])].find(d => d?.function_id === ky)?.short_name + ' '
                                                }
                                                - <span className='font-bold'>{`${month.find(d => d.value === toString(bulan))?.name || ""} ${(tahun) || ""}`.toUpperCase()}</span>
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <hr />
                        </>
                    )
                }
                <Card
                    className="m-0 p-0  mb-5 customShadow bg-primary"
                >
                    <Card.Body className="p-3">
                        <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                            <div className="col-lg-auto col-md-auto col-sm-6 col-6 mb-3">
                                <div className="d-flex border-right-custom pr-3">
                                    <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Project</span>
                                        <span className="d-block"><strong>{(state.data_JumlahProject?.total || 0) - (state.data_JumlahProject?.continue_request || 0)}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Project Running</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.project_running || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Finished</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.finished || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Aborted</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.aborted || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Review</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.review || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Need Revision</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.need_revision || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                        </div>
                                    </div>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Not Yet Reported</span>
                                        <span className="d-block"><strong>{state.data_JumlahProject?.project_continue || 0}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                                <div className="d-flex border-left-custom border-right-custom px-md-3">
                                    <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                        <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                    </span>
                                    <div className="pl-3 text-white">
                                        <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                        <span className="d-block">$ <strong>{(state.data_JumlahProject?.total_plan_budget || 0)}/{(state.data_JumlahProject?.total_actual_budget || 0)}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto col-md-auto col-sm-6 col-6 mb-3">
                                <div className="text-white rounded p-2 d-flex flex-column" style={{ backgroundColor: '#F6406A' }}>
                                    <span className="ctsmFilterSubTitle text-subtittle-card-1 d-flex" >
                                        Need Attention
                                        <Link
                                            to={`/ongoing/table-detail-search/${state?.data_tahun_default}/${state?.data_bulan_default}?q=${btoa('3/0/0/0/1')}`}
                                            target={"_blank"}>
                                            <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                        </Link>
                                    </span>
                                    <span className="ctsmFilterTitle text-tittle-card mt-auto">{state.data_need_attention || 0}</span>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card
                    className="m-0 p-0 h-100 mb-5 shadow"
                >
                    <Card.Body className="p-2">
                        <div className="row mb-5">
                            <div className="col-md-12 m-0">
                                <div className="overflow-auto">
                                    <table className="table m-0 cstm-perform-table">
                                        <tbody>
                                            <tr className="overflow-auto">
                                                {
                                                    state.loadingAll ? (
                                                        <>
                                                            <td><Skeleton height={100} /></td>
                                                            <td><Skeleton height={100} /></td>
                                                            <td><Skeleton height={100} /></td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td key={-1}>
                                                                <Card
                                                                    style={{ cursor: 'pointer' }}
                                                                    key={-1}
                                                                    onClick={(e) => functionChange(0)}
                                                                    className={0 === ky ? 'cstm-Active customShadow m-0 p-0 h-100' : 'cstm customShadow m-0 p-0 h-100'}
                                                                >
                                                                    <Card.Body className="p-2">
                                                                        <div className="justify-content-center">
                                                                            <span className="d-flex font-weight-bold" style={{ fontSize: '12px' }}>RTI</span>
                                                                            <div className="col"><h5 style={{ textAlign: 'end' }}><strong>{parseFloat((state.data_HeaderMonitoring ? perform() : 0) * 100).toFixed(1)}</strong>%</h5></div>
                                                                            <div className="progress border border-primary" style={{ marginLeft: '10px', backgroundColor: 'white', marginRight: '10px' }}>
                                                                                <div className="progress-bar" role="progressbar" style={{ width: parseFloat((state.data_HeaderMonitoring ? performAll() : 0) * 100).toFixed(1) + '%' }} aria-valuenow={parseFloat((state.data_HeaderMonitoring ? performAll() : 0) * 100).toFixed(1)} aria-valuemin="0" aria-valuemax={parseFloat((state.data_HeaderMonitoring ? perform() : 0) * 100).toFixed(1)}></div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between">
                                                                                <span style={{ textAlign: 'start', paddingLeft: '10px', paddingTop: '7px' }}>{parseFloat((state.data_HeaderMonitoring ? performAll() : 0) * 100).toFixed(1)}%</span>
                                                                                <span className="text-right" style={{ textAlign: 'end', paddingRight: '10px', paddingTop: '7px' }}>{state.data_tahun_default}</span>
                                                                            </div>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </td>
                                                            {
                                                                [...(state.data_HeaderMonitoring || [])].map((d, i) => (
                                                                    <td key={i}>
                                                                        <Card
                                                                            style={{ cursor: 'pointer' }}
                                                                            key={i}
                                                                            onClick={(e) => functionChange(by === "area" ? d.area_id : d.function_id)}
                                                                            className={(by === "area" ? d.area_id : d.function_id) === ky ? 'cstm-Active customShadow m-0 p-0 h-100' : 'cstm customShadow m-0 p-0 h-100'}
                                                                        >
                                                                            <Card.Body className="p-2">
                                                                                <div className="justify-content-center">
                                                                                    <span className="d-flex font-weight-bold" style={{ fontSize: '12px' }}>{by === "area" ? d?.name : d.short_name}</span>
                                                                                    <div className="col"><h5 style={{ textAlign: 'end' }}><strong>{parseFloat((d.perform * 100)).toFixed(1)}</strong>%</h5></div>
                                                                                    <div className="progress border border-primary" style={{ marginLeft: '10px', backgroundColor: 'white', marginRight: '10px' }}>
                                                                                        <div className="progress-bar" role="progressbar" style={{ width: (parseFloat((d.perform_all * 100)).toFixed(1)) + '%' }} aria-valuenow={parseFloat((d.perform_all * 100)).toFixed(1)} aria-valuemin="0" aria-valuemax={parseFloat((d.perform * 100)).toFixed(1)}></div>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <span style={{ textAlign: 'start', paddingLeft: '10px', paddingTop: '7px' }}>{parseFloat((d.perform_all * 100)).toFixed(1)}%</span>
                                                                                        <span className="text-right" style={{ textAlign: 'end', paddingRight: '10px', paddingTop: '7px' }}>{state.data_tahun_default}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </td>
                                                                ))
                                                            }
                                                        </>
                                                    )
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-md-6 mb-5">
                                <div className="myContainer">
                                    <div className="row mr-2 ml-2">
                                        <div className="col-md-12">
                                            <div className="row m-0">
                                                <div className="col-10 p-0">
                                                    <span className="ctsmFilterTitle">S-Curve RTI Projects</span>
                                                    <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI</span>
                                                </div>
                                                <div className="col-2" style={{ paddingRight: 0 }}>
                                                    <SwitchBtn value={scurve1} onChange={(e) => setScurve1(e)} />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row ">
                                                <div className="col-md-12">
                                                    {React.useMemo(() => (
                                                        <ChartDx
                                                            loading={state.loadingAll || state.loadingSelect}
                                                            dataSource={state.data_SCurve1 ? dsCurve1() : null}
                                                            type="spline"
                                                            withTitle={false}
                                                            showLegend={true}
                                                            toolsExport={false}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onRenderLabelChart={function (e) { }}
                                                            onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: ${e.valueText}` })}
                                                            yAxisArgs={scurve1 ? [

                                                                {
                                                                    name: "Plan",
                                                                    valueField: "plan",
                                                                    color: '#D1D1D1'
                                                                },
                                                                {
                                                                    name: "Realisasi",
                                                                    valueField: "actual",
                                                                    color: '#326BD5'
                                                                }

                                                            ] : [
                                                                {
                                                                    name: "Perform",
                                                                    valueField: "perform",
                                                                    color: 'red'
                                                                }
                                                            ]}
                                                        >

                                                        </ChartDx>
                                                    ), [state.loadingAll, state.loadingSelect, scurve1])}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="myContainer">
                                    <div className="row mr-2 ml-2">
                                        <div className="col-md-12">
                                            <div className="row m-0">
                                                <div className="col-10 p-0">
                                                    <span className="ctsmFilterTitle">S-Curve RTI Budget</span>
                                                    <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan pragnosis proyek proyek RTI (Ribu USD)</span>
                                                </div>
                                                <div className="col-2" style={{ paddingRight: 0 }}>
                                                    <SwitchBtn value={scurve2} onChange={(e) => setScurve2(e)} />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        React.useMemo(() => (
                                                            <ChartDx
                                                                loading={state.loadingAll || state.loadingSelect}
                                                                dataSource={state.data_SCurve2 ? dsCurve2() : null}
                                                                type="spline"
                                                                withTitle={false}
                                                                showLegend={true}
                                                                toolsExport={false}
                                                                LegendverticalAlignment="bottom"
                                                                LegendhorizontalAlignment="center"
                                                                LegenditemTextPosition="right"
                                                                onRenderLabelChart={function (e) { }}
                                                                onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: $ ${e.valueText}` })}
                                                                yAxisArgs={scurve2 ? [
                                                                    {
                                                                        name: "Proposed Budget",
                                                                        valueField: "plan",
                                                                        color: '#D1D1D1'
                                                                    },
                                                                    {
                                                                        name: "Realisasi",
                                                                        valueField: "actual",
                                                                        color: '#F08044'
                                                                    }

                                                                ] : [
                                                                    {
                                                                        name: "Perform",
                                                                        valueField: "perform",
                                                                        color: 'red'
                                                                    }
                                                                ]}
                                                            >

                                                            </ChartDx>
                                                        ), [state.loadingAll, state.loadingSelect, scurve2])
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card >
                <Card
                    className="m-0 p-0 h-100 mb-5 shadow"
                >
                    <Card.Body className="p-2">
                        <div className="row">
                            <div className="col-md-2 mb-5">
                                <div className="row h-100 m-0">
                                    <div className="col-md-12 p-0">
                                        <div className="m-portlet__head-text" style={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'Poppins' }}>
                                            <span className="ctsmFilterTitle">PERFORMANCE</span>
                                            <span className="ctsmFilterSubTitle">% Performance / Jumlah Project</span>
                                        </div>
                                        <hr />
                                        {
                                            by === "function" ? (
                                                <>
                                                    {
                                                        (state.loadingAll || state.loadingSelect) ? (
                                                            <Skeleton height={200} />
                                                        ) :
                                                            [...(state.data_SpendYearToDate2 || [])].map((d, i) => (
                                                                <div style={{ height: '35px', paddingBottom: '7px', paddingTop: '7px' }} key={i}>
                                                                    <div key={i} className="rounded" style={{ backgroundColor: '#7F37C7', color: 'white', padding: '5px', display: 'flex', justifyContent: 'space-between', height: '20px' }}>
                                                                        <span style={{ fontSize: 9 }}>
                                                                            {d.short_name}:
                                                                        </span>
                                                                        <span style={{ fontSize: 9 }}>
                                                                            {parseFloat(d.perform_budget * 100).toFixed(1)} % / {d?.jumlah_project || 0}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                    }
                                                    {
                                                        (state.loadingAll || state.loadingSelect) ? (
                                                            <Skeleton height={30} />
                                                        ) :
                                                            state.data_SpendYearToDate2 && (
                                                                <>
                                                                    <div className="mt-10" style={{ minHeight: 30 }}>
                                                                        <div className="w-100 text-white p-1 rounded" style={{ backgroundColor: '#7F37C7', minHeight: 30, position: 'absolute', bottom: 0 }}>
                                                                            <span className="d-block text-left" style={{ fontSize: 9 }}>
                                                                                {sName}
                                                                            </span>
                                                                            <span className="d-block text-right" style={{ fontSize: 12 }}>
                                                                                {Array.isArray(state.data_SpendYearToDate2) ? getperformance() : null}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        (state.loadingAll || state.loadingSelect) ? (
                                                            <Skeleton height={200} />
                                                        ) : (

                                                            <>
                                                                {
                                                                    [...(state.data_SpendYearToDateRes2 || [])].map((d, i) => (
                                                                        <div style={{ height: '35px', paddingBottom: '7px', paddingTop: '7px' }} key={i}>
                                                                            <div key={i} className="rounded" style={{ backgroundColor: '#7F37C7', color: 'white', padding: '5px', height: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                <span style={{ fontSize: 9 }}>
                                                                                    {d.name}:
                                                                                </span>
                                                                                <span style={{ fontSize: 9 }}>
                                                                                    {parseFloat(d.perform_budget * 100).toFixed(1)} % / {d?.jumlah_project || 0}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {
                                                                    (ky === 0 || ky === 100) && [...(state.data_SpendYearToDateNonRes2 || [])].map((d, i) => (
                                                                        <div style={{ height: '35px', paddingBottom: '7px', paddingTop: '7px' }} key={i}>
                                                                            <div key={i} className="rounded" style={{ backgroundColor: '#7F37C7', color: 'white', padding: '5px', height: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                <span style={{ fontSize: 9 }}>
                                                                                    Operational :
                                                                                </span>
                                                                                <span style={{ fontSize: 9 }}>
                                                                                    {parseFloat(d.perform_budget * 100).toFixed(1)} % / {d?.jumlah_project || 0}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (state.loadingAll || state.loadingSelect) ? (
                                                            <Skeleton height={30} />
                                                        ) : (
                                                            <div className="mt-10 " style={{ minHeight: 30 }}>
                                                                <div className="w-100 text-white p-1 rounded" style={{ backgroundColor: '#7F37C7', minHeight: 30, position: 'absolute', bottom: 0 }}>
                                                                    <span className="d-block text-left" style={{ fontSize: 9 }}>
                                                                        {sName}
                                                                    </span>
                                                                    <span className="d-block text-right" style={{ fontSize: 12 }}>
                                                                        {getperformance()}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 mb-5">
                                <div className="row h-100">
                                    <div className="col-md-6">
                                        <div className="row h-100 m-0 mb-5">
                                            <div className="col-12 p-0">
                                                <span className="ctsmFilterTitle text-uppercase">project performance detail</span>
                                                <span className="ctsmFilterSubTitle">Jumlah proyek berdasarkan status kinerjanya</span>
                                                <hr />
                                                {
                                                    React.useMemo(() => (
                                                        <>
                                                            {
                                                                (state.loadingAll || state.loadingSelect) ? (
                                                                    <Skeleton height={200} />
                                                                ) : (
                                                                    <ChartDx
                                                                        className="h-100"
                                                                        height={'auto'}
                                                                        barWidth={20}
                                                                        xLabel={false}
                                                                        labelChart={true}
                                                                        argumentField="short_name"
                                                                        type="stackedBar"
                                                                        dataSource={dsWorkReal()}
                                                                        withTitle={false}
                                                                        showLegend={false}
                                                                        toolsExport={false}
                                                                        LegendverticalAlignment="bottom"
                                                                        LegendhorizontalAlignment="center"
                                                                        LegenditemTextPosition="right"
                                                                        onrenderLabel={(e) => { }}
                                                                        onRenderLabelChart={(e) => { }}
                                                                        onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                                                                        rotate={true}
                                                                        yAxisArgs={[
                                                                            {
                                                                                name: "Early",
                                                                                valueField: "early",
                                                                                color: '#4C84FF'
                                                                            },
                                                                            {
                                                                                name: "Ontime",
                                                                                valueField: "ontime",
                                                                                color: '#44E08D'
                                                                            },
                                                                            {
                                                                                name: "Delayed",
                                                                                valueField: "delayed",
                                                                                color: '#FF9941'
                                                                            },
                                                                            {
                                                                                name: "Finished",
                                                                                valueField: "finished",
                                                                                color: '#BBAB41'
                                                                            },
                                                                            {
                                                                                name: "Abborted",
                                                                                valueField: "stopped",
                                                                                color: '#444444'
                                                                            }
                                                                        ]}

                                                                    />

                                                                )
                                                            }
                                                            {
                                                                (state.loadingAll || state.loadingSelect) ? (
                                                                    <Skeleton height={30} />
                                                                ) : (

                                                                    <div className="mt-10 " style={{ minHeight: 30 }}>
                                                                        <table className="table m-0 p-0 text-white" style={{ position: 'absolute', bottom: 0 }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`${1}/0/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#4C84FF' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Early :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{work.early}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`${2}/0/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#44E08D' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Ontime :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{work.ontime}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`${3}/0/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#FF9941' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Delayed :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{work.delayed}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`${4}/0/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#BBAB41' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Finished :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{work.finished}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`${5}/0/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#444444' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Stopped :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{work.stopped}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    ), [state.loadingAll, state.loadingSelect])
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row h-100 m-0 mb-5">
                                            <div className="col-12 p-0">
                                                <span className="ctsmFilterTitle text-uppercase">budget performance detail</span>
                                                <span className="ctsmFilterSubTitle">Jumlah proyek berdasarkan status anggarannya </span>
                                                <hr />
                                                {
                                                    React.useMemo(() => (
                                                        <>
                                                            {
                                                                (state.loadingAll || state.loadingSelect) ? (
                                                                    <Skeleton height={200} />
                                                                ) : (

                                                                    <ChartDx
                                                                        height={'auto'}
                                                                        barWidth={20}
                                                                        // yVisible={false}
                                                                        // xVisible={false}
                                                                        xLabel={false}
                                                                        labelChart={true}
                                                                        argumentField="category"
                                                                        type="stackedBar"
                                                                        dataSource={dsBgReal()}
                                                                        withTitle={false}
                                                                        showLegend={false}
                                                                        toolsExport={false}
                                                                        LegendverticalAlignment="bottom"
                                                                        LegendhorizontalAlignment="center"
                                                                        LegenditemTextPosition="right"
                                                                        onrenderLabel={(e) => { }}
                                                                        onRenderLabelChart={(e) => { }}
                                                                        onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                                                                        rotate={true}
                                                                        yAxisArgs={[
                                                                            {
                                                                                name: "Under Budget",
                                                                                valueField: "under",
                                                                                color: '#4C84FF'
                                                                            },
                                                                            {
                                                                                name: "Within Budget",
                                                                                valueField: "within",
                                                                                color: '#44E08D'
                                                                            },
                                                                            {
                                                                                name: "Over Budget",
                                                                                valueField: "over_",
                                                                                color: '#F6406A'
                                                                            },
                                                                            {
                                                                                name: "Stopped",
                                                                                valueField: "stopped",
                                                                                color: '#444444'
                                                                            }
                                                                        ]}

                                                                    />
                                                                )
                                                            }
                                                            {
                                                                (state.loadingAll || state.loadingSelect) ? (
                                                                    <Skeleton height={30} />
                                                                ) : (

                                                                    <div className="mt-10 " style={{ minHeight: 30 }}>
                                                                        <table className="table m-0 p-0 text-white" style={{ position: 'absolute', bottom: 0 }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`0/${1}/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#4C84FF' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Under Bdgt :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{budget.under}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`0/${2}/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#44E08D' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Within Bdgt :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{budget.within}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`0/${3}/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#F6406A' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Over Bdgt :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{budget.over}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                        <Link to={`/ongoing/table-detail-search/${state.data_tahun_default}/${state.data_bulan_default}?q=${btoa(`0/${5}/${byParam === 'function' ? ky : '0'}/${byParam === 'area' ? ky : '0'}/0`)}`} target={"_blank"}>
                                                                                            <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#444444' }}>
                                                                                                <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Stopped :</span>
                                                                                                <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{budget.stopped}</span>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    ), [state.loadingAll, state.loadingSelect])
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5">
                                <div className="row h-100 m-0">
                                    <div className="col-12 p-0">
                                        <span className="ctsmFilterTitle text-uppercase">SPEND YEAR TO DATE</span>
                                        <span className="ctsmFilterSubTitle">Realisasi dan sisa anggaran (Ribu USD)</span>
                                        <hr />
                                        {
                                            React.useMemo(() => (
                                                <>
                                                    {
                                                        (state.loadingAll || state.loadingSelect) ? (
                                                            <Skeleton height={200} />
                                                        ) : (

                                                            <ChartDx
                                                                height={'auto'}
                                                                barWidth={20}
                                                                labelChart={true}
                                                                argumentField="category"
                                                                type="stackedBar"
                                                                rotate={true}
                                                                xLabel={false}
                                                                dataSource={dsSpend()}
                                                                loading={state.loadingAll || state.loadingSelect}
                                                                withTitle={false}
                                                                showLegend={false}
                                                                toolsExport={false}
                                                                LegendverticalAlignment="bottom"
                                                                LegendhorizontalAlignment="center"
                                                                LegenditemTextPosition="right"
                                                                onrenderLabel={(e) => { }}
                                                                onRenderLabelChart={(e) => {
                                                                    if (e.seriesName === "Total") {
                                                                        return `Total : $${parseFloat(e.total).toFixed(2)}`;
                                                                    }
                                                                }}
                                                                onRenderTooltip={(e) => ({ text: `${e.argumentText} ${e.seriesName}: ${e.percentText} \r\n <b>$${e.valueText}</b>` })}
                                                                yAxisArgs={[
                                                                    {
                                                                        name: "Budget Realization",
                                                                        valueField: "actual_budget",
                                                                        color: '#ACA6EE'
                                                                    },
                                                                    {
                                                                        name: "Budget Remain",
                                                                        valueField: "remain_budget",
                                                                        color: '#716ACA'
                                                                    },
                                                                    {
                                                                        name: "Total",
                                                                        valueField: "_total",
                                                                        color: 'transparent',
                                                                        label: { visible: true, position: "outside", font: { color: '#3F4254' } }
                                                                    }
                                                                ]}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        (state.loadingAll || state.loadingSelect) ? (
                                                            <Skeleton height={30} />
                                                        ) : (
                                                            <div className="mt-10" style={{ minHeight: 30 }}>
                                                                <div className="w-100 text-white p-1 rounded" style={{ backgroundColor: '#716ACA', minHeight: 30, position: 'absolute', bottom: 0 }}>
                                                                    {getSpandBudget()}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            ), [state.loadingAll, state.loadingSelect])
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}
export default TabPerformance;