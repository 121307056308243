import React, { memo, useState, useEffect, useRef } from 'react'
import { useGlobalState } from '../../contexts/useGlobalState';
import { HeaderList, GridDx } from '../../components';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";

const columns = [
    { fieldName: "created_at", caption: "Date Created" },
    { fieldName: "title", caption: "Title", link: true, url: "/portfolio-research/view", width: 500 },
    { fieldName: "workflow_state_state", caption: "Status Workflow" },
    { fieldName: "function_name", caption: "Function" },
    { fieldName: "chief_name", caption: "Chief" },
    { fieldName: "area_name", caption: "Area" },
    { fieldName: "cat_name", caption: "Category" },
]

export default memo(function () {
    const [state, dispatch, action] = useGlobalState();
    const [year, setYear] = useState();
    const tableRef = useRef()
    const [filter, setFilter] = useState({
        fungsi_search: state.filterData.fungsi_search || "",
        chief_search: state.filterData.chief_search || "",
        area_search: state.filterData.area_search || "",
        cat_search: state.filterData.cat_search || "",
        status_workflow_search: state.filterData.status_workflow_search || "",
        project_type_search: state.filterData.project_type_search || "",
        leader_search: state.filterData.leader_search || "",
        metode_search: state.filterData.metode_search || "",
        value_creation_search: state.filterData.value_creation_search || "",
        stage_search: state.filterData.stage_search || "",
        year_start_search: state.filterData.year_start_search || "",
        year_end_search: state.filterData.year_end_search || "",
        text_search: state.filterData.text_search || ""
    })

    useEffect(() => {
        action.getData("/api/project-portfolio/table", {
            id: "",
            year_search: state.filterData.year_search || "",
            ...filter
        },null, "loadingGrid").then((resp) => {
            if (resp.code === "00") {
                getYear(resp.data.data_allyear)
            }
        })
    }, [filter, state.filterData.year_search])
    function getYear(value) {
        if (value) {
            const newDs = []
            value.map((d, i) => {
                newDs.push({
                    value: d.idYear,
                    name: d.idYear
                })
            })
            setYear(newDs)
        }
    }
    return (
        <>
            <div className="pb-5">
                <HeaderList
                    title="Portfolio Approval"
                    optionList={[{
                        withLabel: true,
                        label: "Rekapitulasi usulan riset pada tahun : ",
                        option: year || [],
                        value: state.filterData.year_search,
                        onChange: function (e) { action.setFilter("year_search", e) }
                    }]}
                    someText="Research Planning - Portfolio Research"
                ></HeaderList>
            </div>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row" style={{fontSize:'8pt'}}>
                        <div className="col-md-2">
                            <div className="d-flex border-right">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total New Ideasi</span>
                                    <span className="d-block"><strong>143</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1" style={{ width: 15, height: 15, backgroundColor: '#894CEA' }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Draft</span>
                                            <span className="d-block"><strong>143</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1" style={{ width: 15, height: 15, backgroundColor: '#006CB8' }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Waiting Approval</span>
                                            <span className="d-block"><strong>143</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Approved</span>
                                            <span className="d-block"><strong>143</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">Rejected</span>
                                            <span className="d-block"><strong>143</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex border-left pl-5">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Propose Budget (Ribu USD)</span>
                                    <span className="d-block">$ <strong>10,490.58</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12">
                            <GridDx
                                searchForm={true}
                                ref={tableRef}
                                loading={state.loadingGrid}
                                data={state.data_table}
                                columns={columns}
                                withAction={true}
                                pagination={true}
                                orderingDrag={false}
                                actions={[
                                    {
                                        name: "Edit",
                                        icon: "fa fa-pencil",
                                        action: (v, c) => { console.log(v, c) }
                                    }
                                ]}
                                currentPage
                                show
                            >
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})