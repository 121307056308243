import React, { useEffect, useState } from 'react'
import { ChartDx, CstmFilterCahrtDx, DoughnatDx, GridDx, Tab, TabContent, GaugeChart, SwitchBtn, SkeletonLoading } from '../../components'
import { Card, Col, Row } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import './people.css';
import SVG from "react-inlinesvg";
import useFetch from '../../modules/useFetch';
import Swal from 'sweetalert2';

const TabDashboard = () => {
    const [state, dispatch, action] = useGlobalState();
    const [gender, setGender] = useState({
        jml_laki: 0,
        jml_perem: 0,
        total: 0
    })
    const [showTotal, setShowTotal] = useState(false);
    const [kuota, setKuota] = useState(0);
    const [jumlah, setJumlah] = useState(0);
    const [chartRKAP, setChartRKAP] = useState([]);
    const [swRKAP, setSwRKAP] = useState(false);
    const [ftRKAP, setFtRKAP] = useState('0');
    const { doGet } = useFetch();
    const [loadingGetPosisi, setloadingGetPosisi] = useState(false);
    const [loadingChart6, setloadingChart6] = useState(false);
    const [data_chart_posisi, setdata_chart_posisi] = useState({});
    useEffect(() => {
        action.getData('/api/people-hrd/dashboard', {}, null, 'loadingAll', true).then((res) => {
            if (res.code === "00") {
                const filter = [...(res.data.data_chart2_gender || [])].filter(d => d.jenis !== null)
                const data = [
                    {
                        state: 'Gender',
                        laki_laki: filter.find(d => d.jenis === "Laki")?.jumlah || 0,
                        perempuan: filter.find(d => d.jenis === "Perempuan")?.jumlah || 0,
                    }
                ]
                const chart3 = [
                    { state: '20-25', jumlah: res.data.data_chart3_usia[0]?.usia1 || 0 },
                    { state: '26-30', jumlah: res.data.data_chart3_usia[0]?.usia2 || 0 },
                    { state: '31-35', jumlah: res.data.data_chart3_usia[0]?.usia3 || 0 },
                    { state: '36-40', jumlah: res.data.data_chart3_usia[0]?.usia4 || 0 },
                    { state: '41-45', jumlah: res.data.data_chart3_usia[0]?.usia5 || 0 },
                    { state: '46-50', jumlah: res.data.data_chart3_usia[0]?.usia6 || 0 },
                    { state: '51-55', jumlah: res.data.data_chart3_usia[0]?.usia7 || 0 },
                ]
                const chart8 = [
                    { state: '<1', jumlah: res.data.data_chart8_tahun_pengalaman[0]?.tahun1 || 0 },
                    { state: '1-2', jumlah: res.data.data_chart8_tahun_pengalaman[0]?.tahun2 || 0 },
                    { state: '2-5', jumlah: res.data.data_chart8_tahun_pengalaman[0]?.tahun3 || 0 },
                    { state: '5-10', jumlah: res.data.data_chart8_tahun_pengalaman[0]?.tahun4 || 0 },
                    { state: '>10', jumlah: res.data.data_chart8_tahun_pengalaman[0]?.tahun5 || 0 }
                ]
                const chart9 = [...new Set(res.data.data_chart9_expertise.map(d => d.nama_skill))].map((s, i) => {
                    return {
                        name: s,
                        data: [...(res.data.data_chart9_expertise || [])].filter(x => x.nama_skill === s)
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { data_chart3_usia: chart3, loadingGet: false }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { data_chart8_tahun_pengalaman: chart8, loadingGet: false }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { data_chart9_expertise: chart9, loadingGet: false }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { data_chart2_gender: data, loadingGet: false }
                })
                setGender({
                    jml_laki: filter.find(d => d.jenis === "Laki")?.jumlah || 0,
                    jml_perem: filter.find(d => d.jenis === "Perempuan")?.jumlah || 0,
                    total: (filter.find(d => d.jenis === "Laki")?.jumlah || 0) + (filter.find(d => d.jenis === "Perempuan")?.jumlah || 0)
                })
                getDataChartPosisi(0);
                getDataChartPekerjaRKAP(ftRKAP);
                action.setForm('people_page', res.data_page);
            }
        });
    }, [])
    function renderCenter(e) {
        const total = e.getAllSeries()[0].getVisiblePoints().reduce((s, p) => s + p.originalValue, 0)
        return (
            <svg>
                <text textAnchor="middle" x="100" y="120" style={{ fontSize: 18, fill: '#494949' }}>
                    <tspan x="100" style={{ fontWeight: 600 }}>{
                        total
                    }</tspan>
                    <tspan x="100" dy="20px" style={{ fontSize: '8pt' }}>Total Pekerja</tspan>
                </text>
            </svg>
        )
    }
    function renderCenterExpertise(e, title = "") {
        var textLength = title.length;
        var lastIndex = 0;
        var arrayTitle = title.split(" ");
        // while(textLength > 0){
        //     var txt = ""
        //     if(textLength > 10){
        //        txt =  title.substr(lastIndex, 10)
        //        lastIndex = lastIndex + 10;
        //        textLength = textLength - 10;
        //     }else{
        //         txt =  title.substr(lastIndex, textLength)
        //         lastIndex = lastIndex + textLength
        //         textLength = textLength - textLength;
        //     }
        //     arrayTitle.push(txt)
        // }
        // console.log(arrayTitle)
        return (
            <svg>
                <text textAnchor="middle" x="100" y="120" style={{ fontSize: 18, fill: '#494949', fontWeight: 'bold' }}>
                    {
                        arrayTitle.map((d, i) => (
                            <tspan key={i} x="100" dy="13px" className="center-text-doughnat">{d}</tspan>
                        ))
                    }
                </text>
            </svg>
        )
    }
    async function getDataChartPosisi(id) {
        setloadingGetPosisi(true)
        try {
            const resp = await doGet({ url: `/api/people-hrd/dashboard-position?func_id=${id ? id : '0'}` });
            if (resp?.code === "00") {
                const kuo = [...(resp.data.data_chart || [])].reduce((tot, val) => tot = tot + val.kuota, 0);
                const jum = [...(resp.data.data_chart || [])].reduce((tot, val) => tot = tot + val.jumlah, 0);
                setKuota(kuo);
                setJumlah(jum);
                setdata_chart_posisi({ ...resp?.data });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setloadingGetPosisi(false);
        // action.getData(`/api/people-hrd/dashboard-position?func_id=${id ? id : '0'}`, {}, 'data_chart_posisi', {}, 'loadingGetPosisi').then((resp) => {
        //     if (resp.code === '00') {
        //         const kuo = [...(resp.data.data_chart || [])].reduce((tot, val) => tot = tot + val.kuota, 0);
        //         const jum = [...(resp.data.data_chart || [])].reduce((tot, val) => tot = tot + val.jumlah, 0);
        //         setKuota(kuo);
        //         setJumlah(jum);
        //     }
        // })
    }
    async function getDataChartPekerjaRKAP(funct) {
        setFtRKAP(funct);
        setloadingChart6(true);
        try {
            const resp = await doGet({ url: `/api/people-hrd/dashboard-pekerja-rkap?func_id=${funct}` });
            if (resp?.code === "00") {
                setChartRKAP(resp.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setloadingChart6(false);
        // action.getData(`/api/people-hrd/dashboard-pekerja-rkap?func_id=${funct}`, {}, null, 'loadingChart6', true).then((resp) => {
        //     if (resp.code === '00') {
        //         setChartRKAP(resp.data);
        //     }
        // })
    }
    const legendMarkerRender = (item) => {
        const alldata = item?.series?.getVisiblePoints();
        const sumActive = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.active, 0);
        const sumPwt = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.pwt, 0);
        const sumContract = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.contract, 0);
        let display = 0;
        if (item.text === 'PWTT') display = sumActive;
        if (item.text === 'PWT') display = sumPwt;
        if (item.text === 'Mitra Kerja') display = sumContract;
        return (
            <svg>
                <rect x={0} y={0} width={80} height={20} fill={item?.marker?.fill || '#888'} rx='3'></rect>
                <text x={3} y={13} fill="white" fontSize='8pt'>{item?.text} : {display}</text>
            </svg>
        );
    }
    function legendMarkerRenderRKAP(item) {
        const alldata = item?.series?.getVisiblePoints();
        const sumActive = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.active, 0);
        const sumPwt = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.pwt, 0);
        const sumContract = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.contract, 0);
        const sumRKAP = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.jumlah_rkap, 0);
        let display = 0;
        if (item.text === 'PWTT') display = sumActive;
        if (item.text === 'PWT') display = sumPwt;
        if (item.text === 'Mitra Kerja') display = sumContract;
        if (item.text === 'Jumlah RKAP') display = sumRKAP;
        return (
            <svg>
                <rect x={0} y={0} width={100} height={20} fill={item?.marker?.fill} rx="3"></rect>
                <text x={3} y={13} fill="white" fontSize='8pt'>{item?.text} : {display}</text>
            </svg>
        );
    }
    function legendMarkerRenderPendidikan(item) {
        const alldata = item?.series?.getVisiblePoints();
        const D3 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.D3, 0);
        const S1 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.S1, 0);
        const S2 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.S2, 0);
        const S3 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.S3, 0);
        const total = [...(state.data_chart6_pendidikan || [])].reduce((tot, prev) => tot = tot + (prev?.D3 + prev?.S1 + prev?.S2 + prev?.S3), 0);
        return (
            <svg>
                <rect x={0} y={0} width={80} height={20} fill={item?.marker?.fill} rx="3"></rect>
                {
                    item?.text !== 'Total' && (
                        <text x={3} y={13} fill="white" fontSize='8pt'>
                            {item?.text} :
                            {item?.text === 'D3' ? D3 : ''}
                            {item?.text === 'S1' ? S1 : ''}
                            {item?.text === 'S2' ? S2 : ''}
                            {item?.text === 'S3' ? S3 : ''}
                        </text>
                    )
                }
                {
                    item?.text === 'Total' && (
                        <text x={3} y={13} fill="black" fontSize='8pt'>
                            {item?.text} :
                            {total}
                        </text>
                    )
                }

            </svg>
        );
    }
    function legendMarkerRenderMasihPendidikan(item) {
        const alldata = item?.series?.getVisiblePoints();
        const D3 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.D3, 0);
        const S1 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.S1, 0);
        const S2 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.S2, 0);
        const S3 = [...(alldata || [])].reduce((tot, prev) => tot = tot + prev?.data?.S3, 0);
        const total = [...(state.data_chart7_masih_pendidikan || [])].reduce((tot, prev) => tot = tot + (prev?.D3 + prev?.S1 + prev?.S2 + prev?.S3), 0);
        return (
            <svg>
                <rect x={0} y={0} width={80} height={20} opacity={1} fill={item?.marker?.fill} rx="3"></rect>
                {
                    item?.text !== 'Total' && (
                        <text x={3} y={13} fill="white" fontSize='8pt'>
                            {item?.text} :
                            {item?.text === 'D3' ? D3 : ''}
                            {item?.text === 'S1' ? S1 : ''}
                            {item?.text === 'S2' ? S2 : ''}
                            {item?.text === 'S3' ? S3 : ''}
                        </text>
                    )
                }
                {
                    item?.text === 'Total' && (
                        <text x={3} y={13} fill="black" fontSize='8pt'>
                            {item?.text} :
                            {total}
                        </text>
                    )
                }

            </svg>
        );
    }
    function legendMarkerRenderPosisi(item) {
        return (
            <>
                {
                    item?.text === "Kuota" && (
                        <svg>
                            <rect x={0} y={0} width={20} height={5} fill={item?.marker?.fill} rx="3"></rect>
                        </svg>
                    )
                }
                {
                    item?.text !== "Kuota" && (
                        <svg>
                            <rect x={0} y={0} width={13} height={13} fill={item?.marker?.fill} rx="3"></rect>
                        </svg>
                    )
                }
            </>
        );
    }
    return (
        <>
            <div className="row mt-5 mb-5">
                <div className="col-md-4 mb-5">
                    <Card
                        className="p-0 customShadow h-100"
                    >
                        <Card.Body className="p-5">
                            <div className="d-flex">
                                <i style={{ width: '40px', height: '40px' }} className="fas fa-user-friends bg-primary text-white rounded customShadow d-flex align-items-center justify-content-center"></i>
                                <div className="ml-5">
                                    <span className="d-block" style={{ fontSize: '12px' }}><b>Total Pekerja RTI</b></span>
                                    <span style={{ fontSize: '9px' }}>Statistik pekerja RTI berdasarkan area</span>
                                </div>
                            </div>
                            <hr />
                            {
                                React.useMemo(() => (
                                    <DoughnatDx
                                        loading={state.loadingAll}
                                        height={250}
                                        withTitle={false}
                                        dataSource={state.data_chart1_total_pekerja || []}
                                        showLegend={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        LegenditemTextPosition="top"
                                        toolsExport={false}
                                        centerRender={renderCenter}
                                        onRenderLabelChart={(e) => (`${e.argumentText} ${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}`)}
                                        onRenderTooltip={(e) => ({ text: `${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah",
                                                argumentField: "short_name",
                                                color: null,
                                                format: null
                                            }
                                        ]}

                                    />
                                ), [state.loadingAll, state.data_chart1_total_pekerja])
                            }
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4 mb-5">
                    <CstmFilterCahrtDx
                        title="Gender"
                        subtitle="Statistik Pekerja RTI berdasarkan gender"
                    >
                        <div className="row justify-content-center mb-3">
                            <div className="col-10">
                                <Row>
                                    <Col className="text-left" style={{ fontSize: '8pt', color: '#4191FF' }}>
                                        <span className="d-block"><b>{gender.jml_laki || 0}</b></span>
                                        Pekerja Laki-laki
                                    </Col>
                                    <Col className="text-right" style={{ fontSize: '8pt', color: '#F87CCC' }}>
                                        <span className="d-block"><b>{gender.jml_perem || 0}</b></span>
                                        Pekerja Perempuan
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-1 px-0 text-center">
                                <i className="fas fa-male icon-4x" style={{ color: '#4191FF' }}></i>
                            </div>
                            <div className="col-10">
                                {
                                    React.useMemo(() => (
                                        <ChartDx
                                            loading={state.loadingAll}
                                            height={50}
                                            id="gender"
                                            type="fullstackedbar"
                                            dataSource={[...(state.data_chart2_gender || [])]}
                                            onRenderLabelChart={(e) => { }}
                                            onrenderLabel={(e) => { }}
                                            withTitle={false}
                                            showLegend={false}
                                            toolsExport={false}
                                            argumentField="state"
                                            rotate={true}
                                            yAxisArgs={[
                                                {
                                                    name: "Laki - Laki",
                                                    valueField: "laki_laki",
                                                    color: "#4191FF"
                                                },
                                                {
                                                    name: "Perempuan",
                                                    valueField: "perempuan",
                                                    color: "#F87CCC"
                                                }
                                            ]}
                                        />
                                    ), [state.loadingAll, state.data_chart2_gender])
                                }
                            </div>
                            <div className="col-1 px-0 text-center">
                                <i className="fas fa-female icon-4x" style={{ color: '#F87CCC' }}></i>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-3">
                            <div className="col-10">
                                <Row>
                                    <Col className="text-left" style={{ fontSize: '8pt', color: '#4191FF' }}>
                                        <span className="d-block"><b>{`${parseFloat(gender.jml_laki / gender.total * 100 || 0).toFixed(2)}`} %</b></span>
                                    </Col>
                                    <Col className="text-right" style={{ fontSize: '8pt', color: '#F87CCC' }}>
                                        <span className="d-block"><b>{`${parseFloat(gender.jml_perem / gender.total * 100 || 0).toFixed(2)}`} %</b></span>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-4 mb-5">
                    <CstmFilterCahrtDx
                        title="Usia Pekerja"
                        subtitle="Statistik pekerja RTI berdasarkan usia pekerja"
                    >
                        {
                            React.useMemo(() => (
                                <ChartDx
                                    loading={state.loadingAll}
                                    id="type"
                                    argumentField="state"
                                    dataSource={state.data_chart3_usia || []}
                                    colorLabel='#181C32'
                                    withTitle={false}
                                    showLegend={false}
                                    toolsExport={false}
                                    color={["#5BED16"]}
                                    onRenderTooltip={(e) => ({ text: `Usia ${e.argumentText} = ${e.valueText}` })}
                                    yAxisArgs={[
                                        {
                                            valueField: "jumlah",
                                            color: '#44E08D'
                                        }
                                    ]}
                                />
                            ), [state.loadingAll, state.data_chart3_usia])
                        }
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-md-7 mb-5">
                    <CstmFilterCahrtDx
                        title="Active vs Retired"
                        subtitle="Proyeksi pekerja RTI yang aktif dan akan pensiun"
                    >
                        {
                            React.useMemo(() => (
                                <ChartDx
                                    loading={state.loadingAll}
                                    type="fullstackedbar"
                                    dataSource={[...(state.data_chart4_active_retired || [])].map(d => {
                                        return {
                                            "tahun": d.tahun?.toString(),
                                            "Active": d.Active,
                                            "Retire": d.Retire
                                        }
                                    })}
                                    argumentField="tahun"
                                    withTitle={false}
                                    colorLabel='#181C32'
                                    onRenderLabelChart={(e) => e.valueText}
                                    showLegend={true}
                                    toolsExport={false}
                                    yAxisArgs={[
                                        {
                                            name: "Active",
                                            valueField: "Active",
                                            color: '#44E08D'
                                        },
                                        {
                                            name: "Retire",
                                            valueField: "Retire",
                                            color: '#F6406A'
                                        }
                                    ]}
                                />
                            ), [state.loadingAll, state.data_chart4_active_retired])
                        }
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-5 mb-5">
                    <CstmFilterCahrtDx
                        title="Tahun Pengalaman Bekerja"
                        subtitle="Statistik lama pengalaman bekerja"
                    >
                        {
                            React.useMemo(() => (
                                <ChartDx
                                    loading={state.loadingAll}
                                    id="type"
                                    dataSource={state.data_chart8_tahun_pengalaman || []}
                                    withTitle={false}
                                    showLegend={false}
                                    toolsExport={false}
                                    color={["#5BED16"]}
                                    yVisible={true}
                                    xVisible={true}
                                    yLabel={false}
                                    argumentField="state"
                                    onRenderTooltip={(e) => ({ text: `${e.argumentText} = ${e.valueText}` })}
                                    yAxisArgs={[
                                        {
                                            valueField: "jumlah",
                                        }
                                    ]}
                                />
                            ), [state.loadingAll, state.data_chart8_tahun_pengalaman])
                        }

                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className='row my-5'>
                <div className='col-md-6 mb-5'>
                    <CstmFilterCahrtDx
                        title="Pekerja vs RKAP"
                        subtitle="Perbandingan antara jumlah pekerja dengan RKAP yang ditangani"
                        customOption={
                            <div>
                                <select className='form-control form-control-sm'
                                    value={ftRKAP}
                                    onChange={(e) => getDataChartPekerjaRKAP(e.target.value)}
                                >
                                    {
                                        [{ name: 'RTI', value: 0 }, ...[...(state.data_function || [])].map(d => {
                                            return {
                                                name: d.name,
                                                value: d.id
                                            }
                                        })
                                        ].map((d, i) => (
                                            <option key={i} value={d?.value}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                                <SwitchBtn
                                    className='my-2'
                                    value={swRKAP}
                                    onChange={(val) => setSwRKAP(val)} />
                            </div>
                        }
                    >
                        {
                            React.useMemo(() => (
                                <ChartDx
                                    loading={loadingChart6}
                                    id="3"
                                    argumentField="short_name"
                                    type={swRKAP ? "bar" : "stackedBar"}
                                    dataSource={chartRKAP}
                                    withTitle={false}
                                    showLegend={true}
                                    toolsExport={false}
                                    legendMarkerRender={legendMarkerRenderRKAP}
                                    onrenderLegendText={() => { }}
                                    onRenderLabelChart={(e) => {
                                        if (e.seriesName !== "Jumlah RKAP") {
                                            return e.value;
                                        } else {
                                            return "";
                                        }
                                    }}
                                    yAxisArgs={
                                        !swRKAP ? [
                                            {
                                                name: "PWTT",
                                                valueField: "active",
                                                color: '#365D96'
                                            },
                                            {
                                                name: "Jumlah RKAP",
                                                type: 'spline',
                                                valueField: "jumlah_rkap",
                                                color: '#F1B938'
                                            },

                                        ] : [
                                            {
                                                name: "PWTT",
                                                valueField: "active",
                                                color: '#365D96'
                                            },
                                            {
                                                name: "PWT",
                                                valueField: "pwt",
                                                color: '#dc3545'
                                            },
                                            {
                                                name: "Mitra Kerja",
                                                valueField: "contract",
                                                color: '#FA6541'
                                            },
                                            {
                                                name: "Jumlah RKAP",
                                                type: 'spline',
                                                valueField: "jumlah_rkap",
                                                color: '#F1B938'
                                            },

                                        ]}
                                />
                            ), [loadingChart6, chartRKAP, swRKAP])
                        }
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-6 mb-5">
                    <CstmFilterCahrtDx
                        title="PWTT vs PWT vs Mitra Kerja"
                        subtitle="Perbandingan jumlah pekerja PWTT, PWT dan Mitra Kerja"
                    >
                        <div className="row">
                            <div className="col-12">
                                {
                                    React.useMemo(() => (
                                        <ChartDx
                                            loading={state.loadingAll}
                                            id="3"
                                            argumentField="short_name"
                                            dataSource={state.data_chart5_active_contract || []}
                                            withTitle={false}
                                            showLegend={true}
                                            toolsExport={false}
                                            // LegendverticalAlignment="bottom"
                                            // LegendhorizontalAlignment="center"
                                            legendMarkerRender={legendMarkerRender}
                                            onrenderLegendText={() => { }}
                                            yAxisArgs={[
                                                {
                                                    name: "PWTT",
                                                    valueField: "active",
                                                    color: '#365D96'
                                                },
                                                {
                                                    name: "PWT",
                                                    valueField: "pwt",
                                                    color: '#dc3545'
                                                },
                                                {
                                                    name: "Mitra Kerja",
                                                    valueField: "contract",
                                                    color: '#FA6541'
                                                },
                                            ]}
                                        />
                                    ), [state.loadingAll, state.data_chart5_active_contract])
                                }
                            </div>
                        </div>
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-md-6 mb-5">
                    <CstmFilterCahrtDx
                        title="Pendidikan"
                        subtitle="Statistik jenjang pendidikan terakhir berdasarkan fungsi di RTI"
                    >
                        {
                            React.useMemo(() => (
                                <ChartDx
                                    id="3"
                                    loading={state.loadingAll}
                                    argumentField="short_name"
                                    dataSource={state.data_chart6_pendidikan || []}
                                    withTitle={false}
                                    colorLabel='#181C32'
                                    showLegend={true}
                                    legendMarkerRender={legendMarkerRenderPendidikan}
                                    onrenderLegendText={() => { }}
                                    toolsExport={false}
                                    yAxisArgs={[
                                        {
                                            name: "D3",
                                            valueField: "D3",
                                            color: "#3699FF"
                                        },
                                        {
                                            name: "S1",
                                            valueField: "S1",
                                            color: "#FF9941"
                                        },
                                        {
                                            name: "S2",
                                            valueField: "S2",
                                            color: "#44E08D"
                                        },
                                        {
                                            name: "S3",
                                            valueField: "S3",
                                            color: "#BE52CE"
                                        },
                                        {
                                            name: "Total",
                                            //valueField: "S3",
                                            color: "#E8E8E8"
                                        }
                                    ]}
                                />
                            ), [state.loadingAll, state.data_chart6_pendidikan])
                        }
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-6 mb-5">
                    <CstmFilterCahrtDx
                        title="Masih Menempuh Pendidikan"
                        subtitle="Statistik pekerja RTI yang masih menempuh pendidikan"
                    >
                        {
                            React.useMemo(() => (
                                <ChartDx
                                    id="type"
                                    loading={state.loadingAll}
                                    dataSource={state.data_chart7_masih_pendidikan || []}
                                    withTitle={false}
                                    showLegend={true}
                                    toolsExport={false}
                                    colorLabel='#181C32'
                                    legendMarkerRender={legendMarkerRenderMasihPendidikan}
                                    onrenderLegendText={() => { }}
                                    argumentField="short_name"
                                    onRenderTooltip={(e) => ({ text: `${e.seriesName}:${e.valueText}` })}
                                    yAxisArgs={[
                                        {
                                            name: "D3",
                                            valueField: "D3",
                                            // color: "rgba(54, 153, 255, 0.4)"
                                        },
                                        {
                                            name: "S1",
                                            valueField: "S1",
                                            // color: "rgba(255, 153, 65, 0.4)"
                                        },
                                        {
                                            name: "S2",
                                            valueField: "S2",
                                            // color: "rgba(68, 224, 141, 0.4)"
                                        },
                                        {
                                            name: "S3",
                                            valueField: "S3",
                                            // color: "rgba(190, 82, 206, 0.4)"
                                        },
                                        {
                                            name: "Total",
                                            //valueField: "S3",
                                            color: "#E8E8E8"
                                        }
                                    ]}
                                />
                            ), [state.loadingAll, state.data_chart7_masih_pendidikan])
                        }

                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-md-12 mb-5">
                    <CstmFilterCahrtDx
                        title="Posisi"
                        subtitle="Perbandingan posisi yang kosong dan terisi dalam organisasi RTI"
                        customOption={
                            <div>
                                <select className='form-control form-control-sm'
                                    onChange={(e) => getDataChartPosisi(e.target.value)}
                                >
                                    {
                                        [{ name: 'RTI', value: 0 }, ...[...(state.data_function || [])].map(d => {
                                            return {
                                                name: d.name,
                                                value: d.id
                                            }
                                        })
                                        ].map((d, i) => (
                                            <option key={i} value={d?.value}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                                <SwitchBtn className='my-2' value={showTotal} onChange={(e) => setShowTotal(e)} />
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="col-md-4">
                                <div style={{ height: '200px' }}>
                                    {
                                        React.useMemo(() => (
                                            <GaugeChart
                                                tickInterval={kuota / 2}
                                                loading={loadingGetPosisi}
                                                subvalues={[
                                                    {
                                                        value: kuota,
                                                        label: "Total Kuota",
                                                        unit: "",
                                                    }, {
                                                        value: jumlah,
                                                        label: "Total",
                                                        unit: ""

                                                    }
                                                ]}
                                                rangeOptions={[
                                                    {
                                                        startValue: 0,
                                                        endValue: jumlah,
                                                        color: "#77DD77"
                                                    }, {
                                                        startValue: jumlah,
                                                        endValue: kuota,
                                                        color: "#92000A"
                                                    }
                                                ]}
                                                data={
                                                    {
                                                        label: "Total",
                                                        value: jumlah,
                                                        unit: "",
                                                    }
                                                }
                                                startAngle={180}
                                                endAngle={0}
                                                endValue={kuota}
                                                subIndicatorType="textCloud"
                                                subIndicatorColor="gray"
                                                indicatorColor="red"
                                                indicatorType="triangleNeedle"
                                                withTitle={false}
                                            />
                                        ), [loadingGetPosisi, data_chart_posisi?.data_chart])
                                    }
                                </div>
                            </div>
                            <div className="col-md-8">
                                {
                                    React.useMemo(() => (
                                        <>
                                            <ChartDx
                                                showTotal={true}
                                                loading={loadingGetPosisi}
                                                id="3"
                                                argumentField="short_name"
                                                type="stackedBar"
                                                legendMarkerRender={legendMarkerRenderPosisi}
                                                dataSource={data_chart_posisi?.data_chart}
                                                withTitle={false}
                                                showLegend={true}
                                                toolsExport={false}
                                                onRenderLabelChart={(e) => {
                                                    if (e.seriesName !== "Kuota") {
                                                        return e.value;
                                                    } else {
                                                        return "";
                                                    }
                                                }}
                                                yAxisArgs={!showTotal ? [{
                                                    name: "Jumlah",
                                                    valueField: "jumlah",
                                                }, {
                                                    name: "Kuota",
                                                    type: 'spline',
                                                    valueField: "kuota",
                                                },] : [
                                                    {
                                                        name: "Ast. Analyst",
                                                        valueField: "Ast_Analyst",
                                                    },
                                                    {
                                                        name: "Jr. Analyst / Specialist",
                                                        valueField: "Jr_Analyst",
                                                    },
                                                    {
                                                        name: "Analyst / Specialist",
                                                        valueField: "Analyst",
                                                    },
                                                    {
                                                        name: "Sr. Analyst / Specialist",
                                                        valueField: "Sr_Analyst",
                                                    },
                                                    {
                                                        name: "Lead / Expert",
                                                        valueField: "Lead",
                                                    },
                                                    {
                                                        name: "Principal",
                                                        valueField: "Principal",
                                                    },
                                                    {
                                                        name: "Manager",
                                                        valueField: "Manager",
                                                    },
                                                    {
                                                        name: "VP",
                                                        valueField: "VP",
                                                    },
                                                    {
                                                        name: "SVP",
                                                        valueField: "SVP",
                                                    },
                                                    {
                                                        name: "Kuota",
                                                        type: 'spline',
                                                        valueField: "kuota",
                                                    },
                                                ]}
                                            />
                                        </>
                                    ), [loadingGetPosisi, data_chart_posisi?.data_chart, showTotal])
                                }
                            </div>
                        </div>
                    </CstmFilterCahrtDx>
                </div>

            </div>
            <div className="row mt-5 mb-5">
                <div className="col-md-12 mb-5">
                    <CstmFilterCahrtDx
                        title="Expertise"
                        subtitle="Statistik expertise pekerja RTI berdasarkan Section Manager"
                    >
                        <div className="row">
                            {
                                React.useMemo(() => [...(state.data_chart9_expertise || [])].map((d, i) => (
                                    <div className="col-md-3 mb-5" key={i}>
                                        <DoughnatDx
                                            height={300}
                                            withTitle={false}
                                            dataSource={d.data}
                                            showLegend={false}
                                            LegendverticalAlignment="bottom"
                                            LegendhorizontalAlignment="center"
                                            LegenditemTextPosition="top"
                                            toolsExport={false}
                                            onRenderLabelChart={(e) => (`${parseFloat(e.percentText).toFixed(0)}%`)}
                                            centerRender={(e) => renderCenterExpertise(e, d.name)}
                                            onRenderTooltip={(e) => ({ text: `${e.argumentText} = ${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                                            yAxisArgs={[
                                                {
                                                    valueField: "jumlah",
                                                    argumentField: "nama_subskill",
                                                    color: null,
                                                    format: null
                                                }
                                            ]}

                                        />
                                    </div>
                                )), [state.loadingAll, state.data_chart9_expertise])
                            }
                        </div>
                    </CstmFilterCahrtDx>
                </div>

            </div>

        </>
    )
}
export default TabDashboard;