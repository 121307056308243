import React, { useEffect, useRef, useState } from 'react';
import usePermohonanPengajuan from '../usePermohonanPengajuan';
import { PDFExport } from '@progress/kendo-react-pdf';
import ViewDetail from './viewDetail';
import { FileUploadDx } from '../../../../components';



function StepThree(props) {
    const { ppState, ppAction } = usePermohonanPengajuan();
    const fileExtensions = ['.pdf'];
    const pdfRef = useRef();
    const refFile = useRef();
    const [file, setFile] = useState(null);
    const [fileDel, setFileDel] = useState([]);

    const fileUploaded = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            if (file) {
                setFileDel([{ ...file }]);
            }
            setFile({ id: 0, file_name: resp.data?.data_filename || "", file_location: resp.data?.data_file_location });
            if (refFile.current) {
                refFile.current.instance.reset();
            }
        }
    }
    const deleteFile = () => {
        if (file && file?.id !== 0) {
            setFileDel([{ ...file }]);
        }
        setFile(null);
    }
    useEffect(() => {
        function setDefaultData() {
            const file = ppState.dataForm3?.file_docs?.[0];
            setFile(file);
            setFileDel([]);
        }
        setDefaultData();
    }, [ppState.dataForm3])
    return (
        <div className='p-6'>
            {/* <h5><u>Resume Permohonan Pengujian</u></h5> */}
            <div className='card card-body mb-5'>
                <div className='row'>
                    <div className='col-md-12 mb-3'>
                        <PDFExport pageTemplate={(props) => <div className='h-100'>
                            <div style={{
                                position: 'absolute',
                                top: 20,
                                right: "2cm"
                            }}>
                                <img crossOrigin="anonymous" style={{ height: '45px' }} src="/loginasset/logopertamina@2x.png" />
                            </div>
                        </div>} imageResolution={100} paperSize={'A4'} margin={"2cm"} scale={0.6} forcePageBreak=".page-break" ref={pdfRef} fileName={`Resume Permohonan Pengujian ${ppState.dataForm3?.judul}.pdf`}>
                            <ViewDetail data={ppState?.dataForm3 || {}} />
                            {/* <div className='row mb-5' style={{ height: '200px', justifyContent: 'space-around' }}>
                    <div className='col-md-3 d-flex flex-column justify-content-between'>
                        <p><b>Mengetahui</b></p>
                        <div style={{ height: '55px' }}>
                            <div className='d-flex mb-3'>
                                <span>Nama</span>
                                <span className='flex-grow-1 ml-2' style={{ borderBottom: '1px dotted' }}></span>
                            </div>
                            <div className='d-flex mb-3'>
                                <span>Jabatan</span>
                                <span className='flex-grow-1 ml-2' style={{ borderBottom: '1px dotted' }}></span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 d-flex flex-column justify-content-between'>
                        <p><b>Pemohon</b></p>
                        <div style={{ height: '55px' }}>
                            <p>{ppState.dataForm3?.user_nama}</p>
                        </div>
                    </div>
                </div> */}
                        </PDFExport>
                    </div>
                    <div className='col-md-6'>
                        <button className='btn btn-sm btn-danger'
                            onClick={() => {
                                if (pdfRef.current) {
                                    pdfRef.current.save();
                                }
                            }}
                        >Download Resume (PDF)</button>
                    </div>
                </div>
            </div>
            {/* <h5><u>Upload Nota Dinas/Surat Keluar/Notulen Rapat/Email (PDF)</u></h5> */}
            <div className='card card-body mb-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="form-group row mb-0">
                            <label htmlFor="file" className="col-sm-2 col-form-label col-form-label-sm pt-0">Contoh Draft SPK</label>
                            <div className="col-sm-4">
                                <button onClick={() => ppAction.setModalDraftSpk({ show: true, data: { ...ppState.dataForm3 } })} className='btn btn-danger btn-sm'>Preview Draft SPK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card card-body mb-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="form-group row">
                            <label htmlFor="file" className="col-sm-2 col-form-label col-form-label-sm">Nota Dinas/Surat Keluar/Notulen Rapat/Email (PDF)<br /> (Wajib di isi)</label>
                            <div className="col-sm-4">
                                <FileUploadDx
                                    ref={refFile}
                                    className="custom-dx-fileuploader-file-name"
                                    showFileList={true}
                                    multiple={false}
                                    selectButtonText="Select File"
                                    allowedFileExtensions={fileExtensions}
                                    readyToUploadMessage=" "
                                    onUploaded={fileUploaded}
                                    labelText="(*.pdf) | Max size 10 Mb"
                                    accept="*"
                                    invalidFileExtensionMessage="File harus berupa PDF"
                                    maxFileSize={10000000}
                                    uploadMode="instantly"
                                />
                                {file && <div className='mb-2 bg-secondary py-3 px-5 rounded d-flex justify-content-between align-items-center'>
                                    {file?.file_name}
                                    <button onClick={() => deleteFile()} type='button' className="btn btn-icon btn-xs btn-danger">
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 text-right'>
                    <button onClick={ppAction.backTostep2} className='btn btn-sm btn-secondary mr-5'><i className='fas fa-angle-double-left icon-nm'></i> Sebelumnya</button>
                    <button onClick={() => ppAction.kirimPengajuan({
                        file,
                        fileDel
                    })} className='btn btn-sm btn-primary'>Kirim Pengajuan <i className='fab fa-telegram-plane icon-nm'></i></button>
                </div>
            </div>
        </div>
    );
}

export default StepThree;