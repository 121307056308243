import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { GridDx, HeaderList } from '../../components';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';

const column = [
    { fieldName: "title", caption: "Judul", minWidth: 300 },
    { fieldName: "description", caption: "Deskripsi", width: 200 },
    { fieldName: "area_name", caption: "Area", width: 150 },
    { fieldName: "category_name", caption: "Kategori", width: 200 },
    { fieldName: "year_end", caption: "Tahun", width: 150 },
    { fieldName: "owner_name", caption: "Leader", width: 150 },
    { fieldName: "team_member", caption: "Member", width: 150, onExport: (e) => [...(e?.team_member || [])].map(d => " - " + d?.name).join(`\u2029`) },
]

function ReportKmResearch(props) {
    const tableRef = useRef();
    const history = useHistory();
    const [tahun, setTahun] = useState('');
    const [state, dispatch, action] = useGlobalState();
    const [data, setData] = useState({});
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        getDataTable(tahun);
    }, [tahun])

    function getDataTable(th) {
        action.getData(`/api/km-research/table-admin`, {
            year_search: th
        }, null, "loadingTable", true).then((resp) => {
            if (resp?.code === '00') {
                setData(resp?.data);
                setData_page(resp.data_page);
            }
        })
    }
    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (<Link to=
                {
                    {
                        pathname: "/research-detail/729" + e.data.id,
                    }
                }
                target="_blank"
            >
                <span className="text-wrap">{e.value}</span>
            </Link>)
        } else if (e.column.name === 'team_member') {
            return (
                <ul>
                    {
                        [...(e.value || [])].map((d, i) => (
                            <li className=" text-wrap p-2">{d.name}</li>
                        ))
                    }
                </ul>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }

    }

    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
                optionList={[
                    {
                        option: [...(data?.data_year_arr || [])].map(d => { return { name: d?.year_end, value: d?.year_end } }),
                        value: data?.data_year_search,
                        onChange: (val) => setTahun(val)
                    }
                ]}
            ></HeaderList>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-4">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Book-open.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Research</span>
                                    <span className="d-block"><strong>{data?.jumlahTotal}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Upstream</span>
                                    <span className="d-block"><strong>{data?.jumlahUpstream}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Refining</span>
                                    <span className="d-block"><strong>{data?.jumlahRefining}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">P&NF</span>
                                    <span className="d-block"><strong>{data?.jumlahPNF}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Gas & LNG</span>
                                    <span className="d-block"><strong>{data?.jumlahGas}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NRE</span>
                                    <span className="d-block"><strong>{data?.jumlahNRE}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Digital</span>
                                    <span className="d-block"><strong>{data?.jumlahDigital}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body>
                    <GridDx
                        ref={tableRef}
                        filenameExport={`Research Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                        searchForm={true}
                        loading={state.loadingTable}
                        className="custom-style-master"
                        data={data?.data_dataResearchs}
                        columns={column}
                        cellRender={cstmCellRender}
                        selectionMode={"multiple"}
                        exportMode="selection"
                        exportExcel={true}
                        settingColumn={true}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                    >

                    </GridDx>
                </Card.Body>
            </Card>
        </>
    );
}

export default ReportKmResearch;