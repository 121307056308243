import React, { createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";
import useIndexLandPage from "../use_index";

const lndAchiContext = createContext({
    lndAchiState: {},
    lndAchiAction: {}
});

export function LndAchiProvider({ children }) {
    const { indAction, indState } = useIndexLandPage();
    const { doGet } = useFetch();
    const [dataAchi, setDataAchi] = useState({});
    const [tahun, setTahun] = useState('');
    const [level, setlevel] = useState('');
    const [judul, setJudul] = useState('');

    async function getData(thn = '', lvl = '', jdl = '') {
        // setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/web/all-achievement',
                param: {
                    tahun: thn,
                    level: lvl,
                    judul: jdl
                },
                token: false
            });
            if (resp?.code === '00') {
                setDataAchi({...resp?.data})
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        // setLoading(false);
    }
    function tahunChange(val){
        setTahun(val);
        getData(val, level, judul);
    }
    function levelChange(val){
        setlevel(val);
        getData(tahun, val, judul);
    }
    function judulEnter(e) {
        if (e?.key === 'Enter') {
            getData(tahun, level, judul);
        }
    }
    useEffect(() => {
        getData(tahun, level, judul);
        // indAction.setScrollElement('beranda');
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])
    return <lndAchiContext.Provider
        value={{
            lndAchiState: {
                dataAchi,
                level,
                tahun
            },
            lndAchiAction: {
                setJudul,
                tahunChange,
                levelChange,
                judulEnter
            }
        }}
    >{children}</lndAchiContext.Provider>
}
export default function useLndAchi() {
    return useContext(lndAchiContext);
}