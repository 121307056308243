import moment from 'moment';
import React from 'react';
import useModalHistory, { ModalHistoryProvider } from './service';

function ModalHistory({ modeEdit = false, data = {} }) {
    const { action, state: {
        formData = {},
        source_option: {
            data_status = [],
            data_status_maintenancen = []
        }
    } } = useModalHistory();
    const RenderPelapor = React.useMemo(() => {
        return (
            <>
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Tanggal</span>
                    </div>
                    <div className='col-8'>
                        <span className="text-wrap px-5 py-1 rounded" style={{ backgroundColor: '#F0F0F0' }}>{moment(formData?.tanggal_mulai).format('DD MMMM YYYY')} <span style={{ color: '#ACC429' }}></span></span>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Status</span>
                    </div>
                    <div className='col-8'>
                        <select className="form-control form-control-sm" disabled={modeEdit}
                            value={formData?.equipment_status} onChange={(e) => action.HandleChange("equipment_status", e.target.value)}
                        >
                            <option value={''}>- Pilih -</option>
                            {
                                [...(data_status || [])].map((d, i) => (
                                    <option key={i} value={d?.param_id}>{d?.title}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {
                    (formData?.equipment_status !== '5') ? (
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Masa Berlaku Status</span>
                            </div>
                            <div className='col-8'>
                                <div className="input-group input-group-sm mb-3">
                                    <input type="number" min={0} className="form-control" placeholder="Masa berlaku status dalam hari" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                        value={formData?.masa_status} onChange={(e) => action.HandleChange("masa_status", e.target.value)}
                                        disabled={modeEdit}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">Hari</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Tanggal</span>
                            </div>
                            <div className='col-8'>
                                <input type="date" className="form-control" placeholder="Tanggal" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    value={formData?.tanggal_mulai}
                                    onChange={(e) => action.HandleChange("tanggal_mulai", e.target.value)}
                                    disabled={modeEdit}
                                />
                            </div>
                        </div>
                    )
                }
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Keterangan</span>
                        <span className="d-block text-wrap"> {formData.equipment_status === '2' ? '(Perkiraan Kerusakan)' : ''}</span>
                    </div>
                    <div className='col-8'>
                        <textarea placeholder='Keterangan' className="form-control form-control-sm" rows={4}
                            value={formData.deskripsi}
                            onChange={(e) => action.HandleChange("deskripsi", e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                {
                    formData?.equipment_status === '2' && (
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Kronologi Kejadian</span>
                            </div>
                            <div className='col-8'>
                                <textarea placeholder='Kronologi kejadian' className="form-control form-control-sm" rows={4}
                                    value={formData?.kronologi_kejadian}
                                    onChange={(e) => action.HandleChange("kronologi_kejadian", e.target.value)}
                                >
                                </textarea>
                            </div>
                        </div>
                    )
                }
            </>
        )
    }, [data?.pelapor, formData, data_status]);
    const RenderTeknisi = React.useMemo(() => {
        return (
            <>
                {
                    (formData?.equipment_status === '2' || formData?.equipment_status === '4') && (
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Biaya</span>
                            </div>
                            <div className='col-8'>
                                <div className="input-group input-group-sm mb-3">
                                    <input type="number" min={0} className="form-control" placeholder="Biaya perbaikan/perawatan" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                        value={formData?.biaya}
                                        onChange={(e) => action.HandleChange("biaya", e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">USD</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Analisa Kerusakan</span>
                    </div>
                    <div className='col-8'>
                        <textarea placeholder='Analisa Kerusakan' className="form-control form-control-sm" rows={4}
                            value={formData?.analisa_kerusakan}
                            onChange={(e) => action.HandleChange("analisa_kerusakan", e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Tindakan Perbaikan</span>
                    </div>
                    <div className='col-8'>
                        <textarea placeholder='Tindakan perbaikan' className="form-control form-control-sm" rows={4}
                            value={formData?.tindakan_perbaikan}
                            onChange={(e) => action.HandleChange("tindakan_perbaikan", e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Teknisi</span>
                    </div>
                    <div className='col-8'>
                        <input placeholder='Teknisi' className="form-control form-control-sm"
                            value={formData?.nama_teknisi}
                            onChange={(e) => action.HandleChange("nama_teknisi", e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Status Perbaikan</span>
                    </div>
                    <div className='col-8'>
                        <select className="form-control form-control-sm"
                            value={formData?.status_maintenance}
                            onChange={(e) => action.HandleChange("status_maintenance", e.target.value)}
                        >
                            {
                                [{
                                    "id": 0,
                                    "param_name": "",
                                    "param_id": 0,
                                    "title": "- Pilih -",
                                    "title2": null,
                                    "status": 1
                                }, ...(data_status_maintenancen || [])].map((d, i) => (
                                    <option key={i} value={d?.param_id}>{d?.title}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className='col-4'>
                        <span className="d-block text-wrap">Tanggal Selesai Perbaikan</span>
                    </div>
                    <div className='col-8'>
                        <input type="date" className="form-control" placeholder="Tanggal" aria-label="Recipient's username" aria-describedby="basic-addon2"
                            value={formData?.tanggal_selesai_maintenance}
                            onChange={(e) => action.HandleChange("tanggal_selesai_maintenance", e.target.value)}
                        />
                    </div>
                </div>
            </>
        )
    }, [modeEdit, data?.pelapor, formData, data_status_maintenancen])
    return (
        <div>
            <h5>{modeEdit ? "Edit" : "Add"} Status {(modeEdit && data?.pelapor === false) ? "(Teknisi)" : ""}</h5>
            <hr />
            {
                data?.pelapor === true && RenderPelapor
            }
            {
                (modeEdit && data?.pelapor === false) && RenderTeknisi
            }
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={action.CloseModal}
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-primary mx-1"
                    onClick={() => action.SimpanStatusClick(data?.pelapor)}
                >
                    <i className="fa fa-save icon-nm"></i>
                    Simpan
                </button>
            </div>
        </div>
    );
}

export default (props) => (
    <ModalHistoryProvider {...props}>
        <ModalHistory {...props} />
    </ModalHistoryProvider>
);