import React from "react";
import { Spinner } from "react-bootstrap";
import { useGlobalState } from "../../contexts/useGlobalState";
require("./asset/style.css");
// require("../../../index.light.scss")

const Login = () => {
    const [state, , actions] = useGlobalState();
    return (
        <>
            <div className="m-content" style={{ height: "100%" }}>
                <div className="loginPage">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-login">
                                <div className="card-body">
                                    <form className="form-horizontal" onSubmit={(e) => {
                                        e.preventDefault(e)
                                        actions.doLogin();
                                    }}>
                                        <div className="row">
                                            <div className="col">
                                                <div className=" text-center" style={{ width: "100%" }}>
                                                    <img className="logospiirit" src="/loginasset/logospiirit.png" alt="logo spiirit login"/>
                                                </div>
                                                <div style={{ width: "100%", wordWrap: "break-word" }} className='title-text text-center m--padding-left-45'>Sistem Pengelolaan Inovasi dan Informasi Riset & Teknologi Pertamina</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-center" style={{ paddingTop: "50px" }}>
                                                <h1 style={{ fontWeight: "bold", color: "#5867DD" }}>Masuk</h1>
                                                <span className='title-text'>
                                                    Masukkan Email dan Password
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row text-center" style={{ marginTop: "30px" }}>
                                            <div className="col-12">
                                                <input onChange={(e) => actions.setForm("Email", e.target.value)} value={state.formData.Email} id="email" className="form-control form-control-sm col-10 form-login" name="email" placeholder="Username" required autofocus />
                                            </div>
                                        </div>
                                        <div className="row text-center" style={{ marginTop: "10px" }}>

                                            <div className="col-12">
                                                <input onChange={(e) => actions.setForm("Password", e.target.value)} value={state.formData.Password} autocomplete="off" id="password" type="password" className="form-control form-control-sm col-10 form-login" name="password" placeholder="******" required />
                                            </div>
                                        </div>
                                        <div className="row text-center" style={{ marginTop: "25px", marginBottom:'20px' }}>
                                            <div className="col-md-12">
                                                <button type="submit" disabled={state.loadingLogin} className="btn btn-sm btn-primary">
                                                    {
                                                        state.loadingLogin ? (
                                                            <>
                                                            <Spinner animation="grow" variant="light" size="sm" style={{marginRight:5}} /> Login
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="la la-sign-in"></i>
                                                                Login
                                                            </>
                                                        )
                                                    }
                                                </button>
                                                <span>&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                                                <a href="~/noSession/CreatePreIdeasi" className="btn btn-sm btn-outline-primary">
                                                    <i className="la la-pencil"></i>  Create Research
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 none-on-devices">
                            <img className="orgmerah" src="/loginasset/orangmerah.svg" alt="orgmerah"/>
                            <img className="kursikiri" src="/loginasset/kursikiri.svg" alt="kursikiri"/>
                            <img className="orgbiru" src="/loginasset/orangbiru.svg" alt="orgbiru"/>
                            <img className="orghijau" src="/loginasset/oranghijau.svg" alt="orghijau"/>
                            <img className="mejaset" src="/loginasset/mejaset.svg" alt="mejaset"/>
                            <img className="kursikanan" src="/loginasset/kursikanan.svg" alt="kursikanan"/>
                            <img className="jendela" src="/loginasset/jendela.svg" alt="jendela"/>
                            <img className="screen" src="/loginasset/screen.svg" alt="screen"/>
                            <img className="jam" src="/loginasset/jam.svg" alt="jam"/>
                            <img className="logopertamina" src="/loginasset/logopertamina.png" alt="logopertamina"/>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="row footer m--hidden-mobile">
                <div className="col-6" style={{textAlign: "left", padding:"20px", paddingLeft:"50px"}}>
                    &copy; Copyright PT Pertamina(Persero). All Rights Reserved
    </div>
                <div className="col-6" style={{textAlign: "right", padding:"20px"}}>
                    <a href="~/NoSession/Play">Display News Mode</a>
                </div>
            </div> */}
        </>
    );
}

export default Login;