import React, { memo, useCallback, useLayoutEffect, useRef, useState } from 'react';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import { useResizeDetector } from 'react-resize-detector';


const toolbars = [
    {
        name: "undo",
        acceptedValues: null
    },
    {
        name: "redo",
        acceptedValues: null
    },
    {
        name: "sparator",
        acceptedValues: null
    },
    {
        name: "size",
        acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']
    },
    {
        name: "font",
        acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']
    },
    {
        name: "bold",
        acceptedValues: null
    },
    {
        name: "italic",
        acceptedValues: null
    },
    {
        name: "strike",
        acceptedValues: null
    },
    {
        name: "underline",
        acceptedValues: null
    },
    {
        name: "separator",
        acceptedValues: null
    },
    {
        name: "alignLeft",
        acceptedValues: null
    },
    {
        name: "alignCenter",
        acceptedValues: null
    },
    {
        name: "alignRight",
        acceptedValues: null
    },
    {
        name: "alignJustify",
        acceptedValues: null
    },
    {
        name: "header",
        acceptedValues: [false, 1, 2, 3, 4, 5]
    },
    {
        name: "sparator",
        acceptedValues: null
    },
    {
        name: "color",
        acceptedValues: null
    },
    {
        name: "background",
        acceptedValues: null
    },
    {
        name: "sparator",
        acceptedValues: null
    },
    {
        name: "image",
        acceptedValues: null
    },

    {
        name: "link",
        acceptedValues: null
    },
    {
        name: "sparator",
        acceptedValues: null
    },
    {
        name: "clear",
        acceptedValues: null
    },

    {
        name: "codeBlock",
        acceptedValues: null
    },

    {
        name: "blockquote",
        acceptedValues: null
    },
    {
        name: "sparator",
        acceptedValues: null
    },
    {
        name: "orderedList",
        acceptedValues: null
    },
    {
        name: "bulletList",
        acceptedValues: null
    },
    {
        name: "insertTable",
        acceptedValues: null
    },
    {
        name: "deleteTable",
        acceptedValues: null
    },
    {
        name: "insertRowAbove",
        acceptedValues: null
    },
    {
        name: "insertRowBelow",
        acceptedValues: null
    },
    {
        name: "deleteRow",
        acceptedValues: null
    },
    {
        name: "insertColumnLeft",
        acceptedValues: null
    },
    {
        name: "insertColumnRight",
        acceptedValues: null
    },
    {
        name: "deletColumn",
        acceptedValues: null
    },
]
export default memo(function({
    children = null,
    width = "100%",
    height=null,
    multilineToolbar = true,
    onChanged = ()=>null,
    onChange=()=>null,
    value = null,
    
}){

    const refEditor = useRef()
    const [updateToolbar, setUpdateToolbar] = useState([]);
    const refreshRef = function(x){
        return true;
    }
    const onResize = useCallback(() => {
        if (refEditor.current !== null) {
            refEditor.current.forceUpdate()
            const newUp = [ ...updateToolbar, updateToolbar.length ];
            setUpdateToolbar(newUp);
            // console.log(refEditor.current?.instance?.refresh())
        };
    }, []);
    const { ref } = useResizeDetector({ onResize });
    const parentRef = ref;
    return (
        <>
        <div style={{width}} ref={parentRef}>
            <HtmlEditor
                className='bg-white'
                height={height}
                width={width}
                ref={refEditor}
                onValueChanged={(e)=>onChanged(e)}
                onValueChange={(e)=>onChange(e)}
                {
                    ...(value !== null ? { value } : {})
                }
            >
                <MediaResizing enabled={true} />
                <Toolbar multiline={multilineToolbar}>
                    {
                            updateToolbar.map(({ name = "", acceptedValues = null }, l) => <Item key={l} />)
                    }
                    {
                        (children === undefined || children === null) ? toolbars.map(({name="", acceptedValues=null}, key)=> <Item name={name} acceptedValues={acceptedValues} key={key} /> ) : children
                    }
                </Toolbar>
            </HtmlEditor>
        </div>
        </>
    )
});