import React from 'react';
import { RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { toInteger } from 'lodash';

function ModalMonitoring({ mode, data, id_kerjasama, onSave = (e) => { } }) {
    const [state, , action] = useGlobalState();
    const [status_monitoring, setstatus_monitoring] = useState("");
    const [status_monitoring_note, setstatus_monitoring_note] = useState("");
    const [dukungan_manajemen, setdukungan_manajemen] = useState(false);
    const [dukungan_pemerintah, setdukungan_pemerintah] = useState(false);
    const [dukungan_note, setdukungan_note] = useState("");
    const [kendala_alignment, setkendala_alignment] = useState(false);
    const [kendala_dukungan, setkendala_dukungan] = useState(false);
    const [kendala_komersialisasi, setkendala_komersialisasi] = useState(false);
    const [kendala_operasional, setkendala_operasional] = useState(false);
    const [kendala_regulasi, setkendala_regulasi] = useState(false);
    const [kendala_lainnya, setkendala_lainnya] = useState(false);
    const [kendala_note, setkendala_note] = useState("");

    useEffect(() => {
        if (state.modal.modalMonitoring === true) {
            ResetForm();
            GetDataSources();
            if (mode === "Edit") {
                SetForm();
            }
        }
    }, [state.modal.modalMonitoring]);

    const ResetForm = () => {
        setstatus_monitoring("");
        setstatus_monitoring_note("");
        setdukungan_manajemen(false);
        setdukungan_pemerintah(false);
        setdukungan_note("");
        setkendala_alignment(false);
        setkendala_dukungan(false);
        setkendala_komersialisasi(false);
        setkendala_operasional(false);
        setkendala_regulasi(false);
        setkendala_lainnya(false);
        setkendala_note("");
    }
    const SetForm = () => {
        setstatus_monitoring(data?.status_monitoring);
        setstatus_monitoring_note(data?.status_monitoring_note);
        setdukungan_manajemen(data?.dukungan_manajemen);
        setdukungan_pemerintah(data?.dukungan_pemerintah);
        setdukungan_note(data?.dukungan_note);
        setkendala_alignment(data?.kendala_alignment);
        setkendala_dukungan(data?.kendala_dukungan);
        setkendala_komersialisasi(data?.kendala_komersialisasi);
        setkendala_operasional(data?.kendala_operasional);
        setkendala_regulasi(data?.kendala_regulasi);
        setkendala_lainnya(data?.kendala_lainnya);
        setkendala_note(data?.kendala_note);
    }
    const GetDataSources = () => {
        action.getData(`/api/ext-partnership/add-monitoring?research_id=${id_kerjasama || 0}`);
    }
    const ValidationSubmit = () => {
        var isValid = true;
        var message = [];
        var messageOption = [];
        if (status_monitoring === "") {
            isValid = false;
            message = [...message, "Status"]
        }
        if (status_monitoring_note === "") {
            isValid = false;
            message = [...message, "Catatan Status"]
        }
        if (dukungan_manajemen === false && dukungan_pemerintah === false) {
            isValid = false;
            messageOption = [...messageOption, "Pilih (minimal satu) Dukungan Kerjasama"]
        }
        if (dukungan_note === "") {
            isValid = false;
            message = [...message, "Dukungan yang Diperlukan"]
        }
        if (kendala_alignment === false &&
            kendala_dukungan === false &&
            kendala_komersialisasi === false &&
            kendala_operasional === false &&
            kendala_regulasi === false &&
            kendala_lainnya === false) {
            isValid = false;
            messageOption = [...messageOption, "Pilih (minimal satu) Kendala Kerjasama"]
        }
        if (kendala_note === "") {
            isValid = false;
            message = [...message, "Deskripsi Kendala"]
        }
        return {
            isValid,
            message: message.length > 0 ? message.join(", ").concat(" Tidak boleh kosong") : "",
            messageOption: messageOption.join(", ")
        }
    }
    const Submit = () => {
        const val = ValidationSubmit();
        if (!val.isValid) {
            return Swal.fire({
                title: 'Warning',
                html: `<p>${val.message}</p><p>${val.messageOption}</p>`,
                icon: 'warning',
            })
        }
        onSave({
            "id": toInteger(data?.id || 0),
            "kerjasama_id": toInteger(id_kerjasama),
            "status_monitoring": status_monitoring,
            "status_monitoring_note": status_monitoring_note,
            "dukungan_manajemen": dukungan_manajemen,
            "dukungan_pemerintah": dukungan_pemerintah,
            "dukungan_note": dukungan_note,
            "kendala_alignment": kendala_alignment,
            "kendala_dukungan": kendala_dukungan,
            "kendala_komersialisasi": kendala_komersialisasi,
            "kendala_operasional": kendala_operasional,
            "kendala_regulasi": kendala_regulasi,
            "kendala_lainnya": kendala_lainnya,
            "kendala_note": kendala_note,
            "jenis_dokumen": state?.data_status_dokumen?.jenis_dokumen,
            "status_dokumen": state?.data_status_dokumen?.status_dokumen
        });
    }
    return (
        <div>
            <h5>{mode === 'Edit' ? 'Edit' : 'Tambah'} Monitoring</h5>
            <hr />
            <div className="row mb-3 mx-3">
                <div className='col-md-6'>
                    <div>
                        <p className='border-bottom' style={{ fontWeight: 700 }}>Status Proyek Kerjasama</p>
                        <div style={{ marginLeft: '20px', fontSize: '12px', marginBottom: '20px' }}>
                            {
                                [...(state.status_monitoring_list || [])].map((d, i) => (
                                    <div className="form-check" key={i}>
                                        <input className="form-check-input" type="radio" name="status" id={`status${i}`} value={d} style={{
                                            top: '3px',
                                            position: 'relative',
                                            marginRight: '4px'
                                        }}
                                            checked={status_monitoring === d}
                                            onChange={(e) => setstatus_monitoring(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={`status${i}`}>
                                            {d}
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="form-group row ml-2" style={{ fontSize: '12px' }}>
                            <span htmlFor="catatan" className="col-sm-3">Catatan</span>
                            <div className="col-sm-9">
                                <textarea rows={3} className="form-control form-control-sm" id="catatan"
                                    value={status_monitoring_note}
                                    onChange={(e) => setstatus_monitoring_note(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className='border-bottom' style={{ fontWeight: 700 }}>Dukungan Kerjasama (Optional)</p>
                        <div style={{ marginLeft: '20px', fontSize: '12px', marginBottom: '20px' }}>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="Manajemen" id="Manajemen" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={dukungan_manajemen}
                                    onChange={(e) => setdukungan_manajemen(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="Manajemen">
                                    Manajemen
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="Pemerintah" id="Pemerintah" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={dukungan_pemerintah}
                                    onChange={(e) => setdukungan_pemerintah(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="Pemerintah">
                                    Pemerintah
                                </label>
                            </div>
                        </div>
                        <div className="form-group row ml-2" style={{ fontSize: '12px' }}>
                            <span htmlFor="duk" className="col-sm-3">Dukungan yang Diperlukan</span>
                            <div className="col-sm-9">
                                <textarea rows={3} className="form-control form-control-sm" id="duk"
                                    value={dukungan_note}
                                    onChange={(e) => setdukungan_note(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div>
                        <p className='border-bottom' style={{ fontWeight: 700 }}>Kendala Kerjasama</p>
                        <div style={{ marginLeft: '20px', marginBottom: '20px', fontSize: '12px' }}>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="" id="satu" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={kendala_alignment}
                                    onChange={(e) => setkendala_alignment(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="satu">
                                    Kendala Alignment
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="" id="dua" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={kendala_dukungan}
                                    onChange={(e) => setkendala_dukungan(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="dua">
                                    Kendala Dukungan Manajemen
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="" id="tiga" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={kendala_komersialisasi}
                                    onChange={(e) => setkendala_komersialisasi(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="tiga">
                                    Kendala Komersialisasi
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="" id="empat" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={kendala_operasional}
                                    onChange={(e) => setkendala_operasional(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="empat">
                                    Kendala Operasional
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="" id="lima" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={kendala_regulasi}
                                    onChange={(e) => setkendala_regulasi(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="lima">
                                    Kendala Regulasi
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" value="" id="enam" style={{
                                    top: '3px',
                                    position: 'relative',
                                    marginRight: '4px'
                                }}
                                    checked={kendala_lainnya}
                                    onChange={(e) => setkendala_lainnya(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="enam">
                                    Kendala Lainnya
                                </label>
                            </div>
                        </div>
                        <div className="form-group row ml-2" style={{ fontSize: '12px' }}>
                            <span htmlFor="catatan" className="col-sm-3">Deskripsi Kendala</span>
                            <div className="col-sm-9">
                                <textarea rows={3} className="form-control form-control-sm" id="catatan"
                                    value={kendala_note}
                                    onChange={(e) => setkendala_note(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className='border-bottom' style={{ fontWeight: 700 }}>Status Dokumen Kerjasama</p>
                        <div className="form-group row ml-2" style={{ fontSize: '12px' }}>
                            <span htmlFor="catatan" className="col-sm-3">Status Dokumen</span>
                            <div className="col-sm-9">
                                <span className='badge badge-secondary'>{state?.data_status_dokumen?.status_dokumen_name}</span>
                            </div>
                        </div>
                        <div className="form-group row ml-2" style={{ fontSize: '12px' }}>
                            <span htmlFor="catatan" className="col-sm-3">Jenis Dokumen</span>
                            <div className="col-sm-9">
                                <span className='badge badge-secondary'>{state?.data_status_dokumen?.jenis_dokumen_name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row ">
                <div className="col-md-12 text-md-right">
                    <button className="btn btn-sm btn-secondary mx-1"
                        onClick={() => action.toggleModal('modalMonitoring')}
                    >
                        Batal
                    </button>
                    <button className="btn btn-sm btn-primary"
                        onClick={() => Submit()}
                    >
                        <i className="fa fa-save icon-sm" ></i> Simpan
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ModalMonitoring;