import React, { memo } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import './radio.group.css'
import { CustomPopover } from '..';

// format data = {
//     label:'',
//     value:'',
//     disable:false
// }
export default memo(function ({
    data = [],
    value = '',
    disable = false,
    onChange,
    modeRow = false,
    withPopup = false,
    className = "",
    placementHint = "top",
    contentPopups = (props) => <span>Test</span>
}) {
    const RenderButton = (info, key) => {
        return withPopup ? <CustomPopover
            keepOpenContent={false}
            placement={placementHint}
            key={key}
            contentPopup={{
                title: '',
                content: <>{contentPopups(info)}</>
            }}
        >
            <FormControlLabel className={className} key={key} disabled={disable === true ? disable : info?.disabled} value={info?.value} control={<Radio icon={<RadioButtonUncheckedIcon fontSize="small" />} checkedIcon={<RadioButtonCheckedIcon fontSize="small" />} />} label={info?.label} />
        </CustomPopover> : <FormControlLabel className={className} key={key} disabled={disable === true ? disable : info?.disabled} value={info?.value} control={<Radio icon={<RadioButtonUncheckedIcon fontSize="small" />} checkedIcon={<RadioButtonCheckedIcon fontSize="small" />} />} label={info?.label} />
    }
    return (
        <div className='row'>
            <div className='col-12'>
                <RadioGroup
                    row={modeRow}
                    className={`cstmRadio ctstmText m-0 ${className}`}
                    value={value}
                    onChange={(e) => onChange ? onChange(e.target.value) : null}
                >
                    {
                        data.map(({ label, value, disabled, hint }, i) => {
                            // <RenderButton info={{ label, value, disabled }} key={i} />
                            return RenderButton({ label, value, disabled, hint }, i);
                        }
                            // <CustomPopover
                            //     contentPopup={<>Test</>}
                            // >
                            //     <FormControlLabel className={className} key={i} disabled={disable === true ? disable : disabled} value={value} control={<Radio icon={<RadioButtonUncheckedIcon fontSize="small" />} checkedIcon={<RadioButtonCheckedIcon fontSize="small" />} />} label={label} />
                            // </CustomPopover>
                        )
                    }
                </RadioGroup>
            </div>
        </div>
    )
});