import React from 'react';
import { GridDx } from '../../../../components';

const column = [
    { fieldName: 'id_log', caption: 'ID Log', allowFiltering: false },
    { fieldName: 'page_name', caption: 'Page Name', allowFiltering: false },
    { fieldName: 'act_type', caption: 'Action type', allowFiltering: false },
    { fieldName: 'data_id', caption: 'Data ID', allowFiltering: false },
    { fieldName: 'data_summary', caption: 'Data Summary', allowFiltering: false },
    { fieldName: 'created_at', caption: 'Access Time', allowFiltering: false },
]
export default function (props) {
    
    function cellRender(rowInfo){
        if(rowInfo.column.name === 'page_name'){
            return(
                <span className='d-block text-wrap'>{rowInfo.data?.grp_name + "/" + rowInfo.data?.side1_name+ "/" + rowInfo.data?.side2_name+ "/" + rowInfo.data?.side3_name+ "/" + rowInfo.data?.act_name}</span>
            )
        }else{
            return(
                <span className='d-block text-wrap'>{rowInfo.value}</span>
            )
        }
    }
    return (
        <div>
            <h5 className='mb-5'>Detail Session</h5>
            <GridDx
                className="custom-style"
                columns={column}
                data={props?.data?.data?.data_logs}
                cellRender={cellRender}
            />
        </div>
    );
}
