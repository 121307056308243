import React, { memo, useState, useEffect, useRef } from 'react'
import { useGlobalState } from '../../contexts/useGlobalState';
import { HeaderList, GridDx } from '../../components';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import DropDownButton from "devextreme-react/drop-down-button";
import { workflow_state_name_light_style, stage, action_portfolio_table_crud } from '../../components/utility/utility';

const columns = [
    // { fieldName: 'action', caption: 'Action', width: 100 },
    { fieldName: "created_at", caption: "Date Created", width: 200 },
    { fieldName: "title", caption: "Title", link: true, url: "/portfolio-research/view", width: 500 },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "function_name", caption: "Function", width: 200 },
    { fieldName: "chief_name", caption: "Chief", width: 200 },
    { fieldName: "area_name", caption: "Area", width: 200 },
    { fieldName: "cat_name", caption: "Category", width: 200 },
    { fieldName: "project_type_short_name", caption: "Project Type", width: 200 },
    { fieldName: "focus", caption: "Ruang Lingkup Riset", width: 400 },   
    { fieldName: "leader", caption: "Owner", width: 200 },
    { fieldName: "plan_tot_budget", caption: "Budget Total (Ribu USD)", width: 200 },
    { fieldName: "method_name", caption: "Metode", width: 200 },
    { fieldName: "potensial_partner_opt_name", caption: "Potential Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potential User", width: 200 },
    //{ fieldName: "value_creation", caption: "Value Creation", width: 200 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "stage", caption: "Stage", width: 200 },
    { fieldName: "year_start", caption: "Year Start", width: 200 },
    { fieldName: "year_end", caption: "Year End", width: 200 },
    { fieldName: "keyword", caption: "Keyword", width: 300 },
]

export default memo(function () {
    const [state, dispatch, action] = useGlobalState();
    const [year, setYear] = useState();
    const history = useHistory()
    const tableRef = useRef()
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData("/api/project-portfolio/table", {
            tahun: state.filterData.year_search || "",
        }, null, "loadingGrid").then((resp) => {
            if (resp.code === "00") {
                const data_allyear = resp.data.data_allyear?.map((d, i) => {
                    return {
                        value: d.idYear,
                        name: d.idYear
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { data_allyear: data_allyear, loadingGet: false }
                })
                setData_page(resp.data_page);
            }
        })
    }, [state.filterData.year_search])
    function searchChange(value) {
        if (tableRef) {
            tableRef.current.instance.searchByText(value)
        }
    }
    function cstmCellRender(e) {
        if (e.column.name === 'action') {
            const data = [action_portfolio_table_crud(e.data)]
            return (
                <DropDownButton
                    width='70px'
                    text=" "
                    className="bg-primary rounded customColorIcon customColotText"
                    icon="preferences"
                    dropDownOptions={{ width: 250 }}
                    displayExpr="titleAction"
                    keyExpr="id"
                    items={data}
                    onItemClick={(btn) => {
                        if (btn.itemData?.url) {
                            history.push(btn.itemData?.url)
                        }
                    }}
                />
            )

        } else if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/admin/portfolio-research/crud/edit/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span></Link>
            )
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "value_creation") {
            return (
                <span className="text-wrap">
                    {e.data.vc_pendapatan === "1" && "Pendapatan "}
                    {e.data.vc_efisiensi === "1" && "Efisiensi "}
                    {e.data.vc_produksi === "1" && "Produksi "}
                    {e.data.vc_intangible === "1" && "Intangible "}
                </span>
            )
        } else if (e.column.name === "stage") {
            return (stage(e))
        } else if (e.column.name === "keyword"){
            return (
                <div className="text-wrap">
                {
                    (e.value || "").split(',').map((d, i) => (
                        <span className="badge badge-secondary mr-1 mb-1">{d}</span>
                    ))
                }
                </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <div className="pb-5">
                <HeaderList
                    title={data_page?.page_title}
                    someText={data_page?.page_breadcrumbs}
                    optionList={[{
                        withLabel: true,
                        label: "Rekapitulasi usulan riset pada tahun : ",
                        option: state.data_allyear,
                        onChange: function (e) { action.setFilter("year_search", e) }
                    }]}
                ></HeaderList>
            </div>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Research</span>
                                    <span className="d-block"><strong>{
                                        state?.data_jumlah_project?.total
                                    }</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-pink" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Review</span>
                                    <span className="d-block"><strong>{state?.data_jumlah_project?.review || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Need Revision</span>
                                    <span className="d-block"><strong>{state?.data_jumlah_project?.draft_need_revision || 0}</strong></span>
                                </div>
                            </div>
                        </div>                        
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Rejected</span>
                                    <span className="d-block"><strong>{state?.data_jumlah_project?.rejected || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Waiting Implementation</span>
                                    <span className="d-block"><strong>{state?.data_jumlah_project?.waiting_implementation || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Running</span>
                                    <span className="d-block"><strong>{state?.data_jumlah_project?.running || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-secondary" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Finished</span>
                                    <span className="d-block"><strong>{state?.data_jumlah_project?.finished || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-1 col-lg-1 col-md-1 col-sm-6 col-6 mb-3'>
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block title-box">Aborted</span>
                                    <span className="d-block"><strong>{state?.data_jumlah_project?.aborted || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom pl-md-5">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Budget (Ribu USD)</span>
                                    <span className="d-block">$ <strong>{parseFloat(state?.data_jumlah_project?.total_plan_budget || 0).toFixed(1)}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-3">
                            <div className="formSearch">
                                <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => searchChange(e.target.value)} />
                                <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12">
                            <GridDx
                                ref={tableRef}
                                loading={state.loadingGrid}
                                data={state.data_table}
                                cellRender={(e) => cstmCellRender(e)}
                                columns={columns}
                                withAction={true}
                                pagination={true}
                                orderingDrag={false}
                                actions={[
                                    // {
                                    //     name: "Edit",
                                    //     icon: "fa fa-pencil-alt icon-nm text-success",
                                    //     action: (v, c) => {
                                    //         history.push({
                                    //             pathname: "/admin/portfolio-research/crud/edit/" + v.id,
                                    //             state: { view_admin: true }
                                    //         })
                                    //     }
                                    // }
                                ]}
                                currentPage
                                show
                            >
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})