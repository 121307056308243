import React, { createContext, useContext } from "react";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";
import { useState } from "react";

const waContext = createContext({
    waState: {},
    waAction: {}
});

const column_wa = [
    { fieldName: 'creator', caption: 'Pengirim', allowFiltering: true },
    { fieldName: 'receiver', caption: 'Penerima', allowFiltering: true, width: 150 },
    { fieldName: 'message', caption: 'Isi Pesan', allowFiltering: false },
    { fieldName: 'module', caption: 'Modul' },
    { fieldName: 'module_act', caption: 'Action', allowFiltering: true },
    { fieldName: 'module_id', caption: 'Data ID', allowFiltering: false },
    { fieldName: 'created_at', caption: 'Created at', allowFiltering: false },
    { fieldName: 'schedulled_at', caption: 'Schedulled at', allowFiltering: false },
    { fieldName: 'sent_at', caption: 'Sent at', allowFiltering: false },
    { fieldName: 'wa_status_string', caption: 'Status', allowFiltering: false },
]

export function WaProvider({ children }) {
    const { doGet, doPost, doPostMultiple } = useFetch();
    const [data_page, setData_page] = useState({
        "page_title": "",
        "page_breadcrumbs": ""
    })
    const [loadingWa, setLoadingWa] = useState(false);
    const [dataViewWa, setDataViewWa] = useState({});
    const [clearWa, setClearWa] = useState(false);
    async function getMasterWa() {
        setLoadingWa(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/user/view-wa-broadcast',
            });
            if (resp?.code === '00') {
                setData_page(resp?.data_page);
                setDataViewWa(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoadingWa(false);
    }
    const [dataLogWa, setDataLogWa] = useState({});
    const [loadingLog, setLoadingLog] = useState(false);
    const [filterWa, setFilterWa] = useState({
        date_start: '',
        date_end: '',
        module: ''
    })
    const handleFilterWa = (name, val) => {
        setFilterWa((e) => ({
            ...e,
            [name]: val
        }))
    }
    async function getLogWa() {
        try {
            setLoadingLog(true);
            Swal.fire({
                title: 'Please Wait',
                html: 'Retrieving data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doGet({
                url: 'api/user/table-wa-logs',
                param: {
                    ...filterWa
                }
            });
            if (resp?.code === "00") {
                setDataLogWa(resp.data);
            }
            setLoadingLog(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
    }
    async function doSendWa(data = {}) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `/api/user/send-wa-broadcast`,
                data: {
                    ...data
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { setClearWa(true) })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    function onSendWa(data = {}) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Kirim Pesan?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validation(data);
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendWa(data);
                }
                return true;
            } else {
                return true;
            }
        })
    }
    function validation(data = {}) {
        var message = ""
        var isValid = true;
        if ([...(data?.listUserPersonal || [])].length < 1 &&
            [...(data?.listGroupRole || [])].length < 1 &&
            [...(data?.listGroupFunction || [])].length < 1) {
            isValid = false;
            message = "Silahkan pilih penerima broadcast"
        } else if (data?.message === "") {
            message = "Pesan tidak boleh kosong";
            isValid = false;
        } else if (data?.withSchedulledTime === true && data?.schedulled_at === "") {
            message = "Silahkan tentukan tanggal pengiriman";
            isValid = false;
        }
        return {
            message,
            isValid
        }
    }
    function cellRender(rowInfo) {
        if (rowInfo.column?.name === 'receiver') {
            return (
                <div className="text-wrap">
                    <span className="badge badge-primary mr-5">{rowInfo.data?.jumlah || ''} Messages</span>
                    <span className="d-block">{rowInfo.value}</span>
                </div>
            )
        } else {
            return (
                <div className="text-wrap">
                    {rowInfo.value}
                </div>
            )
        }
    }

    //Project Notification
    const [data_viewp, setData_viewp] = useState({});
    const [loadingp, setLoadingp] = useState(false);
    async function getProjectNotifSource() {
        try {
            setLoadingp(true);
            Swal.fire({
                title: 'Please Wait',
                html: 'Retrieving data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doGet({
                url: 'api/user/view-wa-smart-notification',
            });
            if (resp?.code === "00") {
                setData_viewp({ ...resp?.data });
                setData_page({...resp?.data_page});
            }
            setLoadingp(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
    }
    function onSendProjectNotification(type = "") {
        var judul_konfirm = "";
        if (type === "reminder")
            judul_konfirm = "Project Update Reminder";
        if (type === "attention")
            judul_konfirm = "Project Need Attention";
        if (type === "delay")
            judul_konfirm = "Project Delay";
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                `<strong>${judul_konfirm}</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                return true;
            } else {
                return true;
            }
        })
    }
    return <waContext.Provider
        value={{
            waState: {
                loadingWa,
                dataViewWa,
                clearWa,
                data_page,
                dataLogWa,
                loadingLog,
                column_wa,
                filterWa,
                data_viewp,
                loadingp
            },
            waAction: {
                getMasterWa,
                onSendWa,
                setClearWa,
                handleFilterWa,
                getLogWa,
                cellRender,
                onSendProjectNotification,
                getProjectNotifSource
            }
        }}
    >
        {children}
    </waContext.Provider>
}

export default function useWhatsapp() {
    return useContext(waContext);
}