import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
export default memo(
    function ({
        type = "card",
        height = 150,
        width = 250,
        rows = 15,
        cols = 5,
        widthChart = "100%",
        heightChart = 300,
        rotate = false
    }) {

        const colsArray = [];
        const rowsArray = [];
        for (var i = 0; i < cols; i++) {
            colsArray.push(i)
        }

        if (type === "card")
            return (
                <>
                    {<Skeleton
                        height={height}
                        width={width}
                    />}
                </>
            )
        if (type === "table")
            return (
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            {
                                colsArray.map((val, i) => (
                                    <th style={{ paddingLeft: 10 }} key={i}> <Skeleton height={20} count={rows} style={{ marginBottom: 5 }} /> </th>
                                ))
                            }
                        </tr>
                    </thead>
                </table>
            )
        if (type === "chart")
            return (
                <div style={{ minHeight: heightChart, width: widthChart, borderBottom: "1px solid gray", borderLeft: "1px solid gray", padding: 10 }}>
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                {
                                    colsArray.map((val, i) => (
                                        <th style={{ paddingLeft: 10 }} key={i}> <Skeleton height={heightChart} style={{ marginBottom: 5, margin: "auto" }} /> </th>
                                    ))
                                }
                            </tr>
                        </thead>
                    </table>
                </div>
            )
        if (type === "doughnat")
            return (
                <div style={{ minHeight: heightChart, width: widthChart, borderBottom: "1px solid gray", borderLeft: "1px solid gray", padding: 10 }}>
                    <div className="row">
                        <div className="col text-center">
                            <Skeleton circle={true} height={heightChart / 1.5} width={heightChart / 1.5} style={{ marginBottom: 5, margin: "auto", marginTop: heightChart * 0.1 }} />
                        </div>
                    </div>
                </div>
            )
    })