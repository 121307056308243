import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { HeaderList, Modal, Tab, TabContent } from '../../components'
import SVG from "react-inlinesvg";
import DetailTabDetail from './detail_tabDetail';
import DetailTabBudget from './detail_tabBudget';
import useSwakelola, { SwaProvider } from './useSwakelola';
import { useHistory, useParams } from 'react-router-dom';
import ModalNeedRevision from '../crudSwakelola/modalNeedRevision';
import ModalAddendum from '../crudSwakelola/modalAddendum';

function DetailSwakelola(props) {
    const { id } = useParams();
    const history = useHistory();
    const {
        swaAction,
        swaState
    } = useSwakelola();
    useEffect(() => {
        if (id) {
            swaAction.getDetailAdmin(id);
        }
    }, [id])
    return (
        <>
            <Modal
                name="modalNeedRevision"
                baseColor={false}
                size='70vh'
            >
                <ModalNeedRevision id_swakelola={id} />
            </Modal>
            <Modal
                name="modalAddendum"
                baseColor={false}
                size='70vh'
            >
                <ModalAddendum id_swakelola={id} />
            </Modal>
            <HeaderList
                title='Swakelola Detail'
                someText='Swakelola\Swakelola Detail'
            />
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body
                    className="p-2"
                >
                    <div className="d-flex">
                        <span className="p-2 bg-light-primary rounded svg-icon svg-icon-4x svg-icon-primary" style={{ width: '65px', height: '65px' }}>
                            <SVG src="/media/svg/icons/Shopping/Cart1.svg" />
                        </span>
                        <div className="w-100 ml-5">
                            <div className="row">
                                <div className="col-md-12 py-2">
                                    <h5 className="d-block">{swaState.dataDetail?.dataSwakelola?.judul}</h5>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div class="input-group mb-3">
                                                <div className="input-group-prepend mr-1">
                                                    <label style={{ width: '100px' }}>Pihak Ke III</label>:
                                                </div>
                                                <div>
                                                    <ul>
                                                        {
                                                            [...(swaState.dataDetail?.dataSwakelola?.nama_pihak3 || [])].map((d, i) => (
                                                                <li>{d.nama}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div class="input-group mb-3">
                                                <div className="input-group-prepend mr-1">
                                                    <label style={{ width: '100px' }}>PIC</label>:
                                                </div>
                                                <span>{swaState.dataDetail?.dataSwakelola?.nama_PIC}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div class="input-group mb-3">
                                                <div className="input-group-prepend mr-1">
                                                    <label style={{ width: '100px' }}>Tahapan</label>:
                                                </div>
                                                <span>{swaState.dataDetail?.dataSwakelola?.status_swakelola}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 text-right'>
                                    {
                                        swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Selesai" && (
                                            <button className='btn btn-sm btn-primary'
                                                onClick={() => swaAction.buttonEditClick(swaState.dataDetail?.dataSwakelola?.swakelola_id)}
                                            ><i className='fa fa-pencil-alt icon-sm'></i> Edit</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 customShadow"
                style={{ marginBottom: '100px !important' }}
            >
                <Card.Body
                    className="p-2"
                >
                    {
                        swaState.dataDetail?.dataSwakelola?.status_swakelola === 'Addendum' && (
                            <div className="bg-light-warning p-2 rounded mb-5 text-dark-50">
                                <div className="row px-3">
                                    <div className="col-auto px-2">
                                        <span className="svg-icon svg-icon-nm svg-icon-dark mx-1">
                                            <SVG src="/media/svg/icons/Files/Compiled-file.svg" />
                                        </span>
                                    </div>
                                    <div className="col px-2">
                                        <span>Note Addendum :</span>
                                        <span className="d-block my-2">{swaState.dataDetail?.dataSwakelola?.note}</span>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                    {
                        (swaState.dataDetail?.dataSwakelola?.status_swakelola === "Need Revision" || swaState.dataDetail?.dataSwakelola?.status_swakelola === "Revisi") && (
                            <div className="bg-light-warning rounded p-4 mb-5 text-dark-50">
                                <span className="d-block mb-1">Reason :</span>
                                <span className="d-block px-5">{swaState.dataDetail?.dataSwakelola?.note}</span>
                            </div>
                        )
                    }
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "detail",
                                title: <><div className="d-flex">
                                    <span className="svg-icon svg-icon-nm svg-icon-primary mx-1">
                                        <SVG src="/media/svg/icons/Files/File.svg" />
                                    </span>
                                    <span>Swakelola Detail</span>
                                </div>
                                </>,
                            },
                            ...(swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Validasi" && (swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Need Revision" || swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Revisi") && swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Rejected") ?
                                [{
                                    id: "budget",
                                    title: <><div className="d-flex">
                                        <span className="svg-icon svg-icon-nm svg-icon-primary mx-1">
                                            <SVG src="/media/svg/icons/Shopping/Money.svg" />
                                        </span>
                                        <span>Swakelola Budget</span>
                                    </div>
                                    </>,
                                }] : []
                            ,
                        ]}
                    >
                        <TabContent id='detail'>
                            <DetailTabDetail />
                        </TabContent>
                        <TabContent id='budget'>
                            <DetailTabBudget />
                        </TabContent>
                    </Tab>
                </Card.Body>
            </Card>
            <div className={`p-2 bg-white rounded shadow-lg floating-footer`} style={{ 'left': 0, 'width': '100%' }}>
                <div className="row">
                    <div className="col-md-6 text-left">
                        {
                            // history.length > 1 && (
                            //     <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            // )
                        }
                        {
                            // swaState.path === '/admin' && (
                            //     <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            // )
                        }
                    </div>
                    <div className="col-md-6 text-md-right">
                        {
                            (swaState.path === '/admin/swakelola-detail' &&
                                (swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Need Revision" || swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Revisi") &&
                                swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Pengadaan" &&
                                swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Addendum"
                            ) && (
                                <div className="d-inline-block">
                                    {
                                        swaAction.validationActionButton() && (
                                            <DropdownButton size="sm" id="dropdown-basic-button" title="Action" className="mx-1">
                                                {
                                                    (swaState.dataDetail?.dataSwakelola?.status_swakelola === "Validasi" &&
                                                        swaAction.validationActionButtonValidasi()) && (
                                                        <>
                                                            <Dropdown.Item className="text-dark" onClick={() => swaAction.submitApprove(id)}>Approve ke Pengadaan</Dropdown.Item>
                                                            <Dropdown.Item className="text-dark" onClick={() => swaAction.submitNeedRevision()}>Need Revision</Dropdown.Item>
                                                            <Dropdown.Item className="text-danger" onClick={() => swaAction.submitReject(id)}>Reject</Dropdown.Item>
                                                        </>
                                                    )
                                                }
                                                {
                                                    swaState.dataDetail?.dataSwakelola?.status_swakelola === "Kontrak Berjalan" && (
                                                        <>
                                                            <Dropdown.Item className="text-dark" onClick={() => swaAction.submitRealisasi(id)}>Finish</Dropdown.Item>
                                                            {
                                                                swaAction.validationActionButtonKBerjalan() && <Dropdown.Item className="text-dark" onClick={() => swaAction.submitToAddendum(id)}>Addendum</Dropdown.Item>
                                                            }

                                                        </>
                                                    )
                                                }
                                            </DropdownButton>
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            // (swaState.path === '/admin/swakelola-detail' && swaState.dataDetail?.dataSwakelola?.status_swakelola === "Need Revision") &&
                            // <button className="btn btn-sm btn-success mx-1">Submit Swakelola</button>
                        }
                        {
                            (
                                swaState.path === '/admin/swakelola-detail' &&
                                swaState.dataDetail?.dataSwakelola?.status_swakelola === "Pengadaan" &&
                                swaAction.validationActionButtonSubmitPengadaan()) &&
                            <button className="btn btn-sm btn-success mx-1"
                                onClick={() => swaAction.submitPengadaan(id)}
                            >Submit ke Kontrak Berjalan</button>
                        }
                        {
                            (
                                swaState.path === '/admin/swakelola-detail' &&
                                swaState.dataDetail?.dataSwakelola?.status_swakelola === "Addendum") &&
                            <button className="btn btn-sm btn-success mx-1"
                                onClick={() => swaAction.submitAddendum(id)}
                            >Submit Addendum</button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <SwaProvider><DetailSwakelola /></SwaProvider>;