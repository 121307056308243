import React, { useEffect, useState } from 'react';
import useEquipment, { EqProvider } from './useEquipment';
import { GridDx, HeaderList, Modal } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Button, Column } from 'devextreme-react/data-grid';
import ModalEditingHistory from '../crudEquipment/modalEditingHistory';
import ModalEditingLogbook from '../crudEquipment/modalEditingLogbook';
import useCrudEquipment, { CrudEquipmentProvider } from '../crudEquipment/useCrudEquipment';
import { status_equipment } from '../../components/utility/utility';
import { PDFExport } from '@progress/kendo-react-pdf';
import { useRef } from 'react';
import ReportingLogbook from './reportingLogbook';
import { Modal as Modals } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import './equipment.css';

const column_history = [
    { fieldName: 'tanggal_mulai_str', caption: 'Tanggal', allowFiltering: false, width: 100 },
    { fieldName: 'equipment_status_str', caption: 'Status', allowFiltering: false, width: 120 },
    { fieldName: 'tanggal_selesai_str', caption: 'Sampai Dengan', allowFiltering: false, width: 100 },
    { fieldName: 'keterangan', caption: 'Keterangan', allowFiltering: false },
    { fieldName: 'biaya', caption: 'Biaya', allowFiltering: false, width: 150 },
    // { fieldName: 'deskripsi', caption: 'Keterangan', allowFiltering: false },
]
const column_logbook = [
    { fieldName: 'status', caption: 'Status Alat', allowFiltering: false },
    { fieldName: 'show_start', caption: 'Mulai', allowFiltering: false },
    { fieldName: 'show_end', caption: 'Sampai Dengan', allowFiltering: false },
    { fieldName: 'pemakai_name', caption: 'Pemakai', allowFiltering: false },
    { fieldName: 'deskripsi', caption: 'Keterangan', allowFiltering: false },
]
function EquipmentDetailAdmin(props) {
    const { eqAction, eqState } = useEquipment();
    const { ceqAction, ceqState } = useCrudEquipment();
    const history = useHistory();
    // const [state, dispatch, action] = useGlobalState()
    const [modalExport, setModalExport] = useState({
        show: false,
        data: {
            status: '',
            logbook: [],
            pic_lab: {
                name: '',
                jabatan: ''
            }
        }
    });

    const refPDf = useRef();
    const pdfRef = useRef();
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            eqAction.getDetailAdmin(id);
        }
    }, [id])
    const [start, setstart] = useState();
    const [end, setEnd] = useState();
    const handleCloseModal = () => {
        setstart();
        setEnd();
        setModalExport({
            show: false,
            data: {
                status: '',
                logbook: [],
                pic_lab: {
                    name: '',
                    jabatan: ''
                }
            }
        });
    }
    const handleTanggalChange = (name = '', val = '') => {

        if (name === 'start') setstart(val || undefined);
        if (name === 'end') setEnd(val || undefined);
        const bantu = {
            start,
            end,
            [name]: val || undefined
        }
        const st_ = moment(bantu.start).format('YYYY-MM-DD');
        const en_ = moment(bantu.end).format('YYYY-MM-DD');
        const temp_ = [...(eqState.dataDetail?.dataLogBook || [])].filter(d => moment(d?.show_start).isBetween(st_, en_, undefined, '[]'));

        setModalExport((e) => ({
            ...e,
            data: {
                status: eqState.dataDetail?.dataEquipment?.nama_status || '',
                logbook: temp_ || [],
                pic_lab: {
                    name: eqState.dataDetail?.dataEquipment?.penanggung_jawab_name || '',
                    jabatan: ''
                }
            }
        }))
    }
    return (
        <div className='container'>
            <Modals backdropClassName='' show={modalExport.show} onHide={handleCloseModal} size="md">
                <Modals.Header>
                    <Modals.Title>Pilih tanggal export</Modals.Title>
                    <i role={'button'} className='fas fa-times' onClick={handleCloseModal}></i>
                </Modals.Header>
                <Modals.Body>
                    <div className="row px-3">
                        <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}><b>Tanggal</b></div>
                        <div className="col-md ">
                            <div className="form-group">
                                <input type={'date'} className="form-control form-control-sm"
                                    value={start || ''}
                                    onChange={(e) => handleTanggalChange('start', e.target.value)}
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}><b>Sampai</b></div>
                        <div className="col-md ">
                            <div className="form-group">
                                <input type={'date'} className="form-control form-control-sm"
                                    value={end || ''}
                                    onChange={(e) => handleTanggalChange('end', e.target.value)}
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}><b>Item Terpilih</b></div>
                        <div className="col-md ">
                            <div className="form-group">
                                <span>{modalExport.data.logbook?.length || 0}</span>
                            </div>
                        </div>
                    </div>
                </Modals.Body>
                <Modals.Footer>
                    <div className='text-right'>
                        <button className='btn btn-sm btn-secondary mr-1' onClick={handleCloseModal}>Batal</button>
                        <button className='btn btn-sm btn-danger mr-1' onClick={() => {
                            if (refPDf.current) {
                                if ((modalExport.data.logbook?.length || 0) < 1) {
                                    Swal.fire({
                                        title: 'Warning',
                                        text: 'Tidak ada item terpilih',
                                        icon: 'warning'
                                    })
                                } else {
                                    refPDf.current.save();
                                }
                            }
                        }}>
                            <i className="far fa-file-pdf"></i>
                            Export
                        </button>
                    </div>
                </Modals.Footer>
            </Modals>
            <Modal
                name="modalEqH"
                baseColor={true}
            >
                <ModalEditingHistory mode={ceqState.modeEditH} data={ceqState.dataEditH} />
            </Modal>

            <Modal
                name="modalEqL"
                baseColor={true}
            >
                <ModalEditingLogbook mode={ceqState.modeEditL} data={ceqState.dataEditL} />
            </Modal>
            <HeaderList
                title={eqState.data_paged?.page_title}
                someText={eqState.data_paged?.page_breadcrumbs}
            />
            <Card
                style={{ marginBottom: '50px' }}
                className="mx-0 mt-0 p-1 customShadow"
            >
                <Card.Body className="p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row mb-5">
                                <div className="col-md-3 mb-5">
                                    <div className="pr-5">
                                        <img style={{ width: '100%', height: 200, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                            src={eqState.dataDetail?.dataEquipment?.path}
                                            onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                    </div>
                                </div>
                                <div className="col-md-9 mb-5">
                                    <h5 className='font-bold'>{eqState.dataDetail?.dataEquipment?.nama}</h5>
                                    <hr />
                                    <span className="d-block text-wrap font-bold mb-1">Deskripsi Alat</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.deskripsi}</span>
                                    <span className="d-block text-wrap font-bold mb-1">Informasi Tambahan</span>
                                    <span className="d-block text-wrap text-dark-50">{eqState.dataDetail?.dataEquipment?.information}</span>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-12 text-right'>
                                    {
                                        (eqAction.validationRole() || eqState.dataDetail?.dataEquipment?.is_penanggung_jawab === true) && (
                                            <button className='btn btn-sm btn-primary'
                                                onClick={() => history.push(`/admin/equipment-edit/${eqState.dataDetail?.dataEquipment?.id}`)}
                                            ><i className='fa fa-pencil-alt icon-sm'></i> Edit</button>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                eqState.dataDetail?.dataEquipment?.status === '5' && (
                                    <div className="row justify-content-center mb-5">
                                        <div className="col-md-12">
                                            <div className='p-5 bg-danger rounded text-white text-center'>
                                                Alat dengan status 'UPP/Dumped' tidak bisa dirubah kembali !
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="row mb-5">
                                <div className="col-md-6">
                                    <span className="d-block font-bold mb-3 ">Detail Alat</span>
                                    <hr />
                                    <span className="d-block text-wrap font-weight-bold mb-1">Status Alat</span>
                                    <span className="d-flex text-wrap mb-5 text-dark-50">{status_equipment(eqState.dataDetail?.dataEquipment?.nama_status)}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Nomor Asset</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.nomor}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Nomor Seri</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.nomor_seri}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Tipe Alat</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.nama_tipe}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Merk</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.merk}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Utilitas Pendukung</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.utilitas}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Supporting Equipment</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.supporting}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Daya Listrik (Watt)</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.daya_listrik}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">PC</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.pc ? "Ada" : "Tidak Ada"}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Lingkup Akreditasi ISO 17025</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.iso_17025 ? "Ada" : "Tidak"}</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Tahun Pembelian / Umur</span>
                                    <span className="d-block text-wrap mb-5 text-dark-50">{eqState.dataDetail?.dataEquipment?.tahun_pembelian} / {eqState.dataDetail?.dataEquipment?.usia} Tahun</span>
                                    <span className="d-block text-wrap font-weight-bold mb-1">Dokumen Terkait</span>
                                    <div className="d-block">
                                        <ul>
                                            {
                                                [...(eqState.dataDetail?.dataEquipment?.list_dokumen || [])].map((d, i) => (
                                                    <li key={i}>
                                                        <a href={eqState.dataDetail?.baseURL + d.file_doc_name_with_url} target='_blank' download>
                                                            {d.file_doc_name}
                                                        </a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <span className="d-block text-wrap font-bold mb-3">Lokasi</span>
                                    <hr />
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Lokasi Alat</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.nama_lokasi}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Lab</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.nama_lab}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Penanggung Jawab</span>
                                    <span className="d-block text-wrap text-dark-50 mb-5">{eqState.dataDetail?.dataEquipment?.penanggung_jawab_name}</span>
                                    <span className="d-block text-wrap mb-1 font-weight-bold">Operator</span>
                                    <ul>
                                        {
                                            [...(eqState.dataDetail?.dataEquipment?.nama_PIC_array_name || [])].map((d, i) => (
                                                <li key={i}>{d}</li>
                                            ))
                                        }
                                    </ul>
                                    {/* <span className="d-block text-wrap mb-5">Product Price</span> */}
                                    <span className="d-block text-wrap font-bold mb-3">Harga</span>
                                    <hr />
                                    <div className="mb-2">
                                        <label className="text-wrap mb-1 font-weight-bold" style={{ width: '100px' }}>Harga Alat</label>
                                        <span className="text-dark-50 mb-5 bg-light-primary mx-2 px-2 py-1 rounded text-primary">$ {eqState.dataDetail?.dataEquipment?.harga_alat}</span>
                                    </div>
                                    <div className="mb-2">
                                        <label className="text-wrap mb-1 font-weight-bold" style={{ width: '100px' }}>Biaya Perbaikan / Pemeliharaan (USD)</label>
                                        <span className="text-dark-50 mb-5 bg-light-warning mx-2 px-2 py-1 rounded text-warning">$ {eqState.dataDetail?.dataEquipment?.biaya_total}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between py-1">
                                        <span className="d-block text-wrap font-bold my-auto">History Status Alat</span>
                                        {
                                            (//eqState.path === '/admin/equipment-detail' && 
                                                (eqState.dataDetail?.dataEquipment?.status !== '5' && (ceqState.editingH || eqState.dataDetail?.dataEquipment?.is_penanggung_jawab === true || eqAction.validationRole()))) && (
                                                <button className="btn btn-sm btn-primary"
                                                    onClick={() => ceqAction.addHistory(id)}
                                                >
                                                    <i className="fa fa-plus icon-nm"></i>
                                                    Status
                                                </button>
                                            )
                                        }
                                    </div>
                                    <hr className="mt-1" />
                                    <GridDx
                                        className='custom-style'
                                        columns={column_history}
                                        data={eqState.dataDetail?.dataHistory}
                                        cellRender={eqAction.customCellRenderHistory}
                                    >
                                        {
                                            ((ceqState.editingH || eqState.dataDetail?.dataEquipment?.is_penanggung_jawab === true || eqAction.validationRole())) && (
                                                <Column width={160} name={"action_history"} caption="Action" headerCellRender={ceqAction.headerCellRender} cellRender={(rowInfo) => {
                                                    return (
                                                        <>
                                                            {
                                                                (rowInfo?.data?.equipment_status_str === 'Maintenance' || rowInfo?.data?.equipment_status_str === 'Broken') && (
                                                                    <div>
                                                                        {
                                                                            rowInfo?.data?.is_active && (
                                                                                <>
                                                                                    <button title='Edit laporan kerusakan' className="btn btn-sm btn-primary text-center py-1 px-2 mx-1"
                                                                                        onClick={(e) => {
                                                                                            if (eqState.dataDetail?.dataEquipment?.is_pic_equipment === true) {
                                                                                                ceqAction.editHistory({ pelapor: true, ...rowInfo?.data }, rowInfo?.data?.id);
                                                                                            } else {
                                                                                                return Swal.fire({
                                                                                                    title: 'Warning',
                                                                                                    text: "Anda tidak memiliki hak akses",
                                                                                                    icon: 'warning',
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
                                                                                    </button>
                                                                                    <button title='Approve laporan kerusakan' onClick={() => {
                                                                                        if (eqState.dataDetail?.dataEquipment?.is_penanggung_jawab === true) {
                                                                                            ceqAction.Approval1(rowInfo?.data?.id);
                                                                                        } else {
                                                                                            return Swal.fire({
                                                                                                title: 'Warning',
                                                                                                text: "Anda tidak memiliki hak akses",
                                                                                                icon: 'warning',
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                        className='btn btn-icon btn-xs btn-primary'>
                                                                                        <i className='fas fa-check-circle'></i>
                                                                                    </button>
                                                                                    {/* <button title='Edit tindakan perbaikan' className="btn btn-sm btn-warning text-center py-1 px-2 mx-1"
                                                                                        onClick={(e) => {
                                                                                            if (rowInfo.data?.is_teknisi === true) {
                                                                                                ceqAction.editHistory({ pelapor: false, ...rowInfo?.data }, rowInfo?.data?.id)
                                                                                            } else {
                                                                                                return Swal.fire({
                                                                                                    title: 'Warning',
                                                                                                    text: "Anda tidak memiliki hak akses",
                                                                                                    icon: 'warning',
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
                                                                                    </button>
                                                                                    <button title='Approve tindakan perbaikan' onClick={() => {
                                                                                        if (rowInfo.data?.is_pelapor) {
                                                                                            ceqAction.Approval2(rowInfo?.data?.id)
                                                                                        } else {
                                                                                            return Swal.fire({
                                                                                                title: 'Warning',
                                                                                                text: "Anda tidak memiliki hak akses",
                                                                                                icon: 'warning',
                                                                                            });
                                                                                        }
                                                                                    }} className='btn btn-icon btn-xs btn-warning'>
                                                                                        <i className='fas fa-check-circle'></i>
                                                                                    </button> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                        <a title='Laporan' href={`/equipment/report-status/${rowInfo.data?.id}`} target='_blank' className="ml-1 btn btn-icon btn-xs btn-outline-danger">
                                                                            <i className="far fa-file-pdf"></i>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }}>
                                                </Column>
                                            )
                                        }
                                    </GridDx>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between py-1">
                                        <span className="d-block text-wrap font-bold my-auto">Log Book Pemakaian</span>
                                        {
                                            ( //eqState.path === '/admin/equipment-detail' && 
                                                eqState.dataDetail?.dataEquipment?.status === '3' && (ceqState.editingL || eqState.dataDetail?.dataEquipment?.is_penanggung_jawab === true || eqState.dataDetail?.dataEquipment?.is_pic_equipment === true || eqAction.validationRole())) && (
                                                <div>
                                                    <button className="btn btn-sm btn-primary mr-1"
                                                        onClick={() => ceqAction.addLogbook(id)}
                                                    >
                                                        <i className="fa fa-plus icon-nm"></i>
                                                        Logbook
                                                    </button>
                                                    <button className="btn btn-sm btn-outline-danger btn-export mb-3"
                                                        onClick={() => {
                                                            setModalExport({ show: true, data: [] })
                                                            // if (refPDf.current) {
                                                            //     refPDf.current.save();
                                                            // }
                                                        }}
                                                    >
                                                        <i className="far fa-file-pdf"></i>
                                                        Export To PDF
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <hr className="mt-1" />
                                    <GridDx
                                        cellRender={eqAction.cellRenderLoogbook}
                                        className='custom-style'
                                        columns={column_logbook}
                                        data={eqState.dataDetail?.dataLogBook}
                                    >
                                        {
                                            (//eqState.path === '/admin/equipment-detail' && 
                                                eqState.dataDetail?.dataEquipment?.status === '3' && (ceqState.editingL || eqState.dataDetail?.dataEquipment?.is_penanggung_jawab === true || eqState.dataDetail?.dataEquipment?.is_pic_equipment === true || eqAction.validationRole())) && (
                                                <Column caption="Action" type="buttons" headerCellRender={ceqAction.headerCellRender} >
                                                    <Button name="customEdit" visible={true} render={(e) => ceqAction.editBtnL(e, id)} />
                                                    <Button name="delete" visible={true} render={ceqAction.deleteBtnL} />
                                                </Column>
                                            )
                                        }
                                    </GridDx>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div
                style={{
                    position: "absolute",
                    left: "-1000px",
                    top: 0,
                }}
            >
                <PDFExport c forcePageBreak=".page-break" landscape={true} repeatHeaders={true} paperSize={'A4'} margin={'2cm'} ref={refPDf}
                    fileName={
                        `Logbook_equipment_${eqState.dataDetail?.dataEquipment?.id}.pdf`
                        // `Logbook_equipment_  ${eqState.dataDetail?.dataEquipment?.nomor}.pdf`
                    }>
                    <ReportingLogbook data={modalExport.data} />
                </PDFExport>
            </div>
        </div>
    );
}

export default () => <CrudEquipmentProvider><EqProvider><EquipmentDetailAdmin /></EqProvider></CrudEquipmentProvider>;