import React from 'react';
import Img2018 from './image/2018.png';
import Img2019 from './image/2019.png';
import Img2020 from './image/2020.png';
import Img2021 from './image/2021.png';
import './home_index.css';
import useHomIndex, { HomeIndexProvider } from './use_home_index';
import useIndexLandPage from '../use_index';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';
import { CarouselSpiirit } from '../../../components';
import Achiev from './image/LP-Achievement.svg';
import DummyImage from './image/LP-Banner utama.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../../contexts/useGlobalState';

const home = [
    'https://bend.spiirit.net/uploads/spiirit/product/00_SAMPUL_CAROUSEL_01.png',
    'https://bend.spiirit.net/uploads/spiirit/product/00_SAMPUL_CAROUSEL_02.png',
    'https://bend.spiirit.net/uploads/spiirit/product/00_SAMPUL_CAROUSEL_03.jpg',
    'https://bend.spiirit.net/uploads/spiirit/product/00_SAMPUL_CAROUSEL_04.png',
    'https://bend.spiirit.net/uploads/spiirit/product/00_SAMPUL_CAROUSEL_05.png',
]

const archive_sources = [
    { tahun: '2018', image_sources: Img2018, url: 'https://bend.spiirit.net/uploads/archive/RTC_Annual_Outlook_2018.pdf' },
    { tahun: '2019', image_sources: Img2019, url: 'https://bend.spiirit.net/uploads/archive/RTC_INNOVATION_UPDATE_2019.pdf' },
    { tahun: '2020', image_sources: Img2020, url: 'https://sway.office.com/OILltHtTj009VfR7' },
    { tahun: '2021', image_sources: Img2021, url: 'https://sway.office.com/ZOhg6xQGn1A7wnoU' },
    { tahun: '2023', image_sources: 'https://bend.spiirit.net/uploads/spiirit/product/00_SAMPUL_CAROUSEL_03.jpg', url: 'https://bend.spiirit.net/uploads/archive/BOOKLET_PRODUK_INOVASI_DAN_LAYANAN_LAB_RTI_2023.pdf' },
]
function HomeIndex(props) {
    const { homIndState } = useHomIndex();
    const history = useHistory();
    const { indAction: {
        toAllEventDetail = () => null
    }, indState: {
        dataType = []
    } } = useIndexLandPage();
 
    const [, , action] = useGlobalState();
    return (
        <div className='bg-white'>
            {/* new ui */}
            <div className='position-relative'>
                <img src={DummyImage} style={{
                    position: 'absolute',
                    width: '100%',
                    minHeight: '400px',
                    objectFit: 'cover',
                    bottom: '-100px'
                }} />
                <div className='row justify-content-center mx-auto container px-0'>
                    <div className='col-12' style={{
                        marginBottom: '40px'
                    }}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop={true}
                            className='test-carousel rounded cs-crsl-prdk'
                            axis='vertical'
                            showIndicators={true}
                            showThumbs={false}
                            showArrows={false}
                            interval={10000}
                            renderIndicator={(onClickHandler, isSelected, index, label) => {
                                const defStyle = "text-muted bg-transparent rounded";
                                return (
                                    <span
                                        style={{ padding: 1, cursor: "pointer" }}
                                        className={isSelected ? `${defStyle} bg-light-secondary` : defStyle}
                                        onClick={onClickHandler}
                                        onKeyDown={onClickHandler}
                                        value={index}
                                        key={index}
                                        role="button"
                                        tabIndex={0}
                                        aria-label={`${label} ${index + 1}`}
                                    >
                                        {'0' + index}
                                    </span>
                                );
                            }}
                        >
                            {
                                home.map((d, i) => (
                                    <div key={i} style={{ minHeight: '200px', maxHeight: '600px' }}>
                                        <LazyLoadImage
                                            src={d}
                                            key={i}
                                            width={'100%'}
                                            className='image-1'
                                            alt='Image Alt'
                                        />
                                    </div>
                                ))
                            }
                        </Carousel>
                        <div className='row'>
                            <div className='col-md-6 text-white mb-2 mb-md-0 mb-lg-0'>
                                <div style={{
                                    fontSize: '20pt',
                                    fontWeight: 'bold',
                                }}>Produk Inovasi RTI</div>
                                <span className='d-block mt-2'>Portofolio produk inovasi RTI yang telah dikembangkan berdasarkan klasifikasinya</span>
                            </div>
                            <div className='col-md-6 mb-2 mb-md-0 mb-lg-0'>
                                <button className='button-cs-produk center-corner d-inline-flex float-left float-md-right float-lg-right'
                                    onClick={() => {
                                        action.setForm('product_type', 0);
                                        history.push('/landing-page/product');
                                    }}
                                >
                                    <div className="background-button-cs-produk"></div>Lihat semua produk
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white' style={{ marginBottom: '35px' }}>
                <div className='row justify-content-center mx-auto container px-0' style={{
                    position: 'relative',
                    minHeight: '200px'
                }}>
                    <div className='col-12' >
                        <div style={{ top: '-90px', width: '100%', left: 0 }}>
                            <CarouselSpiirit
                                autoPlay={false}
                                showItem={1}
                                jumlahItem={6}
                                withButton={true}
                                showIndicators={true}
                                modeNextPrev={2}
                                infiniteLoop={false}
                                className="custom-carousel-overflow custom-carousel-control-dots custom-carousel-dots"
                            >
                                {
                                    dataType.map((x, j) => (
                                        <div className='row mb-3' key={j}>
                                            {
                                                [...(x?.col || [])].map((d, i) => (
                                                    <div className='col-md-6 mb-3' key={i}>
                                                        <div style={{
                                                            minHeight: '200px',
                                                        }} className='position-relative m-3 overflow-hidden rounded customShadow' key={i}
                                                        >
                                                            <LazyLoadImage
                                                                src={d?.image_link}
                                                                className='image-2 bg-light'
                                                                alt='Image Alt'
                                                                key={i}
                                                            />
                                                            <div className='test-hovearble bg-danger'>
                                                                <p><b>{d?.name}</b></p>
                                                                <span className='d-block text-wrap' style={{ fontSize: '8pt' }}>{d?.keterangan}</span>
                                                                <div className='mt-1'>
                                                                    <i className='fa fa-arrow-right rounded-circle p-2 m-0'
                                                                        onClick={() => {
                                                                            action.setForm('product_type', d?.id);
                                                                            history.push('/landing-page/product');
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </CarouselSpiirit>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white' style={{
                marginBottom: '50px'
            }}>
                <div className='row justify-content-center mx-auto container px-0'>
                    <div className='col-12'>
                        <hr className='ml-3' />
                        <div className='row mb-3'>
                            <div className='col-md-6 text-white mb-2 mb-md-0 mb-lg-0'>
                                <div style={{
                                    fontSize: '20pt',
                                    fontWeight: 'bold',
                                }} className='font-color-cs'>Achievement</div>
                                <span className='d-block mt-2 font-color-cs'>Penghargaan yang telah diraih RTI sepanjang tahun</span>
                            </div>
                            <div className='col-md-6 mb-2 mb-md-0 mb-lg-0'>
                                <button className='button-cs-event center-corner d-inline-flex float-left float-md-right float-lg-right'
                                    onClick={() => history.push('/landing-page/achievement')}
                                >
                                    <div className="background-button-cs-event"></div>Lihat semua achievement
                                </button>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            {
                                [...(homIndState.dataLand.data_achievements || [])].splice(0, 3).map((d, i) => (
                                    <div className='col-md-4 mb-2 mb-md-0 mb-lg-0' key={i}>
                                        <div className='card customShadow rounded overflow-hidden h-100'>
                                            <img src={d?.photo_location} height={'250px'} style={{ objectFit: 'cover' }}></img>
                                            <div className='bg-white m-3'>
                                                <span className='d-block font-weight-boldest mb-2'>{d?.name}</span>
                                                <span><i className='fa fa-star icon nm text-secondart'></i> {d?.level}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-relative mb-5' style={{ paddingTop: '30px' }}>
                <img src={Achiev} className='custom-bg-achievement'></img>
                <div className='row justify-content-center mx-auto container px-0'>
                    <div className='col-12 text-white'>
                        <div style={{
                            fontSize: '20pt',
                            fontWeight: 'bold',
                        }}>Archive</div>
                        <span className='d-block mt-2'>Kumpulan laporan kinerja tahunan RTI dan media informasi lainnya</span>
                        <CarouselSpiirit
                            autoPlay={false}
                            showItem={3}
                            jumlahItem={6}
                            withButton={true}
                            showIndicators={true}
                            modeNextPrev={2}
                            infiniteLoop={false}
                            className="custom-carousel-overflow custom-carousel-control-dots custom-carousel-dots"
                        >
                            {
                                archive_sources.map((d, i) => (
                                    <div key={i} className='p-5'>
                                        <div style={{ height: '250px' }} className='position-relative overflow-hidden rounded customShadow'>
                                            <img src={d.image_sources} style={{ 'height': '100%', 'objectFit': 'cover', 'backgroundColor': 'whitesmoke' }} />
                                        </div>
                                        <a href={d.url} target='_blank'>
                                            <div className='btn-carousel-anual-rpt customShadow rounded'>
                                                <span className='m-auto'
                                                    style={{ fontSize: '8pt' }}
                                                >{d?.tahun === "2023" ? "Booklet Produk Inovasi RTI" : "Annual Report"} {d.tahun}<i className='fas fa-external-link-alt pr-2 text-white btn-carousel-anual-rpt-icon icon-sm ml-1'></i></span>
                                            </div>
                                        </a>
                                    </div>
                                ))
                            }
                        </CarouselSpiirit>
                        <hr style={{ 'marginTop': '30px' }} />
                    </div>
                </div>
            </div>
            <div className='bg-white' style={{
                marginBottom: '50px'
            }}>
                <div className='row justify-content-center mx-auto container px-0'>
                    <div className='col-12 pt-5'>
                        <div className='row mb-3'>
                            <div className='col-md-6 font-color-cs  mb-2 mb-md-0 mb-lg-0'>
                                <div style={{
                                    fontSize: '20pt',
                                    fontWeight: 'bold',
                                }}>Event</div>
                                <span className='d-block mt-2'>Kumpulan penyelenggaraan event di internal dan eksternal RTI</span>
                            </div>
                            <div className='col-md-6  mb-2 mb-md-0 mb-lg-0'>
                                <button className='button-cs-event center-corner d-inline-flex float-left float-md-right float-lg-right'
                                    onClick={() => history.push('/landing-page/event')}
                                >
                                    <div className="background-button-cs-event"></div>Lihat semua event
                                </button>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            {
                                [...(homIndState.dataLand.data_events || [])].splice(0, 3).map((d, i) => (
                                    <div className='col-md-4 mb-2 mb-md-0 mb-lg-0' key={i}>
                                        <div className='card customShadow rounded overflow-hidden h-100 card-land-produk'
                                            onClick={() => toAllEventDetail(d)}
                                        >
                                            <img src={d?.poster_location} height={'250px'} style={{ objectFit: 'cover' }}></img>
                                            <span className='text-wrap font-weight-boldest mb-3 pt-5 px-4'>{d?.name}</span>
                                            <div className='footer-card-event' style={{ marginTop: "10px" }}>
                                                <div>
                                                    <span className='d-block text-wrap mb-2'><i className='far fa-clock px-2'></i>{d?.time_start} - {d?.time_end}</span>
                                                    <span className='d-block text-wrap mb-2'><i className='fas fa-calendar px-2'></i>{moment(d?.date_start).format('MMMM DD, YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>                       
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (props) => <HomeIndexProvider><HomeIndex {...props} /></HomeIndexProvider>;