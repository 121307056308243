import React, { isValidElement, memo, useEffect, useRef } from 'react'
import { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import './customFilterChart.css'

export default memo(function ({ children, title, subtitle, onChange, value, option = [], cardStyle = true, withBorder = true, customOption = <></> }) {
    // const refTemp = useRef()

    // useEffect(() => {
    // }, [refTemp.current])    
    return (
        <Card
            className={(cardStyle ? "m-0 p-0 h-100 mb-5 customShadow" : "m-0 p-0 h-100 mb-5 bg-transparent") + (withBorder ? '' : ' border-0')}
        >
            <Card.Header>
                <div className="row pr-5 pl-5">
                    <div className={Array.isArray(option) ? "col-8" : "col-12"} style={{ paddingLeft: 0 }}>
                        <div className="row">
                            <div className="col">
                                <span className="ctsmFilterTitle">{title}</span>
                                <span className="ctsmFilterSubTitle">{subtitle}</span>
                            </div>
                        </div>
                    </div>
                    {
                        <div className="col-4 text-right" style={{ paddingRight: 0 }}>
                            {
                                [...(option || [])].length > 0 ? (
                                    <select className="form-control form-control-sm" value={value} onChange={(e) => onChange ? onChange(e.target.value) : null}>
                                        {
                                            Array.isArray(option) ? (
                                                option.map((y, i) => (
                                                    <option key={i} value={y.value}>{y.name}</option>
                                                ))
                                            ) : null
                                        }
                                    </select>

                                ) : null
                            }
                            {
                                customOption
                            }
                        </div>
                    }
                </div>
            </Card.Header>
            <Card.Body className="p-2">
                <div className="p-5">
                    <div className="row  m--padding-15">
                        <div className="col-md-12">
                            <div className="row p-5">
                                <div className="col-md-12" >
                                    {
                                       children
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
})