import React, { useEffect, useState } from 'react'
import { RadioGrp } from '../../components';
// import FileUploader from 'devextreme-react/file-uploader';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import FileUploadDx from '../fileUploadDx/fileUploadDx';

export default function ({ id, data_year_form }) {
    const [state, , action] = useGlobalState();
    const [status, setStatus] = useState('1');
    const [reason, setReason] = useState('');
    const [document, setDocument] = useState([]);
    const [document_delete, setDocument_delete] = useState([]);
    useEffect(() => {
        if (state.modal?.modalStopProjectAbi === true) {
            resetEditor();
            getDataReport(id);
        }
    }, [state.modal?.modalStopProjectAbi])

    function resetEditor() {
        setStatus('1');
        setReason('');
        setDocument([]);
        setDocument_delete([]);
    }
    function getDataReport(id_research) {
        action.getData(`/api/get-data-abi-report-request?abi_id=${id_research}&tipe=${1}`, {}, null, 'loadingStop').then((resp) => {
            if (resp.code === '00') {
                if (resp.data.data_request) {
                    setDocument([...(resp.data?.data_report || [])].map(d => {
                        return {
                            file_location: d?.file_location,
                            file_name: d?.file_name,
                            is_new: false
                        }
                    }));
                }
            }
        })
    }
    // function fileFinalReport(data) {
    //     const file = data.value?.[0]
    //     if (data.value?.[0] && file.size <= 10000000) {
    //         action.postData(
    //             {
    //                 url: "/api/base/upload-file-temp",
    //                 data: {
    //                     "File": data.value?.[0],
    //                 },
    //                 withFile: true,
    //                 withLoading: false,
    //                 withAlert: false
    //             }
    //         ).then((resp) => {
    //             if (resp.code === "00") {
    //                 setDocument([{
    //                     "file_location": resp.data?.data_file_location,
    //                     "file_name": resp.data?.data_filename,
    //                     "is_new": true
    //                 }, ...document])
    //             }
    //         })
    //     } else if (data.value?.[0] && file.size > 10000000) {
    //         return Swal.fire({
    //             title: 'Error',
    //             text: "File lebih dari 15 Mb",
    //             icon: 'error',
    //         })
    //     }
    // }
    function fileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setDocument([{
                "file_location": resp.data?.data_file_location,
                "file_name": resp.data?.data_filename,
                "is_new": true
            }, ...document])
        }
    }
    function deleteSumberFile(file, index) {
        if (file?.is_new === true) {
            action.postData(
                {
                    url: "/api/base/delete-file-temp?fileName=" + file?.file_name,
                    withFile: false,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code !== "00") {
                    alert(resp.message)
                } else {
                    setDocument(document.filter(d => d !== file));
                    setDocument_delete([{ file }, ...document_delete]);
                }
            })
        }
        if (file?.is_new === false) {
            setDocument(document.filter(d => d !== file));
            setDocument_delete([file, ...document_delete]);
        }
    }
    function sendRequestClick() {
        if (status === '1') {
            finishedRequest();
        } else {
            abbortedRequest();
        }
    }
    function finishedRequest() {
        const data = {
            "id": null,
            "abi_id": id || '',
            "document": document || [],
            "document_delete": document_delete || [],
            "year": '',
            "is_final_report": true,
            "reason": reason || ''
        };
        const validate = validationFinish(data);
        if (validate.isValid === true) {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-success mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                    '<strong>Finished Project ?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-abi-monitoring-crud/submit-finished-request",
                            data: data,
                        },
                        () => window.location.reload()
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal("modalStopProjectAbi")
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message.join(', ') + " Tidak Boleh Kosong",
                icon: 'warning',
            })
        }
    }
    function abbortedRequest() {
        const data = {
            "id": null,
            "abi_id": id || '',
            "document": document || [],
            "document_delete": document_delete || [],
            "year": '',
            "is_final_report": true,
            "reason": reason || ''
        };
        const validate = validationAbort(data);
        if (validate.isValid === true) {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-danger mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                    '<strong>Aborted Project ?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-abi-monitoring-crud/submit-aborted-request",
                            data: data,
                        },
                        () => window.location.reload()
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal("modalStopProjectAbi")
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message.join(', ') + " Tidak Boleh Kosong",
                icon: 'warning',
            })
        }
    }
    function validationAbort(data) {
        // console.log('dataaa', data);
        var isValid = true;
        var message = [];
        if (data.reason === '') {
            isValid = false;
            message = [...message, 'Reason']
        }
        if ([...(data.document || [])].length === 0) {
            isValid = false;
            message = [...message, 'Final Report']
        }
        return {
            isValid,
            message
        }
    }
    function validationFinish(data) {
        // console.log('dataaa', data);
        var isValid = true;
        var message = [];
        if (data.reason === '') {
            isValid = false;
            message = [...message, 'Reason']
        }
        if ([...(data.document || [])].length === 0) {
            isValid = false;
            message = [...message, 'Final Report']
        }
        return {
            isValid,
            message
        }
    }
    return (
        <>
            <span>Stop Project</span>
            <hr />
            {
                state.data_request && (
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <div className="p-3 bg-light-warning rounded">
                                <span className="text-wrap">{state.data_request?.reason}</span>
                            </div>
                        </div>
                    </div>
                )
            }
            <form>
                <div className="form-group row">
                    <label htmlFor="status" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-10 my-auto">
                        <div className="form-control border-0" id="status">
                            <RadioGrp
                                modeRow={true}
                                onChange={(e) => setStatus(e)}
                                value={status}
                                data={[
                                    { label: 'Finished', value: '1' },
                                    { label: 'Aborted', value: '0' },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="reason" className="col-sm-2 col-form-label">Reason</label>
                    <div className="col-sm-10">
                        <textarea
                            rows={4}
                            className="form-control"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            id="reason" placeholder="Reason" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="reason" className="col-sm-2 col-form-label">Final report<br /><span className="text-danger">*max file 10Mb</span></label>
                    <div className="col-sm-10">
                        <div className="p-3 border rounded">
                            <FileUploadDx
                                className="custom-dx-fileuploader-file-name"
                                showFileList={true}
                                multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb"
                                accept="*"
                                onUploaded={fileUploaded}
                                maxFileSize={10000000}
                                uploadMode="instantly"
                            />
                            {/* <FileUploader
                                onValueChanged={(e) => fileFinalReport(e)}
                                className="custom-dx-fileuploader-file-name"
                                showFileList={false} multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 15 Mb" accept="*" maxFileSize={15000000} uploadMode="useForm" >

                            </FileUploader> */}
                            <div className="d-block">
                                {
                                    [...(document || [])].map((d, i) => (
                                        <div className="d-flex flex-row justify-content-between mb-1">
                                            <span style={{ fontSize: '8pt' }}>{d.file_name}</span>
                                            <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger" onClick={() => deleteSumberFile(d, i)}></i>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-md-12 text-md-right">
                    <button className="btn btn-sm btn-danger mx-1" onClick={() => sendRequestClick()}>Send Request</button>
                    <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => action.toggleModal("modalStopProjectAbi")}>Close</button>
                </div>
            </div>
        </>
    )
}