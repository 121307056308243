import React, { useRef, useState } from 'react';
import { GridDx } from '../../../../../components';
import useCrudPengujian from '../useCrudPengujian';
import LabStatus from '../../../../nonAdmin/laboratory/component/labStatus';
import ModalDiagram from '../../../../nonAdmin/laboratory/component/modalDiagram';
import { DropDownButton } from 'devextreme-react';
const column = [
    { fieldName: "action_", caption: "Action", allowSorting: false, allowFiltering: false, width: 200 },
    { fieldName: "user_name", caption: "Customer" },
    { fieldName: "institution_nama", caption: "Direktorat" },
    { fieldName: "institution_sub_nama", caption: "Sub Holding" },
    { fieldName: "created_at_str", caption: "Tanggal Pengujian" },
    { fieldName: "updated_at_str", caption: "Last Modified" },
    { fieldName: "judul", caption: "Judul Pengujian" },
    { fieldName: "lab_test_status_id_str", caption: "Status Pengujian" },
    { fieldName: "note_history", caption: "Keterangan" },
];
function TabTestingReport(props) {
    const tableRef = useRef();
    const { cpState, cpAction } = useCrudPengujian();
    const [modald, setmodald] = useState(false);

    const viewModal = (id_lab_test = "") => {
        cpAction.setModalView({ show: true, default_data: { id_lab_test, opt: 4 } });
    }
    const updateModal = (data = {}) => {
        cpAction.setModalTre({ show: true, default_data: { ...data } });
    }
    const cellrender = (rowInfo) => {
        if (rowInfo.column.name === "action_") {
            return <div className='flex'>
                {/* <button
                    onClick={() => viewModal(rowInfo?.data?.id)}
                    className="btn btn-sm btn-light-primary mr-2"
                >
                    View
                </button> */}
                <DropDownButton
                    text="View"
                    className="bg-primary rounded customColorIcon customColotText mr-2"
                    icon="preferences"
                    dropDownOptions={{ width: 250 }}
                    displayExpr="name"
                    keyExpr="id"
                    items={[
                        {
                            id: 1,
                            name: "History",

                        },
                        {
                            id: 2,
                            name: "Detail",

                        },
                    ]}
                    onItemClick={(e) => {
                        const { id } = e.itemData
                        if (id === 1) {
                            window.open(`/admin/admin-lab/view-pengujian-history/${rowInfo.data?.id}`)
                        }
                        if (id === 2) {
                            viewModal(rowInfo?.data?.id);
                        }
                    }}
                />
                {
                    rowInfo.data?.status_id === 11 && <button
                        onClick={() => updateModal({ ...rowInfo?.data })}
                        className="btn btn-sm btn-light-danger mr-2"
                    >
                        Update
                    </button>
                }

            </div>
        } else if (rowInfo.column.name === "lab_test_status_id_str") {
            return <LabStatus status={rowInfo.data?.status_id} >{rowInfo.value}</LabStatus>
        }
        else {
            return <p>{rowInfo.value}</p>
        }
    }
    return (
        <div>
            <ModalDiagram show={modald} onHide={() => setmodald(false)} />
            {React.useMemo(() => (
                <GridDx
                    toolbar={<div>
                        <button className='btn btn-sm btn-primary' onClick={() => setmodald(true)}>View Diagram</button>
                    </div>}
                    className='custom-style-master'
                    ref={tableRef}
                    data={[...(cpState.dataTre || [])]}
                    cellRender={cellrender}
                    columns={column}
                    pagination={true}
                    orderingDrag={false}
                    withAction={false}
                    searchForm={true}
                    currentPage
                    show

                ></GridDx>
            ), [cpState.dataTre])}
        </div>
    );
}

export default TabTestingReport;