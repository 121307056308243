import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import useMpps from '../_context';
import SVG from "react-inlinesvg";
import Skeleton from '../../../components/skeleton';
import { ChartDx, GridDx, SwitchBtn } from '../../../components';
import { Link } from 'react-router-dom';

const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]

const columns = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "cut_off_mtyr", caption: "Cutoff", width: 80 },
    { fieldName: "function_sn", caption: "Fungsi", width: 80 },
    { fieldName: "chief_sn", caption: "Chief", width: 80 },
    { fieldName: "duk_operasional_opt_str", caption: "Sub-Holding", width: 115 },
    { fieldName: "perform_work", caption: "Performance/Progress", width: 115 },
    { fieldName: "status_progress_1", caption: "1", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_2", caption: "2", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_3", caption: "3", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_4", caption: "4", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_5", caption: "5", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_6", caption: "6", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_7", caption: "7", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_8", caption: "8", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_9", caption: "9", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_10", caption: "10", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_11", caption: "11", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_12", caption: "12", width: 50, headerFilterSource: progresFilter },
]

function Dashboard(props) {
    const { mppsAction, mppsState } = useMpps();
    const [sCurve1, SetScurve1] = useState(true);
    const [sCurve2, SetScurve2] = useState(true);

    useEffect(() => {
        mppsAction.GetDashboard(mppsState.filter?.tahun, mppsState.filter?.bulanGet, mppsState.active, true);
    }, []);

    return (
        <div>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-3">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-auto col-md-auto col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom pr-3">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Project</span>
                                    <span className="d-block"><strong>{(mppsState.data_dash?.data_JumlahProject?.total || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Project Running</span>
                                    <span className="d-block"><strong>{mppsState.data_dash?.data_JumlahProject?.project_running || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Finished</span>
                                    <span className="d-block"><strong>{mppsState.data_dash?.data_JumlahProject?.finished || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Aborted</span>
                                    <span className="d-block"><strong>{mppsState.data_dash?.data_JumlahProject?.aborted || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Review</span>
                                    <span className="d-block"><strong>{mppsState.data_dash?.data_JumlahProject?.review || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Need Revision</span>
                                    <span className="d-block"><strong>{mppsState.data_dash?.data_JumlahProject?.need_revision || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Not Yet Reported</span>
                                    <span className="d-block"><strong>{mppsState.data_dash?.data_JumlahProject?.project_continue || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom border-right-custom px-md-3">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                    <span className="d-block">$ <strong>{(mppsState.data_dash?.data_JumlahProject?.total_plan_budget || 0)}/{(mppsState.data_dash?.data_JumlahProject?.total_actual_budget || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 h-100 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mb-5">
                        <div className="col-md-12 m-0">
                            <div className="overflow-auto">
                                <table className="table m-0 cstm-perform-table">
                                    <tbody>
                                        <tr className="overflow-auto">
                                            {
                                                mppsState.loadingActive ? (
                                                    <>
                                                        <td><Skeleton height={100} /></td>
                                                        <td><Skeleton height={100} /></td>
                                                        <td><Skeleton height={100} /></td>
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            [...(mppsState.data_dash?.data_HeaderMonitoring || [])].map((d, i) => (
                                                                <td key={i}>
                                                                    <Card
                                                                        style={{ cursor: 'pointer' }}
                                                                        key={i}
                                                                        onClick={() => mppsAction.handleActiveChange(d.function_id)}
                                                                        className={d.function_id === mppsState.active ? 'cstm-Active customShadow m-0 p-0 h-100' : 'cstm customShadow m-0 p-0 h-100'}
                                                                    >
                                                                        <Card.Body className="p-2">
                                                                            <div className="justify-content-center">
                                                                                <span className="d-flex font-weight-bold" style={{ fontSize: '12px' }}>{d.short_name}</span>
                                                                                <div className="col"><h5 style={{ textAlign: 'end' }}><strong>{parseFloat((d.perform * 100)).toFixed(1)}</strong>%</h5></div>
                                                                                <div className="progress border border-primary" style={{ marginLeft: '10px', backgroundColor: 'white', marginRight: '10px' }}>
                                                                                    <div className="progress-bar" role="progressbar" style={{ width: (parseFloat((d.perform_all * 100)).toFixed(1)) + '%' }} aria-valuenow={parseFloat((d.perform_all * 100)).toFixed(1)} aria-valuemin="0" aria-valuemax={parseFloat((d.perform * 100)).toFixed(1)}></div>
                                                                                </div>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <span style={{ textAlign: 'start', paddingLeft: '10px', paddingTop: '7px' }}>{parseFloat((d.perform_all * 100)).toFixed(1)}%</span>
                                                                                    <span className="text-right" style={{ textAlign: 'end', paddingRight: '10px', paddingTop: '7px' }}>{mppsState.filter?.data_tahun_default}</span>
                                                                                </div>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </td>
                                                            ))
                                                        }
                                                    </>
                                                )
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-md-6 mb-5">
                            <div className="myContainer">
                                <div className="row mr-2 ml-2">
                                    <div className="col-md-12">
                                        <div className="row m-0">
                                            <div className="col-10 p-0">
                                                <span className="ctsmFilterTitle">S-Curve RTI Projects</span>
                                                <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI</span>
                                            </div>
                                            <div className="col-2" style={{ paddingRight: 0 }}>
                                                <SwitchBtn value={sCurve1} onChange={(e) => SetScurve1(e)} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row ">
                                            <div className="col-md-12">
                                                {React.useMemo(() => (
                                                    <ChartDx
                                                        loading={mppsState.loading_dash}
                                                        dataSource={mppsState.data_dash?.dsCurve1}
                                                        type="spline"
                                                        withTitle={false}
                                                        showLegend={true}
                                                        toolsExport={false}
                                                        LegendverticalAlignment="bottom"
                                                        LegendhorizontalAlignment="center"
                                                        LegenditemTextPosition="right"
                                                        onRenderLabelChart={function (e) { }}
                                                        onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: ${e.valueText}` })}
                                                        yAxisArgs={sCurve1 ? [

                                                            {
                                                                name: "Plan",
                                                                valueField: "plan",
                                                                color: '#D1D1D1'
                                                            },
                                                            {
                                                                name: "Realisasi",
                                                                valueField: "actual",
                                                                color: '#326BD5'
                                                            }

                                                        ] : [
                                                            {
                                                                name: "Perform",
                                                                valueField: "perform",
                                                                color: 'red'
                                                            }
                                                        ]}
                                                    >

                                                    </ChartDx>
                                                ), [mppsState.loading_dash, sCurve1])}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="myContainer">
                                <div className="row mr-2 ml-2">
                                    <div className="col-md-12">
                                        <div className="row m-0">
                                            <div className="col-10 p-0">
                                                <span className="ctsmFilterTitle">S-Curve RTI Budget</span>
                                                <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan pragnosis proyek proyek RTI (Ribu USD)</span>
                                            </div>
                                            <div className="col-2" style={{ paddingRight: 0 }}>
                                                <SwitchBtn value={sCurve2} onChange={(e) => SetScurve2(e)} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    React.useMemo(() => (
                                                        <ChartDx
                                                            loading={mppsState.loading_dash}
                                                            dataSource={mppsState.data_dash?.dsCurve2}
                                                            type="spline"
                                                            withTitle={false}
                                                            showLegend={true}
                                                            toolsExport={false}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onRenderLabelChart={function (e) { }}
                                                            onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: $ ${e.valueText}` })}
                                                            yAxisArgs={sCurve2 ? [
                                                                {
                                                                    name: "Proposed Budget",
                                                                    valueField: "plan",
                                                                    color: '#D1D1D1'
                                                                },
                                                                {
                                                                    name: "Realisasi",
                                                                    valueField: "actual",
                                                                    color: '#F08044'
                                                                }

                                                            ] : [
                                                                {
                                                                    name: "Perform",
                                                                    valueField: "perform",
                                                                    color: 'red'
                                                                }
                                                            ]}
                                                        >

                                                        </ChartDx>
                                                    ), [mppsState.loading_dash, sCurve2])
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card >
            <Card
                className="m-0 p-0 h-100 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mb-5">
                        <div className="col-md-2 mb-5">
                            <div className="row h-100 m-0">
                                <div className="col-md-12 p-0">
                                    <div className="m-portlet__head-text" style={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'Poppins' }}>
                                        <span className="ctsmFilterTitle">PERFORMANCE</span>
                                        <span className="ctsmFilterSubTitle">% Performance / Jumlah Project</span>
                                    </div>
                                    <hr />
                                    {
                                        (mppsState.loading_dash) ? (
                                            <Skeleton height={200} />
                                        ) : [...(mppsState.data_dash?.data_SpendYearToDate2 || [])].map((d, i) => (
                                            <div style={{ height: '35px', paddingBottom: '7px', paddingTop: '7px' }} key={i}>
                                                <div key={i} className="rounded" style={{ backgroundColor: '#7F37C7', color: 'white', padding: '5px', display: 'flex', justifyContent: 'space-between', height: '20px' }}>
                                                    <span style={{ fontSize: 9 }}>
                                                        {d?.short_name || ''}:
                                                    </span>
                                                    <span style={{ fontSize: 9 }}>
                                                        {parseFloat(d?.perform_budget * 100).toFixed(1)}% / {d?.jumlah_project || 0}
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        (mppsState.loading_dash) ? (
                                            <Skeleton height={30} />
                                        ) : (
                                            <div className="mt-10" style={{ minHeight: 30 }}>
                                                <div className="w-100 text-white p-1 rounded" style={{ backgroundColor: '#7F37C7', minHeight: 30, position: 'absolute', bottom: 0 }}>
                                                    <span className="d-block text-left" style={{ fontSize: 9 }}>
                                                        {[...(mppsState.data_dash?.data_HeaderMonitoring || [])].find(d => d?.function_id === mppsState.active)?.short_name || ''}
                                                    </span>
                                                    <span className="d-block text-right" style={{ fontSize: 12 }}>
                                                        {mppsState.helperPerformance?.text || ''}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 mb-5">
                            <div className="row h-100">
                                <div className="col-md-6">
                                    <div className="row h-100 m-0 mb-5">
                                        <div className="col-12 p-0">
                                            <span className="ctsmFilterTitle text-uppercase">project performance detail</span>
                                            <span className="ctsmFilterSubTitle">Jumlah proyek berdasarkan status kinerjanya</span>
                                            <hr />
                                            {
                                                React.useMemo(() => (
                                                    <>
                                                        <ChartDx
                                                            className="h-100"
                                                            loading={mppsState.loading_dash}
                                                            height={'auto'}
                                                            barWidth={20}
                                                            xLabel={false}
                                                            labelChart={true}
                                                            argumentField="short_name"
                                                            type="stackedBar"
                                                            dataSource={[...(mppsState.data_dash?.data_WorkRealizationTotal || [])].reverse()}
                                                            withTitle={false}
                                                            showLegend={false}
                                                            toolsExport={false}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onrenderLabel={(e) => { }}
                                                            onRenderLabelChart={(e) => { }}
                                                            onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                                                            rotate={true}
                                                            yAxisArgs={[
                                                                {
                                                                    name: "Early",
                                                                    valueField: "early",
                                                                    color: '#4C84FF'
                                                                },
                                                                {
                                                                    name: "Ontime",
                                                                    valueField: "ontime",
                                                                    color: '#44E08D'
                                                                },
                                                                {
                                                                    name: "Delayed",
                                                                    valueField: "delayed",
                                                                    color: '#FF9941'
                                                                },
                                                                {
                                                                    name: "Finished",
                                                                    valueField: "finished",
                                                                    color: '#BBAB41'
                                                                },
                                                                {
                                                                    name: "Abborted",
                                                                    valueField: "stopped",
                                                                    color: '#444444'
                                                                }
                                                            ]}

                                                        />
                                                        {
                                                            (mppsState.loading_dash) ? (
                                                                <Skeleton height={30} />
                                                            ) : (
                                                                <div className="mt-10 " style={{ minHeight: 30 }}>
                                                                    <table className="table m-0 p-0 text-white" style={{ position: 'absolute', bottom: 0 }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`${1}/0/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#4C84FF' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Early :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperPerformance.early}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`${2}/0/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#44E08D' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Ontime :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperPerformance?.ontime}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`${3}/0/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#FF9941' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Delayed :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperPerformance?.delayed}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`${4}/0/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#BBAB41' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Finished :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperPerformance?.finished}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="px-1 pt-1  pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`${5}/0/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#444444' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Stopped :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperPerformance?.stopped}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                ), [mppsState.loading_dash])
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row h-100 m-0 mb-5">
                                        <div className="col-12 p-0">
                                            <span className="ctsmFilterTitle text-uppercase">budget performance detail</span>
                                            <span className="ctsmFilterSubTitle">Jumlah proyek berdasarkan status anggarannya </span>
                                            <hr />
                                            {
                                                React.useMemo(() => (
                                                    <>
                                                        <ChartDx
                                                            height={'auto'}
                                                            barWidth={20}
                                                            loading={mppsState.loading_dash}
                                                            xLabel={false}
                                                            labelChart={true}
                                                            argumentField="category"
                                                            type="stackedBar"
                                                            dataSource={[...(mppsState.data_dash?.data_BudgetRealizationTotal || [])].map(d => ({ ...d, category: d?.short_name })).reverse()}
                                                            withTitle={false}
                                                            showLegend={false}
                                                            toolsExport={false}
                                                            LegendverticalAlignment="bottom"
                                                            LegendhorizontalAlignment="center"
                                                            LegenditemTextPosition="right"
                                                            onrenderLabel={(e) => { }}
                                                            onRenderLabelChart={(e) => { }}
                                                            onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                                                            rotate={true}
                                                            yAxisArgs={[
                                                                {
                                                                    name: "Under Budget",
                                                                    valueField: "under",
                                                                    color: '#4C84FF'
                                                                },
                                                                {
                                                                    name: "Within Budget",
                                                                    valueField: "within",
                                                                    color: '#44E08D'
                                                                },
                                                                {
                                                                    name: "Over Budget",
                                                                    valueField: "over_",
                                                                    color: '#F6406A'
                                                                },
                                                                {
                                                                    name: "Stopped",
                                                                    valueField: "stopped",
                                                                    color: '#444444'
                                                                }
                                                            ]}

                                                        />
                                                        {
                                                            (mppsState.loading_dash) ? (
                                                                <Skeleton height={30} />
                                                            ) : (

                                                                <div className="mt-10 " style={{ minHeight: 30 }}>
                                                                    <table className="table m-0 p-0 text-white" style={{ position: 'absolute', bottom: 0 }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`0/${1}/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#4C84FF' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Under Bdgt :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperBudget?.under}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`0/${2}/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#44E08D' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Within Bdgt :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperBudget?.within}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`0/${3}/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#F6406A' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Over Bdgt :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperBudget?.over}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="px-1 pt-1 pb-0" width="20%" style={{ height: '1px' }}>
                                                                                    <Link to={`/ongoing/table-detail-search/${mppsState.data_dash?.data_tahun_default}/${mppsState.data_dash?.data_bulan_default}?q=${btoa(`0/${5}/${mppsState.active}/0/0/subholding`)}`} target={"_blank"}>
                                                                                        <div className="h-100 rounded text-white" style={{ minHeight: 30, backgroundColor: '#444444' }}>
                                                                                            <span className="d-block px-2 text-left" style={{ fontSize: 9 }}>Stopped :</span>
                                                                                            <span className="d-block text-right px-2" style={{ fontSize: 12 }}>{mppsState.helperBudget?.stopped}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                ), [mppsState.loading_dash])
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-5">
                            <div className="row h-100 m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle text-uppercase">SPEND YEAR TO DATE</span>
                                    <span className="ctsmFilterSubTitle">Realisasi dan sisa anggaran (Ribu USD)</span>
                                    <hr />
                                    {
                                        React.useMemo(() => (
                                            <>
                                                <ChartDx
                                                    height={'auto'}
                                                    barWidth={20}
                                                    labelChart={true}
                                                    argumentField="category"
                                                    type="stackedBar"
                                                    rotate={true}
                                                    xLabel={false}
                                                    dataSource={mppsState.data_dash?.dsChartSpend}
                                                    loading={mppsState.loading_dash}
                                                    withTitle={false}
                                                    showLegend={false}
                                                    toolsExport={false}
                                                    LegendverticalAlignment="bottom"
                                                    LegendhorizontalAlignment="center"
                                                    LegenditemTextPosition="right"
                                                    onrenderLabel={(e) => { }}
                                                    onRenderLabelChart={(e) => {
                                                        if (e.seriesName === "Total") {
                                                            return `Total : $${parseFloat(e.total).toFixed(2)}`;
                                                        }
                                                    }}
                                                    onRenderTooltip={(e) => ({ text: `${e.argumentText} ${e.seriesName}: ${e.percentText} \r\n <b>$${e.valueText}</b>` })}
                                                    yAxisArgs={[
                                                        {
                                                            name: "Budget Realization",
                                                            valueField: "actual_budget",
                                                            color: '#ACA6EE'
                                                        },
                                                        {
                                                            name: "Budget Remain",
                                                            valueField: "remain_budget",
                                                            color: '#716ACA'
                                                        },
                                                        {
                                                            name: "Total",
                                                            valueField: "_total",
                                                            color: 'transparent',
                                                            label: { visible: true, position: "outside", font: { color: '#3F4254' } }
                                                        }
                                                    ]}
                                                />
                                                {
                                                    (mppsState.loading_dash) ? (
                                                        <Skeleton height={30} />
                                                    ) : (
                                                        <div className="mt-10" style={{ minHeight: 30 }}>
                                                            <div className="w-100 text-white p-1 rounded" style={{ backgroundColor: '#716ACA', minHeight: 30, position: 'absolute', bottom: 0 }}>
                                                                <span className="d-block text-center" style={{ fontSize: 9 }}>
                                                                    {[...(mppsState.data_dash?.data_HeaderMonitoring || [])].find(d => d?.function_id === mppsState.active)?.short_name || ''} : {parseFloat(mppsState.helperBudget?.perform_budget * 100).toFixed(1)} %
                                                                </span>
                                                                <span className="d-block text-center" style={{ fontSize: 12 }}>
                                                                    $ {parseFloat(mppsState.helperBudget?.actual_budget).toFixed(1)} / $ {parseFloat(mppsState.helperBudget?.plan_tot).toFixed(1)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        ), [mppsState.loading_dash])
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-md-12'>
                            <GridDx
                                data={mppsState.data_dash?.data_TableResearch_u}
                                columns={columns}
                                cellRender={mppsAction.custmCellRender}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Dashboard;