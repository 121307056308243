import { DropDownButton } from 'devextreme-react';
import React, { useEffect, useState, useRef } from 'react';
import {
    Card,
    // Dropdown
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { GridDx, HeaderList } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import './people.css';


const columns = [
    { fieldName: "name", caption: "Intellectual Property", width: 300 },
    { fieldName: "patent_status_name", caption: "Status" },
    { fieldName: "patent_area_name", caption: "Area" },
    { fieldName: "patent_year", caption: "Year" },
    { fieldName: "paten_owner", caption: "Inventor" },
    { fieldName: "owner_ext", caption: "Inventor (Ext)" },
    { fieldName: "creator_name", caption: "Creator" },
    { fieldName: "list_dokumen", caption: "Action", width: 150, allowSorting: false, allowFiltering: false },
]
function InventorDetail(props) {
    const [state, dispatch, action] = useGlobalState();
    const { id } = useParams();
    const history = useHistory();
    const [dataInv, setDataInv] = useState({});
    const tableRef = useRef();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        if (id) {
            getData(id);
        }
    }, []);
    function getData(owner_id) {
        action.getData(`/api/people-intel-property/detail-inventor`, {
            id: owner_id
        }, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setDataInv(resp.data);
                setData_page(resp.data_page);
            }
        })
    }
    const customCellRender = (e) => {
        if (e.column.name === "name") {
            return (
                <div>
                    <span className='d-block text-wrap font-bold mb-3'>{e.value}</span>
                    <span className='text-wrap'>{e.data?.description}</span>
                </div>
            )
        } else if (e.column.name === "list_dokumen") {
            return (
                <div className="text-center">
                    {
                        [...(e.value || [])].length > 0 && (
                            <DropDownButton
                                text="Download"
                                className="bg-primary rounded customColorIcon customColotText"
                                icon="fa fa-download icon-nm"
                                dropDownOptions={{ width: 250 }}
                                itemRender={(e) => {
                                    return (
                                        <>
                                            <a target="_blank" href={e?.file_name_with_url}>
                                                {e?.name}
                                            </a>
                                        </>
                                    )
                                }}
                                displayExpr="name"
                                keyExpr="id"
                                items={[...(e.value || [])]}
                            />
                        )
                    }
                </div>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            />
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className='p-5'>
                    <div className='text-center'>
                        <div className="symbol symbol-120 mr-3 customShadow">
                            <img alt="Pic"
                                src={dataInv?.data_owner?.photo_location}
                                onError={(e) => { e.target.onerror = null; e.target.src = "/media/svg/icons/General/User.svg" }}
                            />
                        </div>
                        <span className='d-block inv-det-name'>{dataInv?.data_owner?.owner_name}</span>
                        <div>
                            <label className='inv-det-exp text-wrap'>{dataInv?.data_owner?.jabatan}</label>
                        </div>
                        <span className='bg-light-primary text-primary rounded-pill py-1 px-3'>{dataInv?.data_owner?.jumlah} patents</span>
                    </div>
                </Card.Body>
            </Card>
            <Card
                style={{ marginBottom: '40px' }}
                className=" p-0 customShadow"
            >
                <Card.Body className='p-5'>
                    <span className='inv-det-name'>Patent Invented by {dataInv?.data_owner?.owner_name}</span>
                    <hr />
                    <GridDx
                        ref={tableRef}
                        searchForm={true}
                        data={dataInv?.dataPatent}
                        columns={columns}
                        cellRender={customCellRender}
                    />
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                    </div>
                    <div className="col-md-6 text-md-right">

                    </div>
                </div>
            </div>
        </>
    );
}

export default InventorDetail;