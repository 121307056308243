import React, { memo, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Card } from 'react-bootstrap'
import { HeaderList, RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import { HeaderProject } from '../../components';
import Swal from 'sweetalert2/dist/sweetalert2.js'

const iniStateFormData = {
    tc_approval: '0',
    duk_operasional_opt: ''
}
export default memo(function () {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const param = useParams()
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))
    const [formData, setFormData] = useState(iniStateFormData);
    const [data_page, setData_page] = useState({
        "page_title": "",
        "page_breadcrumbs": ""
    });

    const handleChangeFormData = (name, val) => {
        setFormData((prev) => ({ ...prev, [name]: val }));
    }

    useEffect(() => {
        if (param.id) {
            if (path !== "admin") {
                history.push("/error-page")
            } else {
                getProjectDetail(param.id)
            }
        }
    }, [])
    function getProjectDetail(id) {
        if (id) {
            action.getData("/api/project-ongoing-crud/view-update-project-unggulan-sub-holding/" + id, null, "detaSubholding").then((resp) => {
                if (resp.code === '00') {
                    setFormData({
                        tc_approval: (resp.data?.data_view_project_unggulan_sh?.tc_approval || '0').toString(),
                        duk_operasional_opt: resp.data?.data_view_project_unggulan_sh?.tc_approval || ''
                    });
                    setData_page(resp.data_page);
                }
            })
        }
    }
    function saveData() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Save Project priority Sub-holding?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // console.log('semua data', state)
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/update-project-unggulan-sub-holding",
                        data: {
                            "id": state.detaSubholding?.data_view_header?.id,
                            "tc_approval": formData.tc_approval,
                            "duk_operasional_opt": formData.duk_operasional_opt
                        },
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        getProjectDetail(param.id)
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const onSubmit = () => {
        const validate = validation();
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            saveData()
        }

    }
    const validation = () => {
        var result = {
            isValid: true,
            message: ''
        }
        if (formData.tc_approval === '1' && formData.duk_operasional_opt === '') {
            result = {
                isValid: false,
                message: "Silahkan pilih Sub-holding"
            }
        }
        return result;
    }
    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            />
            <HeaderProject
                workflow_stage="Project Monitoring"
                rightMenu={true}
                dataHeader={state.detaSubholding?.data_view_header}
            />
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body className="p-3">
                    <span className="d-block px-1" style={{ fontWeight: 'bold' }}>Project Unggulan</span>
                    <hr />
                    <div className="row px-1">
                        <div className="col-md-3">
                            Project priority sub-holding
                        </div>
                        <div className="col-md-9">
                            <RadioGrp
                                value={formData.tc_approval}
                                onChange={(e) => handleChangeFormData('tc_approval', e)}
                                modeRow={true}
                                data={[{ label: "Ya", value: '1' }, { label: "Tidak", value: '0' }]}
                            />
                        </div>
                    </div>
                    {
                        formData.tc_approval === '1' && (
                            <div className="row px-1">
                                <div className="col-md-3">
                                    Sub-holding
                                </div>
                                <div className="col-md-4">
                                    <select className='form-control form-control-sm'
                                        value={formData.duk_operasional_opt}
                                        onChange={(e) => handleChangeFormData('duk_operasional_opt', e.target.value)}
                                    >
                                        {
                                            [{ param_id: '', title: '- Pilih -' }, ...(state.detaSubholding?.data_dukungan_operasional || [])].map((d, i) => (
                                                <option key={i} value={d?.param_id}>{d?.title || ''}</option>
                                            ))
                                        }
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                        )
                    }
                </Card.Body>
            </Card>
            <div className={`p-2 bg-white rounded shadow-lg floating-footer${path.toLowerCase() === 'admin' ? '-nolayout' : ''}`}>
                {
                    path.toLocaleLowerCase() !== 'admin' && history.length > 1 && <button className="btn btn-sm btn-secondary m-1"
                        onClick={() => history.goBack()}
                    >
                        Kembali
                    </button>
                }
                <button className="btn btn-sm btn-primary m-1 float-md-right float-lg-right"
                    onClick={() => onSubmit()}
                >
                    <i className="fa fa-save icon-nm"></i>
                    &nbsp;Save
                </button>
            </div>
        </>
    )
})