
import React from 'react';
import { useParams } from 'react-router-dom';
import { GridDx, Modal } from '../../components';
import ModalEditorPlan from '../crudSwakelola/modalEditorPlan';
import useSwakelola from './useSwakelola';
import { Button, Column, TotalItem, Summary } from 'devextreme-react/data-grid';
import ModalEditorReal from '../crudSwakelola/modalEditorReal';

const column_plan = [
    { fieldName: 'tahun', caption: 'Tahun', allowFiltering: false },
    { fieldName: 'bulan', caption: 'Bulan', allowFiltering: false },
    { fieldName: 'tipe', caption: 'Tipe Pembayaran', allowFiltering: false },
    { fieldName: 'jumlah', caption: 'Rencana Pembayaran (Juta IDR)', allowFiltering: false },
]
const column_realisasi = [
    { fieldName: 'tanggal', caption: 'Tanggal SA', allowFiltering: false },
    { fieldName: 'plan_list', caption: 'Plan', allowFiltering: false },
    { fieldName: 'jumlah', caption: 'Jumlah (Juta IDR)', allowFiltering: false },
    { fieldName: 'dok_pendukung', caption: 'Dokumen Pendukung', allowFiltering: false },
    // { fieldName: 'dok_laporan', caption: 'Laporan Progres', allowFiltering: false },
    // { fieldName: 'dok_lain', caption: 'Dokumen Lain', allowFiltering: false },
]
const DetailTabBudget = () => {
    const { id } = useParams();
    const {
        swaAction,
        swaState
    } = useSwakelola();
    return (
        <>
            <Modal
                name="modalEditorPlan"
                baseColor={false}
            >
                <ModalEditorPlan mode={swaState.modeEditorPlan} id_swakelola={id} data={swaState.dataEditorPlan} />
            </Modal>
            <Modal
                name="modalEditorReal"
                baseColor={false}
            >
                <ModalEditorReal mode={swaState.modeEditorReal} id_swakelola={id} data={swaState.dataEditorReal} data_plan={swaState.dataDetail?.data_plan} />
            </Modal>
            <div className="row mx-1">
                <div className="col-md-12">
                    {
                        (swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Validasi" && swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Need Revision") && (
                            <>
                                <span style={{ fontWeight: 'bold', fontSize: '10pt' }}>Plan</span>
                                <hr />
                                {
                                    (swaState.path === '/admin/swakelola-detail' &&
                                        swaState.dataDetail?.dataSwakelola?.status_swakelola === "Pengadaan" || swaState.dataDetail?.dataSwakelola?.status_swakelola === "Addendum") && (
                                        <div className="d-block text-md-right mb-3">
                                            <button className="btn btn-sm btn-primary"
                                                onClick={() => swaAction.addPlan()}
                                            >
                                                <i className="fa fa-plus icon-nm"></i>
                                                Plan
                                            </button>
                                        </div>
                                    )
                                }
                                <GridDx
                                    className='custom-style'
                                    cellRender={swaAction.cellRenderPlan}
                                    data={swaState.dataDetail?.data_plan}
                                    columns={column_plan}
                                >
                                    {
                                        (swaState.path === '/admin/swakelola-detail' &&
                                            swaState.dataDetail?.dataSwakelola?.status_swakelola === "Pengadaan" || swaState.dataDetail?.dataSwakelola?.status_swakelola === "Addendum") && (
                                            <Column caption="Action" type="buttons" headerCellRender={swaAction.headerCellRender} >
                                                <Button name="customEdit" visible={true} render={swaAction.editBtnPlan} />
                                                <Button name="delete" visible={true} render={swaAction.deleteBtnPlan} />
                                            </Column>
                                        )
                                    }
                                    {/* <Summary>
                                        <TotalItem
                                            column="jumlah"
                                            showInColumn='jumlah'
                                            summaryType="sum"                                                                                        
                                            customizeText={(e) => parseFloat(e?.value, 0).toFixed(2)}
                                             />
                                    </Summary> */}
                                </GridDx>
                                <div className="p-2 custom-footer-grid text-center mb-5">
                                    Total {swaState.dataDetail?.dataSwakelola?.totalPlan} (Juta IDR)
                                </div>
                            </>
                        )
                    }
                    {
                        (swaState.dataDetail?.dataSwakelola?.status_swakelola === "Kontrak Berjalan" || swaState.dataDetail?.dataSwakelola?.status_swakelola === "Addendum" || swaState.dataDetail?.dataSwakelola?.status_swakelola === "Finished")
                    }
                    <span style={{ fontWeight: 'bold', fontSize: '10pt' }}>Realisasi Pembayaran</span>
                    <hr />
                    {
                        (swaState.path === '/admin/swakelola-detail' &&
                            swaState.dataDetail?.dataSwakelola?.status_swakelola === "Kontrak Berjalan") && (
                            <div className="d-block text-md-right mb-3">
                                <button className="btn btn-sm btn-primary"
                                    onClick={() => swaAction.addReal()}
                                >
                                    <i className="fa fa-plus icon-nm"></i>
                                    Realisasi
                                </button>
                            </div>
                        )
                    }
                    <GridDx
                        className='custom-style'
                        data={swaState.dataDetail?.data_real}
                        cellRender={swaAction.cellRenderReal}
                        columns={column_realisasi}
                    >
                        {
                            (swaState.path === '/admin/swakelola-detail' &&
                                swaState.dataDetail?.dataSwakelola?.status_swakelola === "Kontrak Berjalan") && (
                                <Column caption="Action" type="buttons" headerCellRender={swaAction.headerCellRender} >
                                    <Button name="customEdit" visible={true} render={swaAction.editBtnReal} />
                                    <Button name="delete" visible={true} render={swaAction.deleteBtnReal} />
                                </Column>
                            )
                        }
                    </GridDx>
                    <div className="p-2 custom-footer-grid text-center mb-5">
                        Total {swaState.dataDetail?.dataSwakelola?.totalReal} (Juta IDR)
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailTabBudget;