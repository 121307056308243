import React from 'react';
import { HeaderList, Tab, TabContent } from '../../components';
import useSwakelola, { SwaProvider } from './useSwakelola';
import DashboardSwakelola from './dashboard';
import ListSwakelola from './list';
import './swakelola.css';

function SwakelolaPage() {

    const { swaState, swaAction } = useSwakelola();
    return (
        <>
            <div style={{ paddingBottom: '20px' }}>
                <HeaderList
                    title={swaState.data_page?.page_title}
                    someText={swaState.data_page?.page_breadcrumbs}
                />
                <div className="row">
                    <div className="col-12">
                        <Tab
                            withCard={false}
                            itemsTab={[
                                {
                                    id: "dashboard",
                                    title: "Dashboard",
                                    onClick: () => swaAction.tabChange("dashboard")
                                },
                                {
                                    id: "table",
                                    title: "Swakelola Table",
                                    onClick: () => swaAction.tabChange("table")
                                },
                            ]}
                            manualActive={swaState.menu}
                        >
                            <TabContent id="dashboard">
                                <DashboardSwakelola />
                            </TabContent>
                            <TabContent id="table">
                                <ListSwakelola editing={true} />
                            </TabContent>
                        </Tab>
                    </div>
                </div>
            </div>
        </>
    )
}

export default () => <SwaProvider><SwakelolaPage /></SwaProvider>