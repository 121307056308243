import React, { memo } from 'react';
import { Link } from 'react-router-dom';

export default memo(({ imgUrl = "", title = "Sample Title", date = "11/11/2021", kategory = "sample kategory", linkProps, cardStyle = true, height = 'auto' }) => {

    return (
        <>
            <Link {...linkProps} className="link-card" href={imgUrl}>

                <div className={cardStyle === true ? 'card-home my-3 mx-3 customShadow ' : ''}>
                    <div className="row">
                        <div className="col-4">
                            <img className="img-berita border" style={{ objectFit: "contain", 'height': height }} src={imgUrl}
                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                        </div>
                        <div className="col-8">
                            <div className="elipsis-title" style={{ marginBottom: "0px", textAlign: "left", fontSize: "12pt" }}>
                                <h6>
                                    {title}
                                </h6>
                            </div>
                            <div style={{ textAlign: "left", marginRight: 2, fontSize: "12pt" }}>
                                <div>
                                    <span className="text-muted">
                                        {kategory}
                                    </span>
                                </div>
                                <div style={{ marginTop: 5 }}>
                                    <span className="text-muted" style={{ fontSize: "12pt", textAlign: "right", marginLeft: 2 }}>
                                        {date}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
})