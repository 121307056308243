import React from 'react';

function ReportingLogbook(props) {
    return (
        <div>
            <div style={{
                'marginBottom': '150px'
            }}>
                <table className='table table-bordered ' width={'100%'} style={{ 'fontSize': '8pt' }}>
                    <thead>
                        <tr className='bg-blue'>
                            <th width={'30px'} scope="col" style={{ 'fontSize': '8pt' }}>#</th>
                            <th width={'80px'} scope="col" style={{ 'fontSize': '8pt' }}>Status</th>
                            <th width={'85px'} scope="col" style={{ 'fontSize': '8pt' }}>Mulai</th>
                            <th width={'119px'} scope="col" style={{ 'fontSize': '8pt' }}>Sampai Dengan</th>
                            <th scope="col" style={{ 'fontSize': '8pt' }}>Pemakai</th>
                            <th scope="col" style={{ 'fontSize': '8pt' }}>Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (props?.data?.logbook || []).map((d, i) => (
                                <tr key={i}>
                                    <td width={'20px'}>{i + 1}</td>
                                    <td>{props?.data?.status}</td>
                                    <td>{d?.show_start}</td>
                                    <td>{d?.show_end}</td>
                                    <td>{d?.pemakai_name}</td>
                                    <td>{d?.deskripsi}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div>
                <table width={'100%'}>
                    <tbody>
                        <tr>
                            <td width={'30%'} style={{ 'fontSize': '8pt' }}>
                                <div>Mengetahui,</div>
                                <div style={{ 'marginBottom': '90px', 'fontSize': '7pt', 'fontStyle': 'italic' }}>PIC LAB</div>
                                <div>
                                    <label style={{ 'width': '50px' }}>Nama</label>
                                    <span>: {props?.data?.pic_lab?.name}</span>
                                </div>
                                <div>
                                    <label style={{ 'width': '50px' }}>Jabatan</label>
                                    <span>: {props?.data?.pic_lab?.jabatan}</span>
                                </div>
                            </td>
                            <td width={'40%'}></td>
                            <td width={'30%'} style={{ 'fontSize': '8pt' }}>
                                <div>Yang Menyatakan,</div>
                                <div style={{ 'marginBottom': '90px', 'fontSize': '7pt', 'fontStyle': 'italic' }}>PIC Alat</div>
                                <div>
                                    <label style={{ 'width': '50px' }}>Nama</label>
                                    <span>:</span>
                                </div>
                                <div>
                                    <label style={{ 'width': '50px' }}>Jabatan</label>
                                    <span>:</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReportingLogbook;