import React from 'react';
import { HeaderList, Tab, TabContent } from '../../components';
import TabCutoff from './tabCutoff/tabCutoff';
import TabScreensaver from './tabScreensaver/tabScreensaver';
import TabSession from './tabSession/tabSession';
import useSettingParameter, { SettingParamProvider } from './useSettingParameter';

function Index(props) {
    const { setParState } = useSettingParameter();
    return (
        <div>
            <HeaderList
                title={setParState.data_page?.page_title}
                someText={setParState.data_page?.page_breadcrumbs}
            />
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "cutoff",
                        title: "Cut off Date",
                    },
                    // {
                    //     id: "screen",
                    //     title: "Screensaver",
                    // },
                    {
                        id: "session",
                        title: "Session Timeout",
                    },
                ]}
            >
                <TabContent id="cutoff">
                    <TabCutoff />
                </TabContent>
                <TabContent id="screen">
                    <TabScreensaver />
                </TabContent>
                <TabContent id="session">
                    <TabSession />
                </TabContent>
            </Tab>
        </div>
    );
}

export default () => <SettingParamProvider><Index /></SettingParamProvider>;