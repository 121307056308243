import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GridDx } from '../../components';
import moment from 'moment';
import {
    status_progress_shortName,
    status_progress_shortName_2,
    perform_work,
    workflow_state_name_light_style,
    status_budget_light,
    subholding,
    potensial_partner_opt_name,
    need_management_support,
    value_creation
} from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import useMonitoring from '../monitoring/useMonitoring';

const budgetFilter = [
    { text: 'Under Budget', value: 1 },
    { text: 'Within Budget', value: 2 },
    { text: 'Over Budget', value: 3 },
    { text: 'Aborted', value: 4 },
    { text: '-', value: null },
]
const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]
const needMngFilter = [
    { text: 'Tidak', value: "0" },
    { text: 'Ya', value: "1" },
]
const columnSubHol = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "function_sn", caption: "Fungsi", width: 100 },
    { fieldName: "chief_sn", caption: "Chief", width: 100 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
    { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
    { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter, onExport: (e) => e?.status_progress_name },
    { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter, onExport: (e) => e?.status_budget_name },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "area_name", caption: "Area", width: 100 },
    { fieldName: "cat_name", caption: "Research Kategori", width: 115 },
    { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
    { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
    { fieldName: "leader", caption: "Leader", width: 100 },
    { fieldName: "method_name", caption: "Metode", width: 100 },
    { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potensial User", width: 200 },
    { fieldName: "need_management_support", caption: "Need Management Support", width: 120, headerFilterSource: needMngFilter, onExport: (e) => needMngFilter.find(d => d.value === e.need_management_support)?.text },
    { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "year_start", caption: "Start", width: 100 },
    { fieldName: "year_end", caption: "End", width: 100 },
]
function TabSubholdings(props) {
    const [state, dispatch, action] = useGlobalState();
    // const [tahun, setTahun] = useState('');
    // const [bulan, setBulan] = useState('');
    const tableRef = useRef();
    const {
        monState: {
            tahun,
            bulan,
        }
    } = useMonitoring();
    const getData = useCallback(async function (tahun = '', bulan) {
        action.getData('/api/project-ongoing/table-project-unggulan-sh-3', {
            tahun: tahun || '',
            bulanGet: bulan || ''
        }, "dsGrid", "loadingTable", true).then((resp) => {
            if (resp.code === "00") {
                const option_year = (resp?.data?.data_allyear || [])?.map(e => ({ name: e?.idYear, value: e?.idYear }))
                dispatch({
                    type: 'getSuccess',
                    payload: {
                        option_year,
                        data_bulan_default: resp.data?.data_bulanNow,
                        data_tahun_default: resp.data?.data_tahunGet,
                        data_page: resp.data_page,
                        loadingAll: false
                    }
                })
            }
        })
    })
    useEffect(() => {
        getData(tahun, bulan);
    }, [tahun, bulan]);
    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/Ongoingresearch/view/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span></Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName(e, state.dsGrid?.data_bulanNow))
        } else if (e.column.name === "status_progress") {
            return (status_progress_shortName_2(e))
        } else if (e.column.name === "status_budget") {
            return (status_budget_light(e))
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "potensial_partner_opt_name") {
            return (potensial_partner_opt_name(e))
        } else if (e.column.name === "need_management_support") {
            return (need_management_support(e))
        } else if (e.column.name === "subholding") {
            return (subholding(e))
        } else if (e.column.name === "value_creation") {
            return (value_creation(e))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }
    function searchChange(value) {
        if (tableRef) {
            tableRef.current.instance.searchByText(value)
        }
    }
    return (
        <div>
            <Card
                className="m-0 p-0 h-100 mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        ref={tableRef}
                        selectionMode="multiple"
                        exportMode="selection"
                        exportExcel={true}
                        settingColumn={true}
                        filenameExport={`Monitoring Table Subholdings-${state.data_bulan_default}-${state.data_tahun_default}`}
                        searchForm={false}
                        loading={state.loadingTable}
                        data={state?.dsGrid?.data_table}
                        columns={columnSubHol}
                        cellRender={(e) => cstmCellRender(e)}
                        withAction={false}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                    >

                    </GridDx>
                </Card.Body>
            </Card>

        </div>
    );
}

export default TabSubholdings;