import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Tab, TabContent, HeaderList, HeaderProject, SkeletonLoading } from '../../components';
import TabApproval from './tabApproval';
import TabVersion from './tabVersion';
import { useHistory } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';
import Skeleton from 'react-loading-skeleton';

export default function (props) {
    const [selectedTab, setSelectedTab] = useState("approval")
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();

    useEffect(() => {
        getDataUser();
    }, []);
    async function getDataUser() {
        action.getData('/api/me').then((resp) => {
            if (resp.code === "00") {
                dispatch({
                    type: "getSuccess",
                    payload: { dataUser: resp.data.data_user_me?.[0], loadingGet: false }
                })
            }
        })
    }
    return (
        <div className='container mb-5'>
            <HeaderList
                title={state.formData?.prohis?.page_title}
                someText={state.formData?.prohis?.page_breadcrumbs}
            ></HeaderList>
            <HeaderProject
                workflow_stage={state.data_view_header?.workflow_stage}
                rightMenu={false}
                dataHeader={state.data_view_header}
                dataReport={state.data_reports}
            />
            <Card
                className="shadow-sm"
            >
                <Card.Body>
                    <h5>Project Histories</h5>
                    <hr />
                    {
                        state.dataUser ? (
                            <Tab
                                withCard={false}
                                itemsTab={[
                                    {
                                        id: "approval",
                                        title: "History Approval",
                                        onClick: (e) => setSelectedTab("approval")
                                    },
                                    {
                                        id: "version",
                                        title: "History Version",
                                        onClick: (e) => setSelectedTab("version")
                                    }
                                ]}
                                manualActive={selectedTab}
                            >
                                <TabContent id="approval">
                                    <TabApproval />
                                </TabContent>
                                <TabContent id="version">
                                    <TabVersion />
                                </TabContent>
                            </Tab>

                        ) :(
                            <Skeleton height={300}/>
                        )
                    }
                </Card.Body>
            </Card>
            {
                history.length > 1 && (
                    <div className="p-2 bg-white rounded shadow-lg floating-footer-nolayout" >
                        <div className="row">
                            <div className="col-md-6 text-md-left">
                                <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
