import React, { memo, useEffect, useRef, useState } from 'react'
import { GridDx, Modal, SkeletonLoading } from '../../components/index'
import { useGlobalState } from '../../contexts/useGlobalState'
import ModalAddLogBook from './modalAddLogBook';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment';
import { toInteger } from 'lodash';


const filterType = [
    { text: 'Ya', value: 1 },
    { text: 'Tidak', value: 0 },
]
const column = [
    { fieldName: "date", caption: "Tanggal", width: 100 },
    { fieldName: "user_name", caption: "Create By", width: 100 },
    { fieldName: "type", caption: "Type", width: 100, headerFilterSource: filterType },
    { fieldName: "description", caption: "Deskripsi", minWidth: 200 },
    { fieldName: "way_forward", caption: "Way Forward", width: 200 },
    { fieldName: "uploaded_file", caption: "", width: 150, allowSorting: false, allowFiltering: false },
]
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <div className="btn btn-sm btn-light-primary"><i className="fa fa-file"></i></div>
        {/* Render custom icon here */}
        {/* &#x25bc; */}
        {children}
    </a>
));

const TabLoogBook = memo(({ param, view_admin = false }) => {
    const tableRef = useRef()
    const [data, setData] = useState();
    const [state, dispatch, action] = useGlobalState();
    const [file, setFile] = useState();

    useEffect(() => {
        if (param?.id) {
            if (view_admin === true) {
                action.getData("/api/project-ongoing-crud/view-logbook/" + param?.id, {}, null, 'loadingLogbook', true).then((resp) => {
                    if (resp.code === "00") {
                        setData(resp.data.data_view_logbook)
                        action.setForm('disable', allowEditing(resp.data))
                    }
                })
            } else {
                action.getData("/api/project-ongoing-crud/view-logbook-2/" + param?.id, {}, null, 'loadingLogbook', true).then((resp) => {
                    if (resp.code === "00") {
                        setData(resp.data.data_view_logbook)
                        action.setForm('disable', allowEditing(resp.data))
                    }
                })
            }
        }
    }, []);

    function allowEditing(data) {
        const max_year_month = '01/' + moment().format('YYYY');
        const curent_day = toInteger(moment().format('D'));
        if (state.filterData.mode === "edit" && data?.data_view_header?.workflow_state_id === 45) {
            // if (curent_day > toInteger(data?.data_cut_off_date) && data?.data_year_month === max_year_month) {
            //     return true
            // } else {
            //     return false
            // }
            if (state.data_view_header?.is_continue === 2) {
                return false;
            } else {
                if (moment().format('M').toString() === '1' && toInteger(moment().format('D')) < toInteger(state?.data_cut_off_date)) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true
        }
    }

    function editOnclick(data) {
        // action.setForm('idLog', data.id)
        // action.setForm('date', data.date)
        // action.setForm('type', data.type)
        // action.setForm('description', data.description)
        // action.setForm('way_forward', data.way_forward)
        // action.setForm('file', [...(data.uploaded_file.split('?') || [])].map(d => {
        //     return {
        //         "file_name": d,
        //         "is_new": false,
        //     }
        // }))
        action.setForm('dataEdit', {
            idLog: data.id,
            date: data.date,
            type: data.type,
            description: data.description,
            way_forward: data.way_forward,
            file: [...(data.uploaded_file.split('?') || [])].map(d => {
                return {
                    "file_name": d,
                    "is_new": false,
                }
            })
        })
        action.toggleModal('modalAddLogbook')
    }
    function addlogbook() {
        // action.setForm('idLog', '0')
        // action.setForm('date', '')
        // action.setForm('type', '')
        // action.setForm('description', '')
        // action.setForm('way_forward', '')
        // action.setForm('file', '')
        action.setForm('dataEdit', {
            idLog: '0',
            date: new Date(),
            type: '',
            description: '',
            way_forward: '',
            file: ''
        })
        action.toggleModal('modalAddLogbook')
    }
    function customCellrender(e) {
        if (e.column.name === "date") {
            return (<span className="d-block text-wrap">{e.data.date_format}</span>)
        } else if (e.column.name === "type") {
            let str = ""
            if (e.value === "1") {
                str = "Achievement"
            }
            if (e.value === "2") {
                str = "Concern"
            }
            if (e.value === "3") {
                str = "other"
            }
            return (<span className="d-block text-wrap">{str}</span>)
        } else if (e.column.name === "uploaded_file") {
            return (
                <div>
                    {
                        e.data?.status === "1" && (
                            <>
                                {
                                    state.formData.disable === false && (
                                        <>
                                            <button title='Edit' className="btn btn-sm btn-success mx-1 p-1" onClick={() => editOnclick(e.data)}>
                                                <i className="fa fa-pencil-alt icon-sm m-0 p-1"></i>
                                            </button>
                                            <button title='Submit' className="btn btn-sm btn-primary mx-1 p-1" onClick={() => submit(e.data)}>
                                                <i className="fab fa-telegram icon-sm m-0 p-1"></i>
                                            </button>
                                            <button title='Delete' className="btn btn-sm btn-danger mx-1 p-1" onClick={() => deleteLogbook(e.data)}>
                                                <i className="fa fa-trash-alt icon-sm m-0 p-1"></i>
                                            </button>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        e.value ? (
                            <button title="Show file" className="btn btn-sm btn-success mx-1 p-1"
                                onClick={() => {
                                    setFile(e.value.split(','));
                                    action.toggleModal('modalFile')
                                }}
                            ><i className="fa fa-file icon-sm m-0 p-1"></i></button>
                        ) : (
                            <span className="bg-light-secondary p-1 rounded">No File</span>
                        )
                    }
                </div>

            )
        } else if (e.column.name === "way_forward") {
            return (<div className="text-wrap dangerous-text" dangerouslySetInnerHTML={{ __html: e.value }}></div>)
        } else if (e.column.name === "description") {
            return (<div className="text-wrap dangerous-text" dangerouslySetInnerHTML={{ __html: e.value }}></div>)
        } else {
            return (
                <span className="d-block text-wrap">{e.text}</span>
            )
        }
    }
    function searchChange(value) {
        if (tableRef.current) {
            tableRef.current.instance.searchByText(value)
        }
    }
    function getData() {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-logbook/" + param?.id, {}, null, 'loadingLogbook', true).then((resp) => {
                if (resp.code === "00") {
                    setData(resp.data.data_view_logbook)
                }
            })
        }
    }
    function submit(data) {
        const d = {
            "id": data?.id,
            "date": data?.date,
            "type": data?.type,
            "description": data?.description,
            "status": '2',
            "uploaded_file": data?.uploaded_file,
            "uploaded_location": data?.uploaded_location,
            "way_forward": data?.way_forward,
            "research_id": data?.research_id,
            "user_id": data?.user_id,
            "document": [...(data?.uploaded_file === "" ? [] : data?.uploaded_file.split('?'))].map(d => {
                return {
                    "file_name": d,
                    "is_new": false
                }
            }),
            "document_delete": []
        }
        // console.log(d);
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Submit Logbook?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/update-logbook",
                        data: d,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        getData()
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteLogbook(data) {
        const d = {
            "id": data?.id,
            "date": data?.date,
            "type": data?.type,
            "description": data?.description,
            "status": data?.status,
            "uploaded_file": data?.uploaded_file,
            "uploaded_location": data?.uploaded_location,
            "way_forward": data?.way_forward,
            "research_id": data?.research_id,
            "user_id": data?.user_id,
            "document": [...(data?.uploaded_file === "" ? [] : data?.uploaded_file.split('?'))].map(d => {
                return {
                    "file_name": d,
                    "is_new": false
                }
            }),
            "document_delete": []
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Logbook ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/delete-logbook",
                        data: d,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        getData()
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function finish() {
        action.toggleModal("modalAddLogbook")
        getData()
    }
    const ModalFile = ({ data }) => {
        return (
            <>
                <h3>List File</h3>
                <hr />
                {
                    [...(data || [])].map((d, i) => (
                        d.replace("null", "") && (
                            <a className="d-block p-2" target="_blank" download href={d.replace("null", "")}>
                                <i className="fa fa-circle icon-sm"></i>
                                &nbsp;
                                {
                                    d.replace("null", "")
                                }
                            </a>
                        )
                    ))
                }
            </>
        )
    }
    return (
        <>
            <Modal
                name="modalAddLogbook"
                baseColor={true}
                size="70%"
            >
                <ModalAddLogBook data={state.formData.dataEdit} onFinish={() => finish()} />
            </Modal>
            <Modal
                name="modalFile"
                baseColor={true}
                size="70%"
            >
                <ModalFile data={file} />
            </Modal>
            <div className="row">
                <div className="col-md-12 px-5">
                    <h5>History Log Book</h5>
                    <hr />
                    <div className="row">
                        <div className="col-md-3">
                            <div className="formSearch">
                                <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => searchChange(e.target.value)} />
                                <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                        <div className="col-md-9 text-md-right">
                            {
                                state.formData.disable === false && (
                                    <button className="btn btn-sm btn-primary m-1" onClick={() => addlogbook()}>Add New</button>
                                )
                            }
                            {/* <button className="btn btn-sm btn-outline-success m-1">
                                <i className="fa fa-file icon-nm"></i>
                                Export Logbook</button> */}
                        </div>
                    </div>
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12">
                            {
                                state.loadingLogbook ? (
                                    <SkeletonLoading type="table" rows={5} cols={column.length} />
                                ) : (
                                    <GridDx
                                        searchForm={false}
                                        ref={tableRef}
                                        data={data}
                                        columns={column}
                                        cellRender={(e) => customCellrender(e)}
                                        withAction={false}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                    >

                                    </GridDx>

                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
export default TabLoogBook