import React, { useEffect, useState } from 'react';
import { RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';

function ModalPendidikan({ data = [], onSave = (e) => { }, onClose = () => { } }) {
    const [state, dispatch, action] = useGlobalState();
    const [idEdit, setIdEdit] = useState();
    const [college_name, setCollege_name] = useState();
    const [major, setMajor] = useState();
    const [minor, setMinor] = useState();
    const [year_start, setYear_start] = useState();
    const [year_end, setYear_end] = useState();
    const [degree, setDegree] = useState();
    const [graduate_status, setGraduate_status] = useState();
    const [statusEdit, setStatusEdit] = useState(1);
    const [user_profile_id, setUser_profile_id] = useState();

    useEffect(() => {
        if (state.modal.editPendidikan === true) {
            cancelEdit();
        }
    }, [state.modal.editPendidikan])

    function setEdit(d) {
        action.getData(`/api/people-hrd/view-update-education?id=${d.id}`, {}, 'data_edit_pendidikan').then((resp) => {
            if (resp.code === '00') {
                setStatusEdit(0)
                setIdEdit(resp.data.data_user_pendidikan?.id);
                setCollege_name(resp.data.data_user_pendidikan?.college_name);
                setMajor(resp.data.data_user_pendidikan?.major);
                setDegree(resp.data.data_user_pendidikan?.degree);
                setYear_end(resp.data.data_user_pendidikan?.year_end);
                setGraduate_status((resp.data.data_user_pendidikan?.graduate_status || '').toString() === '1' ? true : false);
                setUser_profile_id(resp.data.data_user_pendidikan?.user_profile_id);
            }
        })
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Hapus Pendidikan ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/people-hrd/delete-education?id=${id}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        cancelEdit();
                        onSave();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function cancelEdit() {
        setStatusEdit(1);
        setIdEdit('');
        setCollege_name('');
        setMajor('');
        setDegree('');
        setYear_end('');
        setGraduate_status(false);
        setUser_profile_id('');
    }
    function addpendidikan() {
        const dataKirim = {
            "id": '',
            "college_name": college_name,
            "major": major,
            "minor": '',
            "year_start": '',
            "year_end": year_end,
            "degree": degree,
            "graduate_status": graduate_status === true ? '1' : '0',
            "user_profile_id": state.data_user_profile?.id
        }
        const validate = validation(dataKirim);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Tambah Pendidikan?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //console.log('semua data', state)
                    action.postData(
                        {
                            url: `/api/people-hrd/create-education`,
                            data: dataKirim,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            cancelEdit();
                            onSave();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function updatePendidikan() {
        const dataKirim = {
            "id": idEdit,
            "college_name": college_name,
            "major": major,
            "minor": '',
            "year_start": '',
            "year_end": year_end,
            "degree": degree,
            "graduate_status": graduate_status === true ? '1' : '0',
            "user_profile_id": user_profile_id
        }
        const validate = validation(dataKirim);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Pendidikan?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //console.log('semua data', state)
                    action.postData(
                        {
                            url: `/api/people-hrd/update-education`,
                            data: dataKirim,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            cancelEdit();
                            onSave();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageYear = '';
        if (data.degree === '') {
            isValid = false;
            message = [...message, 'Degree'];
        }
        if (data.college_name === '') {
            isValid = false;
            message = [...message, 'University'];
        }
        if (data.major === '') {
            isValid = false;
            message = [...message, 'Major'];
        }
        if (data.graduate_status === '0' && (data.year_end === '' || parseInt(data.year_end, 0) < 1950)) {
            isValid = false;
            message = [...message, 'Tahun Lulus'];
            messageYear = 'Minimal tahun lulus 1950'
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 ? ' dan ' : '') + messageYear
        }
    }
    return (
        <div>
            <p><b>{statusEdit === 1 ? 'Add' : 'Edit'} pendidikan</b></p>
            <hr />
            <div className="row mb-3">
                <div className="col-md-2">
                    <label>Degree</label>
                </div>
                <div className="col-md-10">
                    <select className="form-control form-control-sm" value={degree} onChange={(e) => setDegree(e.target.value)}>
                        <option value="">- Pilih -</option>
                        <option value="D3">Diploma</option>
                        <option value="S1">Bachelor</option>
                        <option value="S2">Magister</option>
                        <option value="S3">Doctor</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2">
                    <label>University</label>
                </div>
                <div className="col-md-10">
                    <input className="form-control form-control-sm" placeholder="Nama unversitas tanpa singkatan (Ex: Universitas Indonesia)" value={college_name || ''} onChange={(e) => setCollege_name(e.target.value)}></input>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2">
                    <label>Major</label>
                </div>
                <div className="col-md-10">
                    <input className="form-control form-control-sm" placeholder="Jurusan" value={major || ''} onChange={(e) => setMajor(e.target.value)}></input>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2">
                    <label>Tahun Lulus</label>
                </div>
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-6">
                            <input disabled={graduate_status} type="number" min={1950} className="form-control form-control-sm" placeholder="Tahun Lulus"
                                value={year_end || ''} onChange={(e) => setYear_end(e.target.value)}
                            ></input>
                        </div>
                        <div className="col-md-6">
                            <input id="graduate_status" type="checkbox" className="form-check-input" checked={graduate_status || false} onChange={(e) => setGraduate_status(e.target.checked)}></input>
                            <label htmlFor="graduate_status">Masih menempuh pendidikan</label>
                            {/* <RadioGrp
                                value={graduate_status}
                                onChange={(e) => setGraduate_status(e)}
                                data={[
                                    { label: 'Masih menempuh pendidikan', value: '1' },
                                ]}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-block text-right mb-5">
                {
                    statusEdit === 1 && (
                        <button className="btn btn-sm btn-success" onClick={() => addpendidikan()}><i className="fa fa-plus icon-nm mr-3"></i>Pendidikan</button>
                    )
                }
                {
                    statusEdit === 0 && (
                        <>
                            <button className="btn btn-sm btn-secondary mx-1" onClick={() => cancelEdit()}>Cancel Edit</button>
                            <button className="btn btn-sm btn-success mx-1" onClick={updatePendidikan}><i className="fa fa-save icon-nm mr-3"></i>Update</button>
                        </>
                    )
                }
            </div>
            {/* <hr /> */}
            {
                [...(data || [])].map((d, i) => (
                    <div className="row mb-3" key={i}>
                        <div className="col-6">
                            <div className="d-inline-flex">
                                <i className="fa fa-circle icon-xs mr-5 mt-1 text-dark"></i>
                                <div>
                                    <span className="d-block"><b>{d.degree}</b></span>
                                    <span className="d-block">{d.major} - {d.college_name}</span>
                                    <span className="d-block">{d.tahun_lulus}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-md-right">
                            <button className="btn btn-sm btn-outline-warning text-warning mx-1"
                                onClick={() => setEdit(d)}
                            >
                                <i className="fa fa-pencil-alt icon-nm p-0"></i>
                            </button>
                            <button className="btn btn-sm btn-outline-danger text-danger mx-1"
                                onClick={() => deleteData(d.id)}
                            >
                                <i className="fa fa-trash-alt icon-nm  p-0"></i>
                            </button>
                        </div>
                    </div>
                ))
            }
            <hr />
            <div className="text-md-right">
                {/* <button className="btn btn-sm btn-secondary mx-1" onClick={onClose}>Batal</button>
                <button className="btn btn-sm btn-primary mx-1" onClick={onSave}><i className="fa fa-save icon-nm mx-1"></i>Simpan</button> */}
            </div>
        </div>
    );
}

export default ModalPendidikan;