import React, { memo, useState } from 'react'
import { Card, Badge } from 'react-bootstrap'
import { HeaderList, Modal } from '../../components'
import { useGlobalState } from '../../contexts/useGlobalState'
import ModalEditScreensaver from './modalEditScreensaver'
import ModalScreensaver from './modalscreensaver'

const dummy = [
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' }
]
export default memo(function () {
    const [state, dispatch, action] = useGlobalState();
    const [ds, setDs] = useState(dummy)
    const [mode, setMode] = useState()
    const [editData, setEditData] = useState()

    function onSave(data) {
    }
    function onEditClick(data) {
        setMode("Edit")
        setEditData(data)
        action.toggleModal("modalEditScreensaver")
    }
    function onAddClick() {
        setMode("Add")
        action.toggleModal("modalEditScreensaver")
    }
    function renderContent(data, key) {
        return (
            <>
                <Card
                    className="m-0 p-0 customShadow"
                >
                    <Card.Body>
                        <div>
                            <img className="d-block w-100 bg-secondary" height='150px' src={data.image ? data.image : "/media/svg/icons/Code/Question-circle.svg"} />
                        </div>
                        <span className="d-block mb-5">
                            {data.judul}
                        </span>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Badge variant="light" className="bg-light-primary text-primary" style={{ lineHeight: 2 }}>{key}</Badge>
                            <div className="d-inline-block">
                                <button className="btn btn-sm btn-light-danger mx-1"><i className="fa fa-trash-alt icon-sm"></i></button>
                                <button className="btn btn-sm btn-light-primary mx-1"
                                    onClick={() => onEditClick(data)}
                                ><i className="fa fa-pencil-alt icon-sm"></i> Edit</button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>
        )
    }
    return (
        <>
            <Modal
                name="modalScreensaver"
                baseColor={false}
            >
                <ModalScreensaver data={ds} />
            </Modal>
            <Modal
                name="modalEditScreensaver"
                baseColor={false}
            >
                <ModalEditScreensaver data={editData} mode={mode} onSave={onSave} />
            </Modal>
            <HeaderList
                title="Screensaver"
                someText="Screensaver"
            />
            <Card
                className="customShadow p-3"
            >
                <Card.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <span>List Screensaver</span>
                                <div className="d-inline-block">
                                    <button className="btn btn-sm btn-outline-primary mx-1"
                                        onClick={() => action.toggleModal("modalScreensaver")}
                                    >
                                        <i className="fa fa-play icon-sm"></i>
                                        Preview
                                    </button>
                                    <button className="btn btn-sm btn-primary mx-1"
                                        onClick={() => onAddClick()}
                                    >
                                        <i className="fa fa-plus icon-sm"></i>
                                        Add Screensaver
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        {
                            ds?.map((d, i) => (
                                <div className="col-md-3 mb-5">
                                    {
                                        renderContent(d, (i + 1))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})