import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { GridDx, HeaderList } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import {
    status_progress_shortName,
    status_progress_shortName_2,
    status_progress_shortName_4,
    perform_work,
    workflow_state_name_light_style,
    status_budget_light,
    subholding,
    potensial_partner_opt_name,
    need_management_support,
    value_creation
} from '../../components/utility/utility';
import { Card } from 'react-bootstrap';

const budgetFilter = [
    { text: 'Under Budget', value: 1 },
    { text: 'Within Budget', value: 2 },
    { text: 'Over Budget', value: 3 },
    { text: 'Aborted', value: 4 },
    { text: '-', value: null },
]
const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]
// const columnDetail = [
//     { fieldName: "updated_at", caption: "Last Modified", width: 100 },
//     { fieldName: "project_code", caption: "Code Project", width: 100 },
//     { fieldName: "title", caption: "Title", width: 300 },
//     { fieldName: "project_type_str", caption: "Project Type", width: 80 },
//     { fieldName: "function_sn", caption: "Fungsi", width: 100 },
//     { fieldName: "chief_sn", caption: "Chief", width: 100 },
//     { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
//     { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
//     { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
//     { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
//     { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
//     { fieldName: "area_name", caption: "Area", width: 100 },
//     { fieldName: "research_category_id", caption: "Research Kategori", width: 115 },
//     { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
//     { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
//     { fieldName: "leader", caption: "Leader", width: 100 },
//     { fieldName: "method_name", caption: "Metode", width: 100 },
//     { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
//     { fieldName: "potential_user", caption: "Potensial User", width: 200 },
//     { fieldName: "need_management_support", caption: "Need Management Support", width: 120 },
//     { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
//     //{ fieldName: "value_creation", caption: "Value Creations", width: 300 },
//     { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
//     { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
//     { fieldName: "year_start", caption: "Start", width: 100 },
//     { fieldName: "year_end", caption: "End", width: 100 },
// ]
const columnProgress = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "function_sn", caption: "Fungsi", width: 80 },
    { fieldName: "chief_sn", caption: "Chief", width: 80 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
    { fieldName: "perform_work", caption: "Performance/Progress", width: 115 },
    { fieldName: "status_progress_1", caption: "1", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_2", caption: "2", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_3", caption: "3", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_4", caption: "4", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_5", caption: "5", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_6", caption: "6", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_7", caption: "7", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_8", caption: "8", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_9", caption: "9", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_10", caption: "10", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_11", caption: "11", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_12", caption: "12", width: 50, headerFilterSource: progresFilter },
]

const columnDetail = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "function_sn", caption: "Fungsi", width: 100 },
    { fieldName: "chief_sn", caption: "Chief", width: 100 },
    { fieldName: "duk_operasional_opt_str", caption: "Sub-Holding", width: 115 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
    { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
    { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
    { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "area_name", caption: "Area", width: 100 },
    { fieldName: "cat_name", caption: "Research Kategori", width: 115 },
    { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
    { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
    { fieldName: "leader", caption: "Leader", width: 100 },
    { fieldName: "method_name", caption: "Metode", width: 100 },
    { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potensial User", width: 200 },
    { fieldName: "need_management_support", caption: "Need Management Support", width: 120, headerFilterSource: needMngFilter },
    { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
    { fieldName: "stage", caption: "TRL", width: 100 },
    { fieldName: "achievement", caption: "Achievement", width: 500 },
    { fieldName: "concern", caption: "Concern", width: 400 },
    { fieldName: "way_forward", caption: "Way Forward", width: 400 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "year_start", caption: "Start", width: 100 },
    { fieldName: "year_end", caption: "End", width: 100 },
    { fieldName: "keyword", caption: "Keyword", width: 300 },
]
const needMngFilter = [
    { text: 'Tidak', value: "0" },
    { text: 'Ya', value: "1" },
]

function Detail(props) {
    const tableRef = useRef();
    const [data, setData] = useState();
    const [data_page, setData_page] = useState({});
    const [state, dispatch, action] = useGlobalState();
    const { search } = useLocation();
    const param = new URLSearchParams(search).get('q');
    const { tahun = '', bulanGet = '' } = useParams();
    const splitParam = atob(param).split('/');
    const [column, setColum] = useState(splitParam[5] === 'subholding' ? columnDetail : columnProgress)

    useEffect(() => {
        // console.log(splitParam);
        if (splitParam[4] === 1 || splitParam[4] === '1') {
            action.getData('/api/project-ongoing/table-detail-search-need-support', {
                tahun,
                bulanGet,
            }, null, "loadingGrid").then((resp) => {
                if (resp?.code === "00") {
                    setData(resp.data);
                    setData_page({ ...resp?.data_page });
                }
            })
        } else {
            const url = splitParam[5] === 'subholding' ? '/api/project-ongoing/table-detail-search-sh' : '/api/project-ongoing/table-detail-search'
            action.getData(url, {
                tahun,
                bulanGet,
                status_progress_search: splitParam[0],
                status_budget_search: splitParam[1],
                idFunction: splitParam[2],
                idArea: splitParam[3],
                need_support: splitParam[4]
            }, null, "loadingGrid").then((resp) => {
                if (resp?.code === "00") {
                    setData(resp.data);
                    setData_page({ ...resp?.data_page });
                }
            })
        }
    }, []);

    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: (splitParam[5] === 'subholding' ? "/ongoing-research/view-sub-holdings/" : "/OngoingResearch-public/view/") + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span></Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName_4(e, data?.data_bulanNow))
        } else if (e.column.name === "status_progress") {
            return (status_progress_shortName_2(e))
        } else if (e.column.name === "status_budget") {
            return (status_budget_light(e))
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "potensial_partner_opt_name") {
            return (potensial_partner_opt_name(e))
        } else if (e.column.name === "need_management_support") {
            return (need_management_support(e))
        } else if (e.column.name === "subholding") {
            return (subholding(e))
        } else if (e.column.name === "value_creation") {
            return (value_creation(e))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }

    return (
        <div className='p-5'>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <GridDx
                                searchForm={true}
                                ref={tableRef}
                                loading={state.loadingGrid}
                                data={data?.data_table}
                                columns={column}
                                cellRender={(e) => cstmCellRender(e)}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Detail;