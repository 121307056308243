import React from 'react';
import { Card } from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { GridDx } from '../../components';
import useInventory from './useInventory';

const columns = [
    { fieldName: "nomaterial", caption: "No. Material", width: 110 },
    { fieldName: "name", caption: "Name", link: true, url: "", minWidth: 300 },
    { fieldName: "category", caption: "Kategori", width: 100 },
    { fieldName: "stock_status", caption: "Stok Status", width: 100 },
    { fieldName: "abc", caption: "ABC/FMR", width: 100 },
    { fieldName: "dimensi", caption: "Dimensi", width: 100 },
    { fieldName: "satuan_dimensi", caption: "Satuan Dimensi", width: 100 },
    { fieldName: "berat", caption: "Project Berat", width: 100 },
    { fieldName: "satuan_berat", caption: "Satuan Berat", width: 100 },
    { fieldName: "procurement", caption: "Procurement", width: 100 }
]

const dataDummy = Array.from(Array(10), (_, i) => {
    return {
        nomaterial: `ASXTGR${i + 1}`,
        name: `Barang ${i + 1}`,
        category: "Lorem",
        stock_status: i < 3 ? "instock" : i < 6 ? "outstock" : "lowstock",
        abc: "AA",
        dimensi: (i + 1) * 2,
        satuan_dimensi: "m",
        berat: (i + 1) * 2,
        satuan_berat: "Kg",
        procurement: i < 5 ? "Stock" : "Non Stock"
    }
})
function TabTable(props) {
    const {invAction, invState} = useInventory();
    return (
        <>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-2">
                    <div className="row" style={{ fontSize: '8pt' }}>
                        <div className="col-md-2">
                            <div className="d-flex border-right">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark" style={{width:'32.5px', height:'32.5px'}}>
                                    <SVG src="/media/svg/icons/Communication/Clipboard-list.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block text-nowrap mr-2">Total Inventory</span> 
                                    <span className="d-block text-nowrap mr-2" style={{fontSize:'6pt'}}>/ Nilai Stock</span>                                      
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row justify-content-around">
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 1</span>
                                            <span className="d-block text-nowrap mr-2" style={{fontSize:'6pt'}}>/ Nilai Stock</span>  
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 2</span>
                                            <span className="d-block text-nowrap mr-2" style={{fontSize:'6pt'}}>/ Nilai Stock</span>  
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 3</span>
                                            <span className="d-block text-nowrap mr-2" style={{fontSize:'6pt'}}>/ Nilai Stock</span>  
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 4</span>
                                            <span className="d-block text-nowrap mr-2" style={{fontSize:'6pt'}}>/ Nilai Stock</span>  
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-flex border-left">
                                <div className="pl-3 text-white">
                                    <span className="d-block text-nowrap mr-2">Total Recieve</span>
                                    <span className="d-block text-nowrap mr-2" style={{fontSize:'6pt'}}>/ Nilai Investasi</span>                                    
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                                <select className="form-control form-control-sm"></select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-flex border-left">
                                <div className="pl-3 text-white">
                                    <span className="d-block text-nowrap mr-2">Total Dispatch</span>
                                    <span className="d-block text-nowrap mr-2" style={{fontSize:'6pt'}}>/ Nilai Investasi</span> 
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                                <select className="form-control form-control-sm"></select>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <GridDx
                        searchForm={true}
                        cellRender={invAction.cellRenderTable}
                        data={dataDummy}
                        columns={columns}
                        withAction={false}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default TabTable;