import moment from "moment";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useLocation } from "react-router-dom";

const monContext = createContext({
    monState: {},
    monAction: {}
});

export function MonProvider({ children }) {
    const { param } = useLocation();
    const [tahun, setTahun] = useState(param?.tahun || moment(new Date()).add(-1, 'months').format('YYYY'));
    const [bulan, setBulan] = useState(param?.bulan || moment(new Date()).add(-1, 'months').format('M'));
    return <monContext.Provider value={{
        monState: {
            tahun,
            bulan,
        },
        monAction: {
            setTahun,
            setBulan
        }
    }}>
        {children}
    </monContext.Provider>
}
export default function useMonitoring() {
    return useContext(monContext);
}