import React, { memo, useEffect, useState } from 'react'
import { useGlobalState } from '../../contexts/useGlobalState';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'

const ModalCategory = memo(() => {
    const [state, dispatch, action] = useGlobalState();
    const [dataKategori, setDataKategori] = useState([]);
    const [kategori_name, setKategori_name] = useState('');
    const [mode, setMode] = useState(0);
    const [id_edit, setId_edit] = useState(0);

    useEffect(() => {
        if (state.modal?.modalKategory === true) {
            resetData();
            getData();
        }
    }, [state.modal?.modalKategory]);

    function getData() {
        action.getData(`/api/Faq/master-faq-category`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setDataKategori(resp.data?.category);
            }
        })
    }

    function resetData() {
        setDataKategori([]);
        setKategori_name('');
        setMode(0);
        setId_edit(0);
    }
    function cancelEdit() {
        setKategori_name('');
        setMode(0);
        setId_edit(0);
    }
    function setEditor(id) {
        action.getData(`/api/Faq/view-faq-category?id=${id}`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                const { category } = resp.data;
                setId_edit(category?.id);
                setKategori_name(category?.name);
                setMode(1);
            }
        })
    }
    function addKategori() {
        const data = {
            id: id_edit,
            name: kategori_name || ''
        }
        if (data?.name === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Kategori name tidak boleh kosong',
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Kategori? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/Faq/store-faq-category`,
                    data: data
                }).then((resp) => {
                    if (resp.code === '00') {
                        cancelEdit();
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function updateKategori() {
        const data = {
            id: id_edit,
            name: kategori_name || ''
        }
        if (data?.name === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Kategori name tidak boleh kosong',
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update Kategori? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/Faq/update-faq-category`,
                    data: data
                }).then((resp) => {
                    if (resp.code === '00') {
                        cancelEdit();
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Hapus kategori ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/Faq/delete-faq-category`,
                        data: {
                            id: id,
                            name: ''
                        }
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <>
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body>
                    <span><b>{mode === 0 ? 'Add' : 'Edit'} Kategori</b></span>
                    <hr />
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="input-group mb-2">
                                <label className="mr-3" style={{ width: '99px' }}>Nama Kategori</label>
                                <input className="form-control form-control-sm mr-1"
                                    value={kategori_name}
                                    onChange={(e) => setKategori_name(e.target.value)}
                                >
                                </input>
                                {
                                    mode === 0 && (
                                        <div>
                                            <button className="btn btn-sm btn-success"
                                                onClick={() => addKategori()}
                                            >
                                                <i className="fa fa-plus icon-sm"></i>
                                                Add Kategori
                                            </button>
                                        </div>
                                    )
                                }
                                {
                                    mode === 1 && (
                                        <div>
                                            <button className="btn btn-sm btn-success mr-1"
                                                onClick={() => updateKategori()}
                                            >
                                                Update
                                            </button>
                                            <button className="btn btn-sm btn-secondary"
                                                onClick={() => cancelEdit()}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                            <hr />
                            <ul>
                                {
                                    dataKategori.map((d, i) => (
                                        <li>
                                            <div className="input-group mb-2">
                                                <span className="form-control form-control-sm mr-1 bg-secondary">{d?.name}</span>
                                                <div style={{ width: '115px' }}>
                                                    <button className="btn btn-sm btn-outline-warning mr-1"
                                                        onClick={() => setEditor(d?.id)}
                                                    >
                                                        <i className="fa fa-pencil-alt icon-sm p-0"></i>
                                                    </button>
                                                    <button className="btn btn-sm btn-outline-danger mr-1"
                                                        onClick={() => deleteData(d?.id)}
                                                    >
                                                        <i className="fa fa-trash-alt icon-sm p-0"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className="d-block mb-5 text-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => action.toggleModal("modalKategory")}
                        >
                            Batal
                        </button>
                        {/* <button className="btn btn-sm btn-primary">
                            <i className="fa fa-save icon-nm mx-1 text-white"></i>
                            Save
                        </button> */}
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})
export default ModalCategory