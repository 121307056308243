import React from 'react'
import './utility.css'
import { AbiIcon, CommercialIcon, OperationalIcon, ProductIcon, ResearchIcon, TechIcon } from '../../pages/myproject/icon';
import moment from 'moment';
import Swal from 'sweetalert2';

export function nilaiTerbilang(nil) {
	const pembilang = (nilai = 0) => {
		nilai = Math.floor(Math.abs(nilai));

		var simpanNilaiBagi = 0;
		var huruf = [
			'',
			'Satu',
			'Dua',
			'Tiga',
			'Empat',
			'Lima',
			'Enam',
			'Tujuh',
			'Delapan',
			'Sembilan',
			'Sepuluh',
			'Sebelas',
		];
		var temp = '';

		if (nilai < 12) {
			temp = ' ' + huruf[nilai];
		} else if (nilai < 20) {
			temp = pembilang(Math.floor(nilai - 10)) + ' Belas';
		} else if (nilai < 100) {
			simpanNilaiBagi = Math.floor(nilai / 10);
			temp = pembilang(simpanNilaiBagi) + ' Puluh' + pembilang(nilai % 10);
		} else if (nilai < 200) {
			temp = ' Seratus' + pembilang(nilai - 100);
		} else if (nilai < 1000) {
			simpanNilaiBagi = Math.floor(nilai / 100);
			temp = pembilang(simpanNilaiBagi) + ' Ratus' + pembilang(nilai % 100);
		} else if (nilai < 2000) {
			temp = ' Seribu' + pembilang(nilai - 1000);
		} else if (nilai < 1000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000);
			temp = pembilang(simpanNilaiBagi) + ' Ribu' + pembilang(nilai % 1000);
		} else if (nilai < 1000000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000000);
			temp = pembilang(simpanNilaiBagi) + ' Juta' + pembilang(nilai % 1000000);
		} else if (nilai < 1000000000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000000000);
			temp = pembilang(simpanNilaiBagi) + ' Miliar' + pembilang(nilai % 1000000000);
		} else if (nilai < 1000000000000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000000000000);
			temp = pembilang(nilai / 1000000000000) + ' Triliun' + pembilang(nilai % 1000000000000);
		}
		return temp;
	}
	return pembilang(nil) + " Rrupiah";
}
export const formatTanggalId = (stringDate) => {
	const formatter = new Intl.DateTimeFormat('id-ID', {
		year: 'numeric',
		month: 'long',
		day: '2-digit'
	});
	return stringDate && formatter.format(moment(stringDate, 'YYYY-MM-DD').toDate());
}
export const formatterIdr = (value = 0) => {
	const formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR'
	});
	return formatter.format(value);
}

export const formatter = new Intl.NumberFormat('en-US', {
	valuta: 'USD',
	maximumFractionDigits: 2
})
export const formatter_int = new Intl.NumberFormat('en-US', {
	valuta: 'USD',
	minimumFractionDigits: 0
})
export const belumtersediaAlert = () => {
	return Swal.fire({
		icon: 'info',
		title: '',
		text: 'Maaf menu belum tersedia',
	})
}
export const kuantitatif = [{ value: "Kuantitatif", name: "Kuantitatif" }, { value: "Kualitatif", name: "Kualitatif" }]
export const probabilitas = [
	{ value: 1, name: "1. Rare" },
	{ value: 2, name: "2. Unlikely" },
	{ value: 3, name: "3. Moderate" },
	{ value: 4, name: "4. Likely" },
	{ value: 5, name: "5. Almost Certain" },
	{ value: 6, name: "6. Definitely" }];
export const dampak = [
	{ value: 1, name: "1. Insignificant" },
	{ value: 2, name: "2. Minor" },
	{ value: 3, name: "3. Moderate" },
	{ value: 4, name: "4. Significant" },
	{ value: 5, name: "5. Catastrophic" }];
function getProgress(value) {
	var t = 0;
	if (value === "status_progress_1") { t = 1 }
	if (value === "status_progress_2") { t = 2 }
	if (value === "status_progress_3") { t = 3 }
	if (value === "status_progress_4") { t = 4 }
	if (value === "status_progress_5") { t = 5 }
	if (value === "status_progress_6") { t = 6 }
	if (value === "status_progress_7") { t = 7 }
	if (value === "status_progress_8") { t = 8 }
	if (value === "status_progress_9") { t = 9 }
	if (value === "status_progress_10") { t = 10 }
	if (value === "status_progress_11") { t = 11 }
	if (value === "status_progress_12") { t = 12 }
	return t;
}
export const risk_priority = function (inher_probabil = 0, inher_dampak = 0) {
	const pri = inher_probabil * inher_dampak;
	let cssName = ""
	if (pri <= 3) {
		cssName = "d-block text-white rounded text-center bg-success";
	}
	else if (pri <= 6 && pri >= 4) {
		cssName = "d-block text-white rounded text-center bg-yellow";
	}
	else if (pri <= 12 && pri >= 8) {
		cssName = "d-block text-white rounded text-center bg-warning";
	}
	else if (pri <= 30 && pri >= 15) {
		cssName = "d-block text-white rounded text-center bg-danger";
	}
	return (<span className={cssName}>
		{
			pri
		}
	</span>)
}
export const risk_priority_residu = function (res_probabil = 0, res_dampak = 0) {
	const pri = res_probabil * res_dampak;
	let cssName = ""
	if (pri <= 3) {
		cssName = "d-block text-white rounded text-center bg-success";
	}
	else if (pri <= 6 && pri >= 4) {
		cssName = "d-block text-white rounded text-center bg-yellow";
	}
	else if (pri <= 12 && pri >= 8) {
		cssName = "d-block text-white rounded text-center bg-warning";
	}
	else if (pri <= 30 && pri >= 15) {
		cssName = "d-block text-white rounded text-center bg-danger";
	}
	return (<span className={cssName}>
		{
			pri
		}
	</span>)
}

export const heatmap_color = function (res_probabil = 0, res_dampak = 0) {
	const pri = res_probabil * res_dampak;
	let color = "";
	if (pri <= 3) color = "#20d873";
	else if (pri <= 6 && pri >= 4) color = "#f2f22a";
	else if (pri <= 12 && pri >= 8) color = "#eb9015";
	else if (pri <= 30 && pri >= 15) color = "#c73636";
	return color //{ rpn : pri, color }
}

export const inher_probabil = function (value) {
	return (<span className="d-block text-wrap text-center">
		{
			probabilitas.find(d => d.value.toString() === (value || "").toString())?.name
		}
	</span>)
}
export const inher_dampak = function (value) {
	return (<span className="d-block text-wrap text-center">
		{
			dampak.find(d => d.value.toString() === (value || "").toString())?.name
		}
	</span>)
}
export const task = function (e) {
	return (
		<span className={`text-wrap textContent ${e.data.type === "task" ? "text-uppercase" : ""}`} style=
			{
				e.data.type === "milestones" ? { fontWeight: "bold", fontStyle: 'italic' } :
					e.data.type === "submilestones" ? { fontWeight: "bold", paddingLeft: "35px", fontStyle: 'italic' } :
						e.data.type === "task" ? { fontWeight: "bold" } :
							{ fontWeight: "regular", paddingLeft: "35px" }
			}
		>
			{e.value}
		</span>
	)
}
export const status_progress_name = function (val) {
	if (val === 1) {
		return (<span className="bg-primary text-wrap text-white px-2 py-1 rounded" title="Early">Early</span>)
	}
	else if (val === 2) {
		return (<span className="text-white text-wrap px-2 py-1 rounded" title="Ontime" style={{ backgroundColor: '#44E08D' }}>Ontime</span>)
	}
	else if (val === 3) {
		return (<span className="text-white text-wrap px-2 py-1 rounded" title="Delay" style={{ backgroundColor: '#FF9941' }}>Delay</span>)
	}
	else if (val === 4) {
		return (<span className="text-white text-wrap px-2 py-1 rounded" title="Finished" style={{ backgroundColor: '#FFD700' }}>Finished</span>)
	}
	else if (val === 5) {
		return (<span className="bg-dark text-wrap text-white px-2 py-1 rounded" title="Aborted">Aborted</span>)
	}
	else if (val === 6) {
		return (<span className="text-white text-wrap px-2 py-1 rounded" title="Continue" style={{ backgroundColor: 'purple' }}>Continue</span>)
	}
	else if (val === 7) {
		return (<span className="text-white text-wrap px-2 py-1 rounded" title="Unfinished" style={{ backgroundColor: 'brown' }}>Unfinished</span>)
	}
	else {
		return (<span>-</span>)
	}
}
export const status_progress_shortName = function (e, bulanNow) {
	var prog = getProgress(e.column.name);
	if (bulanNow >= prog && e.value == 1) {
		return (<div className="d-block text-center"><span className="bg-primary text-white px-2 py-1 rounded" title="Early">ER</span></div>)
	}
	else if (bulanNow >= prog && e.value == 2) {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Ontime" style={{ backgroundColor: '#44E08D' }}>OT</span></div>)
	}
	else if (bulanNow >= prog && e.value == 3) {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Delay" style={{ backgroundColor: '#FF9941' }}>DL</span></div>)
	}
	else if (bulanNow >= prog && e.value == 4) {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Finished" style={{ backgroundColor: '#FFD700' }}>FN</span></div>)
	}
	else if (bulanNow >= prog && e.value == 5) {
		return (<div className="d-block text-center"><span className="bg-dark text-white px-2 py-1 rounded" title="Aborted">ST</span></div>)
	}
	else if (bulanNow >= prog && e.value == 6) {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Continue" style={{ backgroundColor: 'purple' }}>CON</span></div>)
	}
	else if (bulanNow >= prog && e.value == 7) {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Unfinished" style={{ backgroundColor: 'brown' }}>UNF</span></div>)
	}
	else {
		return (<div className="d-block text-center"><span>-</span></div>)
	}
}
export const status_progress_shortName_2 = function (e) {
	if (e.value === 1) {
		return (<div className="d-block text-center"><span className="bg-primary text-wrap text-white px-2 py-1 rounded" title="Early">ER</span></div>)
	}
	else if (e.value === 2) {
		return (<div className="d-block text-center"><span className="text-white text-wrap px-2 py-1 rounded" title="Ontime" style={{ backgroundColor: '#44E08D' }}>OT</span></div>)
	}
	else if (e.value === 3) {
		return (<div className="d-block text-center"><span className="text-white text-wrap px-2 py-1 rounded" title="Delay" style={{ backgroundColor: '#FF9941' }}>DL</span></div>)
	}
	else if (e.value === 4) {
		return (<div className="d-block text-center"><span className="text-white text-wrap px-2 py-1 rounded" title="Finished" style={{ backgroundColor: '#FFD700' }}>FN</span></div>)
	}
	else if (e.value === 5) {
		return (<div className="d-block text-center"><span className="bg-dark text-wrap text-white px-2 py-1 rounded" title="Aborted">ST</span></div>)
	}
	else if (e.value === 6) {
		return (<div className="d-block text-center"><span className="text-white text-wrap px-2 py-1 rounded" title="Continue" style={{ backgroundColor: 'purple' }}>CON</span></div>)
	}
	else if (e.value === 7) {
		return (<div className="d-block text-center"><span className="text-white text-wrap px-2 py-1 rounded" title="Unfinished" style={{ backgroundColor: 'brown' }}>UNF</span></div>)
	}
	else {
		return (<div className="d-block text-center"><span>-</span></div>)
	}
}
export const status_progress_shortName_3 = function (value) {
	if (value === 1) {
		return (<div><span className="bg-primary text-wrap text-white px-2 py-1 rounded" title="Early">ER</span></div>)
	}
	else if (value === 2) {
		return (<div><span className="text-white text-wrap px-2 py-1 rounded" title="Ontime" style={{ backgroundColor: '#44E08D' }}>OT</span></div>)
	}
	else if (value === 3) {
		return (<div><span className="text-white text-wrap px-2 py-1 rounded" title="Delay" style={{ backgroundColor: '#FF9941' }}>DL</span></div>)
	}
	else if (value === 4) {
		return (<div><span className="text-white text-wrap px-2 py-1 rounded" title="Finished" style={{ backgroundColor: '#FFD700' }}>FN</span></div>)
	}
	else if (value === 5) {
		return (<div><span className="bg-dark text-wrap text-white px-2 py-1 rounded" title="Aborted">ST</span></div>)
	}
	else if (value === 6) {
		return (<div><span className="text-white text-wrap px-2 py-1 rounded" title="Continue" style={{ backgroundColor: 'purple' }}>CON</span></div>)
	}
	else if (value === 7) {
		return (<div><span className="text-white text-wrap px-2 py-1 rounded" title="Unfinished" style={{ backgroundColor: 'brown' }}>UNF</span></div>)
	}
	else {
		return (<div><span>-</span></div>)
	}
}
export const status_progress_shortName_4 = function (e, bulanNow) {
	var prog = getProgress(e.column.name);
	if (bulanNow >= prog && e.data["status_progress_name_" + prog] === "ER") {
		return (<div className="d-block text-center"><span className="bg-primary text-white px-2 py-1 rounded" title="Early">{e.data["status_progress_name_" + prog]}</span></div>)
	}
	else if (bulanNow >= prog && e.data["status_progress_name_" + prog] === "OT") {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Ontime" style={{ backgroundColor: '#44E08D' }}>{e.data["status_progress_name_" + prog]}</span></div>)
	}
	else if (bulanNow >= prog && e.data["status_progress_name_" + prog] === "DL") {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Delay" style={{ backgroundColor: '#FF9941' }}>{e.data["status_progress_name_" + prog]}</span></div>)
	}
	else if (bulanNow >= prog && e.data["status_progress_name_" + prog] === "FN") {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Finished" style={{ backgroundColor: '#FFD700' }}>{e.data["status_progress_name_" + prog]}</span></div>)
	}
	else if (bulanNow >= prog && e.data["status_progress_name_" + prog] === "ST") {
		return (<div className="d-block text-center"><span className="bg-dark text-white px-2 py-1 rounded" title="Aborted">{e.data["status_progress_name_" + prog]}</span></div>)
	}
	else if (bulanNow >= prog && e.data["status_progress_name_" + prog] === "CON") {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Continue" style={{ backgroundColor: 'purple' }}>{e.data["status_progress_name_" + prog]}</span></div>)
	}
	else if (bulanNow >= prog && e.data["status_progress_name_" + prog] === "UNF") {
		return (<div className="d-block text-center"><span className="text-white px-2 py-1 rounded" title="Unfinished" style={{ backgroundColor: 'brown' }}>{e.data["status_progress_name_" + prog]}</span></div>)
	}
	else {
		return (<div className="d-block text-center"><span>-</span></div>)
	}
}
export const workflow_state_name_solid_style = function (e) {
	// let cssName = "";
	// let val = e.value;
	// if (e.data.workflow_state_name === "ideation_draft") {
	//     cssName = "d-block px-2 py-1 rounded bg-metal text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "ideation_approve_2") {
	//     cssName = "d-block px-2 py-1 rounded bg-metal text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "ideation_approve_1") {
	//     cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "portfolio_waiting_approval") {
	//     cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "portfolio_new_approve_2") {
	//     cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_waiting_approval") {
	//     cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_continue_request") {
	//     cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_finished_request") {
	//     cssName = "d-block px-2 py-1 rounded bg-accent text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_aborted_request") {
	//     cssName = "d-block px-2 py-1 rounded bg-accent text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "ideation_approve_1_revise") {
	//     cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "portfolio_waiting_approval_revise") {
	//     cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_revise_portfolio") {
	//     cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_revise_upload") {
	//     cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring" && e.data.is_continue === 1) {
	//     cssName = "d-block px-2 py-1 rounded bg-success text-center text-wrap text-white"
	//     val = e.value + "(Continue)"
	// } else if (e.data.workflow_state_name === "monitoring" && e.data.is_continue === 2) {
	//     cssName = "d-block px-2 py-1 rounded bg-success text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name == "ideation_approve_1_reject") {
	//     cssName = "d-block px-2 py-1 rounded bg-danger text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "portfolio_waiting_approval_reject") {
	//     cssName = "d-block px-2 py-1 rounded bg-danger text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_finished") {
	//     cssName = "d-block px-2 py-1 rounded bg-gold text-center text-wrap text-white"
	// } else if (e.data.workflow_state_name === "monitoring_aborted") {
	//     cssName = "d-block px-2 py-1 rounded bg-dark text-center text-wrap text-white"
	// } else {
	//     cssName = "d-block px-2 py-1 rounded bg-light text-center text-wrap text-dark"
	// }
	return workflow_state_name_solid(e.data.workflow_state_name, e.value, e.data.is_continue)
}
export const workflow_state_name_light_style = function (e) {
	return workflow_state_name_light(e.data.workflow_state_name, e.value, e.data.is_continue)
}
export const workflow_state_name_abi_light_style = function (e) {
	return workflow_state_name_light(e.data?.workflow_name_abi, e?.value, e.data?.is_continue)
}
export const status_budget_solid = function (e) {
	if (e.value == 1) {
		return (<span className="d-block text-center bg-primary text-wrap text-white px-2 py-1 rounded" title="Under Budget">Under Budget</span>)
	}
	else if (e.value == 2) {
		return (<span className="d-block text-center text-white text-wrap px-2 py-1 rounded" title="Within Budget" style={{ backgroundColor: '#44E08D' }}>Within Budget</span>)
	}
	else if (e.value == 3) {
		return (<span className="d-block text-center bg-danger text-wrap text-white px-2 py-1 rounded" title="Over Budget">Over Budget</span>)
	}
	else if (e.value == 5) {
		return (<span className="d-block text-center bg-dark text-wrap text-white px-2 py-1 rounded" title="Aborted">Aborted</span>)
	}
	else {
		return (<span className="d-block text-center">-</span>)
	}
}
export const status_budget_light = function (e) {
	if (e.value == 1) {
		return (<span className="d-block text-center bg-light-primary text-wrap text-primary px-2 py-1 rounded" title="Under Budget">Under Budget</span>)
	}
	else if (e.value == 2) {
		return (<span className="d-block text-center bg-light-success text-success text-wrap px-2 py-1 rounded" title="Within Budget" >Within Budget</span>)
	}
	else if (e.value == 3) {
		return (<span className="d-block text-center bg-light-danger text-wrap text-danger px-2 py-1 rounded" title="Over Budget">Over Budget</span>)
	}
	else if (e.value == 5) {
		return (<span className="d-block text-center bg-light-dark text-wrap text-dark px-2 py-1 rounded" title="Aborted">Aborted</span>)
	}
	else {
		return (<span className="d-block text-center">-</span>)
	}
}
export const status_budget_solid_2 = function (value) {
	if (value == 1) {
		return (<span className="d-block text-center bg-primary text-wrap text-white px-2 py-1 rounded" title="Under Budget">Under Budget</span>)
	}
	else if (value == 2) {
		return (<span className="d-block text-center text-white text-wrap px-2 py-1 rounded" title="Within Budget" style={{ backgroundColor: '#44E08D' }}>Within Budget</span>)
	}
	else if (value == 3) {
		return (<span className="d-block text-center bg-danger text-wrap text-white px-2 py-1 rounded" title="Over Budget">Over Budget</span>)
	}
	else if (value == 5) {
		return (<span className="d-block text-center bg-dark text-wrap text-white px-2 py-1 rounded" title="Aborted">Aborted</span>)
	}
	else {
		return (<span className="d-block text-center">-</span>)
	}
}
export const status_budget_light_2 = function (value) {
	if (value == 1) {
		return (<span className="bg-light-primary text-wrap text-primary px-2 py-1 rounded" title="Under Budget">Under Budget</span>)
	}
	else if (value == 2) {
		return (<span className="bg-light-success text-success text-wrap px-2 py-1 rounded" title="Within Budget" >Within Budget</span>)
	}
	else if (value == 3) {
		return (<span className=" bg-light-danger text-wrap text-danger px-2 py-1 rounded" title="Over Budget">Over Budget</span>)
	}
	else if (value == 5) {
		return (<span className="bg-light-dark text-wrap text-dark px-2 py-1 rounded" title="Aborted">Aborted</span>)
	}
	else {
		return (<span className="">-</span>)
	}
}
export const status_budget_light_string = function (value) {
	if (value == "Under Budget") {
		return (<span className="bg-light-primary text-wrap text-primary px-2 py-1 rounded" title="Under Budget">{value}</span>)
	}
	else if (value == "Within Budget") {
		return (<span className="bg-light-success text-success text-wrap px-2 py-1 rounded" title="Within Budget" >{value}</span>)
	}
	else if (value == "Over Budget") {
		return (<span className=" bg-light-danger text-wrap text-danger px-2 py-1 rounded" title="Over Budget">{value}</span>)
	}
	else if (value == "Aborted") {
		return (<span className="bg-light-dark text-wrap text-dark px-2 py-1 rounded" title="Aborted">{value}</span>)
	}
	else {
		return (<span className="">-</span>)
	}
}
export const stage = function (e) {
	var str = "";
	if (e.value === "I") {
		str = "Stage I (Idea generation)";
	}
	else if (e.value === "II") {
		str = "Stage II (Concept Proof)";
	}
	else if (e.value === "III") {
		str = "Stage III (Prototype Development)";
	}
	else if (e.value === "IV") {
		str = "Stage IV (Pilot Run & Testing)";
	}
	else if (e.value === "V") {
		str = "Stage V / TRL : 8 (System Complete and Qualified)";
	}
	else if (e.value === "VI") {
		str = "Stage VI / TRL : 9 (Proven in Operational Environment)";
	}
	else {
		str = "";
	}
	return (
		<span className="text-wrap">{str}</span>
	)
}
export const perform_work = function (e) {
	return (
		<span>{(e.value || e.data.perform_all) ? ((e.value) ? e.value + "% " : "-") + "/" + ((e.data.perform_all) ? e.data.perform_all + "%" : "-") : "-"}</span>
	)
}
export const potensial_partner_opt_name = function (e) {
	return (
		<span className="text-wrap">
			{e.data.potensial_partner_opt_name && e.data.potensial_partner_opt_name}
			{" - " + (e.data.potensial_partner_ket ? e.data.potensial_partner_ket : "")}
		</span>
	)
}
export const subholding = function (e) {
	return (
		<span className="text-wrap">
			{e.data.subholding1 != 0 && e.data.subholding1_name}
			{e.data.subholding2 != 0 && "; " + e.data.subholding2_name}
			{e.data.subholding3 != 0 && "; " + e.data.subholding3_name}
		</span>
	)
}
export const subholding_str = function (data) {
	return `${data.subholding1 != 0 && data.subholding1_name}
	${data.subholding2 != 0 && "; " + data.subholding2_name}
	${data.subholding3 != 0 && "; " + data.subholding3_name}`
}
export const need_management_support = function (e) {
	return (<span className="text-wrap">{e.value === 1 ? "Ya" : "Tidak"}</span>)
}
export const value_creation = function (e) {
	return (
		<span className="text-wrap">
			{e.data.val_pendapatan !== 0 && "Pendapatan : " + (e.data.val_pendapatan || "") + " " + (e.data.sat_pendapatan || "") + "; "}
			{e.data.val_efisiensi !== 0 && "Efisiensi : " + (e.data.val_efisiensi || "") + " " + (e.data.sat_efisiensi || "") + "; "}
			{e.data.val_produksi !== 0 && "Produksi : " + (e.data.val_produksi || "") + " " + (e.data.sat_produksi || "") + "; "}
			{e.data.val_intangible !== 0 && "Intangible : " + (e.data.val_intangible || "")}
		</span>
	)
}
export const workflow_state_id = function (id) {
	var idx = parseFloat(id)
	if (idx === 37) {
		return 1
	} else if (idx === 40 || idx === 44 || idx === 76) {
		return 2
	} else if (idx === 46 || idx === 60 || idx === 53) {
		return 3
	}
}
export const workflow_state_name_solid = function (workflow_state_name, workflow_state_state, is_continue) {
	let cssName = "";
	let val = workflow_state_state;
	if (workflow_state_name === "ideation_draft") {
		cssName = "d-block px-2 py-1 rounded bg-metal text-center text-wrap text-white"
	} else if (workflow_state_name === "ideation_approve_2") {
		cssName = "d-block px-2 py-1 rounded bg-metal text-center text-wrap text-white"
	} else if (workflow_state_name === "ideation_approve_1") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name === "portfolio_waiting_approval") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name === "portfolio_new_approve_2") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_waiting_approval") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_continue_request") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_finished_request") {
		cssName = "d-block px-2 py-1 rounded bg-accent text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_aborted_request") {
		cssName = "d-block px-2 py-1 rounded bg-accent text-center text-wrap text-white"
	} else if (workflow_state_name === "ideation_approve_1_revise") {
		cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	} else if (workflow_state_name === "portfolio_waiting_approval_revise") {
		cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_revise_portfolio") {
		cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_revise_upload") {
		cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring") {
		cssName = "d-block px-2 py-1 rounded bg-success text-center text-wrap text-white"
	}
	else if (workflow_state_name === "monitoring" && is_continue === 1) {
		cssName = "d-block px-2 py-1 rounded bg-success text-center text-wrap text-white"
		val = val + "(Continue)"
	} else if (workflow_state_name === "monitoring" && is_continue === 2) {
		cssName = "d-block px-2 py-1 rounded bg-success text-center text-wrap text-white"
	} else if (workflow_state_name == "ideation_approve_1_reject") {
		cssName = "d-block px-2 py-1 rounded bg-danger text-center text-wrap text-white"
	} else if (workflow_state_name === "portfolio_waiting_approval_reject") {
		cssName = "d-block px-2 py-1 rounded bg-danger text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_finished") {
		cssName = "d-block px-2 py-1 rounded bg-gold text-center text-wrap text-white"
	} else if (workflow_state_name === "monitoring_aborted") {
		cssName = "d-block px-2 py-1 rounded bg-dark text-center text-wrap text-white"
	} else if (workflow_state_name == "draft") {
		cssName = "d-block px-2 py-1 rounded bg-metal text-center text-wrap text-white"
	} else if (workflow_state_name == "waiting_approval") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name == "approved") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name == "abi_monitoring_need_rev_review") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name == "abi_monitoring_continue_req") {
		cssName = "d-block px-2 py-1 rounded bg-info text-center text-wrap text-white"
	} else if (workflow_state_name == "abi_monitoring_finished_req") {
		cssName = "d-block px-2 py-1 rounded bg-accent text-center text-wrap text-white"
	} else if (workflow_state_name == "abi_monitoring_aborted_req") {
		cssName = "d-block px-2 py-1 rounded bg-accent text-center text-wrap text-white"
	} else if (workflow_state_name == "revised") {
		cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	}
	else if (workflow_state_name == "abi_monitoring_need_rev") {
		cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	}
	else if (workflow_state_name == "abi_monitoring_revise_upload") {
		cssName = "d-block px-2 py-1 rounded bg-warning text-center text-wrap text-white"
	}
	else if (workflow_state_name == "abi_monitoring") {
		cssName = "d-block px-2 py-1 rounded bg-success text-center text-wrap text-white"
	}
	else if (workflow_state_name == "rejected") {
		cssName = "d-block px-2 py-1 rounded bg-danger text-center text-wrap text-white"
	}
	else if (workflow_state_name == "abi_monitoring_finished") {
		cssName = "d-block px-2 py-1 rounded bg-gold text-center text-wrap text-white"
	}
	else if (workflow_state_name == "abi_monitoring_aborted") {
		cssName = "d-block px-2 py-1 rounded bg-dark text-center text-wrap text-white"
	} else {
		cssName = "d-block px-2 py-1 rounded bg-metal text-center text-wrap text-white"
	}
	return (<span className={cssName}>{val}</span>)
}
export const workflow_state_name_light = function (workflow_state_name, workflow_state_state, is_continue) {
	let cssName = "";
	let val = workflow_state_state;
	if (workflow_state_name === "ideation_draft") {
		cssName = "d-block px-2 py-1 rounded bg-light-metal text-center text-wrap text-metal"
	} else if (workflow_state_name === "ideation_approve_2") {
		cssName = "d-block px-2 py-1 rounded bg-light-metal text-center text-wrap text-metal"
	} else if (workflow_state_name === "ideation_approve_1") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name === "portfolio_waiting_approval") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name === "portfolio_new_approve_2") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name === "monitoring_waiting_approval") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name === "monitoring_continue_request") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name === "monitoring_finished_request") {
		cssName = "d-block px-2 py-1 rounded bg-light-accent text-center text-wrap text-accent"
	} else if (workflow_state_name === "monitoring_aborted_request") {
		cssName = "d-block px-2 py-1 rounded bg-light-accent text-center text-wrap text-accent"
	} else if (workflow_state_name === "ideation_approve_1_revise") {
		cssName = "d-block px-2 py-1 rounded bg-light-warning text-center text-wrap text-warning"
	} else if (workflow_state_name === "portfolio_waiting_approval_revise") {
		cssName = "d-block px-2 py-1 rounded bg-light-warning text-center text-wrap text-warning"
	} else if (workflow_state_name === "monitoring_revise_portfolio") {
		cssName = "d-block px-2 py-1 rounded bg-light-warning text-center text-wrap text-warning"
	} else if (workflow_state_name === "monitoring_revise_upload") {
		cssName = "d-block px-2 py-1 rounded bg-light-warning text-center text-wrap text-warning"
	} else if (workflow_state_name === "monitoring" && is_continue === 1) {
		cssName = "d-block px-2 py-1 rounded bg-light-success text-center text-wrap text-success"
		val = val + "(Continue)"
	} else if (workflow_state_name === "monitoring" && is_continue !== 1) {
		cssName = "d-block px-2 py-1 rounded bg-light-success text-center text-wrap text-success"
	} else if (workflow_state_name == "ideation_approve_1_reject") {
		cssName = "d-block px-2 py-1 rounded bg-light-danger text-center text-wrap text-danger"
	} else if (workflow_state_name === "portfolio_waiting_approval_reject") {
		cssName = "d-block px-2 py-1 rounded bg-light-danger text-center text-wrap text-danger"
	} else if (workflow_state_name === "monitoring_finished") {
		cssName = "d-block px-2 py-1 rounded bg-light-gold text-center text-wrap text-gold"
	} else if (workflow_state_name === "monitoring_aborted") {
		cssName = "d-block px-2 py-1 rounded bg-light-dark text-center text-wrap text-dark"
	} else if (workflow_state_name == "draft") {
		cssName = "d-block px-2 py-1 rounded bg-light-metal text-center text-wrap text-metal"
	} else if (workflow_state_name == "waiting_approval") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name == "approved") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name == "abi_monitoring_need_rev_review") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name == "abi_monitoring_continue_req") {
		cssName = "d-block px-2 py-1 rounded bg-light-info text-center text-wrap text-info"
	} else if (workflow_state_name == "abi_monitoring_finished_req") {
		cssName = "d-block px-2 py-1 rounded bg-light-accent text-center text-wrap text-accent"
	} else if (workflow_state_name == "abi_monitoring_aborted_req") {
		cssName = "d-block px-2 py-1 rounded bg-light-accent text-center text-wrap text-accent"
	} else if (workflow_state_name == "revised") {
		cssName = "d-block px-2 py-1 rounded bg-light-warning text-center text-wrap text-warning"
	}
	else if (workflow_state_name == "abi_monitoring_need_rev") {
		cssName = "d-block px-2 py-1 rounded bg-light-warning text-center text-wrap text-warning"
	}
	else if (workflow_state_name == "abi_monitoring_revise_upload") {
		cssName = "d-block px-2 py-1 rounded bg-light-warning text-center text-wrap text-warning"
	}
	else if (workflow_state_name == "abi_monitoring") {
		cssName = "d-block px-2 py-1 rounded bg-light-success text-center text-wrap text-success"
	}
	else if (workflow_state_name == "rejected") {
		cssName = "d-block px-2 py-1 rounded bg-light-danger text-center text-wrap text-danger"
	}
	else if (workflow_state_name == "abi_monitoring_finished") {
		cssName = "d-block px-2 py-1 rounded bg-light-gold text-center text-wrap text-gold"
	}
	else if (workflow_state_name == "abi_monitoring_aborted") {
		cssName = "d-block px-2 py-1 rounded bg-light-dark text-center text-wrap text-dark"
	} else {
		cssName = "d-block px-2 py-1 rounded bg-light text-center text-wrap text-dark"
	}
	return (<span className={cssName}>{val}</span>)
}
export const color_workflow_stage = function (workflow_stage) {
	var color = '';
	if (workflow_stage === "Project Ideation") {
		color = "bg-light-success text-left";
	}
	else if (workflow_stage === "Project Portfolio") {
		color = "bg-light-primary text-left";
	}
	else if (workflow_stage === "Project Monitoring") {
		color = "bg-light-purple text-left";
	}
	else if (workflow_stage === "ABI Request") {
		color = "bg-light-primary text-left";
	}
	else if (workflow_stage === "ABI Monitoring") {
		color = "bg-light-purple text-left";
	}
	else {
		color = "bg-light-secondary text-left";
	}
	return color;

}
export const project_type_string = function (project_type) {
	if (project_type === 1) {
		return 'Product Development'
	} else if (project_type === 2) {
		return 'Technology Selection'
	} else if (project_type === 3) {
		return 'Research Study'
	} else if (project_type === 4) {
		return 'Commercial'
	} else if (project_type === 5) {
		return 'Operational'
	} else if (project_type === 6) {
		return 'ABI / ABO Pra Proyek'
	}
}
export const action_portfolio_table_crud = function (item) {
	let data = {
		titleAction: '',
		url: ''
	}
	if (item?.workflow_state_name != "monitoring_revise_portfolio") {
		if (item.project_type == 5) {

		}
		else {
			data.titleAction = 'View'
			data.url = '/portfolio-research/view/' + item.id
		}
	}
	else {
		if (item?.project_type == 5) {

		}
		else {
			data.titleAction = 'view'
			data.url = '/portfolio-research/view/' + item.id
		}
	}

	if (item?.workflow_state_name == "monitoring") {
		if (item?.project_type == 5) {

		}
		else {
			data.titleAction = 'Edit Team Member'
			data.url = '/portfolio-research/view/' + item.id
		}
	}


	if (item?.workflow_state_name != "portfolio_new_approve_2" && item?.workflow_state_name != "monitoring" &&
		item?.workflow_state_name != "monitoring_continue_request" && item?.workflow_state_name != "monitoring_revise_upload" &&
		item?.workflow_state_name != "monitoring_revise_portfolio" && item?.workflow_state_name != "monitoring_waiting_approval" &&
		item?.workflow_state_name != "monitoring_finished_request" && item?.workflow_state_name != "monitoring_finished" &&
		item?.workflow_state_name != "monitoring_aborted_request" && item?.workflow_state_name != "monitoring_aborted") {
		data.titleAction = 'Delete'
		data.url = '/portfolio-research/view/' + item.id
	}
	return data
}
export const icon_project = function iconProject(project_type, workflow_stage, workflow_state_name, project_type_name) {
	const project = project_type ? project_type.toString() : '';
	let fill = '';
	let filla = '';
	let fillb = '';
	let fillc = '';
	let filld = '';
	let str = ''
	if (workflow_stage === "Project Ideation") {
		fill = '#1BC5BD';
		filla = '#1BC5BD';
		fillb = '#1BC5BD';
		fillc = '#1BC5BD';
		filld = '#1BC5BD';
		str = 'Ideation'
	}
	else if (workflow_stage === "Project Portfolio") {
		fill = '#3699ff';
		filla = '#3699ff';
		fillb = '#3699ff';
		fillc = '#3699ff';
		filld = '#3699ff';
		str = 'Portfolio'
	}
	else if (workflow_stage === "Project Monitoring") {
		fill = '#6f42c1';
		filla = '#6f42c1';
		fillb = '#6f42c1';
		fillc = '#6f42c1';
		filld = '#6f42c1';
		str = 'Monitoring'
	}
	else if (workflow_stage === "ABI Request") {
		fill = '#3699ff';
		filla = '#3699ff';
		fillb = '#3699ff';
		fillc = '#3699ff';
		filld = '#3699ff';
		str = 'ABI Request'
	}
	else if (workflow_stage === "ABI Monitoring") {
		fill = '#6f42c1';
		filla = '#6f42c1';
		fillb = '#6f42c1';
		fillc = '#6f42c1';
		filld = '#6f42c1';
		str = 'ABI Monitoring'
	}
	else {
		fill = '#00000';
		filla = '#00000';
		fillb = '#00000';
		fillc = '#00000';
		filld = '#00000';
		str = workflow_state_name === 'monitoring_aborted' ? 'Project Aborted' :
			workflow_state_name === 'monitoring_finished' ? 'Project Finished' : ''
	}

	if (project === '1') {
		return (
			<>
				<table>
					<tbody>
						<tr>
							<td>
								<div className="d-block"><ProductIcon fillb={fillb} filla={filla} /></div>
							</td>
							<td>
								<span className="mx-3 d-block text-left" style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</>
		)
	} else if (project === '2') {
		return (
			<>
				<table>
					<tbody>
						<tr>
							<td>
								<div className="d-block"><TechIcon fill={fill} /></div>
							</td>
							<td>
								<span className="mx-3 d-block text-left" style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</>
		)
	} else if (project === '3') {
		return (
			<>
				<table>
					<tbody>
						<tr>
							<td>
								<div className="d-block"><ResearchIcon fill={fill} /></div>
							</td>
							<td>
								<span className="mx-3 d-block text-left" style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</>
		)
	} else if (project === '4') {
		return (
			<>
				<table>
					<tbody>
						<tr>
							<td>
								<div className="d-block"><CommercialIcon fill={fill} /></div>
							</td>
							<td>
								<span className="mx-3 d-block text-left" style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</>
		)
	} else if (project === '5') {
		return (
			<>
				<table>
					<tbody>
						<tr>
							<td>
								<div className="d-block"><OperationalIcon fill={fill} /></div>
							</td>
							<td>
								<span className="mx-3 d-block text-left" style={{ color: fill, fontSize: '8pt' }}>{project_type_string(project_type)} - {str}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</>
		)
	} else if (project === '6' || project === '7') {
		return (
			<>
				<table>
					<tbody>
						<tr>
							<td>
								<div className="d-block"><AbiIcon fillb={fillb} fillc={fillc} stroke={fillb} /></div>
							</td>
							<td>
								<span className="mx-3 d-block text-left" style={{ color: fill, fontSize: '8pt' }}>{project_type_name} - {str}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</>
		)
	}
}
export const array_type = [{ name: 'Function VP', value: '1' }, { name: 'Type Project', value: '2' }]
export const array_type_invers = [{ name: 'Type Project', value: '1' }, { name: 'Function VP', value: '2' }]
export const jangkaWaktu = [{ value: 1, name: 1 },
{ value: 2, name: 2 },
{ value: 3, name: 3 },
{ value: 4, name: 4 },
{ value: 5, name: 5 },
{ value: 6, name: 6 },
{ value: 7, name: 7 },
{ value: 8, name: 8 },
{ value: 9, name: 9 },
{ value: 10, name: 10 },
{ value: 11, name: 11 },
{ value: 12, name: 12 },
{ value: 13, name: 13 },
{ value: 14, name: 14 },
{ value: 15, name: 15 },]
export const dokPenType = [{ value: "file", name: "File" }, { value: "url", name: "Masukan Link/Url" }]
export const status_pegawai = (status) => {
	if (status === "Active") {
		return (
			<span className="d-block bg-light-success text-success rounded-pill py-1 px-3">
				{status}
			</span>
		)
	} else if (status === "PWTT") {
		return (
			<span className="d-block bg-light-success text-success rounded-pill py-1 px-3">
				{status}
			</span>
		)
	} else if (status === "Non Active") {
		return (
			<span className="d-block bg-light-dark text-dark rounded-pill py-1 px-3">
				{status}
			</span>
		)
	} else if (status === "Retired") {
		return (
			<span className="d-block bg-light-danger text-danger rounded-pill py-1 px-3">
				{status}
			</span>
		)
	} else if (status === "Contract") {
		return (
			<span className="d-block bg-light-primary text-primary rounded-pill py-1 px-3">
				{status}
			</span>
		)
	} else if (status === "Mitra Kerja") {
		return (
			<span className="d-block bg-light-primary text-primary rounded-pill py-1 px-3">
				{status}
			</span>
		)
	} else if (status === "PWT") {
		return (
			<span className="d-block bg-light-purple text-purple rounded-pill py-1 px-3">
				{status}
			</span>
		)
	}
}
export const status_pegawai_solid = (status) => {
	if (status === "Active") {
		return (
			<span className="d-block bg-success text-white rounded-pill py-2 px-4">
				{status}
			</span>
		)
	}
	else if (status === "Non Active") {
		return (
			<span className="d-block bg-dark text-white rounded-pill py-2 px-4">
				{status}
			</span>
		)
	}
	else if (status === "Retired") {
		return (
			<span className="d-block bg-danger text-white rounded-pill py-2 px-4">
				{status}
			</span>
		)
	}
	else if (status === "Contract") {
		return (
			<span className="d-block bg-success text-white rounded-pill py-2 px-4">
				{status}
			</span>
		)
	}
}
export const achievement_level = (level) => {
	if (level === "Internal") {
		return (
			<span className="bg-light-warning text-warning p-2  rounded">{level}</span>
		)
	} else if (level === "National") {
		return (
			<span className="bg-light-success text-success p-2 rounded">{level}</span>
		)
	} else if (level === "International") {
		return (
			<span className="bg-light-info text-info p-2 rounded">{level}</span>
		)
	} else {
		return (
			<span className="bg-light-primary text-primary p-2 rounded">{level}</span>
		)
	}
}
export const status_equipment = (status = '') => {
	var cssName = '';
	var statusName = status;
	if (status === 'Idle') {
		cssName = 'bg-blue text-white';
	}
	if (status === 'Ready') {
		cssName = 'bg-success text-white';
	}
	if (status === 'Maintenance') {
		cssName = 'bg-warning text-white'
	}
	if (status === 'Broken') {
		cssName = 'bg-danger text-white'
	}
	if (status === 'Expired') {
		cssName = 'bg-red-dark text-white'
	}
	if (status === 'No Status') {
		cssName = 'bg-secondary text-dark'
	}

	if (status === 'IDL') {
		cssName = 'bg-blue text-white';
	}
	if (status === 'RDY') {
		cssName = 'bg-success text-white';
	}
	if (status === 'MTN') {
		cssName = 'bg-warning text-white'
	}
	if (status === 'BKN') {
		cssName = 'bg-danger text-white'
	}
	if (status === 'EXP') {
		cssName = 'bg-red-dark text-white'
	}
	if (status === 'NST') {
		cssName = 'bg-secondary text-dark'
	}
	if (status === 'UPP/Dumped') {
		cssName = 'bg-dark text-white'
	}
	if (status === 'UPP') {
		cssName = 'bg-dark text-white'
	}
	return (
		<span className={`d-block py-1 px-2 rounded text-center ${cssName}`}>{statusName}</span>
	)
}
export const fileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
export const fileExtensions_string = ['jpg', 'jpeg', 'gif', 'png'];
export const chart_color = ['#006CB8', '#369AFF', '#F04445', '#F08044', '#F2BA22', '#ACC429', '#46CCB1', '#716ACA', '#C070CC'];
export const month_source = [
	{ name: 'Januari', value: '1' },
	{ name: 'Februari', value: '2' },
	{ name: 'Maret', value: '3' },
	{ name: 'April', value: '4' },
	{ name: 'Mei', value: '5' },
	{ name: 'Juni', value: '6' },
	{ name: 'Juli', value: '7' },
	{ name: 'Agustus', value: '8' },
	{ name: 'September', value: '9' },
	{ name: 'Oktober', value: '10' },
	{ name: 'November', value: '11' },
	{ name: 'Desember', value: '12' }
]
export const month_source_mm = [
	{ name: 'Januari', value: '01' },
	{ name: 'Februari', value: '02' },
	{ name: 'Maret', value: '03' },
	{ name: 'April', value: '04' },
	{ name: 'Mei', value: '05' },
	{ name: 'Juni', value: '06' },
	{ name: 'Juli', value: '07' },
	{ name: 'Agustus', value: '08' },
	{ name: 'September', value: '09' },
	{ name: 'Oktober', value: '10' },
	{ name: 'November', value: '11' },
	{ name: 'Desember', value: '12' }
]
export const swa_status_color = (status_name = '') => {
	var className = '';
	if (status_name === 'Validasi') {
		className = 'bg-purple';
	}
	if (status_name === 'Need Revision') {
		className = 'bg-warning'
	}
	if (status_name === 'Revisi') {
		className = 'bg-warning'
	}
	if (status_name === 'Pengadaan') {
		className = 'bg-success'
	}
	if (status_name === 'Kontrak Berjalan') {
		className = 'bg-primary'
	}
	if (status_name === 'Addendum') {
		className = 'bg-orange'
	}
	if (status_name === 'Rejected') {
		className = 'bg-danger'
	}
	if (status_name === 'Selesai') {
		className = 'bg-dark'
	}
	return className;
}
export const research_level = [
	{ name: 'Project Running', value: '45' },
	{ name: 'Finish', value: '52' },
	{ name: 'Aborted', value: '61' },
]
export const tipe_pembayaran = [
	{ name: 'At Cost', value: 'At Cost' },
	{ name: 'Lumpsum', value: 'Lumpsum' }
]
export const achievement_status_filter = [
	{ text: 'Waiting Approval', value: 1 },
	{ text: 'Approved', value: 2 },
]
export const faq_status_filter = [
	{ text: 'Aktif', value: 1 },
	{ text: 'Inaktif', value: 0 },
]
export const generate_year = () => {
	const y = Array.from(Array(6), (d, i) => {
		const x = moment().add((-3 + i), 'years').format('YYYY');
		return {
			value: x,
			name: x
		}
	})
	return y;
}
export const generate_prev_year = (ct = 3) => {
	const y = Array.from(Array((ct)), (d, i) => {
		const x = moment().add((((ct - 1) * -1) + i), 'years').format('YYYY');
		return {
			value: x,
			name: x
		}
	})
	return y;
}
export const isValidHttpUrl = (string) => {
	let url;

	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	// console.log('uriiii', url);
	return url.protocol === "http:" || url.protocol === "https:";
}
export const usr_action_type = [
	{ name: 'VIEW', value: 'VIEW' },
	{ name: 'ADD', value: 'ADD' },
	{ name: 'UPDATE', value: 'UPDATE' },
	{ name: 'DELETE', value: 'DELETE' }
]
export const cut_off_option = function () {
	const opt = []
	for (let i = 1; i <= 31; i++) {
		opt.push({
			name: i,
			value: i
		})
	}
	return opt
}
export const sector_source = [
	{
		name: 'Hulu', value: 'Hulu'
	},
	{
		name: 'Hilir', value: 'Hilir'
	},
	{
		name: 'NRE', value: 'NRE'
	}
]
export const equipment_pc_filter = [
	{ text: 'Ada', value: true },
	{ text: 'Tidak Ada', value: false },
]
export const equipment_iso_1705_filter = [
	{ text: 'Ya', value: true },
	{ text: 'Tidak', value: false },
]
export const provinsiIndonesia = [
	{
		"id": "11",
		"name": "Aceh",
		"alt_name": "ACEH",
		"latitude": "4.36855",
		"longitude": "97.0253"
	},
	{
		"id": "12",
		"name": "Sumatera Utara",
		"alt_name": "SUMATERA UTARA",
		"latitude": "2.19235",
		"longitude": "99.38122"
	},
	{
		"id": "13",
		"name": "Sumatera Barat",
		"alt_name": "SUMATERA BARAT",
		"latitude": "-1.34225",
		"longitude": "100.0761"
	},
	{
		"id": "14",
		"name": "Riau",
		"alt_name": "RIAU",
		"latitude": "0.50041",
		"longitude": "101.54758"
	},
	{
		"id": "15",
		"name": "Jambi",
		"alt_name": "JAMBI",
		"latitude": "-1.61157",
		"longitude": "102.7797"
	},
	{
		"id": "16",
		"name": "Sumatera Selatan",
		"alt_name": "SUMATERA SELATAN",
		"latitude": "-3.12668",
		"longitude": "104.09306"
	},
	{
		"id": "17",
		"name": "Bengkulu",
		"alt_name": "BENGKULU",
		"latitude": "-3.51868",
		"longitude": "102.53598"
	},
	{
		"id": "18",
		"name": "Lampung",
		"alt_name": "LAMPUNG",
		"latitude": "-4.8555",
		"longitude": "105.0273"
	},
	{
		"id": "19",
		"name": "Kepulauan Bangka Belitung",
		"alt_name": "KEPULAUAN BANGKA BELITUNG",
		"latitude": "-2.75775",
		"longitude": "107.58394"
	},
	{
		"id": "21",
		"name": "Kepulauan Riau",
		"alt_name": "KEPULAUAN RIAU",
		"latitude": "-0.15478",
		"longitude": "104.58037"
	},
	{
		"id": "31",
		"name": "DKI Jakarta",
		"alt_name": "DKI JAKARTA",
		"latitude": "-6.1745",
		"longitude": "106.8227"
	},
	{
		"id": "32",
		"name": "Jawa Barat",
		"alt_name": "JAWA BARAT",
		"latitude": "-6.88917",
		"longitude": "107.64047"
	},
	{
		"id": "33",
		"name": "Jawa Tengah",
		"alt_name": "JAWA TENGAH",
		"latitude": "-7.30324",
		"longitude": "110.00441"
	},
	{
		"id": "34",
		"name": "DIY Yogyakarta",
		"alt_name": "DIY YOGYAKARTA",
		"latitude": "7.7956",
		"longitude": "110.3695"
	},
	{
		"id": "35",
		"name": "Jawa Timur",
		"alt_name": "JAWA TIMUR",
		"latitude": "-6.96851",
		"longitude": "113.98005"
	},
	{
		"id": "36",
		"name": "Banten",
		"alt_name": "BANTEN",
		"latitude": "-6.44538",
		"longitude": "106.13756"
	},
	{
		"id": "51",
		"name": "Bali",
		"alt_name": "BALI",
		"latitude": "-8.23566",
		"longitude": "115.12239"
	},
	{
		"id": "52",
		"name": "Nusa Tenggara Barat",
		"alt_name": "NUSA TENGGARA BARAT",
		"latitude": "-8.12179",
		"longitude": "117.63696"
	},
	{
		"id": "53",
		"name": "Nusa Tenggara Timur",
		"alt_name": "NUSA TENGGARA TIMUR",
		"latitude": "-8.56568",
		"longitude": "120.69786"
	},
	{
		"id": "61",
		"name": "Kalimantan Barat",
		"alt_name": "KALIMANTAN BARAT",
		"latitude": "-0.13224",
		"longitude": "111.09689"
	},
	{
		"id": "62",
		"name": "Kalimantan Tengah",
		"alt_name": "KALIMANTAN TENGAH",
		"latitude": "-1.49958",
		"longitude": "113.29033"
	},
	{
		"id": "63",
		"name": "Kalimantan Selatan",
		"alt_name": "KALIMANTAN SELATAN",
		"latitude": "-2.94348",
		"longitude": "115.37565"
	},
	{
		"id": "64",
		"name": "Kalimantan Timur",
		"alt_name": "KALIMANTAN TIMUR",
		"latitude": "0.78844",
		"longitude": "116.242"
	},
	{
		"id": "65",
		"name": "Kalimantan Utara",
		"alt_name": "KALIMANTAN UTARA",
		"latitude": "2.72594",
		"longitude": "116.911"
	},
	{
		"id": "71",
		"name": "Sulawesi Utara",
		"alt_name": "SULAWESI UTARA",
		"latitude": "0.65557",
		"longitude": "124.09015"
	},
	{
		"id": "72",
		"name": "Sulawesi Tengah",
		"alt_name": "SULAWESI TENGAH",
		"latitude": "-1.69378",
		"longitude": "120.80886"
	},
	{
		"id": "73",
		"name": "Sulawesi Selatan",
		"alt_name": "SULAWESI SELATAN",
		"latitude": "-3.64467",
		"longitude": "119.94719"
	},
	{
		"id": "74",
		"name": "Sulawesi Tenggara",
		"alt_name": "SULAWESI TENGGARA",
		"latitude": "-3.54912",
		"longitude": "121.72796"
	},
	{
		"id": "75",
		"name": "Gorontalo",
		"alt_name": "GORONTALO",
		"latitude": "0.71862",
		"longitude": "122.45559"
	},
	{
		"id": "76",
		"name": "Sulawesi Barat",
		"alt_name": "SULAWESI BARAT",
		"latitude": "-2.49745",
		"longitude": "119.3919"
	},
	{
		"id": "81",
		"name": "Maluku",
		"alt_name": "MALUKU",
		"latitude": "-3.11884",
		"longitude": "129.42078"
	},
	{
		"id": "82",
		"name": "Maluku Utara",
		"alt_name": "MALUKU UTARA",
		"latitude": "0.63012",
		"longitude": "127.97202"
	},
	{
		"id": "91",
		"name": "Papua Barat",
		"alt_name": "PAPUA BARAT",
		"latitude": "-1.38424",
		"longitude": "132.90253"
	},
	{
		"id": "94",
		"name": "Papua",
		"alt_name": "PAPUA",
		"latitude": "-3.98857",
		"longitude": "138.34853"
	}
]
export const countriesGDP = [
	{
		"name": "Afghanistan",
		"alpha2": "AF",
		"alpha3": "AFG",
		"numeric": 4,
		"latitude": 33,
		"longitude": 65
	},
	{
		"name": "Albania",
		"alpha2": "AL",
		"alpha3": "ALB",
		"numeric": 8,
		"latitude": 41,
		"longitude": 20
	},
	{
		"name": "Algeria",
		"alpha2": "DZ",
		"alpha3": "DZA",
		"numeric": 12,
		"latitude": 28,
		"longitude": 3
	},
	{
		"name": "American Samoa",
		"alpha2": "AS",
		"alpha3": "ASM",
		"numeric": 16,
		"latitude": -14.3333,
		"longitude": -170
	},
	{
		"name": "Andorra",
		"alpha2": "AD",
		"alpha3": "AND",
		"numeric": 20,
		"latitude": 42.5,
		"longitude": 1.6
	},
	{
		"name": "Angola",
		"alpha2": "AO",
		"alpha3": "AGO",
		"numeric": 24,
		"latitude": -12.5,
		"longitude": 18.5
	},
	{
		"name": "Anguilla",
		"alpha2": "AI",
		"alpha3": "AIA",
		"numeric": 660,
		"latitude": 18.25,
		"longitude": -63.1667
	},
	{
		"name": "Antarctica",
		"alpha2": "AQ",
		"alpha3": "ATA",
		"numeric": 10,
		"latitude": -90,
		"longitude": 0
	},
	{
		"name": "Antigua and Barbuda",
		"alpha2": "AG",
		"alpha3": "ATG",
		"numeric": 28,
		"latitude": 17.05,
		"longitude": -61.8
	},
	{
		"name": "Argentina",
		"alpha2": "AR",
		"alpha3": "ARG",
		"numeric": 32,
		"latitude": -34,
		"longitude": -64
	},
	{
		"name": "Armenia",
		"alpha2": "AM",
		"alpha3": "ARM",
		"numeric": 51,
		"latitude": 40,
		"longitude": 45
	},
	{
		"name": "Aruba",
		"alpha2": "AW",
		"alpha3": "ABW",
		"numeric": 533,
		"latitude": 12.5,
		"longitude": -69.9667
	},
	{
		"name": "Australia",
		"alpha2": "AU",
		"alpha3": "AUS",
		"numeric": 36,
		"latitude": -27,
		"longitude": 133
	},
	{
		"name": "Austria",
		"alpha2": "AT",
		"alpha3": "AUT",
		"numeric": 40,
		"latitude": 47.3333,
		"longitude": 13.3333
	},
	{
		"name": "Azerbaijan",
		"alpha2": "AZ",
		"alpha3": "AZE",
		"numeric": 31,
		"latitude": 40.5,
		"longitude": 47.5
	},
	{
		"name": "Bahamas",
		"alpha2": "BS",
		"alpha3": "BHS",
		"numeric": 44,
		"latitude": 24.25,
		"longitude": -76
	},
	{
		"name": "Bahrain",
		"alpha2": "BH",
		"alpha3": "BHR",
		"numeric": 48,
		"latitude": 26,
		"longitude": 50.55
	},
	{
		"name": "Bangladesh",
		"alpha2": "BD",
		"alpha3": "BGD",
		"numeric": 50,
		"latitude": 24,
		"longitude": 90
	},
	{
		"name": "Barbados",
		"alpha2": "BB",
		"alpha3": "BRB",
		"numeric": 52,
		"latitude": 13.1667,
		"longitude": -59.5333
	},
	{
		"name": "Belarus",
		"alpha2": "BY",
		"alpha3": "BLR",
		"numeric": 112,
		"latitude": 53,
		"longitude": 28
	},
	{
		"name": "Belgium",
		"alpha2": "BE",
		"alpha3": "BEL",
		"numeric": 56,
		"latitude": 50.8333,
		"longitude": 4
	},
	{
		"name": "Belize",
		"alpha2": "BZ",
		"alpha3": "BLZ",
		"numeric": 84,
		"latitude": 17.25,
		"longitude": -88.75
	},
	{
		"name": "Benin",
		"alpha2": "BJ",
		"alpha3": "BEN",
		"numeric": 204,
		"latitude": 9.5,
		"longitude": 2.25
	},
	{
		"name": "Bermuda",
		"alpha2": "BM",
		"alpha3": "BMU",
		"numeric": 60,
		"latitude": 32.3333,
		"longitude": -64.75
	},
	{
		"name": "Bhutan",
		"alpha2": "BT",
		"alpha3": "BTN",
		"numeric": 64,
		"latitude": 27.5,
		"longitude": 90.5
	},
	{
		"name": "Bolivia, Plurinational State of",
		"alpha2": "BO",
		"alpha3": "BOL",
		"numeric": 68,
		"latitude": -17,
		"longitude": -65
	},
	{
		"name": "Bosnia and Herzegovina",
		"alpha2": "BA",
		"alpha3": "BIH",
		"numeric": 70,
		"latitude": 44,
		"longitude": 18
	},
	{
		"name": "Botswana",
		"alpha2": "BW",
		"alpha3": "BWA",
		"numeric": 72,
		"latitude": -22,
		"longitude": 24
	},
	{
		"name": "Bouvet Island",
		"alpha2": "BV",
		"alpha3": "BVT",
		"numeric": 74,
		"latitude": -54.4333,
		"longitude": 3.4
	},
	{
		"name": "Brazil",
		"alpha2": "BR",
		"alpha3": "BRA",
		"numeric": 76,
		"latitude": -10,
		"longitude": -55
	},
	{
		"name": "British Indian Ocean Territory",
		"alpha2": "IO",
		"alpha3": "IOT",
		"numeric": 86,
		"latitude": -6,
		"longitude": 71.5
	},
	{
		"name": "Brunei Darussalam",
		"alpha2": "BN",
		"alpha3": "BRN",
		"numeric": 96,
		"latitude": 4.5,
		"longitude": 114.6667
	},
	{
		"name": "Bulgaria",
		"alpha2": "BG",
		"alpha3": "BGR",
		"numeric": 100,
		"latitude": 43,
		"longitude": 25
	},
	{
		"name": "Burkina Faso",
		"alpha2": "BF",
		"alpha3": "BFA",
		"numeric": 854,
		"latitude": 13,
		"longitude": -2
	},
	{
		"name": "Burundi",
		"alpha2": "BI",
		"alpha3": "BDI",
		"numeric": 108,
		"latitude": -3.5,
		"longitude": 30
	},
	{
		"name": "Cambodia",
		"alpha2": "KH",
		"alpha3": "KHM",
		"numeric": 116,
		"latitude": 13,
		"longitude": 105
	},
	{
		"name": "Cameroon",
		"alpha2": "CM",
		"alpha3": "CMR",
		"numeric": 120,
		"latitude": 6,
		"longitude": 12
	},
	{
		"name": "Canada",
		"alpha2": "CA",
		"alpha3": "CAN",
		"numeric": 124,
		"latitude": 60,
		"longitude": -95
	},
	{
		"name": "Cape Verde",
		"alpha2": "CV",
		"alpha3": "CPV",
		"numeric": 132,
		"latitude": 16,
		"longitude": -24
	},
	{
		"name": "Cayman Islands",
		"alpha2": "KY",
		"alpha3": "CYM",
		"numeric": 136,
		"latitude": 19.5,
		"longitude": -80.5
	},
	{
		"name": "Central African Republic",
		"alpha2": "CF",
		"alpha3": "CAF",
		"numeric": 140,
		"latitude": 7,
		"longitude": 21
	},
	{
		"name": "Chad",
		"alpha2": "TD",
		"alpha3": "TCD",
		"numeric": 148,
		"latitude": 15,
		"longitude": 19
	},
	{
		"name": "Chile",
		"alpha2": "CL",
		"alpha3": "CHL",
		"numeric": 152,
		"latitude": -30,
		"longitude": -71
	},
	{
		"name": "China",
		"alpha2": "CN",
		"alpha3": "CHN",
		"numeric": 156,
		"latitude": 35,
		"longitude": 105
	},
	{
		"name": "Christmas Island",
		"alpha2": "CX",
		"alpha3": "CXR",
		"numeric": 162,
		"latitude": -10.5,
		"longitude": 105.6667
	},
	{
		"name": "Cocos (Keeling) Islands",
		"alpha2": "CC",
		"alpha3": "CCK",
		"numeric": 166,
		"latitude": -12.5,
		"longitude": 96.8333
	},
	{
		"name": "Colombia",
		"alpha2": "CO",
		"alpha3": "COL",
		"numeric": 170,
		"latitude": 4,
		"longitude": -72
	},
	{
		"name": "Comoros",
		"alpha2": "KM",
		"alpha3": "COM",
		"numeric": 174,
		"latitude": -12.1667,
		"longitude": 44.25
	},
	{
		"name": "Congo",
		"alpha2": "CG",
		"alpha3": "COG",
		"numeric": 178,
		"latitude": -1,
		"longitude": 15
	},
	{
		"name": "Congo, the Democratic Republic of the",
		"alpha2": "CD",
		"alpha3": "COD",
		"numeric": 180,
		"latitude": 0,
		"longitude": 25
	},
	{
		"name": "Cook Islands",
		"alpha2": "CK",
		"alpha3": "COK",
		"numeric": 184,
		"latitude": -21.2333,
		"longitude": -159.7667
	},
	{
		"name": "Costa Rica",
		"alpha2": "CR",
		"alpha3": "CRI",
		"numeric": 188,
		"latitude": 10,
		"longitude": -84
	},
	{
		"name": "Croatia",
		"alpha2": "HR",
		"alpha3": "HRV",
		"numeric": 191,
		"latitude": 45.1667,
		"longitude": 15.5
	},
	{
		"name": "Cuba",
		"alpha2": "CU",
		"alpha3": "CUB",
		"numeric": 192,
		"latitude": 21.5,
		"longitude": -80
	},
	{
		"name": "Cyprus",
		"alpha2": "CY",
		"alpha3": "CYP",
		"numeric": 196,
		"latitude": 35,
		"longitude": 33
	},
	{
		"name": "Czech Republic",
		"alpha2": "CZ",
		"alpha3": "CZE",
		"numeric": 203,
		"latitude": 49.75,
		"longitude": 15.5
	},
	{
		"name": "Côte d'Ivoire",
		"alpha2": "CI",
		"alpha3": "CIV",
		"numeric": 384,
		"latitude": 8,
		"longitude": -5
	},
	{
		"name": "Denmark",
		"alpha2": "DK",
		"alpha3": "DNK",
		"numeric": 208,
		"latitude": 56,
		"longitude": 10
	},
	{
		"name": "Djibouti",
		"alpha2": "DJ",
		"alpha3": "DJI",
		"numeric": 262,
		"latitude": 11.5,
		"longitude": 43
	},
	{
		"name": "Dominica",
		"alpha2": "DM",
		"alpha3": "DMA",
		"numeric": 212,
		"latitude": 15.4167,
		"longitude": -61.3333
	},
	{
		"name": "Dominican Republic",
		"alpha2": "DO",
		"alpha3": "DOM",
		"numeric": 214,
		"latitude": 19,
		"longitude": -70.6667
	},
	{
		"name": "Ecuador",
		"alpha2": "EC",
		"alpha3": "ECU",
		"numeric": 218,
		"latitude": -2,
		"longitude": -77.5
	},
	{
		"name": "Egypt",
		"alpha2": "EG",
		"alpha3": "EGY",
		"numeric": 818,
		"latitude": 27,
		"longitude": 30
	},
	{
		"name": "El Salvador",
		"alpha2": "SV",
		"alpha3": "SLV",
		"numeric": 222,
		"latitude": 13.8333,
		"longitude": -88.9167
	},
	{
		"name": "Equatorial Guinea",
		"alpha2": "GQ",
		"alpha3": "GNQ",
		"numeric": 226,
		"latitude": 2,
		"longitude": 10
	},
	{
		"name": "Eritrea",
		"alpha2": "ER",
		"alpha3": "ERI",
		"numeric": 232,
		"latitude": 15,
		"longitude": 39
	},
	{
		"name": "Estonia",
		"alpha2": "EE",
		"alpha3": "EST",
		"numeric": 233,
		"latitude": 59,
		"longitude": 26
	},
	{
		"name": "Ethiopia",
		"alpha2": "ET",
		"alpha3": "ETH",
		"numeric": 231,
		"latitude": 8,
		"longitude": 38
	},
	{
		"name": "Falkland Islands (Malvinas)",
		"alpha2": "FK",
		"alpha3": "FLK",
		"numeric": 238,
		"latitude": -51.75,
		"longitude": -59
	},
	{
		"name": "Faroe Islands",
		"alpha2": "FO",
		"alpha3": "FRO",
		"numeric": 234,
		"latitude": 62,
		"longitude": -7
	},
	{
		"name": "Fiji",
		"alpha2": "FJ",
		"alpha3": "FJI",
		"numeric": 242,
		"latitude": -18,
		"longitude": 175
	},
	{
		"name": "Finland",
		"alpha2": "FI",
		"alpha3": "FIN",
		"numeric": 246,
		"latitude": 64,
		"longitude": 26
	},
	{
		"name": "France",
		"alpha2": "FR",
		"alpha3": "FRA",
		"numeric": 250,
		"latitude": 46,
		"longitude": 2
	},
	{
		"name": "French Guiana",
		"alpha2": "GF",
		"alpha3": "GUF",
		"numeric": 254,
		"latitude": 4,
		"longitude": -53
	},
	{
		"name": "French Polynesia",
		"alpha2": "PF",
		"alpha3": "PYF",
		"numeric": 258,
		"latitude": -15,
		"longitude": -140
	},
	{
		"name": "French Southern Territories",
		"alpha2": "TF",
		"alpha3": "ATF",
		"numeric": 260,
		"latitude": -43,
		"longitude": 67
	},
	{
		"name": "Gabon",
		"alpha2": "GA",
		"alpha3": "GAB",
		"numeric": 266,
		"latitude": -1,
		"longitude": 11.75
	},
	{
		"name": "Gambia",
		"alpha2": "GM",
		"alpha3": "GMB",
		"numeric": 270,
		"latitude": 13.4667,
		"longitude": -16.5667
	},
	{
		"name": "Georgia",
		"alpha2": "GE",
		"alpha3": "GEO",
		"numeric": 268,
		"latitude": 42,
		"longitude": 43.5
	},
	{
		"name": "Germany",
		"alpha2": "DE",
		"alpha3": "DEU",
		"numeric": 276,
		"latitude": 51,
		"longitude": 9
	},
	{
		"name": "Ghana",
		"alpha2": "GH",
		"alpha3": "GHA",
		"numeric": 288,
		"latitude": 8,
		"longitude": -2
	},
	{
		"name": "Gibraltar",
		"alpha2": "GI",
		"alpha3": "GIB",
		"numeric": 292,
		"latitude": 36.1833,
		"longitude": -5.3667
	},
	{
		"name": "Greece",
		"alpha2": "GR",
		"alpha3": "GRC",
		"numeric": 300,
		"latitude": 39,
		"longitude": 22
	},
	{
		"name": "Greenland",
		"alpha2": "GL",
		"alpha3": "GRL",
		"numeric": 304,
		"latitude": 72,
		"longitude": -40
	},
	{
		"name": "Grenada",
		"alpha2": "GD",
		"alpha3": "GRD",
		"numeric": 308,
		"latitude": 12.1167,
		"longitude": -61.6667
	},
	{
		"name": "Guadeloupe",
		"alpha2": "GP",
		"alpha3": "GLP",
		"numeric": 312,
		"latitude": 16.25,
		"longitude": -61.5833
	},
	{
		"name": "Guam",
		"alpha2": "GU",
		"alpha3": "GUM",
		"numeric": 316,
		"latitude": 13.4667,
		"longitude": 144.7833
	},
	{
		"name": "Guatemala",
		"alpha2": "GT",
		"alpha3": "GTM",
		"numeric": 320,
		"latitude": 15.5,
		"longitude": -90.25
	},
	{
		"name": "Guernsey",
		"alpha2": "GG",
		"alpha3": "GGY",
		"numeric": 831,
		"latitude": 49.5,
		"longitude": -2.56
	},
	{
		"name": "Guinea",
		"alpha2": "GN",
		"alpha3": "GIN",
		"numeric": 324,
		"latitude": 11,
		"longitude": -10
	},
	{
		"name": "Guinea-Bissau",
		"alpha2": "GW",
		"alpha3": "GNB",
		"numeric": 624,
		"latitude": 12,
		"longitude": -15
	},
	{
		"name": "Guyana",
		"alpha2": "GY",
		"alpha3": "GUY",
		"numeric": 328,
		"latitude": 5,
		"longitude": -59
	},
	{
		"name": "Haiti",
		"alpha2": "HT",
		"alpha3": "HTI",
		"numeric": 332,
		"latitude": 19,
		"longitude": -72.4167
	},
	{
		"name": "Heard Island and McDonald Islands",
		"alpha2": "HM",
		"alpha3": "HMD",
		"numeric": 334,
		"latitude": -53.1,
		"longitude": 72.5167
	},
	{
		"name": "Holy See (Vatican City State)",
		"alpha2": "VA",
		"alpha3": "VAT",
		"numeric": 336,
		"latitude": 41.9,
		"longitude": 12.45
	},
	{
		"name": "Honduras",
		"alpha2": "HN",
		"alpha3": "HND",
		"numeric": 340,
		"latitude": 15,
		"longitude": -86.5
	},
	{
		"name": "Hong Kong",
		"alpha2": "HK",
		"alpha3": "HKG",
		"numeric": 344,
		"latitude": 22.25,
		"longitude": 114.1667
	},
	{
		"name": "Hungary",
		"alpha2": "HU",
		"alpha3": "HUN",
		"numeric": 348,
		"latitude": 47,
		"longitude": 20
	},
	{
		"name": "Iceland",
		"alpha2": "IS",
		"alpha3": "ISL",
		"numeric": 352,
		"latitude": 65,
		"longitude": -18
	},
	{
		"name": "India",
		"alpha2": "IN",
		"alpha3": "IND",
		"numeric": 356,
		"latitude": 20,
		"longitude": 77
	},
	{
		"name": "Indonesia",
		"alpha2": "ID",
		"alpha3": "IDN",
		"numeric": 360,
		"latitude": -5,
		"longitude": 120
	},
	{
		"name": "Iran, Islamic Republic of",
		"alpha2": "IR",
		"alpha3": "IRN",
		"numeric": 364,
		"latitude": 32,
		"longitude": 53
	},
	{
		"name": "Iraq",
		"alpha2": "IQ",
		"alpha3": "IRQ",
		"numeric": 368,
		"latitude": 33,
		"longitude": 44
	},
	{
		"name": "Ireland",
		"alpha2": "IE",
		"alpha3": "IRL",
		"numeric": 372,
		"latitude": 53,
		"longitude": -8
	},
	{
		"name": "Isle of Man",
		"alpha2": "IM",
		"alpha3": "IMN",
		"numeric": 833,
		"latitude": 54.23,
		"longitude": -4.55
	},
	{
		"name": "Israel",
		"alpha2": "IL",
		"alpha3": "ISR",
		"numeric": 376,
		"latitude": 31.5,
		"longitude": 34.75
	},
	{
		"name": "Italy",
		"alpha2": "IT",
		"alpha3": "ITA",
		"numeric": 380,
		"latitude": 42.8333,
		"longitude": 12.8333
	},
	{
		"name": "Jamaica",
		"alpha2": "JM",
		"alpha3": "JAM",
		"numeric": 388,
		"latitude": 18.25,
		"longitude": -77.5
	},
	{
		"name": "Japan",
		"alpha2": "JP",
		"alpha3": "JPN",
		"numeric": 392,
		"latitude": 36,
		"longitude": 138
	},
	{
		"name": "Jersey",
		"alpha2": "JE",
		"alpha3": "JEY",
		"numeric": 832,
		"latitude": 49.21,
		"longitude": -2.13
	},
	{
		"name": "Jordan",
		"alpha2": "JO",
		"alpha3": "JOR",
		"numeric": 400,
		"latitude": 31,
		"longitude": 36
	},
	{
		"name": "Kazakhstan",
		"alpha2": "KZ",
		"alpha3": "KAZ",
		"numeric": 398,
		"latitude": 48,
		"longitude": 68
	},
	{
		"name": "Kenya",
		"alpha2": "KE",
		"alpha3": "KEN",
		"numeric": 404,
		"latitude": 1,
		"longitude": 38
	},
	{
		"name": "Kiribati",
		"alpha2": "KI",
		"alpha3": "KIR",
		"numeric": 296,
		"latitude": 1.4167,
		"longitude": 173
	},
	{
		"name": "Korea, Democratic People's Republic of",
		"alpha2": "KP",
		"alpha3": "PRK",
		"numeric": 408,
		"latitude": 40,
		"longitude": 127
	},
	{
		"name": "Korea, Republic of",
		"alpha2": "KR",
		"alpha3": "KOR",
		"numeric": 410,
		"latitude": 37,
		"longitude": 127.5
	},
	{
		"name": "Kuwait",
		"alpha2": "KW",
		"alpha3": "KWT",
		"numeric": 414,
		"latitude": 29.3375,
		"longitude": 47.6581
	},
	{
		"name": "Kyrgyzstan",
		"alpha2": "KG",
		"alpha3": "KGZ",
		"numeric": 417,
		"latitude": 41,
		"longitude": 75
	},
	{
		"name": "Lao People's Democratic Republic",
		"alpha2": "LA",
		"alpha3": "LAO",
		"numeric": 418,
		"latitude": 18,
		"longitude": 105
	},
	{
		"name": "Latvia",
		"alpha2": "LV",
		"alpha3": "LVA",
		"numeric": 428,
		"latitude": 57,
		"longitude": 25
	},
	{
		"name": "Lebanon",
		"alpha2": "LB",
		"alpha3": "LBN",
		"numeric": 422,
		"latitude": 33.8333,
		"longitude": 35.8333
	},
	{
		"name": "Lesotho",
		"alpha2": "LS",
		"alpha3": "LSO",
		"numeric": 426,
		"latitude": -29.5,
		"longitude": 28.5
	},
	{
		"name": "Liberia",
		"alpha2": "LR",
		"alpha3": "LBR",
		"numeric": 430,
		"latitude": 6.5,
		"longitude": -9.5
	},
	{
		"name": "Libyan Arab Jamahiriya",
		"alpha2": "LY",
		"alpha3": "LBY",
		"numeric": 434,
		"latitude": 25,
		"longitude": 17
	},
	{
		"name": "Liechtenstein",
		"alpha2": "LI",
		"alpha3": "LIE",
		"numeric": 438,
		"latitude": 47.1667,
		"longitude": 9.5333
	},
	{
		"name": "Lithuania",
		"alpha2": "LT",
		"alpha3": "LTU",
		"numeric": 440,
		"latitude": 56,
		"longitude": 24
	},
	{
		"name": "Luxembourg",
		"alpha2": "LU",
		"alpha3": "LUX",
		"numeric": 442,
		"latitude": 49.75,
		"longitude": 6.1667
	},
	{
		"name": "Macao",
		"alpha2": "MO",
		"alpha3": "MAC",
		"numeric": 446,
		"latitude": 22.1667,
		"longitude": 113.55
	},
	{
		"name": "Macedonia, the former Yugoslav Republic of",
		"alpha2": "MK",
		"alpha3": "MKD",
		"numeric": 807,
		"latitude": 41.8333,
		"longitude": 22
	},
	{
		"name": "Madagascar",
		"alpha2": "MG",
		"alpha3": "MDG",
		"numeric": 450,
		"latitude": -20,
		"longitude": 47
	},
	{
		"name": "Malawi",
		"alpha2": "MW",
		"alpha3": "MWI",
		"numeric": 454,
		"latitude": -13.5,
		"longitude": 34
	},
	{
		"name": "Malaysia",
		"alpha2": "MY",
		"alpha3": "MYS",
		"numeric": 458,
		"latitude": 2.5,
		"longitude": 112.5
	},
	{
		"name": "Maldives",
		"alpha2": "MV",
		"alpha3": "MDV",
		"numeric": 462,
		"latitude": 3.25,
		"longitude": 73
	},
	{
		"name": "Mali",
		"alpha2": "ML",
		"alpha3": "MLI",
		"numeric": 466,
		"latitude": 17,
		"longitude": -4
	},
	{
		"name": "Malta",
		"alpha2": "MT",
		"alpha3": "MLT",
		"numeric": 470,
		"latitude": 35.8333,
		"longitude": 14.5833
	},
	{
		"name": "Marshall Islands",
		"alpha2": "MH",
		"alpha3": "MHL",
		"numeric": 584,
		"latitude": 9,
		"longitude": 168
	},
	{
		"name": "Martinique",
		"alpha2": "MQ",
		"alpha3": "MTQ",
		"numeric": 474,
		"latitude": 14.6667,
		"longitude": -61
	},
	{
		"name": "Mauritania",
		"alpha2": "MR",
		"alpha3": "MRT",
		"numeric": 478,
		"latitude": 20,
		"longitude": -12
	},
	{
		"name": "Mauritius",
		"alpha2": "MU",
		"alpha3": "MUS",
		"numeric": 480,
		"latitude": -20.2833,
		"longitude": 57.55
	},
	{
		"name": "Mayotte",
		"alpha2": "YT",
		"alpha3": "MYT",
		"numeric": 175,
		"latitude": -12.8333,
		"longitude": 45.1667
	},
	{
		"name": "Mexico",
		"alpha2": "MX",
		"alpha3": "MEX",
		"numeric": 484,
		"latitude": 23,
		"longitude": -102
	},
	{
		"name": "Micronesia, Federated States of",
		"alpha2": "FM",
		"alpha3": "FSM",
		"numeric": 583,
		"latitude": 6.9167,
		"longitude": 158.25
	},
	{
		"name": "Moldova, Republic of",
		"alpha2": "MD",
		"alpha3": "MDA",
		"numeric": 498,
		"latitude": 47,
		"longitude": 29
	},
	{
		"name": "Monaco",
		"alpha2": "MC",
		"alpha3": "MCO",
		"numeric": 492,
		"latitude": 43.7333,
		"longitude": 7.4
	},
	{
		"name": "Mongolia",
		"alpha2": "MN",
		"alpha3": "MNG",
		"numeric": 496,
		"latitude": 46,
		"longitude": 105
	},
	{
		"name": "Montenegro",
		"alpha2": "ME",
		"alpha3": "MNE",
		"numeric": 499,
		"latitude": 42,
		"longitude": 19
	},
	{
		"name": "Montserrat",
		"alpha2": "MS",
		"alpha3": "MSR",
		"numeric": 500,
		"latitude": 16.75,
		"longitude": -62.2
	},
	{
		"name": "Morocco",
		"alpha2": "MA",
		"alpha3": "MAR",
		"numeric": 504,
		"latitude": 32,
		"longitude": -5
	},
	{
		"name": "Mozambique",
		"alpha2": "MZ",
		"alpha3": "MOZ",
		"numeric": 508,
		"latitude": -18.25,
		"longitude": 35
	},
	{
		"name": "Myanmar",
		"alpha2": "MM",
		"alpha3": "MMR",
		"numeric": 104,
		"latitude": 22,
		"longitude": 98
	},
	{
		"name": "Namibia",
		"alpha2": "NA",
		"alpha3": "NAM",
		"numeric": 516,
		"latitude": -22,
		"longitude": 17
	},
	{
		"name": "Nauru",
		"alpha2": "NR",
		"alpha3": "NRU",
		"numeric": 520,
		"latitude": -0.5333,
		"longitude": 166.9167
	},
	{
		"name": "Nepal",
		"alpha2": "NP",
		"alpha3": "NPL",
		"numeric": 524,
		"latitude": 28,
		"longitude": 84
	},
	{
		"name": "Netherlands",
		"alpha2": "NL",
		"alpha3": "NLD",
		"numeric": 528,
		"latitude": 52.5,
		"longitude": 5.75
	},
	{
		"name": "Netherlands Antilles",
		"alpha2": "AN",
		"alpha3": "ANT",
		"numeric": 530,
		"latitude": 12.25,
		"longitude": -68.75
	},
	{
		"name": "New Caledonia",
		"alpha2": "NC",
		"alpha3": "NCL",
		"numeric": 540,
		"latitude": -21.5,
		"longitude": 165.5
	},
	{
		"name": "New Zealand",
		"alpha2": "NZ",
		"alpha3": "NZL",
		"numeric": 554,
		"latitude": -41,
		"longitude": 174
	},
	{
		"name": "Nicaragua",
		"alpha2": "NI",
		"alpha3": "NIC",
		"numeric": 558,
		"latitude": 13,
		"longitude": -85
	},
	{
		"name": "Niger",
		"alpha2": "NE",
		"alpha3": "NER",
		"numeric": 562,
		"latitude": 16,
		"longitude": 8
	},
	{
		"name": "Nigeria",
		"alpha2": "NG",
		"alpha3": "NGA",
		"numeric": 566,
		"latitude": 10,
		"longitude": 8
	},
	{
		"name": "Niue",
		"alpha2": "NU",
		"alpha3": "NIU",
		"numeric": 570,
		"latitude": -19.0333,
		"longitude": -169.8667
	},
	{
		"name": "Norfolk Island",
		"alpha2": "NF",
		"alpha3": "NFK",
		"numeric": 574,
		"latitude": -29.0333,
		"longitude": 167.95
	},
	{
		"name": "Northern Mariana Islands",
		"alpha2": "MP",
		"alpha3": "MNP",
		"numeric": 580,
		"latitude": 15.2,
		"longitude": 145.75
	},
	{
		"name": "Norway",
		"alpha2": "NO",
		"alpha3": "NOR",
		"numeric": 578,
		"latitude": 62,
		"longitude": 10
	},
	{
		"name": "Oman",
		"alpha2": "OM",
		"alpha3": "OMN",
		"numeric": 512,
		"latitude": 21,
		"longitude": 57
	},
	{
		"name": "Pakistan",
		"alpha2": "PK",
		"alpha3": "PAK",
		"numeric": 586,
		"latitude": 30,
		"longitude": 70
	},
	{
		"name": "Palau",
		"alpha2": "PW",
		"alpha3": "PLW",
		"numeric": 585,
		"latitude": 7.5,
		"longitude": 134.5
	},
	{
		"name": "Palestinian Territory, Occupied",
		"alpha2": "PS",
		"alpha3": "PSE",
		"numeric": 275,
		"latitude": 32,
		"longitude": 35.25
	},
	{
		"name": "Panama",
		"alpha2": "PA",
		"alpha3": "PAN",
		"numeric": 591,
		"latitude": 9,
		"longitude": -80
	},
	{
		"name": "Papua New Guinea",
		"alpha2": "PG",
		"alpha3": "PNG",
		"numeric": 598,
		"latitude": -6,
		"longitude": 147
	},
	{
		"name": "Paraguay",
		"alpha2": "PY",
		"alpha3": "PRY",
		"numeric": 600,
		"latitude": -23,
		"longitude": -58
	},
	{
		"name": "Peru",
		"alpha2": "PE",
		"alpha3": "PER",
		"numeric": 604,
		"latitude": -10,
		"longitude": -76
	},
	{
		"name": "Philippines",
		"alpha2": "PH",
		"alpha3": "PHL",
		"numeric": 608,
		"latitude": 13,
		"longitude": 122
	},
	{
		"name": "Pitcairn",
		"alpha2": "PN",
		"alpha3": "PCN",
		"numeric": 612,
		"latitude": -24.7,
		"longitude": -127.4
	},
	{
		"name": "Poland",
		"alpha2": "PL",
		"alpha3": "POL",
		"numeric": 616,
		"latitude": 52,
		"longitude": 20
	},
	{
		"name": "Portugal",
		"alpha2": "PT",
		"alpha3": "PRT",
		"numeric": 620,
		"latitude": 39.5,
		"longitude": -8
	},
	{
		"name": "Puerto Rico",
		"alpha2": "PR",
		"alpha3": "PRI",
		"numeric": 630,
		"latitude": 18.25,
		"longitude": -66.5
	},
	{
		"name": "Qatar",
		"alpha2": "QA",
		"alpha3": "QAT",
		"numeric": 634,
		"latitude": 25.5,
		"longitude": 51.25
	},
	{
		"name": "Romania",
		"alpha2": "RO",
		"alpha3": "ROU",
		"numeric": 642,
		"latitude": 46,
		"longitude": 25
	},
	{
		"name": "Russian Federation",
		"alpha2": "RU",
		"alpha3": "RUS",
		"numeric": 643,
		"latitude": 60,
		"longitude": 100
	},
	{
		"name": "Rwanda",
		"alpha2": "RW",
		"alpha3": "RWA",
		"numeric": 646,
		"latitude": -2,
		"longitude": 30
	},
	{
		"name": "Réunion",
		"alpha2": "RE",
		"alpha3": "REU",
		"numeric": 638,
		"latitude": -21.1,
		"longitude": 55.6
	},
	{
		"name": "Saint Helena, Ascension and Tristan da Cunha",
		"alpha2": "SH",
		"alpha3": "SHN",
		"numeric": 654,
		"latitude": -15.9333,
		"longitude": -5.7
	},
	{
		"name": "Saint Kitts and Nevis",
		"alpha2": "KN",
		"alpha3": "KNA",
		"numeric": 659,
		"latitude": 17.3333,
		"longitude": -62.75
	},
	{
		"name": "Saint Lucia",
		"alpha2": "LC",
		"alpha3": "LCA",
		"numeric": 662,
		"latitude": 13.8833,
		"longitude": -61.1333
	},
	{
		"name": "Saint Pierre and Miquelon",
		"alpha2": "PM",
		"alpha3": "SPM",
		"numeric": 666,
		"latitude": 46.8333,
		"longitude": -56.3333
	},
	{
		"name": "Saint Vincent and the Grenadines",
		"alpha2": "VC",
		"alpha3": "VCT",
		"numeric": 670,
		"latitude": 13.25,
		"longitude": -61.2
	},
	{
		"name": "Samoa",
		"alpha2": "WS",
		"alpha3": "WSM",
		"numeric": 882,
		"latitude": -13.5833,
		"longitude": -172.3333
	},
	{
		"name": "San Marino",
		"alpha2": "SM",
		"alpha3": "SMR",
		"numeric": 674,
		"latitude": 43.7667,
		"longitude": 12.4167
	},
	{
		"name": "Sao Tome and Principe",
		"alpha2": "ST",
		"alpha3": "STP",
		"numeric": 678,
		"latitude": 1,
		"longitude": 7
	},
	{
		"name": "Saudi Arabia",
		"alpha2": "SA",
		"alpha3": "SAU",
		"numeric": 682,
		"latitude": 25,
		"longitude": 45
	},
	{
		"name": "Senegal",
		"alpha2": "SN",
		"alpha3": "SEN",
		"numeric": 686,
		"latitude": 14,
		"longitude": -14
	},
	{
		"name": "Serbia",
		"alpha2": "RS",
		"alpha3": "SRB",
		"numeric": 688,
		"latitude": 44,
		"longitude": 21
	},
	{
		"name": "Seychelles",
		"alpha2": "SC",
		"alpha3": "SYC",
		"numeric": 690,
		"latitude": -4.5833,
		"longitude": 55.6667
	},
	{
		"name": "Sierra Leone",
		"alpha2": "SL",
		"alpha3": "SLE",
		"numeric": 694,
		"latitude": 8.5,
		"longitude": -11.5
	},
	{
		"name": "Singapore",
		"alpha2": "SG",
		"alpha3": "SGP",
		"numeric": 702,
		"latitude": 1.3667,
		"longitude": 103.8
	},
	{
		"name": "Slovakia",
		"alpha2": "SK",
		"alpha3": "SVK",
		"numeric": 703,
		"latitude": 48.6667,
		"longitude": 19.5
	},
	{
		"name": "Slovenia",
		"alpha2": "SI",
		"alpha3": "SVN",
		"numeric": 705,
		"latitude": 46,
		"longitude": 15
	},
	{
		"name": "Solomon Islands",
		"alpha2": "SB",
		"alpha3": "SLB",
		"numeric": 90,
		"latitude": -8,
		"longitude": 159
	},
	{
		"name": "Somalia",
		"alpha2": "SO",
		"alpha3": "SOM",
		"numeric": 706,
		"latitude": 10,
		"longitude": 49
	},
	{
		"name": "South Africa",
		"alpha2": "ZA",
		"alpha3": "ZAF",
		"numeric": 710,
		"latitude": -29,
		"longitude": 24
	},
	{
		"name": "South Georgia and the South Sandwich Islands",
		"alpha2": "GS",
		"alpha3": "SGS",
		"numeric": 239,
		"latitude": -54.5,
		"longitude": -37
	},
	{
		"name": "Spain",
		"alpha2": "ES",
		"alpha3": "ESP",
		"numeric": 724,
		"latitude": 40,
		"longitude": -4
	},
	{
		"name": "Sri Lanka",
		"alpha2": "LK",
		"alpha3": "LKA",
		"numeric": 144,
		"latitude": 7,
		"longitude": 81
	},
	{
		"name": "Sudan",
		"alpha2": "SD",
		"alpha3": "SDN",
		"numeric": 736,
		"latitude": 15,
		"longitude": 30
	},
	{
		"name": "Suriname",
		"alpha2": "SR",
		"alpha3": "SUR",
		"numeric": 740,
		"latitude": 4,
		"longitude": -56
	},
	{
		"name": "Svalbard and Jan Mayen",
		"alpha2": "SJ",
		"alpha3": "SJM",
		"numeric": 744,
		"latitude": 78,
		"longitude": 20
	},
	{
		"name": "Swaziland",
		"alpha2": "SZ",
		"alpha3": "SWZ",
		"numeric": 748,
		"latitude": -26.5,
		"longitude": 31.5
	},
	{
		"name": "Sweden",
		"alpha2": "SE",
		"alpha3": "SWE",
		"numeric": 752,
		"latitude": 62,
		"longitude": 15
	},
	{
		"name": "Switzerland",
		"alpha2": "CH",
		"alpha3": "CHE",
		"numeric": 756,
		"latitude": 47,
		"longitude": 8
	},
	{
		"name": "Syrian Arab Republic",
		"alpha2": "SY",
		"alpha3": "SYR",
		"numeric": 760,
		"latitude": 35,
		"longitude": 38
	},
	{
		"name": "Taiwan, Province of China",
		"alpha2": "TW",
		"alpha3": "TWN",
		"numeric": 158,
		"latitude": 23.5,
		"longitude": 121
	},
	{
		"name": "Tajikistan",
		"alpha2": "TJ",
		"alpha3": "TJK",
		"numeric": 762,
		"latitude": 39,
		"longitude": 71
	},
	{
		"name": "Tanzania, United Republic of",
		"alpha2": "TZ",
		"alpha3": "TZA",
		"numeric": 834,
		"latitude": -6,
		"longitude": 35
	},
	{
		"name": "Thailand",
		"alpha2": "TH",
		"alpha3": "THA",
		"numeric": 764,
		"latitude": 15,
		"longitude": 100
	},
	{
		"name": "Timor-Leste",
		"alpha2": "TL",
		"alpha3": "TLS",
		"numeric": 626,
		"latitude": -8.55,
		"longitude": 125.5167
	},
	{
		"name": "Togo",
		"alpha2": "TG",
		"alpha3": "TGO",
		"numeric": 768,
		"latitude": 8,
		"longitude": 1.1667
	},
	{
		"name": "Tokelau",
		"alpha2": "TK",
		"alpha3": "TKL",
		"numeric": 772,
		"latitude": -9,
		"longitude": -172
	},
	{
		"name": "Tonga",
		"alpha2": "TO",
		"alpha3": "TON",
		"numeric": 776,
		"latitude": -20,
		"longitude": -175
	},
	{
		"name": "Trinidad and Tobago",
		"alpha2": "TT",
		"alpha3": "TTO",
		"numeric": 780,
		"latitude": 11,
		"longitude": -61
	},
	{
		"name": "Tunisia",
		"alpha2": "TN",
		"alpha3": "TUN",
		"numeric": 788,
		"latitude": 34,
		"longitude": 9
	},
	{
		"name": "Turkey",
		"alpha2": "TR",
		"alpha3": "TUR",
		"numeric": 792,
		"latitude": 39,
		"longitude": 35
	},
	{
		"name": "Turkmenistan",
		"alpha2": "TM",
		"alpha3": "TKM",
		"numeric": 795,
		"latitude": 40,
		"longitude": 60
	},
	{
		"name": "Turks and Caicos Islands",
		"alpha2": "TC",
		"alpha3": "TCA",
		"numeric": 796,
		"latitude": 21.75,
		"longitude": -71.5833
	},
	{
		"name": "Tuvalu",
		"alpha2": "TV",
		"alpha3": "TUV",
		"numeric": 798,
		"latitude": -8,
		"longitude": 178
	},
	{
		"name": "Uganda",
		"alpha2": "UG",
		"alpha3": "UGA",
		"numeric": 800,
		"latitude": 1,
		"longitude": 32
	},
	{
		"name": "Ukraine",
		"alpha2": "UA",
		"alpha3": "UKR",
		"numeric": 804,
		"latitude": 49,
		"longitude": 32
	},
	{
		"name": "United Arab Emirates",
		"alpha2": "AE",
		"alpha3": "ARE",
		"numeric": 784,
		"latitude": 24,
		"longitude": 54
	},
	{
		"name": "United Kingdom",
		"alpha2": "GB",
		"alpha3": "GBR",
		"numeric": 826,
		"latitude": 54,
		"longitude": -2
	},
	{
		"name": "United States",
		"alpha2": "US",
		"alpha3": "USA",
		"numeric": 840,
		"latitude": 38,
		"longitude": -97
	},
	{
		"name": "United States Minor Outlying Islands",
		"alpha2": "UM",
		"alpha3": "UMI",
		"numeric": 581,
		"latitude": 19.2833,
		"longitude": 166.6
	},
	{
		"name": "Uruguay",
		"alpha2": "UY",
		"alpha3": "URY",
		"numeric": 858,
		"latitude": -33,
		"longitude": -56
	},
	{
		"name": "Uzbekistan",
		"alpha2": "UZ",
		"alpha3": "UZB",
		"numeric": 860,
		"latitude": 41,
		"longitude": 64
	},
	{
		"name": "Vanuatu",
		"alpha2": "VU",
		"alpha3": "VUT",
		"numeric": 548,
		"latitude": -16,
		"longitude": 167
	},
	{
		"name": "Venezuela, Bolivarian Republic of",
		"alpha2": "VE",
		"alpha3": "VEN",
		"numeric": 862,
		"latitude": 8,
		"longitude": -66
	},
	{
		"name": "Viet Nam",
		"alpha2": "VN",
		"alpha3": "VNM",
		"numeric": 704,
		"latitude": 16,
		"longitude": 106
	},
	{
		"name": "Virgin Islands, British",
		"alpha2": "VG",
		"alpha3": "VGB",
		"numeric": 92,
		"latitude": 18.5,
		"longitude": -64.5
	},
	{
		"name": "Virgin Islands, U.S.",
		"alpha2": "VI",
		"alpha3": "VIR",
		"numeric": 850,
		"latitude": 18.3333,
		"longitude": -64.8333
	},
	{
		"name": "Wallis and Futuna",
		"alpha2": "WF",
		"alpha3": "WLF",
		"numeric": 876,
		"latitude": -13.3,
		"longitude": -176.2
	},
	{
		"name": "Western Sahara",
		"alpha2": "EH",
		"alpha3": "ESH",
		"numeric": 732,
		"latitude": 24.5,
		"longitude": -13
	},
	{
		"name": "Yemen",
		"alpha2": "YE",
		"alpha3": "YEM",
		"numeric": 887,
		"latitude": 15,
		"longitude": 48
	},
	{
		"name": "Zambia",
		"alpha2": "ZM",
		"alpha3": "ZMB",
		"numeric": 894,
		"latitude": -15,
		"longitude": 30
	},
	{
		"name": "Zimbabwe",
		"alpha2": "ZW",
		"alpha3": "ZWE",
		"numeric": 716,
		"latitude": -20,
		"longitude": 30
	}
]