import React, { memo } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderList, Tab, TabContent } from '../../components'
import TabDashboard from '../kerjasama/tabDashboard';
import useKejasama, { KerjasamaProvider } from '../kerjasama/useKerjasama';
import TabTable from './tabTable';


function KerjasamaCrud() {
    const { pathname } = useLocation();
    const { krjAction, krjState } = useKejasama();
    const history = useHistory();
    return (
        <>
            <div className="mb-5">
                <HeaderList
                    title={krjState.data_page?.page_title}
                    someText={krjState.data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            {/* <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        title: "Partnership Dashboard",
                        onClick: () => history.push(`/admin/partnership-crud/dashboard`)
                    },
                    {
                        id: "table",
                        title: "Partnership Table",
                        onClick: () => history.push(`/admin/partnership-crud/table`)
                    }
                ]}
                manualActive={pathname === '/admin/partnership-crud/table' ? 'table' : 'dashboard'}
            >
                <TabContent id="dashboard">
                    <TabDashboard></TabDashboard>
                </TabContent>
                <TabContent id="table"> */}
            <TabTable></TabTable>
            {/* </TabContent>
            </Tab> */}
        </>
    )
}
export default () => <KerjasamaProvider><KerjasamaCrud /></KerjasamaProvider>