import React, { memo } from 'react'
import { Card } from 'react-bootstrap'
import SVG from "react-inlinesvg";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default memo(function () {

    function resetClick() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-orange mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
            '<div><i class="fa fa-question icon-3x rounded p-5 bg-orange text-white customShadow mb-5"></i></div>' + 
            '<strong> Reset Status Cache? </strong>',
            showCancelButton: true,
            confirmButtonText: '<i class="fa fa-undo icon-sm text-white"></i> Reset',
            cancelButtonText: 'Kembali',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire('Deleted!', '', 'success')
                return false;
            } else {
                return true;
            }
        })
    }
    return (
        <Card
            className="m-0 p-0 customShadow"
        >
            <Card.Header>
                <span className="d-block" style={{ fontWeight: 'bold' }}>Reset Status Cache</span>
                <span className="d-block" style={{ color: '#C6C6C6' }}>Setting Reset Status Cache</span>
            </Card.Header>
            <Card.Body>
                <div className="d-block p-2 text-white rounded mb-5 text-center bg-orange">
                    <span className="svg-icon svg-icon-2x svg-icon-light px-5" style={{ verticalAlign: 'baseline' }}>
                        <SVG src="/media/svg/icons/Code/Warning-2.svg" />
                    </span>
                    Halaman ini untuk mengatur ulang jendela informasi yang muncul sebagai pemberitahuan agar user melakukan clear cache (hapus cache) pada browser
                </div>
                <span className="d-block p-2 mb-5">
                    Pembaruan terakhir&nbsp;
                    <span className="text-primary">12 Apr 2020 | 21:09:04</span>
                </span>
                <hr />
                <div className="d-block text-right">
                    <button className="btn btn-sm btn-orange mx-1"
                        onClick={() => resetClick()}
                    >
                        <i className="fa fa-undo icon-sm text-white"></i>
                        Reset Status Cache
                    </button>
                </div>
            </Card.Body>
        </Card>
    )
})