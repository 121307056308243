import React, { createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";
import useIndexLandPage from "../use_index";
import moment from "moment";

const lndEvenContext = createContext({
    lndEvenState: {},
    lndEvenAction: {}
});

export function LndEvenProvider({ children }) {
    const { indAction, indState } = useIndexLandPage();
    const [keyword_search, setkeyword_search] = useState('');
    const [status, setStatus] = useState({
        key: 1,
        date_start: '',
        date_end: ''
    })
    const [dataEvent, setDataEvent] = useState({});
    const { doGet } = useFetch();

    async function getData(keyword = '', start_date = '', end_date = '') {
        // setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: '/api/web/all-event',
                param: {
                    date_start: start_date,
                    date_end: end_date,
                    keyword_search: keyword
                },
                token: false
            });
            if (resp?.code === '00') {
                // console.log('resp', resp.data)
                setDataEvent({ ...resp?.data })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        // setLoading(false);
    }
    function keywordEnter(e) {
        if (e?.key === 'Enter') {
            getData(keyword_search, status.date_start, status.date_end);
        }
    }
    function statusChange(key) {
        if (key === 1) {
            setStatus({
                key: 1,
                date_start: '',
                date_end: ''
            });
            getData(keyword_search, '', '');
        }
        if (key === 2) {
            setStatus({
                key: 2,
                date_start: moment(new Date()).format('YYYY-MM-DD'),
                date_end: ''
            });
            getData(keyword_search, moment(new Date()).format('YYYY-MM-DD'), '');
        }
        if (key === 3) {
            setStatus({
                key: 3,
                date_start: moment(new Date()).add(-1, 'days').format('YYYY-MM-DD'),
                date_end: ''
            });
            getData(keyword_search, '', moment(new Date()).add(-1, 'days').format('YYYY-MM-DD'));
        }
    }
    useEffect(() => {
        getData(keyword_search, status.date_start, status.date_end);
        // indAction.setScrollElement('beranda');
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])
    return <lndEvenContext.Provider
        value={{
            lndEvenState: {
                dataEvent,
                keyword_search,
                status
            },
            lndEvenAction: {
                setkeyword_search,
                keywordEnter,
                statusChange
            }
        }}
    >{children}</lndEvenContext.Provider>
}
export default function useLndEven() {
    return useContext(lndEvenContext);
}