import React, { useContext, useEffect, useReducer } from 'react';
import fetchApi from '../modules/fetch';
import reducers from './reducers';
import useLocalStorage from './useLocalStorage';
import Swal from 'sweetalert2'
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// import 'sweetalert2/src/sweetalert2.scss'

const initialState = {
    state: "SPIIRIT",
    formData: {},
    modal: {},
    defValue: {},
    laoding: false,
    data: "",
    filterData: {},
    loadingGet: false,
    isAuthenticated: false
}
const globalContext = React.createContext(null);


const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, initialState);
    const [token, setToken] = useLocalStorage("token", null);
    const [, setIdaman] = useLocalStorage("idaman", null);
    const [user, setUser] = useLocalStorage("current_user")
    const history = useHistory();
    const [pathBefore, setPathBefore] = useLocalStorage("pathBefore");


    // useEffect(function () {
    //     if (token !== null && token !== undefined && token !== "")
    //         dispatch({
    //             type: "setLogin"
    //         })
    //     else dispatch({
    //         type: "setLogout"
    //     })
    // }, [])
    // useEffect(function () {
    //     // window.addEventListener("beforeunload", function (e) {
    //     //     var confirmationMessage = "\o/";

    //     //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    //     //     return confirmationMessage;                            //Webkit, Safari, Chrome
    //     //   });
    //     // return () => {
    //     //     window.removeEventListener("*", ()=>null)
    //     // }
    // }, [])
    const actions = {
        setState: () => {
            return dispatch({
                type: "setState",
                payload: state
            })
        },
        setFilter: (key, value) => {
            return dispatch({
                type: "setFilter",
                payload: { key, value }
            })
        },
        setForm: (key, value) => {
            return dispatch({
                type: "setForm",
                payload: { key, value }
            })
        },
        resetForm: () => {
            return dispatch({
                type: "setState",
                payload: { formData: {} }
            })
        },
        doLogin: async (withLoading = true, body = {}) => {
            dispatch({
                type: "loadingLogin"
            })
            const response = await fetchApi("/account/login", {
                "method": "POST",
                "body": JSON.stringify(body)
            })
            if (response.code !== "00") {
                dispatch({
                    type: "loginFailed"
                })
                return withLoading ? Swal.fire({
                    title: 'Error!',
                    text: 'Email atau password salah',
                    icon: 'error',
                }) : null;
            }

            let getstrg = localStorage.getItem("pathBefore") || "/"
            let path = getstrg !== "/" ? JSON.parse(getstrg) : getstrg;
            path = getstrg.split('/')[1] === "landing-page" ? "/" : path;
            if (response?.data?.role_id === '10') {
                path = '/Monitoring/sub-holdings';
            }
            setUser(JSON.stringify({
                ...response.data,
                token: response.token
            }))
            setToken(response.token)
            dispatch({
                type: "loginSuccess",
                payload: response.data
            })
            setIdaman(false);
            return window.location.href = path
        },
        doLoginIdaman: async (withLoading = true, body = {}) => {
            dispatch({
                type: "loadingLogin"
            })
            const response = await fetchApi("/account/login-idaman", {
                "method": "POST",
                "body": JSON.stringify(body)
            })

            if (response.code !== "00") {
                return {
                    success: false,
                    message: response?.message
                }
            }

            setUser(JSON.stringify({
                ...response.data,
                token: response.token
            }))
            dispatch({
                type: "loginSuccess",
                payload: response.data
            })
            setToken(response.token);
            setIdaman(true);
            return {
                success: true,
                message: response?.message
            };
        },
        doLogout: () => {
            setToken(null)
            setPathBefore("/")
            dispatch({ type: "setLogout" })
            return window.location.href = "/"
        },
        getData: async (url, param = {}, _var = null, _loadingVar = "loadingGet", withLoading = false) => {
            withLoading && Swal.fire({
                title: 'Please Wait',
                html: 'Retrieving data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            dispatch({
                type: "doGet",
                payload: {
                    [_loadingVar]: true
                }
            })
            var uri = url

            if (param != null) {
                var keys = Object.keys(param)
                keys.forEach((val, key) => {
                    if (key == 0)
                        uri = uri + "?"
                    uri = uri + (val + "=" + param[val])
                    if (key != (keys.length - 1))
                        uri = uri + "&"
                })
            }
            // console.log(uri)
            const response = await doFetch(uri);
            if (response?.code === "02") {

                Swal.fire({
                    title: 'Error!',
                    text: response.message,
                    icon: 'error',
                })
            }
            if (response?.code === "01") {
                Swal.fire({
                    title: 'Warning',
                    text: response.message,
                    icon: 'warning',
                })
            }
            if (response?.code === '04') {
                Swal.close();
                actions.doLogout();
                // history.replace('/error-page-session');
                // Swal.fire({
                //     title: 'Warning',
                //     allowOutsideClick: false,
                //     text: "Session anda sudah kadaluarsa. Silahkan login ulang!",
                //     icon: 'warning',
                // }).then(res =>
                //     actions.doLogout()
                // )
            }
            if (response?.code === '05') {
                Swal.close();
                history.replace('/error-page');
                // return Swal.fire({
                //     allowOutsideClick: false,                
                //     title: 'Warning',
                //     text: "Anda tidak memiliki hak akses halaman ini!.",
                //     icon: 'warning',
                // }).then(res => res.isConfirmed && window.history.back()
                //     //console.log('sasas',res)
                //     // window.history.back()
                // )
            }
            if (response?.code === "00") {
                withLoading && Swal.close()
            }
            var payload = { ...response?.data, [_loadingVar]: false };
            if (_var !== null) payload = { [_var]: response?.data, [_loadingVar]: false }
            if (response?.code === "00") {

                dispatch({
                    type: "getSuccess",
                    payload
                })
            }
            return response;

        },
        postData: async ({
            url,
            data,
            withLoading = true,
            withAlert = true,
            withFile = false
        }, callback) => {
            withLoading && Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            var dataForm = JSON.stringify(data);
            var opts = {
                method: "POST",
                "body": dataForm
            }
            // console.log("data yang dikirim", opts, url)
            if (withFile) {
                var keys = Object.keys(data)
                var form = new FormData()

                keys.forEach((val, key) => {
                    form.append(val, data[val]);
                })
                opts = {
                    "body": form,
                    "method": "POST",
                    headers: {

                    }
                }
                // console.log("data yang dikirim", opts, data)
            }
            // const response = await doFetch(url, {
            //     method:"POST",
            //     "body":dataForm
            // })

            const response = await doFetch(url, opts)
            //console.log("resp", response, opts)
            if (response.code === "02") {
                withAlert && (
                    Swal.fire({
                        title: 'Error!',
                        text: response.message,
                        icon: 'error',
                    })
                )
            }
            if (response.code === "01") {
                withAlert && (
                    Swal.fire({
                        title: 'Warning',
                        text: response.message,
                        icon: 'warning',
                    })
                )
            }
            if (response?.code === '04') {
                Swal.close();
                actions.doLogout();
                // history.replace('/error-page-session');
                // Swal.fire({
                //     title: 'Warning',
                //     allowOutsideClick: false,
                //     text: "Session anda sudah kadaluarsa. Silahkan login ulang!",
                //     icon: 'warning',
                // }).then(res =>
                //     actions.doLogout()
                // )
            }
            if (response?.code === '05') {
                Swal.close();
                history.replace('/error-page');
                // return Swal.fire({
                //     allowOutsideClick: false,                
                //     title: 'Warning',
                //     text: "Anda tidak memiliki hak akses halaman ini!.",
                //     icon: 'warning',
                // }).then(res => res.isConfirmed && window.history.back()
                //     //console.log('sasas',res)
                //     // window.history.back()
                // )
            }
            if (response.code === "00") {
                Swal.close()
                if (withAlert) {
                    const res = await Swal.fire({
                        title: 'Success',
                        text: response.message,
                        icon: 'success',
                    }).then(() => (
                        callback !== undefined && callback != null) && callback()
                    )
                    return response
                }
            }

            return response;
        },
        toggleModal: (modalName) => {
            const opened = (state.modal[modalName] === undefined || state.modal[modalName] === null) ? false : state.modal[modalName]
            dispatch({
                type: "setModal",
                payload: {
                    opened: !opened,
                    modalName
                }
            })
        },
        setData: (payload) => {
            dispatch({
                type: "setData",
                payload: payload
            })
        },
        setFilterDefault: (formData) => {
            dispatch({
                type: "setFilterDefault",
                payload: formData
            })
        },
        setFormDefault: (formData) => {
            dispatch({
                type: "setFormDefault",
                payload: formData
            })
        }
    }
    // function getSession() {
    //     //const sisa = moment.duration((jwtDecode(token)?.exp - (moment(moment(new Date()).format('YYYY-MM-DD HH:mm:ss')).format('x') / 1000)), 'millisecond')};
    //     return `Session Time ${moment.unix(jwtDecode(token)?.exp).format("YYYY-MM-DD HH:mm:ss")} : Now ${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")} : Count `;
    // }
    async function doFetch(url, opts = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    },
        typeResponse = "json"
    ) {
        var auth = {
            'Authorization': 'Bearer ' + token
        }

        if (opts.headers === undefined) {
            opts = {
                ...opts,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...auth
                }
            }
        } else {
            opts = {
                ...opts,
                headers: {
                    ...opts.headers,
                    ...auth
                }
            }
        }
        let response = await fetchApi(url, opts);
        if (response.statusCode === 401) {
            //Swal.close()
            return Swal.fire({
                title: 'Warning',
                text: "Session expired",
                icon: 'warning',
            }).then(res =>
                actions.doLogout()
            )
        }
        if (response.statusCode !== undefined && response.statusCode !== 200) {
            //console.log("test",response.statusCode)
            const newResponse = Object.keys(response).reduce((accumulator, key) => {
                accumulator[key.toLowerCase()] = response[key];
                return accumulator;
            }, {});
            response = {
                code: '02',
                message: newResponse.message
            }
        }
        return response
    }

    // async function doPost(  
    //     url,
    //     data,
    //     callback = ()=>null,
    //     type = 0,
    //     paramImage = ""
    // ){
    //     var auth = {
    //         'Authorization': 'Bearer ' + token
    //     }

    //     var opts = {
    //         body: type === 0 ? JSON.stringify(data) : data,
    //         method: "POST",
    //         'Authorization': 'Bearer ' + token
    //     }

    //     if (type == 1) {
    //         var keys = Object.keys(data)
    //         var form = new FormData()

    //         keys.forEach((val, key) => {
    //             if (val === "img" && val === "image" && val === paramImage) {
    //                 form.append(val, data[val], data[val].name);
    //             } else {
    //                 form.append(val, data[val]);
    //             }
    //             // console.log(form.values())
    //         })
    //         opts = {
    //             "body": form,
    //             "method": "POST",
    //             "Content-Type": "multipart/form-data",
    //             ...auth
    //         }
    //     }
    //     if (type == 2) {
    //         opts = {
    //             "body": JSON.stringify(data),
    //             "method": "POST",
    //             "Content-Type": "application/json",
    //             'Accept': 'application/json',
    //             ...auth
    //         }
    //     }

    //     const response  = await fetchApi(url, opts);
    //     if(response.statusCode === 401){
    //         Swal.close()
    //         return Swal.fire({
    //             title: 'Warning',
    //             text: "Session expired",
    //             icon: 'warning',
    //         }).then(res => actions.doLogout())
    //     }

    //     if(res)
    //     return Swal.fire({
    //         title: 'Warning',
    //         text: "Session expired",
    //         icon: 'warning',
    //     }).then(res => actions.doLogout())
    //     return callback();
    // }

    useEffect(() => {
        actions.resetForm();
        //console.log('expired time', getSession());
    }, [])

    return (
        <globalContext.Provider value={[state, dispatch, actions]}>
            {children}
        </globalContext.Provider>
    );
}

export const useGlobalState = () => useContext(globalContext);
export default GlobalProvider;