import React from 'react';
import { HeatmapRisk, HeatmapRiskTable } from '../../../components';
import useProjectProfile from './useProjectProfile';
// import {} from ''

const ChartProjectRisk = () => {


    const { pfState, pfAction } = useProjectProfile();
    return (
        <>
            <HeatmapRiskTable data={(pfState.data?.data_view_risk || []).map(({ inher_probabil, inher_dampak }) => ({ probabilitas: inher_probabil, dampak: inher_dampak }))} />
            {/* <HeatmapRisk id="risk-1" data={([
                {
                    "id": 4115,
                    "research_id": 667,
                    "baseline_name": 3,
                    "risk_id": 1,
                    "tipe_risk": "Kualitatif",
                    "kejadian": "Progress pelaksanaan riset tidak mencapai target yang ditetapkan.",
                    "penyebab": "Adanya kondisi major force (misal. pandemi global, bencana alam, dll) yang membuat pelaksanaan penelitian menjadi lambat (stagnant).",
                    "gejala": "1. Keterlambatan dalam memulai penelitian.\r\n2. Progress riset secara periodeik tidak sesuai dengan timeline yang telah ditetapkan.",
                    "faktor_positif": "Pembuatan jadwal persiapan dan memonitoring pekerjaan secara periodic",
                    "dampak_kual": "Riset tidak berjalan sesuai dengan target",
                    "inher_kuant": null,
                    "inher_probabil": 2,
                    "inher_dampak": 2,
                    "mit_rencana": "1. Penyusunan timeline proyek riset secara detail dengan memperhitungkan kemungkinan adanya force major 2. Penyusunan anggaran proyek riset dengan memperhitungkan kemungkinan adanya perubahan anggaran",
                    "mit_biaya": 0,
                    "mit_tahun_berlaku": 2021,
                    "mit_tahun_selesai": 2021,
                    "res_kuant": null,
                    "res_probabil": 2,
                    "res_dampak": 2
                },
                {
                    "id": 4116,
                    "research_id": 667,
                    "baseline_name": 3,
                    "risk_id": 2,
                    "tipe_risk": "Kualitatif",
                    "kejadian": "Progress pelaksanaan riset tidak mencapai target yang ditetapkan",
                    "penyebab": "Adanya kondisi major force (misal. pandemi global, bencana alam, dll) yang membuat pelaksanaan penelitian menjadi lambat (stagnant)",
                    "gejala": "Riset tidak berjalan sesuai rencana",
                    "faktor_positif": "Penyusunan timeline proyek riset secara detail",
                    "dampak_kual": "Riset tidak berjalan sesuai rencana",
                    "inher_kuant": null,
                    "inher_probabil": 2,
                    "inher_dampak": 2,
                    "mit_rencana": "Penyusunan timeline proyek riset secara detail dengan memperhitungkan kemungkinan adanya force major ",
                    "mit_biaya": 0,
                    "mit_tahun_berlaku": 2023,
                    "mit_tahun_selesai": 2023,
                    "res_kuant": null,
                    "res_probabil": 1,
                    "res_dampak": 1
                }
            ] || []).map(({ inher_probabil, inher_dampak }) => ({ probabilitas: inher_probabil, dampak: inher_dampak }))} /> */}
        </>
    );
}

export default ChartProjectRisk;