import React, { useRef, useEffect } from 'react';
import { FormResearch, Modal, RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Accordion } from 'devextreme-react';
import { useHistory } from 'react-router';
import Tags from "@yaireo/tagify/dist/react.tagify"
import NotOperational from './component/notOperational';
import Operational from './component/operational';

// const formatter = new Intl.NumberFormat('en-US', {
//     valuta: 'USD',
//     minimumFractionDigits: 2
// })

const TabDetail = ({ param }) => {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const tagref = useRef();

    useEffect(() => {
        if (param.id === null) {
            history.push("/error-page")
        }
        getData();
    }, [])

    function getData() {
        action.getData("/api/project-portfolio-crud/view-detail-2/" + param?.id, {}, null, "isLoading", true).then((resp) => {
            if (resp.code === "00") {
                const data_area = resp.data.data_area.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                const data_dukungan_operasional = resp.data.data_dukungan_operasional.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_expertise_group = resp.data.data_expertise_group.map(d => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                const document = resp.data.data_view_documents.map((d, i) => {
                    return {
                        "file_location": d.file_location,
                        "tipe_document": d.file_location ? "file" : "url",
                        "is_new": false,
                        "file_name": d.name,
                        "link": d.link
                    }
                })
                const data_sat_pendapatan = resp.data.data_sat_pendapatan.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_sat_produksi = resp.data.data_sat_produksi.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_sat_efisiensi = resp.data.data_sat_efisiensi.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_potensial_partner = resp.data.data_potensial_partner.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_related_research = resp.data.data_related_research.map((d, i) => {
                    return {
                        title: d.title,
                        related_id: d.related_id
                    }
                })

                const data_duk = [];
                if (resp.data.data_view_detail?.duk_operasional_opt_0) {
                    const t = data_dukungan_operasional.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_0.toString())
                    if (t) {
                        data_duk.push({
                            name: t?.name,
                            value: t?.value
                        })
                    }
                }
                if (resp.data.data_view_detail?.duk_operasional_opt_1) {
                    const t = data_dukungan_operasional.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_1.toString())
                    if (t) {
                        data_duk.push({
                            name: t?.name,
                            value: t?.value
                        })
                    }
                }
                if (resp.data.data_view_detail?.duk_operasional_opt_2) {
                    const t = data_dukungan_operasional.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_2.toString())
                    if (t) {
                        data_duk.push({
                            name: t?.name,
                            value: t?.value
                        })
                    }
                }
                const version = [];
                for (let index = 0; index < (parseFloat(resp.data.data_view_header?.baseline_name) || 0); index++) {
                    version.push({
                        name: index + 1,
                        value: index + 1
                    })
                }
                dispatch({
                    type: "getSuccess",
                    payload: { data_version: version, loadingGet: false }
                })
                const t = [...(resp.data.data_view_keywords || [])].map(d => { return { value: d.name, is_new: false } })
                //Data Option
                action.setForm('data_area', data_area);
                action.setForm('data_dukungan_operasional', data_dukungan_operasional);
                action.setForm('data_expertise_group', data_expertise_group);
                action.setForm('data_sat_pendapatan', data_sat_pendapatan);
                action.setForm('data_sat_produksi', data_sat_produksi);
                action.setForm('data_sat_efisiensi', data_sat_efisiensi);
                action.setForm('data_potensial_partner', data_potensial_partner);
                action.setForm('data_related_research', data_related_research);
                //Data Value Default
                action.setForm('realated_document', resp.data.data_view_related_research);
                action.setForm('defkeyword', t)
                action.setForm('keyword', t)
                action.setForm("dukungan_operational", data_duk);
                action.setForm("sumberFileTemp", [...document])
                action.setForm("sumberFile", [...document])
                action.setForm('stage', resp.data.data_view_detail?.stage || "VII");
                action.setForm('duk_operasional_ket', resp.data.data_view_detail?.duk_operasional_ket)
                action.setForm('potensial_partner_opt', resp.data.data_view_detail?.potensial_partner_opt)
                action.setForm('method', resp.data.data_view_detail?.method)
                action.setForm('potensial_partner_ket', resp.data.data_view_detail?.potensial_partner_ket)
                action.setForm('pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.pendapatan)
                action.setForm('efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.efisiensi)
                action.setForm('produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.produksi)
                action.setForm('sat_pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_pendapatan)
                action.setForm('sat_efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_efisiensi)
                action.setForm('sat_produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_produksi)
                action.setForm('ket_pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_pendapatan)
                action.setForm('ket_efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_efisiensi)
                action.setForm('ket_produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_produksi)
                action.setForm('tangible_angka', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.tangible_angka);
                action.setForm('tangible_keterangan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.tangible_keterangan);
                action.setForm('intangible', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.intangible)
                action.setForm('potential_user', resp.data.data_view_detail?.potential_user)
                action.setForm('jangka_waktu', resp.data.data_view_detail?.jangka_waktu)
                action.setForm('research_category_id', resp.data.data_view_detail?.research_category_id)
                action.setForm('description', resp.data.data_view_detail?.description)
                action.setForm('title', resp.data.data_view_detail?.title)
                action.setForm('purpose', resp.data.data_view_detail?.purpose)
                action.setForm('area_id', resp.data.data_view_detail?.area_id)
                action.setForm('focus', resp.data.data_view_detail?.focus)
                getLeader(resp.data.data_view_team_members)
                getMember(resp.data.data_view_team_members)
                setKeahlian(resp.data.data_view_expertise_needed)

                action.setForm('portfolio_data_page', resp.data_page);
            }
        })
    }
    function setKeahlian(data) {
        const newds = [...(data || [])].map(d => {
            return {
                value: d?.expertise_id,
                is_new: false,
                name: d?.technical_competency
            }
        })
        action.setForm("keahlian", newds)
    }
    function getLeader(data) {
        if (data) {
            const disctinctLeaderName = Array.from([... new Set((data.filter(obj => obj.team_role === "Leader")).map(s => s))]).map(d => {
                if (d.user_id !== null) {
                    return {
                        user_id: d.user_id,
                        employee_id: d.employee_id,
                        name: d.name,
                        section_name: d.section_name,
                        position_name: d.position_name,
                        function_short_name: d.function_short_name,
                        technical_competency: d.technical_competency
                    }
                }
            })
            action.setForm("leader", disctinctLeaderName?.[0])
        }
    }
    function getMember(data) {
        if (data) {
            const disctinctMamberName = Array.from([... new Set((data.filter(obj => obj.team_role === "Member")).map(s => s))]).map(d => {
                return {
                    user_id: d.user_id,
                    employee_id: d.employee_id,
                    name: d.name,
                    is_new: false,
                    section_name: d.section_name,
                    function_short_name: d.function_short_name,
                    position_name: d.position_name,
                    technical_competency: d.technical_competency
                }

            })
            action.setForm("anggota", disctinctMamberName)
        }
    }
    function RenderDetail(project_type) {
        if ((project_type === 1 ||
            project_type === 2 ||
            project_type === 3 ||
            project_type === 4 ||
            project_type === 6)) {
            return <NotOperational />
        }
        if (project_type === 5) {
            return <Operational />
        }
    }
    return (
        <>
            <Modal
                name="viewTableTrl"
                baseColor={false}

            >
                <div className="customShadow">
                    <img className="w-100" src='/images/trl.jpg'></img>
                </div>
            </Modal>
            {
                state.data_view_header?.project_type && RenderDetail(state.data_view_header?.project_type)
            }
            {/* <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: true,
                        name: <>Initiator Ide <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Nama",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_name}</div>)
                            },
                            {
                                label: "Jabatan",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_position_name}</div>)
                            },
                            {
                                label: "Fungsi",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.initiator_function_name}</div>)
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Latar Belakang <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "array",
                                label: "Judul",
                                forms: [
                                    {
                                        type: "custom",
                                        component: (<div className='form-group style-view-disable'>{state.formData.title}</div>)
                                    },
                                ]
                            },
                            {
                                label: "Pain Point",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.formData.description}</div>)
                            },
                            {
                                label: "Commercialization /Implementation- Objective",
                                component: (<div className='form-group style-view-disable'>{state.formData.purpose}</div>),
                                type: "custom",
                            },
                            {
                                label: <> Dukungan Operational</>,
                                type: "custom",
                                component: (
                                    <>
                                        <div className="row mb-5">
                                            <div className="col-md-12">
                                                <ul>
                                                    {
                                                        state.formData.dukungan_operational?.map((duk, key) => (
                                                            <li key={key}>{duk?.name}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )

                            },
                            {
                                label: "Detail Dukungan dan Operational",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.formData.duk_operasional_ket}</div>),
                            },
                            {
                                label: "Pemakai Potensial",
                                type: "custom",
                                component: (<div className='form-group style-view-disable'>{state.formData.potential_user}</div>),
                            },
                            {
                                label: <> Keyword <i title="Kata kunci terkait riset yang dijalankan, dipisah dengan tanda koma (,)" className="fa fa-exclamation-circle"></i></>,
                                type: "custom",
                                component: (
                                    <Tags
                                        readOnly={state.formData.disable}
                                        tagifyRef={tagref}
                                        value={state.formData.keyword}
                                    />
                                )
                            },
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Susunan tim <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: 'bold' }}>Leader</span>
                                        <div className='rounded border p-5'>
                                            <div className="row">
                                                {
                                                    state.formData.leader && (
                                                        <>
                                                            <div className="col-md-4 mb-5">
                                                                <img src={state.formData.leader?.image || "/media/svg/icons/General/User.svg"}
                                                                    style={{ width: '100%' }}
                                                                ></img>
                                                            </div>
                                                            <div className="col-md-8 mb-5">
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Nama</div>
                                                                    <div className="col-auto">: {state.formData.leader.name}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Posisi</div>
                                                                    <div className="col-auto">: {state.formData.leader.position_name}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">No. Pegawai</div>
                                                                    <div className="col-auto">: {state.formData.leader.employee_id}</div>
                                                                </div>
                                                                <div className="row" style={{ paddingBottom: 10 }}>
                                                                    <div className="col-3">Expertise</div>
                                                                    <div className="col-auto"></div>
                                                                </div>
                                                                {
                                                                    state.formData.leader.technical_competency?.split(',').map((ex, i) => (
                                                                        <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                                                            <div className="col-3"></div>
                                                                            <div className="col-auto">{i + 1}.&nbsp;{ex}</div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: "bold" }}>Anggota</span>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    state.formData.anggota?.length > 0 ? (
                                                        <Accordion
                                                            className="accordion-cstm py-5"
                                                            multiple={true}
                                                            itemRender={renderItem}
                                                            dataSource={[...state.formData.anggota]} collapsible={true}
                                                            itemTitleRender={renderTitle}
                                                        >
                                                        </Accordion>
                                                    ) : (
                                                        <div className="mb-5"></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: "Fungsi",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="row mb-5">
                                        <div className="col-md-12">
                                            <ul>
                                                {
                                                    getFungsi()?.map((d, i) => (
                                                        <li key={i}>{d.section_name} ({d.function_short_name})</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Keahlian Peneliti yang Dibutuhkan <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <ul style={{ paddingBottom: '25px' }}>
                                        {
                                            state.formData.keahlian?.map((kh, key) => (
                                                <li key={key}>{kh.name}</li>
                                            ))
                                        }
                                    </ul>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Klasifikasi Research <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Area Research",
                                component: (
                                    <div className='form-group style-view-disable'>{state.data_view_detail?.area_name}</div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Kategory Research",
                                component: (<div className='form-group style-view-disable'>{state.data_view_detail?.research_category_name}</div>)
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Ruang Lingkup Riset <span className="text-danger"></span></>,
                        childs: [
                            {
                                type: "array",
                                withLabel: false,
                                forms: [
                                    {
                                        type: "custom",
                                        component: (
                                            <>{
                                                state.formData.focus ?
                                                    <div className='form-group style-view-disable'>{state.formData.focus}</div>
                                                    :
                                                    <div className='text-muted text-center mb-5'>tidak ada data</div>
                                            }
                                            </>
                                        ),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: "Dokumen Pendukung ",
                        childs: [
                            {
                                type: "custom",
                                label: "Sumber File",
                                disabled: false,
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        [...(state.formData.sumberFileTemp || [])].length < 1 && <div className='text-muted'>tidak ada data</div>
                                                    }
                                                    {
                                                        [...(state.formData.sumberFileTemp || [])]?.map((file, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                {
                                                                    file.tipe_document === "file" ? (
                                                                        <>
                                                                            <span style={{ fontSize: '8pt', width: '250px' }}>{file.file_name.split('/')?.[6] || file.file_name}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span>{file.link}</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: "Proyek berkaitan",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        [...(state.formData.realated_document || [])].length < 1 && <div className='text-muted text-center'>tidak ada data</div>
                                                    }
                                                    {
                                                        [...(state.formData.realated_document || [])]?.map((d, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                <span className="pl-2 text-wrap" style={{ fontSize: '8pt' }}>{d.title}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: <>Metode Riset <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="d-inline-flex">
                                        <RadioGrp
                                            disable={true}
                                            value={state.formData.method?.toString()}
                                            data={[
                                                {
                                                    label: (
                                                        <span>
                                                            Inhouse
                                                            <i title="Dikerjakan dengan SDM dan Anggaran Pertamina" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '1'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Procurement
                                                            <i title="Dikerjakan oleh pihak ketiga dengan Anggaran Pertamina" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '2'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Partnership
                                                            <i title="SDM dan anggaran berasal dari Pertamina dan pihak ketiga" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '3'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Swakelola
                                                            <i title="Kerjasama yang dilaksanakan melalui metode swakelola" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '4'
                                                }
                                            ]}
                                        />
                                    </div>
                                )
                            }
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        childs: [
                            ...(state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ?
                                [{
                                    type: "custom",
                                    label: "Potensial Partner",
                                    component: (<div className='form-group style-view-disable'>{state.data_view_detail?.potensial_partner_name}</div>),
                                },
                                {
                                    type: "custom",
                                    label: "Detail Partner",
                                    component: (<div className='form-group style-view-disable'>{state.data_view_detail?.potensial_partner_ket}</div>),
                                }] : []
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: <span>
                            Value Creation
                            <i title="Nilai yang dapat dihasilkan setelah riset dilaksanakan, dapat berupa pendapatan, efisiensi, produksi minyak, atau intangible asset" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                            <span className="text-danger">(*)</span>
                        </span>,
                        childs: [
                            {
                                type: "custom",
                                label: "Pendapatan (Revenus)",
                                withLabel: false,
                                component: (
                                    <div className="border bg-light-secondary rounded p-3 mb-5">
                                        {
                                            (state.formData.pendapatan || state.formData.sat_pendapatan_name || state.formData.ket_pendapatan) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Pendapatan (Revenus)
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.pendapatan} {state.formData.sat_pendapatan_name} {state.formData.ket_pendapatan}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (state.formData.efisiensi || state.formData.sat_efisiensi_name || state.formData.ket_efisiensi) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Efisiensi
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.efisiensi} {state.formData.sat_efisiensi_name} {state.formData.ket_efisiensi}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (state.formData.produksi || state.formData.sat_produksi_name || state.formData.ket_produksi) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <span>
                                                            Production
                                                            <i title="Penambahan Produksi Migas, Cadangan Migas, Penjualan, Kapasitas listrik" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.produksi} {state.formData.sat_produksi_name} {state.formData.ket_produksi}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Tangible value
                                </span>,
                                component: (<div className='form-group style-view-disable'>{formatter.format(state.formData.tangible_angka || 0)}</div>),
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Justifikasi Perhitungan Tangible Value
                                </span>,
                                component: (<div className='form-group style-view-disable'>{state.formData.tangible_keterangan}</div>),                            
                            }
                            , {
                                type: "custom",
                                label: "Intangible Value",
                                component: (<div className='form-group style-view-disable'>{state.formData.intangible}</div>),
                            }
                        ]
                    },
                    ...(state.data_view_header?.project_type?.toString() === '1' ? [
                        {
                            rows: 2,
                            header: true,
                            name: <>Tingkat Kesiapan Riset <span className="text-danger">(*)</span><span role={'button'} className="badge badge-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('viewTableTrl')}>Lihat Table TRL</span></>,
                            childs: [
                                {
                                    type: "custom",
                                    withLabel: false,
                                    component: (
                                        <div className="d-inline-flex">
                                            <RadioGrp
                                                disable={true}
                                                value={state.formData.stage}
                                                data={[
                                                    { label: 'TRL I', value: 'I' },
                                                    { label: 'TRL II', value: 'II' },
                                                    { label: 'TRL III', value: 'III' },
                                                    { label: 'TRL IV', value: 'IV' },
                                                    { label: 'TRL V', value: 'V' },
                                                    { label: 'TRL VI', value: 'VI' },
                                                    { label: 'TRL VII', value: 'VII' },
                                                    { label: 'TRL VIII', value: 'VIII' },
                                                    { label: 'TRL IX', value: 'IX' }
                                                ]}
                                            />
                                        </div>
                                    )
                                }
                            ]
                        }
                    ] : []),
                    ...((state.data_view_header?.project_type?.toString() === '2' || state.data_view_header?.project_type?.toString() === '4') ? [
                        {
                            rows: 2,
                            header: true,
                            name: <>Tingkat Kesiapan Riset <span className="text-danger">(*)</span> <button className="btn mb-1 btn-sm btn-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('viewTableTrl')}>Lihat Table TRL</button></>,
                            childs: [
                                {
                                    type: "custom",
                                    withLabel: false,
                                    component: (
                                        <div className="d-inline-flex">
                                            <RadioGrp
                                                disable={true}
                                                value={state.formData.stage}
                                                data={[
                                                    { label: 'TRL I', value: 'I', disabled: true },
                                                    { label: 'TRL II', value: 'II', disabled: true },
                                                    { label: 'TRL III', value: 'III', disabled: true },
                                                    { label: 'TRL IV', value: 'IV', disabled: true },
                                                    { label: 'TRL V', value: 'V', disabled: true },
                                                    { label: 'TRL VI', value: 'VI', disabled: true },
                                                    { label: 'TRL VII', value: 'VII' },
                                                    { label: 'TRL VIII', value: 'VIII' },
                                                    { label: 'TRL IX', value: 'IX' }
                                                ]}
                                            />
                                        </div>
                                    )
                                }
                            ]
                        }
                    ] : [])
                ]}
            /> */}
            {/* <div className="row mt-5">
                <div className="col-6">
                    <span className="d-block text-danger">(*) Mandatori tidak boleh kosong</span>
                </div>
                <div className="col-6 text-right">
                </div>
            </div> */}
        </>
    )
}
export default TabDetail;