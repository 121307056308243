import { UserManager, WebStorageStateStore, Log } from "oidc-client";

const OidcSettings = {
    authority: "https://login.idaman.pertamina.com",
    client_id: "bb74252e-3da0-4de5-afbf-b34998cabdac",
    redirect_uri: "https://spiirit.net/signin-oidc",
    response_type: "code id_token",
    scope: "openid profile api.auth email user.role user.read offline_access",
    post_logout_redirect_uri: "https://spiirit.net/signin-oidc",
    signingKeys: [
        {
            "kty": "RSA",
            "use": "sig",
            "kid": "3C39DEE7A747B65347468E474C12A22F6DF7D7DD",
            "x5t": "PDne56dHtlNHRo5HTBKiL233190",
            "e": "AQAB",
            "n": "mfzxvWkB42j_vW4dqWPPRKkmF2csWmvyjvJieMAGP_oVS2VKo5Q33M_tY3CBaX-ZSS4qFqvygFCjHWtvWy8GISC-C4TmjEkZkOlgW_G7gNzq_U5j9_zy0dACa2uYMT3F2CSyYLi7Lcm5TuKnM0G7aXRDNOIGQqCP2Xtf5Lgr8gYn6oRLjnqPQ6FcD_1SdsaizSaCdY_OuFakusqZjTdoZNa5QvX8aUNMP8960O34_FNBIUW5vWRyydk6Ij_UoiajrCDf5_xRI6XRInEhDrKcmjUfJkGvahq3hHkrKVQWWX94EdeBvF6g5FCYxU5WXChMonm2-R_flWnmEnOIcXNc_w",
            "x5c": [
                "MIIDVjCCAj6gAwIBAgIBATANBgkqhkiG9w0BAQUFADA8MRwwGgYDVQQLDBNQZXJ0YW1pbmEgSWRBTWFuIENBMRwwGgYDVQQDDBNQZXJ0YW1pbmEgSWRBTWFuIENBMB4XDTIwMDgwNjAzMTEwM1oXDTMwMDgwNDAzMTEwM1owPDEcMBoGA1UECwwTUGVydGFtaW5hIElkQU1hbiBDQTEcMBoGA1UEAwwTUGVydGFtaW5hIElkQU1hbiBDQTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJn88b1pAeNo/71uHaljz0SpJhdnLFpr8o7yYnjABj/6FUtlSqOUN9zP7WNwgWl/mUkuKhar8oBQox1rb1svBiEgvguE5oxJGZDpYFvxu4Dc6v1OY/f88tHQAmtrmDE9xdgksmC4uy3JuU7ipzNBu2l0QzTiBkKgj9l7X+S4K/IGJ+qES456j0OhXA/9UnbGos0mgnWPzrhWpLrKmY03aGTWuUL1/GlDTD/PetDt+PxTQSFFub1kcsnZOiI/1KImo6wg3+f8USOl0SJxIQ6ynJo1HyZBr2oat4R5KylUFll/eBHXgbxeoORQmMVOVlwoTKJ5tvkf35Vp5hJziHFzXP8CAwEAAaNjMGEwDgYDVR0PAQH/BAQDAgEGMA8GA1UdEwEB/wQFMAMBAf8wHQYDVR0OBBYEFMoBLDBdIzHE0u953thD07GuzYftMB8GA1UdIwQYMBaAFMoBLDBdIzHE0u953thD07GuzYftMA0GCSqGSIb3DQEBBQUAA4IBAQB3z2pphl4jB1opipqqkTNMRYeE9gj7jOmw0EWG5rOZuJdDaVNFxzWc5tk94zx8EoC78rIBM6gX/3RKHG/y+M9PlHnIC68VSdz9Xt35IhcUekA6LwWIy1X7ZeWFE34G9+n9W1wBpfikB44ZpfbdNRVTALDQuCPClnfyIpDBFBL/pQxXd0GhvezwMNGmQtxp6RBGGewh5E+D6qXIg064IoO15GDDJ9LkbdQXmjCEh+8uqfSEw7ZBvONTHxVEQhbFKBZdWg7u8bm51otq230NN+hkmSqPGre5Irpz26q6zF0xwnB1paBDv3emhnsiw1ZBBnrr7zj0Z7OXGG8KPN4NaKTr"
            ],
            "alg": "RS256"
        }
    ]
};
const METADATA_OIDC = {
    "issuer": "https://login.idaman.pertamina.com",
    "jwks_uri": "https://login.idaman.pertamina.com/.well-known/openid-configuration/jwks",
    "authorization_endpoint": "https://login.idaman.pertamina.com/connect/authorize",
    "token_endpoint": "https://login.idaman.pertamina.com/connect/token",
    "userinfo_endpoint": "https://login.idaman.pertamina.com/connect/userinfo",
    "end_session_endpoint": "https://login.idaman.pertamina.com/connect/endsession",
    "check_session_iframe": "https://login.idaman.pertamina.com/connect/checksession",
    "revocation_endpoint": "https://login.idaman.pertamina.com/connect/revocation",
    "introspection_endpoint": "https://login.idaman.pertamina.com/connect/introspect",
};

export default class AuthenticationIdAManService {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...OidcSettings,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata:{
                ...METADATA_OIDC
            }
        });
        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
            "";
        });
    };


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };


    navigateToScreen = () => {
        window.location.replace("/home");
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`))
        return (!!oidcStorage && !!oidcStorage.id_token)
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace(process.env.REACT_APP_PUBLIC_URL);
        });
        this.UserManager.clearStaleState();
    };
}