const reducers = (state = {}, action = {}) => {
    const { payload } = action;
    switch (action.type) {
        case "setLogin":
            return {
                ...state,
                isAuthenticated: true
            }
        case "setLogout":
            return {
                ...state,
                isAuthenticated: false
            }
        case "setState":
            return {
                ...state,
                ...payload
            }
        case "setFilter":
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    [payload.key]: payload.value
                }
            }
        case "setForm":
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [payload.key]: payload.value
                }
            }
        case "loadingLogin":
            return {
                ...state,
                loadingLogin: true
            }
        case "loginFailed":
            return {
                ...state,
                loadingLogin: false
            }
        case "loginSuccess":
            return {
                ...state,
                loadingLogin: false,
                isAuthenticated:true,
                userData: payload
            }
        case "getSuccess":
            return {
                ...state,
                ...payload
            }
        case "setLoading":
            return {
                ...state,
                loading: payload.loading
            }
        case "setShimmer":
            return {
                ...state,
                shimmer: payload.shimmer
            }
        case "setModal":
            return {
                ...state,
                modal: {
                    ...state.modal,
                    [payload.modalName]: payload.opened
                }
            }
        case "setData":
            return {
                ...state,
                data: payload
            }
        case "loginSuccess":
            return {
                ...state,
                users: payload
            }
        case "setFormDefault":
            return {
                ...state,
                formData: payload
            }
        case "setFilterDefault":
            return {
                ...state,
                filterData: payload
            }
        case "doGet":
            return {
                ...state, 
                ...payload
            }
        default:
            return state
    }
}

export default reducers;