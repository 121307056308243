import React, { useEffect, useRef, useState } from 'react';
import { HeaderList, GridDx } from '../../components';
import { Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
// import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js'

const columns = [
    { fieldName: "name", caption: 'Judul', width: 200 },
    { fieldName: 'description', caption: 'Deskripsi', minWidth: 300 },
    { fieldName: 'status_event', caption: "Status", width: 100 },
    { fieldName: 'date_start', caption: 'Waktu Mulai', width: 100 },
    { fieldName: 'date_end', caption: "Waktu Selesai", width: 100 },
    { fieldName: 'venue', caption: 'Tempat.', width: 200 },
    { fieldName: "organizer", caption: 'Organizer', width: 100 },
]
function TableCRUD(props) {
    const history = useHistory();
    const [state, , action] = useGlobalState();
    const tableRef = useRef();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData('/api/people-event/table', {}, null, null, true).then((resp) => {
            if (resp.code === "00") {
                setData_page(resp.data_page);
            }
        });
        action.getData('/api/people-event/header', {}, null, null, true);
    }, [])

    const customCellrender = (e) => {
        if (e.column.name === "name") {
            return (
                <Link to={`/admin/people/event-detail/${e.data.id}`}
                >
                    <span className="text-wrap">
                        {
                            e.value
                        }
                    </span>
                </Link>
            )
        } else if (e.column.name === "date_start" || e.column.name === "date_end") {
            var time = '';
            if (e.column.name === 'date_start') {
                time = e.data.time_start;
            }
            if (e.column.name === 'date_end') {
                time = e.data.time_end;
            }
            const dateString = e.value ? moment(e.value).format("YYYY-MM-DD").toString() : '-'
            return (
                <>
                    <span className="text-wrap">
                        {
                            dateString
                        }
                        &nbsp;
                        {
                            time
                        }
                    </span>
                </>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function deleteOnclick(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Event ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-event/delete-event?id=" + id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div>
            <div className="pb-5">
                <HeaderList
                    title={data_page?.page_title}
                    someText={data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-4 col-md-4 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <i style={{ width: '40px', height: '40px' }} className="far fa-calendar-check bg-white text-dark rounded customShadow d-flex align-items-center justify-content-center"></i>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Event.</span>
                                    <span className="d-block"><strong>{state.jumlahTotalEvent}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Upcoming</span>
                                    <span className="d-block"><strong>{state.jumlahUpcoming}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Previous</span>
                                    <span className="d-block"><strong>{state.jumlahPrevious}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        ref={tableRef}
                        toolbar={
                            <button className="btn btn-sm btn-primary mb-3" onClick={() => history.push('/admin/people/event/add')}>
                                <i className="fa fa-plus icon-nm"></i> Event
                            </button>
                        }
                        cellRender={customCellrender}
                        searchForm={true}
                        withAction={true}
                        data={state.data_event_list}
                        columns={columns}
                        actions={[
                            {
                                name: "View",
                                icon: "fas fa-eye icon-nm text-primary",
                                action: (v, c) => {
                                    history.push(`/admin/people/event-detail/${v.id}`)
                                }
                            },
                            {
                                name: "Edit",
                                icon: "fa fa-pencil-alt icon-nm text-success",
                                action: (v, c) => {
                                    history.push(`/admin/people/event/edit/${v.id}`)
                                }
                            }, {
                                name: "Delete",
                                icon: "fa fa-trash-alt icon-nm text-danger",
                                action: (v, c) => { deleteOnclick(v.id) }
                            }
                        ]}
                    />
                </Card.Body>
            </Card>
        </div>
    );
}

export default TableCRUD;