import React, { memo, useEffect } from 'react'
import { Tab, TabContent, HeaderList } from '../../components'
import TabDashboard from '../nonAdmin/portfolio/tabDashboard'
import TabTable from '../nonAdmin/portfolio/tabTable'
import usePortfolio, { PortProvider } from '../nonAdmin/portfolio/usePortfolio'
import TableBudget from './tableBudget'

function Portfolio() {
    const { portAction, portState } = usePortfolio();

    return (
        <>
            <HeaderList
                title={portState.data_page?.page_title}
                someText={portState.data_page?.page_breadcrumbs}
                optionList={[{
                    withLabel: true,
                    label: "Usulan project pada tahun : ",
                    option: [...portState.filterTahun],
                    value: portState.tahun,
                    onChange: function (e) { portAction.setTahun(e) }
                }]}
            />
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        title: "Portfolio Dashboard"
                    },
                    {
                        id: "table",
                        title: "Table Detail"
                    },
                    {
                        id: "tableBudget",
                        title: "Table Budget"
                    }
                ]}
            >
                <TabContent id="dashboard">
                    <TabDashboard reporting={true} />
                </TabContent>
                <TabContent id="table">
                    <TabTable reporting={true} />
                </TabContent>
                <TabContent id="tableBudget">
                    <TableBudget reporting={true} />
                </TabContent>
            </Tab>
        </>
    )
}
export default () => <PortProvider><Portfolio /></PortProvider>