import React from 'react';
import { HeaderList, Tab, TabContent } from '../../components';
import TabAbout from './tabAbout/tabAbout';
import TabFaq from './tabFaq/tabFaq';
// import TabWhatsapp from './tabWhatsapp/tabWhatsapp';
import useSettingApplication, { SettingAppProvider } from './useSettingAplication';

function Index(props) {
    const { setAppState } = useSettingApplication();
    return (
        <div>
            <HeaderList
                title={setAppState.data_page?.page_title}
                someText={setAppState.data_page?.page_breadcrumbs}
            />
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "faq",
                        title: "Master FAQ",
                    },
                    {
                        id: "about",
                        title: "Master About",
                    },
                    // {
                    //     id: "whatsapp",
                    //     title: "Master Whatsapp",
                    // },
                ]}
            >
                <TabContent id="faq">
                    <TabFaq />
                </TabContent>
                <TabContent id="about">
                    <TabAbout />
                </TabContent>
                {/* <TabContent id="whatsapp">
                    <TabWhatsapp/>
                </TabContent> */}
            </Tab>
        </div>
    );
}

export default () => <SettingAppProvider><Index /></SettingAppProvider>;