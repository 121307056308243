import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../components';
import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import { Link, useHistory } from 'react-router-dom';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';

const column = [
    { fieldName: "name", caption: "Nama Product", minWidth: 300 },
    { fieldName: "status_approval_name", caption: "Status Approval", widts: 100 },
    { fieldName: "price", caption: "Akumulasi Product Contribution (Ribu USD)", width: 200 },
    { fieldName: "inventor_name", caption: "Inventor", width: 300 },
    { fieldName: "kontak_name", caption: "Kontak", width: 200 },
    { fieldName: "product_types_name", caption: "Tipe Product", width: 100 },
    { fieldName: "product_status_name", caption: "Status Product", width: 100 },
    { fieldName: "release_year", caption: "Tahun Rilis", width: 100 },
]

function ApprovalProduct(props) {
    const [state, dispatch, action] = useGlobalState();
    const tableRef = useRef();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        getData();
    }, []);
    function getData() {
        action.getData('/api/ext-product/table-approval', {
        }, null, 'loadingGet', true).then((resp) => {
            if (resp.code === '00') {
                setData(resp.data.data_tabel);
                setData_page(resp.data_page);
            }
        })
    }
    function cstmCellRender(e) {
        if (e.column.name === "name") {
            return (
                <Link to={`/admin/product-approval/detail/${e.data?.id}`}
                >
                    <span className="text-wrap">{e.value}</span>
                </Link>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Product ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/km-product/delete?id=${id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded customShadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Clothes/Briefcase.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Product</span>
                                    <span className="d-block"><strong>{state.HeaderTotal || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Upstream Tech</span>
                                    <span className="d-block"><strong>{state.HeaderUpstream || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Material and Speciality Chemical</span>
                                    <span className="d-block"><strong>{state.HeaderMaterial || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Petroleum Fuel</span>
                                    <span className="d-block"><strong>{state.HeaderPetroleum || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Petrochemical and non-fuel</span>
                                    <span className="d-block"><strong>{state.HeaderPetrochemical | 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NRE Fuel</span>
                                    <span className="d-block"><strong>{state.HeaderNREF || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NRE Power</span>
                                    <span className="d-block"><strong>{state.HeaderNREP || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Laboratory Service</span>
                                    <span className="d-block"><strong>{state.HeaderLab || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Others</span>
                                    <span className="d-block"><strong>{state.HeaderOther || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12 ">
                            <GridDx
                                searchForm={true}
                                ref={tableRef}
                                loading={state.loadingGet}
                                data={data}
                                cellRender={cstmCellRender}
                                columns={column}
                                withAction={true}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                                actions={[
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => { deleteData(v?.id) }
                                    },
                                    {
                                        name: "Edit",
                                        icon: "fa fa-pencil-alt icon-nm text-secondary",
                                        action: (v, c) => { history.push(`/admin/product/edit/${v?.id}`) }
                                    }
                                ]}
                            >
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default ApprovalProduct;