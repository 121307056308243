import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';

function Index(props) {
    const [state, dispatch, action] = useGlobalState()
    return (
        <div className="d-block text-center p-5">
            <h5>Session anda sudah kadaluarsa. Silahkan login ulang!!</h5>
            <button className="btn btn-sm btn-primary" onClick={() => action.doLogout()}>Logout</button>
            <img className="bgcustom" src='/media/error/bg1.jpg'></img>
        </div>
    );
}

export default Index;