import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../../../modules/useFetch";
import Swal from "sweetalert2";
import ModalTestReqApproval from "./component/modalTestReqApproval";
import ModalView from "./component/modalView";
import ModalPersetujuan from "./component/modalPersetujuan";
import ModalSampleProcessing from "./component/modalSampleProcessing";
import ModalUpdatetestingReport from "./component/modalUpdatetestingReport";

const contexts = createContext({
    cpState: {},
    cpAction: {}
});

export function CrudPengujianProvider({ children }) {
    const history = useHistory();
    const [tabActive, setTabActive] = useState(history.location.state?.tabActive || "tr");
    const { doGet, doPost } = useFetch();
    const [modalReq, setModalReq] = useState({ show: false, default_data: {} });
    const [modalView, setModalView] = useState({ show: false, default_data: {} });
    const [modalSd, setModalSd] = useState({ show: false, default_data: {} });
    const [modalSp, setModalSp] = useState({ show: false, default_data: {} });
    const [modalTre, setModalTre] = useState({ show: false, default_data: {} });


    const [dataReq, setDataReq] = useState([]);
    async function getReq() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-testing-request-list',
            });
            if (resp?.code === '00') {
                setDataReq(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [dataSd, setDataSd] = useState([]);
    async function getSd() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-sample-delivery-list',
            });
            if (resp?.code === '00') {
                setDataSd(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [dataSp, setDataSp] = useState([]);
    async function getSp() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-testing-process-list',
            });
            if (resp?.code === '00') {
                setDataSp(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [dataTre, setdataTre] = useState([]);
    async function getTre() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-testing-report-list',
            });
            if (resp?.code === '00') {
                setdataTre(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [dataDetail, setDataDetail] = useState({});
    async function getDetail(id_lab_test) {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-detail',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDataDetail(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getDetailSd(id_lab_test) {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-detail',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDataDetail(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getDetailSp(id_lab_test) {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-detail',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDataDetail(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getDetailTre(id_lab_test) {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-detail',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDataDetail(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [detailAp, setDetailAp] = useState({})
    async function getDetailAp(id_lab_test) {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-pengujian-testing-request-detail-approval',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDetailAp(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const rejectKlik = (formdata = {}) => {
        if (formdata?.note === "") {
            return Swal.fire({
                title: 'Error',
                text: "Silahkan isi keterangan",
                icon: 'warning',
            })
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Reject Pengajuan Pengujian ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiReq("api/ext-lab/admin-add-pengujian-testing-request-reject", formdata);
            }
        });
    }
    const needRevKlik = (formdata = {}) => {
        if (formdata?.note === "") {
            return Swal.fire({
                title: 'Error',
                text: "Silahkan isi keterangan",
                icon: 'warning',
            })
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Need Revision?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiReq("api/ext-lab/admin-add-pengujian-testing-request-need-revision", formdata);
            }
        });
    }
    const approveKlik = (formdata = {}) => {
        if (formdata?.no_lab_test === "" || formdata?.no_lab_test === null || formdata?.no_lab_test === undefined) {
            return Swal.fire({
                title: 'Error',
                text: "No Pengujian Tidak Boleh Kosong",
                icon: 'warning',
            })
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Approve Pengajuan Pengujian ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiReq("api/ext-lab/admin-add-pengujian-testing-request-approve", formdata);
            }
        });
    }
    const sendApiReq = async (url = "", data = {}) => {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            const resp = await doPost({
                url: url,
                data: { ...data },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalReq({ show: false, default_data: {} });
                    getReq();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const sncKlik = (formdata = {}) => {
        if(formdata?.note === "" || formdata?.note === null){
            return Swal.fire({
                title: 'Warning',
                text: "Keterangan / Komentar tidak boleh kosong",
                icon: 'warning',
            });
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSd("api/ext-lab/admin-add-pengujian-sample-delivery-not-complete", formdata);
            }
        });
    }
    const saKlik = (formdata = {}) => {
        if(formdata?.note === "" || formdata?.note === null){
            return Swal.fire({
                title: 'Warning',
                text: "Keterangan / Komentar tidak boleh kosong",
                icon: 'warning',
            });
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSd("api/ext-lab/admin-add-pengujian-sample-delivery-accepted", formdata);
            }
        });
    }
    const sabKlik = (formdata = {}) => {
        if(formdata?.note === "" || formdata?.note === null){
            return Swal.fire({
                title: 'Warning',
                text: "Keterangan / Komentar tidak boleh kosong",
                icon: 'warning',
            });
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSd("api/ext-lab/admin-add-pengujian-sample-delivery-aborted", formdata);
            }
        });
    }
    const tprKlik = (formdata = {}) => {
        if(formdata?.note === "" || formdata?.note === null){
            return Swal.fire({
                title: 'Warning',
                text: "Keterangan / Komentar tidak boleh kosong",
                icon: 'warning',
            });
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSd("api/ext-lab/admin-add-pengujian-sample-delivery-process-running", formdata);
            }
        });
    }
    const sendApiSd = async (url = "", data = {}) => {       
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            const resp = await doPost({
                url: url,
                data: { ...data },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalSd({ show: false, default_data: {} });
                    getSd();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const abortKlik = (formdata = {}) => {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSp("api/ext-lab/admin-add-pengujian-testing-process-aborted", formdata);
            }
        });
    }
    const finishKlik = (formdata = {}) => {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSp("api/ext-lab/admin-add-pengujian-testing-process-finished", formdata);
            }
        });
    }
    const sendApiSp = async (url = "", data = {}) => {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            const resp = await doPost({
                url: url,
                data: { ...data },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalSp({ show: false, default_data: {} });
                    getSp();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const spkNeedRevKlik = (formdata = {}) => {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSpReport("api/ext-lab/admin-add-pengujian-testing-report-need-revision", formdata);
            }
        });
    }
    const publishKlik = (formdata = {}) => {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApiSpReport("api/ext-lab/admin-add-pengujian-testing-report-published", formdata);
            }
        });
    }
    const sendApiSpReport = async (url = "", data = {}) => {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            const resp = await doPost({
                url: url,
                data: { ...data },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalTre({ show: false, default_data: {} });
                    getTre();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }


    //Hanlde Tab
    const tabChangeHandle = (name) => {
        setTabActive(name);
    }
    useEffect(() => {
        if (modalView.show && modalView.default_data?.opt === 1) {
            getDetail(modalView?.default_data?.id_lab_test || "");
        }
        if (modalView.show && modalView.default_data?.opt === 2) {
            getDetailSd(modalView?.default_data?.id_lab_test || "");
        }
        if (modalView.show && modalView.default_data?.opt === 3) {
            getDetailSp(modalView?.default_data?.id_lab_test || "");
        }
        if (modalView.show && modalView.default_data?.opt === 4) {
            getDetailTre(modalView?.default_data?.id_lab_test || "");
        }

    }, [modalView.show])
    useEffect(() => {
        if (tabActive === "tr") {
            getReq();
        }
        if (tabActive === "sd") {
            getSd();
        }
        if (tabActive === "sp") {
            getSp();
        }
        if (tabActive === "tre") {
            getTre();
        }
    }, [tabActive]);

    return <contexts.Provider
        value={{
            cpState: {
                tabActive,
                dataReq,
                dataSd,
                dataSp,
                modalReq,
                modalSd,
                modalView,
                dataDetail,
                detailAp,
                modalSp,
                dataTre,
                modalTre
            },
            cpAction: {
                tabChangeHandle,
                setModalReq,
                setModalView,
                setModalSd,
                getDetail,
                getDetailAp,
                getDetailSd,
                rejectKlik,
                needRevKlik,
                approveKlik,
                sncKlik,
                saKlik, sabKlik, tprKlik,
                setModalSp,
                abortKlik,
                finishKlik,
                setModalTre,
                spkNeedRevKlik,
                publishKlik
            }
        }}
    >
        <ModalSampleProcessing />
        <ModalPersetujuan />
        <ModalTestReqApproval />
        {React.useMemo(() => (
            <ModalUpdatetestingReport />
        ), [modalTre])}
        <ModalView />
        {children}
    </contexts.Provider>
}

export default function useCrudPengujian() {
    return useContext(contexts)
}