import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

function Index({ data = [] }) {
    const [dataSource, setDataSource] = useState([]);

    var colors = {
        "label": "#FEFEFE",
        "critical": "#c73636",
        "bad": "#eb9015",
        "medium": "#f2f22a",
        "good": "#20d873",
        "verygood": "#0b7d03"
    };

    var tableTemplate = [
        {
            col: [
                {
                    "text": "6. Definitely",
                    "color": colors.label,
                    "value": "6,0",
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "6,1",
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "6,2",
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "6,3",
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "6,4",
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "6,5",
                },
            ]
        },
        {
            col: [
                {
                    "text": "5. Almost Certain",
                    "color": colors.label,
                    "value": "5,0"
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "5,1"
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "5,2"
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "5,3"
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "5,4"
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "5,5"
                },
            ]
        },
        {
            col: [
                {
                    "text": "4. Likely",
                    "color": colors.label,
                    "value": "4,0"
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "4,1"
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "4,2"
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "4,3"
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "4,4"
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "4,5"
                },
            ]
        },
        {
            col: [
                {
                    "text": "3. Moderate",
                    "color": colors.label,
                    "value": "3,0"
                },
                {
                    "text": "",
                    "color": colors.good,
                    "value": "3,1"
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "3,2"
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "3,3"
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "3,4"
                },
                {
                    "text": "",
                    "color": colors.critical,
                    "value": "3,5"
                },
            ]
        },
        {
            col: [
                {
                    "text": "2. Unlikely",
                    "color": colors.label,
                    "value": "2,0"
                },
                {
                    "text": "",
                    "color": colors.good,
                    "value": "2,1"
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "2,2"
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "2,3"
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "2,4"
                },
                {
                    "text": "",
                    "color": colors.bad,
                    "value": "2,5"
                },
            ]
        },
        {
            col: [
                {
                    "text": "1. Rare",
                    "color": colors.label,
                    "value": "1,0"
                },
                {
                    "text": "",
                    "color": colors.good,
                    "value": "1,1"
                },
                {
                    "text": "",
                    "color": colors.good,
                    "value": "1,2"
                },
                {
                    "text": "",
                    "color": colors.good,
                    "value": "1,3"
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "1,4"
                },
                {
                    "text": "",
                    "color": colors.medium,
                    "value": "1,5"
                },
            ]
        },
        {
            col: [
                {
                    "text": "",
                    "color": colors.label,
                    "value": "0,0"
                },
                {
                    "text": "1. Insignificant",
                    "color": colors.label,
                    "value": "0,1"
                },
                {
                    "text": "2. Minor",
                    "color": colors.label,
                    "value": "0,2"
                },
                {
                    "text": "3. Moderate",
                    "color": colors.label,
                    "value": "0,3"
                },
                {
                    "text": "4. Significant",
                    "color": colors.label,
                    "value": "0,4"
                },
                {
                    "text": "5. Catastrophic",
                    "color": colors.label,
                    "value": "0,5"
                },
            ]
        },
    ];
    useEffect(() => {
        const newFormat = (data || []).map(({ probabilitas, dampak }) => ({
            "value": `${probabilitas},${dampak}`,
            "text": `${probabilitas},${dampak}`
        }))
        const distinctData = Array.from(new Set(newFormat.map((d) => d.value))).map((d) => ({
            "value": `${d}`,
            "text": `${d}`
        }));
        setDataSource(distinctData)
    }, [data])
    return (
        <div className='mt-5 px-5'>
            {
                tableTemplate.map((d, i) => (
                    <div className='row mb-1' style={{ height: '55px' }} key={i}>
                        {
                            d.col.map((x, j) => {
                                var text = "";
                                var styleCss = "";
                                var styleText = "";
                                if (i !== 6) {
                                    if (j === 0) {
                                        text = x.text;
                                        styleCss = "justify-content-end align-items-center pr-2";
                                    } else {
                                        text = dataSource.find(dt => dt?.value === x.value)?.text
                                        styleCss = "justify-content-center align-items-center";
                                        styleText = "font-weight-bold";
                                    }
                                } else {
                                    text = x.text;
                                    styleCss = "justify-content-center align-items-center";
                                }
                                return <div className='col-2 pl-0 pr-1' key={`${i}${j}`}>
                                    <div className={`d-flex rounded h-100 ${styleCss}`} style={{ background: x.color }}>
                                        <h4 className={styleText}>{text}</h4>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default Index;