import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import HeaderList from '../../../components/header-list/header-list';
import useCrudEquipment, { CrudEquipmentProvider } from '../useCrudEquipment';
import FormAddEdit from './component/formAddEdit';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalState } from '../../../contexts/useGlobalState';
import Swal from 'sweetalert2';

function EditPage(props) {
    const { ceqAction } = useCrudEquipment();
    const { id } = useParams();
    const [state] = useGlobalState();
    const [validate, setValidate] = useState(false);
    const history  = useHistory();

    async function getDataAndValidate(super_admin = false) {
        const resp = await ceqAction.getEdit(id);
        if (super_admin === false && resp?.data?.data_equipment?.is_penanggung_jawab !== true) {
            Swal.fire({
                title: 'Warning',
                text: "Anda bukan PIC Alata ini !",
                icon: 'warning',
                allowOutsideClick: false
            }).then(() => window.location.href = '/')
        } else if (resp?.data?.data_equipment?.status === '5') {
            Swal.fire({
                title: 'Warning',
                text: "Alat dengan status 'UPP/Dumped' tidak bisa dirubah kembali !",
                icon: 'warning',
                allowOutsideClick: false
            }).then(() => window.location.href = '/')
        } else {
            setValidate(true);
        }
    }
    useEffect(() => {
        if(state.dataUser){
            if (validationRoleSuperAdmin()) {
                ceqAction.resetEditorEquipment();
                getDataAndValidate(true);
                setValidate(true);
            } else {
                ceqAction.resetEditorEquipment();
                getDataAndValidate();
            }
        }
    }, [state.dataUser]);
    function validationRoleSuperAdmin() {
        const data_role = state.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Administrator" || d === "Admin Equipment") {
                result = true;
            }
        });
        return result;
    }
    // function validationRole() {
    //     const data_role = state.dataUser?.role_name?.split(' | ');
    //     let result = false;
    //     [...(data_role || [])].forEach(d => {
    //         if (d === "Admin Equipment") {
    //             result = true;
    //         }
    //     });
    //     return result;
    // }
    const renderContent = () => {
        if (validate) {
            return (
                <div className='container'>
                    <div style={{ paddingBottom: '20px' }}>
                        <HeaderList
                            title={"Equipment MS"}
                            someText={"Edit Equipment"}
                        />
                        <Card
                            style={{ marginBottom: '50px' }}
                            className="mx-0 mt-0 p-1 customShadow"
                        >
                            <Card.Header className='text-center'><h3>Form Edit Equipment</h3></Card.Header>
                            <Card.Body className="p-5">
                                <FormAddEdit />
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="p-2 bg-white rounded shadow-lg floating-footer-nolayout" >
                        <div className="row">
                        <div className="col-6 text-left">
                                <button className="btn btn-sm btn-secondary mx-1"
                                    onClick={() => history.goBack()}
                                >                                    
                                    Kembali
                                </button>
                            </div>
                            <div className="col-6 text-right">
                                <button className="btn btn-sm btn-primary mx-1"
                                    onClick={() => ceqAction.simpanClickEquipment('Edit')}
                                >
                                    <i className="fa fa-save icon-nm"></i>
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    return (
        <>{renderContent()}</>
    );
}

export default (props) => <CrudEquipmentProvider><EditPage {...props} /></CrudEquipmentProvider>;