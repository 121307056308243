import React, { useEffect, useState } from 'react';
import { HeaderList, Modal, CardBerita, PopularCard, GridDx, CarouselSpiirit } from '../../components';
import { Card, DropdownButton, Dropdown } from 'react-bootstrap'
import { useGlobalState } from '../../contexts/useGlobalState';
import { pathFile, pathImage } from '../../app.config';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';
import EditorContribution from './editorContribution';
import { Button, Column } from 'devextreme-react/data-grid';
import { month_source } from '../../components/utility/utility';
import { toString } from 'lodash';

const column = [
    { fieldName: 'month', caption: 'Implementation Time', allowFiltering: false },
    { fieldName: 'customer', caption: 'Customer', allowFiltering: false },
    { fieldName: 'product_contribution_status_name', caption: 'Contribution Status', allowFiltering: false },
    { fieldName: 'quantity', caption: 'Product Quantity', allowFiltering: false },
    { fieldName: 'price', caption: 'Predicted / Actual Price per Quantitity (USD)', allowFiltering: false },
    { fieldName: 'jumlah_contribution', caption: 'Product Contribution (USD)', allowFiltering: false },
    { fieldName: 'notes', caption: 'Notes', allowFiltering: false },
    { fieldName: 'updated_by', caption: 'Updated by', allowFiltering: false },
    { fieldName: 'updated_at', caption: 'Updated at', allowFiltering: false },
]
function ProductDetail(props) {
    const [state, dispatch, action] = useGlobalState();
    const { pathname } = useLocation();
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    const [dsGrid, setDsGrid] = useState([]);
    const [year_add, setYear_add] = useState('');
    const [mode, setMode] = useState('Add');
    const [id_edit, setId_edit] = useState('0');
    const [year_product, setYear_product] = useState('');
    const [predic, setPredic] = useState(0);
    const param = useParams();
    const history = useHistory();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        if (param?.id) {
            getData(param?.id);
        }
    }, []);
    function getData(id) {
        action.getData(`/api/ext-product/view-2/${id}`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setDsGrid([...(resp.data?.data_tahun || [])].map(d => {
                    const data_table = [...(resp.data.data_contribution)].filter(x => x.year === d);
                    return {
                        year: d,
                        data_table: data_table
                    }
                }))
                setData_page(resp.data_page);
            }
        })
    }
    function cstmCellRender(e) {
        if (e.column.name === "month") {
            return (
                <span className="text-wrap">{e.value ? month_source.find(d => d?.value === toString(e.value))?.name : ''}</span>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function editClicked(id) {

    }
    function headerCellRender(e) {
        return <p style={{ fontWeight: 'bolder', fontSize: '9pt', fontFamily: 'Poppins' }} className="text-wrap">{e.column.caption}</p>;
    }
    function editBtn(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => editContribution(data?.data)}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtn(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger" onClick={() => deleteContribution(data?.data)}>
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Product ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/km-product/delete?id=${id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        history.goBack();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function approve(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Product ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/km-product/approve?id=${id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        //history.goBack();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function reject(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Reject Product ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/km-product/reject?id=${id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        //history.goBack();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function waiting(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-warning mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-warning text-white customShadow mb-5"></i></div>' +
                '<strong>Are you sure ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/km-product/waiting?id=${id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        //history.goBack();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function addYear(year) {
        const data = {
            "id": '',
            "product_id": param?.id,
            "product_contribution_status_id": '',
            "product_location_id": '',
            "year": year || '',
            "month": '',
            "quantity": '',
            "price": '',
            "notes": "",
            "created_at": "",
            "updated_at": ""
        }
        if (data?.year === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Tahun tidak boleh kosong',
                icon: 'warning',
            })
        } else if ((parseInt(data?.year, 0) < 1900 || parseInt(data?.year, 0) > 2100)) {
            return Swal.fire({
                title: 'Warning',
                text: 'Tahun minimal 1900 dan maksimal 2100',
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Year contribution ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/ext-product/store-year`,
                        data: data
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.toggleModal('modalAddyear');
                        getData(param?.id);
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteYear(year) {
        const data = {
            "id": '',
            "product_id": param?.id,
            "product_contribution_status_id": '',
            "product_location_id": '',
            "year": year,
            "month": '',
            "quantity": '',
            "price": '',
            "notes": "",
            "created_at": "",
            "updated_at": ""
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete year contribution ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/ext-product/delete-year-contribution`,
                        data: data
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        getData(param?.id);
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function addContribution(data) {
        setId_edit('0');
        setMode('Add');
        setPredic(state.detail_product?.price)
        setYear_product(data?.year);
        action.toggleModal('modalAddContribution');
    }
    function editContribution(data) {
        setId_edit(data?.id);
        setMode('Edit');
        setPredic(0);
        setYear_product(data?.year);
        action.toggleModal('modalAddContribution');
    }
    function deleteContribution(data) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete product contribution ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // console.log(data);
                action.postData(
                    {
                        url: `/api/ext-product/delete-contribution/${data?.id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        getData(param?.id);
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function onSaveContribution(data) {
        // console.log(data);
        if (mode === 'Add') {
            action.postData(
                {
                    url: "/api/ext-product/store-contribution",
                    data: data,
                    withFile: false
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    getData(param?.id);
                }
            })
        }
        if (mode === 'Edit') {
            action.postData(
                {
                    url: "/api/ext-product/update-contribution",
                    data: data,
                    withFile: false
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    getData(param?.id);
                }
            })
        }
    }
    function summary(data) {
        const total = [...(data || [])].reduce((tot, current) => {
            return tot = tot + current?.jumlah_contribution
        }, 0);
        return parseFloat(total).toFixed(2);
    }
    return (
        <div style={{ padding: '30px', paddingBottom: '40px' }}>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            >

            </HeaderList>
            <Modal
                name="modalAddyear"
                baseColor={false}
                size='300px'
            >
                <Card>
                    <Card.Body>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="my-auto"> Year</span>
                            </div>
                            <input type="number" min={1900} max={2100} className="form-control form-control-sm mx-2" placeholder="Year" value={year_add} onChange={(e) => setYear_add(e.target.value)} />
                            <button className="btn btn-sm btn-primary"
                                onClick={() => addYear(year_add)}
                            >Add</button>
                        </div>
                    </Card.Body>
                </Card>
            </Modal>
            <Modal
                name="modalAddContribution"
                baseColor={false}
            >
                <Card>
                    <Card.Body>
                        <EditorContribution
                            mode={mode}
                            id_edit_p={id_edit}
                            id_product_p={param?.id}
                            year_p={year_product}
                            onSave={onSaveContribution}
                            predic={predic}
                        />
                    </Card.Body>
                </Card>
            </Modal>
            <Card
                className="m-0 pt-4 mb-5 customShadow"
            >
                <Card.Body className="p-4">
                    <div class="row mb-5">
                        <div class="col-md-6 mb-5">
                            <div class="pl-5 pr-5">
                                <CarouselSpiirit
                                    showItem={1}
                                    withButton={true}
                                    showIndicators={true}
                                >
                                    {
                                        [
                                            state.detail_product?.photo_location_name,
                                            state.detail_product?.photo_location_1_name,
                                            state.detail_product?.photo_location_2_name,
                                            state.detail_product?.photo_location_3_name
                                        ].filter(d => d !== "" && d !== null && d !== undefined).map((d, i) => (
                                            <img style={{ height: 250, width: '100%', objectFit: 'contain', borderRadius: '3%', backgroundColor:'whitesmoke' }} className="border"
                                                src={d}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} key={i} />
                                        ))
                                    }
                                </CarouselSpiirit>
                                {/* <img style={{ height: 250, width: '100%', objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                    src={state.detail_product?.photo_location_name}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} /> */}
                            </div>
                        </div>
                        <div class="col-md-6 mb-5">
                            <h2><b>{state.detail_product?.name}</b></h2>
                            <br />
                            <span className="d-block text-dark-50 mb-1">Deskripsi</span>
                            <span className="d-block font-weight-bold mb-5">{state.detail_product?.description}</span>
                            <span className="d-block text-dark-50 mb-1">Latar Belakang</span>
                            <span className="d-block font-weight-bold">{state.detail_product?.background}</span>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <span className="d-block font-weight-bold mb-3 ">Product Information</span>
                            <hr />
                            <span className="d-block text-dark-50 mb-1">IPR</span>
                            <ul className="d-block font-weight-bold mb-5">
                                {
                                    [...(state.detail_product?.list_ipr || [])].map((d, i) => (

                                        <li key={i}>
                                            <Link to={`/people/patent-view/${d?.patent_id}`} target={'_blank'}>{d.title}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                            <span className="d-block text-dark-50 mb-1">Tipe Produk</span>
                            <span className="d-block font-weight-bold mb-5">{state.detail_product?.product_types_name}</span>
                            <span className="d-block text-dark-50 mb-1">Tahun Rilis</span>
                            <span className="d-block font-weight-bold mb-5">{state.detail_product?.release_year}</span>
                            <span className="d-block text-dark-50 mb-1">Status</span>
                            <span className="d-block font-weight-bold mb-5">{state.detail_product?.product_status_name}</span>
                            <span className="d-block text-dark-50 mb-1">Contribution Type</span>
                            <span className="d-block font-weight-bold mb-5">{state.detail_product?.contrib_type}</span>
                        </div>
                        <div className="col-md-6">
                            <span className="d-block font-weight-bold mb-3">Initiator Information</span>
                            <hr />
                            <span className="d-block text-dark-50 mb-1">Inventor</span>
                            <span className="d-block font-weight-bold mb-5">{state.detail_product?.inventor_name}</span>
                            <span className="d-block text-dark-50 mb-1">Kontak</span>
                            <span className="d-block font-weight-bold mb-5">{state.detail_product?.kontak_name}</span>
                            <span className="d-block text-wrap text-dark mb-1">PIC Product Contribution</span>
                            <span className="d-block text-wrap font-weight-bold mb-5">{state?.detail_product?.nama_PIC_str}</span>
                            <span className="d-block text-dark-50 mb-1">Product Price</span>
                            <div className="row">
                                <div className="col-md-6">
                                    <span className="d-block text-dark-50 mb-1">Predicted / Actual Price per Quantitity (USD)</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="px-3 py-1 bg-light-primary rounded">
                                        ${
                                            state.detail_product?.price || '0'
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <span className="d-block">Product Contribution</span>
                        </div>
                        <div className="col-md-6 text-md-right">
                            {
                                path === '/admin/product/detail' && (
                                    <button className="btn btn-sm btn-primary"
                                        onClick={() => action.toggleModal('modalAddyear')}
                                    >
                                        <i className="fa fa-plus icon-sm"></i> Year
                                    </button>
                                )
                            }
                        </div>
                    </div>
                    <hr />
                    {
                        dsGrid.map((d, i) => (
                            <div className="mb-5">
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <span className="d-block text-wrap font-weight-bold mb-3 ">{d?.year}</span>
                                    </div>
                                    <div className="col-md-6 text-md-right">
                                        {
                                            path === '/admin/product/detail' && (
                                                <>
                                                    <button className="btn btn-sm btn-primary mx-1"
                                                        onClick={() => addContribution(d)}
                                                    >
                                                        <i className="fa fa-plus icon-sm m-0 p-0"></i> Contribution
                                                    </button>
                                                    <button className="btn btn-sm btn-outline-danger mx-1"
                                                        onClick={() => deleteYear(d?.year)}
                                                    >
                                                        <i className="fa fa-trash-alt icon-sm"></i> Year
                                                    </button>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                                <GridDx
                                    columns={column}
                                    cellRender={cstmCellRender}
                                    className='custom-style'
                                    data={d?.data_table}
                                >
                                    {
                                        path === '/admin/product/detail' && (
                                            <Column caption="Action" type="buttons" headerCellRender={headerCellRender} >
                                                <Button name="customEdit" visible={true} render={editBtn} />
                                                <Button name="delete" visible={true} render={deleteBtn} />
                                            </Column>
                                        )
                                    }
                                </GridDx>
                                <div className="p-2 custom-footer-grid text-center">
                                    Total Kontribusi Produk {summary(d?.data_table)} (USD)
                                </div>
                            </div>
                        ))
                    }
                </Card.Body>
            </Card>
            {/* <span class="d-block titlePage mb-5">Lihat product lain</span>
            <div className="row mb-5">
                {
                    [...(state.product_lain || [])].map(d => (
                        <div className="col-md-4 mb-3">
                            <Card
                                className="p-0 customShadow"
                            >
                                <Card.Body className="p-5">
                                    <div className="col-md-12">
                                        <div className="card card-custom overlay my-5">
                                            <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                <div className="overlay-wrapper">
                                                    <img style={{ width: '100%', minHeight: 150, maxHeight: 300, objectFit: 'cover', borderRadius: '3%' }} className="bg-secondary"
                                                        src={d.photo_product}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                                </div>
                                                <div class="overlay-layer">
                                                    <button className="btn btn-sm btn-primary mx-1"><i className="fa fa-download"></i>
                                                        Download
                                                    </button>
                                                    <button className="btn btn-sm btn-light mx-1"
                                                        onClick={() => history.replace(`/product/detail/${d?.id}`)}
                                                    ><i className="fa fa-search"></i>
                                                        Lihat Detail
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <h5>{d.name}</h5>
                                        </p>
                                        <p>{d.description}</p>
                                        <div>
                                            {d.level}
                                        </div>
                                        <br />
                                        <br />
                                        <p>
                                            {d.release_year}
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }
            </div> */}
            <div className={`p-2 bg-white rounded shadow-lg floating-footer ${path.toLowerCase() === '/admin/product/detail' ? '' : 'detail-footer'}`} >
                <div className="row">
                    <div className="col-md-6 text-left">
                        {
                            path.toLowerCase() === '/admin/product/detail' && (
                                <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            )
                        }
                    </div>
                    <div className="col-md-6 text-md-right">
                        {
                            state.detail_product?.file_location_name && (
                                <a href={state.detail_product?.file_location_name} target="_blank" download>
                                    <button className="btn btn-sm btn-primary">
                                        <i className="fa fa-download icon-nm mx-2"></i>
                                        Download ({state.detail_product?.file_size} Kb)
                                    </button>
                                </a>
                            )
                        }
                        {
                            path.toLowerCase() === '/admin/product-approval/detail' && (
                                <div className="d-inline-block">
                                    <DropdownButton size="sm" id="dropdown-basic-button" title="Action" className="mx-1">
                                        {
                                            (state.detail_product?.status_approval === 1 || state.detail_product?.status_approval === 3) && (
                                                <Dropdown.Item onClick={() => approve(param?.id)}>Approve</Dropdown.Item>
                                            )
                                        }
                                        {
                                            (state.detail_product?.status_approval === 1 || state.detail_product?.status_approval === 2) && (
                                                <Dropdown.Item className="text-danger" onClick={() => reject(param?.id)}>Reject</Dropdown.Item>
                                            )
                                        }
                                        {
                                            (state.detail_product?.status_approval === 2 || state.detail_product?.status_approval === 3) && (
                                                <Dropdown.Item onClick={() => waiting(param?.id)}>Waiting Approval</Dropdown.Item>
                                            )
                                        }
                                        <Dropdown.Item onClick={() => history.push(`/admin/product/edit/${param?.id}`)}>Edit</Dropdown.Item>
                                        <Dropdown.Item className="text-danger" onClick={() => deleteData(param?.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ProductDetail;