import DataGrid, { Column, Lookup, LoadPanel } from 'devextreme-react/data-grid';
import React, { useState, useRef, useEffect } from 'react';
import { task } from '../utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import './grid-timeline.css';
import { Button, NumberBox } from 'devextreme-react';
import { Validator, CustomRule } from 'devextreme-react/validator'
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toInteger } from 'lodash';
import { date } from 'yup/lib/locale';

export default function ({
    dataSource,
    columns,
    allowAdding = false,
    allowEditing = false,
    allowDeleting = false,
    modeEditing = "row",
    showTotalActual = false,
    data_year_month = ""
}) {
    const [state, dispatch, action] = useGlobalState();
    const [ds, setDs] = useState();
    const refGrid = useRef(null);
    const [sum, setSum] = useState(0);
    const [sumCut, setSumCut] = useState(0);
    const [sumActual, setSumActual] = useState(0)

    useEffect(() => {
        setDs(dataSource.data)
    }, [dataSource])

    useEffect(() => {
        if (refGrid.current) {
            Summary();
            refGrid.current.instance.refresh()
        }
    }, [ds])
    function headerRender(e) {
        return (<span className="d-block text-center" style={{ fontWeight: 'bold' }}>{e.column.caption}</span>)
    }
    function celRender(e) {
        if (e.column.name === "no") {
            return (
                <span className="text-wrap textContent">{e.rowIndex + 1}</span>
            )
        } else if (e.column.name === "task") {
            return (task(e))
        }
        else {
            return (
                <span className="text-wrap textContent">{e.text}</span>
            )
        }
    }
    function Summary() {
        const bln = ["plan_jan", "plan_feb", "plan_mar", "plan_apr", "plan_may", "plan_jun", "plan_jul", "plan_aug", "plan_sep", "plan_oct", "plan_nov", "plan_dec"];
        const tmp = ds;
        const month = toInteger(data_year_month.split('/')?.[0]);
        const year = toInteger(data_year_month.split('/')?.[1]);
        var monthCut = 12;
        if (toInteger(dataSource.year) === toInteger(year)) {
            monthCut = toInteger(month);
        }
        if (toInteger(dataSource.year) > toInteger(year)) {
            monthCut = 0;
        }
        let total = 0;
        let totalCut = 0;
        let totalActual = 0;
        if (Array.isArray(tmp)) {
            [...(tmp || [])].forEach((dt, i) => {
                total += ((isNaN(Number(dt.plan_jan))) ? 0 : Number(dt.plan_jan)) +
                    ((isNaN(Number(dt.plan_feb))) ? 0 : Number(dt.plan_feb)) +
                    ((isNaN(Number(dt.plan_mar))) ? 0 : Number(dt.plan_mar)) +
                    ((isNaN(Number(dt.plan_apr))) ? 0 : Number(dt.plan_apr)) +
                    ((isNaN(Number(dt.plan_may))) ? 0 : Number(dt.plan_may)) +
                    ((isNaN(Number(dt.plan_jun))) ? 0 : Number(dt.plan_jun)) +
                    ((isNaN(Number(dt.plan_jul))) ? 0 : Number(dt.plan_jul)) +
                    ((isNaN(Number(dt.plan_aug))) ? 0 : Number(dt.plan_aug)) +
                    ((isNaN(Number(dt.plan_sep))) ? 0 : Number(dt.plan_sep)) +
                    ((isNaN(Number(dt.plan_oct))) ? 0 : Number(dt.plan_oct)) +
                    ((isNaN(Number(dt.plan_nov))) ? 0 : Number(dt.plan_nov)) +
                    ((isNaN(Number(dt.plan_dec))) ? 0 : Number(dt.plan_dec));

                totalCut += bln.slice(0, monthCut).reduce((tot, cur) => tot += ((isNaN(Number(dt[cur]))) ? 0 : Number(dt[cur])), 0)

                totalActual += ((isNaN(Number(dt.actual_jan))) ? 0 : Number(dt.actual_jan)) +
                    ((isNaN(Number(dt.actual_feb))) ? 0 : Number(dt.actual_feb)) +
                    ((isNaN(Number(dt.actual_mar))) ? 0 : Number(dt.actual_mar)) +
                    ((isNaN(Number(dt.actual_apr))) ? 0 : Number(dt.actual_apr)) +
                    ((isNaN(Number(dt.actual_may))) ? 0 : Number(dt.actual_may)) +
                    ((isNaN(Number(dt.actual_jun))) ? 0 : Number(dt.actual_jun)) +
                    ((isNaN(Number(dt.actual_jul))) ? 0 : Number(dt.actual_jul)) +
                    ((isNaN(Number(dt.actual_aug))) ? 0 : Number(dt.actual_aug)) +
                    ((isNaN(Number(dt.actual_sep))) ? 0 : Number(dt.actual_sep)) +
                    ((isNaN(Number(dt.actual_oct))) ? 0 : Number(dt.actual_oct)) +
                    ((isNaN(Number(dt.actual_nov))) ? 0 : Number(dt.actual_nov)) +
                    ((isNaN(Number(dt.actual_dec))) ? 0 : Number(dt.actual_dec));

            })
        }
        setSumCut(totalCut);
        setSum(total)
        setSumActual(totalActual)
    }
    function lookupValueChange(rowData, value) {
        this.defaultSetCellValue(rowData, value);
    }
    function actualDataChange(rowInfo, value) {
        const newDs = [...(ds || [])];
        newDs.map((d, i) => {
            if (d.id === rowInfo.key.id) {
                // var max = maxActual(rowInfo.data, Object.keys(value).toString())
                // if (((isNaN(Number(value[Object.keys(value)]))) ? 0 : Number(value[Object.keys(value)])) > max) {
                //     d[Object.keys(value)] = max
                // } else {
                d[Object.keys(value)] = (isNaN(Number(value[Object.keys(value)]))) ? 0 : Number(value[Object.keys(value)])
                // }
            }
        })
        //console.log('new data', newDs)
        setDs(newDs)
        Summary();
    }
    function maxActual(data, bulan) {
        var key = ['actual_jan', 'actual_feb', 'actual_mar', 'actual_apr', 'actual_may', 'actual_jun', 'actual_jul', 'actual_aug',
            'actual_sep', 'actual_oct', 'actual_nov', 'actual_dec']
        var plan = ((isNaN(Number(data.plan_jan))) ? 0 : Number(data.plan_jan)) +
            ((isNaN(Number(data.plan_feb))) ? 0 : Number(data.plan_feb)) +
            ((isNaN(Number(data.plan_mar))) ? 0 : Number(data.plan_mar)) +
            ((isNaN(Number(data.plan_apr))) ? 0 : Number(data.plan_apr)) +
            ((isNaN(Number(data.plan_may))) ? 0 : Number(data.plan_may)) +
            ((isNaN(Number(data.plan_jun))) ? 0 : Number(data.plan_jun)) +
            ((isNaN(Number(data.plan_jul))) ? 0 : Number(data.plan_jul)) +
            ((isNaN(Number(data.plan_aug))) ? 0 : Number(data.plan_aug)) +
            ((isNaN(Number(data.plan_sep))) ? 0 : Number(data.plan_sep)) +
            ((isNaN(Number(data.plan_oct))) ? 0 : Number(data.plan_oct)) +
            ((isNaN(Number(data.plan_nov))) ? 0 : Number(data.plan_nov)) +
            ((isNaN(Number(data.plan_dec))) ? 0 : Number(data.plan_dec));

        var act = key.map(s => {
            if (s !== bulan) {
                return data[s] || 0
            } else {
                return 0
            }
        })
        var actual = act.reduce((a, b) => a + b, 0);
        var result = Number(plan.toFixed(2) || 0) - Number(actual.toFixed(2) || 0);
        return Number(parseFloat(result).toFixed(2) || 0);
    }
    function renderTooltip(val, data, bulan, disable = false, ...props) {
        var bigger = false;
        if (maxActual(data, bulan) < val) {
            bigger = true;
        }
        return (
            <Tooltip id="button-tooltip" {...props} className={`my-tooltip ${(!bigger || disable === true) && 'd-none'}`}>
                Realisasi melebihi Rencana
            </Tooltip>
        );
    }
    function RowRender(rowInfo) {
        //console.log('tanggal', new Date().getMonth())
        const days = parseInt(moment(new Date()).format('D'));
        const month = toInteger(data_year_month.split('/')?.[0]);
        const year = toInteger(data_year_month.split('/')?.[1]);
        let disableInput = {
            jan: true,
            feb: true,
            mar: true,
            apr: true,
            may: true,
            jun: true,
            jul: true,
            aug: true,
            sep: true,
            oct: true,
            nov: true,
            dec: true,
        }

        if (allowEditing === true) {
            if (state.filterData.mode === "edit") {
                // disableInput = {
                //     jan: month === 1 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 2 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     feb: month === 2 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 3 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     mar: month === 3 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 4 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     apr: month === 4 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 5 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     may: month === 5 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 6 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     jun: month === 6 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 7 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     jul: month === 7 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 8 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     aug: month === 8 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 9 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     sep: month === 9 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 10 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     oct: month === 10 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 11 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     nov: month === 11 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 12 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                //     dec: month === 12 ? (days > parseInt(state.formData.cut_off_timeline) ? false : true) : (month === 1 && days <= parseInt(state.formData.cut_off_timeline) ? false : true),
                // }
                disableInput = {
                    jan: month === 1 ? false : true,
                    feb: month === 2 ? false : true,
                    mar: month === 3 ? false : true,
                    apr: month === 4 ? false : true,
                    may: month === 5 ? false : true,
                    jun: month === 6 ? false : true,
                    jul: month === 7 ? false : true,
                    aug: month === 8 ? false : true,
                    sep: month === 9 ? false : true,
                    oct: month === 10 ? false : true,
                    nov: month === 11 ? false : true,
                    dec: month === 12 ? false : true,
                }
            }
            if (year.toString() !== dataSource.year.toString()) {
                disableInput = {
                    jan: true,
                    feb: true,
                    mar: true,
                    apr: true,
                    may: true,
                    jun: true,
                    jul: true,
                    aug: true,
                    sep: true,
                    oct: true,
                    nov: true,
                    dec: true,
                }
            }
            if (rowInfo.data.type === 'task') {
                disableInput = {
                    jan: true,
                    feb: true,
                    mar: true,
                    apr: true,
                    may: true,
                    jun: true,
                    jul: true,
                    aug: true,
                    sep: true,
                    oct: true,
                    nov: true,
                    dec: true,
                }
            }
        } else {
            disableInput = {
                jan: true,
                feb: true,
                mar: true,
                apr: true,
                may: true,
                jun: true,
                jul: true,
                aug: true,
                sep: true,
                oct: true,
                nov: true,
                dec: true,
            }
        }
        return (
            <tbody>
                <tr className="dx-row dx-data-row">
                    <td>
                        <span className="d-block text-wrap px-2  border-0">{rowInfo.rowIndex + 1}</span>
                    </td>
                    <td colSpan={rowInfo.data.type === 'task' ? '14' : '0'}>
                        <span className={`d-block text-wrap  border-0 ${rowInfo.data.type === "task" ? "text-uppercase" : ""}`} style={
                            rowInfo.data.type === "milestones" ? { fontWeight: "bold", fontStyle: 'italic' } :
                                rowInfo.data.type === "submilestones" ? { fontWeight: "bold", paddingLeft: "35px", fontStyle: 'italic' } :
                                    rowInfo.data.type === "task" ? { fontWeight: "bold" } :
                                        { fontWeight: "regular", paddingLeft: "35px" }
                        }>{rowInfo.data.task}</span>
                    </td>
                    {
                        rowInfo.data.type !== "task" && (
                            <td colSpan="13" className="p-0">
                                <table width={"100%"}>
                                    <tbody className="main-row">
                                        <tr className={`mb-1 ${(rowInfo.data.type === 'milestones' || rowInfo.data.type === "submilestones") ? "bgMlsPlan" : "bgTaskPlan"}`}>
                                            <td width="100px" height={'32px'}>
                                                <span className="d-block text-wrap px-2 border-0 form-control-sm text-right"><strong>PLAN</strong></span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_jan}</span>
                                            </td>
                                            <td width="50px">
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_feb}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_mar}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_apr}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_may}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_jun}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_jul}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_aug}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_sep}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_oct}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_nov}</span>
                                            </td>
                                            <td width="50px" >
                                                <span className="d-block text-wrap px-2 border-0">{rowInfo.data.plan_dec}</span>
                                            </td>
                                        </tr>
                                        <tr className={`mb-1 ${(rowInfo.data.type === 'milestones' || rowInfo.data.type === "submilestones") ? "bgMlsActual" : "bgTaskActual"}`}>
                                            <td >
                                                <span className={`d-block text-wrap form-control form-control-sm border-0 rounded-0 mx-0 border-top text-right ${(rowInfo.data.type === 'milestones' || rowInfo.data.type === "submilestones") ? "bgMlsActual" : "bgTaskActual"}`}><strong>REALISASI</strong></span>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_jan, rowInfo.data, 'actual_jan', disableInput.jan)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.jan} className={disableInput.jan ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_jan, rowInfo.data, 'actual_jan')} value={rowInfo.data.actual_jan} onValueChanged={(e) => actualDataChange(rowInfo, { actual_jan: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_feb, rowInfo.data, 'actual_feb', disableInput.feb)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.feb} className={disableInput.feb ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_feb, rowInfo.data, 'actual_feb')} value={rowInfo.data.actual_feb} onValueChanged={(e) => actualDataChange(rowInfo, { actual_feb: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_mar, rowInfo.data, 'actual_mar', disableInput.mar)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.mar} className={disableInput.mar ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_mar, rowInfo.data, 'actual_mar')} value={rowInfo.data.actual_mar} onValueChanged={(e) => actualDataChange(rowInfo, { actual_mar: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_apr, rowInfo.data, 'actual_apr', disableInput.apr)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.apr} className={disableInput.apr ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_apr, rowInfo.data, 'actual_apr')} value={rowInfo.data.actual_apr} onValueChanged={(e) => actualDataChange(rowInfo, { actual_apr: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_may, rowInfo.data, 'actual_may', disableInput.may)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.may} className={disableInput.may ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_may, rowInfo.data, 'actual_may')} value={rowInfo.data.actual_may} onValueChanged={(e) => actualDataChange(rowInfo, { actual_may: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_jun, rowInfo.data, 'actual_jun', disableInput.jun)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.jun} className={disableInput.jun ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_jun, rowInfo.data, 'actual_jun')} value={rowInfo.data.actual_jun} onValueChanged={(e) => actualDataChange(rowInfo, { actual_jun: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_jul, rowInfo.data, 'actual_jul', disableInput.jul)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.jul} className={disableInput.jul ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_jul, rowInfo.data, 'actual_jul')} value={rowInfo.data.actual_jul} onValueChanged={(e) => actualDataChange(rowInfo, { actual_jul: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_aug, rowInfo.data, 'actual_aug', disableInput.aug)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.aug} className={disableInput.aug ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_aug, rowInfo.data, 'actual_aug')} value={rowInfo.data.actual_aug} onValueChanged={(e) => actualDataChange(rowInfo, { actual_aug: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_sep, rowInfo.data, 'actual_sep', disableInput.sep)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.sep} className={disableInput.sep ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_sep, rowInfo.data, 'actual_sep')} value={rowInfo.data.actual_sep} onValueChanged={(e) => actualDataChange(rowInfo, { actual_sep: e.value })} format="#,##0.##" i />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_oct, rowInfo.data, 'actual_oct', disableInput.oct)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.oct} className={disableInput.oct ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_oct, rowInfo.data, 'actual_oct')} value={rowInfo.data.actual_oct} onValueChanged={(e) => actualDataChange(rowInfo, { actual_oct: e.value })} format="#,##0.##" >
                                                        </NumberBox>
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_nov, rowInfo.data, 'actual_nov', disableInput.nov)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.nov} className={disableInput.nov ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_nov, rowInfo.data, 'actual_nov')} value={rowInfo.data.actual_nov} onValueChanged={(e) => actualDataChange(rowInfo, { actual_nov: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td >
                                                <OverlayTrigger
                                                    placement="left-start"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip(rowInfo.data.actual_dec, rowInfo.data, 'actual_dec', disableInput.dec)}
                                                >
                                                    <div>
                                                        <NumberBox min={0} disabled={disableInput.dec} className={disableInput.dec ? 'cstmbgColorEditorActualDisable' : borderStyle(rowInfo.data.actual_dec, rowInfo.data, 'actual_dec')} value={rowInfo.data.actual_dec} onValueChanged={(e) => actualDataChange(rowInfo, { actual_dec: e.value })} format="#,##0.##" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        )
                    }
                </tr>
            </tbody>
        )
    }
    function borderStyle(val, data, bulan) {
        var st = "border-primary";
        if (maxActual(data, bulan) < val) {
            st = "border-danger";
        }
        // console.log(val, data, bulan, st);
        return st;
    }
    return (
        <React.Fragment>
            <DataGrid
                width={'100%'}
                dataSource={ds}
                ref={refGrid}
                paging={null}
                showRowLines={true}
                showColumnLines={true}
                showBorders={true}
                rowRender={RowRender}
                className="headerCustomTimeline gridBorder gridBorder-col gridBorder-content"
                columnAutoWidth={false}

            >
                <LoadPanel enabled={false} />
                {
                    [{
                        fieldName: "no",
                        allowEditing: false,
                        caption: "#",
                        width: 75,
                        allowSorting: false,
                        allowFiltering: false
                    },
                    ...columns
                    ].map(({
                        fieldName,
                        caption,
                        allowEditing = true,
                        dataType = null,
                        width = null,
                        minWidth = null,
                        allowSorting = true,
                        allowFiltering = true,
                        custom = false,
                        customType = "lookup",
                        dsCustom = [],
                        displayExpr = "",
                        valueExpr = "",
                        visible = true
                    }, i) => custom ?
                            (
                                <Column
                                    fixed={true}
                                    minWidth={minWidth}
                                    allowEditing={allowEditing}
                                    dataField={fieldName}
                                    dataType={dataType}
                                    width={width}
                                    caption={caption}
                                    allowSorting={allowSorting}
                                    allowFiltering={allowFiltering}
                                    key={i}
                                    visible={visible}
                                    cellRender={celRender}
                                    headerCellRender={headerRender}
                                    setCellValue={lookupValueChange}
                                >
                                    {
                                        CreateCustom({
                                            type: customType,
                                            dataType: dataType,
                                            dataSource: dsCustom,
                                            displayExpr: displayExpr,
                                            valueExpr: valueExpr
                                        })
                                    }
                                </Column>
                            ) : (
                                < Column
                                    fixed={true}
                                    minWidth={minWidth}
                                    allowEditing={allowEditing}
                                    dataField={fieldName}
                                    width={width}
                                    allowSorting={allowSorting}
                                    allowFiltering={allowFiltering}
                                    caption={caption}
                                    dataType={dataType}
                                    headerCellRender={headerRender}
                                    key={i}
                                    visible={visible}
                                    cellRender={celRender} >
                                </Column>
                            )
                    )
                }
            </DataGrid>
            <div style={{ padding: 10, borderLeft: '1px solid #006CB8', borderRight: '1px solid #006CB8', borderBottom: '1px solid #006CB8' }} >
                <span style={{ display: "block", textAlign: "center" }}>Total Plan (Month/Year) {(sumCut || 0).toFixed(2)}% / {(sum || 0).toFixed(2)}%</span>
            </div>
            {
                showTotalActual &&
                <div style={{ padding: 10, borderLeft: '1px solid #006CB8', borderRight: '1px solid #006CB8', borderBottom: '1px solid #006CB8' }} >
                    <span style={{ display: "block", textAlign: "center" }}>Total Realisasi {(sumActual || 0).toFixed(2)}%</span>
                </div>
            }
        </React.Fragment>
    )
}
function CreateCustom(props) {
    if (props.type === "lookup") {
        return (
            <Lookup dataSource={props.dataSource} dataType={props.dataType} displayExpr={props.displayExpr} valueExpr={props.valueExpr} />
        )
    }
}
