import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FileUploadDx } from '../../../../components';
import moment from 'moment';
import useLab from '../useLaboratory';
import Swal from 'sweetalert2';

function ModalUploadSPK(props) {
    const [note, setNotes] = useState("");
    const [file_name, setfile_name] = useState("");
    const [file_name2, setfile_name2] = useState("");
    const refFile = useRef();
    const refFilePo = useRef();
    const { labState, labAction } = useLab();

    const fileUploaded = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setfile_name(resp.data?.data_filename || "");
            if (refFile.current) {
                refFile.current.instance.reset();
            }
        }
    }
    const fileUploaded2 = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setfile_name2(resp.data?.data_filename || "");
            if (refFilePo.current) {
                refFilePo.current.instance.reset();
            }
        }
    }
    const onKirim = () => {
        if ((file_name === "" || file_name === null || file_name === undefined) || (file_name2 === "" || file_name2 === null || file_name2 === undefined) || note === "") {
            return Swal.fire({
                title: 'Error',
                text: "Harap mengisi keterangan, dokumen kontrak dan dokumen PO.",
                icon: 'warning',
            })
        }
        labAction.uploadSpk({
            "id": labState.modalSPK?.defaultData?.id,
            note,
            file_name,
            file_name2
        });
    }

    return (
        <Modal show={labState.modalSPK?.show} onHide={() => labAction.setModalSPK({ show: false, default_data: {} })}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Testing Process Finished (Upload Dok. Kontrak)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-sm-4">Tanggal Perubahan Status</label>
                    <div className="col-sm-8">
                        <p>{moment().format("DD-MM-yyyy")}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan / Komentar</label>
                    <div className="col-sm-8">
                        <textarea className='form-control form-control-sm' rows={3}
                            value={note} onChange={e => setNotes(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Upload Dokumen Kontrak (Wajib)</label>
                    <div className="col-sm-8">
                        <FileUploadDx
                            ref={refFile}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            allowedFileExtensions={['.pdf',]}
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 10 Mb"
                            accept="*"
                            onUploaded={fileUploaded}
                            maxFileSize={10000000}
                            uploadMode="instantly"
                        />
                        {file_name && <p>{file_name}</p>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Upload Dokumen PO (Wajib)</label>
                    <div className="col-sm-8">
                        <FileUploadDx
                            ref={refFilePo}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            allowedFileExtensions={['.pdf',]}
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 10 Mb"
                            accept="*"
                            onUploaded={fileUploaded2}
                            maxFileSize={10000000}
                            uploadMode="instantly"
                        />
                        {file_name2 && <p>{file_name2}</p>}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => onKirim()} className="btn btn-sm btn-success mr-1">Kirim Dokumen</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalUploadSPK;