import React from 'react';
import { Container, Dropdown } from 'react-bootstrap';
import ImageCover from '../images/bg-land-detail.svg';
import useLaboratory, { LaboratoryProvider } from './useLaboratory';
import { PaginationCs } from '../../../components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import useIndexLandPage from '../use_index';
import { AuthConsumer } from '../../../service/authenticationProvider';

function LaboratoryIndex(props) {
    const { indAction: {
        toLaboratoryDetail = () => null
    } } = useIndexLandPage();
    const { labState, labAction } = useLaboratory();
    const history = useHistory();
    return (
        <div className='mt-5' style={{ 'paddingTop': '35px' }}>
            <img src={ImageCover} style={{ 'position': 'absolute', width: '100%', height: '220px', top: '10px', objectFit: 'cover' }}></img>
            <Container>
                <div className='row mb-5 text-white'>
                    <div className='col-md-6'>
                        <h5 className='fw-bold'>Produk Laboratory RTI</h5>
                    </div>
                    <div className='col-md-6 text-right'>
                        {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Permohonan Pengujian LAB
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <AuthConsumer>
                                    {({ signinRedirect }) => (
                                        <Dropdown.Item onClick={() => signinRedirect()}>Sudah Terdaftar Customer</Dropdown.Item>
                                    )}
                                </AuthConsumer>
                                <Dropdown.Item onClick={() => labAction.openModalReg()}>Ajukan Permohonan Pendaftaran</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </div>
                </div>
                {
                    labState.urlImage && (
                        <div className='row'>
                            <div className='col-md-12 mb-5'>
                                <img src={labState.urlImage}
                                    style={{ maxHeight: '500px', width: '100%' }}
                                ></img>
                            </div>
                        </div>
                    )
                }
                <PaginationCs
                    data={[...(labState.dataLab?.data_products || [])]}
                    dataPerPage={8}
                    RenderContainer={({ children }) => (
                        <div className='row mb-5'>
                            {children}
                        </div>
                    )}
                    renderComponent={(d, i) => (
                        <div className='col-lg-3 col-md-3 mb-3' key={i}>
                            <div className='card p-3 h-100 rounded-cs card-land-produk'
                                onClick={() => toLaboratoryDetail({
                                    detail: d,
                                    all_data: [...(labState.dataLab?.data_products || [])],
                                    selected_index: i
                                })}
                            >
                                <LazyLoadImage
                                    key={i}
                                    src={d?.photo_location_name}
                                    width={'100%'}
                                    height={'180px'}
                                    style={{ objectFit: 'contain', backgroundColor: 'whitesmoke' }}
                                    onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                    effect='blur'
                                />
                                <span className='text-wrap font-weight-boldest m-3'>{d?.name}</span>
                                <div className='footer-card-product'>
                                    <div>
                                        <span className='bg-light-primary text-primary px-2 rounded'>{d?.product_status_name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <div className='d-inline-flex bg-transparent py-3 position-sticky my-3' style={{ bottom: '10px' }}>
                    <button className='btn btn-sm btn-secondary'
                        onClick={() => history.push('/landing-page/beranda')}
                    >
                        <i className='fas fa-chevron-circle-left icon-nm'></i>
                        Kembali
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default () => <LaboratoryProvider><LaboratoryIndex /></LaboratoryProvider>;