import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import useFetch from '../../../modules/useFetch';
import useIndexLandPage from '../use_index';
import { useGlobalState } from '../../../contexts/useGlobalState';
const homIndContext = createContext({
    homIndState: {},
    homIndAction: {}
})
export function HomeIndexProvider({ children }) {
    const { indAction, indState } = useIndexLandPage();
    const [, , action] = useGlobalState();
    const { doGet } = useFetch();
    const [loading, setLoading] = useState(false);
    const [dataLand, setDataLan] = useState({
        data_products: [],
        data_achievements: [],
        data_events: []
    });

    async function getDataLanding() {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/web/home',
                token: false
            });
            if (resp?.code === '00') {
                setDataLan({ ...resp?.data })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }

    async function getDataType() {
        try {
            const resp = await doGet({
                url: 'api/web/get-product-type',
                token: false
            });
            if (resp?.code === '00') {
                action.setForm('landingpage_product_type', [...resp?.data]);
                const new_array_length = Math.floor([...resp?.data].length / 2) + ([...resp?.data].length % 2 > 0 ? 1 : 0);
                const new_array = Array.from(Array(new_array_length), (d, i) => i).map((d, i) => (
                    { "col": [...resp?.data].splice(i * 2, 2) }
                ))
                // console.log(new_array, [...resp?.data])
                indAction.setDataType(new_array)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
    }

    const refBera = useRef();
    const refProd = useRef();
    const refAchi = useRef();
    const refEven = useRef();
    const refRTI = useRef();

    useEffect(() => {
        indAction.berandaScroll();
        indAction.setDisplayLayout(true);
        getDataLanding();
        getDataType();
    }, []);
    useEffect(() => {
        if (indState.scrollElement === 'product') {
            // refProd.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            window.scrollTo({
                behavior: 'smooth',
                top: refProd.current?.offsetTop - 100
            })
        }
        else if (indState.scrollElement === 'achievement') {
            // refAchi.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            window.scrollTo({
                behavior: 'smooth',
                top: refAchi.current?.offsetTop - 100
            })
        }
        else if (indState.scrollElement === 'event') {
            // refEven.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            window.scrollTo({
                behavior: 'smooth',
                top: refEven.current?.offsetTop - 100
            })
        }
        else if (indState.scrollElement === 'rti') {
            // refRTI.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            window.scrollTo({
                behavior: 'smooth',
                top: refRTI.current?.offsetTop - 100
            })
        } else {
            window.scrollTo({
                behavior: 'smooth',
                top: refBera.current?.offsetTop - 100
            })
        }
    }, [indState.scrollElement]);
    return <homIndContext.Provider
        value={{
            homIndState: {
                refBera,
                refProd,
                refAchi,
                refEven,
                refRTI,
                loading,
                dataLand
            },
            homIndAction: {}
        }}
    >{children}</homIndContext.Provider>
}

export default function useHomIndex() {
    return useContext(homIndContext);
};