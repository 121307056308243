import React, { useState, useRef, useEffect, memo, forwardRef } from 'react';
import DataGrid, { Button, Column, Editing, Paging, Popup, Position, Scrolling, Texts, ColumnFixing, LoadPanel } from 'devextreme-react/data-grid';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useGlobalState } from '../../contexts/useGlobalState';
import { Modal } from '../index';
import {
    risk_priority,
    risk_priority_residu,
    inher_dampak,
    inher_probabil
} from '../utility/utility';
import './grid-dx-risk.css';

export default memo(
    forwardRef(
        function ({
            dataSource = [],
            collumns = [],
            bodyForm = () => null,
            settingColumn = false,
            columnResizing = false,
            onFinish,
            onDelete,
            editable = true

        }, ref) {
            const [state, dispatch, action] = useGlobalState();
            const [ds, setDs] = useState();
            const [disable, setDisable] = useState(true);
            const [indexSlected, setIndex] = useState();
            const [selData, setSelData] = useState();
            const [actionEdit, setActionEd] = useState();
            //const refGrid = useRef(null);

            useEffect(() => {
                if (dataSource.length > 0) {
                    setDs(dataSource)
                }
            }, [dataSource])

            function headerRender(e) {
                return (<span className="d-block text-center" style={{ fontWeight: 'bold' }}>{e.column.caption}</span>)
            }
            function viewBtn(data) {
                return (
                    <button className="btn btn-sm text-center p-2 mx-1 btn-warning" onClick={() => viewOnclick(data)} >
                        <i className="fa fa-eye icon-nm m-0 p-0 text-white"></i>
                    </button>
                )
            }
            function editBtn(data) {
                return (
                    <button className="btn btn-sm btn-primary text-center p-2 mx-1" onClick={() => editOnclick(data)} >
                        <i className="fa fa-pencil-alt icon-nm m-0 p-0 text-white"></i>
                    </button>
                )
            }
            function deleteBtn(data) {
                return (
                    <button style={{ margin: 2 }} className="btn btn-sm text-center p-2 mx-1 btn-danger" onClick={() => onDelete ? onDelete(data) : null} >
                        <i className="fa fa-trash-alt icon-nm m-0 p-0 text-white"></i>
                    </button>
                )
            }
            function onRowRemoving(e) {
                const res = Swal.fire({
                    text: 'Are you sure you want to delete this record?',
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire('Deleted!', '', 'success')
                        return false;
                    } else {
                        return true;
                    }
                })
                e.cancel = res;
            }
            // function onInitRow(e) {
            //     const data = refGrid.instance.getDataSource()._items
            //     let max = data.reduce((mx, dt) => {
            //         return dt.No >= mx.No ? dt : mx;
            //     }, {
            //         No: Number.MIN_SAFE_INTEGER
            //     });
            //     e.data.No = max.No + 1;
            // }
            // function addNewRow() {
            //     refGrid.instance.addRow();
            // }
            // function onToolbarPreparing(e) {
            //     let toolbarItems = e.toolbarOptions.items;
            //     toolbarItems.forEach(function (item) {
            //         if (item.name === "addRowButton") {
            //             item.options = {
            //                 icon: "fa fa-plus-square",
            //                 text: "Add New",
            //                 hint: "Add New",
            //                 onClick: addNewRow.bind(this)
            //             }
            //         }
            //     });
            // }
            function addOnclick() {
                setDisable(false)
                setActionEd("add");
                setSelData({
                    "id": "",
                    "research_id": "",
                    "baseline_name": "",
                    "risk_id": "",
                    "tipe_risk": "",
                    "kejadian": "",
                    "penyebab": "",
                    "gejala": "",
                    "faktor_positif": "",
                    "dampak_kual": "",
                    "inher_kuant": "",
                    "inher_probabil": "",
                    "inher_dampak": "",
                    "mit_rencana": "",
                    "mit_biaya": "",
                    "mit_tahun_berlaku": "",
                    "mit_tahun_selesai": "",
                    "res_kuant": "",
                    "res_probabil": "",
                    "res_dampak": "",
                })
                action.toggleModal("Show")
            }
            function viewOnclick(data) {
                // console.log("viewdataa", data.row.key)
                setDisable(true)
                setSelData(data.row.key)
                action.toggleModal("Show")
            }
            function editOnclick(e) {
                // console.log("dataass", e.row.key)
                setDisable(false)
                setIndex(e.row.rowIndex);
                setActionEd("edit");
                setSelData(e.row.key)
                action.toggleModal("Show")
            }
            // function onFinish(e) {
            //     console.log("hasil", e)
            //     const newDs = [...(ds || [])];
            //     if (actionEdit === "edit") {
            //         Object.keys(e).forEach((key) => {
            //             newDs[indexSlected][key] = e[key];
            //         })
            //     } else if (actionEdit === "add") {
            //         newDs.splice(newDs.length, 0, e);
            //     }
            //     setDs(newDs);
            //     action.setForm("data_view_risk", newDs)
            //     refGrid.current.instance.refresh();
            //     action.toggleModal("Show");
            // }
            // function generateId() {
            //     const data = refGrid.current.instance.getDataSource()?._items
            //     let max = data?.reduce((mx, dt) => {
            //         return dt.id >= mx.id ? dt : mx;
            //     }, {
            //         id: Number.MIN_SAFE_INTEGER
            //     });
            //     return (max?.id || 0) + 1;

            // }
            function customCellRender(e) {
                if (e.column.name === 'no') {
                    return e.rowIndex + 1
                }
                else if (e.column.name === "risk_priority") {
                    return (risk_priority(e.data.inher_probabil, e.data.inher_dampak))
                } else if (e.column.name === "risk_priority_residu") {
                    return (risk_priority_residu(e.data.res_probabil, e.data.res_dampak))
                } else if (e.column.name === "inher_probabil") {
                    return (inher_probabil(e.value))
                } else if (e.column.name === "inher_dampak") {
                    return (inher_dampak(e.value))
                } else {
                    return (<span className="d-block text-wrap">{e.value}</span>)
                }
            }
            return (
                <>
                    <Modal
                        name="Show"
                        baseColor={true}
                        size="70%"
                    >
                        {
                            bodyForm(disable, onFinish, selData)
                        }
                    </Modal>
                    <div className="container-body" style={{ paddingLeft: 15, paddingRight: 15 }}>
                        {
                            editable && (
                                <div className="d-flex flex-row-reverse" style={{ paddingBottom: 10 }}><button className="btn btn-primary btn-sm" onClick={addOnclick} style={{ fontSize: '8pt', fontFamily: 'Poppins' }}><i className="fa fa-plus icon-sm text-white" ></i>Risk</button></div>
                            )
                        }
                        <DataGrid
                            ref={ref}
                            key="No"
                            //onInitNewRow={onInitRow}
                            className="striped fontContent"
                            showRowLines={true}
                            showColumnLines={true}
                            rowAlternationEnabled={true}
                            wordWrapEnabled={true}
                            dataSource={dataSource}
                            allowColumnResizing={columnResizing}
                            columnAutoWidth={settingColumn}
                            //onToolbarPreparing={onToolbarPreparing}
                            onRowRemoving={onRowRemoving}
                        >
                            <LoadPanel enabled={false} />
                            <ColumnFixing enabled={false} />
                            <Scrolling />
                            <Editing mode="popup" allowDeleting={true} >
                                <Popup >
                                    <Position of={window} my="center" at="center" />
                                </Popup>
                                <Texts confirmDeleteMessage=""></Texts>
                            </Editing>
                            {
                                collumns.map(({ multiHeader, column, dataField, caption, width = null, minWidth = null, cellRender = (e) => e.value, custom = false, component = <> </>, name = "" }, i) => {
                                    return multiHeader ? (
                                        <Column caption={caption} key={i} headerCellRender={headerRender}>
                                            {
                                                column.map((col, j) => {
                                                    return (
                                                        <Column width={col.width} minWidth={col.minWidth} dataField={col.dataField} caption={col.caption} key={j} alignment="center" cellRender={customCellRender} />
                                                    )
                                                })
                                            }
                                        </Column>
                                    ) : (
                                        <Column key={i} cellRender={customCellRender} width={width} minWidth={minWidth} dataField={dataField} caption={caption} alignment="center" />
                                    )
                                })


                            }
                            <Column caption="Action" type="buttons" headerCellRender={headerRender} >
                                <Button name="view" render={viewBtn} />
                                <Button name="customEdit" visible={editable} render={editBtn} />
                                <Button name="delete" visible={editable} render={deleteBtn} />
                            </Column>
                            <Paging defaultPageSize={1} enabled={false} />
                        </DataGrid>
                    </div>
                </>
            )
        })
);