import React, {  useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import useLab from '../useLaboratory';
import Swal from 'sweetalert2';

function ModalSampleNC(props) {
    const [note, setNotes] = useState("");
    const { labState, labAction } = useLab();

    const onKirim = () => {
        if (note === "") {
            return Swal.fire({
                title: 'Error',
                text: "Harap isi Keterangan / Komentar",
                icon: 'warning',
            })
        }
        labAction.submitSdnc({
            "id": labState.modalSDNC?.defaultData?.id,
            "note": note,
        });
    }

    return (
        <Modal show={labState.modalSDNC?.show} onHide={() => labAction.setModalSDNC({ show: false, default_data: {} })}>
            <Modal.Header closeButton>
                <Modal.Title>
                   Sample Delivery Not Complete
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-sm-4">Tanggal Perubahan Status</label>
                    <div className="col-sm-8">
                        <p>{moment().format("DD-MM-yyyy")}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan / Komentar</label>
                    <div className="col-sm-8">
                        <textarea className='form-control form-control-sm' rows={3}
                            value={note} onChange={e => setNotes(e.target.value)}
                        />
                    </div>
                </div>                
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => onKirim()} className="btn btn-sm btn-success mr-1">Submit</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalSampleNC;