import { Autocomplete, SelectBox } from 'devextreme-react';
import { toString } from 'lodash';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { HeaderList, FileUploadDx } from '../../../components';
import useAddEditKerjasama, { AddEditKerjasamaProvider } from './useAddEditKerjasama';
import '../kerjasamaCRUD.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { countriesGDP, provinsiIndonesia } from '../../../components/utility/utility';

const placeholderCustom = {
    pertam_expected_value: "Value yang ingin didapatkan PERTAMINA dari kerjasama bersama Partner. Dapat berupa kapabilitas teknologi, pasar, biaya, dll.",
    pertam_value_offer: "Value yang dapat diberikan oleh PERTAMINA untuk Partner. Dapat berupa kapabilitas teknologi, pasar, biaya, dll.",
    partner_expected_value: "Value yang ingin didapatkan Partner dari Pertamina melalui kerjasama. Dapat berupa kapabilitas teknologi, pasar, biaya, dll",
    partner_value_offer: "Value yang dapat diberikan Partner untuk Pertamina melalu kerjasama. Dapat berupa kapabilitas teknologi, pasar, biaya, dll",
    aktifitas_kerjasama: "- Kegiatan dan aktivitas kerjasama yang akan dilakukan bersama partner\n- Output yang diharapkan dari aktivitas kerjasama\n- Kebutuhan dalam kegiatan kerjasama"
}

function EditPage(props) {
    const history = useHistory();
    const { addEditKAction: {
        getResources = () => null,
        handleChange = () => null,
        handleChangePartnerContent = () => null,
        deletePartner = () => null,
        deleteReviewer = () => null,
        deleteSection = () => null,
        submitButton = () => null,
        setShowInfo = null,
        OnFileUploaded = () => null
    }, addEditKState: {
        formData = {},
        functSource = [],
        viewerSource = [],
        sectionSource = [],
        optRadioSa = [],
        optRadioCs = [],
        loadingGetSection = false,
        projCluster = [],
        sourceRKAP = [],
        data_users = [],
        data_stream_business = [],
        data_page: {
            page_title = "",
            page_breadcrumbs = ""
        },
        refFile = null
    } } = useAddEditKerjasama();
    const { id } = useParams();
    const { pathname } = useLocation();
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    useEffect(() => {
        getResources(true, id);
    }, [])
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={page_title}
                someText={page_breadcrumbs}
            />
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <Card
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div className='row p-5'>
                                <div className='col-md-6'>
                                    <div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Detail</span>
                                        <hr />
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Judul Kerjasama</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <input className="form-control form-control-sm" placeholder="Judul Kerjasama" value={formData?.judul} onChange={(e) => handleChange(e.target.value, "judul")} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Usulan Dari</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="usulan" id="usulan1" value="1"
                                                            checked={toString(formData?.usulan_dari) === '1' ? true : false}
                                                            onChange={(e) => handleChange(e.target.value, "usulan_dari")}
                                                        />
                                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                                            Top Down
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="usulan" id="usulan2" value="2"
                                                            checked={toString(formData?.usulan_dari) === '2' ? true : false}
                                                            onChange={(e) => handleChange(e.target.value, "usulan_dari")}
                                                        />
                                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                                            Bottom Up
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Nama Partner</div>
                                            <div className="col-md ">
                                                <div className="col p-0">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="input-group mb-2">
                                                                <input className="form-control form-control-sm" placeholder="Ketik nama partner dan tekan tombol '+ Add' "
                                                                    value={formData?.kerjasama_partners_temp}
                                                                    onChange={(e) => handleChange(e.target.value, "kerjasama_partners_temp")}
                                                                />
                                                                <div className="text-right" style={{ width: '65px' }}>
                                                                    <button type="submit" className="btn btn-sm btn-outline-primary"
                                                                        onClick={() => handleChange(formData?.kerjasama_partners_temp, "kerjasama_partners")}
                                                                    >+ Add</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-5 justify-content-end">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}></div>
                                            <div className="col-md">
                                                <ul className='pl-6'>
                                                    {
                                                        (formData.kerjasama_partners || []).map((d, i) => (
                                                            <li key={i} className="mb-1">
                                                                <div className="row px-3  mb-2">
                                                                    <div className="col text-left">
                                                                        <span className="d-block text-left mb-3  font-weight-bold">
                                                                            {d?.nama_partner}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-auto text-md-right">
                                                                        <button className="btn btn-sm btn-outline-danger"
                                                                            onClick={() => deletePartner(i)}
                                                                        >
                                                                            <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <form className='px-5 mb-3'>
                                                                    <div className="form-group row mb-0">
                                                                        <div className='table-responsive'>
                                                                            <table className='table table-bordered' style={{ 'fontSize': '8pt' }}>
                                                                                <thead>
                                                                                    <tr className='bg-light-metal'>
                                                                                        <th scope="col" style={{ 'fontSize': '8pt' }}>
                                                                                            Strategic Advantage
                                                                                        </th>
                                                                                        {/* <th scope="col" style={{ 'fontSize': '8pt' }}>Company Size</th> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='p-5'>
                                                                                            {/* <RadioGrp
                                                                                                modeRow={false}
                                                                                                className="text-content-partners-grp"
                                                                                                withPopup={false}
                                                                                                contentPopups={(props) => <span>{props?.hint}</span>}
                                                                                                onChange={(e) => handleChangePartnerContent(i, e, "score_sa")}
                                                                                                value={d?.score_sa}
                                                                                                data={optRadioSa?.map(d => ({ ...d, label: `(${d?.value}) ${d?.hint}` }))}
                                                                                            /> */}
                                                                                            {
                                                                                                [...(optRadioSa || [])].map((x, j) => (
                                                                                                    <div className="form-check">
                                                                                                        <input className="form-check-input" type="radio" name="partner" id={`partner${j}`} value={x?.value} style={{
                                                                                                            top: '3px',
                                                                                                            position: 'relative',
                                                                                                            marginRight: '4px'
                                                                                                        }}
                                                                                                            checked={d?.score_sa === x?.value}
                                                                                                            onChange={(e) => handleChangePartnerContent(i, e.target.value, "score_sa")}
                                                                                                        />
                                                                                                        <label className="form-check-label" for={`partner${i}`} style={{ fontSize: '8pt' }} >
                                                                                                            {`(${x?.value}) ${x?.hint}`}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <div style={{ marginLeft: '18px', marginTop: '5px' }} className='d-block'>
                                                                                                <span>(1) Memiliki kapabilitas dan reputasi pada riset terkait</span>
                                                                                                <div className="form-check mb-1">
                                                                                                    <input className="form-check-input" type="radio" name="partner" id="partnerq" value="a" style={{
                                                                                                        top: '3px',
                                                                                                        position: 'relative',
                                                                                                        marginRight: '4px'
                                                                                                    }}
                                                                                                        checked={d?.score_sa === "a"}
                                                                                                        onChange={(e) => handleChangePartnerContent(i, e.target.value, "score_sa")}
                                                                                                    />
                                                                                                    <label className="form-check-label" for="partnerq" style={{ fontSize: '8pt' }}>
                                                                                                        (a) Jumlah implementasi proyek
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="form-check mb-1">
                                                                                                    <input className="form-check-input" type="radio" name="partner" id="partnerw" value="b" style={{
                                                                                                        top: '3px',
                                                                                                        position: 'relative',
                                                                                                        marginRight: '4px'
                                                                                                    }}
                                                                                                        checked={d?.score_sa === "b"}
                                                                                                        onChange={(e) => handleChangePartnerContent(i, e.target.value, "score_sa")}
                                                                                                    />
                                                                                                    <label className="form-check-label" for="partnerw" style={{ fontSize: '8pt' }}>
                                                                                                        (b) Fasilitas mendukung
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="form-check mb-1">
                                                                                                    <input className="form-check-input" type="radio" name="partner" id="partnere" value="c" style={{
                                                                                                        top: '3px',
                                                                                                        position: 'relative',
                                                                                                        marginRight: '4px'
                                                                                                    }}
                                                                                                        checked={d?.score_sa === "c"}
                                                                                                        onChange={(e) => handleChangePartnerContent(i, e.target.value, "score_sa")}
                                                                                                    />
                                                                                                    <label className="form-check-label" for="partnere" style={{ fontSize: '8pt' }}>
                                                                                                        (c) Memiliki added value di ekosistem proyek
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        {/* <td className='p-5'>
                                                                                            <RadioGrp
                                                                                                modeRow={false}
                                                                                                withPopup={false}
                                                                                                contentPopups={(props) => <span>{props?.hint}</span>}
                                                                                                onChange={(e) => handleChangePartnerContent(i, e, "score_cs")}
                                                                                                value={d?.score_cs}
                                                                                                className="text-content-partners-grp"
                                                                                                data={optRadioCs?.map(d => ({ ...d, label: `(${d?.value}) ${d?.hint}` }))}
                                                                                            />
                                                                                        </td> */}
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {/* <div className="col-sm-8 my-auto">
                                                                    <div className="form-control border-0" id="status">
                                                                        <RadioGrp
                                                                            modeRow={true}
                                                                            className="text-content-partners-grp"
                                                                            onChange={(e) => handleChangePartnerContent(i, e, "score_sa")}
                                                                            value={d?.score_sa}
                                                                            data={optRadioSa}
                                                                            withPopup={true}
                                                                            contentPopups={(props) => <span>{props?.hint}</span>}
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                                    </div>
                                                                    {/* <div className="form-group row mb-0">
                                                                <label htmlFor="status" className="col-sm-4 col-form-label text-sm-right text-md-right text-lg-right text-content-partners">Company Size :</label>
                                                                <div className="col-sm-8 my-auto">
                                                                    <div className="form-control border-0" id="status">
                                                                        <RadioGrp
                                                                            modeRow={true}
                                                                            onChange={(e) => handleChangePartnerContent(i, e, "score_cs")}
                                                                            value={d?.score_cs}
                                                                            className="text-content-partners-grp"
                                                                            data={optRadioCs}
                                                                            withPopup={true}
                                                                            contentPopups={(props) => <span>{props?.hint}</span>}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                                    <div className="form-group row mb-1">
                                                                        <label htmlFor="status" className="col-sm-4 col-form-label text-sm-right text-md-right text-lg-right text-content-partners"> Country Base :</label>
                                                                        <div className="col-sm-8 my-auto">
                                                                            <div className="form-control border-0" id="status">
                                                                                <SelectBox dataSource={[...(countriesGDP || [])]}
                                                                                    height="30px"
                                                                                    displayExpr="name"
                                                                                    valueExpr="name"
                                                                                    searchEnabled={true}
                                                                                    searchMode={"contains"}
                                                                                    searchExpr={"name"}
                                                                                    searchTimeout={200}
                                                                                    minSearchLength={0}
                                                                                    value={d?.country}
                                                                                    onValueChange={(e) => handleChangePartnerContent(i, e, "country")}
                                                                                    showDataBeforeSearch={false} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        d?.country === 'Indonesia' && (
                                                                            <div className="form-group row mb-0">
                                                                                <label htmlFor="status" className="col-sm-4 col-form-label text-sm-right text-md-right text-lg-right text-content-partners">Province :</label>
                                                                                <div className="col-sm-8 my-auto">
                                                                                    <div className="form-control border-0" id="status">
                                                                                        <SelectBox dataSource={[...(provinsiIndonesia || [])]}
                                                                                            height="30px"
                                                                                            displayExpr="name"
                                                                                            valueExpr="name"
                                                                                            searchEnabled={true}
                                                                                            searchMode={"contains"}
                                                                                            searchExpr={"name"}
                                                                                            searchTimeout={200}
                                                                                            minSearchLength={0}
                                                                                            value={d?.province}
                                                                                            onValueChange={(e) => handleChangePartnerContent(i, e, "province")}
                                                                                            showDataBeforeSearch={false} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </form>
                                                                <hr className='mx-3' />
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row px-3 mb-5">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}> RKAP Berkaitan (opsional)</div>
                                            <div className="col-md">
                                                <div className="input-group mb-2" >
                                                    <Autocomplete
                                                        dataSource={sourceRKAP}
                                                        value={formData?.kerjasama_rkaps_temp_name}
                                                        valueExpr="name"
                                                        displayExpr="name"
                                                        placeholder="Pilih dan tekan tombol '+ Add'"
                                                        onValueChanged={(e) => handleChange(e.value, "kerjasama_rkaps_temp_name")}
                                                        onSelectionChanged={(e) => handleChange(e.selectedItem, "kerjasama_rkaps_temp")}
                                                        className="form-control form-control-sm p-0 mr-1">
                                                    </Autocomplete>
                                                    <div className="text-right" style={{ width: '65px' }}>
                                                        <button type="submit" className="btn btn-sm btn-primary"
                                                            onClick={() => handleChange(formData?.kerjasama_rkaps_temp, "add_rkaps")}
                                                        >+ Add</button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div style={{ paddingBottom: '25px' }}>
                                                            {
                                                                [...(formData?.kerjasama_rkaps || [])]?.map((d, key) => (
                                                                    <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                        <span className="pl-2 text-wrap" style={{ fontSize: '8pt', width: '90%' }}>{d?.show_research}</span>
                                                                        <button className='btn btn-icon btn-danger btn-xs'
                                                                            onClick={() => handleChange(d?.research_id, "delete_rkaps")}
                                                                        >
                                                                            <i className="fa fa-trash-alt icon-nm"
                                                                            ></i>
                                                                        </button>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Stream Business</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <select className="form-control form-control-sm" placeholder="Judul Kerjasama"
                                                        value={formData?.stream_business}
                                                        onChange={(e) => handleChange(e.target.value, "stream_business")}
                                                    >
                                                        {
                                                            [{
                                                                "id": 0,
                                                                "param_name": "duk_operasional",
                                                                "param_id": 0,
                                                                "title": "- Pilih -",
                                                                "title2": null,
                                                                "status": 1
                                                            }, ...(data_stream_business || [])].map((d, i) => (
                                                                <option key={i} value={d.param_id}>{d.title}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        formData?.stream_business === "7" && (
                                                            <input className="form-control form-control-sm mt-3" placeholder="Silahkan isi (Jika klik Others di Stream Business)"
                                                                value={formData?.stream_business_note}
                                                                onChange={(e) => handleChange(e.target.value, "stream_business_note")} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Project Cluster</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <select className="form-control form-control-sm" placeholder="Judul Kerjasama"
                                                        value={formData?.project_cluster}
                                                        onChange={(e) => handleChange(e.target.value, "project_cluster")}
                                                    >
                                                        {
                                                            [{
                                                                "id": 0,
                                                                "param_name": "kerjasama_project_cluster",
                                                                "param_id": 0,
                                                                "title": "- Pilih Project cluster -",
                                                                "title2": "5",
                                                                "status": 1
                                                            }, ...(projCluster || [])].map((d, i) => (
                                                                <option key={i} value={d.param_id}>{d.title}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        formData?.project_cluster === "5" && (
                                                            <input className="form-control form-control-sm mt-3" placeholder="Silahkan isi (Jika klik Others di Project Cluster)"
                                                                value={formData?.project_cluster_note}
                                                                onChange={(e) => handleChange(e.target.value, "project_cluster_note")} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Lokasi Project</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <textarea rows={4} className='form-control'
                                                        value={formData?.lokasi_proyek}
                                                        onChange={(e) => handleChange(e.target.value, "lokasi_proyek")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Aktivitas Kerjasama</span>
                                        <hr />
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Pertamina Expected Value</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <textarea className="form-control" rows={4} placeholder={placeholderCustom.pertam_expected_value}
                                                        value={formData?.pertam_expected_value}
                                                        onChange={(e) => handleChange(e.target.value, "pertam_expected_value")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Pertamina Value Offer</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <textarea className="form-control" rows={4} placeholder={placeholderCustom.pertam_value_offer}
                                                        value={formData?.pertam_value_offer}
                                                        onChange={(e) => handleChange(e.target.value, "pertam_value_offer")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Partner Expected Value</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <textarea className="form-control" rows={4} placeholder={placeholderCustom.partner_expected_value}
                                                        value={formData?.partner_expected_value}
                                                        onChange={(e) => handleChange(e.target.value, "partner_expected_value")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Partner Value Offer</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <textarea className="form-control" rows={4} placeholder={placeholderCustom.partner_value_offer}
                                                        value={formData?.partner_value_offer}
                                                        onChange={(e) => handleChange(e.target.value, "partner_value_offer")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Aktivitas Kerjasama</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <textarea className="form-control" rows={4} placeholder={placeholderCustom.aktifitas_kerjasama}
                                                        value={formData?.aktifitas_kerjasama}
                                                        onChange={(e) => handleChange(e.target.value, "aktifitas_kerjasama")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Pengelola</span>
                                        <hr />
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Fungsi VP</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <select className="form-control form-control-sm" placeholder="Judul Kerjasama"
                                                        value={formData?.kerjasama_fungsies}
                                                        onChange={(e) => handleChange(e.target.value, "kerjasama_fungsies")}
                                                    >
                                                        <option value="">- Pilih Fungsi VP -</option>
                                                        {
                                                            (functSource || []).map((d, i) => (
                                                                <option key={i} value={d.value}>{d.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Fungsi Manager / Riset</div>
                                            <div className="col-md ">
                                                <div className="col p-0">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="input-group mb-2">
                                                                <select className='form-control form-control-sm'
                                                                    value={formData?.kerjasama_section_temp?.id || ''}
                                                                    onChange={(e) => handleChange([...(sectionSource || [])].find(d => toString(d?.id) === toString(e.target.value)), "kerjasama_section_temp")}
                                                                >
                                                                    <option>{loadingGetSection ? "Loading..." : "Pilih dan tekan tombol '+ Add'"}</option>
                                                                    {
                                                                        [...(sectionSource || [])].map((d, i) => (
                                                                            <option key={i} value={d?.id}>{d?.short_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {/* <Autocomplete dataSource={sectionSource}
                                                            value={formData?.kerjasama_section_temp_name}
                                                            placeholder={loadingGetSection ? "Loading..." : "Pilih dan tekan tombol '+ Add'"}
                                                            valueExpr="name"
                                                            displayExpr="name"
                                                            onValueChanged={(e) => handleChange(e.value, "kerjasama_section_temp_name")}
                                                            onSelectionChanged={(e) => handleChange(e.selectedItem, "kerjasama_section_temp")}
                                                            className="form-control form-control-sm p-0 mr-1">
                                                        </Autocomplete> */}
                                                                <div className="text-right" style={{ width: '65px' }}>
                                                                    <button type="submit" className="btn btn-sm btn-outline-primary" onClick={() => handleChange(formData?.kerjasama_section_temp, "kerjasama_section")}>+ Add</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}></div>
                                            <div className="col-md ">
                                                <ul>
                                                    {
                                                        [...(formData?.kerjasama_section || [])].map((d, i) => (
                                                            <li key={i}>
                                                                <div className="row mb-1">
                                                                    <div className="col">
                                                                        <span className="d-block text-left mb-3 font-weight-bold">
                                                                            {d.show_chief_manager}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <button className="btn btn-sm btn-outline-danger"
                                                                            onClick={() => deleteSection(d?.chief_manager_id)}
                                                                        >
                                                                            <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row px-3">
                                            <div className="col-md-3 text-left" style={{ fontSize: '8pt' }}>Nama PIC</div>
                                            <div className="col-md ">
                                                <div className="form-group">
                                                    <select className="form-control form-control-sm" placeholder="Judul Kerjasama"
                                                        value={formData?.pic}
                                                        onChange={(e) => handleChange(e.target.value, "pic")}
                                                    >
                                                        {
                                                            [{
                                                                "id": '0',
                                                                "name": "- Pilih -"
                                                            }, ...(data_users || [])].map((d, i) => (
                                                                <option key={i} value={d?.id}>{d?.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="d-block text-left" style={{ fontWeight: 'bold', fontSize: '10pt' }}>Dokumen Pendukung (Pemilihan Partner Terpilih)(Optional)</span>
                                        <hr />
                                        <div className="row px-3">
                                            <div className="col-md-3" style={{ fontSize: '8pt' }}>
                                                Dokumen
                                            </div>
                                            <div className="col-md">
                                                <div className='mb-3'>
                                                    <FileUploadDx
                                                        ref={refFile}
                                                        className="custom-dx-fileuploader-file-name"
                                                        showFileList={true}
                                                        multiple={false}
                                                        selectButtonText="Select File"
                                                        readyToUploadMessage=" "
                                                        labelText="or Drop file hire | Max size 10 Mb"
                                                        accept="*"
                                                        onUploaded={OnFileUploaded}
                                                        maxFileSize={15000000}
                                                        uploadMode="instantly"
                                                    />
                                                </div>
                                                {
                                                    formData?.file_name && (
                                                        <div className="d-flex flex-row justify-content-between mb-1">
                                                            <span className="pl-2 text-wrap" style={{ fontSize: '8pt', width: '90%' }}>{formData?.file_name}</span>
                                                            <button className='btn btn-icon btn-danger btn-xs'
                                                                onClick={() => handleChange(null, "delete_file")}
                                                            >
                                                                <i className="fa fa-trash-alt icon-nm"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="p-2 bg-white rounded shadow-lg floating-footer" style={path === '/admin/update/partnership-edit' ? {
                'left': 0,
                'width': '100%'
            } : {}}>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-sm btn-secondary mx-1" onClick={() => history.goBack()}>Kembali</button>
                        <button className="btn btn-success btn-sm mx-1 float-md-right float-lg-right"
                            onClick={() => submitButton(true)}
                        ><i class="fa fa-save icon-nm"></i> Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (props) => <AddEditKerjasamaProvider><EditPage /></AddEditKerjasamaProvider>;