import { FileUploader } from 'devextreme-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FileUploadDx, FormResearch, RadioGrp } from '../../components';
import { fileExtensions } from '../../components/utility/utility';
import useCrudEquipment from './useCrudEquipment';

const ModalEditingEquipment = ({ mode = 'Add', id_edit = '0' }) => {
    const { ceqAction, ceqState } = useCrudEquipment()

    useEffect(() => {
        if (ceqState.globalState?.modal?.modalEquipment === true) {
            //console.log('testtt', mode, id_edit);
            ceqAction.resetEditorEquipment();
            ceqAction.getEdit(id_edit);
        }
    }, [ceqState.globalState?.modal?.modalEquipment])
    return (
        <div>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: false,
                        childs: [
                            {
                                type: "text",
                                label: "Nomor Asset",
                                placeholder: "Nomor Asset",
                                value: ceqState.nomor,
                                onChange: (val) => ceqAction.setNomor(val)
                            },
                            {
                                type: "text",
                                label: "Nomor Seri",
                                placeholder: "Nomor Seri",
                                value: ceqState.nomorSeri,
                                onChange: (val) => ceqAction.setNomorSeri(val)
                            },
                            {
                                type: "text",
                                label: "Nama Alat",
                                placeholder: "Nama Alat",
                                value: ceqState.nama,
                                onChange: (val) => ceqAction.setNama(val)
                            },
                            {
                                type: "select",
                                label: "Tipe Alat",
                                placeholder: "Nomor Alat",
                                options: [{ name: '- Pilih tipe alat -', value: '' }, ...[...(ceqState.data_edit?.data_tipe || [])].map(d => { return { name: d?.title, value: d?.param_id } })],
                                value: ceqState.equipment_type,
                                onChange: (val) => ceqAction.setEquipment_type(val)
                            },
                            {
                                type: "text",
                                label: "Merk",
                                placeholder: "Merk",
                                value: ceqState.merk,
                                onChange: (val) => ceqAction.setMerk(val)
                            },
                            {
                                type: "textarea",
                                label: "Deskripsi Alat (Kegunaan)",
                                placeholder: "Deskripsi..",
                                value: ceqState.deskripsi,
                                onChange: (val) => ceqAction.setDeskripsi(val)
                            },
                            {
                                type: "textarea",
                                label: "Utilitas Pendukung",
                                placeholder: "Utilitas Pendukung",
                                value: ceqState.utilitas,
                                onChange: (val) => ceqAction.setUtilitas(val)
                            },
                            {
                                type: "textarea",
                                label: "Supporting Equipment",
                                placeholder: "Supporting Equipment",
                                value: ceqState.supporting,
                                onChange: (val) => ceqAction.setSupporting(val)
                            },

                        ]
                    },
                    {
                        rows: 2,
                        header: false,
                        childs: [
                            {
                                type: "custom",
                                label: "Daya Listrik (Watt)",
                                component: (
                                    <div className="input-group input-group-sm mb-5">
                                        <input type="number" min={0} className="form-control" placeholder="Daya Listrik (Watt)"
                                            value={ceqState.daya_listrik}
                                            onChange={(e) => ceqAction.setDaya_listrik(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">(Watt)</span>
                                        </div>
                                    </div>
                                )
                            },
                            // {
                            //     type: "text",
                            //     label: "Daya Listrik (Watt)",
                            //     placeholder: "Daya Listrik (Watt)",
                            //     value: ceqState.daya_listrik,
                            //     onChange: (val) => ceqAction.daya_listrik(val)
                            // },
                            {
                                type: "custom",
                                label: "Tahun Pembelian",
                                component: (
                                    <input type='number' placeholder='Minimum tahun pembelian 1990' min={1990} max={moment(new Date()).format('YYYY')} className="form-control form-control-sm mb-5"
                                        value={ceqState.tahun_pembelian}
                                        onChange={(e) => ceqAction.setTahun_pembelian(e.target.value)}
                                    ></input>
                                )
                            }
                            ,
                            {
                                type: "custom",
                                label: "Harga Alat (USD)",
                                component: (
                                    <div className="input-group input-group-sm mb-5">
                                        <input type="number" min={0} className="form-control" placeholder="Harga Alat"
                                            value={ceqState.harga_alat}
                                            onChange={(e) => ceqAction.setHarga_alat(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">$ (USD)</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "PC",
                                placeholder: "",
                                component: (
                                    <div className='mb-3'>
                                        <RadioGrp
                                            modeRow={true}
                                            onChange={(e) => ceqAction.setPC(e)}
                                            value={ceqState.pc}
                                            data={[
                                                { label: 'Ada', value: '1' },
                                                { label: 'Tidak Ada', value: '0' },
                                            ]}
                                        />
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Lingkup Akreditasi ISO 17025",
                                placeholder: "",
                                component: (
                                    <div className='mb-3'>
                                        <RadioGrp
                                            modeRow={true}
                                            onChange={(e) => ceqAction.setIso(e)}
                                            value={ceqState.iso}
                                            data={[
                                                { label: 'Ya', value: '1' },
                                                { label: 'Tidak', value: '0' },
                                            ]}
                                        />
                                    </div>
                                )
                            },
                            {
                                type: "textarea",
                                label: "Informasi Tambahan",
                                placeholder: "Contoh : Durasi maintenance, cara penanganan jika rusak",
                                value: ceqState.information,
                                onChange: (val) => ceqAction.setInformation(val)
                            },
                            {
                                type: "custom",
                                label: "Dokument Terkait (Buku manual dll)",
                                component: (
                                    <div>
                                        {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                            onValueChanged={(e) => ceqAction.fileOnchangeFile(e)}
                                            showFileList={false} multiple={false}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000} uploadMode="useForm" >
                                        </FileUploader> */}
                                        <FileUploadDx
                                            className="custom-dx-fileuploader-file-name"
                                            showFileList={true}
                                            multiple={false}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb"
                                            accept="*"
                                            onUploaded={(e) => ceqAction.onUploadedFile(e)}
                                            maxFileSize={10000000}
                                            uploadMode="instantly"
                                        />
                                        {
                                            [...(ceqState.list_dokumen || [])].map(d => (
                                                <div className="d-flex flex-row justify-content-between mb-1">
                                                    <span style={{ fontSize: '8pt' }}>{d?.file_doc_name}</span>
                                                    <div>
                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => ceqAction.deleteList_document(d)}></i>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Foto Alat",
                                component: (
                                    <div>
                                        {/* <FileUploader className="custom-dx-fileuploader-file-name"
                                            showFileList={false} multiple={false}
                                            onValueChanged={(e) => ceqAction.fileOnchangePhoto(e)}
                                            selectButtonText="Select File"
                                            readyToUploadMessage=" "
                                            allowedFileExtensions={fileExtensions}
                                            accept="image/*"
                                            labelText="or Drop file hire | Max size 10 Mb" maxFileSize={10000000} uploadMode="useForm" >
                                        </FileUploader>                                         */}
                                        <FileUploadDx
                                            className="custom-dx-fileuploader-file-name"
                                            showFileList={true}
                                            multiple={false}
                                            selectButtonText="Select File"
                                            allowedFileExtensions={fileExtensions}
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb"
                                            accept="image/*"
                                            onUploaded={(e) => ceqAction.onUploadedCover(e)}
                                            maxFileSize={10000000}
                                            uploadMode="instantly"
                                        />
                                        {
                                            ceqState.file_foto && (
                                                <div className="d-flex flex-row justify-content-between mb-1">
                                                    <span style={{ fontSize: '8pt' }}>{ceqState.file_foto}</span>
                                                    <div>
                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => ceqAction.setFile_foto('')}></i>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className='border-bottom mb-3'></div>
                                )
                            },
                            {
                                type: "select",
                                label: "PIC",
                                placeholder: "PIC",
                                options: [{ name: '- Pilih PIC -', value: '' }, ...[...(ceqState.data_edit?.data_users || [])].map(d => { return { name: d?.name, value: d?.id } })],
                                value: ceqState.penanggung_jawab,
                                disabled: (ceqState.globalState?.dataUser?.role_name || '').split(' | ')?.filter(d => ['Admin Equipment', 'Administrator'].includes(d))?.length > 0 ? false : true,
                                onChange: (val) => ceqAction.setPenanggung_jawab(val)
                            },
                            {
                                type: "select",
                                label: "Lokasi",
                                placeholder: "Lokasi",
                                options: [{ name: '- Pilih Lokasi -', value: '' }, ...[...(ceqState.data_edit?.data_lokasi || [])].map(d => { return { name: d?.title, value: d?.param_id } })],
                                value: ceqState.equipment_loc,
                                onChange: (val) => ceqAction.setEquipment_loc(val)
                            },
                            {
                                type: "select",
                                label: "Lab",
                                placeholder: "Lab",
                                options: [{ name: '- Pilih Lab -', value: '' }, ...[...(ceqState.data_edit?.data_lab || [])].map(d => { return { name: d?.title, value: d?.param_id } })],
                                value: ceqState.equipment_lab,
                                onChange: (val) => ceqAction.setEquipment_lab(val)
                            },
                        ]
                    }
                ]}
            />
            <hr />
            <div className="d-block text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={() => ceqAction.globalAction.toggleModal('modalEquipment')}
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-primary mx-1"
                    onClick={() => ceqAction.simpanClick()}
                >
                    <i className="fa fa-save icon-nm"></i>
                    Simpan
                </button>
            </div>
        </div>
    );
}

export default ModalEditingEquipment;