import React from 'react';
import useEquipment, { EqProvider } from './useEquipment';
import { HeaderList, Tab, TabContent } from '../../components';
import DashboardEquipment from './dashboard';
import ListEquipment from './list';
import './equipment.css';
import { CrudEquipmentProvider } from '../crudEquipment/useCrudEquipment';

function EqPage() {
    const { eqAction, eqState } = useEquipment();

    return (
        <>    <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={eqState.data_page?.page_title}
                someText={eqState.data_page?.page_breadcrumbs}
            />
            <div className="row">
                <div className="col-12">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "dashboard",
                                title: "Dashboard",
                                onClick: () => eqAction.tabChange('dashboard')
                            },
                            {
                                id: "table",
                                title: "Equipment Table",
                                onClick: () => eqAction.tabChange('table')
                            },
                        ]}
                        manualActive={eqState.menu}
                    >
                        <TabContent id="dashboard">
                            <DashboardEquipment />
                        </TabContent>
                        <TabContent id="table">
                            <ListEquipment editing={false} />
                        </TabContent>
                    </Tab>
                </div>
            </div>
        </div>
        </>
    )
}

export default () =>
    <CrudEquipmentProvider> <EqProvider><EqPage /></EqProvider></CrudEquipmentProvider>


