import { baseUrl } from "../app.config";

const fetchApi = async function (
    url,
    opts = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    },
    typeResponse = "json"
) {
    if(opts.headers === undefined)
    {
        opts = { ...opts, headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }}
    }
    var data = [];
    var fetchResponse = await fetch(`${baseUrl + url}`, opts)
    if (fetchResponse.status !== 200) {
        return {
            statusCode: fetchResponse.status,
            message: `HTTP request error ${fetchResponse.status}. Reason: ${fetchResponse.statusText}`
        }
    } else {
        if(typeResponse == "blob"){
            data = await fetchResponse.blob()
        }else{
            data = await fetchResponse.json()
        }
        return data;
    }
}

export default fetchApi;
