import React, { useRef } from 'react'
import { GridDx } from '../../components';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import moment from 'moment';
import usePeopleReport from './usePeopleReporting';

export default function TableReportPeople() {
    const tableRef = useRef();
    const { pReportAction, pReportState } = usePeopleReport();

    return (
        <>
            {/* <HeaderList
                title={pReportState.data_page?.page_title}
                someText={pReportState.data_page?.page_breadcrumbs}
            ></HeaderList> */}
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/svg/open-people.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total People</span>
                                    <span className="d-block"><strong>{pReportState.data?.data_box?.total}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-success" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PWTT</span>
                                    <span className="d-block"><strong>{pReportState.data?.data_box?.active}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Mitra Kerja</span>
                                    <span className="d-block"><strong>{pReportState.data?.data_box?.contract}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-purple" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PWT</span>
                                    <span className="d-block"><strong>{pReportState.data?.data_box?.pwt}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Retired</span>
                                    <span className="d-block"><strong>{pReportState.data?.data_box?.retired}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Non Active</span>
                                    <span className="d-block"><strong>{pReportState.data?.data_box?.non_active}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5 px-5">
                        <div className="col-md-12">
                            <GridDx
                                ref={tableRef}
                                selectionMode="multiple"
                                exportMode="selection"
                                exportExcel={true}
                                settingColumn={true}
                                searchForm={true}
                                data={pReportState.data?.data_table}
                                cellRender={pReportAction.customCellRender}
                                filenameExport={`People Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                columns={pReportState.columns}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >

                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}