import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import SVG from "react-inlinesvg";
import Card from 'react-bootstrap/Card';
import { ChartDx, CstmFilterCahrtDx, DoughnatDx, GaugeChart, SwitchBtn } from '../../components';
import useKejasama from './useKerjasama';
import MapIndonesia from './component/map-indonesia';
import MapWorld from './component/map-world';

function TabDashboard(props) {
    const { krjState, krjAction } = useKejasama();
    const [sw1, setSw1] = useState(false);
    const [sw2, setSw2] = useState(false);
    const [sw3, setSw3] = useState(false);

    useEffect(() => {
        krjAction.getDashboard();
    }, [])
    function customTextLegend(text = '') {
        var result = '';
        const lines = text.split(' ');
        var bantu = result;
        lines.forEach((t, i) => {
            if ((bantu + `${t} `).length > 20) {
                result = result + `${i === 0 ? `(${t})` : t}<br/>`
                bantu = ''
            } else {
                bantu = bantu + `${t} `
                result = result + `${i === 0 ? `(${t})` : t} `
            }
        })
        return result
    }
    return (
        <>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-3">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-3 col-md-3 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded icon-header shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Design/Difference.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block text-wrap">Total dokumen partnership yang telah disahkan</span>
                                    <span className="d-block"><strong>
                                        {[...(krjState.dashboard_box || [])].reduce((tot, val) => tot = tot + val?.jumlah, 0)}
                                        {/* {krjState.header?.jumlahTotal} */}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[0] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">MoU</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahMou} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 1)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[1] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NDA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahNDA} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 2)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[2] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PKS</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahPKS} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 3)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[3] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">JSA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahJSA} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 4)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[4] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">HoA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahHoa} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 5)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className='row mb-5'>
                <div className='col-md-6 mb-3 h-100'>
                    <CstmFilterCahrtDx
                        title='Lokasi Partner di Dunia'
                        subtitle='Lokasi partnership di seluruh dunia'
                    >
                        <MapWorld nameField="country" valueField="jumlah" dataPartnership={(krjState.data_chart6?.data_chart_country || [])} />
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-md-6 h-100'>
                    <CstmFilterCahrtDx
                        title='Lokasi Partner di Indonesia'
                        subtitle='Lokasi partner di Indonesia berdasarkan provinsi'
                    >
                        <MapIndonesia nameField="province" valueField="jumlah" dataPartnership={(krjState.data_chart6?.data_chart_province || [])} />
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-4 mb-5">
                    <CstmFilterCahrtDx
                        title='Dokumen partnership perfungsi'
                        subtitle='Jumlah dokumen partnership yang telah disahkan berdasarkan fungsi'
                        option={[...(krjState.data_chart1?.data_function || [])].map(d => ({ name: d?.short_name, value: d?.id }))}
                        onChange={(val) => krjAction.getChart1(val)}
                        customOption={
                            <SwitchBtn className='my-2' value={sw1} onChange={(e) => setSw1(e)} />
                        }
                    >
                        <ChartDx
                            withTitle={false}
                            showLegend={true}
                            toolsExport={false}
                            argumentField="short_name"
                            type={sw1 === true ? "stackedBar" : "bar"}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="right"
                            dataSource={krjState.data_chart1?.data_chart}
                            yAxisArgs={sw1 === true ? [
                                {
                                    valueField: 'MoU',
                                    name: 'MoU',
                                    color: krjState.color[0]
                                },
                                {
                                    valueField: 'NDA',
                                    name: 'NDA',
                                    color: krjState.color[1]
                                },
                                {
                                    valueField: 'PKS',
                                    name: 'PKS',
                                    color: krjState.color[2]
                                },
                                {
                                    valueField: 'JSA',
                                    name: 'JSA',
                                    color: krjState.color[3]
                                },
                                {
                                    valueField: 'HoA',
                                    name: 'HoA',
                                    color: krjState.color[4]
                                }
                            ] : [
                                {
                                    name: 'Total',
                                    valueField: 'total',
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-4 mb-5">
                    <CstmFilterCahrtDx
                        title='Dokumen Partnership yg telah disahkan'
                        subtitle='Jumlah dokumen partnership yang telah disahkan dalam 5 tahun terakhir'
                        option={[...(krjState.data_chart2?.data_dok_status || [])].map(d => ({ name: d?.title, value: d?.param_id }))}
                        onChange={(val) => krjAction.getChart2(val)}
                        customOption={
                            <SwitchBtn className='my-2' value={sw2} onChange={(e) => setSw2(e)} />
                        }
                    >
                        <ChartDx
                            withTitle={false}
                            showLegend={true}
                            toolsExport={false}
                            argumentField="tahun"
                            type={sw2 === true ? "stackedBar" : "bar"}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="right"
                            dataSource={[...(krjState.data_chart2?.data_chart || [])].map(d => ({ ...d, tahun: d?.tahun?.toString() }))}
                            yAxisArgs={sw2 === true ? [
                                {
                                    valueField: 'MoU',
                                    name: 'MoU',
                                    color: krjState.color[0]
                                },
                                {
                                    valueField: 'NDA',
                                    name: 'NDA',
                                    color: krjState.color[1]
                                },
                                {
                                    valueField: 'PKS',
                                    name: 'PKS',
                                    color: krjState.color[2]
                                },
                                {
                                    valueField: 'JSA',
                                    name: 'JSA',
                                    color: krjState.color[3]
                                },
                                {
                                    valueField: 'HoA',
                                    name: 'HoA',
                                    color: krjState.color[4]
                                }
                            ] : [
                                {
                                    name: 'Total',
                                    valueField: 'total',
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-4 mb-5">
                    <CstmFilterCahrtDx
                        title='Dokumen partnership yang akan berakhir'
                        subtitle='Jumlah dokumen partnership yang akan berakhir 5 tahun kedepan'
                        option={[...(krjState.data_chart3?.data_dok_status || [])].map(d => ({ name: d?.title, value: d?.param_id }))}
                        onChange={(val) => krjAction.getChart3(val)}
                        customOption={
                            <SwitchBtn className='my-2' value={sw3} onChange={(e) => setSw3(e)} />
                        }
                    >
                        <ChartDx
                            withTitle={false}
                            showLegend={true}
                            toolsExport={false}
                            argumentField="tahun_string"
                            type={sw3 === true ? "stackedBar" : "bar"}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="right"
                            dataSource={[...(krjState.data_chart3?.data_chart || [])].map(d => ({ ...d, tahun: d?.tahun?.toString() }))}
                            yAxisArgs={sw3 === true ? [
                                {
                                    valueField: 'MoU',
                                    name: 'MoU',
                                    color: krjState.color[0]
                                },
                                {
                                    valueField: 'NDA',
                                    name: 'NDA',
                                    color: krjState.color[1]
                                },
                                {
                                    valueField: 'PKS',
                                    name: 'PKS',
                                    color: krjState.color[2]
                                },
                                {
                                    valueField: 'JSA',
                                    name: 'JSA',
                                    color: krjState.color[3]
                                },
                                {
                                    valueField: 'HoA',
                                    name: 'HoA',
                                    color: krjState.color[4]
                                }
                            ] : [
                                {
                                    name: 'Total',
                                    valueField: 'total',
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-4 mb-3">
                    <CstmFilterCahrtDx
                        title='Status Tahapan'
                        subtitle='jumlah dokumen yang telah disahkan berdasarkan jangka waktu'
                        option={[...(krjState.data_chart4?.data_dok_tipe || [])].map(d => ({ name: d?.title, value: d?.param_id }))}
                        onChange={(val) => krjAction.getChart4(val)}
                    >
                        <DoughnatDx
                            withTitle={false}
                            dataSource={[...(krjState.data_chart4?.data_chart || [])]}
                            showLegend={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="top"
                            toolsExport={false}
                            backgroundColorLabel={'none'}
                            connectorVisible={true}
                            onRenderLabelChart={(e) => (`${e.argumentText}\n${e.valueText}`)}
                            onRenderTooltip={(e) => ({ text: `${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                            yAxisArgs={[
                                {
                                    valueField: "jumlah",
                                    argumentField: "dok_status",
                                    color: null,
                                    format: null
                                }
                            ]}

                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-4 mb-3">
                    <CstmFilterCahrtDx
                        title='Jangka Waktu'
                        subtitle='Jumlah dokumen berdasarkan jangka waktu'
                        option={[...(krjState.data_chart5?.data_dok_status || [])].map(d => ({ name: d?.title, value: d?.param_id }))}
                        onChange={(val) => krjAction.getChart5(val)}
                    >
                        <ChartDx
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            argumentField="state"
                            onrenderLabel={(e) => krjAction.renderLabelChart5(e.value)}
                            onRenderTooltip={(arg) => ({ text: `${krjAction.renderLabelChart5(arg.argumentText)}: ${arg.valueText}` })}
                            dataSource={[...(krjState.data_chart5?.data_chart || [])].map(d => ({ ...d, jangka_waktu: d?.jangka_waktu?.toString() }))}
                            yAxisArgs={[
                                {
                                    valueField: 'jumlah',
                                    color: krjState.filter_chart5 === '5' ? '#3699FF' : krjState.filter_chart5 == '6' ? '#fd7e14' : '#1BC5BD'
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-4 mb-3">
                    <CstmFilterCahrtDx
                        title='Project Cluster'
                        subtitle='Jumlah kerjasama berdasarkan cluster'
                    >
                        <ChartDx
                            height={300}
                            withTitle={false}
                            showLegend={true}
                            toolsExport={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="right"
                            colorBarDifferent={true}
                            orientationLegend='vertical'
                            argumentField="name"
                            colorBarDiffirentField="name"
                            coloBarDifferentValueField="jumlah"
                            onrenderLabel={(e) => `(${(e.value || '')?.split('|')[0]})`}
                            onrenderLegendText={(e) => customTextLegend(e.seriesName?.replace('|', ' '))}
                            onRenderTooltip={(arg) => ({ text: `${(arg.argumentText || '').split('|')[1]}: ${arg.valueText}` })}
                            dataSource={[...(krjState.data_chart_cluster?.data_chart || [])].map((d, i) => ({ ...d, name: `${String.fromCharCode(i + 65)}|${d?.name}`, jangka_waktu: d?.jangka_waktu?.toString() }))}
                        />
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-md-4 mb-3'>
                    <CstmFilterCahrtDx
                        title='Usulan Partnership'
                        subtitle=''
                    >
                        <DoughnatDx
                            withTitle={false}
                            dataSource={krjState.data_chart7 || []}
                            showLegend={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="top"
                            toolsExport={false}
                            backgroundColorLabel={'none'}
                            connectorVisible={true}
                            onRenderLabelChart={(e) => (`${e.argumentText}\n${e.valueText}`)}
                            onRenderTooltip={(e) => ({ text: `${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                            yAxisArgs={[
                                {
                                    valueField: "jumlah",
                                    argumentField: "name",
                                    color: null,
                                    format: null
                                }
                            ]}

                        />                        
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-md-4 mb-3'>
                    <CstmFilterCahrtDx
                        title='Monitoring Status Partnership'
                        subtitle=''
                    >
                        <ChartDx
                            withTitle={false}
                            showLegend={true}
                            toolsExport={false}
                            argumentField="name"
                            type={"bar"}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="right"
                            onRenderTooltip={(arg) => ({ text: `${(arg.argumentText || '')}: ${arg.valueText}` })}
                            dataSource={krjState.data_chart8 || []}
                            yAxisArgs={[
                                {
                                    name: 'Jumlah',
                                    valueField: 'jumlah',
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className='col-md-4 mb-3'>
                    <CstmFilterCahrtDx
                        title='Monitoring Kendala Kerjasama'
                        subtitle=''
                    >
                        <ChartDx
                            withTitle={false}
                            showLegend={true}
                            toolsExport={false}
                            argumentField="name"
                            type={"bar"}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="right"
                            onRenderTooltip={(arg) => ({ text: `${(arg.argumentText || '')}: ${arg.valueText}` })}
                            dataSource={krjState.data_chart9 || []}
                            yAxisArgs={[
                                {
                                    name: 'Jumlah',
                                    valueField: 'jumlah',
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
            </div>
        </>
    );
}

export default TabDashboard;