import React, { createContext, useContext } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';

const profileContext = createContext({
    profileState:{},
    profileAction:{}
})

export function ProfileProvider({children}){
    return<profileContext.Provider value={{
        profileState:{},
        profileAction:{}
    }}>{children}</profileContext.Provider>

}


export default function useProfile(){
    return useContext(profileContext);
}