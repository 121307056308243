import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormResearch, GridDx, Modal } from '../../components';
import ModalEditorKontrak from '../crudSwakelola/modalEditorKontrak';
import { Button, Column } from 'devextreme-react/data-grid';
import useSwakelola from './useSwakelola';
import ModalEditorAddendum from '../crudSwakelola/modalEditorAddendum';

const column_kontrak = [
    { fieldName: 'tanggal', caption: 'Tanggal', allowFiltering: false },
    { fieldName: 'periode', caption: 'Periode Proyek', allowFiltering: false },
    { fieldName: 'nilai_kontrak', caption: 'Nilai Kontrak (Juta IDR)', allowFiltering: false },
    { fieldName: 'dok_pen', caption: 'Dokumen Pendukung', allowFiltering: false },
    { fieldName: 'note', caption: 'Note', allowFiltering: false },
]
const column_addendum = [
    { fieldName: 'tanggal', caption: 'Tanggal', allowFiltering: false },
    { fieldName: 'nilai_adendum', caption: 'Nilai Addendum (Juta IDR)', allowFiltering: false },
    { fieldName: 'update_nilai_kontrak', caption: 'Nilai Kontrak (Juta IDR)', allowFiltering: false },
    { fieldName: 'periode', caption: 'Periode Proyek', allowFiltering: false },
    { fieldName: 'dok_pen', caption: 'Dokumen Pendukung', allowFiltering: false },
    { fieldName: 'note', caption: 'Note', allowFiltering: false },
]
const DetailTabDetail = (props) => {
    const { id } = useParams();
    const {
        swaAction,
        swaState
    } = useSwakelola();

    return (
        <div>
            <Modal
                name="modalEditorKontrak"
                baseColor={false}
            >
                <ModalEditorKontrak mode={swaState.modeEditorKontrak} id_swakelola={id} data={swaState.dataEditorKontrak} />
            </Modal>
            <Modal
                name="modalEditorAddendum"
                baseColor={false}
            >
                <ModalEditorAddendum mode={swaState.modeEditorAddendum} id_swakelola={id} data={swaState.dataEditorAddendum} nilai_kontrak_terbaru={swaState.dataDetail?.dataSwakelola?.nilai_kontrak_terbaru} start_date={swaState.dataDetail?.dataSwakelola?.show_start} />
            </Modal>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: true,
                        name: "Detail",
                        childs: [
                            {
                                type: "custom",
                                label: "Judul Proyek Swakelola",
                                component: (
                                    <div className="mb-5">
                                        <span className="d-block">{swaState.dataDetail?.dataSwakelola?.judul}</span>
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Pihak ke III",
                                component: (
                                    <div className="mb-5">
                                        <ul className="mb-5">
                                            {
                                                [...(swaState.dataDetail?.dataSwakelola?.nama_pihak3 || [])].map((d, i) => (
                                                    <li>{d.nama}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "PIC",
                                component: (
                                    <div className="mb-5">
                                        <span className="d-block">{swaState.dataDetail?.dataSwakelola?.nama_PIC}</span>
                                    </div>
                                )
                            },
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: "Fungsi",
                        childs: [
                            {
                                type: "custom",
                                label: "Fungsi",
                                component: (
                                    <div className="mb-5">
                                        <span className="d-block">{swaState.dataDetail?.dataSwakelola?.fungsi}</span>
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Manager",
                                component: (
                                    <div className="mb-5">
                                        <span className="d-block">{swaState.dataDetail?.dataSwakelola?.nama_section}</span>
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Persetujuan Oleh",
                                component: (
                                    <div className="mb-5">
                                        <span className="d-block">{swaState.dataDetail?.dataSwakelola?.nama_persetujuan}</span>
                                    </div>
                                )
                            },
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: "Proyek Berkaitan",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="mb-5">
                                        <ul>
                                            {
                                                [...(swaState.dataDetail?.dataSwakelola?.related_list || [])].map((d, i) => (
                                                    <>
                                                        {
                                                            d?.research_id && (
                                                                <Link to={`/OngoingResearch/view/${d?.research_id}`} target={'_blank'}>
                                                                    <li className="text-wrap p-2">{d.title}</li>
                                                                </Link>
                                                            )
                                                        }
                                                    </>
                                                ))
                                            }
                                        </ul>
                                        <div className="p-5 bg-light-primary rounded">
                                            <span style={{ fontWeight: 'bold', fontSize: '10pt' }}>Nilai Kontrak dan Jangka Waktu</span>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label style={{ width: '150px' }}>Owner Estimate</label><b>{swaState.dataDetail?.dataSwakelola?.owner_est}</b>
                                                </div>
                                                <div className="col-md-6 text-md-right">
                                                    <label>IDR (Juta)</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label style={{ width: '150px' }}>Periode Proyek</label><b>{swaState.dataDetail?.dataSwakelola?.show_start}</b>
                                                </div>
                                                <div className="col-md-6">
                                                    <label style={{ width: '150px' }}>Sampai</label><b>{swaState.dataDetail?.dataSwakelola?.show_end}</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label style={{ width: '150px' }}>Nilai Kontrak</label><b>{swaState.dataDetail?.dataSwakelola?.nilai_kontrak_terbaru}</b>
                                                </div>
                                                <div className="col-md-6 text-md-right">
                                                    <label>IDR (Juta)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: "Dokumen Pendukung",
                        childs: [
                            {
                                type: "custom",
                                label: "Kerangka Acuan",
                                component: (
                                    <div className="mb-5">
                                        {
                                            swaState.dataDetail?.dataSwakelola?.dok1_kak &&
                                            <a className="d-block" target='_blank' href={swaState.dataDetail?.dataSwakelola?.dok1_kak} download>Download File</a>
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Owner Estimate",
                                component: (
                                    <div className="mb-5">
                                        {
                                            swaState.dataDetail?.dataSwakelola?.dok2_owner_est &&
                                            <a className="d-block" target='_blank' href={swaState.dataDetail?.dataSwakelola?.dok2_owner_est} download>Download File</a>
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Purchase Requisition",
                                component: (
                                    <div className="mb-5">
                                        {
                                            swaState.dataDetail?.dataSwakelola?.dok3_purchase_req &&
                                            <a className="d-block" target='_blank' href={swaState.dataDetail?.dataSwakelola?.dok3_purchase_req} download={swaState.dataDetail?.dataSwakelola?.dok3_purchase_req}>Download File</a>
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Pakta Integritas",
                                component: (
                                    <div className="mb-5">
                                        {
                                            swaState.dataDetail?.dataSwakelola?.dok4_pakta_int &&
                                            <a className="d-block" target='_blank' href={swaState.dataDetail?.dataSwakelola?.dok4_pakta_int} download>Download File</a>
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: "Justifikasi",
                                component: (
                                    <div className="mb-5">
                                        {
                                            swaState.dataDetail?.dataSwakelola?.dok5_justifikasi &&
                                            <a className="d-block" target='_blank' href={swaState.dataDetail?.dataSwakelola?.dok5_justifikasi} download>Download File</a>
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>Dokumen lainnya <b>(Optional)</b></span>,
                                component: (
                                    <div className="mb-5">
                                        {
                                            swaState.dataDetail?.dataSwakelola?.dok6_lainnya &&
                                            <a className="d-block" target='_blank' href={swaState.dataDetail?.dataSwakelola?.dok6_lainnya} download>Download File</a>
                                        }
                                    </div>
                                )
                            },
                        ]
                    }
                ]}
            />
            <div className="row mx-1">
                <div className="col-md-12">
                    {
                        (swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Validasi" && swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Need Revision" && swaState.dataDetail?.dataSwakelola?.status_swakelola !== "Rejected") && (
                            <>
                                <span style={{ fontWeight: 'bold', fontSize: '10pt' }}>Kontrak</span>
                                <hr />
                                {
                                    (swaState.path === '/admin/swakelola-detail' &&
                                        swaState.dataDetail?.dataSwakelola?.status_swakelola === "Pengadaan" && swaState.dataDetail?.dataSwakelola?.is_available_button_add_kontrak === true) && (
                                        <div className="d-block text-md-right mb-3">
                                            <button className="btn btn-sm btn-primary"
                                                onClick={() => swaAction.addKontrak()}
                                            >
                                                <i className="fa fa-plus icon-nm"></i>
                                                Kontrak
                                            </button>
                                        </div>
                                    )
                                }
                                <GridDx
                                    cellRender={swaAction.cellRenderKontak}
                                    className='custom-style mb-5'
                                    data={swaState.dataDetail?.data_kontrak}
                                    columns={column_kontrak}
                                >
                                    {
                                        (swaState.path === '/admin/swakelola-detail' &&
                                            swaState.dataDetail?.dataSwakelola?.status_swakelola === "Pengadaan") && (
                                            <Column caption="Action" type="buttons" headerCellRender={swaAction.headerCellRender} >
                                                <Button name="customEdit" visible={true} render={swaAction.editBtn} />
                                                <Button name="delete" visible={true} render={swaAction.deleteBtn} />
                                            </Column>
                                        )
                                    }
                                </GridDx>
                            </>
                        )
                    }
                    {
                        (swaState.dataDetail?.dataSwakelola?.status_swakelola == "Addendum" || (swaState.dataDetail?.dataSwakelola?.status_swakelola == "Kontrak Berjalan" || swaState.dataDetail?.dataSwakelola?.status_swakelola == "Selesai") && swaState.dataDetail?.data_addendum?.length !== 0) && (
                            <>
                                <span style={{ fontWeight: 'bold', fontSize: '10pt' }}>Addendum</span>
                                <hr />
                                {
                                    (swaState.path === '/admin/swakelola-detail' &&
                                        swaState.dataDetail?.dataSwakelola?.status_swakelola == "Addendum" && swaState.dataDetail?.dataSwakelola?.status_flag == false) && (
                                        <div className="d-block text-md-right mb-3">
                                            <button className="btn btn-sm btn-primary"
                                                onClick={() => swaAction.addAddendum()}
                                            >
                                                <i className="fa fa-plus icon-nm"></i>
                                                Addendum
                                            </button>
                                        </div>
                                    )
                                }
                                <GridDx
                                    className='custom-style mb-5'
                                    cellRender={swaAction.cellRenderAddendum}
                                    data={swaState.dataDetail?.data_addendum}
                                    columns={column_addendum}
                                >
                                    {
                                        (swaState.path === '/admin/swakelola-detail' &&
                                            swaState.dataDetail?.dataSwakelola?.status_swakelola == "Addendum" && swaState.dataDetail?.dataSwakelola?.status_flag == true) && (
                                            <Column caption="Action" type="buttons" headerCellRender={swaAction.headerCellRender} >
                                                <Button name="customEdit" visible={true} render={swaAction.editBtnAddendum} />
                                                <Button name="delete" visible={true} render={swaAction.deleteBtnAddendum} />
                                            </Column>
                                        )
                                    }
                                </GridDx>
                            </>
                        )
                    }
                </div>
            </div>

        </div>
    );
}

export default DetailTabDetail;