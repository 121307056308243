import React, { useState, useEffect } from 'react';
import { GridDxTimeline, GanttChartP, SwitchBtn } from '../../components/index'
import { columnSummary, rowSummary } from '../../pages/portFolioViewEdit/dummy-data';
import Card from 'react-bootstrap/Card'

const type = [
    { "value": "milestones", "name": "Milestone" }, 
    { "value": "submilestones", "name": "Sub Milestone" }, 
    { "value": "task", "name": "Task" }, 
    { "value": "subtask", "name": "Sub Task" }
];
const colomn = [
    { "fieldName": "task", "caption": "Task", minWidth: 100 },
    { "fieldName": "type", "caption": "Type", "custom": true, "customType": "lookup", "dsCustom": type, "displayExpr": "name", "valueExpr": "value", "visible": true, width: 100 },
    { "fieldName": "plan_jan", "caption": "JAN", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_feb", "caption": "FEB", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_mar", "caption": "MAR", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_apr", "caption": "APR", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_may", "caption": "MAY", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_jun", "caption": "JUN", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_jul", "caption": "JUL", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_aug", "caption": "AUG", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_sep", "caption": "SEP", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_oct", "caption": "OCT", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_nov", "caption": "NOV", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "plan_dec", "caption": "DEC", "visible": true, "dataType": "number", width: 50 }
]

const TabTimeline = () => {
    const [sw, setSw] = useState(false);
    const [dataM, setDataM] = useState([]);

    function btnProjectOnClick(e) {
        let max = dataM.reduce((mx, dt) => {
            return dt.year >= mx.year ? dt : mx;
        }, {
            year: Number.MIN_SAFE_INTEGER
        });
        // console.log("year", max)
        setDataM(dataM.concat({ year: (max.year === Number.MIN_SAFE_INTEGER ? parseInt(new Date().getFullYear()) : max.year) + 1, data: [] }))
    }

    function deleteYear(e) {
        setDataM(dataM.filter(d => d.year !== e.year));
    }
    useEffect(() => {
        
    }, [])
    return (
        <div className="row p-3">
            <div className="col-md-12">
                <p><i className="fa fa-circle"></i> Pengisian estimasi progres dapat diisi pada Sub Task, Sub Milestone.
                    Sedangkan untuk Task hanya berupa keterangan.
                </p>
                <p><i className="fa fa-circle"></i> Bobot isian adalah dalam bentuk persentase (%) dan total plan untuk setiap tahun
                    harus berjumlah 100%
                </p>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button onClick={btnProjectOnClick} className="btn btn-success btn-sm customButton text-white m-2" style={{ fontSize: '8pt', fontFamily: 'Poppins' }}><i className="fa fa-plus icon-sm"></i> Add Year</button>
                        <label style={{ paddingRight: 10 }}>{sw ? ("Table view") : ("Ganttchart view")}</label>
                        <SwitchBtn value={sw} onChange={(e) => setSw(e)} />
                    </div>
                </div>
                <hr style={{ margin: 0 }} />
                {
                    dataM.map((e, i) => {
                        return (
                            <Card className="p-0 my-2">
                                <Card.Body className="p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="d-flex py-2 justify-content-between border-bottom">
                                                        <span className="align-self-center">{e.year}</span>
                                                        {
                                                            sw === false ?
                                                                (
                                                                    <button className="btn btn-outline-danger btn-sm pull-right" onClick={() => deleteYear(e)} style={{ fontSize: '8pt', fontFamily: 'Poppins' }}><i className="fa fa-trash-alt icon-sm"></i> Remove {e.year}</button>
                                                                ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        sw === false ?
                                                            <GridDxTimeline
                                                                dataSource={e.data}
                                                                key={i}
                                                                allowAdding={true}
                                                                allowDeleting={true}
                                                                allowEditing={true}
                                                                columns={colomn}
                                                                summaryC={true}
                                                                columnSummary={columnSummary}
                                                                summaryR={true}
                                                                rowSummary={rowSummary}
                                                            /> :
                                                            <GanttChartP
                                                                key={i}
                                                                dataSource={e.data}
                                                                column={colomn}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                }

            </div>
        </div>
    )
}
export default TabTimeline;