import React, { memo, useEffect, useRef, useState } from 'react'
import { useGlobalState } from '../../contexts/useGlobalState';
import { HeaderList, GridDx } from '../../components';
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { useHistory } from 'react-router';
import {
    status_progress_shortName,
    status_progress_shortName_2,
    perform_work,
    workflow_state_name_light_style,
    status_budget_light,
    subholding,
    potensial_partner_opt_name,
    need_management_support,
    value_creation
} from '../../components/utility/utility';

//Api Parameter
const paramApiDetail = {
    fungsi_search: "",
    chief_search: "",
    area_search: "",
    cat_search: "",
    status_workflow_search: "",
    status_progress_search: "",
    status_budget_search: "",
    cut_off_search: "",
    leader_search: "",
    project_type_search: "",
    need_management_support_search: "",
    sub_holding_search: "",
    year_start_search: "",
    year_end_search: "",
}
const paramApiProgress = {
    fungsi_search: "",
    chief_search: "",
    area_search: "",
    cat_search: "",
}
const paramApiSubHold = {
    fungsi_search: "",
    chief_search: "",
    area_search: "",
    cat_search: "",
    status_workflow_search: "",
    status_progress_search: "",
    status_budget_search: "",
    cut_off_search: "",
    leader_search: "",
    project_type_search: "",
    need_management_support_search: "",
    sub_holding_search: "",
    year_start_search: "",
    year_end_search: ""
}

//Filter Column
const budgetFilter = [
    { text: 'Under Budget', value: 1 },
    { text: 'Within Budget', value: 2 },
    { text: 'Over Budget', value: 3 },
    { text: 'Aborted', value: 4 },
    { text: '-', value: null },
]
const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]

//Column Visible
const columnProgress = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 200 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 200 },
    { fieldName: "chief_sn", caption: "Chief", width: 200 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 200 },
    { fieldName: "perform_work", caption: "Performance/Progress", width: 200 },
    { fieldName: "status_progress_1", caption: "1", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_2", caption: "2", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_3", caption: "3", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_4", caption: "4", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_5", caption: "5", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_6", caption: "6", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_7", caption: "7", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_8", caption: "8", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_9", caption: "9", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_10", caption: "10", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_11", caption: "11", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_12", caption: "12", width: 50, headerFilterSource: progresFilter },
]
const columnDetail = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 150 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 100 },
    { fieldName: "function_sn", caption: "Fungsi", width: 200 },
    { fieldName: "chief_sn", caption: "Chief", width: 200 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 100 },
    { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
    { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
    { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "area_name", caption: "Area", width: 100 },
    { fieldName: "research_category_id", caption: "Research Kategori", width: 115 },
    { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
    { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
    { fieldName: "leader", caption: "Leader", width: 100 },
    { fieldName: "method_name", caption: "Metode", width: 100 },
    { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potensial User", width: 200 },
    { fieldName: "need_management_support", caption: "Need Management Support", width: 120 },
    { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
    //{ fieldName: "value_creation", caption: "Value Creations", width: 300 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "year_start", caption: "Start", width: 100 },
    { fieldName: "year_end", caption: "End", width: 100 },
    { fieldName: "keyword", caption: "Keyword", width: 300 },
]
const columnSubHol = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 150 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 100 },
    { fieldName: "function_sn", caption: "Fungsi", width: 200 },
    { fieldName: "chief_sn", caption: "Chief", width: 200 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 100 },
    { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
    { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
    { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "area_name", caption: "Area", width: 100 },

    { fieldName: "research_category_id", caption: "Research Kategori", width: 115 },
    { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
    { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
    { fieldName: "leader", caption: "Leader", width: 100 },
    { fieldName: "method_name", caption: "Metode", width: 100 },
    { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potensial User", width: 200 },
    { fieldName: "need_management_support", caption: "Need Management Support", width: 120 },
    { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
    //{ fieldName: "value_creation", caption: "Value Creations", width: 300 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "year_start", caption: "Start", width: 100 },
    { fieldName: "year_end", caption: "End", width: 100 },
]

//Grid Selector
const optGrid = [
    { name: "Project Detail", value: 1 },
    { name: "Progress Status", value: 2 },
    { name: "Project Sub Holding", value: 3 }
]
const month = [
    { name: 'JAN', value: '1' },
    { name: 'FEB', value: '2' },
    { name: 'MAR', value: '3' },
    { name: 'APR', value: '4' },
    { name: 'MAY', value: '5' },
    { name: 'JUN', value: '6' },
    { name: 'JUL', value: '7' },
    { name: 'AUG', value: '8' },
    { name: 'SEP', value: '9' },
    { name: 'OCT', value: '10' },
    { name: 'NOV', value: '11' },
    { name: 'DEC', value: '12' },
]

export default memo(function () {
    const tableRef = useRef()
    const [gridType, setGridType] = useState("1")
    const history = useHistory()
    const [state, dispatch, action] = useGlobalState();
    const [column, setColumn] = useState(columnDetail)
    const [years, setYears] = useState([]);
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        getTableDetail();
    }, [])

    function filterChange(tahun, bulan) {
        // if (gridType.toString() === "1") {
        getTableDetail(tahun, bulan)
        // } else if (gridType.toString() === "2") {
        //     getTableProgress(tahun, bulan)
        // } else if (gridType.toString() === "3") {
        //     getTableSubHolding(tahun, bulan)
        // }
    }
    function getTableDetail(tahun, bulan) {
        action.getData("/api/project-ongoing/table-detail", {
            tahun: tahun || "",
            bulanGet: bulan || "",
            ...paramApiDetail
        }, "dsGrid", "loadingGrid", true).then((resp) => {
            if (resp.code === "00") {
                action.setFilter("year_search", resp.data.data_tahunGet)
                action.setFilter("bulanGet", resp.data.data_bulanNow)
                const y = [...(resp.data.data_allyear || [])].map(d => {
                    return {
                        name: d.idYear,
                        value: d.idYear
                    }
                })
                setYears(y);
                setData_page(resp.data_page);
            }
        })
    }
    // function getTableProgress(tahun, bulan) {
    //     action.getData("/api/project-ongoing/table-progress", {
    //         tahun: tahun || "",
    //         bulanGet: bulan || "",
    //         ...paramApiProgress
    //     }, "dsGrid", "loadingGrid").then((resp) => {
    //         if (resp.code === "00") {
    //             action.setFilter("year_search", resp.data.data_tahunGet)
    //             action.setFilter("bulanGet", resp.data.data_bulanNow)
    //             const y = [...(resp.data.data_allyear || [])].map(d => {
    //                 return {
    //                     name: d.idYear,
    //                     value: d.idYear
    //                 }
    //             })
    //             setYears(y);
    //         }
    //     })
    // }
    // function getTableSubHolding(tahun, bulan) {
    //     action.getData("/api/project-ongoing/table-sub-holdings", {
    //         tahun: tahun || "",
    //         bulanGet: bulan || "",
    //         ...paramApiSubHold
    //     }, "dsGrid", "loadingGrid").then((resp) => {
    //         if (resp.code === "00") {
    //             action.setFilter("year_search", resp.data.data_tahunGet)
    //             action.setFilter("bulanGet", resp.data.data_bulanNow)
    //             const y = [...(resp.data.data_allyear || [])].map(d => {
    //                 return {
    //                     name: d.idYear,
    //                     value: d.idYear
    //                 }
    //             })
    //             setYears(y);
    //         }
    //     })
    // }
    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <>
                    {
                        e.data?.workflow_state_name === 'monitoring_revise_portfolio' && (
                            <Link target="_blank" to=
                                {
                                    {
                                        pathname: "/admin/portfolio-research/crud/edit/" + e.data.id,
                                    }
                                }
                            ><span className="text-wrap">{e.value}</span></Link>
                        )
                    }
                    {
                        e.data?.workflow_state_name !== 'monitoring_revise_portfolio' && (
                            <Link target="_blank" to=
                                {
                                    {
                                        pathname: "/admin/Ongoingresearch/crud/edit/" + e.data.id,
                                    }
                                }
                            ><span className="text-wrap">{e.value}</span></Link>
                        )
                    }
                </>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span>{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName(e, state.dsGrid?.data_bulanNow))
        } else if (e.column.name === "status_progress") {
            return (status_progress_shortName_2(e))
        } else if (e.column.name === "status_budget") {
            return (status_budget_light(e))
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "potensial_partner_opt_name") {
            return (potensial_partner_opt_name(e))
        } else if (e.column.name === "need_management_support") {
            return (need_management_support(e))
        } else if (e.column.name === "subholding") {
            return (subholding(e))
        } else if (e.column.name === "value_creation") {
            return (value_creation(e))
        } else if (e.column.name === "keyword") {
            return (
                <div className="text-wrap">
                    {
                        (e.value || "").split(',').map((d, i) => (
                            <span className="badge badge-secondary mr-1 mb-1">{d}</span>
                        ))
                    }
                </div>
            )
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }

    // function searchChange(value) {
    //     if (tableRef) {
    //         tableRef.current.instance.searchByText(value)
    //     }
    // }
    // function optionGridChange(value) {
    //     setGridType(value)
    //     if (value === "1") {
    //         setColumn(columnDetail)
    //         getTableDetail(state.filterData.year_search, state.filterData.bulanGet)
    //     } else if (value === "2") {
    //         setColumn(columnProgress)
    //         getTableProgress(state.filterData.year_search, state.filterData.bulanGet)
    //     } else if (value === "3") {
    //         setColumn(columnSubHol)
    //         getTableSubHolding(state.filterData.year_search, state.filterData.bulanGet)
    //     }
    // }
    return (
        <>
            <div className="pb-5">
                <HeaderList
                    title={data_page?.page_title}
                    someText={data_page?.page_breadcrumbs}
                    optionList={[{
                        withLabel: true,
                        label: "Progress kinerja dan anggaran proyek-proyek RTI ",
                        option: years,
                        value: state.dsGrid?.data_tahun_default,
                        onChange: (val) => { action.setFilter("year_search", val); filterChange(val, state.dsGrid?.data_bulan_default) }
                    },
                    {
                        withLabel: false,
                        option: month,
                        value: state.dsGrid?.data_bulan_default,
                        onChange: (val) => { action.setFilter("bulanGet", val); filterChange(state.dsGrid?.data_tahun_default, val) }
                    }]}
                ></HeaderList>
            </div>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Project</span>
                                    <span className="d-block"><strong>{(state.dsGrid?.data_jumlah_project?.total || 0) - (state.dsGrid?.data_jumlah_project?.continue_request || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Project Running</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.project_running || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Finished</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.finished || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Aborted</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.rejected || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Review</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.review || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Need Revision</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.need_revision || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Not Yet Reported</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.project_continue || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom pl-2">
                                <div>
                                    <div className="shadow rounded bg-white text-dark text-center" style={{ width: 32.5, height: 32.5, display: 'grid' }}>
                                        <span className="my-auto"><b>C</b></span>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Continue Request</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.continue_request || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom pl-md-5">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                    <span className="d-block">$ <strong>{(state.dsGrid?.data_jumlah_project?.total_plan_budget || 0)}/{(state.dsGrid?.data_jumlah_project?.total_actual_budget || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="px-5">
                    {/* <div className="row mb-5">
                        <div className="col-md-3">
                            <div className="formSearch">
                                <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => searchChange(e.target.value)} />
                                <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="d-flex align-items-center justify-content-md-end justify-content-sm-center">
                                <div className="containerGroup">
                                    <span className="m-0 p-0">Total Performance, Progress Status & Budget Status for</span>
                                </div>
                                <div className="pl-md-2">
                                    <select className="form-control form-control-sm d-flex"
                                        onChange={function (e) { action.setFilter("year_search", e.target.value); filterChange(e.target.value, state.filterData.bulanGet) }}
                                        value={state.filterData.year_search}
                                    >
                                        {
                                            years.map((y, i) => (
                                                <option key={i} value={y.value}>{y.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="pl-md-2 pr-md-2 border-right">
                                    <select className="form-control form-control-sm d-flex"
                                        onChange={function (e) {
                                            action.setFilter("bulanGet", e.target.value);
                                            filterChange(state.filterData.year_search, e.target.value)
                                        }}
                                        value={state.filterData.bulanGet}
                                    >
                                        {
                                            month.map((y, i) => (
                                                <option key={i} value={y.value}>{y.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="pl-md-2 pr-md-2 ">
                                    View
                                </div>
                                <div className="pl-md-2 pr-md-2" >
                                    <select className="form-control form-control-sm" value={gridType} onChange={(e) => optionGridChange(e.target.value)}>
                                        {
                                            optGrid.map((d, i) => (
                                                <option key={i} value={d.value}>{d.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mb-5">
                        <div className="col-md-12">
                            {
                                gridType === "1" && (
                                    <GridDx
                                        ref={tableRef}
                                        loading={state.loadingGrid}
                                        data={state.dsGrid?.data_table}
                                        columns={columnDetail}
                                        searchForm={true}
                                        cellRender={(e) => cstmCellRender(e)}
                                        withAction={true}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        actions={[
                                            // {
                                            //     name: "Edit",
                                            //     icon: "fa fa-pencil-alt icon-nm text-success",
                                            //     action: (v, c) => { history.push("/admin/OngoingResearch/crud/edit/" + v.id) }
                                            // },
                                            {
                                                name: "Edit Team Member",
                                                icon: "fa fa-users icon-nm text-primary",
                                                action: (v, c) => window.open("/admin/EditTeamMember/OngoingResearch/" + v.id, "_blank")//{ history.push("/admin/EditTeamMember/OngoingResearch/" + v.id) }
                                            },
                                            {
                                                name: "Edit Project Unggulan",
                                                icon: "fa fa-bookmark icon-nm text-primary",
                                                action: (v, c) => window.open("/admin/EditProjectUnggulan/OngoingResearch/" + v.id + "/" + state.dsGrid?.data_tahun_default, "_blank")//{history.push("/admin/EditProjectUnggulan/OngoingResearch/" + v.id)}
                                            },
                            {
                                name: "Edit Project Priority Sub-holding",
                            icon: "fa fa-bookmark icon-nm text-success",
                                                action: (v, c) => window.open("/admin/project-ongoing-crud/view-update-project-unggulan-sub-holding/" + v.id, "_blank")
                                            }
                                        ]}
                            show
                                    >

                        </GridDx>
                        )
                            }
                        {
                            gridType === "2" && (
                                <GridDx
                                    ref={tableRef}
                                    loading={state.loadingGrid}
                                    data={state.dsGrid?.data_table}
                                    columns={columnProgress}
                                    searchForm={true}
                                    cellRender={(e) => cstmCellRender(e)}
                                    withAction={true}
                                    pagination={true}
                                    orderingDrag={false}
                                    currentPage
                                    actions={[
                                        {
                                            name: "Edit",
                                            icon: "fa fa-pencil-alt icon-nm text-success",
                                            action: (v, c) => { history.push("/admin/OngoingResearch/crud/edit/" + v.id) }
                                        },
                                        {
                                            name: "Edit Team Member",
                                            icon: "fa fa-users icon-nm text-primary",
                                            action: (v, c) => { history.push("/admin/EditTeamMember/OngoingResearch/" + v.id) }
                                        },
                                        {
                                            name: "Edit Project Unggulan",
                                            icon: "fa fa-bookmark icon-nm text-primary",
                                            action: (v, c) => { history.push("/admin/EditProjectUnggulan/OngoingResearch/" + v.id) }
                                        }
                                    ]}
                                    show
                                >

                                </GridDx>
                            )
                        }
                        {
                            gridType === "3" && (
                                <GridDx
                                    ref={tableRef}
                                    loading={state.loadingGrid}
                                    data={state.dsGrid?.data_table}
                                    columns={columnSubHol}
                                    cellRender={(e) => cstmCellRender(e)}
                                    withAction={true}
                                    searchForm={true}
                                    pagination={true}
                                    orderingDrag={false}
                                    currentPage
                                    actions={[
                                        {
                                            name: "Edit",
                                            icon: "fa fa-pencil-alt icon-nm text-success",
                                            action: (v, c) => { history.push("/admin/OngoingResearch/crud/edit/" + v.id) }
                                        },
                                        {
                                            name: "Edit Team Member",
                                            icon: "fa fa-users icon-nm text-primary",
                                            action: (v, c) => { history.push("/admin/EditTeamMember/OngoingResearch/" + v.id) }
                                        },
                                        {
                                            name: "Edit Project Unggulan",
                                            icon: "fa fa-bookmark icon-nm text-primary",
                                            action: (v, c) => { history.push("/admin/EditProjectUnggulan/OngoingResearch/" + v.id) }
                                        }
                                    ]}
                                    show
                                >

                                </GridDx>
                            )
                        }
                    </div>
                </div>
            </Card.Body>
        </Card >
        </>
    )
})
