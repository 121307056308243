import React, { useEffect, useRef } from 'react'
import { GridDx } from '../../components';
import Card from 'react-bootstrap/Card'
import usePortfolio from '../nonAdmin/portfolio/usePortfolio';

const TableBudget = ({ reporting = true }) => {
    const { portAction, portState } = usePortfolio();
    const tableRef = useRef(null);

    useEffect(() => {
        portAction.getTableReporting();
    }, [portState.tahun]);

    return (
        <>
            <Card
                className="m-0 p-0 h-100 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12">
                            <GridDx
                                selectionMode={reporting ? "multiple" : false}
                                exportMode="selection"
                                exportExcel={reporting ? true : false}
                                // exportPdf={reporting ? true : false}
                                filenameExport={`Portfolio Table Budget-${portState.tahun}`}
                                settingColumn={reporting ? true : false}
                                defaultColumn={portState.defColumnReporting}

                                searchForm={true}
                                ref={tableRef}
                                loading={portState.loadingTableReporting}
                                cellRender={portAction.cellRenderGrid}
                                data={portState.dataTableReporting?.data_table}
                                columns={portState.columnReporting}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                                {/* {
                                    [...(portState.columnReporting || [])].map((d, i) => (
                                        <Column dataField={d?.fieldName} caption={d?.caption} width={d?.width} headerCellRender={portAction.headerCellRender} cellRender={portAction.cellRenderGrid}></Column>
                                    ))
                                } */}
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default TableBudget;