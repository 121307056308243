import React, { memo } from 'react'
import { Card, Carousel } from 'react-bootstrap'
import { useGlobalState } from '../../contexts/useGlobalState';

const ModalScreensaver = memo(({ data = [] }) => {
    const [state, dispatch, action] = useGlobalState();
    return (
        <>
            <Card>
                <Card.Body>
                    <Carousel>
                        {
                            data.map((d, i) => (
                                <Carousel.Item>
                                    <img
                                        height="400px"
                                        className="d-block w-100 bg-secondary"
                                        src={d.image ? d.image : "/media/svg/icons/Code/Question-circle.svg"}
                                    />
                                    <Carousel.Caption>
                                        <p>{d.judul}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                </Card.Body>
            </Card>
        </>
    )
})
export default ModalScreensaver