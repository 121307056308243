import React from 'react';
import { HeaderList, Tab, TabContent } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import TabDashoard from '../productMng/tabDashoard';
import TabProductList from '../productMng/tabProductList';

function ReportingProduct(props) {
    const [state, , ] = useGlobalState();
    return (
        <>
            <HeaderList
                title={state.formData.pro?.page_title}
                someText={state.formData.pro?.page_breadcrumbs}
            ></HeaderList>
            <Tab
                withCard={true}
                itemsTab={[
                    {
                        id: "dashboard",
                        title: "Product Dashboard"
                    },
                    {
                        id: "list",
                        title: "Product List"
                    }
                ]}
            >
                <TabContent id="dashboard">
                    <TabDashoard reporting={true}></TabDashoard>
                </TabContent>
                <TabContent id="list">
                    <TabProductList reporting={true}></TabProductList>
                </TabContent>
            </Tab>
        </>
    );
}

export default ReportingProduct;