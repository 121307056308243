import React, { memo, useEffect, useRef } from 'react'
import { GridDx, HeaderList, Modal } from '../../components'
import { Card } from 'react-bootstrap'
import { useGlobalState } from '../../contexts/useGlobalState'
import ModalCategory from './modalCategory'
import ModalFAQ from './modalFAQ'
import { useState } from 'react'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { faq_status_filter } from '../../components/utility/utility'
import { toString } from 'lodash'

const columns = [
    { fieldName: "category_name", caption: "Category", width: 200 },
    { fieldName: "question", caption: "Question", width: 300 },
    { fieldName: "answer", caption: "Answer", minWidth: 300 },
    { fieldName: "status", caption: "Status", minWidth: 300, headerFilterSource: faq_status_filter },
]
const dummy = [
    {
        category: 'General',
        question: 'Mengapa popup pada modal tidak muncul?',
        answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero'
    }
]
export default memo(function () {
    const tableRef = useRef()
    const [state, dispatch, action] = useGlobalState();
    const [mode, setMode] = useState('Add');
    const [data, setData] = useState();
    const [dataFaq, setDataFaq] = useState({});
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        getData();
    }, [])

    function getData() {
        action.getData(`/api/Faq/master-faq`, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setDataFaq(resp.data);
                setData_page(resp.data_page);
            }
        })
    }
    function addFAQ() {
        setMode("Add")
        action.toggleModal("masterFaq")
    }
    function editFaq(data) {
        setMode("Edit")
        setData(data)
        action.toggleModal("masterFaq")
    }
    function deleteData(data) {
        const d = {
            "id": data?.id,
            "category": '',
            "question": '',
            "answer": '',
            "status": '',
            "created_at": ''
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete FAQ ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/Faq/delete-faq`,
                        data: d
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const customRender = (e) => {
        if (e.column.name === "status") {
            return (
                <span className="text-wrap">{faq_status_filter.find(d => toString(d.value) === toString(e.value))?.text}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <Modal
                name="masterFaq"
                baseColor={false}
            >
                <ModalFAQ mode={mode} data={data} />
            </Modal>
            <Modal
                name="modalKategory"
                baseColor={false}
            >
                <ModalCategory />
            </Modal>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            />
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body>
                    {/* <div className="row justify-content-between">
                        <div className="col-md-5">
                            <div className="input-group mb-2">
                                <select className="form-control form-control-sm mr-1">
                                    <option disabled={true} selected value="">-All Type-</option>
                                </select>
                                <input className="form-control form-control-sm mr-1">
                                </input>
                                <div>
                                    <button type="submit" className="btn btn-sm btn-primary">
                                        <i className="fa fa-search icon-nm text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-block text-right">
                                <button className="btn btn-sm btn-warning mx-1"
                                    onClick={() => action.toggleModal("modalKategory")}
                                >
                                    <i className="fa fa-pencil-alt icon-nm text-white"></i>
                                    Edit Kategori
                                </button>
                                <button className="btn btn-sm btn-success mx-1"
                                    onClick={() => addFAQ()}
                                >
                                    <i className="fa fa-plus icon-nm text-white"></i>
                                    Add FAQ
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-12">
                            <GridDx
                                searchForm={true}
                                toolbar={<>
                                    <button className="btn btn-sm btn-warning mx-1"
                                        onClick={() => action.toggleModal("modalKategory")}
                                    >
                                        <i className="fa fa-pencil-alt icon-nm text-white"></i>
                                        Edit Kategori
                                    </button>
                                    <button className="btn btn-sm btn-success mx-1"
                                        onClick={() => addFAQ()}
                                    >
                                        <i className="fa fa-plus icon-nm text-white"></i>
                                        Add FAQ
                                    </button>
                                </>}
                                data={dataFaq?.faq}
                                ref={tableRef}
                                withAction={true}
                                columns={columns}
                                cellRender={customRender}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                actions={[
                                    {
                                        name: "Edit",
                                        icon: "fa fa-pencil-alt icon-nm text-success",
                                        action: (v, c) => editFaq(v)
                                    },
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => deleteData(v)
                                    }
                                ]}
                                show

                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
})