import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useGlobalState } from "../../../../contexts/useGlobalState";
import { Button, Column } from 'devextreme-react/data-grid';
import Swal from 'sweetalert2';
import { DropdownButton } from 'react-bootstrap';
import { GridDx } from "../../../../components";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { perform_work, status_progress_shortName } from "../../../../components/utility/utility";
import useFetch from "../../../../modules/useFetch";

const hdContext = createContext({
    hdState: {},
    hdAction: {}
})

const column_activity = [
    { fieldName: 'created_at', caption: 'Date', allowFiltering: false },
    { fieldName: 'notes_status', caption: 'Status', allowFiltering: false },
    { fieldName: 'tindak_lanjut', caption: 'Tindak Lanjut VP', allowFiltering: false },
    { fieldName: 'deadline', caption: 'Deadline', allowFiltering: false },
]
export function HdProvider({ children }) {
    const [globalState, , globalAction] = useGlobalState();
    const { doGet, doPost } = useFetch();
    const [year_search, setYear_Search] = useState(moment(new Date()).add(-1, 'months').format('YYYY'));
    const [month_search, setMonth_Search] = useState(moment(new Date()).add(-1, 'months').format('M'));
    const [dataDahsboard, setDataDashboard] = useState({});
    const [dataActivity, setDataActivity] = useState([]);
    const [dataRelated, setDataRelated] = useState([]);
    const [modeEditorActivity, setModeEditorActivity] = useState('Add');
    const [dataEditorActivity, setDataEditorActivity] = useState({});
    const [data_page, setData_page] = useState({});

    const [modeEditorNotes, setModeEditorNotes] = useState('Add');
    const [dataEditorNotes, setDataEditorNotes] = useState({});
    //const []
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [yAxis_chart1, setYAxis_chart1] = useState([{
        name: "Plan",
        valueField: "plan",
        color: '#D1D1D1'
    },
    {
        name: "Realisasi",
        valueField: "actual",
        color: '#326BD5'
    }]);
    const [swChart1, setSwChart1] = useState(false);
    const [yAxis_chart2, setYAxis_chart2] = useState([{
        name: "Proposed Budget",
        valueField: "plan",
        color: '#D1D1D1'
    },
    {
        name: "Realisasi",
        valueField: "actual",
        color: '#F08044'
    }]);
    const [swChart2, setSwChart2] = useState(false);
    const [swChart1Unggulan, setSwChart1Unggulan] = useState(false);
    const [yAxis_chart1Unggulan, setYAxis_chart1Unggulan] = useState([{
        name: "Plan",
        valueField: "plan",
        color: '#D1D1D1'
    },
    {
        name: "Realisasi",
        valueField: "actual",
        color: '#326BD5'
    }]);
    const [swChart2Unggulan, setSwChart2Unggulan] = useState(false);
    const [yAxis_chart2Unggulan, setYAxis_chart2Ungulan] = useState([{
        name: "Proposed Budget",
        valueField: "plan",
        color: '#D1D1D1'
    },
    {
        name: "Realisasi",
        valueField: "actual",
        color: '#F08044'
    }]);

    async function getDashboard(tahun, bulanGet) {
        setLoadingDashboard(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {

            const resp = await doGet({
                url: 'api/home/highlight-dashboard',
                param: {
                    tahun,
                    bulanGet
                }
            });
            if (resp?.code === '00') {
                setDataDashboard(resp.data);
                setData_page(resp.data_page);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setLoadingDashboard(false)
    }
    async function getDashboardAdmin(tahun, bulanGet) {
        setLoadingDashboard(true);
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {

            const resp = await doGet({
                url: 'api/home/highlight-dashboard-3',
                param: {
                    tahun,
                    bulanGet
                }
            });
            if (resp?.code === '00') {
                setDataDashboard(resp.data);
                setData_page(resp.data_page);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setLoadingDashboard(false)
    }
    async function getActivity() {
        try {
            const resp = await doGet({
                url: 'api/home/activity-table',
            });
            if (resp?.code === '00') {
                setDataActivity(resp.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getRelatedResearch() {
        try {
            const resp = await doGet({
                url: 'api/param-related-research-monitoring',
            });
            if (resp?.code === '00') {
                setDataRelated(resp.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function postCreateActivity(data) {
        try {
            const resp = await doPost({
                url: 'api/home/activity-create',
                token: false,
                data: {
                    ...data
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    globalAction.toggleModal('modalActivity');
                    getActivity();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postUpdateActivity(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/home/activity-update",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalActivity');
                getActivity();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postUpdateStatusActivity(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/home/activity-update-status",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                getActivity();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postDeleteActivity(id) {
        try {
            const resp = await globalAction.postData(
                {
                    url: `/api/home/activity-delete?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                getActivity();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postAddNotes(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/home/activity-notes-create",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalNotes');
                getActivity();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postUpdateNotes(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/home/activity-notes-update",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalNotes');
                getActivity();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postDeleteNotes(id) {
        try {
            const resp = await globalAction.postData(
                {
                    url: `/api/home/activity-notes-delete?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                getActivity();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }


    useEffect(() => {
        getActivity();
        getRelatedResearch();
    }, []);

    function yearChange(val) {
        setYear_Search(val);
        getDashboard(val, month_search);
    }
    function monthChange(val) {
        setMonth_Search(val);
        getDashboard(year_search, val);
    }
    function yearChangeAdmin(val) {
        setYear_Search(val);
        getDashboardAdmin(val, month_search);
    }
    function monthChangeAdmin(val) {
        setMonth_Search(val);
        getDashboardAdmin(year_search, val);
    }
    function getDataYear() {
        const data_year_portfolio = [...(dataDahsboard?.data_year_portfolio || [])];
        return data_year_portfolio.map((d, i) => {
            return {
                name: d.idYear,
                value: d.idYear
            }
        })
    }
    function getPerformRTI() {
        const data_HeaderMonitoring = [...(dataDahsboard?.data_HeaderMonitoring || [])];
        var actual_tot = 0
        var plan_tot = 0
        var perform = 0
        if (data_HeaderMonitoring) {
            data_HeaderMonitoring.forEach(e => {
                actual_tot += e.actual_tot;
                plan_tot += e.plan_tot;
            });
        }
        if (plan_tot != 0) {
            perform = actual_tot / plan_tot;
        } else {
            perform = 0;
        }
        return perform
    }
    function getPercentagePErform(perform = 0) {
        return parseFloat((perform * 100), 0).toFixed(2);
    }
    function switch1Change(val) {
        setSwChart1(val);
        if (val === true) {
            setYAxis_chart1([
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ])
        } else {
            setYAxis_chart1([
                {
                    name: "Plan",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#326BD5'
                }
            ])
        }
    }
    function switch2Change(val) {
        setSwChart2(val);
        if (val === true) {
            setYAxis_chart2([
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ])
        } else {
            setYAxis_chart2([
                {
                    name: "Proposed Budget",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#F08044'
                }
            ])
        }
    }
    function switch1UnggulanChange(val) {
        setSwChart1Unggulan(val);
        if (val === true) {
            setYAxis_chart1Unggulan([
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ])
        } else {
            setYAxis_chart1Unggulan([
                {
                    name: "Plan",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#326BD5'
                }
            ])
        }
    }
    function switch2UnggulanChange(val) {
        setSwChart2Unggulan(val);
        if (val === true) {
            setYAxis_chart2Ungulan([
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ])
        } else {
            setYAxis_chart2Ungulan([
                {
                    name: "Proposed Budget",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#F08044'
                }
            ])
        }
    }
    function getScurve1() {
        var ds = [
            { arg: "JAN", args_value: 1, name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", args_value: 2, name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", args_value: 3, name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", args_value: 4, name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", args_value: 5, name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", args_value: 6, name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", args_value: 7, name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", args_value: 8, name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", args_value: 9, name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", args_value: 10, name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", args_value: 11, name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", args_value: 12, name: "dec", plan: null, actual: null, perform: null },
        ]
        if (dataDahsboard?.data_SCurve1) {
            ds = ds.map((d, i) => ({
                ...d,
                plan: dataDahsboard?.data_SCurve1[`plan_${d.name}`],
                actual: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve1[`actual_${d.name}`] : null,
                perform: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve1[`perform_${d.name}`] : null
            }))
        }
        return ds
    }
    function getScurve2() {
        var ds = [
            { arg: "JAN", args_value: 1, name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", args_value: 2, name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", args_value: 3, name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", args_value: 4, name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", args_value: 5, name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", args_value: 6, name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", args_value: 7, name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", args_value: 8, name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", args_value: 9, name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", args_value: 10, name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", args_value: 11, name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", args_value: 12, name: "dec", plan: null, actual: null, perform: null },
        ]
        if (dataDahsboard?.data_SCurve2) {
            ds = ds.map((d, i) => ({
                ...d,
                plan: dataDahsboard?.data_SCurve2[`plan_${d.name}`],
                actual: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve2[`actual_${d.name}`] : null,
                perform: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve2[`perform_${d.name}`] : null
            }))
        }
        return ds
    }
    function getScurve1Unggulan() {
        var ds = [
            { arg: "JAN", args_value: 1, name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", args_value: 2, name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", args_value: 3, name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", args_value: 4, name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", args_value: 5, name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", args_value: 6, name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", args_value: 7, name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", args_value: 8, name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", args_value: 9, name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", args_value: 10, name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", args_value: 11, name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", args_value: 12, name: "dec", plan: null, actual: null, perform: null },
        ]
        if (dataDahsboard?.data_SCurve1_Unggulan) {
            ds = ds.map((d, i) => ({
                ...d,
                plan: dataDahsboard?.data_SCurve1_Unggulan[`plan_${d.name}`],
                actual: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve1_Unggulan[`actual_${d.name}`] : null,
                perform: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve1_Unggulan[`perform_${d.name}`] : null
            }))
        }
        return ds
    }
    function getScurve2Unggulan() {
        var ds = [
            { arg: "JAN", args_value: 1, name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", args_value: 2, name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", args_value: 3, name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", args_value: 4, name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", args_value: 5, name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", args_value: 6, name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", args_value: 7, name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", args_value: 8, name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", args_value: 9, name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", args_value: 10, name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", args_value: 11, name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", args_value: 12, name: "dec", plan: null, actual: null, perform: null },
        ]
        if (dataDahsboard?.data_SCurve2_Unggulan) {
            ds = ds.map((d, i) => ({
                ...d,
                plan: dataDahsboard?.data_SCurve2_Unggulan[`plan_${d.name}`],
                actual: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve2_Unggulan[`actual_${d.name}`] : null,
                perform: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve2_Unggulan[`perform_${d.name}`] : null
            }))
        }
        return ds
    }
    function getChartDoughnat(data = {}) {
        return [
            { state: "early", value: data?.early || 0 },
            { state: "ontime", value: data?.ontime || 0 },
            { state: "delayed", value: data?.delayed || 0 },
            { state: "finished", value: data?.finished || 0 },
            { state: "stopped", value: data?.stopped || 0 }
        ]
    }
    function renderCenterLabelDoughnat(text_label) {
        return (
            <svg>
                <text textAnchor="middle" x="100" y="120" style={{ fontSize: 8, fill: '#494949' }}>
                    <tspan x="100" style={{ fontWeight: 600 }}>{
                        text_label
                    }</tspan>
                </text>
            </svg>
        )
    }
    function renderTitleAktifitas(title, isCurrentEventKey) {
        return (
            <>
                <div className="row">
                    <div className="col-6">
                        <div className="d-flex">
                            <span className="p-2 mr-3 rounded svg-icon svg-icon-nm svg-icon-warning" style={{ backgroundColor: '#F0F0F0' }}>
                                <SVG src="/media/svg/icons/General/Thunder-move.svg" />
                            </span>
                            <span><b>{title}</b></span>
                        </div>
                    </div>
                    <div className="col-6 text-right">
                        <i className={`text-primary mx-3 ` + (isCurrentEventKey ? `fas fa-angle-double-down` : `fas fa-angle-double-left`)}></i>
                    </div>
                </div>
            </>
        )
    }
    function renderItemAktifitas(data) {
        return (
            <>
                <div className="row mb-3">
                    <div className="col-md-9 mb-3">
                        <span>Detail</span>
                        <hr />
                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <span className="d-block">Terakhir Diperbarui</span>
                                <span className="d-block"><b>{data?.updated_at_form}</b></span>
                            </div>
                            <div className="col-md-2 mb-3">
                                <span className="d-block">Oleh</span>
                                <span className="d-block"><b>{data?.editor_name}</b></span>
                            </div>
                            <div className="col-md-2 mb-3">
                                <span className="d-block mb-1">Status</span>
                                <span className={`px-3 py-1 rounded ${data?.status === 'Open' ? 'status-bg-open' : 'status-bg-close'}`}><b>{data?.status}</b></span>
                            </div>
                            <div className="col-md-6 mb-3">
                                <span className="d-block">Proyek berkaitan</span>
                                <ul>
                                    {

                                        [...(data?.activity_related_project || [])].map((d, i) => (
                                            <li key={i}>
                                                <Link to={`/OngoingResearch/view/${d?.research_id}`} target="_blank">
                                                    {d?.title}
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <span>Action</span>
                        <hr />
                        {
                            data?.status === 'Open' && (
                                <>
                                    <button className="btn btn-sm btn-primary mx-1"
                                        onClick={() => addNotes(data?.id)}
                                    >
                                        <i className="fa fa-plus icon-nm"></i>
                                        Notes
                                    </button>
                                </>
                            )
                        }
                        <DropdownButton size="sm" id="dropdown-basic-button" title={
                            <>
                                <span className="rounded svg-icon svg-icon-sm svg-icon-success">
                                    <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                </span>
                                <span>Option</span>
                            </>
                        }
                            className="mx-1 d-inline dropdown-test">
                            {
                                data?.status === 'Open' && (
                                    <>
                                        <span className="dropdown-item" onClick={() => updateActivity({
                                            id: data?.id,
                                            title: data?.title,
                                            activity_related_project: data?.activity_related_project
                                        })}>Edit</span>
                                    </>
                                )
                            }
                            {
                                (data?.status === 'Close' && globalState.dataUser?.role_name === 'Administrator') && (
                                    <>
                                        <span className="dropdown-item" onClick={() => updateStatusActivity(data, 'Open')}>Open</span>
                                    </>
                                )
                            }
                            {
                                data?.status === 'Open' && (
                                    <>
                                        <span className="dropdown-item" onClick={() => updateStatusActivity(data, 'Close')}>Close</span>
                                        <span className="dropdown-item text-danger" onClick={() => deleteActivity(data?.id)}>Delete</span>
                                    </>
                                )
                            }
                            {/* {
                            [...(d.research_reports?.split(',') || [])].map((d, i) => (
                                <a className="dropdown-item" href={baseUrl + d} target='_blank' download>File Report {i + 1}</a>
                            ))
                        } */}
                        </DropdownButton>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <GridDx
                            className="custom-style"
                            columns={column_activity}
                            cellRender={cellRender}
                            data={[...(data?.activity_notes || [])]}
                        >
                            {
                                data?.status === 'Open' && (
                                    <Column caption="Action" type="buttons" headerCellRender={headerCellRender} >
                                        <Button name="customEdit" visible={true} render={editBtn} />
                                        <Button name="delete" visible={true} render={deleteBtn} />
                                    </Column>
                                )
                            }
                        </GridDx>
                    </div>
                </div>
            </>
        )
    }
    function renderItemAktifitasReporting(data) {
        return (
            <>
                <div className="row mb-3">
                    <div className="col-md-9 mb-3">
                        <span>Detail</span>
                        <hr />
                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <span className="d-block">Terakhir Diperbarui</span>
                                <span className="d-block"><b>{data?.updated_at_form}</b></span>
                            </div>
                            <div className="col-md-2 mb-3">
                                <span className="d-block">Oleh</span>
                                <span className="d-block"><b>{data?.editor_name}</b></span>
                            </div>
                            <div className="col-md-2 mb-3">
                                <span className="d-block mb-1">Status</span>
                                <span className={`px-3 py-1 rounded ${data?.status === 'Open' ? 'status-bg-open' : 'status-bg-close'}`}><b>{data?.status}</b></span>
                            </div>
                            <div className="col-md-6 mb-3">
                                <span className="d-block">Proyek berkaitan</span>
                                <ul>
                                    {

                                        [...(data?.activity_related_project || [])].map((d, i) => (
                                            <li key={i}>
                                                <Link to={`/OngoingResearch/view/${d?.research_id}`} target="_blank">
                                                    {d?.title}
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-3">
                        <span>Action</span>
                        <hr />
                        {
                            data?.status === 'Open' && (
                                <>
                                    <button className="btn btn-sm btn-primary mx-1"
                                        onClick={() => addNotes(data?.id)}
                                    >
                                        <i className="fa fa-plus icon-nm"></i>
                                        Notes
                                    </button>
                                </>
                            )
                        }
                        <DropdownButton size="sm" id="dropdown-basic-button" title={
                            <>
                                <span className="rounded svg-icon svg-icon-sm svg-icon-success">
                                    <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                </span>
                                <span>Option</span>
                            </>
                        }
                            className="mx-1 d-inline dropdown-test">
                            {
                                data?.status === 'Open' && (
                                    <>
                                        <span className="dropdown-item" onClick={() => updateActivity({
                                            id: data?.id,
                                            title: data?.title,
                                            activity_related_project: data?.activity_related_project
                                        })}>Edit</span>
                                    </>
                                )
                            }
                            {
                                (data?.status === 'Close' && globalState.dataUser?.role_name === 'Administrator') && (
                                    <>
                                        <span className="dropdown-item" onClick={() => updateStatusActivity(data, 'Open')}>Open</span>
                                    </>
                                )
                            }
                            {
                                data?.status === 'Open' && (
                                    <>
                                        <span className="dropdown-item" onClick={() => updateStatusActivity(data, 'Close')}>Close</span>
                                        <span className="dropdown-item text-danger" onClick={() => deleteActivity(data?.id)}>Delete</span>
                                    </>
                                )
                            }
                        </DropdownButton>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <GridDx
                            className="custom-style"
                            columns={column_activity}
                            cellRender={cellRender}
                            data={[...(data?.activity_notes || [])]}
                        >
                            {/* {
                                data?.status === 'Open' && (
                                    <Column caption="Action" type="buttons" headerCellRender={headerCellRender} >
                                        <Button name="customEdit" visible={true} render={editBtn} />
                                        <Button name="delete" visible={true} render={deleteBtn} />
                                    </Column>
                                )
                            } */}
                        </GridDx>
                    </div>
                </div>
            </>
        )
    }
    function cellRender(rowInfo) {
        if (rowInfo.column.name === 'created_at' || rowInfo.column.name === 'deadline') {
            return <span>{moment(rowInfo.value).format('YYYY-MM-DD')}</span>
        } else {
            return <span className="text-wrap">{rowInfo.value}</span>
        }
    }
    function editBtn(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1"
                onClick={() => editNotes(data?.data)}
            >
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtn(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deleteNotes(data?.data?.id)}
            >
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function headerCellRender(e) {
        return <p style={{ fontWeight: 'bolder', fontSize: '9pt', fontFamily: 'Poppins' }} className="text-wrap">{e.column.caption}</p>;
    }
    function addActivity() {
        setModeEditorActivity('Add');
        setDataEditorActivity({});
        globalAction.toggleModal('modalActivity');
    }
    function updateActivity(data = {}) {
        setModeEditorActivity('Edit');
        setDataEditorActivity(data);
        globalAction.toggleModal('modalActivity');
    }
    function updateStatusActivity(data, status = '') {
        const x = {
            "id": data?.id,
            "title": data?.title,
            "status": status,
            "creator": '',
            "editor": globalState.dataUser?.id,
            "keterangan": "",
            "created_at": "",
            "updated_at": "",
            "activity_related_project": [],
            "activity_related_project_delete": []
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                `<strong>Update status aktifitas menjadi ${status} ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postUpdateStatusActivity(x);
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteActivity(id) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Aktifitas?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postDeleteActivity(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function addNotes(activity_id = '') {
        setModeEditorNotes('Add');
        setDataEditorNotes({
            activity_id: activity_id
        });
        globalAction.toggleModal('modalNotes');
    }
    function editNotes(data) {
        // console.log(data)
        setModeEditorNotes('Edit');
        setDataEditorNotes(data);
        globalAction.toggleModal('modalNotes');
    }
    function deleteNotes(id) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Note?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postDeleteNotes(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/OngoingResearch-public/view/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span>
                </Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName(e, dataDahsboard?.data_bulanNow))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }
    return <hdContext.Provider value={{
        hdState: {
            globalState,
            loadingDashboard,
            dataDahsboard,
            dataActivity,
            dataRelated,
            yAxis_chart1,
            swChart1,
            yAxis_chart2,
            swChart2,
            swChart1Unggulan,
            yAxis_chart1Unggulan,
            swChart2Unggulan,
            yAxis_chart2Unggulan,
            modeEditorActivity,
            dataEditorActivity,
            modeEditorNotes,
            dataEditorNotes,
            year_search,
            month_search,
            data_page
        },
        hdAction: {
            globalAction,
            getDashboard,
            getDashboardAdmin,
            getActivity,
            yearChange,
            yearChangeAdmin,
            monthChange,
            monthChangeAdmin,
            getDataYear,
            getPerformRTI,
            getPercentagePErform,
            getScurve1,
            getScurve2,
            getScurve1Unggulan,
            getScurve2Unggulan,
            switch1Change,
            switch2Change,
            switch1UnggulanChange,
            switch2UnggulanChange,
            getChartDoughnat,
            renderCenterLabelDoughnat,
            renderTitleAktifitas,
            renderItemAktifitas,
            renderItemAktifitasReporting,
            addActivity,
            updateActivity,
            postCreateActivity,
            postUpdateActivity,
            postAddNotes,
            postUpdateNotes,
            cstmCellRender
        }
    }}>
        {children}
    </hdContext.Provider>
}

export default function useHighlightDash() {
    return useContext(hdContext)
}