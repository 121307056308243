import React, { useEffect } from 'react';
import { CardBerita, CardMenu, CarouselSpiirit, PopularCard } from '../../../components';
import { useGlobalState } from '../../../contexts/useGlobalState';
import './home.scss';
import '../../../components/utility/utility.css'
import { Card } from 'react-bootstrap';
import moment from 'moment';
import useHome, { HmProvider } from './useHome';
require('./style.css')

function Index(props) {

  const [state] = useGlobalState();
  const { hmState: {
    dataDash = {},
    sub_holding_guest = true,
    loading = true
  } } = useHome();

  return (
    <React.Fragment>
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <h3>
              Selamat datang, <strong>{state.dataUser?.name}</strong>
            </h3>
          </div>
        </div>
        {
          !sub_holding_guest && <>
            <div className="row">
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/Projectmanagement/Dashboard'
                  title="Project Management"
                  iconUrl="/media/svg/icons/Shopping/Chart-bar2.svg"
                  colour="#326BD5"
                />
              </div>
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/People/dashboard'
                  title="Human Capital Management"
                  iconUrl="/media/svg/icons/General/User.svg"
                  colour="#F2BA22"
                />
              </div>
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/knowledge/research'
                  title="Knowledge Management"
                  iconUrl="/media/svg/icons/Home/Book-open.svg"
                  colour="#717EE2"
                />
              </div>
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/product/dashboard'
                  title="Product Management"
                  iconUrl="/media/svg/icons/Clothes/Briefcase.svg"
                  colour="#ACC429"
                />
              </div>
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/comming-soon'
                  title="Inventory Management"
                  iconUrl="/media/svg/icons/Shopping/Box3.svg"
                  colour="#3DC4DD"
                />
              </div>
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/equipment-mng/dashboard'
                  title="Equipment Management"
                  iconUrl="/media/svg/icons/Design/PenAndRuller.svg"
                  colour="#46CCB1"
                />
              </div>
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/swakelola/dashboard'
                  title="Swakelola Management"
                  iconUrl="/media/svg/icons/Shopping/Cart1.svg"
                  colour="#894CEA"
                />
              </div>
              <div className="col-md-3 mb-5">
                <CardMenu
                  url='/Kerjasama/dashboard'
                  title="Partnership Management"
                  iconUrl="/media/svg/icons/Design/Difference.svg"
                  colour="#C070CC"
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <h3>Broadcast RTI</h3>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CarouselSpiirit
                  showItem={1}
                  jumlahItem={1}
                  showTumbs={false}
                  withButton={true}
                >
                  {
                    []?.map((val, index) => (
                      <div key={index}
                      >
                        <div style={{ 
                          'height':'300px'
                         }}>

                        </div>
                      </div>
                    ))
                  }
                </CarouselSpiirit>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <h3>Info Terbaru SPIIRIT</h3>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CarouselSpiirit
                  showItem={3}
                  jumlahItem={dataDash.data_berita?.length}
                  showTumbs={false}
                  withButton={true}
                >
                  {
                    dataDash.data_berita?.map((val, index) => (
                      <div key={index}
                      >
                        <CardBerita
                          linkProps={
                            {
                              to: `/library-detail/${val?.id}`, target: '_blank'
                            }
                          }
                          imgUrl={val.cover_location || ''}
                          title={val.title}
                          height='30px'
                          date={val.created_at ? moment(val.created_at).format('DD MMM YYYY') : ''}
                          kategory={val.book_category_name}
                        />
                      </div>
                    ))
                  }
                </CarouselSpiirit>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <h3>Populer di SPIIRIT</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-5 mb-sm-5">
                <PopularCard
                  linkProps={{ to: `/OngoingResearch-public/view/${dataDash.data_research?.id}`, target: '_blank' }}
                  spanColour="#ABDE58"
                  spanText="Research"
                  imgSrc={(dataDash.base_url + dataDash.data_research?.cover_file_location) || ''}
                  title={dataDash.data_research?.title}
                >
                  <div className="input-group input-group-sm mb-1 font-card">
                    <div className="input-group-prepend">
                      <span >Kategori :&nbsp;</span>
                    </div>
                    <span className="text-wrap">{dataDash.data_research?.research_category_id}</span>
                  </div>
                  <div className="input-group input-group-sm mb-1 font-card">
                    <div className="input-group-prepend">
                      <span >Tahun :&nbsp;</span>
                    </div>
                    <span className="text-wrap">{dataDash.data_research?.year_start} - {dataDash.data_research?.year_end}</span>
                  </div>
                </PopularCard>
              </div>
              <div className="col-md-3 col-sm-12 mb-5 mb-sm-5">
                <PopularCard
                  colour="#BF73CA"
                  linkProps={{ to: `/library-detail/${dataDash.data_book?.id}`, target: '_blank' }}
                  spanText="Library"
                  imgSrc={dataDash.data_book?.cover_location || ''}
                  spanColour="#BF73CA"
                  title={dataDash.data_book?.title}
                >
                  <div className="input-group input-group-sm mb-1 font-card">
                    <div className="input-group-prepend">
                      <span >Kategori :&nbsp;</span>
                    </div>
                    <span className="text-wrap">{dataDash.data_book?.book_category_id}</span>
                  </div>
                  <div className="input-group input-group-sm mb-1 font-card">
                    <div className="input-group-prepend">
                      <span >Tahun :&nbsp;</span>
                    </div>
                    <span className="text-wrap">{dataDash.data_book?.year_start} - {dataDash.data_research?.year_end}</span>
                  </div>
                </PopularCard>
              </div>
              <div className="col-md-3 col-sm-12 mb-5 mb-sm-5">
                <PopularCard
                  linkProps={{ to: `/People/achievement-detail/${dataDash.data_achievement?.id}`, target: '_blank' }}
                  spanColour="#717EE2"
                  spanText="Achievement"
                  imgSrc={dataDash.data_achievement?.photo_location || ''}
                  title={dataDash.data_achievement?.name}
                >

                  <div className="input-group input-group-sm mb-1 font-card">
                    <div className="input-group-prepend">
                      <span >Level :&nbsp;</span>
                    </div>
                    <span className="text-wrap">{dataDash.data_achievement?.level}</span>
                  </div>
                  <div className="input-group input-group-sm mb-1 font-card">
                    <div className="input-group-prepend">
                      <span >Tahun :&nbsp;</span>
                    </div>
                    <span className="text-wrap">{dataDash.data_achievement?.year_of_achievement}</span>
                  </div>
                </PopularCard>
              </div>
              <div className="col-md-3 col-sm-12 mb-5 mb-sm-5">
                <PopularCard
                  linkProps={{ to: `/product/detail/${dataDash.data_product?.id}`, target: '_blank' }}
                  spanColour="#46CCB1"
                  spanText="Product"
                  imgSrc={dataDash.data_product?.photo_location || ''}
                  title={dataDash.data_product?.name}
                >
                  <div className="d-flex mb-1 font-card">
                    <span className="mr-1">Background:</span>
                    <span className="text-truncate">{dataDash.data_product?.background}</span>
                  </div>
                  <div className="d-flex mb-1 font-card">
                    <span >Tahun :&nbsp;</span>
                    <span className="text-wrap">{dataDash.data_product?.release_year}</span>
                  </div>
                </PopularCard>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <h3>Event yang akan datang <i className="far fa-calendar-check icon-xl text-dark"></i></h3>
              </div>
            </div>
            <Card
              className="customShadow"
            >
              <Card.Body style={{ overflow: 'hidden' }}>
                <div className="row">
                  <div className="col-6">
                    <CarouselSpiirit
                      showItem={1}
                      jumlahItem={dataDash.data_berita?.length}
                      showTumbs={true}
                      withButton={false}
                    >
                      {
                        [...(dataDash.data_event || [])].length > 0 ?
                          dataDash.data_event?.map((val, index) => (
                            <div key={index}>
                              <CardBerita
                                linkProps={
                                  {
                                    to: `/people/event-detail/${val?.id}`,
                                    target: '_blank'
                                  }
                                }
                                cardStyle={false}
                                imgUrl={val.poster_location || ''}
                                title={val.name}
                                date={moment(val.created_at).format('DD MMMM YYYY')}
                                kategory={val.organizer}
                              />
                            </div>
                          )) :
                          (
                            <CardBerita
                              linkProps={
                                {
                                  to: "#"
                                }
                              }
                              cardStyle={false}
                              imgUrl='/images/no-event.jpg'
                              title='No Upcoming Event'
                              date='-'
                              kategory='-'
                            />
                          )
                      }
                    </CarouselSpiirit>
                  </div>
                  <div className="col-6">
                    <img className='event-bg' src='/homeasset/home_event.svg' />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </>
        }
      </div>
    </React.Fragment>
  )
};

export default (props) => <HmProvider><Index {...props} /></HmProvider> 
