import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useGlobalState } from '../../../contexts/useGlobalState';


const projectProfileContext = createContext(null)
export const PfProvider = ({children}) => {
    const [ , , gAction ] = useGlobalState();
    const { baseline_name = undefined, id  = undefined} = useParams()
    const [ data, setData ] = useState({});
    const [ dataMilestone, setDataMilestone] = useState([])
    const [zoom, setZoom] = useState("80%");
    async function getDataProfile(){
        const {code, data = {}} = await gAction.getData(
            "/api/project-ongoing-crud/view-project-profile", 
            {  id, baseline_name },
            null, 
            "loadingGet", 
            true
        );
        if(code === "00"){
            // console.log(data)

            const { data_view_timeline = [] } = data;
            let newDataMilesTone = [];

            data_view_timeline
                .forEach((vt)=> {
                    vt.data.filter(
                        vr=>(vr
                            .type === "milestones" || vr.type === "submilestones"
                            )
                        ).forEach( md => newDataMilesTone = [ ...newDataMilesTone, {
                            task:md.task,
                            type:md.type.charAt(0).toUpperCase() + md.type.slice(1),
                            year:vt.year
                        }]) 
                });

            setDataMilestone(newDataMilesTone)
                // console.log(newDataMilesTone)
            setData(data);
        }
    }

    function getObject(x){
        if(Array.isArray(x))
            return x.length > 0 ? x[0] : {}
        return x || {}
    }

    useEffect(function(){
        getDataProfile()
    }, [])


    return <projectProfileContext.Provider value={{
        pfState : {
            data,
            zoom,
            dataMilestone
        },
        pfAction : { 
            getObject,
            setZoom
        }
    }}>{children}</projectProfileContext.Provider>;
}
 
export default function useProjectProfile() { return useContext(projectProfileContext) };