import React, { useEffect, useRef, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Card } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { GridDx, HeaderList, Modal } from '../../components';
import ProductGridDetail from './productGridDetail';
import { Link, useHistory } from 'react-router-dom';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';

const column = [
    { fieldName: "name", caption: "Nama Product", width: 300 },
    { fieldName: "list_field_test", caption: "Total Field Test (Ribu USD)", width: 150, onExport: (e) => [...(e?.list_field_test || [])].map(d => " - " + d?.year + " : " + parseFloat(d?.price, 0).toFixed(2)).join(`\u2029`) + `\u2029 - Total : ` + parseFloat(e.field_test_total, 0).toFixed(5) },
    { fieldName: "list_commercial", caption: "Total Commercial (Ribu USD)", width: 150, onExport: (e) => [...(e?.list_commercial || [])].map(d => " - " + d?.year + " : " + parseFloat(d?.price, 0).toFixed(2)).join(`\u2029`) + `\u2029 - Total : ` + parseFloat(e.commectial_total, 0).toFixed(5) },
    { fieldName: "list_contribution", caption: "Product Contribution (Ribu USD)", width: 150, onExport: (e) => [...(e?.list_contribution || [])].map(d => " - " + d?.year + " : " + parseFloat(d?.price, 0).toFixed(2)).join(`\u2029`) + `\u2029 - Total : ` + parseFloat(e.price, 0).toFixed(5) },
    { fieldName: "inventor_name", caption: "Inventor", width: 300 },
    { fieldName: "nama_PIC_str", caption: "PIC Contributor", width: 300 },
    { fieldName: "kontak_name", caption: "Kontak", width: 200 },
    { fieldName: "product_types_name", caption: "Tipe Product", width: 100 },
    { fieldName: "product_status_name", caption: "Status Product", width: 100 },
    { fieldName: "contrib_type", caption: "Contribution type", width: 200 },
    { fieldName: "release_year", caption: "Tahun Rilis", width: 100 },
]

function CrudProduct(props) {
    const [state, dispatch, action] = useGlobalState();
    const tableRef = useRef();
    const [data, setData] = useState([]);
    const history = useHistory();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        getData();
    }, []);
    function getData() {
        action.getData('/api/ext-product/table-view', {
        }, null, 'loadingGet', true).then((resp) => {
            if (resp.code === '00') {
                setData(resp.data.data_tabel);
                setData_page(resp.data_page);
            }
        })
    }
    function cstmCellRender(e) {
        if (e.column.name === "action") {
            return (
                <div className='text-center'>
                    <button className='btn btn-sm btn-icon btn-light-danger'
                        onClick={() => deleteData(e?.data?.id)}
                    ><i className='fa fa-trash-alt icon-nm'></i></button>
                </div>
            )
        } else if (e.column.name === "name") {
            return (
                <>
                    {
                        (!e?.data?.is_pic_contributor) ? (
                            <Link to={`#`}
                                onClick={() => {
                                    return Swal.fire({
                                        title: 'Warning',
                                        text: "Anda tidak terdaftar sebagai PIC Product Contributor",
                                        icon: 'warning',
                                    })
                                }}
                            >
                                <span className="text-wrap">{e.value}</span>
                            </Link>
                        ) : (
                            <Link to={`/admin/product/detail/${e?.data?.id}`}
                                target='_blank'
                            >
                                <span className="text-wrap">{e.value}</span>
                            </Link>
                        )
                    }
                </>
            )
        }
        else if (e.column.name === 'list_field_test') {
            return (
                <div>
                    {
                        [...(e.value || [])].map((d, i) => (
                            <span className="d-block text-wrap p-2">{d?.year} : {parseFloat(d.price, 0).toFixed(5)}</span>
                        ))
                    }
                    <span className="d-block text-wrap p-2">Total : {parseFloat(Number(e.data?.field_test_total), 0).toFixed(5)}</span>
                </div>
            )
        }
        else if (e.column.name === 'list_commercial') {
            return (
                <div>
                    {
                        [...(e.value || [])].map((d, i) => (
                            <span className="d-block text-wrap p-2">{d?.year} : {parseFloat(d?.price, 0).toFixed(5)}</span>
                        ))
                    }
                    <span className="d-block text-wrap p-2">Total : {parseFloat(Number(e.data?.commercial_total), 0).toFixed(5) || 0}</span>
                </div>
            )
        }
        else if (e.column.name === 'list_contribution') {
            return (
                <div>
                    {
                        [...(e.value || [])].map((d, i) => (
                            <span className="d-block text-wrap p-2">{d?.year} : {parseFloat(d?.price, 0).toFixed(5)}</span>
                        ))
                    }
                    <span className="d-block text-wrap p-2">Total : {parseFloat(Number(e.data?.price), 0).toFixed(5)}</span>
                </div>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function searchChange(value) {
        if (tableRef) {
            tableRef.current.instance.searchByText(value)
        }
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Product ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/km-product/delete?id=${id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded customShadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Clothes/Briefcase.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Product</span>
                                    <span className="d-block"><strong>{state.HeaderTotal || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Upstream Tech</span>
                                    <span className="d-block"><strong>{state.HeaderUpstream || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Material and Speciality Chemical</span>
                                    <span className="d-block"><strong>{state.HeaderMaterial || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Petroleum Fuel</span>
                                    <span className="d-block"><strong>{state.HeaderPetroleum || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Petrochemical and non-fuel</span>
                                    <span className="d-block"><strong>{state.HeaderPetrochemical | 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NRE Fuel</span>
                                    <span className="d-block"><strong>{state.HeaderNREF || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NRE Power</span>
                                    <span className="d-block"><strong>{state.HeaderNREP || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Laboratory Service</span>
                                    <span className="d-block"><strong>{state.HeaderLab || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Others</span>
                                    <span className="d-block"><strong>{state.HeaderOther || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5 justify-content-between">
                        <div className="col-md-3 mb-3">
                            <div className="formSearch">
                                <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => searchChange(e.target.value)} />
                                <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 text-right">
                            <button className="btn btn-sm btn-primary" onClick={() => history.push('/admin/product/add')}>+ Product</button>
                        </div>
                    </div>
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12 ">
                            <GridDx
                                className='custom-style-master'
                                masterDetail={true}
                                repaintChangesOnly={true}
                                //onRowExpanded={getData}
                                ComponenMasterDetail={ProductGridDetail}
                                ref={tableRef}
                                loading={state.loadingGet}
                                data={data}
                                cellRender={cstmCellRender}
                                columns={[{ fieldName: "action", caption: "Action", width: 100 }, ...column]}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                                actions={[
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => { deleteData(v?.id) }
                                    },
                                    {
                                        name: "Edit Data Product",
                                        icon: "fa fa-pencil-alt icon-nm text-secondary",
                                        action: (v, c) => { history.push(`/admin/product/edit/${v?.id}`) }
                                    },
                                    {
                                        name: "Update Data Product Contrib.",
                                        icon: "fa fa-pencil-alt icon-nm text-secondary",
                                        action: (v, c) => {
                                            if (v.contrib_type === "Indirect") {
                                                return Swal.fire({
                                                    title: 'Warning',
                                                    text: "Product dengan contribution type 'Inderect' tidak bisa di update",
                                                    icon: 'warning',
                                                })
                                            }
                                            if (!v?.is_pic_contributor) {
                                                return Swal.fire({
                                                    title: 'Warning',
                                                    text: "Anda tidak terdaftar sebagai PIC Product Contributor",
                                                    icon: 'warning',
                                                })
                                            }
                                            history.push(`/admin/product/detail/${v?.id}`)
                                        }
                                    }
                                ]}
                            >
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default CrudProduct;