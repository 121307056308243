import React from 'react';
import { GanttChartP } from '../../../components';

const ProjectTimelineGantt = (
    {
        year,
        deliverable_research_year_id,
        data = [],
    }
) => {
    const type = [
        { "value": "milestones", "name": "Milestone" }, 
        { "value": "submilestones", "name": "Sub Milestone" }, 
        { "value": "task", "name": "Task" }, 
        { "value": "subtask", "name": "Sub Task" }
    ];
    return (
        <>
            <div className="row">
                <div className="col">
                    <span className="page-title-pp">
                        Monthly <span className="badge badge-primary"> {year} </span>
                    </span>
                    <hr />

                    <GanttChartP 
                        dataSource={data}
                        column={[
                            { "fieldName": "task", "caption": "Task", minWidth: 100 },
                            { "fieldName": "type", "caption": "Type", "custom": true, "customType": "lookup", "dsCustom": type, "displayExpr": "name", "valueExpr": "value", "visible": true, width: 100 },
                            { "fieldName": "plan_jan", "caption": "JAN", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_feb", "caption": "FEB", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_mar", "caption": "MAR", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_apr", "caption": "APR", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_may", "caption": "MAY", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_jun", "caption": "JUN", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_jul", "caption": "JUL", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_aug", "caption": "AUG", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_sep", "caption": "SEP", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_oct", "caption": "OCT", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_nov", "caption": "NOV", "visible": true, "dataType": "number", width: 50 },
                            { "fieldName": "plan_dec", "caption": "DEC", "visible": true, "dataType": "number", width: 50 }
                        ]}
                    />
                </div>
            </div>
        </>
    );
}

export default ProjectTimelineGantt;