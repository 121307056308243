import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { ChartDx, CstmFilterCahrtDx, DoughnatDx } from '../../components';
import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import useInventory from './useInventory';

const dataDummy = [
    { kategori: '1', total: 100 },
    { kategori: '2', total: 200 },
    { kategori: '3', total: 300 },
    { kategori: '4', total: 400 },
];
const dataDummy2 = Array.from(Array(10), (_, i) => {
    return {
        state: i + 1,
        total: 100 - (i * 10),
        percentage: (10 * (i + 1)) * 100 / 100,
    }
})
const dataDummy3 = Array.from(Array(10), (_, i) => {
    return {
        state: 'Lorem ipsum ' + (i + 1),
        data_1: 100 - ((i + 1) * 10),
        data_2: 10
    }
})
const dataDummy4 = Array.from(Array(10), (_, i) => {
    return {
        name: `User ${i + 1}`,
        jumlah: (i + 1) * 10
    }
})
const dataDummy5 = Array.from(Array(5), (_, i) => {
    return {
        name: `Suplier ${i + 1}`,
        jumlah: (i + 1) * 10
    }
})
function TabDashboard(props) {
    const { invState, invAction } = useInventory();

    return (
        <>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-2">
                    <div className="row" style={{ fontSize: '8pt' }}>
                        <div className="col-md-2">
                            <div className="d-flex border-right">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark" style={{ width: '32.5px', height: '32.5px' }}>
                                    <SVG src="/media/svg/icons/Communication/Clipboard-list.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block text-nowrap mr-2">Total Inventory</span>
                                    <span className="d-block text-nowrap mr-2" style={{ fontSize: '6pt' }}>/ Nilai Stock</span>
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row justify-content-around">
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 1</span>
                                            <span className="d-block text-nowrap mr-2" style={{ fontSize: '6pt' }}>/ Nilai Stock</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 2</span>
                                            <span className="d-block text-nowrap mr-2" style={{ fontSize: '6pt' }}>/ Nilai Stock</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 3</span>
                                            <span className="d-block text-nowrap mr-2" style={{ fontSize: '6pt' }}>/ Nilai Stock</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 px-0">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block text-nowrap mr-2">Kategori 4</span>
                                            <span className="d-block text-nowrap mr-2" style={{ fontSize: '6pt' }}>/ Nilai Stock</span>
                                            <span className="d-block"><strong>0</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-flex border-left">
                                <div className="pl-3 text-white">
                                    <span className="d-block text-nowrap mr-2">Total Recieve</span>
                                    <span className="d-block text-nowrap mr-2" style={{ fontSize: '6pt' }}>/ Nilai Investasi</span>
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                                <select className="form-control form-control-sm"></select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-flex border-left">
                                <div className="pl-3 text-white">
                                    <span className="d-block text-nowrap mr-2">Total Dispatch</span>
                                    <span className="d-block text-nowrap mr-2" style={{ fontSize: '6pt' }}>/ Nilai Investasi</span>
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                                <select className="form-control form-control-sm"></select>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className="row mb-5">
                {/* <div className="col-md-4">
                    <Card
                        className="p-0 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div className="d-flex">
                                <i style={{ width: '40px', height: '40px' }} className="fas fa-boxes bg-primary text-white rounded customShadow d-flex align-items-center justify-content-center"></i>
                                <div className="ml-5">
                                    <span className="d-block" style={{ fontSize: '12px' }}><b>Total Inventory</b></span>
                                    <span style={{ fontSize: '9px' }}>Statistik inventory berdasarkan kategori</span>
                                </div>
                            </div>
                            <hr />
                            <DoughnatDx
                                height={250}
                                withTitle={false}
                                dataSource={dataDummy}
                                showLegend={false}
                                LegendverticalAlignment="bottom"
                                LegendhorizontalAlignment="center"
                                LegenditemTextPosition="top"
                                toolsExport={false}
                                centerRender={renderCenter}
                                onRenderLabelChart = {(e) => (`Kategori ${e.argumentText} ${e.value}`)}
                                onRenderTooltip={(e) => ({ text: `Kategori ${e.argumentText} = ${e.value}` })}
                                yAxisArgs={[
                                    {
                                        valueField: "total",
                                        argumentField: "kategori",
                                        color: null,
                                        format: null
                                    }
                                ]}

                            />
                        </Card.Body>
                    </Card>
                </div> */}
                <div className="col-md-6">
                    <CstmFilterCahrtDx
                        title="Inventory Value & Healthy Stock Evolution"
                        subtitle="Nilai inventory & Evolusi Stok Sehat"
                    >
                        <ChartDx
                            id="6"
                            argumentField="arg"
                            dataSource={invAction.getChart1(invState.dataDashboard?.data_chart_1)}
                            withTitle={false}
                            showLegend={true}
                            toolsExport={false}
                            yAxisArgs={[
                                {
                                    valueField: "value",
                                    name: 'Value in USD',
                                    type: "bar",
                                    color: '#326BD5'
                                },
                                {
                                    valueField: "uhs_value",
                                    name: 'Healthy Stock',
                                    type: "spline",
                                    color: '#01E396'
                                }
                            ]}

                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-6">
                    <CstmFilterCahrtDx
                        title="Strorage Utilization"
                        subtitle="Sisa ruangan pada setiap gudang"
                    >

                    </CstmFilterCahrtDx>

                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-4">
                    <CstmFilterCahrtDx
                        title="Top Inventory Item by Value"
                        subtitle="10 besar inventory dengan value terbanyak"
                    >
                        <ChartDx
                            id="3"
                            labelChart={true}
                            argumentField="name"
                            dataSource={invState.dataDashboard?.data_chart_3}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="top"
                            onrenderLabel={(e) => `${e.value.length > 10 ? (e.value.substr(0, 10) + '...') : e.value}`}
                            onRenderLabelChart={() => { }}
                            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                            overlappingBehavior='stagger'
                            rotate={true}
                            yAxisArgs={[
                                {
                                    valueField: "harga",
                                    color: '#326BD5'
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-4">
                    <CstmFilterCahrtDx
                        title="Highest Risk of Stock Out"
                        subtitle="10 besar inventory dengan stok menipis"
                    >
                        <ChartDx
                            id="3"
                            labelChart={true}
                            argumentField="name"
                            dataSource={invState.dataDashboard?.data_chart_4}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="top"
                            onrenderLabel={(e) => `${e.value.length > 10 ? (e.value.substr(0, 10) + '...') : e.value}`}
                            onRenderLabelChart={() => { }}
                            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                            overlappingBehavior='stagger'
                            rotate={true}
                            yAxisArgs={[
                                {
                                    valueField: "harga",
                                    color: '#0CA7A1',
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-4">
                    <CstmFilterCahrtDx
                        title="Highest Risk of Excess Items"
                        subtitle="10 besar inventory dengan stok berlimpah"
                    >
                        <ChartDx
                            id="3"
                            labelChart={true}
                            argumentField="name"
                            dataSource={invState.dataDashboard?.data_chart_5}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="top"
                            onrenderLabel={(e) => `${e.value.length > 10 ? (e.value.substr(0, 10) + '...') : e.value}`}
                            onRenderLabelChart={() => { }}
                            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                            overlappingBehavior='stagger'
                            rotate={true}
                            yAxisArgs={[
                                {
                                    valueField: "harga",
                                    color: '#0CA7A1',
                                }
                            ]}
                        />
                    </CstmFilterCahrtDx>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <CstmFilterCahrtDx
                        title="Value Consumption"
                        subtitle="TOP 10 User dengan pemakaian inventory terbanyak"
                        option={[{ name: 'User', value: '0' }, { name: 'Fungsi VP', value: '1' }]}
                        value={invState.typeChart6}
                        onChange={(e) => invAction.setTypeChart6(e)}
                    >
                        <ChartDx
                            id="9"
                            colorBarDifferent={true}
                            argumentField="name"
                            colorBarDiffirentField="name"
                            coloBarDifferentValueField="harga"
                            dataSource={invAction.getChart6(invState.typeChart6)}
                            onRenderLabelChart={(e) => (`${e.valueText}`)}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            onRenderLabelChart={() => { }}
                            onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                            rotate={true}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-3">
                    <CstmFilterCahrtDx
                        title="Top Supplier Spend"
                        subtitle="TOP 5 Suplier dengan pengeluaran uang terbanyak"
                    >
                        <ChartDx
                            id="9"
                            colorBarDifferent={true}
                            argumentField="name"
                            colorBarDiffirentField="name"
                            coloBarDifferentValueField="harga"
                            dataSource={invState.dataDashboard?.data_chart_7}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            onRenderLabelChart={(e) => (`${e.valueText} $`)}
                            onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                            rotate={true}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-3">
                    <CstmFilterCahrtDx
                        title="Top Supplier Value"
                        subtitle="TOP 5 suplier dengan pembelian item terbanyak"
                    >
                        <ChartDx
                            id="9"
                            colorBarDifferent={true}
                            argumentField="name"
                            colorBarDiffirentField="name"
                            coloBarDifferentValueField="jumlah"
                            dataSource={invState.dataDashboard?.data_chart_8}
                            withTitle={false}
                            showLegend={false}
                            toolsExport={false}
                            onRenderLabelChart={(e) => (`${e.valueText}`)}
                            onRenderTooltip={(e) => ({ text: `${e.seriesName}: ${e.valueText}` })}
                            rotate={true}
                        />
                    </CstmFilterCahrtDx>
                </div>
                <div className="col-md-3">
                    <CstmFilterCahrtDx
                        title="Scrap Value"
                        subtitle="Inventory yang memasuki masa kadaluarsa"
                    >
                        <DoughnatDx
                            height={250}
                            withTitle={false}
                            dataSource={dataDummy}
                            showLegend={false}
                            LegendverticalAlignment="bottom"
                            LegendhorizontalAlignment="center"
                            LegenditemTextPosition="top"
                            toolsExport={false}
                            onRenderLabelChart={(e) => (`Kategori ${e.argumentText} ${e.value}$`)}
                            centerRender={invAction.renderCenterScrap}
                            onRenderTooltip={(e) => ({ text: `Kategori ${e.argumentText} = ${e.value}` })}
                            yAxisArgs={[
                                {
                                    valueField: "total",
                                    argumentField: "kategori",
                                    color: null,
                                    format: null
                                }
                            ]}

                        />
                    </CstmFilterCahrtDx>
                </div>
            </div>
        </>
    );
}

export default TabDashboard;