import React, { useRef } from 'react';
import { GridDx } from '../../../../../components';
import useLabMaster from '../useLabMaster';
import { formatter } from '../../../../../components/utility/utility';

const column = [
    { fieldName: "lab_master_como_nama", caption: "Jenis Komoditi", groupIndex: 0 },
    { fieldName: "parameter_analisis", caption: "Parameter Analisis", allowSorting: false, allowFiltering: false, allowGrouping: false },
    { fieldName: "metode", caption: "Metode Pengujian", allowSorting: false, allowFiltering: false, allowGrouping: false },
    { fieldName: "harga", caption: "Harga", allowFiltering: false, allowGrouping: false },
    {
        fieldName: "active", caption: "Active", allowFiltering: true, allowGrouping: false, headerFilterSource: [
            { text: 'Aktif', value: true },
            { text: 'Tidak Aktif', value: false },
        ]
    },
    { fieldName: "note", caption: "Keterangan", allowFiltering: false, allowGrouping: false },
];


function TabParameterAnalisis(props) {
    const tableRef = useRef();
    const { lmAction, lmState } = useLabMaster();
    function onSearch(val) {
        if (tableRef.current) {
            tableRef.current.instance.searchByText(val)
        }
        lmAction.setSearchParam(val)
    }

    const customCellRender = (rowInfo) => {
        if (rowInfo.column.name === 'harga') {
            return (<span className='d-block text-wrap text-right'>Rp. {formatter.format(rowInfo.value)}</span>)
        } else if (rowInfo.column.name === 'active') {
            return (<span className={`badge ${rowInfo.value ? 'badge-success' : 'badge-danger'}`}>{rowInfo.value ? "Aktif" : "Tidak Aktif"}</span>)
        } else {
            return (<span className='d-block text-wrap text-left'>{rowInfo.value}</span>)
        }
    }
    function expanCollapse() {
        if (lmState.expand) {
            if(tableRef.current){
                tableRef.current.instance.collapseAll();
            }
        } else {
            if(tableRef.current){
                tableRef.current.instance.expandAll();
            }
        }
        lmAction.setExpand(!lmState.expand);
    }
    return (
        <div>
            <div className='d-sm-block d-md-flex justify-content-between mb-2'>
                <div className='d-sm-block d-md-inline-flex mb-1'>
                    <button className='mr-2 btn btn-sm btn-warning mb-1' onClick={expanCollapse}>{lmState.expand ? 'Collapse' : 'Expand'} All</button>
                    <div className="formSearch" style={{ width: 300 }}>
                        <input value={lmState.searchParam} onChange={val => onSearch(val.target.value)} className="form-control form-control-sm" placeholder="Cari" />
                        <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                    </div>
                </div>
                <button className="btn btn-sm btn-primary mx-1 mb-1"
                    onClick={() => lmAction.showModalFormPA()}
                >
                    Tambah Data Parameter Analisis
                </button>
            </div>
            {React.useMemo(() => (
                <GridDx
                    className='custom-style-master'
                    ref={tableRef}
                    data={lmState?.dataPA || []}
                    columns={column}
                    cellRender={customCellRender}
                    pagination={true}
                    orderingDrag={false}
                    withAction={true}
                    searchForm={false}
                    currentPage
                    show
                    dataGroup={true}
                    actions={[
                        {
                            name: "Edit",
                            icon: "fa fa-pencil-alt icon-nm text-success",
                            action: (v, c) => {
                                lmAction.showModalFormPA(true, v)
                            }
                        },
                        {
                            name: "Delete",
                            icon: "fa fa-trash-alt icon-nm text-danger",
                            action: (v, c) => {
                                lmAction.deletePA(v?.id);
                            }
                        },
                    ]}
                ></GridDx>
            ), [lmState?.dataPA])}
        </div>
    );
}

export default TabParameterAnalisis;