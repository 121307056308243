import React, { useEffect } from 'react';
import { Card, DropdownButton, Dropdown } from 'react-bootstrap';
import { baseUrl } from '../../app.config';
import { HeaderList, ChartDx, PaginationCs } from '../../components';
import Skeleton from 'react-loading-skeleton';
import useKnowledgeMng, { KnoProvider } from './useKnowledgeMng';
import { research_level } from '../../components/utility/utility';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './knowledgeMng.css';
import { Link, useHistory } from 'react-router-dom';

function Research(props) {
    const { knoAction, knoState } = useKnowledgeMng();
    const history = useHistory();

    useEffect(() => {
        knoAction.getDataResearch(knoState.keyword_search);
        knoAction.getArea();
    }, [knoState.year_search, knoState.area_search, knoState.status_workflow_search, knoState.category_search])
    return (
        <>
            <HeaderList
                title={knoState.data_pager?.page_title}
                someText={knoState.data_pager?.page_breadcrumbs}
                optionList={[
                    {
                        withLabel: true,
                        label: "Kumpulan laporan proyek RTI yang telah selesai dilaksanakan ",
                    }
                ]}
            />
            <Card
                style={{ backgroundColor: '#C4E0FF' }}
                className="p-0 customShadow mb-5 header_research_display"
            >
                <Card.Body className="p-5">
                    <div className="row mb-5">
                        <div className="col-lg-3 col-md-3 col-12 mb-5">
                            <div className="rounded bg-warning p-2 card_1">
                                <div className="d-flex justify-content-between">
                                    <span className="font-bold">Total Research</span>
                                    <span className="font-bold">{knoState.dataResearch?.data_dataChart1?.total}</span>
                                </div>
                                <div className="mt-5">
                                    <ChartDx
                                        dataSource={knoAction.getChart1()}
                                        argumentField="state"
                                        height={120}
                                        withTitle={false}
                                        toolsExport={false}
                                        showLegend={false}
                                        yVisible={false}
                                        xVisible={false}
                                        yLabel={false}
                                        xLabel={true}
                                        onrenderLabel={(e) => e.value?.substring(0, 4)}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                        overlappingBehavior='rotate'
                                        rotationAngle={45}
                                        colorLabel="#000000"
                                        yAxisArgs={[
                                            {
                                                valueField: "value",
                                                color: '#FFFF'
                                            }
                                        ]}
                                    >

                                    </ChartDx>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 mb-5">
                            <div className="rounded bg-white p-2 card">
                                <div className="card_2"></div>
                                <div className="d-flex justify-content-between">
                                    <span className="d-block font-bold">Status</span>
                                    <span className="font-bold">{knoState.dataResearch?.data_dataChart2?.total}</span>
                                </div>
                                <div className="mt-5" style={{ zIndex: 0 }}>
                                    <ChartDx
                                        dataSource={knoAction.getChart2()}
                                        argumentField="state"
                                        height={120}
                                        withTitle={false}
                                        toolsExport={false}
                                        showLegend={false}
                                        yVisible={false}
                                        xVisible={false}
                                        yLabel={false}
                                        onrenderLabel={(e) => e.value?.substring(0, 4)}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                        overlappingBehavior='rotate'
                                        rotationAngle={45}
                                        yAxisArgs={[
                                            {
                                                valueField: "value",
                                            }
                                        ]}
                                    >

                                    </ChartDx>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 mb-5">
                            <div className="rounded bg-primary p-2 card_1">
                                <span className="d-block font-bold">Total Download</span>
                                <div className="mt-5">
                                    <ChartDx
                                        dataSource={[]}
                                        height={120}
                                        withTitle={false}
                                        toolsExport={false}
                                        showLegend={false}
                                        yVisible={false}
                                        xVisible={false}
                                        onrenderLabel={(e) => e.value?.substring(0, 4)}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                        overlappingBehavior='rotate'
                                        rotationAngle={45}
                                    >

                                    </ChartDx>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 mb-5">
                            <div className="rounded bg-success p-2 card_1">
                                <span className="d-block font-bold">Total View</span>
                                <div className="mt-5">
                                    <ChartDx
                                        dataSource={[]}
                                        height={120}
                                        withTitle={false}
                                        toolsExport={false}
                                        showLegend={false}
                                        yVisible={false}
                                        xVisible={false}
                                        onrenderLabel={(e) => e.value?.substring(0, 4)}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                        overlappingBehavior='rotate'
                                        rotationAngle={45}
                                    >

                                    </ChartDx>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6 mb-5">
                            <div className="d-flex">
                                <select className="form-control form-control-sm mx-1 w-25" value={knoState.year_search} onChange={(e) => knoAction.setYear_search(e.target.value)}>
                                    <option value="0" >- Semua Tahun -</option>
                                    {
                                        [...(knoState.dataResearch?.data_year_arr || [])].map((d, i) => (
                                            <option value={d.year_end}>{d.year_end}</option>
                                        ))
                                    }
                                </select>
                                <select className="form-control form-control-sm mx-1 w-25" value={knoState.area_search} onChange={(e) => knoAction.areaChange(e.target.value)}>
                                    <option value="0" >- Semua Area -</option>
                                    {
                                        [...(knoState.dataArea || [])].map((d, i) => (
                                            <option value={d.id}>{d.name}</option>
                                        ))
                                    }
                                </select>
                                <select className="form-control form-control-sm mx-1 w-25" value={knoState.category_search} onChange={(e) => knoAction.setCategory_search(e.target.value)}>
                                    <option value="0" >- Kategori -</option>
                                    {
                                        [...(knoState.dataKategori || [])].map((d, i) => (
                                            <option value={d.id}>{d.name}</option>
                                        ))
                                    }
                                </select>
                                <select className="form-control form-control-sm mx-1 w-25" value={knoState.status_workflow_search} onChange={(e) => knoAction.setStatus_workflow_search(e.target.value)}>
                                    <option value="0" >- Semua status -</option>
                                    {
                                        [...(research_level || [])].map((d, i) => (
                                            <option value={d.value}>{d.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-md-3 col-12 px-md-0 mb-5'>
                            <div className="d-flex">
                                <input className="form-control form-control-sm" placeholder="Cari Research"
                                    onKeyUp={(e) => knoAction.onEnterResearch(e)}
                                ></input>
                                <i className="fa fa-search icon-nm" style={{ position: 'absolute', right: '20px', top: '35%' }}></i>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex align-items-center">
                                <label className="my-0 mx-3">Level :</label>
                                <button className={'btn btn-sm mx-1 ' + (knoState.btnResearch === '1' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnResearch('1')}>Semua Area</button>
                                <button className={'btn btn-sm mx-1 ' + (knoState.btnResearch === '2' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnResearch('2')}>Upstream</button>
                                <button className={'btn btn-sm mx-1 ' + (knoState.btnResearch === '3' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnResearch('3')}>Refining</button>
                                <button className={'btn btn-sm mx-1 ' + (knoState.btnResearch === '4' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnResearch('4')}>Petrochemical & NF</button>
                                <button className={'btn btn-sm mx-1 ' + (knoState.btnResearch === '5' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnResearch('5')}>Gas & LNG</button>
                                <button className={'btn btn-sm mx-1 ' + (knoState.btnResearch === '6' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnResearch('6')}>NRE</button>
                                <button className={'btn btn-sm mx-1 ' + (knoState.btnResearch === '7' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnResearch('7')}>Digital</button>
                            </div>
                        </div>
                    </div> */}

                </Card.Body>
            </Card>
            <div className="row">
                <div className="col-md-12">
                    {
                        knoState.loadingResearch ?
                            <div className="card-columns px-1 my-1">
                                {
                                    [1, 2, 3, 4].map(d => (
                                        <div>
                                            <Card
                                                className="p-0 customShadow"
                                            >
                                                <Card.Body className='p-5'>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="card card-custom overlay">
                                                                <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                                    <Skeleton height={150} />
                                                                </div>
                                                            </div>
                                                            <p>
                                                                <h5><Skeleton /></h5>
                                                            </p>
                                                            <p><Skeleton /></p>
                                                            <div>
                                                                <Skeleton />
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <p>
                                                                <Skeleton />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <PaginationCs
                                data={[...(knoState.dataResearch?.data_dataResearchs || [])]}
                                dataPerPage={10}
                                RenderContainer={({ children }) => (
                                    <div className="card-columns px-1 my-1">
                                        {children}
                                    </div>
                                )}
                                renderComponent={(d, i) => (
                                    <Card
                                        className="p-0 customShadow mb-5"
                                    >
                                        <Card.Body className='p-5'>
                                            <div>
                                                <div className="card card-custom overlay">
                                                    <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                        <div className="overlay-wrapper">
                                                            <LazyLoadImage
                                                                height={200}
                                                                width={'100%'}
                                                                effect='blur'
                                                                className="border image_card"
                                                                onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                                                src={baseUrl + d.cover_file_location}
                                                            />
                                                        </div>
                                                        <div className="overlay-layer">
                                                            <DropdownButton size="sm" id="dropdown-basic-button" title={<span><i className="fa fa-download icon-nm mx-1"></i>Download</span>} className="mx-1">
                                                                {
                                                                    [...(d.research_reports?.split(',') || [])].map((d, i) => (
                                                                        <a className="dropdown-item" href={baseUrl + d} target='_blank' download>File Report {i + 1}</a>
                                                                    ))
                                                                }
                                                            </DropdownButton>
                                                            <Link to={`/research-detail/${d?.id}`} target="_blank">
                                                                <button className="btn btn-sm btn-light mx-1"
                                                                ><i className="fa fa-search"></i>
                                                                    Lihat Detail
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <h5>{d.title}</h5>
                                                </p>
                                                <p>{d.description}</p>
                                                <div>
                                                    <span className="py-1 px-2 rounded bg-light-success text-success">{d.area_name}</span>
                                                </div>
                                                <br />
                                                <br />
                                                <p>
                                                    {d.year_end}
                                                </p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )}
                            />
                    }

                </div>
            </div>
            <div className="card-columns px-0">

            </div>
        </>
    );
}

export default () => <KnoProvider><Research /></KnoProvider>;