import React, { useEffect, useState } from 'react';
import { Summary, TotalItem } from 'devextreme-react/data-grid';
import { CarouselSpiirit, GridDx } from '../../components';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import moment from 'moment';
import useFetch from '../../modules/useFetch';
import { month_source } from '../../components/utility/utility';
import { toString } from 'lodash';

const column = [
    { fieldName: 'month', caption: 'Implementation Time', allowFiltering: false },
    { fieldName: 'customer', caption: 'Customer', allowFiltering: false },
    { fieldName: 'product_contribution_status_name', caption: 'Contribution Status', allowFiltering: false },
    { fieldName: 'quantity', caption: 'Product Quantity', allowFiltering: false },
    { fieldName: 'price', caption: 'Predicted / Actual Price per Quantitity (USD)', allowFiltering: false },
    { fieldName: 'jumlah_contribution', caption: 'Product Contribution (USD)', allowFiltering: false },
    { fieldName: 'notes', caption: 'Notes', allowFiltering: false },
    { fieldName: 'updated_by', caption: 'Updated by', allowFiltering: false },
    { fieldName: 'updated_at', caption: 'Updated at', allowFiltering: false },
]

function ProductGridDetail(props) {
    const [data, setData] = useState();
    const [dsGrid, setDsGrid] = useState([]);
    const [state, dispatch, action] = useGlobalState();
    const { doGet } = useFetch();

    useEffect(() => {
        if (props.data.data?.id) {
            doGet({ url: `/api/ext-product/view-2/${props.data.data?.id}` }).then((resp) => {
                if (resp.code === '00') {
                    setData(resp.data);
                    setDsGrid([...(resp.data?.data_tahun || [])].map(d => {
                        const data_table = [...(resp.data.data_contribution)].filter(x => x.year === d);
                        return {
                            year: d,
                            data_table: data_table
                        }
                    }))
                }
            })
            // action.getData(`/api/ext-product/view/${props.data.data?.id}`, {}, null, null).then((resp) => {
            //     if (resp.code === '00') {
            //         setData(resp.data);
            //         setDsGrid([...(resp.data?.data_tahun || [])].map(d => {
            //             const data_table = [...(resp.data.data_contribution)].filter(x => x.year === d);
            //             return {
            //                 year: d,
            //                 data_table: data_table
            //             }
            //         }))
            //     }
            // })
            // try {
            //     fetch(`${baseUrl}/api/ext-product/view/${props.data.data?.id}`, {
            //         headers: {
            //             Accept: 'application/json',
            //             'Content-Type': 'application/json',
            //             'Authorization': 'Bearer ' + token
            //         }
            //     })
            //         .then((response) => response.json())
            //         .then((data) => {
            //             setData(data.data);
            //         })
            //         .catch(() => { throw new Error('Data Loading Error'); });
            // } catch (er) {
            //     return Swal.fire({
            //         title: 'Warning',
            //         text: er,
            //         icon: 'warning',
            //     })
            // }
        }
    }, [props.data.data?.id])
    function cstmCellRender(e) {
        if (e.column.name === "month") {
            return (
                <span className="text-wrap">{e.value ? month_source.find(d => d?.value === toString(e.value))?.name : ''}</span>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function summary(data) {
        const total = [...(data || [])].reduce((tot, current) => {
            return tot = tot + current?.jumlah_contribution
        }, 0);
        return parseFloat(total).toFixed(2);
    }
    return (
        <>
            <Card
                className="m-0 p-0 mb-5 text-dark"
            >
                <Card.Body className="p-4">
                    <div className="row mb-5">
                        <div className="col-md-6 mb-5">
                            <div className="pl-5 pr-5">
                                <CarouselSpiirit
                                    showItem={1}
                                    withButton={true}
                                    showIndicators={true}
                                >
                                    {
                                        [
                                            data?.detail_product?.photo_location_name,
                                            data?.detail_product?.photo_location_1_name,
                                            data?.detail_product?.photo_location_2_name,
                                            data?.detail_product?.photo_location_3_name
                                        ].filter(d => d !== "" && d !== null && d !== undefined).map((d, i) => (
                                            <img style={{ height: 250, width: '100%', objectFit: 'contain', borderRadius: '3%' , backgroundColor:'whitesmoke'}} className=" border"
                                                src={d}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} key={i} />
                                        ))
                                    }
                                </CarouselSpiirit>
                                {/* <img style={{ width: '100%', height: 200, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                    src={data?.detail_product?.photo_location_name}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} /> */}
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <h5 className='text-wrap'><b>{data?.detail_product?.name}</b></h5>
                            <br />
                            <span className="d-block text-wrap text-dark mb-1">Deskripsi</span>
                            <span className="d-block text-wrap font-weight-bold text-dark mb-5">{data?.detail_product?.description}</span>
                            <span className="d-block text-wrap text-dark mb-1">Latar Belakang</span>
                            <span className="d-block text-wrap font-weight-bold text-dark">{data?.detail_product?.background}</span>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6 mb-5">
                            <span className="d-block font-weight-bold mb-3 ">Product Information</span>
                            <hr />
                            <span className="d-block text-wrap text-dark mb-1">IPR</span>
                            <ul className="d-block font-weight-bold mb-5">
                                {
                                    [...(data?.detail_product?.list_ipr || [])].map((d, i) => (
                                        <li className="text-wrap" key={i}>{d.title}</li>
                                    ))
                                }
                            </ul>
                            <span className="d-block text-wrap text-dark mb-1">Tipe Produk</span>
                            <span className="d-block text-wrap font-weight-bold mb-5">{data?.detail_product?.product_types_name}</span>
                            <span className="d-block text-wrap text-dark mb-1">Tahun Rilis</span>
                            <span className="d-block text-wrap font-weight-bold mb-5">{data?.detail_product?.release_year}</span>
                            <span className="d-block text-wrap text-dark mb-1">Status</span>
                            <span className="d-block text-wrap font-weight-bold mb-5">{data?.detail_product?.product_status_name}</span>
                            <span className="d-block text-dark-50 mb-1">Contribution Type</span>
                            <span className="d-block font-weight-bold mb-5">{data?.detail_product?.contrib_type}</span>
                        </div>
                        <div className="col-md-6 mb-5">
                            <span className="d-block text-wrap font-weight-bold mb-3">Initiator Information</span>
                            <hr />
                            <span className="d-block text-wrap text-dark mb-1">Inventor</span>
                            <span className="d-block text-wrap font-weight-bold mb-5">{data?.detail_product?.inventor_name}</span>
                            <span className="d-block text-wrap text-dark mb-1">Kontak</span>
                            <span className="d-block text-wrap font-weight-bold mb-5">{data?.detail_product?.kontak_name}</span>
                            <span className="d-block text-wrap text-dark mb-1">PIC Product Contribution</span>
                            <span className="d-block text-wrap font-weight-bold mb-5">{data?.detail_product?.nama_PIC_str}</span>
                            <span className="d-block text-wrap text-dark mb-1">Product Price</span>
                            <div className="row">
                                <div className="col-md-6">
                                    <span className="d-block text-wrap text-dark mb-1">Predicted / Actual Price per Quantitity (USD)</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="px-3 py-1 bg-light-primary rounded text-wrap">
                                        ${
                                            data?.detail_product?.price || '0'
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        dsGrid.map((d, i) => (
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <span className="d-block text-wrap font-weight-bold mb-3 ">Product Contribution {d?.year}</span>
                                    <hr />
                                    <GridDx
                                        columns={column}
                                        cellRender={cstmCellRender}
                                        className='custom-style'
                                        data={d?.data_table}
                                    >
                                        {/* <Summary>
                                            <TotalItem                                                
                                                column="jumlah_contribution"
                                                summaryType="sum"
                                                valueFormat="currency" />
                                        </Summary> */}
                                    </GridDx>
                                    <div className="p-2 custom-footer-grid text-center">
                                        Total Kontribusi Produk {summary(d?.data_table)} (USD)
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Card.Body>
            </Card>
        </>
    );
}

export default ProductGridDetail;