import React, { memo, useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Skeleton from 'react-loading-skeleton';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.css";
import { useResizeDetector } from 'react-resize-detector';

export default memo(({ children, centerMode = true, showItem = 1, jumlahItem, withButton = true, showThumbs = false, showMobile = 1, showIndicators = false, className = "", modeNextPrev = 1, autoPlay=true, infiniteLoop=true, defaultCurrentIndex = 0, onIndexChange = (index) => null }) => {
    const [currentIndex, setSlide] = useState(defaultCurrentIndex)
    const [countChild, setCountChild] = useState(0)
    const [sw, setSw] = useState(showItem);
    useEffect(() => {
        const count = React.Children.count(children);
        setCountChild(count)
        // console.log("countin children")
    }, [children]);
    useEffect(() => {
        onIndexChange(currentIndex)
    }, [currentIndex])

    const onResize = useCallback((p) => {
        if (p < 768) {
            setSw(showMobile);
        } else {
            setSw(showItem);
        }
    }, []);
    const { width, ref } = useResizeDetector({ onResize });
    const parentRef = ref;

    const a = [1, 3, 4, 5, 6,]
    return (
        <div ref={parentRef} style={{ position: 'relative', width:'100%' }}>
            {withButton && modeNextPrev === 1 && <div className="btn-carousel btn-left rounded-circle text-center border-0 customShadow"
                onClick={() => setSlide((currentIndex - sw) % (countChild === 0 ? 5 : countChild))}
            >
                <i className="fa fa-chevron-left m-auto"></i>
            </div>}
            {withButton && modeNextPrev === 2 && <div className="btn-carousel btn-left rounded-circle text-center border-0 customShadow"
                onClick={() => currentIndex > 0 ? setSlide(currentIndex - 1) : null}
            >
                <i className="fa fa-chevron-left m-auto"></i>
            </div>}
            <Carousel
                centerMode={centerMode}
                centerSlidePercentage={100 / sw}
                selectedItem={currentIndex}
                showArrows={false}
                showStatus={false}
                showIndicators={showIndicators}
                showThumbs={showThumbs}
                autoPlay={autoPlay}
                infiniteLoop={infiniteLoop}
                className={className}                
            >
                {
                    countChild > 0 ? children : (
                        a.map((val, key) => (
                            <div className="m-3" key={key}>
                                <Skeleton height={150} />
                            </div>
                        ))
                    )
                }
                {/* <Skeleton count={5}/>  */}
                {/* {children} */}
            </Carousel>
            {withButton && modeNextPrev === 1 && <div className="btn-carousel btn-right rounded-circle text-center border-0 customShadow"
                onClick={() => setSlide((currentIndex + sw) % (countChild === 0 ? 5 : countChild))}
            >
                <i className="fa fa-chevron-right m-auto"></i>
            </div>}
            {withButton && modeNextPrev === 2 && <div className="btn-carousel btn-right rounded-circle text-center border-0 customShadow"
                onClick={() => currentIndex < countChild ? setSlide(currentIndex + 1) : null}
            >
                <i className="fa fa-chevron-right m-auto"></i>
            </div>}
        </div>
    )
})