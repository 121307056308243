import React, { useState } from 'react'
import { useContext } from 'react';
import { createContext } from 'react'
import useFetch from '../../modules/useFetch';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { perform_work, status_progress_shortName } from '../../components/utility/utility';

const pcham = createContext({
    pchamAction: {},
    pchamState: {}
});

export function PchamProvider({ children }) {
    const { doGet } = useFetch();
    const [filter, setFilter] = useState({
        idFunction: "0",
        tahun: "",
        bulanGet: ""
    });
    const [swChart1Unggulan, setSwChart1Unggulan] = useState(false);
    const [yAxis_chart1Unggulan, setYAxis_chart1Unggulan] = useState([{
        name: "Plan",
        valueField: "plan",
        color: '#D1D1D1'
    },
    {
        name: "Realisasi",
        valueField: "actual",
        color: '#326BD5'
    }]);
    const [swChart2Unggulan, setSwChart2Unggulan] = useState(false);
    const [yAxis_chart2Unggulan, setYAxis_chart2Ungulan] = useState([{
        name: "Proposed Budget",
        valueField: "plan",
        color: '#D1D1D1'
    },
    {
        name: "Realisasi",
        valueField: "actual",
        color: '#F08044'
    }]);
    const [data_page, setData_page] = useState({});
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [dataDahsboard, setDataDashboard] = useState({});

    const getDashboard = useCallback(async function () {
        setLoadingDashboard(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {

            const resp = await doGet({
                url: 'api/project-ongoing/table-project-champions-3',
                param: {
                    ...filter
                }
            });
            if (resp?.code === '00') {
                setDataDashboard(resp.data);
                setData_page(resp.data_page);
            } else {
                return Swal.fire({
                    title: 'Error !',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error get dashboard", error.toString(), "error")
        }
        Swal.close();
        setLoadingDashboard(false)
    }, [filter])
    const filterChange = (name, val) => {
        setFilter((e) => ({ ...e, [name]: val }))
    }
    const getParameter = useCallback(async function () {
        try {

            const resp = await doGet({
                url: 'api/param-function',
            });
            console.log(resp)
            if (resp?.code !== '00') {
                return Swal.fire({
                    title: 'Error !',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error get parameter", error.toString(), "error")
        }
    }, [])
    function switch1UnggulanChange(val) {
        setSwChart1Unggulan(val);
        if (val === true) {
            setYAxis_chart1Unggulan([
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ])
        } else {
            setYAxis_chart1Unggulan([
                {
                    name: "Plan",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#326BD5'
                }
            ])
        }
    }
    function switch2UnggulanChange(val) {
        setSwChart2Unggulan(val);
        if (val === true) {
            setYAxis_chart2Ungulan([
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ])
        } else {
            setYAxis_chart2Ungulan([
                {
                    name: "Proposed Budget",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#F08044'
                }
            ])
        }
    }
    function getScurve1Unggulan() {
        var ds = [
            { arg: "JAN", args_value: 1, name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", args_value: 2, name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", args_value: 3, name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", args_value: 4, name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", args_value: 5, name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", args_value: 6, name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", args_value: 7, name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", args_value: 8, name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", args_value: 9, name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", args_value: 10, name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", args_value: 11, name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", args_value: 12, name: "dec", plan: null, actual: null, perform: null },
        ]
        if (dataDahsboard?.data_SCurve1_Unggulan) {
            ds = ds.map((d, i) => ({
                ...d,
                plan: dataDahsboard?.data_SCurve1_Unggulan[`plan_${d.name}`],
                actual: d.args_value <= dataDahsboard?.data_bulan_default ? dataDahsboard?.data_SCurve1_Unggulan[`actual_${d.name}`] : null,
                perform: d.args_value <= dataDahsboard?.data_bulan_default ? Number(parseFloat(dataDahsboard?.data_SCurve1_Unggulan[`perform_${d.name}`] * 100).toFixed(1)) : null
            }))
        }
        return ds
    }
    function getScurve2Unggulan() {
        var ds = [
            { arg: "JAN", args_value: 1, name: "jan", plan: null, actual: null, perform: null },
            { arg: "FEB", args_value: 2, name: "feb", plan: null, actual: null, perform: null },
            { arg: "MAR", args_value: 3, name: "mar", plan: null, actual: null, perform: null },
            { arg: "APR", args_value: 4, name: "apr", plan: null, actual: null, perform: null },
            { arg: "MAY", args_value: 5, name: "may", plan: null, actual: null, perform: null },
            { arg: "JUN", args_value: 6, name: "jun", plan: null, actual: null, perform: null },
            { arg: "JUL", args_value: 7, name: "jul", plan: null, actual: null, perform: null },
            { arg: "AUG", args_value: 8, name: "aug", plan: null, actual: null, perform: null },
            { arg: "SEP", args_value: 9, name: "sep", plan: null, actual: null, perform: null },
            { arg: "OCT", args_value: 10, name: "oct", plan: null, actual: null, perform: null },
            { arg: "NOV", args_value: 11, name: "nov", plan: null, actual: null, perform: null },
            { arg: "DEC", args_value: 12, name: "dec", plan: null, actual: null, perform: null },
        ]
        if (dataDahsboard?.data_SCurve2_Unggulan) {
            ds = ds.map((d, i) => ({
                ...d,
                plan: Number(parseFloat(dataDahsboard?.data_SCurve2_Unggulan[`plan_${d.name}`]).toFixed(2)),
                actual: d.args_value <= dataDahsboard?.data_bulan_default ? Number(parseFloat(dataDahsboard?.data_SCurve2_Unggulan[`actual_${d.name}`]).toFixed(2)) : null,
                perform: d.args_value <= dataDahsboard?.data_bulan_default ? Math.round(dataDahsboard?.data_SCurve2_Unggulan[`perform_${d.name}`] * 100) : null
            }))
        }
        return ds
    }
    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/OngoingResearch-public/view/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span>
                </Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            // console.log(e, dataDahsboard)
            return (status_progress_shortName(e, dataDahsboard?.data_bulan_default))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }
    function perform() {
        var actual_tot = 0
        var plan_tot = 0
        var perform = 0
        if (dataDahsboard?.data_HeaderMonitoring_Unggulan) {
            [...(dataDahsboard?.data_HeaderMonitoring_Unggulan || [])].forEach(e => {
                actual_tot += e.actual_tot;
                plan_tot += e.plan_tot;
            });
        }
        if (plan_tot !== 0) {
            perform = actual_tot / plan_tot;
        } else {
            perform = 0;
        }
        return perform
    }
    function performAll() {
        var actual_tot = 0
        var plan_tot = 0
        var performAll = 0
        var plan_dec = 0
        if (dataDahsboard?.data_HeaderMonitoring_Unggulan) {
            [...(dataDahsboard?.data_HeaderMonitoring_Unggulan || [])].forEach(e => {
                actual_tot += e.actual_tot;
                plan_tot += e.plan_tot;
                plan_dec += e.plan_dec;
            });
        }
        if (plan_tot !== 0) {
            performAll = actual_tot / plan_dec;
        } else {
            performAll = 0;
        }
        return performAll
    }
    useEffect(() => {
        getDashboard();
        getParameter();
    }, [filter])
    return <pcham.Provider
        value={{
            pchamAction: {
                filterChange,
                getScurve1Unggulan,
                getScurve2Unggulan,
                switch1UnggulanChange,
                switch2UnggulanChange,
                cstmCellRender,
                perform,
                performAll
            },
            pchamState: {
                filter,
                loadingDashboard,
                dataDahsboard,
                data_page,
                swChart1Unggulan,
                swChart2Unggulan,
                yAxis_chart1Unggulan,
                yAxis_chart2Unggulan
            }
        }}
    >{children}</pcham.Provider>
}
export default function useProjectChampionsReporting() {
    return useContext(pcham);
}