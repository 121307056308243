import React, { useEffect, useRef } from 'react';
import { GridDx, HeaderList } from '../../../../components';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useFetch from '../../../../modules/useFetch';
import {
    perform_work,
    status_progress_shortName,
    status_progress_shortName_2,
    status_budget_light,
    workflow_state_name_light_style,
    potensial_partner_opt_name,
    need_management_support,
    subholding,
    value_creation
} from '../../../../components/utility/utility';
import { Link } from 'react-router-dom';

const column = [
    { fieldName: "updated_at", caption: "Last Modified", width: 100 },
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "function_sn", caption: "Fungsi", width: 100 },
    { fieldName: "chief_sn", caption: "Chief", width: 100 },
    { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
    { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
    { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
    { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "area_name", caption: "Area", width: 100 },
    { fieldName: "research_category_id", caption: "Research Kategori", width: 115 },
    { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
    { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
    { fieldName: "leader", caption: "Leader", width: 100 },
    { fieldName: "method_name", caption: "Metode", width: 100 },
    { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potensial User", width: 200 },
    { fieldName: "need_management_support", caption: "Need Management Support", width: 120 },
    { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "year_start", caption: "Start", width: 100 },
    { fieldName: "year_end", caption: "End", width: 100 },
];

const budgetFilter = [
    { text: 'Under Budget', value: 1 },
    { text: 'Within Budget', value: 2 },
    { text: 'Over Budget', value: 3 },
    { text: 'Aborted', value: 4 },
    { text: '-', value: null },
]
const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]

function DetailReminder(props) {
    const { doGet } = useFetch();
    const [data_page, setData_page] = useState({});
    const tableRef = useRef();

    useEffect(() => {
        getData();
    }, []);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    async function getData() {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/user/table-detail-search',
            });
            if (resp?.code === '00') {
                setData(resp?.data);
                setData_page(resp?.data_page);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }
    function customCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/Ongoingresearch/view/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span></Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span>{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName(e, data?.data_bulan))
        } else if (e.column.name === "status_progress") {
            return (status_progress_shortName_2(e))
        } else if (e.column.name === "status_budget") {
            return (status_budget_light(e))
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "potensial_partner_opt_name") {
            return (potensial_partner_opt_name(e))
        } else if (e.column.name === "need_management_support") {
            return (need_management_support(e))
        } else if (e.column.name === "subholding") {
            return (subholding(e))
        } else if (e.column.name === "value_creation") {
            return (value_creation(e))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }
    return (
        <div className='p-5'>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <GridDx
                                searchForm={true}
                                ref={tableRef}
                                data={data?.data_table}
                                columns={column}
                                cellRender={customCellRender}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default DetailReminder;