import React, { memo, useState } from 'react';
import { Tab, TabContent, HeaderList, HeaderProject } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import TabRiskMon from './tabRiskMon'
import TabDetailMon from './tabDetailMon'
import TabTimelineMon from './tabTimelineMon'
import TabBudgetMon from './tabBudgetMon'
import TabAchievement from './tabAchievement'
import Card from 'react-bootstrap/Card'
import './monitoringViewEdit.css';
import {  useParams } from 'react-router-dom';
import { useHistory } from 'react-router';


export default memo(function (props) {
    const [state] = useGlobalState();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState("detail")
    const param = useParams()

    return (
        <div className="pb-5">
            <HeaderList
                title={state.formData?.mon?.page_title}
                someText={state.formData?.mon?.page_breadcrumbs}
            >
            </HeaderList>
            <HeaderProject
                workflow_stage="Project Monitoring"
                rightMenu={true}
                dataHeader={state.data_view_header}
                dataReport={state.data_reports}
                view_admin={history.location.state?.view_admin}
                history_onClick={({ id }) => history.push({
                    pathname: '/Projects/ResearchHistories-public/' + id,
                })}
                monthly_onClick={({ id }) => history.push({
                    pathname: '/reportResearch-public/index/' + id,
                })}
                profile_onClick={({ id, baseline_name }) => history.push({
                    pathname: '/research/project-profile-public/' + id + "/" + baseline_name,
                })
                }
            />
            {
                (state.data_view_header?.workflow_state_name === 'monitoring_finished_request' || state.data_view_header?.workflow_state_name === 'monitoring_aborted_request' || state.data_view_header?.workflow_state_name === 'monitoring_continue_request') && (
                    <Card
                        bg="warning"
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div className="row m-5 text-white">
                                <div className="col-md">
                                    <span>
                                        {
                                            state.data_view_header?.workflow_state_name === 'monitoring_finished_request' ? 'Need to Finish : ' :
                                                state.data_view_header?.workflow_state_name === 'monitoring_aborted_request' ? 'Need to Abort : ' : ''
                                        }
                                    </span>
                                </div>
                                <div className="col-md-10">
                                    <span className="d-block text-wrap">{state.data_reason}</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )
            }
            <Card
                className="m-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "detail",
                                title: "Project Detail",
                                onClick: (e) => setSelectedTab("detail")
                            },
                            ...(state.data_view_header?.project_type !== 5 ? [
                                {
                                    id: "risk",
                                    title: "Project Risk",
                                    onClick: (e) => setSelectedTab("risk")
                                },
                            ] : []),
                            {
                                id: "timeline",
                                title: "Project Timeline",
                                onClick: (e) => setSelectedTab("timeline")
                            },
                            {
                                id: "budget",
                                title: "Drawdown Budget",
                                onClick: (e) => setSelectedTab("budget")
                            }, {
                                id: "achievement",
                                title: "Achievement & Concern",
                                onClick: (e) => setSelectedTab("achievement")
                            },                            
                        ]}
                        manualActive={selectedTab}
                    >
                        <TabContent id="detail">
                            <TabDetailMon param={param} />
                        </TabContent>
                        <TabContent id="risk">
                            <TabRiskMon param={param} />
                        </TabContent>
                        <TabContent id="timeline">
                            <TabTimelineMon param={param} />
                        </TabContent>
                        <TabContent id="budget">
                            <TabBudgetMon param={param} />
                        </TabContent>
                        <TabContent id="achievement">
                            <TabAchievement param={param} />
                        </TabContent>                        
                    </Tab>

                </Card.Body>
            </Card>           
        </div>
    )
})