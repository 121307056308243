import React, { memo, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useGlobalState } from '../../contexts/useGlobalState';
import { Accordion, Autocomplete } from 'devextreme-react';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { HeaderProject, HeaderList } from '../../components';
import Swal from 'sweetalert2/dist/sweetalert2.js'


export const susunanTeam = [
    { name: "Agus", position_name: "Senior Analist", employee_id: "0213062", technical_competency: ["test"], image: "" },
    { name: "Hadi", position_name: "Senior Analist", employee_id: "0213062", technical_competency: ["test"], image: "" },
    { name: "Iman", position_name: "Senior Analist", employee_id: "0213062", technical_competency: ["test1"], image: "" }
]

export default memo(function () {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const param = useParams()
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))

    useEffect(() => {
        if (param.id) {
            getProjectDetail(param.id);
        }
    }, [])
    function getProjectDetail(id) {
        if (id) {
            action.getData("/api/project-ongoing-crud/view-update-team-member/" + id).then((resp) => {
                if (resp.code === "00") {
                    getLeader(resp.data.data_view_team_members);
                    getMember(resp.data.data_view_team_members);
                    action.setForm("disable", false);
                    dispatch({
                        type: 'getSuccess',
                        payload: { data_page: resp?.data_page }
                    })
                }
            })
        }
    }
    function getLeader(data) {
        if (data) {
            const disctinctLeaderName = Array.from([... new Set((data.filter(obj => obj.team_role === "Leader")).map(s => s))]).map(d => {
                if (d.user_id !== null) {
                    return {
                        user_id: d.user_id,
                        employee_id: d.employee_id,
                        name: d.name,
                        position_name: d.position_name,
                        technical_competency: d.technical_competency
                    }
                }
            })
            action.setForm("leader", disctinctLeaderName?.[0])
        }
    }
    function getMember(data) {
        if (data) {
            const disctinctMamberName = Array.from([... new Set((data.filter(obj => obj.team_role === "Member")).map(s => s))]).map(d => {
                return {
                    user_id: d.user_id,
                    employee_id: d.employee_id,
                    name: d.name,
                    is_new: false,
                    position_name: d.position_name,
                    technical_competency: d.technical_competency
                }

            })
            action.setForm("anggota", disctinctMamberName)
        }
    }
    function deleteAnggota(data) {
        const newData = state.formData.anggota || [];
        const index = newData.indexOf(data)
        if (data.is_new === false) {
            const newd = state.formData.deleteAnggota || [];
            newd.splice(newd, 0, data)
            action.setForm('deleteAnggota', newd)
        }
        newData.splice(index, 1);
        action.setForm("anggota", newData);

    }
    function addAnggota(value) {
        if (value) {
            const newData = state.formData.anggota || [];
            const fnd = state.formData.anggota?.find(sa => sa.user_id.toString() === value.user_id.toString())
            if (!fnd) {
                newData.push({
                    user_id: value?.user_id,
                    employee_id: value?.employee_id,
                    name: value?.name,
                    position_name: value?.position_name,
                    is_new: true,
                    section_name: value?.section_name,
                    function_short_name: value?.function_short_name,
                    technical_competency: value?.technical_competency
                });
                action.setForm("anggota", newData);
                action.setForm("selectedAnggota_temp", null)
            }
        }
    }
    function renderTitle(data) {
        return (
            <div className="m-2" style={{ fontSize: "10px", fontFamily: 'Poppins' }}><i className="fa flaticon-user-ok p-2"></i>{data?.name}</div>
        )
    }
    function renderItem(data) {
        return (
            <div className="row h-100 p-5 border-top" style={{ fontFamily: 'Poppins' }}>
                <div className="col-4">
                    <img src={data?.image || "/media/svg/icons/General/User.svg"}
                        style={{ width: '100%' }}
                    ></img>
                </div>
                <div className="col-8">
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Nama</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Posisi</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.position_name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>No. Pegawai</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.employee_id}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Expertise</div>
                        <div className="col-auto"></div>
                    </div>
                    {
                        data.technical_competency?.split(',').map((s, i) => (
                            <div className="row" style={{ paddingBottom: 10 }}>
                                <div className="col-3"></div>
                                <div className="col-9" style={{ fontSize: '8pt' }}>{i + 1}.&nbsp;{s}</div>
                            </div>
                        ))
                    }
                    <div className="d-flex flex-row-reverse" style={{ paddingBottom: 10 }}>
                        <button disabled={state.formData.disable} className="btn btn-sm btn-outline-danger" onClick={() => deleteAnggota(data)}><i className="fa fa-trash icon-sm"></i> Remove</button>
                    </div>
                </div>
            </div>
        )
    }
    function saveMember() {
        const data = {
            "id": state.data_view_header?.id,
            "owner": state.formData.leader?.user_id || '',
            "team_members": state.formData.anggota || [],
            "team_members_delete": state.formData.deleteAnggota || []
        }
        if (data.owner === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Pilih Leader',
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Save Project Member?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // console.log('semua data', state)
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/update-team-member",
                        data: data,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }

    return (
        <>
            <HeaderList
                title={state.data_page?.page_title}
                someText={state.data_page?.page_breadcrumbs}
            />
            <HeaderProject
                workflow_stage="Project Monitoring"
                rightMenu={true}
                dataHeader={state.data_view_header}
            />
            <Card
                className="mt-0 mx-0 p-0 customShadow" style={{ marginBottom: '50px' }}
            >
                <Card.Body className="p-5">
                    <span className="d-block">Team</span>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <span style={{ fontSize: '8pt', fontWeight: 'bold' }}>Leader</span>
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <div className="input-group mb-2">
                                        <Autocomplete dataSource={state.data_users}
                                            disabled={state.formData.disable}
                                            valueExpr="name"
                                            displayExpr="name"
                                            onSelectionChanged={(e) => action.setForm("selectedLeader", e.selectedItem)}
                                            className="form-control form-control-sm p-0 mr-1">
                                        </Autocomplete>
                                        <button disabled={state.formData.disable} type="submit" className="btn btn-sm btn-primary" onClick={() => action.setForm("leader", state.formData.selectedLeader)}>+ Select</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        state.formData.leader && (
                                            <div className="row">
                                                <div className="col" style={{ marginLeft: 10, marginRight: 10 }}>

                                                    <div className="row" style={{ paddingTop: '20px', paddingLeft: '7px', paddingBottom: '20px', border: '1px solid #ebedf2', borderRadius: '3px' }}>
                                                        <div className="col-4">
                                                            <img src={state.formData.leader?.image || "/media/svg/icons/General/User.svg"}
                                                                style={{ width: '100%' }}
                                                            ></img>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">Nama</div>
                                                                <div className="col-auto">: {state.formData.leader.name}</div>
                                                            </div>
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">Posisi</div>
                                                                <div className="col-auto">: {state.formData.leader.position_name}</div>
                                                            </div>
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">No. Pegawai</div>
                                                                <div className="col-auto">: {state.formData.leader.employee_id}</div>
                                                            </div>
                                                            <div className="row" style={{ paddingBottom: 10 }}>
                                                                <div className="col-3">Expertise</div>
                                                                <div className="col-auto"></div>
                                                            </div>
                                                            {
                                                                state.formData.leader.technical_competency?.split(',').map((ex, i) => (
                                                                    <div className="row" style={{ paddingBottom: 10 }}>
                                                                        <div className="col-3"></div>
                                                                        <div className="col-auto">{i + 1}.&nbsp;{ex}</div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <span style={{ fontSize: '8pt', fontWeight: "bold" }}>Anggota</span>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group mb-2">
                                        <Autocomplete dataSource={state.data_users}
                                            disabled={state.formData.disable}
                                            value={state.formData.selectedAnggota_temp}
                                            valueExpr="name"
                                            displayExpr="name"
                                            onValueChanged={(e) => action.setForm("selectedAnggota_temp", e.value)}
                                            onSelectionChanged={(e) => action.setForm("selectedAnggota", e.selectedItem)}
                                            className="form-control form-control-sm p-0 mr-1">
                                        </Autocomplete>
                                        <button disabled={state.formData.disable} type="submit" className="btn btn-sm btn-primary" onClick={() => addAnggota(state.formData.selectedAnggota)}>+ Add</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        state.formData.anggota?.length > 0 ? (
                                            <Accordion
                                                className="accordion-cstm py-5"
                                                multiple={true}
                                                itemRender={renderItem}
                                                dataSource={[...state.formData.anggota]} collapsible={true}
                                                itemTitleRender={renderTitle}
                                            >
                                            </Accordion>
                                        ) : (
                                            <div className="mb-5"></div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className={`p-2 bg-white rounded shadow-lg floating-footer${path.toLocaleLowerCase() === 'admin' ? '-nolayout' : ''}`}>
                <div className='row'>
                    <div className='col-6'>
                        {
                            path.toLocaleLowerCase() !== 'admin' && history.length > 1 && <button className="btn btn-sm btn-secondary m-1"
                                onClick={() => history.goBack()}
                            >
                                Kembali
                            </button>
                        }

                    </div>
                    <div className='col-6 text-right'>
                        <button disabled={state.formData.disable} className="btn btn-sm btn-primary m-1"
                            onClick={() => saveMember()}
                        >
                            <i className="fa fa-save icon-nm"></i>
                            &nbsp;Simpan
                        </button>
                    </div>
                </div>

            </div>
        </>
    )
})