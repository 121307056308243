/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
// import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { LandingPage, MaintenancePage, PrivateLogin } from "./pages"
import useLocalStorage from "./contexts/useLocalStorage";
// import routes from "./routes/app-routes";
import { useGlobalState } from "./contexts/useGlobalState";
import { AuthConsumer } from "./service/authenticationProvider";
import LoginCallback from "./service/loginCallBack";
import LogoutCallback from "./service/logoutCallback";
import useFetch from "./modules/useFetch";
import Swal from "sweetalert2";
// import { FooterCS } from "./components";

export function Routes() {
  const location = useLocation()
  const [token] = useLocalStorage("token")
  const [globalState] = useGlobalState();
  const { doGet } = useFetch();
  const [isMaintenance, setisMaintenance] = useState(null);
  const [pathBefore, setPathBefore] = useLocalStorage("pathBefore", "/");

  // useEffect(function () {
  //   if (location.pathname !== "/" && location.pathname.split('/')[1] !== "landing-page") {
  //     setPathBefore(location.pathname)
  //   }
  // }, [location.pathname])

  useEffect(function () {
    checkMaintenance()
  }, []);
  const checkMaintenance = useCallback(async () => {
    const resp = await doGet({
      url: 'api/web/ismaintenance',
      token: false
    });
    if (resp?.code === '00') {
      setisMaintenance(resp?.data);
    } else {
      return Swal.fire({
        title: 'Error !',
        text: resp?.message,
        icon: 'error',
      })
    }
  }, []);

  const isAuthorized = globalState.isAuthenticated || (token != null || token != undefined);

  return (
    <Switch>
      {/* <Route path="/superadmin-login" component={PrivateLogin} /> */}      
      <Route path="/maintenance" component={MaintenancePage} />
      <Route path="/signin-oidc" component={LoginCallback} />
      <Route path="/signout-callback-oidc" component={LogoutCallback} />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/private-login-admin" component={PrivateLogin} />
      {/* {
        isAuthorized ? <BasePage /> : <LandingPage />
      } */}
      <AuthConsumer>
        {({ isAuthenticated }) => {
          // console.log("isAuthenticated", isAuthenticated())
          if (isMaintenance === true) {
            return <Redirect to={'/maintenance'} />
          }
          if (isMaintenance === false) {
            if (isAuthenticated() || isAuthorized) {
              return <BasePage />
            } else {
              return <LandingPage />
            }
          }
        }}
      </AuthConsumer>
    </Switch >
  );
}
