import React, { createContext, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useGlobalState } from '../../contexts/useGlobalState';

const inventoryContext = createContext({
    invState: {},
    invAction: {}
})

export function InvProvider({ children }) {
    const [globalState, dispatch, globalActon] = useGlobalState();
    const [data_page, setData_page] = useState({});

    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [dataDashboard, setDataDashboard] = useState({});
    const [loadingTable, setLoadingTable] = useState(false);
    const [dataTable, setDataTable] = useState({});
    const [typeChart6, setTypeChart6] = useState('0');

    async function getDashboard() {
        setLoadingDashboard(true)
        try {

            const resp = await globalActon.getData("/api/ext-inventory/dashboard")
            if (resp.code === "00") {
                setDataDashboard(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
        setLoadingDashboard(false);
    }
    async function getTable() {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData("/api/ext-inventory/table")
            if (resp.code === "00") {
                setDataTable(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    function getChart1(data = null) {
        const stateName = ['01', '02', '03', '04', '05', '06', '07', '08', '10', '11', '12'];
        if (data === null || data === undefined) return [];
        const chart = stateName.map((d, i) => {
            return {
                arg: parseInt(d).toString(),
                value: data['value_'+d],
                uhs_value:data['uhs_value_'+d]
            }
        })
        return chart;
    }
    function renderCenter(e) {
        const total = e.getAllSeries()[0].getVisiblePoints().reduce((s, p) => s + p.originalValue, 0)
        return (
            <svg>
                <text textAnchor="middle" x="100" y="120" style={{ fontSize: 18, fill: '#494949' }}>
                    <tspan x="100" style={{ fontWeight: 600 }}>{
                        total
                    }</tspan>
                    <tspan x="100" dy="20px" style={{ fontSize: '8pt' }}>Total Inventory</tspan>
                </text>
            </svg>
        )
    }
    function renderCenterScrap(e) {
        const total = e.getAllSeries()[0].getVisiblePoints().reduce((s, p) => s + p.originalValue, 0)
        return (
            <svg>
                <text textAnchor="middle" x="100" y="120" style={{ fontSize: 18, fill: '#494949' }}>
                    <tspan x="100" style={{ fontWeight: 600 }}>{
                        total
                    } $</tspan>
                    <tspan x="100" dy="20px" style={{ fontSize: '8pt' }}>Sunk Cost</tspan>
                </text>
            </svg>
        )
    }
    function getChart6(type = null){
        if(type === null || type === undefined) return [];
        if(type === '0'){
            return dataDashboard?.data_chart_6 ;
        }
        if(type === '1'){
            return dataDashboard?.data_chart_6b ;
        }
    }
    function cellRenderTable(e) {
        if (e.column.name === "stock_status") {
            switch (e.value) {
                case "instock":
                    return (
                        <span className="p-2 my-2 rounded bg-light-success text-success">In Stock</span>
                    )
                case "outstock":
                    return (
                        <span className="p-2 rounded bg-light-danger text-danger">Out Stock</span>
                    )
                case "lowstock":
                    return (
                        <span className="p-2 rounded bg-light-warning text-warning">Low Stock</span>
                    )
                default:
                    return (
                        <span className="p-2 rounded bg-light-secondary text-secondary"> - </span>
                    )
            }
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    useEffect(() => {
        getDashboard();
        getTable();
    }, [])
    return <inventoryContext.Provider value={{
        invState: {
            dataDashboard,
            loadingDashboard,
            dataTable,
            loadingTable,
            typeChart6,
            data_page
        },
        invAction: {
            getChart1,
            renderCenter,
            renderCenterScrap,
            getChart6,
            setTypeChart6,
            cellRenderTable,            
        }
    }}>{children}</inventoryContext.Provider>
}

export default function useInventory() {
    return useContext(inventoryContext);
}