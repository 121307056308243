import React, { useEffect, useState } from 'react';
import { FormResearch } from '../../components';
import { useGlobalState } from "../../contexts/useGlobalState";
import {
    risk_priority,
    risk_priority_residu,
    inher_dampak,
    inher_probabil,
    probabilitas,
    dampak,
    kuantitatif
} from '../../components/utility/utility';
import { NumberBox } from 'devextreme-react';

const ModalViewEditRisk = (props) => {
    const [state, dispatch, actions] = useGlobalState();
    useEffect(() => {
        actions.setForm("id", props.data?.id)
        actions.setForm("research_id", props.data?.research_id)
        actions.setForm("baseline_name", props.data?.baseline_name)
        actions.setForm("risk_id", props.data?.risk_id)
        actions.setForm("tipe_risk", props.data?.tipe_risk)
        actions.setForm("kejadian", props.data?.kejadian)
        actions.setForm("penyebab", props.data?.penyebab)
        actions.setForm("gejala", props.data?.gejala)
        actions.setForm("faktor_positif", props.data?.faktor_positif)
        actions.setForm("dampak_kual", props.data?.dampak_kual)
        actions.setForm("inher_kuant", props.data?.inher_kuant)
        actions.setForm("inher_probabil", props.data?.inher_probabil)
        actions.setForm("inher_dampak", props.data?.inher_dampak)
        actions.setForm("mit_rencana", props.data?.mit_rencana)
        actions.setForm("mit_biaya", props.data?.mit_biaya)
        actions.setForm("mit_tahun_berlaku", props.data?.mit_tahun_berlaku)
        actions.setForm("mit_tahun_selesai", props.data?.mit_tahun_selesai)
        actions.setForm("res_kuant", props.data?.res_kuant)
        actions.setForm("res_probabil", props.data?.res_probabil)
        actions.setForm("res_dampak", props.data?.res_dampak)
    }, [props.data])

    function saveOnclick() {
        props.onFinish({
            "id": state.formData?.id,
            "research_id": state.formData?.research_id || state.data_view_header?.id,
            "baseline_name": state.formData?.baseline_name || state.data_view_header?.baseline_name,
            "risk_id": state.formData?.risk_id,
            "tipe_risk": state.formData?.tipe_risk,
            "kejadian": state.formData?.kejadian,
            "penyebab": state.formData?.penyebab,
            "gejala": state.formData?.gejala,
            "faktor_positif": state.formData?.faktor_positif,
            "dampak_kual": state.formData?.dampak_kual,
            "inher_kuant": state.formData?.inher_kuant,
            "inher_probabil": state.formData?.inher_probabil,
            "inher_dampak": state.formData?.inher_dampak,
            "mit_rencana": state.formData?.mit_rencana,
            "mit_biaya": state.formData?.mit_biaya,
            "mit_tahun_berlaku": state.formData?.mit_tahun_berlaku,
            "mit_tahun_selesai": state.formData?.mit_tahun_selesai,
            "res_kuant": state.formData?.res_kuant,
            "res_probabil": state.formData?.res_probabil,
            "res_dampak": state.formData?.res_dampak,
        })
    }
    return (
        <>
            <div style={{ padding: '10px' }}>

                <FormResearch
                    formLists={[
                        {
                            rows: 1,
                            header: true,
                            name: <>Project Risk <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                {
                                    type: "select",
                                    label: "Kuantitatif/Kuantitatif Risk",
                                    placeholder: "- Pilih -",
                                    options: [{ value: "", name: "- Pilih -" }, ...(kuantitatif || [])],
                                    value: state.formData.tipe_risk,
                                    onChange: function (e) { actions.setForm("tipe_risk", e) },
                                    disabled: props.disable
                                },
                                {
                                    label: "Kejadian Resiko (Risk Event)",
                                    type: "textarea",
                                    value: state.formData.kejadian,
                                    onChange: function (e) { actions.setForm("kejadian", e) },
                                    placeholder: "Tulis disini...",
                                    disabled: props.disable
                                },
                                {
                                    label: "Penyebab Resiko (Risk Agent)",
                                    type: "textarea",
                                    value: state.formData.penyebab,
                                    onChange: function (e) { actions.setForm("penyebab", e) },
                                    placeholder: "Tulis disini...",
                                    disabled: props.disable
                                }
                                ,
                                {
                                    label: "Gejala Resiko (KRI)",
                                    type: "textarea",
                                    value: state.formData.gejala,
                                    onChange: function (e) { actions.setForm("gejala", e) },
                                    placeholder: "Tulis disini...",
                                    disabled: props.disable
                                }
                                ,
                                {
                                    label: "Faktor Positif (Control)",
                                    type: "textarea",
                                    value: state.formData.faktor_positif,
                                    onChange: function (e) { actions.setForm("faktor_positif", e) },
                                    placeholder: "Tulis disini...",
                                    disabled: props.disable
                                }
                                ,
                                {
                                    label: "Dampak Kualitatif",
                                    type: "textarea",
                                    value: state.formData.dampak_kual,
                                    onChange: function (e) { actions.setForm("dampak_kual", e) },
                                    placeholder: "Tulis disini...",
                                    disabled: props.disable
                                }
                            ]
                        }, {
                            rows: 2,
                            header: true,
                            name: <>Inherent <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                state.formData.tipe_risk === "Kuantitatif" && (
                                    {
                                        type: "custom",
                                        label: "Dampak Kuantitatif (000 USD)",
                                        component: (
                                            <div className="form-group">
                                                <input
                                                    placeholder="$"
                                                    value={state.formData.inher_kuant || 0}
                                                    onChange={(e) => actions.setForm("inher_kuant", e.target.value)}
                                                    type="number"
                                                    min="0.01"
                                                    step="0.01"
                                                    disabled={props.disable}
                                                    className="form-control form-control-sm"
                                                >

                                                </input>
                                            </div>
                                        )
                                    }
                                )
                                ,
                                {
                                    label: "Probabilitas",
                                    options: [{ value: 0, name: "- Pilih -" }, ...(probabilitas || [])],
                                    value: state.formData.inher_probabil,
                                    onChange: function (e) { actions.setForm("inher_probabil", e) },
                                    type: "select",
                                    placeholder: "- Pilih -",
                                    disabled: props.disable
                                },
                                {
                                    label: "Dampak",
                                    options: [{ value: 0, name: "- Pilih -" }, ...(dampak || [])],
                                    value: state.formData.inher_dampak,
                                    onChange: function (e) { actions.setForm("inher_dampak", e) },
                                    type: "select",
                                    placeholder: "- pilih -",
                                    disabled: props.disable
                                },
                                {
                                    label: "Risk Priority Number (RPN)",
                                    type: "custom",
                                    disabled: props.disable,
                                    component: (
                                        <>
                                            <div className="row justify-content-left">
                                                <div className="col-3">
                                                    {risk_priority(state.formData.inher_probabil, state.formData.inher_dampak)}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            ]
                        }, {
                            rows: 2,
                            header: true,
                            name: <>Mitigasi <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                {
                                    type: "textarea",
                                    label: "Rencana mitigasi",
                                    value: state.formData.mit_rencana,
                                    onChange: function (e) { actions.setForm("mit_rencana", e) },
                                    placeholder: "Tulis disini...",
                                    disabled: props.disable
                                },
                                {
                                    label: "Biaya Mitigasi",
                                    type: "custom",
                                    component: (
                                        <div className="form-group">
                                            <input
                                                placeholder="$"
                                                value={state.formData.mit_biaya || 0}
                                                onChange={(e) => actions.setForm("mit_biaya", e.target.value)}
                                                type="number"
                                                min="0.01"
                                                step="0.01"
                                                disabled={props.disable}
                                                className="form-control form-control-sm"
                                            >

                                            </input>
                                        </div>
                                    )
                                },
                                {
                                    label: "Tahun Berlaku",
                                    //value: state.formData.mit_tahun_berlaku,
                                    //onChange: function (e) { actions.setForm("mit_tahun_berlaku", e) },
                                    type: "custom",
                                    //placeholder: "- Pilih - ",
                                    //disabled: props.disable,
                                    component: (
                                        //<input type="number" className="form-control form-control-sm" min={2017} value={state.formData.mit_tahun_berlaku} onChange={(e) => actions.setForm("mit_tahun_berlaku", e.target.value)}></input>
                                        <NumberBox className="mb-5 form-control form-control-sm p-0" disabled={props.disable} placeholder="2017" value={state.formData.mit_tahun_berlaku} min={2017} onValueChanged={(e) => actions.setForm("mit_tahun_berlaku", e.value)} />
                                    )
                                },
                                {
                                    label: "Tahun Selesai",
                                    //value: state.formData.mit_tahun_selesai,
                                    //onChange: function (e) { actions.setForm("mit_tahun_selesai", e) },
                                    type: "custom",
                                    //placeholder: "- Pilih - ",
                                    //disabled: props.disable,
                                    component: (
                                        // <input type="number" className="form-control form-control-sm" min={2017} value={state.formData.mit_tahun_selesai} onChange={(e) => actions.setForm("mit_tahun_selesai", e.target.value)}></input>
                                        <NumberBox className="form-control form-control-sm p-0" disabled={props.disable} placeholder="2017" value={state.formData.mit_tahun_selesai} min={2017} onValueChanged={(e) => actions.setForm("mit_tahun_selesai", e.value)} />
                                    )
                                }
                            ]
                        }, {
                            rows: 2,
                            header: true,
                            name: <>Residual <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                state.formData.tipe_risk === "Kuantitatif" && (
                                    {
                                        type: "custom",
                                        label: "Dampak Kuantitatif (000 USD)",
                                        component: (
                                            <div className="form-group">
                                                <input
                                                    placeholder="$"
                                                    value={state.formData.res_kuant || 0}
                                                    onChange={(e) => actions.setForm("res_kuant", e.target.value)}
                                                    type="number"
                                                    min="0.01"
                                                    step="0.01"
                                                    disabled={props.disable}
                                                    className="form-control form-control-sm"
                                                >

                                                </input>
                                            </div>
                                        )
                                    }
                                ),
                                {
                                    label: "Probabilitas",
                                    options: [{ value: 0, name: "- Pilih -" }, ...(probabilitas || [])],
                                    value: state.formData.res_probabil,
                                    onChange: function (e) { actions.setForm("res_probabil", e) },
                                    type: "select",
                                    placeholder: "- Pilih -",
                                    disabled: props.disable
                                },
                                {
                                    label: "Dampak",
                                    options: [{ value: 0, name: "- Pilih -" }, ...(dampak || [])],
                                    value: state.formData.res_dampak,
                                    onChange: function (e) { actions.setForm("res_dampak", e) },
                                    type: "select",
                                    placeholder: "- Pilih - ",
                                    disabled: props.disable
                                },
                                {
                                    label: "Risk Priority Number (RPN)",
                                    type: "custom",
                                    placeholder: "- Pilih - ",
                                    disabled: props.disable,
                                    component: (
                                        <>
                                            <div className="row justify-content-left">
                                                <div className="col-3">
                                                    {risk_priority_residu(state.formData.res_probabil, state.formData.res_dampak)}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            ]
                        }
                    ]}
                />
                <div className="row my-5">
                    <span className="d-block text-danger">(*) Mandatori tidak boleh kosong</span>
                </div>
                <div className="mt-5" style={!props.disable ? { width: '100%', justifyContent: 'center', display: "flex" } : { display: "none" }}>
                    <button className="btn btn-sm btn-primary" onClick={saveOnclick}><i className="fa fa-save icon-nm text-white"></i> Simpan</button>
                </div>
            </div>
        </>
    )
}
export default ModalViewEditRisk;