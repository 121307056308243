import React, { useEffect } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import { useParams } from 'react-router-dom';
import { workflow_state_name_solid } from '../../components/utility/utility';

const TabApproval = () => {
    const param = useParams();
    const [state, , action] = useGlobalState();
    useEffect(() => {
        if (param?.id) {
            action.getData('/api/project-ongoing-crud/view-history/' + param?.id).then((resp) => {
                if(resp.code === '00'){
                    action.setForm('prohis', resp.data_page);
                }
            })
        }
    }, [])
    return (
        <div>
            <div className="timeline timeline-justified timeline-4">
                <div className="timeline-bar"></div>
                <div className="timeline-items">
                    {
                        [...(state.data_view_history || [])].map((d, i) => (
                            <div className="timeline-item" key={i}>
                                <div className="timeline-badge">
                                    <div className="bg-primary"></div>
                                </div>

                                {/* <div className="timeline-label">
                                    <span className="text-primary font-weight-bold">{d.date_created}</span>
                                </div> */}

                                <div className="timeline-content">
                                    <div>{d.user_name}<span className="text-black-50 ml-3">{d.date_created}</span></div>
                                    <div className="d-flex mt-3">
                                        {
                                            workflow_state_name_solid(d.wfs_name_new, d.wfs_state_new, 0)
                                        }
                                    </div>
                                    <div className="d-flex mt-3">
                                        {
                                            d.comment && (
                                                <span>({d.comment})</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default TabApproval;