import React from 'react';
import { Link } from 'react-router-dom';

export default function ({ children, spanText, spanColour = "rgba(171,222,88, 0.7)", imgSrc = "", linkProps, title }) {

    return (
        <>
            <Link className="link-card" {...linkProps}>
                <div className="card-home h-100 customShadow" style={{ padding: "15px", minHeight: "307px" }}>
                    <div className="row" style={{ marginBottom: "15px" }}>
                        <div className="col-12">

                            <img style={{ objectFit: "contain" }} className="img-populer border" src={imgSrc}
                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12"> */}
                    <span className="label-populer" style={{ background: spanColour }}>
                        {spanText}
                    </span>
                    {/* </div>
                    </div> */}
                    <div style={{ marginTop: 20 }}>
                        <div className="row" style={{ marginBottom: "5px" }}>
                            <div className="col-12">
                                <div className="elipsis-title">
                                    <h6>
                                        {title}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        {
                            children
                        }
                    </div>
                </div>
            </Link>
        </>
    )
}