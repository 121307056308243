import moment from 'moment';
import React, { useEffect } from 'react';
import useCrudEquipment from './useCrudEquipment';

function ModalEditingHistory({ mode = 'Add', data = {} }) {
    const { ceqAction, ceqState } = useCrudEquipment();

    useEffect(() => {
        if (ceqState.globalState?.modal?.modalEqH === true) {
            ceqAction.reseTeditorStatus();
            ceqAction.getEditStatus(mode === 'Add' ? '0' : data?.id);
        }
    }, [ceqState.globalState?.modal?.modalEqH])
    return (
        <div>
            <h5>{mode} Status {(mode === "Edit" && data?.pelapor === false) ? "(Teknisi)" : ""}</h5>
            <hr />
            {
                ((mode === "Add") || (mode === "Edit" && data?.pelapor === true)) && (
                    <>
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Tanggal</span>
                            </div>
                            <div className='col-8'>
                                <span className="text-wrap px-5 py-1 rounded" style={{ backgroundColor: '#F0F0F0' }}>{moment(ceqState.tanggal_mulai).format('DD MMMM YYYY')} <span style={{ color: '#ACC429' }}></span></span>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Status</span>
                            </div>
                            <div className='col-8'>
                                <select className="form-control form-control-sm" disabled={mode === 'Edit' ? true : false}
                                    value={ceqState.equipment_status} onChange={(e) => ceqAction.setEquipment_status(e.target.value)}
                                >
                                    <option value={''}>- Pilih -</option>
                                    {
                                        [...(ceqState.data_status?.data_status || [])].map((d, i) => (
                                            <option key={i} value={d?.param_id}>{d?.title}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {/* {
                            (ceqState.equipment_status === '2' || ceqState.equipment_status === '4') && (
                                <div className="row mb-3">
                                    <div className='col-4'>
                                        <span className="d-block text-wrap">Biaya</span>
                                    </div>
                                    <div className='col-8'>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="number" min={0} className="form-control" placeholder="Biaya perbaikan/perawatan" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                value={ceqState.biaya} onChange={(e) => ceqAction.setBiaya(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2">USD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } */}
                        {
                            (ceqState.equipment_status !== '5') ? (
                                <div className="row mb-3">
                                    <div className='col-4'>
                                        <span className="d-block text-wrap">Masa Berlaku Status</span>
                                    </div>
                                    <div className='col-8'>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="number" min={0} className="form-control" placeholder="Masa berlaku status dalam hari" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                value={ceqState.masa_status} onChange={(e) => ceqAction.setMasa_status(e.target.value)} disabled={mode === 'Edit' ? true : false}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2">Hari</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row mb-3">
                                    <div className='col-4'>
                                        <span className="d-block text-wrap">Tanggal</span>
                                    </div>
                                    <div className='col-8'>
                                        <input type="date" className="form-control" placeholder="Tanggal" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                            value={ceqState.tanggal_mulai}
                                            onChange={(e) => ceqAction.setTanggal_mulai(e.target.value)} disabled={mode === 'Edit' ? true : false}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Keterangan</span>
                                <span className="d-block text-wrap"> {ceqState.equipment_status === '2' ? '(Perkiraan Kerusakan)' : ''}</span>
                            </div>
                            <div className='col-8'>
                                <textarea placeholder='Keterangan' className="form-control form-control-sm" rows={4}
                                    value={ceqState.deskripsi_status} onChange={(e) => ceqAction.setDeskripsi_status(e.target.value)}
                                >
                                </textarea>
                            </div>
                        </div>
                        {
                            ceqState.equipment_status === '2' && (
                                <div className="row mb-3">
                                    <div className='col-4'>
                                        <span className="d-block text-wrap">Kronologi Kejadian</span>
                                    </div>
                                    <div className='col-8'>
                                        <textarea placeholder='Kronologi kejadian' className="form-control form-control-sm" rows={4}
                                            value={ceqState.kronologi_kejadian} onChange={(e) => ceqAction.setKronologi_kejadian(e.target.value)}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                (mode === "Edit" && data?.pelapor === false) && (
                    <>
                        {
                            (ceqState.equipment_status === '2' || ceqState.equipment_status === '4') && (
                                <div className="row mb-3">
                                    <div className='col-4'>
                                        <span className="d-block text-wrap">Biaya</span>
                                    </div>
                                    <div className='col-8'>
                                        <div className="input-group input-group-sm mb-3">
                                            <input type="number" min={0} className="form-control" placeholder="Biaya perbaikan/perawatan" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                                value={ceqState.biaya} onChange={(e) => ceqAction.setBiaya(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2">USD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Analisa Kerusakan</span>
                            </div>
                            <div className='col-8'>
                                <textarea placeholder='Analisa Kerusakan' className="form-control form-control-sm" rows={4}
                                    value={ceqState.analisa_kerusakan} onChange={(e) => ceqAction.setAnalisa_kerusakan(e.target.value)}
                                >
                                </textarea>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Tindakan Perbaikan</span>
                            </div>
                            <div className='col-8'>
                                <textarea placeholder='Tindakan perbaikan' className="form-control form-control-sm" rows={4}
                                    value={ceqState.tindakan_perbaikan} onChange={(e) => ceqAction.setTindakan_perbaikan(e.target.value)}
                                >
                                </textarea>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Teknisi</span>
                            </div>
                            <div className='col-8'>
                                <input placeholder='Teknisi' className="form-control form-control-sm"
                                    value={ceqState.nama_teknisi} onChange={(e) => ceqAction.setNama_teknisi(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Status Perbaikan</span>
                            </div>
                            <div className='col-8'>
                                <select className="form-control form-control-sm"
                                    value={ceqState.status_maintenance}
                                    onChange={(e) => ceqAction.setStatus_maintenance(e.target.value)}
                                >
                                    {
                                        [{
                                            "id": 0,
                                            "param_name": "",
                                            "param_id": 0,
                                            "title": "- Pilih -",
                                            "title2": null,
                                            "status": 1
                                        }, ...(ceqState.data_status?.data_status_maintenancen || [])].map((d, i) => (
                                            <option key={i} value={d?.param_id}>{d?.title}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col-4'>
                                <span className="d-block text-wrap">Tanggal Selesai Perbaikan</span>
                            </div>
                            <div className='col-8'>
                                <input type="date" className="form-control" placeholder="Tanggal" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    value={ceqState.tanggal_selesai_maintenance}
                                    onChange={(e) => ceqAction.setTanggal_selesai_maintenance(e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                )
            }
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={() => ceqAction.globalAction.toggleModal('modalEqH')}
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-primary mx-1"
                    onClick={() => ceqAction.simpanStatusClick(data?.pelapor)}
                >
                    <i className="fa fa-save icon-nm"></i>
                    Simpan
                </button>
            </div>
        </div>
    );
}

export default ModalEditingHistory;