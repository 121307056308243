import React, { useEffect } from 'react';
import { FormResearch } from '../../components';
import { useGlobalState } from "../../contexts/useGlobalState";
import {
    risk_priority,
    risk_priority_residu,
    probabilitas,
    dampak,    
} from '../../components/utility/utility';

const formatter = new Intl.NumberFormat('en-US', {
    valuta: 'USD',
    minimumFractionDigits: 2
})

const ModalViewEditRisk = (props) => {
    const [state, , actions] = useGlobalState();
    useEffect(() => {
        actions.setForm("id", props.data?.id)
        actions.setForm("research_id", props.data?.research_id)
        actions.setForm("baseline_name", props.data?.baseline_name)
        actions.setForm("risk_id", props.data?.risk_id)
        actions.setForm("tipe_risk", props.data?.tipe_risk)
        actions.setForm("kejadian", props.data?.kejadian)
        actions.setForm("penyebab", props.data?.penyebab)
        actions.setForm("gejala", props.data?.gejala)
        actions.setForm("faktor_positif", props.data?.faktor_positif)
        actions.setForm("dampak_kual", props.data?.dampak_kual)
        actions.setForm("inher_kuant", props.data?.inher_kuant)
        actions.setForm("inher_probabil", props.data?.inher_probabil)
        actions.setForm("inher_dampak", props.data?.inher_dampak)
        actions.setForm("mit_rencana", props.data?.mit_rencana)
        actions.setForm("mit_biaya", props.data?.mit_biaya)
        actions.setForm("mit_tahun_berlaku", props.data?.mit_tahun_berlaku)
        actions.setForm("mit_tahun_selesai", props.data?.mit_tahun_selesai)
        actions.setForm("res_kuant", props.data?.res_kuant)
        actions.setForm("res_probabil", props.data?.res_probabil)
        actions.setForm("res_dampak", props.data?.res_dampak)
    }, [props.data])

    return (
        <>
            <div style={{ padding: '10px' }}>

                <FormResearch
                    formLists={[
                        {
                            rows: 1,
                            header: true,
                            name: <>Project Risk <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                {
                                    type: "custom",
                                    label: "Kuantitatif/Kuantitatif Risk",
                                    component: (<div className='form-group style-view-disable'>{state.formData.tipe_risk}</div>),
                                },
                                {
                                    label: "Kejadian Resiko (Risk Event)",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{state.formData.kejadian}</div>),
                                },
                                {
                                    label: "Penyebab Resiko (Risk Agent)",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{state.formData.penyebab}</div>),
                                }
                                ,
                                {
                                    label: "Gejala Resiko (KRI)",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{state.formData.gejala}</div>),
                                }
                                ,
                                {
                                    label: "Faktor Positif (Control)",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{state.formData.faktor_positif}</div>),
                                }
                                ,
                                {
                                    label: "Dampak Kualitatif",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{state.formData.dampak_kual}</div>),
                                }
                            ]
                        }, {
                            rows: 2,
                            header: true,
                            name: <>Inherent <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                state.formData.tipe_risk === "Kuantitatif" && (
                                    {
                                        type: "custom",
                                        label: "Dampak Kuantitatif (000 USD)",
                                        component: (<div className='form-group style-view-disable'>{formatter.format(state.formData.inher_kuant || 0)}</div>)
                                    }
                                )
                                ,
                                {
                                    label: "Probabilitas",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{probabilitas.find(d => d.value === state.formData.inher_probabil)?.name || ''}</div>),
                                },
                                {
                                    label: "Dampak",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{dampak.find(d => d.value === state.formData.inher_dampak)?.name || ''}</div>),
                                },
                                {
                                    label: "Risk Priority Number (RPN)",
                                    type: "custom",
                                    component: (
                                        <>
                                            <div className="form-group">
                                                <div className="d-inline-block" style={{ 'width': '50px' }}>
                                                    {risk_priority(state.formData.inher_probabil, state.formData.inher_dampak)}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            ]
                        }, {
                            rows: 2,
                            header: true,
                            name: <>Mitigasi <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                {
                                    type: "custom",
                                    label: "Rencana mitigasi",
                                    component: (<div className='form-group style-view-disable'>{state.formData.mit_rencana}</div>),
                                },
                                {
                                    label: "Biaya Mitigasi",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{formatter.format(state.formData.mit_biaya || 0)}</div>)
                                },
                                {
                                    label: "Tahun Berlaku",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{state.formData.mit_tahun_berlaku}</div>),

                                },
                                {
                                    label: "Tahun Selesai",
                                    type: "custom",
                                    component: (<div className='form-group style-view-disable'>{state.formData.mit_tahun_selesai}</div>),

                                }
                            ]
                        }, {
                            rows: 2,
                            header: true,
                            name: <>Residual <span className="text-danger ml-2">(*)</span></>,
                            childs: [
                                state.formData.tipe_risk === "Kuantitatif" && (
                                    {
                                        type: "custom",
                                        label: "Dampak Kuantitatif (000 USD)",
                                        component: (<div className='form-group style-view-disable'>{formatter.format(state.formData.res_kuant || 0)}</div>)
                                    }
                                ),
                                {
                                    type: "custom",
                                    label: "Probabilitas",
                                    component: (<div className='form-group style-view-disable'>{probabilitas.find(d => d.value === state.formData.res_probabil)?.name || ''}</div>),
                                },
                                {
                                    type: "custom",
                                    label: "Dampak",
                                    component: (<div className='form-group style-view-disable'>{dampak.find(d => d.value === state.formData.res_dampak)?.name || ''}</div>),
                                },
                                {
                                    label: "Risk Priority Number (RPN)",
                                    type: "custom",
                                    placeholder: "- Pilih - ",
                                    disabled: props.disable,
                                    component: (
                                        <>
                                            <div className="form-group">
                                                <div className="d-inline-block" style={{ 'width': '50px' }}>
                                                    {risk_priority_residu(state.formData.res_probabil, state.formData.res_dampak)}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            ]
                        }
                    ]}
                />
                {/* <div className="row my-5">
                    <span className="d-block text-danger">(*) Mandatori tidak boleh kosong</span>
                </div> */}
            </div>
        </>
    )
}
export default ModalViewEditRisk;