import React, { useEffect } from "react";
import useMonthlyReport from "./useMonthlyReport";
import usePageAchievement, { UpaProvider } from "./usePageAchievement";

const PageAchievement = ({ data = {} }) => {
  const { rState, rAction } = useMonthlyReport();
  const { upaAction, upaState } = usePageAchievement();
  useEffect(() => {
    let idLA = data?.data_view_achieve_con?.find(
      ({ type }) => type === "Achievement")?.from_logbook_research || "";

    let idLCon = data?.data_view_achieve_con?.find(
      ({ type }) => type === "Concern")?.from_logbook_research || "";
    upaAction.getDataLogbook(idLA, 0)
    upaAction.getDataLogbook(idLCon, 1)
  }, [])
  return (
    <div className="page-content">
      <div className="row">
        <div className="col-6">
          <div className="card blue_2">
            <h5 className="blue_1" style={{ padding: "20px 10px" }}>
              Achievement
            </h5>
            <div className="card-content" style={{ padding: 20 }}

            ><div style={{ padding: 20 }}
              dangerouslySetInnerHTML={{
                __html: data.data_view_achieve_con?.find(
                  ({ type }) => type === "Achievement")?.description
              }}
              />
              {
                (upaState.logBookAch || []).length > 0 && (
                  <>
                    <hr />
                    <h5 className="mb-2">Data From Logbook</h5>
                    {
                      (upaState.logBookAch || []).map(({ description }, k) => (
                        <div className="card-content" style={{ padding: 20 }}
                          dangerouslySetInnerHTML={{
                            __html: description
                          }}
                        />
                      ))
                    }
                  </>
                )
              }
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="card violet_2">
            <h5 className="violet_1" style={{ padding: "20px 10px" }}>
              Concern
            </h5>
            <div className="card-content" style={{ padding: 20 }}

            >

              <div style={{ padding: 20 }}
                dangerouslySetInnerHTML={{
                  __html: data.data_view_achieve_con?.find(
                    ({ type }) => type === "Concern")?.description
                }}
              />
              {
                (upaState.logBookCon || []).length > 0 && (
                  <>
                    <hr />
                    <h5 className="mb-2">Data From Logbook</h5>
                    {
                      (upaState.logBookCon || []).map(({ description }, k) => (
                        <div className="card-content" style={{ padding: 20 }}
                          dangerouslySetInnerHTML={{
                            __html: description
                          }}
                        />
                      ))
                    }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <div className="card green_2">
            <h5 className="green_1" style={{ padding: "20px 10px" }}>
              Way Forward
            </h5>
            <div className="card-content" style={{ padding: 20 }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data.data_view_achieve_con?.find(
                    ({ type }) => type === "Way Forward")?.description
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ({ data = {} }) => <UpaProvider><PageAchievement data={data} /></UpaProvider>;
