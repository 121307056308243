import React, { memo, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { status_pegawai } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment';

const ModalChangeStatus = memo(({ name, status, user_id, end_dates = '', onSuccess = () => { } }) => {
    const [state, dispatch, action] = useGlobalState();
    const [status_profil, setStatus_profil] = useState();
    const [end_date, setEnd_date] = useState();
    const [dataStatus, setDataStatus] = useState([]);

    useEffect(() => {
        if (state.modal.modalChangeStatus === true) {
            // console.log(end_dates)
            getData();
            setStatus_profil(status);
            setEnd_date((end_dates === '1900-01-01' || end_dates === '' || end_dates === null) ? '' : moment(end_dates).format('YYYY-MM-DD'));
        }
    }, [state.modal.modalChangeStatus])

    function saveStatus() {
        const data = {
            user_id: user_id,
            status_profil: status_profil || '',
            end_date: end_date || ''
        }
        if (data.status_profil === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Harap Pilih Status',
                icon: 'warning',
            })
        } else if (data.status_profil === 'Mitra Kerja' || data.status_profil === 'Retired' || data.status_profil === 'PWT') {
            if (data.end_date === '' || data.end_date === null) {
                return Swal.fire({
                    title: 'Warning',
                    text: 'Harap isi end date jika status profile Mitra Kerja, PWT atau Retired',
                    icon: 'warning',
                })
            } else {
                const res = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-sm btn-primary mx-1',
                        cancelButton: 'btn btn-sm btn-secondary mx-1'
                    },
                    buttonsStyling: false
                }).fire({
                    html:
                        '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                        '<strong>Update Status? </strong>',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: true
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        // console.log(data)
                        action.postData(
                            {
                                url: `/api/people-hrd/change-status`,
                                data: data,
                                withFile: false
                            }
                        ).then((res) => {
                            if (res.code === "00") {
                                onSuccess();
                            }
                        })
                        return true;
                    } else {
                        return true;
                    }
                })
            }
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Status? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    // console.log(data)
                    action.postData(
                        {
                            url: `/api/people-hrd/change-status`,
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            onSuccess();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function getData() {
        action.getData('/api/param-status-pegawai', {}, null, null, true).then((resp) => {
            if (resp.code === "00") {
                setDataStatus(resp?.data);
            }
        })
    }
    return (
        <Card
            className="m-0 p-0 customShadow"
        >
            <Card.Body>
                <span><b>Change Status</b></span>
                <hr />
                <div className="row mb-3">
                    <div className="col-md-3">Name</div>
                    <div className="col-md-9">
                        <span className="mr-2">{name}</span>
                        <div className="d-inline-flex">{status_pegawai(status)}</div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-3">Ubah Status</div>
                    <div className="col-md-9">
                        <select className="form-control form-control-sm" value={status_profil} onChange={(e) => setStatus_profil(e.target.value)}>
                            <option value="">- Pilih Status -</option>
                            {
                                dataStatus.map((d, i) => (
                                    <option value={d?.title} key={i}>{d?.title}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {
                    (status_profil === "Retired" || status_profil === "PWT" || status_profil === "Mitra Kerja") && (
                        <div className="row mb-3">
                            <div className="col-md-3">End Date</div>
                            <div className="col-md-9">
                                <input type='date' className="form-control form-control-sm" value={end_date} onChange={(e) => setEnd_date(e.target.value)}></input>
                            </div>
                        </div>
                    )
                }
                <hr />
                <div className="d-block mb-5 text-right">
                    <button className="btn btn-sm btn-secondary mx-1"
                        onClick={() => action.toggleModal("modalChangeStatus")}
                    >
                        Batal
                    </button>
                    <button className="btn btn-sm btn-primary"
                        onClick={() => saveStatus()}
                    >
                        <i className="fa fa-save icon-nm mx-1 text-white"></i>
                        Save
                    </button>
                </div>
            </Card.Body>
        </Card>
    )
})
export default ModalChangeStatus