import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { useGlobalState } from "../../contexts/useGlobalState";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const dutContext = createContext({
    dutAction: {},
    dutState: {}
});
const defColumn = [
    { fieldName: 'position', caption: 'Position' },
    { fieldName: 'chief', caption: 'Section' },
    { fieldName: 'func', caption: 'Fungsi' },
    { fieldName: 'jumlah', caption: 'Jumlah' },
];

export function DutProvider({ children }) {
    const [, , gaction] = useGlobalState();
    const { search } = useLocation();
    const [column, setColumn] = useState(defColumn);
    const param = new URLSearchParams(search).get('q');
    const {
        tahun = '',
        bulan = '',
        hari = '',
        tipe = '',
        tipe_table = 1,
        param_filter_name = 'functionID',
        param_filter_value = '0'
    } = JSON.parse(atob(param));
    const [data_page, setData_page] = useState({
        page_title: '',
        page_breadcrumbs: ''
    });

    const [data_table, setData_table] = useState([]);
    const [loading, setLoading] = useState(false);
    async function getTable(filter = {}, tipe = '', url = '') {
        try {
            setLoading(true);
            const resp = await gaction.getData(url, {
                tahun: '',
                bulan: '',
                hari: '',
                ...filter,
                tipe
            }, null, null, true);
            if (resp?.code === "00") {
                setData_table(resp?.data || []);
                setData_page(resp?.data_page);
            }
            setLoading(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }

    useEffect(() => {
        if (tipe_table === 1) {
            getTable({ tahun, bulan, hari, [param_filter_name]: param_filter_value }, tipe, '/api/home-admin/dashboard-user-position-tabel')
        }
        if (tipe_table === 2) {
            setColumn([{ fieldName: 'nama', caption: 'Nama' }, { fieldName: 'func', caption: 'Role User' }, { fieldName: 'jumlah', caption: 'Jumlah' }])
            getTable({ tahun, bulan, hari, [param_filter_name]: param_filter_value }, tipe, '/api/home-admin/dashboard-user-admin-tabel')
        }
    }, [])
    return <dutContext.Provider
        value={{
            dutAction: {},
            dutState: {
                data_page,
                data_table,
                loading,
                column
            }
        }}
    >{children}</dutContext.Provider>
}

export default function useDut() {
    return useContext(dutContext);
}