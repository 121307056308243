import React from 'react';

function WaEventReminder(props) {
    return (
        <div className='card customShadow'>
            <div className='card-body p-5'>
            </div>
        </div>
    );
}

export default WaEventReminder;