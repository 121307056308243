import React, { useEffect, useRef } from 'react';
import { GridDx, HeaderList } from '../../../../components';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useFetch from '../../../../modules/useFetch';

const column = [
    { fieldName: "employee_id", caption: "No. Pegawai", width: 100 },
    { fieldName: "name", caption: "Nama" },
    { fieldName: "date_of_birth", caption: "Tanggal Lahir", width: 100 },
    { fieldName: "handphone", caption: "No. Handphone", width: 130 },
    { fieldName: "photo_location", caption: "Photo", width: 100 },
    { fieldName: "join_date", caption: "Tanggal bergabung", width: 100 },
    { fieldName: "position_id", caption: "Posisi", width: 100 },
    { fieldName: "user_educations", caption: "Pendidikan", width: 100 },
    { fieldName: "user_expertises", caption: "Expertise", width: 100 },
    { fieldName: "user_experience_in_pertaminas", caption: "Pengalaman di dalam Pertamina" },
    { fieldName: "user_experience_outside_pertaminas", caption: "Pengalaman di luar Pertamina" },
]
function DetailUserProfileReminder(props) {
    const { doGet } = useFetch();
    const [data_page, setData_page] = useState({});
    const tableRef = useRef();
    
    const customCellRender = (rowInfo) => {
        return (
            <span>{rowInfo.value}</span>
        )
    }
    useEffect(() => {
        getData();
    }, []);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    async function getData() {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/user/table-search-user-profile',
            });
            if (resp?.code === '00') {
                setData(resp?.data);
                setData_page(resp?.data_page);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }
    return (
        <div className='p-5'>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <GridDx
                                searchForm={true}
                                loading={loading}
                                ref={tableRef}
                                data={data}
                                columns={column}
                                cellRender={customCellRender}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default DetailUserProfileReminder;