import React from "react";
import { AuthConsumer } from "./authenticationProvider";

const LogoutCallback = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return <span>loading</span>;
        }}
    </AuthConsumer>
);

export default LogoutCallback