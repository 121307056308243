import React, { useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GridDx, HeaderList } from '../../../components';
import useDetailLeader, { DetailLeaderProvider } from './use_detail_leader';

const column = [
    { fieldName: 'name', caption: 'Nama' },
    { fieldName: 'short_name', caption: 'Fungsi' },
    { fieldName: 'jumlah', caption: 'Jumlah RKAP' },
]
function TableDetailLeader(props) {
    const tableRef = useRef();
    const { dLeadState, dLeadAction } = useDetailLeader();
    const history = useHistory();
    return (
        <div className='p-5'>
            <HeaderList
                title={dLeadState.data_page?.page_title}
                someText={dLeadState.data_page?.page_breadcrumbs}
            />
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <GridDx
                                toolbar={
                                    <select className='form-control form-control-sm'
                                        value={dLeadState.func_id}
                                        onChange={(e) => dLeadAction.fungsiChange(e.target.value)}
                                    >
                                        {
                                            [{ id: '', name: '- All -' }, ...(dLeadState.data?.data_function || [])].map((d, i) => (
                                                <option key={i} value={d?.id}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                }
                                searchForm={true}
                                ref={tableRef}
                                // loading={state.loadingGrid}
                                data={dLeadState.data?.data_chart1_JumlUsulanProyek}
                                columns={column}
                                // cellRender={(e) => cstmCellRender(e)}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                            {/* <button onClick={() => history.goBack()} className='btn btn-sm btn-secondary mt-5'>Kembali</button> */}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default () => <DetailLeaderProvider><TableDetailLeader /></DetailLeaderProvider>;