import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';
import { HeaderList } from '../../components';
import Skeleton from 'react-loading-skeleton';


const dummy = [
    {
        year: 2019,
        month: 'Januari'
    },
    {
        year: 2020,
        month: 'Januari'
    }
]
export default function (props) {
    const param = useParams();
    const history = useHistory();
    const [state, dispatch, actions] = useGlobalState();

    async function getDataUser() {
        actions.getData('/api/me').then((resp) => {
            if (resp.code === "00") {
                dispatch({
                    type: "getSuccess",
                    payload: { dataUser: resp.data.data_user_me?.[0], loadingGet: false }
                })
            }
        })
    }
    async function getDataMonthlyIndex() {
        actions.getData(`/api/project-ongoing-crud/view-monthly-${state.dataUser?.role_id === '10' ? '4' : '2'}/` + param?.id, {}, null, "isLoading", true).then((resp) => {
            if (resp.code === '00') {
                dispatch({
                    type: 'getSuccess',
                    payload: { data_page: resp.data_page }
                })
            }
        })
    }
    useEffect(() => {
        if (state.dataUser) {
            getDataMonthlyIndex();
        } else {
            getDataUser();
        }
    }, [state.dataUser])
    return (
        <>
            <HeaderList
                title={state.data_page?.page_title}
                someText={state.data_page?.page_breadcrumbs}
            ></HeaderList>
            <div
                className="row justify-content-center mb-5"
                style={{ fontSize: '8pt' }}
            >
                <div className="col-md-10">
                    {
                        state.dataUser ? (
                            <Card
                                className="shadow-sm"
                            >
                                <Card.Body>
                                    <h5>Monthly Report</h5>
                                    <hr />
                                    <div className="p-5 bg-light-primary rounded">
                                        <span className="d-block text-wrap"><strong>{state.data_view_header?.title}</strong></span>
                                        <span className="d-block text-wrap">{state.data_view_header?.description}</span>
                                        <table className="mt-5">
                                            <tr>
                                                <td width='150px'><strong>Function</strong></td>
                                                <td>:</td>
                                                <td>{state.data_view_header?.function_name}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Chief/Manager</strong></td>
                                                <td>:</td>
                                                <td>{state.data_view_header?.chief_manager_name}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Research Category</strong></td>
                                                <td>:</td>
                                                <td>{state.data_view_header?.research_category_name}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="row mt-5 justify-content-center">
                                        <div className="col-md-10">
                                            <div className="timeline timeline-6 mt-3">
                                                {
                                                    [...(state.data_view_monthly || [])].map((d, i) => (
                                                        <div className="timeline-item align-items-start" key={i}>
                                                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-nm my-auto">{d.year}</div>
                                                            <div className="timeline-badge">
                                                                <i className="fa fa-genderless text-primary icon-xl"></i>
                                                            </div>
                                                            <div className="font-weight-mormal font-size-sm timeline-content d-flex my-auto">
                                                                <span className="font-weight-bolder text-dark-50 pl-3 font-size-nm d-block">
                                                                    {d.month_name}
                                                                    <Link
                                                                        // target="_blank"
                                                                        to={{
                                                                            pathname: '/research/monthlyReport-public/' + param.id + '/' + d.year + '/' + d.month
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-search text-primary icon-nm mx-3"></i>
                                                                    </Link>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                                {/* <Card.Footer>
                        <button className="btn btn-sm btn-outline-primary mt-5" onClick={() => history.goBack()}>Back</button>
                    </Card.Footer> */}
                            </Card>
                        ) : (
                            <Skeleton height={400} />
                        )
                    }
                </div>
            </div>
            <div className="p-2 bg-white rounded shadow-lg floating-footer-nolayout" >
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                    </div>
                </div>
            </div>
        </>
    );
}

