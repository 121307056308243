import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../components';
// import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import { Link, useHistory } from 'react-router-dom'
// import Swal from 'sweetalert2/dist/sweetalert2.js'
import { achievement_status_filter } from '../../components/utility/utility';
import moment from 'moment';

const columns = [
    { fieldName: "name", caption: "Judul", width: 200 },
    { fieldName: "description", caption: "Description", width: 500 },
    { fieldName: "status_approval", caption: "Status", width: 100, headerFilterSource: achievement_status_filter, onExport: (e) => e.status_approval === 2 ? "Approved" : "Waiting Approval" },
    { fieldName: "owner_name", caption: "Owner", width: 200 },
    { fieldName: "level", caption: "Level", width: 100 },
    { fieldName: "year_of_achievement", caption: "Year", width: 100 },
    { fieldName: "creator_name", caption: "Creator", width: 100 },
]

function ReportingPeopleAchievement(props) {
    const [state, dispatch, action] = useGlobalState();
    // const history = useHistory();
    const tableRef = useRef();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData('/api/people-achievement/table-3', {}, null, null, true).then((resp) => {
            if (resp.code === "00") {
                setData_page(resp.data_page);
            }
        });
    }, [])
    const customRender = (e) => {
        if (e.column.name === "name") {
            return (
                <Link to={{
                    pathname: `/admin/people/achievement-detail-crud/${e.data.id}`,
                    state: { view_admin: true }
                }}>
                    {
                        e.value
                    }
                </Link>
            )
        } else if (e.column.name === "status_approval") {
            return (
                <div className="my-2">
                    {
                        e.value === 1 && (
                            <span className="bg-light-warning text-warning p-2 rounded">Waiting Approval</span>
                        )
                    }
                    {
                        e.value === 2 && (
                            <span className="bg-light-success text-success p-2 rounded">Approved</span>
                        )
                    }

                </div>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <div>
            <div className="pb-5">
                <HeaderList
                    title={data_page?.page_title}
                    someText={data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            <Card
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        ref={tableRef}
                        cellRender={customRender}
                        searchForm={true}
                        settingColumn={true}
                        filenameExport={`Achievement Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                        selectionMode="multiple"
                        exportMode="selection"
                        exportExcel={true}
                        data={state.data_table || []}
                        columns={columns}
                    />
                </Card.Body>
            </Card>
        </div>
    );
}

export default ReportingPeopleAchievement;