import { PDFExport, PDFMargin } from '@progress/kendo-react-pdf';
import React from 'react';
import { useRef } from 'react';
import './style.css';
import useEquipmentReporting, { EquipmentReportProvider } from './service';

function Index(props) {
    const refPDf = useRef();
    const { state } = useEquipmentReporting();

    return (
        <div className='d-flex justify-content-center'>
            <div className="toolbar-report-status">
                <div className="row">
                    <div className="col-4 form-inline">
                        <div className="form-group form-group-sm col-6">

                        </div>
                    </div>
                    <div className="col-4">
                        <div
                            className="alert bg-blue-sky text-center"
                            style={{
                                borderRadius: "5px",
                                width: "100%",
                                margin: "auto",
                            }}
                        >
                            <span style={{ fontWeight: "bold" }}>Reporting History Status Alat</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <button
                            onClick={() => {
                                if (refPDf.current) {
                                    refPDf.current.save();
                                }
                            }}
                            className="btn btn-sm btn-success mr-3 float-right"> <i className="fa fa-download"></i> Download PDF</button>
                    </div>
                </div>
            </div>
            <div className='bg-white doc-export-style' style={{
                width: '210mm',
                paddingTop: '10mm',
                paddingBottom: '27mm',
                paddingLeft: '16mm',
                paddingRight: '16mm',
                marginTop: '100px'
            }}>
                <PDFExport forcePageBreak=".page-break" paperSize="A4" ref={refPDf} fileName={`Report Maintenance.pdf`}>
                    <PDFMargin top={"10mm"} left={"16mm"} bottom={"27mm"} right={"16mm"} />
                    <div className='d-flex w-100 header-doc' style={{ border: '1px solid black' }}>
                        <div className='w-50 border-right border-dark d-block'><span>PT. PERTAMINA (PERSERO)<br />LABORATORY - RTI</span></div>
                        <div className='w-50'><span>LAPORAN KERUSAKAN DAN<br />TINDAKAN PERBAIKAN</span></div>
                    </div>
                    <div className='row-2 w-100 border-bottom border-left border-right border-top-0 border-dark'>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '110px' }}>Nama Alat/Mesin</div>
                            <span className='text-wrap'>: {state.data?.nama}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '110px' }}>Lokasi Alat/Mesin</div>
                            <span className='text-wrap'>: {state.data?.equipment_loc_str}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '110px' }}>Perkiraan Kerusakan</div>
                            <span className='text-wrap'>: {state.data?.perkiraan_kerusakan}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '110px' }}>Tanggal Kerusakan</div>
                            <span className='text-wrap'>: {state.data?.tanggal_kerusakan}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '110px' }}>Running Hours</div>
                            <span className='text-wrap'>: {state.data?.umur_alat}</span>
                        </div>
                    </div>
                    <div className="row-2 w-100 border-bottom border-left border-right border-top-0 border-dark">
                        <div>
                            <p className='mb-0'>Kronologi Kejadian</p>
                            <p className='pl-3'>{state.data?.kronologi_kejadian}</p>
                        </div>
                        <span><span className='text-danger'>*</span> Jika kolom ini tidak mencukupi maka dapat melampirkan keterangan tambahan.</span>
                    </div>
                    <div className='row-4 border-bottom border-left border-right border-top-0 border-dark'>
                        <div className='w-50 border-right border-dark d-block'>
                            <div className='d-flex h-100 flex-column justify-content-between text-center pt-2'>
                                <div>
                                    <span>Mengetahui</span>
                                    <br />
                                    <span>Penanggung Jawab.</span>
                                    {state.data?.tanggal_approval1 && (
                                        <div className='d-flex justify-content-center'>
                                            <div className='px-5 py-1 bg-light-primary'>
                                                <span className='d-block'>{state.data?.tanggal_approval1}</span>
                                                <span>Approved By</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <span>{state.data?.nama_penanggung_jawab ? `(${state.data?.nama_penanggung_jawab})` : ''}</span>
                            </div>
                        </div>
                        <div className='w-50 mt-0'>
                            <div className='d-flex h-100 flex-column justify-content-between text-center pt-2'>
                                <div>
                                    <br />
                                    <span>Yang Melaporkan</span>
                                    {state.data?.tanggal_kerusakan && (
                                        <div className='d-flex justify-content-center'>
                                            <div className='px-5 py-1 bg-light-primary'>
                                                <span className='d-block'>{state.data?.tanggal_kerusakan}</span>
                                                <span>Reported By</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <span>{state.data?.nama_pelapor ? `(${state.data?.nama_pelapor})` : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row-2 w-100 border-bottom border-left border-right border-top-0 border-dark">
                        <div>
                            <p className='mb-0'>Analisa</p>
                            <p className='pl-3'>{state.data?.analisa_kerusakan}</p>
                        </div>
                    </div>
                    <div className="row-2 w-100 border-bottom border-left border-right border-top-0 border-dark">
                        <div>
                            <p className='mb-0'>Tindakan Perbaikan</p>
                            <p className='pl-3'>{state.data?.tindakan_perbaikan}</p>
                        </div>
                    </div>
                    <div className="row-7 w-100 border-bottom border-left border-right border-top-0 border-dark">
                        <p className='text-center mb-1'>Serah Terima Hasil Tindakan Perbaikan</p>
                        <div className='d-flex w-100 h-100'>
                            <div className='w-50'>
                                <div className='d-flex align-items-center h-100 flex-column justify-content-between pt-1 content-left-row-7'>
                                    <div className='text-center'>
                                        <span className='d-block'>Tanggal : {state.data?.tanggal_selesai_maintenance} </span>
                                        <span>Yang Menerima</span>
                                        {state.data?.tanggal_approval2 && (
                                            <div className='d-flex justify-content-start'>
                                                <div className='px-5 py-1 bg-light-primary'>
                                                    <span className='d-block'>{state.data?.tanggal_approval2}</span>
                                                    <span>Reported By</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <span>{state.data?.nama_pelapor ? `(${state.data?.nama_pelapor})` : ''}</span>
                                </div>
                            </div>
                            <div className='w-50'>
                                <div className='d-flex align-items-center h-100 flex-column justify-content-between content-right-row-7'>
                                    <div className='text-center'>
                                        <br />
                                        <span>Yang Menyerahkan</span>
                                        {state.data?.tanggal_teknisi && (
                                            <div className='d-flex justify-content-start'>
                                                <div className='px-5 py-1 bg-light-primary'>
                                                    <span className='d-block'>{state.data?.tanggal_teknisi}</span>
                                                    <span>Reported By</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <span>{state.data?.nama_nama_teknisi ? `(${state.data?.nama_nama_teknisi})` : ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='mt-2' style={{ fontWeight: 700 }}>F02/C6-236</p>
                </PDFExport>
            </div>
        </div>
    );
}

export default () => <EquipmentReportProvider><Index /></EquipmentReportProvider>;