import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import SVG from "react-inlinesvg";
import './card-menu-home.css'


const CardHome = ({ colour = "#F6406A", url = "!#", iconUrl, title = "" }) => {
    const renderCard = useMemo(() => {
        return (
            <Link to={url} className="link-card">
                <div className="card-menu h-100 customShadow border-0 text-center" style={{ background: colour }}>
                    <div className="img-menu">
                        <span className="p-3 rounded svg-icon svg-icon-5x svg-icon-light">
                            <SVG src={iconUrl} />
                        </span>
                        {/* <img src={iconUrl} className="svg-icon-light" /> */}
                    </div>
                    <div className="title-menu text-center">
                        <h5>{title}</h5>
                    </div>
                </div>
            </Link>
        )
    }, [colour, url, iconUrl, title]);
    return renderCard
}

export default CardHome;