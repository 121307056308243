import React from 'react';
import { MilestonesBreadcrumbs } from '../../../components';
import useProjectProfile from './useProjectProfile';

const ProjectMilestone = () => {
    const { pfState, pfAction } = useProjectProfile();
    return (
        <div className="page-content-pp">
            <div className="row">
                <div className="col-12">
                    <MilestonesBreadcrumbs data={pfState.gState?.data_view_timeline} titleField="year" renderItem={pfAction.renderMilestonesItem} />
                    {/* <span class="page-title-pp">
                        Yearly
                    </span>
                    <table style={{ textAlign: "left", width: "100%", marginTop: "10px" }} border="1">
                        <thead>
                            <tr style={{ backgroundColor: "#cccccc" }}>
                                <th className="text-center"><p><b>#</b></p></th>
                                <th><span className="normal"><b>TASK</b> </span></th>
                                <th><span className="normal"><b>TYPE</b></span></th>
                                <th><span className="normal"><b>TAHUN</b></span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pfState.dataMilestone.map(({ task, year, type }, key) => (
                                    <tr key={key}>

                                        <td style={{ padding: "5px 15px" }} className="text-center">
                                            <span className="normal"> {key+1} </span>
                                        </td>
                                        <td style={{ padding: "5px 15px" }}>
                                            <span className="normal">{task}</span>
                                        </td>
                                        <td style={{ padding: "5px 15px" }}>
                                            <span className="normal">{type}</span>
                                        </td>
                                        <td style={{ padding: "5px 15px" }}>
                                            <span className="normal">{year}</span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table> */}
                </div>
            </div>
        </div>
    );
}

export default ProjectMilestone;