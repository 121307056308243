import React, { useRef } from 'react';
import { GridDx } from '../../../../../components';
import useLabMaster from '../useLabMaster';

const column = [
    { fieldName: "nama", caption: "Jenis Komoditi" },
];


function TabJenis(props) {
    const tableRef = useRef();
    const { lmAction, lmState } = useLabMaster();
    return (
        <div>
            <div className="row mb-3">
                <div className="col-md-12 mb-5 text-right">
                    <button className="btn btn-sm btn-primary mx-1"
                        onClick={() => lmAction.showModalFormJK()}
                    >
                        Tambah Data Jenis Komoditi
                    </button>
                </div>
            </div>
            {React.useMemo(() => (
                <GridDx
                    className='custom-style-master'
                    ref={tableRef}
                    data={lmState.dataJK}
                    columns={column}
                    pagination={true}
                    orderingDrag={false}
                    withAction={true}
                    currentPage
                    show
                    actions={[
                        // {
                        //     name: "View",
                        //     icon: "far fa-eye icon-nm icon-nm text-primary",
                        //     action: (v, c) => {

                        //     }
                        // },
                        {
                            name: "Edit",
                            icon: "fa fa-pencil-alt icon-nm text-success",
                            action: (v, c) => {
                                lmAction.showModalFormJK(true, v)
                            }
                        },
                        {
                            name: "Delete",
                            icon: "fa fa-trash-alt icon-nm text-danger",
                            action: (v, c) => {
                                lmAction.deleteJK(v?.id)
                            }
                        },
                    ]}
                ></GridDx>
            ), [lmState.dataJK])}
        </div >
    );
}

export default TabJenis;