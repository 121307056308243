import React from 'react';
import { useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { GridDx } from '../../components';
import useApprovalKerjasama from './useApprovalKerjasama';

const TabReevaluated = (props) => {
    const refTable = useRef();
    const history = useHistory();
    const { appKrjAction, appKrjState } = useApprovalKerjasama();
    return (
        <Card
            className="m-0 p-0 mb-5 shadow"
        >
            <Card.Body className="p-2">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 ">
                        <GridDx
                            className='custom-style-master'
                            masterDetail={false}
                            repaintChangesOnly={true}
                            searchForm={true}
                            ref={refTable}
                            loading={appKrjState.loadingTable}
                            data={(appKrjState.dataTable?.tabel_kerjasama || [])}
                            cellRender={appKrjAction.CustomCellRender}
                            columns={appKrjState.column}
                            withAction={true}
                            pagination={true}
                            orderingDrag={false}
                            currentPage
                            show
                            actions={[
                                {
                                    name: "Edit Data Partnership",
                                    icon: "fa fa-pencil-alt icon-nm text-secondary",
                                    action: (v, c) => history.push(`/admin/partnership-approval-edit/${v?.id}`)
                                },
                                {
                                    name: "Approval",
                                    icon: "fas fa-clipboard-check icon-nm text-secondary",
                                    action: (v, c) => history.push(`/admin/partnership/detail-approval/${v?.id}`)//appKrjAction.setModal({ show: true, data: { ...v } })
                                }
                            ]}
                        >
                        </GridDx>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default TabReevaluated;