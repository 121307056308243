import React, { memo } from 'react';

export default memo(function ({
    formLists = []
}) {
    // console.log(formLists)
    return (
        <>
            <div className="m-portlet m-portlet--unair">
                <div className="m-portlet__body m--padding-15">
                    <div className="row px-5">
                        <div className="col-md-6 ">
                            {
                                formLists
                                    .filter(v => v.rows === 1)
                                    .map((form, key) => {
                                        //if (form.header)
                                        return (
                                            <div key={'1' + key}>
                                                {
                                                    form.header && (
                                                        <>
                                                            <span style={{
                                                                fontWeight: "bold", fontSize: '10pt'
                                                            }}>{form.name}</span>
                                                            <hr />
                                                        </>
                                                    )
                                                }
                                                {
                                                    Array.isArray(form.childs) ? (
                                                        form.childs.map(({ withLabel = true, ...formc }, i) => {
                                                            return (
                                                                <div className="row  m--padding-left-15 m--padding-right-15" key={`1${key}${i}`}>
                                                                    {withLabel && <div className="col-md-4" style={{ fontSize: "8pt" }}>
                                                                        {formc.label}
                                                                    </div>}
                                                                    <div className={`col-md${form.withLabel ? '-8' : ''} `}>
                                                                        {
                                                                            formc.type === "array" ?
                                                                                formc.forms?.map((f, m) =>
                                                                                    <div className="row" key={`${key}${i}${m}`}>
                                                                                        <div className="col-md-12  m--padding-left-15 m--padding-right-15">
                                                                                            {createForm({ ...f, key: `1${key}${i}${m}` })}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                : (
                                                                                    createForm({ ...formc, key: `1${key}${i}z` })
                                                                                )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : null
                                                }
                                            </div>
                                        )
                                    })
                            }

                        </div>



                        <div className="col-md-6">
                            {
                                formLists
                                    .filter(v => v.rows === 2)
                                    .map((form, key) => {
                                        //if (form.header)
                                        return (
                                            <div key={'2' + key}>
                                                {
                                                    form.header && (
                                                        <>
                                                            <span style={{
                                                                fontWeight: "bold", fontSize: '10pt'
                                                            }}>{form.name}</span>
                                                            <hr />
                                                        </>
                                                    )
                                                }
                                                {
                                                    Array.isArray(form.childs) ? (
                                                        form.childs.map(({ withLabel = true, ...formc }, i) => {
                                                            return (
                                                                <React.Fragment key={`2${key}${i}`}>
                                                                    {
                                                                        formc.type === "radio" ? (
                                                                            <div className="row  m--padding-left-15 m--padding-right-15" key={`2${key}${i}`}>
                                                                                <div className="col-md-12 m--padding-left-15 m--padding-right-15">
                                                                                    {createForm(formc)}
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                            (
                                                                                <div className="row  m--padding-left-15 m--padding-right-15" key={`2${key}${i}`}>
                                                                                    {withLabel && <div className="col-md-4" style={{ fontSize: "8pt" }}>
                                                                                        {formc.label}
                                                                                    </div>}
                                                                                    <div className={`col-md${form.withLabel ? '-8' : ''} `}>
                                                                                        {
                                                                                            formc.type === "array" ?
                                                                                                formc.forms?.map((f, m) =>
                                                                                                    <div className="row" key={`2${key}${i}${m}`}>
                                                                                                        <div className="col-md-12  m--padding-left-15 m--padding-right-15">
                                                                                                            {createForm({ ...f, key: `2${key}${i}${m}` })}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                                : (
                                                                                                    createForm({ ...formc, key: `2${key}${i}z` })
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    ) : null
                                                }
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
});

function createForm(form) {
    if (form.type === "text")
        return (
            <div className="form-group" key={form?.key}>
                <input
                    disabled={form.disabled}
                    onChange={(e) => form.onChange ? form.onChange(e.target.value) : null}
                    className="form-control form-control-sm"
                    placeholder={form.placeholder}
                    value={form.value || ''}
                />
            </div>
        )
    if (form.type === "textarea")
        return (
            <div className="form-group" key={form?.key}>
                <textarea
                    disabled={form.disabled}
                    onChange={(e) => form.onChange ? form.onChange(e.target.value) : null}
                    className="form-control form-control-sm"
                    placeholder={form.placeholder}
                    rows={4}
                    value={form.value || ''}
                />
            </div>
        )
    if (form.type === "custom")
        return (
            <div className="col p-0" key={form?.key}>
                {form.component}
            </div>
        )
    if (form.type === "select")
        return (
            <div className="form-group" key={form?.key}>
                <select
                    disabled={form.disabled}
                    onChange={(e) => form.onChange ? form.onChange(e.target.value) : null}
                    className="form-control form-control-sm"
                    placeholder={form.placeholder}
                    value={form.value || ''}
                >
                    {
                        form.options?.map((opt, key) => (
                            <option key={`${opt?.key}${key}`} value={opt.value}>
                                {opt.name}
                            </option>
                        ))
                    }
                </select>
            </div>
        )
    if (form.type === "radio")
        return (
            <div className="form-check m--margin-left-15" key={form?.key}>
                <input
                    className="form-check-input"
                    type="radio"
                    onChange={(e) => form.onChange ? form.onChange(e.target.value) : null}
                    disabled={form.disabled}
                    checked={form.checked || false}
                />
                <label className="form-check-label" style={{ fontSize: "12pt" }}>
                    {form.label}
                </label>
            </div>
        )
    if (form.type === "file")
        return (
            <div className="form-group" key={form?.key}>
                <input
                    type="file"
                    disabled={form.disabled}
                    onChange={(e) => form.onChange ? form.onChange(e.target.value) : null}
                    className="form-control-file py-5"
                    placeholder={form.placeholder}
                    value={form.value || ''}
                />
            </div>
        )
}