import React from 'react';

export default function ({
    onClickLanjut = (e) => {},
    onClickBatal = (e) => {},
}) {
    return (
        <div>
            <img src="/images/stop-project.jpg" className="w-100" alt='stop project'>
            </img>
            <div className="text-right mt-5">
                <button className="btn btn-sm btn-outline-primary mx-1" onClick={onClickLanjut}>
                    Lanjut
                </button>
                <button className="btn btn-sm btn-outline-danger mx-1" onClick={onClickBatal}>
                    Batal
                </button>
            </div>
        </div>
    );
}
