import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Tab, TabContent, HeaderList, HeaderProject } from '../../components';
import TabApproval from './tabApproval';
import TabVersion from './tabVersion';
import { useHistory, useLocation } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';

export default function (props) {
    const [selectedTab, setSelectedTab] = useState("approval")
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const { pathname } = useLocation();
    return (
        <>
            <HeaderList
                title={state.formData?.prohis?.page_title}
                someText={state.formData?.prohis?.page_breadcrumbs}
            ></HeaderList>
            <HeaderProject
                workflow_stage={state.data_view_header?.workflow_state_state}
                rightMenu={false}
                dataHeader={state.data_view_header}
                dataReport={state.data_reports}
            />
            <Card
                className="shadow-sm"
            >
                <Card.Body>
                    <h5>Project Histories</h5>
                    <hr />
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "approval",
                                title: "History Approval",
                                onClick: (e) => setSelectedTab("approval")
                            },
                            {
                                id: "version",
                                title: "History Version",
                                onClick: (e) => setSelectedTab("version")
                            }
                        ]}
                        manualActive={selectedTab}
                    >
                        <TabContent id="approval">
                            <TabApproval />
                        </TabContent>
                        <TabContent id="version">
                            <TabVersion />
                        </TabContent>
                    </Tab>
                </Card.Body>
            </Card>
            {
                pathname.split('/').length > 0 && pathname.split('/')[1] === 'admin' ? (
                    <div className="p-2 bg-white rounded shadow-lg floating-footer-nolayout" >
                        <div className="row">
                            <div className="col-md-6 text-md-left">
                                <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                        <div className="row">
                            <div className="col-md-6 text-md-left">
                                <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}
