import React, { useState, useEffect, useRef } from 'react'
import { GridDx } from '../../components'
import { useGlobalState } from '../../contexts/useGlobalState';
import { status_pegawai } from '../../components/utility/utility';
import moment from 'moment';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";

const columns = [
    { fieldName: "name", caption: "Nama" },
    { fieldName: "status_profil", caption: "Status Pekerja", width: 100 },
    { fieldName: "end_date", caption: "End Date", width: 100 },
    { fieldName: "employee_id", caption: "No Peg" },
    { fieldName: "email", caption: "Email" },
    { fieldName: "gender", caption: "Gender" },
    { fieldName: "usia", caption: "Usia" },
    { fieldName: "position_name", caption: "Posisi" },
    { fieldName: "function_sn", caption: "Fungsi" },
    { fieldName: "section_sn", caption: "Chief" },
    { fieldName: "pendidikan", caption: "Pendidikan" },
    { fieldName: "join_date", caption: "Masuk Pertamina" },
    { fieldName: "pengalaman_kerja", caption: "Total Pengalaman Kerja" },
    { fieldName: "as_leader", caption: "As Leader" },
    { fieldName: "as_member", caption: "As Member" },
]

const TabTable = () => {
    const [state, dispatch, action] = useGlobalState();
    const tableRef = useRef();
    useEffect(() => {
        action.getData("/api/people-hrd/table-2", {
        }, null, null, true).then((res) => {
            if (res.code === '00') {
                action.setForm('people_page', res.data_page);
            }
        })
    }, [])
    const customCellRender = (e) => {
        if (e.column.name === "status_profil") {

            return (
                <div className="text-center">
                    {
                        status_pegawai(e.value)
                    }
                </div>
            )
        } else if (e.column.name === "end_date") {
            return (
                <>
                    {
                        (e.data.status_profil === 'Retired' || e.data.status_profil === 'Contract') ? (
                            <span className="p-1 text-wrap d-blok">{moment(e.value).format('YYYY-MM-DD')}</span>
                        ) : (
                            <span className="p-1 text-wrap d-blok"> - </span>
                        )
                    }
                </>
            )
        } else if (e.column.name === "join_date") {
            return (
                <>
                    {
                        e.value && (
                            <span>{moment(e.value).format("YYYY-MM-DD")}</span>
                        )
                    }
                </>
            )
        } else if (e.column.name === "gender") {
            return (
                <span className='text-wrap'>{e.value === "Laki" ? "Laki - Laki" : e.value}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/svg/open-people.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total People</span>
                                    <span className="d-block"><strong>{state.data_box?.total}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-success" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PWTT</span>
                                    <span className="d-block"><strong>{state.data_box?.active}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Mitra Kerja</span>
                                    <span className="d-block"><strong>{state.data_box?.contract}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-purple" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PWT</span>
                                    <span className="d-block"><strong>{state.data_box?.pwt}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Retired</span>
                                    <span className="d-block"><strong>{state.data_box?.retired}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="border border-light rounded bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Non Active</span>
                                    <span className="d-block"><strong>{state.data_box?.non_active}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {/* <div className="row mt-5 mb-5">
                <div className="col-md-3">
                    <div className="formSearch">
                        <input className="form-control form-control-sm" placeholder="Cari" />
                        <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                    </div>
                </div>
            </div> */}
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row ">
                        <div className="col-md-12">
                            <GridDx
                                ref={tableRef}
                                searchForm={true}
                                columns={columns}
                                data={state.data_table}
                                cellRender={customCellRender}
                                orderingDrag={false}
                                pagination={true}
                                currentPage
                                show
                            >
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>

        </>
    )
}
export default TabTable;