import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";

const crtResContext = createContext({
    crtResState: {},
    crtResAction: {}
});
const iniStateFormData = {
    "id": 0,
    "name_initiator": "",
    "from": "",
    "name_institution": "",
    "faculty": "",
    "department": "",
    "telp": "",
    "email": "",
    "title": "",
    "description": "",
    "purpose": "",
    "tags": "",
    "sector": "",
    "research_category_id": 0,
    "focus": "",
    "stage": "",
    "budget": 0,
    "leader": "",
    "vice": "",
    "member": "",
    "status": "",
    "durasi": "",
    "summary_file_name": "",
    "budget_file_name": "",
    "created_at": "",
    "updated_at": "",
    "staffs": []
}
const iniStateFormStaff = {
    "preideasi_id": 0,
    "nama": "",
    "jabatan": "",
    "telp": "",
    "email": "",
    "is_new": true
}
export function CrtResProvider({ children }) {
    const [isVerified, setIsVerified] = useState({
        key: '',
        val: false
    });
    const [dataArea, setDataArea] = useState([]);
    const [dataCategory, setDataCategory] = useState([]);
    const [formData, setFormData] = useState(iniStateFormData);
    const [formStaff, setFormStaff] = useState(iniStateFormStaff);
    const { doGet, doPost } = useFetch();
    const history = useHistory();

    async function getDataArea() {
        // setLoading(true)
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: 'api/web/param-area',
                token: false
            });
            if (resp?.code === '00') {
                setDataArea([...resp?.data])
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        // setLoading(false);
    }
    async function getDataAreaKategori(id) {
        // setLoading(true)
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: `api/web/param-area-category/${id}`,
                token: false
            });
            if (resp?.code === '00') {
                setDataCategory([...resp?.data])
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        // setLoading(false);
    }
    async function deleteFileUploaded(fileName = '', name = '') {
        try {
            const resp = await doPost({
                url: 'api/web/delete-file-temp',
                data: {
                    fileName
                },
                token: false
            });
            if (resp?.code === '00') {
                handleChange(name, '');
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function doNext() {
        try {
            const tg = formData.tags === '' ? '' : JSON.parse(formData?.tags).map(d => d?.value).join(', ');
            const dataKirim = {
                ...formData,
                tags: tg
            }
            const val = vaidationSubmitIdea(dataKirim)
            if (val.valid === false) {
                return Swal.fire({
                    title: 'Error!',
                    text: val.message,
                    icon: 'error',
                })
            }
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: 'api/web/create-project',
                token: false,
                data: {
                    ...dataKirim
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => (
                    // indAction.toHome()
                    history.push('/landing-page/beranda')
                ))
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    function handleChange(name = '', val) {
        if (name === 'area') {
            if (val !== null && val !== '' && val !== undefined) {
                getDataAreaKategori(val)
            } else {
                setDataCategory([])
            }
            setFormData(e => ({
                ...e,
                [name]: val
            }))
        } else if (name === 'from') {
            if (val === 'Universitas') {
                setFormData(e => ({
                    ...e,
                    [name]: val,
                    department: ''
                }))
            } else {
                setFormData(e => ({
                    ...e,
                    [name]: val,
                    faculty: ''
                }))
            }
        } else {
            setFormData(e => ({
                ...e,
                [name]: val
            }))
        }
    }
    function handleChangeStaff(name = '', val) {
        setFormStaff(e => ({
            ...e,
            [name]: val
        }))
    }
    function addStaff() {
        const validate = validationAddStaff(formStaff)
        if (!validate.valid) {
            return Swal.fire({
                title: 'Error!',
                text: validate.message,
                icon: 'error',
            })
        }
        handleChange('staffs', [...formData.staffs, formStaff]);
        setFormStaff({ ...iniStateFormStaff });
    }
    function deleteStaff(data) {
        setFormData(e => ({
            ...e,
            staffs: formData.staffs.filter(d => d?.nama !== data?.nama && d?.jabatan !== data?.jabatan)
        }))
    }
    function validationAddStaff(data) {
        var message = [];
        var valid = true;
        if (data?.nama === '') {
            message = [...message, 'Nama'];
            valid = false;
        }
        if (data?.jabatan === '') {
            message = [...message, 'Jabatan'];
            valid = false;
        }
        if (data?.telp === '') {
            message = [...message, 'Telp/Hp'];
            valid = false;
        }
        if (data?.email === '') {
            message = [...message, 'Email'];
            valid = false;
        }
        return {
            valid,
            message: message.join(', ') + ' Tidak boleh kosong'
        }
    }
    function vaidationSubmitIdea(data) {
        var message = [];
        var valid = true;
        if (data?.name_initiator === '') {
            message = [...message, "Nama Initiator"];
            valid = false
        }
        if (data?.from === '') {
            message = [...message, "Lembaga/Univ"];
            valid = false
        }
        if (data?.name_institution === '') {
            message = [...message, data?.from === 'Universitas' ? 'Nama Universitas' : 'Nama Lembaga'];
            valid = false
        }
        if (data?.from === 'Universitas' && data?.faculty === '') {
            message = [...message, "Fakultas"];
            valid = false
        }
        if (data?.from === 'Lembaga' && data?.department === '') {
            message = [...message, "Jabatan"];
            valid = false
        }
        if (data?.telp === '') {
            message = [...message, "No. Telp/HP"];
            valid = false
        }
        if (data?.email === '') {
            message = [...message, "Email"];
            valid = false
        }
        if (data?.title === '') {
            message = [...message, "Judul"];
            valid = false
        }
        if (data?.description === '') {
            message = [...message, "Descripsi"];
            valid = false
        }
        if (data?.purpose === '') {
            message = [...message, "Tujuan"];
            valid = false
        }
        if (data?.tags === '') {
            message = [...message, "Tags"];
            valid = false
        }
        if (data?.research_category_id === '' || data?.research_category_id === '0' || data?.research_category_id === 0) {
            message = [...message, "Kategori riset"];
            valid = false
        }
        if (data?.sector === '') {
            message = [...message, "Sector"];
            valid = false
        }
        if (data?.research_category_id === '') {
            message = [...message, "Kategori Riset"];
            valid = false
        }
        if (data?.stage === '') {
            message = [...message, "TRL"];
            valid = false
        }
        if (data?.durasi === '') {
            message = [...message, "Durasi Riset"];
            valid = false
        }
        if (data?.summary_file_name === '') {
            message = [...message, "Sumber File"];
            valid = false
        }
        if (data?.budget === '' || data?.budget === 0 || data?.budget === '0') {
            message = [...message, "Total Biaya"];
            valid = false
        }
        // if (data?.budget_file_name === '') {
        //     message = [...message, "Rincian Budget"];
        //     valid = false
        // }
        if ([...(data?.staffs || [])].length <= 0) {
            message = [...message, "Staff"];
            valid = false
        }
        return {
            valid,
            message: message.join(', ') + " tidak boleh kosong"
        }
    }
    function fileUploaded(res, name = '') {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            handleChange(name, resp.data?.data_filename);
        } else {
            return Swal.fire({
                title: 'Error!',
                text: resp?.message,
                icon: 'error',
            })
        }
    }
    function onSubmit() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Submit Ideasi?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                doNext();
                return true;
            } else {
                return true;
            }
        })
    }
    function captchaChange(val) {
        setIsVerified(val)
    }

    return <crtResContext.Provider
        value={{
            crtResState: {
                isVerified,
                formData,
                formStaff,
                dataArea,
                dataCategory
            },
            crtResAction: {
                setIsVerified,
                handleChange,
                handleChangeStaff,
                addStaff,
                getDataArea,
                fileUploaded,
                deleteFileUploaded,
                deleteStaff,
                onSubmit,
                captchaChange
            }
        }}
    >{children}</crtResContext.Provider>
}
export default function useCreateResearch() {
    return useContext(crtResContext);
}