import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
// import { Link } from "react-router-dom";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
// import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
// import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
// import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
// import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { useGlobalState } from "../../../../app/contexts/useGlobalState";
// import { pathProfile } from "../../../../app/app.config";

export function Topbar() {
  const [state] = useGlobalState();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      headerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}
      {/* <a href="#!" className="brand-logo">
        <img alt="logo" src={layoutProps.headerLogo} style={{width:"70%"}}/>
      </a> */}
      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

      {/* Custom*/}

      {/* {layoutProps.viewQuickActionsDisplay && (
        <div className="topbar-item">
          <a>
            <div
              className={
                "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg"
              }
            >
              <span className="p-2 rounded svg-icon svg-icon-lg svg-icon-primary">
                <SVG src="/media/svg/icons/General/Search.svg" />
              </span>
            </div>
          </a>
        </div>
      )} */}
      {/* {layoutProps.viewQuickActionsDisplay && (
        <div className="topbar-item">
          <a>
            <div
              className={
                "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg"
              }
            >
              <span className="p-2 rounded svg-icon svg-icon-lg svg-icon-primary">
                <SVG src="/media/svg/icons/General/Notifications1.svg" />
              </span>
            </div>
          </a>
        </div>
      )} */}
      {layoutProps.viewQuickActionsDisplay && (
        <div className="topbar-item">
          <a>
            <div
              className={
                "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg"
              }
              id="kt_quick_panel_help_toggle"
            >
              <span className="p-2 rounded svg-icon svg-icon-lg svg-icon-primary">
                <SVG src="/media/svg/icons/Code/Question-circle.svg" />
              </span>
            </div>
          </a>
        </div>
      )}
      {layoutProps.viewQuickActionsDisplay && (
        <div className="topbar-item">
          <a href="/myproject" >
            <div
              className={
                "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              }
            >
              <div className="border-left border-right border-2 px-1">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Pixels.svg")}
                  />
                </span>
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1 ml-1">
                  My Project
                </span>
              </div>
            </div>
          </a>
        </div>
      )}

      {/* {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

      {/* {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )} */}

      {/* Custom*/}
      {layoutProps.viewQuickPanelDisplay && (
        <div
          className="topbar-item"
          data-toggle="tooltip"
          //title="Quick panel"
          data-placement="right"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
            id="kt_quick_panel_user_toggle"
          >
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi,
            </span>{" "}
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {state.dataUser?.name}
            </span>
            <span className="symbol symbol-35 symbol-light-success">
              {/* <span className="symbol-label font-size-h5 font-weight-bold"> */}
              <img src={state.dataUser?.photo_location}
                onError={(e) => { e.target.onerror = null; e.target.src = "/media/svg/icons/General/User.svg" }} />
              {/* </span> */}
            </span>
          </div>
        </div>
      )}

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

      {/* {layoutProps.viewUserDisplay && <QuickUserToggler />} */}
    </div>
  );
}
