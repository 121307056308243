import { DateBox, NumberBox } from 'devextreme-react';
import React, { useEffect } from 'react';
import { useState } from 'react';

const iniStateData = {
    id: 0,
    patent_id: 0,
    jumlah: 0,
    keterangan: "",
    created_at: null
}

function FormPembayaran({ data = {}, onSave = (e) => null, onCancel = () => null }) {
    const [defaultData, setDefaultData] = useState(iniStateData);
    const [validat, setValidate] = useState(true);
    useEffect(() => {
        setDefaultData((e) => ({ ...e, ...data }));
    }, [])

    const handleChange = (name, val) => {
        setDefaultData((e) => ({ ...e, [name]: val }))
    }
    
    const validate = () => {
        if (defaultData.created_at === null || defaultData.created_at === undefined  || defaultData.created_at === "" ){
            setValidate(false);
        }else{
            setValidate(true);
            onSave(defaultData);
        }
    }
    return (
        <>
            <div className="form-group row">
                <label htmlFor="tanggal" className="col-sm-4 col-form-label py-0">Tanggal Pembayaran</label>
                <div className="col-sm-8">
                    <DateBox id="tanggal"
                        displayFormat={'yyyy/MM/dd'}
                        value={defaultData.created_at}
                        onValueChange={(e) => handleChange('created_at', e)}
                    />
                    {
                        !validat && (<small className='text-danger font-weight-normal mt-1'>Tanggal tidak boleh kosong</small>)
                    }
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="jumlah" className="col-sm-4 col-form-label py-0">Jumlah Pembayaran</label>
                <div className="col-sm-8">
                    <NumberBox id="jumlah" placeholder='Jumlah pembayaran' className="form-control form-control-sm p-0"
                        format="#,##0.00"
                        min={0}
                        value={defaultData.jumlah}
                        onValueChange={(e) => handleChange('jumlah', e)}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="keterangan" className="col-sm-4 col-form-label py-0">Keterangan</label>
                <div className="col-sm-8">
                    <textarea rows={3} className='form-control' id='keterangan'
                        value={defaultData.keterangan}
                        onChange={(e) => handleChange('keterangan', e.target.value)}
                    >

                    </textarea>
                </div>
            </div>
            <div className='text-left text-md-right text-lg-right'>
                <button className='btn btn-sm btn-primary mr-2'
                    onClick={validate}
                >Simpan</button>
                <button className='btn btn-sm btn-secondary'
                    onClick={onCancel}
                >Batal</button>
            </div>
        </>
    );
}

export default FormPembayaran;