import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GridRisk, SkeletonLoading } from '../../components/index'
import { useGlobalState } from '../../contexts/useGlobalState';
import ModalViewEdit from './modalViewEdit';

const column = [
    { "multiHeader": false, "dataField": "risk_id", "caption": "ID Risk", width: 100 },
    { "multiHeader": false, "dataField": "tipe_risk", "caption": "Kuantitatif/Kualitatif Risk", width: 100 },
    { "multiHeader": false, "dataField": "kejadian", "caption": "Kejadian Resiko (Risk Event)", width: 150 },
    { "multiHeader": false, "dataField": "penyebab", "caption": "Penyebab Resiko (Risk Agent)", width: 150 },
    {
        "multiHeader": true, "caption": "Inherent", "column": [
            { "dataField": "inher_probabil", "caption": "Probabilitas", width: 100 },
            { "dataField": "inher_dampak", "caption": "Dampak", width: 100 },
            { "dataField": "risk_priority", "caption": "Risk Priority", width: 100 },
        ]
    },
    {
        "multiHeader": true, "caption": "Mitigasi", "column": [
            { "dataField": "mit_rencana", "caption": "Rencana Mitigasi", width: 150 },
            { "dataField": "mit_tahun_berlaku", "caption": "Tahun Berlaku", width: 100 },
            { "dataField": "mit_tahun_selesai", "caption": "Tahun Selesai", width: 100 },
        ]
    },
    {
        "multiHeader": true, "caption": "Residual", "column": [
            { "dataField": "risk_priority_residu", "caption": "Risk Priority Number", width: 100 }
        ]
    }
]
const TabRiskMon = ({ param }) => {
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-risk-2/" + param?.id, {}, null, 'loadingRisk', true).then((resp) => {
                if(resp.code === '00'){
                    action.setForm('mon', resp.data_page);
                }   
            })
        }
    }, [])
    return (
        <>
            {
                state.loadingRisk ? (
                    <SkeletonLoading type="table" rows={5} cols={column.length} />
                ) : (
                    <GridRisk
                        dataSource={state.data_view_risk}
                        collumns={column}
                        editable={false}
                        bodyForm={(disable, onFinish, selData) => <ModalViewEdit disable={true} onFinish={onFinish} data={selData} />}
                    />
                )
            }
        </>
    )
}
export default TabRiskMon;