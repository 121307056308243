import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { HeaderList } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import SVG from "react-inlinesvg";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment';
// import { List } from 'devextreme-react';

function Index(props) {
    const [state, dispatch, action] = useGlobalState();
    const [notif, setNotif] = useState({});
    const [data_page, setData_page] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (state.dataUser?.id) {
            getData();
        }
    }, [state.dataUser?.id]);

    function getData() {
        action.getData(`/api/notification/all?id=${state.dataUser?.id}`).then((resp) => {
            if (resp.code === '00') {
                setNotif(resp.data);
                setData_page(resp.data_page);
            }
        })
    }
    function titleClick(data) {
        if (data?.read_status === 0) {
            action.postData({
                url: `/api/notification/update-read-status?id=${data?.id}`,
                withLoading: false,
                withAlert: false
            }).then((resp) => {
                if (resp.code === '00') {
                    getData();
                }
            })
        }
        if (data?.link) {
            history.push(data?.link)
        }
    }
    function updateAll() {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update all status? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/notification/update-all-notif?id=${state.dataUser?.id}`,
                }).then((resp) => {
                    if (resp.code === '00') {
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    // const Itemrenders = (item) => {
    //     return (
    //         <div className="row mb-3">
    //             <div className="col-2">
    //                 {
    //                     item?.read_status === 0 && (
    //                         <span className="p-2 rounded svg-icon svg-icon-lg svg-icon-primary">
    //                             <SVG src="/media/svg/icons/Communication/Mail.svg" />
    //                         </span>
    //                     )
    //                 }
    //                 {
    //                     item?.read_status === 1 && (
    //                         <span className="p-2 rounded svg-icon svg-icon-lg">
    //                             <SVG src="/media/svg/icons/Communication/Mail-opened.svg" />
    //                         </span>
    //                     )
    //                 }
    //             </div>
    //             <div className="col-10">
    //                 <span className="d-block text-dark-50">{moment(item?.created_at).format('DD MMM YYYY HH:mm:ss')}</span>
    //                 <span className="d-block text-wrap font-weight-bold link-style"
    //                     onClick={() => titleClick(item)}
    //                 >{item?.title}</span>
    //             </div>
    //         </div>
    //     )
    // }
    return (
        <div>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <HeaderList
                        title={data_page?.page_title}
                        someText={data_page?.page_breadcrumbs}
                    />
                    <Card>
                        <Card.Header>
                            <div className='row'>
                                <div className='col-6 text-left'>
                                    <span className='font-weight-bold'>All Notification</span>
                                </div>
                                <div className='col-6 text-right'>
                                    <button className='btn btn-sm btn-primary'
                                        onClick={() => updateAll()}
                                    >
                                        Mark As Read
                                    </button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {/* <List
                                dataSource={[...(notif?.all_notif_unread || []), ...(notif?.all_notif_read || [])]}
                                height={400}
                                allowItemDeleting={false}
                                itemRender={Itemrenders}
                                activeStateEnabled={false}
                            >

                            </List> */}
                            {[...(notif?.all_notif_unread || []), ...(notif?.all_notif_read || [])].map((d, i) => (
                                <div className="row mb-3" key={i}>
                                    <div className="col-2">
                                        {
                                            d?.read_status === 0 && (
                                                <span className="p-2 rounded svg-icon svg-icon-lg svg-icon-primary">
                                                    <SVG src="/media/svg/icons/Communication/Mail.svg" />
                                                </span>
                                            )
                                        }
                                        {
                                            d?.read_status === 1 && (
                                                <span className="p-2 rounded svg-icon svg-icon-lg">
                                                    <SVG src="/media/svg/icons/Communication/Mail-opened.svg" />
                                                </span>
                                            )
                                        }
                                    </div>
                                    <div className="col-10">
                                        <span className="d-block text-dark-50">{moment(d?.created_at).format('DD MMM YYYY HH:mm:ss')}</span>
                                        <span className="d-block text-wrap font-weight-bold link-style"
                                            onClick={() => titleClick(d)}
                                        >{d?.title}</span>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <button className='btn btn-sm btn-secondary'
                                    onClick={() => history.goBack()}
                                >
                                    Kembali
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Index;