import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import useCrudPengujian from '../useCrudPengujian';
import { Modal } from 'react-bootstrap';
import { FileUploadDx } from '../../../../../components';
import Swal from 'sweetalert2';

function ModalSampleProcessing(props) {
    const { cpState, cpAction } = useCrudPengujian();
    const [note, setNotes] = useState("");
    const [file_name, setfile_name] = useState("");
    const refFile = useRef();

    const closeModal = () => {
        cpAction.setModalSp({ show: false, default_data: {} });
    }

    const fileUploaded = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setfile_name(resp.data?.data_filename || "");
            if (refFile.current) {
                refFile.current.instance.reset();
            }
        }
    }
    const finishOnclick = () => {
        if ((file_name === "" || file_name === null || file_name === undefined) || (note === "" || note === null || note === undefined)) {
            return Swal.fire({
                title: 'Error',
                text: "Harap isi keterangan dan upload file COA",
                icon: 'warning',
            })
        }
        cpAction.finishKlik({
            id: cpState.modalSp?.default_data?.id,
            file_name: file_name,
            note: note
        })
    }
    const abortedOnclick = () => {
        if (note === "" || note === null || note === undefined) {
            return Swal.fire({
                title: 'Error',
                text: "Silahkan Isi Keterangan",
                icon: 'warning',
            })
        }
        cpAction.abortKlik({
            id: cpState.modalSp?.default_data?.id,
            note: note
        })
    }
    useEffect(() => {
        if (!cpState.modalSp?.show) {
            setNotes("");
            setfile_name("");
            if (refFile.current) {
                refFile.current.instance.reset();
            }
        }
    }, [cpState.modalSp?.show])

    return (
        <Modal show={cpState.modalSp?.show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Update Status Pengujian
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-sm-4">Tanggal Perubahan Status</label>
                    <div className="col-sm-8">
                        <p>{moment().format("DD-MM-yyyy")}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan / Komentar</label>
                    <div className="col-sm-8">
                        <textarea className='form-control form-control-sm' rows={3}
                            value={note} onChange={e => setNotes(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Upload Dokumen COA</label>
                    <div className="col-sm-8">
                        <FileUploadDx
                            ref={refFile}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            allowedFileExtensions={['.pdf',]}
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 10 Mb"
                            accept="*"
                            onUploaded={fileUploaded}
                            maxFileSize={10000000}
                            uploadMode="instantly"
                        />
                        {file_name && <p>{file_name}</p>}
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => abortedOnclick()} className="btn btn-sm btn-dark mr-1">Aborted</button>
                <button onClick={() => finishOnclick()} className="btn btn-sm btn-success mr-1">Finish (Request Dok. Kontrak)</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalSampleProcessing;