import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2';
import moment from 'moment';
import { formatter, status_equipment } from '../../components/utility/utility';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import useCrudEquipment from '../crudEquipment/useCrudEquipment';
import DropDownButton from "devextreme-react/drop-down-button";

const eqContext = createContext({
    eqAction: {},
    eqState: {}
})

export function EqProvider({ children }) {
    const [globalState, dispatch, globalActon] = useGlobalState();
    const [data_page, setData_page] = useState({});
    const [data_paged, setData_paged] = useState({});
    const { ceqAction, ceqState } = useCrudEquipment();
    const { pathname } = useLocation();
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    const { menu } = useParams();
    const history = useHistory();
    const [loadingTable, setLoadingTable] = useState(false);
    const [dataTable, setDataTable] = useState({});
    const [loadingTableR, setLoadingTableR] = useState(false);
    const [dataTableR, setDataTableR] = useState({});
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [dataDashboard, setDataDashboard] = useState({});
    const [dataHeader, setDataHeader] = useState({});
    const [dataDetail, setDataDetail] = useState({});

    async function getTable(year = moment(new Date()).format('YYYY')) {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData("/api/ext-equipment/table-2", { year }, null, null, true)
            if (resp.code === "00") {
                setDataTable(resp.data)
                setData_page(resp.data_page);
                getHeader(year);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    async function getTableAdmin(year = moment(new Date()).format('YYYY')) {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData("/api/ext-equipment/table", { year }, null, null, true)
            if (resp.code === "00") {
                setDataTable(resp.data)
                setData_page(resp.data_page);
                getHeader(year);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    async function getTableReport() {
        setLoadingTableR(true)

        try {

            const resp = await globalActon.getData("/api/ext-equipment/table-report", {}, null, null, true)
            if (resp.code === "00") {
                setDataTableR(resp.data)
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTableR(false)
    }
    async function getDashboard() {
        setLoadingDashboard(true)

        try {

            const resp = await globalActon.getData("/api/ext-equipment/dashboard", {}, null, null, true)
            if (resp.code === "00") {
                getHeader();
                const chart5 = [...(resp.data?.chart5 || [])].map(d => {
                    return {
                        ...d,
                        total: (d?.gol1 + d?.gol2 + d?.gol3 + d?.gol4 + d?.gol5 + d?.gol6)
                    }
                });
                // const chart6 = [...(resp.data?.chart6 || [])].map(d => {
                //     return {
                //         ...d,
                //         total: (d?.LAB + d?.PCD + d?.URTI + d?.DRTI)
                //     }
                // });

                const chart7 = [...(resp.data?.chart7 || [])].map(d => {
                    return {
                        ...d,
                        total: (d?.j1 + d?.j2 + d?.j3 + d?.j4 + d?.j5 + d?.j6 + d?.j7 + d?.j8)
                    }
                });

                setDataDashboard({
                    ...resp.data,
                    chart5,
                    //chart6,
                    chart7
                })
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingDashboard(false)
    }
    async function getHeader(tahun = "") {
        try {
            const resp = await globalActon.getData(`/api/ext-equipment/header-data?year=${tahun}`);
            if (resp.code === "00") {
                setDataHeader(resp.data)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getDetail(id = '0') {
        try {
            const resp = await globalActon.getData(`/api/ext-equipment/view-2/${id}`, {}, null, null, true);
            if (resp.code === "00") {
                setDataDetail(resp.data);
                setData_paged(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getDetailAdmin(id = '0') {
        try {
            const resp = await globalActon.getData(`/api/ext-equipment/view/${id}`, {}, null, null, true);
            if (resp.code === "00") {
                setDataDetail(resp.data);
                setData_paged(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    function cellRenderMaster(rowInfo) {
        if (rowInfo.column.name === 'action_edit') {
            return (
                <>
                    <DropDownButton
                        text=" "
                        className="bg-primary rounded customColorIcon customColotText"
                        icon="preferences"
                        dropDownOptions={{ width: 250 }}
                        itemRender={(e) => {
                            return (
                                <>
                                    {
                                        e.name === "Edit" && (
                                            <span className="d-block text-success" onClick={() => ceqAction.editEquipmentClick(rowInfo.data?.id)}><i className="fa fa-pencil-alt text-success icon-nm m-0 p-0"></i> Edit</span>
                                        )
                                    }
                                    {
                                        e.name === "View" && (
                                            <Link to={`/equipment-detail/${rowInfo.data?.id}`} target="_blank">
                                                <span className="d-block text-primary"><i className="fa fa-eye text-primary icon-nm m-0 p-0"></i> View Detail</span>
                                            </Link>
                                        )
                                    }
                                </>
                            )
                        }}
                        displayExpr="name"
                        keyExpr="id"
                        items={rowInfo.data?.is_creator === 1 ? [
                            {
                                name: "View",
                                icon: "fa fa-eye icon-nm text-primary",
                            },
                            {
                                name: "Edit",
                                icon: "fa fa-pencil-alt icon-nm text-secondary",
                                //action: (v, c) => ceqAction.editEquipmentClick(v?.id)
                            }] : [
                            {
                                name: "View",
                                icon: "fa fa-eye icon-nm text-primary",
                            }]}
                    />
                    {/* {
                        rowInfo.data?.is_creator === 1 ? (
                            <button className="btn btn-sm btn-outline-success p-1" onClick={() => ceqAction.editEquipmentClick(rowInfo.data?.id)}><i className="fa fa-pencil-alt icon-nm m-0 p-0"></i> Edit</button>
                        ) : (
                            <Link to={`/equipment-detail/${rowInfo.data?.id}`} target="_blank">
                                <button className="btn btn-sm btn-outline-primary p-1">View Detail</button>
                            </Link>
                        )
                    } */}
                </>

            )
        }
        if (rowInfo.column.name === "action_custom") {
            return (
                <>
                    {
                        (validationRole() || rowInfo.data?.is_penanggung_jawab === 1) && (
                            <div className='text-center'>
                                <button className='btn btn-sm btn-light-danger btn-icon'
                                    onClick={() => ceqAction.deleteEquipment(rowInfo.data?.id)}
                                ><i className='fa fa-trash-alt icon-nm'></i></button>
                            </div>
                        )
                    }
                </>
            )
        }
        if (rowInfo.column.name === 'nama') {
            return (
                <>
                    {
                        pathname === '/admin/equipment-crud/dashboard' && (
                            <Link to={`/admin/equipment-detail/${rowInfo.data?.id}`} target="_blank">
                                <span className="d-block text-wrap">{rowInfo.value}</span>
                            </Link>
                        )
                    }
                    {
                        pathname === '/admin/equipment-reporting' && (
                            <Link to={`/admin/equipment-detail/${rowInfo.data?.id}`} target="_blank">
                                <span className="d-block text-wrap">{rowInfo.value}</span>
                            </Link>
                        )
                    }
                    {
                        pathname === '/equipment-mng/table' && (
                            <Link to={`/equipment-detail/${rowInfo.data?.id}`} target="_blank">
                                <span className="d-block text-wrap">{rowInfo.value}</span>
                            </Link>
                        )
                    }
                </>

            )
        } else if (rowInfo.column.name === 'status_name_01' ||
            rowInfo.column.name === 'status_name_02' ||
            rowInfo.column.name === 'status_name_03' ||
            rowInfo.column.name === 'status_name_04' ||
            rowInfo.column.name === 'status_name_05' ||
            rowInfo.column.name === 'status_name_06' ||
            rowInfo.column.name === 'status_name_07' ||
            rowInfo.column.name === 'status_name_08' ||
            rowInfo.column.name === 'status_name_09' ||
            rowInfo.column.name === 'status_name_10' ||
            rowInfo.column.name === 'status_name_11' ||
            rowInfo.column.name === 'status_name_12' ||
            rowInfo.column.name === 'nama_status'
        ) {
            return (
                <>
                    {
                        status_equipment(rowInfo.value)
                    }
                </>
            )
        } else if (rowInfo.column.name === "status_equipment") {
            var color_text = rowInfo.value === "Expired" ? "text-danger" :
                rowInfo.value === "Warning" ? "text-warning" :
                    rowInfo.value === "Running" ? "text-success" : "text-dark"
            return (
                <span className={`d-block ${color_text}`}>{rowInfo.value}</span>
            )
        }
        else if (rowInfo.column.name === "tanggal_selesai") {
            var dt = moment().add(1, 'months');
            return (
                <>
                    {
                        (rowInfo.data?.status_equipment === "Expired") ? (
                            <span className='d-block text-danger'>{rowInfo.value} <i className='fas fa-exclamation-circle icon-nm ml-2 text-danger'></i></span>
                        ) : (
                            <span className='d-block text-dark'>{rowInfo.value}</span>
                        )
                    }
                </>
            )
        } else if (rowInfo.column.name === 'iso_17025') {
            return (
                <span className="d-block py-1 px-2">{rowInfo.value === true ? "Ya" : rowInfo.value === false ? "Tidak" : ""}</span>
            )
        } else if (rowInfo.column.name === 'pc') {
            return (
                <span className="d-block py-1 px-2">{rowInfo.value === true ? "Ada" : rowInfo.value === false ? "Tidak Ada" : ""}</span>
            )
        } else if (rowInfo.column.name === 'usia') {
            return (
                <span className="d-block py-1 px-2">{rowInfo.value} Tahun</span>
            )
        } else {
            return (
                <span className="d-block py-1 px-2 text-wrap">{rowInfo.value}</span>
            )
        }
    }
    function cellRenderLoogbook(rowInfo) {
        if (rowInfo.column.name === 'status') {
            return (
                <span className="d-block text-wrap">{dataDetail?.dataEquipment?.nama_status}</span>
            )
        } else {
            return (
                <span className="d-block text-wrap">{rowInfo.value}</span>
            )
        }
    }
    function backButton() {
        history.goBack();
    }
    function tabChange(menu) {
        history.push(`/equipment-mng/${menu}`)
    }
    function validationRole() {
        const data_role = globalState.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Admin Equipment" ||
                d === "Administrator") {
                result = true;
            }
        })
        return result;
    }
    function validationRoleSuperAdmin() {
        const data_role = globalState.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Administrator") {
                result = true;
            }
        })
        return result;
    }
    const customCellRenderHistory = (rowInfo) => {
        if (rowInfo.column.name === 'keterangan') {
            return (
                <div>
                    {/* {
                        (rowInfo.data?.equipment_status === 2 || rowInfo.data?.equipment_status === 4) ? (
                            <>
                                <div className="form-group row mb-1">
                                    <div htmlFor="biaya" className="col-sm-3 font-weight-bold">Biaya (USD)</div>
                                    <div className="col-sm-8">
                                        <span className='text-wrap'>: {formatter.format(rowInfo.data?.biaya || 0)}</span>
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <div htmlFor="biaya" className="col-sm-3 font-weight-bold">{rowInfo.data?.equipment_status === 2 ? "Perkiraan Kerusakan" : "Keterangan"}</div>
                                    <div className="col-sm-8">
                                        <span className='text-wrap'>: {rowInfo.data?.deskripsi}</span>
                                    </div>
                                </div>
                                {
                                    rowInfo.data?.equipment_status === 2 && (
                                        <>
                                            <div className="form-group row mb-1">
                                                <div htmlFor="biaya" className="col-sm-3 font-weight-bold">Kronologi Kejadian</div>
                                                <div className="col-sm-8">
                                                    <span className='text-wrap'>: {rowInfo.data?.kronologi_kejadian}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row mb-1">
                                                <div htmlFor="biaya" className="col-sm-3 font-weight-bold">Analisa Kerusakan</div>
                                                <div className="col-sm-8">
                                                    <span className='text-wrap'>: {rowInfo.data?.analisa_kerusakan}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row mb-1">
                                                <div htmlFor="biaya" className="col-sm-3 font-weight-bold">Tindakan Perbaikan</div>
                                                <div className="col-sm-8">
                                                    <span className='text-wrap'>: {rowInfo.data?.tindakan_perbaikan}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row mb-1">
                                                <div htmlFor="biaya" className="col-sm-3 font-weight-bold">Status Perbaikan</div>
                                                <div className="col-sm-8">
                                                    <span className='text-wrap'>: {rowInfo.data?.status_maintenance_str}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row mb-1">
                                                <div htmlFor="biaya" className="col-sm-3 font-weight-bold">Tanggal Selesai Perbaikan</div>
                                                <div className="col-sm-8">
                                                    <span className='text-wrap'>: {rowInfo.data?.show_tanggal_selesai_maintenance}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        ) : ( */}
                    <span className="d-block text-wrap">{rowInfo.data?.deskripsi}</span>
                    {/* )
                    } */}
                </div>
            )
        } else {
            return (
                <span className="d-block text-wrap">{rowInfo.value}</span>
            )
        }
    }
    // useEffect(() => {
    //     // getTable();
    //     // getDashboard();
    //     getHeader();
    // }, [])

    return <eqContext.Provider value={{
        eqAction: {
            getTable,
            getTableAdmin,
            getTableReport,
            getDetail,
            getDetailAdmin,
            cellRenderMaster,
            cellRenderLoogbook,
            backButton,
            getDashboard,
            tabChange,
            validationRole,
            validationRoleSuperAdmin,
            customCellRenderHistory
        },
        eqState: {
            loadingTable,
            dataTable,
            loadingTableR,
            dataTableR,
            loadingDashboard,
            dataDashboard,
            dataHeader,
            dataDetail,
            menu,
            path,
            data_page,
            data_paged
        }
    }}>{children}</eqContext.Provider>
}

export default function useEquipment() {
    return useContext(eqContext)
}