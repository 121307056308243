import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';

function ModalStopProjectReject({ stopProject = '', rejectOnclick = (val) => { } }) {
    const [state, dispatch, action] = useGlobalState();
    const [comment, setComment] = useState('');

    useEffect(() => {
        if (state.modal?.modalStopReject) {
            resetEditor()
        }
    }, [state.modal?.modalStopReject])

    function resetEditor() {
        setComment('');
    }
    function rejectClick(){
        if((comment || '') === ''){
            return Swal.fire({
                title: 'Error',
                text: "Reason tidak boleh kosong",
                icon: 'error',
            })
        }
        action.toggleModal('modalStopReject');
        rejectOnclick(comment)
    }
    return (
        <div>
            <h5>Reject {stopProject}</h5>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="text-wrap" style={{ width: '150px' }}>Reason</span>
                        </div>
                        <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="form-control" placeholder="Reason" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
            </div>
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={() => action.toggleModal('modalStopReject')}
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-danger mx-1"
                    onClick={() => rejectClick()}
                >
                    Reject {stopProject}
                </button>
            </div>
        </div>
    );
}

export default ModalStopProjectReject;