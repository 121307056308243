import React, { createContext, useRef, useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import useFetch from '../../modules/useFetch';
import ModalAjukanPermohonan from './laboratory/modalAjukanPermohonan';

const indContext = createContext({
    indState: {},
    indAction: {}
})
const iniStatePage = {
    title: "Landingpage",
    type: "HomeLandPage",
    dataPage: {
    }
};
export function IndProvider({ children }) {
    const [scrollElement, setScrollElement] = useState('beranda');
    const [displayLayout, setDisplayLayout] = useState(true);
    const [pageState, setPageState] = useState(iniStatePage);
    const [dataType, setDataType] = useState([]);
    const history = useHistory();
    const [modalFormReg, setModalFormReg] = useState({
        show: false,
        default_data: {}
    });
    const { doGet, doPost } = useFetch();

    const openModalReg = () => setModalFormReg({ show: true, default_data: {} });
    const closeModalReg = () => setModalFormReg({ show: false, default_data: {} });

    const kirimPendaftaran = (formData = {}) => {
        const data = {
            "institution_sub_id": formData?.institution_sub_id || "",
            "nama": formData?.nama || "",
            "email": formData?.email || "",
            "hp": formData?.hp || "",
            "fungsi": formData?.fungsi || "",
            "alamat": formData?.alamat || ""
        };

        // if (data.institution_sub_id === "" || data.nama === "" || data.email === "" || data.hp === "") {
        //     return Swal.fire({
        //         title: 'Error',
        //         text: "Nama, Email, Direktorat dan No. Hp Tidak boleh kosong!",
        //         icon: 'warning',
        //     })
        // }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Kirim pendataran ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                addCustomRegistration(data);
            }
        })
    }
    const [dataInstit, setDataInstitut] = useState([]);
    const [dataSubInstit, setDataSubInstitut] = useState([]);
    async function getDataInstitution() {
        try {
            const resp = await doGet({
                url: 'api/ext-lab/institution',
                token: false
            });
            if (resp?.code === '00') {
                setDataInstitut(resp.data || []);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getDataSubInstitution(id) {
        try {
            const resp = await doGet({
                url: 'api/ext-lab/institution-sub',
                param: {
                    id
                },
                token: false
            });
            if (resp?.code === '00') {
                setDataSubInstitut(resp.data || []);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function addCustomRegistration(formData = {}) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-lab/add-customer-registration`,
                token: false,
                data: {
                    ...formData
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    closeModalReg();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    function berandaScroll() {
        setScrollElement('beranda');
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        });
    }
    const toAllProduct = useCallback((id = 0) => {
        setPageState({
            title: "All Product",
            type: "ProductLandPage",
            dataPage: {
                id
            }
        })
    }, [pageState]);

    const toProductDetail = useCallback((data = {}) => {
        // setPageState({
        //     title: "Product Detail",
        //     type: "ProductLandPageDetail",
        //     dataPage: {
        //         ...data
        //     }
        // })
        history.push({ pathname: '/landing-page/product/detail', state: { ...data } });
    }, [pageState]);

    const toLaboratoryDetail = useCallback((data = {}) => {
        history.push({ pathname: '/landing-page/laboratory/detail', state: { ...data } });
    }, [pageState]);

    const toAllAchievement = useCallback(() => {
        setPageState({
            title: "All Achievement",
            type: "AchievementLandPage",
            dataPage: {
            }
        })
    }, [pageState]);

    const toAllEvent = useCallback(() => {
        setPageState({
            title: "All Event",
            type: "EventLandPage",
            dataPage: {
            }
        })
    }, [pageState]);

    const toAllEventDetail = useCallback((data = {}) => {
        // setPageState({
        //     title: "Event Detail",
        //     type: "EventLandPageDetail",
        //     dataPage: {
        //         ...data
        //     }
        // })
        history.push({ pathname: '/landing-page/event/detail', state: { ...data } });
    }, [pageState])

    const toHome = useCallback(() => {
        setPageState({ ...iniStatePage });
    }, [pageState]);

    const toCreateResearch = useCallback(() => {
        setPageState({
            title: "Create Research",
            type: "CreateResearch",
            dataPage: {
            }
        })
    }, [pageState]);

    return <indContext.Provider
        value={{
            indState: {
                scrollElement,
                displayLayout,
                pageState,
                dataType,
                modalFormReg,
                dataInstit,
                dataSubInstit
                //dataLand,
                //loading
            },
            indAction: {
                setScrollElement,
                berandaScroll,
                setDisplayLayout,
                toAllProduct,
                toAllAchievement,
                toAllEvent,
                toProductDetail,
                toAllEventDetail,
                toLaboratoryDetail,
                toHome,
                toCreateResearch,
                setDataType,
                setPageState,
                kirimPendaftaran,
                closeModalReg,
                openModalReg,
                getDataInstitution,
                getDataSubInstitution,
                setDataSubInstitut
            }
        }}
    >
        <ModalAjukanPermohonan />
        {children}
    </indContext.Provider>
}

export default function useIndexLandPage() {
    return useContext(indContext)
}