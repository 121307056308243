import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import useLaboratory from './useLaboratory';
import { toString } from 'lodash';
import useIndexLandPage from '../use_index';
import Swal from 'sweetalert2';

const inistateFormData = {
    "institution": "",
    "institution_sub_id": "",
    "nama": "",
    "email": "",
    "hp": "",
    "fungsi": "",
    "alamat":""
}


function ModalAjukanPermohonan(props) {
    const {
        indState: {
            modalFormReg: {
                show = false,
                default_data = {},
            },
            dataInstit = [],
            dataSubInstit = []
        },
        indAction: {
            closeModalReg = () => null,
            kirimPendaftaran = () => null,
            getDataInstitution = () => null,
            getDataSubInstitution = () => null,
            setDataSubInstitut = null
        }
    } = useIndexLandPage();
    useEffect(() => {
        if (show) {
            getDataInstitution();
            setDefaultData();
        } else {
            setFormData({ ...inistateFormData });
        }
    }, [show]);

    const [formData, setFormData] = useState(inistateFormData);
    const setDefaultData = () => {
        setFormData((prev) => ({ ...prev, ...default_data }));
    }
    const handlChangeFrom = (name, val) => {
        if (name === "institution") {
            if (val !== "") {
                getDataSubInstitution(val);
            } else {
                setDataSubInstitut([]);
            }
            setFormData((prev) => ({ ...prev, [name]: val, institution_sub_id: "" }))
        }
        else {
            setFormData((prev) => ({ ...prev, [name]: val }))
        }
    }

    function validation() {
        if (formData.nama === "" || formData.email === "" || formData.institution_sub_id === "" || formData.fungsi === "" || formData.hp === "") {
            return Swal.fire({
                title: 'Error',
                text: "Nama, Email, Direktorat / Entitas, Fungsi tidak boleh kosong",
                icon: 'warning',
            })
        }
        kirimPendaftaran(formData);
    }
    return (
        <Modal show={show} size='md' onHide={closeModalReg}>
            <div className="card">
                <div className="card-header bg-white">
                    Customer Registration
                </div>
                <div className="card-body">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Nama</label>
                            <input type="text" className="form-control form-control-sm" id="name" placeholder='Nama'
                                value={formData.nama}
                                onChange={e => handlChangeFrom("nama", e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="text" className="form-control form-control-sm" id="email" placeholder='Email'
                                value={formData.email}
                                onChange={e => handlChangeFrom("email", e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sub" className="form-label">Holding / Subholding</label>
                            <select className='form-control form-control-sm' id='sub'
                                value={formData.institution}
                                onChange={e => handlChangeFrom("institution", e.target.value)}
                            >
                                {[{ id: "", nama: "-Pilih-" }, ...(dataInstit || [])].map((d, i) => (
                                    <option key={i} value={toString(d?.id)}>{d?.nama}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="dir" className="form-label">Direktorat / Entitas</label>
                            <select className='form-control form-control-sm' id='dir'
                                value={formData.institution_sub_id}
                                onChange={e => handlChangeFrom("institution_sub_id", e.target.value)}
                            >
                                {[{ id: "", institution_id: "", nama: "-Pilih-" }, ...(dataSubInstit || [])].map((d, i) => (
                                    <option key={i} value={toString(d?.id)}>{d?.nama}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="fung" className="form-label">Fungsi</label>
                            <input type="text" className="form-control form-control-sm" id="fung" placeholder='Fungsi'
                                value={formData.fungsi}
                                onChange={e => handlChangeFrom("fungsi", e.target.value)}
                            /> 
                        </div>

                        <div className="mb-3">
                            <label htmlFor="alamat" className="form-label">Alamat</label>
                            <textarea rows={3} className="form-control form-control-sm" id="alamat" placeholder='Alamat'
                                value={formData.alamat}
                                onChange={e => handlChangeFrom("alamat", e.target.value)}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="no" className="form-label">No. HP (WA)</label>
                            <input type="number" className="form-control form-control-sm" id="hp" placeholder='08123456789'
                                value={formData.hp}
                                onChange={e => handlChangeFrom("hp", e.target.value)}
                            />
                        </div>
                    </form>
                </div>
                <div className='card-footer'>
                    <button onClick={closeModalReg} className='btn btn-sm btn-secondary mr-3'>Batal</button>
                    <button onClick={e => validation()} className='btn btn-sm btn-primary'>Kirim Pendaftaran</button>
                </div>
            </div>
        </Modal >
    );
}

export default ModalAjukanPermohonan;