import { NumberBox } from 'devextreme-react';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import useSettingParameter from '../useSettingParameter';

function TabSession(props) {
    const { setParAction, setParState } = useSettingParameter();
    useEffect(() => {
        setParAction.getDataTimeout();
        setParAction.setIsEditingST(false);
    }, [])
    return (
        <div>
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Header>
                    <span className="d-block" style={{ fontWeight: 'bold' }}>Session Timeout</span>
                    <span className="d-block" style={{ color: '#C6C6C6' }}>Setting Session Timeout</span>
                </Card.Header>
                <Card.Body className="p-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="row my-5">
                                <div className="col-md-3">
                                    Batasan Menit
                                </div>
                                <div className="col-md">
                                    {
                                        setParState.isEditingST === false ? (
                                            <div className="p-2 bg-light-primary text-white text-center rounded text-dark" style={{ width: '50px' }}>
                                                {setParState.time_out}
                                            </div>
                                        ) : (
                                            <NumberBox
                                                value={setParState.time_out}
                                                min={1}
                                                placeholder="Timeout (Menit)"
                                                onValueChanged={(e) => setParAction.setTime_out(e.value)}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    Pratinjau
                                </div>
                                <div className="col-md">
                                    <span className="d-block p-3 text-center bg-light-primary text-dark rounded">
                                        <i className="fas fa-stopwatch icon-sm text-primary mx-3"></i>
                                        Session Timeout {setParState.time_out} Menit
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div className="d-block text-right">
                                {
                                    setParState.isEditingST === false ? (
                                        <button className="btn btn-sm btn-primary m-1"
                                            onClick={() => setParAction.setIsEditingST(true)}
                                        >
                                            <i className="fa fa-pencil-alt text-white icon-sm"></i>
                                            Ubah
                                        </button>
                                    ) : (
                                        <>
                                            <button className="btn btn-sm btn-secondary m-1"
                                                onClick={() => setParAction.cancelTimeout()}
                                            >
                                                <i className="fa fa-undo icon-sm"></i>
                                                Kembali
                                            </button>
                                            <button className="btn btn-sm btn-primary m-1"
                                                onClick={() => setParAction.saveTimeOut()}
                                            >
                                                <i className="fa fa-save text-white icon-sm"></i>
                                                Save
                                            </button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TabSession;