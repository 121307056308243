import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { HeaderList, ChartDx, PaginationCs } from '../../components';
import Skeleton from 'react-loading-skeleton';
import useKnowledgeMng, { KnoProvider } from './useKnowledgeMng';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toString } from 'lodash';
import { Link, useHistory } from 'react-router-dom';

function Library(props) {
    const { knoAction, knoState } = useKnowledgeMng();
    const history = useHistory();

    useEffect(() => {
        knoAction.getDataLibrary(knoState.larea, knoState.btnLibrary, knoState.lown, knoState.lkeyw);
    }, [knoState.larea, knoState.btnLibrary, knoState.lown])

    return (
        <>
            <HeaderList
                title={knoState.data_pagel?.page_title}
                someText={knoState.data_pagel?.page_breadcrumbs}
            />
            <Card
                style={{ backgroundColor: '#C4E0FF' }}
                className="p-0 customShadow header_library_display mb-5"
            >

                <Card.Body className="p-5">
                    <div className="row mb-5">
                        <div className="col-md-4 mb-5">
                            <div className="rounded bg-warning p-2 card_1">
                                <div className="d-flex justify-content-between">
                                    <span className="font-bold">Total Library</span>
                                    <span className="font-bold">{[...(knoState.dataLibrary?.grafik_book_area || [])].reduce((tot, cur) => tot = tot + cur?.jumlah_int, 0)}</span>
                                </div>
                                <div className="mt-5">
                                    <ChartDx
                                        dataSource={knoState.dataLibrary?.grafik_book_area}
                                        argumentField="name"
                                        height={120}
                                        colorLabel='#181C32'
                                        onrenderLabel={(e) => e.value?.substring(0, 4)}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                        withTitle={false}
                                        toolsExport={false}
                                        showLegend={false}
                                        yVisible={false}
                                        xVisible={false}
                                        yLabel={false}
                                        overlappingBehavior='rotate'
                                        rotationAngle={45}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah_int",
                                                color: '#FFFF'
                                            }
                                        ]}
                                    >

                                    </ChartDx>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className="rounded bg-white p-2 card">
                                <div className="card_2"></div>
                                <div className="d-flex justify-content-between">
                                    <span className="d-block font-bold">Kategori</span>
                                    <span className="font-bold">{[...(knoState.dataLibrary?.grafik_book_cat || [])].reduce((tot, cur) => tot = tot + cur?.jumlah_int, 0)}</span>
                                </div>
                                <div className="mt-5" style={{ zIndex: 0 }}>
                                    <ChartDx
                                        dataSource={knoState.dataLibrary?.grafik_book_cat}
                                        argumentField="name"
                                        onrenderLabel={(e) => e.value?.substring(0, 4)}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                        overlappingBehavior='rotate'
                                        rotationAngle={45}
                                        height={120}
                                        withTitle={false}
                                        toolsExport={false}
                                        showLegend={false}
                                        yVisible={false}
                                        xVisible={false}
                                        yLabel={false}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah_int",
                                            }
                                        ]}
                                    >

                                    </ChartDx>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className="rounded bg-primary p-2 card_1">
                                <div className="d-flex justify-content-between">
                                    <span className="d-block font-bold">Total Download</span>
                                    <span className="font-bold">{[...(knoState.dataLibrary?.grafik_book_download || [])].reduce((tot, cur) => tot = tot + cur?.jumlah_int, 0)}</span>
                                </div>
                                <div className="mt-5">
                                    <ChartDx
                                        dataSource={knoState.dataLibrary?.grafik_book_download}
                                        onrenderLabel={(e) => e.value?.substring(0, 4)}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                        overlappingBehavior='rotate'
                                        rotationAngle={45}
                                        height={120}
                                        withTitle={false}
                                        toolsExport={false}
                                        showLegend={false}
                                        yVisible={false}
                                        xVisible={false}
                                        yLabel={false}
                                        colorLabel='#181C32'
                                        argumentField="name"
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah_int",
                                                color: '#FFFF'
                                            }
                                        ]}
                                    >

                                    </ChartDx>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="d-flex">
                                <select className="form-control form-control-sm mx-1 w-25"
                                    onChange={(e) => knoAction.setLarea(e.target.value)}
                                    value={knoState.larea}
                                >
                                    <option value="0" selected>- Semua Area -</option>
                                    {
                                        [...(knoState.dataLibrary?.dataAreas || [])].map((d, i) => (
                                            <option key={i} value={d?.param_id}>{d?.title}</option>
                                        ))
                                    }
                                </select>
                                <select className="form-control form-control-sm mx-1 w-25"
                                    onChange={(e) => knoAction.setLown(e.target.value)}
                                    value={knoState.lown}
                                >
                                    <option value="0" selected>- Pilih Owner -</option>
                                    {
                                        [...(knoState.dataLibrary?.dataOwner || [])].map((d, i) => (
                                            <option key={i} value={d?.id}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                                <div className="w-50 d-flex">
                                    <input className="form-control form-control-sm" placeholder="Cari Research"
                                        // value={knoState.lkeyw} onChange={(e) => knoAction.setLkeyw(e.target.value)}
                                        onKeyUp={(e) => knoAction.onEnterLibrary(e)}
                                    ></input>
                                    <i className="fa fa-search icon-nm" style={{ position: 'absolute', right: '20px', top: '35%' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-auto'>
                            <label className="my-0 mx-3">Kategori :</label>
                        </div>
                        <div className="col">
                            <button className={'btn btn-sm mx-1 mb-2 ' + (knoState.btnLibrary === '0' ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnLibrary('0')}>Semua Kategori</button>
                            {
                                [...(knoState.dataLibrary?.dataCategories || [])].map((d, i) => (
                                    <button key={i} className={'btn btn-sm mx-1 mb-2 ' + (knoState.btnLibrary === toString(d?.id) ? "btn-primary" : "btn-light")} onClick={() => knoAction.setBtnLibrary(toString(d?.id))}>{d?.name}</button>
                                ))
                            }
                        </div>
                    </div>

                </Card.Body>
            </Card>
            <div className="row">
                <div className="col-md-12">
                    {
                        knoState.loadingLibrary ?
                            <div className="card-columns px-1 my-1">
                                {
                                    [1, 2, 3].map(d => (
                                        <div>
                                            <Card
                                                className="p-0 customShadow"
                                            >
                                                <Card.Body className="p-5">
                                                    <div>
                                                        <div className="card card-custom overlay my-5">
                                                            <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                                <Skeleton height={150} />
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <h5><Skeleton /></h5>
                                                        </p>
                                                        <p><Skeleton /></p>
                                                        <div>
                                                            <Skeleton />
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <p>
                                                            <Skeleton />
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))
                                }
                            </div>
                            : (
                                <PaginationCs
                                    data={[...(knoState.dataLibrary?.data_book || [])]}
                                    dataPerPage={10}
                                    RenderContainer={({ children }) => (
                                        <div className="card-columns px-1 my-1">
                                            {children}
                                        </div>
                                    )}
                                    renderComponent={(d, i) => (
                                        <Card
                                            key={i}
                                            className="p-0 customShadow mb-5"
                                        >
                                            <Card.Body className="p-5">
                                                <div>
                                                    <div className="card card-custom overlay mt-0 mb-1">
                                                        <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                            <div className="overlay-wrapper">
                                                                <LazyLoadImage
                                                                    height={200}
                                                                    width={'100%'}
                                                                    effect='blur'
                                                                    res
                                                                    className="border image_card"
                                                                    onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                                                    src={d.photo_product}
                                                                />
                                                            </div>
                                                            <div className="overlay-layer">
                                                                {
                                                                    d?.file_location && (
                                                                        <a target="_blank" href={d?.file_location} download>
                                                                            <button className="btn btn-sm btn-primary mx-1">
                                                                                <i className="fas fa-file-download icon-nm"></i>
                                                                                Download
                                                                            </button>
                                                                        </a>
                                                                    )
                                                                }
                                                                <Link to={`/library-detail/${d?.id}`} target="_blank">
                                                                    <button className="btn btn-sm btn-light mx-1"><i className="fa fa-search"></i>
                                                                        Lihat Detail
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        <Link className="textTitleKm" to={`/library-detail/${d.id}`} target="_blank">
                                                            <h5>{d.title}</h5>
                                                        </Link>
                                                    </p>
                                                    <p>{d.description}</p>
                                                    <div>
                                                        <span className={`py-1 px-2 rounded bg-light-${d?.book_Status_color} text-${d?.book_Status_color}`}>{d.category_name}</span>
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <span className='font-bold'>{d.area_name}</span>
                                                    </div>
                                                    <br />
                                                    <p>
                                                        {d.year_end}
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )}
                                />
                            )
                    }
                </div>
            </div>
        </>
    );
}

export default () => <KnoProvider><Library /></KnoProvider>;