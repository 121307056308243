import { default as UserAccessPage } from './tab-content/useraccess';
import { default as RolesPage } from './tab-content/roles';
import { default as CreateUserPage } from './tab-content/create-user';

import React from 'react';
import { HeaderList, Tab, TabContent } from '../../../components';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalState } from '../../../contexts/useGlobalState';
import LogSession from './tab-content/log-session';
import { UserRoleProvider } from './useUserRole';
import { PageSessionProvider } from './usePageSession';
import LogActivity from './tab-content/log-activity';

function Index() {

    const { pathname, param } = useLocation();
    const [state, dispatch, action] = useGlobalState();
    const { pageName } = useParams();
    const history = useHistory();
    const getTitle = function () {
        const textArr = pageName.split("-").join(" ");
        return <span style={{ textTransform: "capitalize" }}>{textArr}</span>

    }
    return (
        <>
            <div style={{ paddingBottom: 20 }}>
                <HeaderList
                    title={state.formData?.usr_page?.page_title}
                    someText={state.formData?.usr_page?.page_breadcrumbs}
                />
            </div>

            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "create-user",
                        title: "User",
                        //onClick: () => history.push("/admin/usr-mng/create-user")
                    },
                    {
                        id: "user-role",
                        title: "Role",
                        //onClick: () => history.push("/admin/usr-mng/user-role")
                    },
                    {
                        id: "user-access",
                        title: "Role Access",
                        //onClick: () => history.push("/admin/usr-mng/user-access")
                    },
                    {
                        id: "log-session",
                        title: "Log Session",
                        //onClick: () => history.push("/admin/usr-mng/user-access")
                    },
                    {
                        id: "log-activity",
                        title: "Log Activity",
                        //onClick: () => history.push("/admin/usr-mng/user-access")
                    },
                ]}
            >
                <TabContent id="create-user">
                    <CreateUserPage />
                </TabContent>
                <TabContent id="user-role">
                    <RolesPage />
                </TabContent>
                <TabContent id="user-access">
                    <UserAccessPage />
                </TabContent>
                <TabContent id="log-session">
                    <LogSession />
                </TabContent>
                <TabContent id="log-activity">
                    <LogActivity />
                </TabContent>
            </Tab>
        </>
    )
}
export default () => <UserRoleProvider><PageSessionProvider><Index /></PageSessionProvider></UserRoleProvider>;