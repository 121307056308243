import React, { useEffect, useRef } from 'react';
import { GridRisk, SkeletonLoading } from '../../components/index'
import { kuantitatif, probabilitas, dampak } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ModalViewEditRisk from './modalViewEditRisk';

const column = [
    { "multiHeader": false, "dataField": "no", "caption": "No.", width: 50 },
    { "multiHeader": false, "dataField": "tipe_risk", "caption": "Kuantitatif/Kualitatif Risk", width: 100 },
    { "multiHeader": false, "dataField": "kejadian", "caption": "Kejadian Resiko (Risk Event)", width: 200 },
    { "multiHeader": false, "dataField": "penyebab", "caption": "Penyebab Resiko (Risk Agent)", width: 300 },
    {
        "multiHeader": true, "caption": "Inherent", "column": [
            { "dataField": "inher_probabil", "caption": "Probabilitas", width: 100 },
            { "dataField": "inher_dampak", "caption": "Dampak", width: 100 },
            { "dataField": "risk_priority", "caption": "Risk Priority", width: 100 },
        ]
    },
    {
        "multiHeader": true, "caption": "Mitigasi", "column": [
            { "dataField": "mit_rencana", "caption": "Rencana Mitigasi", width: 300 },
            { "dataField": "mit_tahun_berlaku", "caption": "Tahun Berlaku", width: 100 },
            { "dataField": "mit_tahun_selesai", "caption": "Tahun Selesai", width: 100 },
        ]
    },
    {
        "multiHeader": true, "caption": "Residual", "column": [
            { "dataField": "risk_priority_residu", "caption": "Risk Priority Number", width: 100 }
        ]
    }
]
const TabRisk = ({ param }) => {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const tableRef = useRef();

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.instance.refresh()
        }
    }, [state.formData.data_view_risk])
    useEffect(() => {
        if (param.id === null) {
            history.push("/error-page")
        } else {
            action.getData("/api/project-portfolio-crud/view-risk/" + param.id, {}, null, 'loadingRisk', true).then((resp) => {
                if (resp.code === "00") {
                    action.setForm("data_view_risk", resp.data.data_view_risk);
                    action.setForm('portfolio_data_page', resp.data_page);
                }
            })
        }
    }, [])
    function finish(data) {
        const d = {
            "id": data?.id || '',
            "research_id": data?.research_id || '',
            "baseline_name": data?.baseline_name || '',
            "risk_id": data?.risk_id || '',
            "tipe_risk": data?.tipe_risk || '',
            "kejadian": data?.kejadian || '',
            "penyebab": data?.penyebab || '',
            "gejala": data?.gejala || '',
            "faktor_positif": data?.faktor_positif || '',
            "dampak_kual": data?.dampak_kual || '',
            "inher_kuant": data?.inher_kuant || '',
            "inher_probabil": data?.inher_probabil || '',
            "inher_dampak": data?.inher_dampak || '',
            "mit_rencana": data?.mit_rencana || '',
            "mit_biaya": data?.mit_biaya || '',
            "mit_tahun_berlaku": data.mit_tahun_berlaku || '',
            "mit_tahun_selesai": data.mit_tahun_selesai || '',
            "res_kuant": data?.res_kuant || '',
            "res_probabil": data?.res_probabil || '',
            "res_dampak": data?.res_dampak || ''
        }
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            // console.log('data yang dikirim', d)
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Save Risk? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/project-portfolio-crud/update-risk",
                            data: d,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            refresh();
                            action.toggleModal("Show")
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageYear = '';
        if (data.tipe_risk === '') {
            isValid = false;
            message = [...message, 'Tipe Risk'];
        } else if (data.tipe_risk === 'Kualitatif') {            
            if (data.kejadian === '') {
                isValid = false;
                message = [...message, 'Kejadian resiko'];
            }
            if (data.penyebab === '') {
                isValid = false;
                message = [...message, 'Penyebab resiko'];
            }
            if (data.gejala === '') {
                isValid = false;
                message = [...message, 'Gejala resiko'];
            }
            if (data.faktor_positif === '') {
                isValid = false;
                message = [...message, 'Faktor positif'];
            }
            if (data.dampak_kual === '') {
                isValid = false;
                message = [...message, 'Dampak kualitatif'];
            }
            if (data.inher_probabil === '') {
                isValid = false;
                message = [...message, 'Inher. Probabilitas'];
            }
            if (data.inher_dampak === '') {
                isValid = false;
                message = [...message, 'Inher. Dampak'];
            }
            if (data.mit_rencana === '') {
                isValid = false;
                message = [...message, 'Rencana mitigasi'];
            }
            if (data.mit_biaya === '') {
                isValid = false;
                message = [...message, 'Biaya mitigasi'];
            }
            if (data.mit_tahun_berlaku === '' || data.mit_tahun_berlaku === null) {
                isValid = false;
                message = [...message, 'Tahun berlaku'];
            }
            if (data.mit_tahun_selesai === '' || data.mit_tahun_selesai === null) {
                isValid = false;
                message = [...message, 'Tahun selesai'];
            }
            if (data.res_probabil === '') {
                isValid = false;
                message = [...message, 'Res. Probabilitas'];
            }
            if (data.res_dampak === '') {
                isValid = false;
                message = [...message, 'Res. Dampak'];
            }
        } else if (data.tipe_risk === 'Kuantitatif') {            
            if (data.kejadian === '') {
                isValid = false;
                message = [...message, 'Kejadian resiko'];
            }
            if (data.penyebab === '') {
                isValid = false;
                message = [...message, 'Penyebab resiko'];
            }
            if (data.gejala === '') {
                isValid = false;
                message = [...message, 'Gejala resiko'];
            }
            if (data.faktor_positif === '') {
                isValid = false;
                message = [...message, 'Faktor positif'];
            }
            if (data.dampak_kual === '') {
                isValid = false;
                message = [...message, 'Dampak kualitatif'];
            }
            if (data.inher_kuant === '') {
                isValid = false;
                message = [...message, 'Inher. Kuantitatif'];
            }
            if (data.inher_probabil === '') {
                isValid = false;
                message = [...message, 'Inher. Probabilitas'];
            }
            if (data.inher_dampak === '') {
                isValid = false;
                message = [...message, 'Inher. Dampak'];
            }
            if (data.mit_rencana === '') {
                isValid = false;
                message = [...message, 'Rencana mitigasi'];
            }
            if (data.mit_biaya === '') {
                isValid = false;
                message = [...message, 'Biaya mitigasi'];
            }
            if (data.mit_tahun_berlaku === '' || data.mit_tahun_berlaku === null) {
                isValid = false;
                message = [...message, 'Tahun berlaku'];
            }
            if (data.mit_tahun_selesai === '' || data.mit_tahun_selesai === null) {
                isValid = false;
                message = [...message, 'Tahun selesai'];
            }
            if (data.res_kuant === '') {
                isValid = false;
                message = [...message, 'Res. Kuantitatif'];
            }
            if (data.res_probabil === '') {
                isValid = false;
                message = [...message, 'Res. Probabilitas'];
            }
            if (data.res_dampak === '') {
                isValid = false;
                message = [...message, 'Res. Dampak'];
            }
        }
        if (isValid) {
            if (parseInt(data.mit_tahun_selesai) < parseInt(data.mit_tahun_berlaku)) {
                isValid = false;
                messageYear = "Tahun selesai tidak boleh lebih besar tahun selesai"
            }
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageYear ? ' dan ' : '') + messageYear
        }
    }
    function refresh() {
        if (param.id === null) {
            history.push("/error-page")
        } else {
            // console.log('firing')
            action.getData("/api/project-portfolio-crud/view-risk/" + param.id, {}, null, 'loadingRisk', true).then((resp) => {
                if (resp.code === "00") {
                    action.setForm("data_view_risk", resp.data.data_view_risk)
                }
            })

        }
    }
    function onDelete(data) {
        const res = Swal.fire({
            text: 'Are you sure you want to delete this record?',
            showCancelButton: true,
            confirmButtonText: `Yes`,
        }).then((result) => {
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-portfolio-crud/delete-risk",
                        data: data.data,
                        withFile: false

                    }
                ).then((resp) => {
                    if (resp.code === "00") {
                        refresh();
                    }
                })
                return false;
            } else {
                return true;
            }
        })
    }
    return (
        <>
            {
                state.loadingRisk ? (
                    <SkeletonLoading type="table" rows={5} cols={column.length} />
                ) : (
                    <GridRisk
                        ref={tableRef}
                        editable={!state.formData.disable}
                        dataSource={state.formData.data_view_risk}
                        collumns={column}
                        dataSourceProb={probabilitas}
                        dataSourceDam={dampak}
                        dataSourceKuant={kuantitatif}
                        onDelete={onDelete}
                        onFinish={finish}
                        bodyForm={(disable, onFinish, selData) => <ModalViewEditRisk disable={disable} onFinish={onFinish} data={selData} />}
                    />
                )
            }
        </>
    )
}
export default TabRisk;