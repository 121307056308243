import React, { useState } from 'react';
import { RadioGrp } from '../../components';
const data_checkbox = [
    { value: '1', label: "Resources (SDM & Facility)" },
    { value: '2', label: "Administrasi & Pembayaran (Legal, Procurement, metode pembayaran)" },
    { value: '3', label: "Strategi Inovasi (Keekonomian tidak feasible, arahan manajemen)" },
    { value: '4', label: "Sub Holding (Kendala pada Sub Holding)" },
    { value: '5', label: "Partner (Kendala pada partner)" },
    { value: '6', label: "Regulasi (Kendala regulasi pemerintah)" },
    { value: '7', label: "Lainnya (RKAP carry over, pendetailan timeline dan anggaran)" },
]
function ModalSubmitRevision({ onValidSubmit = (data) => null, onClose = () => null }) {
    const [form, setForm] = useState({
        check: '',
        note: '',
        valCheck: true,
        valNote: true
    })
    const validate = () => {
        var checkVal = [true, true];
        if (form.check === '') {
            checkVal = [false, checkVal[1]];
        }
        if (form.note === '') {
            checkVal = [checkVal[0], false];
        }
        setForm((e) => ({ ...e, valCheck: checkVal[0], valNote: checkVal[1] }));
        if (checkVal[0] && checkVal[1]) {
            onValidSubmit({note:form.check, comment:form.note});
        }
    }
    const handleChange = (name, val) => {
        setForm((prev) => ({ ...prev, [name]: val }))
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <p><b>Penyebab Revisi:</b></p>
                    <div className='ml-5 mb-3'>
                        <RadioGrp
                            onChange={(val) => handleChange('check', val)}
                            value={form.check}
                            data={data_checkbox}
                        />
                        {
                            !form.valCheck && <small className='text-danger font-weight-normal'>Pilih salah satu penyebab</small>
                        }

                    </div>
                    {
                        // data_checkbox.map((d, i) => (
                        //     <div className="form-check mb-1" key={i}>
                        //         <input type="checkbox" className="form-check-input" id={d.value} />
                        //         <label className="form-check-label" htmlFor={d.value}>{d.label}</label>
                        //     </div>
                        // ))
                    }
                    <div class="form-group">
                        <label htmlFor="note"><b>Note :</b></label>
                        <textarea rows={3} className="form-control" id="note" placeholder="Keterangan"
                            value={form.note}
                            onChange={(e) => handleChange('note', e.target.value)}
                        />
                        {
                            !form.valNote && <small className='text-danger font-weight-normal'>Note tidak boleh kosong</small>
                        }
                    </div>
                </div>
            </div>
            <hr />
            <div className="text-md-right">
                <button onClick={onClose} className="btn btn-sm btn-secondary mx-1"
                >
                    Batal
                </button>
                <button className="btn btn-sm btn-success mx-1"
                    onClick={() => validate()}
                >
                    Submit Revision
                </button>
            </div>
        </div>
    );
}

export default ModalSubmitRevision;