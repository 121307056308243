import React from 'react';
import { Container } from 'react-bootstrap';
import { desc_product } from '../component/constanta';
import useIndexLandPage from '../use_index';
import useLndProd, { LndProdProvider } from './use_product_index';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PaginationCs } from '../../../components';
import ImageCover from '../images/bg-land-detail.svg';
import { useGlobalState } from '../../../contexts/useGlobalState';
import { useHistory } from 'react-router-dom';


const product_sources = [
    { name: "Upstream tech", url: "https://bend.spiirit.net/uploads/spiirit/product/01_UPT_Product_107.jpg" },
    { name: "Material & specialty chemical", url: "https://bend.spiirit.net/uploads/spiirit/product/02_MSC_Product_043.jpg" },
    { name: "Petrochemical & non fuel product", url: "https://bend.spiirit.net/uploads/spiirit/product/04_PNF_Product_061.jpg" },
    { name: "Biofuels", url: "https://bend.spiirit.net/uploads/spiirit/product/05_BIOF_Product_093.jpg" },
    { name: "Energy storage & power", url: "https://bend.spiirit.net/uploads/spiirit/product/06_ESP_Product_100.jpg" },
    // { name: "Laboratory", url: "https://bend.spiirit.net/uploads/spiirit/product/07_LAB_Product_118.jpg" },
    { name: "Catalyst", url: "https://bend.spiirit.net/uploads/spiirit/product/08_Catalyst_Product_005.jpg" },
];

function Product(props) {
    const { lndProdAction, lndProdState } = useLndProd();
    const [state] = useGlobalState();
    const { indAction: {
        toHome = () => null,
        toProductDetail = () => null
    }, indState: {
        dataType = []
    } } = useIndexLandPage();
    const history = useHistory();
    return (
        <div className='mt-5' style={{ 'paddingTop': '35px' }}>
            <img src={ImageCover} style={{ 'position': 'absolute', width: '100%', height: '220px', top: '10px', objectFit: 'cover' }}></img>
            <Container>
                <div className='row justify-content-center text-center mb-5 text-white'>
                    <div className='col-lg-6 col-md-6'>
                        <h5 className='fw-bold'>Produk Inovasi RTI</h5>
                        <span className='text-wrap'>{desc_product}</span>
                    </div>
                </div>
                <div className='row justify-content-center text-center mb-5'>
                    <div className='col-lg-8 col-md-8'>
                        <div className="input-group input-group-rounded mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text input-group-icon border-right-0 bg-white" id="basic-addon1">
                                    <i className='fa fa-search text-primary icon-nm'></i>
                                </span>
                            </div>
                            <input type="text" className="form-control border-left-0 input-group-field" placeholder="Cari Product..."
                                value={lndProdState.judul}
                                onChange={(e) => lndProdAction.setJudul(e.target.value)}
                                onKeyUp={(e) => lndProdAction.judulEnter(e)} />
                        </div>
                        <div className='text-left'>
                            <div className='row'>
                                <div className='col-auto'>
                                    <select className='form-control form-control-sm mx-1' style={{ width: '100px' }}
                                        value={lndProdState.tahun}
                                        onChange={(e) => lndProdAction.tahunChange(e.target.value)}
                                    >
                                        {
                                            [
                                                {
                                                    name: 'Semua tahun',
                                                    value: ''
                                                },
                                                ...(lndProdState.dataProduct?.data_tahun || []).map(d => ({ name: d, value: d }))
                                            ].map((d, i) => (
                                                <option key={i} value={d?.value}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='col'>
                                    <button className={`btn btn-sm bg-${lndProdState.product_type === 0 ? 'blue text-white' : 'light'} mx-1 mb-2`}
                                        onClick={(e) => lndProdAction.productTypeChange(0)}
                                    >Semua tipe</button>
                                    {
                                        [...(state.formData?.landingpage_product_type || [])].map((d, i) => (
                                            <button className={`btn btn-sm bg-${lndProdState.product_type === d?.id ? 'blue text-white' : 'light'} mx-1 mb-2`} key={i}
                                                onClick={(e) => lndProdAction.productTypeChange(d?.id)}
                                            >{d?.name}</button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    [...(state.formData?.landingpage_product_type || [])].find(d => d?.id === lndProdState.product_type)?.image_link && (
                        <div className='row'>
                            <div className='col-md-12 mb-5'>
                                <img src={[...(state.formData?.landingpage_product_type || [])].find(d => d?.id === lndProdState.product_type)?.image_link}
                                    style={{ maxHeight: '500px', width: '100%' }}
                                ></img>
                            </div>
                        </div>
                    )
                }
                <PaginationCs
                    data={[...(lndProdState.dataProduct?.data_products || [])]}
                    dataPerPage={8}
                    RenderContainer={({ children }) => (
                        <div className='row mb-5'>
                            {children}
                        </div>
                    )}
                    renderComponent={(d, i) => (
                        <div className='col-lg-3 col-md-3 mb-3' key={i}>
                            <div className='card p-3 h-100 rounded-cs card-land-produk'
                                onClick={() => toProductDetail({
                                    detail: d,
                                    all_data: [...(lndProdState.dataProduct?.data_products || [])],
                                    selected_index: i
                                })}
                            >
                                <LazyLoadImage
                                    key={i}
                                    src={d?.photo_location_name}
                                    width={'100%'}
                                    height={'180px'}
                                    style={{ objectFit: 'contain', backgroundColor: 'whitesmoke' }}
                                    onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                    effect='blur'
                                />
                                <span className='text-wrap font-weight-boldest m-3'>{d?.name}</span>
                                {/* <div className='text-wrap m-3  text-truncate-container'>{d?.description}</div> */}
                                <div className='footer-card-product'>
                                    <div>
                                        <span className='bg-light-primary text-primary px-2 rounded'>{d?.product_status_name}</span>
                                        {/* <span className='text-muted float-right'>{d?.release_year}</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <div className='d-inline-flex bg-transparent py-3 position-sticky my-3' style={{ bottom: '10px' }}>
                    <button className='btn btn-sm btn-secondary'
                        // onClick={toHome}
                        onClick={() => history.push('/landing-page/beranda')}
                    >
                        <i className='fas fa-chevron-circle-left icon-nm'></i>
                        Kembali
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default (props) => <LndProdProvider><Product {...props} /></LndProdProvider>;