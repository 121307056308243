import React, { createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useGlobalState } from "../../../../contexts/useGlobalState";
import moment from "moment";
import { status_equipment } from "../../../../components/utility/utility";
import { Link, useLocation } from "react-router-dom";

const context = createContext({
    eqCrMainState: {},
    eqCrMainAction: {}
});

export function EqCrMainProvider({ children }) {
    const { pathname } = useLocation();
    const [, , globalActon] = useGlobalState();
    const [data_page, setData_page] = useState({});
    const [loadingTable, setLoadingTable] = useState(false);
    const [dataTable, setDataTable] = useState({});
    async function getData(year = moment(new Date()).format('YYYY')) {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData("/api/ext-equipment/table-maintenance", { year }, null, null, true)
            if (resp.code === "00") {
                setDataTable(resp.data)
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    const CustomCellRender = (rowInfo) => {
        if (rowInfo.column.name === 'nama') {
            return (
                <Link to={`/admin/equipment-detail/${rowInfo.data?.id}`} target="_blank">
                    <span className="d-block text-wrap">{rowInfo.value}</span>
                </Link>
            )
        } else if (rowInfo.column.name === 'status_name_01' ||
            rowInfo.column.name === 'status_name_02' ||
            rowInfo.column.name === 'status_name_03' ||
            rowInfo.column.name === 'status_name_04' ||
            rowInfo.column.name === 'status_name_05' ||
            rowInfo.column.name === 'status_name_06' ||
            rowInfo.column.name === 'status_name_07' ||
            rowInfo.column.name === 'status_name_08' ||
            rowInfo.column.name === 'status_name_09' ||
            rowInfo.column.name === 'status_name_10' ||
            rowInfo.column.name === 'status_name_11' ||
            rowInfo.column.name === 'status_name_12' ||
            rowInfo.column.name === 'nama_status' ||
            rowInfo.column.name === 'equipment_status_str'
        ) {
            return (
                <div className="d-flex justify-content-center">
                    {
                        status_equipment(rowInfo.value)
                    }
                </div>
            )
        } else if (rowInfo.column.name === "status_equipment") {
            var color_text = rowInfo.value === "Expired" ? "text-danger" :
                rowInfo.value === "Warning" ? "text-warning" :
                    rowInfo.value === "Running" ? "text-success" : "text-dark"
            return (
                <span className={`d-block ${color_text}`}>{rowInfo.value}</span>
            )
        } else {
            return (
                <span className="d-block py-1 px-2 text-wrap">{rowInfo.value}</span>
            )
        }
    }
    const HeaderCellRender = (e) => {
        return <p style={{ fontWeight: 'bolder', fontSize: '9pt', fontFamily: 'Poppins' }} className="text-wrap">{e.column.caption}</p>;
    }

    const [modeEdit, setModeEdit] = useState(false);
    const [data, setData] = useState({});

    function editHistory(data = {}) {
        setModeEdit(true);
        setData(data);
        globalActon.toggleModal('modalEditHistory');
    }
    const onAddUpdateSuccess = () => {
        globalActon.toggleModal('modalEditHistory');
        setModeEdit(false);
        setData({});
        getData();
    }
    useEffect(() => {
        getData();
    }, [])
    return <context.Provider
        value={{
            eqCrMainState: {
                dataTable,
                data_page,
                loadingTable,
                modeEdit,
                data,                
            },
            eqCrMainAction: {
                CustomCellRender,
                HeaderCellRender,
                editHistory,
                onAddUpdateSuccess
            }
        }}
    >{children}</context.Provider>
}

export default function useEqCrMain() {
    return useContext(context);
}