import React, { useEffect, useRef } from 'react';
import { GridDx, HeaderList } from '../../../../../components';
import Card from 'react-bootstrap/Card'
import useCreateUser, { UserProvider } from './useCreateUser';
import { Modal } from 'react-bootstrap';

function Index() {
    const { usrAction, usrState } = useCreateUser();
    const tableRef = useRef();

    useEffect(() => {
        usrAction.getUser();
    }, [])

    const roleRef = useRef(null)
    return (
        <>
            <Modal size="lg" show={usrState.modal.show} onHide={() => usrAction.toggleModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {usrState.modal.editMode ? "Edit" : "Add"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="name">Name</label>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                {
                                    usrState.modal.editMode ? (
                                        <span className='form-control form-control-sm bg-disable'>{usrState.formData?.name || ''}</span>
                                    ) : (
                                        <input
                                            onChange={(e) => usrAction.setFormData(f => ({ ...f, name: e.target.value }))}
                                            value={usrState.formData?.name} placeholder="name of new user" id="name" className="form-control" />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="email">Email</label>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                {
                                    usrState.modal.editMode ? (
                                        <span className='form-control form-control-sm bg-disable'>{usrState.formData?.email || ''}</span>
                                    ) : (
                                        <input
                                            onChange={(e) => usrAction.setFormData(f => ({ ...f, email: e.target.value }))}
                                            type={"email"}
                                            value={usrState.formData?.email} placeholder="email of new user" id="email" className="form-control" />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {
                        usrState.modal?.editMode === false && (
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="pass">Password</label>

                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <input
                                            onChange={(e) => usrAction.setFormData(f => ({ ...f, password: e.target.value }))}

                                            value={usrState.formData?.password} type="password" placeholder="password" id="pass" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="employeeID">Employee ID</label>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                {
                                    usrState.modal.editMode ? (
                                        <span className='form-control form-control-sm bg-disable'>{usrState.formData?.employee_id || ''}</span>
                                    ) : (
                                        <input
                                            onChange={(e) => usrAction.setFormData(f => ({ ...f, employee_id: e.target.value }))}
                                            value={usrState.formData?.employee_id} type="text" placeholder="Employee ID" id="employeeID" className="form-control" />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="status">Status Pekerja</label>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                {
                                    usrState.modal.editMode ? (
                                        <span className='form-control form-control-sm bg-disable'>{usrState.formData?.status_profil || ''}</span>
                                    ) : (
                                        <select
                                            onChange={(e) => usrAction.setFormData(f => ({ ...f, status_profil: e.target.value }))}
                                            value={usrState.formData?.status_profil} id="status" className="form-control" >
                                            <option value={''}>- Status -</option>
                                            {
                                                (usrState.dataStatus || []).map(({ param_id, title }, k) => (
                                                    <option key={k} value={title}>
                                                        {title}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="status">Status User</label>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <select
                                    onChange={(e) => usrAction.setFormData(f => ({ ...f, status_user: e.target.value }))}

                                    value={usrState.formData?.status_user} id="status" className="form-control" >
                                    <option value={1}>Active</option>
                                    <option value={0}>Blocked</option>
                                    {/* {
                                        (usrState.dataStatus || []).map(({ param_id, title }, k) => (
                                            <option key={k} value={title}>
                                                {title}
                                            </option>
                                        ))
                                    } */}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="role">Roles</label>

                        </div>
                        <div className="col-md-8">
                            <div className="input-group">
                                <select ref={roleRef} id="role" className="form-control" >
                                    <option value={''}>- Role -</option>
                                    {
                                        (usrState.dataRoles || []).map(({ id, name }, k) => (
                                            <option key={k} value={id}>
                                                {name}
                                            </option>
                                        ))
                                    }
                                </select>
                                {/* <div className="input-group-append" */}
                                <button
                                    onClick={() => {
                                        if (roleRef.current.value) {
                                            usrAction.setFormData(f => ({
                                                ...f,
                                                role_id: [...f.role_id, {
                                                    "role_id": roleRef.current.value,
                                                    "is_new": true
                                                }]
                                            }))
                                        }
                                    }}
                                    className="input-group-append btn btn-primary ml-1">+</button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <ul class="list-group">
                                        {
                                            (usrState.formData?.role_id || []).map((d, k) => (
                                                <div className='mb-1'>
                                                    {
                                                        (usrState.dataRoles || [])
                                                            .find(({ id }) => id === parseInt(d?.role_id))?.name
                                                    }
                                                    <button
                                                        onClick={(e) => usrAction.deleteRole(d)
                                                        }
                                                        className="btn btn-sm btn-outline-danger float-right">
                                                        <i className='fa fa-trash-alt icon-nm'></i>
                                                    </button>
                                                </div>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => usrAction.toggleModal()} className="btn btn-secondary">Close</button>
                    <button onClick={() => usrAction.buttonAdd()} className="btn btn-sm btn-primary"><i className="fa fa-save icon-nm"></i>Simpan</button>
                </Modal.Footer>
            </Modal>
            <Card
                className="m-0 p-0 h-100 mb-5 customShadow"
            >
                <Card.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <GridDx
                                toolbar={
                                    <button onClick={() => usrAction.toggleModal()} className="btn-toolbar btn btn-primary mb-3">
                                        <i className="fa fa-plus icon-nm my-auto"></i> Add User
                                    </button>
                                }
                                searchForm={true}
                                ref={tableRef}
                                loading={usrState.loadingUser}
                                data={usrState.dataUser || []}
                                withAction={true}
                                columns={[
                                    { fieldName: "employee_id", caption: "Employee ID" },
                                    { fieldName: "name", caption: "Nama" },
                                    { fieldName: "email", caption: "Email" },
                                    { fieldName: "role_name", caption: "Role" },
                                    { fieldName: "status_profil", caption: "Status pekerja" },
                                    { fieldName: "status_user", caption: "Status user" },
                                ]}
                                actions={[
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => { usrAction.deleteData(v?.id) }
                                    },
                                    {
                                        name: "Edit Role & Status User",
                                        icon: "fa fa-pencil-alt icon-nm text-secondary",
                                        action: (v, c) => { usrAction.toggleModal(true, v?.id) }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
export default (props) => <UserProvider><Index /></UserProvider>