import React, { memo, useEffect, useRef, useState } from 'react';
import { HeaderList, Modal, ChartDx, GridDx } from '../../components';
import TabTable from './tabTable';
import TabDashboard from './tabDashboard';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';
import { blue } from '@material-ui/core/colors';
import { Card, Dropdown } from 'react-bootstrap'
import {
    Button,
} from "react-bootstrap";
import './people.css';
import { pathFile, pathImage } from '../../app.config';


const columns = [
    { fieldName: "name", caption: 'Judul', width: 200 },
    { fieldName: 'description', caption: 'Deskripsi', width: 500 },
    { fieldName: 'patent_category_name', caption: 'Category', width: 100 },
    { fieldName: 'patent_year', caption: 'Tahun', width: 100 },
    { fieldName: "pemilik", caption: 'Pemilik', width: 100 },
    { fieldName: "area_name", caption: 'Area', width: 100 },
    { fieldName: "direktorat_fungsi", caption: 'Direktorat', width: 100 },
    { fieldName: 'patent_owners_name', caption: "Inventor", width: 200 },
    { fieldName: 'owner_ext', caption: 'Inventor Ext.', width: 200 },
    { fieldName: "keyword", caption: 'Keyword', width: 150 },
    { fieldName: 'patent_status_name', caption: "Status", width: 100 },
    { fieldName: 'no_pengajuan', caption: "No. Pengajuan", width: 100 },
    { fieldName: 'no_paten', caption: "No. Paten", width: 100 },
]

export default memo(function () {
    const { pathname, param } = useLocation();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [data_page, setData_page] = useState({});
    const tableRef = useRef();
    useEffect(() => {
        action.getData("/api/people-intel-property/table-dashboard-2", {
        }, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setData_page(resp.data_page);
            }
        })
    }, [])

    const customCellrender = (e) => {
        if (e.column.name === "name") {
            return (
                <>
                    <div>
                        <Link to={`/people/patent-view-public/${e.data?.id}`} target='_blank'>
                            <span className="d-block text-wrap"><strong>{e.value}</strong></span>
                        </Link>
                    </div>
                </>
            )
        } else if (e.column.name === 'no_pengajuan') {
            return (
                <div className="row">
                    {/* <div className="col-auto pr-0">
                        <label as className="label-patent text-wrap"><strong>No. Pengajuan / No Paten</strong></label>
                    </div> */}
                    <div className="col">
                        <span className="text-wrap">
                            {
                                e.value
                            }
                        </span>
                    </div>
                </div>
            )
        }
        else if (e.column.name === "note") {
            return (
                <>
                    <div className="row">
                        <div className="col-auto pr-0">
                            <label as className="label-patent text-wrap"><strong>Inventor</strong></label>
                        </div>
                        <div className="col">
                            <span className="text-wrap">
                                {
                                    e.data.owner_name
                                }
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto pr-0">
                            <label as className="label-patent"><strong>Inventor Ext.</strong></label>
                        </div>
                        <div className="col">
                            <span className="text-wrap">
                                {
                                    e.data.owner_ext
                                }
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto pr-0">
                            <label as className="label-patent text-wrap"><strong>Pemegang Paten</strong></label>
                        </div>
                        <div className="col">
                            <span className="text-wrap">
                                {
                                    e.data.pemegang_paten
                                }
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto pr-0">
                            <label as className="label-patent"><strong>Status</strong></label>
                        </div>
                        <div className="col">
                            <span className="text-wrap">
                                {
                                    e.data.patent_status_name
                                }
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto pr-0">
                            <label as className="label-patent text-wrap"><strong>No. Pengajuan / No Paten</strong></label>
                        </div>
                        <div className="col">
                            <span className="text-wrap">
                                {
                                    e.data.no_pengajuan
                                }

                                {
                                    e.data.no_paten
                                }

                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto pr-0">
                            <label as className="label-patent"><strong>Year</strong></label>
                        </div>
                        <div className="col">
                            <span className="text-wrap">
                                {
                                    e.data.patent_year
                                }
                            </span>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-auto pr-0">
                            <label as className="label-patent"><strong>Creator</strong></label>
                        </div>
                        <div className="col">
                            <span className="text-wrap">
                                {
                                    e.data.creator_name
                                }
                            </span>
                        </div>
                    </div> */}
                </>
            )
        } else if (e.column.name === "file_location") {
            return (
                <>
                    {
                        e.value ? (
                            <div>
                                {/* <Dropdown
                                    className="mb-3"
                                >
                                    <Dropdown.Toggle className="btn btn-sm btn-light-primary d-flex justify-content-between custom-dropdown-icon" style={{ width: '100%' }} id="dropdown-basic">
                                        <span style={{ float: 'left' }}><i className="fa fa-file"></i> Final Report</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            dataReport.map((d, i) => (
                                                <a className="dropdown-item" target="_blank" href={pathFile + d.file_name}>
                                                    Final Report&nbsp;{d.year}&nbsp;({i + 1})
                                                </a>
                                                // <span className="dropdown-item">Final Report&nbsp;{d.year}&nbsp;({i + 1})</span>
                                                // <Link className="dropdown-item">Report {i + 1}</Link>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown> */}
                                <a target="_blank" href={e.value} download className="my-5">
                                    <button className="btn btn-sm btn-outline-primary"><i className="fas fa-file-download icon-nm"></i> Download File</button>
                                </a>
                            </div>

                        ) : (
                            <div>
                                <span className="p-2 my-5 bg-light-secondary text-dark">No file</span>
                            </div>
                        )
                    }
                </>
            )
        } else if (e.column.name === "keyword") {
            return (
                <div className="text-wrap">
                    {
                        (e.value || "").split(',').map((d, i) => (
                            <span className="badge badge-secondary mr-1 mb-1" key={i}>{d}</span>
                        ))
                    }
                </div>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <div>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            >
            </HeaderList>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <div className="row">
                        <div className="col" style={{ textAlign: "left" }}>
                            <h5 className="m-portlet__head-text">
                                Top Inventor
                            </h5>
                        </div>
                        <div className="col" style={{ textAlign: "right" }}>
                            <Link className="d-inline-flex align-middle " to="/AllInventor">
                                <span className="text-primary">Lihat Semua
                                </span>
                                <i className="fa fa-arrow-right my-auto ml-1 text-primary"></i>
                            </Link>
                        </div>
                    </div>
                    <hr />
                    <div className="row" >
                        {
                            [...(state.data_Owners || [])].map((d, i) => (
                                <div className="col-lg-2 col-md-2 col-6 mb-5" style={{ textAlign: "center" }}>
                                    <div className='overlay'>
                                        <div className="overlay-wrapper">
                                            <img style={{ height: 150, borderRadius: '3%' }} className="bg-secondary mb-3"
                                                src={d.photo_location}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                        </div>
                                        <div class="overlay-layer">
                                            <Link to={`/inventor-detail/${d?.owner_id}`}>
                                                <button className="btn btn-sm btn-primary"><i className="fa fa-search"></i>
                                                    Lihat Detail
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <br />
                                    <h5>{d.owner_name}</h5>
                                    {/* <p>{d.role_name}</p> */}
                                    <p>
                                        <span class="badge badge-primary badge-wide">
                                            {d.jumlah} Patents
                                        </span>
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <div className="col" style={{ marginTop: "10px" }}>
                        <div className="row col-md-12">
                            <div className="col-md-6 mb-5">

                                <div className="col-8">
                                    <h5><b>Status Patent</b></h5>

                                </div>

                                <hr />
                                <div className="row ">
                                    <div className="col-md-12">
                                        <ChartDx
                                            labelChart={true}
                                            argumentField="name"
                                            type="stackedBar"
                                            dataSource={[...(state.data_grafik_patent_status || [])]}
                                            withTitle={false}
                                            showLegend={false}
                                            toolsExport={false}
                                            colorBarDifferent={true}
                                            coloBarDifferentValueField="jumlah_int"
                                            colorBarDiffirentField="name"
                                            //onRenderLabelChart={() => { }}
                                            onRenderTooltip={(e) => (`${e.seriesName}: ${e.valueText}`)}
                                            rotate={true}
                                            yAxisArgs={[
                                                {
                                                    //name: "PD",
                                                    valueField: "jumlah_int",
                                                }
                                            ]}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">

                                <div className="col-8">
                                    <h5><b>Area Patent</b></h5>

                                </div>

                                <hr />
                                <div className="row ">
                                    <div className="col-md-12">
                                        <ChartDx
                                            labelChart={true}
                                            argumentField="name"
                                            type="stackedBar"
                                            dataSource={[...(state.data_grafik_patent_area || [])]}
                                            withTitle={false}
                                            showLegend={false}
                                            toolsExport={false}
                                            colorBarDifferent={true}
                                            coloBarDifferentValueField="jumlah_int"
                                            colorBarDiffirentField="name"
                                            //onRenderLabelChart={() => { }}
                                            onRenderTooltip={(e) => (`${e.seriesName}: ${e.valueText}`)}
                                            rotate={true}
                                            yAxisArgs={[
                                                {
                                                    //name: "jumlah_int",
                                                    valueField: "jumlah_int",
                                                }
                                            ]}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <h5><b>List Intellectual Property</b></h5>
                    <hr />

                    <GridDx
                        ref={tableRef}
                        searchForm={true}
                        columns={columns}
                        data={state.data_Patents}
                        cellRender={customCellrender}
                        orderingDrag={false}
                        pagination={true}
                        currentPage
                        show
                    >

                    </GridDx>
                </Card.Body>
            </Card>
        </div>
    )
})