import React, { useLayoutEffect, useState } from 'react';
import { useLocation } from "react-router";
import { useGlobalState } from '../../../../app/contexts/useGlobalState';





const initialDarkClasses = {
    themeType : "dark",
}


const initialLightClasses = {
    themeType : "light"
}


export default function(){
    const { pathname } = useLocation();
    const [state, dispatch, action] = useGlobalState();
    const [classes, setClasses] = useState({});


    function isRouteAdmin() {
        let path = pathname.split("/");
        // console.log(path)
        if (path.length >= 2 ) return path[1] === "admin";
        return false;
    }

    useLayoutEffect(function(){
        if(isRouteAdmin()) setClasses(initialDarkClasses)
        if(!isRouteAdmin()) setClasses(initialLightClasses)
    },[pathname])

    return classes;
}

