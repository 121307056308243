import React, { createContext, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import useFetch from '../../modules/useFetch';

const mpContext = createContext({
    mpState: {},
    mpAction: {}
});

const tabs = [
    {
        id: 'All',
        title: 'All Research'
    },
    {
        id: 'Leader',
        title: 'As Leader'
    },
    {
        id: 'Team',
        title: 'As Team Member'
    },
    {
        id: 'Archived',
        title: 'Archived'
    }

]
export function MpProvider({ children }) {
    const { doGet, doGetMultiple } = useFetch();
    const [dataAll, setDataAll] = useState({});
    const [lodaingAll, setLoadingAll] = useState(false);
    const [dataLeader, setDataLeader] = useState({});
    const [lodaingLeader, setLoadingLeader] = useState(false);
    const [dataMember, setDataMember] = useState({});
    const [lodaingMember, setLoadingMember] = useState(false);
    const [dataArchived, setDataArchived] = useState({});
    const [lodaingArchived, setLoadingArchived] = useState(false);
    const { param } = useLocation();
    const [selectedTab, setSelectedTab] = useState('All');
    const [dataTahun, setDatatahun] = useState([]);
    const [dataFungsi, setDataFungsi] = useState([]);
    const [tmpSrc, settmpSrc] = useState('');
    const [totalData, setTotalData] = useState({
        All: 0,
        Leader: 0,
        Team: 0,
        Archived: 0
    })
    const [data_page, setData_page] = useState({
        page_title: "",
        page_breadcrumbs: ""
    })
    const [filter, setFilter] = useState({
        tahun: param?.tahun || new Date().getFullYear(),
        fungsi: '',
        search: ''
    })
    const GetAllData = async (thn) => {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        setLoadingAll(true)
        try {
            var opt = [{
                url: 'api/project-admin/table',
                param: { tahun: thn, ...filter },
                responseVar: 'resp1'
            }, {
                url: 'api/project-admin/table-as-leader',
                param: { tahun: thn, ...filter },
                responseVar: 'resp2'
            }, {
                url: 'api/project-admin/table-as-member',
                param: { tahun: thn, ...filter },
                responseVar: 'resp3'
            }, {
                url: 'api/project-admin/table-archived',
                param: { tahun: thn, ...filter },
                responseVar: 'resp4'
            }];
            const { resp1, resp2, resp3, resp4 } = await doGetMultiple(opt);
            var total = totalData;

            //response handle request 1      
            if (resp1?.code === '00') {
                setDataAll(resp1?.data);
                setDatatahun(resp1?.data?.data_year_portfolio);
                setDataFungsi(resp1?.data?.data_function);
                setData_page({ ...resp1?.data_page });
                total.All = [...(resp1?.data?.data_table || [])].length;
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp1?.message,
                    icon: 'error',
                })
            }
            //response handle request 2
            if (resp2?.code === '00') {
                setDataLeader(resp2?.data);
                setDatatahun(resp2?.data?.data_year_portfolio);
                setDataFungsi(resp2?.data?.data_function);
                total.Leader = [...(resp2?.data?.data_table || [])].length;
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp2?.message,
                    icon: 'error',
                })
            }
            //response handle request 3
            if (resp3?.code === '00') {
                setDataMember(resp3?.data);
                setDatatahun(resp3?.data?.data_year_portfolio);
                setDataFungsi(resp3?.data?.data_function);
                total.Team = [...(resp3?.data?.data_table || [])].length;
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp3?.message,
                    icon: 'error',
                })
            }
            //response handle request 4
            if (resp4?.code === '00') {
                setDataArchived(resp4?.data);
                setDatatahun(resp4?.data?.data_year_portfolio);
                setDataFungsi(resp4?.data?.data_function);
                total.Archived = [...(resp4?.data?.data_table || [])].length;
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp4?.message,
                    icon: 'error',
                })
            }
            setTotalData({ ...total });
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingAll(false);
        Swal.close();
    }
    const GetAll = async (thn) => {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        setLoadingAll(true);
        try {
            const resp = await doGet({
                url: 'api/project-admin/table',
                param: { tahun: thn },
                token: true
            });
            if (resp?.code === '00') {
                setDataAll(resp?.data);
                setDatatahun(resp?.data?.data_year_portfolio);
                setDataFungsi(resp?.data?.data_function);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setLoadingAll(false);
    }
    const GetLeader = async (thn) => {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        setLoadingLeader(true);
        try {
            const resp = await doGet({
                url: 'api/project-admin/table-as-leader',
                param: { tahun: thn },
                token: true
            });
            if (resp?.code === '00') {
                setDataLeader(resp?.data);
                setDatatahun(resp?.data?.data_year_portfolio);
                setDataFungsi(resp?.data?.data_function);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setLoadingLeader(false);
    }
    const GetMember = async (thn) => {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        setLoadingMember(true);
        try {
            const resp = await doGet({
                url: 'api/project-admin/table-as-member',
                param: { tahun: thn },
                token: true
            });
            if (resp?.code === '00') {
                setDataMember(resp?.data);
                setDatatahun(resp?.data?.data_year_portfolio);
                setDataFungsi(resp?.data?.data_function);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setLoadingMember(false);
    }
    const GetArchived = async (thn) => {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        setLoadingArchived(true);
        try {
            const resp = await doGet({
                url: 'api/project-admin/table-archived',
                param: { tahun: thn },
                token: true
            });
            if (resp?.code === '00') {
                setDataArchived(resp?.data);
                setDatatahun(resp?.data?.data_year_portfolio);
                setDataFungsi(resp?.data?.data_function);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        Swal.close();
        setLoadingArchived(false);
    }

    const handleTabChange = (val = '') => {
        setSelectedTab(val);
    }
    const handleChangeFilter = (name = '', val = '') => {
        setFilter((prev) => ({
            ...prev,
            [name]: val
        }))
    }
    useEffect(() => {
        GetAllData(filter.tahun);
        // if (selectedTab === 'All') GetAll(filter.tahun);
        // if (selectedTab === 'Leader') GetLeader(filter.tahun);
        // if (selectedTab === 'Team') GetMember(filter.tahun);
        // if (selectedTab === 'Archived') GetArchived(filter.tahun);
    }, [filter]);
    return <mpContext.Provider
        value={{
            mpState: {
                dataAll,
                dataMember,
                dataLeader,
                dataArchived,
                lodaingAll,
                lodaingMember,
                lodaingLeader,
                lodaingArchived,
                tabs,
                filter,
                selectedTab,
                dataTahun,
                dataFungsi,
                tmpSrc,
                totalData,
                data_page
            },
            mpAction: {
                handleTabChange,
                handleChangeFilter,
                settmpSrc
            }
        }}
    >
        {children}
    </mpContext.Provider>
}
export default function useMyProject() {
    return useContext(mpContext);
}