import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import SVG from "react-inlinesvg";
import { GridDx } from '../../components';
import ProductGridDetail from './productGridDetail';
import { Link } from 'react-router-dom';
import moment from 'moment';

const column = [
    { fieldName: "name", caption: "Nama Product", width: 300 },
    { fieldName: "list_field_test", caption: "Total Field Test (Ribu USD)", width: 200, onExport: (e) => [...(e?.list_field_test || [])].map(d => " - " + d?.year + " : " + parseFloat(d?.price, 0).toFixed(2)).join(`\u2029`) + `\u2029 - Total : ` + parseFloat(e.field_test_total, 0).toFixed(5), allowFiltering: false, },
    { fieldName: "list_commercial", caption: "Total Commercial (Ribu USD)", width: 150, onExport: (e) => [...(e?.list_commercial || [])].map(d => " - " + d?.year + " : " + parseFloat(d?.price, 0).toFixed(2)).join(`\u2029`) + `\u2029 - Total : ` + parseFloat(e.commectial_total, 0).toFixed(5), allowFiltering: false, },
    { fieldName: "list_contribution", caption: "Product Contribution (Ribu USD)", width: 150, onExport: (e) => [...(e?.list_contribution || [])].map(d => " - " + d?.year + " : " + parseFloat(d?.price, 0).toFixed(2)).join(`\u2029`) + `\u2029 - Total : ` + parseFloat(e.price, 0).toFixed(5), allowFiltering: false, },
    { fieldName: "inventor_name", caption: "Inventor", width: 300 },
    { fieldName: "nama_PIC_str", caption: "PIC Contributor", width: 300 },
    { fieldName: "kontak_name", caption: "Kontak", width: 200 },
    { fieldName: "product_types_name", caption: "Tipe Product", width: 100 },
    { fieldName: "product_status_name", caption: "Status Product", width: 100 },
    { fieldName: "contrib_type", caption: "Contribution type", width: 200 },  
    { fieldName: "release_year", caption: "Tahun Rilis", width: 100 },
]
const column_report = [
    { fieldName: "name", caption: "Nama Product", width: 300 },
    { fieldName: "field_test_total", caption: "Total Field Test (Ribu USD)", width: 200 },
    { fieldName: "commercial_total", caption: "Total Commercial (Ribu USD)", width: 150 },
    { fieldName: "price", caption: "Total Product Contribution (Ribu USD)", width: 150 },
    { fieldName: "field_test_year_select", caption: "Field Test", width: 100 },
    { fieldName: "commercial_year_select", caption: "Commercial", width: 100 },
    { fieldName: "contribution_year_select", caption: "Product Contribution", width: 100 },
    { fieldName: "inventor_name", caption: "Inventor", width: 300 },
    { fieldName: "nama_PIC_str", caption: "PIC Contributor", width: 300 },
    { fieldName: "kontak_name", caption: "Kontak", width: 200 },
    { fieldName: "product_types_name", caption: "Tipe Product", width: 100 },
    { fieldName: "product_status_name", caption: "Status Product", width: 100 },
    { fieldName: "contrib_type", caption: "Contribution type", width: 200 },  
    { fieldName: "release_year", caption: "Tahun Rilis", width: 100 },
]
function TabProductList({ reporting = false }) {
    const [state, dispatch, action] = useGlobalState();
    const tableRef = useRef();
    const [data, setData] = useState([]);
    const [dataReport, setDataReport] = useState({});
    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (reporting === true) {
            getDataYear(filter);
        } else {
            action.getData('/api/ext-product/table-view-2', {
            }, null, 'loadingGet', true).then((resp) => {
                if (resp.code === '00') {
                    setData(resp.data.data_tabel);
                    action.setForm('pro', resp.data_page);
                }
            })
        }
    }, []);
    function getDataYear(year) {
        action.getData('/api/ext-product/table-reporting', {
            year: year
        }, null, 'loadingGet', true).then((resp) => {
            if (resp.code === '00') {
                setDataReport(resp.data);
                setFilter(resp.data?.year_select);
                action.setForm('pro', resp.data_page);
            }
        })
    }
    function headerCellRender(e) {
        if (e.column.name === 'field_test_year_select' ||
            e.column.name === 'commercial_year_select' ||
            e.column.name === 'contribution_year_select'
        ) {
            return <p className="text-wrap">{e.column.caption} {dataReport?.year_select}</p>
        } else {
            return <p className="text-wrap">{e.column.caption}</p>
        }
    }
    function cstmCellRender(e) {
        if (e.column.name === "name") {
            return (
                <Link to={`/product/detail/${e.data?.id}`}
                    target={'_blank'}
                    rel='noreferrer noopener'
                >
                    <span className="text-wrap">{e.value}</span>
                </Link>
            )
        }
        else if (e.column.name === 'list_field_test') {
            return (
                <div>
                    {
                        [...(e.value || [])].map((d, i) => (
                            <span className="d-block text-wrap p-2">{d?.year} : {parseFloat(d.price, 0).toFixed(5)}</span>
                        ))
                    }
                    <span className="d-block text-wrap p-2">Total : {parseFloat(e.data?.field_test_total, 0).toFixed(5)}</span>
                </div>
            )
        }
        else if (e.column.name === 'list_commercial') {
            return (
                <div>
                    {
                        [...(e.value || [])].map((d, i) => (
                            <span className="d-block text-wrap p-2">{d?.year} : {parseFloat(d?.price, 0).toFixed(5)}</span>
                        ))
                    }
                    <span className="d-block text-wrap p-2">Total : {parseFloat(e.data?.commercial_total, 0).toFixed(5)}</span>
                </div>
            )
        }
        else if (e.column.name === 'list_contribution') {
            return (
                <div>
                    {
                        [...(e.value || [])].map((d, i) => (
                            <span className="d-block text-wrap p-2">{d?.year} : {parseFloat(d?.price, 0).toFixed(5)}</span>
                        ))
                    }
                    <span className="d-block text-wrap p-2">Total : {parseFloat(e.data?.price, 0).toFixed(5)}</span>
                </div>
            )
        }
        else if (e.column.name === 'field_test_total' ||
            e.column.name === 'commercial_total' ||
            e.column.name === 'price'
        ) {
            return (
                <span className="d-block text-wrap p-2">{parseFloat(e?.value, 0).toFixed(5)}</span>
            )
        }
        else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded customShadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Clothes/Briefcase.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Product</span>
                                    <span className="d-block"><strong>{state.HeaderTotal || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Upstream Tech</span>
                                    <span className="d-block"><strong>{state.HeaderUpstream || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Material and Speciality Chemical</span>
                                    <span className="d-block"><strong>{state.HeaderMaterial || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Petroleum Fuel</span>
                                    <span className="d-block"><strong>{state.HeaderPetroleum || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Petrochemical and non-fuel</span>
                                    <span className="d-block"><strong>{state.HeaderPetrochemical | 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NRE Fuel</span>
                                    <span className="d-block"><strong>{state.HeaderNREF || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NRE Power</span>
                                    <span className="d-block"><strong>{state.HeaderNREP || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Laboratory Service</span>
                                    <span className="d-block"><strong>{state.HeaderLab || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Others</span>
                                    <span className="d-block"><strong>{state.HeaderOther || 0}</strong></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12 ">
                            {
                                reporting === true && (
                                    <GridDx
                                        className='custom-style-master'
                                        masterDetail={reporting === false ? true : false}
                                        filenameExport={`Product Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        repaintChangesOnly={true}
                                        selectionMode={reporting ? "multiple" : false}
                                        exportMode="selection"
                                        exportExcel={reporting ? true : false}
                                        settingColumn={reporting ? true : false}
                                        ComponenMasterDetail={ProductGridDetail}
                                        toolbar={
                                            <select className='form-control form-control-sm'
                                                value={filter}
                                                onChange={(e) => getDataYear(e.target.value)}
                                            >
                                                {
                                                    (dataReport?.data_tahun || [])?.map((d, i) => (
                                                        <option key={i} value={d}>{d}</option>
                                                    ))
                                                }
                                            </select>
                                        }
                                        headerCellRender={headerCellRender}
                                        searchForm={true}
                                        ref={tableRef}
                                        loading={state.loadingGet}
                                        data={dataReport?.data_tabel}
                                        cellRender={cstmCellRender}
                                        columns={column_report}
                                        withAction={false}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                    >
                                    </GridDx>
                                )
                            }
                            {
                                reporting === false && (
                                    <GridDx
                                        height={600}
                                        className='custom-style-master'
                                        masterDetail={reporting === false ? true : false}
                                        filenameExport={`Product Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        repaintChangesOnly={true}
                                        selectionMode={reporting ? "multiple" : false}
                                        exportMode="selection"
                                        exportExcel={reporting ? true : false}
                                        settingColumn={reporting ? true : false}
                                        ComponenMasterDetail={ProductGridDetail}
                                        searchForm={true}
                                        ref={tableRef}
                                        loading={state.loadingGet}
                                        data={data}
                                        cellRender={cstmCellRender}
                                        columns={column}
                                        withAction={false}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                    >
                                    </GridDx>
                                )
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default TabProductList;