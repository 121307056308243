import React, { memo, useEffect, useState } from 'react';
import { Tab, TabContent, HeaderList, HeaderProject, Modal, StopProjectQuest, StopProjectAndAction } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import TabRiskMon from './tabRiskMon'
import TabDetailMon from './tabDetailMon'
import TabTimelineMon from './tabTimelineMon'
import TabBudgetMon from './tabBudgetMon'
import TabAchievement from './tabAchievement'
import TabLoogBook from './tabLoogBook'
import Card from 'react-bootstrap/Card'
import './monitoringViewEdit.css';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import ModalContinueProject from './modalContinueProject';
import moment from 'moment';
import ModalContinueReqRevision from './modalContinueReqRevision';
import ModalStopProjectReject from './modalStopProjectReject';
import { toInteger, toLower } from 'lodash';


export default memo(function (props) {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState("detail")
    const param = useParams()
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))
    const [komentarOngoing, setKomentarOngoing] = useState('');
    const [stopProject, setstopProject] = useState('');

    useEffect(() => {
        if (param.mode) {
            if (param.mode !== "view" && param.mode !== "edit") {
                history.push("/error-page")
            }
            action.setFilter("disable", param.mode === "view" ? true : false)
            action.setFilter("mode", param.mode)
            action.setFilter("id", param.id)
        }
    }, [])

    useEffect(() => {
        if (state.modal.modalRevisionOngoing === true) {
            setKomentarOngoing('');
        }
    }, [state.modal.modalRevisionOngoing])
    useEffect(() => {
        if (state.data_view_header?.workflow_state_id === 45 && toLower(param.mode) === "edit") {
            // if (state.data_view_header?.is_continue === 1 &&
            //     toInteger(moment().format('D')) > toInteger(state?.data_cut_off_date) &&
            //     state?.data_year_month === `01/${moment().format('YYYY')}`) {
            //     action.setFilter("disable", true)
            // } else {
            //     action.setFilter("disable", false);
            // }
            if (state.data_view_header?.is_continue === 2) {
                action.setFilter("disable", false);
            } else {
                if (moment().format('M').toString() === '1' && toInteger(moment().format('D')) < toInteger(state?.data_cut_off_date)) {
                    action.setFilter("disable", false)
                } else {
                    action.setFilter("disable", true)
                }
            }
        } else {
            action.setFilter("disable", true);
        }
    }, [state.data_view_header]);
    function renderButtonContinue() {
        if (state.data_view_header?.is_continue === 2) {
            return false;
        } else {
            if (moment().format('M').toString() === '1' && toInteger(moment().format('D')) < toInteger(state?.data_cut_off_date)) {
                return false;
            } else {
                return true;
            }
        }
    }
    function toNeedRevision() {
        if ((komentarOngoing || '') === '') {
            return Swal.fire({
                title: 'Warning',
                html: "Komentar tidak boleh kosong",
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-orange mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-orange text-white customShadow mb-5"></i></div>' +
                '<strong>Revision ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/project-ongoing-crud/submit-need-revision?id=${state.data_view_header?.id}&comment=${komentarOngoing}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.toggleModal('modalRevisionOngoing')
                        action.resetForm();
                        history.push('/admin/update/ProjectMonitoring')
                    }
                })
                return true;
            } else {
                action.toggleModal('modalRevisionOngoing')
                return true;
            }
        })
    }
    function approveFinish() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Finish ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/submit-finished-request-approve?id=" + state.data_view_header?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function rejectFinish(comment) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Reject Finish ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/project-ongoing-crud/submit-finished-request-reject?id=${state.data_view_header?.id}&comment=${comment}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function approveAbort() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Abort ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/submit-aborted-request-approve?id=" + state.data_view_header?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function rejectAbort(comment) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Reject Abort ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/project-ongoing-crud/submit-aborted-request-reject?id=${state.data_view_header?.id}&comment=${comment}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function submitContinueReq(val) {
        const data = {
            "id": state.data_view_header?.id,
            "research_id": state.data_view_header?.id,
            "document": val?.document || [],
            "document_delete": val?.document_delete || [],
            "year": '',
            "is_final_report": true,
            "cover_file_name": val?.cover?.cover_file_name || '',
            "cover_file_location": val?.cover?.cover_file_location || '',
            "reason": val?.reason
        }
        action.postData(
            {
                url: `/api/project-ongoing-crud/submit-continue-request?workflow_state_id=${state.data_view_header?.workflow_state_id}`,
                data: data,
                withFile: false,
            }
        ).then((resp) => {
            if (resp.code === '00') {
                window.location.reload();
            }
        })
    }
    function approveContinueReq() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Continue Request ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/submit-continue-request-approve?id=" + state.data_view_header?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/approval/project')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function onContinueReqRevision(reason) {
        action.postData(
            {
                url: `/api/project-ongoing-crud/submit-continue-request-need-revision?id=${state.data_view_header?.id}&comment=${reason}`,
                withFile: false,
            }
        ).then((resp) => {
            if (resp.code === '00') {
                action.resetForm();
                history.push('/admin/approval/project')
            }
        })
    }
    function rejectStopClick(stopProject) {
        setstopProject(stopProject);
        action.toggleModal('modalStopReject')
    }
    function rejectStopReject(val) {
        if (stopProject === 'Abort Request') {
            rejectAbort(val)
        }
        if (stopProject === 'Finish Request') {
            rejectFinish(val)
        }
    }

    return (
        <div className="pb-5">
            <Modal
                title="Continue Request Need Revision"
                name="modalProjectContinueRevision"
                baseColor={true}
            >
                <ModalContinueReqRevision onRevison={onContinueReqRevision} />
            </Modal>
            <Modal
                name="modalStopProject"
                baseColor={true}
                size='700px'
            >
                <StopProjectQuest onClickBatal={() => action.toggleModal('modalStopProject')} onClickLanjut={() => {
                    action.toggleModal('modalStopProject');
                    action.toggleModal('modalStopProjectForm');
                }} />
            </Modal>
            <Modal
                name="modalStopProjectForm"
                baseColor={true}
            >
                <StopProjectAndAction id={state.data_view_header?.id} data_actual_timeline={state.data_view_header?.actual_timeline} data_year_form={state.data_year_form} />
            </Modal>
            <Modal
                name="modalContinueProject"
                baseColor={true}
            >
                <ModalContinueProject onSubmit={submitContinueReq} workflow_state_name={state.data_view_header?.workflow_state_name} id_research={state?.data_view_header?.id} />
            </Modal>
            <Modal
                name="modalStopReject"
                baseColor={true}
            >
                <ModalStopProjectReject stopProject={stopProject} rejectOnclick={rejectStopReject} />
            </Modal>
            < Modal
                name="modalRevisionOngoing"
                baseColor={true}
            >
                <h5>Revisi Project</h5>
                <hr />
                <div className="row">
                    <div className="col-md-3">
                        Komentar
                    </div>
                    <div className="col-md-9">
                        <textarea className="form-control form-control-sm" value={komentarOngoing} onChange={(e) => setKomentarOngoing(e.target.value)}></textarea>
                    </div>
                </div>
                <hr />
                <div className="text-right">
                    <button className="btn btn-sm btn-secondary mx-1" onClick={() => action.toggleModal('modalRevisionOngoing')}>Batal</button>
                    <button className="btn btn-sm btn-warning mx-1" onClick={() => toNeedRevision()}>Revisi</button>
                </div>
            </Modal>
            <HeaderList
                title={state.formData?.mon?.page_title}
                someText={state.formData?.mon?.page_breadcrumbs}
            >
            </HeaderList>
            <HeaderProject
                workflow_stage="Project Monitoring"
                rightMenu={true}
                dataHeader={state.data_view_header}
                dataReport={state.data_reports}
                view_admin={history.location.state?.view_admin}
                history_onClick={({ id, baseline_name }) => history.push({
                    pathname: '/Projects/ResearchHistories/' + id,
                })}
                monthly_onClick={({ id, baseline_name }) => history.push({
                    pathname: '/reportResearch/index/' + id,
                })}
                profile_onClick={({ id, baseline_name }) => history.push({
                    pathname: '/research/project-profile/' + id + "/" + baseline_name,
                })
                }
            />
            {
                (state.data_view_header?.workflow_state_name === 'monitoring_finished_request' || state.data_view_header?.workflow_state_name === 'monitoring_aborted_request' || state.data_view_header?.workflow_state_name === 'monitoring_continue_request') && (
                    <Card
                        bg="warning"
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div className="row m-5 text-white">
                                <div className="col-md">
                                    <span>
                                        {
                                            state.data_view_header?.workflow_state_name === 'monitoring_finished_request' ? 'Need to Finish : ' :
                                                state.data_view_header?.workflow_state_name === 'monitoring_aborted_request' ? 'Need to Abort : ' : ''
                                        }
                                    </span>
                                </div>
                                <div className="col-md-10">
                                    <span className="d-block text-wrap">{state.data_reason}</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )
            }
            <Card
                className="m-0 p-0 mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "detail",
                                title: "Project Detail",
                                onClick: (e) => setSelectedTab("detail")
                            },
                            ...(state.data_view_header?.project_type !== 5 ? [
                                {
                                    id: "risk",
                                    title: "Project Risk",
                                    onClick: (e) => setSelectedTab("risk")
                                },
                            ] : []),
                            {
                                id: "timeline",
                                title: "Project Timeline",
                                onClick: (e) => setSelectedTab("timeline")
                            },
                            {
                                id: "budget",
                                title: "Drawdown Budget",
                                onClick: (e) => setSelectedTab("budget")
                            }, {
                                id: "achievement",
                                title: "Achievement & Concern",
                                onClick: (e) => setSelectedTab("achievement")
                            },
                            {
                                id: "logbook",
                                title: "Log Book",
                                onClick: (e) => setSelectedTab("logbook")
                            }
                        ]}
                        manualActive={selectedTab}
                    >
                        <TabContent id="detail">
                            <TabDetailMon param={param} />
                        </TabContent>
                        <TabContent id="risk">
                            <TabRiskMon param={param} />
                        </TabContent>
                        <TabContent id="timeline">
                            <TabTimelineMon param={param} />
                        </TabContent>
                        <TabContent id="budget">
                            <TabBudgetMon param={param} />
                        </TabContent>
                        <TabContent id="achievement">
                            <TabAchievement param={param} />
                        </TabContent>
                        <TabContent id="logbook">
                            <TabLoogBook param={param} />
                        </TabContent>
                    </Tab>

                </Card.Body>
            </Card>
            {
                state.filterData.disable === false && state.isLoading === false && (
                    <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                        <div className="row justify-content-center">
                            <div className="col-md-6 mb-md-0 mb-5">
                                <button className='btn btn-sm btn-secondary'
                                    onClick={() => history.goBack()}
                                >Kembali</button>
                            </div>
                            <div className="col-md-6 mb-md-0 mb-5 text-md-right" style={{ minHeight: '33px' }}>
                                {
                                    (state.data_view_header?.workflow_state_name === 'monitoring') && (
                                        <>
                                            {
                                                path === 'admin' && (
                                                    <button
                                                        onClick={() => action.toggleModal('modalRevisionOngoing')}
                                                        className="btn btn-sm btn-warning text-dark mx-1">
                                                        {/* <i className="fab fa-telegram-plane icon-nm"></i> */}
                                                        Submit To Need Revision
                                                    </button>
                                                )
                                            }
                                            <button
                                                onClick={() => action.toggleModal("modalStopProject")}
                                                className="btn btn-sm btn-outline-danger mx-1">
                                                <i className="fa fa-times icon-nm"></i>
                                                Stop Project
                                            </button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                state.filterData.disable === true && state.isLoading === false &&
                (
                    <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                        <div className="row justify-content-center">
                            <div className="col-md-6 mb-md-0 mb-5">
                                <button className='btn btn-sm btn-secondary'
                                    onClick={() => history.goBack()}
                                >Kembali</button>
                            </div>
                            <div className="col-md-6 mb-md-0 mb-5 text-md-right" style={{ minHeight: '33px' }}>
                                {
                                    renderButtonContinue() &&// (state?.data_view_header?.workflow_state_id === 45 && state.data_view_header?.is_continue == 1 && toInteger(moment().format('D')) > toInteger(state?.data_cut_off_date) && state?.data_year_month === `01/${moment().format('YYYY')}`) && 
                                    (
                                        <button className="btn btn-outline-primary btn-sm mr-1"
                                            onClick={() => action.toggleModal('modalContinueProject')}
                                        >
                                            <i className="far fa-arrow-alt-circle-right icon-nm"></i> Continue Project
                                        </button>
                                    )
                                }
                                {
                                    path === 'admin' && state.data_view_header?.workflow_state_name === 'monitoring_aborted_request' && (
                                        <>
                                            <button className="btn btn-sm btn-success mx-1" onClick={() => approveAbort()}>Approve Abort</button>
                                            <button className="btn btn-sm btn-danger mx-1" onClick={() => rejectStopClick('Abort Request')}>Reject Abort</button>
                                        </>
                                    )
                                }
                                {
                                    path === 'admin' && state.data_view_header?.workflow_state_name === 'monitoring_finished_request' && (
                                        <>
                                            <button className="btn btn-sm btn-success mx-1" onClick={() => approveFinish()}>Approve Finish</button>
                                            <button className="btn btn-sm btn-danger mx-1" onClick={() => rejectStopClick('Finish Request')}>Reject Finish</button>
                                        </>
                                    )

                                }
                                {
                                    path === 'admin' && state.data_view_header?.workflow_state_name === 'monitoring_continue_request' && (
                                        <>
                                            <button className="btn btn-sm btn-success mx-1" onClick={() => approveContinueReq()}>Approve Continue Request</button>
                                            <button className="btn btn-sm btn-warning mx-1" onClick={() => action.toggleModal('modalProjectContinueRevision')}>Continue Request Need Revision</button>
                                        </>
                                    )

                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
})