import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import useFetch from '../../../modules/useFetch';

const dLeadContext = createContext({
    dLeadAction: {},
    dLeadState: {}
})

export function DetailLeaderProvider({ children }) {
    const { doGet } = useFetch();
    const [data, setData] = useState([]);
    const [data_page, setData_page] = useState({});
    const [func_id, setFunc_id] = useState('');
    const param = useParams();
    const { tahunQuery } = param;

    async function getData(func_id = '', tahunQuery = '') {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        try {
            const resp = await doGet({ url: `api/project-ongoing/tabel-dashboard-project-profile-chart12?func_id=${func_id}&tahunQuery=${tahunQuery}` });
            if (resp.code === "00") {
                setData(resp.data);
                setData_page(resp.data_page);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: resp?.message,
                    icon: 'error'
                });
            }
        } catch (error) {
            Swal.fire('error', error.toString(), 'error')
        }
        Swal.close();
    }
    function fungsiChange(val) {
        setFunc_id(val);
        getData(val, tahunQuery);
    }
    useEffect(() => {
        getData(func_id, tahunQuery);
    }, [])
    return <dLeadContext.Provider
        value={{
            dLeadAction: {
                fungsiChange
            },
            dLeadState: {
                data,
                func_id,
                data_page
            }
        }}
    >{children}</dLeadContext.Provider>
}

export default function useDetailLeader() {
    return useContext(dLeadContext);
}