import React, { createContext, useContext, useState } from "react";
import { useGlobalState } from "../../contexts/useGlobalState";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { faq_status_filter } from "../../components/utility/utility";
import useFetch from "../../modules/useFetch";

const contextSetApp = createContext({
    setAppAction: {},
    setAppState: {}
})
const columns_faq = [
    { fieldName: "category_name", caption: "Category", width: 200 },
    { fieldName: "question", caption: "Question", width: 300 },
    { fieldName: "answer", caption: "Answer", minWidth: 300 },
    { fieldName: "status", caption: "Status", minWidth: 300, headerFilterSource: faq_status_filter },
]
const columns_about = [
    { fieldName: "date", caption: "Date", width: 200 },
    { fieldName: "version", caption: "Version", width: 300 },
    { fieldName: "update", caption: "update", minWidth: 300 },
]
export function SettingAppProvider({ children }) {
    const [state, dispatch, action] = useGlobalState();
    const [data_page, setData_page] = useState({});

    const [modeFaq, setModeFaq] = useState('Add');
    const [dataEditFaq, setDataEditFaq] = useState({});
    const [modeAb, setModeAb] = useState('Add');
    const [dataEditAb, setDataEditAb] = useState({});

    const [loadingFaq, setLoadingFaq] = useState({});
    const [dataFaq, setDataFaq] = useState({});
    const [loadingAb, setLoadingAb] = useState({});
    const [dataAb, setDataAb] = useState({});

    const { doGet, doPost, doPostMultiple } = useFetch();
    const [loadingWa, setLoadingWa] = useState(false);
    const [dataViewWa, setDataViewWa] = useState({});
    const [clearWa, setClearWa] = useState(false);
    async function doSendWa(data = {}) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `/api/setting/add-wa-broadcast`,
                data: {
                    ...data
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => { setClearWa(true) })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getMasterFaq() {
        try {
            setLoadingFaq(true);
            const resp = await action.getData("/api/Faq/master-faq", {
            }, null, null, true);
            if (resp?.code === "00") {
                setDataFaq(resp?.data);
                setData_page(resp?.data_page);
            }
            setLoadingFaq(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function getMasterAbout() {
        try {
            setLoadingAb(true);
            const resp = await action.getData("/api/Faq/master-faq", {
            }, null, null, true);
            if (resp?.code === "00") {
                setDataAb(resp?.data);
                setData_page(resp?.data_page);
            }
            setLoadingAb(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function getMasterWa() {
        setLoadingWa(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/setting/view-wa-broadcast',
            });
            if (resp?.code === '00') {
                setDataViewWa(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoadingWa(false);
    }
    function onSendWa(data = {}) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Kirim Pesan?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const val = validation(data);
                if (!val.isValid) {
                    return Swal.fire({
                        title: 'Error!',
                        text: val.message,
                        icon: 'error',
                    })
                } else {
                    doSendWa(data);
                }
                return true;
            } else {
                return true;
            }
        })
    }
    function validation(data = {}) {
        var message = ""
        var isValid = true;
        if ([...(data?.listUserPersonal || [])].length < 1 &&
            [...(data?.listGroupRole || [])].length < 1 &&
            [...(data?.listGroupFunction || [])].length < 1) {
            isValid = false;
            message = "Silahkan pilih penerima broadcast"
        } else if (data?.message === "") {
            message = "Pesan tidak boleh kosong";
            isValid = false;
        }
        return {
            message,
            isValid
        }
    }
    function openModalKategori() {
        action.toggleModal("modalKategory")
    }
    function addFAQ() {
        setModeFaq("Add")
        action.toggleModal("masterFaq")
    }
    function editFAQ(data) {
        setModeFaq("Edit")
        setDataEditFaq(data)
        action.toggleModal("masterFaq")
    }
    function deleteFAQ(data) {
        const d = {
            "id": data?.id,
            "category": '',
            "question": '',
            "answer": '',
            "status": '',
            "created_at": ''
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete FAQ ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/Faq/delete-faq`,
                        data: d
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        getMasterFaq();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const customRenderFAQ = (e) => {
        if (e.column.name === "status") {
            return (
                <span className="text-wrap">{faq_status_filter.find(d => toString(d.value) === toString(e.value))?.text}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function addAbout() {
        setModeAb("Add")
        action.toggleModal("modalabout")
    }
    function editAbout(data) {
        setModeAb("Edit")
        setDataEditAb(data)
        action.toggleModal("modalabout")
    }
    return <contextSetApp.Provider
        value={{
            setAppAction: {
                getMasterFaq,
                getMasterAbout,
                getMasterWa,
                addFAQ,
                editFAQ,
                deleteFAQ,
                customRenderFAQ,
                openModalKategori,
                addAbout,
                editAbout,
                onSendWa
            },
            setAppState: {
                data_page,
                dataFaq,
                dataAb,
                loadingFaq,
                loadingAb,
                modeFaq,
                modeAb,
                dataEditFaq,
                dataEditAb,
                columns_faq,
                columns_about,
                dataViewWa,
                clearWa

            }
        }}
    >{children}</contextSetApp.Provider>
}
export default function useSettingApplication() {
    return useContext(contextSetApp)
}