import { Autocomplete } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import useHighlightDash from './useHighlightDashboard';
import Swal from 'sweetalert2';
function ModalActivity({ data = {}, mode = 'Add' }) {
    const { hdAction, hdState } = useHighlightDash();
    const [id_activity, setId_activity] = useState('');
    const [title, setTitle] = useState('');
    const [activity_related_project, setActivity_related_project] = useState([]);
    const [activity_related_project_delete, setActivity_related_project_delete] = useState([]);
    const [tempRKAP, setTempRKAP] = useState({});
    const [tempRKAP_name, setTempRKAP_name] = useState('');

    useEffect(() => {
        if (hdState.globalState.modal?.modalActivity) {
            resetEditorActivity();
            setEditorActivity();
        }
    }, [hdState.globalState.modal?.modalActivity])

    function resetEditorActivity() {
        setId_activity('');
        setTitle('');
        setTempRKAP({});
        setTempRKAP_name('');
        setActivity_related_project([]);
        setActivity_related_project_delete([]);
    }
    function setEditorActivity() {
        setId_activity(data?.id || '');
        setTitle(data?.title || '');
        setActivity_related_project([...(data?.activity_related_project || [])].map(d => {
            return {
                ...d,
                is_new: false
            }
        }));
    }
    function addActivity(status = '', data) {
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Aktifitas?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                hdAction.postCreateActivity(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function updateActivity(status = '', data) {
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Update Aktifitas?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                hdAction.postUpdateActivity(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function createActivity(status = '') {
        const data = {
            "id": id_activity,
            "title": title || '',
            "status": status,
            "creator": '',
            "editor": hdState.globalState.dataUser?.id,
            "keterangan": "",
            "created_at": "",
            "updated_at": "",
            "activity_related_project": activity_related_project || [],
            "activity_related_project_delete": activity_related_project_delete || []
        }
        if (mode === 'Add') {
            addActivity(status, data);
        }
        if (mode === 'Edit') {
            updateActivity(status, data);
        }

    }
    function deleteRelated(rel) {
        const t = activity_related_project || [];
        if (rel) {
            if (rel?.is_new === false) {
                setActivity_related_project_delete([rel, ...activity_related_project_delete])
            }
            setActivity_related_project(t.filter(d => d?.research_id?.toString() !== rel?.research_id?.toString()));
        }
    }
    function addrelated(rel) {
        const data = {
            "id": '',
            "activity_id": id_activity,
            "title": rel?.title,
            "research_id": rel?.related_id,
            "is_new": true
        }
        const t = activity_related_project || [];
        if (rel?.related_id && !t.find(d => {
            return d.research_id === data?.research_id
        })) {
            setTempRKAP_name('');
            setTempRKAP({});
            setActivity_related_project([data, ...t]);
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        if (data?.title === '') {
            isValid = false;
            message = [...message, "Judul"]
        }
        if ([...(data?.activity_related_project || [])].length === 0) {
            isValid = false;
            message = [...message, "Proyek berkaitan"]
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }
    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col-3">
                    <span className="text-wrap">Judul</span>
                </div>
                <div className="col-9">
                    <input className="form-control form-control-sm" placeholder='Judul Tindak Lanjut Arahan Direksi'
                        onChange={(e) => setTitle(e.target.value)} value={title}
                    ></input>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-3">
                    <span className="text-wrap">Proyek Berkaitan</span>
                </div>
                <div className="col-9">
                    <div className="input-group input-group-sm mb-3">
                        <Autocomplete
                            dataSource={hdState.dataRelated}
                            value={tempRKAP_name}
                            valueExpr="title"
                            displayExpr="title"
                            placeholder="Ketik nama proyek dan tekan tombol '+ Add'"
                            onValueChanged={(e) => setTempRKAP_name(e.value)}
                            onSelectionChanged={(e) => setTempRKAP(e.selectedItem)}
                            className="form-control form-control-sm p-0 mr-1">
                        </Autocomplete>
                        <button className="btn btn-sm btn-outline-primary ml-1"
                            onClick={(e) => addrelated(tempRKAP)}
                        >+ Add</button>
                    </div>
                    <div>
                        {
                            [...(activity_related_project || [])].map((d, i) => (
                                <div className="row mb-2" key={i}>
                                    <div className="col-md-10">
                                        <span className="d-block text-left mb-3">
                                            <i className="fa fa-circle icon-sm text-dark pr-3"></i>
                                            {d.title}
                                        </span>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn btn-sm btn-outline-danger" onClick={() => deleteRelated(d)}>
                                            <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <hr />
            <div className="text-md-right">
                <button className="btn btn-sm btn-secondary mx-1"
                    onClick={() => hdAction.globalAction.toggleModal('modalActivity')}
                >
                    Batal
                </button>
                {/* <button className="btn btn-sm btn-outline-primary mx-1"
                    onClick={() => createActivity('Open')}
                >
                    Draft
                </button> */}
                <button className="btn btn-sm btn-primary mx-1"
                    onClick={() => createActivity('Open')}
                >
                    <i className="fa fa-save icon-nm"></i>
                    Simpan
                </button>
            </div>
        </React.Fragment>
    );
};

export default ModalActivity;