import React, { useContext, useEffect, useState } from 'react';
import { Card, Tab, Row, Col, Nav, Accordion, useAccordionToggle } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { HeaderList } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import './faq.css'

function FaqPage(props) {
    const [, , action] = useGlobalState();
    const [dataFaq, setDataFaq] = useState({});
    const [data_page, setdata_page] = useState({});

    useEffect(() => {
        getData();
    }, []);
    function getData() {
        action.getData(`/api/Faq/get`, {}, null, null, true).then((resp) => {
            if(resp.code === '00'){
                setDataFaq(resp.data);
                setdata_page(resp.data_page);
            }
        })
    }
    function CustomToggle({ title, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <span className="d-block" onClick={decoratedOnClick}>
                <i className={`text-primary mx-3 ` + (isCurrentEventKey ? `fas fa-angle-double-down` : `fas fa-angle-double-right`)}></i>
                <b>{title}</b>
            </span>
        );
    }

    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            />
            <Card
                className="p-0 customShadow"
            >
                <Card.Body className="p-5">
                    <h5>Frequently Ask Question</h5>
                    <p className="text-dark-50">Pertanyaan yang sering diajukan</p>
                    <hr />

                    <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
                        <Row className="mx-3">
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    {
                                        [...(dataFaq?.faq_category || [])].map((d, i) => (
                                            <Nav.Item key={i}>
                                                <Nav.Link eventKey={d?.id}>{d?.name}</Nav.Link>
                                            </Nav.Item>
                                        ))
                                    }
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    {
                                        [...(dataFaq?.faq || [])].map((d, i) => (
                                            <Tab.Pane eventKey={d?.category}>
                                                <Accordion defaultActiveKey="0" className="accordion-light accordion-light-borderless" >
                                                    <Card>
                                                        <Card.Header>
                                                            <CustomToggle eventKey={d?.id} title={d?.question}/>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey={d?.id}>
                                                            <Card.Body className="my-3 cardMargin">
                                                                {
                                                                    d?.answer
                                                                }
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </Tab.Pane>
                                        ))
                                    }
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Card.Body>
            </Card>
        </>

    );
}

export default FaqPage;