import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { cut_off_option, month_source } from '../../../components/utility/utility';
import useSettingParameter from '../useSettingParameter';
import moment from 'moment';
import { parseInt, toUpper } from 'lodash';
import { NumberBox } from 'devextreme-react';

function TabCutoff(props) {
    const { setParAction, setParState } = useSettingParameter();
    useEffect(() => {
        setParAction.getDataCutOff();
        setParAction.setIsEditingCO(false);
    }, [])
    return (
        <div>
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Header>
                    <span className="d-block" style={{ fontWeight: 'bold' }}>Cut Off Date</span>
                    <span className="d-block" style={{ color: '#C6C6C6' }}>Setting Cut Off Date</span>
                </Card.Header>
                <Card.Body className="p-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="row my-5">
                                <div className="col-md-3">
                                    Batasan Tanggal
                                </div>
                                <div className="col-md">
                                    {
                                        setParState.isEditingCO === false ? (
                                            <div className="p-2 bg-light-primary text-white text-center rounded text-dark" style={{ width: '50px' }}>
                                                {setParState.cut_off}
                                            </div>
                                        ) : (
                                            <>
                                                <div className="input-group input-group-sm mb-3">
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-secondary" disabled={setParState.cut_off === 1 ? true : false} type="button" onClick={() => setParAction.minCutoff()}>-</button>
                                                    </div>
                                                    <NumberBox min={1} value={setParState.cut_off} onValueChanged={(e) => setParAction.setCut_off(e.value)} max={31} />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-secondary" type="button" disabled={setParState.cut_off === 31 ? true : false} onClick={() => setParAction.plusCutoff()}>+</button>
                                                    </div>
                                                </div>
                                                {/* <select className="form-control form-control-sm"
                                                value={setParState.cut_off}
                                                onChange={(e) => setParAction.setCut_off(e.target.value)}
                                            >
                                                {
                                                    cut_off_option()?.map((d, i) => (
                                                        <option key={i} value={d.value}>{d.name}</option>
                                                    ))
                                                }
                                            </select> */}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    Pratinjau
                                </div>
                                <div className="col-md">
                                    <span className="d-block p-3 text-center bg-light-primary text-dark rounded">
                                        <i className="fa fa-calendar-alt icon-sm text-primary mx-3"></i>
                                        Pengisian realisasi bulan {toUpper(moment().format("MMM"))} {moment().format('YYYY')} dapat dilakukan s.d. tanggal &nbsp;
                                        {
                                            setParState.cut_off
                                        }
                                        &nbsp;{toUpper(moment().add(1, 'month').format('MMM'))}
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div className="d-block text-right">
                                {
                                    setParState.isEditingCO === false ? (
                                        <button className="btn btn-sm btn-primary m-1"
                                            onClick={() => setParAction.setIsEditingCO(true)}
                                        >
                                            <i className="fa fa-pencil-alt text-white icon-sm"></i>
                                            Ubah
                                        </button>
                                    ) : (
                                        <>
                                            <button className="btn btn-sm btn-secondary m-1"
                                                onClick={() => setParAction.cancelCutoff()}
                                            >
                                                <i className="fa fa-undo icon-sm"></i>
                                                Kembali
                                            </button>
                                            <button className="btn btn-sm btn-primary m-1"
                                                onClick={() => setParAction.saveCutoff()}
                                            >
                                                <i className="fa fa-save text-white icon-sm"></i>
                                                Save
                                            </button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TabCutoff;