import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import useCrudPengujian from '../useCrudPengujian';
import moment from 'moment';
import Swal from 'sweetalert2';

function ModalUpdatetestingReport(props) {
    const { cpState, cpAction } = useCrudPengujian();
    const closeModal = () => cpAction.setModalTre({ show: false, default_data: {} });
    const [modalResume, setModalResume] = useState({ show: false, url: "" });
    const [note, setNote] = useState("");

    const publishOnclick = () => {
        if (note === "" || note === null || note === undefined) {
            return Swal.fire({
                title: 'Error',
                text: "Silahkan Isi Keterangan",
                icon: 'warning',
            })
        }
        cpAction.publishKlik({
            id: cpState.modalTre?.default_data?.id,
            note: note
        })
    }
    const needRevOnclick = () => {
        if (note === "" || note === null || note === undefined) {
            return Swal.fire({
                title: 'Error',
                text: "Silahkan Isi Keterangan",
                icon: 'warning',
            })
        }
        cpAction.spkNeedRevKlik({
            id: cpState.modalTre?.default_data?.id,
            note: note
        })
    }
    useEffect(() => {
        function setDefault() {
            setNote("");
        }
        if (cpState.modalTre.show) setDefault();
    }, [cpState.modalTre.show])
    return (
        <>
            <Modal size='xl' show={modalResume.show} onHide={() => setModalResume({ show: false, url: "" })}>
                <Modal.Body>
                    <iframe className='vh-100 w-100' src={modalResume.url} type="application/pdf">
                        <div>No online PDF viewer installed</div>
                    </iframe>
                </Modal.Body>
            </Modal>
            <Modal show={cpState.modalTre?.show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Dok. Kontrak Review
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm py-0"> Dokumen Kontrak</label>
                        <div className="col-sm-8">
                            <button onClick={() => setModalResume({ show: true, url: cpState.modalTre?.default_data?.file_location })} className='btn btn-icon btn-light-primary'>
                                <i className='fas fa-download'></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm py-0"> Dokumen PO</label>
                        <div className="col-sm-8">
                            <button onClick={() => setModalResume({ show: true, url: cpState.modalTre?.default_data?.file_location2 })} className='btn btn-icon btn-light-primary'>
                                <i className='fas fa-download'></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4">Tanggal Persetujuan</label>
                        <div className="col-sm-8">
                            <p>{moment().format("DD-MM-yyyy")}</p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan</label>
                        <div className="col-sm-8">
                            <textarea className='form-control form-control-sm' rows={3}
                                value={note} onChange={e => setNote(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => needRevOnclick()} className="btn btn-sm btn-warning mr-1">Dok. Kontrak Need Revision</button>
                    <button onClick={() => publishOnclick()} className="btn btn-sm btn-success mr-1">COA Published</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalUpdatetestingReport;