import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './index.css'
import SVG from "react-inlinesvg";
import moment from 'moment';
import { toInteger } from 'lodash';


export const badge_1 = function (workflow_stage) {
    var color = '';
    if (workflow_stage === "Project Ideation") {
        color = "badge badge-info text-left";
    }
    else if (workflow_stage === "Project Portfolio") {
        color = "badge badge-warning text-left";
    }
    else if (workflow_stage === "Project Monitoring") {
        color = "badge badge-success text-left";
    }
    else if (workflow_stage === "ABI Request" || workflow_stage === "ABI Monitoring") {
        color = "badge badge-primary text-left";
    }
    else {
        color = "badge badge-success text-left";
    }
    return color;

}


export const badge_1_num = function (workflow_stage) {
    var num = '';
    if (workflow_stage === "Project Ideation") {
        num = "1";
    }
    else if (workflow_stage === "Project Portfolio") {
        num = "2";
    }
    else if (workflow_stage === "Project Monitoring") {
        num = "3";
    }
    else if (workflow_stage === "ABI Request" || workflow_stage === "ABI Monitoring") {
        num = "A";
    }
    else {
        num = "F";
    }

    return num;
}

export const badge_1_text = function (workflow_stage) {
    var text = '';
    if (workflow_stage === "Project Ideation") {
        text = "Ideasi Riset: ";
    }
    else if (workflow_stage === "Project Portfolio") {
        text = "Penyusunan Evaluasi Portfolio :";
    }
    else if (workflow_stage === "Project Monitoring") {
        text = "Ongoing Research :";
    }
    else if (workflow_stage === "ABI Request") {
        text = "ABI Request";
    }
    else if (workflow_stage === "ABI Monitoring") {
        text = "ABI Monitoring";
    }
    else {
        text = "Project :";
    }
    return text;
}
export const badge_1_textproject = function (project_type_id) {
    var text = '';
    switch (project_type_id) {
        case 1:
            text = "Product Development";
            break;
        case 2:
            text = "Technology Selection";
            break;
        case 3:
            text = "Research Study";
            break;
        case 4:
            text = "Commercial";
            break;
        case 5:
            text = "Operational";
            break;
        default:
            text = "";
            break;

    }
    return text;
}

export const badge_2 = function (workflow_stage, state_name) {
    if (workflow_stage === "Project Ideation" || workflow_stage === "Project Portfolio" || workflow_stage === "Project Monitoring" || workflow_stage === "Project") {
        if (state_name === "ideation_draft") {
            return (
                <>
                    <span className="badge badge-metal badge-wide" style={{ color: 'white' }}>Ideation Draft</span>
                </>
            )
        }
        else if (state_name === "ideation_approve_2") {
            return (
                <>
                    <span className="badge badge-metal badge-wide" style={{ color: 'white' }}>Portfolio Draft</span>
                </>
            )
        }

        //info
        else if (state_name === "ideation_approve_1") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>Ideation Review</span>
                </>
            )
        }
        else if (state_name === "portfolio_waiting_approval") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>Portfolio Review</span>
                </>
            )
        }
        else if (state_name === "portfolio_new_approve_2") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>Approved to Implementation</span>
                </>
            )
        }
        else if (state_name === "monitoring_waiting_approval") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>Ongoing Need Revision Review</span>
                </>
            )
        }
        else if (state_name === "monitoring_continue_request") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>Ongoing Continue Request</span>
                </>
            )
        }

        //accent
        else if (state_name === "monitoring_finished_request") {
            return (
                <>
                    <span className="badge badge-accent badge-wide" style={{ color: 'white' }}>Request to Stop (Finished)</span>
                </>
            )
        }
        else if (state_name === "monitoring_aborted_request") {
            return (
                <>
                    <span className="badge badge-accent badge-wide" style={{ color: 'white' }}>Request to Stop (Aborted)</span>
                </>
            )
        }

        //warning
        else if (state_name === "ideation_approve_1_revise") {
            return (
                <>
                    <span className="badge badge-warning badge-wide" style={{ color: 'white' }}>Ideation Need Revision</span>
                </>
            )
        }
        else if (state_name === "portfolio_waiting_approval_revise") {
            return (
                <>
                    <span className="badge badge-warning badge-wide" style={{ color: 'white' }}>Portfolio Need Revision</span>
                </>
            )
        }
        else if (state_name === "monitoring_revise_portfolio") {
            return (
                <>
                    <span className="badge badge-warning badge-wide" style={{ color: 'white' }}>Ongoing Need Revision</span>
                </>
            )
        }
        else if (state_name === "monitoring_revise_upload") {
            return (
                <>
                    <span className="badge badge-warning badge-wide" style={{ color: 'white' }}>Ongoing Report Need Revision</span>
                </>
            )
        }

        //success
        else if (state_name === "monitoring") {
            return (
                <>
                    <span className="badge badge-success badge-wide" style={{ color: 'white' }}>Project Running</span>
                </>
            )
        }

        //danger
        else if (state_name === "ideation_approve_1_reject") {
            return (
                <>
                    <span className="badge badge-danger badge-wide" style={{ color: 'white' }}>Ideation Rejected</span>
                </>
            )
        }
        else if (state_name === "portfolio_waiting_approval_reject") {
            return (
                <>
                    <span className="badge badge-danger badge-wide" style={{ color: 'white' }}>Portfolio Rejected</span>
                </>
            )
        }

        //gold
        else if (state_name === "monitoring_finished") {
            return (
                <>
                    <span className="badge badge-info badge-wide bg-gold" style={{ color: 'white' }}>Finished</span>
                </>
            )
        }

        //black
        else if (state_name === "monitoring_aborted") {
            return (
                <>
                    <span className="badge badge-brand badge-wide bg-black" style={{ color: 'white' }}>Aborted</span>
                </>
            )
        }

        //light
        else {
            return (
                <>
                    <span className="badge badge-trans badge-wide" style={{ color: 'black' }}>-</span>
                </>
            )
        }
    }
    else {

        if (state_name === "draft") {
            return (
                <>
                    <span className="badge badge-metal badge-wide" style={{ color: 'white' }}>ABI Draft</span>
                </>
            )
        }

        //info
        else if (state_name === "waiting_approval") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>ABI Review</span>
                </>
            )
        }
        else if (state_name === "approved") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>ABI Approved to Implementation</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_need_rev_review") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>ABI Ongoing Need Revision Review</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_continue_req") {
            return (
                <>
                    <span className="badge badge-info badge-wide" style={{ color: 'white' }}>ABI Ongoing Continue Request</span>
                </>
            )
        }

        //accent
        else if (state_name === "abi_monitoring_finished_req") {
            return (
                <>
                    <span className="badge badge-accent badge-wide" style={{ color: 'white' }}>ABI Finished Request</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_aborted_req") {
            return (
                <>
                    <span className="badge badge-accent badge-wide" style={{ color: 'white' }}>ABI Aborted Request</span>
                </>
            )
        }

        //warning
        else if (state_name === "revised") {
            return (
                <>
                    <span className="badge badge-warning badge-wide" style={{ color: 'white' }}>ABI Need Revision</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_need_rev") {
            return (
                <>
                    <span className="badge badge-warning badge-wide" style={{ color: 'white' }}>ABI Ongoing Need Revision</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_revise_upload") {
            return (
                <>
                    <span className="badge badge-warning badge-wide" style={{ color: 'white' }}>ABI Ongoing Report Need Revision</span>
                </>
            )
        }

        //success
        else if (state_name === "abi_monitoring") {
            return (
                <>
                    <span className="badge badge-success badge-wide" style={{ color: 'white' }}>ABI Project Running</span>
                </>
            )
        }

        //danger
        else if (state_name === "rejected") {
            return (
                <>
                    <span className="badge badge-danger badge-wide" style={{ color: 'white' }}>ABI Rejected</span>
                </>
            )
        }

        //gold
        else if (state_name === "abi_monitoring_finished") {
            return (
                <>
                    <span className="badge badge-brand badge-wide bg-gold" style={{ color: 'white' }}>ABI Finished</span>
                </>
            )
        }

        //black
        else if (state_name === "abi_monitoring_aborted") {
            return (
                <>
                    <span className="badge badge-brand badge-wide bg-black" style={{ color: 'white' }}>ABI Aborted</span>
                </>
            )
        }

        //light
        else {
            return (
                <>
                    <span className="badge badge-trans badge-wide" style={{ color: 'black' }}></span>
                </>
            )
        }
    }
}
export const badge_2_light = function (workflow_stage, state_name, state_state) {
    if (workflow_stage === "Project Ideation" || workflow_stage === "Project Portfolio" || workflow_stage === "Project Monitoring" || workflow_stage === "Project") {
        if (state_name === "ideation_draft") {
            return (
                <>
                    <span className="label label-light-metal label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "ideation_approve_2") {
            return (
                <>
                    <span className="label label-light-metal label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //info
        else if (state_name === "ideation_approve_1") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "portfolio_waiting_approval") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "portfolio_new_approve_2") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "monitoring_waiting_approval") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "monitoring_continue_request") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //accent
        else if (state_name === "monitoring_finished_request") {
            return (
                <>
                    <span className="label label-light-accent label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "monitoring_aborted_request") {
            return (
                <>
                    <span className="label label-light-accent label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //warning
        else if (state_name === "ideation_approve_1_revise") {
            return (
                <>
                    <span className="label label-light-warning label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "portfolio_waiting_approval_revise") {
            return (
                <>
                    <span className="label label-light-warning label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "monitoring_revise_portfolio") {
            return (
                <>
                    <span className="label label-light-warning label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "monitoring_revise_upload") {
            return (
                <>
                    <span className="label label-light-warning label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //success
        else if (state_name === "monitoring") {
            return (
                <>
                    <span className="label label-light-success label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //danger
        else if (state_name === "ideation_approve_1_reject") {
            return (
                <>
                    <span className="label label-light-danger label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "portfolio_waiting_approval_reject") {
            return (
                <>
                    <span className="label label-light-danger label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //gold
        else if (state_name === "monitoring_finished") {
            return (
                <>
                    <span className="label label-light-info label-inline bg-gold h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //black
        else if (state_name === "monitoring_aborted") {
            return (
                <>
                    <span className="label label-light-brand label-inline bg-black h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //light
        else {
            return (
                <>
                    <span className="label label-light-trans label-inline h-auto text-center" style={{ color: 'black' }}>-</span>
                </>
            )
        }
    }
    else {

        if (state_name === "draft") {
            return (
                <>
                    <span className="label label-light-metal label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //info
        else if (state_name === "waiting_approval") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "approved") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_need_rev_review") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_continue_req") {
            return (
                <>
                    <span className="label label-light-info label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //accent
        else if (state_name === "abi_monitoring_finished_req") {
            return (
                <>
                    <span className="label label-light-accent label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_aborted_req") {
            return (
                <>
                    <span className="label label-light-accent label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //warning
        else if (state_name === "revised") {
            return (
                <>
                    <span className="label label-light-warning label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_need_rev") {
            return (
                <>
                    <span className="label label-light-warning label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }
        else if (state_name === "abi_monitoring_revise_upload") {
            return (
                <>
                    <span className="label label-light-warning label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //success
        else if (state_name === "abi_monitoring") {
            return (
                <>
                    <span className="label label-light-success label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //danger
        else if (state_name === "rejected") {
            return (
                <>
                    <span className="label label-light-danger label-inline h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //gold
        else if (state_name === "abi_monitoring_finished") {
            return (
                <>
                    <span className="label label-light-brand label-inline bg-gold h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //black
        else if (state_name === "abi_monitoring_aborted") {
            return (
                <>
                    <span className="label label-light-brand label-inline bg-black h-auto text-center" >{state_state}</span>
                </>
            )
        }

        //light
        else {
            return (
                <>
                    <span className="label label-light-trans label-inline h-auto text-center" style={{ color: 'black' }}></span>
                </>
            )
        }
    }
}

export const checkobox = function (workflow_stage, state_name) {
    if (workflow_stage === "Project Ideation") {
        if (state_name === "ideation_draft") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Ideation Draft</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Draft</span><br />
                </>
            )
        }
        else if (state_name === "ideation_approve_1") {
            return (
                <>

                    <span><i className="fa fa-check-circle c-green"></i> Ideation Draft</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ideation Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Draft</span><br />
                </>
            )
        }
        else if (state_name === "ideation_approve_1_revise") {
            return (
                <>

                    <span><i className="fa fa-check-circle c-green"></i> Ideation Draft</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ideation Review</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ideation Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Draft</span><br />
                </>
            )
        }
        else if (state_name === "ideation_approve_1_reject") {
            return (
                <>

                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Draft</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ideation Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Draft</span><br />
                </>
            )
        }
        else if (state_name === "ideation_approve_2") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Ideation Draft</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ideation Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Rejected</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Portfolio Draft</span><br />
                </>
            )
        }
        else {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Draft</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ideation Rejected</span><br />
                </>
            )
        }
    }
    else if (workflow_stage === "Project Portfolio") {
        if ("portfolio_waiting_approval") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Portfolio Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Approved to Implementation</span><br />
                </>
            )
        }
        else if ("portfolio_waiting_approval_revise") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Portfolio Review</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Portfolio Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Approved to Implementation</span><br />
                </>
            )
        }
        else if ("portfolio_waiting_approval_reject") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Portfolio Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Approved to Implementation</span><br />
                </>
            )
        }
        else if ("portfolio_new_approve_2") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Portfolio Review</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Portfolio Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Rejected</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Approved to Implementation</span><br />
                </>
            )
        }
        else {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Portfolio Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Approved to Implementation</span><br />
                </>
            )
        }
    }
    else if (workflow_stage === "Project Monitoring" || workflow_stage === "Project") {
        if (state_name === "monitoring") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_revise_portfolio") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_waiting_approval") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_continue_request") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_revise_upload") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_aborted_request") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_finished_request") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_aborted") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Aborted</span><br />
                </>
            )
        }
        else if (state_name === "monitoring_finished") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Finished</span><br />
                </>
            )
        }
        else {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> Project Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Revision Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Ongoing Report Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> Finished Request</span><br />
                </>
            )
        }
    }
    else if (workflow_stage === 'ABI Request') {
        if (state_name === "draft") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Draft</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Approved to Implementation</span><br />
                </>
            )
        }
        else if (state_name === "waiting_approval") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Draft</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Approved to Implementation</span><br />
                </>
            )
        }
        else if (state_name === "revised") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Draft</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Review</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Approved to Implementation</span><br />
                </>
            )
        }
        else if (state_name === "rejected") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Draft</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Approved to Implementation</span><br />
                </>
            )
        }
        else if (state_name === "approved") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Draft</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Rejected</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Approved to Implementation</span><br />
                </>
            )
        }
        else {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Draft</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Review</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Rejected</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Approved to Implementation</span><br />
                </>
            )
        }
    }
    else if (workflow_stage === 'ABI Monitoring' || workflow_stage === 'ABI') {
        if (state_name === "abi_monitoring") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_need_rev") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_need_rev_review") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_continue_req") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-green"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_revise_upload") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-green"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_aborted_req") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-green"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_finished_req") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-green"></i> ABI Finished Request</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_finished") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Finished</span><br />
                </>
            )
        }
        else if (state_name === "abi_monitoring_aborted") {
            return (
                <>
                    <span><i className="fa fa-check-circle c-green"></i> Aborted</span><br />
                </>
            )
        }
        else {
            return (
                <>
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Running</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Need Revision</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Revision Review</span><br />
                    {/* <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Continue Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Ongoing Report Need Revision</span><br /> */}
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Aborted Request</span><br />
                    <span><i className="fa fa-check-circle c-light-gray"></i> ABI Finished Request</span><br />
                </>
            )
        }
    }

}

export const button = function (workflow_stage, state_name, is_project_continued, id) {
    if (workflow_stage === 'Project Ideation') {
        switch (state_name) {
            case "ideation_waiting_approval":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/View/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search icon-nm la-flip-horizontal"></i> View</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "ideation_approve_1":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/View/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search icon-nm la-flip-horizontal"></i> View</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "ideation_waiting_approval_revise":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/Edit/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil icon-nm"></i> Edit Data</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "ideation_approve_1_revise":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/Edit/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil icon-nm"></i> Edit Data</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "ideation_waiting_approval_reject":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/View/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                            </Link>

                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "ideation_approve_1_reject":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/view/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Edit Data</a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <form method="POST" action="#" accept-charset="UTF-8" style={{ display: 'inline' }}>
                                {/* @Html.AntiForgeryToken() */}
                                <button type="submit" className="btn btn-outline-danger btn-sm mr-1 delete-project" title="Delete Research"><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                            </form>
                        </div>
                    </>
                )
                break;
        }
    }
    else if (workflow_stage === 'Project Portfolio') {
        switch (state_name) {
            case "portfolio_waiting_approval":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_1":
                return (
                    <>
                        <div>

                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>

                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "portfolio_waiting_approval_revise":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/edit/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Edit Data</a>
                            </Link>
                        </div>

                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_1_revise":
                return (
                    <>
                        <div>

                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Edit Data</a>

                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "portfolio_waiting_approval_reject":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_1_reject":
                return (
                    <>
                        <div>

                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_2":
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div>
                            {/* {
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1 mb-2"><i className="fa fa-pencil"></i> Fill Data</a>
                            } */}
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/edit/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Fill Data</a>
                            </Link>

                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                        </div>
                    </>
                )
                break;
        }
    }
    else if (workflow_stage === "Project Monitoring" || workflow_stage === "Project") {

        if (state_name === "monitoring" || state_name === "monitoring_revise_upload") {
            if (is_project_continued === 1) {
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Continue Project </a>
                        </div>


                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                            <a href="#" className="btn btn-outline-success btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project Logbook"><i className="fa fa-list"></i></a>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project Report"><i className="flaticon-file-2"></i></a>
                            <br />
                            <a href="#" className="btn btn-outline-info btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Edit Team Member"><i className="fa fa-user"></i></a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-danger btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Request To Stop"><i className="ki ki-close"></i></a>
                        </div>
                    </>
                )
            }
            else {
                return (
                    <>
                        <div>
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingResearch/edit/" + id,
                                    }
                                }
                            >
                                <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Update Data </a>
                            </Link>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                            <a href="#" className="btn btn-outline-success btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project Logbook"><i className="fa fa-list"></i></a>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project Report"><i className="flaticon-file-2"></i></a>
                            <br />
                            <a href="#" className="btn btn-outline-info btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Edit Team Member"><i className="fa fa-user"></i></a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <a href="#" className="btn btn-outline-danger btn-sm mr-1" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Request To Stop"><i className="ki ki-close"></i></a>
                        </div>
                    </>
                )
            }

        }
        else if (state_name === "monitoring_revise_portfolio") {
            return (
                <>
                    <div>
                        <Link to=
                            {
                                {
                                    pathname: "/portfolio-research/edit/" + id,
                                }
                            }
                        >
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Fill Data</a>
                        </Link>

                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <a href="#" className="btn btn-outline-warning btn-sm mr-1" data-container="body" data-toggle="m-tooltip" data-placement="bottom" title="" data-original-title="Project History"><i className="fa fa-history"></i></a>
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    <div>
                        <Link to=
                            {
                                {
                                    pathname: "/OngoingResearch/view/" + id,
                                }
                            }
                        >
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View Data</a>
                        </Link>
                    </div>

                </>
            )
        }
    }
    else if (workflow_stage === 'ABI Request') {
        switch (state_name) {
            case "waiting_approval":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                        </div>
                    </>
                )
                break;
            case "rejected":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                        </div>
                    </>
                )
                break;
            case "revised":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Edit Data</a>
                        </div>
                    </>
                )
                break;
            case "approved":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Update Data</a>
                        </div>
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i>Edit Data</a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <form method="POST" action="#" accept-charset="UTF-8" style="display:inline">
                                @Html.AntiForgeryToken()
                                <button type="submit" className="btn btn-outline-danger btn-sm delete-project" title="Delete Research"><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                            </form>
                        </div>
                    </>
                )
                break;
        }


    }
    else if (workflow_stage === 'ABI Monitoring') {
        switch (state_name) {
            case "abi_monitoring":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Update Data</a>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_need_rev":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i>Edit Data</a>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_need_rev_review":
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-search la-flip-horizontal"></i> View</a>
                        </div>
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i>Edit Data</a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <form method="POST" action="#">
                                @Html.AntiForgeryToken()
                                <button type="submit" className="btn btn-outline-danger btn-sm delete-project" title="Delete Research"><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                            </form>
                        </div>
                    </>
                )
                break;
        }


    }
}
export const button_new = function (workflow_stage, state_name, is_project_continued, id, onClickContinue = (id) => { }, data_year_form = '', workflow_state_id = '', baseline_number = '0', data_year_month = '', data_cut_off_date = '0', state = {}) {
    if (workflow_stage === 'Project Ideation') {
        switch (state_name) {
            case "ideation_waiting_approval":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "ideation_approve_1":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "ideation_waiting_approval_revise":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/Edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "ideation_approve_1_revise":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/Edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "ideation_waiting_approval_reject":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "ideation_approve_1_reject":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/ideation/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "ideation_approve_2":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        {/* <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i> Edit Data</a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <form method="POST" action="#" accept-charset="UTF-8" style={{ display: 'inline' }}>
                                <button type="submit" className="btn btn-outline-danger btn-sm mr-1 delete-project" title="Delete Research"><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                            </form>
                        </div> */}
                    </>
                )
                break;
        }
    }
    else if (workflow_stage === 'Project Portfolio') {
        switch (state_name) {
            case "portfolio_waiting_approval":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_1":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "portfolio_waiting_approval_revise":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_1_revise":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "portfolio_waiting_approval_reject":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_1_reject":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            case "portfolio_new_approve_2":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/portfolio-research/edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                            {/* <Link>
                                <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                            </Link> */}
                        </div>
                    </>
                )
                break;
        }
    }
    else if (workflow_stage === "Project Monitoring" || workflow_stage === "Project") {

        if (state_name === "monitoring" || state_name === "monitoring_revise_upload") {
            let btnContinue = false;
            if (is_project_continued === 2) {
                btnContinue = false;
            } else {
                if (moment().format('M').toString() === '1' && toInteger(moment().format('D')) < toInteger(data_cut_off_date)) {
                    btnContinue = false;
                } else {
                    btnContinue = true;
                }
            }
            return (
                <div>
                    {
                        state.dataUser?.role_id !== '10' && (
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/reportResearch/index/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                    <Link to={`/EditTeamMember/OngoingResearch/${id}`} className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        )
                    }
                    {
                        btnContinue && (
                            <button className="btn btn-sm btn-light-primary w-100 my-1"
                                onClick={() => is_project_continued === 1 ? onClickContinue(id, state_name, workflow_state_id) : null}
                            ><i className="fas fa-step-forward icon-nm"></i> Continue Project</button>
                        )
                    }
                    {
                        !btnContinue && (
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingResearch/edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                        )
                    }
                </div>
            )
        }
        else if (state_name === "monitoring_revise_portfolio") {
            return (
                <>
                    <div>
                        {
                            state.dataUser?.role_id !== '10' && (
                                <Dropdown
                                    className="my-1"
                                >
                                    <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                        <span className="rounded svg-icon svg-icon-sm">
                                            <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                        </span>
                                        <span >
                                            Option
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                        <Link to={`/reportResearch/index/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                        <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                        <Link to={`/EditTeamMember/OngoingResearch/${id}`} className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )
                        }
                        <Link to=
                            {
                                {
                                    pathname: "/portfolio-research/edit/" + id,
                                }
                            }
                        >
                            <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                        </Link>
                        {/* <Link>
                            <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                        </Link> */}
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    <div>
                        {
                            state.dataUser?.role_id !== '10' && (
                                <Dropdown
                                    className="my-1"
                                >
                                    <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                        <span className="rounded svg-icon svg-icon-sm">
                                            <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                        </span>
                                        <span >
                                            Option
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Link to={`/Projects/ResearchHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                        <Link to={`/reportResearch/index/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                        <Link to={`/research/project-profile/${id}/${baseline_number}`} className="dropdown-item" target={'_blank'}><i className="fas fa-file-alt mx-1"></i>Project Profile</Link>
                                        <Link to={`/EditTeamMember/OngoingResearch/${id}`} className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )
                        }
                        <Link to=
                            {
                                {
                                    pathname: "/OngoingResearch/view/" + id,
                                }
                            }
                        >
                            <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                        </Link>
                        {/* <Link>
                            <button className="btn btn-sm btn-light-danger w-100 my-1"><i className="fa fa-times icon-nm"></i> Stop</button>
                        </Link> */}
                    </div>
                </>
            )
        }
    }
    else if (workflow_stage === 'ABI Request') {
        switch (state_name) {
            case "waiting_approval":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/AbiAbo/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "rejected":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/AbiAbo/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "revised":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/AbiAbo/Edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "approved":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/AbiAbo/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingAbi/edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Project Logbook</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link>
                                    <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/AbiAbo/Edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                        </div>
                    </>
                )
                break;
        }


    }
    else if (workflow_stage === 'ABI Monitoring' || workflow_stage === 'ABI') {
        switch (state_name) {
            case "abi_monitoring":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/AbiMonthly/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingAbi/edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_need_rev":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/AbiMonthly/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/AbiAbo/Edit/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> Update</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_need_rev_review":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/AbiMonthly/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/AbiAbo/View/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-pencil-alt icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_aborted_req":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/AbiMonthly/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingAbi/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_finished_req":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/AbiMonthly/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingAbi/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_finished":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    \                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/AbiMonthly/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingAbi/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case "abi_monitoring_aborted":
                return (
                    <>
                        <div>
                            <Dropdown
                                className="my-1"
                            >
                                <Dropdown.Toggle bsPrefix="hide-icon-dropdown" className="btn btn-sm btn-light-success justify-content-between" style={{ width: '100%' }} id="dropdown-basic">
                                    <span className="rounded svg-icon svg-icon-sm">
                                        <SVG src="/media/svg/icons/General/Settings-1.svg" />
                                    </span>
                                    <span >
                                        Option
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link to={`/AbiHistories/${id}`} className="dropdown-item" ><i className="fas fa-history mx-1"></i>History</Link>
                                    <Link to={`/AbiMonthly/${id}`} className="dropdown-item" ><i className="fas fa-clipboard-list mx-1"></i>Monthly Report</Link>
                                    {/* <Link className="dropdown-item" ><i className="fas fa-file-alt mx-1"></i>Project Report</Link> */}
                                    {/* <Link className="dropdown-item" ><i className="fas fa-user-edit mx-1"></i>Edit Team</Link> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to=
                                {
                                    {
                                        pathname: "/OngoingAbi/view/" + id,
                                    }
                                }
                            >
                                <button className="btn btn-sm btn-light-primary w-100 my-1"><i className="fa fa-search icon-nm"></i> View</button>
                            </Link>
                        </div>
                    </>
                )
                break
            default:
                return (
                    <>
                        <div>
                            <a href="#" className="btn btn-outline-primary btn-sm mr-1"><i className="fa fa-pencil"></i>Edit Data</a>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <button type="submit" className="btn btn-outline-danger btn-sm delete-project" title="Delete Research"><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>
                        </div>
                    </>
                )
                break;
        }
    }
}
