import React from 'react';
import useCrudCustomer from './useCrudCustomer';
import { Modal } from 'react-bootstrap';
import CustomerDetailForm from '../../../nonAdmin/laboratory/component/customerDetailForm';

function ModalDetailCustomer(props) {
    const { ccAction, ccState } = useCrudCustomer();

    function closeModal() {
        ccAction.setModalCu({ show: false, data: {} });
    }
    return (
        <Modal show={ccState.modalCu?.show} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    Detail Customer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomerDetailForm data={ccState.modalCu?.data}/>
                <div className='row'>
                    <div className='col-md-12 text-right'>
                        <button className='btn btn-sm btn-success mr-2' onClick={() => ccAction.approve(ccState.modalCu?.data?.id)}>Approve</button>
                        <button className='btn btn-sm btn-danger mr-2' onClick={() => ccAction.reject(ccState.modalCu?.data?.id)}>Reject</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDetailCustomer;