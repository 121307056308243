import React, { useEffect, useState } from 'react';
import { ChartDx, DoughnatDx, SwitchBtn } from '../../components';
import useEquipment from './useEquipment';
import { Card } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { formatter } from '../../components/utility/utility';

const DashboardEquipment = () => {

    const { eqAction, eqState } = useEquipment()
    useEffect(function () {
        eqAction.getDashboard()
    }, []);
    const [swChat5, setSwChart5] = useState(false);
    const [swChat6, setSwChart6] = useState(false);
    const [swChat7, setSwChart7] = useState(false);
    const [swChat8, setSwChart8] = useState(false);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Card
                        className="m-0 p-0 mb-3 customShadow bg-primary"
                    >
                        <Card.Body className="p-2">
                            <div className="row" style={{ fontSize: '8pt' }}>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex border-right-custom">
                                        <div>
                                            <div className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                                <SVG src="/images/penandruller.svg" />
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Total Alat</span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_total_alat}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_total_harga || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-secondary" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">No Status </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_NST}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_NST || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block  header-total">Idle</span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_IDL}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_IDL || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Maintenance </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_MTN}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_MTN || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Ready </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_RDY}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_RDY || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Broken </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_BKN}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_BKN || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block  header-total">UPP/Dumped</span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_UPP}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_UPP || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-red-dark" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block  header-total">Expired </span>
                                            {/* <span className="header-invest">/ Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_EXP}</strong>
                                                    {/* <span className="header-invest"> / {formatter.format(eqState.dataHeader?.data_harga_EXP || 0)} $</span> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            {/* baris 1 */}
            <div className="row">
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row m-0">
                                        <div className="col-12 p-0">
                                            <span className="ctsmFilterTitle">Jumlah Alat / Fungsi</span>
                                            <span className="ctsmFilterSubTitle">
                                                Jumlah alat berdasarkan fungsi
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                withTitle={false}
                                                showLegend={false}
                                                toolsExport={false}
                                                LegendverticalAlignment="bottom"
                                                LegendhorizontalAlignment="center"
                                                argumentField="short_name"
                                                dataSource={eqState?.dataDashboard?.chart1 || []}
                                                yAxisArgs={[
                                                    {
                                                        name: "Jumlah Alat",
                                                        valueField: "jumlah",
                                                        color: "#4C84FF"
                                                    },
                                                ]}
                                            />
                                        ), [eqState?.dataDashboard?.chart1])
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row m-0">
                                        <div className="col-12 p-0">
                                            <span className="ctsmFilterTitle">Jumlah Alat / Umur</span>
                                            <span className="ctsmFilterSubTitle">
                                                Jumlah alat berdasarkan umur alat
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                withTitle={false}
                                                showLegend={false}
                                                toolsExport={false}
                                                LegendverticalAlignment="bottom"
                                                LegendhorizontalAlignment="center"
                                                argumentField="title"
                                                dataSource={eqState?.dataDashboard?.chart2 || []}
                                                yAxisArgs={[
                                                    {
                                                        name: "Jumlah Alat",
                                                        valueField: "jumlah",
                                                        color: "#46CCB1"
                                                    },
                                                ]}
                                            />
                                        ), [eqState?.dataDashboard?.chart2])
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Jumlah Alat / Tipe Alat</span>
                                    <span className="ctsmFilterSubTitle">
                                        Jumlah alat berdasarkan tipe alat
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <DoughnatDx
                                        withTitle={false}
                                        loading={eqState.loadingDashboard}
                                        showLegend={true}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `${e.value}`}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.value} ; ${arg.percentText}` })}
                                        dataSource={eqState?.dataDashboard?.chart3 || []}
                                        toolsExport={false}
                                        colors={["#FEB01A", "#46CCB1"]}
                                        yAxisArgs={[
                                            {
                                                valueField: "jumlah",
                                                argumentField: "title",
                                                color: null,
                                                format: null
                                            }
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart3, eqState.loadingDashboard])
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* baris 2 */}
            <div className="row mt-3">
                <div className="col-md-6 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Jumlah Alat / Lokasi</span>
                                    <span className="ctsmFilterSubTitle">
                                        Jumlah alat berdasarkan Lokasi
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        showTotal={true}
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        loading={eqState.loadingDashboard}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        dataSource={eqState?.dataDashboard?.chart4 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Jumlah Alat",
                                                valueField: "jumlah",
                                                color: "#C070CC"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart4, eqState.loadingDashboard])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className='col'>
                                    <span className="ctsmFilterTitle">Jumlah Alat / Lab</span>
                                    <span className="ctsmFilterSubTitle">
                                        Jumlah alat berdasarkan umur dan lab
                                    </span>
                                </div>
                                <div className='col-auto'>
                                    <SwitchBtn value={swChat5} onChange={(val) => setSwChart5(val)} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        showTotal={true}
                                        type="stackedBar"
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        loading={eqState.loadingDashboard}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `${e.valueText}`}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText} ${arg.seriesName} ${arg.value}` })}
                                        dataSource={(eqState?.dataDashboard?.chart5 || [])}
                                        yAxisArgs={!swChat5 ? [{
                                            name: "Jumlah",
                                            valueField: "total",
                                        },] : [
                                            {
                                                name: "0-5",
                                                valueField: "gol1",
                                                color: "#006CB8"
                                            },
                                            {
                                                name: "6-10",
                                                valueField: "gol2",
                                                color: "#46CCB1"
                                            },
                                            {
                                                name: "11-15",
                                                valueField: "gol3",
                                                color: "#FEB01A"
                                            },
                                            {
                                                name: "16-20",
                                                valueField: "gol4",
                                                color: "#F08044"
                                            },
                                            {
                                                name: "21-25",
                                                valueField: "gol5",
                                                color: "#F04445"
                                            },
                                            {
                                                name: "26-30",
                                                valueField: "gol6",
                                                color: "#716ACA"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart5, eqState.loadingDashboard, swChat5])
                            }
                        </div>

                    </div>
                </div>
            </div>

            {/* baris 3 */}
            <div className="row  mt-3">
                <div className="col-md-4 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col">
                                    <span className="ctsmFilterTitle">Umur Alat / Fungsi</span>
                                    <span className="ctsmFilterSubTitle">
                                        Jumlah alat berdasarkan umur dan fungsi
                                    </span>
                                </div>
                                <div className='col-auto'>
                                    <SwitchBtn value={swChat6} onChange={(val) => setSwChart6(val)} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        showTotal={true}
                                        type="stackedBar"
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        loading={eqState.loadingDashboard}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `${e.valueText}`}
                                        dataSource={(eqState?.dataDashboard?.chart6 || [])}
                                        yAxisArgs={!swChat6 ? [
                                            {
                                                name: "Jumlah",
                                                valueField: 'total'
                                            }
                                        ] : [
                                            { name: "TD I", valueField: "td_i", },
                                            { name: "TD II", valueField: "td_ii", },
                                            { name: "TA", valueField: "ta", },
                                            { name: "TISPOM", valueField: "tispom", },
                                            { name: "TINC", valueField: "tinc", },
                                            { name: "FL", valueField: "fl", },
                                            // {
                                            //     name: "LAB",
                                            //     valueField: "LAB",
                                            //     color: "#006CB8"
                                            // },
                                            // {
                                            //     name: "PCD",
                                            //     valueField: "PCD",
                                            //     color: "#F04445"
                                            // },
                                            // {
                                            //     name: "URTI",
                                            //     valueField: "URTI",
                                            //     color: "#F08044"
                                            // },
                                            // {
                                            //     name: "DRTI",
                                            //     valueField: "DRTI",
                                            //     color: "#46CCB1"
                                            // }
                                        ]}
                                    />
                                ), [eqState.loadingDashboard, eqState?.dataDashboard?.chart6, swChat6])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col">
                                    <span className="ctsmFilterTitle">Umur Alat / Lokasi</span>
                                    <span className="ctsmFilterSubTitle">
                                        Jumlah alat berdasarkan umur dan lokasi
                                    </span>
                                </div>
                                <div className='col-auto'>
                                    <SwitchBtn value={swChat7} onChange={(val) => setSwChart7(val)} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        showTotal={true}
                                        type="stackedBar"
                                        withTitle={false}
                                        showLegend={true}
                                        toolsExport={false}
                                        loading={eqState.loadingDashboard}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `${e.valueText}`}
                                        dataSource={(eqState?.dataDashboard?.chart7 || [])}
                                        yAxisArgs={!swChat7 ? [
                                            {
                                                name: "Jumlah",
                                                valueField: "total"
                                            }
                                        ] : [
                                            {
                                                name: "Gd1 Lt1",
                                                valueField: "j1",
                                                color: "#369AFF"
                                            },
                                            {
                                                name: "Gd2 Lt1",
                                                valueField: "j2",
                                                color: "#46CCB1"
                                            },
                                            {
                                                name: "Gd2 Lt2",
                                                valueField: "j3",
                                                color: "#FEB01A"
                                            },
                                            {
                                                name: "Gd3 Lt1",
                                                valueField: "j4",
                                                color: "#F08044"
                                            },
                                            {
                                                name: "Gd4 Lt1",
                                                valueField: "j5",
                                                color: "#F04445"
                                            },
                                            {
                                                name: "Gd4 Lt2",
                                                valueField: "j6",
                                                color: "#ACC429"
                                            },
                                            {
                                                name: "Gd5",
                                                valueField: "j7",
                                                color: "#874BE6"
                                            },
                                            {
                                                name: "Gd6",
                                                valueField: "j8",
                                                color: "#C070CC"
                                            },
                                        ]}
                                    />
                                ), [eqState.loadingDashboard, eqState?.dataDashboard?.chart7, swChat7])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col">
                                    <span className="ctsmFilterTitle">Umur Alat / Status</span>
                                    <span className="ctsmFilterSubTitle">
                                        Jumlah alat berdasarkan umur dan status
                                    </span>
                                </div>
                                <div className='col-auto'>
                                    <SwitchBtn value={swChat8} onChange={(val) => setSwChart8(val)} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        showTotal={true}
                                        withTitle={false}
                                        toolsExport={false}
                                        type="stackedBar"
                                        showLegend={true}
                                        loading={eqState.loadingDashboard}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `${e.valueText}`}
                                        dataSource={(eqState?.dataDashboard?.chart8 || [])}
                                        yAxisArgs={!swChat8 ? [
                                            {
                                                name: "Jumlah",
                                                valueField: "jumlah"
                                            }
                                        ] : [
                                            {
                                                name: "Ready",
                                                valueField: "RDY",
                                                color: "#006CB8"
                                            },
                                            {
                                                name: "Maintenance",
                                                valueField: "MTN",
                                                color: "#FEB01A"
                                            },
                                            {
                                                name: "Broken",
                                                valueField: "BKN",
                                                color: "#F08044"
                                            },
                                            {
                                                name: "No Status",
                                                valueField: "NST",
                                                color: "#F04445"
                                            },
                                            {
                                                name: "Expired",
                                                valueField: "EXP",
                                                color: "#414346"
                                            },
                                        ]}
                                    />
                                ), [eqState.loadingDashboard, eqState?.dataDashboard?.chart8, swChat8])
                            }
                        </div>

                    </div>
                </div>
            </div>

            {/* baris 4 */}
            <div className="row  mt-3">
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Nilai Investasi / Fungsi</span>
                                    <span className="ctsmFilterSubTitle">
                                        Nilai Investasi ($ USD) berdasarkan fungsi
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="short_name"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: $${arg.valueText}` })}
                                        dataSource={eqState?.dataDashboard?.chart9 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Nilai Investasi",
                                                valueField: "nilai",
                                                color: "#46CCB1"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart9])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Nilai Investasi / Umur</span>
                                    <span className="ctsmFilterSubTitle">
                                        Nilai Investasi ($ USD) berdasarkan umur
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="golongan_umur"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: $${arg.valueText}` })}
                                        dataSource={eqState?.dataDashboard?.chart10 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Nilai Investasi",
                                                valueField: "nilai",
                                                color: "#874BE6"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart10])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Nilai Investasi / Lokasi Alat</span>
                                    <span className="ctsmFilterSubTitle">
                                        Nilai Investasi ($ USD) berdasarkan Lokasi Alat
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        overlappingBehavior="rotate"
                                        rotationAngle={45}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: $${arg.valueText}` })}
                                        dataSource={eqState?.dataDashboard?.chart11 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Nilai Investasi",
                                                valueField: "nilai",
                                                color: "#F08044"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart11])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Nilai Investasi / Tipe Alat</span>
                                    <span className="ctsmFilterSubTitle">
                                        Nilai Investasi ($ USD) berdasarkan Tipe Alat
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <DoughnatDx
                                        withTitle={false}
                                        loading={eqState.loadingDashboard}
                                        showLegend={true}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        dataSource={eqState?.dataDashboard?.chart12 || []}
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: $${arg.value} ; ${arg.percentText}` })}
                                        toolsExport={false}
                                        colors={["#FEB01A", "#46CCB1"]}
                                        yAxisArgs={[
                                            {
                                                valueField: "nilai",
                                                argumentField: "title",
                                                color: null,
                                                format: null
                                            }
                                        ]}

                                    />
                                ), [eqState.loadingDashboard, eqState?.dataDashboard?.chart12])
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* baris 5 */}
            <div className="row  mt-3">
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Biaya Perawatan / Fungsi</span>
                                    <span className="ctsmFilterSubTitle">
                                        Biaya maintenance dan broken ($ USD) berdasarkan fungsi
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="short_name"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        dataSource={eqState?.dataDashboard?.chart13 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Biaya Perawatan",
                                                valueField: "nilai",
                                                color: "#006CB8"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart13])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Biaya Perawatan / Umur</span>
                                    <span className="ctsmFilterSubTitle">
                                        Biaya maintenance dan broken ($ USD) berdasarkan umur
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="golongan_umur"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        dataSource={eqState?.dataDashboard?.chart14 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Biaya Perawatan",
                                                valueField: "nilai",
                                                color: "#716ACA"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart14])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Biaya Perawatan / Lokasi </span>
                                    <span className="ctsmFilterSubTitle">
                                        Biaya maintenance dan broken ($ USD) berdasarkan lokasi
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        overlappingBehavior="rotate"
                                        rotationAngle={45}
                                        dataSource={eqState?.dataDashboard?.chart15 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Biaya Perawatan",
                                                valueField: "nilai",
                                                color: "#369AFF"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart15])
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Biaya Perawatan/ Tipe Alat</span>
                                    <span className="ctsmFilterSubTitle">
                                        Biaya maintenance dan broken ($ USD) berdasarkan tipe alat
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <DoughnatDx
                                        withTitle={false}
                                        loading={eqState.loadingDashboard}
                                        showLegend={true}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: $${arg.value} ; ${arg.percentText}` })}
                                        dataSource={eqState?.dataDashboard?.chart16 || []}
                                        toolsExport={false}
                                        colors={["#006CB8", "#C070CC"]}
                                        yAxisArgs={[
                                            {
                                                valueField: "nilai",
                                                argumentField: "title",
                                                color: null,
                                                format: null
                                            }
                                        ]}

                                    />
                                ), [eqState.loadingDashboard, eqState?.dataDashboard?.chart16])
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* bartis 6 */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <span className="ctsmFilterTitle">Biaya perawatan / Lab</span>
                                    <span className="ctsmFilterSubTitle">
                                        Biaya perawatan ($ USD) berdasarkan lab
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            {
                                React.useMemo(() => (
                                    <ChartDx
                                        withTitle={false}
                                        showLegend={false}
                                        toolsExport={false}
                                        LegendverticalAlignment="bottom"
                                        LegendhorizontalAlignment="center"
                                        argumentField="title"
                                        onRenderLabelChart={(e) => `$ ${e.value}`}
                                        dataSource={eqState?.dataDashboard?.chart17 || []}
                                        yAxisArgs={[
                                            {
                                                name: "Biaya Perawatan",
                                                valueField: "nilai",
                                                color: "#ACC429"
                                            },
                                        ]}
                                    />
                                ), [eqState?.dataDashboard?.chart17])
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardEquipment;