import { Autocomplete, FileUploader, NumberBox, TagBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { baseUrl } from '../../app.config';
import { fileExtensions, fileExtensions_string } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';
import './achievement.css';
import { useHistory } from 'react-router-dom';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import { FileUploadDx, HeaderList } from '../../components';

function AddAchievement(props) {
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [judul, setJudul] = useState("");
    const [description, setDescription] = useState("");
    const [level, setLevel] = useState("");
    const [owner, setOwner] = useState([]);
    const [tahun, setTahun] = useState("");
    const [cover, setCover] = useState("")
    useEffect(() => {
        action.getData('/api/people-achievement/table');
    }, [])

    function onUploadedCover(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setCover({
                "name": resp.data?.data_filename,
                "is_new": true
            })
        }
    }
    function fileOnchange(data) {
        // console.log(data)
        const f = data.value?.[0];
        if (data.value?.[0] && f.size <= 10000000) {
            var file = data.value?.[0];
            var ext = file?.name?.split('.').pop();
            var result = fileExtensions_string.filter(d => d === ext)
            if (result.length > 0) {
                action.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": data.value?.[0],
                        },
                        withFile: true,
                    }
                ).then((resp) => {
                    if (resp.code === "00") {
                        setCover({
                            "name": resp.data?.data_filename,
                            "is_new": true
                        })
                    }
                })
            }
        } else if (data.value?.[0] && f.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageYear = '';
        if (data.name === '') {
            isValid = false;
            message = [...message, 'Judul'];
        }
        if (data.description === '') {
            isValid = false;
            message = [...message, 'Description'];
        }
        if (data.level === '') {
            isValid = false;
            message = [...message, 'Level'];
        }
        // if ([...(data.owner || [])].length === 0) {
        //     isValid = false;
        //     message = [...message, 'Owner'];
        // }
        if (data.year_of_achievement === '') {
            isValid = false;
            message = [...message, 'Tahun'];
        }
        if (data.photo_location?.name === '') {
            isValid = false;
            message = [...message, 'Cover'];
        }
        if (isValid && parseInt(data?.year_of_achievement, 0) < 1950) {
            isValid = false;
            messageYear = 'Tahun minimal 1950';
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageYear ? ' dan ' : '') + messageYear
        }
    }
    function save() {
        const data = {
            "id": '',
            "name": judul || '',
            "description": description || '',
            "photo_location": {
                name: cover?.name || '',
                is_new: cover?.is_new
            },
            "year_of_achievement": tahun || '',
            "level": level || '',
            "creator": '',
            "created_at": "",
            "updated_at": "",
            "owner": [...(owner || [])].map(d => {
                return {
                    user_id: d,
                    is_new: true
                }
            }) || [],
            "owner_delete": [],
            "status_approval": ""
        }
        const valid = validation(data);
        if (valid.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: valid.message,
                icon: 'warning',
            })
        } else {

            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Achievement? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/people-achievement/create-achievement",
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            history.goBack();
                            // history.push('/admin/people-achievement/table')
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function ownerChange(e) {
        setOwner(e)
    }
    return (
        <>
            <HeaderList
                title="Add Achievement"
                someText="People Management\Achievement\Add Achievement"
            >

            </HeaderList>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <h5>Detail</h5>
                            <hr />
                            <div className="row mb-3">
                                <div className="col-md-3">Judul Achievement</div>
                                <div className="col-md-9">
                                    <input className="form-control form-control-sm" onChange={(e) => setJudul(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Deskripsi</div>
                                <div className="col-md-9">
                                    <input type="textarea" className="form-control form-control-sm" onChange={(e) => setDescription(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Level</div>
                                <div className="col-md-9">
                                    <select className="form-control form-control-sm" onChange={(e) => setLevel(e.target.value)}>
                                        <option value="" selected disabled> - Pilih Level -</option>
                                        <option value="Internal">Internal</option>
                                        <option value="National">National</option>
                                        <option value="International">International</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Owner</div>
                                <div className="col-md-9">
                                    {/* <Autocomplete dataSource={state.data_users}
                                        valueExpr="name"
                                        displayExpr="name"
                                        onSelectionChanged={(e) => setOwner(e.selectedItem)}
                                        className="form-control form-control-sm mr-1">
                                    </Autocomplete> */}
                                    <TagBox
                                        searchEnabled={true}
                                        value={owner}
                                        id="inventor"
                                        items={state.data_users}
                                        displayExpr="name"
                                        displayValue="name"
                                        valueExpr="id"
                                        onValueChange={ownerChange}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Tahun</div>
                                <div className="col-md-9">
                                    <input type="number" placeholder="2000" min={0} className="form-control form-control-sm" onChange={(e) => setTahun(e.target.value)} />
                                    {/* <NumberBox className="form-control form-control-sm" onValueChanged={(e) => setTahun(e.value)}></NumberBox> */}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Cover</div>
                                <div className="col-md-9">
                                    {/* <FileUploader className="custom-dx-fileuploader-file-name p-0 custom-uploader"
                                        uploadUrl={baseUrl + "/api/base/upload-file-temp"}
                                        allowedFileExtensions={fileExtensions}
                                        accept="image/*"
                                        invalidFileExtensionMessag="File "
                                        onValueChanged={(e) => fileOnchange(e)}
                                        showFileList={false} multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb" maxFileSize={10000000} uploadMode="useForm" >

                                    </FileUploader> */}
                                    <FileUploadDx
                                        className="custom-dx-fileuploader-file-name"
                                        showFileList={true}
                                        multiple={false}
                                        selectButtonText="Select File"
                                        allowedFileExtensions={fileExtensions}
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb"
                                        accept="image/*"
                                        onUploaded={onUploadedCover}
                                        maxFileSize={10000000}
                                        uploadMode="instantly"
                                    />
                                    <span>{cover.name}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="d-block text-right">
                                <button className="btn btn-sm btn-secondary mx-1"
                                    onClick={() => history.goBack()}
                                >
                                    Batal
                                </button>
                                <button className="btn btn-sm btn-primary"
                                    onClick={() => save()}
                                >
                                    <i className="fa fa-save icon-nm mx-1 text-white"></i>
                                    Save
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default AddAchievement;