import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SwitchBtn } from '../../../../../components';
import { useGlobalState } from '../../../../../contexts/useGlobalState';

const acsContext = createContext({
    acsState: {},
    acsAction: {}
})
export function AccessProvider({ children }) {
    const [globalState, dispatch, globalActon] = useGlobalState();
    const history = useHistory();
    const [tab, setTab] = useState('spiirit')
    const [dataUserAccessSpiirit, setDataUserAccessSpiirit] = useState({});
    const [loadingAccessSpiirit, setLoadingAccessSpiirit] = useState(false);
    const [dataUserAccessAdmin, setDataUserAccessAdmin] = useState({});
    const [loadingAccessAdmin, setLoadingAccessAdmin] = useState(false);
    const [accessSpiirit, setAccessSpiirit] = useState([])
    const [role_id, setRole_id] = useState('');
    const [role_idDef, setRole_idDef] = useState('');
    const [modal, setModal] = useState({
        show: false,
        editMode: false
    });

    async function getUserAccessSpiirit(role_id) {
        setLoadingAccessSpiirit(true)

        try {

            const resp = await globalActon.getData(`/api/user/table-action-access?role_id=${role_id}&grp=${2}`, {}, null, null, true)
            if (resp.code === "00") {
                // // console.log(resp.data)
                setDataUserAccessSpiirit(resp.data)
                globalActon.setForm('usr_page', resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingAccessSpiirit(false)
    }
    async function getUserAccessAdmin(role_id) {
        setLoadingAccessAdmin(true)

        try {

            const resp = await globalActon.getData(`/api/user/table-action-access?role_id=${role_id}&grp=${1}`, {}, null, null, true)
            if (resp.code === "00") {
                // // console.log(resp.data)
                setDataUserAccessAdmin(resp.data)
                globalActon.setForm('usr_page', resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingAccessAdmin(false)
    }
    async function postUpdateApi(data, role_id) {
        const d = [...(data || [])].map(d => {
            return {
                "grp": d?.grp,
                "modul": d?.modul,
                "menu1": d?.menu1,
                "menu2": d?.menu2,
                "action": d?.action,
                "access": d?.access
            }
        })
        //console.log(d);
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/user/update-action-access?role_id=${role_id}`,
                    data: d,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                getUserAccessAdmin(role_id);
                getUserAccessSpiirit(role_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    function toggleModal(editMode = false) {
        setModal(prevState => ({ editMode, show: !prevState.show }))
    }
    function tabChange(id) {
        setTab(id);
        getUserAccessSpiirit(role_id);
        getUserAccessAdmin(role_id);
    }
    function roleChange(val) {
        setAccessSpiirit([]);
        setRole_id(val)
        //if (tab === 'spiirit') {
            getUserAccessSpiirit(val);
        //}
        //if (tab === 'admin') {
            getUserAccessAdmin(val);
        //}

    }
    function roleDefChange(val){
        setRole_idDef(val);
        if(val === ''){
            getUserAccessSpiirit(role_id);
            getUserAccessAdmin(role_id);
        }else{
            getUserAccessSpiirit(val);
            getUserAccessAdmin(val);
        }
    }
    function renderTitle(title, isCurrentEventKey) {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex">
                            {/* <span className="p-2 mr-3 rounded svg-icon svg-icon-nm svg-icon-warning" style={{ backgroundColor: '#F0F0F0' }}>
                                <SVG src="/media/svg/icons/General/Thunder-move.svg" />
                            </span> */}
                            <i className={`text-primary mx-3 ` + (isCurrentEventKey ? `fas fa-angle-double-down` : `fas fa-angle-double-right`)}></i>
                            <span><b>{title}</b></span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    function renderGridContent(rowInfo) {
        if (rowInfo.column.name === 'access') {
            return (
                <SwitchBtn value={rowInfo.value} />
            )
        } else {
            return (
                <span className="d-block">{rowInfo.value}</span>
            )
        }
    }
    function accessChange(data, val) {
        // console.log(data, val)
        //data.column.setCellValue(dataNew);
        // if (data?.acceess !== val) {
        //     setAccessSpiirit([...accessSpiirit, { ...data, access: val }])
        // }
    }
    function setBtnSwitch(rowData, value) {
        // console.log(rowData, value);
    }
    function onEditorPreparing(e) {
        if (e.dataField === 'access' && e.parentType === 'dataRow') {
            e.editorName = "dxSwitch";
            e.editorOptions = {
                value: e.value === "Y" ? true : false,
                onValueChanged: function (args) {
                    e.setValue(args.value ? "Y" : "N");
                }
            }
        }
    }
    function saveAccess() {
        var data = [];
        [...(dataUserAccessSpiirit?.data_table || [])].forEach(d => {
            data = [...data, ...d?.data_child]
        });
        [...(dataUserAccessAdmin?.data_table || [])].forEach(d => {
            data = [...data, ...d?.data_child]
        });
        if (role_id === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Pilih Role',
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update user access?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //console.log(accessSpiirit, role_id)
                    postUpdateApi(data, role_id);
                    return true;
                } else {
                    return true;
                }
            })
        }
    }

    return <acsContext.Provider value={{
        acsState: {
            dataUserAccessSpiirit,
            loadingAccessSpiirit,
            dataUserAccessAdmin,
            loadingAccessAdmin,
            tab,
            role_id,
            role_idDef,
            modal,
            accessSpiirit
        },
        acsAction: {
            getUserAccessSpiirit,
            getUserAccessAdmin,
            setDataUserAccessSpiirit,
            setDataUserAccessAdmin,
            renderGridContent,
            tabChange,
            roleChange,
            roleDefChange,
            toggleModal,
            renderTitle,
            accessChange,
            setBtnSwitch,
            setAccessSpiirit,
            onEditorPreparing,
            saveAccess
        }
    }}>
        {children}
    </acsContext.Provider>
}
export default function useUserAccessPage() {
    return useContext(acsContext);
}