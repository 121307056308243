import React, { useState } from 'react';
import { GridDrawdown } from '../../components/index';

const format = {
    type: "currency",
    precision: 2
}
const dataSource = [
    {
        "id": 2342,
        "year": 2021,
        "description": "Technical Support",
        "cost_element_id": 1,
        "cost_element_name": "Research & Development Expense",
        "plan_jan": 0.0,
        "plan_feb": 0.0,
        "plan_mar": 0.0,
        "plan_apr": 0.0,
        "plan_may": 0.0,
        "plan_jun": 0.0,
        "plan_jul": 0.0,
        "plan_aug": 0.0,
        "plan_sep": 0.0,
        "plan_oct": 0.0,
        "plan_nov": 0.0,
        "plan_dec": 0.0,
        "actual_jan": null,
        "actual_feb": null,
        "actual_mar": null,
        "actual_apr": 0.0,
        "actual_may": 0.0,
        "actual_jun": null,
        "actual_jul": null,
        "actual_aug": null,
        "actual_sep": null,
        "actual_oct": null,
        "actual_nov": null,
        "actual_dec": null,
        "created_at": "2021-04-09T18:50:11.577",
        "updated_at": "2021-04-09T18:50:11.577"
    }
]
const type = [{ "id": "milestone", "name": "Milestone" }, { "id": "sub milestone", "name": "Sub Milestone" }, { "id": "task", "name": "Task" }, { "id": "sub task", "name": "Sub Task" }];
const column = [
    //{ "dataField": "no", "caption": "#", "visible": false, "dataType": "number" },
    { "dataField": "decriptions", "caption": "Description", "dataType": "string", minWidth:300, },
    { "dataField": "costElement", "caption": "Cost Element", "custom": true, "type": "lookup", "dSource": type, "displayExpr": "name", "valueExpr": "id", "visible": true, "dataType": "string", width:100 },
    { "dataField": "plan_jan", "caption": "JAN", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_feb", "caption": "FEB", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_mar", "caption": "MAR", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_apr", "caption": "APR", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_may", "caption": "MAY", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_jun", "caption": "JUN", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_jul", "caption": "JUL", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_aug", "caption": "AUG", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_sep", "caption": "SEP", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_oct", "caption": "OCT", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_nov", "caption": "NOV", "visible": true, "format": format, "dataType": "number", width:50 },
    { "dataField": "plan_dec", "caption": "DEC", "visible": true, "format": format, "dataType": "number", width:50 }
]
const TabBudget = () => {
    const [data, ] = useState(dataSource)
    return (
        <>
            <div className="row" style={{ paddingLeft: 15, paddingRight: 15 }}>
                <div className="col">
                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>Drawdown Budget(Anggaran Biaya Operasi)</p>
                    <p><i className="fa fa-circle"></i> Nominal anggaran yang tertera bernilai $(Ribu USD)
                </p>

                </div>
            </div>
            {
                data.length === 0 ? (
                    <DefaultTable />
                ) :
                    data.map((e, i) => {
                        return (
                            <>
                                <div className="row" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: '35px' }}>
                                    <div className="col">
                                        <span>{e.year}</span>
                                    </div>
                                </div>
                                <hr style={{ marginRight: 5, marginLeft: 5 }} />
                                <div className="row" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="col">
                                        <GridDrawdown dataSource={[e]}
                                            key={i}
                                            allowAdding={true}
                                            allowDeleting={true}
                                            allowEditing={true}
                                            columns={column}
                                            />
                                    </div>
                                </div>
                            </>
                        )
                    })
            }

        </>
    )
}
export default TabBudget;
function DefaultTable() {
    const caption = ["Description", "Cost Element", "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
    return (
        <table className="table">
            <thead>
                {
                    caption.map(cap => (
                        <th className="customTableBorderRowCol customTableBorderHeader">{cap}</th>
                    ))
                }
            </thead>
            <tbody>
                <tr>
                    <td className="customTableBorderRowCol" colSpan={14} style={{ height: '200px', verticalAlign: 'middle', textAlign: 'center' }}>
                        <button className="btn btn-primary"><i className="la la-plus"> Add Project Timeline</i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}