import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useCrudPengujian from '../useCrudPengujian';
import { Modal } from 'react-bootstrap';

function ModalPersetujuan(props) {
    const { cpState, cpAction } = useCrudPengujian();
    const [note, setNotes] = useState("");

    const closeModal = () => {
        cpAction.setModalSd({ show: false, default_data: {} });
    }
    useEffect(() => {
        if (!cpState.modalSd?.show) {
            setNotes("");
        }
    }, [cpState.modalSd?.show])

    return (
        <Modal show={cpState.modalSd?.show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Persetujuan Penerimaan Sample
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-sm-4">Tanggal Persetujuan</label>
                    <div className="col-sm-8">
                        <p>{moment().format("DD-MM-yyyy")}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan / Komentar</label>
                    <div className="col-sm-8">
                        <textarea className='form-control form-control-sm' rows={3}
                            value={note} onChange={e => setNotes(e.target.value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    cpState.modalSd?.default_data?.status_id === 5 && <>
                        <button onClick={() => cpAction.sabKlik({
                            id: cpState.modalSd?.default_data?.id,
                            note
                        })} className="btn btn-sm btn-dark mr-1">Sample Aborted</button>
                        <button onClick={() => cpAction.sncKlik({
                            id: cpState.modalSd?.default_data?.id,
                            note
                        })} className="btn btn-sm btn-warning mr-1">Sample Not Complete</button>
                        {/* <button onClick={() => cpAction.saKlik({
                            id: cpState.modalSd?.default_data?.id,
                            note
                        })} className="btn btn-sm btn-success mr-1">Sample Accepted</button> */}
                        <button onClick={() => cpAction.tprKlik({
                            id: cpState.modalSd?.default_data?.id,
                            note
                        })} className="btn btn-sm btn-success mr-1">Proccess Running</button>
                    </>
                }
                {
                    // cpState.modalSd?.default_data?.status_id === 7 && <>
                    //     <button onClick={() => cpAction.sabKlik({
                    //         id: cpState.modalSd?.default_data?.id,
                    //         note
                    //     })} className="btn btn-sm btn-dark mr-1">Sample Aborted</button>
                    //     <button onClick={() => cpAction.tprKlik({
                    //         id: cpState.modalSd?.default_data?.id,
                    //         note
                    //     })} className="btn btn-sm btn-success mr-1">Testing Proccess Running</button>
                    // </>
                }

            </Modal.Footer>
        </Modal>
    );
}

export default ModalPersetujuan;