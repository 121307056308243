import React from 'react';
import { HeaderList } from '../../../components';
import { Card } from 'react-bootstrap';
import StepperRegister from './component/stepperRegister';
import usePermohonanPengajuan, { PerPengProvider } from './usePermohonanPengajuan';
import SteppOne from './component/steppOne';
// import { Carousel } from 'react-responsive-carousel';
import StepTwo from './component/stepTwo';
import StepThree from './component/stepThree';
import './index.css';
import StepFour from './component/stepFour';
import { toInteger } from 'lodash';


function PermohonanPengajuan(props) {
    const { ppState, ppAction } = usePermohonanPengajuan();
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={"Permohonan Pengujian Laboratorium"}
                someText={""}
            />
            <div className='row'>
                <div className='col-md-12'>
                    <StepperRegister activeStep={toInteger(ppState.activeStep)} />
                    {/* <div className='mb-5'>
                        <button onClick={ppAction.prevStep} className='btn btn-sm btn-secondary mr-5'>Kembali</button>
                        <button onClick={ppAction.nextStep} className='btn btn-sm btn-primary'>Next</button>
                    </div> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <Card
                                className="m-0 p-0 mb-5"
                            >
                                <Card.Body className="p-5">
                                    {
                                        ppState.activeStep === "0" && <SteppOne />
                                    }
                                    {
                                        ppState.activeStep === "1" && <StepTwo />
                                    }
                                    {
                                        ppState.activeStep === "2" && <StepThree />
                                    }
                                    {
                                        // ppState.activeStep === "3" && <StepFour />
                                    }                                    
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default () => <PerPengProvider><PermohonanPengajuan /></PerPengProvider>;