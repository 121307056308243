import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../components';
import { Link, useHistory } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { useGlobalState } from '../../contexts/useGlobalState';
import moment from 'moment';

const columns = [
    { fieldName: "name", caption: 'Judul', width: 200 },
    { fieldName: 'description', caption: 'Deskripsi', width: 500 },
    { fieldName: 'patent_category_name', caption: 'Category', width: 100 },
    { fieldName: 'patent_year', caption: 'Tahun', width: 100 },
    { fieldName: "pemilik", caption: 'Pemilik', width: 100 },
    { fieldName: "area_name", caption: 'Area', width: 100 },
    { fieldName: "direktorat_fungsi", caption: 'Direktorat', width: 100 },
    { fieldName: 'patent_owners_name', caption: "Inventor", width: 200 },
    { fieldName: 'owner_ext', caption: 'Inventor Ext.', width: 200 },
    { fieldName: "keyword", caption: 'Keyword', width: 150 },
    { fieldName: 'patent_status_name', caption: "Status", width: 100 },
    { fieldName: 'no_pengajuan', caption: "No. Pengajuan", width: 100 },
    { fieldName: 'no_paten', caption: "No. Paten", width: 100 },
]

function Reporting(props) {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const tableRef = useRef();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData('/api/people-intel-property/table-dashboard-3', {}, null, null, true).then((resp) => {
            if (resp.code === "00") {
                setData_page(resp.data_page);
            }
        });
    }, [])
    const customCellrender = (e) => {
        if (e.column.name === "name") {
            return (
                <Link to={`/admin/people/patent-detail-crud/${e.data.id}`}>
                    <span className="text-wrap">
                        {
                            e.value
                        }
                    </span>
                </Link>
            )
        } else if (e.column.name === "inventor") {
            return (
                <>
                    <span className="text-wrap">
                        {
                            [e.data.owner1_name, e.data.owner2_name, e.data.owner3_name, e.data.owner4_name, e.data.owner5_name, e.data.owner6_name].filter(s => s).join(', ')
                        }
                    </span>
                </>
            )
        } else if (e.column.name === "keyword") {
            return (
                <div className="text-wrap">
                {
                    (e.value || "").split(',').map((d, i) => (
                        <span className="badge badge-secondary mr-1 mb-1" key={i}>{d}</span>
                    ))
                }
                </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <div>
            <div className="pb-5">
                <HeaderList
                    title={data_page?.page_title}
                    someText={data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/General/User.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Intellectual Prop.</span>
                                    <span className="d-block"><strong>{state.data_Patents_Count || '0'}</strong></span>
                                </div>
                            </div>
                        </div>
                        {
                            [...(state.data_grafik_patent_area || [])].map(d => (
                                <div className="col-lg-1 col-md-1 col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block">{d.name}</span>
                                            <span className="d-block"><strong>{d.jumlah_int || '0'}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 shadow"
            >
                <Card.Body className="p-5">
                    <GridDx
                        ref={tableRef}
                        cellRender={customCellrender}
                        settingColumn={true}
                        filenameExport={`Intellectual Property Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                        searchForm={true}
                        selectionMode="multiple"
                        exportMode="selection"
                        exportExcel={true}
                        data={state.data_Patents || []}
                        columns={columns}
                    />
                </Card.Body>
            </Card>
        </div>
    );
}

export default Reporting;