import React, { createContext, useContext, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useGlobalState } from "../../../contexts/useGlobalState";
import useFetch from "../../../modules/useFetch";

const hmContext = createContext({
    hmState: {},
    hmAction: {}
});

export function HmProvider({ children }) {
    const [state] = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [dataDash, setDataDash] = useState({});
    const [sub_holding_guest, setSub_holding_guest] = useState(true);
    const { doGet } = useFetch();

    const getData = useCallback(async function () {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/home/home-dashboard',
                token: true
            });
            if (resp?.code === '00') {
                setDataDash({ ...resp?.data })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }, []);
    const validationRole = useCallback((validRole = '') => {
        const roleNames = (state?.dataUser?.role_name || '')?.split('|');
        var result = false;
        [...(roleNames || [])].forEach(d => {
            if (`${d}`.toLowerCase() === validRole.toLowerCase()) result = true;
        });
        return result;
    }, [state.dataUser]);

    useEffect(() => {
        if (state.dataUser) {
            if (validationRole('Sub Holding Guest')) {
                setSub_holding_guest(true);
            } else {
                getData();
                setSub_holding_guest(false);
            }
        }
    }, [state.dataUser]);
    return <hmContext.Provider
        value={{
            hmState: {
                dataDash,
                loading,
                sub_holding_guest
            },
            hmAction: {
                validationRole
            }
        }}
    >{children}</hmContext.Provider>
}

export default function useHome() {
    return useContext(hmContext);
}