import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { desc_achievement } from '../component/constanta';
import ImgBeranda from '../home/image/beranda.png';
import useIndexLandPage from '../use_index';
import useLndAchi, { LndAchiProvider } from './use_achievement_index';
import { PaginationCs } from '../../../components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageCover from '../home/image/LP-Achievement.svg';
import { useHistory } from 'react-router-dom';

function Achievement(props) {
    const { lndAchiAction, lndAchiState } = useLndAchi();
    const { indAction: { toHome = () => null } } = useIndexLandPage();
    const history = useHistory();
    return (
        <div className='mt-5' style={{ 'paddingTop': '35px' }}>
            <img src={ImageCover} style={{ 'position': 'absolute', width: '100%', height: '210px', top: '10px', objectFit: 'cover' }}></img>
            <Container>
                <div className='row justify-content-center text-center mb-5'>
                    <div className='col-lg-6 col-md-6 text-white'>
                        <h5 className='font-weight-boldest'>Achievement</h5>
                        <span className='text-wrap'>{desc_achievement}</span>
                    </div>
                </div>
                <div className='row justify-content-center text-center mb-5'>
                    <div className='col-lg-8 col-md-8'>
                        <div className="input-group input-group-rounded mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text input-group-icon border-right-0 bg-white" id="basic-addon1">
                                    <i className='fa fa-search text-primary icon-nm'></i>
                                </span>
                            </div>
                            <input type="text" className="form-control border-left-0 input-group-field" placeholder="Cari Achievement..."
                                value={lndAchiState.judul}
                                onChange={(e) => lndAchiAction.setJudul(e.target.value)}
                                onKeyUp={(e) => lndAchiAction.judulEnter(e)}
                            />
                        </div>
                        <div className='text-left'>
                            <div className='row'>
                                <div className='col-auto'>
                                    <select className='form-control form-control-sm mx-1' style={{ width: '100px' }}
                                        value={lndAchiState.tahun}
                                        onChange={(e) => lndAchiAction.tahunChange(e.target.value)}
                                    >
                                        {
                                            [
                                                {
                                                    name: 'Tahun',
                                                    value: ''
                                                },
                                                ...(lndAchiState.dataAchi?.data_tahun || []).map(d => ({ name: d, value: d }))
                                            ].map((d, i) => (
                                                <option key={i} value={d?.value}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='col'>
                                    <button className={`btn btn-sm btn-${lndAchiState.level === '' ? 'secondary' : 'light'} mx-1 mb-2`}
                                        onClick={(e) => lndAchiAction.levelChange('')}
                                    >Semua Level</button>
                                    {
                                        [...(lndAchiState.dataAchi?.data_level || [])].map((d, i) => (
                                            <button className={`btn btn-sm btn-${lndAchiState.level === d ? 'secondary' : 'light'} mx-1 mb-2`} key={i}
                                                onClick={(e) => lndAchiAction.levelChange(d)}
                                            >{d}</button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PaginationCs
                    data={[...(lndAchiState.dataAchi?.data_products || [])]}
                    dataPerPage={8}
                    RenderContainer={({ children }) => (
                        <div className='row mb-5'>
                            {children}
                        </div>
                    )}
                    renderComponent={(d, i) => (

                        <div className='col-lg-3 col-md-3 mb-3' key={i}>
                            <div className='card p-3 h-100 rounded-cs customShadow'>
                                <LazyLoadImage
                                    key={i}
                                    src={d?.photo_location}
                                    width={'100%'}
                                    height={'180px'}
                                    className='rounded-cs border border-cs mb-3  image-card'
                                    style={{ objectFit: 'contain', backgroundColor: 'whitesmoke' }}
                                    onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                    effect='blur'
                                />
                                <span className='text-wrap font-weight-boldest mb-3'>{d?.name}</span>
                                <div className='text-wrap mb-3 text-truncate-container'>{d?.description}</div>
                                <div className='footer-card-product'>
                                    <div>
                                        <span className='bg-light-primary text-primary px-2 rounded'>{d?.level}</span>
                                        <span className='text-muted float-right'>{d?.year_of_achievement}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <div className='d-inline-flex bg-transparent py-3 position-sticky my-3' style={{ bottom: '10px' }}>
                    <button className='btn btn-sm btn-secondary'
                        // onClick={toHome}
                        onClick={() => history.push('/landing-page/beranda')}
                    >
                        <i className='fas fa-chevron-circle-left icon-nm'></i>
                        Kembali
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default (props) => <LndAchiProvider><Achievement {...props} /></LndAchiProvider>