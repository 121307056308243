import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../components';
import SVG from "react-inlinesvg";
import moment from 'moment';
// import { useHistory } from 'react-router-dom';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useGlobalState } from '../../contexts/useGlobalState';

const column = [
    { fieldName: "title", caption: "Judul", onExport: (e) => (e?.title || '') },
    { fieldName: "description", caption: "Deskripsi", width: 200, onExport: (e) => (e?.description || '') },
    { fieldName: "area_name", caption: "Area", width: 150, onExport: (e) => (e?.area_name || '') },
    { fieldName: "category_name", caption: "Kategori", width: 200, onExport: (e) => (e?.category_name || '') },
    { fieldName: "author", caption: "Author", width: 150, onExport: (e) => (e?.author || '') },
    { fieldName: "creator_name", caption: "Creator", width: 150, onExport: (e) => (e?.creator_name || '') },
    { fieldName: "sitelink", caption: "Site/Url", width: 150, onExport: (e) => (e?.sitelink || '') },
    { fieldName: "created_at_str", caption: "Tanggal Upload", width: 100, onExport: (e) => (e?.created_at_str || '') },
]

function ReportingKmLibrary() {
    const tableRef = useRef();
    // const history = useHistory();
    const [state, , action] = useGlobalState();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        action.getData('/api/km-library/table-admin', {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                setData_page(resp.data_page);
            }
        });
    }, [])

    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-4">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Book-open.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Library</span>
                                    <span className="d-block"><strong>0</strong></span>
                                </div>
                            </div>
                        </div>
                        {
                            [...(state.data_header || [])].map((d, i) => (
                                <div className="col-lg-1 col-md-1 col-6 mb-5" key={i}>
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block" style={{ wordBreak: 'break-word' }}>{d?.name}</span>
                                            <span className="d-block"><strong>{d?.jumlah_int}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body>
                    <GridDx
                        ref={tableRef}
                        filenameExport={`Library Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                        searchForm={true}
                        className="custom-style-master"
                        data={state?.dataBook}
                        selectionMode={"multiple"}
                        exportMode="selection"
                        exportExcel={true}
                        settingColumn={true}
                        columns={column}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                    >

                    </GridDx>
                </Card.Body>
            </Card>
        </>
    );
}

export default ReportingKmLibrary;