import React from 'react';
import { Card } from 'react-bootstrap';
import { Modal } from '../../../components';
import ModalEditScreensaver from '../../screensaver/modalEditScreensaver';
import ModalScreensaver from '../../screensaver/modalscreensaver';
import useSettingParameter from '../useSettingParameter';

const dummy = [
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' },
    { image: "/media/svg/icons/Design/Image.svg", judul: 'Kegiatan loading katalis, kegiatan skimming katalis, trouble shooting, dan hasil unit kinerja katalis NHT Pertamina PK-100 H' }
]

function TabScreensaver(props) {
    const { setParAction, setParState } = useSettingParameter();
    return (
        <div>
            <Modal
                name="modalScreensaver"
                baseColor={false}
            >
                <ModalScreensaver data={dummy} />
            </Modal>
            <Modal
                name="modalEditScreensaver"
                baseColor={false}
            >
                <ModalEditScreensaver data={setParState.editDataSS} mode={setParState.modeSS} onSave={setParAction.onSave_ss} />
            </Modal>
            <Card
                className="customShadow p-3"
            >
                <Card.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <span>List Screensaver</span>
                                <div className="d-inline-block">
                                    <button className="btn btn-sm btn-outline-primary mx-1"
                                        onClick={() => setParAction.onPriviewClick_ss()}
                                    >
                                        <i className="fa fa-play icon-sm"></i>
                                        Preview
                                    </button>
                                    <button className="btn btn-sm btn-primary mx-1"
                                        onClick={() => setParAction.onAddClick_ss()}
                                    >
                                        <i className="fa fa-plus icon-sm"></i>
                                        Add Screensaver
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        {
                            dummy?.map((d, i) => (
                                <div className="col-md-3 mb-5">
                                    {
                                        setParAction.renderContent_ss(d, (i + 1))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TabScreensaver;