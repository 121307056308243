import React, { useEffect, useState } from 'react';
import { RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';

function ModalInternalExp({ data = [], onSave = (e) => { }, onClose = () => { } }) {
    const [state, dispatch, action] = useGlobalState();
    const [position, setPosisition] = useState('');
    const [year_start, setYear_start] = useState('');
    const [year_end, setYear_end] = useState('');
    const [masih_bekerja, setMasih_bekerja] = useState('');
    const [statusEdit, setStatusEdit] = useState(0);
    const [user_profile_id, setUser_profile_id] = useState();
    const [editId, setIdEdit] = useState();

    useEffect(() => {
        if (state.modal.insideExpertise === true) {
            cancelEdit();
        }
    }, [state.modal.insideExpertise])

    function setEdit(data) {
        action.getData(`/api/people-hrd/view-update-in-experience?id=${data.id}`, {}, 'data_internalExp_edit').then((resp) => {
            if (resp.code === '00') {
                setStatusEdit(1)
                setIdEdit(resp.data.data_user_internal_exp?.id);
                setPosisition(resp.data.data_user_internal_exp?.position);
                setYear_start(resp.data.data_user_internal_exp?.year_start);
                setYear_end(resp.data.data_user_internal_exp?.year_end === "Present" ? '' : resp.data.data_user_internal_exp?.year_end);
                setMasih_bekerja(resp.data.data_user_internal_exp?.year_end === "Present" ? true : false);
                setUser_profile_id(resp.data.data_user_internal_exp?.user_profile_id);
            }
        })
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Hapus Experience ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/people-hrd/delete-in-experience?id=${id}`,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        cancelEdit();
                        onSave();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function cancelEdit() {
        setStatusEdit(0)
        setIdEdit('');
        setPosisition('');
        setYear_start('');
        setYear_end('');
        setMasih_bekerja(false);
        setUser_profile_id('');
    }
    function addExperience() {
        const dataKirim = {
            "id": '',
            "position": position,
            "year_start": year_start,
            "year_end": masih_bekerja === true ? "Present" : (year_end || ''),
            "user_profile_id": state.data_user_profile?.id
        }
        const validate = validation(dataKirim);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Tambah Experience?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/people-hrd/create-in-experience`,
                            data: dataKirim,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            cancelEdit();
                            onSave();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function updateExperience() {
        const dataKirim = {
            "id": editId,
            "position": position,
            "year_start": year_start,
            "year_end": masih_bekerja === true ? "Present" : (year_end || ''),
            "user_profile_id": user_profile_id
        }
        const validate = validation(dataKirim);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Expertise?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/people-hrd/update-in-experience`,
                            data: dataKirim,
                            withFile: false,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            cancelEdit();
                            onSave();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageYear = '';
        if (data.position === '') {
            isValid = false;
            message = [...message, 'Posisi'];
        }
        if (data.year_start === '' || data.year_start === '0') {
            isValid = false;
            message = [...message, 'Tahun Mulai'];
        }
        if (data.year_end === '' || data.year_end === '0') {
            isValid = false;
            message = [...message, 'Tahun Selesai'];
        }
        if (masih_bekerja === false && isValid === true) {
            if (parseInt(data.year_start, 0) < 1950) {
                isValid = false;
                messageYear = 'Minimal tahun mulai 1950'
            } else if (parseInt(data.year_start, 0) > parseInt(data.year_end, 0)) {
                isValid = false;
                messageYear = 'Tahun mulai tidak boleh lebih besar tahun selesai'
            }
        } else if (masih_bekerja === true && isValid === true) {
            if (parseInt(data.year_start, 0) < 1950) {
                isValid = false;
                messageYear = 'Minimal tahun mulai 1950'
            }
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + ' Tidak Boleh Kosong' : '') + (message.length > 0 && messageYear ? ' dan ' : '') + (messageYear && ` ${messageYear}`),
        }
    }
    return (
        <div>
            <p><b>{statusEdit === 0 ? 'Add' : 'Edit'} pengalaman didalam Pertamina</b></p>
            <hr />
            <div className="row mb-3">
                <div className="col-md-2">
                    <label>Posisi</label>
                </div>
                <div className="col-md-10">
                    <input className="form-control form-control-sm" placeholder="Nama Posisi" value={position} onChange={(e) => setPosisition(e.target.value)}></input>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2">
                    <label>Tahun</label>
                </div>
                <div className="col-md-4">
                    <input type="number" min={0} className="form-control form-control-sm" placeholder="Tahun Mulai" value={year_start} onChange={(e) => setYear_start(e.target.value)}></input>
                </div>
                <div className="col-md-2">
                    <label>Selesai</label>
                </div>
                <div className="col-md-4">
                    <input disabled={masih_bekerja} type="number" min={0} className="form-control form-control-sm" placeholder="Tahun Selesai" value={year_end} onChange={(e) => setYear_end(e.target.value)}></input>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2">

                </div>
                <div className="col-md-10">
                    <div className="pl-5">
                        <input id="masih_bekerja" type="checkbox" className="form-check-input" checked={masih_bekerja} onChange={(e) => setMasih_bekerja(e.target.checked)}></input>
                        <label htmlFor="masih_bekerja">Saya Masih Bekerja</label>
                    </div>
                    {/* <RadioGrp
                        value={masih_bekerja}
                        onChange={(e) => setMasih_bekerja(e)}
                        data={[
                            { label: 'Saya Masih Bekerja', value: 'true' },
                        ]}
                    /> */}
                </div>
            </div>

            <div className="d-block text-right mb-5">
                {
                    statusEdit === 0 && (
                        <>
                            <button className="btn btn-sm btn-success" onClick={addExperience}><i className="fa fa-plus icon-nm mr-3"></i>Pengalaman</button>
                        </>
                    )
                }
                {
                    statusEdit === 1 && (
                        <>
                            <button className="btn btn-sm btn-secondary mx-1" onClick={() => cancelEdit()}>Cancel Edit</button>
                            <button className="btn btn-sm btn-success mx-1" onClick={updateExperience}><i className="fa fa-save icon-nm mr-3"></i>Update</button>
                        </>
                    )
                }
            </div>
            {/* <hr /> */}
            {
                [...(data || [])].map((d, i) => (
                    <div className="row mb-3">
                        <div className="col-6">
                            <div className="d-inline-flex">
                                <i className="fa fa-circle icon-xs mr-5 mt-1 text-dark"></i>
                                <div>
                                    <span className="d-block"><b>{d.position}</b></span>
                                    <span className="d-block">{d.year_start} - {d.year_end}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-md-right">
                            <button className="btn btn-sm btn-outline-warning text-warning mx-1" onClick={() => setEdit(d)}>
                                <i className="fa fa-pencil-alt icon-nm  p-0"></i>
                            </button>
                            <button className="btn btn-sm btn-outline-danger text-danger mx-1" onClick={() => deleteData(d.id)}>
                                <i className="fa fa-trash-alt icon-nm  p-0"></i>
                            </button>
                        </div>
                    </div>
                ))
            }
            <hr />
            <div className="text-md-right">
                {/* <button className="btn btn-sm btn-secondary mx-1" onClick={onClose}>Batal</button>
                <button className="btn btn-sm btn-primary mx-1" onClick={onSave}><i className="fa fa-save icon-nm mx-1"></i>Simpan</button> */}
            </div>
        </div>
    );
}

export default ModalInternalExp;