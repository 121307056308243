import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { Modal, GridDx } from '../../../components';
import ModalCategory from '../../settingMasterFAQ/modalCategory';
import ModalFAQ from '../../settingMasterFAQ/modalFAQ';
import useSettingApplication from '../useSettingAplication';

function TabFaq(props) {
    const tableRef = useRef();
    const {setAppAction, setAppState} = useSettingApplication();

    useEffect(() => {
        setAppAction.getMasterFaq();
    },[])
    return (
        <div>
            <Modal
                name="masterFaq"
                baseColor={false}
            >
                <ModalFAQ mode={setAppState.modeFaq} data={setAppState.dataEditFaq} />
            </Modal>
            <Modal
                name="modalKategory"
                baseColor={false}
            >
                <ModalCategory />
            </Modal>
            <Card
                className="m-0 p-0 customShadow"
            >
                <Card.Body>                    
                    <div className="row">
                        <div className="col-md-12">
                            <GridDx
                                searchForm={true}
                                toolbar={<>
                                    <button className="btn btn-sm btn-warning mx-1"
                                        onClick={() => setAppAction.openModalKategori()}
                                    >
                                        <i className="fa fa-pencil-alt icon-nm text-white"></i>
                                        Edit Kategori
                                    </button>
                                    <button className="btn btn-sm btn-success mx-1"
                                        onClick={() => setAppAction.addFAQ()}
                                    >
                                        <i className="fa fa-plus icon-nm text-white"></i>
                                        Add FAQ
                                    </button>
                                </>}
                                data={setAppState.dataFaq?.faq}
                                ref={tableRef}
                                withAction={true}
                                columns={setAppState.columns_faq}
                                cellRender={setAppAction.customRenderFAQ}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                actions={[
                                    {
                                        name: "Edit",
                                        icon: "fa fa-pencil-alt icon-nm text-success",
                                        action: (v, c) => setAppAction.editFAQ(v)
                                    },
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => setAppAction.deleteFAQ(v)
                                    }
                                ]}
                                show

                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TabFaq;