import React, { useRef } from 'react';
import { GridDx } from '../../../../components';
import useCrudCustomer from './useCrudCustomer';
import { Link } from 'react-router-dom';

const column = [
    { fieldName: "action_", caption: "Action", allowSorting: false, allowFiltering: false },
    { fieldName: "nama", caption: "Nama" },
    { fieldName: "email", caption: "Parameter Analisis", allowSorting: false, allowFiltering: false },
    { fieldName: "institution_nama", caption: "Holding/Sub Holding" },
    { fieldName: "institution_sub_nama", caption: "Direktorat" },
    { fieldName: "hp", caption: "No. HP" },
];

function TabCustomerList(props) {
    const { ccState: {
        dataCL = null,
    } } = useCrudCustomer();
    const tableRef = useRef();

    const cellRender = (rowInfo) => {
        if(rowInfo?.column?.name === "action_"){
            return <Link target='_blank' to={`/admin/admin-lab/view-detail/${rowInfo?.data?.id}`}>View</Link>
        }else{
            return rowInfo.value
        }
    }

    return (
        <>
            {React.useMemo(() => (
                <GridDx
                    className='custom-style-master'
                    cellRender={cellRender}
                    searchForm={true}
                    ref={tableRef}
                    data={dataCL}
                    columns={column}
                    pagination={true}
                    orderingDrag={false}
                    withAction={false}
                    currentPage
                    show

                ></GridDx>
            ), [dataCL])}
        </>
    );
}

export default TabCustomerList;