import React, { useRef, useState } from 'react';
import { GridDx } from '../../../../components';
import { Link } from 'react-router-dom';
import useLab from '../useLaboratory';
import LabStatus from '../component/labStatus';
import { DropDownButton } from 'devextreme-react';
import ModalDiagram from '../component/modalDiagram';
import Swal from 'sweetalert2';

const column = [
    { fieldName: "action_", caption: "Action", allowSorting: false, allowFiltering: false, width: 300 },
    { fieldName: "created_at_str", caption: "Tanggal", width: 100 },
    { fieldName: "judul", caption: "Judul Pengujian", allowSorting: false, allowFiltering: false, },
    { fieldName: "lab_test_status_id_str", caption: "Status Pengujian", width: 300 },
    { fieldName: "note_history", caption: "Keterangan" },
];


function MyLab(props) {
    const tableRef = useRef();
    const { labState, labAction } = useLab();
    const [modald, setmodald] = useState(false);

    const cellrender = (rowInfo) => {
        if (rowInfo.column.name === "action_") {
            return <div className='flex'>
                <DropDownButton

                    text="View"
                    className="bg-primary rounded customColorIcon customColotText mr-2"
                    icon="preferences"
                    dropDownOptions={{ width: 250 }}
                    displayExpr="name"
                    keyExpr="id"
                    items={[
                        {
                            id: 1,
                            name: "History Status",

                        },
                        {
                            id: 2,
                            name: "Detail Pengujian",

                        },
                    ]}
                    onItemClick={(e) => {
                        const { id } = e.itemData
                        if (id === 1) {
                            window.open(`/laboratory-detail/${rowInfo.data?.id}`)
                        }
                        if (id === 2) {
                            labAction.setModalView({
                                show: true,
                                default_data: { id_lab_test: rowInfo?.data?.id }
                            })
                        }
                    }}
                />
                {/* <Link to={`/laboratory-detail/${rowInfo.data?.id}`} target='_blank'
                    className="btn btn-sm btn-light-primary btn-hover-primary mr-2 my-1"
                >
                    View History
                </Link>
                <button
                    onClick={() => labAction.setModalView({
                        show: true,
                        defaultData: { ...rowInfo?.data }
                    })}
                    className="btn btn-sm btn-light-success btn-hover-success mr-2 my-1"
                >
                    View Resume
                </button> */}
                {
                    [1, 3].includes(rowInfo?.data?.status_id) && <Link to={{
                        pathname: `/laboratory-permohonan-pengajuan?id_lab_test=${rowInfo.data?.id}&step=0`,
                    }}
                        target='_blank'
                        className="btn btn-light-danger btn-hover-danger mr-2 my-1"
                    >
                        Update
                    </Link>
                }
                {
                    [6].includes(rowInfo?.data?.status_id) && <button
                        onClick={() => labAction.setModalSDNC({
                            show: true,
                            defaultData: { ...rowInfo?.data }
                        })}
                        className="btn btn-light-danger btn-hover-danger mr-2 my-1"
                    >
                        Update
                    </button>
                }
                {
                    [10].includes(rowInfo?.data?.status_id) && <button
                        onClick={() => labAction.setModalSPK({
                            show: true,
                            defaultData: { ...rowInfo?.data, revisi: true }
                        })}
                        className="btn btn-light-danger btn-hover-danger mr-2 my-1"
                        style={{
                            fontSize: '8pt',
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        }}
                    >
                        Update
                    </button>
                }
                {
                    [12].includes(rowInfo?.data?.status_id) && <button
                        onClick={() => labAction.setModalSpkNr({
                            show: true,
                            defaultData: { ...rowInfo?.data, revisi: true }
                        })}
                        className="btn btn-light-danger btn-hover-danger mr-2 my-1"
                        style={{
                            fontSize: '8pt',
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        }}
                    >
                        Update
                    </button>
                }

            </div>
        } else if (rowInfo.column.name === "lab_test_status_id_str") {
            return <LabStatus status={rowInfo.data?.status_id} >{rowInfo.value}</LabStatus>
        }
        else {
            return <p>{rowInfo.value}</p>
        }
    }

    return (
        <div>
            <ModalDiagram show={modald} onHide={() => setmodald(false)} />
            <GridDx
                className='custom-style-master'
                searchForm={true}
                toolbar={<div>
                    {
                        labState.dataMyLab?.cekIsCustomer === 1 ? (
                            <Link to={{
                                pathname: "/laboratory-permohonan-pengajuan",
                                state: {
                                    is_new: true,
                                    id_lab_test: null

                                }
                            }} target='_blank'>
                                <button className="btn btn-sm btn-danger mx-1"
                                >
                                    Lab Testing Request
                                </button>
                            </Link>
                        ) : (
                            <button className="btn btn-sm btn-danger mx-1"
                                onClick={() => {
                                    Swal.fire({
                                        title: 'Oops',
                                        text: "Anda belum terdaftar sebagai Lab Customer",
                                        icon: 'warning',
                                    })
                                }}
                            >
                                Lab Testing Request
                            </button>
                        )
                    }
                    <button className='btn btn-sm btn-primary' onClick={() => setmodald(true)}>View Diagram</button>
                </div>}
                ref={tableRef}
                data={[...(labState.dataMyLab?.dataLabTestList || [])]}
                columns={column}
                pagination={true}
                orderingDrag={false}
                withAction={false}
                currentPage
                cellRender={cellrender}
                show
            // actions={[
            //     {
            //         name: "View",
            //         icon: "far fa-eye icon-nm icon-nm text-primary",
            //         action: (v, c) => {
            //             const win = window.open(`/laboratory-detail/${v?.id}`, "_blank");
            //             win.focus();
            //         }
            //     },
            //     {
            //         name: "Edit",
            //         icon: "fa fa-pencil-alt icon-nm text-success",
            //         action: (v, c) => {

            //         }
            //     },
            //     // {
            //     //     name: "Delete",
            //     //     icon: "fa fa-trash-alt icon-nm text-danger",
            //     //     action: (v, c) => {

            //     //     }
            //     // },
            // ]}
            >
            </GridDx>
        </div>
    );
}

export default MyLab;