import React, { useEffect, useState } from 'react';
import { GridDxDrawdownMon, SkeletonLoading } from '../../components/index';
import { useGlobalState } from '../../contexts/useGlobalState';
import Card from 'react-bootstrap/Card'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment';
import { toInteger, toUpper } from 'lodash';

const format = {
    type: "currency",
    precision: 2
}
const column = [
    { "dataField": "description", "caption": "Description", "dataType": "string", minWidth: 200 },
    { "dataField": "cost_element_name", "caption": "Cost Element", "visible": true, "dataType": "string", width: 150 },
    { "dataField": "plan_actual", "caption": "", "visible": true, "dataType": "string",minWidth: 76, width: 76, allowFiltering:false },
    { "dataField": "plan_jan", "caption": "JAN", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_feb", "caption": "FEB", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_mar", "caption": "MAR", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_apr", "caption": "APR", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_may", "caption": "MAY", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_jun", "caption": "JUN", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_jul", "caption": "JUL", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_aug", "caption": "AUG", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_sep", "caption": "SEP", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_oct", "caption": "OCT", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_nov", "caption": "NOV", "visible": true, "format": format, "dataType": "number", width: 65 },
    { "dataField": "plan_dec", "caption": "DEC", "visible": true, "format": format, "dataType": "number", width: 65 }
]
const TabBudgetMon = ({ param, view_admin = false }) => {
    const [data, setData] = useState()
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        if (param?.id) {
            action.getData("/api/param-cost-element", {}, "cost_element").then((resp) => {
                if (resp.code === "00") {
                    dispatch({
                        type: "getSuccess",
                        payload: { cost_element: resp.data, loadingGet: false }
                    })
                }
            })
            action.getData("/api/project-ongoing-crud/view-budget/" + param?.id, {}, null, 'loadingBudget', true).then((resp) => {
                if (resp.code === "00") {
                    setData([...(resp.data.data_view_budget || [])])
                    action.setForm('cut_off_budget', resp.data.data_cut_off_day || 1);
                    action.setForm('mon', resp.data_page);
                }
            })
        }

    }, [])

    function getData() {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-budget/" + param?.id, {}, null, 'loadingBudget', true).then((resp) => {
                if (resp.code === "00") {
                    setData([...(resp.data.data_view_budget || [])]);
                    action.setForm('mon', resp.data_page);
                }
            })
        }
    }
    function updateBudgetRealisasi(data) {
        const month = parseInt(moment(new Date()).format('M'));
        const days = parseInt(moment(new Date()).format('D'));
        const disableInput = {
            jan: month === 1 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 2 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            feb: month === 2 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 3 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            mar: month === 3 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 4 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            apr: month === 4 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 5 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            may: month === 5 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 6 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            jun: month === 6 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 7 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            jul: month === 7 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 8 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            aug: month === 8 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 9 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            sep: month === 9 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 10 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            oct: month === 10 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 11 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            nov: month === 11 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 12 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
            dec: month === 12 ? (days > parseInt(state.formData.cut_off_budget) ? false : true) : (month === 1 && days <= parseInt(state.formData.cut_off_budget) ? false : true),
        }
        const dataKirim = [...(data || [])].map(d => {
            return {
                id: d.id,
                actual_jan: disableInput.jan === false ? d.actual_jan : null,
                actual_feb: disableInput.feb === false ? d.actual_feb : null,
                actual_mar: disableInput.mar === false ? d.actual_mar : null,
                actual_apr: disableInput.apr === false ? d.actual_apr : null,
                actual_may: disableInput.may === false ? d.actual_may : null,
                actual_jun: disableInput.jun === false ? d.actual_jun : null,
                actual_jul: disableInput.jul === false ? d.actual_jul : null,
                actual_aug: disableInput.aug === false ? d.actual_aug : null,
                actual_sep: disableInput.sep === false ? d.actual_sep : null,
                actual_oct: disableInput.oct === false ? d.actual_oct : null,
                actual_nov: disableInput.nov === false ? d.actual_nov : null,
                actual_dec: disableInput.dec === false ? d.actual_dec : null,
            }
        })
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Update Realisasi Budget?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-ongoing-crud/update-budget?research_id=" + state.data_view_header?.id,
                        data: dataKirim,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        getData()
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function allowEditing(e) {
        const yearMonth = (state.data_year_month || '')?.split('/');
        const max_year_month = '01/' + moment().format('YYYY');
        const curent_day = toInteger(moment().format('D'));
        const year = yearMonth[1] || '';
        if (state.filterData.mode === "edit" && year.toString() === e.year.toString() && state.data_view_header?.workflow_state_id === 45) {
            // if (state.data_view_header?.is_continue === 1 && curent_day > toInteger(state.data_cut_off_date) && state.data_year_month === max_year_month) {
            //     return false
            // } else {
            //     return true
            // }
            if (state.data_view_header?.is_continue === 2) {
                return true;
            } else {
                if (moment().format('M').toString() === '1' && toInteger(moment().format('D')) < toInteger(state?.data_cut_off_date)) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false
        }
    }

    return (
        <>
            <div className="row" style={{ paddingLeft: 15, paddingRight: 15 }}>
                <div className="col">
                    <span className='d-block text-center mb-5 px-2 py-1 bg-primary rounded text-white'>Pengisian realisasi bulan {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).format('MMM'))} dapat dilakukan s.d. tanggal {state.data_cut_off_date} {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).add(1, 'month').format('MMM'))} {state.data_year_month?.split('/')?.[1]}</span>
                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>Drawdown Budget(Anggaran Biaya Operasi)</p>
                    <p><i className="fa fa-circle icon-sm"></i> Nominal anggaran yang tertera bernilai $(Ribu USD)</p>
                    <p><i className="fa fa-circle icon-sm"></i> Kurs untuk tahun 2024: Rp 14.500</p>
                    <p><i className="fa fa-circle icon-sm"></i> Kurs untuk tahun 2025: Rp 15.046</p>
                </div>
            </div>
            {
                state.loadingBudget ? (
                    <SkeletonLoading type="table" rows={5} cols={column.length} />
                ) : (
                    [...(data || [])].map((e, i) => {
                        return (
                            <Card className="p-0 my-2">
                                <Card.Body className="p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 text-left">
                                                    <span>{e.year}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <GridDxDrawdownMon
                                                        dataSource={e}
                                                        key={i}
                                                        allowAdding={allowEditing(e)}
                                                        allowDeleting={allowEditing(e)}
                                                        allowEditing={allowEditing(e)}
                                                        columns={column}
                                                        showTotalActual={true}
                                                        data_year_month={state.data_year_month}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-12 text-right">
                                                    {
                                                        allowEditing(e) && (
                                                            <button className="btn btn-sm btn-success"
                                                                onClick={() => updateBudgetRealisasi(e.data)}
                                                            >Update Realisasi Budget</button>
                                                        )

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                )
            }
        </>
    )
}
export default TabBudgetMon;
// function DefaultTable() {
//     const caption = ["Description", "Cost Element", "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
//     return (
//         <table className="table">
//             <thead>
//                 {
//                     caption.map(cap => (
//                         <th className="customTableBorderRowCol customTableBorderHeader">{cap}</th>
//                     ))
//                 }
//             </thead>
//             <tbody>
//                 <tr>
//                     <td className="customTableBorderRowCol" colSpan={14} style={{ height: '200px', verticalAlign: 'middle', textAlign: 'center' }}>
//                         <button className="btn btn-primary"><i className="la la-plus"> Add Project Timeline</i></button>
//                     </td>
//                 </tr>
//             </tbody>
//         </table>
//     )
// }