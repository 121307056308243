import React from 'react';
import { HeaderList, Tab, TabContent } from '../../components';
import TabDashboard from './tabDashboard';
import TabTable from './tabTable';
import useSubholdings, { SubhProvider } from './useSubholdings';
import {
    month_source,
} from '../../components/utility/utility';

function Index(props) {
    const { subhAction, subhState } = useSubholdings();
    return (
        <React.Fragment>
            <HeaderList
                title={subhState.data_page?.page_title}
                someText={subhState.data_page?.page_breadcrumbs}
                optionList={[
                    {
                        option: subhState.year_source || [],
                        onChange: (val) => subhAction.filterChange('tahun', val),
                        value: subhState.filter?.tahun
                    },
                    {
                        option: month_source,
                        onChange: (val) => subhAction.filterChange('bulan', val),
                        value: subhState.filter?.bulan
                    }
                ]}
            ></HeaderList>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        title: 'Sub Holding Dashboard',
                        onClick: () => subhAction.setSelectedTab("dashboard")
                    },
                    {
                        id: "table",
                        title: "Sub Holding Table",
                        onClick: () => subhAction.setSelectedTab("table")
                    }
                ]}
                manualActive={subhState.selectedTab}
            >
                <TabContent id="dashboard">
                    <TabDashboard></TabDashboard>
                </TabContent>
                <TabContent id="table">
                    <TabTable></TabTable>
                </TabContent>

            </Tab>
        </React.Fragment>
    )
}

export default () => <SubhProvider><Index /></SubhProvider>;