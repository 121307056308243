import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { PaginationCs } from '../../components';

function TabProductDsply(props) {
    const [status, setStatus] = useState(0);
    const [data, setData] = useState([]);
    const [year, setYear] = useState('');
    const [keyword, setKeyword] = useState('');
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        getData(status, year, keyword);
    }, [])
    function getData(status, year, keywords) {
        action.getData('/api/ext-product/table', {
            status_search: status,
            tahunQuery: year,
            keyword_search: keywords
        }, null, 'loadingGet', true).then((resp) => {
            if (resp.code === '00') {
                setData(resp.data.data_Products);
                action.setForm('pro', resp.data_page);
            }
        })
    }
    function statusChange(val) {
        setStatus(val);
        getData(val, year, keyword);
    }
    function keywordEnter(e) {
        if (e.key === 'Enter') {
            setKeyword(e.target.value);
            getData(status, year, e.target.value);
        }
    }
    function yearChange(val) {
        setYear(val);
        getData(status, val, keyword);
    }
    return (
        <>
            <Card
                style={{ backgroundColor: '#C4E0FF' }}
                className="p-0 customShadow header_product_display mb-5"
            >
                <Card.Body className="p-5">
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <div className="d-flex">
                                <select className="form-control form-control-sm mx-1 w-25" onChange={(e) => yearChange(e.target.value)}>
                                    <option value="">- Semua Tahun -</option>
                                    {
                                        [...(state.filter_tahun || [])].map((d, i) => (
                                            <option value={d.release_year} key={i}>{d.release_year}</option>
                                        ))
                                    }
                                </select>
                                <div className="w-50 d-flex">
                                    <input className="form-control form-control-sm" placeholder="Cari Research"
                                        // onChange={(e) => setKeyword(e.target.value)}
                                        onKeyUp={(e) => keywordEnter(e)}></input>
                                    <i className="fa fa-search icon-nm" style={{ position: 'absolute', right: '27%', top: '35%' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-auto'>
                            <label className="my-0 mx-3">Level :</label>
                        </div>
                        <div className="col">
                            <button className={'btn btn-sm mx-1 mb-2 ' + (status === 0 ? "btn-primary" : "btn-light")} onClick={() => statusChange(0)}>Semua Status</button>
                            {
                                [...(state.filter_status || [])].map(d => (
                                    <button className={'btn btn-sm mx-1 mb-2 ' + (status === d.id ? "btn-primary" : "btn-light")} onClick={() => statusChange(d.id)}>{d.name}</button>
                                ))
                            }
                        </div>
                    </div>

                </Card.Body>
            </Card>
            <div className='row mb-5'>
                <div className='col-md-12'>
                    {
                        state.loadingGet ?
                            <div className="card-columns mt-5">
                                {
                                    [1, 2, 3, 4].map(d => (
                                        <div>
                                            <Card
                                                className="p-0 customShadow"
                                            >
                                                <Card.Body className="p-5">
                                                    <div className="col-md-12">
                                                        <div className="card card-custom overlay my-5">
                                                            <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                                <Skeleton height={150} />
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <h5><Skeleton /></h5>
                                                        </p>
                                                        <p><Skeleton /></p>
                                                        <div>
                                                            <Skeleton />
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <p>
                                                            <Skeleton />
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))
                                }
                            </div> :
                            <PaginationCs
                                data={[...(data || [])]}
                                dataPerPage={10}
                                RenderContainer={({ children }) => (
                                    <div className="card-columns px-1 my-1">
                                        {children}
                                    </div>
                                )}
                                renderComponent={(d, i) => (
                                    <Card
                                        className="p-0 customShadow mb-5"
                                    >
                                        <Card.Body className="p-5">
                                            <div>
                                                <div className="card card-custom overlay">
                                                    <div className="card-body p-0" style={{ borderRadius: '3%' }}>
                                                        <div className="overlay-wrapper">
                                                            <img style={{ width: '100%', height: 250, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border"
                                                                src={d.photo_product}
                                                                onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }} />
                                                        </div>
                                                        <div class="overlay-layer">
                                                            {/* <Link to={`/People/achievement-detail/${d.id}`}> */}
                                                            {
                                                                d?.file_location && (
                                                                    <a href={d?.file_location} download rel='noreferrer noopener' target='_blank'>
                                                                        <button className="btn btn-sm btn-primary mx-1"><i className="fa fa-download"></i>
                                                                            Download
                                                                        </button>
                                                                    </a>
                                                                )
                                                            }
                                                            <Link to={`/product/detail/${d?.id}`} target={'_blank'} rel='noreferrer noopener'>
                                                                <button className="btn btn-sm btn-light mx-1"><i className="fa fa-search"></i>
                                                                    Lihat Detail
                                                                </button>
                                                            </Link>
                                                            {/* </Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <h5>{d.name}</h5>
                                                </p>
                                                <p>{d.description}</p>
                                                <div>
                                                    <span className={`px-3 py-2 rounded bg-light-${d?.prodcut_Status_color} text-${d?.prodcut_Status_color}`}>{d.prodcut_Status_name}</span>
                                                </div>
                                                <br />
                                                <br />
                                                <p>
                                                    {d.release_year}
                                                </p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )}
                            />
                    }
                </div>
            </div>
        </>
    );
}

export default TabProductDsply;