import React, { useState } from 'react';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import LogoSpiirit from '../../images/logospiirit.png';
import './navbar.css';
import Drawer from '@material-ui/core/Drawer';
import LoginDrawer from './loginDrawer'
import useIndexLandPage from '../../use_index';
import { useGlobalState } from '../../../../contexts/useGlobalState';
import { useHistory } from 'react-router-dom';
import { AuthConsumer } from '../../../../service/authenticationProvider';
import { react_app_dummy } from '../../../../app.config';

function NavB(props) {
    const [color, setColor] = useState('nav-color');
    const [state, dispatch, actions] = useGlobalState();
    const [isOpenDrawer, setOpenDrawer] = useState(false);
    const { indAction, indState } = useIndexLandPage();
    const ChangeColor = () => {
        setColor(window.scrollY >= 50 ? 'nav-color-transparent' : 'nav-color');
    }
    const history = useHistory();

    window.addEventListener('scroll', ChangeColor);

    return (
        <>
            <Drawer onStalled={(s) => null} anchor="right" open={isOpenDrawer} onClose={() => {
                actions.setForm("Email", "");
                actions.setForm("Password", "");
                setOpenDrawer(false);
            }} >
                <LoginDrawer onCloseDrawer={() => {
                    actions.setForm("Email", "");
                    actions.setForm("Password", "");
                    setOpenDrawer(false);
                }} />
            </Drawer>
            <Navbar hidden={!indState.displayLayout} collapseOnSelect className={color} expand="md" sticky='top'>

                <Container>
                    <Navbar.Brand href="/landing-page">
                        <img src={'/images/logo-spiirit registered hitam.png'} style={{ height: '50px' }}></img>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            {
                                indState?.pageState?.type === 'HomeLandPage' ? <>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => history.push('/landing-page/beranda')}>Home</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => {
                                        actions.setForm('product_type', 0);
                                        history.push('/landing-page/product');
                                    }}>Product</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => { history.push('/landing-page/laboratory'); }}>Laboratory</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => history.push('/landing-page/achievement')}>Achievement</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => history.push('/landing-page/event')}>Event</span>
                                    {/* <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => indAction.setScrollElement('rti')}>Tentang RTI</span> */}
                                </> : <>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3"
                                        onClick={(e) => { indAction.toHome() }}
                                    >Beranda</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => {
                                        actions.setForm('product_type', 0);
                                        history.push('/landing-page/product');
                                    }}>Product</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => { history.push('/landing-page/laboratory'); }}>Laboratory</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => history.push('/landing-page/achievement')}>Achievement</span>
                                    <span className="nav-link font-weight-boldest pointer-style mx-3" onClick={() => history.push('/landing-page/event')}>Event</span>
                                    {/* <span className="nav-link disabled font-weight-boldest mx-3">Product</span>
                                    <span className="nav-link disabled font-weight-boldest mx-3">Achievement</span>
                                    <span className="nav-link disabled font-weight-boldest mx-3">Event</span> */}
                                    {/* <span className="nav-link disabled font-weight-boldest mx-3">Tentang RTI</span> */}
                                </>
                            }
                        </Nav>
                        <Nav className='mr-1 mb-1'>
                            <AuthConsumer>
                                {({ signinRedirect }) => (
                                    <button
                                        onClick={() => signinRedirect()}
                                        className='button-cs-landingpage center-corner'>
                                        <div className="background-button-cs-landingpage"></div>Login
                                    </button>
                                )}
                            </AuthConsumer>
                            {/* <button
                                onClick={() => setOpenDrawer(true)}
                                className='button-cs-landingpage center-corner'>
                                <div className="background-button-cs-landingpage"></div>Masuk
                            </button> */}
                        </Nav>
                        {
                            react_app_dummy && <Nav className='mb-1'>
                                <Dropdown size="sm">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }}>
                                        Lab Testing Request (Beta Testing)
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <AuthConsumer>
                                            {({ signinRedirect }) => (
                                                <Dropdown.Item style={{ fontSize: '9pt' }} className='py-2' onClick={() => signinRedirect()}>Customer Login</Dropdown.Item>
                                            )}
                                        </AuthConsumer>
                                        <Dropdown.Item style={{ fontSize: '9pt' }} className='py-2'
                                            onClick={() => indAction.openModalReg()}
                                        >Customer Registration</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default NavB;