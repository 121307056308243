import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useGlobalState } from '../../contexts/useGlobalState';

const dLeadContext = createContext({
    dLeadAction: {},
    dLeadState: {}
})

export function DetailLeaderProvider({ children }) {
    const [glblState, dispatch, glblAction] = useGlobalState();
    const [data, setData] = useState([]);
    const [data_page, setData_page] = useState({});
    const [func_id, setFunc_id] = useState('');
    const param = useParams();
    const { tahunQuery } = param;
    const location = useLocation();

    async function getData(func_id = '', tahunQuery = '') {
        try {
            const resp = await glblAction.getData(`/api/project-ongoing/tabel-dashboard-project-profile-chart12?func_id=${func_id}&tahunQuery=${tahunQuery}`, null, null, null, true);
            if (resp.code === "00") {
                setData(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire('error', error.toString(), 'error')
        }
    }
    function fungsiChange(val){
        setFunc_id(val);
        getData(val, tahunQuery);
    }
    useEffect(() => {
        getData(func_id, tahunQuery);
    }, [])
    return <dLeadContext.Provider
        value={{
            dLeadAction: {
                fungsiChange
            },
            dLeadState: {
                data,
                func_id,
                data_page
            }
        }}
    >{children}</dLeadContext.Provider>
}

export default function useDetailLeader() {
    return useContext(dLeadContext);
}