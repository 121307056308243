import React, { useEffect, useRef, useState } from 'react';
import useKejasama from './useKerjasama';
import { useGlobalState } from '../../contexts/useGlobalState';
import { GridDx } from '../../components';
import { Card } from 'react-bootstrap';
import moment from 'moment';

const columnMonitoring = [
    { fieldName: "created_at_form", caption: "Date Created", allowFiltering: false },
    { fieldName: "judul", caption: "Judul Kerjasama" },
    { fieldName: "kendala", caption: "Kendala Kerjasama", onExport: (e) => (e?.kendala || '')?.split(';')?.join(',') },
    { fieldName: "dukungan", caption: "Dukungan Kerjasama", onExport: (e) => (e?.dukungan || '')?.split(';')?.join(',') },
    { fieldName: "status_kerjasama", caption: "Status Dokumen", onExport: (e) => `${e?.jenis_dokumen_name} (${e?.status_dokumen_name})` },
]

function TabMonitoring({ reporting = false }) {
    const { krjAction, krjState } = useKejasama();
    const refTable = useRef();
    const [dataMonitoring, setDataMonitoring] = useState({})
    const [state, , action] = useGlobalState();

    function customRenderMonitoring(e) {
        if (e.column.name === "created_at") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "kendala") {
            return (
                <ul>
                    {
                        [...((e.value || '').split(";") || [])].filter(d => d).map((d, i) => (
                            <li key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "dukungan") {
            return (
                <ul>
                    {
                        [...((e.value || '').split(";") || [])].filter(d => d).map((d, i) => (
                            <li key={i}>{d}</li>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "status_kerjasama") {
            return (
                <div>
                    {e.data?.jenis_dokumen_name} {`(${e.data?.status_dokumen_name})`}
                </div>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }

    useEffect(() => {
        action.getData('/api/ext-partnership/table-monitoring', {}, null, 'loadingTable', true).then((resp) => {
            if (resp.code === "00") {
                setDataMonitoring({ ...resp?.data });
            }
        });
    }, [])
    return (
        <div>
            <Card
                className="m-0 p-0 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12 ">
                            <GridDx
                                className='custom-style-master'
                                repaintChangesOnly={true}
                                filterOperations={["contains", "endswith", "=", "startswith"]}
                                searchForm={true}
                                ref={refTable}
                                loading={state.loadingTable}
                                data={dataMonitoring?.tabel_monitoring}
                                cellRender={customRenderMonitoring}
                                columns={columnMonitoring}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                                selectionMode={reporting ? "multiple" : false}
                                exportMode="selection"
                                exportExcel={reporting}
                                settingColumn={reporting}
                                filenameExport={`Partnership Monitoring-${moment(new Date()).format('YYYY-MM-DD')}`}
                            ></GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TabMonitoring;