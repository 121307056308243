import moment from "moment";
import React, { createContext, useContext, useState } from "react";
import { useGlobalState } from "../../contexts/useGlobalState";
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import useSwakelola from "../swakelola/useSwakelola";

const swaEditContext = createContext({
    swaEditAction: {},
    swaEditState: {}
})

export function SwaEditModalProvider({ children }) {
    const [globalState, dispatch, globalAction] = useGlobalState();
    const {
        swaAction,
        swaState
    } = useSwakelola();
    const [swakelola_id, setSwakelola_id] = useState('');
    const history = useHistory();
    //Kontrak
    const [modeEditKontrak, setModeEditKontrak] = useState('');
    const [id_kontrak, set_id_kontrak] = useState('');
    const [nilai_kontrak, setnilai_kontrak] = useState('');
    const [start_date, setStart_date] = useState('');
    const [end_date, setEnd_date] = useState('');
    const [note, setNote] = useState('');
    const [dok1_absensi, setdok1_absensi] = useState('');
    const [dok2_ba_penjelasan, setdok2_ba_penjelasan] = useState('');
    const [dok3_ba_pembukaan, setdok3_ba_pembukaan] = useState('');
    const [dok4_ba_evaluasi, setdok4_ba_evaluasi] = useState('');
    const [dok5_ba_klarifikasi, setdok5_ba_klarifikasi] = useState('');
    const [dok6_ba_negosiasi, setdok6_ba_negosiasi] = useState('');
    const [dok7_kontrak_final, setdok7_kontrak_final] = useState('');
    const [dok8_po, setdok8_po] = useState('');
    const [dok9_dokumen_penawaran, setdok9_dokumen_penawaran] = useState('');
    //Plan
    const [id_plan, set_id_plan] = useState('');
    const [tahun_plan, setTahun_plan] = useState('');
    const [bulan_plan, setBulan_plan] = useState('');
    const [tipe_plan, setTipe_plan] = useState('');
    const [jumlah_plan, setJumlah_plan] = useState('');
    const [modeEditPlan, setModeEditPlan] = useState('');
    //Need Revision
    const [note_revision, setNote_revision] = useState('');
    //Addendum Status
    const [note_addendum, setNote_addendum] = useState('');
    //Addendum
    const [modeEditAddendum, setModeEditAddendum] = useState('Add');
    const [id_addendum, setId_addendum] = useState('');
    const [start_date_adden, setStart_date_adden] = useState('');
    const [end_date_adden, setEnd_date_adden] = useState('');
    const [nilai_addendum, setNilai_addendum] = useState('');
    const [update_nilai_kontrak, setupdate_nilai_kontrak] = useState('');
    const [note_adden, setNote_adden] = useState('');
    const [dok1_ba_addendum, setdok1_ba_addendum] = useState('');
    const [dok2_permintaan, setdok2_permintaan] = useState('');
    const [dok3_rev_po, setdok3_rev_po] = useState('');
    const [dok4_kontrak_addendum, setdok4_kontrak_addendum] = useState('');
    const [flag, setflag] = useState(false);
    //realisasi
    const [modeEditReal, setModeEditReal] = useState('Add');
    const [id_realisasi, setId_realisasi] = useState('');
    const [plan_id, setPlan_id] = useState('');
    const [jumlah_real, setJumlah_real] = useState('');
    const [tipe_real, settipe_real] = useState('');
    const [tanggal_real, setTanggal_real] = useState('');
    const [dok_kompilasi, setdok_kompilasi] = useState('');
    const [dok_laporan, setdok_laporan] = useState('');
    const [dok_lain, setdok_lain] = useState('');


    async function uploadFileKontrak(data, dok_type) {
        var file = data.value?.[0]
        var new_file = new File([file], swakelola_id + '-Swakelola-Kontrak-' + file?.name);
        if (file && file.size <= 10000000) {
            try {
                const resp = await globalAction.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            'File': new_file
                        },
                        withFile: true,
                        withLoading: false,
                        withAlert: false,
                    }
                );
                if (resp.code === "00") {
                    if (dok_type === 'dok_1') {
                        setdok1_absensi(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_2') {
                        setdok2_ba_penjelasan(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_3') {
                        setdok3_ba_pembukaan(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_4') {
                        setdok4_ba_evaluasi(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_5') {
                        setdok5_ba_klarifikasi(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_6') {
                        setdok6_ba_negosiasi(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_7') {
                        setdok7_kontrak_final(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_8') {
                        setdok8_po(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_9') {
                        setdok9_dokumen_penawaran(resp.data?.data_filename);
                    }
                }
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }

        } else if (file && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    async function postAddKontrak(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/store-kontrak",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalEditorKontrak');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postEditKontrak(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/update-kontrak",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalEditorKontrak');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postAddPlan(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/store-plan",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                //window.location.reload();
                globalAction.toggleModal('modalEditorPlan');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postEditPlan(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/update-plan",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                // window.location.reload();
                globalAction.toggleModal('modalEditorPlan');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postAddAddendum(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/store-addendum",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                // window.location.reload();
                globalAction.toggleModal('modalEditorAddendum');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postEditAddendum(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/update-addendum",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                //window.location.reload();
                globalAction.toggleModal('modalEditorAddendum');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postAddRealisasi(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/store-real",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                //window.location.reload();
                globalAction.toggleModal('modalEditorReal');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postEditRealisasi(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-swakelola/update-real",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                //window.location.reload();
                globalAction.toggleModal('modalEditorReal');
                swaAction.getDetail(swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function submitNeedRevisionAPI(id, data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: `/api/ext-swakelola/need-revision/${id}`,
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postAddendumAPI(id, data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: `/api/ext-swakelola/addendum/${id}`,
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function uploadFileAddendum(data, dok_type) {
        const file = data.value?.[0]
        var new_file = new File([file], swakelola_id + '-Swakelola-Addendum-' + file?.name);
        if (file && file.size <= 10000000) {
            try {
                const resp = await globalAction.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": new_file,
                        },
                        withFile: true,
                        withLoading: false,
                        withAlert: false,
                    }
                );
                if (resp.code === "00") {
                    if (dok_type === 'dok_1') {
                        setdok1_ba_addendum(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_2') {
                        setdok2_permintaan(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_3') {
                        setdok3_rev_po(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_4') {
                        setdok4_kontrak_addendum(resp.data?.data_filename);
                    }
                }
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }

        } else if (file && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    async function uploadFileReal(data, dok_type) {
        const file = data.value?.[0];
        var new_file = new File([file], swakelola_id + '-Swakelola-Realisasi-' + file?.name);
        if (file && file.size <= 10000000) {
            try {
                const resp = await globalAction.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": new_file,
                        },
                        withFile: true,
                        withLoading: false,
                        withAlert: false,
                    }
                );
                if (resp.code === "00") {
                    if (dok_type === 'dok_kompilasi') {
                        setdok_kompilasi(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_laporan') {
                        setdok_laporan(resp.data?.data_filename);
                    }
                    if (dok_type === 'dok_lain') {
                        setdok_lain(resp.data?.data_filename);
                    }
                }
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }

        } else if (file && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }

    function validationKontrak(data) {
        var isValid = true;
        var message = [];
        if (data?.nilai_kontrak === '') {
            isValid = false;
            message = [...message, 'Nilai Kontrak']
        }
        if (data?.start_date === '' || data?.end_date === '') {
            isValid = false;
            message = [...message, 'Periode Kontrak']
        }
        // if (data?.dok1_absensi === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. Absensi']
        // }
        // if (data?.dok2_ba_penjelasan === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. BA Penjelasan']
        // }
        // if (data?.dok3_ba_pembukaan === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. BA Pembukaan']
        // }
        // if (data?.dok4_ba_evaluasi === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. BA Evaluasi']
        // }
        // if (data?.dok5_ba_klarifikasi === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. BA Klarifikasi']
        // }
        // if (data?.dok7_kontrak_final === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. Kontrak final']
        // }
        // if (data?.dok8_po === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. PO']
        // }
        // if (data?.dok9_dokumen_penawaran === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. Penawaran']
        // }
        var messageString = message.join(', ') + " Tidak boleh kosong";
        if (isValid) {
            if (moment(data?.end_date).isBefore(data?.start_date)) {
                isValid = false;
                messageString = 'Periode Kontrak salah';
            }
        }
        return {
            isValid,
            'message': messageString
        }
    }
    function validationPlan(data) {
        var isValid = true;
        var message = [];
        if (data?.tahun === '') {
            isValid = false;
            message = [...message, 'Tahun']
        }
        if (data?.bulan === '') {
            isValid = false;
            message = [...message, 'Bulan']
        }
        if (data?.tipe === '') {
            isValid = false;
            message = [...message, 'Tipe']
        }
        if (data?.jumlah === '') {
            isValid = false;
            message = [...message, 'Jumlah']
        }
        return {
            isValid,
            'message': message.join(', ') + ' Tidak boleh kosong'
        }

    }
    function validationRealisasi(data) {
        var isValid = true;
        var message = [];
        if (data?.tanggal === '') {
            isValid = false;
            message = [...message, 'Tanggal SA']
        }
        if (data?.plan_id === '') {
            isValid = false;
            message = [...message, 'Plan']
        }
        if (data?.tipe === '') {
            isValid = false;
            message = [...message, 'Tipe pembayaran']
        }
        if (data?.jumlah === '') {
            isValid = false;
            message = [...message, 'Jumlah pembayaran']
        }
        // if (data?.dok_kompilasi === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. Kompilasi']
        // }
        // if (data?.dok_laporan === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. Laporan']
        // }

        return {
            isValid,
            'message': message.join(', ') + ' Tidak boleh kosong'
        }
    }
    function vaidationAddendum(data) {
        var isValid = true;
        var message = [];
        if (data?.nilai_adendum === '') {
            isValid = false;
            message = [...message, 'Nilai Addendum']
        }
        if (data?.end_date === '') {
            isValid = false;
            message = [...message, 'End Date Addendum']
        }
        // if (data?.dok1_ba_addendum === '') {
        //     isValid = false;
        //     message = [...message, 'Dok. BA Addendum']
        // }
        if (data?.dok2_permintaan === '') {
            isValid = false;
            message = [...message, 'Dok. Permintaan']
        }
        if (data?.dok3_rev_po === '') {
            isValid = false;
            message = [...message, 'Dok. Rev. PO']
        }
        if (data?.dok4_kontrak_addendum === '') {
            isValid = false;
            message = [...message, 'Dok. Kontrak Addendum']
        }
        return {
            isValid,
            'message': message.join(', ') + ' Tidak boleh kosong'
        }
    }

    function resetEditorKontrak() {
        set_id_kontrak('');
        setnilai_kontrak('');
        setStart_date('');
        setEnd_date('');
        setNote('');
        setdok1_absensi('');
        setdok2_ba_penjelasan('');
        setdok3_ba_pembukaan('');
        setdok4_ba_evaluasi('');
        setdok5_ba_klarifikasi('');
        setdok6_ba_negosiasi('');
        setdok7_kontrak_final('');
        setdok8_po('');
        setdok9_dokumen_penawaran('');
    }
    function setEditorKontrak(data = {}) {
        set_id_kontrak(data?.id || '');
        setnilai_kontrak(data?.nilai_kontrak || '');
        setStart_date(data?.start_date ? moment(data?.start_date).format('YYYY-MM-DD') : '');
        setEnd_date(data?.start_date ? moment(data?.end_date).format('YYYY-MM-DD') : '');
        setNote(data?.note || '');
        setdok1_absensi(data?.dok1_absensi || '');
        setdok2_ba_penjelasan(data?.dok2_ba_penjelasan || '');
        setdok3_ba_pembukaan(data?.dok3_ba_pembukaan || '');
        setdok4_ba_evaluasi(data?.dok4_ba_evaluasi || '');
        setdok5_ba_klarifikasi(data?.dok5_ba_klarifikasi || '');
        setdok6_ba_negosiasi(data?.dok6_ba_negosiasi || '');
        setdok7_kontrak_final(data?.dok7_kontrak_final || '');
        setdok8_po(data?.dok8_po || '');
        setdok9_dokumen_penawaran(data?.dok9_dokumen_penawaran || '');
    }
    function resetEditorPlan() {
        set_id_plan('');
        setTahun_plan('');
        setBulan_plan('');
        setTipe_plan('');
        setJumlah_plan('');
    }
    function setEditorPlan(data = {}) {
        set_id_plan(data?.id);
        setTahun_plan(data?.tahun || '');
        setBulan_plan(data?.bulan || '');
        setTipe_plan(data?.tipe || '');
        setJumlah_plan(data?.jumlah || '');
    }
    function resetEditorAddendum() {
        setModeEditAddendum('Add');
        setId_addendum('');
        setStart_date_adden('');
        setEnd_date_adden('');
        setNilai_addendum('');
        setupdate_nilai_kontrak('');
        setNote_adden('');
        setdok1_ba_addendum('');
        setdok2_permintaan('');
        setdok3_rev_po('');
        setdok4_kontrak_addendum('');
        setflag(false);
    }
    function setEditorAddendum(data = {}, nilai_kontrak_terbaru, start_date) {
        setId_addendum(data?.id);
        setStart_date_adden(start_date ? moment(start_date).format('YYYY-MM-DD') : '');
        setEnd_date_adden(data?.end_date ? moment(data?.end_date).format('YYYY-MM-DD') : '');
        setNilai_addendum(data?.nilai_adendum);
        setupdate_nilai_kontrak(nilai_kontrak_terbaru);
        setNote_adden(data?.note);
        setdok1_ba_addendum(data?.dok1_ba_addendum);
        setdok2_permintaan(data?.dok2_permintaan);
        setdok3_rev_po(data?.dok3_rev_po);
        setdok4_kontrak_addendum(data?.dok4_kontrak_addendum);
        setflag(data?.flag);
    }
    function resetEditorReal() {
        setId_realisasi('');
        setPlan_id('');
        setTanggal_real('');
        settipe_real('');
        setJumlah_real('');
        setdok_kompilasi('');
        setdok_laporan('');
        setdok_lain('');
    }
    function setEditorReal(data = {}) {
        setId_realisasi(data?.id || '');
        setPlan_id(data?.plan_id || '');
        setTanggal_real(data?.tanggal ? moment(data?.tanggal).format('YYYY-MM-DD') : '');
        settipe_real(data?.tipe || '');
        setJumlah_real(data?.jumlah || '');
        setdok_kompilasi(data?.dok_kompilasi || '');
        setdok_laporan(data?.dok_laporan || '');
        setdok_lain(data?.dok_lain || '');
    }

    function saveButtonClick() {
        if (modeEditKontrak === 'Add') {
            addKontrak()
        }
        if (modeEditKontrak === 'Edit') {
            editKontrak();
        }
    }
    function saveButtonClickPLan() {
        if (modeEditPlan === 'Add') {
            addPlan()
        }
        if (modeEditPlan === 'Edit') {
            editPlan();
        }
    }
    function saveButtonClickAddendum() {
        if (modeEditAddendum === 'Add') {
            addAddendum()
        }
        if (modeEditAddendum === 'Edit') {
            editAddendum();
        }
    }
    function saveButtonClickReal() {
        if (modeEditReal === 'Add') {
            addReal()
        }
        if (modeEditReal === 'Edit') {
            editReal();
        }
    }
    function needRevision() {
        const data = {
            "id": '',
            "swakelola_id": swakelola_id,
            "judul": '',
            "pic": '',
            "section_manager": '',
            "persetujuan_oleh": '',
            "dok1_kak": '',
            "dok2_owner_est": "",
            "dok3_purchase_req": "",
            "dok4_pakta_int": "",
            "dok5_justifikasi": "",
            "dok6_lainnya": "",
            "owner_est": '',
            "start_date": "",
            "end_date": "",
            "note": note_revision || ''
        }
        submitNeedRevisionAPI(swakelola_id, data);
    }
    function addendum() {
        const data = {
            "id": '',
            "swakelola_id": swakelola_id,
            "judul": '',
            "pic": '',
            "section_manager": '',
            "persetujuan_oleh": '',
            "dok1_kak": '',
            "dok2_owner_est": "",
            "dok3_purchase_req": "",
            "dok4_pakta_int": "",
            "dok5_justifikasi": "",
            "dok6_lainnya": "",
            "owner_est": '',
            "start_date": "",
            "end_date": "",
            "note": note_addendum || ''
        }
        postAddendumAPI(swakelola_id, data);
    }

    function addPlan() {
        const data = {
            "id": id_plan,
            "swakelola_id": swakelola_id,
            "tahun": tahun_plan || '',
            "bulan": bulan_plan || '',
            "tipe": tipe_plan || '',
            "jumlah": jumlah_plan || ''
        }
        const validate = validationPlan(data);
        if (validate.isValid === true) {

            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Plan?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postAddPlan(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function editPlan() {
        const data = {
            "id": id_plan,
            "swakelola_id": swakelola_id,
            "tahun": tahun_plan || '',
            "bulan": bulan_plan || '',
            "tipe": tipe_plan || '',
            "jumlah": jumlah_plan || ''
        }
        const validate = validationPlan(data);
        if (validate.isValid === true) {

            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Plan?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postEditPlan(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function addKontrak() {
        const data = {
            "id": id_kontrak,
            "swakelola_id": swakelola_id,
            "tanggal": "",
            "nilai_kontrak": nilai_kontrak || '',
            "start_date": start_date ? moment(start_date).format('YYYY-MM-DD') : '',
            "end_date": end_date ? moment(end_date).format('YYYY-MM-DD') : '',
            "note": note || '',
            "dok1_absensi": dok1_absensi || '',
            "dok2_ba_penjelasan": dok2_ba_penjelasan || '',
            "dok3_ba_pembukaan": dok3_ba_pembukaan || '',
            "dok4_ba_evaluasi": dok4_ba_evaluasi || '',
            "dok5_ba_klarifikasi": dok5_ba_klarifikasi || '',
            "dok6_ba_negosiasi": dok6_ba_negosiasi || '',
            "dok7_kontrak_final": dok7_kontrak_final || '',
            "dok8_po": dok8_po || '',
            "dok9_dokumen_penawaran": dok9_dokumen_penawaran || ''
        }
        const validate = validationKontrak(data);
        if (validate.isValid === true) {

            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Kontrak?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postAddKontrak(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function editKontrak() {
        const data = {
            "id": id_kontrak,
            "swakelola_id": swakelola_id,
            "tanggal": "",
            "nilai_kontrak": nilai_kontrak || '',
            "start_date": start_date ? moment(start_date).format('YYYY-MM-DD') : '',
            "end_date": end_date ? moment(end_date).format('YYYY-MM-DD') : '',
            "note": note || '',
            "dok1_absensi": dok1_absensi || '',
            "dok2_ba_penjelasan": dok2_ba_penjelasan || '',
            "dok3_ba_pembukaan": dok3_ba_pembukaan || '',
            "dok4_ba_evaluasi": dok4_ba_evaluasi || '',
            "dok5_ba_klarifikasi": dok5_ba_klarifikasi || '',
            "dok6_ba_negosiasi": dok6_ba_negosiasi || '',
            "dok7_kontrak_final": dok7_kontrak_final || '',
            "dok8_po": dok8_po || '',
            "dok9_dokumen_penawaran": dok9_dokumen_penawaran || ''
        }
        const validate = validationKontrak(data);
        if (validate.isValid === true) {

            // console.log(data)
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Kontrak?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postEditKontrak(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function addAddendum() {
        const data = {
            "id": id_addendum,
            "swakelola_id": swakelola_id,
            "tanggal": "",
            "nilai_adendum": (nilai_addendum === null || nilai_addendum === undefined) ? '' : nilai_addendum,
            "update_nilai_kontrak": parseFloat(update_nilai_kontrak || '0', 0) + parseFloat(nilai_addendum || '0', 0),
            "start_date": start_date_adden,
            "end_date": end_date_adden || '',
            "note": note_adden || '',
            "dok1_ba_addendum": dok1_ba_addendum || '',
            "dok2_permintaan": dok2_permintaan || '',
            "dok3_rev_po": dok3_rev_po || '',
            "dok4_kontrak_addendum": dok4_kontrak_addendum || '',
            "flag": flag
        }
        const validate = vaidationAddendum(data);
        if (validate.isValid === true) {

            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Addendum?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postAddAddendum(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function editAddendum() {
        const data = {
            "id": id_addendum,
            "swakelola_id": swakelola_id,
            "tanggal": "",
            "nilai_adendum": (nilai_addendum === null || nilai_addendum === undefined) ? '' : nilai_addendum,
            "update_nilai_kontrak": parseFloat(update_nilai_kontrak || '0', 0) + parseFloat(nilai_addendum || '0', 0),
            "start_date": start_date_adden,
            "end_date": end_date_adden || '',
            "note": note_adden || '',
            "dok1_ba_addendum": dok1_ba_addendum || '',
            "dok2_permintaan": dok2_permintaan || '',
            "dok3_rev_po": dok3_rev_po || '',
            "dok4_kontrak_addendum": dok4_kontrak_addendum || '',
            "flag": flag
        }
        // console.log(data)

        const validate = vaidationAddendum(data);
        if (validate.isValid === true) {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Addendum?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postEditAddendum(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function addReal() {
        const data = {
            "id": id_realisasi,
            "swakelola_id": swakelola_id,
            "plan_id": plan_id || '',
            "tanggal": tanggal_real || '',
            "jumlah": jumlah_real || '',
            "tipe": tipe_real || '',
            "dok_kompilasi": dok_kompilasi || '',
            "dok_laporan": dok_laporan || '',
            "dok_lain": dok_lain || ''
        }
        const validate = validationRealisasi(data);
        if (validate.isValid === true) {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Realisasi?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postAddRealisasi(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function editReal() {
        const data = {
            "id": id_realisasi,
            "swakelola_id": swakelola_id,
            "plan_id": plan_id || '',
            "tanggal": tanggal_real || '',
            "jumlah": jumlah_real || '',
            "tipe": tipe_real || '',
            "dok_kompilasi": dok_kompilasi || '',
            "dok_laporan": dok_laporan || '',
            "dok_lain": dok_lain || ''
        }
        const validate = validationRealisasi(data);
        if (validate.isValid === true) {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Realisasi?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    postEditRealisasi(data);
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }

    function batalKontrak() {
        globalAction.toggleModal('modalEditorKontrak');
    }
    function batalPlan() {
        globalAction.toggleModal('modalEditorPlan');
    }
    function batalReal() {
        globalAction.toggleModal('modalEditorReal');
    }
    function batalEditorAddendum() {
        globalAction.toggleModal('modalEditorAddendum');
    }
    function batalNeedRevision() {
        globalAction.toggleModal('modalNeedRevision');
    }
    function batalAddendum() {
        globalAction.toggleModal('modalAddendum');
    }

    function getYear() {
        const y = Array.from(Array(6), (d, i) => {
            const x = moment().add((-3 + i), 'years').format('YYYY');
            return {
                value: x,
                name: x
            }
        })
        return y;
    }
    function nilaiAddendumChange(val) {
        const nil = val || '0';
        setNilai_addendum(parseFloat(nil, 0))
    }

    return <swaEditContext.Provider
        value={{
            swaEditAction: {
                resetEditorKontrak,
                setEditorKontrak,
                resetEditorPlan,
                setEditorPlan,
                resetEditorAddendum,
                setEditorAddendum,
                resetEditorReal,
                setEditorReal,
                saveButtonClick,
                saveButtonClickPLan,
                saveButtonClickAddendum,
                saveButtonClickReal,
                setSwakelola_id,
                getYear,
                handleKontrak: {
                    setnilai_kontrak,
                    setStart_date,
                    setEnd_date,
                    setNote,
                    uploadFileKontrak,
                    batalKontrak,
                    setModeEditKontrak
                },
                handlePlan: {
                    batalPlan,
                    setModeEditPlan,
                    setTahun_plan,
                    setBulan_plan,
                    setTipe_plan,
                    setJumlah_plan
                },
                handleNeedRevision: {
                    setNote_revision,
                    batalNeedRevision,
                    needRevision
                },
                handleAddendum: {
                    setNote_addendum,
                    batalAddendum,
                    addendum
                },
                handleEditorAddendum: {
                    nilaiAddendumChange,
                    setNote_adden,
                    setEnd_date_adden,
                    uploadFileAddendum,
                    setflag,
                    setModeEditAddendum,
                    batalEditorAddendum
                },
                handleReal: {
                    uploadFileReal,
                    setModeEditReal,
                    setTanggal_real,
                    settipe_real,
                    setPlan_id,
                    setJumlah_real,
                    batalReal,

                }
            },
            swaEditState: {
                dataKontrak: {
                    nilai_kontrak,
                    start_date,
                    end_date,
                    note,
                    dok1_absensi,
                    dok2_ba_penjelasan,
                    dok3_ba_pembukaan,
                    dok4_ba_evaluasi,
                    dok5_ba_klarifikasi,
                    dok6_ba_negosiasi,
                    dok7_kontrak_final,
                    dok8_po,
                    dok9_dokumen_penawaran
                },
                dataPlan: {
                    tahun_plan,
                    bulan_plan,
                    tipe_plan,
                    jumlah_plan
                },
                dataNeedRevision: {
                    note_revision
                },
                dataAddendum: {
                    note_addendum
                },
                dataEditorAddendum: {
                    start_date_adden,
                    end_date_adden,
                    nilai_addendum,
                    update_nilai_kontrak,
                    note_adden,
                    dok1_ba_addendum,
                    dok2_permintaan,
                    dok3_rev_po,
                    dok4_kontrak_addendum,
                    flag
                },
                dataReal: {
                    tanggal_real,
                    tipe_real,
                    jumlah_real,
                    plan_id,
                    dok_kompilasi,
                    dok_laporan,
                    dok_lain
                }
            }
        }}
    >
        {children}
    </swaEditContext.Provider>

}

export default function useModalEditorSwa() {
    return useContext(swaEditContext);
}