import React from 'react';
import { HeaderList, Tab, TabContent } from '../../../components';
import { Card } from 'react-bootstrap';
import MyLab from './tabContent/myLab';
import useLab, { LabProvider } from './useLaboratory';
import './index.css';
import ProductLab from './tabContent/productLab';
import { useGlobalState } from '../../../contexts/useGlobalState';
import Dashboard from './tabContent/dashboard';

function Index(props) {
    const [state] = useGlobalState();
    const { labAction, labState } = useLab();

    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={"Laboratory"}
                someText={""}
            />
            <div className="row">
                <div className="col-12">
                    <Tab
                        withCard={false}
                        itemsTab={["22"].includes(state.dataUser?.role_id) ? [
                            {
                                id: "home",
                                title: "Product Lab",
                                onClick: () => labAction.tabChangeHandle("home")
                            },
                            {
                                id: "my-lab",
                                title: "Lab Testing List",
                                onClick: () => labAction.tabChangeHandle("my-lab")
                            }
                        ] : [
                            {
                                id: "home",
                                title: "Product Lab",
                                onClick: () => labAction.tabChangeHandle("home")
                            },
                            {
                                id: "dashboard",
                                title: "Dashboard",
                                onClick: () => labAction.tabChangeHandle("dashboard")
                            },
                            {
                                id: "my-lab",
                                title: "Lab Testing List",
                                onClick: () => labAction.tabChangeHandle("my-lab")
                            }
                        ]}
                        manualActive={labState.tabActive}
                    >
                        <TabContent id="home">
                            <ProductLab />                            
                        </TabContent>
                        <TabContent id="dashboard">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <Dashboard />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                        <TabContent id="my-lab">
                            <Card
                                className="m-0 p-0 mb-5 shadow"
                            >
                                <Card.Body className="p-2">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-12 ">
                                            <MyLab />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </TabContent>
                    </Tab>
                </div>
            </div>
        </div>
    );
}

export default () => <LabProvider><Index /></LabProvider>;