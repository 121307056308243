import React, { useState, useRef, useEffect } from 'react';
import { FileUploadDx, FormResearch, Modal, RadioGrp } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import { dokPenType } from '../../components/utility/utility';
import { Accordion, Autocomplete } from 'devextreme-react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import Tags from "@yaireo/tagify/dist/react.tagify"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { toLower, toString } from 'lodash';

const TabDetail = ({ param }) => {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const { pathname } = useLocation();
    const temp = pathname.slice(1, pathname.length);
    const path = temp.substr(0, temp.indexOf('/'));
    const par = useParams();
    const [fileType, setFileType] = useState();
    const tagref = useRef();
    const [disable, setDisable] = useState(false);
    useEffect(() => {
        if (param.id === null) {
            history.push("/error-page")
        }
        getData();
    }, [])

    useEffect(() => {
        if (state.formData.deletekeywordTemp) {
            if (state.formData.deletekeywordTemp?.is_new === false) {
                const d = {
                    "name": state.formData.deletekeywordTemp?.value,
                    "tag_id": '',
                    "table_name": '',
                    "data_id": '',
                    "is_new": state.formData.deletekeywordTemp?.is_new
                };
                action.setForm('deletekeyword', [d, ...(state.formData.deletekeyword || [])])
            }
        }
    }, [state.formData.deletekeywordTemp])
    function getData() {
        const disable = state.formData.disable;
        action.resetForm();
        action.setForm('disable', disable);
        action.getData("/api/project-portfolio-crud/view-detail/" + param?.id, {}, null, "isLoading", true).then((resp) => {
            if (resp.code === "00") {
                const data_area = resp.data.data_area.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                const data_dukungan_operasional = resp.data.data_dukungan_operasional.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_expertise_group = resp.data.data_expertise_group.map(d => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                const document = resp.data.data_view_documents.map((d, i) => {
                    return {
                        "file_location": d.file_location,
                        "tipe_document": d.file_location ? "file" : "url",
                        "is_new": false,
                        "file_name": d.name,
                        "link": d.link
                    }
                })
                const data_sat_pendapatan = resp.data.data_sat_pendapatan.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_sat_produksi = resp.data.data_sat_produksi.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_sat_efisiensi = resp.data.data_sat_efisiensi.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_potensial_partner = resp.data.data_potensial_partner.map((d, i) => {
                    return {
                        name: d.title,
                        value: d.param_id
                    }
                })
                const data_related_research = resp.data.data_related_research.map((d, i) => {
                    return {
                        title: d.title,
                        related_id: d.related_id
                    }
                })

                const data_duk = [];
                if (resp.data.data_view_detail?.duk_operasional_opt_0) {
                    const t = data_dukungan_operasional.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_0.toString())
                    if (t) {
                        data_duk.push({
                            name: t?.name,
                            value: t?.value
                        })
                    }
                }
                if (resp.data.data_view_detail?.duk_operasional_opt_1) {
                    const t = data_dukungan_operasional.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_1.toString())
                    if (t) {
                        data_duk.push({
                            name: t?.name,
                            value: t?.value
                        })
                    }
                }
                if (resp.data.data_view_detail?.duk_operasional_opt_2) {
                    const t = data_dukungan_operasional.find(({ value }) => value.toString() === resp.data.data_view_detail?.duk_operasional_opt_2.toString())
                    if (t) {
                        data_duk.push({
                            name: t?.name,
                            value: t?.value
                        })
                    }
                }
                const version = [];
                for (let index = 0; index < (parseFloat(resp.data.data_view_header?.baseline_name) || 0); index++) {
                    version.push({
                        name: index + 1,
                        value: index + 1
                    })
                }
                dispatch({
                    type: "getSuccess",
                    payload: { data_version: version, loadingGet: false }
                })
                const t = [...(resp.data.data_view_keywords || [])].map(d => { return { value: d.name, is_new: false } })
                //Data Option
                action.setForm('portfolio_data_page', resp.data_page);

                action.setForm('data_area', data_area);
                action.setForm('data_dukungan_operasional', data_dukungan_operasional);
                action.setForm('data_expertise_group', data_expertise_group);
                action.setForm('data_sat_pendapatan', data_sat_pendapatan);
                action.setForm('data_sat_produksi', data_sat_produksi);
                action.setForm('data_sat_efisiensi', data_sat_efisiensi);
                action.setForm('data_potensial_partner', data_potensial_partner);
                action.setForm('data_related_research', data_related_research);
                //Data Value Default
                action.setForm('realated_document', resp.data.data_view_related_research);
                action.setForm('defkeyword', t)
                action.setForm('keyword', t)
                action.setForm("dukungan_operational", data_duk);
                action.setForm("sumberFileTemp", [...document])
                action.setForm("sumberFile", [...document])
                action.setForm('stage', resp.data.data_view_detail?.stage || "VII");
                action.setForm('duk_operasional_ket', resp.data.data_view_detail?.duk_operasional_ket)
                action.setForm('potensial_partner_opt', resp.data.data_view_detail?.potensial_partner_opt)
                action.setForm('method', resp.data.data_view_detail?.method)
                action.setForm('potensial_partner_ket', resp.data.data_view_detail?.potensial_partner_ket)
                action.setForm('pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.pendapatan)
                action.setForm('efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.efisiensi)
                action.setForm('produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.produksi)
                action.setForm('sat_pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_pendapatan)
                action.setForm('sat_efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_efisiensi)
                action.setForm('sat_produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.sat_produksi)
                action.setForm('ket_pendapatan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_pendapatan)
                action.setForm('ket_efisiensi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_efisiensi)
                action.setForm('ket_produksi', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.ket_produksi)
                action.setForm('tangible_angka', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.tangible_angka);
                action.setForm('tangible_keterangan', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.tangible_keterangan);
                action.setForm('intangible', (Array.isArray(resp.data.data_view_value_creations) ? { ...resp.data.data_view_value_creations?.[0] } : resp.data.data_view_value_creations)?.intangible)
                action.setForm('potential_user', resp.data.data_view_detail?.potential_user)
                action.setForm('jangka_waktu', resp.data.data_view_detail?.jangka_waktu)
                action.setForm('research_category_id', resp.data.data_view_detail?.research_category_id)
                action.setForm('description', resp.data.data_view_detail?.description)
                action.setForm('title', resp.data.data_view_detail?.title)
                action.setForm('purpose', resp.data.data_view_detail?.purpose)
                action.setForm('area_id', resp.data.data_view_detail?.area_id)
                action.setForm('focus', resp.data.data_view_detail?.focus)
                areaChange(resp.data.data_view_detail.area_id)
                getLeader(resp.data.data_view_team_members)
                getMember(resp.data.data_view_team_members)
                setKeahlian(resp.data.data_view_expertise_needed)
            }
        })

    }
    useEffect(() => {
        setDisable(approvalEditing(state.formData.disable));
    }, [state.formData.disable])

    function areaChange(val) {
        action.setForm("area_id", val)
        if (val) {
            action.getData('/api/param-area-category/' + val, null, 'kategory').then((resp) => {
                if (resp.code === "00") {
                    const newDs = resp.data.map((d, i) => {
                        return {
                            name: d.name,
                            value: d.id
                        }
                    })
                    dispatch({
                        type: "getSuccess",
                        payload: { kategory: newDs, loadingGet: false }
                    })
                }
            });
        } else {
            action.setForm("research_category_id", '')
        }
    }
    function getLeader(data) {
        if (data) {
            const disctinctLeaderName = Array.from([... new Set((data.filter(obj => obj.team_role === "Leader")).map(s => s))]).map(d => {
                if (d.user_id !== null) {
                    return {
                        user_id: d.user_id,
                        employee_id: d.employee_id,
                        name: d.name,
                        section_name: d.section_name,
                        position_name: d.position_name,
                        function_short_name: d.function_short_name,
                        technical_competency: d.technical_competency
                    }
                }
            })
            action.setForm("leader", disctinctLeaderName?.[0])
        }
    }
    function getMember(data) {
        if (data) {
            const disctinctMamberName = Array.from([... new Set((data.filter(obj => obj.team_role === "Member")).map(s => s))]).map(d => {
                return {
                    user_id: d.user_id,
                    employee_id: d.employee_id,
                    name: d.name,
                    is_new: false,
                    section_name: d.section_name,
                    function_short_name: d.function_short_name,
                    position_name: d.position_name,
                    technical_competency: d.technical_competency
                }

            })
            action.setForm("anggota", disctinctMamberName)
        }
    }
    function setKeahlian(data) {
        const newds = [...(data || [])].map(d => {
            return {
                value: d?.expertise_id,
                is_new: false,
                name: d?.technical_competency
            }
        })
        action.setForm("keahlian", newds)
    }

    function approvalEditing(def) {
        let result = def;
        if (toLower(path) === 'admin' && toLower(par?.menu) === 'approval') {
            result = false;
        }
        return result;
    }
    function expertGroupChange(val) {
        action.setForm("keahlian_group", val)
        action.setForm("keahlian_sub_group", '')
        action.setForm("selected_keahlian", '')
        action.getData('/api/param-expertise-sub-group/' + val, null, 'expertise_sub_group').then((resp) => {
            if (resp.code === "00") {
                const newDs = resp.data.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { expertise_sub_group: newDs, loadingGet: false }
                })
            }
        });
    }
    function expertSubGroupChange(val) {
        action.setForm("keahlian_sub_group", val)
        action.setForm("selected_keahlian", '')
        action.getData('/api/param-expertise-detail/' + val, null, 'expertise_detail').then((resp) => {
            if (resp.code === "00") {
                const newDs = resp.data.map((d, i) => {
                    return {
                        name: d.technical_competency,
                        value: d.id
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { expertise_detail: newDs, loadingGet: false }
                })
            }
        });
    }
    function addKeahlian(val) {
        if (val) {
            const t = [...(state.expertise_detail || [])].find(({ value }) => value.toString() === val.toString())
            const newData = state.formData.keahlian || [];
            const fn = state.formData.keahlian?.find(kh => kh.value.toString() === t?.value.toString());
            if (!fn) {
                newData.splice(newData, 0, {
                    value: t?.value,
                    is_new: true,
                    name: t?.name
                })
                action.setForm("keahlian", newData);
            }
        }
    }
    function deleteKeahlian(data) {
        const newData = state.formData.keahlian;
        const index = newData.indexOf(data);
        if (data.is_new === false) {
            const newd = state.formData.deletedKeahlian || [];
            newd.splice(newd, 0, data)
            action.setForm('deletedKeahlian', newd)
        }
        newData.splice(index, 1)
        action.setForm("keahlian", newData);
    }
    function addDukunganOP(val) {
        if (val) {
            const t = [...(state.formData.data_dukungan_operasional || [])].find(({ value }) => value.toString() === val.toString())
            const newData = state.formData.dukungan_operational || [];
            if (newData.length < 3) {
                const fn = state.formData.dukungan_operational?.find(op => op.value.toString() === t?.value.toString())
                if (!fn) {
                    newData.splice(state.formData.dukungan_operational, 0, {
                        value: t?.value,
                        name: t?.name
                    })
                    action.setForm("dukungan_operational", newData);
                }
            }
        }
    }
    function deleteDukunganOP(key) {
        const newData = state.formData.dukungan_operational || [];
        const index = newData.indexOf(key);
        newData.splice(index, 1);
        action.setForm("dukungan_operational", newData);
    }
    function deleteAnggota(data) {
        const newData = state.formData.anggota || [];
        const index = newData.indexOf(data)
        if (data.is_new === false) {
            const newd = state.formData.deleteAnggota || [];
            newd.splice(newd, 0, data)
            action.setForm('deleteAnggota', newd)
        }
        newData.splice(index, 1);
        action.setForm("anggota", newData);

    }
    function addAnggota(value) {
        if (value) {
            const newData = state.formData.anggota || [];
            const fnd = state.formData.anggota?.find(sa => sa.user_id.toString() === value.user_id.toString())
            if (!fnd) {
                newData.push({
                    user_id: value?.user_id,
                    employee_id: value?.employee_id,
                    name: value?.name,
                    position_name: value?.position_name,
                    is_new: true,
                    section_name: value?.section_name,
                    function_short_name: value?.function_short_name,
                    technical_competency: value?.technical_competency
                });
                action.setForm("anggota", newData);
            }
        }
    }
    function renderTitle(data) {
        return (
            <div className="m-2" style={{ fontSize: "10px", fontFamily: 'Poppins' }}><i className="fa flaticon-user-ok p-2"></i>{data?.name}</div>
        )
    }
    function renderItem(data) {
        return (
            <div className="row h-100 p-5 border-top" style={{ fontFamily: 'Poppins' }}>
                <div className="col-md-4 mb-5">
                    <img src={data?.image || "/media/svg/icons/General/User.svg"}
                        style={{ width: '100%' }}
                    ></img>
                </div>
                <div className="col-md-8 mb-5">
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Nama</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Posisi</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.position_name}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>No. Pegawai</div>
                        <div className="col-auto" style={{ fontSize: '8pt' }}>: {data?.employee_id}</div>
                    </div>
                    <div className="row" style={{ paddingBottom: 10 }}>
                        <div className="col-3" style={{ fontSize: '9pt' }}>Expertise</div>
                        <div className="col-auto"></div>
                    </div>
                    {
                        data.technical_competency?.split(',').map((s, i) => (
                            <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                <div className="col-3"></div>
                                <div className="col-9" style={{ fontSize: '8pt' }}>{i + 1}.&nbsp;{s}</div>
                            </div>
                        ))
                    }
                    <div className="d-flex flex-row-reverse" style={{ paddingBottom: 10 }}>
                        {
                            disable === false && (
                                <button className="btn btn-sm btn-outline-danger" onClick={() => deleteAnggota(data)}><i className="fa fa-trash icon-sm"></i> Remove</button>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
    function fileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            const newData = state.formData.sumberFileTemp || [];
            newData.splice(state.formData.sumberFileTemp, 0, {
                "file_location": resp.data?.data_file_location,
                "tipe_document": "file",
                "file_name": resp.data?.data_filename,
                "is_new": true,
                "link": ''
            })
            action.setForm("sumberFileTemp", newData);

            const newD = state.formData.sumberFile || [];
            newD.splice(state.formData.sumberFile, 0, {
                "file_location": resp.data?.data_file_location,
                "tipe_document": "file",
                "file_name": resp.data?.data_filename,
                "is_new": true,
                "link": ''
            })
            action.setForm("sumberFile", newD);
        }
    }
    function addSumberFile(value) {
        if (value.tipe_document === "url" && value.link !== null && value.link !== undefined && value.link !== "") {
            const newData = state.formData.sumberFileTemp || [];
            newData.splice(state.formData.sumberFileTemp, 0, value)
            action.setForm("sumberFileTemp", newData);

            const newD = state.formData.sumberFile || [];
            newD.splice(state.formData.sumberFile, 0, value)
            action.setForm("sumberFile", newD);
        }
        if (value.tipe_document === "file") {
            const newData = state.formData.sumberFileTemp || [];
            newData.splice(state.formData.sumberFileTemp, 0, value)
            action.setForm("sumberFileTemp", newData);

            const newD = state.formData.sumberFile || [];
            newD.splice(state.formData.sumberFile, 0, value)
            action.setForm("sumberFile", newD);
        }
    }
    function deletedDocument(key, data) {
        const newData = state.formData.sumberFileTemp || []
        const indexOf = newData.indexOf(data)

        if (!data.is_new) {
            newData.splice(indexOf, 1)
            action.setForm("sumberFileTemp", newData);

            const newd = state.formData.deletedDocument || [];
            newd.splice(state.formData.deletedDocument, 0, data)
            action.setForm("deletedDocument", newd);
        } else {
            deleteSumberFile(indexOf, data)
            const newD = state.formData.sumberFile || [];
            const index = newD.indexOf(data)
            newD.splice(index, 1)
            action.setForm("sumberFile", newD);
        }
    }
    function deleteSumberFile(key, file) {
        if (file.tipe_document === "file") {
            action.postData(
                {
                    url: "/api/base/delete-file-temp?fileName=" + file.file_name,
                    withFile: false,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code !== "00") {
                    alert(resp.message)
                } else {
                    const newData = state.formData.sumberFileTemp || []
                    newData.splice(key, 1)
                    action.setForm("sumberFileTemp", newData);
                }
            })
        } else {
            const newData = state.formData.sumberFileTemp || []
            newData.splice(key, 1)
            action.setForm("sumberFileTemp", newData);
        }
    }
    function getFungsi() {
        let newDs = [
            ...([...(state.formData.anggota || [])].map(d => {
                return {
                    user_id: d.user_id,
                    section_name: d.section_name,
                    function_short_name: d.function_short_name
                }
            }))
        ]
        if (state.formData.leader) {
            newDs = [
                { user_id: state.formData.leader?.user_id, section_name: state.formData.leader?.section_name, function_short_name: state.formData.leader?.function_short_name },
                ...newDs
            ]
        }
        return newDs
    }
    function addRelated(data) {
        if (data) {
            const newDs = [...(state.formData.realated_document || [])]
            const fnd = newDs?.find(sa => sa.related_id.toString() === data.related_id.toString())
            if (!fnd) {
                newDs.splice(newDs, 0, {
                    title: data?.title,
                    related_id: data?.related_id,
                    is_new: true,
                });
                action.setForm("realated_document", newDs);
            }
        }
    }
    function deleteRelatedDoc(key) {
        const newData = [...(state.formData.realated_document || [])]
        const index = newData.indexOf(key)
        if (key.is_new === false) {
            const newd = [...(state.formData.realated_document_deleted || [])];
            newd.splice(newd, 0, key)
            action.setForm('realated_document_deleted', newd)
        }
        newData.splice(index, 1);
        action.setForm("realated_document", newData);
    }
    function validateUpdateDetail(data) {
        var isValid = true;
        var message = [];
        if (data.project_type === 5) {
            if (data.title === ''
            ) {
                isValid = false
                message = [...message, `Judul`];
            }
            if (data.description === '') {
                isValid = false
                message = [...message, `Pain Point`];
            }
            if (data.purpose === '') {
                isValid = false
                message = [...message, `Commercialization /Implementation- Objective`];
            }
            if (data.keyword.length === 0) {
                isValid = false
                message = [...message, `Keyword`];
            }
            if (data.owner === '') {
                isValid = false
                message = [...message, `Leader`];
            }
            if (data.team_members.length === 0) {
                isValid = false
                message = [...message, `Member`];
            }
        } else {
            if (data.title === '') {
                isValid = false
                message = [...message, `Judul`];
            }
            if (data.description === '') {
                isValid = false
                message = [...message, `Pain Point`];
            }
            if (data.purpose === '') {
                isValid = false
                message = [...message, `Commercialization /Implementation- Objective`];
            }
            if ([...(state.formData.dukungan_operational || [])].length === 0) {
                isValid = false
                message = [...message, `Dukungan OP`];
            }
            if (data.potential_user === '') {
                isValid = false
                message = [...message, `Pemakai Potensial`];
            }
            if (data.keyword.length === 0) {
                isValid = false
                message = [...message, `Keyword`];
            }
            if (data.owner === '') {
                isValid = false
                message = [...message, `Leader`];
            }
            if (data.team_members.length === 0) {
                isValid = false
                message = [...message, `Member`];
            }
            if (data.expertise_neededs.length === 0) {
                isValid = false
                message = [...message, `Keahlian`];
            }
            if (data.research_category_id === "" || data.research_category_id === null){                
                isValid = false
                message = [...message, `Kategory Research`];
            }
            if (data.method === '') {
                isValid = false
                message = [...message, `Metode Riset`];
            }
            if (data.method === '2' || data.method === '3' || data.method === '4') {
                if (data.potensial_partner_opt === '' || data.potensial_partner_ket === '') {
                    isValid = false;
                    message = [...message, `Potensial partner`];
                }
            }
            if (data.tangible_angka === '') {
                isValid = false
                message = [...message, `Tangible value`];
            }
            if (data.tangible_keterangan === '') {
                isValid = false
                message = [...message, `Tangible ket.`];
            }                        
            if (toString(data.project_type) === '1' || toString(data.project_type) === '2' || toString(data.project_type) === '4') {
                if (data.stage === '') {
                    isValid = false;
                    message = [...message, `Stage`];
                }
            }
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        };
    }
    function updateDetail() {
        const tmp = state.formData.keyword ? [...((typeof state.formData.keyword === 'string' ? JSON.parse(state.formData.keyword) : state.formData.keyword) || [])] : [];
        const keyword = tmp.map(d => {
            return {
                "name": d.value,
                "tag_id": '',
                "table_name": '',
                "data_id": '',
                "is_new": d?.is_new === false ? d?.is_new : true
            }
        })
        const data = {
            "id": state.data_view_header?.id || '',
            "project_type": state.data_view_header?.project_type || '',
            "research_category_id": state.formData.research_category_id || '',
            "chief_manager_id": state.data_view_header?.chief_manager_id || '',
            "title": state.formData.title || '',
            "description": state.formData.description || '',
            "purpose": state.formData.purpose || '',
            "initiator": state.data_view_detail?.initiator || '',
            "created_at": state.data_view_header?.created_at_form || '',
            "updated_at": state.data_view_header?.updated_at_form || '',
            "year_ideation": state.data_view_header?.year_ideation || '',
            "baseline_name": state.data_view_detail?.baseline_name || '',
            "jangka_waktu": state.formData.jangka_waktu || '',
            "duk_operasional_opt_0": [...(state.formData.dukungan_operational || [])]?.[0]?.value || '',
            "duk_operasional_opt_1": [...(state.formData.dukungan_operational || [])]?.[1]?.value || '',
            "duk_operasional_opt_2": [...(state.formData.dukungan_operational || [])]?.[2]?.value || '',
            "duk_operasional_ket": state.formData.duk_operasional_ket || '',
            "potential_user": state.formData.potential_user || '',
            "owner": state.formData.leader?.user_id || '',
            "method": state.formData.method || '',
            "potensial_partner_opt": (state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ? (state.formData.potensial_partner_opt || '') : '',
            "potensial_partner_ket": (state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ? (state.formData.potensial_partner_ket || '') : '',
            "project_code": state.data_view_header?.project_code || '',
            "project_unggulan": state.data_view_header?.project_unggulan || '',
            "stage": state.formData.stage || '',
            "document": [...(state.formData.sumberFileTemp || [])],
            "document_delete": (state.formData.deletedDocument || []),//state.data_view_header?.submit_status === 0 ? [] : (state.formData.deletedDocument || []),
            "expertise_neededs": [...(state.formData.keahlian || [])].map(d => {
                return {
                    "expertise_id": d?.value,
                    "is_new": d?.is_new
                }
            }),
            "expertise_neededs_delete": [...(state.formData.deletedKeahlian || [])].map(d => {
                return {
                    "expertise_id": d?.value,
                    "is_new": d?.is_new
                }
            }),
            "keyword": keyword,
            "keyword_delete": state.formData.deletekeyword || [],
            "related_researchs": state.formData.realated_document || [],
            "related_researchs_delete": state.formData.realated_document_deleted || [],
            "team_members": [...(state.formData.anggota || [])].map(d => { return { user_id: d.user_id, is_new: d.is_new } }),
            "team_members_delete": [...(state.formData.deleteAnggota || [])].map(d => { return { user_id: d.user_id, is_new: d.is_new } }),
            "pendapatan": state.formData.pendapatan || '',
            "efisiensi": state.formData.efisiensi || '',
            "produksi": state.formData.produksi || '',
            "sat_pendapatan": state.formData.sat_pendapatan || '',
            "sat_efisiensi": state.formData.sat_efisiensi || '',
            "sat_produksi": state.formData.sat_produksi || '',
            "ket_pendapatan": state.formData.ket_pendapatan || '',
            "ket_efisiensi": state.formData.ket_efisiensi || '',
            "ket_produksi": state.formData.ket_produksi || '',
            "intangible": state.formData.intangible || '',
            "tangible_angka": (state.formData.tangible_angka === null || state.formData.tangible_angka === undefined) ? '' : state.formData.tangible_angka,
            "tangible_keterangan": state.formData.tangible_keterangan || '',
            "focus": state.formData?.focus || ''
        }
        const validation = validateUpdateDetail(data);
        // console.log(data);
        if (validation.isValid === true) {
            // console.log('data yang dikirim', data, state.formData.keyword)
            action.postData(
                {
                    url: "/api/project-portfolio-crud/update-detail",
                    data: data,
                    withFile: false
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    getData()
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validation.message,
                icon: 'warning',
            })
        }
    }
    function updateDetailClick() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Save Project Detail?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                updateDetail();
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <>
            <Modal
                name="viewTableTrl"
                baseColor={false}

            >
                <div className="customShadow">
                    <img className="w-100" src='/images/trl.jpg'></img>
                </div>
            </Modal>
            <FormResearch
                formLists={[
                    {
                        rows: 1,
                        header: true,
                        name: <>Project Type & Initiator <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "text",
                                label: "Project type",
                                value: state.data_view_header?.project_type_name,
                                disabled: true,
                            },
                            {
                                type: "text",
                                label: "Nama",
                                value: state.data_view_detail?.initiator_name,
                                disabled: true,
                                placeholder: "Administrator",
                            },
                            {
                                label: "Jabatan",
                                type: "text",
                                value: state.data_view_detail?.initiator_position_name,
                                disabled: true,
                                placeholder: "Administrator",
                            },
                            {
                                label: "Fungsi",
                                value: state.data_view_detail?.initiator_function_name,
                                disabled: true,
                                type: "text",
                                placeholder: "Fungsi",
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Latar Belakang <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "array",
                                label: "Judul",
                                forms: [
                                    {
                                        type: "textarea",
                                        value: state.formData.title,
                                        onChange: function (e) { action.setForm("title", e) },
                                        placeholder: "Apa?",
                                        disabled: disable
                                    },
                                ]
                            },
                            {
                                label: "Pain Point",
                                value: state.formData.description,
                                onChange: function (e) { action.setForm("description", e) },
                                placeholder: "Tulis disini...",
                                type: "textarea",
                                disabled: disable
                            },
                            {
                                label: "Commercialization /Implementation- Objective",
                                value: state.formData.purpose,
                                onChange: function (e) { action.setForm("purpose", e) },
                                placeholder: "Tulis disini...",
                                type: "textarea",
                                disabled: disable
                            },
                            {
                                label: <> Dukungan Operational</>,
                                type: "custom",
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group mb-2">
                                                    <select
                                                        value={state.formData.dukunganOP}
                                                        placeholder="ini place"
                                                        onChange={(e) => action.setForm("dukunganOP", e.target.value)}
                                                        className="form-control form-control-sm mr-1"
                                                        disabled={disable}
                                                    >
                                                        <option value="">Pilih dukungan operasional dan tekan tombol +Add</option>
                                                        {
                                                            [...(state.formData.data_dukungan_operasional || [])].map((op, key) => (
                                                                <option key={key} value={op.value}>{op.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        disable === false &&
                                                        <div>
                                                            <button type="submit" className="btn btn-sm btn-primary" onClick={() => addDukunganOP(state.formData.dukunganOP)}>+ Add</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-12">
                                                <div>
                                                    {
                                                        state.formData.dukungan_operational?.map((duk, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                <span className="pl-2" style={{ fontSize: '8pt' }}>{duk.name}</span>
                                                                {
                                                                    disable === false && (
                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger" onClick={() => deleteDukunganOP(duk)}></i>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )

                            },
                            {
                                label: "Detail Dukungan dan Operational (Optional)",
                                type: "textarea",
                                value: state.formData.duk_operasional_ket,
                                onChange: (e) => action.setForm("duk_operasional_ket", e),
                                placeholder: "Isi dengan nama unit operasi dari dukungan operasional diatas, Mis:\r\n" +
                                    "1. KP1 : RU II Dumai, RU III Plaju\r\n" +
                                    "2. PHE : PHE ONWJ, PHE WMO",
                                disabled: disable
                            },
                            {
                                label: "Pemakai Potensial",
                                type: "text",
                                value: state.formData.potential_user,
                                onChange: (e) => action.setForm("potential_user", e),
                                placeholder: "Pemakai potensial",
                                disabled: disable
                            },
                            {
                                label: <> Keyword <i title="Kata kunci terkait riset yang dijalankan, dipisah dengan tanda koma (,)" className="fa fa-exclamation-circle"></i></>,
                                type: "custom",
                                component: (
                                    <Tags
                                        readOnly={disable}
                                        tagifyRef={tagref}
                                        onChange={(e) => action.setForm('keyword', e.detail.value)}
                                        onRemove={(e) => action.setForm('deletekeywordTemp', e.detail.data)}
                                        value={state.formData.keyword}
                                    />
                                )
                            },
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: <>Susunan tim <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: 'bold' }}>Leader</span>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    disable === false && (
                                                        <div className="input-group mb-2">
                                                            <Autocomplete dataSource={state.data_users}
                                                                valueExpr="name"
                                                                displayExpr="name"
                                                                onSelectionChanged={(e) => action.setForm("selectedLeader", e.selectedItem)}
                                                                className="form-control form-control-sm p-0 mr-1">
                                                            </Autocomplete>
                                                            <button type="submit" className="btn btn-sm btn-primary" onClick={() => action.setForm("leader", state.formData.selectedLeader)}>+ Select</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    state.formData.leader && (
                                                        <div className="row">
                                                            <div className="col" style={{ marginLeft: 10, marginRight: 10 }}>

                                                                <div className="row" style={{ paddingTop: '20px', paddingLeft: '7px', paddingBottom: '20px', border: '1px solid #ebedf2', borderRadius: '3px' }}>
                                                                    <div className="col-md-4 mb-5">
                                                                        <img src={state.formData.leader?.image || "/media/svg/icons/General/User.svg"}
                                                                            style={{ width: '100%' }}
                                                                        ></img>
                                                                    </div>
                                                                    <div className="col-md-8 mb-5">
                                                                        <div className="row" style={{ paddingBottom: 10 }}>
                                                                            <div className="col-3">Nama</div>
                                                                            <div className="col-auto">: {state.formData.leader.name}</div>
                                                                        </div>
                                                                        <div className="row" style={{ paddingBottom: 10 }}>
                                                                            <div className="col-3">Posisi</div>
                                                                            <div className="col-auto">: {state.formData.leader.position_name}</div>
                                                                        </div>
                                                                        <div className="row" style={{ paddingBottom: 10 }}>
                                                                            <div className="col-3">No. Pegawai</div>
                                                                            <div className="col-auto">: {state.formData.leader.employee_id}</div>
                                                                        </div>
                                                                        <div className="row" style={{ paddingBottom: 10 }}>
                                                                            <div className="col-3">Expertise</div>
                                                                            <div className="col-auto"></div>
                                                                        </div>
                                                                        {
                                                                            state.formData.leader.technical_competency?.split(',').map((ex, i) => (
                                                                                <div className="row" style={{ paddingBottom: 10 }} key={i}>
                                                                                    <div className="col-3"></div>
                                                                                    <div className="col-auto">{i + 1}.&nbsp;{ex}</div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <span style={{ fontSize: '8pt', fontWeight: "bold" }}>Anggota</span>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    disable === false && (
                                                        <div className="input-group mb-2">
                                                            <Autocomplete dataSource={state.data_users}
                                                                valueExpr="name"
                                                                displayExpr="name"
                                                                onSelectionChanged={(e) => action.setForm("selectedAnggota", e.selectedItem)}
                                                                className="form-control form-control-sm p-0 mr-1">
                                                            </Autocomplete>
                                                            <button type="submit" className="btn btn-sm btn-primary" onClick={() => addAnggota(state.formData.selectedAnggota)}>+ Add</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    state.formData.anggota?.length > 0 ? (
                                                        <Accordion
                                                            className="accordion-cstm py-5"
                                                            multiple={true}
                                                            itemRender={renderItem}
                                                            dataSource={[...state.formData.anggota]} collapsible={true}
                                                            itemTitleRender={renderTitle}
                                                        >
                                                        </Accordion>
                                                    ) : (
                                                        <div className="mb-5"></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 1,
                        header: true,
                        name: "Fungsi",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        {
                                            getFungsi().map((d, i) => (
                                                <span className="d-block" key={i}><i className="fa fa-circle icon-sm text-dark"></i> {d.section_name} ({d.function_short_name})</span>
                                            ))
                                        }
                                    </>
                                )
                            }
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Keahlian Peneliti yang Dibutuhkan <span className="text-danger">(*)</span></>,
                        childs: [
                            ...(disable === false ?
                                [
                                    {
                                        type: "custom",
                                        placeholder: "- {Pilih Group} -",
                                        label: "Group",
                                        component: (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12 pull-left">
                                                        <div className="input-group mb-2">
                                                            <select onChange={(e) => expertGroupChange(e.target.value)} placeholder="ini place" className="form-control form-control-sm">
                                                                <option value="" > - Group Keahlian - </option>
                                                                {
                                                                    [...(state.formData.data_expertise_group || [])].map((kg, i) => {
                                                                        return (
                                                                            <option key={i} value={kg.value}>{kg.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <div style={{ width: '65px' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    },
                                    {
                                        type: "custom",
                                        label: "Sub Group",
                                        placeholder: "- Pilih Sub Group -",
                                        component: (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-group mb-2">
                                                            <select onChange={(e) => expertSubGroupChange(e.target.value)} value={state.formData.keahlian_sub_group} placeholder="ini place" className="form-control form-control-sm">
                                                                <option value="" > - Group Sub Keahlian - </option>
                                                                {
                                                                    [...(state.expertise_sub_group || [])]?.map((s, i) => {
                                                                        return (
                                                                            <option key={i} value={s.value}>{s.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <div style={{ width: '65px' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    },
                                    {
                                        type: "custom",
                                        label: "Keahlian",
                                        component: (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-group mb-2" >
                                                            <select onChange={(e) => action.setForm("selected_keahlian", e.target.value)} value={state.formData.selected_keahlian} placeholder="ini place" className="form-control form-control-sm">
                                                                <option value="" > - Keahlian - </option>
                                                                {
                                                                    [...(state.expertise_detail || [])]?.map((k, i) => {
                                                                        return (
                                                                            <option key={i} value={k.value}>{k.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <div className="text-right" style={{ width: '65px' }}>
                                                                <button type="submit" className="btn btn-sm btn-primary" onClick={() => addKeahlian(state.formData.selected_keahlian)}>+ Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div style={{ paddingBottom: '25px' }}>
                                                            {
                                                                state.formData.keahlian?.map((kh, key) => (
                                                                    <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                        <span className="pl-2" style={{ fontSize: '8pt' }}>{kh.name}</span>
                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger" onClick={(e) => deleteKeahlian(kh)}></i>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                ]
                                : [
                                    {
                                        type: "custom",
                                        withLabel: false,
                                        component: (
                                            <div style={{ paddingBottom: '25px' }}>
                                                {
                                                    state.formData.keahlian?.map((kh, key) => (
                                                        <span className="d-block" style={{ fontSize: '8pt' }} key={key}>{kh.name}</span>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                ]
                            )
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Klasifikasi Research <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                label: "Area Research",
                                component: (
                                    <>
                                        <select className="form-control form-control-sm mb-5"
                                            disabled={disable}
                                            value={state.formData.area_id}
                                            onChange={(e) => areaChange(e.target.value)}
                                        >
                                            <option value="" > - Pilih Area - </option>
                                            {
                                                [...(state.formData.data_area || [])].map((d, i) => (
                                                    <option value={d.value} key={i}>
                                                        {d.name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </>
                                )
                            },
                            {
                                type: "custom",
                                label: "Kategory Research",
                                component: (
                                    <>
                                        <select className="form-control form-control-sm mb-5"
                                            value={state.formData.research_category_id}
                                            disabled={disable}
                                            onChange={(e) => action.setForm("research_category_id", e.target.value)}
                                        >
                                            <option value="" > - Pilih Kategori - </option>
                                            {
                                                [...(state.kategory || [])].map((d, i) => (
                                                    <option value={d.value} key={i}>
                                                        {d.name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </>
                                )
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: <>Ruang Lingkup Riset <span className="text-danger"></span></>,
                        childs: [
                            {
                                type: "array",
                                withLabel: false,
                                forms: [
                                    {
                                        type: "textarea",
                                        value: state.formData.focus,
                                        onChange: function (e) { action.setForm("focus", e) },
                                        placeholder: "Ruang Lingkup Riset",
                                        disabled: disable
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        rows: 2,
                        header: true,
                        name: "Dokumen Pendukung ",
                        childs: [
                            (
                                disable === false &&
                                {
                                    type: "select",
                                    label: "Tipe",
                                    options: dokPenType,
                                    onChange: function (e) { setFileType(e) },
                                    value: fileType,
                                    disabled: false
                                }
                            )
                            ,
                            {
                                type: "custom",
                                label: "Sumber File",
                                disabled: false,
                                component: (
                                    <>
                                        {
                                            disable === false && (

                                                <div className="row">
                                                    <div className="col-md-12 px-4">
                                                        <div className="input-group mb-2">
                                                            {
                                                                fileType === "url" ? (
                                                                    <>
                                                                        <input type="text" onChange={(e) => action.setForm("inputSumber", e.target.value)} className="form-control form-control-sm"></input>
                                                                        <div style={{ marginLeft: 10 }}>
                                                                            <button type="submit" className="btn btn-sm btn-primary" onClick={() => addSumberFile({
                                                                                "file_location": '',
                                                                                "tipe_document": "url",
                                                                                "file_name": null,
                                                                                "is_new": true,
                                                                                "link": state.formData.inputSumber
                                                                            })}>+ Add</button>
                                                                        </div>
                                                                    </>

                                                                ) : (
                                                                    <div>
                                                                        <FileUploadDx
                                                                            className="custom-dx-fileuploader-file-name"
                                                                            showFileList={true}
                                                                            multiple={false}
                                                                            selectButtonText="Select File"
                                                                            readyToUploadMessage=" "
                                                                            labelText="or Drop file hire | Max size 10 Mb"
                                                                            accept="*"
                                                                            onUploaded={fileUploaded}
                                                                            maxFileSize={10000000}
                                                                            uploadMode="instantly"
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        state.formData.sumberFileTemp?.map((file, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                {
                                                                    file.tipe_document === "file" ? (
                                                                        <>
                                                                            <span style={{ fontSize: '8pt', width: '250px' }}>{file.file_name}</span>
                                                                            {
                                                                                disable === false ? (
                                                                                    <div>
                                                                                        <a target="_blank" href={file.file_location} download>
                                                                                            <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1"></i>
                                                                                        </a>
                                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deletedDocument(key, file)}></i>
                                                                                    </div>
                                                                                ) : (
                                                                                    <a target="_blank" href={file.file_location}>
                                                                                        <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1"></i>
                                                                                    </a>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <a target="_blank" href={file.link} className="pl-2" style={{ fontFamily: 'Poppins', fontSize: '10px' }}>{file.link}</a>
                                                                            {
                                                                                disable === false && (
                                                                                    <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deletedDocument(key, file)}></i>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: "Proyek berkaitan",
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 px-4">
                                                <div className="input-group mb-2">
                                                    <Autocomplete dataSource={state.formData.data_related_research}
                                                        disabled={disable}
                                                        valueExpr="title"
                                                        displayExpr="title"
                                                        onSelectionChanged={(e) => action.setForm('relatedChange', e.selectedItem)}
                                                        className="form-control form-control-sm p-0 mr-1">
                                                    </Autocomplete>
                                                    {
                                                        disable === false &&
                                                        <div>
                                                            <button type="submit" className="btn btn-sm btn-primary" onClick={() => addRelated(state.formData.relatedChange)}>+ Add</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ paddingBottom: '25px' }}>
                                                    {
                                                        [...(state.formData.realated_document || [])]?.map((d, key) => (
                                                            <div className="d-flex flex-row justify-content-between mb-1" key={key}>
                                                                <span className="pl-2 text-wrap" style={{ fontSize: '8pt' }}>{d.title}</span>
                                                                {
                                                                    disable === false && (
                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger" onClick={(e) => deleteRelatedDoc(d)}></i>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: <>Metode Riset <span className="text-danger">(*)</span></>,
                        childs: [
                            {
                                type: "custom",
                                withLabel: false,
                                component: (
                                    <div className="d-inline-flex">
                                        <RadioGrp
                                            disable={disable}
                                            onChange={(e) => action.setForm('method', e)}
                                            value={state.formData.method?.toString()}
                                            data={[
                                                {
                                                    label: (
                                                        <span>
                                                            Inhouse
                                                            <i title="Dikerjakan dengan SDM dan Anggaran Pertamina" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '1'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Procurement
                                                            <i title="Dikerjakan oleh pihak ketiga dengan Anggaran Pertamina" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '2'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Partnership
                                                            <i title="SDM dan anggaran berasal dari Pertamina dan pihak ketiga" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '3'
                                                },
                                                {
                                                    label: (
                                                        <span>
                                                            Swakelola
                                                            <i title="Kerjasama yang dilaksanakan melalui metode swakelola" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    ), value: '4'
                                                }
                                            ]}
                                        />
                                    </div>
                                )
                            }
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        childs: [
                            ...(state.formData.method === '2' || state.formData.method === '3' || state.formData.method === '4') ? (
                                [{
                                    type: "select",
                                    label: "Potensial Partner",
                                    onChange: (e) => action.setForm('potensial_partner_opt', e),
                                    value: state.formData.potensial_partner_opt,
                                    options: [{ name: '- Pilih -', value: '' }, ...(state.formData.data_potensial_partner || [])],
                                    disabled: disable
                                },
                                {
                                    type: "text",
                                    label: "Detail Partner",
                                    value: state.formData.potensial_partner_ket,
                                    onChange: (e) => action.setForm('potensial_partner_ket', e),
                                    placeholder: "Detail Partner",
                                    disabled: disable
                                }]
                            ) : (
                                [{
                                    type: "custom",
                                    withLabel: false,
                                    component: (
                                        <>
                                        </>
                                    )
                                }]
                            )
                        ]
                    }, {
                        rows: 2,
                        header: true,
                        name: <span>
                            Value Creation
                            <i title="Nilai yang dapat dihasilkan setelah riset dilaksanakan, dapat berupa pendapatan, efisiensi, produksi minyak, atau intangible asset" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                            <span className="text-danger">(*)</span>
                        </span>,
                        childs: [
                            {
                                type: "custom",
                                label: "Pendapatan (Revenus)",
                                withLabel: false,
                                component: (
                                    <div className="border bg-light-secondary rounded p-3 mb-5">
                                        {
                                            (state.formData.pendapatan || state.formData.sat_pendapatan_name || state.formData.ket_pendapatan) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Pendapatan (Revenus)
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.pendapatan} {state.formData.sat_pendapatan_name} {state.formData.ket_pendapatan}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (state.formData.efisiensi || state.formData.sat_efisiensi_name || state.formData.ket_efisiensi) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Efisiensi
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.efisiensi} {state.formData.sat_efisiensi_name} {state.formData.ket_efisiensi}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (state.formData.produksi || state.formData.sat_produksi_name || state.formData.ket_produksi) && (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <span>
                                                            Production
                                                            <i title="Penambahan Produksi Migas, Cadangan Migas, Penjualan, Kapasitas listrik" className="fa fa-exclamation-circle icon-nm mx-1"></i>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{state.formData.produksi} {state.formData.sat_produksi_name} {state.formData.ket_produksi}</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Tangible value
                                </span>,
                                component: (
                                    <>
                                        <div className="form-group row">
                                            <div className="col-md-12" >
                                                <input className="form-control form-control-sm"
                                                    type="number"
                                                    min={0}
                                                    value={state.formData.tangible_angka || 0}
                                                    onChange={(e) => action.setForm('tangible_angka', e.target.value)}
                                                    disabled={disable} placeholder="Angka dalam IDR/Tahun"></input>
                                            </div>
                                        </div>
                                    </>
                                )
                            },
                            {
                                type: "custom",
                                label: <span>
                                    Justifikasi Perhitungan Tangible Value
                                </span>,
                                component: (
                                    <>
                                        <div className="form-group row">
                                            <div className="col-md-12" >
                                                <textarea className="form-control form-control-sm"
                                                    rows={4}
                                                    value={state.formData.tangible_keterangan}
                                                    onChange={(e) => action.setForm('tangible_keterangan', e.target.value)}
                                                    disabled={disable} placeholder="Justifikasi Perhitungan Tangible Value"></textarea>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            , {
                                type: "textarea",
                                label: "Intangible Value (Optional)",
                                value: state.formData.intangible,
                                onChange: (e) => action.setForm('intangible', e),
                                placeholder: "Intangible Value",
                                disabled: disable
                            }
                        ]
                    },
                    ...(state.data_view_header?.project_type?.toString() === '1' ? [
                        {
                            rows: 2,
                            header: true,
                            name: <>Tingkat Kesiapan Riset <span className="text-danger">(*)</span><span role={'button'} className="badge badge-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('viewTableTrl')}>Lihat Table TRL</span></>,
                            childs: [
                                {
                                    type: "custom",
                                    withLabel: false,
                                    component: (
                                        <div className="d-inline-flex">
                                            <RadioGrp
                                                disable={disable}
                                                value={state.formData.stage}
                                                onChange={(e) => action.setForm('stage', e)}
                                                data={[
                                                    { label: 'TRL I', value: 'I' },
                                                    { label: 'TRL II', value: 'II' },
                                                    { label: 'TRL III', value: 'III' },
                                                    { label: 'TRL IV', value: 'IV' },
                                                    { label: 'TRL V', value: 'V' },
                                                    { label: 'TRL VI', value: 'VI' },
                                                    { label: 'TRL VII', value: 'VII' },
                                                    { label: 'TRL VIII', value: 'VIII' },
                                                    { label: 'TRL IX', value: 'IX' }
                                                ]}
                                            />
                                        </div>
                                    )
                                }
                            ]
                        }
                    ] : []),
                    ...(( state.data_view_header?.project_type?.toString() === '4') ? [
                        {
                            rows: 2,
                            header: true,
                            name: <>Tingkat Kesiapan Riset <span className="text-danger">(*)</span> <button className="btn mb-1 btn-sm btn-primary" style={{ float: 'right' }} onClick={() => action.toggleModal('viewTableTrl')}>Lihat Table TRL</button></>,
                            childs: [
                                {
                                    type: "custom",
                                    withLabel: false,
                                    component: (
                                        <div className="d-inline-flex">
                                            <RadioGrp
                                                disable={disable}
                                                value={state.formData.stage}
                                                onChange={(e) => action.setForm('stage', e)}
                                                data={[
                                                    { label: 'TRL I', value: 'I', disabled: true },
                                                    { label: 'TRL II', value: 'II', disabled: true },
                                                    { label: 'TRL III', value: 'III', disabled: true },
                                                    { label: 'TRL IV', value: 'IV', disabled: true },
                                                    { label: 'TRL V', value: 'V', disabled: true },
                                                    { label: 'TRL VI', value: 'VI', disabled: true },
                                                    { label: 'TRL VII', value: 'VII' },
                                                    { label: 'TRL VIII', value: 'VIII' },
                                                    { label: 'TRL IX', value: 'IX' }
                                                ]}
                                            />
                                        </div>
                                    )
                                }
                            ]
                        }
                    ] : [])
                ]}
            />
            <div className="row mt-5">
                <div className="col-6">
                    <span className="d-block text-danger">(*) Mandatori tidak boleh kosong</span>
                </div>
                <div className="col-6 text-right">
                    {
                        disable === false && (
                            <button className="btn btn-sm btn-outline-primary" onClick={() => updateDetailClick()
                            } >Save Project Detail</button>
                        )
                    }
                </div>
            </div>
        </>

    )
}
export default TabDetail;