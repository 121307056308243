import React, { memo, useEffect, useRef, useState } from 'react'
import { GridDx, HeaderList, Modal } from '../../components'
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useGlobalState } from '../../contexts/useGlobalState';
import KerjasamaGridDetail from './kerjasamaGridDetail';
import ModalKerjasamaMasterEdit from './modalKerjasamaMasterEdit';
//import ModalKerjasamaDetailEdit from './modalKerjasamaDetailEdit';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import useLocalStorage from '../../contexts/useLocalStorage';
import { baseUrl } from '../../app.config';
import useKejasama from '../kerjasama/useKerjasama';
import moment from 'moment';

const filterWarning = [{ text: "Expired", value: 1 }, { text: "Warning", value: 2 }, { text: "Running", value: 3 }, { text: "None", value: 0 }, { text: "Selesai", value: 4 }];

function TabTable({ reporting = false }) {
    const tableRef = useRef()
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [modeMaster, setModeMaster] = useState();
    const [modeEdit, setModeEdit] = useState('Add');
    const [dataEdit, setDataEdit] = useState();
    const [token, setToken] = useLocalStorage("token", null);
    const { krjState, krjAction } = useKejasama();
    const [dataTable, setDataTable] = useState([]);
    const [filterCountry, setfilterCountry] = useState([]);
    const [filterProvince, setfilterProvince] = useState([]);
    const [column, setColumn] = useState([
        { fieldName: "updated_at_form", caption: "Last Modified", width: 100 },
        { fieldName: "warning", caption: "Status Expired", width: 200, onExport: (e) => filterWarning.find(d => d.value === e?.warning)?.text || "", headerFilterSource: filterWarning },
        { fieldName: "judul", caption: "Judul Partnership", width: 300 },
        { fieldName: "nama_partner", caption: "Partner", width: 200, allowFiltering: true, onExport: onExportNamaPartner },
        { fieldName: "project_cluster_name", caption: "Project Cluster", width: 150 },
        { fieldName: "total_sa_cs_pc_tpta", caption: "Partnership Score", width: 150 },
        { fieldName: "country", caption: "Country", width: 150, headerFilterSource: filterCountry, 'calculateFilterExpression': calculateFilterExpression, onExport: (e) => (e?.country || '')?.replace(';', ',') },
        { fieldName: "province", caption: "Province", width: 150, headerFilterSource: filterProvince, 'calculateFilterExpression': calculateFilterExpression, onExport: (e) => (e?.country || '')?.replace(';', ',') },
        { fieldName: "function_name", caption: "Fungsi VP", width: 150, onExport: (e) => (e?.function_name || '')?.replace(';', ',') },
        { fieldName: "chief_name", caption: "Fungsi Manager", width: 150, onExport: (e) => (e?.chief_name || '')?.replace(';', ',') },
        ...(reporting ? [{ fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", width: 150 },
        { fieldName: "status_dokumen_name", caption: "Status Dokumen", width: 150 },
        { fieldName: "berlaku_start", caption: "Mulai Berlaku", width: 150 },
        { fieldName: "berlaku_end", caption: "Sampai Dengan", width: 150 },] : [{ fieldName: "custom_dokumen", caption: "Status Dokumen", width: 150 }]),
        { fieldName: "rkap", caption: "Related RKAP", width: 200 },
        { fieldName: "created_by_name", caption: "Created By", width: 150 },
    ]);

    useEffect(() => {
        getData();
        krjAction.getDashboardBox();
        krjAction.getHeader();
    }, [])
    function onExportNamaPartner(data) {
        const partners = (data?.nama_partner || '')?.split(';');
        const partner = (partners || []).map((d, i) => {
            const splitter = (d || '')?.split('|');
            return `${i + 1}. ${splitter[0]}\r\n ${splitter[1]}\r\n ${splitter[2]}\r\n`
        })
        return (partner || [])?.join(``);
    }
    function getData() {
        if (reporting === false) {
            action.getData('/api/ext-partnership/table', {}, null, 'loadingTable', true).then((resp) => {
                if (resp.code === '00') {
                    krjAction.setData_page(resp.data_page);
                    var fc = [];
                    var fp = [];
                    setDataTable([...(resp.data?.tabel_kerjasama || [])].map((d, i) => {
                        fc = [...fc, ...(d?.country || '').split(';')];
                        fp = [...fp, ...(d?.province || '').split(';')];
                        return {
                            ...d,
                            // kerjasama_partners: [...(d?.kerjasama_partners || [])].map(x => x?.nama_partner).join(','),
                            // kerjasama_fungsies: [...(d?.kerjasama_fungsies || [])].map(x => x?.show_chief_manager)?.join(','),
                            // kerjasama_rkaps: [...(d?.kerjasama_rkaps || [])].map(x => x?.show_research)?.join(','),
                            // show_jenis_dokumen: [...(d?.show_jenis_dokumen || [])].join(','),
                            // show_status_dokumen: [...(d?.show_status_dokumen || [])].join(','),
                            // show_berlaku_start: [...(d?.show_berlaku_start || [])].join(','),
                            // show_berlaku_end: [...(d?.show_berlaku_end || [])].join(','),
                        }
                    }))
                    fc = [...new Set(fc)].filter(d => d).map(d => ({ text: d, value: d }));
                    fp = [...new Set(fp)].filter(d => d).map(d => ({ text: d, value: d }))
                    setfilterCountry(fc);
                    setfilterProvince(fp);
                    setColumn([
                        { fieldName: "updated_at_form", caption: "Last Modified", width: 100 },
                        { fieldName: "warning", caption: "Status Expired", width: 200, onExport: (e) => filterWarning.find(d => d.value === e?.warning)?.text || "", headerFilterSource: filterWarning },
                        { fieldName: "judul", caption: "Judul Partnership", width: 300 },
                        { fieldName: "nama_partner", caption: "Partner", width: 200, allowFiltering: true, onExport: onExportNamaPartner },
                        { fieldName: "project_cluster_name", caption: "Project Cluster", width: 150 },
                        { fieldName: "total_sa_cs_pc_tpta", caption: "Partnership Score", width: 150 },
                        { fieldName: "country", caption: "Country", width: 150, headerFilterSource: fc, 'calculateFilterExpression': calculateFilterExpression, selectedFilterOperation: 'contains', onExport: (e) => (e?.country || '')?.replace(';', ',') },
                        { fieldName: "province", caption: "Province", width: 150, headerFilterSource: fp, 'calculateFilterExpression': calculateFilterExpression, selectedFilterOperation: 'contains', onExport: (e) => (e?.province || '')?.replace(';', ',') },
                        { fieldName: "function_name", caption: "Fungsi VP", width: 150, onExport: (e) => (e?.function_name || '')?.replace(';', ',') },
                        { fieldName: "chief_name", caption: "Fungsi Manager", width: 150, onExport: (e) => (e?.chief_name || '')?.replace(';', ',') },
                        ...(reporting ? [{ fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", width: 150 },
                        { fieldName: "status_dokumen_name", caption: "Status Dokumen", width: 150 },
                        { fieldName: "berlaku_start", caption: "Mulai Berlaku", width: 150 },
                        { fieldName: "berlaku_end", caption: "Sampai Dengan", width: 150 },] : [{ fieldName: "custom_dokumen", caption: "Status Dokumen", width: 150 }]),
                        { fieldName: "rkap", caption: "Related RKAP", width: 200 },
                        { fieldName: "created_by_name", caption: "Created By", width: 150 },
                    ])
                }
            });
        } else {
            action.getData('/api/ext-partnership/table-3', {}, null, 'loadingTable', true).then((resp) => {
                if (resp.code === '00') {
                    krjAction.setData_page(resp.data_page);
                    setDataTable([...(resp.data?.tabel_kerjasama || [])].map((d, i) => {
                        return {
                            ...d,
                            // kerjasama_partners: [...(d?.kerjasama_partners || [])].map(x => x?.nama_partner).join(','),
                            kerjasama_fungsies: [...(d?.kerjasama_fungsies || [])].map(x => x?.show_chief_manager).join(','),
                            kerjasama_rkaps: [...(d?.kerjasama_rkaps || [])].map(x => x?.show_research).join(','),
                            show_jenis_dokumen: [...(d?.show_jenis_dokumen || [])].join(','),
                            show_status_dokumen: [...(d?.show_status_dokumen || [])].join(','),
                            show_berlaku_start: [...(d?.show_berlaku_start || [])].join(','),
                            show_berlaku_end: [...(d?.show_berlaku_end || [])].join(','),
                        }
                    }))
                }
            });
        }
    }
    function searchChange(value) {
        if (tableRef) {
            tableRef.current.instance.searchByText(value)
        }
    }
    function editClicked(data) {
        setDataEdit({
            ...data
        })
        setModeMaster('Edit');
        action.toggleModal('modalMasterEdit');
    }
    function addClick() {
        setDataEdit(null);
        setModeMaster('Add');
        action.toggleModal('modalMasterEdit');
    }
    function cstmCellRender(e) {
        if (e.column.name === "action") {
            return (
                <div className='text-center'>
                    <button className='btn btn-sm btn-light-danger btn-icon'
                        onClick={() => deleteData(e?.data?.id)}
                    ><i className='fa fa-trash-alt icon-nm'></i></button>
                </div>
            )
        } else if (e.column.name === "judul") {
            return (<Link to=
                {
                    {
                        pathname: "/admin/update/Kerjasama-detail/" + e.data.id,
                    }
                }
                target="_blank"
            >
                <span className={`text-wrap ${e.data?.warning === 1 ? 'text-danger' : ''}`}>
                    {e.value}
                    {e.data?.warning === 1 && (<i title='Expired' className='fas fa-exclamation-circle icon-nm ml-2 text-danger'></i>)}
                </span>
            </Link>)
        } else if (e.column.name === 'nama_partner') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => {
                            const partner = (d || '')?.split('|');
                            return (
                                <li className=" text-wrap p-2" key={i}>
                                    <span className='d-block'><b>{(partner[0] || '')}</b></span>
                                    <span className='d-block'>{(partner[1] || '')}</span>
                                    <span className='d-block'>{(partner[2] || '')}</span>
                                    {/* <span className='d-block'>Country = {d?.country}</span>
                                    {
                                        d?.country === "Indonesia" && <span>Province:{d?.Province}</span>
                                    } */}
                                </li>
                            )
                        })
                    }
                </ul>
            )
        } else if (e.column.name === 'function_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'chief_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'country') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'province') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'reviewer_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(',') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === "custom_dokumen") {
            return (
                <div>
                    <span className='d-block mb-2'>{`${e.data?.jenis_dokumen_name} (${e?.data?.status_dokumen_name})`}</span>
                    <span className='d-block'>{`${e?.data?.berlaku_start || ''} - ${e?.data?.berlaku_end || ''}`}</span>
                </div>
            )
        } else if (e.column.name === 'rkap') {
            return (
                // <span className="text-wrap">{e.value}</span>
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'warning') {
            const text = filterWarning.find(d => d.value === e.value)?.text || '';
            const color = e.value === 1 ? "text-danger" :
                e.value === 2 ? "text-warning" :
                    e.value === 3 ? "text-success" :
                        e.value === 0 ? "text-secondary" : ""
            return (
                <span className={`text-wrap ${color}`}>{text}</span>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }

    }
    function onSave(data) {
        if (modeMaster === 'Add') {
            if (data) {
                // console.log(data)
                Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-sm btn-primary mx-1',
                        cancelButton: 'btn btn-sm btn-secondary mx-1'
                    },
                    buttonsStyling: false
                }).fire({
                    html:
                        '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                        '<strong>Add Partnership?</strong>',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: true
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //console.log('semua data', state)
                        action.postData(
                            {
                                url: "/api/ext-partnership/store-kerjasama",
                                data: data,
                                withFile: false
                            }
                        ).then((resp) => {
                            if (resp.code === "00") {
                                action.toggleModal('modalMasterEdit');
                                getData()
                            }
                        })
                        return true;
                    } else {
                        return true;
                    }
                })
            }
        }
        if (modeMaster === 'Edit') {
            if (data) {
                // console.log(data)
                const res = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-sm btn-primary mx-1',
                        cancelButton: 'btn btn-sm btn-secondary mx-1'
                    },
                    buttonsStyling: false
                }).fire({
                    html:
                        '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                        '<strong>Update Partnership?</strong>',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: true
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //console.log('semua data', state)
                        action.postData(
                            {
                                url: "/api/ext-partnership/update-kerjasama",
                                data: data,
                                withFile: false
                            }
                        ).then((resp) => {
                            if (resp.code === "00") {
                                action.toggleModal('modalMasterEdit');
                                getData()
                            }
                        })
                        return true;
                    } else {
                        return true;
                    }
                })
            }
        }
    }
    function deleteData(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Partnership ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: `/api/ext-partnership/delete-kerjasama?id=${id}`,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    function calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        let column = this;
        if (filterValue) {
            let selector = (data) => {
                let applyOperation = (arg1, arg2, op) => {
                    if (op === "=") return arg1 === arg2;
                    if (op === "contains") return arg1.includes(arg2);
                    if (op === "startswith") return arg1.startsWith(arg2);
                    if (op === "endswith") return arg1.endsWith(arg2);
                };
                let values = (column.calculateCellValue(data))?.split(';');
                return (
                    values &&
                    !!values.find((v) =>
                        applyOperation(v, filterValue, selectedFilterOperation)
                    )
                );
            };
            return [selector, "=", true];
        }
        return column.defaultCalculateFilterExpression.apply(this, arguments);
    }
    return (
        <>
            {/* <Modal
                name="modalDetailEdit"
                baseColor={false}
            >
                <ModalKerjasamaDetailEdit mode={modeEdit} data={dataEdit} />
            </Modal> */}
            <Modal
                name="modalMasterEdit"
                baseColor={false}
            >
                <ModalKerjasamaMasterEdit mode={modeMaster} data={dataEdit} onSave={onSave} />
            </Modal>
            <Card
                bg="primary"
                className="m-0 p-0 mx-3 mb-5 shadow"
            >
                <Card.Body className="p-3">
                    <div className="row" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded icon-header shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Bag1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total dokumen partnership yang telah disahkan</span>
                                    <span className="d-block"><strong>
                                        {[...(krjState.dashboard_box || [])].reduce((tot, val) => tot = tot + val?.jumlah, 0)}
                                        {/* {krjState.header?.jumlahTotal} */}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[0] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">MoU</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahMou} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 1)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[1] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">NDA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahNDA} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 2)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[2] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">PKS</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahPKS} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 3)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[3] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">JSA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahJSA} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 4)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1" style={{ width: 15, height: 15, backgroundColor: krjState.color[4] }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">HoA</span>
                                    <span className="d-block"><strong>
                                        {/* {krjState.header?.jumlahHoa} */}
                                        {[...(krjState.dashboard_box || [])].find(d => d?.id === 5)?.jumlah || 0}
                                    </strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                style={{ boxShadow: '0px 3px 6px #00000029' }}
                className="mx-3 p-0 mb-5"
            >
                <Card.Body className="p-2">
                    {
                        reporting === false && (
                            <div className="row mt-5 mb-5 justify-content-between">
                                <div className="col-md-3 mb-3">
                                    <div className="formSearch">
                                        <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => searchChange(e.target.value)} />
                                        <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3 text-right">
                                    <Link
                                        to={'/admin/update/partnership-add'}
                                    // target="_blank"
                                    >
                                        <button className="btn btn-sm btn-primary"
                                        // onClick={() => addClick()}
                                        >+ Partnership</button>
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12">
                            <GridDx
                                masterDetail={reporting === false ? true : false}
                                filenameExport={`Partnership Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                                repaintChangesOnly={true}
                                ComponenMasterDetail={KerjasamaGridDetail}
                                searchForm={reporting}
                                className="custom-style-master"
                                ref={tableRef}
                                loading={state.loadingTable}
                                data={dataTable}
                                cellRender={cstmCellRender}
                                columns={reporting ? column : [{ fieldName: "action", caption: "Action", width: 100, allowFiltering: false, allowSorting: false }, ...column]}
                                withAction={reporting === false ? false : false}
                                selectionMode={reporting ? "multiple" : false}
                                exportMode="selection"
                                exportExcel={reporting}
                                //exportPdf={reporting}
                                settingColumn={reporting}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                                actions={[
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => { deleteData(v?.id) }
                                    }
                                    ,
                                    {
                                        name: "Edit Data Partnership",
                                        icon: "fa fa-pencil-alt icon-nm text-secondary",
                                        action: (v, c) => history.push(`/admin/update/partnership-edit/${v?.id}`)//{ editClicked(v) }
                                    }
                                    ,
                                    {
                                        name: "Update Data Dokumen",
                                        icon: "fa fa-pencil-alt icon-nm text-secondary",
                                        action: (v, c) => window.open(`/admin/update/Kerjasama-detail/${v?.id}`, '_blank') //{ history.push(`/admin/update/Kerjasama-detail/${v?.id}`) }
                                    }
                                ]}
                            >

                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>

        </>
    );
}

export default TabTable;