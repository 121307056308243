import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { HeaderList, Tab, TabContent } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import TabDashoard from './tabDashoard';
import TabProductDsply from './tabProductDsply';
import './productMng.css';
import TabProductList from './tabProductList';

function ProductMng(props) {
    const { pathname, param } = useLocation();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    return (
        <>
            <HeaderList
                title={state.formData.pro?.page_title}
                someText={state.formData.pro?.page_breadcrumbs}
            ></HeaderList>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/product/dashboard'
                            })
                        },
                        title: "Product Dashboard"
                    },
                    {
                        id: "display",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/product/display'
                            })
                        },
                        title: "Product Display"
                    }
                    ,
                    {
                        id: "list",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/product/list'
                            })
                        },
                        title: "Product List"
                    }
                ]}
                manualActive={pathname === "/product/display" ? "display" : pathname === "/product/dashboard" ? "dashboard" : pathname === "/product/list" ? "list" : ''}
            >
                <TabContent id="dashboard">
                    <TabDashoard></TabDashoard>
                </TabContent>
                <TabContent id="display">
                    <TabProductDsply></TabProductDsply>
                </TabContent>
                <TabContent id="list">
                    <TabProductList></TabProductList>
                </TabContent>
            </Tab>
        </>
    );
}

export default ProductMng;