import { Switch } from 'devextreme-react';
import React from 'react';
import './switch-btn.css'

export default function ({ onChange, value, className = '' }) {
    return (
        <Switch
            onValueChanged={(e) => onChange ? onChange(e.value) : null}
            value={value}
            className={`cstm-switch-container cstm-switch-btn cstm-switch-btn-before switchOn switchOff ${className}`}
        ></Switch>
    )
}
