import React, { createContext, useContext, useState } from 'react';
import { useGlobalState } from '../../../contexts/useGlobalState';

const upaContext = createContext({
    upaAction: {},
    upaState: {}
})

export function UpaProvider({ children }) {
    const [globalState, dispatch, globalAction] = useGlobalState()
    const [logBookAch, setLogBookAch] = useState([]);
    const [logBookCon, setLogBookCon] = useState([]);

    async function getDataLogbook(ids, type = 0) {
        const idArr = ids.split(",");
        const datas = await Promise.all(
            idArr.map(v => v === "" ? null : globalAction.getData(`/api/get-data-logbook?id=${v}`))
        )
        let newData = []
        datas.forEach((c) => {
            if (c?.code === "00") {
                // console.log(newData)
                newData = [...newData, c.data]
            }
        })

        if (type === 0) {
            setLogBookAch(newData)
        } else if (type === 1) {
            setLogBookCon(newData)
        }
    }

    return <upaContext.Provider
        value={{
            upaAction: {
                getDataLogbook
            },
            upaState: {
                logBookAch,
                logBookCon
            }
        }}
    >{children}</upaContext.Provider>
}

export default function usePageAchievement() {
    return useContext(upaContext);
}