import React, { useState, useEffect } from 'react';
import { GridDxTimeline, GanttChartP, SwitchBtn, SkeletonLoading, GridDxTimelineRevise, MilestonesBreadcrumbs } from '../../components/index'
import { columnSummary, rowSummary } from './dummy-data';
import Card from 'react-bootstrap/Card'
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js'

const format = "#,##0.##";
const type = [
    { "value": "milestones", "name": "Milestone" },
    { "value": "submilestones", "name": "Sub Milestone" },
    { "value": "task", "name": "Task" },
    { "value": "subtask", "name": "Sub Task" }
];
const colomn = [
    { "fieldName": "task", "caption": "Task", minWidth: 300 },
    { "fieldName": "type", "caption": "Type", "custom": true, "customType": "lookup", "dsCustom": type, "displayExpr": "name", "valueExpr": "value", "visible": true, width: 100 },
    { "fieldName": "plan_jan", "caption": "JAN", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_feb", "caption": "FEB", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_mar", "caption": "MAR", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_apr", "caption": "APR", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_may", "caption": "MAY", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_jun", "caption": "JUN", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_jul", "caption": "JUL", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_aug", "caption": "AUG", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_sep", "caption": "SEP", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_oct", "caption": "OCT", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_nov", "caption": "NOV", "visible": true, "dataType": "number", width: 50, 'format': format },
    { "fieldName": "plan_dec", "caption": "DEC", "visible": true, "dataType": "number", width: 50, 'format': format }
]

const TabTimeline = ({ param }) => {
    const [sw, setSw] = useState(false);
    const history = useHistory()
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))
    const [state, dispatch, action] = useGlobalState();

    useEffect(() => {
        if (param.id === null) {
            history.push("/error-page")
        } else {
            action.getData("/api/project-portfolio-crud/view-timeline/" + param.id, {}, null, 'loadingTimeline', true).then((resp) => {
                if (resp.code === "00") {
                    action.setForm("dataSource", resp.data.data_view_timeline);
                    action.setForm('portfolio_data_page', resp.data_page);
                }
            })
        }
    }, [])

    function getData() {
        if (param.id === null) {
            history.push("/error-page")
        } else {
            action.getData("/api/project-portfolio-crud/view-timeline/" + param.id, {}, null, 'loadingTimeline', true).then((resp) => {
                if (resp.code === "00") {
                    action.setForm("dataSource", resp.data.data_view_timeline);
                    action.setForm('portfolio_data_page', resp.data_page);
                }
            })
        }
    }

    function btnProjectOnClick(e) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add year timeline?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-portfolio-crud/create-timeline-year?research_id=" + state.data_view_header?.id,
                        withFile: false
                    }
                ).then((resp) => {
                    if (resp.code === "00") {
                        //Swal.fire('Timeline Year Added', '', 'success')
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        });

        // let max = (state.formData.dataSource || []).reduce((mx, dt) => {
        //     return dt.year >= mx.year ? dt : mx;
        // }, {
        //     year: Number.MIN_SAFE_INTEGER
        // }) || new Date().getFullYear();
        // action.setForm("dataSource", (state.formData.dataSource || []).concat({ year: (max.year === Number.MIN_SAFE_INTEGER ? parseInt(new Date().getFullYear()) : max.year) + 1, data: [] }))
        // action.setForm("newProject", (state.formData.newProject || []).concat({ year: (max.year === Number.MIN_SAFE_INTEGER ? parseInt(new Date().getFullYear()) : max.year) + 1, data: [] }))
    }

    function deleteYear(e) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete year timeline ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/project-portfolio-crud/delete-timeline-year?research_id=" + state.data_view_header?.id + "&year=" + e,
                        withFile: false
                    }
                ).then((resp) => {
                    if (resp.code === "00") {
                        getData();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
        // const res = Swal.fire({
        //     text: 'Are you sure you want to delete this record?',
        //     showCancelButton: true,
        //     confirmButtonText: `Yes`,
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         action.postData(
        //             {
        //                 url: "/api/project-portfolio-crud/delete-timeline-year?research_id=" + state.data_view_header?.id + "&year=" + e,
        //                 withFile: false
        //             }
        //         ).then((resp) => {
        //             if (resp.code === "00") {
        //                 getData();
        //             }
        //         })
        //         return false;
        //     } else {
        //         return true;
        //     }
        // })
    }
    function rowAdded(data) {
        // console.log('data added', data)
    }
    function onReordering(data) {
        // console.log('data yang dikirim', data)
        if (data) {
            action.postData(
                {
                    url: `/api/project-portfolio-crud/update-timeline-weight?research_id=${state.data_view_header?.id}`,
                    'data': data,
                    withFile: false,
                    withLoading: true,
                    withAlert: false,
                }
            ).then((resp) => {
                getData()
            })
        }
    }
    function renderMilestonesItem(item) {
        return (
            <ol style={{ paddingLeft: '15px' }}>
                {
                    [...(item?.data || [])].filter(d => d?.type === "milestones" || d?.type === "submilestones").map((d, i) => (
                        <li className='text-wrap' key={i}>{d?.task} ({
                            ["plan_jan", "plan_feb", "plan_mar", "plan_apr", "plan_may", "plan_jun", "plan_jul", "plan_aug", "plan_sep", "plan_oct", "plan_nov", "plan_dec"].map(x => d[x] > 0 ? x.split('_')[1] : '').filter(x => x).join(",").toUpperCase()
                        })</li>
                    ))
                }
            </ol>
        )
    }
    return (
        <div className="row p-3">
            <div className="col-md-12">
                <p><i className="fa fa-circle icon-sm"></i> Pengisian estimasi progres dapat diisi pada Sub Task, Milestone, Sub Milestone.
                    Sedangkan untuk Task hanya berupa keterangan.
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Jumlah [Sub Task + Milestone + Sub Milestone] minimal 8 baris
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Bobot isian adalah dalam bentuk persentase (%) dan total plan untuk setiap tahun
                    harus berjumlah 100%
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Pastikan timeline project diisi sampai dengan komersial / implementasi
                </p>
                <div className="row">
                    <div className="col-md-12 text-left text-md-right text-lg-right">
                        {
                            state.formData.disable === false && (
                                <button onClick={btnProjectOnClick} className="btn btn-success btn-sm customButton text-white m-2" style={{ fontSize: '8pt', fontFamily: 'Poppins' }}><i className="fa fa-plus icon-sm text-white"></i> Year</button>
                            )
                        }
                        <label style={{ paddingRight: 10 }}>Ganttchart view</label>
                        <SwitchBtn value={sw} onChange={(e) => setSw(e)} />
                        <label style={{ paddingLeft: 10 }}>Weight view</label>
                    </div>
                </div>
                <hr style={{ margin: 0 }} />
                <MilestonesBreadcrumbs data={state.formData.dataSource} titleField="year" renderItem={renderMilestonesItem} />
                {
                    state.formData.dataSource?.map((e, i) => {
                        return (
                            <Card className="p-0 my-2" key={i}>
                                <Card.Body className="p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="d-flex py-2 justify-content-between border-bottom">
                                                        <span className="align-self-center">{e.year}</span>
                                                        {
                                                            sw === true ?
                                                                (
                                                                    state.formData.disable === false && (
                                                                        <button className="btn btn-outline-danger btn-sm pull-right " onClick={() => deleteYear(e.year)} style={{ fontSize: '8pt', fontFamily: 'Poppins' }}><i className="fa fa-trash-alt icon-sm"></i> Remove {e.year}</button>
                                                                    )

                                                                ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        sw === true ? (
                                                            state.loadingTimeline ? (
                                                                <SkeletonLoading type="table" rows={5} cols={colomn.length} />
                                                            ) : (
                                                                state.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio' ? (
                                                                    <GridDxTimelineRevise
                                                                        data={e.data}
                                                                        year={e.year}
                                                                        onRefresh={() => getData()}
                                                                        onReOrdering={(e) => onReordering(e)}
                                                                        deliverable_research_year_id={e.deliverable_research_year_id}
                                                                        key={i}
                                                                        allowAdding={!state.formData.disable}
                                                                        allowDeleting={!state.formData.disable}
                                                                        allowEditing={!state.formData.disable}
                                                                        column={colomn}
                                                                    // summaryC={true}
                                                                    // columnSummary={columnSummary}
                                                                    // summaryR={true}
                                                                    // rowSummary={rowSummary}
                                                                    // rowAdded={(e) => rowAdded(e)}
                                                                    />
                                                                ) : (
                                                                    <GridDxTimeline
                                                                        dataSource={e.data}
                                                                        year={e.year}
                                                                        onRefresh={() => getData()}
                                                                        onReOrdering={(e) => onReordering(e)}
                                                                        deliverable_research_year_id={e.deliverable_research_year_id}
                                                                        key={i}
                                                                        allowAdding={!state.formData.disable}
                                                                        allowDeleting={!state.formData.disable}
                                                                        allowEditing={!state.formData.disable}
                                                                        columns={colomn}
                                                                        summaryC={true}
                                                                        columnSummary={columnSummary}
                                                                        summaryR={true}
                                                                        rowSummary={rowSummary}
                                                                        rowAdded={(e) => rowAdded(e)}
                                                                    />
                                                                )
                                                            )
                                                        ) : (
                                                            <GanttChartP
                                                                key={i}
                                                                dataSource={e.data}
                                                                column={colomn}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                }

            </div>
        </div>
    )
}
export default TabTimeline;