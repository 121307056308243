import React from "react";
import { useContext } from "react";
import { createContext } from "react";
import Swal from "sweetalert2";
import { useGlobalState } from "../../../../../contexts/useGlobalState";
import { useEffect } from "react";
import { Modal } from "../../../../../components";
import { useState } from "react";
import moment from "moment";
import { toString, toInteger } from 'lodash';

const context = createContext({
    state: {},
    action: {}
});
const iniStateFormData = {
    tanggal_mulai: "",
    masa_status: "",
    deskripsi: "",
    equipment_status: "",
    status_id: "",
    biaya: "",
    kronologi_kejadian: "",
    analisa_kerusakan: "",
    tindakan_perbaikan: "",
    status_maintenance: "",
    tanggal_selesai_maintenance: "",
    nama_teknisi: ""
}

export function ModalHistoryProvider({
    modalName = "modalEditHistory",
    id_equipment = "id",
    id_equipment_histories = "id_equipment_histories",
    onAddUpdateSuccess = () => null,
    modeEdit = false,
    data = {},
    children }) {

    const [gState, , gAction] = useGlobalState();
    const [formData, setFormData] = useState({ ...iniStateFormData });
    const [source_option, setSource_option] = useState({
        data_status: [],
        data_status_maintenancen: []
    });

    //Api
    const GetDataEdit = async (id) => {
        try {
            const resp = await gAction.getData(`/api/ext-equipment/Edit-history/${id}`, {}, null, null, false)
            if (resp.code === "00") {
                SetDefaultValueEditor(resp.data?.data_equipment);
                setSource_option({
                    data_status: resp.data?.data_status || [],
                    data_status_maintenancen: resp.data?.data_status_maintenancen || []
                });
            }
        } catch (err) {
            Swal.fire("Error", err.toString(), "error")
        }
    }
    const PostAddStatus = async (data) => {
        try {
            const resp = await gAction.postData(
                {
                    url: "/api/ext-equipment/store-history-status",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                onAddUpdateSuccess();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    const PostUpdateStatus = async (data, pelapor = false) => {
        var url = "/api/ext-equipment/update-history-status";
        if (pelapor === false)
            url = "/api/ext-equipment/update-history-status-teknisi";
        try {
            const resp = await gAction.postData(
                {
                    url: url,
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                onAddUpdateSuccess();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    //Confirm popup
    const AddStatus = (data) => {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Status?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                PostAddStatus(data);
                return true;
            } else {
                return true;
            }
        })
    }
    const UpdateStatus = (data, pelapor = false) => {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Update Status?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                PostUpdateStatus(data, pelapor)
                return true;
            } else {
                return true;
            }
        })
    }


    //Action
    const SetDefaultValueEditor = (data = {}) => {
        setFormData({
            tanggal_mulai: data?.tanggal_mulai ? moment(data?.tanggal_mulai).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            masa_status: data?.masa_status || '',
            deskripsi: data?.deskripsi || '',
            equipment_status: toString(data?.equipment_status || ''),
            status_id: toString(modeEdit ? data?.id : "0"),
            biaya: data?.biaya,
            kronologi_kejadian: data?.kronologi_kejadian || '',
            analisa_kerusakan: data?.analisa_kerusakan || '',
            tindakan_perbaikan: data?.tindakan_perbaikan || '',
            status_maintenance: data?.status_maintenance || 0,
            tanggal_selesai_maintenance: data?.tanggal_selesai_maintenance || '',
            nama_teknisi: data?.nama_teknisi || '',
        })
    }
    const ResetEditor = () => setFormData({ ...iniStateFormData });
    const HandleChange = (name, val) => setFormData((prev) => ({ ...prev, [name]: val }));
    const CloseModal = () => gAction.toggleModal(modalName);
    const SimpanStatusClick = () => {
        const dt = {
            id: toInteger(data?.[id_equipment_histories]),
            equipment_id: toInteger(data?.[id_equipment]),
            equipment_status: toInteger(formData?.equipment_status),
            tanggal_mulai: formData?.tanggal_mulai,
            tanggal_selesai: '',
            masa_status: toInteger(formData?.masa_status),
            biaya: toInteger(formData?.biaya || 0),
            deskripsi: formData?.deskripsi || '',
            kronologi_kejadian: formData?.kronologi_kejadian,
            analisa_kerusakan: formData?.analisa_kerusakan,
            tindakan_perbaikan: formData?.tindakan_perbaikan,
            status_maintenance: toInteger(formData?.status_maintenance || 0),
            tanggal_selesai_maintenance: formData?.tanggal_selesai_maintenance,
            nama_teknisi: formData?.nama_teknisi,
            created_at: ''
        }
        console.log(dt);
        const validate = ValidationStatus({ pelapor: data?.pelapor, ...dt });
        if (validate.isValid === true) {
            if (modeEdit === false) {
                AddStatus({
                    equipment_id: dt.equipment_id,
                    equipment_status: dt.equipment_status,
                    tanggal_mulai: dt.tanggal_mulai,
                    masa_status: dt.masa_status,
                    deskripsi: dt.deskripsi,
                    kronologi_kejadian: dt.kronologi_kejadian
                });
            }
            if (modeEdit === true) {
                UpdateStatus(data?.pelapor ? {
                    id: dt.id,
                    tanggal_mulai: dt.tanggal_mulai,
                    masa_status: dt.masa_status,
                    deskripsi: dt.deskripsi,
                    kronologi_kejadian: dt.kronologi_kejadian
                } : {
                    id: dt.id,
                    biaya: dt.biaya,
                    analisa_kerusakan: dt.analisa_kerusakan,
                    tindakan_perbaikan: dt.tindakan_perbaikan,
                    status_maintenance: dt.status_maintenance,
                    tanggal_selesai_maintenance: dt.tanggal_selesai_maintenance
                }, data?.pelapor);
            }
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }

    //Validation
    const ValidationStatus = (data) => {
        var isValid = true;
        var message = '';
        if (data?.equipment_status !== 5) {
            if (data?.equipment_status === 0) {
                isValid = false;
                message = "Status tidak boleh kosong";
            } else {
                if (data?.pelapor === true) {
                    if (data?.masa_status === 0) {
                        isValid = false;
                        message = "Masa status tidak boleh kosong atau 0";
                    }
                }
                if (modeEdit && data?.pelapor === false) {
                    if (data?.equipment_status === 2 && data?.status_maintenance === 0) {
                        isValid = false;
                        message = message + " Status Perbaikan tidak boleh kosong";
                    }
                    if (data?.equipment_status === 2 && data?.status_maintenance === 3) {
                        if (data?.analisa_kerusakan === '' || data?.tindakan_perbaikan === '' || data?.tanggal_selesai_maintenance === '' || data?.nama_teknisi === '') {
                            isValid = false;
                            message = message + "Status Selesai harus mengisi semua isian form (Keterangan (Perkiraan Kerusakan), Kronologi Kejadian, Analisa Kerusakan, Tindakan Perbaikan, Nama Teknisi dan Tanggal Selesai Perbaikan)";
                        }
                    }
                }
            }
        } else {
            if (data?.tanggal_mulai === '' || data?.tanggal_mulai === 'Invalid Date') {
                isValid = false;
                message = message + " Tanggal tidak boleh kosong";
            }
        }
        return {
            isValid,
            message
        }

    }


    useEffect(() => {
        if (gState?.modal?.[modalName] === true) {
            ResetEditor();
            GetDataEdit(modeEdit ? data?.[id_equipment_histories] : 0);
        }
    }, [gState?.modal?.[modalName]]);
    return <context.Provider
        value={{
            state: {
                formData,
                source_option,
            },
            action: {
                HandleChange,
                CloseModal,
                SimpanStatusClick
            }
        }}
    >
        <Modal
            name={modalName}
            baseColor={true}
        >
            {children}
        </Modal>
    </context.Provider>
}

export default function useModalHistory() {
    return useContext(context);
}