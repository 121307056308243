import React, { memo, useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import id from "date-fns/locale/id";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
    // overflowX:"auto !important"
  },
  scroller: {
    overflowX: "auto !important"
  },
  indicator: {
    // backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  typography: {
    padding: theme.spacing(3, 0, 3, 0),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    // backgroundColor: "#2e1534",
  },
}));

export default memo(function ({
  children,
  itemsTab = [],
  manualActive = null,
  withCard = true
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [keySelected, setKeySelected] = useState("");

  function handleChange(event, newValue) {
    var newKeySelected = event.currentTarget.getAttribute("id");
    // console.log(newValue)
    var newVal = newValue;
    if (manualActive !== null) {
      newVal = itemsTab.findIndex(({ id }) => manualActive === id)
      newKeySelected = itemsTab[newVal]?.id

    };
    // console.log(newVal)
    setKeySelected(newKeySelected)
    setValue(newVal)
  }
  useEffect(() => {
    var newInd = manualActive === null ? 0 : itemsTab.findIndex(({ id }) => manualActive === id)
    setKeySelected(itemsTab[newInd]?.id)
    setValue(newInd)
  }, [manualActive])

  return (
    <>
      <div className={` ${withCard ? "card" : ''} `}>
        <div className={` ${withCard ? "card-body" : ''} `}>
          <div className={classes.root}>
            <div className={classes.demo1}>
              {/* <div className="col"> */}

                <AntTabs value={value} onChange={handleChange}>
                  {
                    itemsTab.map(({ title, id, onClick = () => null }, index) => (
                      <AntTab label={title} key={id} id={id} onClick={onClick} />
                    ))
                  }
                </AntTabs>
              {/* </div> */}
              {/* <Typography className={classes.typography} /> */}
              {React.Children.map(children, (child, i) => {
                // console.log(manualActive === null ? keySelected === child.props.id : manualActive === child.props.id)
                return (manualActive === null ? keySelected === child.props.id : manualActive === child.props.id) && <Typography component={'div'} className={classes.typography} key={i} index={i}>
                  {
                    React.cloneElement(child, {
                      active: manualActive === null ? keySelected : manualActive,
                    })
                  }
                </Typography>
              }

                // </Wrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export const TabContent = memo(({ children, active, id, ...props }) => {
  // console.log(props)

  const classes = useStyles();
  return children
  // return (
  //     <>
  //         {id === active && <Typography className={classes.typography}>
  //             {children}
  //         </Typography>}
  //     </>
  // )
})
