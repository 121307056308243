import React, { useRef } from 'react';
import useEqCrMain, { EqCrMainProvider } from './useEqCrMain';
import { GridDx, HeaderList, Modal } from '../../../../components';
import { Card } from 'react-bootstrap';
import { Column } from 'devextreme-react/data-grid';
import Swal from 'sweetalert2';
import ModalHistory from '../component/modalEditingHistory/modalHistory';

const columns = [
    { fieldName: 'nomor', caption: 'Nomor Asset', width: 150 },
    { fieldName: 'nama', caption: 'Nama Alat' },
    { fieldName: 'lab', caption: 'LAB', width: 100 },
    { fieldName: 'penanggung_jawab_name', caption: 'PJ', width: 100 },
    { fieldName: 'pic_name', caption: 'Operator', width: 100 },
    { fieldName: 'status_equipment', caption: 'Status Expired', width: 100 },
    { fieldName: 'tanggal_selesai', caption: 'Masa berlaku status', width: 150 },
    { fieldName: 'equipment_status_str', caption: 'Status Alat', allowFiltering: true, width: 100 },
    // { fieldName: 'status_name_01', caption: '1', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_02', caption: '2', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_03', caption: '3', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_04', caption: '4', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_05', caption: '5', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_06', caption: '6', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_07', caption: '7', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_08', caption: '8', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_09', caption: '9', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_10', caption: '10', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_11', caption: '11', allowFiltering: true, width: 50 },
    // { fieldName: 'status_name_12', caption: '12', allowFiltering: true, width: 50 },
]

function Index(props) {
    const { eqCrMainState, eqCrMainAction } = useEqCrMain();
    const tableRef = useRef();
    return (
        <>
            <ModalHistory modeEdit={eqCrMainState.modeEdit} data={eqCrMainState.data} onAddUpdateSuccess={eqCrMainAction.onAddUpdateSuccess} />
            <div style={{ paddingBottom: 20 }}>
                <HeaderList
                    title={eqCrMainState?.data_page?.page_title}
                    someText={eqCrMainState?.data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5 px-5">
                        <div className="col-md-12">
                            <GridDx
                                ref={tableRef}
                                searchForm={true}
                                cellRender={eqCrMainAction.CustomCellRender}
                                loading={eqCrMainState.loadingTable}
                                data={eqCrMainState.dataTable?.data_table}
                                columns={columns}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                                <Column width={160} name={"action_history"} caption="Action" headerCellRender={eqCrMainAction.HeaderCellRender} cellRender={(rowInfo) => {
                                    return (
                                        <>
                                            <button title='Edit tindakan perbaikan' className="btn btn-sm btn-primary text-center py-1 px-2 mx-1"
                                                onClick={(e) => {
                                                    // if (rowInfo.data?.is_teknisi === true) {
                                                    eqCrMainAction.editHistory({ pelapor: false, ...rowInfo?.data })
                                                    // } else {
                                                    //     return Swal.fire({
                                                    //         title: 'Warning',
                                                    //         text: "Anda tidak memiliki hak akses",
                                                    //         icon: 'warning',
                                                    //     });
                                                    // }
                                                }}
                                            >
                                                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
                                            </button>
                                            <button title='Approve tindakan perbaikan' onClick={() => {
                                                if (rowInfo.data?.is_penanggung_jawab) {
                                                    // ceqAction.Approval2(rowInfo?.data?.id)
                                                } else {
                                                    return Swal.fire({
                                                        title: 'Warning',
                                                        text: "Anda tidak memiliki hak akses",
                                                        icon: 'warning',
                                                    });
                                                }
                                            }} className='btn btn-icon btn-xs btn-primary'>
                                                <i className='fas fa-check-circle'></i>
                                            </button>
                                            <a title='Laporan' href={`/equipment/report-status/${rowInfo.data?.id_equipment_histories}`} target='_blank' className="ml-1 btn btn-icon btn-xs btn-outline-danger">
                                                <i className="far fa-file-pdf"></i>
                                            </a>
                                        </>
                                    )
                                }} />
                            </GridDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default () => <EqCrMainProvider><Index /></EqCrMainProvider>;