import React, { useEffect } from 'react';
// import ViewDetail from '../../../../laboratory/component/viewDetail';
import { Modal } from 'react-bootstrap';
import useCrudPengujian from '../useCrudPengujian';
import ViewDetail from '../../../../nonAdmin/laboratory/component/viewDetail';

function ModalView(props) {
    const { cpState, cpAction } = useCrudPengujian();
    const closeModal = () => {
        cpAction.setModalView({ show: false, default_data: {} });
    }
    // useEffect(() => {
    //     if (cpState.modalView?.show) {
    //         cpAction.getDetail(cpState.modalView?.default_data?.id_lab_test || "");
    //     }
    // }, [cpState.modalView?.show])
    return (
        <Modal size='xl' show={cpState.modalView?.show} onHide={closeModal}>
            <Modal.Body>
                <ViewDetail data={cpState.dataDetail} />
            </Modal.Body>
        </Modal>
    );
}

export default ModalView;