import React, { useEffect, useState } from 'react';
import { useGlobalState } from "../../contexts/useGlobalState";
// import FileUploader from 'devextreme-react/file-uploader';
import { DateBox } from 'devextreme-react';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TextEditor, FileUploadDx } from '../../components';
import { useRef } from 'react';

const ModalAddLogBook = (props) => {
    const [state, dispatch, action] = useGlobalState();
    const [date, setDate] = useState(new Date());
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [way_forward, setWay_forward] = useState('');
    const refFile = useRef();
    // const [file, setFile] = useState('');

    useEffect(() => {
        if (refFile.current)
                refFile.current.instance.reset();
        if (state.modal?.modalAddLogbook === true && props.data) {
            setDate(props.data?.date);
            setType(props.data?.type);
            setDescription(props.data?.description);
            setWay_forward(props.data?.way_forward);
            action.setForm('file', props.data?.file);
        }
    }, [state.modal?.modalAddLogbook])
    // function fileOnchange(data) {
    //     const file = data.value?.[0]
    //     if (data.value?.[0] && file.size <= 10000000) {
    //         action.postData(
    //             {
    //                 url: "/api/base/upload-file-temp",
    //                 data: {
    //                     "File": data.value?.[0],
    //                 },
    //                 withFile: true,
    //                 withLoading: false,
    //                 withAlert: false
    //             }
    //         ).then((resp) => {
    //             if (resp.code === "00") {
    //                 const newData = state.formData.file || [];
    //                 newData.splice(newData, 0, {
    //                     "file_location": resp.data?.data_file_location,
    //                     "file_name": resp.data?.data_filename,
    //                     "is_new": true,
    //                 })
    //                 action.setForm("file", newData);
    //             }
    //         })
    //     } else if (data.value?.[0] && file.size > 10000000) {
    //         return Swal.fire({
    //             title: 'Error',
    //             text: "File lebih dari 10Mb",
    //             icon: 'error',
    //         })
    //     }
    // }
    function fileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            const newData = state.formData.file || [];
            newData.splice(newData, 0, {
                "file_location": resp.data?.data_file_location,
                "file_name": resp.data?.data_filename,
                "is_new": true,
            })
            action.setForm("file", newData);
        }
    }
    function deleteFile(key, data) {
        // console.log('delete', data)
        const newData = state.formData.file || []
        const indexOf = newData.indexOf(data)

        if (!data.is_new) {
            newData.splice(indexOf, 1)
            action.setForm("file", newData);

            const docdelete = state.formData.deleteDocument || []
            docdelete.splice(docdelete, 0, data)
            action.setForm("deleteDocument", docdelete);
        } else {
            deleteFileApi(key, data.file_name)
        }
    }
    function deleteFileApi(index, fileName) {
        // console.log('fileName', fileName)
        action.postData(
            {
                url: "/api/base/delete-file-temp?fileName=" + fileName,
                withFile: false,
                withLoading: false,
                withAlert: false
            }
        ).then((resp) => {
            const newData = state.formData.file || []
            newData.splice(index, 1)
            action.setForm("file", newData);
        })
    }
    function save(status) {
        const dates = moment(new Date(date || new Date().toDateString())).format('YYYY-MM-DD')
        if ((type || '') === '') {
            return Swal.fire({
                title: 'Error',
                text: "Type tidak boleh kosong",
                icon: 'error',
            })
        } else {

            const data = {
                "id": props.data.idLog,
                "date": dates,
                "type": type || '',
                "description": description || '',
                "status": status,
                "uploaded_file": [...(state.formData.file || [])].map(d => { return d.file_name }).join(','),
                "uploaded_location": '',
                "way_forward": way_forward,
                "research_id": state.data_view_header?.id,
                "user_id": '',
                "document": [...(state.formData.file || [])].map(d => {
                    return {
                        "file_name": d.file_name,
                        "is_new": d.is_new
                    }
                }),
                "document_delete": [...(state.formData.deleteDocument || [])].map(d => {
                    return {
                        "file_name": d.file_name,
                        "is_new": d.is_new
                    }
                })
            }
            // console.log('data kirim', data, dates)
            action.postData(
                {
                    url: "/api/project-ongoing-crud/update-logbook",
                    data: data,
                    withFile: false,
                }
            ).then((resp) => {
                if (resp.code === '00') {
                    props.onFinish();
                }
            })
        }
    }
    return (
        <div className="row" style={{ overflow: 'hidden' }}>
            <div className="col-md-12">

                <span>Create Log Book</span>
                <hr />
                <div className="row mb-1">
                    <div className="col-md-2">Date</div>
                    <div className="col-md-6">
                        <DateBox defaultValue={new Date()} type="date" value={date} onValueChange={(e) => setDate(e)} />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-md-2">Type</div>
                    <div className="col-md-6">
                        <select className="form-control form-control-sm"
                            onChange={(e) => setType(e.target.value)}
                            value={type}>
                            <option value="" disabled selected>- Pilih Type -</option>
                            <option value="1">Achievement</option>
                            <option value="2">Concern</option>
                            <option value="3">Other</option>
                        </select>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-md-2">Descriptions</div>
                    <div className="col-md-10">
                        <TextEditor
                            onChange={(e) => setDescription(e)}
                            value={description}
                            className="mb-5"
                            multilineToolbar={false}
                            height="250px"
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-md-2">Way Forwad</div>
                    <div className="col-md-10">
                        <TextEditor
                            onChange={(e) => setWay_forward(e)}
                            value={way_forward}
                            className="mb-5"
                            multilineToolbar={false}
                            height="250px" />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-md-2">Attachment</div>
                    <div className="col-md-10">
                        <FileUploadDx
                            ref={refFile}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 10 Mb"
                            accept="*"
                            onUploaded={fileUploaded}
                            maxFileSize={10000000}
                            uploadMode="instantly"
                        />
                        {/* <FileUploader
                            onValueChanged={(e) => fileOnchange(e)}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={false} multiple={false}
                            selectButtonText="Select File" labelText="or Drop file hire | Max size 10 Mb"
                            accept="*" maxFileSize={10000000} uploadMode="useForm" /> */}
                    </div>
                </div>
                <div className="row mb-1 justify-content-end">
                    <div className="col-md-10">
                        {
                            [...(state.formData.file || [])].map((d, i) => (
                                <div className="d-flex flex-row justify-content-between mb-1">

                                    <span className="d-block pl-2" style={{ fontSize: '8pt' }}>{d.file_name}</span>
                                    {
                                        state.filterData.mode === "edit" ? (
                                            <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deleteFile(i, d)}></i>
                                        ) : (
                                            <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1" onClick={() => { }}></i>
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-sm btn-primary m-1" onClick={() => save('2')}>Submit</button>
                        <button className="btn btn-sm btn-outline-primary m-1" onClick={() => save('1')}>Save as Draft</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalAddLogBook