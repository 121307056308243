import React, { useEffect, useRef } from 'react';
import { Tab, TabContent, AccordionCs } from '../../../../../components';
import Card from 'react-bootstrap/Card'
import useUserAccessPage, { AccessProvider } from './useUserAccessPage';
import ContentSpiirit from './contentSpiirit';
import ContentAdmin from './contentAdmin';
import './userAccess.css';

function UserAccess() {
    const tableRef = useRef();
    const { acsAction, acsState } = useUserAccessPage();
    useEffect(() => {
        acsAction.getUserAccessSpiirit('');
        acsAction.getUserAccessAdmin('');
    }, [])
    return (
        <>
            <Card
                className="m-0 p-0 h-100 mb-5 customShadow"
            >
                <Card.Body>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-6'>
                            <div className='d-block d-sm-block d-md-flex d-lg-flex mb-5'>
                                <div className='input-group mb-3 mr-1'>
                                    <div className="input-group-prepend">
                                        <span className='input-group-text'>Pilih Role</span>
                                    </div>
                                    <select className="form-control form-control" onChange={(e) => acsAction.roleChange(e.target.value)} value={acsState.role_id}>
                                        <option value={''}>-Pilih Role-</option>
                                        {
                                            [...(acsState.dataUserAccessSpiirit?.data_role || [])].map((d, i) => (
                                                <option key={i} value={d?.id}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='input-group mb-3 mr-1'>
                                    <div className="input-group-prepend">
                                        <span className='input-group-text'>Role Template</span>
                                    </div>
                                    <select className="form-control form-control" onChange={(e) => acsAction.roleDefChange(e.target.value)} value={acsState.role_idDef}>
                                        <option value={''}>-Pilih Role-</option>
                                        {
                                            [...(acsState.dataUserAccessSpiirit?.data_role || [])].map((d, i) => (
                                                <option key={i} value={d?.id}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mb-2">
                        <div className="col-md-2">
                            <span className="d-block">Pilih Role</span>
                            <select className="form-control form-control-sm" onChange={(e) => acsAction.roleChange(e.target.value)} value={acsState.role_id}>
                                <option value={''}>-Pilih Role-</option>
                                {
                                    [...(acsState.dataUserAccessSpiirit?.data_role || [])].map((d, i) => (
                                        <option key={i} value={d?.id}>{d?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-md-2">
                            <span className="d-block">Role Template</span>
                            <select className="form-control form-control-sm" onChange={(e) => acsAction.roleDefChange(e.target.value)} value={acsState.role_idDef}>
                                <option value={''}>-Pilih Role-</option>
                                {
                                    [...(acsState.dataUserAccessSpiirit?.data_role || [])].map((d, i) => (
                                        <option key={i} value={d?.id}>{d?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-12">
                            <Tab
                                withCard={false}
                                itemsTab={[
                                    {
                                        id: "spiirit",
                                        title: "HOME",
                                        onClick: () => acsAction.tabChange('spiirit')
                                    },
                                    {
                                        id: "admin",
                                        title: "Admin",
                                        onClick: () => acsAction.tabChange('admin')
                                    },
                                ]}
                                manualActive={acsState.tab}
                            >
                                <TabContent id='spiirit'>
                                    <div className="row">
                                        <div className="col-md-12 custom-title">
                                            <AccordionCs data={acsState.role_id === '' ? [] : acsState.dataUserAccessSpiirit?.data_table} titleField='modul_name' renderTitle={acsAction.renderTitle} renderContent={(props) => <ContentSpiirit dataSource={props?.data_child || []} modul={props?.modul} />} />
                                        </div>
                                    </div>
                                </TabContent>
                                <TabContent id='admin'>
                                    <div className="row">
                                        <div className="col-md-12  custom-title">
                                            <AccordionCs data={acsState.role_id === '' ? [] : acsState.dataUserAccessAdmin?.data_table} titleField='modul_name' renderTitle={acsAction.renderTitle} renderContent={(props) => <ContentAdmin dataSource={props?.data_child || []} modul={props?.modul} />} />
                                        </div>
                                    </div>
                                </TabContent>

                            </Tab>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-sm btn-primary"
                            onClick={() => acsAction.saveAccess()}
                        ><i className="fa fa-save icon-nm"></i>Simpan</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default (props) => <AccessProvider><UserAccess /></AccessProvider>