import React, { useRef, useState } from 'react';
import { FileUploadDx } from '../../../../components';
import usePermohonanPengajuan from '../usePermohonanPengajuan';
import { toInteger } from 'lodash';

export const fileExtensions = ['.pdf'];

function SteppOne(props) {
    const { ppAction, ppState } = usePermohonanPengajuan();
    const refFile = useRef();
    const fileUploaded = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            ppAction.addFile(resp.data?.data_filename || "");
            if (refFile.current) {
                refFile.current.instance.reset();
            }
        }
    }
    return (
        <div className='d-flex flex-column justify-content-between h-100'>
            <form>
                <div className="form-group row">
                    <label htmlFor="judul" className="col-sm-2 col-form-label col-form-label-sm">Judul Pengujian</label>
                    <div className="col-sm-4">
                        <textarea className="form-control form-control-sm" id="judul" rows="3"
                            value={ppState.dataStepOne?.judul_pengujian}
                            onChange={e => ppAction?.judulChange(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row mb-5 p-4 mx-1 mt-1 border rounded'>
                    <div className='col-12'>
                        <div className='text-right mb-3'>
                            <button onClick={() => ppAction.openModal()} type='button' className='btn btn-sm btn-primary'>+ Tambah</button>
                        </div>
                        <table className="table tblecs table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Jenis Komoditi</th>
                                    <th scope="col">Nama Sample</th>
                                    <th scope="col">Volume/Berat Sample</th>
                                    <th scope="col">Satuan</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Keterangan</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    [...(ppState.dataStepOne?.komoditi || [])].map((d, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{d?.lab_master_como_nama}</td>
                                            <td>{d?.nama}</td>
                                            <td>{d?.volume_berat}</td>
                                            <td>{d?.satuan}</td>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <button type='button' onClick={() => ppAction.openModal(true, d)} className="btn btn-icon btn-xs btn-success mr-1">
                                                        <i className="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type='button' onClick={() => ppAction.deleteKomoditi(d)} className="btn btn-icon btn-xs btn-danger">
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td>{d?.note}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="jml" className="col-sm-2 col-form-label col-form-label-sm">Jumlah Komoditi Sample</label>
                    <div className="col-sm-4">
                        <div className='bg-secondary px-5 py-2 rounded text-right'>{
                            [...(ppState.dataStepOne?.komoditi || [])].length
                        }</div>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="file" className="col-sm-2 col-form-label col-form-label-sm">Dokumen pendukung (Optional)</label>
                    <div className="col-sm-4">
                        <FileUploadDx
                            ref={refFile}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            allowedFileExtensions={fileExtensions}
                            readyToUploadMessage=" "
                            labelText="(*.pdf) | Max size 10 Mb"
                            accept="*"
                            invalidFileExtensionMessage="File harus berupa PDF"
                            onUploaded={fileUploaded}
                            maxFileSize={10000000}
                            uploadMode="instantly"
                        />
                        {
                            [...(ppState.dataStepOne?.file_docs || [])].length > 0 && (
                                <div className='my-5'>
                                    {[...(ppState.dataStepOne?.file_docs || [])].map((d, i) => (
                                        <div key={i} className='mb-2 bg-secondary py-3 px-5 rounded d-flex justify-content-between align-items-center'>
                                            {d?.file_name}
                                            <button type='button' onClick={() => ppAction.deleteFile(i)} className="btn btn-icon btn-xs btn-danger">
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    </div>
                </div>
            </form>
            <div className='d-flex justify-content-end'>
                <button onClick={() => window.close()} className='btn btn-sm btn-secondary mr-5'>Batal</button>
                <button onClick={ppAction.handleNextToStepTwo} className='btn btn-sm btn-primary'>Selanjutnya <i className='fas fa-angle-double-right icon-nm'></i></button>
            </div>
        </div>
    );
}

export default SteppOne;