import React, { memo, useEffect, useState } from 'react';
import { Tab, TabContent, HeaderList, HeaderProject, Modal } from '../../components';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useGlobalState } from '../../contexts/useGlobalState';
import TabBudget from './tabbudget';
import TabDetail from './tabdetail';
import TabRisk from './tabrisk';
import TabTimeline from './tabtimeline';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card'
import TabDetailOperational from './tabDetailOperational';


export default memo(function (props) {
    const [state, dispatch, action] = useGlobalState();
    const [selectedTab, setSelectedTab] = useState('detail')
    const history = useHistory()
    const param = useParams()
    const { pathname } = useLocation()
    const temp = pathname.slice(1, pathname.length)
    const path = temp.substr(0, temp.indexOf('/'))

    // useEffect(() => {
    //     action.resetForm();
    //     action.setForm('disable', true);
    //     action.getData("/api/project-portfolio-crud/view-detail-2/" + param?.id, {}, null, null, false).then((resp) => {
    //         if (resp.code === "00") {
    //             if (resp.data.data_view_header?.project_type !== 5) {
    //                 action.getData("/api/project-portfolio-crud/view-risk-2/" + param.id).then((resp) => {
    //                     if (resp.code === "00") {
    //                         action.setForm("data_view_risk", resp.data.data_view_risk)
    //                         if (resp.data.data_view_header?.submit_status !== 0 && resp.data.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio') {
    //                             action.setForm('disable', true)
    //                         }
    //                     }
    //                 })
    //             }
    //             if (resp.data.data_view_header?.submit_status !== 0 && resp.data.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio') {
    //                 action.setForm('disable', true)
    //             }
    //             action.setForm('portfolio_data_page', resp.data_page);
    //         }
    //     })
    // }, [])
    function onTabChanged(val) {
        setSelectedTab(val)
    }
    return (
        <div>
            <HeaderList
                title={state.formData?.portfolio_data_page?.page_title}
                someText={state.formData?.portfolio_data_page?.page_breadcrumbs}
            >
            </HeaderList>
            <HeaderProject
                workflow_stage={state.data_view_header?.workflow_state_name == 'monitoring_revise_portfolio' ? "Project Monitoring" : "Project Portfolio"}
                rightMenu={true}
                dataHeader={state.data_view_header}
                history_onClick={({ id, baseline_name }) => history.push(`/Projects/ResearchHistories-public/${id}`)}
                monthly_onClick={({ id, baseline_name }) => history.push((path === 'admin' ? '/admin/reportResearch/index/' : '/reportResearch/index/') + id)}
                profile_onClick={({ id, baseline_name }) => history.push((path === 'admin' ? '/' : '/research/project-profile/') + id + "/" + baseline_name)}
            />
            {
                (state.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio' ||
                    state.data_view_header?.workflow_state_name === 'portfolio_waiting_approval_revise' || state.data_view_header?.workflow_state_name === 'monitoring_waiting_approval') && (
                    <Card
                        bg="warning"
                        className="m-0 p-0 mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <div className="row m-5 text-white">
                                <div className="col-md-12">
                                    <span className="d-block mb-3">
                                        <b>
                                            Note :
                                        </b>
                                    </span>
                                    <span className="d-block text-wrap">{state.data_reason}</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )
            }
            <Card
                className="mx-0 p-3 customShadow"
                style={{ marginBottom: '40px' }}
            >
                <Card.Body>
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "detail",
                                title: "Project Detail",
                                onClick: () => onTabChanged("detail")
                            },
                            ...(state.data_view_header?.project_type !== 5 ? [{
                                id: "risk",
                                title: "Project Risk",
                                onClick: () => onTabChanged("risk")
                            }] : []),
                            {
                                id: "timeline",
                                title: "Project Timeline",
                                onClick: () => onTabChanged("timeline")
                            },
                            {
                                id: "budget",
                                title: "Drawdown Budget",
                                onClick: () => onTabChanged("budget")
                            },
                        ]}
                        manualActive={selectedTab}
                    >
                        <TabContent id="detail">
                            <TabDetail param={param} />
                            {/* {
                                (state.data_view_header?.project_type === 1 ||
                                    state.data_view_header?.project_type === 2 ||
                                    state.data_view_header?.project_type === 3 ||
                                    state.data_view_header?.project_type === 4 ||
                                    state.data_view_header?.project_type === 6) && (
                                    <TabDetail param={param}></TabDetail>
                                )
                            }
                            {
                                state.data_view_header?.project_type === 5 && (
                                    <TabDetailOperational param={param} />
                                )
                            } */}
                        </TabContent>
                        <TabContent id="risk">
                            <TabRisk param={param}></TabRisk>
                        </TabContent>
                        <TabContent id="timeline">
                            <TabTimeline param={param}></TabTimeline>
                        </TabContent>
                        <TabContent id="budget">
                            <TabBudget param={param} addTimeline={() => onTabChanged("timeline")}></TabBudget>
                        </TabContent>
                    </Tab>
                </Card.Body>
            </Card>
            {/* <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className="col-md-6">
                        {
                            history.length > 1 && (
                                <button className='btn btn-sm btn-secondary'
                                    onClick={() => history.goBack()}
                                >
                                    Kembali
                                </button>
                            )
                        }
                    </div>
                    <div className="col-md-6 text-right">                        
                    </div>
                </div>
            </div> */}
        </div>
    )
})