import React, { useEffect, useState } from "react";
import AuthenticationIdAManService from "./authenticationIdAManService";
import { useContext } from "react";

const AuthContext = React.createContext({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({}),
    getUser: () => null,
    parseJwt: () => null,
    LoginRequest: () => null
});

export const AuthConsumer = AuthContext.Consumer;

export function AuthenticationProvider({ children }) {
    const [authService] = useState(new AuthenticationIdAManService());
    useEffect(() => {

    },[])

    const LoginRequest = async () => {
        authService.signinRedirectCallback();
    };

    return <AuthContext.Provider value={{ ...authService, LoginRequest }}>{children}</AuthContext.Provider>;

}
export function useAuthenticationIdAMan() {
    return useContext(AuthContext);
}