import React from 'react';
import './index.css';
// import LogoSp from './images/logo-pertamina.png';

function Index(props) {
    return <div className={'footer-css text-center'} {...props}>
        {/* <img src={LogoSp} height={'20px'}></img> */}
        <span className='d-block mr-1 text-muted text-center mt-1'>Copyright &copy; 2022 SPIIRIT. All Rights Reserved.</span>
    </div>
}

export default Index;