import { toInteger, toString } from "lodash";
import React, { createContext, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";
import { useGlobalState } from "../../../contexts/useGlobalState";
import { useHistory, useLocation } from "react-router-dom";
import ModalAddEditSample from "./component/modalAddEditSample";
import ModalUploadSPK from "./component/modalUploadSPK";
import ModalDraftSPK from "./component/modalDraftSPK";

const contexts = createContext({
    ppState: {},
    ppAction: {}
});

export function PerPengProvider({ children }) {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get("id_lab_test") || "";
    const activeStep = searchParams.get("step") || "0";
    const [modalDraftSpk, setModalDraftSpk] = useState({ show: false, data: {} });
    const [lab_customer_id, setlab_customer_id] = useState(0);

    const [id_lab_test, setId_lab_test] = useState(id);
    const history = useHistory();
    const [state] = useGlobalState();
    const { doGet, doPost } = useFetch();

    const [modal, setModal] = useState({
        show: false,
        editMode: false,
        defaultData: {}
    });

    //modal editing sample
    const openModal = (editMode = false, defaultData = {}) => setModal({ show: true, editMode, defaultData });
    const closeModal = () => setModal({ show: false, editMode: false, defaultData: {} });
    const onClickSimpanModal = (isEditing = false, formData = {}) => {
        const data = {
            ...formData,
            lab_master_como_nama: jenisKomoditi.find(d => toString(d?.id) === toString(formData?.lab_master_como_id))?.nama
        }
        var validate = validationModalSample(data);
        if (!validate.isValid) {
            return Swal.fire({
                title: 'Error',
                text: validate.message,
                icon: 'warning',
            })
        }
        if (isEditing) {
            updateKomoditi(data);
        } else {
            addKomoditi(data);
        }
    }

    //Handle Step One
    const [dataStepOne, setDataStepOne] = useState({
        judul_pengujian: "",
        komoditi: [],
        komoditiDel: [],
        file_docs: [],
    });
    const judulChange = (val) => {
        setDataStepOne(prev => ({ ...prev, judul_pengujian: val }));
    }
    const addKomoditi = (data = {}) => {
        const newkomo = { id: Math.random(), ...data, is_new: true };
        setDataStepOne(prev => ({
            ...prev,
            komoditi: [...prev?.komoditi, newkomo],
        }));
        closeModal();
    }
    const updateKomoditi = (data = {}) => {
        setDataStepOne(prev => ({
            ...prev,
            komoditi: prev.komoditi.map(d => {
                if (toString(d?.id) === toString(data?.id)) {
                    return {
                        ...data,
                        is_edit: true
                    }
                } else {
                    return d;
                }
            }),
        }));
        closeModal();
    }
    const deleteKomoditi = (data = {}) => {
        const b = dataStepOne.komoditi.filter(d => d?.id === data?.id && d?.is_new === false);
        setDataStepOne(prev => ({
            ...prev,
            komoditiDel: b ? [...prev.komoditiDel, data] : prev.komoditiDel,
            komoditi: prev.komoditi.filter(d => d?.id !== data?.id),
        }));
    }
    const addFile = (data_filename = "") => {
        setDataStepOne(prev => ({
            ...prev,
            file_docs: [{
                "file_location": data_filename,
                "file_name": data_filename,
                "is_new": true
            }, ...prev.file_docs]
        }));
    }
    const deleteFile = (index = 0) => {
        const data = [...(dataStepOne.file_docs || [])][index];
        if (data?.is_new) {
            setDataStepOne(prev => ({
                ...prev,
                file_docs: prev.file_docs.filter((p, i) => i !== index)
            }));
            deleteFileTempp(data?.file_name);
        } else {
            setDataStepOne(prev => ({
                ...prev,
                file_docs: prev.file_docs.filter((p, i) => i !== index),
                file_docs_delete: [prev?.file_docs_delete, data]
            }));
        }
    }
    const handleNextToStepTwo = () => {
        const validate = validationStep1();
        if (!validate.isValid) {
            return Swal.fire({
                title: 'Error',
                text: validate.message,
                icon: 'warning',
            })
        }
        simpanPermohonan1();
    }

    //Handle Step Two
    const generateDataKomoditiSample = (parameterUji = []) => {
        let bantu = parameterUji[0]?.lab_master_como_id;
        let icon_id = 1;
        const data = parameterUji.map(prev => {
            if (bantu !== prev.lab_master_como_id) {
                bantu = prev.lab_master_como_id;
                icon_id++;
            }
            return {
                ...prev,
                parameter_uji: [...(prev?.tabel_parameter_uji || [])].map(d => d?.lab_master_como_param_id).filter(d => d !== null),
                icon_id
            }
        });
        setDataStepTwo(data);
        const dataOne = data[0]
        setFormDataStepTwo({
            jenis_komoditi: dataOne?.lab_master_como_nama,
            nama: dataOne?.nama,
            volume_berat: dataOne?.volume_berat,
            satuan: dataOne?.satuan,
            parameter_uji: dataOne?.parameter_uji || [],
            tabel_parameter_uji: dataOne?.tabel_parameter_uji || [],
            note: dataOne?.note,
        });
        setActiveKomoditi(dataOne?.id);
        // setCurrentVlueStepOne(dataStepOne.komoditi);
        // nextStep();
    }
    const [activeKomoditi, setActiveKomoditi] = useState(0);
    const [formDataStepTwo, setFormDataStepTwo] = useState({
        jenis_komoditi: "",
        nama: "",
        volume_berat: "",
        satuan: "",
        parameter_uji: [
        ],
        note: "",
        tabel_parameter_uji: []
    })
    const [dataStepTwo, setDataStepTwo] = useState([]);
    const handleChangeKomoditi = (id) => {
        const data = dataStepTwo.find(d => toString(d?.id) === toString(id));
        setDataStepTwo(dataStepTwo.map((d, i) => {
            if (toString(d?.id) === toString(activeKomoditi)) {
                return {
                    ...d,
                    jenis_komoditi: d?.lab_master_como_nama,
                    nama: formDataStepTwo?.nama,
                    volume_berat: formDataStepTwo?.volume_berat,
                    satuan: formDataStepTwo?.satuan,
                    parameter_uji: formDataStepTwo?.parameter_uji || [],
                    note: formDataStepTwo?.note,
                }
            } else {
                return d
            }
        }));
        setFormDataStepTwo({
            jenis_komoditi: data?.lab_master_como_nama,
            nama: data?.nama,
            volume_berat: data?.volume_berat,
            satuan: data?.satuan,
            parameter_uji: data?.parameter_uji,
            note: data?.note,
            tabel_parameter_uji: data?.tabel_parameter_uji
        });
        setActiveKomoditi(id);
    }
    const simpanEditing = () => {
        const data = dataStepTwo.map((d, i) => {
            if (toString(d?.id) === toString(activeKomoditi)) {
                return {
                    ...d,
                    nama: formDataStepTwo?.nama,
                    volume_berat: formDataStepTwo?.volume_berat,
                    satuan: formDataStepTwo?.satuan,
                    parameter_uji: formDataStepTwo?.parameter_uji,
                    note: formDataStepTwo?.note
                }
            } else {
                return d
            }
        }).map(d => (({
            "lab_test_sample_como_id": d?.id,
            // "nama": d?.nama || "",
            // "volume_berat": d?.volume_berat || "",
            // "satuan": d?.satuan || "",
            // "note": d?.note || "",
            "lab_master_como_param_id": d?.parameter_uji || []
        })));
        const validate = validationStep2(data);
        if (!validate.isValid) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            });
        } else {
            Swal.fire({
                title: "Simpan perubahan?",
                text: "",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya, Lanjutkan",
                cancelButtonText: "Batal"
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log(data);
                    simpanPermohonan2(data);
                }
            });
        }
    }
    const handleChangeParameterUji = (val, id) => {
        if (val === true) {
            setFormDataStepTwo(prev => ({
                ...prev,
                parameter_uji: [...prev.parameter_uji, id]
            }));
        } else {
            setFormDataStepTwo(prev => ({
                ...prev,
                parameter_uji: [...prev.parameter_uji].filter(d => d !== id)
            }));
        }
    }
    const handleNextToStepThree = () => {
        simpanEditing();
        // nextStep();
    }

    //Handle Step Four
    const kirimPengajuan = (formData = {}) => {
        let data = {
            "spk_no": formData?.no || "",
            "spk_date": formData?.tglSpk || "",
            "spk_date_end": formData?.tglSel || "",
            "spk_note1": formData?.dpk || "",
            "file_docs_delete": [...formData?.fileDel],
            "file_docs_add": formData?.file ? [{ ...formData?.file }].filter(d => d.id === 0) : []
        }

        console.log(data)
        const validate = validationStep4(data);
        if (!validate.isValid) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            });
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Kirim Pengajuan ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                simpanPermohonan4({
                    id: id_lab_test,
                    ...data,
                });
            }
        });
    }

    //validation
    const validationStep1 = () => {
        var isValid = true;
        var message = [];
        if (dataStepOne.judul_pengujian === null || dataStepOne.judul_pengujian === "") {
            isValid = false
            message = [...message, "Judul Pengujian"];
        }
        if (dataStepOne.komoditi.length < 1) {
            isValid = false;
            message = [...message, "Jenis Komoditi"];
        }
        return {
            isValid,
            message: message.join(", ") + " Tidak boleh kosong",
        }
    }
    const validationStep2 = (data = []) => {
        var isValid = true;
        var message = "";
        data.forEach(d => {
            if ([...(d?.lab_master_como_param_id || [])].length === 0) {
                isValid = false;
                message = "Harap Memilih Minimal 1 Parameter uji pada semua sample";
            }
        });
        return {
            isValid,
            message
        }
    }
    const validationModalSample = (d = {}) => {
        var isValid = true;
        var message = "";
        if (d?.lab_master_como_id === "" || d?.nama === "" || d?.volume_berat === "" || d?.satuan === "") {
            isValid = false;
            message = "Jenis Komoditi, Nama sample, Volume berat, Satuan tidak boleh kosong !"
        }
        return {
            isValid,
            message
        }
    }
    const validationStep4 = (data = {}) => {
        var isValid = true;
        var message = "";
        // if (data?.spk_no === "" || data?.spk_date === "" || data?.spk_date_end === "" || data?.spk_note1 === "") {
        //     isValid = false;
        //     message = "Tanggal SPK, Tanggal Selesai, Nota Dinas/Surat Keluar/Notulen Rapat/Email (PDF), Dasar Pelaksanan Pekerjaan Tidak Boleh Kosong!"
        // }
        // if ([...(data?.file_docs_delete || [])].length > 0 && [...(data?.file_docs_add || [])].length < 1) {
        //     isValid = false;
        //     message = "Tanggal SPK, Tanggal Selesai, Nota Dinas/Surat Keluar/Notulen Rapat/Email (PDF), Dasar Pelaksanan Pekerjaan Tidak Boleh Kosong!"
        // }
        // if ([...(data?.file_docs_delete || [])].length === 0 && [...(dataForm4.file_docs || [])].length < 1 && [...(data?.file_docs_add || [])].length < 1) {
        //     isValid = false;
        //     message = "Tanggal SPK, Tanggal Selesai, Nota Dinas/Surat Keluar/Notulen Rapat/Email (PDF), Dasar Pelaksanan Pekerjaan Tidak Boleh Kosong!"
        // }

        //Form 3 New Validation
        if ([...(data?.file_docs_delete || [])].length > 0 && [...(data?.file_docs_add || [])].length < 1) {
            isValid = false;
            message = "Harap upload file 'Nota Dinas/Surat Keluar/Notulen Rapat/Email (PDF)'"
        }
        if ([...(data?.file_docs_delete || [])].length === 0 && [...(dataForm3.file_docs || [])].length < 1 && [...(data?.file_docs_add || [])].length < 1) {
            isValid = false;
            message = "Harap upload file 'Nota Dinas/Surat Keluar/Notulen Rapat/Email (PDF)'"
        }

        return {
            isValid,
            message
        }
    }

    const [dataForm3, setDataForm3] = useState({});
    const [dataForm4, setDataForm4] = useState({});
    //Api handler
    const [jenisKomoditi, setJenisKomoditi] = useState([]);
    async function getPermohonan1() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/view-form-permohonan1',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setJenisKomoditi(resp.data?.data_master_comos);
                setlab_customer_id(resp?.data?.lab_customer_id);
                setDataStepOne(prev => ({
                    ...prev,
                    judul_pengujian: resp?.data?.data_lab_test_form1?.judul || "",
                    komoditi: resp?.data?.data_lab_test_form1?.comodities || [],
                    file_docs: [...(resp?.data?.data_lab_test_form1?.file_docs || [])].map(d => ({ ...d, is_new: false }))
                }))

            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getPermohonan2() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/view-form-permohonan2',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                generateDataKomoditiSample(resp?.data || [])
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getPermohonan3() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/view-form-permohonan3',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setlab_customer_id(resp?.data?.lab_customer_id);
                setDataForm3(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getPermohonan4() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/view-form-permohonan4',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDataForm4(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const simpanPermohonan1 = async () => {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const comodities = [...(dataStepOne?.komoditi || [])].map(d => d);
            const data = {
                "id": id_lab_test || 0,
                "lab_customer_id": lab_customer_id,
                "judul": dataStepOne?.judul_pengujian,
                "jumlah_komoditi": comodities.length,
                "comodities_add": comodities?.filter(d => d?.is_new === true) || [],
                "comodities_update": comodities?.filter(d => d?.is_edit === true) || [],
                "comodities_delete": [...(dataStepOne?.komoditiDel || [])].filter(d => d?.is_new !== true),
                "file_docs_add": [...(dataStepOne.file_docs || [])].filter(d => d?.is_new === true),
                "file_docs_delete": [...(dataStepOne?.file_docs_delete || [])].filter(d => d),
            }
            const resp = await doPost({
                url: `api/ext-lab/add-form-permohonan1`,
                data: { ...data },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                // const res = await Swal.fire({
                //     title: 'Success',
                //     text: resp.message,
                //     icon: 'success',
                // }).then(() => {
                setId_lab_test(resp?.data);
                tostep2(resp?.data);
                // })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const simpanPermohonan2 = async (data = []) => {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-lab/add-form-permohonan2`,
                data: data,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    tostep3();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const simpanPermohonan4 = async (data = {}) => {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-lab/add-form-permohonan4`,
                data: data,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    history.push({
                        pathname: '/lab/data-product',
                        state: { tabActive: "my-lab" }
                    });
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function deleteFileTempp(fileName) {
        const resp = await doPost({
            url: `/api/base/delete-file-temp`,
            queryParam: { fileName: fileName },
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (resp?.code === '00') {

        } else {
            return Swal.fire({
                title: 'Error!',
                text: resp?.message,
                icon: 'error',
            })
        }
    }


    //step action
    const tostep2 = (id_resp) => history.push(`/laboratory-permohonan-pengajuan?id_lab_test=${id_resp}&step=${1}`);
    const tostep3 = () => history.push(`/laboratory-permohonan-pengajuan?id_lab_test=${id_lab_test}&step=${2}`);
    const tostep4 = () => history.push(`/laboratory-permohonan-pengajuan?id_lab_test=${id_lab_test}&step=${3}`);
    const backTostep1 = () => history.push(`/laboratory-permohonan-pengajuan?id_lab_test=${id_lab_test}&step=${0}`);
    const backTostep2 = () => history.push(`/laboratory-permohonan-pengajuan?id_lab_test=${id_lab_test}&step=${1}`);
    const backTostep3 = () => history.push(`/laboratory-permohonan-pengajuan?id_lab_test=${id_lab_test}&step=${2}`);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        if (activeStep === "0") {
            getPermohonan1();
        }
        if (activeStep === "1") {
            getPermohonan2();
        }
        if (activeStep === "2") {
            getPermohonan3();
        }
        if (activeStep === "3") {
            getPermohonan4();
        }

    }, [activeStep]);

    return <contexts.Provider
        value={{
            ppState: {
                activeStep,
                dataStepOne,
                dataStepTwo,
                formDataStepTwo,
                activeKomoditi,
                jenisKomoditi,
                modal,
                dataForm3,
                dataForm4,
                modalDraftSpk
            },
            ppAction: {
                backTostep1,
                backTostep2,
                backTostep3,
                addKomoditi,
                deleteKomoditi,
                addFile,
                deleteFile,
                judulChange,
                handleNextToStepTwo,
                generateDataKomoditiSample,
                handleChangeKomoditi,
                handleChangeParameterUji,
                simpanEditing,
                handleNextToStepThree,
                kirimPengajuan,
                openModal,
                closeModal,
                onClickSimpanModal,
                tostep4,
                setModalDraftSpk
            }
        }}
    >
        {React.useMemo(() => (
            <ModalDraftSPK />
        ), [modalDraftSpk])}
        <ModalAddEditSample />
        {children}
    </contexts.Provider>
}

export default function usePermohonanPengajuan() {
    return useContext(contexts);
}