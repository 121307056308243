import React from 'react';
import Pdf from "react-to-pdf";
import Swal from 'sweetalert2'
import { jsPDF } from 'jspdf';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

export default function({
    targetRef = {}, //ref of component
    children, // children 1 button
    fileName= "file-pdf", // filename button
    paperSize = "auto", // ukuran kertas
    margin = 40 // margin kertas
}){

    /*
        <<<<<<<<<<<<<Example props>>>>>>>>>>>>>>>>>>>>>>
        options = {
            orientation: 'landscape',
            unit: 'in',
            format: [4,2]
        }

         x={.5} // position x on document
         y={.5} // position y on document
         scale={0.8} // scalling on document
         onComplete function callback / trigger on complete document exported
    */

    const export_ = ()=>{
        // const string = renderToString(<Prints />);
        let element = targetRef.current || null;
        savePDF(element, {
            paperSize: paperSize,
            margin: margin,
            fileName: `${fileName}`,
        });
    }
    return(
        <>
            {/* <Pdf 
                targetRef={targetRef} 
                filename={`${fileName}-${new Date()}.pdf`}
                options={options}
                x={x} 
                y={y} 
                scale={scale}
                onComplete={onComplete}
            >
                {({ toPdf }) => React.Children.map(children, child => {
                        // Checking isValidElement is the safe way and avoids a typescript
                        // error too.
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, { onClick : toPdf });
                        }
                        return child;
                    })
                }
            </Pdf> */}
            {
                React.Children.map(children, child => {
                    // Checking isValidElement is the safe way and avoids a typescript
                    // error too.
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { onClick : export_ });
                    }
                    return child;
                })
            }
        </>
    )
}