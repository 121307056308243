import React, { createContext, useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { useGlobalState } from '../../../../../contexts/useGlobalState';

const roleContext = createContext({
    roleAction: {},
    roleState: {}
})
export function RoleProvider({ children }) {
    const [globalState, dispatch, globalActon] = useGlobalState();
    const [dataRoles, setDataroles] = useState({});
    const [loadingRole, setLoadingRole] = useState(false);
    const [modal, setModal] = useState({
        show: false,
        editMode: false
    });
    const [name, setName] = useState('');
    const [id_edit, setId_edit] = useState('');
    const [Deskripsi, setDeskripsi] = useState('');

    async function getRoles() {
        setLoadingRole(true)

        try {

            const resp = await globalActon.getData("/api/user/table-role", {}, null , null, true)
            if (resp.code === "00") {
                // // console.log(resp.data)
                setDataroles(resp.data)
                globalActon.setForm('usr_page', resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingRole(false)
    }
    async function getRolesById(id) {
        try {

            const resp = await globalActon.getData(`/api/user/view-role?id=${id}`)
            if (resp.code === "00") {
                setName(resp.data?.data_role?.name);
                setDeskripsi(resp.data?.data_role?.Deskripsi);
                setId_edit(resp.data?.data_role?.id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingRole(false)
    }
    async function postAddApi(data) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/user/create-role`,
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                toggleModal();
                getRoles();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postUpdateApi(data) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/user/update-role`,
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                toggleModal();
                getRoles();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deleteRoleApi(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/user/delete-role?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                getRoles();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    function resetEditor() {
        setName('');
        setId_edit('');
        setDeskripsi('');
    }
    function toggleModal(editMode = false, idEdit = '') {
        if (!modal?.show === true) {
            resetEditor();
        }
        if (editMode === true) {
            getRolesById(idEdit)
        }
        setModal(prevState => ({ editMode, show: !prevState.show }))
    }
    function simpanClick() {
        const data = {
            id: id_edit,
            name: name || '',
            Deskripsi: Deskripsi || ''
        }
        if (data?.name === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Name tidak boleh kosong',
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    `<strong>${modal.editMode === true ? 'Update' : 'Add'} Role?</strong>`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    if(modal.editMode === false){
                        postAddApi(data);
                    }
                    if(modal.editMode === true){
                        postUpdateApi(data);
                    }
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function deleteClick(id){
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Role ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteRoleApi(id);
                return true;
            } else {
                return true;
            }
        })
    }

    return <roleContext.Provider value={{
        roleState: {
            dataRoles,
            loadingRole,
            name,
            Deskripsi,
            modal
        },
        roleAction: {
            getRoles,
            toggleModal,
            setName,
            setDeskripsi,
            simpanClick,
            deleteClick
        }
    }}>
        {children}
    </roleContext.Provider>
}
export default function useRoles() {
    return useContext(roleContext)
}