import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import useModalEditorSwa, { SwaEditModalProvider } from './useModalEditorSwa';
import { month_source, tipe_pembayaran } from '../../components/utility/utility';

const ModalEditorPlan = ({ mode = 'Add', id_swakelola = '', data = {} }) => {
    const { swaEditState, swaEditAction } = useModalEditorSwa();
    const [state] = useGlobalState();
    useEffect(() => {
        if (state.modal?.modalEditorPlan === true) {
            swaEditAction.resetEditorPlan();
            swaEditAction.setSwakelola_id(id_swakelola);
            swaEditAction.setEditorPlan(data)
            swaEditAction.handlePlan.setModeEditPlan(mode);
        }
    }, [state.modal?.modalEditorPlan])
    return (
        <div>
            <Card
                className="customShadow"
            >
                <Card.Body>
                    <h5>{`${mode} Plan`}</h5>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text bg-transparent border-0">Realisasi Plan</span>
                                </div>
                                <select className="form-control mr-2" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    value={swaEditState.dataPlan.tahun_plan} onChange={(e) => swaEditAction.handlePlan.setTahun_plan(e.target.value)}
                                >
                                    <option value={''}>- Tahun -</option>
                                    {
                                        [...(swaEditAction.getYear() || [])].map((d, i) => (
                                            <option value={d?.value}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                                <select className="form-control ml-2" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    value={swaEditState.dataPlan.bulan_plan} onChange={(e) => swaEditAction.handlePlan.setBulan_plan(e.target.value)}
                                >
                                    <option value={''}>- Bulan -</option>
                                    {
                                        [...(month_source || [])].map((d, i) => (
                                            <option value={d?.value}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text bg-transparent border-0" id="basic-addon2">Disbursment Plan</span>
                                </div>
                                <input type="number" className="form-control" placeholder="Disbursment Plan" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataPlan.jumlah_plan} onChange={(e) => swaEditAction.handlePlan.setJumlah_plan(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2">IDR (Juta)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span style={{ width: '128px' }} className="input-group-text bg-transparent border-0" id="basic-addon2">Tipe Pembayaran</span>
                                </div>
                                <select className="form-control" placeholder="IDR Juta" aria-label="IDR Juta" aria-describedby="basic-addon2"
                                    value={swaEditState.dataPlan.tipe_plan} onChange={(e) => swaEditAction.handlePlan.setTipe_plan(e.target.value)}
                                >
                                    <option value={''}>- Pilih tipe pembayaran -</option>
                                    {
                                        [...(tipe_pembayaran || [])].map((d, i) => (
                                            <option value={d?.value}>{d?.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="text-md-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => swaEditAction.handlePlan.batalPlan()}
                        >
                            Batal
                        </button>
                        <button className="btn btn-sm btn-primary mx-1"
                            onClick={() => swaEditAction.saveButtonClickPLan()}
                        >
                            <i className="fa fa-save icon-nm mr-1"></i>
                            Simpan
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default (props) => <SwaEditModalProvider> <ModalEditorPlan mode={props?.mode} id_swakelola={props?.id_swakelola} data={props?.data} /></SwaEditModalProvider>;