import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";
import ModalUploadSPK from "./component/modalUploadSPK";
import ModalUploadSPKRev from "./component/modalUploadSPKRev";
import ModalSampleNC from "./component/modalSampleNC";
import ModalViewPengujian from "./component/modalViewPengujian";
import moment from "moment";

const contexts = createContext({
    labState: {},
    labAction: {}
});
export function LabProvider({ children }) {
    const history = useHistory();
    const [tabActive, setTabActive] = useState(history.location.state?.tabActive || "home");
    console.log(history.location.state?.tabActive)
    const [modalView, setModalView] = useState({ show: false, default_data: {} });
    const { doGet, doPost } = useFetch();
    const [modalSPK, setModalSPK] = useState({
        show: false,
        defaultData: {}
    });
    const [modalSDNC, setModalSDNC] = useState({
        show: false,
        defaultData: {}
    });
    const [modalSpkNR, setModalSpkNr] = useState({
        show: false,
        defaultData: {}
    });
    const uploadSpk = (formdata = {}) => {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApi("api/ext-lab/customer-add-pengujian-testing-process-spk-review", formdata);
            }
        });
    }
    const uploadSpkRev = (formdata = {}) => {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApi("api/ext-lab/customer-add-pengujian-testing-report-need-revision-revised", formdata);
            }
        });
    }
    const submitSdnc = (formdata = {}) => {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                sendApi("api/ext-lab/customer-add-pengujian-sample-delivery-not-complete-revised", formdata);
            }
        });
    }
    const sendApi = async (url = "", data = {}) => {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            const resp = await doPost({
                url: url,
                data: { ...data },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    setModalSPK({ show: false, default_data: {} });
                    setModalSDNC({ show: false, default_data: {} });
                    setModalSpkNr({ show: false, default_data: {} });
                    getDataMyLab();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const tabChangeHandle = (name) => {
        setTabActive(name);
    }

    const [dataMyLab, setDataMyLab] = useState({});
    async function getDataMyLab() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/view-mylab',
            });
            if (resp?.code === '00') {
                setDataMyLab(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [dataDetail, setDataDetail] = useState({});
    async function getDetail(id_lab_test) {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/view-mylab-resume',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDataDetail(resp?.data);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const [doKontrak, setDoKontrak] = useState({});
    const [loadDok, setLoadDok] = useState(false);
    async function getDokKontrak(id_lab_test = "") {
        setLoadDok(true);
        try {
            const resp = await doGet({
                url: 'api/ext-lab/customer-view-pengujian-testing-report-need-revision-revised',
                param: { id_lab_test }
            });
            if (resp?.code === '00') {
                setDoKontrak({
                    file_name: [...(resp?.data?.file_name || [])][0]?.file_name,
                    file_name2: [...(resp?.data?.file_name2 || [])][0]?.file_name
                });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        setLoadDok(false);
        Swal.close();
    }

    const [tahun, setTahun] = useState(moment().year());
    const [fChart1, setFChart1] = useState(1);
    const [chart1, setChart1] = useState({});
    async function getChart1(param = 1) {
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart1?parameter=${param}`,
            });
            if (resp?.code === '00') {
                setFChart1(param)
                setChart1(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [fChart2, setFChart2] = useState(1);
    const [chart2, setChart2] = useState({});
    async function getChart2(param = 1) {
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart2?parameter=${param}`,
            });
            if (resp?.code === '00') {
                setFChart2(param)
                setChart2(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [fChart3, setFChart3] = useState(1);
    const [chart3, setChart3] = useState({});
    async function getChart3(param = 1) {
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart3?parameter=${param}`,
            });
            if (resp?.code === '00') {
                setFChart3(param)
                setChart3(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [chart4, setChart4] = useState([]);
    async function getChart4() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart4`,
            });
            if (resp?.code === '00') {
                setChart4(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [chart5, setChart5] = useState([]);
    async function getChart5() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart5`,
            });
            if (resp?.code === '00') {
                setChart5(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [searchParam, setSearchParam] = useState("");
    const [expand, setExpand] = useState(false);
    const [productLab, setProductLab] = useState([]);
    async function getProductLab() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: `api/ext-lab/view-product-lab`,
            });
            if (resp?.code === '00') {
                setProductLab(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    useEffect(() => {
        if(tabActive === 'home'){
            getProductLab();
            setSearchParam("");
            setExpand(false);
        }
        if (tabActive === "my-lab") {
            getDataMyLab();
        }
        if (tabActive === 'dashboard') {
            getChart1()
            getChart2()
            getChart3()
            getChart4();
            getChart5();
        }
    }, [tabActive])
    useEffect(() => {
        setTabActive(history.location.state?.tabActive);
    }, [history.location.state?.tabActive]);
    return <contexts.Provider
        value={{
            labState: {
                tabActive,
                dataMyLab,
                modalSPK,
                modalSDNC,
                modalSpkNR,
                modalView,
                dataDetail,
                doKontrak,
                loadDok,
                //Daashboard
                tahun,
                fChart1,
                chart1,
                fChart2,
                chart2,
                fChart3,
                chart3,
                chart4,
                chart5,
                productLab,
                expand,
                searchParam
            },
            labAction: {
                tabChangeHandle,
                setModalSPK,
                setModalSDNC,
                setModalSpkNr,
                uploadSpk,
                submitSdnc,
                uploadSpkRev,
                setModalView,
                getDetail,
                getDokKontrak,
                //dashboard,
                setTahun,
                getChart1,
                getChart2,
                getChart3,
                setExpand,
                setSearchParam
            }
        }}
    >
        {React.useMemo(() => (
            <ModalViewPengujian />
        ), [modalView])}
        {React.useMemo(() => (
            <ModalSampleNC />
        ), [modalSDNC])}
        {React.useMemo(() => (
            <ModalUploadSPK />
        ), [modalSPK])}
        {React.useMemo(() => (
            <ModalUploadSPKRev />
        ), [modalSPK])}
        {children}
    </contexts.Provider>
}

export default function useLab() {
    return useContext(contexts);
}