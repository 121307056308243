import React, { createContext, useContext, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2';
import { fileExtensions_string } from '../../components/utility/utility';
import moment from 'moment';
import FormAddEdit from './add-edit-equipment/component/formAddEdit';
import { useHistory } from 'react-router-dom';
import { toInteger } from 'lodash';

const crudEquipmentContext = createContext({
    ceqState: {},
    ceqAction: {}
})
export function CrudEquipmentProvider({ children }) {
    const history = useHistory();
    const [globalState, dispatch, globalAction] = useGlobalState();
    const [modeEditEq, setModeEditEq] = useState('Add');
    const [id_edit, setId_edit] = useState('');
    const [data_edit, setData_edit] = useState({});
    const [nomor, setNomor] = useState('');
    const [nomorSeri, setNomorSeri] = useState('');
    const [nama, setNama] = useState('');
    const [merk, setMerk] = useState('');
    const [pc, setPC] = useState(null);
    const [iso, setIso] = useState(null);
    const [utilitas, setUtilitas] = useState('');
    const [supporting, setSupporting] = useState('');
    const [daya_listrik, setDaya_listrik] = useState('');
    const [tahun_pembelian, setTahun_pembelian] = useState('');
    const [harga_alat, setHarga_alat] = useState('0');
    const [deskripsi, setDeskripsi] = useState('');
    const [information, setInformation] = useState('');
    const [list_dokumen, setList_dokumen] = useState([]);
    const [list_dok_delete, setList_dok_delete] = useState([]);
    const [file_foto, setFile_foto] = useState('');
    const [equipment_type, setEquipment_type] = useState('');
    const [equipment_loc, setEquipment_loc] = useState('');
    const [equipment_lab, setEquipment_lab] = useState('');
    const [list_pic, setList_pic] = useState([]);
    const [penanggung_jawab, setPenanggung_jawab] = useState('');

    const [editingH, setEditingH] = useState(false);
    const [editingL, setEditingL] = useState(false);

    const [modeEditH, setModeEditH] = useState('Add');
    const [dataEditH, setDataEditH] = useState({});
    const [modeEditL, setModeEditL] = useState('Add');
    const [dataEditL, setDataEditL] = useState({});

    const [refreshDetail, setRefreshDetail] = useState(false);

    const [data_status, setData_status] = useState({});
    const [status_id, setStatus_id] = useState('');
    const [status_eq_id, setStatus_eq_id] = useState('');
    const [tanggal_mulai, setTanggal_mulai] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [tanggal_selesai, setTanggal_selesai] = useState('');
    const [biaya, setBiaya] = useState('');
    const [masa_status, setMasa_status] = useState('');
    const [deskripsi_status, setDeskripsi_status] = useState('');
    const [equipment_status, setEquipment_status] = useState('');

    const [kronologi_kejadian, setKronologi_kejadian] = useState('');
    const [analisa_kerusakan, setAnalisa_kerusakan] = useState('');
    const [tindakan_perbaikan, setTindakan_perbaikan] = useState('');
    const [status_maintenance, setStatus_maintenance] = useState(0);
    const [tanggal_selesai_maintenance, setTanggal_selesai_maintenance] = useState('');
    const [nama_teknisi, setNama_teknisi] = useState('');

    const [logbook_id, setLogbook_id] = useState('');
    const [logbook_eq_id, setLogbook_eq_id] = useState('');
    const [tanggal_mulai_l, setTanggal_mulai_l] = useState('');
    const [tanggal_selesai_l, setTanggal_selesai_l] = useState('');
    const [pemakai, setPemakai] = useState([]);
    const [pemakaiTemp, setPemakaiTemp] = useState('');
    const [deskripsi_logbook, setDeskripsi_logbook] = useState('');
    const [source_pemakai, setSource_pemakai] = useState([]);

    async function getEdit(id) {
        try {
            const resp = await globalAction.getData(`/api/ext-equipment/Edit/${id}`, {}, null, null, true)
            if (resp.code === "00") {
                setData_edit(resp.data)
                setEditorEquipment(resp.data?.data_equipment);
            }
            return resp;
        } catch (err) {
            Swal.fire("Error", err.toString(), "error")
        }
    }
    async function getSourcePemakai() {
        try {
            const resp = await globalAction.getData(`/api/param-user-active-contract`, {}, null, null, true)
            if (resp.code === "00") {
                setSource_pemakai(resp.data)
                // setEditorEquipment(resp.data?.data_equipment);
            }
        } catch (err) {
            Swal.fire("Error", err.toString(), "error")
        }
    }
    async function fileOnchangeFile(data) {
        const file = data.value?.[0]
        if (file && file.size <= 20000000) {
            try {
                const resp = await globalAction.postData(
                    {
                        url: "/api/base/upload-file-temp",
                        data: {
                            "File": file,
                        },
                        withFile: true,
                        withLoading: false,
                        withAlert: false,
                    }
                );
                if (resp.code === "00") {
                    setList_dokumen([
                        {
                            "id": '',
                            "equipment_id": id_edit,
                            "file_doc_name": resp.data?.data_filename,
                            "file_doc_location": ""
                        },
                        ...list_dokumen
                    ]);
                }
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }
        } else if (file && file.size > 20000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    async function fileOnchangePhoto(data) {
        const file = data.value?.[0]
        if (file && file.size <= 10000000) {
            var ext = file?.name?.split('.').pop();
            var result = fileExtensions_string.filter(d => d === ext)
            if (result.length > 0) {
                try {
                    const resp = await globalAction.postData(
                        {
                            url: "/api/base/upload-file-temp",
                            data: {
                                "File": file,
                            },
                            withFile: true,
                            withLoading: false,
                            withAlert: false,
                        }
                    );
                    if (resp.code === "00") {
                        setFile_foto(resp.data?.data_filename);
                    }
                } catch (error) {
                    Swal.fire("Error", error.toString(), "error")
                }
            }
        } else if (file && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    async function postAddEquipment(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-equipment/store-equipment",
                    data: data,
                    withFile: false,
                    withAlert: false
                }
            );
            if (resp.code === "00") {
                // history.push({
                //     pathname: '/admin/equipment-crud/dashboard',
                // })
                return Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                    showConfirmButton: false
                })
            } else {
                return Swal.fire({
                    title: 'Error',
                    text: resp.message,
                    icon: 'error',
                    showConfirmButton: false
                })
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postUpdateEquipment(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-equipment/update-equipment",
                    data: data,
                    withFile: false,
                    withAlert: false
                }
            );
            if (resp.code === "00") {
                // history.push({
                //     pathname: '/admin/equipment-crud/dashboard',
                // })
                return Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => history.goBack())
            } else {
                return Swal.fire({
                    title: 'Error',
                    text: resp.message,
                    icon: 'error',
                    showConfirmButton: false
                })
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postAddStatus(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-equipment/store-history-status",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalEqH');
                setRefreshDetail(true);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postUpdateStatus(data, pelapor = false) {
        var url = "/api/ext-equipment/update-history-status";
        if (pelapor === false)
            url = "/api/ext-equipment/update-history-status-teknisi";
        try {
            const resp = await globalAction.postData(
                {
                    url: url,
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalEqH');
                window.location.reload();
                setRefreshDetail(true);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postAddLogbook(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-equipment/store-logbook",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalEqL');
                setRefreshDetail(true);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function postUpdateLogbook(data) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-equipment/update-logbook",
                    data: data,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                globalAction.toggleModal('modalEqL');
                setRefreshDetail(true);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getEditStatus(id) {
        try {
            const resp = await globalAction.getData(`/api/ext-equipment/Edit-history/${id}`, {}, null, null, false)
            if (resp.code === "00") {
                setData_status(resp.data);
                setEditorStatus(resp.data?.data_equipment);
            }
        } catch (err) {
            Swal.fire("Error", err.toString(), "error")
        }
    }
    async function deleteStatusApi(id) {
        try {
            const resp = await globalAction.postData(
                {
                    url: `/api/ext-equipment/delete-history-status?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                setRefreshDetail(true);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deleteLogbookApi(id) {
        try {
            const resp = await globalAction.postData(
                {
                    url: `/api/ext-equipment/delete-logbook?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                setRefreshDetail(true);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deleteEquipmentApi(id) {
        try {
            const resp = await globalAction.postData(
                {
                    url: `/api/ext-equipment/delete-equipment?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    function onUploadedFile(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setList_dokumen([
                {
                    "id": '',
                    "equipment_id": id_edit,
                    "file_doc_name": resp.data?.data_filename,
                    "file_doc_location": ""
                },
                ...list_dokumen
            ]);
        }
    }
    function onUploadedCover(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setFile_foto(resp.data?.data_filename);
        }
    }
    function addEquipmentClick() {
        setModeEditEq('Add');
        setId_edit('0');
        globalAction.toggleModal('modalEquipment');
    }
    function editEquipmentClick(id) {
        setModeEditEq('Edit');
        setId_edit(id);
        globalAction.toggleModal('modalEquipment');
    }
    function resetEditorEquipment() {
        setId_edit('');
        setData_edit({});
        setNomor('');
        setNama('');
        setMerk('');
        setTahun_pembelian('');
        setHarga_alat('');
        setDeskripsi('');
        setInformation('');
        setList_dokumen([]);
        setList_dok_delete([]);
        setFile_foto('');
        setEquipment_type('');
        setEquipment_loc('');
        setEquipment_lab('');
        setPenanggung_jawab('');
        setList_pic([]);
        setNomorSeri('');
        setUtilitas('');
        setSupporting('');
        setDaya_listrik('');
        setPC(null);
        setIso(null);
    }
    function setEditorEquipment(data) {
        setId_edit(data?.id);
        setNomor(data?.nomor);
        setNama(data?.nama);
        setMerk(data?.merk);
        setTahun_pembelian(data?.tahun_pembelian);
        setHarga_alat(data?.harga_alat);
        setDeskripsi(data?.deskripsi);
        setInformation(data?.information);
        setList_dokumen((data?.list_dokumen || [])?.map(d => ({
            ...d,
            is_new: false
        })));
        setFile_foto(data?.file_foto);
        setEquipment_type(data?.equipment_type);
        setEquipment_loc(data?.equipment_loc);
        setEquipment_lab(data?.equipment_lab);
        setPenanggung_jawab(data?.penanggung_jawab);
        // setPenanggung_jawab(modeEditEq === 'Add' ? (validationRole() === false ? globalState?.dataUser?.id : data.penanggung_jawab) : data.penanggung_jawab);
        setList_pic(createArrayPIC(data?.nama_PIC_array_id));
        setNomorSeri(data?.nomor_seri || '');
        setUtilitas(data?.utilitas || '');
        setSupporting(data?.supporting || '');
        setPC(data?.pc === true ? '1' : data?.pc === false ? '0' : data?.pc);
        setIso(data?.iso_17025 === true ? '1' : data?.iso_17025 === false ? '0' : data?.iso_17025);
        setDaya_listrik(data?.daya_listrik || '');

    }
    function validationRole() {
        const data_role = globalState.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Admin Equipment" ||
                d === "Administrator") {
                result = true;
            }
        })
        return result;
    }
    function reseTeditorStatus() {
        setData_status({});
        setTanggal_mulai(moment(new Date()).format('YYYY-MM-DD'));
        setTanggal_selesai('');
        setMasa_status('');
        setDeskripsi_status('');
        setEquipment_status('');
        setStatus_id('');
        setBiaya('');
        setKronologi_kejadian('');
        setAnalisa_kerusakan('');
        setTindakan_perbaikan('');
        setStatus_maintenance(0);
        setTanggal_selesai_maintenance('');
        setNama_teknisi('');
    }
    function setEditorStatus(data) {
        setTanggal_mulai(data?.tanggal_mulai ? moment(data?.tanggal_mulai).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'));
        setMasa_status(data?.masa_status || '');
        setDeskripsi_status(data?.deskripsi || '');
        setEquipment_status(data?.equipment_status?.toString());
        setStatus_id(modeEditH === 'Add' ? '0' : data?.id)
        setBiaya(data?.biaya);
        setKronologi_kejadian(data?.kronologi_kejadian || '');
        setAnalisa_kerusakan(data?.analisa_kerusakan || '');
        setTindakan_perbaikan(data?.tindakan_perbaikan || '');
        setStatus_maintenance(data?.status_maintenance || 0);
        setTanggal_selesai_maintenance(data?.tanggal_selesai_maintenance || '');
        setNama_teknisi(data?.nama_teknisi || '')
    }
    function resetEditorLogbook() {
        setLogbook_id('');
        setTanggal_mulai_l('');
        setTanggal_selesai_l('');
        setPemakai([]);
        setPemakaiTemp('');
        setDeskripsi_logbook('');
    }
    function setEditorLogbook(data, id) {
        // getEdit(id || '0');
        getSourcePemakai();
        setLogbook_id(data?.id || '');
        setTanggal_mulai_l(data?.tanggal_mulai ? moment(data?.tanggal_mulai).format('YYYY-MM-DD') : '');
        setTanggal_selesai_l(data?.tanggal_selesai ? moment(data?.tanggal_selesai).format('YYYY-MM-DD') : '');
        setPemakai(data?.pemakai_array_id || []);
        setDeskripsi_logbook(data?.deskripsi || '');
    }
    function onRowExpandedMaster(key) {
        if (key?.id === 124) {
            setEditingH(false);
            setEditingL(false);
        } else {
            setEditingH(true);
            setEditingL(true);
        }
    }

    function deleteList_document(dok) {
        const newVr = list_dokumen || [];
        setList_dokumen(newVr.filter(d => d?.file_doc_name !== dok?.file_doc_name));
        if (dok?.is_new === false) {
            setList_dok_delete((prev) => [...prev, dok]);
        }
    }
    function simpanClick() {
        const data = {
            "id": id_edit,
            "nomor": nomor || '',
            "nama": nama || '',
            "deskripsi": deskripsi || '',
            "information": information || '',
            "merk": merk || '',
            "tahun_pembelian": tahun_pembelian || '',
            "harga_alat": harga_alat || '',
            "file_foto": file_foto || '',
            "path": "",
            "nama_tipe": "",
            "nama_lokasi": "",
            "nama_lab": "",
            "nama_PIC": "",
            "status": "",
            "nama_status": "",
            "show_end": "",
            "equipment_type": equipment_type || '',
            "biaya_total": '',
            "equipment_loc": equipment_loc || '',
            "equipment_lab": equipment_lab || '',
            "usia": '',
            "penanggung_jawab": penanggung_jawab || '',
            "list_pic": list_pic || [],
            "created_at": "",
            "updated_at": "",
            "end_datetime": "",
            "list_dokumen": list_dokumen,
            "utilitas": utilitas || '',
            "supporting": supporting || '',
            "daya_listrik": daya_listrik || '',
            "pc": pc,
            "iso_17025": iso,
            "nomor_seri": nomorSeri || ''

        }
        const validate = validationEquipment(data);
        if (validate.isValid) {
            if (modeEditEq === 'Add') {
                addEquipment(data);
            }
            if (modeEditEq === 'Edit') {
                updateEquipment(data);
            }
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function createArrayPIC(data) {
        if (data) {
            return Array.isArray(data) ? (data) : [data]
        } else {
            return []
        }
    }
    function simpanClickEquipment(mode = "Add") {
        var data = {
            "id": id_edit,
            "nomor": nomor || '',
            "nama": nama || '',
            "deskripsi": deskripsi || '',
            "information": information || '',
            "merk": merk || '',
            "tahun_pembelian": tahun_pembelian || '',
            "harga_alat": harga_alat || '',
            "file_foto": file_foto || '',
            "path": "",
            "nama_tipe": "",
            "nama_lokasi": "",
            "nama_lab": "",
            "nama_PIC": "",
            "status": "",
            "nama_status": "",
            "show_end": "",
            "equipment_type": equipment_type || '',
            "biaya_total": '',
            "equipment_loc": equipment_loc || '',
            "equipment_lab": equipment_lab || '',
            "penanggung_jawab": penanggung_jawab || '',
            "usia": '',
            // "list_pic": list_pic || [],
            "list_pic": (list_pic || []).map(d => {
                const bantu = (createArrayPIC(data_edit?.data_equipment?.nama_PIC_array_id) || []).find(x => x === d);
                var newOjb = {};
                if (bantu) {
                    newOjb = { is_new: false, pic: d }
                } else {
                    newOjb = { is_new: true, pic: d }
                }
                return newOjb;
            }),
            "list_pic_delete": (createArrayPIC(data_edit?.data_equipment?.nama_PIC_array_id) || []).filter(d => {
                return !(list_pic || []).some(x => x === d)
            }).map(d => ({ is_new: false, pic: d })),
            "created_at": "",
            "updated_at": "",
            "end_datetime": "",
            "list_dok": list_dokumen || [],
            "list_dok_delete": list_dok_delete || [],
            "utilitas": utilitas || '',
            "supporting": supporting || '',
            "daya_listrik": daya_listrik || '',
            "pc": pc,
            "iso_17025": iso,
            "nomor_seri": nomorSeri || ''

        }
        const validate = validationEquipmentNew(data);
        if (validate.isValid) {
            if (mode === 'Add') {
                addEquipment({
                    ...data,
                    pc: toInteger(pc),
                    iso_17025: toInteger(iso)
                });
            }
            if (mode === 'Edit') {
                updateEquipment({
                    ...data,
                    pc: toInteger(pc),
                    iso_17025: toInteger(iso)
                });
            }
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function simpanStatusClick(pelapor = false) {
        const data = {
            "id": toInteger(status_id),
            "equipment_id": toInteger(status_eq_id),
            "equipment_status": toInteger(equipment_status),
            "tanggal_mulai": tanggal_mulai,
            "tanggal_selesai": "",
            "masa_status": toInteger(masa_status),
            "biaya": toInteger(biaya) || 0,
            "deskripsi": deskripsi_status || '',
            kronologi_kejadian,
            analisa_kerusakan,
            tindakan_perbaikan,
            status_maintenance: toInteger(status_maintenance || 0),
            tanggal_selesai_maintenance,
            nama_teknisi,
            "created_at": ""
        }
        console.log({ pelapor, ...data })
        const validate = validationStatus({ pelapor, ...data });
        if (validate.isValid === true) {
            if (modeEditH === 'Add') {
                addStatus({
                    equipment_id: data.equipment_id,
                    equipment_status: data.equipment_status,
                    tanggal_mulai: data.tanggal_mulai,
                    masa_status: data.masa_status,
                    deskripsi: data.deskripsi,
                    kronologi_kejadian: data.kronologi_kejadian
                });
            }
            if (modeEditH === 'Edit') {
                updateStatus(pelapor ? {
                    id: data.id,
                    tanggal_mulai: data.tanggal_mulai,
                    masa_status: data.masa_status,
                    deskripsi: data.deskripsi,
                    kronologi_kejadian: data.kronologi_kejadian
                } : {
                    id: data.id,
                    biaya: data.biaya,
                    analisa_kerusakan: data.analisa_kerusakan,
                    tindakan_perbaikan: data.tindakan_perbaikan,
                    status_maintenance: data.status_maintenance,
                    tanggal_selesai_maintenance: data.tanggal_selesai_maintenance
                }, pelapor);
            }
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function simpanLogbookClick() {
        const data = {
            "id": logbook_id,
            "equipment_id": logbook_eq_id,
            "pemakai": pemakai.map(d => d?.toString()),
            "show_start": "",
            "show_end": "",
            "pemakai_name": "",
            "tanggal_mulai": tanggal_mulai_l ? moment(tanggal_mulai_l).format('YYYY-MM-DD') : '',
            "tanggal_selesai": tanggal_selesai_l ? moment(tanggal_selesai_l).format('YYYY-MM-DD') : '',
            "deskripsi": deskripsi_logbook || '',
            "created_at": ""
        }
        const validate = validationLogbook(data);
        if (validate.isValid === true) {
            if (modeEditL === 'Add') {
                tambahLogbook(data);
            }
            if (modeEditL === 'Edit') {
                updateLogbook(data);
            }
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function addEquipment(data) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Equipment?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postAddEquipment(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function updateEquipment(data) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Update Equipment?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postUpdateEquipment(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function addStatus(data) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Status?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postAddStatus(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function updateStatus(data, pelapor = false) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Update Status?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postUpdateStatus(data, pelapor)
                return true;
            } else {
                return true;
            }
        })
    }
    function tambahLogbook(data) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Add Logbook?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postAddLogbook(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function updateLogbook(data) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Update Logbook?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postUpdateLogbook(data);
                return true;
            } else {
                return true;
            }
        })
    }
    function editBtnH(data, id) {
        return (
            <>
                {
                    ((data?.data?.equipment_status_str === 'Maintenance' || data?.data?.equipment_status_str === 'Broken')
                        // &&
                        // data?.rowIndex === 0
                    ) ? (
                        <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1"
                            onClick={(e) => editHistory(data?.data, id)}
                        >
                            <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
                        </button>
                    ) : (
                        <>
                        </>
                    )
                }
            </>
        )
    }
    function deleteBtnH(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deleteStatus(data?.data?.id)}
            >
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function editBtnL(data, id) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1"
                onClick={(e) => editLogbook(data?.data, id)}
            >
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtnL(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deleteLogbook(data?.data?.id)}
            >
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function headerCellRender(e) {
        return <p style={{ fontWeight: 'bolder', fontSize: '9pt', fontFamily: 'Poppins' }} className="text-wrap">{e.column.caption}</p>;
    }
    function addHistory(id) {
        setStatus_eq_id(id);
        setModeEditH('Add');
        setDataEditH({});
        globalAction.toggleModal('modalEqH');
    }
    function addLogbook(id) {
        setModeEditL('Add');
        setDataEditL({});
        setLogbook_eq_id(id);
        globalAction.toggleModal('modalEqL');
    }
    function editHistory(data = {}, id) {
        setModeEditH('Edit');
        setDataEditH(data);
        setStatus_eq_id(id);
        globalAction.toggleModal('modalEqH');
    }
    function editLogbook(data = {}, id) {
        setModeEditL('Edit');
        setDataEditL(data);
        setLogbook_eq_id(id);
        globalAction.toggleModal('modalEqL');
    }
    function deleteStatus(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete History Status ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteStatusApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteLogbook(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Logbook ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteLogbookApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteEquipment(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Equipment ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteEquipmentApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function addPemakai(val) {
        if (val) {
            setPemakaiTemp('');
            setPemakai([JSON.parse(val), ...pemakai])
        }
    }
    function deletePemakai(val) {
        setPemakai(pemakai.filter(d => d?.id !== val?.id));
    }

    function validationEquipment(data) {
        var isValid = true;
        var message = [];
        var messageYear = ""
        if (data?.nomor === '') {
            isValid = false;
            message = [...message, 'Nomor'];
        }
        if (data?.nomor_seri === '') {
            isValid = false;
            message = [...message, 'Nomor Seri'];
        }
        if (data?.nama === '') {
            isValid = false;
            message = [...message, 'Nama'];
        }
        if (data?.equipment_type === '') {
            isValid = false;
            message = [...message, 'Type'];
        }
        if (data?.merk === '') {
            isValid = false;
            message = [...message, 'Merk'];
        }
        if (data?.tahun_pembelian === '') {
            isValid = false;
            message = [...message, 'Tahun pembelian'];
        }
        if (data?.harga_alat === '') {
            isValid = false;
            message = [...message, 'Harga Alat'];
        }
        if (data?.pc === '' || data?.pc === null || data?.pc === undefined) {
            isValid = false;
            message = [...message, 'PC'];
        }
        if (data?.iso_17025 === '' || data?.iso_17025 === null || data?.iso_17025 === undefined) {
            isValid = false;
            message = [...message, 'Lingkup Akreditasi ISO 17025'];
        }
        if (data?.penanggung_jawab === '') {
            isValid = false;
            message = [...message, 'PIC'];
        }
        if (data?.equipment_loc === '') {
            isValid = false;
            message = [...message, 'Lokasi'];
        }
        if (data?.equipment_lab === '') {
            isValid = false;
            message = [...message, 'Lab'];
        }
        if (data?.deskripsi === '') {
            isValid = false;
            message = [...message, 'Deskripsi'];
        }
        if (data?.information === '') {
            isValid = false;
            message = [...message, 'Informasi Tambahan'];
        }
        if (data?.file_foto === '') {
            isValid = false;
            message = [...message, 'Foto alat'];
        }
        if (isValid === true && (parseInt(data?.tahun_pembelian, 0) < 1990 || parseInt(data?.tahun_pembelian, 0) > 2050)) {
            isValid = false;
            messageYear = "Tahun release minimal 1990 dan maksimal 2050";
        }
        return {
            isValid,
            'message': (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageYear ? ' dan ' : '') + messageYear
        }
    }
    function validationEquipmentNew(data) {
        var isValid = true;
        var message = [];
        var messageYear = ""
        if (data?.nomor === '') {
            isValid = false;
            message = [...message, 'Nomor'];
        }
        if (data?.nomor_seri === '') {
            isValid = false;
            message = [...message, 'Nomor Seri'];
        }
        if (data?.nama === '') {
            isValid = false;
            message = [...message, 'Nama'];
        }
        if (data?.equipment_type === '') {
            isValid = false;
            message = [...message, 'Type'];
        }
        if (data?.merk === '') {
            isValid = false;
            message = [...message, 'Merk'];
        }
        if (data?.tahun_pembelian === '') {
            isValid = false;
            message = [...message, 'Tahun pembelian'];
        }
        if (data?.harga_alat === '') {
            isValid = false;
            message = [...message, 'Harga Alat'];
        }
        if (data?.pc === '' || data?.pc === null || data?.pc === undefined) {
            isValid = false;
            message = [...message, 'PC'];
        }
        if (data?.iso_17025 === '' || data?.iso_17025 === null || data?.iso_17025 === undefined) {
            isValid = false;
            message = [...message, 'Lingkup Akreditasi ISO 17025'];
        }
        if (data?.penanggung_jawab === '' || data?.penanggung_jawab === null || data?.penanggung_jawab === undefined) {
            isValid = false;
            message = [...message, 'PIC'];
        }
        if (data?.list_pic?.length === 0) {
            isValid = false;
            message = [...message, 'Operator'];
        }
        if (data?.equipment_loc === '') {
            isValid = false;
            message = [...message, 'Lokasi'];
        }
        if (data?.equipment_lab === '') {
            isValid = false;
            message = [...message, 'Lab'];
        }
        if (data?.deskripsi === '') {
            isValid = false;
            message = [...message, 'Deskripsi'];
        }
        if (data?.information === '') {
            isValid = false;
            message = [...message, 'Informasi Tambahan'];
        }
        if (data?.file_foto === '') {
            isValid = false;
            message = [...message, 'Foto alat'];
        }
        if (isValid === true && (parseInt(data?.tahun_pembelian, 0) < 1990 || parseInt(data?.tahun_pembelian, 0) > 2050)) {
            isValid = false;
            messageYear = "Tahun release minimal 1990 dan maksimal 2050";
        }
        return {
            isValid,
            'message': (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageYear ? ' dan ' : '') + messageYear
        }
    }
    function validationStatus(data) {
        var isValid = true;
        var message = '';
        if (data?.equipment_status !== 5) {
            if (data?.equipment_status === 0) {
                isValid = false;
                message = "Status tidak boleh kosong";
            } else {
                if ((modeEditH === "Add") || (modeEditH === "Edit" && data?.pelapor === true)) {
                    if (data?.masa_status === 0) {
                        isValid = false;
                        message = "Masa status tidak boleh kosong atau 0";
                    }
                }
                if (modeEditH === "Edit" && data?.pelapor === false) {
                    if (data?.equipment_status === 2 && data?.status_maintenance === 0) {
                        isValid = false;
                        message = message + " Status Perbaikan tidak boleh kosong";
                    }
                    if (data?.equipment_status === 2 && data?.status_maintenance === 3) {
                        if (data?.analisa_kerusakan === '' || data?.tindakan_perbaikan === '' || data?.tanggal_selesai_maintenance === '' || data?.nama_teknisi === '') {
                            isValid = false;
                            message = message + "Status Selesai harus mengisi semua isian form (Keterangan (Perkiraan Kerusakan), Kronologi Kejadian, Analisa Kerusakan, Tindakan Perbaikan, Nama Teknisi dan Tanggal Selesai Perbaikan)";
                        }
                    }
                }
            }
        } else {
            if (data?.tanggal_mulai === '' || data?.tanggal_mulai === 'Invalid Date') {
                isValid = false;
                message = message + " Tanggal tidak boleh kosong";
            }
        }
        return {
            isValid,
            message
        }

    }
    function validationLogbook(data) {
        var isValid = true;
        var message = '';
        if (data?.tanggal_mulai === '' || data?.tanggal_selesai === '') {
            isValid = false;
            message = "Tanggal pemaikaian harus di isi";
        } else {
            if (moment(data?.tanggal_selesai).isBefore(data?.tanggal_mulai)) {
                isValid = false;
                message = "Tanggal pemakaian salah";
            }
            if ([...(data?.pemakai || '')].length === 0) {
                isValid = false;
                message = "Pemakai tidak boleh kosong";
            }
        }
        return {
            isValid,
            message
        }
    }

    const Approval1 = (id) => {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Laporan Kerusakan?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postApprove1(id);
                return true;
            } else {
                return true;
            }
        })
    }
    async function postApprove1(id) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-equipment/update-history-approval1",
                    data: { id },
                    withFile: false
                }
            );
            if (resp.code === "00") {
                setRefreshDetail(true);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    const Approval2 = (id) => {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Tindakan Perbaikan?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postApprove2(id);
                return true;
            } else {
                return true;
            }
        })
    }
    async function postApprove2(id) {
        try {
            const resp = await globalAction.postData(
                {
                    url: "/api/ext-equipment/update-history-approval2",
                    data: { id },
                    withFile: false
                }
            );
            if (resp.code === "00") {
                setRefreshDetail(true);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    return <crudEquipmentContext.Provider value={{
        ceqState: {
            globalState,
            modeEditEq,
            id_edit,
            data_edit,
            nomor,
            nomorSeri,
            nama,
            merk,
            pc,
            iso,
            utilitas,
            supporting,
            daya_listrik,
            tahun_pembelian,
            harga_alat,
            deskripsi,
            information,
            list_dokumen,
            file_foto,
            equipment_type,
            equipment_loc,
            equipment_lab,
            penanggung_jawab,
            editingL,
            editingH,
            modeEditH,
            modeEditL,
            dataEditH,
            dataEditL,
            data_status,
            tanggal_mulai,
            masa_status,
            deskripsi_status,
            equipment_status,
            biaya,
            refreshDetail,
            tanggal_mulai_l,
            tanggal_selesai_l,
            pemakai,
            pemakaiTemp,
            deskripsi_logbook,
            list_pic,
            source_pemakai,
            kronologi_kejadian,
            analisa_kerusakan,
            tindakan_perbaikan,
            status_maintenance,
            tanggal_selesai_maintenance,
            nama_teknisi
        },
        ceqAction: {
            globalAction,
            getEdit,
            addEquipmentClick,
            editEquipmentClick,
            deleteEquipment,
            resetEditorEquipment,
            setNomor,
            setNomorSeri,
            setNama,
            setMerk,
            setPC,
            setIso,
            setUtilitas,
            setSupporting,
            setDaya_listrik,
            setTahun_pembelian,
            setHarga_alat,
            setDeskripsi,
            setInformation,
            setEquipment_type,
            setEquipment_loc,
            setEquipment_lab,
            setPenanggung_jawab,
            setFile_foto,
            fileOnchangeFile,
            fileOnchangePhoto,
            deleteList_document,
            simpanClick,
            headerCellRender,
            editBtnL,
            editBtnH,
            deleteBtnL,
            deleteBtnH,
            setEditingH,
            setEditingL,
            addHistory,
            addLogbook,
            editHistory,
            editLogbook,
            getEditStatus,
            reseTeditorStatus,
            setMasa_status,
            setEquipment_status,
            setBiaya,
            setDeskripsi_status,
            simpanStatusClick,
            setRefreshDetail,
            resetEditorLogbook,
            setEditorLogbook,
            setTanggal_mulai_l,
            setTanggal_selesai_l,
            addPemakai,
            deletePemakai,
            setPemakaiTemp,
            setPemakai,
            setDeskripsi_logbook,
            simpanLogbookClick,
            onRowExpandedMaster,
            onUploadedFile,
            onUploadedCover,
            simpanClickEquipment,
            setList_pic,
            setTanggal_mulai,
            setKronologi_kejadian,
            setAnalisa_kerusakan,
            setTindakan_perbaikan,
            setStatus_maintenance,
            setTanggal_selesai_maintenance,
            setNama_teknisi,
            Approval1,
            Approval2
        }
    }}>
        {children}
    </crudEquipmentContext.Provider>
}

export default function useCrudEquipment() {
    return useContext(crudEquipmentContext);
};