import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import useLabMaster from '../useLabMaster';

function ModalJenisKomoditi(props) {
    const {
        lmState: {
            modalFormJK: {
                show = false,
                is_editing = false,
                default_data = {}
            }
        },
        lmAction: {
            closeModalFormJK = () => null,
            simpanJK = () => null }
    } = useLabMaster();
    const [nama, setNama] = useState("");

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        simpanJK({
            ...default_data,
            nama
        });
    }, [simpanJK, nama]);

    useEffect(() => {
        if (show) {
            setNama(default_data?.nama || "");
        } else {
            setNama("");
        }
    }, [show]);
    return (
        <Modal show={show} size='md' onHide={closeModalFormJK}>
            <form
                onSubmit={onSubmit}
            >
                <div className="card">
                    <div className="card-header bg-white">
                        {is_editing ? "Ubah" : "Tambah"} Jenis Komoditi
                    </div>
                    <div className="card-body">

                        <div className="form-group row">
                            <label htmlFor="jenis" className="col-sm-4 col-form-label col-form-label-sm text-left">Jenis Komoditi</label>
                            <div className="col-sm-8">
                                <input className='form-control form-control-sm' id='namaSample'
                                    value={nama}
                                    required
                                    onChange={e => setNama(e.target.value)}
                                />
                            </div>
                        </div>

                    </div>
                    <div className='card-footer'>
                        <button type='button' onClick={closeModalFormJK} className='btn btn-sm btn-secondary mr-3'>Batal</button>
                        <button type='submit' className='btn btn-sm btn-primary'>Simpan</button>
                    </div>
                </div>
            </form>
        </Modal >
    );
}

export default ModalJenisKomoditi;