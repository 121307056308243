import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";
import { CommonAxisSettingsConstantLineStyle } from "devextreme-react/chart";
import { useThemes } from "../themes";

export function Brand() {

  const classes = useThemes();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses(`brand`, true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  const btnReff = useRef(null)
  const brandReff = useRef(null)
  const [expand, setExpand] = useState(false)
  function handleToggle(){
    var classN = btnReff.current.getAttribute("class")?.split(" ")
    var newExpand = classN.some(v=>v==="active")
    setExpand(!newExpand)
  }


  // useLayoutEffect(()=>{
  //   console.log(btnReff.current.getBoundingClientRect())
  // },[document.getElementById('kt_brand')?.offsetWidth])
  

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand brand-${classes.themeType} flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
          ref={brandReff}
          
      >
        {/* begin::Logo */}
        <a href="#!" className="brand-logo">
          {/* <img alt="logo" src={layoutProps.headerLogo} style={{width:"70%"}}/> */}
          <span>{expand ? "Sembunyikan Menu" : "Tampilkan Menu"} </span>
        </a>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button ref={btnReff} className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" onClick={handleToggle} >
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            {/* end::Toolbar */}
            </>
        )}
      </div>
      {/* end::Brand */}
      </>
  );
}
