import React, { createContext, useContext, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
// import { renderToString } from 'react-dom/server';
import html2canvas from 'html2canvas';
// import { createPdfFromHtml } from '../../../modules';
import { useGlobalState } from '../../../contexts/useGlobalState';
import { useParams, useLocation } from 'react-router';
import { baseUrl } from '../../../app.config';
import Swal from 'sweetalert2';
import moment from 'moment';
// import { toLower } from 'lodash';



const monthlyContext = createContext({
    rState: {
    },
    rAction: {
        // print
    }
})
export const MonthlyProvider = ({ children }) => {

    const [dataMilestone, setDataMilestone] = useState([])
    const [dataBudget, setDataBudget] = useState([])
    const [zoom, setZoom] = useState("80%");
    const [globalState, dispatch, globalActon] = useGlobalState()
    const { id, year, month } = useParams();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');
    const [data, setData] = useState({})

    const getChildren = (elmPage = [], elmCover = []) => {
        // console.log(elmPage, elmCover)
        const pdf = new jsPDF('p', 'mm');
        pdf.html(elmPage[0], {})

        elmPage.forEach(function (elm, a) {
            // console.log(elm)

            html2canvas(elm, { scale: 1 }).then(canvas => {
                pdf.addPage();
            }).catch(v => { });

        })

        pdf.save("pdf")
    }

    const monthNames = [null, "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    function createDataScurve(dataScurve = null) {
        // const bln = data?.data_view_header?.cut_off?.split('/');
        if (dataScurve === null || dataScurve === undefined) return [];
        const keyDataScurve = Object.keys(dataScurve);
        let mont = [];
        if (keyDataScurve.length > 0 && data?.data_cutoff_bulan) {
            keyDataScurve.forEach(k => {
                const arrK = k.split("_");
                if (arrK[0] === "plan") {
                    mont = [...mont, arrK[1]];
                }
            })
        }
        return mont.map(m => ({
            arg: m.toUpperCase(), name: m, plan: dataScurve[`plan_${m}`], actual: moment().month(m).isSameOrAfter(moment().month(data?.data_cutoff_bulan)) === false ? dataScurve[`actual_${m}`] : null
        }))
    }
    async function getMonthlyMobile() {
        const resp = await mobile();
        const { code, data = {} } = resp;
        if (code === '00') {
            setData(data);

            const { data_view_timeline = [], data_view_budget = [] } = data;
            let newDataMilesTone = [];
            let newDataBudget = [];
            data_view_timeline
                .filter(y => y.year === parseInt(year))
                .forEach((vt) => {
                    vt.data.filter(
                        vr => (vr.type === "milestones" || vr.type === "submilestones")
                    ).forEach(md => newDataMilesTone = [...newDataMilesTone, {
                        task: md.task,
                        type: md.type.charAt(0).toUpperCase() + md.type.slice(1),
                        year: vt.year
                    }])
                });
            data_view_budget
                .filter(b => b.year === parseInt(year))
                .forEach(v => {
                    newDataBudget = [...newDataBudget, ...v.data];
                })

            createDataScurve(data.data_view_scurve1)
            setDataBudget(newDataBudget)
            setDataMilestone(newDataMilesTone)
        }
    }

    async function getDataMonthlyReport() {
        const resp = await globalActon.getData(`/api/project-ongoing-crud/view-monthly-report-${globalState.dataUser?.role_id === '10' ? '4' : '2'}`, { id, year, month })
        const { code, data = {} } = resp;
        if (code === "00") {
            setData(data);
            const { data_view_timeline = [], data_view_budget = [] } = data;
            let newDataMilesTone = [];
            let newDataBudget = [];
            data_view_timeline
                .filter(y => y.year === parseInt(year))
                .forEach((vt) => {
                    vt.data.filter(
                        vr => (vr.type === "milestones" || vr.type === "submilestones")
                    ).forEach(md => newDataMilesTone = [...newDataMilesTone, {
                        task: md.task,
                        type: md.type.charAt(0).toUpperCase() + md.type.slice(1),
                        year: vt.year
                    }])
                });
            data_view_budget
                .filter(b => b.year === parseInt(year))
                .forEach(v => {
                    newDataBudget = [...newDataBudget, ...v.data];
                })

            let idLA = data?.data_view_achieve_con?.find(
                ({ type }) => type === "Achievement")?.from_logbook_research || "";

            let idLCon = data?.data_view_achieve_con?.find(
                ({ type }) => type === "Concern")?.from_logbook_research || "";

            getDataLogbook(idLA, 0)
            getDataLogbook(idLCon, 1)
            createDataScurve(data.data_view_scurve1)
            setDataBudget(newDataBudget)
            setDataMilestone(newDataMilesTone)
        }
    }

    async function getDataUser() {
        globalActon.getData('/api/me').then((resp) => {
            if (resp.code === "00") {
                dispatch({
                    type: "getSuccess",
                    payload: { dataUser: resp.data.data_user_me?.[0], loadingGet: false }
                })
            }
        })
    }
    const [logBookAch, setLogBookAch] = useState([]);
    const [logBookCon, setLogBookCon] = useState([]);

    async function getDataLogbook(ids, type = 0) {
        const idArr = ids.split(",");
        const datas = await Promise.all(
            idArr.map(v => v === "" ? null : globalActon.getData(`/api/get-data-logbook?id=${v}`))
        )
        let newData = []
        // console.log
        datas.forEach((c) => {
            if (c?.code === "00") {
                // console.log(newData)
                newData = [...newData, c.data]
            }
        })

        if (type === 0) {
            setLogBookAch(newData)
        } else if (type === 1) {
            setLogBookCon(newData)
        }
    }
    const mobile = async function getDataMonthlyReportMobile() {
        try {
            var data = [];
            const fetchResponse = await fetch(`${baseUrl}/api/project-ongoing-crud/view-monthly-report?id=${id}&&year=${year}&&month=${month}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            // console.log(fetchResponse);
            if (fetchResponse.status !== 200) {
                return {
                    statusCode: fetchResponse.status,
                    message: `HTTP request error ${fetchResponse.status}. Reason: ${fetchResponse.statusText}`
                }
            } else {
                data = await fetchResponse.json();
                // console.log('data', data);
                return data;
            }
        } catch (er) {
            return Swal.fire({
                title: 'Warning',
                text: er,
                icon: 'warning',
            })
        }
    }

    useEffect(function () {
        if (token) {
            getMonthlyMobile();
        } else {
            if (globalState.dataUser) {
                getDataMonthlyReport();
            } else {
                getDataUser();
            }
        }
    }, [globalState.dataUser])

    return <monthlyContext.Provider value={{
        rState: {
            zoom,
            data,
            monthNames,
            dataMilestone,
            dataBudget,
            logBookAch,
            logBookCon
        },
        rAction: {
            setZoom,
            getChildren,
            createDataScurve
            // print
        }
    }}>{children}</monthlyContext.Provider>;
}

export default function useMonthlyReport() { return useContext(monthlyContext) };