import React from 'react';
import { formatter } from '../../../../components/utility/utility';
import LabStatus from './labStatus';
import './stylePdf.css'

function ViewDetail({ data = {} }) {
    return (
        <div className='pdfStyle'>
            <div className='row mb-5 mt-5'>
                <div className='col-md-7 text-left'>
                    <h5 className='mb-5 font-weight-bold'>Permohonan Pengujian Laboratorium</h5>
                    <div className="form-group row mb-0">
                        <div className="col-sm-4 d-md-flex justify-cointent-between pr-0">
                            <span className=' mr-0' style={{ width: '175px' }}>No.</span>
                            <span className='ml-2'>:</span>
                        </div>
                        <div className="col-sm-8">
                            <span></span>
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <div className="col-sm-4 d-md-flex justify-cointent-between pr-0">
                            <span className=' mr-0' style={{ width: '175px' }}>Tanggal</span>
                            <span className='ml-2'>:</span>
                        </div>
                        <div className="col-sm-8">
                            <span>{data?.created_at_str || ""}</span>
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <div className="col-sm-4 d-md-flex justify-cointent-between pr-0">
                            <span className=' mr-0' style={{ width: '175px' }}>Judul Pengujian</span>
                            <span className='ml-2'>:</span>
                        </div>
                        <div className="col-sm-8">
                            <span>{data?.judul || ""}</span>
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <div className="col-sm-4 d-md-flex justify-cointent-between pr-0">
                            <span className=' mr-0' style={{ width: '175px' }}>Jumlah Komoditi/Sample</span>
                            <span className='ml-2'>:</span>
                        </div>
                        <div className="col-sm-8">
                            <span>{data?.jumlah_komoditi_sampel || ""}</span>
                        </div>
                    </div>
                    {/* <div className='d-flex mb-1'>
                        <span className='font-weight-bold' style={{ width: '175px' }}>Judul Pengujian</span>
                        <span>: {data?.judul || ""}</span>
                    </div> */}
                    {/* <div className='d-flex mb-1'>
                        <span className='font-weight-bold' style={{ width: '175px' }}>Jumlah Komoditi/Sample</span>
                        <span>: {data?.jumlah_komoditi_sampel}</span>
                    </div> */}
                </div>
                <div className='col-md-5 text-left'>
                    <div className="form-group row mb-5">
                        <div className="col-sm-6 d-md-flex justify-cointent-between pr-0 mb-0">
                            <p className='font-weight-bold mr-0' style={{ width: '175px' }}>Status Pengujian</p>
                            <span className='ml-2'>:</span>
                        </div>
                        <div className="col-sm-auto">
                            <span><LabStatus status={data?.status_id}>{data?.lab_test_status_id_str}</LabStatus></span>
                        </div>
                    </div>
                    {/* <p style={{ marginBottom: '20px' }}>Status Pengujian : {data?.lab_test_status_id_str}</p> */}
                    <p className='font-weight-bold mb-1'>Diajukan Oleh</p>
                    <span className='d-block mb-1'>{data?.user_nama}</span>
                    <span className='d-block mb-1'>{data?.user_email}</span>
                    <span className='d-block mb-1'>{data?.institution_nama} / {data?.institution_sub_nama}</span>
                    <span className='d-block mb-1'>{data?.user_fungsi}</span>
                    <span className='d-block mb-1'>{data?.user_alamat}</span>
                    <span className='d-block mb-1'>{data?.user_hp}</span>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-12'>
                    <p><u>Detail Permohonan Pengujian</u></p>
                    {
                        [...(data?.comodities || [])].map((d, i) => (
                            <div className='mb-5 p-5' key={i}>
                                <div className='row p-5 border rounded'>
                                    <div className='col-md-8 text-left mb-5'>
                                        <div className='d-flex mb-1'>
                                            <span className='font-weight-bold' style={{ width: '175px' }}>Komoditi Sample {i + 1}</span>
                                            <span>: {d?.lab_master_como_nama || ""}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='font-weight-bold' style={{ width: '175px' }}>Nama Sample</span>
                                            <span>: {d?.nama || ""}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='font-weight-bold' style={{ width: '175px' }}>Volume/Berat Sample</span>
                                            <span>: {d?.volume_berat || ""} {d?.satuan || ''}</span>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <p>Parameter Analisis</p>
                                        <table className="table tblecs table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width={'80px'} scope="col">#</th>
                                                    <th scope="col">Parameter Analisis</th>
                                                    <th scope="col">Metode Pengujian</th>
                                                    <th scope="col">Harga</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    [...(d?.tabel_parameter_uji || [])].map((f, i) => (
                                                        <tr key={i}>
                                                            <td width={'80px'}>{i + 1}</td>
                                                            <td>{f?.parameter_analisis}</td>
                                                            <td>{f?.metode}</td>
                                                            <td>{formatter.format(f?.harga)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <div className='d-flex'>
                                            <span className='font-weight-bold mb-3' style={{ width: '175px' }}>Keterangan</span>
                                            <span>{d?.note || ""}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <p><u>RESUME PENGUJIAN</u></p>
            <table className="table table-borderless" style={{ marginBottom: '100px' }}>
                <thead>
                    <tr className='bg-light-warning'>
                        <th scope="col">Deskripsi</th>
                        <th scope="col"></th>
                        <th scope="col">Jumlah</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='bg-white'>
                        <td width={'60%'}>Total Biaya Pengujian</td>
                        <td></td>
                        <td>Rp. {formatter.format(data?.total_harga)}</td>
                    </tr>
                    <tr className='bg-light-warning'>
                        <td></td>
                        <td>Sub Total</td>
                        <td>Rp. {formatter.format(data?.total_harga)}</td>
                    </tr>
                    <tr className='bg-white'>
                        <td></td>
                        <td>11% PPN</td>
                        <td>Rp. {formatter.format(data?.total_harga * 11 / 100)}</td>
                    </tr>
                    <tr className='bg-light-warning'>
                        <td></td>
                        <td>Total</td>
                        <td>Rp. {formatter.format(data?.total_harga + (data?.total_harga * 11 / 100))}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ViewDetail;