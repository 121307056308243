import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { GridDx, HeaderList } from '../../components';
import useFetch from '../../modules/useFetch';

function MapDetail(props) {
    const { doGet } = useFetch();
    const tableRef = useRef();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [data_page, setData_page] = useState({});
    const { search } = useLocation();
    const country = new URLSearchParams(search).get('country');
    const province = new URLSearchParams(search).get('province');
    const [filterCountry, setfilterCountry] = useState([]);
    const [filterProvince, setfilterProvince] = useState([]);
    const [column, setColumn] = useState([
        { fieldName: "created_by_name", caption: "Created By", width: 150 },
        { fieldName: "update_at_form", caption: "Last Modified", width: 100 },
        { fieldName: "judul", caption: "Judul Partnership", width: 300 },
        { fieldName: "nama_partner", caption: "Partner", width: 200, allowFiltering: false },
        { fieldName: "project_cluster_name", caption: "Project Cluster", width: 150 },
        { fieldName: "total_sa_cs_pc_tpta", caption: "Partnership Score", width: 150 },
        { fieldName: "country", caption: "Country", width: 150, headerFilterSource: filterCountry, 'calculateFilterExpression': calculateFilterExpression },
        { fieldName: "province", caption: "Province", width: 150, headerFilterSource: filterProvince, 'calculateFilterExpression': calculateFilterExpression },
        { fieldName: "function_name", caption: "Fungsi VP", width: 150 },
        { fieldName: "chief_name", caption: "Fungsi Manager", width: 150 },
        { fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", width: 150 },
        { fieldName: "status_dokumen_name", caption: "Status Dokumen", width: 150 },
        { fieldName: "berlaku_start", caption: "Mulai Berlaku", width: 150 },
        { fieldName: "berlaku_end", caption: "Sampai Dengan", width: 150 },
        { fieldName: "rkap", caption: "Related RKAP", width: 200 },
    ]);
    useEffect(() => {
        if (country) {
            Getdata();
        }
    }, [])


    const Getdata = useCallback(async () => {
        setLoading(true)
        try {
            const resp = await doGet({
                url: `api/ext-partnership/table-country-2?country=${country}&province=${province}`,
            });
            if (resp?.code === '00') {
                var fc = [];
                var fp = [];
                setData([...(resp.data?.tabel_kerjasama || [])].map(d => {
                    fc = [...fc, ...(d?.country || '').split(';')];
                    fp = [...fp, ...(d?.province || '').split(';')];
                    return { ...d }
                }))
                fc = [...new Set(fc)].filter(d => d).map(d => ({ text: d, value: d }));
                fp = [...new Set(fp)].filter(d => d).map(d => ({ text: d, value: d }))
                setfilterCountry(fc);
                setfilterProvince(fp);
                setColumn([
                    { fieldName: "created_by_name", caption: "Created By", width: 150 },
                    { fieldName: "update_at_form", caption: "Last Modified", width: 100 },
                    { fieldName: "judul", caption: "Judul Partnership", width: 300 },
                    { fieldName: "nama_partner", caption: "Partner", width: 200, allowFiltering: false },
                    { fieldName: "project_cluster_name", caption: "Project Cluster", width: 110 },
                    { fieldName: "total_sa_cs_pc_tpta", caption: "Partnership Score", width: 110 },
                    { fieldName: "country", caption: "Country", width: 150, headerFilterSource: fc, 'calculateFilterExpression': calculateFilterExpression, selectedFilterOperation: 'contains' },
                    { fieldName: "province", caption: "Province", width: 150, headerFilterSource: fp, 'calculateFilterExpression': calculateFilterExpression, selectedFilterOperation: 'contains' },
                    { fieldName: "function_name", caption: "Fungsi VP", width: 150 },
                    { fieldName: "chief_name", caption: "Fungsi Manager", width: 150 },
                    { fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", width: 150 },
                    { fieldName: "status_dokumen_name", caption: "Status Dokumen", width: 150 },
                    { fieldName: "berlaku_start", caption: "Mulai Berlaku", width: 150 },
                    { fieldName: "berlaku_end", caption: "Sampai Dengan", width: 150 },
                    { fieldName: "rkap", caption: "Related RKAP", width: 200 },
                ]);
                setData_page({ ...resp.data_page });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoading(false)
    }, [country, province]);

    function calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        let column = this;
        if (filterValue) {
            let selector = (data) => {
                let applyOperation = (arg1, arg2, op) => {
                    if (op === "=") return arg1 === arg2;
                    if (op === "contains") return arg1.includes(arg2);
                    if (op === "startswith") return arg1.startsWith(arg2);
                    if (op === "endswith") return arg1.endsWith(arg2);
                };
                let values = (column.calculateCellValue(data))?.split(';');
                return (
                    values &&
                    !!values.find((v) =>
                        applyOperation(v, filterValue, selectedFilterOperation)
                    )
                );
            };
            return [selector, "=", true];
        }
        return column.defaultCalculateFilterExpression.apply(this, arguments);
    }
    function cstmCellRender(e) {
        if (e.column.name === "judul") {
            return (
                <Link to=
                    {
                        {
                            pathname: "/KerjasamaDetail/" + e.data.id,
                        }
                    }
                >
                    <span className="text-wrap">{e.value}</span>
                </Link>
            )
        } else if (e.column.name === 'nama_partner') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => {
                            const partner = (d || '')?.split('|');
                            return (
                                <li className=" text-wrap p-2" key={i}>
                                    <span className='d-block'><b>{(partner[0] || '')}</b></span>
                                    <span className='d-block'>{(partner[1] || '')}</span>
                                    <span className='d-block'>{(partner[2] || '')}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        } else if (e.column.name === 'function_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'chief_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'country') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'province') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'reviewer_name') {
            return (
                <ul>
                    {
                        [...(e.value?.split(',') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else if (e.column.name === 'rkap') {
            return (
                <ul>
                    {
                        [...(e.value?.split(';') || [])].map((d, i) => (
                            <>
                                {
                                    d && <li className=" text-wrap p-2" key={i}>{d}</li>
                                }
                            </>
                        ))
                    }
                </ul>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <div className='container'>
            <div className="mb-5">
                <HeaderList
                    title={data_page?.page_title || ''}
                    someText={data_page?.page_breadcrumbs || ''}
                ></HeaderList>
            </div>
            <div className='row justify-content-center mb-5'>
                <div className='col-md-12'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <h5 className='mb-5 text-left'><b className='text-capitalize'>{country}{province ? ` - ${province}` : ''}</b></h5>
                            <GridDx
                                searchForm={true}
                                ref={tableRef}
                                loading={loading}
                                data={[...(data || [])]}
                                columns={column}
                                cellRender={cstmCellRender}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default MapDetail;