import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { GridDx, HeaderList } from '../../components';
import SVG from "react-inlinesvg";
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useGlobalState } from '../../contexts/useGlobalState';

const column = [
    { fieldName: "title", caption: "Judul", width: 300 },
    { fieldName: "description", caption: "Deskripsi", width: 300 },
    { fieldName: "area_name", caption: "Area", width: 150 },
    { fieldName: "category_name", caption: "Kategori", width: 200 },
    { fieldName: "author", caption: "Author", width: 150 },
    { fieldName: "creator_name", caption: "Creator", width: 150 },
    { fieldName: "sitelink", caption: "Site/Url", width: 150 },
    { fieldName: "created_at_str", caption: "Tanggal Upload", width: 100 },
]


function CrudKmLibrary() {
    const tableRef = useRef();
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [data_page, setData_page] = useState({});


    const deleteOnclick = (id) => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Library ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/km-library/delete?id=" + id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        window.location.reload();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const cstmCellRender = (e) => {
        if (e.column.name === "title") {
            return (
                <Link to={{
                    pathname: `/admin/library-detail/${e.data.id}`,
                }} target="_blank">
                    <span className='text-wrap'>
                        {
                            e.value
                        }
                    </span>
                </Link>
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }

    useEffect(() => {
        action.getData('/api/km-library/table-admin', {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                setData_page(resp.data_page);
            }
        });
    }, [])

    return (
        <>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <Card
                bg="primary"
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body className="p-4">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-6 mb-5">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Book-open.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Library</span>
                                    <span className="d-block"><strong>{[...(state.data_header || [])].reduce((tot, cur) => tot = tot + cur?.jumlah_int, 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                        {
                            [...(state.data_header || [])].map((d, i) => (
                                <div className="col-lg-1 col-md-1 col-6 mb-5" key={i}>
                                    <div className="d-flex">
                                        <div>
                                            <div className="border border-light rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block" style={{ wordBreak: 'break-word' }}>{d?.name}</span>
                                            <span className="d-block"><strong>{d?.jumlah_int}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0  mb-5 customShadow"
            >
                <Card.Body>
                    <GridDx
                        ref={tableRef}
                        toolbar={
                            <button className="btn-toolbar btn btn-primary mb-3"
                                onClick={() => history.push('/admin/library-add')}
                            >
                                <i className="fa fa-plus icon-nm my-auto"></i> Library
                            </button>
                        }
                        filenameExport={`Library Table-${moment(new Date()).format('YYYY-MM-DD')}`}
                        searchForm={true}
                        className="custom-style-master"
                        data={state?.dataBook}
                        cellRender={cstmCellRender}
                        columns={column}
                        withAction={true}
                        selectionMode={''}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                        actions={[
                            {
                                name: "Delete",
                                icon: "fa fa-trash-alt icon-nm text-danger",
                                action: (v, c) => { deleteOnclick(v?.id) }
                            },
                            {
                                name: "Edit Data Library",
                                icon: "fa fa-pencil-alt icon-nm text-secondary",
                                action: (v, c) => { history.push(`/admin/library-edit/${v?.id}`) }
                            }
                        ]}
                    >

                    </GridDx>
                </Card.Body>
            </Card>
        </>
    );
}

export default CrudKmLibrary;