import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useGlobalState } from "../../contexts/useGlobalState";

const tabDetContext = createContext({
    tabDtAction: {},
    tabDtState: {}
});

export function TabelDetailUserProvider({ children }) {
    const [glblState, dispatch, glblAction] = useGlobalState();
    const [data, setData] = useState([]);
    const [data_page, setData_page] = useState({});
    const location = useLocation();

    async function getDataDaily(tahun = '', bulan = '', hari = '') {
        try {
            const resp = await glblAction.getData(`/api/user/table-user-daily?tahun=${tahun}&bulan=${bulan}&hari=${hari}`, null, null, null, true);
            if (resp.code === "00") {
                setData(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire('error', error.toString(), 'error')
        }
    }
    async function getDataMonthly(tahun = '', bulan = '') {
        try {
            const resp = await glblAction.getData(`/api/user/table-user-monthly?tahun=${tahun}&bulan=${bulan}`, null, null, null, true);
            if (resp.code === "00") {
                setData(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire('error', error.toString(), 'error')
        }
    }
    async function getDatayearly(tahun = '') {
        try {
            const resp = await glblAction.getData(`/api/user/table-user-yearly?tahun=${tahun}`, null, null, null, true);
            if (resp.code === "00") {
                setData(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire('error', error.toString(), 'error')
        }
    }

    useEffect(() => {
        var menu = location.pathname?.split('/');
        if (menu[3] === 'table-user-daily') {
            getDataDaily(menu[4], menu[5], menu[6]);
        }
        if (menu[3] === 'table-user-monthly') {
            getDataMonthly(menu[4], menu[5])
        }
        if (menu[3] === 'table-user-yearly') {
            getDatayearly(menu[4])
        }
    }, [])
    return <tabDetContext.Provider
        value={{
            tabDtAction: {
                getDataDaily,
                getDataMonthly,
                getDatayearly
            },
            tabDtState: {
                data,
                data_page
            }
        }}
    >{children}</tabDetContext.Provider>
}

export default function useTabelDetail() {
    return useContext(tabDetContext);
}