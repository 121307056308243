import React, { useEffect, useRef } from 'react'
import { AccordionCs, ChartDx, CstmFilterCahrtDx, DoughnatDx, GridDx, HeaderList, SwitchBtn, Tab, TabContent, ExportPdf } from '../../components'
import Card from 'react-bootstrap/Card'
import '../nonAdmin/projectManagement/highlightDashboard/highlightDashboard.css'
import { month_source } from '../../components/utility/utility';
import useHighlightDash, { HdProvider } from '../nonAdmin/projectManagement/highlightDashboard/useHighlightDashboard';
import { useHistory, Link } from 'react-router-dom';
import { toUpper } from 'lodash';

const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]
const columns = [
    { fieldName: "project_code", caption: "Code Project", width: 100 },
    { fieldName: "title", caption: "Title", width: 300 },
    { fieldName: "project_type_str", caption: "Project Type", width: 80 },
    { fieldName: "perform_work", caption: "Performance/Progress", width: 115 },
    { fieldName: "status_progress_1", caption: "1", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_2", caption: "2", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_3", caption: "3", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_4", caption: "4", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_5", caption: "5", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_6", caption: "6", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_7", caption: "7", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_8", caption: "8", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_9", caption: "9", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_10", caption: "10", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_11", caption: "11", width: 50, headerFilterSource: progresFilter },
    { fieldName: "status_progress_12", caption: "12", width: 50, headerFilterSource: progresFilter },
]
function ReportHDashboard() {
    const { hdAction, hdState } = useHighlightDash();
    const history = useHistory();
    const refEx = useRef();

    useEffect(() => {
        hdAction.getDashboardAdmin(hdState.year_search, hdState.month_search)
    }, [])
    return (
        <>
            <HeaderList
                title={hdState.data_page?.page_title}
                someText={hdState.data_page?.page_breadcrumbs}
            />
            <div className="card customShadow ">
                <div className="card-header pb-0">
                    <span style={{ borderBottom: '3px solid #366ED2' }}>Hilight Dashboard Report</span>
                </div>
                <div className="card-body p-5">
                    <div className="row justify-content-end mb-5">
                        <div className="col-md-4 d-inline-flex">
                            <select className="form-control form-control-sm mx-1"
                                value={hdState.year_search}
                                onChange={(e) => hdAction.yearChangeAdmin(e.target.value)}
                            >
                                {
                                    [...(hdAction.getDataYear() || [])].map((d, i) => (
                                        <option key={i} value={d?.value}>{d?.name}</option>
                                    ))
                                }
                            </select>
                            <select className="form-control form-control-sm mx-1"
                                value={hdState.month_search}
                                onChange={(e) => hdAction.monthChangeAdmin(e.target.value)}
                            >
                                {
                                    [...(month_source || [])].map((d, i) => (
                                        <option key={i} value={d?.value}>{d?.name}</option>
                                    ))
                                }
                            </select>
                            <ExportPdf
                                targetRef={refEx}
                                fileName={`Dashboard Report-${hdState.month_search}-${hdState.year_search}`}
                                scale={0.8}
                            >
                                <button style={{ width: 'inherit' }} className="btn btn-sm btn-primary">
                                    Export PDF
                                </button>
                            </ExportPdf>
                        </div>
                    </div>
                    <div ref={refEx}>
                        <div className="row mb-5">
                            <div className="col-md-7 mb-md-0 mb-sm-5">
                                <div className="row h-100">
                                    <div className="col-md-4 mb-md-0 mb-sm-5">
                                        <Card
                                            className="m-0 p-0 h-100 customShadow background_card_1"
                                        >
                                            <Card.Body className="p-5">
                                                <div className="row m-0">
                                                    <div className="col-2 p-0">
                                                        <div className="rounded bg-white text-dark d-flex align-item-center justify-content-center p-2">
                                                            <i className="fa fa-folder-open text-dark" style={{ fontSize: '18px' }}></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 pl-5">
                                                        <span className="ctsmFilterTitle text-tittle-card">
                                                            My Project
                                                            <i className="fa fa-chevron-circle-right" onClick={() => history.push('/myproject')} style={{ color: 'white', paddingLeft: 10, cursor: 'pointer' }}></i>
                                                        </span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">Rangkuman Project</span>
                                                    </div>
                                                </div>
                                                <div className="row text-dark mt-5 mb-3" style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                    <div className="col-6 p-0">
                                                        <div style={{ height: '67px' }} className={`p-1 br-bottom `}
                                                        >
                                                            <div className="rounded m-1 p-1" style={{ backgroundColor: '#E8A819' }}>
                                                                <span className="ctsmFilterTitle text-tittle-card">{[...(hdState.dataDahsboard?.data_MyProject || [])].reduce((tot, curr) => {
                                                                    return tot += curr?.jumlah
                                                                }, 0)}</span>
                                                                <span className="ctsmFilterSubTitle text-subtittle-card">
                                                                    Total My Project
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        [...(hdState.dataDahsboard?.data_MyProject || [])].map((d, i, arr) =>
                                                        (
                                                            <div className="col-6 p-0" key={i}>
                                                                <div style={{ height: '67px' }} className={`p-2 ${i % 2 === 0 && 'br-left '} ${(i + 2) < arr.length && 'br-bottom '}`}
                                                                >
                                                                    <span className="ctsmFilterTitle text-tittle-card">{d.jumlah}</span>
                                                                    <span className="ctsmFilterSubTitle text-subtittle-card">
                                                                        {d.workflow_stage}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        )
                                                        )
                                                    }
                                                </div>
                                                <div className="rounded m-1 p-2 mb-3 myproject">
                                                    <span className="ctsmFilterTitle text-tittle-card">$ {[...(hdState.dataDahsboard?.data_MyProject || [])].reduce((tot, curr) => {
                                                        return tot += curr?.plan_budget
                                                    }, 0)} / $ {[...(hdState.dataDahsboard?.data_MyProject || [])].reduce((tot, curr) => {
                                                        return tot += curr?.actual_budget
                                                    }, 0)}</span>
                                                    <span className="ctsmFilterSubTitle text-subtittle-card">Total Budget/Expense (Ribu USD)</span>
                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 mb-md-0 mb-sm-5">
                                        <Card
                                            className="m-0 p-0 h-100 customShadow background_card_2"
                                        >
                                            <Card.Body className="p-5 text-white">
                                                <div className="row m-0">
                                                    <div className="col-2 p-0">
                                                        <div className="rounded bg-dark d-flex align-item-center justify-content-center p-2">
                                                            <i className="fa fa-gem text-white" style={{ fontSize: '18px' }}></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 pl-5">
                                                        <span className="ctsmFilterTitle text-tittle-card">
                                                            Planning
                                                        </span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">Ringkasan ideation dan portofolio tahun {hdState.dataDahsboard?.data_tahunQuery}</span>
                                                    </div>
                                                </div>
                                                <div className="row m-0 mt-5">
                                                    <div className="col-12" style={{ paddingLeft: 10 }}>
                                                        <span className="ctsmFilterTitle text-tittle-card">{hdState.dataDahsboard?.data_JumlahProjectIdeation?.total}</span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">
                                                            Total Ideasi Baru
                                                            <i className="fa fa-chevron-circle-right icon-nm" onClick={(e) => history.push({
                                                                pathname: '/ideation/table',
                                                                param: { tahun: hdState.dataDahsboard?.data_tahun_default },
                                                            })} style={{ color: 'white', paddingLeft: 10, cursor: 'pointer' }}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row m-0 mt-5">
                                                    <div className="col-12" style={{ paddingLeft: 10 }}>
                                                        <span className="ctsmFilterTitle text-tittle-card">{hdState.dataDahsboard?.data_JumlahProjectPortfolio?.total}</span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">
                                                            Total Portfolio
                                                            <i className="fa fa-chevron-circle-right icon-nm" onClick={(e) => history.push({ pathname: '/portfolio/table', param: { tahun: hdState.dataDahsboard?.data_tahun_default } })} style={{ color: 'white', paddingLeft: 10, cursor: 'pointer' }}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row m-0 mt-5">
                                                    <div className="col-12" style={{ paddingLeft: 10 }}>
                                                        <span className="ctsmFilterTitle  text-tittle-card">
                                                            $ <strong>{parseFloat((hdState.dataDahsboard?.data_JumlahProjectPortfolio?.total_plan_budget || 0), 0).toFixed(2)}</strong>
                                                        </span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">Propossed Budget (Ribu USD) </span>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4 mb-md-0 mb-sm-5">
                                        <Card
                                            className="m-0 p-0 h-100 customShadow background_card_3"
                                        >
                                            <Card.Body className="p-5 text-dark">
                                                <div className="row m-0">
                                                    <div className="col-2 p-0">
                                                        <div className="rounded bg-white d-flex align-item-center justify-content-center p-2">
                                                            <i className="fa fa-laptop text-dark" style={{ fontSize: '18px' }}></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 pl-5">
                                                        <span className="ctsmFilterTitle text-tittle-card">
                                                            Monitoring & Control
                                                        </span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">Kinerja fungsi RTI</span>
                                                    </div>
                                                </div>
                                                <div className="row text-dark mt-5 mb-3" style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                    <div className={`col-md-12 p-3 br-bottom text-center`}>
                                                        <span className="ctsmFilterTitle text-tittle-card">{hdAction.getPercentagePErform(([...(hdState.dataDahsboard?.data_HeaderMonitoring || [])].reduce((tot, cur) => tot = tot + cur?.actual_tot, 0) / [...(hdState.dataDahsboard?.data_HeaderMonitoring || [])].reduce((tot, cur) => tot = tot + cur?.plan_tot, 0)) || 0)}%</span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">
                                                            RTI
                                                            <i className="fa fa-chevron-circle-right icon-nm" onClick={(e) => history.push({
                                                                pathname: '/Monitoring/dashboard-performance-byfunction',
                                                                param: { function_id: 0, tahun: hdState.year_search, bulan: hdState.month_search }
                                                            })} style={{ color: 'white', paddingLeft: 10, cursor: 'pointer' }}></i>
                                                        </span>
                                                    </div>
                                                    {
                                                        [...(hdState.dataDahsboard?.data_HeaderMonitoring || [])].map((d, i) => (
                                                            <div key={i} className={`col-6 p-3 ${(i + 1) % 2 !== 0 && 'br-right'} ${(i < ([...(hdState.dataDahsboard?.data_HeaderMonitoring || [])].length % 2 === 0 ? [...(hdState.dataDahsboard?.data_HeaderMonitoring || [])].length - 2 : [...(hdState.dataDahsboard?.data_HeaderMonitoring || [])].length - 1)) && 'br-bottom'}`}>
                                                                <span className="ctsmFilterTitle text-tittle-card">{hdAction.getPercentagePErform(d.perform)}%</span>
                                                                <span className="ctsmFilterSubTitle text-subtittle-card">
                                                                    {d.short_name}
                                                                    <i className="fa fa-chevron-circle-right icon-nm float-right" onClick={(e) => history.push({
                                                                        pathname: '/Monitoring/dashboard-performance-byfunction',
                                                                        param: { function_id: d?.function_id, tahun: hdState.year_search, bulan: hdState.month_search }
                                                                    })} style={{ color: 'white', paddingLeft: 10, cursor: 'pointer' }}></i>
                                                                </span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className="rounded m-1 p-2 mb-3 myproject">
                                                    <span className="ctsmFilterTitle text-tittle-card">$ {hdState.dataDahsboard?.data_JumlahProjectMonitoring?.total_plan_budget} /$ {hdState.dataDahsboard?.data_JumlahProjectMonitoring?.total_actual_budget}</span>
                                                    <span className="ctsmFilterSubTitle text-subtittle-card">Total Budget/Expense (Ribu USD)</span>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <Card
                                    style={{ backgroundColor: 'white' }}
                                    className="m-0 p-0 h-100 customShadow"
                                >
                                    <Card.Body className="p-5 text-dark">
                                        <div className="row m-0">
                                            <div className="col-md-12 mb-sm-5">
                                                <div className="row">
                                                    <div className="col-2 p-0">
                                                        <div className="rounded text-dark d-flex align-item-center justify-content-center p-2" style={{ backgroundColor: '#F2BA22' }}>
                                                            <img style={{ width: '18px' }} src='/sidemenuasset/highlight_2.svg'></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 pl-5">
                                                        <span className="ctsmFilterTitle text-tittle-card">
                                                            Highlight
                                                            <i className="fa fa-chevron-circle-right icon-nm" onClick={(e) => history.push({
                                                                pathname: '/Monitoring/table',
                                                                param: { tahun: hdState.year_search, bulan: hdState.month_search },
                                                            })} style={{ color: '#F2BA22', paddingLeft: 10, cursor: 'pointer' }}></i>
                                                        </span>
                                                        <span className="ctsmFilterSubTitle text-subtittle-card">Pada Tahun {hdState.dataDahsboard?.data_tahunQuery}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="col-md-12 p-1 m-0">
                                                <div className="bg-blue text-white rounded p-2">
                                                    <span className="ctsmFilterSubTitle text-subtittle-card-1">
                                                        Total Proyek
                                                    </span>
                                                    <span className="ctsmFilterTitle text-tittle-card">{hdState.dataDahsboard?.data_CountProject?.jumlahProject || 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="col-3 p-1 m-0">
                                                <div className="bg-primary text-white rounded p-2">
                                                    <span className="ctsmFilterSubTitle text-subtittle-card-1">
                                                        Early Project
                                                        <Link to={`/ongoing/table-detail-search/${hdState.dataDahsboard?.data_tahun_default}/${hdState.dataDahsboard?.data_bulan_default}?q=${btoa('1/0/0/0/0')}`} target={"_blank"}>
                                                            <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                        </Link>
                                                    </span>
                                                    <span className="ctsmFilterTitle text-tittle-card">{[...(hdState.dataDahsboard?.data_WorkRealizationTotal || [])].reduce((tot, val) => { return tot = tot + val?.early }, 0)}</span>
                                                </div>
                                            </div>
                                            <div className="col-3 p-1 m-0">
                                                <div className="text-white rounded p-2" style={{ backgroundColor: '#44E08D' }}>
                                                    <span className="ctsmFilterSubTitle text-subtittle-card-1">
                                                        Ontime Project
                                                        <Link to={`/ongoing/table-detail-search/${hdState.dataDahsboard?.data_tahun_default}/${hdState.dataDahsboard?.data_bulan_default}?q=${btoa('2/0/0/0/0')}`} target={"_blank"}>
                                                            <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                        </Link>
                                                    </span>
                                                    <span className="ctsmFilterTitle text-tittle-card">{[...(hdState.dataDahsboard?.data_WorkRealizationTotal || [])].reduce((tot, val) => { return tot = tot + val?.ontime }, 0)}</span>
                                                </div>
                                            </div>
                                            <div className="col-3 p-1 m-0">
                                                <div className="text-white rounded p-2" style={{ backgroundColor: '#FF9941' }}>
                                                    <span className="ctsmFilterSubTitle text-subtittle-card-1">
                                                        Delay Project
                                                        <Link to={`/ongoing/table-detail-search/${hdState.dataDahsboard?.data_tahun_default}/${hdState.dataDahsboard?.data_bulan_default}?q=${btoa('3/0/0/0/0')}`} target={"_blank"}>
                                                            <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                        </Link>
                                                    </span>
                                                    <span className="ctsmFilterTitle text-tittle-card">{[...(hdState.dataDahsboard?.data_WorkRealizationTotal || [])].reduce((tot, val) => { return tot = tot + val?.delayed }, 0)}</span>
                                                </div>
                                            </div>
                                            <div className="col-3 p-1 m-0">
                                                <div className="text-white rounded p-2" style={{ backgroundColor: '#F6406A' }}>
                                                    <span className="ctsmFilterSubTitle text-subtittle-card-1" >
                                                        Need Attention
                                                        <Link to={`/ongoing/table-detail-search/${hdState.dataDahsboard?.data_tahun_default}/${hdState.dataDahsboard?.data_bulan_default}?q=${btoa('3/0/0/0/1')}`} target={"_blank"}>
                                                            <i className="fa fa-chevron-circle-right" style={{ color: 'white', paddingLeft: 10, cursor: 'pointer', fontSize: '9px' }}></i>
                                                        </Link>
                                                    </span>
                                                    <span className="ctsmFilterTitle text-tittle-card">{hdState.dataDahsboard?.data_need_man_support || 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="col-md-6 mb-5">
                                                <span className="d-block py-2 border-bottom border-dark mb-3">Jumlah Proyek / Tipe Proyek</span>
                                                {
                                                    React.useMemo(() => (
                                                        <ChartDx
                                                            loading={hdState.loadingDashboard}
                                                            id="type"
                                                            height={130}
                                                            yVisible={false}
                                                            xVisible={false}
                                                            yLabel={false}
                                                            argumentField='short_name'
                                                            dataSource={hdState.dataDahsboard?.data_CountProjectType}
                                                            withTitle={false}
                                                            showLegend={false}
                                                            toolsExport={false}
                                                            colorBarDifferent={true}
                                                            coloBarDifferentValueField='jumlahProject'
                                                            colorBarDiffirentField="short_name"
                                                            yAxisArgs={[
                                                                {

                                                                    valueField: "jumlahProject",
                                                                }
                                                            ]}
                                                        >

                                                        </ChartDx>
                                                    ), [hdState.loadingDashboard, hdState.dataDahsboard?.data_CountProjectType])
                                                }
                                            </div>
                                            <div className="col-md-6 mb-5">
                                                <span className="d-block py-2 border-bottom border-dark mb-3">Jumlah Proyek / Fungsi</span>
                                                {
                                                    React.useMemo(() => (
                                                        <ChartDx
                                                            id="type"
                                                            loading={hdState.loadingDashboard}
                                                            height={130}
                                                            yVisible={false}
                                                            xVisible={false}
                                                            yLabel={false}
                                                            argumentField='short_name'
                                                            dataSource={hdState.dataDahsboard?.data_CountProjectFunction}
                                                            onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                                            withTitle={false}
                                                            showLegend={false}
                                                            toolsExport={false}
                                                            colorBarDifferent={false}
                                                            coloBarDifferentValueField='jumlahProject'
                                                            colorBarDiffirentField="short_name"
                                                            yAxisArgs={[
                                                                {

                                                                    valueField: "jumlahProject",
                                                                }
                                                            ]}
                                                        >

                                                        </ChartDx>
                                                    ), [hdState.loadingDashboard, hdState.dataDahsboard?.data_CountProjectFunction])
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 mb-5 mb-sm-5">
                                <CstmFilterCahrtDx
                                    title="S-Curve Projects"
                                    subtitle="Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI"
                                    customOption={<SwitchBtn value={hdState.swChart1} onChange={(val) => hdAction.switch1Change(val)} />}

                                >
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                loading={hdState.loadingDashboard}
                                                dataSource={hdAction.getScurve1()}
                                                type="spline"
                                                withTitle={false}
                                                showLegend={true}
                                                toolsExport={false}
                                                LegendverticalAlignment="bottom"
                                                LegendhorizontalAlignment="center"
                                                LegenditemTextPosition="right"
                                                onRenderLabelChart={function (e) { }}
                                                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                                yAxisArgs={hdState.yAxis_chart1}
                                            >

                                            </ChartDx>
                                        ), [hdState.loadingDashboard, hdState.yAxis_chart1])
                                    }
                                </CstmFilterCahrtDx>
                            </div>
                            <div className="col-md-6 mb-5 mb-sm-5">
                                <CstmFilterCahrtDx
                                    title="S-Curve Budget"
                                    subtitle="Perbandingan kumulatif dan prognosis proyek-proyek RTI (Ribu USD)"
                                    customOption={<SwitchBtn value={hdState.swChart2} onChange={(val) => hdAction.switch2Change(val)} />}

                                >
                                    {
                                        React.useMemo(() => (
                                            <ChartDx
                                                loading={hdState.loadingDashboard}
                                                dataSource={hdAction.getScurve2()}
                                                type="spline"
                                                withTitle={false}
                                                showLegend={true}
                                                toolsExport={false}
                                                LegendverticalAlignment="bottom"
                                                LegendhorizontalAlignment="center"
                                                LegenditemTextPosition="right"
                                                onRenderLabelChart={function (e) { }}
                                                onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                                yAxisArgs={hdState.yAxis_chart2}
                                            >

                                            </ChartDx>
                                        ), [hdState.loadingDashboard, hdState.yAxis_chart2])
                                    }
                                </CstmFilterCahrtDx>
                            </div>
                            <div className="col-md-12 mb-5">
                                <CstmFilterCahrtDx
                                    title="Performance by Function"
                                    subtitle="Progres kinerja proyek berdasarkan fungsi-fungsi di RTI"
                                >
                                    <div className="row">
                                        {
                                            React.useMemo(() => [...(hdState.dataDahsboard?.data_WorkRealizationTotal || [])].map((d, i) => (
                                                <div className="col-md-3 mb-5" key={i}>
                                                    <DoughnatDx
                                                        //height={150}
                                                        colors={['#4C84FF', '#44E08D', '#FF9941', '#BBAB4', '#444444']}
                                                        withTitle={false}
                                                        dataSource={hdAction.getChartDoughnat(d)}
                                                        showLegend={true}
                                                        LegendverticalAlignment="bottom"
                                                        LegendhorizontalAlignment="center"
                                                        LegenditemTextPosition="top"
                                                        centerRender={() => hdAction.renderCenterLabelDoughnat(d?.short_name)}
                                                        onRenderLabelChart={(e) => (`${parseFloat(e.percentText).toFixed(0)}%`)}
                                                        toolsExport={false}
                                                        onRenderTooltip={(e) => ({ text: `${toUpper(e.argumentText)} = ${parseFloat(e.percentText).toFixed(0)}% ; ${e.value}` })}
                                                        yAxisArgs={[
                                                            {
                                                                valueField: "value",
                                                                argumentField: "state",
                                                                color: null,
                                                                format: null
                                                            }
                                                        ]}

                                                    />
                                                </div>
                                            )), [hdState.dataDahsboard?.data_WorkRealizationTotal])
                                        }
                                    </div>
                                </CstmFilterCahrtDx>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <Card
                                    className="m-0 p-0 h-100 customShadow"
                                >
                                    <Card.Body className="p-5">
                                        <div className="row">
                                            <div className="col-6">
                                                <h5>Tindak Lanjut Arahan Direksi</h5>
                                            </div>
                                            <div className="col-6 text-right">

                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <AccordionCs data={hdState.dataActivity} renderTitle={hdAction.renderTitleAktifitas} renderContent={hdAction.renderItemAktifitasReporting} />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <Card
                                    className="m-0 p-0 h-100 customShadow"
                                >
                                    <Card.Header>
                                        <span className="ctsmFilterTitle">Project Priority</span>
                                    </Card.Header>
                                    <Card.Body className="p-5">
                                        <div className="row">
                                            <div className="col-md-4 mb-5 mb-sm-5">
                                                <div className="d-flex bg-primary rounded p-2 text-white">
                                                    <div className="row col-md-12">
                                                        <div className="col-md-6">
                                                            <div className="h-100 d-flex flex-column justify-content-between">
                                                                <span className="ctsmFilterTitle">Total Project Priority</span>
                                                                <span className="ctsmFilterTitle float-bottom">{hdState.dataDahsboard?.data_CountProject_Unggulan?.jumlahProject}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {
                                                                React.useMemo(() => (
                                                                    <ChartDx
                                                                        id="type"
                                                                        height={70}
                                                                        yVisible={false}
                                                                        xVisible={false}
                                                                        yLabel={false}
                                                                        xLabel={true}
                                                                        dataSource={hdState.dataDahsboard?.data_CountProjectType_Unggulan}
                                                                        onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                                                        colorLabel='#181C32'
                                                                        argumentField='short_name'
                                                                        withTitle={false}
                                                                        showLegend={false}
                                                                        toolsExport={false}
                                                                        color={["#ffaa66"]}
                                                                        yAxisArgs={[
                                                                            {
                                                                                color: 'white',
                                                                                valueField: "jumlahProject",
                                                                            }
                                                                        ]}
                                                                    >

                                                                    </ChartDx>
                                                                ), [hdState.dataDahsboard?.data_CountProjectType_Unggulan])
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-5 mb-sm-5">
                                                <div className="d-flex h-100 rounded p-2 text-white" style={{ backgroundColor: '#ABC329' }}>
                                                    <div className="row col-md-12">
                                                        <div className="col-md-12">
                                                            <div className="h-100 d-flex flex-column justify-content-between">
                                                                <span className="ctsmFilterTitle">Project Performance</span>
                                                                <span className="ctsmFilterTitle text-right">{hdState.dataDahsboard?.data_TotalPerformance_Unggulan}%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-5 mb-sm-5">
                                                <div className="d-flex h-100 rounded p-2 text-white" style={{ backgroundColor: '#006CB8' }}>
                                                    <div className="row col-md-12">
                                                        <div className="col-md-12">
                                                            <div className="h-100 d-flex flex-column justify-content-between">
                                                                <span className="ctsmFilterTitle">Total Budget vs Expense</span>
                                                                <span className="ctsmFilterTitle text-right">${(hdState.dataDahsboard?.data_SCurve2_Unggulan?.plan_dec || 0)?.toFixed(2)}/${(hdState.dataDahsboard?.data_SCurve2_Unggulan?.actual_dec || 0)?.toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-12">
                                                <Tab
                                                    withCard={false}
                                                    itemsTab={[
                                                        {
                                                            id: "scurve",
                                                            title: "S-Curve"
                                                        },
                                                        {
                                                            id: "table",
                                                            title: "Table Detail"
                                                        }
                                                    ]}
                                                >
                                                    <TabContent id="scurve">
                                                        <div className="row col-md-12">
                                                            <div className="col-md-6 mb-5">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <span className="ctsmFilterTitle">S-Curve Projects Priority</span>
                                                                        <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI</span>
                                                                    </div>
                                                                    <div className="col-4 text-md-right" style={{ paddingRight: 0 }}>
                                                                        <SwitchBtn value={hdState.swChart1Unggulan} onChange={(val) => hdAction.switch1UnggulanChange(val)} />
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row ">
                                                                    <div className="col-md-12">
                                                                        {
                                                                            React.useMemo(() => (
                                                                                <ChartDx
                                                                                    dataSource={hdAction.getScurve1Unggulan()}
                                                                                    type="spline"
                                                                                    withTitle={false}
                                                                                    showLegend={true}
                                                                                    toolsExport={false}
                                                                                    LegendverticalAlignment="bottom"
                                                                                    LegendhorizontalAlignment="center"
                                                                                    LegenditemTextPosition="right"
                                                                                    onRenderLabelChart={function (e) { }}
                                                                                    onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                                                                    yAxisArgs={hdState.yAxis_chart1Unggulan}
                                                                                >

                                                                                </ChartDx>
                                                                            ), [hdState.yAxis_chart1Unggulan])
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-5">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <span className="ctsmFilterTitle">S-Curve Budget Priority</span>
                                                                        <span className="ctsmFilterSubTitle">Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI</span>
                                                                    </div>
                                                                    <div className="col-4 text-md-right" style={{ paddingRight: 0 }}>
                                                                        <SwitchBtn value={hdState.swChart2Unggulan} onChange={(val) => hdAction.switch2UnggulanChange(val)} />
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row ">
                                                                    <div className="col-md-12">
                                                                        {
                                                                            React.useMemo(() => (
                                                                                <ChartDx
                                                                                    dataSource={hdAction.getScurve2Unggulan()}
                                                                                    type="spline"
                                                                                    withTitle={false}
                                                                                    showLegend={true}
                                                                                    toolsExport={false}
                                                                                    LegendverticalAlignment="bottom"
                                                                                    LegendhorizontalAlignment="center"
                                                                                    LegenditemTextPosition="right"
                                                                                    onRenderLabelChart={function (e) { }}
                                                                                    onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
                                                                                    yAxisArgs={hdState.yAxis_chart2Unggulan}
                                                                                >

                                                                                </ChartDx>
                                                                            ), [hdState.yAxis_chart2Unggulan])
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabContent>
                                                    <TabContent id="table">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <GridDx
                                                                    data={hdState.dataDahsboard?.data_TableResearch_u}
                                                                    columns={columns}
                                                                    cellRender={hdAction.cstmCellRender}
                                                                >

                                                                </GridDx>
                                                            </div>
                                                        </div>
                                                    </TabContent>
                                                </Tab>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default () => <HdProvider><ReportHDashboard /></HdProvider>