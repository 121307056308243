import React, { useContext } from 'react';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';

function AccordionCs({
    data = [],
    renderContent = (data, index) => <span>{`Data - ${index}`}</span>,
    renderTitle = (title, isCurrentEventKey) => <span>{`Item ${title}`}</span>,
    titleField = 'title',
}) {
    function CustomToggle({ title, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <div className="d-block p-5" onClick={decoratedOnClick}>
                {
                    renderTitle(title, isCurrentEventKey)
                }
            </div>
        );
    }
    return (
        <>
            {/* <Accordion defaultActiveKey="0,1" className="accordion" alwaysOpen>
                {
                    data.map((d, i) => (
                        <Card>
                            <Card.Header className="bg-white">
                                <CustomToggle eventKey={i} title={d[titleField]} />
                            </Card.Header>
                            <Accordion.Collapse eventKey={i}>
                                <Card.Body className="p-5" style={{backgroundColor:'#F0F0F0'}}>
                                    {renderContent(d, i)}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))
                }
            </Accordion> */}
            {
                data.map((d, i) => (
                    <Accordion defaultActiveKey="0" className="accordion" key={i}>
                        <Card>
                            <Card.Header className="bg-white">
                                <CustomToggle eventKey={i} title={d[titleField]} />
                            </Card.Header>
                            <Accordion.Collapse eventKey={i}>
                                <Card.Body className="p-5" style={{ backgroundColor: '#F0F0F0' }}>
                                    {renderContent(d, i)}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                ))
            }

        </>
    );
}

export default AccordionCs;