import React, { useEffect, useState } from 'react';
import { FileUploadDx, HeaderList } from '../../components';
import { Card } from 'react-bootstrap'
// import { FileUploader } from 'devextreme-react';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import { fileExtensions, fileExtensions_string } from '../../components/utility/utility';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment';

function EditEvent(props) {
    const [state, dispatch, action] = useGlobalState();
    const history = useHistory();
    const param = useParams();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [waktuMulai, setWaktuMulai] = useState();
    const [waktuSelesai, setWaktuSelesai] = useState();
    const [tempat, setTempat] = useState();
    const [online, setOnline] = useState(false);
    const [organizer, setOrganizer] = useState();
    const [poster_location, setPoster_location] = useState();


    useEffect(() => {
        if (param?.id) {
            action.getData(`/api/people-event/view?id=${param?.id}`).then((resp) => {
                if (resp.code === '00') {
                    // console.log(resp)
                    const data = Array.isArray(resp.data.data_event) ? { ...resp.data.data_event[0] } : { ...resp.data.data_event }
                    setName(data?.name);
                    setDescription(data?.description);
                    const wm = data?.date_start ? moment(data?.date_start).format('YYYY-MM-DDTHH:mm:ss') : '';
                    const ws = data?.date_end ? moment(data?.date_end).format('YYYY-MM-DDTHH:mm:ss') : '';
                    // console.log(wm, ws);

                    setWaktuMulai(wm);
                    setWaktuSelesai(ws);
                    setTempat(data?.venue);
                    setOnline(data?.online_event);
                    setOrganizer(data?.organizer);
                    setPoster_location({
                        name: data?.poster_name,
                        is_new: false
                    })
                }
            })
        }
    }, [])

    function onUploadedCover(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setPoster_location({
                "name": resp.data?.data_filename,
                "is_new": true
            })
        }
    }
    // function fileOnchange(data) {
    //     // console.log(data)
    //     const f = data.value?.[0];
    //     if (data.value?.[0] && f.size <= 10000000) {
    //         var file = data.value?.[0];
    //         var ext = file?.name?.split('.').pop();
    //         var result = fileExtensions_string.filter(d => d === ext)
    //         if (result.length > 0) {
    //             action.postData(
    //                 {
    //                     url: "/api/base/upload-file-temp",
    //                     data: {
    //                         "File": data.value?.[0],
    //                     },
    //                     withFile: true,
    //                 }
    //             ).then((resp) => {
    //                 if (resp.code === "00") {
    //                         function onUploadedCover(res) {
    //     const resp = JSON.parse(res);
    //     if (resp?.code === '00') {
    //         setPoster_location({
    //             "name": resp.data?.data_filename,
    //             "is_new": true
    //         })
    //     }
    // }
    //                 }
    //             })
    //         }
    //     } else if (data.value?.[0] && f.size > 10000000) {
    //         return Swal.fire({
    //             title: 'Error',
    //             text: "File lebih dari 10Mb",
    //             icon: 'error',
    //         })
    //     }
    // }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageTime = '';
        if (data.name === '') {
            isValid = false;
            message = [...message, 'Judul'];
        }
        if (data.description === '') {
            isValid = false;
            message = [...message, 'Description'];
        }
        if (data.date_start === '') {
            isValid = false;
            message = [...message, 'Waktu Mulai'];
        }
        if (data.date_end === '') {
            isValid = false;
            message = [...message, 'Waktu Selesai'];
        }
        if (data.venue === '') {
            isValid = false;
            message = [...message, 'Venue'];
        }
        if (data.organizer === '') {
            isValid = false;
            message = [...message, 'Organizer'];
        }
        if (data.poster_location === '') {
            isValid = false;
            message = [...message, 'Foto'];
        }
        if (isValid) {
            if (!moment(waktuSelesai).isAfter(moment(waktuMulai))) {
                isValid = false;
                messageTime = 'Waktu Selesai tidak boleh kurang dari waktu mulai ';
            }
        }
        return {
            isValid,
            message: (message.length > 0 ? (message.join(', ') + ' Tidak Boleh Kosong') : '') + ((message.length > 0 && messageTime) ? ', ' : '') + messageTime
        }
    }
    function save() {
        const data = {
            "id": param?.id,
            "name": name || '',
            "description": description || '',
            "date_start": waktuMulai ? moment(waktuMulai).format('YYYY-MM-DD') : '',
            "date_end": waktuSelesai ? moment(waktuSelesai).format('YYYY-MM-DD') : '',
            "time_start": waktuMulai ? moment(waktuMulai).format('HH:mm') : '',
            "time_end": waktuSelesai ? moment(waktuSelesai).format('HH:mm') : '',
            "venue": tempat || '',
            "online_event" : online,
            "poster_location": poster_location || '',
            "organizer": organizer || '',
            "creator": '',
            "created_at": "",
            "updated_at": ""
        }
        // console.log(data)
        const valid = validation(data);
        if (valid.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: valid.message,
                icon: 'warning',
            })
        }
        else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Event? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/people-event/update-event",
                            data: data,
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            history.goBack();
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    return (
        <div>
            <HeaderList
                title="Edit Event"
                someText="People Management\Event\Edit"
            >

            </HeaderList>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-5">
                            <p>Detail</p>
                            <hr />
                            <div className="mx-5">
                                <div className="row mt-5 mb-3">
                                    <div className="col-md-2">
                                        <label>Nama Event</label>
                                    </div>
                                    <div className="col-md-10">
                                        <input className="form-control form-control-sm" placeholder="Nama Event" value={name} onChange={(e) => setName(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-2">
                                        <label>Deskripsi</label>
                                    </div>
                                    <div className="col-md-10">
                                        <textarea rows={3} className="form-control form-control-sm" placeholder="Deskripsi" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Waktu Mulai</label>
                                            </div>
                                            <div className="col-md-8">
                                                <input type="datetime-local" className="form-control form-control-sm" placeholder="" value={waktuMulai} onChange={(e) => setWaktuMulai(e.target.value)}></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Sampai</label>
                                            </div>
                                            <div className="col-md-8">
                                                <input type="datetime-local" className="form-control form-control-sm" placeholder="Nama Event" value={waktuSelesai} onChange={(e) => setWaktuSelesai(e.target.value)}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5 mb-3">
                                    <div className="col-md-2">
                                        <label>Tempat</label>
                                    </div>
                                    <div className="col-md-10">
                                        <input className="form-control form-control-sm" placeholder="Venue" value={tempat} onChange={(e) => setTempat(e.target.value)}></input>
                                        <input className="my-3 mr-2" type="checkbox" id="vehicle1" name="vehicle1"
                                            checked={online}
                                            onChange={(e) => setOnline(e.target.checked)}
                                        />
                                        <label for="vehicle1"> Online Event</label>
                                    </div>
                                </div>
                                <div className="row mt-5 mb-3">
                                    <div className="col-md-2">
                                        <label>Organizer</label>
                                    </div>
                                    <div className="col-md-10">
                                        <input className="form-control form-control-sm" placeholder="Organizer" value={organizer} onChange={(e) => setOrganizer(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className="row mt-5 mb-3">
                                    <div className="col-md-2">
                                        <label>Foto</label>
                                    </div>
                                    <div className="col-md-10">
                                        {/* <FileUploader
                                            className="custom-dx-fileuploader-file-name  cstm-upload" showFileList={false} multiple={false}
                                            allowedFileExtensions={fileExtensions}
                                            onValueChanged={(e) => fileOnchange(e)}
                                            readyToUploadMessage=" "
                                            selectButtonText="Select File" labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000}
                                            uploadMode="useForm" >

                                        </FileUploader> */}
                                        <FileUploadDx
                                            className="custom-dx-fileuploader-file-name"
                                            showFileList={true}
                                            multiple={false}
                                            selectButtonText="Select File"
                                            allowedFileExtensions={fileExtensions}
                                            readyToUploadMessage=" "
                                            labelText="or Drop file hire | Max size 10 Mb"
                                            accept="image/*"
                                            onUploaded={onUploadedCover}
                                            maxFileSize={10000000}
                                            uploadMode="instantly"
                                        />
                                        <span>{poster_location?.name}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="text-md-right">
                                    <button className="btn btn-sm btn-secondary mx-1" onClick={() => history.goBack()}>Batal</button>
                                    <button className="btn btn-sm btn-success mx-1"
                                        onClick={() => save()}
                                    ><i className="fab fa-telegram-plane icon-nm mx-1"></i>Update</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default EditEvent;