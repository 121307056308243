import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ModalJenisKomoditi from "./component/modalJenisKomoditi";
import ModalParameterAnalisis from "./component/modalParameterAnalisis";
import Swal from "sweetalert2";
import useFetch from "../../../../modules/useFetch";

const contexts = createContext({
    lmState: {},
    lmAction: {}
});

export function LabMasterProvider({ children }) {
    const history = useHistory();
    const [tabActive, setTabActive] = useState(history.location.state?.tabActive || "jenis");
    const { doPost, doGet } = useFetch();

    //Handle Jenis Komoditi
    const [modalFormJK, setModalFormJK] = useState({ show: false, is_editing: false, default_data: {} });
    const showModalFormJK = (is_editing = false, default_data = {}) => {
        setModalFormJK({
            show: true,
            is_editing,
            default_data
        });
    }
    const closeModalFormJK = () => setModalFormJK({ show: false, is_editing: false, default_data: {} });


    //Hanlde Parameter Analisis
    const [modalFormPA, setModalFormPA] = useState({ show: false, is_editing: false, default_data: {} });
    const showModalFormPA = (is_editing = false, default_data = {}) => {
        setModalFormPA({
            show: true,
            is_editing,
            default_data
        });
    }
    const closeModalFormPA = () => setModalFormPA({ show: false, is_editing: false, default_data: {} });

    //JK Handler
    const [dataJK, setDataJK] = useState([]);
    async function getJK() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-jenis-komoditi-list',
            });
            if (resp?.code === '00') {
                setDataJK([...(resp?.data || [])]);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const simpanJK = (formData = {}) => {
        const data = {
            id: formData?.id || "",
            nama: formData?.nama || "",
            note: "",
        }
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                <strong>Apa anda yakin ?</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                postApiJk(data);
            }
        });
    }
    const postApiJk = async (data = {}) => {
        try {
            const url = modalFormJK.is_editing ? "api/ext-lab/admin-update-jenis-komoditi" : "api/ext-lab/admin-add-jenis-komoditi";
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: url,
                data: data,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    closeModalFormJK();
                    getJK();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const deleteJK = (id) => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Komoditi ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const resp = await doPost({
                        url: `api/ext-lab/admin-delete-jenis-komoditi`,
                        data: {
                            "id": id,
                        },
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    });
                    if (resp?.code === '00') {
                        getJK();
                    } else {
                        return Swal.fire({
                            title: 'Error!',
                            text: resp?.message,
                            icon: 'error',
                        })
                    }
                } catch (error) {
                    Swal.fire("Error", error.toString(), "error")
                }
                return true;
            } else {
                return true;
            }
        })
    }

    //PA Handler
    const [dataPA, setDataPA] = useState([]);
    async function getPA() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/ext-lab/admin-view-parameter-analisis-list',
            });
            if (resp?.code === '00') {
                setDataPA([...(resp?.data || [])]);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const simpanPA = (formData = {}) => {
        const data = {
            "id": formData?.id || '',
            "lab_master_como_id": formData?.lab_master_como_id || "",
            "parameter_analisis": formData?.parameter_analisis || "",
            "metode": formData?.metode || "",
            "harga": formData?.harga || "",
            "note": formData?.note || "",
            "active": formData?.active
        }
        const validate = validationPA(data);
        if (!validate.isValid) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            });
        } else {
            Swal.fire({
                html:
                    `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
                    <strong>Apa anda yakin ?</strong>`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((res) => {
                if (res.isConfirmed) {
                    postApiPA(data);
                }
            });
        }
    }
    const validationPA = (data = {}) => {
        var isValid = true;
        var message = "";
        if (data?.lab_master_como_id === null || data?.parameter_analisis === "" || data?.metode === "" || data?.harga === "") {
            isValid = false
            message = "Jenis Komoditi, Parameter Analisis, Metode Pengujian dan Harga tidak boleh kosong";
        }
        return {
            isValid,
            message
        }
    }
    const postApiPA = async (data = {}) => {
        try {
            const url = modalFormPA.is_editing ? "api/ext-lab/admin-update-parameter-analisis" : "api/ext-lab/admin-add-parameter-analisis";
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: url,
                data: data,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    closeModalFormPA();
                    getPA();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    const deletePA = (id) => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Parameter Analisis ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const resp = await doPost({
                        url: `api/ext-lab/admin-delete-parameter-analisis`,
                        data: {
                            "id": id,
                        },
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    });
                    if (resp?.code === '00') {
                        getPA();
                    } else {
                        return Swal.fire({
                            title: 'Error!',
                            text: resp?.message,
                            icon: 'error',
                        })
                    }
                } catch (error) {
                    Swal.fire("Error", error.toString(), "error")
                }
                return true;
            } else {
                return true;
            }
        })
    }

    //Hanlde Tab
    const tabChangeHandle = (name) => {
        setTabActive(name);
    }

    useEffect(() => {
        if (tabActive === "jenis") {
            getJK();
        }
        if (tabActive === "parameter") {
            getPA();
            setSearchParam("");
            setExpand(false);
        }
    }, [tabActive])

    const [searchParam, setSearchParam] = useState("");
    const [expand, setExpand] = useState(false);

    return <contexts.Provider
        value={{
            lmState: {
                tabActive,
                modalFormJK,
                modalFormPA,
                dataJK,
                dataPA,
                searchParam,
                expand
            },
            lmAction: {
                tabChangeHandle,
                showModalFormJK,
                closeModalFormJK,
                showModalFormPA,
                closeModalFormPA,
                simpanJK,
                deleteJK,
                getJK,
                simpanPA,
                deletePA,
                setSearchParam,
                setExpand
            }
        }}
    >
        {React.useMemo(() => (
            <ModalJenisKomoditi />
        ), [modalFormJK])}
        {React.useMemo(() => (
            <ModalParameterAnalisis />
        ), [modalFormPA])}
        {children}
    </contexts.Provider>
}

export default function useLabMaster() {
    return useContext(contexts);
}