import React, { useRef } from 'react';
import useEquipment, { EqProvider } from '../equipmentMng/useEquipment';
import { HeaderList, Tab, TabContent, ExportPdf } from '../../components';
// import ListEquipment from './list';
// import DashboardEquipment from './tabDashboard';
import TabTable from './tabTable';
import DashboardEquipment from '../equipmentMng/dashboard';
import moment from 'moment';

function EqReporting() {
    const { eqAction, eqState } = useEquipment();
    const refEx = useRef();

    return (
        <>    <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={eqState.data_page?.page_title}
                someText={eqState.data_page?.page_breadcrumbs}
            />
            <div className="row">
                <div className="col-12">
                    <Tab
                        withCard={false}
                        itemsTab={[
                            {
                                id: "dashboard",
                                title: "Dashboard",
                                //onClick: () => eqAction.tabChange('dashboard')
                            },
                            {
                                id: "table",
                                title: "Equipment Table",
                                //onClick: () => eqAction.tabChange('table')
                            },
                        ]}
                    //manualActive={eqState.menu}
                    >
                        <TabContent id="dashboard">
                            <div className="card customShadow p-5">
                                <div className="text-right mb-5">
                                    <ExportPdf
                                        targetRef={refEx}
                                        fileName={`Equipment Dashboard'-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        scale={0.8}
                                    >
                                        <button className="btn btn-sm btn-primary">
                                            Export PDF
                                        </button>
                                    </ExportPdf>
                                </div>
                                <div ref={refEx}>
                                    <div className="mb-5 px-2 font-bold" style={{ fontSize: '12pt' }}>Equipment Dashboard</div>
                                    <DashboardEquipment />
                                </div>
                            </div>
                            {/* <DashboardEquipment /> */}
                        </TabContent>
                        <TabContent id="table">
                            <TabTable />
                        </TabContent>
                    </Tab>
                </div>
            </div>
        </div>
        </>
    )
}

export default () =>
    <EqProvider><EqReporting /></EqProvider>


