import moment from 'moment';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { swa_status_color } from '../../components/utility/utility';
import { useGlobalState } from '../../contexts/useGlobalState';

const swakelolaContext = createContext({
    swaState: {},
    swaAction: {}
})

export function SwaProvider({ children }) {
    const [globalState, dispatch, globalActon] = useGlobalState();
    const [data_page, setData_page] = useState({});

    const refDashboard = useRef();
    const detailRef = useRef();
    const realRef = useRef();
    const { menu } = useParams();
    const { pathname } = useLocation();
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    const history = useHistory();
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [dataDashboard, setDataDashboard] = useState({});
    const [loadingTable, setLoadingTable] = useState(false);
    const [dataTable, setDataTable] = useState({});
    const [switchBtn, setSwitchBtn] = useState(true);
    const [dataDetail, setDataDetail] = useState({});
    const [dataHeader, setDataHeader] = useState({});
    const [modeEditorKontrak, setmodeEditorKontrak] = useState('Add');
    const [dataEditorKontrak, setdataEditorKontrak] = useState({});
    const [modeEditorPlan, setmodeEditorPlan] = useState('Add');
    const [dataEditorPlan, setdataEditorPlan] = useState({});
    const [modeEditorAddendum, setmodeEditorAddendum] = useState('Add');
    const [dataEditorAddendum, setdataEditorAddendum] = useState({});
    const [modeEditorReal, setmodeEditorReal] = useState('Add');
    const [dataEditorReal, setdataEditorReal] = useState({});

    const [sw1, setSw1] = useState(false);
    const [swCost, setSwCost] = useState(false);
    async function getDashboard() {
        setLoadingDashboard(true)

        try {

            const resp = await globalActon.getData("/api/ext-swakelola/dashboard", {}, null, null, true)
            if (resp.code === "00") {
                setDataDashboard(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingDashboard(false)
    }

    async function getTable() {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData("/api/ext-swakelola/table-2", {}, null, null, true)
            if (resp.code === "00") {
                setDataTable(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    async function getTableAdmin() {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData("/api/ext-swakelola/table", {}, null, null, true)
            if (resp.code === "00") {
                setDataTable(resp.data);
                setData_page(resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    async function getTableReport() {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData("/api/ext-swakelola/table-3", {}, null, null, true)
            if (resp.code === "00") {
                setData_page(resp.data_page);
                setDataTable({
                    ...resp.data,
                    tabelDetail: [...(resp.data?.tabelDetail || [])].map((d, i) => {
                        return {
                            ...d,
                            nama_pihak3: [...(d?.nama_pihak3 || [])].map(x => x?.nama).join(','),
                            periode: `${d?.show_start} - ${d?.show_end}`
                        }
                    })
                })
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    async function getTablePlan(year) {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData(`/api/ext-swakelola/table-plan-2?year=${year}`, {}, null, null, true)
            if (resp.code === "00") {
                setData_page(resp.data_page);
                setDataTable(resp.data)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    async function getTablePlanAdmin(year) {
        setLoadingTable(true)

        try {

            const resp = await globalActon.getData(`/api/ext-swakelola/table-plan?year=${year}`, {}, null, null, true)
            if (resp.code === "00") {
                setData_page(resp.data_page);
                setDataTable(resp.data)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingTable(false)
    }
    async function getDetail(id) {
        try {

            const resp = await globalActon.getData(`/api/ext-swakelola/view-2/${id}`, {}, null, null, true);
            if (resp.code === "00") {
                setData_page(resp.data_page);
                setDataDetail(resp.data)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getDetailAdmin(id) {
        try {

            const resp = await globalActon.getData(`/api/ext-swakelola/view/${id}`, {}, null, null, true);
            if (resp.code === "00") {
                setData_page(resp.data_page);
                setDataDetail(resp.data)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getHeader() {
        try {

            const resp = await globalActon.getData(`/api/ext-swakelola/header`);
            if (resp.code === "00") {
                // console.log(resp.data)
                setDataHeader(resp.data)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function submitApproveAPI(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/approve/${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function submitPengadaanAPI(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/submit-pengadaan/${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function submitAddendumAPI(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/submit-addendum/${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function submitRejectAPI(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/reject/${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function submitFinishAPI(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/submit-realisasi/${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deleteKontrakApi(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/delete-kontrak?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                getDetail(dataDetail?.dataSwakelola?.swakelola_id);
                //window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deleteAddendumApi(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/delete-addendum?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                // window.location.reload();
                getDetail(dataDetail?.dataSwakelola?.swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deletePlanApi(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/delete-plan?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                // window.location.reload();
                getDetail(dataDetail?.dataSwakelola?.swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deleteRealisasiApi(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/delete-real?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                //window.location.reload();
                getDetail(dataDetail?.dataSwakelola?.swakelola_id);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function deleteSwakelolaApi(id) {
        try {
            const resp = await globalActon.postData(
                {
                    url: `/api/ext-swakelola/delete-swakelola?id=${id}`,
                    withFile: false
                }
            );
            if (resp.code === "00") {
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    function cellRenderGridDetailReport(rowInfo) {
        if (rowInfo.column.name === 'judul') {
            return (
                <>
                    {
                        path === '/admin/swakelola-crud' ? (
                            <>
                                {
                                    (rowInfo.data?.status_swakelola === "Need Revision" || rowInfo.data?.status_swakelola === 'Revisi') ? (
                                        <Link to={`/admin/swakelola-edit/${rowInfo.data?.swakelola_id}`}>
                                            <span className="d-block text-wrap">{rowInfo.value}</span>
                                        </Link>
                                    ) : (
                                        <Link to={`/admin/swakelola-detail/${rowInfo.data?.swakelola_id}`}
                                        // target='_blank' rel='noreferrer noopener'
                                        >
                                            <span className="d-block text-wrap">{rowInfo.value}</span>
                                        </Link>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                {
                                    ((rowInfo.data?.status_swakelola === "Need Revision" || rowInfo.data?.status_swakelola === "Revisi") && rowInfo.data?.is_Creator === true) ? (
                                        <Link to={`/swakelola-edit/${rowInfo.data?.swakelola_id}`} target='_blank'>
                                            <span className="d-block text-wrap">{rowInfo.value}</span>
                                        </Link>
                                    ) : (
                                        <Link to={`/swakelola-detail/${rowInfo.data?.swakelola_id}`}
                                            target='_blank' rel='noreferrer noopener'
                                        >
                                            <span className="d-block text-wrap">{rowInfo.value}</span>
                                        </Link>
                                    )
                                }
                            </>
                        )
                    }
                </>
            )
        } else if (rowInfo.column.name === 'nama_pihak3') {
            return (
                <>
                    <ul>
                        {
                            [...(rowInfo.value?.split(',') || [])].map((d, i) => (
                                <>
                                    {
                                        d && <li key={i}>{d}</li>
                                    }
                                </>
                            ))
                        }
                    </ul>
                </>
            )
        } else if (rowInfo.column.name === 'status_swakelola') {
            return (
                <span className={`d-block px-2 py-1 text-center text-white rounded ${swa_status_color(rowInfo.value)}`}>{rowInfo.value}</span>
            )
        } else if (rowInfo.column.name === 'show_end_status') {
            return (
                <span className={`d-block ${rowInfo.value === 'Expired' ? 'text-danger' : 'text-success'}`}>{rowInfo.value}</span>
            )
        }
        // else if (rowInfo.column.name === 'periode') {
        //     return (
        //         <span>{rowInfo.data?.show_start} - {rowInfo.data?.show_end}</span>
        //     )
        // } 
        else if (rowInfo.column.name === 'presentasePlanReal') {
            return (
                <span className="d-block text-wrap">{rowInfo.value} %</span>
            )
        } else {
            return (
                <span className="d-block text-wrap">{rowInfo.value}</span>
            )
        }
    }
    function cellRenderGridDetail(rowInfo) {
        if (switchBtn === false) {
            if (rowInfo.column.name === "action") {
                return (
                    <>
                        {
                            (rowInfo?.data?.status_swakelola !== "Selesai") && (
                                <div className='text-center'>
                                    <button className='btn btn-sm btn-danger'
                                        onClick={() => buttonDeleteClick(rowInfo?.data?.id)}
                                    ><i className='fa fa-trash-alt icon-nm'></i> Delete</button>
                                </div>
                            )
                        }
                    </>
                )
            } else if (rowInfo.column.name === 'judul') {
                return (
                    <>
                        {
                            path === '/admin/swakelola-crud' ? (
                                <>
                                    {
                                        (rowInfo.data?.status_swakelola === "Need Revision" || rowInfo.data?.status_swakelola === 'Revisi') ? (
                                            <Link to={`/admin/swakelola-edit/${rowInfo.data?.swakelola_id}`} target='_blank'>
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        ) : (
                                            <Link to={`/admin/swakelola-detail/${rowInfo.data?.swakelola_id}`}
                                                target='_blank' rel='noreferrer noopener'
                                            >
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        ((rowInfo.data?.status_swakelola === "Need Revision" || rowInfo.data?.status_swakelola === "Revisi") && rowInfo.data?.is_Creator === true) ? (
                                            <Link to={`/swakelola-edit/${rowInfo.data?.swakelola_id}`} target='_blank'>
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        ) : (
                                            <Link to={`/swakelola-detail/${rowInfo.data?.swakelola_id}`}
                                                target='_blank' rel='noreferrer noopener'
                                            >
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                )
            } else if (rowInfo.column.name === 'show_end_status') {
                return (
                    <span className={`d-block ${rowInfo.value === 'Expired' ? 'text-danger' : 'text-success'}`}>{rowInfo.value}</span>
                )
            } else if (rowInfo.column.name === 'nama_pihak3') {
                return (
                    <>
                        <ul>
                            {
                                [...(rowInfo.value || [])].map((d, i) => (
                                    <li key={i}>{d.nama}</li>
                                ))
                            }
                        </ul>
                    </>
                )
            } else if (rowInfo.column.name === 'status_swakelola') {
                return (
                    <span className={`d-block px-2 py-1 text-center text-white rounded ${swa_status_color(rowInfo.value)}`}>{rowInfo.value}</span>
                )
            } else if (rowInfo.column.name === 'periode') {
                var dt = moment().add(1, 'months');
                return (
                    <>
                        {
                            (rowInfo?.data?.show_end_status === 'Expired') ? (//moment(rowInfo.data?.show_end).isSameOrAfter(dt) ? (
                                <span className='d-block text-danger'>{rowInfo.data?.show_start} - {rowInfo.data?.show_end} <i className='fas fa-exclamation-circle icon-nm ml-2 text-danger'></i></span>
                            ) : (
                                <span className='d-block text-dark'>{rowInfo.data?.show_start} - {rowInfo.data?.show_end}</span>
                            )
                        }
                    </>
                    // <span>{rowInfo.data?.show_start} - {rowInfo.data?.show_end}</span>
                )
            } else if (rowInfo.column.name === 'presentasePlanReal') {
                return (
                    <span className="d-block text-wrap">{rowInfo.value} %</span>
                )
            } else {
                return (
                    <span className="d-block text-wrap">{rowInfo.value}</span>
                )
            }
        }
        if (switchBtn === true) {
            if (rowInfo.column.name === "action") {
                return (
                    <>
                        {
                            (rowInfo?.data?.status_swakelola !== "Selesai") && (
                                <div className='text-center'>
                                    <button className='btn btn-sm btn-danger'
                                        onClick={() => buttonDeleteClick(rowInfo?.data?.id)}
                                    ><i className='fa fa-trash-alt icon-nm'></i> Delete</button>
                                </div>
                            )
                        }
                    </>
                )
            }
            else if (rowInfo.column.name === 'judul') {
                return (
                    <>
                        {
                            path === '/admin/swakelola-crud' ? (
                                <>
                                    {
                                        (rowInfo.data?.status_swakelola === "Need Revision" || rowInfo.data?.status_swakelola === 'Revisi') ? (
                                            <Link to={`/admin/swakelola-edit/${rowInfo.data?.swakelola_id}`} target='_blank'>
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        ) : (
                                            <Link to={`/admin/swakelola-detail/${rowInfo.data?.swakelola_id}`}
                                                target='_blank' rel='noreferrer noopener'
                                            >
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        ((rowInfo.data?.status_swakelola === "Need Revision" || rowInfo.data?.status_swakelola === "Revisi") && rowInfo.data?.is_Creator === true) ? (
                                            <Link to={`/swakelola-edit/${rowInfo.data?.swakelola_id}`} target='_blank'>
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        ) : (
                                            <Link to={`/swakelola-detail/${rowInfo.data?.swakelola_id}`}
                                                target='_blank' rel='noreferrer noopener'
                                            >
                                                <span className="d-block text-wrap">{rowInfo.value}</span>
                                            </Link>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                )
            }
            else if (rowInfo.column.name === 'status_swakelola') {
                return (
                    <span className={`d-block px-2 py-1 text-center text-white rounded ${swa_status_color(rowInfo.value)}`}>{rowInfo.value}</span>
                )
            }
            else if (
                rowInfo.column.name === '1' ||
                rowInfo.column.name === '2' ||
                rowInfo.column.name === '3' ||
                rowInfo.column.name === '4' ||
                rowInfo.column.name === '5' ||
                rowInfo.column.name === '6' ||
                rowInfo.column.name === '7' ||
                rowInfo.column.name === '8' ||
                rowInfo.column.name === '9' ||
                rowInfo.column.name === '10' ||
                rowInfo.column.name === '11' ||
                rowInfo.column.name === '12'
            ) {
                return (
                    <>
                        <div className="d-block mb-1" style={{ minHeight: '22px' }}>
                            {
                                rowInfo?.data[`plan_${rowInfo.column.name}`] && (
                                    <span className="d-block px-2 py-1 bg-light-secondary text-secondary rounded text-center text-wrap">{parseFloat(rowInfo?.data[`plan_${rowInfo.column.name}`], 0).toFixed(2)}</span>
                                )
                            }
                        </div>
                        <div className="d-block" style={{ minHeight: '22px' }}>
                            {
                                rowInfo?.data[`real_${rowInfo.column.name}`] && (
                                    <span className="d-block px-2 py-1 bg-success text-white rounded text-center text-wrap">{parseFloat(rowInfo?.data[`real_${rowInfo.column.name}`], 0).toFixed(2)}</span>
                                )
                            }
                        </div>
                    </>
                )
            }
            else if (rowInfo.column.name === 'total') {
                return (
                    <>
                        <div className="d-block mb-1" style={{ height: '22px' }}>
                            {
                                rowInfo.data.totalPlan !== null && (
                                    <span className="d-block px-2 py-1 bg-light-secondary text-secondary rounded text-center">{parseFloat(rowInfo?.data?.totalPlan, 0).toFixed(2)}</span>
                                )
                            }
                        </div>
                        <div className="d-block" style={{ height: '22px' }}>
                            {
                                rowInfo.data.totalReal !== null && (
                                    <span className="d-block px-2 py-1 bg-success text-white rounded text-center">{parseFloat(rowInfo?.data?.totalReal, 0).toFixed(2)}</span>
                                )
                            }
                        </div>
                    </>
                )
            } else {
                return (
                    <span className="d-block text-wrap">{rowInfo.value}</span>
                )
            }
        }
    }
    function cellRenderKontak(rowInfo) {
        if (rowInfo.column.name === 'tanggal') {
            return (
                <span className="d-block p-2">{moment(rowInfo.value).format('DD MMM YYYY')}</span>
            )
        } else if (rowInfo.column.name === 'periode') {
            return (
                <span className="d-block p-2">{moment(rowInfo.data?.start_date).format('DD MMM YYYY')} - {moment(rowInfo.data?.end_date).format('DD MMM YYYY')}</span>
            )
        } else if (rowInfo.column.name === 'dok_pen') {
            return (
                <>
                    <ul>
                        {/* {rowInfo.data?.dok1_absensi && <li><label style={{ width: '100px' }} className="text-wrap"><b> ARP </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok1_absensi} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok2_ba_penjelasan && <li><label style={{ width: '100px' }} className="text-wrap"><b> BAP_Pekerjaan </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok2_ba_penjelasan} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok3_ba_pembukaan && <li><label style={{ width: '100px' }} className="text-wrap"><b> BAP_Penawaran </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok3_ba_pembukaan} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok4_ba_evaluasi && <li><label style={{ width: '100px' }} className="text-wrap"><b> BAEATH </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok4_ba_evaluasi} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok5_ba_klarifikasi && <li><label style={{ width: '100px' }} className="text-wrap"><b> BAKN </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok5_ba_klarifikasi} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok6_ba_negosiasi && <li><label style={{ width: '100px' }} className="text-wrap"><b> BANL </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok6_ba_negosiasi} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok7_kontrak_final && <li><label style={{ width: '100px' }} className="text-wrap"><b> KFinal </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok7_kontrak_final} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok8_po && <li><label style={{ width: '100px' }} className="text-wrap"><b> PO </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok8_po} target='_blank' download>Download File</a></li>}
                        {rowInfo.data?.dok9_dokumen_penawaran && <li><label style={{ width: '100px' }} className="text-wrap"><b> DPV </b></label><b>: </b><a href={dataDetail?.path + rowInfo.data?.dok9_dokumen_penawaran} target='_blank' download>Download File</a></li>} */}

                        <li><label style={{ width: '100px' }} className="text-wrap"><b> ARP </b></label><b>: </b>
                            {
                                rowInfo.data?.dok1_absensi ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok1_absensi} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        <li><label style={{ width: '100px' }} className="text-wrap"><b> BAP_Pekerjaan </b></label><b>: </b>
                            {
                                rowInfo.data?.dok2_ba_penjelasan ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok2_ba_penjelasan} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        <li><label style={{ width: '100px' }} className="text-wrap"><b> BAP_Penawaran </b></label><b>: </b>
                            {
                                rowInfo.data?.dok3_ba_pembukaan ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok3_ba_pembukaan} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        <li><label style={{ width: '100px' }} className="text-wrap"><b> BAEATH </b></label><b>: </b>
                            {
                                rowInfo.data?.dok4_ba_evaluasi ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok4_ba_evaluasi} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        <li><label style={{ width: '100px' }} className="text-wrap"><b> BAKN </b></label><b>: </b>
                            {
                                rowInfo.data?.dok5_ba_klarifikasi ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok5_ba_klarifikasi} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        <li><label style={{ width: '100px' }} className="text-wrap"><b> BANL </b></label><b>: </b>
                            {
                                rowInfo.data?.dok6_ba_negosiasi ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok6_ba_negosiasi} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        <li><label style={{ width: '100px' }} className="text-wrap"><b> KFinal </b></label><b>: </b>
                            {
                                rowInfo.data?.dok7_kontrak_final ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok7_kontrak_final} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        <li><label style={{ width: '100px' }} className="text-wrap"><b> PO </b></label><b>: </b>
                            {
                                rowInfo.data?.dok8_po ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok8_po} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li>
                        {/* <li><label style={{ width: '100px' }} className="text-wrap"><b> DPV </b></label><b>: </b>
                            {
                                rowInfo.data?.dok9_dokumen_penawaran ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok9_dokumen_penawaran} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                    :
                                    <i className='fa fa-times text-danger'></i>
                            }
                        </li> */}

                    </ul>
                </>
            )
        }
        else {
            return (
                <span className="d-block p-2">{rowInfo.value}</span>
            )
        }
    }
    function cellRenderAddendum(rowInfo) {
        if (rowInfo.column.name === 'tanggal') {
            return (
                <span className="d-block p-2">{moment(rowInfo.value).format('DD MMM YYYY')}</span>
            )
        } if (rowInfo.column.name === 'dok_pen') {
            return (
                <>
                    <ul>
                        {rowInfo.data?.dok1_ba_addendum && <li><label style={{ width: '100px' }} className="text-wrap"><b> BAA </b></label><b>: </b>{rowInfo.data?.dok1_ba_addendum}</li>}
                        {rowInfo.data?.dok2_permintaan && <li><label style={{ width: '100px' }} className="text-wrap"><b> SPP </b></label><b>: </b>{rowInfo.data?.dok2_permintaan}</li>}
                        {rowInfo.data?.dok3_rev_po && <li><label style={{ width: '100px' }} className="text-wrap"><b> Dok Rev PO </b></label><b>: </b>{rowInfo.data?.dok3_rev_po}</li>}
                        {rowInfo.data?.dok4_kontrak_addendum && <li><label style={{ width: '100px' }} className="text-wrap"><b> KA </b></label><b>: </b>{rowInfo.data?.dok4_kontrak_addendum}</li>}
                    </ul>
                </>
            )
        } else if (rowInfo.column.name === 'periode') {
            return (
                <span className="d-block p-2">{moment(rowInfo.data?.start_date).format('DD MMM YYYY')} - {moment(rowInfo.data?.end_date).format('DD MMM YYYY')}</span>
            )
        }
        else {
            return (
                <span className="d-block p-2">{rowInfo.value}</span>
            )
        }
    }
    function cellRenderPlan(rowInfo) {
        if (rowInfo.column.name === 'bulan') {
            return (
                <span className="d-block p-2">{moment(rowInfo.value, 'MM').format('MMMM')}</span>
            )
        } else {
            return (
                <span className="d-block p-2">{rowInfo.value}</span>
            )
        }
    }
    function cellRenderReal(rowInfo) {
        if (rowInfo.column.name === 'tanggal') {
            return (
                <span className="d-block p-2">{moment(rowInfo.value).format('DD MMM YYYY')}</span>
            )
        } else if (rowInfo.column.name === 'plan_list') {
            return (
                <span className="d-block p-2">{`${moment().month(rowInfo.value?.bulan - 1).format("MMMM")} ${rowInfo.value?.tahun} - ${rowInfo.value?.tipe} ${rowInfo.value?.jumlah}`}</span>
            )
        } else if (rowInfo.column.name === 'dok_kompilasi') {
            return (
                <>
                    {
                        rowInfo.value ? <span><i className='fa fa-check text-success mr-1'></i><a href={rowInfo.value} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                            :
                            <i className='fa fa-times text-danger'></i>
                    }
                </>
            )
        } else if (rowInfo.column.name === 'dok_laporan') {
            return (
                <>
                    {
                        rowInfo.value ? <span><i className='fa fa-check text-success mr-1'></i><a href={rowInfo.value} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                            :
                            <i className='fa fa-times text-danger'></i>
                    }
                </>
            )
        } else if (rowInfo.column.name === 'dok_lain') {
            return (
                <>
                    {
                        rowInfo.value ? <span><i className='fa fa-check text-success mr-1'></i><a href={rowInfo.value} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                            :
                            <i className='fa fa-times text-danger'></i>
                    }
                </>
            )
        } else if (rowInfo.column.name === "dok_pendukung") {
            return (

                <ul>
                    <li><label style={{ width: '150px' }} className="text-wrap"><b> Kompilasi Dokumen </b></label><b>: </b>
                        {
                            rowInfo.data?.dok_kompilasi ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok_kompilasi} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                :
                                <i className='fa fa-times text-danger'></i>
                        }
                    </li>
                    <li><label style={{ width: '150px' }} className="text-wrap"><b> Laporan Progres </b></label><b>: </b>
                        {
                            rowInfo.data?.dok_laporan ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok_laporan} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                :
                                <i className='fa fa-times text-danger'></i>
                        }
                    </li>
                    <li><label style={{ width: '150px' }} className="text-wrap"><b> Dokumen Lain </b></label><b>: </b>
                        {
                            rowInfo.data?.dok_lain ? <span><i className='fa fa-check text-success mr-1'></i><a href={dataDetail?.path + rowInfo.data?.dok_lain} target='_blank' download><i className='fa fa-download text-primary'></i></a></span>
                                :
                                <i className='fa fa-times text-danger'></i>
                        }
                    </li>
                </ul>
            )
        }
        else {
            return (
                <span className="d-block p-2">{rowInfo.value}</span>
            )
        }
    }
    function detailSearch(value) {
        if (detailRef.current) {
            detailRef.current.instance.searchByText(value)
        }
    }
    function realSearch(value) {
        if (realRef.current) {
            realRef.current.instance.searchByText(value)
        }
    }
    function switchBtnChange(val, reporting = false, menu_admin = false) {
        setSwitchBtn(val);
        if (val === true) {
            if (menu_admin === true) {
                getTablePlanAdmin('');
            } else {
                getTablePlan('');
            }
        } else {
            if (reporting === false) {
                if (menu_admin === true) {
                    getTableAdmin();
                } else {
                    getTable()
                }
            } else {
                getTableReport();
            }
        }
    }
    function yearFilterChange(val) {
        getTablePlan(val)
    }
    function tabChange(menu) {
        history.push(`/swakelola/${menu}`);
    }
    function buttonAddClick() {
        history.push(`/admin/swakelola-add`);
    }
    function buttonEditClick(id) {
        history.push(`/admin/swakelola-edit/${id}`)
    }
    function buttonAddClickResearcher() {
        history.push(`/swakelola-add`);
    }
    function buttonEditClickResearcher(id) {
        history.push(`/swakelola-edit/${id}`)
    }
    function buttonDeleteClick(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Swakelola ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteSwakelolaApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function submitApprove(id) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve ke Pengadaan?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                submitApproveAPI(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function submitPengadaan(id) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Submit Pengadaan ke Kontrak Berjalan?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                submitPengadaanAPI(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function submitToAddendum() {
        globalActon.toggleModal('modalAddendum');
    }
    function submitAddendum(id) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Submit Addendum?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                submitAddendumAPI(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function submitReject(id) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Reject ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                submitRejectAPI(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function submitNeedRevision() {
        globalActon.toggleModal('modalNeedRevision');
    }
    function submitRealisasi(id) {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Are you sure ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                submitFinishAPI(id);
                return true;
            } else {
                return true;
            }
        })

    }
    function addKontrak() {
        setmodeEditorKontrak('Add');
        setdataEditorKontrak({});
        globalActon.toggleModal('modalEditorKontrak');
    }
    function addPlan() {
        setmodeEditorPlan('Add');
        setdataEditorPlan({});
        globalActon.toggleModal('modalEditorPlan');
    }
    function addReal() {
        setmodeEditorReal('Add');
        setdataEditorReal({});
        globalActon.toggleModal('modalEditorReal');
    }
    function addAddendum() {
        setmodeEditorAddendum('Add');
        setdataEditorAddendum({});
        globalActon.toggleModal('modalEditorAddendum');
    }
    function editBtn(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => { setmodeEditorKontrak('Edit'); setdataEditorKontrak(data?.data); globalActon.toggleModal('modalEditorKontrak') }}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtn(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deleteKontrak(data?.data?.id)}
            >
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function editBtnPlan(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => { setmodeEditorPlan('Edit'); setdataEditorPlan(data?.data); globalActon.toggleModal('modalEditorPlan') }}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtnPlan(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deletePlan(data?.data?.id)}
            >
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function editBtnReal(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => { setmodeEditorReal('Edit'); setdataEditorReal(data?.data); globalActon.toggleModal('modalEditorReal') }}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtnReal(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deleteRealisasi(data?.data?.id)}
            >
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function editBtnAddendum(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => { setmodeEditorAddendum('Edit'); setdataEditorAddendum(data?.data); globalActon.toggleModal('modalEditorAddendum') }}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtnAddendum(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger"
                onClick={() => deleteAddendum(data?.data?.id)}
            >
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function headerCellRender(e) {
        return <p style={{ fontWeight: 'bolder', fontSize: '9pt', fontFamily: 'Poppins' }} className="text-wrap">{e.column.caption}</p>;
    }

    function deleteKontrak(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Kontrak ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteKontrakApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteAddendum(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Addendum ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteAddendumApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function deletePlan(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Plan ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deletePlanApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function deleteRealisasi(id) {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Realisasi ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteRealisasiApi(id);
                return true;
            } else {
                return true;
            }
        })
    }
    function validationActionButton() {
        const data_role = globalState.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Admin Pengadaan Swakelola" ||
                d === "Admin Pembayaran Swakelola" ||
                d === "Administrator") {
                result = true;
            }
        })
        return result;
    }
    function validationActionButtonValidasi() {
        const data_role = globalState.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Admin Pengadaan Swakelola" ||
                d === "Administrator") {
                result = true;
            }
        })
        return result;
    }
    function validationActionButtonKBerjalan() {
        const data_role = globalState.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Admin Pengadaan Swakelola" ||
                d === "Administrator") {
                result = true;
            }
        })
        return result;
    }
    function validationActionButtonSubmitPengadaan() {
        const data_role = globalState.dataUser?.role_name?.split(' | ');
        let result = false;
        [...(data_role || [])].forEach(d => {
            if (d === "Admin Pengadaan Swakelola" ||
                d === "Administrator") {
                result = true;
            }
        })
        return result;
    }

    const [dataChart2, setDataChart2] = useState([]);
    const [data_plan_pembayaran, setdata_plan_pembayaran] = useState('');
    const [data_persen_pembayaran, setdata_persen_pembayaran] = useState('');
    const [data_real_pembayaran, setdata_real_pembayaran] = useState('');
    const [loadingChart2, setLoadingChart2] = useState(true);
    const [dropDownChart2, setDropDownChart2] = useState([]);
    const [ddSelectedChart2, setDdSelectedChart2] = useState(new Date().getFullYear());

    const [dataChart5, setDataChart5] = useState([]);
    const [loadingChart5, setLoadingChart5] = useState(true);
    const [dropDownChart5, setDropDownChart5] = useState([]);
    const [ddSelectedChart5, setDdSelectedChart5] = useState(0);

    const [dataChart6, setDataChart6] = useState([]);
    const [loadingChart6, setLoadingChart6] = useState(true);
    const [dropDownChart6, setDropDownChart6] = useState([]);
    const [ddSelectedChart6, setDdSelectedChart6] = useState(0);

    async function getChart2() {
        setLoadingChart2(true)

        try {

            const resp = await globalActon.getData("/api/ext-swakelola/dashboard-chart2", {
                tahun: ddSelectedChart2
            })
            if (resp.code === "00") {
                const { data_chart = [], data_tahun = [], data_plan_pembayaran, data_real_pembayaran, data_persen_pembayaran } = resp?.data
                setDataChart2(data_chart);
                setDropDownChart2(data_tahun);
                setdata_plan_pembayaran(data_plan_pembayaran);
                setdata_real_pembayaran(data_real_pembayaran);
                setdata_persen_pembayaran(data_persen_pembayaran);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingChart2(false)
    }
    async function getChart5() {
        setLoadingChart5(true)

        try {

            const resp = await globalActon.getData(`/api/ext-swakelola/dashboard-chart5`, {
                swakelola_status: ddSelectedChart5
            })
            if (resp.code === "00") {
                const { data_chart = [], data_swakelola_status = [] } = resp?.data
                // setDataChart5(resp.data)
                setDataChart5(data_chart);
                setDropDownChart5(data_swakelola_status)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingChart5(false)
    }

    async function getChart6() {
        setLoadingChart6(true)

        try {

            const resp = await globalActon.getData(`/api/ext-swakelola/dashboard-chart6`, {
                swakelola_status: ddSelectedChart6
            })
            if (resp.code === "00") {
                const { data_chart = [], data_swakelola_status = [] } = resp?.data
                setDataChart6(data_chart);
                setDropDownChart6(data_swakelola_status)
                // // console.log(resp.data)
                // setDataChart6(resp.data)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingChart6(false)
    }

    useEffect(function () {
        getChart5()
    }, [ddSelectedChart5])

    useEffect(function () {
        getChart6()
    }, [ddSelectedChart6])

    useEffect(function () {
        getChart2()
    }, [ddSelectedChart2])
    useEffect(function () {
        getDashboard();
        // getTable();
        // getTablePlan('');
        getHeader();
    }, [])

    return <swakelolaContext.Provider value={{
        swaState: {
            globalState,
            dataDashboard,
            loadingDashboard,
            dataTable,
            loadingTable,
            switchBtn,
            dataDetail,
            dataHeader,
            menu,
            path,
            detailRef,
            realRef,
            refDashboard,
            modeEditorKontrak,
            dataEditorKontrak,
            modeEditorPlan,
            dataEditorPlan,
            modeEditorAddendum,
            dataEditorAddendum,
            modeEditorReal,
            dataEditorReal,
            dataChart2,
            dataChart5,
            dataChart6,
            dropDownChart2,
            dropDownChart5,
            dropDownChart6,
            ddSelectedChart2,
            ddSelectedChart5,
            ddSelectedChart6,
            loadingChart2,
            loadingChart5,
            loadingChart6,
            data_page,
            data_plan_pembayaran,
            data_real_pembayaran,
            data_persen_pembayaran,
            sw1,
            swCost
        },
        swaAction: {
            cellRenderGridDetail,
            cellRenderGridDetailReport,
            cellRenderKontak,
            cellRenderAddendum,
            cellRenderPlan,
            cellRenderReal,
            switchBtnChange,
            yearFilterChange,
            getDetail,
            tabChange,
            buttonAddClick,
            buttonAddClickResearcher,
            buttonDeleteClick,
            buttonEditClick,
            buttonEditClickResearcher,
            submitApprove,
            submitPengadaan,
            submitToAddendum,
            submitAddendum,
            submitReject,
            submitNeedRevision,
            submitRealisasi,
            detailSearch,
            realSearch,
            addKontrak,
            editBtn,
            deleteBtn,
            addPlan,
            addReal,
            editBtnPlan,
            deleteBtnPlan,
            editBtnReal,
            deleteBtnReal,
            editBtnAddendum,
            deleteBtnAddendum,
            addAddendum,
            headerCellRender,
            setDdSelectedChart2,
            setDdSelectedChart5,
            setDdSelectedChart6,
            // setDropDownChart6,
            getTable,
            getTableAdmin,
            getTableReport,
            getTablePlan,
            getTablePlanAdmin,
            getDetailAdmin,
            validationActionButton,
            validationActionButtonValidasi,
            validationActionButtonKBerjalan,
            validationActionButtonSubmitPengadaan,
            setSw1,
            setSwCost

        }
    }}>{children}</swakelolaContext.Provider>

}


export default function useSwakelola() {
    return useContext(swakelolaContext);
}