import React, { memo } from 'react';
import { Modal, Tab, TabContent, StopProjectAbi, StopProjectAndAction, StopProjectQuest, HeaderList } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import TabAll from './tabAll';
import TabLeader from './tabLeader';
import TabMember from './tabMember';
import TabArchived from './tabArchived';
import CreateProject from './createProjectModal';
import "./style.css";
import ModalContinueProject from '../monitoringViewEdit/modalContinueProject';
import useMyProject, { MpProvider } from './useMyProject';


const MyProject = memo(() => {
    const [state, dispatch, action] = useGlobalState();
    const { mpAction, mpState } = useMyProject();

    function submitContinueReq(val) {
        const data = {
            "id": '',
            "research_id": state.formData?.continue_id,
            "document": val?.document || [],
            "document_delete": val?.document_delete || [],
            "year": '',
            "is_final_report": true,
            "cover_file": val?.cover || [],
            "cover_file_delete": val?.cover_delete || [],
            "reason": val?.reason || ''
        }
        action.postData(
            {
                url: `/api/project-ongoing-crud/submit-continue-request?workflow_state_id=${state.formData?.workflow_state_id}`,
                data: data,
                withFile: false,
            }
        ).then((resp) => {
            if (resp.code === '00') {
                window.location.reload();
            }
        })
    }

    return (
        <>

            <Modal
                name="createProject"
                baseColor={false}
            >
                <CreateProject />
            </Modal>
            <Modal
                name="modalStopProject"
                baseColor={true}
                size='700px'
            >
                <StopProjectQuest onClickBatal={() => action.toggleModal('modalStopProject')} onClickLanjut={() => {
                    action.toggleModal('modalStopProject');
                    action.toggleModal('modalStopProjectForm');
                }} />
            </Modal>
            <Modal
                name="modalStopProjectForm"
                baseColor={true}
            >
                <StopProjectAndAction id={state.formData.stop_id} />
            </Modal>
            <Modal
                name="modalStopProjectAbi"
                baseColor={true}
            >
                <StopProjectAbi id={state.formData.stop_abi_id}></StopProjectAbi>
            </Modal>
            <Modal
                name="modalContinueProject"
                baseColor={true}
            >
                <ModalContinueProject onSubmit={submitContinueReq} workflow_state_name={state.formData?.workflow_state_name} id_research={state.formData?.continue_id} />
            </Modal>
            <HeaderList
                title={mpState.data_page?.page_title}
                someText={mpState.data_page?.page_breadcrumbs}
            ></HeaderList>
            <div className="card customShadow" style={{ padding: "20px", marginBottom: '50px' }}>
                <div className="row">
                    <div className="col-md-2 mb-3 mb-sm-3">
                        <select className="form-control form-control-sm"
                            value={mpState.filter?.tahun || ''}
                            onChange={(e) => mpAction.handleChangeFilter('tahun', e.target.value)}>
                            <option value="" key={-1}>- Pilih Tahun -</option>
                            {
                                [...(mpState.dataTahun || [])].map((y, i) => (
                                    <option key={i} value={y.idYear}>{y.idYear}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-md-2 mb-3 mb-sm-3">
                        <select className="form-control form-control-sm"
                            value={mpState.filter?.fungsi || ''}
                            onChange={(e) => mpAction.handleChangeFilter('fungsi', e.target.value)}>
                            <option value="" key={-1}>- Pilih Fungsi -</option>
                            {
                                [...(mpState.dataFungsi || [])].map((y, i) => (
                                    <option key={i} value={y.id}>{y.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-md-3 mb-3 mb-sm-3">
                        <div className="mb-3 input-group">
                            <input placeholder="Cari berdasarkan judul project"
                                aria-label="Cari"
                                aria-describedby="basic-addon2"
                                className="form-control form-control-sm"
                                value={mpState.tmpSrc}
                                onChange={(e) => mpAction.settmpSrc(e.target.value)}
                            />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-sm btn-outline-primary"
                                    onClick={() => mpAction.handleChangeFilter('search', mpState.tmpSrc)}
                                ><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                <Tab
                    withCard={false}
                    itemsTab={[...(mpState.tabs || {})].map(d => ({
                        id: d?.id,
                        onClick: () => mpAction.handleTabChange(d?.id),
                        title: <span>{d?.title}<span className="label label-light-dark label-inline mx-2">{mpState.totalData[d?.id] || 0}</span></span>
                    }))}
                    manualActive={mpState.selectedTab}
                >
                    <TabContent id="All">
                        <TabAll></TabAll>
                    </TabContent>
                    <TabContent id="Leader">
                        <TabLeader></TabLeader>
                    </TabContent>
                    <TabContent id="Team">
                        <TabMember></TabMember>
                    </TabContent>
                    <TabContent id="Archived">
                        <TabArchived></TabArchived>
                    </TabContent>
                </Tab>
            </div>
            <footer className="bg-white rounded shadow-lg floating-footer border-1">
                <div className="row">
                    <div className="col-md-6">

                    </div>
                    <div className="col-md-6 text-right">
                        {
                            state.dataUser?.role_id !== '10' && (
                                <button onClick={() => action.toggleModal("createProject")} className="btn btn-sm btn-success" >
                                    <i className="fa fa-plus icon-nm text-white"></i> Create Project
                                </button>
                            )
                        }
                    </div>
                </div>
            </footer>
        </>
    )
})

export default () => <MpProvider><MyProject /></MpProvider>;