import React, { useState, useEffect } from 'react';
import { GridDxTimelineMon, GanttChartP, SwitchBtn, SkeletonLoading, MilestonesBreadcrumbs } from '../../components/index'
import { columnSummary, rowSummary } from './dummy-data';
import Card from 'react-bootstrap/Card'
import { useGlobalState } from '../../contexts/useGlobalState';
import moment from 'moment';
import { toInteger, toUpper } from 'lodash';

const colomn = [
    { "fieldName": "task", "caption": "Task", minWidth: 300, witdh: "auto" },
    { "fieldName": "sometext", "caption": "", minWidth: 100, width: 100, allowSorting: false, allowFiltering: false },
    { "fieldName": "actual_jan", "caption": "JAN", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_feb", "caption": "FEB", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_mar", "caption": "MAR", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_apr", "caption": "APR", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_may", "caption": "MAY", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_jun", "caption": "JUN", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_jul", "caption": "JUL", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_aug", "caption": "AUG", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_sep", "caption": "SEP", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_oct", "caption": "OCT", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_nov", "caption": "NOV", "visible": true, "dataType": "number", width: 50 },
    { "fieldName": "actual_dec", "caption": "DEC", "visible": true, "dataType": "number", width: 50 }
]

const TabTimelineMon = ({ param }) => {
    const [sw, setSw] = useState(false);
    const [data, setData] = useState();
    const [state, , action] = useGlobalState();

    useEffect(() => {
        if (param?.id) {
            action.getData("/api/project-ongoing-crud/view-timeline-4/" + param?.id, {}, null, 'loadingTimeline', true).then((resp) => {
                if (resp.code === "00") {
                    setData(resp.data.data_view_timeline)
                    action.setForm('cut_off_timeline', resp.data.data_cut_off_day || 1)
                    action.setForm('mon', resp.data_page);
                }
            })
        }
    }, [])

    function renderMilestonesItem(item) {
        return (
            <ol style={{ paddingLeft: '15px' }}>
                {
                    [...(item?.data || [])].filter(d => d?.type === "milestones" || d?.type === "submilestones").map((d, i) => (
                        <li className='text-wrap' key={i}>{d?.task} ({
                            ["plan_jan", "plan_feb", "plan_mar", "plan_apr", "plan_may", "plan_jun", "plan_jul", "plan_aug", "plan_sep", "plan_oct", "plan_nov", "plan_dec"].map(x => d[x] > 0 ? x.split('_')[1] : '').filter(x => x).join(",").toUpperCase()
                        })</li>
                    ))
                }
            </ol>
        )
    }
    return (
        <div className="row h-100 p-3">
            <div className="col-md-12">
                <span className='d-block text-center mb-5 px-2 py-1 bg-primary rounded text-white'>Pengisian realisasi bulan {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).format('MMM'))} dapat dilakukan s.d. tanggal {state.data_cut_off_date} {toUpper(moment().month(toInteger(state.data_year_month?.split('/')?.[0]) - 1).add(1, 'month').format('MMM'))} {state.data_year_month?.split('/')?.[1]}</span>
                <p><i className="fa fa-circle icon-sm"></i> Pengisian estimasi progres dapat diisi pada Sub Task, Milestone, Sub Milestone.
                    Sedangkan untuk Task hanya berupa keterangan.
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Jumlah [Sub Task + Milestone + Sub Milestone] minimal 8 baris
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Bobot isian adalah dalam bentuk persentase (%) dan total plan untuk setiap tahun
                    harus berjumlah 100%
                </p>
                <p><i className="fa fa-circle icon-sm"></i> Pastikan timeline project diisi sampai dengan komersial / implementasi
                </p>
                <div className="row">
                    <div className="col-md-12 text-left text-md-right text-lg-right">
                        <label style={{ paddingRight: 10 }}>Ganttchart view</label>
                        <SwitchBtn value={sw} onChange={(e) => setSw(e)} />
                        <label style={{ paddingLeft: 10 }}>Weight view</label>
                    </div>
                </div>
                <hr style={{ margin: 0 }} />
                <MilestonesBreadcrumbs data={[...(data || [])]} titleField="year" renderItem={renderMilestonesItem} />
                {
                    state.loadingTimeline ? (
                        <SkeletonLoading type="table" rows={5} cols={colomn.length} />
                    ) : (
                        [...(data || [])].map((e, i) => (
                            <Card className="p-0 my-2" key={i}>
                                <Card.Body className="p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="d-flex py-2 justify-content-between border-bottom">
                                                        <span className="align-self-center">{e.year}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        sw === true ? (
                                                            <>
                                                                <GridDxTimelineMon
                                                                    dataSource={e}
                                                                    allowAdding={false}
                                                                    allowDeleting={false}
                                                                    allowEditing={false}
                                                                    showTotalActual={true}
                                                                    columns={colomn}
                                                                    summaryC={true}
                                                                    columnSummary={columnSummary}
                                                                    summaryR={true}
                                                                    rowSummary={rowSummary}
                                                                    data_year_month={state.data_year_month}
                                                                />                                                            
                                                            </>
                                                        ) : (
                                                            <GanttChartP
                                                                dataSource={e.data}
                                                                column={colomn.filter(c => c.fieldName !== "sometext")}
                                                                showActualPlan={true}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))
                    )
                }
            </div>
        </div>
    )
}
export default TabTimelineMon;