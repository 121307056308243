import React, { useEffect } from 'react';
import useFetch from '../../../../modules/useFetch';
import { useState } from 'react';
import { useRef } from 'react';
import Swal from 'sweetalert2';
import { GridDx, HeaderList } from '../../../../components';
import { Card } from 'react-bootstrap';
import { status_equipment } from '../../../../components/utility/utility';
import { Link } from 'react-router-dom';

const column_table = [
    { fieldName: 'nomor', caption: 'Nomor Asset', width: 150 },
    { fieldName: 'nama', caption: 'Nama Alat', width: 300 },
    { fieldName: 'lab', caption: 'LAB', width: 100 },
    { fieldName: 'pic_name', caption: 'PIC', width: 100 },
    { fieldName: 'status_equipment', caption: 'Status Expired', width: 100 },
    { fieldName: 'tanggal_selesai', caption: 'Masa berlaku status', width: 150 },
    { fieldName: 'status_name_01', caption: '1', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_02', caption: '2', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_03', caption: '3', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_04', caption: '4', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_05', caption: '5', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_06', caption: '6', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_07', caption: '7', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_08', caption: '8', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_09', caption: '9', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_10', caption: '10', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_11', caption: '11', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_12', caption: '12', allowFiltering: true, width: 50 },
]

function DetailEquipmentExpired(props) {
    const { doGet } = useFetch();
    const [data_page, setData_page] = useState({});
    const tableRef = useRef();

    function customCellRender(rowInfo) {               
        if (rowInfo.column.name === 'nama') {
            return (
                <Link to={`/admin/equipment-detail/${rowInfo.data?.id}`} target="_blank">
                    <span className="d-block text-wrap">{rowInfo.value}</span>
                </Link>
            )
        } else if (rowInfo.column.name === 'status_name_01' ||
            rowInfo.column.name === 'status_name_02' ||
            rowInfo.column.name === 'status_name_03' ||
            rowInfo.column.name === 'status_name_04' ||
            rowInfo.column.name === 'status_name_05' ||
            rowInfo.column.name === 'status_name_06' ||
            rowInfo.column.name === 'status_name_07' ||
            rowInfo.column.name === 'status_name_08' ||
            rowInfo.column.name === 'status_name_09' ||
            rowInfo.column.name === 'status_name_10' ||
            rowInfo.column.name === 'status_name_11' ||
            rowInfo.column.name === 'status_name_12' ||
            rowInfo.column.name === 'nama_status'
        ) {
            return (
                <>
                    {
                        status_equipment(rowInfo.value)
                    }
                </>
            )
        } else if (rowInfo.column.name === "status_equipment") {
            var color_text = rowInfo.value === "Expired" ? "text-danger" :
                rowInfo.value === "Warning" ? "text-warning" :
                    rowInfo.value === "Running" ? "text-success" : "text-dark"
            return (
                <span className={`d-block ${color_text}`}>{rowInfo.value}</span>
            )
        } else if (rowInfo.column.name === "tanggal_selesai") {
            return (
                <>
                    {
                        (rowInfo.data?.status_equipment === "Expired") ? (
                            <span className='d-block text-danger'>{rowInfo.value} <i className='fas fa-exclamation-circle icon-nm ml-2 text-danger'></i></span>
                        ) : (
                            <span className='d-block text-dark'>{rowInfo.value}</span>
                        )
                    }
                </>
            )
        } else if (rowInfo.column.name === 'iso_17025') {
            return (
                <span className="d-block py-1 px-2">{rowInfo.value === true ? "Ya" : rowInfo.value === false ? "Tidak" : ""}</span>
            )
        } else if (rowInfo.column.name === 'pc') {
            return (
                <span className="d-block py-1 px-2">{rowInfo.value === true ? "Ada" : rowInfo.value === false ? "Tidak Ada" : ""}</span>
            )
        } else if (rowInfo.column.name === 'usia') {
            return (
                <span className="d-block py-1 px-2">{rowInfo.value} Tahun</span>
            )
        } else {
            return (
                <span className="d-block py-1 px-2 text-wrap">{rowInfo.value}</span>
            )
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    async function getData() {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/user/table-search-equipment-expire',
            });
            if (resp?.code === '00') {
                setData(resp?.data);
                setData_page(resp?.data_page);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }
    return (
        <div className='p-5'>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            ></HeaderList>
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <Card
                        className='m-0 p-0 customShadow'
                        style={{ marginBottom: '40px' }}
                    >
                        <Card.Body className='p-5'>
                            <GridDx
                                searchForm={true}
                                loading={loading}
                                ref={tableRef}
                                data={data}
                                columns={column_table}
                                cellRender={customCellRender}
                                withAction={false}
                                pagination={true}
                                orderingDrag={false}
                                currentPage
                                show
                            >
                            </GridDx>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default DetailEquipmentExpired;