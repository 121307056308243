import moment from 'moment';
import React from 'react';
import { Card } from 'react-bootstrap';
import { ExportPdf, HeaderList, Tab, TabContent } from '../../components';
import DashboardSwakelola from '../swakelola/dashboard';
import ListSwakelola from '../swakelola/list';
import useSwakelola, { SwaProvider } from '../swakelola/useSwakelola';

function ReportingSwakelola(props) {
    const { swaAction, swaState } = useSwakelola();
    return (
        <>
            <div style={{ paddingBottom: '20px' }}>
                <HeaderList
                    title={swaState.data_page?.page_title}
                    someText={swaState.data_page?.page_breadcrumbs}
                />
                <div className="row">
                    <div className="col-12">
                        <Tab
                            withCard={false}
                            itemsTab={[
                                {
                                    id: "dashboard",
                                    title: "Dashboard",
                                },
                                {
                                    id: "table",
                                    title: "Swakelola Table",
                                },
                            ]}
                        >
                            <TabContent id="dashboard">
                                <Card
                                    className="m-0 p-0 customShadow"
                                >
                                    <Card.Body className="p-2">
                                        <div className="d-block text-md-right">
                                            <ExportPdf
                                                targetRef={swaState.refDashboard}
                                                scale={0.8}
                                                fileName={`Swakelola Dashboard-${moment(new Date()).format('YYYY-MM-DD')}`}
                                            >
                                                <button className="btn btn-sm btn-primary mb-5">
                                                    Export PDF
                                                </button>
                                            </ExportPdf>
                                        </div>
                                        <div ref={swaState.refDashboard} >
                                            <div className="mb-5 px-2 font-bold" style={{ fontSize: '12pt' }}>Swakelola Dashboard</div>
                                            <DashboardSwakelola />
                                        </div>
                                    </Card.Body>
                                </Card>

                            </TabContent>
                            <TabContent id="table">
                                <ListSwakelola reporting={true} />
                            </TabContent>
                        </Tab>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <SwaProvider><ReportingSwakelola /> </SwaProvider>;