import { DateBox, TagBox } from 'devextreme-react';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import useWhatsapp from '../useWhatsapp';
import { FileUploadDx, RadioGrp } from '../../../../components';
import moment from 'moment';

function TabWhatsapp(props) {
    const refFile = useRef();
    const { waAction, waState } = useWhatsapp();
    const [personal, setPersonal] = useState({
        enable: false,
        value: []
    });
    const [role, setRole] = useState({
        enable: false,
        value: []
    });
    const [fungsi, setFungsi] = useState({
        enable: false,
        value: []
    });
    const [withFileAttachment, setwithFileAttachment] = useState({
        enable: false,
        value: ""
    })
    const [message, setMessage] = useState('');
    const [withRecipientName, setwithRecipientName] = useState(false);
    const [withOpeningGreeting, setwithOpeningGreeting] = useState(false);
    const [withClosingGreeting, setwithClosingGreeting] = useState(false);
    const [withSpiiritFooter, setwithSpiiritFooter] = useState(false);
    const [scheduled, setScheduled] = useState({
        enable: '0',
        value: ""
    })

    const handleChangePersonal = (check, val) => {
        if (check) {
            setPersonal((e) => ({
                enable: val,
                value: !val ? [] : e.value
            }))
        } else {
            setPersonal((e) => ({
                ...e,
                value: val
            }))
        }
    }
    const handleChangeRole = (check, val) => {
        if (check) {
            setRole((e) => ({
                enable: val,
                value: !val ? [] : e.value
            }))
        } else {
            setRole((e) => ({
                ...e,
                value: val
            }))
        }
    }
    const handleChangeFungsi = (check, val) => {
        if (check) {
            setFungsi((e) => ({
                enable: val,
                value: !val ? [] : e.value
            }))
        } else {
            setFungsi((e) => ({
                ...e,
                value: val
            }))
        }
    }
    const handleChangeScheduled = (check, val) => {
        if (check) {
            setScheduled((e) => ({
                enable: val,
                value: !val ? "" : e.value
            }))
        } else {
            console.log(val);
            setScheduled((e) => ({
                ...e,
                value: val
            }))
        }
    }
    const handleChangeFile = (check, val) => {
        if (check) {
            if (refFile.current) {
                refFile.current.instance.reset();
            }
            setwithFileAttachment((e) => ({
                enable: val,
                value: ""
            }))
        } else {
            setwithFileAttachment((e) => ({
                ...e,
                value: val
            }))
        }
    }
    function onFileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            handleChangeFile(false, resp.data?.data_filename);
        }
    }
    const resetEditor = () => {
        setPersonal({
            enable: false,
            value: []
        });
        setFungsi({
            enable: false,
            value: []
        });
        setRole({
            enable: false,
            value: []
        });
        setwithFileAttachment({
            enable: false,
            value: ''
        })
        if (refFile.current) {
            refFile.current.instance.reset();
        }
        setMessage("");
        setwithRecipientName(false);
        setwithOpeningGreeting(false);
        setwithClosingGreeting(false);
        setwithSpiiritFooter(false);
        waAction.setClearWa(false);
        setScheduled({
            enable: '0',
            value: ''
        })
    }

    useEffect(() => {
        waAction.getMasterWa();
    }, [])
    useEffect(() => {
        if (waState.clearWa) {
            resetEditor();
        }
    }, [waState.clearWa])
    return (
        <div className='card customShadow'>
            <div className='card-body p-5'>
                <div className='row mx-5'>
                    <div className='col-12 px-1 mb-3'><b>WA Recipient</b></div>
                    <div className='col-12 px-1 mb-3'>
                        <div className='row'>
                            <div className='col-12 col-md-2 col-lg-2'>
                                <div className="form-check mt-1">
                                    <input type="checkbox" className="form-check-input" id="personal"
                                        checked={personal.enable}
                                        onChange={(e) => handleChangePersonal(true, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="personal">Personal</label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <TagBox
                                    value={personal.value}
                                    disabled={!personal.enable}
                                    onValueChange={(e) => handleChangePersonal(false, e)}
                                    dataSource={[...(waState.dataViewWa?.data_users || [])]} searchEnabled={true}
                                    valueExpr={"id"}
                                    displayExpr={"name"} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 px-1 mb-3'>
                        <div className='row'>
                            <div className='col-12 col-md-2 col-lg-2'>
                                <div className="form-check mt-1">
                                    <input type="checkbox" className="form-check-input" id="function"
                                        checked={fungsi.enable}
                                        onChange={(e) => handleChangeFungsi(true, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="function">Group Function</label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <TagBox
                                    value={fungsi.value}
                                    disabled={!fungsi.enable}
                                    onValueChange={(e) => handleChangeFungsi(false, e)}
                                    dataSource={[...(waState.dataViewWa?.data_functions || [])]} searchEnabled={true}
                                    valueExpr={"id"}
                                    displayExpr={"name"} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 px-1 mb-3'>
                        <div className='row'>
                            <div className='col-12 col-md-2 col-lg-2'>
                                <div className="form-check mt-1">
                                    <input type="checkbox" className="form-check-input" id="role"
                                        checked={role.enable}
                                        onChange={(e) => handleChangeRole(true, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="role">Group Role User</label>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <TagBox
                                    value={role.value}
                                    disabled={!role.enable}
                                    onValueChange={(e) => handleChangeRole(false, e)}
                                    dataSource={[...(waState.dataViewWa?.data_roles || [])]} searchEnabled={true}
                                    valueExpr={"id"}
                                    displayExpr={"name"} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 px-1 mb-3'>
                        <div className='row'>
                            <div className='col-12 col-md-2 col-lg-2'>
                                <label className="form-check-label"><b>WA Message</b></label>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-check mb-3">
                                    <input type="checkbox" className="form-check-input" id="rp"
                                        checked={withRecipientName}
                                        onChange={(e) => setwithRecipientName(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="rp">Recipient Name </label>
                                    <span className="hint-form-wa">(Kepada Yth. [Bapak/Ibu] [Nama])</span>
                                </div>
                                <div className="form-check mb-3">
                                    <input type="checkbox" className="form-check-input" id="og"
                                        checked={withOpeningGreeting}
                                        onChange={(e) => setwithOpeningGreeting(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="og">Opening Greeting</label>
                                    <span className="hint-form-wa">(Selamat [Pagi/Siang/Malam])</span>
                                </div>
                                <textarea className='form-control mb-3' rows={5}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                <div className="form-check mb-3">
                                    <input type="checkbox" className="form-check-input" id="file"
                                        checked={withFileAttachment.enable}
                                        onChange={(e) => handleChangeFile(true, e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="file">Image/File Attachment</label>
                                </div>
                                <div style={{ 'marginBottom': '20px' }}>
                                    <FileUploadDx
                                        ref={refFile}
                                        disable={!withFileAttachment.enable}
                                        className="custom-dx-fileuploader-file-name mb-3"
                                        showFileList={true}
                                        multiple={false}
                                        selectButtonText="Select File"
                                        readyToUploadMessage=" "
                                        labelText="or Drop file hire | Max size 10 Mb"
                                        accept="*"
                                        // accept="image/*"
                                        // allowedFileExtensions={ ['.jpg', '.jpeg', '.gif', '.png']}
                                        onUploaded={onFileUploaded}
                                        maxFileSize={10000000}
                                        uploadMode="instantly"
                                    />
                                </div>
                                <div className="form-check mb-3">
                                    <input type="checkbox" className="form-check-input" id="cg"
                                        checked={withClosingGreeting}
                                        onChange={(e) => setwithClosingGreeting(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="cg">Closing Greeting</label>
                                    <span className="hint-form-wa">(Salam, Administrator.)</span>
                                </div>
                                <div className="form-check mb-3">
                                    <input type="checkbox" className="form-check-input" id="sf"
                                        checked={withSpiiritFooter}
                                        onChange={(e) => setwithSpiiritFooter(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="sf">SPIIRIT Footer</label>
                                    <span className="hint-form-wa">(Generated by SPIIRIT - Driving Innovation Excellence)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 px-1 mb-3'>
                        <div className='row'>
                            <div className='col-12 col-md-2 col-lg-2'>
                                <label className="form-check-label"><b>WA Delivery Time</b></label>
                            </div>
                            <div className='col-md-6'>
                                <RadioGrp
                                    value={scheduled.enable}
                                    onChange={(e) => handleChangeScheduled(true, e)}
                                    modeRow={true}
                                    data={
                                        [
                                            { label: 'Now', value: '0' },
                                            { label: 'Scheduled at', value: '1' },
                                        ]
                                    }
                                />
                                {
                                    scheduled.enable === '1' && (
                                        <DateBox
                                            className='mb-5'
                                            min={new Date().getTime()}
                                            type="datetime"
                                            displayFormat="yyyy-MM-dd HH:mm:ss"
                                            // value={}
                                            onValueChanged={(e) => handleChangeScheduled(false, e.value? moment(e.value).format('yyyy-MM-DD-HH-mm-ss') : "")}
                                        />
                                    )
                                }
                                <button className='btn btn-sm btn-success mt-5'
                                    onClick={() => waAction.onSendWa({
                                        cbUserPersonal: personal.enable,
                                        cbGroupRole: role.enable,
                                        cbGroupFunction: fungsi.enable,
                                        listUserPersonal: personal.value,
                                        "listGroupRole": role.value,
                                        "listGroupFunction": fungsi.value,
                                        message: message,
                                        withRecipientName,
                                        withOpeningGreeting,
                                        withClosingGreeting,
                                        withSpiiritFooter,
                                        withFileAttachment: withFileAttachment.enable,
                                        file_name: withFileAttachment.value || '',
                                        withSchedulledTime: scheduled.enable === '1' ? true : false,
                                        schedulled_at: scheduled.value
                                    })}
                                >Kirim</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabWhatsapp;