import React, { useRef, useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FileUploadDx, FormResearch, HeaderList } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import Tags from "@yaireo/tagify/dist/react.tagify";
import { NumberBox } from 'devextreme-react';
import Swal from 'sweetalert2/dist/sweetalert2.js';


function EditKmResearch(props) {
    const tagref = useRef();
    const history = useHistory();
    const { id } = useParams();
    const [state, dispatch, action] = useGlobalState();

    const [dataArea, setDataArea] = useState([]);
    const [dataKateg, setDataKateg] = useState([]);
    const [dataUser, setDataUser] = useState([]);

    const [judul, setJudul] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [area, setArea] = useState('0');
    const [kategori, setKategori] = useState('');
    const [tahun, setTahun] = useState('');
    const [tags, setTags] = useState([]);
    const [tags_delete, setTags_delete] = useState([]);
    const [finalRpt, setFinalRpt] = useState({});
    const [reports, setReports] = useState([]);
    const [reports_delete, setReports_delete] = useState([]);
    const [cover, setCover] = useState({});
    const [leader, setLeader] = useState('');
    const [leaderTemp, setLeaderTemp] = useState('');
    const [member, setMember] = useState([]);
    const [member_delete, setMember_delete] = useState([]);
    const [memberTemp, setMemberTemp] = useState('');

    useEffect(() => {
        getDataArea();
        getDataUser();
        getDataEdit(id);
    }, [])

    function getDataArea() {
        action.getData('/api/param-area', {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                setDataArea(resp?.data);
            }
        })
    }
    function getDataKateg(id) {
        if (id !== '0') {
            action.getData(`/api/param-area-category/${id}`, {}, null, null, true).then((resp) => {
                if (resp?.code === '00') {
                    setDataKateg(resp?.data);
                }
            })
        } else {
            setDataKateg([]);
        }
    }
    function getDataUser() {
        action.getData('/api/param-user', {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                setDataUser(resp?.data);
            }
        })
    }
    function getDataEdit(id) {
        action.getData(`/api/km-research/view/${id}`, {}, null, null, true).then((resp) => {
            if (resp?.code === '00') {
                const { data_research } = resp?.data
                getDataKateg(data_research?.area_research_id);
                setKategori(data_research?.research_category_id);
                setJudul(data_research?.title);
                setDeskripsi(data_research?.description);
                setLeader(data_research?.owner);
                setTahun(data_research?.year_end);
                setArea(data_research?.area_research_id);
                setCover({
                    "name": data_research?.cover_file_name,
                    "file_location": data_research?.cover_file_location,
                    "is_new": false
                })
                setReports([...(data_research?.list_research_reports || [])].map(d => { return { ...d, is_new: false } }))
                setMember([...(data_research?.team_member || [])].map(d => { return { user_id: d?.id, is_new: false } }))
                setTags((typeof data_research?.tag_list == "string" ? data_research?.tag_list?.split(',') : data_research?.tag_list || [])?.map(d => { return { value: d, is_new: false } }))

            }
        })
    }

    function onUploadedFinalRpt(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            // setFinalRpt({
            //     "name": resp.data?.data_filename,
            //     "file_location": resp.data?.data_file_location,
            //     "is_new": true
            // })
            setReports([...reports, {
                "file_name": resp.data?.data_filename,
                "year": '',
                "is_final_report": true,
                "is_new" : true,
                "file_location": resp.data?.data_file_location
            }])
        }
    }
    function onUploadedCover(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setCover({
                "name": resp.data?.data_filename,
                "file_location": resp.data?.data_file_location,
                "is_new": true
            })
        }
    }
    function areaChange(id) {
        setArea(id);
        getDataKateg(id);
    }
    function addMemberClick(val) {
        setMember([...member, {
            user_id: val,
            is_new: true
        }])
    }
    function deleteMember(val) {
        if (val?.is_new === false) {
            setMember_delete([
                ...member_delete,
                val
            ])
        }
        setMember(member.filter(d => d?.user_id !== val?.user_id))
    }
    function deleteReport(val) {
        if (val?.is_new === false) {
            setReports_delete([
                ...reports_delete,
                val
            ])
        }
        setReports(reports.filter(d => d?.file_name !== val?.file_name))
    }
    function deleteTags(val) {
        // console.log(val)
        if (val?.is_new === false) {
            const d = {
                "name": state.formData.deletekeywordTemp?.value,
                "tag_id": '',
                "table_name": '',
                "data_id": '',
                "is_new": state.formData.deletekeywordTemp?.is_new
            };
            setTags_delete([...tags_delete, d])
        }
    }
    function validation(data) {
        var isValid = true;
        var message = [];
        if (data?.title === "") {
            isValid = false;
            message = [...message, "Judul"];
        }
        if (data?.description === "") {
            isValid = false;
            message = [...message, "Deskripsi"];
        }
        if (data?.research_category_id === "") {
            isValid = false;
            message = [...message, "Kategori"];
        }
        if (data?.year_end === "") {
            isValid = false;
            message = [...message, "Tahun Research"];
        }
        if ([...(data?.keyword || [])].length === 0) {
            isValid = false;
            message = [...message, "Tags"];
        }
        if (data?.owner === "") {
            isValid = false;
            message = [...message, "Leader"];
        }
        if ([...(data?.team_members || [])].length === 0) {
            isValid = false;
            message = [...message, "Team member"];
        }
        return {
            isValid,
            message: message.join(', ') + " Tidak boleh kosong"
        }
    }
    function simpanClick() {
        const tmp = tags ? [...((typeof tags === 'string' ? JSON.parse(tags) : tags) || [])] : [];
        const keyword = tmp.map(d => {
            return {
                "name": d.value,
                "tag_id": '',
                "table_name": '',
                "data_id": '',
                "is_new": d?.is_new === false ? d?.is_new : true
            }
        })
        const data = {
            "id": id,
            "title": judul || '',
            "description": deskripsi || '',
            "research_category_id": kategori || '',
            "initiator": 0,
            "owner": leader,
            "year_start": '',
            "year_end": tahun || '',
            "cover_file_name": cover?.name || '',
            "cover_file_location": cover?.file_location || '',
            "status": "",
            "project_code": "",
            "year_ideation": '',
            "baseline_name": '',
            // "file_location": finalRpt?.file_location || '',
            // "file_name": finalRpt?.name || '',
            "reports": reports.map(d => { return { ...d, year: tahun } }),
            "reports_delete": reports_delete.map(d => { return { ...d, year: tahun } }),
            "year": '',
            "is_final_report": true,
            "team_members": member,
            "team_members_delete": member_delete || [],
            "keyword": keyword,
            "keyword_delete": tags_delete || []
        }
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
        // console.log(data)
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update Research? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/km-research/update",
                        data: data,
                        withFile: false
                    }
                ).then((res) => {
                    if (res.code === "00") {
                        history.goBack();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div className='row justify-content-center'>
            <div className='col-md-11'>
                <HeaderList
                    title="Edit Research"
                    someText={"Knowladge Management\\Research\\Add"}
                ></HeaderList>
                <Card
                    className="m-0 p-0  mb-5 customShadow"
                >
                    <Card.Body className="p-4">
                        <FormResearch
                            formLists={[
                                {
                                    rows: 1,
                                    header: true,
                                    name: <>Detail <span className="text-danger">(*)</span></>,
                                    childs: [
                                        {
                                            type: "text",
                                            label: "Judul Research",
                                            value: judul,
                                            onChange: (val) => setJudul(val),
                                            placeholder: "Judul Research",
                                        },
                                        {
                                            label: "Deskripsi",
                                            type: "textarea",
                                            value: deskripsi,
                                            onChange: (val) => setDeskripsi(val),
                                            placeholder: "Deskripsi",
                                        },
                                        {
                                            label: "Area",
                                            type: "custom",
                                            component: (
                                                <select className='form-control form-control-sm mb-5'
                                                    value={area}
                                                    onChange={(e) => areaChange(e.target.value)}
                                                >
                                                    <option value="0" selected> - Pilih Area -</option>
                                                    {
                                                        dataArea.map((d, i) => (
                                                            <option key={i} value={d?.id}>{d?.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            )
                                        },
                                        {
                                            label: "Kategori Research",
                                            type: "custom",
                                            component: (
                                                <select className='form-control form-control-sm mb-5'
                                                    value={kategori}
                                                    onChange={(e) => setKategori(e.target.value)}
                                                >
                                                    <option value="" selected> - Pilih Kategori -</option>
                                                    {
                                                        dataKateg.map((d, i) => (
                                                            <option key={i} value={d?.id}>{d?.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            )
                                        },
                                        {
                                            type: "custom",
                                            label: "Tahun Research",
                                            component: (
                                                <NumberBox min={1950} max={2050} className='form-control form-control-sm mb-5' placeholder='Tahun Research'
                                                    onValueChanged={(e) => setTahun(e.value)}
                                                    value={tahun}
                                                />
                                            )
                                        },
                                        {
                                            label: <>Tags</>,
                                            type: "custom",
                                            component: (
                                                <Tags
                                                    tagifyRef={tagref}
                                                    className="mb-5"
                                                    placeholder="Masukan Tag dan tekan tab"
                                                    onChange={(e) => setTags(e.detail.value)}
                                                    onRemove={(e) => deleteTags(e.detail.data)}
                                                    value={tags}
                                                />
                                            )
                                        },
                                        {
                                            type: "custom",
                                            label: "Final Report",
                                            component: (
                                                <div>
                                                    <FileUploadDx
                                                        className="custom-dx-fileuploader-file-name"
                                                        showFileList={true}
                                                        multiple={false}
                                                        selectButtonText="Select File"
                                                        readyToUploadMessage=" "
                                                        labelText="or Drop file hire | Max size 10 Mb"
                                                        accept="*"
                                                        onUploaded={onUploadedFinalRpt}
                                                        maxFileSize={10000000}
                                                        uploadMode="instantly"
                                                    />
                                                    <div>
                                                        {
                                                            reports && (
                                                                <ul>
                                                                    {
                                                                        reports.map((d, i) => (
                                                                            <li>
                                                                                <div className="d-flex flex-row justify-content-between mb-1">
                                                                                    <span className="pl-2" style={{ fontSize: '8pt' }}>{d?.file_name}</span>
                                                                                    <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger" onClick={() => deleteReport(d)}></i>
                                                                                </div>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            type: "custom",
                                            label: "Cover Research",
                                            component: (
                                                <div>
                                                    <FileUploadDx
                                                        className="custom-dx-fileuploader-file-name"
                                                        showFileList={true}
                                                        multiple={false}
                                                        selectButtonText="Select File"
                                                        readyToUploadMessage=" "
                                                        labelText="or Drop file hire | Max size 10 Mb"
                                                        accept="image/*"
                                                        onUploaded={onUploadedCover}
                                                        maxFileSize={10000000}
                                                        uploadMode="instantly"
                                                    />
                                                    <div>{cover?.name}</div>
                                                </div>
                                            )
                                        },
                                    ]
                                },
                                {
                                    rows: 2,
                                    header: true,
                                    name: <>Lead Researcher <span className="text-danger">(*)</span></>,
                                    childs: [
                                        {
                                            type: "custom",
                                            withLabel: false,
                                            component: (
                                                <div>
                                                    <div className='row mb-5'>
                                                        <div className='col-9'>
                                                            <select className='form form-control sm'
                                                                value={leaderTemp}
                                                                onChange={(e) => setLeaderTemp(e.target.value)}
                                                            >
                                                                <option value=""> - Pilih Lead Researcher -</option>
                                                                {
                                                                    dataUser.map((d, i) => (
                                                                        <option key={i} value={d?.id}>{d?.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className='col-3'>
                                                            <button className='btn btn-sm btn-outline-primary'
                                                                onClick={() => setLeader(leaderTemp)}
                                                            >
                                                                <i className='fa fa-plus icon-nm'></i>
                                                                Select
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-5'>
                                                        <div className='col-12'>
                                                            {
                                                                leader && (
                                                                    <ul>
                                                                        <li>
                                                                            {dataUser.find(x => x.id === parseInt(leader, 0))?.name}
                                                                        </li>
                                                                    </ul>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        },
                                    ]
                                },
                                {
                                    rows: 2,
                                    header: true,
                                    name: <>Team Member <span className="text-danger">(*)</span></>,
                                    childs: [
                                        {
                                            type: "custom",
                                            withLabel: false,
                                            component: (
                                                <div>
                                                    <div className='row mb-5'>
                                                        <div className='col-9'>
                                                            <select className='form form-control sm'
                                                                value={memberTemp}
                                                                onChange={(e) => setMemberTemp(e.target.value)}
                                                            >
                                                                <option value="" selected> - Pilih Team Member dan tekan add -</option>
                                                                {
                                                                    dataUser.map((d, i) => (
                                                                        <option key={i} value={d?.id}>{d?.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className='col-3'>
                                                            <button className='btn btn-sm btn-outline-primary'
                                                                onClick={() => addMemberClick(memberTemp)}
                                                            >
                                                                <i className='fa fa-plus icon-nm'></i>
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-5'>
                                                        <div className='col-12'>
                                                            {
                                                                member && (
                                                                    <ul>
                                                                        {
                                                                            member.map((d, i) => (
                                                                                <li>
                                                                                    <div className="d-flex flex-row justify-content-between mb-1">
                                                                                        <span className="pl-2" style={{ fontSize: '8pt' }}>{dataUser.find(x => x.id === parseInt(d?.user_id, 0))?.name}</span>
                                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger" onClick={() => deleteMember(d)}></i>
                                                                                    </div>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    ]
                                }
                            ]}
                        />
                        <hr />
                        <div className='text-right'>
                            <button className='btn btn-sm btn-secondary mr-1'
                                onClick={() => history.goBack()}
                            >
                                Kembali
                            </button>
                            <button className='btn btn-sm btn-primary'
                                onClick={() => simpanClick()}
                            >
                                <i className='fa fa-save icon-nm'></i>
                                Simpan
                            </button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default EditKmResearch;