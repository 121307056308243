import React, { useEffect, useState } from 'react';
import { HeaderList } from '../../components';
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';
import { useParams } from 'react-router-dom';
import { pathFile } from '../../app.config';
import moment from 'moment';
import { isValidHttpUrl } from '../../components/utility/utility';

function EventDetail(props) {
    const history = useHistory()
    const [state, dispatch, action] = useGlobalState();
    const param = useParams();
    const [data, setData] = useState();
    const [data_page, setData_page] = useState({});

    useEffect(() => {
        if (param?.id) {
            action.getData(`/api/people-event/view?id=${param?.id}`, {}, null, null, true).then((resp) => {
                if (resp.code === '00') {
                    const dataObj = Array.isArray(resp.data.data_event) ? { ...resp.data.data_event[0] } : { ...resp.data.data_event }
                    setData(dataObj);
                    setData_page(resp.data_page);
                }
            })
        }
    }, [])
    return (
        <div>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            >

            </HeaderList>

            <Card
                className="mx-0 mt-0 p-0 customShadow"
                style={{marginBottom:'40px'}}
            >
                <Card.Body className="p-4">
                    <div class="row">
                        <div class="col-md-4 mb-5">
                            <div class="pl-5 pr-5">
                                <img style={{ width: '100%', height: 250, objectFit: 'contain', borderRadius: '3%' }} className="bg-white border" src={data?.poster_location}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/no_image.jpg" }}
                                />
                            </div>
                        </div>
                        <div class="col-md-7 mb-5">
                            <h3 className="font-bold">{data?.name}</h3>
                            <br />
                            <p>Deskripsi</p>

                            <p className="font-bold">{data?.description}</p>
                            <hr />
                            <div className="row mb-5">
                                <div className="col-md-4">
                                    <div className='mb-5'>
                                        <span className="d-block mb-1">Creator</span>
                                        <span><strong>{data?.creator_name}</strong></span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-1">Date</span>
                                        <span><strong>{moment(data?.date_start).format('YYYY-MM-DD')}</strong></span>
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-1">Time</span>
                                        <span><strong>{data?.time_start} - {data?.time_end}</strong></span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-5'>
                                        <span className="d-block mb-1">Venue</span>
                                        {
                                            isValidHttpUrl(data?.venue) ? (
                                                <a href={data?.venue} target={'_blank'}>
                                                    <span><strong>{data?.venue}</strong></span>
                                                </a>
                                            ) : (
                                                <span className='text-wrap'>{data?.venue}</span>
                                            )
                                        }
                                    </div>
                                    <div className='mb-5'>
                                        <span className="d-block mb-1">Organizer</span>
                                        <div><strong>{data?.organizer}</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer" >
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        {
                            history.length > 1 && (
                                <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventDetail;