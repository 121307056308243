import React, { useEffect, useState } from 'react';
import { GridDx, Modal } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import { Button, Column } from 'devextreme-react/data-grid';
import DropDownButton from "devextreme-react/drop-down-button";
import { Dropdown } from 'react-bootstrap';
import ModalKerjasamaDetailEdit from './modalKerjasamaDetailEdit';
import Popup, { Position } from 'devextreme-react/popup';
import './kerjasamaCRUD.css';
import overlay from 'devextreme/ui/overlay';
import useFetch from '../../modules/useFetch';

const column = [
    { fieldName: "created_at", caption: "Date Created", allowFiltering: false },
    { fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", allowFiltering: false },
    { fieldName: "status_dokumen_name", caption: "Status", allowFiltering: false },
    { fieldName: "berlaku_start", caption: "Mulai Berlaku", allowFiltering: false },
    { fieldName: "berlaku_end", caption: "Sampai Dengan", allowFiltering: false },
    // { fieldName: "rkap", caption: "Related RKAP", allowFiltering: false },
    { fieldName: "file_name", caption: "Dokumen", allowFiltering: false },

]
function KerjasamaGridDetail(props) {
    const [state, dispatch, action] = useGlobalState();
    const [ds, setDs] = useState([...(props?.data?.data?.kerjasama_dokumens)]);
    const [vis, setVis] = useState(false);
    const [id, setId] = useState(props.data.data?.id);
    const [modeEdit, setModeEdit] = useState('Add');
    const [dataEdit, setDataEdit] = useState({});
    const { doGet } = useFetch();
    const [dataDetail, setDataDetail] = useState({});

    useEffect(() => {
        if (props.data.data?.id) {
            setId(props.data.data?.id);
            // getData(props.data.data?.id);
            overlay.baseZIndex(1050);
        }
    }, [props.data.data?.id])
    function getData(id) {
        doGet({ url: `/api/ext-partnership/view/${id}` }).then((resp) => {
            if (resp.code === '00') {
                setDataDetail(resp.data);
                setDs(resp.data.tabel_dokumen);
            }
        })
    }
    function customRender(e) {
        if (e.column.name === "rkap") {
            return (
                <span className="text-wrap">{e.value}</span>
                // <ul>
                //     {
                //         [...(e.value?.split(';') || [])].map((d, i) => (
                //             <Link key={i} to={`/OngoingResearch/view/${d?.research_id}`} target={'_blank'}>
                //                 <li className="text-wrap p-2">{d.show_research}</li>
                //             </Link>
                //         ))
                //     }
                // </ul>
            )
        } else if (e.column.name === 'file_name') {
            return (
                <span className="text-wrap">{e.value}</span>
                // <DropDownButton
                //     text=""
                //     className="btn btn-sm btn-outline-primary rounded custom-dropdown-download p-0"
                //     stylingMode="text"
                //     icon="fa fa-download icon-sm m-0 p-0"
                //     showArrowIcon={false}
                //     dropDownOptions={{ width: 250 }}
                //     displayExpr="file_name"
                //     keyExpr="id"
                //     noDataText="No File"
                //     itemRender={(e) => {
                //         return (
                //             <a
                //                 href={dataDetail?.base_url + e.file_name_url}
                //                 target="_blank" download
                //                 rel="noopener noreferrer"
                //             >
                //                 <span>{e.file_name}</span>
                //             </a>
                //         )
                //     }}
                //     items={[...(e.value || [])].filter(d => d?.file_name_url !== '' && d?.file_name_url !== null)}
                // />
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function editBtn(data) {
        return (
            <button className="btn btn-sm btn-outline-warning text-center py-1 px-2 mx-1" onClick={() => { setModeEdit('Edit'); setDataEdit(data?.data); action.toggleModal('modalDetailEdit') }}>
                <i className="fa fa-pencil-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function deleteBtn(data) {
        return (
            <button className="btn btn-sm text-center py-1 px-2 mx-1 btn-outline-danger" onClick={() => deleteClick(data?.data?.id)}>
                <i className="fa fa-trash-alt icon-sm m-0 p-0"></i>
            </button>
        )
    }
    function headerCellRender(e) {
        return <p style={{ fontWeight: 'bolder', fontSize: '9pt', fontFamily: 'Poppins' }} className="text-wrap">{e.column.caption}</p>;
    }
    function saveData(data) {
        // console.log(data);
        if (modeEdit === 'Add') {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Add Dokumen?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/ext-partnership/store-dokumen",
                            data: data,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal('modalDetailEdit');
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
        if (modeEdit === 'Edit') {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Update Dokumen?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: "/api/ext-partnership/update-dokumen",
                            data: data,
                            withFile: false
                        }
                    ).then((resp) => {
                        if (resp.code === "00") {
                            action.toggleModal('modalDetailEdit');
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function deleteClick(id_dokumen) {
        if (id_dokumen) {
            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-danger mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                    '<strong>Delete Document?</strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/ext-partnership/delete-dokumen?id=${id_dokumen}`,
                        }
                    ).then((resp) => {
                        if (resp.code === '00') {
                            getData(id);
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function addClick() {
        setModeEdit('Add');
        setDataEdit({});
        action.toggleModal('modalDetailEdit')
    }
    return (
        <React.Fragment>
            {/* <Modal
                name="modalDetailEdit"
                baseColor={true}
            >
                <ModalKerjasamaDetailEdit mode={modeEdit} data={dataEdit} id_kerjasama={id} onSave={saveData} />
            </Modal> */}
            {/* <Popup
                className="detail-popup mb-5"
                showTitle={false}
                width='100vh'
                height='auto'
                closeOnOutsideClick={false}
                visible={state.modal.modalDetailEdit}
                shading={true}
                shadingColor='transparent'
                onHiding={() => action.toggleModal('modalDetailEdit')}
            >
                <ModalKerjasamaDetailEdit mode={modeEdit} data={dataEdit} id_kerjasama={id} onSave={saveData} />
            </Popup> */}
            <div className="p-5">
                <div className="row mb-3">
                    <div className="col-md-6 text-left">
                        <h5>Detail Partnership</h5>
                    </div>
                    {/* <div className="col-md-6 text-md-right">
                        <button className="btn btn-sm btn-primary" onClick={() => addClick()}>+ Dokumen</button>
                    </div> */}
                </div>
                <GridDx
                    //loading={state.loadingDetail}
                    className="custom-style"
                    columns={column}
                    data={ds}
                    cellRender={customRender}
                >
                    {/* <Column caption="Action" type="buttons" headerCellRender={headerCellRender} >
                        <Button name="customEdit" visible={true} render={editBtn} />
                        <Button name="delete" visible={true} render={deleteBtn} />
                    </Column> */}
                </GridDx>
            </div>
        </React.Fragment>
    );
}

export default KerjasamaGridDetail;