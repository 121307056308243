import { DataGrid, NumberBox, TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { GridDrawdown, SkeletonLoading, GridDrawdownRevise } from '../../components/index';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { useGlobalState } from '../../contexts/useGlobalState';

const format = "#,##0.##";

const TabBudget = ({ param, addTimeline }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const temp = pathname.slice(1, pathname.length);
    const path = temp.substr(0, temp.indexOf('/'));
    const [state, dispatch, action] = useGlobalState();
    const column = [
        { "dataField": "description", "caption": "Description", "dataType": "string", minWidth: 300, },
        { "dataField": "cost_element_id", "caption": "Cost Element", "custom": true, "type": "lookup", "dSource": state.cost_element, "displayExpr": "name", "valueExpr": "id", "visible": true, "dataType": "string", width: 150 },
        { "dataField": "plan_jan", "caption": "JAN", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_feb", "caption": "FEB", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_mar", "caption": "MAR", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_apr", "caption": "APR", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_may", "caption": "MAY", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_jun", "caption": "JUN", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_jul", "caption": "JUL", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_aug", "caption": "AUG", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_sep", "caption": "SEP", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_oct", "caption": "OCT", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_nov", "caption": "NOV", "visible": true, "format": format, "dataType": "number", width: 65 },
        { "dataField": "plan_dec", "caption": "DEC", "visible": true, "format": format, "dataType": "number", width: 65 }
    ]

    useEffect(() => {
        if (param.id === null) {
            history.push("/error-page")
        } else {
            getData();
            action.getData("/api/param-cost-element", {}, "cost_element").then((resp) => {
                if (resp.code === "00") {
                    dispatch({
                        type: "getSuccess",
                        payload: { cost_element: resp.data, loadingGet: false }
                    })
                }
            })
        }
    }, [])
    function getData() {
        action.getData("/api/project-portfolio-crud/view-budget/" + param.id, {}, "data_view_budget", 'loadingBudget', true).then((resp) => {
            if (resp.code === "00") {
                action.setForm("dataSourceBudget", resp.data.data_view_budget)
                action.setForm('portfolio_data_page', resp.data_page);
            }
        })
    }
    function DefaultTable() {
        const caption = ["Description", "Cost Element", "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
        return (
            <table className="table">
                <thead>
                    <tr>
                        {
                            caption.map((cap, i) => (
                                <th className="customTableBorderRowCol customTableBorderHeader" key={i}>{cap}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="customTableBorderRowCol" colSpan={14} style={{ height: '200px', verticalAlign: 'middle', textAlign: 'center' }}>
                            <button className="btn btn-sm btn-primary" onClick={addTimeline}><i className="fa fa-plus icon-nm mx-1"></i> Add Project Timeline</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
    function editCellTemplate(props) {
        //console.log('propsss', props)
        //var disable = props.data.type === 'task' ? true : false;
        const actual = {
            plan_jan: 'actual_jan',
            plan_feb: 'actual_feb',
            plan_mar: 'actual_mar',
            plan_apr: 'actual_apr',
            plan_may: 'actual_may',
            plan_jun: 'actual_jun',
            plan_jul: 'actual_jul',
            plan_aug: 'actual_aug',
            plan_sep: 'actual_sep',
            plan_oct: 'actual_oct',
            plan_nov: 'actual_nov',
            plan_dec: 'actual_dec',
        }
        // if (props.data.type === 'task' && (
        //     props.column.name === 'plan_jan' ||
        //     props.column.name === 'plan_feb' ||
        //     props.column.name === 'plan_mar' ||
        //     props.column.name === 'plan_apr' ||
        //     props.column.name === 'plan_may' ||
        //     props.column.name === 'plan_jun' ||
        //     props.column.name === 'plan_jul' ||
        //     props.column.name === 'plan_aug' ||
        //     props.column.name === 'plan_sep' ||
        //     props.column.name === 'plan_oct' ||
        //     props.column.name === 'plan_nov' ||
        //     props.column.name === 'plan_dec'
        // )) {
        //     props.setValue(null)
        // }
        return (
            <>
                {
                    props.column.name === "description" && (
                        <TextBox defaultValue={props.value} onValueChanged={(e) => props.setValue(e.value)} />
                    )

                }
                {
                    (props.column.name === 'plan_jan' ||
                        props.column.name === 'plan_feb' ||
                        props.column.name === 'plan_mar' ||
                        props.column.name === 'plan_apr' ||
                        props.column.name === 'plan_may' ||
                        props.column.name === 'plan_jun' ||
                        props.column.name === 'plan_jul' ||
                        props.column.name === 'plan_aug' ||
                        props.column.name === 'plan_sep' ||
                        props.column.name === 'plan_oct' ||
                        props.column.name === 'plan_nov' ||
                        props.column.name === 'plan_dec') && (
                        <div>
                            <NumberBox className='border border-primary' min={0} format="#,##0.##" defaultValue={props.value} onValueChanged={(e) => props.setValue(e.value)} />
                            <span className="d-block  p-2 bgTaskActual" style={{ height: '32px' }}>{props.data[actual[props.column.name]] || " "}</span>
                        </div>
                    )
                }
            </>
        )
    }
    function cellRender(e) {
        const actual = {
            plan_jan: 'actual_jan',
            plan_feb: 'actual_feb',
            plan_mar: 'actual_mar',
            plan_apr: 'actual_apr',
            plan_may: 'actual_may',
            plan_jun: 'actual_jun',
            plan_jul: 'actual_jul',
            plan_aug: 'actual_aug',
            plan_sep: 'actual_sep',
            plan_oct: 'actual_oct',
            plan_nov: 'actual_nov',
            plan_dec: 'actual_dec',
        }
        if (e.column.name === "no") {
            return (
                <span className="d-block text-wrap textContent m-2">{e.rowIndex + 1}</span>
            )
        } else if (e.column.name === "description") {
            return (
                <span className="d-block text-wrap textContent m-2">{e.value}</span>
            )
        }
        else if (e.column.name === "cost_element_id") {
            return (
                <div>
                    <span style={{ minHeight: '43px', padding: '7px' }} className="d-block text-wrap textContent">{e.data.cost_element_name}</span>
                    {/* <span style={{ minHeight: '30px', padding: '7px' }} className="d-block text-wrap textContent bgTaskActual">Realisasi</span> */}
                </div>
            )
        }
        else {
            return (
                <div>
                    <span style={{ minHeight: '43px', padding: '7px' }} className="d-block text-wrap textContent bgTaskPlan">{e.text}</span>
                    <span style={{ minHeight: '30px', padding: '7px' }} className="d-block text-wrap textContent bgTaskActual">{e.data[actual[e.column.name]] ? "" + e.data[actual[e.column.name]] : " "}</span>
                </div>
            )
        }
    }
    return (
        <>
            <div className="row" style={{ paddingLeft: 15, paddingRight: 15 }}>
                <div className="col">
                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>Drawdown Budget(Anggaran Biaya Operasi)</p>
                    <p><i className="fa fa-circle icon-sm"></i> Nominal anggaran yang tertera bernilai $(Ribu USD)</p>
                    <p><i className="fa fa-circle icon-sm"></i> Kurs untuk tahun 2024: Rp 14.500</p>
                    <p><i className="fa fa-circle icon-sm"></i> Kurs untuk tahun 2025: Rp 15.046</p>
                </div>
            </div>
            {
                [...(state.formData.dataSourceBudget || [])].length === 0 ? (
                    <DefaultTable />
                ) :
                    [...(state.formData.dataSourceBudget || [])].map((e, i) => {
                        return (
                            <React.Fragment key={i}>
                                <div className="row" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: '35px' }}>
                                    <div className="col">
                                        <span>{e.year}</span>
                                    </div>
                                </div>
                                <hr style={{ marginRight: 5, marginLeft: 5 }} />
                                <div className="row" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="col">
                                        {
                                            state.loadingBudget ? (
                                                <SkeletonLoading type="table" rows={5} cols={column.length} />
                                            ) : (
                                                state.data_view_header?.workflow_state_name === 'monitoring_revise_portfolio' ? (
                                                    <GridDrawdownRevise
                                                        dataSource={[...(e.data || [])]}
                                                        key={i}
                                                        editCellTemplate={editCellTemplate}
                                                        cellRender={cellRender}
                                                        year={e.year}
                                                        baseline_drawdown_budget_id={e.baseline_drawdown_budget_id}
                                                        onRefresh={() => getData()}
                                                        allowAdding={!state.formData.disable}
                                                        allowDeleting={!state.formData.disable}
                                                        allowEditing={!state.formData.disable}
                                                        columns={column}
                                                        showTotalActual={true}
                                                    />
                                                ) : (
                                                    <GridDrawdown
                                                        dataSource={[...(e.data || [])]}
                                                        key={i}
                                                        year={e.year}
                                                        baseline_drawdown_budget_id={e.baseline_drawdown_budget_id}
                                                        onRefresh={() => getData()}
                                                        allowAdding={!state.formData.disable}
                                                        allowDeleting={!state.formData.disable}
                                                        allowEditing={!state.formData.disable}
                                                        columns={column}
                                                    />
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })
            }

        </>
    )
}
export default TabBudget;
