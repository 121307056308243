import { Button, Template, TextBox } from 'devextreme-react';
import React, { useState, useRef } from 'react';
import { Card, Col, InputGroup, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './changePassword.css';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';
import Form, {
    ButtonItem,
    Item,
    GroupItem,
    SimpleItem,
    Label,
    CompareRule,
    EmailRule,
    PatternRule,
    RangeRule,
    RequiredRule,
    StringLengthRule,
    AsyncRule,
    ButtonOptions,
} from 'devextreme-react/form';
import { useGlobalState } from '../../contexts/useGlobalState';

function ChangePassword(props) {
    const history = useHistory();
    const [state, dispatch, action] = useGlobalState();
    const [prPass, setPrePAss] = useState(false);
    const [nwPass, setNewPAss] = useState(false);
    const [coPass, setConfPAss] = useState(false);
    const [prPassStr, setPrPAssStr] = useState('');
    const [nwPassStr, setNewPAssStr] = useState('');
    const [coPassStr, setConfPAssStr] = useState('');
    const formData = {
        password_lama: '',
        password_baru: '',
        password_confirm: ''
    }
    const refForm = useRef();


    function onSubmit(e) {
        e.preventDefault();
        // console.log(formData)
    }
    function passwordComparison(e) {
        return formData.password_baru
    }
    const opt1 = {
        mode: prPass === true ? 'password' : 'text',
        buttons: [{
            name: 'trash',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: prPass === true ? 'fa fa-eye' : 'far fa-eye-slash',
                onClick: () => setPrePAss(!prPass),
            },
        }],
    };
    const opt2 = {
        mode: nwPass === true ? 'password' : 'text',
        buttons: [{
            name: 'trash',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: nwPass === true ? 'fa fa-eye' : 'far fa-eye-slash',
                onClick: () => setNewPAss(!nwPass),
            },
        }],
    };
    const opt3 = {
        mode: coPass === true ? 'password' : 'text',
        buttons: [{
            name: 'trash',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: coPass === true ? 'fa fa-eye' : 'far fa-eye-slash',
                onClick: () => setConfPAss(!coPass),
            },
        }],
    };

    function simpanClick() {
        const data = {
            password_lama: prPassStr || '',
            password_baru: nwPassStr || '',
            password_confirm: coPassStr || ''
        }
        const validate = validation(data);
        if (validate.isValid === false) {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Ubah Password? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    action.postData(
                        {
                            url: `/api/people-hrd/change-password?id_user=${state.dataUser?.id}&old_password=${data?.password_lama}&new_password=${data?.password_baru}&new_password_2=${data?.password_confirm}
                            `,
                            data: {},
                            withFile: false
                        }
                    ).then((res) => {
                        if (res.code === "00") {
                            history.replace('/');
                        }
                    })
                    return true;
                } else {
                    return true;
                }
            })
        }

    }
    function validation(data) {
        var isValid = true;
        var message = [];
        var messageConfirm = '';
        if (data?.password_lama === '') {
            isValid = false;
            message = [...message, "Password lama"];
        }
        if (data?.password_baru === '') {
            isValid = false;
            message = [...message, "Password baru"];
        }
        if (isValid) {
            if (data?.password_baru !== data?.password_confirm) {
                isValid = false;
                messageConfirm = 'Password confirm tidak sesuai'
            }
        }
        return {
            isValid,
            message: (message.length > 0 ? message.join(', ') + " Tidak Boleh Kosong" : '') + (message.length > 0 && messageConfirm ? ' dan ' : '') + messageConfirm
        }
    }
    return (
        <>
            <Card
                className="p-0 customShadow mb-3"
            >
                <Card.Body className="p-5">
                    <h5>Change Password</h5>
                    <p className="text-dark-50">Ubah Password</p>
                    <hr />
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            {/* <form id="form" action='' onSubmit={onSubmit}>
                                <Form
                                    ref={refForm}
                                    formData={formData}
                                >
                                    <GroupItem colSpan={2}>
                                        <SimpleItem dataField={'password_lama'} editorType="dxTextBox"
                                            editorOptions={opt1}
                                        >
                                            <Label text="Password lama" />
                                            <RequiredRule message="Password lama tidak boleh kosong" />
                                        </SimpleItem>
                                        <SimpleItem dataField={'password_baru'} editorType="dxTextBox"
                                            editorOptions={opt2}
                                        >
                                            <Label text="Password baru" />
                                            <RequiredRule message="Password baru tidak boleh kosong" />
                                        </SimpleItem>
                                        <SimpleItem dataField={'password_confirm'} editorType="dxTextBox"
                                            editorOptions={opt3}
                                        >
                                            <Label text="Ulangi password baru" />
                                            <RequiredRule message="Confirm Password is required" />
                                            <CompareRule
                                                message="Password baru and Confirm Password tidak sama"
                                                comparisonTarget={passwordComparison}
                                            />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem
                                        colCount={4}

                                    >
                                        <SimpleItem></SimpleItem>
                                        <SimpleItem></SimpleItem>
                                        <ButtonItem horizontalAlignment="right"
                                            className='bg-secondary'
                                            buttonOptions={{
                                                width: '100%',
                                                text: 'Kembali',
                                                type: 'normal',
                                                useSubmitBehavior: false,
                                            }}
                                        />
                                        <ButtonItem horizontalAlignment="right"
                                            buttonOptions={{
                                                width: '100%',
                                                text: 'Simpan',
                                                type: 'default',
                                                useSubmitBehavior: true,
                                            }}
                                        />
                                    </GroupItem>
                                    <GroupItem

                                        colCount={2}
                                    >                                        
                                    </GroupItem>
                                </Form>
                            </form> */}
                            <div className='row  mb-2'>
                                <div className='col-4'>
                                    <span>Password Lama</span>
                                </div>
                                <div className='col-8'>
                                    <input
                                        onChange={(e) => setPrPAssStr(e.target.value)}
                                        value={prPassStr}
                                        autocomplete="off"
                                        id="password"
                                        type={prPass ? 'text' : 'password'}
                                        className="form-control form-control-sm form-login"
                                        name="password"
                                        placeholder="******"
                                        required
                                    />
                                    <span className="showpass-change" onClick={() => setPrePAss(!prPass)}><i className={prPass === true ? 'far fa-eye-slash icon-nm' : 'far fa-eye icon-nm'}></i></span>
                                </div>
                            </div>
                            <div className='row  mb-2'>
                                <div className='col-4'>
                                    <span>Password Baru</span>
                                </div>
                                <div className='col-8'>
                                    <input
                                        onChange={(e) => setNewPAssStr(e.target.value)}
                                        value={nwPassStr}
                                        autocomplete="off"
                                        id="password"
                                        type={nwPass ? 'text' : 'password'}
                                        className="form-control form-control-sm form-login"
                                        name="password"
                                        placeholder="******"
                                        required
                                    />
                                    <span className="showpass-change" onClick={() => setNewPAss(!nwPass)}><i className={nwPass === true ? 'far fa-eye-slash icon-nm' : 'far fa-eye icon-nm'}></i></span>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-4'>
                                    <span>Ulangi password baru</span>
                                </div>
                                <div className='col-8'>
                                    <input
                                        onChange={(e) => setConfPAssStr(e.target.value)}
                                        value={coPassStr}
                                        autocomplete="off"
                                        id="password"
                                        type={coPass ? 'text' : 'password'}
                                        className="form-control form-control-sm  form-login"
                                        name="password"
                                        placeholder="******"
                                        required
                                    />
                                    <span className="showpass-change" onClick={() => setConfPAss(!coPass)}><i className={coPass === true ? 'far fa-eye-slash icon-nm' : 'far fa-eye icon-nm'}></i></span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 text-right'>
                                    <button className='btn btn-sm btn-secondary mr-2'
                                        onClick={() => history.goBack()}
                                    >Kembali</button>
                                    <button className='btn btn-sm btn-primary'
                                        onClick={() => simpanClick()}
                                    >
                                        <i className='fa fa-save icon-nm'></i>
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Card.Body>
            </Card>

        </>
    );
}

export default ChangePassword;