import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { useHistory } from 'react-router-dom';
import { GridDx, Modal } from '../../components';
import { formatter, generate_year } from '../../components/utility/utility';
import ModalEditingEquipment from '../crudEquipment/modalEditingMaster';
import useCrudEquipment from '../crudEquipment/useCrudEquipment';
import EquipmentGridDetail from './equipmentGridDetail';
import useEquipment from './useEquipment';

const column_table = [
    { fieldName: 'nomor', caption: 'Nomor Asset', width: 150 },
    { fieldName: 'nama', caption: 'Nama Alat', width: 300 },
    { fieldName: 'lab', caption: 'LAB', width: 100 },
    { fieldName: 'status_equipment', caption: 'Status Expired', width: 100 },
    { fieldName: 'tanggal_selesai', caption: 'Masa berlaku status', width: 150 },
    { fieldName: 'status_name_01', caption: '1', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_02', caption: '2', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_03', caption: '3', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_04', caption: '4', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_05', caption: '5', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_06', caption: '6', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_07', caption: '7', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_08', caption: '8', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_09', caption: '9', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_10', caption: '10', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_11', caption: '11', allowFiltering: true, width: 50 },
    { fieldName: 'status_name_12', caption: '12', allowFiltering: true, width: 50 },
]
const column_table_ext = [
    { fieldName: 'nomor', caption: 'Nomor Alat' },
    { fieldName: 'nama', caption: 'Nama Alat' },
    { fieldName: 'nama_tipe', caption: 'Tipe Alat', allowFiltering: false },
    { fieldName: 'merk', caption: 'Merk', allowFiltering: false },
    { fieldName: 'harga_alat', caption: 'Harga (USD)', allowFiltering: false },
    { fieldName: 'tahun_pembelian', caption: 'Tahun Pembelian', allowFiltering: false },
    { fieldName: 'usia', caption: 'Umur (Tahun)', allowFiltering: false },
    { fieldName: 'nama_lab', caption: 'Lab', allowFiltering: false },
    { fieldName: 'nama_lokasi', caption: 'Lokasi', allowFiltering: false },
    { fieldName: 'nama_PIC', caption: 'PIC', allowFiltering: false },
    { fieldName: 'nama_status', caption: 'Status Terakhir', allowFiltering: false },
    { fieldName: 'show_end', caption: 'Sampai Dengan', allowFiltering: false },
    { fieldName: 'biaya_total', caption: 'Biaya Perbaikan / Pemeliharaan (USD)', allowFiltering: false },
]
const ListEquipment = ({ editing = false }, props) => {
    const { eqAction, eqState } = useEquipment();
    const { ceqAction, ceqState } = useCrudEquipment();
    const history = useHistory();
    const tableRef = useRef();

    useEffect(() => {
        ceqAction.setEditingH(editing);
        ceqAction.setEditingL(editing);
        eqAction.getTable();
    }, [])

    function searchChange(value) {
        if (tableRef.current) {
            tableRef.current.instance.searchByText(value);
        }
    }

    return (
        <>
            <Modal
                title={`${ceqState.modeEditEq} Equipment`}
                name={'modalEquipment'}
                baseColor={true}
            >
                <ModalEditingEquipment mode={ceqState.modeEditEq} id_edit={ceqState.id_edit} />
            </Modal>
            <div className="row">
                <div className="col-12">

                    <Card
                        className="m-0 p-0 mb-3 customShadow bg-primary"
                    >
                        <Card.Body className="p-2">
                            <div className="row" style={{ fontSize: '8pt' }}>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex border-right-custom">
                                        <div>
                                            <div className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                                <SVG src="/images/penandruller.svg" />
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Total Alat</span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_total_alat}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_total_harga || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-secondary" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">No Status </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_NST}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_NST || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block  header-total">Idle</span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_IDL}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_IDL || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Maintenance </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_MTN}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_MTN || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-success" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Ready </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_RDY}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_RDY || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block header-total">Broken </span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_BKN}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_BKN || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-2">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block  header-total">UPP/Dumped</span>
                                            <span className="header-invest">/ Nilai Investasi</span>
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_UPP}</strong></span>
                                                <span className="header-invest">${formatter.format(eqState.dataHeader?.data_harga_UPP || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg col-md col-6 mb-5">
                                    <div className="d-flex">
                                        <div>
                                            <div className="shadow rounded mt-1 bg-red-dark" style={{ width: 15, height: 15, border: "0.5px solid white" }}>
                                            </div>
                                        </div>
                                        <div className="pl-3 text-white">
                                            <span className="d-block  header-total">Expired </span>
                                            {/* <span className="header-invest">/ Nilai Investasi</span> */}
                                            <div className="pt-3">
                                                <span className="d-block header-total"><strong>{eqState.dataHeader?.data_jumlah_EXP}</strong>
                                                    {/* <span className="header-invest"> / {formatter.format(eqState.dataHeader?.data_harga_EXP || 0)} $</span> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-2">
                            <div className="row mt-5 mb-5">
                                <div className="col-md-3 mb-5">
                                    <div className="formSearch">
                                        <input className="form-control form-control-sm" placeholder="Cari" onChange={(e) => searchChange(e.target.value)} />
                                        <span className="formSearchIcon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                                <div className="col-md-9 mb-5">
                                    <div className="d-flex align-items-center justify-content-md-end justify-content-sm-center">
                                        <div className="containerGroup">
                                            <span className="m-0 p-0">Histori Status Alat Pada Tahun</span>
                                        </div>
                                        <div className="pl-md-2">
                                            <select className="form-control form-control-sm d-flex"
                                                value={eqState.dataTable?.data_tahun}
                                                onChange={(e) => eqAction?.getTable(e.target.value)}
                                            >
                                                {
                                                    [...(generate_year() || [])].map((y, i) => (
                                                        <option key={i} value={y?.value}>{y?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        {
                                            editing && (
                                                <button className="btn btn-sm btn-primary mx-1"
                                                    onClick={() => ceqAction.addEquipmentClick()}
                                                >
                                                    <i className="fa fa-plus icon-nm"></i>
                                                    Equipment
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <GridDx
                                ref={tableRef}
                                className='custom-style-master'
                                cellRender={eqAction.cellRenderMaster}
                                loading={eqState.loadingTable}
                                masterDetail={true}
                                withAction={false}
                                repaintChangesOnly={true}
                                columns={[
                                    // { fieldName: 'action_edit', caption: 'Action', allowFiltering: false, allowSorting: false, width: 75 },
                                    ...(column_table || [])]}
                                ComponenMasterDetail={EquipmentGridDetail}
                                data={eqState.dataTable?.data_table}
                            // actions={[
                            //     {
                            //         name: "Delete",
                            //         icon: "fa fa-trash-alt icon-nm text-danger",
                            //         action: (v, c) => { ceqAction.deleteEquipment(v?.id) }
                            //     },
                            //     {
                            //         name: "Edit Data Equipment",
                            //         icon: "fa fa-pencil-alt icon-nm text-secondary",
                            //         action: (v, c) => ceqAction.editEquipmentClick(v?.id)
                            //     },
                            //     {
                            //         name: "Update Data Status dan Logbook",
                            //         icon: "fa fa-pencil-alt icon-nm text-secondary",
                            //         action: (v, c) => history.push(`/admin/equipment-detail/${v?.id}`)
                            //     }
                            // ]}
                            />
                            {
                                // editing === false ? (
                                //     <GridDx
                                //         ref={tableRef}
                                //         className='custom-style-master'
                                //         cellRender={eqAction.cellRenderMaster}
                                //         loading={eqState.loadingTable}
                                //         masterDetail={true}
                                //         withAction={editing}
                                //         repaintChangesOnly={true}
                                //         columns={editing ? column_table : [{ fieldName: 'action_edit', caption: 'Action', allowFiltering: false, allowSorting: false, width: 75 }, ...(column_table || [])]}
                                //         //onRowExpanded={(e) => ceqAction.onRowExpandedMaster(e?.key)}
                                //         ComponenMasterDetail={EquipmentGridDetail}
                                //         data={eqState.dataTable?.data_table}
                                //         actions={[
                                //             {
                                //                 name: "Delete",
                                //                 icon: "fa fa-trash-alt icon-nm text-danger",
                                //                 action: (v, c) => { ceqAction.deleteEquipment(v?.id) }
                                //             },
                                //             {
                                //                 name: "Edit Data Equipment",
                                //                 icon: "fa fa-pencil-alt icon-nm text-secondary",
                                //                 action: (v, c) => ceqAction.editEquipmentClick(v?.id)
                                //             },
                                //             {
                                //                 name: "Update Data Status dan Logbook",
                                //                 icon: "fa fa-pencil-alt icon-nm text-secondary",
                                //                 action: (v, c) => history.push(`/admin/equipment-detail/${v?.id}`)
                                //             }
                                //         ]}
                                //     />
                                // ) : (
                                //     <GridDx
                                //         ref={tableRef}
                                //         className='custom-style-master'
                                //         cellRender={eqAction.cellRenderMaster}
                                //         loading={eqState.loadingTableR}
                                //         masterDetail={true}
                                //         withAction={editing}
                                //         repaintChangesOnly={true}
                                //         columns={editing ? column_table_ext : [{ fieldName: 'action_edit', caption: 'Action', allowFiltering: false, allowSorting: false, width: 75 }, ...(column_table_ext || [])]}
                                //         //onRowExpanded={(e) => ceqAction.onRowExpandedMaster(e?.key)}
                                //         ComponenMasterDetail={EquipmentGridDetail}
                                //         data={eqState.dataTableR?.tabelDetail}
                                //         actions={[
                                //             {
                                //                 name: "Delete",
                                //                 icon: "fa fa-trash-alt icon-nm text-danger",
                                //                 action: (v, c) => { ceqAction.deleteEquipment(v?.id) }
                                //             },
                                //             {
                                //                 name: "Edit Data Equipment",
                                //                 icon: "fa fa-pencil-alt icon-nm text-secondary",
                                //                 action: (v, c) => ceqAction.editEquipmentClick(v?.id)
                                //             },
                                //             {
                                //                 name: "Update Data Status dan Logbook",
                                //                 icon: "fa fa-pencil-alt icon-nm text-secondary",
                                //                 action: (v, c) => history.push(`/admin/equipment-detail/${v?.id}`)
                                //             }
                                //         ]}
                                //     />
                                // )
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default ListEquipment;