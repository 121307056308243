import React, { useRef } from "react";
import "./asset/style.css";
// import { ExportPdf } from "../../../components";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/";
import PageTracking from "./pageTracking";
import PageAchievement from "./pageAchievement";
import PageTimeline from "./pageTimeline";
import useMonthlyReport, { MonthlyProvider } from "./useMonthlyReport";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useHistory, useParams } from "react-router";
import { useGlobalState } from "../../../contexts/useGlobalState";
import { status_progress_shortName_2 } from "../../../components/utility/utility";

function MonthlyPage() {
  const pdfRef = useRef();

  const { rState, rAction } = useMonthlyReport();
  const [gState] = useGlobalState();
  const { year, month } = useParams();
  const history = useHistory()
  return (
    <div style={{ zoom: 0.8 }} className="parent-dok">
      {/* <div className="d-flex flex-column flex-root"> */}
      <div className="toolbar-pdf">
        <div className="row">
          <div className="col-4">
            <button className="btn btn-sm btn-outline-primary mr-3" onClick={() => history.goBack()}>Back</button>
          </div>
          <div className="col-4">
            <div
              className="alert bg-blue-sky text-center"
              style={{
                borderRadius: "5px",
                width: "100%",
                margin: "auto",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Version</span>
            </div>
          </div>
          <div className="col-4">
            {/* <ExportPdf targetRef={pdfRef}> */}
            <button onClick={() => {
              if (pdfRef.current) {
                pdfRef.current.save();
              }
            }} className="btn btn-sm btn-success mr-3 float-right"> <i className="fa fa-download"></i> Download PDF</button>
            {/* </ExportPdf> */}
            <div className="form-group-sm float-right col-7">
              <select onChange={(e) => rAction.setZoom(e.target.value)} value={rState.zoom} className="form-control form-control-sm" id="zoom">
                <option value="10%">
                  60%
                </option>
                <option value="30%">
                  70%
                </option>
                <option value="60%">
                  80%
                </option>
                <option selected value="80%">
                  100%
                </option>
                <option value="100%">
                  120%
                </option>
                <option value="120%">
                  150%
                </option>
              </select>
            </div>

          </div>
        </div>
      </div>

      <div className="container-pdf" style={{ fontSize: '13pt' }}>
        <PDFExport forcePageBreak=".page-break" ref={pdfRef} fileName={`monthly-project-${year}-${month}-${rState.data?.data_view_header?.title}.pdf`}>
          {/* Cover Page */}
          <div className="page-root cover" style={{ zoom: rState.zoom }} id="cover" >
            <div className="position-absolute create-by-style" style={{
              'bottom': '20px',
              'right': '30px'
            }}>Printed by {gState?.dataUser?.name || ''}</div>
            <div className="row">
              <div className="col">
                <img
                  alt="logospiirit"
                  src={toAbsoluteUrl("/pdfasset/logospiirit@2x.png")}
                  style={{ width: "200px", margin: "30px" }}
                />
                <img
                  alt="logopertamina"
                  src={toAbsoluteUrl("/pdfasset/logopertamina.png")}
                  style={{ float: "right", margin: "30px" }}
                />
              </div>
            </div>

            <div className="body-cover">
              <div className="row">
                <div className="col">
                  <h1 style={{ fontSize: "40pt", fontWeight: "bold" }}>
                    MONTHLY REPORT
                  </h1>
                  <span className="bulan-tahun">{rState.monthNames[month]} {year}</span>{" "}
                  <span className="tipe-proyek">{rState.data?.data_view_header?.project_type_name}</span>
                </div>
              </div>
              <div className="row ">
                <div className="col mt-5">
                  <h3>
                    {
                      rState.data?.data_view_header?.title
                    }
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <span className="page-title">Fungsi</span>
                  <hr />
                  <p>{rState.data?.data_view_header?.initiator_function_name}</p>
                  <span className="page-title">Klasifikasi Riset</span>
                  <hr />
                  <div className="row">
                    <div className="col-6">
                      <span className="normal"> Area Riset </span>
                    </div>
                    <div className="col-6">
                      <span className="normal">{rState.data?.data_view_header?.area_name}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <span className="normal"> Kategori Riset </span>
                    </div>
                    <div className="col-6">
                      <span className="normal">{rState.data?.data_view_header?.research_category_name}</span>
                    </div>
                  </div>

                  <span className="page-title">Chief</span>
                  <hr />
                  <p>{rState.data?.data_view_header?.section_name}</p>
                  <table width={'100%'}>
                    <tbody>
                      <tr>
                        <td width={'50%'} className="align-baseline">
                          <div>
                            <span className="page-title">Periode</span>
                            <hr className="mr-3" />
                            <p>{rState.data?.data_cutoff_bulan}/{rState.data?.data_cutoff_tahun}</p>
                          </div>
                        </td>
                        <td className="align-baseline">
                          <div>
                            <span className="page-title">Status Performance</span>
                            <hr className="mr-3" />
                            <div className="d-flex">{status_progress_shortName_2({ value: rState.data?.data_view_header?.status_progress })}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span className="page-title">Team Leader</span>
                  <hr />
                  <table style={{ width: "100%", fontSize: "10pt" }}>
                    <tbody>
                      <tr style={{ borderBottom: "1px solid black", borderTop: "1px solid black", width: "100%" }}>
                        <th style={{ padding: 3 }}>{rState.data?.data_view_header?.owner_name}</th>
                        <th style={{ padding: 3 }}>{rState.data?.data_view_header?.initiator_function_name}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* End Of Cover Page */}
          <div className="page-break"></div>
          <div className="page-root bg" style={{ zoom: rState.zoom }} id="page" >
            {/* page Header */}
            <div className="position-absolute create-by-style" style={{ 'bottom': '20px', 'right': '30px' }}>Printed by {gState?.dataUser?.name || ''}</div>
            <div className="page-header">
              <div className="sub-header-title">
                <h2 style={{ fontWeight: "bold" }}>MONTHLY REPORT</h2>
                <h3>{rState.monthNames[month].toUpperCase()} {year}</h3>
              </div>
              <div className="sub-header">
                <h2
                  style={{
                    margin: "auto",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  TRACKING
                </h2>
              </div>
            </div>
            {/* End Of Page header */}
            <PageTracking />
          </div>

          <div className="page-break"></div>
          <div className="page-root bg" style={{ zoom: rState.zoom }} id="page">
            {/* page Header */}
            <div className="position-absolute create-by-style" style={{
              'bottom': '20px',
              'right': '30px'
            }}>Printed by {gState?.dataUser?.name || ''}</div>
            <div className="page-header">
              <div className="sub-header-title">
                <h2 style={{ fontWeight: "bold" }}>MONTHLY REPORT</h2>
                <h3>{rState.monthNames[month].toUpperCase()} {year}</h3>
              </div>
              <div className="sub-header">
                <h2
                  style={{
                    margin: "auto",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  ACHIEVEMENT & CONCERN
                </h2>
              </div>
            </div>
            {/* End Of Page header */}
            <PageAchievement />
          </div>

          <div className="page-break"></div>
          <div className="page-root bg" style={{ zoom: rState.zoom }} id="page">
            {/* page Header */}
            <div className="position-absolute create-by-style" style={{
              'bottom': '35px',
              'right': '30px'
            }}>Printed by {gState?.dataUser?.name || ''}</div>
            <div className="page-header">
              <div className="sub-header-title">
                <h2 style={{ fontWeight: "bold" }}>MONTHLY REPORT</h2>
                <h3>{rState.monthNames[month].toUpperCase()} {year}</h3>
              </div>
              <div className="sub-header">
                <h2
                  style={{
                    margin: "auto",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  PROJECT TIMELINE
                </h2>
              </div>
            </div>
            {/* End Of Page header */}
            <PageTimeline />
          </div>
        </PDFExport>
      </div>
      {/* </div> */}
    </div>
  );
}

export default () => <MonthlyProvider><MonthlyPage /> </MonthlyProvider>
