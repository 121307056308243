import React, { useEffect, useState } from 'react';
import { GridDx } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import DropDownButton from "devextreme-react/drop-down-button";
import { Link, useLocation } from 'react-router-dom';
import useLocalStorage from '../../contexts/useLocalStorage';
import { baseUrl } from '../../app.config';
import Swal from 'sweetalert2';
import useFetch from '../../modules/useFetch';
import { toLower } from 'lodash';

const col = [
    { fieldName: "created_at", caption: "Date Created", allowFiltering: false },
    { fieldName: "jenis_dokumen_name", caption: "Jenis Dokumen", allowFiltering: false },
    { fieldName: "status_dokumen_name", caption: "Status", allowFiltering: false },
    { fieldName: "berlaku_start", caption: "Mulai Berlaku", allowFiltering: false },
    { fieldName: "berlaku_end", caption: "Sampai Dengan", allowFiltering: false },
    // { fieldName: "rkap", caption: "Related RKAP", allowFiltering: false },
    { fieldName: "file_name", caption: "Dokumen", allowFiltering: false },
]
function KerjasamaGridDetail(props) {
    const [state, dispatch, action] = useGlobalState();
    const [ds, setDs] = useState([...(props?.data?.data?.kerjasama_dokumens)]);
    const { doGet } = useFetch();
    const { pathname } = useLocation();
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    const [column, setColumn] = useState([]);
    const [dataDetail, setDataDetail] = useState({});

    useEffect(() => {
        if (props.data.data?.id) {
            // doGet({ url: `/api/ext-partnership/view-2/${props.data.data?.id}` }).then((resp) => {
            //     if (resp.code === '00') {
            //         setDs(resp.data.tabel_dokumen);
            //         setDataDetail(resp.data);
            //     }
            // })
            if (toLower(path) === '/admin/partnership-crud/table') {
                setColumn(col)
            } else {
                setColumn(col.filter(d => d.fieldName !== 'file_name'))
            }
        }
    }, [props.data.data?.id])

    function customRender(e) {
        if (e.column.name === "rkap") {
            return (
                <span className="text-wrap">{e.value}</span>
                // <ul>
                //     {
                //         [...(e.value?.split(';') || [])].map((d, i) => (
                //             <Link key={i} to={`/OngoingResearch/view/${d?.research_id}`} target={'_blank'}>
                //                 <li className="text-wrap p-2">{d.show_research}</li>
                //             </Link>
                //         ))
                //     }
                // </ul>
            )
        } else if (e.column.name === 'file_name') {
            return (
                <span className="text-wrap">{e.value}</span>
                // <DropDownButton
                //     text=""
                //     className="btn btn-sm btn-outline-primary rounded custom-dropdown-download p-0"
                //     stylingMode="text"
                //     icon="fa fa-download icon-sm m-0 p-0"
                //     showArrowIcon={false}
                //     dropDownOptions={{ width: 250 }}
                //     displayExpr="file_name"
                //     keyExpr="id"
                //     noDataText="No File"
                //     itemRender={(e) => {
                //         return (
                //             <a
                //                 href={dataDetail?.base_url + e.file_name_url}
                //                 target="_blank" download
                //                 rel="noopener noreferrer"
                //             >
                //                 <span>{e.file_name}</span>
                //             </a>
                //         )
                //     }}
                //     items={[...(e.value || [])].filter(d => d?.file_name_url !== '' && d?.file_name_url !== null)}
                // />
            )
        } else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    return (
        <div className="p-5">
            <div className="row">
                <div className="col-md-6 text-left">
                    <h5>Detail Partnership</h5>
                </div>
            </div>
            <GridDx
                className="custom-style"
                columns={column}
                data={ds}
                cellRender={customRender}
            />
        </div>
    );
}

export default KerjasamaGridDetail;