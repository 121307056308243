import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import useCrudPengujian from '../useCrudPengujian';
import moment from 'moment';

const inistateForm = {
    no_lab_test: "",
    note: "",
}

function ModalTestReqApproval(props) {
    const [formData, setformData] = useState({ ...inistateForm });
    const { cpAction, cpState } = useCrudPengujian();
    const [modalResume, setModalResume] = useState(false);
    const closeModal = () => {
        cpAction.setModalReq({ show: false, default_data: {} });
    }
    const handleChange = (name, val) => {
        setformData(prev => ({ ...prev, [name]: val }));
    }
    useEffect(() => {
        if (cpState.modalReq?.show) {
            cpAction.getDetailAp(cpState.modalReq?.default_data?.id_lab_test || "");
        } else {
            setformData({ ...inistateForm });
        }
    }, [cpState.modalReq?.show])
    return (
        <>
            <Modal size='xl' show={modalResume} onHide={() => setModalResume(false)}>
                <Modal.Body>
                    <iframe className='vh-100 w-100' src={cpState.detailAp?.file_location} type="application/pdf">
                        <div>No online PDF viewer installed</div>
                    </iframe>
                </Modal.Body>
            </Modal>
            <Modal show={cpState.modalReq?.show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Persetujuan Pengujian
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm py-0">Dokumen Nota Dinas / Surat Keluar / Notulen Rapat / Email</label>
                        <div className="col-sm-8">
                            <button onClick={() => setModalResume(true)} className='btn btn-icon btn-light-primary'>
                                <i className='fas fa-download'></i>
                            </button>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">Nomor Pengujian</label>
                        <div className="col-sm-8">
                            <input value={formData?.no_lab_test} onChange={e => handleChange("no_lab_test", e.target.value)} className='form-control form-control-sm'></input>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">Tanggal Persetujuan</label>
                        <div className="col-sm-8">
                            <p>{moment().format("DD-MM-yyyy")}</p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan</label>
                        <div className="col-sm-8">
                            <textarea className='form-control form-control-sm' rows={3}
                                value={formData?.note} onChange={e => handleChange("note", e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => cpAction.rejectKlik({
                        id: cpState.modalReq?.default_data?.id_lab_test,
                        no_lab_test: formData.no_lab_test,
                        note: formData?.note
                    })} className="btn btn-sm btn-dark mr-1">Reject</button>
                    <button onClick={() => cpAction.needRevKlik({
                        id: cpState.modalReq?.default_data?.id_lab_test,
                        no_lab_test: formData.no_lab_test,
                        note: formData?.note
                    })} className="btn btn-sm btn-warning mr-1">Need Revision</button>
                    <button onClick={() => cpAction.approveKlik({
                        id: cpState.modalReq?.default_data?.id_lab_test,
                        no_lab_test: formData.no_lab_test,
                        note: formData?.note
                    })} className="btn btn-sm btn-success mr-1">Approve</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalTestReqApproval;