import React from 'react';
import { GridDx } from '../../../components';
import useBroadcastEmail from '../useBroadcastEmail';
import { useEffect } from 'react';
import { useRef } from 'react';

function TabLogBroadcast(props) {
    const { beAction, beState } = useBroadcastEmail();
    const tableRef = useRef();

    useEffect(() => {
        beAction.getLogBe();
    },[])
    return (
        <div className='card customShadow p-5'>            
            <div className='row mb-5'>
                <div className='col-12'>
                    <GridDx
                        className='custom-style-master'
                        repaintChangesOnly={true}
                        searchForm={false}
                        ref={tableRef}
                        loading={beState.loadinglog}
                        data={beState.data_log?.data_bcemail_list}
                        cellRender={beAction.cellRender}
                        columns={beState.column_be}
                        withAction={false}
                        pagination={true}
                        orderingDrag={false}
                        currentPage
                        show
                    >
                    </GridDx>
                </div>
            </div>
        </div>
    );
}

export default TabLogBroadcast;