import React , { useState}from 'react';
import { FormResearch } from '../../components';
import { useGlobalState } from "../../contexts/useGlobalState";
import {kuantitatif, probabilitas, dampak} from '../../components/utility/utility';
const ModalViewEdit = (props) =>{
    const [state, dispatch, actions] = useGlobalState();
    // console.log("dataaaa", props, state)
    function saveOnclick(){
        props.onFinish(state.formData)
    }
    return(
        <>
        <div style={{padding:'10px'}}>
            
            <FormResearch
                formLists={[
                    {
                        rows:1,
                        header:true,
                        name:"Project Risk",
                        childs:[
                            {                            
                                type:"select",                                
                                label:"Kuantitatif/Kuantitatif Risk",
                                placeholder: "- Pilih -",
                                options:kuantitatif,
                                value:state.formData.data_editing?.kuantitatif,
                                onChange: function(e){actions.setForm("kuantitatif", e)},
                                disabled:props.disable
                            },
                            {
                                label:"Kejadian Resiko (Risk Event)",
                                type:"textarea",
                                value:state.formData.data_editing?.kejadian,
                                onChange: function(e){actions.setForm("kejadianResiko", e)},
                                placeholder: "Tulis disini...",
                                disabled:props.disable
                            },
                            {
                                label:"Penyebab Resiko (Risk Agent)",
                                type:"textarea",
                                value:state.formData.data_editing?.penyebabResiko,
                                onChange: function(e){actions.setForm("penyebabResiko", e)},
                                placeholder: "Tulis disini...",
                                disabled:props.disable
                            }
                            ,
                            {
                                label:"Gejala Resiko (KRI)",
                                type:"textarea",
                                value:state.formData.gejalaResiko,
                                onChange: function(e){actions.setForm("gejalaResiko", e)},
                                placeholder: "Tulis disini...",
                                disabled:props.disable
                            }
                            ,
                            {
                                label:"Faktor Positif (Control)",
                                type:"textarea",
                                value:state.formData.faktorPositif,
                                onChange: function(e){actions.setForm("faktorPositif", e)},
                                placeholder: "Tulis disini...",
                                disabled:props.disable
                            }
                            ,
                            {
                                label:"Dampak Kualitatif",
                                type:"textarea",
                                value:state.formData.dampakKualitatif,
                                onChange: function(e){actions.setForm("dampakKualitatif", e)},
                                placeholder: "Tulis disini...",
                                disabled:props.disable
                            }
                        ]
                    },{
                        rows:1,
                        header:true,
                        name:"Initiator Ide",
                        childs:[
                            {                            
                                type:"text",
                                value: state.formData.dampakKuantitatif,
                                label:"Dampak Kuantitatif (000 USD)",
                                onChange: function(e){actions.setForm("dampakKuantitatif", e)},
                                placeholder: "$",
                                disabled:props.disable
                            },
                            {
                                label:"Probabilitas",
                                options: probabilitas,
                                value:state.formData.probabilitas,
                                onChange:function(e){actions.setForm("probabilitas", e)},
                                type:"select",
                                placeholder: "- Pilih -",
                                disabled:props.disable
                            },
                            {
                                label:"Dampak",
                                options:dampak,
                                value:state.formData.dampak,
                                onChange:function(e){actions.setForm("dampak", e)},
                                type:"select",
                                placeholder: "- pilih -",
                                disabled:props.disable
                            },
                            {
                                label:"Risk Priority Number (RPN)",
                                type:"custom",
                                disabled:props.disable,
                                component:(
                                    <>
                                        <div className="row">
                                            {state.formData.riskPriority}
                                        </div>
                                    </>
                                )
                            }
                        ]
                    },{
                        rows:2,
                        header:true,
                        name:"Mitigasi",
                        childs:[
                            {                            
                                type:"textarea",
                                label:"Rencana mitigasi",
                                value: state.formData.rencanaMitigasi,
                                onChange:function(e){actions.setForm("rencanaMitigasi", e)},
                                placeholder: "Tulis disini...",
                                disabled:props.disable
                            },
                            {
                                label:"Biaya Mitigasi",
                                value:state.formData.biayaMitigasi,
                                onChange:function(e){actions.setForm("biayaMitigasi", e)},
                                type:"text",
                                placeholder: "$",
                                disabled:props.disable
                            },
                            {
                                label:"Tahun Berlaku",
                                value:state.formData.tahunBerlaku,
                                onChange:function(e){actions.setForm("tahunBerlaku", e)},
                                type:"text",
                                placeholder: "- Pilih - ",
                                disabled:props.disable
                            },
                            {
                                label:"Tahun Selesai",
                                value:state.formData.tahunSelesai,
                                onChange:function(e){actions.setForm("tahunSelesai", e)},
                                type:"text",
                                placeholder: "- Pilih - ",
                                disabled:props.disable
                            }
                        ]
                    },{
                        rows:2,
                        header:true,
                        name:"Reduksial",
                        childs:[
                            {                            
                                type:"text",
                                value:state.formData.dampakKuantitatifReduksial,
                                onChange:function(e){actions.setForm("dampakKuantitatifReduksial", e)},
                                label:"Dampak Kuantitatif (000 USD)",
                                placeholder: "$",
                                disabled:props.disable
                            },
                            {
                                label:"Probabilitas",
                                options:probabilitas,
                                value:state.formData.probabilitasReduksial,
                                onChange:function(e){actions.setForm("probabilitasReduksial", e)},
                                type:"select",
                                placeholder: "- Pilih -",
                                disabled:props.disable
                            },
                            {
                                label:"Dampak",
                                options: dampak,
                                value:state.formData.dampakReduksial,
                                onChange: function(e){actions.setForm("dampakReduksial", e)},
                                type:"select",
                                placeholder: "- Pilih - ",
                                disabled:props.disable
                            },
                            {
                                label:"Risk Priority Number (RPN)",
                                type:"custom",
                                placeholder: "- Pilih - ",
                                disabled:props.disable,
                                component:(
                                    <>
                                        <div className="row">{state.formData.RPN}
                                        </div>
                                    </>
                                )
                            }
                        ]
                    }
                ]}
            />
            <div style={ !props.disable ? {width:'100%', justifyContent:'center', display:"flex"} : {display:"none"}}>
            <button  className="btn btn-primary" onClick={saveOnclick}><i className="fa fa-save"></i> Simpan</button>
            </div>
        </div>
        </>
    )
}
export default ModalViewEdit;