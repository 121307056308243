import React, { memo, useEffect, useRef, useState } from 'react'
import { GridDx } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card'
import SVG from "react-inlinesvg";
import {
    status_progress_shortName,
    status_progress_shortName_2,
    status_progress_shortName_4,
    perform_work,
    workflow_state_name_light_style,
    status_budget_light,
    subholding,
    potensial_partner_opt_name,
    need_management_support,
    value_creation
} from '../../components/utility/utility';
import moment from 'moment';
import useMonitoring from './useMonitoring';

//Api Parameter
const paramApiDetail = {
    fungsi_search: "",
    chief_search: "",
    area_search: "",
    cat_search: "",
    status_workflow_search: "",
    status_progress_search: "",
    status_budget_search: "",
    cut_off_search: "",
    leader_search: "",
    project_type_search: "",
    need_management_support_search: "",
    sub_holding_search: "",
    year_start_search: "",
    year_end_search: "",
}
const paramApiProgress = {
    fungsi_search: "",
    chief_search: "",
    area_search: "",
    cat_search: "",
}
const paramApiSubHold = {
    fungsi_search: "",
    chief_search: "",
    area_search: "",
    cat_search: "",
    status_workflow_search: "",
    status_progress_search: "",
    status_budget_search: "",
    cut_off_search: "",
    leader_search: "",
    project_type_search: "",
    need_management_support_search: "",
    sub_holding_search: "",
    year_start_search: "",
    year_end_search: ""
}

//Filter Column
const budgetFilter = [
    { text: 'Under Budget', value: 1 },
    { text: 'Within Budget', value: 2 },
    { text: 'Over Budget', value: 3 },
    { text: 'Aborted', value: 4 },
    { text: '-', value: null },
]
const progresFilter = [
    { text: 'ER', value: 1 },
    { text: 'OT', value: 2 },
    { text: 'DL', value: 3 },
    { text: 'FN', value: 4 },
    { text: 'ST', value: 5 },
    { text: 'CON', value: 6 },
    { text: 'UNF', value: 7 },
]

//Column Visible

//Grid Selector
const optGrid = [
    { name: "Project Detail", value: 1 },
    { name: "Progress Status", value: 2 },
    // { name: "Project Sub Holding", value: 3 }
]
const years = [{ value: 2021, name: 2021 }, { value: 2020, name: 2020 }, { value: 2019, name: 2019 }];
const month = [
    { name: 'JAN', value: '1' },
    { name: 'FEB', value: '2' },
    { name: 'MAR', value: '3' },
    { name: 'APR', value: '4' },
    { name: 'MAY', value: '5' },
    { name: 'JUN', value: '6' },
    { name: 'JUL', value: '7' },
    { name: 'AUG', value: '8' },
    { name: 'SEP', value: '9' },
    { name: 'OCT', value: '10' },
    { name: 'NOV', value: '11' },
    { name: 'DEC', value: '12' },
]

const TabTableProgress = function ({ reporting = false, view_admin = false }) {
    const tableRef = useRef()
    const [sw, setSw] = useState(false);
    const [gridType, setGridType] = useState("2")
    const [state, dispatch, action] = useGlobalState();
    const columnProgress = [
        { fieldName: "updated_at", caption: "Last Modified", width: 100 },
        { fieldName: "project_code", caption: "Code Project", width: 100 },
        { fieldName: "title", caption: "Title", width: 300 },
        { fieldName: "project_type_str", caption: "Project Type", width: 80 },
        { fieldName: "function_sn", caption: "Fungsi", width: 80 },        
        { fieldName: "chief_sn", caption: "Chief", width: 80 },
        { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
        { fieldName: "perform_work", caption: "Performance/Progress", width: 115 },
        // { fieldName: "stage", caption: "TRL", width: 100 },
        { fieldName: "status_progress_1", caption: "1", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_2", caption: "2", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_3", caption: "3", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_4", caption: "4", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_5", caption: "5", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_6", caption: "6", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_7", caption: "7", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_8", caption: "8", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_9", caption: "9", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_10", caption: "10", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_11", caption: "11", width: 50, headerFilterSource: progresFilter },
        { fieldName: "status_progress_12", caption: "12", width: 50, headerFilterSource: progresFilter },
    ]
    const columnDetail = [
        { fieldName: "updated_at", caption: "Last Modified", width: 100 },
        { fieldName: "project_code", caption: "Code Project", width: 100 },
        { fieldName: "title", caption: "Title", width: 300 },
        { fieldName: "project_type_str", caption: "Project Type", width: 80 },
        { fieldName: "function_sn", caption: "Fungsi", width: 100 },
        { fieldName: "chief_sn", caption: "Chief", width: 100 },
        { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
        { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
        { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
        { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
        { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
        { fieldName: "area_name", caption: "Area", width: 100 },
        { fieldName: "research_category_id", caption: "Research Kategori", width: 115 },
        { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
        { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
        { fieldName: "leader", caption: "Leader", width: 100 },
        { fieldName: "method_name", caption: "Metode", width: 100 },
        { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
        { fieldName: "potential_user", caption: "Potensial User", width: 200 },
        { fieldName: "need_management_support", caption: "Need Management Support", width: 120 },
        { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
        //{ fieldName: "value_creation", caption: "Value Creations", width: 300 },
        { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
        { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
        { fieldName: "year_start", caption: "Start", width: 100 },
        { fieldName: "year_end", caption: "End", width: 100 },
    ]
    const columnSubHol = [
        { fieldName: "updated_at", caption: "Last Modified", width: 100 },
        { fieldName: "project_code", caption: "Code Project", width: 100 },
        { fieldName: "title", caption: "Title", width: 300 },
        { fieldName: "project_type_str", caption: "Project Type", width: 80 },
        { fieldName: "function_sn", caption: "Fungsi", width: 100 },
        { fieldName: "chief_sn", caption: "Chief", width: 100 },
        { fieldName: "cut_off_mtyr", caption: "Cut Off", width: 80 },
        { fieldName: "perform_work", caption: "Performance / Progress", width: 115 },
        { fieldName: "status_progress", caption: "Status Performance", width: 100, headerFilterSource: progresFilter },
        { fieldName: "status_budget", caption: "Status Budget", width: 100, headerFilterSource: budgetFilter },
        { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
        { fieldName: "area_name", caption: "Area", width: 100 },
        { fieldName: "research_category_id", caption: "Research Kategori", width: 115 },
        { fieldName: "actual_tot_budget", caption: "Budget Spend (RIBU USD)", width: 110, allowFiltering: false },
        { fieldName: "plan_tot_budget", caption: "Total Budget (RIBU USD)", width: 100, allowFiltering: false },
        { fieldName: "leader", caption: "Leader", width: 100 },
        { fieldName: "method_name", caption: "Metode", width: 100 },
        { fieldName: "potensial_partner_opt_name", caption: "Potensial Partner", width: 200 },
        { fieldName: "potential_user", caption: "Potensial User", width: 200 },
        { fieldName: "need_management_support", caption: "Need Management Support", width: 120 },
        { fieldName: "subholding", caption: "Dukungan Operasional", width: 115 },
        //{ fieldName: "value_creation", caption: "Value Creations", width: 300 },
        { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
        { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
        { fieldName: "year_start", caption: "Start", width: 100 },
        { fieldName: "year_end", caption: "End", width: 100 },
    ]
    const {
        monState: {
            tahun,
            bulan,
        }
    } = useMonitoring();
    useEffect(() => {
        getTableProgress(tahun, bulan);
    }, [tahun, bulan])

    // useEffect(() => {
    //     if (tableRef.current) {
    //         tableRef.current.instance.refresh();
    //     }
    // }, [state.dsGrid])
    function getTableProgress(tahun, bulan) {
        if (reporting === false) {
            action.getData("/api/project-ongoing/table-progress", {
                tahun: tahun || '',
                bulanGet: bulan || ''
            }, "dsGrid", "loadingGrid", true).then((resp) => {
                if (resp.code === "00") {
                    const option_year = (resp?.data?.data_allyear || [])?.map(e => ({ name: e?.idYear, value: e?.idYear }))
                    dispatch({
                        type: 'getSuccess',
                        payload: {
                            option_year,
                            data_bulan_default: resp.data?.data_bulan_default,
                            data_tahun_default: resp.data?.data_tahun_default,
                            data_page: resp.data_page,
                            loadingAll: false
                        }
                    })
                }
            })
        } else {
            action.getData("/api/project-ongoing/table-progress-3", {
                tahun: tahun || '',
                bulanGet: bulan || ''
            }, "dsGrid", "loadingGrid", true).then((resp) => {
                if (resp.code === "00") {
                    const option_year = (resp?.data?.data_allyear || [])?.map(e => ({ name: e?.idYear, value: e?.idYear }))
                    dispatch({
                        type: 'getSuccess',
                        payload: {
                            option_year,
                            data_bulan_default: resp.data?.data_bulan_default,
                            data_tahun_default: resp.data?.data_tahun_default,
                            data_page: resp.data_page,
                            loadingAll: false
                        }
                    })
                }
            })
        }
    }
    function cstmCellRender(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/OngoingResearch-public/view/" + e.data.id,
                        }
                    }
                ><span className="text-wrap">{e.value}</span></Link>
            )
        } else if (e.column.name === "cut_off_mtyr") {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        } else if (e.column.name === "perform_work") {
            return (perform_work(e))
        } else if (
            e.column.name === "status_progress_1" ||
            e.column.name === "status_progress_2" ||
            e.column.name === "status_progress_3" ||
            e.column.name === "status_progress_4" ||
            e.column.name === "status_progress_5" ||
            e.column.name === "status_progress_6" ||
            e.column.name === "status_progress_7" ||
            e.column.name === "status_progress_8" ||
            e.column.name === "status_progress_9" ||
            e.column.name === "status_progress_10" ||
            e.column.name === "status_progress_11" ||
            e.column.name === "status_progress_12"
        ) {
            return (status_progress_shortName_4(e, state.dsGrid?.data_bulan_default))
        } else if (e.column.name === "status_progress") {
            return (status_progress_shortName_2(e))
        } else if (e.column.name === "status_budget") {
            return (status_budget_light(e))
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "potensial_partner_opt_name") {
            return (potensial_partner_opt_name(e))
        } else if (e.column.name === "need_management_support") {
            return (need_management_support(e))
        } else if (e.column.name === "subholding") {
            return (subholding(e))
        } else if (e.column.name === "value_creation") {
            return (value_creation(e))
        } else {
            return (<span className="text-wrap">{e.value}</span>)
        }
    }
    return (
        <>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-3">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Project</span>
                                    <span className="d-block"><strong>{(state.dsGrid?.data_jumlah_project?.total || 0) - (state.dsGrid?.data_jumlah_project?.continue_request || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Project Running</span>
                                    <span className="d-block"><strong>{(state.dsGrid?.data_jumlah_project?.project_running || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Finished</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.finished || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Aborted</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.aborted || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Review</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.review || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Need Revision</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.need_revision || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Not Yet Reported</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.project_continue || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom pl-2">
                                <div>
                                    <div className="shadow rounded bg-white text-dark text-center" style={{ width: 32.5, height: 32.5, display: 'grid' }}>
                                        <span className="my-auto"><b>C</b></span>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Continue Request</span>
                                    <span className="d-block"><strong>{state.dsGrid?.data_jumlah_project?.continue_request || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom pl-md-5">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                    <span className="d-block">$ <strong>{(state.dsGrid?.data_jumlah_project?.total_plan_budget || 0)}/{(state.dsGrid?.data_jumlah_project?.total_actual_budget || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 h-100 mb-5 shadow"
            >
                <Card.Body className="p-2">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-12">
                            {
                                gridType === "1" && (
                                    <GridDx
                                        selectionMode={reporting ? "multiple" : false}
                                        exportMode="selection"
                                        filenameExport={`Monitoring Table Detail-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        exportExcel={reporting ? true : false}
                                        exportPdf={reporting ? true : false}
                                        settingColumn={reporting ? true : false}
                                        defaultColumn={columnDetail}
                                        searchForm={true}
                                        ref={tableRef}
                                        loading={state.loadingGrid}
                                        data={state.dsGrid?.data_table}
                                        columns={columnDetail}
                                        cellRender={(e) => cstmCellRender(e)}
                                        withAction={false}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                    >

                                    </GridDx>
                                )
                            }
                            {
                                gridType === "2" && (
                                    <GridDx

                                        selectionMode={reporting ? "multiple" : false}
                                        filenameExport={`Monitoring Table Progress-${state.data_bulan_default}-${state.data_tahun_default}`}
                                        exportMode="selection"
                                        exportExcel={reporting ? true : false}
                                        // exportPdf={reporting ? true : false}
                                        // settingColumn={reporting ? true : false}
                                        defaultColumn={columnProgress}
                                        searchForm={true}
                                        ref={tableRef}
                                        loading={state.loadingGrid}
                                        data={state.dsGrid?.data_table}
                                        columns={columnProgress}
                                        cellRender={(e) => cstmCellRender(e)}
                                        withAction={false}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                    >

                                    </GridDx>
                                )
                            }
                            {
                                gridType === "3" && (
                                    <GridDx
                                        selectionMode={reporting ? "multiple" : false}
                                        exportMode="selection"
                                        filenameExport={`Monitoring Table Progress-${moment(new Date()).format('YYYY-MM-DD')}`}
                                        exportExcel={reporting ? true : false}
                                        // exportPdf={reporting ? true : false}
                                        settingColumn={reporting ? true : false}
                                        defaultColumn={columnSubHol}

                                        ref={tableRef}
                                        loading={state.loadingGrid}
                                        data={state.dsGrid?.data_table}
                                        columns={columnSubHol}
                                        cellRender={(e) => cstmCellRender(e)}
                                        withAction={false}
                                        pagination={true}
                                        orderingDrag={false}
                                        currentPage
                                        show
                                    >

                                    </GridDx>
                                )
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
export default TabTableProgress;