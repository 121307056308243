import React from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

const ImageTemplateProduct = ({ ds = [], scrollPosition }) => {
    return (
        <>
            {
                ds.map((d, i) => (
                    <div className='col-lg-3 col-md-3 mb-3' key={i}>
                        <div className='card p-3 h-100 rounded-cs'>
                            <LazyLoadImage
                                key={i}
                                src={d?.photo_location_name}
                                alt='Image Alt'
                                width={'100%'}
                                height={'180px'}
                                placeholderSrc='/images/no_image.jpg'
                                effect='blur'
                                scrollPosition={scrollPosition}
                            />
                            <span className='text-wrap font-weight-boldest m-3'>{d?.name}</span>
                            <div className='text-wrap m-3  text-truncate-container'>{d?.description}</div>
                            <div className='footer-card-product'>
                                <div>
                                    <span className='bg-light-primary text-primary px-2 rounded'>{d?.product_status_name}</span>
                                    <span className='text-muted float-right'>{d?.release_year}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default trackWindowScroll(ImageTemplateProduct);