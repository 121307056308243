import React, { useEffect, useState } from 'react';
import { ChartDx, CstmFilterCahrtDx, HeaderList } from '../../../../components';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import useFetch from '../../../../modules/useFetch';
import Swal from "sweetalert2";

function Index(props) {
    const { doGet } = useFetch();

    const [tahun, setTahun] = useState(moment().year());
    const [fChart1, setFChart1] = useState(1);
    const [chart1, setChart1] = useState({});
    async function getChart1(param = 1) {
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart1?parameter=${param}`,
            });
            if (resp?.code === '00') {
                setFChart1(param)
                setChart1(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [fChart2, setFChart2] = useState(1);
    const [chart2, setChart2] = useState({});
    async function getChart2(param = 1) {
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart2?parameter=${param}`,
            });
            if (resp?.code === '00') {
                setFChart2(param)
                setChart2(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [fChart3, setFChart3] = useState(1);
    const [chart3, setChart3] = useState({});
    async function getChart3(param = 1) {
        // Swal.fire({
        //     title: 'Please Wait',
        //     html: 'Retrieving data ...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart3?parameter=${param}`,
            });
            if (resp?.code === '00') {
                setFChart3(param)
                setChart3(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [chart4, setChart4] = useState([]);
    async function getChart4() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart4`,
            });
            if (resp?.code === '00') {
                setChart4(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const [chart5, setChart5] = useState([]);
    async function getChart5() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: `api/ext-lab/dashboard-chart5`,
            });
            if (resp?.code === '00') {
                setChart5(resp?.data)
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    useEffect(() => {
        getChart1()
        getChart2()
        getChart3()
        getChart4();
        getChart5();
    }, [])
    return (
        <div style={{ paddingBottom: '20px' }}>
            <HeaderList
                title={"Dashboard Laboratory"}
                someText={""}
            />
            <div className="row">
                <div className="col-12">
                    <Card
                        className="m-0 p-0 mb-5 shadow"
                    >
                        <Card.Body className="p-2">
                            <div className="row mt-5 mb-5">
                                <div className="col-md-12 ">
                                    <div className='p-5'>
                                        <div className='d-flex align-items-center mb-5'>
                                            <h5>Dashboard Pengujian Lab RTI - Tahun</h5>
                                            <select className='form-control form-control-sm w-auto ml-5'>
                                                <option selected>2024</option>
                                                <option>2023</option>
                                                <option>2022</option>
                                            </select>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-md-6'>
                                                <CstmFilterCahrtDx
                                                    cardStyle={false}
                                                    title="Jumlah Pengujian"
                                                    subtitle="Jumlah pengujian laboratorium"
                                                    option={[...(chart1?.data_parameter || [])].map(d => ({ name: d?.nama, value: d?.id }))}
                                                    value={fChart1}
                                                    onChange={val => getChart1(val)}
                                                >
                                                    {
                                                        React.useMemo(() => (

                                                            <ChartDx
                                                                id="1"
                                                                argumentField="nama"
                                                                dataSource={chart1?.data_chart || []}
                                                                onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                                                withTitle={false}
                                                                showLegend={false}
                                                                toolsExport={false}
                                                                yAxisArgs={[
                                                                    {
                                                                        valueField: "jumlah",
                                                                        color: '#326BD5'
                                                                    },
                                                                ]}
                                                            />
                                                        ), [chart1?.data_chart])
                                                    }
                                                </CstmFilterCahrtDx>
                                            </div>
                                            <div className='col-md-6'>
                                                <CstmFilterCahrtDx
                                                    cardStyle={false}
                                                    title="Revenue Pengujian (Ribu IDR)"
                                                    subtitle="Jumlah pendapatan pengujian berdasarkan komoditi dan customer"
                                                    option={[...(chart2?.data_parameter || [])].map(d => ({ name: d?.nama, value: d?.id }))}
                                                    value={fChart2}
                                                    onChange={val => getChart2(val)}
                                                >
                                                    {
                                                        React.useMemo(() => (
                                                            <ChartDx
                                                                id="1"
                                                                argumentField="nama"
                                                                dataSource={chart2?.data_chart || []}
                                                                onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                                                rotationAngle={45}
                                                                overlappingBehavior="rotate"
                                                                withTitle={false}
                                                                showLegend={false}
                                                                toolsExport={false}
                                                                yAxisArgs={[
                                                                    {
                                                                        valueField: "jumlah",
                                                                        color: "#28a745"
                                                                    }
                                                                ]}
                                                            />
                                                        ), [chart2?.data_chart])
                                                    }
                                                </CstmFilterCahrtDx>
                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-md-6'>
                                                <CstmFilterCahrtDx
                                                    cardStyle={false}
                                                    title="Jumlah Revenue"
                                                    subtitle="Jumlah pendapatan pengujian berdasarkan bulanan atau tahunan"
                                                    option={[...(chart3?.data_parameter || [])].map(d => ({ name: d?.nama, value: d?.id }))}
                                                    value={fChart3}
                                                    onChange={val => getChart3(val)}
                                                >
                                                    {
                                                        React.useMemo(() => (

                                                            <ChartDx
                                                                id="1"
                                                                type="line"
                                                                argumentField="nama"
                                                                dataSource={chart3?.data_chart || []}
                                                                onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                                                withTitle={false}
                                                                showLegend={false}
                                                                toolsExport={false}
                                                                yAxisArgs={[
                                                                    {
                                                                        valueField: "jumlah",
                                                                        color: '#dc3545'
                                                                    }
                                                                ]}
                                                            />
                                                        ), [chart3?.data_chart])
                                                    }
                                                </CstmFilterCahrtDx>
                                            </div>
                                            <div className='col-md-6'>
                                                <CstmFilterCahrtDx
                                                    cardStyle={false}
                                                    title="Jenis Pengujian"
                                                    subtitle="Top 10 jenis pengujian & komoditi per tahun"
                                                >
                                                    {
                                                        React.useMemo(() => (

                                                            <ChartDx
                                                                id="1"
                                                                rotate={true}
                                                                argumentField="nama"
                                                                dataSource={chart4}
                                                                withTitle={false}
                                                                showLegend={false}
                                                                toolsExport={false}
                                                                colorBarDifferent={true}
                                                                colorBarDiffirentField="nama"
                                                                coloBarDifferentValueField="jumlah"
                                                                onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}

                                                            />
                                                        ), [chart4])
                                                    }
                                                </CstmFilterCahrtDx>
                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-md-12'>
                                                <CstmFilterCahrtDx
                                                    cardStyle={false}
                                                    title="Status Pengujian"
                                                    subtitle="Jumlah pengujian per status pengujian"
                                                >
                                                    {
                                                        React.useMemo(() => (

                                                            <ChartDx
                                                                id="1"
                                                                height={400}
                                                                argumentField="nama"
                                                                dataSource={chart5 || []}
                                                                withTitle={false}
                                                                showLegend={false}
                                                                toolsExport={false}
                                                                colorBarDifferent={true}
                                                                colorBarDiffirentField="nama"
                                                                coloBarDifferentValueField="jumlah"
                                                                rotationAngle={45}
                                                                overlappingBehavior="rotate"
                                                                onRenderTooltip={(arg) => ({ text: `${arg.argumentText}: ${arg.valueText}` })}
                                                            />
                                                        ), [chart5])
                                                    }
                                                </CstmFilterCahrtDx>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Index;