import React, { createContext, useCallback, useContext, useState } from "react";
import Swal from 'sweetalert2';
import { Link, useLocation } from "react-router-dom";
import { potensial_partner_opt_name, stage, workflow_state_name_light_style } from "../../../components/utility/utility";
import moment from "moment";
import useFetch from "../../../modules/useFetch";

const portContext = createContext({
    portAction: {},
    portState: {}
})
const data_type_proyek_1 = [
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '3', name: 'Area Inovasi' },
    { value: '4', name: 'Metode Proyek' }
]
const data_type_proyek_2 = [
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '3', name: 'Area Inovasi' }
]
const columns = [
    { fieldName: "created_at", caption: "Date Created", width: 200 },
    { fieldName: "title", caption: "Title", link: true, url: "/portfolio-research/view", width: 500 },
    { fieldName: 'description', caption: "Latar Belakang Masalah", width: 500 },
    { fieldName: 'purpose', caption: "Tujuan & Deliverable", width: 500 },
    { fieldName: "workflow_state_state", caption: "Status Workflow", width: 100 },
    { fieldName: "function_name", caption: "Function", width: 200 },
    { fieldName: "chief_name", caption: "Chief", width: 200 },
    { fieldName: "area_name", caption: "Area", width: 200 },
    { fieldName: "cat_name", caption: "Category", width: 200 },
    { fieldName: "project_type_short_name", caption: "Project Type", width: 200 },
    { fieldName: "leader", caption: "Owner", width: 200 },
    { fieldName: "method_name", caption: "Metode", width: 200 },
    { fieldName: "potensial_partner_opt_name", caption: "Potential Partner", width: 200 },
    { fieldName: "potential_user", caption: "Potential User", width: 200 },
    { fieldName: "val_tangible_angka", caption: "Tangible Value", width: 200 },
    { fieldName: "val_tangible_keterangan", caption: "Justifikasi", width: 200 },
    { fieldName: "val_intangible", caption: "Intangible Value", width: 200 },
    { fieldName: "stage", caption: "Stage", width: 200 },
    { fieldName: "year_start", caption: "Year Start", width: 200 },
    { fieldName: "year_end", caption: "Year End", width: 200 },
]
export function PortProvider({ children }) {
    const { doGet } = useFetch();
    const [data_page, setData_page] = useState({});

    const [dataDashboard, setDataDashboard] = useState({});
    const [dataTable, setDataTable] = useState({});
    const [dataTableReporting, setDataTableReporting] = useState({});
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingTableReporting, setLoadingTableReporting] = useState(false);
    const [dataProyekType, setDataProyekType] = useState([]);
    const [typeChart1, setTypeChart1] = useState('1');
    const [typeChart2, setTypeChart2] = useState('1');
    const [typeChart3, setTypeChart3] = useState('1');
    const [typeChart4, setTypeChart4] = useState('1');
    const [typeChart8, setTypeChart8] = useState('1');
    const [typeChart9, setTypeChart9] = useState('1');
    const [typeChart11, setTypeChart11] = useState('1');
    const [typeChart12, setTypeChart12] = useState('');
    const [typeChart13, setTypeChart13] = useState('0');
    const [typeChart14, setTypeChart14] = useState('0');
    const [typeTableIn, setTypeTableIn] = useState('0');
    const [chart1, setChart1] = useState({});
    const [loadingchart1, setloadingChart1] = useState(false);
    const [chart2, setChart2] = useState({});
    const [loadingchart2, setloadingChart2] = useState(false);
    const [chart3, setChart3] = useState({});
    const [loadingchart3, setloadingChart3] = useState(false);
    const [chart4, setChart4] = useState({});
    const [loadingchart4, setloadingChart4] = useState(false);
    const [chart8, setChart8] = useState({});
    const [loadingchart8, setloadingChart8] = useState(false);
    const [chart9, setChart9] = useState({});
    const [loadingchart9, setloadingChart9] = useState(false);
    const [chart11, setChart11] = useState({});
    const [loadingchart11, setloadingChart11] = useState(false);
    const [chart12, setChart12] = useState({});
    const [loadingchart12, setloadingChart12] = useState(false);
    const [chart13, setChart13] = useState({});
    const [loadingchart13, setloadingChart13] = useState(false);
    const [chart14, setChart14] = useState({});
    const [loadingchart14, setloadingChart14] = useState(false);
    const [tableIntangible, setTableIntangible] = useState({});
    const [tableIntangibleLoading, setTableIntangibleLoading] = useState(false);
    const [columnReporting, setColumnReporting] = useState([]);
    const [defColumnReporting, setDefColumnReporting] = useState([]);
    const [filterTahun, setFilterTahun] = useState([]);
    const { param } = useLocation();
    const [tahun, setTahun] = useState(param?.tahun || moment(new Date()).add(-1, 'months').format('YYYY'));

    async function getProyekType() {
        try {
            const resp = await doGet({ url: "api/param-project-type" });
            if (resp?.code === "00") {
                setDataProyekType([...(resp?.data || [])].map(d => {
                    return {
                        value: d?.param_id?.toString(),
                        name: d?.title
                    }
                }))
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function getDashboard() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        setLoadingDashboard(true);
        try {
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile",
                param: { tahun },
            });
            if (resp?.code === "00") {
                setDataDashboard(resp?.data);
                setData_page(resp.data_page);
                setFilterTahun([...(resp?.data?.data_year_portfolio || [])].map(d => ({ value: d?.idYear, name: d?.idYear })))
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingDashboard(false);
        Swal.close();

    }
    async function getDashboardAdmin() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        setLoadingDashboard(true);
        try {
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-3",
                param: {
                    tahun
                }
            });
            if (resp?.code === "00") {
                setDataDashboard(resp?.data);
                setData_page(resp.data_page);
                setFilterTahun([...(resp?.data?.data_year_portfolio || [])].map(d => ({ value: d?.idYear, name: d?.idYear })))
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingDashboard(false);
        Swal.close();
    }
    const getTable = useCallback(async function () {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        setLoadingTable(true);
        try {
            const resp = await doGet({
                url: "api/project-portfolio/table",
                param: {
                    tahun
                }
            });
            if (resp?.code === "00") {
                setDataTable(resp?.data);
                setData_page(resp.data_page);
                setFilterTahun([...(resp?.data?.data_allyear || [])].map(d => ({ value: d?.idYear, name: d?.idYear })))
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingTable(false);
        Swal.close();
    }, [tahun])
    const getTableSpiirit = useCallback(async function () {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        setLoadingTable(true);
        try {
            const resp = await doGet({
                url: "/api/project-portfolio/table-2", param: {
                    tahun
                }
            });
            if (resp?.code === "00") {
                setDataTable(resp?.data);
                setData_page(resp.data_page);
                setFilterTahun([...(resp?.data?.data_allyear || [])].map(d => ({ value: d?.idYear, name: d?.idYear })))
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingTable(false);
        Swal.close();
    }, [tahun])
    const getTableAdmin = useCallback(async function () {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        setLoadingTable(true);
        try {
            const resp = await doGet({
                url: "api/project-portfolio/table-3", param: {
                    tahun
                }
            });
            if (resp?.code === "00") {
                setDataTable(resp?.data);
                setData_page(resp.data_page);
                setFilterTahun([...(resp?.data?.data_allyear || [])].map(d => ({ value: d?.idYear, name: d?.idYear })))
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingTable(false);
        Swal.close();
    }, [tahun])
    const getTableReporting = useCallback(async function () {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        });
        setLoadingTableReporting(true);
        try {
            const resp = await doGet({
                url: "api/project-portfolio/table-budget-report", param: {
                    tahun: tahun
                }
            });
            if (resp?.code === "00") {
                setDataTableReporting(resp?.data);
                setData_page(resp.data_page);
                generateColumnReporting(resp?.data?.data_tahunmaxmin, resp?.data?.data_jumlahce, resp?.data?.data_tahunmin);
            }
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
        setLoadingTableReporting(false);
        Swal.close();
    }, [tahun])
    async function getChart1(type = '1') {
        try {
            setTypeChart1(type);
            setloadingChart1(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart1", param: {
                    tipe: type,
                    tahunQuery: tahun
                }
            })
            if (resp?.code === "00") {
                setChart1(resp?.data)
            }
            setloadingChart1(false);
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart2(type = '1') {
        try {
            setTypeChart2(type);
            setloadingChart2(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart2", param: {
                    tipe: type,
                    tahunQuery: tahun
                }
            })
            if (resp?.code === "00") {
                setChart2(resp?.data)
            }
            setloadingChart2(false);
        } catch (error) {
            return Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart3(type = '1') {
        try {
            setTypeChart3(type);
            setloadingChart3(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart3", param: {
                    tipe: type,
                    tahunQuery: tahun
                }
            });
            if (resp?.code === "00") {
                const data = [1, 2, 3, 4].map((val, i) => {
                    var ob = [...(resp?.data?.data_chart || [])].reduce((x, current) => {
                        return x = {
                            ...x,
                            [current?.name]: current[`data_${val}`]
                        }
                    }, {});
                    return {
                        state: val > 3 ? "> 3 Tahun" : val + " Tahun",
                        ...ob

                    }
                });
                setChart3({
                    ...resp?.data,
                    data_chart_fix: data
                })
            }
            setloadingChart3(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart4(type = '1') {
        try {
            setTypeChart4(type);
            setloadingChart4(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart4", param: {
                    tipe: type,
                    tahunQuery: tahun
                }
            });
            if (resp?.code === "00") {
                const data = ["InHouse", "Procurement", "Partnership", "Swakelola"].map((val, i) => {
                    var ob = [...(resp?.data?.data_chart || [])].reduce((x, current) => {
                        return x = {
                            ...x,
                            [current?.name]: current[`${val}`]
                        }
                    }, {});
                    return {
                        state: val,
                        ...ob

                    }
                });
                setChart4({
                    ...resp?.data,
                    data_chart_fix: data
                })
            }
            setloadingChart4(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    function getChart5() {
        var result = [];
        if (dataDashboard?.data_chart5_Partner) {
            const data = ['Universitas', 'Lembaga Penelitian', 'Entitas Bisnis'].map((val, i) => {
                var ob = [...(dataDashboard?.data_chart5_Partner || [])].reduce((x, current) => {
                    return x = {
                        ...x,
                        [current?.name]: current[`data_${i + 1}`]
                    }
                }, {});
                return {
                    state: val,
                    ...ob

                }
            });
            result = data;
        }
        return result;
    }
    function getChart7() {
        var result = [];
        var data = Array.isArray(dataDashboard?.data_chart7_Sinergisitas) ? dataDashboard?.data_chart7_Sinergisitas?.[0] : dataDashboard?.data_chart7_Sinergisitas;
        if (data) {
            result = [{ state: 'Satu Fungsi', val: data?.data_1 }, { state: 'Multi Fungsi', val: data?.data_2 }]
        }
        return result;
    }
    async function getChart8(type = '1') {
        try {
            setTypeChart8(type);
            setloadingChart8(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart8", param: {
                    area_id: type,
                    tahunQuery: tahun
                }
            });
            if (resp?.code === "00") {
                setChart8(resp?.data)
            }
            setloadingChart8(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart9(type = '1') {

        try {
            setTypeChart9(type);
            setloadingChart9(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart9", param: {
                    tipe: type,
                    tahunQuery: tahun
                }
            });
            if (resp?.code === "00") {
                const data = ['TRL 1', 'TRL 2', 'TRL 3', 'TRL 4', 'TRL 5', 'TRL 6', 'TRL 7', 'TRL 8', 'TRL 9'].map((val, i) => {
                    var ob = [...(resp?.data?.data_chart || [])].reduce((x, current) => {
                        return x = {
                            ...x,
                            [current?.name]: current[`data_${i + 1}`]
                        }
                    }, {});
                    return {
                        state: val,
                        ...ob

                    }
                });
                setChart9({
                    ...resp?.data,
                    data_chart_fix: data
                })
            }
            setloadingChart9(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart11(type = '1') {

        try {
            setTypeChart11(type);
            setloadingChart11(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart11", param: {
                    tipe: type,
                    tahunQuery: tahun
                }
            });
            if (resp?.code === "00") {
                const data = ['Pendapatan', 'Efisiensi', 'Penambahan Produksi Migas dll', 'Intangible Value'].map((val, i) => {
                    var ob = [...(resp?.data?.data_chart || [])].reduce((x, current) => {
                        return x = {
                            ...x,
                            [current?.name]: current[`data_${i + 1}`]
                        }
                    }, {});
                    return {
                        state: val,
                        ...ob

                    }
                });
                setChart11({
                    ...resp?.data,
                    data_chart_fix: data
                })
            }
            setloadingChart11(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart12(type = '') {
        try {
            setTypeChart12(type);
            setloadingChart12(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart12", param: {
                    func_id: type,
                    tahunQuery: tahun
                }
            });
            if (resp?.code === "00") {
                setChart12(resp?.data)
            }
            setloadingChart12(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart13(type = '0') {
        try {
            setTypeChart13(type);
            setloadingChart13(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart13", param: {
                    project_type: type,
                    tahun: tahun
                }
            });
            if (resp?.code === "00") {
                setChart13(resp?.data)
            }
            setloadingChart13(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getChart14(type = '0') {
        try {
            setTypeChart14(type);
            setloadingChart14(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-chart14", param: {
                    project_type: type,
                    tahun: tahun
                }
            });
            if (resp?.code === "00") {
                setChart14(resp?.data)
            }
            setloadingChart14(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }
    async function getTangibleTable(type = '0') {
        try {
            setTypeTableIn(type);
            setTableIntangibleLoading(true);
            const resp = await doGet({
                url: "api/project-portfolio/dashboard-project-profile-tab-intangible", param: {
                    project_type: type,
                    tahun: tahun
                }
            });
            if (resp?.code === "00") {
                setTableIntangible(resp?.data)
            }
            setTableIntangibleLoading(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    function getAreaOption() {
        return [...(dataDashboard?.data_area || [])].map(d => {
            return {
                value: d?.id,
                name: d?.name
            }
        })
    }
    function getFunctionOption() {
        return [...(dataDashboard?.data_function || [])].map(d => {
            return {
                value: d?.id,
                name: d?.name
            }
        })
    }
    function seriesBubleClick(e) {
        const series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }
    function headerCellRender(e) {
        return <p className="text-wrap">{e.column.caption}</p>;
    }
    function cellRenderGrid(e) {
        if (e.column.name === "title") {
            return (
                <Link target="_blank" to=
                    {
                        {
                            pathname: "/portfolio-research-public/view/" + e.data.id,
                        }
                    }
                    rel='noreferrer noopener'><span className="text-wrap">{e.value}</span></Link>
            )
        } else if (e.column.name === "workflow_state_state") {
            return (workflow_state_name_light_style(e))
        } else if (e.column.name === "value_creation") {
            return (
                <span className="text-wrap">
                    {e.data.vc_pendapatan === "1" && "Pendapatan "}
                    {e.data.vc_efisiensi === "1" && "Efisiensi "}
                    {e.data.vc_produksi === "1" && "Produksi "}
                    {e.data.vc_intangible === "1" && "Intangible "}
                </span>
            )
        } else if (e.column.name === "stage") {
            return (stage(e))
        } else if (e.column.name === "potensial_partner_opt_name") {
            return (potensial_partner_opt_name(e))
        } else if (e.column.name === "keyword"){
            return (
                <div className="text-wrap">
                {
                    (e.value || "").split(',').map((d, i) => (
                        <span className="badge badge-secondary mr-1 mb-1">{d}</span>
                    ))
                }
                </div>
            )
        }
         else {
            return (
                <span className="text-wrap">{e.value}</span>
            )
        }
    }
    function generateColumnReporting(data_tahunmaxmin = 0, data_jumlahce = 0, tahun_min = 0) {
        var column = [...columns];
        column = [
            ...columns,
            { fieldName: "plan_tot_budget", caption: "Budget Plan Total (Ribu USD)", width: 200 },
            { fieldName: "actual_tot_budget", caption: "Budget Spent Total (Ribu USD)", width: 200 },
        ];
        var defColumn = [...column];
        const p_ar1 = Array.from(Array(data_tahunmaxmin + 1), (prev, i) => ({
            fieldName: `plan_tot_budget${i + 1}`,
            caption: `Budget Plan ${tahun_min + i}`,
            width: 150,
        }))
        const p_ar2 = p_ar1.flatMap((d, i) => {
            const helper = Array.from(Array(data_jumlahce), (prev, j) => ({
                fieldName: `plan_tot_budget${i + 1}_${j + 1}`,
                caption: `Budget Plan_${tahun_min + i}_${j + 1}`,
                width: 150,
            }))
            return [d, ...helper]
        })
        const a_ar1 = Array.from(Array(data_tahunmaxmin + 1), (prev, i) => ({
            fieldName: `actual_tot_budget${i + 1}`,
            caption: `Budget Spent ${tahun_min + i}`,
            width: 150,
        }))
        const a_ar2 = a_ar1.flatMap((d, i) => {
            const helper = Array.from(Array(data_jumlahce), (prev, j) => ({
                fieldName: `actual_tot_budget${i + 1}_${j + 1}`,
                caption: `Budget Spent_${tahun_min + i}_${j + 1}`,
                width: 150,
            }))
            return [d, ...helper]
        })
        setDefColumnReporting([...defColumn, ...p_ar1, ...a_ar1]);
        column = [...column, ...p_ar2, ...a_ar2];
        // for (var i = 0; i <= data_tahunmaxmin; i++) {
        //     column = [...column, {
        //         fieldName: `plan_tot_budget${i + 1}`,
        //         caption: `Budget Plan ${tahun_min + i}`,
        //         width: 150,
        //     }]
        //     for (var j = 1; j <= data_jumlahce; j++) {
        //         column = [...column, {
        //             fieldName: `plan_tot_budget${i + 1}_${j}`,
        //             caption: `Budget Plan_${tahun_min + i}_${j}`,
        //             width: 150,
        //         }]
        //     }
        // }
        // for (var i = 0; i <= data_tahunmaxmin; i++) {
        //     column = [...column, {
        //         fieldName: `actual_tot_budget${i + 1}`,
        //         caption: `Budget Spent ${tahun_min + i}`,
        //         width: 150,
        //     }]
        //     for (var j = 1; j <= data_jumlahce; j++) {
        //         column = [...column, {
        //             fieldName: `actual_tot_budget${i + 1}_${j}`,
        //             caption: `Budget Spent_${tahun_min + i}_${j}`,
        //             width: 150,
        //         }]
        //     }
        // }
        setColumnReporting(column)
    }

    return <portContext.Provider value={{
        portAction: {
            getProyekType,
            getDashboard,
            getDashboardAdmin,
            getTable,
            getTableAdmin,
            getTableSpiirit,
            getTableReporting,
            getChart1,
            getChart2,
            getChart3,
            getChart4,
            getChart5,
            getChart7,
            getChart8,
            getChart9,
            getChart11,
            getChart12,
            getChart13,
            getChart14,
            getTangibleTable,
            getAreaOption,
            getFunctionOption,
            seriesBubleClick,
            headerCellRender,
            cellRenderGrid,
            setTahun
        },
        portState: {
            dataProyekType,
            chart1,
            chart2,
            chart3,
            chart4,
            chart8,
            chart9,
            chart11,
            chart12,
            chart13,
            chart14,
            tableIntangible,
            dataDashboard,
            dataTable,
            dataTableReporting,
            data_type_proyek_1,
            data_type_proyek_2,
            loadingDashboard,
            loadingTable,
            loadingTableReporting,
            loadingchart1,
            loadingchart2,
            loadingchart3,
            loadingchart4,
            loadingchart8,
            loadingchart9,
            loadingchart11,
            loadingchart12,
            loadingchart13,
            loadingchart14,
            tableIntangibleLoading,
            typeChart1,
            typeChart2,
            typeChart3,
            typeChart4,
            typeChart8,
            typeChart9,
            typeChart11,
            typeChart12,
            typeChart13,
            typeChart14,
            typeTableIn,
            columnReporting,
            defColumnReporting,
            filterTahun,
            data_page,
            tahun
        }
    }}>
        {children}
    </portContext.Provider>
}

export default function usePortfolio() {
    return useContext(portContext);
}