import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FileUploadDx, FormResearch } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import './style.css';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import FileUploader from 'devextreme-react/file-uploader';
import Card from 'react-bootstrap/Card'

export default memo(function (props) {
    const location = useHistory();
    const [sumberType, setSumberType] = useState()
    const history = useHistory()
    const [store, dispatch, actions] = useGlobalState();
    const [project_type, setProject_type] = useState(store.formData.project_type);

    useEffect(function () {
        actions.resetForm();
        if (project_type === null || project_type === undefined) {
            Swal.fire({
                title: 'Warning',
                text: "Pilih tipe project terlebih dahulu",
                icon: 'warning',
            }).then(res => history.goBack())

        }
        actions.getData('/api/param-area', null, 'areas').then((resp) => {
            if (resp.code === "00") {
                const newDs = resp.data.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { areas: newDs, loadingGet: false }
                })
            }
        });
        // actions.setForm('title', '')
        // actions.setForm('description', '')
        // actions.setForm('purpose', '')
        // actions.setForm('research_category_id', '')
        // actions.setForm('sumberFile', [])
    }, [])

    function areaChange(val) {
        actions.getData('/api/param-area-category/' + val, null, 'kategory').then((resp) => {
            if (resp.code === "00") {
                const newDs = resp.data.map((d, i) => {
                    return {
                        name: d.name,
                        value: d.id
                    }
                })
                dispatch({
                    type: "getSuccess",
                    payload: { kategory: newDs, loadingGet: false }
                })
            }
        });
    }
    function changeTitle(param, txt) {
        actions.setForm(param, txt)

        var title = (store.formData.apa ? store.formData.apa : "")
            + " " + (store.formData.bagaimana ? store.formData.bagaimana : "")
            + " " + (store.formData.cara ? store.formData.cara : "")
            + " " + (store.formData.dimana ? store.formData.dimana : "")

        switch (param) {
            case "apa":
                title = txt + " " + (store.formData.bagaimana ? store.formData.bagaimana : "")
                    + " " + (store.formData.cara ? store.formData.cara : "")
                    + " " + (store.formData.dimana ? store.formData.dimana : "")
                break;
            case "bagaimana":
                title = (store.formData.apa ? store.formData.apa : "") + " " + txt + " " + (store.formData.cara ? store.formData.cara : "")
                    + " " + (store.formData.dimana ? store.formData.dimana : "")
                break;
            case "cara":
                title = (store.formData.apa ? store.formData.apa : "") + " " + (store.formData.bagaimana ? store.formData.bagaimana : "")
                    + " " + txt + " " + (store.formData.dimana ? store.formData.dimana : "")
                break;
            case "dimana":
                title = (store.formData.apa ? store.formData.apa : "") + " " + (store.formData.bagaimana ? store.formData.bagaimana : "")
                    + " " + (store.formData.cara ? store.formData.cara : "")
                    + " " + txt
                break;
        }
        actions.setForm("title", title)
    }
    function addSumberFile(value) {
        if (value.tipe_document === "url" && value.link !== null && value.link !== undefined && value.link !== "") {
            const newData = store.formData.sumberFile || [];
            newData.splice(store.formData.sumberFile, 0, value)
            actions.setForm("sumberFile", newData);
        }
        if (value.tipe_document === "file") {
            const newData = store.formData.sumberFile || [];
            newData.splice(store.formData.sumberFile, 0, value)
            actions.setForm("sumberFile", newData);
        }
    }
    function deleteSumberFile(key, file) {
        if (file.tipe_document === "file") {
            actions.postData(
                {
                    url: "/api/base/delete-file-temp?fileName=" + file.file_name,
                    withFile: false,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code !== "00") {
                    alert(resp.message)
                } else {
                    const newData = store.formData.sumberFile || []
                    newData.splice(key, 1)
                    actions.setForm("sumberFile", newData);
                }
            })
        } else {
            const newData = store.formData.sumberFile || []
            newData.splice(key, 1)
            actions.setForm("sumberFile", newData);
        }
    }
    function onChangeFile(data) {
        const file = data.value?.[0]
        if (data.value?.[0] && file.size <= 10000000) {
            actions.postData(
                {
                    url: "/api/base/upload-file-temp",
                    data: {
                        "File": data.value?.[0],
                    },
                    withFile: true,
                    withLoading: false,
                    withAlert: false
                }
            ).then((resp) => {
                if (resp.code === "00") {
                    const newData = store.formData.sumberFile || [];
                    newData.splice(store.formData.sumberFile, 0, {
                        "file_location": resp.data?.data_file_location,
                        "tipe_document": "file",
                        "file_name": resp.data?.data_filename,
                        "is_new": true,
                        "link": ''
                    })
                    actions.setForm("sumberFile", newData);
                }
            })
        } else if (data.value?.[0] && file.size > 10000000) {
            return Swal.fire({
                title: 'Error',
                text: "File lebih dari 10Mb",
                icon: 'error',
            })
        }
    }
    function fileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            const newData = [{
                "file_location": resp.data?.data_file_location,
                "tipe_document": "file",
                "file_name": resp.data?.data_filename,
                "is_new": true,
                "link": ''
            }, ...(store.formData.sumberFile || [])];
            actions.setForm("sumberFile", newData);
        }
    }
    function validation() {
        const data = {
            "id": 0,
            "chief_manager_id": 0,
            "project_type": project_type,
            "title": store.formData.title,
            "description": store.formData.description,
            "purpose": store.formData.purpose,
            "initiator": store.dataUser?.id,
            "research_category_id": store.formData.research_category_id,
            "document": store.formData.sumberFile || [],
            "created_at": "",
            "updated_at": "",
            "year_ideation": "",
            "baseline_name": 0,
            "document_delete": []
        }
        let error = false
        let message = []
        if ((store.formData.apa || "") === '') {
            error = true
            message = [...message, "Judul (Apa)"]
        }
        if ((store.formData.bagaimana || "") === '') {
            error = true
            message = [...message, "Judul (Bagaimana)"]
        }
        if ((store.formData.cara || "") === '') {
            error = true
            message = [...message, "Judul (Cara)"]
        }
        if ((store.formData.dimana || "") === '') {
            error = true
            message = [...message, "Judul (Dimana)"]
        } if (!data.description) {
            error = true
            message = [...message, "Latar Belakang"]
        } if (!data.purpose) {
            error = true
            message = [...message, "Tujuan"]
        } if (!data.research_category_id) {
            error = true
            message = [...message, "Kategori"]
        }
        // else if (data.document.length < 1) {
        //     error = true
        //     message = "Document tidak boleh kosong"
        // }
        if (error) {
            return Swal.fire({
                title: 'Warning',
                text: message.join(', ') + ' Tidak boleh kosong',
                icon: 'warning',
            })
        } else {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    '<strong>Submit Project? </strong>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    submit(data)
                    return true;
                } else {
                    return true;
                }
            })
        }
    }
    function submit(formData) {
        actions.postData(
            {
                url: "/api/project-ideation-crud/submit-draft",
                data: formData,
                withFile: false
            }
        ).then((resp) => {
            if (resp.code === "00") {
                actions.resetForm();
                history.push('/myproject')
            }
        })
    }

    return (
        <>

            <h3>{store.formData.project_type_str}</h3>
            <Card
                className="mx-0 mt-0 p-0 customShadow" style={{ marginBottom: 40 }}
            >
                <Card.Body className="p-5">
                    <FormResearch
                        formLists={[
                            {
                                rows: 1,
                                header: true,
                                name: <>Initiator Ide <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "text",
                                        label: "Nama",
                                        value: store.dataUser?.name,
                                        disabled: true
                                    },
                                    {
                                        label: "Jabatan",
                                        type: "text",
                                        value: store.dataUser?.position_name,
                                        disabled: true
                                    },
                                    {
                                        label: "Fungsi",
                                        type: "text",
                                        value: store.dataUser?.function_name,
                                        disabled: true
                                    }
                                ]
                            },
                            {
                                rows: 1,
                                header: true,
                                name: <>Latar Belakang <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "array",
                                        label: "Judul",
                                        forms: [
                                            {
                                                type: "text",
                                                placeholder: "Apa?",
                                                disabled: false,
                                                required: true,
                                                value: store.formData.apa,
                                                onChange: (v) => changeTitle("apa", v)
                                            },
                                            {
                                                placeholder: "Bagaimana?",
                                                type: "text",
                                                disabled: false,
                                                value: store.formData.bagaimana,
                                                onChange: (v) => changeTitle("bagaimana", v)
                                            },
                                            {
                                                placeholder: "Cara?",
                                                type: "text",
                                                disabled: false,
                                                value: store.formData.cara,
                                                onChange: (v) => changeTitle("cara", v)
                                            },
                                            {
                                                placeholder: "Dimana?",
                                                type: "text",
                                                disabled: false,
                                                value: store.formData.dimana,
                                                onChange: (v) => changeTitle("dimana", v)
                                            },
                                            {
                                                type: "custom",
                                                component: (
                                                    <>
                                                        <div style={{
                                                            padding: "10px 10px",
                                                            margin: "10px 0px",
                                                            border: "1pt dashed #0F6EB5",
                                                            borderRadius: "5px",
                                                            background: "#006CB83B",
                                                            minHeight: 50
                                                        }}>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span style={{ fontSize: "13pt", fontWeight: "bold" }}>Judul Research :</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col text-center">
                                                                    <span style={{ fontSize: "12pt" }}>
                                                                        {store.formData.title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        ]
                                    },
                                    {
                                        label: "Pain Point",
                                        type: "textarea",
                                        disabled: false,
                                        value: store.formData.description,
                                        onChange: (v) => actions.setForm("description", v)
                                    },
                                    {
                                        label: "Commercialization /Implementation- Objective",
                                        type: "textarea",
                                        disabled: false,
                                        value: store.formData.purpose,
                                        onChange: (v) => actions.setForm("purpose", v)
                                    }
                                ]
                            },

                            {
                                rows: 2,
                                header: true,
                                name: <>Klasifikasi Research <span className="text-danger">(*)</span></>,
                                childs: [
                                    {
                                        type: "custom",
                                        //options: [{ name: '- Pilih Area -', value: '', disabled: true }, ...(store.areas || [])],
                                        //onChange: (e) => areaChange(e),
                                        label: "Area Research",
                                        disabled: false,
                                        component: (
                                            <>
                                                <select className="form-control form-control-sm mb-5"
                                                    onChange={(e) => areaChange(e.target.value)}
                                                >
                                                    <option value=""> - Pilih Area - </option>
                                                    {
                                                        [...(store.areas || [])].map((d, i) => (
                                                            <option value={d.value} key={i}>
                                                                {d.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </>
                                        )
                                    },
                                    {
                                        type: "custom",
                                        //options: [{ name: '- Pilih Kategori -', value: '', disabled: true }, ...(store.kategory || [])],
                                        //onChange: (e) => actions.setForm("research_category_id", e),
                                        label: "Kategory Research",
                                        disabled: false,
                                        component: (
                                            <>
                                                <select className="form-control form-control-sm mb-5"
                                                    value={store.formData.research_category_id}
                                                    onChange={(e) => actions.setForm("research_category_id", e.target.value)}
                                                >
                                                    <option value="" > - Pilih Kategori - </option>
                                                    {
                                                        [...(store.kategory || [])].map((d, i) => (
                                                            <option value={d.value} key={i}>
                                                                {d.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </>
                                        )
                                    },
                                ]
                            },
                            {
                                rows: 2,
                                header: true,
                                name: "Dokumen Pendukung",
                                childs: [
                                    {
                                        type: "select",
                                        options: [{ name: 'Masukan Link/Url', value: 'url' }, { name: 'File', value: 'file' }],
                                        label: "Tipe",
                                        value: sumberType,
                                        onChange: (e) => setSumberType(e),
                                        disabled: false
                                    },
                                    {
                                        type: "custom",
                                        label: "Sumber File",
                                        disabled: false,
                                        component: (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12 px-4">
                                                        <div className="input-group mb-2">
                                                            {
                                                                sumberType === "file" ? (
                                                                    <div >
                                                                        <FileUploadDx
                                                                            className="custom-dx-fileuploader-file-name"
                                                                            showFileList={true}
                                                                            multiple={false}
                                                                            selectButtonText="Select File"
                                                                            readyToUploadMessage=" "
                                                                            labelText="or Drop file hire | Max size 10 Mb"
                                                                            accept="*"
                                                                            onUploaded={fileUploaded}
                                                                            maxFileSize={10000000}
                                                                            uploadMode="instantly"
                                                                        />
                                                                        {/* <FileUploader className="custom-dx-fileuploader-file-name" showFileList={false} multiple={false}
                                                                            onValueChanged={(e) => onChangeFile(e)}
                                                                            readyToUploadMessage=" "
                                                                            selectButtonText="Select File" labelText="or Drop file hire | Max size 10 Mb" accept="*" maxFileSize={10000000}
                                                                            uploadMode="useForm" ></FileUploader> */}
                                                                    </div>


                                                                ) : (
                                                                    <>
                                                                        <input type="text" onChange={(e) => actions.setForm("inputSumber", e.target.value)} className="form-control form-control-sm"></input>
                                                                        <div style={{ marginLeft: 10 }}>
                                                                            <button type="submit" className="btn btn-sm btn-primary" onClick={() => addSumberFile({
                                                                                "file_location": '',
                                                                                "tipe_document": "url",
                                                                                "file_name": null,
                                                                                "is_new": true,
                                                                                "link": store.formData.inputSumber
                                                                            })}>+ Add</button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div style={{ paddingBottom: '25px' }}>
                                                            {
                                                                store.formData.sumberFile?.map((file, key) => (
                                                                    <div className="d-flex flex-row justify-content-between mb-1">
                                                                        <span style={{ fontSize: '8pt' }}>{file.tipe_document === "file" ? file.file_name : file.link}</span>
                                                                        <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deleteSumberFile(key, file)}></i>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    },
                                ]
                            },
                        ]}
                    />
                    <span className="d-block my-5 text-danger">(*) Mandatori tidak boleh kosong</span>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer">
                <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Batal</button>
                    </div>
                    <div className="col-md-6 text-right">
                        <button onClick={() => validation()}
                            className="btn btn-sm btn-success">
                            Submit ideasi
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
})