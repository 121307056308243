import React, { memo } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import "./style.css";
import { checkobox, badge_2_light, button_new } from '../../components/myproject/index';
import Skeleton from 'react-loading-skeleton';
import { icon_project, color_workflow_stage } from '../../components/utility/utility';
import useMyProject from './useMyProject';

const TabMember = memo(() => {
    const [state, dispatch, action] = useGlobalState();
    const { mpState } = useMyProject();

    function stopOnclick(id) {
        action.setForm('stop_id', id)
        action.toggleModal("modalStopProject")        
    }
    function continueProject(id, workflow_state_name, workflow_state_id) {
        action.setForm('continue_id', id);
        action.setForm('workflow_state_name', workflow_state_name);
        action.setForm('workflow_state_id', workflow_state_id);
        action.toggleModal("modalContinueProject")
    }
    return (
        <>
            {
                mpState.lodaingMember ? (
                    <>
                        <div style={{ marginTop: "20px" }}>
                            <div className="row" style={{ margin: "5px", fontSize: '9pt' }}>
                                <div className="col-md-2 pl-0 text-left">
                                    <Skeleton height={150} />
                                </div>
                                <div className="col-projectdetail">
                                    <Skeleton height={150} />
                                </div>
                                <div className="col-projectstatus">
                                    <Skeleton height={150} />
                                </div>
                                <div className="col-md">
                                    <Skeleton height={150} />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <div className="row" style={{ margin: "5px", fontSize: '9pt' }}>
                                <div className="col-md-2 pl-0 text-left">
                                    <Skeleton height={150} />
                                </div>
                                <div className="col-projectdetail">
                                    <Skeleton height={150} />
                                </div>
                                <div className="col-projectstatus">
                                    <Skeleton height={150} />
                                </div>
                                <div className="col-md">
                                    <Skeleton height={150} />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (

                    [...(mpState.dataMember?.data_table || [])].map((d, i) => (
                        <div style={{ marginTop: "20px" }} key={i}>
                            <div className="row" style={{ margin: "5px", fontSize: '9pt' }}>
                                <div className="col-md-2 mb-3 mb-sm-3 pl-0 text-left">
                                    <div className={color_workflow_stage(d.workflow_stage) + " text-center p-5 rounded"}>
                                        {
                                            icon_project(d.project_type, d.workflow_stage, d.workflow_state_name, d.project_type_name)
                                        }
                                    </div>                                    
                                </div>
                                <div className="col-md-5 mb-3 mb-sm-3">
                                    <div className="row project-detail-custom m--padding-left-15">
                                        <div className="col-md-12" style={{ paddingBottom: '5px' }}><strong>{d.title}</strong></div>
                                        <div className="col-md-12" style={{ paddingBottom: '5px' }}><span>{d.description}</span></div>                                        
                                        <div className="col-md-6">
                                            <table className="clear-table mt-5">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "70px" }}><strong>Created</strong></td>
                                                        <td>:</td>
                                                        <td>{d.created_at}</td>
                                                    </tr>
                                                    {
                                                        (d.project_type === 6 || d.project_type === 7) && (
                                                            <tr>
                                                                <td><strong>Last Update</strong></td>
                                                                <td>:</td>
                                                                <td>{d.updated_at}</td>
                                                            </tr>

                                                        )
                                                    }
                                                    {
                                                        (d.project_type !== 6 && d.project_type !== 7) && (
                                                            <>
                                                                <tr>
                                                                    <td><strong>Area Riset</strong></td>
                                                                    <td>:</td>
                                                                    <td>{d.area_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Category</strong></td>
                                                                    <td>:</td>
                                                                    <td>{d.cat_name}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <table className="mt-5">
                                                <tbody>
                                                    {
                                                        (d.project_type !== 6 && d.project_type !== 7) && (
                                                            <tr>
                                                                <td><strong>Last Update</strong></td>
                                                                <td>:</td>
                                                                <td>{d.updated_at}</td>
                                                            </tr>

                                                        )
                                                    }
                                                    <tr>
                                                        <td><strong>Fungsi</strong></td>
                                                        <td>:</td>
                                                        <td>{d.function_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Cut Off</strong></td>
                                                        <td>:</td>
                                                        <td>
                                                            {
                                                                (d.workflow_stage === 'Project Monitoring' || d.workflow_stage === "ABI Monitoring") && (
                                                                    <>
                                                                        {
                                                                            d.cut_off === mpState.dataMember?.data_year_month_query ? (
                                                                                <span className="label label-light-success label-inline h-auto">
                                                                                    {d.cut_off}
                                                                                </span>
                                                                            ) : (
                                                                                <span className="label label-light-danger label-inline h-auto">
                                                                                    {d.cut_off}
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-sm-3">
                                    <div className="padding-left-15">                                        
                                        <div className="d-flex">
                                            <span className="pl-0 pr-2"><b>Status</b></span>
                                            {badge_2_light(d.workflow_stage, d.workflow_state_name, d.workflow_state_state)}
                                        </div>

                                        <div className="m--padding-10 project-approval-stage">
                                            {checkobox(d.workflow_stage, d.workflow_state_name)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md mb-3 mb-sm-3">
                                    {button_new(d.workflow_stage, d.workflow_state_name, d.is_continue, d.data_id, continueProject, mpState.dataMember?.data_year_form, d.workflow_state_id, d.baseline_name, mpState.dataMember?.data_year_month_form, mpState.dataMember?.data_cut_off_date, state)}
                                    {
                                        ((d.workflow_state_name === "monitoring" || d.workflow_state_name === "monitoring_revise_upload") && state.dataUser?.role_id !== '10') && (
                                            <button className="btn btn-sm btn-light-danger w-100 my-1" onClick={() => stopOnclick(d.data_id)}><i className="fa fa-times icon-nm"></i> Stop</button>
                                        )
                                    }                                    
                                </div>
                            </div>
                        </div>
                    ))
                )
            }
        </>
    )
})
export default TabMember;