import React from 'react';
import { HeaderList, Tab, TabContent } from '../../../components';
import './crudPortfolio.css';
import TabDashboard from './tabDashboard';
import TabTable from './tabTable';
import { useHistory, useLocation } from 'react-router';
import usePortfolio, { PortProvider } from './usePortfolio';


function Portfolio() {
    const { pathname } = useLocation();
    const history = useHistory();
    const { portAction, portState } = usePortfolio();

    return (
        <>
            <div style={{ paddingBottom: 20 }}>
                <HeaderList
                    title={portState.data_page?.page_title}
                    someText={portState.data_page?.page_breadcrumbs}
                    optionList={[{
                        withLabel: true,
                        label: "Usulan project pada tahun : ",
                        option: [...portState.filterTahun],
                        value: portState.tahun,
                        onChange: function (e) { portAction.setTahun(e) }
                    }]}
                ></HeaderList>
            </div>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/portfolio/dashboard',
                                param: { tahun: portState.tahun }
                            })
                        },
                        title: "Portfolio Dashboard"
                    },
                    {
                        id: "table",
                        onClick: function (e) {
                            return history.push({
                                pathname: '/portfolio/table',
                                param: { tahun: portState.tahun }
                            })
                        },
                        title: "Portfolio Table"
                    }
                ]}
                manualActive={pathname === "/portfolio/table" ? "table" : "dashboard"}
            >
                <TabContent id="dashboard">
                    <TabDashboard ></TabDashboard>
                </TabContent>
                <TabContent id="table">
                    <TabTable></TabTable>
                </TabContent>
            </Tab>
        </>
    )
}
export default () => <PortProvider><Portfolio /></PortProvider>