import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import useLndEven, { LndEvenProvider } from './use_event_index';
import moment from 'moment';
import './event.css';
import { desc_event } from '../component/constanta';
import useIndexLandPage from '../use_index';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PaginationCs } from '../../../components';
import ImageCover from '../images/bg-land-detail-event.svg';
import { useHistory } from 'react-router-dom';

function Event(props) {
    const { lndEvenAction, lndEvenState } = useLndEven();
    const { indAction: {
        toHome = () => null,
        toAllEventDetail = () => null
    } } = useIndexLandPage();
    const history = useHistory();
    return (
        <div className='mt-5' style={{ 'paddingTop': '35px' }}>
            <img src={ImageCover} style={{ 'position': 'absolute', width: '100%', height: '210px', top: '10px', objectFit: 'cover' }}></img>
            <Container>
                <div className='row justify-content-center text-center mb-5'>
                    <div className='col-lg-6 col-md-6 text-white'>
                        <h5 className='font-weight-boldest'>Event</h5>
                        <span className='text-wrap'>{desc_event}</span>
                    </div>
                </div>
                <div className='row justify-content-center text-center mb-5'>
                    <div className='col-lg-8 col-md-8'>
                        <div className="input-group input-group-rounded mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text input-group-icon border-right-0 bg-white" id="basic-addon1">
                                    <i className='fa fa-search text-primary icon-nm'></i>
                                </span>
                            </div>
                            <input type="text"
                                value={lndEvenState.keyword_search}
                                onChange={(e) => lndEvenAction.setkeyword_search(e.target.value)}
                                onKeyUp={(e) => lndEvenAction.keywordEnter(e)}
                                className="form-control border-left-0 input-group-field"
                                placeholder="Cari Event..." />
                        </div>
                        <div className='d-flex'>
                            <button className={`btn btn-sm btn-${lndEvenState.status?.key === 1 ? 'secondary' : 'light'} mx-1`}
                                onClick={(e) => lndEvenAction.statusChange(1)}
                            >Semua Event</button>
                            <button className={`btn btn-sm btn-${lndEvenState.status?.key === 2 ? 'secondary' : 'light'} mx-1`}
                                onClick={(e) => lndEvenAction.statusChange(2)}
                            >Akan datang</button>
                            <button className={`btn btn-sm btn-${lndEvenState.status?.key === 3 ? 'secondary' : 'light'} mx-1`}
                                onClick={(e) => lndEvenAction.statusChange(3)}
                            >Berlalu</button>
                        </div>
                    </div>
                </div>
                <PaginationCs
                    data={[...(lndEvenState.dataEvent?.data_event_list || [])]}
                    dataPerPage={8}
                    RenderContainer={({ children }) => (
                        <div className='row mb-5'>
                            {children}
                        </div>
                    )}
                    renderComponent={(d, i) => (
                        <div className='col-lg-3 col-md-3 mb-3' key={i}>
                            <div className='card p-3 h-100 rounded-cs card-land-produk'
                                onClick={() => toAllEventDetail(d)}
                            >
                                <LazyLoadImage
                                    key={i}
                                    src={d?.poster_location}
                                    width={'100%'}
                                    height={'200px'}
                                    className='rounded-cs border border-cs mb-3  image-card'
                                    style={{ objectFit: 'contain', backgroundColor: 'whitesmoke' }}
                                    onError={(e) => { e.target.src = "/images/no_image.jpg"; }}
                                    effect='blur'
                                />
                                <span className='text-wrap font-weight-boldest mb-3'>{d?.name}</span>
                                <div className='text-wrap mb-3 text-truncate-container'>{d?.description}</div>
                                <div className='footer-card-event'>
                                    <div>
                                        <span className='d-block text-wrap mb-2'><i className='far fa-clock px-2'></i>{d?.time_start} - {d?.time_end}</span>
                                        <span className='d-block text-wrap mb-2'><i className='fas fa-calendar px-2'></i>{moment(d?.date_start).format('MMMM DD, YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <div className='d-inline-flex bg-transparent py-3 position-sticky my-3' style={{ bottom: '10px' }}>
                    <button className='btn btn-sm btn-secondary'
                        onClick={() => history.push('/landing-page/beranda')}
                    >
                        <i className='fas fa-chevron-circle-left icon-nm'></i>
                        Kembali
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default (props) => <LndEvenProvider><Event {...props} /></LndEvenProvider>;