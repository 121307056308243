import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FileUploadDx } from '../../components';

const ModalContinueProject = ({ onSubmit = (data) => { }, workflow_state_name = '', id_research = '' }) => {
    const [state, dispatch, action] = useGlobalState();
    const [document, setDocument] = useState([]);
    const [document_delete, setDocument_delete] = useState([]);
    const [cover, setCover] = useState([]);
    const [cover_delete, setCover_delete] = useState();
    const [reason, setReason] = useState('');
    useEffect(() => {
        if (state.modal.modalContinueProject === true) {
            resetEditor();
            getData();
        }
    }, [state.modal.modalContinueProject])
    function resetEditor() {
        setDocument([]);
        setCover([]);
        setReason('');
    }
    function getData() {
        action.getData(`/api/get-data-report-request?research_id=${id_research}&tipe=${3}`, {}, null, 'loadingStop').then((resp) => {
            if (resp.code === '00') {
                if (resp.data.data_request) {
                    setDocument([...(resp.data?.data_report || [])].map(d => {
                        return {
                            file_location: d?.file_location,
                            file_name: d?.file_name,
                            is_new: false
                        }
                    }));
                    setCover([{
                        file_name: resp.data?.data_request?.cover_file_name,
                        file_location: resp.data?.data_request?.cover_file_location,
                        is_new: false
                    }])
                }
            }
        })
    }
    function fileUploaded(res) {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            const newData = document || [];
            setDocument([{
                "file_location": resp.data?.data_file_location,
                "file_name": resp.data?.data_filename,
                "is_new": true
            }, ...newData])
            const newD = state.formData.sumberFile || [];
        }
    }
    function deletedDocument(key, data) {
        const newData = document || []
        const indexOf = newData.indexOf(data)

        if (data?.is_new === true) {
            deleteSumberFile(indexOf, data)
        }
        if (data?.is_new === false) {
            setDocument(newData.filter(d => d !== data));
            setDocument_delete([data, ...document_delete]);
        }
    }
    function deleteSumberFile(key, file) {
        action.postData(
            {
                url: "/api/base/delete-file-temp?fileName=" + file.file_name,
                withFile: false,
                withLoading: false,
                withAlert: false
            }
        ).then((resp) => {
            if (resp.code !== "00") {
                return Swal.fire({
                    title: 'Error',
                    text: resp.message,
                    icon: 'error',
                })
            } else {
                setDocument(document.filter(d => d !== file));
            }
        })
    }
    function submitOnclick() {
        const data = {
            reason,
            document,
            document_delete,
            cover,
            cover_delete
        }
        const validate = validationContinueReq(data);
        if (validate.isValid === true) {
            const res = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-sm btn-primary mx-1',
                    cancelButton: 'btn btn-sm btn-secondary mx-1'
                },
                buttonsStyling: false
            }).fire({
                html:
                    '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                    `<strong>Continue Request ${workflow_state_name === 'monitoring_revise_upload' ? 'Need Revision ' : ''}?</strong>`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    onSubmit({
                        reason,
                        document,
                        document_delete,
                        cover,
                        cover_delete
                    })
                    return true;
                } else {
                    return true;
                }
            })
        } else {
            return Swal.fire({
                title: 'Warning',
                text: validate.message,
                icon: 'warning',
            })
        }
    }
    function validationContinueReq(data) {
        var isValid = true;
        var message = [];
        if (data?.reason === '') {
            isValid = false;
            message = [...message, 'Reason']
        }
        if ([...(data?.document || [])].length === 0) {
            isValid = false;
            message = [...message, "Document"]
        }
        return {
            isValid,
            'message': message.join(', ') + ' Tidak boleh kosong'
        }
    }
    return (
        <div>
            <div className={state.loadingStop && 'overlay overlay-block'}>
                <div className="overlay-wrapper p-2 rounded">
                    <h5>Continue Project {workflow_state_name === 'monitoring_revise_upload' && 'Need Revision'}</h5>
                    <hr className="mb-5" />
                    {
                        workflow_state_name === 'monitoring_revise_upload' && (
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <div className="p-3 bg-light-warning rounded">
                                        <span className="text-wrap">{state.data_request?.reason}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="row mb-3">
                        <div className="col-md-3">
                            Reason
                        </div>
                        <div className="col-md-9">
                            <textarea className="form-control form-control-sm" rows="5" placeholder="Tulis disini..." required
                                value={reason} onChange={(e) => setReason(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-3">
                            Project Report
                        </div>
                        <div className="col-md-9">
                            <FileUploadDx
                                className="custom-dx-fileuploader-file-name"
                                showFileList={true}
                                multiple={false}
                                selectButtonText="Select File"
                                readyToUploadMessage=" "
                                labelText="or Drop file hire | Max size 10 Mb"
                                accept="*"
                                onUploaded={fileUploaded}
                                maxFileSize={10000000}
                                uploadMode="instantly"
                            />
                            <div className="mt-3 mb-5">
                                {
                                    document.map((file, key) => (
                                        <div className="d-flex flex-row justify-content-between mb-1">
                                            <span style={{ fontSize: '8pt' }}>{file.file_name}</span>
                                            <div className="d-inline-flex">
                                                <a target="_blank" href={file.file_name} download>
                                                    <i className="fas fa-file-alt icon-nm text-center p-2 btn btn-primary mx-1"></i>
                                                </a>
                                                <i className="fa fa-trash-alt icon-nm text-center p-2 btn btn-danger mx-1" onClick={() => deletedDocument(key, file)}></i>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>                    
                    <div className="text-md-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => action.toggleModal('modalContinueProject')}
                        >Batal</button>
                        <button className="btn btn-sm btn-primary mx-1"
                            onClick={() => submitOnclick()}
                        >Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalContinueProject;