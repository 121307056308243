import React, {createContext, useContext, useEffect, useState } from 'react';
import { useGlobalState } from '../../../contexts/useGlobalState';
import Swal from 'sweetalert2';

const usrContext = createContext({
    usrAction: {},
    usrState: {}
})
export function UserRoleProvider({ children }) {
    const [globalState, dispatch, globalActon] = useGlobalState();
    const [dataUser, setDataUser] = useState([]);
    const [loadingUser, setLoadingUSer] = useState(false);
    const [modal, setModal] = useState({
        show: false,
        editMode: false
    });
    const [formData, setFormData] = useState({
        "name": "",
        "email": "",
        "password": "",
        "employee_id": "",
        "status_profil": "",
        "role_id": [],
        "role_id_delete": [],
        "status_user": 1
    })
    const [dataRoles, setDataRoles] = useState([]);
    useEffect(function () {
        getRoles()
        getDataUserById()
    }, [])

    const [dataStatus, setDataStatus] = useState([])

    async function getRoles() {
        try {

            const resp = await globalActon.getData("/api/user/table-role")
            if (resp.code === "00") {
                setDataRoles(resp.data?.data_table)
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    function resetForm() {
        setFormData({
            "name": "",
            "email": "",
            "password": "",
            "employee_id": "",
            "status_profil": "",
            "role_id": [],
            "role_id_delete": []
        })
    }

    async function addData() {

        try {

            const resp = await globalActon.postData({
                url: "/api/user/create-user",
                data: formData
            }, () => {
                getUser()
                toggleModal()
                resetForm()
            })
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    async function getDataUserById(id = 0) {
        try {

            const resp = await globalActon.getData(`/api/user/view-user?id=${id}`, {}, null, null, true)
            if (resp.code === "00") {
                // setDataUser(resp.data)
                if (id === 0) setDataStatus(resp.data?.data_status_profil || [])
                else {
                    const { data_user } = (resp.data || { data_user: {} })
                    let roles = (data_user?.role_name || "").split(",");
                    let role_id = (roles || []).map(v => ({
                        role_id: (dataRoles.find(({ name }) => name === v)?.id || ""),
                        is_new: false
                    }))
                    setFormData({
                        id,
                        name: data_user.name,
                        email: data_user.email,
                        password: "",
                        status_profil: data_user.status_profil,
                        employee_id: data_user.employee_id,
                        role_id,
                        role_id_delete: [],
                        status_user: id === 0 ? 1 : data_user.status
                    })
                }
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    async function updateData() {
        try {

            const resp = await globalActon.postData({
                url: "/api/user/update-user",
                data: formData
            }, () => {
                getUser()
                toggleModal()
                resetForm()
            })
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }
    }

    function deleteData(id) {
        confirm("Apakah anda yakin hapus data ini ? ", async function () {
            try {

                const resp = await globalActon.postData({
                    url: `/api/user/delete-user?id=${id}`
                }, () => {
                    getUser()
                })
            } catch (error) {
                Swal.fire("Error", error.toString(), "error")
            }
        })
    }

    function confirm(pesan = "Apakah Anda yakin ?", doNext = () => null) {
        Swal.fire({
            html:
                `<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>\
            <strong>${pesan}</strong>`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((res) => {
            if (res.isConfirmed) {
                doNext();
            }
        })
    }
    async function getUser() {
        setLoadingUSer(true)

        try {

            const resp = await globalActon.getData("/api/user/table-user")
            if (resp.code === "00") {
                setDataUser(resp.data?.data_table || [])
                globalActon.setForm('usr_page', resp.data_page);
            }
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

        setLoadingUSer(false)
    }

    function toggleModal(editMode = false, id = null) {
        if (!modal?.show === true) {
            resetForm();
        }
        setModal(prevState => ({ editMode, show: !prevState.show }))
        if (editMode) getDataUserById(id)
    }

    function buttonAdd() {
        const val = validation();
        if (val.isValid === false) {
            return Swal.fire({
                title: 'Error!',
                text: val.message,
                icon: 'error',
            })
        }
        confirm("Apakah anda yakin ?", () => {

            if (modal.editMode) return updateData()
            else return addData()
        })
    }
    function validation() {
        var message = [];
        var isValid = true;
        if (formData.name === '') {
            isValid = false;
            message = [...message, 'Nama'];
        }
        if (formData.email === '') {
            isValid = false;
            message = [...message, 'Email'];
        }
        if (modal.editMode === false && formData.password === '') {
            isValid = false;
            message = [...message, 'Password'];
        }
        if (formData.employee_id === '') {
            isValid = false;
            message = [...message, 'Employee ID'];
        }
        if (formData.status_profil === '') {
            isValid = false;
            message = [...message, 'Status'];
        }
        if (formData.role_id.length === 0) {
            isValid = false;
            message = [...message, 'Role ID'];
        }
        return {
            isValid,
            message: message.join(', ') + ' Tidak boleh kosong'
        }
    }
    function deleteRole(data) {
        setFormData(f => ({
            ...f,
            role_id: (f.role_id || [])
                .filter((x) => x.role_id !== data?.role_id)
        }))
        if (data?.is_new === false) {
            setFormData(f => ({
                ...f,
                role_id_delete: [...(f.role_id_delete || []), data]
            }))
        }
    }
    return <usrContext.Provider value={{
        usrState: {
            dataUser,
            loadingUser,
            modal,
            formData,
            dataRoles,
            dataStatus
        },
        usrAction: {
            getUser,
            toggleModal,
            setFormData,
            buttonAdd,
            deleteData,
            deleteRole
        }
    }}>
        {children}
    </usrContext.Provider>
}
export default function useUserRole() {
    return useContext(usrContext)
}