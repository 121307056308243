import React, { useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card'
import useRoles, { RoleProvider } from './useRoles';
import { Modal } from 'react-bootstrap';
import { GridDx } from '../../../../../components';


function Index() {
    const { roleAction, roleState } = useRoles();
    const tableRef = useRef();
    useEffect(() => {
        roleAction.getRoles();
    }, [])

    return (
        <>
            <Modal show={roleState.modal.show} onHide={() => roleAction.toggleModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {` ${roleState.modal.editMode === true? 'Edit' : 'Add'} Roles`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="name">Name</label>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <input value={roleState.name} onChange={(e) => roleAction.setName(e.target.value)} placeholder="name" id="name" className="form-control form-control-sm" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="desc">Description</label>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <textarea value={roleState.Deskripsi} onChange={(e) => roleAction.setDeskripsi(e.target.value)} placeholder="description" id="desc" className="form-control  form-control-sm" cols={3} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => roleAction.toggleModal()} className="btn btn-sm btn-secondary">Close</button>
                    <button onClick={() => roleAction.simpanClick()} className="btn btn-sm btn-primary"><i className="fa fa-save icon-nm"></i>Simpan</button>
                </Modal.Footer>
            </Modal>
            <Card
                className="m-0 p-0 h-100 mb-5 customShadow"
            >
                <Card.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <GridDx
                                toolbar={
                                    <button onClick={() => roleAction.toggleModal()} className="btn-toolbar btn btn-primary mb-3">
                                        <i className="fa fa-plus icon-nm my-auto"></i> Add Role
                                    </button>
                                }
                                searchForm={true}
                                ref={tableRef}
                                loading={roleState.loadingRole}
                                data={roleState.dataRoles?.data_table}
                                withAction={true}
                                columns={[
                                    { fieldName: "name", caption: "Nama" },
                                    { fieldName: "Deskripsi", caption: "Deskripsi" },
                                ]}
                                actions={[
                                    {
                                        name: "Delete",
                                        icon: "fa fa-trash-alt icon-nm text-danger",
                                        action: (v, c) => { roleAction.deleteClick(v?.id) }
                                    },
                                    {
                                        name: "Edit",
                                        icon: "fa fa-pencil-alt icon-nm text-secondary",
                                        action: (v, c) => { roleAction.toggleModal(true, v?.id) }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
export default (props) => <RoleProvider><Index /></RoleProvider> 