import React from 'react'
// import {
//   HomePage,
//   TasksPage,
//   ProfilePage,
//   TestPage,
//   MyProject,
//   CreateProject,
//   PortFolioViewEdit,
//   Ideations,
//   ProjectList,
//   Portfolio,
//   MyTest,
//   Monitoring,
//   HighlightDashboard,
//   People
// } from '../pages';

const pages = (namePage) =>
  React.lazy(() =>
    import('../pages')
      .then((m) => ({ default: m[namePage] }))
      .catch((err) => err)
  );



const routes = [
  {
    path: '/profile',
    title: "Profile",
    component: pages("ProfilePage")
  },
  {
    path: '/profile-edit/:id',
    title: "Edit Profile",
    component: pages("EditProfile")
  },
  {
    path: '/home',
    title: "Project Management",
    component: pages("HomePage")
  },
  {
    path: '/myproject',
    title: "My Project",
    component: pages("MyProject")
  },
  {
    path: '/create-project',
    title: "Create Project",
    component: pages("CreateProjectCommercial")
  },
  {
    path: '/create-project-operasional',
    title: "Create Project Operasional",
    component: pages("CreateProjectOperasional")
  },
  {
    path: '/portfolio-research/:mode/:id',
    title: "Portfolio",
    component: pages("PortFolioViewEdit")
  },
  {
    path: '/portfolio-research-public/view/:id',
    title: "Portfolio",
    component: pages("PortFolioViewPublic"),
    noLayout: true
  },
  {
    path: '/OngoingResearch/:mode/:id/',
    title: "Monitoring",
    component: pages("MonitoringViewEdit")
  },
  {
    path: '/OngoingResearch-public/view/:id/',
    title: "Monitoring",
    component: pages("MonitoringViewPublic"),
    noLayout: true
  },
  {
    path: '/Portfolio/table',
    title: "Portfolio table",
    component: pages("Portfolio")
  },
  {
    path: '/Portfolio/dashboard',
    title: "Portfolio Dashboard",
    component: pages("Portfolio")
  },
  {
    path: '/Monitoring/dashboard-performance-byfunction',
    title: 'Monitoring',
    component: pages("Monitoring")
  },
  {
    path: '/Monitoring/dashboard-performance-byarea',
    title: 'Monitoring',
    component: pages("Monitoring")
  },
  {
    path: '/Monitoring/profile',
    title: 'Monitoring',
    component: pages("Monitoring")
  },
  {
    path: '/Monitoring/table',
    title: 'Monitoring',
    component: pages("Monitoring")
  },
  {
    path: '/Monitoring/table-progress',
    title: 'Monitoring',
    component: pages("Monitoring")
  },
  {
    path: '/Monitoring/sub-holdings',
    title: 'Monitoring',
    component: pages("MppsPage")
  },
  {
    path: '/ongoing/table-detail-search/:tahun/:bulanGet',
    title: 'Ongoing Detail',
    component: pages("OngoingTableSearch"),
    noLayout: true
  },
  {
    path: '/Projectmanagement/Dashboard',
    title: 'Project - Management',
    component: pages("HighlightDashboard")
  },
  {
    path: '/People/Table',
    title: 'People - Table',
    component: pages("People")
  },
  {
    path: '/People/dashboard',
    title: "People - Dashboard",
    component: pages("People")
  },
  {
    path: '/people/achievement',
    title: "People - Achievement",
    component: pages("Achievement")
  },
  {
    path: '/people/achievement-detail-public/:id',
    title: "Achievement Detail",
    component: pages("AchievementDetail")
  },
  {
    path: '/people/achievement-detail-hrd/:id',
    title: "Achievement Detail",
    component: pages("AchievementDetailHrd")
  },
  {
    path: '/people/achievement-detail/:id',
    title: "Achievement Detail",
    component: pages("AchievementDetailAdmin")
  },
  {
    path: '/People/event',
    title: "People - Event",
    component: pages("Event")
  },
  {
    path: '/People/patent',
    title: "People - Patent",
    component: pages("Patent")
  },
  {
    path: "/people/patent-view/:id",
    title: "Patent Detail",
    component: pages("PatentDetail")
  },
  {
    path: "/people/patent-view-public/:id",
    title: "Patent Detail",
    component: pages("PatentDetailPublic"),
    noLayout: true
  },
  {
    path: "/inventor-detail/:id",
    title: "Inventor Detail",
    component: pages("InventorDetail")
  },
  {
    path: '/Kerjasama/:tabContent',
    title: "Partnership Dashboard",
    component: pages("Kerjasama")
  }
  , {
    path: '/kerjasama/map-detail',
    title: "Partnership Map Detail",
    component: pages("KerjasamaMapDetail"),
    noLayout: true
  }
  , {
    path: '/KerjasamaDetail/:id',
    title: "Kerjasama Detail",
    component: pages("KerjasamaDetail"),
    noLayout: true
  }
  , {
    path: '/partnership-add',
    title: "Add Partnership",
    component: pages("KerjasamaAdd")
  },
  {
    path: '/research/projectProfile',
    title: "Project Profile",
    component: pages('ProjectProfile')
  },
  {
    path: '/research/monthlyReport/:id/:year/:month',
    title: "Monthly Report",
    component: pages('MonthlyReportAdmin'),
    noLayout: true
  },
  {
    path: '/research/monthlyReport-public/:id/:year/:month',
    title: "Monthly Report",
    component: pages('MonthlyReport'),
    noLayout: true
  }
  , {
    path: "/Projects/ResearchHistories/:id",
    title: "Monthly Report",
    component: pages("ProjectHistoriesAdmin")
  }
  , {
    path: "/Projects/ResearchHistories-public/:id",
    title: "Monthly Report",
    component: pages("ProjectHistories"),
    noLayout: true
  }
  , {
    path: "/reportResearch/index/:id",
    title: "Monthly Report",
    component: pages("MonthlyReportPageAdmin")
  }
  , {
    path: "/reportResearch-public/index/:id",
    title: "Monthly Report",
    component: pages("MonthlyReportPage"),
    noLayout: true
  }
  ,
  {
    path: "/research/project-profile/:id/:baseline_name",
    title: "Project Profile",
    component: pages("ProjectProfileAdmin"),
    noLayout: true
  },
  {
    path: "/research/project-profile-public/:id/:baseline_name",
    title: "Project Profile",
    component: pages("ProjectProfile"),
    noLayout: true
  }
  , {
    path: "/AllInventor",
    title: "All Inventor",
    component: pages("AllInventor")
  }
  , {
    path: "/people/event-detail/:id",
    title: "Event Detail",
    component: pages("EventDetail"),
    noLayout: true
  }
  , {
    path: "/people/patent-detail/:id",
    title: "Event Detail",
    component: pages("PatentDetail")
  }
  , {
    path: "/changePassword",
    title: "Change Password",
    component: pages("ChangePassword")
  }
  , {
    path: "/faq",
    title: "FAQ",
    component: pages("FaqPage")
  }
  , {
    path: "/knowledge/research",
    title: "Research",
    component: pages("KnowledgeResearch")
  }
  , {
    path: "/research-detail/:id",
    title: "Research Detail",
    component: pages("ResearchDetail"),
    noLayout: true
  }
  , {
    path: "/knowledge/library",
    title: "Library",
    component: pages("KnowledgeLibrary")
  }
  , {
    path: "/library-detail/:id",
    title: "Library Detail",
    component: pages("LibraryDetail"),
    noLayout: true
  }
  , {
    path: "/library-detail-hrd/:id",
    title: "Library Detail",
    component: pages("LibraryDetailAdmin")
  }
  , {
    path: '/inventory/dashboard',
    title: "Inventory Dashboard",
    component: pages("Inventory")
  }
  , {
    path: '/inventory/list',
    title: "Inventory List",
    component: pages("Inventory")
  }
  , {
    path: "/people-achievement/add",
    title: "People Achievement",
    component: pages("AddAchievement")
  }
  , {
    path: '/product/dashboard',
    title: "Product Dashboard",
    component: pages("ProducrMng")
  }
  , {
    path: '/product/display',
    title: "Product Display",
    component: pages("ProducrMng")
  }, {
    path: '/product/list',
    title: "Product Display",
    component: pages("ProducrMng")
  }, {
    path: '/product/detail/:id',
    title: "Product Display",
    component: pages("ProductDetail"),
    noLayout: true
  }
  , {
    path: '/swakelola/:menu',
    title: "Swakelola",
    component: pages("Swakelola")
  }
  , {
    path: '/swakelola-detail/:id',
    title: "Swakelola Detail",
    component: pages("SwakelolaDetail"),
    noLayout: true
  }
  , {
    path: '/swakelola-add',
    title: "Swakelola Add",
    component: pages("SwakelolaAdd")
  }
  , {
    path: '/swakelola-edit/:id',
    title: "Swakelola Edit",
    component: pages("SwakelolaEdit")
  }
  , {
    path: '/equipment-mng/:menu',
    title: "Equipment",
    component: pages("EquipmentMng")
  }
  , {
    path: '/equipment-detail/:id',
    title: "Equipment Detail",
    component: pages("EquipmentMngDetail"),
    noLayout: true,
  }
  , {
    path: '/EditTeamMember/OngoingResearch/:id',
    title: "Monitoring Edit Team Member",
    component: pages('MonitoringEditMember')
  }
  , {
    path: '/AllNotification',
    title: "All Notifications",
    component: pages('AllNotif')
  }
  , {
    path: '/project-ongoing/tabel-dashboard-project-profile-chart12/:tahunQuery',
    title: "Project Ongoing Table Dashboard Chart 12",
    component: pages('MonitoringChart12')
  }
  , {
    path: '/project-portfolio/tabel-dashboard-project-profile-chart12/:tahunQuery',
    title: "Project Ongoing Table Dashboard Chart 12",
    component: pages('PortfolioChart12'),
    noLayout: true
  }
  , {
    path: '/MyTest',
    title: "Test",
    component: pages("MyTest")
  }
  , {
    path: '/error-page',
    title: "Error",
    component: pages("NotAllowPage"),
  }
  , {
    path: '/page/error',
    title: "Error",
    component: pages("ErrorPage"),
  }
  , {
    path: '/comming-soon',
    title: "Comming Soon",
    component: pages("Commingsoon"),
  }
  , {
    path: '/error-page-session',
    title: "Error",
    component: pages("SessionExpired"),
  },
  ,
  {
    path: '/research/monthlyReport-multiple/:year/:month/:research_id',
    title: "Monthly Report Multiple",
    component: pages('MonthlyReportMultiple'),
    noLayout: true
  },
  {
    path: '/monitoring/project-champions',
    title: "Monitoring Project Champions",
    component: pages('MonitoringProjectChampions'),
    noLayout: false
  },
  {
    path: '/table-user-login-reminder',
    title: "Table User Login Reminder",
    component: pages('UserLoginReminder'),
    noLayout: true
  },
  {
    path: '/table-user-profile-reminder',
    title: "Table User profile Reminder",
    component: pages('UserProfileReminder'),
    noLayout: true
  },
  {
    path: '/table-user-equipment-expired-reminder',
    title: "Table Equipment Expired Reminder",
    component: pages('EquipmentReminder'),
    noLayout: true
  },
  {
    path: '/table-project-reminder',
    title: "Table Project Reminder",
    component: pages('DetailReminder'),
    noLayout: true
  },
  {
    path: '/table-project-need-attention',
    title: "Table Project Need Attention",
    component: pages('DetailNeedAttention'),
    noLayout: true
  },
  , {
    path: '/ongoing-research/view-sub-holdings/:id',
    title: 'Ongoing Research Sub-holding',
    component: pages("MonitoringDetailSubholding"),
    noLayout: true
  }
  , {
    path: '/equipment/report-status/:id',
    title: 'Report History Satus Alat',
    component: pages("ReportStatusAlat"),
    noLayout: true
  },
  {
    path: '/lab/data-product',
    title: "Laboratory",
    component: pages('LaboratoryPage'),
    noLayout: false
  }, {
    path: '/laboratory-permohonan-pengajuan',
    title: "Laboratory",
    component: pages('LaboratoryPermohonanPengajuan'),
    noLayout: false
  },
  {
    path: '/laboratory-detail/:id',
    title: "Laboratory Detail",
    component: pages('LaboratoryDetailPage'),
    noLayout: true
  },
  // {
  //   path: '/project/create',
  //   title: "Create Project",
  //   component: pages("CreateProjectNew")
  // },
  //  {
  //   path: '/create-project-commercial',
  //   title: "Create Project Commercial",
  //   component: pages("CreateProjectCommercial")
  // },
  // {
  //   path: '/portfolio-research/:id',
  //   title: "Portfolio",
  //   component:pages("PortFolioViewEdit")    
  // },
  // {
  //   path: '/tasks',
  //   title: "Tasks",
  //   component: pages("TasksPage")
  // },
  // {
  //   path: '/test',
  //   title: "test",
  //   component: pages("TestPage")
  // }, 
  // {
  //   path: '/create-project-abiabo',
  //   title: "Create Project ABI/ABO",
  //   component: pages("CreateProjectABI")
  // },
  // {
  //   path: '/ideation/dashboard',
  //   title: "Ideations",
  //   component: pages("Ideations")
  // }, 
  // {
  //   path: '/ideation/table',
  //   title: "Ideations",
  //   component: pages("Ideations")
  // }, 
  // {
  //   path: '/AbiRequest',
  //   title: "Abi Abo",
  //   component: pages("AbiAboRequest")
  // },
  // {
  //   path: '/ongoing/table-detail-search/:tahun/:bulanGet/:status_progress_search/:status_budget_search/:idFunction/:idArea/:need_support',
  //   title: 'Ongoing Detail',
  //   component: pages("OngoingTableSearch"),
  //   noLayout: true
  // },
  // {
  //   path: '/MyProject/leader',
  //   title: "MyProject - Leader",
  //   component: pages("MyProject")
  // }, {
  //   path: '/MyProject/team',
  //   title: "MyProject - Team",
  //   component: pages("MyProject")
  // }, {
  //   path: '/MyProject/archived',
  //   title: "MyProject - Archived",
  //   component: pages("MyProject")
  // },
  // , {
  //   path: '/AbiMonitoring/:mode',
  //   title: "Monitoring Abi",
  //   component: pages("AbiAboMon")
  // }
  // , {
  //   path: "/AbiAbo/:mode/:id",
  //   title: "Monthly Report",
  //   component: pages("AbiAboRequestViewEdit")
  // }
  // , {
  //   path: "/AbiAbo-public/view/:id",
  //   title: "Monthly Report",
  //   component: pages("AbiAboRequestViewPublic"),
  //   noLayout: true
  // }
  // , {
  //   path: "/OngoingAbi/:mode/:id",
  //   title: "Monthly Report",
  //   component: pages("OngoingAbi")
  // }
  // , {
  //   path: "/OngoingAbi-public/view/:id",
  //   title: "Monthly Report",
  //   component: pages("OngoingAbiPublic"),
  //   noLayout: true
  // }
  // , {
  //   path: "/AbiHistories-public/:id",
  //   title: "Abi Histories",
  //   component: pages("AbiHistories"),
  //   noLayout: true
  // }
  // , {
  //   path: "/AbiHistories/:id",
  //   title: "Abi Histories",
  //   component: pages("AbiHistoriesAdmin")
  // }
  // , {
  //   path: "/AbiMonthly-public/:id",
  //   title: "Abi Monthly Report",
  //   component: pages("AbiMonthly"),
  //   noLayout: true
  // }
  // , {
  //   path: "/AbiMonthly/:id",
  //   title: "Abi Monthly Report",
  //   component: pages("AbiMonthlyAdmin")
  // },
  // {
  //   path: '/ideation/:mode/:id',
  //   title: "Ideation detail",
  //   component: pages('ViewEditProject')
  // },
  // {
  //   path: '/ideation-public/view/:id',
  //   title: "Ideation detail",
  //   component: pages('ViewEditProjectPublic'),
  //   noLayout: true
  // },
  // {
  //   path: '/project-ongoing/project-unggulan-sh',
  //   title: "Table Project Need Attention",
  //   component: pages('MppsPage'),
  //   noLayout: false
  // },   
];

export default routes;
// export default routes.map(route => {
//   return {
//     ...route,
//     component: withNavigationWatcher(route.component, route.title)
//   };
// });
