import React, { createContext, useContext, useState } from "react";
import { useGlobalState } from "../../contexts/useGlobalState";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Badge, Card } from "react-bootstrap";
import { parseInt } from "lodash";
const contextSetParam = createContext({
    setParAction: {},
    setParState: {}
})

export function SettingParamProvider({ children }) {
    const [data_page, setData_page] = useState({});
    const [, , action] = useGlobalState();

    const [isEditingCO, setIsEditingCO] = useState(false);
    const [cut_off, setCut_off] = useState('1');
    const [loadingCo, setLoadingCo] = useState(false);

    const [modeSS, setModeSS] = useState();
    const [editDataSS, setEditDataSS] = useState();

    const [isEditingST, setIsEditingST] = useState(false);
    const [time_out, setTime_out] = useState('1');
    const [loadingST, setLoadingST] = useState(false);

    async function getDataCutOff() {
        try {
            setLoadingCo(true);
            const resp = await action.getData("/api/setting/view-cutoff", {
                // id: state.dataUser?.id
            }, null, null, true);
            if (resp?.code === "00") {
                setCut_off(resp?.data);
                setData_page(resp?.data_page);
            }
            setLoadingCo(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    async function getDataTimeout() {
        try {
            setLoadingST(true);
            const resp = await action.getData("/api/setting/view-timeout-session", {
                // id: state.dataUser?.id
            }, null, null, true);
            if (resp?.code === "00") {
                setTime_out(resp?.data);
                setData_page(resp?.data_page);
            }
            setLoadingST(false);
        } catch (error) {
            Swal.fire("Error", error.toString(), "error")
        }

    }
    function cancelCutoff() {
        getDataCutOff();
        setIsEditingCO(false);
    }
    function saveCutoff() {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update Cutoff? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/setting/update-cutoff?variable_value=${cut_off}`,
                    data: {}
                }).then((resp) => {
                    if (resp.code === '00') {
                        cancelCutoff();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }

    function cancelTimeout() {
        getDataTimeout();
        setIsEditingST(false);
    }
    function saveTimeOut() {
        if ((time_out || '') === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Timeout minimal 1 menit',
                icon: 'warning',
            })
        }
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Update Session Timeout? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/setting/update-timeout-session?variable_value=${time_out}`,
                    data: {}
                }).then((resp) => {
                    if (resp.code === '00') {
                        cancelTimeout();
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }

    function onSave_ss(data) {
    }
    function onEditClick_ss(data) {
        setModeSS("Edit")
        setEditDataSS(data)
        action.toggleModal("modalEditScreensaver")
    }
    function onAddClick_ss() {
        setModeSS("Add")
        action.toggleModal("modalEditScreensaver")
    }
    function onPriviewClick_ss() {
        action.toggleModal("modalScreensaver")
    }
    function renderContent_ss(data, key) {
        return (
            <>
                <Card
                    className="m-0 p-0 customShadow"
                >
                    <Card.Body>
                        <div>
                            <img className="d-block w-100 bg-secondary" height='150px' src={data.image ? data.image : "/media/svg/icons/Code/Question-circle.svg"} />
                        </div>
                        <span className="d-block mb-5">
                            {data.judul}
                        </span>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Badge variant="light" className="bg-light-primary text-primary" style={{ lineHeight: 2 }}>{key}</Badge>
                            <div className="d-inline-block">
                                <button className="btn btn-sm btn-light-danger mx-1"><i className="fa fa-trash-alt icon-sm"></i></button>
                                <button className="btn btn-sm btn-light-primary mx-1"
                                    onClick={() => onEditClick_ss(data)}
                                ><i className="fa fa-pencil-alt icon-sm"></i> Edit</button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>
        )
    }
    function plusCutoff(){
        const tcut = parseInt(cut_off || 0)
        if(tcut < 31){
            setCut_off(tcut + 1);
        }
    }
    function minCutoff(){
        const tcut = parseInt(cut_off || 0)
        if(tcut > 1){
            setCut_off(tcut - 1);
        } 
    }
    return <contextSetParam.Provider
        value={{
            setParAction: {
                getDataCutOff,
                getDataTimeout,
                setIsEditingCO,
                setCut_off,
                cancelCutoff,
                saveCutoff,
                setIsEditingST,
                setTime_out,
                cancelTimeout,
                saveTimeOut,
                onSave_ss,
                onEditClick_ss,
                onAddClick_ss,
                renderContent_ss,
                onPriviewClick_ss,
                plusCutoff,
                minCutoff
            },
            setParState: {
                data_page,
                isEditingCO,
                cut_off,
                loadingCo,
                modeSS,
                editDataSS,
                isEditingST,
                time_out,
                loadingST
            }
        }}
    >{children}</contextSetParam.Provider>
}
export default function useSettingParameter() {
    return useContext(contextSetParam);
}