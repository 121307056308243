import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../modules/useFetch";

const vdPreContext = createContext({
    vdPreState: {},
    vdPreAction: {}
});

export function VdPreProvider({ children }) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { doGet } = useFetch();
    const { id } = useParams();

    async function getData() {
        setLoading(true)
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: `/api/project-preideation-crud/view-detail/${id}`,
                token: true
            });
            if (resp?.code === '00') {
                // setData({ ...resp?.data });
                // setData_page({ ...resp?.data_page });
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
        setLoading(false);
    }
    useEffect(() => {
        getData();
    },[])
    return <vdPreContext.Provider
        value={{
            vdPreState: {},
            vdPreAction: {}
        }}
    >{children}</vdPreContext.Provider>
}
export default function useViewDetailPreideasi() {
    return useContext(vdPreContext);
}