import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import useFetch from '../../../modules/useFetch';
import ModalAjukanPermohonan from './modalAjukanPermohonan';

const context = createContext({
    labState: {},
    labAction: {}
});

export function LaboratoryProvider({ children }) {
    const { doGet, doPost } = useFetch();
    const [dataLab, setsetDataLab] = useState({});
    const [urlImage, setUrlImage] = useState("");
    const [modalFormReg, setModalFormReg] = useState({
        show: false,
        default_data: {}
    });

    async function getData() {
        Swal.fire({
            title: 'Please Wait',
            html: 'Retrieving data ...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        try {
            const resp = await doGet({
                url: 'api/web/all-product',
                param: {
                    tahun: '',
                    product_status: 0,
                    judul: '',
                    product_type_id: 7
                },
                token: false
            });
            if (resp?.code === '00') {
                setsetDataLab({ ...resp?.data })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getDataType() {
        try {
            const resp = await doGet({
                url: 'api/web/get-product-type',
                token: false
            });
            if (resp?.code === '00') {
                setUrlImage([...(resp.data || '')].find(d => d?.id === 7)?.image_link);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
    }
    const kirimPendaftaran = (formData = {}) => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-success mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-success text-white customShadow mb-5"></i></div>' +
                '<strong>Kirim pendataran ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                addCustomRegistration(formData)
            }
        })
    }
    const [dataInstit, setDataInstitut] = useState([]);
    const [dataSubInstit, setDataSubInstitut] = useState([]);
    async function getDataInstitution() {
        try {
            const resp = await doGet({
                url: 'api/ext-lab/institution',
                token: false
            });
            if (resp?.code === '00') {
                setDataInstitut(resp.data || []);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function getDataSubInstitution(id) {
        try {
            const resp = await doGet({
                url: 'api/ext-lab/institution-sub',
                param: {
                    id
                },
                token: false
            });
            if (resp?.code === '00') {
                setDataSubInstitut(resp.data || []);
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }
    async function addCustomRegistration(formData = {}) {
        try {
            Swal.fire({
                title: 'Please Wait',
                html: 'Sending data ...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const resp = await doPost({
                url: `api/ext-lab/add-customer-registration`,
                token: false,
                data: {
                    "institution_sub_id": formData?.institution_sub_id || "",
                    "nama": formData?.nama || "",
                    "email": formData?.email || "",
                    "hp": formData?.hp || "",
                    "fungsi": formData?.fungsi || "", 
                    "alamat": formData?.alamat || ""
                },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (resp?.code === '00') {
                const res = await Swal.fire({
                    title: 'Success',
                    text: resp.message,
                    icon: 'success',
                }).then(() => {
                    closeModalReg();
                })
            } else {
                return Swal.fire({
                    title: 'Error!',
                    text: resp?.message,
                    icon: 'error',
                })
            }
        } catch (error) {
            return Swal.fire({
                title: 'Error!',
                text: error.toString(),
                icon: 'error',
            })
        }
        Swal.close();
    }

    const openModalReg = () => setModalFormReg({ show: true, default_data: {} });
    const closeModalReg = () => setModalFormReg({ show: false, default_data: {} });


    useEffect(() => {
        getDataType();
        getData();
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])
    return <context.Provider
        value={{
            labState: {
                urlImage,
                dataLab,
                modalFormReg,
                dataInstit,
                dataSubInstit
            },
            labAction: {
                openModalReg,
                closeModalReg,
                kirimPendaftaran,
                getDataInstitution,
                getDataSubInstitution,
                setDataSubInstitut
            }
        }}
    >
        {/* <ModalAjukanPermohonan /> */}
        {children}
    </context.Provider>
}

export default function useLaboratory() {
    return useContext(context);
}