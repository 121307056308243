import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/useGlobalState';
import useModalEditorSwa, { SwaEditModalProvider } from './useModalEditorSwa';

const ModalNeedRevision = ({id_swakelola = ''}) => {
    const { swaEditAction, swaEditState } = useModalEditorSwa();
    const [state, , ] = useGlobalState();
    useEffect(() => {
        if(state.modal?.modalNeedRevision === true){
            swaEditAction.handleNeedRevision.setNote_revision('');
            swaEditAction.setSwakelola_id(id_swakelola);
        }
    },[state.modal?.modalNeedRevision])
    return (
        <div>
            <Card
                className="customShadow"
            >
                <Card.Body>
                    <h5>Need Revision ?</h5>
                    <hr />
                    <div className="mx-5">
                        <span className="d-block">Swakelola yang diajukan membutuhkan perbaikan dengan catatan :</span>
                        <textarea className="form-control form-control-sm" rows={4}
                            value={swaEditState.dataNeedRevision.note_revision} onChange={(e) => swaEditAction.handleNeedRevision.setNote_revision(e.target.value)}
                        ></textarea>
                    </div>
                    <hr />
                    <div className="text-md-right">
                        <button className="btn btn-sm btn-secondary mx-1"
                            onClick={() => swaEditAction.handleNeedRevision.batalNeedRevision()}
                        >Batal</button>
                        <button className="btn btn-sm btn-warning text-white"
                            onClick={() => swaEditAction.handleNeedRevision.needRevision()}
                        >
                            Need Revision
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default (props) => <SwaEditModalProvider><ModalNeedRevision id_swakelola={props?.id_swakelola} /></SwaEditModalProvider>;