import React, { useState } from 'react';
import useSubholdings from './useSubholdings';
import SVG from "react-inlinesvg";
import { Card } from 'react-bootstrap';
import { useMemo } from 'react';
import { ChartDx, CstmFilterCahrtDx, SwitchBtn } from '../../components';
import { useEffect } from 'react';

const usulanProyek = [
    { value: '3', name: 'Area Inovasi' },
    { value: "1", name: "Tipe Proyek" },
    { value: "2", name: 'Fungsi VP' },
    { value: '4', name: 'Metode Proyek' }
]

function TabDashboard(props) {
    const { subhState, subhAction } = useSubholdings();
    const [vmChartOne, setvmChartOne] = useState(false);
    const [vmChartTwo, setvmChartTwo] = useState(false);

    const ChartOne = useMemo(() => (
        <ChartDx
            loading={subhState.loadingTabDash}
            dataSource={[...(subhState.dataTabDash?.data_SCurve1_SH || [])]}
            type="spline"
            withTitle={false}
            showLegend={true}
            toolsExport={false}
            LegendverticalAlignment="bottom"
            LegendhorizontalAlignment="center"
            LegenditemTextPosition="right"
            onRenderLabelChart={function (e) { }}
            onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: ${e.valueText}` })}
            yAxisArgs={!vmChartOne ? [

                {
                    name: "Plan",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#326BD5'
                }

            ] : [
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ]}
        >

        </ChartDx>
    ), [vmChartOne, subhState.dataTabDash?.data_SCurve1_SH, subhState.loadingTabDash]);

    const ChartTwo = useMemo(() => (
        <ChartDx
            loading={subhState.loadingTabDash}
            dataSource={[...(subhState.dataTabDash?.data_SCurve2_SH || [])]}
            type="spline"
            withTitle={false}
            showLegend={true}
            toolsExport={false}
            LegendverticalAlignment="bottom"
            LegendhorizontalAlignment="center"
            LegenditemTextPosition="right"
            onRenderLabelChart={function (e) { }}
            onRenderTooltip={(e) => ({ text: `${e.seriesName} ${e.argumentText}: $ ${e.valueText}` })}
            yAxisArgs={!vmChartTwo ? [
                {
                    name: "Proposed Budget",
                    valueField: "plan",
                    color: '#D1D1D1'
                },
                {
                    name: "Realisasi",
                    valueField: "actual",
                    color: '#F08044'
                }

            ] : [
                {
                    name: "Perform",
                    valueField: "perform",
                    color: 'red'
                }
            ]}
        >

        </ChartDx>
    ), [vmChartTwo, subhState.dataTabDash?.data_SCurve2_SH, subhState.loadingTabDash]);

    const ChartThree = useMemo(() => (
        <ChartDx
            id="1"
            loading={subhState.loadingTabDash || subhState.loadingChart3}
            argumentField="name"
            dataSource={[...(subhState.data_chart3?.data_chart || [])]}
            withTitle={false}
            showLegend={false}
            toolsExport={false}
            onRenderTooltip={(e) => ({ text: `${e.argumentText}: ${e.valueText}` })}
            yAxisArgs={[
                {
                    valueField: "jumlah",
                    color: '#326BD5'
                }
            ]}
        />
    ), [subhState.loadingTabDash, subhState.loadingChart3, subhState.data_chart3?.data_chart]);

    const ChartProfile = useMemo(() => (
        <ChartDx
            id="5"
            loading={subhState.loadingTabDash}
            dataSource={[...(subhState.dataTabDash?.data_BarChart_SH || [])]}
            overlappingBehavior="stagger"
            colorBarDifferent={true}
            argumentField="name"
            colorBarDiffirentField="name"
            coloBarDifferentValueField="jumlah"
            withTitle={false}
            showLegend={false}
            toolsExport={false}

        />
    ), [subhState.loadingTabDash, subhState.dataTabDash?.data_BarChart_SH]);

    useEffect(() => {
        subhAction.getDataTabDashboard(subhState.filter?.tahun, subhState.filter?.bulan);
    }, []);
    return (
        <div>
            <Card
                className="m-0 p-0  mb-5 customShadow bg-primary"
            >
                <Card.Body className="p-3">
                    <div className="row justify-content-between" style={{ fontSize: '8pt' }}>
                        <div className="col-lg-auto col-md-auto col-sm-6 col-6 mb-3">
                            <div className="d-flex border-right-custom pr-3">
                                <span className="p-2 rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Home/Bulb1.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Project</span>
                                    <span className="d-block"><strong>{(subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.total || 0) - (subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.continue_request || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-blue" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Project Running</span>
                                    <span className="d-block"><strong>{subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.project_running || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-dark" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Finished</span>
                                    <span className="d-block"><strong>{subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.finished || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-danger" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Aborted</span>
                                    <span className="d-block"><strong>{subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.aborted || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-info" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Review</span>
                                    <span className="d-block"><strong>{subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.review || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-orange" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Need Revision</span>
                                    <span className="d-block"><strong>{subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.need_revision || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-6 mb-3">
                            <div className="d-flex">
                                <div>
                                    <div className="shadow rounded mt-1 bg-warning" style={{ width: 15, height: 15 }}>
                                    </div>
                                </div>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Not Yet Reported</span>
                                    <span className="d-block"><strong>{subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.project_continue || 0}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="d-flex border-left-custom px-md-3">
                                <span className="p-2 icon-header rounded shadow bg-white svg-icon svg-icon-dark">
                                    <SVG src="/media/svg/icons/Shopping/Dollar.svg" />
                                </span>
                                <div className="pl-3 text-white">
                                    <span className="d-block">Total Budget/Expense (Ribu USD)</span>
                                    <span className="d-block">$ <strong>{(subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.total_plan_budget || 0)}/{(subhState.dataTabDash?.data_JumlahProjectMonitoring_SH?.total_actual_budget || 0)}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card
                className="m-0 p-0 h-100 mb-5 customShadow"
            >
                <Card.Body className="p-5">
                    <div className='row mb-5'>
                        <div className='col-md-3 col-lg-3 col-6'>
                            <Card
                                key={-1}
                                className={'cstm customShadow m-0 p-0 h-100'}
                            >
                                <Card.Body className="p-2">
                                    <div className="justify-content-center">
                                        <span className="d-flex font-weight-bold" style={{ fontSize: '12px' }}>RTI</span>
                                        <div className="col"><h5 style={{ textAlign: 'end' }}><strong>{subhState.dataTabDash?.data_TotalPerformance_SH || 0}</strong>%</h5></div>
                                        <div className="progress border border-primary" style={{ marginLeft: '10px', backgroundColor: 'white', marginRight: '10px' }}>
                                            <div className="progress-bar" role="progressbar" style={{ width: `${subhState.perfomAll || 0}%` }}
                                                aria-valuenow={"10"}
                                                aria-valuemin="0"
                                                aria-valuemax={"100"}></div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span style={{ textAlign: 'start', paddingLeft: '10px', paddingTop: '7px' }}>{subhState.perfomAll || 0}%</span>
                                            <span className="text-right" style={{ textAlign: 'end', paddingRight: '10px', paddingTop: '7px' }}>{subhState.dataTabDash?.data_tahun_default || ''}</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-12 col-md-6 col-lg-6'>
                            <CstmFilterCahrtDx
                                cardStyle={false}
                                withBorder={true}
                                title="S-Curve RTI Projects"
                                subtitle="Perbandingan kumulatif realisasi dan prognosis proyek-proyek RTI"
                                customOption={
                                    <SwitchBtn value={vmChartOne} onChange={(e) => setvmChartOne(e)} />
                                }
                            >
                                {
                                    ChartOne
                                }
                            </CstmFilterCahrtDx>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6'>
                            <CstmFilterCahrtDx
                                cardStyle={false}
                                withBorder={true}
                                title="S-Curve RTI Budget"
                                subtitle="Perbandingan kumulatif realisasi dan pragnosis proyek proyek RTI (Ribu USD)"
                                customOption={
                                    <SwitchBtn value={vmChartTwo} onChange={(e) => setvmChartTwo(e)} />
                                }
                            >
                                {
                                    ChartTwo
                                }
                            </CstmFilterCahrtDx>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-md-6 col-lg-6'>
                            <CstmFilterCahrtDx
                                cardStyle={false}
                                withBorder={true}
                                title="Dukungan Operasional/ Sub-holding"
                                subtitle="Jumlah keterlibatan operasional/ sub-holding untuk mendukung proyek inovasi RTI"
                            >
                                {
                                    ChartProfile
                                }
                            </CstmFilterCahrtDx>
                        </div>
                        <div className='col-md-6 col-lg-6'>
                            <CstmFilterCahrtDx
                                cardStyle={false}
                                withBorder={true}
                                title="Jumlah Proyek"
                                subtitle="Statistik proyek berdasarkan tipe proyek"
                                option={usulanProyek}
                                onChange={(val) => subhAction.getDataChart3(subhState.dataTabDash?.data_tahun_default, val)}
                            >
                                {
                                    ChartThree
                                }
                            </CstmFilterCahrtDx>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default TabDashboard;