import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';

function PaginationCs({
    data = [],
    dataPerPage = 10,
    RenderContainer = ({ children }) => <>{children}</>,
    renderComponent = (data, i) => <span className='d-block'>{data?.idx}</span>
}) {
    const ds = data.map((d, i) => {
        return {
            idx: (i + 1),
            ...d
        }
    })
    const [page, setPage] = useState(1);
    const [countPg, setCountPg] = useState(0);
    const [ps, setPs] = useState(1);
    const [pn, setPn] = useState(1);
    const [tempData, setTempData] = useState([]);
    const handleChangePage = (event, newPage) => {
        const klpStart = newPage === 1 ? 1 : ((newPage - 1) * dataPerPage) + 1;
        const klpEnd = klpStart + (dataPerPage - 1);
        const tmp = ds.filter(d => d?.idx >= klpStart && d?.idx <= klpEnd);
        // console.log(klpStart, klpEnd, tmp)
        setPs(tmp?.[0]?.idx);
        setPn(tmp?.[tmp.length - 1]?.idx);
        setTempData(tmp)
        setPage(newPage);
    };

    useEffect(() => {
        var result = 0;
        if (ds.length > dataPerPage) {
            var hbagi = Math.floor(ds.length / dataPerPage);
            const sbagi = ds.length % dataPerPage;
            if (sbagi > 0) {
                hbagi = hbagi + 1
            }
            result = hbagi;
            setPs(1);
            setPn(dataPerPage);
            // console.log(ds.slice(0, dataPerPage))
            setTempData(ds.slice(0, dataPerPage));
        } else {
            result = 1;
            setPs(1);
            setPn(1);
            setTempData(ds);
        }
        setCountPg(result);
        setPage(1);
    }, [data])

    return (
        <>
            {/* <div className='mb-0'> */}
            <RenderContainer>
                {
                    tempData.map((d, i) => (
                        renderComponent(d, i)
                    ))
                }
            </RenderContainer>
            {/* </div> */}
            <div className='row'>
                <div className='col-md-6'>
                    <Pagination shape='rounded' page={page} onChange={handleChangePage} count={countPg} />
                </div>
                <div className='col-md-6 text-md-right'>
                    <span>Data {ps} {ds.length > dataPerPage && <>- {pn}</>} (Total Data {ds.length})</span>
                </div>
            </div>
        </>
    );
}

export default PaginationCs;