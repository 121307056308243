import React, { memo, useRef } from 'react'
import { HeaderList, Tab, TabContent, ExportPdf } from '../../components'
import TabTable from '../crudKerjasama/tabTable';
import TabDashboard from '../kerjasama/tabDashboard';
import useKejasama, { KerjasamaProvider } from '../kerjasama/useKerjasama';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import TabMonitoring from '../kerjasama/tabMonitoring';

function ReportingKerjasama(props) {
    const refDashboard = useRef();
    const { krjAction, krjState } = useKejasama();
    return (
        <>
            <div className="mb-5">
                <HeaderList
                    title={krjState.data_page?.page_title}
                    someText={krjState.data_page?.page_breadcrumbs}
                ></HeaderList>
            </div>
            <Tab
                withCard={false}
                itemsTab={[
                    {
                        id: "dashboard",
                        title: "Partnership Dashboard"
                    },
                    {
                        id: "table",
                        title: "Partnership Profile"
                    },
                    {
                        id: "monitoring",
                        title: "Partnership Monitoring"
                    }
                ]}
            >
                <TabContent id="dashboard">
                    <Card
                        className="m-0 p-0  mb-5 customShadow"
                    >
                        <Card.Body className="p-2">
                            <div className="d-block text-md-right mt-5">
                                <ExportPdf
                                    targetRef={refDashboard}
                                    scale={0.8}
                                    fileName={`Partnership Dashboard-${moment(new Date()).format('YYYY-MM-DD')}`}
                                >
                                    <button className="btn btn-sm btn-primary mb-5">
                                        Export PDF
                                    </button>
                                </ExportPdf>
                            </div>
                            <div ref={refDashboard}>
                                <div className="mb-5 px-2 font-bold" style={{ fontSize: '12pt' }}>Partnership Dashboard</div>
                                <TabDashboard reporting={true}></TabDashboard>
                            </div>
                        </Card.Body>
                    </Card>
                </TabContent>
                <TabContent id="table">
                    <TabTable reporting={true}></TabTable>
                </TabContent>
                <TabContent id="monitoring">
                    <TabMonitoring reporting={true}/>
                </TabContent>
            </Tab>
        </>
    );
}

export default () => <KerjasamaProvider><ReportingKerjasama /></KerjasamaProvider>