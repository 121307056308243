import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    backgroundColor: '#eaeaf0'
  },
  completed: {
    backgroundColor: '#eaeaf0'
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: ({ backgroundColor = '#ccc' }) => backgroundColor,
    zIndex: 1,
    color: ({ color = '#fff' }) => color,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles({ backgroundColor: props.bgIconColor, color: props.color });
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root)}
    >
      <i style={{ color: props.iconColor }} className={`${props.icon} icon-lg`}></i>
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));



export default function ({
  bgIconColor,
  color,
  iconColor = '#fff',
  data = [],
  onRenderLabel,
  icon = "fa fa-user"
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={data.length} connector={<ColorlibConnector />}>
        {
          data.map((d) => (
            <Step key={d}>
              <StepLabel StepIconComponent={(e) => ColorlibStepIcon({ ...e, bgIconColor, color, iconColor, icon })}>
                {
                  onRenderLabel? onRenderLabel(d) : typeof d === "object" ? "data is object please custom with OnrenderLabel" : d
                }
              </StepLabel>
            </Step>
          ))
        }
      </Stepper>
    </div>
  );
}
