import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { GridDx, HeaderList } from '../../components';
import { useGlobalState } from '../../contexts/useGlobalState';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment';
import { Summary, TotalItem } from 'devextreme-react/data-grid';
import { formatter_int } from '../../components/utility/utility';

function PatentDetail(props) {
    const [state, dispatch, action] = useGlobalState();
    const { pathname } = useLocation();
    const path = pathname.slice(0, pathname.lastIndexOf('/'));
    const [data_page, setData_page] = useState({});

    const param = useParams();
    const history = useHistory();
    useEffect(() => {
        action.getData("/api/people-intel-property/view-2?id=" + param.id, {}, null, null, true).then((resp) => {
            if (resp.code === '00') {
                setData_page(resp.data_page);
            }
        });
    }, [])
    const approveOnclick = () => {
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Approve Patent?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-intel-property/update-approve?id=" + param?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/people-patent/approval')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const rejectOnclick = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Reject Patent ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-intel-property/update-reject?id=" + param?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/people-patent/approval')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const deleteOnclick = () => {
        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-danger mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-danger text-white customShadow mb-5"></i></div>' +
                '<strong>Delete Patent ?</strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData(
                    {
                        url: "/api/people-intel-property/delete?id=" + param?.id,
                        withFile: false,
                    }
                ).then((resp) => {
                    if (resp.code === '00') {
                        action.resetForm();
                        history.push('/admin/people-patent/approval')
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    const cellRender = (rowInfo) => {
        if (rowInfo.column.name === 'created_at') {
            return (
                <span className="d-block text-wrap">{moment(rowInfo.value).format('yyyy-MM-DD')}</span>
            )
        } else if (rowInfo.column.name === 'jumlah') {
            return <span className='d-block text-right'>Rp. {formatter_int.format(rowInfo.value)}</span>
        } else {
            return (
                <span className="d-block text-wrap">{rowInfo.value}</span>
            )
        }
    }
    return (
        <div>
            <HeaderList
                title={data_page?.page_title}
                someText={data_page?.page_breadcrumbs}
            >

            </HeaderList>
            <Card
                className=" p-5 customShadow patent-detail-margin"
            >
                <Card.Body className="p-5">
                    <div class="row">
                        <div class="col-12">
                            <h5 className="font-bold text-bold-patent">{state.data_patent?.name}</h5>
                            <br />
                            <p className='text-bold-patent'>Deskripsi</p>
                            <p className="font-normal">{state.data_patent?.description}</p>
                            <hr />
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="mb-5">
                                        <span className="d-block mb-1 text-bold-patent">Kategori</span>
                                        <span>{state.data_patent?.category_name}</span>
                                    </div>
                                    <div className="mb-5">
                                        <span className="d-block mb-1 text-bold-patent">Tahun</span>
                                        <span>{state.data_patent?.patent_year}</span>
                                    </div>
                                    <div className="mb-5">
                                        <span className="d-block mb-1 text-bold-patent">Creator</span>
                                        <span>{state.data_patent?.creator_name}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-5">
                                        <span className="d-block mb-1 text-bold-patent">Inventor</span>
                                        <span>

                                            {
                                                state.data_patent?.paten_owner
                                            }

                                        </span>
                                    </div>
                                    <div className="mb-5">
                                        <span className="d-block mb-1 text-bold-patent">Inventor Ext.</span>
                                        <span>

                                            {state.data_patent?.owner_ext}

                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-5">
                                        <span className="d-block mb-1 text-bold-patent">Pemegang Paten</span>
                                        <ul>
                                            {
                                                state.data_patent?.pemegang_patent?.map((d, i) => (
                                                    <li>{d?.nama}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="mb-5">
                                        <span className="d-block mb-2 text-bold-patent">Keyword</span>
                                        {
                                            state.data_patent?.tag?.split(',')?.map((d, i) => (
                                                <span className="label label-light-primary label-inline mr-2 mb-2">{d}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='col-md-12'><hr /></div>
                            </div>
                            <div className='row mb-5'>
                                <div className="col-md-4">
                                    <div className="mb-5">
                                        <div className='bg-light-primary p-3 rounded'>
                                            <div className="mb-2">
                                                <span className="d-block mb-1 text-bold-patent">Pemilik</span>
                                                <span>{state.data_patent?.pemilik || '-'}</span>
                                            </div>
                                            {
                                                state.data_patent?.number === 1 && (
                                                    <>
                                                        <div className="mb-2">
                                                            <span className="d-block mb-1 text-bold-patent">Direktorat/Fungsi</span>
                                                            <span>{state.data_patent?.direktorat_fungsi || '-'}</span>
                                                        </div>
                                                        <div className="mb-2">
                                                            <span className="d-block mb-1 text-bold-patent">Area</span>
                                                            <span className="py-1 px-5 rounded">{state.data_patent?.patent_area_name}</span>
                                                        </div>
                                                        <div className="mb-2">
                                                            <span className="d-block mb-1 text-bold-patent">RKAP Berkaitan</span>
                                                            <ul>
                                                                {
                                                                    [...(state.data_patent?.rkap_berkaitan || [])].map((d, i) => (
                                                                        <li><Link>{d?.patent_name}</Link></li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                state.data_patent?.number === 2 && (
                                                    <>
                                                        <div className="mb-2">
                                                            <span className="d-block mb-1 text-bold-patent">Direktorat/Fungsi</span>
                                                            <span>{state.data_patent?.direktorat_fungsi || '-'}</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-5">
                                        <div className='bg-light-primary p-3 rounded'>
                                            <div className='mb-2'>
                                                <span className="d-block mb-1 text-bold-patent">Status</span>
                                                <span>{state.data_patent?.patent_status_name || '-'}</span>
                                            </div>
                                            <div className="mb-2">
                                                <span className="d-block mb-1 text-bold-patent">No. Pengajuan</span>
                                                <span>{state.data_patent?.no_pengajuan || '-'}</span>
                                            </div>
                                            <div className="mb-2">
                                                <span className="d-block mb-1 text-bold-patent">Tanggal Penerimaan</span>
                                                <span>{state.data_patent?.tanggal_penerimaan_str || '-'}</span>
                                            </div>
                                            <div className="mb-2">
                                                <span className="d-block mb-1 text-bold-patent">Tanggal Pemberian Paten</span>
                                                <span>{state.data_patent?.tanggal_pemberian_str || '-'}</span>
                                            </div>
                                            <div className="mb-2">
                                                <span className="d-block mb-1 text-bold-patent">No. Paten</span>
                                                <span>{state.data_patent?.no_paten || '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4"></div>
                            </div>
                            <hr />
                            <div className='row mb-5'>
                                <div className='col-md-12'>
                                    <p><b>Biaya Maintenance Intellectual Property</b></p>
                                    <GridDx
                                        cellRender={cellRender}
                                        className='custom-style'
                                        columns={[
                                            { fieldName: 'created_at', caption: "Tanggal Pembayaran", allowFiltering: false, width: 180 },
                                            { fieldName: 'jumlah', caption: "Jumlah (Rupiah)", allowFiltering: false, width: 150, alignment: 'right' },
                                            { fieldName: 'keterangan', caption: "Keterangan", allowFiltering: false }]}
                                        data={[...(state.data_maintenance || [])]}
                                    >
                                        <Summary>
                                            <TotalItem
                                                column="jumlah"
                                                showInColumn={'created_at'}
                                                summaryType="sum"
                                                customizeText={(e) => `Total Biaya`}
                                            />
                                            <TotalItem
                                                column="jumlah"
                                                summaryType="sum"
                                                customizeText={(e) => `Rp. ${formatter_int.format(e.value)}`}
                                            />
                                        </Summary>
                                    </GridDx>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className="p-2 bg-white rounded shadow-lg floating-footer w-100" style={{ left: 0 }}>
                <div className="row">
                    <div className="col-md-6 text-md-left">
                        {/* <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Kembali</button> */}
                    </div>
                    <div className="col-md-6 text-md-right">
                        {
                            state.data_patent?.list_dokumen && (
                                <>
                                    <Dropdown
                                        className="mb-3"
                                    >
                                        <Dropdown.Toggle className="btn btn-sm btn-primary custom-dropdown-icon" id="dropdown-basic">
                                            <span style={{ float: 'left' }}><i className="fa fa-file"></i> Download</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                [...(state.data_patent?.list_dokumen || [])].map((d, i) => (
                                                    <a className="dropdown-item text-wrap" target="_blank" href={d.file_name_with_url}>
                                                        {d?.name}
                                                    </a>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>

                            )
                        }
                        {
                            path.toLowerCase() === '/admin/people/patent-detail-approval' && (
                                <DropdownButton size="sm" id="dropdown-basic-button" title="Action">
                                    <Dropdown.Item onClick={approveOnclick}>Approve</Dropdown.Item>
                                    <Dropdown.Item onClick={() => history.push(`/admin/people-patent/edit/${param.id}`)}>Edit</Dropdown.Item>
                                    <Dropdown.Item className="text-danger" onClick={rejectOnclick}>Reject</Dropdown.Item>
                                    <Dropdown.Item className="text-danger" onClick={deleteOnclick}>Delete</Dropdown.Item>
                                </DropdownButton>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PatentDetail;