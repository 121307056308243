import React from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
import {
    HeaderList,
    // Tab,
    // TabContent
} from '../../components';
// import DashboardSwakelola from '../swakelola/dashboard';
import ListSwakelola from '../swakelola/list';
import useSwakelola, { SwaProvider } from '../swakelola/useSwakelola';

function CrudSwakelola(props) {
    const {
        swaState,
        // swaAction
    } = useSwakelola();
    // const { pathname } = useLocation();
    // const history = useHistory();
    return (
        <>
            <div style={{ paddingBottom: '20px' }}>
                <HeaderList
                    title={swaState.data_page?.page_title}
                    someText={swaState.data_page?.page_breadcrumbs}
                />
                <div className="row">
                    <div className="col-12">
                        {/* <Tab
                            withCard={false}
                            itemsTab={[
                                {
                                    id: "dashboard",
                                    title: "Dashboard",
                                    onClick: () => history.push(`/admin/swakelola-crud/dashboard`)
                                },
                                {
                                    id: "table",
                                    title: "Swakelola Table",
                                    onClick: () => history.push(`/admin/swakelola-crud/table`)
                                },
                            ]}
                            manualActive={pathname === '/admin/swakelola-crud/table' ? 'table' : 'dashboard'}
                        >
                            <TabContent id="dashboard">
                                <DashboardSwakelola />
                            </TabContent>
                            <TabContent id="table"> */}
                        <ListSwakelola editing={true} menu_admin={true} />
                        {/* </TabContent>
                        </Tab> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <SwaProvider><CrudSwakelola /></SwaProvider>;