import React, { useEffect, useState } from 'react';
import useLabMaster from '../useLabMaster';
import { Modal } from 'react-bootstrap';

function ModalParameterAnalisis(props) {
    const {
        lmState: {
            modalFormPA: {
                show = false,
                is_editing = false,
                default_data = {},
            },
            dataJK = []
        },
        lmAction: {
            closeModalFormPA = () => null,
            getJK = () => null,
            simpanPA = () => null
        }
    } = useLabMaster();
    const [formData, setFormData] = useState({
        lab_master_como_id: "",
        parameter_analisis: "",
        metode: "",
        harga: "",
        note: "",
        active: false
    });
    const handleChange = (name, val) => setFormData(prev => ({ ...prev, [name]: val }));
    useEffect(() => {
        if (show) {
            getJK();
            setFormData({
                lab_master_como_id: default_data?.lab_master_como_id || "",
                parameter_analisis: default_data?.parameter_analisis || "",
                metode: default_data?.metode || "",
                harga: default_data?.harga || "",
                note: default_data?.note || "",
                active: is_editing ? default_data?.active : true
            });
        } else {
            setFormData({
                lab_master_como_id: "",
                parameter_analisis: "",
                metode: "",
                harga: "",
                note: "",
                active: false
            });
        }
    }, [show]);
    return (
        <Modal show={show} size='md' onHide={closeModalFormPA}>
            <div className="card">
                <div className="card-header bg-white">
                    {is_editing ? "Ubah" : "Tambah"} Parameter Analisis
                </div>
                <div className="card-body">
                    <div className="form-group row">
                        <label htmlFor="lab_master_como_id" className="col-sm-4 col-form-label col-form-label-sm text-left">Jenis Komoditi</label>
                        <div className="col-sm-8">
                            <select className='form-control form-control-sm' id='lab_master_como_id'
                                value={formData.lab_master_como_id}
                                onChange={e => handleChange('lab_master_como_id', e.target.value)}
                            >
                                {[{ id: "", name: "-Pilih-" }, ...(dataJK || [])].map((d, i) => (
                                    <option key={i} value={d?.id}>{d?.nama}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="parameter_analisis" className="col-sm-4 col-form-label col-form-label-sm text-left">Parameter Analisis</label>
                        <div className="col-sm-8">
                            <input className='form-control form-control-sm' id='parameter_analisis'
                                value={formData.parameter_analisis}
                                onChange={e => handleChange('parameter_analisis', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="metode" className="col-sm-4 col-form-label col-form-label-sm text-left">Metode Pengujian</label>
                        <div className="col-sm-8">
                            <input className='form-control form-control-sm' id='metode'
                                value={formData.metode}
                                onChange={e => handleChange('metode', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="harga" className="col-sm-4 col-form-label col-form-label-sm text-left">Harga</label>
                        <div className="col-sm-8">
                            <input type='number' className='form-control form-control-sm' id='harga'
                                value={formData.harga}
                                onChange={e => handleChange('harga', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="active" className="col-sm-4 col-form-label col-form-label-sm text-left">Active</label>
                        <div className="col-sm-8">
                            <input type='checkbox' className='form-check-input form-control-sm ml-1' id='active'
                                checked={formData.active}
                                onChange={e => handleChange('active', e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="note" className="col-sm-4 col-form-label col-form-label-sm text-left">note</label>
                        <div className="col-sm-8">
                            <textarea rows={4} className='form-control form-control-sm' id='note'
                                value={formData.note}
                                onChange={e => handleChange('note', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    <button onClick={closeModalFormPA} className='btn btn-sm btn-secondary mr-3'>Batal</button>
                    <button onClick={() => simpanPA({
                        ...default_data,
                        ...formData
                    })} className='btn btn-sm btn-primary'>Simpan</button>
                </div>
            </div>
        </Modal >
    );
}

export default ModalParameterAnalisis;