import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FileUploadDx } from '../../../../components';
import moment from 'moment';
import useLab from '../useLaboratory';
import Swal from 'sweetalert2';

function ModalUploadSPKRev(props) {
    const [note, setNotes] = useState("");
    const [file_name, setfile_name] = useState("");
    const [file_name2, setfile_name2] = useState("");
    const [file_name_isnew, setfile_name_isnew] = useState(false);
    const [file_name2_isnew, setfile_name2_isnew] = useState(false);
    const refFile = useRef();
    const refFilePo = useRef();
    const { labState, labAction } = useLab();

    const fileUploaded = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setfile_name(resp.data?.data_filename || "");
            setfile_name_isnew(true);
            if (refFile.current) {
                refFile.current.instance.reset();
            }
        }
    }
    const fileUploaded2 = (res) => {
        const resp = JSON.parse(res);
        if (resp?.code === '00') {
            setfile_name2(resp.data?.data_filename || "");
            setfile_name2_isnew(true);
            if (refFilePo.current) {
                refFilePo.current.instance.reset();
            }
        }
    }
    const onKirim = () => {
        const name1 = file_name || labState.doKontrak?.file_name;
        const name2 = file_name2 || labState.doKontrak?.file_name2;
        if ((name1 === "" || name1 === null || name1 === undefined) || (name2 === "" || name2 === null || name2 === undefined) || note === "") {
            return Swal.fire({
                title: 'Error',
                text: "Harap mengisi keterangan, dokumen kontrak dan dokumen PO.",
                icon: 'warning',
            })
        }
        labAction.uploadSpkRev({
            "id": labState.modalSpkNR?.defaultData?.id,
            "note": note,
            "file_name": file_name,
            file_name2,
            file_name_isnew,
            file_name2_isnew
        });
    }

    useEffect(() => {
        function setDefault() {
            setfile_name("");
            setfile_name2("");
            setNotes("");
            setfile_name_isnew(false);
            setfile_name2_isnew(false);
            if (refFile.current) {
                refFile.current.instance.reset();
            }
            if (refFilePo.current) {
                refFilePo.current.instance.reset();
            }
        }
        if (labState.modalSpkNR.show) {
            setDefault();
            labAction.getDokKontrak(labState.modalSpkNR.defaultData?.id);
        }
    }, [labState.modalSpkNR.show]);

    return (
        <Modal show={labState.modalSpkNR?.show} onHide={() => labAction.setModalSpkNr({ show: false, default_data: {} })}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Dok. Kontrak Need Revision
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row">
                    <label className="col-sm-4">Tanggal Perubahan Status</label>
                    <div className="col-sm-8">
                        <p>{moment().format("DD-MM-yyyy")}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Keterangan / Komentar</label>
                    <div className="col-sm-8">
                        <textarea className='form-control form-control-sm' rows={3}
                            value={note} onChange={e => setNotes(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Upload Dokumen Kontrak (Wajib)</label>
                    <div className="col-sm-8">
                        <FileUploadDx
                            ref={refFile}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            allowedFileExtensions={['.pdf',]}
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 10 Mb"
                            accept="*"
                            onUploaded={fileUploaded}
                            maxFileSize={10000000}
                            uploadMode="instantly"
                        />
                        <p>{file_name || labState.doKontrak?.file_name}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label col-form-label-sm">Upload Dokumen PO (Wajib)</label>
                    <div className="col-sm-8">
                        <FileUploadDx
                            ref={refFilePo}
                            className="custom-dx-fileuploader-file-name"
                            showFileList={true}
                            multiple={false}
                            selectButtonText="Select File"
                            allowedFileExtensions={['.pdf',]}
                            readyToUploadMessage=" "
                            labelText="or Drop file hire | Max size 10 Mb"
                            accept="*"
                            onUploaded={fileUploaded2}
                            maxFileSize={10000000}
                            uploadMode="instantly"
                        />
                        <p>{file_name2 || labState.doKontrak?.file_name2}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => onKirim()} className="btn btn-sm btn-success mr-1">Kirim Dokumen SPK</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalUploadSPKRev;