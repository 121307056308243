import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";
import { useGlobalState } from "../../../../../app/contexts/useGlobalState";
import Swal from 'sweetalert2/dist/sweetalert2.js'


export function QuickPanelHelp() {
    const [state, dispatch, action] = useGlobalState();
    const { pathname } = useLocation();
    const [note, setNote] = useState('');

    function sendHelp() {
        if ((note || '') === '') {
            return Swal.fire({
                title: 'Warning',
                text: 'Note tidak boleh kosong',
                icon: 'warning',
            })
        }
        const res = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-sm btn-primary mx-1',
                cancelButton: 'btn btn-sm btn-secondary mx-1'
            },
            buttonsStyling: false
        }).fire({
            html:
                '<div><i class="fa fa-question icon-3x rounded p-5 bg-primary text-white customShadow mb-5"></i></div>' +
                '<strong>Send help? </strong>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                action.postData({
                    url: `/api/Help/Send`,
                    data: {
                        "id": '',
                        "swakelola_id": '',
                        "tanggal": "",
                        "nilai_adendum": '',
                        "update_nilai_kontrak": '',
                        "start_date": "",
                        "end_date": "",
                        "note": note || '',
                        "dok1_ba_addendum": "",
                        "dok2_permintaan": "",
                        "dok3_rev_po": "",
                        "dok4_kontrak_addendum": "",
                        "flag": false
                    }
                }).then((resp) => {
                    if (resp.code === '00') {
                        setNote('');
                    }
                })
                return true;
            } else {
                return true;
            }
        })
    }
    return (
        <div id="kt_quick_panel_help" className="offcanvas offcanvas-right pt-5 pb-10">
            <div className="row mx-5">
                <div className="col-md-12 d-flex justify-content-between mb-5">
                    <h5>Help</h5>
                    <a
                        href="#"
                        className="btn btn-xs btn-icon btn-light btn-hover-primary"
                        id="kt_quick_panel_help_close"
                    >
                        <i className="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
                <div className="col-md-12">
                    <span className="d-block text-wrap mb-5 text-dark-50">Silahkan bertanya kepada kami. Kami siap membantu Anda.</span>
                    <textarea className="form-control form-control-sm"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        rows={13}
                        placeholder="Deskripsi masalah atau pertanyaan"
                        style={{ backgroundColor: '#F2F2F2' }}
                    >
                    </textarea>
                    <div className="text-center mt-3 mb-5">
                        <button className="btn btn-sm btn-light-success"
                            onClick={() => sendHelp()}
                        >
                            Send
                        </button>
                    </div>
                    <hr style={{ borderTop: '1px dashed #00000029' }} />
                    <div>
                        <Link to={'/faq'} className="row bg-light-primary mb-3 p-3 rounded">
                            <div className="col-3">
                                <span className="p-2 rounded svg-icon svg-icon-lg svg-icon-primary">
                                    <SVG src="/media/svg/icons/Communication/Mail.svg" />
                                </span>
                            </div>
                            <div className="col-9">
                                <span className="d-block font-weight-bold">FAQ</span>
                                <span className="d-block text-dark-50 text-wrap"
                                >Pertanyaan yang sering diajukan</span>
                            </div>
                        </Link>
                        <Link to={'/comming-soon'} className="row bg-light-warning mb-3 p-3 rounded">
                            <div className="col-3">
                                <span className="p-2 rounded svg-icon svg-icon-lg svg-icon-warning">
                                    <SVG src="/media/svg/icons/Code/Info-circle.svg" />
                                </span>
                            </div>
                            <div className="col-9">
                                <span className="d-block font-weight-bold">About Version</span>
                                <span className="d-block text-dark-50 text-wrap"
                                >Tentang aplikasi SPIIRIT</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}